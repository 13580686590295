/* eslint complexity: ["error", 500] */
import axios from 'axios';

import { IPaginationBaseState, IPayloadResult } from 'react-jhipster';

import { IPayload } from 'react-jhipster/src/type/redux-action.type';

import { BASE_API_VERSION_PATH } from 'app/config/constants';

import { cleanEntity, jsonParse } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IPatientDoctorPrescription, defaultValue } from 'app/shared/model/patient-doctor-prescription.model';

import { IPatDrPrescrProcd, defaultValue as defaultValuePatDrPrescrProcd } from 'app/shared/model/pat-dr-prescr-procd.model';

export declare type ICrudPutAction<T> = (data?: T, listFiltersPage?) => IPayload<T> | IPayloadResult<T>;
export declare type ICrudGetAction<T> = (id: string | number, selectFields?: string) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const ACTION_TYPES = {
  FETCH_PATIENTDOCTORPRESCRIPTION_LIST_EXPORT: 'patientDoctorPrescription/FETCH_PATIENTDOCTORPRESCRIPTION_LIST_EXPORT',
  FETCH_PATIENTDOCTORPRESCRIPTION_LIST: 'patientDoctorPrescription/FETCH_PATIENTDOCTORPRESCRIPTION_LIST',
  FETCH_PATIENTDOCTORPRESCRIPTION: 'patientDoctorPrescription/FETCH_PATIENTDOCTORPRESCRIPTION',
  FETCH_PATIENTDOCTORPRESCRIPTION_OFFSET: 'patientDoctorPrescription/FETCH_PATIENTDOCTORPRESCRIPTION_OFFSET',
  CREATE_PATIENTDOCTORPRESCRIPTION: 'patientDoctorPrescription/CREATE_PATIENTDOCTORPRESCRIPTION',
  UPDATE_PATIENTDOCTORPRESCRIPTION: 'patientDoctorPrescription/UPDATE_PATIENTDOCTORPRESCRIPTION',
  CREATE_PATIENTDOCTORPRESCRIPTION_AND_GO_LIST: 'patientDoctorPrescription/CREATE_PATIENTDOCTORPRESCRIPTION_AND_GO_LIST',
  UPDATE_PATIENTDOCTORPRESCRIPTION_AND_GO_LIST: 'patientDoctorPrescription/UPDATE_PATIENTDOCTORPRESCRIPTION_AND_GO_LIST',
  DELETE_PATIENTDOCTORPRESCRIPTION: 'patientDoctorPrescription/DELETE_PATIENTDOCTORPRESCRIPTION',
  RESET: 'patientDoctorPrescription/RESET',
  SHOW_MODAL: 'patientDoctorPrescription/SHOW_MODAL',
};

const initialState = {
  loading: false,
  openModal: {
    list: false,
    view: false,
    detail: false,
  },
  errorMessage: null,
  entities: [] as ReadonlyArray<IPatientDoctorPrescription>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,

  patDrPrescrProcdListEmbed: [] as ReadonlyArray<IPatDrPrescrProcd> | any,
  patDrPrescrProcdListAllEmbed: [] as ReadonlyArray<IPatDrPrescrProcd> | any,
  patDrPrescrProcdSelected: defaultValuePatDrPrescrProcd,
};

const actionPendind = [];
let actualState = initialState;

export const convertEntityBuffers = entity => ({
  ...entity,
});

export type PatientDoctorPrescriptionState = Readonly<typeof initialState>;

export interface IPatientDoctorPrescriptionBaseState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  listCheckedID?: Array<number>;
  isCheckedAll?: boolean;
  urlBack?: string;
  extraFilters?: any;
  baseFilters: any;
  offset: number;
  observation?: any;
  patientId?: any;
  patientStartFilter?: any;
}

export interface IFieldsBase extends IPatientDoctorPrescriptionBaseState, IPaginationBaseState {}
export interface IPatientDoctorPrescriptionUpdateState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  urlBack?: string;
  functionEmbebed?: any[];
  fieldsBase: IFieldsBase;
  observationSelectValue?: any;
  observationStartSelectOptions?: any;

  patDrPrescrProcdSelectValue?: any;
  patDrPrescrProcdStartSelectOptions?: any;

  patientSelectValue?: any;
  patientStartSelectOptions?: any;

  isNew: boolean;
  patient?: any;

  patientId?: any;
}

// Reducer

export default (state: PatientDoctorPrescriptionState = initialState, action): PatientDoctorPrescriptionState => {
  actualState = state;
  if (
    action.type.includes('_PENDING') &&
    !actionPendind.includes(action.type.replace('_PENDING', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_PENDING', ''))
  ) {
    actionPendind.push(action.type.replace('_PENDING', ''));
  }

  if (
    action.type.includes('_FULFILLED') &&
    actionPendind.includes(action.type.replace('_FULFILLED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_FULFILLED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_FULFILLED', '')), 1);
  }

  if (
    action.type.includes('_REJECTED') &&
    actionPendind.includes(action.type.replace('_REJECTED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_REJECTED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_REJECTED', '')), 1);
  }

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PATIENTDOCTORPRESCRIPTION_LIST_EXPORT):
    case REQUEST(ACTION_TYPES.FETCH_PATIENTDOCTORPRESCRIPTION_LIST):
    case REQUEST(ACTION_TYPES.FETCH_PATIENTDOCTORPRESCRIPTION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_PATIENTDOCTORPRESCRIPTION):
    case REQUEST(ACTION_TYPES.UPDATE_PATIENTDOCTORPRESCRIPTION):
    case REQUEST(ACTION_TYPES.CREATE_PATIENTDOCTORPRESCRIPTION_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.UPDATE_PATIENTDOCTORPRESCRIPTION_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.DELETE_PATIENTDOCTORPRESCRIPTION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_PATIENTDOCTORPRESCRIPTION_LIST_EXPORT):
    case FAILURE(ACTION_TYPES.FETCH_PATIENTDOCTORPRESCRIPTION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_PATIENTDOCTORPRESCRIPTION):
    case FAILURE(ACTION_TYPES.CREATE_PATIENTDOCTORPRESCRIPTION):
    case FAILURE(ACTION_TYPES.UPDATE_PATIENTDOCTORPRESCRIPTION):
    case FAILURE(ACTION_TYPES.CREATE_PATIENTDOCTORPRESCRIPTION_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.UPDATE_PATIENTDOCTORPRESCRIPTION_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.DELETE_PATIENTDOCTORPRESCRIPTION):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_PATIENTDOCTORPRESCRIPTION_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_PATIENTDOCTORPRESCRIPTION_OFFSET):
      if (action.payload.data && action.payload.data.length > 0) {
        return {
          ...state,
          loading: false,
          entity: convertEntityBuffers(action.payload.data[0]),
        };
      }
      return {
        ...state,
        loading: false,
      };

    case SUCCESS(ACTION_TYPES.FETCH_PATIENTDOCTORPRESCRIPTION):
      return {
        ...state,
        loading: false,
        entity: convertEntityBuffers(action.payload.data),
      };
    case SUCCESS(ACTION_TYPES.CREATE_PATIENTDOCTORPRESCRIPTION):
    case SUCCESS(ACTION_TYPES.UPDATE_PATIENTDOCTORPRESCRIPTION):
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_PATIENTDOCTORPRESCRIPTION_AND_GO_LIST):
    case SUCCESS(ACTION_TYPES.UPDATE_PATIENTDOCTORPRESCRIPTION_AND_GO_LIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_PATIENTDOCTORPRESCRIPTION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
        patDrPrescrProcdListEmbed: [],
        patDrPrescrProcdListAllEmbed: [],
        patDrPrescrProcdSelected: state.patDrPrescrProcdSelected,
      };

    case 'patientDoctorPrescription/PATDRPRESCRPROCD_GET_ENTITIES':
      return {
        ...state,
        patDrPrescrProcdListEmbed: state.patDrPrescrProcdListAllEmbed,
      };
    case 'patientDoctorPrescription/PATDRPRESCRPROCD_CREATE_ENTITY':
      return {
        ...state,
        patDrPrescrProcdListAllEmbed: [...(state.patDrPrescrProcdListAllEmbed ? state.patDrPrescrProcdListAllEmbed : []), action.payload],
      };
    case 'patientDoctorPrescription/PATDRPRESCRPROCD_SET_ENTITIES':
      return {
        ...state,
        patDrPrescrProcdListAllEmbed: action.payload,
      };
    case 'patientDoctorPrescription/PATDRPRESCRPROCD_UPDATE_ENTITY': {
      const patDrPrescrProcdListAllEmbed = [...state.patDrPrescrProcdListAllEmbed];
      state.patDrPrescrProcdListAllEmbed.map((v, i: any) => {
        if (v.id === action.payload.id) {
          patDrPrescrProcdListAllEmbed[i] = action.payload;
        }
      });
      return {
        ...state,
        patDrPrescrProcdListAllEmbed,
        patDrPrescrProcdSelected: {},
      };
    }
    case 'patientDoctorPrescription/PATDRPRESCRPROCD_RESET':
      return {
        ...state,
        patDrPrescrProcdSelected: {},
        patDrPrescrProcdListEmbed: [],
      };
    case 'patientDoctorPrescription/PATDRPRESCRPROCD_GET_ENTITY':
      if (!!state.patDrPrescrProcdListAllEmbed && state.patDrPrescrProcdListAllEmbed.constructor === Array) {
        return {
          ...state,
          patDrPrescrProcdSelected: state.patDrPrescrProcdListAllEmbed.filter(v => v.id === action.payload).pop(),
        };
      }
      return {
        ...state,
        patDrPrescrProcdSelected: state.patDrPrescrProcdListAllEmbed,
      };
    case 'patientDoctorPrescription/PATDRPRESCRPROCD_DELETE_ENTITY':
      return {
        ...state,
        patDrPrescrProcdListAllEmbed: state.patDrPrescrProcdListAllEmbed.filter(v => v.id !== action.payload),
      };

    case ACTION_TYPES.SHOW_MODAL: {
      const openModal = state.openModal;
      openModal[action.payload.type] = action.payload.show;
      return {
        ...state,
        openModal,
      };
    }
    default:
      return state;
  }
};

const apiUrl = BASE_API_VERSION_PATH + 'patient-doctor-prescriptions';

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

// Actions

// Actions
export type ICrudGetAllActionPatientDoctorPrescription<T> = (
  observation?: any,
  patientId?: any,
  extraFilters?: {},
  page?: number,
  size?: number,
  sort?: string,
  selectFields?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getEntitiesFilter = (filters?, page?, size?, sort?, selectFields = '') => {
  const requestUrl = `${apiUrl}?page=${page >= 0 ? page : 0}&size=${size >= 0 ? size : 20}&sort=${sort ? sort : 'id,asc'}&`;
  const requestFilters = filters.map(v => v.column + '.' + v.operation + '=' + v.value).join('&');
  return {
    type: ACTION_TYPES.FETCH_PATIENTDOCTORPRESCRIPTION_LIST,
    payload: axios.get<IPatientDoctorPrescription>(`${requestUrl}${requestFilters}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getAllEntities = (filters?, sort?, selectFields = '') => {
  if (
    (actualState.totalItems > 0 && actualState.entities.length === actualState.totalItems) ||
    actionPendind.includes(ACTION_TYPES.FETCH_PATIENTDOCTORPRESCRIPTION_LIST)
  ) {
    return {
      type: ACTION_TYPES.FETCH_PATIENTDOCTORPRESCRIPTION_LIST + '_FROM_CACHE',
      payload: new Promise(function (resolve, reject) {
        resolve('done');
      }),
    };
  }

  const idsRequest = filters && typeof filters['ids'] !== 'undefined' && filters['ids'] ? `id.in=${filters['ids'].join(',')}&` : '';
  const observationRequest =
    filters && typeof filters['observation'] !== 'undefined' && filters['observation']
      ? `observation.contains=${filters['observation']}&`
      : '';
  const patientRequest =
    filters && typeof filters['patientId'] !== 'undefined' && filters['patientId'] ? `patient.id.in=${filters['patientId']}&` : '';

  const requestUrl = `${apiUrl}${`?page=${0}&size=${1000000}&sort=${sort ? sort : 'id,asc'}&`}`;
  return {
    type: ACTION_TYPES.FETCH_PATIENTDOCTORPRESCRIPTION_LIST,
    payload: axios.get<IPatientDoctorPrescription>(`${requestUrl}${idsRequest}${observationRequest}${patientRequest}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntities: ICrudGetAllActionPatientDoctorPrescription<IPatientDoctorPrescription> = (
  observation,
  patientId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const observationRequest = observation ? `observation.contains=${observation}&` : '';
  const patientRequest = patientId ? `patient.id.in=${patientId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PATIENTDOCTORPRESCRIPTION_LIST,
    payload: axios.get<IPatientDoctorPrescription>(`${requestUrl}${extraFiltersRequest}${observationRequest}${patientRequest}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntityOffset: any = (observation, patientId, extraFilters, offset, sort, selectFields = '') => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const observationRequest = observation ? `observation.contains=${observation}&` : '';
  const patientRequest = patientId ? `patient.id.in=${patientId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${offset}&size=${1}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PATIENTDOCTORPRESCRIPTION_OFFSET,
    payload: axios.get<IPatientDoctorPrescription>(`${requestUrl}${extraFiltersRequest}${observationRequest}${patientRequest}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntity: ICrudGetAction<IPatientDoctorPrescription> = (id, selectFields = '') => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_PATIENTDOCTORPRESCRIPTION,
    payload: axios.get<IPatientDoctorPrescription>(requestUrl, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntitiesExport: ICrudGetAllActionPatientDoctorPrescription<IPatientDoctorPrescription> = (
  observation,
  patientId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const observationRequest = observation
    ? Array.isArray(status)
      ? `observation.in=${observation.join(',')}&`
      : `observation.contains=${observation}&`
    : '';
  const patientRequest = patientId ? `patient.id.in=${patientId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PATIENTDOCTORPRESCRIPTION_LIST,
    payload: axios.get<IPatientDoctorPrescription>(`${requestUrl}${extraFiltersRequest}${observationRequest}${patientRequest}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const createEntity: ICrudPutAction<IPatientDoctorPrescription> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.CREATE_PATIENTDOCTORPRESCRIPTION_AND_GO_LIST
        : ACTION_TYPES.CREATE_PATIENTDOCTORPRESCRIPTION,
    payload: axios.post(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const updateEntity: ICrudPutAction<IPatientDoctorPrescription> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.UPDATE_PATIENTDOCTORPRESCRIPTION_AND_GO_LIST
        : ACTION_TYPES.UPDATE_PATIENTDOCTORPRESCRIPTION,
    payload: axios.put(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const deleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_PATIENTDOCTORPRESCRIPTION,
    payload: axios.delete(requestUrl),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const showModal = view => ({
  payload: { type: view, show: true },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const hideModal = view => ({
  payload: { type: view, show: false },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const getUrlBack = (location): string => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  let urlBack = _urlBase.searchParams.get('urlBack') || '';
  urlBack = urlBack.trim();
  urlBack = urlBack.startsWith('/') ? urlBack.slice(1) : urlBack;
  urlBack = urlBack.endsWith('/') ? urlBack.slice(0, -1) : urlBack;
  return urlBack ? '/' + urlBack + '/' : '';
};

export const getPatientDoctorPrescriptionState = (location): IPatientDoctorPrescriptionBaseState => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  const extraFilters = jsonParse(_urlBase.searchParams.get('extraFilters') || '{}');
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;

  const observation = _urlBase.searchParams.get('observation') || '';
  const patientId = _urlBase.searchParams.get('patient') || '';
  return {
    baseFilters,
    extraFilters,
    offset,
    observation,
    patientId: patientId ? patientId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
  };
};

export const getEntityFiltersURL = (state, offset = null) => {
  return (
    'baseFilters=' +
    state.baseFilters +
    '&extraFilters=' +
    encodeURI(JSON.stringify(state.extraFilters)) +
    '&page=' +
    state.activePage +
    '&' +
    'size=' +
    state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    state.sort +
    ',' +
    state.order +
    '&' +
    (state.observation ? 'observation=' + state.observation + '&' : '') +
    (state.patientId ? 'patient=' + state.patientId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    ''
  );
};

export const patDrPrescrProcdReset: any = values => ({
  payload: values,
  type: 'patientDoctorPrescription/PATDRPRESCRPROCD_RESET',
});

export const patDrPrescrProcdGetEntities: any = values => ({
  payload: values,
  type: 'patientDoctorPrescription/PATDRPRESCRPROCD_GET_ENTITIES',
});

export const patDrPrescrProcdSetEntities: any = values => async dispatch => {
  const result = await dispatch({
    payload: values,
    type: 'patientDoctorPrescription/PATDRPRESCRPROCD_SET_ENTITIES',
  });
  dispatch(patDrPrescrProcdGetEntities());
  return result;
};

export const patDrPrescrProcdCreateEntity: ICrudPutAction<IPatDrPrescrProcd> = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: { id: (new Date().getTime() * Math.random()).toString(16).split('.').join('-'), ...entity },
    type: 'patientDoctorPrescription/PATDRPRESCRPROCD_CREATE_ENTITY',
  });

  if (entity.patientDoctorPrescription === 'is_new') {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(patDrPrescrProcdReset());
      dispatch(patDrPrescrProcdGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};

export const patDrPrescrProcdUpdateEntity: ICrudPutAction<IPatDrPrescrProcd> = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: entity,
    type: 'patientDoctorPrescription/PATDRPRESCRPROCD_UPDATE_ENTITY',
  });
  if (entity.patientDoctorPrescription === 'is_new') {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(patDrPrescrProcdReset());
      dispatch(patDrPrescrProcdGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};
export const patDrPrescrProcdGetEntity: any = values => ({
  payload: values,
  type: 'patientDoctorPrescription/PATDRPRESCRPROCD_GET_ENTITY',
});

export const patDrPrescrProcdDeleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: id,
    type: 'patientDoctorPrescription/PATDRPRESCRPROCD_DELETE_ENTITY',
  });
  if (isNaN(id)) {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(patDrPrescrProcdReset());
      dispatch(patDrPrescrProcdGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};
