/* eslint complexity: ["error", 500] */
/* eslint no-empty: off */
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import SelectAsync from 'react-select/async';

import { Link, RouteComponentProps } from 'react-router-dom';
import InputMask from 'react-input-mask';
import ReactQuill from 'react-quill';
import CurrencyInput from 'react-currency-input';
import {
  quillEditorModules,
  quillEditorFormats,
  getFilterFromSelect,
  getFormFromSelect,
  getListAxios,
  showFieldsSelectAsync,
} from 'app/shared/util/entity-utils';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Col, Label } from 'reactstrap';
import { setFileData } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import {
  IClientUpdateState as IBaseUpdateState,
  getEntity,
  getClientState,
  getUrlBack,
  updateEntity,
  createEntity,
  getEntityFiltersURL,
  getEntityOffset,
  setBlob,
  reset,
  clientContactSetEntities,
  clientContactGetEntities,
  clientContactUpdateEntity,
  clientContactReset,
  clientContactGetEntity,
  clientContactCreateEntity,
  clientContactDeleteEntity,
} from './client.reducer';

import { convertDateTimeToServer } from 'app/shared/util/date-utils';
import { viacepRequest } from 'app/shared/util/entity-utils';

import {
  mapStateToProps as mapStateToPropsClientContact,
  mapDispatchToProps as mapDispatchToPropsClientContact,
  ClientContact,
} from 'app/entities/client-contact/client-contact';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';
import moment from 'moment';
registerLocale('pt', pt);

export interface IClientUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}
export type IClientUpdateState = IBaseUpdateState;

export class ClientUpdate extends React.Component<IClientUpdateProps, IClientUpdateState> {
  socialReasonFileInput: any;

  fantasyNameFileInput: any;

  cnpjFileInput: any;

  ieFileInput: any;

  statusFileInput: any;

  zipCodeFileInput: any;

  streetFileInput: any;

  complementFileInput: any;

  numberFileInput: any;

  neighborhoodFileInput: any;

  cityFileInput: any;

  ufFileInput: any;

  codAnsFileInput: any;

  codCnesFileInput: any;

  codEmpNoProvedorFileInput: any;

  contractSignatureDateFileInput: any;

  expectedEmissionNfDayFileInput: any;

  expectedBillingDayFileInput: any;

  expectedReceiptDateFileInput: any;

  billingRulesFileInput: any;

  observationsFileInput: any;

  xmlBillingTypesFileInput: any;

  xmlSimpleExportFileInput: any;

  referenceFileInput: any;
  constructor(props: Readonly<IClientUpdateProps>) {
    super(props);

    this.clientContactUpdateEntity = this.clientContactUpdateEntity.bind(this);
    this.clientContactGetEntities = this.clientContactGetEntities.bind(this);
    this.clientContactReset = this.clientContactReset.bind(this);
    this.clientContactGetEntity = this.clientContactGetEntity.bind(this);
    this.clientContactCreateEntity = this.clientContactCreateEntity.bind(this);
    this.clientContactDeleteEntity = this.clientContactDeleteEntity.bind(this);

    this.observationsFileInput = React.createRef();

    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      functionEmbebed: [],

      patientSelectValue: null,

      clientContactSelectValue: null,

      clientStatusHistorySelectValue: null,

      billingXmlSelectValue: null,

      clientFileSelectValue: null,

      clComTableSelectValue: null,

      pushSelectValue: null,

      segmentSelectValue: null,

      cepRequestCep: null,
      cepRequestLogradouro: null,

      cepRequestBairro: null,
      cepRequestLocalidade: null,
      cepRequestUf: null,

      socialReasonSelectValue: null,
      fantasyNameSelectValue: null,
      cnpjSelectValue: null,
      ieSelectValue: null,
      statusSelectValue: null,
      zipCodeSelectValue: null,
      streetSelectValue: null,
      complementSelectValue: null,
      numberSelectValue: null,
      neighborhoodSelectValue: null,
      citySelectValue: null,
      ufSelectValue: null,
      codAnsSelectValue: null,
      codCnesSelectValue: null,
      codEmpNoProvedorSelectValue: null,
      contractSignatureDateSelectValue: null,
      expectedEmissionNfDaySelectValue: null,
      expectedBillingDaySelectValue: null,
      expectedReceiptDateSelectValue: null,
      billingRulesSelectValue: null,
      observationsSelectValue: null,
      xmlBillingTypesSelectValue: null,
      xmlSimpleExportSelectValue: null,
      referenceSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getClientState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
      activeTab: 0,
      segmentName: null,

      isNew: !this.props.match.params || !this.props.match.params['id'],
    };
  }
  toggleTab(tab: number) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }
  tabsNext(tab: number, tabAtual?: number) {
    if (typeof tabAtual !== 'undefined' && tabAtual === 0) {
      if (!this.state.socialReasonSelectValue) {
        toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.client.socialReason') }));
        return false;
      }

      if (!this.state.fantasyNameSelectValue) {
        toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.client.fantasyName') }));
        return false;
      }

      if (!this.state.cnpjSelectValue) {
        toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.client.cnpj') }));
        return false;
      }
    }
    if (typeof tabAtual !== 'undefined' && tabAtual === 1) {
      if (!this.state.cepRequestCep) {
        toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.client.zipCode') }));
        return false;
      }

      if (!this.state.numberSelectValue) {
        toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.client.number') }));
        return false;
      }
    }
    if (typeof tabAtual !== 'undefined' && tabAtual === 2) {
    }
    if (typeof tabAtual !== 'undefined' && tabAtual === 3) {
    }
    this.toggleTab(tab);
  }

  clientContactUpdateEntity(...values) {
    values[0]['client'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['updateEntityClientContact'](...values);
    }
    return this.props.clientContactUpdateEntity(...values);
  }
  clientContactGetEntities(...values) {
    return this.props.clientContactGetEntities(...values);
  }
  clientContactReset(...values) {
    return this.props.clientContactReset(...values);
  }
  clientContactGetEntity(...values) {
    return this.props.clientContactGetEntity(...values);
  }
  clientContactCreateEntity(...values) {
    values[0]['client'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['createEntityClientContact'](...values);
    }
    return this.props.clientContactCreateEntity(...values);
  }
  clientContactDeleteEntity(...values) {
    if (!this.state.isNew) {
      this.props['deleteEntityClientContact'](...values);
    }
    return this.props.clientContactDeleteEntity(...values);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateSuccess !== this.props.updateSuccess && prevProps.updateSuccess) {
      this.handleClose();
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.props.patients.length > 0 && this.state.patientSelectValue === null && this.props.clientEntity.patient)
    ) {
      this.setState({
        patientSelectValue:
          this.props.clientEntity && this.props.clientEntity.patient
            ? this.props.clientEntity.patient.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.props.clientContacts.length > 0 && this.state.clientContactSelectValue === null && this.props.clientEntity.clientContact)
    ) {
      this.setState({ clientContactSelectValue: this.props.clientEntity.clientContact });
      this.props.clientContactSetEntities(this.props.clientEntity.clientContact);
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.props.clientStatusHistories.length > 0 &&
        this.state.clientStatusHistorySelectValue === null &&
        this.props.clientEntity.clientStatusHistory)
    ) {
      this.setState({
        clientStatusHistorySelectValue:
          this.props.clientEntity && this.props.clientEntity.clientStatusHistory
            ? this.props.clientEntity.clientStatusHistory.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.props.billingXmls.length > 0 && this.state.billingXmlSelectValue === null && this.props.clientEntity.billingXml)
    ) {
      this.setState({
        billingXmlSelectValue:
          this.props.clientEntity && this.props.clientEntity.billingXml
            ? this.props.clientEntity.billingXml.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.props.clientFiles.length > 0 && this.state.clientFileSelectValue === null && this.props.clientEntity.clientFile)
    ) {
      this.setState({
        clientFileSelectValue:
          this.props.clientEntity && this.props.clientEntity.clientFile
            ? this.props.clientEntity.clientFile.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.props.clComTables.length > 0 && this.state.clComTableSelectValue === null && this.props.clientEntity.clComTable)
    ) {
      this.setState({
        clComTableSelectValue:
          this.props.clientEntity && this.props.clientEntity.clComTable
            ? this.props.clientEntity.clComTable.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.props.pushes.length > 0 && this.state.pushSelectValue === null && this.props.clientEntity.push)
    ) {
      this.setState({
        pushSelectValue:
          this.props.clientEntity && this.props.clientEntity.push
            ? this.props.clientEntity.push.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.props.segments &&
        this.state.segmentSelectValue === null &&
        this.props.clientEntity.segment &&
        this.props.clientEntity.segment.id)
    ) {
      const p = this.props.clientEntity && this.props.clientEntity.segment ? this.props.clientEntity.segment : null;
      this.setState({
        segmentSelectValue: p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : null,
      });
    }

    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.props.clientEntity.socialReason &&
        this.state.socialReasonSelectValue === null &&
        this.props.clientEntity.socialReason !== this.state.socialReasonSelectValue)
    ) {
      this.setState({ socialReasonSelectValue: this.props.clientEntity.socialReason ? this.props.clientEntity.socialReason : null });
    }
    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.props.clientEntity.fantasyName &&
        this.state.fantasyNameSelectValue === null &&
        this.props.clientEntity.fantasyName !== this.state.fantasyNameSelectValue)
    ) {
      this.setState({ fantasyNameSelectValue: this.props.clientEntity.fantasyName ? this.props.clientEntity.fantasyName : null });
    }
    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.props.clientEntity.cnpj && this.state.cnpjSelectValue === null && this.props.clientEntity.cnpj !== this.state.cnpjSelectValue)
    ) {
      this.setState({ cnpjSelectValue: this.props.clientEntity.cnpj ? this.props.clientEntity.cnpj : null });
    }
    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.props.clientEntity.ie && this.state.ieSelectValue === null && this.props.clientEntity.ie !== this.state.ieSelectValue)
    ) {
      this.setState({ ieSelectValue: this.props.clientEntity.ie ? this.props.clientEntity.ie : null });
    }
    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.props.clientEntity.status &&
        this.state.statusSelectValue === null &&
        this.props.clientEntity.status !== this.state.statusSelectValue)
    ) {
      this.setState({ statusSelectValue: this.props.clientEntity.status ? this.props.clientEntity.status : null });
    }
    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.props.clientEntity.zipCode && this.state.cepRequestCep === null)
    ) {
      this.setState({ cepRequestCep: this.props.clientEntity.zipCode });
      // this.setState({ zipCodeSelectValue: this.props.clientEntity.zipCode })
    }
    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.props.clientEntity.zipCode &&
        this.state.zipCodeSelectValue === null &&
        this.props.clientEntity.zipCode !== this.state.zipCodeSelectValue)
    ) {
      this.setState({ zipCodeSelectValue: this.props.clientEntity.zipCode ? this.props.clientEntity.zipCode : null });
    }
    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.props.clientEntity.street && this.state.cepRequestLogradouro === null)
    ) {
      this.setState({ cepRequestLogradouro: this.props.clientEntity.street });
      // this.setState({ streetSelectValue: this.props.clientEntity.street })
    }
    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.props.clientEntity.street &&
        this.state.streetSelectValue === null &&
        this.props.clientEntity.street !== this.state.streetSelectValue)
    ) {
      this.setState({ streetSelectValue: this.props.clientEntity.street ? this.props.clientEntity.street : null });
    }
    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.props.clientEntity.complement &&
        this.state.complementSelectValue === null &&
        this.props.clientEntity.complement !== this.state.complementSelectValue)
    ) {
      this.setState({ complementSelectValue: this.props.clientEntity.complement ? this.props.clientEntity.complement : null });
    }
    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.props.clientEntity.number &&
        this.state.numberSelectValue === null &&
        this.props.clientEntity.number !== this.state.numberSelectValue)
    ) {
      this.setState({ numberSelectValue: this.props.clientEntity.number ? this.props.clientEntity.number : null });
    }
    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.props.clientEntity.neighborhood && this.state.cepRequestBairro === null)
    ) {
      this.setState({ cepRequestBairro: this.props.clientEntity.neighborhood });
      // this.setState({ neighborhoodSelectValue: this.props.clientEntity.neighborhood })
    }
    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.props.clientEntity.neighborhood &&
        this.state.neighborhoodSelectValue === null &&
        this.props.clientEntity.neighborhood !== this.state.neighborhoodSelectValue)
    ) {
      this.setState({ neighborhoodSelectValue: this.props.clientEntity.neighborhood ? this.props.clientEntity.neighborhood : null });
    }
    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.props.clientEntity.city && this.state.cepRequestLocalidade === null)
    ) {
      this.setState({ cepRequestLocalidade: this.props.clientEntity.city });
      // this.setState({ citySelectValue: this.props.clientEntity.city })
    }
    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.props.clientEntity.city && this.state.citySelectValue === null && this.props.clientEntity.city !== this.state.citySelectValue)
    ) {
      this.setState({ citySelectValue: this.props.clientEntity.city ? this.props.clientEntity.city : null });
    }
    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.props.clientEntity.uf && this.state.cepRequestUf === null)
    ) {
      this.setState({ cepRequestUf: this.props.clientEntity.uf });
      // this.setState({ ufSelectValue: this.props.clientEntity.uf })
    }
    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.props.clientEntity.uf && this.state.ufSelectValue === null && this.props.clientEntity.uf !== this.state.ufSelectValue)
    ) {
      this.setState({ ufSelectValue: this.props.clientEntity.uf ? this.props.clientEntity.uf : null });
    }
    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.props.clientEntity.codAns &&
        this.state.codAnsSelectValue === null &&
        this.props.clientEntity.codAns !== this.state.codAnsSelectValue)
    ) {
      this.setState({ codAnsSelectValue: this.props.clientEntity.codAns ? this.props.clientEntity.codAns : null });
    }
    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.props.clientEntity.codCnes &&
        this.state.codCnesSelectValue === null &&
        this.props.clientEntity.codCnes !== this.state.codCnesSelectValue)
    ) {
      this.setState({ codCnesSelectValue: this.props.clientEntity.codCnes ? this.props.clientEntity.codCnes : null });
    }
    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.props.clientEntity.codEmpNoProvedor &&
        this.state.codEmpNoProvedorSelectValue === null &&
        this.props.clientEntity.codEmpNoProvedor !== this.state.codEmpNoProvedorSelectValue)
    ) {
      this.setState({
        codEmpNoProvedorSelectValue: this.props.clientEntity.codEmpNoProvedor ? this.props.clientEntity.codEmpNoProvedor : null,
      });
    }
    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.props.clientEntity.contractSignatureDate &&
        this.state.contractSignatureDateSelectValue === null &&
        this.props.clientEntity.contractSignatureDate !== this.state.contractSignatureDateSelectValue)
    ) {
      this.setState({
        contractSignatureDateSelectValue: this.props.clientEntity.contractSignatureDate
          ? this.props.clientEntity.contractSignatureDate
          : null,
      });
    }
    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.props.clientEntity.expectedEmissionNfDay &&
        this.state.expectedEmissionNfDaySelectValue === null &&
        this.props.clientEntity.expectedEmissionNfDay !== this.state.expectedEmissionNfDaySelectValue)
    ) {
      this.setState({
        expectedEmissionNfDaySelectValue: this.props.clientEntity.expectedEmissionNfDay
          ? this.props.clientEntity.expectedEmissionNfDay
          : null,
      });
    }
    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.props.clientEntity.expectedBillingDay &&
        this.state.expectedBillingDaySelectValue === null &&
        this.props.clientEntity.expectedBillingDay !== this.state.expectedBillingDaySelectValue)
    ) {
      this.setState({
        expectedBillingDaySelectValue: this.props.clientEntity.expectedBillingDay ? this.props.clientEntity.expectedBillingDay : null,
      });
    }
    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.props.clientEntity.expectedReceiptDate &&
        this.state.expectedReceiptDateSelectValue === null &&
        this.props.clientEntity.expectedReceiptDate !== this.state.expectedReceiptDateSelectValue)
    ) {
      this.setState({
        expectedReceiptDateSelectValue: this.props.clientEntity.expectedReceiptDate ? this.props.clientEntity.expectedReceiptDate : null,
      });
    }
    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.props.clientEntity.billingRules &&
        this.state.billingRulesSelectValue === null &&
        this.props.clientEntity.billingRules !== this.state.billingRulesSelectValue)
    ) {
      this.setState({ billingRulesSelectValue: this.props.clientEntity.billingRules ? this.props.clientEntity.billingRules : null });
    }
    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.props.clientEntity.observations &&
        this.state.observationsSelectValue === null &&
        this.props.clientEntity.observations !== this.state.observationsSelectValue)
    ) {
      this.setState({ observationsSelectValue: this.props.clientEntity.observations ? this.props.clientEntity.observations : null });
    }
    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.props.clientEntity.xmlBillingTypes &&
        this.state.xmlBillingTypesSelectValue === null &&
        this.props.clientEntity.xmlBillingTypes !== this.state.xmlBillingTypesSelectValue)
    ) {
      this.setState({
        xmlBillingTypesSelectValue: this.props.clientEntity.xmlBillingTypes ? this.props.clientEntity.xmlBillingTypes : null,
      });
    }
    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.state.xmlSimpleExportSelectValue === null && this.props.clientEntity.xmlSimpleExport !== this.state.xmlSimpleExportSelectValue)
    ) {
      this.setState({
        xmlSimpleExportSelectValue: this.props.clientEntity.xmlSimpleExport ? this.props.clientEntity.xmlSimpleExport : false,
      });
    }
    if (
      (prevProps.clientEntity && prevProps.clientEntity.id !== this.props.clientEntity.id) ||
      (this.props.clientEntity.reference &&
        this.state.referenceSelectValue === null &&
        this.props.clientEntity.reference !== this.state.referenceSelectValue)
    ) {
      this.setState({ referenceSelectValue: this.props.clientEntity.reference ? this.props.clientEntity.reference : null });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  UNSAFE_componentWillMount() {
    if (this.state.isNew) {
      this.props.reset();
      this.clientContactReset();
    } else {
      this.props.getEntity(this.props.match.params['id']);
    }
  }

  onBlobChange = (isAnImage, name, fileInput) => event => {
    const fileName = event.target.files[0].name;
    setFileData(event, (contentType, data) => this.props.setBlob(name, data, contentType, fileName), isAnImage);
    const varAux = {};
    varAux[name + 'SelectValue'] = fileName;
    this.setState(varAux);
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined);
  };
  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = offset !== null ? '&offset=' + offset : '';
    Object.keys(fieldsBase).map(key => {
      url += key !== 'offset' ? '&' + key + '=' + fieldsBase[key] : '';
    });
    return url;
  };
  saveEntity = (event: any, errors: any, values: any) => {
    if (!this.state.socialReasonSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.client.socialReason') }));
      return false;
    }

    if (!this.state.fantasyNameSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.client.fantasyName') }));
      return false;
    }

    if (!this.state.cnpjSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.client.cnpj') }));
      return false;
    }

    if (!this.state.cepRequestCep) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.client.zipCode') }));
      return false;
    }

    if (!this.state.numberSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.client.number') }));
      return false;
    }

    const selectFieldsList = ['socialReason', 'fantasyName', 'segment.name', 'status'];

    values.contractSignatureDate = convertDateTimeToServer(values.contractSignatureDate);

    if (!this.state.cepRequestCep) {
      errors.push(translate('entity.validation.required'));
    }

    if (errors.length === 0) {
      const { clientEntity } = this.props;
      const _entity = {
        ...clientEntity,
        // ...values,

        segment: getFormFromSelect(this.state.segmentSelectValue, 'many-to-one'), // many-to-one - otherSide

        patient: getFormFromSelect(this.state.patientSelectValue, 'one-to-many'), // one-to-many - otherSide

        clientContact: undefined,

        socialReason: this.state.socialReasonSelectValue,
        fantasyName: this.state.fantasyNameSelectValue,
        cnpj: this.state.cnpjSelectValue,
        ie: this.state.ieSelectValue,
        status: this.state.statusSelectValue === null ? 'ATIVO' : this.state.statusSelectValue,
        zipCode: this.state.cepRequestCep,
        street: this.state.cepRequestLogradouro,
        complement: this.state.complementSelectValue,
        number: this.state.numberSelectValue,
        neighborhood: this.state.cepRequestBairro,
        city: this.state.cepRequestLocalidade,
        uf: this.state.cepRequestUf,
        codAns: this.state.codAnsSelectValue,
        codCnes: this.state.codCnesSelectValue,
        codEmpNoProvedor: this.state.codEmpNoProvedorSelectValue,
        contractSignatureDate: this.state.contractSignatureDateSelectValue,
        expectedEmissionNfDay: this.state.expectedEmissionNfDaySelectValue,
        expectedBillingDay: this.state.expectedBillingDaySelectValue,
        expectedReceiptDate: this.state.expectedReceiptDateSelectValue,
        billingRules: this.state.billingRulesSelectValue,
        observations: this.state.observationsSelectValue,
        xmlBillingTypes: this.state.xmlBillingTypesSelectValue === null ? null : this.state.xmlBillingTypesSelectValue,
        xmlSimpleExport: this.state.xmlSimpleExportSelectValue === null ? false : this.state.xmlSimpleExportSelectValue,
        reference: this.state.referenceSelectValue,
      };

      const entity = _entity;

      const {
        socialReason,
        fantasyName,
        cnpj,
        status,
        zipCode,
        city,
        uf,
        segmentId,
        extraFilters,
        activePage,
        itemsPerPage,
        sort,
        order,
      } = this.state.fieldsBase;

      const clientContactListAllEmbed = this.props.clientContactListAllEmbed;

      if (this.state.isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              socialReason,
              fantasyName,
              cnpj,
              status,
              zipCode,
              city,
              uf,
              getFilterFromSelect(segmentId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
          if (idEntity) {
            clientContactListAllEmbed.map(v => {
              v['id'] = null;
              v['client'] = { id: idEntity };
              this.props['createEntityClientContact'](v);
            });
          }
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              socialReason,
              fantasyName,
              cnpj,
              status,
              zipCode,
              city,
              uf,
              getFilterFromSelect(segmentId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/client?' + getEntityFiltersURL(this.state.fieldsBase));
  };

  getFullEntityFromState(v) {
    return {
      id: v.id,
      socialReason: this.state.socialReasonSelectValue,
      fantasyName: this.state.fantasyNameSelectValue,
      cnpj: this.state.cnpjSelectValue,
      ie: this.state.ieSelectValue,
      status: this.state.statusSelectValue,
      zipCode: this.state.zipCodeSelectValue,
      street: this.state.streetSelectValue,
      complement: this.state.complementSelectValue,
      number: this.state.numberSelectValue,
      neighborhood: this.state.neighborhoodSelectValue,
      city: this.state.citySelectValue,
      uf: this.state.ufSelectValue,
      codAns: this.state.codAnsSelectValue,
      codCnes: this.state.codCnesSelectValue,
      codEmpNoProvedor: this.state.codEmpNoProvedorSelectValue,
      contractSignatureDate: this.state.contractSignatureDateSelectValue,
      expectedEmissionNfDay: this.state.expectedEmissionNfDaySelectValue,
      expectedBillingDay: this.state.expectedBillingDaySelectValue,
      expectedReceiptDate: this.state.expectedReceiptDateSelectValue,
      billingRules: this.state.billingRulesSelectValue,
      observations: this.state.observationsSelectValue,
      xmlBillingTypes: this.state.xmlBillingTypesSelectValue,
      xmlSimpleExport: this.state.xmlSimpleExportSelectValue,
      reference: this.state.referenceSelectValue,
      patient: this.state.patientSelectValue,
      clientContact: this.state.clientContactSelectValue,
      clientStatusHistory: this.state.clientStatusHistorySelectValue,
      billingXml: this.state.billingXmlSelectValue,
      clientFile: this.state.clientFileSelectValue,
      clComTable: this.state.clComTableSelectValue,
      push: this.state.pushSelectValue,
      segment: this.state.segmentSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    const patientEntity = v.patient;

    const clientContactEntity = v.clientContact;

    const clientStatusHistoryEntity = v.clientStatusHistory;

    const billingXmlEntity = v.billingXml;

    const clientFileEntity = v.clientFile;

    const clComTableEntity = v.clComTable;

    const pushEntity = v.push;

    const segmentEntity = v.segment;

    return {
      socialReasonSelectValue: v.socialReason,
      fantasyNameSelectValue: v.fantasyName,
      cnpjSelectValue: v.cnpj,
      ieSelectValue: v.ie,
      statusSelectValue: v.status,
      zipCodeSelectValue: v.zipCode,
      streetSelectValue: v.street,
      complementSelectValue: v.complement,
      numberSelectValue: v.number,
      neighborhoodSelectValue: v.neighborhood,
      citySelectValue: v.city,
      ufSelectValue: v.uf,
      codAnsSelectValue: v.codAns,
      codCnesSelectValue: v.codCnes,
      codEmpNoProvedorSelectValue: v.codEmpNoProvedor,
      contractSignatureDateSelectValue: v.contractSignatureDate,
      expectedEmissionNfDaySelectValue: v.expectedEmissionNfDay,
      expectedBillingDaySelectValue: v.expectedBillingDay,
      expectedReceiptDateSelectValue: v.expectedReceiptDate,
      billingRulesSelectValue: v.billingRules,
      observationsSelectValue: v.observations,
      xmlBillingTypesSelectValue: v.xmlBillingTypes,
      xmlSimpleExportSelectValue: v.xmlSimpleExport,
      referenceSelectValue: v.reference,
      patientSelectValue: patientEntity ? patientEntity['id'] : null,
      clientContactSelectValue: clientContactEntity ? clientContactEntity['id'] : null,
      clientStatusHistorySelectValue: clientStatusHistoryEntity ? clientStatusHistoryEntity['id'] : null,
      billingXmlSelectValue: billingXmlEntity ? billingXmlEntity['id'] : null,
      clientFileSelectValue: clientFileEntity ? clientFileEntity['id'] : null,
      clComTableSelectValue: clComTableEntity ? clComTableEntity['id'] : null,
      pushSelectValue: pushEntity ? pushEntity['id'] : null,
      segmentSelectValue: segmentEntity ? segmentEntity['id'] : null,
    };
  }

  renderHeader() {
    const {
      clientEntity,
      patients,
      clientContacts,
      clientStatusHistories,
      billingXmls,
      clientFiles,
      clComTables,
      pushes,
      segments,
      loading,
      updating,
    } = this.props;
    const { isNew } = this.state;

    const { observations } = clientEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.client.home.createLabel">Create a Client</Translate>
            ) : (
              <Translate contentKey="generadorApp.client.home.editLabel">Edit a Client</Translate>
            )}
          </span>

          <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.client.btnSave">Save</Translate>
          </Button>

          {!this.state.isNew ? (
            <>
              <Button
                color="primary"
                id="next-entity"
                disabled={updating}
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.socialReason,
                    this.state.fieldsBase.fantasyName,
                    this.state.fieldsBase.cnpj,
                    this.state.fieldsBase.status,
                    this.state.fieldsBase.zipCode,
                    this.state.fieldsBase.city,
                    this.state.fieldsBase.uf,
                    this.state.fieldsBase.segmentId,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 + 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 + 1 },
                    });
                    this.props.history.push(
                      '/client/' + myresult.value.data[0]['id'] + '/edit?' + this.getFiltersURL(this.state.fieldsBase.offset * 1 + 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.last.item', { fieldName: translate('generadorApp.client.detail.title') }));
                  }
                }}
                className="float-right jh-create-entity"
              >
                {'>>'}
              </Button>

              <Button
                color="primary"
                id="prev-entity"
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.socialReason,
                    this.state.fieldsBase.fantasyName,
                    this.state.fieldsBase.cnpj,
                    this.state.fieldsBase.status,
                    this.state.fieldsBase.zipCode,
                    this.state.fieldsBase.city,
                    this.state.fieldsBase.uf,
                    this.state.fieldsBase.segmentId,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 - 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 - 1 },
                    });
                    this.props.history.push(
                      '/client/' + myresult.value.data[0]['id'] + '/edit?' + this.getFiltersURL(this.state.fieldsBase.offset * 1 - 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.first.item', { fieldName: translate('generadorApp.client.detail.title') }));
                  }
                }}
                disabled={updating}
                className="float-right jh-create-entity"
              >
                {'<<'}
              </Button>
            </>
          ) : (
            <> </>
          )}

          <Button
            tag={Link}
            id="cancel-save"
            to={'/client?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.client.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={'/client'}>
              <Translate contentKey="generadorApp.client.home.title">Clients</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.client.detail.title">Client edit</Translate>
          </li>
        </ol>

        {!isNew ? (
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === 0 })}
                onClick={() => {
                  this.tabsNext(0);
                }}
              >
                <span className="d-sm-none">
                  <Translate contentKey="generadorApp.client.CompanyInformation">CompanyInformation</Translate>
                </span>
                <span className="d-sm-block d-none">
                  <Translate contentKey="generadorApp.client.CompanyInformation">CompanyInformation</Translate>
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === 1 })}
                onClick={() => {
                  this.tabsNext(1);
                }}
              >
                <span className="d-sm-none">
                  <Translate contentKey="generadorApp.client.CommercialInformation">CommercialInformation</Translate>
                </span>
                <span className="d-sm-block d-none">
                  <Translate contentKey="generadorApp.client.CommercialInformation">CommercialInformation</Translate>
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === 2 })}
                onClick={() => {
                  this.tabsNext(2);
                }}
              >
                <span className="d-sm-none">
                  <Translate contentKey="generadorApp.client.ClientContacts">ClientContacts</Translate>
                </span>
                <span className="d-sm-block d-none">
                  <Translate contentKey="generadorApp.client.ClientContacts">ClientContacts</Translate>
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === 3 })}
                onClick={() => {
                  this.tabsNext(3);
                }}
              >
                <span className="d-sm-none">
                  <Translate contentKey="generadorApp.client.OtherInformation">OtherInformation</Translate>
                </span>
                <span className="d-sm-block d-none">
                  <Translate contentKey="generadorApp.client.OtherInformation">OtherInformation</Translate>
                </span>
              </NavLink>
            </NavItem>
          </Nav>
        ) : null}
      </>
    );
  }
  renderBody() {
    const {
      clientEntity,
      patients,
      clientContacts,
      clientStatusHistories,
      billingXmls,
      clientFiles,
      clComTables,
      pushes,
      segments,
      loading,
      updating,
    } = this.props;
    const { isNew } = this.state;

    const { observations } = clientEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const fullEntityEmbebed = this.getFullEntityFromState(clientEntity);

    const mapDispatchToPropsClientContactList: any = {};
    Object.keys(mapDispatchToPropsClientContact).map(v => {
      mapDispatchToPropsClientContactList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'ClientContact')) {
          this.setState({ functionEmbebed: [...this.state.functionEmbebed, v + 'ClientContact'] }, this.props[v + 'ClientContact']);
        }
      };
    });

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="client-id">
                  <Translate contentKey="generadorApp.client.field_id">ID</Translate>
                </Label>
                </Col> */}
                      <Col md="12">
                        <AvInput id="client-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId={0}>
                    {isNew ? (
                      <Row className="justify-content-center mb-3">
                        <Col md="12">
                          <h2 id="generadorApp.client.home.formTabs_25">
                            <Translate contentKey="generadorApp.client.formTabs.CompanyInformation">CompanyInformation</Translate>
                          </h2>
                        </Col>
                      </Row>
                    ) : null}

                    <Row>
                      {baseFilters !== 'socialReason' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="socialReasonLabel" for="client-socialReason">
                                  <Translate contentKey="generadorApp.client.socialReason">Social Reason</Translate>(*)
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  onChange={evt => this.setState({ socialReasonSelectValue: evt.target.value })}
                                  value={this.state.socialReasonSelectValue ? this.state.socialReasonSelectValue : ''}
                                  id="client-socialReason"
                                  type="text"
                                  name="socialReason"
                                  className={'form-control'}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ socialReasonSelectValue: evt.target.value })}
                          type="hidden"
                          name="socialReason"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}
                      {baseFilters !== 'fantasyName' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="fantasyNameLabel" for="client-fantasyName">
                                  <Translate contentKey="generadorApp.client.fantasyName">Fantasy Name</Translate>(*)
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  onChange={evt => this.setState({ fantasyNameSelectValue: evt.target.value })}
                                  value={this.state.fantasyNameSelectValue ? this.state.fantasyNameSelectValue : ''}
                                  id="client-fantasyName"
                                  type="text"
                                  name="fantasyName"
                                  className={'form-control'}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ fantasyNameSelectValue: evt.target.value })}
                          type="hidden"
                          name="fantasyName"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}
                      {baseFilters !== 'cnpj' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="cnpjLabel" for="client-cnpj">
                                  <Translate contentKey="generadorApp.client.cnpj">CNPJ</Translate>(*)
                                </Label>
                              </Col>
                              <Col md="12">
                                <InputMask
                                  onChange={evt => this.setState({ cnpjSelectValue: evt.target.value })}
                                  value={this.state.cnpjSelectValue ? this.state.cnpjSelectValue : ''}
                                  id="client-cnpj"
                                  type="text"
                                  name="cnpj"
                                  className={'form-control'}
                                  mask="99.999.999/9999-99"
                                  tag={InputMask}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ cnpjSelectValue: evt.target.value })}
                          type="hidden"
                          name="cnpj"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}
                      {baseFilters !== 'ie' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="ieLabel" for="client-ie">
                                  <Translate contentKey="generadorApp.client.ie">IE</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  onChange={evt => this.setState({ ieSelectValue: evt.target.value })}
                                  value={this.state.ieSelectValue ? this.state.ieSelectValue : ''}
                                  id="client-ie"
                                  type="text"
                                  name="ie"
                                  className={'form-control'}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ ieSelectValue: evt.target.value })}
                          type="hidden"
                          name="ie"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}
                      {baseFilters !== 'segment' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" for="client-segment">
                                  <Translate contentKey="generadorApp.client.segment">Segment</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <SelectAsync
                                  id="client-segment"
                                  name={'segment'}
                                  className={'css-select-control'}
                                  data-type-rel="many-to-one-other-side"
                                  value={this.state.segmentSelectValue ? this.state.segmentSelectValue : ''}
                                  onChange={options => this.setState({ segmentSelectValue: options })}
                                  defaultOptions={this.state.segmentStartSelectOptions ? this.state.segmentStartSelectOptions : []}
                                  loadingMessage={input => translate('selectAsync.loadingMessage')}
                                  noOptionsMessage={input =>
                                    this.state.segmentStartSelectOptions === undefined
                                      ? translate('selectAsync.loadingMessage')
                                      : translate('selectAsync.noOptionsMessage')
                                  }
                                  onMenuOpen={async () => {
                                    if (this.state.segmentStartSelectOptions === undefined) {
                                      const result = await getListAxios('segments', {}, 0, 100, 'name,asc', 'name');
                                      this.setState({
                                        segmentStartSelectOptions: result.data
                                          ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                          : [],
                                      });
                                    }
                                  }}
                                  loadOptions={async (inputValue, callback) => {
                                    const result = await getListAxios(
                                      'segments',
                                      { 'name.contains': inputValue },
                                      0,
                                      100,
                                      'name,asc',
                                      'name'
                                    );
                                    callback(
                                      result.data
                                        ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                        : []
                                    );
                                  }}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          type="hidden"
                          name="segment"
                          value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                        />
                      )}{' '}
                    </Row>

                    {isNew ? (
                      <Button
                        color="primary"
                        className={'float-right jh-create-entity'}
                        onClick={() => {
                          this.tabsNext(1, 0);
                        }}
                      >
                        <span className="d-sm-none">
                          <Translate contentKey="generadorApp.client.formTabs.btnCommercialInformation">CommercialInformation</Translate>
                        </span>
                        <span className="d-sm-block d-none">
                          <Translate contentKey="generadorApp.client.formTabs.btnCommercialInformation">CommercialInformation</Translate>
                          &nbsp;
                          <FontAwesomeIcon icon="arrow-right" />
                        </span>
                      </Button>
                    ) : null}
                  </TabPane>
                  <TabPane tabId={1}>
                    {isNew ? (
                      <Row className="justify-content-center mb-3">
                        <Col md="12">
                          <h2 id="generadorApp.client.home.formTabs_25">
                            <Translate contentKey="generadorApp.client.formTabs.CommercialInformation">CommercialInformation</Translate>
                          </h2>
                        </Col>
                      </Row>
                    ) : null}

                    <Row>
                      {baseFilters !== 'zipCode' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label
                                  className={'mt-2' + (this.state.cepRequestCep === '' ? ' text-danger' : '')}
                                  id="zipCodeLabel"
                                  for="client-zipCode"
                                >
                                  <Translate contentKey="generadorApp.client.zipCode">Zip Code</Translate>(*)
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  id="client-zipCode"
                                  type="text"
                                  name="zipCode"
                                  className={
                                    'form-control' +
                                    (this.state.cepRequestCep === '' ? ' is-touched is-pristine av-invalid is-invalid' : '')
                                  }
                                  value={this.state.cepRequestCep}
                                  onBlur={inputVal => {
                                    viacepRequest(inputVal.target.value, (cepRequest: any) => {
                                      this.setState(cepRequest);
                                    });
                                    this.setState({ zipCodeSelectValue: inputVal.target.value });
                                    this.setState({ cepRequestCep: inputVal.target.value ? inputVal.target.value : '' });
                                  }}
                                  onChange={inputVal => {
                                    this.setState({ cepRequestCep: inputVal.target.value });
                                    this.setState({ zipCodeSelectValue: inputVal.target.value });
                                  }}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ zipCodeSelectValue: evt.target.value })}
                          type="hidden"
                          name="zipCode"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'street' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className={'mt-2'} id="streetLabel" for="client-street">
                                  <Translate contentKey="generadorApp.client.street">Street</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  id="client-street"
                                  type="text"
                                  name="street"
                                  className={'form-control'}
                                  value={this.state.cepRequestLogradouro}
                                  onBlur={inputVal =>
                                    this.setState({ cepRequestLogradouro: inputVal.target.value ? inputVal.target.value : '' })
                                  }
                                  onChange={inputVal => {
                                    this.setState({ cepRequestLogradouro: inputVal.target.value });
                                    this.setState({ streetSelectValue: inputVal.target.value });
                                  }}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ streetSelectValue: evt.target.value })}
                          type="hidden"
                          name="street"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'complement' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="complementLabel" for="client-complement">
                                  <Translate contentKey="generadorApp.client.complement">Complement</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  onChange={evt => this.setState({ complementSelectValue: evt.target.value })}
                                  value={this.state.complementSelectValue ? this.state.complementSelectValue : ''}
                                  id="client-complement"
                                  type="text"
                                  name="complement"
                                  className={'form-control'}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ complementSelectValue: evt.target.value })}
                          type="hidden"
                          name="complement"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'number' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="numberLabel" for="client-number">
                                  <Translate contentKey="generadorApp.client.number">Number</Translate>(*)
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  onChange={evt => this.setState({ numberSelectValue: evt.target.value })}
                                  value={this.state.numberSelectValue ? this.state.numberSelectValue : ''}
                                  id="client-number"
                                  type="text"
                                  name="number"
                                  className={'form-control'}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ numberSelectValue: evt.target.value })}
                          type="hidden"
                          name="number"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'neighborhood' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className={'mt-2'} id="neighborhoodLabel" for="client-neighborhood">
                                  <Translate contentKey="generadorApp.client.neighborhood">Neighborhood</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  id="client-neighborhood"
                                  type="text"
                                  name="neighborhood"
                                  className={'form-control'}
                                  value={this.state.cepRequestBairro}
                                  onBlur={inputVal =>
                                    this.setState({ cepRequestBairro: inputVal.target.value ? inputVal.target.value : '' })
                                  }
                                  onChange={inputVal => {
                                    this.setState({ cepRequestBairro: inputVal.target.value });
                                    this.setState({ neighborhoodSelectValue: inputVal.target.value });
                                  }}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ neighborhoodSelectValue: evt.target.value })}
                          type="hidden"
                          name="neighborhood"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'reference' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="referenceLabel" for="client-reference">
                                  <Translate contentKey="generadorApp.client.reference">Reference</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  onChange={evt => this.setState({ referenceSelectValue: evt.target.value })}
                                  value={this.state.referenceSelectValue ? this.state.referenceSelectValue : ''}
                                  id="client-reference"
                                  type="text"
                                  name="reference"
                                  className={'form-control'}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ referenceSelectValue: evt.target.value })}
                          type="hidden"
                          name="reference"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'city' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className={'mt-2'} id="cityLabel" for="client-city">
                                  <Translate contentKey="generadorApp.client.city">City</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  id="client-city"
                                  type="text"
                                  name="city"
                                  className={'form-control'}
                                  value={this.state.cepRequestLocalidade}
                                  onBlur={inputVal =>
                                    this.setState({ cepRequestLocalidade: inputVal.target.value ? inputVal.target.value : '' })
                                  }
                                  onChange={inputVal => {
                                    this.setState({ cepRequestLocalidade: inputVal.target.value });
                                    this.setState({ citySelectValue: inputVal.target.value });
                                  }}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ citySelectValue: evt.target.value })}
                          type="hidden"
                          name="city"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'uf' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className={'mt-2'} id="ufLabel" for="client-uf">
                                  <Translate contentKey="generadorApp.client.uf">UF</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  id="client-uf"
                                  type="text"
                                  name="uf"
                                  className={'form-control'}
                                  value={this.state.cepRequestUf}
                                  onBlur={inputVal => this.setState({ cepRequestUf: inputVal.target.value ? inputVal.target.value : '' })}
                                  onChange={inputVal => {
                                    this.setState({ cepRequestUf: inputVal.target.value });
                                    this.setState({ ufSelectValue: inputVal.target.value });
                                  }}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ ufSelectValue: evt.target.value })}
                          type="hidden"
                          name="uf"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}
                    </Row>

                    {isNew ? (
                      <Button
                        color="primary"
                        className={'float-right jh-create-entity'}
                        onClick={() => {
                          this.tabsNext(2, 1);
                        }}
                      >
                        <span className="d-sm-none">
                          <Translate contentKey="generadorApp.client.formTabs.btnClientContacts">ClientContacts</Translate>
                        </span>
                        <span className="d-sm-block d-none">
                          <Translate contentKey="generadorApp.client.formTabs.btnClientContacts">ClientContacts</Translate>
                          &nbsp;
                          <FontAwesomeIcon icon="arrow-right" />
                        </span>
                      </Button>
                    ) : null}

                    {isNew ? (
                      <Button
                        color="info"
                        className={'float-right jh-create-entity'}
                        onClick={() => {
                          this.tabsNext(0);
                        }}
                      >
                        <span className="d-sm-none">
                          <Translate contentKey="generadorApp.client.formTabs.btnCompanyInformation">CompanyInformation</Translate>
                        </span>
                        <span className="d-sm-block d-none">
                          <FontAwesomeIcon icon="arrow-left" />
                          &nbsp;
                          <Translate contentKey="generadorApp.client.formTabs.btnCompanyInformation">CompanyInformation</Translate>
                        </span>
                      </Button>
                    ) : null}
                  </TabPane>
                  <TabPane tabId={2}>
                    {isNew ? (
                      <Row className="justify-content-center mb-3">
                        <Col md="12">
                          <h2 id="generadorApp.client.home.formTabs_25">
                            <Translate contentKey="generadorApp.client.formTabs.ClientContacts">ClientContacts</Translate>
                          </h2>
                        </Col>
                      </Row>
                    ) : null}

                    <Row>
                      {baseFilters !== 'clientContact' ? (
                        <Col md="12">
                          <div className="entityEmbebedList">
                            <ClientContact
                              entityEmbebedPai={fullEntityEmbebed}
                              history={this.props.history}
                              location={{
                                pathname: `/client/${this.props.match.params['id']}/client-contact/`,
                                search: '',
                                hash: '',
                                state: undefined,
                                key: 'q2tbhd',
                              }}
                              match={{
                                path: `/client/:idClient/client-contact/:id`,
                                url: `/client/${this.props.match.params['id']}/client-contact/`,
                                isExact: true,
                                params: {
                                  idClient: this.props.match.params['id'],
                                  url: `/client/${this.props.match.params['id']}/client-contact/`,
                                },
                              }}
                              {...this.props.mapStateToPropsClientContact}
                              {...mapDispatchToPropsClientContactList}
                              getEntityPai={(...v) => this.props.getEntity(...v)}
                              getEntities={this.clientContactGetEntities}
                              updateEntity={this.clientContactUpdateEntity}
                              getEntity={this.clientContactGetEntity}
                              createEntity={this.clientContactCreateEntity}
                              deleteEntity={this.clientContactDeleteEntity}
                              reset={this.clientContactReset}
                              clientContactList={this.props.clientContactListEmbed}
                              totalItems={this.props.clientContactListEmbed ? this.props.clientContactListEmbed.length : 0}
                              loading={this.props.loading}
                              clientContactEntity={this.props.clientContactSelected}
                            />
                          </div>
                        </Col>
                      ) : (
                        <AvInput
                          type="hidden"
                          name="clientContact"
                          value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                        />
                      )}{' '}
                    </Row>

                    {isNew ? (
                      <Button
                        color="primary"
                        className={'float-right jh-create-entity'}
                        onClick={() => {
                          this.tabsNext(3, 2);
                        }}
                      >
                        <span className="d-sm-none">
                          <Translate contentKey="generadorApp.client.formTabs.btnOtherInformation">OtherInformation</Translate>
                        </span>
                        <span className="d-sm-block d-none">
                          <Translate contentKey="generadorApp.client.formTabs.btnOtherInformation">OtherInformation</Translate>
                          &nbsp;
                          <FontAwesomeIcon icon="arrow-right" />
                        </span>
                      </Button>
                    ) : null}

                    {isNew ? (
                      <Button
                        color="info"
                        className={'float-right jh-create-entity'}
                        onClick={() => {
                          this.tabsNext(1);
                        }}
                      >
                        <span className="d-sm-none">
                          <Translate contentKey="generadorApp.client.formTabs.btnCommercialInformation">CommercialInformation</Translate>
                        </span>
                        <span className="d-sm-block d-none">
                          <FontAwesomeIcon icon="arrow-left" />
                          &nbsp;
                          <Translate contentKey="generadorApp.client.formTabs.btnCommercialInformation">CommercialInformation</Translate>
                        </span>
                      </Button>
                    ) : null}
                  </TabPane>
                  <TabPane tabId={3}>
                    {isNew ? (
                      <Row className="justify-content-center mb-3">
                        <Col md="12">
                          <h2 id="generadorApp.client.home.formTabs_25">
                            <Translate contentKey="generadorApp.client.formTabs.OtherInformation">OtherInformation</Translate>
                          </h2>
                        </Col>
                      </Row>
                    ) : null}

                    <Row>
                      {baseFilters !== 'codAns' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="codAnsLabel" for="client-codAns">
                                  <Translate contentKey="generadorApp.client.codAns">ANS Code</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  onChange={evt => this.setState({ codAnsSelectValue: evt.target.value })}
                                  value={this.state.codAnsSelectValue ? this.state.codAnsSelectValue : ''}
                                  id="client-codAns"
                                  type="text"
                                  name="codAns"
                                  className={'form-control'}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ codAnsSelectValue: evt.target.value })}
                          type="hidden"
                          name="codAns"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'codCnes' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="codCnesLabel" for="client-codCnes">
                                  <Translate contentKey="generadorApp.client.codCnes">CNES Code</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  onChange={evt => this.setState({ codCnesSelectValue: evt.target.value })}
                                  value={this.state.codCnesSelectValue ? this.state.codCnesSelectValue : ''}
                                  id="client-codCnes"
                                  type="text"
                                  name="codCnes"
                                  className={'form-control'}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ codCnesSelectValue: evt.target.value })}
                          type="hidden"
                          name="codCnes"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'codEmpNoProvedor' ? (
                        <Col md="3">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="codEmpNoProvedorLabel" for="client-codEmpNoProvedor">
                                  <Translate contentKey="generadorApp.client.codEmpNoProvedor">Company Code Provider</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  onChange={evt => this.setState({ codEmpNoProvedorSelectValue: evt.target.value })}
                                  value={this.state.codEmpNoProvedorSelectValue ? this.state.codEmpNoProvedorSelectValue : ''}
                                  id="client-codEmpNoProvedor"
                                  type="text"
                                  name="codEmpNoProvedor"
                                  className={'form-control'}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ codEmpNoProvedorSelectValue: evt.target.value })}
                          type="hidden"
                          name="codEmpNoProvedor"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'contractSignatureDate' ? (
                        <Col md="2">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label
                                  className="mt-2 label-single-line"
                                  id="contractSignatureDateLabel"
                                  for="client-contractSignatureDate"
                                >
                                  <Translate contentKey="generadorApp.client.contractSignatureDate">Contract Signature Date</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <DatePicker
                                  autoComplete="off"
                                  onChange={value => this.setState({ contractSignatureDateSelectValue: value })}
                                  id="contractSignatureDate"
                                  validate={{}}
                                  className="form-control react-datepicker"
                                  name="contractSignatureDate"
                                  dateFormat="dd/MM/yyyy"
                                  selected={
                                    this.state.contractSignatureDateSelectValue
                                      ? moment(this.state.contractSignatureDateSelectValue).toDate()
                                      : null
                                  }
                                  locale="pt"
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ contractSignatureDateSelectValue: evt.target.value })}
                          type="hidden"
                          name="contractSignatureDate"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'expectedEmissionNfDay' ? (
                        <Col md="2">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label
                                  className="mt-2 label-single-line"
                                  id="expectedEmissionNfDayLabel"
                                  for="client-expectedEmissionNfDay"
                                >
                                  <Translate contentKey="generadorApp.client.expectedEmissionNfDay">Expected emission Nf date</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <CurrencyInput
                                  onChange={(maskedvalue, floatvalue, event) =>
                                    this.setState({ expectedEmissionNfDaySelectValue: floatvalue })
                                  }
                                  decimalSeparator=""
                                  thousandSeparator=""
                                  precision={0}
                                  prefix=""
                                  allowNegative={true}
                                  value={this.state.expectedEmissionNfDaySelectValue ? this.state.expectedEmissionNfDaySelectValue : 0}
                                  id="client-expectedEmissionNfDay"
                                  name="expectedEmissionNfDay"
                                  className={'form-control'}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ expectedEmissionNfDaySelectValue: evt.target.value })}
                          type="hidden"
                          name="expectedEmissionNfDay"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'expectedBillingDay' ? (
                        <Col md="2">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="expectedBillingDayLabel" for="client-expectedBillingDay">
                                  <Translate contentKey="generadorApp.client.expectedBillingDay">Expected billing day</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <CurrencyInput
                                  onChange={(maskedvalue, floatvalue, event) =>
                                    this.setState({ expectedBillingDaySelectValue: floatvalue })
                                  }
                                  decimalSeparator=""
                                  thousandSeparator=""
                                  precision={0}
                                  prefix=""
                                  allowNegative={true}
                                  value={this.state.expectedBillingDaySelectValue ? this.state.expectedBillingDaySelectValue : 0}
                                  id="client-expectedBillingDay"
                                  name="expectedBillingDay"
                                  className={'form-control'}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ expectedBillingDaySelectValue: evt.target.value })}
                          type="hidden"
                          name="expectedBillingDay"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'expectedReceiptDate' ? (
                        <Col md="3">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="expectedReceiptDateLabel" for="client-expectedReceiptDate">
                                  <Translate contentKey="generadorApp.client.expectedReceiptDate">Expected Receipt Date</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <CurrencyInput
                                  onChange={(maskedvalue, floatvalue, event) =>
                                    this.setState({ expectedReceiptDateSelectValue: floatvalue })
                                  }
                                  decimalSeparator=""
                                  thousandSeparator=""
                                  precision={0}
                                  prefix=""
                                  allowNegative={true}
                                  value={this.state.expectedReceiptDateSelectValue ? this.state.expectedReceiptDateSelectValue : 0}
                                  id="client-expectedReceiptDate"
                                  name="expectedReceiptDate"
                                  className={'form-control'}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ expectedReceiptDateSelectValue: evt.target.value })}
                          type="hidden"
                          name="expectedReceiptDate"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'xmlBillingTypes' ? (
                        <Col md="3">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="xmlBillingTypesLabel" for="client-xmlBillingTypes">
                                  <Translate contentKey="generadorApp.client.xmlBillingTypes">XML Table Type</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <Select
                                  id="client-xmlBillingTypes"
                                  className={'css-select-control'}
                                  value={
                                    this.state.xmlBillingTypesSelectValue
                                      ? {
                                          value: this.state.xmlBillingTypesSelectValue,
                                          label: translate('generadorApp.XmlBillingTypes.' + this.state.xmlBillingTypesSelectValue),
                                        }
                                      : { value: '', label: translate('generadorApp.client.xmlBillingTypes') }
                                  }
                                  options={[
                                    { value: 'XML_3_03_01', label: translate('generadorApp.XmlBillingTypes.XML_3_03_01') },
                                    { value: 'XML_3_03_03', label: translate('generadorApp.XmlBillingTypes.XML_3_03_03') },
                                    { value: 'XML_3_04_01', label: translate('generadorApp.XmlBillingTypes.XML_3_04_01') },
                                  ]}
                                  onChange={(options: any) => this.setState({ xmlBillingTypesSelectValue: options['value'] })}
                                  name="xmlBillingTypes"
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ xmlBillingTypesSelectValue: evt.target.value })}
                          type="hidden"
                          name="xmlBillingTypes"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'xmlSimpleExport' ? (
                        <Col md="3">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="xmlSimpleExportLabel" for="client-xmlSimpleExport">
                                  <Translate contentKey="generadorApp.client.xmlSimpleExport">Exportação Simplificada de XML</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <div className="switcher switcher-success">
                                  <input
                                    type="checkbox"
                                    name={'xmlSimpleExport_checkbox'}
                                    id={'xmlSimpleExport_checkbox'}
                                    checked={this.state.xmlSimpleExportSelectValue}
                                    onChange={evt => this.setState({ xmlSimpleExportSelectValue: !this.state.xmlSimpleExportSelectValue })}
                                  />
                                  <label htmlFor={'xmlSimpleExport_checkbox'} />
                                </div>
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ xmlSimpleExportSelectValue: evt.target.value })}
                          type="hidden"
                          name="xmlSimpleExport"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'billingRules' ? (
                        <Col md="12">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className={'mt-2'} id="billingRulesLabel" for="client-billingRules">
                                  <Translate contentKey="generadorApp.client.billingRules">Billing Rules</Translate>
                                </Label>
                              </Col>

                              <Col md="12">
                                <ReactQuill
                                  value={this.state.billingRulesSelectValue ? this.state.billingRulesSelectValue : ''}
                                  onChange={evt => this.setState({ billingRulesSelectValue: evt })}
                                  modules={quillEditorModules}
                                  formats={quillEditorFormats}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ billingRulesSelectValue: evt.target.value })}
                          type="hidden"
                          name="billingRules"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'observations' ? (
                        <Col md="12">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className={'mt-2'} id="observationsLabel" for="client-observations">
                                  <Translate contentKey="generadorApp.client.observations">Observations</Translate>
                                </Label>
                              </Col>

                              <Col md="12">
                                <ReactQuill
                                  value={this.state.observationsSelectValue ? this.state.observationsSelectValue : ''}
                                  onChange={evt => this.setState({ observationsSelectValue: evt })}
                                  modules={quillEditorModules}
                                  formats={quillEditorFormats}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ observationsSelectValue: evt.target.value })}
                          type="hidden"
                          name="observations"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}
                    </Row>

                    {isNew ? (
                      <Button
                        color="primary"
                        id="save-entity"
                        type="submit"
                        disabled={updating}
                        className="btn btn-primary float-right jh-create-entity"
                      >
                        <FontAwesomeIcon icon="save" />
                        &nbsp;
                        <Translate contentKey="generadorApp.client.btnSave">Save</Translate>
                      </Button>
                    ) : null}

                    {isNew ? (
                      <Button
                        color="info"
                        className={'float-right jh-create-entity'}
                        onClick={() => {
                          this.tabsNext(2);
                        }}
                      >
                        <span className="d-sm-none">
                          <Translate contentKey="generadorApp.client.formTabs.btnClientContacts">ClientContacts</Translate>
                        </span>
                        <span className="d-sm-block d-none">
                          <FontAwesomeIcon icon="arrow-left" />
                          &nbsp;
                          <Translate contentKey="generadorApp.client.formTabs.btnClientContacts">ClientContacts</Translate>
                        </span>
                      </Button>
                    ) : null}
                  </TabPane>
                </TabContent>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const {
      clientEntity,
      patients,
      clientContacts,
      clientStatusHistories,
      billingXmls,
      clientFiles,
      clComTables,
      pushes,
      segments,
      loading,
      updating,
    } = this.props;
    const { isNew } = this.state;

    const { observations } = clientEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...clientEntity,
                  activeTab: 0,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel className="client client-update">
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToProps = (storeState: IRootState) => {
  return {
    patients: storeState.patient.entities,
    clientContacts: storeState.clientContact.entities,
    clientStatusHistories: storeState.clientStatusHistory.entities,
    billingXmls: storeState.billingXml.entities,
    clientFiles: storeState.clientFile.entities,
    clComTables: storeState.clComTable.entities,
    pushes: storeState.push.entities,
    segments: storeState.segment.entities,
    clientEntity: storeState.client.entity,
    loading: storeState.client.loading,
    updating: storeState.client.updating,
    updateSuccess: storeState.client.updateSuccess,

    clients: storeState.client.entities,
    clientContactListAllEmbed: storeState.client.clientContactListAllEmbed,
    clientContactListEmbed: storeState.client.clientContactListEmbed,
    clientContactSelected: storeState.client.clientContactSelected,
    mapStateToPropsClientContact: mapStateToPropsClientContact(storeState),
  };
};

const mapDispatchToPropsClientContactList = {};
Object.keys(mapDispatchToPropsClientContact).map(v => {
  mapDispatchToPropsClientContactList[v + 'ClientContact'] = mapDispatchToPropsClientContact[v];
});

export const mapDispatchToProps = {
  getEntity,
  updateEntity,
  setBlob,
  createEntity,
  getEntityOffset,
  reset,

  ...mapDispatchToPropsClientContactList,
  clientContactUpdateEntity,
  clientContactGetEntities,
  clientContactSetEntities,
  clientContactReset,
  clientContactGetEntity,
  clientContactCreateEntity,
  clientContactDeleteEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ClientUpdate);
