import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import PatDrPrescrProcd from './extended/pat-dr-prescr-procd';

import PatDrPrescrProcdDetail from './pat-dr-prescr-procd-detail';

import PatDrPrescrProcdUpdate from './pat-dr-prescr-procd';

import DrStatusHistory from '../dr-status-history/index';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute path={`${match.path}/:idPatDrPrescrProcd/dr-status-history`} component={DrStatusHistory} />

      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={PatDrPrescrProcdUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={PatDrPrescrProcdUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={PatDrPrescrProcdDetail} />
      <ErrorBoundaryRoute path={match.path} component={PatDrPrescrProcd} />
    </Switch>

    <Switch>{/* <ErrorBoundaryRoute path={`${match.path}/:idPatDrPrescrProcd/dr-status-history`} component={DrStatusHistory} /> */}</Switch>
  </>
);

export default Routes;
