import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Nfe from './nfe';

import NfeDetail from './nfe-detail';

import NfeUpdate from './nfe-update';

import NfeFile from '../nfe-file/index';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute path={`${match.path}/:idNfe/nfe-file`} component={NfeFile} />

      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={NfeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={NfeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={NfeDetail} />
      <ErrorBoundaryRoute path={match.path} component={Nfe} />
    </Switch>

    <Switch>{/* <ErrorBoundaryRoute path={`${match.path}/:idNfe/nfe-file`} component={NfeFile} /> */}</Switch>
  </>
);

export default Routes;
