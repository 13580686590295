/* eslint complexity: ["error", 300] */
import { connect } from 'react-redux';

import { SupplierStatusHistory, mapStateToProps, mapDispatchToProps } from '../supplier-status-history';
import { IRootState } from 'app/shared/reducers';
import { getFilterFromSelect, getFormFromSelect } from 'app/shared/util/entity-utils';
import { updateEntity as updateEntitySupplier } from '../../supplier/supplier.reducer';
import { toast } from 'react-toastify';
import { translate } from 'app/config/translate-component';

export interface IExtendedState {
  dumystate?: any;
}
export interface IExtendedProps {
  entityEmbebedPai?: any;
}

export class SupplierStatusHistoryExtended extends SupplierStatusHistory {
  saveEntity = (event: any, errors: any, values: any, isNew: any) => {
    const selectFieldsList = ['status', 'observation', 'supplier.fantasyName', 'createdBy.login', 'createdDate'];

    if (errors.length === 0) {
      const { supplierStatusHistoryEntity } = this.props;
      const _entity = {
        ...supplierStatusHistoryEntity,
        // ...values,

        supplier: getFormFromSelect(this.state.supplierSelectValue, 'many-to-one'), // many-to-one - otherSide

        status: this.state.statusSelectValue === null ? 'ATIVO' : this.state.statusSelectValue,
        observation: this.state.observationSelectValue,
      };
      const entity = _entity;

      const { status, observation, supplierId, extraFilters, activePage, itemsPerPage, sort, order } = this.state.fieldsBase;

      if (isNew) {
        const entityIdBase = parseInt(supplierId && supplierId.length > 0 && supplierId[0].value ? supplierId[0].value : '', 10);
        this.props.updateEntitySupplier(
          {
            id: entityIdBase,
            status: this.state.statusSelectValue === null ? 'ATIVO' : this.state.statusSelectValue,
          },
          { reloadList: false }
        );
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              status,
              observation,
              getFilterFromSelect(supplierId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              status,
              observation,
              getFilterFromSelect(supplierId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
      this.setState({ showModalForm: false });
      this.setState({ showModalEdit: null });
    } else {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.specialty.' + errors[0]) }));
    }
  };
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
  updateEntitySupplier,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;

export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(SupplierStatusHistoryExtended);
