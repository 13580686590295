import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';
import moment from 'moment';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity, IKitBaseState, getUrlBack, getKitState, getEntityFiltersURL } from './kit.reducer';

import { APP_DATE_FORMAT } from 'app/config/constants';

export interface IKitState {
  fieldsBase: IKitBaseState;
}

export interface IKitDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class KitDetail extends React.Component<IKitDetailProps, IKitState> {
  constructor(props: Readonly<IKitDetailProps>) {
    super(props);
    this.state = {
      ...this.state,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getKitState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
    };
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = '_back=1' + (offset !== null ? '&offset=' + offset : '');
    Object.keys(fieldsBase).map(key => {
      url += '&' + key + '=' + fieldsBase[key];
    });
    return url;
  };

  UNSAFE_componentWillMount() {
    this.props.getEntity(this.props.match.params['id']);
  }

  renderHeader() {
    const { kitEntity } = this.props;
    const state = this.state;
    return (
      <>
        <div id="page-heading">
          {' '}
          <h2>
            <Translate contentKey="generadorApp.kit.detail.title">Kit</Translate>
            <small>&nbsp; {kitEntity['name']}</small>
          </h2>
        </div>

        <div className="pull-right">
          <Button tag={Link} to={'/kit?' + getEntityFiltersURL(this.state.fieldsBase)} replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.kit.btnBack">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/kit/${kitEntity.id}/edit?+${getEntityFiltersURL(this.state.fieldsBase)}`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.kit.btnEdit">Edit</Translate>
            </span>
          </Button>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>

          <li className="breadcrumb-item active">
            <Link to={'/kit'}>Kits</Link>
          </li>
          <li className="breadcrumb-item active">Kits details</li>
        </ol>
      </>
    );
  }

  renderBody() {
    const { kitEntity } = this.props;
    const state = this.state;
    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            <Row className="jh-entity-details">
              <Col className="view-detail-row kit-name-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top kit-name-detail-label" md="12">
                    <dt>
                      <span id="name">
                        <Translate contentKey="generadorApp.kit.name">Name</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top kit-name-detail-value" md="12">
                    <dd>{kitEntity.name}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row kit-status-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top kit-status-detail-label" md="12">
                    <dt>
                      <span id="status">
                        <Translate contentKey="generadorApp.kit.status">Status</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top kit-status-detail-value" md="12">
                    <dd>{translate('generadorApp.Status.' + (kitEntity.status ? kitEntity.status : 'INATIVADO'))}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row kit-createdDate-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top kit-createdDate-detail-label" md="12">
                    <dt>
                      <span id="createdDate">
                        <Translate contentKey="generadorApp.kit.createdDate">Created Date</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top kit-createdDate-detail-value" md="12">
                    <dd>{kitEntity.createdDate ? moment(kitEntity.createdDate).format(APP_DATE_FORMAT) : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row kit-lastModifiedDate-detail" md="12">
                <Row>
                  <Col className="view-detail-label-left kit-lastModifiedDate-detail-label" md="3">
                    <dt>
                      <span id="lastModifiedDate">
                        <Translate contentKey="generadorApp.kit.lastModifiedDate">Last Modified Date</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left kit-lastModifiedDate-detail-value" md="9">
                    <dd>{kitEntity.lastModifiedDate ? moment(kitEntity.lastModifiedDate).format(APP_DATE_FORMAT) : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row kit-kitRhs-detail" md="12">
                <Row>
                  <Col className="view-detail-label-top kit-kitRhs-detail-label" md="12">
                    <dt>
                      <Translate contentKey="generadorApp.kit.kitRhs">Kit Rhs</Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top kit-kitRhs-detail-value" md="12">
                    <dd>{kitEntity.kitRhs ? kitEntity.kitRhs.id : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row kit-kitProductService-detail" md="12">
                <Row>
                  <Col className="view-detail-label-top kit-kitProductService-detail-label" md="12">
                    <dt>
                      <Translate contentKey="generadorApp.kit.kitProductService">Kit Product Service</Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top kit-kitProductService-detail-value" md="12">
                    <dd>{kitEntity.kitProductService ? kitEntity.kitProductService.id : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row kit-kitMaterial-detail" md="12">
                <Row>
                  <Col className="view-detail-label-top kit-kitMaterial-detail-label" md="12">
                    <dt>
                      <Translate contentKey="generadorApp.kit.kitMaterial">Kit Material</Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top kit-kitMaterial-detail-value" md="12">
                    <dd>{kitEntity.kitMaterial ? kitEntity.kitMaterial.id : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row kit-kitMedicine-detail" md="12">
                <Row>
                  <Col className="view-detail-label-top kit-kitMedicine-detail-label" md="12">
                    <dt>
                      <Translate contentKey="generadorApp.kit.kitMedicine">Kit Medicine</Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top kit-kitMedicine-detail-value" md="12">
                    <dd>{kitEntity.kitMedicine ? kitEntity.kitMedicine.id : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row kit-kitDiet-detail" md="12">
                <Row>
                  <Col className="view-detail-label-top kit-kitDiet-detail-label" md="12">
                    <dt>
                      <Translate contentKey="generadorApp.kit.kitDiet">Kit Diet</Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top kit-kitDiet-detail-value" md="12">
                    <dd>{kitEntity.kitDiet ? kitEntity.kitDiet.id : ''}</dd>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { kitEntity } = this.props;
    const state = this.state;
    return (
      <div>
        <Panel className="kit kit-detail">
          <PanelHeader>{this.renderHeader()}</PanelHeader>

          <PanelBody>{this.renderBody()}</PanelBody>
        </Panel>
      </div>
    );
  }
}

export const mapStateToProps = ({ kit, ...storeState }: IRootState) => ({
  kitEntity: kit.entity,
});

export const mapDispatchToProps = {
  getEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(KitDetail);
