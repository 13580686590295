import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Professional from './extended/professional';

import ProfessionalDetail from './professional-detail';

import ProfessionalUpdate from './extended/professional-update';

import ProfessionalContact from '../professional-contact/index';

import ProfessionalOccupationArea from '../professional-occupation-area/index';

import ProfessionalFile from '../professional-file/index';

import ProfessionalStatusHistory from '../professional-status-history/index';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute path={`${match.path}/:idProfessional/professional-contact`} component={ProfessionalContact} />

      <ErrorBoundaryRoute path={`${match.path}/:idProfessional/professional-occupation-area`} component={ProfessionalOccupationArea} />

      <ErrorBoundaryRoute path={`${match.path}/:idProfessional/professional-file`} component={ProfessionalFile} />

      <ErrorBoundaryRoute path={`${match.path}/:idProfessional/professional-status-history`} component={ProfessionalStatusHistory} />

      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={ProfessionalUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={ProfessionalUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={ProfessionalDetail} />
      <ErrorBoundaryRoute path={match.path} component={Professional} />
    </Switch>

    <Switch>
      {/* <ErrorBoundaryRoute path={`${match.path}/:idProfessional/professional-contact`} component={ProfessionalContact} /> */}

      {/* <ErrorBoundaryRoute path={`${match.path}/:idProfessional/professional-occupation-area`} component={ProfessionalOccupationArea} /> */}

      {/* <ErrorBoundaryRoute path={`${match.path}/:idProfessional/professional-file`} component={ProfessionalFile} /> */}

      {/* <ErrorBoundaryRoute path={`${match.path}/:idProfessional/professional-status-history`} component={ProfessionalStatusHistory} /> */}
    </Switch>
  </>
);

export default Routes;
