import { Translate, translate } from 'app/config/translate-component';
import React from 'react';
import SelectAsync from 'react-select/async';
import { AvGroup } from 'availity-reactstrap-validation';

export interface IClComItemProps {
  baseState?: any;
  baseProps?: any;
  entityBase?: IClComItem;
  setState?: any;
}
import { Col, Row, Label } from 'reactstrap';
import { getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import { RenderModalSuperSelect } from 'app/shared/layout/components/RenderModalSuperSelect';
import { IClComItem } from 'app/shared/model/cl-com-item.model';
import { Sub } from 'app/shared/model/enumerations/sub.model';

const LevelGroupCategory = (props: IClComItemProps) => {
  const { clComRulerList, clComRulerEntity } = props.baseProps;
  let specialityRegistersInRulersIds = [];
  let medicineMaterialInRulersIds = [];
  let productServiceRegistersInRulersIds = [];
  if (clComRulerList && clComRulerList.length > 0) {
    specialityRegistersInRulersIds = clComRulerList
      .filter(
        v =>
          v.specialty &&
          v.specialty.category &&
          v.specialty.category.levelGroup.sub === 'ESPECIALIDADE' &&
          (!clComRulerEntity || !clComRulerEntity.specialty || v.specialty.id !== clComRulerEntity.specialty.id)
      )
      .map(v => v.specialty.id);

    medicineMaterialInRulersIds = clComRulerList
      .filter(
        v =>
          v.category &&
          v.category.levelGroup &&
          v.category.levelGroup.sub === 'MATERIALMEDICAMENTOEDIETA' &&
          (!clComRulerEntity || !clComRulerEntity.category || v.category.id !== clComRulerEntity.category.id)
      )
      .map(v => v.category.id);

    productServiceRegistersInRulersIds = clComRulerList
      .filter(
        v =>
          v.productService &&
          v.productService.category &&
          v.productService.category.levelGroup.sub === 'PRODUTOSESERVICOS' &&
          (!clComRulerEntity || !clComRulerEntity.productService || v.productService.id !== clComRulerEntity.productService.id)
      )
      .map(v => v.productService.id);
  }

  return (
    <Row>
      <Col md="3">
        <AvGroup>
          <Row>
            <Col md="12">
              <Label className="mt-2 label-single-line" for="cl-com-ruler-levelGroup">
                <Translate contentKey="generadorApp.clComRuler.levelGroup">levelGroup</Translate>
              </Label>
            </Col>
            <Col md="12">
              <SelectAsync
                id="cl-com-ruler-levelGroup"
                name={'levelGroup'}
                className={'css-select-control'}
                data-type-rel="many-to-one-other-side"
                value={props.baseState.levelGroupSelectValue}
                onChange={options =>
                  props.setState({
                    levelGroupSelectValue: options,
                    categorySelectValue: null,
                    categoryStartSelectOptions: undefined,
                    materialMedicineSelectValue: null,
                    materialMedicineStartSelectOptions: undefined,
                  })
                }
                defaultOptions={props.baseState.levelGroupStartSelectOptions ? props.baseState.levelGroupStartSelectOptions : []}
                loadingMessage={input => translate('selectAsync.loadingMessage')}
                noOptionsMessage={input =>
                  props.baseState.levelGroupStartSelectOptions === undefined
                    ? translate('selectAsync.loadingMessage')
                    : translate('selectAsync.noOptionsMessage')
                }
                onMenuOpen={async () => {
                  if (props.baseState.levelGroupStartSelectOptions === undefined) {
                    const result = await getListAxios(
                      'level-groups',
                      { 'sub.equals': Sub.MATERIALMEDICAMENTOEDIETA },
                      0,
                      100,
                      'name,asc',
                      'name'
                    );
                    props.setState({
                      levelGroupStartSelectOptions: result.data
                        ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                        : [],
                    });
                  }
                }}
                loadOptions={async (inputValue, callback) => {
                  const result = await getListAxios(
                    'level-groups',
                    { 'sub.equals': Sub.MATERIALMEDICAMENTOEDIETA, 'name.contains': inputValue },
                    0,
                    100,
                    'name,asc'
                  );
                  callback(result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []);
                }}
              />
            </Col>
          </Row>
        </AvGroup>
      </Col>
      <Col md="3">
        <AvGroup>
          <Row>
            <Col md="12">
              <Label className="mt-2 label-single-line" for="cl-com-ruler-category">
                <Translate contentKey="generadorApp.clComRuler.category">Category</Translate>
              </Label>
            </Col>
            <Col md="12">
              <SelectAsync
                id="cl-com-ruler-category"
                name={'category'}
                className={'css-select-control'}
                data-type-rel="many-to-one-other-side"
                value={props.baseState.categorySelectValue}
                onChange={options =>
                  props.setState({
                    categorySelectValue: options,
                    materialMedicineSelectValue: null,
                    materialMedicineStartSelectOptions: undefined,
                  })
                }
                defaultOptions={props.baseState.categoryStartSelectOptions ? props.baseState.categoryStartSelectOptions : []}
                loadingMessage={input => translate('selectAsync.loadingMessage')}
                noOptionsMessage={input =>
                  props.baseState.categoryStartSelectOptions === undefined
                    ? translate('selectAsync.loadingMessage')
                    : translate('selectAsync.noOptionsMessage')
                }
                onMenuOpen={async () => {
                  if (props.baseState.categoryStartSelectOptions === undefined) {
                    const result = await getListAxios(
                      'categories',
                      {
                        'levelGroup.id.in': props.baseState.levelGroupSelectValue ? props.baseState.levelGroupSelectValue.id : '',
                        'id.notIn': medicineMaterialInRulersIds,
                      },
                      0,
                      100,
                      'levelGroup,asc',
                      'name,levelGroup.id'
                    );
                    props.setState({
                      categoryStartSelectOptions: result.data
                        ? result.data.map(p => ({
                            ...p,
                            value: p.id,
                            label: p['name'] ? p.name : '',
                          }))
                        : [],
                    });
                  }
                }}
                loadOptions={async (inputValue, callback) => {
                  const result = await getListAxios(
                    'categories',
                    {
                      'levelGroup.id.in': props.baseState.levelGroupSelectValue ? props.baseState.levelGroupSelectValue.id : '',
                      'id.notIn': medicineMaterialInRulersIds,
                      'name.contains': inputValue,
                    },
                    0,
                    100,
                    'levelGroup,asc',
                    'name,levelGroup.id'
                  );
                  callback(
                    result.data
                      ? result.data.map(p => ({
                          ...p,
                          value: p.id,
                          label: p['name'] ? p.name : '',
                        }))
                      : []
                  );
                }}
              />
            </Col>

            <RenderModalSuperSelect
              baseState={props.baseState}
              setState={props.setState}
              entity={'category'}
              listEntity={'categories'}
              template={'levelGroup.name;name'.split(';')}
              stateField={'categorySelectValue'}
              multiple={false}
              showFields={'levelGroup.name;name'.split(';')}
              order={'levelGroup,asc'}
              filtersBase={{
                'levelGroup.id.in': props.baseState.levelGroupSelectValue ? props.baseState.levelGroupSelectValue.id : '',
                'id.notIn': medicineMaterialInRulersIds,
              }}
            />
          </Row>
        </AvGroup>
      </Col>
      <Col md="6">
        <AvGroup>
          <Row>
            <Col md="12">
              <Label className="mt-2 label-single-line" for="cl-com-item-materialMedicine">
                <Translate contentKey="generadorApp.clComItem.materialMedicine">Material Medicine</Translate>
              </Label>
            </Col>
            <Col md="12">
              <SelectAsync
                id="cl-com-item-materialMedicine"
                name={'materialMedicine'}
                className={'css-select-control'}
                data-type-rel="many-to-one-other-side"
                value={props.baseState.materialMedicineSelectValue}
                onChange={options => props.setState({ materialMedicineSelectValue: options })}
                defaultOptions={
                  props.baseState.materialMedicineStartSelectOptions ? props.baseState.materialMedicineStartSelectOptions : []
                }
                loadingMessage={input => translate('selectAsync.loadingMessage')}
                noOptionsMessage={input =>
                  props.baseState.materialMedicineStartSelectOptions === undefined
                    ? translate('selectAsync.loadingMessage')
                    : translate('selectAsync.noOptionsMessage')
                }
                onMenuOpen={async () => {
                  if (props.baseState.materialMedicineStartSelectOptions === undefined) {
                    const result = await getListAxios(
                      'medicine-materials',
                      {
                        'categories.id.in': props.baseState.categorySelectValue ? props.baseState.categorySelectValue.id : '',
                      },
                      0,
                      100,
                      'name,asc'
                    );
                    props.setState({
                      materialMedicineStartSelectOptions: result.data
                        ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                        : [],
                    });
                  }
                }}
                loadOptions={async (inputValue, callback) => {
                  const result = await getListAxios(
                    'medicine-materials',
                    {
                      'categories.id.in': props.baseState.categorySelectValue ? props.baseState.categorySelectValue.id : '',
                      'name.contains': inputValue,
                    },
                    0,
                    100,
                    'name,asc'
                  );
                  callback(result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []);
                }}
              />
            </Col>
          </Row>
        </AvGroup>
      </Col>
    </Row>
  );
};

export default LevelGroupCategory;
