import { Moment } from 'moment';
import { IUser } from 'app/shared/model/user.model';

export interface IJhiReports {
  id?: number;
  name?: string;
  querySql?: any;
  queryParams?: any;
  queryResult?: any;
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const defaultValue: Readonly<IJhiReports> = {};
