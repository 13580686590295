import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ConfigTag from './config-tag';

import ConfigTagDetail from './config-tag-detail';

import ConfigTagUpdate from './config-tag';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={ConfigTagUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={ConfigTagUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={ConfigTagDetail} />
      <ErrorBoundaryRoute path={match.path} component={ConfigTag} />
    </Switch>
  </>
);

export default Routes;
