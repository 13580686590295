/* eslint complexity: ["error", 300] */
import React from 'react';

import { connect } from 'react-redux';

import { Link, RouteComponentProps } from 'react-router-dom';

import { Button, Col, Row, Table, Label, UncontrolledCollapse, CardBody } from 'reactstrap';
import { AvForm, div, AvInput } from 'availity-reactstrap-validation';
import { Translate, getSortState, IPaginationBaseState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { IRootState } from 'app/shared/reducers';
import { updateEntity, getAuditEntityState, IAuditEntityBaseState, getAuditEntityFiltersURL, getEntities } from './audit-entity.reducer';

import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';

export interface IAuditEntityProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export interface IAuditEntityState extends IAuditEntityBaseState, IPaginationBaseState {}

export class AuditEntity extends React.Component<IAuditEntityProps, IAuditEntityState> {
  private myFormRef: any;

  constructor(props: IAuditEntityProps) {
    super(props);
    this.state = {
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      ...getAuditEntityState(this.props.location),
    };
  }

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  cancelFilters = () => {
    this.setState(
      {
        entityId: '',

        entityType: '',

        action: '',

        entityValue: '',

        commitVersion: '',
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getAuditEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    const { entityId, entityType, action, entityValue, commitVersion, activePage, itemsPerPage, sort, order } = this.state;
    this.props.getEntities(entityId, entityType, action, entityValue, commitVersion, activePage - 1, itemsPerPage, `${sort},${order}`);
  };

  updateEntity = entity => {
    const { entityId, entityType, action, entityValue, commitVersion, activePage, itemsPerPage, sort, order } = this.state;
    this.props.updateEntity(entity, [
      entityId,
      entityType,
      action,
      entityValue,
      commitVersion,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
    ]);
  };

  renderHeader() {
    const { auditEntityList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">Audit Entities</span>
          <Button id="togglerFilterAuditEntity" className="btn btn-primary float-right jh-create-entity">
            <Translate contentKey="generadorApp.auditEntity.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          &nbsp;
          <Link
            to={`${match.url}/new?${getAuditEntityFiltersURL(this.state)}`}
            className="btn btn-primary float-right jh-create-entity"
            id="jh-create-entity"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="generadorApp.auditEntity.home.createLabel">Create a new Audit Entity</Translate>
          </Link>{' '}
          &nbsp;
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.auditEntity.home.title">Audit Entities</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    const { auditEntityList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <UncontrolledCollapse toggler="#togglerFilterAuditEntity">
          <CardBody>
            <AvForm ref={el => (this.myFormRef = el)} id="form-filter" onSubmit={this.filterEntity}>
              <div className="row mt-1 ml-3 mr-3">
                {this.state.baseFilters !== 'entityId' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <Label id="entityIdLabel" for="audit-entity-entityId">
                        <Translate contentKey="generadorApp.auditEntity.entityId">Entity Id</Translate>
                      </Label>
                      <AvInput type="string" name="entityId" id="audit-entity-entityId" value={this.state.entityId} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'entityType' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <Label id="entityTypeLabel" for="audit-entity-entityType">
                        <Translate contentKey="generadorApp.auditEntity.entityType">Entity Type</Translate>
                      </Label>

                      <AvInput type="text" name="entityType" id="audit-entity-entityType" value={this.state.entityType} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'action' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <Label id="actionLabel" for="audit-entity-action">
                        <Translate contentKey="generadorApp.auditEntity.action">Action</Translate>
                      </Label>

                      <AvInput type="text" name="action" id="audit-entity-action" value={this.state.action} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'entityValue' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <Label id="entityValueLabel" for="audit-entity-entityValue">
                        <Translate contentKey="generadorApp.auditEntity.entityValue">Entity Value</Translate>
                      </Label>
                      <AvInput id="audit-entity-entityValue" type="textarea" name="entityValue" />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'commitVersion' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <Label id="commitVersionLabel" for="audit-entity-commitVersion">
                        <Translate contentKey="generadorApp.auditEntity.commitVersion">Commit Version</Translate>
                      </Label>
                      <AvInput type="string" name="commitVersion" id="audit-entity-commitVersion" value={this.state.commitVersion} />
                    </Row>
                  </Col>
                ) : null}
              </div>

              <div className="row mb-2 mr-4 justify-content-end">
                <Button className="btn btn-success" type="submit">
                  <i className="fa fa-filter" aria-hidden={'true'}></i>&nbsp;
                  <Translate contentKey="generadorApp.auditEntity.btnFilter">Filter</Translate>
                </Button>
                &nbsp;
                <div className="btn btn-secondary hand" onClick={this.cancelFilters}>
                  <FontAwesomeIcon icon="trash-alt" />
                  &nbsp;
                  <Translate contentKey="generadorApp.auditEntity.btnCleanFilter">Clean Filter</Translate>
                </div>
              </div>
            </AvForm>
          </CardBody>
        </UncontrolledCollapse>
      </>
    );
  }

  renderTable() {
    const { auditEntityList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {auditEntityList && auditEntityList.length > 0 ? (
          <Table
            id="audit-entity-table-list"
            responsive
            aria-describedby="audit-entity-heading"
            className={'table-hover table-striped mt-4 table-responsive-css'}
          >
            <thead className={'thead-light'}>
              <tr>
                <th className="hand" onClick={sortFunction('id')}>
                  <Translate contentKey="global.field.id">ID</Translate>
                  <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                </th>
                {state.baseFilters !== 'entityId' ? (
                  <th className="hand" onClick={sortFunction('entityId')}>
                    <Translate contentKey="generadorApp.auditEntity.entityId">Entity Id</Translate>
                    <FontAwesomeIcon icon={state.sort === 'entityId' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                ) : null}
                {state.baseFilters !== 'entityType' ? (
                  <th className="hand" onClick={sortFunction('entityType')}>
                    <Translate contentKey="generadorApp.auditEntity.entityType">Entity Type</Translate>
                    <FontAwesomeIcon icon={state.sort === 'entityType' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                ) : null}
                {state.baseFilters !== 'action' ? (
                  <th className="hand" onClick={sortFunction('action')}>
                    <Translate contentKey="generadorApp.auditEntity.action">Action</Translate>
                    <FontAwesomeIcon icon={state.sort === 'action' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                ) : null}
                {state.baseFilters !== 'entityValue' ? (
                  <th className="hand" onClick={sortFunction('entityValue')}>
                    <Translate contentKey="generadorApp.auditEntity.entityValue">Entity Value</Translate>
                    <FontAwesomeIcon icon={state.sort === 'entityValue' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                ) : null}
                {state.baseFilters !== 'commitVersion' ? (
                  <th className="hand" onClick={sortFunction('commitVersion')}>
                    <Translate contentKey="generadorApp.auditEntity.commitVersion">Commit Version</Translate>
                    <FontAwesomeIcon icon={state.sort === 'commitVersion' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                ) : null}

                <th />
              </tr>
            </thead>
            <tbody>
              <tr></tr>
              {auditEntityList.map((auditEntity, i) => (
                <tr key={`entity-${i}`}>
                  <td>
                    <b className="visible-xs">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </b>
                    <Button tag={Link} to={`${match.url}/${auditEntity.id}`} color="link" size="sm">
                      {auditEntity.id}
                    </Button>
                  </td>
                  {state.baseFilters !== 'entityId' ? (
                    <td id="entityId-cell">
                      <b className="visible-xs"> Entity Id </b>
                      {auditEntity.entityId}
                    </td>
                  ) : null}

                  {state.baseFilters !== 'entityType' ? (
                    <td id="entityType-cell">
                      <b className="visible-xs"> Entity Type </b>
                      {auditEntity.entityType}
                    </td>
                  ) : null}

                  {state.baseFilters !== 'action' ? (
                    <td id="action-cell">
                      <b className="visible-xs"> Action </b>
                      {auditEntity.action}
                    </td>
                  ) : null}

                  {state.baseFilters !== 'entityValue' ? (
                    <td id="entityValue-cell">
                      <b className="visible-xs"> Entity Value </b>
                      {auditEntity.entityValue ? Buffer.from(auditEntity.entityValue).toString() : null}
                    </td>
                  ) : null}

                  {state.baseFilters !== 'commitVersion' ? (
                    <td id="commitVersion-cell">
                      <b className="visible-xs"> Commit Version </b>
                      {auditEntity.commitVersion}
                    </td>
                  ) : null}

                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${auditEntity.id}?${getAuditEntityFiltersURL(state)}`} color="info" size="sm">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="%= i18nKeyPrefix .btnView">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${auditEntity.id}/edit?${getAuditEntityFiltersURL(state)}`}
                        color="primary"
                        size="sm"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="%= i18nKeyPrefix .btnEdit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${auditEntity.id}/delete?${getAuditEntityFiltersURL(state)}`}
                        color="danger"
                        size="sm"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="%= i18nKeyPrefix .btnDelete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.auditEntity.home.notFound">No Audit Entities found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const { auditEntityList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={auditEntityList && auditEntityList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={this.state.activePage}
              onSelect={this.handlePagination}
              maxButtons={5}
              itemsPerPage={this.state.itemsPerPage}
              totalItems={this.props.totalItems}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const { auditEntityList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel>
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToProps = ({ auditEntity, ...storeState }: IRootState) => ({
  auditEntityList: auditEntity.entities,
  totalItems: auditEntity.totalItems,
  loading: auditEntity.loading,
});

export const mapDispatchToProps = {
  getEntities,

  updateEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AuditEntity);
