/* eslint no-console: off */
import React from 'react';
import { connect } from 'react-redux';
import { Route, Link, RouteComponentProps } from 'react-router-dom';
import { IRootState } from 'app/shared/reducers';
import { Translate } from 'app/config/translate-component';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import Reports from 'app/entities/reports/reports';

export interface ISidebarNavListPropsData {
  icon?: string;
  img?: string;
  children?: Array<ISidebarNavListPropsData>;
  title?: string;
  onClick?: string;
  label?: string;
  badge?: string;
  exact?: boolean;
  path?: string;
}
export interface ISidebarNavListProps {
  data: ISidebarNavListPropsData;
  key?: number;
  expand?: Function;
  active?: boolean;
  clicked?: number;
  show?: boolean;
}
export interface ISidebarNavListState {
  active: number;
  clicked: number;
}

export interface ISidebarProps extends StateProps, DispatchProps, ISidebarNavListProps, RouteComponentProps<{ url: string }> {}

class SidebarNavList extends React.Component<any, ISidebarNavListState> {
  constructor(props) {
    super(props);
    this.state = {
      active: -1,
      clicked: -1,
    };
  }

  handleExpand(e, i, match) {
    e.preventDefault();

    this.setState({
      active: this.state.active === i ? -1 : i,
      clicked: 1,
    });
  }

  handleSidebarOnMouseOver(e) {
    e.preventDefault();
  }

  handleSidebarOnMouseOut(e) {
    e.preventDefault();
  }

  handleActiveMenu(match) {
    if (match && match.url === '/' && match.isExact === false) {
      return false;
    }
    return match;
  }

  render() {
    const icon = this.props.data.icon && <i className={this.props.data.icon}></i>;
    const img = this.props.data.img && (
      <div className="icon-img">
        <img src={this.props.data.img} alt="" />
      </div>
    );
    const caret = this.props.data.children && !this.props.data.badge && <b className="caret"></b>;
    const label = this.props.data.label && <span className="label label-theme m-l-5">{this.props.data.label}</span>;
    const badge = this.props.data.badge && <span className="badge pull-right">{this.props.data.badge}</span>;
    const title = this.props.data.title && (
      <span>
        <Translate contentKey={this.props.data.title}>{this.props.data.title}</Translate> {label}
      </span>
    );
    const menuFunctions = {
      showModalImobiliario: () => false,
    };
    const pageSidebarMinified = this.props.pageSidebarMinified;
    if (this.props.show === false) return null;
    return (
      <Route path={this.props.data.path} exact={this.props.data.exact}>
        {({ match }) => (
          <li
            className={
              (this.handleActiveMenu(match) ? 'active ' : '') +
              (this.props.active || (this.props.clicked === -1 && match) ? 'expand ' : 'closed ')
            }
          >
            {this.props.data.children ? (
              <Link
                to={() => void 0}
                onMouseOver={e => this.handleSidebarOnMouseOver(e)}
                onMouseOut={e => this.handleSidebarOnMouseOut(e)}
                onClick={e => this.props.expand(e)}
              >
                {caret} {img} {icon} {badge} {title}
              </Link>
            ) : this.props.data.onClick ? (
              <Link to={() => void 0} onClick={() => menuFunctions[this.props.data.onClick]()}>
                {caret} {img} {icon} {badge} {title}
              </Link>
            ) : (
              <Link to={this.props.data.path}>
                {caret} {img} {icon} {badge} {title}
              </Link>
            )}
            {this.props.data.children && (
              <ul
                className={
                  'sub-menu ' +
                  ((this.props.active || (this.props.clicked === -1 && match)) && !pageSidebarMinified ? 'd-block ' : 'd-none')
                }
              >
                {this.props.data.children &&
                  this.props.data.children.map((submenu, i) => {
                    return (
                      <SidebarNavList
                        {...this.props}
                        show={submenu.role && submenu.role.length > 0 ? hasAnyAuthority(this.props.userAccount, submenu.role) : true}
                        data={submenu}
                        key={i}
                        expand={e => this.handleExpand(e, i, match)}
                        active={i === this.state.active}
                        clicked={this.state.clicked}
                      />
                    );
                  })}
              </ul>
            )}
          </li>
        )}
      </Route>
    );
  }
}

const mapStateToProps = ({ sidebar, authentication }: IRootState) => ({
  pageSidebarMinified: sidebar.pageSidebarMinified,
  pageSidebarTransparent: sidebar.pageSidebarTransparent,
  userAccount: authentication.account,
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SidebarNavList);
