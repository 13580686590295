/* eslint complexity: ["error", 300] */
import React from 'react';
import { connect } from 'react-redux';

import { KitRhs, mapStateToProps, mapDispatchToProps } from '../kit-rhs';
import { IRootState } from 'app/shared/reducers';

export interface IExtendedState {
  categorySelectValue?: any;
  categoryStartSelectOptions?: any;
}

export interface IExtendedProps {
  entityEmbebedPai?: any;
}

export class KitRhsExtended extends KitRhs {
  renderHeader() {
    return <> {super.renderHeader()} </>;
  }

  renderFilter() {
    return <> {super.renderFilter()} </>;
  }

  renderTable() {
    return <> {super.renderTable()} </>;
  }

  renderFooter() {
    return <> {super.renderFooter()} </>;
  }

  render() {
    return <> {super.render()} </>;
  }
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;

export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(KitRhsExtended);
