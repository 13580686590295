/* eslint complexity: ["error", 500] */
import React from 'react';
import axios from 'axios';

import { getFilterFromSelect } from 'app/shared/util/entity-utils';
import { connect } from 'react-redux';

import { Link, RouteComponentProps } from 'react-router-dom';

import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { Button, Row, Table } from 'reactstrap';
import { div } from 'availity-reactstrap-validation';
import { JhiItemCount } from 'react-jhipster';
import { Translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { IRootState } from 'app/shared/reducers';
import {
  updateEntity,
  deleteEntity,
  getUrlBack,
  getProfileState,
  IProfileBaseState,
  getEntityFiltersURL,
  getEntities,
  reset,
} from './profile.reducer';

import { BASE_API_VERSION_PATH } from 'app/config/constants';
import { ITEMS_PER_PAGE, IPaginationBaseState, getSortState } from 'app/shared/util/pagination.constants';

import { StatePropsExtended, DispatchPropsExtended, IExtendedState, IExtendedProps } from './extended/profile';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

export interface IProfileProps
  extends StateProps,
    DispatchProps,
    StatePropsExtended,
    DispatchPropsExtended,
    IExtendedProps,
    RouteComponentProps<{ url: string }> {}
export interface IProfileStateAux extends IProfileBaseState, IExtendedState {}

export interface IProfileState extends IProfileStateAux, IPaginationBaseState {}

export class Profile extends React.Component<IProfileProps, IProfileState> {
  protected myFormRef: any;

  constructor(props: IProfileProps) {
    super(props);
    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      listCheckedID: [],
      isCheckedAll: false,
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      urlBack: getUrlBack(this.props.location),
      ...getProfileState(this.props.location),
    };
  }

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({ listCheckedID: this.props.profileList.map(profile => profile.id) });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({ listCheckedID: this.props.profileList.map(profile => profile.id) });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  removeEntitySelected() {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  }

  saveEntityMany = () => {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  };

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  deleteEntity(profileEntity) {
    MySwal.fire({
      title: <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>,
      html: (
        <Translate contentKey="generadorApp.profile.delete.question" interpolate={{ id: profileEntity.id }}>
          Are you sure you want to delete this Profile?
        </Translate>
      ),
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#e70015',
      cancelButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="ban" />
          &nbsp; <Translate contentKey="generadorApp.profile.btnCancel">Cancel</Translate>
        </>
      ),
      confirmButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="trash" />
          &nbsp;<Translate contentKey="generadorApp.profile.btnDelete">Delete</Translate>
        </>
      ),
    }).then(async response => {
      if (response.isConfirmed) {
        await this.props.deleteEntity(profileEntity.id, { reloadList: false });
        this.getEntities();
        MySwal.fire({
          title: <Translate contentKey="entity.delete.title.confirmed">Confirmed delete operation</Translate>,
          icon: 'success',
        });
      }
    });
  }

  cancelFilters = () => {
    this.setState(
      {
        name: '',

        status: '',
        permissionProfileId: '',
        userId: '',
        extraFilters: {},
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
        activePage: 0,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();

    const selectFieldsList = ['name', 'status'];

    const { name, status, permissionProfileId, userId, extraFilters, activePage, itemsPerPage, sort, order } = this.state;
    return this.props.getEntities(
      name,
      status,
      getFilterFromSelect(permissionProfileId, 'one-to-many'),
      getFilterFromSelect(userId, 'many-to-one'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(',')
    );
  };

  updateEntity = entity => {
    const selectFieldsList = ['name', 'status'];

    const { permissionProfileId, userId, extraFilters, activePage, itemsPerPage, sort, order } = this.state;
    this.props.updateEntity(entity, [
      name,
      status,
      getFilterFromSelect(permissionProfileId, 'one-to-many'),
      getFilterFromSelect(userId, 'many-to-one'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(','),
    ]);
  };

  renderHeader() {
    const { permissionProfiles, users, profileList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.profile.home.title">Profiles</Translate>
          </span>
          <Link
            to={`${match.url}/new?${getEntityFiltersURL(state)}`}
            className="btn btn-primary float-right jh-create-entity"
            id="jh-create-entity"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="generadorApp.profile.home.createLabel">Create a new Profile</Translate>
          </Link>{' '}
          &nbsp;
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.profile.home.title">Profiles</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    return <> </>;
  }

  renderTable() {
    const { permissionProfiles, users, profileList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {profileList && profileList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null).length > 0 ? (
          <>
            <Table
              id="profile-table-list"
              responsive
              aria-describedby="profile-heading"
              className={'table-hover table-striped mt-4 table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.profile.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'name' ? (
                    <th className="hand" onClick={sortFunction('name')}>
                      <Translate contentKey="generadorApp.profile.name">Name</Translate>
                      <FontAwesomeIcon icon={state.sort === 'name' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'status' ? (
                    <th className="hand" onClick={sortFunction('status')}>
                      <Translate contentKey="generadorApp.profile.status">Status</Translate>
                      <FontAwesomeIcon icon={state.sort === 'status' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}

                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {profileList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((profile, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.profile.field_id">ID</Translate>
                        </b>
                        {profile.id}
                      </td>

                      {state.baseFilters !== 'name' ? (
                        <td id="name-cell">
                          <b className="visible-xs"> Name </b>

                          {profile.name}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'status' ? (
                        <td id="status-cell">
                          <b className="visible-xs"> Status </b>
                          <div className="switcher switcher-success">
                            <input
                              type="checkbox"
                              name={'status_' + profile.id}
                              id={'status_' + profile.id}
                              defaultChecked={profile.status}
                              onClick={(evt: any) =>
                                axios.put(BASE_API_VERSION_PATH + 'profiles', { ...profile, status: evt.target.checked }).then(v => {
                                  this.getEntities();
                                  MySwal.fire({ title: 'Atualizado com sucesso', icon: 'success' });
                                })
                              }
                            />
                            <label htmlFor={'status_' + profile.id} />
                          </div>
                        </td>
                      ) : null}

                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          {hasAnyAuthority(props.userAccount, ['profile'], 'edit') ? (
                            <Button
                              tag={Link}
                              to={`${match.url}/${profile.id}/edit?${getEntityFiltersURL(
                                state,
                                this.state.itemsPerPage * (this.state.activePage - 1) + i
                              )}`}
                              color="primary"
                              size="sm"
                            >
                              <FontAwesomeIcon icon="pencil-alt" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                          {hasAnyAuthority(props.userAccount, ['profile'], 'canDelete') ? (
                            <Button onClick={() => this.deleteEntity(profile)} color="danger" size="sm">
                              <FontAwesomeIcon icon="trash" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.profile.home.notFound">No Profiles found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const { permissionProfiles, users, profileList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={profileList && profileList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const { permissionProfiles, users, profileList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel className="profile profile-list-table">
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">{this.renderTable()}</div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToProps = ({ ...storeState }: IRootState) => {
  return {
    permissionProfiles: storeState.permissionProfile.entities,
    users: storeState.userManagement.users,
    profileList: storeState.profile.entities,
    totalItems: storeState.profile.totalItems,
    userAccount: storeState.authentication.account,
    loading: storeState.profile.loading,
  };
};

export const mapDispatchToProps = {
  getEntities,

  deleteEntity,
  updateEntity,
  reset,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
