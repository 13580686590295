/* eslint complexity: ["error", 500] */
import React from 'react';

import { connect } from 'react-redux';
import Select from 'react-select';

import { Link, RouteComponentProps } from 'react-router-dom';

import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { Button, Col, Row, Table, Label, UncontrolledCollapse, CardBody } from 'reactstrap';
import { AvForm, div, AvInput } from 'availity-reactstrap-validation';
import { JhiItemCount } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { IRootState } from 'app/shared/reducers';
import {
  updateEntity,
  deleteEntity,
  getUrlBack,
  getBrasindiceState,
  IBrasindiceBaseState,
  getEntityFiltersURL,
  getEntities,
  reset,
} from './brasindice.reducer';

import { ITEMS_PER_PAGE, IPaginationBaseState, getSortState } from 'app/shared/util/pagination.constants';

import { StatePropsExtended, DispatchPropsExtended, IExtendedState, IExtendedProps } from './extended/brasindice';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import moment from 'moment';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

export interface IBrasindiceProps
  extends StateProps,
    DispatchProps,
    StatePropsExtended,
    DispatchPropsExtended,
    IExtendedProps,
    RouteComponentProps<{ url: string }> {}
export interface IBrasindiceStateAux extends IBrasindiceBaseState, IExtendedState {}

export interface IBrasindiceState extends IBrasindiceStateAux, IPaginationBaseState {}

export class Brasindice extends React.Component<IBrasindiceProps, IBrasindiceState> {
  protected myFormRef: any;

  constructor(props: IBrasindiceProps) {
    super(props);
    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      listCheckedID: [],
      isCheckedAll: false,
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      urlBack: getUrlBack(this.props.location),
      ...getBrasindiceState(this.props.location),
    };
  }

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({ listCheckedID: this.props.brasindiceList.map(brasindice => brasindice.id) });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({ listCheckedID: this.props.brasindiceList.map(brasindice => brasindice.id) });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  removeEntitySelected() {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  }

  saveEntityMany = () => {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  };

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  deleteEntity(brasindiceEntity) {
    MySwal.fire({
      title: <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>,
      html: (
        <Translate contentKey="generadorApp.brasindice.delete.question" interpolate={{ id: brasindiceEntity.id }}>
          Are you sure you want to delete this Brasindice?
        </Translate>
      ),
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#e70015',
      cancelButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="ban" />
          &nbsp; <Translate contentKey="generadorApp.brasindice.btnCancel">Cancel</Translate>
        </>
      ),
      confirmButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="trash" />
          &nbsp;<Translate contentKey="generadorApp.brasindice.btnDelete">Delete</Translate>
        </>
      ),
    }).then(async response => {
      if (response.isConfirmed) {
        await this.props.deleteEntity(brasindiceEntity.id, { reloadList: false });
        this.getEntities();
        MySwal.fire({
          title: <Translate contentKey="entity.delete.title.confirmed">Confirmed delete operation</Translate>,
          icon: 'success',
        });
      }
    });
  }

  cancelFilters = () => {
    this.setState(
      {
        brasindiceType: '',
        codeLab: '',
        nameLab: '',
        code: '',
        name: '',
        codeApre: '',
        nameApre: '',
        price: '',
        status: '',
        extraFilters: {},
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
        activePage: 0,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();

    const selectFieldsList = ['codeLab', 'nameLab', 'code', 'name', 'pcEmFab', 'pcEmVen'];

    const {
      brasindiceType,
      codeLab,
      nameLab,
      code,
      name,
      codeApre,
      nameApre,
      price,
      status,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;
    return this.props.getEntities(
      brasindiceType,
      codeLab,
      nameLab,
      code,
      name,
      codeApre,
      nameApre,
      price,
      status,
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(',')
    );
  };

  updateEntity = entity => {
    const selectFieldsList = ['codeLab', 'nameLab', 'code', 'name', 'pcEmFab', 'pcEmVen'];

    const {
      brasindiceType,
      codeLab,
      nameLab,
      code,
      name,
      codeApre,
      nameApre,
      price,
      status,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;
    this.props.updateEntity(entity, [
      brasindiceType,
      codeLab,
      nameLab,
      code,
      name,
      codeApre,
      nameApre,
      price,
      status,
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(','),
    ]);
  };

  renderHeader() {
    const { medicineMaterials, brasindiceList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.brasindice.home.title">Brasindices</Translate>
          </span>
          <Button id="togglerFilterBrasindice" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.brasindice.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          &nbsp;
          <Link
            to={`${match.url}/new?${getEntityFiltersURL(state)}`}
            className="btn btn-primary float-right jh-create-entity"
            id="jh-create-entity"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="generadorApp.brasindice.btnNewItem">Create new</Translate>
          </Link>{' '}
          &nbsp;
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.brasindice.home.title">Brasindices</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    const { medicineMaterials, brasindiceList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <UncontrolledCollapse toggler="#togglerFilterBrasindice">
          <CardBody>
            <AvForm ref={el => (this.myFormRef = el)} id="form-filter" onSubmit={this.filterEntity}>
              <div className="row mt-1 ml-3 mr-3">
                {this.state.baseFilters !== 'brasindiceType' ? (
                  <Col md="2" className="col-filter-brasindice-brasindiceType">
                    <Row className="mr-1 mt-1">
                      <Label id="brasindiceTypeLabel" for="brasindice-brasindiceType">
                        <Translate contentKey="generadorApp.brasindice.brasindiceType">Brasindice Type</Translate>
                      </Label>

                      <AvInput type="text" name="brasindiceType" id="brasindice-brasindiceType" value={this.state.brasindiceType} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'codeLab' ? (
                  <Col md="3" className="col-filter-brasindice-codeLab">
                    <Row className="mr-1 mt-1">
                      <Label id="codeLabLabel" for="brasindice-codeLab">
                        <Translate contentKey="generadorApp.brasindice.codeLab">Code Lab</Translate>
                      </Label>

                      <AvInput type="text" name="codeLab" id="brasindice-codeLab" value={this.state.codeLab} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'nameLab' ? (
                  <Col md="3" className="col-filter-brasindice-nameLab">
                    <Row className="mr-1 mt-1">
                      <Label id="nameLabLabel" for="brasindice-nameLab">
                        <Translate contentKey="generadorApp.brasindice.nameLab">Name Lab</Translate>
                      </Label>

                      <AvInput type="text" name="nameLab" id="brasindice-nameLab" value={this.state.nameLab} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'code' ? (
                  <Col md="3" className="col-filter-brasindice-code">
                    <Row className="mr-1 mt-1">
                      <Label id="codeLabel" for="brasindice-code">
                        <Translate contentKey="generadorApp.brasindice.code">Code</Translate>
                      </Label>

                      <AvInput type="text" name="code" id="brasindice-code" value={this.state.code} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'name' ? (
                  <Col md="3" className="col-filter-brasindice-name">
                    <Row className="mr-1 mt-1">
                      <Label id="nameLabel" for="brasindice-name">
                        <Translate contentKey="generadorApp.brasindice.name">Name</Translate>
                      </Label>

                      <AvInput type="text" name="name" id="brasindice-name" value={this.state.name} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'codeApre' ? (
                  <Col md="3" className="col-filter-brasindice-codeApre">
                    <Row className="mr-1 mt-1">
                      <Label id="codeApreLabel" for="brasindice-codeApre">
                        <Translate contentKey="generadorApp.brasindice.codeApre">Code Apre</Translate>
                      </Label>

                      <AvInput type="text" name="codeApre" id="brasindice-codeApre" value={this.state.codeApre} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'nameApre' ? (
                  <Col md="3" className="col-filter-brasindice-nameApre">
                    <Row className="mr-1 mt-1">
                      <Label id="nameApreLabel" for="brasindice-nameApre">
                        <Translate contentKey="generadorApp.brasindice.nameApre">Name Apre</Translate>
                      </Label>

                      <AvInput type="text" name="nameApre" id="brasindice-nameApre" value={this.state.nameApre} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'price' ? (
                  <Col md="2" className="col-filter-brasindice-price">
                    <Row className="mr-1 mt-1">
                      <Label id="priceLabel" for="brasindice-price">
                        <Translate contentKey="generadorApp.brasindice.price">Price</Translate>
                      </Label>

                      <AvInput type="text" name="price" id="brasindice-price" value={this.state.price} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'status' ? (
                  <Col md="2" className="col-filter-brasindice-status">
                    <Row className="mr-1 mt-1">
                      <Label id="statusLabel" for="brasindice-status">
                        <Translate contentKey="generadorApp.brasindice.status">Status</Translate>
                      </Label>
                      <Select
                        id="brasindice-status"
                        className={'css-select-control'}
                        value={
                          this.state.status === 0
                            ? { value: 0, label: translate('generadorApp.brasindice.status_filter_without') }
                            : this.state.status === 1
                            ? { value: 1, label: translate('generadorApp.brasindice.status_filter_with') }
                            : { value: '', label: translate('generadorApp.brasindice.status_filter_all') }
                        }
                        options={[
                          { value: '', label: translate('generadorApp.brasindice.status_filter_all') },
                          { value: 1, label: translate('generadorApp.brasindice.status_filter_with') },
                          { value: 0, label: translate('generadorApp.brasindice.status_filter_without') },
                        ]}
                        onChange={(options: any) => this.setState({ status: options['value'] })}
                        name="status"
                      />
                    </Row>
                  </Col>
                ) : null}
              </div>

              <div className="row mb-2 mr-4 justify-content-end">
                <Button color="primary" type="submit">
                  <i className="fa fa-filter" aria-hidden={'true'}></i>&nbsp;
                  <Translate contentKey="generadorApp.brasindice.btnFilter">Filter</Translate>
                </Button>
                &nbsp;
                <Button color="secondary" onClick={this.cancelFilters}>
                  <FontAwesomeIcon icon="trash-alt" />
                  &nbsp;
                  <Translate contentKey="generadorApp.brasindice.btnCleanFilter">Clean Filter</Translate>
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </UncontrolledCollapse>
      </>
    );
  }

  renderTable() {
    const { medicineMaterials, brasindiceList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {brasindiceList && brasindiceList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null).length > 0 ? (
          <>
            <Table
              id="brasindice-table-list"
              responsive
              aria-describedby="brasindice-heading"
              className={'table-hover table-striped mt-4 table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.brasindice.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'codeLab' ? (
                    <th className="hand" onClick={sortFunction('codeLab')}>
                      <Translate contentKey="generadorApp.brasindice.codeLab">Code Lab</Translate>
                      <FontAwesomeIcon icon={state.sort === 'codeLab' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'nameLab' ? (
                    <th className="hand" onClick={sortFunction('nameLab')}>
                      <Translate contentKey="generadorApp.brasindice.nameLab">Name Lab</Translate>
                      <FontAwesomeIcon icon={state.sort === 'nameLab' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'code' ? (
                    <th className="hand" onClick={sortFunction('code')}>
                      <Translate contentKey="generadorApp.brasindice.code">Code</Translate>
                      <FontAwesomeIcon icon={state.sort === 'code' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'name' ? (
                    <th className="hand" onClick={sortFunction('name')}>
                      <Translate contentKey="generadorApp.brasindice.name">Name</Translate>
                      <FontAwesomeIcon icon={state.sort === 'name' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'pcEmFab' ? (
                    <th className="hand" onClick={sortFunction('pcEmFab')}>
                      <Translate contentKey="generadorApp.brasindice.pcEmFab">Pc Em Fab</Translate>
                      <FontAwesomeIcon icon={state.sort === 'pcEmFab' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'pcEmVen' ? (
                    <th className="hand" onClick={sortFunction('pcEmVen')}>
                      <Translate contentKey="generadorApp.brasindice.pcEmVen">Pc Em Ven</Translate>
                      <FontAwesomeIcon icon={state.sort === 'pcEmVen' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'lastModifiedDate' ? (
                    <th className="hand" onClick={sortFunction('lastModifiedDate')}>
                      <Translate contentKey="generadorApp.brasindice.lastModifiedDate" />
                      <FontAwesomeIcon
                        icon={state.sort === 'lastModifiedDate' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'}
                      />
                    </th>
                  ) : null}

                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {brasindiceList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((brasindice, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.brasindice.field_id">ID</Translate>
                        </b>
                        {brasindice.id}
                      </td>

                      {state.baseFilters !== 'codeLab' ? (
                        <td id="codeLab-cell">
                          <b className="visible-xs"> Code Lab </b>

                          {brasindice.codeLab}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'nameLab' ? (
                        <td id="nameLab-cell">
                          <b className="visible-xs"> Name Lab </b>

                          {brasindice.nameLab}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'code' ? (
                        <td id="code-cell">
                          <b className="visible-xs"> Code </b>

                          {brasindice.code}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'name' ? (
                        <td id="name-cell">
                          <b className="visible-xs"> Name </b>

                          {brasindice.name}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'pcEmFab' ? (
                        <td id="pcEmFab-cell">
                          <b className="visible-xs"> Pc Em Fab </b>
                          R$ {(brasindice.pcEmFab * 1).toFixed(2).replace('.', ',')}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'pcEmVen' ? (
                        <td id="pcEmVen-cell">
                          <b className="visible-xs"> Pc Em Ven </b>
                          R$ {(brasindice.pcEmVen * 1).toFixed(2).replace('.', ',')}
                        </td>
                      ) : null}
                      {state.baseFilters !== 'lastModifiedDate' ? (
                        <td id="lastModifiedDate-cell">
                          {brasindice.lastModifiedDate ? moment(brasindice.lastModifiedDate).format(APP_LOCAL_DATE_FORMAT) : ''}
                        </td>
                      ) : null}

                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          {hasAnyAuthority(props.userAccount, ['brasindice'], 'view') ? (
                            <Button tag={Link} to={`${match.url}/${brasindice.id}`} color="info" size="sm">
                              <FontAwesomeIcon icon="eye" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.brasindice.home.notFound">No Brasindices found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const { medicineMaterials, brasindiceList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={brasindiceList && brasindiceList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const { medicineMaterials, brasindiceList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel className="brasindice brasindice-list-table">
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToProps = ({ ...storeState }: IRootState) => {
  return {
    medicineMaterials: storeState.medicineMaterial.entities,
    brasindiceList: storeState.brasindice.entities,
    totalItems: storeState.brasindice.totalItems,
    userAccount: storeState.authentication.account,
    loading: storeState.brasindice.loading,
  };
};

export const mapDispatchToProps = {
  getEntities,

  deleteEntity,
  updateEntity,
  reset,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Brasindice);
