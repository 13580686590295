/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import Geocode from 'react-geocode';

export interface IPatientProps {
  baseState?: any;
  baseProps?: any;
  setState?: any;
  entityBase?: any;
}

const mapStyles = {
  map: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
};
const Balloon = (lat?, lng?) => <img width="50" src="/content/images/google-tracker.png" />;

const PatientComponentCustomize = (props: IPatientProps) => {
  if (!props.entityBase || !props.entityBase.zipCode) {
    return <> </>;
  }
  const [latitud, setLatitud] = useState(0);
  const [longitud, setLongitud] = useState(0);

  Geocode.setApiKey('AIzaSyAKIprXb-tPQDfNB8hzLGq6RXU7XZ3y1hU');
  Geocode.setLanguage('pt');
  Geocode.setRegion('br');

  useEffect(() => {
    Geocode.fromAddress(props.entityBase.zipCode).then(
      response => {
        const { lat, lng } = response.results[0].geometry.location;
        setLatitud(lat);
        setLongitud(lng);
      },
      error => {
        console.error(error);
      }
    );
  });

  if (latitud === 0 && longitud === 0) {
    return <> </>;
  }

  return (
    <>
      <br />
      <br />
      <br />
      <div style={{ height: '300px', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyAKIprXb-tPQDfNB8hzLGq6RXU7XZ3y1hU', language: 'pt', region: 'br' }}
          defaultZoom={16}
          defaultCenter={{
            lat: latitud,
            lng: longitud,
          }}
        >
          <Balloon lat={latitud} lng={longitud} />
        </GoogleMapReact>
      </div>
    </>
  );
};

const mapStateToProps = storeState => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default PatientComponentCustomize;
