import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { ISupplierTable } from 'app/shared/model/supplier-table.model';
import { IMedicineMaterial } from 'app/shared/model/medicine-material.model';
import { IPresentation } from 'app/shared/model/presentation.model';

export interface ISupplierTableMaterial {
  id?: number;
  description?: string;
  price?: number;
  supplierTable?: ISupplierTable;
  medicineMaterial?: IMedicineMaterial;
  presentation?: IPresentation;
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  supplierTable: {
    isMulti: true, // many-to-one - other-side
    entity: 'SupplierTable',
    urlParameters: {
      enpoint: 'supplier-tables',
      filters: 'supplierTableMaterial.id.equals',
    },
  },
  medicineMaterial: {
    isMulti: true, // many-to-one - other-side
    entity: 'MedicineMaterial',
    urlParameters: {
      enpoint: 'medicine-materials',
      filters: 'supplierTableMaterial.id.equals',
    },
  },
  presentation: {
    isMulti: true, // many-to-one - other-side
    entity: 'Presentation',
    urlParameters: {
      enpoint: 'presentations',
      filters: 'supplierTableMaterial.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'supplierTableMaterial.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'supplierTableMaterial.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<ISupplierTableMaterial> = {};
