import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ClientGlosaItem from './client-glosa-item';

import ClientGlosaItemUpdate from './client-glosa-item';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={ClientGlosaItemUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={ClientGlosaItemUpdate} />
      <ErrorBoundaryRoute path={match.path} component={ClientGlosaItem} />
    </Switch>
  </>
);

export default Routes;
