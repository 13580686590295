import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity, IPermissionBaseState, getUrlBack, getPermissionState, getEntityFiltersURL } from './permission.reducer';

export interface IPermissionState {
  fieldsBase: IPermissionBaseState;
}

export interface IPermissionDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class PermissionDetail extends React.Component<IPermissionDetailProps, IPermissionState> {
  constructor(props: Readonly<IPermissionDetailProps>) {
    super(props);
    this.state = {
      ...this.state,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getPermissionState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
    };
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = '_back=1' + (offset !== null ? '&offset=' + offset : '');
    Object.keys(fieldsBase).map(key => {
      url += '&' + key + '=' + fieldsBase[key];
    });
    return url;
  };

  UNSAFE_componentWillMount() {
    this.props.getEntity(this.props.match.params['id']);
  }

  renderHeader() {
    const { permissionEntity } = this.props;
    const state = this.state;
    return (
      <>
        <div id="page-heading">
          {' '}
          <h2>
            <Translate contentKey="generadorApp.permission.detail.title">Permission</Translate>
            <small>&nbsp; {permissionEntity['name']}</small>
          </h2>
        </div>

        <div className="pull-right">
          <Button tag={Link} to={'/permission?' + getEntityFiltersURL(this.state.fieldsBase)} replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.permission.btnBack">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/permission/${permissionEntity.id}/edit?+${getEntityFiltersURL(this.state.fieldsBase)}`}
            replace
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.permission.btnEdit">Edit</Translate>
            </span>
          </Button>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>

          <li className="breadcrumb-item active">
            <Link to={'/permission'}>Permissions</Link>
          </li>
          <li className="breadcrumb-item active">Permissions details</li>
        </ol>
      </>
    );
  }

  renderBody() {
    const { permissionEntity } = this.props;
    const state = this.state;
    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            <Row className="jh-entity-details">
              <Col className="view-detail-row permission-slug-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top permission-slug-detail-label" md="12">
                    <dt>
                      <span id="slug">
                        <Translate contentKey="generadorApp.permission.slug">Slug</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top permission-slug-detail-value" md="12">
                    <dd>{permissionEntity.slug}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row permission-name-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top permission-name-detail-label" md="12">
                    <dt>
                      <span id="name">
                        <Translate contentKey="generadorApp.permission.name">Name</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top permission-name-detail-value" md="12">
                    <dd>{permissionEntity.name}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row permission-view-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top permission-view-detail-label" md="12">
                    <dt>
                      <span id="view">
                        <Translate contentKey="generadorApp.permission.view">View</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top permission-view-detail-value" md="12">
                    <dd>
                      {permissionEntity.view
                        ? translate('generadorApp.permission.view.true')
                        : translate('generadorApp.permission.view.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row permission-resgister-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top permission-resgister-detail-label" md="12">
                    <dt>
                      <span id="resgister">
                        <Translate contentKey="generadorApp.permission.resgister">Register</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top permission-resgister-detail-value" md="12">
                    <dd>
                      {permissionEntity.resgister
                        ? translate('generadorApp.permission.resgister.true')
                        : translate('generadorApp.permission.resgister.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row permission-edit-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top permission-edit-detail-label" md="12">
                    <dt>
                      <span id="edit">
                        <Translate contentKey="generadorApp.permission.edit">Edit</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top permission-edit-detail-value" md="12">
                    <dd>
                      {permissionEntity.edit
                        ? translate('generadorApp.permission.edit.true')
                        : translate('generadorApp.permission.edit.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row permission-canDelete-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top permission-canDelete-detail-label" md="12">
                    <dt>
                      <span id="canDelete">
                        <Translate contentKey="generadorApp.permission.canDelete">Delete</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top permission-canDelete-detail-value" md="12">
                    <dd>
                      {permissionEntity.canDelete
                        ? translate('generadorApp.permission.canDelete.true')
                        : translate('generadorApp.permission.canDelete.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row permission-manualRemoval-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top permission-manualRemoval-detail-label" md="12">
                    <dt>
                      <span id="manualRemoval">
                        <Translate contentKey="generadorApp.permission.manualRemoval">Manual removal</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top permission-manualRemoval-detail-value" md="12">
                    <dd>
                      {permissionEntity.manualRemoval
                        ? translate('generadorApp.permission.manualRemoval.true')
                        : translate('generadorApp.permission.manualRemoval.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row permission-editLocation-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top permission-editLocation-detail-label" md="12">
                    <dt>
                      <span id="editLocation">
                        <Translate contentKey="generadorApp.permission.editLocation">editLocation</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top permission-editLocation-detail-value" md="12">
                    <dd>
                      {permissionEntity.editLocation
                        ? translate('generadorApp.permission.editLocation.true')
                        : translate('generadorApp.permission.editLocation.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row permission-report-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top permission-report-detail-label" md="12">
                    <dt>
                      <span id="report">
                        <Translate contentKey="generadorApp.permission.report">Report</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top permission-report-detail-value" md="12">
                    <dd>
                      {permissionEntity.report
                        ? translate('generadorApp.permission.report.true')
                        : translate('generadorApp.permission.report.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row permission-activateProfessional-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top permission-activateProfessional-detail-label" md="12">
                    <dt>
                      <span id="activateProfessional">
                        <Translate contentKey="generadorApp.permission.activateProfessional">Activate professional</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top permission-activateProfessional-detail-value" md="12">
                    <dd>
                      {permissionEntity.activateProfessional
                        ? translate('generadorApp.permission.activateProfessional.true')
                        : translate('generadorApp.permission.activateProfessional.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row permission-deletePhoto-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top permission-deletePhoto-detail-label" md="12">
                    <dt>
                      <span id="deletePhoto">
                        <Translate contentKey="generadorApp.permission.deletePhoto">Delete photo</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top permission-deletePhoto-detail-value" md="12">
                    <dd>
                      {permissionEntity.deletePhoto
                        ? translate('generadorApp.permission.deletePhoto.true')
                        : translate('generadorApp.permission.deletePhoto.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row permission-financialValue-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top permission-financialValue-detail-label" md="12">
                    <dt>
                      <span id="financialValue">
                        <Translate contentKey="generadorApp.permission.financialValue">Values</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top permission-financialValue-detail-value" md="12">
                    <dd>
                      {permissionEntity.financialValue
                        ? translate('generadorApp.permission.financialValue.true')
                        : translate('generadorApp.permission.financialValue.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row permission-valueAuthorization-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top permission-valueAuthorization-detail-label" md="12">
                    <dt>
                      <span id="valueAuthorization">
                        <Translate contentKey="generadorApp.permission.valueAuthorization">Value authorization</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top permission-valueAuthorization-detail-value" md="12">
                    <dd>
                      {permissionEntity.valueAuthorization
                        ? translate('generadorApp.permission.valueAuthorization.true')
                        : translate('generadorApp.permission.valueAuthorization.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row permission-confirmPayment-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top permission-confirmPayment-detail-label" md="12">
                    <dt>
                      <span id="confirmPayment">
                        <Translate contentKey="generadorApp.permission.confirmPayment">confirmPayment</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top permission-confirmPayment-detail-value" md="12">
                    <dd>
                      {permissionEntity.confirmPayment
                        ? translate('generadorApp.permission.confirmPayment.true')
                        : translate('generadorApp.permission.confirmPayment.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row permission-evolution-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top permission-evolution-detail-label" md="12">
                    <dt>
                      <span id="evolution">
                        <Translate contentKey="generadorApp.permission.evolution">Evolution</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top permission-evolution-detail-value" md="12">
                    <dd>
                      {permissionEntity.evolution
                        ? translate('generadorApp.permission.evolution.true')
                        : translate('generadorApp.permission.evolution.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row permission-professionalName-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top permission-professionalName-detail-label" md="12">
                    <dt>
                      <span id="professionalName">
                        <Translate contentKey="generadorApp.permission.professionalName">Professional name</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top permission-professionalName-detail-value" md="12">
                    <dd>
                      {permissionEntity.professionalName
                        ? translate('generadorApp.permission.professionalName.true')
                        : translate('generadorApp.permission.professionalName.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row permission-professionalRegister-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top permission-professionalRegister-detail-label" md="12">
                    <dt>
                      <span id="professionalRegister">
                        <Translate contentKey="generadorApp.permission.professionalRegister">Professional register</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top permission-professionalRegister-detail-value" md="12">
                    <dd>
                      {permissionEntity.professionalRegister
                        ? translate('generadorApp.permission.professionalRegister.true')
                        : translate('generadorApp.permission.professionalRegister.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { permissionEntity } = this.props;
    const state = this.state;
    return (
      <div>
        <Panel className="permission permission-detail">
          <PanelHeader>{this.renderHeader()}</PanelHeader>

          <PanelBody>{this.renderBody()}</PanelBody>
        </Panel>
      </div>
    );
  }
}

export const mapStateToProps = ({ permission, ...storeState }: IRootState) => ({
  permissionEntity: permission.entity,
});

export const mapDispatchToProps = {
  getEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PermissionDetail);
