/* eslint complexity: ["error", 500] */
/* eslint no-empty: off */
import React from 'react';
import { connect } from 'react-redux';

import { Link, RouteComponentProps } from 'react-router-dom';

import CurrencyInput from 'react-currency-input';

import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Col, Label } from 'reactstrap';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import {
  IPharmacyStockDevolutionUpdateState as IBaseUpdateState,
  getEntity,
  getPharmacyStockDevolutionState,
  getUrlBack,
  updateEntity,
  createEntity,
  getEntityFiltersURL,
  getEntityOffset,
  reset,
} from './pharmacy-stock-devolution.reducer';

import { StatePropsExtended, DispatchPropsExtended, IUpdateExtendedState } from './extended/pharmacy-stock-devolution-update';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

registerLocale('pt', pt);

export interface IPharmacyStockDevolutionUpdateProps
  extends StateProps,
    DispatchProps,
    StatePropsExtended,
    DispatchPropsExtended,
    RouteComponentProps<{ url: string }> {}
export interface IPharmacyStockDevolutionUpdateState extends IBaseUpdateState, IUpdateExtendedState {}

export class PharmacyStockDevolutionUpdate extends React.Component<
  IPharmacyStockDevolutionUpdateProps,
  IPharmacyStockDevolutionUpdateState
> {
  quantityReturnedFileInput: any;

  damagedProductFileInput: any;

  damagedAmountFileInput: any;
  constructor(props: Readonly<IPharmacyStockDevolutionUpdateProps>) {
    super(props);

    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      functionEmbebed: [],

      quantityReturnedSelectValue: null,
      damagedProductSelectValue: null,
      damagedAmountSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getPharmacyStockDevolutionState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },

      isNew: !this.props.match.params || !this.props.match.params['id'],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateSuccess !== this.props.updateSuccess && prevProps.updateSuccess) {
      this.handleClose();
    }

    if (
      (prevProps.pharmacyStockDevolutionEntity &&
        prevProps.pharmacyStockDevolutionEntity.id !== this.props.pharmacyStockDevolutionEntity.id) ||
      (this.props.pharmacyStockDevolutionEntity.quantityReturned &&
        this.state.quantityReturnedSelectValue === null &&
        this.props.pharmacyStockDevolutionEntity.quantityReturned !== this.state.quantityReturnedSelectValue)
    ) {
      this.setState({
        quantityReturnedSelectValue: this.props.pharmacyStockDevolutionEntity.quantityReturned
          ? this.props.pharmacyStockDevolutionEntity.quantityReturned
          : null,
      });
    }
    if (
      (prevProps.pharmacyStockDevolutionEntity &&
        prevProps.pharmacyStockDevolutionEntity.id !== this.props.pharmacyStockDevolutionEntity.id) ||
      (this.state.damagedProductSelectValue === null &&
        this.props.pharmacyStockDevolutionEntity.damagedProduct !== this.state.damagedProductSelectValue)
    ) {
      this.setState({
        damagedProductSelectValue: this.props.pharmacyStockDevolutionEntity.damagedProduct
          ? this.props.pharmacyStockDevolutionEntity.damagedProduct
          : false,
      });
    }
    if (
      (prevProps.pharmacyStockDevolutionEntity &&
        prevProps.pharmacyStockDevolutionEntity.id !== this.props.pharmacyStockDevolutionEntity.id) ||
      (this.props.pharmacyStockDevolutionEntity.damagedAmount &&
        this.state.damagedAmountSelectValue === null &&
        this.props.pharmacyStockDevolutionEntity.damagedAmount !== this.state.damagedAmountSelectValue)
    ) {
      this.setState({
        damagedAmountSelectValue: this.props.pharmacyStockDevolutionEntity.damagedAmount
          ? this.props.pharmacyStockDevolutionEntity.damagedAmount
          : null,
      });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  UNSAFE_componentWillMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params['id']);
    }
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = offset !== null ? '&offset=' + offset : '';
    Object.keys(fieldsBase).map(key => {
      url += key !== 'offset' ? '&' + key + '=' + fieldsBase[key] : '';
    });
    return url;
  };
  saveEntity = (event: any, errors: any, values: any) => {
    if (!this.state.quantityReturnedSelectValue) {
      toast.error(
        translate('generadorApp.validators.required', { fieldName: translate('generadorApp.pharmacyStockDevolution.quantityReturned') })
      );
      return false;
    }

    const selectFieldsList = ['quantityReturned', 'damagedProduct', 'damagedAmount'];

    if (errors.length === 0) {
      const { pharmacyStockDevolutionEntity } = this.props;
      const _entity = {
        ...pharmacyStockDevolutionEntity,
        // ...values,

        quantityReturned: this.state.quantityReturnedSelectValue,
        damagedProduct: this.state.damagedProductSelectValue === null ? false : this.state.damagedProductSelectValue,
        damagedAmount: this.state.damagedAmountSelectValue,
      };

      const entity = _entity;

      const {
        quantityReturned,
        damagedProduct,
        damagedAmount,
        extraFilters,
        activePage,
        itemsPerPage,
        sort,
        order,
      } = this.state.fieldsBase;

      if (this.state.isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              quantityReturned,
              damagedProduct,
              damagedAmount,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              quantityReturned,
              damagedProduct,
              damagedAmount,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/pharmacy-stock-devolution?' + getEntityFiltersURL(this.state.fieldsBase));
  };

  getFullEntityFromState(v) {
    return {
      id: v.id,
      quantityReturned: this.state.quantityReturnedSelectValue,
      damagedProduct: this.state.damagedProductSelectValue,
      damagedAmount: this.state.damagedAmountSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    return {
      quantityReturnedSelectValue: v.quantityReturned,
      damagedProductSelectValue: v.damagedProduct,
      damagedAmountSelectValue: v.damagedAmount,
    };
  }

  renderHeader() {
    const { pharmacyStockDevolutionEntity, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.pharmacyStockDevolution.home.createLabel">Create a PharmacyStockDevolution</Translate>
            ) : (
              <Translate contentKey="generadorApp.pharmacyStockDevolution.home.editLabel">Edit a PharmacyStockDevolution</Translate>
            )}
          </span>

          <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.pharmacyStockDevolution.btnSave">Save</Translate>
          </Button>

          {!this.state.isNew ? (
            <>
              <Button
                color="primary"
                id="next-entity"
                disabled={updating}
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.quantityReturned,
                    this.state.fieldsBase.damagedProduct,
                    this.state.fieldsBase.damagedAmount,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 + 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 + 1 },
                    });
                    this.props.history.push(
                      '/pharmacy-stock-devolution/' +
                        myresult.value.data[0]['id'] +
                        '/edit?' +
                        this.getFiltersURL(this.state.fieldsBase.offset * 1 + 1)
                    );
                  } else {
                    toast.error(
                      translate('generadorApp.last.item', { fieldName: translate('generadorApp.pharmacyStockDevolution.detail.title') })
                    );
                  }
                }}
                className="float-right jh-create-entity"
              >
                {'>>'}
              </Button>

              <Button
                color="primary"
                id="prev-entity"
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.quantityReturned,
                    this.state.fieldsBase.damagedProduct,
                    this.state.fieldsBase.damagedAmount,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 - 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 - 1 },
                    });
                    this.props.history.push(
                      '/pharmacy-stock-devolution/' +
                        myresult.value.data[0]['id'] +
                        '/edit?' +
                        this.getFiltersURL(this.state.fieldsBase.offset * 1 - 1)
                    );
                  } else {
                    toast.error(
                      translate('generadorApp.first.item', { fieldName: translate('generadorApp.pharmacyStockDevolution.detail.title') })
                    );
                  }
                }}
                disabled={updating}
                className="float-right jh-create-entity"
              >
                {'<<'}
              </Button>
            </>
          ) : (
            <> </>
          )}

          <Button
            tag={Link}
            id="cancel-save"
            to={'/pharmacy-stock-devolution?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.pharmacyStockDevolution.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={'/pharmacy-stock-devolution'}>
              <Translate contentKey="generadorApp.pharmacyStockDevolution.home.title">Pharmacy Stock Devolutions</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.pharmacyStockDevolution.detail.title">Pharmacy Stock Devolution edit</Translate>
          </li>
        </ol>
      </>
    );
  }
  renderBody() {
    const { pharmacyStockDevolutionEntity, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const fullEntityEmbebed = this.getFullEntityFromState(pharmacyStockDevolutionEntity);

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="pharmacy-stock-devolution-id">
                  <Translate contentKey="generadorApp.pharmacyStockDevolution.field_id">ID</Translate>
                </Label>
                </Col> */}
                      <Col md="12">
                        <AvInput id="pharmacy-stock-devolution-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'quantityReturned' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label
                              className="mt-2 label-single-line"
                              id="quantityReturnedLabel"
                              for="pharmacy-stock-devolution-quantityReturned"
                            >
                              <Translate contentKey="generadorApp.pharmacyStockDevolution.quantityReturned">Quantity Returned</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ quantityReturnedSelectValue: floatvalue })}
                              decimalSeparator=""
                              thousandSeparator=""
                              precision={0}
                              prefix=""
                              allowNegative={true}
                              value={this.state.quantityReturnedSelectValue ? this.state.quantityReturnedSelectValue : 0}
                              id="pharmacy-stock-devolution-quantityReturned"
                              name="quantityReturned"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ quantityReturnedSelectValue: evt.target.value })}
                      type="hidden"
                      name="quantityReturned"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'damagedProduct' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label
                              className="mt-2 label-single-line"
                              id="damagedProductLabel"
                              for="pharmacy-stock-devolution-damagedProduct"
                            >
                              <Translate contentKey="generadorApp.pharmacyStockDevolution.damagedProduct">Damaged Product</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'damagedProduct_checkbox'}
                                id={'damagedProduct_checkbox'}
                                checked={this.state.damagedProductSelectValue}
                                onChange={evt => this.setState({ damagedProductSelectValue: !this.state.damagedProductSelectValue })}
                              />
                              <label htmlFor={'damagedProduct_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ damagedProductSelectValue: evt.target.value })}
                      type="hidden"
                      name="damagedProduct"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {this.state.damagedProductSelectValue === true && baseFilters !== 'damagedAmount' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="damagedAmountLabel" for="pharmacy-stock-devolution-damagedAmount">
                              <Translate contentKey="generadorApp.pharmacyStockDevolution.damagedAmount">Damaged Amount</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ damagedAmountSelectValue: floatvalue })}
                              decimalSeparator=""
                              thousandSeparator=""
                              precision={0}
                              prefix=""
                              allowNegative={true}
                              value={this.state.damagedAmountSelectValue ? this.state.damagedAmountSelectValue : 0}
                              id="pharmacy-stock-devolution-damagedAmount"
                              name="damagedAmount"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ damagedAmountSelectValue: evt.target.value })}
                      type="hidden"
                      name="damagedAmount"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { pharmacyStockDevolutionEntity, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...pharmacyStockDevolutionEntity,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel className="pharmacy-stock-devolution pharmacy-stock-devolution-update">
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToProps = (storeState: IRootState) => {
  return {
    pharmacyStockDevolutionEntity: storeState.pharmacyStockDevolution.entity,
    loading: storeState.pharmacyStockDevolution.loading,
    updating: storeState.pharmacyStockDevolution.updating,
    updateSuccess: storeState.pharmacyStockDevolution.updateSuccess,
  };
};

export const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  getEntityOffset,
  reset,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyStockDevolutionUpdate);
