/* eslint complexity: ["error", 500] */
import React from 'react';
import axios from 'axios';

import { connect } from 'react-redux';

import {
  getFilterFromSelect,
  getFormFromSelect,
  getListAxios,
  getValueRecursive,
  showFieldsSelectAsync,
} from 'app/shared/util/entity-utils';

import { toast } from 'react-toastify';
import SelectAsync from 'react-select/async';
import { Link, RouteComponentProps } from 'react-router-dom';

import { Button, Col, Row, Table, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { JhiItemCount } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { IRootState } from 'app/shared/reducers';
import {
  updateEntity,
  getUrlBack,
  getPermissionProfileState,
  IPermissionProfileBaseState,
  getEntityFiltersURL,
  getEntities,
  IPermissionProfileUpdateState as IBaseUpdateState,
  reset,
  getEntity,
  deleteEntity,
  createEntity,
} from './permission-profile.reducer';

import { BASE_API_VERSION_PATH } from 'app/config/constants';
import { ITEMS_PER_PAGE, IPaginationBaseState, getSortState } from 'app/shared/util/pagination.constants';

import { StatePropsExtended, DispatchPropsExtended, IExtendedState, IExtendedProps } from './extended/permission-profile';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

export interface IPermissionProfileProps
  extends StateProps,
    DispatchProps,
    StatePropsExtended,
    DispatchPropsExtended,
    IExtendedProps,
    RouteComponentProps<{ url: string }> {
  entityEmbebedPai?: any;
}

export interface IPermissionProfileState extends IPermissionProfileBaseState, IPaginationBaseState, IBaseUpdateState, IExtendedState {
  showModalForm?: boolean;
  showModalEdit?: number;
  showModalEditView?: boolean;
}

export class PermissionProfile extends React.Component<IPermissionProfileProps, IPermissionProfileState> {
  protected myFormRef: any;
  protected myFormRefNew: any;
  protected myFormRefEdit: any;

  viewFileInput: any;

  resgisterFileInput: any;

  editFileInput: any;

  canDeleteFileInput: any;

  manualRemovalFileInput: any;

  editLocationFileInput: any;

  reportFileInput: any;

  activateProfessionalFileInput: any;

  deletePhotoFileInput: any;

  financialValueFileInput: any;

  valueAuthorizationFileInput: any;

  confirmPaymentFileInput: any;

  evolutionFileInput: any;

  professionalNameFileInput: any;

  professionalRegisterFileInput: any;

  constructor(props: IPermissionProfileProps) {
    super(props);
    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      listCheckedID: [],
      isCheckedAll: false,
      showModalForm: false,
      showModalEdit: null,
      urlBack: getUrlBack(this.props.location),
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      ...getPermissionProfileState(this.props.location),
      profileId: [
        {
          ...(this.props.entityEmbebedPai ? this.props.entityEmbebedPai : {}),
          value: this.props.match.params['idProfile'],
          label: this.props.match.params['idProfile'],
        },
      ],
      baseFilters: 'profile',
      profileSelectValue: {
        ...(this.props.entityEmbebedPai ? this.props.entityEmbebedPai : {}),
        value: this.props.match.params['idProfile'],
        label: this.props.match.params['idProfile'],
      },

      permissionSelectValue: null,

      viewSelectValue: null,
      resgisterSelectValue: null,
      editSelectValue: null,
      canDeleteSelectValue: null,
      manualRemovalSelectValue: null,
      editLocationSelectValue: null,
      reportSelectValue: null,
      activateProfessionalSelectValue: null,
      deletePhotoSelectValue: null,
      financialValueSelectValue: null,
      valueAuthorizationSelectValue: null,
      confirmPaymentSelectValue: null,
      evolutionSelectValue: null,
      professionalNameSelectValue: null,
      professionalRegisterSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getPermissionProfileState(this.props.location),
        profileId: [
          {
            ...(this.props.entityEmbebedPai ? this.props.entityEmbebedPai : {}),
            value: this.props.match.params['idProfile'],
            label: this.props.match.params['idProfile'],
          },
        ],
        baseFilters: 'profile',
      },
      profileName: null,
      permissionName: null,
      isNew: false,
    };

    axios
      .get('/api/profiles/' + this.props.match.params['idProfile'], {
        headers: {
          'Select-Fields': 'name',
        },
      })
      .then(result => {
        this.setState({
          profileSelectValue: {
            ...result['data'],
            value: this.props.match.params['idProfile'],
            label: showFieldsSelectAsync(result['data'], 'name'),
          },
        });
      });
  }

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({ listCheckedID: this.props.permissionProfileList.map(permissionProfile => permissionProfile.id) });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({ listCheckedID: this.props.permissionProfileList.map(permissionProfile => permissionProfile.id) });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  removeEntitySelected() {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  }

  saveEntityMany = () => {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  };

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  cancelFilters = () => {
    this.setState(
      {
        view: '',

        resgister: '',

        edit: '',

        canDelete: '',

        manualRemoval: '',

        editLocation: '',

        report: '',

        activateProfessional: '',

        deletePhoto: '',

        financialValue: '',

        valueAuthorization: '',

        confirmPayment: '',

        evolution: '',

        professionalName: '',

        professionalRegister: '',
        profileId: '',
        permissionId: '',
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
        activePage: 0,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();

    const selectFieldsList = ['permission.name', 'view', 'resgister', 'edit', 'canDelete'];
    const {
      view,
      resgister,
      edit,
      canDelete,
      manualRemoval,
      editLocation,
      report,
      activateProfessional,
      deletePhoto,
      financialValue,
      valueAuthorization,
      confirmPayment,
      evolution,
      professionalName,
      professionalRegister,
      profileId,
      permissionId,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;
    return this.props.getEntities(
      view,
      resgister,
      edit,
      canDelete,
      manualRemoval,
      editLocation,
      report,
      activateProfessional,
      deletePhoto,
      financialValue,
      valueAuthorization,
      confirmPayment,
      evolution,
      professionalName,
      professionalRegister,
      getFilterFromSelect(profileId, 'many-to-one'),
      getFilterFromSelect(permissionId, 'many-to-one'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(',')
    );
  };

  updateEntity = entity => {
    const selectFieldsList = ['permission.name', 'view', 'resgister', 'edit', 'canDelete'];

    const {
      view,
      resgister,
      edit,
      canDelete,
      manualRemoval,
      editLocation,
      report,
      activateProfessional,
      deletePhoto,
      financialValue,
      valueAuthorization,
      confirmPayment,
      evolution,
      professionalName,
      professionalRegister,
      profileId,
      permissionId,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;
    this.props.updateEntity(entity, [
      view,
      resgister,
      edit,
      canDelete,
      manualRemoval,
      editLocation,
      report,
      activateProfessional,
      deletePhoto,
      financialValue,
      valueAuthorization,
      confirmPayment,
      evolution,
      professionalName,
      professionalRegister,
      getFilterFromSelect(profileId, 'many-to-one'),
      getFilterFromSelect(permissionId, 'many-to-one'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(','),
    ]);
  };

  saveEntity = (event: any, errors: any, values: any, isNew: any) => {
    const selectFieldsList = ['permission.name', 'view', 'resgister', 'edit', 'canDelete'];

    if (errors.length === 0) {
      const { permissionProfileEntity } = this.props;
      const _entity = {
        ...permissionProfileEntity,
        // ...values,

        permission: getFormFromSelect(this.state.permissionSelectValue, 'many-to-one'), // many-to-one - otherSide

        view: this.state.viewSelectValue === null ? false : this.state.viewSelectValue,
        resgister: this.state.resgisterSelectValue === null ? false : this.state.resgisterSelectValue,
        edit: this.state.editSelectValue === null ? false : this.state.editSelectValue,
        canDelete: this.state.canDeleteSelectValue === null ? false : this.state.canDeleteSelectValue,
        manualRemoval: this.state.manualRemovalSelectValue === null ? false : this.state.manualRemovalSelectValue,
        editLocation: this.state.editLocationSelectValue === null ? false : this.state.editLocationSelectValue,
        report: this.state.reportSelectValue === null ? false : this.state.reportSelectValue,
        activateProfessional: this.state.activateProfessionalSelectValue === null ? false : this.state.activateProfessionalSelectValue,
        deletePhoto: this.state.deletePhotoSelectValue === null ? false : this.state.deletePhotoSelectValue,
        financialValue: this.state.financialValueSelectValue === null ? false : this.state.financialValueSelectValue,
        valueAuthorization: this.state.valueAuthorizationSelectValue === null ? false : this.state.valueAuthorizationSelectValue,
        confirmPayment: this.state.confirmPaymentSelectValue === null ? false : this.state.confirmPaymentSelectValue,
        evolution: this.state.evolutionSelectValue === null ? false : this.state.evolutionSelectValue,
        professionalName: this.state.professionalNameSelectValue === null ? false : this.state.professionalNameSelectValue,
        professionalRegister: this.state.professionalRegisterSelectValue === null ? false : this.state.professionalRegisterSelectValue,
      };
      const entity = _entity;

      const {
        view,
        resgister,
        edit,
        canDelete,
        manualRemoval,
        editLocation,
        report,
        activateProfessional,
        deletePhoto,
        financialValue,
        valueAuthorization,
        confirmPayment,
        evolution,
        professionalName,
        professionalRegister,
        profileId,
        permissionId,
        extraFilters,
        activePage,
        itemsPerPage,
        sort,
        order,
      } = this.state.fieldsBase;

      if (isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              view,
              resgister,
              edit,
              canDelete,
              manualRemoval,
              editLocation,
              report,
              activateProfessional,
              deletePhoto,
              financialValue,
              valueAuthorization,
              confirmPayment,
              evolution,
              professionalName,
              professionalRegister,
              getFilterFromSelect(profileId, 'many-to-one'),
              getFilterFromSelect(permissionId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              view,
              resgister,
              edit,
              canDelete,
              manualRemoval,
              editLocation,
              report,
              activateProfessional,
              deletePhoto,
              financialValue,
              valueAuthorization,
              confirmPayment,
              evolution,
              professionalName,
              professionalRegister,
              getFilterFromSelect(profileId, 'many-to-one'),
              getFilterFromSelect(permissionId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
      this.setState({ showModalForm: false });
      this.setState({ showModalEdit: null });
    } else {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.specialty.' + errors[0]) }));
    }
  };

  componentDidUpdate(prevProps) {}

  deleteEntity(permissionProfileEntity) {
    MySwal.fire({
      title: <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>,
      html: (
        <Translate contentKey="generadorApp.permissionProfile.delete.question" interpolate={{ id: permissionProfileEntity.id }}>
          Are you sure you want to delete this PermissionProfile?
        </Translate>
      ),
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#e70015',
      cancelButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="ban" />
          &nbsp; <Translate contentKey="generadorApp.permissionProfile.btnCancel">Cancel</Translate>
        </>
      ),
      confirmButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="trash" />
          &nbsp;<Translate contentKey="generadorApp.permissionProfile.btnDelete">Delete</Translate>
        </>
      ),
    }).then(async response => {
      if (response.isConfirmed) {
        await this.props.deleteEntity(permissionProfileEntity.id, { reloadList: false });
        this.getEntities();
        MySwal.fire({
          title: <Translate contentKey="entity.delete.title.confirmed">Confirmed delete operation</Translate>,
          icon: 'success',
        });
      }
    });
  }

  renderNewModal() {
    return (
      <Modal size={'xl'} isOpen={this.state.showModalForm} toggle={() => this.setState({ showModalForm: false })}>
        <ModalHeader toggle={() => this.setState({ showModalForm: false })}>{this.renderHeaderUpdate({}, true)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefNew = el)}
          model={{}}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, true)}
        >
          <ModalBody>{this.renderBodyUpdate({}, true)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalForm: false })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.permissionProfile.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefNew && this.myFormRefNew.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.permissionProfile.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    );
  }

  getFullEntityFromState(v) {
    return {
      id: v.id,
      view: this.state.viewSelectValue,
      resgister: this.state.resgisterSelectValue,
      edit: this.state.editSelectValue,
      canDelete: this.state.canDeleteSelectValue,
      manualRemoval: this.state.manualRemovalSelectValue,
      editLocation: this.state.editLocationSelectValue,
      report: this.state.reportSelectValue,
      activateProfessional: this.state.activateProfessionalSelectValue,
      deletePhoto: this.state.deletePhotoSelectValue,
      financialValue: this.state.financialValueSelectValue,
      valueAuthorization: this.state.valueAuthorizationSelectValue,
      confirmPayment: this.state.confirmPaymentSelectValue,
      evolution: this.state.evolutionSelectValue,
      professionalName: this.state.professionalNameSelectValue,
      professionalRegister: this.state.professionalRegisterSelectValue,
      profile: this.state.profileSelectValue,
      permission: this.state.permissionSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    let p: any = null;

    p = v.profile;
    const profileEntity = p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : p;

    p = v.permission;
    const permissionEntity = p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : p;

    return {
      viewSelectValue: v.view, // view,
      resgisterSelectValue: v.resgister, // resgister,
      editSelectValue: v.edit, // edit,
      canDeleteSelectValue: v.canDelete, // canDelete,
      manualRemovalSelectValue: v.manualRemoval, // manualRemoval,
      editLocationSelectValue: v.editLocation, // editLocation,
      reportSelectValue: v.report, // report,
      activateProfessionalSelectValue: v.activateProfessional, // activateProfessional,
      deletePhotoSelectValue: v.deletePhoto, // deletePhoto,
      financialValueSelectValue: v.financialValue, // financialValue,
      valueAuthorizationSelectValue: v.valueAuthorization, // valueAuthorization,
      confirmPaymentSelectValue: v.confirmPayment, // confirmPayment,
      evolutionSelectValue: v.evolution, // evolution,
      professionalNameSelectValue: v.professionalName, // professionalName,
      professionalRegisterSelectValue: v.professionalRegister, // professionalRegister,
      profileSelectValue: profileEntity,
      permissionSelectValue: permissionEntity,
    };
  }

  openUpdateModal(v, edit = true) {
    this.props.getEntity(v.id);
    this.setState({
      showModalEdit: v.id,
      showModalEditView: edit,
      ...this.getFullEntitySelectValue(v),
    });
  }

  renderUpdateModal() {
    const { permissionProfileEntity, entityEmbebedPai } = this.props;
    return permissionProfileEntity && permissionProfileEntity.id ? (
      <Modal
        size={'xl'}
        isOpen={this.state.showModalEdit === permissionProfileEntity.id}
        toggle={() => this.setState({ showModalEdit: null })}
      >
        <ModalHeader toggle={() => this.setState({ showModalEdit: null })}>
          {this.renderHeaderUpdate(permissionProfileEntity, false)}
        </ModalHeader>
        <AvForm
          ref={el => (this.myFormRefEdit = el)}
          model={{
            ...permissionProfileEntity,
          }}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, false)}
        >
          <ModalBody>{this.renderBodyUpdate(permissionProfileEntity, false)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalEdit: null })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.permissionProfile.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefEdit && this.myFormRefEdit.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.permissionProfile.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    ) : (
      <> </>
    );
  }

  renderHeaderUpdate(permissionProfileEntity, isNew) {
    const { entityEmbebedPai, profiles, permissions, loading } = this.props;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {isNew ? (
              <Translate contentKey="generadorApp.permissionProfile.home.createLabel">Create a PermissionProfile</Translate>
            ) : (
              <Translate contentKey="generadorApp.permissionProfile.home.editLabel">Edit a PermissionProfile</Translate>
            )}
          </span>
        </div>
      </>
    );
  }
  renderBodyUpdate(permissionProfileEntity, isNew) {
    const { entityEmbebedPai, profiles, permissions, loading } = this.props;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
              <Col md="3">
              <Label className="mt-2 label-single-line"for="permission-profile-id">
                <Translate contentKey="generadorApp.permissionProfile.field_id">ID</Translate>
              </Label>
              </Col> */}
                      <Col md="12">
                        <AvInput id="permission-profile-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'permission' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="permission-profile-permission">
                              <Translate contentKey="generadorApp.permissionProfile.permission">Permission</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="permission-profile-permission"
                              name={'permission'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.permissionSelectValue ? this.state.permissionSelectValue : ''}
                              onChange={options => this.setState({ permissionSelectValue: options })}
                              defaultOptions={this.state.permissionStartSelectOptions ? this.state.permissionStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.permissionStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.permissionStartSelectOptions === undefined) {
                                  const result = await getListAxios('permissions', {}, 0, 100, 'name,asc', 'name');
                                  this.setState({
                                    permissionStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'permissions',
                                  { 'name.contains': inputValue },
                                  0,
                                  100,
                                  'name,asc',
                                  'name'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="permission"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}

                  {baseFilters !== 'view' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="viewLabel" for="permission-profile-view">
                              <Translate contentKey="generadorApp.permissionProfile.view">View</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'view_checkbox'}
                                id={'view_checkbox'}
                                checked={this.state.viewSelectValue}
                                onChange={evt => this.setState({ viewSelectValue: !this.state.viewSelectValue })}
                              />
                              <label htmlFor={'view_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ viewSelectValue: evt.target.value })}
                      type="hidden"
                      name="view"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'resgister' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="resgisterLabel" for="permission-profile-resgister">
                              <Translate contentKey="generadorApp.permissionProfile.resgister">Register</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'resgister_checkbox'}
                                id={'resgister_checkbox'}
                                checked={this.state.resgisterSelectValue}
                                onChange={evt => this.setState({ resgisterSelectValue: !this.state.resgisterSelectValue })}
                              />
                              <label htmlFor={'resgister_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ resgisterSelectValue: evt.target.value })}
                      type="hidden"
                      name="resgister"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'edit' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="editLabel" for="permission-profile-edit">
                              <Translate contentKey="generadorApp.permissionProfile.edit">Edit</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'edit_checkbox'}
                                id={'edit_checkbox'}
                                checked={this.state.editSelectValue}
                                onChange={evt => this.setState({ editSelectValue: !this.state.editSelectValue })}
                              />
                              <label htmlFor={'edit_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ editSelectValue: evt.target.value })}
                      type="hidden"
                      name="edit"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'canDelete' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="canDeleteLabel" for="permission-profile-canDelete">
                              <Translate contentKey="generadorApp.permissionProfile.canDelete">Delete</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'canDelete_checkbox'}
                                id={'canDelete_checkbox'}
                                checked={this.state.canDeleteSelectValue}
                                onChange={evt => this.setState({ canDeleteSelectValue: !this.state.canDeleteSelectValue })}
                              />
                              <label htmlFor={'canDelete_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ canDeleteSelectValue: evt.target.value })}
                      type="hidden"
                      name="canDelete"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  renderHeader() {
    const { profiles, permissions, permissionProfileList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.permissionProfile.home.title">Profile Permissions</Translate>
          </span>
          <Button
            onClick={() =>
              this.setState({
                showModalForm: true,
                viewSelectValue: null,
                resgisterSelectValue: null,
                editSelectValue: null,
                canDeleteSelectValue: null,
                manualRemovalSelectValue: null,
                editLocationSelectValue: null,
                reportSelectValue: null,
                activateProfessionalSelectValue: null,
                deletePhotoSelectValue: null,
                financialValueSelectValue: null,
                valueAuthorizationSelectValue: null,
                confirmPaymentSelectValue: null,
                evolutionSelectValue: null,
                professionalNameSelectValue: null,
                professionalRegisterSelectValue: null,

                permissionStartSelectOptions: undefined,
                permissionSelectValue: null,
                isNew: true,
              })
            }
            color="primary"
            className="btn btn-primary float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="generadorApp.permissionProfile.btnNewItem">Create new</Translate>
          </Button>{' '}
          &nbsp;
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/profile'}>
              <Translate contentKey="generadorApp.profile.home.title">Profiles</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/profile/' + this.props.match.params['idProfile']}>
              {this.state.profileSelectValue ? this.state.profileSelectValue.label : this.props.match.params['idProfile']}
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.permissionProfile.home.title">Profile Permissions</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    return <> </>;
  }

  renderTable() {
    const { profiles, permissions, permissionProfileList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {permissionProfileList &&
        permissionProfileList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null).length > 0 ? (
          <>
            <Table
              id="permission-profile-table-list"
              responsive
              aria-describedby="permission-profile-heading"
              className={'table-hover table-striped table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.permissionProfile.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'permission' ? (
                    <th>
                      <Translate contentKey="generadorApp.permissionProfile.permission">Permission</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'view' ? (
                    <th className="hand" onClick={sortFunction('view')}>
                      <Translate contentKey="generadorApp.permissionProfile.view">View</Translate>
                      <FontAwesomeIcon icon={state.sort === 'view' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'resgister' ? (
                    <th className="hand" onClick={sortFunction('resgister')}>
                      <Translate contentKey="generadorApp.permissionProfile.resgister">Register</Translate>
                      <FontAwesomeIcon icon={state.sort === 'resgister' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'edit' ? (
                    <th className="hand" onClick={sortFunction('edit')}>
                      <Translate contentKey="generadorApp.permissionProfile.edit">Edit</Translate>
                      <FontAwesomeIcon icon={state.sort === 'edit' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'canDelete' ? (
                    <th className="hand" onClick={sortFunction('canDelete')}>
                      <Translate contentKey="generadorApp.permissionProfile.canDelete">Delete</Translate>
                      <FontAwesomeIcon icon={state.sort === 'canDelete' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {permissionProfileList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((permissionProfile, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.permissionProfile.field_id">ID</Translate>
                        </b>
                        {permissionProfile.id}
                      </td>

                      {state.baseFilters !== 'permission' ? (
                        <td id="permission-cell">
                          <b className="visible-xs"> Permission </b>
                          {permissionProfile ? (
                            <span data-type-rel="2-many-to-one-other-side">
                              {getValueRecursive(permissionProfile, 'permission.name'.split('.'), '')}
                            </span>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'view' ? (
                        <td id="view-cell">
                          <b className="visible-xs"> View </b>
                          <div className="switcher switcher-success">
                            <input
                              type="checkbox"
                              name={'view_' + permissionProfile.id}
                              id={'view_' + permissionProfile.id}
                              defaultChecked={permissionProfile.view}
                              onClick={(evt: any) =>
                                axios
                                  .put(BASE_API_VERSION_PATH + 'permission-profiles', { ...permissionProfile, view: evt.target.checked })
                                  .then(v => {
                                    this.getEntities();
                                    MySwal.fire({ title: 'Atualizado com sucesso', icon: 'success' });
                                  })
                              }
                            />
                            <label htmlFor={'view_' + permissionProfile.id} />
                          </div>
                        </td>
                      ) : null}

                      {state.baseFilters !== 'resgister' ? (
                        <td id="resgister-cell">
                          <b className="visible-xs"> Register </b>
                          <div className="switcher switcher-success">
                            <input
                              type="checkbox"
                              name={'resgister_' + permissionProfile.id}
                              id={'resgister_' + permissionProfile.id}
                              defaultChecked={permissionProfile.resgister}
                              onClick={(evt: any) =>
                                axios
                                  .put(BASE_API_VERSION_PATH + 'permission-profiles', {
                                    ...permissionProfile,
                                    resgister: evt.target.checked,
                                  })
                                  .then(v => {
                                    this.getEntities();
                                    MySwal.fire({ title: 'Atualizado com sucesso', icon: 'success' });
                                  })
                              }
                            />
                            <label htmlFor={'resgister_' + permissionProfile.id} />
                          </div>
                        </td>
                      ) : null}

                      {state.baseFilters !== 'edit' ? (
                        <td id="edit-cell">
                          <b className="visible-xs"> Edit </b>
                          <div className="switcher switcher-success">
                            <input
                              type="checkbox"
                              name={'edit_' + permissionProfile.id}
                              id={'edit_' + permissionProfile.id}
                              defaultChecked={permissionProfile.edit}
                              onClick={(evt: any) =>
                                axios
                                  .put(BASE_API_VERSION_PATH + 'permission-profiles', { ...permissionProfile, edit: evt.target.checked })
                                  .then(v => {
                                    this.getEntities();
                                    MySwal.fire({ title: 'Atualizado com sucesso', icon: 'success' });
                                  })
                              }
                            />
                            <label htmlFor={'edit_' + permissionProfile.id} />
                          </div>
                        </td>
                      ) : null}

                      {state.baseFilters !== 'canDelete' ? (
                        <td id="canDelete-cell">
                          <b className="visible-xs"> Delete </b>
                          <div className="switcher switcher-success">
                            <input
                              type="checkbox"
                              name={'canDelete_' + permissionProfile.id}
                              id={'canDelete_' + permissionProfile.id}
                              defaultChecked={permissionProfile.canDelete}
                              onClick={(evt: any) =>
                                axios
                                  .put(BASE_API_VERSION_PATH + 'permission-profiles', {
                                    ...permissionProfile,
                                    canDelete: evt.target.checked,
                                  })
                                  .then(v => {
                                    this.getEntities();
                                    MySwal.fire({ title: 'Atualizado com sucesso', icon: 'success' });
                                  })
                              }
                            />
                            <label htmlFor={'canDelete_' + permissionProfile.id} />
                          </div>
                        </td>
                      ) : null}
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.permissionProfile.home.notFound">No Profile Permissions found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const { profiles, permissions, permissionProfileList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={permissionProfileList && permissionProfileList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const { profiles, permissions, permissionProfileList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel className="permission-profile permission-profile-all-in-one">
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderTable()}
            {this.renderNewModal()}
            {this.renderUpdateModal()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToProps = ({ permissionProfile, ...storeState }: IRootState) => ({
  profiles: storeState.profile.entities,
  permissions: storeState.permission.entities,
  permissionProfileList: permissionProfile.entities,
  totalItems: permissionProfile.totalItems,
  userAccount: storeState.authentication.account,
  loading: permissionProfile.loading,

  // UPDATE
  permissionProfileEntity: permissionProfile.entity,
});

export const mapDispatchToProps = {
  getEntities,

  updateEntity,
  reset,

  // UPDATE
  getEntity,
  createEntity,

  // DELETE
  deleteEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PermissionProfile);
