import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import {
  getEntity,
  INotificationUserBaseState,
  getUrlBack,
  getNotificationUserState,
  getEntityFiltersURL,
} from './notification-user.reducer';

export interface INotificationUserState {
  fieldsBase: INotificationUserBaseState;
}

export interface INotificationUserDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class NotificationUserDetail extends React.Component<INotificationUserDetailProps, INotificationUserState> {
  constructor(props: Readonly<INotificationUserDetailProps>) {
    super(props);
    this.state = {
      ...this.state,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getNotificationUserState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
    };
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = '_back=1' + (offset !== null ? '&offset=' + offset : '');
    Object.keys(fieldsBase).map(key => {
      url += '&' + key + '=' + fieldsBase[key];
    });
    return url;
  };

  UNSAFE_componentWillMount() {
    this.props.getEntity(this.props.match.params['id']);
  }

  renderHeader() {
    const { notificationUserEntity } = this.props;
    const state = this.state;
    return (
      <>
        <div id="page-heading">
          {' '}
          <h2>
            <Translate contentKey="generadorApp.notificationUser.detail.title">NotificationUser</Translate>
            <small>&nbsp; {notificationUserEntity['visualized']}</small>
          </h2>
        </div>

        <div className="pull-right">
          <Button tag={Link} to={'/notification-user?' + getEntityFiltersURL(this.state.fieldsBase)} replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.notificationUser.btnBack">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/notification-user/${notificationUserEntity.id}/edit?+${getEntityFiltersURL(this.state.fieldsBase)}`}
            replace
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.notificationUser.btnEdit">Edit</Translate>
            </span>
          </Button>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>

          <li className="breadcrumb-item active">
            <Link to={'/notification-user'}>Notification Users</Link>
          </li>
          <li className="breadcrumb-item active">Notification Users details</li>
        </ol>
      </>
    );
  }

  renderBody() {
    const { notificationUserEntity } = this.props;
    const state = this.state;
    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            <Row className="jh-entity-details">
              <Col className="view-detail-row notification-user-visualized-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top notification-user-visualized-detail-label" md="12">
                    <dt>
                      <span id="visualized">
                        <Translate contentKey="generadorApp.notificationUser.visualized">Visualized</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top notification-user-visualized-detail-value" md="12">
                    <dd>
                      {notificationUserEntity.visualized
                        ? translate('generadorApp.notificationUser.visualized.true')
                        : translate('generadorApp.notificationUser.visualized.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row notification-user-user-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top notification-user-user-detail-label" md="12">
                    <dt>
                      <Translate contentKey="generadorApp.notificationUser.user">User</Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top notification-user-user-detail-value" md="12">
                    <dd>{notificationUserEntity.user ? notificationUserEntity.user.id : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row notification-user-notification-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top notification-user-notification-detail-label" md="12">
                    <dt>
                      <Translate contentKey="generadorApp.notificationUser.notification">Notification</Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top notification-user-notification-detail-value" md="12">
                    <dd>{notificationUserEntity.notification ? notificationUserEntity.notification.id : ''}</dd>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { notificationUserEntity } = this.props;
    const state = this.state;
    return (
      <div>
        <Panel className="notification-user notification-user-detail">
          <PanelHeader>{this.renderHeader()}</PanelHeader>

          <PanelBody>{this.renderBody()}</PanelBody>
        </Panel>
      </div>
    );
  }
}

export const mapStateToProps = ({ notificationUser, ...storeState }: IRootState) => ({
  notificationUserEntity: notificationUser.entity,
});

export const mapDispatchToProps = {
  getEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(NotificationUserDetail);
