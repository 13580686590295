import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';
import moment from 'moment';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity, IPatientBaseState, getUrlBack, getPatientState, getEntityFiltersURL } from './patient.reducer';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

import AttendacesPatientList from './components/AttendacesPatientList';
import GoogleMapComponent from './components/GoogleMapComponent';

import { PatientSupplierDetail } from '../patient-supplier/patient-supplier-detail';
import {
  getEntity as getEntityPatientSupplier,
  convertEntityBuffers as convertEntityBuffersPatientSupplier,
} from '../patient-supplier/patient-supplier.reducer';
import { PatientCidDetail } from '../patient-cid/patient-cid-detail';
import {
  getEntity as getEntityPatientCid,
  convertEntityBuffers as convertEntityBuffersPatientCid,
} from '../patient-cid/patient-cid.reducer';
import { PatientAllergyDetail } from '../patient-allergy/patient-allergy-detail';
import {
  getEntity as getEntityPatientAllergy,
  convertEntityBuffers as convertEntityBuffersPatientAllergy,
} from '../patient-allergy/patient-allergy.reducer';
import { PatientContactDetail } from '../patient-contact/patient-contact-detail';
import {
  getEntity as getEntityPatientContact,
  convertEntityBuffers as convertEntityBuffersPatientContact,
} from '../patient-contact/patient-contact.reducer';
import { PatientResponsibleDetail } from '../patient-responsible/patient-responsible-detail';
import {
  getEntity as getEntityPatientResponsible,
  convertEntityBuffers as convertEntityBuffersPatientResponsible,
} from '../patient-responsible/patient-responsible.reducer';

import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

export interface IPatientState {
  fieldsBase: IPatientBaseState;
  activeTab: number;
}

export interface IPatientDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class PatientDetail extends React.Component<IPatientDetailProps, IPatientState> {
  constructor(props: Readonly<IPatientDetailProps>) {
    super(props);
    this.state = {
      ...this.state,
      activeTab: 0,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getPatientState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
    };
  }

  toggleTab(tab: number) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = '_back=1' + (offset !== null ? '&offset=' + offset : '');
    Object.keys(fieldsBase).map(key => {
      url += '&' + key + '=' + fieldsBase[key];
    });
    return url;
  };

  UNSAFE_componentWillMount() {
    this.props.getEntity(this.props.match.params['id']);
  }

  renderHeader() {
    const { patientEntity } = this.props;
    const state = this.state;
    return (
      <>
        <div id="page-heading">
          {' '}
          <h2>
            <Translate contentKey="generadorApp.patient.detail.title">Patient</Translate>
            <small>&nbsp; {patientEntity['name']}</small>
          </h2>
        </div>

        <div className="pull-right">
          <Button tag={Link} to={'/patient?' + getEntityFiltersURL(this.state.fieldsBase)} replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.patient.btnBack">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/patient/${patientEntity.id}/edit?+${getEntityFiltersURL(this.state.fieldsBase)}`}
            replace
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.patient.btnEdit">Edit</Translate>
            </span>
          </Button>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>

          <li className="breadcrumb-item active">
            <Link to={'/patient'}>Patients</Link>
          </li>
          <li className="breadcrumb-item active">Patients details</li>
        </ol>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === 0 })}
              onClick={() => {
                this.toggleTab(0);
              }}
            >
              <span className="d-sm-none">
                <Translate contentKey="generadorApp.patient.viewTabs.PersonalInformation">PersonalInformation</Translate>
              </span>
              <span className="d-sm-block d-none">
                <Translate contentKey="generadorApp.patient.viewTabs.PersonalInformation">PersonalInformation</Translate>
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === 1 })}
              onClick={() => {
                this.toggleTab(1);
              }}
            >
              <span className="d-sm-none">
                <Translate contentKey="generadorApp.patient.viewTabs.InformationResidential">InformationResidential</Translate>
              </span>
              <span className="d-sm-block d-none">
                <Translate contentKey="generadorApp.patient.viewTabs.InformationResidential">InformationResidential</Translate>
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === 2 })}
              onClick={() => {
                this.toggleTab(2);
              }}
            >
              <span className="d-sm-none">
                <Translate contentKey="generadorApp.patient.viewTabs.PatientContacts">PatientContacts</Translate>
              </span>
              <span className="d-sm-block d-none">
                <Translate contentKey="generadorApp.patient.viewTabs.PatientContacts">PatientContacts</Translate>
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === 3 })}
              onClick={() => {
                this.toggleTab(3);
              }}
            >
              <span className="d-sm-none">
                <Translate contentKey="generadorApp.patient.viewTabs.PatientInfomation">PatientInfomation</Translate>
              </span>
              <span className="d-sm-block d-none">
                <Translate contentKey="generadorApp.patient.viewTabs.PatientInfomation">PatientInfomation</Translate>
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === 4 })}
              onClick={() => {
                this.toggleTab(4);
              }}
            >
              <span className="d-sm-none">
                <Translate contentKey="generadorApp.patient.viewTabs.PatientCid">PatientCid</Translate>
              </span>
              <span className="d-sm-block d-none">
                <Translate contentKey="generadorApp.patient.viewTabs.PatientCid">PatientCid</Translate>
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === 5 })}
              onClick={() => {
                this.toggleTab(5);
              }}
            >
              <span className="d-sm-none">
                <Translate contentKey="generadorApp.patient.viewTabs.PatientAlergies">PatientAlergies</Translate>
              </span>
              <span className="d-sm-block d-none">
                <Translate contentKey="generadorApp.patient.viewTabs.PatientAlergies">PatientAlergies</Translate>
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === 6 })}
              onClick={() => {
                this.toggleTab(6);
              }}
            >
              <span className="d-sm-none">
                <Translate contentKey="generadorApp.patient.viewTabs.PatientResponsible">PatientResponsible</Translate>
              </span>
              <span className="d-sm-block d-none">
                <Translate contentKey="generadorApp.patient.viewTabs.PatientResponsible">PatientResponsible</Translate>
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === 7 })}
              onClick={() => {
                this.toggleTab(7);
              }}
            >
              <span className="d-sm-none">
                <Translate contentKey="generadorApp.patient.viewTabs.PatientSupplier">PatientSupplier</Translate>
              </span>
              <span className="d-sm-block d-none">
                <Translate contentKey="generadorApp.patient.viewTabs.PatientSupplier">PatientSupplier</Translate>
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === 8 })}
              onClick={() => {
                this.toggleTab(8);
              }}
            >
              <span className="d-sm-none">
                <Translate contentKey="generadorApp.patient.viewTabs.PatientPadAttendance">PatientPadAttendance</Translate>
              </span>
              <span className="d-sm-block d-none">
                <Translate contentKey="generadorApp.patient.viewTabs.PatientPadAttendance">PatientPadAttendance</Translate>
              </span>
            </NavLink>
          </NavItem>
        </Nav>
      </>
    );
  }

  renderBody() {
    const { patientEntity } = this.props;
    const state = this.state;
    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            <Row className="jh-entity-details">
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId={0}>
                  <Row>
                    <Col className="view-detail-row patient-name-detail" md="12">
                      <Row>
                        <Col className="view-detail-label-left patient-name-detail-label" md="3">
                          <dt>
                            <span id="name">
                              <Translate contentKey="generadorApp.patient.name">Name</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-left patient-name-detail-value" md="9">
                          <dd>{patientEntity.name}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row patient-cpf-detail" md="12">
                      <Row>
                        <Col className="view-detail-label-left patient-cpf-detail-label" md="3">
                          <dt>
                            <span id="cpf">
                              <Translate contentKey="generadorApp.patient.cpf">Cpf</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-left patient-cpf-detail-value" md="9">
                          <dd>{patientEntity.cpf}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row patient-rg-detail" md="12">
                      <Row>
                        <Col className="view-detail-label-left patient-rg-detail-label" md="3">
                          <dt>
                            <span id="rg">
                              <Translate contentKey="generadorApp.patient.rg">Rg</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-left patient-rg-detail-value" md="9">
                          <dd>{patientEntity.rg}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row patient-sex-detail" md="12">
                      <Row>
                        <Col className="view-detail-label-left patient-sex-detail-label" md="3">
                          <dt>
                            <span id="sex">
                              <Translate contentKey="generadorApp.patient.sex">Sex</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-left patient-sex-detail-value" md="9">
                          <dd>{translate('generadorApp.Sex.' + (patientEntity.sex ? patientEntity.sex : 'MASCULINO'))}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row patient-birthDate-detail" md="12">
                      <Row>
                        <Col className="view-detail-label-left patient-birthDate-detail-label" md="3">
                          <dt>
                            <span id="birthDate">
                              <Translate contentKey="generadorApp.patient.birthDate">Birth Date</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-left patient-birthDate-detail-value" md="9">
                          <dd>{patientEntity.birthDate ? moment(patientEntity.birthDate).format(APP_LOCAL_DATE_FORMAT) : ''}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row patient-weight-detail" md="12">
                      <Row>
                        <Col className="view-detail-label-left patient-weight-detail-label" md="3">
                          <dt>
                            <span id="weight">
                              <Translate contentKey="generadorApp.patient.weight">Weight</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-left patient-weight-detail-value" md="9">
                          <dd>{patientEntity.weight}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row patient-height-detail" md="12">
                      <Row>
                        <Col className="view-detail-label-left patient-height-detail-label" md="3">
                          <dt>
                            <span id="height">
                              <Translate contentKey="generadorApp.patient.height">Height</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-left patient-height-detail-value" md="9">
                          <dd>{patientEntity.height}</dd>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={1}>
                  <Row>
                    <Col className="view-detail-row patient-zipCode-detail" md="12">
                      <Row>
                        <Col className="view-detail-label-left patient-zipCode-detail-label" md="3">
                          <dt>
                            <span id="zipCode">
                              <Translate contentKey="generadorApp.patient.zipCode">Zip Code</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-left patient-zipCode-detail-value" md="9">
                          <dd>{patientEntity.zipCode}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row patient-street-detail" md="12">
                      <Row>
                        <Col className="view-detail-label-left patient-street-detail-label" md="3">
                          <dt>
                            <span id="street">
                              <Translate contentKey="generadorApp.patient.street">Street</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-left patient-street-detail-value" md="9">
                          <dd>{patientEntity.street}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row patient-number-detail" md="12">
                      <Row>
                        <Col className="view-detail-label-left patient-number-detail-label" md="3">
                          <dt>
                            <span id="number">
                              <Translate contentKey="generadorApp.patient.number">Number</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-left patient-number-detail-value" md="9">
                          <dd>{patientEntity.number}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row patient-neighborhood-detail" md="12">
                      <Row>
                        <Col className="view-detail-label-left patient-neighborhood-detail-label" md="3">
                          <dt>
                            <span id="neighborhood">
                              <Translate contentKey="generadorApp.patient.neighborhood">Neighborhood</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-left patient-neighborhood-detail-value" md="9">
                          <dd>{patientEntity.neighborhood}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row patient-city-detail" md="12">
                      <Row>
                        <Col className="view-detail-label-left patient-city-detail-label" md="3">
                          <dt>
                            <span id="city">
                              <Translate contentKey="generadorApp.patient.city">City</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-left patient-city-detail-value" md="9">
                          <dd>{patientEntity.city}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row patient-uf-detail" md="12">
                      <Row>
                        <Col className="view-detail-label-left patient-uf-detail-label" md="3">
                          <dt>
                            <span id="uf">
                              <Translate contentKey="generadorApp.patient.uf">Uf</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-left patient-uf-detail-value" md="9">
                          <dd>{patientEntity.uf}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row patient-complement-detail" md="12">
                      <Row>
                        <Col className="view-detail-label-left patient-complement-detail-label" md="3">
                          <dt>
                            <span id="complement">
                              <Translate contentKey="generadorApp.patient.complement">Complement</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-left patient-complement-detail-value" md="9">
                          <dd>{patientEntity.complement}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row patient-reference-detail" md="12">
                      <Row>
                        <Col className="view-detail-label-left patient-reference-detail-label" md="3">
                          <dt>
                            <span id="reference">
                              <Translate contentKey="generadorApp.patient.reference">Reference</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-left patient-reference-detail-value" md="9">
                          <dd>{patientEntity.reference}</dd>
                        </Col>
                      </Row>
                    </Col>
                    <Col md="12">
                      <GoogleMapComponent
                        entityBase={patientEntity}
                        baseState={this.state}
                        baseProps={this.props}
                        setState={_state => this.setState(_state)}
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={2}>
                  <Row>
                    {patientEntity.patientContact ? (
                      patientEntity.patientContact.map((v, i) => (
                        <div key={i} className="entityEmbebed">
                          <PatientContactDetail
                            history={this.props.history}
                            location={{
                              pathname: `/patient/${this.props.match.params['id']}/patient-contact/${v.id}`,
                              search: '',
                              hash: '',
                              state: undefined,
                              key: 'q2tbhd',
                            }}
                            match={{
                              path: `/patient/:idPatient/patient-contact/:id`,
                              url: `/patient/${this.props.match.params['id']}/patient-contact/${v.id}`,
                              isExact: true,
                              params: { id: '' + v.id },
                            }}
                            patientContactEntity={convertEntityBuffersPatientContact(v)}
                            getEntity={(): any => {}}
                          />
                        </div>
                      ))
                    ) : (
                      <> </>
                    )}
                  </Row>
                </TabPane>
                <TabPane tabId={3}>
                  <Row>
                    <Col className="view-detail-row patient-client-detail" md="12">
                      <Row>
                        <Col className="view-detail-label-left patient-client-detail-label" md="3">
                          <dt>
                            <Translate contentKey="generadorApp.patient.client">Client</Translate>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-left patient-client-detail-value" md="9">
                          <dd>{patientEntity.client ? patientEntity.client.id : ''}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row patient-healthPlanEnrollment-detail" md="12">
                      <Row>
                        <Col className="view-detail-label-left patient-healthPlanEnrollment-detail-label" md="3">
                          <dt>
                            <span id="healthPlanEnrollment">
                              <Translate contentKey="generadorApp.patient.healthPlanEnrollment">Health Plan Enrollment</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-left patient-healthPlanEnrollment-detail-value" md="9">
                          <dd>{patientEntity.healthPlanEnrollment}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row patient-liminar-detail" md="12">
                      <Row>
                        <Col className="view-detail-label-left patient-liminar-detail-label" md="3">
                          <dt>
                            <span id="liminar">
                              <Translate contentKey="generadorApp.patient.liminar">Liminar</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-left patient-liminar-detail-value" md="9">
                          <dd>{translate('generadorApp.GeralSelect.' + (patientEntity.liminar ? patientEntity.liminar : 'SIM'))}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row patient-patientComplexity-detail" md="12">
                      <Row>
                        <Col className="view-detail-label-left patient-patientComplexity-detail-label" md="3">
                          <dt>
                            <span id="patientComplexity">
                              <Translate contentKey="generadorApp.patient.patientComplexity">Patient complexity</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-left patient-patientComplexity-detail-value" md="9">
                          <dd>
                            {translate(
                              'generadorApp.PatientComplexity.' +
                                (patientEntity.patientComplexity ? patientEntity.patientComplexity : 'BAIXA')
                            )}
                          </dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row patient-patientDevice-detail" md="12">
                      <Row>
                        <Col className="view-detail-label-left patient-patientDevice-detail-label" md="3">
                          <dt>
                            <Translate contentKey="generadorApp.patient.patientDevice">Patient Device</Translate>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-left patient-patientDevice-detail-value" md="9">
                          <dd>
                            {patientEntity.patientDevices
                              ? patientEntity.patientDevices.map((val, i) => (
                                  <span key={val.id}>
                                    <a>{val.name}</a>
                                    {i === patientEntity.patientDevices.length - 1 ? '' : ', '}
                                  </span>
                                ))
                              : null}
                          </dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row patient-adId-detail" md="12">
                      <Row>
                        <Col className="view-detail-label-left patient-adId-detail-label" md="3">
                          <dt>
                            <span id="adId">
                              <Translate contentKey="generadorApp.patient.adId">AD/ID</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-left patient-adId-detail-value" md="9">
                          <dd>{translate('generadorApp.ADID.' + (patientEntity.adId ? patientEntity.adId : 'AD'))}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row patient-obese-detail" md="12">
                      <Row>
                        <Col className="view-detail-label-left patient-obese-detail-label" md="3">
                          <dt>
                            <span id="obese">
                              <Translate contentKey="generadorApp.patient.obese">Obese</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-left patient-obese-detail-value" md="9">
                          <dd>{translate('generadorApp.GeralSelect.' + (patientEntity.obese ? patientEntity.obese : 'SIM'))}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row patient-nead-detail" md="12">
                      <Row>
                        <Col className="view-detail-label-left patient-nead-detail-label" md="3">
                          <dt>
                            <span id="nead">
                              <Translate contentKey="generadorApp.patient.nead">NEAD</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-left patient-nead-detail-value" md="9">
                          <dd>{patientEntity.nead}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row patient-hospitalReference-detail" md="12">
                      <Row>
                        <Col className="view-detail-label-left patient-hospitalReference-detail-label" md="3">
                          <dt>
                            <span id="hospitalReference">
                              <Translate contentKey="generadorApp.patient.hospitalReference">Hospital Reference</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-left patient-hospitalReference-detail-value" md="9">
                          <dd>{patientEntity.hospitalReference}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row patient-observations-detail" md="12">
                      <Row>
                        <Col className="view-detail-label-left patient-observations-detail-label" md="3">
                          <dt>
                            <span id="observations">
                              <Translate contentKey="generadorApp.patient.observations">Observations</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-left patient-observations-detail-value" md="9">
                          <dd>{patientEntity.observations}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row patient-informationProfessional-detail" md="12">
                      <Row>
                        <Col className="view-detail-label-left patient-informationProfessional-detail-label" md="3">
                          <dt>
                            <span id="informationProfessional">
                              <Translate contentKey="generadorApp.patient.informationProfessional">Information Professional</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-left patient-informationProfessional-detail-value" md="9">
                          <dd>{patientEntity.informationProfessional}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row patient-professionalBlocked-detail" md="12">
                      <Row>
                        <Col className="view-detail-label-left patient-professionalBlocked-detail-label" md="3">
                          <dt>
                            <Translate contentKey="generadorApp.patient.professionalBlocked">Professional Blocked</Translate>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-left patient-professionalBlocked-detail-value" md="9">
                          <dd>
                            {patientEntity.professionalBlockeds
                              ? patientEntity.professionalBlockeds.map((val, i) => (
                                  <span key={val.id}>
                                    <a>{val.name}</a>
                                    {i === patientEntity.professionalBlockeds.length - 1 ? '' : ', '}
                                  </span>
                                ))
                              : null}
                          </dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row patient-professionalPreferred-detail" md="12">
                      <Row>
                        <Col className="view-detail-label-left patient-professionalPreferred-detail-label" md="3">
                          <dt>
                            <Translate contentKey="generadorApp.patient.professionalPreferred">Professional Preferred</Translate>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-left patient-professionalPreferred-detail-value" md="9">
                          <dd>
                            {patientEntity.professionalPreferreds
                              ? patientEntity.professionalPreferreds.map((val, i) => (
                                  <span key={val.id}>
                                    <a>{val.name}</a>
                                    {i === patientEntity.professionalPreferreds.length - 1 ? '' : ', '}
                                  </span>
                                ))
                              : null}
                          </dd>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={4}>
                  <Row>
                    {patientEntity.patientCid ? (
                      patientEntity.patientCid.map((v, i) => (
                        <div key={i} className="entityEmbebed">
                          <PatientCidDetail
                            history={this.props.history}
                            location={{
                              pathname: `/patient/${this.props.match.params['id']}/patient-cid/${v.id}`,
                              search: '',
                              hash: '',
                              state: undefined,
                              key: 'q2tbhd',
                            }}
                            match={{
                              path: `/patient/:idPatient/patient-cid/:id`,
                              url: `/patient/${this.props.match.params['id']}/patient-cid/${v.id}`,
                              isExact: true,
                              params: { id: '' + v.id },
                            }}
                            patientCidEntity={convertEntityBuffersPatientCid(v)}
                            getEntity={(): any => {}}
                          />
                        </div>
                      ))
                    ) : (
                      <> </>
                    )}
                  </Row>
                </TabPane>
                <TabPane tabId={5}>
                  <Row>
                    {patientEntity.patientAllergy ? (
                      patientEntity.patientAllergy.map((v, i) => (
                        <div key={i} className="entityEmbebed">
                          <PatientAllergyDetail
                            history={this.props.history}
                            location={{
                              pathname: `/patient/${this.props.match.params['id']}/patient-allergy/${v.id}`,
                              search: '',
                              hash: '',
                              state: undefined,
                              key: 'q2tbhd',
                            }}
                            match={{
                              path: `/patient/:idPatient/patient-allergy/:id`,
                              url: `/patient/${this.props.match.params['id']}/patient-allergy/${v.id}`,
                              isExact: true,
                              params: { id: '' + v.id },
                            }}
                            patientAllergyEntity={convertEntityBuffersPatientAllergy(v)}
                            getEntity={(): any => {}}
                          />
                        </div>
                      ))
                    ) : (
                      <> </>
                    )}
                  </Row>
                </TabPane>
                <TabPane tabId={6}>
                  <Row>
                    {patientEntity.patientResponsible ? (
                      patientEntity.patientResponsible.map((v, i) => (
                        <div key={i} className="entityEmbebed">
                          <PatientResponsibleDetail
                            history={this.props.history}
                            location={{
                              pathname: `/patient/${this.props.match.params['id']}/patient-responsible/${v.id}`,
                              search: '',
                              hash: '',
                              state: undefined,
                              key: 'q2tbhd',
                            }}
                            match={{
                              path: `/patient/:idPatient/patient-responsible/:id`,
                              url: `/patient/${this.props.match.params['id']}/patient-responsible/${v.id}`,
                              isExact: true,
                              params: { id: '' + v.id },
                            }}
                            patientResponsibleEntity={convertEntityBuffersPatientResponsible(v)}
                            getEntity={(): any => {}}
                          />
                        </div>
                      ))
                    ) : (
                      <> </>
                    )}
                  </Row>
                </TabPane>
                <TabPane tabId={7}>
                  <Row>
                    {patientEntity.patientSupplier ? (
                      patientEntity.patientSupplier.map((v, i) => (
                        <div key={i} className="entityEmbebed">
                          <PatientSupplierDetail
                            history={this.props.history}
                            location={{
                              pathname: `/patient/${this.props.match.params['id']}/patient-supplier/${v.id}`,
                              search: '',
                              hash: '',
                              state: undefined,
                              key: 'q2tbhd',
                            }}
                            match={{
                              path: `/patient/:idPatient/patient-supplier/:id`,
                              url: `/patient/${this.props.match.params['id']}/patient-supplier/${v.id}`,
                              isExact: true,
                              params: { id: '' + v.id },
                            }}
                            patientSupplierEntity={convertEntityBuffersPatientSupplier(v)}
                            getEntity={(): any => {}}
                          />
                        </div>
                      ))
                    ) : (
                      <> </>
                    )}
                  </Row>
                </TabPane>
                <TabPane tabId={8}>
                  <Row>
                    <Col md="12">
                      <AttendacesPatientList
                        entityBase={patientEntity}
                        baseState={this.state}
                        baseProps={this.props}
                        setState={_state => this.setState(_state)}
                      />
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { patientEntity } = this.props;
    const state = this.state;
    return (
      <div>
        <Panel className="patient patient-detail">
          <PanelHeader>{this.renderHeader()}</PanelHeader>

          <PanelBody>{this.renderBody()}</PanelBody>
        </Panel>
      </div>
    );
  }
}

export const mapStateToProps = ({ patient, ...storeState }: IRootState) => ({
  patientEntity: patient.entity,
  patientSupplierEntity: storeState.patientSupplier.entity,
  patientCidEntity: storeState.patientCid.entity,
  patientAllergyEntity: storeState.patientAllergy.entity,
  patientContactEntity: storeState.patientContact.entity,
  patientResponsibleEntity: storeState.patientResponsible.entity,
});

export const mapDispatchToProps = {
  getEntity,
  getEntityPatientSupplier,
  getEntityPatientCid,
  getEntityPatientAllergy,
  getEntityPatientContact,
  getEntityPatientResponsible,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PatientDetail);
