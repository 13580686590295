/* eslint complexity: ["error", 500] */
/* eslint no-empty: off */
import React from 'react';
import { connect } from 'react-redux';

import { Link, RouteComponentProps } from 'react-router-dom';

import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Col, Label } from 'reactstrap';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import {
  IKitUpdateState as IBaseUpdateState,
  getEntity,
  getKitState,
  getUrlBack,
  updateEntity,
  createEntity,
  getEntityFiltersURL,
  getEntityOffset,
  reset,
  kitRhsSetEntities,
  kitRhsGetEntities,
  kitRhsUpdateEntity,
  kitRhsReset,
  kitRhsGetEntity,
  kitRhsCreateEntity,
  kitRhsDeleteEntity,
  kitProductServiceSetEntities,
  kitProductServiceGetEntities,
  kitProductServiceUpdateEntity,
  kitProductServiceReset,
  kitProductServiceGetEntity,
  kitProductServiceCreateEntity,
  kitProductServiceDeleteEntity,
  kitMaterialSetEntities,
  kitMaterialGetEntities,
  kitMaterialUpdateEntity,
  kitMaterialReset,
  kitMaterialGetEntity,
  kitMaterialCreateEntity,
  kitMaterialDeleteEntity,
  kitMedicineSetEntities,
  kitMedicineGetEntities,
  kitMedicineUpdateEntity,
  kitMedicineReset,
  kitMedicineGetEntity,
  kitMedicineCreateEntity,
  kitMedicineDeleteEntity,
  kitDietSetEntities,
  kitDietGetEntities,
  kitDietUpdateEntity,
  kitDietReset,
  kitDietGetEntity,
  kitDietCreateEntity,
  kitDietDeleteEntity,
} from './kit.reducer';

import {
  mapStateToPropsExtended as mapStateToPropsKitRhs,
  mapDispatchToPropsExtended as mapDispatchToPropsKitRhs,
  KitRhsExtended as KitRhs,
} from 'app/entities/kit-rhs/extended/kit-rhs';

import {
  mapStateToPropsExtended as mapStateToPropsKitProductService,
  mapDispatchToPropsExtended as mapDispatchToPropsKitProductService,
  KitProductServiceExtended as KitProductService,
} from 'app/entities/kit-product-service/extended/kit-product-service';

import {
  mapStateToPropsExtended as mapStateToPropsKitMaterial,
  mapDispatchToPropsExtended as mapDispatchToPropsKitMaterial,
  KitMaterialExtended as KitMaterial,
} from 'app/entities/kit-material/extended/kit-material';

import {
  mapStateToPropsExtended as mapStateToPropsKitMedicine,
  mapDispatchToPropsExtended as mapDispatchToPropsKitMedicine,
  KitMedicineExtended as KitMedicine,
} from 'app/entities/kit-medicine/extended/kit-medicine';

import {
  mapStateToPropsExtended as mapStateToPropsKitDiet,
  mapDispatchToPropsExtended as mapDispatchToPropsKitDiet,
  KitDietExtended as KitDiet,
} from 'app/entities/kit-diet/extended/kit-diet';

import { StatePropsExtended, DispatchPropsExtended, IUpdateExtendedState } from './extended/kit-update';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

registerLocale('pt', pt);

export interface IKitUpdateProps
  extends StateProps,
    DispatchProps,
    StatePropsExtended,
    DispatchPropsExtended,
    RouteComponentProps<{ url: string }> {}
export interface IKitUpdateState extends IBaseUpdateState, IUpdateExtendedState {}

export class KitUpdate extends React.Component<IKitUpdateProps, IKitUpdateState> {
  nameFileInput: any;

  statusFileInput: any;
  constructor(props: Readonly<IKitUpdateProps>) {
    super(props);

    this.kitRhsUpdateEntity = this.kitRhsUpdateEntity.bind(this);
    this.kitRhsGetEntities = this.kitRhsGetEntities.bind(this);
    this.kitRhsReset = this.kitRhsReset.bind(this);
    this.kitRhsGetEntity = this.kitRhsGetEntity.bind(this);
    this.kitRhsCreateEntity = this.kitRhsCreateEntity.bind(this);
    this.kitRhsDeleteEntity = this.kitRhsDeleteEntity.bind(this);
    this.kitProductServiceUpdateEntity = this.kitProductServiceUpdateEntity.bind(this);
    this.kitProductServiceGetEntities = this.kitProductServiceGetEntities.bind(this);
    this.kitProductServiceReset = this.kitProductServiceReset.bind(this);
    this.kitProductServiceGetEntity = this.kitProductServiceGetEntity.bind(this);
    this.kitProductServiceCreateEntity = this.kitProductServiceCreateEntity.bind(this);
    this.kitProductServiceDeleteEntity = this.kitProductServiceDeleteEntity.bind(this);
    this.kitMaterialUpdateEntity = this.kitMaterialUpdateEntity.bind(this);
    this.kitMaterialGetEntities = this.kitMaterialGetEntities.bind(this);
    this.kitMaterialReset = this.kitMaterialReset.bind(this);
    this.kitMaterialGetEntity = this.kitMaterialGetEntity.bind(this);
    this.kitMaterialCreateEntity = this.kitMaterialCreateEntity.bind(this);
    this.kitMaterialDeleteEntity = this.kitMaterialDeleteEntity.bind(this);
    this.kitMedicineUpdateEntity = this.kitMedicineUpdateEntity.bind(this);
    this.kitMedicineGetEntities = this.kitMedicineGetEntities.bind(this);
    this.kitMedicineReset = this.kitMedicineReset.bind(this);
    this.kitMedicineGetEntity = this.kitMedicineGetEntity.bind(this);
    this.kitMedicineCreateEntity = this.kitMedicineCreateEntity.bind(this);
    this.kitMedicineDeleteEntity = this.kitMedicineDeleteEntity.bind(this);
    this.kitDietUpdateEntity = this.kitDietUpdateEntity.bind(this);
    this.kitDietGetEntities = this.kitDietGetEntities.bind(this);
    this.kitDietReset = this.kitDietReset.bind(this);
    this.kitDietGetEntity = this.kitDietGetEntity.bind(this);
    this.kitDietCreateEntity = this.kitDietCreateEntity.bind(this);
    this.kitDietDeleteEntity = this.kitDietDeleteEntity.bind(this);

    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      functionEmbebed: [],

      kitDietSelectValue: null,

      kitMaterialSelectValue: null,

      kitMedicineSelectValue: null,

      kitProductServiceSelectValue: null,

      kitRhsSelectValue: null,

      kitStatusHistorySelectValue: null,

      categorySelectValue: null,

      nameSelectValue: null,
      statusSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getKitState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
      idscategory: [],

      isNew: !this.props.match.params || !this.props.match.params['id'],
    };
  }

  kitRhsUpdateEntity(...values) {
    values[0]['kit'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['updateEntityKitRhs'](...values);
    }
    return this.props.kitRhsUpdateEntity(...values);
  }
  kitRhsGetEntities(...values) {
    return this.props.kitRhsGetEntities(...values);
  }
  kitRhsReset(...values) {
    return this.props.kitRhsReset(...values);
  }
  kitRhsGetEntity(...values) {
    return this.props.kitRhsGetEntity(...values);
  }
  kitRhsCreateEntity(...values) {
    values[0]['kit'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['createEntityKitRhs'](...values);
    }
    return this.props.kitRhsCreateEntity(...values);
  }
  kitRhsDeleteEntity(...values) {
    if (!this.state.isNew) {
      this.props['deleteEntityKitRhs'](...values);
    }
    return this.props.kitRhsDeleteEntity(...values);
  }
  kitProductServiceUpdateEntity(...values) {
    values[0]['kit'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['updateEntityKitProductService'](...values);
    }
    return this.props.kitProductServiceUpdateEntity(...values);
  }
  kitProductServiceGetEntities(...values) {
    return this.props.kitProductServiceGetEntities(...values);
  }
  kitProductServiceReset(...values) {
    return this.props.kitProductServiceReset(...values);
  }
  kitProductServiceGetEntity(...values) {
    return this.props.kitProductServiceGetEntity(...values);
  }
  kitProductServiceCreateEntity(...values) {
    values[0]['kit'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['createEntityKitProductService'](...values);
    }
    return this.props.kitProductServiceCreateEntity(...values);
  }
  kitProductServiceDeleteEntity(...values) {
    if (!this.state.isNew) {
      this.props['deleteEntityKitProductService'](...values);
    }
    return this.props.kitProductServiceDeleteEntity(...values);
  }
  kitMaterialUpdateEntity(...values) {
    values[0]['kit'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['updateEntityKitMaterial'](...values);
    }
    return this.props.kitMaterialUpdateEntity(...values);
  }
  kitMaterialGetEntities(...values) {
    return this.props.kitMaterialGetEntities(...values);
  }
  kitMaterialReset(...values) {
    return this.props.kitMaterialReset(...values);
  }
  kitMaterialGetEntity(...values) {
    return this.props.kitMaterialGetEntity(...values);
  }
  kitMaterialCreateEntity(...values) {
    values[0]['kit'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['createEntityKitMaterial'](...values);
    }
    return this.props.kitMaterialCreateEntity(...values);
  }
  kitMaterialDeleteEntity(...values) {
    if (!this.state.isNew) {
      this.props['deleteEntityKitMaterial'](...values);
    }
    return this.props.kitMaterialDeleteEntity(...values);
  }
  kitMedicineUpdateEntity(...values) {
    values[0]['kit'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['updateEntityKitMedicine'](...values);
    }
    return this.props.kitMedicineUpdateEntity(...values);
  }
  kitMedicineGetEntities(...values) {
    return this.props.kitMedicineGetEntities(...values);
  }
  kitMedicineReset(...values) {
    return this.props.kitMedicineReset(...values);
  }
  kitMedicineGetEntity(...values) {
    return this.props.kitMedicineGetEntity(...values);
  }
  kitMedicineCreateEntity(...values) {
    values[0]['kit'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['createEntityKitMedicine'](...values);
    }
    return this.props.kitMedicineCreateEntity(...values);
  }
  kitMedicineDeleteEntity(...values) {
    if (!this.state.isNew) {
      this.props['deleteEntityKitMedicine'](...values);
    }
    return this.props.kitMedicineDeleteEntity(...values);
  }
  kitDietUpdateEntity(...values) {
    values[0]['kit'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['updateEntityKitDiet'](...values);
    }
    return this.props.kitDietUpdateEntity(...values);
  }
  kitDietGetEntities(...values) {
    return this.props.kitDietGetEntities(...values);
  }
  kitDietReset(...values) {
    return this.props.kitDietReset(...values);
  }
  kitDietGetEntity(...values) {
    return this.props.kitDietGetEntity(...values);
  }
  kitDietCreateEntity(...values) {
    values[0]['kit'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['createEntityKitDiet'](...values);
    }
    return this.props.kitDietCreateEntity(...values);
  }
  kitDietDeleteEntity(...values) {
    if (!this.state.isNew) {
      this.props['deleteEntityKitDiet'](...values);
    }
    return this.props.kitDietDeleteEntity(...values);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateSuccess !== this.props.updateSuccess && prevProps.updateSuccess) {
      this.handleClose();
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.kitEntity && prevProps.kitEntity.id !== this.props.kitEntity.id) ||
      (this.props.kitDiets.length > 0 && this.state.kitDietSelectValue === null && this.props.kitEntity.kitDiet)
    ) {
      this.setState({ kitDietSelectValue: this.props.kitEntity.kitDiet });
      this.props.kitDietSetEntities(this.props.kitEntity.kitDiet);
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.kitEntity && prevProps.kitEntity.id !== this.props.kitEntity.id) ||
      (this.props.kitMaterials.length > 0 && this.state.kitMaterialSelectValue === null && this.props.kitEntity.kitMaterial)
    ) {
      this.setState({ kitMaterialSelectValue: this.props.kitEntity.kitMaterial });
      this.props.kitMaterialSetEntities(this.props.kitEntity.kitMaterial);
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.kitEntity && prevProps.kitEntity.id !== this.props.kitEntity.id) ||
      (this.props.kitMedicines.length > 0 && this.state.kitMedicineSelectValue === null && this.props.kitEntity.kitMedicine)
    ) {
      this.setState({ kitMedicineSelectValue: this.props.kitEntity.kitMedicine });
      this.props.kitMedicineSetEntities(this.props.kitEntity.kitMedicine);
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.kitEntity && prevProps.kitEntity.id !== this.props.kitEntity.id) ||
      (this.props.kitProductServices.length > 0 &&
        this.state.kitProductServiceSelectValue === null &&
        this.props.kitEntity.kitProductService)
    ) {
      this.setState({ kitProductServiceSelectValue: this.props.kitEntity.kitProductService });
      this.props.kitProductServiceSetEntities(this.props.kitEntity.kitProductService);
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.kitEntity && prevProps.kitEntity.id !== this.props.kitEntity.id) ||
      (this.props.kitRhs.length > 0 && this.state.kitRhsSelectValue === null && this.props.kitEntity.kitRhs)
    ) {
      this.setState({ kitRhsSelectValue: this.props.kitEntity.kitRhs });
      this.props.kitRhsSetEntities(this.props.kitEntity.kitRhs);
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.kitEntity && prevProps.kitEntity.id !== this.props.kitEntity.id) ||
      (this.props.kitStatusHistories.length > 0 && this.state.kitStatusHistorySelectValue === null && this.props.kitEntity.kitStatusHistory)
    ) {
      this.setState({
        kitStatusHistorySelectValue:
          this.props.kitEntity && this.props.kitEntity.kitStatusHistory
            ? this.props.kitEntity.kitStatusHistory.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-1 many-to-many owner-side
    if (
      (prevProps.kitEntity && prevProps.kitEntity.id !== this.props.kitEntity.id) ||
      (this.props.categories.length > 0 && this.state.categorySelectValue === null && this.props.kitEntity.categories)
    ) {
      this.setState({
        categorySelectValue:
          this.props.kitEntity && this.props.kitEntity.categories
            ? this.props.kitEntity.categories.filter(p => p).map(p => ({ value: p.id, label: p['name'] ? p.name : '' }))
            : [],
      });
    }

    if (
      (prevProps.kitEntity && prevProps.kitEntity.id !== this.props.kitEntity.id) ||
      (this.props.kitEntity.name && this.state.nameSelectValue === null && this.props.kitEntity.name !== this.state.nameSelectValue)
    ) {
      this.setState({ nameSelectValue: this.props.kitEntity.name ? this.props.kitEntity.name : null });
    }
    if (
      (prevProps.kitEntity && prevProps.kitEntity.id !== this.props.kitEntity.id) ||
      (this.state.statusSelectValue === null && this.props.kitEntity.status !== this.state.statusSelectValue)
    ) {
      this.setState({ statusSelectValue: this.props.kitEntity.status ? this.props.kitEntity.status : 'ATIVO' });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  UNSAFE_componentWillMount() {
    if (this.state.isNew) {
      this.props.reset();
      this.kitRhsReset();
      this.kitProductServiceReset();
      this.kitMaterialReset();
      this.kitMedicineReset();
      this.kitDietReset();
    } else {
      this.props.getEntity(this.props.match.params['id']);
    }
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = offset !== null ? '&offset=' + offset : '';
    Object.keys(fieldsBase).map(key => {
      url += key !== 'offset' ? '&' + key + '=' + fieldsBase[key] : '';
    });
    return url;
  };
  saveEntity = (event: any, errors: any, values: any) => {
    if (!this.state.nameSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.kit.name') }));
      return false;
    }

    const selectFieldsList = ['name', 'createdDate', 'lastModifiedDate', 'status'];

    if (errors.length === 0) {
      const { kitEntity } = this.props;
      const _entity = {
        ...kitEntity,
        // ...values,

        kitRhs: undefined,

        kitProductService: undefined,

        kitMaterial: undefined,

        kitMedicine: undefined,

        kitDiet: undefined,

        name: this.state.nameSelectValue,
        status: this.state.statusSelectValue === null ? 'INATIVADO' : this.state.statusSelectValue,
      };

      const entity = _entity;

      const {
        name,
        createdDateStart,
        createdDateEnd,
        lastModifiedDateStart,
        lastModifiedDateEnd,
        status,
        extraFilters,
        activePage,
        itemsPerPage,
        sort,
        order,
      } = this.state.fieldsBase;

      const kitRhsListAllEmbed = this.props.kitRhsListAllEmbed;
      const kitProductServiceListAllEmbed = this.props.kitProductServiceListAllEmbed;
      const kitMaterialListAllEmbed = this.props.kitMaterialListAllEmbed;
      const kitMedicineListAllEmbed = this.props.kitMedicineListAllEmbed;
      const kitDietListAllEmbed = this.props.kitDietListAllEmbed;

      if (this.state.isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              name,
              createdDateStart,
              createdDateEnd,
              lastModifiedDateStart,
              lastModifiedDateEnd,
              status,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
          if (idEntity) {
            kitRhsListAllEmbed.map(v => {
              v['id'] = null;
              v['kit'] = { id: idEntity };
              this.props['createEntityKitRhs'](v);
            });
          }
          if (idEntity) {
            kitProductServiceListAllEmbed.map(v => {
              v['id'] = null;
              v['kit'] = { id: idEntity };
              this.props['createEntityKitProductService'](v);
            });
          }
          if (idEntity) {
            kitMaterialListAllEmbed.map(v => {
              v['id'] = null;
              v['kit'] = { id: idEntity };
              this.props['createEntityKitMaterial'](v);
            });
          }
          if (idEntity) {
            kitMedicineListAllEmbed.map(v => {
              v['id'] = null;
              v['kit'] = { id: idEntity };
              this.props['createEntityKitMedicine'](v);
            });
          }
          if (idEntity) {
            kitDietListAllEmbed.map(v => {
              v['id'] = null;
              v['kit'] = { id: idEntity };
              this.props['createEntityKitDiet'](v);
            });
          }
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              name,
              createdDateStart,
              createdDateEnd,
              lastModifiedDateStart,
              lastModifiedDateEnd,
              status,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/kit?' + getEntityFiltersURL(this.state.fieldsBase));
  };

  getFullEntityFromState(v) {
    return {
      id: v.id,
      name: this.state.nameSelectValue,
      status: this.state.statusSelectValue,
      kitDiet: this.state.kitDietSelectValue,
      kitMaterial: this.state.kitMaterialSelectValue,
      kitMedicine: this.state.kitMedicineSelectValue,
      kitProductService: this.state.kitProductServiceSelectValue,
      kitRhs: this.state.kitRhsSelectValue,
      kitStatusHistory: this.state.kitStatusHistorySelectValue,
      category: this.state.categorySelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    const kitDietEntity = v.kitDiet;

    const kitMaterialEntity = v.kitMaterial;

    const kitMedicineEntity = v.kitMedicine;

    const kitProductServiceEntity = v.kitProductService;

    const kitRhsEntity = v.kitRhs;

    const kitStatusHistoryEntity = v.kitStatusHistory;

    const categoryEntity = this.props.categories.filter(x => x.kits.map(x1 => x1.id).includes(v.id)).pop();

    return {
      nameSelectValue: v.name,
      statusSelectValue: v.status,
      kitDietSelectValue: kitDietEntity ? kitDietEntity['id'] : null,
      kitMaterialSelectValue: kitMaterialEntity ? kitMaterialEntity['id'] : null,
      kitMedicineSelectValue: kitMedicineEntity ? kitMedicineEntity['id'] : null,
      kitProductServiceSelectValue: kitProductServiceEntity ? kitProductServiceEntity['id'] : null,
      kitRhsSelectValue: kitRhsEntity ? kitRhsEntity['id'] : null,
      kitStatusHistorySelectValue: kitStatusHistoryEntity ? kitStatusHistoryEntity['id'] : null,
      categorySelectValue: categoryEntity ? categoryEntity['id'] : null,
    };
  }

  renderHeader() {
    const {
      kitEntity,
      kitDiets,
      kitMaterials,
      kitMedicines,
      kitProductServices,
      kitRhs,
      kitStatusHistories,
      categories,
      loading,
      updating,
    } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.kit.home.createLabel">Create a Kit</Translate>
            ) : (
              <Translate contentKey="generadorApp.kit.home.editLabel">Edit a Kit</Translate>
            )}
          </span>

          <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.kit.btnSave">Save</Translate>
          </Button>

          {!this.state.isNew ? (
            <>
              <Button
                color="primary"
                id="next-entity"
                disabled={updating}
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.name,
                    this.state.fieldsBase.createdDateStart,
                    this.state.fieldsBase.createdDateEnd,
                    this.state.fieldsBase.lastModifiedDateStart,
                    this.state.fieldsBase.lastModifiedDateEnd,
                    this.state.fieldsBase.status,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 + 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 + 1 },
                    });
                    this.props.history.push(
                      '/kit/' + myresult.value.data[0]['id'] + '/edit?' + this.getFiltersURL(this.state.fieldsBase.offset * 1 + 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.last.item', { fieldName: translate('generadorApp.kit.detail.title') }));
                  }
                }}
                className="float-right jh-create-entity"
              >
                {'>>'}
              </Button>

              <Button
                color="primary"
                id="prev-entity"
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.name,
                    this.state.fieldsBase.createdDateStart,
                    this.state.fieldsBase.createdDateEnd,
                    this.state.fieldsBase.lastModifiedDateStart,
                    this.state.fieldsBase.lastModifiedDateEnd,
                    this.state.fieldsBase.status,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 - 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 - 1 },
                    });
                    this.props.history.push(
                      '/kit/' + myresult.value.data[0]['id'] + '/edit?' + this.getFiltersURL(this.state.fieldsBase.offset * 1 - 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.first.item', { fieldName: translate('generadorApp.kit.detail.title') }));
                  }
                }}
                disabled={updating}
                className="float-right jh-create-entity"
              >
                {'<<'}
              </Button>
            </>
          ) : (
            <> </>
          )}

          <Button
            tag={Link}
            id="cancel-save"
            to={'/kit?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.kit.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={'/kit'}>
              <Translate contentKey="generadorApp.kit.home.title">Kits</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.kit.detail.title">Kit edit</Translate>
          </li>
        </ol>
      </>
    );
  }
  renderBody() {
    const {
      kitEntity,
      kitDiets,
      kitMaterials,
      kitMedicines,
      kitProductServices,
      kitRhs,
      kitStatusHistories,
      categories,
      loading,
      updating,
    } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const fullEntityEmbebed = this.getFullEntityFromState(kitEntity);

    const mapDispatchToPropsKitRhsList: any = {};
    Object.keys(mapDispatchToPropsKitRhs).map(v => {
      mapDispatchToPropsKitRhsList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'KitRhs')) {
          this.setState({ functionEmbebed: [...this.state.functionEmbebed, v + 'KitRhs'] }, this.props[v + 'KitRhs']);
        }
      };
    });
    const mapDispatchToPropsKitProductServiceList: any = {};
    Object.keys(mapDispatchToPropsKitProductService).map(v => {
      mapDispatchToPropsKitProductServiceList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'KitProductService')) {
          this.setState({ functionEmbebed: [...this.state.functionEmbebed, v + 'KitProductService'] }, this.props[v + 'KitProductService']);
        }
      };
    });
    const mapDispatchToPropsKitMaterialList: any = {};
    Object.keys(mapDispatchToPropsKitMaterial).map(v => {
      mapDispatchToPropsKitMaterialList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'KitMaterial')) {
          this.setState({ functionEmbebed: [...this.state.functionEmbebed, v + 'KitMaterial'] }, this.props[v + 'KitMaterial']);
        }
      };
    });
    const mapDispatchToPropsKitMedicineList: any = {};
    Object.keys(mapDispatchToPropsKitMedicine).map(v => {
      mapDispatchToPropsKitMedicineList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'KitMedicine')) {
          this.setState({ functionEmbebed: [...this.state.functionEmbebed, v + 'KitMedicine'] }, this.props[v + 'KitMedicine']);
        }
      };
    });
    const mapDispatchToPropsKitDietList: any = {};
    Object.keys(mapDispatchToPropsKitDiet).map(v => {
      mapDispatchToPropsKitDietList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'KitDiet')) {
          this.setState({ functionEmbebed: [...this.state.functionEmbebed, v + 'KitDiet'] }, this.props[v + 'KitDiet']);
        }
      };
    });

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="kit-id">
                  <Translate contentKey="generadorApp.kit.field_id">ID</Translate>
                </Label>
                </Col> */}
                      <Col md="12">
                        <AvInput id="kit-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'name' ? (
                    <Col md="9">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="nameLabel" for="kit-name">
                              <Translate contentKey="generadorApp.kit.name">Name</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                              value={this.state.nameSelectValue ? this.state.nameSelectValue : ''}
                              id="kit-name"
                              type="text"
                              name="name"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                      type="hidden"
                      name="name"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'kitRhs' ? (
                    <Col md="12">
                      <div className="entityEmbebedList">
                        <KitRhs
                          entityEmbebedPai={fullEntityEmbebed}
                          history={this.props.history}
                          location={{
                            pathname: `/kit/${this.props.match.params['id']}/kit-rhs/`,
                            search: '',
                            hash: '',
                            state: undefined,
                            key: 'q2tbhd',
                          }}
                          match={{
                            path: `/kit/:idKit/kit-rhs/:id`,
                            url: `/kit/${this.props.match.params['id']}/kit-rhs/`,
                            isExact: true,
                            params: { idKit: this.props.match.params['id'], url: `/kit/${this.props.match.params['id']}/kit-rhs/` },
                          }}
                          {...this.props.mapStateToPropsKitRhs}
                          {...mapDispatchToPropsKitRhsList}
                          getEntityPai={(...v) => this.props.getEntity(...v)}
                          getEntities={this.kitRhsGetEntities}
                          updateEntity={this.kitRhsUpdateEntity}
                          getEntity={this.kitRhsGetEntity}
                          createEntity={this.kitRhsCreateEntity}
                          deleteEntity={this.kitRhsDeleteEntity}
                          reset={this.kitRhsReset}
                          kitRhsList={this.props.kitRhsListEmbed}
                          totalItems={this.props.kitRhsListEmbed ? this.props.kitRhsListEmbed.length : 0}
                          loading={this.props.loading}
                          kitRhsEntity={this.props.kitRhsSelected}
                        />
                      </div>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="kitRhs"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}
                  {baseFilters !== 'kitProductService' ? (
                    <Col md="12">
                      <div className="entityEmbebedList">
                        <KitProductService
                          entityEmbebedPai={fullEntityEmbebed}
                          history={this.props.history}
                          location={{
                            pathname: `/kit/${this.props.match.params['id']}/kit-product-service/`,
                            search: '',
                            hash: '',
                            state: undefined,
                            key: 'q2tbhd',
                          }}
                          match={{
                            path: `/kit/:idKit/kit-product-service/:id`,
                            url: `/kit/${this.props.match.params['id']}/kit-product-service/`,
                            isExact: true,
                            params: {
                              idKit: this.props.match.params['id'],
                              url: `/kit/${this.props.match.params['id']}/kit-product-service/`,
                            },
                          }}
                          {...this.props.mapStateToPropsKitProductService}
                          {...mapDispatchToPropsKitProductServiceList}
                          getEntityPai={(...v) => this.props.getEntity(...v)}
                          getEntities={this.kitProductServiceGetEntities}
                          updateEntity={this.kitProductServiceUpdateEntity}
                          getEntity={this.kitProductServiceGetEntity}
                          createEntity={this.kitProductServiceCreateEntity}
                          deleteEntity={this.kitProductServiceDeleteEntity}
                          reset={this.kitProductServiceReset}
                          kitProductServiceList={this.props.kitProductServiceListEmbed}
                          totalItems={this.props.kitProductServiceListEmbed ? this.props.kitProductServiceListEmbed.length : 0}
                          loading={this.props.loading}
                          kitProductServiceEntity={this.props.kitProductServiceSelected}
                        />
                      </div>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="kitProductService"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}
                  {baseFilters !== 'kitMaterial' ? (
                    <Col md="12">
                      <div className="entityEmbebedList">
                        <KitMaterial
                          entityEmbebedPai={fullEntityEmbebed}
                          history={this.props.history}
                          location={{
                            pathname: `/kit/${this.props.match.params['id']}/kit-material/`,
                            search: '',
                            hash: '',
                            state: undefined,
                            key: 'q2tbhd',
                          }}
                          match={{
                            path: `/kit/:idKit/kit-material/:id`,
                            url: `/kit/${this.props.match.params['id']}/kit-material/`,
                            isExact: true,
                            params: { idKit: this.props.match.params['id'], url: `/kit/${this.props.match.params['id']}/kit-material/` },
                          }}
                          {...this.props.mapStateToPropsKitMaterial}
                          {...mapDispatchToPropsKitMaterialList}
                          getEntityPai={(...v) => this.props.getEntity(...v)}
                          getEntities={this.kitMaterialGetEntities}
                          updateEntity={this.kitMaterialUpdateEntity}
                          getEntity={this.kitMaterialGetEntity}
                          createEntity={this.kitMaterialCreateEntity}
                          deleteEntity={this.kitMaterialDeleteEntity}
                          reset={this.kitMaterialReset}
                          kitMaterialList={this.props.kitMaterialListEmbed}
                          totalItems={this.props.kitMaterialListEmbed ? this.props.kitMaterialListEmbed.length : 0}
                          loading={this.props.loading}
                          kitMaterialEntity={this.props.kitMaterialSelected}
                        />
                      </div>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="kitMaterial"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}
                  {baseFilters !== 'kitMedicine' ? (
                    <Col md="12">
                      <div className="entityEmbebedList">
                        <KitMedicine
                          entityEmbebedPai={fullEntityEmbebed}
                          history={this.props.history}
                          location={{
                            pathname: `/kit/${this.props.match.params['id']}/kit-medicine/`,
                            search: '',
                            hash: '',
                            state: undefined,
                            key: 'q2tbhd',
                          }}
                          match={{
                            path: `/kit/:idKit/kit-medicine/:id`,
                            url: `/kit/${this.props.match.params['id']}/kit-medicine/`,
                            isExact: true,
                            params: { idKit: this.props.match.params['id'], url: `/kit/${this.props.match.params['id']}/kit-medicine/` },
                          }}
                          {...this.props.mapStateToPropsKitMedicine}
                          {...mapDispatchToPropsKitMedicineList}
                          getEntityPai={(...v) => this.props.getEntity(...v)}
                          getEntities={this.kitMedicineGetEntities}
                          updateEntity={this.kitMedicineUpdateEntity}
                          getEntity={this.kitMedicineGetEntity}
                          createEntity={this.kitMedicineCreateEntity}
                          deleteEntity={this.kitMedicineDeleteEntity}
                          reset={this.kitMedicineReset}
                          kitMedicineList={this.props.kitMedicineListEmbed}
                          totalItems={this.props.kitMedicineListEmbed ? this.props.kitMedicineListEmbed.length : 0}
                          loading={this.props.loading}
                          kitMedicineEntity={this.props.kitMedicineSelected}
                        />
                      </div>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="kitMedicine"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}
                  {baseFilters !== 'kitDiet' ? (
                    <Col md="12">
                      <div className="entityEmbebedList">
                        <KitDiet
                          entityEmbebedPai={fullEntityEmbebed}
                          history={this.props.history}
                          location={{
                            pathname: `/kit/${this.props.match.params['id']}/kit-diet/`,
                            search: '',
                            hash: '',
                            state: undefined,
                            key: 'q2tbhd',
                          }}
                          match={{
                            path: `/kit/:idKit/kit-diet/:id`,
                            url: `/kit/${this.props.match.params['id']}/kit-diet/`,
                            isExact: true,
                            params: { idKit: this.props.match.params['id'], url: `/kit/${this.props.match.params['id']}/kit-diet/` },
                          }}
                          {...this.props.mapStateToPropsKitDiet}
                          {...mapDispatchToPropsKitDietList}
                          getEntityPai={(...v) => this.props.getEntity(...v)}
                          getEntities={this.kitDietGetEntities}
                          updateEntity={this.kitDietUpdateEntity}
                          getEntity={this.kitDietGetEntity}
                          createEntity={this.kitDietCreateEntity}
                          deleteEntity={this.kitDietDeleteEntity}
                          reset={this.kitDietReset}
                          kitDietList={this.props.kitDietListEmbed}
                          totalItems={this.props.kitDietListEmbed ? this.props.kitDietListEmbed.length : 0}
                          loading={this.props.loading}
                          kitDietEntity={this.props.kitDietSelected}
                        />
                      </div>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="kitDiet"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}{' '}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const {
      kitEntity,
      kitDiets,
      kitMaterials,
      kitMedicines,
      kitProductServices,
      kitRhs,
      kitStatusHistories,
      categories,
      loading,
      updating,
    } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...kitEntity,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel className="kit kit-update">
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToProps = (storeState: IRootState) => {
  return {
    kitDiets: storeState.kitDiet.entities,
    kitMaterials: storeState.kitMaterial.entities,
    kitMedicines: storeState.kitMedicine.entities,
    kitProductServices: storeState.kitProductService.entities,
    kitRhs: storeState.kitRhs.entities,
    kitStatusHistories: storeState.kitStatusHistory.entities,
    categories: storeState.category.entities,
    kitEntity: storeState.kit.entity,
    loading: storeState.kit.loading,
    updating: storeState.kit.updating,
    updateSuccess: storeState.kit.updateSuccess,

    kits: storeState.kit.entities,
    kitRhsListAllEmbed: storeState.kit.kitRhsListAllEmbed,
    kitRhsListEmbed: storeState.kit.kitRhsListEmbed,
    kitRhsSelected: storeState.kit.kitRhsSelected,
    mapStateToPropsKitRhs: mapStateToPropsKitRhs(storeState),

    kitProductServiceListAllEmbed: storeState.kit.kitProductServiceListAllEmbed,
    kitProductServiceListEmbed: storeState.kit.kitProductServiceListEmbed,
    kitProductServiceSelected: storeState.kit.kitProductServiceSelected,
    mapStateToPropsKitProductService: mapStateToPropsKitProductService(storeState),

    kitMaterialListAllEmbed: storeState.kit.kitMaterialListAllEmbed,
    kitMaterialListEmbed: storeState.kit.kitMaterialListEmbed,
    kitMaterialSelected: storeState.kit.kitMaterialSelected,
    mapStateToPropsKitMaterial: mapStateToPropsKitMaterial(storeState),

    kitMedicineListAllEmbed: storeState.kit.kitMedicineListAllEmbed,
    kitMedicineListEmbed: storeState.kit.kitMedicineListEmbed,
    kitMedicineSelected: storeState.kit.kitMedicineSelected,
    mapStateToPropsKitMedicine: mapStateToPropsKitMedicine(storeState),

    kitDietListAllEmbed: storeState.kit.kitDietListAllEmbed,
    kitDietListEmbed: storeState.kit.kitDietListEmbed,
    kitDietSelected: storeState.kit.kitDietSelected,
    mapStateToPropsKitDiet: mapStateToPropsKitDiet(storeState),
  };
};

const mapDispatchToPropsKitRhsList = {};
Object.keys(mapDispatchToPropsKitRhs).map(v => {
  mapDispatchToPropsKitRhsList[v + 'KitRhs'] = mapDispatchToPropsKitRhs[v];
});
const mapDispatchToPropsKitProductServiceList = {};
Object.keys(mapDispatchToPropsKitProductService).map(v => {
  mapDispatchToPropsKitProductServiceList[v + 'KitProductService'] = mapDispatchToPropsKitProductService[v];
});
const mapDispatchToPropsKitMaterialList = {};
Object.keys(mapDispatchToPropsKitMaterial).map(v => {
  mapDispatchToPropsKitMaterialList[v + 'KitMaterial'] = mapDispatchToPropsKitMaterial[v];
});
const mapDispatchToPropsKitMedicineList = {};
Object.keys(mapDispatchToPropsKitMedicine).map(v => {
  mapDispatchToPropsKitMedicineList[v + 'KitMedicine'] = mapDispatchToPropsKitMedicine[v];
});
const mapDispatchToPropsKitDietList = {};
Object.keys(mapDispatchToPropsKitDiet).map(v => {
  mapDispatchToPropsKitDietList[v + 'KitDiet'] = mapDispatchToPropsKitDiet[v];
});

export const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  getEntityOffset,
  reset,

  ...mapDispatchToPropsKitRhsList,
  kitRhsUpdateEntity,
  kitRhsGetEntities,
  kitRhsSetEntities,
  kitRhsReset,
  kitRhsGetEntity,
  kitRhsCreateEntity,
  kitRhsDeleteEntity,
  ...mapDispatchToPropsKitProductServiceList,
  kitProductServiceUpdateEntity,
  kitProductServiceGetEntities,
  kitProductServiceSetEntities,
  kitProductServiceReset,
  kitProductServiceGetEntity,
  kitProductServiceCreateEntity,
  kitProductServiceDeleteEntity,
  ...mapDispatchToPropsKitMaterialList,
  kitMaterialUpdateEntity,
  kitMaterialGetEntities,
  kitMaterialSetEntities,
  kitMaterialReset,
  kitMaterialGetEntity,
  kitMaterialCreateEntity,
  kitMaterialDeleteEntity,
  ...mapDispatchToPropsKitMedicineList,
  kitMedicineUpdateEntity,
  kitMedicineGetEntities,
  kitMedicineSetEntities,
  kitMedicineReset,
  kitMedicineGetEntity,
  kitMedicineCreateEntity,
  kitMedicineDeleteEntity,
  ...mapDispatchToPropsKitDietList,
  kitDietUpdateEntity,
  kitDietGetEntities,
  kitDietSetEntities,
  kitDietReset,
  kitDietGetEntity,
  kitDietCreateEntity,
  kitDietDeleteEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(KitUpdate);
