/* eslint complexity: ["error", 500] */
import React from 'react';
import { connect } from 'react-redux';

import { QuotePackage, mapStateToProps, mapDispatchToProps } from '../quote-package';
import { IRootState } from 'app/shared/reducers';
import moment from 'moment';
import { getFilterFromSelect } from 'app/shared/util/entity-utils';
import { toast } from 'react-toastify';
import { translate } from 'app/config/translate-component';
import { convertDateTimeToServer } from 'app/shared/util/date-utils';

export interface IExtendedState {
  dumystate?: any;
}
export interface IExtendedProps {
  entityEmbebedPai?: any;
}

export class QuotePackageExtended extends QuotePackage {
  renderHeader() {
    return <> {super.renderHeader()} </>;
  }

  renderFilter() {
    return <> {super.renderFilter()} </>;
  }

  saveEntity = (event: any, errors: any, values: any, isNew: any) => {
    const selectFieldsList = ['clComTable.name', 'startDate', 'finishDate', 'days', 'grossAmount', 'finalAmount'];

    values.startDate = convertDateTimeToServer(values.startDate);
    values.finishDate = convertDateTimeToServer(values.finishDate);
    if (errors.length === 0) {
      const { quotePackageEntity } = this.props;
      const _entity = {
        ...quotePackageEntity,
        // ...values,

        startDate: this.state.startDateSelectValue,
        clComTable: { id: this.state.clComTableSelectValue.id },
        finishDate: this.state.finishDateSelectValue,
        days: this.state.daysSelectValue,
        quantity: this.state.quantitySelectValue,
        unitaryAmount: this.state.unitaryAmountSelectValue,
        grossAmount: this.state.grossAmountSelectValue,
        typeDiscount: this.state.typeDiscountSelectValue === null ? null : this.state.typeDiscountSelectValue,
        discountPercent: this.state.discountPercentSelectValue,
        discount: this.state.discountSelectValue,
        finalAmount: this.state.finalAmountSelectValue,
        frequency: this.state.frequencySelectValue === null ? null : this.state.frequencySelectValue,
        additive: this.state.additiveSelectValue === null ? false : this.state.additiveSelectValue,
        billQuantityItem: this.state.billQuantityItemSelectValue ? this.state.billGrossAmountSelectValue : this.state.daysSelectValue,
        billGrossAmount: this.state.billGrossAmountSelectValue ? this.state.billGrossAmountSelectValue : this.state.grossAmountSelectValue,
        billFinalAmount: this.state.billFinalAmountSelectValue ? this.state.billFinalAmountSelectValue : this.state.finalAmountSelectValue,
      };
      const entity = _entity;

      const {
        startDateStart,
        startDateEnd,
        finishDateStart,
        finishDateEnd,
        days,
        quantity,
        unitaryAmount,
        grossAmount,
        typeDiscount,
        discountPercent,
        discount,
        finalAmount,
        frequency,
        additive,
        billQuantityItem,
        billFinalAmount,
        clientGlosaItemId,
        quoteId,
        clComTableId,
        extraFilters,
        activePage,
        itemsPerPage,
        sort,
        order,
      } = this.state.fieldsBase;

      if (isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              startDateStart,
              startDateEnd,
              finishDateStart,
              finishDateEnd,
              days,
              quantity,
              unitaryAmount,
              grossAmount,
              typeDiscount,
              discountPercent,
              discount,
              finalAmount,
              frequency,
              additive,
              billQuantityItem,
              billFinalAmount,
              getFilterFromSelect(clientGlosaItemId, 'one-to-many'),
              getFilterFromSelect(quoteId, 'many-to-one'),
              getFilterFromSelect(clComTableId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              startDateStart,
              startDateEnd,
              finishDateStart,
              finishDateEnd,
              days,
              quantity,
              unitaryAmount,
              grossAmount,
              typeDiscount,
              discountPercent,
              discount,
              finalAmount,
              frequency,
              additive,
              billQuantityItem,
              billFinalAmount,
              getFilterFromSelect(clientGlosaItemId, 'one-to-many'),
              getFilterFromSelect(quoteId, 'many-to-one'),
              getFilterFromSelect(clComTableId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
      this.setState({ showModalForm: false });
      this.setState({ showModalEdit: null });
    } else {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.specialty.' + errors[0]) }));
    }
  };

  componentDidUpdate(prevProps) {
    const daysCount =
      ((this.state.finishDateSelectValue ? moment(this.state.finishDateSelectValue).toDate().getTime() : 0) -
        (this.state.startDateSelectValue ? moment(this.state.startDateSelectValue).toDate().getTime() : 0)) /
        (1000 * 3600 * 24) +
      1;

    if (daysCount > 0 && this.state.daysSelectValue !== daysCount) {
      this.setState({
        daysSelectValue: daysCount > 0 ? daysCount : 0,
        grossAmountSelectValue: this.state.clComTableSelectValue ? this.state.clComTableSelectValue.valuePackage : 0,
        finalAmountSelectValue: daysCount * (this.state.clComTableSelectValue ? this.state.clComTableSelectValue.valuePackage : 0),
      });
    }

    // if(this.state.clComTableSelectValue && this.state.clComTableSelectValue.value){

    // }

    return <> {super.componentDidUpdate(prevProps)} </>;
  }

  renderTable() {
    return <> {super.renderTable()} </>;
  }

  renderFooter() {
    return <> {super.renderFooter()} </>;
  }

  render() {
    return <> {super.render()} </>;
  }
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;

export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(QuotePackageExtended);
