import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { IPadAttendance } from 'app/shared/model/pad-attendance.model';
import { IProfessionalContact } from 'app/shared/model/professional-contact.model';
import { IProfessionalStatusHistory } from 'app/shared/model/professional-status-history.model';
import { IPatientNursingPrescription } from 'app/shared/model/patient-nursing-prescription.model';
import { IProfessionalFile } from 'app/shared/model/professional-file.model';
import { IProfessionalOccupationArea } from 'app/shared/model/professional-occupation-area.model';
import { IPush } from 'app/shared/model/push.model';
import { IProfessionalDevice } from 'app/shared/model/professional-device.model';
import { ISpecialty } from 'app/shared/model/specialty.model';
import { IProfessionalContractionType } from 'app/shared/model/professional-contraction-type.model';
import { ILevelGroup } from 'app/shared/model/level-group.model';
import { IProfessionalAdvice } from 'app/shared/model/professional-advice.model';
import { ICategory } from 'app/shared/model/category.model';
import { IPatient } from 'app/shared/model/patient.model';
import { ExperienceTime } from 'app/shared/model/enumerations/experience-time.model';
import { StatusProfessional } from 'app/shared/model/enumerations/status-professional.model';
import { Sex } from 'app/shared/model/enumerations/sex.model';
import { BrazilBanks } from 'app/shared/model/enumerations/brazil-banks.model';
import { TypeProfessionalBank } from 'app/shared/model/enumerations/type-professional-bank.model';
import { ProfessionalComplexity } from 'app/shared/model/enumerations/professional-complexity.model';

export interface IProfessional {
  id?: number;
  name?: string;
  rg?: string;
  cpf?: string;
  expiredDate?: Moment;
  experienceTime?: ExperienceTime;
  company?: string;
  cnpj?: string;
  observation?: any;
  source?: string;
  register?: string;
  status?: StatusProfessional;
  zipCode?: string;
  street?: string;
  complement?: string;
  number?: string;
  neighborhood?: string;
  city?: string;
  uf?: string;
  sex?: Sex;
  birthDate?: Moment;
  bank?: BrazilBanks;
  agency?: string;
  bankAccount?: string;
  type?: TypeProfessionalBank;
  professionalComplexity?: ProfessionalComplexity;
  user?: IUser;
  padAttendance?: IPadAttendance[];
  professionalContact?: IProfessionalContact[];
  professionalStatusHistory?: IProfessionalStatusHistory[];
  patientNursingPrescription?: IPatientNursingPrescription[];
  professionalFile?: IProfessionalFile[];
  professionalOccupationArea?: IProfessionalOccupationArea[];
  push?: IPush[];
  professionalDevices?: IProfessionalDevice[];
  specialties?: ISpecialty[];
  professionalContractionType?: IProfessionalContractionType;
  levelGroup?: ILevelGroup;
  professionalAdvice?: IProfessionalAdvice;
  category?: ICategory;
  patientBlockeds?: IPatient[];
  patientPreferreds?: IPatient[];
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  user: {
    isMulti: false, // one-to-one - owner-side
    entity: 'User',
    urlParameters: {
      enpoint: 'users',
      filters: 'professional.id.equals',
    },
  },
  padAttendance: {
    isMulti: false, // one-to-many - other-side
    entity: 'PadAttendance',
    urlParameters: {
      enpoint: 'pad-attendances',
      filters: 'professional.id.equals',
    },
  },
  professionalContact: {
    isMulti: false, // one-to-many - other-side
    entity: 'ProfessionalContact',
    urlParameters: {
      enpoint: 'professional-contacts',
      filters: 'professional.id.equals',
    },
  },
  professionalStatusHistory: {
    isMulti: false, // one-to-many - other-side
    entity: 'ProfessionalStatusHistory',
    urlParameters: {
      enpoint: 'professional-status-histories',
      filters: 'professional.id.equals',
    },
  },
  patientNursingPrescription: {
    isMulti: false, // one-to-many - other-side
    entity: 'PatientNursingPrescription',
    urlParameters: {
      enpoint: 'patient-nursing-prescriptions',
      filters: 'technician.id.equals',
    },
  },
  professionalFile: {
    isMulti: false, // one-to-many - other-side
    entity: 'ProfessionalFile',
    urlParameters: {
      enpoint: 'professional-files',
      filters: 'professional.id.equals',
    },
  },
  professionalOccupationArea: {
    isMulti: false, // one-to-many - other-side
    entity: 'ProfessionalOccupationArea',
    urlParameters: {
      enpoint: 'professional-occupation-areas',
      filters: 'professional.id.equals',
    },
  },
  push: {
    isMulti: false, // one-to-many - other-side
    entity: 'Push',
    urlParameters: {
      enpoint: 'pushes',
      filters: 'professional.id.equals',
    },
  },
  professionalDevice: {
    isMulti: true, // many-to-many - owner-side
    entity: 'ProfessionalDevice',
    urlParameters: {
      enpoint: 'professional-devices',
      filters: 'professionals.id.equals',
    },
  },
  specialty: {
    isMulti: true, // many-to-many - owner-side
    entity: 'Specialty',
    urlParameters: {
      enpoint: 'specialties',
      filters: 'professionals.id.equals',
    },
  },
  professionalContractionType: {
    isMulti: true, // many-to-one - other-side
    entity: 'ProfessionalContractionType',
    urlParameters: {
      enpoint: 'professional-contraction-types',
      filters: 'professional.id.equals',
    },
  },
  levelGroup: {
    isMulti: true, // many-to-one - other-side
    entity: 'LevelGroup',
    urlParameters: {
      enpoint: 'level-groups',
      filters: 'professional.id.equals',
    },
  },
  professionalAdvice: {
    isMulti: true, // many-to-one - other-side
    entity: 'ProfessionalAdvice',
    urlParameters: {
      enpoint: 'professional-advices',
      filters: 'professional.id.equals',
    },
  },
  category: {
    isMulti: true, // many-to-one - other-side
    entity: 'Category',
    urlParameters: {
      enpoint: 'categories',
      filters: 'professional.id.equals',
    },
  },
  patient: {
    isMulti: true, // many-to-many - other-side
    entity: 'Patient',
    urlParameters: {
      enpoint: 'patients',
      filters: 'professionalBlockeds.id.equals',
    },
  },
  patient: {
    isMulti: true, // many-to-many - other-side
    entity: 'Patient',
    urlParameters: {
      enpoint: 'patients',
      filters: 'professionalPreferreds.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'professional.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'professional.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<IProfessional> = {};
