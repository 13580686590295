/* eslint-disable no-console */
/* eslint complexity: ["error", 300] */
import React from 'react';
import { connect } from 'react-redux';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);

import { Translate } from 'app/config/translate-component';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { ProfessionalStatusHistory, mapStateToProps, mapDispatchToProps } from '../professional-status-history';
import { IRootState } from 'app/shared/reducers';
import { updateEntity as updateEntityProfessional } from '../../professional/professional.reducer';
import { StatusProfessional } from 'app/shared/model/enumerations/status-professional.model';
import { callFunction } from 'app/shared/reports/jhi-reports.reducer';
import { getFormFromSelect } from 'app/shared/util/entity-utils';

export interface IExtendedState {
  dumystate?: any;
}
export interface IExtendedProps {
  entityEmbebedPai?: any;
}

export class ProfessionalStatusHistoryExtended extends ProfessionalStatusHistory {
  constructor(props: any) {
    super(props);
    this.state = {
      ...this.state,
      sort: 'id',
      order: 'DESC',
    };
  }

  saveEntity = (event: any, errors: any, values: any, isNew: any) => {
    if (errors.length === 0) {
      const { professionalStatusHistoryEntity } = this.props;
      const entity = {
        ...professionalStatusHistoryEntity,
        ...values,
        professional: getFormFromSelect(this.state.professionalSelectValue, 'many-to-one'), // many-to-one - otherSide
        status: this.state.statusSelectValue === null ? 'ATIVO' : this.state.statusSelectValue,
        observation: this.state.observationSelectValue,
      };

      const { status, observation, professionalId } = this.state.fieldsBase;
      const entityIdBase = parseInt(
        professionalId && professionalId.length > 0 && professionalId[0].value ? professionalId[0].value : '',
        10
      );

      if (isNew) {
        if (this.state.statusSelectValue === StatusProfessional.INATIVADO) {
          MySwal.fire({
            title: <Translate contentKey={`generadorApp.cancel_quote`} />,
            html: <Translate contentKey={`generadorApp.want_to_cancel_quote`} />,
            showConfirmButton: true,
            showCancelButton: true,
          }).then(async response => {
            if (response.isConfirmed) {
              this.props.updateEntityProfessional({ id: entityIdBase, status: StatusProfessional.INATIVADO }, { reloadList: false });
              this.props.createEntity(entity, [status, observation, entityIdBase, 0, 10000, 'id,DESC']);
              const replayFuction = await callFunction('fnChangePadAttendanceStatusByProfessionalStatus', {
                kitId: entityIdBase,
              });
              this.props.history.push('/professional');
            }
          });
        } else {
          this.props.updateEntityProfessional(
            {
              id: entityIdBase,
              status: this.state.statusSelectValue === null ? 'ATIVO' : this.state.statusSelectValue,
            },
            { reloadList: false }
          );
          new Promise(resolve => {
            resolve(this.props.createEntity(entity, [status, observation, entityIdBase, 0, 10000, 'id,DESC']));
          }).then(resultEntity => {
            const idEntity =
              resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
                ? resultEntity['value']['data']['id']
                : -1;
          });
        }
      } else {
        this.props.updateEntity(entity, [status, observation, entityIdBase, 0, 10000, 'id,DESC']);
      }
    }
    this.setState({ showModalForm: false });
    this.setState({ showModalEdit: null });
  };

  renderHeader() {
    return <> {super.renderHeader()} </>;
  }

  renderFilter() {
    return <> {super.renderFilter()} </>;
  }

  renderTable() {
    return <> {super.renderTable()} </>;
  }

  renderFooter() {
    return <> {super.renderFooter()} </>;
  }

  render() {
    const { professionalStatusHistoryList, match, totalItems, professionals } = this.props;
    let professionalBaseFilter = null;
    if (professionals) {
      professionals.map(p => {
        if (p.id === parseInt(this.state.professionalId, 10)) professionalBaseFilter = p;
      });
    }

    if (professionalBaseFilter && professionalBaseFilter.status && this.state.statusSelectValue === null) {
      this.setState({ statusSelectValue: professionalBaseFilter.status });
    }
    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel>
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderTable()}

            {this.renderNewModal()}

            {this.renderUpdateModal()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
  updateEntityProfessional,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;

export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(ProfessionalStatusHistoryExtended);
