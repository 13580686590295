import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { IKitProductService } from 'app/shared/model/kit-product-service.model';
import { IQuoteProductService } from 'app/shared/model/quote-product-service.model';
import { IProductServiceStatusHistory } from 'app/shared/model/product-service-status-history.model';
import { ISupplierTableProductService } from 'app/shared/model/supplier-table-product-service.model';
import { IClComRuler } from 'app/shared/model/cl-com-ruler.model';
import { IDose } from 'app/shared/model/dose.model';
import { ICategory } from 'app/shared/model/category.model';
import { Status } from 'app/shared/model/enumerations/status.model';

export interface IProductService {
  id?: number;
  name?: string;
  tussCode?: string;
  tableCode?: string;
  expenseCode?: string;
  status?: Status;
  kitProductService?: IKitProductService[];
  quoteProductService?: IQuoteProductService[];
  productServiceStatusHistory?: IProductServiceStatusHistory[];
  supplierTableProductService?: ISupplierTableProductService[];
  clComRulers?: IClComRuler[];
  dose?: IDose;
  category?: ICategory;
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  kitProductService: {
    isMulti: false, // one-to-many - other-side
    entity: 'KitProductService',
    urlParameters: {
      enpoint: 'kit-product-services',
      filters: 'productService.id.equals',
    },
  },
  quoteProductService: {
    isMulti: false, // one-to-many - other-side
    entity: 'QuoteProductService',
    urlParameters: {
      enpoint: 'quote-product-services',
      filters: 'productService.id.equals',
    },
  },
  productServiceStatusHistory: {
    isMulti: false, // one-to-many - other-side
    entity: 'ProductServiceStatusHistory',
    urlParameters: {
      enpoint: 'product-service-status-histories',
      filters: 'productService.id.equals',
    },
  },
  supplierTableProductService: {
    isMulti: false, // one-to-many - other-side
    entity: 'SupplierTableProductService',
    urlParameters: {
      enpoint: 'supplier-table-product-services',
      filters: 'productService.id.equals',
    },
  },
  clComRuler: {
    isMulti: true, // many-to-many - owner-side
    entity: 'ClComRuler',
    urlParameters: {
      enpoint: 'cl-com-rulers',
      filters: 'productServices.id.equals',
    },
  },
  dose: {
    isMulti: true, // many-to-one - other-side
    entity: 'Dose',
    urlParameters: {
      enpoint: 'doses',
      filters: 'productService.id.equals',
    },
  },
  category: {
    isMulti: true, // many-to-one - other-side
    entity: 'Category',
    urlParameters: {
      enpoint: 'categories',
      filters: 'productService.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'productService.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'productService.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<IProductService> = {};
