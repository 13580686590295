export const selectFieldsList = [
  'name',
  'zipCode',
  'neighborhood',
  'city',
  'uf',
  'client.fantasyName',
  'patientComplexity',
  'status',
  'patientCid.id',
  'patientContact.id',
  'patientDevices.id',
  'patientResponsible.id',
];
