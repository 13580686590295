/* eslint complexity: ["error", 500] */
import axios from 'axios';

import moment from 'moment';
import { IPaginationBaseState, IPayloadResult } from 'react-jhipster';

import { IPayload } from 'react-jhipster/src/type/redux-action.type';

import { BASE_API_VERSION_PATH } from 'app/config/constants';

import { cleanEntity, jsonParse } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IPharmacyStock, defaultValue } from 'app/shared/model/pharmacy-stock.model';

export declare type ICrudPutAction<T> = (data?: T, listFiltersPage?) => IPayload<T> | IPayloadResult<T>;
export declare type ICrudGetAction<T> = (id: string | number, selectFields?: string) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const ACTION_TYPES = {
  FETCH_PHARMACYSTOCK_LIST_EXPORT: 'pharmacyStock/FETCH_PHARMACYSTOCK_LIST_EXPORT',
  FETCH_PHARMACYSTOCK_LIST: 'pharmacyStock/FETCH_PHARMACYSTOCK_LIST',
  FETCH_PHARMACYSTOCK: 'pharmacyStock/FETCH_PHARMACYSTOCK',
  FETCH_PHARMACYSTOCK_OFFSET: 'pharmacyStock/FETCH_PHARMACYSTOCK_OFFSET',
  CREATE_PHARMACYSTOCK: 'pharmacyStock/CREATE_PHARMACYSTOCK',
  UPDATE_PHARMACYSTOCK: 'pharmacyStock/UPDATE_PHARMACYSTOCK',
  CREATE_PHARMACYSTOCK_AND_GO_LIST: 'pharmacyStock/CREATE_PHARMACYSTOCK_AND_GO_LIST',
  UPDATE_PHARMACYSTOCK_AND_GO_LIST: 'pharmacyStock/UPDATE_PHARMACYSTOCK_AND_GO_LIST',
  DELETE_PHARMACYSTOCK: 'pharmacyStock/DELETE_PHARMACYSTOCK',
  RESET: 'pharmacyStock/RESET',
  SHOW_MODAL: 'pharmacyStock/SHOW_MODAL',
};

const initialState = {
  loading: false,
  openModal: {
    list: false,
    view: false,
    detail: false,
  },
  errorMessage: null,
  entities: [] as ReadonlyArray<IPharmacyStock>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const actionPendind = [];
let actualState = initialState;

export const convertEntityBuffers = entity => ({
  ...entity,
});

export type PharmacyStockState = Readonly<typeof initialState>;

export interface IPharmacyStockBaseState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  listCheckedID?: Array<number>;
  isCheckedAll?: boolean;
  urlBack?: string;
  extraFilters?: any;
  baseFilters: any;
  offset: number;
  lot?: any;
  validateStart?: any;
  validateEnd?: any;
  medicineMaterialId?: any;
  medicineMaterialStartFilter?: any;
  supplierId?: any;
  supplierStartFilter?: any;
  externalPurchase?: any;
  showModalReportOpen?: any;
}

export interface IFieldsBase extends IPharmacyStockBaseState, IPaginationBaseState {}
export interface IPharmacyStockUpdateState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  urlBack?: string;
  functionEmbebed?: any[];
  fieldsBase: IFieldsBase;
  lotSelectValue?: any;
  lotStartSelectOptions?: any;
  validateSelectValue?: any;
  validateStartSelectOptions?: any;
  notificationDateSelectValue?: any;
  notificationDateStartSelectOptions?: any;
  stockedLocationSelectValue?: any;
  stockedLocationStartSelectOptions?: any;
  princePaidSelectValue?: any;
  princePaidStartSelectOptions?: any;
  quantitySelectValue?: any;
  quantityStartSelectOptions?: any;
  amountExtractSelectValue?: any;
  amountExtractStartSelectOptions?: any;
  statusSelectValue?: any;
  statusStartSelectOptions?: any;
  externalPurchaseSelectValue?: any;
  externalPurchaseStartSelectOptions?: any;
  observationSelectValue?: any;
  observationStartSelectOptions?: any;
  actionTypeSelectValue?: any;
  actionTypeStartSelectOptions?: any;

  xmlData?: any;
  parsedData?: any;
  getAllDeposit?: any;
  checkImportNF?: boolean;

  pharmacyStockStatusSelectValue?: any;
  pharmacyStockStatusStartSelectOptions?: any;

  medicineMaterialSelectValue?: any;
  medicineMaterialStartSelectOptions?: any;

  supplierSelectValue?: any;
  supplierStartSelectOptions?: any;

  nfeSelectValue?: any;
  nfeStartSelectOptions?: any;

  isNew: boolean;
  medicineMaterialName?: any;

  medicineMaterialId?: any;
  supplierFantasyName?: any;

  supplierId?: any;
  nfeNumber?: any;

  nfeId?: any;
}

// Reducer

export default (state: PharmacyStockState = initialState, action): PharmacyStockState => {
  actualState = state;
  if (
    action.type.includes('_PENDING') &&
    !actionPendind.includes(action.type.replace('_PENDING', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_PENDING', ''))
  ) {
    actionPendind.push(action.type.replace('_PENDING', ''));
  }

  if (
    action.type.includes('_FULFILLED') &&
    actionPendind.includes(action.type.replace('_FULFILLED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_FULFILLED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_FULFILLED', '')), 1);
  }

  if (
    action.type.includes('_REJECTED') &&
    actionPendind.includes(action.type.replace('_REJECTED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_REJECTED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_REJECTED', '')), 1);
  }

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PHARMACYSTOCK_LIST_EXPORT):
    case REQUEST(ACTION_TYPES.FETCH_PHARMACYSTOCK_LIST):
    case REQUEST(ACTION_TYPES.FETCH_PHARMACYSTOCK):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_PHARMACYSTOCK):
    case REQUEST(ACTION_TYPES.UPDATE_PHARMACYSTOCK):
    case REQUEST(ACTION_TYPES.CREATE_PHARMACYSTOCK_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.UPDATE_PHARMACYSTOCK_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.DELETE_PHARMACYSTOCK):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_PHARMACYSTOCK_LIST_EXPORT):
    case FAILURE(ACTION_TYPES.FETCH_PHARMACYSTOCK_LIST):
    case FAILURE(ACTION_TYPES.FETCH_PHARMACYSTOCK):
    case FAILURE(ACTION_TYPES.CREATE_PHARMACYSTOCK):
    case FAILURE(ACTION_TYPES.UPDATE_PHARMACYSTOCK):
    case FAILURE(ACTION_TYPES.CREATE_PHARMACYSTOCK_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.UPDATE_PHARMACYSTOCK_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.DELETE_PHARMACYSTOCK):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_PHARMACYSTOCK_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_PHARMACYSTOCK_OFFSET):
      if (action.payload.data && action.payload.data.length > 0) {
        return {
          ...state,
          loading: false,
          entity: convertEntityBuffers(action.payload.data[0]),
        };
      }
      return {
        ...state,
        loading: false,
      };

    case SUCCESS(ACTION_TYPES.FETCH_PHARMACYSTOCK):
      return {
        ...state,
        loading: false,
        entity: convertEntityBuffers(action.payload.data),
      };
    case SUCCESS(ACTION_TYPES.CREATE_PHARMACYSTOCK):
    case SUCCESS(ACTION_TYPES.UPDATE_PHARMACYSTOCK):
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_PHARMACYSTOCK_AND_GO_LIST):
    case SUCCESS(ACTION_TYPES.UPDATE_PHARMACYSTOCK_AND_GO_LIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_PHARMACYSTOCK):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };

    case ACTION_TYPES.SHOW_MODAL: {
      const openModal = state.openModal;
      openModal[action.payload.type] = action.payload.show;
      return {
        ...state,
        openModal,
      };
    }
    default:
      return state;
  }
};

const apiUrl = BASE_API_VERSION_PATH + 'pharmacy-stocks';

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

// Actions

// Actions
export type ICrudGetAllActionPharmacyStock<T> = (
  lot?: any,
  validateStart?: any,
  validateEnd?: any,
  medicineMaterialId?: any,
  supplierId?: any,
  externalPurchase?: any,
  extraFilters?: {},
  page?: number,
  size?: number,
  sort?: string,
  selectFields?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getEntitiesFilter = (filters?, page?, size?, sort?, selectFields = '') => {
  const requestUrl = `${apiUrl}?page=${page >= 0 ? page : 0}&size=${size >= 0 ? size : 20}&sort=${sort ? sort : 'id,asc'}&`;
  const requestFilters = filters.map(v => v.column + '.' + v.operation + '=' + v.value).join('&');
  return {
    type: ACTION_TYPES.FETCH_PHARMACYSTOCK_LIST,
    payload: axios.get<IPharmacyStock>(`${requestUrl}${requestFilters}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getAllEntities = (filters?, sort?, selectFields = '') => {
  if (
    (actualState.totalItems > 0 && actualState.entities.length === actualState.totalItems) ||
    actionPendind.includes(ACTION_TYPES.FETCH_PHARMACYSTOCK_LIST)
  ) {
    return {
      type: ACTION_TYPES.FETCH_PHARMACYSTOCK_LIST + '_FROM_CACHE',
      payload: new Promise(function (resolve, reject) {
        resolve('done');
      }),
    };
  }

  const idsRequest = filters && typeof filters['ids'] !== 'undefined' && filters['ids'] ? `id.in=${filters['ids'].join(',')}&` : '';
  const lotRequest = filters && typeof filters['lot'] !== 'undefined' && filters['lot'] ? `lot.contains=${filters['lot']}&` : '';
  let validateStartRequest = '';
  let validateEndRequest = '';
  if (
    filters &&
    typeof filters['validateStart'] !== 'undefined' &&
    filters['validateStart'] &&
    !(typeof filters['validateEnd'] !== 'undefined' && filters['validateEnd'])
  ) {
    validateStartRequest = `validate.greaterThan=${moment(filters['validateStart']).format('YYYY-MM-DD')}&`;
  } else if (
    filters &&
    !(typeof filters['validateStart'] !== 'undefined' && filters['validateStart']) &&
    typeof filters['validateEnd'] !== 'undefined' &&
    filters['validateEnd']
  ) {
    validateEndRequest = `validate.lessThan=${moment(filters['validateEnd']).format('YYYY-MM-DD')}&`;
  } else if (
    filters &&
    typeof filters['validateStart'] !== 'undefined' &&
    filters['validateStart'] &&
    typeof filters['validateEnd'] !== 'undefined' &&
    filters['validateEnd']
  ) {
    validateStartRequest = `validate.between=${moment(filters['validateStart']).format('YYYY-MM-DD')},${moment(
      filters['validateEnd']
    ).format('YYYY-MM-DD')}&`;
  }
  const medicineMaterialRequest =
    filters && typeof filters['medicineMaterialId'] !== 'undefined' && filters['medicineMaterialId']
      ? `medicineMaterial.id.in=${filters['medicineMaterialId']}&`
      : '';
  const supplierRequest =
    filters && typeof filters['supplierId'] !== 'undefined' && filters['supplierId'] ? `supplier.id.in=${filters['supplierId']}&` : '';
  const externalPurchaseRequest =
    filters && typeof filters['externalPurchase'] !== 'undefined' && filters['externalPurchase']
      ? `externalPurchase.contains=${filters['externalPurchase']}&`
      : '';

  const requestUrl = `${apiUrl}${`?page=${0}&size=${1000000}&sort=${sort ? sort : 'id,asc'}&`}`;
  return {
    type: ACTION_TYPES.FETCH_PHARMACYSTOCK_LIST,
    payload: axios.get<IPharmacyStock>(
      `${requestUrl}${idsRequest}${lotRequest}${validateStartRequest}${validateEndRequest}${medicineMaterialRequest}${supplierRequest}${externalPurchaseRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntities: ICrudGetAllActionPharmacyStock<IPharmacyStock> = (
  lot,
  validateStart,
  validateEnd,
  medicineMaterialId,
  supplierId,
  externalPurchase,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const lotRequest = lot ? `lot.contains=${lot}&` : '';
  let validateStartRequest = '';
  let validateEndRequest = '';
  if (typeof validateStart !== 'undefined' && validateStart && !(typeof validateEnd !== 'undefined' && validateEnd)) {
    validateStartRequest = `validate.greaterThan=${moment(validateStart).format('YYYY-MM-DD')}&`;
  } else if (!(typeof validateStart !== 'undefined' && validateStart) && typeof validateEnd !== 'undefined' && validateEnd) {
    validateEndRequest = `validate.lessThan=${moment(validateEnd).format('YYYY-MM-DD')}&`;
  } else if (typeof validateStart !== 'undefined' && validateStart && typeof validateEnd !== 'undefined' && validateEnd) {
    validateStartRequest = `validate.between=${moment(validateStart).format('YYYY-MM-DD')},${moment(validateEnd).format('YYYY-MM-DD')}&`;
  }
  const medicineMaterialRequest = medicineMaterialId ? `medicineMaterial.id.in=${medicineMaterialId}&` : '';
  const supplierRequest = supplierId ? `supplier.id.in=${supplierId}&` : '';
  const externalPurchaseRequest = externalPurchase !== '' ? `externalPurchase.equals=${externalPurchase}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PHARMACYSTOCK_LIST,
    payload: axios.get<IPharmacyStock>(
      `${requestUrl}${extraFiltersRequest}${lotRequest}${validateStartRequest}${validateEndRequest}${medicineMaterialRequest}${supplierRequest}${externalPurchaseRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntityOffset: any = (
  lot,
  validateStart,
  validateEnd,
  medicineMaterialId,
  supplierId,
  externalPurchase,
  extraFilters,
  offset,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const lotRequest = lot ? `lot.contains=${lot}&` : '';
  let validateStartRequest = '';
  let validateEndRequest = '';
  if (typeof validateStart !== 'undefined' && validateStart && !(typeof validateEnd !== 'undefined' && validateEnd)) {
    validateStartRequest = `validate.greaterThan=${moment(validateStart).format('YYYY-MM-DD')}&`;
  } else if (!(typeof validateStart !== 'undefined' && validateStart) && typeof validateEnd !== 'undefined' && validateEnd) {
    validateEndRequest = `validate.lessThan=${moment(validateEnd).format('YYYY-MM-DD')}&`;
  } else if (typeof validateStart !== 'undefined' && validateStart && typeof validateEnd !== 'undefined' && validateEnd) {
    validateStartRequest = `validate.between=${moment(validateStart).format('YYYY-MM-DD')},${moment(validateEnd).format('YYYY-MM-DD')}&`;
  }
  const medicineMaterialRequest = medicineMaterialId ? `medicineMaterial.id.in=${medicineMaterialId}&` : '';
  const supplierRequest = supplierId ? `supplier.id.in=${supplierId}&` : '';
  const externalPurchaseRequest = externalPurchase !== '' ? `externalPurchase.equals=${externalPurchase}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${offset}&size=${1}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PHARMACYSTOCK_OFFSET,
    payload: axios.get<IPharmacyStock>(
      `${requestUrl}${extraFiltersRequest}${lotRequest}${validateStartRequest}${validateEndRequest}${medicineMaterialRequest}${supplierRequest}${externalPurchaseRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntity: ICrudGetAction<IPharmacyStock> = (id, selectFields = '') => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_PHARMACYSTOCK,
    payload: axios.get<IPharmacyStock>(requestUrl, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntitiesExport: ICrudGetAllActionPharmacyStock<IPharmacyStock> = (
  lot,
  validateStart,
  validateEnd,
  medicineMaterialId,
  supplierId,
  externalPurchase,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const lotRequest = lot ? (Array.isArray(status) ? `lot.in=${lot.join(',')}&` : `lot.contains=${lot}&`) : '';
  let validateStartRequest = '';
  let validateEndRequest = '';
  if (typeof validateStart !== 'undefined' && validateStart && !(typeof validateEnd !== 'undefined' && validateEnd)) {
    validateStartRequest = `validate.greaterThan=${moment(validateStart).format('YYYY-MM-DD')}&`;
  } else if (!(typeof validateStart !== 'undefined' && validateStart) && typeof validateEnd !== 'undefined' && validateEnd) {
    validateEndRequest = `validate.lessThan=${moment(validateEnd).format('YYYY-MM-DD')}&`;
  } else if (typeof validateStart !== 'undefined' && validateStart && typeof validateEnd !== 'undefined' && validateEnd) {
    validateStartRequest = `validate.between=${moment(validateStart).format('YYYY-MM-DD')},${moment(validateEnd).format('YYYY-MM-DD')}&`;
  }
  const medicineMaterialRequest = medicineMaterialId ? `medicineMaterial.id.in=${medicineMaterialId}&` : '';
  const supplierRequest = supplierId ? `supplier.id.in=${supplierId}&` : '';
  const externalPurchaseRequest = externalPurchase !== '' ? `externalPurchase.equals=${externalPurchase}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PHARMACYSTOCK_LIST,
    payload: axios.get<IPharmacyStock>(
      `${requestUrl}${extraFiltersRequest}${lotRequest}${validateStartRequest}${validateEndRequest}${medicineMaterialRequest}${supplierRequest}${externalPurchaseRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const createEntity: ICrudPutAction<IPharmacyStock> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity, validate: entity.validate ? moment(entity.validate).format('YYYY-MM-DD') : null };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.CREATE_PHARMACYSTOCK_AND_GO_LIST
        : ACTION_TYPES.CREATE_PHARMACYSTOCK,
    payload: axios.post(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const updateEntity: ICrudPutAction<IPharmacyStock> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity, validate: entity.validate ? moment(entity.validate).format('YYYY-MM-DD') : null };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.UPDATE_PHARMACYSTOCK_AND_GO_LIST
        : ACTION_TYPES.UPDATE_PHARMACYSTOCK,
    payload: axios.put(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const deleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_PHARMACYSTOCK,
    payload: axios.delete(requestUrl),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const showModal = view => ({
  payload: { type: view, show: true },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const hideModal = view => ({
  payload: { type: view, show: false },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const getUrlBack = (location): string => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  let urlBack = _urlBase.searchParams.get('urlBack') || '';
  urlBack = urlBack.trim();
  urlBack = urlBack.startsWith('/') ? urlBack.slice(1) : urlBack;
  urlBack = urlBack.endsWith('/') ? urlBack.slice(0, -1) : urlBack;
  return urlBack ? '/' + urlBack + '/' : '';
};

export const getPharmacyStockState = (location): IPharmacyStockBaseState => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  const extraFilters = jsonParse(_urlBase.searchParams.get('extraFilters') || '{}');
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;

  const lot = _urlBase.searchParams.get('lot') || '';
  const validateStart = _urlBase.searchParams.get('validateStart') || '';
  const validateEnd = _urlBase.searchParams.get('validateEnd') || '';
  const medicineMaterialId = _urlBase.searchParams.get('medicineMaterial') || '';
  const supplierId = _urlBase.searchParams.get('supplier') || '';
  const externalPurchase = _urlBase.searchParams.get('externalPurchase') || '';
  return {
    baseFilters,
    extraFilters,
    offset,
    lot,
    validateStart,
    validateEnd,
    medicineMaterialId: medicineMaterialId
      ? medicineMaterialId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] }))
      : [],
    supplierId: supplierId ? supplierId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
    externalPurchase,
  };
};

export const getEntityFiltersURL = (state, offset = null) => {
  return (
    'baseFilters=' +
    state.baseFilters +
    '&extraFilters=' +
    encodeURI(JSON.stringify(state.extraFilters)) +
    '&page=' +
    state.activePage +
    '&' +
    'size=' +
    state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    state.sort +
    ',' +
    state.order +
    '&' +
    (state.lot ? 'lot=' + state.lot + '&' : '') +
    (state.validate ? 'validate=' + state.validate + '&' : '') +
    (state.medicineMaterialId ? 'medicineMaterial=' + state.medicineMaterialId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.supplierId ? 'supplier=' + state.supplierId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.externalPurchase ? 'externalPurchase=' + state.externalPurchase + '&' : '') +
    ''
  );
};
