import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';
import moment from 'moment';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity, IProfessionalBaseState, getUrlBack, getProfessionalState, getEntityFiltersURL } from './professional.reducer';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

export interface IProfessionalState {
  fieldsBase: IProfessionalBaseState;
  activeTab: number;
}

export interface IProfessionalDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class ProfessionalDetail extends React.Component<IProfessionalDetailProps, IProfessionalState> {
  constructor(props: Readonly<IProfessionalDetailProps>) {
    super(props);
    this.state = {
      ...this.state,
      activeTab: 0,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getProfessionalState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
    };
  }

  toggleTab(tab: number) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = '_back=1' + (offset !== null ? '&offset=' + offset : '');
    Object.keys(fieldsBase).map(key => {
      url += '&' + key + '=' + fieldsBase[key];
    });
    return url;
  };

  UNSAFE_componentWillMount() {
    this.props.getEntity(this.props.match.params['id']);
  }

  renderHeader() {
    const { professionalEntity } = this.props;
    const state = this.state;
    return (
      <>
        <div id="page-heading">
          {' '}
          <h2>
            <Translate contentKey="generadorApp.professional.detail.title">Professional</Translate>
            <small>&nbsp; {professionalEntity['company']}</small>
          </h2>
        </div>

        <div className="pull-right">
          <Button tag={Link} to={'/professional?' + getEntityFiltersURL(this.state.fieldsBase)} replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.professional.btnBack">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/professional/${professionalEntity.id}/edit?+${getEntityFiltersURL(this.state.fieldsBase)}`}
            replace
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.professional.btnEdit">Edit</Translate>
            </span>
          </Button>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>

          <li className="breadcrumb-item active">
            <Link to={'/professional'}>Professionals</Link>
          </li>
          <li className="breadcrumb-item active">Professionals details</li>
        </ol>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === 0 })}
              onClick={() => {
                this.toggleTab(0);
              }}
            >
              <span className="d-sm-none">
                <Translate contentKey="generadorApp.professional.viewTabs.PersonalInformation">PersonalInformation</Translate>
              </span>
              <span className="d-sm-block d-none">
                <Translate contentKey="generadorApp.professional.viewTabs.PersonalInformation">PersonalInformation</Translate>
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === 1 })}
              onClick={() => {
                this.toggleTab(1);
              }}
            >
              <span className="d-sm-none">
                <Translate contentKey="generadorApp.professional.viewTabs.InformationResidential">InformationResidential</Translate>
              </span>
              <span className="d-sm-block d-none">
                <Translate contentKey="generadorApp.professional.viewTabs.InformationResidential">InformationResidential</Translate>
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === 2 })}
              onClick={() => {
                this.toggleTab(2);
              }}
            >
              <span className="d-sm-none">
                <Translate contentKey="generadorApp.professional.viewTabs.ProfessionalInformation">ProfessionalInformation</Translate>
              </span>
              <span className="d-sm-block d-none">
                <Translate contentKey="generadorApp.professional.viewTabs.ProfessionalInformation">ProfessionalInformation</Translate>
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === 3 })}
              onClick={() => {
                this.toggleTab(3);
              }}
            >
              <span className="d-sm-none">
                <Translate contentKey="generadorApp.professional.viewTabs.AccountInformation">AccountInformation</Translate>
              </span>
              <span className="d-sm-block d-none">
                <Translate contentKey="generadorApp.professional.viewTabs.AccountInformation">AccountInformation</Translate>
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === 4 })}
              onClick={() => {
                this.toggleTab(4);
              }}
            >
              <span className="d-sm-none">
                <Translate contentKey="generadorApp.professional.viewTabs.OtherInformation">OtherInformation</Translate>
              </span>
              <span className="d-sm-block d-none">
                <Translate contentKey="generadorApp.professional.viewTabs.OtherInformation">OtherInformation</Translate>
              </span>
            </NavLink>
          </NavItem>
        </Nav>
      </>
    );
  }

  renderBody() {
    const { professionalEntity } = this.props;
    const state = this.state;
    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            <Row className="jh-entity-details">
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId={0}>
                  <Row>
                    <Col className="view-detail-row professional-name-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top professional-name-detail-label" md="12">
                          <dt>
                            <span id="name">
                              <Translate contentKey="generadorApp.professional.name">Name</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top professional-name-detail-value" md="12">
                          <dd>{professionalEntity.name}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row professional-rg-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top professional-rg-detail-label" md="12">
                          <dt>
                            <span id="rg">
                              <Translate contentKey="generadorApp.professional.rg">RG</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top professional-rg-detail-value" md="12">
                          <dd>{professionalEntity.rg}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row professional-cpf-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top professional-cpf-detail-label" md="12">
                          <dt>
                            <span id="cpf">
                              <Translate contentKey="generadorApp.professional.cpf">CPF</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top professional-cpf-detail-value" md="12">
                          <dd>{professionalEntity.cpf}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row professional-sex-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top professional-sex-detail-label" md="12">
                          <dt>
                            <span id="sex">
                              <Translate contentKey="generadorApp.professional.sex">Sex</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top professional-sex-detail-value" md="12">
                          <dd>{translate('generadorApp.Sex.' + (professionalEntity.sex ? professionalEntity.sex : 'MASCULINO'))}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row professional-birthDate-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top professional-birthDate-detail-label" md="12">
                          <dt>
                            <span id="birthDate">
                              <Translate contentKey="generadorApp.professional.birthDate">Birth Date</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top professional-birthDate-detail-value" md="12">
                          <dd>{professionalEntity.birthDate ? moment(professionalEntity.birthDate).format(APP_LOCAL_DATE_FORMAT) : ''}</dd>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={1}>
                  <Row>
                    <Col className="view-detail-row professional-zipCode-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top professional-zipCode-detail-label" md="12">
                          <dt>
                            <span id="zipCode">
                              <Translate contentKey="generadorApp.professional.zipCode">Zip Code</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top professional-zipCode-detail-value" md="12">
                          <dd>{professionalEntity.zipCode}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row professional-street-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top professional-street-detail-label" md="12">
                          <dt>
                            <span id="street">
                              <Translate contentKey="generadorApp.professional.street">Street</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top professional-street-detail-value" md="12">
                          <dd>{professionalEntity.street}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row professional-complement-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top professional-complement-detail-label" md="12">
                          <dt>
                            <span id="complement">
                              <Translate contentKey="generadorApp.professional.complement">Complement</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top professional-complement-detail-value" md="12">
                          <dd>{professionalEntity.complement}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row professional-number-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top professional-number-detail-label" md="12">
                          <dt>
                            <span id="number">
                              <Translate contentKey="generadorApp.professional.number">Number</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top professional-number-detail-value" md="12">
                          <dd>{professionalEntity.number}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row professional-neighborhood-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top professional-neighborhood-detail-label" md="12">
                          <dt>
                            <span id="neighborhood">
                              <Translate contentKey="generadorApp.professional.neighborhood">Neighborhood</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top professional-neighborhood-detail-value" md="12">
                          <dd>{professionalEntity.neighborhood}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row professional-city-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top professional-city-detail-label" md="12">
                          <dt>
                            <span id="city">
                              <Translate contentKey="generadorApp.professional.city">City</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top professional-city-detail-value" md="12">
                          <dd>{professionalEntity.city}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row professional-uf-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top professional-uf-detail-label" md="12">
                          <dt>
                            <span id="uf">
                              <Translate contentKey="generadorApp.professional.uf">UF</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top professional-uf-detail-value" md="12">
                          <dd>{professionalEntity.uf}</dd>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={2}>
                  <Row>
                    <Col className="view-detail-row professional-company-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top professional-company-detail-label" md="12">
                          <dt>
                            <span id="company">
                              <Translate contentKey="generadorApp.professional.company">Company</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top professional-company-detail-value" md="12">
                          <dd>{professionalEntity.company}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row professional-cnpj-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top professional-cnpj-detail-label" md="12">
                          <dt>
                            <span id="cnpj">
                              <Translate contentKey="generadorApp.professional.cnpj">CNPJ</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top professional-cnpj-detail-value" md="12">
                          <dd>{professionalEntity.cnpj}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row professional-expiredDate-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top professional-expiredDate-detail-label" md="12">
                          <dt>
                            <span id="expiredDate">
                              <Translate contentKey="generadorApp.professional.expiredDate">Expired Date</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top professional-expiredDate-detail-value" md="12">
                          <dd>
                            {professionalEntity.expiredDate ? moment(professionalEntity.expiredDate).format(APP_LOCAL_DATE_FORMAT) : ''}
                          </dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row professional-experienceTime-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top professional-experienceTime-detail-label" md="12">
                          <dt>
                            <span id="experienceTime">
                              <Translate contentKey="generadorApp.professional.experienceTime">Experience Time</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top professional-experienceTime-detail-value" md="12">
                          <dd>
                            {translate(
                              'generadorApp.ExperienceTime.' +
                                (professionalEntity.experienceTime ? professionalEntity.experienceTime : 'ZEROADOISANOS')
                            )}
                          </dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row professional-register-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top professional-register-detail-label" md="12">
                          <dt>
                            <span id="register">
                              <Translate contentKey="generadorApp.professional.register">Professional register</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top professional-register-detail-value" md="12">
                          <dd>{professionalEntity.register}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row professional-professionalDevice-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top professional-professionalDevice-detail-label" md="12">
                          <dt>
                            <Translate contentKey="generadorApp.professional.professionalDevice">Professional Device</Translate>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top professional-professionalDevice-detail-value" md="12">
                          <dd>
                            {professionalEntity.professionalDevices
                              ? professionalEntity.professionalDevices.map((val, i) => (
                                  <span key={val.id}>
                                    <a>{val.name}</a>
                                    {i === professionalEntity.professionalDevices.length - 1 ? '' : ', '}
                                  </span>
                                ))
                              : null}
                          </dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row professional-professionalComplexity-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top professional-professionalComplexity-detail-label" md="12">
                          <dt>
                            <span id="professionalComplexity">
                              <Translate contentKey="generadorApp.professional.professionalComplexity">Patient complexity</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top professional-professionalComplexity-detail-value" md="12">
                          <dd>
                            {translate(
                              'generadorApp.ProfessionalComplexity.' +
                                (professionalEntity.professionalComplexity ? professionalEntity.professionalComplexity : 'BAIXA')
                            )}
                          </dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row professional-professionalAdvice-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top professional-professionalAdvice-detail-label" md="12">
                          <dt>
                            <Translate contentKey="generadorApp.professional.professionalAdvice">Professional Advice</Translate>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top professional-professionalAdvice-detail-value" md="12">
                          <dd>{professionalEntity.professionalAdvice ? professionalEntity.professionalAdvice.id : ''}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row professional-professionalContractionType-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top professional-professionalContractionType-detail-label" md="12">
                          <dt>
                            <Translate contentKey="generadorApp.professional.professionalContractionType">
                              Professional Contraction Type
                            </Translate>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top professional-professionalContractionType-detail-value" md="12">
                          <dd>{professionalEntity.professionalContractionType ? professionalEntity.professionalContractionType.id : ''}</dd>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={3}>
                  <Row>
                    <Col className="view-detail-row professional-bank-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top professional-bank-detail-label" md="12">
                          <dt>
                            <span id="bank">
                              <Translate contentKey="generadorApp.professional.bank">Bank</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top professional-bank-detail-value" md="12">
                          <dd>
                            {translate(
                              'generadorApp.BrazilBanks.' + (professionalEntity.bank ? professionalEntity.bank : 'BANCO_DO_BRASIL_SA_001')
                            )}
                          </dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row professional-agency-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top professional-agency-detail-label" md="12">
                          <dt>
                            <span id="agency">
                              <Translate contentKey="generadorApp.professional.agency">Agency</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top professional-agency-detail-value" md="12">
                          <dd>{professionalEntity.agency}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row professional-bankAccount-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top professional-bankAccount-detail-label" md="12">
                          <dt>
                            <span id="bankAccount">
                              <Translate contentKey="generadorApp.professional.bankAccount">Bank Account</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top professional-bankAccount-detail-value" md="12">
                          <dd>{professionalEntity.bankAccount}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row professional-type-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top professional-type-detail-label" md="12">
                          <dt>
                            <span id="type">
                              <Translate contentKey="generadorApp.professional.type">Type</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top professional-type-detail-value" md="12">
                          <dd>
                            {translate(
                              'generadorApp.TypeProfessionalBank.' + (professionalEntity.type ? professionalEntity.type : 'CORRENTE')
                            )}
                          </dd>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={4}>
                  <Row>
                    <Col className="view-detail-row professional-observation-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top professional-observation-detail-label" md="12">
                          <dt>
                            <span id="observation">
                              <Translate contentKey="generadorApp.professional.observation">Observation</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top professional-observation-detail-value" md="12">
                          <dd>{professionalEntity.observation}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row professional-source-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top professional-source-detail-label" md="12">
                          <dt>
                            <span id="source">
                              <Translate contentKey="generadorApp.professional.source">Registration Origin</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top professional-source-detail-value" md="12">
                          <dd>{professionalEntity.source}</dd>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { professionalEntity } = this.props;
    const state = this.state;
    return (
      <div>
        <Panel className="professional professional-detail">
          <PanelHeader>{this.renderHeader()}</PanelHeader>

          <PanelBody>{this.renderBody()}</PanelBody>
        </Panel>
      </div>
    );
  }
}

export const mapStateToProps = ({ professional, ...storeState }: IRootState) => ({
  professionalEntity: professional.entity,
});

export const mapDispatchToProps = {
  getEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProfessionalDetail);
