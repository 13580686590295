/* eslint complexity: ["error", 500] */
import React from 'react';
import axios from 'axios';

import CurrencyInput from 'react-currency-input';
import { connect } from 'react-redux';

import {
  getFilterFromSelect,
  getFormFromSelect,
  getListAxios,
  showFieldsSelectAsync,
  checkAnyValueFields,
} from 'app/shared/util/entity-utils';
import Select from 'react-select';
import { toast } from 'react-toastify';
import SelectAsync from 'react-select/async';
import { Link, RouteComponentProps } from 'react-router-dom';

import { Button, Col, Row, Table, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { JhiItemCount } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { IRootState } from 'app/shared/reducers';
import {
  updateEntity,
  getUrlBack,
  getClientGlosaItemState,
  IClientGlosaItemBaseState,
  getEntityFiltersURL,
  getEntities,
  IClientGlosaItemUpdateState as IBaseUpdateState,
  reset,
  getEntity,
  deleteEntity,
  createEntity,
} from './client-glosa-item.reducer';

import { ITEMS_PER_PAGE, IPaginationBaseState, getSortState } from 'app/shared/util/pagination.constants';

import { selectFieldsList } from './extended/extendSelectFields';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

export interface IClientGlosaItemProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
  entityEmbebedPai?: any;
}

export interface IClientGlosaItemState extends IClientGlosaItemBaseState, IPaginationBaseState, IBaseUpdateState {
  showModalForm?: boolean;
  showModalEdit?: number;
  showModalEditView?: boolean;
}

export class ClientGlosaItem extends React.Component<IClientGlosaItemProps, IClientGlosaItemState> {
  protected myFormRef: any;
  protected myFormRefNew: any;
  protected myFormRefEdit: any;

  secOrderFileInput: any;

  typeItemGlosaFileInput: any;

  quantityGlossedFileInput: any;

  quantityRefusedFileInput: any;

  justificationFileInput: any;

  glosaItemAceitaFileInput: any;

  constructor(props: IClientGlosaItemProps) {
    super(props);
    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      listCheckedID: [],
      isCheckedAll: false,
      showModalForm: false,
      showModalEdit: null,
      urlBack: getUrlBack(this.props.location),
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      ...getClientGlosaItemState(this.props.location),
      clientGlosaId: [
        {
          ...(this.props.entityEmbebedPai ? this.props.entityEmbebedPai : {}),
          value: this.props.match.params['idClientGlosa'],
          label: this.props.match.params['idClientGlosa'],
        },
      ],
      baseFilters: 'clientGlosa',
      clientGlosaSelectValue: {
        ...(this.props.entityEmbebedPai ? this.props.entityEmbebedPai : {}),
        value: this.props.match.params['idClientGlosa'],
        label: this.props.match.params['idClientGlosa'],
      },

      quoteMedicineSelectValue: null,

      quoteDietSelectValue: null,

      quoteMaterialSelectValue: null,

      quoteProductServiceSelectValue: null,

      quoteRhSelectValue: null,

      quotePackageSelectValue: null,

      secOrderSelectValue: null,
      typeItemGlosaSelectValue: null,
      quantityGlossedSelectValue: null,
      quantityRefusedSelectValue: null,
      justificationSelectValue: null,
      glosaItemAceitaSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getClientGlosaItemState(this.props.location),
        clientGlosaId: [
          {
            ...(this.props.entityEmbebedPai ? this.props.entityEmbebedPai : {}),
            value: this.props.match.params['idClientGlosa'],
            label: this.props.match.params['idClientGlosa'],
          },
        ],
        baseFilters: 'clientGlosa',
      },
      quoteMedicineId: null,
      quoteDietId: null,
      quoteMaterialId: null,
      quoteProductServiceId: null,
      quoteRhId: null,
      quotePackageId: null,
      isNew: false,
    };

    axios
      .get('/api/client-glosas/' + this.props.match.params['idClientGlosa'], {
        headers: {
          'Select-Fields': 'id',
        },
      })
      .then(result => {
        this.setState({
          clientGlosaSelectValue: {
            ...result['data'],
            value: this.props.match.params['idClientGlosa'],
            label: showFieldsSelectAsync(result['data'], 'id'),
          },
        });
      });
  }

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({ listCheckedID: this.props.clientGlosaItemList.map(clientGlosaItem => clientGlosaItem.id) });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({ listCheckedID: this.props.clientGlosaItemList.map(clientGlosaItem => clientGlosaItem.id) });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  removeEntitySelected() {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  }

  saveEntityMany = () => {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  };

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  cancelFilters = () => {
    this.setState(
      {
        secOrder: '',

        typeItemGlosa: '',

        quantityGlossed: '',

        quantityRefused: '',

        justification: '',

        glosaItemAceita: '',
        clientGlosaId: '',
        quoteMedicineId: '',
        quoteDietId: '',
        quoteMaterialId: '',
        quoteProductServiceId: '',
        quoteRhId: '',
        quotePackageId: '',
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
        activePage: 0,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();
    const {
      secOrder,
      typeItemGlosa,
      quantityGlossed,
      quantityRefused,
      justification,
      glosaItemAceita,
      clientGlosaId,
      quoteMedicineId,
      quoteDietId,
      quoteMaterialId,
      quoteProductServiceId,
      quoteRhId,
      quotePackageId,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;
    return this.props.getEntities(
      secOrder,
      typeItemGlosa,
      quantityGlossed,
      quantityRefused,
      justification,
      glosaItemAceita,
      getFilterFromSelect(clientGlosaId, 'many-to-one'),
      getFilterFromSelect(quoteMedicineId, 'many-to-one'),
      getFilterFromSelect(quoteDietId, 'many-to-one'),
      getFilterFromSelect(quoteMaterialId, 'many-to-one'),
      getFilterFromSelect(quoteProductServiceId, 'many-to-one'),
      getFilterFromSelect(quoteRhId, 'many-to-one'),
      getFilterFromSelect(quotePackageId, 'many-to-one'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(',')
    );
  };

  updateEntity = entity => {
    const {
      secOrder,
      typeItemGlosa,
      quantityGlossed,
      quantityRefused,
      justification,
      glosaItemAceita,
      clientGlosaId,
      quoteMedicineId,
      quoteDietId,
      quoteMaterialId,
      quoteProductServiceId,
      quoteRhId,
      quotePackageId,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;
    this.props.updateEntity(entity, [
      secOrder,
      typeItemGlosa,
      quantityGlossed,
      quantityRefused,
      justification,
      glosaItemAceita,
      getFilterFromSelect(clientGlosaId, 'many-to-one'),
      getFilterFromSelect(quoteMedicineId, 'many-to-one'),
      getFilterFromSelect(quoteDietId, 'many-to-one'),
      getFilterFromSelect(quoteMaterialId, 'many-to-one'),
      getFilterFromSelect(quoteProductServiceId, 'many-to-one'),
      getFilterFromSelect(quoteRhId, 'many-to-one'),
      getFilterFromSelect(quotePackageId, 'many-to-one'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(','),
    ]);
  };

  saveEntity = (event: any, errors: any, values: any, isNew: any) => {
    if (errors.length === 0) {
      const { clientGlosaItemEntity } = this.props;
      const _entity = {
        ...clientGlosaItemEntity,
        // ...values,

        quotePackage: getFormFromSelect(this.state.quotePackageSelectValue, 'many-to-one'), // many-to-one - otherSide

        quoteRh: getFormFromSelect(this.state.quoteRhSelectValue, 'many-to-one'), // many-to-one - otherSide

        quoteMedicine: getFormFromSelect(this.state.quoteMedicineSelectValue, 'many-to-one'), // many-to-one - otherSide

        quoteDiet: getFormFromSelect(this.state.quoteDietSelectValue, 'many-to-one'), // many-to-one - otherSide

        quoteMaterial: getFormFromSelect(this.state.quoteMaterialSelectValue, 'many-to-one'), // many-to-one - otherSide

        quoteProductService: getFormFromSelect(this.state.quoteProductServiceSelectValue, 'many-to-one'), // many-to-one - otherSide

        secOrder: this.state.secOrderSelectValue,
        typeItemGlosa: this.state.typeItemGlosaSelectValue === null ? null : this.state.typeItemGlosaSelectValue,
        quantityGlossed: this.state.quantityGlossedSelectValue,
        quantityRefused: this.state.quantityRefusedSelectValue,
        justification: this.state.justificationSelectValue,
        glosaItemAceita: this.state.glosaItemAceitaSelectValue === null ? null : this.state.glosaItemAceitaSelectValue,
      };
      const entity = _entity;

      const {
        secOrder,
        typeItemGlosa,
        quantityGlossed,
        quantityRefused,
        justification,
        glosaItemAceita,
        clientGlosaId,
        quoteMedicineId,
        quoteDietId,
        quoteMaterialId,
        quoteProductServiceId,
        quoteRhId,
        quotePackageId,
        extraFilters,
        activePage,
        itemsPerPage,
        sort,
        order,
      } = this.state.fieldsBase;

      if (isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              secOrder,
              typeItemGlosa,
              quantityGlossed,
              quantityRefused,
              justification,
              glosaItemAceita,
              getFilterFromSelect(clientGlosaId, 'many-to-one'),
              getFilterFromSelect(quoteMedicineId, 'many-to-one'),
              getFilterFromSelect(quoteDietId, 'many-to-one'),
              getFilterFromSelect(quoteMaterialId, 'many-to-one'),
              getFilterFromSelect(quoteProductServiceId, 'many-to-one'),
              getFilterFromSelect(quoteRhId, 'many-to-one'),
              getFilterFromSelect(quotePackageId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              secOrder,
              typeItemGlosa,
              quantityGlossed,
              quantityRefused,
              justification,
              glosaItemAceita,
              getFilterFromSelect(clientGlosaId, 'many-to-one'),
              getFilterFromSelect(quoteMedicineId, 'many-to-one'),
              getFilterFromSelect(quoteDietId, 'many-to-one'),
              getFilterFromSelect(quoteMaterialId, 'many-to-one'),
              getFilterFromSelect(quoteProductServiceId, 'many-to-one'),
              getFilterFromSelect(quoteRhId, 'many-to-one'),
              getFilterFromSelect(quotePackageId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
      this.setState({ showModalForm: false });
      this.setState({ showModalEdit: null });
    } else {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.specialty.' + errors[0]) }));
    }
  };

  componentDidUpdate(prevProps) {}

  deleteEntity(clientGlosaItemEntity) {
    MySwal.fire({
      title: <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>,
      html: (
        <Translate contentKey="generadorApp.clientGlosaItem.delete.question" interpolate={{ id: clientGlosaItemEntity.id }}>
          Are you sure you want to delete this ClientGlosaItem?
        </Translate>
      ),
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#e70015',
      cancelButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="ban" />
          &nbsp; <Translate contentKey="generadorApp.clientGlosaItem.btnCancel">Cancel</Translate>
        </>
      ),
      confirmButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="trash" />
          &nbsp;<Translate contentKey="generadorApp.clientGlosaItem.btnDelete">Delete</Translate>
        </>
      ),
    }).then(async response => {
      if (response.isConfirmed) {
        await this.props.deleteEntity(clientGlosaItemEntity.id, { reloadList: false });
        this.getEntities();
        MySwal.fire({
          title: <Translate contentKey="entity.delete.title.confirmed">Confirmed delete operation</Translate>,
          icon: 'success',
        });
      }
    });
  }

  renderNewModal() {
    return (
      <Modal size={'xl'} isOpen={this.state.showModalForm} toggle={() => this.setState({ showModalForm: false })}>
        <ModalHeader toggle={() => this.setState({ showModalForm: false })}>{this.renderHeaderUpdate({}, true)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefNew = el)}
          model={{}}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, true)}
        >
          <ModalBody>{this.renderBodyUpdate({}, true)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalForm: false })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.clientGlosaItem.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefNew && this.myFormRefNew.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.clientGlosaItem.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    );
  }

  getFullEntityFromState(v) {
    return {
      id: v.id,
      secOrder: this.state.secOrderSelectValue,
      typeItemGlosa: this.state.typeItemGlosaSelectValue,
      quantityGlossed: this.state.quantityGlossedSelectValue,
      quantityRefused: this.state.quantityRefusedSelectValue,
      justification: this.state.justificationSelectValue,
      glosaItemAceita: this.state.glosaItemAceitaSelectValue,
      clientGlosa: this.state.clientGlosaSelectValue,
      quoteMedicine: this.state.quoteMedicineSelectValue,
      quoteDiet: this.state.quoteDietSelectValue,
      quoteMaterial: this.state.quoteMaterialSelectValue,
      quoteProductService: this.state.quoteProductServiceSelectValue,
      quoteRh: this.state.quoteRhSelectValue,
      quotePackage: this.state.quotePackageSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    let p: any = null;

    p = v.clientGlosa;
    const clientGlosaEntity = p ? { ...p, value: p.id, label: p['id'] ? p.id : '' } : p;

    p = v.quoteMedicine;
    const quoteMedicineEntity = p ? { ...p, value: p.id, label: p['id'] ? p.id : '' } : p;

    p = v.quoteDiet;
    const quoteDietEntity = p ? { ...p, value: p.id, label: p['id'] ? p.id : '' } : p;

    p = v.quoteMaterial;
    const quoteMaterialEntity = p ? { ...p, value: p.id, label: p['id'] ? p.id : '' } : p;

    p = v.quoteProductService;
    const quoteProductServiceEntity = p ? { ...p, value: p.id, label: p['id'] ? p.id : '' } : p;

    p = v.quoteRh;
    const quoteRhEntity = p ? { ...p, value: p.id, label: p['id'] ? p.id : '' } : p;

    p = v.quotePackage;
    const quotePackageEntity = p ? { ...p, value: p.id, label: p['id'] ? p.id : '' } : p;

    return {
      secOrderSelectValue: v.secOrder, // secOrder,
      typeItemGlosaSelectValue: v.typeItemGlosa, // typeItemGlosa,
      quantityGlossedSelectValue: v.quantityGlossed, // quantityGlossed,
      quantityRefusedSelectValue: v.quantityRefused, // quantityRefused,
      justificationSelectValue: v.justification, // justification,
      glosaItemAceitaSelectValue: v.glosaItemAceita, // glosaItemAceita,
      clientGlosaSelectValue: clientGlosaEntity,
      quoteMedicineSelectValue: quoteMedicineEntity,
      quoteDietSelectValue: quoteDietEntity,
      quoteMaterialSelectValue: quoteMaterialEntity,
      quoteProductServiceSelectValue: quoteProductServiceEntity,
      quoteRhSelectValue: quoteRhEntity,
      quotePackageSelectValue: quotePackageEntity,
    };
  }

  openUpdateModal(v, edit = true) {
    this.props.getEntity(v.id);
    this.setState({
      showModalEdit: v.id,
      showModalEditView: edit,
      ...this.getFullEntitySelectValue(v),
    });
  }

  renderUpdateModal() {
    const { clientGlosaItemEntity, entityEmbebedPai } = this.props;
    return clientGlosaItemEntity && clientGlosaItemEntity.id ? (
      <Modal
        size={'xl'}
        isOpen={this.state.showModalEdit === clientGlosaItemEntity.id}
        toggle={() => this.setState({ showModalEdit: null })}
      >
        <ModalHeader toggle={() => this.setState({ showModalEdit: null })}>
          {this.renderHeaderUpdate(clientGlosaItemEntity, false)}
        </ModalHeader>
        <AvForm
          ref={el => (this.myFormRefEdit = el)}
          model={{
            ...clientGlosaItemEntity,
          }}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, false)}
        >
          <ModalBody>{this.renderBodyUpdate(clientGlosaItemEntity, false)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalEdit: null })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.clientGlosaItem.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefEdit && this.myFormRefEdit.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.clientGlosaItem.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    ) : (
      <> </>
    );
  }

  renderHeaderUpdate(clientGlosaItemEntity, isNew) {
    const {
      entityEmbebedPai,
      clientGlosas,
      quoteMedicines,
      quoteDiets,
      quoteMaterials,
      quoteProductServices,
      quoteRhs,
      quotePackages,
      loading,
    } = this.props;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {isNew ? (
              <Translate contentKey="generadorApp.clientGlosaItem.home.createLabel">Create a ClientGlosaItem</Translate>
            ) : (
              <Translate contentKey="generadorApp.clientGlosaItem.home.editLabel">Edit a ClientGlosaItem</Translate>
            )}
          </span>
        </div>
      </>
    );
  }
  renderBodyUpdate(clientGlosaItemEntity, isNew) {
    const {
      entityEmbebedPai,
      clientGlosas,
      quoteMedicines,
      quoteDiets,
      quoteMaterials,
      quoteProductServices,
      quoteRhs,
      quotePackages,
      loading,
    } = this.props;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
              <Col md="3">
              <Label className="mt-2 label-single-line"for="client-glosa-item-id">
                <Translate contentKey="generadorApp.clientGlosaItem.field_id">ID</Translate>
              </Label>
              </Col> */}
                      <Col md="12">
                        <AvInput id="client-glosa-item-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'typeItemGlosa' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="typeItemGlosaLabel" for="client-glosa-item-typeItemGlosa">
                              <Translate contentKey="generadorApp.clientGlosaItem.typeItemGlosa">Group</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="client-glosa-item-typeItemGlosa"
                              className={'css-select-control'}
                              value={
                                this.state.typeItemGlosaSelectValue
                                  ? {
                                      value: this.state.typeItemGlosaSelectValue,
                                      label: translate('generadorApp.TypeBilling.' + this.state.typeItemGlosaSelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.clientGlosaItem.typeItemGlosa') }
                              }
                              options={[
                                { value: 'PACKAGE', label: translate('generadorApp.TypeBilling.PACKAGE') },
                                { value: 'RECURSOSHUMANOS', label: translate('generadorApp.TypeBilling.RECURSOSHUMANOS') },
                                { value: 'MEDICAMENTOS', label: translate('generadorApp.TypeBilling.MEDICAMENTOS') },
                                { value: 'MATERIAIS', label: translate('generadorApp.TypeBilling.MATERIAIS') },
                                { value: 'DIETAS', label: translate('generadorApp.TypeBilling.DIETAS') },
                                { value: 'PRODUCTOSERVICIO', label: translate('generadorApp.TypeBilling.PRODUCTOSERVICIO') },
                              ]}
                              onChange={(options: any) => this.setState({ typeItemGlosaSelectValue: options['value'] })}
                              name="typeItemGlosa"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ typeItemGlosaSelectValue: evt.target.value })}
                      type="hidden"
                      name="typeItemGlosa"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'quantityGlossed' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="quantityGlossedLabel" for="client-glosa-item-quantityGlossed">
                              <Translate contentKey="generadorApp.clientGlosaItem.quantityGlossed">Quantity glossed</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ quantityGlossedSelectValue: floatvalue })}
                              decimalSeparator=""
                              thousandSeparator=""
                              precision={0}
                              prefix=""
                              allowNegative={true}
                              value={this.state.quantityGlossedSelectValue ? this.state.quantityGlossedSelectValue : 0}
                              id="client-glosa-item-quantityGlossed"
                              name="quantityGlossed"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ quantityGlossedSelectValue: evt.target.value })}
                      type="hidden"
                      name="quantityGlossed"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'quantityRefused' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="quantityRefusedLabel" for="client-glosa-item-quantityRefused">
                              <Translate contentKey="generadorApp.clientGlosaItem.quantityRefused">Quantity refused</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ quantityRefusedSelectValue: floatvalue })}
                              decimalSeparator=""
                              thousandSeparator=""
                              precision={0}
                              prefix=""
                              allowNegative={true}
                              value={this.state.quantityRefusedSelectValue ? this.state.quantityRefusedSelectValue : 0}
                              id="client-glosa-item-quantityRefused"
                              name="quantityRefused"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ quantityRefusedSelectValue: evt.target.value })}
                      type="hidden"
                      name="quantityRefused"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'glosaItemAceita' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="glosaItemAceitaLabel" for="client-glosa-item-glosaItemAceita">
                              <Translate contentKey="generadorApp.clientGlosaItem.glosaItemAceita">Accepted</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="client-glosa-item-glosaItemAceita"
                              className={'css-select-control'}
                              value={
                                this.state.glosaItemAceitaSelectValue
                                  ? {
                                      value: this.state.glosaItemAceitaSelectValue,
                                      label: translate('generadorApp.GeralSelect.' + this.state.glosaItemAceitaSelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.clientGlosaItem.glosaItemAceita') }
                              }
                              options={[
                                { value: 'SIM', label: translate('generadorApp.GeralSelect.SIM') },
                                { value: 'NAO', label: translate('generadorApp.GeralSelect.NAO') },
                              ]}
                              onChange={(options: any) => this.setState({ glosaItemAceitaSelectValue: options['value'] })}
                              name="glosaItemAceita"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ glosaItemAceitaSelectValue: evt.target.value })}
                      type="hidden"
                      name="glosaItemAceita"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'justification' ? (
                    <Col md="12">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="justificationLabel" for="client-glosa-item-justification">
                              <Translate contentKey="generadorApp.clientGlosaItem.justification">Justification</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ justificationSelectValue: evt.target.value })}
                              value={this.state.justificationSelectValue ? this.state.justificationSelectValue : ''}
                              id="client-glosa-item-justification"
                              type="text"
                              name="justification"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ justificationSelectValue: evt.target.value })}
                      type="hidden"
                      name="justification"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {checkAnyValueFields(this.state, 'typeItemGlosaSelectValue', 'PACKAGE', true) && baseFilters !== 'quotePackage' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="client-glosa-item-quotePackage">
                              <Translate contentKey="generadorApp.clientGlosaItem.quotePackage">Quote Package</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="client-glosa-item-quotePackage"
                              name={'quotePackage'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.quotePackageSelectValue ? this.state.quotePackageSelectValue : ''}
                              onChange={options => this.setState({ quotePackageSelectValue: options })}
                              defaultOptions={this.state.quotePackageStartSelectOptions ? this.state.quotePackageStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.quotePackageStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.quotePackageStartSelectOptions === undefined) {
                                  const result = await getListAxios('quote-packages', {}, 0, 100, 'id,asc', 'id');
                                  this.setState({
                                    quotePackageStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios('quote-packages', { 'id.contains': inputValue }, 0, 100, 'id,asc', 'id');
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="quotePackage"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}
                  {checkAnyValueFields(this.state, 'typeItemGlosaSelectValue', 'RECURSOSHUMANOS', true) && baseFilters !== 'quoteRh' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="client-glosa-item-quoteRh">
                              <Translate contentKey="generadorApp.clientGlosaItem.quoteRh">Quote Rh</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="client-glosa-item-quoteRh"
                              name={'quoteRh'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.quoteRhSelectValue ? this.state.quoteRhSelectValue : ''}
                              onChange={options => this.setState({ quoteRhSelectValue: options })}
                              defaultOptions={this.state.quoteRhStartSelectOptions ? this.state.quoteRhStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.quoteRhStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.quoteRhStartSelectOptions === undefined) {
                                  const result = await getListAxios('quote-rhs', {}, 0, 100, 'id,asc', 'id');
                                  this.setState({
                                    quoteRhStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios('quote-rhs', { 'id.contains': inputValue }, 0, 100, 'id,asc', 'id');
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="quoteRh"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}
                  {checkAnyValueFields(this.state, 'typeItemGlosaSelectValue', 'MEDICAMENTOS', true) && baseFilters !== 'quoteMedicine' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="client-glosa-item-quoteMedicine">
                              <Translate contentKey="generadorApp.clientGlosaItem.quoteMedicine">Quote Medicine</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="client-glosa-item-quoteMedicine"
                              name={'quoteMedicine'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.quoteMedicineSelectValue ? this.state.quoteMedicineSelectValue : ''}
                              onChange={options => this.setState({ quoteMedicineSelectValue: options })}
                              defaultOptions={this.state.quoteMedicineStartSelectOptions ? this.state.quoteMedicineStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.quoteMedicineStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.quoteMedicineStartSelectOptions === undefined) {
                                  const result = await getListAxios('quote-medicines', {}, 0, 100, 'id,asc', 'id');
                                  this.setState({
                                    quoteMedicineStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios('quote-medicines', { 'id.contains': inputValue }, 0, 100, 'id,asc', 'id');
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="quoteMedicine"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}
                  {checkAnyValueFields(this.state, 'typeItemGlosaSelectValue', 'MATERIAIS', true) && baseFilters !== 'quoteDiet' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="client-glosa-item-quoteDiet">
                              <Translate contentKey="generadorApp.clientGlosaItem.quoteDiet">Quote Diet</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="client-glosa-item-quoteDiet"
                              name={'quoteDiet'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.quoteDietSelectValue ? this.state.quoteDietSelectValue : ''}
                              onChange={options => this.setState({ quoteDietSelectValue: options })}
                              defaultOptions={this.state.quoteDietStartSelectOptions ? this.state.quoteDietStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.quoteDietStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.quoteDietStartSelectOptions === undefined) {
                                  const result = await getListAxios('quote-diets', {}, 0, 100, 'id,asc', 'id');
                                  this.setState({
                                    quoteDietStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios('quote-diets', { 'id.contains': inputValue }, 0, 100, 'id,asc', 'id');
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="quoteDiet"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}
                  {checkAnyValueFields(this.state, 'typeItemGlosaSelectValue', 'DIETAS', true) && baseFilters !== 'quoteMaterial' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="client-glosa-item-quoteMaterial">
                              <Translate contentKey="generadorApp.clientGlosaItem.quoteMaterial">Quote Material</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="client-glosa-item-quoteMaterial"
                              name={'quoteMaterial'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.quoteMaterialSelectValue ? this.state.quoteMaterialSelectValue : ''}
                              onChange={options => this.setState({ quoteMaterialSelectValue: options })}
                              defaultOptions={this.state.quoteMaterialStartSelectOptions ? this.state.quoteMaterialStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.quoteMaterialStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.quoteMaterialStartSelectOptions === undefined) {
                                  const result = await getListAxios('quote-materials', {}, 0, 100, 'id,asc', 'id');
                                  this.setState({
                                    quoteMaterialStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios('quote-materials', { 'id.contains': inputValue }, 0, 100, 'id,asc', 'id');
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="quoteMaterial"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}
                  {checkAnyValueFields(this.state, 'typeItemGlosaSelectValue', 'PRODUCTOSERVICIO', true) &&
                  baseFilters !== 'quoteProductService' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="client-glosa-item-quoteProductService">
                              <Translate contentKey="generadorApp.clientGlosaItem.quoteProductService">Quote Product Service</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="client-glosa-item-quoteProductService"
                              name={'quoteProductService'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.quoteProductServiceSelectValue ? this.state.quoteProductServiceSelectValue : ''}
                              onChange={options => this.setState({ quoteProductServiceSelectValue: options })}
                              defaultOptions={
                                this.state.quoteProductServiceStartSelectOptions ? this.state.quoteProductServiceStartSelectOptions : []
                              }
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.quoteProductServiceStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.quoteProductServiceStartSelectOptions === undefined) {
                                  const result = await getListAxios('quote-product-services', {}, 0, 100, 'id,asc', 'id');
                                  this.setState({
                                    quoteProductServiceStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'quote-product-services',
                                  { 'id.contains': inputValue },
                                  0,
                                  100,
                                  'id,asc',
                                  'id'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="quoteProductService"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}{' '}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  renderHeader() {
    const {
      clientGlosas,
      quoteMedicines,
      quoteDiets,
      quoteMaterials,
      quoteProductServices,
      quoteRhs,
      quotePackages,
      clientGlosaItemList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.clientGlosaItem.home.title">Items</Translate>
          </span>
          <Button
            onClick={() =>
              this.setState({
                showModalForm: true,
                secOrderSelectValue: null,
                typeItemGlosaSelectValue: null,
                quantityGlossedSelectValue: null,
                quantityRefusedSelectValue: null,
                justificationSelectValue: null,
                glosaItemAceitaSelectValue: null,

                quoteMedicineStartSelectOptions: undefined,
                quoteMedicineSelectValue: null,

                quoteDietStartSelectOptions: undefined,
                quoteDietSelectValue: null,

                quoteMaterialStartSelectOptions: undefined,
                quoteMaterialSelectValue: null,

                quoteProductServiceStartSelectOptions: undefined,
                quoteProductServiceSelectValue: null,

                quoteRhStartSelectOptions: undefined,
                quoteRhSelectValue: null,

                quotePackageStartSelectOptions: undefined,
                quotePackageSelectValue: null,
                isNew: true,
              })
            }
            color="primary"
            className="btn btn-primary float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="generadorApp.clientGlosaItem.btnNewItem">Create new</Translate>
          </Button>{' '}
          &nbsp;
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/clientGlosa'}>
              <Translate contentKey="generadorApp.clientGlosa.home.title">ClientGlosas</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/clientGlosa/' + this.props.match.params['idClientGlosa']}>
              {this.state.clientGlosaSelectValue ? this.state.clientGlosaSelectValue.label : this.props.match.params['idClientGlosa']}
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.clientGlosaItem.home.title">Items</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    return <> </>;
  }

  renderTable() {
    const {
      clientGlosas,
      quoteMedicines,
      quoteDiets,
      quoteMaterials,
      quoteProductServices,
      quoteRhs,
      quotePackages,
      clientGlosaItemList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {clientGlosaItemList && clientGlosaItemList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null).length > 0 ? (
          <>
            <Table
              id="client-glosa-item-table-list"
              responsive
              aria-describedby="client-glosa-item-heading"
              className={'table-hover table-striped table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.clientGlosaItem.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'typeItemGlosa' ? (
                    <th className="hand" onClick={sortFunction('typeItemGlosa')}>
                      <Translate contentKey="generadorApp.clientGlosaItem.typeItemGlosa">Group</Translate>
                      <FontAwesomeIcon icon={state.sort === 'typeItemGlosa' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'quantityGlossed' ? (
                    <th className="hand" onClick={sortFunction('quantityGlossed')}>
                      <Translate contentKey="generadorApp.clientGlosaItem.quantityGlossed">Quantity glossed</Translate>
                      <FontAwesomeIcon
                        icon={state.sort === 'quantityGlossed' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'}
                      />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'quantityRefused' ? (
                    <th className="hand" onClick={sortFunction('quantityRefused')}>
                      <Translate contentKey="generadorApp.clientGlosaItem.quantityRefused">Quantity refused</Translate>
                      <FontAwesomeIcon
                        icon={state.sort === 'quantityRefused' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'}
                      />
                    </th>
                  ) : null}

                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {clientGlosaItemList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((clientGlosaItem, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.clientGlosaItem.field_id">ID</Translate>
                        </b>
                        {clientGlosaItem.id}
                      </td>

                      {state.baseFilters !== 'typeItemGlosa' ? (
                        <td id="typeItemGlosa-cell">
                          <b className="visible-xs"> Group </b>
                          {clientGlosaItem.typeItemGlosa ? (
                            <Translate contentKey={`generadorApp.TypeBilling.${clientGlosaItem.typeItemGlosa}`} />
                          ) : (
                            <> </>
                          )}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'quantityGlossed' ? (
                        <td id="quantityGlossed-cell">
                          <b className="visible-xs"> Quantity glossed </b>

                          {clientGlosaItem.quantityGlossed}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'quantityRefused' ? (
                        <td id="quantityRefused-cell">
                          <b className="visible-xs"> Quantity refused </b>

                          {clientGlosaItem.quantityRefused}
                        </td>
                      ) : null}

                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          <Button tag={Link} to={`${match.url}/${clientGlosaItem.id}`} color="success" size="sm">
                            <FontAwesomeIcon icon="" />

                            <span className="d-none d-md-inline">
                              <Translate contentKey="generadorApp.clientGlosaItem.listButtons.sim">sim</Translate>
                            </span>
                          </Button>
                          <Button tag={Link} to={`${match.url}/${clientGlosaItem.id}`} color="danger" size="sm">
                            <FontAwesomeIcon icon="" />

                            <span className="d-none d-md-inline">
                              <Translate contentKey="generadorApp.clientGlosaItem.listButtons.nao">nao</Translate>
                            </span>
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.clientGlosaItem.home.notFound">No Items found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const {
      clientGlosas,
      quoteMedicines,
      quoteDiets,
      quoteMaterials,
      quoteProductServices,
      quoteRhs,
      quotePackages,
      clientGlosaItemList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={clientGlosaItemList && clientGlosaItemList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const {
      clientGlosas,
      quoteMedicines,
      quoteDiets,
      quoteMaterials,
      quoteProductServices,
      quoteRhs,
      quotePackages,
      clientGlosaItemList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel className="client-glosa-item client-glosa-item-all-in-one">
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderTable()}
            {this.renderNewModal()}
            {this.renderUpdateModal()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToProps = ({ clientGlosaItem, ...storeState }: IRootState) => ({
  clientGlosas: storeState.clientGlosa.entities,
  quoteMedicines: storeState.quoteMedicine.entities,
  quoteDiets: storeState.quoteDiet.entities,
  quoteMaterials: storeState.quoteMaterial.entities,
  quoteProductServices: storeState.quoteProductService.entities,
  quoteRhs: storeState.quoteRh.entities,
  quotePackages: storeState.quotePackage.entities,
  clientGlosaItemList: clientGlosaItem.entities,
  totalItems: clientGlosaItem.totalItems,
  userAccount: storeState.authentication.account,
  loading: clientGlosaItem.loading,

  // UPDATE
  clientGlosaItemEntity: clientGlosaItem.entity,
});

export const mapDispatchToProps = {
  getEntities,

  updateEntity,
  reset,

  // UPDATE
  getEntity,
  createEntity,

  // DELETE
  deleteEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ClientGlosaItem);
