/* eslint no-console: off */
import 'react-toastify/dist/ReactToastify.css';
import './app.scss';

import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import { IRootState } from 'app/shared/reducers';
import { getSession } from 'app/shared/reducers/authentication';
import { getProfile } from 'app/shared/reducers/application-profile';
import { setLocale } from 'app/shared/reducers/locale';
import Header from 'app/shared/layout/header/header';
import Sidebar from 'app/shared/layout/sidebar/sidebar';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { AUTHORITIES } from 'app/config/constants';
import { Switch, RouteComponentProps } from 'react-router-dom';
import Loadable from 'react-loadable';

import Home from 'app/modules/home/home';
import { toggleSidebarMinify, toggleMobileSidebar } from 'app/shared/reducers/sidebar.ts';
import Entities from 'app/entities';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import PadAttendanceListAll from './entities/pad-attendance/extended/pad-attendance-list-all';
import AuditEntity from './entities/category/extended/audit-entity';
import Romaneio from './entities/quote/extended/romaneio';
import RomaneioUpdate from './entities/quote/extended/romaneio-update';
import Bill from './entities/quote/extended/bill';
import BillUpdate from './entities/quote/extended/bill-update';
import CashFlow from './entities/billing-xml/cash-flow';
import Suprimento from './entities/quote/extended/suprimento';
import SuprimentoUpdate from './entities/quote/extended/suprimento-update';
import Reports from './entities/reports/reports';
import Deposit from './entities/deposit/deposit';

const Account = Loadable({
  loader: () => import(/* webpackChunkName: "account" */ 'app/modules/account'),
  loading: () => (
    <div className="app-loading">
      <div className="lds-css ng-scope">
        <i className="fas fa-spinner fa-spin fa-7x" style={{ marginTop: '58px' }}>
          {' '}
        </i>
      </div>
    </div>
  ),
});

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
  loading: () => (
    <div className="app-loading">
      <div className="lds-css ng-scope">
        <i className="fas fa-spinner fa-spin fa-7x" style={{ marginTop: '58px' }}>
          {' '}
        </i>
      </div>
    </div>
  ),
});

// const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

export interface IRoutesProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const Routes = (props: any) => {
  useEffect(() => {
    props.getSession();
    props.getProfile();
  }, []);

  const paddingTop = '60px';
  return (
    <div
      className={
        'fade page-sidebar-fixed show page-container page-header-fixed ' +
        (props.pageSidebarMinified ? 'page-sidebar-minified' : '') +
        (props.pageSidebarMovilOpen ? 'page-sidebar-toggled' : '')
      }
      style={{ paddingTop }}
    >
      <ErrorBoundary>
        <Header
          isAuthenticated={props.isAuthenticated}
          isAdmin={props.isAdmin}
          currentLocale={props.currentLocale}
          toggleSidebarMinify={props.toggleSidebarMinify}
          toggleMobileSidebar={props.toggleMobileSidebar}
          pageSidebarMinified={props.pageSidebarMinified}
          pageSidebarTransparent={props.pageSidebarTransparent}
          onLocaleChange={props.setLocale}
          ribbonEnv={props.ribbonEnv}
          isInProduction={props.isInProduction}
          isSwaggerEnabled={props.isSwaggerEnabled}
        />
      </ErrorBoundary>

      <div className={'content'}>
        <ErrorBoundary>
          <Sidebar
            pageSidebarMinified={props.pageSidebarMinified}
            pageSidebarTransparent={props.pageSidebarTransparent}
            toggleSidebarMinify={props.toggleSidebarMinify}
            toggleMobileSidebar={props.toggleMobileSidebar}
            userAccount={props.userAccount}
            whiteLabel={props.userAccount.whiteLabel}
          />
        </ErrorBoundary>
        <ErrorBoundary>
          <Switch>
            <PrivateRoute path="/admin" component={Admin} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
            <PrivateRoute path="/account" component={Account} />
            <ErrorBoundaryRoute path="/romaneio" exact component={Romaneio} />
            <ErrorBoundaryRoute path="/reports" exact component={Reports} />
            <ErrorBoundaryRoute path="/pharmacy-deposit" exact component={Deposit} />
            <ErrorBoundaryRoute path="/romaneio/:id" exact component={RomaneioUpdate} />
            <ErrorBoundaryRoute path="/suprimento" exact component={Suprimento} />
            <ErrorBoundaryRoute path="/suprimento/:id" exact component={SuprimentoUpdate} />
            <ErrorBoundaryRoute path="/bills" exact component={Bill} />
            <ErrorBoundaryRoute path="/bills/:id" exact component={BillUpdate} />
            <ErrorBoundaryRoute path="/cash-flow" exact component={CashFlow} />
            <ErrorBoundaryRoute path="/attendance-list/" exact component={PadAttendanceListAll} />
            <ErrorBoundaryRoute path="/" exact component={Home} />
            <PrivateRoute path="/" component={Entities} />
          </Switch>
          <Switch>
            <ErrorBoundaryRoute path="/category/:id/audit-entity/" exact component={AuditEntity} />
          </Switch>
        </ErrorBoundary>
      </div>
    </div>
  );
};

const mapStateToProps = ({ authentication, applicationProfile, locale, sidebar }: IRootState) => ({
  pageSidebarMovilOpen: sidebar.pageSidebarMovilOpen,
  pageSidebarMinified: sidebar.pageSidebarMinified,
  pageSidebarTransparent: sidebar.pageSidebarTransparent,
  currentLocale: locale.currentLocale,
  isAuthenticated: authentication.isAuthenticated,
  userAccount: authentication.account,
  isAdmin: authentication.account.authorities && authentication.account.authorities.includes(AUTHORITIES.ADMIN),
  ribbonEnv: applicationProfile.ribbonEnv,
  isInProduction: applicationProfile.inProduction,
  isSwaggerEnabled: applicationProfile.isSwaggerEnabled,
});

const mapDispatchToProps = { setLocale, getSession, getProfile, toggleSidebarMinify, toggleMobileSidebar };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
