/* eslint complexity: ["error", 500] */
/* eslint no-empty: off */
import React from 'react';
import { connect } from 'react-redux';
import './styled.css';

import SelectAsync from 'react-select/async';

import { Link, RouteComponentProps } from 'react-router-dom';

import CurrencyInput from 'react-currency-input';
import { getFilterFromSelect, getFormFromSelect, getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Col, Label } from 'reactstrap';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import {
  IPharmacyStockUpdateState as IBaseUpdateState,
  getEntity,
  getPharmacyStockState,
  getUrlBack,
  updateEntity,
  createEntity,
  getEntityFiltersURL,
  getEntityOffset,
  reset,
} from './pharmacy-stock.reducer';

import { convertDateTimeToServer } from 'app/shared/util/date-utils';

import { StatePropsExtended, DispatchPropsExtended, IUpdateExtendedState } from './extended/pharmacy-stock-update';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';
import moment from 'moment';
import { parseString } from 'xml2js';
import Axios from 'axios';
registerLocale('pt', pt);

export interface IPharmacyStockUpdateProps
  extends StateProps,
  DispatchProps,
  StatePropsExtended,
  DispatchPropsExtended,
  RouteComponentProps<{ url: string }> { }
export interface IPharmacyStockUpdateState extends IBaseUpdateState, IUpdateExtendedState { }

export class PharmacyStockUpdate extends React.Component<IPharmacyStockUpdateProps, IPharmacyStockUpdateState> {
  lotFileInput: any;

  validateFileInput: any;

  notificationDateFileInput: any;

  stockedLocationFileInput: any;

  princePaidFileInput: any;

  quantityFileInput: any;

  amountExtractFileInput: any;

  statusFileInput: any;

  externalPurchaseFileInput: any;

  observationFileInput: any;

  xmlData: any;

  convertedData: any;



  actionTypeFileInput: any;
  getAllDeposit: any;
  constructor(props: Readonly<IPharmacyStockUpdateProps>) {
    super(props);

    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      functionEmbebed: [],

      xmlData: null,
      parsedData: null,
      getAllDeposit: [],
      checkImportNF: false,

      pharmacyStockStatusSelectValue: null,

      medicineMaterialSelectValue: null,

      supplierSelectValue: null,

      nfeSelectValue: null,

      lotSelectValue: null,
      validateSelectValue: null,
      notificationDateSelectValue: null,
      stockedLocationSelectValue: null,
      princePaidSelectValue: null,
      quantitySelectValue: null,
      amountExtractSelectValue: null,
      statusSelectValue: null,
      externalPurchaseSelectValue: null,
      observationSelectValue: null,
      actionTypeSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getPharmacyStockState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
      medicineMaterialName: null,
      supplierFantasyName: null,
      nfeNumber: null,

      isNew: !this.props.match.params || !this.props.match.params['id'],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateSuccess !== this.props.updateSuccess && prevProps.updateSuccess) {
      this.handleClose();
    } else {

    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.pharmacyStockEntity && prevProps.pharmacyStockEntity.id !== this.props.pharmacyStockEntity.id) ||
      (this.props.pharmacyStockStatuses.length > 0 &&
        this.state.pharmacyStockStatusSelectValue === null &&
        this.props.pharmacyStockEntity.pharmacyStockStatus)
    ) {
      this.setState({
        pharmacyStockStatusSelectValue:
          this.props.pharmacyStockEntity && this.props.pharmacyStockEntity.pharmacyStockStatus
            ? this.props.pharmacyStockEntity.pharmacyStockStatus.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.pharmacyStockEntity && prevProps.pharmacyStockEntity.id !== this.props.pharmacyStockEntity.id) ||
      (this.props.medicineMaterials &&
        this.state.medicineMaterialSelectValue === null &&
        this.props.pharmacyStockEntity.medicineMaterial &&
        this.props.pharmacyStockEntity.medicineMaterial.id)
    ) {
      const p =
        this.props.pharmacyStockEntity && this.props.pharmacyStockEntity.medicineMaterial
          ? this.props.pharmacyStockEntity.medicineMaterial
          : null;
      this.setState({
        medicineMaterialSelectValue: p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : null,
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.pharmacyStockEntity && prevProps.pharmacyStockEntity.id !== this.props.pharmacyStockEntity.id) ||
      (this.props.suppliers &&
        this.state.supplierSelectValue === null &&
        this.props.pharmacyStockEntity.supplier &&
        this.props.pharmacyStockEntity.supplier.id)
    ) {
      const p = this.props.pharmacyStockEntity && this.props.pharmacyStockEntity.supplier ? this.props.pharmacyStockEntity.supplier : null;
      this.setState({
        supplierSelectValue: p ? { ...p, value: p.id, label: p['fantasyName'] ? p.fantasyName : '' } : null,
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.pharmacyStockEntity && prevProps.pharmacyStockEntity.id !== this.props.pharmacyStockEntity.id) ||
      (this.props.nfes && this.state.nfeSelectValue === null && this.props.pharmacyStockEntity.nfe && this.props.pharmacyStockEntity.nfe.id)
    ) {
      const p = this.props.pharmacyStockEntity && this.props.pharmacyStockEntity.nfe ? this.props.pharmacyStockEntity.nfe : null;
      this.setState({
        nfeSelectValue: p ? { ...p, value: p.id, label: p['number'] ? p.number : '' } : null,
      });
    }

    if (
      (prevProps.pharmacyStockEntity && prevProps.pharmacyStockEntity.id !== this.props.pharmacyStockEntity.id) ||
      (this.props.pharmacyStockEntity.lot &&
        this.state.lotSelectValue === null &&
        this.props.pharmacyStockEntity.lot !== this.state.lotSelectValue)
    ) {
      this.setState({ lotSelectValue: this.props.pharmacyStockEntity.lot ? this.props.pharmacyStockEntity.lot : null });
    }
    if (
      (prevProps.pharmacyStockEntity && prevProps.pharmacyStockEntity.id !== this.props.pharmacyStockEntity.id) ||
      (this.props.pharmacyStockEntity.validate &&
        this.state.validateSelectValue === null &&
        this.props.pharmacyStockEntity.validate !== this.state.validateSelectValue)
    ) {
      this.setState({ validateSelectValue: this.props.pharmacyStockEntity.validate ? this.props.pharmacyStockEntity.validate : null });
    }
    if (
      (prevProps.pharmacyStockEntity && prevProps.pharmacyStockEntity.id !== this.props.pharmacyStockEntity.id) ||
      (this.props.pharmacyStockEntity.notificationDate &&
        this.state.notificationDateSelectValue === null &&
        this.props.pharmacyStockEntity.notificationDate !== this.state.notificationDateSelectValue)
    ) {
      this.setState({
        notificationDateSelectValue: this.props.pharmacyStockEntity.notificationDate
          ? this.props.pharmacyStockEntity.notificationDate
          : null,
      });
    }
    if (
      (prevProps.pharmacyStockEntity && prevProps.pharmacyStockEntity.id !== this.props.pharmacyStockEntity.id) ||
      (this.props.pharmacyStockEntity.stockedLocation &&
        this.state.stockedLocationSelectValue === null &&
        this.props.pharmacyStockEntity.stockedLocation !== this.state.stockedLocationSelectValue)
    ) {
      this.setState({
        stockedLocationSelectValue: this.props.pharmacyStockEntity.stockedLocation ? this.props.pharmacyStockEntity.stockedLocation : null,
      });
    }
    if (
      (prevProps.pharmacyStockEntity && prevProps.pharmacyStockEntity.id !== this.props.pharmacyStockEntity.id) ||
      (this.props.pharmacyStockEntity.princePaid &&
        this.state.princePaidSelectValue === null &&
        this.props.pharmacyStockEntity.princePaid !== this.state.princePaidSelectValue)
    ) {
      this.setState({
        princePaidSelectValue: this.props.pharmacyStockEntity.princePaid ? this.props.pharmacyStockEntity.princePaid : null,
      });
    }
    if (
      (prevProps.pharmacyStockEntity && prevProps.pharmacyStockEntity.id !== this.props.pharmacyStockEntity.id) ||
      (this.props.pharmacyStockEntity.quantity &&
        this.state.quantitySelectValue === null &&
        this.props.pharmacyStockEntity.quantity !== this.state.quantitySelectValue)
    ) {
      this.setState({ quantitySelectValue: this.props.pharmacyStockEntity.quantity ? this.props.pharmacyStockEntity.quantity : null });
    }
    if (
      (prevProps.pharmacyStockEntity && prevProps.pharmacyStockEntity.id !== this.props.pharmacyStockEntity.id) ||
      (this.props.pharmacyStockEntity.amountExtract &&
        this.state.amountExtractSelectValue === null &&
        this.props.pharmacyStockEntity.amountExtract !== this.state.amountExtractSelectValue)
    ) {
      this.setState({
        amountExtractSelectValue: this.props.pharmacyStockEntity.amountExtract ? this.props.pharmacyStockEntity.amountExtract : null,
      });
    }
    if (
      (prevProps.pharmacyStockEntity && prevProps.pharmacyStockEntity.id !== this.props.pharmacyStockEntity.id) ||
      (this.props.pharmacyStockEntity.status &&
        this.state.statusSelectValue === null &&
        this.props.pharmacyStockEntity.status !== this.state.statusSelectValue)
    ) {
      this.setState({ statusSelectValue: this.props.pharmacyStockEntity.status ? this.props.pharmacyStockEntity.status : null });
    }
    if (
      (prevProps.pharmacyStockEntity && prevProps.pharmacyStockEntity.id !== this.props.pharmacyStockEntity.id) ||
      (this.state.externalPurchaseSelectValue === null &&
        this.props.pharmacyStockEntity.externalPurchase !== this.state.externalPurchaseSelectValue)
    ) {
      this.setState({
        externalPurchaseSelectValue: this.props.pharmacyStockEntity.externalPurchase
          ? this.props.pharmacyStockEntity.externalPurchase
          : false,
      });
    }
    if (
      (prevProps.pharmacyStockEntity && prevProps.pharmacyStockEntity.id !== this.props.pharmacyStockEntity.id) ||
      (this.props.pharmacyStockEntity.observation &&
        this.state.observationSelectValue === null &&
        this.props.pharmacyStockEntity.observation !== this.state.observationSelectValue)
    ) {
      this.setState({
        observationSelectValue: this.props.pharmacyStockEntity.observation ? this.props.pharmacyStockEntity.observation : null,
      });
    }
    if (
      (prevProps.pharmacyStockEntity && prevProps.pharmacyStockEntity.id !== this.props.pharmacyStockEntity.id) ||
      (this.props.pharmacyStockEntity.actionType &&
        this.state.actionTypeSelectValue === null &&
        this.props.pharmacyStockEntity.actionType !== this.state.actionTypeSelectValue)
    ) {
      this.setState({
        actionTypeSelectValue: this.props.pharmacyStockEntity.actionType ? this.props.pharmacyStockEntity.actionType : null,
      });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  UNSAFE_componentWillMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params['id']);
    }
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = offset !== null ? '&offset=' + offset : '';
    Object.keys(fieldsBase).map(key => {
      url += key !== 'offset' ? '&' + key + '=' + fieldsBase[key] : '';
    });
    return url;
  };
  saveEntity = (event: any, errors: any, values: any) => {
    const selectFieldsList = [
      'medicineMaterial.name',
      'quantity',
      'validate',
      'lot',
      'supplier.fantasyName',
      'princePaid',
      'stockedLocation',
      'status',
    ];

    values.notificationDate = convertDateTimeToServer(values.notificationDate);

    if (errors.length === 0) {
      const { pharmacyStockEntity } = this.props;
      const _entity = {
        ...pharmacyStockEntity,
        // ...values,

        medicineMaterial: getFormFromSelect(this.state.medicineMaterialSelectValue, 'many-to-one'), // many-to-one - otherSide

        supplier: getFormFromSelect(this.state.supplierSelectValue, 'many-to-one'), // many-to-one - otherSide

        nfe: getFormFromSelect(this.state.nfeSelectValue, 'many-to-one'), // many-to-one - otherSide

        lot: this.state.lotSelectValue,
        validate: this.state.validateSelectValue,
        notificationDate: this.state.notificationDateSelectValue,
        stockedLocation: this.state.stockedLocationSelectValue,
        princePaid: this.state.princePaidSelectValue,
        quantity: this.state.quantitySelectValue,
        amountExtract: this.state.amountExtractSelectValue,
        status: this.state.statusSelectValue === null ? null : this.state.statusSelectValue,
        externalPurchase: this.state.externalPurchaseSelectValue === null ? false : this.state.externalPurchaseSelectValue,
        observation: this.state.observationSelectValue,
        actionType: this.state.actionTypeSelectValue === null ? null : this.state.actionTypeSelectValue,
      };

      const entity = _entity;

      const {
        lot,
        validateStart,
        validateEnd,
        medicineMaterialId,
        supplierId,
        externalPurchase,
        extraFilters,
        activePage,
        itemsPerPage,
        sort,
        order,
      } = this.state.fieldsBase;

      if (this.state.isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              lot,
              validateStart,
              validateEnd,
              getFilterFromSelect(medicineMaterialId, 'many-to-one'),
              getFilterFromSelect(supplierId, 'many-to-one'),
              externalPurchase,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              lot,
              validateStart,
              validateEnd,
              getFilterFromSelect(medicineMaterialId, 'many-to-one'),
              getFilterFromSelect(supplierId, 'many-to-one'),
              externalPurchase,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/pharmacy-stock?' + getEntityFiltersURL(this.state.fieldsBase));
  };

  getFullEntityFromState(v) {
    return {
      id: v.id,
      lot: this.state.lotSelectValue,
      validate: this.state.validateSelectValue,
      notificationDate: this.state.notificationDateSelectValue,
      stockedLocation: this.state.stockedLocationSelectValue,
      princePaid: this.state.princePaidSelectValue,
      quantity: this.state.quantitySelectValue,
      amountExtract: this.state.amountExtractSelectValue,
      status: this.state.statusSelectValue,
      externalPurchase: this.state.externalPurchaseSelectValue,
      observation: this.state.observationSelectValue,
      actionType: this.state.actionTypeSelectValue,
      pharmacyStockStatus: this.state.pharmacyStockStatusSelectValue,
      medicineMaterial: this.state.medicineMaterialSelectValue,
      supplier: this.state.supplierSelectValue,
      nfe: this.state.nfeSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    const pharmacyStockStatusEntity = v.pharmacyStockStatus;

    const medicineMaterialEntity = v.medicineMaterial;

    const supplierEntity = v.supplier;

    const nfeEntity = v.nfe;

    return {
      lotSelectValue: v.lot,
      validateSelectValue: v.validate,
      notificationDateSelectValue: v.notificationDate,
      stockedLocationSelectValue: v.stockedLocation,
      princePaidSelectValue: v.princePaid,
      quantitySelectValue: v.quantity,
      amountExtractSelectValue: v.amountExtract,
      statusSelectValue: v.status,
      externalPurchaseSelectValue: v.externalPurchase,
      observationSelectValue: v.observation,
      actionTypeSelectValue: v.actionType,
      pharmacyStockStatusSelectValue: pharmacyStockStatusEntity ? pharmacyStockStatusEntity['id'] : null,
      medicineMaterialSelectValue: medicineMaterialEntity ? medicineMaterialEntity['id'] : null,
      supplierSelectValue: supplierEntity ? supplierEntity['id'] : null,
      nfeSelectValue: nfeEntity ? nfeEntity['id'] : null,
    };
  }

  renderHeader() {
    const { pharmacyStockEntity, pharmacyStockStatuses, medicineMaterials, suppliers, nfes, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.pharmacyStock.home.createLabel">Create a PharmacyStock</Translate>
            ) : (
              <Translate contentKey="generadorApp.pharmacyStock.home.editLabel">Edit a PharmacyStock</Translate>
            )}
          </span>

          <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.pharmacyStock.btnSave">Save</Translate>
          </Button>
          {this.state.checkImportNF ?

            <span id='button-nf' className="float-right" onClick={() => this.setState({ checkImportNF: false })}>
              <FontAwesomeIcon icon="book" />
              <text> Importar Produto</text>
            </span>

            :

            <span id='button-nf' className="float-right" onClick={() => this.setState({ checkImportNF: true })}>
              <FontAwesomeIcon icon="book" />
              <text> Importar NF</text>
            </span>

          }

          {!this.state.isNew ? (
            <>
              <Button
                color="primary"
                id="next-entity"
                disabled={updating}
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.lot,
                    this.state.fieldsBase.validateStart,
                    this.state.fieldsBase.validateEnd,
                    this.state.fieldsBase.medicineMaterialId,
                    this.state.fieldsBase.supplierId,
                    this.state.fieldsBase.externalPurchase,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 + 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 + 1 },
                    });
                    this.props.history.push(
                      '/pharmacy-stock/' +
                      myresult.value.data[0]['id'] +
                      '/edit?' +
                      this.getFiltersURL(this.state.fieldsBase.offset * 1 + 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.last.item', { fieldName: translate('generadorApp.pharmacyStock.detail.title') }));
                  }
                }}
                className="float-right jh-create-entity"
              >
                {'>>'}
              </Button>

              <Button
                color="primary"
                id="prev-entity"
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.lot,
                    this.state.fieldsBase.validateStart,
                    this.state.fieldsBase.validateEnd,
                    this.state.fieldsBase.medicineMaterialId,
                    this.state.fieldsBase.supplierId,
                    this.state.fieldsBase.externalPurchase,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 - 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 - 1 },
                    });
                    this.props.history.push(
                      '/pharmacy-stock/' +
                      myresult.value.data[0]['id'] +
                      '/edit?' +
                      this.getFiltersURL(this.state.fieldsBase.offset * 1 - 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.first.item', { fieldName: translate('generadorApp.pharmacyStock.detail.title') }));
                  }
                }}
                disabled={updating}
                className="float-right jh-create-entity"
              >
                {'<<'}
              </Button>
            </>
          ) : (
            <> </>
          )}

          <Button
            tag={Link}
            id="cancel-save"
            to={'/pharmacy-stock?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.pharmacyStock.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={'/pharmacy-stock'}>
              <Translate contentKey="generadorApp.pharmacyStock.home.title">Pharmacy Stocks</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.pharmacyStock.detail.title">Pharmacy Stock edit</Translate>
          </li>
        </ol>
      </>
    );
  }


  handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    let itens = []
    reader.onload = (e) => {
      const xmlContent = e.target.result;
      parseString(xmlContent, (err, result) => {

        if (err) {
          console.error('Error parsing XML:', err);
          
        } else {
          // Acesse os elementos e valores específicos do XML
          const nNF = result.nfeProc.NFe[0].infNFe[0].ide[0].nNF[0];
          const dhEmi = result.nfeProc.NFe[0].infNFe[0].ide[0].dhEmi[0];
          const cnpj_emitente = result.nfeProc.NFe[0].infNFe[0].emit[0].CNPJ[0];
          const razao_social_emitente = result.nfeProc.NFe[0].infNFe[0].emit[0].xNome[0];
          const cnpj_destinatario = result.nfeProc.NFe[0].infNFe[0].dest[0].CNPJ[0];
          const razao_social_destinatario = result.nfeProc.NFe[0].infNFe[0].dest[0].xNome[0];
          itens = result.nfeProc.NFe[0].infNFe[0].det;

          // Faça o que for necessário com os valores extraídos do XML
          console.log(nNF);
          console.log(dhEmi);
          console.log(cnpj_emitente);
          console.log(razao_social_emitente);
          console.log(cnpj_destinatario);
          console.log(razao_social_destinatario);
          console.log(itens);
          itens?.map((itens) => {
            console.log(itens?.prod[0]?.cEAN[0])
           })
        }
      });
    }; 

    reader.readAsText(file);
  };

  componentDidMount() {
    Axios.get('/api/deposit-table')
      .then(response => {
        // Manipular a resposta da chamada Axios aqui, se necessário
        this.setState({ getAllDeposit: response.data })
        console.log(response.data);
      })
      .catch(error => {
        // Lidar com erros da chamada Axios aqui, se necessário
        console.error(error);
      });
  }

  renderBody() {
    const { pharmacyStockEntity, pharmacyStockStatuses, medicineMaterials, suppliers, nfes, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const fullEntityEmbebed = this.getFullEntityFromState(pharmacyStockEntity);




    return (
      <>
        {this.state.checkImportNF ?
          <>
            <Row className="justify-content-center">
              <Col md="11">
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <div>
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="pharmacy-stock-nfe">
                              <Translate contentKey="generadorApp.pharmacyStock.nfe">Nfe</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <label style={{}} htmlFor="file-upload" class="custom-file-upload">
                              Importar NF
                            </label>
                            <input id="file-upload" type="file" onChange={this.handleFileChange} />
                          </Col>
                        </Row>
                      </AvGroup>
                      <AvGroup>
                          <Row>
                            <Col md="12">
                              <Label className="mt-2 label-single-line" for="pharmacy-stock-nfe">
                                <text>Deposito</text>
                              </Label>
                            </Col>
                            <Col md="12">
                            <select value={ this.state.princePaidSelectValue } onChange={(evt) =>  this.setState({ stockedLocationSelectValue: evt.target.value })} class="custom-select">
                                {this.state.getAllDeposit?.map((itens) => {
                                  return (
                                    <option>{itens.name}/{itens.local}</option>
                                  )
                                })}
                              </select>
                            </Col>
                          </Row>
                        </AvGroup>
                  </div>
                )}
              </Col>
            </Row>
          </>
          :
          <Row className="justify-content-center">
            <Col md="11">
              {loading ? (
                <p>Loading...</p>
              ) : (
                <div>
                  {!isNew ? (
                    <AvGroup>
                      <Row>
                        {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="pharmacy-stock-id">
                  <Translate contentKey="generadorApp.pharmacyStock.field_id">ID</Translate>
                </Label>
                </Col> */}
                        <Col md="12">
                          <AvInput id="pharmacy-stock-id" type="hidden" className="form-control" name="id" required readOnly />
                        </Col>
                      </Row>
                    </AvGroup>
                  ) : null}
                  <Row>
                    {baseFilters !== 'medicineMaterial' ? (
                      <Col md="6">
                        <AvGroup>
                          <Row>
                            <Col md="12">
                              <Label className="mt-2 label-single-line" for="pharmacy-stock-medicineMaterial">
                                <Translate contentKey="generadorApp.pharmacyStock.medicineMaterial">Medicine Material</Translate>
                              </Label>
                            </Col>
                            <Col md="12">
                              <SelectAsync
                                id="pharmacy-stock-medicineMaterial"
                                name={'medicineMaterial'}
                                className={'css-select-control'}
                                data-type-rel="many-to-one-other-side"
                                value={this.state.medicineMaterialSelectValue ? this.state.medicineMaterialSelectValue : ''}
                                onChange={options => this.setState({ medicineMaterialSelectValue: options })}
                                defaultOptions={
                                  this.state.medicineMaterialStartSelectOptions ? this.state.medicineMaterialStartSelectOptions : []
                                }
                                loadingMessage={input => translate('selectAsync.loadingMessage')}
                                noOptionsMessage={input =>
                                  this.state.medicineMaterialStartSelectOptions === undefined
                                    ? translate('selectAsync.loadingMessage')
                                    : translate('selectAsync.noOptionsMessage')
                                }
                                onMenuOpen={async () => {
                                  if (this.state.medicineMaterialStartSelectOptions === undefined) {
                                    const result = await getListAxios('medicine-materials', {}, 0, 100, 'name,asc', 'name');
                                    this.setState({
                                      medicineMaterialStartSelectOptions: result.data
                                        ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                        : [],
                                    });
                                  }
                                }}
                                loadOptions={async (inputValue, callback) => {
                                  const result = await getListAxios(
                                    'medicine-materials',
                                    { 'name.contains': inputValue },
                                    0,
                                    100,
                                    'name,asc',
                                    'name'
                                  );
                                  callback(
                                    result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                      : []
                                  );
                                }}
                              />
                            </Col>
                          </Row>
                        </AvGroup>
                      </Col>
                    ) : (
                      <AvInput
                        type="hidden"
                        name="medicineMaterial"
                        value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                      />
                    )}

                    {baseFilters !== 'supplier' ? (
                      <Col md="6">
                        <AvGroup>
                          <Row>
                            <Col md="12">
                              <Label className="mt-2 label-single-line" for="pharmacy-stock-supplier">
                                <Translate contentKey="generadorApp.pharmacyStock.supplier">Supplier</Translate>
                              </Label>
                            </Col>
                            <Col md="12">
                              <SelectAsync
                                id="pharmacy-stock-supplier"
                                name={'supplier'}
                                className={'css-select-control'}
                                data-type-rel="many-to-one-other-side"
                                value={this.state.supplierSelectValue ? this.state.supplierSelectValue : ''}
                                onChange={options => this.setState({ supplierSelectValue: options })}
                                defaultOptions={this.state.supplierStartSelectOptions ? this.state.supplierStartSelectOptions : []}
                                loadingMessage={input => translate('selectAsync.loadingMessage')}
                                noOptionsMessage={input =>
                                  this.state.supplierStartSelectOptions === undefined
                                    ? translate('selectAsync.loadingMessage')
                                    : translate('selectAsync.noOptionsMessage')
                                }
                                onMenuOpen={async () => {
                                  if (this.state.supplierStartSelectOptions === undefined) {
                                    const result = await getListAxios('suppliers', {}, 0, 100, 'fantasyName,asc', 'fantasyName');
                                    this.setState({
                                      supplierStartSelectOptions: result.data
                                        ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'fantasyName') || '' }))
                                        : [],
                                    });
                                  }
                                }}
                                loadOptions={async (inputValue, callback) => {
                                  const result = await getListAxios(
                                    'suppliers',
                                    { 'fantasyName.contains': inputValue },
                                    0,
                                    100,
                                    'fantasyName,asc',
                                    'fantasyName'
                                  );
                                  callback(
                                    result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'fantasyName') || '' }))
                                      : []
                                  );
                                }}
                              />
                            </Col>
                          </Row>
                        </AvGroup>
                      </Col>
                    ) : (
                      <AvInput
                        type="hidden"
                        name="supplier"
                        value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                      />
                    )}

                    {baseFilters !== 'externalPurchase' ? (
                      <Col md="3">
                        <AvGroup>
                          <Row>
                            <Col md="12">
                              <Label className="mt-2 label-single-line" id="externalPurchaseLabel" for="pharmacy-stock-externalPurchase">
                                <Translate contentKey="generadorApp.pharmacyStock.externalPurchase">External purchase</Translate>
                              </Label>
                            </Col>
                            <Col md="12">
                              <div className="switcher switcher-success">
                                <input
                                  type="checkbox"
                                  name={'externalPurchase_checkbox'}
                                  id={'externalPurchase_checkbox'}
                                  checked={this.state.externalPurchaseSelectValue}
                                  onChange={evt => this.setState({ externalPurchaseSelectValue: !this.state.externalPurchaseSelectValue })}
                                />
                                <label htmlFor={'externalPurchase_checkbox'} />
                              </div>
                            </Col>
                          </Row>
                        </AvGroup>
                      </Col>
                    ) : (
                      <AvInput
                        onChange={evt => this.setState({ externalPurchaseSelectValue: evt.target.value })}
                        type="hidden"
                        name="externalPurchase"
                        value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                      />
                    )}

                    {this.state.externalPurchaseSelectValue === false && baseFilters !== 'observation' ? (
                      <Col md="3">
                        <AvGroup>
                          <Row>
                            <Col md="12">
                              <Label className="mt-2 label-single-line" id="observationLabel" for="pharmacy-stock-observation">
                                <Translate contentKey="generadorApp.pharmacyStock.observation">Observation</Translate>
                              </Label>
                            </Col>
                            <Col md="12">
                              <input
                                onChange={evt => this.setState({ observationSelectValue: evt.target.value })}
                                value={this.state.observationSelectValue ? this.state.observationSelectValue : ''}
                                id="pharmacy-stock-observation"
                                type="text"
                                name="observation"
                                className={'form-control'}
                              />
                            </Col>
                          </Row>
                        </AvGroup>
                      </Col>
                    ) : (
                      <AvInput
                        onChange={evt => this.setState({ observationSelectValue: evt.target.value })}
                        type="hidden"
                        name="observation"
                        value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                      />
                    )}

                    {baseFilters !== 'lot' ? (
                      <Col md="6">
                        <AvGroup>
                          <Row>
                            <Col md="12">
                              <Label className="mt-2 label-single-line" id="lotLabel" for="pharmacy-stock-lot">
                                <Translate contentKey="generadorApp.pharmacyStock.lot">Lot</Translate>
                              </Label>
                            </Col>
                            <Col md="12">
                              <input
                                onChange={evt => this.setState({ lotSelectValue: evt.target.value })}
                                value={this.state.lotSelectValue ? this.state.lotSelectValue : ''}
                                id="pharmacy-stock-lot"
                                type="text"
                                name="lot"
                                className={'form-control'}
                              />
                            </Col>
                          </Row>
                        </AvGroup>
                      </Col>
                    ) : (
                      <AvInput
                        onChange={evt => this.setState({ lotSelectValue: evt.target.value })}
                        type="hidden"
                        name="lot"
                        value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                      />
                    )}

                    {baseFilters !== 'validate' ? (
                      <Col md="6">
                        <AvGroup>
                          <Row>
                            <Col md="12">
                              <Label className="mt-2 label-single-line" id="validateLabel" for="pharmacy-stock-validate">
                                <Translate contentKey="generadorApp.pharmacyStock.validate">Validate</Translate>
                              </Label>
                            </Col>
                            <Col md="12">
                              <DatePicker
                                autoComplete="off"
                                onChange={value => this.setState({ validateSelectValue: value })}
                                id="validate"
                                validate={{}}
                                className="form-control react-datepicker"
                                name="validate"
                                dateFormat="dd/MM/yyyy"
                                selected={this.state.validateSelectValue ? moment(this.state.validateSelectValue).toDate() : null}
                                locale="pt"
                              />
                            </Col>
                          </Row>
                        </AvGroup>
                      </Col>
                    ) : (
                      <AvInput
                        onChange={evt => this.setState({ validateSelectValue: evt.target.value })}
                        type="hidden"
                        name="validate"
                        value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                      />
                    )}

                    {this.state.externalPurchaseSelectValue === false && baseFilters !== 'notificationDate' ? (
                      <Col md="6">
                        <AvGroup>
                          <Row>
                            <Col md="12">
                              <Label className="mt-2 label-single-line" id="notificationDateLabel" for="pharmacy-stock-notificationDate">
                                <Translate contentKey="generadorApp.pharmacyStock.notificationDate">
                                  Date for notification of validity
                                </Translate>
                              </Label>
                            </Col>
                            <Col md="12">
                              <DatePicker
                                autoComplete="off"
                                onChange={value => this.setState({ notificationDateSelectValue: value })}
                                id="notificationDate"
                                validate={{}}
                                className="form-control react-datepicker"
                                name="notificationDate"
                                dateFormat="dd/MM/yyyy"
                                selected={
                                  this.state.notificationDateSelectValue ? moment(this.state.notificationDateSelectValue).toDate() : null
                                }
                                locale="pt"
                              />
                            </Col>
                          </Row>
                        </AvGroup>
                      </Col>
                    ) : (
                      <AvInput
                        onChange={evt => this.setState({ notificationDateSelectValue: evt.target.value })}
                        type="hidden"
                        name="notificationDate"
                        value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                      />
                    )}

                    {/* {this.state.externalPurchaseSelectValue === false && baseFilters !== 'stockedLocation' ? (
                      <Col md="6">
                        <AvGroup>
                          <Row>
                            <Col md="12">
                              <Label className="mt-2 label-single-line" id="stockedLocationLabel" for="pharmacy-stock-stockedLocation">
                                <Translate contentKey="generadorApp.pharmacyStock.stockedLocation">Stocked Location</Translate>
                              </Label>
                            </Col>
                            <Col md="12">
                              <input
                                onChange={evt => this.setState({ stockedLocationSelectValue: evt.target.value })}
                                value={this.state.stockedLocationSelectValue ? this.state.stockedLocationSelectValue : ''}
                                id="pharmacy-stock-stockedLocation"
                                type="text"
                                name="stockedLocation"
                                className={'form-control'}
                              />
                            </Col>
                          </Row>
                        </AvGroup>
                      </Col>
                    ) : (
                      <AvInput
                        onChange={evt => this.setState({ stockedLocationSelectValue: evt.target.value })}
                        type="hidden"
                        name="stockedLocation"
                        value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                      />
                    )} */}

                    {baseFilters !== 'princePaid' ? (
                      <Col md="6">
                        <AvGroup>
                          <Row>
                            <Col md="12">
                              <Label className={'mt-2'} id="princePaidLabel" for="pharmacy-stock-princePaid">
                                <Translate contentKey="generadorApp.pharmacyStock.princePaid">Prince Paid</Translate>
                              </Label>
                            </Col>

                            <Col md="12">
                              <CurrencyInput
                                onChange={(maskedvalue, floatvalue, event) => this.setState({ princePaidSelectValue: floatvalue })}
                                decimalSeparator=","
                                precision={2}
                                thousandSeparator=""
                                prefix="R$ "
                                allowNegative={true}
                                value={this.state.princePaidSelectValue ? this.state.princePaidSelectValue : 0}
                                id="pharmacy-stock-princePaid"
                                name="princePaid"
                                className={'form-control'}
                              />
                            </Col>
                          </Row>
                        </AvGroup>
                      </Col>
                    ) : (
                      <AvInput
                        onChange={evt => this.setState({ princePaidSelectValue: evt.target.value })}
                        type="hidden"
                        name="princePaid"
                        value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                      />
                    )}

                    {baseFilters !== 'nfe' ? (
                      <Col md="6">

                        <AvGroup>
                          <Row>
                            <Col md="12">
                              <Label className="mt-2 label-single-line" for="pharmacy-stock-nfe">
                                <text>Deposito</text>
                              </Label>
                            </Col>
                            <Col md="12">
                              <select value={ this.state.princePaidSelectValue } onChange={(evt) =>  this.setState({ stockedLocationSelectValue: evt.target.value })} class="custom-select">
                                {this.state.getAllDeposit?.map((itens) => {
                                  return (
                                    <option>{itens.name}/{itens.local}</option>
                                  )
                                })}
                              </select>
                            </Col>
                          </Row>
                        </AvGroup>
                      </Col>


                    ) : (
                      <AvInput
                        type="hidden"
                        name="nfe"
                        value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                      />
                    )}

                    {baseFilters !== 'quantity' ? (
                      <Col md="6">
                        <AvGroup>
                          <Row>
                            <Col md="12">
                              <Label className="mt-2 label-single-line" id="quantityLabel" for="pharmacy-stock-quantity">
                                <Translate contentKey="generadorApp.pharmacyStock.quantity">Quantity</Translate>
                              </Label>
                            </Col>
                            <Col md="12">
                              <CurrencyInput
                                onChange={(maskedvalue, floatvalue, event) => this.setState({ quantitySelectValue: floatvalue })}
                                decimalSeparator=""
                                thousandSeparator=""
                                precision={0}
                                prefix=""
                                allowNegative={true}
                                value={this.state.quantitySelectValue ? this.state.quantitySelectValue : 0}
                                id="pharmacy-stock-quantity"
                                name="quantity"
                                className={'form-control'}
                              />
                            </Col>
                          </Row>
                        </AvGroup>
                      </Col>
                    ) : (
                      <AvInput
                        onChange={evt => this.setState({ quantitySelectValue: evt.target.value })}
                        type="hidden"
                        name="quantity"
                        value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                      />
                    )}
                  </Row>
                </div>
              )}
            </Col>
          </Row>
        }

      </>
    );
  }



  render() {
    const { pharmacyStockEntity, pharmacyStockStatuses, medicineMaterials, suppliers, nfes, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                ...pharmacyStockEntity,
              }
          }
          onSubmit={this.saveEntity}
        >
          <Panel className="pharmacy-stock pharmacy-stock-update">
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToProps = (storeState: IRootState) => {
  return {
    pharmacyStockStatuses: storeState.pharmacyStockStatus.entities,
    medicineMaterials: storeState.medicineMaterial.entities,
    suppliers: storeState.supplier.entities,
    nfes: storeState.nfe.entities,
    pharmacyStockEntity: storeState.pharmacyStock.entity,
    loading: storeState.pharmacyStock.loading,
    updating: storeState.pharmacyStock.updating,
    updateSuccess: storeState.pharmacyStock.updateSuccess,
  };
};

export const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  getEntityOffset,
  reset,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyStockUpdate);
