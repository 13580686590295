/* eslint complexity: ["error", 500] */
/* eslint no-empty: off */
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import SelectAsync from 'react-select/async';
import axios from 'axios';
import { Link, RouteComponentProps } from 'react-router-dom';

import CurrencyInput from 'react-currency-input';
import {
  getFilterFromSelect,
  getFormFromSelect,
  getListAxios,
  showFieldsSelectAsync,
  checkAnyValueFields,
} from 'app/shared/util/entity-utils';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Col, Label } from 'reactstrap';
import { setFileData } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import {
  IClComTableUpdateState as IBaseUpdateState,
  getEntity,
  getClComTableState,
  getUrlBack,
  updateEntity,
  createEntity,
  getEntityFiltersURL,
  getEntityOffset,
  setBlob,
  reset,
  clComRulerSetEntities,
  clComRulerGetEntities,
  clComRulerUpdateEntity,
  clComRulerReset,
  clComRulerGetEntity,
  clComRulerCreateEntity,
  clComRulerDeleteEntity,
  clComItemSetEntities,
  clComItemGetEntities,
  clComItemUpdateEntity,
  clComItemReset,
  clComItemGetEntity,
  clComItemCreateEntity,
  clComItemDeleteEntity,
} from './cl-com-table.reducer';

import {
  mapStateToPropsExtended as mapStateToPropsClComRuler,
  mapDispatchToPropsExtended as mapDispatchToPropsClComRuler,
  ClComRulerExtended as ClComRuler,
} from 'app/entities/cl-com-ruler/extended/cl-com-ruler';

import {
  mapStateToPropsExtended as mapStateToPropsClComItem,
  mapDispatchToPropsExtended as mapDispatchToPropsClComItem,
  ClComItemExtended as ClComItem,
} from 'app/entities/cl-com-item/extended/cl-com-item';

import { StatePropsExtended, DispatchPropsExtended, IUpdateExtendedState } from './extended/cl-com-table-update';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

registerLocale('pt', pt);

export interface IClComTableUpdateProps
  extends StateProps,
    DispatchProps,
    StatePropsExtended,
    DispatchPropsExtended,
    RouteComponentProps<{ url: string }> {}
export interface IClComTableUpdateState extends IBaseUpdateState, IUpdateExtendedState {}

export class ClComTableUpdate extends React.Component<IClComTableUpdateProps, IClComTableUpdateState> {
  nameFileInput: any;

  statusFileInput: any;

  chargeTypeFileInput: any;

  typeFileInput: any;

  valuePackageFileInput: any;

  optionFileInput: any;

  saleValueFileInput: any;

  observationFileInput: any;

  rentalValueFileInput: any;

  tussCodeFileInput: any;

  tableCodeFileInput: any;

  expenseCodeFileInput: any;
  constructor(props: Readonly<IClComTableUpdateProps>) {
    super(props);

    this.clComRulerUpdateEntity = this.clComRulerUpdateEntity.bind(this);
    this.clComRulerGetEntities = this.clComRulerGetEntities.bind(this);
    this.clComRulerReset = this.clComRulerReset.bind(this);
    this.clComRulerGetEntity = this.clComRulerGetEntity.bind(this);
    this.clComRulerCreateEntity = this.clComRulerCreateEntity.bind(this);
    this.clComRulerDeleteEntity = this.clComRulerDeleteEntity.bind(this);
    this.clComItemUpdateEntity = this.clComItemUpdateEntity.bind(this);
    this.clComItemGetEntities = this.clComItemGetEntities.bind(this);
    this.clComItemReset = this.clComItemReset.bind(this);
    this.clComItemGetEntity = this.clComItemGetEntity.bind(this);
    this.clComItemCreateEntity = this.clComItemCreateEntity.bind(this);
    this.clComItemDeleteEntity = this.clComItemDeleteEntity.bind(this);

    this.observationFileInput = React.createRef();

    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      functionEmbebed: [],

      quotePackageSelectValue: null,

      quoteSelectValue: null,

      clComRulerSelectValue: null,

      clComItemSelectValue: null,

      clientSelectValue: { value: this.props.match.params['idClient'], label: this.props.match.params['idClient'] },

      doseSelectValue: null,

      nameSelectValue: null,
      statusSelectValue: null,
      chargeTypeSelectValue: null,
      typeSelectValue: null,
      valuePackageSelectValue: null,
      optionSelectValue: null,
      saleValueSelectValue: null,
      observationSelectValue: null,
      rentalValueSelectValue: null,
      tussCodeSelectValue: null,
      tableCodeSelectValue: null,
      expenseCodeSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getClComTableState(this.props.location),
        urlBack: getUrlBack(this.props.location),
        clientId: [{ value: this.props.match.params['idClient'], label: this.props.match.params['idClient'] }],
        baseFilters: 'client',
      },
      clientFantasyName: null,
      doseName: null,

      isNew: !this.props.match.params || !this.props.match.params['id'],
    };

    axios
      .get('/api/clients/' + this.props.match.params['idClient'], {
        headers: {
          'Select-Fields': 'fantasyName',
        },
      })
      .then(result => {
        this.setState({
          clientSelectValue: {
            ...result['data'],
            value: this.props.match.params['idClient'],
            label: showFieldsSelectAsync(result['data'], 'fantasyName'),
          },
        });
      });
  }

  clComRulerUpdateEntity(...values) {
    values[0]['clComTable'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['updateEntityClComRuler'](...values);
    }
    return this.props.clComRulerUpdateEntity(...values);
  }
  clComRulerGetEntities(...values) {
    return this.props.clComRulerGetEntities(...values);
  }
  clComRulerReset(...values) {
    return this.props.clComRulerReset(...values);
  }
  clComRulerGetEntity(...values) {
    return this.props.clComRulerGetEntity(...values);
  }
  clComRulerCreateEntity(...values) {
    values[0]['clComTable'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['createEntityClComRuler'](...values);
    }
    return this.props.clComRulerCreateEntity(...values);
  }
  clComRulerDeleteEntity(...values) {
    if (!this.state.isNew) {
      this.props['deleteEntityClComRuler'](...values);
    }
    return this.props.clComRulerDeleteEntity(...values);
  }
  clComItemUpdateEntity(...values) {
    values[0]['clComTable'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['updateEntityClComItem'](...values);
    }
    return this.props.clComItemUpdateEntity(...values);
  }
  clComItemGetEntities(...values) {
    return this.props.clComItemGetEntities(...values);
  }
  clComItemReset(...values) {
    return this.props.clComItemReset(...values);
  }
  clComItemGetEntity(...values) {
    return this.props.clComItemGetEntity(...values);
  }
  clComItemCreateEntity(...values) {
    values[0]['clComTable'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['createEntityClComItem'](...values);
    }
    return this.props.clComItemCreateEntity(...values);
  }
  clComItemDeleteEntity(...values) {
    if (!this.state.isNew) {
      this.props['deleteEntityClComItem'](...values);
    }
    return this.props.clComItemDeleteEntity(...values);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateSuccess !== this.props.updateSuccess && prevProps.updateSuccess) {
      this.handleClose();
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.clComTableEntity && prevProps.clComTableEntity.id !== this.props.clComTableEntity.id) ||
      (this.props.quotePackages.length > 0 && this.state.quotePackageSelectValue === null && this.props.clComTableEntity.quotePackage)
    ) {
      this.setState({
        quotePackageSelectValue:
          this.props.clComTableEntity && this.props.clComTableEntity.quotePackage
            ? this.props.clComTableEntity.quotePackage.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.clComTableEntity && prevProps.clComTableEntity.id !== this.props.clComTableEntity.id) ||
      (this.props.quotes.length > 0 && this.state.quoteSelectValue === null && this.props.clComTableEntity.quote)
    ) {
      this.setState({
        quoteSelectValue:
          this.props.clComTableEntity && this.props.clComTableEntity.quote
            ? this.props.clComTableEntity.quote.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.clComTableEntity && prevProps.clComTableEntity.id !== this.props.clComTableEntity.id) ||
      (this.props.clComRulers.length > 0 && this.state.clComRulerSelectValue === null && this.props.clComTableEntity.clComRuler)
    ) {
      this.setState({ clComRulerSelectValue: this.props.clComTableEntity.clComRuler });
      this.props.clComRulerSetEntities(this.props.clComTableEntity.clComRuler);
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.clComTableEntity && prevProps.clComTableEntity.id !== this.props.clComTableEntity.id) ||
      (this.props.clComItems.length > 0 && this.state.clComItemSelectValue === null && this.props.clComTableEntity.clComItem)
    ) {
      this.setState({ clComItemSelectValue: this.props.clComTableEntity.clComItem });
      this.props.clComItemSetEntities(this.props.clComTableEntity.clComItem);
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.clComTableEntity && prevProps.clComTableEntity.id !== this.props.clComTableEntity.id) ||
      (this.props.clients &&
        this.state.clientSelectValue === null &&
        this.props.clComTableEntity.client &&
        this.props.clComTableEntity.client.id)
    ) {
      const p = this.props.clComTableEntity && this.props.clComTableEntity.client ? this.props.clComTableEntity.client : null;
      this.setState({
        clientSelectValue: p ? { ...p, value: p.id, label: p['fantasyName'] ? p.fantasyName : '' } : null,
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.clComTableEntity && prevProps.clComTableEntity.id !== this.props.clComTableEntity.id) ||
      (this.props.doses && this.state.doseSelectValue === null && this.props.clComTableEntity.dose && this.props.clComTableEntity.dose.id)
    ) {
      const p = this.props.clComTableEntity && this.props.clComTableEntity.dose ? this.props.clComTableEntity.dose : null;
      this.setState({
        doseSelectValue: p ? { ...p, value: p.id, label: (p['name'] ? p.name : '') + ' | ' + (p['codeANS'] ? p.codeANS : '') } : null,
      });
    }

    if (
      (prevProps.clComTableEntity && prevProps.clComTableEntity.id !== this.props.clComTableEntity.id) ||
      (this.props.clComTableEntity.name &&
        this.state.nameSelectValue === null &&
        this.props.clComTableEntity.name !== this.state.nameSelectValue)
    ) {
      this.setState({ nameSelectValue: this.props.clComTableEntity.name ? this.props.clComTableEntity.name : null });
    }
    if (
      (prevProps.clComTableEntity && prevProps.clComTableEntity.id !== this.props.clComTableEntity.id) ||
      (this.state.statusSelectValue === null && this.props.clComTableEntity.status !== this.state.statusSelectValue)
    ) {
      this.setState({ statusSelectValue: this.props.clComTableEntity.status ? this.props.clComTableEntity.status : 'ATIVO' });
    }
    if (
      (prevProps.clComTableEntity && prevProps.clComTableEntity.id !== this.props.clComTableEntity.id) ||
      (this.props.clComTableEntity.chargeType &&
        this.state.chargeTypeSelectValue === null &&
        this.props.clComTableEntity.chargeType !== this.state.chargeTypeSelectValue)
    ) {
      this.setState({ chargeTypeSelectValue: this.props.clComTableEntity.chargeType ? this.props.clComTableEntity.chargeType : null });
    }
    if (
      (prevProps.clComTableEntity && prevProps.clComTableEntity.id !== this.props.clComTableEntity.id) ||
      (this.state.typeSelectValue === null && this.props.clComTableEntity.type !== this.state.typeSelectValue)
    ) {
      this.setState({ typeSelectValue: this.props.clComTableEntity.type ? this.props.clComTableEntity.type : 'TABELAABERTA' });
    }
    if (
      (prevProps.clComTableEntity && prevProps.clComTableEntity.id !== this.props.clComTableEntity.id) ||
      (this.props.clComTableEntity.valuePackage &&
        this.state.valuePackageSelectValue === null &&
        this.props.clComTableEntity.valuePackage !== this.state.valuePackageSelectValue)
    ) {
      this.setState({
        valuePackageSelectValue: this.props.clComTableEntity.valuePackage ? this.props.clComTableEntity.valuePackage : null,
      });
    }
    if (
      (prevProps.clComTableEntity && prevProps.clComTableEntity.id !== this.props.clComTableEntity.id) ||
      (this.props.clComTableEntity.option &&
        this.state.optionSelectValue === null &&
        this.props.clComTableEntity.option !== this.state.optionSelectValue)
    ) {
      this.setState({ optionSelectValue: this.props.clComTableEntity.option ? this.props.clComTableEntity.option : null });
    }
    if (
      (prevProps.clComTableEntity && prevProps.clComTableEntity.id !== this.props.clComTableEntity.id) ||
      (this.props.clComTableEntity.saleValue &&
        this.state.saleValueSelectValue === null &&
        this.props.clComTableEntity.saleValue !== this.state.saleValueSelectValue)
    ) {
      this.setState({ saleValueSelectValue: this.props.clComTableEntity.saleValue ? this.props.clComTableEntity.saleValue : null });
    }
    if (
      (prevProps.clComTableEntity && prevProps.clComTableEntity.id !== this.props.clComTableEntity.id) ||
      (this.props.clComTableEntity.observation &&
        this.state.observationSelectValue === null &&
        this.props.clComTableEntity.observation !== this.state.observationSelectValue)
    ) {
      this.setState({ observationSelectValue: this.props.clComTableEntity.observation ? this.props.clComTableEntity.observation : null });
    }
    if (
      (prevProps.clComTableEntity && prevProps.clComTableEntity.id !== this.props.clComTableEntity.id) ||
      (this.props.clComTableEntity.rentalValue &&
        this.state.rentalValueSelectValue === null &&
        this.props.clComTableEntity.rentalValue !== this.state.rentalValueSelectValue)
    ) {
      this.setState({ rentalValueSelectValue: this.props.clComTableEntity.rentalValue ? this.props.clComTableEntity.rentalValue : null });
    }
    if (
      (prevProps.clComTableEntity && prevProps.clComTableEntity.id !== this.props.clComTableEntity.id) ||
      (this.props.clComTableEntity.tussCode &&
        this.state.tussCodeSelectValue === null &&
        this.props.clComTableEntity.tussCode !== this.state.tussCodeSelectValue)
    ) {
      this.setState({ tussCodeSelectValue: this.props.clComTableEntity.tussCode ? this.props.clComTableEntity.tussCode : null });
    }
    if (
      (prevProps.clComTableEntity && prevProps.clComTableEntity.id !== this.props.clComTableEntity.id) ||
      (this.props.clComTableEntity.tableCode &&
        this.state.tableCodeSelectValue === null &&
        this.props.clComTableEntity.tableCode !== this.state.tableCodeSelectValue)
    ) {
      this.setState({ tableCodeSelectValue: this.props.clComTableEntity.tableCode ? this.props.clComTableEntity.tableCode : null });
    }
    if (
      (prevProps.clComTableEntity && prevProps.clComTableEntity.id !== this.props.clComTableEntity.id) ||
      (this.props.clComTableEntity.expenseCode &&
        this.state.expenseCodeSelectValue === null &&
        this.props.clComTableEntity.expenseCode !== this.state.expenseCodeSelectValue)
    ) {
      this.setState({ expenseCodeSelectValue: this.props.clComTableEntity.expenseCode ? this.props.clComTableEntity.expenseCode : null });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  UNSAFE_componentWillMount() {
    if (this.state.isNew) {
      this.props.reset();
      this.clComRulerReset();
      this.clComItemReset();
    } else {
      this.props.getEntity(this.props.match.params['id']);
    }
  }

  onBlobChange = (isAnImage, name, fileInput) => event => {
    const fileName = event.target.files[0].name;
    setFileData(event, (contentType, data) => this.props.setBlob(name, data, contentType, fileName), isAnImage);
    const varAux = {};
    varAux[name + 'SelectValue'] = fileName;
    this.setState(varAux);
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined);
  };
  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = offset !== null ? '&offset=' + offset : '';
    Object.keys(fieldsBase).map(key => {
      url += key !== 'offset' ? '&' + key + '=' + fieldsBase[key] : '';
    });
    return url;
  };
  saveEntity = (event: any, errors: any, values: any) => {
    const selectFieldsList = ['type', 'name', 'status', 'observation', 'client.fantasyName', 'valuePackage'];

    if (errors.length === 0) {
      const { clComTableEntity } = this.props;
      const _entity = {
        ...clComTableEntity,
        // ...values,

        client: getFormFromSelect(this.state.clientSelectValue, 'many-to-one'), // many-to-one - otherSide
        dose: getFormFromSelect(this.state.doseSelectValue, 'many-to-one'), // many-to-one - otherSide

        clComRuler: undefined,

        clComItem: undefined,

        name: this.state.nameSelectValue,
        status: this.state.statusSelectValue === null ? null : this.state.statusSelectValue,
        chargeType: this.state.chargeTypeSelectValue === null ? 'DESPESA' : this.state.chargeTypeSelectValue,
        type: this.state.typeSelectValue === null ? null : this.state.typeSelectValue,
        valuePackage: this.state.valuePackageSelectValue,
        option: this.state.optionSelectValue === null ? null : this.state.optionSelectValue,
        saleValue: this.state.saleValueSelectValue,
        observation: this.state.observationSelectValue,
        rentalValue: this.state.rentalValueSelectValue,
        tussCode: this.state.tussCodeSelectValue,
        tableCode: this.state.tableCodeSelectValue,
        expenseCode: this.state.expenseCodeSelectValue,
      };

      const entity = _entity;

      const {
        name,
        status,
        type,
        valuePackage,
        option,
        saleValue,
        observation,
        rentalValue,
        clientId,
        clComRulerId,
        clComItemId,
        extraFilters,
        activePage,
        itemsPerPage,
        sort,
        order,
      } = this.state.fieldsBase;

      const clComRulerListAllEmbed = this.props.clComRulerListAllEmbed;
      const clComItemListAllEmbed = this.props.clComItemListAllEmbed;

      if (this.state.isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              name,
              status,
              type,
              valuePackage,
              option,
              saleValue,
              observation,
              rentalValue,
              getFilterFromSelect(clientId, 'many-to-one'),
              getFilterFromSelect(clComRulerId, 'one-to-many'),
              getFilterFromSelect(clComItemId, 'one-to-many'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
          if (idEntity) {
            clComRulerListAllEmbed.map(v => {
              v['id'] = null;
              v['clComTable'] = { id: idEntity };
              this.props['createEntityClComRuler'](v);
            });
          }
          if (idEntity) {
            clComItemListAllEmbed.map(v => {
              v['id'] = null;
              v['clComTable'] = { id: idEntity };
              this.props['createEntityClComItem'](v);
            });
          }
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              name,
              status,
              type,
              valuePackage,
              option,
              saleValue,
              observation,
              rentalValue,
              getFilterFromSelect(clientId, 'many-to-one'),
              getFilterFromSelect(clComRulerId, 'one-to-many'),
              getFilterFromSelect(clComItemId, 'one-to-many'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
    }
  };

  handleClose = () => {
    this.props.history.push(
      '/client/' + this.props.match.params['idClient'] + '/cl-com-table?' + getEntityFiltersURL(this.state.fieldsBase)
    );
  };

  getFullEntityFromState(v) {
    return {
      id: v.id,
      name: this.state.nameSelectValue,
      status: this.state.statusSelectValue,
      chargeType: this.state.chargeTypeSelectValue,
      type: this.state.typeSelectValue,
      valuePackage: this.state.valuePackageSelectValue,
      option: this.state.optionSelectValue,
      saleValue: this.state.saleValueSelectValue,
      observation: this.state.observationSelectValue,
      rentalValue: this.state.rentalValueSelectValue,
      tussCode: this.state.tussCodeSelectValue,
      tableCode: this.state.tableCodeSelectValue,
      expenseCode: this.state.expenseCodeSelectValue,
      quotePackage: this.state.quotePackageSelectValue,
      quote: this.state.quoteSelectValue,
      clComRuler: this.state.clComRulerSelectValue,
      clComItem: this.state.clComItemSelectValue,
      client: this.state.clientSelectValue,
      dose: this.state.doseSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    const quotePackageEntity = v.quotePackage;

    const quoteEntity = v.quote;

    const clComRulerEntity = v.clComRuler;

    const clComItemEntity = v.clComItem;

    const clientEntity = v.client;

    const doseEntity = v.dose;

    return {
      nameSelectValue: v.name,
      statusSelectValue: v.status,
      chargeTypeSelectValue: v.chargeType,
      typeSelectValue: v.type,
      valuePackageSelectValue: v.valuePackage,
      optionSelectValue: v.option,
      saleValueSelectValue: v.saleValue,
      observationSelectValue: v.observation,
      rentalValueSelectValue: v.rentalValue,
      tussCodeSelectValue: v.tussCode,
      tableCodeSelectValue: v.tableCode,
      expenseCodeSelectValue: v.expenseCode,
      quotePackageSelectValue: quotePackageEntity ? quotePackageEntity['id'] : null,
      quoteSelectValue: quoteEntity ? quoteEntity['id'] : null,
      clComRulerSelectValue: clComRulerEntity ? clComRulerEntity['id'] : null,
      clComItemSelectValue: clComItemEntity ? clComItemEntity['id'] : null,
      clientSelectValue: clientEntity ? clientEntity['id'] : null,
      doseSelectValue: doseEntity ? doseEntity['id'] : null,
    };
  }

  renderHeader() {
    const { clComTableEntity, quotePackages, quotes, clComRulers, clComItems, clients, doses, loading, updating } = this.props;
    const { isNew } = this.state;

    const { observation } = clComTableEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.clComTable.home.createLabel">Create a ClComTable</Translate>
            ) : (
              <Translate contentKey="generadorApp.clComTable.home.editLabel">Edit a ClComTable</Translate>
            )}
          </span>

          <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.clComTable.btnSave">Save</Translate>
          </Button>

          {!this.state.isNew ? (
            <>
              <Button
                color="primary"
                id="next-entity"
                disabled={updating}
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.name,
                    this.state.fieldsBase.status,
                    this.state.fieldsBase.type,
                    this.state.fieldsBase.valuePackage,
                    this.state.fieldsBase.option,
                    this.state.fieldsBase.saleValue,
                    this.state.fieldsBase.observation,
                    this.state.fieldsBase.rentalValue,
                    this.state.fieldsBase.clientId,
                    this.state.fieldsBase.clComRulerId,
                    this.state.fieldsBase.clComItemId,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 + 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 + 1 },
                    });
                    this.props.history.push(
                      '/cl-com-table/' + myresult.value.data[0]['id'] + '/edit?' + this.getFiltersURL(this.state.fieldsBase.offset * 1 + 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.last.item', { fieldName: translate('generadorApp.clComTable.detail.title') }));
                  }
                }}
                className="float-right jh-create-entity"
              >
                {'>>'}
              </Button>

              <Button
                color="primary"
                id="prev-entity"
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.name,
                    this.state.fieldsBase.status,
                    this.state.fieldsBase.type,
                    this.state.fieldsBase.valuePackage,
                    this.state.fieldsBase.option,
                    this.state.fieldsBase.saleValue,
                    this.state.fieldsBase.observation,
                    this.state.fieldsBase.rentalValue,
                    this.state.fieldsBase.clientId,
                    this.state.fieldsBase.clComRulerId,
                    this.state.fieldsBase.clComItemId,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 - 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 - 1 },
                    });
                    this.props.history.push(
                      '/cl-com-table/' + myresult.value.data[0]['id'] + '/edit?' + this.getFiltersURL(this.state.fieldsBase.offset * 1 - 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.first.item', { fieldName: translate('generadorApp.clComTable.detail.title') }));
                  }
                }}
                disabled={updating}
                className="float-right jh-create-entity"
              >
                {'<<'}
              </Button>
            </>
          ) : (
            <> </>
          )}

          <Button
            tag={Link}
            id="cancel-save"
            to={'/client/' + this.props.match.params['idClient'] + '/cl-com-table?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.clComTable.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/client'}>
              <Translate contentKey="generadorApp.client.home.title">Clients</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/client/' + this.props.match.params['idClient']}>
              {this.state.clientSelectValue ? this.state.clientSelectValue.label : this.props.match.params['idClient']}
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/client/' + this.props.match.params['idClient'] + '/cl-com-table'}>
              <Translate contentKey="generadorApp.clComTable.home.title">Commercial tables</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.clComTable.detail.title">Commercial table edit</Translate>
          </li>
        </ol>
      </>
    );
  }
  renderBody() {
    const { clComTableEntity, quotePackages, quotes, clComRulers, clComItems, clients, doses, loading, updating } = this.props;
    const { isNew } = this.state;

    const { observation } = clComTableEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const fullEntityEmbebed = this.getFullEntityFromState(clComTableEntity);

    const mapDispatchToPropsClComRulerList: any = {};
    Object.keys(mapDispatchToPropsClComRuler).map(v => {
      mapDispatchToPropsClComRulerList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'ClComRuler')) {
          this.setState({ functionEmbebed: [...this.state.functionEmbebed, v + 'ClComRuler'] }, this.props[v + 'ClComRuler']);
        }
      };
    });
    const mapDispatchToPropsClComItemList: any = {};
    Object.keys(mapDispatchToPropsClComItem).map(v => {
      mapDispatchToPropsClComItemList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'ClComItem')) {
          this.setState({ functionEmbebed: [...this.state.functionEmbebed, v + 'ClComItem'] }, this.props[v + 'ClComItem']);
        }
      };
    });

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="cl-com-table-id">
                  <Translate contentKey="generadorApp.clComTable.field_id">ID</Translate>
                </Label>
                </Col> */}
                      <Col md="12">
                        <AvInput id="cl-com-table-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'name' ? (
                    <Col md="4">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="nameLabel" for="cl-com-table-name">
                              <Translate contentKey="generadorApp.clComTable.name">Name</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                              value={this.state.nameSelectValue ? this.state.nameSelectValue : ''}
                              id="cl-com-table-name"
                              type="text"
                              name="name"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                      type="hidden"
                      name="name"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'status' ? (
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="statusLabel" for="cl-com-table-status">
                              <Translate contentKey="generadorApp.clComTable.status">Status</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="cl-com-table-status"
                              className={'css-select-control'}
                              value={
                                this.state.statusSelectValue
                                  ? {
                                      value: this.state.statusSelectValue,
                                      label: translate('generadorApp.Status.' + this.state.statusSelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.clComTable.status') }
                              }
                              options={[
                                { value: 'INATIVADO', label: translate('generadorApp.Status.INATIVADO') },
                                { value: 'ATIVO', label: translate('generadorApp.Status.ATIVO') },
                              ]}
                              onChange={(options: any) => this.setState({ statusSelectValue: options['value'] })}
                              name="status"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ statusSelectValue: evt.target.value })}
                      type="hidden"
                      name="status"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'type' ? (
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="typeLabel" for="cl-com-table-type">
                              <Translate contentKey="generadorApp.clComTable.type">Type</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="cl-com-table-type"
                              className={'css-select-control'}
                              value={
                                this.state.typeSelectValue
                                  ? {
                                      value: this.state.typeSelectValue,
                                      label: translate('generadorApp.TypeClientCommercialTable.' + this.state.typeSelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.clComTable.type') }
                              }
                              options={[
                                { value: 'TABELAABERTA', label: translate('generadorApp.TypeClientCommercialTable.TABELAABERTA') },
                                { value: 'PACOTE', label: translate('generadorApp.TypeClientCommercialTable.PACOTE') },
                              ]}
                              onChange={(options: any) => this.setState({ typeSelectValue: options['value'] })}
                              name="type"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ typeSelectValue: evt.target.value })}
                      type="hidden"
                      name="type"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {this.state.typeSelectValue === 'PACOTE' && baseFilters !== 'chargeType' ? (
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="chargeTypeLabel" for="cl-com-table-chargeType">
                              <Translate contentKey="generadorApp.clComTable.chargeType">Charge Type</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="cl-com-table-chargeType"
                              className={'css-select-control'}
                              value={
                                this.state.chargeTypeSelectValue
                                  ? {
                                      value: this.state.chargeTypeSelectValue,
                                      label: translate('generadorApp.ChargeType.' + this.state.chargeTypeSelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.clComTable.chargeType') }
                              }
                              options={[
                                { value: 'DESPESA', label: translate('generadorApp.ChargeType.DESPESA') },
                                { value: 'PROCEDIMENTO', label: translate('generadorApp.ChargeType.PROCEDIMENTO') },
                              ]}
                              onChange={(options: any) => this.setState({ chargeTypeSelectValue: options['value'] })}
                              name="chargeType"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ chargeTypeSelectValue: evt.target.value })}
                      type="hidden"
                      name="chargeType"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {this.state.typeSelectValue === 'PACOTE' && baseFilters !== 'valuePackage' ? (
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="valuePackageLabel" for="cl-com-table-valuePackage">
                              <Translate contentKey="generadorApp.clComTable.valuePackage">Value</Translate>
                            </Label>
                          </Col>

                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ valuePackageSelectValue: floatvalue })}
                              decimalSeparator=","
                              precision={2}
                              thousandSeparator=""
                              prefix="R$ "
                              allowNegative={true}
                              value={this.state.valuePackageSelectValue ? this.state.valuePackageSelectValue : 0}
                              id="cl-com-table-valuePackage"
                              name="valuePackage"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ valuePackageSelectValue: evt.target.value })}
                      type="hidden"
                      name="valuePackage"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {this.state.typeSelectValue === 'PACOTE' && baseFilters !== 'tussCode' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="tussCodeLabel" for="cl-com-table-tussCode">
                              <Translate contentKey="generadorApp.clComTable.tussCode">Tuss Code</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ tussCodeSelectValue: evt.target.value })}
                              value={this.state.tussCodeSelectValue ? this.state.tussCodeSelectValue : ''}
                              id="cl-com-table-tussCode"
                              type="text"
                              name="tussCode"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ tussCodeSelectValue: evt.target.value })}
                      type="hidden"
                      name="tussCode"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {this.state.typeSelectValue === 'PACOTE' && baseFilters !== 'tableCode' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="tableCodeLabel" for="cl-com-table-tableCode">
                              <Translate contentKey="generadorApp.clComTable.tableCode">Table Code</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ tableCodeSelectValue: evt.target.value })}
                              value={this.state.tableCodeSelectValue ? this.state.tableCodeSelectValue : ''}
                              id="cl-com-table-tableCode"
                              type="text"
                              name="tableCode"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ tableCodeSelectValue: evt.target.value })}
                      type="hidden"
                      name="tableCode"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {this.state.typeSelectValue === 'PACOTE' && baseFilters !== 'expenseCode' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="expenseCodeLabel" for="cl-com-table-expenseCode">
                              <Translate contentKey="generadorApp.clComTable.expenseCode">Expense Code</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ expenseCodeSelectValue: evt.target.value })}
                              value={this.state.expenseCodeSelectValue ? this.state.expenseCodeSelectValue : ''}
                              id="cl-com-table-expenseCode"
                              type="text"
                              name="expenseCode"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ expenseCodeSelectValue: evt.target.value })}
                      type="hidden"
                      name="expenseCode"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {checkAnyValueFields(this.state, 'typeSelectValue', 'PACOTE', true) && baseFilters !== 'dose' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="cl-com-table-dose">
                              <Translate contentKey="generadorApp.clComTable.dose">Dose</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="cl-com-table-dose"
                              name={'dose'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.doseSelectValue ? this.state.doseSelectValue : ''}
                              onChange={options => this.setState({ doseSelectValue: options })}
                              defaultOptions={this.state.doseStartSelectOptions ? this.state.doseStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.doseStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.doseStartSelectOptions === undefined) {
                                  const result = await getListAxios('doses', {}, 0, 100, 'name,asc', 'name,codeANS');
                                  this.setState({
                                    doseStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name;codeANS') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'doses',
                                  { 'codeANS.contains': inputValue },
                                  0,
                                  100,
                                  'name,asc',
                                  'name,codeANS'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name;codeANS') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="dose"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}
                  {baseFilters !== 'clComRuler' ? (
                    <Col md="12">
                      <div className="entityEmbebedList">
                        <ClComRuler
                          entityEmbebedPai={fullEntityEmbebed}
                          history={this.props.history}
                          location={{
                            pathname: `/cl-com-table/${this.props.match.params['id']}/cl-com-ruler/`,
                            search: '',
                            hash: '',
                            state: undefined,
                            key: 'q2tbhd',
                          }}
                          match={{
                            path: `/cl-com-table/:idClComTable/cl-com-ruler/:id`,
                            url: `/cl-com-table/${this.props.match.params['id']}/cl-com-ruler/`,
                            isExact: true,
                            params: {
                              idClComTable: this.props.match.params['id'],
                              url: `/cl-com-table/${this.props.match.params['id']}/cl-com-ruler/`,
                            },
                          }}
                          {...this.props.mapStateToPropsClComRuler}
                          {...mapDispatchToPropsClComRulerList}
                          getEntityPai={(...v) => this.props.getEntity(...v)}
                          getEntities={this.clComRulerGetEntities}
                          updateEntity={this.clComRulerUpdateEntity}
                          getEntity={this.clComRulerGetEntity}
                          createEntity={this.clComRulerCreateEntity}
                          deleteEntity={this.clComRulerDeleteEntity}
                          reset={this.clComRulerReset}
                          clComRulerList={this.props.clComRulerListEmbed}
                          totalItems={this.props.clComRulerListEmbed ? this.props.clComRulerListEmbed.length : 0}
                          loading={this.props.loading}
                          clComRulerEntity={this.props.clComRulerSelected}
                        />
                      </div>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="clComRuler"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}
                  {baseFilters !== 'clComItem' ? (
                    <Col md="12">
                      <div className="entityEmbebedList">
                        <ClComItem
                          entityEmbebedPai={fullEntityEmbebed}
                          history={this.props.history}
                          location={{
                            pathname: `/cl-com-table/${this.props.match.params['id']}/cl-com-item/`,
                            search: '',
                            hash: '',
                            state: undefined,
                            key: 'q2tbhd',
                          }}
                          match={{
                            path: `/cl-com-table/:idClComTable/cl-com-item/:id`,
                            url: `/cl-com-table/${this.props.match.params['id']}/cl-com-item/`,
                            isExact: true,
                            params: {
                              idClComTable: this.props.match.params['id'],
                              url: `/cl-com-table/${this.props.match.params['id']}/cl-com-item/`,
                            },
                          }}
                          {...this.props.mapStateToPropsClComItem}
                          {...mapDispatchToPropsClComItemList}
                          getEntityPai={(...v) => this.props.getEntity(...v)}
                          getEntities={this.clComItemGetEntities}
                          updateEntity={this.clComItemUpdateEntity}
                          getEntity={this.clComItemGetEntity}
                          createEntity={this.clComItemCreateEntity}
                          deleteEntity={this.clComItemDeleteEntity}
                          reset={this.clComItemReset}
                          clComItemList={this.props.clComItemListEmbed}
                          totalItems={this.props.clComItemListEmbed ? this.props.clComItemListEmbed.length : 0}
                          loading={this.props.loading}
                          clComItemEntity={this.props.clComItemSelected}
                        />
                      </div>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="clComItem"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}{' '}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { clComTableEntity, quotePackages, quotes, clComRulers, clComItems, clients, doses, loading, updating } = this.props;
    const { isNew } = this.state;

    const { observation } = clComTableEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...clComTableEntity,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel className="cl-com-table cl-com-table-update">
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToProps = (storeState: IRootState) => {
  return {
    quotePackages: storeState.quotePackage.entities,
    quotes: storeState.quote.entities,
    clComRulers: storeState.clComRuler.entities,
    clComItems: storeState.clComItem.entities,
    clients: storeState.client.entities,
    doses: storeState.dose.entities,
    clComTableEntity: storeState.clComTable.entity,
    loading: storeState.clComTable.loading,
    updating: storeState.clComTable.updating,
    updateSuccess: storeState.clComTable.updateSuccess,

    clComTables: storeState.clComTable.entities,
    clComRulerListAllEmbed: storeState.clComTable.clComRulerListAllEmbed,
    clComRulerListEmbed: storeState.clComTable.clComRulerListEmbed,
    clComRulerSelected: storeState.clComTable.clComRulerSelected,
    mapStateToPropsClComRuler: mapStateToPropsClComRuler(storeState),

    clComItemListAllEmbed: storeState.clComTable.clComItemListAllEmbed,
    clComItemListEmbed: storeState.clComTable.clComItemListEmbed,
    clComItemSelected: storeState.clComTable.clComItemSelected,
    mapStateToPropsClComItem: mapStateToPropsClComItem(storeState),
  };
};

const mapDispatchToPropsClComRulerList = {};
Object.keys(mapDispatchToPropsClComRuler).map(v => {
  mapDispatchToPropsClComRulerList[v + 'ClComRuler'] = mapDispatchToPropsClComRuler[v];
});
const mapDispatchToPropsClComItemList = {};
Object.keys(mapDispatchToPropsClComItem).map(v => {
  mapDispatchToPropsClComItemList[v + 'ClComItem'] = mapDispatchToPropsClComItem[v];
});

export const mapDispatchToProps = {
  getEntity,
  updateEntity,
  setBlob,
  createEntity,
  getEntityOffset,
  reset,

  ...mapDispatchToPropsClComRulerList,
  clComRulerUpdateEntity,
  clComRulerGetEntities,
  clComRulerSetEntities,
  clComRulerReset,
  clComRulerGetEntity,
  clComRulerCreateEntity,
  clComRulerDeleteEntity,
  ...mapDispatchToPropsClComItemList,
  clComItemUpdateEntity,
  clComItemGetEntities,
  clComItemSetEntities,
  clComItemReset,
  clComItemGetEntity,
  clComItemCreateEntity,
  clComItemDeleteEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ClComTableUpdate);
