import { combineReducers } from 'redux';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';
import rootReducerExtended, { IRootStateExtended } from './index_extended';

import locale, { LocaleState } from './locale';
import authentication, { AuthenticationState } from './authentication';
import sidebar, { SidebarState } from './sidebar';
import applicationProfile, { ApplicationProfileState } from './application-profile';

import administration, { AdministrationState } from 'app/modules/administration/administration.reducer';
import userManagement, { UserManagementState } from 'app/modules/administration/user-management/user-management.reducer';
import register, { RegisterState } from 'app/modules/account/register/register.reducer';
import activate, { ActivateState } from 'app/modules/account/activate/activate.reducer';
import password, { PasswordState } from 'app/modules/account/password/password.reducer';
import settings, { SettingsState } from 'app/modules/account/settings/settings.reducer';
import passwordReset, { PasswordResetState } from 'app/modules/account/password-reset/password-reset.reducer';
import auditEntity, { AuditEntityState } from 'app/entities/audit-entity/audit-entity.reducer';
import jhiReports, { JhiReportsState } from 'app/shared/reports/jhi-reports.reducer';
import whiteLabel, { WhiteLabelState } from 'app/shared/white-label/white-label.reducer';

// prettier-ignore
import company, {
  CompanyState
} from 'app/entities/company/company.reducer';
// prettier-ignore
import brand, {
  BrandState
} from 'app/entities/brand/brand.reducer';
// prettier-ignore
import brasindice, {
  BrasindiceState
} from 'app/entities/brasindice/brasindice.reducer';
// prettier-ignore
import category, {
  CategoryState
} from 'app/entities/category/category.reducer';
// prettier-ignore
import cid, {
  CidState
} from 'app/entities/cid/cid.reducer';
// prettier-ignore
import clComRuler, {
  ClComRulerState
} from 'app/entities/cl-com-ruler/cl-com-ruler.reducer';
// prettier-ignore
import clComItem, {
  ClComItemState
} from 'app/entities/cl-com-item/cl-com-item.reducer';
// prettier-ignore
import billingXml, {
  BillingXmlState
} from 'app/entities/billing-xml/billing-xml.reducer';
// prettier-ignore
import billToPay, {
  BillToPayState
} from 'app/entities/bill-to-pay/bill-to-pay.reducer';
// prettier-ignore
import variableCost, {
  VariableCostState
} from 'app/entities/variable-cost/variable-cost.reducer';
// prettier-ignore
import fixedCost, {
  FixedCostState
} from 'app/entities/fixed-cost/fixed-cost.reducer';
// prettier-ignore
import clComTable, {
  ClComTableState
} from 'app/entities/cl-com-table/cl-com-table.reducer';
// prettier-ignore
import clientContact, {
  ClientContactState
} from 'app/entities/client-contact/client-contact.reducer';
// prettier-ignore
import clientFile, {
  ClientFileState
} from 'app/entities/client-file/client-file.reducer';
// prettier-ignore
import medicalRecordFile, {
  MedicalRecordFileState
} from 'app/entities/medical-record-file/medical-record-file.reducer';
// prettier-ignore
import userFile, {
  UserFileState
} from 'app/entities/user-file/user-file.reducer';
// prettier-ignore
import client, {
  ClientState
} from 'app/entities/client/client.reducer';
// prettier-ignore
import dose, {
  DoseState
} from 'app/entities/dose/dose.reducer';
// prettier-ignore
import specialty, {
  SpecialtyState
} from 'app/entities/specialty/specialty.reducer';
// prettier-ignore
import expectedResult, {
  ExpectedResultState
} from 'app/entities/expected-result/expected-result.reducer';
// prettier-ignore
import levelGroup, {
  LevelGroupState
} from 'app/entities/level-group/level-group.reducer';
// prettier-ignore
import kitDiet, {
  KitDietState
} from 'app/entities/kit-diet/kit-diet.reducer';
// prettier-ignore
import kitMaterial, {
  KitMaterialState
} from 'app/entities/kit-material/kit-material.reducer';
// prettier-ignore
import kitMedicine, {
  KitMedicineState
} from 'app/entities/kit-medicine/kit-medicine.reducer';
// prettier-ignore
import kitProductService, {
  KitProductServiceState
} from 'app/entities/kit-product-service/kit-product-service.reducer';
// prettier-ignore
import kitRhs, {
  KitRhsState
} from 'app/entities/kit-rhs/kit-rhs.reducer';
// prettier-ignore
import kit, {
  KitState
} from 'app/entities/kit/kit.reducer';
// prettier-ignore
import medicineMaterial, {
  MedicineMaterialState
} from 'app/entities/medicine-material/medicine-material.reducer';
// prettier-ignore
import medicalRecord, {
  MedicalRecordState
} from 'app/entities/medical-record/medical-record.reducer';
// prettier-ignore
import nfe, {
  NfeState
} from 'app/entities/nfe/nfe.reducer';
// prettier-ignore
import nfeFile, {
  NfeFileState
} from 'app/entities/nfe-file/nfe-file.reducer';
// prettier-ignore
import notificationUser, {
  NotificationUserState
} from 'app/entities/notification-user/notification-user.reducer';
// prettier-ignore
import notification, {
  NotificationState
} from 'app/entities/notification/notification.reducer';
// prettier-ignore
import nursingDiary, {
  NursingDiaryState
} from 'app/entities/nursing-diary/nursing-diary.reducer';
// prettier-ignore
import subTag, {
  SubTagState
} from 'app/entities/sub-tag/sub-tag.reducer';
// prettier-ignore
import configTag, {
  ConfigTagState
} from 'app/entities/config-tag/config-tag.reducer';
// prettier-ignore
import clinicalIndicator, {
  ClinicalIndicatorState
} from 'app/entities/clinical-indicator/clinical-indicator.reducer';
// prettier-ignore
import patientClinicalIndicator, {
  PatientClinicalIndicatorState
} from 'app/entities/patient-clinical-indicator/patient-clinical-indicator.reducer';
// prettier-ignore
import nursingPrescription, {
  NursingPrescriptionState
} from 'app/entities/nursing-prescription/nursing-prescription.reducer';
// prettier-ignore
import patientContact, {
  PatientContactState
} from 'app/entities/patient-contact/patient-contact.reducer';
// prettier-ignore
import professionalDevice, {
  ProfessionalDeviceState
} from 'app/entities/professional-device/professional-device.reducer';
// prettier-ignore
import patientDevice, {
  PatientDeviceState
} from 'app/entities/patient-device/patient-device.reducer';
// prettier-ignore
import patNrPrescriptionDiagnostic, {
  PatNrPrescriptionDiagnosticState
} from 'app/entities/pat-nr-prescription-diagnostic/pat-nr-prescription-diagnostic.reducer';
// prettier-ignore
import patientDoctorPrescription, {
  PatientDoctorPrescriptionState
} from 'app/entities/patient-doctor-prescription/patient-doctor-prescription.reducer';
// prettier-ignore
import drStatusHistory, {
  DrStatusHistoryState
} from 'app/entities/dr-status-history/dr-status-history.reducer';
// prettier-ignore
import nrStatusHistory, {
  NrStatusHistoryState
} from 'app/entities/nr-status-history/nr-status-history.reducer';
// prettier-ignore
import patDrPrescrProcd, {
  PatDrPrescrProcdState
} from 'app/entities/pat-dr-prescr-procd/pat-dr-prescr-procd.reducer';
// prettier-ignore
import patientFile, {
  PatientFileState
} from 'app/entities/patient-file/patient-file.reducer';
// prettier-ignore
import patientNursingPrescription, {
  PatientNursingPrescriptionState
} from 'app/entities/patient-nursing-prescription/patient-nursing-prescription.reducer';
// prettier-ignore
import patNrPrescProcediment, {
  PatNrPrescProcedimentState
} from 'app/entities/pat-nr-presc-procediment/pat-nr-presc-procediment.reducer';
// prettier-ignore
import patientResponsible, {
  PatientResponsibleState
} from 'app/entities/patient-responsible/patient-responsible.reducer';
// prettier-ignore
import patientStatusHistory, {
  PatientStatusHistoryState
} from 'app/entities/patient-status-history/patient-status-history.reducer';
// prettier-ignore
import clientStatusHistory, {
  ClientStatusHistoryState
} from 'app/entities/client-status-history/client-status-history.reducer';
// prettier-ignore
import levelGroupStatusHistory, {
  LevelGroupStatusHistoryState
} from 'app/entities/level-group-status-history/level-group-status-history.reducer';
// prettier-ignore
import categoryStatusHistory, {
  CategoryStatusHistoryState
} from 'app/entities/category-status-history/category-status-history.reducer';
// prettier-ignore
import specialtyStatusHistory, {
  SpecialtyStatusHistoryState
} from 'app/entities/specialty-status-history/specialty-status-history.reducer';
// prettier-ignore
import productServiceStatusHistory, {
  ProductServiceStatusHistoryState
} from 'app/entities/product-service-status-history/product-service-status-history.reducer';
// prettier-ignore
import kitStatusHistory, {
  KitStatusHistoryState
} from 'app/entities/kit-status-history/kit-status-history.reducer';
// prettier-ignore
import medicineMaterialStatus, {
  MedicineMaterialStatusState
} from 'app/entities/medicine-material-status/medicine-material-status.reducer';
// prettier-ignore
import professionalStatusHistory, {
  ProfessionalStatusHistoryState
} from 'app/entities/professional-status-history/professional-status-history.reducer';
// prettier-ignore
import supplierStatusHistory, {
  SupplierStatusHistoryState
} from 'app/entities/supplier-status-history/supplier-status-history.reducer';
// prettier-ignore
import patient, {
  PatientState
} from 'app/entities/patient/patient.reducer';
// prettier-ignore
import patientCid, {
  PatientCidState
} from 'app/entities/patient-cid/patient-cid.reducer';
// prettier-ignore
import cidStatusHistory, {
  CidStatusHistoryState
} from 'app/entities/cid-status-history/cid-status-history.reducer';
// prettier-ignore
import patientAllergy, {
  PatientAllergyState
} from 'app/entities/patient-allergy/patient-allergy.reducer';
// prettier-ignore
import permission, {
  PermissionState
} from 'app/entities/permission/permission.reducer';
// prettier-ignore
import permissionUser, {
  PermissionUserState
} from 'app/entities/permission-user/permission-user.reducer';
// prettier-ignore
import permissionProfile, {
  PermissionProfileState
} from 'app/entities/permission-profile/permission-profile.reducer';
// prettier-ignore
import profile, {
  ProfileState
} from 'app/entities/profile/profile.reducer';
// prettier-ignore
import pharmacyInventory, {
  PharmacyInventoryState
} from 'app/entities/pharmacy-inventory/pharmacy-inventory.reducer';
// prettier-ignore
import inventoryItem, {
  InventoryItemState
} from 'app/entities/inventory-item/inventory-item.reducer';
// prettier-ignore
import pharmacyStockDevolution, {
  PharmacyStockDevolutionState
} from 'app/entities/pharmacy-stock-devolution/pharmacy-stock-devolution.reducer';
// prettier-ignore
import pharmacyStock, {
  PharmacyStockState
} from 'app/entities/pharmacy-stock/pharmacy-stock.reducer';
// prettier-ignore
import pharmacyStockStatus, {
  PharmacyStockStatusState
} from 'app/entities/pharmacy-stock-status/pharmacy-stock-status.reducer';
// prettier-ignore
import presentation, {
  PresentationState
} from 'app/entities/presentation/presentation.reducer';
// prettier-ignore
import productService, {
  ProductServiceState
} from 'app/entities/product-service/product-service.reducer';
// prettier-ignore
import professionalAdvice, {
  ProfessionalAdviceState
} from 'app/entities/professional-advice/professional-advice.reducer';
// prettier-ignore
import professionalContact, {
  ProfessionalContactState
} from 'app/entities/professional-contact/professional-contact.reducer';
// prettier-ignore
import professionalContractionType, {
  ProfessionalContractionTypeState
} from 'app/entities/professional-contraction-type/professional-contraction-type.reducer';
// prettier-ignore
import professionalFile, {
  ProfessionalFileState
} from 'app/entities/professional-file/professional-file.reducer';
// prettier-ignore
import professionalOccupationArea, {
  ProfessionalOccupationAreaState
} from 'app/entities/professional-occupation-area/professional-occupation-area.reducer';
// prettier-ignore
import professional, {
  ProfessionalState
} from 'app/entities/professional/professional.reducer';
// prettier-ignore
import quoteDiet, {
  QuoteDietState
} from 'app/entities/quote-diet/quote-diet.reducer';
// prettier-ignore
import quoteFile, {
  QuoteFileState
} from 'app/entities/quote-file/quote-file.reducer';
// prettier-ignore
import quoteMaterial, {
  QuoteMaterialState
} from 'app/entities/quote-material/quote-material.reducer';
// prettier-ignore
import quoteMedicine, {
  QuoteMedicineState
} from 'app/entities/quote-medicine/quote-medicine.reducer';
// prettier-ignore
import quoteProductService, {
  QuoteProductServiceState
} from 'app/entities/quote-product-service/quote-product-service.reducer';
// prettier-ignore
import quoteRh, {
  QuoteRhState
} from 'app/entities/quote-rh/quote-rh.reducer';
// prettier-ignore
import quotePackage, {
  QuotePackageState
} from 'app/entities/quote-package/quote-package.reducer';
// prettier-ignore
import quote, {
  QuoteState
} from 'app/entities/quote/quote.reducer';
// prettier-ignore
import segment, {
  SegmentState
} from 'app/entities/segment/segment.reducer';
// prettier-ignore
import simpro, {
  SimproState
} from 'app/entities/simpro/simpro.reducer';
// prettier-ignore
import supplierTable, {
  SupplierTableState
} from 'app/entities/supplier-table/supplier-table.reducer';
// prettier-ignore
import supplierTableDiet, {
  SupplierTableDietState
} from 'app/entities/supplier-table-diet/supplier-table-diet.reducer';
// prettier-ignore
import supplierTableMaterial, {
  SupplierTableMaterialState
} from 'app/entities/supplier-table-material/supplier-table-material.reducer';
// prettier-ignore
import supplierTableMedicine, {
  SupplierTableMedicineState
} from 'app/entities/supplier-table-medicine/supplier-table-medicine.reducer';
// prettier-ignore
import supplierTableProductService, {
  SupplierTableProductServiceState
} from 'app/entities/supplier-table-product-service/supplier-table-product-service.reducer';
// prettier-ignore
import supplierTableRh, {
  SupplierTableRhState
} from 'app/entities/supplier-table-rh/supplier-table-rh.reducer';
// prettier-ignore
import supplierContact, {
  SupplierContactState
} from 'app/entities/supplier-contact/supplier-contact.reducer';
// prettier-ignore
import supplierFile, {
  SupplierFileState
} from 'app/entities/supplier-file/supplier-file.reducer';
// prettier-ignore
import supplier, {
  SupplierState
} from 'app/entities/supplier/supplier.reducer';
// prettier-ignore
import pad, {
  PadState
} from 'app/entities/pad/pad.reducer';
// prettier-ignore
import cidPta, {
  CidPtaState
} from 'app/entities/cid-pta/cid-pta.reducer';
// prettier-ignore
import padCidPta, {
  PadCidPtaState
} from 'app/entities/pad-cid-pta/pad-cid-pta.reducer';
// prettier-ignore
import activity, {
  ActivityState
} from 'app/entities/activity/activity.reducer';
// prettier-ignore
import padAttendance, {
  PadAttendanceState
} from 'app/entities/pad-attendance/pad-attendance.reducer';
// prettier-ignore
import padAttendanceFile, {
  PadAttendanceFileState
} from 'app/entities/pad-attendance-file/pad-attendance-file.reducer';
// prettier-ignore
import push, {
  PushState
} from 'app/entities/push/push.reducer';
// prettier-ignore
import supply, {
  SupplyState
} from 'app/entities/supply/supply.reducer';
// prettier-ignore
import clientGlosa, {
  ClientGlosaState
} from 'app/entities/client-glosa/client-glosa.reducer';
// prettier-ignore
import clientGlosaItem, {
  ClientGlosaItemState
} from 'app/entities/client-glosa-item/client-glosa-item.reducer';
// prettier-ignore
import supplierGlosa, {
  SupplierGlosaState
} from 'app/entities/supplier-glosa/supplier-glosa.reducer';
// prettier-ignore
import supplierGlosaItem, {
  SupplierGlosaItemState
} from 'app/entities/supplier-glosa-item/supplier-glosa-item.reducer';
// prettier-ignore
import patientSupplier, {
  PatientSupplierState
} from 'app/entities/patient-supplier/patient-supplier.reducer';
// prettier-ignore
import pharmacyStockQuote, {
  PharmacyStockQuoteState
} from 'app/entities/pharmacy-stock-quote/pharmacy-stock-quote.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

export interface IRootState extends IRootStateExtended {
  readonly jhiReports: JhiReportsState;
  readonly auditEntity: AuditEntityState;
  readonly authentication: AuthenticationState;
  readonly sidebar: SidebarState;
  readonly locale: LocaleState;
  readonly applicationProfile: ApplicationProfileState;
  readonly administration: AdministrationState;
  readonly userManagement: UserManagementState;
  readonly register: RegisterState;
  readonly activate: ActivateState;
  readonly passwordReset: PasswordResetState;
  readonly password: PasswordState;
  readonly settings: SettingsState;
  readonly whiteLabel: WhiteLabelState;

  readonly company: CompanyState;
  readonly brand: BrandState;
  readonly brasindice: BrasindiceState;
  readonly category: CategoryState;
  readonly cid: CidState;
  readonly clComRuler: ClComRulerState;
  readonly clComItem: ClComItemState;
  readonly billingXml: BillingXmlState;
  readonly billToPay: BillToPayState;
  readonly variableCost: VariableCostState;
  readonly fixedCost: FixedCostState;
  readonly clComTable: ClComTableState;
  readonly clientContact: ClientContactState;
  readonly clientFile: ClientFileState;
  readonly medicalRecordFile: MedicalRecordFileState;
  readonly userFile: UserFileState;
  readonly client: ClientState;
  readonly dose: DoseState;
  readonly specialty: SpecialtyState;
  readonly expectedResult: ExpectedResultState;
  readonly levelGroup: LevelGroupState;
  readonly kitDiet: KitDietState;
  readonly kitMaterial: KitMaterialState;
  readonly kitMedicine: KitMedicineState;
  readonly kitProductService: KitProductServiceState;
  readonly kitRhs: KitRhsState;
  readonly kit: KitState;
  readonly medicineMaterial: MedicineMaterialState;
  readonly medicalRecord: MedicalRecordState;
  readonly nfe: NfeState;
  readonly nfeFile: NfeFileState;
  readonly notificationUser: NotificationUserState;
  readonly notification: NotificationState;
  readonly nursingDiary: NursingDiaryState;
  readonly subTag: SubTagState;
  readonly configTag: ConfigTagState;
  readonly clinicalIndicator: ClinicalIndicatorState;
  readonly patientClinicalIndicator: PatientClinicalIndicatorState;
  readonly nursingPrescription: NursingPrescriptionState;
  readonly patientContact: PatientContactState;
  readonly professionalDevice: ProfessionalDeviceState;
  readonly patientDevice: PatientDeviceState;
  readonly patNrPrescriptionDiagnostic: PatNrPrescriptionDiagnosticState;
  readonly patientDoctorPrescription: PatientDoctorPrescriptionState;
  readonly drStatusHistory: DrStatusHistoryState;
  readonly nrStatusHistory: NrStatusHistoryState;
  readonly patDrPrescrProcd: PatDrPrescrProcdState;
  readonly patientFile: PatientFileState;
  readonly patientNursingPrescription: PatientNursingPrescriptionState;
  readonly patNrPrescProcediment: PatNrPrescProcedimentState;
  readonly patientResponsible: PatientResponsibleState;
  readonly patientStatusHistory: PatientStatusHistoryState;
  readonly clientStatusHistory: ClientStatusHistoryState;
  readonly levelGroupStatusHistory: LevelGroupStatusHistoryState;
  readonly categoryStatusHistory: CategoryStatusHistoryState;
  readonly specialtyStatusHistory: SpecialtyStatusHistoryState;
  readonly productServiceStatusHistory: ProductServiceStatusHistoryState;
  readonly kitStatusHistory: KitStatusHistoryState;
  readonly medicineMaterialStatus: MedicineMaterialStatusState;
  readonly professionalStatusHistory: ProfessionalStatusHistoryState;
  readonly supplierStatusHistory: SupplierStatusHistoryState;
  readonly patient: PatientState;
  readonly patientCid: PatientCidState;
  readonly cidStatusHistory: CidStatusHistoryState;
  readonly patientAllergy: PatientAllergyState;
  readonly permission: PermissionState;
  readonly permissionUser: PermissionUserState;
  readonly permissionProfile: PermissionProfileState;
  readonly profile: ProfileState;
  readonly pharmacyInventory: PharmacyInventoryState;
  readonly inventoryItem: InventoryItemState;
  readonly pharmacyStockDevolution: PharmacyStockDevolutionState;
  readonly pharmacyStock: PharmacyStockState;
  readonly pharmacyStockStatus: PharmacyStockStatusState;
  readonly presentation: PresentationState;
  readonly productService: ProductServiceState;
  readonly professionalAdvice: ProfessionalAdviceState;
  readonly professionalContact: ProfessionalContactState;
  readonly professionalContractionType: ProfessionalContractionTypeState;
  readonly professionalFile: ProfessionalFileState;
  readonly professionalOccupationArea: ProfessionalOccupationAreaState;
  readonly professional: ProfessionalState;
  readonly quoteDiet: QuoteDietState;
  readonly quoteFile: QuoteFileState;
  readonly quoteMaterial: QuoteMaterialState;
  readonly quoteMedicine: QuoteMedicineState;
  readonly quoteProductService: QuoteProductServiceState;
  readonly quoteRh: QuoteRhState;
  readonly quotePackage: QuotePackageState;
  readonly quote: QuoteState;
  readonly segment: SegmentState;
  readonly simpro: SimproState;
  readonly supplierTable: SupplierTableState;
  readonly supplierTableDiet: SupplierTableDietState;
  readonly supplierTableMaterial: SupplierTableMaterialState;
  readonly supplierTableMedicine: SupplierTableMedicineState;
  readonly supplierTableProductService: SupplierTableProductServiceState;
  readonly supplierTableRh: SupplierTableRhState;
  readonly supplierContact: SupplierContactState;
  readonly supplierFile: SupplierFileState;
  readonly supplier: SupplierState;
  readonly pad: PadState;
  readonly cidPta: CidPtaState;
  readonly padCidPta: PadCidPtaState;
  readonly activity: ActivityState;
  readonly padAttendance: PadAttendanceState;
  readonly padAttendanceFile: PadAttendanceFileState;
  readonly push: PushState;
  readonly supply: SupplyState;
  readonly clientGlosa: ClientGlosaState;
  readonly clientGlosaItem: ClientGlosaItemState;
  readonly supplierGlosa: SupplierGlosaState;
  readonly supplierGlosaItem: SupplierGlosaItemState;
  readonly patientSupplier: PatientSupplierState;
  readonly pharmacyStockQuote: PharmacyStockQuoteState;
  /* jhipster-needle-add-reducer-type - JHipster will add reducer type here */
  readonly loadingBar: any;
}

const rootReducer = combineReducers<IRootState>({
  whiteLabel,
  jhiReports,
  auditEntity,
  authentication,
  sidebar,
  locale,
  applicationProfile,
  administration,
  userManagement,
  register,
  activate,
  passwordReset,
  password,
  settings,

  company,
  brand,
  brasindice,
  category,
  cid,
  clComRuler,
  clComItem,
  billingXml,
  billToPay,
  variableCost,
  fixedCost,
  clComTable,
  clientContact,
  clientFile,
  medicalRecordFile,
  userFile,
  client,
  dose,
  specialty,
  expectedResult,
  levelGroup,
  kitDiet,
  kitMaterial,
  kitMedicine,
  kitProductService,
  kitRhs,
  kit,
  medicineMaterial,
  medicalRecord,
  nfe,
  nfeFile,
  notificationUser,
  notification,
  nursingDiary,
  subTag,
  configTag,
  clinicalIndicator,
  patientClinicalIndicator,
  nursingPrescription,
  patientContact,
  professionalDevice,
  patientDevice,
  patNrPrescriptionDiagnostic,
  patientDoctorPrescription,
  drStatusHistory,
  nrStatusHistory,
  patDrPrescrProcd,
  patientFile,
  patientNursingPrescription,
  patNrPrescProcediment,
  patientResponsible,
  patientStatusHistory,
  clientStatusHistory,
  levelGroupStatusHistory,
  categoryStatusHistory,
  specialtyStatusHistory,
  productServiceStatusHistory,
  kitStatusHistory,
  medicineMaterialStatus,
  professionalStatusHistory,
  supplierStatusHistory,
  patient,
  patientCid,
  cidStatusHistory,
  patientAllergy,
  permission,
  permissionUser,
  permissionProfile,
  profile,
  pharmacyInventory,
  inventoryItem,
  pharmacyStockDevolution,
  pharmacyStock,
  pharmacyStockStatus,
  presentation,
  productService,
  professionalAdvice,
  professionalContact,
  professionalContractionType,
  professionalFile,
  professionalOccupationArea,
  professional,
  quoteDiet,
  quoteFile,
  quoteMaterial,
  quoteMedicine,
  quoteProductService,
  quoteRh,
  quotePackage,
  quote,
  segment,
  simpro,
  supplierTable,
  supplierTableDiet,
  supplierTableMaterial,
  supplierTableMedicine,
  supplierTableProductService,
  supplierTableRh,
  supplierContact,
  supplierFile,
  supplier,
  pad,
  cidPta,
  padCidPta,
  activity,
  padAttendance,
  padAttendanceFile,
  push,
  supply,
  clientGlosa,
  clientGlosaItem,
  supplierGlosa,
  supplierGlosaItem,
  patientSupplier,
  pharmacyStockQuote,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  loadingBar,
  ...rootReducerExtended,
});

export default rootReducer;
