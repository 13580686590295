import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';
import moment from 'moment';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import {
  getEntity,
  ICategoryStatusHistoryBaseState,
  getUrlBack,
  getCategoryStatusHistoryState,
  getEntityFiltersURL,
} from './category-status-history.reducer';

import { APP_DATE_FORMAT } from 'app/config/constants';

export interface ICategoryStatusHistoryState {
  fieldsBase: ICategoryStatusHistoryBaseState;
}

export interface ICategoryStatusHistoryDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class CategoryStatusHistoryDetail extends React.Component<ICategoryStatusHistoryDetailProps, ICategoryStatusHistoryState> {
  constructor(props: Readonly<ICategoryStatusHistoryDetailProps>) {
    super(props);
    this.state = {
      ...this.state,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getCategoryStatusHistoryState(this.props.location),
        urlBack: getUrlBack(this.props.location),
        categoryId: [{ value: this.props.match.params['idCategory'], label: this.props.match.params['idCategory'] }],
        baseFilters: 'category',
      },
    };
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = '_back=1' + (offset !== null ? '&offset=' + offset : '');
    Object.keys(fieldsBase).map(key => {
      url += '&' + key + '=' + fieldsBase[key];
    });
    return url;
  };

  UNSAFE_componentWillMount() {
    this.props.getEntity(this.props.match.params['id']);
  }

  renderHeader() {
    const { categoryStatusHistoryEntity } = this.props;
    const state = this.state;
    return (
      <>
        <div id="page-heading">
          {' '}
          <h2>
            <Translate contentKey="generadorApp.categoryStatusHistory.detail.title">CategoryStatusHistory</Translate>
            <small>&nbsp; {categoryStatusHistoryEntity['status']}</small>
          </h2>
        </div>

        <div className="pull-right">
          <Button
            tag={Link}
            to={
              '/category/' +
              this.props.match.params['idCategory'] +
              '/category-status-history?' +
              getEntityFiltersURL(this.state.fieldsBase)
            }
            replace
            color="info"
          >
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.categoryStatusHistory.btnBack">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/category/${this.props.match.params['idCategory']}/category-status-history/${
              categoryStatusHistoryEntity.id
            }/edit?+${getEntityFiltersURL(this.state.fieldsBase)}`}
            replace
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.categoryStatusHistory.btnEdit">Edit</Translate>
            </span>
          </Button>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>

          <li className="breadcrumb-item">
            <Link to={'/category'}>Categorys</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/category/' + this.props.match.params['idCategory']}>{this.props.match.params['idCategory']}</Link>
          </li>
          <li className="breadcrumb-item">
            <Link
              to={
                '/category/' + (this.state.fieldsBase.categoryId ? this.state.fieldsBase.categoryId.label : '') + '/category-status-history'
              }
            >
              Category status Histories
            </Link>
          </li>
          <li className="breadcrumb-item active">Category status Histories details</li>
        </ol>
      </>
    );
  }

  renderBody() {
    const { categoryStatusHistoryEntity } = this.props;
    const state = this.state;
    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            <Row className="jh-entity-details">
              <Col className="view-detail-row category-status-history-status-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top category-status-history-status-detail-label" md="12">
                    <dt>
                      <span id="status">
                        <Translate contentKey="generadorApp.categoryStatusHistory.status">Status</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top category-status-history-status-detail-value" md="12">
                    <dd>
                      {translate(
                        'generadorApp.Status.' + (categoryStatusHistoryEntity.status ? categoryStatusHistoryEntity.status : 'INATIVADO')
                      )}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row category-status-history-observation-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top category-status-history-observation-detail-label" md="12">
                    <dt>
                      <span id="observation">
                        <Translate contentKey="generadorApp.categoryStatusHistory.observation">Observation</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top category-status-history-observation-detail-value" md="12">
                    <dd>{categoryStatusHistoryEntity.observation}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row category-status-history-createdBy-detail" md="12">
                <Row>
                  <Col className="view-detail-label-left category-status-history-createdBy-detail-label" md="3">
                    <dt>
                      <Translate contentKey="generadorApp.categoryStatusHistory.createdBy">Created By</Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left category-status-history-createdBy-detail-value" md="9">
                    <dd>{categoryStatusHistoryEntity.createdBy ? categoryStatusHistoryEntity.createdBy.id : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row category-status-history-createdDate-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top category-status-history-createdDate-detail-label" md="12">
                    <dt>
                      <span id="createdDate">
                        <Translate contentKey="generadorApp.categoryStatusHistory.createdDate">Created Date</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top category-status-history-createdDate-detail-value" md="12">
                    <dd>
                      {categoryStatusHistoryEntity.createdDate
                        ? moment(categoryStatusHistoryEntity.createdDate).format(APP_DATE_FORMAT)
                        : ''}
                    </dd>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { categoryStatusHistoryEntity } = this.props;
    const state = this.state;
    return (
      <div>
        <Panel className="category-status-history category-status-history-detail">
          <PanelHeader>{this.renderHeader()}</PanelHeader>

          <PanelBody>{this.renderBody()}</PanelBody>
        </Panel>
      </div>
    );
  }
}

export const mapStateToProps = ({ categoryStatusHistory, ...storeState }: IRootState) => ({
  categoryStatusHistoryEntity: categoryStatusHistory.entity,
});

export const mapDispatchToProps = {
  getEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CategoryStatusHistoryDetail);
