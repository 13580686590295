import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import PermissionUser from './extended/permission-user';

import PermissionUserDetail from './permission-user-detail';

import PermissionUserUpdate from './permission-user-update';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={PermissionUserUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={PermissionUserUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={PermissionUserDetail} />
      <ErrorBoundaryRoute path={match.path} component={PermissionUser} />
    </Switch>
  </>
);

export default Routes;
