import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { IPatient } from 'app/shared/model/patient.model';
import { IClientContact } from 'app/shared/model/client-contact.model';
import { IClientStatusHistory } from 'app/shared/model/client-status-history.model';
import { IBillingXml } from 'app/shared/model/billing-xml.model';
import { IClientFile } from 'app/shared/model/client-file.model';
import { IClComTable } from 'app/shared/model/cl-com-table.model';
import { IPush } from 'app/shared/model/push.model';
import { ISegment } from 'app/shared/model/segment.model';
import { ExtraStatus } from 'app/shared/model/enumerations/extra-status.model';
import { XmlBillingTypes } from 'app/shared/model/enumerations/xml-billing-types.model';

export interface IClient {
  id?: number;
  socialReason?: string;
  fantasyName?: string;
  cnpj?: string;
  ie?: string;
  status?: ExtraStatus;
  zipCode?: string;
  street?: string;
  complement?: string;
  number?: string;
  neighborhood?: string;
  city?: string;
  uf?: string;
  codAns?: string;
  codCnes?: string;
  codEmpNoProvedor?: string;
  contractSignatureDate?: Moment;
  expectedEmissionNfDay?: number;
  expectedBillingDay?: number;
  expectedReceiptDate?: number;
  billingRules?: string;
  observations?: any;
  xmlBillingTypes?: XmlBillingTypes;
  xmlSimpleExport?: boolean;
  reference?: string;
  patient?: IPatient[];
  clientContact?: IClientContact[];
  clientStatusHistory?: IClientStatusHistory[];
  billingXml?: IBillingXml[];
  clientFile?: IClientFile[];
  clComTable?: IClComTable[];
  push?: IPush[];
  segment?: ISegment;
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  patient: {
    isMulti: false, // one-to-many - other-side
    entity: 'Patient',
    urlParameters: {
      enpoint: 'patients',
      filters: 'client.id.equals',
    },
  },
  clientContact: {
    isMulti: false, // one-to-many - other-side
    entity: 'ClientContact',
    urlParameters: {
      enpoint: 'client-contacts',
      filters: 'client.id.equals',
    },
  },
  clientStatusHistory: {
    isMulti: false, // one-to-many - other-side
    entity: 'ClientStatusHistory',
    urlParameters: {
      enpoint: 'client-status-histories',
      filters: 'client.id.equals',
    },
  },
  billingXml: {
    isMulti: false, // one-to-many - other-side
    entity: 'BillingXml',
    urlParameters: {
      enpoint: 'billing-xmls',
      filters: 'client.id.equals',
    },
  },
  clientFile: {
    isMulti: false, // one-to-many - other-side
    entity: 'ClientFile',
    urlParameters: {
      enpoint: 'client-files',
      filters: 'client.id.equals',
    },
  },
  clComTable: {
    isMulti: false, // one-to-many - other-side
    entity: 'ClComTable',
    urlParameters: {
      enpoint: 'cl-com-tables',
      filters: 'client.id.equals',
    },
  },
  push: {
    isMulti: false, // one-to-many - other-side
    entity: 'Push',
    urlParameters: {
      enpoint: 'pushes',
      filters: 'client.id.equals',
    },
  },
  segment: {
    isMulti: true, // many-to-one - other-side
    entity: 'Segment',
    urlParameters: {
      enpoint: 'segments',
      filters: 'client.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'client.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'client.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<IClient> = {
  xmlSimpleExport: false,
};
