/* eslint-disable no-console */
/* eslint complexity: ["error", 300] */
import React from 'react';

import { connect } from 'react-redux';
import ShowMoreText from 'react-show-more-text';
import { RouteComponentProps } from 'react-router-dom';

import { Table, Button } from 'reactstrap';
import { Translate, getSortState, IPaginationBaseState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';
import {
  updateEntity,
  getAuditEntityState,
  IAuditEntityBaseState,
  getAuditEntityFiltersURL,
  getEntities,
} from 'app/entities/audit-entity/audit-entity.reducer';

import moment from 'moment';
import { translate } from 'app/config/translate-component';
import { jsonParse } from 'app/shared/util/entity-utils';

export interface IAuditEntityProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export interface IAuditEntityState extends IAuditEntityBaseState, IPaginationBaseState {}

export class AuditEntity extends React.Component<IAuditEntityProps, IAuditEntityState> {
  private myFormRef: any;

  constructor(props: IAuditEntityProps) {
    super(props);
    this.state = {
      ...getSortState(this.props.location, 100),
      ...getAuditEntityState(this.props.location),
    };
  }

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  cancelFilters = () => {
    this.setState(
      {
        entityId: '',

        entityType: '',

        action: '',

        entityValue: '',

        commitVersion: '',
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getAuditEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    const { entityType, action, entityValue, commitVersion, activePage, itemsPerPage, sort, order } = this.state;
    this.props.getEntities(this.props.match.params['id'], 'Category', action, entityValue, commitVersion, 'contract', 0, 10000, `id,desc`);
  };

  updateEntity = entity => {
    const { entityId, entityType, action, entityValue, commitVersion, activePage, itemsPerPage, sort, order } = this.state;
    this.props.updateEntity(entity, [
      this.props.match.params['id'],
      'Category',
      action,
      entityValue,
      commitVersion,
      'contract',
      activePage - 1,
      10000,
      `id,desc`,
    ]);
  };

  renderHeader() {
    const { auditEntityList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.category.audit_entity">Create a Category</Translate>
          </span>
        </div>
      </>
    );
  }

  handleClose = event => {
    event.stopPropagation();
    this.props.history.goBack();
  };

  renderTable() {
    const { auditEntityList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {auditEntityList && auditEntityList.length > 0 ? (
          <Table
            id="audit-entity-table-list"
            responsive
            aria-describedby="audit-entity-heading"
            className={'table-hover table-striped mt-4 table-responsive-css'}
          >
            <thead className={'thead-light'}>
              <tr>
                <th>
                  <Translate contentKey="generadorApp.auditEntity.commitVersion">Commit Version</Translate>
                </th>
                <th>
                  <Translate contentKey="generadorApp.auditEntity.createdDate">createDate</Translate>
                </th>
                <th>
                  <Translate contentKey="generadorApp.auditEntity.createdBy">Entity Type</Translate>
                </th>
                <th style={{ width: '60%' }}>
                  <Translate contentKey="generadorApp.category.contract">Entity Value</Translate>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr></tr>
              {auditEntityList.map((auditEntity, i) => {
                let entityValue = {};
                if (auditEntity.entityValue) {
                  try {
                    entityValue = jsonParse(auditEntity.entityValue);
                  } catch (error) {
                    entityValue = {};
                  }
                }
                return (
                  <tr key={`entity-${i}`}>
                    <td id="commitVersion-cell">{auditEntity.commitVersion}</td>
                    <td id="entityId-cell">{moment(auditEntity.createdDate).format('DD/MM/YYYY HH:mm:ss')}</td>
                    <td id="entityType-cell">{auditEntity.createdBy.login}</td>
                    <td id="entityValue-cell">
                      {entityValue && entityValue['contract'] ? (
                        <ShowMoreText
                          lines={3}
                          more={translate('generadorApp.base.show_more')}
                          less={translate('generadorApp.base.show_less')}
                          expanded={false}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: Buffer.from(entityValue['contract']).toString(),
                            }}
                          />
                        </ShowMoreText>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.auditEntity.home.notFound">No Audit Entities found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  render() {
    const { auditEntityList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Modal size={'xl'} isOpen={true} toggle={this.handleClose}>
        <ModalHeader toggle={this.handleClose}>{this.renderHeader()}</ModalHeader>

        <ModalBody>
          <div className="table-responsive">{this.renderTable()}</div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.handleClose}>
            <FontAwesomeIcon icon="ban" />
            &nbsp;
            <Translate contentKey="generadorApp.kit.btnCancel">Cancel</Translate>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export const mapStateToProps = ({ auditEntity, ...storeState }: IRootState) => ({
  auditEntityList: auditEntity.entities,
  totalItems: auditEntity.totalItems,
  loading: auditEntity.loading,
});

export const mapDispatchToProps = {
  getEntities,

  updateEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AuditEntity);
