import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { IPatientClinicalIndicator } from 'app/shared/model/patient-clinical-indicator.model';
import { IMedicalRecord } from 'app/shared/model/medical-record.model';
import { MeasurementUnit } from 'app/shared/model/enumerations/measurement-unit.model';

export interface IClinicalIndicator {
  id?: number;
  name?: string;
  unity?: MeasurementUnit;
  patientClinicalIndicator?: IPatientClinicalIndicator[];
  medicalRecords?: IMedicalRecord[];
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  patientClinicalIndicator: {
    isMulti: false, // one-to-many - other-side
    entity: 'PatientClinicalIndicator',
    urlParameters: {
      enpoint: 'patient-clinical-indicators',
      filters: 'clinicalIndicator.id.equals',
    },
  },
  medicalRecord: {
    isMulti: true, // many-to-many - other-side
    entity: 'MedicalRecord',
    urlParameters: {
      enpoint: 'medical-records',
      filters: 'clinicalIndicators.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'clinicalIndicator.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'clinicalIndicator.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<IClinicalIndicator> = {};
