import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import { Translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import {
  getEntity,
  IPatientNursingPrescriptionBaseState,
  getUrlBack,
  getPatientNursingPrescriptionState,
  getEntityFiltersURL,
} from './patient-nursing-prescription.reducer';

import { PatNrPrescProcedimentDetail } from '../pat-nr-presc-procediment/pat-nr-presc-procediment-detail';
import {
  getEntity as getEntityPatNrPrescProcediment,
  convertEntityBuffers as convertEntityBuffersPatNrPrescProcediment,
} from '../pat-nr-presc-procediment/pat-nr-presc-procediment.reducer';

export interface IPatientNursingPrescriptionState {
  fieldsBase: IPatientNursingPrescriptionBaseState;
}

export interface IPatientNursingPrescriptionDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class PatientNursingPrescriptionDetail extends React.Component<
  IPatientNursingPrescriptionDetailProps,
  IPatientNursingPrescriptionState
> {
  constructor(props: Readonly<IPatientNursingPrescriptionDetailProps>) {
    super(props);
    this.state = {
      ...this.state,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getPatientNursingPrescriptionState(this.props.location),
        urlBack: getUrlBack(this.props.location),
        patientId: [{ value: this.props.match.params['idPatient'], label: this.props.match.params['idPatient'] }],
        baseFilters: 'patient',
      },
    };
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = '_back=1' + (offset !== null ? '&offset=' + offset : '');
    Object.keys(fieldsBase).map(key => {
      url += '&' + key + '=' + fieldsBase[key];
    });
    return url;
  };

  UNSAFE_componentWillMount() {
    this.props.getEntity(this.props.match.params['id']);
  }

  renderHeader() {
    const { patientNursingPrescriptionEntity } = this.props;
    const state = this.state;
    return (
      <>
        <div id="page-heading">
          {' '}
          <h2>
            <Translate contentKey="generadorApp.patientNursingPrescription.detail.title">PatientNursingPrescription</Translate>
            <b>{patientNursingPrescriptionEntity.id}</b>
          </h2>
        </div>

        <div className="pull-right">
          <Button
            tag={Link}
            to={
              '/patient/' +
              this.props.match.params['idPatient'] +
              '/patient-nursing-prescription?' +
              getEntityFiltersURL(this.state.fieldsBase)
            }
            replace
            color="info"
          >
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.patientNursingPrescription.btnBack">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/patient/${this.props.match.params['idPatient']}/patient-nursing-prescription/${
              patientNursingPrescriptionEntity.id
            }/edit?+${getEntityFiltersURL(this.state.fieldsBase)}`}
            replace
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.patientNursingPrescription.btnEdit">Edit</Translate>
            </span>
          </Button>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>

          <li className="breadcrumb-item">
            <Link to={'/patient'}>Patients</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/patient/' + this.props.match.params['idPatient']}>{this.props.match.params['idPatient']}</Link>
          </li>
          <li className="breadcrumb-item">
            <Link
              to={
                '/patient/' +
                (this.state.fieldsBase.patientId ? this.state.fieldsBase.patientId.label : '') +
                '/patient-nursing-prescription'
              }
            >
              Nurse Prescriptions
            </Link>
          </li>
          <li className="breadcrumb-item active">Nurse Prescriptions details</li>
        </ol>
      </>
    );
  }

  renderBody() {
    const { patientNursingPrescriptionEntity } = this.props;
    const state = this.state;
    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            <Row className="jh-entity-details">
              <Col className="view-detail-row patient-nursing-prescription-observations-detail" md="6">
                <Row>
                  <Col className="view-detail-label-top patient-nursing-prescription-observations-detail-label" md="12">
                    <dt>
                      <span id="observations">
                        <Translate contentKey="generadorApp.patientNursingPrescription.observations">Observations</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top patient-nursing-prescription-observations-detail-value" md="12">
                    <dd>{patientNursingPrescriptionEntity.observations}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row patient-nursing-prescription-technician-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top patient-nursing-prescription-technician-detail-label" md="12">
                    <dt>
                      <Translate contentKey="generadorApp.patientNursingPrescription.technician">Technician</Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top patient-nursing-prescription-technician-detail-value" md="12">
                    <dd>{patientNursingPrescriptionEntity.technician ? patientNursingPrescriptionEntity.technician.id : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row patient-nursing-prescription-nrDiagnostic-detail" md="6">
                <Row>
                  <Col className="view-detail-label-top patient-nursing-prescription-nrDiagnostic-detail-label" md="12">
                    <dt>
                      <Translate contentKey="generadorApp.patientNursingPrescription.nrDiagnostic">Nr Diagnostic</Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top patient-nursing-prescription-nrDiagnostic-detail-value" md="12">
                    <dd>
                      {patientNursingPrescriptionEntity.nrDiagnostics
                        ? patientNursingPrescriptionEntity.nrDiagnostics.map((val, i) => (
                            <span key={val.id}>
                              <a>{val.name}</a>
                              {i === patientNursingPrescriptionEntity.nrDiagnostics.length - 1 ? '' : ', '}
                            </span>
                          ))
                        : null}
                    </dd>
                  </Col>
                </Row>
              </Col>

              {patientNursingPrescriptionEntity.patNrPrescProcediment ? (
                patientNursingPrescriptionEntity.patNrPrescProcediment.map((v, i) => (
                  <div key={i} className="entityEmbebed">
                    <PatNrPrescProcedimentDetail
                      history={this.props.history}
                      location={{
                        pathname: `/patient-nursing-prescription/${this.props.match.params['id']}/pat-nr-presc-procediment/${v.id}`,
                        search: '',
                        hash: '',
                        state: undefined,
                        key: 'q2tbhd',
                      }}
                      match={{
                        path: `/patient-nursing-prescription/:idPatientNursingPrescription/pat-nr-presc-procediment/:id`,
                        url: `/patient-nursing-prescription/${this.props.match.params['id']}/pat-nr-presc-procediment/${v.id}`,
                        isExact: true,
                        params: { id: '' + v.id },
                      }}
                      patNrPrescProcedimentEntity={convertEntityBuffersPatNrPrescProcediment(v)}
                      getEntity={(): any => {}}
                    />
                  </div>
                ))
              ) : (
                <> </>
              )}
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { patientNursingPrescriptionEntity } = this.props;
    const state = this.state;
    return (
      <div>
        <Panel className="patient-nursing-prescription patient-nursing-prescription-detail">
          <PanelHeader>{this.renderHeader()}</PanelHeader>

          <PanelBody>{this.renderBody()}</PanelBody>
        </Panel>
      </div>
    );
  }
}

export const mapStateToProps = ({ patientNursingPrescription, ...storeState }: IRootState) => ({
  patientNursingPrescriptionEntity: patientNursingPrescription.entity,
  patNrPrescProcedimentEntity: storeState.patNrPrescProcediment.entity,
});

export const mapDispatchToProps = {
  getEntity,
  getEntityPatNrPrescProcediment,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PatientNursingPrescriptionDetail);
