import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { IMedicineMaterial } from 'app/shared/model/medicine-material.model';
import { IClComTable } from 'app/shared/model/cl-com-table.model';
import { ISpecialty } from 'app/shared/model/specialty.model';
import { IProductService } from 'app/shared/model/product-service.model';
import { ILevelGroup } from 'app/shared/model/level-group.model';

export interface IDose {
  id?: number;
  name?: string;
  codeANS?: string;
  description?: string;
  caseDecimal?: number;
  medicineMaterial?: IMedicineMaterial[];
  clComTable?: IClComTable[];
  specialty?: ISpecialty[];
  productService?: IProductService[];
  levelGroup?: ILevelGroup;
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  medicineMaterial: {
    isMulti: false, // one-to-many - other-side
    entity: 'MedicineMaterial',
    urlParameters: {
      enpoint: 'medicine-materials',
      filters: 'dose.id.equals',
    },
  },
  clComTable: {
    isMulti: false, // one-to-many - other-side
    entity: 'ClComTable',
    urlParameters: {
      enpoint: 'cl-com-tables',
      filters: 'dose.id.equals',
    },
  },
  specialty: {
    isMulti: false, // one-to-many - other-side
    entity: 'Specialty',
    urlParameters: {
      enpoint: 'specialties',
      filters: 'dose.id.equals',
    },
  },
  productService: {
    isMulti: false, // one-to-many - other-side
    entity: 'ProductService',
    urlParameters: {
      enpoint: 'product-services',
      filters: 'dose.id.equals',
    },
  },
  levelGroup: {
    isMulti: true, // many-to-one - other-side
    entity: 'LevelGroup',
    urlParameters: {
      enpoint: 'level-groups',
      filters: 'dose.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'dose.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'dose.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<IDose> = {};
