/* eslint complexity: ["error", 300] */
import { Translate } from 'app/config/translate-component';
import React from 'react';
import { connect } from 'react-redux';
import { Badge, UncontrolledTooltip } from 'reactstrap';

export interface IPatientProps extends StateProps, DispatchProps {
  baseState: any;
  entityBase?: any;
  setState?: any;
  baseProps?: any;
}

const patientComponentCustomize = (props: IPatientProps) => {
  return !props.entityBase.name ||
    !props.entityBase.cpf ||
    !props.entityBase.sex ||
    !props.entityBase.birthDate ||
    !props.entityBase.zipCode ||
    !props.entityBase.street ||
    !props.entityBase.number ||
    !props.entityBase.neighborhood ||
    !props.entityBase.city ||
    !props.entityBase.uf ||
    !props.entityBase.client ||
    !props.entityBase.healthPlanEnrollment ||
    !props.entityBase.patientComplexity ||
    !props.entityBase.liminar ||
    !props.entityBase.adId ||
    !(props.entityBase.patientContact && props.entityBase.patientContact.filter(cont => cont.telephonePrimary).length > 0) ||
    !(
      props.entityBase.patientResponsible &&
      props.entityBase.patientResponsible.filter(resp => resp.name && resp.contactTelephonePrincipal && resp.email).length > 0
    ) ||
    !(props.entityBase.patientDevices && props.entityBase.patientDevices.length > 0) ||
    !(props.entityBase.patientCid && props.entityBase.patientCid.length > 0) ? (
    <div style={{ padding: 0, margin: 0, textAlign: 'center' }} className="alert alert-danger">
      Incompleto{' '}
      <Badge className="pull-right" style={{ cursor: 'pointer' }} color="warning" id={'patient-incomplete-' + props.entityBase.id}>
        !
      </Badge>
      <UncontrolledTooltip placement="right" target={'patient-incomplete-' + props.entityBase.id}>
        <div style={{ textAlign: 'left' }}>
          {!props.entityBase.name ? (
            <div>
              <Translate contentKey="generadorApp.patient.name">name</Translate>
            </div>
          ) : (
            ''
          )}
          {!props.entityBase.cpf ? (
            <div>
              <Translate contentKey="generadorApp.patient.cpf">cpf</Translate>
            </div>
          ) : (
            ''
          )}
          {!props.entityBase.sex ? (
            <div>
              <Translate contentKey="generadorApp.patient.sex">sex</Translate>
            </div>
          ) : (
            ''
          )}
          {!props.entityBase.birthDate ? (
            <div>
              <Translate contentKey="generadorApp.patient.birthDate">birthDate</Translate>
            </div>
          ) : (
            ''
          )}
          {!props.entityBase.zipCode ? (
            <div>
              <Translate contentKey="generadorApp.patient.zipCode">zipCode</Translate>
            </div>
          ) : (
            ''
          )}
          {!props.entityBase.street ? (
            <div>
              <Translate contentKey="generadorApp.patient.street">street</Translate>
            </div>
          ) : (
            ''
          )}
          {!props.entityBase.number ? (
            <div>
              <Translate contentKey="generadorApp.patient.number">number</Translate>
            </div>
          ) : (
            ''
          )}
          {!props.entityBase.neighborhood ? (
            <div>
              <Translate contentKey="generadorApp.patient.neighborhood">neighborhood</Translate>
            </div>
          ) : (
            ''
          )}
          {!props.entityBase.city ? (
            <div>
              <Translate contentKey="generadorApp.patient.city">city</Translate>
            </div>
          ) : (
            ''
          )}
          {!props.entityBase.uf ? (
            <div>
              <Translate contentKey="generadorApp.patient.uf">uf</Translate>
            </div>
          ) : (
            ''
          )}
          {!props.entityBase.client ? (
            <div>
              <Translate contentKey="generadorApp.patient.client">client</Translate>
            </div>
          ) : (
            ''
          )}
          {!props.entityBase.healthPlanEnrollment ? (
            <div>
              <Translate contentKey="generadorApp.patient.healthPlanEnrollment">healthPlanEnrollment</Translate>
            </div>
          ) : (
            ''
          )}
          {!props.entityBase.patientComplexity ? (
            <div>
              <Translate contentKey="generadorApp.patient.patientComplexity">patientComplexity</Translate>
            </div>
          ) : (
            ''
          )}
          {!props.entityBase.liminar ? (
            <div>
              <Translate contentKey="generadorApp.patient.liminar">liminar</Translate>
            </div>
          ) : (
            ''
          )}
          {!props.entityBase.adId ? (
            <div>
              <Translate contentKey="generadorApp.patient.adId">AD/ID</Translate>
            </div>
          ) : (
            ''
          )}
          {!(props.entityBase.patientContact && props.entityBase.patientContact.filter(cont => cont.telephonePrimary).length > 0) ? (
            <div>
              <Translate contentKey="generadorApp.patientContact.home.title">patientContact</Translate>
            </div>
          ) : (
            ''
          )}
          {!(
            props.entityBase.patientResponsible &&
            props.entityBase.patientResponsible.filter(resp => resp.name && resp.contactTelephonePrincipal && resp.email).length > 0
          ) ? (
            <div>
              <Translate contentKey="generadorApp.patientResponsible.home.title">patientResponsible</Translate>
            </div>
          ) : (
            ''
          )}
          {!(props.entityBase.patientDevices && props.entityBase.patientDevices.length > 0) ? (
            <div>
              <Translate contentKey="generadorApp.patientDevice.home.title">patientDevices</Translate>
            </div>
          ) : (
            ''
          )}
          {!(props.entityBase.patientCid && props.entityBase.patientCid.length > 0) ? (
            <div>
              <Translate contentKey="generadorApp.patientCid.home.title">patientCid</Translate>
            </div>
          ) : (
            ''
          )}
        </div>
      </UncontrolledTooltip>
    </div>
  ) : (
    <div style={{ padding: 0, margin: 0, textAlign: 'center' }} className="alert alert-success">
      Completo
    </div>
  );
};

const mapStateToProps = storeState => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(patientComponentCustomize);
