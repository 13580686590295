import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import { Translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity, IActivityBaseState, getUrlBack, getActivityState, getEntityFiltersURL } from './activity.reducer';

export interface IActivityState {
  fieldsBase: IActivityBaseState;
}

export interface IActivityDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class ActivityDetail extends React.Component<IActivityDetailProps, IActivityState> {
  constructor(props: Readonly<IActivityDetailProps>) {
    super(props);
    this.state = {
      ...this.state,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getActivityState(this.props.location),
        urlBack: getUrlBack(this.props.location),
        cidPtaId: [{ value: this.props.match.params['idCidPta'], label: this.props.match.params['idCidPta'] }],
        baseFilters: 'cidPta',
      },
    };
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = '_back=1' + (offset !== null ? '&offset=' + offset : '');
    Object.keys(fieldsBase).map(key => {
      url += '&' + key + '=' + fieldsBase[key];
    });
    return url;
  };

  UNSAFE_componentWillMount() {
    this.props.getEntity(this.props.match.params['id']);
  }

  renderHeader() {
    const { activityEntity } = this.props;
    const state = this.state;
    return (
      <>
        <div id="page-heading">
          {' '}
          <h2>
            <Translate contentKey="generadorApp.activity.detail.title">Activity</Translate>
            <small>&nbsp; {activityEntity['name']}</small>
          </h2>
        </div>

        <div className="pull-right">
          <Button
            tag={Link}
            to={'/cidPta/' + this.props.match.params['idCidPta'] + '/activity?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
          >
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.activity.btnBack">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/cidPta/${this.props.match.params['idCidPta']}/activity/${activityEntity.id}/edit?+${getEntityFiltersURL(
              this.state.fieldsBase
            )}`}
            replace
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.activity.btnEdit">Edit</Translate>
            </span>
          </Button>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>

          <li className="breadcrumb-item">
            <Link to={'/cidPta'}>Cid Ptas</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/cidPta/' + this.props.match.params['idCidPta']}>{this.props.match.params['idCidPta']}</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/cidPta/' + (this.state.fieldsBase.cidPtaId ? this.state.fieldsBase.cidPtaId.label : '') + '/activity'}>
              Activities
            </Link>
          </li>
          <li className="breadcrumb-item active">Activities details</li>
        </ol>
      </>
    );
  }

  renderBody() {
    const { activityEntity } = this.props;
    const state = this.state;
    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            <Row className="jh-entity-details">
              <Col className="view-detail-row activity-name-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top activity-name-detail-label" md="12">
                    <dt>
                      <span id="name">
                        <Translate contentKey="generadorApp.activity.name">Name</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top activity-name-detail-value" md="12">
                    <dd>{activityEntity.name}</dd>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { activityEntity } = this.props;
    const state = this.state;
    return (
      <div>
        <Panel className="activity activity-detail">
          <PanelHeader>{this.renderHeader()}</PanelHeader>

          <PanelBody>{this.renderBody()}</PanelBody>
        </Panel>
      </div>
    );
  }
}

export const mapStateToProps = ({ activity, ...storeState }: IRootState) => ({
  activityEntity: activity.entity,
});

export const mapDispatchToProps = {
  getEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ActivityDetail);
