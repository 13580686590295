/* eslint complexity: ["error", 500] */
import axios from 'axios';

import moment from 'moment';
import { IPaginationBaseState, IPayloadResult } from 'react-jhipster';

import { IPayload } from 'react-jhipster/src/type/redux-action.type';

import { BASE_API_VERSION_PATH } from 'app/config/constants';

import { cleanEntity, jsonParse } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IQuote, defaultValue } from 'app/shared/model/quote.model';

import { SwitchExtended, initialStateExtended } from './extended/quote.reducer';

import { IQuotePackage, defaultValue as defaultValueQuotePackage } from 'app/shared/model/quote-package.model';
import { IQuoteRh, defaultValue as defaultValueQuoteRh } from 'app/shared/model/quote-rh.model';
import { IQuoteMedicine, defaultValue as defaultValueQuoteMedicine } from 'app/shared/model/quote-medicine.model';
import { IQuoteMaterial, defaultValue as defaultValueQuoteMaterial } from 'app/shared/model/quote-material.model';
import { IQuoteDiet, defaultValue as defaultValueQuoteDiet } from 'app/shared/model/quote-diet.model';
import { IQuoteProductService, defaultValue as defaultValueQuoteProductService } from 'app/shared/model/quote-product-service.model';

export declare type ICrudPutAction<T> = (data?: T, listFiltersPage?) => IPayload<T> | IPayloadResult<T>;
export declare type ICrudGetAction<T> = (id: string | number, selectFields?: string) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const ACTION_TYPES = {
  FETCH_QUOTE_LIST_EXPORT: 'quote/FETCH_QUOTE_LIST_EXPORT',
  FETCH_QUOTE_LIST: 'quote/FETCH_QUOTE_LIST',
  FETCH_QUOTE: 'quote/FETCH_QUOTE',
  FETCH_QUOTE_OFFSET: 'quote/FETCH_QUOTE_OFFSET',
  CREATE_QUOTE: 'quote/CREATE_QUOTE',
  UPDATE_QUOTE: 'quote/UPDATE_QUOTE',
  CREATE_QUOTE_AND_GO_LIST: 'quote/CREATE_QUOTE_AND_GO_LIST',
  UPDATE_QUOTE_AND_GO_LIST: 'quote/UPDATE_QUOTE_AND_GO_LIST',
  DELETE_QUOTE: 'quote/DELETE_QUOTE',
  SET_BLOB: 'quote/SET_BLOB',
  RESET: 'quote/RESET',
  SHOW_MODAL: 'quote/SHOW_MODAL',
};

const initialState = {
  loading: false,
  openModal: {
    list: false,
    view: false,
    detail: false,
  },
  errorMessage: null,
  entities: [] as ReadonlyArray<IQuote>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,

  quotePackageListEmbed: [] as ReadonlyArray<IQuotePackage> | any,
  quotePackageListAllEmbed: [] as ReadonlyArray<IQuotePackage> | any,
  quotePackageSelected: defaultValueQuotePackage,
  quoteRhListEmbed: [] as ReadonlyArray<IQuoteRh> | any,
  quoteRhListAllEmbed: [] as ReadonlyArray<IQuoteRh> | any,
  quoteRhSelected: defaultValueQuoteRh,
  quoteMedicineListEmbed: [] as ReadonlyArray<IQuoteMedicine> | any,
  quoteMedicineListAllEmbed: [] as ReadonlyArray<IQuoteMedicine> | any,
  quoteMedicineSelected: defaultValueQuoteMedicine,
  quoteMaterialListEmbed: [] as ReadonlyArray<IQuoteMaterial> | any,
  quoteMaterialListAllEmbed: [] as ReadonlyArray<IQuoteMaterial> | any,
  quoteMaterialSelected: defaultValueQuoteMaterial,
  quoteDietListEmbed: [] as ReadonlyArray<IQuoteDiet> | any,
  quoteDietListAllEmbed: [] as ReadonlyArray<IQuoteDiet> | any,
  quoteDietSelected: defaultValueQuoteDiet,
  quoteProductServiceListEmbed: [] as ReadonlyArray<IQuoteProductService> | any,
  quoteProductServiceListAllEmbed: [] as ReadonlyArray<IQuoteProductService> | any,
  quoteProductServiceSelected: defaultValueQuoteProductService,

  ...initialStateExtended,
};

const actionPendind = [];
let actualState = initialState;

export const convertEntityBuffers = entity => ({
  ...entity,
  billingObservation: entity.billingObservation ? Buffer.from(entity.billingObservation).toString() : entity.billingObservation,
});

export type QuoteState = Readonly<typeof initialState>;

export interface IQuoteBaseState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  listCheckedID?: Array<number>;
  isCheckedAll?: boolean;
  urlBack?: string;
  extraFilters?: any;
  baseFilters: any;
  offset: number;
  patientId?: any;
  patientStartFilter?: any;
  patientClientId?: any;
  patientClientStartFilter?: any;
  source?: any;
  status?: any;
  fromStart?: any;
  fromEnd?: any;
  toStart?: any;
  toEnd?: any;
}

export interface IFieldsBase extends IQuoteBaseState, IPaginationBaseState {}
export interface IQuoteUpdateState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  urlBack?: string;
  functionEmbebed?: any[];
  fieldsBase: IFieldsBase;
  sourceSelectValue?: any;
  sourceStartSelectOptions?: any;
  statusSelectValue?: any;
  statusStartSelectOptions?: any;
  approvalDateSelectValue?: any;
  approvalDateStartSelectOptions?: any;
  fromSelectValue?: any;
  fromStartSelectOptions?: any;
  toSelectValue?: any;
  toStartSelectOptions?: any;
  accountNumberSelectValue?: any;
  accountNumberStartSelectOptions?: any;
  finalAmountSelectValue?: any;
  finalAmountStartSelectOptions?: any;
  billFinalAmountSelectValue?: any;
  billFinalAmountStartSelectOptions?: any;
  typeSelectValue?: any;
  typeStartSelectOptions?: any;
  billingStatusSelectValue?: any;
  billingStatusStartSelectOptions?: any;
  billingPasswordNumberSelectValue?: any;
  billingPasswordNumberStartSelectOptions?: any;
  billingPasswordIssueSelectValue?: any;
  billingPasswordIssueStartSelectOptions?: any;
  billingPasswordExpirySelectValue?: any;
  billingPasswordExpiryStartSelectOptions?: any;
  billingPasswordIssuerSelectValue?: any;
  billingPasswordIssuerStartSelectOptions?: any;
  autorizationDataSelectValue?: any;
  autorizationDataStartSelectOptions?: any;
  billingHospitalizationRecordSelectValue?: any;
  billingHospitalizationRecordStartSelectOptions?: any;
  billingHospitalizationGuideSelectValue?: any;
  billingHospitalizationGuideStartSelectOptions?: any;
  billingGuideSPSADTSelectValue?: any;
  billingGuideSPSADTStartSelectOptions?: any;
  billingObservationSelectValue?: any;
  billingObservationStartSelectOptions?: any;

  clientGlosaSelectValue?: any;
  clientGlosaStartSelectOptions?: any;

  supplierGlosaSelectValue?: any;
  supplierGlosaStartSelectOptions?: any;

  quoteDietSelectValue?: any;
  quoteDietStartSelectOptions?: any;

  quoteMedicineSelectValue?: any;
  quoteMedicineStartSelectOptions?: any;

  quoteMaterialSelectValue?: any;
  quoteMaterialStartSelectOptions?: any;

  quoteProductServiceSelectValue?: any;
  quoteProductServiceStartSelectOptions?: any;

  quotePackageSelectValue?: any;
  quotePackageStartSelectOptions?: any;

  quoteRhSelectValue?: any;
  quoteRhStartSelectOptions?: any;

  quoteFileSelectValue?: any;
  quoteFileStartSelectOptions?: any;

  expectedResultSelectValue?: any;
  expectedResultStartSelectOptions?: any;

  padSelectValue?: any;
  padStartSelectOptions?: any;

  supplySelectValue?: any;
  supplyStartSelectOptions?: any;

  patientSelectValue?: any;
  patientStartSelectOptions?: any;

  billingXmlSelectValue?: any;
  billingXmlStartSelectOptions?: any;

  clComTableSelectValue?: any;
  clComTableStartSelectOptions?: any;

  isNew: boolean;
  patientName?: any;

  patientId?: any;
  billingXmlId?: any;
  clComTableName?: any;

  clComTableId?: any;
}

// Reducer

export default (state: QuoteState = initialState, action): QuoteState => {
  actualState = state;
  if (
    action.type.includes('_PENDING') &&
    !actionPendind.includes(action.type.replace('_PENDING', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_PENDING', ''))
  ) {
    actionPendind.push(action.type.replace('_PENDING', ''));
  }

  if (
    action.type.includes('_FULFILLED') &&
    actionPendind.includes(action.type.replace('_FULFILLED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_FULFILLED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_FULFILLED', '')), 1);
  }

  if (
    action.type.includes('_REJECTED') &&
    actionPendind.includes(action.type.replace('_REJECTED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_REJECTED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_REJECTED', '')), 1);
  }

  const switchExtended = SwitchExtended(state, action);
  if (switchExtended !== null) return switchExtended;

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_QUOTE_LIST_EXPORT):
    case REQUEST(ACTION_TYPES.FETCH_QUOTE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_QUOTE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_QUOTE):
    case REQUEST(ACTION_TYPES.UPDATE_QUOTE):
    case REQUEST(ACTION_TYPES.CREATE_QUOTE_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.UPDATE_QUOTE_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.DELETE_QUOTE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_QUOTE_LIST_EXPORT):
    case FAILURE(ACTION_TYPES.FETCH_QUOTE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_QUOTE):
    case FAILURE(ACTION_TYPES.CREATE_QUOTE):
    case FAILURE(ACTION_TYPES.UPDATE_QUOTE):
    case FAILURE(ACTION_TYPES.CREATE_QUOTE_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.UPDATE_QUOTE_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.DELETE_QUOTE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_QUOTE_LIST):
      action.payload.data = action.payload.data.map(v => {
        return { ...v, billingObservation: v.billingObservation ? Buffer.from(v.billingObservation).toString() : v.billingObservation };
      });

      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_QUOTE_OFFSET):
      if (action.payload.data && action.payload.data.length > 0) {
        return {
          ...state,
          loading: false,
          entity: convertEntityBuffers(action.payload.data[0]),
        };
      }
      return {
        ...state,
        loading: false,
      };

    case SUCCESS(ACTION_TYPES.FETCH_QUOTE):
      return {
        ...state,
        loading: false,
        entity: convertEntityBuffers(action.payload.data),
      };
    case SUCCESS(ACTION_TYPES.CREATE_QUOTE):
    case SUCCESS(ACTION_TYPES.UPDATE_QUOTE):
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_QUOTE_AND_GO_LIST):
    case SUCCESS(ACTION_TYPES.UPDATE_QUOTE_AND_GO_LIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_QUOTE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.SET_BLOB: {
      const { name, data, contentType, fileName } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [name + 'Base64']: data,
          [name + 'ContentType']: contentType,
          [name + 'FileName']: fileName,
        },
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
        quotePackageListEmbed: [],
        quotePackageListAllEmbed: [],
        quotePackageSelected: state.quotePackageSelected,
        quoteRhListEmbed: [],
        quoteRhListAllEmbed: [],
        quoteRhSelected: state.quoteRhSelected,
        quoteMedicineListEmbed: [],
        quoteMedicineListAllEmbed: [],
        quoteMedicineSelected: state.quoteMedicineSelected,
        quoteMaterialListEmbed: [],
        quoteMaterialListAllEmbed: [],
        quoteMaterialSelected: state.quoteMaterialSelected,
        quoteDietListEmbed: [],
        quoteDietListAllEmbed: [],
        quoteDietSelected: state.quoteDietSelected,
        quoteProductServiceListEmbed: [],
        quoteProductServiceListAllEmbed: [],
        quoteProductServiceSelected: state.quoteProductServiceSelected,
      };

    case 'quote/QUOTEPACKAGE_GET_ENTITIES':
      return {
        ...state,
        quotePackageListEmbed: state.quotePackageListAllEmbed,
      };
    case 'quote/QUOTEPACKAGE_CREATE_ENTITY':
      return {
        ...state,
        quotePackageListAllEmbed: [...(state.quotePackageListAllEmbed ? state.quotePackageListAllEmbed : []), action.payload],
      };
    case 'quote/QUOTEPACKAGE_SET_ENTITIES':
      return {
        ...state,
        quotePackageListAllEmbed: action.payload,
      };
    case 'quote/QUOTEPACKAGE_UPDATE_ENTITY': {
      const quotePackageListAllEmbed = [...state.quotePackageListAllEmbed];
      state.quotePackageListAllEmbed.map((v, i: any) => {
        if (v.id === action.payload.id) {
          quotePackageListAllEmbed[i] = action.payload;
        }
      });
      return {
        ...state,
        quotePackageListAllEmbed,
        quotePackageSelected: {},
      };
    }
    case 'quote/QUOTEPACKAGE_RESET':
      return {
        ...state,
        quotePackageSelected: {},
        quotePackageListEmbed: [],
      };
    case 'quote/QUOTEPACKAGE_GET_ENTITY':
      if (!!state.quotePackageListAllEmbed && state.quotePackageListAllEmbed.constructor === Array) {
        return {
          ...state,
          quotePackageSelected: state.quotePackageListAllEmbed.filter(v => v.id === action.payload).pop(),
        };
      }
      return {
        ...state,
        quotePackageSelected: state.quotePackageListAllEmbed,
      };
    case 'quote/QUOTEPACKAGE_DELETE_ENTITY':
      return {
        ...state,
        quotePackageListAllEmbed: state.quotePackageListAllEmbed.filter(v => v.id !== action.payload),
      };

    case 'quote/QUOTERH_GET_ENTITIES':
      return {
        ...state,
        quoteRhListEmbed: state.quoteRhListAllEmbed,
      };
    case 'quote/QUOTERH_CREATE_ENTITY':
      return {
        ...state,
        quoteRhListAllEmbed: [...(state.quoteRhListAllEmbed ? state.quoteRhListAllEmbed : []), action.payload],
      };
    case 'quote/QUOTERH_SET_ENTITIES':
      return {
        ...state,
        quoteRhListAllEmbed: action.payload,
      };
    case 'quote/QUOTERH_UPDATE_ENTITY': {
      const quoteRhListAllEmbed = [...state.quoteRhListAllEmbed];
      state.quoteRhListAllEmbed.map((v, i: any) => {
        if (v.id === action.payload.id) {
          quoteRhListAllEmbed[i] = action.payload;
        }
      });
      return {
        ...state,
        quoteRhListAllEmbed,
        quoteRhSelected: {},
      };
    }
    case 'quote/QUOTERH_RESET':
      return {
        ...state,
        quoteRhSelected: {},
        quoteRhListEmbed: [],
      };
    case 'quote/QUOTERH_GET_ENTITY':
      if (!!state.quoteRhListAllEmbed && state.quoteRhListAllEmbed.constructor === Array) {
        return {
          ...state,
          quoteRhSelected: state.quoteRhListAllEmbed.filter(v => v.id === action.payload).pop(),
        };
      }
      return {
        ...state,
        quoteRhSelected: state.quoteRhListAllEmbed,
      };
    case 'quote/QUOTERH_DELETE_ENTITY':
      return {
        ...state,
        quoteRhListAllEmbed: state.quoteRhListAllEmbed.filter(v => v.id !== action.payload),
      };

    case 'quote/QUOTEMEDICINE_GET_ENTITIES':
      return {
        ...state,
        quoteMedicineListEmbed: state.quoteMedicineListAllEmbed,
      };
    case 'quote/QUOTEMEDICINE_CREATE_ENTITY':
      return {
        ...state,
        quoteMedicineListAllEmbed: [...(state.quoteMedicineListAllEmbed ? state.quoteMedicineListAllEmbed : []), action.payload],
      };
    case 'quote/QUOTEMEDICINE_SET_ENTITIES':
      return {
        ...state,
        quoteMedicineListAllEmbed: action.payload,
      };
    case 'quote/QUOTEMEDICINE_UPDATE_ENTITY': {
      const quoteMedicineListAllEmbed = [...state.quoteMedicineListAllEmbed];
      state.quoteMedicineListAllEmbed.map((v, i: any) => {
        if (v.id === action.payload.id) {
          quoteMedicineListAllEmbed[i] = action.payload;
        }
      });
      return {
        ...state,
        quoteMedicineListAllEmbed,
        quoteMedicineSelected: {},
      };
    }
    case 'quote/QUOTEMEDICINE_RESET':
      return {
        ...state,
        quoteMedicineSelected: {},
        quoteMedicineListEmbed: [],
      };
    case 'quote/QUOTEMEDICINE_GET_ENTITY':
      if (!!state.quoteMedicineListAllEmbed && state.quoteMedicineListAllEmbed.constructor === Array) {
        return {
          ...state,
          quoteMedicineSelected: state.quoteMedicineListAllEmbed.filter(v => v.id === action.payload).pop(),
        };
      }
      return {
        ...state,
        quoteMedicineSelected: state.quoteMedicineListAllEmbed,
      };
    case 'quote/QUOTEMEDICINE_DELETE_ENTITY':
      return {
        ...state,
        quoteMedicineListAllEmbed: state.quoteMedicineListAllEmbed.filter(v => v.id !== action.payload),
      };

    case 'quote/QUOTEMATERIAL_GET_ENTITIES':
      return {
        ...state,
        quoteMaterialListEmbed: state.quoteMaterialListAllEmbed,
      };
    case 'quote/QUOTEMATERIAL_CREATE_ENTITY':
      return {
        ...state,
        quoteMaterialListAllEmbed: [...(state.quoteMaterialListAllEmbed ? state.quoteMaterialListAllEmbed : []), action.payload],
      };
    case 'quote/QUOTEMATERIAL_SET_ENTITIES':
      return {
        ...state,
        quoteMaterialListAllEmbed: action.payload,
      };
    case 'quote/QUOTEMATERIAL_UPDATE_ENTITY': {
      const quoteMaterialListAllEmbed = [...state.quoteMaterialListAllEmbed];
      state.quoteMaterialListAllEmbed.map((v, i: any) => {
        if (v.id === action.payload.id) {
          quoteMaterialListAllEmbed[i] = action.payload;
        }
      });
      return {
        ...state,
        quoteMaterialListAllEmbed,
        quoteMaterialSelected: {},
      };
    }
    case 'quote/QUOTEMATERIAL_RESET':
      return {
        ...state,
        quoteMaterialSelected: {},
        quoteMaterialListEmbed: [],
      };
    case 'quote/QUOTEMATERIAL_GET_ENTITY':
      if (!!state.quoteMaterialListAllEmbed && state.quoteMaterialListAllEmbed.constructor === Array) {
        return {
          ...state,
          quoteMaterialSelected: state.quoteMaterialListAllEmbed.filter(v => v.id === action.payload).pop(),
        };
      }
      return {
        ...state,
        quoteMaterialSelected: state.quoteMaterialListAllEmbed,
      };
    case 'quote/QUOTEMATERIAL_DELETE_ENTITY':
      return {
        ...state,
        quoteMaterialListAllEmbed: state.quoteMaterialListAllEmbed.filter(v => v.id !== action.payload),
      };

    case 'quote/QUOTEDIET_GET_ENTITIES':
      return {
        ...state,
        quoteDietListEmbed: state.quoteDietListAllEmbed,
      };
    case 'quote/QUOTEDIET_CREATE_ENTITY':
      return {
        ...state,
        quoteDietListAllEmbed: [...(state.quoteDietListAllEmbed ? state.quoteDietListAllEmbed : []), action.payload],
      };
    case 'quote/QUOTEDIET_SET_ENTITIES':
      return {
        ...state,
        quoteDietListAllEmbed: action.payload,
      };
    case 'quote/QUOTEDIET_UPDATE_ENTITY': {
      const quoteDietListAllEmbed = [...state.quoteDietListAllEmbed];
      state.quoteDietListAllEmbed.map((v, i: any) => {
        if (v.id === action.payload.id) {
          quoteDietListAllEmbed[i] = action.payload;
        }
      });
      return {
        ...state,
        quoteDietListAllEmbed,
        quoteDietSelected: {},
      };
    }
    case 'quote/QUOTEDIET_RESET':
      return {
        ...state,
        quoteDietSelected: {},
        quoteDietListEmbed: [],
      };
    case 'quote/QUOTEDIET_GET_ENTITY':
      if (!!state.quoteDietListAllEmbed && state.quoteDietListAllEmbed.constructor === Array) {
        return {
          ...state,
          quoteDietSelected: state.quoteDietListAllEmbed.filter(v => v.id === action.payload).pop(),
        };
      }
      return {
        ...state,
        quoteDietSelected: state.quoteDietListAllEmbed,
      };
    case 'quote/QUOTEDIET_DELETE_ENTITY':
      return {
        ...state,
        quoteDietListAllEmbed: state.quoteDietListAllEmbed.filter(v => v.id !== action.payload),
      };

    case 'quote/QUOTEPRODUCTSERVICE_GET_ENTITIES':
      return {
        ...state,
        quoteProductServiceListEmbed: state.quoteProductServiceListAllEmbed,
      };
    case 'quote/QUOTEPRODUCTSERVICE_CREATE_ENTITY':
      return {
        ...state,
        quoteProductServiceListAllEmbed: [
          ...(state.quoteProductServiceListAllEmbed ? state.quoteProductServiceListAllEmbed : []),
          action.payload,
        ],
      };
    case 'quote/QUOTEPRODUCTSERVICE_SET_ENTITIES':
      return {
        ...state,
        quoteProductServiceListAllEmbed: action.payload,
      };
    case 'quote/QUOTEPRODUCTSERVICE_UPDATE_ENTITY': {
      const quoteProductServiceListAllEmbed = [...state.quoteProductServiceListAllEmbed];
      state.quoteProductServiceListAllEmbed.map((v, i: any) => {
        if (v.id === action.payload.id) {
          quoteProductServiceListAllEmbed[i] = action.payload;
        }
      });
      return {
        ...state,
        quoteProductServiceListAllEmbed,
        quoteProductServiceSelected: {},
      };
    }
    case 'quote/QUOTEPRODUCTSERVICE_RESET':
      return {
        ...state,
        quoteProductServiceSelected: {},
        quoteProductServiceListEmbed: [],
      };
    case 'quote/QUOTEPRODUCTSERVICE_GET_ENTITY':
      if (!!state.quoteProductServiceListAllEmbed && state.quoteProductServiceListAllEmbed.constructor === Array) {
        return {
          ...state,
          quoteProductServiceSelected: state.quoteProductServiceListAllEmbed.filter(v => v.id === action.payload).pop(),
        };
      }
      return {
        ...state,
        quoteProductServiceSelected: state.quoteProductServiceListAllEmbed,
      };
    case 'quote/QUOTEPRODUCTSERVICE_DELETE_ENTITY':
      return {
        ...state,
        quoteProductServiceListAllEmbed: state.quoteProductServiceListAllEmbed.filter(v => v.id !== action.payload),
      };

    case ACTION_TYPES.SHOW_MODAL: {
      const openModal = state.openModal;
      openModal[action.payload.type] = action.payload.show;
      return {
        ...state,
        openModal,
      };
    }
    default:
      return state;
  }
};

const apiUrl = BASE_API_VERSION_PATH + 'quotes';

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

// Actions

// Actions
export type ICrudGetAllActionQuote<T> = (
  patientId?: any,
  patientClientId?: any,
  source?: any,
  status?: any,
  fromStart?: any,
  fromEnd?: any,
  toStart?: any,
  toEnd?: any,
  extraFilters?: {},
  page?: number,
  size?: number,
  sort?: string,
  selectFields?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getEntitiesFilter = (filters?, page?, size?, sort?, selectFields = '') => {
  const requestUrl = `${apiUrl}?page=${page >= 0 ? page : 0}&size=${size >= 0 ? size : 20}&sort=${sort ? sort : 'id,asc'}&`;
  const requestFilters = filters.map(v => v.column + '.' + v.operation + '=' + v.value).join('&');
  return {
    type: ACTION_TYPES.FETCH_QUOTE_LIST,
    payload: axios.get<IQuote>(`${requestUrl}${requestFilters}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getAllEntities = (filters?, sort?, selectFields = '') => {
  if (
    (actualState.totalItems > 0 && actualState.entities.length === actualState.totalItems) ||
    actionPendind.includes(ACTION_TYPES.FETCH_QUOTE_LIST)
  ) {
    return {
      type: ACTION_TYPES.FETCH_QUOTE_LIST + '_FROM_CACHE',
      payload: new Promise(function (resolve, reject) {
        resolve('done');
      }),
    };
  }

  const idsRequest = filters && typeof filters['ids'] !== 'undefined' && filters['ids'] ? `id.in=${filters['ids'].join(',')}&` : '';
  const patientRequest =
    filters && typeof filters['patientId'] !== 'undefined' && filters['patientId'] ? `patient.id.in=${filters['patientId']}&` : '';
  const patientClientRequest =
    filters && typeof filters['patientClientId'] !== 'undefined' && filters['patientClientId']
      ? `patient.client.id.in=${filters['patientClientId']}&`
      : '';
  const sourceRequest =
    filters && typeof filters['source'] !== 'undefined' && filters['source'] ? `source.contains=${filters['source']}&` : '';
  const statusRequest =
    filters && typeof filters['status'] !== 'undefined' && filters['status'] ? `status.contains=${filters['status']}&` : '';
  let fromStartRequest = '';
  let fromEndRequest = '';
  if (
    filters &&
    typeof filters['fromStart'] !== 'undefined' &&
    filters['fromStart'] &&
    !(typeof filters['fromEnd'] !== 'undefined' && filters['fromEnd'])
  ) {
    fromStartRequest = `from.greaterThan=${moment(filters['fromStart']).format('YYYY-MM-DD')}&`;
  } else if (
    filters &&
    !(typeof filters['fromStart'] !== 'undefined' && filters['fromStart']) &&
    typeof filters['fromEnd'] !== 'undefined' &&
    filters['fromEnd']
  ) {
    fromEndRequest = `from.lessThan=${moment(filters['fromEnd']).format('YYYY-MM-DD')}&`;
  } else if (
    filters &&
    typeof filters['fromStart'] !== 'undefined' &&
    filters['fromStart'] &&
    typeof filters['fromEnd'] !== 'undefined' &&
    filters['fromEnd']
  ) {
    fromStartRequest = `from.between=${moment(filters['fromStart']).format('YYYY-MM-DD')},${moment(filters['fromEnd']).format(
      'YYYY-MM-DD'
    )}&`;
  }
  let toStartRequest = '';
  let toEndRequest = '';
  if (
    filters &&
    typeof filters['toStart'] !== 'undefined' &&
    filters['toStart'] &&
    !(typeof filters['toEnd'] !== 'undefined' && filters['toEnd'])
  ) {
    toStartRequest = `to.greaterThan=${moment(filters['toStart']).format('YYYY-MM-DD')}&`;
  } else if (
    filters &&
    !(typeof filters['toStart'] !== 'undefined' && filters['toStart']) &&
    typeof filters['toEnd'] !== 'undefined' &&
    filters['toEnd']
  ) {
    toEndRequest = `to.lessThan=${moment(filters['toEnd']).format('YYYY-MM-DD')}&`;
  } else if (
    filters &&
    typeof filters['toStart'] !== 'undefined' &&
    filters['toStart'] &&
    typeof filters['toEnd'] !== 'undefined' &&
    filters['toEnd']
  ) {
    toStartRequest = `to.between=${moment(filters['toStart']).format('YYYY-MM-DD')},${moment(filters['toEnd']).format('YYYY-MM-DD')}&`;
  }

  const requestUrl = `${apiUrl}${`?page=${0}&size=${1000000}&sort=${sort ? sort : 'id,asc'}&`}`;
  return {
    type: ACTION_TYPES.FETCH_QUOTE_LIST,
    payload: axios.get<IQuote>(
      `${requestUrl}${idsRequest}${patientRequest}${patientClientRequest}${sourceRequest}${statusRequest}${fromStartRequest}${fromEndRequest}${toStartRequest}${toEndRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntities: ICrudGetAllActionQuote<IQuote> = (
  patientId,
  patientClientId,
  source,
  status,
  fromStart,
  fromEnd,
  toStart,
  toEnd,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const patientRequest = patientId ? `patient.id.in=${patientId}&` : '';
  const patientClientRequest = patientClientId ? `patient.client.id.in=${patientClientId}&` : '';
  const sourceRequest = source ? (Array.isArray(status) ? `source.in=${source.join(',')}&` : `source.equals=${source}&`) : '';
  const statusRequest = status ? (Array.isArray(status) ? `status.in=${status.join(',')}&` : `status.equals=${status}&`) : '';
  let fromStartRequest = '';
  let fromEndRequest = '';
  if (typeof fromStart !== 'undefined' && fromStart && !(typeof fromEnd !== 'undefined' && fromEnd)) {
    fromStartRequest = `from.greaterThan=${moment(fromStart).format('YYYY-MM-DD')}&`;
  } else if (!(typeof fromStart !== 'undefined' && fromStart) && typeof fromEnd !== 'undefined' && fromEnd) {
    fromEndRequest = `from.lessThan=${moment(fromEnd).format('YYYY-MM-DD')}&`;
  } else if (typeof fromStart !== 'undefined' && fromStart && typeof fromEnd !== 'undefined' && fromEnd) {
    fromStartRequest = `from.between=${moment(fromStart).format('YYYY-MM-DD')},${moment(fromEnd).format('YYYY-MM-DD')}&`;
  }
  let toStartRequest = '';
  let toEndRequest = '';
  if (typeof toStart !== 'undefined' && toStart && !(typeof toEnd !== 'undefined' && toEnd)) {
    toStartRequest = `to.greaterThan=${moment(toStart).format('YYYY-MM-DD')}&`;
  } else if (!(typeof toStart !== 'undefined' && toStart) && typeof toEnd !== 'undefined' && toEnd) {
    toEndRequest = `to.lessThan=${moment(toEnd).format('YYYY-MM-DD')}&`;
  } else if (typeof toStart !== 'undefined' && toStart && typeof toEnd !== 'undefined' && toEnd) {
    toStartRequest = `to.between=${moment(toStart).format('YYYY-MM-DD')},${moment(toEnd).format('YYYY-MM-DD')}&`;
  }

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_QUOTE_LIST,
    payload: axios.get<IQuote>(
      `${requestUrl}${extraFiltersRequest}${patientRequest}${patientClientRequest}${sourceRequest}${statusRequest}${fromStartRequest}${fromEndRequest}${toStartRequest}${toEndRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntityOffset: any = (
  patientId,
  patientClientId,
  source,
  status,
  fromStart,
  fromEnd,
  toStart,
  toEnd,
  extraFilters,
  offset,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const patientRequest = patientId ? `patient.id.in=${patientId}&` : '';
  const patientClientRequest = patientClientId ? `patient.client.id.in=${patientClientId}&` : '';
  const sourceRequest = source ? (Array.isArray(status) ? `source.in=${source.join(',')}&` : `source.equals=${source}&`) : '';
  const statusRequest = status ? (Array.isArray(status) ? `status.in=${status.join(',')}&` : `status.equals=${status}&`) : '';
  let fromStartRequest = '';
  let fromEndRequest = '';
  if (typeof fromStart !== 'undefined' && fromStart && !(typeof fromEnd !== 'undefined' && fromEnd)) {
    fromStartRequest = `from.greaterThan=${moment(fromStart).format('YYYY-MM-DD')}&`;
  } else if (!(typeof fromStart !== 'undefined' && fromStart) && typeof fromEnd !== 'undefined' && fromEnd) {
    fromEndRequest = `from.lessThan=${moment(fromEnd).format('YYYY-MM-DD')}&`;
  } else if (typeof fromStart !== 'undefined' && fromStart && typeof fromEnd !== 'undefined' && fromEnd) {
    fromStartRequest = `from.between=${moment(fromStart).format('YYYY-MM-DD')},${moment(fromEnd).format('YYYY-MM-DD')}&`;
  }
  let toStartRequest = '';
  let toEndRequest = '';
  if (typeof toStart !== 'undefined' && toStart && !(typeof toEnd !== 'undefined' && toEnd)) {
    toStartRequest = `to.greaterThan=${moment(toStart).format('YYYY-MM-DD')}&`;
  } else if (!(typeof toStart !== 'undefined' && toStart) && typeof toEnd !== 'undefined' && toEnd) {
    toEndRequest = `to.lessThan=${moment(toEnd).format('YYYY-MM-DD')}&`;
  } else if (typeof toStart !== 'undefined' && toStart && typeof toEnd !== 'undefined' && toEnd) {
    toStartRequest = `to.between=${moment(toStart).format('YYYY-MM-DD')},${moment(toEnd).format('YYYY-MM-DD')}&`;
  }

  const requestUrl = `${apiUrl}${sort ? `?page=${offset}&size=${1}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_QUOTE_OFFSET,
    payload: axios.get<IQuote>(
      `${requestUrl}${extraFiltersRequest}${patientRequest}${patientClientRequest}${sourceRequest}${statusRequest}${fromStartRequest}${fromEndRequest}${toStartRequest}${toEndRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntity: ICrudGetAction<IQuote> = (id, selectFields = '') => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_QUOTE,
    payload: axios.get<IQuote>(requestUrl, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntitiesExport: ICrudGetAllActionQuote<IQuote> = (
  patientId,
  patientClientId,
  source,
  status,
  fromStart,
  fromEnd,
  toStart,
  toEnd,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const patientRequest = patientId ? `patient.id.in=${patientId}&` : '';
  const patientClientRequest = patientClientId ? `patient.client.id.in=${patientClientId}&` : '';
  const sourceRequest = source ? (Array.isArray(status) ? `source.in=${source.join(',')}&` : `source.equals=${source}&`) : '';
  const statusRequest = status ? (Array.isArray(status) ? `status.in=${status.join(',')}&` : `status.equals=${status}&`) : '';
  let fromStartRequest = '';
  let fromEndRequest = '';
  if (typeof fromStart !== 'undefined' && fromStart && !(typeof fromEnd !== 'undefined' && fromEnd)) {
    fromStartRequest = `from.greaterThan=${moment(fromStart).format('YYYY-MM-DD')}&`;
  } else if (!(typeof fromStart !== 'undefined' && fromStart) && typeof fromEnd !== 'undefined' && fromEnd) {
    fromEndRequest = `from.lessThan=${moment(fromEnd).format('YYYY-MM-DD')}&`;
  } else if (typeof fromStart !== 'undefined' && fromStart && typeof fromEnd !== 'undefined' && fromEnd) {
    fromStartRequest = `from.between=${moment(fromStart).format('YYYY-MM-DD')},${moment(fromEnd).format('YYYY-MM-DD')}&`;
  }
  let toStartRequest = '';
  let toEndRequest = '';
  if (typeof toStart !== 'undefined' && toStart && !(typeof toEnd !== 'undefined' && toEnd)) {
    toStartRequest = `to.greaterThan=${moment(toStart).format('YYYY-MM-DD')}&`;
  } else if (!(typeof toStart !== 'undefined' && toStart) && typeof toEnd !== 'undefined' && toEnd) {
    toEndRequest = `to.lessThan=${moment(toEnd).format('YYYY-MM-DD')}&`;
  } else if (typeof toStart !== 'undefined' && toStart && typeof toEnd !== 'undefined' && toEnd) {
    toStartRequest = `to.between=${moment(toStart).format('YYYY-MM-DD')},${moment(toEnd).format('YYYY-MM-DD')}&`;
  }

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_QUOTE_LIST,
    payload: axios.get<IQuote>(
      `${requestUrl}${extraFiltersRequest}${patientRequest}${patientClientRequest}${sourceRequest}${statusRequest}${fromStartRequest}${fromEndRequest}${toStartRequest}${toEndRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const createEntity: ICrudPutAction<IQuote> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = {
    ...entity,
    approvalDate: entity.approvalDate ? moment(entity.approvalDate).format('YYYY-MM-DD') : null,
    from: entity.from ? moment(entity.from).format('YYYY-MM-DD') : null,
    to: entity.to ? moment(entity.to).format('YYYY-MM-DD') : null,
  };
  const result = await dispatch({
    type: listFiltersPage && listFiltersPage['reloadList'] !== false ? ACTION_TYPES.CREATE_QUOTE_AND_GO_LIST : ACTION_TYPES.CREATE_QUOTE,
    payload: axios.post(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const updateEntity: ICrudPutAction<IQuote> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = {
    ...entity,
    approvalDate: entity.approvalDate ? moment(entity.approvalDate).format('YYYY-MM-DD') : null,
    from: entity.from ? moment(entity.from).format('YYYY-MM-DD') : null,
    to: entity.to ? moment(entity.to).format('YYYY-MM-DD') : null,
  };
  const result = await dispatch({
    type: listFiltersPage && listFiltersPage['reloadList'] !== false ? ACTION_TYPES.UPDATE_QUOTE_AND_GO_LIST : ACTION_TYPES.UPDATE_QUOTE,
    payload: axios.put(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const deleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_QUOTE,
    payload: axios.delete(requestUrl),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const setBlob = (name, data, contentType?, fileName?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType,
    fileName,
  },
});

export const showModal = view => ({
  payload: { type: view, show: true },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const hideModal = view => ({
  payload: { type: view, show: false },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const getUrlBack = (location): string => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  let urlBack = _urlBase.searchParams.get('urlBack') || '';
  urlBack = urlBack.trim();
  urlBack = urlBack.startsWith('/') ? urlBack.slice(1) : urlBack;
  urlBack = urlBack.endsWith('/') ? urlBack.slice(0, -1) : urlBack;
  return urlBack ? '/' + urlBack + '/' : '';
};

export const getQuoteState = (location): IQuoteBaseState => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  const extraFilters = jsonParse(_urlBase.searchParams.get('extraFilters') || '{}');
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;

  const patientId = _urlBase.searchParams.get('patient') || '';
  const patientClientId = _urlBase.searchParams.get('patient.client') || '';
  const source = _urlBase.searchParams.get('source') || '';
  const status = _urlBase.searchParams.get('status') || '';
  const fromStart = _urlBase.searchParams.get('fromStart') || '';
  const fromEnd = _urlBase.searchParams.get('fromEnd') || '';
  const toStart = _urlBase.searchParams.get('toStart') || '';
  const toEnd = _urlBase.searchParams.get('toEnd') || '';
  return {
    baseFilters,
    extraFilters,
    offset,
    patientId: patientId ? patientId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
    patientClientId: patientClientId ? patientClientId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
    source,
    status,
    fromStart,
    fromEnd,
    toStart,
    toEnd,
  };
};

export const getEntityFiltersURL = (state, offset = null) => {
  return (
    'baseFilters=' +
    state.baseFilters +
    '&extraFilters=' +
    encodeURI(JSON.stringify(state.extraFilters)) +
    '&page=' +
    state.activePage +
    '&' +
    'size=' +
    state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    state.sort +
    ',' +
    state.order +
    '&' +
    (state.patientId ? 'patient=' + state.patientId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.patientClientId
      ? 'patient.client.fantasyName=' + state.patientClientId.map(v => v.value + '<->' + v.label).join(',') + '&'
      : '') +
    (state.source ? 'source=' + state.source + '&' : '') +
    (state.status ? 'status=' + state.status + '&' : '') +
    (state.from ? 'from=' + state.from + '&' : '') +
    (state.to ? 'to=' + state.to + '&' : '') +
    ''
  );
};

export const quotePackageReset: any = values => ({
  payload: values,
  type: 'quote/QUOTEPACKAGE_RESET',
});

export const quotePackageGetEntities: any = values => ({
  payload: values,
  type: 'quote/QUOTEPACKAGE_GET_ENTITIES',
});

export const quotePackageSetEntities: any = values => async dispatch => {
  const result = await dispatch({
    payload: values,
    type: 'quote/QUOTEPACKAGE_SET_ENTITIES',
  });
  dispatch(quotePackageGetEntities());
  return result;
};

export const quotePackageCreateEntity: ICrudPutAction<IQuotePackage> = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: { id: (new Date().getTime() * Math.random()).toString(16).split('.').join('-'), ...entity },
    type: 'quote/QUOTEPACKAGE_CREATE_ENTITY',
  });

  if (entity.quote === 'is_new') {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(quotePackageReset());
      dispatch(quotePackageGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};

export const quotePackageUpdateEntity: ICrudPutAction<IQuotePackage> = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: entity,
    type: 'quote/QUOTEPACKAGE_UPDATE_ENTITY',
  });
  if (entity.quote === 'is_new') {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(quotePackageReset());
      dispatch(quotePackageGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};
export const quotePackageGetEntity: any = values => ({
  payload: values,
  type: 'quote/QUOTEPACKAGE_GET_ENTITY',
});

export const quotePackageDeleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: id,
    type: 'quote/QUOTEPACKAGE_DELETE_ENTITY',
  });
  if (isNaN(id)) {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(quotePackageReset());
      dispatch(quotePackageGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};

export const quoteRhReset: any = values => ({
  payload: values,
  type: 'quote/QUOTERH_RESET',
});

export const quoteRhGetEntities: any = values => ({
  payload: values,
  type: 'quote/QUOTERH_GET_ENTITIES',
});

export const quoteRhSetEntities: any = values => async dispatch => {
  const result = await dispatch({
    payload: values,
    type: 'quote/QUOTERH_SET_ENTITIES',
  });
  dispatch(quoteRhGetEntities());
  return result;
};

export const quoteRhCreateEntity: ICrudPutAction<IQuoteRh> = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: { id: (new Date().getTime() * Math.random()).toString(16).split('.').join('-'), ...entity },
    type: 'quote/QUOTERH_CREATE_ENTITY',
  });

  if (entity.quote === 'is_new') {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(quoteRhReset());
      dispatch(quoteRhGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};

export const quoteRhUpdateEntity: ICrudPutAction<IQuoteRh> = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: entity,
    type: 'quote/QUOTERH_UPDATE_ENTITY',
  });
  if (entity.quote === 'is_new') {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(quoteRhReset());
      dispatch(quoteRhGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};
export const quoteRhGetEntity: any = values => ({
  payload: values,
  type: 'quote/QUOTERH_GET_ENTITY',
});

export const quoteRhDeleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: id,
    type: 'quote/QUOTERH_DELETE_ENTITY',
  });
  if (isNaN(id)) {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(quoteRhReset());
      dispatch(quoteRhGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};

export const quoteMedicineReset: any = values => ({
  payload: values,
  type: 'quote/QUOTEMEDICINE_RESET',
});

export const quoteMedicineGetEntities: any = values => ({
  payload: values,
  type: 'quote/QUOTEMEDICINE_GET_ENTITIES',
});

export const quoteMedicineSetEntities: any = values => async dispatch => {
  const result = await dispatch({
    payload: values,
    type: 'quote/QUOTEMEDICINE_SET_ENTITIES',
  });
  dispatch(quoteMedicineGetEntities());
  return result;
};

export const quoteMedicineCreateEntity: ICrudPutAction<IQuoteMedicine> = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: { id: (new Date().getTime() * Math.random()).toString(16).split('.').join('-'), ...entity },
    type: 'quote/QUOTEMEDICINE_CREATE_ENTITY',
  });

  if (entity.quote === 'is_new') {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(quoteMedicineReset());
      dispatch(quoteMedicineGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};

export const quoteMedicineUpdateEntity: ICrudPutAction<IQuoteMedicine> = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: entity,
    type: 'quote/QUOTEMEDICINE_UPDATE_ENTITY',
  });
  if (entity.quote === 'is_new') {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(quoteMedicineReset());
      dispatch(quoteMedicineGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};
export const quoteMedicineGetEntity: any = values => ({
  payload: values,
  type: 'quote/QUOTEMEDICINE_GET_ENTITY',
});

export const quoteMedicineDeleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: id,
    type: 'quote/QUOTEMEDICINE_DELETE_ENTITY',
  });
  if (isNaN(id)) {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(quoteMedicineReset());
      dispatch(quoteMedicineGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};

export const quoteMaterialReset: any = values => ({
  payload: values,
  type: 'quote/QUOTEMATERIAL_RESET',
});

export const quoteMaterialGetEntities: any = values => ({
  payload: values,
  type: 'quote/QUOTEMATERIAL_GET_ENTITIES',
});

export const quoteMaterialSetEntities: any = values => async dispatch => {
  const result = await dispatch({
    payload: values,
    type: 'quote/QUOTEMATERIAL_SET_ENTITIES',
  });
  dispatch(quoteMaterialGetEntities());
  return result;
};

export const quoteMaterialCreateEntity: ICrudPutAction<IQuoteMaterial> = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: { id: (new Date().getTime() * Math.random()).toString(16).split('.').join('-'), ...entity },
    type: 'quote/QUOTEMATERIAL_CREATE_ENTITY',
  });

  if (entity.quote === 'is_new') {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(quoteMaterialReset());
      dispatch(quoteMaterialGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};

export const quoteMaterialUpdateEntity: ICrudPutAction<IQuoteMaterial> = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: entity,
    type: 'quote/QUOTEMATERIAL_UPDATE_ENTITY',
  });
  if (entity.quote === 'is_new') {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(quoteMaterialReset());
      dispatch(quoteMaterialGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};
export const quoteMaterialGetEntity: any = values => ({
  payload: values,
  type: 'quote/QUOTEMATERIAL_GET_ENTITY',
});

export const quoteMaterialDeleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: id,
    type: 'quote/QUOTEMATERIAL_DELETE_ENTITY',
  });
  if (isNaN(id)) {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(quoteMaterialReset());
      dispatch(quoteMaterialGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};

export const quoteDietReset: any = values => ({
  payload: values,
  type: 'quote/QUOTEDIET_RESET',
});

export const quoteDietGetEntities: any = values => ({
  payload: values,
  type: 'quote/QUOTEDIET_GET_ENTITIES',
});

export const quoteDietSetEntities: any = values => async dispatch => {
  const result = await dispatch({
    payload: values,
    type: 'quote/QUOTEDIET_SET_ENTITIES',
  });
  dispatch(quoteDietGetEntities());
  return result;
};

export const quoteDietCreateEntity: ICrudPutAction<IQuoteDiet> = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: { id: (new Date().getTime() * Math.random()).toString(16).split('.').join('-'), ...entity },
    type: 'quote/QUOTEDIET_CREATE_ENTITY',
  });

  if (entity.quote === 'is_new') {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(quoteDietReset());
      dispatch(quoteDietGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};

export const quoteDietUpdateEntity: ICrudPutAction<IQuoteDiet> = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: entity,
    type: 'quote/QUOTEDIET_UPDATE_ENTITY',
  });
  if (entity.quote === 'is_new') {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(quoteDietReset());
      dispatch(quoteDietGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};
export const quoteDietGetEntity: any = values => ({
  payload: values,
  type: 'quote/QUOTEDIET_GET_ENTITY',
});

export const quoteDietDeleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: id,
    type: 'quote/QUOTEDIET_DELETE_ENTITY',
  });
  if (isNaN(id)) {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(quoteDietReset());
      dispatch(quoteDietGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};

export const quoteProductServiceReset: any = values => ({
  payload: values,
  type: 'quote/QUOTEPRODUCTSERVICE_RESET',
});

export const quoteProductServiceGetEntities: any = values => ({
  payload: values,
  type: 'quote/QUOTEPRODUCTSERVICE_GET_ENTITIES',
});

export const quoteProductServiceSetEntities: any = values => async dispatch => {
  const result = await dispatch({
    payload: values,
    type: 'quote/QUOTEPRODUCTSERVICE_SET_ENTITIES',
  });
  dispatch(quoteProductServiceGetEntities());
  return result;
};

export const quoteProductServiceCreateEntity: ICrudPutAction<IQuoteProductService> = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: { id: (new Date().getTime() * Math.random()).toString(16).split('.').join('-'), ...entity },
    type: 'quote/QUOTEPRODUCTSERVICE_CREATE_ENTITY',
  });

  if (entity.quote === 'is_new') {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(quoteProductServiceReset());
      dispatch(quoteProductServiceGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};

export const quoteProductServiceUpdateEntity: ICrudPutAction<IQuoteProductService> = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: entity,
    type: 'quote/QUOTEPRODUCTSERVICE_UPDATE_ENTITY',
  });
  if (entity.quote === 'is_new') {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(quoteProductServiceReset());
      dispatch(quoteProductServiceGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};
export const quoteProductServiceGetEntity: any = values => ({
  payload: values,
  type: 'quote/QUOTEPRODUCTSERVICE_GET_ENTITY',
});

export const quoteProductServiceDeleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: id,
    type: 'quote/QUOTEPRODUCTSERVICE_DELETE_ENTITY',
  });
  if (isNaN(id)) {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(quoteProductServiceReset());
      dispatch(quoteProductServiceGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};
