import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';
import moment from 'moment';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import {
  getEntity,
  IPharmacyStockStatusBaseState,
  getUrlBack,
  getPharmacyStockStatusState,
  getEntityFiltersURL,
} from './pharmacy-stock-status.reducer';

import { APP_DATE_FORMAT } from 'app/config/constants';

export interface IPharmacyStockStatusState {
  fieldsBase: IPharmacyStockStatusBaseState;
}

export interface IPharmacyStockStatusDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class PharmacyStockStatusDetail extends React.Component<IPharmacyStockStatusDetailProps, IPharmacyStockStatusState> {
  constructor(props: Readonly<IPharmacyStockStatusDetailProps>) {
    super(props);
    this.state = {
      ...this.state,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getPharmacyStockStatusState(this.props.location),
        urlBack: getUrlBack(this.props.location),
        pharmacyStockId: [{ value: this.props.match.params['idPharmacyStock'], label: this.props.match.params['idPharmacyStock'] }],
        baseFilters: 'pharmacyStock',
      },
    };
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = '_back=1' + (offset !== null ? '&offset=' + offset : '');
    Object.keys(fieldsBase).map(key => {
      url += '&' + key + '=' + fieldsBase[key];
    });
    return url;
  };

  UNSAFE_componentWillMount() {
    this.props.getEntity(this.props.match.params['id']);
  }

  renderHeader() {
    const { pharmacyStockStatusEntity } = this.props;
    const state = this.state;
    return (
      <>
        <div id="page-heading">
          {' '}
          <h2>
            <Translate contentKey="generadorApp.pharmacyStockStatus.detail.title">PharmacyStockStatus</Translate>
            <small>&nbsp; {pharmacyStockStatusEntity['status']}</small>
          </h2>
        </div>

        <div className="pull-right">
          <Button
            tag={Link}
            to={
              '/pharmacyStock/' +
              this.props.match.params['idPharmacyStock'] +
              '/pharmacy-stock-status?' +
              getEntityFiltersURL(this.state.fieldsBase)
            }
            replace
            color="info"
          >
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.pharmacyStockStatus.btnBack">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/pharmacyStock/${this.props.match.params['idPharmacyStock']}/pharmacy-stock-status/${
              pharmacyStockStatusEntity.id
            }/edit?+${getEntityFiltersURL(this.state.fieldsBase)}`}
            replace
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.pharmacyStockStatus.btnEdit">Edit</Translate>
            </span>
          </Button>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>

          <li className="breadcrumb-item">
            <Link to={'/pharmacyStock'}>Pharmacy Stocks</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/pharmacyStock/' + this.props.match.params['idPharmacyStock']}>{this.props.match.params['idPharmacyStock']}</Link>
          </li>
          <li className="breadcrumb-item">
            <Link
              to={
                '/pharmacyStock/' +
                (this.state.fieldsBase.pharmacyStockId ? this.state.fieldsBase.pharmacyStockId.label : '') +
                '/pharmacy-stock-status'
              }
            >
              Kit status Histories
            </Link>
          </li>
          <li className="breadcrumb-item active">Kit status Histories details</li>
        </ol>
      </>
    );
  }

  renderBody() {
    const { pharmacyStockStatusEntity } = this.props;
    const state = this.state;
    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            <Row className="jh-entity-details">
              <Col className="view-detail-row pharmacy-stock-status-status-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top pharmacy-stock-status-status-detail-label" md="12">
                    <dt>
                      <span id="status">
                        <Translate contentKey="generadorApp.pharmacyStockStatus.status">Status</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pharmacy-stock-status-status-detail-value" md="12">
                    <dd>
                      {translate(
                        'generadorApp.StatusPhamacyStock.' +
                          (pharmacyStockStatusEntity.status ? pharmacyStockStatusEntity.status : 'PROXIMODAVALIDADE')
                      )}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pharmacy-stock-status-observation-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top pharmacy-stock-status-observation-detail-label" md="12">
                    <dt>
                      <span id="observation">
                        <Translate contentKey="generadorApp.pharmacyStockStatus.observation">Observation</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pharmacy-stock-status-observation-detail-value" md="12">
                    <dd>{pharmacyStockStatusEntity.observation}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pharmacy-stock-status-createdBy-detail" md="12">
                <Row>
                  <Col className="view-detail-label-left pharmacy-stock-status-createdBy-detail-label" md="3">
                    <dt>
                      <Translate contentKey="generadorApp.pharmacyStockStatus.createdBy">Created By</Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left pharmacy-stock-status-createdBy-detail-value" md="9">
                    <dd>{pharmacyStockStatusEntity.createdBy ? pharmacyStockStatusEntity.createdBy.id : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pharmacy-stock-status-createdDate-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top pharmacy-stock-status-createdDate-detail-label" md="12">
                    <dt>
                      <span id="createdDate">
                        <Translate contentKey="generadorApp.pharmacyStockStatus.createdDate">Created Date</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pharmacy-stock-status-createdDate-detail-value" md="12">
                    <dd>
                      {pharmacyStockStatusEntity.createdDate ? moment(pharmacyStockStatusEntity.createdDate).format(APP_DATE_FORMAT) : ''}
                    </dd>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { pharmacyStockStatusEntity } = this.props;
    const state = this.state;
    return (
      <div>
        <Panel className="pharmacy-stock-status pharmacy-stock-status-detail">
          <PanelHeader>{this.renderHeader()}</PanelHeader>

          <PanelBody>{this.renderBody()}</PanelBody>
        </Panel>
      </div>
    );
  }
}

export const mapStateToProps = ({ pharmacyStockStatus, ...storeState }: IRootState) => ({
  pharmacyStockStatusEntity: pharmacyStockStatus.entity,
});

export const mapDispatchToProps = {
  getEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyStockStatusDetail);
