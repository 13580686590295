import { IClComRuler } from 'app/shared/model/cl-com-ruler.model';
import { Sub } from 'app/shared/model/enumerations/sub.model';

import React from 'react';

import { ServiceType } from 'app/shared/model/enumerations/service-type.model';
import { ComercialTableType } from 'app/shared/model/enumerations/comercial-table-type.model';

export interface IClComRulerProps {
  baseState?: any;
  baseProps?: any;
  setState?: any;
  entityBase?: IClComRuler;
}

const ClComRulerComponentCustomize = (props: IClComRulerProps) => {
  if (props.entityBase && props.entityBase.levelGroup && props.entityBase.levelGroup.sub !== Sub.MATERIALMEDICAMENTOEDIETA) {
    if (props.entityBase.saleValue && !Number.isNaN(props.entityBase.saleValue) && props.entityBase.option === ServiceType.VENDA) {
      return <>{'R$ ' + Number(props.entityBase.saleValue).toFixed(2).replace('.', ',')}</>;
    } else if (
      props.entityBase.rentalValue &&
      !Number.isNaN(props.entityBase.rentalValue) &&
      props.entityBase.option === ServiceType.LOCACAO
    ) {
      return <>{'R$ ' + Number(props.entityBase.rentalValue).toFixed(2).replace('.', ',')} </>;
    }
  } else if (
    props.entityBase &&
    props.entityBase.levelGroup &&
    props.entityBase.levelGroup.sub === Sub.MATERIALMEDICAMENTOEDIETA &&
    props.entityBase.comercialTableType === ComercialTableType.PMC
  ) {
    return (
      <>
        ({ComercialTableType.PMC}) {Number(props.entityBase.deflator).toFixed(2).replace('.', ',')} %
      </>
    );
  } else if (
    props.entityBase &&
    props.entityBase.levelGroup &&
    props.entityBase.levelGroup.sub === Sub.MATERIALMEDICAMENTOEDIETA &&
    props.entityBase.comercialTableType === ComercialTableType.PF
  ) {
    return (
      <>
        ({ComercialTableType.PF}) {Number(props.entityBase.inflator).toFixed(2).replace('.', ',')} %
      </>
    );
  }

  return <> </>;
};

export default ClComRulerComponentCustomize;
