import React, { useEffect, useState } from 'react';
import './home.scss';
import { Row, Col, Nav, Label, NavItem, NavLink, TabContent, TabPane, Button } from 'reactstrap';

import { getReport } from 'app/shared/reports/jhi-reports.reducer';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';
import moment from 'moment';

import { getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import SelectAsync from 'react-select/async';
import Select from 'react-select';

import axios from 'axios';
import { connect } from 'react-redux';
import PatientsActives from 'app/shared/reports/charts/pacientes-ativos';
import PatientsHospitalizacao from 'app/shared/reports/charts/pacientes-hospitalizacao';
import Exames from 'app/shared/reports/charts/exames';
import AtendimentosCategoria from 'app/shared/reports/charts/atendimentos-categoria';
import AtendimentosCategoriaComplexidade from 'app/shared/reports/charts/atendimentos-categoria-complexidade';

import ChartAtendimentosCategorias from 'app/shared/reports/charts/chart-atendimentos-categorias';
import ChartAtendimentos from 'app/shared/reports/charts/chart-atendimentos';
import ChartImplantacoes from 'app/shared/reports/charts/chart-implantacoes';
import ChartOrcamentosPorStatus from 'app/shared/reports/charts/chart-orcamentos-por-status';
import ChartPacientesPorTerritorios from 'app/shared/reports/charts/chart-pacientes-por-territorios';
import ChartPacientesStatus from 'app/shared/reports/charts/chart-pacientes-status';
import ChartPacientes from 'app/shared/reports/charts/chart-pacientes';
import ChartTotalSuprimentosRomaneioPad from 'app/shared/reports/charts/chart-total-suprimentos-romaneio-pad';
import { Translate, translate } from 'app/config/translate-component';

registerLocale('pt', pt);

export interface IHomeProp extends StateProps, DispatchProps {}

export const Home = (props: IHomeProp) => {
  const [activeTab, setActiveTab] = useState('0');
  const [periodoInicio, setPeriodoInicio] = useState(moment().startOf('month'));
  const [periodoFim, setPeriodoFim] = useState(moment().endOf('month'));
  const [operadora, setOperadora] = useState([]);
  const [pacientesADID, setPacientesADID] = useState(null);
  const [pacientesStatus, setPacientesStatus] = useState('ATIVO');
  const [operadoraStartFilter, setOperadoraStartFilter] = useState(undefined);

  const [atendimentos, setAtendimentos] = useState(null);
  const [atendimentosPorCategorias, setAtendimentosPorCategorias] = useState(null);
  const [implantacoes, setImplantacoes] = useState(null);
  const [orcamentosPorStatus, setOrcamentosPorStatus] = useState(null);
  const [pacientesPorTerritorios, setPacientesPorTerritorios] = useState(null);
  const [pacientesPorStatus, setPacientesPorStatus] = useState(null);
  const [pacientes, setPacientes] = useState(null);
  const [totalSuprimentosRomaneioPad, setTotalSuprimentosRomaneioPad] = useState(null);

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const fnAtendimentos = (_periodoInicio, _periodoFim, _operadora) => {
    axios
      .get(
        `/api/graphics/atendimentos?periodoInicio=${moment(_periodoInicio).format('YYYY-MM-DD')}&periodoFim=${moment(_periodoFim).format(
          'YYYY-MM-DD'
        )}&operadora=${_operadora}`
      )
      .then(v => setAtendimentos(v && v.data ? v.data : null));
  };
  const fnAtendimentosPorCategorias = (_periodoInicio, _periodoFim, _operadora) => {
    axios
      .get(
        `/api/graphics/atendimentos-por-categorias?periodoInicio=${moment(_periodoInicio).format('YYYY-MM-DD')}&periodoFim=${moment(
          _periodoFim
        ).format('YYYY-MM-DD')}&operadora=${_operadora}`
      )
      .then(v => setAtendimentosPorCategorias(v && v.data ? v.data : null));
  };
  const fnImplantacoes = (_periodoInicio, _periodoFim, _operadora) => {
    axios
      .get(
        `/api/graphics/implantacoes?periodoInicio=${moment(_periodoInicio).format('YYYY-MM-DD')}&periodoFim=${moment(_periodoFim).format(
          'YYYY-MM-DD'
        )}&operadora=${_operadora}`
      )
      .then(v => setImplantacoes(v && v.data ? v.data : null));
  };
  const fnOrcamentosPorStatus = (_periodoInicio, _periodoFim, _operadora) => {
    axios
      .get(
        `/api/graphics/orcamentos-por-status?periodoInicio=${moment(_periodoInicio).format('YYYY-MM-DD')}&periodoFim=${moment(
          _periodoFim
        ).format('YYYY-MM-DD')}&operadora=${_operadora}`
      )
      .then(v => setOrcamentosPorStatus(v && v.data ? v.data : null));
  };
  const fnPacientesPorTerritorios = (_periodoInicio, _periodoFim, _operadora, _pacientesStatus) => {
    axios
      .get(
        `/api/graphics/pacientes-por-territorios?periodoInicio=${moment(_periodoInicio).format('YYYY-MM-DD')}&periodoFim=${moment(
          _periodoFim
        ).format('YYYY-MM-DD')}&operadora=${_operadora}&status=${_pacientesStatus}`
      )
      .then(v => setPacientesPorTerritorios(v && v.data ? v.data : null));
  };
  const fnPacientesPorStatus = (_periodoInicio, _periodoFim, _operadora) => {
    axios
      .get(
        `/api/graphics/pacientes-por-status?periodoInicio=${moment(_periodoInicio).format('YYYY-MM-DD')}&periodoFim=${moment(
          _periodoFim
        ).format('YYYY-MM-DD')}&operadora=${_operadora}`
      )
      .then(v => setPacientesPorStatus(v && v.data ? v.data : null));
  };
  const fnPacientes = (_periodoInicio, _periodoFim, _operadora, _pacientesADID) => {
    axios
      .get(
        `/api/graphics/pacientes?pacientesADID=${_pacientesADID}&periodoInicio=${moment(_periodoInicio).format(
          'YYYY-MM-DD'
        )}&periodoFim=${moment(_periodoFim).format('YYYY-MM-DD')}&operadora=${_operadora}`
      )
      .then(v => setPacientes(v && v.data ? v.data : null));
  };
  const fnTotalSuprimentosRomaneioPad = (_periodoInicio, _periodoFim, _operadora) => {
    axios
      .get(
        `/api/graphics/total-suprimentos-romaneio-pad?periodoInicio=${moment(_periodoInicio).format('YYYY-MM-DD')}&periodoFim=${moment(
          _periodoFim
        ).format('YYYY-MM-DD')}&operadora=${_operadora}`
      )
      .then(v => setTotalSuprimentosRomaneioPad(v && v.data ? v.data : null));
  };

  const reloadGraphics = () => {
    fnAtendimentos(
      periodoInicio,
      periodoFim,
      operadora.map(v => v.id)
    );
    fnAtendimentosPorCategorias(
      periodoInicio,
      periodoFim,
      operadora.map(v => v.id)
    );
    fnImplantacoes(
      periodoInicio,
      periodoFim,
      operadora.map(v => v.id)
    );
    fnOrcamentosPorStatus(
      periodoInicio,
      periodoFim,
      operadora.map(v => v.id)
    );
    fnPacientesPorTerritorios(
      periodoInicio,
      periodoFim,
      operadora.map(v => v.id),
      pacientesStatus
    );
    fnPacientesPorStatus(
      periodoInicio,
      periodoFim,
      operadora.map(v => v.id)
    );
    fnPacientes(
      periodoInicio,
      periodoFim,
      operadora.map(v => v.id),
      pacientesADID
    );
    fnTotalSuprimentosRomaneioPad(
      periodoInicio,
      periodoFim,
      operadora.map(v => v.id)
    );
  };

  const [graphicData, setGraphicData] = useState([]);
  useEffect(() => {
    reloadGraphics();
  }, []);

  // const { account } = props;
  return (
    <div className="dashboard-view">
      <div id="page-heading">
        <span className="page-header">Dashboard</span>
      </div>
      <br />
      <Nav tabs>
        <NavItem>
          <NavLink
            active={activeTab === '0'}
            onClick={() => {
              toggle('0');
            }}
          >
            Principais
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={activeTab === '1'}
            onClick={() => {
              toggle('1');
            }}
          >
            Atendimentos
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={activeTab === '2'}
            onClick={() => {
              toggle('2');
            }}
          >
            Pacientes
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="0">
          <Row>
            <Col sm="12">
              <Row style={{ margin: '15px 0' }}>
                <Col md="3" className="">
                  <Row className="mr-1 mt-1">
                    <Label for="createdDateStart"> Periodo (Inicio) </Label>
                    <DatePicker
                      onChange={value => setPeriodoInicio(value)}
                      id="createdDateStart"
                      className="form-control react-datepicker"
                      name="createdDateStart"
                      dateFormat="dd/MM/yyyy"
                      selected={periodoInicio ? moment(periodoInicio).toDate() : null}
                      locale="pt"
                    />
                  </Row>
                </Col>
                <Col md="3" className="">
                  <Row className="mr-1 mt-1">
                    <Label for="createdDateStart"> Periodo (Fin) </Label>
                    <DatePicker
                      onChange={value => setPeriodoFim(value)}
                      id="createdDateStart"
                      className="form-control react-datepicker"
                      name="createdDateStart"
                      dateFormat="dd/MM/yyyy"
                      selected={periodoFim ? moment(periodoFim).toDate() : null}
                      locale="pt"
                    />
                  </Row>
                </Col>
                <Col md="4">
                  <Row className="mr-1 mt-1">
                    <div style={{ width: '100%' }}>
                      <Label for="billing-xml-createdBy">Operadoras</Label>
                      <SelectAsync
                        id="billing-xml-createdBy"
                        isMulti
                        className={'css-select-control'}
                        name={'createdBy'}
                        cacheOptions
                        value={operadora}
                        onChange={options => setOperadora(options)}
                        defaultOptions={operadoraStartFilter ? operadoraStartFilter : []}
                        loadingMessage={input => translate('selectAsync.loadingMessage')}
                        noOptionsMessage={input =>
                          operadoraStartFilter === undefined
                            ? translate('selectAsync.loadingMessage')
                            : translate('selectAsync.noOptionsMessage')
                        }
                        onMenuOpen={async () => {
                          if (operadoraStartFilter === undefined) {
                            const result = await getListAxios('clients', {}, 0, 100, 'fantasyName,asc', 'fantasyName');
                            setOperadoraStartFilter(
                              result.data
                                ? result.data.map(p => ({
                                    ...p,
                                    value: p.id,
                                    label: showFieldsSelectAsync(p, 'fantasyName'),
                                  }))
                                : []
                            );
                          }
                        }}
                        loadOptions={async (inputValue, callback) => {
                          const result = await getListAxios(
                            'clients',
                            { 'fantasyName.contains': inputValue },
                            0,
                            100,
                            'fantasyName,asc',
                            'fantasyName'
                          );
                          callback(
                            result.data
                              ? result.data.map(p => ({
                                  ...p,
                                  value: p.id,
                                  label: showFieldsSelectAsync(p, 'fantasyName'),
                                }))
                              : []
                          );
                        }}
                      />
                    </div>
                  </Row>
                </Col>
                <Col md="2">
                  <div className="row mt-4 justify-content-end">
                    <Button color="primary" onClick={() => reloadGraphics()}>
                      <i className="fa fa-filter" aria-hidden={'true'}></i>&nbsp;
                      <Translate contentKey="generadorApp.activity.btnFilter">Filter</Translate>
                    </Button>
                  </div>
                </Col>
                <br />
                <br />
              </Row>
            </Col>
            <Col sm="12">
              <Row>
                <Col sm="12">
                  <ChartAtendimentos
                    periodoInicio={periodoInicio}
                    periodoFim={periodoFim}
                    operadora={operadora}
                    atendimentos={atendimentos}
                  />
                </Col>
                <Col sm="4">
                  <ChartAtendimentosCategorias
                    periodoInicio={periodoInicio}
                    periodoFim={periodoFim}
                    operadora={operadora}
                    atendimentosPorCategorias={atendimentosPorCategorias}
                  />
                  <ChartPacientesStatus
                    periodoInicio={periodoInicio}
                    periodoFim={periodoFim}
                    operadora={operadora}
                    pacientesPorStatus={pacientesPorStatus}
                  />
                </Col>
                <Col sm="4">
                  <ChartImplantacoes
                    periodoInicio={periodoInicio}
                    periodoFim={periodoFim}
                    operadora={operadora}
                    implantacoes={implantacoes}
                  />
                  <ChartOrcamentosPorStatus
                    periodoInicio={periodoInicio}
                    periodoFim={periodoFim}
                    operadora={operadora}
                    orcamentosPorStatus={orcamentosPorStatus}
                  />
                </Col>
                <Col sm="4">
                  <ChartPacientes periodoInicio={periodoInicio} periodoFim={periodoFim} operadora={operadora} pacientes={pacientes} />
                  <Select
                    id="home-patient-adId"
                    className={'css-select-control'}
                    value={
                      pacientesADID
                        ? {
                            value: pacientesADID,
                            label: translate('generadorApp.ADID.' + pacientesADID),
                          }
                        : { value: '', label: translate('generadorApp.patient.adId') }
                    }
                    options={[
                      { value: '', label: translate('generadorApp.patient.adId') },
                      { value: 'AD', label: translate('generadorApp.ADID.AD') },
                      { value: 'ID', label: translate('generadorApp.ADID.ID') },
                    ]}
                    onChange={(options: any) => {
                      setPacientesADID(options['value']);
                      fnPacientes(periodoInicio, periodoFim, operadora, options['value']);
                    }}
                    name="adId"
                  />
                  <ChartTotalSuprimentosRomaneioPad
                    periodoInicio={periodoInicio}
                    periodoFim={periodoFim}
                    operadora={operadora}
                    totalSuprimentosRomaneioPad={totalSuprimentosRomaneioPad}
                  />
                </Col>
                <Col sm="12">
                  <ChartPacientesPorTerritorios
                    periodoInicio={periodoInicio}
                    periodoFim={periodoFim}
                    operadora={operadora}
                    pacientesPorTerritorios={pacientesPorTerritorios}
                  />
                  <Select
                    id="home-patient-status"
                    className={'css-select-control'}
                    value={
                      pacientesStatus
                        ? {
                            value: pacientesStatus,
                            label: translate('generadorApp.PatientStatus.' + pacientesStatus),
                          }
                        : { value: '', label: translate('generadorApp.patient.status') }
                    }
                    options={[
                      { value: '', label: translate('generadorApp.patient.status') },
                      { value: 'ATIVO', label: translate('generadorApp.PatientStatus.ATIVO') },
                      { value: 'AVALIACAO', label: translate('generadorApp.PatientStatus.AVALIACAO') },
                      { value: 'ALTA', label: translate('generadorApp.PatientStatus.ALTA') },
                      { value: 'HOSPITALIZADO', label: translate('generadorApp.PatientStatus.HOSPITALIZADO') },
                      { value: 'OBITO', label: translate('generadorApp.PatientStatus.OBITO') },
                      { value: 'MIGRACAO', label: translate('generadorApp.PatientStatus.MIGRACAO') },
                    ]}
                    onChange={(options: any) => {
                      setPacientesStatus(options['value']);
                      fnPacientesPorTerritorios(periodoInicio, periodoFim, operadora, options['value']);
                    }}
                    name="adId"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              <Row>
                <Col sm="12">
                  <AtendimentosCategoria getReport={props.getReport} />
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <AtendimentosCategoriaComplexidade getReport={props.getReport} />
                </Col>
              </Row>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col sm="12">
              <PatientsActives getReport={props.getReport} />
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <PatientsHospitalizacao getReport={props.getReport} />
            </Col>
            <Col md="6">
              <Exames getReport={props.getReport} />
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
};

const mapStateToProps = storeState => ({
  account: storeState.authentication.account,
  isAuthenticated: storeState.authentication.isAuthenticated,
});

const mapDispatchToProps = { getReport };
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Home);
