import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';
import moment from 'moment';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import {
  getEntity,
  INursingPrescriptionBaseState,
  getUrlBack,
  getNursingPrescriptionState,
  getEntityFiltersURL,
} from './nursing-prescription.reducer';

import { APP_DATE_FORMAT } from 'app/config/constants';

export interface INursingPrescriptionState {
  fieldsBase: INursingPrescriptionBaseState;
}

export interface INursingPrescriptionDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class NursingPrescriptionDetail extends React.Component<INursingPrescriptionDetailProps, INursingPrescriptionState> {
  constructor(props: Readonly<INursingPrescriptionDetailProps>) {
    super(props);
    this.state = {
      ...this.state,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getNursingPrescriptionState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
    };
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = '_back=1' + (offset !== null ? '&offset=' + offset : '');
    Object.keys(fieldsBase).map(key => {
      url += '&' + key + '=' + fieldsBase[key];
    });
    return url;
  };

  UNSAFE_componentWillMount() {
    this.props.getEntity(this.props.match.params['id']);
  }

  renderHeader() {
    const { nursingPrescriptionEntity } = this.props;
    const state = this.state;
    return (
      <>
        <div id="page-heading">
          {' '}
          <h2>
            <Translate contentKey="generadorApp.nursingPrescription.detail.title">NursingPrescription</Translate>
            <small>&nbsp; {nursingPrescriptionEntity['start']}</small>
          </h2>
        </div>

        <div className="pull-right">
          <Button tag={Link} to={'/nursing-prescription?' + getEntityFiltersURL(this.state.fieldsBase)} replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.nursingPrescription.btnBack">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/nursing-prescription/${nursingPrescriptionEntity.id}/edit?+${getEntityFiltersURL(this.state.fieldsBase)}`}
            replace
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.nursingPrescription.btnEdit">Edit</Translate>
            </span>
          </Button>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>

          <li className="breadcrumb-item active">
            <Link to={'/nursing-prescription'}>Nursing Prescriptions</Link>
          </li>
          <li className="breadcrumb-item active">Nursing Prescriptions details</li>
        </ol>
      </>
    );
  }

  renderBody() {
    const { nursingPrescriptionEntity } = this.props;
    const state = this.state;
    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            <Row className="jh-entity-details">
              <Col className="view-detail-row nursing-prescription-description-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top nursing-prescription-description-detail-label" md="12">
                    <dt>
                      <span id="description">
                        <Translate contentKey="generadorApp.nursingPrescription.description">Description</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top nursing-prescription-description-detail-value" md="12">
                    <dd>{nursingPrescriptionEntity.description}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row nursing-prescription-start-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top nursing-prescription-start-detail-label" md="12">
                    <dt>
                      <span id="start">
                        <Translate contentKey="generadorApp.nursingPrescription.start">Start</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top nursing-prescription-start-detail-value" md="12">
                    <dd>{nursingPrescriptionEntity.start ? moment(nursingPrescriptionEntity.start).format(APP_DATE_FORMAT) : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row nursing-prescription-startTime-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top nursing-prescription-startTime-detail-label" md="12">
                    <dt>
                      <span id="startTime">
                        <Translate contentKey="generadorApp.nursingPrescription.startTime">Start Time</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top nursing-prescription-startTime-detail-value" md="12">
                    <dd>{nursingPrescriptionEntity.startTime}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row nursing-prescription-patient-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top nursing-prescription-patient-detail-label" md="12">
                    <dt>
                      <Translate contentKey="generadorApp.nursingPrescription.patient">Patient</Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top nursing-prescription-patient-detail-value" md="12">
                    <dd>{nursingPrescriptionEntity.patient ? nursingPrescriptionEntity.patient.id : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row nursing-prescription-frequency-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top nursing-prescription-frequency-detail-label" md="12">
                    <dt>
                      <span id="frequency">
                        <Translate contentKey="generadorApp.nursingPrescription.frequency">Frequency</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top nursing-prescription-frequency-detail-value" md="12">
                    <dd>
                      {translate(
                        'generadorApp.QuoteFrequency.' +
                          (nursingPrescriptionEntity.frequency ? nursingPrescriptionEntity.frequency : 'UMXSEMANA')
                      )}
                    </dd>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { nursingPrescriptionEntity } = this.props;
    const state = this.state;
    return (
      <div>
        <Panel className="nursing-prescription nursing-prescription-detail">
          <PanelHeader>{this.renderHeader()}</PanelHeader>

          <PanelBody>{this.renderBody()}</PanelBody>
        </Panel>
      </div>
    );
  }
}

export const mapStateToProps = ({ nursingPrescription, ...storeState }: IRootState) => ({
  nursingPrescriptionEntity: nursingPrescription.entity,
});

export const mapDispatchToProps = {
  getEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(NursingPrescriptionDetail);
