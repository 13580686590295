import React from 'react';
import { Link } from 'react-router-dom';
import { NavbarBrand } from 'reactstrap';
import WhiteLabel from 'server/src/domain/white-label.entity';
export interface ISidebarNavProfileState {
  profileActive: boolean;
}

export interface ISidebarNavProfileProps {
  userAccount: any;
  whiteLabel: WhiteLabel;
}

class SidebarProfile extends React.Component<ISidebarNavProfileProps, ISidebarNavProfileState> {
  constructor(props) {
    super(props);
    this.state = {
      profileActive: false,
    };
    this.handleProfileExpand = this.handleProfileExpand.bind(this);
  }

  handleProfileExpand(e) {
    e.preventDefault();
    this.setState(() => ({
      profileActive: !this.state.profileActive,
    }));
  }

  render() {
    const pageSidebarMinify = true;
    return (
      <ul className="nav">
        <li className={'nav-profile p-t-10 p-b-30' + (this.state.profileActive ? 'expand ' : '')}>
          <span onClick={this.handleProfileExpand}>
            <NavbarBrand tag={Link} to="/" className="brand-logo">
              <img
                src={`${
                  this.props.whiteLabel && this.props.whiteLabel.logo
                    ? Buffer.from(this.props.whiteLabel.logo).toString()
                    : '/content/images/logo.svg'
                }`}
                style={{ maxWidth: '100%' }}
              />
            </NavbarBrand>
            <div className="info">
              {/* <b className="caret pull-right"></b> */}
              Olá {this.props.userAccount.login}
              <small>
                {this.props.userAccount.firstName} {this.props.userAccount.lastName}
              </small>
            </div>
          </span>
        </li>
        <li>
          <ul className={'nav nav-profile ' + (this.state.profileActive && !pageSidebarMinify ? 'd-block ' : '')}>
            <li>
              <Link to="/">
                <i className="fa fa-cog"></i> Settings
              </Link>
            </li>
            <li>
              <Link to="/">
                <i className="fa fa-pencil-alt"></i> Send Feedback
              </Link>
            </li>
            <li>
              <Link to="/">
                <i className="fa fa-question-circle"></i> Helps
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    );
  }
}

export default SidebarProfile;
