import React from 'react';
import axios from 'axios';
import { AvGroup } from 'availity-reactstrap-validation';
import { Row, Col, Label } from 'reactstrap';
import Select from 'react-select';
import { Translate, translate } from 'app/config/translate-component';
import { BASE_API_VERSION_PATH } from 'app/config/constants';
export interface IQuoteProps {
  baseState?: any;
  baseProps?: any;
  setState?: any;
  entityBase?: any;
}

const QuoteComponentCustomize = (props: IQuoteProps) => {
  return (
    <AvGroup>
      <Row>
        <Col md="12">
          <Label className="mt-2 label-single-line" id="sourceLabel" for="quote-source">
            <Translate contentKey="generadorApp.quote.source">Source</Translate>
          </Label>
        </Col>
        <Col md="12">
          <Select
            id="quote-source"
            className={'css-select-control'}
            value={
              props.baseState.sourceSelectValue
                ? {
                    value: props.baseState.sourceSelectValue,
                    label: translate('generadorApp.QuoteSource.' + props.baseState.sourceSelectValue),
                  }
                : { value: '', label: translate('generadorApp.quote.source') }
            }
            options={[
              { value: 'AVALIACAO', label: translate('generadorApp.QuoteSource.AVALIACAO') },
              { value: 'IMPLANTACAO', label: translate('generadorApp.QuoteSource.IMPLANTACAO') },
              { value: 'CONTROLEDECUSTO', label: translate('generadorApp.QuoteSource.CONTROLEDECUSTO') },
              { value: 'ADITIVO', label: translate('generadorApp.QuoteSource.ADITIVO') },
              { value: 'PRORROGADO', label: translate('generadorApp.QuoteSource.PRORROGADO') },
            ]}
            onChange={(options: any) => {
              axios.put(BASE_API_VERSION_PATH + 'quotes', { id: props.entityBase.id, source: options['value'] });
              return props.setState({ sourceSelectValue: options['value'] });
            }}
            name="source"
          />
        </Col>
      </Row>
    </AvGroup>
  );
};

const mapStateToProps = storeState => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default QuoteComponentCustomize;
