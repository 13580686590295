import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ProductService from './product-service';

import ProductServiceUpdate from './product-service';

import ProductServiceStatusHistory from '../product-service-status-history/index';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute path={`${match.path}/:idProductService/product-service-status-history`} component={ProductServiceStatusHistory} />

      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={ProductServiceUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={ProductServiceUpdate} />
      <ErrorBoundaryRoute path={match.path} component={ProductService} />
    </Switch>

    <Switch>
      {/* <ErrorBoundaryRoute path={`${match.path}/:idProductService/product-service-status-history`} component={ProductServiceStatusHistory} /> */}
    </Switch>
  </>
);

export default Routes;
