import moment from 'moment';

import {
  APP_LOCAL_DATETIME_FORMAT,
  APP_LOCAL_DATETIME_FORMAT_Z,
  APP_LOCAL_DATE_FORMAT,
  APP_LOCAL_DATE_FORMAT_SERVER,
} from 'app/config/constants';

export const convertDateTimeFromServer = date => (date ? moment(date).format(APP_LOCAL_DATETIME_FORMAT) : null);
export const convertDateFromServer = date => (date ? moment(date).format(APP_LOCAL_DATE_FORMAT) : null);
export const convertDateFromServerFormat = (date, format) => (date ? moment(date).format(format) : null);

export const convertDateTimeToServer = date => (date ? moment(date, APP_LOCAL_DATETIME_FORMAT_Z).toDate() : null);
export const convertDateToServer = date => (date ? moment(date, APP_LOCAL_DATE_FORMAT_SERVER).toDate() : null);

export const displayDefaultDateTime = () => moment().startOf('day').format(APP_LOCAL_DATETIME_FORMAT);
