import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import FixedCost from './fixed-cost';

import FixedCostDetail from './fixed-cost-detail';

import FixedCostUpdate from './fixed-cost';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={FixedCostUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={FixedCostUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={FixedCostDetail} />
      <ErrorBoundaryRoute path={match.path} component={FixedCost} />
    </Switch>
  </>
);

export default Routes;
