/* eslint complexity: ["error", 500] */
import React from 'react';
import axios from 'axios';
import { convertDateTimeToServer } from 'app/shared/util/date-utils';
import CurrencyInput from 'react-currency-input';
import { connect } from 'react-redux';

import {
  getFilterFromSelect,
  getFormFromSelect,
  getListAxios,
  getValueRecursive,
  showFieldsSelectAsync,
  checkAnyValueFields,
} from 'app/shared/util/entity-utils';
import Select from 'react-select';
import { toast } from 'react-toastify';
import SelectAsync from 'react-select/async';
import { Link, RouteComponentProps } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { Button, Col, Row, Table, Label, UncontrolledCollapse, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { setFileData, JhiItemCount } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import FrequencyDescriptionTable from './components/FrequencyDescriptionTable';
import FrequencyDescriptionComponent from './components/FrequencyDescriptionComponent';

import { IRootState } from 'app/shared/reducers';
import {
  updateEntity,
  getUrlBack,
  getPatDrPrescrProcdState,
  IPatDrPrescrProcdBaseState,
  getEntityFiltersURL,
  getEntities,
  IPatDrPrescrProcdUpdateState as IBaseUpdateState,
  reset,
  getEntity,
  deleteEntity,
  createEntity,
  setBlob,
} from './pat-dr-prescr-procd.reducer';

import { ITEMS_PER_PAGE, IPaginationBaseState, getSortState } from 'app/shared/util/pagination.constants';

import { StatePropsExtended, DispatchPropsExtended, IExtendedState, IExtendedProps } from './extended/pat-dr-prescr-procd';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';
import moment from 'moment';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

export interface IPatDrPrescrProcdProps
  extends StateProps,
    DispatchProps,
    StatePropsExtended,
    DispatchPropsExtended,
    IExtendedProps,
    RouteComponentProps<{ url: string }> {
  entityEmbebedPai?: any;
}

export interface IPatDrPrescrProcdState extends IPatDrPrescrProcdBaseState, IPaginationBaseState, IBaseUpdateState, IExtendedState {
  showModalForm?: boolean;
  showModalEdit?: number;
  showModalEditView?: boolean;
}

export class PatDrPrescrProcd extends React.Component<IPatDrPrescrProcdProps, IPatDrPrescrProcdState> {
  protected myFormRef: any;
  protected myFormRefNew: any;
  protected myFormRefEdit: any;

  orderFileInput: any;

  initialHourFileInput: any;

  frequencyFileInput: any;

  frequencyDescriptionFileInput: any;

  initialDateFileInput: any;

  finalDateFileInput: any;

  continuousFileInput: any;

  doseFileInput: any;

  viaFileInput: any;

  observationsFileInput: any;

  statusFileInput: any;

  otherMedicineFileInput: any;

  medicineNameFileInput: any;

  constructor(props: IPatDrPrescrProcdProps) {
    super(props);
    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      listCheckedID: [],
      isCheckedAll: false,
      showModalForm: false,
      showModalEdit: null,
      urlBack: getUrlBack(this.props.location),
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      ...getPatDrPrescrProcdState(this.props.location),
      patientDoctorPrescriptionId: [
        {
          ...(this.props.entityEmbebedPai ? this.props.entityEmbebedPai : {}),
          value: this.props.match.params['idPatientDoctorPrescription'],
          label: this.props.match.params['idPatientDoctorPrescription'],
        },
      ],
      baseFilters: 'patientDoctorPrescription',
      drStatusHistorySelectValue: null,

      patientDoctorPrescriptionSelectValue: {
        ...(this.props.entityEmbebedPai ? this.props.entityEmbebedPai : {}),
        value: this.props.match.params['idPatientDoctorPrescription'],
        label: this.props.match.params['idPatientDoctorPrescription'],
      },

      medicineMaterialSelectValue: null,

      orderSelectValue: 0,
      initialHourSelectValue: null,
      frequencySelectValue: null,
      frequencyDescriptionSelectValue: null,
      initialDateSelectValue: null,
      finalDateSelectValue: null,
      continuousSelectValue: null,
      doseSelectValue: null,
      viaSelectValue: null,
      observationsSelectValue: null,
      statusSelectValue: null,
      otherMedicineSelectValue: null,
      medicineNameSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getPatDrPrescrProcdState(this.props.location),
        patientDoctorPrescriptionId: [
          {
            ...(this.props.entityEmbebedPai ? this.props.entityEmbebedPai : {}),
            value: this.props.match.params['idPatientDoctorPrescription'],
            label: this.props.match.params['idPatientDoctorPrescription'],
          },
        ],
        baseFilters: 'patientDoctorPrescription',
      },
      medicineMaterialName: null,
      isNew: false,
    };

    axios
      .get('/api/patient-doctor-prescriptions/' + this.props.match.params['idPatientDoctorPrescription'], {
        headers: {
          'Select-Fields': 'id',
        },
      })
      .then(result => {
        this.setState({
          patientDoctorPrescriptionSelectValue: {
            ...result['data'],
            value: this.props.match.params['idPatientDoctorPrescription'],
            label: showFieldsSelectAsync(result['data'], 'id'),
          },
        });
      });
  }

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({ listCheckedID: this.props.patDrPrescrProcdList.map(patDrPrescrProcd => patDrPrescrProcd.id) });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({ listCheckedID: this.props.patDrPrescrProcdList.map(patDrPrescrProcd => patDrPrescrProcd.id) });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  removeEntitySelected() {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  }

  saveEntityMany = () => {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  };

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  cancelFilters = () => {
    this.setState(
      {
        createdDateStart: '',
        createdDateEnd: '',
        lastModifiedDateStart: '',
        lastModifiedDateEnd: '',
        status: '',
        patientDoctorPrescriptionId: '',
        medicineMaterialId: '',
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
        activePage: 0,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();

    const selectFieldsList = ['order', 'medicineMaterial.name', 'frequency', 'observations', 'status', 'patientDoctorPrescription.id'];
    const {
      createdDateStart,
      createdDateEnd,
      lastModifiedDateStart,
      lastModifiedDateEnd,
      status,
      patientDoctorPrescriptionId,
      medicineMaterialId,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;
    return this.props.getEntities(
      createdDateStart,
      createdDateEnd,
      lastModifiedDateStart,
      lastModifiedDateEnd,
      status,
      getFilterFromSelect(patientDoctorPrescriptionId, 'many-to-one'),
      getFilterFromSelect(medicineMaterialId, 'many-to-one'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(',')
    );
  };

  updateEntity = entity => {
    const selectFieldsList = ['order', 'medicineMaterial.name', 'frequency', 'observations', 'status', 'patientDoctorPrescription.id'];

    const {
      createdDateStart,
      createdDateEnd,
      lastModifiedDateStart,
      lastModifiedDateEnd,
      status,
      patientDoctorPrescriptionId,
      medicineMaterialId,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;
    this.props.updateEntity(entity, [
      createdDateStart,
      createdDateEnd,
      lastModifiedDateStart,
      lastModifiedDateEnd,
      status,
      getFilterFromSelect(patientDoctorPrescriptionId, 'many-to-one'),
      getFilterFromSelect(medicineMaterialId, 'many-to-one'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(','),
    ]);
  };

  onBlobChange = (isAnImage, name, fileInput) => event => {
    const fileName = event.target.files[0].name;
    setFileData(event, (contentType, data) => this.props.setBlob(name, data, contentType, fileName), isAnImage);
    const varAux = {};
    varAux[name + 'SelectValue'] = fileName;
    this.setState(varAux);
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined);
  };

  saveEntity = (event: any, errors: any, values: any, isNew: any) => {
    const selectFieldsList = ['order', 'medicineMaterial.name', 'frequency', 'observations', 'status', 'patientDoctorPrescription.id'];

    values.initialDate = convertDateTimeToServer(values.initialDate);
    values.finalDate = convertDateTimeToServer(values.finalDate);

    if (errors.length === 0) {
      const { patDrPrescrProcdEntity } = this.props;
      const _entity = {
        ...patDrPrescrProcdEntity,
        // ...values,

        medicineMaterial: getFormFromSelect(this.state.medicineMaterialSelectValue, 'many-to-one'), // many-to-one - otherSide

        patientDoctorPrescription: getFormFromSelect(this.state.patientDoctorPrescriptionSelectValue, 'many-to-one'), // many-to-one - otherSide

        order: this.state.orderSelectValue === null ? 0 : this.state.orderSelectValue,
        initialHour: this.state.initialHourSelectValue,
        frequency: this.state.frequencySelectValue === null ? null : this.state.frequencySelectValue,
        frequencyDescription: this.state.frequencyDescriptionSelectValue,
        initialDate: this.state.initialDateSelectValue,
        finalDate: this.state.finalDateSelectValue,
        continuous: this.state.continuousSelectValue === null ? false : this.state.continuousSelectValue,
        dose: this.state.doseSelectValue,
        via: this.state.viaSelectValue === null ? null : this.state.viaSelectValue,
        observations: this.state.observationsSelectValue,
        status: this.state.statusSelectValue === null ? 'INATIVADO' : this.state.statusSelectValue,
        otherMedicine: this.state.otherMedicineSelectValue === null ? false : this.state.otherMedicineSelectValue,
        medicineName: this.state.medicineNameSelectValue,
      };
      const entity = _entity;

      const {
        createdDateStart,
        createdDateEnd,
        lastModifiedDateStart,
        lastModifiedDateEnd,
        status,
        patientDoctorPrescriptionId,
        medicineMaterialId,
        extraFilters,
        activePage,
        itemsPerPage,
        sort,
        order,
      } = this.state.fieldsBase;

      if (isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              createdDateStart,
              createdDateEnd,
              lastModifiedDateStart,
              lastModifiedDateEnd,
              status,
              getFilterFromSelect(patientDoctorPrescriptionId, 'many-to-one'),
              getFilterFromSelect(medicineMaterialId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              createdDateStart,
              createdDateEnd,
              lastModifiedDateStart,
              lastModifiedDateEnd,
              status,
              getFilterFromSelect(patientDoctorPrescriptionId, 'many-to-one'),
              getFilterFromSelect(medicineMaterialId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
      this.setState({ showModalForm: false });
      this.setState({ showModalEdit: null });
    } else {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.specialty.' + errors[0]) }));
    }
  };

  componentDidUpdate(prevProps) {}

  deleteEntity(patDrPrescrProcdEntity) {
    MySwal.fire({
      title: <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>,
      html: (
        <Translate contentKey="generadorApp.patDrPrescrProcd.delete.question" interpolate={{ id: patDrPrescrProcdEntity.id }}>
          Are you sure you want to delete this PatDrPrescrProcd?
        </Translate>
      ),
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#e70015',
      cancelButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="ban" />
          &nbsp; <Translate contentKey="generadorApp.patDrPrescrProcd.btnCancel">Cancel</Translate>
        </>
      ),
      confirmButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="trash" />
          &nbsp;<Translate contentKey="generadorApp.patDrPrescrProcd.btnDelete">Delete</Translate>
        </>
      ),
    }).then(async response => {
      if (response.isConfirmed) {
        await this.props.deleteEntity(patDrPrescrProcdEntity.id, { reloadList: false });
        this.getEntities();
        MySwal.fire({
          title: <Translate contentKey="entity.delete.title.confirmed">Confirmed delete operation</Translate>,
          icon: 'success',
        });
      }
    });
  }

  renderNewModal() {
    const { entityEmbebedPai, drStatusHistories, patientDoctorPrescriptions, medicineMaterials, loading, patDrPrescrProcdList} = this.props;
    const find = patDrPrescrProcdList?.find(element => element.order === this.state.orderSelectValue)

    const checkValue = () => {
        if(find){
          return true
        } else if(this.state.medicineMaterialSelectValue === null || this.state.medicineMaterialSelectValue === ''){
          return true
        }else if(this.state.doseSelectValue === null || this.state.doseSelectValue === ''){
          return true
        }else if(this.state.viaSelectValue === null || this.state.viaSelectValue === ''){
          return true
        }else if(this.state.frequencySelectValue === null || this.state.frequencySelectValue === ''){
          return true
        }else if(this.state.initialDateSelectValue === null || this.state.initialDateSelectValue === ''){
          return true
        }else if(this.state.initialHourSelectValue === null || this.state.initialHourSelectValue === ''){
          return true
        }else{
          return false
        }
    }
    
    const checkValueToast = () => {
      if(find){
        return "Order já cadastrado, escolha outro"
      } else if(this.state.medicineMaterialSelectValue === null || this.state.medicineMaterialSelectValue === ''){
        return "Preencha os campos obrigatórios"
      }else if(this.state.doseSelectValue === null || this.state.doseSelectValue === ''){
        return "Preencha os campos obrigatórios"
      }else if(this.state.viaSelectValue === null || this.state.viaSelectValue === ''){
        return "Preencha os campos obrigatórios"
      }else if(this.state.frequencySelectValue === null || this.state.frequencySelectValue === ''){
        return "Preencha os campos obrigatórios"
      }else if(this.state.initialDateSelectValue === null || this.state.initialDateSelectValue === ''){
        return "Preencha os campos obrigatórios"
      }else if(this.state.initialHourSelectValue === null || this.state.initialHourSelectValue === ''){
        return "Preencha os campos obrigatórios"
      }else{
        return ""
      }
  }

    return (
      <Modal size={'xl'} isOpen={this.state.showModalForm} toggle={() => this.setState({ showModalForm: false })}>
        <ModalHeader toggle={() => this.setState({ showModalForm: false })}>{this.renderHeaderUpdate({}, true)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefNew = el)}
          model={{}}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, true)}
        >
          <ModalBody>{this.renderBodyUpdate({}, true)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalForm: false })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.patDrPrescrProcd.btnCancel">Cancel</Translate>
            </Button>
            {checkValue() ?
            <Button
            color="#a3a3a3"
            id="save-entity"
            onClick={() =>  toast.error(checkValueToast())}
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.patDrPrescrProcd.btnSave">Save</Translate>
            </Button>
            
            :
            
            <Button
            color="primary"
            id="save-entity"
            onClick={() => this.myFormRefNew && this.myFormRefNew.submit()}
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.patDrPrescrProcd.btnSave">Save</Translate>
            </Button>
            
            }
          </ModalFooter>
        </AvForm>
      </Modal>
    );
  }

  getFullEntityFromState(v) {
    return {
      id: v.id,
      order: this.state.orderSelectValue === null ? 0 : this.state.orderSelectValue,
      initialHour: this.state.initialHourSelectValue,
      frequency: this.state.frequencySelectValue,
      frequencyDescription: this.state.frequencyDescriptionSelectValue,
      initialDate: this.state.initialDateSelectValue,
      finalDate: this.state.finalDateSelectValue,
      continuous: this.state.continuousSelectValue,
      dose: this.state.doseSelectValue,
      via: this.state.viaSelectValue,
      observations: this.state.observationsSelectValue,
      status: this.state.statusSelectValue,
      otherMedicine: this.state.otherMedicineSelectValue,
      medicineName: this.state.medicineNameSelectValue,
      drStatusHistory: this.state.drStatusHistorySelectValue,
      patientDoctorPrescription: this.state.patientDoctorPrescriptionSelectValue,
      medicineMaterial: this.state.medicineMaterialSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    let p: any = null;

    const drStatusHistoryEntity = v.drStatusHistory
      ? v.drStatusHistory.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' }))
      : v.drStatusHistory;

    p = v.patientDoctorPrescription;
    const patientDoctorPrescriptionEntity = p ? { ...p, value: p.id, label: p['id'] ? p.id : '' } : p;

    p = v.medicineMaterial;
    const medicineMaterialEntity = p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : p;

    return {
      orderSelectValue: v.order === null ? 0 : v.order, // order,
      initialHourSelectValue: v.initialHour, // initialHour,
      frequencySelectValue: v.frequency, // frequency,
      frequencyDescriptionSelectValue: v.frequencyDescription, // frequencyDescription,
      initialDateSelectValue: v.initialDate ? moment(v.initialDate).toDate() : null, // Instant,
      finalDateSelectValue: v.finalDate ? moment(v.finalDate).toDate() : null, // Instant,
      continuousSelectValue: v.continuous, // continuous,
      doseSelectValue: v.dose, // dose,
      viaSelectValue: v.via, // via,
      observationsSelectValue: v.observations, // observations,
      statusSelectValue: v.status, // status,
      otherMedicineSelectValue: v.otherMedicine, // otherMedicine,
      medicineNameSelectValue: v.medicineName, // medicineName,
      drStatusHistorySelectValue: drStatusHistoryEntity,
      patientDoctorPrescriptionSelectValue: patientDoctorPrescriptionEntity,
      medicineMaterialSelectValue: medicineMaterialEntity,
    };
  }

  openUpdateModal(v, edit = true) {
    this.props.getEntity(v.id);
    this.setState({
      showModalEdit: v.id,
      showModalEditView: edit,
      ...this.getFullEntitySelectValue(v),
    });
  }

  renderUpdateModal() {
    const { patDrPrescrProcdEntity, entityEmbebedPai } = this.props;
    return patDrPrescrProcdEntity && patDrPrescrProcdEntity.id ? (
      <Modal
        size={'xl'}
        isOpen={this.state.showModalEdit === patDrPrescrProcdEntity.id}
        toggle={() => this.setState({ showModalEdit: null })}
      >
        <ModalHeader toggle={() => this.setState({ showModalEdit: null })}>
          {this.renderHeaderUpdate(patDrPrescrProcdEntity, false)}
        </ModalHeader>
        <AvForm
          ref={el => (this.myFormRefEdit = el)}
          model={{
            ...patDrPrescrProcdEntity,
          }}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, false)}
        >
          <ModalBody>{this.renderBodyUpdate(patDrPrescrProcdEntity, false)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalEdit: null })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.patDrPrescrProcd.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefEdit && this.myFormRefEdit.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.patDrPrescrProcd.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    ) : (
      <> </>
    );
  }

  renderHeaderUpdate(patDrPrescrProcdEntity, isNew) {
    const { entityEmbebedPai, drStatusHistories, patientDoctorPrescriptions, medicineMaterials, loading } = this.props;

    const { frequencyDescription } = patDrPrescrProcdEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {isNew ? (
              <Translate contentKey="generadorApp.patDrPrescrProcd.home.createLabel">Create a PatDrPrescrProcd</Translate>
            ) : (
              <Translate contentKey="generadorApp.patDrPrescrProcd.home.editLabel">Edit a PatDrPrescrProcd</Translate>
            )}
          </span>
        </div>
      </>
    );
  }
  renderBodyUpdate(patDrPrescrProcdEntity, isNew) {
    const { entityEmbebedPai, drStatusHistories, patientDoctorPrescriptions, medicineMaterials, loading, patDrPrescrProcdList} = this.props;

    const { frequencyDescription } = patDrPrescrProcdEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
              <Col md="3">
              <Label className="mt-2 label-single-line"for="pat-dr-prescr-procd-id">
                <Translate contentKey="generadorApp.patDrPrescrProcd.field_id">ID</Translate>
              </Label>
              </Col> */}
                      <Col md="12">
                        <AvInput id="pat-dr-prescr-procd-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'order' ? (
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="orderLabel" for="pat-dr-prescr-procd-order">
                              <Translate contentKey="generadorApp.patDrPrescrProcd.order">Order</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ orderSelectValue: floatvalue })}
                              decimalSeparator=""
                              thousandSeparator=""
                              precision={0}
                              prefix=""
                              allowNegative={true}
                              value={this.state.orderSelectValue === null ? 0 : this.state.orderSelectValue}
                              id="pat-dr-prescr-procd-order"
                              name="order"
                              className={'form-control'}
                            />
                       
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ orderSelectValue: evt.target.value })}
                      type="hidden"
                      name="order"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'otherMedicine' ? (
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="otherMedicineLabel" for="pat-dr-prescr-procd-otherMedicine">
                              <Translate contentKey="generadorApp.patDrPrescrProcd.otherMedicine">Other Medicine</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'otherMedicine_checkbox'}
                                id={'otherMedicine_checkbox'}
                                checked={this.state.otherMedicineSelectValue}
                                onChange={evt => this.setState({ otherMedicineSelectValue: !this.state.otherMedicineSelectValue })}
                              />
                              <label htmlFor={'otherMedicine_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ otherMedicineSelectValue: evt.target.value })}
                      type="hidden"
                      name="otherMedicine"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {!checkAnyValueFields(this.state, 'otherMedicineSelectValue', true, true) && baseFilters !== 'medicineMaterial' ? (
                    <Col md="5">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="pat-dr-prescr-procd-medicineMaterial">
                              <Translate contentKey="generadorApp.patDrPrescrProcd.medicineMaterial">Medicine Material</Translate>
                            </Label>
                          </Col>
                          {this.state.medicineMaterialSelectValue === null || this.state.medicineMaterialSelectValue === '' ?      
                          <Col md="12">
                          <SelectAsync
                            id="pat-dr-prescr-procd-medicineMaterial"
                            name={'medicineMaterial'}
                            className={'css-select-control'}
                            data-type-rel="many-to-one-other-side"
                            value={this.state.medicineMaterialSelectValue ? this.state.medicineMaterialSelectValue : ''}
                            onChange={options => this.setState({ medicineMaterialSelectValue: options })}
                            defaultOptions={
                              this.state.medicineMaterialStartSelectOptions ? this.state.medicineMaterialStartSelectOptions : []
                            }
                            loadingMessage={input => translate('selectAsync.loadingMessage')}
                            noOptionsMessage={input =>
                              this.state.medicineMaterialStartSelectOptions === undefined
                                ? translate('selectAsync.loadingMessage')
                                : translate('selectAsync.noOptionsMessage')
                            }
                            onMenuOpen={async () => {
                              if (this.state.medicineMaterialStartSelectOptions === undefined) {
                                const result = await getListAxios(
                                  'medicine-materials',
                                  { 'medicineMaterialType.in': 'MEDICAMENTO,DIETA' },
                                  0,
                                  100,
                                  'name,asc',
                                  'name'
                                );
                                this.setState({
                                  medicineMaterialStartSelectOptions: result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : [],
                                });
                              }
                            }}
                            loadOptions={async (inputValue, callback) => {
                              const result = await getListAxios(
                                'medicine-materials',
                                { 'medicineMaterialType.in': 'MEDICAMENTO,DIETA', 'name.contains': inputValue },
                                0,
                                100,
                                'name,asc',
                                'name'
                              );
                              callback(
                                result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                  : []
                              );
                            }}
                          />
                        <text style={{color:"red"}}>* campo obrigatório</text>
                        </Col>
                        

                          :
                          
                          <Col md="12">
                          <SelectAsync
                            id="pat-dr-prescr-procd-medicineMaterial"
                            name={'medicineMaterial'}
                            className={'css-select-control'}
                            data-type-rel="many-to-one-other-side"
                            value={this.state.medicineMaterialSelectValue ? this.state.medicineMaterialSelectValue : ''}
                            onChange={options => this.setState({ medicineMaterialSelectValue: options })}
                            defaultOptions={
                              this.state.medicineMaterialStartSelectOptions ? this.state.medicineMaterialStartSelectOptions : []
                            }
                            loadingMessage={input => translate('selectAsync.loadingMessage')}
                            noOptionsMessage={input =>
                              this.state.medicineMaterialStartSelectOptions === undefined
                                ? translate('selectAsync.loadingMessage')
                                : translate('selectAsync.noOptionsMessage')
                            }
                            onMenuOpen={async () => {
                              if (this.state.medicineMaterialStartSelectOptions === undefined) {
                                const result = await getListAxios(
                                  'medicine-materials',
                                  { 'medicineMaterialType.in': 'MEDICAMENTO,DIETA' },
                                  0,
                                  100,
                                  'name,asc',
                                  'name'
                                );
                                this.setState({
                                  medicineMaterialStartSelectOptions: result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : [],
                                });
                              }
                            }}
                            loadOptions={async (inputValue, callback) => {
                              const result = await getListAxios(
                                'medicine-materials',
                                { 'medicineMaterialType.in': 'MEDICAMENTO,DIETA', 'name.contains': inputValue },
                                0,
                                100,
                                'name,asc',
                                'name'
                              );
                              callback(
                                result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                  : []
                              );
                            }}
                          />
                        </Col>
                        
                        }
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="medicineMaterial"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}
                  {this.state.otherMedicineSelectValue === true && baseFilters !== 'medicineName' ? (
                    <Col md="4">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="medicineNameLabel" for="pat-dr-prescr-procd-medicineName">
                              <Translate contentKey="generadorApp.patDrPrescrProcd.medicineName">Medicine name</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ medicineNameSelectValue: evt.target.value })}
                              value={this.state.medicineNameSelectValue ? this.state.medicineNameSelectValue : ''}
                              id="pat-dr-prescr-procd-medicineName"
                              type="text"
                              name="medicineName"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ medicineNameSelectValue: evt.target.value })}
                      type="hidden"
                      name="medicineName"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'dose' ? (
                    <Col md="1">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="doseLabel" for="pat-dr-prescr-procd-dose">
                              <Translate contentKey="generadorApp.patDrPrescrProcd.dose">Dose</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                          {this.state.doseSelectValue === null || this.state.doseSelectValue === '' ?  
                          <>
                          <input
                          onChange={evt => this.setState({ doseSelectValue: evt.target.value })}
                          value={this.state.doseSelectValue ? this.state.doseSelectValue : ''}
                          id="pat-dr-prescr-procd-dose"
                          type="text"
                          name="dose"
                          className={'form-control'}
                        />
                        <text style={{color:"red"}}>* campo obrigatório</text>
                        </>
                          : 
                          <input
                          onChange={evt => this.setState({ doseSelectValue: evt.target.value })}
                          value={this.state.doseSelectValue ? this.state.doseSelectValue : ''}
                          id="pat-dr-prescr-procd-dose"
                          type="text"
                          name="dose"
                          className={'form-control'}
                        />
                          }
              
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ doseSelectValue: evt.target.value })}
                      type="hidden"
                      name="dose"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'via' ? (
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="viaLabel" for="pat-dr-prescr-procd-via">
                              <Translate contentKey="generadorApp.patDrPrescrProcd.via">Via</Translate>
                            </Label>
                          </Col>
                          <Col md="12">

                          {this.state.viaSelectValue === null || this.state.viaSelectValue === '' ?  
                          <>
                              <Select
                          id="pat-dr-prescr-procd-via"
                          className={'css-select-control'}
                          value={
                            this.state.viaSelectValue
                              ? { value: this.state.viaSelectValue, label: translate('generadorApp.Via.' + this.state.viaSelectValue) }
                              : { value: '', label: translate('generadorApp.patDrPrescrProcd.via') }
                          }
                          options={[
                            { value: 'INJECAO', label: translate('generadorApp.Via.INJECAO') },
                            { value: 'ORAL', label: translate('generadorApp.Via.ORAL') },
                            { value: 'VO', label: translate('generadorApp.Via.VO') },
                            { value: 'SUBLINGUAL', label: translate('generadorApp.Via.SUBLINGUAL') },
                            { value: 'IM', label: translate('generadorApp.Via.IM') },
                            { value: 'EV', label: translate('generadorApp.Via.EV') },
                            { value: 'SC', label: translate('generadorApp.Via.SC') },
                            { value: 'TOPICA', label: translate('generadorApp.Via.TOPICA') },
                            { value: 'ENTERAL', label: translate('generadorApp.Via.ENTERAL') },
                            { value: 'RETAL', label: translate('generadorApp.Via.RETAL') },
                            { value: 'INTRADERMICA', label: translate('generadorApp.Via.INTRADERMICA') },
                            { value: 'OCULAR', label: translate('generadorApp.Via.OCULAR') },
                            { value: 'AURICULAR', label: translate('generadorApp.Via.AURICULAR') },
                            { value: 'SNE', label: translate('generadorApp.Via.SNE') },
                            { value: 'GTT', label: translate('generadorApp.Via.GTT') },
                            { value: 'INALATORIA', label: translate('generadorApp.Via.INALATORIA') },
                            { value: 'TQT', label: translate('generadorApp.Via.TQT') },
                            { value: 'PICC', label: translate('generadorApp.Via.PICC') },
                          ]}
                          onChange={(options: any) => this.setState({ viaSelectValue: options['value'] })}
                          name="via"
                        />
                          <text style={{color:"red"}}>* campo obrigatório</text>
                        </>
                          : 
                          <Select
                          id="pat-dr-prescr-procd-via"
                          className={'css-select-control'}
                          value={
                            this.state.viaSelectValue
                              ? { value: this.state.viaSelectValue, label: translate('generadorApp.Via.' + this.state.viaSelectValue) }
                              : { value: '', label: translate('generadorApp.patDrPrescrProcd.via') }
                          }
                          options={[
                            { value: 'IM', label: "IM" },
                            { value: 'EV', label: "EV"},
                            { value: 'VO', label:"VO" },
                            { value: 'SC', label: "SC" },
                          ]}
                          onChange={(options: any) => this.setState({ viaSelectValue: options['value'] })}
                          name="via"
                        />
                          }
                           
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ viaSelectValue: evt.target.value })}
                      type="hidden"
                      name="via"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'frequency' ? (
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="frequencyLabel" for="pat-dr-prescr-procd-frequency">
                              <Translate contentKey="generadorApp.patDrPrescrProcd.frequency">Frequency</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                          {this.state.frequencySelectValue === null || this.state.frequencySelectValue === '' ?  
                            <>
                             <Select
                              id="pat-dr-prescr-procd-frequency"
                              className={'css-select-control'}
                              value={
                                this.state.frequencySelectValue
                                  ? {
                                      value: this.state.frequencySelectValue,
                                      label: translate('generadorApp.QuoteFrequency.' + this.state.frequencySelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.patDrPrescrProcd.frequency') }
                              }
                              options={[
                                { value: 'UMXSEMANA', label: translate('generadorApp.QuoteFrequency.UMXSEMANA') },
                                { value: 'DOISXSEMANA', label: translate('generadorApp.QuoteFrequency.DOISXSEMANA') },
                                { value: 'TRESXSEMANA', label: translate('generadorApp.QuoteFrequency.TRESXSEMANA') },
                                { value: 'QUATROXSEMANA', label: translate('generadorApp.QuoteFrequency.QUATROXSEMANA') },
                                { value: 'CINCOXSEMANA', label: translate('generadorApp.QuoteFrequency.CINCOXSEMANA') },
                                { value: 'SEISXSEMANA', label: translate('generadorApp.QuoteFrequency.SEISXSEMANA') },
                                { value: 'UMXDIA', label: translate('generadorApp.QuoteFrequency.UMXDIA') },
                                { value: 'DOISXDIA', label: translate('generadorApp.QuoteFrequency.DOISXDIA') },
                                { value: 'TRESXDIA', label: translate('generadorApp.QuoteFrequency.TRESXDIA') },
                                { value: 'QUATROXDIA', label: translate('generadorApp.QuoteFrequency.QUATROXDIA') },
                                { value: 'CINCOXDIA', label: translate('generadorApp.QuoteFrequency.CINCOXDIA') },
                                { value: 'SEISXDIA', label: translate('generadorApp.QuoteFrequency.SEISXDIA') },
                                { value: 'UMXMES', label: translate('generadorApp.QuoteFrequency.UMXMES') },
                                { value: 'DOISXMES', label: translate('generadorApp.QuoteFrequency.DOISXMES') },
                                { value: 'TRESXMES', label: translate('generadorApp.QuoteFrequency.TRESXMES') },
                                { value: 'QUATROXMES', label: translate('generadorApp.QuoteFrequency.QUATROXMES') },
                                { value: 'CADA48HORAS', label: translate('generadorApp.QuoteFrequency.CADA48HORAS') },
                                { value: 'CADA72HORAS', label: translate('generadorApp.QuoteFrequency.CADA72HORAS') },
                                { value: 'PONTUAL', label: translate('generadorApp.QuoteFrequency.PONTUAL') },
                              ]}
                              onChange={(options: any) =>
                                this.setState({ frequencyDescriptionSelectValue: null, frequencySelectValue: options['value'] })
                              }
                              name="frequency"
                            />
                            <text style={{color:"red"}}>* campo obrigatório</text>
                            </>
                          :
                          <Select
                          id="pat-dr-prescr-procd-frequency"
                          className={'css-select-control'}
                          value={
                            this.state.frequencySelectValue
                              ? {
                                  value: this.state.frequencySelectValue,
                                  label: translate('generadorApp.QuoteFrequency.' + this.state.frequencySelectValue),
                                }
                              : { value: '', label: translate('generadorApp.patDrPrescrProcd.frequency') }
                          }
                          options={[
                            { value: 'UMXSEMANA', label: translate('generadorApp.QuoteFrequency.UMXSEMANA') },
                            { value: 'DOISXSEMANA', label: translate('generadorApp.QuoteFrequency.DOISXSEMANA') },
                            { value: 'TRESXSEMANA', label: translate('generadorApp.QuoteFrequency.TRESXSEMANA') },
                            { value: 'QUATROXSEMANA', label: translate('generadorApp.QuoteFrequency.QUATROXSEMANA') },
                            { value: 'CINCOXSEMANA', label: translate('generadorApp.QuoteFrequency.CINCOXSEMANA') },
                            { value: 'SEISXSEMANA', label: translate('generadorApp.QuoteFrequency.SEISXSEMANA') },
                            { value: 'UMXDIA', label: translate('generadorApp.QuoteFrequency.UMXDIA') },
                            { value: 'DOISXDIA', label: translate('generadorApp.QuoteFrequency.DOISXDIA') },
                            { value: 'TRESXDIA', label: translate('generadorApp.QuoteFrequency.TRESXDIA') },
                            { value: 'QUATROXDIA', label: translate('generadorApp.QuoteFrequency.QUATROXDIA') },
                            { value: 'CINCOXDIA', label: translate('generadorApp.QuoteFrequency.CINCOXDIA') },
                            { value: 'SEISXDIA', label: translate('generadorApp.QuoteFrequency.SEISXDIA') },
                            { value: 'UMXMES', label: translate('generadorApp.QuoteFrequency.UMXMES') },
                            { value: 'DOISXMES', label: translate('generadorApp.QuoteFrequency.DOISXMES') },
                            { value: 'TRESXMES', label: translate('generadorApp.QuoteFrequency.TRESXMES') },
                            { value: 'QUATROXMES', label: translate('generadorApp.QuoteFrequency.QUATROXMES') },
                            { value: 'CADA48HORAS', label: translate('generadorApp.QuoteFrequency.CADA48HORAS') },
                            { value: 'CADA72HORAS', label: translate('generadorApp.QuoteFrequency.CADA72HORAS') },
                            { value: 'PONTUAL', label: translate('generadorApp.QuoteFrequency.PONTUAL') },
                          ]}
                          onChange={(options: any) =>
                            this.setState({ frequencyDescriptionSelectValue: null, frequencySelectValue: options['value'] })
                          }
                          name="frequency"
                        />
                            }
                           
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ frequencyDescriptionSelectValue: null, frequencySelectValue: evt.target.value })}
                      type="hidden"
                      name="frequency"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'continuous' ? (
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="continuousLabel" for="pat-dr-prescr-procd-continuous">
                              <Translate contentKey="generadorApp.patDrPrescrProcd.continuous">Continuous</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'continuous_checkbox'}
                                id={'continuous_checkbox'}
                                checked={this.state.continuousSelectValue}
                                onChange={evt => this.setState({ continuousSelectValue: !this.state.continuousSelectValue })}
                              />
                              <label htmlFor={'continuous_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ continuousSelectValue: evt.target.value })}
                      type="hidden"
                      name="continuous"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'initialDate' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="initialDateLabel" for="pat-dr-prescr-procd-initialDate">
                              <Translate contentKey="generadorApp.patDrPrescrProcd.initialDate">Initial Date</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                          {this.state.initialDateSelectValue === null || this.state.initialDateSelectValue === '' ?  
                          <>
                            <DatePicker
                              autoComplete="off"
                              onChange={value => this.setState({ initialDateSelectValue: value })}
                              id="initialDate"
                              validate={{}}
                              className="form-control react-datepicker"
                              name="initialDate"
                              dateFormat="dd/MM/yyyy"
                              selected={this.state.initialDateSelectValue ? moment(this.state.initialDateSelectValue).toDate() : null}
                              locale="pt"
                            />
                          <text style={{color:"red"}}>* campo obrigatório</text>
                          </>
                          :
                          <DatePicker
                          autoComplete="off"
                          onChange={value => this.setState({ initialDateSelectValue: value })}
                          id="initialDate"
                          validate={{}}
                          className="form-control react-datepicker"
                          name="initialDate"
                          dateFormat="dd/MM/yyyy"
                          selected={this.state.initialDateSelectValue ? moment(this.state.initialDateSelectValue).toDate() : null}
                          locale="pt"
                        />

                          }
              
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ initialDateSelectValue: evt.target.value })}
                      type="hidden"
                      name="initialDate"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'initialHour' ? (
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="initialHourLabel" for="pat-dr-prescr-procd-initialHour">
                              <Translate contentKey="generadorApp.patDrPrescrProcd.initialHour">Hour</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                          {this.state.initialHourSelectValue === null || this.state.initialHourSelectValue === '' ?  
                            <>
                              <InputMask
                              onChange={evt => this.setState({ initialHourSelectValue: evt.target.value })}
                              value={this.state.initialHourSelectValue ? this.state.initialHourSelectValue : ''}
                              id="pat-dr-prescr-procd-initialHour"
                              type="text"
                              name="initialHour"
                              className={'form-control'}
                              mask="99:99"
                            />
                            <text style={{color:"red"}}>* campo obrigatório</text>
                            </>
                            :

                            <InputMask
                            onChange={evt => this.setState({ initialHourSelectValue: evt.target.value })}
                            value={this.state.initialHourSelectValue ? this.state.initialHourSelectValue : ''}
                            id="pat-dr-prescr-procd-initialHour"
                            type="text"
                            name="initialHour"
                            className={'form-control'}
                            mask="99:99"
                          />

                            }
           
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ initialHourSelectValue: evt.target.value })}
                      type="hidden"
                      name="initialHour"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {this.state.continuousSelectValue === false && baseFilters !== 'finalDate' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="finalDateLabel" for="pat-dr-prescr-procd-finalDate">
                              <Translate contentKey="generadorApp.patDrPrescrProcd.finalDate">Final Date</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                          {this.state.finalDateSelectValue === null || this.state.finalDateSelectValue === '' ?  

                          <>
                              <DatePicker
                              autoComplete="off"
                              onChange={value => this.setState({ finalDateSelectValue: value })}
                              id="finalDate"
                              validate={{}}
                              className="form-control react-datepicker"
                              name="finalDate"
                              dateFormat="dd/MM/yyyy"
                              selected={this.state.finalDateSelectValue ? moment(this.state.finalDateSelectValue).toDate() : null}
                              locale="pt"
                            />
                            <text style={{color:"red"}}>* campo obrigatório</text>
                          </>

                            :
                            <DatePicker
                            autoComplete="off"
                            onChange={value => this.setState({ finalDateSelectValue: value })}
                            id="finalDate"
                            validate={{}}
                            className="form-control react-datepicker"
                            name="finalDate"
                            dateFormat="dd/MM/yyyy"
                            selected={this.state.finalDateSelectValue ? moment(this.state.finalDateSelectValue).toDate() : null}
                            locale="pt"
                          />


                            }
            
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ finalDateSelectValue: evt.target.value })}
                      type="hidden"
                      name="finalDate"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'FrequencyDescriptionComponent' ? (
                    <Col md="12">
                      <FrequencyDescriptionComponent
                        entityBase={patDrPrescrProcdEntity}
                        baseState={this.state}
                        baseProps={this.props}
                        setState={_state => this.setState(_state)}
                      />
                    </Col>
                  ) : (
                    <> </>
                  )}
                  {baseFilters !== 'observations' ? (
                    <Col md="12">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="observationsLabel" for="pat-dr-prescr-procd-observations">
                              <Translate contentKey="generadorApp.patDrPrescrProcd.observations">Observations</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ observationsSelectValue: evt.target.value })}
                              value={this.state.observationsSelectValue}
                              id="pat-dr-prescr-procd-observations"
                              type="text"
                              name="observations"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ observationsSelectValue: evt.target.value })}
                      type="hidden"
                      name="observations"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'patientDoctorPrescription' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="pat-dr-prescr-procd-patientDoctorPrescription">
                              <Translate contentKey="generadorApp.patDrPrescrProcd.patientDoctorPrescription">
                                Patient Doctor Prescription
                              </Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="pat-dr-prescr-procd-patientDoctorPrescription"
                              name={'patientDoctorPrescription'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.patientDoctorPrescriptionSelectValue ? this.state.patientDoctorPrescriptionSelectValue : ''}
                              onChange={options => this.setState({ patientDoctorPrescriptionSelectValue: options })}
                              defaultOptions={
                                this.state.patientDoctorPrescriptionStartSelectOptions
                                  ? this.state.patientDoctorPrescriptionStartSelectOptions
                                  : []
                              }
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.patientDoctorPrescriptionStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.patientDoctorPrescriptionStartSelectOptions === undefined) {
                                  const result = await getListAxios('patient-doctor-prescriptions', {}, 0, 100, 'id,asc', 'id');
                                  this.setState({
                                    patientDoctorPrescriptionStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'patient-doctor-prescriptions',
                                  { 'id.contains': inputValue },
                                  0,
                                  100,
                                  'id,asc',
                                  'id'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="patientDoctorPrescription"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}{' '}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  renderHeader() {
    const { drStatusHistories, patientDoctorPrescriptions, medicineMaterials, patDrPrescrProcdList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.patDrPrescrProcd.home.title">Pat Dr Prescr Procds</Translate>
          </span>
          <Button id="togglerFilterPatDrPrescrProcd" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.patDrPrescrProcd.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          &nbsp;
          <Button
            onClick={() =>
              this.setState({
                showModalForm: true,
                orderSelectValue: 0,
                initialHourSelectValue: null,
                frequencySelectValue: null,
                frequencyDescriptionSelectValue: null,
                initialDateSelectValue: null,
                finalDateSelectValue: null,
                continuousSelectValue: false,
                doseSelectValue: null,
                viaSelectValue: null,
                observationsSelectValue: null,
                statusSelectValue: 'ATIVO',
                otherMedicineSelectValue: false,
                medicineNameSelectValue: null,

                drStatusHistoryStartSelectOptions: undefined,
                drStatusHistorySelectValue: null,

                medicineMaterialStartSelectOptions: undefined,
                medicineMaterialSelectValue: null,
                isNew: true,
              })
            }
            color="primary"
            className="btn btn-primary float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="generadorApp.patDrPrescrProcd.btnNewItem">Create new</Translate>
          </Button>{' '}
          &nbsp;
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/patientDoctorPrescription'}>
              <Translate contentKey="generadorApp.patientDoctorPrescription.home.title">PatientDoctorPrescriptions</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/patientDoctorPrescription/' + this.props.match.params['idPatientDoctorPrescription']}>
              {this.state.patientDoctorPrescriptionSelectValue
                ? this.state.patientDoctorPrescriptionSelectValue.label
                : this.props.match.params['idPatientDoctorPrescription']}
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.patDrPrescrProcd.home.title">Pat Dr Prescr Procds</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    const { drStatusHistories, patientDoctorPrescriptions, medicineMaterials, patDrPrescrProcdList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <UncontrolledCollapse toggler="#togglerFilterPatDrPrescrProcd">
          <CardBody>
            <AvForm ref={el => (this.myFormRef = el)} id="form-filter" onSubmit={this.filterEntity}>
              <div className="row mt-1 ml-3 mr-3">
                {this.state.baseFilters !== 'createdDate' ? (
                  <Col md="3" className="col-filter-pat-dr-prescr-procd-createdDate">
                    <Row className="mr-1 mt-1">
                      <Label id="createdDateStart" for="pat-dr-prescr-procd-createdDate_start">
                        <Translate contentKey="generadorApp.patDrPrescrProcd.createdDate_start">Created Date (start)</Translate>
                      </Label>
                      <DatePicker
                        onChange={value => this.setState({ createdDateStart: value })}
                        id="createdDateStart"
                        className="form-control react-datepicker"
                        name="createdDateStart"
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.createdDateStart ? moment(this.state.createdDateStart).toDate() : null}
                        locale="pt"
                      />
                    </Row>
                  </Col>
                ) : null}
                {this.state.baseFilters !== 'createdDate' ? (
                  <Col md="3" className="col-filter-pat-dr-prescr-procd-createdDate-final">
                    <Row className="mr-1 mt-1">
                      <Label id="createdDateEnd" for="pat-dr-prescr-procd-createdDate_end">
                        <Translate contentKey="generadorApp.patDrPrescrProcd.createdDate_end">Created Date (end)</Translate>
                      </Label>
                      <DatePicker
                        onChange={value => this.setState({ createdDateEnd: value })}
                        id="createdDateEnd"
                        className="form-control react-datepicker"
                        name="createdDateEnd"
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.createdDateEnd ? moment(this.state.createdDateEnd).toDate() : null}
                        locale="pt"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'lastModifiedDate' ? (
                  <Col md="3" className="col-filter-pat-dr-prescr-procd-lastModifiedDate">
                    <Row className="mr-1 mt-1">
                      <Label id="lastModifiedDateStart" for="pat-dr-prescr-procd-lastModifiedDate_start">
                        <Translate contentKey="generadorApp.patDrPrescrProcd.lastModifiedDate_start">Last Modified Date (start)</Translate>
                      </Label>
                      <DatePicker
                        onChange={value => this.setState({ lastModifiedDateStart: value })}
                        id="lastModifiedDateStart"
                        className="form-control react-datepicker"
                        name="lastModifiedDateStart"
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.lastModifiedDateStart ? moment(this.state.lastModifiedDateStart).toDate() : null}
                        locale="pt"
                      />
                    </Row>
                  </Col>
                ) : null}
                {this.state.baseFilters !== 'lastModifiedDate' ? (
                  <Col md="3" className="col-filter-pat-dr-prescr-procd-lastModifiedDate-final">
                    <Row className="mr-1 mt-1">
                      <Label id="lastModifiedDateEnd" for="pat-dr-prescr-procd-lastModifiedDate_end">
                        <Translate contentKey="generadorApp.patDrPrescrProcd.lastModifiedDate_end">Last Modified Date (end)</Translate>
                      </Label>
                      <DatePicker
                        onChange={value => this.setState({ lastModifiedDateEnd: value })}
                        id="lastModifiedDateEnd"
                        className="form-control react-datepicker"
                        name="lastModifiedDateEnd"
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.lastModifiedDateEnd ? moment(this.state.lastModifiedDateEnd).toDate() : null}
                        locale="pt"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'status' ? (
                  <Col md="3" className="col-filter-pat-dr-prescr-procd-status">
                    <Row className="mr-1 mt-1">
                      <Label id="statusLabel" for="pat-dr-prescr-procd-status">
                        <Translate contentKey="generadorApp.patDrPrescrProcd.status">Status</Translate>
                      </Label>
                      <Select
                        id="pat-dr-prescr-procd-status"
                        className={'css-select-control'}
                        value={
                          this.state.status
                            ? { value: this.state.status, label: translate('generadorApp.Status.' + this.state.status) }
                            : { value: '', label: translate('generadorApp.patDrPrescrProcd.status') }
                        }
                        options={[
                          { value: '', label: translate('generadorApp.patDrPrescrProcd.status') },
                          { value: 'INATIVADO', label: translate('generadorApp.Status.INATIVADO') },
                          { value: 'ATIVO', label: translate('generadorApp.Status.ATIVO') },
                        ]}
                        onChange={(options: any) => this.setState({ status: options['value'] })}
                        name="status"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'patientDoctorPrescription' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="pat-dr-prescr-procd-patientDoctorPrescription">
                          <Translate contentKey="generadorApp.patDrPrescrProcd.patientDoctorPrescription">
                            Patient Doctor Prescription
                          </Translate>
                        </Label>
                        <SelectAsync
                          id="pat-dr-prescr-procd-patientDoctorPrescription"
                          isMulti
                          className={'css-select-control'}
                          name={'patientDoctorPrescription'}
                          cacheOptions
                          value={this.state.patientDoctorPrescriptionId}
                          onChange={options => this.setState({ patientDoctorPrescriptionId: options })}
                          defaultOptions={
                            this.state.patientDoctorPrescriptionStartFilter ? this.state.patientDoctorPrescriptionStartFilter : []
                          }
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.patientDoctorPrescriptionStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.patientDoctorPrescriptionStartFilter === undefined) {
                              const result = await getListAxios('patient-doctor-prescriptions', {}, 0, 100, 'id,asc', 'id');
                              this.setState({
                                patientDoctorPrescriptionStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios(
                              'patient-doctor-prescriptions',
                              { 'id.contains': inputValue },
                              0,
                              100,
                              'id,asc',
                              'id'
                            );
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'medicineMaterial' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="pat-dr-prescr-procd-medicineMaterial">
                          <Translate contentKey="generadorApp.patDrPrescrProcd.medicineMaterial">Medicine Material</Translate>
                        </Label>
                        <SelectAsync
                          id="pat-dr-prescr-procd-medicineMaterial"
                          isMulti
                          className={'css-select-control'}
                          name={'medicineMaterial'}
                          cacheOptions
                          value={this.state.medicineMaterialId}
                          onChange={options => this.setState({ medicineMaterialId: options })}
                          defaultOptions={this.state.medicineMaterialStartFilter ? this.state.medicineMaterialStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.medicineMaterialStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.medicineMaterialStartFilter === undefined) {
                              const result = await getListAxios('medicine-materials', {}, 0, 100, 'name,asc', 'name');
                              this.setState({
                                medicineMaterialStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios(
                              'medicine-materials',
                              { 'name.contains': inputValue },
                              0,
                              100,
                              'name,asc',
                              'name'
                            );
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}
              </div>

              <div className="row mb-2 mr-4 justify-content-end">
                <Button color="primary" type="submit">
                  <i className="fa fa-filter" aria-hidden={'true'}></i>&nbsp;
                  <Translate contentKey="generadorApp.patDrPrescrProcd.btnFilter">Filter</Translate>
                </Button>
                &nbsp;
                <Button color="secondary" onClick={this.cancelFilters}>
                  <FontAwesomeIcon icon="trash-alt" />
                  &nbsp;
                  <Translate contentKey="generadorApp.patDrPrescrProcd.btnCleanFilter">Clean Filter</Translate>
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </UncontrolledCollapse>
      </>
    );
  }

  renderTable() {
    const { drStatusHistories, patientDoctorPrescriptions, medicineMaterials, patDrPrescrProcdList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {patDrPrescrProcdList && patDrPrescrProcdList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null).length > 0 ? (
          <>
            <Table
              id="pat-dr-prescr-procd-table-list"
              responsive
              aria-describedby="pat-dr-prescr-procd-heading"
              className={'table-hover table-striped table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.patDrPrescrProcd.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'order' ? (
                    <th className="hand" onClick={sortFunction('order')}>
                      <Translate contentKey="generadorApp.patDrPrescrProcd.order">Order</Translate>
                      <FontAwesomeIcon icon={state.sort === 'order' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'medicineMaterial' ? (
                    <th>
                      <Translate contentKey="generadorApp.patDrPrescrProcd.medicineMaterial">Medicine Material</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'frequency' ? (
                    <th className="hand" onClick={sortFunction('frequency')}>
                      <Translate contentKey="generadorApp.patDrPrescrProcd.frequency">Frequency</Translate>
                      <FontAwesomeIcon icon={state.sort === 'frequency' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'FrequencyDescriptionTable' ? (
                    <th>
                      <Translate contentKey="generadorApp.patDrPrescrProcd.FrequencyDescriptionTable">FrequencyDescriptionTable</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'observations' ? (
                    <th className="hand" onClick={sortFunction('observations')}>
                      <Translate contentKey="generadorApp.patDrPrescrProcd.observations">Observations</Translate>
                      <FontAwesomeIcon icon={state.sort === 'observations' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'status' ? (
                    <th className="hand" onClick={sortFunction('status')}>
                      <Translate contentKey="generadorApp.patDrPrescrProcd.status">Status</Translate>
                      <FontAwesomeIcon icon={state.sort === 'status' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'patientDoctorPrescription' ? (
                    <th>
                      <Translate contentKey="generadorApp.patDrPrescrProcd.patientDoctorPrescription">
                        Patient Doctor Prescription
                      </Translate>
                    </th>
                  ) : null}

                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {patDrPrescrProcdList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((patDrPrescrProcd, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.patDrPrescrProcd.field_id">ID</Translate>
                        </b>
                        {patDrPrescrProcd.id}
                      </td>

                      {state.baseFilters !== 'order' ? (
                        <td id="order-cell">
                          <b className="visible-xs"> Order </b>

                          {patDrPrescrProcd.order}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'medicineMaterial' ? (
                        <td id="medicineMaterial-cell">
                          <b className="visible-xs"> Medicine Material </b>
                          {patDrPrescrProcd ? (
                            <span data-type-rel="2-many-to-one-other-side">
                              {getValueRecursive(patDrPrescrProcd, 'medicineMaterial.name'.split('.'), '')}
                            </span>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'frequency' ? (
                        <td id="frequency-cell">
                          <b className="visible-xs"> Frequency </b>
                          {patDrPrescrProcd.frequency ? (
                            <Translate contentKey={`generadorApp.QuoteFrequency.${patDrPrescrProcd.frequency}`} />
                          ) : (
                            <> </>
                          )}
                        </td>
                      ) : null}
                      {state.baseFilters !== 'FrequencyDescriptionTable' ? (
                        <td>
                          {' '}
                          <FrequencyDescriptionTable
                            baseState={this.state}
                            baseProps={this.props}
                            setState={st => this.setState(st)}
                            entityBase={patDrPrescrProcd}
                          />
                        </td>
                      ) : (
                        <> </>
                      )}

                      {state.baseFilters !== 'observations' ? (
                        <td id="observations-cell">
                          <b className="visible-xs"> Observations </b>

                          {patDrPrescrProcd.observations}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'status' ? (
                        <td id="status-cell">
                          <b className="visible-xs"> Status </b>
                          {patDrPrescrProcd.status ? <Translate contentKey={`generadorApp.Status.${patDrPrescrProcd.status}`} /> : <> </>}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'patientDoctorPrescription' ? (
                        <td id="patientDoctorPrescription-cell">
                          <b className="visible-xs"> Patient Doctor Prescription </b>
                          {patDrPrescrProcd ? (
                            <span data-type-rel="2-many-to-one-other-side">
                              {getValueRecursive(patDrPrescrProcd, 'patientDoctorPrescription.id'.split('.'), '')}
                            </span>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : null}

                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          {hasAnyAuthority(props.userAccount, ['pat-dr-prescr-procd'], 'edit') ? (
                            <Button onClick={() => this.openUpdateModal(patDrPrescrProcd)} color="primary" size="sm">
                              <FontAwesomeIcon icon="pencil-alt" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                          {hasAnyAuthority(props.userAccount, ['pat-dr-prescr-procd'], 'canDelete') ? (
                            <Button onClick={() => this.deleteEntity(patDrPrescrProcd)} color="danger" size="sm">
                              <FontAwesomeIcon icon="trash" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                          <Button onClick={() => this.setState({ showModalStatus: patDrPrescrProcd })} color="info" size="sm">
                            <FontAwesomeIcon icon="check-square" />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.patDrPrescrProcd.home.notFound">No Pat Dr Prescr Procds found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const { drStatusHistories, patientDoctorPrescriptions, medicineMaterials, patDrPrescrProcdList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={patDrPrescrProcdList && patDrPrescrProcdList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const { drStatusHistories, patientDoctorPrescriptions, medicineMaterials, patDrPrescrProcdList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel className="pat-dr-prescr-procd pat-dr-prescr-procd-all-in-one">
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
            {this.renderNewModal()}
            {this.renderUpdateModal()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToProps = ({ patDrPrescrProcd, ...storeState }: IRootState) => ({
  drStatusHistories: storeState.drStatusHistory.entities,
  patientDoctorPrescriptions: storeState.patientDoctorPrescription.entities,
  medicineMaterials: storeState.medicineMaterial.entities,
  patDrPrescrProcdList: patDrPrescrProcd.entities,
  totalItems: patDrPrescrProcd.totalItems,
  userAccount: storeState.authentication.account,
  loading: patDrPrescrProcd.loading,

  // UPDATE
  patDrPrescrProcdEntity: patDrPrescrProcd.entity,
});

export const mapDispatchToProps = {
  getEntities,

  updateEntity,
  reset,

  // UPDATE
  getEntity,
  setBlob,
  createEntity,

  // DELETE
  deleteEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PatDrPrescrProcd);
