/* eslint complexity: ["error", 500] */
/* eslint no-empty: off */
import React from 'react';
import { connect } from 'react-redux';

import { Link, RouteComponentProps } from 'react-router-dom';

import CurrencyInput from 'react-currency-input';
import { getFilterFromSelect, getFormFromSelect, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Col, Label } from 'reactstrap';
import { setFileData } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import {
  IBillingXmlUpdateState as IBaseUpdateState,
  getEntity,
  getBillingXmlState,
  getUrlBack,
  updateEntity,
  createEntity,
  getEntityFiltersURL,
  getEntityOffset,
  setBlob,
  reset,
} from './billing-xml.reducer';

import XmlView from './components/XmlView';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';
import moment from 'moment';
registerLocale('pt', pt);

export interface IBillingXmlUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}
export type IBillingXmlUpdateState = IBaseUpdateState;

export class BillingXmlUpdate extends React.Component<IBillingXmlUpdateProps, IBillingXmlUpdateState> {
  xmlBillingTypesFileInput: any;

  xmlFileInput: any;

  amountFileInput: any;

  dateReceiptFileInput: any;

  observationFileInput: any;
  constructor(props: Readonly<IBillingXmlUpdateProps>) {
    super(props);

    this.xmlFileInput = React.createRef();

    this.observationFileInput = React.createRef();

    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      functionEmbebed: [],

      quoteSelectValue: null,

      clientSelectValue: null,

      xmlBillingTypesSelectValue: null,
      xmlSelectValue: null,
      amountSelectValue: null,
      dateReceiptSelectValue: null,
      observationSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getBillingXmlState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
      clientFantasyName: null,

      isNew: !this.props.match.params || !this.props.match.params['id'],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateSuccess !== this.props.updateSuccess && prevProps.updateSuccess) {
      this.handleClose();
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.billingXmlEntity && prevProps.billingXmlEntity.id !== this.props.billingXmlEntity.id) ||
      (this.props.quotes.length > 0 && this.state.quoteSelectValue === null && this.props.billingXmlEntity.quote)
    ) {
      this.setState({
        quoteSelectValue:
          this.props.billingXmlEntity && this.props.billingXmlEntity.quote
            ? this.props.billingXmlEntity.quote.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.billingXmlEntity && prevProps.billingXmlEntity.id !== this.props.billingXmlEntity.id) ||
      (this.props.clients &&
        this.state.clientSelectValue === null &&
        this.props.billingXmlEntity.client &&
        this.props.billingXmlEntity.client.id)
    ) {
      const p = this.props.billingXmlEntity && this.props.billingXmlEntity.client ? this.props.billingXmlEntity.client : null;
      this.setState({
        clientSelectValue: p ? { ...p, value: p.id, label: p['fantasyName'] ? p.fantasyName : '' } : null,
      });
    }

    if (
      (prevProps.billingXmlEntity && prevProps.billingXmlEntity.id !== this.props.billingXmlEntity.id) ||
      (this.props.billingXmlEntity.xmlBillingTypes &&
        this.state.xmlBillingTypesSelectValue === null &&
        this.props.billingXmlEntity.xmlBillingTypes !== this.state.xmlBillingTypesSelectValue)
    ) {
      this.setState({
        xmlBillingTypesSelectValue: this.props.billingXmlEntity.xmlBillingTypes ? this.props.billingXmlEntity.xmlBillingTypes : null,
      });
    }
    if (
      (prevProps.billingXmlEntity && prevProps.billingXmlEntity.id !== this.props.billingXmlEntity.id) ||
      (this.props.billingXmlEntity.xml &&
        this.state.xmlSelectValue === null &&
        this.props.billingXmlEntity.xml !== this.state.xmlSelectValue)
    ) {
      this.setState({ xmlSelectValue: this.props.billingXmlEntity.xml ? this.props.billingXmlEntity.xml : null });
    }
    if (
      (prevProps.billingXmlEntity && prevProps.billingXmlEntity.id !== this.props.billingXmlEntity.id) ||
      (this.props.billingXmlEntity.amount &&
        this.state.amountSelectValue === null &&
        this.props.billingXmlEntity.amount !== this.state.amountSelectValue)
    ) {
      this.setState({ amountSelectValue: this.props.billingXmlEntity.amount ? this.props.billingXmlEntity.amount : null });
    }
    if (
      (prevProps.billingXmlEntity && prevProps.billingXmlEntity.id !== this.props.billingXmlEntity.id) ||
      (this.props.billingXmlEntity.dateReceipt &&
        this.state.dateReceiptSelectValue === null &&
        this.props.billingXmlEntity.dateReceipt !== this.state.dateReceiptSelectValue)
    ) {
      this.setState({ dateReceiptSelectValue: this.props.billingXmlEntity.dateReceipt ? this.props.billingXmlEntity.dateReceipt : null });
    }
    if (
      (prevProps.billingXmlEntity && prevProps.billingXmlEntity.id !== this.props.billingXmlEntity.id) ||
      (this.props.billingXmlEntity.observation &&
        this.state.observationSelectValue === null &&
        this.props.billingXmlEntity.observation !== this.state.observationSelectValue)
    ) {
      this.setState({ observationSelectValue: this.props.billingXmlEntity.observation ? this.props.billingXmlEntity.observation : null });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  UNSAFE_componentWillMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params['id']);
    }
  }

  onBlobChange = (isAnImage, name, fileInput) => event => {
    const fileName = event.target.files[0].name;
    setFileData(event, (contentType, data) => this.props.setBlob(name, data, contentType, fileName), isAnImage);
    const varAux = {};
    varAux[name + 'SelectValue'] = fileName;
    this.setState(varAux);
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined);
  };
  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = offset !== null ? '&offset=' + offset : '';
    Object.keys(fieldsBase).map(key => {
      url += key !== 'offset' ? '&' + key + '=' + fieldsBase[key] : '';
    });
    return url;
  };
  saveEntity = (event: any, errors: any, values: any) => {
    const selectFieldsList = ['quote.accountNumber', 'dateReceipt', 'client.fantasyName', 'amount'];

    if (errors.length === 0) {
      const { billingXmlEntity } = this.props;
      const _entity = {
        ...billingXmlEntity,
        // ...values,

        client: getFormFromSelect(this.state.clientSelectValue, 'many-to-one'), // many-to-one - otherSide

        xmlBillingTypes: this.state.xmlBillingTypesSelectValue === null ? null : this.state.xmlBillingTypesSelectValue,
        xml: this.state.xmlSelectValue,
        amount: this.state.amountSelectValue,
        dateReceipt: this.state.dateReceiptSelectValue,
        observation: this.state.observationSelectValue,
      };

      const entity = _entity;

      const { createdById, createdDateStart, createdDateEnd, extraFilters, activePage, itemsPerPage, sort, order } = this.state.fieldsBase;

      if (this.state.isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              getFilterFromSelect(createdById, 'many-to-one'),
              createdDateStart,
              createdDateEnd,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              getFilterFromSelect(createdById, 'many-to-one'),
              createdDateStart,
              createdDateEnd,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/billing-xml?' + getEntityFiltersURL(this.state.fieldsBase));
  };

  getFullEntityFromState(v) {
    return {
      id: v.id,
      xmlBillingTypes: this.state.xmlBillingTypesSelectValue,
      xml: this.state.xmlSelectValue,
      amount: this.state.amountSelectValue,
      dateReceipt: this.state.dateReceiptSelectValue,
      observation: this.state.observationSelectValue,
      quote: this.state.quoteSelectValue,
      client: this.state.clientSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    const quoteEntity = v.quote;

    const clientEntity = v.client;

    return {
      xmlBillingTypesSelectValue: v.xmlBillingTypes,
      xmlSelectValue: v.xml,
      amountSelectValue: v.amount,
      dateReceiptSelectValue: v.dateReceipt,
      observationSelectValue: v.observation,
      quoteSelectValue: quoteEntity ? quoteEntity['id'] : null,
      clientSelectValue: clientEntity ? clientEntity['id'] : null,
    };
  }

  renderHeader() {
    const { billingXmlEntity, quotes, clients, loading, updating } = this.props;
    const { isNew } = this.state;

    const { xml, observation } = billingXmlEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.billingXml.home.createLabel">Create a BillingXml</Translate>
            ) : (
              <Translate contentKey="generadorApp.billingXml.home.editLabel">Edit a BillingXml</Translate>
            )}
          </span>

          <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.billingXml.btnSave">Save</Translate>
          </Button>

          {!this.state.isNew ? (
            <>
              <Button
                color="primary"
                id="next-entity"
                disabled={updating}
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.createdById,
                    this.state.fieldsBase.createdDateStart,
                    this.state.fieldsBase.createdDateEnd,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 + 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 + 1 },
                    });
                    this.props.history.push(
                      '/billing-xml/' + myresult.value.data[0]['id'] + '/edit?' + this.getFiltersURL(this.state.fieldsBase.offset * 1 + 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.last.item', { fieldName: translate('generadorApp.billingXml.detail.title') }));
                  }
                }}
                className="float-right jh-create-entity"
              >
                {'>>'}
              </Button>

              <Button
                color="primary"
                id="prev-entity"
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.createdById,
                    this.state.fieldsBase.createdDateStart,
                    this.state.fieldsBase.createdDateEnd,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 - 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 - 1 },
                    });
                    this.props.history.push(
                      '/billing-xml/' + myresult.value.data[0]['id'] + '/edit?' + this.getFiltersURL(this.state.fieldsBase.offset * 1 - 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.first.item', { fieldName: translate('generadorApp.billingXml.detail.title') }));
                  }
                }}
                disabled={updating}
                className="float-right jh-create-entity"
              >
                {'<<'}
              </Button>
            </>
          ) : (
            <> </>
          )}

          <Button
            tag={Link}
            id="cancel-save"
            to={'/billing-xml?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.billingXml.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={'/billing-xml'}>
              <Translate contentKey="generadorApp.billingXml.home.title">Billing Xmls</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.billingXml.detail.title">Billing Xml edit</Translate>
          </li>
        </ol>
      </>
    );
  }
  renderBody() {
    const { billingXmlEntity, quotes, clients, loading, updating } = this.props;
    const { isNew } = this.state;

    const { xml, observation } = billingXmlEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const fullEntityEmbebed = this.getFullEntityFromState(billingXmlEntity);

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="billing-xml-id">
                  <Translate contentKey="generadorApp.billingXml.field_id">ID</Translate>
                </Label>
                </Col> */}
                      <Col md="12">
                        <AvInput id="billing-xml-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'createdBy' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="createdByLabel" for="billing-xml-createdBy">
                              <Translate contentKey="generadorApp.billingXml.createdBy">Created By</Translate>
                            </Label>
                          </Col>

                          <Col md="12">
                            <AvInput
                              disabled={true}
                              value={billingXmlEntity.createdBy ? billingXmlEntity.createdBy.login : ''}
                              id="billing-xml-createdBy"
                              type="text"
                              name="createdBy"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="createdBy"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}

                  {baseFilters !== 'createdDate' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="createdDateLabel" for="billing-xml-createdDate">
                              <Translate contentKey="generadorApp.billingXml.createdDate">Created Date</Translate>
                            </Label>
                          </Col>

                          <Col md="12">
                            <DatePicker
                              id="createdDate"
                              disabled={true}
                              className="form-control react-datepicker"
                              name="createdDate"
                              dateFormat="dd/MM/yyyy"
                              selected={billingXmlEntity.createdDate ? moment(billingXmlEntity.createdDate).toDate() : ''}
                              locale="pt"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <> </>
                  )}

                  {baseFilters !== 'client' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="billing-xml-client">
                              <Translate contentKey="generadorApp.billingXml.client">Client</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <AvInput
                              disabled={true}
                              value={showFieldsSelectAsync(billingXmlEntity, 'client.fantasyName') || ''}
                              id="billing-xml-client"
                              type="text"
                              name="client"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="client"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}

                  {baseFilters !== 'xmlBillingTypes' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="xmlBillingTypesLabel" for="billing-xml-xmlBillingTypes">
                              <Translate contentKey="generadorApp.billingXml.xmlBillingTypes">XML Table Type</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <AvInput
                              disabled={true}
                              value={this.state.xmlBillingTypesSelectValue ? this.state.xmlBillingTypesSelectValue : ''}
                              id="billing-xml-xmlBillingTypes"
                              type="text"
                              name="xmlBillingTypes"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ xmlBillingTypesSelectValue: evt.target.value })}
                      type="hidden"
                      name="xmlBillingTypes"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'dateReceipt' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="dateReceiptLabel" for="billing-xml-dateReceipt">
                              <Translate contentKey="generadorApp.billingXml.dateReceipt">Date receipt</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <DatePicker
                              autoComplete="off"
                              onChange={value => this.setState({ dateReceiptSelectValue: value })}
                              id="dateReceipt"
                              validate={{}}
                              className="form-control react-datepicker"
                              name="dateReceipt"
                              dateFormat="dd/MM/yyyy"
                              selected={this.state.dateReceiptSelectValue ? moment(this.state.dateReceiptSelectValue).toDate() : null}
                              locale="pt"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ dateReceiptSelectValue: evt.target.value })}
                      type="hidden"
                      name="dateReceipt"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'amount' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="amountLabel" for="billing-xml-amount">
                              <Translate contentKey="generadorApp.billingXml.amount">Amount</Translate>
                            </Label>
                          </Col>

                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ amountSelectValue: floatvalue })}
                              decimalSeparator=","
                              precision={2}
                              thousandSeparator=""
                              prefix="R$ "
                              allowNegative={true}
                              value={this.state.amountSelectValue ? this.state.amountSelectValue : 0}
                              id="billing-xml-amount"
                              name="amount"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ amountSelectValue: evt.target.value })}
                      type="hidden"
                      name="amount"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'observation' ? (
                    <Col md="12">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="observationLabel" for="billing-xml-observation">
                              <Translate contentKey="generadorApp.billingXml.observation">Observation</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <AvInput
                              onChange={evt => this.setState({ observationSelectValue: evt.target.value })}
                              validate={{}}
                              id="billing-xml-observation"
                              type="textarea"
                              name="observation"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ observationSelectValue: evt.target.value })}
                      type="hidden"
                      name="observation"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'XmlView' ? (
                    <Col md="12">
                      <XmlView
                        entityBase={billingXmlEntity}
                        baseState={this.state}
                        baseProps={this.props}
                        setState={_state => this.setState(_state)}
                      />
                    </Col>
                  ) : (
                    <> </>
                  )}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { billingXmlEntity, quotes, clients, loading, updating } = this.props;
    const { isNew } = this.state;

    const { xml, observation } = billingXmlEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...billingXmlEntity,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel className="billing-xml billing-xml-update">
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToProps = (storeState: IRootState) => {
  return {
    quotes: storeState.quote.entities,
    clients: storeState.client.entities,
    billingXmlEntity: storeState.billingXml.entity,
    loading: storeState.billingXml.loading,
    updating: storeState.billingXml.updating,
    updateSuccess: storeState.billingXml.updateSuccess,
  };
};

export const mapDispatchToProps = {
  getEntity,
  updateEntity,
  setBlob,
  createEntity,
  getEntityOffset,
  reset,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(BillingXmlUpdate);
