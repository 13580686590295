import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Quote from './extended/quote';

import QuoteDetail from './quote-detail';

import QuoteUpdate from './extended/quote-update';

import QuoteFile from '../quote-file/index';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute path={`${match.path}/:idQuote/quote-file`} component={QuoteFile} />

      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={QuoteUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={QuoteUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={QuoteDetail} />
      <ErrorBoundaryRoute path={match.path} component={Quote} />
    </Switch>

    <Switch>{/* <ErrorBoundaryRoute path={`${match.path}/:idQuote/quote-file`} component={QuoteFile} /> */}</Switch>
  </>
);

export default Routes;
