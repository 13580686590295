import { IWhiteLabel } from './white-label.model';
import { IUserFile } from './user-file.model';
import { IProfessional } from './professional.model';
import { IProfile } from './profile.model';
import { ISupplier } from './supplier.model';
import { ISpecialty } from './specialty.model';
import { INursingDiary } from './nursing-diary.model';
import { INotificationUser } from './notification-user.model';
import { IPermissionUser } from './permission-user.model';
import { IPharmacyInventory } from './pharmacy-inventory.model';

export interface IUser {
  id?: any;
  login?: string;
  fullname?: string;
  cellphone?: string;
  email?: string;
  birthDate?: Date;
  cpf?: string;
  rg?: string;
  clients?: string;
  profiles?: string;
  activated?: boolean;
  langKey?: string;
  authorities?: any[];
  whiteLabel?: IWhiteLabel;
  createdBy?: string;
  createdDate?: Date;
  lastModifiedBy?: string;
  lastModifiedDate?: Date;
  password?: string;
  userFile?: IUserFile[];
  notificationUser?: INotificationUser[];
  nursingDiary?: INursingDiary[];
  permissionUser?: IPermissionUser[];
  pharmacyInventory?: IPharmacyInventory[];
  professional?: IProfessional;
  supplier?: ISupplier;
  specialty?: ISpecialty;
  profile?: IProfile[];
}

export const defaultValue: Readonly<IUser> = {
  id: '',
  login: '',
  fullname: '',
  cellphone: '',
  email: '',
  birthDate: null,
  cpf: '',
  rg: '',
  activated: false,
  langKey: '',
  clients: '',
  profiles: '',
  whiteLabel: null,
  createdBy: '',
  createdDate: null,
  lastModifiedBy: '',
  lastModifiedDate: null,
  password: '',
};
