/* eslint complexity: ["error", 500] */
import axios from 'axios';

import { IPaginationBaseState, IPayloadResult } from 'react-jhipster';

import { IPayload } from 'react-jhipster/src/type/redux-action.type';

import { BASE_API_VERSION_PATH } from 'app/config/constants';

import { cleanEntity, jsonParse } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IClComRuler, defaultValue } from 'app/shared/model/cl-com-ruler.model';

export declare type ICrudPutAction<T> = (data?: T, listFiltersPage?) => IPayload<T> | IPayloadResult<T>;
export declare type ICrudGetAction<T> = (id: string | number, selectFields?: string) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const ACTION_TYPES = {
  FETCH_CLCOMRULER_LIST_EXPORT: 'clComRuler/FETCH_CLCOMRULER_LIST_EXPORT',
  FETCH_CLCOMRULER_LIST: 'clComRuler/FETCH_CLCOMRULER_LIST',
  FETCH_CLCOMRULER: 'clComRuler/FETCH_CLCOMRULER',
  FETCH_CLCOMRULER_OFFSET: 'clComRuler/FETCH_CLCOMRULER_OFFSET',
  CREATE_CLCOMRULER: 'clComRuler/CREATE_CLCOMRULER',
  UPDATE_CLCOMRULER: 'clComRuler/UPDATE_CLCOMRULER',
  CREATE_CLCOMRULER_AND_GO_LIST: 'clComRuler/CREATE_CLCOMRULER_AND_GO_LIST',
  UPDATE_CLCOMRULER_AND_GO_LIST: 'clComRuler/UPDATE_CLCOMRULER_AND_GO_LIST',
  DELETE_CLCOMRULER: 'clComRuler/DELETE_CLCOMRULER',
  SET_BLOB: 'clComRuler/SET_BLOB',
  RESET: 'clComRuler/RESET',
  SHOW_MODAL: 'clComRuler/SHOW_MODAL',
};

const initialState = {
  loading: false,
  openModal: {
    list: false,
    view: false,
    detail: false,
  },
  errorMessage: null,
  entities: [] as ReadonlyArray<IClComRuler>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const actionPendind = [];
let actualState = initialState;

export const convertEntityBuffers = entity => ({
  ...entity,
  observation: entity.observation ? Buffer.from(entity.observation).toString() : entity.observation,
});

export type ClComRulerState = Readonly<typeof initialState>;

export interface IClComRulerBaseState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  listCheckedID?: Array<number>;
  isCheckedAll?: boolean;
  urlBack?: string;
  extraFilters?: any;
  baseFilters: any;
  offset: number;
  option?: any;
  comercialTableType?: any;
  saleValue?: any;
  observation?: any;
  table?: any;
  percent?: any;
  inflator?: any;
  deflator?: any;
  rentalValue?: any;
  tussCode?: any;
  tissCode?: any;
  tableCode?: any;
  expenseCode?: any;
  frequency?: any;
  minValuePackage?: any;
  clComTableId?: any;
  clComTableStartFilter?: any;
  levelGroupId?: any;
  levelGroupStartFilter?: any;
  categoryId?: any;
  categoryStartFilter?: any;
  productServiceId?: any;
  productServiceStartFilter?: any;
  specialtyId?: any;
  specialtyStartFilter?: any;
}

export interface IFieldsBase extends IClComRulerBaseState, IPaginationBaseState {}
export interface IClComRulerUpdateState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  urlBack?: string;
  functionEmbebed?: any[];
  fieldsBase: IFieldsBase;
  optionSelectValue?: any;
  optionStartSelectOptions?: any;
  comercialTableTypeSelectValue?: any;
  comercialTableTypeStartSelectOptions?: any;
  saleValueSelectValue?: any;
  saleValueStartSelectOptions?: any;
  observationSelectValue?: any;
  observationStartSelectOptions?: any;
  tableSelectValue?: any;
  tableStartSelectOptions?: any;
  percentSelectValue?: any;
  percentStartSelectOptions?: any;
  inflatorSelectValue?: any;
  inflatorStartSelectOptions?: any;
  deflatorSelectValue?: any;
  deflatorStartSelectOptions?: any;
  rentalValueSelectValue?: any;
  rentalValueStartSelectOptions?: any;
  tussCodeSelectValue?: any;
  tussCodeStartSelectOptions?: any;
  tissCodeSelectValue?: any;
  tissCodeStartSelectOptions?: any;
  tableCodeSelectValue?: any;
  tableCodeStartSelectOptions?: any;
  expenseCodeSelectValue?: any;
  expenseCodeStartSelectOptions?: any;
  frequencySelectValue?: any;
  frequencyStartSelectOptions?: any;
  minValuePackageSelectValue?: any;
  minValuePackageStartSelectOptions?: any;

  clComTableSelectValue?: any;
  clComTableStartSelectOptions?: any;

  levelGroupSelectValue?: any;
  levelGroupStartSelectOptions?: any;

  categorySelectValue?: any;
  categoryStartSelectOptions?: any;

  productServiceSelectValue?: any;
  productServiceStartSelectOptions?: any;

  specialtySelectValue?: any;
  specialtyStartSelectOptions?: any;

  isNew: boolean;
  idsproductService: any[];
  idsspecialty: any[];
  clComTableName?: any;

  clComTableId?: any;
  levelGroupName?: any;

  levelGroupId?: any;
  categoryName?: any;

  categoryId?: any;
}

// Reducer

export default (state: ClComRulerState = initialState, action): ClComRulerState => {
  actualState = state;
  if (
    action.type.includes('_PENDING') &&
    !actionPendind.includes(action.type.replace('_PENDING', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_PENDING', ''))
  ) {
    actionPendind.push(action.type.replace('_PENDING', ''));
  }

  if (
    action.type.includes('_FULFILLED') &&
    actionPendind.includes(action.type.replace('_FULFILLED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_FULFILLED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_FULFILLED', '')), 1);
  }

  if (
    action.type.includes('_REJECTED') &&
    actionPendind.includes(action.type.replace('_REJECTED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_REJECTED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_REJECTED', '')), 1);
  }

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_CLCOMRULER_LIST_EXPORT):
    case REQUEST(ACTION_TYPES.FETCH_CLCOMRULER_LIST):
    case REQUEST(ACTION_TYPES.FETCH_CLCOMRULER):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_CLCOMRULER):
    case REQUEST(ACTION_TYPES.UPDATE_CLCOMRULER):
    case REQUEST(ACTION_TYPES.CREATE_CLCOMRULER_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.UPDATE_CLCOMRULER_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.DELETE_CLCOMRULER):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_CLCOMRULER_LIST_EXPORT):
    case FAILURE(ACTION_TYPES.FETCH_CLCOMRULER_LIST):
    case FAILURE(ACTION_TYPES.FETCH_CLCOMRULER):
    case FAILURE(ACTION_TYPES.CREATE_CLCOMRULER):
    case FAILURE(ACTION_TYPES.UPDATE_CLCOMRULER):
    case FAILURE(ACTION_TYPES.CREATE_CLCOMRULER_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.UPDATE_CLCOMRULER_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.DELETE_CLCOMRULER):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_CLCOMRULER_LIST):
      action.payload.data = action.payload.data.map(v => {
        return { ...v, observation: v.observation ? Buffer.from(v.observation).toString() : v.observation };
      });

      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_CLCOMRULER_OFFSET):
      if (action.payload.data && action.payload.data.length > 0) {
        return {
          ...state,
          loading: false,
          entity: convertEntityBuffers(action.payload.data[0]),
        };
      }
      return {
        ...state,
        loading: false,
      };

    case SUCCESS(ACTION_TYPES.FETCH_CLCOMRULER):
      return {
        ...state,
        loading: false,
        entity: convertEntityBuffers(action.payload.data),
      };
    case SUCCESS(ACTION_TYPES.CREATE_CLCOMRULER):
    case SUCCESS(ACTION_TYPES.UPDATE_CLCOMRULER):
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_CLCOMRULER_AND_GO_LIST):
    case SUCCESS(ACTION_TYPES.UPDATE_CLCOMRULER_AND_GO_LIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_CLCOMRULER):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.SET_BLOB: {
      const { name, data, contentType, fileName } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [name + 'Base64']: data,
          [name + 'ContentType']: contentType,
          [name + 'FileName']: fileName,
        },
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };

    case ACTION_TYPES.SHOW_MODAL: {
      const openModal = state.openModal;
      openModal[action.payload.type] = action.payload.show;
      return {
        ...state,
        openModal,
      };
    }
    default:
      return state;
  }
};

const apiUrl = BASE_API_VERSION_PATH + 'cl-com-rulers';

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

// Actions

// Actions
export type ICrudGetAllActionClComRuler<T> = (
  option?: any,
  comercialTableType?: any,
  saleValue?: any,
  observation?: any,
  table?: any,
  percent?: any,
  inflator?: any,
  deflator?: any,
  rentalValue?: any,
  tussCode?: any,
  tissCode?: any,
  tableCode?: any,
  expenseCode?: any,
  frequency?: any,
  minValuePackage?: any,
  clComTableId?: any,
  levelGroupId?: any,
  categoryId?: any,
  productServiceId?: any,
  specialtyId?: any,
  extraFilters?: {},
  page?: number,
  size?: number,
  sort?: string,
  selectFields?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getEntitiesFilter = (filters?, page?, size?, sort?, selectFields = '') => {
  const requestUrl = `${apiUrl}?page=${page >= 0 ? page : 0}&size=${size >= 0 ? size : 20}&sort=${sort ? sort : 'id,asc'}&`;
  const requestFilters = filters.map(v => v.column + '.' + v.operation + '=' + v.value).join('&');
  return {
    type: ACTION_TYPES.FETCH_CLCOMRULER_LIST,
    payload: axios.get<IClComRuler>(`${requestUrl}${requestFilters}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getAllEntities = (filters?, sort?, selectFields = '') => {
  if (
    (actualState.totalItems > 0 && actualState.entities.length === actualState.totalItems) ||
    actionPendind.includes(ACTION_TYPES.FETCH_CLCOMRULER_LIST)
  ) {
    return {
      type: ACTION_TYPES.FETCH_CLCOMRULER_LIST + '_FROM_CACHE',
      payload: new Promise(function (resolve, reject) {
        resolve('done');
      }),
    };
  }

  const idsRequest = filters && typeof filters['ids'] !== 'undefined' && filters['ids'] ? `id.in=${filters['ids'].join(',')}&` : '';
  const optionRequest =
    filters && typeof filters['option'] !== 'undefined' && filters['option'] ? `option.contains=${filters['option']}&` : '';
  const comercialTableTypeRequest =
    filters && typeof filters['comercialTableType'] !== 'undefined' && filters['comercialTableType']
      ? `comercialTableType.contains=${filters['comercialTableType']}&`
      : '';
  const saleValueRequest =
    filters && typeof filters['saleValue'] !== 'undefined' && filters['saleValue'] ? `saleValue.contains=${filters['saleValue']}&` : '';
  const observationRequest =
    filters && typeof filters['observation'] !== 'undefined' && filters['observation']
      ? `observation.contains=${filters['observation']}&`
      : '';
  const tableRequest = filters && typeof filters['table'] !== 'undefined' && filters['table'] ? `table.contains=${filters['table']}&` : '';
  const percentRequest =
    filters && typeof filters['percent'] !== 'undefined' && filters['percent'] ? `percent.equals=${filters['percent']}&` : '';
  const inflatorRequest =
    filters && typeof filters['inflator'] !== 'undefined' && filters['inflator'] ? `inflator.contains=${filters['inflator']}&` : '';
  const deflatorRequest =
    filters && typeof filters['deflator'] !== 'undefined' && filters['deflator'] ? `deflator.contains=${filters['deflator']}&` : '';
  const rentalValueRequest =
    filters && typeof filters['rentalValue'] !== 'undefined' && filters['rentalValue']
      ? `rentalValue.contains=${filters['rentalValue']}&`
      : '';
  const tussCodeRequest =
    filters && typeof filters['tussCode'] !== 'undefined' && filters['tussCode'] ? `tussCode.contains=${filters['tussCode']}&` : '';
  const tissCodeRequest =
    filters && typeof filters['tissCode'] !== 'undefined' && filters['tissCode'] ? `tissCode.contains=${filters['tissCode']}&` : '';
  const tableCodeRequest =
    filters && typeof filters['tableCode'] !== 'undefined' && filters['tableCode'] ? `tableCode.contains=${filters['tableCode']}&` : '';
  const expenseCodeRequest =
    filters && typeof filters['expenseCode'] !== 'undefined' && filters['expenseCode']
      ? `expenseCode.contains=${filters['expenseCode']}&`
      : '';
  const frequencyRequest =
    filters && typeof filters['frequency'] !== 'undefined' && filters['frequency'] ? `frequency.contains=${filters['frequency']}&` : '';
  const minValuePackageRequest =
    filters && typeof filters['minValuePackage'] !== 'undefined' && filters['minValuePackage']
      ? `minValuePackage.contains=${filters['minValuePackage']}&`
      : '';
  const clComTableRequest =
    filters && typeof filters['clComTableId'] !== 'undefined' && filters['clComTableId']
      ? `clComTable.id.in=${filters['clComTableId']}&&`
      : '';
  const levelGroupRequest =
    filters && typeof filters['levelGroupId'] !== 'undefined' && filters['levelGroupId']
      ? `levelGroup.id.in=${filters['levelGroupId']}&&`
      : '';
  const categoryRequest =
    filters && typeof filters['categoryId'] !== 'undefined' && filters['categoryId'] ? `category.id.in=${filters['categoryId']}&&` : '';
  const productServiceRequest =
    filters && typeof filters['productServiceId'] !== 'undefined' && filters['productServiceId']
      ? `productServices.id.in=${filters['productServiceId']}&&`
      : '';
  const specialtyRequest =
    filters && typeof filters['specialtyId'] !== 'undefined' && filters['specialtyId']
      ? `specialties.id.in=${filters['specialtyId']}&&`
      : '';

  const requestUrl = `${apiUrl}${`?page=${0}&size=${1000000}&sort=${sort ? sort : 'id,asc'}&`}`;
  return {
    type: ACTION_TYPES.FETCH_CLCOMRULER_LIST,
    payload: axios.get<IClComRuler>(
      `${requestUrl}${idsRequest}${optionRequest}${comercialTableTypeRequest}${saleValueRequest}${observationRequest}${tableRequest}${percentRequest}${inflatorRequest}${deflatorRequest}${rentalValueRequest}${tussCodeRequest}${tissCodeRequest}${tableCodeRequest}${expenseCodeRequest}${frequencyRequest}${minValuePackageRequest}${clComTableRequest}${levelGroupRequest}${categoryRequest}${productServiceRequest}${specialtyRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntities: ICrudGetAllActionClComRuler<IClComRuler> = (
  option,
  comercialTableType,
  saleValue,
  observation,
  table,
  percent,
  inflator,
  deflator,
  rentalValue,
  tussCode,
  tissCode,
  tableCode,
  expenseCode,
  frequency,
  minValuePackage,
  clComTableId,
  levelGroupId,
  categoryId,
  productServiceId,
  specialtyId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const optionRequest = option ? (Array.isArray(status) ? `option.in=${option.join(',')}&` : `option.equals=${option}&`) : '';
  const comercialTableTypeRequest = comercialTableType
    ? Array.isArray(status)
      ? `comercialTableType.in=${comercialTableType.join(',')}&`
      : `comercialTableType.equals=${comercialTableType}&`
    : '';
  const saleValueRequest = saleValue
    ? Array.isArray(status)
      ? `saleValue.in=${saleValue.join(',')}&`
      : `saleValue.contains=${saleValue}&`
    : '';
  const observationRequest = observation
    ? Array.isArray(status)
      ? `observation.in=${observation.join(',')}&`
      : `observation.contains=${observation}&`
    : '';
  const tableRequest = table ? (Array.isArray(status) ? `table.in=${table.join(',')}&` : `table.equals=${table}&`) : '';
  const percentRequest = percent ? (Array.isArray(status) ? `percent.in=${percent.join(',')}&` : `percent.equals=${percent}&`) : '';
  const inflatorRequest = inflator ? (Array.isArray(status) ? `inflator.in=${inflator.join(',')}&` : `inflator.contains=${inflator}&`) : '';
  const deflatorRequest = deflator ? (Array.isArray(status) ? `deflator.in=${deflator.join(',')}&` : `deflator.contains=${deflator}&`) : '';
  const rentalValueRequest = rentalValue
    ? Array.isArray(status)
      ? `rentalValue.in=${rentalValue.join(',')}&`
      : `rentalValue.contains=${rentalValue}&`
    : '';
  const tussCodeRequest = tussCode ? (Array.isArray(status) ? `tussCode.in=${tussCode.join(',')}&` : `tussCode.contains=${tussCode}&`) : '';
  const tissCodeRequest = tissCode ? (Array.isArray(status) ? `tissCode.in=${tissCode.join(',')}&` : `tissCode.contains=${tissCode}&`) : '';
  const tableCodeRequest = tableCode
    ? Array.isArray(status)
      ? `tableCode.in=${tableCode.join(',')}&`
      : `tableCode.contains=${tableCode}&`
    : '';
  const expenseCodeRequest = expenseCode
    ? Array.isArray(status)
      ? `expenseCode.in=${expenseCode.join(',')}&`
      : `expenseCode.contains=${expenseCode}&`
    : '';
  const frequencyRequest = frequency
    ? Array.isArray(status)
      ? `frequency.in=${frequency.join(',')}&`
      : `frequency.equals=${frequency}&`
    : '';
  const minValuePackageRequest = minValuePackage
    ? Array.isArray(status)
      ? `minValuePackage.in=${minValuePackage.join(',')}&`
      : `minValuePackage.contains=${minValuePackage}&`
    : '';
  const clComTableRequest = clComTableId ? `clComTable.id.in=${clComTableId}&` : '';
  const levelGroupRequest = levelGroupId ? `levelGroup.id.in=${levelGroupId}&` : '';
  const categoryRequest = categoryId ? `category.id.in=${categoryId}&` : '';
  const productServiceRequest = productServiceId ? `productServices.id.in=${productServiceId}&` : '';
  const specialtyRequest = specialtyId ? `specialties.id.in=${specialtyId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_CLCOMRULER_LIST,
    payload: axios.get<IClComRuler>(
      `${requestUrl}${extraFiltersRequest}${optionRequest}${comercialTableTypeRequest}${saleValueRequest}${observationRequest}${tableRequest}${percentRequest}${inflatorRequest}${deflatorRequest}${rentalValueRequest}${tussCodeRequest}${tissCodeRequest}${tableCodeRequest}${expenseCodeRequest}${frequencyRequest}${minValuePackageRequest}${clComTableRequest}${levelGroupRequest}${categoryRequest}${productServiceRequest}${specialtyRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntityOffset: any = (
  option,
  comercialTableType,
  saleValue,
  observation,
  table,
  percent,
  inflator,
  deflator,
  rentalValue,
  tussCode,
  tissCode,
  tableCode,
  expenseCode,
  frequency,
  minValuePackage,
  clComTableId,
  levelGroupId,
  categoryId,
  productServiceId,
  specialtyId,
  extraFilters,
  offset,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const optionRequest = option ? (Array.isArray(status) ? `option.in=${option.join(',')}&` : `option.equals=${option}&`) : '';
  const comercialTableTypeRequest = comercialTableType
    ? Array.isArray(status)
      ? `comercialTableType.in=${comercialTableType.join(',')}&`
      : `comercialTableType.equals=${comercialTableType}&`
    : '';
  const saleValueRequest = saleValue
    ? Array.isArray(status)
      ? `saleValue.in=${saleValue.join(',')}&`
      : `saleValue.contains=${saleValue}&`
    : '';
  const observationRequest = observation
    ? Array.isArray(status)
      ? `observation.in=${observation.join(',')}&`
      : `observation.contains=${observation}&`
    : '';
  const tableRequest = table ? (Array.isArray(status) ? `table.in=${table.join(',')}&` : `table.equals=${table}&`) : '';
  const percentRequest = percent ? (Array.isArray(status) ? `percent.in=${percent.join(',')}&` : `percent.equals=${percent}&`) : '';
  const inflatorRequest = inflator ? (Array.isArray(status) ? `inflator.in=${inflator.join(',')}&` : `inflator.contains=${inflator}&`) : '';
  const deflatorRequest = deflator ? (Array.isArray(status) ? `deflator.in=${deflator.join(',')}&` : `deflator.contains=${deflator}&`) : '';
  const rentalValueRequest = rentalValue
    ? Array.isArray(status)
      ? `rentalValue.in=${rentalValue.join(',')}&`
      : `rentalValue.contains=${rentalValue}&`
    : '';
  const tussCodeRequest = tussCode ? (Array.isArray(status) ? `tussCode.in=${tussCode.join(',')}&` : `tussCode.contains=${tussCode}&`) : '';
  const tissCodeRequest = tissCode ? (Array.isArray(status) ? `tissCode.in=${tissCode.join(',')}&` : `tissCode.contains=${tissCode}&`) : '';
  const tableCodeRequest = tableCode
    ? Array.isArray(status)
      ? `tableCode.in=${tableCode.join(',')}&`
      : `tableCode.contains=${tableCode}&`
    : '';
  const expenseCodeRequest = expenseCode
    ? Array.isArray(status)
      ? `expenseCode.in=${expenseCode.join(',')}&`
      : `expenseCode.contains=${expenseCode}&`
    : '';
  const frequencyRequest = frequency
    ? Array.isArray(status)
      ? `frequency.in=${frequency.join(',')}&`
      : `frequency.equals=${frequency}&`
    : '';
  const minValuePackageRequest = minValuePackage
    ? Array.isArray(status)
      ? `minValuePackage.in=${minValuePackage.join(',')}&`
      : `minValuePackage.contains=${minValuePackage}&`
    : '';
  const clComTableRequest = clComTableId ? `clComTable.id.in=${clComTableId}&` : '';
  const levelGroupRequest = levelGroupId ? `levelGroup.id.in=${levelGroupId}&` : '';
  const categoryRequest = categoryId ? `category.id.in=${categoryId}&` : '';
  const productServiceRequest = productServiceId ? `productServices.id.in=${productServiceId}&` : '';
  const specialtyRequest = specialtyId ? `specialties.id.in=${specialtyId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${offset}&size=${1}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_CLCOMRULER_OFFSET,
    payload: axios.get<IClComRuler>(
      `${requestUrl}${extraFiltersRequest}${optionRequest}${comercialTableTypeRequest}${saleValueRequest}${observationRequest}${tableRequest}${percentRequest}${inflatorRequest}${deflatorRequest}${rentalValueRequest}${tussCodeRequest}${tissCodeRequest}${tableCodeRequest}${expenseCodeRequest}${frequencyRequest}${minValuePackageRequest}${clComTableRequest}${levelGroupRequest}${categoryRequest}${productServiceRequest}${specialtyRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntity: ICrudGetAction<IClComRuler> = (id, selectFields = '') => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_CLCOMRULER,
    payload: axios.get<IClComRuler>(requestUrl, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntitiesExport: ICrudGetAllActionClComRuler<IClComRuler> = (
  option,
  comercialTableType,
  saleValue,
  observation,
  table,
  percent,
  inflator,
  deflator,
  rentalValue,
  tussCode,
  tissCode,
  tableCode,
  expenseCode,
  frequency,
  minValuePackage,
  clComTableId,
  levelGroupId,
  categoryId,
  productServiceId,
  specialtyId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const optionRequest = option ? (Array.isArray(status) ? `option.in=${option.join(',')}&` : `option.equals=${option}&`) : '';
  const comercialTableTypeRequest = comercialTableType
    ? Array.isArray(status)
      ? `comercialTableType.in=${comercialTableType.join(',')}&`
      : `comercialTableType.equals=${comercialTableType}&`
    : '';
  const saleValueRequest = saleValue
    ? Array.isArray(status)
      ? `saleValue.in=${saleValue.join(',')}&`
      : `saleValue.contains=${saleValue}&`
    : '';
  const observationRequest = observation
    ? Array.isArray(status)
      ? `observation.in=${observation.join(',')}&`
      : `observation.contains=${observation}&`
    : '';
  const tableRequest = table ? (Array.isArray(status) ? `table.in=${table.join(',')}&` : `table.equals=${table}&`) : '';
  const percentRequest = percent ? (Array.isArray(status) ? `percent.in=${percent.join(',')}&` : `percent.equals=${percent}&`) : '';
  const inflatorRequest = inflator ? (Array.isArray(status) ? `inflator.in=${inflator.join(',')}&` : `inflator.contains=${inflator}&`) : '';
  const deflatorRequest = deflator ? (Array.isArray(status) ? `deflator.in=${deflator.join(',')}&` : `deflator.contains=${deflator}&`) : '';
  const rentalValueRequest = rentalValue
    ? Array.isArray(status)
      ? `rentalValue.in=${rentalValue.join(',')}&`
      : `rentalValue.contains=${rentalValue}&`
    : '';
  const tussCodeRequest = tussCode ? (Array.isArray(status) ? `tussCode.in=${tussCode.join(',')}&` : `tussCode.contains=${tussCode}&`) : '';
  const tissCodeRequest = tissCode ? (Array.isArray(status) ? `tissCode.in=${tissCode.join(',')}&` : `tissCode.contains=${tissCode}&`) : '';
  const tableCodeRequest = tableCode
    ? Array.isArray(status)
      ? `tableCode.in=${tableCode.join(',')}&`
      : `tableCode.contains=${tableCode}&`
    : '';
  const expenseCodeRequest = expenseCode
    ? Array.isArray(status)
      ? `expenseCode.in=${expenseCode.join(',')}&`
      : `expenseCode.contains=${expenseCode}&`
    : '';
  const frequencyRequest = frequency
    ? Array.isArray(status)
      ? `frequency.in=${frequency.join(',')}&`
      : `frequency.equals=${frequency}&`
    : '';
  const minValuePackageRequest = minValuePackage
    ? Array.isArray(status)
      ? `minValuePackage.in=${minValuePackage.join(',')}&`
      : `minValuePackage.contains=${minValuePackage}&`
    : '';
  const clComTableRequest = clComTableId ? `clComTable.id.in=${clComTableId}&` : '';
  const levelGroupRequest = levelGroupId ? `levelGroup.id.in=${levelGroupId}&` : '';
  const categoryRequest = categoryId ? `category.id.in=${categoryId}&` : '';
  const productServiceRequest = productServiceId ? `productServices.id.in=${productServiceId}&` : '';
  const specialtyRequest = specialtyId ? `specialties.id.in=${specialtyId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_CLCOMRULER_LIST,
    payload: axios.get<IClComRuler>(
      `${requestUrl}${extraFiltersRequest}${optionRequest}${comercialTableTypeRequest}${saleValueRequest}${observationRequest}${tableRequest}${percentRequest}${inflatorRequest}${deflatorRequest}${rentalValueRequest}${tussCodeRequest}${tissCodeRequest}${tableCodeRequest}${expenseCodeRequest}${frequencyRequest}${minValuePackageRequest}${clComTableRequest}${levelGroupRequest}${categoryRequest}${productServiceRequest}${specialtyRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const createEntity: ICrudPutAction<IClComRuler> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.CREATE_CLCOMRULER_AND_GO_LIST
        : ACTION_TYPES.CREATE_CLCOMRULER,
    payload: axios.post(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const updateEntity: ICrudPutAction<IClComRuler> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.UPDATE_CLCOMRULER_AND_GO_LIST
        : ACTION_TYPES.UPDATE_CLCOMRULER,
    payload: axios.put(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const deleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_CLCOMRULER,
    payload: axios.delete(requestUrl),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const setBlob = (name, data, contentType?, fileName?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType,
    fileName,
  },
});

export const showModal = view => ({
  payload: { type: view, show: true },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const hideModal = view => ({
  payload: { type: view, show: false },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const getUrlBack = (location): string => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  let urlBack = _urlBase.searchParams.get('urlBack') || '';
  urlBack = urlBack.trim();
  urlBack = urlBack.startsWith('/') ? urlBack.slice(1) : urlBack;
  urlBack = urlBack.endsWith('/') ? urlBack.slice(0, -1) : urlBack;
  return urlBack ? '/' + urlBack + '/' : '';
};

export const getClComRulerState = (location): IClComRulerBaseState => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  const extraFilters = jsonParse(_urlBase.searchParams.get('extraFilters') || '{}');
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;

  const option = _urlBase.searchParams.get('option') || '';
  const comercialTableType = _urlBase.searchParams.get('comercialTableType') || '';
  const saleValue = _urlBase.searchParams.get('saleValue') || '';
  const observation = _urlBase.searchParams.get('observation') || '';
  const table = _urlBase.searchParams.get('table') || '';
  const percent = _urlBase.searchParams.get('percent') || '';
  const inflator = _urlBase.searchParams.get('inflator') || '';
  const deflator = _urlBase.searchParams.get('deflator') || '';
  const rentalValue = _urlBase.searchParams.get('rentalValue') || '';
  const tussCode = _urlBase.searchParams.get('tussCode') || '';
  const tissCode = _urlBase.searchParams.get('tissCode') || '';
  const tableCode = _urlBase.searchParams.get('tableCode') || '';
  const expenseCode = _urlBase.searchParams.get('expenseCode') || '';
  const frequency = _urlBase.searchParams.get('frequency') || '';
  const minValuePackage = _urlBase.searchParams.get('minValuePackage') || '';
  const clComTableId = _urlBase.searchParams.get('clComTable') || '';
  const levelGroupId = _urlBase.searchParams.get('levelGroup') || '';
  const categoryId = _urlBase.searchParams.get('category') || '';
  const productServiceId = _urlBase.searchParams.get('productService') || '';
  const specialtyId = _urlBase.searchParams.get('specialty') || '';
  return {
    baseFilters,
    extraFilters,
    offset,
    option,
    comercialTableType,
    saleValue,
    observation,
    table,
    percent,
    inflator,
    deflator,
    rentalValue,
    tussCode,
    tissCode,
    tableCode,
    expenseCode,
    frequency,
    minValuePackage,
    clComTableId: clComTableId ? clComTableId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
    levelGroupId: levelGroupId ? levelGroupId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
    categoryId: categoryId ? categoryId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
    productServiceId: productServiceId
      ? productServiceId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] }))
      : [],
    specialtyId: specialtyId ? specialtyId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
  };
};

export const getEntityFiltersURL = (state, offset = null) => {
  return (
    'baseFilters=' +
    state.baseFilters +
    '&extraFilters=' +
    encodeURI(JSON.stringify(state.extraFilters)) +
    '&page=' +
    state.activePage +
    '&' +
    'size=' +
    state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    state.sort +
    ',' +
    state.order +
    '&' +
    (state.option ? 'option=' + state.option + '&' : '') +
    (state.comercialTableType ? 'comercialTableType=' + state.comercialTableType + '&' : '') +
    (state.saleValue ? 'saleValue=' + state.saleValue + '&' : '') +
    (state.observation ? 'observation=' + state.observation + '&' : '') +
    (state.table ? 'table=' + state.table + '&' : '') +
    (state.percent ? 'percent=' + state.percent + '&' : '') +
    (state.inflator ? 'inflator=' + state.inflator + '&' : '') +
    (state.deflator ? 'deflator=' + state.deflator + '&' : '') +
    (state.rentalValue ? 'rentalValue=' + state.rentalValue + '&' : '') +
    (state.tussCode ? 'tussCode=' + state.tussCode + '&' : '') +
    (state.tissCode ? 'tissCode=' + state.tissCode + '&' : '') +
    (state.tableCode ? 'tableCode=' + state.tableCode + '&' : '') +
    (state.expenseCode ? 'expenseCode=' + state.expenseCode + '&' : '') +
    (state.frequency ? 'frequency=' + state.frequency + '&' : '') +
    (state.minValuePackage ? 'minValuePackage=' + state.minValuePackage + '&' : '') +
    (state.clComTableId ? 'clComTable=' + state.clComTableId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.levelGroupId ? 'levelGroup=' + state.levelGroupId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.categoryId ? 'category=' + state.categoryId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.productServiceId ? 'productService=' + state.productServiceId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.specialtyId ? 'specialty=' + state.specialtyId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    ''
  );
};
