import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { IClComTable } from 'app/shared/model/cl-com-table.model';
import { ILevelGroup } from 'app/shared/model/level-group.model';
import { ICategory } from 'app/shared/model/category.model';
import { IMedicineMaterial } from 'app/shared/model/medicine-material.model';
import { ComercialTableType } from 'app/shared/model/enumerations/comercial-table-type.model';
import { EnumTable } from 'app/shared/model/enumerations/enum-table.model';

export interface IClComItem {
  id?: number;
  comercialTableType?: ComercialTableType;
  table?: EnumTable;
  tussCode?: string;
  tissCode?: string;
  tableCode?: string;
  expenseCode?: string;
  saleValue?: number;
  percent?: number;
  inflator?: string;
  deflator?: string;
  rentalValue?: number;
  observation?: any;
  clComTable?: IClComTable;
  levelGroup?: ILevelGroup;
  category?: ICategory;
  materialMedicine?: IMedicineMaterial;
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  clComTable: {
    isMulti: true, // many-to-one - other-side
    entity: 'ClComTable',
    urlParameters: {
      enpoint: 'cl-com-tables',
      filters: 'clComItem.id.equals',
    },
  },
  levelGroup: {
    isMulti: true, // many-to-one - other-side
    entity: 'LevelGroup',
    urlParameters: {
      enpoint: 'level-groups',
      filters: 'clComItem.id.equals',
    },
  },
  category: {
    isMulti: true, // many-to-one - other-side
    entity: 'Category',
    urlParameters: {
      enpoint: 'categories',
      filters: 'clComItem.id.equals',
    },
  },
  medicineMaterial: {
    isMulti: true, // many-to-one - other-side
    entity: 'MedicineMaterial',
    urlParameters: {
      enpoint: 'medicine-materials',
      filters: 'clComItem.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'clComItem.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'clComItem.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<IClComItem> = {};
