/* eslint complexity: ["error", 500] */
/* eslint no-empty: off */
import React from 'react';
import { connect } from 'react-redux';

import SelectAsync from 'react-select/async';

import { Link, RouteComponentProps } from 'react-router-dom';

import { getFilterFromSelect, getFormFromSelect, getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Col, Label } from 'reactstrap';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import {
  IExpectedResultUpdateState as IBaseUpdateState,
  getEntity,
  getExpectedResultState,
  getUrlBack,
  updateEntity,
  createEntity,
  getEntityFiltersURL,
  getEntityOffset,
  reset,
} from './expected-result.reducer';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

registerLocale('pt', pt);

export interface IExpectedResultUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}
export type IExpectedResultUpdateState = IBaseUpdateState;

export class ExpectedResultUpdate extends React.Component<IExpectedResultUpdateProps, IExpectedResultUpdateState> {
  resultFileInput: any;
  constructor(props: Readonly<IExpectedResultUpdateProps>) {
    super(props);

    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      functionEmbebed: [],

      quoteRhSelectValue: null,

      quoteSelectValue: null,

      resultSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getExpectedResultState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
      quoteRhId: null,
      quoteSource: null,

      isNew: !this.props.match.params || !this.props.match.params['id'],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateSuccess !== this.props.updateSuccess && prevProps.updateSuccess) {
      this.handleClose();
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.expectedResultEntity && prevProps.expectedResultEntity.id !== this.props.expectedResultEntity.id) ||
      (this.props.quoteRhs &&
        this.state.quoteRhSelectValue === null &&
        this.props.expectedResultEntity.quoteRh &&
        this.props.expectedResultEntity.quoteRh.id)
    ) {
      const p = this.props.expectedResultEntity && this.props.expectedResultEntity.quoteRh ? this.props.expectedResultEntity.quoteRh : null;
      this.setState({
        quoteRhSelectValue: p ? { ...p, value: p.id, label: p['id'] ? p.id : '' } : null,
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.expectedResultEntity && prevProps.expectedResultEntity.id !== this.props.expectedResultEntity.id) ||
      (this.props.quotes &&
        this.state.quoteSelectValue === null &&
        this.props.expectedResultEntity.quote &&
        this.props.expectedResultEntity.quote.id)
    ) {
      const p = this.props.expectedResultEntity && this.props.expectedResultEntity.quote ? this.props.expectedResultEntity.quote : null;
      this.setState({
        quoteSelectValue: p ? { ...p, value: p.id, label: p['source'] ? p.source : '' } : null,
      });
    }

    if (
      (prevProps.expectedResultEntity && prevProps.expectedResultEntity.id !== this.props.expectedResultEntity.id) ||
      (this.props.expectedResultEntity.result &&
        this.state.resultSelectValue === null &&
        this.props.expectedResultEntity.result !== this.state.resultSelectValue)
    ) {
      this.setState({ resultSelectValue: this.props.expectedResultEntity.result ? this.props.expectedResultEntity.result : null });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  UNSAFE_componentWillMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params['id']);
    }
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = offset !== null ? '&offset=' + offset : '';
    Object.keys(fieldsBase).map(key => {
      url += key !== 'offset' ? '&' + key + '=' + fieldsBase[key] : '';
    });
    return url;
  };
  saveEntity = (event: any, errors: any, values: any) => {
    const selectFieldsList = ['result', 'quoteRh.id', 'quote.source'];

    if (errors.length === 0) {
      const { expectedResultEntity } = this.props;
      const _entity = {
        ...expectedResultEntity,
        // ...values,

        quoteRh: getFormFromSelect(this.state.quoteRhSelectValue, 'many-to-one'), // many-to-one - otherSide

        quote: getFormFromSelect(this.state.quoteSelectValue, 'many-to-one'), // many-to-one - otherSide

        result: this.state.resultSelectValue,
      };

      const entity = _entity;

      const { result, quoteRhId, quoteId, extraFilters, activePage, itemsPerPage, sort, order } = this.state.fieldsBase;

      if (this.state.isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              result,
              getFilterFromSelect(quoteRhId, 'many-to-one'),
              getFilterFromSelect(quoteId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              result,
              getFilterFromSelect(quoteRhId, 'many-to-one'),
              getFilterFromSelect(quoteId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/expected-result?' + getEntityFiltersURL(this.state.fieldsBase));
  };

  getFullEntityFromState(v) {
    return {
      id: v.id,
      result: this.state.resultSelectValue,
      quoteRh: this.state.quoteRhSelectValue,
      quote: this.state.quoteSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    const quoteRhEntity = v.quoteRh;

    const quoteEntity = v.quote;

    return {
      resultSelectValue: v.result,
      quoteRhSelectValue: quoteRhEntity ? quoteRhEntity['id'] : null,
      quoteSelectValue: quoteEntity ? quoteEntity['id'] : null,
    };
  }

  renderHeader() {
    const { expectedResultEntity, quoteRhs, quotes, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.expectedResult.home.createLabel">Create a ExpectedResult</Translate>
            ) : (
              <Translate contentKey="generadorApp.expectedResult.home.editLabel">Edit a ExpectedResult</Translate>
            )}
          </span>

          <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.expectedResult.btnSave">Save</Translate>
          </Button>

          {!this.state.isNew ? (
            <>
              <Button
                color="primary"
                id="next-entity"
                disabled={updating}
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.result,
                    this.state.fieldsBase.quoteRhId,
                    this.state.fieldsBase.quoteId,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 + 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 + 1 },
                    });
                    this.props.history.push(
                      '/expected-result/' +
                        myresult.value.data[0]['id'] +
                        '/edit?' +
                        this.getFiltersURL(this.state.fieldsBase.offset * 1 + 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.last.item', { fieldName: translate('generadorApp.expectedResult.detail.title') }));
                  }
                }}
                className="float-right jh-create-entity"
              >
                {'>>'}
              </Button>

              <Button
                color="primary"
                id="prev-entity"
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.result,
                    this.state.fieldsBase.quoteRhId,
                    this.state.fieldsBase.quoteId,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 - 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 - 1 },
                    });
                    this.props.history.push(
                      '/expected-result/' +
                        myresult.value.data[0]['id'] +
                        '/edit?' +
                        this.getFiltersURL(this.state.fieldsBase.offset * 1 - 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.first.item', { fieldName: translate('generadorApp.expectedResult.detail.title') }));
                  }
                }}
                disabled={updating}
                className="float-right jh-create-entity"
              >
                {'<<'}
              </Button>
            </>
          ) : (
            <> </>
          )}

          <Button
            tag={Link}
            id="cancel-save"
            to={'/expected-result?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.expectedResult.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={'/expected-result'}>
              <Translate contentKey="generadorApp.expectedResult.home.title">Expected Results</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.expectedResult.detail.title">Expected Result edit</Translate>
          </li>
        </ol>
      </>
    );
  }
  renderBody() {
    const { expectedResultEntity, quoteRhs, quotes, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const fullEntityEmbebed = this.getFullEntityFromState(expectedResultEntity);

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="expected-result-id">
                  <Translate contentKey="generadorApp.expectedResult.field_id">ID</Translate>
                </Label>
                </Col> */}
                      <Col md="12">
                        <AvInput id="expected-result-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'result' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="resultLabel" for="expected-result-result">
                              <Translate contentKey="generadorApp.expectedResult.result">Result</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ resultSelectValue: evt.target.value })}
                              value={this.state.resultSelectValue ? this.state.resultSelectValue : ''}
                              id="expected-result-result"
                              type="text"
                              name="result"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ resultSelectValue: evt.target.value })}
                      type="hidden"
                      name="result"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'quoteRh' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="expected-result-quoteRh">
                              <Translate contentKey="generadorApp.expectedResult.quoteRh">Quote Rh</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="expected-result-quoteRh"
                              name={'quoteRh'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.quoteRhSelectValue ? this.state.quoteRhSelectValue : ''}
                              onChange={options => this.setState({ quoteRhSelectValue: options })}
                              defaultOptions={this.state.quoteRhStartSelectOptions ? this.state.quoteRhStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.quoteRhStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.quoteRhStartSelectOptions === undefined) {
                                  const result = await getListAxios('quote-rhs', {}, 0, 100, 'id,asc', 'id');
                                  this.setState({
                                    quoteRhStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios('quote-rhs', { 'id.contains': inputValue }, 0, 100, 'id,asc', 'id');
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="quoteRh"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}
                  {baseFilters !== 'quote' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="expected-result-quote">
                              <Translate contentKey="generadorApp.expectedResult.quote">Quote</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="expected-result-quote"
                              name={'quote'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.quoteSelectValue ? this.state.quoteSelectValue : ''}
                              onChange={options => this.setState({ quoteSelectValue: options })}
                              defaultOptions={this.state.quoteStartSelectOptions ? this.state.quoteStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.quoteStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.quoteStartSelectOptions === undefined) {
                                  const result = await getListAxios('quotes', {}, 0, 100, 'source,asc', 'source');
                                  this.setState({
                                    quoteStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'source') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'quotes',
                                  { 'source.contains': inputValue },
                                  0,
                                  100,
                                  'source,asc',
                                  'source'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'source') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="quote"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}{' '}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { expectedResultEntity, quoteRhs, quotes, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...expectedResultEntity,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel className="expected-result expected-result-update">
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToProps = (storeState: IRootState) => {
  return {
    quoteRhs: storeState.quoteRh.entities,
    quotes: storeState.quote.entities,
    expectedResultEntity: storeState.expectedResult.entity,
    loading: storeState.expectedResult.loading,
    updating: storeState.expectedResult.updating,
    updateSuccess: storeState.expectedResult.updateSuccess,
  };
};

export const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  getEntityOffset,
  reset,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ExpectedResultUpdate);
