import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import { Translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import {
  getEntity,
  IPatientDoctorPrescriptionBaseState,
  getUrlBack,
  getPatientDoctorPrescriptionState,
  getEntityFiltersURL,
} from './patient-doctor-prescription.reducer';

import { PatDrPrescrProcdDetail } from '../pat-dr-prescr-procd/pat-dr-prescr-procd-detail';
import {
  getEntity as getEntityPatDrPrescrProcd,
  convertEntityBuffers as convertEntityBuffersPatDrPrescrProcd,
} from '../pat-dr-prescr-procd/pat-dr-prescr-procd.reducer';

export interface IPatientDoctorPrescriptionState {
  fieldsBase: IPatientDoctorPrescriptionBaseState;
}

export interface IPatientDoctorPrescriptionDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class PatientDoctorPrescriptionDetail extends React.Component<
  IPatientDoctorPrescriptionDetailProps,
  IPatientDoctorPrescriptionState
> {
  constructor(props: Readonly<IPatientDoctorPrescriptionDetailProps>) {
    super(props);
    this.state = {
      ...this.state,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getPatientDoctorPrescriptionState(this.props.location),
        urlBack: getUrlBack(this.props.location),
        patientId: [{ value: this.props.match.params['idPatient'], label: this.props.match.params['idPatient'] }],
        baseFilters: 'patient',
      },
    };
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = '_back=1' + (offset !== null ? '&offset=' + offset : '');
    Object.keys(fieldsBase).map(key => {
      url += '&' + key + '=' + fieldsBase[key];
    });
    return url;
  };

  UNSAFE_componentWillMount() {
    this.props.getEntity(this.props.match.params['id']);
  }

  renderHeader() {
    const { patientDoctorPrescriptionEntity } = this.props;
    const state = this.state;
    return (
      <>
        <div id="page-heading">
          {' '}
          <h2>
            <Translate contentKey="generadorApp.patientDoctorPrescription.detail.title">PatientDoctorPrescription</Translate>
            <small>&nbsp; {patientDoctorPrescriptionEntity['observation']}</small>
          </h2>
        </div>

        <div className="pull-right">
          <Button
            tag={Link}
            to={
              '/patient/' +
              this.props.match.params['idPatient'] +
              '/patient-doctor-prescription?' +
              getEntityFiltersURL(this.state.fieldsBase)
            }
            replace
            color="info"
          >
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.patientDoctorPrescription.btnBack">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/patient/${this.props.match.params['idPatient']}/patient-doctor-prescription/${
              patientDoctorPrescriptionEntity.id
            }/edit?+${getEntityFiltersURL(this.state.fieldsBase)}`}
            replace
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.patientDoctorPrescription.btnEdit">Edit</Translate>
            </span>
          </Button>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>

          <li className="breadcrumb-item">
            <Link to={'/patient'}>Patients</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/patient/' + this.props.match.params['idPatient']}>{this.props.match.params['idPatient']}</Link>
          </li>
          <li className="breadcrumb-item">
            <Link
              to={
                '/patient/' +
                (this.state.fieldsBase.patientId ? this.state.fieldsBase.patientId.label : '') +
                '/patient-doctor-prescription'
              }
            >
              Patient doctor prescriptions
            </Link>
          </li>
          <li className="breadcrumb-item active">Patient doctor prescriptions details</li>
        </ol>
      </>
    );
  }

  renderBody() {
    const { patientDoctorPrescriptionEntity } = this.props;
    const state = this.state;
    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            <Row className="jh-entity-details">
              <Col className="view-detail-row patient-doctor-prescription-observation-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top patient-doctor-prescription-observation-detail-label" md="12">
                    <dt>
                      <span id="observation">
                        <Translate contentKey="generadorApp.patientDoctorPrescription.observation">Observations</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top patient-doctor-prescription-observation-detail-value" md="12">
                    <dd>{patientDoctorPrescriptionEntity.observation}</dd>
                  </Col>
                </Row>
              </Col>

              {patientDoctorPrescriptionEntity.patDrPrescrProcd ? (
                patientDoctorPrescriptionEntity.patDrPrescrProcd.map((v, i) => (
                  <div key={i} className="entityEmbebed">
                    <PatDrPrescrProcdDetail
                      history={this.props.history}
                      location={{
                        pathname: `/patient-doctor-prescription/${this.props.match.params['id']}/pat-dr-prescr-procd/${v.id}`,
                        search: '',
                        hash: '',
                        state: undefined,
                        key: 'q2tbhd',
                      }}
                      match={{
                        path: `/patient-doctor-prescription/:idPatientDoctorPrescription/pat-dr-prescr-procd/:id`,
                        url: `/patient-doctor-prescription/${this.props.match.params['id']}/pat-dr-prescr-procd/${v.id}`,
                        isExact: true,
                        params: { id: '' + v.id },
                      }}
                      patDrPrescrProcdEntity={convertEntityBuffersPatDrPrescrProcd(v)}
                      getEntity={(): any => {}}
                    />
                  </div>
                ))
              ) : (
                <> </>
              )}
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { patientDoctorPrescriptionEntity } = this.props;
    const state = this.state;
    return (
      <div>
        <Panel className="patient-doctor-prescription patient-doctor-prescription-detail">
          <PanelHeader>{this.renderHeader()}</PanelHeader>

          <PanelBody>{this.renderBody()}</PanelBody>
        </Panel>
      </div>
    );
  }
}

export const mapStateToProps = ({ patientDoctorPrescription, ...storeState }: IRootState) => ({
  patientDoctorPrescriptionEntity: patientDoctorPrescription.entity,
  patDrPrescrProcdEntity: storeState.patDrPrescrProcd.entity,
});

export const mapDispatchToProps = {
  getEntity,
  getEntityPatDrPrescrProcd,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PatientDoctorPrescriptionDetail);
