import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import LevelGroup from './level-group';

import LevelGroupUpdate from './level-group';

import LevelGroupStatusHistory from '../level-group-status-history/index';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute path={`${match.path}/:idLevelGroup/level-group-status-history`} component={LevelGroupStatusHistory} />

      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={LevelGroupUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={LevelGroupUpdate} />
      <ErrorBoundaryRoute path={match.path} component={LevelGroup} />
    </Switch>

    <Switch>
      {/* <ErrorBoundaryRoute path={`${match.path}/:idLevelGroup/level-group-status-history`} component={LevelGroupStatusHistory} /> */}
    </Switch>
  </>
);

export default Routes;
