import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { IPatientDoctorPrescription } from 'app/shared/model/patient-doctor-prescription.model';
import { IPatientNursingPrescription } from 'app/shared/model/patient-nursing-prescription.model';
import { IPatientStatusHistory } from 'app/shared/model/patient-status-history.model';
import { IPatientContact } from 'app/shared/model/patient-contact.model';
import { IPatientClinicalIndicator } from 'app/shared/model/patient-clinical-indicator.model';
import { IPatientCid } from 'app/shared/model/patient-cid.model';
import { IPatientAllergy } from 'app/shared/model/patient-allergy.model';
import { IPatientSupplier } from 'app/shared/model/patient-supplier.model';
import { IQuote } from 'app/shared/model/quote.model';
import { IMedicalRecord } from 'app/shared/model/medical-record.model';
import { INursingDiary } from 'app/shared/model/nursing-diary.model';
import { INursingPrescription } from 'app/shared/model/nursing-prescription.model';
import { IPatientFile } from 'app/shared/model/patient-file.model';
import { IPatientResponsible } from 'app/shared/model/patient-responsible.model';
import { IPad } from 'app/shared/model/pad.model';
import { IPush } from 'app/shared/model/push.model';
import { IPatientDevice } from 'app/shared/model/patient-device.model';
import { IProfessional } from 'app/shared/model/professional.model';
import { ISupplier } from 'app/shared/model/supplier.model';
import { IClient } from 'app/shared/model/client.model';
import { Sex } from 'app/shared/model/enumerations/sex.model';
import { GeralSelect } from 'app/shared/model/enumerations/geral-select.model';
import { PatientStatus } from 'app/shared/model/enumerations/patient-status.model';
import { ADID } from 'app/shared/model/enumerations/adid.model';
import { PatientComplexity } from 'app/shared/model/enumerations/patient-complexity.model';

export interface IPatient {
  id?: number;
  name?: string;
  cpf?: string;
  rg?: string;
  sex?: Sex;
  birthDate?: Moment;
  weight?: number;
  height?: number;
  healthPlanEnrollment?: string;
  liminar?: GeralSelect;
  observations?: any;
  informationProfessional?: string;
  register?: boolean;
  zipCode?: string;
  hospitalReference?: string;
  street?: string;
  complement?: string;
  number?: string;
  neighborhood?: string;
  city?: string;
  uf?: string;
  reference?: string;
  lat?: number;
  lng?: number;
  status?: PatientStatus;
  adId?: ADID;
  nead?: number;
  patientComplexity?: PatientComplexity;
  obese?: GeralSelect;
  patientDoctorPrescription?: IPatientDoctorPrescription;
  patientNursingPrescription?: IPatientNursingPrescription;
  patientStatusHistory?: IPatientStatusHistory[];
  patientContact?: IPatientContact[];
  patientClinicalIndicator?: IPatientClinicalIndicator[];
  patientCid?: IPatientCid[];
  patientAllergy?: IPatientAllergy[];
  patientSupplier?: IPatientSupplier[];
  quote?: IQuote[];
  medicalRecord?: IMedicalRecord[];
  nursingDiary?: INursingDiary[];
  nursingPrescription?: INursingPrescription[];
  patientFile?: IPatientFile[];
  patientResponsible?: IPatientResponsible[];
  pad?: IPad[];
  push?: IPush[];
  patientDevices?: IPatientDevice[];
  professionalBlockeds?: IProfessional[];
  professionalPreferreds?: IProfessional[];
  suppliers?: ISupplier[];
  client?: IClient;
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  patientDoctorPrescription: {
    isMulti: false, // one-to-one - owner-side
    entity: 'PatientDoctorPrescription',
    urlParameters: {
      enpoint: 'patient-doctor-prescriptions',
      filters: 'patient.id.equals',
    },
  },
  patientNursingPrescription: {
    isMulti: false, // one-to-one - owner-side
    entity: 'PatientNursingPrescription',
    urlParameters: {
      enpoint: 'patient-nursing-prescriptions',
      filters: 'patient.id.equals',
    },
  },
  patientStatusHistory: {
    isMulti: false, // one-to-many - other-side
    entity: 'PatientStatusHistory',
    urlParameters: {
      enpoint: 'patient-status-histories',
      filters: 'patient.id.equals',
    },
  },
  patientContact: {
    isMulti: false, // one-to-many - other-side
    entity: 'PatientContact',
    urlParameters: {
      enpoint: 'patient-contacts',
      filters: 'patient.id.equals',
    },
  },
  patientClinicalIndicator: {
    isMulti: false, // one-to-many - other-side
    entity: 'PatientClinicalIndicator',
    urlParameters: {
      enpoint: 'patient-clinical-indicators',
      filters: 'patient.id.equals',
    },
  },
  patientCid: {
    isMulti: false, // one-to-many - other-side
    entity: 'PatientCid',
    urlParameters: {
      enpoint: 'patient-cids',
      filters: 'patient.id.equals',
    },
  },
  patientAllergy: {
    isMulti: false, // one-to-many - other-side
    entity: 'PatientAllergy',
    urlParameters: {
      enpoint: 'patient-allergies',
      filters: 'patient.id.equals',
    },
  },
  patientSupplier: {
    isMulti: false, // one-to-many - other-side
    entity: 'PatientSupplier',
    urlParameters: {
      enpoint: 'patient-suppliers',
      filters: 'patient.id.equals',
    },
  },
  quote: {
    isMulti: false, // one-to-many - other-side
    entity: 'Quote',
    urlParameters: {
      enpoint: 'quotes',
      filters: 'patient.id.equals',
    },
  },
  medicalRecord: {
    isMulti: false, // one-to-many - other-side
    entity: 'MedicalRecord',
    urlParameters: {
      enpoint: 'medical-records',
      filters: 'patient.id.equals',
    },
  },
  nursingDiary: {
    isMulti: false, // one-to-many - other-side
    entity: 'NursingDiary',
    urlParameters: {
      enpoint: 'nursing-diaries',
      filters: 'patient.id.equals',
    },
  },
  nursingPrescription: {
    isMulti: false, // one-to-many - other-side
    entity: 'NursingPrescription',
    urlParameters: {
      enpoint: 'nursing-prescriptions',
      filters: 'patient.id.equals',
    },
  },
  patientFile: {
    isMulti: false, // one-to-many - other-side
    entity: 'PatientFile',
    urlParameters: {
      enpoint: 'patient-files',
      filters: 'patient.id.equals',
    },
  },
  patientResponsible: {
    isMulti: false, // one-to-many - other-side
    entity: 'PatientResponsible',
    urlParameters: {
      enpoint: 'patient-responsibles',
      filters: 'patient.id.equals',
    },
  },
  pad: {
    isMulti: false, // one-to-many - other-side
    entity: 'Pad',
    urlParameters: {
      enpoint: 'pads',
      filters: 'patient.id.equals',
    },
  },
  push: {
    isMulti: false, // one-to-many - other-side
    entity: 'Push',
    urlParameters: {
      enpoint: 'pushes',
      filters: 'patient.id.equals',
    },
  },
  patientDevice: {
    isMulti: true, // many-to-many - owner-side
    entity: 'PatientDevice',
    urlParameters: {
      enpoint: 'patient-devices',
      filters: 'patients.id.equals',
    },
  },
  professional: {
    isMulti: true, // many-to-many - owner-side
    entity: 'Professional',
    urlParameters: {
      enpoint: 'professionals',
      filters: 'patientBlockeds.id.equals',
    },
  },
  professional: {
    isMulti: true, // many-to-many - owner-side
    entity: 'Professional',
    urlParameters: {
      enpoint: 'professionals',
      filters: 'patientPreferreds.id.equals',
    },
  },
  supplier: {
    isMulti: true, // many-to-many - owner-side
    entity: 'Supplier',
    urlParameters: {
      enpoint: 'suppliers',
      filters: 'patients.id.equals',
    },
  },
  client: {
    isMulti: true, // many-to-one - other-side
    entity: 'Client',
    urlParameters: {
      enpoint: 'clients',
      filters: 'patient.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'patient.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'patient.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<IPatient> = {
  register: false,
};
