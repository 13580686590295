import { prettifyXml } from 'app/shared/util/entity-utils';
import React from 'react';

export interface IBillingXmlProps {
  baseState?: any;
  baseProps?: any;
  setState?: any;
  entityBase?: any;
}

const BillingXmlComponentCustomize = (props: IBillingXmlProps) => {
  return <> </>;
  return (
    <pre style={{ textAlign: 'left', height: '400px' }}>
      <div
        dangerouslySetInnerHTML={{
          __html:
            '<b>&lt;?xml version="1.0" encoding="ISO-8859-1"?&gt;</b> \n' +
            prettifyXml(props.entityBase.xml)
              .split('<')
              .join('bb_bb&lt;')
              .split('>')
              .join('&gt;ff_ff')
              .split('bb_bb')
              .join('<b>')
              .split('ff_ff')
              .join('</b>'),
        }}
      />
    </pre>
  );
};

const mapStateToProps = storeState => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default BillingXmlComponentCustomize;
