export const enum QuoteStatus {
  ORCAMENTOABERTO = 'ORCAMENTOABERTO',
  AGUARDANDOPRECIFICACAO = 'AGUARDANDOPRECIFICACAO',
  ORCAMENTOPRECIFICADO = 'ORCAMENTOPRECIFICADO',
  AGUARDANDOAPROVACAO = 'AGUARDANDOAPROVACAO',
  PLANTAOAPROVADO = 'PLANTAOAPROVADO',
  APROVADO = 'APROVADO',
  INATIVOREPROVADOPRECO = 'INATIVOREPROVADOPRECO',
  INATIVOCONTROLEINTERNO = 'INATIVOCONTROLEINTERNO',
  CANCELADO = 'CANCELADO',
}
