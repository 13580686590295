import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { IMedicalRecordFile } from 'app/shared/model/medical-record-file.model';
import { IConfigTag } from 'app/shared/model/config-tag.model';
import { IClinicalIndicator } from 'app/shared/model/clinical-indicator.model';
import { IPatient } from 'app/shared/model/patient.model';
import { ISpecialty } from 'app/shared/model/specialty.model';
import { Exam } from 'app/shared/model/enumerations/exam.model';
import { ExamType } from 'app/shared/model/enumerations/exam-type.model';
import { Ocurrence } from 'app/shared/model/enumerations/ocurrence.model';
import { Manifestation } from 'app/shared/model/enumerations/manifestation.model';

export interface IMedicalRecord {
  id?: number;
  passageReason?: string;
  fall?: string;
  clinicalInstability?: string;
  exam?: Exam;
  examType?: ExamType;
  hospitalizationDate?: Moment;
  dischargeDate?: Moment;
  hospitalizationReason?: string;
  occurrence?: Ocurrence;
  manifestation?: Manifestation;
  compliment?: string;
  complaint?: string;
  suggestion?: string;
  additionalInformation?: string;
  type?: string;
  date?: Moment;
  reason?: string;
  conduct?: string;
  record?: boolean;
  highlighted?: boolean;
  medicalRecordFile?: IMedicalRecordFile[];
  configTags?: IConfigTag[];
  clinicalIndicators?: IClinicalIndicator[];
  patient?: IPatient;
  specialty?: ISpecialty;
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  medicalRecordFile: {
    isMulti: false, // one-to-many - other-side
    entity: 'MedicalRecordFile',
    urlParameters: {
      enpoint: 'medical-record-files',
      filters: 'medicalRecord.id.equals',
    },
  },
  configTag: {
    isMulti: true, // many-to-many - owner-side
    entity: 'ConfigTag',
    urlParameters: {
      enpoint: 'config-tags',
      filters: 'medicalRecords.id.equals',
    },
  },
  clinicalIndicator: {
    isMulti: true, // many-to-many - owner-side
    entity: 'ClinicalIndicator',
    urlParameters: {
      enpoint: 'clinical-indicators',
      filters: 'medicalRecords.id.equals',
    },
  },
  patient: {
    isMulti: true, // many-to-one - other-side
    entity: 'Patient',
    urlParameters: {
      enpoint: 'patients',
      filters: 'medicalRecord.id.equals',
    },
  },
  specialty: {
    isMulti: true, // many-to-one - other-side
    entity: 'Specialty',
    urlParameters: {
      enpoint: 'specialties',
      filters: 'medicalRecord.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'medicalRecord.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'medicalRecord.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<IMedicalRecord> = {
  record: false,
  highlighted: false,
};
