/* eslint-disable no-console */
/* eslint complexity: ["error", 300] */
import React from 'react';
import { connect } from 'react-redux';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { QuoteProductService, mapStateToProps, mapDispatchToProps } from '../quote-product-service';
import { IRootState } from 'app/shared/reducers';
import { ClComTable } from 'app/entities/cl-com-table/cl-com-table';
import CurrencyInput from 'react-currency-input';
import SelectAsync from 'react-select/async';

import { Button, Col, Row, Label, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';

import { TextFormat } from 'react-jhipster';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';
import moment from 'moment';

import { QuoteDiscountType } from 'app/shared/model/enumerations/quote-discount-type.model';
import { IQuote } from 'app/shared/model/quote.model';
import { convertDateTimeToServer } from 'app/shared/util/date-utils';
import { toast } from 'react-toastify';
import { TypeClientCommercialTable } from 'app/shared/model/enumerations/type-client-commercial-table.model';
import { Sub } from 'app/shared/model/enumerations/sub.model';
import { IProductService } from 'app/shared/model/product-service.model';
import { getFormFromSelect, getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import { IClComTable } from 'app/shared/model/cl-com-table.model';

import { ICategory } from 'app/shared/model/category.model';

import { ServiceType } from 'app/shared/model/enumerations/service-type.model';
import { QuoteProductServiceFrequency } from 'app/shared/model/enumerations/quote-product-service-frequency.model';
import { SuprimentoStatus } from 'app/shared/model/enumerations/suprimento-status.model';
import Axios from 'axios';
registerLocale('pt', pt);

export interface IExtendedState {
  frequencyEnabled?: boolean;
  unitaryAmountEnabled?: boolean;
  billListFinalAmountSelectValue?: number[];
  billListGrossAmountSelectValue?: number[];
  billListQuantityItemSelectValue?: number[];
  clComTableSelectValue: IClComTable;
  clComTableStartSelectOptions: IClComTable[];
  categorySelectValue: ICategory;
  categoryStartSelectOptions: ICategory[];
}
export interface IExtendedProps {
  clComTable?: ClComTable;
  clComTableRulers?: any;
  clComTableItens?: any;
  quote?: IQuote;
  typeView?: string;
}

export class QuoteProductServiceExtended extends QuoteProductService {
  constructor(props: any) {
    super(props);
    this.state = {
      ...this.state,
      billListFinalAmountSelectValue: [],
      billListGrossAmountSelectValue: [],
      billListQuantityItemSelectValue: [],
    };
  }

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  renderHeader() {
    const { quotes, entityEmbebedPai } = this.props;

    return entityEmbebedPai &&
      ['APROVADO', 'PRORROGADO', 'INATIVOREPROVADOPRECO', 'INATIVOCONTROLEINTERNO'].includes(entityEmbebedPai.status) ? (
      <div id="page-heading">
        <span className="page-header">
          <Translate contentKey="generadorApp.quoteProductService.home.title">quoteProductService</Translate>
        </span>

        {!['Bill'].includes(this.props.typeView) ? (
          <Button
            onClick={() =>
              this.setState({
                showModalForm: true,
                startDateSelectValue: null,
                finishDateSelectValue: null,
                daysSelectValue: null,
                quantitySelectValue: null,
                unitaryAmountSelectValue: null,
                grossAmountSelectValue: null,
                typeDiscountSelectValue: null,
                discountPercentSelectValue: null,
                discountSelectValue: null,
                finalAmountSelectValue: null,
                frequencySelectValue: null,
                additiveSelectValue: null,
                billQuantityItemSelectValue: null,
                billGrossAmountSelectValue: null,
                billFinalAmountSelectValue: null,

                productServiceStartSelectOptions: undefined,
                productServiceSelectValue: null,
                isNew: true,
              })
            }
            color="primary"
            className="btn btn-primary float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="generadorApp.quote.btnAddAdditive">Add Additive</Translate>
          </Button>
        ) : (
          <> </>
        )}
      </div>
    ) : (
      <> {super.renderHeader()} </>
    );
  }

  renderFilter() {
    return <> </>;
  }

  renderTable() {
    const { quotes, quoteProductServiceList, entityEmbebedPai } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    const quoteProductServiceListActives = quoteProductServiceList
      ? quoteProductServiceList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
      : [];

    const listQuoteItemWithValues =
      entityEmbebedPai && entityEmbebedPai.quoteProductService
        ? entityEmbebedPai.quoteProductService
            .filter(quoteProductService => !['Bill'].includes(this.props.typeView) || quoteProductService.billFinalAmount > 0)
            .map(v => v.id)
        : quoteProductServiceListActives;

    console.info(listQuoteItemWithValues);

    return (
      <>
        {quoteProductServiceListActives && quoteProductServiceListActives.length > 0 && listQuoteItemWithValues.length > 0 ? (
          <Table
            id="quote-product-service-table-list"
            responsive
            aria-describedby="quote-product-service-heading"
            className={'table-hover table-striped table-responsive-css'}
          >
            <thead className={'thead-light'}>
              <tr>
                <th>
                  <Translate contentKey="generadorApp.quoteProductService.productService">ID</Translate>
                </th>
                <th>
                  <Translate contentKey="generadorApp.quoteProductService.home.title">ProductService</Translate>
                </th>
                {state.baseFilters !== 'frequency' ? (
                  <th>
                    <Translate contentKey="generadorApp.quoteProductService.frequency">Frequency</Translate>
                  </th>
                ) : null}
                <th>
                  <Translate contentKey="generadorApp.quote.date">Date</Translate>
                </th>
                {state.baseFilters !== 'days' ? (
                  <th>
                    <Translate contentKey="generadorApp.quoteProductService.days">Days</Translate>
                  </th>
                ) : null}
                {!['Bill'].includes(this.props.typeView) ? (
                  <th>
                    <Translate contentKey="generadorApp.quoteProductService.quantity">Quantity</Translate>
                  </th>
                ) : null}
                {['Bill'].includes(this.props.typeView) ? (
                  <th>
                    <Translate contentKey="generadorApp.quoteProductService.billQuantityItem">billQuantityItem</Translate>
                  </th>
                ) : null}
                {state.baseFilters !== 'unitaryAmount' ? (
                  <th className="hand" onClick={sortFunction('unitaryAmount')}>
                    <Translate contentKey="generadorApp.quoteProductService.unitaryAmount">Unitary Amount</Translate>
                  </th>
                ) : null}
                {state.baseFilters !== 'grossAmount' ? (
                  <th className="hand" onClick={sortFunction('grossAmount')}>
                    <Translate contentKey="generadorApp.quoteProductService.grossAmount">Gross Amount</Translate>
                  </th>
                ) : null}
                {state.baseFilters !== 'discount' ? (
                  <th className="hand" onClick={sortFunction('discount')}>
                    <Translate contentKey="generadorApp.quoteProductService.discount">Discount</Translate>
                  </th>
                ) : null}
                {!['Bill'].includes(this.props.typeView) ? (
                  <th>
                    <Translate contentKey="generadorApp.quoteProductService.finalAmount">finalAmount</Translate>
                  </th>
                ) : null}
                {['Bill'].includes(this.props.typeView) ? (
                  <th>
                    <Translate contentKey="generadorApp.quoteProductService.billFinalAmount">billFinalAmount</Translate>
                  </th>
                ) : null}

                <th />
              </tr>
            </thead>
            <tbody>
              <tr></tr>
              {quoteProductServiceListActives
                .filter(quoteItem => !['Bill'].includes(this.props.typeView) || listQuoteItemWithValues.includes(quoteItem.id))
                .map((quoteProductService, i) => (
                  <tr key={`entity-${i}`} className={quoteProductService.additive ? 'alert alert-warning' : ''}>
                    <td />
                    <td id="productService-cell">
                      <b className="visible-xs"> ProductService </b>
                      {quoteProductService && quoteProductService.productService && quoteProductService.productService['name']
                        ? quoteProductService.productService['name']
                        : ''}
                    </td>
                    {state.baseFilters !== 'frequency' ? (
                      <td id="frequency-cell">
                        <b className="visible-xs"> Frequency </b>
                        {quoteProductService.frequency ? (
                          <Translate contentKey={`generadorApp.QuoteProductServiceFrequency.${quoteProductService.frequency}`} />
                        ) : (
                          <> </>
                        )}
                      </td>
                    ) : null}
                    <td id="startDate-cell">
                      <b className="visible-xs"> Date </b>
                      <TextFormat type="date" value={quoteProductService.startDate} format={APP_LOCAL_DATE_FORMAT} /> -{' '}
                      <TextFormat type="date" value={quoteProductService.finishDate} format={APP_LOCAL_DATE_FORMAT} />
                    </td>
                    {state.baseFilters !== 'days' ? (
                      <td id="days-cell">
                        <b className="visible-xs"> Days </b>
                        {quoteProductService.days}
                      </td>
                    ) : null}
                    {!['Bill'].includes(this.props.typeView) ? (
                      <td id="quantity-cell">
                        <b className="visible-xs"> Quantity </b>
                        {quoteProductService.quantity}
                      </td>
                    ) : null}
                    {['Bill'].includes(this.props.typeView) ? (
                      <td id="billQuantityItem-cell">
                        <CurrencyInput
                          precision={0}
                          style={{ width: '100px' }}
                          onChange={(maskedvalue, floatvalue, event) => {
                            const grossAmount = quoteProductService.unitaryAmount * floatvalue;

                            const finalAmount =
                              quoteProductService.typeDiscount !== 'PORCENTO'
                                ? grossAmount - quoteProductService.discount
                                : grossAmount - (grossAmount * quoteProductService.discountPercent) / 100;

                            const billListQuantityItemSelectValue = this.state.billListQuantityItemSelectValue;
                            const billListFinalAmountSelectValue = this.state.billListFinalAmountSelectValue;
                            const billListGrossAmountSelectValue = this.state.billListGrossAmountSelectValue;

                            billListQuantityItemSelectValue[quoteProductService.id] = floatvalue;
                            billListFinalAmountSelectValue[quoteProductService.id] = finalAmount;
                            billListGrossAmountSelectValue[quoteProductService.id] = grossAmount;

                            this.setState({
                              billListQuantityItemSelectValue,
                              billListFinalAmountSelectValue,
                              billListGrossAmountSelectValue,
                            });
                            this.props.updateEntity(
                              {
                                id: quoteProductService.id,
                                billQuantityItem: floatvalue,
                                billFinalAmount: finalAmount,
                                billGrossAmount: grossAmount,
                              },
                              { reloadList: false }
                            );
                          }}
                          allowNegative={false}
                          value={
                            typeof this.state.billListQuantityItemSelectValue === 'undefined' ||
                            typeof this.state.billListQuantityItemSelectValue[quoteProductService.id] === 'undefined'
                              ? quoteProductService.billQuantityItem
                              : this.state.billListQuantityItemSelectValue[quoteProductService.id]
                          }
                          id="quote-product-service-days"
                          name="days"
                          className={'form-control'}
                        />
                      </td>
                    ) : null}
                    {state.baseFilters !== 'unitaryAmount' ? (
                      <td id="unitaryAmount-cell">
                        <b className="visible-xs"> Unitary Amount </b>
                        R$ {(quoteProductService.unitaryAmount * 1).toFixed(2).replace('.', ',')}
                      </td>
                    ) : null}
                    {!['Bill'].includes(this.props.typeView) ? (
                      <td id="grossAmount-cell">
                        <b className="visible-xs"> Gross Amount </b>
                        R$ {(quoteProductService.grossAmount * 1).toFixed(2).replace('.', ',')}
                      </td>
                    ) : null}
                    {['Bill'].includes(this.props.typeView) ? (
                      <td id="grossAmount-cell">
                        <b className="visible-xs"> Gross Amount </b>
                        R${' '}
                        {(
                          (typeof this.state.billListGrossAmountSelectValue === 'undefined' ||
                          !this.state.billListGrossAmountSelectValue[quoteProductService.id]
                            ? quoteProductService.billGrossAmount
                            : this.state.billListGrossAmountSelectValue[quoteProductService.id]) * 1
                        )
                          .toFixed(2)
                          .replace('.', ',')}
                      </td>
                    ) : null}
                    {state.baseFilters !== 'discount' ? (
                      <td id="discount-cell">
                        <b className="visible-xs"> Discount </b>
                        {quoteProductService.typeDiscount !== QuoteDiscountType.PORCENTO
                          ? 'R$ ' + (quoteProductService.discount * 1).toFixed(2).replace('.', ',')
                          : (quoteProductService.discountPercent * 1).toFixed(2).replace('.', ',') + ' %'}
                      </td>
                    ) : null}
                    {!['Bill'].includes(this.props.typeView) ? (
                      <td id="finalAmount-cell">
                        <b className="visible-xs"> Final Amount </b>
                        R$ {(quoteProductService.finalAmount * 1).toFixed(2).replace('.', ',')}
                      </td>
                    ) : null}
                    {['Bill'].includes(this.props.typeView) ? (
                      <td id="billFinalAmount-cell">
                        R${' '}
                        {(
                          (typeof this.state.billListFinalAmountSelectValue === 'undefined' ||
                          !this.state.billListFinalAmountSelectValue[quoteProductService.id]
                            ? quoteProductService.billFinalAmount
                            : this.state.billListFinalAmountSelectValue[quoteProductService.id]) * 1
                        )
                          .toFixed(2)
                          .replace('.', ',')}
                      </td>
                    ) : null}

                    <td className="text-right">
                      {!entityEmbebedPai ||
                      !['APROVADO', 'PRORROGADO', 'INATIVOREPROVADOPRECO', 'INATIVOCONTROLEINTERNO'].includes(entityEmbebedPai.status) ? (
                        <div className="btn-group flex-btn-group-container">
                          <Button onClick={() => this.openUpdateModal(quoteProductService)} color="primary" size="sm">
                            <FontAwesomeIcon icon="pencil-alt" />
                          </Button>
                          <Button onClick={() => this.deleteEntity(quoteProductService)} color="danger" size="sm">
                            <FontAwesomeIcon icon="trash" />
                          </Button>
                        </div>
                      ) : (
                        <> </>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.quoteProductService.home.notFound">No Human Resources found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    return <> {super.renderFooter()} </>;
  }

  saveEntity = (event: any, errors: any, values: any, isNew: any) => {
    values.startDate = convertDateTimeToServer(values.startDate);
    values.finishDate = convertDateTimeToServer(values.finishDate);

    if (errors.length === 0) {
      const { quoteProductServiceEntity } = this.props;
      const entity = {
        ...quoteProductServiceEntity,
        ...values,

        quote: getFormFromSelect(this.state.quoteSelectValue, 'many-to-one'), // many-to-one - otherSide

        productService: getFormFromSelect(this.state.productServiceSelectValue, 'many-to-one'), // many-to-one - otherSide

        startDate: this.state.startDateSelectValue,
        finishDate: this.state.finishDateSelectValue,
        days: this.state.daysSelectValue,
        quantity: this.state.quantitySelectValue,
        unitaryAmount: this.state.unitaryAmountSelectValue,
        grossAmount: this.state.grossAmountSelectValue,
        typeDiscount: this.state.typeDiscountSelectValue === null ? 'VALOR_UNITARIO' : this.state.typeDiscountSelectValue,
        discountPercent: this.state.discountPercentSelectValue,
        discount: this.state.discountSelectValue,
        finalAmount: this.state.finalAmountSelectValue,
        frequency: this.state.frequencySelectValue === null ? 'LOCACAODIARIA' : this.state.frequencySelectValue,
        additive: this.state.additiveSelectValue === null ? false : this.state.additiveSelectValue,
        suprimentoStatus: SuprimentoStatus.INCOMPLETO,
        billQuantityItem: this.state.billQuantityItemSelectValue ? this.state.billQuantityItemSelectValue : this.state.daysSelectValue,
        billGrossAmount: this.state.billGrossAmountSelectValue ? this.state.billGrossAmountSelectValue : this.state.grossAmountSelectValue,
        billFinalAmount: this.state.billFinalAmountSelectValue ? this.state.billFinalAmountSelectValue : this.state.finalAmountSelectValue,
      };

      const {
        startDateStart,
        startDateEnd,
        finishDateStart,
        finishDateEnd,
        typeDiscount,
        days,
        quantity,
        unitaryAmount,
        grossAmount,
        discountPercent,
        discount,
        finalAmount,
        frequency,
        quoteId,
        productServiceId,
        additive,
        billQuantityItem,
      } = this.state.fieldsBase;

      if (isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              startDateStart,
              startDateEnd,
              finishDateStart,
              finishDateEnd,
              typeDiscount,
              days,
              quantity,
              unitaryAmount,
              grossAmount,
              discountPercent,
              discount,
              finalAmount,
              frequency,
              quoteId && quoteId.value ? quoteId.value : '',
              productServiceId && productServiceId.value ? productServiceId.value : '',
              additive,
              billQuantityItem,
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        this.props.updateEntity(entity, [
          startDateStart,
          startDateEnd,
          finishDateStart,
          finishDateEnd,
          typeDiscount,
          days,
          quantity,
          unitaryAmount,
          grossAmount,
          discountPercent,
          discount,
          finalAmount,
          frequency,
          quoteId && quoteId.value ? quoteId.value : '',
          productServiceId && productServiceId.value ? productServiceId.value : '',
          additive,
          billQuantityItem,
        ]);
      }
      this.setState({ showModalForm: false });
      this.setState({ showModalEdit: null });
    } else {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.productService.' + errors[0]) }));
    }
  };

  renderNewModal() {
    const { quotes, quoteProductServiceList, entityEmbebedPai } = this.props;

    let additive = false;
    if (entityEmbebedPai) {
      additive = ['APROVADO', 'PRORROGADO', 'INATIVOREPROVADOPRECO', 'INATIVOCONTROLEINTERNO'].includes(entityEmbebedPai.status);
      if (additive !== this.state.additiveSelectValue) {
        this.setState({ additiveSelectValue: additive });
      }
    }
    return (
      <Modal size={'xl'} isOpen={this.state.showModalForm} toggle={() => this.setState({ showModalForm: false })}>
        <ModalHeader toggle={() => this.setState({ showModalForm: false })}>{this.renderHeaderUpdate({}, true)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefNew = el)}
          model={{}}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, { ...values, additive }, true)}
        >
          <ModalBody>{this.renderBodyUpdate({}, true)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalForm: false })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.quoteProductService.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefNew && this.myFormRefNew.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.quoteProductService.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    );
  }

  async openUpdateModal(v, edit = true) {
    this.props.getEntity(v.id);

    let categorySelectValue = null;
    if (v.productServices && v.productServices.id) {
      const category = await getListAxios('categories', { 'productService.id.equals': v.productServices.id }, 0, 100, 'name,asc', 'name');

      if (category['data'] && category['data'].length > 0) {
        const _categ = category['data'][0];
        categorySelectValue = { ..._categ, value: _categ.id, label: _categ.name };
      }
    }

    this.setState({
      showModalEdit: v.id,
      showModalEditView: edit,
      categorySelectValue,
      ...this.getFullEntitySelectValue(v),
    });
  }

  renderUpdateModal() {
    const { quoteProductServiceEntity, quote } = this.props;
    return quoteProductServiceEntity && quoteProductServiceEntity.id ? (
      <Modal
        size={'xl'}
        isOpen={this.state.showModalEdit === quoteProductServiceEntity.id}
        toggle={() => this.setState({ showModalEdit: null })}
      >
        <ModalHeader toggle={() => this.setState({ showModalEdit: null })}>
          {this.renderHeaderUpdate(quoteProductServiceEntity, false)}
        </ModalHeader>
        <AvForm
          ref={el => (this.myFormRefEdit = el)}
          model={{
            ...quoteProductServiceEntity,
          }}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, false)}
        >
          <ModalBody>{this.renderBodyUpdate(quoteProductServiceEntity, false)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalEdit: null })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.quoteProductService.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefEdit && this.myFormRefEdit.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.quoteProductService.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    ) : (
      <> </>
    );
  }

  renderHeaderUpdate(quoteProductServiceEntity, isNew) {
    const { quotes, productServices, loading } = this.props;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {isNew ? (
              <Translate contentKey="generadorApp.quoteProductService.home.createLabel">Create a QuoteProductService</Translate>
            ) : (
              <Translate contentKey="generadorApp.quoteProductService.home.editLabel">Edit a QuoteProductService</Translate>
            )}
          </span>
        </div>
      </>
    );
  }

  quoteFrequency(frequency, daysCount) {
    switch (frequency) {
      case 'UMXSEMANA':
        return Math.floor(daysCount / 7) * 1;
      case 'DOISXSEMANA':
        return Math.floor(daysCount / 7) * 2;
      case 'TRESXSEMANA':
        return Math.floor(daysCount / 7) * 3;
      case 'QUATROXSEMANA':
        return Math.floor(daysCount / 7) * 4;
      case 'CINCOXSEMANA':
        return Math.floor(daysCount / 7) * 5;
      case 'SEISXSEMANA':
        return Math.floor(daysCount / 7) * 6;
      case 'UMXDIA':
        return daysCount * 1;
      case 'DOISXDIA':
        return daysCount * 2;
      case 'TRESXDIA':
        return daysCount * 3;
      case 'QUATROXDIA':
        return daysCount * 4;
      case 'UMXMES':
        return Math.floor(daysCount / 30) * 1;
      case 'DOISXMES':
        return Math.floor(daysCount / 30) * 2;
      case 'TRESXMES':
        return Math.floor(daysCount / 30) * 3;
      case 'QUATROXMES':
        return Math.floor(daysCount / 30) * 4;
      case 'CADA48HORAS':
        return Math.floor(daysCount / 2);
      case 'CADA72HORAS':
        return Math.floor(daysCount / 3);
      case 'PONTUAL':
        return 1;
      default:
        return 0;
    }
  }

  renderBodyUpdate(quoteProductServiceEntity, isNew) {
    const { loading, entityEmbebedPai } = this.props;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const clComTables = showFieldsSelectAsync(entityEmbebedPai, 'patient.client.clComTable', false);
    const openTable = clComTables
      ? clComTables.filter(v => v.deletedAt === null && v.type === 'TABELAABERTA' && v.status === 'ATIVO').pop()
      : null;

    const defaultTo = this.props.entityEmbebedPai['to'] ? moment(this.props.entityEmbebedPai['to']).toDate() : new Date();
    const defaultFrom = this.props.entityEmbebedPai['from'] ? moment(this.props.entityEmbebedPai['from']).toDate() : new Date();

    const daysCount = Math.round(
      ((this.state.finishDateSelectValue ? moment(this.state.finishDateSelectValue).toDate().getTime() : defaultTo.getTime()) -
        (this.state.startDateSelectValue ? moment(this.state.startDateSelectValue).toDate().getTime() : defaultFrom.getTime())) /
        (1000 * 3600 * 24) +
        1
    );

      const foundValue = async (p) => {
        const result2 = await Axios.get("/api/cl-com-rulers")

          result2.data?.map((itens) => {
          if(itens?.levelGroup?.name === "EQUIPAMENTOS"){
          itens?.productServices?.map((itensProd) => {
            if(itensProd.id === p.id){
              this.setState({ unitaryAmountSelectValue: itens.rentalValue })
            } else {

            }
          })
          } else {
            itens?.productServices?.map((itensProd) => {
              if(itensProd.id === p.id){
                this.setState({ unitaryAmountSelectValue: itens.rentalValue })
              } else {
  
              }
            })
          }
        })
      }

    if (this.state.startDateSelectValue === null) {
      this.setState({
        startDateSelectValue: defaultFrom,
      });
    }
    if (this.state.finishDateSelectValue === null) {
      this.setState({
        finishDateSelectValue: defaultTo,
      });
    }

    const packageTable = this.state.clComTableSelectValue;
    const packageRuler =
      packageTable && packageTable.clComRuler && this.state.productServiceSelectValue && this.state.productServiceSelectValue.id
        ? packageTable.clComRuler
            .filter(v => {
              return v.productServices.map(v1 => v1.id).includes(this.state.productServiceSelectValue.id);
            })
            .map(v => ({ incluseValues: this.quoteFrequency(v.frequency, daysCount), ...v }))
            .sort((a, b) => a.incluseValues - b.incluseValues)
            .pop()
        : null;
    const grossAmount = this.state.unitaryAmountSelectValue * (this.state.quantitySelectValue ? this.state.quantitySelectValue : 0);

    const finalAmount =
      this.state.typeDiscountSelectValue !== 'PORCENTO'
        ? this.state.grossAmountSelectValue - (this.state.discountSelectValue ? this.state.discountSelectValue : 0)
        : this.state.grossAmountSelectValue -
          (this.state.grossAmountSelectValue * (this.state.discountPercentSelectValue ? this.state.discountPercentSelectValue : 0)) / 100;

    if (this.state.grossAmountSelectValue !== grossAmount) {
      this.setState({
        grossAmountSelectValue: grossAmount,
      });
    }
    if (this.state.finalAmountSelectValue !== finalAmount) {
      this.setState({
        finalAmountSelectValue: finalAmount,
      });
    }
    if (entityEmbebedPai && entityEmbebedPai.type !== TypeClientCommercialTable.PACOTE && this.state.clComTableSelectValue !== null) {
      this.setState({
        clComTableSelectValue: null,
        clComTableStartSelectOptions: undefined,
      });
    }

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      <Col md="12">
                        <AvInput id="quote-product-service-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {entityEmbebedPai.type === 'PACOTE' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="frequencyLabel" for="quote-product-service-frequency">
                              <Translate contentKey="generadorApp.quoteItem.clComTable">clComTable</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="quote-product-service-clComTable"
                              name={'clComTable'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.clComTableSelectValue}
                              onChange={(options: any) => {
                                this.setState({
                                  categorySelectValue: null,
                                  categoryStartSelectOptions: undefined,
                                  productServiceSelectValue: null,
                                  productServiceStartSelectOptions: undefined,
                                  clComTableSelectValue: options,
                                });
                              }}
                              defaultOptions={this.state.clComTableStartSelectOptions ? this.state.clComTableStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.clComTableStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                const idsPackage = entityEmbebedPai.quotePackage
                                  ? entityEmbebedPai.quotePackage
                                      .filter(v => v.deletedAt === null && v.clComTable)
                                      .map(v => v.clComTable.id)
                                  : null;

                                let result = { data: null };
                                if (idsPackage && idsPackage.length > 0) {
                                  result = await getListAxios(
                                    'cl-com-tables',
                                    {
                                      'id.in': idsPackage && idsPackage.length > 0 ? idsPackage : ['not-list'],
                                    },
                                    0,
                                    100,
                                    'name,asc',
                                    ''
                                  );
                                }

                                let _listData = [];
                                if (openTable) {
                                  _listData = [{ ...openTable, value: openTable.id, label: '(Tabela) ' + openTable.name }];
                                }
                                if (result.data) {
                                  _listData = [..._listData, ...result.data.map(p => ({ ...p, value: p.id, label: '(Pacote) ' + p.name }))];
                                }

                                this.setState({
                                  clComTableStartSelectOptions: _listData,
                                });
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const idsPackage = entityEmbebedPai.quotePackage
                                  ? entityEmbebedPai.quotePackage
                                      .filter(v => v.deletedAt === null && v.clComTable)
                                      .map(v => v.clComTable.id)
                                  : null;

                                let result = { data: null };
                                if (idsPackage && idsPackage.length > 0) {
                                  result = await getListAxios(
                                    'cl-com-tables',
                                    {
                                      'name.contains': inputValue,
                                      'id.in': idsPackage && idsPackage.length > 0 ? idsPackage : ['not-list'],
                                    },
                                    0,
                                    100,
                                    'name,asc',
                                    ''
                                  );
                                }

                                let _listData = [];
                                if (openTable) {
                                  _listData = [{ ...openTable, value: openTable.id, label: '(Tabela) ' + openTable.name }];
                                }
                                if (result.data) {
                                  _listData = [..._listData, ...result.data.map(p => ({ ...p, value: p.id, label: '(Pacote) ' + p.name }))];
                                }

                                this.setState({
                                  clComTableStartSelectOptions: _listData,
                                });

                                callback(_listData);
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <> </>
                  )}
                  <Col md="4">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" for="category-status-history-category">
                            <Translate contentKey="generadorApp.category.detail.title">Category</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <SelectAsync
                            id="category-status-history-category"
                            name={'category'}
                            className={'css-select-control'}
                            data-type-rel="many-to-one-other-side"
                            value={this.state.categorySelectValue}
                            onChange={(options: ICategory) =>
                              this.setState({
                                quantitySelectValue: QuoteProductServiceFrequency.VENDA === this.state.frequencySelectValue ? 1 : daysCount,
                                daysSelectValue: QuoteProductServiceFrequency.VENDA === this.state.frequencySelectValue ? 1 : daysCount,
                                categorySelectValue: options,
                                productServiceSelectValue: null,
                                productServiceStartSelectOptions: undefined,
                              })
                            }
                            defaultOptions={this.state.categoryStartSelectOptions ? this.state.categoryStartSelectOptions : []}
                            loadingMessage={input => translate('selectAsync.loadingMessage')}
                            noOptionsMessage={input =>
                              this.state.categoryStartSelectOptions === undefined
                                ? translate('selectAsync.loadingMessage')
                                : translate('selectAsync.noOptionsMessage')
                            }
                            onMenuOpen={async () => {
                              if (this.state.categoryStartSelectOptions === undefined) {
                                const result = await getListAxios(
                                  'categories',
                                  {
                                    'levelGroup.sub.equals': Sub.PRODUTOSESERVICOS,
                                  },
                                  0,
                                  100,
                                  'name,asc',
                                  'name'
                                );
                                this.setState({
                                  categoryStartSelectOptions:
                                    result && result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                                      : [],
                                });
                              }
                            }}
                            loadOptions={async (inputValue, callback) => {
                              const result = await getListAxios('categories', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                              callback(
                                result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []
                              );
                            }}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>

                  <Col md={entityEmbebedPai.type === 'PACOTE' ? '5' : '8'}>
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" for="quote-product-service-productService">
                            <Translate contentKey="generadorApp.quoteProductService.productService">ProductService</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <SelectAsync
                            id="quote-product-service-productService"
                            name={'productService'}
                            className={'css-select-control'}
                            data-type-rel="many-to-one-other-side"
                            value={this.state.productServiceSelectValue}
                            onChange={(options: IProductService) => {
                              foundValue(options)
                              const _ruler =
                                openTable && openTable.clComRuler
                                  ? openTable.clComRuler
                                      .filter(v => {
                                        return v.productServices.map(v1 => v1.id).includes(options.id);
                                      })
                                      .pop()
                                  : null;

                              const _unitaryAmount = _ruler
                                ? _ruler.option === ServiceType.VENDA
                                  ? _ruler.saleValue
                                  : _ruler.rentalValue
                                : 0;

                              const _frequencySelectValue = _ruler
                                ? _ruler.option === ServiceType.VENDA
                                  ? QuoteProductServiceFrequency.VENDA
                                  : QuoteProductServiceFrequency.LOCACAODIARIA
                                : 0;

                              this.setState({
                                quantitySelectValue: QuoteProductServiceFrequency.VENDA === _frequencySelectValue ? 1 : daysCount,
                                daysSelectValue: QuoteProductServiceFrequency.VENDA === _frequencySelectValue ? 1 : daysCount,
                                frequencyEnabled: _ruler ? false : true,
                                frequencySelectValue: _frequencySelectValue,
                                unitaryAmountEnabled: _unitaryAmount > 0 ? false : true,
                                unitaryAmountSelectValue: _unitaryAmount,
                                productServiceSelectValue: options,
                              });
                            }}
                            defaultOptions={this.state.productServiceStartSelectOptions ? this.state.productServiceStartSelectOptions : []}
                            loadingMessage={input => translate('selectAsync.loadingMessage')}
                            noOptionsMessage={input =>
                              this.state.productServiceStartSelectOptions === undefined
                                ? translate('selectAsync.loadingMessage')
                                : translate('selectAsync.noOptionsMessage')
                            }
                            onMenuOpen={async () => {
                              if (this.state.productServiceStartSelectOptions === undefined) {
                                const result = await getListAxios(
                                  'product-services',
                                  { 'category.id.in': this.state.categorySelectValue ? this.state.categorySelectValue.id : null },
                                  0,
                                  100,
                                  'name,asc',
                                  'name,category.id'
                                );
                                this.setState({
                                  productServiceStartSelectOptions: result.data
                                    ? result.data.map(p => {
                                        const _ruler =
                                          openTable && openTable.clComRuler
                                            ? openTable.clComRuler.filter(v => v.productServices.map(v1 => v1.id).includes(p.id)).pop()
                                            : null;
                                        const _value = _ruler
                                          ? _ruler.option === ServiceType.VENDA
                                            ? _ruler.saleValue
                                            : _ruler.rentalValue
                                          : 0;
                                        return {
                                          ...p,
                                          clComRulerSelected: _ruler,
                                          value: p.id,
                                          label: p.name + (_ruler ? ' (R$ ' + (_value * 1).toFixed(2).replace('.', ',') + ')' : ''),
                                        };
                                      })
                                    : [],
                                });
                              }
                            }}
                            loadOptions={async (inputValue, callback) => {
                              const result = await getListAxios(
                                'product-services',
                                {
                                  'name.contains': inputValue,
                                  'category.id.in': this.state.categorySelectValue ? this.state.categorySelectValue.id : null,
                                },
                                0,
                                100,
                                'name,asc',
                                'name,category.id'
                              );
                              callback(
                                result.data
                                  ? result.data.map(p => {
                                      const _ruler =
                                        openTable && openTable.clComRuler
                                          ? openTable.clComRuler.filter(v => v.productServices.map(v1 => v1.id).includes(p.id)).pop()
                                          : null;
                                      const _value = _ruler
                                        ? _ruler.option === ServiceType.VENDA
                                          ? _ruler.saleValue
                                          : _ruler.rentalValue
                                        : 0;
                                      return {
                                        ...p,
                                        clComRulerSelected: _ruler,
                                        value: p.id,
                                        label: p.name + (_ruler ? ' (R$ ' + (_value * 1).toFixed(2).replace('.', ',') + ')' : ''),
                                      };
                                    })
                                  : []
                              );
                            }}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>

                  {baseFilters !== 'startDate' ? (
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="startDateLabel" for="quote-rh-startDate">
                              <Translate contentKey="generadorApp.quoteProductService.startDate">Start Date</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <DatePicker
                              onChange={value => {
                                const _daysCount = Math.round(
                                  ((this.state.finishDateSelectValue
                                    ? moment(this.state.finishDateSelectValue).toDate().getTime()
                                    : defaultTo.getTime()) -
                                    (value ? moment(value).toDate().getTime() : defaultFrom.getTime())) /
                                    (1000 * 3600 * 24) +
                                    1
                                );
                                this.setState({
                                  quantitySelectValue:
                                    QuoteProductServiceFrequency.VENDA === this.state.frequencySelectValue ? 1 : _daysCount,
                                  daysSelectValue: QuoteProductServiceFrequency.VENDA === this.state.frequencySelectValue ? 1 : _daysCount,
                                  startDateSelectValue: value,
                                });
                              }}
                              id="startDate"
                              validate={{}}
                              className="form-control react-datepicker"
                              name="startDate"
                              dateFormat="dd/MM/yyyy"
                              minDate={defaultFrom}
                              maxDate={defaultTo}
                              selected={this.state.startDateSelectValue ? moment(this.state.startDateSelectValue).toDate() : defaultFrom}
                              locale="pt"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ startDateSelectValue: evt.target.value })}
                      type="hidden"
                      name="startDate"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'finishDate' ? (
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="finishDateLabel" for="quote-rh-finishDate">
                              <Translate contentKey="generadorApp.quoteProductService.finishDate">Finish Date</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <DatePicker
                              onChange={value => {
                                const _daysCount = Math.round(
                                  ((value ? moment(value).toDate().getTime() : defaultTo.getTime()) -
                                    (this.state.startDateSelectValue
                                      ? moment(this.state.startDateSelectValue).toDate().getTime()
                                      : defaultFrom.getTime())) /
                                    (1000 * 3600 * 24) +
                                    1
                                );
                                this.setState({
                                  quantitySelectValue:
                                    QuoteProductServiceFrequency.VENDA === this.state.frequencySelectValue ? 1 : _daysCount,
                                  daysSelectValue: QuoteProductServiceFrequency.VENDA === this.state.frequencySelectValue ? 1 : _daysCount,
                                  finishDateSelectValue: value,
                                });
                              }}
                              id="finishDate"
                              validate={{}}
                              className="form-control react-datepicker"
                              name="finishDate"
                              dateFormat="dd/MM/yyyy"
                              minDate={defaultFrom}
                              maxDate={defaultTo}
                              selected={this.state.finishDateSelectValue ? moment(this.state.finishDateSelectValue).toDate() : defaultTo}
                              locale="pt"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ finishDateSelectValue: evt.target.value })}
                      type="hidden"
                      name="finishDate"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'days' ? (
                    <Col md="1">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="daysLabel" for="quote-rh-days">
                              <Translate contentKey="generadorApp.quoteProductService.daysCount">daysCount</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <AvField
                              disabled={true}
                              value={Math.round(daysCount)}
                              id="quote-diet-days"
                              type="number"
                              className="form-control"
                              name="daysCount"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ daysSelectValue: evt.target.value })}
                      type="hidden"
                      name="daysCount"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  <Col md="3">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" id="frequencyLabel" for="quote-rh-frequency">
                            <Translate contentKey="generadorApp.quoteProductService.frequency">Frequency</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <Select
                            isDisabled={!this.state.frequencyEnabled}
                            id="quote-rh-frequency"
                            className={'css-select-control'}
                            value={{
                              value: this.state.frequencySelectValue ? this.state.frequencySelectValue : '',
                              label: translate(
                                'generadorApp.QuoteProductServiceFrequency.' +
                                  (this.state.frequencySelectValue ? this.state.frequencySelectValue : 'null')
                              ),
                            }}
                            options={[
                              { value: 'LOCACAODIARIA', label: translate('generadorApp.QuoteProductServiceFrequency.LOCACAODIARIA') },
                              { value: 'VENDA', label: translate('generadorApp.QuoteProductServiceFrequency.VENDA') },
                            ]}
                            onChange={(options: any) => {
                              let packageRulerFrequency = 0;
                              if (packageRuler && packageRuler.frequency) {
                                packageRulerFrequency = this.quoteFrequency(packageRuler.frequency, daysCount);
                              }
                              const daysSelectValue = this.quoteFrequency(options['value'], daysCount);
                              return this.setState({
                                quantitySelectValue: daysSelectValue > packageRulerFrequency ? daysSelectValue - packageRulerFrequency : 0,
                                daysSelectValue,
                                frequencySelectValue: options['value'],
                              });
                            }}
                            name="frequency"
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md="2">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" id="daysLabel" for="quote-rh-days">
                            <Translate contentKey="generadorApp.quoteProductService.days">Days</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <CurrencyInput
                            precision={0}
                            onChange={(maskedvalue, floatvalue, event) => {
                              let packageRulerFrequency = 0;
                              if (packageRuler && packageRuler.frequency) {
                                packageRulerFrequency = this.quoteFrequency(packageRuler.frequency, daysCount);
                              }

                              return this.setState({
                                quantitySelectValue: floatvalue > packageRulerFrequency ? floatvalue - packageRulerFrequency : 0,
                                daysSelectValue: floatvalue,
                              });
                            }}
                            allowNegative={false}
                            value={this.state.daysSelectValue ? this.state.daysSelectValue : '0'}
                            id="quote-rh-days"
                            name="days"
                            className={'form-control'}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md="2">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" id="unitaryAmountLabel" for="quote-rh-unitaryAmount">
                            <Translate contentKey="generadorApp.quoteProductService.unitaryAmount">Unitary Amount</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <CurrencyInput
                            disabled={!this.state.unitaryAmountEnabled}
                            onChange={(maskedvalue, floatvalue, event) => this.setState({ unitaryAmountSelectValue: floatvalue })}
                            decimalSeparator=","
                            thousandSeparator=""
                            prefix="R$ "
                            allowNegative={true}
                            value={this.state.unitaryAmountSelectValue}
                            id="quote-rh-unitaryAmount"
                            name="unitaryAmount"
                            className={'form-control'}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md="2">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" id="quantityLabel" for="quote-rh-quantity">
                            <Translate contentKey="generadorApp.quoteProductService.quantity">Quantity</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <AvField
                            onChange={evt => this.setState({ quantitySelectValue: evt.target.value })}
                            id="quote-rh-quantity"
                            value={this.state.quantitySelectValue ? this.state.quantitySelectValue : '0'}
                            type="number"
                            className="form-control"
                            name="quantity"
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>

                  <Col md="2">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" id="discountLabel" for="quote-rh-grossAmount">
                            <Translate contentKey="generadorApp.quoteProductService.grossAmount">Gross Amount</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <CurrencyInput
                            disabled={true}
                            onChange={(maskedvalue, floatvalue, event) => this.setState({ grossAmountSelectValue: floatvalue })}
                            decimalSeparator=","
                            thousandSeparator=""
                            prefix="R$ "
                            allowNegative={true}
                            value={this.state.grossAmountSelectValue}
                            id="quote-rh-grossAmount"
                            name="grossAmount"
                            className={'form-control'}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>

                  <Col md="3">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" id="frequencyLabel" for="quote-rh-typeDiscount">
                            <Translate contentKey="generadorApp.quote.typeDiscount">Type Discount</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <Select
                            id="quote-rh-typeDiscount"
                            className={'css-select-control'}
                            value={
                              this.state.typeDiscountSelectValue
                                ? {
                                    value: this.state.typeDiscountSelectValue ? this.state.typeDiscountSelectValue : '',
                                    label: translate('generadorApp.quote.' + this.state.typeDiscountSelectValue),
                                  }
                                : { value: 'VALOR_UNITARIO', label: translate('generadorApp.quote.VALOR_UNITARIO') }
                            }
                            options={[
                              { value: 'VALOR_UNITARIO', label: translate('generadorApp.quote.VALOR_UNITARIO') },
                              { value: 'PORCENTO', label: translate('generadorApp.quote.PORCENTO') },
                            ]}
                            onChange={(options: any) => this.setState({ typeDiscountSelectValue: options['value'] })}
                            name="frequency"
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>

                  <Col md="2">
                    <AvGroup>
                      {this.state.typeDiscountSelectValue === 'PORCENTO' ? (
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="discountPercentLabel" for="quote-rh-discountPercent">
                              <Translate contentKey="generadorApp.quoteProductService.discountPercent">Discount (%)</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ discountPercentSelectValue: floatvalue })}
                              decimalSeparator=","
                              thousandSeparator=""
                              suffix=" %"
                              allowNegative={true}
                              value={this.state.discountPercentSelectValue ? this.state.discountPercentSelectValue : 0}
                              id="quote-rh-discountPercent"
                              name="discountPercent"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      ) : (
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="discountLabel" for="quote-rh-discount">
                              <Translate contentKey="generadorApp.quoteProductService.discount">discount</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ discountSelectValue: floatvalue })}
                              decimalSeparator=","
                              thousandSeparator=""
                              prefix="R$ "
                              allowNegative={true}
                              value={this.state.discountSelectValue ? this.state.discountSelectValue : 0}
                              id="quote-rh-discount"
                              name="discount"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      )}
                    </AvGroup>
                  </Col>
                  <Col md="3">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" id="finalAmountLabel" for="quote-rh-finalAmount">
                            <Translate contentKey="generadorApp.quoteProductService.finalAmount">Final Amount</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <CurrencyInput
                            disabled={true}
                            onChange={(maskedvalue, floatvalue, event) => this.setState({ finalAmountSelectValue: floatvalue })}
                            allowNegative={true}
                            decimalSeparator=","
                            thousandSeparator=""
                            prefix="R$ "
                            value={this.state.finalAmountSelectValue}
                            id="quote-rh-finalAmount"
                            name="finalAmount"
                            className={'form-control'}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <AvInput type="hidden" name="quote" value={this.state.fieldsBase[baseFilters + 'Id']} />
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    return (
      <Panel>
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
            {this.renderNewModal()}

            {this.renderUpdateModal()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;

export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(QuoteProductServiceExtended);
