import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';
import moment from 'moment';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import {
  getEntity,
  IPatNrPrescProcedimentBaseState,
  getUrlBack,
  getPatNrPrescProcedimentState,
  getEntityFiltersURL,
} from './pat-nr-presc-procediment.reducer';

import { APP_DATE_FORMAT } from 'app/config/constants';

export interface IPatNrPrescProcedimentState {
  fieldsBase: IPatNrPrescProcedimentBaseState;
}

export interface IPatNrPrescProcedimentDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class PatNrPrescProcedimentDetail extends React.Component<IPatNrPrescProcedimentDetailProps, IPatNrPrescProcedimentState> {
  constructor(props: Readonly<IPatNrPrescProcedimentDetailProps>) {
    super(props);
    this.state = {
      ...this.state,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getPatNrPrescProcedimentState(this.props.location),
        urlBack: getUrlBack(this.props.location),
        patientNursingPrescriptionId: [
          {
            value: this.props.match.params['idPatientNursingPrescription'],
            label: this.props.match.params['idPatientNursingPrescription'],
          },
        ],
        baseFilters: 'patientNursingPrescription',
      },
    };
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = '_back=1' + (offset !== null ? '&offset=' + offset : '');
    Object.keys(fieldsBase).map(key => {
      url += '&' + key + '=' + fieldsBase[key];
    });
    return url;
  };

  UNSAFE_componentWillMount() {
    this.props.getEntity(this.props.match.params['id']);
  }

  renderHeader() {
    const { patNrPrescProcedimentEntity } = this.props;
    const state = this.state;
    return (
      <>
        <div id="page-heading">
          {' '}
          <h2>
            <Translate contentKey="generadorApp.patNrPrescProcediment.detail.title">PatNrPrescProcediment</Translate>
            <small>&nbsp; {patNrPrescProcedimentEntity['id']}</small>
          </h2>
        </div>

        <div className="pull-right">
          <Button
            tag={Link}
            to={
              '/patientNursingPrescription/' +
              this.props.match.params['idPatientNursingPrescription'] +
              '/pat-nr-presc-procediment?' +
              getEntityFiltersURL(this.state.fieldsBase)
            }
            replace
            color="info"
          >
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.patNrPrescProcediment.btnBack">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/patientNursingPrescription/${this.props.match.params['idPatientNursingPrescription']}/pat-nr-presc-procediment/${
              patNrPrescProcedimentEntity.id
            }/edit?+${getEntityFiltersURL(this.state.fieldsBase)}`}
            replace
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.patNrPrescProcediment.btnEdit">Edit</Translate>
            </span>
          </Button>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>

          <li className="breadcrumb-item">
            <Link to={'/patientNursingPrescription'}>Patient Nursing Prescriptions</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/patientNursingPrescription/' + this.props.match.params['idPatientNursingPrescription']}>
              {this.props.match.params['idPatientNursingPrescription']}
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link
              to={
                '/patientNursingPrescription/' +
                (this.state.fieldsBase.patientNursingPrescriptionId ? this.state.fieldsBase.patientNursingPrescriptionId.label : '') +
                '/pat-nr-presc-procediment'
              }
            >
              Procedures
            </Link>
          </li>
          <li className="breadcrumb-item active">Procedures details</li>
        </ol>
      </>
    );
  }

  renderBody() {
    const { patNrPrescProcedimentEntity } = this.props;
    const state = this.state;
    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            <Row className="jh-entity-details">
              <Col className="view-detail-row pat-nr-presc-procediment-order-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top pat-nr-presc-procediment-order-detail-label" md="12">
                    <dt>
                      <span id="order">
                        <Translate contentKey="generadorApp.patNrPrescProcediment.order">Order</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pat-nr-presc-procediment-order-detail-value" md="12">
                    <dd>{patNrPrescProcedimentEntity.order}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pat-nr-presc-procediment-description-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top pat-nr-presc-procediment-description-detail-label" md="12">
                    <dt>
                      <span id="description">
                        <Translate contentKey="generadorApp.patNrPrescProcediment.description">Description</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pat-nr-presc-procediment-description-detail-value" md="12">
                    <dd>{patNrPrescProcedimentEntity.description}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pat-nr-presc-procediment-frequency-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top pat-nr-presc-procediment-frequency-detail-label" md="12">
                    <dt>
                      <span id="frequency">
                        <Translate contentKey="generadorApp.patNrPrescProcediment.frequency">Frequency</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pat-nr-presc-procediment-frequency-detail-value" md="12">
                    <dd>
                      {translate(
                        'generadorApp.QuoteFrequency.' +
                          (patNrPrescProcedimentEntity.frequency ? patNrPrescProcedimentEntity.frequency : 'UMXSEMANA')
                      )}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pat-nr-presc-procediment-continuous-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top pat-nr-presc-procediment-continuous-detail-label" md="12">
                    <dt>
                      <span id="continuous">
                        <Translate contentKey="generadorApp.patNrPrescProcediment.continuous">Continuous</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pat-nr-presc-procediment-continuous-detail-value" md="12">
                    <dd>
                      {patNrPrescProcedimentEntity.continuous
                        ? translate('generadorApp.patNrPrescProcediment.continuous.true')
                        : translate('generadorApp.patNrPrescProcediment.continuous.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pat-nr-presc-procediment-initialDate-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top pat-nr-presc-procediment-initialDate-detail-label" md="12">
                    <dt>
                      <span id="initialDate">
                        <Translate contentKey="generadorApp.patNrPrescProcediment.initialDate">Initial Date</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pat-nr-presc-procediment-initialDate-detail-value" md="12">
                    <dd>
                      {patNrPrescProcedimentEntity.initialDate
                        ? moment(patNrPrescProcedimentEntity.initialDate).format(APP_DATE_FORMAT)
                        : ''}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pat-nr-presc-procediment-finalDate-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top pat-nr-presc-procediment-finalDate-detail-label" md="12">
                    <dt>
                      <span id="finalDate">
                        <Translate contentKey="generadorApp.patNrPrescProcediment.finalDate">Final Date</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pat-nr-presc-procediment-finalDate-detail-value" md="12">
                    <dd>
                      {patNrPrescProcedimentEntity.finalDate ? moment(patNrPrescProcedimentEntity.finalDate).format(APP_DATE_FORMAT) : ''}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pat-nr-presc-procediment-period-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top pat-nr-presc-procediment-period-detail-label" md="12">
                    <dt>
                      <span id="period">
                        <Translate contentKey="generadorApp.patNrPrescProcediment.period">period</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pat-nr-presc-procediment-period-detail-value" md="12">
                    <dd>
                      {translate('generadorApp.Periodo.' + (patNrPrescProcedimentEntity.period ? patNrPrescProcedimentEntity.period : 'M'))}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pat-nr-presc-procediment-status-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top pat-nr-presc-procediment-status-detail-label" md="12">
                    <dt>
                      <span id="status">
                        <Translate contentKey="generadorApp.patNrPrescProcediment.status">Status</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pat-nr-presc-procediment-status-detail-value" md="12">
                    <dd>
                      {translate(
                        'generadorApp.Status.' + (patNrPrescProcedimentEntity.status ? patNrPrescProcedimentEntity.status : 'INATIVADO')
                      )}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pat-nr-presc-procediment-observation-detail" md="6">
                <Row>
                  <Col className="view-detail-label-top pat-nr-presc-procediment-observation-detail-label" md="12">
                    <dt>
                      <span id="observation">
                        <Translate contentKey="generadorApp.patNrPrescProcediment.observation">Observations</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pat-nr-presc-procediment-observation-detail-value" md="12">
                    <dd>{patNrPrescProcedimentEntity.observation}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pat-nr-presc-procediment-createdDate-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top pat-nr-presc-procediment-createdDate-detail-label" md="12">
                    <dt>
                      <span id="createdDate">
                        <Translate contentKey="generadorApp.patNrPrescProcediment.createdDate">Created Date</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pat-nr-presc-procediment-createdDate-detail-value" md="12">
                    <dd>
                      {patNrPrescProcedimentEntity.createdDate
                        ? moment(patNrPrescProcedimentEntity.createdDate).format(APP_DATE_FORMAT)
                        : ''}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pat-nr-presc-procediment-lastModifiedDate-detail" md="12">
                <Row>
                  <Col className="view-detail-label-left pat-nr-presc-procediment-lastModifiedDate-detail-label" md="3">
                    <dt>
                      <span id="lastModifiedDate">
                        <Translate contentKey="generadorApp.patNrPrescProcediment.lastModifiedDate">Last Modified Date</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left pat-nr-presc-procediment-lastModifiedDate-detail-value" md="9">
                    <dd>
                      {patNrPrescProcedimentEntity.lastModifiedDate
                        ? moment(patNrPrescProcedimentEntity.lastModifiedDate).format(APP_DATE_FORMAT)
                        : ''}
                    </dd>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { patNrPrescProcedimentEntity } = this.props;
    const state = this.state;
    return (
      <div>
        <Panel className="pat-nr-presc-procediment pat-nr-presc-procediment-detail">
          <PanelHeader>{this.renderHeader()}</PanelHeader>

          <PanelBody>{this.renderBody()}</PanelBody>
        </Panel>
      </div>
    );
  }
}

export const mapStateToProps = ({ patNrPrescProcediment, ...storeState }: IRootState) => ({
  patNrPrescProcedimentEntity: patNrPrescProcediment.entity,
});

export const mapDispatchToProps = {
  getEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PatNrPrescProcedimentDetail);
