/* eslint complexity: ["error", 500] */
/* eslint no-empty: off */
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

import { Link, RouteComponentProps } from 'react-router-dom';

import { getFilterFromSelect } from 'app/shared/util/entity-utils';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Col, Label } from 'reactstrap';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import {
  IPharmacyInventoryUpdateState as IBaseUpdateState,
  getEntity,
  getPharmacyInventoryState,
  getUrlBack,
  updateEntity,
  createEntity,
  getEntityFiltersURL,
  getEntityOffset,
  reset,
  inventoryItemSetEntities,
  inventoryItemGetEntities,
  inventoryItemUpdateEntity,
  inventoryItemReset,
  inventoryItemGetEntity,
  inventoryItemCreateEntity,
  inventoryItemDeleteEntity,
} from './pharmacy-inventory.reducer';

import {
  mapStateToPropsExtended as mapStateToPropsInventoryItem,
  mapDispatchToPropsExtended as mapDispatchToPropsInventoryItem,
  InventoryItemExtended as InventoryItem,
} from 'app/entities/inventory-item/extended/inventory-item';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

registerLocale('pt', pt);

export interface IPharmacyInventoryUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}
export type IPharmacyInventoryUpdateState = IBaseUpdateState;

export class PharmacyInventoryUpdate extends React.Component<IPharmacyInventoryUpdateProps, IPharmacyInventoryUpdateState> {
  levelGroupFileInput: any;
  constructor(props: Readonly<IPharmacyInventoryUpdateProps>) {
    super(props);

    this.inventoryItemUpdateEntity = this.inventoryItemUpdateEntity.bind(this);
    this.inventoryItemGetEntities = this.inventoryItemGetEntities.bind(this);
    this.inventoryItemReset = this.inventoryItemReset.bind(this);
    this.inventoryItemGetEntity = this.inventoryItemGetEntity.bind(this);
    this.inventoryItemCreateEntity = this.inventoryItemCreateEntity.bind(this);
    this.inventoryItemDeleteEntity = this.inventoryItemDeleteEntity.bind(this);

    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      functionEmbebed: [],

      inventoryItemSelectValue: null,

      userSelectValue: null,

      levelGroupSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getPharmacyInventoryState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
      userId: null,

      isNew: !this.props.match.params || !this.props.match.params['id'],
    };
  }

  inventoryItemUpdateEntity(...values) {
    values[0]['pharmacyInventory'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['updateEntityInventoryItem'](...values);
    }
    return this.props.inventoryItemUpdateEntity(...values);
  }
  inventoryItemGetEntities(...values) {
    return this.props.inventoryItemGetEntities(...values);
  }
  inventoryItemReset(...values) {
    return this.props.inventoryItemReset(...values);
  }
  inventoryItemGetEntity(...values) {
    return this.props.inventoryItemGetEntity(...values);
  }
  inventoryItemCreateEntity(...values) {
    values[0]['pharmacyInventory'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['createEntityInventoryItem'](...values);
    }
    return this.props.inventoryItemCreateEntity(...values);
  }
  inventoryItemDeleteEntity(...values) {
    if (!this.state.isNew) {
      this.props['deleteEntityInventoryItem'](...values);
    }
    return this.props.inventoryItemDeleteEntity(...values);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateSuccess !== this.props.updateSuccess && prevProps.updateSuccess) {
      this.handleClose();
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.pharmacyInventoryEntity && prevProps.pharmacyInventoryEntity.id !== this.props.pharmacyInventoryEntity.id) ||
      (this.props.inventoryItems.length > 0 &&
        this.state.inventoryItemSelectValue === null &&
        this.props.pharmacyInventoryEntity.inventoryItem)
    ) {
      this.setState({ inventoryItemSelectValue: this.props.pharmacyInventoryEntity.inventoryItem });
      this.props.inventoryItemSetEntities(this.props.pharmacyInventoryEntity.inventoryItem);
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.pharmacyInventoryEntity && prevProps.pharmacyInventoryEntity.id !== this.props.pharmacyInventoryEntity.id) ||
      (this.props.users &&
        this.state.userSelectValue === null &&
        this.props.pharmacyInventoryEntity.user &&
        this.props.pharmacyInventoryEntity.user.id)
    ) {
      const p =
        this.props.pharmacyInventoryEntity && this.props.pharmacyInventoryEntity.user ? this.props.pharmacyInventoryEntity.user : null;
      this.setState({
        userSelectValue: p ? { ...p, value: p.id, label: p['id'] ? p.id : '' } : null,
      });
    }

    if (
      (prevProps.pharmacyInventoryEntity && prevProps.pharmacyInventoryEntity.id !== this.props.pharmacyInventoryEntity.id) ||
      (this.props.pharmacyInventoryEntity.levelGroup &&
        this.state.levelGroupSelectValue === null &&
        this.props.pharmacyInventoryEntity.levelGroup !== this.state.levelGroupSelectValue)
    ) {
      this.setState({
        levelGroupSelectValue: this.props.pharmacyInventoryEntity.levelGroup ? this.props.pharmacyInventoryEntity.levelGroup : null,
      });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  UNSAFE_componentWillMount() {
    if (this.state.isNew) {
      this.props.reset();
      this.inventoryItemReset();
    } else {
      this.props.getEntity(this.props.match.params['id']);
    }
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = offset !== null ? '&offset=' + offset : '';
    Object.keys(fieldsBase).map(key => {
      url += key !== 'offset' ? '&' + key + '=' + fieldsBase[key] : '';
    });
    return url;
  };
  saveEntity = (event: any, errors: any, values: any) => {
    const selectFieldsList = ['createdDate', 'levelGroup', 'createdBy.login'];

    if (errors.length === 0) {
      const { pharmacyInventoryEntity } = this.props;
      const _entity = {
        ...pharmacyInventoryEntity,
        // ...values,

        inventoryItem: undefined,

        levelGroup: this.state.levelGroupSelectValue === null ? 'MATERIAL' : this.state.levelGroupSelectValue,
      };

      const entity = _entity;

      const {
        createdDateStart,
        createdDateEnd,
        createdById,
        levelGroup,
        extraFilters,
        activePage,
        itemsPerPage,
        sort,
        order,
      } = this.state.fieldsBase;

      const inventoryItemListAllEmbed = this.props.inventoryItemListAllEmbed;

      if (this.state.isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              createdDateStart,
              createdDateEnd,
              getFilterFromSelect(createdById, 'many-to-one'),
              levelGroup,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
          if (idEntity) {
            inventoryItemListAllEmbed.map(v => {
              v['id'] = null;
              v['pharmacyInventory'] = { id: idEntity };
              this.props['createEntityInventoryItem'](v);
            });
          }
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              createdDateStart,
              createdDateEnd,
              getFilterFromSelect(createdById, 'many-to-one'),
              levelGroup,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/pharmacy-inventory?' + getEntityFiltersURL(this.state.fieldsBase));
  };

  getFullEntityFromState(v) {
    return {
      id: v.id,
      levelGroup: this.state.levelGroupSelectValue,
      inventoryItem: this.state.inventoryItemSelectValue,
      user: this.state.userSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    const inventoryItemEntity = v.inventoryItem;

    const userEntity = v.user;

    return {
      levelGroupSelectValue: v.levelGroup,
      inventoryItemSelectValue: inventoryItemEntity ? inventoryItemEntity['id'] : null,
      userSelectValue: userEntity ? userEntity['id'] : null,
    };
  }

  renderHeader() {
    const { pharmacyInventoryEntity, inventoryItems, users, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.pharmacyInventory.home.createLabel">Create a PharmacyInventory</Translate>
            ) : (
              <Translate contentKey="generadorApp.pharmacyInventory.home.editLabel">Edit a PharmacyInventory</Translate>
            )}
          </span>

          <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.pharmacyInventory.btnSave">Save</Translate>
          </Button>

          {!this.state.isNew ? (
            <>
              <Button
                color="primary"
                id="next-entity"
                disabled={updating}
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.createdDateStart,
                    this.state.fieldsBase.createdDateEnd,
                    this.state.fieldsBase.createdById,
                    this.state.fieldsBase.levelGroup,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 + 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 + 1 },
                    });
                    this.props.history.push(
                      '/pharmacy-inventory/' +
                        myresult.value.data[0]['id'] +
                        '/edit?' +
                        this.getFiltersURL(this.state.fieldsBase.offset * 1 + 1)
                    );
                  } else {
                    toast.error(
                      translate('generadorApp.last.item', { fieldName: translate('generadorApp.pharmacyInventory.detail.title') })
                    );
                  }
                }}
                className="float-right jh-create-entity"
              >
                {'>>'}
              </Button>

              <Button
                color="primary"
                id="prev-entity"
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.createdDateStart,
                    this.state.fieldsBase.createdDateEnd,
                    this.state.fieldsBase.createdById,
                    this.state.fieldsBase.levelGroup,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 - 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 - 1 },
                    });
                    this.props.history.push(
                      '/pharmacy-inventory/' +
                        myresult.value.data[0]['id'] +
                        '/edit?' +
                        this.getFiltersURL(this.state.fieldsBase.offset * 1 - 1)
                    );
                  } else {
                    toast.error(
                      translate('generadorApp.first.item', { fieldName: translate('generadorApp.pharmacyInventory.detail.title') })
                    );
                  }
                }}
                disabled={updating}
                className="float-right jh-create-entity"
              >
                {'<<'}
              </Button>
            </>
          ) : (
            <> </>
          )}

          <Button
            tag={Link}
            id="cancel-save"
            to={'/pharmacy-inventory?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.pharmacyInventory.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={'/pharmacy-inventory'}>
              <Translate contentKey="generadorApp.pharmacyInventory.home.title">Pharmacy Inventories</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.pharmacyInventory.detail.title">Pharmacy Inventory edit</Translate>
          </li>
        </ol>
      </>
    );
  }
  renderBody() {
    const { pharmacyInventoryEntity, inventoryItems, users, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const fullEntityEmbebed = this.getFullEntityFromState(pharmacyInventoryEntity);

    const mapDispatchToPropsInventoryItemList: any = {};
    Object.keys(mapDispatchToPropsInventoryItem).map(v => {
      mapDispatchToPropsInventoryItemList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'InventoryItem')) {
          this.setState({ functionEmbebed: [...this.state.functionEmbebed, v + 'InventoryItem'] }, this.props[v + 'InventoryItem']);
        }
      };
    });

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="pharmacy-inventory-id">
                  <Translate contentKey="generadorApp.pharmacyInventory.field_id">ID</Translate>
                </Label>
                </Col> */}
                      <Col md="12">
                        <AvInput id="pharmacy-inventory-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'levelGroup' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="levelGroupLabel" for="pharmacy-inventory-levelGroup">
                              <Translate contentKey="generadorApp.pharmacyInventory.levelGroup">Group</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="pharmacy-inventory-levelGroup"
                              className={'css-select-control'}
                              value={
                                this.state.levelGroupSelectValue
                                  ? {
                                      value: this.state.levelGroupSelectValue,
                                      label: translate('generadorApp.MedicineMaterialType.' + this.state.levelGroupSelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.pharmacyInventory.levelGroup') }
                              }
                              options={[
                                { value: 'MATERIAL', label: translate('generadorApp.MedicineMaterialType.MATERIAL') },
                                { value: 'MEDICAMENTO', label: translate('generadorApp.MedicineMaterialType.MEDICAMENTO') },
                                { value: 'DIETA', label: translate('generadorApp.MedicineMaterialType.DIETA') },
                              ]}
                              onChange={(options: any) => this.setState({ levelGroupSelectValue: options['value'] })}
                              name="levelGroup"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ levelGroupSelectValue: evt.target.value })}
                      type="hidden"
                      name="levelGroup"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'inventoryItem' ? (
                    <Col md="12">
                      <div className="entityEmbebedList">
                        <InventoryItem
                          entityEmbebedPai={fullEntityEmbebed}
                          history={this.props.history}
                          location={{
                            pathname: `/pharmacy-inventory/${this.props.match.params['id']}/inventory-item/`,
                            search: '',
                            hash: '',
                            state: undefined,
                            key: 'q2tbhd',
                          }}
                          match={{
                            path: `/pharmacy-inventory/:idPharmacyInventory/inventory-item/:id`,
                            url: `/pharmacy-inventory/${this.props.match.params['id']}/inventory-item/`,
                            isExact: true,
                            params: {
                              idPharmacyInventory: this.props.match.params['id'],
                              url: `/pharmacy-inventory/${this.props.match.params['id']}/inventory-item/`,
                            },
                          }}
                          {...this.props.mapStateToPropsInventoryItem}
                          {...mapDispatchToPropsInventoryItemList}
                          getEntityPai={(...v) => this.props.getEntity(...v)}
                          getEntities={this.inventoryItemGetEntities}
                          updateEntity={this.inventoryItemUpdateEntity}
                          getEntity={this.inventoryItemGetEntity}
                          createEntity={this.inventoryItemCreateEntity}
                          deleteEntity={this.inventoryItemDeleteEntity}
                          reset={this.inventoryItemReset}
                          inventoryItemList={this.props.inventoryItemListEmbed}
                          totalItems={this.props.inventoryItemListEmbed ? this.props.inventoryItemListEmbed.length : 0}
                          loading={this.props.loading}
                          inventoryItemEntity={this.props.inventoryItemSelected}
                        />
                      </div>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="inventoryItem"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}{' '}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { pharmacyInventoryEntity, inventoryItems, users, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...pharmacyInventoryEntity,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel className="pharmacy-inventory pharmacy-inventory-update">
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToProps = (storeState: IRootState) => {
  return {
    inventoryItems: storeState.inventoryItem.entities,
    users: storeState.userManagement.users,
    pharmacyInventoryEntity: storeState.pharmacyInventory.entity,
    loading: storeState.pharmacyInventory.loading,
    updating: storeState.pharmacyInventory.updating,
    updateSuccess: storeState.pharmacyInventory.updateSuccess,

    pharmacyInventorys: storeState.pharmacyInventory.entities,
    inventoryItemListAllEmbed: storeState.pharmacyInventory.inventoryItemListAllEmbed,
    inventoryItemListEmbed: storeState.pharmacyInventory.inventoryItemListEmbed,
    inventoryItemSelected: storeState.pharmacyInventory.inventoryItemSelected,
    mapStateToPropsInventoryItem: mapStateToPropsInventoryItem(storeState),
  };
};

const mapDispatchToPropsInventoryItemList = {};
Object.keys(mapDispatchToPropsInventoryItem).map(v => {
  mapDispatchToPropsInventoryItemList[v + 'InventoryItem'] = mapDispatchToPropsInventoryItem[v];
});

export const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  getEntityOffset,
  reset,

  ...mapDispatchToPropsInventoryItemList,
  inventoryItemUpdateEntity,
  inventoryItemGetEntities,
  inventoryItemSetEntities,
  inventoryItemReset,
  inventoryItemGetEntity,
  inventoryItemCreateEntity,
  inventoryItemDeleteEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyInventoryUpdate);
