/* eslint-disable no-console */
/* eslint complexity: ["error", 300] */
import React from 'react';
import { connect } from 'react-redux';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';
import { Table, Button } from 'reactstrap';
import { getAllEntities as getPermissions } from 'app/entities/permission/permission.reducer';
import { getEntity as getEntityPai } from 'app/entities/profile/profile.reducer';
import { getFilterFromSelect } from 'app/shared/util/entity-utils';

import { PermissionProfile, mapStateToProps, mapDispatchToProps } from '../permission-profile';
import { IRootState } from 'app/shared/reducers';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  permissionProfileSelectAll,
  permissionProfileUnselectAll,
} from 'app/modules/administration/user-management/user-management.reducer';

export interface IExtendedState {
  dumystate?: any;
}
export interface IExtendedProps {
  permissionBooleanList?: any;
  setPermissionBooleanList?: Function;
}

export class PermissionProfileExtended extends PermissionProfile {
  constructor(props: any) {
    super(props);
    this.permissionSelectAll = this.permissionSelectAll.bind(this);
    this.permissionUnselectAll = this.permissionUnselectAll.bind(this);
    this.state = {
      ...this.state,
    };
  }

  componentDidUpdate(prevProps) {
    const { loading, permissions, permissionProfileList, permissionBooleanList, setPermissionBooleanList } = this.props;
    if (permissionBooleanList == null && permissions && permissions.length > 0 && !loading) {
      const _permissionBooleanList = {};
      permissionProfileList.map((permissionProfile, i) => {
        if (permissionProfile.permission && permissionProfile.permission.id) {
          _permissionBooleanList[permissionProfile.permission.id] = permissionProfile;
        }
      });
      setPermissionBooleanList(_permissionBooleanList);
    }
  }

  UNSAFE_componentWillMount() {
    this.getEntities();
    this.props.getPermissions();
  }

  getEntities = () => {
    const selectFieldsList = ['permission.name', 'view', 'resgister', 'edit', 'canDelete'];
    const {
      view,
      resgister,
      edit,
      canDelete,
      manualRemoval,
      editLocation,
      report,
      activateProfessional,
      deletePhoto,
      financialValue,
      valueAuthorization,
      confirmPayment,
      evolution,
      professionalName,
      professionalRegister,
      profileId,
      permissionId,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;
    return this.props.getEntities(
      view,
      resgister,
      edit,
      canDelete,
      manualRemoval,
      editLocation,
      report,
      activateProfessional,
      deletePhoto,
      financialValue,
      valueAuthorization,
      confirmPayment,
      evolution,
      professionalName,
      professionalRegister,
      getFilterFromSelect(profileId, 'many-to-one'),
      getFilterFromSelect(permissionId, 'many-to-one'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(',')
    );
  };

  renderHeader() {
    return <> </>;
  }

  renderFilter() {
    return <> </>;
  }

  permissionSelectAll = permissionProfile => {
    const { permissions } = this.props;

    const getEntities = this.getEntities;

    new Promise(resolve => {
      resolve(this.props.permissionProfileSelectAll(permissionProfile, permissions));
    }).then(resultEntity => {
      window.location.reload();
    });
  };
  permissionUnselectAll = permissionProfile => {
    const { permissions } = this.props;

    const getEntities = this.getEntities;

    new Promise(resolve => {
      resolve(this.props.permissionProfileUnselectAll(permissionProfile, permissions));
    }).then(resultEntity => {
      window.location.reload();
    });
  };

  renderTable() {
    const { permissions, permissionBooleanList, setPermissionBooleanList, loading } = this.props;

    const props = this.props;
    const state = this.state;

    return (
      <>
        {!loading && permissionBooleanList != null && permissions && permissions.length > 0 ? (
          <div>
            <Button
              id="togglerFilterPermissionProfile"
              color="default"
              onClick={() => {
                this.permissionUnselectAll({ id: state.profileId[0]['value'] });
              }}
              className="float-right jh-create-entity"
            >
              <Translate contentKey="generadorApp.permission.un_select_all">Unselect all</Translate>
              &nbsp;
              <FontAwesomeIcon icon="toggle-on" />
            </Button>
            <Button
              id="togglerFilterPermissionProfile"
              color="primary"
              onClick={() => this.permissionSelectAll({ id: state.profileId[0]['value'] })}
              className="float-right jh-create-entity"
            >
              <Translate contentKey="generadorApp.permission.select_all">Select all</Translate>
              &nbsp;
              <FontAwesomeIcon icon="toggle-off" />
            </Button>

            <Table
              id="permission-user-table-list"
              responsive
              aria-describedby="permission-user-heading"
              className={'table-hover table-striped mt-4 table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th id="permission-id"></th>
                  <th>
                    <Translate contentKey="generadorApp.permissionProfile.permission">Permission</Translate>
                  </th>
                  {Object.keys(permissions[0])
                    .filter(
                      v =>
                        ![
                          'id',
                          'createdDate',
                          'lastModifiedDate',
                          'deletedAt',
                          'createdBy',
                          'lastModifiedBy',
                          'slug',
                          'name',
                          'permissionProfile',
                          'permissionProfile',
                        ].includes(v)
                    )
                    .filter(v => permissions.map(x => x[v]).includes(true))
                    .map((v, j) => (
                      <th key={j}>
                        <Translate contentKey={'generadorApp.permissionProfile.' + v}>{v}</Translate>
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {permissions.map((permissionProfile, i) => (
                  <tr key={i}>
                    <td key={i}></td>
                    <td id="permission-cell">{translate(permissionProfile.name)}</td>

                    {Object.keys(permissions[i])
                      .filter(
                        v =>
                          ![
                            'id',
                            'createdDate',
                            'lastModifiedDate',
                            'deletedAt',
                            'createdBy',
                            'lastModifiedBy',
                            'slug',
                            'name',
                            'permissionProfile',
                            'permissionUser',
                          ].includes(v)
                      )
                      .filter(v => permissions.map(x => x[v]).includes(true))
                      .map((v, j) =>
                        permissions[i][v] === true ? (
                          <td key={j} id={v + '-cell'}>
                            <b className="visible-xs">
                              {' '}
                              <Translate contentKey={'generadorApp.permissionProfile.' + v}>{v}</Translate>{' '}
                            </b>
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={v + '_' + permissionProfile.id}
                                id={v + '_' + permissionProfile.id}
                                defaultChecked={
                                  permissionBooleanList[permissionProfile.id] ? permissionBooleanList[permissionProfile.id][v] : false
                                }
                                onChange={obj => {
                                  const _permissionBooleanList = JSON.parse(JSON.stringify(permissionBooleanList));
                                  if (!_permissionBooleanList[permissionProfile.id]) {
                                    _permissionBooleanList[permissionProfile.id] = {
                                      permission: permissions[i],
                                      profile: this.props.entityEmbebedPai,
                                    };
                                  }

                                  _permissionBooleanList[permissionProfile.id][v] = obj.target.checked;
                                  console.info(_permissionBooleanList);
                                  setPermissionBooleanList(_permissionBooleanList);
                                }}
                              />
                              <label htmlFor={v + '_' + permissionProfile.id} />
                            </div>
                          </td>
                        ) : (
                          <td key={j} id={v + '-cell'}>
                            {' '}
                          </td>
                        )
                      )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    return <> {super.renderFooter()} </>;
  }

  render() {
    const { permissionProfileList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel>
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
            {this.renderNewModal()}

            {this.renderUpdateModal()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
  permissionProfileSelectAll,
  permissionProfileUnselectAll,
  getEntityPai,
  getPermissions,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;

export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(PermissionProfileExtended);
