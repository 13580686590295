import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';

import { Translate } from 'react-jhipster';

import { NavDropdown } from './menu-components';

export const EntitiesMenu = props => (
  <NavDropdown
    icon="asterisk"
    name="Entities"
    id="entity-menu"
    style={{ position: 'absolute', width: 320, height: 500, overflowX: 'hidden', overflowY: 'auto' }}
  >
    <MenuItem icon="asterisk" to="/company">
      <Translate contentKey="global.menu.entities.company" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/brand">
      <Translate contentKey="global.menu.entities.brand" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/brasindice">
      <Translate contentKey="global.menu.entities.brasindice" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/category">
      <Translate contentKey="global.menu.entities.category" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/cid">
      <Translate contentKey="global.menu.entities.cid" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/cl-com-ruler">
      <Translate contentKey="global.menu.entities.clComRuler" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/cl-com-item">
      <Translate contentKey="global.menu.entities.clComItem" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/billing-xml">
      <Translate contentKey="global.menu.entities.billingXml" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/bill-to-pay">
      <Translate contentKey="global.menu.entities.billToPay" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/variable-cost">
      <Translate contentKey="global.menu.entities.variableCost" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/fixed-cost">
      <Translate contentKey="global.menu.entities.fixedCost" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/cl-com-table">
      <Translate contentKey="global.menu.entities.clComTable" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/client-contact">
      <Translate contentKey="global.menu.entities.clientContact" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/client-file">
      <Translate contentKey="global.menu.entities.clientFile" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/medical-record-file">
      <Translate contentKey="global.menu.entities.medicalRecordFile" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/user-file">
      <Translate contentKey="global.menu.entities.userFile" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/client">
      <Translate contentKey="global.menu.entities.client" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/dose">
      <Translate contentKey="global.menu.entities.dose" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/specialty">
      <Translate contentKey="global.menu.entities.specialty" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/expected-result">
      <Translate contentKey="global.menu.entities.expectedResult" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/level-group">
      <Translate contentKey="global.menu.entities.levelGroup" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/kit-diet">
      <Translate contentKey="global.menu.entities.kitDiet" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/kit-material">
      <Translate contentKey="global.menu.entities.kitMaterial" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/kit-medicine">
      <Translate contentKey="global.menu.entities.kitMedicine" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/kit-product-service">
      <Translate contentKey="global.menu.entities.kitProductService" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/kit-rhs">
      <Translate contentKey="global.menu.entities.kitRhs" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/kit">
      <Translate contentKey="global.menu.entities.kit" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/medicine-material">
      <Translate contentKey="global.menu.entities.medicineMaterial" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/medical-record">
      <Translate contentKey="global.menu.entities.medicalRecord" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/nfe">
      <Translate contentKey="global.menu.entities.nfe" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/nfe-file">
      <Translate contentKey="global.menu.entities.nfeFile" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/notification-user">
      <Translate contentKey="global.menu.entities.notificationUser" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/notification">
      <Translate contentKey="global.menu.entities.notification" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/nursing-diary">
      <Translate contentKey="global.menu.entities.nursingDiary" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/sub-tag">
      <Translate contentKey="global.menu.entities.subTag" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/config-tag">
      <Translate contentKey="global.menu.entities.configTag" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/clinical-indicator">
      <Translate contentKey="global.menu.entities.clinicalIndicator" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/patient-clinical-indicator">
      <Translate contentKey="global.menu.entities.patientClinicalIndicator" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/nursing-prescription">
      <Translate contentKey="global.menu.entities.nursingPrescription" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/patient-contact">
      <Translate contentKey="global.menu.entities.patientContact" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/professional-device">
      <Translate contentKey="global.menu.entities.professionalDevice" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/patient-device">
      <Translate contentKey="global.menu.entities.patientDevice" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/pat-nr-prescription-diagnostic">
      <Translate contentKey="global.menu.entities.patNrPrescriptionDiagnostic" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/patient-doctor-prescription">
      <Translate contentKey="global.menu.entities.patientDoctorPrescription" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/dr-status-history">
      <Translate contentKey="global.menu.entities.drStatusHistory" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/nr-status-history">
      <Translate contentKey="global.menu.entities.nrStatusHistory" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/pat-dr-prescr-procd">
      <Translate contentKey="global.menu.entities.patDrPrescrProcd" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/patient-file">
      <Translate contentKey="global.menu.entities.patientFile" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/patient-nursing-prescription">
      <Translate contentKey="global.menu.entities.patientNursingPrescription" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/pat-nr-presc-procediment">
      <Translate contentKey="global.menu.entities.patNrPrescProcediment" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/patient-responsible">
      <Translate contentKey="global.menu.entities.patientResponsible" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/patient-status-history">
      <Translate contentKey="global.menu.entities.patientStatusHistory" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/client-status-history">
      <Translate contentKey="global.menu.entities.clientStatusHistory" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/level-group-status-history">
      <Translate contentKey="global.menu.entities.levelGroupStatusHistory" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/category-status-history">
      <Translate contentKey="global.menu.entities.categoryStatusHistory" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/specialty-status-history">
      <Translate contentKey="global.menu.entities.specialtyStatusHistory" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/product-service-status-history">
      <Translate contentKey="global.menu.entities.productServiceStatusHistory" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/kit-status-history">
      <Translate contentKey="global.menu.entities.kitStatusHistory" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/medicine-material-status">
      <Translate contentKey="global.menu.entities.medicineMaterialStatus" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/professional-status-history">
      <Translate contentKey="global.menu.entities.professionalStatusHistory" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/supplier-status-history">
      <Translate contentKey="global.menu.entities.supplierStatusHistory" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/patient">
      <Translate contentKey="global.menu.entities.patient" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/patient-cid">
      <Translate contentKey="global.menu.entities.patientCid" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/cid-status-history">
      <Translate contentKey="global.menu.entities.cidStatusHistory" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/patient-allergy">
      <Translate contentKey="global.menu.entities.patientAllergy" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/permission">
      <Translate contentKey="global.menu.entities.permission" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/permission-user">
      <Translate contentKey="global.menu.entities.permissionUser" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/permission-profile">
      <Translate contentKey="global.menu.entities.permissionProfile" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/profile">
      <Translate contentKey="global.menu.entities.profile" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/pharmacy-inventory">
      <Translate contentKey="global.menu.entities.pharmacyInventory" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/inventory-item">
      <Translate contentKey="global.menu.entities.inventoryItem" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/pharmacy-stock-devolution">
      <Translate contentKey="global.menu.entities.pharmacyStockDevolution" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/pharmacy-stock">
      <Translate contentKey="global.menu.entities.pharmacyStock" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/pharmacy-stock-status">
      <Translate contentKey="global.menu.entities.pharmacyStockStatus" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/presentation">
      <Translate contentKey="global.menu.entities.presentation" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/product-service">
      <Translate contentKey="global.menu.entities.productService" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/professional-advice">
      <Translate contentKey="global.menu.entities.professionalAdvice" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/professional-contact">
      <Translate contentKey="global.menu.entities.professionalContact" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/professional-contraction-type">
      <Translate contentKey="global.menu.entities.professionalContractionType" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/professional-file">
      <Translate contentKey="global.menu.entities.professionalFile" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/professional-occupation-area">
      <Translate contentKey="global.menu.entities.professionalOccupationArea" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/professional">
      <Translate contentKey="global.menu.entities.professional" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/quote-diet">
      <Translate contentKey="global.menu.entities.quoteDiet" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/quote-file">
      <Translate contentKey="global.menu.entities.quoteFile" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/quote-material">
      <Translate contentKey="global.menu.entities.quoteMaterial" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/quote-medicine">
      <Translate contentKey="global.menu.entities.quoteMedicine" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/quote-product-service">
      <Translate contentKey="global.menu.entities.quoteProductService" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/quote-rh">
      <Translate contentKey="global.menu.entities.quoteRh" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/quote-package">
      <Translate contentKey="global.menu.entities.quotePackage" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/quote">
      <Translate contentKey="global.menu.entities.quote" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/reports">
      <Translate contentKey="global.menu.entities.reports" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/segment">
      <Translate contentKey="global.menu.entities.segment" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/simpro">
      <Translate contentKey="global.menu.entities.simpro" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/supplier-table">
      <Translate contentKey="global.menu.entities.supplierTable" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/supplier-table-diet">
      <Translate contentKey="global.menu.entities.supplierTableDiet" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/supplier-table-material">
      <Translate contentKey="global.menu.entities.supplierTableMaterial" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/supplier-table-medicine">
      <Translate contentKey="global.menu.entities.supplierTableMedicine" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/supplier-table-product-service">
      <Translate contentKey="global.menu.entities.supplierTableProductService" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/supplier-table-rh">
      <Translate contentKey="global.menu.entities.supplierTableRh" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/supplier-contact">
      <Translate contentKey="global.menu.entities.supplierContact" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/supplier-file">
      <Translate contentKey="global.menu.entities.supplierFile" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/supplier">
      <Translate contentKey="global.menu.entities.supplier" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/pad">
      <Translate contentKey="global.menu.entities.pad" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/cid-pta">
      <Translate contentKey="global.menu.entities.cidPta" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/pad-cid-pta">
      <Translate contentKey="global.menu.entities.padCidPta" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/activity">
      <Translate contentKey="global.menu.entities.activity" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/pad-attendance">
      <Translate contentKey="global.menu.entities.padAttendance" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/pad-attendance-file">
      <Translate contentKey="global.menu.entities.padAttendanceFile" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/push">
      <Translate contentKey="global.menu.entities.push" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/supply">
      <Translate contentKey="global.menu.entities.supply" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/client-glosa">
      <Translate contentKey="global.menu.entities.clientGlosa" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/client-glosa-item">
      <Translate contentKey="global.menu.entities.clientGlosaItem" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/supplier-glosa">
      <Translate contentKey="global.menu.entities.supplierGlosa" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/supplier-glosa-item">
      <Translate contentKey="global.menu.entities.supplierGlosaItem" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/patient-supplier">
      <Translate contentKey="global.menu.entities.patientSupplier" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/pharmacy-stock-quote">
      <Translate contentKey="global.menu.entities.pharmacyStockQuote" />
    </MenuItem>
    {/*   jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
  </NavDropdown>
);
