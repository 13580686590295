import React from 'react';

export interface IPadProps {
  baseState?: any;
  baseProps?: any;
  setState?: any;
  entityBase?: any;
}

const PadComponentCustomize = (props: IPadProps) => {
  const pad = props.entityBase;
  const listaTotalPadAttendance = [];

  const quoteRhList = props.entityBase && props.entityBase.quote && props.entityBase.quote.quoteRh ? props.entityBase.quote.quoteRh : [];
  const padAttendanceList = props.entityBase.padAttendance;
  const padAttendanceListPlanned = padAttendanceList
    ? padAttendanceList.filter(v => (typeof v.deletedAt === 'undefined' || v.deletedAt === null) && v.date !== null)
    : [];

  const totais = quoteRhList.map(v => v.days - padAttendanceListPlanned.filter(v1 => v1.quoteRh && v1.quoteRh.id === v.id).length);

  return (
    <>
      {totais.map((val, j) => (
        <div key={j}>
          {val <= 0 ? (
            <div key={j} style={{ padding: 0, margin: 0, textAlign: 'center' }} className="alert alert-success">
              &nbsp;Completo&nbsp;
            </div>
          ) : (
            <div key={j} style={{ padding: 0, margin: 0, textAlign: 'center' }} className="alert alert-danger">
              &nbsp;Incompleto&nbsp;
            </div>
          )}
        </div>
      ))}
    </>
  );
};

const mapStateToProps = storeState => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default PadComponentCustomize;
