import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { Translate, translate } from 'app/config/translate-component';
import { AttendanceStatus } from 'app/shared/model/enumerations/attendance-status.model';
import React from 'react';
import { TextFormat } from 'react-jhipster';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';

export interface IPatientProps extends StateProps, DispatchProps {
  setState?: any;
  baseProps?: any;
  baseState?: any;
}

const patientComponentCustomize = (props: IPatientProps) => {
  const professionals = [];
  const padAttendanceList = [];
  props.patientEntity &&
    props.patientEntity.pad &&
    props.patientEntity.pad.map(p => {
      p.padAttendance.map(attendance => {
        padAttendanceList.push(attendance);
      });
    });
  return padAttendanceList && padAttendanceList.length > 0 ? (
    <Table
      id="pad-attendance-table-list"
      responsive
      aria-describedby="pad-attendance-heading"
      className={'table-hover table-striped table-responsive-css'}
    >
      <thead className={'thead-light'}>
        <tr>
          <th>
            {' '}
            <Translate contentKey="generadorApp.padAttendance.professional">Professional</Translate>
          </th>
          <th>
            {' '}
            <Translate contentKey="generadorApp.padAttendance.period">Period</Translate>
          </th>
          <th>
            {' '}
            <Translate contentKey="generadorApp.padAttendance.schedule">Schedule</Translate>
          </th>
          <th>
            {' '}
            <Translate contentKey="generadorApp.padAttendance.push">Push</Translate>
          </th>
          <th>
            {' '}
            <Translate contentKey="generadorApp.padAttendance.date">Date</Translate>
          </th>
          <th>
            {' '}
            <Translate contentKey="generadorApp.padAttendance.value">Value</Translate>
          </th>
          <th>
            {' '}
            <Translate contentKey="generadorApp.padAttendance.frequency">Frequency</Translate>
          </th>
          <th>
            {' '}
            <Translate contentKey="generadorApp.padAttendance.status">Status</Translate>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr></tr>
        {padAttendanceList
          .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
          .map((padAttendance, i) => {
            return (
              <tr key={`entity-${i}`} className={AttendanceStatus.CANCELADO === padAttendance.status ? 'alert alert-danger' : ''}>
                <td id="professional-cell">
                  <b className="visible-xs"> Professional </b>
                  {padAttendance && padAttendance.professional && padAttendance.professional['name']
                    ? padAttendance.professional['name']
                    : professionals.filter(v => v.padAttendance.map(b => b.id).includes(padAttendance.id)).map((v, k) => v.name)}
                </td>
                <td id="period-cell">
                  <b className="visible-xs"> Period </b>
                  {padAttendance.period ? <Translate contentKey={`generadorApp.PadAttendancePeriod.${padAttendance.period}`} /> : <> </>}
                </td>
                <td id="schedule-cell">
                  <b className="visible-xs"> Schedule </b>

                  {padAttendance.schedule}
                </td>
                <td id="push-cell">
                  <b className="visible-xs"> Push </b>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: padAttendance.push
                        ? Buffer.from(padAttendance.push)
                            .toString()
                            .replace(/(<([^>]+)>)/gi, '')
                            .substring(0, 150)
                        : null,
                    }}
                  />
                </td>
                <td id="date-cell">
                  <b className="visible-xs"> Date </b>
                  <TextFormat type="date" value={padAttendance.date} format={APP_LOCAL_DATE_FORMAT} />
                </td>
                <td id="value-cell">
                  <b className="visible-xs"> Value </b>
                  R$ {(padAttendance.valuePad * 1).toFixed(2).replace('.', ',')}
                </td>
                <td id="frequency-cell">
                  {padAttendance.frequency ? <Translate contentKey={`generadorApp.QuoteFrequency.${padAttendance.frequency}`} /> : <> </>}
                </td>
                <td id="quantity-cell">{translate('generadorApp.AttendanceStatus.' + padAttendance.status)}</td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  ) : (
    <div className="alert alert-warning">
      <Translate contentKey="generadorApp.padAttendance.home.notFound">No Attendance found</Translate>
    </div>
  );
};

const mapStateToProps = storeState => ({
  patientEntity: storeState.patient.entity,
});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(patientComponentCustomize);
