/* eslint complexity: ["error", 500] */
/* eslint no-empty: off */
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import SelectAsync from 'react-select/async';

import { Link, RouteComponentProps } from 'react-router-dom';

import { getFilterFromSelect, getFormFromSelect, getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Col, Label } from 'reactstrap';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import {
  ISupplyUpdateState as IBaseUpdateState,
  getEntity,
  getSupplyState,
  getUrlBack,
  updateEntity,
  createEntity,
  getEntityFiltersURL,
  getEntityOffset,
  reset,
} from './supply.reducer';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';
import moment from 'moment';
registerLocale('pt', pt);

export interface ISupplyUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}
export type ISupplyUpdateState = IBaseUpdateState;

export class SupplyUpdate extends React.Component<ISupplyUpdateProps, ISupplyUpdateState> {
  initDateFileInput: any;

  finalDateFileInput: any;

  statusFileInput: any;
  constructor(props: Readonly<ISupplyUpdateProps>) {
    super(props);

    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      functionEmbebed: [],

      quoteSelectValue: null,

      supplierSelectValue: null,

      initDateSelectValue: null,
      finalDateSelectValue: null,
      statusSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getSupplyState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
      quoteId: null,
      supplierId: null,

      isNew: !this.props.match.params || !this.props.match.params['id'],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateSuccess !== this.props.updateSuccess && prevProps.updateSuccess) {
      this.handleClose();
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.supplyEntity && prevProps.supplyEntity.id !== this.props.supplyEntity.id) ||
      (this.props.quotes && this.state.quoteSelectValue === null && this.props.supplyEntity.quote && this.props.supplyEntity.quote.id)
    ) {
      const p = this.props.supplyEntity && this.props.supplyEntity.quote ? this.props.supplyEntity.quote : null;
      this.setState({
        quoteSelectValue: p ? { ...p, value: p.id, label: p['id'] ? p.id : '' } : null,
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.supplyEntity && prevProps.supplyEntity.id !== this.props.supplyEntity.id) ||
      (this.props.suppliers &&
        this.state.supplierSelectValue === null &&
        this.props.supplyEntity.supplier &&
        this.props.supplyEntity.supplier.id)
    ) {
      const p = this.props.supplyEntity && this.props.supplyEntity.supplier ? this.props.supplyEntity.supplier : null;
      this.setState({
        supplierSelectValue: p ? { ...p, value: p.id, label: p['id'] ? p.id : '' } : null,
      });
    }

    if (
      (prevProps.supplyEntity && prevProps.supplyEntity.id !== this.props.supplyEntity.id) ||
      (this.props.supplyEntity.initDate &&
        this.state.initDateSelectValue === null &&
        this.props.supplyEntity.initDate !== this.state.initDateSelectValue)
    ) {
      this.setState({ initDateSelectValue: this.props.supplyEntity.initDate ? this.props.supplyEntity.initDate : null });
    }
    if (
      (prevProps.supplyEntity && prevProps.supplyEntity.id !== this.props.supplyEntity.id) ||
      (this.props.supplyEntity.finalDate &&
        this.state.finalDateSelectValue === null &&
        this.props.supplyEntity.finalDate !== this.state.finalDateSelectValue)
    ) {
      this.setState({ finalDateSelectValue: this.props.supplyEntity.finalDate ? this.props.supplyEntity.finalDate : null });
    }
    if (
      (prevProps.supplyEntity && prevProps.supplyEntity.id !== this.props.supplyEntity.id) ||
      (this.props.supplyEntity.status &&
        this.state.statusSelectValue === null &&
        this.props.supplyEntity.status !== this.state.statusSelectValue)
    ) {
      this.setState({ statusSelectValue: this.props.supplyEntity.status ? this.props.supplyEntity.status : null });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  UNSAFE_componentWillMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params['id']);
    }
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = offset !== null ? '&offset=' + offset : '';
    Object.keys(fieldsBase).map(key => {
      url += key !== 'offset' ? '&' + key + '=' + fieldsBase[key] : '';
    });
    return url;
  };
  saveEntity = (event: any, errors: any, values: any) => {
    const selectFieldsList = ['status', 'initDate', 'finalDate', 'quote.id'];

    if (errors.length === 0) {
      const { supplyEntity } = this.props;
      const _entity = {
        ...supplyEntity,
        // ...values,

        quote: getFormFromSelect(this.state.quoteSelectValue, 'many-to-one'), // many-to-one - otherSide

        initDate: this.state.initDateSelectValue,
        finalDate: this.state.finalDateSelectValue,
        status: this.state.statusSelectValue === null ? null : this.state.statusSelectValue,
      };

      const entity = _entity;

      const {
        id,
        status,
        initDateStart,
        initDateEnd,
        finalDateStart,
        finalDateEnd,
        quoteId,
        extraFilters,
        activePage,
        itemsPerPage,
        sort,
        order,
      } = this.state.fieldsBase;

      if (this.state.isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              id,
              status,
              initDateStart,
              initDateEnd,
              finalDateStart,
              finalDateEnd,
              getFilterFromSelect(quoteId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              id,
              status,
              initDateStart,
              initDateEnd,
              finalDateStart,
              finalDateEnd,
              getFilterFromSelect(quoteId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/supply?' + getEntityFiltersURL(this.state.fieldsBase));
  };

  getFullEntityFromState(v) {
    return {
      id: v.id,
      initDate: this.state.initDateSelectValue,
      finalDate: this.state.finalDateSelectValue,
      status: this.state.statusSelectValue,
      quote: this.state.quoteSelectValue,
      supplier: this.state.supplierSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    const quoteEntity = v.quote;

    const supplierEntity = v.supplier;

    return {
      initDateSelectValue: v.initDate,
      finalDateSelectValue: v.finalDate,
      statusSelectValue: v.status,
      quoteSelectValue: quoteEntity ? quoteEntity['id'] : null,
      supplierSelectValue: supplierEntity ? supplierEntity['id'] : null,
    };
  }

  renderHeader() {
    const { supplyEntity, quotes, suppliers, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.supply.home.createLabel">Create a Supply</Translate>
            ) : (
              <Translate contentKey="generadorApp.supply.home.editLabel">Edit a Supply</Translate>
            )}
          </span>

          <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.supply.btnSave">Save</Translate>
          </Button>

          {!this.state.isNew ? (
            <>
              <Button
                color="primary"
                id="next-entity"
                disabled={updating}
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.id,
                    this.state.fieldsBase.status,
                    this.state.fieldsBase.initDateStart,
                    this.state.fieldsBase.initDateEnd,
                    this.state.fieldsBase.finalDateStart,
                    this.state.fieldsBase.finalDateEnd,
                    this.state.fieldsBase.quoteId,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 + 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 + 1 },
                    });
                    this.props.history.push(
                      '/supply/' + myresult.value.data[0]['id'] + '/edit?' + this.getFiltersURL(this.state.fieldsBase.offset * 1 + 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.last.item', { fieldName: translate('generadorApp.supply.detail.title') }));
                  }
                }}
                className="float-right jh-create-entity"
              >
                {'>>'}
              </Button>

              <Button
                color="primary"
                id="prev-entity"
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.id,
                    this.state.fieldsBase.status,
                    this.state.fieldsBase.initDateStart,
                    this.state.fieldsBase.initDateEnd,
                    this.state.fieldsBase.finalDateStart,
                    this.state.fieldsBase.finalDateEnd,
                    this.state.fieldsBase.quoteId,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 - 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 - 1 },
                    });
                    this.props.history.push(
                      '/supply/' + myresult.value.data[0]['id'] + '/edit?' + this.getFiltersURL(this.state.fieldsBase.offset * 1 - 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.first.item', { fieldName: translate('generadorApp.supply.detail.title') }));
                  }
                }}
                disabled={updating}
                className="float-right jh-create-entity"
              >
                {'<<'}
              </Button>
            </>
          ) : (
            <> </>
          )}

          <Button
            tag={Link}
            id="cancel-save"
            to={'/supply?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.supply.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={'/supply'}>
              <Translate contentKey="generadorApp.supply.home.title">Supplies</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.supply.detail.title">Supply edit</Translate>
          </li>
        </ol>
      </>
    );
  }
  renderBody() {
    const { supplyEntity, quotes, suppliers, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const fullEntityEmbebed = this.getFullEntityFromState(supplyEntity);

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="supply-id">
                  <Translate contentKey="generadorApp.supply.field_id">ID</Translate>
                </Label>
                </Col> */}
                      <Col md="12">
                        <AvInput id="supply-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'status' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="statusLabel" for="supply-status">
                              <Translate contentKey="generadorApp.supply.status">Status</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="supply-status"
                              className={'css-select-control'}
                              value={
                                this.state.statusSelectValue
                                  ? {
                                      value: this.state.statusSelectValue,
                                      label: translate('generadorApp.PadStatus.' + this.state.statusSelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.supply.status') }
                              }
                              options={[
                                { value: 'COMPLETO', label: translate('generadorApp.PadStatus.COMPLETO') },
                                { value: 'INCOMPLETO', label: translate('generadorApp.PadStatus.INCOMPLETO') },
                              ]}
                              onChange={(options: any) => this.setState({ statusSelectValue: options['value'] })}
                              name="status"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ statusSelectValue: evt.target.value })}
                      type="hidden"
                      name="status"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'initDate' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="initDateLabel" for="supply-initDate">
                              <Translate contentKey="generadorApp.supply.initDate">Initial date</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <DatePicker
                              autoComplete="off"
                              onChange={value => this.setState({ initDateSelectValue: value })}
                              id="initDate"
                              validate={{}}
                              className="form-control react-datepicker"
                              name="initDate"
                              dateFormat="dd/MM/yyyy"
                              selected={this.state.initDateSelectValue ? moment(this.state.initDateSelectValue).toDate() : null}
                              locale="pt"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ initDateSelectValue: evt.target.value })}
                      type="hidden"
                      name="initDate"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'finalDate' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="finalDateLabel" for="supply-finalDate">
                              <Translate contentKey="generadorApp.supply.finalDate">Final date</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <DatePicker
                              autoComplete="off"
                              onChange={value => this.setState({ finalDateSelectValue: value })}
                              id="finalDate"
                              validate={{}}
                              className="form-control react-datepicker"
                              name="finalDate"
                              dateFormat="dd/MM/yyyy"
                              selected={this.state.finalDateSelectValue ? moment(this.state.finalDateSelectValue).toDate() : null}
                              locale="pt"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ finalDateSelectValue: evt.target.value })}
                      type="hidden"
                      name="finalDate"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'quote' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="supply-quote">
                              <Translate contentKey="generadorApp.supply.quote">Quote</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="supply-quote"
                              name={'quote'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.quoteSelectValue ? this.state.quoteSelectValue : ''}
                              onChange={options => this.setState({ quoteSelectValue: options })}
                              defaultOptions={this.state.quoteStartSelectOptions ? this.state.quoteStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.quoteStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.quoteStartSelectOptions === undefined) {
                                  const result = await getListAxios('quotes', {}, 0, 100, 'id,asc', 'id');
                                  this.setState({
                                    quoteStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios('quotes', { 'id.contains': inputValue }, 0, 100, 'id,asc', 'id');
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="quote"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}{' '}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { supplyEntity, quotes, suppliers, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...supplyEntity,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel className="supply supply-update">
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToProps = (storeState: IRootState) => {
  return {
    quotes: storeState.quote.entities,
    suppliers: storeState.supplier.entities,
    supplyEntity: storeState.supply.entity,
    loading: storeState.supply.loading,
    updating: storeState.supply.updating,
    updateSuccess: storeState.supply.updateSuccess,
  };
};

export const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  getEntityOffset,
  reset,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SupplyUpdate);
