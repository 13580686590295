import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { IClient } from 'app/shared/model/client.model';
import { IPatient } from 'app/shared/model/patient.model';
import { IProfessional } from 'app/shared/model/professional.model';
import { ICategory } from 'app/shared/model/category.model';
import { ISpecialty } from 'app/shared/model/specialty.model';
import { TypePush } from 'app/shared/model/enumerations/type-push.model';

export interface IPush {
  id?: number;
  type?: TypePush;
  message?: any;
  client?: IClient;
  patient?: IPatient;
  professional?: IProfessional;
  category?: ICategory;
  specialty?: ISpecialty;
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  client: {
    isMulti: true, // many-to-one - other-side
    entity: 'Client',
    urlParameters: {
      enpoint: 'clients',
      filters: 'push.id.equals',
    },
  },
  patient: {
    isMulti: true, // many-to-one - other-side
    entity: 'Patient',
    urlParameters: {
      enpoint: 'patients',
      filters: 'push.id.equals',
    },
  },
  professional: {
    isMulti: true, // many-to-one - other-side
    entity: 'Professional',
    urlParameters: {
      enpoint: 'professionals',
      filters: 'push.id.equals',
    },
  },
  category: {
    isMulti: true, // many-to-one - other-side
    entity: 'Category',
    urlParameters: {
      enpoint: 'categories',
      filters: 'push.id.equals',
    },
  },
  specialty: {
    isMulti: true, // many-to-one - other-side
    entity: 'Specialty',
    urlParameters: {
      enpoint: 'specialties',
      filters: 'push.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'push.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'push.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<IPush> = {};
