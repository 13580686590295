import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity, IPermissionUserBaseState, getUrlBack, getPermissionUserState, getEntityFiltersURL } from './permission-user.reducer';

export interface IPermissionUserState {
  fieldsBase: IPermissionUserBaseState;
}

export interface IPermissionUserDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class PermissionUserDetail extends React.Component<IPermissionUserDetailProps, IPermissionUserState> {
  constructor(props: Readonly<IPermissionUserDetailProps>) {
    super(props);
    this.state = {
      ...this.state,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getPermissionUserState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
    };
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = '_back=1' + (offset !== null ? '&offset=' + offset : '');
    Object.keys(fieldsBase).map(key => {
      url += '&' + key + '=' + fieldsBase[key];
    });
    return url;
  };

  UNSAFE_componentWillMount() {
    this.props.getEntity(this.props.match.params['id']);
  }

  renderHeader() {
    const { permissionUserEntity } = this.props;
    const state = this.state;
    return (
      <>
        <div id="page-heading">
          {' '}
          <h2>
            <Translate contentKey="generadorApp.permissionUser.detail.title">PermissionUser</Translate>
            <b>{permissionUserEntity.id}</b>
          </h2>
        </div>

        <div className="pull-right">
          <Button tag={Link} to={'/permission-user?' + getEntityFiltersURL(this.state.fieldsBase)} replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.permissionUser.btnBack">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/permission-user/${permissionUserEntity.id}/edit?+${getEntityFiltersURL(this.state.fieldsBase)}`}
            replace
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.permissionUser.btnEdit">Edit</Translate>
            </span>
          </Button>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>

          <li className="breadcrumb-item active">
            <Link to={'/permission-user'}>Permission Users</Link>
          </li>
          <li className="breadcrumb-item active">Permission Users details</li>
        </ol>
      </>
    );
  }

  renderBody() {
    const { permissionUserEntity } = this.props;
    const state = this.state;
    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            <Row className="jh-entity-details">
              <Col className="view-detail-row permission-user-permission-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top permission-user-permission-detail-label" md="12">
                    <dt>
                      <Translate contentKey="generadorApp.permissionUser.permission">Permission</Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top permission-user-permission-detail-value" md="12">
                    <dd>{permissionUserEntity.permission ? permissionUserEntity.permission.id : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row permission-user-user-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top permission-user-user-detail-label" md="12">
                    <dt>
                      <Translate contentKey="generadorApp.permissionUser.user">User</Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top permission-user-user-detail-value" md="12">
                    <dd>{permissionUserEntity.user ? permissionUserEntity.user.id : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row permission-user-view-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top permission-user-view-detail-label" md="12">
                    <dt>
                      <span id="view">
                        <Translate contentKey="generadorApp.permissionUser.view">View</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top permission-user-view-detail-value" md="12">
                    <dd>
                      {permissionUserEntity.view
                        ? translate('generadorApp.permissionUser.view.true')
                        : translate('generadorApp.permissionUser.view.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row permission-user-resgister-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top permission-user-resgister-detail-label" md="12">
                    <dt>
                      <span id="resgister">
                        <Translate contentKey="generadorApp.permissionUser.resgister">Register</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top permission-user-resgister-detail-value" md="12">
                    <dd>
                      {permissionUserEntity.resgister
                        ? translate('generadorApp.permissionUser.resgister.true')
                        : translate('generadorApp.permissionUser.resgister.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row permission-user-edit-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top permission-user-edit-detail-label" md="12">
                    <dt>
                      <span id="edit">
                        <Translate contentKey="generadorApp.permissionUser.edit">Edit</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top permission-user-edit-detail-value" md="12">
                    <dd>
                      {permissionUserEntity.edit
                        ? translate('generadorApp.permissionUser.edit.true')
                        : translate('generadorApp.permissionUser.edit.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row permission-user-canDelete-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top permission-user-canDelete-detail-label" md="12">
                    <dt>
                      <span id="canDelete">
                        <Translate contentKey="generadorApp.permissionUser.canDelete">Delete</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top permission-user-canDelete-detail-value" md="12">
                    <dd>
                      {permissionUserEntity.canDelete
                        ? translate('generadorApp.permissionUser.canDelete.true')
                        : translate('generadorApp.permissionUser.canDelete.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row permission-user-manualRemoval-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top permission-user-manualRemoval-detail-label" md="12">
                    <dt>
                      <span id="manualRemoval">
                        <Translate contentKey="generadorApp.permissionUser.manualRemoval">Manual removal</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top permission-user-manualRemoval-detail-value" md="12">
                    <dd>
                      {permissionUserEntity.manualRemoval
                        ? translate('generadorApp.permissionUser.manualRemoval.true')
                        : translate('generadorApp.permissionUser.manualRemoval.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row permission-user-editLocation-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top permission-user-editLocation-detail-label" md="12">
                    <dt>
                      <span id="editLocation">
                        <Translate contentKey="generadorApp.permissionUser.editLocation">editLocation</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top permission-user-editLocation-detail-value" md="12">
                    <dd>
                      {permissionUserEntity.editLocation
                        ? translate('generadorApp.permissionUser.editLocation.true')
                        : translate('generadorApp.permissionUser.editLocation.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row permission-user-report-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top permission-user-report-detail-label" md="12">
                    <dt>
                      <span id="report">
                        <Translate contentKey="generadorApp.permissionUser.report">Report</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top permission-user-report-detail-value" md="12">
                    <dd>
                      {permissionUserEntity.report
                        ? translate('generadorApp.permissionUser.report.true')
                        : translate('generadorApp.permissionUser.report.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row permission-user-activateProfessional-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top permission-user-activateProfessional-detail-label" md="12">
                    <dt>
                      <span id="activateProfessional">
                        <Translate contentKey="generadorApp.permissionUser.activateProfessional">Activate professional</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top permission-user-activateProfessional-detail-value" md="12">
                    <dd>
                      {permissionUserEntity.activateProfessional
                        ? translate('generadorApp.permissionUser.activateProfessional.true')
                        : translate('generadorApp.permissionUser.activateProfessional.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row permission-user-deletePhoto-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top permission-user-deletePhoto-detail-label" md="12">
                    <dt>
                      <span id="deletePhoto">
                        <Translate contentKey="generadorApp.permissionUser.deletePhoto">Delete photo</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top permission-user-deletePhoto-detail-value" md="12">
                    <dd>
                      {permissionUserEntity.deletePhoto
                        ? translate('generadorApp.permissionUser.deletePhoto.true')
                        : translate('generadorApp.permissionUser.deletePhoto.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row permission-user-financialValue-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top permission-user-financialValue-detail-label" md="12">
                    <dt>
                      <span id="financialValue">
                        <Translate contentKey="generadorApp.permissionUser.financialValue">Values</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top permission-user-financialValue-detail-value" md="12">
                    <dd>
                      {permissionUserEntity.financialValue
                        ? translate('generadorApp.permissionUser.financialValue.true')
                        : translate('generadorApp.permissionUser.financialValue.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row permission-user-valueAuthorization-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top permission-user-valueAuthorization-detail-label" md="12">
                    <dt>
                      <span id="valueAuthorization">
                        <Translate contentKey="generadorApp.permissionUser.valueAuthorization">Value authorization</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top permission-user-valueAuthorization-detail-value" md="12">
                    <dd>
                      {permissionUserEntity.valueAuthorization
                        ? translate('generadorApp.permissionUser.valueAuthorization.true')
                        : translate('generadorApp.permissionUser.valueAuthorization.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row permission-user-confirmPayment-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top permission-user-confirmPayment-detail-label" md="12">
                    <dt>
                      <span id="confirmPayment">
                        <Translate contentKey="generadorApp.permissionUser.confirmPayment">confirmPayment</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top permission-user-confirmPayment-detail-value" md="12">
                    <dd>
                      {permissionUserEntity.confirmPayment
                        ? translate('generadorApp.permissionUser.confirmPayment.true')
                        : translate('generadorApp.permissionUser.confirmPayment.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row permission-user-evolution-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top permission-user-evolution-detail-label" md="12">
                    <dt>
                      <span id="evolution">
                        <Translate contentKey="generadorApp.permissionUser.evolution">Evolution</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top permission-user-evolution-detail-value" md="12">
                    <dd>
                      {permissionUserEntity.evolution
                        ? translate('generadorApp.permissionUser.evolution.true')
                        : translate('generadorApp.permissionUser.evolution.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row permission-user-professionalName-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top permission-user-professionalName-detail-label" md="12">
                    <dt>
                      <span id="professionalName">
                        <Translate contentKey="generadorApp.permissionUser.professionalName">Professional name</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top permission-user-professionalName-detail-value" md="12">
                    <dd>
                      {permissionUserEntity.professionalName
                        ? translate('generadorApp.permissionUser.professionalName.true')
                        : translate('generadorApp.permissionUser.professionalName.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row permission-user-professionalRegister-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top permission-user-professionalRegister-detail-label" md="12">
                    <dt>
                      <span id="professionalRegister">
                        <Translate contentKey="generadorApp.permissionUser.professionalRegister">Professional register</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top permission-user-professionalRegister-detail-value" md="12">
                    <dd>
                      {permissionUserEntity.professionalRegister
                        ? translate('generadorApp.permissionUser.professionalRegister.true')
                        : translate('generadorApp.permissionUser.professionalRegister.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { permissionUserEntity } = this.props;
    const state = this.state;
    return (
      <div>
        <Panel className="permission-user permission-user-detail">
          <PanelHeader>{this.renderHeader()}</PanelHeader>

          <PanelBody>{this.renderBody()}</PanelBody>
        </Panel>
      </div>
    );
  }
}

export const mapStateToProps = ({ permissionUser, ...storeState }: IRootState) => ({
  permissionUserEntity: permissionUser.entity,
});

export const mapDispatchToProps = {
  getEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PermissionUserDetail);
