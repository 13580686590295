/* eslint complexity: ["error", 500] */
import axios from 'axios';

import { IPaginationBaseState, IPayloadResult } from 'react-jhipster';

import { IPayload } from 'react-jhipster/src/type/redux-action.type';

import { BASE_API_VERSION_PATH } from 'app/config/constants';

import { cleanEntity, jsonParse } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IPush, defaultValue } from 'app/shared/model/push.model';

export declare type ICrudPutAction<T> = (data?: T, listFiltersPage?) => IPayload<T> | IPayloadResult<T>;
export declare type ICrudGetAction<T> = (id: string | number, selectFields?: string) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const ACTION_TYPES = {
  FETCH_PUSH_LIST_EXPORT: 'push/FETCH_PUSH_LIST_EXPORT',
  FETCH_PUSH_LIST: 'push/FETCH_PUSH_LIST',
  FETCH_PUSH: 'push/FETCH_PUSH',
  FETCH_PUSH_OFFSET: 'push/FETCH_PUSH_OFFSET',
  CREATE_PUSH: 'push/CREATE_PUSH',
  UPDATE_PUSH: 'push/UPDATE_PUSH',
  CREATE_PUSH_AND_GO_LIST: 'push/CREATE_PUSH_AND_GO_LIST',
  UPDATE_PUSH_AND_GO_LIST: 'push/UPDATE_PUSH_AND_GO_LIST',
  DELETE_PUSH: 'push/DELETE_PUSH',
  SET_BLOB: 'push/SET_BLOB',
  RESET: 'push/RESET',
  SHOW_MODAL: 'push/SHOW_MODAL',
};

const initialState = {
  loading: false,
  openModal: {
    list: false,
    view: false,
    detail: false,
  },
  errorMessage: null,
  entities: [] as ReadonlyArray<IPush>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const actionPendind = [];
let actualState = initialState;

export const convertEntityBuffers = entity => ({
  ...entity,
  message: entity.message ? Buffer.from(entity.message).toString() : entity.message,
});

export type PushState = Readonly<typeof initialState>;

export interface IPushBaseState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  listCheckedID?: Array<number>;
  isCheckedAll?: boolean;
  urlBack?: string;
  extraFilters?: any;
  baseFilters: any;
  offset: number;
  type?: any;
  message?: any;
  patientId?: any;
  patientStartFilter?: any;
  clientId?: any;
  clientStartFilter?: any;
  professionalId?: any;
  professionalStartFilter?: any;
}

export interface IFieldsBase extends IPushBaseState, IPaginationBaseState {}
export interface IPushUpdateState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  urlBack?: string;
  functionEmbebed?: any[];
  fieldsBase: IFieldsBase;
  typeSelectValue?: any;
  typeStartSelectOptions?: any;
  messageSelectValue?: any;
  messageStartSelectOptions?: any;

  clientSelectValue?: any;
  clientStartSelectOptions?: any;

  patientSelectValue?: any;
  patientStartSelectOptions?: any;

  professionalSelectValue?: any;
  professionalStartSelectOptions?: any;

  categorySelectValue?: any;
  categoryStartSelectOptions?: any;

  specialtySelectValue?: any;
  specialtyStartSelectOptions?: any;

  isNew: boolean;
  clientFantasyName?: any;

  clientId?: any;
  patientName?: any;

  patientId?: any;
  professionalName?: any;

  professionalId?: any;
  categoryName?: any;

  categoryId?: any;
  specialtyName?: any;

  specialtyId?: any;
}

// Reducer

export default (state: PushState = initialState, action): PushState => {
  actualState = state;
  if (
    action.type.includes('_PENDING') &&
    !actionPendind.includes(action.type.replace('_PENDING', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_PENDING', ''))
  ) {
    actionPendind.push(action.type.replace('_PENDING', ''));
  }

  if (
    action.type.includes('_FULFILLED') &&
    actionPendind.includes(action.type.replace('_FULFILLED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_FULFILLED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_FULFILLED', '')), 1);
  }

  if (
    action.type.includes('_REJECTED') &&
    actionPendind.includes(action.type.replace('_REJECTED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_REJECTED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_REJECTED', '')), 1);
  }

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PUSH_LIST_EXPORT):
    case REQUEST(ACTION_TYPES.FETCH_PUSH_LIST):
    case REQUEST(ACTION_TYPES.FETCH_PUSH):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_PUSH):
    case REQUEST(ACTION_TYPES.UPDATE_PUSH):
    case REQUEST(ACTION_TYPES.CREATE_PUSH_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.UPDATE_PUSH_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.DELETE_PUSH):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_PUSH_LIST_EXPORT):
    case FAILURE(ACTION_TYPES.FETCH_PUSH_LIST):
    case FAILURE(ACTION_TYPES.FETCH_PUSH):
    case FAILURE(ACTION_TYPES.CREATE_PUSH):
    case FAILURE(ACTION_TYPES.UPDATE_PUSH):
    case FAILURE(ACTION_TYPES.CREATE_PUSH_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.UPDATE_PUSH_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.DELETE_PUSH):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_PUSH_LIST):
      action.payload.data = action.payload.data.map(v => {
        return { ...v, message: v.message ? Buffer.from(v.message).toString() : v.message };
      });

      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_PUSH_OFFSET):
      if (action.payload.data && action.payload.data.length > 0) {
        return {
          ...state,
          loading: false,
          entity: convertEntityBuffers(action.payload.data[0]),
        };
      }
      return {
        ...state,
        loading: false,
      };

    case SUCCESS(ACTION_TYPES.FETCH_PUSH):
      return {
        ...state,
        loading: false,
        entity: convertEntityBuffers(action.payload.data),
      };
    case SUCCESS(ACTION_TYPES.CREATE_PUSH):
    case SUCCESS(ACTION_TYPES.UPDATE_PUSH):
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_PUSH_AND_GO_LIST):
    case SUCCESS(ACTION_TYPES.UPDATE_PUSH_AND_GO_LIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_PUSH):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.SET_BLOB: {
      const { name, data, contentType, fileName } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [name + 'Base64']: data,
          [name + 'ContentType']: contentType,
          [name + 'FileName']: fileName,
        },
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };

    case ACTION_TYPES.SHOW_MODAL: {
      const openModal = state.openModal;
      openModal[action.payload.type] = action.payload.show;
      return {
        ...state,
        openModal,
      };
    }
    default:
      return state;
  }
};

const apiUrl = BASE_API_VERSION_PATH + 'pushes';

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

// Actions

// Actions
export type ICrudGetAllActionPush<T> = (
  type?: any,
  message?: any,
  patientId?: any,
  clientId?: any,
  professionalId?: any,
  extraFilters?: {},
  page?: number,
  size?: number,
  sort?: string,
  selectFields?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getEntitiesFilter = (filters?, page?, size?, sort?, selectFields = '') => {
  const requestUrl = `${apiUrl}?page=${page >= 0 ? page : 0}&size=${size >= 0 ? size : 20}&sort=${sort ? sort : 'id,asc'}&`;
  const requestFilters = filters.map(v => v.column + '.' + v.operation + '=' + v.value).join('&');
  return {
    type: ACTION_TYPES.FETCH_PUSH_LIST,
    payload: axios.get<IPush>(`${requestUrl}${requestFilters}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getAllEntities = (filters?, sort?, selectFields = '') => {
  if (
    (actualState.totalItems > 0 && actualState.entities.length === actualState.totalItems) ||
    actionPendind.includes(ACTION_TYPES.FETCH_PUSH_LIST)
  ) {
    return {
      type: ACTION_TYPES.FETCH_PUSH_LIST + '_FROM_CACHE',
      payload: new Promise(function (resolve, reject) {
        resolve('done');
      }),
    };
  }

  const idsRequest = filters && typeof filters['ids'] !== 'undefined' && filters['ids'] ? `id.in=${filters['ids'].join(',')}&` : '';
  const typeRequest = filters && typeof filters['type'] !== 'undefined' && filters['type'] ? `type.contains=${filters['type']}&` : '';
  const messageRequest =
    filters && typeof filters['message'] !== 'undefined' && filters['message'] ? `message.contains=${filters['message']}&` : '';
  const patientRequest =
    filters && typeof filters['patientId'] !== 'undefined' && filters['patientId'] ? `patient.id.in=${filters['patientId']}&` : '';
  const clientRequest =
    filters && typeof filters['clientId'] !== 'undefined' && filters['clientId'] ? `client.id.in=${filters['clientId']}&` : '';
  const professionalRequest =
    filters && typeof filters['professionalId'] !== 'undefined' && filters['professionalId']
      ? `professional.id.in=${filters['professionalId']}&`
      : '';

  const requestUrl = `${apiUrl}${`?page=${0}&size=${1000000}&sort=${sort ? sort : 'id,asc'}&`}`;
  return {
    type: ACTION_TYPES.FETCH_PUSH_LIST,
    payload: axios.get<IPush>(
      `${requestUrl}${idsRequest}${typeRequest}${messageRequest}${patientRequest}${clientRequest}${professionalRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntities: ICrudGetAllActionPush<IPush> = (
  type,
  message,
  patientId,
  clientId,
  professionalId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const typeRequest = type ? (Array.isArray(status) ? `type.in=${type.join(',')}&` : `type.equals=${type}&`) : '';
  const messageRequest = message ? `message.contains=${message}&` : '';
  const patientRequest = patientId ? `patient.id.in=${patientId}&` : '';
  const clientRequest = clientId ? `client.id.in=${clientId}&` : '';
  const professionalRequest = professionalId ? `professional.id.in=${professionalId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PUSH_LIST,
    payload: axios.get<IPush>(
      `${requestUrl}${extraFiltersRequest}${typeRequest}${messageRequest}${patientRequest}${clientRequest}${professionalRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntityOffset: any = (type, message, patientId, clientId, professionalId, extraFilters, offset, sort, selectFields = '') => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const typeRequest = type ? (Array.isArray(status) ? `type.in=${type.join(',')}&` : `type.equals=${type}&`) : '';
  const messageRequest = message ? `message.contains=${message}&` : '';
  const patientRequest = patientId ? `patient.id.in=${patientId}&` : '';
  const clientRequest = clientId ? `client.id.in=${clientId}&` : '';
  const professionalRequest = professionalId ? `professional.id.in=${professionalId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${offset}&size=${1}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PUSH_OFFSET,
    payload: axios.get<IPush>(
      `${requestUrl}${extraFiltersRequest}${typeRequest}${messageRequest}${patientRequest}${clientRequest}${professionalRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntity: ICrudGetAction<IPush> = (id, selectFields = '') => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_PUSH,
    payload: axios.get<IPush>(requestUrl, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntitiesExport: ICrudGetAllActionPush<IPush> = (
  type,
  message,
  patientId,
  clientId,
  professionalId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const typeRequest = type ? (Array.isArray(status) ? `type.in=${type.join(',')}&` : `type.equals=${type}&`) : '';
  const messageRequest = message ? (Array.isArray(status) ? `message.in=${message.join(',')}&` : `message.contains=${message}&`) : '';
  const patientRequest = patientId ? `patient.id.in=${patientId}&` : '';
  const clientRequest = clientId ? `client.id.in=${clientId}&` : '';
  const professionalRequest = professionalId ? `professional.id.in=${professionalId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PUSH_LIST,
    payload: axios.get<IPush>(
      `${requestUrl}${extraFiltersRequest}${typeRequest}${messageRequest}${patientRequest}${clientRequest}${professionalRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const createEntity: ICrudPutAction<IPush> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type: listFiltersPage && listFiltersPage['reloadList'] !== false ? ACTION_TYPES.CREATE_PUSH_AND_GO_LIST : ACTION_TYPES.CREATE_PUSH,
    payload: axios.post(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const updateEntity: ICrudPutAction<IPush> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type: listFiltersPage && listFiltersPage['reloadList'] !== false ? ACTION_TYPES.UPDATE_PUSH_AND_GO_LIST : ACTION_TYPES.UPDATE_PUSH,
    payload: axios.put(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const deleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_PUSH,
    payload: axios.delete(requestUrl),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const setBlob = (name, data, contentType?, fileName?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType,
    fileName,
  },
});

export const showModal = view => ({
  payload: { type: view, show: true },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const hideModal = view => ({
  payload: { type: view, show: false },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const getUrlBack = (location): string => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  let urlBack = _urlBase.searchParams.get('urlBack') || '';
  urlBack = urlBack.trim();
  urlBack = urlBack.startsWith('/') ? urlBack.slice(1) : urlBack;
  urlBack = urlBack.endsWith('/') ? urlBack.slice(0, -1) : urlBack;
  return urlBack ? '/' + urlBack + '/' : '';
};

export const getPushState = (location): IPushBaseState => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  const extraFilters = jsonParse(_urlBase.searchParams.get('extraFilters') || '{}');
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;

  const type = _urlBase.searchParams.get('type') || '';
  const message = _urlBase.searchParams.get('message') || '';
  const patientId = _urlBase.searchParams.get('patient') || '';
  const clientId = _urlBase.searchParams.get('client') || '';
  const professionalId = _urlBase.searchParams.get('professional') || '';
  return {
    baseFilters,
    extraFilters,
    offset,
    type,
    message,
    patientId: patientId ? patientId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
    clientId: clientId ? clientId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
    professionalId: professionalId ? professionalId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
  };
};

export const getEntityFiltersURL = (state, offset = null) => {
  return (
    'baseFilters=' +
    state.baseFilters +
    '&extraFilters=' +
    encodeURI(JSON.stringify(state.extraFilters)) +
    '&page=' +
    state.activePage +
    '&' +
    'size=' +
    state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    state.sort +
    ',' +
    state.order +
    '&' +
    (state.type ? 'type=' + state.type + '&' : '') +
    (state.message ? 'message=' + state.message + '&' : '') +
    (state.patientId ? 'patient=' + state.patientId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.clientId ? 'client=' + state.clientId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.professionalId ? 'professional=' + state.professionalId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    ''
  );
};
