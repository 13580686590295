import React from 'react';

import { IClComRuler } from 'app/shared/model/cl-com-ruler.model';
import { Sub } from 'app/shared/model/enumerations/sub.model';
import { Translate } from 'app/config/translate-component';

export interface IClComRulerProps {
  baseState?: any;
  baseProps?: any;
  setState?: any;
  entityBase?: IClComRuler;
}

const ClComRulerComponentCustomize = (props: IClComRulerProps) => {
  if (props.entityBase && props.entityBase.levelGroup) {
    if (props.entityBase.productServices && props.entityBase.levelGroup.sub === Sub.PRODUTOSESERVICOS) {
      return (
        <>
          {' '}
          {props.entityBase.productServices.map((val, j) => (
            <span key={j}>
              {val.name}
              {j === props.entityBase.productServices.length - 1 ? '' : <>, </>}
            </span>
          ))}{' '}
        </>
      );
    } else if (props.entityBase.specialties && props.entityBase.levelGroup.sub === Sub.ESPECIALIDADE) {
      return (
        <>
          {' '}
          {props.entityBase.specialties.map((val, j) => (
            <span key={j}>
              {val.name}
              {j === props.entityBase.specialties.length - 1 ? '' : <>, </>}
            </span>
          ))}{' '}
        </>
      );
    } else if (props.entityBase.table && props.entityBase.levelGroup && props.entityBase.levelGroup.sub === Sub.MATERIALMEDICAMENTOEDIETA) {
      return <Translate contentKey={`generadorApp.EnumTable.${props.entityBase.table}`} />;
    }
  }
  return <> </>;
};

export default ClComRulerComponentCustomize;
