/* eslint complexity: ["error", 500] */
import React from 'react';

import { connect } from 'react-redux';

import Select from 'react-select';
import { toast } from 'react-toastify';

import { Link, RouteComponentProps } from 'react-router-dom';

import { Button, Col, Row, Table, Label, UncontrolledCollapse, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { JhiItemCount } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { IRootState } from 'app/shared/reducers';
import {
  updateEntity,
  getUrlBack,
  getLevelGroupState,
  ILevelGroupBaseState,
  getEntityFiltersURL,
  getEntities,
  ILevelGroupUpdateState as IBaseUpdateState,
  reset,
  getEntity,
  deleteEntity,
  createEntity,
} from './level-group.reducer';

import { APP_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE, IPaginationBaseState, getSortState } from 'app/shared/util/pagination.constants';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';
import moment from 'moment';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

export interface ILevelGroupProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
  entityEmbebedPai?: any;
}

export interface ILevelGroupState extends ILevelGroupBaseState, IPaginationBaseState, IBaseUpdateState {
  showModalForm?: boolean;
  showModalEdit?: number;
  showModalEditView?: boolean;
}

export class LevelGroup extends React.Component<ILevelGroupProps, ILevelGroupState> {
  protected myFormRef: any;
  protected myFormRefNew: any;
  protected myFormRefEdit: any;

  nameFileInput: any;

  chargeTypeFileInput: any;

  despesaNumberFileInput: any;

  subFileInput: any;

  statusFileInput: any;

  constructor(props: ILevelGroupProps) {
    super(props);
    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      listCheckedID: [],
      isCheckedAll: false,
      showModalForm: false,
      showModalEdit: null,
      urlBack: getUrlBack(this.props.location),
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      ...getLevelGroupState(this.props.location),
      levelGroupStatusHistorySelectValue: null,

      categorySelectValue: null,

      clComRulerSelectValue: null,

      clComItemSelectValue: null,

      professionalSelectValue: null,

      doseSelectValue: null,

      brandSelectValue: null,

      medicineMaterialSelectValue: null,

      presentationSelectValue: null,

      suppliersSelectValue: null,

      nameSelectValue: null,
      chargeTypeSelectValue: null,
      despesaNumberSelectValue: null,
      subSelectValue: null,
      statusSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getLevelGroupState(this.props.location),
      },
      idssuppliers: [],
      isNew: false,
    };
  }

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({ listCheckedID: this.props.levelGroupList.map(levelGroup => levelGroup.id) });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({ listCheckedID: this.props.levelGroupList.map(levelGroup => levelGroup.id) });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  removeEntitySelected() {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  }

  saveEntityMany = () => {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  };

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  cancelFilters = () => {
    this.setState(
      {
        name: '',
        chargeType: '',
        sub: '',
        status: '',
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
        activePage: 0,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();

    const selectFieldsList = ['name', 'sub', 'createdDate', 'lastModifiedDate', 'status'];
    const { name, chargeType, sub, status, extraFilters, activePage, itemsPerPage, sort, order } = this.state;
    return this.props.getEntities(
      name,
      chargeType,
      sub,
      status,
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(',')
    );
  };

  updateEntity = entity => {
    const selectFieldsList = ['name', 'sub', 'createdDate', 'lastModifiedDate', 'status'];

    const { name, chargeType, sub, status, extraFilters, activePage, itemsPerPage, sort, order } = this.state;
    this.props.updateEntity(entity, [
      name,
      chargeType,
      sub,
      status,
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(','),
    ]);
  };

  saveEntity = (event: any, errors: any, values: any, isNew: any) => {
    if (!this.state.nameSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.levelGroup.name') }));
      return false;
    }

    const selectFieldsList = ['name', 'sub', 'createdDate', 'lastModifiedDate', 'status'];

    if (errors.length === 0) {
      const { levelGroupEntity } = this.props;
      const _entity = {
        ...levelGroupEntity,
        // ...values,

        name: this.state.nameSelectValue,
        chargeType: this.state.chargeTypeSelectValue === null ? 'DESPESA' : this.state.chargeTypeSelectValue,
        despesaNumber: this.state.despesaNumberSelectValue,
        sub: this.state.subSelectValue === null ? 'ESPECIALIDADE' : this.state.subSelectValue,
        status: this.state.statusSelectValue === null ? 'INATIVADO' : this.state.statusSelectValue,
      };
      const entity = _entity;

      const { name, chargeType, sub, status, extraFilters, activePage, itemsPerPage, sort, order } = this.state.fieldsBase;

      if (isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              name,
              chargeType,
              sub,
              status,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              name,
              chargeType,
              sub,
              status,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
      this.setState({ showModalForm: false });
      this.setState({ showModalEdit: null });
    } else {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.specialty.' + errors[0]) }));
    }
  };

  componentDidUpdate(prevProps) {}

  deleteEntity(levelGroupEntity) {
    MySwal.fire({
      title: <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>,
      html: (
        <Translate contentKey="generadorApp.levelGroup.delete.question" interpolate={{ id: levelGroupEntity.id }}>
          Are you sure you want to delete this LevelGroup?
        </Translate>
      ),
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#e70015',
      cancelButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="ban" />
          &nbsp; <Translate contentKey="generadorApp.levelGroup.btnCancel">Cancel</Translate>
        </>
      ),
      confirmButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="trash" />
          &nbsp;<Translate contentKey="generadorApp.levelGroup.btnDelete">Delete</Translate>
        </>
      ),
    }).then(async response => {
      if (response.isConfirmed) {
        await this.props.deleteEntity(levelGroupEntity.id, { reloadList: false });
        this.getEntities();
        MySwal.fire({
          title: <Translate contentKey="entity.delete.title.confirmed">Confirmed delete operation</Translate>,
          icon: 'success',
        });
      }
    });
  }

  renderNewModal() {
    return (
      <Modal size={'xl'} isOpen={this.state.showModalForm} toggle={() => this.setState({ showModalForm: false })}>
        <ModalHeader toggle={() => this.setState({ showModalForm: false })}>{this.renderHeaderUpdate({}, true)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefNew = el)}
          model={{}}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, true)}
        >
          <ModalBody>{this.renderBodyUpdate({}, true)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalForm: false })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.levelGroup.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefNew && this.myFormRefNew.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.levelGroup.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    );
  }

  getFullEntityFromState(v) {
    return {
      id: v.id,
      name: this.state.nameSelectValue,
      chargeType: this.state.chargeTypeSelectValue,
      despesaNumber: this.state.despesaNumberSelectValue,
      sub: this.state.subSelectValue,
      status: this.state.statusSelectValue,
      levelGroupStatusHistory: this.state.levelGroupStatusHistorySelectValue,
      category: this.state.categorySelectValue,
      clComRuler: this.state.clComRulerSelectValue,
      clComItem: this.state.clComItemSelectValue,
      professional: this.state.professionalSelectValue,
      dose: this.state.doseSelectValue,
      brand: this.state.brandSelectValue,
      medicineMaterial: this.state.medicineMaterialSelectValue,
      presentation: this.state.presentationSelectValue,
      suppliers: this.state.suppliersSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    const p: any = null;

    const levelGroupStatusHistoryEntity = v.levelGroupStatusHistory
      ? v.levelGroupStatusHistory.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' }))
      : v.levelGroupStatusHistory;

    const categoryEntity = v.category
      ? v.category.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' }))
      : v.category;

    const clComRulerEntity = v.clComRuler
      ? v.clComRuler.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' }))
      : v.clComRuler;

    const clComItemEntity = v.clComItem
      ? v.clComItem.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' }))
      : v.clComItem;

    const professionalEntity = v.professional
      ? v.professional.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' }))
      : v.professional;

    const doseEntity = v.dose ? v.dose.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' })) : v.dose;

    const brandEntity = v.brand ? v.brand.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' })) : v.brand;

    const medicineMaterialEntity = v.medicineMaterial
      ? v.medicineMaterial.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' }))
      : v.medicineMaterial;

    const presentationEntity = v.presentation
      ? v.presentation.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' }))
      : v.presentation;

    const suppliersEntity = v.suppliers
      ? v.suppliers.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['fantasyName'] ? p1.fantasyName : '' }))
      : v.suppliers;

    return {
      nameSelectValue: v.name, // name,
      chargeTypeSelectValue: v.chargeType, // chargeType,
      despesaNumberSelectValue: v.despesaNumber, // despesaNumber,
      subSelectValue: v.sub, // sub,
      statusSelectValue: v.status, // status,
      levelGroupStatusHistorySelectValue: levelGroupStatusHistoryEntity,
      categorySelectValue: categoryEntity,
      clComRulerSelectValue: clComRulerEntity,
      clComItemSelectValue: clComItemEntity,
      professionalSelectValue: professionalEntity,
      doseSelectValue: doseEntity,
      brandSelectValue: brandEntity,
      medicineMaterialSelectValue: medicineMaterialEntity,
      presentationSelectValue: presentationEntity,
      suppliersSelectValue: suppliersEntity,
    };
  }

  openUpdateModal(v, edit = true) {
    this.props.getEntity(v.id);
    this.setState({
      showModalEdit: v.id,
      showModalEditView: edit,
      ...this.getFullEntitySelectValue(v),
    });
  }

  renderUpdateModal() {
    const { levelGroupEntity, entityEmbebedPai } = this.props;
    return levelGroupEntity && levelGroupEntity.id ? (
      <Modal size={'xl'} isOpen={this.state.showModalEdit === levelGroupEntity.id} toggle={() => this.setState({ showModalEdit: null })}>
        <ModalHeader toggle={() => this.setState({ showModalEdit: null })}>{this.renderHeaderUpdate(levelGroupEntity, false)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefEdit = el)}
          model={{
            ...levelGroupEntity,
          }}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, false)}
        >
          <ModalBody>{this.renderBodyUpdate(levelGroupEntity, false)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalEdit: null })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.levelGroup.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefEdit && this.myFormRefEdit.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.levelGroup.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    ) : (
      <> </>
    );
  }

  renderHeaderUpdate(levelGroupEntity, isNew) {
    const {
      entityEmbebedPai,
      levelGroupStatusHistories,
      categories,
      clComRulers,
      clComItems,
      professionals,
      doses,
      brands,
      medicineMaterials,
      presentations,
      suppliers,
      loading,
    } = this.props;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {isNew ? (
              <Translate contentKey="generadorApp.levelGroup.home.createLabel">Create a LevelGroup</Translate>
            ) : (
              <Translate contentKey="generadorApp.levelGroup.home.editLabel">Edit a LevelGroup</Translate>
            )}
          </span>
        </div>
      </>
    );
  }
  renderBodyUpdate(levelGroupEntity, isNew) {
    const {
      entityEmbebedPai,
      levelGroupStatusHistories,
      categories,
      clComRulers,
      clComItems,
      professionals,
      doses,
      brands,
      medicineMaterials,
      presentations,
      suppliers,
      loading,
    } = this.props;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
              <Col md="3">
              <Label className="mt-2 label-single-line"for="level-group-id">
                <Translate contentKey="generadorApp.levelGroup.field_id">ID</Translate>
              </Label>
              </Col> */}
                      <Col md="12">
                        <AvInput id="level-group-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'name' ? (
                    <Col md="5">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="nameLabel" for="level-group-name">
                              <Translate contentKey="generadorApp.levelGroup.name">Name</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                              value={this.state.nameSelectValue ? this.state.nameSelectValue : ''}
                              id="level-group-name"
                              type="text"
                              name="name"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                      type="hidden"
                      name="name"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'chargeType' ? (
                    <Col md="4">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="chargeTypeLabel" for="level-group-chargeType">
                              <Translate contentKey="generadorApp.levelGroup.chargeType">Charge Type</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="level-group-chargeType"
                              className={'css-select-control'}
                              value={
                                this.state.chargeTypeSelectValue
                                  ? {
                                      value: this.state.chargeTypeSelectValue,
                                      label: translate('generadorApp.ChargeType.' + this.state.chargeTypeSelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.levelGroup.chargeType') }
                              }
                              options={[
                                { value: 'DESPESA', label: translate('generadorApp.ChargeType.DESPESA') },
                                { value: 'PROCEDIMENTO', label: translate('generadorApp.ChargeType.PROCEDIMENTO') },
                              ]}
                              onChange={(options: any) => this.setState({ chargeTypeSelectValue: options['value'] })}
                              name="chargeType"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ chargeTypeSelectValue: evt.target.value })}
                      type="hidden"
                      name="chargeType"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {this.state.chargeTypeSelectValue === 'DESPESA' && baseFilters !== 'despesaNumber' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="despesaNumberLabel" for="level-group-despesaNumber">
                              <Translate contentKey="generadorApp.levelGroup.despesaNumber">Despesa number</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ despesaNumberSelectValue: evt.target.value })}
                              value={this.state.despesaNumberSelectValue ? this.state.despesaNumberSelectValue : ''}
                              id="level-group-despesaNumber"
                              type="text"
                              name="despesaNumber"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ despesaNumberSelectValue: evt.target.value })}
                      type="hidden"
                      name="despesaNumber"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'sub' ? (
                    <Col md="4">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="subLabel" for="level-group-sub">
                              <Translate contentKey="generadorApp.levelGroup.sub">Sublevel</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="level-group-sub"
                              className={'css-select-control'}
                              value={
                                this.state.subSelectValue
                                  ? { value: this.state.subSelectValue, label: translate('generadorApp.Sub.' + this.state.subSelectValue) }
                                  : { value: '', label: translate('generadorApp.levelGroup.sub') }
                              }
                              options={[
                                { value: 'ESPECIALIDADE', label: translate('generadorApp.Sub.ESPECIALIDADE') },
                                { value: 'MATERIALMEDICAMENTOEDIETA', label: translate('generadorApp.Sub.MATERIALMEDICAMENTOEDIETA') },
                                { value: 'PRODUTOSESERVICOS', label: translate('generadorApp.Sub.PRODUTOSESERVICOS') },
                              ]}
                              onChange={(options: any) => this.setState({ subSelectValue: options['value'] })}
                              name="sub"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ subSelectValue: evt.target.value })}
                      type="hidden"
                      name="sub"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  renderHeader() {
    const {
      levelGroupStatusHistories,
      categories,
      clComRulers,
      clComItems,
      professionals,
      doses,
      brands,
      medicineMaterials,
      presentations,
      suppliers,
      levelGroupList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.levelGroup.home.title">Groups</Translate>
          </span>
          <Button id="togglerFilterLevelGroup" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.levelGroup.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          &nbsp;
          {hasAnyAuthority(props.userAccount, ['level-group'], 'resgister') ? (
            <Button
              onClick={() =>
                this.setState({
                  showModalForm: true,
                  nameSelectValue: null,
                  chargeTypeSelectValue: null,
                  despesaNumberSelectValue: null,
                  subSelectValue: null,
                  statusSelectValue: 'ATIVO',

                  levelGroupStatusHistoryStartSelectOptions: undefined,
                  levelGroupStatusHistorySelectValue: null,

                  categoryStartSelectOptions: undefined,
                  categorySelectValue: null,

                  clComRulerStartSelectOptions: undefined,
                  clComRulerSelectValue: null,

                  clComItemStartSelectOptions: undefined,
                  clComItemSelectValue: null,

                  professionalStartSelectOptions: undefined,
                  professionalSelectValue: null,

                  doseStartSelectOptions: undefined,
                  doseSelectValue: null,

                  brandStartSelectOptions: undefined,
                  brandSelectValue: null,

                  medicineMaterialStartSelectOptions: undefined,
                  medicineMaterialSelectValue: null,

                  presentationStartSelectOptions: undefined,
                  presentationSelectValue: null,

                  suppliersStartSelectOptions: undefined,
                  suppliersSelectValue: null,
                  isNew: true,
                })
              }
              color="primary"
              className="btn btn-primary float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="plus" />
              &nbsp;
              <Translate contentKey="generadorApp.levelGroup.btnNewItem">Create new</Translate>
            </Button>
          ) : (
            <></>
          )}{' '}
          &nbsp;
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.levelGroup.home.title">Groups</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    const {
      levelGroupStatusHistories,
      categories,
      clComRulers,
      clComItems,
      professionals,
      doses,
      brands,
      medicineMaterials,
      presentations,
      suppliers,
      levelGroupList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <UncontrolledCollapse toggler="#togglerFilterLevelGroup">
          <CardBody>
            <AvForm ref={el => (this.myFormRef = el)} id="form-filter" onSubmit={this.filterEntity}>
              <div className="row mt-1 ml-3 mr-3">
                {this.state.baseFilters !== 'name' ? (
                  <Col md="3" className="col-filter-level-group-name">
                    <Row className="mr-1 mt-1">
                      <Label id="nameLabel" for="level-group-name">
                        <Translate contentKey="generadorApp.levelGroup.name">Name</Translate>
                      </Label>

                      <AvInput type="text" name="name" id="level-group-name" value={this.state.name} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'chargeType' ? (
                  <Col md="3" className="col-filter-level-group-chargeType">
                    <Row className="mr-1 mt-1">
                      <Label id="chargeTypeLabel" for="level-group-chargeType">
                        <Translate contentKey="generadorApp.levelGroup.chargeType">Charge Type</Translate>
                      </Label>
                      <Select
                        id="level-group-chargeType"
                        className={'css-select-control'}
                        value={
                          this.state.chargeType
                            ? { value: this.state.chargeType, label: translate('generadorApp.ChargeType.' + this.state.chargeType) }
                            : { value: '', label: translate('generadorApp.levelGroup.chargeType') }
                        }
                        options={[
                          { value: '', label: translate('generadorApp.levelGroup.chargeType') },
                          { value: 'DESPESA', label: translate('generadorApp.ChargeType.DESPESA') },
                          { value: 'PROCEDIMENTO', label: translate('generadorApp.ChargeType.PROCEDIMENTO') },
                        ]}
                        onChange={(options: any) => this.setState({ chargeType: options['value'] })}
                        name="chargeType"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'sub' ? (
                  <Col md="3" className="col-filter-level-group-sub">
                    <Row className="mr-1 mt-1">
                      <Label id="subLabel" for="level-group-sub">
                        <Translate contentKey="generadorApp.levelGroup.sub">Sublevel</Translate>
                      </Label>
                      <Select
                        id="level-group-sub"
                        className={'css-select-control'}
                        value={
                          this.state.sub
                            ? { value: this.state.sub, label: translate('generadorApp.Sub.' + this.state.sub) }
                            : { value: '', label: translate('generadorApp.levelGroup.sub') }
                        }
                        options={[
                          { value: '', label: translate('generadorApp.levelGroup.sub') },
                          { value: 'ESPECIALIDADE', label: translate('generadorApp.Sub.ESPECIALIDADE') },
                          { value: 'MATERIALMEDICAMENTOEDIETA', label: translate('generadorApp.Sub.MATERIALMEDICAMENTOEDIETA') },
                          { value: 'PRODUTOSESERVICOS', label: translate('generadorApp.Sub.PRODUTOSESERVICOS') },
                        ]}
                        onChange={(options: any) => this.setState({ sub: options['value'] })}
                        name="sub"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'status' ? (
                  <Col md="3" className="col-filter-level-group-status">
                    <Row className="mr-1 mt-1">
                      <Label id="statusLabel" for="level-group-status">
                        <Translate contentKey="generadorApp.levelGroup.status">Status</Translate>
                      </Label>
                      <Select
                        id="level-group-status"
                        className={'css-select-control'}
                        value={
                          this.state.status
                            ? { value: this.state.status, label: translate('generadorApp.Status.' + this.state.status) }
                            : { value: '', label: translate('generadorApp.levelGroup.status') }
                        }
                        options={[
                          { value: '', label: translate('generadorApp.levelGroup.status') },
                          { value: 'INATIVADO', label: translate('generadorApp.Status.INATIVADO') },
                          { value: 'ATIVO', label: translate('generadorApp.Status.ATIVO') },
                        ]}
                        onChange={(options: any) => this.setState({ status: options['value'] })}
                        name="status"
                      />
                    </Row>
                  </Col>
                ) : null}
              </div>

              <div className="row mb-2 mr-4 justify-content-end">
                <Button color="primary" type="submit">
                  <i className="fa fa-filter" aria-hidden={'true'}></i>&nbsp;
                  <Translate contentKey="generadorApp.levelGroup.btnFilter">Filter</Translate>
                </Button>
                &nbsp;
                <Button color="secondary" onClick={this.cancelFilters}>
                  <FontAwesomeIcon icon="trash-alt" />
                  &nbsp;
                  <Translate contentKey="generadorApp.levelGroup.btnCleanFilter">Clean Filter</Translate>
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </UncontrolledCollapse>
      </>
    );
  }

  renderTable() {
    const {
      levelGroupStatusHistories,
      categories,
      clComRulers,
      clComItems,
      professionals,
      doses,
      brands,
      medicineMaterials,
      presentations,
      suppliers,
      levelGroupList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {levelGroupList && levelGroupList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null).length > 0 ? (
          <>
            <Table
              id="level-group-table-list"
              responsive
              aria-describedby="level-group-heading"
              className={'table-hover table-striped table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.levelGroup.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'name' ? (
                    <th className="hand" onClick={sortFunction('name')}>
                      <Translate contentKey="generadorApp.levelGroup.name">Name</Translate>
                      <FontAwesomeIcon icon={state.sort === 'name' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'sub' ? (
                    <th className="hand" onClick={sortFunction('sub')}>
                      <Translate contentKey="generadorApp.levelGroup.sub">Sublevel</Translate>
                      <FontAwesomeIcon icon={state.sort === 'sub' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'createdDate' ? (
                    <th className="hand" onClick={sortFunction('createdDate')}>
                      <Translate contentKey="generadorApp.levelGroup.createdDate">Created Date</Translate>
                      <FontAwesomeIcon icon={state.sort === 'createdDate' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'lastModifiedDate' ? (
                    <th className="hand" onClick={sortFunction('lastModifiedDate')}>
                      <Translate contentKey="generadorApp.levelGroup.lastModifiedDate">Last Modified Date</Translate>
                      <FontAwesomeIcon
                        icon={state.sort === 'lastModifiedDate' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'}
                      />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'status' ? (
                    <th className="hand" onClick={sortFunction('status')}>
                      <Translate contentKey="generadorApp.levelGroup.status">Status</Translate>
                      <FontAwesomeIcon icon={state.sort === 'status' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}

                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {levelGroupList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((levelGroup, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.levelGroup.field_id">ID</Translate>
                        </b>
                        {levelGroup.id}
                      </td>

                      {state.baseFilters !== 'name' ? (
                        <td id="name-cell">
                          <b className="visible-xs"> Name </b>

                          {levelGroup.name}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'sub' ? (
                        <td id="sub-cell">
                          <b className="visible-xs"> Sublevel </b>
                          {levelGroup.sub ? <Translate contentKey={`generadorApp.Sub.${levelGroup.sub}`} /> : <> </>}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'createdDate' ? (
                        <td id="createdDate-cell">
                          <b className="visible-xs"> Created Date </b>
                          {levelGroup.createdDate ? moment(levelGroup.createdDate).format(APP_DATE_FORMAT) : ''}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'lastModifiedDate' ? (
                        <td id="lastModifiedDate-cell">
                          <b className="visible-xs"> Last Modified Date </b>
                          {levelGroup.lastModifiedDate ? moment(levelGroup.lastModifiedDate).format(APP_DATE_FORMAT) : ''}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'status' ? (
                        <td id="status-cell">
                          <b className="visible-xs"> Status </b>
                          {levelGroup.status ? <Translate contentKey={`generadorApp.Status.${levelGroup.status}`} /> : <> </>}
                        </td>
                      ) : null}

                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          {hasAnyAuthority(props.userAccount, ['level-group'], 'edit') ? (
                            <Button onClick={() => this.openUpdateModal(levelGroup)} color="primary" size="sm">
                              <FontAwesomeIcon icon="pencil-alt" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                          {hasAnyAuthority(props.userAccount, ['level-group'], 'canDelete') ? (
                            <Button onClick={() => this.deleteEntity(levelGroup)} color="danger" size="sm">
                              <FontAwesomeIcon icon="trash" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                          {hasAnyAuthority(props.userAccount, ['level-group'], 'edit') ? (
                            <Button tag={Link} to={`/level-group/${levelGroup.id}/level-group-status-history`} color="info" size="sm">
                              <FontAwesomeIcon icon="check-square" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.levelGroup.home.notFound">No Groups found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const {
      levelGroupStatusHistories,
      categories,
      clComRulers,
      clComItems,
      professionals,
      doses,
      brands,
      medicineMaterials,
      presentations,
      suppliers,
      levelGroupList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={levelGroupList && levelGroupList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const {
      levelGroupStatusHistories,
      categories,
      clComRulers,
      clComItems,
      professionals,
      doses,
      brands,
      medicineMaterials,
      presentations,
      suppliers,
      levelGroupList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel className="level-group level-group-all-in-one">
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
            {this.renderNewModal()}
            {this.renderUpdateModal()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToProps = ({ levelGroup, ...storeState }: IRootState) => ({
  levelGroupStatusHistories: storeState.levelGroupStatusHistory.entities,
  categories: storeState.category.entities,
  clComRulers: storeState.clComRuler.entities,
  clComItems: storeState.clComItem.entities,
  professionals: storeState.professional.entities,
  doses: storeState.dose.entities,
  brands: storeState.brand.entities,
  medicineMaterials: storeState.medicineMaterial.entities,
  presentations: storeState.presentation.entities,
  suppliers: storeState.supplier.entities,
  levelGroupList: levelGroup.entities,
  totalItems: levelGroup.totalItems,
  userAccount: storeState.authentication.account,
  loading: levelGroup.loading,

  // UPDATE
  levelGroupEntity: levelGroup.entity,
});

export const mapDispatchToProps = {
  getEntities,

  updateEntity,
  reset,

  // UPDATE
  getEntity,
  createEntity,

  // DELETE
  deleteEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(LevelGroup);
