/* eslint-disable no-console */
import { IBillingXml } from 'app/shared/model/billing-xml.model';
import 'react-datepicker/dist/react-datepicker.css';
import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Translate } from 'app/config/translate-component';
const MySwal = withReactContent(Swal);

export interface IBillingXmlProps {
  baseState?: any;
  baseProps?: any;
  setState?: any;
  entityBase?: IBillingXml;
}

const BillingXmlComponentCustomize = (props: IBillingXmlProps) => {
  if (props.entityBase.observation) {
    if (props.entityBase.observation.length < 50) {
      return <>{props.entityBase.observation} </>;
    } else {
      return (
        <>
          {' '}
          {props.entityBase.observation.substring(0, 47)}...{' '}
          <span
            style={{ color: 'blue', cursor: 'pointer' }}
            onClick={() => {
              MySwal.fire({
                title: <Translate contentKey="generadorApp.billingXml.ObservationComponent">ObservationComponent</Translate>,
                html: props.entityBase.observation,
                width: '80%',
              });
            }}
          >
            Ver mais
          </span>
        </>
      );
    }
  }

  return <></>;
};

const mapStateToProps = storeState => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default BillingXmlComponentCustomize;
