import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { INfeFile } from 'app/shared/model/nfe-file.model';
import { IPharmacyStock } from 'app/shared/model/pharmacy-stock.model';
import { ISupplier } from 'app/shared/model/supplier.model';

export interface INfe {
  id?: number;
  number?: string;
  acessKey?: string;
  valueNfe?: number;
  buyDate?: Moment;
  paymentDate?: Moment;
  nfeFile?: INfeFile[];
  pharmacyStock?: IPharmacyStock[];
  supplier?: ISupplier;
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  nfeFile: {
    isMulti: false, // one-to-many - other-side
    entity: 'NfeFile',
    urlParameters: {
      enpoint: 'nfe-files',
      filters: 'nfe.id.equals',
    },
  },
  pharmacyStock: {
    isMulti: false, // one-to-many - other-side
    entity: 'PharmacyStock',
    urlParameters: {
      enpoint: 'pharmacy-stocks',
      filters: 'nfe.id.equals',
    },
  },
  supplier: {
    isMulti: true, // many-to-one - other-side
    entity: 'Supplier',
    urlParameters: {
      enpoint: 'suppliers',
      filters: 'nfe.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'nfe.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'nfe.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<INfe> = {};
