import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';
import moment from 'moment';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import {
  getEntity,
  IPatDrPrescrProcdBaseState,
  getUrlBack,
  getPatDrPrescrProcdState,
  getEntityFiltersURL,
} from './pat-dr-prescr-procd.reducer';

import { APP_DATE_FORMAT } from 'app/config/constants';

export interface IPatDrPrescrProcdState {
  fieldsBase: IPatDrPrescrProcdBaseState;
}

export interface IPatDrPrescrProcdDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class PatDrPrescrProcdDetail extends React.Component<IPatDrPrescrProcdDetailProps, IPatDrPrescrProcdState> {
  constructor(props: Readonly<IPatDrPrescrProcdDetailProps>) {
    super(props);
    this.state = {
      ...this.state,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getPatDrPrescrProcdState(this.props.location),
        urlBack: getUrlBack(this.props.location),
        patientDoctorPrescriptionId: [
          { value: this.props.match.params['idPatientDoctorPrescription'], label: this.props.match.params['idPatientDoctorPrescription'] },
        ],
        baseFilters: 'patientDoctorPrescription',
      },
    };
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = '_back=1' + (offset !== null ? '&offset=' + offset : '');
    Object.keys(fieldsBase).map(key => {
      url += '&' + key + '=' + fieldsBase[key];
    });
    return url;
  };

  UNSAFE_componentWillMount() {
    this.props.getEntity(this.props.match.params['id']);
  }

  renderHeader() {
    const { patDrPrescrProcdEntity } = this.props;
    const state = this.state;
    return (
      <>
        <div id="page-heading">
          {' '}
          <h2>
            <Translate contentKey="generadorApp.patDrPrescrProcd.detail.title">PatDrPrescrProcd</Translate>
            <small>&nbsp; {patDrPrescrProcdEntity['id']}</small>
          </h2>
        </div>

        <div className="pull-right">
          <Button
            tag={Link}
            to={
              '/patientDoctorPrescription/' +
              this.props.match.params['idPatientDoctorPrescription'] +
              '/pat-dr-prescr-procd?' +
              getEntityFiltersURL(this.state.fieldsBase)
            }
            replace
            color="info"
          >
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.patDrPrescrProcd.btnBack">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/patientDoctorPrescription/${this.props.match.params['idPatientDoctorPrescription']}/pat-dr-prescr-procd/${
              patDrPrescrProcdEntity.id
            }/edit?+${getEntityFiltersURL(this.state.fieldsBase)}`}
            replace
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.patDrPrescrProcd.btnEdit">Edit</Translate>
            </span>
          </Button>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>

          <li className="breadcrumb-item">
            <Link to={'/patientDoctorPrescription'}>Patient Doctor Prescriptions</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/patientDoctorPrescription/' + this.props.match.params['idPatientDoctorPrescription']}>
              {this.props.match.params['idPatientDoctorPrescription']}
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link
              to={
                '/patientDoctorPrescription/' +
                (this.state.fieldsBase.patientDoctorPrescriptionId ? this.state.fieldsBase.patientDoctorPrescriptionId.label : '') +
                '/pat-dr-prescr-procd'
              }
            >
              Pat Dr Prescr Procds
            </Link>
          </li>
          <li className="breadcrumb-item active">Pat Dr Prescr Procds details</li>
        </ol>
      </>
    );
  }

  renderBody() {
    const { patDrPrescrProcdEntity } = this.props;
    const state = this.state;
    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            <Row className="jh-entity-details">
              <Col className="view-detail-row pat-dr-prescr-procd-order-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top pat-dr-prescr-procd-order-detail-label" md="12">
                    <dt>
                      <span id="order">
                        <Translate contentKey="generadorApp.patDrPrescrProcd.order">Order</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pat-dr-prescr-procd-order-detail-value" md="12">
                    <dd>{patDrPrescrProcdEntity.order}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pat-dr-prescr-procd-otherMedicine-detail" md="2">
                <Row>
                  <Col className="view-detail-label-top pat-dr-prescr-procd-otherMedicine-detail-label" md="12">
                    <dt>
                      <span id="otherMedicine">
                        <Translate contentKey="generadorApp.patDrPrescrProcd.otherMedicine">Other Medicine</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pat-dr-prescr-procd-otherMedicine-detail-value" md="12">
                    <dd>
                      {patDrPrescrProcdEntity.otherMedicine
                        ? translate('generadorApp.patDrPrescrProcd.otherMedicine.true')
                        : translate('generadorApp.patDrPrescrProcd.otherMedicine.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pat-dr-prescr-procd-medicineMaterial-detail" md="4">
                <Row>
                  <Col className="view-detail-label-top pat-dr-prescr-procd-medicineMaterial-detail-label" md="12">
                    <dt>
                      <Translate contentKey="generadorApp.patDrPrescrProcd.medicineMaterial">Medicine Material</Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pat-dr-prescr-procd-medicineMaterial-detail-value" md="12">
                    <dd>{patDrPrescrProcdEntity.medicineMaterial ? patDrPrescrProcdEntity.medicineMaterial.id : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pat-dr-prescr-procd-medicineName-detail" md="4">
                <Row>
                  <Col className="view-detail-label-top pat-dr-prescr-procd-medicineName-detail-label" md="12">
                    <dt>
                      <span id="medicineName">
                        <Translate contentKey="generadorApp.patDrPrescrProcd.medicineName">Medicine name</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pat-dr-prescr-procd-medicineName-detail-value" md="12">
                    <dd>{patDrPrescrProcdEntity.medicineName}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pat-dr-prescr-procd-dose-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top pat-dr-prescr-procd-dose-detail-label" md="12">
                    <dt>
                      <span id="dose">
                        <Translate contentKey="generadorApp.patDrPrescrProcd.dose">Dose</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pat-dr-prescr-procd-dose-detail-value" md="12">
                    <dd>{patDrPrescrProcdEntity.dose}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pat-dr-prescr-procd-via-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top pat-dr-prescr-procd-via-detail-label" md="12">
                    <dt>
                      <span id="via">
                        <Translate contentKey="generadorApp.patDrPrescrProcd.via">Via</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pat-dr-prescr-procd-via-detail-value" md="12">
                    <dd>{translate('generadorApp.Via.' + (patDrPrescrProcdEntity.via ? patDrPrescrProcdEntity.via : 'INJECAO'))}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pat-dr-prescr-procd-frequency-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top pat-dr-prescr-procd-frequency-detail-label" md="12">
                    <dt>
                      <span id="frequency">
                        <Translate contentKey="generadorApp.patDrPrescrProcd.frequency">Frequency</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pat-dr-prescr-procd-frequency-detail-value" md="12">
                    <dd>
                      {translate(
                        'generadorApp.QuoteFrequency.' + (patDrPrescrProcdEntity.frequency ? patDrPrescrProcdEntity.frequency : 'UMXSEMANA')
                      )}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pat-dr-prescr-procd-continuous-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top pat-dr-prescr-procd-continuous-detail-label" md="12">
                    <dt>
                      <span id="continuous">
                        <Translate contentKey="generadorApp.patDrPrescrProcd.continuous">Continuous</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pat-dr-prescr-procd-continuous-detail-value" md="12">
                    <dd>
                      {patDrPrescrProcdEntity.continuous
                        ? translate('generadorApp.patDrPrescrProcd.continuous.true')
                        : translate('generadorApp.patDrPrescrProcd.continuous.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pat-dr-prescr-procd-initialDate-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top pat-dr-prescr-procd-initialDate-detail-label" md="12">
                    <dt>
                      <span id="initialDate">
                        <Translate contentKey="generadorApp.patDrPrescrProcd.initialDate">Initial Date</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pat-dr-prescr-procd-initialDate-detail-value" md="12">
                    <dd>{patDrPrescrProcdEntity.initialDate ? moment(patDrPrescrProcdEntity.initialDate).format(APP_DATE_FORMAT) : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pat-dr-prescr-procd-finalDate-detail" md="6">
                <Row>
                  <Col className="view-detail-label-top pat-dr-prescr-procd-finalDate-detail-label" md="12">
                    <dt>
                      <span id="finalDate">
                        <Translate contentKey="generadorApp.patDrPrescrProcd.finalDate">Final Date</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pat-dr-prescr-procd-finalDate-detail-value" md="12">
                    <dd>{patDrPrescrProcdEntity.finalDate ? moment(patDrPrescrProcdEntity.finalDate).format(APP_DATE_FORMAT) : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pat-dr-prescr-procd-initialHour-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top pat-dr-prescr-procd-initialHour-detail-label" md="12">
                    <dt>
                      <span id="initialHour">
                        <Translate contentKey="generadorApp.patDrPrescrProcd.initialHour">Hour</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pat-dr-prescr-procd-initialHour-detail-value" md="12">
                    <dd>{patDrPrescrProcdEntity.initialHour}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pat-dr-prescr-procd-observations-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top pat-dr-prescr-procd-observations-detail-label" md="12">
                    <dt>
                      <span id="observations">
                        <Translate contentKey="generadorApp.patDrPrescrProcd.observations">Observations</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pat-dr-prescr-procd-observations-detail-value" md="12">
                    <dd>{patDrPrescrProcdEntity.observations}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pat-dr-prescr-procd-createdDate-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top pat-dr-prescr-procd-createdDate-detail-label" md="12">
                    <dt>
                      <span id="createdDate">
                        <Translate contentKey="generadorApp.patDrPrescrProcd.createdDate">Created Date</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pat-dr-prescr-procd-createdDate-detail-value" md="12">
                    <dd>{patDrPrescrProcdEntity.createdDate ? moment(patDrPrescrProcdEntity.createdDate).format(APP_DATE_FORMAT) : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pat-dr-prescr-procd-lastModifiedDate-detail" md="12">
                <Row>
                  <Col className="view-detail-label-left pat-dr-prescr-procd-lastModifiedDate-detail-label" md="3">
                    <dt>
                      <span id="lastModifiedDate">
                        <Translate contentKey="generadorApp.patDrPrescrProcd.lastModifiedDate">Last Modified Date</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left pat-dr-prescr-procd-lastModifiedDate-detail-value" md="9">
                    <dd>
                      {patDrPrescrProcdEntity.lastModifiedDate
                        ? moment(patDrPrescrProcdEntity.lastModifiedDate).format(APP_DATE_FORMAT)
                        : ''}
                    </dd>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { patDrPrescrProcdEntity } = this.props;
    const state = this.state;
    return (
      <div>
        <Panel className="pat-dr-prescr-procd pat-dr-prescr-procd-detail">
          <PanelHeader>{this.renderHeader()}</PanelHeader>

          <PanelBody>{this.renderBody()}</PanelBody>
        </Panel>
      </div>
    );
  }
}

export const mapStateToProps = ({ patDrPrescrProcd, ...storeState }: IRootState) => ({
  patDrPrescrProcdEntity: patDrPrescrProcd.entity,
});

export const mapDispatchToProps = {
  getEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PatDrPrescrProcdDetail);
