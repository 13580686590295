import React from 'react';
import { translate as baseTrans } from 'react-jhipster';

export const Translate = props => {
  return <span trans-key={props.contentKey}> {baseTrans(props.contentKey, props.interpolate, props.children)} </span>;
};

export const translate = (contentKey: string, interpolate?: any, children?: string) => {
  return baseTrans(contentKey, interpolate, children);
};

export const dangerouslySetInnerHTML = (contentKey: string) => {
  return <div dangerouslySetInnerHTML={{ __html: contentKey.substring(0, 150) }} />;
};
