/* eslint-disable no-console */

import React from 'react';
import axios from 'axios';
import { Col, Row, Label } from 'reactstrap';
import { AvGroup } from 'availity-reactstrap-validation';
import { Translate, translate } from 'app/config/translate-component';
import { getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import SelectAsync from 'react-select/async';
import Select from 'react-select';

export interface IInventoryItemProps {
  baseState?: any;
  baseProps?: any;
  setState?: any;
  entityBase?: any;
}

const InventoryItemComponentCustomize = (props: IInventoryItemProps) => {
  return (
    <Row>
      <Col md="8">
        <AvGroup>
          <Row>
            <Col md="12">
              <Label className="mt-2 label-single-line" for="inventory-item-medicineMaterial">
                <Translate contentKey="generadorApp.inventoryItem.medicineMaterial">Medicine Material</Translate>
              </Label>
            </Col>
            <Col md="12">
              <SelectAsync
                id="inventory-item-medicineMaterial"
                name={'medicineMaterial'}
                className={'css-select-control'}
                data-type-rel="many-to-one-other-side"
                value={props.baseState.medicineMaterialSelectValue ? props.baseState.medicineMaterialSelectValue : ''}
                onChange={async options => {
                  const result = await axios.get('/api/medicine-materials/' + options.value + '/stock');
                  const totalLotes = result['data']
                    .map(v => v.STOCK)
                    .reduce((a, b) => Number.parseFloat(a) + Number.parseFloat(b), 0)
                    .toFixed(2);
                  const loteList = [{ value: 'Todos os Lotes', label: 'Todos os Lotes', total: totalLotes }];
                  result['data'].map(v => {
                    loteList.push({ value: v.lot, label: v.lot, total: v.STOCK });
                  });
                  props.setState({
                    medicineMaterialSelectValue: options,
                    currentQuantitySelectValue: 0,
                    lotStartSelectOptions: loteList,
                  });
                }}
                defaultOptions={
                  props.baseState.medicineMaterialStartSelectOptions ? props.baseState.medicineMaterialStartSelectOptions : []
                }
                loadingMessage={input => translate('selectAsync.loadingMessage')}
                noOptionsMessage={input =>
                  props.baseState.medicineMaterialStartSelectOptions === undefined
                    ? translate('selectAsync.loadingMessage')
                    : translate('selectAsync.noOptionsMessage')
                }
                onMenuOpen={async () => {
                  if (props.baseState.medicineMaterialStartSelectOptions === undefined) {
                    const result = await getListAxios('medicine-materials', {}, 0, 100, 'name,asc', 'name');
                    props.setState({
                      medicineMaterialStartSelectOptions: result.data
                        ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                        : [],
                    });
                  }
                }}
                loadOptions={async (inputValue, callback) => {
                  const result = await getListAxios('medicine-materials', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                  callback(result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' })) : []);
                }}
              />
            </Col>
          </Row>
        </AvGroup>
      </Col>
      <Col md="4">
        <AvGroup>
          <Row>
            <Col md="12">
              <Label className="mt-2 label-single-line" for="inventory-item-lot">
                <Translate contentKey="generadorApp.inventoryItem.lot">lot</Translate>
              </Label>
            </Col>
            <Col md="12">
              <Select
                id="bill-to-pay-type"
                className={'css-select-control'}
                value={{ value: props.baseState.lotSelectValue, label: props.baseState.lotSelectValue }}
                options={
                  !props.baseState.lotStartSelectOptions
                    ? [{ value: 'Todos os Lotes', label: 'Todos os Lotes', total: 0 }]
                    : props.baseState.lotStartSelectOptions
                }
                onChange={(options: any) =>
                  props.setState({
                    currentQuantitySelectValue: options['total'] ? options['total'] : '0.00',
                    lotSelectValue: options['value'],
                  })
                }
                name="lot"
              />
            </Col>
          </Row>
        </AvGroup>
      </Col>
    </Row>
  );
};

const mapStateToProps = storeState => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default InventoryItemComponentCustomize;
