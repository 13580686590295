import { styled } from 'styled-components';

export const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Card = styled.div`
  height: 300px;
  width: 90%;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
`;

export const InputReport = styled.input`
  height: 40px;
  width: 200px;
  border: solid 1px #c4c4c4;
  border-radius: 5px;
`;

export const TableCard = styled.div`
  width: 80%;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`;

export const TableRow = styled.div`
  width: 90%;
  height: 30px;
  background-color: #d6dadd;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
export const TableColumn = styled.div`
  width: 20%;
  height: 100%;
  border-top: solid 1px black;
  border-bottom: solid 1px black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const TittleRow = styled.text`
  color: black;
  font-size: 10px;
`;
export const MenuPagination = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
export const ButtonPagination = styled.button`
  border: none;
  color: black;
`;

export const Modal = styled.div`
  position: absolute;
  width: 70%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

export const OptionsReportName = styled.div`
  position: absolute;
  width: 200px;
  background-color: white;
  margin-left: 60px;
  margin-top: 30px;
  max-height: 100px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
`;

export const OptionsTextName = styled.text`
  color: black;
`;
export const OptionsTextDiv = styled.div`
  width: 100%;
  :hover {
    background-color: #6a9ef7;
  }
`;

export const ButtonSearch = styled.button`
  margin-top: 20px;
  color: #fff;
  background-color: #0060cc;
  height: 40px;
  width: 70px;
  border: none;
  border-radius: 10px;
  :hover {
    color: black;
  }
`;

export const SelectStatus = styled.select`
  height: 40px;
  width: 200px;
  border-color: #c4c4c4;
  border-radius: 10px;
`;

export const TittleDeposit = styled.text`
  font-size: 25px;
  line-height: 32px;
  font-weight: 600;
  align-self: flex-start;
  margin-left: 50px;
`;
export const SubTittleDeposit = styled.text`
  font-size: 13px;
  line-height: 32px;
  font-weight: 600;
  align-self: flex-start;
  margin-left: 70px;
`;
