/* eslint complexity: ["error", 500] */
/* eslint no-empty: off */
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import SelectAsync from 'react-select/async';

import { Link, RouteComponentProps } from 'react-router-dom';

import ReactQuill from 'react-quill';
import CurrencyInput from 'react-currency-input';
import {
  quillEditorModules,
  quillEditorFormats,
  getFilterFromSelect,
  getFormFromSelect,
  getListAxios,
  showFieldsSelectAsync,
  checkAnyValueFields,
} from 'app/shared/util/entity-utils';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Col, Label } from 'reactstrap';
import { setFileData } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import {
  IMedicineMaterialUpdateState as IBaseUpdateState,
  getEntity,
  getMedicineMaterialState,
  getUrlBack,
  updateEntity,
  createEntity,
  getEntityFiltersURL,
  getEntityOffset,
  setBlob,
  reset,
} from './medicine-material.reducer';

import { RenderModalSuperSelect } from 'app/shared/layout/components/RenderModalSuperSelect';

import { StatePropsExtended, DispatchPropsExtended, IUpdateExtendedState } from './extended/medicine-material-update';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

registerLocale('pt', pt);

export interface IMedicineMaterialUpdateProps
  extends StateProps,
    DispatchProps,
    StatePropsExtended,
    DispatchPropsExtended,
    RouteComponentProps<{ url: string }> {}
export interface IMedicineMaterialUpdateState extends IBaseUpdateState, IUpdateExtendedState {}

export class MedicineMaterialUpdate extends React.Component<IMedicineMaterialUpdateProps, IMedicineMaterialUpdateState> {
  tableFileInput: any;

  nameFileInput: any;

  minimumAmountFileInput: any;

  expenseCodeFileInput: any;

  activePrincipleFileInput: any;

  statusFileInput: any;

  ordinance344FileInput: any;

  medicineMaterialTypeFileInput: any;

  inventoryControlFileInput: any;

  fractionedFileInput: any;

  specialControlFileInput: any;

  thermolabileFileInput: any;

  commentsFileInput: any;

  laboratoryFileInput: any;
  constructor(props: Readonly<IMedicineMaterialUpdateProps>) {
    super(props);

    this.commentsFileInput = React.createRef();

    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      functionEmbebed: [],

      medicineMaterialStatusSelectValue: null,

      kitDietSelectValue: null,

      kitMaterialSelectValue: null,

      kitMedicineSelectValue: null,

      quoteDietSelectValue: null,

      quoteMedicineSelectValue: null,

      quoteMaterialSelectValue: null,

      clComItemSelectValue: null,

      supplierTableDietSelectValue: null,

      supplierTableMaterialSelectValue: null,

      supplierTableMedicineSelectValue: null,

      inventoryItemSelectValue: null,

      pharmacyStockSelectValue: null,

      patientAllergySelectValue: null,

      patDrPrescrProcdSelectValue: null,

      categorySelectValue: null,

      simproSelectValue: null,

      brasindiceSelectValue: null,

      brandSelectValue: null,

      presentationSelectValue: null,

      doseSelectValue: null,

      levelGroupSelectValue: null,

      tableSelectValue: null,
      nameSelectValue: null,
      minimumAmountSelectValue: null,
      expenseCodeSelectValue: null,
      activePrincipleSelectValue: null,
      statusSelectValue: null,
      ordinance344SelectValue: null,
      pharmagroupSelectValue: null,
      medicineMaterialTypeSelectValue: null,
      inventoryControlSelectValue: null,
      fractionedSelectValue: null,
      specialControlSelectValue: null,
      medicineControlSelectValue: null,
      thermolabileSelectValue: null,
      commentsSelectValue: null,
      laboratorySelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getMedicineMaterialState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
      idscategory: [],
      simproDescricao: null,
      brasindiceName: null,
      brandName: null,
      presentationName: null,
      doseName: null,
      levelGroupName: null,

      isNew: !this.props.match.params || !this.props.match.params['id'],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateSuccess !== this.props.updateSuccess && prevProps.updateSuccess) {
      this.handleClose();
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
      (this.props.medicineMaterialStatuses.length > 0 &&
        this.state.medicineMaterialStatusSelectValue === null &&
        this.props.medicineMaterialEntity.medicineMaterialStatus)
    ) {
      this.setState({
        medicineMaterialStatusSelectValue:
          this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.medicineMaterialStatus
            ? this.props.medicineMaterialEntity.medicineMaterialStatus
                .filter(p => p)
                .map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
      (this.props.kitDiets.length > 0 && this.state.kitDietSelectValue === null && this.props.medicineMaterialEntity.kitDiet)
    ) {
      this.setState({
        kitDietSelectValue:
          this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.kitDiet
            ? this.props.medicineMaterialEntity.kitDiet.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
      (this.props.kitMaterials.length > 0 && this.state.kitMaterialSelectValue === null && this.props.medicineMaterialEntity.kitMaterial)
    ) {
      this.setState({
        kitMaterialSelectValue:
          this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.kitMaterial
            ? this.props.medicineMaterialEntity.kitMaterial.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
      (this.props.kitMedicines.length > 0 && this.state.kitMedicineSelectValue === null && this.props.medicineMaterialEntity.kitMedicine)
    ) {
      this.setState({
        kitMedicineSelectValue:
          this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.kitMedicine
            ? this.props.medicineMaterialEntity.kitMedicine.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
      (this.props.quoteDiets.length > 0 && this.state.quoteDietSelectValue === null && this.props.medicineMaterialEntity.quoteDiet)
    ) {
      this.setState({
        quoteDietSelectValue:
          this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.quoteDiet
            ? this.props.medicineMaterialEntity.quoteDiet.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
      (this.props.quoteMedicines.length > 0 &&
        this.state.quoteMedicineSelectValue === null &&
        this.props.medicineMaterialEntity.quoteMedicine)
    ) {
      this.setState({
        quoteMedicineSelectValue:
          this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.quoteMedicine
            ? this.props.medicineMaterialEntity.quoteMedicine.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
      (this.props.quoteMaterials.length > 0 &&
        this.state.quoteMaterialSelectValue === null &&
        this.props.medicineMaterialEntity.quoteMaterial)
    ) {
      this.setState({
        quoteMaterialSelectValue:
          this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.quoteMaterial
            ? this.props.medicineMaterialEntity.quoteMaterial.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
      (this.props.clComItems.length > 0 && this.state.clComItemSelectValue === null && this.props.medicineMaterialEntity.clComItem)
    ) {
      this.setState({
        clComItemSelectValue:
          this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.clComItem
            ? this.props.medicineMaterialEntity.clComItem.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
      (this.props.supplierTableDiets.length > 0 &&
        this.state.supplierTableDietSelectValue === null &&
        this.props.medicineMaterialEntity.supplierTableDiet)
    ) {
      this.setState({
        supplierTableDietSelectValue:
          this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.supplierTableDiet
            ? this.props.medicineMaterialEntity.supplierTableDiet.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
      (this.props.supplierTableMaterials.length > 0 &&
        this.state.supplierTableMaterialSelectValue === null &&
        this.props.medicineMaterialEntity.supplierTableMaterial)
    ) {
      this.setState({
        supplierTableMaterialSelectValue:
          this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.supplierTableMaterial
            ? this.props.medicineMaterialEntity.supplierTableMaterial.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
      (this.props.supplierTableMedicines.length > 0 &&
        this.state.supplierTableMedicineSelectValue === null &&
        this.props.medicineMaterialEntity.supplierTableMedicine)
    ) {
      this.setState({
        supplierTableMedicineSelectValue:
          this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.supplierTableMedicine
            ? this.props.medicineMaterialEntity.supplierTableMedicine.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
      (this.props.inventoryItems.length > 0 &&
        this.state.inventoryItemSelectValue === null &&
        this.props.medicineMaterialEntity.inventoryItem)
    ) {
      this.setState({
        inventoryItemSelectValue:
          this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.inventoryItem
            ? this.props.medicineMaterialEntity.inventoryItem.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
      (this.props.pharmacyStocks.length > 0 &&
        this.state.pharmacyStockSelectValue === null &&
        this.props.medicineMaterialEntity.pharmacyStock)
    ) {
      this.setState({
        pharmacyStockSelectValue:
          this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.pharmacyStock
            ? this.props.medicineMaterialEntity.pharmacyStock.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
      (this.props.patientAllergies.length > 0 &&
        this.state.patientAllergySelectValue === null &&
        this.props.medicineMaterialEntity.patientAllergy)
    ) {
      this.setState({
        patientAllergySelectValue:
          this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.patientAllergy
            ? this.props.medicineMaterialEntity.patientAllergy.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
      (this.props.patDrPrescrProcds.length > 0 &&
        this.state.patDrPrescrProcdSelectValue === null &&
        this.props.medicineMaterialEntity.patDrPrescrProcd)
    ) {
      this.setState({
        patDrPrescrProcdSelectValue:
          this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.patDrPrescrProcd
            ? this.props.medicineMaterialEntity.patDrPrescrProcd.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-1 many-to-many owner-side
    if (
      (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
      (this.props.categories.length > 0 && this.state.categorySelectValue === null && this.props.medicineMaterialEntity.categories)
    ) {
      this.setState({
        categorySelectValue:
          this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.categories
            ? this.props.medicineMaterialEntity.categories
                .filter(p => p)
                .map(p => ({
                  value: p.id,
                  label: (p['levelGroup'] && p['levelGroup']['name'] ? p.levelGroup.name : '') + ' | ' + (p['name'] ? p.name : ''),
                }))
            : [],
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
      (this.props.simpros &&
        this.state.simproSelectValue === null &&
        this.props.medicineMaterialEntity.simpro &&
        this.props.medicineMaterialEntity.simpro.id)
    ) {
      const p =
        this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.simpro ? this.props.medicineMaterialEntity.simpro : null;
      this.setState({
        simproSelectValue: p ? { ...p, value: p.id, label: p['descricao'] ? p.descricao : '' } : null,
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
      (this.props.brasindices &&
        this.state.brasindiceSelectValue === null &&
        this.props.medicineMaterialEntity.brasindice &&
        this.props.medicineMaterialEntity.brasindice.id)
    ) {
      const p =
        this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.brasindice
          ? this.props.medicineMaterialEntity.brasindice
          : null;
      this.setState({
        brasindiceSelectValue: p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : null,
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
      (this.props.brands &&
        this.state.brandSelectValue === null &&
        this.props.medicineMaterialEntity.brand &&
        this.props.medicineMaterialEntity.brand.id)
    ) {
      const p =
        this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.brand ? this.props.medicineMaterialEntity.brand : null;
      this.setState({
        brandSelectValue: p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : null,
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
      (this.props.presentations &&
        this.state.presentationSelectValue === null &&
        this.props.medicineMaterialEntity.presentation &&
        this.props.medicineMaterialEntity.presentation.id)
    ) {
      const p =
        this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.presentation
          ? this.props.medicineMaterialEntity.presentation
          : null;
      this.setState({
        presentationSelectValue: p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : null,
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
      (this.props.doses &&
        this.state.doseSelectValue === null &&
        this.props.medicineMaterialEntity.dose &&
        this.props.medicineMaterialEntity.dose.id)
    ) {
      const p = this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.dose ? this.props.medicineMaterialEntity.dose : null;
      this.setState({
        doseSelectValue: p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : null,
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
      (this.props.levelGroups &&
        this.state.levelGroupSelectValue === null &&
        this.props.medicineMaterialEntity.levelGroup &&
        this.props.medicineMaterialEntity.levelGroup.id)
    ) {
      const p =
        this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.levelGroup
          ? this.props.medicineMaterialEntity.levelGroup
          : null;
      this.setState({
        levelGroupSelectValue: p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : null,
      });
    }

    if (
      (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
      (this.props.medicineMaterialEntity.table &&
        this.state.tableSelectValue === null &&
        this.props.medicineMaterialEntity.table !== this.state.tableSelectValue)
    ) {
      this.setState({ tableSelectValue: this.props.medicineMaterialEntity.table ? this.props.medicineMaterialEntity.table : null });
    }
    if (
      (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
      (this.props.medicineMaterialEntity.name &&
        this.state.nameSelectValue === null &&
        this.props.medicineMaterialEntity.name !== this.state.nameSelectValue)
    ) {
      this.setState({ nameSelectValue: this.props.medicineMaterialEntity.name ? this.props.medicineMaterialEntity.name : null });
    }
    if (
      (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
      (this.props.medicineMaterialEntity.minimumAmount &&
        this.state.minimumAmountSelectValue === null &&
        this.props.medicineMaterialEntity.minimumAmount !== this.state.minimumAmountSelectValue)
    ) {
      this.setState({
        minimumAmountSelectValue: this.props.medicineMaterialEntity.minimumAmount ? this.props.medicineMaterialEntity.minimumAmount : null,
      });
    }
    if (
      (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
      (this.props.medicineMaterialEntity.expenseCode &&
        this.state.expenseCodeSelectValue === null &&
        this.props.medicineMaterialEntity.expenseCode !== this.state.expenseCodeSelectValue)
    ) {
      this.setState({
        expenseCodeSelectValue: this.props.medicineMaterialEntity.expenseCode ? this.props.medicineMaterialEntity.expenseCode : null,
      });
    }
    if (
      (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
      (this.props.medicineMaterialEntity.activePrinciple &&
        this.state.activePrincipleSelectValue === null &&
        this.props.medicineMaterialEntity.activePrinciple !== this.state.activePrincipleSelectValue)
    ) {
      this.setState({
        activePrincipleSelectValue: this.props.medicineMaterialEntity.activePrinciple
          ? this.props.medicineMaterialEntity.activePrinciple
          : null,
      });
    }
    if (
      (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
      (this.state.statusSelectValue === null && this.props.medicineMaterialEntity.status !== this.state.statusSelectValue)
    ) {
      this.setState({ statusSelectValue: this.props.medicineMaterialEntity.status ? this.props.medicineMaterialEntity.status : 'ATIVO' });
    }
    if (
      (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
      (this.props.medicineMaterialEntity.ordinance344 &&
        this.state.ordinance344SelectValue === null &&
        this.props.medicineMaterialEntity.ordinance344 !== this.state.ordinance344SelectValue)
    ) {
      this.setState({
        ordinance344SelectValue: this.props.medicineMaterialEntity.ordinance344 ? this.props.medicineMaterialEntity.ordinance344 : null,
      });
    }
    if (
      (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
      (this.props.medicineMaterialEntity.medicineMaterialType &&
        this.state.medicineMaterialTypeSelectValue === null &&
        this.props.medicineMaterialEntity.medicineMaterialType !== this.state.medicineMaterialTypeSelectValue)
    ) {
      this.setState({
        medicineMaterialTypeSelectValue: this.props.medicineMaterialEntity.medicineMaterialType
          ? this.props.medicineMaterialEntity.medicineMaterialType
          : null,
      });
    }
    if (
      (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
      (this.state.inventoryControlSelectValue === null &&
        this.props.medicineMaterialEntity.inventoryControl !== this.state.inventoryControlSelectValue)
    ) {
      this.setState({
        inventoryControlSelectValue: this.props.medicineMaterialEntity.inventoryControl
          ? this.props.medicineMaterialEntity.inventoryControl
          : false,
      });
    }
    if (
      (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
      (this.state.fractionedSelectValue === null && this.props.medicineMaterialEntity.fractioned !== this.state.fractionedSelectValue)
    ) {
      this.setState({
        fractionedSelectValue: this.props.medicineMaterialEntity.fractioned ? this.props.medicineMaterialEntity.fractioned : false,
      });
    }
    if (
      (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
      (this.state.specialControlSelectValue === null &&
        this.props.medicineMaterialEntity.specialControl !== this.state.specialControlSelectValue)
    ) {
      this.setState({
        specialControlSelectValue: this.props.medicineMaterialEntity.specialControl
          ? this.props.medicineMaterialEntity.specialControl
          : false,
      });
    }
    if (
      (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
      (this.state.thermolabileSelectValue === null && this.props.medicineMaterialEntity.thermolabile !== this.state.thermolabileSelectValue)
    ) {
      this.setState({
        thermolabileSelectValue: this.props.medicineMaterialEntity.thermolabile ? this.props.medicineMaterialEntity.thermolabile : false,
      });
    }
    if (
      (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
      (this.props.medicineMaterialEntity.comments &&
        this.state.commentsSelectValue === null &&
        this.props.medicineMaterialEntity.comments !== this.state.commentsSelectValue)
    ) {
      this.setState({
        commentsSelectValue: this.props.medicineMaterialEntity.comments ? this.props.medicineMaterialEntity.comments : null,
      });
    }
    if (
      (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
      (this.props.medicineMaterialEntity.laboratory &&
        this.state.laboratorySelectValue === null &&
        this.props.medicineMaterialEntity.laboratory !== this.state.laboratorySelectValue)
    ) {
      this.setState({
        laboratorySelectValue: this.props.medicineMaterialEntity.laboratory ? this.props.medicineMaterialEntity.laboratory : null,
      });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  UNSAFE_componentWillMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params['id']);
    }
  }

  onBlobChange = (isAnImage, name, fileInput) => event => {
    const fileName = event.target.files[0].name;
    setFileData(event, (contentType, data) => this.props.setBlob(name, data, contentType, fileName), isAnImage);
    const varAux = {};
    varAux[name + 'SelectValue'] = fileName;
    this.setState(varAux);
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined);
  };
  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = offset !== null ? '&offset=' + offset : '';
    Object.keys(fieldsBase).map(key => {
      url += key !== 'offset' ? '&' + key + '=' + fieldsBase[key] : '';
    });
    return url;
  };
  saveEntity = (event: any, errors: any, values: any) => {
    if (!this.state.nameSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.medicineMaterial.name') }));
      return false;
    }

    const selectFieldsList = ['name', 'medicineMaterialType', 'categories.name', 'status'];

    if (errors.length === 0) {
      const { medicineMaterialEntity } = this.props;
      const _entity = {
        ...medicineMaterialEntity,
        // ...values,

        brasindice: getFormFromSelect(this.state.brasindiceSelectValue, 'many-to-one'), // many-to-one - otherSide

        simpro: getFormFromSelect(this.state.simproSelectValue, 'many-to-one'), // many-to-one - otherSide

        levelGroup: getFormFromSelect(this.state.levelGroupSelectValue, 'many-to-one'), // many-to-one - otherSide

        categories: getFormFromSelect(this.state.categorySelectValue, 'many-to-many'), // many-to-many - ownerSide

        dose: getFormFromSelect(this.state.doseSelectValue, 'many-to-one'), // many-to-one - otherSide

        presentation: getFormFromSelect(this.state.presentationSelectValue, 'many-to-one'), // many-to-one - otherSide

        brand: getFormFromSelect(this.state.brandSelectValue, 'many-to-one'), // many-to-one - otherSide

        table: this.state.tableSelectValue === null ? null : this.state.tableSelectValue,
        name: this.state.nameSelectValue,
        minimumAmount: this.state.minimumAmountSelectValue,
        expenseCode: this.state.expenseCodeSelectValue,
        activePrinciple: this.state.activePrincipleSelectValue,
        status: this.state.statusSelectValue === null ? 'INATIVADO' : this.state.statusSelectValue,
        ordinance344: this.state.ordinance344SelectValue === null ? null : this.state.ordinance344SelectValue,
        pharmagroup: this.state.pharmagroupSelectValue === null ? null : this.state.pharmagroupSelectValue,
        medicineMaterialType: this.state.medicineMaterialTypeSelectValue === null ? null : this.state.medicineMaterialTypeSelectValue,
        inventoryControl: this.state.inventoryControlSelectValue === null ? false : this.state.inventoryControlSelectValue,
        fractioned: this.state.fractionedSelectValue === null ? false : this.state.fractionedSelectValue,
        dosage: this.state.dosagemSelectValue,
        specialControl: this.state.specialControlSelectValue === null ? false : this.state.specialControlSelectValue,
        controlled: this.state.medicineControlSelectValue === null ? false : this.state.medicineControlSelectValue,
        thermolabile: this.state.thermolabileSelectValue === null ? false : this.state.thermolabileSelectValue,
        comments: this.state.commentsSelectValue,
        laboratory: this.state.laboratorySelectValue,
      };

      const entity = _entity;

      const {
        medicineMaterialType,
        name,
        categoryId,
        brandId,
        status,
        extraFilters,
        activePage,
        itemsPerPage,
        sort,
        order,
      } = this.state.fieldsBase;

      if (this.state.isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              medicineMaterialType,
              name,
              getFilterFromSelect(categoryId, 'many-to-many'),
              getFilterFromSelect(brandId, 'many-to-one'),
              status,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              medicineMaterialType,
              name,
              getFilterFromSelect(categoryId, 'many-to-many'),
              getFilterFromSelect(brandId, 'many-to-one'),
              status,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/medicine-material?' + getEntityFiltersURL(this.state.fieldsBase));
  };

  getFullEntityFromState(v) {
    return {
      id: v.id,
      table: this.state.tableSelectValue,
      name: this.state.nameSelectValue,
      minimumAmount: this.state.minimumAmountSelectValue,
      expenseCode: this.state.expenseCodeSelectValue,
      activePrinciple: this.state.activePrincipleSelectValue,
      status: this.state.statusSelectValue,
      ordinance344: this.state.ordinance344SelectValue,
      pharmagroup: this.state.pharmagroupSelectValue,
      medicineMaterialType: this.state.medicineMaterialTypeSelectValue,
      inventoryControl: this.state.inventoryControlSelectValue,
      fractioned: this.state.fractionedSelectValue,
      specialControl: this.state.specialControlSelectValue,
      controlled: this.state.medicineControlSelectValue,
      thermolabile: this.state.thermolabileSelectValue,
      comments: this.state.commentsSelectValue,
      laboratory: this.state.laboratorySelectValue,
      medicineMaterialStatus: this.state.medicineMaterialStatusSelectValue,
      kitDiet: this.state.kitDietSelectValue,
      kitMaterial: this.state.kitMaterialSelectValue,
      kitMedicine: this.state.kitMedicineSelectValue,
      quoteDiet: this.state.quoteDietSelectValue,
      quoteMedicine: this.state.quoteMedicineSelectValue,
      quoteMaterial: this.state.quoteMaterialSelectValue,
      clComItem: this.state.clComItemSelectValue,
      supplierTableDiet: this.state.supplierTableDietSelectValue,
      supplierTableMaterial: this.state.supplierTableMaterialSelectValue,
      supplierTableMedicine: this.state.supplierTableMedicineSelectValue,
      inventoryItem: this.state.inventoryItemSelectValue,
      pharmacyStock: this.state.pharmacyStockSelectValue,
      patientAllergy: this.state.patientAllergySelectValue,
      patDrPrescrProcd: this.state.patDrPrescrProcdSelectValue,
      category: this.state.categorySelectValue,
      simpro: this.state.simproSelectValue,
      brasindice: this.state.brasindiceSelectValue,
      brand: this.state.brandSelectValue,
      presentation: this.state.presentationSelectValue,
      dose: this.state.doseSelectValue,
      dosage: this.state.dosagemSelectValue,
      levelGroup: this.state.levelGroupSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    const medicineMaterialStatusEntity = v.medicineMaterialStatus;

    const kitDietEntity = v.kitDiet;

    const kitMaterialEntity = v.kitMaterial;

    const kitMedicineEntity = v.kitMedicine;

    const quoteDietEntity = v.quoteDiet;

    const quoteMedicineEntity = v.quoteMedicine;

    const quoteMaterialEntity = v.quoteMaterial;

    const clComItemEntity = v.clComItem;

    const supplierTableDietEntity = v.supplierTableDiet;

    const supplierTableMaterialEntity = v.supplierTableMaterial;

    const supplierTableMedicineEntity = v.supplierTableMedicine;

    const inventoryItemEntity = v.inventoryItem;

    const pharmacyStockEntity = v.pharmacyStock;

    const patientAllergyEntity = v.patientAllergy;

    const patDrPrescrProcdEntity = v.patDrPrescrProcd;

    const categoryEntity = this.props.categories.filter(x => x.medicineMaterials.map(x1 => x1.id).includes(v.id)).pop();

    const simproEntity = v.simpro;

    const brasindiceEntity = v.brasindice;

    const brandEntity = v.brand;

    const presentationEntity = v.presentation;

    const doseEntity = v.dose;

    const dosagemEntity = v.dosagem;

    const levelGroupEntity = v.levelGroup;

    return {
      tableSelectValue: v.table,
      nameSelectValue: v.name,
      minimumAmountSelectValue: v.minimumAmount,
      expenseCodeSelectValue: v.expenseCode,
      activePrincipleSelectValue: v.activePrinciple,
      statusSelectValue: v.status,
      ordinance344SelectValue: v.ordinance344,
      pharmagroupSelectValue: v.pharmagroup,
      medicineMaterialTypeSelectValue: v.medicineMaterialType,
      inventoryControlSelectValue: v.inventoryControl,
      fractionedSelectValue: v.fractioned,
      specialControlSelectValue: v.specialControl,
      medicineControlSelectValue: v.controlled,
      thermolabileSelectValue: v.thermolabile,
      commentsSelectValue: v.comments,
      laboratorySelectValue: v.laboratory,
      medicineMaterialStatusSelectValue: medicineMaterialStatusEntity ? medicineMaterialStatusEntity['id'] : null,
      kitDietSelectValue: kitDietEntity ? kitDietEntity['id'] : null,
      kitMaterialSelectValue: kitMaterialEntity ? kitMaterialEntity['id'] : null,
      kitMedicineSelectValue: kitMedicineEntity ? kitMedicineEntity['id'] : null,
      quoteDietSelectValue: quoteDietEntity ? quoteDietEntity['id'] : null,
      quoteMedicineSelectValue: quoteMedicineEntity ? quoteMedicineEntity['id'] : null,
      quoteMaterialSelectValue: quoteMaterialEntity ? quoteMaterialEntity['id'] : null,
      clComItemSelectValue: clComItemEntity ? clComItemEntity['id'] : null,
      supplierTableDietSelectValue: supplierTableDietEntity ? supplierTableDietEntity['id'] : null,
      supplierTableMaterialSelectValue: supplierTableMaterialEntity ? supplierTableMaterialEntity['id'] : null,
      supplierTableMedicineSelectValue: supplierTableMedicineEntity ? supplierTableMedicineEntity['id'] : null,
      inventoryItemSelectValue: inventoryItemEntity ? inventoryItemEntity['id'] : null,
      pharmacyStockSelectValue: pharmacyStockEntity ? pharmacyStockEntity['id'] : null,
      patientAllergySelectValue: patientAllergyEntity ? patientAllergyEntity['id'] : null,
      patDrPrescrProcdSelectValue: patDrPrescrProcdEntity ? patDrPrescrProcdEntity['id'] : null,
      categorySelectValue: categoryEntity ? categoryEntity['id'] : null,
      simproSelectValue: simproEntity ? simproEntity['id'] : null,
      brasindiceSelectValue: brasindiceEntity ? brasindiceEntity['id'] : null,
      brandSelectValue: brandEntity ? brandEntity['id'] : null,
      presentationSelectValue: presentationEntity ? presentationEntity['id'] : null,
      doseSelectValue: doseEntity ? doseEntity['id'] : null,
      dosagemSelectValue: dosagemEntity ? dosagemEntity['id'] : null,
      levelGroupSelectValue: levelGroupEntity ? levelGroupEntity['id'] : null,
    };
  }

  renderHeader() {
    const {
      medicineMaterialEntity,
      medicineMaterialStatuses,
      kitDiets,
      kitMaterials,
      kitMedicines,
      quoteDiets,
      quoteMedicines,
      quoteMaterials,
      clComItems,
      supplierTableDiets,
      supplierTableMaterials,
      supplierTableMedicines,
      inventoryItems,
      pharmacyStocks,
      patientAllergies,
      patDrPrescrProcds,
      categories,
      simpros,
      brasindices,
      brands,
      presentations,
      doses,
      levelGroups,
      loading,
      updating,
    } = this.props;
    const { isNew } = this.state;

    const { comments } = medicineMaterialEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.medicineMaterial.home.createLabel">Create a MedicineMaterial</Translate>
            ) : (
              <Translate contentKey="generadorApp.medicineMaterial.home.editLabel">Edit a MedicineMaterial</Translate>
            )}
          </span>

          <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.medicineMaterial.btnSave">Save</Translate>
          </Button>

          {!this.state.isNew ? (
            <>
              <Button
                color="primary"
                id="next-entity"
                disabled={updating}
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.medicineMaterialType,
                    this.state.fieldsBase.name,
                    this.state.fieldsBase.categoryId,
                    this.state.fieldsBase.brandId,
                    this.state.fieldsBase.status,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 + 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 + 1 },
                    });
                    this.props.history.push(
                      '/medicine-material/' +
                        myresult.value.data[0]['id'] +
                        '/edit?' +
                        this.getFiltersURL(this.state.fieldsBase.offset * 1 + 1)
                    );
                  } else {
                    toast.error(
                      translate('generadorApp.last.item', { fieldName: translate('generadorApp.medicineMaterial.detail.title') })
                    );
                  }
                }}
                className="float-right jh-create-entity"
              >
                {'>>'}
              </Button>

              <Button
                color="primary"
                id="prev-entity"
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.medicineMaterialType,
                    this.state.fieldsBase.name,
                    this.state.fieldsBase.categoryId,
                    this.state.fieldsBase.brandId,
                    this.state.fieldsBase.status,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 - 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 - 1 },
                    });
                    this.props.history.push(
                      '/medicine-material/' +
                        myresult.value.data[0]['id'] +
                        '/edit?' +
                        this.getFiltersURL(this.state.fieldsBase.offset * 1 - 1)
                    );
                  } else {
                    toast.error(
                      translate('generadorApp.first.item', { fieldName: translate('generadorApp.medicineMaterial.detail.title') })
                    );
                  }
                }}
                disabled={updating}
                className="float-right jh-create-entity"
              >
                {'<<'}
              </Button>
            </>
          ) : (
            <> </>
          )}

          <Button
            tag={Link}
            id="cancel-save"
            to={'/medicine-material?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.medicineMaterial.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={'/medicine-material'}>
              <Translate contentKey="generadorApp.medicineMaterial.home.title">Medicine/Material/Diet</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.medicineMaterial.detail.title">Medicine/Material/Diet edit</Translate>
          </li>
        </ol>
      </>
    );
  }
  renderBody() {
    const {
      medicineMaterialEntity,
      medicineMaterialStatuses,
      kitDiets,
      kitMaterials,
      kitMedicines,
      quoteDiets,
      quoteMedicines,
      quoteMaterials,
      clComItems,
      supplierTableDiets,
      supplierTableMaterials,
      supplierTableMedicines,
      inventoryItems,
      pharmacyStocks,
      patientAllergies,
      patDrPrescrProcds,
      categories,
      simpros,
      brasindices,
      brands,
      presentations,
      doses,
      levelGroups,
      loading,
      updating,
    } = this.props;
    const { isNew } = this.state;

    const { comments } = medicineMaterialEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const fullEntityEmbebed = this.getFullEntityFromState(medicineMaterialEntity);

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="medicine-material-id">
                  <Translate contentKey="generadorApp.medicineMaterial.field_id">ID</Translate>
                </Label>
                </Col> */}
                      <Col md="12">
                        <AvInput id="medicine-material-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'table' ? (
                    <Col md="5">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="tableLabel" for="medicine-material-table">
                              <Translate contentKey="generadorApp.medicineMaterial.table">Price Table</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="medicine-material-table"
                              className={'css-select-control'}
                              value={
                                this.state.tableSelectValue
                                  ? {
                                      value: this.state.tableSelectValue,
                                      label: translate('generadorApp.EnumTablePriceType.' + this.state.tableSelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.medicineMaterial.table') }
                              }
                              options={[
                                { value: 'BRASINDICE', label: translate('generadorApp.EnumTablePriceType.BRASINDICE') },
                                { value: 'SIMPRO', label: translate('generadorApp.EnumTablePriceType.SIMPRO') },
                              ]}
                              onChange={(options: any) => this.setState({ tableSelectValue: options['value'] })}
                              name="table"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ tableSelectValue: evt.target.value })}
                      type="hidden"
                      name="table"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {checkAnyValueFields(this.state, 'tableSelectValue', 'BRASINDICE', true) && baseFilters !== 'brasindice' ? (
                    <Col md="7">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="medicine-material-brasindice">
                              <Translate contentKey="generadorApp.medicineMaterial.brasindice">Brasindice</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="medicine-material-brasindice"
                              name={'brasindice'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.brasindiceSelectValue ? this.state.brasindiceSelectValue : ''}
                              onChange={options => this.setState({ brasindiceSelectValue: options })}
                              defaultOptions={this.state.brasindiceStartSelectOptions ? this.state.brasindiceStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.brasindiceStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.brasindiceStartSelectOptions === undefined) {
                                  const result = await getListAxios('brasindices', {}, 0, 100, 'name,asc', 'name');
                                  this.setState({
                                    brasindiceStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'brasindices',
                                  { 'name.contains': inputValue },
                                  0,
                                  100,
                                  'name,asc',
                                  'name'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="brasindice"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}

                  {checkAnyValueFields(this.state, 'tableSelectValue', 'SIMPRO', true) && baseFilters !== 'simpro' ? (
                    <Col md="7">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="medicine-material-simpro">
                              <Translate contentKey="generadorApp.medicineMaterial.simpro">Simpro</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="medicine-material-simpro"
                              name={'simpro'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.simproSelectValue ? this.state.simproSelectValue : ''}
                              onChange={options => this.setState({ simproSelectValue: options })}
                              defaultOptions={this.state.simproStartSelectOptions ? this.state.simproStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.simproStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.simproStartSelectOptions === undefined) {
                                  const result = await getListAxios('simpros', {}, 0, 100, 'descricao,asc', 'descricao');
                                  this.setState({
                                    simproStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'descricao') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'simpros',
                                  { 'descricao.contains': inputValue },
                                  0,
                                  100,
                                  'descricao,asc',
                                  'descricao'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'descricao') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="simpro"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}

                  {baseFilters !== 'levelGroup' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="medicine-material-levelGroup">
                              <Translate contentKey="generadorApp.medicineMaterial.levelGroup">Level Group</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="medicine-material-levelGroup"
                              name={'levelGroup'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.levelGroupSelectValue ? this.state.levelGroupSelectValue : ''}
                              onChange={options => this.setState({ levelGroupSelectValue: options })}
                              defaultOptions={this.state.levelGroupStartSelectOptions ? this.state.levelGroupStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.levelGroupStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.levelGroupStartSelectOptions === undefined) {
                                  const result = await getListAxios('level-groups', {}, 0, 100, 'name,asc', 'name');
                                  this.setState({
                                    levelGroupStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'level-groups',
                                  { 'name.contains': inputValue },
                                  0,
                                  100,
                                  'name,asc',
                                  'name'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="levelGroup"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}

                  {baseFilters !== 'category' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="medicine-material-category">
                              <Translate contentKey="generadorApp.medicineMaterial.category">Category</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              isMulti
                              id="medicine-material-category"
                              name={'category'}
                              className={'css-select-control'}
                              data-type-rel="many-to-many-owner-side"
                              value={this.state.categorySelectValue ? this.state.categorySelectValue : ''}
                              onChange={options => this.setState({ categorySelectValue: options })}
                              defaultOptions={this.state.categoryStartSelectOptions ? this.state.categoryStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.categoryStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.categoryStartSelectOptions === undefined) {
                                  const result = await getListAxios('categories', {}, 0, 100, 'levelGroup,asc', 'levelGroup.name,name');
                                  this.setState({
                                    categoryStartSelectOptions: result.data
                                      ? result.data.map(p => ({
                                          ...p,
                                          value: p.id,
                                          label: showFieldsSelectAsync(p, 'levelGroup.name;name') || '',
                                        }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'categories',
                                  { 'name.contains': inputValue },
                                  0,
                                  100,
                                  'levelGroup,asc',
                                  'levelGroup.name,name'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({
                                        ...p,
                                        value: p.id,
                                        label: showFieldsSelectAsync(p, 'levelGroup.name;name') || '',
                                      }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                          <RenderModalSuperSelect
                            baseState={this.state}
                            setState={_state => this.setState(_state)}
                            entity={'category'}
                            listEntity={'categories'}
                            template={'levelGroup.name;name'.split(';')}
                            stateField={'categorySelectValue'}
                            multiple={true}
                            showFields={'levelGroup.name;name'.split(';')}
                            order={'levelGroup,asc'}
                            filtersBase={{}}
                          />
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="category"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}

                  {baseFilters !== 'laboratory' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="laboratoryLabel" for="medicine-material-laboratory">
                              <Translate contentKey="generadorApp.medicineMaterial.laboratory">Laboratory</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ laboratorySelectValue: evt.target.value })}
                              value={this.state.laboratorySelectValue ? this.state.laboratorySelectValue : ''}
                              id="medicine-material-laboratory"
                              type="text"
                              name="laboratory"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ laboratorySelectValue: evt.target.value })}
                      type="hidden"
                      name="laboratory"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'name' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="nameLabel" for="medicine-material-name">
                              <Translate contentKey="generadorApp.medicineMaterial.name">Name</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                              value={this.state.nameSelectValue ? this.state.nameSelectValue : ''}
                              id="medicine-material-name"
                              type="text"
                              name="name"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                      type="hidden"
                      name="name"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'minimumAmount' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="minimumAmountLabel" for="medicine-material-minimumAmount">
                              <Translate contentKey="generadorApp.medicineMaterial.minimumAmount">Minimum Amount</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ minimumAmountSelectValue: floatvalue })}
                              decimalSeparator=""
                              thousandSeparator=""
                              precision={0}
                              prefix=""
                              allowNegative={true}
                              value={this.state.minimumAmountSelectValue ? this.state.minimumAmountSelectValue : 0}
                              id="medicine-material-minimumAmount"
                              name="minimumAmount"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ minimumAmountSelectValue: evt.target.value })}
                      type="hidden"
                      name="minimumAmount"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'activePrinciple' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="activePrincipleLabel" for="medicine-material-activePrinciple">
                              <Translate contentKey="generadorApp.medicineMaterial.activePrinciple">Active Principle</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ activePrincipleSelectValue: evt.target.value })}
                              value={this.state.activePrincipleSelectValue ? this.state.activePrincipleSelectValue : ''}
                              id="medicine-material-activePrinciple"
                              type="text"
                              name="activePrinciple"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ activePrincipleSelectValue: evt.target.value })}
                      type="hidden"
                      name="activePrinciple"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'dose' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="medicine-material-dose">
                              <Translate contentKey="generadorApp.medicineMaterial.dose">Dose</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="medicine-material-dose"
                              name={'dose'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.doseSelectValue ? this.state.doseSelectValue : ''}
                              onChange={options => this.setState({ doseSelectValue: options })}
                              defaultOptions={this.state.doseStartSelectOptions ? this.state.doseStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.doseStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.doseStartSelectOptions === undefined) {
                                  const result = await getListAxios('doses', {}, 0, 100, 'name,asc', 'name');
                                  this.setState({
                                    doseStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios('doses', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="dose"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}

                  {baseFilters !== 'presentation' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="medicine-material-presentation">
                              <Translate contentKey="generadorApp.medicineMaterial.presentation">Presentation</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="medicine-material-presentation"
                              name={'presentation'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.presentationSelectValue ? this.state.presentationSelectValue : ''}
                              onChange={options => this.setState({ presentationSelectValue: options })}
                              defaultOptions={this.state.presentationStartSelectOptions ? this.state.presentationStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.presentationStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.presentationStartSelectOptions === undefined) {
                                  const result = await getListAxios('presentations', {}, 0, 100, 'name,asc', 'name');
                                  this.setState({
                                    presentationStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'presentations',
                                  { 'name.contains': inputValue },
                                  0,
                                  100,
                                  'name,asc',
                                  'name'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="presentation"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}

                  {baseFilters !== 'thermolabile' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="thermolabileLabel" for="medicine-material-thermolabile">
                              <Translate contentKey="generadorApp.medicineMaterial.thermolabile">Thermolabile</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'thermolabile_checkbox'}
                                id={'thermolabile_checkbox'}
                                checked={this.state.thermolabileSelectValue}
                                onChange={evt => this.setState({ thermolabileSelectValue: !this.state.thermolabileSelectValue })}
                              />
                              <label htmlFor={'thermolabile_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ thermolabileSelectValue: evt.target.value })}
                      type="hidden"
                      name="thermolabile"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'fractioned' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="fractionedLabel" for="medicine-material-fractioned">
                              <Translate contentKey="generadorApp.medicineMaterial.fractioned">Fractioned</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'fractioned_checkbox'}
                                id={'fractioned_checkbox'}
                                checked={this.state.fractionedSelectValue}
                                onChange={evt => this.setState({ fractionedSelectValue: !this.state.fractionedSelectValue })}
                              />
                              <label htmlFor={'fractioned_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ fractionedSelectValue: evt.target.value })}
                      type="hidden"
                      name="fractioned"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'specialControl' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="specialControlLabel" for="medicine-material-specialControl">
                              <Translate contentKey="generadorApp.medicineMaterial.specialControl">Special Control</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'specialControl_checkbox'}
                                id={'specialControl_checkbox'}
                                checked={this.state.specialControlSelectValue}
                                onChange={evt => this.setState({ specialControlSelectValue: !this.state.specialControlSelectValue })}
                              />
                              <label htmlFor={'specialControl_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ specialControlSelectValue: evt.target.value })}
                      type="hidden"
                      name="specialControl"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'brand' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="medicine-material-brand">
                              <Translate contentKey="generadorApp.medicineMaterial.brand">Brand</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="medicine-material-brand"
                              name={'brand'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.brandSelectValue ? this.state.brandSelectValue : ''}
                              onChange={options => this.setState({ brandSelectValue: options })}
                              defaultOptions={this.state.brandStartSelectOptions ? this.state.brandStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.brandStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.brandStartSelectOptions === undefined) {
                                  const result = await getListAxios('brands', {}, 0, 100, 'name,asc', 'name');
                                  this.setState({
                                    brandStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios('brands', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="brand"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}

                  {baseFilters !== 'ordinance344' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="ordinance344Label" for="medicine-material-ordinance344">
                              <Translate contentKey="generadorApp.medicineMaterial.ordinance344">Ordinance 344</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="medicine-material-ordinance344"
                              className={'css-select-control'}
                              value={
                                this.state.ordinance344SelectValue
                                  ? {
                                      value: this.state.ordinance344SelectValue,
                                      label: translate('generadorApp.Ordinance.' + this.state.ordinance344SelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.medicineMaterial.ordinance344') }
                              }
                              options={[
                                { value: 'A1', label: translate('generadorApp.Ordinance.A1') },
                                { value: 'A2', label: translate('generadorApp.Ordinance.A2') },
                                { value: 'A3', label: translate('generadorApp.Ordinance.A3') },
                                { value: 'C1', label: translate('generadorApp.Ordinance.C1') },
                                { value: 'B1', label: translate('generadorApp.Ordinance.B1') },
                                { value: 'B2', label: translate('generadorApp.Ordinance.B2') },
                              ]}
                              onChange={(options: any) => this.setState({ ordinance344SelectValue: options['value'] })}
                              name="ordinance344"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ ordinance344SelectValue: evt.target.value })}
                      type="hidden"
                      name="ordinance344"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'inventoryControl' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="inventoryControlLabel" for="medicine-material-inventoryControl">
                              <Translate contentKey="generadorApp.medicineMaterial.inventoryControl">Inventory Control</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'inventoryControl_checkbox'}
                                id={'inventoryControl_checkbox'}
                                checked={this.state.inventoryControlSelectValue}
                                onChange={evt => this.setState({ inventoryControlSelectValue: !this.state.inventoryControlSelectValue })}
                              />
                              <label htmlFor={'inventoryControl_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ inventoryControlSelectValue: evt.target.value })}
                      type="hidden"
                      name="inventoryControl"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'comments' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="commentsLabel" for="medicine-material-comments">
                              <Translate contentKey="generadorApp.medicineMaterial.comments">Observations</Translate>
                            </Label>
                          </Col>

                          <Col md="12">
                            <ReactQuill
                              value={this.state.commentsSelectValue ? this.state.commentsSelectValue : ''}
                              onChange={evt => this.setState({ commentsSelectValue: evt })}
                              modules={quillEditorModules}
                              formats={quillEditorFormats}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ commentsSelectValue: evt.target.value })}
                      type="hidden"
                      name="comments"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'status' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="statusLabel" for="medicine-material-status">
                              <Translate contentKey="generadorApp.medicineMaterial.status">Status</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="medicine-material-status"
                              className={'css-select-control'}
                              value={
                                this.state.statusSelectValue
                                  ? {
                                      value: this.state.statusSelectValue,
                                      label: translate('generadorApp.Status.' + this.state.statusSelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.medicineMaterial.status') }
                              }
                              options={[
                                { value: 'INATIVADO', label: translate('generadorApp.Status.INATIVADO') },
                                { value: 'ATIVO', label: translate('generadorApp.Status.ATIVO') },
                              ]}
                              onChange={(options: any) => this.setState({ statusSelectValue: options['value'] })}
                              name="status"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ statusSelectValue: evt.target.value })}
                      type="hidden"
                      name="status"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const {
      medicineMaterialEntity,
      medicineMaterialStatuses,
      kitDiets,
      kitMaterials,
      kitMedicines,
      quoteDiets,
      quoteMedicines,
      quoteMaterials,
      clComItems,
      supplierTableDiets,
      supplierTableMaterials,
      supplierTableMedicines,
      inventoryItems,
      pharmacyStocks,
      patientAllergies,
      patDrPrescrProcds,
      categories,
      simpros,
      brasindices,
      brands,
      presentations,
      doses,
      levelGroups,
      loading,
      updating,
    } = this.props;
    const { isNew } = this.state;

    const { comments } = medicineMaterialEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...medicineMaterialEntity,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel className="medicine-material medicine-material-update">
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToProps = (storeState: IRootState) => {
  return {
    medicineMaterialStatuses: storeState.medicineMaterialStatus.entities,
    kitDiets: storeState.kitDiet.entities,
    kitMaterials: storeState.kitMaterial.entities,
    kitMedicines: storeState.kitMedicine.entities,
    quoteDiets: storeState.quoteDiet.entities,
    quoteMedicines: storeState.quoteMedicine.entities,
    quoteMaterials: storeState.quoteMaterial.entities,
    clComItems: storeState.clComItem.entities,
    supplierTableDiets: storeState.supplierTableDiet.entities,
    supplierTableMaterials: storeState.supplierTableMaterial.entities,
    supplierTableMedicines: storeState.supplierTableMedicine.entities,
    inventoryItems: storeState.inventoryItem.entities,
    pharmacyStocks: storeState.pharmacyStock.entities,
    patientAllergies: storeState.patientAllergy.entities,
    patDrPrescrProcds: storeState.patDrPrescrProcd.entities,
    categories: storeState.category.entities,
    simpros: storeState.simpro.entities,
    brasindices: storeState.brasindice.entities,
    brands: storeState.brand.entities,
    presentations: storeState.presentation.entities,
    doses: storeState.dose.entities,
    levelGroups: storeState.levelGroup.entities,
    medicineMaterialEntity: storeState.medicineMaterial.entity,
    loading: storeState.medicineMaterial.loading,
    updating: storeState.medicineMaterial.updating,
    updateSuccess: storeState.medicineMaterial.updateSuccess,
  };
};

export const mapDispatchToProps = {
  getEntity,
  updateEntity,
  setBlob,
  createEntity,
  getEntityOffset,
  reset,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MedicineMaterialUpdate);
