import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { IQuoteProductService } from 'app/shared/model/quote-product-service.model';
import { ISupplierTable } from 'app/shared/model/supplier-table.model';
import { IProductService } from 'app/shared/model/product-service.model';
import { ServiceType } from 'app/shared/model/enumerations/service-type.model';

export interface ISupplierTableProductService {
  id?: number;
  description?: string;
  service?: ServiceType;
  price?: number;
  quoteProductService?: IQuoteProductService[];
  supplierTable?: ISupplierTable;
  productService?: IProductService;
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  quoteProductService: {
    isMulti: false, // one-to-many - other-side
    entity: 'QuoteProductService',
    urlParameters: {
      enpoint: 'quote-product-services',
      filters: 'comercialTable.id.equals',
    },
  },
  supplierTable: {
    isMulti: true, // many-to-one - other-side
    entity: 'SupplierTable',
    urlParameters: {
      enpoint: 'supplier-tables',
      filters: 'supplierTableProductService.id.equals',
    },
  },
  productService: {
    isMulti: true, // many-to-one - other-side
    entity: 'ProductService',
    urlParameters: {
      enpoint: 'product-services',
      filters: 'supplierTableProductService.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'supplierTableProductService.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'supplierTableProductService.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<ISupplierTableProductService> = {};
