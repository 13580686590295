/* eslint complexity: ["error", 500] */
import React from 'react';

import { getFilterFromSelect, getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import { connect } from 'react-redux';
import Select from 'react-select';
import SelectAsync from 'react-select/async';
import { Link, RouteComponentProps } from 'react-router-dom';

import {
  Button,
  Col,
  Row,
  Table,
  Label,
  UncontrolledCollapse,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { AvForm, div, AvInput } from 'availity-reactstrap-validation';
import { JhiItemCount } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import QuoteRhFrequency from './components/QuoteRhFrequency';
import AttendancePlanned from './components/AttendancePlanned';
import ItemStatus from './components/ItemStatus';

import { IRootState } from 'app/shared/reducers';
import { updateEntity, deleteEntity, getUrlBack, getPadState, IPadBaseState, getEntityFiltersURL, getEntities, reset } from './pad.reducer';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE, IPaginationBaseState, getSortState } from 'app/shared/util/pagination.constants';

import { StatePropsExtended, DispatchPropsExtended, IExtendedState, IExtendedProps } from './extended/pad';

import { selectFieldsList } from './extended/extendSelectFields';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';
import moment from 'moment';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

export interface IPadProps
  extends StateProps,
    DispatchProps,
    StatePropsExtended,
    DispatchPropsExtended,
    IExtendedProps,
    RouteComponentProps<{ url: string }> {}
export interface IPadStateAux extends IPadBaseState, IExtendedState {}

export interface IPadState extends IPadStateAux, IPaginationBaseState {
  dropdownButtons: {};
}

export class Pad extends React.Component<IPadProps, IPadState> {
  protected myFormRef: any;

  constructor(props: IPadProps) {
    super(props);
    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      listCheckedID: [],
      isCheckedAll: false,
      dropdownButtons: {},
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      urlBack: getUrlBack(this.props.location),
      ...getPadState(this.props.location),
    };
  }

  toggle = btn => {
    const dropdownButtons = this.state.dropdownButtons;
    dropdownButtons[btn] = !dropdownButtons[btn];
    this.setState({ dropdownButtons });
  };

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({ listCheckedID: this.props.padList.map(pad => pad.id) });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({ listCheckedID: this.props.padList.map(pad => pad.id) });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  removeEntitySelected() {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  }

  saveEntityMany = () => {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  };

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  deleteEntity(padEntity) {
    MySwal.fire({
      title: <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>,
      html: (
        <Translate contentKey="generadorApp.pad.delete.question" interpolate={{ id: padEntity.id }}>
          Are you sure you want to delete this Pad?
        </Translate>
      ),
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#e70015',
      cancelButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="ban" />
          &nbsp; <Translate contentKey="generadorApp.pad.btnCancel">Cancel</Translate>
        </>
      ),
      confirmButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="trash" />
          &nbsp;<Translate contentKey="generadorApp.pad.btnDelete">Delete</Translate>
        </>
      ),
    }).then(async response => {
      if (response.isConfirmed) {
        await this.props.deleteEntity(padEntity.id, { reloadList: false });
        this.getEntities();
        MySwal.fire({
          title: <Translate contentKey="entity.delete.title.confirmed">Confirmed delete operation</Translate>,
          icon: 'success',
        });
      }
    });
  }

  cancelFilters = () => {
    this.setState(
      {
        id: '',
        initDateStart: '',
        initDateEnd: '',
        finalDateStart: '',
        finalDateEnd: '',
        status: '',
        patientId: '',
        quoteId: '',
        padCidPtaId: '',
        extraFilters: {},
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
        activePage: 0,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();

    const {
      id,
      initDateStart,
      initDateEnd,
      finalDateStart,
      finalDateEnd,
      status,
      patientId,
      quoteId,
      padCidPtaId,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;
    return this.props.getEntities(
      id,
      initDateStart,
      initDateEnd,
      finalDateStart,
      finalDateEnd,
      status,
      getFilterFromSelect(patientId, 'many-to-one'),
      getFilterFromSelect(quoteId, 'many-to-one'),
      getFilterFromSelect(padCidPtaId, 'one-to-many'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(',')
    );
  };

  updateEntity = entity => {
    const {
      id,
      initDateStart,
      initDateEnd,
      finalDateStart,
      finalDateEnd,
      status,
      patientId,
      quoteId,
      padCidPtaId,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;
    this.props.updateEntity(entity, [
      id,
      initDateStart,
      initDateEnd,
      finalDateStart,
      finalDateEnd,
      status,
      getFilterFromSelect(patientId, 'many-to-one'),
      getFilterFromSelect(quoteId, 'many-to-one'),
      getFilterFromSelect(padCidPtaId, 'one-to-many'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(','),
    ]);
  };

  renderHeader() {
    const { padCidPtas, padAttendances, patients, quotes, padList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.pad.home.title">PAD</Translate>
          </span>
          <Button id="togglerFilterPad" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.pad.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          &nbsp;
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.pad.home.title">PAD</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    const { padCidPtas, padAttendances, patients, quotes, padList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <UncontrolledCollapse toggler="#togglerFilterPad">
          <CardBody>
            <AvForm ref={el => (this.myFormRef = el)} id="form-filter" onSubmit={this.filterEntity}>
              <div className="row mt-1 ml-3 mr-3">
                {this.state.baseFilters !== 'id' ? (
                  <Col md="1" className="col-filter-pad-id">
                    <Row className="mr-1 mt-1">
                      <Label id="idLabel" for="pad-id">
                        <Translate contentKey="generadorApp.pad.id">Id</Translate>
                      </Label>
                      <AvInput type="number" name="id" id="pad-id" value={this.state.id} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'initDate' ? (
                  <Col md="3" className="col-filter-pad-initDate">
                    <Row className="mr-1 mt-1">
                      <Label id="initDateStart" for="pad-initDate_start">
                        <Translate contentKey="generadorApp.pad.initDate_start">Initial date (start)</Translate>
                      </Label>
                      <DatePicker
                        onChange={value => this.setState({ initDateStart: value })}
                        id="initDateStart"
                        className="form-control react-datepicker"
                        name="initDateStart"
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.initDateStart ? moment(this.state.initDateStart).toDate() : null}
                        locale="pt"
                      />
                    </Row>
                  </Col>
                ) : null}
                {this.state.baseFilters !== 'initDate' ? (
                  <Col md="3" className="col-filter-pad-initDate-final">
                    <Row className="mr-1 mt-1">
                      <Label id="initDateEnd" for="pad-initDate_end">
                        <Translate contentKey="generadorApp.pad.initDate_end">Initial date (end)</Translate>
                      </Label>
                      <DatePicker
                        onChange={value => this.setState({ initDateEnd: value })}
                        id="initDateEnd"
                        className="form-control react-datepicker"
                        name="initDateEnd"
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.initDateEnd ? moment(this.state.initDateEnd).toDate() : null}
                        locale="pt"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'finalDate' ? (
                  <Col md="3" className="col-filter-pad-finalDate">
                    <Row className="mr-1 mt-1">
                      <Label id="finalDateStart" for="pad-finalDate_start">
                        <Translate contentKey="generadorApp.pad.finalDate_start">Final date (start)</Translate>
                      </Label>
                      <DatePicker
                        onChange={value => this.setState({ finalDateStart: value })}
                        id="finalDateStart"
                        className="form-control react-datepicker"
                        name="finalDateStart"
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.finalDateStart ? moment(this.state.finalDateStart).toDate() : null}
                        locale="pt"
                      />
                    </Row>
                  </Col>
                ) : null}
                {this.state.baseFilters !== 'finalDate' ? (
                  <Col md="3" className="col-filter-pad-finalDate-final">
                    <Row className="mr-1 mt-1">
                      <Label id="finalDateEnd" for="pad-finalDate_end">
                        <Translate contentKey="generadorApp.pad.finalDate_end">Final date (end)</Translate>
                      </Label>
                      <DatePicker
                        onChange={value => this.setState({ finalDateEnd: value })}
                        id="finalDateEnd"
                        className="form-control react-datepicker"
                        name="finalDateEnd"
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.finalDateEnd ? moment(this.state.finalDateEnd).toDate() : null}
                        locale="pt"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'status' ? (
                  <Col md="2" className="col-filter-pad-status">
                    <Row className="mr-1 mt-1">
                      <Label id="statusLabel" for="pad-status">
                        <Translate contentKey="generadorApp.pad.status">Status</Translate>
                      </Label>
                      <Select
                        id="pad-status"
                        className={'css-select-control'}
                        value={
                          this.state.status
                            ? { value: this.state.status, label: translate('generadorApp.PadStatus.' + this.state.status) }
                            : { value: '', label: translate('generadorApp.pad.status') }
                        }
                        options={[
                          { value: '', label: translate('generadorApp.pad.status') },
                          { value: 'COMPLETO', label: translate('generadorApp.PadStatus.COMPLETO') },
                          { value: 'INCOMPLETO', label: translate('generadorApp.PadStatus.INCOMPLETO') },
                        ]}
                        onChange={(options: any) => this.setState({ status: options['value'] })}
                        name="status"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'patient' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="pad-patient">
                          <Translate contentKey="generadorApp.pad.patient">Patient</Translate>
                        </Label>
                        <SelectAsync
                          id="pad-patient"
                          isMulti
                          className={'css-select-control'}
                          name={'patient'}
                          cacheOptions
                          value={this.state.patientId}
                          onChange={options => this.setState({ patientId: options })}
                          defaultOptions={this.state.patientStartFilter ? this.state.patientStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.patientStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.patientStartFilter === undefined) {
                              const result = await getListAxios('patients', {}, 0, 100, 'name,asc', 'name');
                              this.setState({
                                patientStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios('patients', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'quote' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="pad-quote">
                          <Translate contentKey="generadorApp.pad.quote">Quote</Translate>
                        </Label>
                        <SelectAsync
                          id="pad-quote"
                          isMulti
                          className={'css-select-control'}
                          name={'quote'}
                          cacheOptions
                          value={this.state.quoteId}
                          onChange={options => this.setState({ quoteId: options })}
                          defaultOptions={this.state.quoteStartFilter ? this.state.quoteStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.quoteStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.quoteStartFilter === undefined) {
                              const result = await getListAxios('quotes', {}, 0, 100, 'id,asc', 'id');
                              this.setState({
                                quoteStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios('quotes', { 'id.contains': inputValue }, 0, 100, 'id,asc', 'id');
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'padCidPta' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="pad-padCidPta">
                          <Translate contentKey="generadorApp.pad.padCidPta">Pad Cid Pta</Translate>
                        </Label>
                        <SelectAsync
                          id="pad-padCidPta"
                          isMulti
                          className={'css-select-control'}
                          name={'padCidPta'}
                          cacheOptions
                          value={this.state.padCidPtaId}
                          onChange={options => this.setState({ padCidPtaId: options })}
                          defaultOptions={this.state.padCidPtaStartFilter ? this.state.padCidPtaStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.padCidPtaStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.padCidPtaStartFilter === undefined) {
                              const result = await getListAxios('pad-cid-ptas', {}, 0, 100, 'id,asc', 'id');
                              this.setState({
                                padCidPtaStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios('pad-cid-ptas', { 'id.contains': inputValue }, 0, 100, 'id,asc', 'id');
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}
              </div>

              <div className="row mb-2 mr-4 justify-content-end">
                <Button color="primary" type="submit">
                  <i className="fa fa-filter" aria-hidden={'true'}></i>&nbsp;
                  <Translate contentKey="generadorApp.pad.btnFilter">Filter</Translate>
                </Button>
                &nbsp;
                <Button color="secondary" onClick={this.cancelFilters}>
                  <FontAwesomeIcon icon="trash-alt" />
                  &nbsp;
                  <Translate contentKey="generadorApp.pad.btnCleanFilter">Clean Filter</Translate>
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </UncontrolledCollapse>
      </>
    );
  }

  renderTable() {
    const { padCidPtas, padAttendances, patients, quotes, padList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {padList && padList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null).length > 0 ? (
          <>
            <Table
              id="pad-table-list"
              responsive
              aria-describedby="pad-heading"
              className={'table-hover table-striped mt-4 table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.pad.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'quote.id' ? (
                    <th>
                      <Translate contentKey="generadorApp.pad.quote">Quote</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'patient.client.fantasyName' ? (
                    <th>
                      <Translate contentKey="generadorApp.pad.patient.client.fantasyName">Patient</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'patient.name' ? (
                    <th>
                      <Translate contentKey="generadorApp.pad.patient">Patient</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'initDate' ? (
                    <th className="hand" onClick={sortFunction('initDate')}>
                      <Translate contentKey="generadorApp.pad.initDate">Initial date</Translate>
                      <FontAwesomeIcon icon={state.sort === 'initDate' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'finalDate' ? (
                    <th className="hand" onClick={sortFunction('finalDate')}>
                      <Translate contentKey="generadorApp.pad.finalDate">Final date</Translate>
                      <FontAwesomeIcon icon={state.sort === 'finalDate' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'quote.quoteRh.specialty.category.name' ? (
                    <th>
                      <Translate contentKey="generadorApp.pad.quote.quoteRh.specialty.category.name">Quote</Translate>
                    </th>
                  ) : null}
                  <th>
                    <Translate contentKey="generadorApp.pad.QuoteRhFrequency">QuoteRhFrequency</Translate>
                  </th>
                  {state.baseFilters !== 'quote.quoteRh.days' ? (
                    <th>
                      <Translate contentKey="generadorApp.pad.quote.quoteRh.days">Quote</Translate>
                    </th>
                  ) : null}
                  <th>
                    <Translate contentKey="generadorApp.pad.AttendancePlanned">AttendancePlanned</Translate>
                  </th>
                  <th>
                    <Translate contentKey="generadorApp.pad.ItemStatus">ItemStatus</Translate>
                  </th>

                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {padList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((pad, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.pad.field_id">ID</Translate>
                        </b>
                        {pad.id}
                      </td>

                      {state.baseFilters !== 'quote' ? (
                        <td id="quote-cell">
                          <b className="visible-xs"> Quote </b>
                          {showFieldsSelectAsync(pad, 'quote.id', null).map((v, ikey) => (
                            <span key={ikey}> {v} </span>
                          ))}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'patient.client' ? (
                        <td id="patient-client-cell">
                          <b className="visible-xs"> Patient </b>
                          {showFieldsSelectAsync(pad, 'patient.client.fantasyName', null).map((v, ikey) => (
                            <span key={ikey}> {v} </span>
                          ))}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'patient' ? (
                        <td id="patient-cell">
                          <b className="visible-xs"> Patient </b>
                          {showFieldsSelectAsync(pad, 'patient.name', null).map((v, ikey) => (
                            <span key={ikey}> {v} </span>
                          ))}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'initDate' ? (
                        <td id="initDate-cell">
                          <b className="visible-xs"> Initial date </b>
                          {pad.initDate ? moment(pad.initDate).format(APP_LOCAL_DATE_FORMAT) : ''}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'finalDate' ? (
                        <td id="finalDate-cell">
                          <b className="visible-xs"> Final date </b>
                          {pad.finalDate ? moment(pad.finalDate).format(APP_LOCAL_DATE_FORMAT) : ''}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'quote.quoteRh.specialty.category' ? (
                        <td id="quote-quoteRh-specialty-category-cell">
                          <b className="visible-xs"> Quote </b>
                          {showFieldsSelectAsync(pad, 'quote.quoteRh.specialty.category.name', null).map((v, ikey) => (
                            <span key={ikey}> {v} </span>
                          ))}
                        </td>
                      ) : null}
                      <td>
                        {' '}
                        <QuoteRhFrequency
                          baseProps={this.props}
                          setState={_state => this.setState(_state)}
                          baseState={this.state}
                          entityBase={pad}
                        />
                      </td>

                      {state.baseFilters !== 'quote.quoteRh' ? (
                        <td id="quote-quoteRh-cell">
                          <b className="visible-xs"> Quote </b>
                          {showFieldsSelectAsync(pad, 'quote.quoteRh.days', null).map((v, ikey) => (
                            <span key={ikey}> {v} </span>
                          ))}
                        </td>
                      ) : null}
                      <td>
                        {' '}
                        <AttendancePlanned
                          baseProps={this.props}
                          setState={_state => this.setState(_state)}
                          baseState={this.state}
                          entityBase={pad}
                        />
                      </td>
                      <td>
                        {' '}
                        <ItemStatus
                          baseProps={this.props}
                          setState={_state => this.setState(_state)}
                          baseState={this.state}
                          entityBase={pad}
                        />
                      </td>

                      <td className="text-right">
                        <Dropdown isOpen={state.dropdownButtons[i]} toggle={() => this.toggle(i)}>
                          <DropdownToggle caret>
                            <Translate contentKey="generadorApp.pad.btnActions">Actions</Translate>
                          </DropdownToggle>
                          <DropdownMenu right>
                            <DropdownItem tag={Link} to={`/pad/${pad.id}/pad-attendance`} color="info" size="sm">
                              <FontAwesomeIcon icon="user" />

                              <span className="d-none d-md-inline">
                                <Translate contentKey="generadorApp.pad.listButtons.attendance">attendance</Translate>
                              </span>
                            </DropdownItem>
                            <DropdownItem tag={Link} to={`${match.url}/${pad.id}`} color="info" size="sm">
                              <FontAwesomeIcon icon="eye" />

                              <span className="d-none d-md-inline">
                                <Translate contentKey="generadorApp.pad.listButtons.view">view</Translate>
                              </span>
                            </DropdownItem>
                            <DropdownItem
                              tag={Link}
                              to={`${match.url}/${pad.id}/edit?${getEntityFiltersURL(state)}`}
                              color="primary"
                              size="sm"
                            >
                              <FontAwesomeIcon icon="pencil-alt" />

                              <span className="d-none d-md-inline">
                                <Translate contentKey="generadorApp.pad.listButtons.edit">edit</Translate>
                              </span>
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.pad.home.notFound">No PAD found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const { padCidPtas, padAttendances, patients, quotes, padList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={padList && padList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const { padCidPtas, padAttendances, patients, quotes, padList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel className="pad pad-list-table">
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToProps = ({ ...storeState }: IRootState) => {
  return {
    padCidPtas: storeState.padCidPta.entities,
    padAttendances: storeState.padAttendance.entities,
    patients: storeState.patient.entities,
    quotes: storeState.quote.entities,
    padList: storeState.pad.entities,
    totalItems: storeState.pad.totalItems,
    userAccount: storeState.authentication.account,
    loading: storeState.pad.loading,
  };
};

export const mapDispatchToProps = {
  getEntities,

  deleteEntity,
  updateEntity,
  reset,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Pad);
