/* eslint-disable no-console */
/* eslint complexity: ["error", 300] */
import React from 'react';
import { connect } from 'react-redux';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { PadUpdate, mapStateToProps, mapDispatchToProps } from '../pad-update';

import Select from 'react-select';

import 'react-quill/dist/quill.snow.css';

import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Table, Row, Col } from 'reactstrap';

import { Translate, translate } from 'app/config/translate-component';
import { IRootState } from 'app/shared/reducers';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';
import moment from 'moment';
registerLocale('pt', pt);

import {
  mapDispatchToPropsExtended as mapDispatchToPropsPadCidPta,
  PadCidPtaExtended as PadCidPta,
} from 'app/entities/pad-cid-pta/extended/pad-cid-pta';

import { TextFormat } from 'react-jhipster';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IUpdateExtendedState {
  dumystate?: any;
}

export class PadExtendedUpdate extends PadUpdate {
  renderHeader() {
    return <> {super.renderHeader()} </>;
  }

  UNSAFE_componentWillMount() {
    super.UNSAFE_componentWillMount();
  }

  renderTableQuoteRh(quoteRhList) {
    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.quoteRh.home.title">Human Resources</Translate>
          </span>
        </div>
        {quoteRhList && quoteRhList.length > 0 ? (
          <Table
            id="quote-rh-table-list"
            responsive
            aria-describedby="quote-rh-heading"
            className={'table-hover table-striped table-responsive-css'}
          >
            <thead className={'thead-light'}>
              <tr>
                <th>
                  <Translate contentKey="generadorApp.specialty.category">Category</Translate>
                </th>
                <th>
                  <Translate contentKey="generadorApp.quoteRh.specialty">Specialty</Translate>
                </th>
                <th>
                  <Translate contentKey="generadorApp.quoteRh.frequency">Frequency</Translate>
                </th>
                <th>
                  <Translate contentKey="generadorApp.quoteRh.quantity">Quantity</Translate>
                </th>
                <th>
                  <Translate contentKey="generadorApp.quote.date">Date</Translate>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr></tr>
              {quoteRhList
                .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                .map((quoteRh, i) => (
                  <tr key={`entity-${i}`}>
                    <td id="category-cell">
                      <b className="visible-xs">
                        {' '}
                        <Translate contentKey="generadorApp.specialty.category">Category</Translate>{' '}
                      </b>
                      {this.props.specialties
                        .filter(v => v.quoteRh.map(b => b.id).includes(quoteRh.id))
                        .map((v, k) => {
                          return v.category ? v.category.name : '';
                        })
                        .join(', ')}
                    </td>
                    <td id="specialty-cell">
                      <b className="visible-xs">
                        {' '}
                        <Translate contentKey="generadorApp.quoteRh.specialty">Specialty</Translate>{' '}
                      </b>
                      {quoteRh && quoteRh.specialty && quoteRh.specialty['name'] ? (
                        <> {quoteRh.specialty['name']} </>
                      ) : (
                        this.props.specialties.filter(v => v.quoteRh.map(b => b.id).includes(quoteRh.id)).map((v, k) => <> {v.name} </>)
                      )}
                    </td>
                    <td id="frequency-cell">
                      <b className="visible-xs">
                        {' '}
                        <Translate contentKey="generadorApp.quoteRh.frequency">Frequency</Translate>{' '}
                      </b>
                      {quoteRh.frequency ? <Translate contentKey={`generadorApp.QuoteFrequency.${quoteRh.frequency}`} /> : <> </>}
                    </td>
                    <td id="frequency-cell">
                      <b className="visible-xs">
                        {' '}
                        <Translate contentKey="generadorApp.quoteRh.quantity">Quantity</Translate>{' '}
                      </b>
                      {quoteRh.quantity}
                    </td>
                    <td id="frequency-cell">
                      <b className="visible-xs">
                        {' '}
                        <Translate contentKey="generadorApp.quote.date">Date</Translate>{' '}
                      </b>
                      <TextFormat type="date" value={quoteRh.startDate} format={APP_LOCAL_DATE_FORMAT} /> -{' '}
                      <TextFormat type="date" value={quoteRh.finishDate} format={APP_LOCAL_DATE_FORMAT} />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.quoteRh.home.notFound">No Human Resources found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderBody() {
    const { padEntity, patients, loading } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const mapDispatchToPropsPadCidPtaList: any = {};
    Object.keys(mapDispatchToPropsPadCidPta).map(v => {
      mapDispatchToPropsPadCidPtaList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'PadCidPta')) {
          this.setState({ functionEmbebed: [...this.state.functionEmbebed, v + 'PadCidPta'] }, this.props[v + 'PadCidPta']);
        }
      };
    });

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      <Col md="12">
                        <AvInput id="pad-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  <Col md="5">
                    <Row>
                      <Col md="6">
                        <AvGroup>
                          <Row>
                            <Col md="3">
                              <b className="mt-2">
                                <Translate contentKey="generadorApp.patient.client">Client</Translate>
                              </b>
                            </Col>
                            <Col md="9">{padEntity.patient && padEntity.patient.client ? padEntity.patient.client.socialReason : ''}</Col>
                            <Col md="3">
                              <b className="mt-2">
                                <Translate contentKey="generadorApp.pad.patient">Patient</Translate>
                              </b>
                            </Col>
                            <Col md="9"> {padEntity.patient ? padEntity.patient.name : ''}</Col>
                            <Col md="3">
                              <b className="mt-2" id="initDateLabel">
                                <Translate contentKey="generadorApp.pad.initDate">Initial date</Translate>
                              </b>
                            </Col>
                            <Col md="9">
                              {padEntity.initDate ? moment(padEntity.initDate).format('DD/MM/YYYY') : null} -{' '}
                              {padEntity.finalDate ? moment(padEntity.finalDate).format('DD/MM/YYYY') : null}
                            </Col>
                            <Col md="3">
                              <b className="mt-2" id="statusLabel">
                                <Translate contentKey="generadorApp.pad.status">Status</Translate>
                              </b>
                            </Col>
                            <Col md="9">
                              <Select
                                id="pad-status"
                                className={'css-select-control'}
                                value={
                                  this.state.statusSelectValue
                                    ? {
                                        value: this.state.statusSelectValue,
                                        label: translate('generadorApp.PadStatus.' + this.state.statusSelectValue),
                                      }
                                    : { value: 'INCOMPLETO', label: translate('generadorApp.PadStatus.INCOMPLETO') }
                                }
                                options={[
                                  { value: 'COMPLETO', label: translate('generadorApp.PadStatus.COMPLETO') },
                                  { value: 'INCOMPLETO', label: translate('generadorApp.PadStatus.INCOMPLETO') },
                                ]}
                                onChange={(options: any) => this.setState({ statusSelectValue: options['value'] })}
                                name="status"
                              />
                            </Col>
                          </Row>
                        </AvGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="7">
                    <div className="entityEmbebedList">
                      <PadCidPta
                        entityEmbebedPai={padEntity}
                        history={this.props.history}
                        location={{
                          pathname: `/pad/${this.props.match.params['id']}/pad-cid-pta/`,
                          search: '',
                          hash: '',
                          state: undefined,
                          key: 'q2tbhd',
                        }}
                        match={{
                          path: `/pad/:idPad/pad-cid-pta/:id`,
                          url: `/pad/${this.props.match.params['id']}/pad-cid-pta/`,
                          isExact: true,
                          params: { idPad: this.props.match.params['id'], url: `/pad/${this.props.match.params['id']}/pad-cid-pta/` },
                        }}
                        {...this.props.mapStateToPropsPadCidPta}
                        {...mapDispatchToPropsPadCidPtaList}
                        getEntities={this.padCidPtaGetEntities}
                        updateEntity={this.padCidPtaUpdateEntity}
                        getEntity={this.padCidPtaGetEntity}
                        createEntity={this.padCidPtaCreateEntity}
                        deleteEntity={this.padCidPtaDeleteEntity}
                        reset={this.padCidPtaReset}
                        padCidPtaList={this.props.padCidPtaListEmbed}
                        totalItems={this.props.padCidPtaListEmbed ? this.props.padCidPtaListEmbed.length : 0}
                        loading={this.props.loading}
                        padCidPtaEntity={this.props.padCidPtaSelected}
                      />
                    </div>
                  </Col>
                  <Col md="12">
                    {this.renderTableQuoteRh(
                      this.props.quoteRhs.filter(v => (padEntity.quote && v.quote ? v.quote.id === padEntity.quote.id : ''))
                    )}
                  </Col>
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { padEntity, padCidPtas, companies, patients, quotes, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...padEntity,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel>
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
    listProcediment: storeState.patDrPrescrProcd.entities,
    quoteRhs: storeState.quoteRh.entities,
    specialties: storeState.specialty.entities,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;
export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(PadExtendedUpdate);
