import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { ISupplierTableDiet } from 'app/shared/model/supplier-table-diet.model';
import { ISupplierTableMaterial } from 'app/shared/model/supplier-table-material.model';
import { ISupplierTableMedicine } from 'app/shared/model/supplier-table-medicine.model';
import { ISupplierTableProductService } from 'app/shared/model/supplier-table-product-service.model';
import { ISupplierTableRh } from 'app/shared/model/supplier-table-rh.model';
import { ISupplier } from 'app/shared/model/supplier.model';
import { Status } from 'app/shared/model/enumerations/status.model';

export interface ISupplierTable {
  id?: number;
  name?: string;
  status?: Status;
  observation?: any;
  supplierTableDiet?: ISupplierTableDiet[];
  supplierTableMaterial?: ISupplierTableMaterial[];
  supplierTableMedicine?: ISupplierTableMedicine[];
  supplierTableProductService?: ISupplierTableProductService[];
  supplierTableRh?: ISupplierTableRh[];
  supplier?: ISupplier;
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  supplierTableDiet: {
    isMulti: false, // one-to-many - other-side
    entity: 'SupplierTableDiet',
    urlParameters: {
      enpoint: 'supplier-table-diets',
      filters: 'supplierTable.id.equals',
    },
  },
  supplierTableMaterial: {
    isMulti: false, // one-to-many - other-side
    entity: 'SupplierTableMaterial',
    urlParameters: {
      enpoint: 'supplier-table-materials',
      filters: 'supplierTable.id.equals',
    },
  },
  supplierTableMedicine: {
    isMulti: false, // one-to-many - other-side
    entity: 'SupplierTableMedicine',
    urlParameters: {
      enpoint: 'supplier-table-medicines',
      filters: 'supplierTable.id.equals',
    },
  },
  supplierTableProductService: {
    isMulti: false, // one-to-many - other-side
    entity: 'SupplierTableProductService',
    urlParameters: {
      enpoint: 'supplier-table-product-services',
      filters: 'supplierTable.id.equals',
    },
  },
  supplierTableRh: {
    isMulti: false, // one-to-many - other-side
    entity: 'SupplierTableRh',
    urlParameters: {
      enpoint: 'supplier-table-rhs',
      filters: 'supplierTable.id.equals',
    },
  },
  supplier: {
    isMulti: true, // many-to-one - other-side
    entity: 'Supplier',
    urlParameters: {
      enpoint: 'suppliers',
      filters: 'supplierTable.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'supplierTable.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'supplierTable.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<ISupplierTable> = {};
