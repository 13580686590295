import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'app/config/translate-component';
import { getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import SelectAsync from 'react-select/async';
import Select from 'react-select';
import { RenderModalSuperSelect } from 'app/shared/layout/components/RenderModalSuperSelect';

const ModalUpdateSuprimento = props => {
  const { buttonLabel, className } = props;

  const [modal, setModal] = useState(false);
  const [tabelaComercialSel, setTabelaComercialSel] = useState(undefined);
  const [listSuppliersInitial, setlistSuppliersInitial] = useState(undefined);
  const [suprimentoStatus, setSuprimentoStatus] = useState(undefined);

  const toggle = () => setModal(!modal);
  if (suprimentoStatus === undefined && props.suprimentoStatus !== undefined) {
    setSuprimentoStatus(props.suprimentoStatus);
  }
  if (tabelaComercialSel === undefined && props.comercialTable !== null) {
    setTabelaComercialSel({
      id: props.comercialTable.id,
      label: showFieldsSelectAsync(
        props.comercialTable,
        'supplierTable.supplier.fantasyName;supplierTable.supplier.uf;supplierTable.supplier.city;price'
      ),
    });
  }

  return (
    <span>
      <Button onClick={() => toggle()} className="btn btn-sm btn-info">
        <FontAwesomeIcon icon="pencil-alt" />
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Editar suprimento</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={'6'}>
              <Label for="current-quantity">Status</Label>
              <Select
                id="suprimento-status"
                className={'css-select-control'}
                value={
                  suprimentoStatus
                    ? {
                        value: suprimentoStatus,
                        label: translate('generadorApp.SuprimentoStatus.' + suprimentoStatus),
                      }
                    : { value: '', label: translate('generadorApp.SuprimentoStatus.INCOMPLETO') }
                }
                options={[
                  { value: 'COMPLETO', label: translate('generadorApp.SuprimentoStatus.COMPLETO') },
                  { value: 'INCOMPLETO', label: translate('generadorApp.SuprimentoStatus.INCOMPLETO') },
                ]}
                onChange={(options: any) => setSuprimentoStatus(options['value'])}
                name="suprimentoStatus"
              />
            </Col>
          </Row>
          <Row className={'mt-3'}>
            <Col lg={'12'}>
              <Label for="itemId">
                Fornecedor | UF | Cidade | Preço (R$)
                <RenderModalSuperSelect
                  baseState={{ tabelaComercialSel }}
                  setState={_state => setTabelaComercialSel(_state.tabelaComercialSel)}
                  entity={'tabelaComercialSel'}
                  listEntity={'quotes/suppliers-product-service'}
                  template={'supplierTable.supplier.fantasyName;supplierTable.supplier.uf;supplierTable.supplier.city;price'.split(';')}
                  stateField={'tabelaComercialSel'}
                  multiple={false}
                  showFields={'supplierTable.supplier.fantasyName;supplierTable.supplier.uf;supplierTable.supplier.city;price'.split(';')}
                  order={'id,asc'}
                  filtersBase={{ productServiceId: props.productService ? props.productService.id : null }}
                />
              </Label>
              <SelectAsync
                id="suprimento-supplier"
                name={'suprimentoStatus'}
                className={'css-select-control'}
                data-type-rel="many-to-one-other-side"
                value={tabelaComercialSel}
                onChange={options => setTabelaComercialSel(options)}
                defaultOptions={listSuppliersInitial ? listSuppliersInitial : []}
                loadingMessage={input => translate('selectAsync.loadingMessage')}
                noOptionsMessage={input =>
                  listSuppliersInitial === undefined ? translate('selectAsync.loadingMessage') : translate('selectAsync.noOptionsMessage')
                }
                onMenuOpen={async () => {
                  if (listSuppliersInitial === undefined) {
                    const result = await getListAxios(
                      'quotes/suppliers-product-service',
                      { productServiceId: props.productService ? props.productService.id : null },
                      0,
                      100,
                      'id,asc',
                      'id,supplierTable.supplier.fantasyName'
                    );
                    setlistSuppliersInitial(
                      result.data
                        ? result.data.map(p => ({
                            ...p,
                            value: p.id,
                            label: showFieldsSelectAsync(
                              p,
                              'supplierTable.supplier.fantasyName;supplierTable.supplier.uf;supplierTable.supplier.city;price'
                            ),
                          }))
                        : []
                    );
                  }
                }}
                loadOptions={async (inputValue, callback) => {
                  const result = await getListAxios(
                    'quotes/suppliers-product-service',
                    {
                      'supplier.fantasyName.contains': inputValue,
                      productServiceId: props.productService.id,
                    },
                    0,
                    100,
                    'id,asc',
                    'id,supplierTable.supplier.fantasyName'
                  );
                  callback(
                    result.data
                      ? result.data.map(p => ({
                          ...p,
                          value: p.id,
                          label: showFieldsSelectAsync(
                            p,
                            'supplierTable.supplier.fantasyName;supplierTable.supplier.uf;supplierTable.supplier.city;price'
                          ),
                        }))
                      : []
                  );
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => props.getSuppliersByProductService1(tabelaComercialSel, suprimentoStatus)} type="submit">
            Salvar
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </span>
  );
};

export default ModalUpdateSuprimento;
