import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Category from './extended/category';

import CategoryUpdate from './category';

import CategoryStatusHistory from '../category-status-history/index';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute path={`${match.path}/:idCategory/category-status-history`} component={CategoryStatusHistory} />

      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={CategoryUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={CategoryUpdate} />
      <ErrorBoundaryRoute path={match.path} component={Category} />
    </Switch>

    <Switch>
      {/* <ErrorBoundaryRoute path={`${match.path}/:idCategory/category-status-history`} component={CategoryStatusHistory} /> */}
    </Switch>
  </>
);

export default Routes;
