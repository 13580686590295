/* eslint-disable no-console */

import React from 'react';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input';
import { Col, Label, Row } from 'reactstrap';
import InputMask from 'react-input-mask';
import { jsonParse } from 'app/shared/util/entity-utils';

export interface IPatDrPrescrProcdProps {
  baseState?: any;
  baseProps?: any;
  setState?: any;
  entityBase?: any;
}

const PatDrPrescrProcdComponentCustomize = (props: IPatDrPrescrProcdProps) => {
  const calcOptionsType = v => {
    switch (v) {
      case 'UMXSEMANA':
        return { dayOfMount: 0, dayOfWeek: 1, hoursOfDay: 0 };
      case 'DOISXSEMANA':
        return { dayOfMount: 0, dayOfWeek: 2, hoursOfDay: 0 };
      case 'TRESXSEMANA':
        return { dayOfMount: 0, dayOfWeek: 3, hoursOfDay: 0 };
      case 'QUATROXSEMANA':
        return { dayOfMount: 0, dayOfWeek: 4, hoursOfDay: 0 };
      case 'CINCOXSEMANA':
        return { dayOfMount: 0, dayOfWeek: 5, hoursOfDay: 0 };
      case 'SEISXSEMANA':
        return { dayOfMount: 0, dayOfWeek: 6, hoursOfDay: 0 };
      case 'UMXDIA':
        return { dayOfMount: 0, dayOfWeek: 0, hoursOfDay: 1 };
      case 'DOISXDIA':
        return { dayOfMount: 0, dayOfWeek: 0, hoursOfDay: 2 };
      case 'TRESXDIA':
        return { dayOfMount: 0, dayOfWeek: 0, hoursOfDay: 3 };
      case 'QUATROXDIA':
        return { dayOfMount: 0, dayOfWeek: 0, hoursOfDay: 4 };
      case 'CINCOXDIA':
        return { dayOfMount: 0, dayOfWeek: 0, hoursOfDay: 5 };
      case 'SEISXDIA':
        return { dayOfMount: 0, dayOfWeek: 0, hoursOfDay: 6 };
      case 'UMXMES':
        return { dayOfMount: 1, dayOfWeek: 0, hoursOfDay: 0 };
      case 'DOISXMES':
        return { dayOfMount: 2, dayOfWeek: 0, hoursOfDay: 0 };
      case 'TRESXMES':
        return { dayOfMount: 3, dayOfWeek: 0, hoursOfDay: 0 };
      case 'QUATROXMES':
        return { dayOfMount: 4, dayOfWeek: 0, hoursOfDay: 0 };
      case 'CADA48HORAS':
        return { dayOfMount: 0, dayOfWeek: 0, hoursOfDay: 0 };
      case 'CADA72HORAS':
        return { dayOfMount: 0, dayOfWeek: 0, hoursOfDay: 0 };
      case 'PONTUAL':
        return { dayOfMount: 0, dayOfWeek: 0, hoursOfDay: 0 };
      default:
        return { dayOfMount: 0, dayOfWeek: 0, hoursOfDay: 0 };
    }
  };

  const weekOptions = [
    { value: 'Domingo', label: 'Domingo' },
    { value: 'Segunda-feira', label: 'Segunda-feira' },
    { value: 'Terça-feira', label: 'Terça-feira' },
    { value: 'Quarta-feira', label: 'Quarta-feira' },
    { value: 'Quinta-feira', label: 'Quinta-feira' },
    { value: 'Sexta-feira', label: 'Sexta-feira' },
    { value: 'Sábado', label: 'Sábado' },
  ];
  const optionsType = calcOptionsType(props.baseState.frequencySelectValue);
  const frequencyDescriptionBuffered = props.baseState.frequencyDescriptionSelectValue;
  if (frequencyDescriptionBuffered && frequencyDescriptionBuffered.type === 'Buffer') {
    props.setState({
      frequencyDescriptionSelectValue: frequencyDescriptionBuffered ? Buffer.from(frequencyDescriptionBuffered).toString() : '[]',
    });
  }
  const frequencyDescriptionJson = frequencyDescriptionBuffered ? frequencyDescriptionBuffered : '[]';

  try {
    const frequencyDescriptionObjAux = { '0': '', '1': '', '2': '', '3': '', '4': '', '5': '' };
    jsonParse(frequencyDescriptionJson, false).map((v, i) => {
      frequencyDescriptionObjAux[i] = v;
    });

    return (
      <Row>
        <Col md="12">
          <Label className="mt-2 label-single-line">
            {optionsType.hoursOfDay === 1 ? 'Selecione o horário do dia do procedimento' : ''}
            {optionsType.hoursOfDay > 1 ? 'Selecione os horários do dia do procedimento' : ''}
            {optionsType.dayOfWeek === 1 ? 'Selecione o dia da semana do procedimento' : ''}
            {optionsType.dayOfWeek > 1 ? 'Selecione os dias da semana do procedimento' : ''}
            {optionsType.dayOfMount === 1 ? 'Selecione o dia do mes do procedimento' : ''}
            {optionsType.dayOfMount > 1 ? 'Selecione os dias do mes do procedimento' : ''}
          </Label>
        </Col>

        {[...Array(optionsType.hoursOfDay)].map((e, i) => (
          <Col key={i} md="2">
            <InputMask
              onChange={evt => {
                const _val = evt.target.value;
                frequencyDescriptionObjAux[i] = _val;
                props.setState({ frequencyDescriptionSelectValue: JSON.stringify(Object.values(frequencyDescriptionObjAux)) });
              }}
              value={frequencyDescriptionObjAux[i] ? frequencyDescriptionObjAux[i] : ''}
              id="pad-attendance-schedule"
              type="text"
              name="schedule"
              className={'form-control'}
              mask="99:99"
              tag={InputMask}
            />
          </Col>
        ))}
        {[...Array(optionsType.dayOfWeek)].map((e, i) => (
          <Col key={i} md="2">
            <Select
              className={'css-select-control'}
              value={
                weekOptions.map(v => v.value).includes(frequencyDescriptionObjAux[i])
                  ? { value: frequencyDescriptionObjAux[i], label: frequencyDescriptionObjAux[i] }
                  : ''
              }
              options={weekOptions}
              onChange={(options: any) => {
                frequencyDescriptionObjAux[i] = options.value;
                props.setState({ frequencyDescriptionSelectValue: JSON.stringify(Object.values(frequencyDescriptionObjAux)) });
              }}
              name="status"
            />
          </Col>
        ))}
        {[...Array(optionsType.dayOfMount)].map((e, i) => (
          <Col key={i} md="2">
            <CurrencyInput
              decimalSeparator=""
              thousandSeparator=""
              precision={0}
              prefix=""
              allowNegative={false}
              value={frequencyDescriptionObjAux[i] ? frequencyDescriptionObjAux[i] : 1}
              onChange={(maskedvalue, floatvalue, event) => {
                frequencyDescriptionObjAux[i] = floatvalue <= 31 ? floatvalue : 31;
                props.setState({ frequencyDescriptionSelectValue: JSON.stringify(Object.values(frequencyDescriptionObjAux)) });
              }}
              id="pat-dr-prescr-procd-order"
              name="order"
              className={'form-control'}
            />
          </Col>
        ))}
      </Row>
    );
  } catch (error) {
    console.info(error);
    return <></>;
  }
};

const mapStateToProps = storeState => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default PatDrPrescrProcdComponentCustomize;
