import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import PharmacyStockDevolution from './pharmacy-stock-devolution';

import PharmacyStockDevolutionDetail from './pharmacy-stock-devolution-detail';

import PharmacyStockDevolutionUpdate from './extended/pharmacy-stock-devolution-update';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={PharmacyStockDevolutionUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={PharmacyStockDevolutionUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={PharmacyStockDevolutionDetail} />
      <ErrorBoundaryRoute path={match.path} component={PharmacyStockDevolution} />
    </Switch>
  </>
);

export default Routes;
