/* eslint complexity: ["error", 500] */
/* eslint no-empty: off */
import React from 'react';
import { connect } from 'react-redux';

import { Link, RouteComponentProps } from 'react-router-dom';

import CurrencyInput from 'react-currency-input';

import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Col, Label } from 'reactstrap';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import {
  IBrasindiceUpdateState as IBaseUpdateState,
  getEntity,
  getBrasindiceState,
  getUrlBack,
  updateEntity,
  createEntity,
  getEntityFiltersURL,
  getEntityOffset,
  reset,
} from './brasindice.reducer';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

registerLocale('pt', pt);

export interface IBrasindiceUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}
export type IBrasindiceUpdateState = IBaseUpdateState;

export class BrasindiceUpdate extends React.Component<IBrasindiceUpdateProps, IBrasindiceUpdateState> {
  codeLabFileInput: any;

  nameLabFileInput: any;

  codeFileInput: any;

  nameFileInput: any;

  codeApreFileInput: any;

  nameApreFileInput: any;

  priceFileInput: any;

  quantFracFileInput: any;

  priceTypeFileInput: any;

  valueFracFileInput: any;

  ipiFileInput: any;

  flagPortFileInput: any;

  codeEanFileInput: any;

  codeTissFileInput: any;

  flagGenFileInput: any;

  codeTussFileInput: any;

  codeHierFileInput: any;

  brasindiceTypeFileInput: any;

  pcEmFabFileInput: any;

  pcFrFabFileInput: any;

  pcEmVenFileInput: any;

  pcFrVenFileInput: any;

  statusFileInput: any;
  constructor(props: Readonly<IBrasindiceUpdateProps>) {
    super(props);

    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      functionEmbebed: [],

      medicineMaterialSelectValue: null,

      codeLabSelectValue: null,
      nameLabSelectValue: null,
      codeSelectValue: null,
      nameSelectValue: null,
      codeApreSelectValue: null,
      nameApreSelectValue: null,
      priceSelectValue: null,
      quantFracSelectValue: null,
      priceTypeSelectValue: null,
      valueFracSelectValue: null,
      ipiSelectValue: null,
      flagPortSelectValue: null,
      codeEanSelectValue: null,
      codeTissSelectValue: null,
      flagGenSelectValue: null,
      codeTussSelectValue: null,
      codeHierSelectValue: null,
      brasindiceTypeSelectValue: null,
      pcEmFabSelectValue: null,
      pcFrFabSelectValue: null,
      pcEmVenSelectValue: null,
      pcFrVenSelectValue: null,
      statusSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getBrasindiceState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },

      isNew: !this.props.match.params || !this.props.match.params['id'],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateSuccess !== this.props.updateSuccess && prevProps.updateSuccess) {
      this.handleClose();
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.brasindiceEntity && prevProps.brasindiceEntity.id !== this.props.brasindiceEntity.id) ||
      (this.props.medicineMaterials.length > 0 &&
        this.state.medicineMaterialSelectValue === null &&
        this.props.brasindiceEntity.medicineMaterial)
    ) {
      this.setState({
        medicineMaterialSelectValue:
          this.props.brasindiceEntity && this.props.brasindiceEntity.medicineMaterial
            ? this.props.brasindiceEntity.medicineMaterial.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    if (
      (prevProps.brasindiceEntity && prevProps.brasindiceEntity.id !== this.props.brasindiceEntity.id) ||
      (this.props.brasindiceEntity.codeLab &&
        this.state.codeLabSelectValue === null &&
        this.props.brasindiceEntity.codeLab !== this.state.codeLabSelectValue)
    ) {
      this.setState({ codeLabSelectValue: this.props.brasindiceEntity.codeLab ? this.props.brasindiceEntity.codeLab : null });
    }
    if (
      (prevProps.brasindiceEntity && prevProps.brasindiceEntity.id !== this.props.brasindiceEntity.id) ||
      (this.props.brasindiceEntity.nameLab &&
        this.state.nameLabSelectValue === null &&
        this.props.brasindiceEntity.nameLab !== this.state.nameLabSelectValue)
    ) {
      this.setState({ nameLabSelectValue: this.props.brasindiceEntity.nameLab ? this.props.brasindiceEntity.nameLab : null });
    }
    if (
      (prevProps.brasindiceEntity && prevProps.brasindiceEntity.id !== this.props.brasindiceEntity.id) ||
      (this.props.brasindiceEntity.code &&
        this.state.codeSelectValue === null &&
        this.props.brasindiceEntity.code !== this.state.codeSelectValue)
    ) {
      this.setState({ codeSelectValue: this.props.brasindiceEntity.code ? this.props.brasindiceEntity.code : null });
    }
    if (
      (prevProps.brasindiceEntity && prevProps.brasindiceEntity.id !== this.props.brasindiceEntity.id) ||
      (this.props.brasindiceEntity.name &&
        this.state.nameSelectValue === null &&
        this.props.brasindiceEntity.name !== this.state.nameSelectValue)
    ) {
      this.setState({ nameSelectValue: this.props.brasindiceEntity.name ? this.props.brasindiceEntity.name : null });
    }
    if (
      (prevProps.brasindiceEntity && prevProps.brasindiceEntity.id !== this.props.brasindiceEntity.id) ||
      (this.props.brasindiceEntity.codeApre &&
        this.state.codeApreSelectValue === null &&
        this.props.brasindiceEntity.codeApre !== this.state.codeApreSelectValue)
    ) {
      this.setState({ codeApreSelectValue: this.props.brasindiceEntity.codeApre ? this.props.brasindiceEntity.codeApre : null });
    }
    if (
      (prevProps.brasindiceEntity && prevProps.brasindiceEntity.id !== this.props.brasindiceEntity.id) ||
      (this.props.brasindiceEntity.nameApre &&
        this.state.nameApreSelectValue === null &&
        this.props.brasindiceEntity.nameApre !== this.state.nameApreSelectValue)
    ) {
      this.setState({ nameApreSelectValue: this.props.brasindiceEntity.nameApre ? this.props.brasindiceEntity.nameApre : null });
    }
    if (
      (prevProps.brasindiceEntity && prevProps.brasindiceEntity.id !== this.props.brasindiceEntity.id) ||
      (this.props.brasindiceEntity.price &&
        this.state.priceSelectValue === null &&
        this.props.brasindiceEntity.price !== this.state.priceSelectValue)
    ) {
      this.setState({ priceSelectValue: this.props.brasindiceEntity.price ? this.props.brasindiceEntity.price : null });
    }
    if (
      (prevProps.brasindiceEntity && prevProps.brasindiceEntity.id !== this.props.brasindiceEntity.id) ||
      (this.props.brasindiceEntity.quantFrac &&
        this.state.quantFracSelectValue === null &&
        this.props.brasindiceEntity.quantFrac !== this.state.quantFracSelectValue)
    ) {
      this.setState({ quantFracSelectValue: this.props.brasindiceEntity.quantFrac ? this.props.brasindiceEntity.quantFrac : null });
    }
    if (
      (prevProps.brasindiceEntity && prevProps.brasindiceEntity.id !== this.props.brasindiceEntity.id) ||
      (this.props.brasindiceEntity.priceType &&
        this.state.priceTypeSelectValue === null &&
        this.props.brasindiceEntity.priceType !== this.state.priceTypeSelectValue)
    ) {
      this.setState({ priceTypeSelectValue: this.props.brasindiceEntity.priceType ? this.props.brasindiceEntity.priceType : null });
    }
    if (
      (prevProps.brasindiceEntity && prevProps.brasindiceEntity.id !== this.props.brasindiceEntity.id) ||
      (this.props.brasindiceEntity.valueFrac &&
        this.state.valueFracSelectValue === null &&
        this.props.brasindiceEntity.valueFrac !== this.state.valueFracSelectValue)
    ) {
      this.setState({ valueFracSelectValue: this.props.brasindiceEntity.valueFrac ? this.props.brasindiceEntity.valueFrac : null });
    }
    if (
      (prevProps.brasindiceEntity && prevProps.brasindiceEntity.id !== this.props.brasindiceEntity.id) ||
      (this.props.brasindiceEntity.ipi &&
        this.state.ipiSelectValue === null &&
        this.props.brasindiceEntity.ipi !== this.state.ipiSelectValue)
    ) {
      this.setState({ ipiSelectValue: this.props.brasindiceEntity.ipi ? this.props.brasindiceEntity.ipi : null });
    }
    if (
      (prevProps.brasindiceEntity && prevProps.brasindiceEntity.id !== this.props.brasindiceEntity.id) ||
      (this.props.brasindiceEntity.flagPort &&
        this.state.flagPortSelectValue === null &&
        this.props.brasindiceEntity.flagPort !== this.state.flagPortSelectValue)
    ) {
      this.setState({ flagPortSelectValue: this.props.brasindiceEntity.flagPort ? this.props.brasindiceEntity.flagPort : null });
    }
    if (
      (prevProps.brasindiceEntity && prevProps.brasindiceEntity.id !== this.props.brasindiceEntity.id) ||
      (this.props.brasindiceEntity.codeEan &&
        this.state.codeEanSelectValue === null &&
        this.props.brasindiceEntity.codeEan !== this.state.codeEanSelectValue)
    ) {
      this.setState({ codeEanSelectValue: this.props.brasindiceEntity.codeEan ? this.props.brasindiceEntity.codeEan : null });
    }
    if (
      (prevProps.brasindiceEntity && prevProps.brasindiceEntity.id !== this.props.brasindiceEntity.id) ||
      (this.props.brasindiceEntity.codeTiss &&
        this.state.codeTissSelectValue === null &&
        this.props.brasindiceEntity.codeTiss !== this.state.codeTissSelectValue)
    ) {
      this.setState({ codeTissSelectValue: this.props.brasindiceEntity.codeTiss ? this.props.brasindiceEntity.codeTiss : null });
    }
    if (
      (prevProps.brasindiceEntity && prevProps.brasindiceEntity.id !== this.props.brasindiceEntity.id) ||
      (this.props.brasindiceEntity.flagGen &&
        this.state.flagGenSelectValue === null &&
        this.props.brasindiceEntity.flagGen !== this.state.flagGenSelectValue)
    ) {
      this.setState({ flagGenSelectValue: this.props.brasindiceEntity.flagGen ? this.props.brasindiceEntity.flagGen : null });
    }
    if (
      (prevProps.brasindiceEntity && prevProps.brasindiceEntity.id !== this.props.brasindiceEntity.id) ||
      (this.props.brasindiceEntity.codeTuss &&
        this.state.codeTussSelectValue === null &&
        this.props.brasindiceEntity.codeTuss !== this.state.codeTussSelectValue)
    ) {
      this.setState({ codeTussSelectValue: this.props.brasindiceEntity.codeTuss ? this.props.brasindiceEntity.codeTuss : null });
    }
    if (
      (prevProps.brasindiceEntity && prevProps.brasindiceEntity.id !== this.props.brasindiceEntity.id) ||
      (this.props.brasindiceEntity.codeHier &&
        this.state.codeHierSelectValue === null &&
        this.props.brasindiceEntity.codeHier !== this.state.codeHierSelectValue)
    ) {
      this.setState({ codeHierSelectValue: this.props.brasindiceEntity.codeHier ? this.props.brasindiceEntity.codeHier : null });
    }
    if (
      (prevProps.brasindiceEntity && prevProps.brasindiceEntity.id !== this.props.brasindiceEntity.id) ||
      (this.props.brasindiceEntity.brasindiceType &&
        this.state.brasindiceTypeSelectValue === null &&
        this.props.brasindiceEntity.brasindiceType !== this.state.brasindiceTypeSelectValue)
    ) {
      this.setState({
        brasindiceTypeSelectValue: this.props.brasindiceEntity.brasindiceType ? this.props.brasindiceEntity.brasindiceType : null,
      });
    }
    if (
      (prevProps.brasindiceEntity && prevProps.brasindiceEntity.id !== this.props.brasindiceEntity.id) ||
      (this.props.brasindiceEntity.pcEmFab &&
        this.state.pcEmFabSelectValue === null &&
        this.props.brasindiceEntity.pcEmFab !== this.state.pcEmFabSelectValue)
    ) {
      this.setState({ pcEmFabSelectValue: this.props.brasindiceEntity.pcEmFab ? this.props.brasindiceEntity.pcEmFab : null });
    }
    if (
      (prevProps.brasindiceEntity && prevProps.brasindiceEntity.id !== this.props.brasindiceEntity.id) ||
      (this.props.brasindiceEntity.pcFrFab &&
        this.state.pcFrFabSelectValue === null &&
        this.props.brasindiceEntity.pcFrFab !== this.state.pcFrFabSelectValue)
    ) {
      this.setState({ pcFrFabSelectValue: this.props.brasindiceEntity.pcFrFab ? this.props.brasindiceEntity.pcFrFab : null });
    }
    if (
      (prevProps.brasindiceEntity && prevProps.brasindiceEntity.id !== this.props.brasindiceEntity.id) ||
      (this.props.brasindiceEntity.pcEmVen &&
        this.state.pcEmVenSelectValue === null &&
        this.props.brasindiceEntity.pcEmVen !== this.state.pcEmVenSelectValue)
    ) {
      this.setState({ pcEmVenSelectValue: this.props.brasindiceEntity.pcEmVen ? this.props.brasindiceEntity.pcEmVen : null });
    }
    if (
      (prevProps.brasindiceEntity && prevProps.brasindiceEntity.id !== this.props.brasindiceEntity.id) ||
      (this.props.brasindiceEntity.pcFrVen &&
        this.state.pcFrVenSelectValue === null &&
        this.props.brasindiceEntity.pcFrVen !== this.state.pcFrVenSelectValue)
    ) {
      this.setState({ pcFrVenSelectValue: this.props.brasindiceEntity.pcFrVen ? this.props.brasindiceEntity.pcFrVen : null });
    }
    if (
      (prevProps.brasindiceEntity && prevProps.brasindiceEntity.id !== this.props.brasindiceEntity.id) ||
      (this.state.statusSelectValue === null && this.props.brasindiceEntity.status !== this.state.statusSelectValue)
    ) {
      this.setState({ statusSelectValue: this.props.brasindiceEntity.status ? this.props.brasindiceEntity.status : false });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  UNSAFE_componentWillMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params['id']);
    }
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = offset !== null ? '&offset=' + offset : '';
    Object.keys(fieldsBase).map(key => {
      url += key !== 'offset' ? '&' + key + '=' + fieldsBase[key] : '';
    });
    return url;
  };
  saveEntity = (event: any, errors: any, values: any) => {
    const selectFieldsList = ['codeLab', 'nameLab', 'code', 'name', 'pcEmFab', 'pcEmVen'];

    if (errors.length === 0) {
      const { brasindiceEntity } = this.props;
      const _entity = {
        ...brasindiceEntity,
        // ...values,

        codeLab: this.state.codeLabSelectValue,
        nameLab: this.state.nameLabSelectValue,
        code: this.state.codeSelectValue,
        name: this.state.nameSelectValue,
        codeApre: this.state.codeApreSelectValue,
        nameApre: this.state.nameApreSelectValue,
        price: this.state.priceSelectValue,
        quantFrac: this.state.quantFracSelectValue,
        priceType: this.state.priceTypeSelectValue,
        valueFrac: this.state.valueFracSelectValue,
        ipi: this.state.ipiSelectValue,
        flagPort: this.state.flagPortSelectValue,
        codeEan: this.state.codeEanSelectValue,
        codeTiss: this.state.codeTissSelectValue,
        flagGen: this.state.flagGenSelectValue,
        codeTuss: this.state.codeTussSelectValue,
        codeHier: this.state.codeHierSelectValue,
        brasindiceType: this.state.brasindiceTypeSelectValue,
        pcEmFab: this.state.pcEmFabSelectValue,
        pcFrFab: this.state.pcFrFabSelectValue,
        pcEmVen: this.state.pcEmVenSelectValue,
        pcFrVen: this.state.pcFrVenSelectValue,
        status: this.state.statusSelectValue === null ? false : this.state.statusSelectValue,
      };

      const entity = _entity;

      const {
        brasindiceType,
        codeLab,
        nameLab,
        code,
        name,
        codeApre,
        nameApre,
        price,
        status,
        extraFilters,
        activePage,
        itemsPerPage,
        sort,
        order,
      } = this.state.fieldsBase;

      if (this.state.isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              brasindiceType,
              codeLab,
              nameLab,
              code,
              name,
              codeApre,
              nameApre,
              price,
              status,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              brasindiceType,
              codeLab,
              nameLab,
              code,
              name,
              codeApre,
              nameApre,
              price,
              status,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/brasindice?' + getEntityFiltersURL(this.state.fieldsBase));
  };

  getFullEntityFromState(v) {
    return {
      id: v.id,
      codeLab: this.state.codeLabSelectValue,
      nameLab: this.state.nameLabSelectValue,
      code: this.state.codeSelectValue,
      name: this.state.nameSelectValue,
      codeApre: this.state.codeApreSelectValue,
      nameApre: this.state.nameApreSelectValue,
      price: this.state.priceSelectValue,
      quantFrac: this.state.quantFracSelectValue,
      priceType: this.state.priceTypeSelectValue,
      valueFrac: this.state.valueFracSelectValue,
      ipi: this.state.ipiSelectValue,
      flagPort: this.state.flagPortSelectValue,
      codeEan: this.state.codeEanSelectValue,
      codeTiss: this.state.codeTissSelectValue,
      flagGen: this.state.flagGenSelectValue,
      codeTuss: this.state.codeTussSelectValue,
      codeHier: this.state.codeHierSelectValue,
      brasindiceType: this.state.brasindiceTypeSelectValue,
      pcEmFab: this.state.pcEmFabSelectValue,
      pcFrFab: this.state.pcFrFabSelectValue,
      pcEmVen: this.state.pcEmVenSelectValue,
      pcFrVen: this.state.pcFrVenSelectValue,
      status: this.state.statusSelectValue,
      medicineMaterial: this.state.medicineMaterialSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    const medicineMaterialEntity = v.medicineMaterial;

    return {
      codeLabSelectValue: v.codeLab,
      nameLabSelectValue: v.nameLab,
      codeSelectValue: v.code,
      nameSelectValue: v.name,
      codeApreSelectValue: v.codeApre,
      nameApreSelectValue: v.nameApre,
      priceSelectValue: v.price,
      quantFracSelectValue: v.quantFrac,
      priceTypeSelectValue: v.priceType,
      valueFracSelectValue: v.valueFrac,
      ipiSelectValue: v.ipi,
      flagPortSelectValue: v.flagPort,
      codeEanSelectValue: v.codeEan,
      codeTissSelectValue: v.codeTiss,
      flagGenSelectValue: v.flagGen,
      codeTussSelectValue: v.codeTuss,
      codeHierSelectValue: v.codeHier,
      brasindiceTypeSelectValue: v.brasindiceType,
      pcEmFabSelectValue: v.pcEmFab,
      pcFrFabSelectValue: v.pcFrFab,
      pcEmVenSelectValue: v.pcEmVen,
      pcFrVenSelectValue: v.pcFrVen,
      statusSelectValue: v.status,
      medicineMaterialSelectValue: medicineMaterialEntity ? medicineMaterialEntity['id'] : null,
    };
  }

  renderHeader() {
    const { brasindiceEntity, medicineMaterials, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.brasindice.home.createLabel">Create a Brasindice</Translate>
            ) : (
              <Translate contentKey="generadorApp.brasindice.home.editLabel">Edit a Brasindice</Translate>
            )}
          </span>

          <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.brasindice.btnSave">Save</Translate>
          </Button>

          {!this.state.isNew ? (
            <>
              <Button
                color="primary"
                id="next-entity"
                disabled={updating}
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.brasindiceType,
                    this.state.fieldsBase.codeLab,
                    this.state.fieldsBase.nameLab,
                    this.state.fieldsBase.code,
                    this.state.fieldsBase.name,
                    this.state.fieldsBase.codeApre,
                    this.state.fieldsBase.nameApre,
                    this.state.fieldsBase.price,
                    this.state.fieldsBase.status,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 + 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 + 1 },
                    });
                    this.props.history.push(
                      '/brasindice/' + myresult.value.data[0]['id'] + '/edit?' + this.getFiltersURL(this.state.fieldsBase.offset * 1 + 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.last.item', { fieldName: translate('generadorApp.brasindice.detail.title') }));
                  }
                }}
                className="float-right jh-create-entity"
              >
                {'>>'}
              </Button>

              <Button
                color="primary"
                id="prev-entity"
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.brasindiceType,
                    this.state.fieldsBase.codeLab,
                    this.state.fieldsBase.nameLab,
                    this.state.fieldsBase.code,
                    this.state.fieldsBase.name,
                    this.state.fieldsBase.codeApre,
                    this.state.fieldsBase.nameApre,
                    this.state.fieldsBase.price,
                    this.state.fieldsBase.status,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 - 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 - 1 },
                    });
                    this.props.history.push(
                      '/brasindice/' + myresult.value.data[0]['id'] + '/edit?' + this.getFiltersURL(this.state.fieldsBase.offset * 1 - 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.first.item', { fieldName: translate('generadorApp.brasindice.detail.title') }));
                  }
                }}
                disabled={updating}
                className="float-right jh-create-entity"
              >
                {'<<'}
              </Button>
            </>
          ) : (
            <> </>
          )}

          <Button
            tag={Link}
            id="cancel-save"
            to={'/brasindice?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.brasindice.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={'/brasindice'}>
              <Translate contentKey="generadorApp.brasindice.home.title">Brasindices</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.brasindice.detail.title">Brasindice edit</Translate>
          </li>
        </ol>
      </>
    );
  }
  renderBody() {
    const { brasindiceEntity, medicineMaterials, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const fullEntityEmbebed = this.getFullEntityFromState(brasindiceEntity);

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="brasindice-id">
                  <Translate contentKey="generadorApp.brasindice.field_id">ID</Translate>
                </Label>
                </Col> */}
                      <Col md="12">
                        <AvInput id="brasindice-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'codeLab' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="codeLabLabel" for="brasindice-codeLab">
                              <Translate contentKey="generadorApp.brasindice.codeLab">Code Lab</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ codeLabSelectValue: evt.target.value })}
                              value={this.state.codeLabSelectValue ? this.state.codeLabSelectValue : ''}
                              id="brasindice-codeLab"
                              type="text"
                              name="codeLab"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ codeLabSelectValue: evt.target.value })}
                      type="hidden"
                      name="codeLab"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'nameLab' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="nameLabLabel" for="brasindice-nameLab">
                              <Translate contentKey="generadorApp.brasindice.nameLab">Name Lab</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ nameLabSelectValue: evt.target.value })}
                              value={this.state.nameLabSelectValue ? this.state.nameLabSelectValue : ''}
                              id="brasindice-nameLab"
                              type="text"
                              name="nameLab"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ nameLabSelectValue: evt.target.value })}
                      type="hidden"
                      name="nameLab"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'code' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="codeLabel" for="brasindice-code">
                              <Translate contentKey="generadorApp.brasindice.code">Code</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ codeSelectValue: evt.target.value })}
                              value={this.state.codeSelectValue ? this.state.codeSelectValue : ''}
                              id="brasindice-code"
                              type="text"
                              name="code"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ codeSelectValue: evt.target.value })}
                      type="hidden"
                      name="code"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'name' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="nameLabel" for="brasindice-name">
                              <Translate contentKey="generadorApp.brasindice.name">Name</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                              value={this.state.nameSelectValue ? this.state.nameSelectValue : ''}
                              id="brasindice-name"
                              type="text"
                              name="name"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                      type="hidden"
                      name="name"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'codeApre' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="codeApreLabel" for="brasindice-codeApre">
                              <Translate contentKey="generadorApp.brasindice.codeApre">Code Apre</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ codeApreSelectValue: evt.target.value })}
                              value={this.state.codeApreSelectValue ? this.state.codeApreSelectValue : ''}
                              id="brasindice-codeApre"
                              type="text"
                              name="codeApre"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ codeApreSelectValue: evt.target.value })}
                      type="hidden"
                      name="codeApre"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'nameApre' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="nameApreLabel" for="brasindice-nameApre">
                              <Translate contentKey="generadorApp.brasindice.nameApre">Name Apre</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ nameApreSelectValue: evt.target.value })}
                              value={this.state.nameApreSelectValue ? this.state.nameApreSelectValue : ''}
                              id="brasindice-nameApre"
                              type="text"
                              name="nameApre"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ nameApreSelectValue: evt.target.value })}
                      type="hidden"
                      name="nameApre"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'price' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="priceLabel" for="brasindice-price">
                              <Translate contentKey="generadorApp.brasindice.price">Price</Translate>
                            </Label>
                          </Col>

                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ priceSelectValue: floatvalue })}
                              decimalSeparator=","
                              precision={2}
                              thousandSeparator=""
                              prefix="R$ "
                              allowNegative={true}
                              value={this.state.priceSelectValue ? this.state.priceSelectValue : 0}
                              id="brasindice-price"
                              name="price"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ priceSelectValue: evt.target.value })}
                      type="hidden"
                      name="price"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'quantFrac' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="quantFracLabel" for="brasindice-quantFrac">
                              <Translate contentKey="generadorApp.brasindice.quantFrac">Quant Frac</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ quantFracSelectValue: floatvalue })}
                              decimalSeparator=","
                              thousandSeparator=""
                              precision={2}
                              prefix=""
                              allowNegative={true}
                              value={this.state.quantFracSelectValue ? this.state.quantFracSelectValue : 0}
                              id="brasindice-quantFrac"
                              name="quantFrac"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ quantFracSelectValue: evt.target.value })}
                      type="hidden"
                      name="quantFrac"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'priceType' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="priceTypeLabel" for="brasindice-priceType">
                              <Translate contentKey="generadorApp.brasindice.priceType">Price Type</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ priceTypeSelectValue: evt.target.value })}
                              value={this.state.priceTypeSelectValue ? this.state.priceTypeSelectValue : ''}
                              id="brasindice-priceType"
                              type="text"
                              name="priceType"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ priceTypeSelectValue: evt.target.value })}
                      type="hidden"
                      name="priceType"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'valueFrac' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="valueFracLabel" for="brasindice-valueFrac">
                              <Translate contentKey="generadorApp.brasindice.valueFrac">Value Frac</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ valueFracSelectValue: floatvalue })}
                              decimalSeparator=","
                              thousandSeparator=""
                              precision={2}
                              prefix=""
                              allowNegative={true}
                              value={this.state.valueFracSelectValue ? this.state.valueFracSelectValue : 0}
                              id="brasindice-valueFrac"
                              name="valueFrac"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ valueFracSelectValue: evt.target.value })}
                      type="hidden"
                      name="valueFrac"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'ipi' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="ipiLabel" for="brasindice-ipi">
                              <Translate contentKey="generadorApp.brasindice.ipi">Ipi</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ ipiSelectValue: floatvalue })}
                              decimalSeparator=","
                              thousandSeparator=""
                              precision={2}
                              prefix=""
                              allowNegative={true}
                              value={this.state.ipiSelectValue ? this.state.ipiSelectValue : 0}
                              id="brasindice-ipi"
                              name="ipi"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ ipiSelectValue: evt.target.value })}
                      type="hidden"
                      name="ipi"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'flagPort' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="flagPortLabel" for="brasindice-flagPort">
                              <Translate contentKey="generadorApp.brasindice.flagPort">FLAG_PORT_PIS/COFINS</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ flagPortSelectValue: evt.target.value })}
                              value={this.state.flagPortSelectValue ? this.state.flagPortSelectValue : ''}
                              id="brasindice-flagPort"
                              type="text"
                              name="flagPort"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ flagPortSelectValue: evt.target.value })}
                      type="hidden"
                      name="flagPort"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'codeEan' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="codeEanLabel" for="brasindice-codeEan">
                              <Translate contentKey="generadorApp.brasindice.codeEan">Code Ean</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ codeEanSelectValue: evt.target.value })}
                              value={this.state.codeEanSelectValue ? this.state.codeEanSelectValue : ''}
                              id="brasindice-codeEan"
                              type="text"
                              name="codeEan"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ codeEanSelectValue: evt.target.value })}
                      type="hidden"
                      name="codeEan"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'codeTiss' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="codeTissLabel" for="brasindice-codeTiss">
                              <Translate contentKey="generadorApp.brasindice.codeTiss">Code Tiss</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ codeTissSelectValue: evt.target.value })}
                              value={this.state.codeTissSelectValue ? this.state.codeTissSelectValue : ''}
                              id="brasindice-codeTiss"
                              type="text"
                              name="codeTiss"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ codeTissSelectValue: evt.target.value })}
                      type="hidden"
                      name="codeTiss"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'flagGen' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="flagGenLabel" for="brasindice-flagGen">
                              <Translate contentKey="generadorApp.brasindice.flagGen">Flag Gen</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ flagGenSelectValue: evt.target.value })}
                              value={this.state.flagGenSelectValue ? this.state.flagGenSelectValue : ''}
                              id="brasindice-flagGen"
                              type="text"
                              name="flagGen"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ flagGenSelectValue: evt.target.value })}
                      type="hidden"
                      name="flagGen"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'codeTuss' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="codeTussLabel" for="brasindice-codeTuss">
                              <Translate contentKey="generadorApp.brasindice.codeTuss">Code Tuss</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ codeTussSelectValue: evt.target.value })}
                              value={this.state.codeTussSelectValue ? this.state.codeTussSelectValue : ''}
                              id="brasindice-codeTuss"
                              type="text"
                              name="codeTuss"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ codeTussSelectValue: evt.target.value })}
                      type="hidden"
                      name="codeTuss"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'codeHier' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="codeHierLabel" for="brasindice-codeHier">
                              <Translate contentKey="generadorApp.brasindice.codeHier">Code Hier</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ codeHierSelectValue: evt.target.value })}
                              value={this.state.codeHierSelectValue ? this.state.codeHierSelectValue : ''}
                              id="brasindice-codeHier"
                              type="text"
                              name="codeHier"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ codeHierSelectValue: evt.target.value })}
                      type="hidden"
                      name="codeHier"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'brasindiceType' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="brasindiceTypeLabel" for="brasindice-brasindiceType">
                              <Translate contentKey="generadorApp.brasindice.brasindiceType">Brasindice Type</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ brasindiceTypeSelectValue: evt.target.value })}
                              value={this.state.brasindiceTypeSelectValue ? this.state.brasindiceTypeSelectValue : ''}
                              id="brasindice-brasindiceType"
                              type="text"
                              name="brasindiceType"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ brasindiceTypeSelectValue: evt.target.value })}
                      type="hidden"
                      name="brasindiceType"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'status' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="statusLabel" for="brasindice-status">
                              <Translate contentKey="generadorApp.brasindice.status">Status</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'status_checkbox'}
                                id={'status_checkbox'}
                                checked={this.state.statusSelectValue}
                                onChange={evt => this.setState({ statusSelectValue: !this.state.statusSelectValue })}
                              />
                              <label htmlFor={'status_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ statusSelectValue: evt.target.value })}
                      type="hidden"
                      name="status"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'pcEmFab' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="pcEmFabLabel" for="brasindice-pcEmFab">
                              <Translate contentKey="generadorApp.brasindice.pcEmFab">Pc Em Fab</Translate>
                            </Label>
                          </Col>

                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ pcEmFabSelectValue: floatvalue })}
                              decimalSeparator=","
                              precision={2}
                              thousandSeparator=""
                              prefix="R$ "
                              allowNegative={true}
                              value={this.state.pcEmFabSelectValue ? this.state.pcEmFabSelectValue : 0}
                              id="brasindice-pcEmFab"
                              name="pcEmFab"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ pcEmFabSelectValue: evt.target.value })}
                      type="hidden"
                      name="pcEmFab"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'pcEmVen' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="pcEmVenLabel" for="brasindice-pcEmVen">
                              <Translate contentKey="generadorApp.brasindice.pcEmVen">Pc Em Ven</Translate>
                            </Label>
                          </Col>

                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ pcEmVenSelectValue: floatvalue })}
                              decimalSeparator=","
                              precision={2}
                              thousandSeparator=""
                              prefix="R$ "
                              allowNegative={true}
                              value={this.state.pcEmVenSelectValue ? this.state.pcEmVenSelectValue : 0}
                              id="brasindice-pcEmVen"
                              name="pcEmVen"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ pcEmVenSelectValue: evt.target.value })}
                      type="hidden"
                      name="pcEmVen"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { brasindiceEntity, medicineMaterials, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...brasindiceEntity,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel className="brasindice brasindice-update">
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToProps = (storeState: IRootState) => {
  return {
    medicineMaterials: storeState.medicineMaterial.entities,
    brasindiceEntity: storeState.brasindice.entity,
    loading: storeState.brasindice.loading,
    updating: storeState.brasindice.updating,
    updateSuccess: storeState.brasindice.updateSuccess,
  };
};

export const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  getEntityOffset,
  reset,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(BrasindiceUpdate);
