import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Kit from './extended/kit';

import KitDetail from './kit-detail';

import KitUpdate from './extended/kit-update';

import KitStatusHistory from '../kit-status-history/index';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute path={`${match.path}/:idKit/kit-status-history`} component={KitStatusHistory} />

      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={KitUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={KitUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={KitDetail} />
      <ErrorBoundaryRoute path={match.path} component={Kit} />
    </Switch>

    <Switch>{/* <ErrorBoundaryRoute path={`${match.path}/:idKit/kit-status-history`} component={KitStatusHistory} /> */}</Switch>
  </>
);

export default Routes;
