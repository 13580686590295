import React, { useEffect } from 'react';
import Chart from 'react-apexcharts';
import { connect } from 'react-redux';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import FiltersModal from './modal/filters-modal';

export interface IAtendimentosCategoriaProps extends StateProps, DispatchProps {
  getReport: Function;
}

const AtendimentosCategoria = (props: IAtendimentosCategoriaProps) => {
  useEffect(() => {
    props.getReport('atendimentos-categoria');
  }, []);

  let total = 0;
  let categories = ['Sem Categorias'];
  const data = [];
  if (props.report.length > 0) {
    categories = [];
    Object.values(props.report)
      .filter(value => value !== undefined)
      .map(value => {
        categories.push(value['name']);
        data.push(value['amount']);
        total += parseInt(value['amount'], 10);
      });
  }

  const state = {
    options: {
      chart: {
        id: 'chart-atendimentos-categoria',
      },
      xaxis: {
        categories,
      },
      title: {
        text: 'Total (' + total + ')',
        floating: true,
        align: 'center',
        style: {
          color: '#444',
        },
      },
    },
    series: [
      {
        name: 'Atendimentos',
        data,
      },
    ],
  };

  return (
    <Panel>
      <PanelHeader>
        <span>Número de atendimentos por categorías</span>
        <FiltersModal />
      </PanelHeader>
      <PanelBody>
        <Chart options={state.options} series={state.series} type="bar" width="100%" height={250} />
      </PanelBody>
    </Panel>
  );
};

const mapStateToProps = storeState => ({
  report:
    storeState.jhiReports.reportsList && storeState.jhiReports.reportsList['atendimentos-categoria']
      ? storeState.jhiReports.reportsList['atendimentos-categoria']
      : [],
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps)(AtendimentosCategoria);
