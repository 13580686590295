import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { IInventoryItem } from 'app/shared/model/inventory-item.model';
import { MedicineMaterialType } from 'app/shared/model/enumerations/medicine-material-type.model';

export interface IPharmacyInventory {
  id?: number;
  levelGroup?: MedicineMaterialType;
  inventoryItem?: IInventoryItem[];
  user?: IUser;
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  inventoryItem: {
    isMulti: false, // one-to-many - other-side
    entity: 'InventoryItem',
    urlParameters: {
      enpoint: 'inventory-items',
      filters: 'pharmacyInventory.id.equals',
    },
  },
  user: {
    isMulti: true, // many-to-one - other-side
    entity: 'User',
    urlParameters: {
      enpoint: 'users',
      filters: 'pharmacyInventory.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'pharmacyInventory.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'pharmacyInventory.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<IPharmacyInventory> = {};
