/* eslint-disable no-console */
/* eslint complexity: ["error", 300] */
import React from 'react';

import { connect } from 'react-redux';
import Select from 'react-select';

import SelectAsync from 'react-select/async';
import { Link, RouteComponentProps } from 'react-router-dom';

import { Button, Col, Row, Table, Label, UncontrolledCollapse, CardBody, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, div } from 'availity-reactstrap-validation';
import { TextFormat, getSortState, IPaginationBaseState, JhiItemCount } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { IRootState } from 'app/shared/reducers';
import { updateEntity, getUrlBack, getQuoteState, IQuoteBaseState, getEntityFiltersURL, getEntities, reset } from '../quote.reducer';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';

import { StatePropsExtended, DispatchPropsExtended, IExtendedState, IExtendedProps } from './quote';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';
import moment from 'moment';
import { getListAxios } from 'app/shared/util/entity-utils';
import { RenderModalSuperSelect } from 'app/shared/layout/components/RenderModalSuperSelect';
import { SuprimentoStatus } from 'app/shared/model/enumerations/suprimento-status.model';
import ModalFilterExportSuprimentos from 'app/entities/quote/extended/modal-filter-export-suprimentos';
import { SuprimentoPdfReport } from 'app/entities/quote/report/suprimentos';

registerLocale('pt', pt);

export interface IQuoteProps
  extends StateProps,
    DispatchProps,
    StatePropsExtended,
    DispatchPropsExtended,
    IExtendedProps,
    RouteComponentProps<{ url: string }> {}
export interface IQuoteStateAux extends IQuoteBaseState, IExtendedState {
  showModalReport?: boolean;
}

export interface IQuoteState extends IQuoteStateAux, IPaginationBaseState {}

export class Suprimento extends React.Component<IQuoteProps, IQuoteState> {
  protected myFormRef: any;

  constructor(props: IQuoteProps) {
    super(props);
    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      listCheckedID: [],
      isCheckedAll: false,
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      urlBack: getUrlBack(this.props.location),
      ...getQuoteState(this.props.location),
    };
  }

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({ listCheckedID: this.props.quoteList.map(quote => quote.id) });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({ listCheckedID: this.props.quoteList.map(quote => quote.id) });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  removeEntitySelected() {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  }

  saveEntityMany = () => {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  };

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  cancelFilters = () => {
    this.setState(
      {
        patientId: '',
        source: '',
        status: '',
        fromStart: '',
        fromEnd: '',
        toStart: '',
        toEnd: '',
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();
    const { patientId, source, fromStart, fromEnd, toStart, toEnd, activePage, itemsPerPage, sort, order } = this.state;
    const status = ['APROVADO'];
    this.props.getEntities(
      patientId,
      null,
      source,
      status,
      fromStart,
      fromEnd,
      toStart,
      toEnd,
      '',
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`
    );
  };

  updateEntity = entity => {
    const { patientId, source, status, fromStart, fromEnd, toStart, toEnd, activePage, itemsPerPage, sort, order } = this.state;
    this.props.updateEntity(entity, [
      patientId,
      null,
      source,
      status,
      fromStart,
      fromEnd,
      toStart,
      toEnd,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
    ]);
  };

  renderHeader() {
    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.suprimento.home.title">Quotes</Translate>
          </span>
          <Button id="togglerFilterQuote" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.quote.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>
          <ModalFilterExportSuprimentos setBaseState={v => this.setState(v)} />
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.suprimento.home.title">Quotes</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    return (
      <>
        <UncontrolledCollapse toggler="#togglerFilterQuote">
          <CardBody>
            <AvForm ref={el => (this.myFormRef = el)} id="form-filter" onSubmit={this.filterEntity}>
              <div className="row mt-1 ml-3 mr-3">
                {this.state.baseFilters !== 'patient' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="quote-patient">
                          <Translate contentKey="generadorApp.quote.patient">Patient</Translate>
                        </Label>
                        <SelectAsync
                          id="quote-patient"
                          isMulti
                          className={'css-select-control'}
                          name={'patient'}
                          cacheOptions
                          value={this.state.patientId}
                          onChange={options => this.setState({ patientId: options })}
                          defaultOptions={this.state.patientStartFilter ? this.state.patientStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.patientStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.patientStartFilter === undefined) {
                              const result = await getListAxios('patients', {}, 0, 100, 'name,asc', 'name');
                              this.setState({
                                patientStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: p['name'] ? p.name : '' }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios('patients', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                            callback(result.data ? result.data.map(p => ({ ...p, value: p.id, label: p['name'] ? p.name : '' })) : []);
                          }}
                        />
                        <RenderModalSuperSelect
                          baseState={this.state}
                          setState={_state => this.setState(_state)}
                          entity={'patient'}
                          listEntity={'patients'}
                          template={'name;client.socialReason;zipCode'.split(';')}
                          stateField={'patientId'}
                          multiple={true}
                          showFields={'name'.split(';')}
                          order={'socialReason,asc'}
                          filtersBase={{}}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'status' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <Label id="statusLabel" for="quote-status">
                        <Translate contentKey="generadorApp.quote.status">Status</Translate>
                      </Label>
                      <Select
                        id="quote-status"
                        className={'css-select-control'}
                        value={
                          this.state.status
                            ? { value: this.state.status, label: translate('generadorApp.QuoteStatus.' + this.state.status) }
                            : { value: '', label: translate('generadorApp.quote.status') }
                        }
                        options={[
                          { value: '', label: translate('generadorApp.quote.status') },
                          { value: 'ORCAMENTOABERTO', label: translate('generadorApp.QuoteStatus.ORCAMENTOABERTO') },
                          { value: 'AGUARDANDOPRECIFICACAO', label: translate('generadorApp.QuoteStatus.AGUARDANDOPRECIFICACAO') },
                          { value: 'ORCAMENTOPRECIFICADO', label: translate('generadorApp.QuoteStatus.ORCAMENTOPRECIFICADO') },
                          { value: 'AGUARDANDOAPROVACAO', label: translate('generadorApp.QuoteStatus.AGUARDANDOAPROVACAO') },
                          { value: 'PLANTAOAPROVADO', label: translate('generadorApp.QuoteStatus.PLANTAOAPROVADO') },
                          { value: 'APROVADO', label: translate('generadorApp.QuoteStatus.APROVADO') },
                          { value: 'INATIVOREPROVADOPRECO', label: translate('generadorApp.QuoteStatus.INATIVOREPROVADOPRECO') },
                          { value: 'INATIVOCONTROLEINTERNO', label: translate('generadorApp.QuoteStatus.INATIVOCONTROLEINTERNO') },
                        ]}
                        onChange={(options: any) => this.setState({ status: options['value'] })}
                        name="status"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'from' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <Label id="fromLabel" for="quote-from_start">
                        <Translate contentKey="generadorApp.quote.from_start">From (start)</Translate>
                      </Label>
                      <DatePicker
                        onChange={value => this.setState({ fromStart: value })}
                        id="birthDate"
                        className="form-control react-datepicker"
                        name="birthDate"
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.fromStart ? moment(this.state.fromStart).toDate() : null}
                        locale="pt"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'to' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <Label id="toLabel" for="quote-to_end">
                        <Translate contentKey="generadorApp.quote.to_end">To (end)</Translate>
                      </Label>
                      <DatePicker
                        onChange={value => this.setState({ toEnd: value })}
                        id="toLabel"
                        className="form-control react-datepicker"
                        name="toLabel"
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.toEnd ? moment(this.state.toEnd).toDate() : null}
                        locale="pt"
                      />
                    </Row>
                  </Col>
                ) : null}
              </div>

              <div className="row mb-2 mr-4 justify-content-end">
                <Button color="primary" type="submit">
                  <i className="fa fa-filter" aria-hidden={'true'}></i>&nbsp;
                  <Translate contentKey="generadorApp.quote.btnFilter">Filter</Translate>
                </Button>
                &nbsp;
                <Button color="secondary" onClick={this.cancelFilters}>
                  <FontAwesomeIcon icon="trash-alt" />
                  &nbsp;
                  <Translate contentKey="generadorApp.quote.btnCleanFilter">Clean Filter</Translate>
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </UncontrolledCollapse>
      </>
    );
  }

  renderTable() {
    const { quoteList, totalItems, match } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    const nQuoteList = this.props.quoteList;

    return (
      <>
        {nQuoteList && nQuoteList.length > 0 ? (
          <>
            <Table
              id="quote-table-list"
              responsive
              aria-describedby="quote-heading"
              className={'table-hover table-striped mt-4 table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.quote.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  <th>
                    <Translate contentKey="generadorApp.quote.client">Client</Translate>
                  </th>
                  <th>
                    <Translate contentKey="generadorApp.quote.patient">Patient</Translate>
                  </th>
                  <th className={'text-center'}>
                    <Translate contentKey="generadorApp.quote.period">Period</Translate>
                  </th>
                  <th>
                    <Translate contentKey="generadorApp.quote.itens">Itens</Translate>
                  </th>
                  <th className={'text-center'}>
                    <Translate contentKey="generadorApp.quote.itens-count">Itens Count</Translate>
                  </th>
                  <th className={'text-center'}>
                    <Translate contentKey="generadorApp.quote.itens-delivered">Itens Delivered</Translate>
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {nQuoteList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((quote, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.quote.field_id">ID</Translate>
                        </b>
                        {(quote.id + '').padStart(4, '0')}
                      </td>

                      <td id="client-cell">
                        <b className="visible-xs"> Client </b>
                        {quote.patient && quote.patient.client ? quote.patient.client.socialReason : ''}
                      </td>

                      <td id="patient-cell">
                        <b className="visible-xs"> Patient </b>
                        {quote.patient ? quote.patient.name : ''}
                      </td>
                      <td id="from-cell" className={'text-center'}>
                        {quote.from ? <TextFormat type="date" value={quote.from} format={APP_LOCAL_DATE_FORMAT} /> : '-'} <br />
                        {quote.to ? <TextFormat type="date" value={quote.to} format={APP_LOCAL_DATE_FORMAT} /> : '-'}
                      </td>
                      <td id="status-cell">
                        {quote &&
                          quote.quoteProductService &&
                          quote.quoteProductService.map((v, j) => {
                            return v.productService ? <div key={j}>{v.productService.name}</div> : <> </>;
                          })}
                      </td>
                      <td id="status-cell" className={'text-center'}>
                        {quote &&
                          quote.quoteProductService &&
                          quote.quoteProductService.map((v, j) => {
                            return v.productService ? <div key={j}>{v.quantity ? v.quantity : '-'}</div> : <> </>;
                          })}
                      </td>
                      <td id="status-cell" className={'text-center'}>
                        {quote &&
                          quote.quoteProductService &&
                          quote.quoteProductService.map((v, j) => {
                            return v.productService ? (
                              <div key={j}>
                                {v.suprimentoStatus === SuprimentoStatus.COMPLETO ? (
                                  <div style={{ padding: 0, margin: 0, textAlign: 'center' }} className="alert alert-success">
                                    {SuprimentoStatus.COMPLETO}
                                  </div>
                                ) : (
                                  <div style={{ padding: 0, margin: 0, textAlign: 'center' }} className="alert alert-danger">
                                    {SuprimentoStatus.INCOMPLETO}
                                  </div>
                                )}
                              </div>
                            ) : (
                              <> </>
                            );
                          })}
                      </td>

                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          <Button tag={Link} to={`${match.url}/${quote.id}`} color="info" size="sm">
                            <FontAwesomeIcon icon="pencil-alt" />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.quote.home.notFound">No Quotes found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const { quoteList, totalItems } = this.props;

    return (
      <>
        <div className={quoteList && quoteList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    return (
      <>
        {this.renderModal()}
        <Panel>
          <PanelHeader>{this.renderHeader()}</PanelHeader>

          <PanelBody>
            <div className="table-responsive">
              {this.renderFilter()}

              {this.renderTable()}
            </div>
          </PanelBody>
          <PanelFooter>{this.renderFooter()}</PanelFooter>
        </Panel>
      </>
    );
  }

  renderModal() {
    const report: any = this.state.report;
    if (!report || this.state.showModalReport !== true) return <> </>;

    return (
      <Modal size="xl" isOpen toggle={() => this.setState({ showModalReport: false })}>
        <ModalBody id="generadorApp.quote.pdf.generate">
          <SuprimentoPdfReport data={report.data} from={this.state.fromStart} to={this.state.toEnd} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => this.setState({ showModalReport: false })}>
            <FontAwesomeIcon icon="ban" />
            &nbsp;
            <Translate contentKey="generadorApp.quote.btnCancel">Close</Translate>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export const mapStateToProps = ({ quote, ...storeState }: IRootState) => {
  return {
    quoteDiets: storeState.quoteDiet.entities,
    quoteMedicines: storeState.quoteMedicine.entities,
    quoteMaterials: storeState.quoteMaterial.entities,
    quoteProductServices: storeState.quoteProductService.entities,
    quoteRhs: storeState.quoteRh.entities,
    quoteFiles: storeState.quoteFile.entities,
    expectedResults: storeState.expectedResult.entities,
    pads: storeState.pad.entities,
    supplies: storeState.supply.entities,
    patients: storeState.patient.entities,
    clComTables: storeState.clComTable.entities,
    clients: storeState.client.entities,
    quoteList: quote.entities,
    totalItems: quote.totalItems,
    loading: quote.loading,
  };
};

export const mapDispatchToProps = {
  getEntities,

  updateEntity,
  reset,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Suprimento);
