import { IPad } from 'app/shared/model/pad.model';
import React from 'react';

export interface IPadProps {
  baseState?: any;
  baseProps?: any;
  setState?: any;
  entityBase?: IPad;
}

const PadComponentCustomize = (props: IPadProps) => {
  const quoteRhList = props.entityBase && props.entityBase.quote && props.entityBase.quote.quoteRh ? props.entityBase.quote.quoteRh : [];
  const padAttendanceList = props.entityBase.padAttendance;
  const padAttendanceListPlanned = padAttendanceList
    ? padAttendanceList.filter(v => (typeof v.deletedAt === 'undefined' || v.deletedAt === null) && v.date !== null)
    : [];

  const totais = quoteRhList.map(v => padAttendanceListPlanned.filter(v1 => v1.quoteRh && v1.quoteRh.id === v.id).length);
  return (
    <>
      {totais.map((v, j) => (
        <div key={j}>{v}</div>
      ))}
    </>
  );
};

const mapStateToProps = storeState => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default PadComponentCustomize;
