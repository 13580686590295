import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { IProfessional } from 'app/shared/model/professional.model';
import { StatusProfessional } from 'app/shared/model/enumerations/status-professional.model';

export interface IProfessionalStatusHistory {
  id?: number;
  status?: StatusProfessional;
  observation?: string;
  professional?: IProfessional;
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  professional: {
    isMulti: true, // many-to-one - other-side
    entity: 'Professional',
    urlParameters: {
      enpoint: 'professionals',
      filters: 'professionalStatusHistory.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'professionalStatusHistory.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'professionalStatusHistory.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<IProfessionalStatusHistory> = {};
