import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { IMedicineMaterial } from 'app/shared/model/medicine-material.model';

export interface IBrasindice {
  id?: number;
  codeLab?: string;
  nameLab?: string;
  code?: string;
  name?: string;
  codeApre?: string;
  nameApre?: string;
  price?: string;
  quantFrac?: number;
  priceType?: string;
  valueFrac?: number;
  ipi?: number;
  flagPort?: string;
  codeEan?: string;
  codeTiss?: string;
  flagGen?: string;
  codeTuss?: string;
  codeHier?: string;
  brasindiceType?: string;
  pcEmFab?: number;
  pcFrFab?: number;
  pcEmVen?: number;
  pcFrVen?: number;
  status?: boolean;
  medicineMaterial?: IMedicineMaterial[];
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  medicineMaterial: {
    isMulti: false, // one-to-many - other-side
    entity: 'MedicineMaterial',
    urlParameters: {
      enpoint: 'medicine-materials',
      filters: 'brasindice.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'brasindice.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'brasindice.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<IBrasindice> = {
  status: false,
};
