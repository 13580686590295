import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import PadAttendanceFile from './extended/pad-attendance-file';

import PadAttendanceFileUpdate from './pad-attendance-file';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={PadAttendanceFileUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={PadAttendanceFileUpdate} />
      <ErrorBoundaryRoute path={match.path} component={PadAttendanceFile} />
    </Switch>
  </>
);

export default Routes;
