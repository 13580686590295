import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';
import moment from 'moment';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity, IBillToPayBaseState, getUrlBack, getBillToPayState, getEntityFiltersURL } from './bill-to-pay.reducer';

import { APP_DATE_FORMAT } from 'app/config/constants';

export interface IBillToPayState {
  fieldsBase: IBillToPayBaseState;
}

export interface IBillToPayDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class BillToPayDetail extends React.Component<IBillToPayDetailProps, IBillToPayState> {
  constructor(props: Readonly<IBillToPayDetailProps>) {
    super(props);
    this.state = {
      ...this.state,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getBillToPayState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
    };
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = '_back=1' + (offset !== null ? '&offset=' + offset : '');
    Object.keys(fieldsBase).map(key => {
      url += '&' + key + '=' + fieldsBase[key];
    });
    return url;
  };

  UNSAFE_componentWillMount() {
    this.props.getEntity(this.props.match.params['id']);
  }

  renderHeader() {
    const { billToPayEntity } = this.props;
    const state = this.state;
    return (
      <>
        <div id="page-heading">
          {' '}
          <h2>
            <Translate contentKey="generadorApp.billToPay.detail.title">BillToPay</Translate>
            <small>&nbsp; {billToPayEntity['createdBy'] ? billToPayEntity['createdBy']['login'] : ''}</small>
          </h2>
        </div>

        <div className="pull-right">
          <Button tag={Link} to={'/bill-to-pay?' + getEntityFiltersURL(this.state.fieldsBase)} replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.billToPay.btnBack">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/bill-to-pay/${billToPayEntity.id}/edit?+${getEntityFiltersURL(this.state.fieldsBase)}`}
            replace
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.billToPay.btnEdit">Edit</Translate>
            </span>
          </Button>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>

          <li className="breadcrumb-item active">
            <Link to={'/bill-to-pay'}>Billing Xmls</Link>
          </li>
          <li className="breadcrumb-item active">Billing Xmls details</li>
        </ol>
      </>
    );
  }

  renderBody() {
    const { billToPayEntity } = this.props;
    const state = this.state;
    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            <Row className="jh-entity-details">
              <Col className="view-detail-row bill-to-pay-type-detail" md="12">
                <Row>
                  <Col className="view-detail-label-left bill-to-pay-type-detail-label" md="3">
                    <dt>
                      <span id="type">
                        <Translate contentKey="generadorApp.billToPay.type">Type</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left bill-to-pay-type-detail-value" md="9">
                    <dd>{translate('generadorApp.BillsPayType.' + (billToPayEntity.type ? billToPayEntity.type : 'FIXO'))}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row bill-to-pay-name-detail" md="12">
                <Row>
                  <Col className="view-detail-label-left bill-to-pay-name-detail-label" md="3">
                    <dt>
                      <span id="name">
                        <Translate contentKey="generadorApp.billToPay.name">Name</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left bill-to-pay-name-detail-value" md="9">
                    <dd>{billToPayEntity.name}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row bill-to-pay-description-detail" md="12">
                <Row>
                  <Col className="view-detail-label-left bill-to-pay-description-detail-label" md="3">
                    <dt>
                      <span id="description">
                        <Translate contentKey="generadorApp.billToPay.description">Description</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left bill-to-pay-description-detail-value" md="9">
                    <dd>{billToPayEntity.description}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row bill-to-pay-billValue-detail" md="12">
                <Row>
                  <Col className="view-detail-label-left bill-to-pay-billValue-detail-label" md="3">
                    <dt>
                      <span id="billValue">
                        <Translate contentKey="generadorApp.billToPay.billValue">Value</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left bill-to-pay-billValue-detail-value" md="9">
                    <dd>{billToPayEntity.billValue}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row bill-to-pay-paymentDay-detail" md="12">
                <Row>
                  <Col className="view-detail-label-left bill-to-pay-paymentDay-detail-label" md="3">
                    <dt>
                      <span id="paymentDay">
                        <Translate contentKey="generadorApp.billToPay.paymentDay">Payment day</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left bill-to-pay-paymentDay-detail-value" md="9">
                    <dd>{billToPayEntity.paymentDay}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row bill-to-pay-paymentDate-detail" md="12">
                <Row>
                  <Col className="view-detail-label-left bill-to-pay-paymentDate-detail-label" md="3">
                    <dt>
                      <span id="paymentDate">
                        <Translate contentKey="generadorApp.billToPay.paymentDate">Payment date</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left bill-to-pay-paymentDate-detail-value" md="9">
                    <dd>{billToPayEntity.paymentDate ? moment(billToPayEntity.paymentDate).format(APP_DATE_FORMAT) : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row bill-to-pay-variableCost-detail" md="12">
                <Row>
                  <Col className="view-detail-label-left bill-to-pay-variableCost-detail-label" md="3">
                    <dt>
                      <Translate contentKey="generadorApp.billToPay.variableCost">Variable Cost</Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left bill-to-pay-variableCost-detail-value" md="9">
                    <dd>{billToPayEntity.variableCost ? billToPayEntity.variableCost.id : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row bill-to-pay-fixedCost-detail" md="12">
                <Row>
                  <Col className="view-detail-label-left bill-to-pay-fixedCost-detail-label" md="3">
                    <dt>
                      <Translate contentKey="generadorApp.billToPay.fixedCost">Fixed Cost</Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left bill-to-pay-fixedCost-detail-value" md="9">
                    <dd>{billToPayEntity.fixedCost ? billToPayEntity.fixedCost.id : ''}</dd>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { billToPayEntity } = this.props;
    const state = this.state;
    return (
      <div>
        <Panel className="bill-to-pay bill-to-pay-detail">
          <PanelHeader>{this.renderHeader()}</PanelHeader>

          <PanelBody>{this.renderBody()}</PanelBody>
        </Panel>
      </div>
    );
  }
}

export const mapStateToProps = ({ billToPay, ...storeState }: IRootState) => ({
  billToPayEntity: billToPay.entity,
});

export const mapDispatchToProps = {
  getEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(BillToPayDetail);
