/* eslint complexity: ["error", 500] */
import React from 'react';

import { getFilterFromSelect, getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import { connect } from 'react-redux';

import SelectAsync from 'react-select/async';
import { Link, RouteComponentProps } from 'react-router-dom';

import { Button, Col, Row, Table, Label, UncontrolledCollapse, CardBody } from 'reactstrap';
import { AvForm, div, AvInput } from 'availity-reactstrap-validation';
import { JhiItemCount } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { IRootState } from 'app/shared/reducers';
import {
  updateEntity,
  deleteEntity,
  getUrlBack,
  getPatientDoctorPrescriptionState,
  IPatientDoctorPrescriptionBaseState,
  getEntityFiltersURL,
  getEntities,
  reset,
} from './patient-doctor-prescription.reducer';

import { ITEMS_PER_PAGE, IPaginationBaseState, getSortState } from 'app/shared/util/pagination.constants';

import { StatePropsExtended, DispatchPropsExtended, IExtendedState, IExtendedProps } from './extended/patient-doctor-prescription';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

export interface IPatientDoctorPrescriptionProps
  extends StateProps,
    DispatchProps,
    StatePropsExtended,
    DispatchPropsExtended,
    IExtendedProps,
    RouteComponentProps<{ url: string }> {}
export interface IPatientDoctorPrescriptionStateAux extends IPatientDoctorPrescriptionBaseState, IExtendedState {}

export interface IPatientDoctorPrescriptionState extends IPatientDoctorPrescriptionStateAux, IPaginationBaseState {}

export class PatientDoctorPrescription extends React.Component<IPatientDoctorPrescriptionProps, IPatientDoctorPrescriptionState> {
  protected myFormRef: any;

  constructor(props: IPatientDoctorPrescriptionProps) {
    super(props);
    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      listCheckedID: [],
      isCheckedAll: false,
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      urlBack: getUrlBack(this.props.location),
      ...getPatientDoctorPrescriptionState(this.props.location),
      patientId: [{ value: this.props.match.params['idPatient'], label: this.props.match.params['idPatient'] }],
      baseFilters: 'patient',
    };
  }

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({
      listCheckedID: this.props.patientDoctorPrescriptionList.map(patientDoctorPrescription => patientDoctorPrescription.id),
    });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({
        listCheckedID: this.props.patientDoctorPrescriptionList.map(patientDoctorPrescription => patientDoctorPrescription.id),
      });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  removeEntitySelected() {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  }

  saveEntityMany = () => {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  };

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  deleteEntity(patientDoctorPrescriptionEntity) {
    MySwal.fire({
      title: <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>,
      html: (
        <Translate
          contentKey="generadorApp.patientDoctorPrescription.delete.question"
          interpolate={{ id: patientDoctorPrescriptionEntity.id }}
        >
          Are you sure you want to delete this PatientDoctorPrescription?
        </Translate>
      ),
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#e70015',
      cancelButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="ban" />
          &nbsp; <Translate contentKey="generadorApp.patientDoctorPrescription.btnCancel">Cancel</Translate>
        </>
      ),
      confirmButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="trash" />
          &nbsp;<Translate contentKey="generadorApp.patientDoctorPrescription.btnDelete">Delete</Translate>
        </>
      ),
    }).then(async response => {
      if (response.isConfirmed) {
        await this.props.deleteEntity(patientDoctorPrescriptionEntity.id, { reloadList: false });
        this.getEntities();
        MySwal.fire({
          title: <Translate contentKey="entity.delete.title.confirmed">Confirmed delete operation</Translate>,
          icon: 'success',
        });
      }
    });
  }

  cancelFilters = () => {
    this.setState(
      {
        observation: '',
        patientId: '',
        extraFilters: {},
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
        activePage: 0,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();

    const selectFieldsList = ['observation', 'patient.'];

    const { observation, patientId, extraFilters, activePage, itemsPerPage, sort, order } = this.state;
    return this.props.getEntities(
      observation,
      getFilterFromSelect(patientId, 'one-to-one'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(',')
    );
  };

  updateEntity = entity => {
    const selectFieldsList = ['observation', 'patient.'];

    const { observation, patientId, extraFilters, activePage, itemsPerPage, sort, order } = this.state;
    this.props.updateEntity(entity, [
      observation,
      getFilterFromSelect(patientId, 'one-to-one'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(','),
    ]);
  };

  renderHeader() {
    const { patDrPrescrProcds, patients, patientDoctorPrescriptionList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.patientDoctorPrescription.home.title">Patient doctor prescriptions</Translate>
          </span>
          <Button id="togglerFilterPatientDoctorPrescription" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.patientDoctorPrescription.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          &nbsp;
          <Link
            to={`${match.url}/new?${getEntityFiltersURL(state)}`}
            className="btn btn-primary float-right jh-create-entity"
            id="jh-create-entity"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="generadorApp.patientDoctorPrescription.btnNewItem">Create new</Translate>
          </Link>{' '}
          &nbsp;
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/patient'}>Patients</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/patient/' + this.props.match.params['idPatient']}>{this.props.match.params['idPatient']}</Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.patientDoctorPrescription.home.title">Patient doctor prescriptions</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    const { patDrPrescrProcds, patients, patientDoctorPrescriptionList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <UncontrolledCollapse toggler="#togglerFilterPatientDoctorPrescription">
          <CardBody>
            <AvForm ref={el => (this.myFormRef = el)} id="form-filter" onSubmit={this.filterEntity}>
              <div className="row mt-1 ml-3 mr-3">
                {this.state.baseFilters !== 'observation' ? (
                  <Col md="3" className="col-filter-patient-doctor-prescription-observation">
                    <Row className="mr-1 mt-1">
                      <Label id="observationLabel" for="patient-doctor-prescription-observation">
                        <Translate contentKey="generadorApp.patientDoctorPrescription.observation">Observations</Translate>
                      </Label>

                      <AvInput type="text" name="observation" id="patient-doctor-prescription-observation" value={this.state.observation} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'patient' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="patient-doctor-prescription-patient">
                          <Translate contentKey="generadorApp.patientDoctorPrescription.patient">Patient</Translate>
                        </Label>
                        <SelectAsync
                          id="patient-doctor-prescription-patient"
                          isMulti
                          className={'css-select-control'}
                          name={'patient'}
                          cacheOptions
                          value={this.state.patientId}
                          onChange={options => this.setState({ patientId: options })}
                          defaultOptions={this.state.patientStartFilter ? this.state.patientStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.patientStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.patientStartFilter === undefined) {
                              const result = await getListAxios('patients', {}, 0, 100, 'id,asc', 'id');
                              this.setState({
                                patientStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios('patients', { 'id.contains': inputValue }, 0, 100, 'id,asc', 'id');
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}
              </div>

              <div className="row mb-2 mr-4 justify-content-end">
                <Button color="primary" type="submit">
                  <i className="fa fa-filter" aria-hidden={'true'}></i>&nbsp;
                  <Translate contentKey="generadorApp.patientDoctorPrescription.btnFilter">Filter</Translate>
                </Button>
                &nbsp;
                <Button color="secondary" onClick={this.cancelFilters}>
                  <FontAwesomeIcon icon="trash-alt" />
                  &nbsp;
                  <Translate contentKey="generadorApp.patientDoctorPrescription.btnCleanFilter">Clean Filter</Translate>
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </UncontrolledCollapse>
      </>
    );
  }

  renderTable() {
    const { patDrPrescrProcds, patients, patientDoctorPrescriptionList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {patientDoctorPrescriptionList &&
        patientDoctorPrescriptionList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null).length > 0 ? (
          <>
            <Table
              id="patient-doctor-prescription-table-list"
              responsive
              aria-describedby="patient-doctor-prescription-heading"
              className={'table-hover table-striped mt-4 table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.patientDoctorPrescription.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'observation' ? (
                    <th className="hand" onClick={sortFunction('observation')}>
                      <Translate contentKey="generadorApp.patientDoctorPrescription.observation">Observations</Translate>
                      <FontAwesomeIcon icon={state.sort === 'observation' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'patient' ? (
                    <th>
                      <Translate contentKey="generadorApp.patientDoctorPrescription.patient">Patient</Translate>
                    </th>
                  ) : null}

                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {patientDoctorPrescriptionList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((patientDoctorPrescription, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.patientDoctorPrescription.field_id">ID</Translate>
                        </b>
                        {patientDoctorPrescription.id}
                      </td>

                      {state.baseFilters !== 'observation' ? (
                        <td id="observation-cell">
                          <b className="visible-xs"> Observations </b>

                          {patientDoctorPrescription.observation}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'patient' ? (
                        <td id="patient-cell">
                          <b className="visible-xs"> Patient </b>
                          {showFieldsSelectAsync(patientDoctorPrescription, 'patient.id', null).map((v, ikey) => (
                            <span key={ikey}> {v} </span>
                          ))}
                        </td>
                      ) : null}

                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          <Button
                            tag={Link}
                            to={`${match.url}/${patientDoctorPrescription.id}?${getEntityFiltersURL(
                              state,
                              this.state.itemsPerPage * this.state.activePage + i
                            )}`}
                            color="info"
                            size="sm"
                          >
                            <FontAwesomeIcon icon="eye" />{' '}
                            <span className="d-none d-md-inline">
                              <Translate contentKey="generadorApp.patientDoctorPrescription.btnView">View</Translate>
                            </span>
                          </Button>
                          <Button
                            tag={Link}
                            to={`${match.url}/${patientDoctorPrescription.id}/edit?${getEntityFiltersURL(
                              state,
                              this.state.itemsPerPage * this.state.activePage + i
                            )}`}
                            color="primary"
                            size="sm"
                          >
                            <FontAwesomeIcon icon="pencil-alt" />{' '}
                            <span className="d-none d-md-inline">
                              <Translate contentKey="generadorApp.patientDoctorPrescription.btnEdit">Edit</Translate>
                            </span>
                          </Button>
                          <Button onClick={() => this.deleteEntity(patientDoctorPrescription)} color="danger" size="sm">
                            <FontAwesomeIcon icon="trash" />{' '}
                            <span className="d-none d-md-inline">
                              <Translate contentKey="generadorApp.patientDoctorPrescription.btnDelete">Delete</Translate>
                            </span>
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.patientDoctorPrescription.home.notFound">No Patient doctor prescriptions found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const { patDrPrescrProcds, patients, patientDoctorPrescriptionList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={patientDoctorPrescriptionList && patientDoctorPrescriptionList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const { patDrPrescrProcds, patients, patientDoctorPrescriptionList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel className="patient-doctor-prescription patient-doctor-prescription-list-table">
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToProps = ({ ...storeState }: IRootState) => {
  return {
    patDrPrescrProcds: storeState.patDrPrescrProcd.entities,
    patients: storeState.patient.entities,
    patientDoctorPrescriptionList: storeState.patientDoctorPrescription.entities,
    totalItems: storeState.patientDoctorPrescription.totalItems,
    userAccount: storeState.authentication.account,
    loading: storeState.patientDoctorPrescription.loading,
  };
};

export const mapDispatchToProps = {
  getEntities,

  deleteEntity,
  updateEntity,
  reset,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PatientDoctorPrescription);
