import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import PatNrPrescProcediment from './extended/pat-nr-presc-procediment';

import PatNrPrescProcedimentDetail from './pat-nr-presc-procediment-detail';

import PatNrPrescProcedimentUpdate from './pat-nr-presc-procediment';

import NrStatusHistory from '../nr-status-history/index';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute path={`${match.path}/:idPatNrPrescProcediment/nr-status-history`} component={NrStatusHistory} />

      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={PatNrPrescProcedimentUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={PatNrPrescProcedimentUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={PatNrPrescProcedimentDetail} />
      <ErrorBoundaryRoute path={match.path} component={PatNrPrescProcediment} />
    </Switch>

    <Switch>
      {/* <ErrorBoundaryRoute path={`${match.path}/:idPatNrPrescProcediment/nr-status-history`} component={NrStatusHistory} /> */}
    </Switch>
  </>
);

export default Routes;
