/* eslint complexity: ["error", 500] */
/* eslint no-empty: off */
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import SelectAsync from 'react-select/async';

import { Link, RouteComponentProps } from 'react-router-dom';

import { getFilterFromSelect, getFormFromSelect, getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Col, Label } from 'reactstrap';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import {
  IPadUpdateState as IBaseUpdateState,
  getEntity,
  getPadState,
  getUrlBack,
  updateEntity,
  createEntity,
  getEntityFiltersURL,
  getEntityOffset,
  reset,
  padCidPtaSetEntities,
  padCidPtaGetEntities,
  padCidPtaUpdateEntity,
  padCidPtaReset,
  padCidPtaGetEntity,
  padCidPtaCreateEntity,
  padCidPtaDeleteEntity,
} from './pad.reducer';

import { selectFieldsList } from './extended/extendSelectFields';

import {
  mapStateToPropsExtended as mapStateToPropsPadCidPta,
  mapDispatchToPropsExtended as mapDispatchToPropsPadCidPta,
  PadCidPtaExtended as PadCidPta,
} from 'app/entities/pad-cid-pta/extended/pad-cid-pta';

import { StatePropsExtended, DispatchPropsExtended, IUpdateExtendedState } from './extended/pad-update';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';
import moment from 'moment';
registerLocale('pt', pt);

export interface IPadUpdateProps
  extends StateProps,
    DispatchProps,
    StatePropsExtended,
    DispatchPropsExtended,
    RouteComponentProps<{ url: string }> {}
export interface IPadUpdateState extends IBaseUpdateState, IUpdateExtendedState {}

export class PadUpdate extends React.Component<IPadUpdateProps, IPadUpdateState> {
  initDateFileInput: any;

  finalDateFileInput: any;

  statusFileInput: any;
  constructor(props: Readonly<IPadUpdateProps>) {
    super(props);

    this.padCidPtaUpdateEntity = this.padCidPtaUpdateEntity.bind(this);
    this.padCidPtaGetEntities = this.padCidPtaGetEntities.bind(this);
    this.padCidPtaReset = this.padCidPtaReset.bind(this);
    this.padCidPtaGetEntity = this.padCidPtaGetEntity.bind(this);
    this.padCidPtaCreateEntity = this.padCidPtaCreateEntity.bind(this);
    this.padCidPtaDeleteEntity = this.padCidPtaDeleteEntity.bind(this);

    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      functionEmbebed: [],

      padCidPtaSelectValue: null,

      padAttendanceSelectValue: null,

      patientSelectValue: null,

      quoteSelectValue: null,

      initDateSelectValue: null,
      finalDateSelectValue: null,
      statusSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getPadState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
      patientName: null,
      quoteId: null,

      isNew: !this.props.match.params || !this.props.match.params['id'],
    };
  }

  padCidPtaUpdateEntity(...values) {
    values[0]['pad'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['updateEntityPadCidPta'](...values);
    }
    return this.props.padCidPtaUpdateEntity(...values);
  }
  padCidPtaGetEntities(...values) {
    return this.props.padCidPtaGetEntities(...values);
  }
  padCidPtaReset(...values) {
    return this.props.padCidPtaReset(...values);
  }
  padCidPtaGetEntity(...values) {
    return this.props.padCidPtaGetEntity(...values);
  }
  padCidPtaCreateEntity(...values) {
    values[0]['pad'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['createEntityPadCidPta'](...values);
    }
    return this.props.padCidPtaCreateEntity(...values);
  }
  padCidPtaDeleteEntity(...values) {
    if (!this.state.isNew) {
      this.props['deleteEntityPadCidPta'](...values);
    }
    return this.props.padCidPtaDeleteEntity(...values);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateSuccess !== this.props.updateSuccess && prevProps.updateSuccess) {
      this.handleClose();
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.padEntity && prevProps.padEntity.id !== this.props.padEntity.id) ||
      (this.props.padCidPtas.length > 0 && this.state.padCidPtaSelectValue === null && this.props.padEntity.padCidPta)
    ) {
      this.setState({ padCidPtaSelectValue: this.props.padEntity.padCidPta });
      this.props.padCidPtaSetEntities(this.props.padEntity.padCidPta);
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.padEntity && prevProps.padEntity.id !== this.props.padEntity.id) ||
      (this.props.padAttendances.length > 0 && this.state.padAttendanceSelectValue === null && this.props.padEntity.padAttendance)
    ) {
      this.setState({
        padAttendanceSelectValue:
          this.props.padEntity && this.props.padEntity.padAttendance
            ? this.props.padEntity.padAttendance.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.padEntity && prevProps.padEntity.id !== this.props.padEntity.id) ||
      (this.props.patients && this.state.patientSelectValue === null && this.props.padEntity.patient && this.props.padEntity.patient.id)
    ) {
      const p = this.props.padEntity && this.props.padEntity.patient ? this.props.padEntity.patient : null;
      this.setState({
        patientSelectValue: p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : null,
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.padEntity && prevProps.padEntity.id !== this.props.padEntity.id) ||
      (this.props.quotes && this.state.quoteSelectValue === null && this.props.padEntity.quote && this.props.padEntity.quote.id)
    ) {
      const p = this.props.padEntity && this.props.padEntity.quote ? this.props.padEntity.quote : null;
      this.setState({
        quoteSelectValue: p ? { ...p, value: p.id, label: p['id'] ? p.id : '' } : null,
      });
    }

    if (
      (prevProps.padEntity && prevProps.padEntity.id !== this.props.padEntity.id) ||
      (this.props.padEntity.initDate &&
        this.state.initDateSelectValue === null &&
        this.props.padEntity.initDate !== this.state.initDateSelectValue)
    ) {
      this.setState({ initDateSelectValue: this.props.padEntity.initDate ? this.props.padEntity.initDate : null });
    }
    if (
      (prevProps.padEntity && prevProps.padEntity.id !== this.props.padEntity.id) ||
      (this.props.padEntity.finalDate &&
        this.state.finalDateSelectValue === null &&
        this.props.padEntity.finalDate !== this.state.finalDateSelectValue)
    ) {
      this.setState({ finalDateSelectValue: this.props.padEntity.finalDate ? this.props.padEntity.finalDate : null });
    }
    if (
      (prevProps.padEntity && prevProps.padEntity.id !== this.props.padEntity.id) ||
      (this.props.padEntity.status && this.state.statusSelectValue === null && this.props.padEntity.status !== this.state.statusSelectValue)
    ) {
      this.setState({ statusSelectValue: this.props.padEntity.status ? this.props.padEntity.status : null });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  UNSAFE_componentWillMount() {
    if (this.state.isNew) {
      this.props.reset();
      this.padCidPtaReset();
    } else {
      this.props.getEntity(this.props.match.params['id']);
    }
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = offset !== null ? '&offset=' + offset : '';
    Object.keys(fieldsBase).map(key => {
      url += key !== 'offset' ? '&' + key + '=' + fieldsBase[key] : '';
    });
    return url;
  };
  saveEntity = (event: any, errors: any, values: any) => {
    if (errors.length === 0) {
      const { padEntity } = this.props;
      const _entity = {
        ...padEntity,
        // ...values,

        patient: getFormFromSelect(this.state.patientSelectValue, 'many-to-one'), // many-to-one - otherSide

        quote: getFormFromSelect(this.state.quoteSelectValue, 'many-to-one'), // many-to-one - otherSide

        padCidPta: undefined,

        initDate: this.state.initDateSelectValue,
        finalDate: this.state.finalDateSelectValue,
        status: this.state.statusSelectValue === null ? null : this.state.statusSelectValue,
      };

      const entity = _entity;

      const {
        id,
        initDateStart,
        initDateEnd,
        finalDateStart,
        finalDateEnd,
        status,
        patientId,
        quoteId,
        padCidPtaId,
        extraFilters,
        activePage,
        itemsPerPage,
        sort,
        order,
      } = this.state.fieldsBase;

      const padCidPtaListAllEmbed = this.props.padCidPtaListAllEmbed;

      if (this.state.isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              id,
              initDateStart,
              initDateEnd,
              finalDateStart,
              finalDateEnd,
              status,
              getFilterFromSelect(patientId, 'many-to-one'),
              getFilterFromSelect(quoteId, 'many-to-one'),
              getFilterFromSelect(padCidPtaId, 'one-to-many'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
          if (idEntity) {
            padCidPtaListAllEmbed.map(v => {
              v['id'] = null;
              v['pad'] = { id: idEntity };
              this.props['createEntityPadCidPta'](v);
            });
          }
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              id,
              initDateStart,
              initDateEnd,
              finalDateStart,
              finalDateEnd,
              status,
              getFilterFromSelect(patientId, 'many-to-one'),
              getFilterFromSelect(quoteId, 'many-to-one'),
              getFilterFromSelect(padCidPtaId, 'one-to-many'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/pad?' + getEntityFiltersURL(this.state.fieldsBase));
  };

  getFullEntityFromState(v) {
    return {
      id: v.id,
      initDate: this.state.initDateSelectValue,
      finalDate: this.state.finalDateSelectValue,
      status: this.state.statusSelectValue,
      padCidPta: this.state.padCidPtaSelectValue,
      padAttendance: this.state.padAttendanceSelectValue,
      patient: this.state.patientSelectValue,
      quote: this.state.quoteSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    const padCidPtaEntity = v.padCidPta;

    const padAttendanceEntity = v.padAttendance;

    const patientEntity = v.patient;

    const quoteEntity = v.quote;

    return {
      initDateSelectValue: v.initDate,
      finalDateSelectValue: v.finalDate,
      statusSelectValue: v.status,
      padCidPtaSelectValue: padCidPtaEntity ? padCidPtaEntity['id'] : null,
      padAttendanceSelectValue: padAttendanceEntity ? padAttendanceEntity['id'] : null,
      patientSelectValue: patientEntity ? patientEntity['id'] : null,
      quoteSelectValue: quoteEntity ? quoteEntity['id'] : null,
    };
  }

  renderHeader() {
    const { padEntity, padCidPtas, padAttendances, patients, quotes, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.pad.home.createLabel">Create a Pad</Translate>
            ) : (
              <Translate contentKey="generadorApp.pad.home.editLabel">Edit a Pad</Translate>
            )}
          </span>

          <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.pad.btnSave">Save</Translate>
          </Button>

          {!this.state.isNew ? (
            <>
              <Button
                color="primary"
                id="next-entity"
                disabled={updating}
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.id,
                    this.state.fieldsBase.initDateStart,
                    this.state.fieldsBase.initDateEnd,
                    this.state.fieldsBase.finalDateStart,
                    this.state.fieldsBase.finalDateEnd,
                    this.state.fieldsBase.status,
                    this.state.fieldsBase.patientId,
                    this.state.fieldsBase.quoteId,
                    this.state.fieldsBase.padCidPtaId,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 + 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 + 1 },
                    });
                    this.props.history.push(
                      '/pad/' + myresult.value.data[0]['id'] + '/edit?' + this.getFiltersURL(this.state.fieldsBase.offset * 1 + 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.last.item', { fieldName: translate('generadorApp.pad.detail.title') }));
                  }
                }}
                className="float-right jh-create-entity"
              >
                {'>>'}
              </Button>

              <Button
                color="primary"
                id="prev-entity"
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.id,
                    this.state.fieldsBase.initDateStart,
                    this.state.fieldsBase.initDateEnd,
                    this.state.fieldsBase.finalDateStart,
                    this.state.fieldsBase.finalDateEnd,
                    this.state.fieldsBase.status,
                    this.state.fieldsBase.patientId,
                    this.state.fieldsBase.quoteId,
                    this.state.fieldsBase.padCidPtaId,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 - 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 - 1 },
                    });
                    this.props.history.push(
                      '/pad/' + myresult.value.data[0]['id'] + '/edit?' + this.getFiltersURL(this.state.fieldsBase.offset * 1 - 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.first.item', { fieldName: translate('generadorApp.pad.detail.title') }));
                  }
                }}
                disabled={updating}
                className="float-right jh-create-entity"
              >
                {'<<'}
              </Button>
            </>
          ) : (
            <> </>
          )}

          <Button
            tag={Link}
            id="cancel-save"
            to={'/pad?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.pad.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={'/pad'}>
              <Translate contentKey="generadorApp.pad.home.title">PAD</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.pad.detail.title">PAD edit</Translate>
          </li>
        </ol>
      </>
    );
  }
  renderBody() {
    const { padEntity, padCidPtas, padAttendances, patients, quotes, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const fullEntityEmbebed = this.getFullEntityFromState(padEntity);

    const mapDispatchToPropsPadCidPtaList: any = {};
    Object.keys(mapDispatchToPropsPadCidPta).map(v => {
      mapDispatchToPropsPadCidPtaList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'PadCidPta')) {
          this.setState({ functionEmbebed: [...this.state.functionEmbebed, v + 'PadCidPta'] }, this.props[v + 'PadCidPta']);
        }
      };
    });

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="pad-id">
                  <Translate contentKey="generadorApp.pad.field_id">ID</Translate>
                </Label>
                </Col> */}
                      <Col md="12">
                        <AvInput id="pad-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'patient' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="pad-patient">
                              <Translate contentKey="generadorApp.pad.patient">Patient</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="pad-patient"
                              name={'patient'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.patientSelectValue ? this.state.patientSelectValue : ''}
                              onChange={options => this.setState({ patientSelectValue: options })}
                              defaultOptions={this.state.patientStartSelectOptions ? this.state.patientStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.patientStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.patientStartSelectOptions === undefined) {
                                  const result = await getListAxios('patients', {}, 0, 100, 'name,asc', 'name');
                                  this.setState({
                                    patientStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios('patients', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="patient"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}
                  {baseFilters !== 'status' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="statusLabel" for="pad-status">
                              <Translate contentKey="generadorApp.pad.status">Status</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="pad-status"
                              className={'css-select-control'}
                              value={
                                this.state.statusSelectValue
                                  ? {
                                      value: this.state.statusSelectValue,
                                      label: translate('generadorApp.PadStatus.' + this.state.statusSelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.pad.status') }
                              }
                              options={[
                                { value: 'COMPLETO', label: translate('generadorApp.PadStatus.COMPLETO') },
                                { value: 'INCOMPLETO', label: translate('generadorApp.PadStatus.INCOMPLETO') },
                              ]}
                              onChange={(options: any) => this.setState({ statusSelectValue: options['value'] })}
                              name="status"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ statusSelectValue: evt.target.value })}
                      type="hidden"
                      name="status"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'initDate' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="initDateLabel" for="pad-initDate">
                              <Translate contentKey="generadorApp.pad.initDate">Initial date</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <DatePicker
                              autoComplete="off"
                              onChange={value => this.setState({ initDateSelectValue: value })}
                              id="initDate"
                              validate={{}}
                              className="form-control react-datepicker"
                              name="initDate"
                              dateFormat="dd/MM/yyyy"
                              selected={this.state.initDateSelectValue ? moment(this.state.initDateSelectValue).toDate() : null}
                              locale="pt"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ initDateSelectValue: evt.target.value })}
                      type="hidden"
                      name="initDate"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'finalDate' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="finalDateLabel" for="pad-finalDate">
                              <Translate contentKey="generadorApp.pad.finalDate">Final date</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <DatePicker
                              autoComplete="off"
                              onChange={value => this.setState({ finalDateSelectValue: value })}
                              id="finalDate"
                              validate={{}}
                              className="form-control react-datepicker"
                              name="finalDate"
                              dateFormat="dd/MM/yyyy"
                              selected={this.state.finalDateSelectValue ? moment(this.state.finalDateSelectValue).toDate() : null}
                              locale="pt"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ finalDateSelectValue: evt.target.value })}
                      type="hidden"
                      name="finalDate"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'quote' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="pad-quote">
                              <Translate contentKey="generadorApp.pad.quote">Quote</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="pad-quote"
                              name={'quote'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.quoteSelectValue ? this.state.quoteSelectValue : ''}
                              onChange={options => this.setState({ quoteSelectValue: options })}
                              defaultOptions={this.state.quoteStartSelectOptions ? this.state.quoteStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.quoteStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.quoteStartSelectOptions === undefined) {
                                  const result = await getListAxios('quotes', {}, 0, 100, 'id,asc', 'id');
                                  this.setState({
                                    quoteStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios('quotes', { 'id.contains': inputValue }, 0, 100, 'id,asc', 'id');
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="quote"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}
                  {baseFilters !== 'padCidPta' ? (
                    <Col md="12">
                      <div className="entityEmbebedList">
                        <PadCidPta
                          entityEmbebedPai={fullEntityEmbebed}
                          history={this.props.history}
                          location={{
                            pathname: `/pad/${this.props.match.params['id']}/pad-cid-pta/`,
                            search: '',
                            hash: '',
                            state: undefined,
                            key: 'q2tbhd',
                          }}
                          match={{
                            path: `/pad/:idPad/pad-cid-pta/:id`,
                            url: `/pad/${this.props.match.params['id']}/pad-cid-pta/`,
                            isExact: true,
                            params: { idPad: this.props.match.params['id'], url: `/pad/${this.props.match.params['id']}/pad-cid-pta/` },
                          }}
                          {...this.props.mapStateToPropsPadCidPta}
                          {...mapDispatchToPropsPadCidPtaList}
                          getEntityPai={(...v) => this.props.getEntity(...v)}
                          getEntities={this.padCidPtaGetEntities}
                          updateEntity={this.padCidPtaUpdateEntity}
                          getEntity={this.padCidPtaGetEntity}
                          createEntity={this.padCidPtaCreateEntity}
                          deleteEntity={this.padCidPtaDeleteEntity}
                          reset={this.padCidPtaReset}
                          padCidPtaList={this.props.padCidPtaListEmbed}
                          totalItems={this.props.padCidPtaListEmbed ? this.props.padCidPtaListEmbed.length : 0}
                          loading={this.props.loading}
                          padCidPtaEntity={this.props.padCidPtaSelected}
                        />
                      </div>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="padCidPta"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}{' '}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { padEntity, padCidPtas, padAttendances, patients, quotes, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...padEntity,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel className="pad pad-update">
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToProps = (storeState: IRootState) => {
  return {
    padCidPtas: storeState.padCidPta.entities,
    padAttendances: storeState.padAttendance.entities,
    patients: storeState.patient.entities,
    quotes: storeState.quote.entities,
    padEntity: storeState.pad.entity,
    loading: storeState.pad.loading,
    updating: storeState.pad.updating,
    updateSuccess: storeState.pad.updateSuccess,

    pads: storeState.pad.entities,
    padCidPtaListAllEmbed: storeState.pad.padCidPtaListAllEmbed,
    padCidPtaListEmbed: storeState.pad.padCidPtaListEmbed,
    padCidPtaSelected: storeState.pad.padCidPtaSelected,
    mapStateToPropsPadCidPta: mapStateToPropsPadCidPta(storeState),
  };
};

const mapDispatchToPropsPadCidPtaList = {};
Object.keys(mapDispatchToPropsPadCidPta).map(v => {
  mapDispatchToPropsPadCidPtaList[v + 'PadCidPta'] = mapDispatchToPropsPadCidPta[v];
});

export const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  getEntityOffset,
  reset,

  ...mapDispatchToPropsPadCidPtaList,
  padCidPtaUpdateEntity,
  padCidPtaGetEntities,
  padCidPtaSetEntities,
  padCidPtaReset,
  padCidPtaGetEntity,
  padCidPtaCreateEntity,
  padCidPtaDeleteEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PadUpdate);
