import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate, translate } from 'app/config/translate-component';
import { getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import SelectAsync from 'react-select/async';
import DatePicker from 'react-datepicker';

import moment from 'moment';
import { BillingStatus } from 'app/shared/model/enumerations/billing-status.model';
import { APP_LOCAL_DATE_FORMAT_SERVER } from 'app/config/constants';

const ModalFilterExportContas = props => {
  const { buttonLabel, className } = props;

  const [modal, setModal] = useState(false);
  const [from, setFrom] = useState(moment().subtract(30, 'days').toDate());
  const [to, setTo] = useState(moment().toDate());
  const [patientClient, setPatientClient] = useState(undefined);
  const [patientClientStartFilter, setPatientClientStartFilter] = useState(undefined);

  const toggle = () => setModal(!modal);

  return (
    <span>
      <Button onClick={() => toggle()} className="btn" size="md" color="dark">
        <FontAwesomeIcon icon="file" />
        <span className="d-none d-md-inline">
          <Translate contentKey="generadorApp.quote.listSelectedButtons.report">Report</Translate>
        </span>
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <Translate contentKey="generadorApp.bills.report.filtros">Filtros</Translate>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md="3">
              <Label id="fromStart" for="quote-from_start">
                <Translate contentKey="generadorApp.quote.from_start">From (start)</Translate>
              </Label>
              <DatePicker
                onChange={value => setFrom(value)}
                id="fromStart"
                className="form-control react-datepicker"
                name="fromStart"
                dateFormat="dd/MM/yyyy"
                selected={from}
                locale="pt"
              />
            </Col>
            <Col md="3">
              <Label id="toEnd" for="quote-to_end">
                <Translate contentKey="generadorApp.quote.to_end">To (end)</Translate>
              </Label>
              <DatePicker
                onChange={value => setTo(value)}
                id="toEnd"
                className="form-control react-datepicker"
                name="toEnd"
                dateFormat="dd/MM/yyyy"
                selected={to}
                locale="pt"
              />
            </Col>
            <Col md="6">
              <div style={{ width: '100%' }}>
                <Label for="quote-client">
                  <Translate contentKey="generadorApp.quote.client">Client</Translate>
                </Label>
                <SelectAsync
                  id="quote-client"
                  className={'css-select-control'}
                  name={'client'}
                  cacheOptions
                  value={patientClient}
                  onChange={options => setPatientClient(options)}
                  defaultOptions={patientClientStartFilter ? patientClientStartFilter : []}
                  loadingMessage={input => translate('selectAsync.loadingMessage')}
                  noOptionsMessage={input =>
                    patientClientStartFilter === undefined
                      ? translate('selectAsync.loadingMessage')
                      : translate('selectAsync.noOptionsMessage')
                  }
                  onMenuOpen={async () => {
                    if (patientClientStartFilter === undefined) {
                      const result = await getListAxios('clients', {}, 0, 100, 'fantasyName,asc', 'fantasyName');
                      setPatientClientStartFilter(
                        result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'fantasyName') })) : []
                      );
                    }
                  }}
                  loadOptions={async (inputValue, callback) => {
                    const result = await getListAxios(
                      'clients',
                      { 'fantasyName.contains': inputValue },
                      0,
                      100,
                      'fantasyName,asc',
                      'fantasyName'
                    );
                    callback(
                      result.data
                        ? result.data.map(p => ({
                            ...p,
                            value: p.id,
                            label: showFieldsSelectAsync(p, 'fantasyName'),
                          }))
                        : []
                    );
                  }}
                />
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={false}
            onClick={async () => {
              const filters = { 'billingStatus.equals': BillingStatus.FECHADA };
              if (from) {
                filters['from.greaterOrEqualThan'] = moment(from).format(APP_LOCAL_DATE_FORMAT_SERVER);
              }
              if (to) {
                filters['to.lessOrEqualThan'] = moment(to).format(APP_LOCAL_DATE_FORMAT_SERVER);
              }
              if (patientClient) {
                filters['patient.client.id.equals'] = patientClient.id;
              }
              props.setBaseState({
                report: await getListAxios(
                  'quotes',
                  filters,
                  0,
                  999999999999,
                  'createdDate,desc',
                  'id,patient.client, quoteRh.id, quoteMedicine.id, quoteMaterial.id, quoteProductService.id, quoteDiet.id'
                ),
                fromStart: from,
                toEnd: to,
                provider: patientClient.socialReason,
              });
              props.setBaseState({ showModalReport: true });
            }}
            type="submit"
          >
            Aceitar
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </span>
  );
};

export default ModalFilterExportContas;
