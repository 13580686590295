import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';
import moment from 'moment';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity, IClientBaseState, getUrlBack, getClientState, getEntityFiltersURL } from './client.reducer';

import { APP_DATE_FORMAT } from 'app/config/constants';

import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

export interface IClientState {
  fieldsBase: IClientBaseState;
  activeTab: number;
}

export interface IClientDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class ClientDetail extends React.Component<IClientDetailProps, IClientState> {
  constructor(props: Readonly<IClientDetailProps>) {
    super(props);
    this.state = {
      ...this.state,
      activeTab: 0,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getClientState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
    };
  }

  toggleTab(tab: number) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = '_back=1' + (offset !== null ? '&offset=' + offset : '');
    Object.keys(fieldsBase).map(key => {
      url += '&' + key + '=' + fieldsBase[key];
    });
    return url;
  };

  UNSAFE_componentWillMount() {
    this.props.getEntity(this.props.match.params['id']);
  }

  renderHeader() {
    const { clientEntity } = this.props;
    const state = this.state;
    return (
      <>
        <div id="page-heading">
          {' '}
          <h2>
            <Translate contentKey="generadorApp.client.detail.title">Client</Translate>
            <small>&nbsp; {clientEntity['fantasyName']}</small>
          </h2>
        </div>

        <div className="pull-right">
          <Button tag={Link} to={'/client?' + getEntityFiltersURL(this.state.fieldsBase)} replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.client.btnBack">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/client/${clientEntity.id}/edit?+${getEntityFiltersURL(this.state.fieldsBase)}`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.client.btnEdit">Edit</Translate>
            </span>
          </Button>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>

          <li className="breadcrumb-item active">
            <Link to={'/client'}>Clients</Link>
          </li>
          <li className="breadcrumb-item active">Clients details</li>
        </ol>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === 0 })}
              onClick={() => {
                this.toggleTab(0);
              }}
            >
              <span className="d-sm-none">
                <Translate contentKey="generadorApp.client.viewTabs.CompanyInformation">CompanyInformation</Translate>
              </span>
              <span className="d-sm-block d-none">
                <Translate contentKey="generadorApp.client.viewTabs.CompanyInformation">CompanyInformation</Translate>
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === 1 })}
              onClick={() => {
                this.toggleTab(1);
              }}
            >
              <span className="d-sm-none">
                <Translate contentKey="generadorApp.client.viewTabs.CommercialInformation">CommercialInformation</Translate>
              </span>
              <span className="d-sm-block d-none">
                <Translate contentKey="generadorApp.client.viewTabs.CommercialInformation">CommercialInformation</Translate>
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === 2 })}
              onClick={() => {
                this.toggleTab(2);
              }}
            >
              <span className="d-sm-none">
                <Translate contentKey="generadorApp.client.viewTabs.ClientContacts">ClientContacts</Translate>
              </span>
              <span className="d-sm-block d-none">
                <Translate contentKey="generadorApp.client.viewTabs.ClientContacts">ClientContacts</Translate>
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === 3 })}
              onClick={() => {
                this.toggleTab(3);
              }}
            >
              <span className="d-sm-none">
                <Translate contentKey="generadorApp.client.viewTabs.OtherInformation">OtherInformation</Translate>
              </span>
              <span className="d-sm-block d-none">
                <Translate contentKey="generadorApp.client.viewTabs.OtherInformation">OtherInformation</Translate>
              </span>
            </NavLink>
          </NavItem>
        </Nav>
      </>
    );
  }

  renderBody() {
    const { clientEntity } = this.props;
    const state = this.state;
    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            <Row className="jh-entity-details">
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId={0}>
                  <Row>
                    <Col className="view-detail-row client-socialReason-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top client-socialReason-detail-label" md="12">
                          <dt>
                            <span id="socialReason">
                              <Translate contentKey="generadorApp.client.socialReason">Social Reason</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top client-socialReason-detail-value" md="12">
                          <dd>{clientEntity.socialReason}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row client-fantasyName-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top client-fantasyName-detail-label" md="12">
                          <dt>
                            <span id="fantasyName">
                              <Translate contentKey="generadorApp.client.fantasyName">Fantasy Name</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top client-fantasyName-detail-value" md="12">
                          <dd>{clientEntity.fantasyName}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row client-cnpj-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top client-cnpj-detail-label" md="12">
                          <dt>
                            <span id="cnpj">
                              <Translate contentKey="generadorApp.client.cnpj">CNPJ</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top client-cnpj-detail-value" md="12">
                          <dd>{clientEntity.cnpj}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row client-ie-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top client-ie-detail-label" md="12">
                          <dt>
                            <span id="ie">
                              <Translate contentKey="generadorApp.client.ie">IE</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top client-ie-detail-value" md="12">
                          <dd>{clientEntity.ie}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row client-segment-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top client-segment-detail-label" md="12">
                          <dt>
                            <Translate contentKey="generadorApp.client.segment">Segment</Translate>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top client-segment-detail-value" md="12">
                          <dd>{clientEntity.segment ? clientEntity.segment.id : ''}</dd>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={1}>
                  <Row>
                    <Col className="view-detail-row client-zipCode-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top client-zipCode-detail-label" md="12">
                          <dt>
                            <span id="zipCode">
                              <Translate contentKey="generadorApp.client.zipCode">Zip Code</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top client-zipCode-detail-value" md="12">
                          <dd>{clientEntity.zipCode}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row client-street-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top client-street-detail-label" md="12">
                          <dt>
                            <span id="street">
                              <Translate contentKey="generadorApp.client.street">Street</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top client-street-detail-value" md="12">
                          <dd>{clientEntity.street}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row client-complement-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top client-complement-detail-label" md="12">
                          <dt>
                            <span id="complement">
                              <Translate contentKey="generadorApp.client.complement">Complement</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top client-complement-detail-value" md="12">
                          <dd>{clientEntity.complement}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row client-number-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top client-number-detail-label" md="12">
                          <dt>
                            <span id="number">
                              <Translate contentKey="generadorApp.client.number">Number</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top client-number-detail-value" md="12">
                          <dd>{clientEntity.number}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row client-neighborhood-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top client-neighborhood-detail-label" md="12">
                          <dt>
                            <span id="neighborhood">
                              <Translate contentKey="generadorApp.client.neighborhood">Neighborhood</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top client-neighborhood-detail-value" md="12">
                          <dd>{clientEntity.neighborhood}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row client-reference-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top client-reference-detail-label" md="12">
                          <dt>
                            <span id="reference">
                              <Translate contentKey="generadorApp.client.reference">Reference</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top client-reference-detail-value" md="12">
                          <dd>{clientEntity.reference}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row client-city-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top client-city-detail-label" md="12">
                          <dt>
                            <span id="city">
                              <Translate contentKey="generadorApp.client.city">City</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top client-city-detail-value" md="12">
                          <dd>{clientEntity.city}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row client-uf-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top client-uf-detail-label" md="12">
                          <dt>
                            <span id="uf">
                              <Translate contentKey="generadorApp.client.uf">UF</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top client-uf-detail-value" md="12">
                          <dd>{clientEntity.uf}</dd>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={2}>
                  <Row>
                    <Col className="view-detail-row client-clientContact-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top client-clientContact-detail-label" md="12">
                          <dt>
                            <Translate contentKey="generadorApp.client.clientContact">Client Contact</Translate>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top client-clientContact-detail-value" md="12">
                          <dd>{clientEntity.clientContact ? clientEntity.clientContact.id : ''}</dd>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={3}>
                  <Row>
                    <Col className="view-detail-row client-status-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top client-status-detail-label" md="12">
                          <dt>
                            <span id="status">
                              <Translate contentKey="generadorApp.client.status">Status</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top client-status-detail-value" md="12">
                          <dd>{translate('generadorApp.ExtraStatus.' + (clientEntity.status ? clientEntity.status : 'ATIVO'))}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row client-codAns-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top client-codAns-detail-label" md="12">
                          <dt>
                            <span id="codAns">
                              <Translate contentKey="generadorApp.client.codAns">ANS Code</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top client-codAns-detail-value" md="12">
                          <dd>{clientEntity.codAns}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row client-codCnes-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top client-codCnes-detail-label" md="12">
                          <dt>
                            <span id="codCnes">
                              <Translate contentKey="generadorApp.client.codCnes">CNES Code</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top client-codCnes-detail-value" md="12">
                          <dd>{clientEntity.codCnes}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row client-codEmpNoProvedor-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top client-codEmpNoProvedor-detail-label" md="12">
                          <dt>
                            <span id="codEmpNoProvedor">
                              <Translate contentKey="generadorApp.client.codEmpNoProvedor">Company Code Provider</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top client-codEmpNoProvedor-detail-value" md="12">
                          <dd>{clientEntity.codEmpNoProvedor}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row client-contractSignatureDate-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top client-contractSignatureDate-detail-label" md="12">
                          <dt>
                            <span id="contractSignatureDate">
                              <Translate contentKey="generadorApp.client.contractSignatureDate">Contract Signature Date</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top client-contractSignatureDate-detail-value" md="12">
                          <dd>
                            {clientEntity.contractSignatureDate ? moment(clientEntity.contractSignatureDate).format(APP_DATE_FORMAT) : ''}
                          </dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row client-expectedEmissionNfDay-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top client-expectedEmissionNfDay-detail-label" md="12">
                          <dt>
                            <span id="expectedEmissionNfDay">
                              <Translate contentKey="generadorApp.client.expectedEmissionNfDay">Expected emission Nf date</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top client-expectedEmissionNfDay-detail-value" md="12">
                          <dd>{clientEntity.expectedEmissionNfDay}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row client-expectedBillingDay-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top client-expectedBillingDay-detail-label" md="12">
                          <dt>
                            <span id="expectedBillingDay">
                              <Translate contentKey="generadorApp.client.expectedBillingDay">Expected billing day</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top client-expectedBillingDay-detail-value" md="12">
                          <dd>{clientEntity.expectedBillingDay}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row client-expectedReceiptDate-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top client-expectedReceiptDate-detail-label" md="12">
                          <dt>
                            <span id="expectedReceiptDate">
                              <Translate contentKey="generadorApp.client.expectedReceiptDate">Expected Receipt Date</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top client-expectedReceiptDate-detail-value" md="12">
                          <dd>{clientEntity.expectedReceiptDate}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row client-billingRules-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top client-billingRules-detail-label" md="12">
                          <dt>
                            <span id="billingRules">
                              <Translate contentKey="generadorApp.client.billingRules">Billing Rules</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top client-billingRules-detail-value" md="12">
                          <dd>{clientEntity.billingRules}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row client-observations-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top client-observations-detail-label" md="12">
                          <dt>
                            <span id="observations">
                              <Translate contentKey="generadorApp.client.observations">Observations</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top client-observations-detail-value" md="12">
                          <dd>{clientEntity.observations}</dd>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { clientEntity } = this.props;
    const state = this.state;
    return (
      <div>
        <Panel className="client client-detail">
          <PanelHeader>{this.renderHeader()}</PanelHeader>

          <PanelBody>{this.renderBody()}</PanelBody>
        </Panel>
      </div>
    );
  }
}

export const mapStateToProps = ({ client, ...storeState }: IRootState) => ({
  clientEntity: client.entity,
});

export const mapDispatchToProps = {
  getEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ClientDetail);
