/* eslint-disable no-console */
/* eslint complexity: ["error", 300] */
import React from 'react';
import { connect } from 'react-redux';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { QuoteUpdate, mapStateToProps, mapDispatchToProps } from '../quote-update';
import { IRootState } from 'app/shared/reducers';
import { getFormFromSelect, getListAxios } from 'app/shared/util/entity-utils';

import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Row, Col, Label, Button, Table, Modal, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);
import { Translate, translate } from 'app/config/translate-component';
import Select from 'react-select';
import { callFunction } from 'app/shared/reports/jhi-reports.reducer';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';
import moment from 'moment';
registerLocale('pt', pt);

import { createEntity as createPad, getEntity as getPad } from 'app/entities/pad/pad.reducer';
import { createEntity as createPharmacyStock, getEntity as getPharmacyStock } from 'app/entities/pharmacy-stock/pharmacy-stock.reducer';

import { mapDispatchToPropsExtended as mapDispatchToPropsQuoteProductService } from 'app/entities/quote-product-service/extended/quote-product-service';

import { mapDispatchToPropsExtended as mapDispatchToPropsQuoteRh } from 'app/entities/quote-rh/extended/quote-rh';

import { IClient } from 'app/shared/model/client.model';
import { convertDateTimeToServer } from 'app/shared/util/date-utils';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IPatient } from 'app/shared/model/patient.model';
import { IClComTable } from 'app/shared/model/cl-com-table.model';
import { alterarQuantidadeAtual, getRomaneioReport } from './quote.reducer';
import ModalUpdateRomaneio from './modal-update-romaneio';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { TextFormat } from 'react-jhipster';
import { RomaneioPdfReport } from 'app/entities/quote/report/romaneio';

export interface IUpdateExtendedState {
  createPadAfterSubmit?: boolean;
  showModalReport?: boolean;
}

export class RomaneioUpdate extends QuoteUpdate {
  clComTableAsyncPendind = false;

  UNSAFE_componentWillMount() {
    super.UNSAFE_componentWillMount();
  }

  saveEntity = (event: any, errors: any, values: any) => {
    if (
      !['APROVADO', 'PRORROGADO', 'INATIVOREPROVADOPRECO', 'INATIVOCONTROLEINTERNO'].includes(this.props.quoteEntity.status) &&
      ['APROVADO'].includes(this.state.statusSelectValue)
    ) {
      MySwal.fire({
        title: <p>Aprovar Orçamento</p>,
        html: <p>Deseja aprovar o orçamento</p>,
        showConfirmButton: true,
        showCancelButton: true,
      }).then(response => {
        if (response.isConfirmed) {
          return this.setState(
            {
              createPadAfterSubmit: true,
            },
            () => this.saveEntityOld(event, errors, values, true)
          );
        }
      });
    } else {
      return this.setState(
        {
          createPadAfterSubmit: false,
        },
        () => this.saveEntityOld(event, errors, values, false)
      );
    }
  };

  saveEntityOld(event: any, errors: any, values: any, createPadAfter: boolean) {
    values.approvalDate = convertDateTimeToServer(values.approvalDate);
    values.from = convertDateTimeToServer(values.from);
    values.to = convertDateTimeToServer(values.to);

    const quoteFile = []; // one-to-many -- ownerSide=false
    this.props.quoteFiles.forEach(e => {
      if (this.state.quoteFileSelectValue && this.state.quoteFileSelectValue.split(',').indexOf(e.id + '') !== -1) {
        quoteFile.push(e);
      }
    });

    const expectedResult = []; // one-to-many -- ownerSide=false
    this.props.expectedResults.forEach(e => {
      if (this.state.expectedResultSelectValue && this.state.expectedResultSelectValue.split(',').indexOf(e.id + '') !== -1) {
        expectedResult.push(e);
      }
    });

    const pad = []; // one-to-many -- ownerSide=false
    this.props.pads.forEach(e => {
      if (this.state.padSelectValue && this.state.padSelectValue.split(',').indexOf(e.id + '') !== -1) {
        pad.push(e);
      }
    });

    let patient = null; // many-to-one -- ownerSide=false
    this.props.patients.forEach(e => {
      if (e.id === this.state.patientSelectValue) {
        patient = e;
      }
    });

    let clComTable = null; // many-to-one -- ownerSide=false
    this.props.clComTables.forEach(e => {
      if (e.id === this.state.clComTableSelectValue) {
        clComTable = e;
      }
    });

    let client = null; // many-to-one -- ownerSide=false
    this.props.clients.forEach(e => {
      if (e.id === this.state.clientSelectValue) {
        client = e;
      }
    });

    if (errors.length === 0) {
      const { quoteEntity } = this.props;
      const entity = {
        ...quoteEntity,
        ...values,

        quoteDiet: undefined,

        quoteMedicine: undefined,

        quoteMaterial: undefined,

        quoteProductService: undefined,

        quoteRh: undefined,

        quoteFile: this.state.quoteFileSelectValue ? this.state.quoteFileSelectValue.map(v => ({ id: v.value })) : [], // one-to-many - otherSide

        expectedResult: this.state.expectedResultSelectValue ? this.state.expectedResultSelectValue.map(v => ({ id: v.value })) : [], // one-to-many - otherSide

        pad: this.state.padSelectValue ? this.state.padSelectValue.map(v => ({ id: v.value })) : [], // one-to-many - otherSide

        supply: this.state.supplySelectValue ? this.state.supplySelectValue.map(v => ({ id: v.value })) : [], // one-to-many - otherSide

        patient: getFormFromSelect(this.state.patientSelectValue, 'many-to-one'), // many-to-one - otherSide

        clComTable: getFormFromSelect(this.state.clComTableSelectValue, 'many-to-one'), // many-to-one - otherSide

        client: getFormFromSelect(this.state.clientSelectValue, 'many-to-one'), // many-to-one - otherSide

        source: this.state.sourceSelectValue === null ? 'AVALIACAO' : this.state.sourceSelectValue,
        status: this.state.statusSelectValue === null ? 'ORCAMENTOABERTO' : this.state.statusSelectValue,
        approvalDate: this.state.approvalDateSelectValue,
        from: this.state.fromSelectValue,
        to: this.state.toSelectValue,
        accountNumber: this.state.accountNumberSelectValue,
        finalAmount: this.state.finalAmountSelectValue,
        billFinalAmount: this.state.billFinalAmountSelectValue,
      };

      const { patientId, patientClientId, source, status, fromStart, fromEnd, toStart, toEnd } = this.state.fieldsBase;

      const quoteProductServiceListAllEmbed = this.props.quoteProductServiceListAllEmbed;
      const quoteRhListAllEmbed = this.props.quoteRhListAllEmbed;
      const quoteMedicineListAllEmbed = this.props.quoteMedicineListAllEmbed;
      const quoteMaterialListAllEmbed = this.props.quoteMaterialListAllEmbed;
      const quoteDietListAllEmbed = this.props.quoteDietListAllEmbed;

      if (this.state.isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(
              entity,
              !createPadAfter ? [patientId, patientClientId, source, status, fromStart, fromEnd, toStart, toEnd] : { reloadList: false }
            )
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
          if (idEntity) {
            quoteProductServiceListAllEmbed &&
              quoteProductServiceListAllEmbed.map(v => {
                v['id'] = null;
                v['quote'] = { id: idEntity };
                this.props['createEntityQuoteProductService'](v);
              });
          }
          // createPad
          if (idEntity) {
            quoteRhListAllEmbed &&
              quoteRhListAllEmbed.map(v => {
                v['id'] = null;
                v['quote'] = { id: idEntity };

                this.props['createEntityQuoteRh'](v);
              });
          }
          if (idEntity) {
            quoteMedicineListAllEmbed.map(v => {
              v['id'] = null;
              v['quote'] = { id: idEntity };
              this.props['createEntityQuoteMedicine'](v);
            });
          }
          if (idEntity) {
            quoteMaterialListAllEmbed.map(v => {
              v['id'] = null;
              v['quote'] = { id: idEntity };
              this.props['createEntityQuoteMaterial'](v);
            });
          }
          if (idEntity) {
            quoteDietListAllEmbed.map(v => {
              v['id'] = null;
              v['quote'] = { id: idEntity };
              this.props['createEntityQuoteDiet'](v);
            });
          }

          if (createPadAfter && idEntity) {
            callFunction('fnCreateStockFromQuote', { parameter: idEntity });
          }
          if (quoteRhListAllEmbed.length > 0 && createPadAfter && idEntity) {
            new Promise(resolve => {
              resolve(
                this.props.createPad(
                  { finalDate: this.state.toSelectValue, initDate: this.state.fromSelectValue, patient, quote: { id: idEntity } },
                  { reloadList: false }
                )
              );
            }).then(resultEntityPad => {
              const idPad =
                resultEntityPad && resultEntityPad['value'] && resultEntityPad['value']['data'] && resultEntityPad['value']['data']['id']
                  ? resultEntityPad['value']['data']['id']
                  : -1;
              new Promise(resolve => {
                resolve(this.props.getPad(idPad));
              }).then(resultPad => {
                //  this.props.history.push('/pad/' + idPad + '/edit');
              });
            });
          }
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(
              entity,
              !createPadAfter ? [patientId, patientClientId, source, status, fromStart, fromEnd, toStart, toEnd] : { reloadList: false }
            )
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;

          if (createPadAfter && idEntity) {
            callFunction('fnCreateStockFromQuote', { parameter: idEntity });
          }
          if (quoteRhListAllEmbed.length > 0 && createPadAfter && idEntity) {
            new Promise(resolve => {
              resolve(
                this.props.createPad(
                  { finalDate: this.state.toSelectValue, initDate: this.state.fromSelectValue, patient, quote: { id: idEntity } },
                  { reloadList: false }
                )
              );
            }).then(resultEntityPad => {
              const idPad =
                resultEntityPad && resultEntityPad['value'] && resultEntityPad['value']['data'] && resultEntityPad['value']['data']['id']
                  ? resultEntityPad['value']['data']['id']
                  : -1;
              new Promise(resolve => {
                resolve(this.props.getPad(idPad));
              }).then(resultPad => {
                //     this.props.history.push('/pad/' + idPad + '/edit');
              });
            });
          }
        });
      }
    }
  }
  async componentDidUpdate(prevProps) {
    if (prevProps.updateSuccess !== this.props.updateSuccess && prevProps.updateSuccess) {
      this.handleClose();
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteDiets.length > 0 && this.state.quoteDietSelectValue === null && this.props.quoteEntity.quoteDiet)
    ) {
      this.setState({ quoteDietSelectValue: this.props.quoteEntity.quoteDiet });
      this.props.quoteDietSetEntities(this.props.quoteEntity.quoteDiet);
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteMedicines.length > 0 && this.state.quoteMedicineSelectValue === null && this.props.quoteEntity.quoteMedicine)
    ) {
      this.setState({ quoteMedicineSelectValue: this.props.quoteEntity.quoteMedicine });
      this.props.quoteMedicineSetEntities(this.props.quoteEntity.quoteMedicine);
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteMaterials.length > 0 && this.state.quoteMaterialSelectValue === null && this.props.quoteEntity.quoteMaterial)
    ) {
      this.setState({ quoteMaterialSelectValue: this.props.quoteEntity.quoteMaterial });
      this.props.quoteMaterialSetEntities(this.props.quoteEntity.quoteMaterial);
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteProductServices.length > 0 &&
        this.state.quoteProductServiceSelectValue === null &&
        this.props.quoteEntity.quoteProductService)
    ) {
      this.setState({ quoteProductServiceSelectValue: this.props.quoteEntity.quoteProductService });
      this.props.quoteProductServiceSetEntities(this.props.quoteEntity.quoteProductService);
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteRhs.length > 0 && this.state.quoteRhSelectValue === null && this.props.quoteEntity.quoteRh)
    ) {
      this.setState({ quoteRhSelectValue: this.props.quoteEntity.quoteRh });
      this.props.quoteRhSetEntities(this.props.quoteEntity.quoteRh);
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteFiles.length > 0 && this.state.quoteFileSelectValue === null && this.props.quoteEntity.quoteFile)
    ) {
      this.setState({
        quoteFileSelectValue:
          this.props.quoteEntity && this.props.quoteEntity.quoteFile
            ? this.props.quoteEntity.quoteFile.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.expectedResults.length > 0 && this.state.expectedResultSelectValue === null && this.props.quoteEntity.expectedResult)
    ) {
      this.setState({
        expectedResultSelectValue:
          this.props.quoteEntity && this.props.quoteEntity.expectedResult
            ? this.props.quoteEntity.expectedResult.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.pads.length > 0 && this.state.padSelectValue === null && this.props.quoteEntity.pad)
    ) {
      this.setState({
        padSelectValue:
          this.props.quoteEntity && this.props.quoteEntity.pad
            ? this.props.quoteEntity.pad.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.supplies.length > 0 && this.state.supplySelectValue === null && this.props.quoteEntity.supply)
    ) {
      this.setState({
        supplySelectValue:
          this.props.quoteEntity && this.props.quoteEntity.supply
            ? this.props.quoteEntity.supply.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }
    // data-type-rel -> tipo-4 many-to-one other-side

    if (
      !this.clComTableAsyncPendind &&
      ((prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
        (this.state.patientSelectValue === null && this.props.quoteEntity.patient && this.props.quoteEntity.patient.id))
    ) {
      const p = this.props.quoteEntity && this.props.quoteEntity.patient ? this.props.quoteEntity.patient : null;
      this.clComTableAsyncPendind = true;
      let rulers = {};
      let itens = {};
      if (p.client && p.client.clComTable && p.client.clComTable.length > 0) {
        const tables = p.client.clComTable.filter(v => v.deletedAt === null);
        const table = tables ? tables.slice(0, 1).pop().id : null;
        if (table) {
          const rulersData = await getListAxios('cl-com-rulers', { 'clComTable.equals': table }, 0, 100, 'id,asc', 'id');
          rulers = rulersData['data'].filter(v => v.deletedAt === null);
          const itensData = await getListAxios('cl-com-items', { 'clComTable.equals': table }, 0, 100, 'id,asc', 'id');
          itens = itensData['data'].filter(v => v.deletedAt === null);
        }
      }
      this.setState(
        {
          patientSelectValue: p
            ? { ...p, value: p.id, label: (p['client'] ? p.client.socialReason : '') + ' | ' + (p['name'] ? p.name : '') }
            : null,
          tableRulersSelectValue: rulers,
          tableItensSelectValue: itens,
        },
        () => {
          // clComTableAsyncPendind=false;
        }
      );
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.clComTables &&
        this.state.clComTableSelectValue === null &&
        this.props.quoteEntity.clComTable &&
        this.props.quoteEntity.clComTable.id)
    ) {
      const p = this.props.quoteEntity && this.props.quoteEntity.clComTable ? this.props.quoteEntity.clComTable : null;
      this.setState({
        clComTableSelectValue: p ? { value: p.id, label: p['name'] ? p.name : '' } : null,
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.clients && this.state.clientSelectValue === null && this.props.quoteEntity.client && this.props.quoteEntity.client.id)
    ) {
      const p = this.props.quoteEntity && this.props.quoteEntity.client ? this.props.quoteEntity.client : null;
      this.setState({
        clientSelectValue: p ? { value: p.id, label: p['fantasyName'] ? p.fantasyName : '' } : null,
      });
    }

    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.source && this.state.sourceSelectValue === null)
    ) {
      this.setState({ sourceSelectValue: this.props.quoteEntity.source });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.status && this.state.statusSelectValue === null)
    ) {
      this.setState({ statusSelectValue: this.props.quoteEntity.status });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.approvalDate && this.state.approvalDateSelectValue === null)
    ) {
      this.setState({ approvalDateSelectValue: this.props.quoteEntity.approvalDate });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.from && this.state.fromSelectValue === null)
    ) {
      this.setState({ fromSelectValue: this.props.quoteEntity.from });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (!this.props.quoteEntity.from && this.state.isNew && this.state.fromSelectValue === null)
    ) {
      this.setState({ fromSelectValue: moment().startOf('month') });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.to && this.state.toSelectValue === null)
    ) {
      this.setState({ toSelectValue: this.props.quoteEntity.to });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (!this.props.quoteEntity.to && this.state.isNew && this.state.toSelectValue === null)
    ) {
      this.setState({ toSelectValue: moment().endOf('month') });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.accountNumber && this.state.accountNumberSelectValue === null)
    ) {
      this.setState({ accountNumberSelectValue: this.props.quoteEntity.accountNumber });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.finalAmount && this.state.finalAmountSelectValue === null)
    ) {
      this.setState({ finalAmountSelectValue: this.props.quoteEntity.finalAmount });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.billFinalAmount && this.state.billFinalAmountSelectValue === null)
    ) {
      this.setState({ billFinalAmountSelectValue: this.props.quoteEntity.billFinalAmount });
    }
  }

  handleClose = () => {
    // if (!this.state.createPadAfterSubmit) {
    this.props.history.push('/quote?' + this.getFiltersURL());
    //  }
  };

  renderHeader() {
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            <Translate contentKey="generadorApp.romaneio.home.title">Romaneio</Translate>
          </span>
          <Button
            className={'float-right'}
            color="dark"
            onClick={() => {
              this.props.getRomaneioReport(this.props.quoteEntity.id);
              this.setState({
                showModalReport: true,
              });
            }}
          >
            <FontAwesomeIcon icon="file-pdf" />
            &nbsp; Visualizar PDF
          </Button>
          <Button
            tag={Link}
            id="cancel-save"
            to={'/romaneio?' + this.getFiltersURL()}
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.quote.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/romaneio'}>
              <Translate contentKey="generadorApp.romaneio.home.title">Romaneio</Translate>
            </Link>
          </li>
        </ol>
      </>
    );
  }

  renderBody() {
    const { quoteEntity, patients, loading } = this.props;
    const { isNew } = this.state;

    const quoteEntityReadOnly = ['APROVADO', 'PRORROGADO', 'INATIVOREPROVADOPRECO', 'INATIVOCONTROLEINTERNO'].includes(quoteEntity.status);

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const mapDispatchToPropsQuoteProductServiceList: any = {};
    Object.keys(mapDispatchToPropsQuoteProductService).map(v => {
      mapDispatchToPropsQuoteProductServiceList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'QuoteProductService')) {
          this.setState(
            { functionEmbebed: [...this.state.functionEmbebed, v + 'QuoteProductService'] },
            this.props[v + 'QuoteProductService']
          );
        }
      };
    });
    const mapDispatchToPropsQuoteRhList: any = {};
    Object.keys(mapDispatchToPropsQuoteRh).map(v => {
      mapDispatchToPropsQuoteRhList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'QuoteRh')) {
          this.setState({ functionEmbebed: [...this.state.functionEmbebed, v + 'QuoteRh'] }, this.props[v + 'QuoteRh']);
        }
      };
    });

    const quoteEntitySelectValue = {
      ...this.props.quoteEntity,
      client: this.state.clientSelectValue,
      source: this.state.sourceSelectValue,
      status: this.state.statusSelectValue,
      approvalDate: this.state.approvalDateSelectValue,
      from: this.state.fromSelectValue,
      to: this.state.toSelectValue,
    };

    const patientSelect: IPatient = this.state.patientSelectValue;
    const patientSelectClient: IClient = patientSelect ? patientSelect.client : null;
    const patientSelectClientTabelas: IClComTable[] =
      patientSelectClient && patientSelectClient.clComTable ? patientSelectClient.clComTable.filter(v => v.deletedAt === null) : [];
    const patientSelectTabela: IClComTable = patientSelectClientTabelas.length > 0 ? patientSelectClientTabelas[0] : null;

    const patientSelectSegment = patientSelectClient && patientSelectClient.segment ? patientSelectClient.segment : null;

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? <AvInput id="quote-id" type="hidden" className="form-control" name="id" required readOnly /> : <></>}
                <Row>
                  <Col md="3">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" for="quote-id">
                            <Translate contentKey="generadorApp.quote.field_id">ID</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <input
                            value={quoteEntity && quoteEntity['id'] ? (quoteEntity.id + '').padStart(4, '0') : 'XXXX'}
                            className="form-control"
                            type="text"
                            disabled={true}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md="3">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" id="createdDateLabel" for="quote-createdDate">
                            <Translate contentKey="generadorApp.quote.createdDate">Created Date</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <input
                            value={
                              quoteEntity ? moment(quoteEntity.createdDate).format('DD/MM/YYYY') : moment(new Date()).format('DD/MM/YYYY')
                            }
                            className="form-control"
                            type="text"
                            disabled={true}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  {baseFilters !== 'approvalDate' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="approvalDateLabel" for="quote-approvalDate">
                              <Translate contentKey="generadorApp.quote.approvalDate">Approval Date</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            {!quoteEntityReadOnly ? (
                              <DatePicker
                                onChange={value => this.setState({ approvalDateSelectValue: value })}
                                id="approvalDate"
                                validate={{}}
                                className="form-control react-datepicker"
                                name="approvalDate"
                                dateFormat="dd/MM/yyyy"
                                selected={this.state.approvalDateSelectValue ? moment(this.state.approvalDateSelectValue).toDate() : null}
                                locale="pt"
                              />
                            ) : (
                              <input
                                className="form-control"
                                type="text"
                                disabled={true}
                                value={quoteEntity ? moment(this.state.approvalDateSelectValue).format('DD/MM/YYYY') : ''}
                              />
                            )}
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ approvalDateSelectValue: evt.target.value })}
                      type="hidden"
                      name="approvalDate"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'source' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="sourceLabel" for="quote-source">
                              <Translate contentKey="generadorApp.quote.source">Source</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            {!quoteEntityReadOnly ? (
                              <Select
                                id="quote-source"
                                className={'css-select-control'}
                                value={{
                                  value: this.state.sourceSelectValue,
                                  label: translate('generadorApp.QuoteSource.' + this.state.sourceSelectValue),
                                }}
                                options={[
                                  { value: 'AVALIACAO', label: translate('generadorApp.QuoteSource.AVALIACAO') },
                                  { value: 'IMPLANTACAO', label: translate('generadorApp.QuoteSource.IMPLANTACAO') },
                                  { value: 'CONTROLEDECUSTO', label: translate('generadorApp.QuoteSource.CONTROLEDECUSTO') },
                                  { value: 'PRORROGADO', label: translate('generadorApp.QuoteSource.PRORROGADO') },
                                  { value: 'ADITIVO', label: translate('generadorApp.QuoteSource.ADITIVO') },
                                ]}
                                onChange={(options: any) => this.setState({ sourceSelectValue: options['value'] })}
                                name="source"
                              />
                            ) : (
                              <input
                                className="form-control"
                                type="text"
                                disabled={true}
                                value={translate('generadorApp.QuoteSource.' + this.state.sourceSelectValue)}
                              />
                            )}
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ sourceSelectValue: evt.target.value })}
                      type="hidden"
                      name="source"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'status' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="statusLabel" for="quote-status">
                              <Translate contentKey="generadorApp.quote.status">Status</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            {!quoteEntityReadOnly ? (
                              <Select
                                id="quote-status"
                                className={'css-select-control'}
                                value={{
                                  value: this.state.statusSelectValue,
                                  label: translate('generadorApp.QuoteStatus.' + this.state.statusSelectValue),
                                }}
                                options={
                                  ['APROVADO', 'PRORROGADO', 'INATIVOREPROVADOPRECO', 'INATIVOCONTROLEINTERNO'].includes(
                                    this.state.statusSelectValue
                                  )
                                    ? [
                                        { value: 'APROVADO', label: translate('generadorApp.QuoteStatus.APROVADO') },
                                        {
                                          value: 'INATIVOREPROVADOPRECO',
                                          label: translate('generadorApp.QuoteStatus.INATIVOREPROVADOPRECO'),
                                        },
                                        {
                                          value: 'INATIVOCONTROLEINTERNO',
                                          label: translate('generadorApp.QuoteStatus.INATIVOCONTROLEINTERNO'),
                                        },
                                      ]
                                    : [
                                        { value: 'ORCAMENTOABERTO', label: translate('generadorApp.QuoteStatus.ORCAMENTOABERTO') },
                                        {
                                          value: 'AGUARDANDOPRECIFICACAO',
                                          label: translate('generadorApp.QuoteStatus.AGUARDANDOPRECIFICACAO'),
                                        },
                                        {
                                          value: 'ORCAMENTOPRECIFICADO',
                                          label: translate('generadorApp.QuoteStatus.ORCAMENTOPRECIFICADO'),
                                        },
                                        { value: 'AGUARDANDOAPROVACAO', label: translate('generadorApp.QuoteStatus.AGUARDANDOAPROVACAO') },
                                        { value: 'PLANTAOAPROVADO', label: translate('generadorApp.QuoteStatus.PLANTAOAPROVADO') },
                                        { value: 'APROVADO', label: translate('generadorApp.QuoteStatus.APROVADO') },
                                      ]
                                }
                                onChange={(options: any) => this.setState({ statusSelectValue: options['value'] })}
                                name="status"
                              />
                            ) : (
                              <input
                                className="form-control"
                                type="text"
                                disabled={true}
                                value={translate('generadorApp.QuoteStatus.' + this.state.statusSelectValue)}
                              />
                            )}
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ statusSelectValue: evt.target.value })}
                      type="hidden"
                      name="status"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'from' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="fromLabel" for="quote-from">
                              <Translate contentKey="generadorApp.quote.from">From</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            {!quoteEntityReadOnly ? (
                              <DatePicker
                                onChange={value => this.setState({ fromSelectValue: value })}
                                id="from"
                                validate={{}}
                                className="form-control react-datepicker"
                                name="from"
                                dateFormat="dd/MM/yyyy"
                                selected={this.state.fromSelectValue ? moment(this.state.fromSelectValue).toDate() : null}
                                locale="pt"
                              />
                            ) : (
                              <input
                                className="form-control"
                                type="text"
                                disabled={true}
                                value={quoteEntity ? moment(this.state.fromSelectValue).format('DD/MM/YYYY') : ''}
                              />
                            )}
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ fromSelectValue: evt.target.value })}
                      type="hidden"
                      name="from"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'to' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="toLabel" for="quote-to">
                              <Translate contentKey="generadorApp.quote.to">To</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            {!quoteEntityReadOnly ? (
                              <DatePicker
                                onChange={value => this.setState({ toSelectValue: value })}
                                id="to"
                                validate={{}}
                                className="form-control react-datepicker"
                                name="to"
                                dateFormat="dd/MM/yyyy"
                                selected={this.state.toSelectValue ? moment(this.state.toSelectValue).toDate() : null}
                                locale="pt"
                              />
                            ) : (
                              <input
                                className="form-control"
                                type="text"
                                disabled={true}
                                value={quoteEntity ? moment(this.state.toSelectValue).format('DD/MM/YYYY') : ''}
                              />
                            )}
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ toSelectValue: evt.target.value })}
                      type="hidden"
                      name="to"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}{' '}
                  {baseFilters !== 'client' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="quote-patient">
                              <Translate contentKey="generadorApp.patient.client">Client</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              className="form-control"
                              type="text"
                              disabled={true}
                              value={
                                this.state.patientSelectValue && this.state.patientSelectValue.client
                                  ? this.state.patientSelectValue.client.socialReason
                                  : ''
                              }
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput type="hidden" name="patient" value={this.state.fieldsBase[baseFilters + 'Id']} />
                  )}
                  {baseFilters !== 'patient' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="quote-patient">
                              <Translate contentKey="generadorApp.quote.patient">Patient</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              className="form-control"
                              type="text"
                              disabled={true}
                              value={this.state.patientSelectValue ? this.state.patientSelectValue.name : ''}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput type="hidden" name="patient" value={this.state.fieldsBase[baseFilters + 'Id']} />
                  )}
                  {patients.filter(v => v.id === this.state.patientSelectValue).length > 0 ? (
                    <>
                      <Col md="3">
                        <AvGroup>
                          <Row>
                            <Col md="12">
                              <Label className="mt-2 label-single-line" for="quote-id">
                                <Translate contentKey="generadorApp.quote.operadora">Operadora</Translate>
                              </Label>
                            </Col>
                            <Col md="12">
                              <input
                                value={patientSelectClient ? patientSelectClient.socialReason : ''}
                                className="form-control"
                                type="text"
                                disabled={true}
                              />
                            </Col>
                          </Row>
                        </AvGroup>
                      </Col>
                      <Col md="3">
                        <AvGroup>
                          <Row>
                            <Col md="12">
                              <Label className="mt-2 label-single-line" for="quote-id">
                                <Translate contentKey="generadorApp.quoteItem.clComTable">Tabela de Valores</Translate>
                              </Label>
                            </Col>
                            <Col md="12">
                              <input
                                value={patientSelectTabela ? patientSelectTabela.name : ''}
                                className="form-control"
                                type="text"
                                disabled={true}
                              />
                            </Col>
                          </Row>
                        </AvGroup>
                      </Col>
                      <Col md="3">
                        <AvGroup>
                          <Row>
                            <Col md="12">
                              <Label className="mt-2 label-single-line" for="quote-id">
                                <Translate contentKey="generadorApp.quote.segment">Segment</Translate>
                              </Label>
                            </Col>
                            <Col md="12">
                              <input
                                value={patientSelectSegment ? patientSelectSegment.name : ''}
                                className="form-control"
                                type="text"
                                disabled={true}
                              />
                            </Col>
                          </Row>
                        </AvGroup>
                      </Col>
                    </>
                  ) : (
                    <> </>
                  )}
                  <></>
                  {quoteEntitySelectValue ? (
                    <>
                      {quoteEntitySelectValue.quoteMaterial && quoteEntitySelectValue.quoteMaterial.length > 0 ? (
                        <div className={'mt-2 col-lg-12'}>
                          <h4>
                            <Translate contentKey="generadorApp.quoteMaterial.home.title">Materiais</Translate>
                          </h4>
                          <Table
                            id="quote-material-table"
                            aria-describedby="quote-heading"
                            className={'table-hover table-striped mt-1 table-responsive-css'}
                          >
                            <thead className={'thead-light'}>
                              <tr>
                                <th className={'align-middle text-center'} style={{ width: '30%' }}>
                                  <Translate contentKey="generadorApp.medicineMaterial.name">Nome</Translate>
                                </th>
                                <th className={'align-middle text-center'} style={{ width: '10%' }}>
                                  <Translate contentKey="generadorApp.quoteMaterial.frequency">Frequency</Translate>
                                </th>
                                <th className={'align-middle text-center'} style={{ width: '10%' }}>
                                  <Translate contentKey="generadorApp.quoteMaterial.currentQuantity">Qtd. atual</Translate>
                                </th>
                                <th className={'align-middle text-center'} style={{ width: '10%' }}>
                                  <Translate contentKey="generadorApp.quoteMedicine.days">Qtd. total</Translate>
                                </th>

                                <th className={'align-middle text-center'} style={{ width: '10%' }}>
                                  <Translate contentKey="generadorApp.quoteMaterial.startDate">Data inicial</Translate>
                                </th>
                                <th className={'align-middle text-center'} style={{ width: '10%' }}>
                                  <Translate contentKey="generadorApp.quoteMaterial.finishDate">Data fim</Translate>
                                </th>
                                <th className={'align-middle text-right'} style={{ width: '10%' }}>
                                  <Translate contentKey="generadorApp.quoteMaterial.romaneioStatus">Status</Translate>
                                </th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {quoteEntitySelectValue.quoteMaterial.map((quoteMaterial, i) => (
                                <tr key={`entity-${i}`} className={quoteMaterial.additive ? 'alert alert-warning' : ''}>
                                  <td className="text-center">
                                    {quoteMaterial.medicineMaterial ? quoteMaterial.medicineMaterial.name : ''}
                                  </td>
                                  <td className="text-center">{`${quoteMaterial.frequency}`}</td>
                                  <td className="text-center">{`${
                                    quoteMaterial.currentQuantity ? parseFloat(String(quoteMaterial.currentQuantity)).toFixed(0) : '-'
                                  }`}</td>
                                  <td className="text-center">{`${
                                    quoteMaterial.days ? parseFloat(String(quoteMaterial.days)).toFixed(0) : '-'
                                  }`}</td>
                                  <td className="text-center">
                                    {quoteMaterial.startDate ? (
                                      <TextFormat type="date" value={quoteMaterial.startDate} format={APP_DATE_FORMAT} />
                                    ) : (
                                      '-'
                                    )}
                                  </td>
                                  <td className="text-center">
                                    {quoteMaterial.finishDate ? (
                                      <TextFormat type="date" value={quoteMaterial.finishDate} format={APP_DATE_FORMAT} />
                                    ) : (
                                      '-'
                                    )}
                                  </td>
                                  <td className="text-right">{`${quoteMaterial.romaneioStatus}`}</td>
                                  <td className="text-right">
                                    <ModalUpdateRomaneio
                                      alterarQuantidadeAtual1={(itenSel, isExternalStock, quantidade, textBatch) =>
                                        this.props.alterarQuantidadeAtual(
                                          quoteEntitySelectValue.id,
                                          'material',
                                          quoteMaterial.id,
                                          quoteMaterial.medicineMaterial.id,
                                          parseFloat(String(quantidade - quoteMaterial.currentQuantity)).toFixed(0),
                                          itenSel,
                                          isExternalStock,
                                          textBatch
                                        )
                                      }
                                      entity={quoteMaterial}
                                      entityTableName={'material'}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      ) : (
                        <AvInput type="hidden" name="quoteMaterial" value={this.state.fieldsBase[baseFilters + 'Id']} />
                      )}

                      {quoteEntitySelectValue.quoteMedicine && quoteEntitySelectValue.quoteMedicine.length > 0 ? (
                        <div className={'mt-2 col-lg-12'}>
                          <h4>
                            <Translate contentKey="generadorApp.quoteMedicine.home.title">Medicamentos</Translate>
                          </h4>
                          <Table
                            id="quote-medicine-table"
                            aria-describedby="quote-heading"
                            className={'table-hover table-striped mt-1 table-responsive-css'}
                          >
                            <thead className={'thead-light'}>
                              <tr>
                                <th className={'align-middle text-center'} style={{ width: '30%' }}>
                                  <Translate contentKey="generadorApp.medicineMaterial.name">Nome</Translate>
                                </th>
                                <th className={'align-middle text-center'} style={{ width: '10%' }}>
                                  <Translate contentKey="generadorApp.quoteMedicine.frequency">Frequency</Translate>
                                </th>
                                <th className={'align-middle text-center'} style={{ width: '10%' }}>
                                  <Translate contentKey="generadorApp.quoteMedicine.currentQuantity">Qtd. atual</Translate>
                                </th>
                                <th className={'align-middle text-center'} style={{ width: '10%' }}>
                                  <Translate contentKey="generadorApp.quoteMedicine.days">Qtd. total</Translate>
                                </th>
                                <th className={'align-middle text-center'} style={{ width: '10%' }}>
                                  <Translate contentKey="generadorApp.quoteMedicine.startDate">Data inicial</Translate>
                                </th>
                                <th className={'align-middle text-center'} style={{ width: '10%' }}>
                                  <Translate contentKey="generadorApp.quoteMedicine.finishDate">Data fim</Translate>
                                </th>
                                <th className={'align-middle text-right'} style={{ width: '10%' }}>
                                  <Translate contentKey="generadorApp.quoteMedicine.romaneioStatus">Status</Translate>
                                </th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {quoteEntitySelectValue.quoteMedicine.map((quoteMedicine, i) => (
                                <tr key={`entity-${i}`} className={quoteMedicine.additive ? 'alert alert-warning' : ''}>
                                  <td className="text-center">
                                    {quoteMedicine.medicineMaterial ? quoteMedicine.medicineMaterial.name : ''}
                                  </td>
                                  <td className="text-center">{`${quoteMedicine.frequency}`}</td>
                                  <td className="text-center">{`${
                                    quoteMedicine.currentQuantity ? parseFloat(String(quoteMedicine.currentQuantity)).toFixed(0) : '-'
                                  }`}</td>
                                  <td className="text-center">{`${
                                    quoteMedicine.days ? parseFloat(String(quoteMedicine.days)).toFixed(0) : '-'
                                  }`}</td>
                                  <td className="text-center">
                                    {quoteMedicine.startDate ? (
                                      <TextFormat type="date" value={quoteMedicine.startDate} format={APP_DATE_FORMAT} />
                                    ) : (
                                      '-'
                                    )}
                                  </td>
                                  <td className="text-center">
                                    {quoteMedicine.finishDate ? (
                                      <TextFormat type="date" value={quoteMedicine.finishDate} format={APP_DATE_FORMAT} />
                                    ) : (
                                      '-'
                                    )}
                                  </td>
                                  <td className="text-right">{`${quoteMedicine.romaneioStatus}`}</td>
                                  <td className="text-right">
                                    <ModalUpdateRomaneio
                                      alterarQuantidadeAtual1={(itenSel, isExternalStock, quantidade, textBatch) =>
                                        this.props.alterarQuantidadeAtual(
                                          quoteEntitySelectValue.id,
                                          'medicine',
                                          quoteMedicine.id,
                                          quoteMedicine.medicineMaterial.id,
                                          parseFloat(String(quantidade - quoteMedicine.currentQuantity)).toFixed(0),
                                          itenSel,
                                          isExternalStock,
                                          textBatch
                                        )
                                      }
                                      entity={quoteMedicine}
                                      entityTableName={'medicine'}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      ) : (
                        <AvInput type="hidden" name="quoteMedicine" value={this.state.fieldsBase[baseFilters + 'Id']} />
                      )}

                      {quoteEntitySelectValue.quoteDiet && quoteEntitySelectValue.quoteDiet.length > 0 ? (
                        <div className={'mt-2 col-lg-12'}>
                          <h4>
                            <Translate contentKey="generadorApp.quoteDiet.home.title">Dietas</Translate>
                          </h4>
                          <Table
                            id="quote-diet-table"
                            aria-describedby="quote-heading"
                            className={'table-hover table-striped mt-1 table-responsive-css'}
                          >
                            <thead className={'thead-light'}>
                              <tr>
                                <th className={'align-middle text-center'} style={{ width: '30%' }}>
                                  <Translate contentKey="generadorApp.medicineMaterial.name">Nome</Translate>
                                </th>
                                <th className={'align-middle text-center'} style={{ width: '10%' }}>
                                  <Translate contentKey="generadorApp.quoteDiet.frequency">Frequency</Translate>
                                </th>
                                <th className={'align-middle text-center'} style={{ width: '10%' }}>
                                  <Translate contentKey="generadorApp.quoteDiet.currentQuantity">Qtd. atual</Translate>
                                </th>
                                <th className={'align-middle text-center'} style={{ width: '10%' }}>
                                  <Translate contentKey="generadorApp.quoteMedicine.days">Qtd. total</Translate>
                                </th>
                                <th className={'align-middle text-center'} style={{ width: '10%' }}>
                                  <Translate contentKey="generadorApp.quoteDiet.startDate">Data inicial</Translate>
                                </th>
                                <th className={'align-middle text-center'} style={{ width: '10%' }}>
                                  <Translate contentKey="generadorApp.quoteDiet.finishDate">Data fim</Translate>
                                </th>
                                <th className={'align-middle text-right'} style={{ width: '10%' }}>
                                  <Translate contentKey="generadorApp.quoteDiet.romaneioStatus">Status</Translate>
                                </th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {quoteEntitySelectValue.quoteDiet.map((quoteDiet, i) => (
                                <tr key={`entity-${i}`} className={quoteDiet.additive ? 'alert alert-warning' : ''}>
                                  <td className="text-center">{quoteDiet.medicineMaterial ? quoteDiet.medicineMaterial.name : ''}</td>
                                  <td className="text-center">{`${quoteDiet.frequency}`}</td>
                                  <td className="text-center">{`${
                                    quoteDiet.currentQuantity ? parseFloat(String(quoteDiet.currentQuantity)).toFixed(0) : '-'
                                  }`}</td>
                                  <td className="text-center">{`${
                                    quoteDiet.days ? parseFloat(String(quoteDiet.days)).toFixed(0) : '-'
                                  }`}</td>
                                  <td className="text-center">
                                    {quoteDiet.startDate ? (
                                      <TextFormat type="date" value={quoteDiet.startDate} format={APP_DATE_FORMAT} />
                                    ) : (
                                      '-'
                                    )}
                                  </td>
                                  <td className="text-center">
                                    {quoteDiet.finishDate ? (
                                      <TextFormat type="date" value={quoteDiet.finishDate} format={APP_DATE_FORMAT} />
                                    ) : (
                                      '-'
                                    )}
                                  </td>
                                  <td className="text-right">{`${quoteDiet.romaneioStatus}`}</td>
                                  <td className="text-right">
                                    <ModalUpdateRomaneio
                                      alterarQuantidadeAtual1={(itenSel, isExternalStock, quantidade, textBatch) =>
                                        this.props.alterarQuantidadeAtual(
                                          quoteEntitySelectValue.id,
                                          'diet',
                                          quoteDiet.id,
                                          quoteDiet.medicineMaterial.id,
                                          parseFloat(String(quantidade - quoteDiet.currentQuantity)).toFixed(0),
                                          itenSel,
                                          isExternalStock,
                                          textBatch
                                        )
                                      }
                                      entity={quoteDiet}
                                      entityTableName={'diet'}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      ) : (
                        <AvInput type="hidden" name="quoteDiet" value={this.state.fieldsBase[baseFilters + 'Id']} />
                      )}
                    </>
                  ) : (
                    <Col md="12"></Col>
                  )}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const {
      quoteEntity,
      quoteDiets,
      quoteMedicines,
      quoteMaterials,
      quoteProductServices,
      quoteRhs,
      quoteFiles,
      expectedResults,
      patients,
      clients,
      loading,
      updating,
    } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        {this.renderModal()}
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...quoteEntity,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel>
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }

  renderModal() {
    if (!this.props.romaneioReport || this.state.showModalReport !== true) return <> </>;

    return (
      <Modal size="xl" isOpen toggle={() => this.setState({ showModalReport: false })}>
        <ModalBody id="generadorApp.quote.delete.question">
          <RomaneioPdfReport data={this.props.romaneioReport} userLogged={this.props.userLogged} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => this.setState({ showModalReport: false })}>
            <FontAwesomeIcon icon="ban" />
            &nbsp;
            <Translate contentKey="generadorApp.quote.btnCancel">Close</Translate>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
    listProcediment: storeState.patDrPrescrProcd.entities,
    listRulersList: storeState.clComRuler.entities,
    listItemsList: storeState.clComItem.entities,
    romaneioReport: storeState.quote.romaneioReport,
    userLogged: storeState.authentication.account,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
  createPad,
  getPad,
  createPharmacyStock,
  getPharmacyStock,
  alterarQuantidadeAtual,
  getRomaneioReport,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;
export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(RomaneioUpdate);
