/* eslint complexity: ["error", 500] */
import React from 'react';
import axios from 'axios';

import { connect } from 'react-redux';

import { TextFormat } from 'react-jhipster';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, Label, Table } from 'reactstrap';

import { Translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import {
  getEntity as getPatientNursingPrescription,
  reset as resetPatientNursingPrescription,
} from 'app/entities/patient-nursing-prescription/patient-nursing-prescription.reducer';

import {
  getEntities as getProcediments,
  updateEntity as updateProcediment,
} from 'app/entities/pat-nr-presc-procediment/pat-nr-presc-procediment.reducer.ts';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

import { PatNrPrescProcediment, mapStateToProps, mapDispatchToProps } from '../pat-nr-presc-procediment';
import { IRootState } from 'app/shared/reducers';
import Select from 'react-select';
import { translate } from 'react-jhipster';
import { APP_DATE_FORMAT, BASE_API_VERSION_PATH } from 'app/config/constants';
import moment from 'moment';
import { getValueRecursive } from 'app/shared/util/entity-utils';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { Status } from 'app/shared/model/enumerations/status.model';
import { convertDateFromServer } from 'app/shared/util/date-utils';

export interface IExtendedState {
  showModalStatus?: any;
  observationProcedimentStatusSelectValue?: any;
  statusProcedimentStatusSelectValue?: any;
}
export interface IExtendedProps {
  entityEmbebedPai?: any;
  setDataInicial?: Function;
  setDataFinal?: Function;
  dataInicial?: any;
  dataFinal?: any;
}

export class PatNrPrescProcedimentExtended extends PatNrPrescProcediment {
  renderHeader() {
    return <> {super.renderHeader()} </>;
  }

  renderFilter() {
    return (
      <>
        <Col md="12">
          <Row>
            {this.state.baseFilters !== 'createdDate' ? (
              <Col md="3">
                <Row className="mr-1 mt-1">
                  <Label id="createdDateStart" for="pat-dr-prescr-procd-createdDate_start">
                    <Translate contentKey="generadorApp.patDrPrescrProcd.createdDate_start">Created Date (start)</Translate>
                  </Label>
                  <DatePicker
                    onChange={value => this.props.setDataInicial(moment(value))}
                    id="createdDateStart"
                    className="form-control react-datepicker"
                    name="createdDateStart"
                    dateFormat="dd/MM/yyyy"
                    maxDate={this.props.dataFinal ? moment(this.props.dataFinal).toDate() : null}
                    selected={this.props.dataInicial ? moment(this.props.dataInicial).toDate() : null}
                    locale="pt"
                  />
                </Row>
              </Col>
            ) : null}
            {this.state.baseFilters !== 'createdDate' ? (
              <Col md="3">
                <Row className="mr-1 mt-1">
                  <Label id="createdDateEnd" for="pat-dr-prescr-procd-createdDate_end">
                    <Translate contentKey="generadorApp.patDrPrescrProcd.createdDate_end">Created Date (end)</Translate>
                  </Label>
                  <DatePicker
                    onChange={value => this.props.setDataFinal(moment(value))}
                    id="createdDateEnd"
                    className="form-control react-datepicker"
                    name="createdDateEnd"
                    dateFormat="dd/MM/yyyy"
                    minDate={this.props.dataInicial ? moment(this.props.dataInicial).toDate() : null}
                    selected={this.props.dataFinal ? moment(this.props.dataFinal).toDate() : null}
                    locale="pt"
                  />
                </Row>
              </Col>
            ) : null}
          </Row>
        </Col>
        <br />
      </>
    );
  }

  renderTable() {
    const { nrStatusHistories, patientNursingPrescriptions, patNrPrescProcedimentList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {patNrPrescProcedimentList &&
        patNrPrescProcedimentList.filter(
          v =>
            (typeof v.deletedAt === 'undefined' || v.deletedAt === null) &&
            moment(v.initialDate).toDate() >= moment(this.props.dataInicial).toDate() &&
            (moment(v.finalDate).toDate() <= moment(this.props.dataFinal).toDate() || v.continuous)
        ).length > 0 ? (
          <>
            <Table
              id="pat-nr-presc-procediment-table-list"
              responsive
              aria-describedby="pat-nr-presc-procediment-heading"
              className={'table-hover table-striped table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand">
                    <Translate contentKey="generadorApp.patNrPrescProcediment.field_id">ID</Translate>
                  </th>
                  {state.baseFilters !== 'order' ? (
                    <th className="hand">
                      <Translate contentKey="generadorApp.patNrPrescProcediment.order">Order</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'frequency' ? (
                    <th className="hand">
                      <Translate contentKey="generadorApp.patNrPrescProcediment.frequency">Frequency</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'period' ? (
                    <th className="hand text-center">
                      <Translate contentKey="generadorApp.patNrPrescProcediment.period">Period</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'initial_date' ? (
                    <th className="hand">
                      <Translate contentKey="generadorApp.patDrPrescrProcd.initialDate">Initial date</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'final_date' ? (
                    <th className="hand">
                      <Translate contentKey="generadorApp.patDrPrescrProcd.finalDate">Final date</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'continuous' ? (
                    <th className="hand text-center">
                      <Translate contentKey="generadorApp.patDrPrescrProcd.continuous">Continuous</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'status' ? (
                    <th className="hand text-center">
                      <Translate contentKey="generadorApp.patNrPrescProcediment.status">Status</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'description' ? (
                    <th>
                      <Translate contentKey="generadorApp.patNrPrescProcediment.description">
                        Patient Nursing Prescription
                      </Translate>
                    </th>
                  ) :
                  
                  null}

                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {patNrPrescProcedimentList
                  .filter(
                    v =>
                      (typeof v.deletedAt === 'undefined' || v.deletedAt === null) &&
                      moment(v.initialDate).toDate() >= moment(this.props.dataInicial).toDate() &&
                      (moment(v.finalDate).toDate() <= moment(this.props.dataFinal).toDate() || v.continuous)
                  )
                  .sort((a, b) => a.order - b.order)
                  .sort((a, b) => (b.status === Status.ATIVO ? 1 : 0) - (a.status === Status.ATIVO ? 1 : 0))
                  .map((patNrPrescProcediment, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.patNrPrescProcediment.field_id">ID</Translate>
                        </b>
                        {patNrPrescProcediment.id}
                      </td>

                      {state.baseFilters !== 'order' ? (
                        <td id="order-cell">
                          <b className="visible-xs"> Order </b>

                          {patNrPrescProcediment.order ? patNrPrescProcediment.order : 0}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'frequency' ? (
                        <td id="frequency-cell">
                          <b className="visible-xs"> Frequency </b>
                          {patNrPrescProcediment.frequency ? (
                            <Translate contentKey={`generadorApp.QuoteFrequency.${patNrPrescProcediment.frequency}`} />
                          ) : (
                            <> </>
                          )}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'period' ? (
                        <td id="period-cell" className="text-center">
                          <b className="visible-xs"> Period </b>

                          {patNrPrescProcediment.period ? patNrPrescProcediment.period.replaceAll('_', '-') : ''}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'initial_date' ? (
                        <td id="initialDate-cell">
                          <b className="visible-xs"> Initial date </b>

                          {convertDateFromServer(patNrPrescProcediment.initialDate)}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'final_date' ? (
                        <td id="finalDate-cell">
                          <b className="visible-xs"> Final date </b>

                          {convertDateFromServer(patNrPrescProcediment.finalDate)}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'continuous' ? (
                        <td id="continuous-cell" className={'text-center'}>
                          <b className="visible-xs"> Continuous </b>

                          <b> {patNrPrescProcediment.continuous ? String.fromCharCode(10003) : ''}</b>
                        </td>
                      ) : null}

                      {state.baseFilters !== 'status' ? (
                        <td id="status-cell" className={'text-center'}>
                          <b className="visible-xs"> Status </b>
                          {patNrPrescProcediment.status ? (
                            <Translate contentKey={`generadorApp.Status.${patNrPrescProcediment.status}`} />
                          ) : (
                            <> </>
                          )}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'description' ? (
                        <td id="patientNursingPrescription-cell">
                          <b className="visible-xs"> Patient Nursing Prescription </b>
                          {patNrPrescProcediment ? (
                            <span data-type-rel="2-many-to-one-other-side">
                              <abbr title={patNrPrescProcediment.description}>{patNrPrescProcediment.description}</abbr>
                            </span>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : null}

                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          {hasAnyAuthority(props.userAccount, ['pat-nr-presc-procediment'], 'edit') ? (
                            <Button onClick={() => this.openUpdateModal(patNrPrescProcediment)} style={{width: 40}} color="primary" size="sm">
                              <FontAwesomeIcon icon="pencil-alt" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                          {hasAnyAuthority(props.userAccount, ['pat-nr-presc-procediment'], 'canDelete') ? (
                            <Button onClick={() => this.deleteEntity(patNrPrescProcediment)} color="danger" size="sm">
                              <FontAwesomeIcon icon="trash" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                          <Button
                            onClick={() =>
                              this.setState({
                                statusProcedimentStatusSelectValue: patNrPrescProcediment.status,
                                showModalStatus: patNrPrescProcediment,
                              })
                            }
                            color="info"
                            size="sm"
                          >
                            <FontAwesomeIcon icon="check-square" />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.patNrPrescProcediment.home.notFound">No Procedures found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }
  renderFooter() {
    return <> {super.renderFooter()} </>;
  }

  renderStatusModal() {
    const entityProcediment = this.state.showModalStatus;
    const { loading } = this.props;
    const nrStatusHistoryList = entityProcediment
      ? entityProcediment.nrStatusHistory
          .filter(v => v.deletedAt === null)
          .sort((a, b) => {
            return moment(b.createdDate).unix() - moment(a.createdDate).unix();
          })
      : [];

    return (
      <>
        <Modal isOpen={this.state.showModalStatus !== undefined} size={'lg'} toggle={() => this.setState({ showModalStatus: undefined })}>
          <ModalHeader toggle={() => this.setState({ showModalStatus: undefined })}>
            <Translate contentKey="generadorApp.patNrPrescProcediment.home.editLabel">Edit a PatientNursingPrescription</Translate>
          </ModalHeader>
          <ModalBody id="generadorApp.patNrPrescProcediment.delete.question">
            <Row className="justify-content-center">
              <Col md="11">
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <div>
                    <Row>
                      <Col md="12">
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="statusLabel" for="nr-status-history-status">
                              <Translate contentKey="generadorApp.nrStatusHistory.status">Status</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="nr-status-history-status"
                              className={'css-select-control'}
                              value={{
                                value: this.state.statusProcedimentStatusSelectValue,
                                label: translate('generadorApp.Status.' + this.state.statusProcedimentStatusSelectValue),
                              }}
                              options={[
                                { value: 'ATIVO', label: translate('generadorApp.Status.ATIVO') },
                                { value: 'INATIVADO', label: translate('generadorApp.Status.INATIVADO') },
                              ]}
                              onChange={(options: any) => this.setState({ statusProcedimentStatusSelectValue: options['value'] })}
                              name="status"
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col md="12">
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="observationLabel" for="nr-status-history-observation">
                              <Translate contentKey="generadorApp.nrStatusHistory.observation">Observation</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ observationProcedimentStatusSelectValue: evt.target.value })}
                              value={this.state.observationProcedimentStatusSelectValue}
                              id="nr-status-history-observation"
                              type="text"
                              name="observation"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col md="12">
                        <br />
                        <Button
                          className="pull-right"
                          color="primary"
                          onClick={async () => {
                            const result = await axios.post(BASE_API_VERSION_PATH + 'nr-status-histories', {
                              observation: this.state.observationProcedimentStatusSelectValue,
                              status: this.state.statusProcedimentStatusSelectValue,
                              patNrPrescProcediment: { id: entityProcediment.id, status: this.state.statusProcedimentStatusSelectValue },
                            });
                            const result1 = await axios.put(BASE_API_VERSION_PATH + 'pat-nr-presc-procediments', {
                              id: entityProcediment.id,
                              status: this.state.statusProcedimentStatusSelectValue,
                            });
                            const result2 = await axios.put(BASE_API_VERSION_PATH + 'patient-nursing-prescriptions', {
                              id: this.props.match.params['idPatientNursingPrescription'] * 1,
                            });
                            this.props.resetPatientNursingPrescription();
                            this.props.getPatientNursingPrescription(this.props.match.params['idPatientNursingPrescription']);
                            this.setState({
                              showModalStatus: undefined,
                              observationProcedimentStatusSelectValue: '',
                              statusProcedimentStatusSelectValue: '',
                            });
                          }}
                        >
                          <FontAwesomeIcon icon="save" />
                          &nbsp;
                          <Translate contentKey="generadorApp.patNrPrescProcediment.btnSave">btnSave</Translate>
                        </Button>
                      </Col>
                    </Row>
                    <br />
                    <br />
                    <>
                      {nrStatusHistoryList && nrStatusHistoryList.length > 0 ? (
                        <Table
                          id="nr-status-history-table-list"
                          responsive
                          aria-describedby="nr-status-history-heading"
                          className={'table-hover table-striped table-responsive-css'}
                        >
                          <thead className={'thead-light'}>
                            <tr>
                              <th>
                                <Translate contentKey="generadorApp.nrStatusHistory.status">Status</Translate>
                              </th>
                              <th>
                                <Translate contentKey="generadorApp.nrStatusHistory.observation">Observation</Translate>
                              </th>
                              <th>
                                <Translate contentKey="generadorApp.nrStatusHistory.createdDate">Created Date</Translate>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr></tr>
                            {nrStatusHistoryList
                              .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                              .map((nrStatusHistory, i) => (
                                <tr key={`entity-${i}`}>
                                  <td id="status-cell">
                                    <b className="visible-xs"> Status </b>
                                    {nrStatusHistory.status ? (
                                      <Translate contentKey={`generadorApp.Status.${nrStatusHistory.status}`} />
                                    ) : (
                                      <> </>
                                    )}
                                  </td>
                                  <td id="observation-cell">
                                    <b className="visible-xs"> Observation </b>

                                    {nrStatusHistory.observation}
                                  </td>

                                  <td id="createdDate-cell">
                                    <b className="visible-xs"> Created Date </b>
                                    <TextFormat type="date" value={nrStatusHistory.createdDate} format={APP_DATE_FORMAT} />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      ) : !this.props.loading ? (
                        <div className="alert alert-warning">
                          <Translate contentKey="generadorApp.nrStatusHistory.home.notFound">No Status Histories found</Translate>
                        </div>
                      ) : (
                        <div />
                      )}
                    </>
                  </div>
                )}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
      </>
    );
  }

  render() {
    return (
      <>
        {' '}
        {this.renderStatusModal()}
        {super.render()}{' '}
      </>
    );
  }
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
  getPatientNursingPrescription,
  updateProcediment,
  getProcediments,
  resetPatientNursingPrescription,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;

export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(PatNrPrescProcedimentExtended);
