/* eslint complexity: ["error", 300] */
/* eslint-disable no-console */

import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { getAllEntities as getPermissions } from 'app/entities/permission/permission.reducer';

import { Table, Button } from 'reactstrap';
import { reset as resetPermissions } from 'app/entities/permission/permission.reducer';

import { Translate, translate } from 'app/config/translate-component';
import { getFilterFromSelect, showFieldsSelectAsync } from 'app/shared/util/entity-utils';

import { PermissionUser, mapStateToProps, mapDispatchToProps, IPermissionUserProps } from '../permission-user';
import { permissionUserSelectAll, permissionUserUnselectAll } from 'app/modules/administration/user-management/user-management.reducer';
import { createEntity } from '../permission-user.reducer';
import { IRootState } from 'app/shared/reducers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { BASE_API_VERSION_PATH } from 'app/config/constants';

export interface IExtendedState {
  permissionBooleanList?: any;
}
export interface IExtendedProps {
  entityEmbebedPai?: any;
}

export class PermissionUserExtended extends PermissionUser {
  constructor(props: IPermissionUserProps) {
    super(props);
    this.permissionSelectAll = this.permissionSelectAll.bind(this);
    this.permissionUnselectAll = this.permissionUnselectAll.bind(this);
    this.state = {
      ...this.state,
      itemsPerPage: 10000,
    };

    axios
      .get('/api/users/id/' + this.state['userId'][0]['value'], {
        headers: {
          'Select-Fields': 'id',
        },
      })
      .then(result => {
        this.setState({
          userId: [
            {
              ...result['data'],
              value: result['data'].id,
              label: showFieldsSelectAsync(result['data'], 'login'),
            },
          ],
        });
      });
  }

  componentDidUpdate(prevProps) {
    const { permissions, permissionUserList, loading } = this.props;
    const { permissionBooleanList } = this.state;
    console.info({ loading, permissionBooleanList, permissions, permissionUserList });
    if (permissionBooleanList === undefined && permissions && permissions.length > 0 && !loading) {
      const _permissionBooleanList = {};
      permissionUserList.map((permissionProfile, i) => {
        if (permissionProfile.permission && permissionProfile.permission.id) {
          _permissionBooleanList[permissionProfile.permission.id] = permissionProfile;
        }
      });
      this.setState({ permissionBooleanList: _permissionBooleanList });
    }
  }

  UNSAFE_componentWillMount() {
    this.getEntities();
    this.props.getPermissions();
  }

  permissionSelectAll = permissionUser => {
    const { permissions } = this.props;

    const getEntities = this.getEntities;

    this.props.resetPermissions();

    new Promise(resolve => {
      resolve(this.props.permissionUserSelectAll(permissionUser, permissions));
    }).then(resultEntity => {
      getEntities();
      this.props.getPermissions();
      // getUsers();
    });
  };
  permissionUnselectAll = permissionUser => {
    const { permissions } = this.props;

    const getEntities = this.getEntities;

    this.props.resetPermissions();

    new Promise(resolve => {
      resolve(this.props.permissionUserUnselectAll(permissionUser, permissions));
    }).then(resultEntity => {
      getEntities();
      this.props.getPermissions();
      // getUsers();
    });
  };

  renderHeader() {
    const { permissions, users, permissionUserList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            <Translate contentKey="generadorApp.permissionUser.home.title">Permission Users</Translate>
          </span>
          <Button
            color="primary"
            className="float-right jh-create-entity"
            onClick={() => {
              new Promise(resolve => {
                resolve(
                  axios.post(BASE_API_VERSION_PATH + 'users/save-user-permission', {
                    id: this.state.userStartFilter,
                    permission: Object.values(this.state.permissionBooleanList),
                  })
                );
              }).then(resultEntity => {
                this.props.history.goBack();
              });
            }}
          >
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.profile.btnSave">Save</Translate>
          </Button>
          <Button
            color="info"
            className="float-right jh-create-entity"
            onClick={() => {
              this.props.history.goBack();
            }}
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.profile.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/admin/user-management/">
              <Translate contentKey="userManagement.home.title">Users</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.permissionUser.home.title">Permission Users</Translate>
          </li>
          <li className="breadcrumb-item active">{this.state['userId'][0]['label']}</li>
        </ol>
      </>
    );
  }

  renderFilter() {
    return <> </>;
  }

  renderTable() {
    const { permissions, users, permissionUserList } = this.props;
    const { permissionBooleanList } = this.state;

    const props = this.props;
    const state = this.state;
    return (
      <>
        {!this.props.loading && permissionBooleanList !== undefined && permissions && permissions.length > 0 ? (
          <div>
            <Button
              id="togglerFilterPermissionUser"
              color="default"
              onClick={() => this.permissionUnselectAll({ id: getFilterFromSelect(state.userId) })}
              className="float-right jh-create-entity"
            >
              <Translate contentKey="generadorApp.permission.un_select_all">Unselect all</Translate>
              &nbsp;
              <FontAwesomeIcon icon="toggle-off" />
            </Button>
            <Button
              id="togglerFilterPermissionUser"
              color="primary"
              onClick={() => this.permissionSelectAll({ id: getFilterFromSelect(state.userId) })}
              className="float-right jh-create-entity"
            >
              <Translate contentKey="generadorApp.permission.select_all">Select all</Translate>
              &nbsp;
              <FontAwesomeIcon icon="toggle-on" />
            </Button>
            <Table
              id="permission-user-table-list"
              responsive
              aria-describedby="permission-user-heading"
              className={'table-hover table-striped mt-4 table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th>
                    <Translate contentKey="generadorApp.permissionUser.permission">Permission</Translate>
                  </th>
                  {Object.keys(permissions[0])
                    .filter(
                      v =>
                        ![
                          'id',
                          'createdDate',
                          'lastModifiedDate',
                          'deletedAt',
                          'createdBy',
                          'lastModifiedBy',
                          'slug',
                          'name',
                          'permissionProfile',
                          'permissionUser',
                        ].includes(v)
                    )
                    .filter(v => permissions.map(x => x[v]).includes(true))
                    .map((v, i) => (
                      <th key={i}>
                        <Translate contentKey={'generadorApp.permissionUser.' + v}>{v}</Translate>
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {permissions.map((permissionUser, i) => (
                  <tr key={i}>
                    <td id="permission-cell">
                      <b className="visible-xs">
                        {' '}
                        <Translate contentKey="generadorApp.permissionUser.permission">Permission</Translate>{' '}
                      </b>
                      {translate(permissionUser.name)}
                    </td>

                    {Object.keys(permissions[i])
                      .filter(
                        v =>
                          ![
                            'id',
                            'createdDate',
                            'lastModifiedDate',
                            'deletedAt',
                            'createdBy',
                            'lastModifiedBy',
                            'slug',
                            'name',
                            'permissionProfile',
                            'permissionUser',
                          ].includes(v)
                      )
                      .filter(v => permissions.map(x => x[v]).includes(true))
                      .map((v, j) =>
                        permissions[i][v] === true ? (
                          <td key={j} id={v + '-cell'}>
                            <b className="visible-xs">
                              {' '}
                              <Translate contentKey={'generadorApp.permissionUser.' + v}>{v}</Translate>{' '}
                            </b>
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={v + '_' + permissionUser.id}
                                id={v + '_' + permissionUser.id}
                                checked={
                                  permissionBooleanList && permissionBooleanList[permissionUser.id]
                                    ? permissionBooleanList[permissionUser.id][v]
                                    : false
                                }
                                onChange={obj => {
                                  const _permissionBooleanList = JSON.parse(JSON.stringify(permissionBooleanList));
                                  if (!_permissionBooleanList[permissionUser.id]) {
                                    _permissionBooleanList[permissionUser.id] = {
                                      permission: permissions[i],
                                      user: this.state['userId'][0],
                                    };
                                  }
                                  _permissionBooleanList[permissionUser.id][v] = obj.target.checked;

                                  this.setState({ permissionBooleanList: _permissionBooleanList });
                                }}
                              />
                              <label htmlFor={v + '_' + permissionUser.id} />
                            </div>
                          </td>
                        ) : (
                          <td key={j} id={v + '-cell'}>
                            {' '}
                          </td>
                        )
                      )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    return <> </>;
  }

  render() {
    const { permissionUserList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel>
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
  createEntity,
  resetPermissions,
  getPermissions,
  permissionUserSelectAll,
  permissionUserUnselectAll,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;

export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(PermissionUserExtended);
