import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity, deleteEntity } from './white-label.reducer';

export interface IWhiteLabelDeleteDialogProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class WhiteLabelDeleteDialog extends React.Component<IWhiteLabelDeleteDialogProps> {
  UNSAFE_componentWillMount() {
    this.props.getEntity(this.props.match.params['id']);
  }

  confirmDelete = event => {
    this.props.deleteEntity(this.props.whiteLabelEntity.id);
    this.handleClose(event);
  };

  handleClose = event => {
    event.stopPropagation();
    this.props.history.goBack();
  };

  render() {
    const { whiteLabelEntity } = this.props;
    return (
      <Modal isOpen toggle={this.handleClose}>
        <ModalHeader toggle={this.handleClose}>
          <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>
        </ModalHeader>
        <ModalBody id="generadorApp.whiteLabel.delete.question">
          <Translate contentKey="generadorApp.whiteLabel.delete.question" interpolate={{ id: whiteLabelEntity.id }}>
            Are you sure you want to delete this WhiteLabel?
          </Translate>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.handleClose}>
            <FontAwesomeIcon icon="ban" />
            &nbsp;
            <Translate contentKey="generadorApp.whiteLabel.btnCancel">Cancel</Translate>
          </Button>
          <Button id="jhi-confirm-delete-whiteLabel" color="danger" onClick={this.confirmDelete}>
            <FontAwesomeIcon icon="trash" />
            &nbsp;
            <Translate contentKey="generadorApp.whiteLabel.btnDelete">Delete</Translate>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = ({ whiteLabel }: IRootState) => ({
  whiteLabelEntity: whiteLabel.entity,
});

const mapDispatchToProps = { getEntity, deleteEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(WhiteLabelDeleteDialog);
