/* eslint complexity: ["error", 500] */
import React from 'react';
import axios from 'axios';
import { convertDateTimeToServer } from 'app/shared/util/date-utils';
import CurrencyInput from 'react-currency-input';
import { connect } from 'react-redux';

import {
  getFilterFromSelect,
  getFormFromSelect,
  getListAxios,
  getValueRecursive,
  showFieldsSelectAsync,
} from 'app/shared/util/entity-utils';
import Select from 'react-select';
import { toast } from 'react-toastify';
import SelectAsync from 'react-select/async';
import { Link, RouteComponentProps } from 'react-router-dom';

import { Button, Col, Row, Table, Label, UncontrolledCollapse, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { JhiItemCount } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { IRootState } from 'app/shared/reducers';
import {
  updateEntity,
  getUrlBack,
  getQuoteRhState,
  IQuoteRhBaseState,
  getEntityFiltersURL,
  getEntities,
  IQuoteRhUpdateState as IBaseUpdateState,
  reset,
  getEntity,
  deleteEntity,
  createEntity,
} from './quote-rh.reducer';

import { APP_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE, IPaginationBaseState, getSortState } from 'app/shared/util/pagination.constants';

import { StatePropsExtended, DispatchPropsExtended, IExtendedState, IExtendedProps } from './extended/quote-rh';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';
import moment from 'moment';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

export interface IQuoteRhProps
  extends StateProps,
    DispatchProps,
    StatePropsExtended,
    DispatchPropsExtended,
    IExtendedProps,
    RouteComponentProps<{ url: string }> {
  entityEmbebedPai?: any;
}

export interface IQuoteRhState extends IQuoteRhBaseState, IPaginationBaseState, IBaseUpdateState, IExtendedState {
  showModalForm?: boolean;
  showModalEdit?: number;
  showModalEditView?: boolean;
}

export class QuoteRh extends React.Component<IQuoteRhProps, IQuoteRhState> {
  protected myFormRef: any;
  protected myFormRefNew: any;
  protected myFormRefEdit: any;

  startDateFileInput: any;

  finishDateFileInput: any;

  daysFileInput: any;

  quantityFileInput: any;

  unitaryAmountFileInput: any;

  unitaryAmountCostFileInput: any;

  grossAmountFileInput: any;

  typeDiscountFileInput: any;

  discountPercentFileInput: any;

  discountFileInput: any;

  finalAmountFileInput: any;

  frequencyFileInput: any;

  additiveFileInput: any;

  billQuantityItemFileInput: any;

  billGrossAmountFileInput: any;

  billFinalAmountFileInput: any;

  constructor(props: IQuoteRhProps) {
    super(props);
    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      listCheckedID: [],
      isCheckedAll: false,
      showModalForm: false,
      showModalEdit: null,
      urlBack: getUrlBack(this.props.location),
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      ...getQuoteRhState(this.props.location),
      quoteId: [
        {
          ...(this.props.entityEmbebedPai ? this.props.entityEmbebedPai : {}),
          value: this.props.match.params['idQuote'],
          label: this.props.match.params['idQuote'],
        },
      ],
      baseFilters: 'quote',
      clientGlosaItemSelectValue: null,

      expectedResultSelectValue: null,

      padAttendanceSelectValue: null,

      quoteSelectValue: {
        ...(this.props.entityEmbebedPai ? this.props.entityEmbebedPai : {}),
        value: this.props.match.params['idQuote'],
        label: this.props.match.params['idQuote'],
      },

      specialtySelectValue: null,

      startDateSelectValue: null,
      finishDateSelectValue: null,
      daysSelectValue: null,
      quantitySelectValue: null,
      unitaryAmountSelectValue: null,
      unitaryAmountCostSelectValue: null,
      grossAmountSelectValue: null,
      typeDiscountSelectValue: null,
      discountPercentSelectValue: null,
      discountSelectValue: null,
      finalAmountSelectValue: null,
      frequencySelectValue: null,
      additiveSelectValue: null,
      billQuantityItemSelectValue: null,
      billGrossAmountSelectValue: null,
      billFinalAmountSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getQuoteRhState(this.props.location),
        quoteId: [
          {
            ...(this.props.entityEmbebedPai ? this.props.entityEmbebedPai : {}),
            value: this.props.match.params['idQuote'],
            label: this.props.match.params['idQuote'],
          },
        ],
        baseFilters: 'quote',
      },
      specialtyName: null,
      isNew: false,
    };

    axios
      .get('/api/quotes/' + this.props.match.params['idQuote'], {
        headers: {
          'Select-Fields': 'id',
        },
      })
      .then(result => {
        this.setState({
          quoteSelectValue: {
            ...result['data'],
            value: this.props.match.params['idQuote'],
            label: showFieldsSelectAsync(result['data'], 'id'),
          },
        });
      });
  }

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({ listCheckedID: this.props.quoteRhList.map(quoteRh => quoteRh.id) });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({ listCheckedID: this.props.quoteRhList.map(quoteRh => quoteRh.id) });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  removeEntitySelected() {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  }

  saveEntityMany = () => {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  };

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  cancelFilters = () => {
    this.setState(
      {
        startDateStart: '',
        startDateEnd: '',
        finishDateStart: '',
        finishDateEnd: '',
        typeDiscount: '',
        days: '',
        quantity: '',
        unitaryAmount: '',
        unitaryAmountCost: '',
        grossAmount: '',
        discountPercent: '',
        discount: '',
        finalAmount: '',
        frequency: '',
        quoteId: '',
        specialtyId: '',
        additive: '',
        billQuantityItem: '',
        billFinalAmount: '',
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
        activePage: 0,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();

    const selectFieldsList = [
      'specialty.category',
      'specialty.name',
      'frequency',
      'typeDiscount',
      'startDate',
      'finishDate',
      'days',
      'quantity',
      'unitaryAmount',
      'unitaryAmountCost',
      'grossAmount',
      'discountPercent',
      'discount',
      'quote.id',
      'billQuantityItem',
      'billFinalAmount',
    ];
    const {
      startDateStart,
      startDateEnd,
      finishDateStart,
      finishDateEnd,
      typeDiscount,
      days,
      quantity,
      unitaryAmount,
      unitaryAmountCost,
      grossAmount,
      discountPercent,
      discount,
      finalAmount,
      frequency,
      quoteId,
      specialtyId,
      additive,
      billQuantityItem,
      billFinalAmount,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;
    return this.props.getEntities(
      startDateStart,
      startDateEnd,
      finishDateStart,
      finishDateEnd,
      typeDiscount,
      days,
      quantity,
      unitaryAmount,
      unitaryAmountCost,
      grossAmount,
      discountPercent,
      discount,
      finalAmount,
      frequency,
      getFilterFromSelect(quoteId, 'many-to-one'),
      getFilterFromSelect(specialtyId, 'many-to-one'),
      additive,
      billQuantityItem,
      billFinalAmount,
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(',')
    );
  };

  updateEntity = entity => {
    const selectFieldsList = [
      'specialty.category',
      'specialty.name',
      'frequency',
      'typeDiscount',
      'startDate',
      'finishDate',
      'days',
      'quantity',
      'unitaryAmount',
      'unitaryAmountCost',
      'grossAmount',
      'discountPercent',
      'discount',
      'quote.id',
      'billQuantityItem',
      'billFinalAmount',
    ];

    const {
      startDateStart,
      startDateEnd,
      finishDateStart,
      finishDateEnd,
      typeDiscount,
      days,
      quantity,
      unitaryAmount,
      unitaryAmountCost,
      grossAmount,
      discountPercent,
      discount,
      finalAmount,
      frequency,
      quoteId,
      specialtyId,
      additive,
      billQuantityItem,
      billFinalAmount,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;
    this.props.updateEntity(entity, [
      startDateStart,
      startDateEnd,
      finishDateStart,
      finishDateEnd,
      typeDiscount,
      days,
      quantity,
      unitaryAmount,
      unitaryAmountCost,
      grossAmount,
      discountPercent,
      discount,
      finalAmount,
      frequency,
      getFilterFromSelect(quoteId, 'many-to-one'),
      getFilterFromSelect(specialtyId, 'many-to-one'),
      additive,
      billQuantityItem,
      billFinalAmount,
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(','),
    ]);
  };

  saveEntity = (event: any, errors: any, values: any, isNew: any) => {
    const selectFieldsList = [
      'specialty.category',
      'specialty.name',
      'frequency',
      'typeDiscount',
      'startDate',
      'finishDate',
      'days',
      'quantity',
      'unitaryAmount',
      'unitaryAmountCost',
      'grossAmount',
      'discountPercent',
      'discount',
      'quote.id',
      'billQuantityItem',
      'billFinalAmount',
    ];

    values.startDate = convertDateTimeToServer(values.startDate);
    values.finishDate = convertDateTimeToServer(values.finishDate);

    if (errors.length === 0) {
      const { quoteRhEntity } = this.props;
      const _entity = {
        ...quoteRhEntity,
        // ...values,

        quote: getFormFromSelect(this.state.quoteSelectValue, 'many-to-one'), // many-to-one - otherSide

        specialty: getFormFromSelect(this.state.specialtySelectValue, 'many-to-one'), // many-to-one - otherSide

        startDate: this.state.startDateSelectValue,
        finishDate: this.state.finishDateSelectValue,
        days: this.state.daysSelectValue,
        quantity: this.state.quantitySelectValue,
        unitaryAmount: this.state.unitaryAmountSelectValue,
        unitaryAmountCost: this.state.unitaryAmountCostSelectValue,
        grossAmount: this.state.grossAmountSelectValue,
        typeDiscount: this.state.typeDiscountSelectValue === null ? null : this.state.typeDiscountSelectValue,
        discountPercent: this.state.discountPercentSelectValue,
        discount: this.state.discountSelectValue,
        finalAmount: this.state.finalAmountSelectValue,
        frequency: this.state.frequencySelectValue === null ? null : this.state.frequencySelectValue,
        additive: this.state.additiveSelectValue === null ? false : this.state.additiveSelectValue,
        billQuantityItem: this.state.billQuantityItemSelectValue,
        billGrossAmount: this.state.billGrossAmountSelectValue,
        billFinalAmount: this.state.billFinalAmountSelectValue,
      };
      const entity = _entity;

      const {
        startDateStart,
        startDateEnd,
        finishDateStart,
        finishDateEnd,
        typeDiscount,
        days,
        quantity,
        unitaryAmount,
        unitaryAmountCost,
        grossAmount,
        discountPercent,
        discount,
        finalAmount,
        frequency,
        quoteId,
        specialtyId,
        additive,
        billQuantityItem,
        billFinalAmount,
        extraFilters,
        activePage,
        itemsPerPage,
        sort,
        order,
      } = this.state.fieldsBase;

      if (isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              startDateStart,
              startDateEnd,
              finishDateStart,
              finishDateEnd,
              typeDiscount,
              days,
              quantity,
              unitaryAmount,
              unitaryAmountCost,
              grossAmount,
              discountPercent,
              discount,
              finalAmount,
              frequency,
              getFilterFromSelect(quoteId, 'many-to-one'),
              getFilterFromSelect(specialtyId, 'many-to-one'),
              additive,
              billQuantityItem,
              billFinalAmount,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              startDateStart,
              startDateEnd,
              finishDateStart,
              finishDateEnd,
              typeDiscount,
              days,
              quantity,
              unitaryAmount,
              unitaryAmountCost,
              grossAmount,
              discountPercent,
              discount,
              finalAmount,
              frequency,
              getFilterFromSelect(quoteId, 'many-to-one'),
              getFilterFromSelect(specialtyId, 'many-to-one'),
              additive,
              billQuantityItem,
              billFinalAmount,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
      this.setState({ showModalForm: false });
      this.setState({ showModalEdit: null });
    } else {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.specialty.' + errors[0]) }));
    }
  };

  componentDidUpdate(prevProps) {}

  deleteEntity(quoteRhEntity) {
    MySwal.fire({
      title: <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>,
      html: (
        <Translate contentKey="generadorApp.quoteRh.delete.question" interpolate={{ id: quoteRhEntity.id }}>
          Are you sure you want to delete this QuoteRh?
        </Translate>
      ),
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#e70015',
      cancelButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="ban" />
          &nbsp; <Translate contentKey="generadorApp.quoteRh.btnCancel">Cancel</Translate>
        </>
      ),
      confirmButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="trash" />
          &nbsp;<Translate contentKey="generadorApp.quoteRh.btnDelete">Delete</Translate>
        </>
      ),
    }).then(async response => {
      if (response.isConfirmed) {
        await this.props.deleteEntity(quoteRhEntity.id, { reloadList: false });
        this.getEntities();
        MySwal.fire({
          title: <Translate contentKey="entity.delete.title.confirmed">Confirmed delete operation</Translate>,
          icon: 'success',
        });
      }
    });
  }

  renderNewModal() {
    return (
      <Modal size={'xl'} isOpen={this.state.showModalForm} toggle={() => this.setState({ showModalForm: false })}>
        <ModalHeader toggle={() => this.setState({ showModalForm: false })}>{this.renderHeaderUpdate({}, true)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefNew = el)}
          model={{}}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, true)}
        >
          <ModalBody>{this.renderBodyUpdate({}, true)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalForm: false })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.quoteRh.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefNew && this.myFormRefNew.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.quoteRh.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    );
  }

  getFullEntityFromState(v) {
    return {
      id: v.id,
      startDate: this.state.startDateSelectValue,
      finishDate: this.state.finishDateSelectValue,
      days: this.state.daysSelectValue,
      quantity: this.state.quantitySelectValue,
      unitaryAmount: this.state.unitaryAmountSelectValue,
      unitaryAmountCost: this.state.unitaryAmountCostSelectValue,
      grossAmount: this.state.grossAmountSelectValue,
      typeDiscount: this.state.typeDiscountSelectValue,
      discountPercent: this.state.discountPercentSelectValue,
      discount: this.state.discountSelectValue,
      finalAmount: this.state.finalAmountSelectValue,
      frequency: this.state.frequencySelectValue,
      additive: this.state.additiveSelectValue,
      billQuantityItem: this.state.billQuantityItemSelectValue,
      billGrossAmount: this.state.billGrossAmountSelectValue,
      billFinalAmount: this.state.billFinalAmountSelectValue,
      clientGlosaItem: this.state.clientGlosaItemSelectValue,
      expectedResult: this.state.expectedResultSelectValue,
      padAttendance: this.state.padAttendanceSelectValue,
      quote: this.state.quoteSelectValue,
      specialty: this.state.specialtySelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    let p: any = null;

    const clientGlosaItemEntity = v.clientGlosaItem
      ? v.clientGlosaItem.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' }))
      : v.clientGlosaItem;

    const expectedResultEntity = v.expectedResult
      ? v.expectedResult.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' }))
      : v.expectedResult;

    const padAttendanceEntity = v.padAttendance
      ? v.padAttendance.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' }))
      : v.padAttendance;

    p = v.quote;
    const quoteEntity = p ? { ...p, value: p.id, label: p['id'] ? p.id : '' } : p;

    p = v.specialty;
    const specialtyEntity = p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : p;

    return {
      startDateSelectValue: v.startDate ? moment(v.startDate).toDate() : null, // Instant,
      finishDateSelectValue: v.finishDate ? moment(v.finishDate).toDate() : null, // Instant,
      daysSelectValue: v.days, // days,
      quantitySelectValue: v.quantity, // quantity,
      unitaryAmountSelectValue: v.unitaryAmount, // unitaryAmount,
      unitaryAmountCostSelectValue: v.unitaryAmountCost, // unitaryAmount,
      grossAmountSelectValue: v.grossAmount, // grossAmount,
      typeDiscountSelectValue: v.typeDiscount, // typeDiscount,
      discountPercentSelectValue: v.discountPercent, // discountPercent,
      discountSelectValue: v.discount, // discount,
      finalAmountSelectValue: v.finalAmount, // finalAmount,
      frequencySelectValue: v.frequency, // frequency,
      additiveSelectValue: v.additive, // additive,
      billQuantityItemSelectValue: v.billQuantityItem, // billQuantityItem,
      billGrossAmountSelectValue: v.billGrossAmount, // billGrossAmount,
      billFinalAmountSelectValue: v.billFinalAmount, // billFinalAmount,
      clientGlosaItemSelectValue: clientGlosaItemEntity,
      expectedResultSelectValue: expectedResultEntity,
      padAttendanceSelectValue: padAttendanceEntity,
      quoteSelectValue: quoteEntity,
      specialtySelectValue: specialtyEntity,
    };
  }

  openUpdateModal(v, edit = true) {
    this.props.getEntity(v.id);
    this.setState({
      showModalEdit: v.id,
      showModalEditView: edit,
      ...this.getFullEntitySelectValue(v),
    });
  }

  renderUpdateModal() {
    const { quoteRhEntity, entityEmbebedPai } = this.props;
    return quoteRhEntity && quoteRhEntity.id ? (
      <Modal size={'xl'} isOpen={this.state.showModalEdit === quoteRhEntity.id} toggle={() => this.setState({ showModalEdit: null })}>
        <ModalHeader toggle={() => this.setState({ showModalEdit: null })}>{this.renderHeaderUpdate(quoteRhEntity, false)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefEdit = el)}
          model={{
            ...quoteRhEntity,
          }}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, false)}
        >
          <ModalBody>{this.renderBodyUpdate(quoteRhEntity, false)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalEdit: null })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.quoteRh.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefEdit && this.myFormRefEdit.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.quoteRh.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    ) : (
      <> </>
    );
  }

  renderHeaderUpdate(quoteRhEntity, isNew) {
    const { entityEmbebedPai, clientGlosaItems, expectedResults, padAttendances, quotes, specialties, loading } = this.props;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {isNew ? (
              <Translate contentKey="generadorApp.quoteRh.home.createLabel">Create a QuoteRh</Translate>
            ) : (
              <Translate contentKey="generadorApp.quoteRh.home.editLabel">Edit a QuoteRh</Translate>
            )}
          </span>
        </div>
      </>
    );
  }
  renderBodyUpdate(quoteRhEntity, isNew) {
    const { entityEmbebedPai, clientGlosaItems, expectedResults, padAttendances, quotes, specialties, loading } = this.props;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
              <Col md="3">
              <Label className="mt-2 label-single-line"for="quote-rh-id">
                <Translate contentKey="generadorApp.quoteRh.field_id">ID</Translate>
              </Label>
              </Col> */}
                      <Col md="12">
                        <AvInput id="quote-rh-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'startDate' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="startDateLabel" for="quote-rh-startDate">
                              <Translate contentKey="generadorApp.quoteRh.startDate">Start Date</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <DatePicker
                              autoComplete="off"
                              onChange={value => this.setState({ startDateSelectValue: value })}
                              id="startDate"
                              validate={{}}
                              className="form-control react-datepicker"
                              name="startDate"
                              dateFormat="dd/MM/yyyy"
                              selected={this.state.startDateSelectValue ? moment(this.state.startDateSelectValue).toDate() : null}
                              locale="pt"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ startDateSelectValue: evt.target.value })}
                      type="hidden"
                      name="startDate"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'typeDiscount' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="typeDiscountLabel" for="quote-rh-typeDiscount">
                              <Translate contentKey="generadorApp.quoteRh.typeDiscount">Type Discount (%)</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="quote-rh-typeDiscount"
                              className={'css-select-control'}
                              value={
                                this.state.typeDiscountSelectValue
                                  ? {
                                      value: this.state.typeDiscountSelectValue,
                                      label: translate('generadorApp.QuoteDiscountType.' + this.state.typeDiscountSelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.quoteRh.typeDiscount') }
                              }
                              options={[
                                { value: 'VALOR_UNITARIO', label: translate('generadorApp.QuoteDiscountType.VALOR_UNITARIO') },
                                { value: 'PORCENTO', label: translate('generadorApp.QuoteDiscountType.PORCENTO') },
                              ]}
                              onChange={(options: any) => this.setState({ typeDiscountSelectValue: options['value'] })}
                              name="typeDiscount"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ typeDiscountSelectValue: evt.target.value })}
                      type="hidden"
                      name="typeDiscount"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'finishDate' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="finishDateLabel" for="quote-rh-finishDate">
                              <Translate contentKey="generadorApp.quoteRh.finishDate">Finish Date</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <DatePicker
                              autoComplete="off"
                              onChange={value => this.setState({ finishDateSelectValue: value })}
                              id="finishDate"
                              validate={{}}
                              className="form-control react-datepicker"
                              name="finishDate"
                              dateFormat="dd/MM/yyyy"
                              selected={this.state.finishDateSelectValue ? moment(this.state.finishDateSelectValue).toDate() : null}
                              locale="pt"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ finishDateSelectValue: evt.target.value })}
                      type="hidden"
                      name="finishDate"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'days' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="daysLabel" for="quote-rh-days">
                              <Translate contentKey="generadorApp.quoteRh.days">Days</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ daysSelectValue: floatvalue })}
                              decimalSeparator=""
                              thousandSeparator=""
                              precision={0}
                              prefix=""
                              allowNegative={true}
                              value={this.state.daysSelectValue ? this.state.daysSelectValue : 0}
                              id="quote-rh-days"
                              name="days"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ daysSelectValue: evt.target.value })}
                      type="hidden"
                      name="days"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'quantity' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="quantityLabel" for="quote-rh-quantity">
                              <Translate contentKey="generadorApp.quoteRh.quantity">Quantity</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ quantitySelectValue: floatvalue })}
                              decimalSeparator=""
                              thousandSeparator=""
                              precision={0}
                              prefix=""
                              allowNegative={true}
                              value={this.state.quantitySelectValue ? this.state.quantitySelectValue : 0}
                              id="quote-rh-quantity"
                              name="quantity"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ quantitySelectValue: evt.target.value })}
                      type="hidden"
                      name="quantity"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'unitaryAmount' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="unitaryAmountLabel" for="quote-rh-unitaryAmount">
                              <Translate contentKey="generadorApp.quoteRh.unitaryAmount">Unitary Amount</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ unitaryAmountSelectValue: floatvalue })}
                              decimalSeparator=","
                              thousandSeparator=""
                              precision={2}
                              prefix=""
                              allowNegative={true}
                              value={this.state.unitaryAmountSelectValue ? this.state.unitaryAmountSelectValue : 0}
                              id="quote-rh-unitaryAmount"
                              name="unitaryAmount"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ unitaryAmountSelectValue: evt.target.value })}
                      type="hidden"
                      name="unitaryAmount"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'grossAmount' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="grossAmountLabel" for="quote-rh-grossAmount">
                              <Translate contentKey="generadorApp.quoteRh.grossAmount">Gross Amount</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ grossAmountSelectValue: floatvalue })}
                              decimalSeparator=","
                              thousandSeparator=""
                              precision={2}
                              prefix=""
                              allowNegative={true}
                              value={this.state.grossAmountSelectValue ? this.state.grossAmountSelectValue : 0}
                              id="quote-rh-grossAmount"
                              name="grossAmount"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ grossAmountSelectValue: evt.target.value })}
                      type="hidden"
                      name="grossAmount"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'discountPercent' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="discountPercentLabel" for="quote-rh-discountPercent">
                              <Translate contentKey="generadorApp.quoteRh.discountPercent">Discount (%)</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ discountPercentSelectValue: floatvalue })}
                              decimalSeparator=""
                              thousandSeparator=""
                              precision={0}
                              prefix=""
                              allowNegative={true}
                              value={this.state.discountPercentSelectValue ? this.state.discountPercentSelectValue : 0}
                              id="quote-rh-discountPercent"
                              name="discountPercent"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ discountPercentSelectValue: evt.target.value })}
                      type="hidden"
                      name="discountPercent"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'discount' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="discountLabel" for="quote-rh-discount">
                              <Translate contentKey="generadorApp.quoteRh.discount">Discount</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ discountSelectValue: floatvalue })}
                              decimalSeparator=","
                              thousandSeparator=""
                              precision={2}
                              prefix=""
                              allowNegative={true}
                              value={this.state.discountSelectValue ? this.state.discountSelectValue : 0}
                              id="quote-rh-discount"
                              name="discount"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ discountSelectValue: evt.target.value })}
                      type="hidden"
                      name="discount"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'finalAmount' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="finalAmountLabel" for="quote-rh-finalAmount">
                              <Translate contentKey="generadorApp.quoteRh.finalAmount">Final Amount</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ finalAmountSelectValue: floatvalue })}
                              decimalSeparator=","
                              thousandSeparator=""
                              precision={2}
                              prefix=""
                              allowNegative={true}
                              value={this.state.finalAmountSelectValue ? this.state.finalAmountSelectValue : 0}
                              id="quote-rh-finalAmount"
                              name="finalAmount"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ finalAmountSelectValue: evt.target.value })}
                      type="hidden"
                      name="finalAmount"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'frequency' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="frequencyLabel" for="quote-rh-frequency">
                              <Translate contentKey="generadorApp.quoteRh.frequency">Frequency</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="quote-rh-frequency"
                              className={'css-select-control'}
                              value={
                                this.state.frequencySelectValue
                                  ? {
                                      value: this.state.frequencySelectValue,
                                      label: translate('generadorApp.QuoteFrequency.' + this.state.frequencySelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.quoteRh.frequency') }
                              }
                              options={[
                                { value: 'UMXSEMANA', label: translate('generadorApp.QuoteFrequency.UMXSEMANA') },
                                { value: 'DOISXSEMANA', label: translate('generadorApp.QuoteFrequency.DOISXSEMANA') },
                                { value: 'TRESXSEMANA', label: translate('generadorApp.QuoteFrequency.TRESXSEMANA') },
                                { value: 'QUATROXSEMANA', label: translate('generadorApp.QuoteFrequency.QUATROXSEMANA') },
                                { value: 'CINCOXSEMANA', label: translate('generadorApp.QuoteFrequency.CINCOXSEMANA') },
                                { value: 'SEISXSEMANA', label: translate('generadorApp.QuoteFrequency.SEISXSEMANA') },
                                { value: 'UMXDIA', label: translate('generadorApp.QuoteFrequency.UMXDIA') },
                                { value: 'DOISXDIA', label: translate('generadorApp.QuoteFrequency.DOISXDIA') },
                                { value: 'TRESXDIA', label: translate('generadorApp.QuoteFrequency.TRESXDIA') },
                                { value: 'QUATROXDIA', label: translate('generadorApp.QuoteFrequency.QUATROXDIA') },
                                { value: 'CINCOXDIA', label: translate('generadorApp.QuoteFrequency.CINCOXDIA') },
                                { value: 'SEISXDIA', label: translate('generadorApp.QuoteFrequency.SEISXDIA') },
                                { value: 'UMXMES', label: translate('generadorApp.QuoteFrequency.UMXMES') },
                                { value: 'DOISXMES', label: translate('generadorApp.QuoteFrequency.DOISXMES') },
                                { value: 'TRESXMES', label: translate('generadorApp.QuoteFrequency.TRESXMES') },
                                { value: 'QUATROXMES', label: translate('generadorApp.QuoteFrequency.QUATROXMES') },
                                { value: 'CADA48HORAS', label: translate('generadorApp.QuoteFrequency.CADA48HORAS') },
                                { value: 'CADA72HORAS', label: translate('generadorApp.QuoteFrequency.CADA72HORAS') },
                                { value: 'PONTUAL', label: translate('generadorApp.QuoteFrequency.PONTUAL') },
                              ]}
                              onChange={(options: any) => this.setState({ frequencySelectValue: options['value'] })}
                              name="frequency"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ frequencySelectValue: evt.target.value })}
                      type="hidden"
                      name="frequency"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'quote' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="quote-rh-quote">
                              <Translate contentKey="generadorApp.quoteRh.quote">Quote</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="quote-rh-quote"
                              name={'quote'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.quoteSelectValue ? this.state.quoteSelectValue : ''}
                              onChange={options => this.setState({ quoteSelectValue: options })}
                              defaultOptions={this.state.quoteStartSelectOptions ? this.state.quoteStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.quoteStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.quoteStartSelectOptions === undefined) {
                                  const result = await getListAxios('quotes', {}, 0, 100, 'id,asc', 'id');
                                  this.setState({
                                    quoteStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios('quotes', { 'id.contains': inputValue }, 0, 100, 'id,asc', 'id');
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="quote"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}

                  {baseFilters !== 'specialty' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="quote-rh-specialty">
                              <Translate contentKey="generadorApp.quoteRh.specialty">Specialty</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="quote-rh-specialty"
                              name={'specialty'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.specialtySelectValue ? this.state.specialtySelectValue : ''}
                              onChange={options => this.setState({ specialtySelectValue: options })}
                              defaultOptions={this.state.specialtyStartSelectOptions ? this.state.specialtyStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.specialtyStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.specialtyStartSelectOptions === undefined) {
                                  const result = await getListAxios('specialties', {}, 0, 100, 'name,asc', 'name');
                                  this.setState({
                                    specialtyStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'specialties',
                                  { 'name.contains': inputValue },
                                  0,
                                  100,
                                  'name,asc',
                                  'name'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="specialty"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}

                  {baseFilters !== 'additive' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="additiveLabel" for="quote-rh-additive">
                              <Translate contentKey="generadorApp.quoteRh.additive">Additive</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'additive_checkbox'}
                                id={'additive_checkbox'}
                                checked={this.state.additiveSelectValue}
                                onChange={evt => this.setState({ additiveSelectValue: !this.state.additiveSelectValue })}
                              />
                              <label htmlFor={'additive_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ additiveSelectValue: evt.target.value })}
                      type="hidden"
                      name="additive"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'billQuantityItem' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="billQuantityItemLabel" for="quote-rh-billQuantityItem">
                              <Translate contentKey="generadorApp.quoteRh.billQuantityItem">Quantity</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ billQuantityItemSelectValue: floatvalue })}
                              decimalSeparator=""
                              thousandSeparator=""
                              precision={0}
                              prefix=""
                              allowNegative={true}
                              value={this.state.billQuantityItemSelectValue ? this.state.billQuantityItemSelectValue : 0}
                              id="quote-rh-billQuantityItem"
                              name="billQuantityItem"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ billQuantityItemSelectValue: evt.target.value })}
                      type="hidden"
                      name="billQuantityItem"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'billFinalAmount' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="billFinalAmountLabel" for="quote-rh-billFinalAmount">
                              <Translate contentKey="generadorApp.quoteRh.billFinalAmount">Final Amount</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ billFinalAmountSelectValue: floatvalue })}
                              decimalSeparator=","
                              thousandSeparator=""
                              precision={2}
                              prefix=""
                              allowNegative={true}
                              value={this.state.billFinalAmountSelectValue ? this.state.billFinalAmountSelectValue : 0}
                              id="quote-rh-billFinalAmount"
                              name="billFinalAmount"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ billFinalAmountSelectValue: evt.target.value })}
                      type="hidden"
                      name="billFinalAmount"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  renderHeader() {
    const { clientGlosaItems, expectedResults, padAttendances, quotes, specialties, quoteRhList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.quoteRh.home.title">Human Resources</Translate>
          </span>
          <Button id="togglerFilterQuoteRh" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.quoteRh.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          &nbsp;
          <Button
            onClick={() =>
              this.setState({
                showModalForm: true,
                startDateSelectValue: null,
                finishDateSelectValue: null,
                daysSelectValue: null,
                quantitySelectValue: null,
                unitaryAmountSelectValue: null,
                grossAmountSelectValue: null,
                typeDiscountSelectValue: null,
                discountPercentSelectValue: null,
                discountSelectValue: null,
                finalAmountSelectValue: null,
                frequencySelectValue: null,
                additiveSelectValue: null,
                billQuantityItemSelectValue: null,
                billGrossAmountSelectValue: null,
                billFinalAmountSelectValue: null,

                clientGlosaItemStartSelectOptions: undefined,
                clientGlosaItemSelectValue: null,

                expectedResultStartSelectOptions: undefined,
                expectedResultSelectValue: null,

                padAttendanceStartSelectOptions: undefined,
                padAttendanceSelectValue: null,

                specialtyStartSelectOptions: undefined,
                specialtySelectValue: null,
                isNew: true,
              })
            }
            color="primary"
            className="btn btn-primary float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="generadorApp.quoteRh.btnNewItem">Create new</Translate>
          </Button>{' '}
          &nbsp;
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/quote'}>
              <Translate contentKey="generadorApp.quote.home.title">Quotes</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/quote/' + this.props.match.params['idQuote']}>
              {this.state.quoteSelectValue ? this.state.quoteSelectValue.label : this.props.match.params['idQuote']}
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.quoteRh.home.title">Human Resources</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    const { clientGlosaItems, expectedResults, padAttendances, quotes, specialties, quoteRhList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <UncontrolledCollapse toggler="#togglerFilterQuoteRh">
          <CardBody>
            <AvForm ref={el => (this.myFormRef = el)} id="form-filter" onSubmit={this.filterEntity}>
              <div className="row mt-1 ml-3 mr-3">
                {this.state.baseFilters !== 'startDate' ? (
                  <Col md="3" className="col-filter-quote-rh-startDate">
                    <Row className="mr-1 mt-1">
                      <Label id="startDateStart" for="quote-rh-startDate_start">
                        <Translate contentKey="generadorApp.quoteRh.startDate_start">Start Date (start)</Translate>
                      </Label>
                      <DatePicker
                        onChange={value => this.setState({ startDateStart: value })}
                        id="startDateStart"
                        className="form-control react-datepicker"
                        name="startDateStart"
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.startDateStart ? moment(this.state.startDateStart).toDate() : null}
                        locale="pt"
                      />
                    </Row>
                  </Col>
                ) : null}
                {this.state.baseFilters !== 'startDate' ? (
                  <Col md="3" className="col-filter-quote-rh-startDate-final">
                    <Row className="mr-1 mt-1">
                      <Label id="startDateEnd" for="quote-rh-startDate_end">
                        <Translate contentKey="generadorApp.quoteRh.startDate_end">Start Date (end)</Translate>
                      </Label>
                      <DatePicker
                        onChange={value => this.setState({ startDateEnd: value })}
                        id="startDateEnd"
                        className="form-control react-datepicker"
                        name="startDateEnd"
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.startDateEnd ? moment(this.state.startDateEnd).toDate() : null}
                        locale="pt"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'finishDate' ? (
                  <Col md="3" className="col-filter-quote-rh-finishDate">
                    <Row className="mr-1 mt-1">
                      <Label id="finishDateStart" for="quote-rh-finishDate_start">
                        <Translate contentKey="generadorApp.quoteRh.finishDate_start">Finish Date (start)</Translate>
                      </Label>
                      <DatePicker
                        onChange={value => this.setState({ finishDateStart: value })}
                        id="finishDateStart"
                        className="form-control react-datepicker"
                        name="finishDateStart"
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.finishDateStart ? moment(this.state.finishDateStart).toDate() : null}
                        locale="pt"
                      />
                    </Row>
                  </Col>
                ) : null}
                {this.state.baseFilters !== 'finishDate' ? (
                  <Col md="3" className="col-filter-quote-rh-finishDate-final">
                    <Row className="mr-1 mt-1">
                      <Label id="finishDateEnd" for="quote-rh-finishDate_end">
                        <Translate contentKey="generadorApp.quoteRh.finishDate_end">Finish Date (end)</Translate>
                      </Label>
                      <DatePicker
                        onChange={value => this.setState({ finishDateEnd: value })}
                        id="finishDateEnd"
                        className="form-control react-datepicker"
                        name="finishDateEnd"
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.finishDateEnd ? moment(this.state.finishDateEnd).toDate() : null}
                        locale="pt"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'typeDiscount' ? (
                  <Col md="3" className="col-filter-quote-rh-typeDiscount">
                    <Row className="mr-1 mt-1">
                      <Label id="typeDiscountLabel" for="quote-rh-typeDiscount">
                        <Translate contentKey="generadorApp.quoteRh.typeDiscount">Type Discount (%)</Translate>
                      </Label>
                      <Select
                        id="quote-rh-typeDiscount"
                        className={'css-select-control'}
                        value={
                          this.state.typeDiscount
                            ? {
                                value: this.state.typeDiscount,
                                label: translate('generadorApp.QuoteDiscountType.' + this.state.typeDiscount),
                              }
                            : { value: '', label: translate('generadorApp.quoteRh.typeDiscount') }
                        }
                        options={[
                          { value: '', label: translate('generadorApp.quoteRh.typeDiscount') },
                          { value: 'VALOR_UNITARIO', label: translate('generadorApp.QuoteDiscountType.VALOR_UNITARIO') },
                          { value: 'PORCENTO', label: translate('generadorApp.QuoteDiscountType.PORCENTO') },
                        ]}
                        onChange={(options: any) => this.setState({ typeDiscount: options['value'] })}
                        name="typeDiscount"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'days' ? (
                  <Col md="3" className="col-filter-quote-rh-days">
                    <Row className="mr-1 mt-1">
                      <Label id="daysLabel" for="quote-rh-days">
                        <Translate contentKey="generadorApp.quoteRh.days">Days</Translate>
                      </Label>
                      <AvInput type="number" name="days" id="quote-rh-days" value={this.state.days} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'quantity' ? (
                  <Col md="3" className="col-filter-quote-rh-quantity">
                    <Row className="mr-1 mt-1">
                      <Label id="quantityLabel" for="quote-rh-quantity">
                        <Translate contentKey="generadorApp.quoteRh.quantity">Quantity</Translate>
                      </Label>
                      <AvInput type="number" name="quantity" id="quote-rh-quantity" value={this.state.quantity} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'unitaryAmount' ? (
                  <Col md="3" className="col-filter-quote-rh-unitaryAmount">
                    <Row className="mr-1 mt-1">
                      <Label id="unitaryAmountLabel" for="quote-rh-unitaryAmount">
                        <Translate contentKey="generadorApp.quoteRh.unitaryAmount">Unitary Amount</Translate>
                      </Label>

                      <AvInput type="text" name="unitaryAmount" id="quote-rh-unitaryAmount" value={this.state.unitaryAmount} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'grossAmount' ? (
                  <Col md="3" className="col-filter-quote-rh-grossAmount">
                    <Row className="mr-1 mt-1">
                      <Label id="grossAmountLabel" for="quote-rh-grossAmount">
                        <Translate contentKey="generadorApp.quoteRh.grossAmount">Gross Amount</Translate>
                      </Label>

                      <AvInput type="text" name="grossAmount" id="quote-rh-grossAmount" value={this.state.grossAmount} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'discountPercent' ? (
                  <Col md="3" className="col-filter-quote-rh-discountPercent">
                    <Row className="mr-1 mt-1">
                      <Label id="discountPercentLabel" for="quote-rh-discountPercent">
                        <Translate contentKey="generadorApp.quoteRh.discountPercent">Discount (%)</Translate>
                      </Label>
                      <AvInput type="number" name="discountPercent" id="quote-rh-discountPercent" value={this.state.discountPercent} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'discount' ? (
                  <Col md="3" className="col-filter-quote-rh-discount">
                    <Row className="mr-1 mt-1">
                      <Label id="discountLabel" for="quote-rh-discount">
                        <Translate contentKey="generadorApp.quoteRh.discount">Discount</Translate>
                      </Label>

                      <AvInput type="text" name="discount" id="quote-rh-discount" value={this.state.discount} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'finalAmount' ? (
                  <Col md="3" className="col-filter-quote-rh-finalAmount">
                    <Row className="mr-1 mt-1">
                      <Label id="finalAmountLabel" for="quote-rh-finalAmount">
                        <Translate contentKey="generadorApp.quoteRh.finalAmount">Final Amount</Translate>
                      </Label>

                      <AvInput type="text" name="finalAmount" id="quote-rh-finalAmount" value={this.state.finalAmount} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'frequency' ? (
                  <Col md="3" className="col-filter-quote-rh-frequency">
                    <Row className="mr-1 mt-1">
                      <Label id="frequencyLabel" for="quote-rh-frequency">
                        <Translate contentKey="generadorApp.quoteRh.frequency">Frequency</Translate>
                      </Label>
                      <Select
                        id="quote-rh-frequency"
                        className={'css-select-control'}
                        value={
                          this.state.frequency
                            ? { value: this.state.frequency, label: translate('generadorApp.QuoteFrequency.' + this.state.frequency) }
                            : { value: '', label: translate('generadorApp.quoteRh.frequency') }
                        }
                        options={[
                          { value: '', label: translate('generadorApp.quoteRh.frequency') },
                          { value: 'UMXSEMANA', label: translate('generadorApp.QuoteFrequency.UMXSEMANA') },
                          { value: 'DOISXSEMANA', label: translate('generadorApp.QuoteFrequency.DOISXSEMANA') },
                          { value: 'TRESXSEMANA', label: translate('generadorApp.QuoteFrequency.TRESXSEMANA') },
                          { value: 'QUATROXSEMANA', label: translate('generadorApp.QuoteFrequency.QUATROXSEMANA') },
                          { value: 'CINCOXSEMANA', label: translate('generadorApp.QuoteFrequency.CINCOXSEMANA') },
                          { value: 'SEISXSEMANA', label: translate('generadorApp.QuoteFrequency.SEISXSEMANA') },
                          { value: 'UMXDIA', label: translate('generadorApp.QuoteFrequency.UMXDIA') },
                          { value: 'DOISXDIA', label: translate('generadorApp.QuoteFrequency.DOISXDIA') },
                          { value: 'TRESXDIA', label: translate('generadorApp.QuoteFrequency.TRESXDIA') },
                          { value: 'QUATROXDIA', label: translate('generadorApp.QuoteFrequency.QUATROXDIA') },
                          { value: 'CINCOXDIA', label: translate('generadorApp.QuoteFrequency.CINCOXDIA') },
                          { value: 'SEISXDIA', label: translate('generadorApp.QuoteFrequency.SEISXDIA') },
                          { value: 'UMXMES', label: translate('generadorApp.QuoteFrequency.UMXMES') },
                          { value: 'DOISXMES', label: translate('generadorApp.QuoteFrequency.DOISXMES') },
                          { value: 'TRESXMES', label: translate('generadorApp.QuoteFrequency.TRESXMES') },
                          { value: 'QUATROXMES', label: translate('generadorApp.QuoteFrequency.QUATROXMES') },
                          { value: 'CADA48HORAS', label: translate('generadorApp.QuoteFrequency.CADA48HORAS') },
                          { value: 'CADA72HORAS', label: translate('generadorApp.QuoteFrequency.CADA72HORAS') },
                          { value: 'PONTUAL', label: translate('generadorApp.QuoteFrequency.PONTUAL') },
                        ]}
                        onChange={(options: any) => this.setState({ frequency: options['value'] })}
                        name="frequency"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'quote' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="quote-rh-quote">
                          <Translate contentKey="generadorApp.quoteRh.quote">Quote</Translate>
                        </Label>
                        <SelectAsync
                          id="quote-rh-quote"
                          isMulti
                          className={'css-select-control'}
                          name={'quote'}
                          cacheOptions
                          value={this.state.quoteId}
                          onChange={options => this.setState({ quoteId: options })}
                          defaultOptions={this.state.quoteStartFilter ? this.state.quoteStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.quoteStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.quoteStartFilter === undefined) {
                              const result = await getListAxios('quotes', {}, 0, 100, 'id,asc', 'id');
                              this.setState({
                                quoteStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios('quotes', { 'id.contains': inputValue }, 0, 100, 'id,asc', 'id');
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'specialty' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="quote-rh-specialty">
                          <Translate contentKey="generadorApp.quoteRh.specialty">Specialty</Translate>
                        </Label>
                        <SelectAsync
                          id="quote-rh-specialty"
                          isMulti
                          className={'css-select-control'}
                          name={'specialty'}
                          cacheOptions
                          value={this.state.specialtyId}
                          onChange={options => this.setState({ specialtyId: options })}
                          defaultOptions={this.state.specialtyStartFilter ? this.state.specialtyStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.specialtyStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.specialtyStartFilter === undefined) {
                              const result = await getListAxios('specialties', {}, 0, 100, 'name,asc', 'name');
                              this.setState({
                                specialtyStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios('specialties', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'additive' ? (
                  <Col md="3" className="col-filter-quote-rh-additive">
                    <Row className="mr-1 mt-1">
                      <Label id="additiveLabel" for="quote-rh-additive">
                        <Translate contentKey="generadorApp.quoteRh.additive">Additive</Translate>
                      </Label>
                      <Select
                        id="quote-rh-additive"
                        className={'css-select-control'}
                        value={
                          this.state.additive === 0
                            ? { value: 0, label: translate('generadorApp.quoteRh.additive_filter_without') }
                            : this.state.additive === 1
                            ? { value: 1, label: translate('generadorApp.quoteRh.additive_filter_with') }
                            : { value: '', label: translate('generadorApp.quoteRh.additive_filter_all') }
                        }
                        options={[
                          { value: '', label: translate('generadorApp.quoteRh.additive_filter_all') },
                          { value: 1, label: translate('generadorApp.quoteRh.additive_filter_with') },
                          { value: 0, label: translate('generadorApp.quoteRh.additive_filter_without') },
                        ]}
                        onChange={(options: any) => this.setState({ additive: options['value'] })}
                        name="additive"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'billQuantityItem' ? (
                  <Col md="3" className="col-filter-quote-rh-billQuantityItem">
                    <Row className="mr-1 mt-1">
                      <Label id="billQuantityItemLabel" for="quote-rh-billQuantityItem">
                        <Translate contentKey="generadorApp.quoteRh.billQuantityItem">Quantity</Translate>
                      </Label>
                      <AvInput type="number" name="billQuantityItem" id="quote-rh-billQuantityItem" value={this.state.billQuantityItem} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'billFinalAmount' ? (
                  <Col md="3" className="col-filter-quote-rh-billFinalAmount">
                    <Row className="mr-1 mt-1">
                      <Label id="billFinalAmountLabel" for="quote-rh-billFinalAmount">
                        <Translate contentKey="generadorApp.quoteRh.billFinalAmount">Final Amount</Translate>
                      </Label>

                      <AvInput type="text" name="billFinalAmount" id="quote-rh-billFinalAmount" value={this.state.billFinalAmount} />
                    </Row>
                  </Col>
                ) : null}
              </div>

              <div className="row mb-2 mr-4 justify-content-end">
                <Button color="primary" type="submit">
                  <i className="fa fa-filter" aria-hidden={'true'}></i>&nbsp;
                  <Translate contentKey="generadorApp.quoteRh.btnFilter">Filter</Translate>
                </Button>
                &nbsp;
                <Button color="secondary" onClick={this.cancelFilters}>
                  <FontAwesomeIcon icon="trash-alt" />
                  &nbsp;
                  <Translate contentKey="generadorApp.quoteRh.btnCleanFilter">Clean Filter</Translate>
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </UncontrolledCollapse>
      </>
    );
  }

  renderTable() {
    const { clientGlosaItems, expectedResults, padAttendances, quotes, specialties, quoteRhList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {quoteRhList && quoteRhList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null).length > 0 ? (
          <>
            <Table
              id="quote-rh-table-list"
              responsive
              aria-describedby="quote-rh-heading"
              className={'table-hover table-striped table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.quoteRh.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'specialty.category' ? (
                    <th>
                      <Translate contentKey="generadorApp.quoteRh.specialty">Specialty</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'specialty' ? (
                    <th>
                      <Translate contentKey="generadorApp.quoteRh.specialty">Specialty</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'frequency' ? (
                    <th className="hand" onClick={sortFunction('frequency')}>
                      <Translate contentKey="generadorApp.quoteRh.frequency">Frequency</Translate>
                      <FontAwesomeIcon icon={state.sort === 'frequency' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'typeDiscount' ? (
                    <th className="hand" onClick={sortFunction('typeDiscount')}>
                      <Translate contentKey="generadorApp.quoteRh.typeDiscount">Type Discount (%)</Translate>
                      <FontAwesomeIcon icon={state.sort === 'typeDiscount' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'startDate' ? (
                    <th className="hand" onClick={sortFunction('startDate')}>
                      <Translate contentKey="generadorApp.quoteRh.startDate">Start Date</Translate>
                      <FontAwesomeIcon icon={state.sort === 'startDate' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'finishDate' ? (
                    <th className="hand" onClick={sortFunction('finishDate')}>
                      <Translate contentKey="generadorApp.quoteRh.finishDate">Finish Date</Translate>
                      <FontAwesomeIcon icon={state.sort === 'finishDate' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'days' ? (
                    <th className="hand" onClick={sortFunction('days')}>
                      <Translate contentKey="generadorApp.quoteRh.days">Days</Translate>
                      <FontAwesomeIcon icon={state.sort === 'days' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'quantity' ? (
                    <th className="hand" onClick={sortFunction('quantity')}>
                      <Translate contentKey="generadorApp.quoteRh.quantity">Quantity</Translate>
                      <FontAwesomeIcon icon={state.sort === 'quantity' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'unitaryAmount' ? (
                    <th className="hand" onClick={sortFunction('unitaryAmount')}>
                      <Translate contentKey="generadorApp.quoteRh.unitaryAmount">Unitary Amount</Translate>
                      <FontAwesomeIcon icon={state.sort === 'unitaryAmount' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'grossAmount' ? (
                    <th className="hand" onClick={sortFunction('grossAmount')}>
                      <Translate contentKey="generadorApp.quoteRh.grossAmount">Gross Amount</Translate>
                      <FontAwesomeIcon icon={state.sort === 'grossAmount' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'discountPercent' ? (
                    <th className="hand" onClick={sortFunction('discountPercent')}>
                      <Translate contentKey="generadorApp.quoteRh.discountPercent">Discount (%)</Translate>
                      <FontAwesomeIcon
                        icon={state.sort === 'discountPercent' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'}
                      />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'discount' ? (
                    <th className="hand" onClick={sortFunction('discount')}>
                      <Translate contentKey="generadorApp.quoteRh.discount">Discount</Translate>
                      <FontAwesomeIcon icon={state.sort === 'discount' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'quote' ? (
                    <th>
                      <Translate contentKey="generadorApp.quoteRh.quote">Quote</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'billQuantityItem' ? (
                    <th className="hand" onClick={sortFunction('billQuantityItem')}>
                      <Translate contentKey="generadorApp.quoteRh.billQuantityItem">Quantity</Translate>
                      <FontAwesomeIcon
                        icon={state.sort === 'billQuantityItem' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'}
                      />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'billFinalAmount' ? (
                    <th className="hand" onClick={sortFunction('billFinalAmount')}>
                      <Translate contentKey="generadorApp.quoteRh.billFinalAmount">Final Amount</Translate>
                      <FontAwesomeIcon
                        icon={state.sort === 'billFinalAmount' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'}
                      />
                    </th>
                  ) : null}

                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {quoteRhList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((quoteRh, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.quoteRh.field_id">ID</Translate>
                        </b>
                        {quoteRh.id}
                      </td>

                      {state.baseFilters !== 'specialty' ? (
                        <td id="specialty-cell">
                          <b className="visible-xs"> Specialty </b>
                          {quoteRh ? (
                            <span data-type-rel="2-many-to-one-other-side">
                              {getValueRecursive(quoteRh, 'specialty.category'.split('.'), '')}
                            </span>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'specialty' ? (
                        <td id="specialty-cell">
                          <b className="visible-xs"> Specialty </b>
                          {quoteRh ? (
                            <span data-type-rel="2-many-to-one-other-side">
                              {getValueRecursive(quoteRh, 'specialty.name'.split('.'), '')}
                            </span>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'frequency' ? (
                        <td id="frequency-cell">
                          <b className="visible-xs"> Frequency </b>
                          {quoteRh.frequency ? <Translate contentKey={`generadorApp.QuoteFrequency.${quoteRh.frequency}`} /> : <> </>}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'typeDiscount' ? (
                        <td id="typeDiscount-cell">
                          <b className="visible-xs"> Type Discount (%) </b>
                          {quoteRh.typeDiscount ? (
                            <Translate contentKey={`generadorApp.QuoteDiscountType.${quoteRh.typeDiscount}`} />
                          ) : (
                            <> </>
                          )}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'startDate' ? (
                        <td id="startDate-cell">
                          <b className="visible-xs"> Start Date </b>
                          {quoteRh.startDate ? moment(quoteRh.startDate).format(APP_DATE_FORMAT) : ''}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'finishDate' ? (
                        <td id="finishDate-cell">
                          <b className="visible-xs"> Finish Date </b>
                          {quoteRh.finishDate ? moment(quoteRh.finishDate).format(APP_DATE_FORMAT) : ''}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'days' ? (
                        <td id="days-cell">
                          <b className="visible-xs"> Days </b>

                          {quoteRh.days}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'quantity' ? (
                        <td id="quantity-cell">
                          <b className="visible-xs"> Quantity </b>

                          {quoteRh.quantity}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'unitaryAmount' ? (
                        <td id="unitaryAmount-cell">
                          <b className="visible-xs"> Unitary Amount </b>

                          {quoteRh.unitaryAmount}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'grossAmount' ? (
                        <td id="grossAmount-cell">
                          <b className="visible-xs"> Gross Amount </b>

                          {quoteRh.grossAmount}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'discountPercent' ? (
                        <td id="discountPercent-cell">
                          <b className="visible-xs"> Discount (%) </b>

                          {quoteRh.discountPercent}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'discount' ? (
                        <td id="discount-cell">
                          <b className="visible-xs"> Discount </b>

                          {quoteRh.discount}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'quote' ? (
                        <td id="quote-cell">
                          <b className="visible-xs"> Quote </b>
                          {quoteRh ? (
                            <span data-type-rel="2-many-to-one-other-side">{getValueRecursive(quoteRh, 'quote.id'.split('.'), '')}</span>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'billQuantityItem' ? (
                        <td id="billQuantityItem-cell">
                          <b className="visible-xs"> Quantity </b>

                          {quoteRh.billQuantityItem}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'billFinalAmount' ? (
                        <td id="billFinalAmount-cell">
                          <b className="visible-xs"> Final Amount </b>

                          {quoteRh.billFinalAmount}
                        </td>
                      ) : null}

                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          {hasAnyAuthority(props.userAccount, ['quote'], 'edit') ? (
                            <Button onClick={() => this.openUpdateModal(quoteRh)} color="primary" size="sm">
                              <FontAwesomeIcon icon="pencil-alt" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                          {hasAnyAuthority(props.userAccount, ['quote'], 'edit') ? (
                            <Button onClick={() => this.deleteEntity(quoteRh)} color="danger" size="sm">
                              <FontAwesomeIcon icon="trash" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.quoteRh.home.notFound">No Human Resources found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const { clientGlosaItems, expectedResults, padAttendances, quotes, specialties, quoteRhList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={quoteRhList && quoteRhList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const { clientGlosaItems, expectedResults, padAttendances, quotes, specialties, quoteRhList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel className="quote-rh quote-rh-all-in-one">
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
            {this.renderNewModal()}
            {this.renderUpdateModal()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToProps = ({ quoteRh, ...storeState }: IRootState) => ({
  clientGlosaItems: storeState.clientGlosaItem.entities,
  expectedResults: storeState.expectedResult.entities,
  padAttendances: storeState.padAttendance.entities,
  quotes: storeState.quote.entities,
  specialties: storeState.specialty.entities,
  quoteRhList: quoteRh.entities,
  totalItems: quoteRh.totalItems,
  userAccount: storeState.authentication.account,
  loading: quoteRh.loading,

  // UPDATE
  quoteRhEntity: quoteRh.entity,
});

export const mapDispatchToProps = {
  getEntities,

  updateEntity,
  reset,

  // UPDATE
  getEntity,
  createEntity,

  // DELETE
  deleteEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(QuoteRh);
