/* eslint complexity: ["error", 500] */
import React from 'react';
import { connect } from 'react-redux';

import { PadAttendanceFile, mapStateToProps, mapDispatchToProps } from '../pad-attendance-file';
import { IRootState } from 'app/shared/reducers';

import { Button } from 'reactstrap';
import { Translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

export interface IExtendedState {
  dumystate?: any;
}
export interface IExtendedProps {
  entityEmbebedPai?: any;
}

export class PadAttendanceFileExtended extends PadAttendanceFile {
  renderHeader() {
    const { padAttendances, padAttendanceFileList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.padAttendanceFile.home.title">Attendance Files</Translate>
          </span>
          <Button id="togglerFilterPadAttendanceFile" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.padAttendanceFile.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          &nbsp;
          <Button
            onClick={() =>
              this.setState({
                showModalForm: true,
                nameSelectValue: null,
                filenameSelectValue: null,

                isNew: true,
              })
            }
            color="primary"
            className="btn btn-primary float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="generadorApp.padAttendanceFile.btnNewItem">Create new</Translate>
          </Button>{' '}
          &nbsp;
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/attendance-list'}>
              <Translate contentKey="generadorApp.padAttendance.home.title">PadAttendances</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            {this.state.padAttendanceSelectValue ? this.state.padAttendanceSelectValue.label : this.props.match.params['idPadAttendance']}
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.padAttendanceFile.home.title">Attendance Files</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    return <> {super.renderFilter()} </>;
  }

  renderTable() {
    return <> {super.renderTable()} </>;
  }

  renderFooter() {
    return <> {super.renderFooter()} </>;
  }

  render() {
    return <> {super.render()} </>;
  }
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;

export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(PadAttendanceFileExtended);
