import React from 'react';
import Chart from 'react-apexcharts';

import { connect } from 'react-redux';
import moment, { Moment } from 'moment';
import { Client } from 'app/entities/client/client';

export interface IChartAtendimentosProps extends StateProps, DispatchProps {
  periodoInicio?: Moment;
  periodoFim?: Moment;
  operadora?: Array<Client>;
  atendimentos: {
    TotalAtendimentosPorDia: Array<{ Dia: Date; Total: number }>;
    AtendimentosRealizadosPorDia: Array<{ Dia: Date; Total: number }>;
  };
}

const ChartAtendimentos = (props: IChartAtendimentosProps) => {
  const getDates = (start, end) => {
    const arr = [];
    for (const dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt));
    }
    return arr;
  };

  const dates = getDates(moment(props.periodoInicio).toDate(), moment(props.periodoFim).toDate()).map(d => ({
    Dia: d,
    TotalAtendimentosPorDia: props.atendimentos?.TotalAtendimentosPorDia?.filter(
      v => moment(v.Dia).format('YYYY-MM-DD') === moment(d).format('YYYY-MM-DD')
    )
      .map(v => v.Total)
      ?.reduce((total: any, dia) => total + dia, 0),
    AtendimentosRealizadosPorDia: props.atendimentos?.AtendimentosRealizadosPorDia?.filter(
      v => moment(v.Dia).format('YYYY-MM-DD') === moment(d).format('YYYY-MM-DD')
    )
      .map(v => v.Total)
      ?.reduce((total: any, dia) => total + dia, 0),
  }));

  const state = {
    series: [
      {
        name: 'Total de atendimentos',
        data: dates.map(v => (v.TotalAtendimentosPorDia ? v.TotalAtendimentosPorDia : 0)),
      },
      {
        name: 'Atendimentos realizados',
        data: dates.map(v => (v.AtendimentosRealizadosPorDia ? v.AtendimentosRealizadosPorDia : 0)),
      },
    ],
    options: {
      chart: {
        height: 160,
        background: '#1f2225',
        toolbar: {
          show: false,
          autoSelected: 'pan',
        },
        type: 'area',
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        opacity: 1,
        type: 'solid',
      },
      colors: ['#5AC8FA', '#348fe2'],
      stroke: {
        curve: 'smooth',
      },
      legend: {
        labels: {
          colors: '#ffffff',
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: '#ffffff',
          },
        },
      },
      xaxis: {
        type: 'datetime',
        categories: dates.map(v => moment(v.Dia).toDate().toISOString()),
        labels: {
          style: {
            colors: '#ffffff',
          },
        },
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy',
        },
      },
    },
  };

  return (
    <div className="card bg-dark border-0 text-white mb-3">
      <div className="card-body">
        <div className="mb-3 text-grey">
          <b>Atendimentos</b>
        </div>
      </div>
      <div className="card-body p-0">
        <div>
          <div className="widget-chart-full-width nvd3-inverse-mode">
            {/*  <NVD3Chart type="stackedAreaChart" datum={this.areaChartData} height={260} options={this.areaChartOptions} /> */}
            <Chart options={state.options} series={state.series} type="area" width="100%" height={350} />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = storeState => ({
  report:
    storeState.jhiReports.reportsList && storeState.jhiReports.reportsList['pacientes-hospitalizados']
      ? storeState.jhiReports.reportsList['pacientes-hospitalizados']
      : [],
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps)(ChartAtendimentos);
