/* eslint complexity: ["error", 500] */
import React from 'react';
import axios from 'axios';

import CurrencyInput from 'react-currency-input';
import { connect } from 'react-redux';

import { getFilterFromSelect, getListAxios, getValueRecursive, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import Select from 'react-select';
import { toast } from 'react-toastify';
import SelectAsync from 'react-select/async';
import { Link, RouteComponentProps } from 'react-router-dom';

import { Button, Col, Row, Table, Label, UncontrolledCollapse, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { setFileData, JhiItemCount } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import LevelGroupCategory from './components/LevelGroupCategory';

import { IRootState } from 'app/shared/reducers';
import {
  updateEntity,
  getUrlBack,
  getClComItemState,
  IClComItemBaseState,
  getEntityFiltersURL,
  getEntities,
  IClComItemUpdateState as IBaseUpdateState,
  reset,
  getEntity,
  deleteEntity,
  createEntity,
  setBlob,
} from './cl-com-item.reducer';

import { ITEMS_PER_PAGE, IPaginationBaseState, getSortState } from 'app/shared/util/pagination.constants';

import { StatePropsExtended, DispatchPropsExtended, IExtendedState, IExtendedProps } from './extended/cl-com-item';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

export interface IClComItemProps
  extends StateProps,
    DispatchProps,
    StatePropsExtended,
    DispatchPropsExtended,
    IExtendedProps,
    RouteComponentProps<{ url: string }> {
  entityEmbebedPai?: any;
}

export interface IClComItemState extends IClComItemBaseState, IPaginationBaseState, IBaseUpdateState, IExtendedState {
  showModalForm?: boolean;
  showModalEdit?: number;
  showModalEditView?: boolean;
}

export class ClComItem extends React.Component<IClComItemProps, IClComItemState> {
  protected myFormRef: any;
  protected myFormRefNew: any;
  protected myFormRefEdit: any;

  comercialTableTypeFileInput: any;

  tableFileInput: any;

  tussCodeFileInput: any;

  tissCodeFileInput: any;

  tableCodeFileInput: any;

  expenseCodeFileInput: any;

  saleValueFileInput: any;

  percentFileInput: any;

  inflatorFileInput: any;

  deflatorFileInput: any;

  rentalValueFileInput: any;

  observationFileInput: any;

  constructor(props: IClComItemProps) {
    super(props);
    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      listCheckedID: [],
      isCheckedAll: false,
      showModalForm: false,
      showModalEdit: null,
      urlBack: getUrlBack(this.props.location),
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      ...getClComItemState(this.props.location),
      clComTableId: [
        {
          ...(this.props.entityEmbebedPai ? this.props.entityEmbebedPai : {}),
          value: this.props.match.params['idClComTable'],
          label: this.props.match.params['idClComTable'],
        },
      ],
      baseFilters: 'clComTable',
      clComTableSelectValue: {
        ...(this.props.entityEmbebedPai ? this.props.entityEmbebedPai : {}),
        value: this.props.match.params['idClComTable'],
        label: this.props.match.params['idClComTable'],
      },

      levelGroupSelectValue: null,

      categorySelectValue: null,

      materialMedicineSelectValue: null,

      comercialTableTypeSelectValue: null,
      tableSelectValue: null,
      tussCodeSelectValue: null,
      tissCodeSelectValue: null,
      tableCodeSelectValue: null,
      expenseCodeSelectValue: null,
      saleValueSelectValue: null,
      percentSelectValue: null,
      inflatorSelectValue: null,
      deflatorSelectValue: null,
      rentalValueSelectValue: null,
      observationSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getClComItemState(this.props.location),
        clComTableId: [
          {
            ...(this.props.entityEmbebedPai ? this.props.entityEmbebedPai : {}),
            value: this.props.match.params['idClComTable'],
            label: this.props.match.params['idClComTable'],
          },
        ],
        baseFilters: 'clComTable',
      },
      clComTableName: null,
      levelGroupName: null,
      categoryName: null,
      materialMedicineName: null,
      isNew: false,
    };

    axios
      .get('/api/cl-com-tables/' + this.props.match.params['idClComTable'], {
        headers: {
          'Select-Fields': 'name',
        },
      })
      .then(result => {
        this.setState({
          clComTableSelectValue: {
            ...result['data'],
            value: this.props.match.params['idClComTable'],
            label: showFieldsSelectAsync(result['data'], 'name'),
          },
        });
      });
  }

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({ listCheckedID: this.props.clComItemList.map(clComItem => clComItem.id) });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({ listCheckedID: this.props.clComItemList.map(clComItem => clComItem.id) });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  removeEntitySelected() {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  }

  saveEntityMany = () => {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  };

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  cancelFilters = () => {
    this.setState(
      {
        comercialTableType: '',

        table: '',

        tussCode: '',

        tissCode: '',

        tableCode: '',

        expenseCode: '',

        saleValue: '',

        percent: '',

        inflator: '',

        deflator: '',

        rentalValue: '',

        observation: '',
        clComTableId: '',
        levelGroupId: '',
        categoryId: '',
        materialMedicineId: '',
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
        activePage: 0,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();

    const selectFieldsList = [
      'materialMedicine.medicineMaterialType',
      'materialMedicine.categories.name',
      'materialMedicine.name',
      'saleValue',
      'clComTable.name',
    ];
    const {
      comercialTableType,
      table,
      tussCode,
      tissCode,
      tableCode,
      expenseCode,
      saleValue,
      percent,
      inflator,
      deflator,
      rentalValue,
      observation,
      clComTableId,
      levelGroupId,
      categoryId,
      materialMedicineId,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;
    return this.props.getEntities(
      comercialTableType,
      table,
      tussCode,
      tissCode,
      tableCode,
      expenseCode,
      saleValue,
      percent,
      inflator,
      deflator,
      rentalValue,
      observation,
      getFilterFromSelect(clComTableId, 'many-to-one'),
      getFilterFromSelect(levelGroupId, 'many-to-one'),
      getFilterFromSelect(categoryId, 'many-to-one'),
      getFilterFromSelect(materialMedicineId, 'many-to-one'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(',')
    );
  };

  updateEntity = entity => {
    const selectFieldsList = [
      'materialMedicine.medicineMaterialType',
      'materialMedicine.categories.name',
      'materialMedicine.name',
      'saleValue',
      'clComTable.name',
    ];

    const {
      comercialTableType,
      table,
      tussCode,
      tissCode,
      tableCode,
      expenseCode,
      saleValue,
      percent,
      inflator,
      deflator,
      rentalValue,
      observation,
      clComTableId,
      levelGroupId,
      categoryId,
      materialMedicineId,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;
    this.props.updateEntity(entity, [
      comercialTableType,
      table,
      tussCode,
      tissCode,
      tableCode,
      expenseCode,
      saleValue,
      percent,
      inflator,
      deflator,
      rentalValue,
      observation,
      getFilterFromSelect(clComTableId, 'many-to-one'),
      getFilterFromSelect(levelGroupId, 'many-to-one'),
      getFilterFromSelect(categoryId, 'many-to-one'),
      getFilterFromSelect(materialMedicineId, 'many-to-one'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(','),
    ]);
  };

  onBlobChange = (isAnImage, name, fileInput) => event => {
    const fileName = event.target.files[0].name;
    setFileData(event, (contentType, data) => this.props.setBlob(name, data, contentType, fileName), isAnImage);
    const varAux = {};
    varAux[name + 'SelectValue'] = fileName;
    this.setState(varAux);
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined);
  };

  saveEntity = (event: any, errors: any, values: any, isNew: any) => {
    const selectFieldsList = [
      'materialMedicine.medicineMaterialType',
      'materialMedicine.categories.name',
      'materialMedicine.name',
      'saleValue',
      'clComTable.name',
    ];

    if (errors.length === 0) {
      const { clComItemEntity } = this.props;
      const _entity = {
        ...clComItemEntity,
        // ...values,

        comercialTableType: this.state.comercialTableTypeSelectValue === null ? null : this.state.comercialTableTypeSelectValue,
        table: this.state.tableSelectValue === null ? null : this.state.tableSelectValue,
        tussCode: this.state.tussCodeSelectValue,
        tissCode: this.state.tissCodeSelectValue,
        tableCode: this.state.tableCodeSelectValue,
        expenseCode: this.state.expenseCodeSelectValue,
        saleValue: this.state.saleValueSelectValue,
        percent: this.state.percentSelectValue,
        inflator: this.state.inflatorSelectValue,
        deflator: this.state.deflatorSelectValue,
        rentalValue: this.state.rentalValueSelectValue,
        observation: this.state.observationSelectValue,
      };
      const entity = _entity;

      const {
        comercialTableType,
        table,
        tussCode,
        tissCode,
        tableCode,
        expenseCode,
        saleValue,
        percent,
        inflator,
        deflator,
        rentalValue,
        observation,
        clComTableId,
        levelGroupId,
        categoryId,
        materialMedicineId,
        extraFilters,
        activePage,
        itemsPerPage,
        sort,
        order,
      } = this.state.fieldsBase;

      if (isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              comercialTableType,
              table,
              tussCode,
              tissCode,
              tableCode,
              expenseCode,
              saleValue,
              percent,
              inflator,
              deflator,
              rentalValue,
              observation,
              getFilterFromSelect(clComTableId, 'many-to-one'),
              getFilterFromSelect(levelGroupId, 'many-to-one'),
              getFilterFromSelect(categoryId, 'many-to-one'),
              getFilterFromSelect(materialMedicineId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              comercialTableType,
              table,
              tussCode,
              tissCode,
              tableCode,
              expenseCode,
              saleValue,
              percent,
              inflator,
              deflator,
              rentalValue,
              observation,
              getFilterFromSelect(clComTableId, 'many-to-one'),
              getFilterFromSelect(levelGroupId, 'many-to-one'),
              getFilterFromSelect(categoryId, 'many-to-one'),
              getFilterFromSelect(materialMedicineId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
      this.setState({ showModalForm: false });
      this.setState({ showModalEdit: null });
    } else {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.specialty.' + errors[0]) }));
    }
  };

  componentDidUpdate(prevProps) {}

  deleteEntity(clComItemEntity) {
    MySwal.fire({
      title: <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>,
      html: (
        <Translate contentKey="generadorApp.clComItem.delete.question" interpolate={{ id: clComItemEntity.id }}>
          Are you sure you want to delete this ClComItem?
        </Translate>
      ),
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#e70015',
      cancelButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="ban" />
          &nbsp; <Translate contentKey="generadorApp.clComItem.btnCancel">Cancel</Translate>
        </>
      ),
      confirmButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="trash" />
          &nbsp;<Translate contentKey="generadorApp.clComItem.btnDelete">Delete</Translate>
        </>
      ),
    }).then(async response => {
      if (response.isConfirmed) {
        await this.props.deleteEntity(clComItemEntity.id, { reloadList: false });
        this.getEntities();
        MySwal.fire({
          title: <Translate contentKey="entity.delete.title.confirmed">Confirmed delete operation</Translate>,
          icon: 'success',
        });
      }
    });
  }

  renderNewModal() {
    return (
      <Modal size={'xl'} isOpen={this.state.showModalForm} toggle={() => this.setState({ showModalForm: false })}>
        <ModalHeader toggle={() => this.setState({ showModalForm: false })}>{this.renderHeaderUpdate({}, true)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefNew = el)}
          model={{}}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, true)}
        >
          <ModalBody>{this.renderBodyUpdate({}, true)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalForm: false })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.clComItem.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefNew && this.myFormRefNew.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.clComItem.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    );
  }

  getFullEntityFromState(v) {
    return {
      id: v.id,
      comercialTableType: this.state.comercialTableTypeSelectValue,
      table: this.state.tableSelectValue,
      tussCode: this.state.tussCodeSelectValue,
      tissCode: this.state.tissCodeSelectValue,
      tableCode: this.state.tableCodeSelectValue,
      expenseCode: this.state.expenseCodeSelectValue,
      saleValue: this.state.saleValueSelectValue,
      percent: this.state.percentSelectValue,
      inflator: this.state.inflatorSelectValue,
      deflator: this.state.deflatorSelectValue,
      rentalValue: this.state.rentalValueSelectValue,
      observation: this.state.observationSelectValue,
      clComTable: this.state.clComTableSelectValue,
      levelGroup: this.state.levelGroupSelectValue,
      category: this.state.categorySelectValue,
      materialMedicine: this.state.materialMedicineSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    let p: any = null;

    p = v.clComTable;
    const clComTableEntity = p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : p;

    p = v.levelGroup;
    const levelGroupEntity = p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : p;

    p = v.category;
    const categoryEntity = p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : p;

    p = v.materialMedicine;
    const materialMedicineEntity = p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : p;

    return {
      comercialTableTypeSelectValue: v.comercialTableType, // comercialTableType,
      tableSelectValue: v.table, // table,
      tussCodeSelectValue: v.tussCode, // tussCode,
      tissCodeSelectValue: v.tissCode, // tissCode,
      tableCodeSelectValue: v.tableCode, // tableCode,
      expenseCodeSelectValue: v.expenseCode, // expenseCode,
      saleValueSelectValue: v.saleValue, // saleValue,
      percentSelectValue: v.percent, // percent,
      inflatorSelectValue: v.inflator, // inflator,
      deflatorSelectValue: v.deflator, // deflator,
      rentalValueSelectValue: v.rentalValue, // rentalValue,
      observationSelectValue: v.observation, // observation,
      clComTableSelectValue: clComTableEntity,
      levelGroupSelectValue: levelGroupEntity,
      categorySelectValue: categoryEntity,
      materialMedicineSelectValue: materialMedicineEntity,
    };
  }

  openUpdateModal(v, edit = true) {
    this.props.getEntity(v.id);
    this.setState({
      showModalEdit: v.id,
      showModalEditView: edit,
      ...this.getFullEntitySelectValue(v),
    });
  }

  renderUpdateModal() {
    const { clComItemEntity, entityEmbebedPai } = this.props;
    return clComItemEntity && clComItemEntity.id ? (
      <Modal size={'xl'} isOpen={this.state.showModalEdit === clComItemEntity.id} toggle={() => this.setState({ showModalEdit: null })}>
        <ModalHeader toggle={() => this.setState({ showModalEdit: null })}>{this.renderHeaderUpdate(clComItemEntity, false)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefEdit = el)}
          model={{
            ...clComItemEntity,
          }}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, false)}
        >
          <ModalBody>{this.renderBodyUpdate(clComItemEntity, false)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalEdit: null })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.clComItem.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefEdit && this.myFormRefEdit.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.clComItem.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    ) : (
      <> </>
    );
  }

  renderHeaderUpdate(clComItemEntity, isNew) {
    const { entityEmbebedPai, clComTables, levelGroups, categories, medicineMaterials, loading } = this.props;

    const { observation } = clComItemEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {isNew ? (
              <Translate contentKey="generadorApp.clComItem.home.createLabel">Create a ClComItem</Translate>
            ) : (
              <Translate contentKey="generadorApp.clComItem.home.editLabel">Edit a ClComItem</Translate>
            )}
          </span>
        </div>
      </>
    );
  }
  renderBodyUpdate(clComItemEntity, isNew) {
    const { entityEmbebedPai, clComTables, levelGroups, categories, medicineMaterials, loading } = this.props;

    const { observation } = clComItemEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
              <Col md="3">
              <Label className="mt-2 label-single-line"for="cl-com-item-id">
                <Translate contentKey="generadorApp.clComItem.field_id">ID</Translate>
              </Label>
              </Col> */}
                      <Col md="12">
                        <AvInput id="cl-com-item-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'LevelGroupCategory' ? (
                    <Col md="12">
                      <LevelGroupCategory
                        entityBase={clComItemEntity}
                        baseState={this.state}
                        baseProps={this.props}
                        setState={_state => this.setState(_state)}
                      />
                    </Col>
                  ) : (
                    <> </>
                  )}

                  {this.props.entityEmbebedPai && this.props.entityEmbebedPai.type !== 'PACOTE' && baseFilters !== 'saleValue' ? (
                    <Col md="4">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="saleValueLabel" for="cl-com-item-saleValue">
                              <Translate contentKey="generadorApp.clComItem.saleValue">Sale Value</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ saleValueSelectValue: floatvalue })}
                              decimalSeparator=","
                              thousandSeparator=""
                              precision={2}
                              prefix=""
                              allowNegative={true}
                              value={this.state.saleValueSelectValue ? this.state.saleValueSelectValue : 0}
                              id="cl-com-item-saleValue"
                              name="saleValue"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ saleValueSelectValue: evt.target.value })}
                      type="hidden"
                      name="saleValue"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'tussCode' ? (
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="tussCodeLabel" for="cl-com-item-tussCode">
                              <Translate contentKey="generadorApp.clComItem.tussCode">Tuss Code</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ tussCodeSelectValue: evt.target.value })}
                              value={this.state.tussCodeSelectValue ? this.state.tussCodeSelectValue : ''}
                              id="cl-com-item-tussCode"
                              type="text"
                              name="tussCode"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ tussCodeSelectValue: evt.target.value })}
                      type="hidden"
                      name="tussCode"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'tissCode' ? (
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="tissCodeLabel" for="cl-com-item-tissCode">
                              <Translate contentKey="generadorApp.clComItem.tissCode">Tiss Code</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ tissCodeSelectValue: evt.target.value })}
                              value={this.state.tissCodeSelectValue ? this.state.tissCodeSelectValue : ''}
                              id="cl-com-item-tissCode"
                              type="text"
                              name="tissCode"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ tissCodeSelectValue: evt.target.value })}
                      type="hidden"
                      name="tissCode"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'tableCode' ? (
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="tableCodeLabel" for="cl-com-item-tableCode">
                              <Translate contentKey="generadorApp.clComItem.tableCode">Table Code</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ tableCodeSelectValue: evt.target.value })}
                              value={this.state.tableCodeSelectValue ? this.state.tableCodeSelectValue : ''}
                              id="cl-com-item-tableCode"
                              type="text"
                              name="tableCode"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ tableCodeSelectValue: evt.target.value })}
                      type="hidden"
                      name="tableCode"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                    {baseFilters !== 'saleValue' ? (
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="saleValueLabel" for="cl-com-item-saleValue">
                              <text>Valor de Venda</text>
                            </Label>
                          </Col>
                          <Col md="12">
                          <CurrencyInput
                              onChange={(maskedvalue: any, floatvalue: null, event: any) => this.setState({ saleValueSelectValue: floatvalue})}
                              decimalSeparator=","
                              thousandSeparator=""
                              prefix="R$ "
                              allowNegative={true}
                              value={this.state.saleValueSelectValue}
                              id="cl-com-item-saleValue"
                              name="saleValue"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ saleValueSelectValue: evt.target.value })}
                      type="hidden"
                      name="saleValue"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'expenseCode' ? (
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="expenseCodeLabel" for="cl-com-item-expenseCode">
                              <Translate contentKey="generadorApp.clComItem.expenseCode">Expense Code</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ expenseCodeSelectValue: evt.target.value })}
                              value={this.state.expenseCodeSelectValue ? this.state.expenseCodeSelectValue : ''}
                              id="cl-com-item-expenseCode"
                              type="text"
                              name="expenseCode"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ expenseCodeSelectValue: evt.target.value })}
                      type="hidden"
                      name="expenseCode"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'observation' ? (
                    <Col md="12">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="observationLabel" for="cl-com-item-observation">
                              <Translate contentKey="generadorApp.clComItem.observation">Observation</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <AvInput
                              onChange={evt => this.setState({ observationSelectValue: evt.target.value })}
                              validate={{}}
                              id="cl-com-item-observation"
                              type="textarea"
                              name="observation"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ observationSelectValue: evt.target.value })}
                      type="hidden"
                      name="observation"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  renderHeader() {
    const { clComTables, levelGroups, categories, medicineMaterials, clComItemList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.clComItem.home.title">Items</Translate>
          </span>
          <Button id="togglerFilterClComItem" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.clComItem.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          &nbsp;
          <Button
            onClick={() =>
              this.setState({
                showModalForm: true,
                comercialTableTypeSelectValue: null,
                tableSelectValue: null,
                tussCodeSelectValue: null,
                tissCodeSelectValue: null,
                tableCodeSelectValue: null,
                expenseCodeSelectValue: null,
                saleValueSelectValue: null,
                percentSelectValue: null,
                inflatorSelectValue: null,
                deflatorSelectValue: null,
                rentalValueSelectValue: null,
                observationSelectValue: null,

                levelGroupStartSelectOptions: undefined,
                levelGroupSelectValue: null,

                categoryStartSelectOptions: undefined,
                categorySelectValue: null,

                materialMedicineStartSelectOptions: undefined,
                materialMedicineSelectValue: null,
                isNew: true,
              })
            }
            color="primary"
            className="btn btn-primary float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="generadorApp.clComItem.btnNewItem">Create new</Translate>
          </Button>{' '}
          &nbsp;
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/clComTable'}>
              <Translate contentKey="generadorApp.clComTable.home.title">ClComTables</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/clComTable/' + this.props.match.params['idClComTable']}>
              {this.state.clComTableSelectValue ? this.state.clComTableSelectValue.label : this.props.match.params['idClComTable']}
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.clComItem.home.title">Items</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    const { clComTables, levelGroups, categories, medicineMaterials, clComItemList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <UncontrolledCollapse toggler="#togglerFilterClComItem">
          <CardBody>
            <AvForm ref={el => (this.myFormRef = el)} id="form-filter" onSubmit={this.filterEntity}>
              <div className="row mt-1 ml-3 mr-3">
                {this.state.baseFilters !== 'comercialTableType' ? (
                  <Col md="3" className="col-filter-cl-com-item-comercialTableType">
                    <Row className="mr-1 mt-1">
                      <Label id="comercialTableTypeLabel" for="cl-com-item-comercialTableType">
                        <Translate contentKey="generadorApp.clComItem.comercialTableType">Type</Translate>
                      </Label>
                      <Select
                        id="cl-com-item-comercialTableType"
                        className={'css-select-control'}
                        value={
                          this.state.comercialTableType
                            ? {
                                value: this.state.comercialTableType,
                                label: translate('generadorApp.ComercialTableType.' + this.state.comercialTableType),
                              }
                            : { value: '', label: translate('generadorApp.clComItem.comercialTableType') }
                        }
                        options={[
                          { value: '', label: translate('generadorApp.clComItem.comercialTableType') },
                          { value: 'PF', label: translate('generadorApp.ComercialTableType.PF') },
                          { value: 'PMC', label: translate('generadorApp.ComercialTableType.PMC') },
                        ]}
                        onChange={(options: any) => this.setState({ comercialTableType: options['value'] })}
                        name="comercialTableType"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'table' ? (
                  <Col md="3" className="col-filter-cl-com-item-table">
                    <Row className="mr-1 mt-1">
                      <Label id="tableLabel" for="cl-com-item-table">
                        <Translate contentKey="generadorApp.clComItem.table">Table</Translate>
                      </Label>
                      <Select
                        id="cl-com-item-table"
                        className={'css-select-control'}
                        value={
                          this.state.table
                            ? { value: this.state.table, label: translate('generadorApp.EnumTable.' + this.state.table) }
                            : { value: '', label: translate('generadorApp.clComItem.table') }
                        }
                        options={[
                          { value: '', label: translate('generadorApp.clComItem.table') },
                          { value: 'BRASINDICE', label: translate('generadorApp.EnumTable.BRASINDICE') },
                          { value: 'SIMPRO', label: translate('generadorApp.EnumTable.SIMPRO') },
                        ]}
                        onChange={(options: any) => this.setState({ table: options['value'] })}
                        name="table"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'tussCode' ? (
                  <Col md="3" className="col-filter-cl-com-item-tussCode">
                    <Row className="mr-1 mt-1">
                      <Label id="tussCodeLabel" for="cl-com-item-tussCode">
                        <Translate contentKey="generadorApp.clComItem.tussCode">Tuss Code</Translate>
                      </Label>

                      <AvInput type="text" name="tussCode" id="cl-com-item-tussCode" value={this.state.tussCode} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'tissCode' ? (
                  <Col md="3" className="col-filter-cl-com-item-tissCode">
                    <Row className="mr-1 mt-1">
                      <Label id="tissCodeLabel" for="cl-com-item-tissCode">
                        <Translate contentKey="generadorApp.clComItem.tissCode">Tiss Code</Translate>
                      </Label>

                      <AvInput type="text" name="tissCode" id="cl-com-item-tissCode" value={this.state.tissCode} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'tableCode' ? (
                  <Col md="3" className="col-filter-cl-com-item-tableCode">
                    <Row className="mr-1 mt-1">
                      <Label id="tableCodeLabel" for="cl-com-item-tableCode">
                        <Translate contentKey="generadorApp.clComItem.tableCode">Table Code</Translate>
                      </Label>

                      <AvInput type="text" name="tableCode" id="cl-com-item-tableCode" value={this.state.tableCode} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'expenseCode' ? (
                  <Col md="3" className="col-filter-cl-com-item-expenseCode">
                    <Row className="mr-1 mt-1">
                      <Label id="expenseCodeLabel" for="cl-com-item-expenseCode">
                        <Translate contentKey="generadorApp.clComItem.expenseCode">Expense Code</Translate>
                      </Label>

                      <AvInput type="text" name="expenseCode" id="cl-com-item-expenseCode" value={this.state.expenseCode} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'saleValue' ? (
                  <Col md="3" className="col-filter-cl-com-item-saleValue">
                    <Row className="mr-1 mt-1">
                      <Label id="saleValueLabel" for="cl-com-item-saleValue">
                        <Translate contentKey="generadorApp.clComItem.saleValue">Sale Value</Translate>
                      </Label>

                      <AvInput type="text" name="saleValue" id="cl-com-item-saleValue" value={this.state.saleValue} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'percent' ? (
                  <Col md="3" className="col-filter-cl-com-item-percent">
                    <Row className="mr-1 mt-1">
                      <Label id="percentLabel" for="cl-com-item-percent">
                        <Translate contentKey="generadorApp.clComItem.percent">Percent</Translate>
                      </Label>
                      <AvInput type="number" name="percent" id="cl-com-item-percent" value={this.state.percent} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'inflator' ? (
                  <Col md="3" className="col-filter-cl-com-item-inflator">
                    <Row className="mr-1 mt-1">
                      <Label id="inflatorLabel" for="cl-com-item-inflator">
                        <Translate contentKey="generadorApp.clComItem.inflator">Inflator</Translate>
                      </Label>

                      <AvInput type="text" name="inflator" id="cl-com-item-inflator" value={this.state.inflator} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'deflator' ? (
                  <Col md="3" className="col-filter-cl-com-item-deflator">
                    <Row className="mr-1 mt-1">
                      <Label id="deflatorLabel" for="cl-com-item-deflator">
                        <Translate contentKey="generadorApp.clComItem.deflator">Deflator</Translate>
                      </Label>

                      <AvInput type="text" name="deflator" id="cl-com-item-deflator" value={this.state.deflator} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'rentalValue' ? (
                  <Col md="3" className="col-filter-cl-com-item-rentalValue">
                    <Row className="mr-1 mt-1">
                      <Label id="rentalValueLabel" for="cl-com-item-rentalValue">
                        <Translate contentKey="generadorApp.clComItem.rentalValue">Rental Value</Translate>
                      </Label>

                      <AvInput type="text" name="rentalValue" id="cl-com-item-rentalValue" value={this.state.rentalValue} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'observation' ? (
                  <Col md="3" className="col-filter-cl-com-item-observation">
                    <Row className="mr-1 mt-1">
                      <Label id="observationLabel" for="cl-com-item-observation">
                        <Translate contentKey="generadorApp.clComItem.observation">Observation</Translate>
                      </Label>
                      <AvInput id="cl-com-item-observation" type="textarea" name="observation" />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'clComTable' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="cl-com-item-clComTable">
                          <Translate contentKey="generadorApp.clComItem.clComTable">Cl Com Table</Translate>
                        </Label>
                        <SelectAsync
                          id="cl-com-item-clComTable"
                          isMulti
                          className={'css-select-control'}
                          name={'clComTable'}
                          cacheOptions
                          value={this.state.clComTableId}
                          onChange={options => this.setState({ clComTableId: options })}
                          defaultOptions={this.state.clComTableStartFilter ? this.state.clComTableStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.clComTableStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.clComTableStartFilter === undefined) {
                              const result = await getListAxios('cl-com-tables', {}, 0, 100, 'name,asc', 'name');
                              this.setState({
                                clComTableStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios('cl-com-tables', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'levelGroup' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="cl-com-item-levelGroup">
                          <Translate contentKey="generadorApp.clComItem.levelGroup">Level Group</Translate>
                        </Label>
                        <SelectAsync
                          id="cl-com-item-levelGroup"
                          isMulti
                          className={'css-select-control'}
                          name={'levelGroup'}
                          cacheOptions
                          value={this.state.levelGroupId}
                          onChange={options => this.setState({ levelGroupId: options })}
                          defaultOptions={this.state.levelGroupStartFilter ? this.state.levelGroupStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.levelGroupStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.levelGroupStartFilter === undefined) {
                              const result = await getListAxios('level-groups', {}, 0, 100, 'name,asc', 'name');
                              this.setState({
                                levelGroupStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios('level-groups', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'category' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="cl-com-item-category">
                          <Translate contentKey="generadorApp.clComItem.category">Category</Translate>
                        </Label>
                        <SelectAsync
                          id="cl-com-item-category"
                          isMulti
                          className={'css-select-control'}
                          name={'category'}
                          cacheOptions
                          value={this.state.categoryId}
                          onChange={options => this.setState({ categoryId: options })}
                          defaultOptions={this.state.categoryStartFilter ? this.state.categoryStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.categoryStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.categoryStartFilter === undefined) {
                              const result = await getListAxios('categories', {}, 0, 100, 'name,asc', 'name');
                              this.setState({
                                categoryStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios('categories', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'materialMedicine' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="cl-com-item-materialMedicine">
                          <Translate contentKey="generadorApp.clComItem.materialMedicine">Material Medicine</Translate>
                        </Label>
                        <SelectAsync
                          id="cl-com-item-materialMedicine"
                          isMulti
                          className={'css-select-control'}
                          name={'materialMedicine'}
                          cacheOptions
                          value={this.state.materialMedicineId}
                          onChange={options => this.setState({ materialMedicineId: options })}
                          defaultOptions={this.state.materialMedicineStartFilter ? this.state.materialMedicineStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.materialMedicineStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.materialMedicineStartFilter === undefined) {
                              const result = await getListAxios('medicine-materials', {}, 0, 100, 'name,asc', 'name');
                              this.setState({
                                materialMedicineStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios(
                              'medicine-materials',
                              { 'name.contains': inputValue },
                              0,
                              100,
                              'name,asc',
                              'name'
                            );
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}
              </div>

              <div className="row mb-2 mr-4 justify-content-end">
                <Button color="primary" type="submit">
                  <i className="fa fa-filter" aria-hidden={'true'}></i>&nbsp;
                  <Translate contentKey="generadorApp.clComItem.btnFilter">Filter</Translate>
                </Button>
                &nbsp;
                <Button color="secondary" onClick={this.cancelFilters}>
                  <FontAwesomeIcon icon="trash-alt" />
                  &nbsp;
                  <Translate contentKey="generadorApp.clComItem.btnCleanFilter">Clean Filter</Translate>
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </UncontrolledCollapse>
      </>
    );
  }

  renderTable() {
    const { clComTables, levelGroups, categories, medicineMaterials, clComItemList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {clComItemList && clComItemList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null).length > 0 ? (
          <>
            <Table
              id="cl-com-item-table-list"
              responsive
              aria-describedby="cl-com-item-heading"
              className={'table-hover table-striped table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.clComItem.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'materialMedicine.medicineMaterialType' ? (
                    <th>
                      <Translate contentKey="generadorApp.clComItem.materialMedicine">Material Medicine</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'materialMedicine.categories.name' ? (
                    <th>
                      <Translate contentKey="generadorApp.clComItem.materialMedicine.categories">Material Medicine</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'materialMedicine' ? (
                    <th>
                      <Translate contentKey="generadorApp.clComItem.materialMedicine">Material Medicine</Translate>
                    </th>
                  ) : null}
                  {this.props.entityEmbebedPai && this.props.entityEmbebedPai.type !== 'PACOTE' && state.baseFilters !== 'saleValue' ? (
                    <th className="hand" onClick={sortFunction('saleValue')}>
                      <Translate contentKey="generadorApp.clComItem.saleValue">Sale Value</Translate>
                      <FontAwesomeIcon icon={state.sort === 'saleValue' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'clComTable' ? (
                    <th>
                      <Translate contentKey="generadorApp.clComItem.clComTable">Cl Com Table</Translate>
                    </th>
                  ) : null}

                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {clComItemList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((clComItem, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.clComItem.field_id">ID</Translate>
                        </b>
                        {clComItem.id}
                      </td>

                      {state.baseFilters !== 'materialMedicine' ? (
                        <td id="materialMedicine-cell">
                          <b className="visible-xs"> Material Medicine </b>
                          {clComItem ? (
                            <span data-type-rel="2-many-to-one-other-side">
                              {getValueRecursive(clComItem, 'materialMedicine.medicineMaterialType'.split('.'), '')}
                            </span>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'materialMedicine.categories' ? (
                        <td id="materialMedicine-categories-cell">
                          <b className="visible-xs"> Material Medicine </b>
                          {clComItem ? (
                            <span data-type-rel="2-many-to-one-other-side">
                              {getValueRecursive(clComItem, 'materialMedicine.categories.name'.split('.'), '')}
                            </span>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'materialMedicine' ? (
                        <td id="materialMedicine-cell">
                          <b className="visible-xs"> Material Medicine </b>
                          {clComItem ? (
                            <span data-type-rel="2-many-to-one-other-side">
                              {getValueRecursive(clComItem, 'materialMedicine.name'.split('.'), '')}
                            </span>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : null}

                      {this.props.entityEmbebedPai && this.props.entityEmbebedPai.type !== 'PACOTE' && state.baseFilters !== 'saleValue' ? (
                        <td id="saleValue-cell">
                          <b className="visible-xs"> Sale Value </b>
                          R$ {(clComItem.saleValue * 1).toFixed(2).replace('.', ',')}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'clComTable' ? (
                        <td id="clComTable-cell">
                          <b className="visible-xs"> Cl Com Table </b>
                          {clComItem ? (
                            <span data-type-rel="2-many-to-one-other-side">
                              {getValueRecursive(clComItem, 'clComTable.name'.split('.'), '')}
                            </span>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : null}

                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          {hasAnyAuthority(props.userAccount, ['client'], 'edit') ? (
                            <Button onClick={() => this.openUpdateModal(clComItem)} color="primary" size="sm">
                              <FontAwesomeIcon icon="pencil-alt" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                          {hasAnyAuthority(props.userAccount, ['client'], 'edit') ? (
                            <Button onClick={() => this.deleteEntity(clComItem)} color="danger" size="sm">
                              <FontAwesomeIcon icon="trash" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.clComItem.home.notFound">No Items found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const { clComTables, levelGroups, categories, medicineMaterials, clComItemList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={clComItemList && clComItemList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const { clComTables, levelGroups, categories, medicineMaterials, clComItemList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel className="cl-com-item cl-com-item-all-in-one">
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
            {this.renderNewModal()}
            {this.renderUpdateModal()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToProps = ({ clComItem, ...storeState }: IRootState) => ({
  clComTables: storeState.clComTable.entities,
  levelGroups: storeState.levelGroup.entities,
  categories: storeState.category.entities,
  medicineMaterials: storeState.medicineMaterial.entities,
  clComItemList: clComItem.entities,
  totalItems: clComItem.totalItems,
  userAccount: storeState.authentication.account,
  loading: clComItem.loading,

  // UPDATE
  clComItemEntity: clComItem.entity,
});

export const mapDispatchToProps = {
  getEntities,

  updateEntity,
  reset,

  // UPDATE
  getEntity,
  setBlob,
  createEntity,

  // DELETE
  deleteEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ClComItem);
