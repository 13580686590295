/* eslint complexity: ["error", 500] */
import axios from 'axios';

import { IPaginationBaseState, IPayloadResult } from 'react-jhipster';

import { IPayload } from 'react-jhipster/src/type/redux-action.type';

import { BASE_API_VERSION_PATH } from 'app/config/constants';

import { cleanEntity, jsonParse } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IPatientNursingPrescription, defaultValue } from 'app/shared/model/patient-nursing-prescription.model';

import { IPatNrPrescProcediment, defaultValue as defaultValuePatNrPrescProcediment } from 'app/shared/model/pat-nr-presc-procediment.model';

export declare type ICrudPutAction<T> = (data?: T, listFiltersPage?) => IPayload<T> | IPayloadResult<T>;
export declare type ICrudGetAction<T> = (id: string | number, selectFields?: string) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const ACTION_TYPES = {
  FETCH_PATIENTNURSINGPRESCRIPTION_LIST_EXPORT: 'patientNursingPrescription/FETCH_PATIENTNURSINGPRESCRIPTION_LIST_EXPORT',
  FETCH_PATIENTNURSINGPRESCRIPTION_LIST: 'patientNursingPrescription/FETCH_PATIENTNURSINGPRESCRIPTION_LIST',
  FETCH_PATIENTNURSINGPRESCRIPTION: 'patientNursingPrescription/FETCH_PATIENTNURSINGPRESCRIPTION',
  FETCH_PATIENTNURSINGPRESCRIPTION_OFFSET: 'patientNursingPrescription/FETCH_PATIENTNURSINGPRESCRIPTION_OFFSET',
  CREATE_PATIENTNURSINGPRESCRIPTION: 'patientNursingPrescription/CREATE_PATIENTNURSINGPRESCRIPTION',
  UPDATE_PATIENTNURSINGPRESCRIPTION: 'patientNursingPrescription/UPDATE_PATIENTNURSINGPRESCRIPTION',
  CREATE_PATIENTNURSINGPRESCRIPTION_AND_GO_LIST: 'patientNursingPrescription/CREATE_PATIENTNURSINGPRESCRIPTION_AND_GO_LIST',
  UPDATE_PATIENTNURSINGPRESCRIPTION_AND_GO_LIST: 'patientNursingPrescription/UPDATE_PATIENTNURSINGPRESCRIPTION_AND_GO_LIST',
  DELETE_PATIENTNURSINGPRESCRIPTION: 'patientNursingPrescription/DELETE_PATIENTNURSINGPRESCRIPTION',
  RESET: 'patientNursingPrescription/RESET',
  SHOW_MODAL: 'patientNursingPrescription/SHOW_MODAL',
};

const initialState = {
  loading: false,
  openModal: {
    list: false,
    view: false,
    detail: false,
  },
  errorMessage: null,
  entities: [] as ReadonlyArray<IPatientNursingPrescription>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,

  patNrPrescProcedimentListEmbed: [] as ReadonlyArray<IPatNrPrescProcediment> | any,
  patNrPrescProcedimentListAllEmbed: [] as ReadonlyArray<IPatNrPrescProcediment> | any,
  patNrPrescProcedimentSelected: defaultValuePatNrPrescProcediment,
};

const actionPendind = [];
let actualState = initialState;

export const convertEntityBuffers = entity => ({
  ...entity,
});

export type PatientNursingPrescriptionState = Readonly<typeof initialState>;

export interface IPatientNursingPrescriptionBaseState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  listCheckedID?: Array<number>;
  isCheckedAll?: boolean;
  urlBack?: string;
  extraFilters?: any;
  baseFilters: any;
  offset: number;
  observations?: any;
  technicianId?: any;
  technicianStartFilter?: any;
  patientId?: any;
  patientStartFilter?: any;
  nrDiagnosticId?: any;
  nrDiagnosticStartFilter?: any;
  patNrPrescProcedimentId?: any;
  patNrPrescProcedimentStartFilter?: any;
}

export interface IFieldsBase extends IPatientNursingPrescriptionBaseState, IPaginationBaseState {}
export interface IPatientNursingPrescriptionUpdateState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  urlBack?: string;
  functionEmbebed?: any[];
  fieldsBase: IFieldsBase;
  observationsSelectValue?: any;
  observationsStartSelectOptions?: any;

  patNrPrescProcedimentSelectValue?: any;
  patNrPrescProcedimentStartSelectOptions?: any;

  nrDiagnosticSelectValue?: any;
  nrDiagnosticStartSelectOptions?: any;

  patientSelectValue?: any;
  patientStartSelectOptions?: any;

  technicianSelectValue?: any;
  technicianStartSelectOptions?: any;

  isNew: boolean;
  idsnrDiagnostic: any[];
  patient?: any;

  patientId?: any;
  technicianName?: any;

  technicianId?: any;
}

// Reducer

export default (state: PatientNursingPrescriptionState = initialState, action): PatientNursingPrescriptionState => {
  actualState = state;
  if (
    action.type.includes('_PENDING') &&
    !actionPendind.includes(action.type.replace('_PENDING', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_PENDING', ''))
  ) {
    actionPendind.push(action.type.replace('_PENDING', ''));
  }

  if (
    action.type.includes('_FULFILLED') &&
    actionPendind.includes(action.type.replace('_FULFILLED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_FULFILLED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_FULFILLED', '')), 1);
  }

  if (
    action.type.includes('_REJECTED') &&
    actionPendind.includes(action.type.replace('_REJECTED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_REJECTED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_REJECTED', '')), 1);
  }

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PATIENTNURSINGPRESCRIPTION_LIST_EXPORT):
    case REQUEST(ACTION_TYPES.FETCH_PATIENTNURSINGPRESCRIPTION_LIST):
    case REQUEST(ACTION_TYPES.FETCH_PATIENTNURSINGPRESCRIPTION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_PATIENTNURSINGPRESCRIPTION):
    case REQUEST(ACTION_TYPES.UPDATE_PATIENTNURSINGPRESCRIPTION):
    case REQUEST(ACTION_TYPES.CREATE_PATIENTNURSINGPRESCRIPTION_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.UPDATE_PATIENTNURSINGPRESCRIPTION_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.DELETE_PATIENTNURSINGPRESCRIPTION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_PATIENTNURSINGPRESCRIPTION_LIST_EXPORT):
    case FAILURE(ACTION_TYPES.FETCH_PATIENTNURSINGPRESCRIPTION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_PATIENTNURSINGPRESCRIPTION):
    case FAILURE(ACTION_TYPES.CREATE_PATIENTNURSINGPRESCRIPTION):
    case FAILURE(ACTION_TYPES.UPDATE_PATIENTNURSINGPRESCRIPTION):
    case FAILURE(ACTION_TYPES.CREATE_PATIENTNURSINGPRESCRIPTION_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.UPDATE_PATIENTNURSINGPRESCRIPTION_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.DELETE_PATIENTNURSINGPRESCRIPTION):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_PATIENTNURSINGPRESCRIPTION_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_PATIENTNURSINGPRESCRIPTION_OFFSET):
      if (action.payload.data && action.payload.data.length > 0) {
        return {
          ...state,
          loading: false,
          entity: convertEntityBuffers(action.payload.data[0]),
        };
      }
      return {
        ...state,
        loading: false,
      };

    case SUCCESS(ACTION_TYPES.FETCH_PATIENTNURSINGPRESCRIPTION):
      return {
        ...state,
        loading: false,
        entity: convertEntityBuffers(action.payload.data),
      };
    case SUCCESS(ACTION_TYPES.CREATE_PATIENTNURSINGPRESCRIPTION):
    case SUCCESS(ACTION_TYPES.UPDATE_PATIENTNURSINGPRESCRIPTION):
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_PATIENTNURSINGPRESCRIPTION_AND_GO_LIST):
    case SUCCESS(ACTION_TYPES.UPDATE_PATIENTNURSINGPRESCRIPTION_AND_GO_LIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_PATIENTNURSINGPRESCRIPTION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
        patNrPrescProcedimentListEmbed: [],
        patNrPrescProcedimentListAllEmbed: [],
        patNrPrescProcedimentSelected: state.patNrPrescProcedimentSelected,
      };

    case 'patientNursingPrescription/PATNRPRESCPROCEDIMENT_GET_ENTITIES':
      return {
        ...state,
        patNrPrescProcedimentListEmbed: state.patNrPrescProcedimentListAllEmbed,
      };
    case 'patientNursingPrescription/PATNRPRESCPROCEDIMENT_CREATE_ENTITY':
      return {
        ...state,
        patNrPrescProcedimentListAllEmbed: [
          ...(state.patNrPrescProcedimentListAllEmbed ? state.patNrPrescProcedimentListAllEmbed : []),
          action.payload,
        ],
      };
    case 'patientNursingPrescription/PATNRPRESCPROCEDIMENT_SET_ENTITIES':
      return {
        ...state,
        patNrPrescProcedimentListAllEmbed: action.payload,
      };
    case 'patientNursingPrescription/PATNRPRESCPROCEDIMENT_UPDATE_ENTITY': {
      const patNrPrescProcedimentListAllEmbed = [...state.patNrPrescProcedimentListAllEmbed];
      state.patNrPrescProcedimentListAllEmbed.map((v, i: any) => {
        if (v.id === action.payload.id) {
          patNrPrescProcedimentListAllEmbed[i] = action.payload;
        }
      });
      return {
        ...state,
        patNrPrescProcedimentListAllEmbed,
        patNrPrescProcedimentSelected: {},
      };
    }
    case 'patientNursingPrescription/PATNRPRESCPROCEDIMENT_RESET':
      return {
        ...state,
        patNrPrescProcedimentSelected: {},
        patNrPrescProcedimentListEmbed: [],
      };
    case 'patientNursingPrescription/PATNRPRESCPROCEDIMENT_GET_ENTITY':
      if (!!state.patNrPrescProcedimentListAllEmbed && state.patNrPrescProcedimentListAllEmbed.constructor === Array) {
        return {
          ...state,
          patNrPrescProcedimentSelected: state.patNrPrescProcedimentListAllEmbed.filter(v => v.id === action.payload).pop(),
        };
      }
      return {
        ...state,
        patNrPrescProcedimentSelected: state.patNrPrescProcedimentListAllEmbed,
      };
    case 'patientNursingPrescription/PATNRPRESCPROCEDIMENT_DELETE_ENTITY':
      return {
        ...state,
        patNrPrescProcedimentListAllEmbed: state.patNrPrescProcedimentListAllEmbed.filter(v => v.id !== action.payload),
      };

    case ACTION_TYPES.SHOW_MODAL: {
      const openModal = state.openModal;
      openModal[action.payload.type] = action.payload.show;
      return {
        ...state,
        openModal,
      };
    }
    default:
      return state;
  }
};

const apiUrl = BASE_API_VERSION_PATH + 'patient-nursing-prescriptions';

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

// Actions

// Actions
export type ICrudGetAllActionPatientNursingPrescription<T> = (
  observations?: any,
  technicianId?: any,
  patientId?: any,
  nrDiagnosticId?: any,
  patNrPrescProcedimentId?: any,
  extraFilters?: {},
  page?: number,
  size?: number,
  sort?: string,
  selectFields?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getEntitiesFilter = (filters?, page?, size?, sort?, selectFields = '') => {
  const requestUrl = `${apiUrl}?page=${page >= 0 ? page : 0}&size=${size >= 0 ? size : 20}&sort=${sort ? sort : 'id,asc'}&`;
  const requestFilters = filters.map(v => v.column + '.' + v.operation + '=' + v.value).join('&');
  return {
    type: ACTION_TYPES.FETCH_PATIENTNURSINGPRESCRIPTION_LIST,
    payload: axios.get<IPatientNursingPrescription>(`${requestUrl}${requestFilters}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getAllEntities = (filters?, sort?, selectFields = '') => {
  if (
    (actualState.totalItems > 0 && actualState.entities.length === actualState.totalItems) ||
    actionPendind.includes(ACTION_TYPES.FETCH_PATIENTNURSINGPRESCRIPTION_LIST)
  ) {
    return {
      type: ACTION_TYPES.FETCH_PATIENTNURSINGPRESCRIPTION_LIST + '_FROM_CACHE',
      payload: new Promise(function (resolve, reject) {
        resolve('done');
      }),
    };
  }

  const idsRequest = filters && typeof filters['ids'] !== 'undefined' && filters['ids'] ? `id.in=${filters['ids'].join(',')}&` : '';
  const observationsRequest =
    filters && typeof filters['observations'] !== 'undefined' && filters['observations']
      ? `observations.contains=${filters['observations']}&`
      : '';
  const technicianRequest =
    filters && typeof filters['technicianId'] !== 'undefined' && filters['technicianId']
      ? `technician.id.in=${filters['technicianId']}&`
      : '';
  const patientRequest =
    filters && typeof filters['patientId'] !== 'undefined' && filters['patientId'] ? `patient.id.in=${filters['patientId']}&` : '';
  const nrDiagnosticRequest =
    filters && typeof filters['nrDiagnosticId'] !== 'undefined' && filters['nrDiagnosticId']
      ? `nrDiagnostics.id.in=${filters['nrDiagnosticId']}&`
      : '';
  const patNrPrescProcedimentRequest =
    filters && typeof filters['patNrPrescProcedimentId'] !== 'undefined' && filters['patNrPrescProcedimentId']
      ? `patNrPrescProcediment.id.in=${filters['patNrPrescProcedimentId']}&`
      : '';

  const requestUrl = `${apiUrl}${`?page=${0}&size=${1000000}&sort=${sort ? sort : 'id,asc'}&`}`;
  return {
    type: ACTION_TYPES.FETCH_PATIENTNURSINGPRESCRIPTION_LIST,
    payload: axios.get<IPatientNursingPrescription>(
      `${requestUrl}${idsRequest}${observationsRequest}${technicianRequest}${patientRequest}${nrDiagnosticRequest}${patNrPrescProcedimentRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntities: ICrudGetAllActionPatientNursingPrescription<IPatientNursingPrescription> = (
  observations,
  technicianId,
  patientId,
  nrDiagnosticId,
  patNrPrescProcedimentId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const observationsRequest = observations ? `observations.contains=${observations}&` : '';
  const technicianRequest = technicianId ? `technician.id.in=${technicianId}&` : '';
  const patientRequest = patientId ? `patient.id.in=${patientId}&` : '';
  const nrDiagnosticRequest = nrDiagnosticId ? `nrDiagnostics.id.in=${nrDiagnosticId}&` : '';
  const patNrPrescProcedimentRequest = patNrPrescProcedimentId ? `patNrPrescProcediment.id.in=${patNrPrescProcedimentId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PATIENTNURSINGPRESCRIPTION_LIST,
    payload: axios.get<IPatientNursingPrescription>(
      `${requestUrl}${extraFiltersRequest}${observationsRequest}${technicianRequest}${patientRequest}${nrDiagnosticRequest}${patNrPrescProcedimentRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntityOffset: any = (
  observations,
  technicianId,
  patientId,
  nrDiagnosticId,
  patNrPrescProcedimentId,
  extraFilters,
  offset,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const observationsRequest = observations ? `observations.contains=${observations}&` : '';
  const technicianRequest = technicianId ? `technician.id.in=${technicianId}&` : '';
  const patientRequest = patientId ? `patient.id.in=${patientId}&` : '';
  const nrDiagnosticRequest = nrDiagnosticId ? `nrDiagnostics.id.in=${nrDiagnosticId}&` : '';
  const patNrPrescProcedimentRequest = patNrPrescProcedimentId ? `patNrPrescProcediment.id.in=${patNrPrescProcedimentId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${offset}&size=${1}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PATIENTNURSINGPRESCRIPTION_OFFSET,
    payload: axios.get<IPatientNursingPrescription>(
      `${requestUrl}${extraFiltersRequest}${observationsRequest}${technicianRequest}${patientRequest}${nrDiagnosticRequest}${patNrPrescProcedimentRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntity: ICrudGetAction<IPatientNursingPrescription> = (id, selectFields = '') => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_PATIENTNURSINGPRESCRIPTION,
    payload: axios.get<IPatientNursingPrescription>(requestUrl, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntitiesExport: ICrudGetAllActionPatientNursingPrescription<IPatientNursingPrescription> = (
  observations,
  technicianId,
  patientId,
  nrDiagnosticId,
  patNrPrescProcedimentId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const observationsRequest = observations
    ? Array.isArray(status)
      ? `observations.in=${observations.join(',')}&`
      : `observations.contains=${observations}&`
    : '';
  const technicianRequest = technicianId ? `technician.id.in=${technicianId}&` : '';
  const patientRequest = patientId ? `patient.id.in=${patientId}&` : '';
  const nrDiagnosticRequest = nrDiagnosticId ? `nrDiagnostics.id.in=${nrDiagnosticId}&` : '';
  const patNrPrescProcedimentRequest = patNrPrescProcedimentId ? `patNrPrescProcediment.id.in=${patNrPrescProcedimentId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PATIENTNURSINGPRESCRIPTION_LIST,
    payload: axios.get<IPatientNursingPrescription>(
      `${requestUrl}${extraFiltersRequest}${observationsRequest}${technicianRequest}${patientRequest}${nrDiagnosticRequest}${patNrPrescProcedimentRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const createEntity: ICrudPutAction<IPatientNursingPrescription> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.CREATE_PATIENTNURSINGPRESCRIPTION_AND_GO_LIST
        : ACTION_TYPES.CREATE_PATIENTNURSINGPRESCRIPTION,
    payload: axios.post(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const updateEntity: ICrudPutAction<IPatientNursingPrescription> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.UPDATE_PATIENTNURSINGPRESCRIPTION_AND_GO_LIST
        : ACTION_TYPES.UPDATE_PATIENTNURSINGPRESCRIPTION,
    payload: axios.put(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const deleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_PATIENTNURSINGPRESCRIPTION,
    payload: axios.delete(requestUrl),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const showModal = view => ({
  payload: { type: view, show: true },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const hideModal = view => ({
  payload: { type: view, show: false },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const getUrlBack = (location): string => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  let urlBack = _urlBase.searchParams.get('urlBack') || '';
  urlBack = urlBack.trim();
  urlBack = urlBack.startsWith('/') ? urlBack.slice(1) : urlBack;
  urlBack = urlBack.endsWith('/') ? urlBack.slice(0, -1) : urlBack;
  return urlBack ? '/' + urlBack + '/' : '';
};

export const getPatientNursingPrescriptionState = (location): IPatientNursingPrescriptionBaseState => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  const extraFilters = jsonParse(_urlBase.searchParams.get('extraFilters') || '{}');
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;

  const observations = _urlBase.searchParams.get('observations') || '';
  const technicianId = _urlBase.searchParams.get('technician') || '';
  const patientId = _urlBase.searchParams.get('patient') || '';
  const nrDiagnosticId = _urlBase.searchParams.get('nrDiagnostic') || '';
  const patNrPrescProcedimentId = _urlBase.searchParams.get('patNrPrescProcediment') || '';
  return {
    baseFilters,
    extraFilters,
    offset,
    observations,
    technicianId: technicianId ? technicianId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
    patientId: patientId ? patientId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
    nrDiagnosticId: nrDiagnosticId ? nrDiagnosticId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
    patNrPrescProcedimentId: patNrPrescProcedimentId
      ? patNrPrescProcedimentId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] }))
      : [],
  };
};

export const getEntityFiltersURL = (state, offset = null) => {
  return (
    'baseFilters=' +
    state.baseFilters +
    '&extraFilters=' +
    encodeURI(JSON.stringify(state.extraFilters)) +
    '&page=' +
    state.activePage +
    '&' +
    'size=' +
    state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    state.sort +
    ',' +
    state.order +
    '&' +
    (state.observations ? 'observations=' + state.observations + '&' : '') +
    (state.technicianId ? 'technician=' + state.technicianId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.patientId ? 'patient=' + state.patientId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.nrDiagnosticId ? 'nrDiagnostic=' + state.nrDiagnosticId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.patNrPrescProcedimentId
      ? 'patNrPrescProcediment=' + state.patNrPrescProcedimentId.map(v => v.value + '<->' + v.label).join(',') + '&'
      : '') +
    ''
  );
};

export const patNrPrescProcedimentReset: any = values => ({
  payload: values,
  type: 'patientNursingPrescription/PATNRPRESCPROCEDIMENT_RESET',
});

export const patNrPrescProcedimentGetEntities: any = values => ({
  payload: values,
  type: 'patientNursingPrescription/PATNRPRESCPROCEDIMENT_GET_ENTITIES',
});

export const patNrPrescProcedimentSetEntities: any = values => async dispatch => {
  const result = await dispatch({
    payload: values,
    type: 'patientNursingPrescription/PATNRPRESCPROCEDIMENT_SET_ENTITIES',
  });
  dispatch(patNrPrescProcedimentGetEntities());
  return result;
};

export const patNrPrescProcedimentCreateEntity: ICrudPutAction<IPatNrPrescProcediment> = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: { id: (new Date().getTime() * Math.random()).toString(16).split('.').join('-'), ...entity },
    type: 'patientNursingPrescription/PATNRPRESCPROCEDIMENT_CREATE_ENTITY',
  });

  if (entity.patientNursingPrescription === 'is_new') {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(patNrPrescProcedimentReset());
      dispatch(patNrPrescProcedimentGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};

export const patNrPrescProcedimentUpdateEntity: ICrudPutAction<IPatNrPrescProcediment> = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: entity,
    type: 'patientNursingPrescription/PATNRPRESCPROCEDIMENT_UPDATE_ENTITY',
  });
  if (entity.patientNursingPrescription === 'is_new') {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(patNrPrescProcedimentReset());
      dispatch(patNrPrescProcedimentGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};
export const patNrPrescProcedimentGetEntity: any = values => ({
  payload: values,
  type: 'patientNursingPrescription/PATNRPRESCPROCEDIMENT_GET_ENTITY',
});

export const patNrPrescProcedimentDeleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: id,
    type: 'patientNursingPrescription/PATNRPRESCPROCEDIMENT_DELETE_ENTITY',
  });
  if (isNaN(id)) {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(patNrPrescProcedimentReset());
      dispatch(patNrPrescProcedimentGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};
