/* eslint-disable no-console */

import { translate } from 'app/config/translate-component';
import { IPatDrPrescrProcd } from 'app/shared/model/pat-dr-prescr-procd.model';
import { jsonParse } from 'app/shared/util/entity-utils';

import React from 'react';

export interface IPatDrPrescrProcdProps {
  baseState?: any;
  baseProps?: any;
  setState?: any;
  entityBase?: IPatDrPrescrProcd;
}

const PatDrPrescrProcdComponentCustomize = (props: IPatDrPrescrProcdProps) => {
  let frequencyDescriptionBuffered = props.entityBase.frequencyDescription;
  if (frequencyDescriptionBuffered && frequencyDescriptionBuffered.type === 'Buffer') {
    frequencyDescriptionBuffered = frequencyDescriptionBuffered ? Buffer.from(frequencyDescriptionBuffered).toString() : '[]';
  }
  const frequencyDescriptionJson = frequencyDescriptionBuffered ? frequencyDescriptionBuffered : '[]';

  const isDayOfWeek = ['UMXSEMANA', 'DOISXSEMANA', 'TRESXSEMANA', 'QUATROXSEMANA', 'CINCOXSEMANA', 'SEISXSEMANA'].includes(
    props.entityBase.frequency
  );
  const isHoursOfDay = ['UMXDIA', 'DOISXDIA', 'TRESXDIA', 'QUATROXDIA'].includes(props.entityBase.frequency);
  const isDayOfMonth = ['UMXMES', 'DOISXMES', 'TRESXMES', 'QUATROXMES'].includes(props.entityBase.frequency);

  try {
    const frequencyDescriptionObjAux = { '0': '', '1': '', '2': '', '3': '', '4': '', '5': '' };
    jsonParse(frequencyDescriptionJson, false).map((v, i) => {
      frequencyDescriptionObjAux[i] = v;
    });

    return (
      <>
        {isDayOfWeek || isDayOfMonth ? (
          Object.values(frequencyDescriptionObjAux).map((v, j) => {
            if (isDayOfWeek && v) {
              return (
                <div key={j}>
                  {v} - {props.entityBase.initialHour}
                </div>
              );
            }
            if (isDayOfMonth && v) {
              return (
                <div key={j}>
                  Dia {v} - {props.entityBase.initialHour}
                </div>
              );
            }
            return <div key={j}></div>;
          })
        ) : (
          <> </>
        )}
        {isHoursOfDay ? (
          <>
            Todos os dias as:{' '}
            {Object.values(frequencyDescriptionObjAux).map((v, j) => {
              if (v) {
                return <div key={j}> - {v}</div>;
              }
              return <div key={j}></div>;
            })}
          </>
        ) : (
          <> </>
        )}
        {!isDayOfWeek && !isDayOfMonth && !isHoursOfDay ? translate('generadorApp.QuoteFrequency.' + props.entityBase.frequency) : <> </>}
      </>
    );
  } catch (error) {
    console.info(error);
    return <></>;
  }
};

const mapStateToProps = storeState => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default PatDrPrescrProcdComponentCustomize;
