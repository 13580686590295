import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity, IPatientCidBaseState, getUrlBack, getPatientCidState, getEntityFiltersURL } from './patient-cid.reducer';

export interface IPatientCidState {
  fieldsBase: IPatientCidBaseState;
}

export interface IPatientCidDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class PatientCidDetail extends React.Component<IPatientCidDetailProps, IPatientCidState> {
  constructor(props: Readonly<IPatientCidDetailProps>) {
    super(props);
    this.state = {
      ...this.state,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getPatientCidState(this.props.location),
        urlBack: getUrlBack(this.props.location),
        patientId: [{ value: this.props.match.params['idPatient'], label: this.props.match.params['idPatient'] }],
        baseFilters: 'patient',
      },
    };
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = '_back=1' + (offset !== null ? '&offset=' + offset : '');
    Object.keys(fieldsBase).map(key => {
      url += '&' + key + '=' + fieldsBase[key];
    });
    return url;
  };

  UNSAFE_componentWillMount() {
    this.props.getEntity(this.props.match.params['id']);
  }

  renderHeader() {
    const { patientCidEntity } = this.props;
    const state = this.state;
    return (
      <>
        <div id="page-heading">
          {' '}
          <h2>
            <Translate contentKey="generadorApp.patientCid.detail.title">PatientCid</Translate>
            <small>
              &nbsp; {patientCidEntity['cid'] ? patientCidEntity['cid']['code'] : ''}
              &nbsp; {patientCidEntity['cid'] ? patientCidEntity['cid']['name'] : ''}
            </small>
          </h2>
        </div>

        <div className="pull-right">
          <Button
            tag={Link}
            to={'/patient/' + this.props.match.params['idPatient'] + '/patient-cid?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
          >
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.patientCid.btnBack">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/patient/${this.props.match.params['idPatient']}/patient-cid/${patientCidEntity.id}/edit?+${getEntityFiltersURL(
              this.state.fieldsBase
            )}`}
            replace
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.patientCid.btnEdit">Edit</Translate>
            </span>
          </Button>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>

          <li className="breadcrumb-item">
            <Link to={'/patient'}>Patients</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/patient/' + this.props.match.params['idPatient']}>{this.props.match.params['idPatient']}</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/patient/' + (this.state.fieldsBase.patientId ? this.state.fieldsBase.patientId.label : '') + '/patient-cid'}>
              Cids
            </Link>
          </li>
          <li className="breadcrumb-item active">Cids details</li>
        </ol>
      </>
    );
  }

  renderBody() {
    const { patientCidEntity } = this.props;
    const state = this.state;
    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            <Row className="jh-entity-details">
            <Col className="view-detail-row patient-cid-cidComments-detail" md="2">
                <Row>
                  <Col className="view-detail-label-top patient-cid-cidComments-detail-label" md="12">
                    <dt>
                      <span id="cidComments">
                        <text>Nome</text>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top patient-cid-cidComments-detail-value" md="12">
                    <dd>{patientCidEntity.cid.name}</dd>
                  </Col>
                </Row>
              </Col>
              <Col className="view-detail-row patient-cid-cidComplexity-detail" md="2">
                <Row>
                  <Col className="view-detail-label-top patient-cid-cidComplexity-detail-label" md="12">
                    <dt>
                      <span id="cidComplexity">
                        <Translate contentKey="generadorApp.patientCid.cidComplexity">Complexity</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top patient-cid-cidComplexity-detail-value" md="12">
                    <dd>
                      {translate(
                        'generadorApp.CidComplexity.' + (patientCidEntity.cidComplexity ? patientCidEntity.cidComplexity : 'BAIXA')
                      )}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row patient-cid-cidPrimary-detail" md="2">
                <Row>
                  <Col className="view-detail-label-top patient-cid-cidPrimary-detail-label" md="12">
                    <dt>
                      <span id="cidPrimary">
                        <Translate contentKey="generadorApp.patientCid.cidPrimary">Primary</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top patient-cid-cidPrimary-detail-value" md="12">
                    <dd>
                      {patientCidEntity.cidPrimary
                        ? translate('generadorApp.patientCid.cidPrimary.true')
                        : translate('generadorApp.patientCid.cidPrimary.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row patient-cid-status-detail" md="2">
                <Row>
                  <Col className="view-detail-label-top patient-cid-status-detail-label" md="12">
                    <dt>
                      <span id="status">
                        <Translate contentKey="generadorApp.patientCid.status">Status</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top patient-cid-status-detail-value" md="12">
                    <dd>{translate('generadorApp.Status.' + (patientCidEntity.status ? patientCidEntity.status : 'INATIVADO'))}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row patient-cid-cidComments-detail" md="4">
                <Row>
                  <Col className="view-detail-label-top patient-cid-cidComments-detail-label" md="12">
                    <dt>
                      <span id="cidComments">
                        <Translate contentKey="generadorApp.patientCid.cidComments">Comments</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top patient-cid-cidComments-detail-value" md="12">
                    <dd>{patientCidEntity.cidComments === null ? "Sem comentários" : ""}</dd>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { patientCidEntity } = this.props;
    const state = this.state;
    return (
      <div>
        <Panel className="patient-cid patient-cid-detail">
          <PanelHeader>{this.renderHeader()}</PanelHeader>

          <PanelBody>{this.renderBody()}</PanelBody>
        </Panel>
      </div>
    );
  }
}

export const mapStateToProps = ({ patientCid, ...storeState }: IRootState) => ({
  patientCidEntity: patientCid.entity,
});

export const mapDispatchToProps = {
  getEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PatientCidDetail);
