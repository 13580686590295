import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ClComTable from './extended/cl-com-table';

import ClComTableDetail from './cl-com-table-detail';

import ClComTableUpdate from './extended/cl-com-table-update';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={ClComTableUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={ClComTableUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={ClComTableDetail} />
      <ErrorBoundaryRoute path={match.path} component={ClComTable} />
    </Switch>
  </>
);

export default Routes;
