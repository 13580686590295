/* eslint complexity: ["error", 500] */
import React from 'react';
import axios from 'axios';

import CurrencyInput from 'react-currency-input';
import { connect } from 'react-redux';

import {
  getFilterFromSelect,
  getFormFromSelect,
  getListAxios,
  getValueRecursive,
  showFieldsSelectAsync,
  checkAnyValueFields,
} from 'app/shared/util/entity-utils';
import Select from 'react-select';
import { toast } from 'react-toastify';
import SelectAsync from 'react-select/async';
import { Link, RouteComponentProps } from 'react-router-dom';

import { Button, Col, Row, Table, Label, UncontrolledCollapse, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { setFileData, JhiItemCount } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import LevelGroupTable from './components/LevelGroupTable';
import SpecialtyProductServiceTableTable from './components/SpecialtyProductServiceTableTable';
import RentalSaleValueTable from './components/RentalSaleValueTable';
import LevelGroupCategory from './components/LevelGroupCategory';
import CategoryObj from './components/CategoryObj';
import BreackLine from './components/BreackLine';

import { IRootState } from 'app/shared/reducers';
import {
  updateEntity,
  getUrlBack,
  getClComRulerState,
  IClComRulerBaseState,
  getEntityFiltersURL,
  getEntities,
  IClComRulerUpdateState as IBaseUpdateState,
  reset,
  getEntity,
  deleteEntity,
  createEntity,
  setBlob,
} from './cl-com-ruler.reducer';

import { ITEMS_PER_PAGE, IPaginationBaseState, getSortState } from 'app/shared/util/pagination.constants';

import { StatePropsExtended, DispatchPropsExtended, IExtendedState, IExtendedProps } from './extended/cl-com-ruler';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { RenderModalSuperSelect } from 'app/shared/layout/components/RenderModalSuperSelect';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

export interface IClComRulerProps
  extends StateProps,
    DispatchProps,
    StatePropsExtended,
    DispatchPropsExtended,
    IExtendedProps,
    RouteComponentProps<{ url: string }> {
  entityEmbebedPai?: any;
}

export interface IClComRulerState extends IClComRulerBaseState, IPaginationBaseState, IBaseUpdateState, IExtendedState {
  showModalForm?: boolean;
  showModalEdit?: number;
  showModalEditView?: boolean;
}

export class ClComRuler extends React.Component<IClComRulerProps, IClComRulerState> {
  protected myFormRef: any;
  protected myFormRefNew: any;
  protected myFormRefEdit: any;

  optionFileInput: any;

  comercialTableTypeFileInput: any;

  saleValueFileInput: any;

  observationFileInput: any;

  tableFileInput: any;

  percentFileInput: any;

  inflatorFileInput: any;

  deflatorFileInput: any;

  rentalValueFileInput: any;

  tussCodeFileInput: any;

  tissCodeFileInput: any;

  tableCodeFileInput: any;

  expenseCodeFileInput: any;

  frequencyFileInput: any;

  minValuePackageFileInput: any;

  constructor(props: IClComRulerProps) {
    super(props);
    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      listCheckedID: [],
      isCheckedAll: false,
      showModalForm: false,
      showModalEdit: null,
      urlBack: getUrlBack(this.props.location),
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      ...getClComRulerState(this.props.location),
      clComTableId: [
        {
          ...(this.props.entityEmbebedPai ? this.props.entityEmbebedPai : {}),
          value: this.props.match.params['idClComTable'],
          label: this.props.match.params['idClComTable'],
        },
      ],
      baseFilters: 'clComTable',
      clComTableSelectValue: {
        ...(this.props.entityEmbebedPai ? this.props.entityEmbebedPai : {}),
        value: this.props.match.params['idClComTable'],
        label: this.props.match.params['idClComTable'],
      },

      levelGroupSelectValue: null,

      categorySelectValue: null,

      productServiceSelectValue: null,

      specialtySelectValue: null,

      optionSelectValue: null,
      comercialTableTypeSelectValue: null,
      saleValueSelectValue: null,
      observationSelectValue: null,
      tableSelectValue: null,
      percentSelectValue: null,
      inflatorSelectValue: null,
      deflatorSelectValue: null,
      rentalValueSelectValue: null,
      tussCodeSelectValue: null,
      tissCodeSelectValue: null,
      tableCodeSelectValue: null,
      expenseCodeSelectValue: null,
      frequencySelectValue: null,
      minValuePackageSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getClComRulerState(this.props.location),
        clComTableId: [
          {
            ...(this.props.entityEmbebedPai ? this.props.entityEmbebedPai : {}),
            value: this.props.match.params['idClComTable'],
            label: this.props.match.params['idClComTable'],
          },
        ],
        baseFilters: 'clComTable',
      },
      idsproductService: [],
      idsspecialty: [],
      clComTableName: null,
      levelGroupName: null,
      categoryName: null,
      isNew: false,
    };

    axios
      .get('/api/cl-com-tables/' + this.props.match.params['idClComTable'], {
        headers: {
          'Select-Fields': 'name',
        },
      })
      .then(result => {
        this.setState({
          clComTableSelectValue: {
            ...result['data'],
            value: this.props.match.params['idClComTable'],
            label: showFieldsSelectAsync(result['data'], 'name'),
          },
        });
      });
  }

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({ listCheckedID: this.props.clComRulerList.map(clComRuler => clComRuler.id) });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({ listCheckedID: this.props.clComRulerList.map(clComRuler => clComRuler.id) });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  removeEntitySelected() {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  }

  saveEntityMany = () => {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  };

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  cancelFilters = () => {
    this.setState(
      {
        option: '',

        comercialTableType: '',

        saleValue: '',

        observation: '',

        table: '',

        percent: '',

        inflator: '',

        deflator: '',

        rentalValue: '',

        tussCode: '',

        tissCode: '',

        tableCode: '',

        expenseCode: '',

        frequency: '',

        minValuePackage: '',
        clComTableId: '',
        levelGroupId: '',
        categoryId: '',
        productServiceId: '',
        specialtyId: '',
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
        activePage: 0,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();

    const selectFieldsList = ['category.name', 'frequency', 'option', 'clComTable.name'];
    const {
      option,
      comercialTableType,
      saleValue,
      observation,
      table,
      percent,
      inflator,
      deflator,
      rentalValue,
      tussCode,
      tissCode,
      tableCode,
      expenseCode,
      frequency,
      minValuePackage,
      clComTableId,
      levelGroupId,
      categoryId,
      productServiceId,
      specialtyId,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;
    return this.props.getEntities(
      option,
      comercialTableType,
      saleValue,
      observation,
      table,
      percent,
      inflator,
      deflator,
      rentalValue,
      tussCode,
      tissCode,
      tableCode,
      expenseCode,
      frequency,
      minValuePackage,
      getFilterFromSelect(clComTableId, 'many-to-one'),
      getFilterFromSelect(levelGroupId, 'many-to-one'),
      getFilterFromSelect(categoryId, 'many-to-one'),
      getFilterFromSelect(productServiceId, 'many-to-many'),
      getFilterFromSelect(specialtyId, 'many-to-many'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(',')
    );
  };

  updateEntity = entity => {
    const selectFieldsList = ['category.name', 'frequency', 'option', 'clComTable.name'];

    const {
      option,
      comercialTableType,
      saleValue,
      observation,
      table,
      percent,
      inflator,
      deflator,
      rentalValue,
      tussCode,
      tissCode,
      tableCode,
      expenseCode,
      frequency,
      minValuePackage,
      clComTableId,
      levelGroupId,
      categoryId,
      productServiceId,
      specialtyId,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;
    this.props.updateEntity(entity, [
      option,
      comercialTableType,
      saleValue,
      observation,
      table,
      percent,
      inflator,
      deflator,
      rentalValue,
      tussCode,
      tissCode,
      tableCode,
      expenseCode,
      frequency,
      minValuePackage,
      getFilterFromSelect(clComTableId, 'many-to-one'),
      getFilterFromSelect(levelGroupId, 'many-to-one'),
      getFilterFromSelect(categoryId, 'many-to-one'),
      getFilterFromSelect(productServiceId, 'many-to-many'),
      getFilterFromSelect(specialtyId, 'many-to-many'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(','),
    ]);
  };

  onBlobChange = (isAnImage, name, fileInput) => event => {
    const fileName = event.target.files[0].name;
    setFileData(event, (contentType, data) => this.props.setBlob(name, data, contentType, fileName), isAnImage);
    const varAux = {};
    varAux[name + 'SelectValue'] = fileName;
    this.setState(varAux);
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined);
  };

  saveEntity = (event: any, errors: any, values: any, isNew: any) => {
    const selectFieldsList = ['category.name', 'frequency', 'option', 'clComTable.name'];

    if (errors.length === 0) {
      const { clComRulerEntity } = this.props;
      const _entity = {
        ...clComRulerEntity,
        // ...values,

        specialties: getFormFromSelect(this.state.specialtySelectValue, 'many-to-many'), // many-to-many - otherSide

        productServices: getFormFromSelect(this.state.productServiceSelectValue, 'many-to-many'), // many-to-many - otherSide

        clComTable: getFormFromSelect(this.state.clComTableSelectValue, 'many-to-one'), // many-to-one - otherSide

        option: this.state.optionSelectValue === null ? null : this.state.optionSelectValue,
        comercialTableType: this.state.comercialTableTypeSelectValue === null ? null : this.state.comercialTableTypeSelectValue,
        saleValue: this.state.saleValueSelectValue,
        observation: this.state.observationSelectValue,
        table: this.state.tableSelectValue === null ? null : this.state.tableSelectValue,
        percent: this.state.percentSelectValue,
        inflator: this.state.inflatorSelectValue,
        deflator: this.state.deflatorSelectValue,
        rentalValue: this.state.rentalValueSelectValue,
        tussCode: this.state.tussCodeSelectValue,
        tissCode: this.state.tissCodeSelectValue,
        tableCode: this.state.tableCodeSelectValue,
        expenseCode: this.state.expenseCodeSelectValue,
        frequency: this.state.frequencySelectValue === null ? null : this.state.frequencySelectValue,
        minValuePackage: this.state.minValuePackageSelectValue,
      };
      const entity = _entity;

      const {
        option,
        comercialTableType,
        saleValue,
        observation,
        table,
        percent,
        inflator,
        deflator,
        rentalValue,
        tussCode,
        tissCode,
        tableCode,
        expenseCode,
        frequency,
        minValuePackage,
        clComTableId,
        levelGroupId,
        categoryId,
        productServiceId,
        specialtyId,
        extraFilters,
        activePage,
        itemsPerPage,
        sort,
        order,
      } = this.state.fieldsBase;

      if (isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              option,
              comercialTableType,
              saleValue,
              observation,
              table,
              percent,
              inflator,
              deflator,
              rentalValue,
              tussCode,
              tissCode,
              tableCode,
              expenseCode,
              frequency,
              minValuePackage,
              getFilterFromSelect(clComTableId, 'many-to-one'),
              getFilterFromSelect(levelGroupId, 'many-to-one'),
              getFilterFromSelect(categoryId, 'many-to-one'),
              getFilterFromSelect(productServiceId, 'many-to-many'),
              getFilterFromSelect(specialtyId, 'many-to-many'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              option,
              comercialTableType,
              saleValue,
              observation,
              table,
              percent,
              inflator,
              deflator,
              rentalValue,
              tussCode,
              tissCode,
              tableCode,
              expenseCode,
              frequency,
              minValuePackage,
              getFilterFromSelect(clComTableId, 'many-to-one'),
              getFilterFromSelect(levelGroupId, 'many-to-one'),
              getFilterFromSelect(categoryId, 'many-to-one'),
              getFilterFromSelect(productServiceId, 'many-to-many'),
              getFilterFromSelect(specialtyId, 'many-to-many'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
      this.setState({ showModalForm: false });
      this.setState({ showModalEdit: null });
    } else {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.specialty.' + errors[0]) }));
    }
  };

  componentDidUpdate(prevProps) {}

  deleteEntity(clComRulerEntity) {
    MySwal.fire({
      title: <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>,
      html: (
        <Translate contentKey="generadorApp.clComRuler.delete.question" interpolate={{ id: clComRulerEntity.id }}>
          Are you sure you want to delete this ClComRuler?
        </Translate>
      ),
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#e70015',
      cancelButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="ban" />
          &nbsp; <Translate contentKey="generadorApp.clComRuler.btnCancel">Cancel</Translate>
        </>
      ),
      confirmButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="trash" />
          &nbsp;<Translate contentKey="generadorApp.clComRuler.btnDelete">Delete</Translate>
        </>
      ),
    }).then(async response => {
      if (response.isConfirmed) {
        await this.props.deleteEntity(clComRulerEntity.id, { reloadList: false });
        this.getEntities();
        MySwal.fire({
          title: <Translate contentKey="entity.delete.title.confirmed">Confirmed delete operation</Translate>,
          icon: 'success',
        });
      }
    });
  }

  renderNewModal() {
    return (
      <Modal size={'xl'} isOpen={this.state.showModalForm} toggle={() => this.setState({ showModalForm: false })}>
        <ModalHeader toggle={() => this.setState({ showModalForm: false })}>{this.renderHeaderUpdate({}, true)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefNew = el)}
          model={{}}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, true)}
        >
          <ModalBody>{this.renderBodyUpdate({}, true)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalForm: false })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.clComRuler.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefNew && this.myFormRefNew.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.clComRuler.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    );
  }

  getFullEntityFromState(v) {
    return {
      id: v.id,
      option: this.state.optionSelectValue,
      comercialTableType: this.state.comercialTableTypeSelectValue,
      saleValue: this.state.saleValueSelectValue,
      observation: this.state.observationSelectValue,
      table: this.state.tableSelectValue,
      percent: this.state.percentSelectValue,
      inflator: this.state.inflatorSelectValue,
      deflator: this.state.deflatorSelectValue,
      rentalValue: this.state.rentalValueSelectValue,
      tussCode: this.state.tussCodeSelectValue,
      tissCode: this.state.tissCodeSelectValue,
      tableCode: this.state.tableCodeSelectValue,
      expenseCode: this.state.expenseCodeSelectValue,
      frequency: this.state.frequencySelectValue,
      minValuePackage: this.state.minValuePackageSelectValue,
      clComTable: this.state.clComTableSelectValue,
      levelGroup: this.state.levelGroupSelectValue,
      category: this.state.categorySelectValue,
      productService: this.state.productServiceSelectValue,
      specialty: this.state.specialtySelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    let p: any = null;

    p = v.clComTable;
    const clComTableEntity = p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : p;

    p = v.levelGroup;
    const levelGroupEntity = p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : p;

    p = v.category;
    const categoryEntity = p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : p;

    const productServiceEntity = v.productServices
      ? v.productServices.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['name'] ? p1.name : '' }))
      : v.productServices;

    const specialtyEntity = v.specialties
      ? v.specialties.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['name'] ? p1.name : '' }))
      : v.specialties;

    return {
      optionSelectValue: v.option, // option,
      comercialTableTypeSelectValue: v.comercialTableType, // comercialTableType,
      saleValueSelectValue: v.saleValue, // saleValue,
      observationSelectValue: v.observation, // observation,
      tableSelectValue: v.table, // table,
      percentSelectValue: v.percent, // percent,
      inflatorSelectValue: v.inflator, // inflator,
      deflatorSelectValue: v.deflator, // deflator,
      rentalValueSelectValue: v.rentalValue, // rentalValue,
      tussCodeSelectValue: v.tussCode, // tussCode,
      tissCodeSelectValue: v.tissCode, // tissCode,
      tableCodeSelectValue: v.tableCode, // tableCode,
      expenseCodeSelectValue: v.expenseCode, // expenseCode,
      frequencySelectValue: v.frequency, // frequency,
      minValuePackageSelectValue: v.minValuePackage, // minValuePackage,
      clComTableSelectValue: clComTableEntity,
      levelGroupSelectValue: levelGroupEntity,
      categorySelectValue: categoryEntity,
      productServiceSelectValue: productServiceEntity,
      specialtySelectValue: specialtyEntity,
    };
  }

  openUpdateModal(v, edit = true) {
    this.props.getEntity(v.id);
    this.setState({
      showModalEdit: v.id,
      showModalEditView: edit,
      ...this.getFullEntitySelectValue(v),
    });
  }

  renderUpdateModal() {
    const { clComRulerEntity, entityEmbebedPai } = this.props;
    return clComRulerEntity && clComRulerEntity.id ? (
      <Modal size={'xl'} isOpen={this.state.showModalEdit === clComRulerEntity.id} toggle={() => this.setState({ showModalEdit: null })}>
        <ModalHeader toggle={() => this.setState({ showModalEdit: null })}>{this.renderHeaderUpdate(clComRulerEntity, false)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefEdit = el)}
          model={{
            ...clComRulerEntity,
          }}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, false)}
        >
          <ModalBody>{this.renderBodyUpdate(clComRulerEntity, false)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalEdit: null })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.clComRuler.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefEdit && this.myFormRefEdit.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.clComRuler.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    ) : (
      <> </>
    );
  }

  renderHeaderUpdate(clComRulerEntity, isNew) {
    const { entityEmbebedPai, clComTables, levelGroups, categories, productServices, specialties, loading } = this.props;

    const { observation } = clComRulerEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {isNew ? (
              <Translate contentKey="generadorApp.clComRuler.home.createLabel">Create a ClComRuler</Translate>
            ) : (
              <Translate contentKey="generadorApp.clComRuler.home.editLabel">Edit a ClComRuler</Translate>
            )}
          </span>
        </div>
      </>
    );
  }
  renderBodyUpdate(clComRulerEntity, isNew) {
    const { entityEmbebedPai, clComTables, levelGroups, categories, productServices, specialties, loading } = this.props;

    const { observation } = clComRulerEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
              <Col md="3">
              <Label className="mt-2 label-single-line"for="cl-com-ruler-id">
                <Translate contentKey="generadorApp.clComRuler.field_id">ID</Translate>
              </Label>
              </Col> */}
                      <Col md="12">
                        <AvInput id="cl-com-ruler-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'LevelGroupCategory' ? (
                    <Col md="3">
                      <LevelGroupCategory
                        entityBase={clComRulerEntity}
                        baseState={this.state}
                        baseProps={this.props}
                        setState={_state => this.setState(_state)}
                      />
                    </Col>
                  ) : (
                    <> </>
                  )}
                  {this.state.levelGroupSelectValue &&
                  ['ESPECIALIDADE', 'PRODUTOSESERVICOS'].includes(this.state.levelGroupSelectValue.sub) &&
                  baseFilters !== 'CategoryObj' ? (
                    <Col md="4">
                      <CategoryObj
                        entityBase={clComRulerEntity}
                        baseState={this.state}
                        baseProps={this.props}
                        setState={_state => this.setState(_state)}
                      />
                    </Col>
                  ) : (
                    <> </>
                  )}
                  {checkAnyValueFields(this.state, 'categorySelectValue.levelGroup.sub', 'ESPECIALIDADE', true) &&
                  baseFilters !== 'specialty' ? (
                    <Col md="4">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="cl-com-ruler-specialty">
                              <Translate contentKey="generadorApp.clComRuler.specialty">Specialty</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              isMulti
                              id="cl-com-ruler-specialty"
                              name={'specialty'}
                              className={'css-select-control'}
                              data-type-rel="many-to-many-other-side"
                              value={this.state.specialtySelectValue ? this.state.specialtySelectValue : ''}
                              onChange={options => this.setState({ specialtySelectValue: options })}
                              defaultOptions={this.state.specialtyStartSelectOptions ? this.state.specialtyStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.specialtyStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.specialtyStartSelectOptions === undefined) {
                                  const result = await getListAxios(
                                    'specialties',
                                    { 'category.id.in': this.state.categorySelectValue.id },
                                    0,
                                    100,
                                    'name,asc',
                                    'name'
                                  );
                                  this.setState({
                                    specialtyStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'specialties',
                                  { 'category.id.in': this.state.categorySelectValue.id, 'name.contains': inputValue },
                                  0,
                                  100,
                                  'name,asc',
                                  'name'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                          <RenderModalSuperSelect
                            baseState={this.state}
                            setState={_state => this.setState(_state)}
                            entity={'specialty'}
                            listEntity={'specialties'}
                            template={'category.levelGroup.name;category.name;name'.split(';')}
                            stateField={'specialtySelectValue'}
                            multiple={true}
                            showFields={'name'.split(';')}
                            order={'name,asc'}
                            filtersBase={{ 'category.id.in': this.state.categorySelectValue.id }}
                          />
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="specialty"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}
                  {checkAnyValueFields(this.state, 'categorySelectValue.levelGroup.sub', 'PRODUTOSESERVICOS', true) &&
                  baseFilters !== 'productService' ? (
                    <Col md="4">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="cl-com-ruler-productService">
                              <Translate contentKey="generadorApp.clComRuler.productService">Product Service</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              isMulti
                              id="cl-com-ruler-productService"
                              name={'productService'}
                              className={'css-select-control'}
                              data-type-rel="many-to-many-other-side"
                              value={this.state.productServiceSelectValue ? this.state.productServiceSelectValue : ''}
                              onChange={options => this.setState({ productServiceSelectValue: options })}
                              defaultOptions={
                                this.state.productServiceStartSelectOptions ? this.state.productServiceStartSelectOptions : []
                              }
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.productServiceStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.productServiceStartSelectOptions === undefined) {
                                  const result = await getListAxios(
                                    'product-services',
                                    { 'category.id.in': this.state.categorySelectValue.id },
                                    0,
                                    100,
                                    'name,asc',
                                    'name'
                                  );
                                  this.setState({
                                    productServiceStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'product-services',
                                  { 'category.id.in': this.state.categorySelectValue.id, 'name.contains': inputValue },
                                  0,
                                  100,
                                  'name,asc',
                                  'name'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                          <RenderModalSuperSelect
                            baseState={this.state}
                            setState={_state => this.setState(_state)}
                            entity={'productService'}
                            listEntity={'product-services'}
                            template={'category.levelGroup.name;category.name;name'.split(';')}
                            stateField={'productServiceSelectValue'}
                            multiple={true}
                            showFields={'name'.split(';')}
                            order={'name,asc'}
                            filtersBase={{ 'category.id.in': this.state.categorySelectValue.id }}
                          />
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="productService"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}
                  {this.state.levelGroupSelectValue &&
                  this.state.levelGroupSelectValue.sub === 'MATERIALMEDICAMENTOEDIETA' &&
                  baseFilters !== 'table' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="tableLabel" for="cl-com-ruler-table">
                              <Translate contentKey="generadorApp.clComRuler.table">Table</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="cl-com-ruler-table"
                              className={'css-select-control'}
                              value={
                                this.state.tableSelectValue
                                  ? {
                                      value: this.state.tableSelectValue,
                                      label: translate('generadorApp.EnumTable.' + this.state.tableSelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.clComRuler.table') }
                              }
                              options={[
                                { value: 'BRASINDICE', label: translate('generadorApp.EnumTable.BRASINDICE') },
                                { value: 'SIMPRO', label: translate('generadorApp.EnumTable.SIMPRO') },
                              ]}
                              onChange={(options: any) => this.setState({ tableSelectValue: options['value'] })}
                              name="table"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ tableSelectValue: evt.target.value })}
                      type="hidden"
                      name="table"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {this.state.levelGroupSelectValue &&
                  this.state.levelGroupSelectValue.sub === 'MATERIALMEDICAMENTOEDIETA' &&
                  this.props.entityEmbebedPai &&
                  this.props.entityEmbebedPai.type === 'TABELAABERTA' &&
                  baseFilters !== 'comercialTableType' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="comercialTableTypeLabel" for="cl-com-ruler-comercialTableType">
                              <Translate contentKey="generadorApp.clComRuler.comercialTableType">Type</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="cl-com-ruler-comercialTableType"
                              className={'css-select-control'}
                              value={
                                this.state.comercialTableTypeSelectValue
                                  ? {
                                      value: this.state.comercialTableTypeSelectValue,
                                      label: translate('generadorApp.ComercialTableType.' + this.state.comercialTableTypeSelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.clComRuler.comercialTableType') }
                              }
                              options={[
                                { value: 'PF', label: translate('generadorApp.ComercialTableType.PF') },
                                { value: 'PMC', label: translate('generadorApp.ComercialTableType.PMC') },
                              ]}
                              onChange={(options: any) => this.setState({ comercialTableTypeSelectValue: options['value'] })}
                              name="comercialTableType"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ comercialTableTypeSelectValue: evt.target.value })}
                      type="hidden"
                      name="comercialTableType"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {this.state.levelGroupSelectValue &&
                  this.state.levelGroupSelectValue.sub === 'MATERIALMEDICAMENTOEDIETA' &&
                  this.state.comercialTableTypeSelectValue === 'PF' &&
                  this.props.entityEmbebedPai &&
                  this.props.entityEmbebedPai.type === 'TABELAABERTA' &&
                  baseFilters !== 'inflator' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="inflatorLabel" for="cl-com-ruler-inflator">
                              <Translate contentKey="generadorApp.clComRuler.inflator">Inflator</Translate>
                            </Label>
                          </Col>

                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ inflatorSelectValue: floatvalue })}
                              decimalSeparator=","
                              thousandSeparator=""
                              precision={2}
                              suffix=" %"
                              allowNegative={true}
                              value={this.state.inflatorSelectValue ? this.state.inflatorSelectValue : 0}
                              id="cl-com-ruler-inflator"
                              name="inflator"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ inflatorSelectValue: evt.target.value })}
                      type="hidden"
                      name="inflator"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {this.state.levelGroupSelectValue &&
                  this.state.levelGroupSelectValue.sub === 'MATERIALMEDICAMENTOEDIETA' &&
                  this.state.comercialTableTypeSelectValue === 'PMC' &&
                  this.props.entityEmbebedPai &&
                  this.props.entityEmbebedPai.type === 'TABELAABERTA' &&
                  baseFilters !== 'deflator' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="deflatorLabel" for="cl-com-ruler-deflator">
                              <Translate contentKey="generadorApp.clComRuler.deflator">Deflator</Translate>
                            </Label>
                          </Col>

                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ deflatorSelectValue: floatvalue })}
                              decimalSeparator=","
                              thousandSeparator=""
                              precision={2}
                              suffix=" %"
                              allowNegative={true}
                              value={this.state.deflatorSelectValue ? this.state.deflatorSelectValue : 0}
                              id="cl-com-ruler-deflator"
                              name="deflator"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ deflatorSelectValue: evt.target.value })}
                      type="hidden"
                      name="deflator"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {this.state.categorySelectValue &&
                  this.state.categorySelectValue.levelGroup &&
                  ['ESPECIALIDADE', 'PRODUTOSESERVICOS'].includes(this.state.categorySelectValue.levelGroup.sub) &&
                  this.props.entityEmbebedPai &&
                  this.props.entityEmbebedPai.type === 'TABELAABERTA' &&
                  baseFilters !== 'option' ? (
                    <Col md="4">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="optionLabel" for="cl-com-ruler-option">
                              <Translate contentKey="generadorApp.clComRuler.option">Option</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="cl-com-ruler-option"
                              className={'css-select-control'}
                              value={
                                this.state.optionSelectValue
                                  ? {
                                      value: this.state.optionSelectValue,
                                      label: translate('generadorApp.ServiceType.' + this.state.optionSelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.clComRuler.option') }
                              }
                              options={[
                                { value: 'VENDA', label: translate('generadorApp.ServiceType.VENDA') },
                                { value: 'LOCACAO', label: translate('generadorApp.ServiceType.LOCACAO') },
                              ]}
                              onChange={(options: any) => this.setState({ optionSelectValue: options['value'] })}
                              name="option"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ optionSelectValue: evt.target.value })}
                      type="hidden"
                      name="option"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {this.state.optionSelectValue === 'VENDA' &&
                  this.state.categorySelectValue &&
                  this.state.categorySelectValue.levelGroup &&
                  ['ESPECIALIDADE', 'PRODUTOSESERVICOS'].includes(this.state.categorySelectValue.levelGroup.sub) &&
                  this.props.entityEmbebedPai &&
                  this.props.entityEmbebedPai.type === 'TABELAABERTA' &&
                  baseFilters !== 'saleValue' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="saleValueLabel" for="cl-com-ruler-saleValue">
                              <Translate contentKey="generadorApp.clComRuler.saleValue">Sale Value</Translate>
                            </Label>
                          </Col>

                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ saleValueSelectValue: floatvalue })}
                              decimalSeparator=","
                              precision={2}
                              thousandSeparator=""
                              prefix="R$ "
                              allowNegative={true}
                              value={this.state.saleValueSelectValue ? this.state.saleValueSelectValue : 0}
                              id="cl-com-ruler-saleValue"
                              name="saleValue"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ saleValueSelectValue: evt.target.value })}
                      type="hidden"
                      name="saleValue"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {this.state.optionSelectValue === 'LOCACAO' &&
                  this.state.categorySelectValue &&
                  this.state.categorySelectValue.levelGroup &&
                  ['ESPECIALIDADE', 'PRODUTOSESERVICOS'].includes(this.state.categorySelectValue.levelGroup.sub) &&
                  this.props.entityEmbebedPai &&
                  this.props.entityEmbebedPai.type === 'TABELAABERTA' &&
                  baseFilters !== 'rentalValue' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="rentalValueLabel" for="cl-com-ruler-rentalValue">
                              <Translate contentKey="generadorApp.clComRuler.rentalValue">Rental Value</Translate>
                            </Label>
                          </Col>

                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ rentalValueSelectValue: floatvalue })}
                              decimalSeparator=","
                              precision={2}
                              thousandSeparator=""
                              prefix="R$ "
                              allowNegative={true}
                              value={this.state.rentalValueSelectValue ? this.state.rentalValueSelectValue : 0}
                              id="cl-com-ruler-rentalValue"
                              name="rentalValue"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ rentalValueSelectValue: evt.target.value })}
                      type="hidden"
                      name="rentalValue"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {this.state.categorySelectValue &&
                  this.state.categorySelectValue.levelGroup &&
                  ['ESPECIALIDADE', 'PRODUTOSESERVICOS'].includes(this.state.categorySelectValue.levelGroup.sub) &&
                  this.props.entityEmbebedPai &&
                  this.props.entityEmbebedPai.type === 'PACOTE' &&
                  baseFilters !== 'frequency' ? (
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="frequencyLabel" for="cl-com-ruler-frequency">
                              <Translate contentKey="generadorApp.clComRuler.frequency">Frequency</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="cl-com-ruler-frequency"
                              className={'css-select-control'}
                              value={
                                this.state.frequencySelectValue
                                  ? {
                                      value: this.state.frequencySelectValue,
                                      label: translate('generadorApp.QuoteFrequency.' + this.state.frequencySelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.clComRuler.frequency') }
                              }
                              options={[
                                { value: 'UMXSEMANA', label: translate('generadorApp.QuoteFrequency.UMXSEMANA') },
                                { value: 'DOISXSEMANA', label: translate('generadorApp.QuoteFrequency.DOISXSEMANA') },
                                { value: 'TRESXSEMANA', label: translate('generadorApp.QuoteFrequency.TRESXSEMANA') },
                                { value: 'QUATROXSEMANA', label: translate('generadorApp.QuoteFrequency.QUATROXSEMANA') },
                                { value: 'CINCOXSEMANA', label: translate('generadorApp.QuoteFrequency.CINCOXSEMANA') },
                                { value: 'SEISXSEMANA', label: translate('generadorApp.QuoteFrequency.SEISXSEMANA') },
                                { value: 'UMXDIA', label: translate('generadorApp.QuoteFrequency.UMXDIA') },
                                { value: 'DOISXDIA', label: translate('generadorApp.QuoteFrequency.DOISXDIA') },
                                { value: 'TRESXDIA', label: translate('generadorApp.QuoteFrequency.TRESXDIA') },
                                { value: 'QUATROXDIA', label: translate('generadorApp.QuoteFrequency.QUATROXDIA') },
                                { value: 'CINCOXDIA', label: translate('generadorApp.QuoteFrequency.CINCOXDIA') },
                                { value: 'SEISXDIA', label: translate('generadorApp.QuoteFrequency.SEISXDIA') },
                                { value: 'UMXMES', label: translate('generadorApp.QuoteFrequency.UMXMES') },
                                { value: 'DOISXMES', label: translate('generadorApp.QuoteFrequency.DOISXMES') },
                                { value: 'TRESXMES', label: translate('generadorApp.QuoteFrequency.TRESXMES') },
                                { value: 'QUATROXMES', label: translate('generadorApp.QuoteFrequency.QUATROXMES') },
                                { value: 'CADA48HORAS', label: translate('generadorApp.QuoteFrequency.CADA48HORAS') },
                                { value: 'CADA72HORAS', label: translate('generadorApp.QuoteFrequency.CADA72HORAS') },
                                { value: 'PONTUAL', label: translate('generadorApp.QuoteFrequency.PONTUAL') },
                              ]}
                              onChange={(options: any) => this.setState({ frequencySelectValue: options['value'] })}
                              name="frequency"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ frequencySelectValue: evt.target.value })}
                      type="hidden"
                      name="frequency"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {this.state.levelGroupSelectValue &&
                  ['MATERIALMEDICAMENTOEDIETA'].includes(this.state.levelGroupSelectValue.sub) &&
                  this.props.entityEmbebedPai &&
                  this.props.entityEmbebedPai.type === 'PACOTE' &&
                  baseFilters !== 'minValuePackage' ? (
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="minValuePackageLabel" for="cl-com-ruler-minValuePackage">
                              <Translate contentKey="generadorApp.clComRuler.minValuePackage">Package Min Value</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ minValuePackageSelectValue: floatvalue })}
                              decimalSeparator=","
                              thousandSeparator=""
                              precision={2}
                              prefix=""
                              allowNegative={true}
                              value={this.state.minValuePackageSelectValue ? this.state.minValuePackageSelectValue : 0}
                              id="cl-com-ruler-minValuePackage"
                              name="minValuePackage"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ minValuePackageSelectValue: evt.target.value })}
                      type="hidden"
                      name="minValuePackage"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'BreackLine' ? (
                    <Col md="12">
                      <BreackLine
                        entityBase={clComRulerEntity}
                        baseState={this.state}
                        baseProps={this.props}
                        setState={_state => this.setState(_state)}
                      />
                    </Col>
                  ) : (
                    <> </>
                  )}
                  {this.state.categorySelectValue &&
                  this.state.categorySelectValue.levelGroup &&
                  ['ESPECIALIDADE', 'PRODUTOSESERVICOS'].includes(this.state.categorySelectValue.levelGroup.sub) &&
                  this.props.entityEmbebedPai &&
                  this.props.entityEmbebedPai.type === 'TABELAABERTA' &&
                  baseFilters !== 'tussCode' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="tussCodeLabel" for="cl-com-ruler-tussCode">
                              <Translate contentKey="generadorApp.clComRuler.tussCode">Tuss Code</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ tussCodeSelectValue: evt.target.value })}
                              value={this.state.tussCodeSelectValue ? this.state.tussCodeSelectValue : ''}
                              id="cl-com-ruler-tussCode"
                              type="text"
                              name="tussCode"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ tussCodeSelectValue: evt.target.value })}
                      type="hidden"
                      name="tussCode"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {this.state.categorySelectValue &&
                  this.state.categorySelectValue.levelGroup &&
                  ['ESPECIALIDADE', 'PRODUTOSESERVICOS'].includes(this.state.categorySelectValue.levelGroup.sub) &&
                  this.props.entityEmbebedPai &&
                  this.props.entityEmbebedPai.type === 'TABELAABERTA' &&
                  baseFilters !== 'tissCode' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="tissCodeLabel" for="cl-com-ruler-tissCode">
                              <Translate contentKey="generadorApp.clComRuler.tissCode">Tiss Code</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ tissCodeSelectValue: evt.target.value })}
                              value={this.state.tissCodeSelectValue ? this.state.tissCodeSelectValue : ''}
                              id="cl-com-ruler-tissCode"
                              type="text"
                              name="tissCode"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ tissCodeSelectValue: evt.target.value })}
                      type="hidden"
                      name="tissCode"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {this.state.categorySelectValue &&
                  this.state.categorySelectValue.levelGroup &&
                  ['ESPECIALIDADE', 'PRODUTOSESERVICOS'].includes(this.state.categorySelectValue.levelGroup.sub) &&
                  this.props.entityEmbebedPai &&
                  this.props.entityEmbebedPai.type === 'TABELAABERTA' &&
                  baseFilters !== 'tableCode' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="tableCodeLabel" for="cl-com-ruler-tableCode">
                              <Translate contentKey="generadorApp.clComRuler.tableCode">Table Code</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ tableCodeSelectValue: evt.target.value })}
                              value={this.state.tableCodeSelectValue ? this.state.tableCodeSelectValue : ''}
                              id="cl-com-ruler-tableCode"
                              type="text"
                              name="tableCode"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ tableCodeSelectValue: evt.target.value })}
                      type="hidden"
                      name="tableCode"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {this.state.categorySelectValue &&
                  this.state.categorySelectValue.levelGroup &&
                  ['ESPECIALIDADE', 'PRODUTOSESERVICOS'].includes(this.state.categorySelectValue.levelGroup.sub) &&
                  this.props.entityEmbebedPai &&
                  this.props.entityEmbebedPai.type === 'TABELAABERTA' &&
                  baseFilters !== 'expenseCode' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="expenseCodeLabel" for="cl-com-ruler-expenseCode">
                              <Translate contentKey="generadorApp.clComRuler.expenseCode">Expense Code</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ expenseCodeSelectValue: evt.target.value })}
                              value={this.state.expenseCodeSelectValue ? this.state.expenseCodeSelectValue : ''}
                              id="cl-com-ruler-expenseCode"
                              type="text"
                              name="expenseCode"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ expenseCodeSelectValue: evt.target.value })}
                      type="hidden"
                      name="expenseCode"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'observation' ? (
                    <Col md="12">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="observationLabel" for="cl-com-ruler-observation">
                              <Translate contentKey="generadorApp.clComRuler.observation">Observation</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <AvInput
                              onChange={evt => this.setState({ observationSelectValue: evt.target.value })}
                              validate={{}}
                              id="cl-com-ruler-observation"
                              type="textarea"
                              name="observation"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ observationSelectValue: evt.target.value })}
                      type="hidden"
                      name="observation"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'clComTable' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="cl-com-ruler-clComTable">
                              <Translate contentKey="generadorApp.clComRuler.clComTable">Cl Com Table</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="cl-com-ruler-clComTable"
                              name={'clComTable'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.clComTableSelectValue ? this.state.clComTableSelectValue : ''}
                              onChange={options => this.setState({ clComTableSelectValue: options })}
                              defaultOptions={this.state.clComTableStartSelectOptions ? this.state.clComTableStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.clComTableStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.clComTableStartSelectOptions === undefined) {
                                  const result = await getListAxios('cl-com-tables', {}, 0, 100, 'name,asc', 'name');
                                  this.setState({
                                    clComTableStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'cl-com-tables',
                                  { 'name.contains': inputValue },
                                  0,
                                  100,
                                  'name,asc',
                                  'name'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="clComTable"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}{' '}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  renderHeader() {
    const { clComTables, levelGroups, categories, productServices, specialties, clComRulerList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.clComRuler.home.title">Rulers</Translate>
          </span>
          <Button id="togglerFilterClComRuler" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.clComRuler.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          &nbsp;
          <Button
            onClick={() =>
              this.setState({
                showModalForm: true,
                optionSelectValue: null,
                comercialTableTypeSelectValue: null,
                saleValueSelectValue: null,
                observationSelectValue: null,
                tableSelectValue: null,
                percentSelectValue: null,
                inflatorSelectValue: null,
                deflatorSelectValue: null,
                rentalValueSelectValue: null,
                tussCodeSelectValue: null,
                tissCodeSelectValue: null,
                tableCodeSelectValue: null,
                expenseCodeSelectValue: null,
                frequencySelectValue: null,
                minValuePackageSelectValue: null,

                levelGroupStartSelectOptions: undefined,
                levelGroupSelectValue: null,

                categoryStartSelectOptions: undefined,
                categorySelectValue: null,

                productServiceStartSelectOptions: undefined,
                productServiceSelectValue: null,

                specialtyStartSelectOptions: undefined,
                specialtySelectValue: null,
                isNew: true,
              })
            }
            color="primary"
            className="btn btn-primary float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="generadorApp.clComRuler.btnNewItem">Create new</Translate>
          </Button>{' '}
          &nbsp;
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/clComTable'}>
              <Translate contentKey="generadorApp.clComTable.home.title">ClComTables</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/clComTable/' + this.props.match.params['idClComTable']}>
              {this.state.clComTableSelectValue ? this.state.clComTableSelectValue.label : this.props.match.params['idClComTable']}
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.clComRuler.home.title">Rulers</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    const { clComTables, levelGroups, categories, productServices, specialties, clComRulerList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <UncontrolledCollapse toggler="#togglerFilterClComRuler">
          <CardBody>
            <AvForm ref={el => (this.myFormRef = el)} id="form-filter" onSubmit={this.filterEntity}>
              <div className="row mt-1 ml-3 mr-3">
                {this.state.baseFilters !== 'option' ? (
                  <Col md="3" className="col-filter-cl-com-ruler-option">
                    <Row className="mr-1 mt-1">
                      <Label id="optionLabel" for="cl-com-ruler-option">
                        <Translate contentKey="generadorApp.clComRuler.option">Option</Translate>
                      </Label>
                      <Select
                        id="cl-com-ruler-option"
                        className={'css-select-control'}
                        value={
                          this.state.option
                            ? { value: this.state.option, label: translate('generadorApp.ServiceType.' + this.state.option) }
                            : { value: '', label: translate('generadorApp.clComRuler.option') }
                        }
                        options={[
                          { value: '', label: translate('generadorApp.clComRuler.option') },
                          { value: 'VENDA', label: translate('generadorApp.ServiceType.VENDA') },
                          { value: 'LOCACAO', label: translate('generadorApp.ServiceType.LOCACAO') },
                        ]}
                        onChange={(options: any) => this.setState({ option: options['value'] })}
                        name="option"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'comercialTableType' ? (
                  <Col md="3" className="col-filter-cl-com-ruler-comercialTableType">
                    <Row className="mr-1 mt-1">
                      <Label id="comercialTableTypeLabel" for="cl-com-ruler-comercialTableType">
                        <Translate contentKey="generadorApp.clComRuler.comercialTableType">Type</Translate>
                      </Label>
                      <Select
                        id="cl-com-ruler-comercialTableType"
                        className={'css-select-control'}
                        value={
                          this.state.comercialTableType
                            ? {
                                value: this.state.comercialTableType,
                                label: translate('generadorApp.ComercialTableType.' + this.state.comercialTableType),
                              }
                            : { value: '', label: translate('generadorApp.clComRuler.comercialTableType') }
                        }
                        options={[
                          { value: '', label: translate('generadorApp.clComRuler.comercialTableType') },
                          { value: 'PF', label: translate('generadorApp.ComercialTableType.PF') },
                          { value: 'PMC', label: translate('generadorApp.ComercialTableType.PMC') },
                        ]}
                        onChange={(options: any) => this.setState({ comercialTableType: options['value'] })}
                        name="comercialTableType"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'saleValue' ? (
                  <Col md="3" className="col-filter-cl-com-ruler-saleValue">
                    <Row className="mr-1 mt-1">
                      <Label id="saleValueLabel" for="cl-com-ruler-saleValue">
                        <Translate contentKey="generadorApp.clComRuler.saleValue">Sale Value</Translate>
                      </Label>

                      <AvInput type="text" name="saleValue" id="cl-com-ruler-saleValue" value={this.state.saleValue} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'observation' ? (
                  <Col md="3" className="col-filter-cl-com-ruler-observation">
                    <Row className="mr-1 mt-1">
                      <Label id="observationLabel" for="cl-com-ruler-observation">
                        <Translate contentKey="generadorApp.clComRuler.observation">Observation</Translate>
                      </Label>
                      <AvInput id="cl-com-ruler-observation" type="textarea" name="observation" />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'table' ? (
                  <Col md="3" className="col-filter-cl-com-ruler-table">
                    <Row className="mr-1 mt-1">
                      <Label id="tableLabel" for="cl-com-ruler-table">
                        <Translate contentKey="generadorApp.clComRuler.table">Table</Translate>
                      </Label>
                      <Select
                        id="cl-com-ruler-table"
                        className={'css-select-control'}
                        value={
                          this.state.table
                            ? { value: this.state.table, label: translate('generadorApp.EnumTable.' + this.state.table) }
                            : { value: '', label: translate('generadorApp.clComRuler.table') }
                        }
                        options={[
                          { value: '', label: translate('generadorApp.clComRuler.table') },
                          { value: 'BRASINDICE', label: translate('generadorApp.EnumTable.BRASINDICE') },
                          { value: 'SIMPRO', label: translate('generadorApp.EnumTable.SIMPRO') },
                        ]}
                        onChange={(options: any) => this.setState({ table: options['value'] })}
                        name="table"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'percent' ? (
                  <Col md="3" className="col-filter-cl-com-ruler-percent">
                    <Row className="mr-1 mt-1">
                      <Label id="percentLabel" for="cl-com-ruler-percent">
                        <Translate contentKey="generadorApp.clComRuler.percent">Percent</Translate>
                      </Label>
                      <AvInput type="number" name="percent" id="cl-com-ruler-percent" value={this.state.percent} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'inflator' ? (
                  <Col md="3" className="col-filter-cl-com-ruler-inflator">
                    <Row className="mr-1 mt-1">
                      <Label id="inflatorLabel" for="cl-com-ruler-inflator">
                        <Translate contentKey="generadorApp.clComRuler.inflator">Inflator</Translate>
                      </Label>

                      <AvInput type="text" name="inflator" id="cl-com-ruler-inflator" value={this.state.inflator} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'deflator' ? (
                  <Col md="3" className="col-filter-cl-com-ruler-deflator">
                    <Row className="mr-1 mt-1">
                      <Label id="deflatorLabel" for="cl-com-ruler-deflator">
                        <Translate contentKey="generadorApp.clComRuler.deflator">Deflator</Translate>
                      </Label>

                      <AvInput type="text" name="deflator" id="cl-com-ruler-deflator" value={this.state.deflator} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'rentalValue' ? (
                  <Col md="3" className="col-filter-cl-com-ruler-rentalValue">
                    <Row className="mr-1 mt-1">
                      <Label id="rentalValueLabel" for="cl-com-ruler-rentalValue">
                        <Translate contentKey="generadorApp.clComRuler.rentalValue">Rental Value</Translate>
                      </Label>

                      <AvInput type="text" name="rentalValue" id="cl-com-ruler-rentalValue" value={this.state.rentalValue} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'tussCode' ? (
                  <Col md="3" className="col-filter-cl-com-ruler-tussCode">
                    <Row className="mr-1 mt-1">
                      <Label id="tussCodeLabel" for="cl-com-ruler-tussCode">
                        <Translate contentKey="generadorApp.clComRuler.tussCode">Tuss Code</Translate>
                      </Label>

                      <AvInput type="text" name="tussCode" id="cl-com-ruler-tussCode" value={this.state.tussCode} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'tissCode' ? (
                  <Col md="3" className="col-filter-cl-com-ruler-tissCode">
                    <Row className="mr-1 mt-1">
                      <Label id="tissCodeLabel" for="cl-com-ruler-tissCode">
                        <Translate contentKey="generadorApp.clComRuler.tissCode">Tiss Code</Translate>
                      </Label>

                      <AvInput type="text" name="tissCode" id="cl-com-ruler-tissCode" value={this.state.tissCode} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'tableCode' ? (
                  <Col md="3" className="col-filter-cl-com-ruler-tableCode">
                    <Row className="mr-1 mt-1">
                      <Label id="tableCodeLabel" for="cl-com-ruler-tableCode">
                        <Translate contentKey="generadorApp.clComRuler.tableCode">Table Code</Translate>
                      </Label>

                      <AvInput type="text" name="tableCode" id="cl-com-ruler-tableCode" value={this.state.tableCode} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'expenseCode' ? (
                  <Col md="3" className="col-filter-cl-com-ruler-expenseCode">
                    <Row className="mr-1 mt-1">
                      <Label id="expenseCodeLabel" for="cl-com-ruler-expenseCode">
                        <Translate contentKey="generadorApp.clComRuler.expenseCode">Expense Code</Translate>
                      </Label>

                      <AvInput type="text" name="expenseCode" id="cl-com-ruler-expenseCode" value={this.state.expenseCode} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'frequency' ? (
                  <Col md="3" className="col-filter-cl-com-ruler-frequency">
                    <Row className="mr-1 mt-1">
                      <Label id="frequencyLabel" for="cl-com-ruler-frequency">
                        <Translate contentKey="generadorApp.clComRuler.frequency">Frequency</Translate>
                      </Label>
                      <Select
                        id="cl-com-ruler-frequency"
                        className={'css-select-control'}
                        value={
                          this.state.frequency
                            ? { value: this.state.frequency, label: translate('generadorApp.QuoteFrequency.' + this.state.frequency) }
                            : { value: '', label: translate('generadorApp.clComRuler.frequency') }
                        }
                        options={[
                          { value: '', label: translate('generadorApp.clComRuler.frequency') },
                          { value: 'UMXSEMANA', label: translate('generadorApp.QuoteFrequency.UMXSEMANA') },
                          { value: 'DOISXSEMANA', label: translate('generadorApp.QuoteFrequency.DOISXSEMANA') },
                          { value: 'TRESXSEMANA', label: translate('generadorApp.QuoteFrequency.TRESXSEMANA') },
                          { value: 'QUATROXSEMANA', label: translate('generadorApp.QuoteFrequency.QUATROXSEMANA') },
                          { value: 'CINCOXSEMANA', label: translate('generadorApp.QuoteFrequency.CINCOXSEMANA') },
                          { value: 'SEISXSEMANA', label: translate('generadorApp.QuoteFrequency.SEISXSEMANA') },
                          { value: 'UMXDIA', label: translate('generadorApp.QuoteFrequency.UMXDIA') },
                          { value: 'DOISXDIA', label: translate('generadorApp.QuoteFrequency.DOISXDIA') },
                          { value: 'TRESXDIA', label: translate('generadorApp.QuoteFrequency.TRESXDIA') },
                          { value: 'QUATROXDIA', label: translate('generadorApp.QuoteFrequency.QUATROXDIA') },
                          { value: 'CINCOXDIA', label: translate('generadorApp.QuoteFrequency.CINCOXDIA') },
                          { value: 'SEISXDIA', label: translate('generadorApp.QuoteFrequency.SEISXDIA') },
                          { value: 'UMXMES', label: translate('generadorApp.QuoteFrequency.UMXMES') },
                          { value: 'DOISXMES', label: translate('generadorApp.QuoteFrequency.DOISXMES') },
                          { value: 'TRESXMES', label: translate('generadorApp.QuoteFrequency.TRESXMES') },
                          { value: 'QUATROXMES', label: translate('generadorApp.QuoteFrequency.QUATROXMES') },
                          { value: 'CADA48HORAS', label: translate('generadorApp.QuoteFrequency.CADA48HORAS') },
                          { value: 'CADA72HORAS', label: translate('generadorApp.QuoteFrequency.CADA72HORAS') },
                          { value: 'PONTUAL', label: translate('generadorApp.QuoteFrequency.PONTUAL') },
                        ]}
                        onChange={(options: any) => this.setState({ frequency: options['value'] })}
                        name="frequency"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'minValuePackage' ? (
                  <Col md="3" className="col-filter-cl-com-ruler-minValuePackage">
                    <Row className="mr-1 mt-1">
                      <Label id="minValuePackageLabel" for="cl-com-ruler-minValuePackage">
                        <Translate contentKey="generadorApp.clComRuler.minValuePackage">Package Min Value</Translate>
                      </Label>

                      <AvInput type="text" name="minValuePackage" id="cl-com-ruler-minValuePackage" value={this.state.minValuePackage} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'clComTable' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="cl-com-ruler-clComTable">
                          <Translate contentKey="generadorApp.clComRuler.clComTable">Cl Com Table</Translate>
                        </Label>
                        <SelectAsync
                          id="cl-com-ruler-clComTable"
                          isMulti
                          className={'css-select-control'}
                          name={'clComTable'}
                          cacheOptions
                          value={this.state.clComTableId}
                          onChange={options => this.setState({ clComTableId: options })}
                          defaultOptions={this.state.clComTableStartFilter ? this.state.clComTableStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.clComTableStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.clComTableStartFilter === undefined) {
                              const result = await getListAxios('cl-com-tables', {}, 0, 100, 'name,asc', 'name');
                              this.setState({
                                clComTableStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios('cl-com-tables', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'levelGroup' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="cl-com-ruler-levelGroup">
                          <Translate contentKey="generadorApp.clComRuler.levelGroup">Level Group</Translate>
                        </Label>
                        <SelectAsync
                          id="cl-com-ruler-levelGroup"
                          isMulti
                          className={'css-select-control'}
                          name={'levelGroup'}
                          cacheOptions
                          value={this.state.levelGroupId}
                          onChange={options => this.setState({ levelGroupId: options })}
                          defaultOptions={this.state.levelGroupStartFilter ? this.state.levelGroupStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.levelGroupStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.levelGroupStartFilter === undefined) {
                              const result = await getListAxios('level-groups', {}, 0, 100, 'name,asc', 'name');
                              this.setState({
                                levelGroupStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios('level-groups', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'category' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="cl-com-ruler-category">
                          <Translate contentKey="generadorApp.clComRuler.category">Category</Translate>
                        </Label>
                        <SelectAsync
                          id="cl-com-ruler-category"
                          isMulti
                          className={'css-select-control'}
                          name={'category'}
                          cacheOptions
                          value={this.state.categoryId}
                          onChange={options => this.setState({ categoryId: options })}
                          defaultOptions={this.state.categoryStartFilter ? this.state.categoryStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.categoryStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.categoryStartFilter === undefined) {
                              const result = await getListAxios('categories', {}, 0, 100, 'name,asc', 'name');
                              this.setState({
                                categoryStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios('categories', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'productService' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="cl-com-ruler-productService">
                          <Translate contentKey="generadorApp.clComRuler.productService">Product Service</Translate>
                        </Label>
                        <SelectAsync
                          id="cl-com-ruler-productService"
                          isMulti
                          className={'css-select-control'}
                          name={'productService'}
                          cacheOptions
                          value={this.state.productServiceId}
                          onChange={options => this.setState({ productServiceId: options })}
                          defaultOptions={this.state.productServiceStartFilter ? this.state.productServiceStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.productServiceStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.productServiceStartFilter === undefined) {
                              const result = await getListAxios('product-services', {}, 0, 100, 'name,asc', 'name');
                              this.setState({
                                productServiceStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios(
                              'product-services',
                              { 'name.contains': inputValue },
                              0,
                              100,
                              'name,asc',
                              'name'
                            );
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'specialty' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="cl-com-ruler-specialty">
                          <Translate contentKey="generadorApp.clComRuler.specialty">Specialty</Translate>
                        </Label>
                        <SelectAsync
                          id="cl-com-ruler-specialty"
                          isMulti
                          className={'css-select-control'}
                          name={'specialty'}
                          cacheOptions
                          value={this.state.specialtyId}
                          onChange={options => this.setState({ specialtyId: options })}
                          defaultOptions={this.state.specialtyStartFilter ? this.state.specialtyStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.specialtyStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.specialtyStartFilter === undefined) {
                              const result = await getListAxios('specialties', {}, 0, 100, 'name,asc', 'name');
                              this.setState({
                                specialtyStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios('specialties', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}
              </div>

              <div className="row mb-2 mr-4 justify-content-end">
                <Button color="primary" type="submit">
                  <i className="fa fa-filter" aria-hidden={'true'}></i>&nbsp;
                  <Translate contentKey="generadorApp.clComRuler.btnFilter">Filter</Translate>
                </Button>
                &nbsp;
                <Button color="secondary" onClick={this.cancelFilters}>
                  <FontAwesomeIcon icon="trash-alt" />
                  &nbsp;
                  <Translate contentKey="generadorApp.clComRuler.btnCleanFilter">Clean Filter</Translate>
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </UncontrolledCollapse>
      </>
    );
  }

  renderTable() {
    const { clComTables, levelGroups, categories, productServices, specialties, clComRulerList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {clComRulerList && clComRulerList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null).length > 0 ? (
          <>
            <Table
              id="cl-com-ruler-table-list"
              responsive
              aria-describedby="cl-com-ruler-heading"
              className={'table-hover table-striped table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.clComRuler.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'LevelGroupTable' ? (
                    <th>
                      <Translate contentKey="generadorApp.clComRuler.LevelGroupTable">LevelGroupTable</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'category' ? (
                    <th>
                      <Translate contentKey="generadorApp.clComRuler.category">Category</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'SpecialtyProductServiceTableTable' ? (
                    <th>
                      <Translate contentKey="generadorApp.clComRuler.SpecialtyProductServiceTableTable">
                        SpecialtyProductServiceTableTable
                      </Translate>
                    </th>
                  ) : null}
                  {this.props.entityEmbebedPai &&
                  this.props.entityEmbebedPai.type !== 'TABELAABERTA' &&
                  state.baseFilters !== 'frequency' ? (
                    <th className="hand" onClick={sortFunction('frequency')}>
                      <Translate contentKey="generadorApp.clComRuler.frequency">Frequency</Translate>
                      <FontAwesomeIcon icon={state.sort === 'frequency' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'option' ? (
                    <th className="hand" onClick={sortFunction('option')}>
                      <Translate contentKey="generadorApp.clComRuler.option">Option</Translate>
                      <FontAwesomeIcon icon={state.sort === 'option' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {this.props.entityEmbebedPai &&
                  this.props.entityEmbebedPai.type === 'TABELAABERTA' &&
                  state.baseFilters !== 'RentalSaleValueTable' ? (
                    <th>
                      <Translate contentKey="generadorApp.clComRuler.RentalSaleValueTable">RentalSaleValueTable</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'clComTable' ? (
                    <th>
                      <Translate contentKey="generadorApp.clComRuler.clComTable">Cl Com Table</Translate>
                    </th>
                  ) : null}

                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {clComRulerList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((clComRuler, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.clComRuler.field_id">ID</Translate>
                        </b>
                        {clComRuler.id}
                      </td>
                      {state.baseFilters !== 'LevelGroupTable' ? (
                        <td>
                          {' '}
                          <LevelGroupTable
                            baseState={this.state}
                            baseProps={this.props}
                            setState={st => this.setState(st)}
                            entityBase={clComRuler}
                          />
                        </td>
                      ) : (
                        <> </>
                      )}

                      {state.baseFilters !== 'category' ? (
                        <td id="category-cell">
                          <b className="visible-xs"> Category </b>
                          {clComRuler ? (
                            <span data-type-rel="2-many-to-one-other-side">
                              {getValueRecursive(clComRuler, 'category.name'.split('.'), '')}
                            </span>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : null}
                      {state.baseFilters !== 'SpecialtyProductServiceTableTable' ? (
                        <td>
                          {' '}
                          <SpecialtyProductServiceTableTable
                            baseState={this.state}
                            baseProps={this.props}
                            setState={st => this.setState(st)}
                            entityBase={clComRuler}
                          />
                        </td>
                      ) : (
                        <> </>
                      )}

                      {this.props.entityEmbebedPai &&
                      this.props.entityEmbebedPai.type !== 'TABELAABERTA' &&
                      state.baseFilters !== 'frequency' ? (
                        <td id="frequency-cell">
                          <b className="visible-xs"> Frequency </b>
                          {clComRuler.frequency ? <Translate contentKey={`generadorApp.QuoteFrequency.${clComRuler.frequency}`} /> : <> </>}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'option' ? (
                        <td id="option-cell">
                          <b className="visible-xs"> Option </b>
                          {clComRuler.option ? <Translate contentKey={`generadorApp.ServiceType.${clComRuler.option}`} /> : <> </>}
                        </td>
                      ) : null}
                      {this.props.entityEmbebedPai &&
                      this.props.entityEmbebedPai.type === 'TABELAABERTA' &&
                      state.baseFilters !== 'RentalSaleValueTable' ? (
                        <td>
                          {' '}
                          <RentalSaleValueTable
                            baseState={this.state}
                            baseProps={this.props}
                            setState={st => this.setState(st)}
                            entityBase={clComRuler}
                          />
                        </td>
                      ) : (
                        <> </>
                      )}

                      {state.baseFilters !== 'clComTable' ? (
                        <td id="clComTable-cell">
                          <b className="visible-xs"> Cl Com Table </b>
                          {clComRuler ? (
                            <span data-type-rel="2-many-to-one-other-side">
                              {getValueRecursive(clComRuler, 'clComTable.name'.split('.'), '')}
                            </span>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : null}

                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          {hasAnyAuthority(props.userAccount, ['client'], 'edit') ? (
                            <Button onClick={() => this.openUpdateModal(clComRuler)} color="primary" size="sm">
                              <FontAwesomeIcon icon="pencil-alt" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                          {hasAnyAuthority(props.userAccount, ['client'], 'edit') ? (
                            <Button onClick={() => this.deleteEntity(clComRuler)} color="danger" size="sm">
                              <FontAwesomeIcon icon="trash" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.clComRuler.home.notFound">No Rulers found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const { clComTables, levelGroups, categories, productServices, specialties, clComRulerList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={clComRulerList && clComRulerList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const { clComTables, levelGroups, categories, productServices, specialties, clComRulerList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel className="cl-com-ruler cl-com-ruler-all-in-one">
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
            {this.renderNewModal()}
            {this.renderUpdateModal()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToProps = ({ clComRuler, ...storeState }: IRootState) => ({
  clComTables: storeState.clComTable.entities,
  levelGroups: storeState.levelGroup.entities,
  categories: storeState.category.entities,
  productServices: storeState.productService.entities,
  specialties: storeState.specialty.entities,
  clComRulerList: clComRuler.entities,
  totalItems: clComRuler.totalItems,
  userAccount: storeState.authentication.account,
  loading: clComRuler.loading,

  // UPDATE
  clComRulerEntity: clComRuler.entity,
});

export const mapDispatchToProps = {
  getEntities,

  updateEntity,
  reset,

  // UPDATE
  getEntity,
  setBlob,
  createEntity,

  // DELETE
  deleteEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ClComRuler);
