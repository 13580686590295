import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Activity from './activity';

import ActivityDetail from './activity-detail';

import ActivityUpdate from './activity';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={ActivityUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={ActivityUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={ActivityDetail} />
      <ErrorBoundaryRoute path={match.path} component={Activity} />
    </Switch>
  </>
);

export default Routes;
