/* eslint complexity: ["error", 300] */
import React from 'react';
import { connect } from 'react-redux';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { AvForm } from 'availity-reactstrap-validation';
import { KitUpdate, mapStateToProps, mapDispatchToProps } from '../kit-update';
import { IRootState } from 'app/shared/reducers';

export interface IUpdateExtendedState {
  dumystate?: any;
}

export class KitExtendedUpdate extends KitUpdate {
  UNSAFE_componentWillMount() {
    if (this.state.isNew) {
      this.props.reset();
      this.kitDietReset();
      this.kitMaterialReset();
      this.kitMedicineReset();
      this.kitProductServiceReset();
      this.kitRhsReset();
    } else {
      this.props.getEntity(this.props.match.params['id']);
    }
  }

  renderHeader() {
    return <> {super.renderHeader()} </>;
  }

  renderBody() {
    return <> {super.renderBody()} </>;
  }

  render() {
    const { kitEntity, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...kitEntity,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel>
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
    listProcediment: storeState.patDrPrescrProcd.entities,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;
export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(KitExtendedUpdate);
