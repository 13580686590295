import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import MedicineMaterial from './medicine-material';

import MedicineMaterialUpdate from './extended/medicine-material-update';

import MedicineMaterialStatus from '../medicine-material-status/index';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute path={`${match.path}/:idMedicineMaterial/medicine-material-status`} component={MedicineMaterialStatus} />

      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={MedicineMaterialUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={MedicineMaterialUpdate} />
      <ErrorBoundaryRoute path={match.path} component={MedicineMaterial} />
    </Switch>

    <Switch>
      {/* <ErrorBoundaryRoute path={`${match.path}/:idMedicineMaterial/medicine-material-status`} component={MedicineMaterialStatus} /> */}
    </Switch>
  </>
);

export default Routes;
