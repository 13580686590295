/* eslint complexity: ["error", 500] */
import React from 'react';
import { connect } from 'react-redux';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { ClComTableUpdate, mapStateToProps, mapDispatchToProps } from '../cl-com-table-update';
import { IRootState } from 'app/shared/reducers';

import Select from 'react-select';
import SelectAsync from 'react-select/async';
import { getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import axios from 'axios';

import CurrencyInput from 'react-currency-input';
import { Link } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Col, Label } from 'reactstrap';
import { Translate, translate } from 'app/config/translate-component';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  mapDispatchToPropsExtended as mapDispatchToPropsClComRuler,
  ClComRulerExtended as ClComRuler,
} from 'app/entities/cl-com-ruler/extended/cl-com-ruler';

import {
  mapDispatchToPropsExtended as mapDispatchToPropsClComItem,
  ClComItemExtended as ClComItem,
} from 'app/entities/cl-com-item/extended/cl-com-item';
import { BASE_API_VERSION_PATH } from 'app/config/constants';
import { getEntityFiltersURL } from '../cl-com-table.reducer';

export interface IUpdateExtendedState {
  dumystate?: any;
}

export class ClComTableExtendedUpdate extends ClComTableUpdate {
  renderHeader() {
    const { clComTableEntity } = this.props;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.clComTable.home.createLabel">Create a ClComTable</Translate>
            ) : (
              <Translate contentKey="generadorApp.clComTable.home.editLabel">Edit a ClComTable</Translate>
            )}
          </span>

          <Button
            tag={Link}
            id="cancel-save"
            to={'/client/' + this.props.match.params['idClient'] + '/cl-com-table?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.clComTable.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/client'}>
              <Translate contentKey="generadorApp.client.home.title">Clients</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/client/' + this.props.match.params['idClient']}>
              {this.state.clientSelectValue ? this.state.clientSelectValue.label : this.props.match.params['idClient']}
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/client/' + this.props.match.params['idClient'] + '/cl-com-table'}>
              <Translate contentKey="generadorApp.clComTable.home.title">Commercial tables</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.clComTable.detail.title">Commercial table edit</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderBody() {
    const { clComTableEntity, loading } = this.props;
    const { isNew } = this.state;

    const fullEntityEmbebed = this.getFullEntityFromState(clComTableEntity);

    const mapDispatchToPropsClComRulerList: any = {};
    Object.keys(mapDispatchToPropsClComRuler).map(v => {
      mapDispatchToPropsClComRulerList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'ClComRuler')) {
          this.setState({ functionEmbebed: [...this.state.functionEmbebed, v + 'ClComRuler'] }, this.props[v + 'ClComRuler']);
        }
      };
    });
    const mapDispatchToPropsClComItemList: any = {};
    Object.keys(mapDispatchToPropsClComItem).map(v => {
      mapDispatchToPropsClComItemList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'ClComItem')) {
          this.setState({ functionEmbebed: [...this.state.functionEmbebed, v + 'ClComItem'] }, this.props[v + 'ClComItem']);
        }
      };
    });

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="cl-com-table-id">
                  <Translate contentKey="generadorApp.clComTable.field_id">ID</Translate>
                </Label>
                </Col> */}
                      <Col md="12">
                        <AvInput id="cl-com-table-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  <Col md="4">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" id="nameLabel" for="cl-com-table-name">
                            <Translate contentKey="generadorApp.clComTable.name">Name</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <input
                            onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                            onBlur={evt => {
                              axios.put(BASE_API_VERSION_PATH + 'cl-com-tables', { id: clComTableEntity.id, name: evt.target.value });
                            }}
                            value={this.state.nameSelectValue ? this.state.nameSelectValue : ''}
                            id="cl-com-table-name"
                            type="text"
                            name="name"
                            className={'form-control'}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md="2">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" id="statusLabel" for="cl-com-table-status">
                            <Translate contentKey="generadorApp.clComTable.status">Status</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <Select
                            id="cl-com-table-status"
                            className={'css-select-control'}
                            value={
                              this.state.statusSelectValue
                                ? {
                                    value: this.state.statusSelectValue,
                                    label: translate('generadorApp.Status.' + this.state.statusSelectValue),
                                  }
                                : { value: '', label: translate('generadorApp.clComTable.status') }
                            }
                            options={[
                              { value: 'INATIVADO', label: translate('generadorApp.Status.INATIVADO') },
                              { value: 'ATIVO', label: translate('generadorApp.Status.ATIVO') },
                            ]}
                            onChange={(options: any) => {
                              axios.put(BASE_API_VERSION_PATH + 'cl-com-tables', { id: clComTableEntity.id, status: options['value'] });
                              this.setState({ statusSelectValue: options['value'] });
                            }}
                            name="status"
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md="2">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" id="typeLabel" for="cl-com-table-type">
                            <Translate contentKey="generadorApp.clComTable.type">Type</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <Select
                            id="cl-com-table-type"
                            className={'css-select-control'}
                            value={
                              this.state.typeSelectValue
                                ? {
                                    value: this.state.typeSelectValue,
                                    label: translate('generadorApp.TypeClientCommercialTable.' + this.state.typeSelectValue),
                                  }
                                : { value: '', label: translate('generadorApp.clComTable.type') }
                            }
                            options={[
                              { value: 'TABELAABERTA', label: translate('generadorApp.TypeClientCommercialTable.TABELAABERTA') },
                              { value: 'PACOTE', label: translate('generadorApp.TypeClientCommercialTable.PACOTE') },
                            ]}
                            onChange={(options: any) => {
                              axios.put(BASE_API_VERSION_PATH + 'cl-com-tables', { id: clComTableEntity.id, type: options['value'] });
                              this.setState({ typeSelectValue: options['value'] });
                            }}
                            name="type"
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  {this.state.typeSelectValue === 'PACOTE' ? (
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="chargeTypeLabel" for="cl-com-table-chargeType">
                              <Translate contentKey="generadorApp.clComTable.chargeType">Charge Type</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="cl-com-table-chargeType"
                              className={'css-select-control'}
                              value={
                                this.state.chargeTypeSelectValue
                                  ? {
                                      value: this.state.chargeTypeSelectValue,
                                      label: translate('generadorApp.ChargeType.' + this.state.chargeTypeSelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.clComTable.chargeType') }
                              }
                              options={[
                                { value: 'DESPESA', label: translate('generadorApp.ChargeType.DESPESA') },
                                { value: 'PROCEDIMENTO', label: translate('generadorApp.ChargeType.PROCEDIMENTO') },
                              ]}
                              onChange={(options: any) => {
                                axios.put(BASE_API_VERSION_PATH + 'cl-com-tables', {
                                  id: clComTableEntity.id,
                                  chargeType: options['value'],
                                });
                                this.setState({ chargeTypeSelectValue: options['value'] });
                              }}
                              name="chargeType"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <> </>
                  )}
                  {this.state.typeSelectValue === 'PACOTE' ? (
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="valuePackageLabel" for="cl-com-table-valuePackage">
                              <Translate contentKey="generadorApp.clComTable.valuePackage">Value</Translate>
                            </Label>
                          </Col>

                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => {
                                this.setState({ valuePackageSelectValue: floatvalue });
                              }}
                              onBlur={() => {
                                axios.put(BASE_API_VERSION_PATH + 'cl-com-tables', {
                                  id: clComTableEntity.id,
                                  valuePackage: this.state.valuePackageSelectValue,
                                });
                              }}
                              decimalSeparator=","
                              precision={2}
                              thousandSeparator=""
                              prefix="R$ "
                              allowNegative={true}
                              value={this.state.valuePackageSelectValue ? this.state.valuePackageSelectValue : 0}
                              id="cl-com-table-valuePackage"
                              name="valuePackage"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <> </>
                  )}
                  {this.state.typeSelectValue === 'PACOTE' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="tussCodeLabel" for="cl-com-table-tussCode">
                              <Translate contentKey="generadorApp.clComTable.tussCode">Tuss Code</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ tussCodeSelectValue: evt.target.value })}
                              onBlur={evt => {
                                axios.put(BASE_API_VERSION_PATH + 'cl-com-tables', { id: clComTableEntity.id, tussCode: evt.target.value });
                              }}
                              value={this.state.tussCodeSelectValue ? this.state.tussCodeSelectValue : ''}
                              id="cl-com-table-tussCode"
                              type="text"
                              name="tussCode"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <> </>
                  )}
                  {this.state.typeSelectValue === 'PACOTE' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="tableCodeLabel" for="cl-com-table-tableCode">
                              <Translate contentKey="generadorApp.clComTable.tableCode">Table Code</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ tableCodeSelectValue: evt.target.value })}
                              onBlur={evt => {
                                axios.put(BASE_API_VERSION_PATH + 'cl-com-tables', {
                                  id: clComTableEntity.id,
                                  tableCode: evt.target.value,
                                });
                              }}
                              value={this.state.tableCodeSelectValue ? this.state.tableCodeSelectValue : ''}
                              id="cl-com-table-tableCode"
                              type="text"
                              name="tableCode"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <> </>
                  )}
                  {this.state.typeSelectValue === 'PACOTE' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="expenseCodeLabel" for="cl-com-table-expenseCode">
                              <Translate contentKey="generadorApp.clComTable.expenseCode">Expense Code</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ expenseCodeSelectValue: evt.target.value })}
                              onBlur={evt => {
                                axios.put(BASE_API_VERSION_PATH + 'cl-com-tables', {
                                  id: clComTableEntity.id,
                                  expenseCode: evt.target.value,
                                });
                              }}
                              value={this.state.expenseCodeSelectValue ? this.state.expenseCodeSelectValue : ''}
                              id="cl-com-table-expenseCode"
                              type="text"
                              name="expenseCode"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <> </>
                  )}
                  {this.state.typeSelectValue === 'PACOTE' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="cl-com-table-dose">
                              <Translate contentKey="generadorApp.clComTable.dose">Dose</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="cl-com-table-dose"
                              name={'dose'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.doseSelectValue ? this.state.doseSelectValue : ''}
                              onChange={options => {
                                this.setState({ doseSelectValue: options });
                                axios.put(BASE_API_VERSION_PATH + 'cl-com-tables', {
                                  id: clComTableEntity.id,
                                  dose: { id: options.value },
                                });
                              }}
                              defaultOptions={this.state.doseStartSelectOptions ? this.state.doseStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.doseStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.doseStartSelectOptions === undefined) {
                                  const result = await getListAxios('doses', {}, 0, 100, 'name,asc', 'name,codeANS');
                                  this.setState({
                                    doseStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name;codeANS') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'doses',
                                  { 'codeANS.contains': inputValue },
                                  0,
                                  100,
                                  'name,asc',
                                  'name,codeANS'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name;codeANS') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <> </>
                  )}
                  <Col md="12">
                    <div className="entityEmbebedList">
                      <ClComRuler
                        entityEmbebedPai={fullEntityEmbebed}
                        history={this.props.history}
                        location={{
                          pathname: `/cl-com-table/${this.props.match.params['id']}/cl-com-ruler/`,
                          search: '',
                          hash: '',
                          state: undefined,
                          key: 'q2tbhd',
                        }}
                        match={{
                          path: `/cl-com-table/:idClComTable/cl-com-ruler/:id`,
                          url: `/cl-com-table/${this.props.match.params['id']}/cl-com-ruler/`,
                          isExact: true,
                          params: {
                            idClComTable: this.props.match.params['id'],
                            url: `/cl-com-table/${this.props.match.params['id']}/cl-com-ruler/`,
                          },
                        }}
                        {...this.props.mapStateToPropsClComRuler}
                        {...mapDispatchToPropsClComRulerList}
                        getEntityPai={(id, ...v) => this.props.getEntity(id, ...v)}
                        getEntities={this.clComRulerGetEntities}
                        updateEntity={this.clComRulerUpdateEntity}
                        getEntity={this.clComRulerGetEntity}
                        createEntity={this.clComRulerCreateEntity}
                        deleteEntity={this.clComRulerDeleteEntity}
                        reset={this.clComRulerReset}
                        clComRulerList={this.props.clComRulerListEmbed}
                        totalItems={this.props.clComRulerListEmbed ? this.props.clComRulerListEmbed.length : 0}
                        loading={this.props.loading}
                        clComRulerEntity={this.props.clComRulerSelected}
                      />
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="entityEmbebedList">
                      <ClComItem
                        entityEmbebedPai={fullEntityEmbebed}
                        history={this.props.history}
                        location={{
                          pathname: `/cl-com-table/${this.props.match.params['id']}/cl-com-item/`,
                          search: '',
                          hash: '',
                          state: undefined,
                          key: 'q2tbhd',
                        }}
                        match={{
                          path: `/cl-com-table/:idClComTable/cl-com-item/:id`,
                          url: `/cl-com-table/${this.props.match.params['id']}/cl-com-item/`,
                          isExact: true,
                          params: {
                            idClComTable: this.props.match.params['id'],
                            url: `/cl-com-table/${this.props.match.params['id']}/cl-com-item/`,
                          },
                        }}
                        {...this.props.mapStateToPropsClComItem}
                        {...mapDispatchToPropsClComItemList}
                        getEntityPai={(id, ...v) => this.props.getEntity(id, ...v)}
                        getEntities={this.clComItemGetEntities}
                        updateEntity={this.clComItemUpdateEntity}
                        getEntity={this.clComItemGetEntity}
                        createEntity={this.clComItemCreateEntity}
                        deleteEntity={this.clComItemDeleteEntity}
                        reset={this.clComItemReset}
                        clComItemList={this.props.clComItemListEmbed}
                        totalItems={this.props.clComItemListEmbed ? this.props.clComItemListEmbed.length : 0}
                        loading={this.props.loading}
                        clComItemEntity={this.props.clComItemSelected}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { clComTableEntity } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...clComTableEntity,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel>
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
    listProcediment: storeState.patDrPrescrProcd.entities,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;
export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(ClComTableExtendedUpdate);
