/* eslint complexity: ["error", 500] */

import { connect } from 'react-redux';
import axios from 'axios';

import { InventoryItem, mapStateToProps, mapDispatchToProps } from '../inventory-item';
import { IRootState } from 'app/shared/reducers';
import { translate } from 'app/config/translate-component';
import { toast } from 'react-toastify';
import { getFormFromSelect } from 'app/shared/util/entity-utils';
import { PharmacyInventoryStatus } from 'app/shared/model/enumerations/pharmacy-inventory-status.model';

export interface IExtendedState {
  dumystate?: any;
}
export interface IExtendedProps {
  entityEmbebedPai?: any;
}

export class InventoryItemExtended extends InventoryItem {
  renderBodyUpdate(inventoryItemEntity, isNew) {
    return super.renderBodyUpdate(inventoryItemEntity, isNew);
  }

  async openUpdateModal(v, edit = true) {
    const result = await axios.get('/api/medicine-materials/' + v.medicineMaterial.id + '/stock');
    const totalLotes = result['data']
      .map(x => x.STOCK)
      .reduce((a, b) => Number.parseFloat(a) + Number.parseFloat(b), 0)
      .toFixed(2);
    const loteList = [{ value: 'Todos os Lotes', label: 'Todos os Lotes', total: totalLotes }];
    result['data'].map(x => {
      loteList.push({ value: x.lot, label: x.lot, total: x.STOCK });
    });
    this.setState(
      {
        lotStartSelectOptions: loteList,
      },
      () => super.openUpdateModal(v, edit)
    );
  }

  saveEntity = (event: any, errors: any, values: any, isNew: any) => {
    const selectFieldsList = ['medicineMaterial.name', 'currentQuantity', 'quantity', 'status'];

    let _status = PharmacyInventoryStatus.CORRETO;
    if (this.state.quantitySelectValue > this.state.currentQuantitySelectValue) {
      _status = PharmacyInventoryStatus.QUANTIDADEAMAIS;
    } else if (this.state.quantitySelectValue < this.state.currentQuantitySelectValue) {
      _status = PharmacyInventoryStatus.PERDA;
    }

    if (errors.length === 0) {
      const { inventoryItemEntity } = this.props;
      const _entity = {
        ...inventoryItemEntity,
        // ...values,
        medicineMaterial: getFormFromSelect(this.state.medicineMaterialSelectValue, 'many-to-one'), // many-to-one - otherSide

        lot: this.state.lotSelectValue,
        quantity: this.state.quantitySelectValue,
        currentQuantity: this.state.currentQuantitySelectValue,
        status: _status,
      };
      const entity = _entity;

      const { currentQuantity, quantity, extraFilters, activePage, itemsPerPage, sort, order } = this.state.fieldsBase;

      if (isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              currentQuantity,
              quantity,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              currentQuantity,
              quantity,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
      this.setState({ showModalForm: false });
      this.setState({ showModalEdit: null });
    } else {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.specialty.' + errors[0]) }));
    }
    return true;
  };
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;

export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(InventoryItemExtended);
