/* eslint complexity: ["error", 500] */
import axios from 'axios';

import moment from 'moment';
import { IPaginationBaseState, IPayloadResult } from 'react-jhipster';

import { IPayload } from 'react-jhipster/src/type/redux-action.type';

import { BASE_API_VERSION_PATH } from 'app/config/constants';

import { cleanEntity, jsonParse } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IProfessional, defaultValue } from 'app/shared/model/professional.model';

export declare type ICrudPutAction<T> = (data?: T, listFiltersPage?) => IPayload<T> | IPayloadResult<T>;
export declare type ICrudGetAction<T> = (id: string | number, selectFields?: string) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const ACTION_TYPES = {
  FETCH_PROFESSIONAL_LIST_EXPORT: 'professional/FETCH_PROFESSIONAL_LIST_EXPORT',
  FETCH_PROFESSIONAL_LIST: 'professional/FETCH_PROFESSIONAL_LIST',
  FETCH_PROFESSIONAL: 'professional/FETCH_PROFESSIONAL',
  FETCH_PROFESSIONAL_OFFSET: 'professional/FETCH_PROFESSIONAL_OFFSET',
  CREATE_PROFESSIONAL: 'professional/CREATE_PROFESSIONAL',
  UPDATE_PROFESSIONAL: 'professional/UPDATE_PROFESSIONAL',
  CREATE_PROFESSIONAL_AND_GO_LIST: 'professional/CREATE_PROFESSIONAL_AND_GO_LIST',
  UPDATE_PROFESSIONAL_AND_GO_LIST: 'professional/UPDATE_PROFESSIONAL_AND_GO_LIST',
  DELETE_PROFESSIONAL: 'professional/DELETE_PROFESSIONAL',
  SET_BLOB: 'professional/SET_BLOB',
  RESET: 'professional/RESET',
  SHOW_MODAL: 'professional/SHOW_MODAL',
};

const initialState = {
  loading: false,
  openModal: {
    list: false,
    view: false,
    detail: false,
  },
  errorMessage: null,
  entities: [] as ReadonlyArray<IProfessional>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const actionPendind = [];
let actualState = initialState;

export const convertEntityBuffers = entity => ({
  ...entity,
  observation: entity.observation ? Buffer.from(entity.observation).toString() : entity.observation,
});

export type ProfessionalState = Readonly<typeof initialState>;

export interface IProfessionalBaseState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  listCheckedID?: Array<number>;
  isCheckedAll?: boolean;
  urlBack?: string;
  extraFilters?: any;
  baseFilters: any;
  offset: number;
  specialtyCategoryId?: any;
  specialtyCategoryStartFilter?: any;
  specialtyId?: any;
  specialtyStartFilter?: any;
  name?: any;
  status?: any;
  professionalComplexity?: any;
  uf?: any;
  city?: any;
}

export interface IFieldsBase extends IProfessionalBaseState, IPaginationBaseState {}
export interface IProfessionalUpdateState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  urlBack?: string;
  functionEmbebed?: any[];
  fieldsBase: IFieldsBase;
  nameSelectValue?: any;
  nameStartSelectOptions?: any;
  rgSelectValue?: any;
  rgStartSelectOptions?: any;
  cpfSelectValue?: any;
  cpfStartSelectOptions?: any;
  expiredDateSelectValue?: any;
  expiredDateStartSelectOptions?: any;
  experienceTimeSelectValue?: any;
  experienceTimeStartSelectOptions?: any;
  companySelectValue?: any;
  companyStartSelectOptions?: any;
  cnpjSelectValue?: any;
  cnpjStartSelectOptions?: any;
  observationSelectValue?: any;
  observationStartSelectOptions?: any;
  sourceSelectValue?: any;
  sourceStartSelectOptions?: any;
  registerSelectValue?: any;
  registerStartSelectOptions?: any;
  statusSelectValue?: any;
  statusStartSelectOptions?: any;
  zipCodeSelectValue?: any;
  zipCodeStartSelectOptions?: any;
  streetSelectValue?: any;
  streetStartSelectOptions?: any;
  complementSelectValue?: any;
  complementStartSelectOptions?: any;
  numberSelectValue?: any;
  numberStartSelectOptions?: any;
  neighborhoodSelectValue?: any;
  neighborhoodStartSelectOptions?: any;
  citySelectValue?: any;
  cityStartSelectOptions?: any;
  ufSelectValue?: any;
  ufStartSelectOptions?: any;
  sexSelectValue?: any;
  sexStartSelectOptions?: any;
  birthDateSelectValue?: any;
  birthDateStartSelectOptions?: any;
  bankSelectValue?: any;
  bankStartSelectOptions?: any;
  agencySelectValue?: any;
  agencyStartSelectOptions?: any;
  bankAccountSelectValue?: any;
  bankAccountStartSelectOptions?: any;
  typeSelectValue?: any;
  typeStartSelectOptions?: any;
  professionalComplexitySelectValue?: any;
  professionalComplexityStartSelectOptions?: any;
  cepRequestBairro?: any;
  cepRequestCep?: any;
  cepRequestComplemento?: any;
  cepRequestGia?: any;
  cepRequestIbge?: any;
  cepRequestLocalidade?: any;
  cepRequestLogradouro?: any;
  cepRequestUf?: any;
  cepRequestUnidade?: any;

  userSelectValue?: any;
  userStartSelectOptions?: any;

  padAttendanceSelectValue?: any;
  padAttendanceStartSelectOptions?: any;

  professionalContactSelectValue?: any;
  professionalContactStartSelectOptions?: any;

  professionalStatusHistorySelectValue?: any;
  professionalStatusHistoryStartSelectOptions?: any;

  patientNursingPrescriptionSelectValue?: any;
  patientNursingPrescriptionStartSelectOptions?: any;

  professionalFileSelectValue?: any;
  professionalFileStartSelectOptions?: any;

  professionalOccupationAreaSelectValue?: any;
  professionalOccupationAreaStartSelectOptions?: any;

  pushSelectValue?: any;
  pushStartSelectOptions?: any;

  professionalDeviceSelectValue?: any;
  professionalDeviceStartSelectOptions?: any;

  specialtySelectValue?: any;
  specialtyStartSelectOptions?: any;

  professionalContractionTypeSelectValue?: any;
  professionalContractionTypeStartSelectOptions?: any;

  levelGroupSelectValue?: any;
  levelGroupStartSelectOptions?: any;

  professionalAdviceSelectValue?: any;
  professionalAdviceStartSelectOptions?: any;

  categorySelectValue?: any;
  categoryStartSelectOptions?: any;

  patientBlockedSelectValue?: any;
  patientBlockedStartSelectOptions?: any;

  patientPreferredSelectValue?: any;
  patientPreferredStartSelectOptions?: any;

  activeTab: number;
  isNew: boolean;
  idsprofessionalDevice: any[];
  idsspecialty: any[];
  idspatientBlocked: any[];
  idspatientPreferred: any[];
  userId?: any;
  professionalContractionTypeName?: any;

  professionalContractionTypeId?: any;
  levelGroupName?: any;

  levelGroupId?: any;
  professionalAdviceName?: any;

  professionalAdviceId?: any;
  categoryName?: any;

  categoryId?: any;
}

// Reducer

export default (state: ProfessionalState = initialState, action): ProfessionalState => {
  actualState = state;
  if (
    action.type.includes('_PENDING') &&
    !actionPendind.includes(action.type.replace('_PENDING', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_PENDING', ''))
  ) {
    actionPendind.push(action.type.replace('_PENDING', ''));
  }

  if (
    action.type.includes('_FULFILLED') &&
    actionPendind.includes(action.type.replace('_FULFILLED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_FULFILLED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_FULFILLED', '')), 1);
  }

  if (
    action.type.includes('_REJECTED') &&
    actionPendind.includes(action.type.replace('_REJECTED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_REJECTED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_REJECTED', '')), 1);
  }

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PROFESSIONAL_LIST_EXPORT):
    case REQUEST(ACTION_TYPES.FETCH_PROFESSIONAL_LIST):
    case REQUEST(ACTION_TYPES.FETCH_PROFESSIONAL):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_PROFESSIONAL):
    case REQUEST(ACTION_TYPES.UPDATE_PROFESSIONAL):
    case REQUEST(ACTION_TYPES.CREATE_PROFESSIONAL_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.UPDATE_PROFESSIONAL_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.DELETE_PROFESSIONAL):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_PROFESSIONAL_LIST_EXPORT):
    case FAILURE(ACTION_TYPES.FETCH_PROFESSIONAL_LIST):
    case FAILURE(ACTION_TYPES.FETCH_PROFESSIONAL):
    case FAILURE(ACTION_TYPES.CREATE_PROFESSIONAL):
    case FAILURE(ACTION_TYPES.UPDATE_PROFESSIONAL):
    case FAILURE(ACTION_TYPES.CREATE_PROFESSIONAL_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.UPDATE_PROFESSIONAL_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.DELETE_PROFESSIONAL):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_PROFESSIONAL_LIST):
      action.payload.data = action.payload.data.map(v => {
        return { ...v, observation: v.observation ? Buffer.from(v.observation).toString() : v.observation };
      });

      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_PROFESSIONAL_OFFSET):
      if (action.payload.data && action.payload.data.length > 0) {
        return {
          ...state,
          loading: false,
          entity: convertEntityBuffers(action.payload.data[0]),
        };
      }
      return {
        ...state,
        loading: false,
      };

    case SUCCESS(ACTION_TYPES.FETCH_PROFESSIONAL):
      return {
        ...state,
        loading: false,
        entity: convertEntityBuffers(action.payload.data),
      };
    case SUCCESS(ACTION_TYPES.CREATE_PROFESSIONAL):
    case SUCCESS(ACTION_TYPES.UPDATE_PROFESSIONAL):
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_PROFESSIONAL_AND_GO_LIST):
    case SUCCESS(ACTION_TYPES.UPDATE_PROFESSIONAL_AND_GO_LIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_PROFESSIONAL):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.SET_BLOB: {
      const { name, data, contentType, fileName } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [name + 'Base64']: data,
          [name + 'ContentType']: contentType,
          [name + 'FileName']: fileName,
        },
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };

    case ACTION_TYPES.SHOW_MODAL: {
      const openModal = state.openModal;
      openModal[action.payload.type] = action.payload.show;
      return {
        ...state,
        openModal,
      };
    }
    default:
      return state;
  }
};

const apiUrl = BASE_API_VERSION_PATH + 'professionals';

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

// Actions

// Actions
export type ICrudGetAllActionProfessional<T> = (
  specialtyCategoryId?: any,
  specialtyId?: any,
  name?: any,
  status?: any,
  professionalComplexity?: any,
  uf?: any,
  city?: any,
  extraFilters?: {},
  page?: number,
  size?: number,
  sort?: string,
  selectFields?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getEntitiesFilter = (filters?, page?, size?, sort?, selectFields = '') => {
  const requestUrl = `${apiUrl}?page=${page >= 0 ? page : 0}&size=${size >= 0 ? size : 20}&sort=${sort ? sort : 'id,asc'}&`;
  const requestFilters = filters.map(v => v.column + '.' + v.operation + '=' + v.value).join('&');
  return {
    type: ACTION_TYPES.FETCH_PROFESSIONAL_LIST,
    payload: axios.get<IProfessional>(`${requestUrl}${requestFilters}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getAllEntities = (filters?, sort?, selectFields = '') => {
  if (
    (actualState.totalItems > 0 && actualState.entities.length === actualState.totalItems) ||
    actionPendind.includes(ACTION_TYPES.FETCH_PROFESSIONAL_LIST)
  ) {
    return {
      type: ACTION_TYPES.FETCH_PROFESSIONAL_LIST + '_FROM_CACHE',
      payload: new Promise(function (resolve, reject) {
        resolve('done');
      }),
    };
  }

  const idsRequest = filters && typeof filters['ids'] !== 'undefined' && filters['ids'] ? `id.in=${filters['ids'].join(',')}&` : '';
  const specialtyCategoryRequest =
    filters && typeof filters['specialtyCategoryId'] !== 'undefined' && filters['specialtyCategoryId']
      ? `specialties.category.id.in=${filters['specialtyCategoryId']}&`
      : '';
  const specialtyRequest =
    filters && typeof filters['specialtyId'] !== 'undefined' && filters['specialtyId']
      ? `specialties.id.in=${filters['specialtyId']}&`
      : '';
  const nameRequest = filters && typeof filters['name'] !== 'undefined' && filters['name'] ? `name.contains=${filters['name']}&` : '';
  const statusRequest =
    filters && typeof filters['status'] !== 'undefined' && filters['status'] ? `status.contains=${filters['status']}&` : '';
  const professionalComplexityRequest =
    filters && typeof filters['professionalComplexity'] !== 'undefined' && filters['professionalComplexity']
      ? `professionalComplexity.contains=${filters['professionalComplexity']}&`
      : '';
  const ufRequest = filters && typeof filters['uf'] !== 'undefined' && filters['uf'] ? `uf.contains=${filters['uf']}&` : '';
  const cityRequest = filters && typeof filters['city'] !== 'undefined' && filters['city'] ? `city.contains=${filters['city']}&` : '';

  const requestUrl = `${apiUrl}${`?page=${0}&size=${1000000}&sort=${sort ? sort : 'id,asc'}&`}`;
  return {
    type: ACTION_TYPES.FETCH_PROFESSIONAL_LIST,
    payload: axios.get<IProfessional>(
      `${requestUrl}${idsRequest}${specialtyCategoryRequest}${specialtyRequest}${nameRequest}${statusRequest}${professionalComplexityRequest}${ufRequest}${cityRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntities: ICrudGetAllActionProfessional<IProfessional> = (
  specialtyCategoryId,
  specialtyId,
  name,
  status,
  professionalComplexity,
  uf,
  city,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const specialtyCategoryRequest = specialtyCategoryId ? `specialties.category.id.in=${specialtyCategoryId}&` : '';
  const specialtyRequest = specialtyId ? `specialties.id.in=${specialtyId}&` : '';
  const nameRequest = name ? `name.contains=${name}&` : '';
  const statusRequest = status ? (Array.isArray(status) ? `status.in=${status.join(',')}&` : `status.equals=${status}&`) : '';
  const professionalComplexityRequest = professionalComplexity
    ? Array.isArray(status)
      ? `professionalComplexity.in=${professionalComplexity.join(',')}&`
      : `professionalComplexity.equals=${professionalComplexity}&`
    : '';
  const ufRequest = uf ? `uf.contains=${uf}&` : '';
  const cityRequest = city ? `city.contains=${city}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PROFESSIONAL_LIST,
    payload: axios.get<IProfessional>(
      `${requestUrl}${extraFiltersRequest}${specialtyCategoryRequest}${specialtyRequest}${nameRequest}${statusRequest}${professionalComplexityRequest}${ufRequest}${cityRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntityOffset: any = (
  specialtyCategoryId,
  specialtyId,
  name,
  status,
  professionalComplexity,
  uf,
  city,
  extraFilters,
  offset,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const specialtyCategoryRequest = specialtyCategoryId ? `specialties.category.id.in=${specialtyCategoryId}&` : '';
  const specialtyRequest = specialtyId ? `specialties.id.in=${specialtyId}&` : '';
  const nameRequest = name ? `name.contains=${name}&` : '';
  const statusRequest = status ? (Array.isArray(status) ? `status.in=${status.join(',')}&` : `status.equals=${status}&`) : '';
  const professionalComplexityRequest = professionalComplexity
    ? Array.isArray(status)
      ? `professionalComplexity.in=${professionalComplexity.join(',')}&`
      : `professionalComplexity.equals=${professionalComplexity}&`
    : '';
  const ufRequest = uf ? `uf.contains=${uf}&` : '';
  const cityRequest = city ? `city.contains=${city}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${offset}&size=${1}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PROFESSIONAL_OFFSET,
    payload: axios.get<IProfessional>(
      `${requestUrl}${extraFiltersRequest}${specialtyCategoryRequest}${specialtyRequest}${nameRequest}${statusRequest}${professionalComplexityRequest}${ufRequest}${cityRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntity: ICrudGetAction<IProfessional> = (id, selectFields = '') => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_PROFESSIONAL,
    payload: axios.get<IProfessional>(requestUrl, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntitiesExport: ICrudGetAllActionProfessional<IProfessional> = (
  specialtyCategoryId,
  specialtyId,
  name,
  status,
  professionalComplexity,
  uf,
  city,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const specialtyCategoryRequest = specialtyCategoryId ? `specialties.category.id.in=${specialtyCategoryId}&` : '';
  const specialtyRequest = specialtyId ? `specialties.id.in=${specialtyId}&` : '';
  const nameRequest = name ? (Array.isArray(status) ? `name.in=${name.join(',')}&` : `name.contains=${name}&`) : '';
  const statusRequest = status ? (Array.isArray(status) ? `status.in=${status.join(',')}&` : `status.equals=${status}&`) : '';
  const professionalComplexityRequest = professionalComplexity
    ? Array.isArray(status)
      ? `professionalComplexity.in=${professionalComplexity.join(',')}&`
      : `professionalComplexity.equals=${professionalComplexity}&`
    : '';
  const ufRequest = uf ? (Array.isArray(status) ? `uf.in=${uf.join(',')}&` : `uf.contains=${uf}&`) : '';
  const cityRequest = city ? (Array.isArray(status) ? `city.in=${city.join(',')}&` : `city.contains=${city}&`) : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PROFESSIONAL_LIST,
    payload: axios.get<IProfessional>(
      `${requestUrl}${extraFiltersRequest}${specialtyCategoryRequest}${specialtyRequest}${nameRequest}${statusRequest}${professionalComplexityRequest}${ufRequest}${cityRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const createEntity: ICrudPutAction<IProfessional> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = {
    ...entity,
    expiredDate: entity.expiredDate ? moment(entity.expiredDate).format('YYYY-MM-DD') : null,
    birthDate: entity.birthDate ? moment(entity.birthDate).format('YYYY-MM-DD') : null,
  };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.CREATE_PROFESSIONAL_AND_GO_LIST
        : ACTION_TYPES.CREATE_PROFESSIONAL,
    payload: axios.post(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const updateEntity: ICrudPutAction<IProfessional> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = {
    ...entity,
    expiredDate: entity.expiredDate ? moment(entity.expiredDate).format('YYYY-MM-DD') : null,
    birthDate: entity.birthDate ? moment(entity.birthDate).format('YYYY-MM-DD') : null,
  };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.UPDATE_PROFESSIONAL_AND_GO_LIST
        : ACTION_TYPES.UPDATE_PROFESSIONAL,
    payload: axios.put(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const deleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_PROFESSIONAL,
    payload: axios.delete(requestUrl),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const setBlob = (name, data, contentType?, fileName?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType,
    fileName,
  },
});

export const showModal = view => ({
  payload: { type: view, show: true },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const hideModal = view => ({
  payload: { type: view, show: false },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const getUrlBack = (location): string => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  let urlBack = _urlBase.searchParams.get('urlBack') || '';
  urlBack = urlBack.trim();
  urlBack = urlBack.startsWith('/') ? urlBack.slice(1) : urlBack;
  urlBack = urlBack.endsWith('/') ? urlBack.slice(0, -1) : urlBack;
  return urlBack ? '/' + urlBack + '/' : '';
};

export const getProfessionalState = (location): IProfessionalBaseState => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  const extraFilters = jsonParse(_urlBase.searchParams.get('extraFilters') || '{}');
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;

  const specialtyCategoryId = _urlBase.searchParams.get('specialty.category') || '';
  const specialtyId = _urlBase.searchParams.get('specialty') || '';
  const name = _urlBase.searchParams.get('name') || '';
  const status = _urlBase.searchParams.get('status') || '';
  const professionalComplexity = _urlBase.searchParams.get('professionalComplexity') || '';
  const uf = _urlBase.searchParams.get('uf') || '';
  const city = _urlBase.searchParams.get('city') || '';
  return {
    baseFilters,
    extraFilters,
    offset,
    specialtyCategoryId: specialtyCategoryId
      ? specialtyCategoryId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] }))
      : [],
    specialtyId: specialtyId ? specialtyId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
    name,
    status,
    professionalComplexity,
    uf,
    city,
  };
};

export const getEntityFiltersURL = (state, offset = null) => {
  return (
    'baseFilters=' +
    state.baseFilters +
    '&extraFilters=' +
    encodeURI(JSON.stringify(state.extraFilters)) +
    '&page=' +
    state.activePage +
    '&' +
    'size=' +
    state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    state.sort +
    ',' +
    state.order +
    '&' +
    (state.specialtyCategoryId
      ? 'specialty.category.name=' + state.specialtyCategoryId.map(v => v.value + '<->' + v.label).join(',') + '&'
      : '') +
    (state.specialtyId ? 'specialty=' + state.specialtyId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.name ? 'name=' + state.name + '&' : '') +
    (state.status ? 'status=' + state.status + '&' : '') +
    (state.professionalComplexity ? 'professionalComplexity=' + state.professionalComplexity + '&' : '') +
    (state.uf ? 'uf=' + state.uf + '&' : '') +
    (state.city ? 'city=' + state.city + '&' : '') +
    ''
  );
};
