import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import VariableCost from './variable-cost';

import VariableCostDetail from './variable-cost-detail';

import VariableCostUpdate from './variable-cost';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={VariableCostUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={VariableCostUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={VariableCostDetail} />
      <ErrorBoundaryRoute path={match.path} component={VariableCost} />
    </Switch>
  </>
);

export default Routes;
