/* eslint complexity: ["error", 300] */
import React from 'react';
import { connect } from 'react-redux';

import { Category, mapStateToProps, mapDispatchToProps } from '../category';
import { IRootState } from 'app/shared/reducers';

import { Link } from 'react-router-dom';
import { Translate } from 'app/config/translate-component';

import { Button, Table } from 'reactstrap';
import { TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export interface IExtendedState {
  dumystate?: any;
}
export interface IExtendedProps {
  entityEmbebedPai?: any;
}

export class CategoryExtended extends Category {
  renderHeader() {
    return <> {super.renderHeader()} </>;
  }

  renderFilter() {
    return <> {super.renderFilter()} </>;
  }

  renderTable() {
    const {
      categoryStatusHistories,
      specialties,
      clComRulers,
      clComItems,
      productServices,
      pushes,
      levelGroups,
      medicineMaterials,
      kits,
      professionals,
      categoryList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {categoryList && categoryList.length > 0 ? (
          <Table
            id="category-table-list"
            responsive
            aria-describedby="category-heading"
            className={'table-hover table-striped table-responsive-css'}
          >
            <thead className={'thead-light'}>
              <tr>
                <th className="hand" onClick={sortFunction('id')}>
                  <Translate contentKey="generadorApp.category.field_id">ID</Translate>
                  <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                </th>
                {state.baseFilters !== 'name' ? (
                  <th className="hand" onClick={sortFunction('name')}>
                    <Translate contentKey="generadorApp.category.name">Name</Translate>
                    <FontAwesomeIcon icon={state.sort === 'name' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                ) : null}
                {state.baseFilters !== 'levelGroup' ? (
                  <th>
                    <Translate contentKey="generadorApp.category.levelGroup">Level Group</Translate>
                  </th>
                ) : null}
                {state.baseFilters !== 'createdDate' ? (
                  <th className="hand" onClick={sortFunction('createdDate')}>
                    <Translate contentKey="generadorApp.category.createdDate">Created Date</Translate>
                    <FontAwesomeIcon icon={state.sort === 'createdDate' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                ) : null}
                {state.baseFilters !== 'lastModifiedDate' ? (
                  <th className="hand" onClick={sortFunction('lastModifiedDate')}>
                    <Translate contentKey="generadorApp.category.lastModifiedDate">Last Modified Date</Translate>
                    <FontAwesomeIcon
                      icon={state.sort === 'lastModifiedDate' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'}
                    />
                  </th>
                ) : null}
                {state.baseFilters !== 'status' ? (
                  <th className="hand" onClick={sortFunction('status')}>
                    <Translate contentKey="generadorApp.category.status">Status</Translate>
                    <FontAwesomeIcon icon={state.sort === 'status' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                ) : null}

                <th />
              </tr>
            </thead>
            <tbody>
              <tr></tr>
              {categoryList
                .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                .map((category, i) => (
                  <tr key={`entity-${i}`}>
                    <td>
                      <b className="visible-xs">
                        <Translate contentKey="generadorApp.category.field_id">ID</Translate>
                      </b>
                      <Button tag={Link} to={`${match.url}/${category.id}`} color="link" size="sm">
                        {category.id}
                      </Button>
                    </td>
                    {state.baseFilters !== 'name' ? (
                      <td id="name-cell">
                        <b className="visible-xs"> Name </b>

                        {category.name}
                      </td>
                    ) : null}

                    {state.baseFilters !== 'levelGroup' ? (
                      <td id="levelGroup-cell">
                        <b className="visible-xs"> Level Group </b>
                        {category && category.levelGroup ? category.levelGroup['name'] : ''}
                      </td>
                    ) : null}
                    {state.baseFilters !== 'createdDate' ? (
                      <td id="createdDate-cell">
                        <b className="visible-xs"> Created Date </b>
                        <TextFormat type="date" value={category.createdDate} format={APP_DATE_FORMAT} />
                      </td>
                    ) : null}
                    {state.baseFilters !== 'lastModifiedDate' ? (
                      <td id="lastModifiedDate-cell">
                        <b className="visible-xs"> Last Modified Date </b>
                        <TextFormat type="date" value={category.lastModifiedDate} format={APP_DATE_FORMAT} />
                      </td>
                    ) : null}
                    {state.baseFilters !== 'status' ? (
                      <td id="status-cell">
                        <b className="visible-xs"> Status </b>
                        {category.status ? <Translate contentKey={`generadorApp.Status.${category.status}`} /> : <> </>}
                      </td>
                    ) : null}

                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">
                        {category && category.levelGroup && category.levelGroup['name'] ? (
                          category.levelGroup['name'] === 'Recursos Humanos' ? (
                            <Button tag={Link} to={`/category/${category.id}/audit-entity`} color="info" size="sm">
                              <FontAwesomeIcon icon="history" />
                            </Button>
                          ) : (
                            <></>
                          )
                        ) : (
                          levelGroups
                            .filter(v => v.category && v.category.map(b => b.id).includes(category.id))
                            .map((v, k) =>
                              v.name === 'Recursos Humanos' ? (
                                <Button tag={Link} to={`/category/${category.id}/audit-entity`} color="info" size="sm">
                                  <FontAwesomeIcon icon="history" />
                                </Button>
                              ) : (
                                <></>
                              )
                            )
                        )}
                        {hasAnyAuthority(props.userAccount, ['category'], 'edit') ? (
                          <Button tag={Link} to={`/category/${category.id}/category-status-history`} color="info" size="sm">
                            <FontAwesomeIcon icon="check-square" />
                          </Button>
                        ) : (
                          <></>
                        )}
                        {hasAnyAuthority(props.userAccount, ['category'], 'edit') ? (
                          <Button onClick={() => this.openUpdateModal(category)} color="primary" size="sm">
                            <FontAwesomeIcon icon="pencil-alt" />
                          </Button>
                        ) : (
                          <></>
                        )}
                        {hasAnyAuthority(props.userAccount, ['category'], 'canDelete') ? (
                          <Button onClick={() => this.deleteEntity(category)} color="danger" size="sm">
                            <FontAwesomeIcon icon="trash" />
                          </Button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.category.home.notFound">No Categories found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    return <> {super.renderFooter()} </>;
  }

  render() {
    return <> {super.render()} </>;
  }
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;

export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(CategoryExtended);
