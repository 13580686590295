import React from 'react';

import { connect } from 'react-redux';
import { Moment } from 'moment';
import { Client } from 'app/entities/client/client';

export interface IChartPacientesProps extends StateProps, DispatchProps {
  periodoInicio?: Moment;
  periodoFim?: Moment;
  operadora?: Array<Client>;
  pacientes: {
    PacientesAtivos: number;
    PacientesComComplexidadeAlta: number;
    PacientesComComplexidadeMedia: number;
    PacientesComComplexidadeBaixa: number;
    PacientesComComplexidadeVentilacaoMecanica: number;
  };
}

const ChartPacientes = (props: IChartPacientesProps) => {
  return (
    <div className="card border-0 bg-dark text-white text-truncate mb-3">
      <div className="card-body">
        <div className="mb-3 text-grey">
          <b className="mb-3">Pacientes</b>
        </div>
        <div className="d-flex align-items-center mb-1">
          <h2 className="text-white mb-0">{props.pacientes?.PacientesAtivos}</h2>
        </div>
        <div className="d-flex mb-2">
          <div className="d-flex align-items-center">
            <i className="fa fa-circle text-red f-s-8 mr-2"></i>
            Alta
          </div>
          <div className="d-flex align-items-center ml-auto">
            <div className="width-50 text-right pl-2 f-w-600"> {props.pacientes?.PacientesComComplexidadeAlta}</div>
          </div>
        </div>
        <div className="d-flex mb-2">
          <div className="d-flex align-items-center">
            <i className="fa fa-circle text-warning f-s-8 mr-2"></i>
            Meia
          </div>
          <div className="d-flex align-items-center ml-auto">
            <div className="width-50 text-right pl-2 f-w-600">{props.pacientes?.PacientesComComplexidadeMedia}</div>
          </div>
        </div>
        <div className="d-flex mb-2">
          <div className="d-flex align-items-center">
            <i className="fa fa-circle text-lime f-s-8 mr-2"></i>
            Baixa
          </div>
          <div className="d-flex align-items-center ml-auto">
            <div className="width-50 text-right pl-2 f-w-600">{props.pacientes?.PacientesComComplexidadeBaixa}</div>
          </div>
        </div>
        <div className="d-flex">
          <div className="d-flex align-items-center">
            <i className="fa fa-circle text-primary f-s-8 mr-2"></i>
            Ventilação Mecânica
          </div>
          <div className="d-flex align-items-center ml-auto">
            <div className="width-50 text-right pl-2 f-w-600">{props.pacientes?.PacientesComComplexidadeVentilacaoMecanica}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = storeState => ({
  report:
    storeState.jhiReports.reportsList && storeState.jhiReports.reportsList['pacientes-hospitalizados']
      ? storeState.jhiReports.reportsList['pacientes-hospitalizados']
      : [],
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps)(ChartPacientes);
