/* eslint complexity: ["error", 500] */
import axios from 'axios';

import moment from 'moment';
import { IPaginationBaseState, IPayloadResult } from 'react-jhipster';

import { IPayload } from 'react-jhipster/src/type/redux-action.type';

import { BASE_API_VERSION_PATH } from 'app/config/constants';

import { cleanEntity, jsonParse } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IClientContact, defaultValue } from 'app/shared/model/client-contact.model';

export declare type ICrudPutAction<T> = (data?: T, listFiltersPage?) => IPayload<T> | IPayloadResult<T>;
export declare type ICrudGetAction<T> = (id: string | number, selectFields?: string) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const ACTION_TYPES = {
  FETCH_CLIENTCONTACT_LIST_EXPORT: 'clientContact/FETCH_CLIENTCONTACT_LIST_EXPORT',
  FETCH_CLIENTCONTACT_LIST: 'clientContact/FETCH_CLIENTCONTACT_LIST',
  FETCH_CLIENTCONTACT: 'clientContact/FETCH_CLIENTCONTACT',
  FETCH_CLIENTCONTACT_OFFSET: 'clientContact/FETCH_CLIENTCONTACT_OFFSET',
  CREATE_CLIENTCONTACT: 'clientContact/CREATE_CLIENTCONTACT',
  UPDATE_CLIENTCONTACT: 'clientContact/UPDATE_CLIENTCONTACT',
  CREATE_CLIENTCONTACT_AND_GO_LIST: 'clientContact/CREATE_CLIENTCONTACT_AND_GO_LIST',
  UPDATE_CLIENTCONTACT_AND_GO_LIST: 'clientContact/UPDATE_CLIENTCONTACT_AND_GO_LIST',
  DELETE_CLIENTCONTACT: 'clientContact/DELETE_CLIENTCONTACT',
  RESET: 'clientContact/RESET',
  SHOW_MODAL: 'clientContact/SHOW_MODAL',
};

const initialState = {
  loading: false,
  openModal: {
    list: false,
    view: false,
    detail: false,
  },
  errorMessage: null,
  entities: [] as ReadonlyArray<IClientContact>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const actionPendind = [];
let actualState = initialState;

export const convertEntityBuffers = entity => ({
  ...entity,
});

export type ClientContactState = Readonly<typeof initialState>;

export interface IClientContactBaseState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  listCheckedID?: Array<number>;
  isCheckedAll?: boolean;
  urlBack?: string;
  extraFilters?: any;
  baseFilters: any;
  offset: number;
  name?: any;
  area?: any;
  funcao?: any;
  cellphonePrincipal?: any;
  clientId?: any;
  clientStartFilter?: any;
}

export interface IFieldsBase extends IClientContactBaseState, IPaginationBaseState {}
export interface IClientContactUpdateState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  urlBack?: string;
  functionEmbebed?: any[];
  fieldsBase: IFieldsBase;
  nameSelectValue?: any;
  nameStartSelectOptions?: any;
  areaSelectValue?: any;
  areaStartSelectOptions?: any;
  funcaoSelectValue?: any;
  funcaoStartSelectOptions?: any;
  cellphonePrincipalSelectValue?: any;
  cellphonePrincipalStartSelectOptions?: any;
  cellphoneSecondarySelectValue?: any;
  cellphoneSecondaryStartSelectOptions?: any;
  telephonePrincipalSelectValue?: any;
  telephonePrincipalStartSelectOptions?: any;
  telephoneSecondarySelectValue?: any;
  telephoneSecondaryStartSelectOptions?: any;
  emailSelectValue?: any;
  emailStartSelectOptions?: any;
  birthDateSelectValue?: any;
  birthDateStartSelectOptions?: any;

  clientSelectValue?: any;
  clientStartSelectOptions?: any;

  isNew: boolean;
  clientFantasyName?: any;

  clientId?: any;
}

// Reducer

export default (state: ClientContactState = initialState, action): ClientContactState => {
  actualState = state;
  if (
    action.type.includes('_PENDING') &&
    !actionPendind.includes(action.type.replace('_PENDING', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_PENDING', ''))
  ) {
    actionPendind.push(action.type.replace('_PENDING', ''));
  }

  if (
    action.type.includes('_FULFILLED') &&
    actionPendind.includes(action.type.replace('_FULFILLED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_FULFILLED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_FULFILLED', '')), 1);
  }

  if (
    action.type.includes('_REJECTED') &&
    actionPendind.includes(action.type.replace('_REJECTED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_REJECTED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_REJECTED', '')), 1);
  }

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_CLIENTCONTACT_LIST_EXPORT):
    case REQUEST(ACTION_TYPES.FETCH_CLIENTCONTACT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_CLIENTCONTACT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_CLIENTCONTACT):
    case REQUEST(ACTION_TYPES.UPDATE_CLIENTCONTACT):
    case REQUEST(ACTION_TYPES.CREATE_CLIENTCONTACT_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.UPDATE_CLIENTCONTACT_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.DELETE_CLIENTCONTACT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_CLIENTCONTACT_LIST_EXPORT):
    case FAILURE(ACTION_TYPES.FETCH_CLIENTCONTACT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_CLIENTCONTACT):
    case FAILURE(ACTION_TYPES.CREATE_CLIENTCONTACT):
    case FAILURE(ACTION_TYPES.UPDATE_CLIENTCONTACT):
    case FAILURE(ACTION_TYPES.CREATE_CLIENTCONTACT_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.UPDATE_CLIENTCONTACT_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.DELETE_CLIENTCONTACT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_CLIENTCONTACT_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_CLIENTCONTACT_OFFSET):
      if (action.payload.data && action.payload.data.length > 0) {
        return {
          ...state,
          loading: false,
          entity: convertEntityBuffers(action.payload.data[0]),
        };
      }
      return {
        ...state,
        loading: false,
      };

    case SUCCESS(ACTION_TYPES.FETCH_CLIENTCONTACT):
      return {
        ...state,
        loading: false,
        entity: convertEntityBuffers(action.payload.data),
      };
    case SUCCESS(ACTION_TYPES.CREATE_CLIENTCONTACT):
    case SUCCESS(ACTION_TYPES.UPDATE_CLIENTCONTACT):
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_CLIENTCONTACT_AND_GO_LIST):
    case SUCCESS(ACTION_TYPES.UPDATE_CLIENTCONTACT_AND_GO_LIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_CLIENTCONTACT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };

    case ACTION_TYPES.SHOW_MODAL: {
      const openModal = state.openModal;
      openModal[action.payload.type] = action.payload.show;
      return {
        ...state,
        openModal,
      };
    }
    default:
      return state;
  }
};

const apiUrl = BASE_API_VERSION_PATH + 'client-contacts';

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

// Actions

// Actions
export type ICrudGetAllActionClientContact<T> = (
  name?: any,
  area?: any,
  funcao?: any,
  cellphonePrincipal?: any,
  clientId?: any,
  extraFilters?: {},
  page?: number,
  size?: number,
  sort?: string,
  selectFields?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getEntitiesFilter = (filters?, page?, size?, sort?, selectFields = '') => {
  const requestUrl = `${apiUrl}?page=${page >= 0 ? page : 0}&size=${size >= 0 ? size : 20}&sort=${sort ? sort : 'id,asc'}&`;
  const requestFilters = filters.map(v => v.column + '.' + v.operation + '=' + v.value).join('&');
  return {
    type: ACTION_TYPES.FETCH_CLIENTCONTACT_LIST,
    payload: axios.get<IClientContact>(`${requestUrl}${requestFilters}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getAllEntities = (filters?, sort?, selectFields = '') => {
  if (
    (actualState.totalItems > 0 && actualState.entities.length === actualState.totalItems) ||
    actionPendind.includes(ACTION_TYPES.FETCH_CLIENTCONTACT_LIST)
  ) {
    return {
      type: ACTION_TYPES.FETCH_CLIENTCONTACT_LIST + '_FROM_CACHE',
      payload: new Promise(function (resolve, reject) {
        resolve('done');
      }),
    };
  }

  const idsRequest = filters && typeof filters['ids'] !== 'undefined' && filters['ids'] ? `id.in=${filters['ids'].join(',')}&` : '';
  const nameRequest = filters && typeof filters['name'] !== 'undefined' && filters['name'] ? `name.contains=${filters['name']}&` : '';
  const areaRequest = filters && typeof filters['area'] !== 'undefined' && filters['area'] ? `area.contains=${filters['area']}&` : '';
  const funcaoRequest =
    filters && typeof filters['funcao'] !== 'undefined' && filters['funcao'] ? `funcao.contains=${filters['funcao']}&` : '';
  const cellphonePrincipalRequest =
    filters && typeof filters['cellphonePrincipal'] !== 'undefined' && filters['cellphonePrincipal']
      ? `cellphonePrincipal.contains=${filters['cellphonePrincipal']}&`
      : '';
  const clientRequest =
    filters && typeof filters['clientId'] !== 'undefined' && filters['clientId'] ? `client.id.in=${filters['clientId']}&` : '';

  const requestUrl = `${apiUrl}${`?page=${0}&size=${1000000}&sort=${sort ? sort : 'id,asc'}&`}`;
  return {
    type: ACTION_TYPES.FETCH_CLIENTCONTACT_LIST,
    payload: axios.get<IClientContact>(
      `${requestUrl}${idsRequest}${nameRequest}${areaRequest}${funcaoRequest}${cellphonePrincipalRequest}${clientRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntities: ICrudGetAllActionClientContact<IClientContact> = (
  name,
  area,
  funcao,
  cellphonePrincipal,
  clientId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const nameRequest = name ? `name.contains=${name}&` : '';
  const areaRequest = area ? `area.contains=${area}&` : '';
  const funcaoRequest = funcao ? `funcao.contains=${funcao}&` : '';
  const cellphonePrincipalRequest = cellphonePrincipal ? `cellphonePrincipal.contains=${cellphonePrincipal}&` : '';
  const clientRequest = clientId ? `client.id.in=${clientId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_CLIENTCONTACT_LIST,
    payload: axios.get<IClientContact>(
      `${requestUrl}${extraFiltersRequest}${nameRequest}${areaRequest}${funcaoRequest}${cellphonePrincipalRequest}${clientRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntityOffset: any = (name, area, funcao, cellphonePrincipal, clientId, extraFilters, offset, sort, selectFields = '') => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const nameRequest = name ? `name.contains=${name}&` : '';
  const areaRequest = area ? `area.contains=${area}&` : '';
  const funcaoRequest = funcao ? `funcao.contains=${funcao}&` : '';
  const cellphonePrincipalRequest = cellphonePrincipal ? `cellphonePrincipal.contains=${cellphonePrincipal}&` : '';
  const clientRequest = clientId ? `client.id.in=${clientId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${offset}&size=${1}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_CLIENTCONTACT_OFFSET,
    payload: axios.get<IClientContact>(
      `${requestUrl}${extraFiltersRequest}${nameRequest}${areaRequest}${funcaoRequest}${cellphonePrincipalRequest}${clientRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntity: ICrudGetAction<IClientContact> = (id, selectFields = '') => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_CLIENTCONTACT,
    payload: axios.get<IClientContact>(requestUrl, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntitiesExport: ICrudGetAllActionClientContact<IClientContact> = (
  name,
  area,
  funcao,
  cellphonePrincipal,
  clientId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const nameRequest = name ? (Array.isArray(status) ? `name.in=${name.join(',')}&` : `name.contains=${name}&`) : '';
  const areaRequest = area ? (Array.isArray(status) ? `area.in=${area.join(',')}&` : `area.contains=${area}&`) : '';
  const funcaoRequest = funcao ? (Array.isArray(status) ? `funcao.in=${funcao.join(',')}&` : `funcao.contains=${funcao}&`) : '';
  const cellphonePrincipalRequest = cellphonePrincipal
    ? Array.isArray(status)
      ? `cellphonePrincipal.in=${cellphonePrincipal.join(',')}&`
      : `cellphonePrincipal.contains=${cellphonePrincipal}&`
    : '';
  const clientRequest = clientId ? `client.id.in=${clientId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_CLIENTCONTACT_LIST,
    payload: axios.get<IClientContact>(
      `${requestUrl}${extraFiltersRequest}${nameRequest}${areaRequest}${funcaoRequest}${cellphonePrincipalRequest}${clientRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const createEntity: ICrudPutAction<IClientContact> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity, birthDate: entity.birthDate ? moment(entity.birthDate).format('YYYY-MM-DD') : null };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.CREATE_CLIENTCONTACT_AND_GO_LIST
        : ACTION_TYPES.CREATE_CLIENTCONTACT,
    payload: axios.post(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const updateEntity: ICrudPutAction<IClientContact> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity, birthDate: entity.birthDate ? moment(entity.birthDate).format('YYYY-MM-DD') : null };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.UPDATE_CLIENTCONTACT_AND_GO_LIST
        : ACTION_TYPES.UPDATE_CLIENTCONTACT,
    payload: axios.put(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const deleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_CLIENTCONTACT,
    payload: axios.delete(requestUrl),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const showModal = view => ({
  payload: { type: view, show: true },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const hideModal = view => ({
  payload: { type: view, show: false },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const getUrlBack = (location): string => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  let urlBack = _urlBase.searchParams.get('urlBack') || '';
  urlBack = urlBack.trim();
  urlBack = urlBack.startsWith('/') ? urlBack.slice(1) : urlBack;
  urlBack = urlBack.endsWith('/') ? urlBack.slice(0, -1) : urlBack;
  return urlBack ? '/' + urlBack + '/' : '';
};

export const getClientContactState = (location): IClientContactBaseState => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  const extraFilters = jsonParse(_urlBase.searchParams.get('extraFilters') || '{}');
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;

  const name = _urlBase.searchParams.get('name') || '';
  const area = _urlBase.searchParams.get('area') || '';
  const funcao = _urlBase.searchParams.get('funcao') || '';
  const cellphonePrincipal = _urlBase.searchParams.get('cellphonePrincipal') || '';
  const clientId = _urlBase.searchParams.get('client') || '';
  return {
    baseFilters,
    extraFilters,
    offset,
    name,
    area,
    funcao,
    cellphonePrincipal,
    clientId: clientId ? clientId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
  };
};

export const getEntityFiltersURL = (state, offset = null) => {
  return (
    'baseFilters=' +
    state.baseFilters +
    '&extraFilters=' +
    encodeURI(JSON.stringify(state.extraFilters)) +
    '&page=' +
    state.activePage +
    '&' +
    'size=' +
    state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    state.sort +
    ',' +
    state.order +
    '&' +
    (state.name ? 'name=' + state.name + '&' : '') +
    (state.area ? 'area=' + state.area + '&' : '') +
    (state.funcao ? 'funcao=' + state.funcao + '&' : '') +
    (state.cellphonePrincipal ? 'cellphonePrincipal=' + state.cellphonePrincipal + '&' : '') +
    (state.clientId ? 'client=' + state.clientId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    ''
  );
};
