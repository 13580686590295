/* eslint complexity: ["error", 500] */
import React from 'react';
import axios from 'axios';

import { connect } from 'react-redux';

import {
  getFilterFromSelect,
  getFormFromSelect,
  getListAxios,
  getValueRecursive,
  showFieldsSelectAsync,
} from 'app/shared/util/entity-utils';

import { toast } from 'react-toastify';
import SelectAsync from 'react-select/async';
import { Link, RouteComponentProps } from 'react-router-dom';

import { Button, Col, Row, Table, Label, UncontrolledCollapse, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { setFileData, JhiItemCount } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { IRootState } from 'app/shared/reducers';
import {
  updateEntity,
  getUrlBack,
  getMedicalRecordFileState,
  IMedicalRecordFileBaseState,
  getEntityFiltersURL,
  getEntities,
  IMedicalRecordFileUpdateState as IBaseUpdateState,
  reset,
  getEntity,
  deleteEntity,
  createEntity,
  setBlob,
} from './medical-record-file.reducer';

import { ITEMS_PER_PAGE, IPaginationBaseState, getSortState } from 'app/shared/util/pagination.constants';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

export interface IMedicalRecordFileProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
  entityEmbebedPai?: any;
}

export interface IMedicalRecordFileState extends IMedicalRecordFileBaseState, IPaginationBaseState, IBaseUpdateState {
  showModalForm?: boolean;
  showModalEdit?: number;
  showModalEditView?: boolean;
}

export class MedicalRecordFile extends React.Component<IMedicalRecordFileProps, IMedicalRecordFileState> {
  protected myFormRef: any;
  protected myFormRefNew: any;
  protected myFormRefEdit: any;

  nameFileInput: any;

  filenameFileInput: any;

  constructor(props: IMedicalRecordFileProps) {
    super(props);
    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      listCheckedID: [],
      isCheckedAll: false,
      showModalForm: false,
      showModalEdit: null,
      urlBack: getUrlBack(this.props.location),
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      ...getMedicalRecordFileState(this.props.location),
      medicalRecordId: [
        {
          ...(this.props.entityEmbebedPai ? this.props.entityEmbebedPai : {}),
          value: this.props.match.params['idMedicalRecord'],
          label: this.props.match.params['idMedicalRecord'],
        },
      ],
      baseFilters: 'medicalRecord',
      medicalRecordSelectValue: {
        ...(this.props.entityEmbebedPai ? this.props.entityEmbebedPai : {}),
        value: this.props.match.params['idMedicalRecord'],
        label: this.props.match.params['idMedicalRecord'],
      },

      nameSelectValue: null,
      filenameSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getMedicalRecordFileState(this.props.location),
        medicalRecordId: [
          {
            ...(this.props.entityEmbebedPai ? this.props.entityEmbebedPai : {}),
            value: this.props.match.params['idMedicalRecord'],
            label: this.props.match.params['idMedicalRecord'],
          },
        ],
        baseFilters: 'medicalRecord',
      },
      isNew: false,
    };

    axios
      .get('/api/medical-records/' + this.props.match.params['idMedicalRecord'], {
        headers: {
          'Select-Fields': 'id',
        },
      })
      .then(result => {
        this.setState({
          medicalRecordSelectValue: {
            ...result['data'],
            value: this.props.match.params['idMedicalRecord'],
            label: showFieldsSelectAsync(result['data'], 'id'),
          },
        });
      });
  }

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({ listCheckedID: this.props.medicalRecordFileList.map(medicalRecordFile => medicalRecordFile.id) });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({ listCheckedID: this.props.medicalRecordFileList.map(medicalRecordFile => medicalRecordFile.id) });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  removeEntitySelected() {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  }

  saveEntityMany = () => {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  };

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  cancelFilters = () => {
    this.setState(
      {
        name: '',
        filename: '',
        medicalRecordId: '',
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
        activePage: 0,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();

    const selectFieldsList = ['name', 'filename', 'medicalRecord.id'];
    const { name, filename, medicalRecordId, extraFilters, activePage, itemsPerPage, sort, order } = this.state;
    return this.props.getEntities(
      name,
      filename,
      getFilterFromSelect(medicalRecordId, 'many-to-one'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(',')
    );
  };

  updateEntity = entity => {
    const selectFieldsList = ['name', 'filename', 'medicalRecord.id'];

    const { name, filename, medicalRecordId, extraFilters, activePage, itemsPerPage, sort, order } = this.state;
    this.props.updateEntity(entity, [
      name,
      filename,
      getFilterFromSelect(medicalRecordId, 'many-to-one'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(','),
    ]);
  };

  onBlobChange = (isAnImage, name, fileInput) => event => {
    const fileName = event.target.files[0].name;
    setFileData(event, (contentType, data) => this.props.setBlob(name, data, contentType, fileName), isAnImage);
    const varAux = {};
    varAux[name + 'SelectValue'] = fileName;
    this.setState(varAux);
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined);
  };

  saveEntity = (event: any, errors: any, values: any, isNew: any) => {
    if (!this.state.filenameSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.medicalRecordFile.filename') }));
      return false;
    }

    const selectFieldsList = ['name', 'filename', 'medicalRecord.id'];

    if (errors.length === 0) {
      const { medicalRecordFileEntity } = this.props;
      const _entity = {
        ...medicalRecordFileEntity,
        // ...values,

        medicalRecord: getFormFromSelect(this.state.medicalRecordSelectValue, 'many-to-one'), // many-to-one - otherSide

        name: this.state.nameSelectValue,
        filename: this.state.filenameSelectValue,
      };
      const entity = _entity;

      const { name, filename, medicalRecordId, extraFilters, activePage, itemsPerPage, sort, order } = this.state.fieldsBase;

      if (isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              name,
              filename,
              getFilterFromSelect(medicalRecordId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              name,
              filename,
              getFilterFromSelect(medicalRecordId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
      this.setState({ showModalForm: false });
      this.setState({ showModalEdit: null });
    } else {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.specialty.' + errors[0]) }));
    }
  };

  componentDidUpdate(prevProps) {}

  deleteEntity(medicalRecordFileEntity) {
    MySwal.fire({
      title: <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>,
      html: (
        <Translate contentKey="generadorApp.medicalRecordFile.delete.question" interpolate={{ id: medicalRecordFileEntity.id }}>
          Are you sure you want to delete this MedicalRecordFile?
        </Translate>
      ),
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#e70015',
      cancelButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="ban" />
          &nbsp; <Translate contentKey="generadorApp.medicalRecordFile.btnCancel">Cancel</Translate>
        </>
      ),
      confirmButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="trash" />
          &nbsp;<Translate contentKey="generadorApp.medicalRecordFile.btnDelete">Delete</Translate>
        </>
      ),
    }).then(async response => {
      if (response.isConfirmed) {
        await this.props.deleteEntity(medicalRecordFileEntity.id, { reloadList: false });
        this.getEntities();
        MySwal.fire({
          title: <Translate contentKey="entity.delete.title.confirmed">Confirmed delete operation</Translate>,
          icon: 'success',
        });
      }
    });
  }

  renderNewModal() {
    return (
      <Modal size={'xl'} isOpen={this.state.showModalForm} toggle={() => this.setState({ showModalForm: false })}>
        <ModalHeader toggle={() => this.setState({ showModalForm: false })}>{this.renderHeaderUpdate({}, true)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefNew = el)}
          model={{}}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, true)}
        >
          <ModalBody>{this.renderBodyUpdate({}, true)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalForm: false })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.medicalRecordFile.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefNew && this.myFormRefNew.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.medicalRecordFile.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    );
  }

  getFullEntityFromState(v) {
    return {
      id: v.id,
      name: this.state.nameSelectValue,
      filename: this.state.filenameSelectValue,
      medicalRecord: this.state.medicalRecordSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    let p: any = null;

    p = v.medicalRecord;
    const medicalRecordEntity = p ? { ...p, value: p.id, label: p['id'] ? p.id : '' } : p;

    return {
      nameSelectValue: v.name, // name,
      filenameSelectValue: v.filename, // filename,
      medicalRecordSelectValue: medicalRecordEntity,
    };
  }

  openUpdateModal(v, edit = true) {
    this.props.getEntity(v.id);
    this.setState({
      showModalEdit: v.id,
      showModalEditView: edit,
      ...this.getFullEntitySelectValue(v),
    });
  }

  renderUpdateModal() {
    const { medicalRecordFileEntity, entityEmbebedPai } = this.props;
    return medicalRecordFileEntity && medicalRecordFileEntity.id ? (
      <Modal
        size={'xl'}
        isOpen={this.state.showModalEdit === medicalRecordFileEntity.id}
        toggle={() => this.setState({ showModalEdit: null })}
      >
        <ModalHeader toggle={() => this.setState({ showModalEdit: null })}>
          {this.renderHeaderUpdate(medicalRecordFileEntity, false)}
        </ModalHeader>
        <AvForm
          ref={el => (this.myFormRefEdit = el)}
          model={{
            ...medicalRecordFileEntity,
          }}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, false)}
        >
          <ModalBody>{this.renderBodyUpdate(medicalRecordFileEntity, false)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalEdit: null })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.medicalRecordFile.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefEdit && this.myFormRefEdit.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.medicalRecordFile.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    ) : (
      <> </>
    );
  }

  renderHeaderUpdate(medicalRecordFileEntity, isNew) {
    const { entityEmbebedPai, medicalRecords, loading } = this.props;

    const { filename, filenameContentType, filenameBase64 } = medicalRecordFileEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {isNew ? (
              <Translate contentKey="generadorApp.medicalRecordFile.home.createLabel">Create a MedicalRecordFile</Translate>
            ) : (
              <Translate contentKey="generadorApp.medicalRecordFile.home.editLabel">Edit a MedicalRecordFile</Translate>
            )}
          </span>
        </div>
      </>
    );
  }
  renderBodyUpdate(medicalRecordFileEntity, isNew) {
    const { entityEmbebedPai, medicalRecords, loading } = this.props;

    const { filename, filenameContentType, filenameBase64 } = medicalRecordFileEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
              <Col md="3">
              <Label className="mt-2 label-single-line"for="medical-record-file-id">
                <Translate contentKey="generadorApp.medicalRecordFile.field_id">ID</Translate>
              </Label>
              </Col> */}
                      <Col md="12">
                        <AvInput id="medical-record-file-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'name' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="nameLabel" for="medical-record-file-name">
                              <Translate contentKey="generadorApp.medicalRecordFile.name">Name</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                              value={this.state.nameSelectValue ? this.state.nameSelectValue : ''}
                              id="medical-record-file-name"
                              type="text"
                              name="name"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                      type="hidden"
                      name="name"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'filename' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="6">
                            <AvGroup>
                              <Row>
                                <Col md="12">
                                  <Label className="mt-2 label-single-line" id="filenameLabel" for="filename">
                                    <Translate contentKey="generadorApp.medicalRecordFile.filename">Filename</Translate>(*)
                                  </Label>
                                </Col>
                                <Col md="12">
                                  {filename || filenameBase64 ? (
                                    <div>
                                      <Row>
                                        <Col md="11"></Col>
                                        <Col md="1">
                                          <Button color="danger" onClick={this.clearBlob('filename')}>
                                            <FontAwesomeIcon icon="times-circle" />
                                          </Button>
                                        </Col>
                                      </Row>
                                      <a rel="noopener noreferrer" target={'_blank'} href={`${filename}`}>
                                        <img
                                          src={`${filenameBase64 ? 'data:' + filenameContentType + ';base64,' + filenameBase64 : filename}`}
                                          style={{ maxHeight: '100px' }}
                                        />
                                      </a>
                                      <br />
                                    </div>
                                  ) : null}

                                  <input
                                    className="file_input"
                                    id="file_filename"
                                    type="file"
                                    ref={el => (this.filenameFileInput = el)}
                                    onChange={this.onBlobChange(false, 'filename', this.filenameFileInput)}
                                    accept="*"
                                  />

                                  <label htmlFor="file_filename" className="file_btn_open">
                                    <FontAwesomeIcon icon="upload" />
                                    <Translate contentKey="generadorApp.medicalRecordFile.upload_filename">Upload Filename </Translate>
                                  </label>

                                  <AvInput
                                    onChange={evt => this.setState({ filenameSelectValue: evt.target.value })}
                                    type="hidden"
                                    name="filename"
                                    value={filename}
                                  />
                                </Col>
                              </Row>
                            </AvGroup>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ filenameSelectValue: evt.target.value })}
                      type="hidden"
                      name="filename"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'medicalRecord' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="medical-record-file-medicalRecord">
                              <Translate contentKey="generadorApp.medicalRecordFile.medicalRecord">Medical Record</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="medical-record-file-medicalRecord"
                              name={'medicalRecord'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.medicalRecordSelectValue ? this.state.medicalRecordSelectValue : ''}
                              onChange={options => this.setState({ medicalRecordSelectValue: options })}
                              defaultOptions={this.state.medicalRecordStartSelectOptions ? this.state.medicalRecordStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.medicalRecordStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.medicalRecordStartSelectOptions === undefined) {
                                  const result = await getListAxios('medical-records', {}, 0, 100, 'id,asc', 'id');
                                  this.setState({
                                    medicalRecordStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios('medical-records', { 'id.contains': inputValue }, 0, 100, 'id,asc', 'id');
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="medicalRecord"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}{' '}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  renderHeader() {
    const { medicalRecords, medicalRecordFileList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.medicalRecordFile.home.title">Medical Record Files</Translate>
          </span>
          <Button id="togglerFilterMedicalRecordFile" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.medicalRecordFile.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          &nbsp;
          <Button
            onClick={() =>
              this.setState({
                showModalForm: true,
                nameSelectValue: null,
                filenameSelectValue: null,

                isNew: true,
              })
            }
            color="primary"
            className="btn btn-primary float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="generadorApp.medicalRecordFile.btnNewItem">Create new</Translate>
          </Button>{' '}
          &nbsp;
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/medicalRecord'}>
              <Translate contentKey="generadorApp.medicalRecord.home.title">MedicalRecords</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/medicalRecord/' + this.props.match.params['idMedicalRecord']}>
              {this.state.medicalRecordSelectValue ? this.state.medicalRecordSelectValue.label : this.props.match.params['idMedicalRecord']}
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.medicalRecordFile.home.title">Medical Record Files</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    const { medicalRecords, medicalRecordFileList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <UncontrolledCollapse toggler="#togglerFilterMedicalRecordFile">
          <CardBody>
            <AvForm ref={el => (this.myFormRef = el)} id="form-filter" onSubmit={this.filterEntity}>
              <div className="row mt-1 ml-3 mr-3">
                {this.state.baseFilters !== 'name' ? (
                  <Col md="3" className="col-filter-medical-record-file-name">
                    <Row className="mr-1 mt-1">
                      <Label id="nameLabel" for="medical-record-file-name">
                        <Translate contentKey="generadorApp.medicalRecordFile.name">Name</Translate>
                      </Label>

                      <AvInput type="text" name="name" id="medical-record-file-name" value={this.state.name} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'filename' ? (
                  <Col md="3" className="col-filter-medical-record-file-filename">
                    <Row className="mr-1 mt-1"></Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'medicalRecord' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="medical-record-file-medicalRecord">
                          <Translate contentKey="generadorApp.medicalRecordFile.medicalRecord">Medical Record</Translate>
                        </Label>
                        <SelectAsync
                          id="medical-record-file-medicalRecord"
                          isMulti
                          className={'css-select-control'}
                          name={'medicalRecord'}
                          cacheOptions
                          value={this.state.medicalRecordId}
                          onChange={options => this.setState({ medicalRecordId: options })}
                          defaultOptions={this.state.medicalRecordStartFilter ? this.state.medicalRecordStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.medicalRecordStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.medicalRecordStartFilter === undefined) {
                              const result = await getListAxios('medical-records', {}, 0, 100, 'id,asc', 'id');
                              this.setState({
                                medicalRecordStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios('medical-records', { 'id.contains': inputValue }, 0, 100, 'id,asc', 'id');
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}
              </div>

              <div className="row mb-2 mr-4 justify-content-end">
                <Button color="primary" type="submit">
                  <i className="fa fa-filter" aria-hidden={'true'}></i>&nbsp;
                  <Translate contentKey="generadorApp.medicalRecordFile.btnFilter">Filter</Translate>
                </Button>
                &nbsp;
                <Button color="secondary" onClick={this.cancelFilters}>
                  <FontAwesomeIcon icon="trash-alt" />
                  &nbsp;
                  <Translate contentKey="generadorApp.medicalRecordFile.btnCleanFilter">Clean Filter</Translate>
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </UncontrolledCollapse>
      </>
    );
  }

  renderTable() {
    const { medicalRecords, medicalRecordFileList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {medicalRecordFileList &&
        medicalRecordFileList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null).length > 0 ? (
          <>
            <Table
              id="medical-record-file-table-list"
              responsive
              aria-describedby="medical-record-file-heading"
              className={'table-hover table-striped table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.medicalRecordFile.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'name' ? (
                    <th className="hand" onClick={sortFunction('name')}>
                      <Translate contentKey="generadorApp.medicalRecordFile.name">Name</Translate>
                      <FontAwesomeIcon icon={state.sort === 'name' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'filename' ? (
                    <th className="hand" onClick={sortFunction('filename')}>
                      <Translate contentKey="generadorApp.medicalRecordFile.filename">Filename</Translate>
                      <FontAwesomeIcon icon={state.sort === 'filename' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'medicalRecord' ? (
                    <th>
                      <Translate contentKey="generadorApp.medicalRecordFile.medicalRecord">Medical Record</Translate>
                    </th>
                  ) : null}

                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {medicalRecordFileList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((medicalRecordFile, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.medicalRecordFile.field_id">ID</Translate>
                        </b>
                        {medicalRecordFile.id}
                      </td>

                      {state.baseFilters !== 'name' ? (
                        <td id="name-cell">
                          <b className="visible-xs"> Name </b>

                          {medicalRecordFile.name}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'filename' ? (
                        <td id="filename-cell">
                          <b className="visible-xs"> Filename </b>
                          {medicalRecordFile.filename ? (
                            <div>
                              <a rel="noopener noreferrer" target={'_blank'} href={`${medicalRecordFile.filename}`}>
                                {medicalRecordFile.filenameContentType && medicalRecordFile.filenameContentType.includes('image/') ? (
                                  <img src={`${medicalRecordFile.filename}`} style={{ maxHeight: '30px' }} />
                                ) : (
                                  <Translate contentKey="generadorApp.medicalRecordFile.btnOpen">Open</Translate>
                                )}
                              </a>
                            </div>
                          ) : null}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'medicalRecord' ? (
                        <td id="medicalRecord-cell">
                          <b className="visible-xs"> Medical Record </b>
                          {medicalRecordFile ? (
                            <span data-type-rel="2-many-to-one-other-side">
                              {getValueRecursive(medicalRecordFile, 'medicalRecord.id'.split('.'), '')}
                            </span>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : null}

                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          {hasAnyAuthority(props.userAccount, ['medical-record'], 'edit') ? (
                            <Button onClick={() => this.openUpdateModal(medicalRecordFile)} color="primary" size="sm">
                              <FontAwesomeIcon icon="pencil-alt" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                          {hasAnyAuthority(props.userAccount, ['medical-record'], 'edit') ? (
                            <Button onClick={() => this.deleteEntity(medicalRecordFile)} color="danger" size="sm">
                              <FontAwesomeIcon icon="trash" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.medicalRecordFile.home.notFound">No Medical Record Files found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const { medicalRecords, medicalRecordFileList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={medicalRecordFileList && medicalRecordFileList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const { medicalRecords, medicalRecordFileList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel className="medical-record-file medical-record-file-all-in-one">
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
            {this.renderNewModal()}
            {this.renderUpdateModal()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToProps = ({ medicalRecordFile, ...storeState }: IRootState) => ({
  medicalRecords: storeState.medicalRecord.entities,
  medicalRecordFileList: medicalRecordFile.entities,
  totalItems: medicalRecordFile.totalItems,
  userAccount: storeState.authentication.account,
  loading: medicalRecordFile.loading,

  // UPDATE
  medicalRecordFileEntity: medicalRecordFile.entity,
});

export const mapDispatchToProps = {
  getEntities,

  updateEntity,
  reset,

  // UPDATE
  getEntity,
  setBlob,
  createEntity,

  // DELETE
  deleteEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MedicalRecordFile);
