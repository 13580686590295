import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import PadAttendance from './extended/pad-attendance';

import PadAttendanceDetail from './pad-attendance-detail';

import PadAttendanceUpdate from './pad-attendance';

import PadAttendanceFile from '../pad-attendance-file/index';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute path={`${match.path}/:idPadAttendance/pad-attendance-file`} component={PadAttendanceFile} />

      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={PadAttendanceUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={PadAttendanceUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={PadAttendanceDetail} />
      <ErrorBoundaryRoute path={match.path} component={PadAttendance} />
    </Switch>

    <Switch>
      {/* <ErrorBoundaryRoute path={`${match.path}/:idPadAttendance/pad-attendance-file`} component={PadAttendanceFile} /> */}
    </Switch>
  </>
);

export default Routes;
