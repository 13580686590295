/* eslint complexity: ["error", 500] */
import React from 'react';
import { connect } from 'react-redux';
import { Panel, PanelBody, PanelHeader } from 'app/shared/layout/panel/panel';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import {
  IPatientDoctorPrescriptionUpdateProps,
  mapDispatchToProps,
  mapStateToProps,
  PatientDoctorPrescriptionUpdate,
} from '../patient-doctor-prescription-update';
import { IRootState } from 'app/shared/reducers';
import { Button, Col, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import { translate, Translate } from 'app/config/translate-component';
import { getEntity as getPatient } from 'app/entities/patient/patient.reducer';
import { getEntityFiltersURL } from 'app/entities/patient-doctor-prescription/patient-doctor-prescription.reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { getReport } from 'app/shared/reports/jhi-reports.reducer';
import {
  mapDispatchToPropsExtended as mapDispatchToPropsPatDrPrescrProcd,
  PatDrPrescrProcdExtended as PatDrPrescrProcd,
} from 'app/entities/pat-dr-prescr-procd/extended/pat-dr-prescr-procd';
import { MedicalReport } from 'app/entities/patient-doctor-prescription/report/medical';
import moment, { Moment } from 'moment';
import { Status } from 'app/shared/model/enumerations/status.model';
import Axios from 'axios';
import { borderRadius } from 'react-select/src/theme';

export interface IUpdateExtendedState {
  showModalReport?: boolean;
  showModalPerid?: boolean;
  dataInicial?: Moment;
  dataFinal?: Moment;
  infos: any
  selectPerid: number;
}

export class PatientDoctorPrescriptionExtendedUpdate extends PatientDoctorPrescriptionUpdate {
  constructor(props: IPatientDoctorPrescriptionUpdateProps) {
    super(props);
    this.state = {
      ...this.state,
      dataInicial: moment().startOf('month'),
      dataFinal: moment().endOf('month'),
      infos: null,
      showModalPerid: false,
      selectPerid: 7,

    };
  }
  renderHeader() {
    const { updating } = this.props;

    function myFunction(e) {
      // Código a ser executado quando a seleção mudar
      console.log('Seleção alterada', e);
    }
    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.patientDoctorPrescription.home.createLabel">Create a PatientDoctorPrescription</Translate>
            ) : (
              <Translate contentKey="generadorApp.patientDoctorPrescription.home.editLabel">Edit a PatientDoctorPrescription</Translate>
            )}
          </span>

          <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.patientDoctorPrescription.btnSave">Save</Translate>
          </Button>
          <Button
            className={'float-right'}
            color="dark"
            onClick={() => this.setState({showModalPerid: true})}
          >
            <FontAwesomeIcon icon="file-pdf" />
            &nbsp; Visualizar PDF
          </Button>
          {this.state.showModalPerid === true ?
            <div style={{position: "absolute", height: "80%", width: "80%", backgroundColor: "transparent" , zIndex: 10, display: "flex", justifyContent: "center", alignItems: "center"}}>
              <div style={{height: 200, width: 400, backgroundColor: "white", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", borderRadius: 10, boxShadow: "0px 0px 5px 2px rgba(0, 0, 0, 0.3)"}}>
                <select style={{height: 50, width: "80%", borderColor: "#c4c4c4"}} value={this.state.selectPerid} onChange={(e) => this.setState({selectPerid: parseInt(e.target.value)})}>
                  <option value={30}>Dia 1 ao dia 7</option>
                  <option value={15}>Dia 1 ao dia 14</option>
                  <option value={10}>Dia 1 ao dia 21</option>
                  <option value={8}>Dia 1 ao dia 28</option>
                  <option value={7}>Dia 1 ao dia 31</option>
                </select>
                <div style={{display: "flex", flexDirection: "row"}}>
                <Button
            className={'float-right'}
            color="dark"
            style={{margin: 20}} 
            onClick={() => {
              new Promise(resolve => {
                resolve(
                  this.props.getReport('medical-prescription', {
                    id: this.props.patientDoctorPrescriptionEntity.id,
                    // initialDate: "'" + this.state.dataInicial.format('YYYY-MM-DD') + "'",
                    // finalDate: "'" + this.state.dataFinal.format('YYYY-MM-DD') + "'",
                    initialDate: "'" + moment().startOf('month').format('YYYY-MM-DD') + "'",
                    finalDate: "'" + moment().endOf('month').format('YYYY-MM-DD') + "'",
                  })
                );
              }).then(resultEntity => {
                if (resultEntity.value.data.result.length === 0) {
                  toast.error(translate('generadorApp.prescription.emptyProcediment'));
                } else {
                  this.setState({
                    showModalReport: true,
                  });
                }
              });
            }}
          >
            <FontAwesomeIcon icon="file-pdf" />
            &nbsp; Abrir
          </Button>
              <Button  style={{margin: 20}} color="blue"  onClick={() => this.setState({showModalPerid: false})}>fechar</Button>
              </div>
              </div>
            </div>
            :

            null
          }
          {!this.state.isNew ? (
            <>
              <Button
                color="primary"
                id="next-entity"
                disabled={updating}
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.observation,
                    this.state.fieldsBase.patientId,
                    this.state.fieldsBase.offset * 1 + 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 + 1 },
                    });
                    this.props.history.push(
                      '/patient/' + myresult.value.data[0]['id'] + '?' + this.getFiltersURL(this.state.fieldsBase.offset * 1 + 1)
                    );
                  } else {
                    toast.error(
                      translate('generadorApp.last.item', { fieldName: translate('generadorApp.patientDoctorPrescription.detail.title') })
                    );
                  }
                }}
                className="float-right jh-create-entity"
              >
                {'>>'}
              </Button>

              <Button
                color="primary"
                id="prev-entity"
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.observation,
                    this.state.fieldsBase.patientId,
                    this.state.fieldsBase.offset * 1 - 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 - 1 },
                    });
                    this.props.history.push(
                      '/patient/' + myresult.value.data[0]['id'] + '?' + this.getFiltersURL(this.state.fieldsBase.offset * 1 - 1)
                    );
                  } else {
                    toast.error(
                      translate('generadorApp.first.item', { fieldName: translate('generadorApp.patientDoctorPrescription.detail.title') })
                    );
                  }
                }}
                disabled={updating}
                className="float-right jh-create-entity"
              >
                {'<<'}
              </Button>
            </>
          ) : (
            <> </>
          )}

          <Button
            tag={Link}
            id="cancel-save"
            to={'/patient?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.patientDoctorPrescription.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/patient?' + getEntityFiltersURL(this.state.fieldsBase)}>
              <Translate contentKey="generadorApp.patient.home.title">Patients</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            {this.state.patientSelectValue ? this.state.patientSelectValue.label : this.props.match.params['idPatient']}
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.patientDoctorPrescription.detail.title">Patient doctor prescription edit</Translate>
          </li>
        </ol>
      </>
    );
  }

  UNSAFE_componentWillMount() {
    super.UNSAFE_componentWillMount();
    this.props.getPatient(parseInt(this.props.match.params['idPatient'], 10));
  }

  renderBody() {
    const { patientDoctorPrescriptionEntity, loading } = this.props;
    const { isNew } = this.state;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const patientAllergy = this.props.patientEntity.patientAllergy && this.props.patientEntity.patientAllergy.map(v => v.id);
    const patientCid = this.props.patientEntity.patientCid && this.props.patientEntity.patientCid.map(v => v.id);

    const mapDispatchToPropsPatDrPrescrProcdList: any = {};
    Object.keys(mapDispatchToPropsPatDrPrescrProcd).map(v => {
      mapDispatchToPropsPatDrPrescrProcdList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'PatDrPrescrProcd')) {
          this.setState({ functionEmbebed: [...this.state.functionEmbebed, v + 'PatDrPrescrProcd'] }, this.props[v + 'PatDrPrescrProcd']);
        }
      };
    });

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="patient-doctor-prescription-id">
                  <Translate contentKey="generadorApp.patientDoctorPrescription.field_id">ID</Translate>
                </Label>
                </Col> */}
                      <Col md="12">
                        <AvInput id="patient-doctor-prescription-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  <Col md={12}>
                    <Row className="justify-content-center">
                      <Col md={11}>
                        <Row className="justify-content-center">
                          <Col md="4">
                            <AvGroup>
                              <Label className="mt-2" id="technicalManagerLabel" for="patient-doctor-prescription-technicalManager">
                                <Translate contentKey="generadorApp.patient.detail.title">Edit a PatientNursingPrescription</Translate>
                              </Label>
                              <AvInput
                                value={this.props.patientEntity ? this.props.patientEntity.name : ''}
                                id="patient-doctor-prescription-technicalManager"
                                type="text"
                                name="technicalManager"
                                disabled
                              />
                            </AvGroup>
                          </Col>
                          <Col md="4">
                            <AvGroup>
                              <Label className="mt-2" id="technicalManagerLabel" for="patient-doctor-prescription-technicalManager">
                                <Translate contentKey="generadorApp.client.detail.title">Edit a PatientNursingPrescription</Translate>
                              </Label>
                              <AvInput
                                value={
                                  this.props.patientEntity && this.props.patientEntity.client
                                    ? this.props.patientEntity.client.fantasyName
                                    : ''
                                }
                                id="patient-doctor-prescription-technicalManager"
                                type="text"
                                name="technicalManager"
                                disabled
                              />
                            </AvGroup>
                          </Col>
                          <Col md="4">
                            <AvGroup>
                              <Label className="mt-2" id="technicalManagerLabel" for="patient-doctor-prescription-technicalManager">
                                <Translate contentKey="generadorApp.patient.birthDate">Birth Date</Translate>
                              </Label>
                              <AvInput
                                value={this.props.patientEntity ? moment(this.props.patientEntity.birthDate).format('DD/MM/YYYY') : ''}
                                id="patient-doctor-prescription-technicalManager"
                                type="text"
                                name="technicalManager"
                                disabled
                              />
                            </AvGroup>
                          </Col>

                          <Col md={6}>
                            <AvGroup>
                              <Label className="mt-2" id="observationLabel" for="patient-doctor-prescription-observation">
                                <Translate contentKey="generadorApp.patientDoctorPrescription.observation">Observations</Translate>
                              </Label>
                              <input
                                onChange={evt => this.setState({ observationSelectValue: evt.target.value })}
                                value={this.state.observationSelectValue ? this.state.observationSelectValue : ''}
                                id="patient-doctor-prescription-observation"
                                type="text"
                                name="observation"
                                className={'form-control'}
                              />
                            </AvGroup>
                          </Col>
                          <Col md={6}>
                            <AvGroup>
                              <Label className="mt-2" id="technicalManagerLabel" for="patient-doctor-prescription-technicalManager">
                                <Translate contentKey="generadorApp.patientDoctorPrescription.technicalManager">
                                  Technical Manager
                                </Translate>
                              </Label>
                              <AvInput
                                value={this.props.userAccount.whiteLabel ? this.props.userAccount.whiteLabel.technicalManager : ''}
                                id="patient-doctor-prescription-technicalManager"
                                type="text"
                                name="technicalManager"
                                disabled
                              />
                            </AvGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12}>
                    <Row className="justify-content-center">
                      <Col md={11}>
                        <Row className="justify-content-center">
                          <Col md={6}>
                            <p>
                              <Translate contentKey="generadorApp.patientAllergy.home.title">patientAllergy</Translate>
                            </p>
                            <ul>
                              {this.props.patientEntity.patientAllergy &&
                                this.props.patientEntity.patientAllergy
                                  .filter(v => patientAllergy && patientAllergy.includes(v.id) && !v.deletedAt)
                                  .map((v1, i1) => <li key={i1}>{v1.name}</li>)}
                            </ul>
                          </Col>
                          <Col md={6}>
                            <p>
                              <Translate contentKey="generadorApp.patientCid.home.title">PatientCid</Translate>
                            </p>
                            <ul>
                              {this.props.patientEntity.patientCid &&
                                this.props.patientEntity.patientCid
                                  .filter(v => patientCid && patientCid.includes(v.id) && !v.deletedAt && v.status === Status.ATIVO)
                                  .map((v1, i1) => (
                                    <li key={i1}>
                                      <span>
                                        {
                                          <>
                                            <b>{v1.cid.code}</b> {v1.cid.name}
                                          </>
                                        }
                                      </span>
                                    </li>
                                  ))}
                            </ul>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  {baseFilters !== 'patDrPrescrProcd' ? (
                    <Col md="12">
                      <div className="entityEmbebedList">
                        <PatDrPrescrProcd
                          entityEmbebedPai={patientDoctorPrescriptionEntity}
                          history={this.props.history}
                          location={{
                            pathname: `/patient-doctor-prescription/${this.props.match.params['id']}/pat-dr-prescr-procd/`,
                            search: '',
                            hash: '',
                            state: undefined,
                            key: 'q2tbhd',
                          }}
                          match={{
                            path: `/patient-doctor-prescription/:idPatientDoctorPrescription/pat-dr-prescr-procd/:id`,
                            url: `/patient-doctor-prescription/${this.props.match.params['id']}/pat-dr-prescr-procd/`,
                            isExact: true,
                            params: {
                              idPatientDoctorPrescription: this.props.match.params['id'],
                              url: `/patient-doctor-prescription/${this.props.match.params['id']}/pat-dr-prescr-procd/`,
                            },
                          }}
                          dataInicial={this.state.dataInicial}
                          dataFinal={this.state.dataFinal}
                          setDataInicial={v => this.setState({ dataInicial: v })}
                          setDataFinal={v => this.setState({ dataFinal: v })}
                          {...this.props.mapStateToPropsPatDrPrescrProcd}
                          {...mapDispatchToPropsPatDrPrescrProcdList}
                          getPatientDoctorPrescription={this.props.getEntity}
                          resetPatientDoctorPrescription={this.props.reset}
                          getEntities={this.patDrPrescrProcdGetEntities}
                          updateEntity={this.patDrPrescrProcdUpdateEntity}
                          getEntity={this.patDrPrescrProcdGetEntity}
                          createEntity={this.patDrPrescrProcdCreateEntity}
                          deleteEntity={this.patDrPrescrProcdDeleteEntity}
                          reset={this.patDrPrescrProcdReset}
                          patDrPrescrProcdList={patientDoctorPrescriptionEntity.patDrPrescrProcd}
                          totalItems={this.props.patDrPrescrProcdListEmbed ? this.props.patDrPrescrProcdListEmbed.length : 0}
                          loading={this.props.loading}
                          updating={this.props.updating}
                          patDrPrescrProcdEntity={this.props.patDrPrescrProcdSelected}
                        />
                      </div>
                    </Col>
                  ) : (
                    <AvInput type="hidden" name="patDrPrescrProcd" value={this.state.fieldsBase[baseFilters + 'Id']} />
                  )}{' '}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { patientDoctorPrescriptionEntity, patDrPrescrProcds, patients, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        {this.renderModal()}
        <AvForm
          model={
            isNew
              ? {}
              : {
                ...patientDoctorPrescriptionEntity,
              }
          }
          onSubmit={this.saveEntity}
        >
          <Panel>
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }





  renderModal() {
    if (!this.props.report || this.props.report.length === 0 || this.state.showModalReport !== true) return <> </>;

    const numCalls = 5; // Número máximo de chamadas

    const getObs = () => {
      Axios.get(`${"api/patient-doctor-prescriptions"}/${this.props.report[0].cod}`)
        .then((res) => {
          const data = res.data; // Armazena o resultado da requisição na variável "data"
          this.setState({ infos: data }); // Atualiza o estado com o resultado da requisição
        })
        .catch((err) => {
          console.log(err);
        });
    };

    // Chama a função getObs 5 vezes usando um loop for
    for (let i = 0; i < numCalls; i++) {
      getObs();
    }


    return (
      <Modal size="xl" isOpen toggle={() => this.setState({ showModalReport: false })}>
        <ModalBody id="generadorApp.quote.delete.question">
          {/* <MedicalReport data={this.props.report} dataInicial={this.state.dataInicial.toDate()} dataFinal={this.state.dataFinal.toDate()} /> */}
          <MedicalReport
            data={this.props.report}
            dataInicial={moment().startOf('month').toDate()}
            dataFinal={moment().endOf('month').toDate()}
            infos={this.state.infos}
            selectPerid={this.state.selectPerid}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => this.setState({ showModalReport: false })}>
            <FontAwesomeIcon icon="ban" />
            &nbsp;
            <Translate contentKey="generadorApp.quote.btnCancel">Close</Translate>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}



export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);

  return {
    ...listBase,
    userAccount: storeState.authentication.account,
    patientEntity: storeState.patient.entity,
    report:
      storeState.jhiReports.reportsList && storeState.jhiReports.reportsList['medical-prescription']
        ? storeState.jhiReports.reportsList['medical-prescription']
        : [],
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
  getPatient,
  getReport,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;
export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(PatientDoctorPrescriptionExtendedUpdate);
