/* eslint complexity: ["error", 500] */
import React from 'react';
import axios from 'axios';

import { connect } from 'react-redux';

import {
  getFilterFromSelect,
  getFormFromSelect,
  getListAxios,
  getValueRecursive,
  showFieldsSelectAsync,
} from 'app/shared/util/entity-utils';
import Select from 'react-select';
import { toast } from 'react-toastify';
import SelectAsync from 'react-select/async';
import { Link, RouteComponentProps } from 'react-router-dom';

import { Button, Col, Row, Table, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { JhiItemCount } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { IRootState } from 'app/shared/reducers';
import {
  updateEntity,
  getUrlBack,
  getMedicineMaterialStatusState,
  IMedicineMaterialStatusBaseState,
  getEntityFiltersURL,
  getEntities,
  IMedicineMaterialStatusUpdateState as IBaseUpdateState,
  reset,
  getEntity,
  deleteEntity,
  createEntity,
} from './medicine-material-status.reducer';

import { APP_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE, IPaginationBaseState, getSortState } from 'app/shared/util/pagination.constants';

import { StatePropsExtended, DispatchPropsExtended, IExtendedState, IExtendedProps } from './extended/medicine-material-status';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';
import moment from 'moment';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

export interface IMedicineMaterialStatusProps
  extends StateProps,
    DispatchProps,
    StatePropsExtended,
    DispatchPropsExtended,
    IExtendedProps,
    RouteComponentProps<{ url: string }> {
  entityEmbebedPai?: any;
}

export interface IMedicineMaterialStatusState
  extends IMedicineMaterialStatusBaseState,
    IPaginationBaseState,
    IBaseUpdateState,
    IExtendedState {
  showModalForm?: boolean;
  showModalEdit?: number;
  showModalEditView?: boolean;
}

export class MedicineMaterialStatus extends React.Component<IMedicineMaterialStatusProps, IMedicineMaterialStatusState> {
  protected myFormRef: any;
  protected myFormRefNew: any;
  protected myFormRefEdit: any;

  statusFileInput: any;

  observationFileInput: any;

  constructor(props: IMedicineMaterialStatusProps) {
    super(props);
    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      listCheckedID: [],
      isCheckedAll: false,
      showModalForm: false,
      showModalEdit: null,
      urlBack: getUrlBack(this.props.location),
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      ...getMedicineMaterialStatusState(this.props.location),
      medicineMaterialId: [
        {
          ...(this.props.entityEmbebedPai ? this.props.entityEmbebedPai : {}),
          value: this.props.match.params['idMedicineMaterial'],
          label: this.props.match.params['idMedicineMaterial'],
        },
      ],
      baseFilters: 'medicineMaterial',
      medicineMaterialSelectValue: {
        ...(this.props.entityEmbebedPai ? this.props.entityEmbebedPai : {}),
        value: this.props.match.params['idMedicineMaterial'],
        label: this.props.match.params['idMedicineMaterial'],
      },

      statusSelectValue: null,
      observationSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getMedicineMaterialStatusState(this.props.location),
        medicineMaterialId: [
          {
            ...(this.props.entityEmbebedPai ? this.props.entityEmbebedPai : {}),
            value: this.props.match.params['idMedicineMaterial'],
            label: this.props.match.params['idMedicineMaterial'],
          },
        ],
        baseFilters: 'medicineMaterial',
      },
      medicineMaterialName: null,
      isNew: false,
    };

    axios
      .get('/api/medicine-materials/' + this.props.match.params['idMedicineMaterial'], {
        headers: {
          'Select-Fields': 'name',
        },
      })
      .then(result => {
        this.setState({
          medicineMaterialSelectValue: {
            ...result['data'],
            value: this.props.match.params['idMedicineMaterial'],
            label: showFieldsSelectAsync(result['data'], 'name'),
          },
        });
      });
  }

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({ listCheckedID: this.props.medicineMaterialStatusList.map(medicineMaterialStatus => medicineMaterialStatus.id) });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({ listCheckedID: this.props.medicineMaterialStatusList.map(medicineMaterialStatus => medicineMaterialStatus.id) });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  removeEntitySelected() {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  }

  saveEntityMany = () => {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  };

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  cancelFilters = () => {
    this.setState(
      {
        status: '',
        observation: '',
        medicineMaterialId: '',
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
        activePage: 0,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();

    const selectFieldsList = ['status', 'observation', 'medicineMaterial.name', 'createdBy.login', 'createdDate'];
    const { status, observation, medicineMaterialId, extraFilters, activePage, itemsPerPage, sort, order } = this.state;
    return this.props.getEntities(
      status,
      observation,
      getFilterFromSelect(medicineMaterialId, 'many-to-one'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(',')
    );
  };

  updateEntity = entity => {
    const selectFieldsList = ['status', 'observation', 'medicineMaterial.name', 'createdBy.login', 'createdDate'];

    const { status, observation, medicineMaterialId, extraFilters, activePage, itemsPerPage, sort, order } = this.state;
    this.props.updateEntity(entity, [
      status,
      observation,
      getFilterFromSelect(medicineMaterialId, 'many-to-one'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(','),
    ]);
  };

  saveEntity = (event: any, errors: any, values: any, isNew: any) => {
    const selectFieldsList = ['status', 'observation', 'medicineMaterial.name', 'createdBy.login', 'createdDate'];

    if (errors.length === 0) {
      const { medicineMaterialStatusEntity } = this.props;
      const _entity = {
        ...medicineMaterialStatusEntity,
        // ...values,

        medicineMaterial: getFormFromSelect(this.state.medicineMaterialSelectValue, 'many-to-one'), // many-to-one - otherSide

        status: this.state.statusSelectValue === null ? 'INATIVADO' : this.state.statusSelectValue,
        observation: this.state.observationSelectValue,
      };
      const entity = _entity;

      const { status, observation, medicineMaterialId, extraFilters, activePage, itemsPerPage, sort, order } = this.state.fieldsBase;

      if (isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              status,
              observation,
              getFilterFromSelect(medicineMaterialId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              status,
              observation,
              getFilterFromSelect(medicineMaterialId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
      this.setState({ showModalForm: false });
      this.setState({ showModalEdit: null });
    } else {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.specialty.' + errors[0]) }));
    }
  };

  componentDidUpdate(prevProps) {}

  deleteEntity(medicineMaterialStatusEntity) {
    MySwal.fire({
      title: <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>,
      html: (
        <Translate contentKey="generadorApp.medicineMaterialStatus.delete.question" interpolate={{ id: medicineMaterialStatusEntity.id }}>
          Are you sure you want to delete this MedicineMaterialStatus?
        </Translate>
      ),
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#e70015',
      cancelButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="ban" />
          &nbsp; <Translate contentKey="generadorApp.medicineMaterialStatus.btnCancel">Cancel</Translate>
        </>
      ),
      confirmButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="trash" />
          &nbsp;<Translate contentKey="generadorApp.medicineMaterialStatus.btnDelete">Delete</Translate>
        </>
      ),
    }).then(async response => {
      if (response.isConfirmed) {
        await this.props.deleteEntity(medicineMaterialStatusEntity.id, { reloadList: false });
        this.getEntities();
        MySwal.fire({
          title: <Translate contentKey="entity.delete.title.confirmed">Confirmed delete operation</Translate>,
          icon: 'success',
        });
      }
    });
  }

  renderNewModal() {
    return (
      <Modal size={'xl'} isOpen={this.state.showModalForm} toggle={() => this.setState({ showModalForm: false })}>
        <ModalHeader toggle={() => this.setState({ showModalForm: false })}>{this.renderHeaderUpdate({}, true)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefNew = el)}
          model={{}}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, true)}
        >
          <ModalBody>{this.renderBodyUpdate({}, true)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalForm: false })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.medicineMaterialStatus.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefNew && this.myFormRefNew.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.medicineMaterialStatus.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    );
  }

  getFullEntityFromState(v) {
    return {
      id: v.id,
      status: this.state.statusSelectValue,
      observation: this.state.observationSelectValue,
      medicineMaterial: this.state.medicineMaterialSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    let p: any = null;

    p = v.medicineMaterial;
    const medicineMaterialEntity = p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : p;

    return {
      statusSelectValue: v.status, // status,
      observationSelectValue: v.observation, // observation,
      medicineMaterialSelectValue: medicineMaterialEntity,
    };
  }

  openUpdateModal(v, edit = true) {
    this.props.getEntity(v.id);
    this.setState({
      showModalEdit: v.id,
      showModalEditView: edit,
      ...this.getFullEntitySelectValue(v),
    });
  }

  renderUpdateModal() {
    const { medicineMaterialStatusEntity, entityEmbebedPai } = this.props;
    return medicineMaterialStatusEntity && medicineMaterialStatusEntity.id ? (
      <Modal
        size={'xl'}
        isOpen={this.state.showModalEdit === medicineMaterialStatusEntity.id}
        toggle={() => this.setState({ showModalEdit: null })}
      >
        <ModalHeader toggle={() => this.setState({ showModalEdit: null })}>
          {this.renderHeaderUpdate(medicineMaterialStatusEntity, false)}
        </ModalHeader>
        <AvForm
          ref={el => (this.myFormRefEdit = el)}
          model={{
            ...medicineMaterialStatusEntity,
          }}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, false)}
        >
          <ModalBody>{this.renderBodyUpdate(medicineMaterialStatusEntity, false)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalEdit: null })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.medicineMaterialStatus.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefEdit && this.myFormRefEdit.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.medicineMaterialStatus.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    ) : (
      <> </>
    );
  }

  renderHeaderUpdate(medicineMaterialStatusEntity, isNew) {
    const { entityEmbebedPai, medicineMaterials, loading } = this.props;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {isNew ? (
              <Translate contentKey="generadorApp.medicineMaterialStatus.home.createLabel">Create a MedicineMaterialStatus</Translate>
            ) : (
              <Translate contentKey="generadorApp.medicineMaterialStatus.home.editLabel">Edit a MedicineMaterialStatus</Translate>
            )}
          </span>
        </div>
      </>
    );
  }
  renderBodyUpdate(medicineMaterialStatusEntity, isNew) {
    const { entityEmbebedPai, medicineMaterials, loading } = this.props;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
              <Col md="3">
              <Label className="mt-2 label-single-line"for="medicine-material-status-id">
                <Translate contentKey="generadorApp.medicineMaterialStatus.field_id">ID</Translate>
              </Label>
              </Col> */}
                      <Col md="12">
                        <AvInput id="medicine-material-status-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'status' ? (
                    <Col md="12">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="statusLabel" for="medicine-material-status-status">
                              <Translate contentKey="generadorApp.medicineMaterialStatus.status">Status</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="medicine-material-status-status"
                              className={'css-select-control'}
                              value={
                                this.state.statusSelectValue
                                  ? {
                                      value: this.state.statusSelectValue,
                                      label: translate('generadorApp.Status.' + this.state.statusSelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.medicineMaterialStatus.status') }
                              }
                              options={[
                                { value: 'INATIVADO', label: translate('generadorApp.Status.INATIVADO') },
                                { value: 'ATIVO', label: translate('generadorApp.Status.ATIVO') },
                              ]}
                              onChange={(options: any) => this.setState({ statusSelectValue: options['value'] })}
                              name="status"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ statusSelectValue: evt.target.value })}
                      type="hidden"
                      name="status"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'observation' ? (
                    <Col md="12">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="observationLabel" for="medicine-material-status-observation">
                              <Translate contentKey="generadorApp.medicineMaterialStatus.observation">Observation</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ observationSelectValue: evt.target.value })}
                              value={this.state.observationSelectValue ? this.state.observationSelectValue : ''}
                              id="medicine-material-status-observation"
                              type="text"
                              name="observation"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ observationSelectValue: evt.target.value })}
                      type="hidden"
                      name="observation"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'medicineMaterial' ? (
                    <Col md="12">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="medicine-material-status-medicineMaterial">
                              <Translate contentKey="generadorApp.medicineMaterialStatus.medicineMaterial">Medicine Material</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="medicine-material-status-medicineMaterial"
                              name={'medicineMaterial'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.medicineMaterialSelectValue ? this.state.medicineMaterialSelectValue : ''}
                              onChange={options => this.setState({ medicineMaterialSelectValue: options })}
                              defaultOptions={
                                this.state.medicineMaterialStartSelectOptions ? this.state.medicineMaterialStartSelectOptions : []
                              }
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.medicineMaterialStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.medicineMaterialStartSelectOptions === undefined) {
                                  const result = await getListAxios('medicine-materials', {}, 0, 100, 'name,asc', 'name');
                                  this.setState({
                                    medicineMaterialStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'medicine-materials',
                                  { 'name.contains': inputValue },
                                  0,
                                  100,
                                  'name,asc',
                                  'name'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="medicineMaterial"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}{' '}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  renderHeader() {
    const { medicineMaterials, medicineMaterialStatusList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.medicineMaterialStatus.home.title">Kit status Histories</Translate>
          </span>

          <Button className="float-right jh-create-entity" onClick={() => this.setState({ showModalForm: true })} color="primary" size="md">
            <FontAwesomeIcon icon="plus" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.medicineMaterialStatus.listTopButtons.new">changeStatus</Translate>
            </span>
          </Button>
          <Button className="float-right jh-create-entity" onClick={() => this.props.history.goBack()} color="info" size="md">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.medicineMaterialStatus.listTopButtons.back">Back</Translate>
            </span>
          </Button>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/medicineMaterial'}>
              <Translate contentKey="generadorApp.medicineMaterial.home.title">MedicineMaterials</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/medicineMaterial/' + this.props.match.params['idMedicineMaterial']}>
              {this.state.medicineMaterialSelectValue
                ? this.state.medicineMaterialSelectValue.label
                : this.props.match.params['idMedicineMaterial']}
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.medicineMaterialStatus.home.title">Kit status Histories</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    return <> </>;
  }

  renderTable() {
    const { medicineMaterials, medicineMaterialStatusList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {medicineMaterialStatusList &&
        medicineMaterialStatusList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null).length > 0 ? (
          <>
            <Table
              id="medicine-material-status-table-list"
              responsive
              aria-describedby="medicine-material-status-heading"
              className={'table-hover table-striped table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  {state.baseFilters !== 'status' ? (
                    <th className="hand" onClick={sortFunction('status')}>
                      <Translate contentKey="generadorApp.medicineMaterialStatus.status">Status</Translate>
                      <FontAwesomeIcon icon={state.sort === 'status' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'observation' ? (
                    <th className="hand" onClick={sortFunction('observation')}>
                      <Translate contentKey="generadorApp.medicineMaterialStatus.observation">Observation</Translate>
                      <FontAwesomeIcon icon={state.sort === 'observation' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'medicineMaterial' ? (
                    <th>
                      <Translate contentKey="generadorApp.medicineMaterialStatus.medicineMaterial">Medicine Material</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'createdBy' ? (
                    <th>
                      <Translate contentKey="generadorApp.medicineMaterialStatus.createdBy">Created By</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'createdDate' ? (
                    <th className="hand" onClick={sortFunction('createdDate')}>
                      <Translate contentKey="generadorApp.medicineMaterialStatus.createdDate">Created Date</Translate>
                      <FontAwesomeIcon icon={state.sort === 'createdDate' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {medicineMaterialStatusList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((medicineMaterialStatus, i) => (
                    <tr key={`entity-${i}`}>
                      {state.baseFilters !== 'status' ? (
                        <td id="status-cell">
                          <b className="visible-xs"> Status </b>
                          {medicineMaterialStatus.status ? (
                            <Translate contentKey={`generadorApp.Status.${medicineMaterialStatus.status}`} />
                          ) : (
                            <> </>
                          )}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'observation' ? (
                        <td id="observation-cell">
                          <b className="visible-xs"> Observation </b>

                          {medicineMaterialStatus.observation}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'medicineMaterial' ? (
                        <td id="medicineMaterial-cell">
                          <b className="visible-xs"> Medicine Material </b>
                          {medicineMaterialStatus ? (
                            <span data-type-rel="2-many-to-one-other-side">
                              {getValueRecursive(medicineMaterialStatus, 'medicineMaterial.name'.split('.'), '')}
                            </span>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'createdBy' ? (
                        <td id="createdBy-cell">
                          <b className="visible-xs"> Created By </b>
                          {medicineMaterialStatus.createdBy ? medicineMaterialStatus.createdBy.login : ''}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'createdDate' ? (
                        <td id="createdDate-cell">
                          <b className="visible-xs"> Created Date </b>
                          {medicineMaterialStatus.createdDate ? moment(medicineMaterialStatus.createdDate).format(APP_DATE_FORMAT) : ''}
                        </td>
                      ) : null}
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.medicineMaterialStatus.home.notFound">No Kit status Histories found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const { medicineMaterials, medicineMaterialStatusList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={medicineMaterialStatusList && medicineMaterialStatusList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const { medicineMaterials, medicineMaterialStatusList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel className="medicine-material-status medicine-material-status-all-in-one">
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderTable()}
            {this.renderNewModal()}
            {this.renderUpdateModal()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToProps = ({ medicineMaterialStatus, ...storeState }: IRootState) => ({
  medicineMaterials: storeState.medicineMaterial.entities,
  medicineMaterialStatusList: medicineMaterialStatus.entities,
  totalItems: medicineMaterialStatus.totalItems,
  userAccount: storeState.authentication.account,
  loading: medicineMaterialStatus.loading,

  // UPDATE
  medicineMaterialStatusEntity: medicineMaterialStatus.entity,
});

export const mapDispatchToProps = {
  getEntities,

  updateEntity,
  reset,

  // UPDATE
  getEntity,
  createEntity,

  // DELETE
  deleteEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MedicineMaterialStatus);
