import { Translate, translate } from 'app/config/translate-component';
import React from 'react';
import SelectAsync from 'react-select/async';
import { AvGroup } from 'availity-reactstrap-validation';

export interface IClComRulerProps {
  baseState?: any;
  baseProps?: any;
  entityBase?: any;
  setState?: any;
}
import { Col, Row, Label } from 'reactstrap';
import { getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';

const LevelGroupCategory = (props: IClComRulerProps) => {
  const { clComRulerList, clComRulerEntity } = props.baseProps;
  let specialityRegistersInRulersIds = [];
  let medicineMaterialInRulersIds = [];
  let productServiceRegistersInRulersIds = [];
  if (clComRulerList && clComRulerList.length > 0) {
    specialityRegistersInRulersIds = clComRulerList
      .filter(
        v =>
          v.specialty &&
          v.specialty.category &&
          v.specialty.category.levelGroup.sub === 'ESPECIALIDADE' &&
          (!clComRulerEntity || !clComRulerEntity.specialty || v.specialty.id !== clComRulerEntity.specialty.id)
      )
      .map(v => v.specialty.id);

    medicineMaterialInRulersIds = clComRulerList
      .filter(
        v =>
          v.category &&
          v.category.levelGroup &&
          v.category.levelGroup.sub === 'MATERIALMEDICAMENTOEDIETA' &&
          (!clComRulerEntity || !clComRulerEntity.category || v.category.id !== clComRulerEntity.category.id)
      )
      .map(v => v.category.id);

    productServiceRegistersInRulersIds = clComRulerList
      .filter(
        v =>
          v.productService &&
          v.productService.category &&
          v.productService.category.levelGroup.sub === 'PRODUTOSESERVICOS' &&
          (!clComRulerEntity || !clComRulerEntity.productService || v.productService.id !== clComRulerEntity.productService.id)
      )
      .map(v => v.productService.id);
  }

  return (
    <Row>
      <Col md="12">
        <AvGroup>
          <Row>
            <Col md="12">
              <Label className="mt-2 label-single-line" for="cl-com-ruler-levelGroup">
                <Translate contentKey="generadorApp.clComRuler.levelGroup">levelGroup</Translate>
              </Label>
            </Col>
            <Col md="12">
              <SelectAsync
                id="cl-com-ruler-levelGroup"
                name={'levelGroup'}
                className={'css-select-control'}
                data-type-rel="many-to-one-other-side"
                value={props.baseState.levelGroupSelectValue}
                onChange={options =>
                  props.setState({
                    levelGroupSelectValue: options,
                    categorySelectValue: null,
                    specialtySelectValue: null,
                    productServiceSelectValue: null,
                    categoryStartSelectOptions: undefined,
                    specialtyStartSelectOptions: undefined,
                    productServiceStartSelectOptions: undefined,
                    tussCodeSelectValue: '',
                    tissCodeSelectValue: '',
                    tableCodeSelectValue: '',
                    expenseCodeSelectValue: '',
                  })
                }
                defaultOptions={props.baseState.levelGroupStartSelectOptions ? props.baseState.levelGroupStartSelectOptions : []}
                loadingMessage={input => translate('selectAsync.loadingMessage')}
                noOptionsMessage={input =>
                  props.baseState.levelGroupStartSelectOptions === undefined
                    ? translate('selectAsync.loadingMessage')
                    : translate('selectAsync.noOptionsMessage')
                }
                onMenuOpen={async () => {
                  if (props.baseState.levelGroupStartSelectOptions === undefined) {
                    const result = await getListAxios('level-groups', {}, 0, 100, 'name,asc', 'name');
                    props.setState({
                      levelGroupStartSelectOptions: result.data
                        ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                        : [],
                    });
                  }
                }}
                loadOptions={async (inputValue, callback) => {
                  const result = await getListAxios('level-groups', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                  callback(result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []);
                }}
              />
            </Col>
          </Row>
        </AvGroup>
      </Col>
    </Row>
  );
};

export default LevelGroupCategory;
