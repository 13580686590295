/* eslint complexity: ["error", 500] */
import React from 'react';

import { getFilterFromSelect, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import { connect } from 'react-redux';

import { Link, RouteComponentProps } from 'react-router-dom';

import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { Button, Row, Table } from 'reactstrap';
import { div } from 'availity-reactstrap-validation';
import { JhiItemCount } from 'react-jhipster';
import { Translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { IRootState } from 'app/shared/reducers';
import {
  updateEntity,
  deleteEntity,
  getUrlBack,
  getClComTableState,
  IClComTableBaseState,
  getEntityFiltersURL,
  getEntities,
  reset,
} from './cl-com-table.reducer';

import { ITEMS_PER_PAGE, IPaginationBaseState, getSortState } from 'app/shared/util/pagination.constants';

import { StatePropsExtended, DispatchPropsExtended, IExtendedState, IExtendedProps } from './extended/cl-com-table';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

export interface IClComTableProps
  extends StateProps,
    DispatchProps,
    StatePropsExtended,
    DispatchPropsExtended,
    IExtendedProps,
    RouteComponentProps<{ url: string }> {}
export interface IClComTableStateAux extends IClComTableBaseState, IExtendedState {}

export interface IClComTableState extends IClComTableStateAux, IPaginationBaseState {}

export class ClComTable extends React.Component<IClComTableProps, IClComTableState> {
  protected myFormRef: any;

  constructor(props: IClComTableProps) {
    super(props);
    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      listCheckedID: [],
      isCheckedAll: false,
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      urlBack: getUrlBack(this.props.location),
      ...getClComTableState(this.props.location),
      clientId: [{ value: this.props.match.params['idClient'], label: this.props.match.params['idClient'] }],
      baseFilters: 'client',
    };
  }

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({ listCheckedID: this.props.clComTableList.map(clComTable => clComTable.id) });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({ listCheckedID: this.props.clComTableList.map(clComTable => clComTable.id) });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  removeEntitySelected() {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  }

  saveEntityMany = () => {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  };

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  deleteEntity(clComTableEntity) {
    MySwal.fire({
      title: <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>,
      html: (
        <Translate contentKey="generadorApp.clComTable.delete.question" interpolate={{ id: clComTableEntity.id }}>
          Are you sure you want to delete this ClComTable?
        </Translate>
      ),
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#e70015',
      cancelButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="ban" />
          &nbsp; <Translate contentKey="generadorApp.clComTable.btnCancel">Cancel</Translate>
        </>
      ),
      confirmButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="trash" />
          &nbsp;<Translate contentKey="generadorApp.clComTable.btnDelete">Delete</Translate>
        </>
      ),
    }).then(async response => {
      if (response.isConfirmed) {
        await this.props.deleteEntity(clComTableEntity.id, { reloadList: false });
        this.getEntities();
        MySwal.fire({
          title: <Translate contentKey="entity.delete.title.confirmed">Confirmed delete operation</Translate>,
          icon: 'success',
        });
      }
    });
  }

  cancelFilters = () => {
    this.setState(
      {
        name: '',
        status: '',
        type: '',
        valuePackage: '',
        option: '',
        saleValue: '',
        observation: '',
        rentalValue: '',
        clientId: '',
        clComRulerId: '',
        clComItemId: '',
        extraFilters: {},
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
        activePage: 0,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();

    const selectFieldsList = ['type', 'name', 'status', 'observation', 'client.fantasyName', 'valuePackage'];

    const {
      name,
      status,
      type,
      valuePackage,
      option,
      saleValue,
      observation,
      rentalValue,
      clientId,
      clComRulerId,
      clComItemId,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;
    return this.props.getEntities(
      name,
      status,
      type,
      valuePackage,
      option,
      saleValue,
      observation,
      rentalValue,
      getFilterFromSelect(clientId, 'many-to-one'),
      getFilterFromSelect(clComRulerId, 'one-to-many'),
      getFilterFromSelect(clComItemId, 'one-to-many'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(',')
    );
  };

  updateEntity = entity => {
    const selectFieldsList = ['type', 'name', 'status', 'observation', 'client.fantasyName', 'valuePackage'];

    const {
      name,
      status,
      type,
      valuePackage,
      option,
      saleValue,
      observation,
      rentalValue,
      clientId,
      clComRulerId,
      clComItemId,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;
    this.props.updateEntity(entity, [
      name,
      status,
      type,
      valuePackage,
      option,
      saleValue,
      observation,
      rentalValue,
      getFilterFromSelect(clientId, 'many-to-one'),
      getFilterFromSelect(clComRulerId, 'one-to-many'),
      getFilterFromSelect(clComItemId, 'one-to-many'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(','),
    ]);
  };

  renderHeader() {
    const { quotePackages, quotes, clComRulers, clComItems, clients, doses, clComTableList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.clComTable.home.title">Commercial tables</Translate>
          </span>

          <Button className="float-right jh-create-entity" onClick={() => this.setState({ showModalForm: true })} color="primary" size="md">
            <FontAwesomeIcon icon="plus" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.clComTable.listTopButtons.new">new</Translate>
            </span>
          </Button>
          <Button className="float-right jh-create-entity" onClick={() => this.props.history.goBack()} color="info" size="md">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.clComTable.listTopButtons.back">Back</Translate>
            </span>
          </Button>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/client'}>Clients</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/client/' + this.props.match.params['idClient']}>{this.props.match.params['idClient']}</Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.clComTable.home.title">Commercial tables</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    return <> </>;
  }

  renderTable() {
    const { quotePackages, quotes, clComRulers, clComItems, clients, doses, clComTableList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {clComTableList && clComTableList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null).length > 0 ? (
          <>
            <Table
              id="cl-com-table-table-list"
              responsive
              aria-describedby="cl-com-table-heading"
              className={'table-hover table-striped mt-4 table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.clComTable.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'type' ? (
                    <th className="hand" onClick={sortFunction('type')}>
                      <Translate contentKey="generadorApp.clComTable.type">Type</Translate>
                      <FontAwesomeIcon icon={state.sort === 'type' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'name' ? (
                    <th className="hand" onClick={sortFunction('name')}>
                      <Translate contentKey="generadorApp.clComTable.name">Name</Translate>
                      <FontAwesomeIcon icon={state.sort === 'name' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'status' ? (
                    <th className="hand" onClick={sortFunction('status')}>
                      <Translate contentKey="generadorApp.clComTable.status">Status</Translate>
                      <FontAwesomeIcon icon={state.sort === 'status' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'observation' ? (
                    <th className="hand" onClick={sortFunction('observation')}>
                      <Translate contentKey="generadorApp.clComTable.observation">Observation</Translate>
                      <FontAwesomeIcon icon={state.sort === 'observation' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'client' ? (
                    <th>
                      <Translate contentKey="generadorApp.clComTable.client">Client</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'valuePackage' ? (
                    <th className="hand" onClick={sortFunction('valuePackage')}>
                      <Translate contentKey="generadorApp.clComTable.valuePackage">Value</Translate>
                      <FontAwesomeIcon icon={state.sort === 'valuePackage' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}

                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {clComTableList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((clComTable, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.clComTable.field_id">ID</Translate>
                        </b>
                        {clComTable.id}
                      </td>

                      {state.baseFilters !== 'type' ? (
                        <td id="type-cell">
                          <b className="visible-xs"> Type </b>
                          {clComTable.type ? (
                            <Translate contentKey={`generadorApp.TypeClientCommercialTable.${clComTable.type}`} />
                          ) : (
                            <> </>
                          )}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'name' ? (
                        <td id="name-cell">
                          <b className="visible-xs"> Name </b>

                          {clComTable.name}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'status' ? (
                        <td id="status-cell">
                          <b className="visible-xs"> Status </b>
                          {clComTable.status ? <Translate contentKey={`generadorApp.Status.${clComTable.status}`} /> : <> </>}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'observation' ? (
                        <td id="observation-cell">
                          <b className="visible-xs"> Observation </b>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: clComTable.observation
                                ? Buffer.from(clComTable.observation)
                                    .toString()
                                    .replace(/(<([^>]+)>)/gi, '')
                                    .substring(0, 150)
                                : null,
                            }}
                          />
                        </td>
                      ) : null}

                      {state.baseFilters !== 'client' ? (
                        <td id="client-cell">
                          <b className="visible-xs"> Client </b>
                          {showFieldsSelectAsync(clComTable, 'client.fantasyName', null).map((v, ikey) => (
                            <span key={ikey}> {v} </span>
                          ))}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'valuePackage' ? (
                        <td id="valuePackage-cell">
                          <b className="visible-xs"> Value </b>
                          R$ {(clComTable.valuePackage * 1).toFixed(2).replace('.', ',')}
                        </td>
                      ) : null}

                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          {hasAnyAuthority(props.userAccount, ['client'], 'edit') ? (
                            <Button
                              tag={Link}
                              to={`${match.url}/${clComTable.id}/edit?${getEntityFiltersURL(
                                state,
                                this.state.itemsPerPage * (this.state.activePage - 1) + i
                              )}`}
                              color="primary"
                              size="sm"
                            >
                              <FontAwesomeIcon icon="pencil-alt" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                          {hasAnyAuthority(props.userAccount, ['client'], 'edit') ? (
                            <Button onClick={() => this.deleteEntity(clComTable)} color="danger" size="sm">
                              <FontAwesomeIcon icon="trash" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.clComTable.home.notFound">No Commercial tables found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const { quotePackages, quotes, clComRulers, clComItems, clients, doses, clComTableList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={clComTableList && clComTableList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const { quotePackages, quotes, clComRulers, clComItems, clients, doses, clComTableList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel className="cl-com-table cl-com-table-list-table">
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">{this.renderTable()}</div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToProps = ({ ...storeState }: IRootState) => {
  return {
    quotePackages: storeState.quotePackage.entities,
    quotes: storeState.quote.entities,
    clComRulers: storeState.clComRuler.entities,
    clComItems: storeState.clComItem.entities,
    clients: storeState.client.entities,
    doses: storeState.dose.entities,
    clComTableList: storeState.clComTable.entities,
    totalItems: storeState.clComTable.totalItems,
    userAccount: storeState.authentication.account,
    loading: storeState.clComTable.loading,
  };
};

export const mapDispatchToProps = {
  getEntities,

  deleteEntity,
  updateEntity,
  reset,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ClComTable);
