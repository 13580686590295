/* eslint complexity: ["error", 500] */
import axios from 'axios';

import { IPaginationBaseState, IPayloadResult } from 'react-jhipster';

import { IPayload } from 'react-jhipster/src/type/redux-action.type';

import { BASE_API_VERSION_PATH } from 'app/config/constants';

import { cleanEntity, jsonParse } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ISimpro, defaultValue } from 'app/shared/model/simpro.model';

export declare type ICrudPutAction<T> = (data?: T, listFiltersPage?) => IPayload<T> | IPayloadResult<T>;
export declare type ICrudGetAction<T> = (id: string | number, selectFields?: string) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const ACTION_TYPES = {
  FETCH_SIMPRO_LIST_EXPORT: 'simpro/FETCH_SIMPRO_LIST_EXPORT',
  FETCH_SIMPRO_LIST: 'simpro/FETCH_SIMPRO_LIST',
  FETCH_SIMPRO: 'simpro/FETCH_SIMPRO',
  FETCH_SIMPRO_OFFSET: 'simpro/FETCH_SIMPRO_OFFSET',
  CREATE_SIMPRO: 'simpro/CREATE_SIMPRO',
  UPDATE_SIMPRO: 'simpro/UPDATE_SIMPRO',
  CREATE_SIMPRO_AND_GO_LIST: 'simpro/CREATE_SIMPRO_AND_GO_LIST',
  UPDATE_SIMPRO_AND_GO_LIST: 'simpro/UPDATE_SIMPRO_AND_GO_LIST',
  DELETE_SIMPRO: 'simpro/DELETE_SIMPRO',
  RESET: 'simpro/RESET',
  SHOW_MODAL: 'simpro/SHOW_MODAL',
};

const initialState = {
  loading: false,
  openModal: {
    list: false,
    view: false,
    detail: false,
  },
  errorMessage: null,
  entities: [] as ReadonlyArray<ISimpro>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const actionPendind = [];
let actualState = initialState;

export const convertEntityBuffers = entity => ({
  ...entity,
});

export type SimproState = Readonly<typeof initialState>;

export interface ISimproBaseState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  listCheckedID?: Array<number>;
  isCheckedAll?: boolean;
  urlBack?: string;
  extraFilters?: any;
  baseFilters: any;
  offset: number;
  cdUsuario?: any;
  descricao?: any;
  vigencia?: any;
  identif?: any;
  status?: any;
  dataArray?: any;
}

export interface IFieldsBase extends ISimproBaseState, IPaginationBaseState {}
export interface ISimproUpdateState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  urlBack?: string;
  functionEmbebed?: any[];
  fieldsBase: IFieldsBase;
  cdUsuarioSelectValue?: any;
  cdUsuarioStartSelectOptions?: any;
  cdFracaoSelectValue?: any;
  cdFracaoStartSelectOptions?: any;
  descricaoSelectValue?: any;
  descricaoStartSelectOptions?: any;
  vigenciaSelectValue?: any;
  vigenciaStartSelectOptions?: any;
  identifSelectValue?: any;
  identifStartSelectOptions?: any;
  pcEmFabSelectValue?: any;
  pcEmFabStartSelectOptions?: any;
  pcEmVenSelectValue?: any;
  pcEmVenStartSelectOptions?: any;
  pcEmUsuSelectValue?: any;
  pcEmUsuStartSelectOptions?: any;
  pcFrFabSelectValue?: any;
  pcFrFabStartSelectOptions?: any;
  pcFrVenSelectValue?: any;
  pcFrVenStartSelectOptions?: any;
  pcFrUsuSelectValue?: any;
  pcFrUsuStartSelectOptions?: any;
  tpEmbalSelectValue?: any;
  tpEmbalStartSelectOptions?: any;
  tpFracaoSelectValue?: any;
  tpFracaoStartSelectOptions?: any;
  qtdeEmbalSelectValue?: any;
  qtdeEmbalStartSelectOptions?: any;
  qtdeFracSelectValue?: any;
  qtdeFracStartSelectOptions?: any;
  percLucrSelectValue?: any;
  percLucrStartSelectOptions?: any;
  tipAltSelectValue?: any;
  tipAltStartSelectOptions?: any;
  fabricaSelectValue?: any;
  fabricaStartSelectOptions?: any;
  cdSimproSelectValue?: any;
  cdSimproStartSelectOptions?: any;
  cdMercadoSelectValue?: any;
  cdMercadoStartSelectOptions?: any;
  percDescSelectValue?: any;
  percDescStartSelectOptions?: any;
  ipiProdutoSelectValue?: any;
  ipiProdutoStartSelectOptions?: any;
  registroAnvisaSelectValue?: any;
  registroAnvisaStartSelectOptions?: any;
  validadeAnvisaSelectValue?: any;
  validadeAnvisaStartSelectOptions?: any;
  cdBarraSelectValue?: any;
  cdBarraStartSelectOptions?: any;
  listaSelectValue?: any;
  listaStartSelectOptions?: any;
  hospitalarSelectValue?: any;
  hospitalarStartSelectOptions?: any;
  fracionarSelectValue?: any;
  fracionarStartSelectOptions?: any;
  cdTussSelectValue?: any;
  cdTussStartSelectOptions?: any;
  cdClassifSelectValue?: any;
  cdClassifStartSelectOptions?: any;
  cdRefProSelectValue?: any;
  cdRefProStartSelectOptions?: any;
  genericoSelectValue?: any;
  genericoStartSelectOptions?: any;
  statusSelectValue?: any;
  statusStartSelectOptions?: any;

  medicineMaterialSelectValue?: any;
  medicineMaterialStartSelectOptions?: any;

  isNew: boolean;
}

// Reducer

export default (state: SimproState = initialState, action): SimproState => {
  actualState = state;
  if (
    action.type.includes('_PENDING') &&
    !actionPendind.includes(action.type.replace('_PENDING', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_PENDING', ''))
  ) {
    actionPendind.push(action.type.replace('_PENDING', ''));
  }

  if (
    action.type.includes('_FULFILLED') &&
    actionPendind.includes(action.type.replace('_FULFILLED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_FULFILLED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_FULFILLED', '')), 1);
  }

  if (
    action.type.includes('_REJECTED') &&
    actionPendind.includes(action.type.replace('_REJECTED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_REJECTED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_REJECTED', '')), 1);
  }

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_SIMPRO_LIST_EXPORT):
    case REQUEST(ACTION_TYPES.FETCH_SIMPRO_LIST):
    case REQUEST(ACTION_TYPES.FETCH_SIMPRO):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_SIMPRO):
    case REQUEST(ACTION_TYPES.UPDATE_SIMPRO):
    case REQUEST(ACTION_TYPES.CREATE_SIMPRO_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.UPDATE_SIMPRO_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.DELETE_SIMPRO):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_SIMPRO_LIST_EXPORT):
    case FAILURE(ACTION_TYPES.FETCH_SIMPRO_LIST):
    case FAILURE(ACTION_TYPES.FETCH_SIMPRO):
    case FAILURE(ACTION_TYPES.CREATE_SIMPRO):
    case FAILURE(ACTION_TYPES.UPDATE_SIMPRO):
    case FAILURE(ACTION_TYPES.CREATE_SIMPRO_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.UPDATE_SIMPRO_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.DELETE_SIMPRO):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_SIMPRO_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_SIMPRO_OFFSET):
      if (action.payload.data && action.payload.data.length > 0) {
        return {
          ...state,
          loading: false,
          entity: convertEntityBuffers(action.payload.data[0]),
        };
      }
      return {
        ...state,
        loading: false,
      };

    case SUCCESS(ACTION_TYPES.FETCH_SIMPRO):
      return {
        ...state,
        loading: false,
        entity: convertEntityBuffers(action.payload.data),
      };
    case SUCCESS(ACTION_TYPES.CREATE_SIMPRO):
    case SUCCESS(ACTION_TYPES.UPDATE_SIMPRO):
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_SIMPRO_AND_GO_LIST):
    case SUCCESS(ACTION_TYPES.UPDATE_SIMPRO_AND_GO_LIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_SIMPRO):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };

    case ACTION_TYPES.SHOW_MODAL: {
      const openModal = state.openModal;
      openModal[action.payload.type] = action.payload.show;
      return {
        ...state,
        openModal,
      };
    }
    default:
      return state;
  }
};

const apiUrl = BASE_API_VERSION_PATH + 'simpros';

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

// Actions

// Actions
export type ICrudGetAllActionSimpro<T> = (
  cdUsuario?: any,
  descricao?: any,
  vigencia?: any,
  identif?: any,
  status?: any,
  extraFilters?: {},
  page?: number,
  size?: number,
  sort?: string,
  selectFields?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getEntitiesFilter = (filters?, page?, size?, sort?, selectFields = '') => {
  const requestUrl = `${apiUrl}?page=${page >= 0 ? page : 0}&size=${size >= 0 ? size : 20}&sort=${sort ? sort : 'id,asc'}&`;
  const requestFilters = filters.map(v => v.column + '.' + v.operation + '=' + v.value).join('&');
  return {
    type: ACTION_TYPES.FETCH_SIMPRO_LIST,
    payload: axios.get<ISimpro>(`${requestUrl}${requestFilters}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getAllEntities = (filters?, sort?, selectFields = '') => {
  if (
    (actualState.totalItems > 0 && actualState.entities.length === actualState.totalItems) ||
    actionPendind.includes(ACTION_TYPES.FETCH_SIMPRO_LIST)
  ) {
    return {
      type: ACTION_TYPES.FETCH_SIMPRO_LIST + '_FROM_CACHE',
      payload: new Promise(function (resolve, reject) {
        resolve('done');
      }),
    };
  }

  const idsRequest = filters && typeof filters['ids'] !== 'undefined' && filters['ids'] ? `id.in=${filters['ids'].join(',')}&` : '';
  const cdUsuarioRequest =
    filters && typeof filters['cdUsuario'] !== 'undefined' && filters['cdUsuario'] ? `cdUsuario.contains=${filters['cdUsuario']}&` : '';
  const descricaoRequest =
    filters && typeof filters['descricao'] !== 'undefined' && filters['descricao'] ? `descricao.contains=${filters['descricao']}&` : '';
  const vigenciaRequest =
    filters && typeof filters['vigencia'] !== 'undefined' && filters['vigencia'] ? `vigencia.contains=${filters['vigencia']}&` : '';
  const identifRequest =
    filters && typeof filters['identif'] !== 'undefined' && filters['identif'] ? `identif.contains=${filters['identif']}&` : '';
  const statusRequest =
    filters && typeof filters['status'] !== 'undefined' && filters['status'] ? `status.contains=${filters['status']}&` : '';

  const requestUrl = `${apiUrl}${`?page=${0}&size=${1000000}&sort=${sort ? sort : 'id,asc'}&`}`;
  return {
    type: ACTION_TYPES.FETCH_SIMPRO_LIST,
    payload: axios.get<ISimpro>(
      `${requestUrl}${idsRequest}${cdUsuarioRequest}${descricaoRequest}${vigenciaRequest}${identifRequest}${statusRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntities: ICrudGetAllActionSimpro<ISimpro> = (
  cdUsuario,
  descricao,
  vigencia,
  identif,
  status,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const cdUsuarioRequest = cdUsuario ? `cdUsuario.contains=${cdUsuario}&` : '';
  const descricaoRequest = descricao ? `descricao.contains=${descricao}&` : '';
  const vigenciaRequest = vigencia ? `vigencia.contains=${vigencia}&` : '';
  const identifRequest = identif ? `identif.contains=${identif}&` : '';
  const statusRequest = status !== undefined && status !== '' ? `status.equals=${status}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_SIMPRO_LIST,
    payload: axios.get<ISimpro>(
      `${requestUrl}${extraFiltersRequest}${cdUsuarioRequest}${descricaoRequest}${vigenciaRequest}${identifRequest}${statusRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntityOffset: any = (cdUsuario, descricao, vigencia, identif, status, extraFilters, offset, sort, selectFields = '') => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const cdUsuarioRequest = cdUsuario ? `cdUsuario.contains=${cdUsuario}&` : '';
  const descricaoRequest = descricao ? `descricao.contains=${descricao}&` : '';
  const vigenciaRequest = vigencia ? `vigencia.contains=${vigencia}&` : '';
  const identifRequest = identif ? `identif.contains=${identif}&` : '';
  const statusRequest = status !== undefined && status !== '' ? `status.equals=${status}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${offset}&size=${1}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_SIMPRO_OFFSET,
    payload: axios.get<ISimpro>(
      `${requestUrl}${extraFiltersRequest}${cdUsuarioRequest}${descricaoRequest}${vigenciaRequest}${identifRequest}${statusRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntity: ICrudGetAction<ISimpro> = (id, selectFields = '') => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_SIMPRO,
    payload: axios.get<ISimpro>(requestUrl, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntitiesExport: ICrudGetAllActionSimpro<ISimpro> = (
  cdUsuario,
  descricao,
  vigencia,
  identif,
  status,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const cdUsuarioRequest = cdUsuario
    ? Array.isArray(status)
      ? `cdUsuario.in=${cdUsuario.join(',')}&`
      : `cdUsuario.contains=${cdUsuario}&`
    : '';
  const descricaoRequest = descricao
    ? Array.isArray(status)
      ? `descricao.in=${descricao.join(',')}&`
      : `descricao.contains=${descricao}&`
    : '';
  const vigenciaRequest = vigencia ? (Array.isArray(status) ? `vigencia.in=${vigencia.join(',')}&` : `vigencia.contains=${vigencia}&`) : '';
  const identifRequest = identif ? (Array.isArray(status) ? `identif.in=${identif.join(',')}&` : `identif.contains=${identif}&`) : '';
  const statusRequest = status !== undefined && status !== '' ? `status.equals=${status}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_SIMPRO_LIST,
    payload: axios.get<ISimpro>(
      `${requestUrl}${extraFiltersRequest}${cdUsuarioRequest}${descricaoRequest}${vigenciaRequest}${identifRequest}${statusRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const createEntity: ICrudPutAction<ISimpro> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type: listFiltersPage && listFiltersPage['reloadList'] !== false ? ACTION_TYPES.CREATE_SIMPRO_AND_GO_LIST : ACTION_TYPES.CREATE_SIMPRO,
    payload: axios.post(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const updateEntity: ICrudPutAction<ISimpro> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type: listFiltersPage && listFiltersPage['reloadList'] !== false ? ACTION_TYPES.UPDATE_SIMPRO_AND_GO_LIST : ACTION_TYPES.UPDATE_SIMPRO,
    payload: axios.put(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const deleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_SIMPRO,
    payload: axios.delete(requestUrl),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const showModal = view => ({
  payload: { type: view, show: true },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const hideModal = view => ({
  payload: { type: view, show: false },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const getUrlBack = (location): string => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  let urlBack = _urlBase.searchParams.get('urlBack') || '';
  urlBack = urlBack.trim();
  urlBack = urlBack.startsWith('/') ? urlBack.slice(1) : urlBack;
  urlBack = urlBack.endsWith('/') ? urlBack.slice(0, -1) : urlBack;
  return urlBack ? '/' + urlBack + '/' : '';
};

export const getSimproState = (location): ISimproBaseState => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  const extraFilters = jsonParse(_urlBase.searchParams.get('extraFilters') || '{}');
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;

  const cdUsuario = _urlBase.searchParams.get('cdUsuario') || '';
  const descricao = _urlBase.searchParams.get('descricao') || '';
  const vigencia = _urlBase.searchParams.get('vigencia') || '';
  const identif = _urlBase.searchParams.get('identif') || '';
  const status = _urlBase.searchParams.get('status') || '';
  return {
    baseFilters,
    extraFilters,
    offset,
    cdUsuario,
    descricao,
    vigencia,
    identif,
    status,
  };
};

export const getEntityFiltersURL = (state, offset = null) => {
  return (
    'baseFilters=' +
    state.baseFilters +
    '&extraFilters=' +
    encodeURI(JSON.stringify(state.extraFilters)) +
    '&page=' +
    state.activePage +
    '&' +
    'size=' +
    state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    state.sort +
    ',' +
    state.order +
    '&' +
    (state.cdUsuario ? 'cdUsuario=' + state.cdUsuario + '&' : '') +
    (state.descricao ? 'descricao=' + state.descricao + '&' : '') +
    (state.vigencia ? 'vigencia=' + state.vigencia + '&' : '') +
    (state.identif ? 'identif=' + state.identif + '&' : '') +
    (state.status ? 'status=' + state.status + '&' : '') +
    ''
  );
};
