import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Pad from './extended/pad';

import PadDetail from './pad-detail';

import PadUpdate from './extended/pad-update';

import PadAttendance from '../pad-attendance/index';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute path={`${match.path}/:idPad/pad-attendance`} component={PadAttendance} />

      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={PadUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={PadUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={PadDetail} />
      <ErrorBoundaryRoute path={match.path} component={Pad} />
    </Switch>

    <Switch>{/* <ErrorBoundaryRoute path={`${match.path}/:idPad/pad-attendance`} component={PadAttendance} /> */}</Switch>
  </>
);

export default Routes;
