/* eslint-disable no-console */
import React from 'react';
import { useDrop } from 'react-dnd';
import { Box } from './Box';
import { Translate, translate } from 'app/config/translate-component';
import moment from 'moment';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);

const styleDustbin: React.CSSProperties = {
  minHeight: '100px',
  width: '100%',
  padding: '0',
  textAlign: 'center',
  fontSize: '1rem',
  lineHeight: 'normal',
  float: 'left',
  border: '0.5px solid #ccc',
};

const styleBox: React.CSSProperties = {
  border: '1px dashed gray',
  fontSize: '13px',
  marginRight: '0',
  marginBottom: '2px',
  float: 'left',
  color: '#000',
  width: '100%',
};

export const Dustbin: any = props => {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'box',
    drop: () => props,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = canDrop && isOver;
  let backgroundColor = 'transparent';
  if (isActive) {
    backgroundColor = '#0078ff';
  } else if (canDrop) {
    backgroundColor = 'transparent';
  }

  const dayStyle: React.CSSProperties = {
    float: 'right',
    margin: '2px 3px 0 0',
    borderRadius: '10px',
    color: '#000',
    padding: '0px 7px 1px 6px',
  };

  return (
    <div ref={drop} style={{ ...styleDustbin, backgroundColor }}>
      <>
        <span style={dayStyle}>{props.dia.date()}</span>
        {props.padAttendanceList &&
          props.padAttendanceList.map((padAttendance, i) => (
            <Box
              key={i}
              md={4}
              inCalendar={true}
              name={'nnonoo'}
              cor={'#f00'}
              padAttendance={padAttendance}
              onDrag={dia => {
                if (
                  moment(padAttendance.quoteRh.startDate).format('YYYY-MM-DD') <= moment(dia).format('YYYY-MM-DD') &&
                  moment(padAttendance.quoteRh.finishDate).format('YYYY-MM-DD') >= moment(dia).format('YYYY-MM-DD')
                ) {
                  props.updateEntity({ ...padAttendance, date: dia.format('YYYY-MM-DD') });
                } else {
                  MySwal.fire({
                    html: <Translate contentKey="generadorApp.PadAttendance.OutOfDate">ObservationComponent</Translate>,
                  });
                }
              }}
              quoteRh={
                padAttendance && padAttendance.quoteRh && padAttendance.quoteRh['id']
                  ? padAttendance.quoteRh['id']
                  : props.quoteRhs.filter(v => v.padAttendance.map(b => b.id).includes(padAttendance.id)).map((v, k) => v.id)
              }
              patient={props.padBaseFilter && props.padBaseFilter.patient ? props.padBaseFilter.patient.name : ''}
              period={padAttendance.period ? translate(`generadorApp.PadAttendancePeriod.SMALL_${padAttendance.period}`) : ''}
              schedule={padAttendance.schedule}
              frequency={padAttendance.frequency ? translate(`generadorApp.QuoteFrequency.${padAttendance.frequency}`) : ''}
              quantity={padAttendance.quantity}
              pad={
                padAttendance && padAttendance.pad && padAttendance.pad['id']
                  ? padAttendance.pad['id']
                  : props.pads.filter(v => v.padAttendance && v.padAttendance.map(b => b.id).includes(padAttendance.id)).map((v, k) => v.id)
              }
              professional={
                padAttendance && padAttendance.professional && padAttendance.professional['name']
                  ? padAttendance.professional['name']
                  : props.professionals.filter(v => v.padAttendance.map(b => b.id).includes(padAttendance.id)).map((v, k) => v.name)
              }
            />
          ))}
      </>
    </div>
  );
};

// <div style={{ ...styleBox }} key={i}>
// {v.professional ? v.professional.name : ""}
// </div>
