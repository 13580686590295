/* eslint complexity: ["error", 500] */
/* eslint no-empty: off */
import React from 'react';
import { connect } from 'react-redux';

import SelectAsync from 'react-select/async';
import axios from 'axios';
import { Link, RouteComponentProps } from 'react-router-dom';

import { getFilterFromSelect, getFormFromSelect, getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Col, Label } from 'reactstrap';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import {
  IPatientNursingPrescriptionUpdateState as IBaseUpdateState,
  getEntity,
  getPatientNursingPrescriptionState,
  getUrlBack,
  updateEntity,
  createEntity,
  getEntityFiltersURL,
  getEntityOffset,
  reset,
  patNrPrescProcedimentSetEntities,
  patNrPrescProcedimentGetEntities,
  patNrPrescProcedimentUpdateEntity,
  patNrPrescProcedimentReset,
  patNrPrescProcedimentGetEntity,
  patNrPrescProcedimentCreateEntity,
  patNrPrescProcedimentDeleteEntity,
} from './patient-nursing-prescription.reducer';

import {
  mapStateToPropsExtended as mapStateToPropsPatNrPrescProcediment,
  mapDispatchToPropsExtended as mapDispatchToPropsPatNrPrescProcediment,
  PatNrPrescProcedimentExtended as PatNrPrescProcediment,
} from 'app/entities/pat-nr-presc-procediment/extended/pat-nr-presc-procediment';

import { StatePropsExtended, DispatchPropsExtended, IUpdateExtendedState } from './extended/patient-nursing-prescription-update';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

registerLocale('pt', pt);

export interface IPatientNursingPrescriptionUpdateProps
  extends StateProps,
    DispatchProps,
    StatePropsExtended,
    DispatchPropsExtended,
    RouteComponentProps<{ url: string }> {}
export interface IPatientNursingPrescriptionUpdateState extends IBaseUpdateState, IUpdateExtendedState {}

export class PatientNursingPrescriptionUpdate extends React.Component<
  IPatientNursingPrescriptionUpdateProps,
  IPatientNursingPrescriptionUpdateState
> {
  observationsFileInput: any;
  constructor(props: Readonly<IPatientNursingPrescriptionUpdateProps>) {
    super(props);

    this.patNrPrescProcedimentUpdateEntity = this.patNrPrescProcedimentUpdateEntity.bind(this);
    this.patNrPrescProcedimentGetEntities = this.patNrPrescProcedimentGetEntities.bind(this);
    this.patNrPrescProcedimentReset = this.patNrPrescProcedimentReset.bind(this);
    this.patNrPrescProcedimentGetEntity = this.patNrPrescProcedimentGetEntity.bind(this);
    this.patNrPrescProcedimentCreateEntity = this.patNrPrescProcedimentCreateEntity.bind(this);
    this.patNrPrescProcedimentDeleteEntity = this.patNrPrescProcedimentDeleteEntity.bind(this);

    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      functionEmbebed: [],

      patNrPrescProcedimentSelectValue: null,

      nrDiagnosticSelectValue: null,

      patientSelectValue: { value: this.props.match.params['idPatient'], label: this.props.match.params['idPatient'] },

      technicianSelectValue: null,

      observationsSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getPatientNursingPrescriptionState(this.props.location),
        urlBack: getUrlBack(this.props.location),
        patientId: [{ value: this.props.match.params['idPatient'], label: this.props.match.params['idPatient'] }],
        baseFilters: 'patient',
      },
      idsnrDiagnostic: [],
      patient: null,
      technicianName: null,

      isNew: false,
    };

    axios
      .get('/api/patients/' + this.props.match.params['idPatient'], {
        headers: {
          'Select-Fields': 'name',
        },
      })
      .then(result => {
        this.setState({
          patientSelectValue: {
            ...result['data'],
            value: this.props.match.params['idPatient'],
            label: showFieldsSelectAsync(result['data'], 'name'),
          },
        });
      });
  }

  patNrPrescProcedimentUpdateEntity(...values) {
    values[0]['patientNursingPrescription'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['updateEntityPatNrPrescProcediment'](...values);
    }
    return this.props.patNrPrescProcedimentUpdateEntity(...values);
  }
  patNrPrescProcedimentGetEntities(...values) {
    return this.props.patNrPrescProcedimentGetEntities(...values);
  }
  patNrPrescProcedimentReset(...values) {
    return this.props.patNrPrescProcedimentReset(...values);
  }
  patNrPrescProcedimentGetEntity(...values) {
    return this.props.patNrPrescProcedimentGetEntity(...values);
  }
  patNrPrescProcedimentCreateEntity(...values) {
    values[0]['patientNursingPrescription'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['createEntityPatNrPrescProcediment'](...values);
    }
    return this.props.patNrPrescProcedimentCreateEntity(...values);
  }
  patNrPrescProcedimentDeleteEntity(...values) {
    if (!this.state.isNew) {
      this.props['deleteEntityPatNrPrescProcediment'](...values);
    }
    return this.props.patNrPrescProcedimentDeleteEntity(...values);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateSuccess !== this.props.updateSuccess && prevProps.updateSuccess) {
      this.handleClose();
    }

    if (
      prevProps.patientNursingPrescriptionEntity &&
      prevProps.patientNursingPrescriptionEntity.id !== this.props.patientNursingPrescriptionEntity.id &&
      this.props.patientNursingPrescriptionEntity.id > 0
    ) {
      if (this.props.patientNursingPrescriptionEntity.id > 0) {
        this.setState({ isNew: false });
      } else {
        this.setState({ isNew: true });
      }
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.patientNursingPrescriptionEntity &&
        prevProps.patientNursingPrescriptionEntity.id !== this.props.patientNursingPrescriptionEntity.id) ||
      (this.props.patNrPrescProcediments.length > 0 &&
        this.state.patNrPrescProcedimentSelectValue === null &&
        this.props.patientNursingPrescriptionEntity.patNrPrescProcediment)
    ) {
      this.setState({ patNrPrescProcedimentSelectValue: this.props.patientNursingPrescriptionEntity.patNrPrescProcediment });
      this.props.patNrPrescProcedimentSetEntities(this.props.patientNursingPrescriptionEntity.patNrPrescProcediment);
    }

    // data-type-rel -> tipo-1 many-to-many owner-side
    if (
      (prevProps.patientNursingPrescriptionEntity &&
        prevProps.patientNursingPrescriptionEntity.id !== this.props.patientNursingPrescriptionEntity.id) ||
      (this.props.patNrPrescriptionDiagnostics.length > 0 &&
        this.state.nrDiagnosticSelectValue === null &&
        this.props.patientNursingPrescriptionEntity.nrDiagnostics)
    ) {
      this.setState({
        nrDiagnosticSelectValue:
          this.props.patientNursingPrescriptionEntity && this.props.patientNursingPrescriptionEntity.nrDiagnostics
            ? this.props.patientNursingPrescriptionEntity.nrDiagnostics
                .filter(p => p)
                .map(p => ({ value: p.id, label: p['name'] ? p.name : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-4 one-to-one other-side
    if (
      (prevProps.patientNursingPrescriptionEntity &&
        prevProps.patientNursingPrescriptionEntity.id !== this.props.patientNursingPrescriptionEntity.id) ||
      (this.props.patients &&
        this.state.patientSelectValue === null &&
        this.props.patientNursingPrescriptionEntity.patient &&
        this.props.patientNursingPrescriptionEntity.patient.id)
    ) {
      const p =
        this.props.patientNursingPrescriptionEntity && this.props.patientNursingPrescriptionEntity.patient
          ? this.props.patientNursingPrescriptionEntity.patient
          : null;
      this.setState({
        patientSelectValue: p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : null,
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.patientNursingPrescriptionEntity &&
        prevProps.patientNursingPrescriptionEntity.id !== this.props.patientNursingPrescriptionEntity.id) ||
      (this.props.professionals &&
        this.state.technicianSelectValue === null &&
        this.props.patientNursingPrescriptionEntity.technician &&
        this.props.patientNursingPrescriptionEntity.technician.id)
    ) {
      const p =
        this.props.patientNursingPrescriptionEntity && this.props.patientNursingPrescriptionEntity.technician
          ? this.props.patientNursingPrescriptionEntity.technician
          : null;
      this.setState({
        technicianSelectValue: p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : null,
      });
    }

    if (
      (prevProps.patientNursingPrescriptionEntity &&
        prevProps.patientNursingPrescriptionEntity.id !== this.props.patientNursingPrescriptionEntity.id) ||
      (this.props.patientNursingPrescriptionEntity.observations &&
        this.state.observationsSelectValue === null &&
        this.props.patientNursingPrescriptionEntity.observations !== this.state.observationsSelectValue)
    ) {
      this.setState({
        observationsSelectValue: this.props.patientNursingPrescriptionEntity.observations
          ? this.props.patientNursingPrescriptionEntity.observations
          : null,
      });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  UNSAFE_componentWillMount() {
    this.props.getEntity(parseInt(this.props.match.params['idPatient'], 10));
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = offset !== null ? '&offset=' + offset : '';
    Object.keys(fieldsBase).map(key => {
      url += key !== 'offset' ? '&' + key + '=' + fieldsBase[key] : '';
    });
    return url;
  };
  saveEntity = (event: any, errors: any, values: any) => {
    const selectFieldsList = ['observations', 'technician.name', 'patient.', 'nrDiagnostics.name'];

    errors = errors.filter(v => v !== 'id');

    if (errors.length === 0) {
      const { patientNursingPrescriptionEntity } = this.props;
      const _entity = {
        ...patientNursingPrescriptionEntity,
        // ...values,
        id: parseInt(this.props.match.params['idPatient'], 10),
        patient: getFormFromSelect(this.state.patientSelectValue, 'many-to-one'), // many-to-one - otherSide
        technician: getFormFromSelect(this.state.technicianSelectValue, 'many-to-one'), // many-to-one - otherSide

        nrDiagnostics: getFormFromSelect(this.state.nrDiagnosticSelectValue, 'many-to-many'), // many-to-many - ownerSide

        patNrPrescProcediment: undefined,

        observations: this.state.observationsSelectValue,
      };

      const entity = _entity;

      const {
        observations,
        technicianId,
        patientId,
        nrDiagnosticId,
        patNrPrescProcedimentId,
        extraFilters,
        activePage,
        itemsPerPage,
        sort,
        order,
      } = this.state.fieldsBase;

      const patNrPrescProcedimentListAllEmbed = this.props.patNrPrescProcedimentListAllEmbed;

      if (this.state.isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              observations,
              getFilterFromSelect(technicianId, 'many-to-one'),
              getFilterFromSelect(patientId, 'one-to-one'),
              getFilterFromSelect(nrDiagnosticId, 'many-to-many'),
              getFilterFromSelect(patNrPrescProcedimentId, 'one-to-many'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
          if (idEntity) {
            patNrPrescProcedimentListAllEmbed.map(v => {
              v['id'] = null;
              v['patientNursingPrescription'] = { id: idEntity };
              this.props['createEntityPatNrPrescProcediment'](v);
            });
          }
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              observations,
              getFilterFromSelect(technicianId, 'many-to-one'),
              getFilterFromSelect(patientId, 'one-to-one'),
              getFilterFromSelect(nrDiagnosticId, 'many-to-many'),
              getFilterFromSelect(patNrPrescProcedimentId, 'one-to-many'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
    }
  };

  handleClose = () => {
    this.props.history.push(
      '/patient/' + this.props.match.params['idPatient'] + '/patient-nursing-prescription?' + getEntityFiltersURL(this.state.fieldsBase)
    );
  };

  getFullEntityFromState(v) {
    return {
      id: v.id,
      observations: this.state.observationsSelectValue,
      patNrPrescProcediment: this.state.patNrPrescProcedimentSelectValue,
      nrDiagnostic: this.state.nrDiagnosticSelectValue,
      patient: this.state.patientSelectValue,
      technician: this.state.technicianSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    const patNrPrescProcedimentEntity = v.patNrPrescProcediment;

    const nrDiagnosticEntity = this.props.patNrPrescriptionDiagnostics.filter(x => x.prescriptions.map(x1 => x1.id).includes(v.id)).pop();

    const patientEntity = v.patient;

    const technicianEntity = v.professional;

    return {
      observationsSelectValue: v.observations,
      patNrPrescProcedimentSelectValue: patNrPrescProcedimentEntity ? patNrPrescProcedimentEntity['id'] : null,
      nrDiagnosticSelectValue: nrDiagnosticEntity ? nrDiagnosticEntity['id'] : null,
      patientSelectValue: patientEntity ? patientEntity['id'] : null,
      technicianSelectValue: technicianEntity ? technicianEntity['id'] : null,
    };
  }

  renderHeader() {
    const {
      patientNursingPrescriptionEntity,
      patNrPrescProcediments,
      patNrPrescriptionDiagnostics,
      patients,
      professionals,
      loading,
      updating,
    } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.patientNursingPrescription.home.createLabel">
                Create a PatientNursingPrescription
              </Translate>
            ) : (
              <Translate contentKey="generadorApp.patientNursingPrescription.home.editLabel">Edit a PatientNursingPrescription</Translate>
            )}
          </span>

          <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.patientNursingPrescription.btnSave">Save</Translate>
          </Button>

          {!this.state.isNew ? (
            <>
              <Button
                color="primary"
                id="next-entity"
                disabled={updating}
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.observations,
                    this.state.fieldsBase.technicianId,
                    this.state.fieldsBase.patientId,
                    this.state.fieldsBase.nrDiagnosticId,
                    this.state.fieldsBase.patNrPrescProcedimentId,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 + 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 + 1 },
                    });
                    this.props.history.push(
                      '/patient-nursing-prescription/' +
                        myresult.value.data[0]['id'] +
                        '/edit?' +
                        this.getFiltersURL(this.state.fieldsBase.offset * 1 + 1)
                    );
                  } else {
                    toast.error(
                      translate('generadorApp.last.item', { fieldName: translate('generadorApp.patientNursingPrescription.detail.title') })
                    );
                  }
                }}
                className="float-right jh-create-entity"
              >
                {'>>'}
              </Button>

              <Button
                color="primary"
                id="prev-entity"
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.observations,
                    this.state.fieldsBase.technicianId,
                    this.state.fieldsBase.patientId,
                    this.state.fieldsBase.nrDiagnosticId,
                    this.state.fieldsBase.patNrPrescProcedimentId,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 - 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 - 1 },
                    });
                    this.props.history.push(
                      '/patient-nursing-prescription/' +
                        myresult.value.data[0]['id'] +
                        '/edit?' +
                        this.getFiltersURL(this.state.fieldsBase.offset * 1 - 1)
                    );
                  } else {
                    toast.error(
                      translate('generadorApp.first.item', { fieldName: translate('generadorApp.patientNursingPrescription.detail.title') })
                    );
                  }
                }}
                disabled={updating}
                className="float-right jh-create-entity"
              >
                {'<<'}
              </Button>
            </>
          ) : (
            <> </>
          )}

          <Button
            tag={Link}
            id="cancel-save"
            to={
              '/patient/' +
              this.props.match.params['idPatient'] +
              '/patient-nursing-prescription?' +
              getEntityFiltersURL(this.state.fieldsBase)
            }
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.patientNursingPrescription.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/patient'}>
              <Translate contentKey="generadorApp.patient.home.title">Patients</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/patient/' + this.props.match.params['idPatient']}>
              {this.state.patientSelectValue ? this.state.patientSelectValue.label : this.props.match.params['idPatient']}
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/patient/' + this.props.match.params['idPatient'] + '/patient-nursing-prescription'}>
              <Translate contentKey="generadorApp.patientNursingPrescription.home.title">Nurse Prescriptions</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.patientNursingPrescription.detail.title">Nurse Prescription edit</Translate>
          </li>
        </ol>
      </>
    );
  }
  renderBody() {
    const {
      patientNursingPrescriptionEntity,
      patNrPrescProcediments,
      patNrPrescriptionDiagnostics,
      patients,
      professionals,
      loading,
      updating,
    } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const fullEntityEmbebed = this.getFullEntityFromState(patientNursingPrescriptionEntity);

    const mapDispatchToPropsPatNrPrescProcedimentList: any = {};
    Object.keys(mapDispatchToPropsPatNrPrescProcediment).map(v => {
      mapDispatchToPropsPatNrPrescProcedimentList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'PatNrPrescProcediment')) {
          this.setState(
            { functionEmbebed: [...this.state.functionEmbebed, v + 'PatNrPrescProcediment'] },
            this.props[v + 'PatNrPrescProcediment']
          );
        }
      };
    });

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="patient-nursing-prescription-id">
                  <Translate contentKey="generadorApp.patientNursingPrescription.field_id">ID</Translate>
                </Label>
                </Col> */}
                      <Col md="12">
                        <AvInput id="patient-nursing-prescription-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'observations' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label
                              className="mt-2 label-single-line"
                              id="observationsLabel"
                              for="patient-nursing-prescription-observations"
                            >
                              <Translate contentKey="generadorApp.patientNursingPrescription.observations">Observations</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ observationsSelectValue: evt.target.value })}
                              value={this.state.observationsSelectValue ? this.state.observationsSelectValue : ''}
                              id="patient-nursing-prescription-observations"
                              type="text"
                              name="observations"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ observationsSelectValue: evt.target.value })}
                      type="hidden"
                      name="observations"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'technician' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="patient-nursing-prescription-technician">
                              <Translate contentKey="generadorApp.patientNursingPrescription.technician">Technician</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="patient-nursing-prescription-technician"
                              name={'technician'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.technicianSelectValue ? this.state.technicianSelectValue : ''}
                              onChange={options => this.setState({ technicianSelectValue: options })}
                              defaultOptions={this.state.technicianStartSelectOptions ? this.state.technicianStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.technicianStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.technicianStartSelectOptions === undefined) {
                                  const result = await getListAxios('professionals', {}, 0, 100, 'name,asc', 'name');
                                  this.setState({
                                    technicianStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'professionals',
                                  { 'name.contains': inputValue },
                                  0,
                                  100,
                                  'name,asc',
                                  'name'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="technician"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}
                  {baseFilters !== 'nrDiagnostic' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="patient-nursing-prescription-nrDiagnostic">
                              <Translate contentKey="generadorApp.patientNursingPrescription.nrDiagnostic">Nr Diagnostic</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              isMulti
                              id="patient-nursing-prescription-nrDiagnostic"
                              name={'nrDiagnostic'}
                              className={'css-select-control'}
                              data-type-rel="many-to-many-owner-side"
                              value={this.state.nrDiagnosticSelectValue ? this.state.nrDiagnosticSelectValue : ''}
                              onChange={options => this.setState({ nrDiagnosticSelectValue: options })}
                              defaultOptions={this.state.nrDiagnosticStartSelectOptions ? this.state.nrDiagnosticStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.nrDiagnosticStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.nrDiagnosticStartSelectOptions === undefined) {
                                  const result = await getListAxios('pat-nr-prescription-diagnostics', {}, 0, 100, 'name,asc', 'name');
                                  this.setState({
                                    nrDiagnosticStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'pat-nr-prescription-diagnostics',
                                  { 'name.contains': inputValue },
                                  0,
                                  100,
                                  'name,asc',
                                  'name'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="nrDiagnostic"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}
                  {baseFilters !== 'patient' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="patient-nursing-prescription-patient">
                              <Translate contentKey="generadorApp.patientNursingPrescription.patient">Patient</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="patient-nursing-prescription-patient"
                              name={'patient'}
                              className={'css-select-control'}
                              data-type-rel="one-to-one-other-side"
                              value={this.state.patientSelectValue ? this.state.patientSelectValue : ''}
                              onChange={options => this.setState({ patientSelectValue: options })}
                              defaultOptions={this.state.patientStartSelectOptions ? this.state.patientStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.patientStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.patientStartSelectOptions === undefined) {
                                  const result = await getListAxios('patients', {}, 0, 100, 'name,asc', 'name');
                                  this.setState({
                                    patientStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios('patients', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="patient"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}
                  {baseFilters !== 'patNrPrescProcediment' ? (
                    <Col md="12">
                      <div className="entityEmbebedList">
                        <PatNrPrescProcediment
                          entityEmbebedPai={fullEntityEmbebed}
                          history={this.props.history}
                          location={{
                            pathname: `/patient-nursing-prescription/${this.props.match.params['id']}/pat-nr-presc-procediment/`,
                            search: '',
                            hash: '',
                            state: undefined,
                            key: 'q2tbhd',
                          }}
                          match={{
                            path: `/patient-nursing-prescription/:idPatientNursingPrescription/pat-nr-presc-procediment/:id`,
                            url: `/patient-nursing-prescription/${this.props.match.params['id']}/pat-nr-presc-procediment/`,
                            isExact: true,
                            params: {
                              idPatientNursingPrescription: this.props.match.params['id'],
                              url: `/patient-nursing-prescription/${this.props.match.params['id']}/pat-nr-presc-procediment/`,
                            },
                          }}
                          {...this.props.mapStateToPropsPatNrPrescProcediment}
                          {...mapDispatchToPropsPatNrPrescProcedimentList}
                          getEntityPai={(...v) => this.props.getEntity(...v)}
                          getEntities={this.patNrPrescProcedimentGetEntities}
                          updateEntity={this.patNrPrescProcedimentUpdateEntity}
                          getEntity={this.patNrPrescProcedimentGetEntity}
                          createEntity={this.patNrPrescProcedimentCreateEntity}
                          deleteEntity={this.patNrPrescProcedimentDeleteEntity}
                          reset={this.patNrPrescProcedimentReset}
                          patNrPrescProcedimentList={this.props.patNrPrescProcedimentListEmbed}
                          totalItems={this.props.patNrPrescProcedimentListEmbed ? this.props.patNrPrescProcedimentListEmbed.length : 0}
                          loading={this.props.loading}
                          patNrPrescProcedimentEntity={this.props.patNrPrescProcedimentSelected}
                        />
                      </div>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="patNrPrescProcediment"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}{' '}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const {
      patientNursingPrescriptionEntity,
      patNrPrescProcediments,
      patNrPrescriptionDiagnostics,
      patients,
      professionals,
      loading,
      updating,
    } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...patientNursingPrescriptionEntity,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel className="patient-nursing-prescription patient-nursing-prescription-update">
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToProps = (storeState: IRootState) => {
  return {
    patNrPrescProcediments: storeState.patNrPrescProcediment.entities,
    patNrPrescriptionDiagnostics: storeState.patNrPrescriptionDiagnostic.entities,
    patients: storeState.patient.entities,
    professionals: storeState.professional.entities,
    patientNursingPrescriptionEntity: storeState.patientNursingPrescription.entity,
    loading: storeState.patientNursingPrescription.loading,
    updating: storeState.patientNursingPrescription.updating,
    updateSuccess: storeState.patientNursingPrescription.updateSuccess,

    patientNursingPrescriptions: storeState.patientNursingPrescription.entities,
    patNrPrescProcedimentListAllEmbed: storeState.patientNursingPrescription.patNrPrescProcedimentListAllEmbed,
    patNrPrescProcedimentListEmbed: storeState.patientNursingPrescription.patNrPrescProcedimentListEmbed,
    patNrPrescProcedimentSelected: storeState.patientNursingPrescription.patNrPrescProcedimentSelected,
    mapStateToPropsPatNrPrescProcediment: mapStateToPropsPatNrPrescProcediment(storeState),
  };
};

const mapDispatchToPropsPatNrPrescProcedimentList = {};
Object.keys(mapDispatchToPropsPatNrPrescProcediment).map(v => {
  mapDispatchToPropsPatNrPrescProcedimentList[v + 'PatNrPrescProcediment'] = mapDispatchToPropsPatNrPrescProcediment[v];
});

export const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  getEntityOffset,
  reset,

  ...mapDispatchToPropsPatNrPrescProcedimentList,
  patNrPrescProcedimentUpdateEntity,
  patNrPrescProcedimentGetEntities,
  patNrPrescProcedimentSetEntities,
  patNrPrescProcedimentReset,
  patNrPrescProcedimentGetEntity,
  patNrPrescProcedimentCreateEntity,
  patNrPrescProcedimentDeleteEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PatientNursingPrescriptionUpdate);
