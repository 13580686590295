import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import BillingXml from './billing-xml';

import BillingXmlDetail from './billing-xml-detail';

import BillingXmlUpdate from './billing-xml-update';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={BillingXmlUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={BillingXmlUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={BillingXmlDetail} />
      <ErrorBoundaryRoute path={match.path} component={BillingXml} />
    </Switch>
  </>
);

export default Routes;
