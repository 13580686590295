import React, { useEffect, useRef, useState } from "react";
import { Body, ButtonPagination, ButtonSearch, Card, InputReport, MenuPagination, Modal, OptionsReportName, OptionsTextDiv, OptionsTextName, SelectStatus, SubTittleDeposit, TableCard, TableColumn, TableRow, TittleDeposit, TittleRow } from "./styled";
import Select from 'react-select';
import Axios from "axios";
import { saveAs } from 'file-saver';
import XLSX from 'xlsx';
import { toast } from "react-toastify";

const Deposit = () => {
  const [depositTableData, setDepositTableData] = useState(
    {
    name: '',
    local: '',
    creat_date: new Date(),
    activity: 1,
  }
  )


  const handleChange = (e) => {
    const { name, value } = e.target;
    setDepositTableData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  const buttonRegister = () => {
    Axios
    .post('/api/deposit-table',
    depositTableData
    )
    .then((res) => {
      console.log(res)
      setDepositTableData(
        {
          name: '',
          local: '',
          creat_date: new Date(),
          activity: 1,
        }
      )
      toast.success('Cadastrado com sucesso');
    })
    .catch((err) => {
      console.log(err)
      toast.error('Algo deu errado');
    })

  }

  const buttonGetAll = () => {
    Axios
    .get('/api/deposit-table')
    .then((res) => {
      console.log(res)
    })
    .catch((err) => {
      console.log(err)
    })

  }


  useEffect(() => {
    buttonGetAll()
  },[])

  return (
    <>
    <Body>
      <TittleDeposit>Deposito de farmácia</TittleDeposit>
      <SubTittleDeposit>inicio</SubTittleDeposit>
      <Card>
        <div style={{display: "flex", flexDirection: "row"}}>
          <div style={{display: "flex", flexDirection: "column", marginRight: 20}}>
            <text>Nome</text>
        <InputReport
          name="name"
          value={depositTableData.name}
          onChange={handleChange}
        ></InputReport>
        </div>
        <div style={{display: "flex", flexDirection: "column"}}>
            <text>Local</text>
        <InputReport
          name="local"
          value={depositTableData.local}
          onChange={handleChange}
        ></InputReport>
        </div>
        </div>
        <ButtonSearch onClick={() => buttonRegister()}>Registrar</ButtonSearch>
      </Card>
      <TableCard>

      </TableCard>
    </Body>
  </>
  )
}

export default Deposit;