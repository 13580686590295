import React from 'react';
// import { AvGroup } from 'availity-reactstrap-validation';
import { Row, Col, Label } from 'reactstrap';
import { Translate, translate } from 'app/config/translate-component';
import SelectAsync from 'react-select/async';
import { getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';

export interface ISupplierGlosaProps {
  baseState?: any;
  baseProps?: any;
  setState?: any;
  entityBase?: any;
}

const SupplierGlosaComponentCustomize = (props: ISupplierGlosaProps) => {
  const isNew = props.entityBase.id && true;
  return (
    <Row>
      <Col md="12">
        <Label className="mt-2 label-single-line" for="client-glosa-quote">
          <Translate contentKey="generadorApp.quote.detail.title">Quote</Translate>
        </Label>
      </Col>
      <Col md="12">
        <SelectAsync
          isDisabled={isNew}
          id="client-glosa-quote"
          name={'quote'}
          className={'css-select-control'}
          data-type-rel="many-to-one-other-side"
          value={props.baseState.quoteSelectValue ? props.baseState.quoteSelectValue : {}}
          onChange={async options => {
            const suppliersListId = options.quoteProductService
              .map(v => (v && v.comercialTable && v.comercialTable ? v.comercialTable.supplierTable : null))
              .map(v => (v && v.supplier ? v.supplier.id : null))
              .filter(v => v);
            const suppliersList = await getListAxios(
              'suppliers',
              {
                'id.in': suppliersListId.join(','),
              },
              0,
              100,
              'fantasyName,asc',
              'fantasyName,supplierTable.supplierTableProductService.quoteProductService.quote.id'
            );
            props.setState({
              quoteSelectValue: options,
              supplierSelectValue: null,
              supplierStartSelectOptions:
                suppliersList && suppliersList.data
                  ? suppliersList.data.map(p => {
                      return {
                        ...p,
                        value: p.id,
                        label: showFieldsSelectAsync(p, 'fantasyName') || '',
                      };
                    })
                  : [],
            });
          }}
          defaultOptions={props.baseState.quoteStartSelectOptions ? props.baseState.quoteStartSelectOptions : []}
          loadingMessage={input => translate('selectAsync.loadingMessage')}
          noOptionsMessage={input =>
            props.baseState.quoteStartSelectOptions === undefined
              ? translate('selectAsync.loadingMessage')
              : translate('selectAsync.noOptionsMessage')
          }
          onMenuOpen={async () => {
            if (props.baseState.quoteStartSelectOptions === undefined) {
              const result = await getListAxios(
                'quotes',
                {
                  // 'status.in': 'APROVADO,PLANTAOAPROVADO,INATIVOREPROVADOPRECO,INATIVOCONTROLEINTERNO',
                  'id.equals': 73,
                },
                0,
                100,
                'id,asc',
                'id,quoteProductService.comercialTable.supplierTable.supplier.id'
              );
              props.setState({
                quoteStartSelectOptions: result.data
                  ? result.data.map(p => ({
                      ...p,
                      value: p.id,
                      label: showFieldsSelectAsync(p, 'id') || '',
                    }))
                  : [],
              });
            }
          }}
          loadOptions={async (inputValue, callback) => {
            const result = await getListAxios(
              'quotes',
              {
                'status.in': 'APROVADO,PLANTAOAPROVADO,INATIVOREPROVADOPRECO,INATIVOCONTROLEINTERNO',
                'id.greaterThan': 0,
                'id.contains': inputValue,
              },
              0,
              100,
              'id,asc',
              'id'
            );
            callback(result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' })) : []);
          }}
        />
      </Col>
    </Row>
  );
};

const mapStateToProps = storeState => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default SupplierGlosaComponentCustomize;
