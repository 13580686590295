/* eslint-disable no-console */
/* eslint complexity: ["error", 300] */
import React from 'react';
import { connect } from 'react-redux';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);

import { Translate } from 'app/config/translate-component';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { PatientStatusHistory, mapStateToProps, mapDispatchToProps } from '../patient-status-history';
import { updateEntity as updateEntityPatient } from '../../patient/patient.reducer';
import { IRootState } from 'app/shared/reducers';

import { PatientStatus } from 'app/shared/model/enumerations/patient-status.model';
import { callFunction } from 'app/shared/reports/jhi-reports.reducer';
import { getFormFromSelect } from 'app/shared/util/entity-utils';

export interface IExtendedState {
  dumystate?: any;
}
export interface IExtendedProps {
  entityEmbebedPai?: any;
}

export class PatientStatusHistoryExtended extends PatientStatusHistory {
  constructor(props: any) {
    super(props);
    this.state = {
      ...this.state,
      sort: 'id',
      order: 'DESC',
    };
  }

  saveEntity = (event: any, errors: any, values: any, isNew: any) => {
    let patient = null; // many-to-one -- ownerSide=false
    this.props.patients.forEach(e => {
      if (e.id === this.state.patientSelectValue) {
        patient = e;
      }
    });

    if (errors.length === 0) {
      const { patientStatusHistoryEntity } = this.props;
      const entity = {
        ...patientStatusHistoryEntity,
        ...values,
        patient: getFormFromSelect(this.state.patientSelectValue, 'many-to-one'), // many-to-one - otherSide
        status: this.state.statusSelectValue === null ? 'ATIVO' : this.state.statusSelectValue,
        observation: this.state.observationSelectValue,
      };

      const { status, observation, patientId } = this.state.fieldsBase;
      const entityIdBase = parseInt(patientId && patientId.length > 0 && patientId[0].value ? patientId[0].value : '', 10);
      if (isNew) {
        if (this.state.statusSelectValue === PatientStatus.OBITO) {
          MySwal.fire({
            title: <Translate contentKey={`generadorApp.cancel_quote`} />,
            html: <Translate contentKey={`generadorApp.want_to_cancel_quote`} />,
            showConfirmButton: true,
            showCancelButton: true,
          }).then(async response => {
            if (response.isConfirmed) {
              this.props.updateEntityPatient({ id: entityIdBase, status: PatientStatus.OBITO }, { reloadList: false });
              this.props.createEntity(entity, [status, observation, entityIdBase, 0, 10000, 'id,DESC']);
              const replayFuction = await callFunction('fnChangePadAttendanceStatusByPatientStatus', {
                kitId: entityIdBase,
              });
              this.props.history.push('/patient');
            }
          });
        } else {
          this.props.updateEntityPatient(
            { id: entityIdBase, status: this.state.statusSelectValue === null ? 'ATIVO' : this.state.statusSelectValue },
            { reloadList: false }
          );
          this.props.createEntity(entity, [status, observation, entityIdBase, 0, 10000, 'id,DESC']);
        }
      } else {
        this.props.updateEntity(entity, [status, observation, entityIdBase, 0, 10000, 'id,DESC']);
      }
    }
    this.setState({ showModalForm: false });
    this.setState({ showModalEdit: null });
  };

  renderHeader() {
    return <> {super.renderHeader()} </>;
  }

  renderFilter() {
    return <> {super.renderFilter()} </>;
  }

  renderTable() {
    return <> {super.renderTable()} </>;
  }

  renderFooter() {
    return <> {super.renderFooter()} </>;
  }

  render() {
    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel>
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderTable()}

            {this.renderNewModal()}

            {this.renderUpdateModal()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
  updateEntityPatient,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;

export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(PatientStatusHistoryExtended);
