/* eslint complexity: ["error", 500] */
import React from 'react';
import axios from 'axios';

import CurrencyInput from 'react-currency-input';
import { connect } from 'react-redux';

import {
  getFilterFromSelect,
  getFormFromSelect,
  getListAxios,
  getValueRecursive,
  showFieldsSelectAsync,
} from 'app/shared/util/entity-utils';
import Select from 'react-select';
import { toast } from 'react-toastify';
import SelectAsync from 'react-select/async';
import { Link, RouteComponentProps } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { Button, Col, Row, Table, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { setFileData, JhiItemCount } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { IRootState } from 'app/shared/reducers';
import {
  updateEntity,
  getUrlBack,
  getPadAttendanceState,
  IPadAttendanceBaseState,
  getEntityFiltersURL,
  getEntities,
  IPadAttendanceUpdateState as IBaseUpdateState,
  reset,
  getEntity,
  deleteEntity,
  createEntity,
  setBlob,
} from './pad-attendance.reducer';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE, IPaginationBaseState, getSortState } from 'app/shared/util/pagination.constants';

import { StatePropsExtended, DispatchPropsExtended, IExtendedState, IExtendedProps } from './extended/pad-attendance';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';
import moment from 'moment';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

export interface IPadAttendanceProps
  extends StateProps,
    DispatchProps,
    StatePropsExtended,
    DispatchPropsExtended,
    IExtendedProps,
    RouteComponentProps<{ url: string }> {
  entityEmbebedPai?: any;
}

export interface IPadAttendanceState extends IPadAttendanceBaseState, IPaginationBaseState, IBaseUpdateState, IExtendedState {
  showModalForm?: boolean;
  showModalEdit?: number;
  showModalEditView?: boolean;
}

export class PadAttendance extends React.Component<IPadAttendanceProps, IPadAttendanceState> {
  protected myFormRef: any;
  protected myFormRefNew: any;
  protected myFormRefEdit: any;

  periodFileInput: any;

  scheduleFileInput: any;

  pushFileInput: any;

  evolutionFileInput: any;

  valuePadFileInput: any;

  frequencyFileInput: any;

  quantityFileInput: any;

  dateFileInput: any;

  statusFileInput: any;

  cancellationReasonFileInput: any;

  checkInFileInput: any;

  checkOutFileInput: any;

  latFileInput: any;

  lngFileInput: any;

  constructor(props: IPadAttendanceProps) {
    super(props);
    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      listCheckedID: [],
      isCheckedAll: false,
      showModalForm: false,
      showModalEdit: null,
      urlBack: getUrlBack(this.props.location),
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      ...getPadAttendanceState(this.props.location),
      padId: [
        {
          ...(this.props.entityEmbebedPai ? this.props.entityEmbebedPai : {}),
          value: this.props.match.params['idPad'],
          label: this.props.match.params['idPad'],
        },
      ],
      baseFilters: 'pad',
      padAttendanceFileSelectValue: null,

      professionalSelectValue: null,

      padSelectValue: {
        ...(this.props.entityEmbebedPai ? this.props.entityEmbebedPai : {}),
        value: this.props.match.params['idPad'],
        label: this.props.match.params['idPad'],
      },

      quoteRhSelectValue: null,

      periodSelectValue: null,
      scheduleSelectValue: null,
      pushSelectValue: null,
      evolutionSelectValue: null,
      valuePadSelectValue: null,
      frequencySelectValue: null,
      quantitySelectValue: null,
      dateSelectValue: null,
      statusSelectValue: null,
      cancellationReasonSelectValue: null,
      checkInSelectValue: null,
      checkOutSelectValue: null,
      latSelectValue: null,
      lngSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getPadAttendanceState(this.props.location),
        padId: [
          {
            ...(this.props.entityEmbebedPai ? this.props.entityEmbebedPai : {}),
            value: this.props.match.params['idPad'],
            label: this.props.match.params['idPad'],
          },
        ],
        baseFilters: 'pad',
      },
      professionalName: null,
      quoteRhId: null,
      isNew: false,
    };

    axios
      .get('/api/pads/' + this.props.match.params['idPad'], {
        headers: {
          'Select-Fields': 'id',
        },
      })
      .then(result => {
        this.setState({
          padSelectValue: {
            ...result['data'],
            value: this.props.match.params['idPad'],
            label: showFieldsSelectAsync(result['data'], 'id'),
          },
        });
      });
  }

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({ listCheckedID: this.props.padAttendanceList.map(padAttendance => padAttendance.id) });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({ listCheckedID: this.props.padAttendanceList.map(padAttendance => padAttendance.id) });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  removeEntitySelected() {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  }

  saveEntityMany = () => {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  };

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  cancelFilters = () => {
    this.setState(
      {
        padId: '',
        period: '',
        schedule: '',
        push: '',
        frequency: '',
        valuePad: '',
        quantity: '',
        dateStart: '',
        dateEnd: '',
        quoteRhId: '',
        quoteRhQuoteId: '',
        quoteRhQuotePatientId: '',
        professionalId: '',
        status: '',
        quoteRhQuotePatientClientId: '',
        quoteRhSpecialtyCategoryId: '',
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
        activePage: 0,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();

    const selectFieldsList = [
      'quoteRh.specialty.category.name',
      'quoteRh.specialty.name',
      'quoteRh.quote.patient.name',
      'professional.name',
      'date',
      'period',
      'pad.id',
    ];
    const {
      padId,
      period,
      schedule,
      push,
      frequency,
      valuePad,
      quantity,
      dateStart,
      dateEnd,
      quoteRhId,
      quoteRhQuoteId,
      quoteRhQuotePatientId,
      professionalId,
      status,
      quoteRhQuotePatientClientId,
      quoteRhSpecialtyCategoryId,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;
    return this.props.getEntities(
      getFilterFromSelect(padId, 'many-to-one'),
      period,
      schedule,
      push,
      frequency,
      valuePad,
      quantity,
      dateStart,
      dateEnd,
      getFilterFromSelect(quoteRhId, 'many-to-one'),
      getFilterFromSelect(quoteRhQuoteId, 'many-to-one'),
      getFilterFromSelect(quoteRhQuotePatientId, 'many-to-one'),
      getFilterFromSelect(professionalId, 'many-to-one'),
      status,
      getFilterFromSelect(quoteRhQuotePatientClientId, 'many-to-one'),
      getFilterFromSelect(quoteRhSpecialtyCategoryId, 'many-to-one'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(',')
    );
  };

  updateEntity = entity => {
    const selectFieldsList = [
      'quoteRh.specialty.category.name',
      'quoteRh.specialty.name',
      'quoteRh.quote.patient.name',
      'professional.name',
      'date',
      'period',
      'pad.id',
    ];

    const {
      padId,
      period,
      schedule,
      push,
      frequency,
      valuePad,
      quantity,
      dateStart,
      dateEnd,
      quoteRhId,
      quoteRhQuoteId,
      quoteRhQuotePatientId,
      professionalId,
      status,
      quoteRhQuotePatientClientId,
      quoteRhSpecialtyCategoryId,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;
    this.props.updateEntity(entity, [
      getFilterFromSelect(padId, 'many-to-one'),
      period,
      schedule,
      push,
      frequency,
      valuePad,
      quantity,
      dateStart,
      dateEnd,
      getFilterFromSelect(quoteRhId, 'many-to-one'),
      getFilterFromSelect(quoteRhQuoteId, 'many-to-one'),
      getFilterFromSelect(quoteRhQuotePatientId, 'many-to-one'),
      getFilterFromSelect(professionalId, 'many-to-one'),
      status,
      getFilterFromSelect(quoteRhQuotePatientClientId, 'many-to-one'),
      getFilterFromSelect(quoteRhSpecialtyCategoryId, 'many-to-one'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(','),
    ]);
  };

  onBlobChange = (isAnImage, name, fileInput) => event => {
    const fileName = event.target.files[0].name;
    setFileData(event, (contentType, data) => this.props.setBlob(name, data, contentType, fileName), isAnImage);
    const varAux = {};
    varAux[name + 'SelectValue'] = fileName;
    this.setState(varAux);
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined);
  };

  saveEntity = (event: any, errors: any, values: any, isNew: any) => {
    const selectFieldsList = [
      'quoteRh.specialty.category.name',
      'quoteRh.specialty.name',
      'quoteRh.quote.patient.name',
      'professional.name',
      'date',
      'period',
      'pad.id',
    ];

    if (errors.length === 0) {
      const { padAttendanceEntity } = this.props;
      const _entity = {
        ...padAttendanceEntity,
        // ...values,

        pad: getFormFromSelect(this.state.padSelectValue, 'many-to-one'), // many-to-one - otherSide

        quoteRh: getFormFromSelect(this.state.quoteRhSelectValue, 'many-to-one'), // many-to-one - otherSide

        professional: getFormFromSelect(this.state.professionalSelectValue, 'many-to-one'), // many-to-one - otherSide

        period: this.state.periodSelectValue === null ? null : this.state.periodSelectValue,
        schedule: this.state.scheduleSelectValue,
        push: this.state.pushSelectValue,
        evolution: this.state.evolutionSelectValue,
        valuePad: this.state.valuePadSelectValue,
        frequency: this.state.frequencySelectValue === null ? null : this.state.frequencySelectValue,
        quantity: this.state.quantitySelectValue,
        date: this.state.dateSelectValue,
        status: this.state.statusSelectValue === null ? null : this.state.statusSelectValue,
        cancellationReason: this.state.cancellationReasonSelectValue,
        checkIn: this.state.checkInSelectValue,
        checkOut: this.state.checkOutSelectValue,
        lat: this.state.latSelectValue,
        lng: this.state.lngSelectValue,
      };
      const entity = _entity;

      const {
        padId,
        period,
        schedule,
        push,
        frequency,
        valuePad,
        quantity,
        dateStart,
        dateEnd,
        quoteRhId,
        quoteRhQuoteId,
        quoteRhQuotePatientId,
        professionalId,
        status,
        quoteRhQuotePatientClientId,
        quoteRhSpecialtyCategoryId,
        extraFilters,
        activePage,
        itemsPerPage,
        sort,
        order,
      } = this.state.fieldsBase;

      if (isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              getFilterFromSelect(padId, 'many-to-one'),
              period,
              schedule,
              push,
              frequency,
              valuePad,
              quantity,
              dateStart,
              dateEnd,
              getFilterFromSelect(quoteRhId, 'many-to-one'),
              getFilterFromSelect(quoteRhQuoteId, 'many-to-one'),
              getFilterFromSelect(quoteRhQuotePatientId, 'many-to-one'),
              getFilterFromSelect(professionalId, 'many-to-one'),
              status,
              getFilterFromSelect(quoteRhQuotePatientClientId, 'many-to-one'),
              getFilterFromSelect(quoteRhSpecialtyCategoryId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              getFilterFromSelect(padId, 'many-to-one'),
              period,
              schedule,
              push,
              frequency,
              valuePad,
              quantity,
              dateStart,
              dateEnd,
              getFilterFromSelect(quoteRhId, 'many-to-one'),
              getFilterFromSelect(quoteRhQuoteId, 'many-to-one'),
              getFilterFromSelect(quoteRhQuotePatientId, 'many-to-one'),
              getFilterFromSelect(professionalId, 'many-to-one'),
              status,
              getFilterFromSelect(quoteRhQuotePatientClientId, 'many-to-one'),
              getFilterFromSelect(quoteRhSpecialtyCategoryId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
      this.setState({ showModalForm: false });
      this.setState({ showModalEdit: null });
    } else {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.specialty.' + errors[0]) }));
    }
  };

  componentDidUpdate(prevProps) {}

  deleteEntity(padAttendanceEntity) {
    MySwal.fire({
      title: <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>,
      html: (
        <Translate contentKey="generadorApp.padAttendance.delete.question" interpolate={{ id: padAttendanceEntity.id }}>
          Are you sure you want to delete this PadAttendance?
        </Translate>
      ),
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#e70015',
      cancelButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="ban" />
          &nbsp; <Translate contentKey="generadorApp.padAttendance.btnCancel">Cancel</Translate>
        </>
      ),
      confirmButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="trash" />
          &nbsp;<Translate contentKey="generadorApp.padAttendance.btnDelete">Delete</Translate>
        </>
      ),
    }).then(async response => {
      if (response.isConfirmed) {
        await this.props.deleteEntity(padAttendanceEntity.id, { reloadList: false });
        this.getEntities();
        MySwal.fire({
          title: <Translate contentKey="entity.delete.title.confirmed">Confirmed delete operation</Translate>,
          icon: 'success',
        });
      }
    });
  }

  renderNewModal() {
    return (
      <Modal size={'xl'} isOpen={this.state.showModalForm} toggle={() => this.setState({ showModalForm: false })}>
        <ModalHeader toggle={() => this.setState({ showModalForm: false })}>{this.renderHeaderUpdate({}, true)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefNew = el)}
          model={{}}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, true)}
        >
          <ModalBody>{this.renderBodyUpdate({}, true)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalForm: false })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.padAttendance.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefNew && this.myFormRefNew.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.padAttendance.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    );
  }

  getFullEntityFromState(v) {
    return {
      id: v.id,
      period: this.state.periodSelectValue,
      schedule: this.state.scheduleSelectValue,
      push: this.state.pushSelectValue,
      evolution: this.state.evolutionSelectValue,
      valuePad: this.state.valuePadSelectValue,
      frequency: this.state.frequencySelectValue,
      quantity: this.state.quantitySelectValue,
      date: this.state.dateSelectValue,
      status: this.state.statusSelectValue,
      cancellationReason: this.state.cancellationReasonSelectValue,
      checkIn: this.state.checkInSelectValue,
      checkOut: this.state.checkOutSelectValue,
      lat: this.state.latSelectValue,
      lng: this.state.lngSelectValue,
      padAttendanceFile: this.state.padAttendanceFileSelectValue,
      professional: this.state.professionalSelectValue,
      pad: this.state.padSelectValue,
      quoteRh: this.state.quoteRhSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    let p: any = null;

    const padAttendanceFileEntity = v.padAttendanceFile
      ? v.padAttendanceFile.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' }))
      : v.padAttendanceFile;

    p = v.professional;
    const professionalEntity = p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : p;

    p = v.pad;
    const padEntity = p ? { ...p, value: p.id, label: p['id'] ? p.id : '' } : p;

    p = v.quoteRh;
    const quoteRhEntity = p ? { ...p, value: p.id, label: p['id'] ? p.id : '' } : p;

    return {
      periodSelectValue: v.period, // period,
      scheduleSelectValue: v.schedule, // schedule,
      pushSelectValue: v.push, // push,
      evolutionSelectValue: v.evolution, // evolution,
      valuePadSelectValue: v.valuePad, // valuePad,
      frequencySelectValue: v.frequency, // frequency,
      quantitySelectValue: v.quantity, // quantity,
      dateSelectValue: v.date ? moment(v.date).toDate() : null, // LocalDate,
      statusSelectValue: v.status, // status,
      cancellationReasonSelectValue: v.cancellationReason, // cancellationReason,
      checkInSelectValue: v.checkIn, // checkIn,
      checkOutSelectValue: v.checkOut, // checkOut,
      latSelectValue: v.lat, // lat,
      lngSelectValue: v.lng, // lng,
      padAttendanceFileSelectValue: padAttendanceFileEntity,
      professionalSelectValue: professionalEntity,
      padSelectValue: padEntity,
      quoteRhSelectValue: quoteRhEntity,
    };
  }

  openUpdateModal(v, edit = true) {
    this.props.getEntity(v.id);
    this.setState({
      showModalEdit: v.id,
      showModalEditView: edit,
      ...this.getFullEntitySelectValue(v),
    });
  }

  renderUpdateModal() {
    const { padAttendanceEntity, entityEmbebedPai } = this.props;
    
    return padAttendanceEntity && padAttendanceEntity.id ? (
      <Modal size={'xl'} isOpen={this.state.showModalEdit === padAttendanceEntity.id} toggle={() => this.setState({ showModalEdit: null })}>
        <ModalHeader toggle={() => this.setState({ showModalEdit: null })}>
          {this.renderHeaderUpdate(padAttendanceEntity, false)}
        </ModalHeader>
        <AvForm
          ref={el => (this.myFormRefEdit = el)}
          model={{
            ...padAttendanceEntity,
          }}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, false)}
        >
          <ModalBody>{this.renderBodyUpdate(padAttendanceEntity, false)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalEdit: null })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.padAttendance.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefEdit && this.myFormRefEdit.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.padAttendance.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    ) : (
      <> </>
    );
  }

  renderHeaderUpdate(padAttendanceEntity, isNew) {
    const { entityEmbebedPai, padAttendanceFiles, professionals, pads, quoteRhs, loading } = this.props;

    const { push, evolution } = padAttendanceEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {isNew ? (
              <Translate contentKey="generadorApp.padAttendance.home.createLabel">Create a PadAttendance</Translate>
            ) : (
              <Translate contentKey="generadorApp.padAttendance.home.editLabel">Edit a PadAttendance</Translate>
            )}
          </span>
        </div>
      </>
    );
  }
  renderBodyUpdate(padAttendanceEntity, isNew) {
    const { entityEmbebedPai, padAttendanceFiles, professionals, pads, quoteRhs, loading } = this.props;

    const { push, evolution } = padAttendanceEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
              <Col md="3">
              <Label className="mt-2 label-single-line"for="pad-attendance-id">
                <Translate contentKey="generadorApp.padAttendance.field_id">ID</Translate>
              </Label>
              </Col> */}
                      <Col md="12">
                        <AvInput id="pad-attendance-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'pad' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="pad-attendance-pad">
                              <Translate contentKey="generadorApp.padAttendance.pad">Pad</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="pad-attendance-pad"
                              name={'pad'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.padSelectValue ? this.state.padSelectValue : ''}
                              onChange={options => this.setState({ padSelectValue: options })}
                              defaultOptions={this.state.padStartSelectOptions ? this.state.padStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.padStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.padStartSelectOptions === undefined) {
                                  const result = await getListAxios('pads', {}, 0, 100, 'id,asc', 'id');
                                  this.setState({
                                    padStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios('pads', { 'id.contains': inputValue }, 0, 100, 'id,asc', 'id');
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="pad"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}
                  {baseFilters !== 'period' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="periodLabel" for="pad-attendance-period">
                              <Translate contentKey="generadorApp.padAttendance.period">Period</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="pad-attendance-period"
                              className={'css-select-control'}
                              value={
                                this.state.periodSelectValue
                                  ? {
                                      value: this.state.periodSelectValue,
                                      label: translate('generadorApp.PadAttendancePeriod.' + this.state.periodSelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.padAttendance.period') }
                              }
                              options={[
                                { value: 'MATUTINO', label: translate('generadorApp.PadAttendancePeriod.MATUTINO') },
                                { value: 'VESPERTINO', label: translate('generadorApp.PadAttendancePeriod.VESPERTINO') },
                                { value: 'NOTURNO', label: translate('generadorApp.PadAttendancePeriod.NOTURNO') },
                              ]}
                              onChange={(options: any) => this.setState({ periodSelectValue: options['value'] })}
                              name="period"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ periodSelectValue: evt.target.value })}
                      type="hidden"
                      name="period"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'schedule' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="scheduleLabel" for="pad-attendance-schedule">
                              <Translate contentKey="generadorApp.padAttendance.schedule">Schedule</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <InputMask
                              onChange={evt => this.setState({ scheduleSelectValue: evt.target.value })}
                              value={this.state.scheduleSelectValue ? this.state.scheduleSelectValue : ''}
                              id="pad-attendance-schedule"
                              type="text"
                              name="schedule"
                              className={'form-control'}
                              mask="99:99"
                              tag={InputMask}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ scheduleSelectValue: evt.target.value })}
                      type="hidden"
                      name="schedule"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'push' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="pushLabel" for="pad-attendance-push">
                              <Translate contentKey="generadorApp.padAttendance.push">Push</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <AvInput
                              onChange={evt => this.setState({ pushSelectValue: evt.target.value })}
                              validate={{}}
                              id="pad-attendance-push"
                              type="textarea"
                              name="push"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ pushSelectValue: evt.target.value })}
                      type="hidden"
                      name="push"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'date' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="dateLabel" for="pad-attendance-date">
                              <Translate contentKey="generadorApp.padAttendance.date">Date</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <DatePicker
                              autoComplete="off"
                              onChange={value => this.setState({ dateSelectValue: value })}
                              id="date"
                              validate={{}}
                              className="form-control react-datepicker"
                              name="date"
                              dateFormat="dd/MM/yyyy"
                              selected={this.state.dateSelectValue ? moment(this.state.dateSelectValue).toDate() : null}
                              locale="pt"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ dateSelectValue: evt.target.value })}
                      type="hidden"
                      name="date"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'frequency' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="frequencyLabel" for="pad-attendance-frequency">
                              <Translate contentKey="generadorApp.padAttendance.frequency">Frequency</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="pad-attendance-frequency"
                              className={'css-select-control'}
                              value={
                                this.state.frequencySelectValue
                                  ? {
                                      value: this.state.frequencySelectValue,
                                      label: translate('generadorApp.QuoteFrequency.' + this.state.frequencySelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.padAttendance.frequency') }
                              }
                              options={[
                                { value: 'UMXSEMANA', label: translate('generadorApp.QuoteFrequency.UMXSEMANA') },
                                { value: 'DOISXSEMANA', label: translate('generadorApp.QuoteFrequency.DOISXSEMANA') },
                                { value: 'TRESXSEMANA', label: translate('generadorApp.QuoteFrequency.TRESXSEMANA') },
                                { value: 'QUATROXSEMANA', label: translate('generadorApp.QuoteFrequency.QUATROXSEMANA') },
                                { value: 'CINCOXSEMANA', label: translate('generadorApp.QuoteFrequency.CINCOXSEMANA') },
                                { value: 'SEISXSEMANA', label: translate('generadorApp.QuoteFrequency.SEISXSEMANA') },
                                { value: 'UMXDIA', label: translate('generadorApp.QuoteFrequency.UMXDIA') },
                                { value: 'DOISXDIA', label: translate('generadorApp.QuoteFrequency.DOISXDIA') },
                                { value: 'TRESXDIA', label: translate('generadorApp.QuoteFrequency.TRESXDIA') },
                                { value: 'QUATROXDIA', label: translate('generadorApp.QuoteFrequency.QUATROXDIA') },
                                { value: 'CINCOXDIA', label: translate('generadorApp.QuoteFrequency.CINCOXDIA') },
                                { value: 'SEISXDIA', label: translate('generadorApp.QuoteFrequency.SEISXDIA') },
                                { value: 'UMXMES', label: translate('generadorApp.QuoteFrequency.UMXMES') },
                                { value: 'DOISXMES', label: translate('generadorApp.QuoteFrequency.DOISXMES') },
                                { value: 'TRESXMES', label: translate('generadorApp.QuoteFrequency.TRESXMES') },
                                { value: 'QUATROXMES', label: translate('generadorApp.QuoteFrequency.QUATROXMES') },
                                { value: 'CADA48HORAS', label: translate('generadorApp.QuoteFrequency.CADA48HORAS') },
                                { value: 'CADA72HORAS', label: translate('generadorApp.QuoteFrequency.CADA72HORAS') },
                                { value: 'PONTUAL', label: translate('generadorApp.QuoteFrequency.PONTUAL') },
                              ]}
                              onChange={(options: any) => this.setState({ frequencySelectValue: options['value'] })}
                              name="frequency"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ frequencySelectValue: evt.target.value })}
                      type="hidden"
                      name="frequency"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'valuePad' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="valuePadLabel" for="pad-attendance-valuePad">
                              <Translate contentKey="generadorApp.padAttendance.valuePad">Value</Translate>
                            </Label>
                          </Col>

                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ valuePadSelectValue: floatvalue })}
                              decimalSeparator=","
                              precision={2}
                              thousandSeparator=""
                              prefix="R$ "
                              allowNegative={true}
                              value={this.state.valuePadSelectValue ? this.state.valuePadSelectValue : 0}
                              id="pad-attendance-valuePad"
                              name="valuePad"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ valuePadSelectValue: evt.target.value })}
                      type="hidden"
                      name="valuePad"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'quantity' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="quantityLabel" for="pad-attendance-quantity">
                              <Translate contentKey="generadorApp.padAttendance.quantity">Quantity</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ quantitySelectValue: floatvalue })}
                              decimalSeparator=""
                              thousandSeparator=""
                              precision={0}
                              prefix=""
                              allowNegative={true}
                              value={this.state.quantitySelectValue ? this.state.quantitySelectValue : 0}
                              id="pad-attendance-quantity"
                              name="quantity"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ quantitySelectValue: evt.target.value })}
                      type="hidden"
                      name="quantity"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'quoteRh' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="pad-attendance-quoteRh">
                              <Translate contentKey="generadorApp.padAttendance.quoteRh">Quote Rh</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="pad-attendance-quoteRh"
                              name={'quoteRh'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.quoteRhSelectValue ? this.state.quoteRhSelectValue : ''}
                              onChange={options => this.setState({ quoteRhSelectValue: options })}
                              defaultOptions={this.state.quoteRhStartSelectOptions ? this.state.quoteRhStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.quoteRhStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.quoteRhStartSelectOptions === undefined) {
                                  const result = await getListAxios('quote-rhs', {}, 0, 100, 'id,asc', 'id');
                                  this.setState({
                                    quoteRhStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios('quote-rhs', { 'id.contains': inputValue }, 0, 100, 'id,asc', 'id');
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="quoteRh"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}
                  {baseFilters !== 'professional' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="pad-attendance-professional">
                              <Translate contentKey="generadorApp.padAttendance.professional">Professional</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="pad-attendance-professional"
                              name={'professional'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.professionalSelectValue ? this.state.professionalSelectValue : ''}
                              onChange={options => this.setState({ professionalSelectValue: options })}
                              defaultOptions={this.state.professionalStartSelectOptions ? this.state.professionalStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.professionalStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.professionalStartSelectOptions === undefined) {
                                  const result = await getListAxios('professionals', {}, 0, 100, 'name,asc', 'name');
                                  this.setState({
                                    professionalStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'professionals',
                                  { 'name.contains': inputValue },
                                  0,
                                  100,
                                  'name,asc',
                                  'name'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="professional"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}{' '}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  renderHeader() {
    const { padAttendanceFiles, professionals, pads, quoteRhs, padAttendanceList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.padAttendance.home.title">Attendance</Translate>
          </span>
          {hasAnyAuthority(props.userAccount, ['pad'], 'resgister') ? (
            <Button
              onClick={() =>
                this.setState({
                  showModalForm: true,
                  periodSelectValue: null,
                  scheduleSelectValue: null,
                  pushSelectValue: null,
                  evolutionSelectValue: null,
                  valuePadSelectValue: null,
                  frequencySelectValue: null,
                  quantitySelectValue: null,
                  dateSelectValue: null,
                  statusSelectValue: null,
                  cancellationReasonSelectValue: null,
                  checkInSelectValue: null,
                  checkOutSelectValue: null,
                  latSelectValue: null,
                  lngSelectValue: null,

                  padAttendanceFileStartSelectOptions: undefined,
                  padAttendanceFileSelectValue: null,

                  professionalStartSelectOptions: undefined,
                  professionalSelectValue: null,

                  quoteRhStartSelectOptions: undefined,
                  quoteRhSelectValue: null,
                  isNew: true,
                })
              }
              color="primary"
              className="btn btn-primary float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="plus" />
              &nbsp;
              <Translate contentKey="generadorApp.padAttendance.btnNewItem">Create new</Translate>
            </Button>
          ) : (
            <></>
          )}{' '}
          &nbsp;
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/pad'}>
              <Translate contentKey="generadorApp.pad.home.title">Pads</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/pad/' + this.props.match.params['idPad']}>
              {this.state.padSelectValue ? this.state.padSelectValue.label : this.props.match.params['idPad']}
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.padAttendance.home.title">Attendance</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    return <> </>;
  }

  renderTable() {
    const { padAttendanceFiles, professionals, pads, quoteRhs, padAttendanceList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {padAttendanceList && padAttendanceList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null).length > 0 ? (
          <>
            {this.state.listCheckedID.length > 0 ? (
              <div className="clasificar-all" style={{ textAlign: 'center' }}>
                {this.state.listCheckedID.length === this.state.itemsPerPage ||
                this.state.listCheckedID.length === this.props.totalItems ? (
                  <div className="alert alert-warning pull-left" style={{ color: 'black', padding: '13px 100px' }}>
                    {!this.state.isCheckedAll ? (
                      this.props.totalItems < 20 ? (
                        <>
                          <Translate
                            contentKey="generadorApp.quote.listSelectedButtons.allItensAreSelected"
                            interpolate={{ totalItems: this.props.totalItems }}
                          >
                            Os {this.props.totalItems} Attendance nesta página estão selecionadas.
                          </Translate>
                          &nbsp;
                          <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => this.handleInputAllChange()}>
                            <Translate contentKey="generadorApp.quote.listSelectedButtons.clearSelect">Limpar Seleção</Translate>
                          </span>
                        </>
                      ) : (
                        <>
                          <Translate
                            contentKey="generadorApp.quote.listSelectedButtons.allItensAreSelectedThisPage"
                            interpolate={{ itemsPerPage: this.state.itemsPerPage }}
                          >
                            Todos os {this.state.itemsPerPage} Attendance nesta página estão selecionadas.
                          </Translate>
                          &nbsp;
                          <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => this.handleInputAllChange()}>
                            <Translate
                              contentKey="generadorApp.quote.listSelectedButtons.selectAll"
                              interpolate={{ totalItems: this.props.totalItems }}
                            >
                              Selecionar os {this.props.totalItems} Attendance
                            </Translate>
                          </span>
                        </>
                      )
                    ) : (
                      <>
                        <Translate
                          contentKey="generadorApp.quote.listSelectedButtons.allItensAreSelectedAllPages"
                          interpolate={{ totalItems: this.props.totalItems }}
                        >
                          Todas os {this.props.totalItems} Attendance estão selecionadas.
                        </Translate>
                        &nbsp;
                        <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => this.handleInputAllChange()}>
                          <Translate contentKey="generadorApp.quote.listSelectedButtons.clearSelect">Limpar Seleção</Translate>
                        </span>
                      </>
                    )}
                  </div>
                ) : (
                  <></>
                )}
                <div className={'pull-right'}>
                  <Button className="float-right jh-create-entity" onClick={() => this.removeEntitySelected()} color="primary" size="md">
                    <FontAwesomeIcon icon="trash" />{' '}
                    <span className="d-none d-md-inline">
                      <Translate contentKey="generadorApp.padAttendance.listSelectedButtons.cancel">Cancelar</Translate>
                    </span>
                  </Button>
                </div>
              </div>
            ) : (
              <> </>
            )}
            <Table
              id="pad-attendance-table-list"
              responsive
              aria-describedby="pad-attendance-heading"
              className={'table-hover table-striped table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className={'align-middle text-center'} style={{ width: '4%' }}>
                    <input
                      type="checkbox"
                      checked={
                        this.state.listCheckedID.length === this.state.itemsPerPage ||
                        this.state.listCheckedID.length === this.props.totalItems
                      }
                      onChange={() => this.handleInputAllThisItens()}
                    />
                  </th>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.padAttendance.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'quoteRh.specialty.category.name' ? (
                    <th>
                      <Translate contentKey="generadorApp.padAttendance.quoteRh.specialty.category">Quote Rh</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'quoteRh.specialty.name' ? (
                    <th>
                      <Translate contentKey="generadorApp.padAttendance.quoteRh.specialty">Quote Rh</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'quoteRh.quote.patient.name' ? (
                    <th>
                      <Translate contentKey="generadorApp.padAttendance.quoteRh.quote.patient">Quote Rh</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'professional' ? (
                    <th>
                      <Translate contentKey="generadorApp.padAttendance.professional">Professional</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'date' ? (
                    <th className="hand" onClick={sortFunction('date')}>
                      <Translate contentKey="generadorApp.padAttendance.date">Date</Translate>
                      <FontAwesomeIcon icon={state.sort === 'date' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'period' ? (
                    <th className="hand" onClick={sortFunction('period')}>
                      <Translate contentKey="generadorApp.padAttendance.period">Period</Translate>
                      <FontAwesomeIcon icon={state.sort === 'period' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'pad' ? (
                    <th>
                      <Translate contentKey="generadorApp.padAttendance.pad">Pad</Translate>
                    </th>
                  ) : null}

                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {padAttendanceList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((padAttendance, i) => (
                    <tr key={`entity-${i}`}>
                      <td className={'align-middle text-center'}>
                        <input
                          type="checkbox"
                          checked={this.state.listCheckedID.includes(padAttendance.id)}
                          onChange={() => this.handleInputChange(padAttendance.id)}
                        />
                      </td>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.padAttendance.field_id">ID</Translate>
                        </b>
                        {padAttendance.id}
                      </td>

                      {state.baseFilters !== 'quoteRh.specialty.category' ? (
                        <td id="quoteRh-specialty-category-cell">
                          <b className="visible-xs"> Quote Rh </b>
                          {padAttendance ? (
                            <span data-type-rel="2-many-to-one-other-side">
                              {getValueRecursive(padAttendance, 'quoteRh.specialty.category.name'.split('.'), '')}
                            </span>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'quoteRh.specialty' ? (
                        <td id="quoteRh-specialty-cell">
                          <b className="visible-xs"> Quote Rh </b>
                          {padAttendance ? (
                            <span data-type-rel="2-many-to-one-other-side">
                              {getValueRecursive(padAttendance, 'quoteRh.specialty.name'.split('.'), '')}
                            </span>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'quoteRh.quote.patient' ? (
                        <td id="quoteRh-quote-patient-cell">
                          <b className="visible-xs"> Quote Rh </b>
                          {padAttendance ? (
                            <span data-type-rel="2-many-to-one-other-side">
                              {getValueRecursive(padAttendance, 'quoteRh.quote.patient.name'.split('.'), '')}
                            </span>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'professional' ? (
                        <td id="professional-cell">
                          <b className="visible-xs"> Professional </b>
                          {padAttendance ? (
                            <span data-type-rel="2-many-to-one-other-side">
                              {getValueRecursive(padAttendance, 'professional.name'.split('.'), '')}
                            </span>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'date' ? (
                        <td id="date-cell">
                          <b className="visible-xs"> Date </b>
                          {padAttendance.date ? moment(padAttendance.date).format(APP_LOCAL_DATE_FORMAT) : ''}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'period' ? (
                        <td id="period-cell">
                          <b className="visible-xs"> Period </b>
                          {padAttendance.period ? (
                            <Translate contentKey={`generadorApp.PadAttendancePeriod.${padAttendance.period}`} />
                          ) : (
                            <> </>
                          )}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'pad' ? (
                        <td id="pad-cell">
                          <b className="visible-xs"> Pad </b>
                          {padAttendance ? (
                            <span data-type-rel="2-many-to-one-other-side">
                              {getValueRecursive(padAttendance, 'pad.id'.split('.'), '')}
                            </span>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : null}

                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          {hasAnyAuthority(props.userAccount, ['pad'], 'canDelete') ? (
                            <Button onClick={() => this.deleteEntity(padAttendance)} color="danger" size="sm">
                              <FontAwesomeIcon icon="trash" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.padAttendance.home.notFound">No Attendance found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const { padAttendanceFiles, professionals, pads, quoteRhs, padAttendanceList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={padAttendanceList && padAttendanceList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const { padAttendanceFiles, professionals, pads, quoteRhs, padAttendanceList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel className="pad-attendance pad-attendance-all-in-one">
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderTable()}
            {this.renderNewModal()}
            {this.renderUpdateModal()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToProps = ({ padAttendance, ...storeState }: IRootState) => ({
  padAttendanceFiles: storeState.padAttendanceFile.entities,
  professionals: storeState.professional.entities,
  pads: storeState.pad.entities,
  quoteRhs: storeState.quoteRh.entities,
  padAttendanceList: padAttendance.entities,
  totalItems: padAttendance.totalItems,
  userAccount: storeState.authentication.account,
  loading: padAttendance.loading,

  // UPDATE
  padAttendanceEntity: padAttendance.entity,
});

export const mapDispatchToProps = {
  getEntities,

  updateEntity,
  reset,

  // UPDATE
  getEntity,
  setBlob,
  createEntity,

  // DELETE
  deleteEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PadAttendance);
