/* eslint-disable no-console */
/* eslint complexity: ["error", 300] */
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { QuoteUpdate, mapStateToProps, mapDispatchToProps } from '../quote-update';
import { IRootState } from 'app/shared/reducers';

import { AvForm } from 'availity-reactstrap-validation';
import { Row, Col, Button } from 'reactstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);
import { Translate } from 'app/config/translate-component';

import { getFilterFromSelect, getFormFromSelect, getListAxios } from 'app/shared/util/entity-utils';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';
import { getSuppliersByProductService, alterarQuantidadeAtual, getRomaneioReport } from './quote.reducer';

registerLocale('pt', pt);

import { getEntityFiltersURL, updateStateManual } from 'app/entities/quote/quote.reducer';
import { createEntity as createPad, getEntity as getPad } from 'app/entities/pad/pad.reducer';
import { createEntity as createPharmacyStock, getEntity as getPharmacyStock } from 'app/entities/pharmacy-stock/pharmacy-stock.reducer';

import { convertDateTimeToServer } from 'app/shared/util/date-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link } from 'react-router-dom';
import { QuoteStatus } from 'app/shared/model/enumerations/quote-status.model';
import { BASE_API_VERSION_PATH } from 'app/config/constants';
import moment from 'moment';
import { IQuotePackage } from 'app/shared/model/quote-package.model';
import { IQuoteRh } from 'app/shared/model/quote-rh.model';
import { IQuoteMedicine } from 'app/shared/model/quote-medicine.model';
import { IQuoteMaterial } from 'app/shared/model/quote-material.model';
import { IQuoteDiet } from 'app/shared/model/quote-diet.model';
import { IQuoteProductService } from 'app/shared/model/quote-product-service.model';
import quote from '../quote';
import { env } from 'process';

export interface IUpdateExtendedState {
  createPadAfterSubmit?: boolean;
  tableRulersSelectValue?: any;
  tableItensSelectValue?: any;
  showModalRepost?: boolean;
  showModalContaConsumoReport?: boolean;
  showModalGuiaIntegracaoReport?: boolean;
  showModalGuiaServicoReport?: boolean;
}

export class QuoteExtendedUpdate extends QuoteUpdate {
  clComTableAsyncPendind = false;

  UNSAFE_componentWillMount() {
    super.UNSAFE_componentWillMount();
  }

  quotePackageCreateEntity = (...values) => {
    values[0]['quote'] = !this.state.isNew ? { id: this.props.match.params['id'] } : 'is_new';
    if (!this.state.isNew) {
      this.props['createEntityQuotePackage'](...values);
    }
    return this.props.quotePackageCreateEntity(...values);
  };

  quoteRhCreateEntity = (...values) => {
    values[0]['quote'] = !this.state.isNew ? { id: this.props.match.params['id'] } : 'is_new';
    if (!this.state.isNew) {
      this.props['createEntityQuoteRh'](...values);
    }
    return this.props.quoteRhCreateEntity(...values);
  };

  quoteMedicineCreateEntity = (...values) => {
    values[0]['quote'] = !this.state.isNew ? { id: this.props.match.params['id'] } : 'is_new';
    if (!this.state.isNew) {
      this.props['createEntityQuoteMedicine'](...values);
    }
    return this.props.quoteMedicineCreateEntity(...values);
  };

  quoteMaterialCreateEntity = (...values) => {
    values[0]['quote'] = !this.state.isNew ? { id: this.props.match.params['id'] } : 'is_new';
    if (!this.state.isNew) {
      this.props['createEntityQuoteMaterial'](...values);
    }
    return this.props.quoteMaterialCreateEntity(...values);
  };

  quoteDietCreateEntity = (...values) => {
    values[0]['quote'] = !this.state.isNew ? { id: this.props.match.params['id'] } : 'is_new';
    if (!this.state.isNew) {
      this.props['createEntityQuoteDiet'](...values);
    }
    return this.props.quoteDietCreateEntity(...values);
  };

  quoteProductServiceCreateEntity = (...values) => {
    values[0]['quote'] = !this.state.isNew ? { id: this.props.match.params['id'] } : 'is_new';
    if (!this.state.isNew) {
      this.props['createEntityQuoteProductService'](...values);
    }

    return this.props.quoteProductServiceCreateEntity(...values);
  };

  saveEntity = (event: any, errors: any, values: any) => {
    if (
      !['APROVADO', 'PRORROGADO', 'INATIVOREPROVADOPRECO', 'INATIVOCONTROLEINTERNO'].includes(this.props.quoteEntity.status) &&
      ['APROVADO'].includes(this.state.statusSelectValue)
    ) {
      MySwal.fire({
        title: <p>Aprovar Orçamento</p>,
        html: <p>Deseja aprovar o orçamento</p>,
        showConfirmButton: true,
        showCancelButton: true,
      }).then(response => {
        if (response.isConfirmed) {
          return this.setState(
            {
              createPadAfterSubmit: true,
            },
            () => this.saveEntityOld(event, errors, values, true)
          );
        }
      });
    } else {
      return this.setState(
        {
          createPadAfterSubmit: false,
        },
        () => this.saveEntityOld(event, errors, values, false)
      );
    }
  };

  saveEntityOld(event: any, errors: any, values: any, createPadAfter: boolean) {
    values.approvalDate = convertDateTimeToServer(values.approvalDate);
    values.from = convertDateTimeToServer(values.from);
    values.to = convertDateTimeToServer(values.to);

    if (errors.length === 0) {
      const { quoteEntity } = this.props;
      const entity = {
        ...quoteEntity,
        ...values,

        quoteDiet: undefined,

        quoteMedicine: undefined,

        quoteMaterial: undefined,

        quoteProductService: undefined,

        quoteRh: undefined,

        quoteFile: getFormFromSelect(this.state.quoteFileSelectValue, 'one-to-many'), // one-to-many - otherSide

        expectedResult: getFormFromSelect(this.state.expectedResultSelectValue, 'one-to-many'), // one-to-many - otherSide

        pad: getFormFromSelect(this.state.padSelectValue, 'one-to-many'), // one-to-many - otherSide

        supply: getFormFromSelect(this.state.supplySelectValue, 'one-to-many'), // one-to-many - otherSide

        patient: getFormFromSelect(this.state.patientSelectValue, 'many-to-one'), // many-to-one - otherSide

        clComTable: getFormFromSelect(this.state.clComTableSelectValue, 'many-to-one'), // many-to-one - otherSide

        client: getFormFromSelect(this.state.clientSelectValue, 'many-to-one'), // many-to-one - otherSide

        source: this.state.sourceSelectValue === null ? 'AVALIACAO' : this.state.sourceSelectValue,
        status: this.state.statusSelectValue === null ? 'ORCAMENTOABERTO' : this.state.statusSelectValue,
        approvalDate: this.state.approvalDateSelectValue,
        from: this.state.fromSelectValue,
        to: this.state.toSelectValue,
        accountNumber: this.state.accountNumberSelectValue,
        finalAmount: this.state.finalAmountSelectValue,
        billFinalAmount: this.state.billFinalAmountSelectValue,
      };

      const { patientId, patientClientId, source, status, fromStart, fromEnd, toStart, toEnd } = this.state.fieldsBase;

      const quoteProductServiceListAllEmbed = this.props.quoteProductServiceListAllEmbed;
      const quoteRhListAllEmbed = this.props.quoteRhListAllEmbed;
      const quoteMedicineListAllEmbed = this.props.quoteMedicineListAllEmbed;
      const quoteMaterialListAllEmbed = this.props.quoteMaterialListAllEmbed;
      const quoteDietListAllEmbed = this.props.quoteDietListAllEmbed;

      if (this.state.isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(
              entity,
              !createPadAfter
                ? [
                  getFilterFromSelect(patientId, 'many-to-one'),
                  getFilterFromSelect(patientClientId, 'many-to-one'),
                  source,
                  status,
                  fromStart,
                  fromEnd,
                  toStart,
                  toEnd,
                ]
                : { reloadList: false }
            )
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
          if (idEntity) {
            quoteProductServiceListAllEmbed &&
              quoteProductServiceListAllEmbed.map(v => {
                v['id'] = null;
                v['quote'] = { id: idEntity };
                this.props['createEntityQuoteProductService'](v);
              });
          }
          // createPad
          if (idEntity) {
            quoteRhListAllEmbed &&
              quoteRhListAllEmbed.map(v => {
                v['id'] = null;
                v['quote'] = { id: idEntity };

                this.props['createEntityQuoteRh'](v);
              });
          }
          if (idEntity) {
            quoteMedicineListAllEmbed.map(v => {
              v['id'] = null;
              v['quote'] = { id: idEntity };
              this.props['createEntityQuoteMedicine'](v);
            });
          }
          if (idEntity) {
            quoteMaterialListAllEmbed.map(v => {
              v['id'] = null;
              v['quote'] = { id: idEntity };
              this.props['createEntityQuoteMaterial'](v);
            });
          }
          if (idEntity) {
            quoteDietListAllEmbed.map(v => {
              v['id'] = null;
              v['quote'] = { id: idEntity };
              this.props['createEntityQuoteDiet'](v);
            });
          }

          if (createPadAfter && idEntity) {
            // callFunction('fnCreateStockFromQuote', { parameter: idEntity });
          }
          if (quoteRhListAllEmbed.length > 0 && createPadAfter && idEntity) {
            new Promise(resolve => {
              resolve(
                this.props.createPad(
                  {
                    finalDate: this.state.toSelectValue,
                    initDate: this.state.fromSelectValue,
                    patient: getFormFromSelect(this.state.patientSelectValue, 'many-to-one'),
                    quote: { id: idEntity },
                  },
                  { reloadList: false }
                )
              );
            }).then(resultEntityPad => {
              const idPad =
                resultEntityPad && resultEntityPad['value'] && resultEntityPad['value']['data'] && resultEntityPad['value']['data']['id']
                  ? resultEntityPad['value']['data']['id']
                  : -1;
              new Promise(resolve => {
                resolve(this.props.getPad(idPad));
              }).then(resultPad => {
                //  this.props.history.push('/pad/' + idPad + '/edit');
              });
            });
          }
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(
              entity,
              !createPadAfter ? [patientId, patientClientId, source, status, fromStart, fromEnd, toStart, toEnd] : { reloadList: false }
            )
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;

          if (createPadAfter && idEntity) {
            //  callFunction('fnCreateStockFromQuote', { parameter: idEntity });
          }
          if (quoteRhListAllEmbed.length > 0 && createPadAfter && idEntity) {
            new Promise(resolve => {
              resolve(
                this.props.createPad(
                  {
                    finalDate: this.state.toSelectValue,
                    initDate: this.state.fromSelectValue,
                    patient: getFormFromSelect(this.state.patientSelectValue, 'many-to-one'),
                    quote: { id: idEntity },
                  },
                  { reloadList: false }
                )
              );
            }).then(resultEntityPad => {
              const idPad =
                resultEntityPad && resultEntityPad['value'] && resultEntityPad['value']['data'] && resultEntityPad['value']['data']['id']
                  ? resultEntityPad['value']['data']['id']
                  : -1;
              new Promise(resolve => {
                resolve(this.props.getPad(idPad));
              }).then(resultPad => {
                //     this.props.history.push('/pad/' + idPad + '/edit');
              });
            });
          }
        });
      }
    }
  }

  async componentDidUpdate(prevProps) {
    const total = this.calcTotalAmount();
    if (total !== this.state.finalAmountSelectValue && !this.props.loading && !this.props.updating) {
      this.setState({ finalAmountSelectValue: total });
      if (!this.state.isNew && !this.props.updating) {
        const result = axios.put('/api/quotes', {
          id: this.props.match.params['id'] * 1,
          finalAmount: total,
        });
      }
    }

    if (
      !this.clComTableAsyncPendind &&
      ((prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
        (this.state.patientSelectValue === null && this.props.quoteEntity.patient && this.props.quoteEntity.patient.id))
    ) {
      const p = this.props.quoteEntity && this.props.quoteEntity.patient ? this.props.quoteEntity.patient : null;
      this.clComTableAsyncPendind = true;
      let rulers = {};
      let itens = {};
      if (p.client && p.client.clComTable && p.client.clComTable.length > 0) {
        const tables = p.client.clComTable.filter(v => v.deletedAt === null);
        const table = tables ? tables.slice(0, 1).pop().id : null;
        if (table) {
          const rulersData = await getListAxios('cl-com-rulers', { 'clComTable.id.equals': table }, 0, 100, 'id,asc');
          rulers = rulersData['data'].filter(v => v.deletedAt === null);
          const itensData = await getListAxios('cl-com-items', { 'clComTable.id.equals': table }, 0, 100, 'id,asc');
          itens = itensData['data'].filter(v => v.deletedAt === null);
        }
      }
      this.setState(
        {
          patientSelectValue: p
            ? { ...p, value: p.id, label: (p['client'] ? p.client.socialReason : '') + ' | ' + (p['name'] ? p.name : '') }
            : null,
          tableRulersSelectValue: rulers,
          tableItensSelectValue: itens,
        },
        () => {
          // clComTableAsyncPendind=false;
        }
      );
    }

    return super.componentDidUpdate(prevProps);
  }

  handleClose = () => {
    // if (!this.state.createPadAfterSubmit) {
    this.props.history.push('/quote?' + getEntityFiltersURL(this.state.fieldsBase));
    //  }
  };

  renderHeader() {
    const {
      quoteEntity,
      updating,
      quoteRhListAllEmbed,
      quotePackageListEmbed,
      quoteRhListEmbed,
      quoteMedicineListEmbed,
      quoteMaterialListEmbed,
      quoteDietListEmbed,
      quoteProductServiceListEmbed,
    } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.quote.home.createLabel">Create a Quote</Translate>
            ) : (
              <Translate contentKey="generadorApp.quote.home.editLabel">Edit a Quote</Translate>
            )}
          </span>
          {this.props?.userLogged?.authorities.find(element => element === "ROLE_ADMIN") ?
            <>
              {!['CANCELADO'].includes(this.state.statusSelectValue) ? (
                <Button
                  className="float-right jh-create-entity"
                  onClick={() => {
                    MySwal.fire({
                      title: (
                        <p>
                          <Translate contentKey={`generadorApp.cancel_quote`} />
                        </p>
                      ),
                      html: (
                        <p>
                          <Translate contentKey={`generadorApp.want_to_cancel_quote`} />
                        </p>
                      ),
                      showConfirmButton: true,
                      showCancelButton: true,
                    }).then(response => {
                      if (response.isConfirmed) {
                        axios.put(BASE_API_VERSION_PATH + 'quotes', { id: quoteEntity.id, status: QuoteStatus.CANCELADO }).then(replay => {
                          this.setState({ statusSelectValue: QuoteStatus.CANCELADO });
                          this.props.getEntity(quoteEntity.id);
                        });
                      }
                    });
                  }}
                  disabled={updating}
                  color="danger"
                >
                  <FontAwesomeIcon icon="ban" />
                  &nbsp;
                  <Translate contentKey="generadorApp.quote.cancelQuote">Save</Translate>
                </Button>
              ) : (
                <> </>
              )}
            </>

            : null


          }
          {this.props?.userLogged?.authorities.find(element => element === "ROLE_ADMIN") ?
        <>
         {['ORCAMENTOABERTO', 'AGUARDANDOPRECIFICACAO', 'ORCAMENTOPRECIFICADO', 'AGUARDANDOAPROVACAO', 'PLANTAOAPROVADO'].includes(
            this.state.statusSelectValue
          ) ? (
            <Button
              className="float-right jh-create-entity"
              onClick={() => {
                MySwal.fire({
                  title: <p>Aprovar Orçamento</p>,
                  html: <p>Deseja aprovar o orçamento</p>,
                  showConfirmButton: true,
                  showCancelButton: true,
                }).then(async response => {
                  if (response.isConfirmed) {
                    const quoteLastAccount = await axios.get(BASE_API_VERSION_PATH + 'quotes?page=0&size=1&sort=accountNumber,desc');
                    const quote = quoteLastAccount.data.pop();
                    const accountNumber = (quote ? quote.accountNumber : 0) + 1;

                    axios
                      .put(BASE_API_VERSION_PATH + 'quotes', {
                        id: quoteEntity.id,
                        accountNumber,
                        approvalDate: moment().format('YYYY-MM-DD'),
                        status: QuoteStatus.APROVADO,
                        gid: quoteEntity.gid,
                      })
                      .then(replay => {

                        if (quoteRhListAllEmbed.length > 0 && quoteEntity.id) {
                          this.props.createPad(
                            {
                              finalDate: this.state.toSelectValue,
                              initDate: this.state.fromSelectValue,
                              patient: getFormFromSelect(this.state.patientSelectValue, 'many-to-one'),
                              quote: { id: quoteEntity.id },
                            },
                            { reloadList: false }
                          );

                          quotePackageListEmbed &&
                            quotePackageListEmbed
                              .filter(v => v.deletedAt === null)
                              .map((v: IQuotePackage) => {
                                axios.put('/api/quote-packages', { ...v, billQuantityItem: v.quantity, billFinalAmount: v.finalAmount });
                              });
                          quoteRhListEmbed &&
                            quoteRhListEmbed
                              .filter((v: IQuoteRh) => v.deletedAt === null)
                              .map((v: IQuoteRh) => {
                                axios.put('/api/quote-rhs', { ...v, billQuantityItem: v.quantity, billFinalAmount: v.finalAmount });
                              });
                          quoteMedicineListEmbed &&
                            quoteMedicineListEmbed
                              .filter((v: IQuoteMedicine) => v.deletedAt === null)
                              .map((v: IQuoteMedicine) => {
                                axios.put('/api/quote-medicines', { ...v, billQuantityItem: v.quantity, billFinalAmount: v.finalAmount });
                              });
                          quoteMaterialListEmbed &&
                            quoteMaterialListEmbed
                              .filter((v: IQuoteMaterial) => v.deletedAt === null)
                              .map((v: IQuoteMaterial) => {
                                axios.put('/api/quote-materials', { ...v, billQuantityItem: v.quantity, billFinalAmount: v.finalAmount });
                              });
                          quoteDietListEmbed &&
                            quoteDietListEmbed
                              .filter((v: IQuoteDiet) => v.deletedAt === null)
                              .map((v: IQuoteDiet) => {
                                axios.put('/api/quote-diets', { ...v, billQuantityItem: v.quantity, billFinalAmount: v.finalAmount });
                              });
                          quoteProductServiceListEmbed &&
                            quoteProductServiceListEmbed
                              .filter((v: IQuoteProductService) => v.deletedAt === null)
                              .map((v: IQuoteProductService) => {
                                axios.put('/api/quote-product-services', {
                                  ...v,
                                  billQuantityItem: v.quantity,
                                  billFinalAmount: v.finalAmount,
                                });
                              });
                        }
                        this.setState({ statusSelectValue: QuoteStatus.APROVADO });
                        this.props.getEntity(quoteEntity.id);
                      });
                  }
                });
              }}
              disabled={updating}
              color="primary"
            >
              <FontAwesomeIcon icon="check" />
              &nbsp;
              <Translate contentKey="generadorApp.quote.approveQuote">Save</Translate>
            </Button>
          ) : (
            <> </>
          )}
        </>    
        : 
        null
        
        }
         

          <Button
            tag={Link}
            id="cancel-save"
            to={'/quote?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.quote.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={'/quote'}>
              <Translate contentKey="generadorApp.quote.home.title">Quotes</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.quote.detail.title">Quote edit</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderBody() {
    return <> {super.renderBody()} </>;
  }

  calcTotalAmount() {
    let total = 0;
    this.props.quotePackageListEmbed &&
      this.props.quotePackageListEmbed
        .filter(v => v.deletedAt === null)
        .map(v => {
          total += parseFloat((v.finalAmount ? v.finalAmount : 0) + '');
        });
    this.props.quoteRhListEmbed &&
      this.props.quoteRhListEmbed
        .filter(v => v.deletedAt === null)
        .map(v => {
          total += parseFloat((v.finalAmount ? v.finalAmount : 0) + '');
        });
    this.props.quoteMedicineListEmbed &&
      this.props.quoteMedicineListEmbed
        .filter(v => v.deletedAt === null)
        .map(v => {
          total += parseFloat((v.finalAmount ? v.finalAmount : 0) + '');
        });
    this.props.quoteMaterialListEmbed &&
      this.props.quoteMaterialListEmbed
        .filter(v => v.deletedAt === null)
        .map(v => {
          total += parseFloat((v.finalAmount ? v.finalAmount : 0) + '');
        });
    this.props.quoteDietListEmbed &&
      this.props.quoteDietListEmbed
        .filter(v => v.deletedAt === null)
        .map(v => {
          total += parseFloat((v.finalAmount ? v.finalAmount : 0) + '');
        });
    this.props.quoteProductServiceListEmbed &&
      this.props.quoteProductServiceListEmbed
        .filter(v => v.deletedAt === null)
        .map(v => {
          total += parseFloat((v.finalAmount ? v.finalAmount : 0) + '');
        });

    return total;
  }

  render() {
    const { quoteEntity, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                ...quoteEntity,
              }
          }
          onSubmit={this.saveEntity}
        >
          <Panel>
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>
              {this.renderBody()}
              <Row className="justify-content-center">
                <Col md="11">
                  <br />
                  <h2 className="pull-right">
                    <Translate contentKey="generadorApp.quoteRh.finalAmount">finalAmount</Translate>: R${' '}
                    {(this.state.finalAmountSelectValue * 1).toFixed(2).replace('.', ',')}
                  </h2>
                </Col>
              </Row>
            </PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
    listProcediment: storeState.patDrPrescrProcd.entities,
    listRulersList: storeState.clComRuler.entities,
    listItemsList: storeState.clComItem.entities,
    romaneioReport: storeState.quote.romaneioReport,
    userLogged: storeState.authentication.account,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
  createPad,
  getPad,
  createPharmacyStock,
  getPharmacyStock,
  updateStateManual,
  getSuppliersByProductService,
  alterarQuantidadeAtual,
  getRomaneioReport,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;
export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(QuoteExtendedUpdate);
