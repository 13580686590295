import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import PatientCid from './extended/patient-cid';

import PatientCidDetail from './patient-cid-detail';

import PatientCidUpdate from './patient-cid';

import CidStatusHistory from '../cid-status-history/index';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute path={`${match.path}/:idPatientCid/cid-status-history`} component={CidStatusHistory} />

      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={PatientCidUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={PatientCidUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={PatientCidDetail} />
      <ErrorBoundaryRoute path={match.path} component={PatientCid} />
    </Switch>

    <Switch>{/* <ErrorBoundaryRoute path={`${match.path}/:idPatientCid/cid-status-history`} component={CidStatusHistory} /> */}</Switch>
  </>
);

export default Routes;
