import React from 'react';
import { Translate } from 'app/config/translate-component';

import { NavItem, NavLink } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// export const BrandIcon = props => (
//   <div {...props} className="brand-icon">
//     <img className="d-none d-sm-block" src="content/images/logo.svg" alt="Logo" />
//     <img className="d-block d-sm-none" src="content/images/logo.svg" alt="Logo" />
//   </div>
// );

// export const Brand = () => (
//   <NavbarBrand tag={Link} to="/" className="brand-logo">
//     <BrandIcon />
//   </NavbarBrand>
// );

export const Home = () => (
  <NavItem>
    <NavLink tag={Link} to="/" className="d-flex align-items-center">
      <FontAwesomeIcon icon="home" />
      <span>
        <Translate contentKey="global.menu.home">Home</Translate>
      </span>
    </NavLink>
  </NavItem>
);

export const SwaggerMenu = () => (
  <NavItem>
    <NavLink tag={Link} to="/admin/docs" className="d-flex align-items-center f-s-13">
      <FontAwesomeIcon icon="book" /> &nbsp;
      <Translate contentKey="global.menu.admin.apidocs">API</Translate>
    </NavLink>
  </NavItem>
);

export const Account = () => (
  <NavItem>
    <NavLink tag={Link} to="/account/settings" className="d-flex align-items-center f-s-13">
      <FontAwesomeIcon icon="user" />
      &nbsp;
      <Translate contentKey="global.menu.account.main">Account</Translate>
    </NavLink>
  </NavItem>
);

export const Logout = () => (
  <NavItem>
    <NavLink tag={Link} to="/logout" className="d-flex align-items-center f-s-13">
      <FontAwesomeIcon icon="sign-out-alt" />
      &nbsp;
      <Translate contentKey="global.menu.account.logout">Sair</Translate>
    </NavLink>
  </NavItem>
);
