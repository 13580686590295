import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Supplier from './supplier';

import SupplierDetail from './supplier-detail';

import SupplierUpdate from './supplier-update';

import SupplierStatusHistory from '../supplier-status-history/index';

import SupplierFile from '../supplier-file/index';

import SupplierContact from '../supplier-contact/index';

import SupplierTable from '../supplier-table/index';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute path={`${match.path}/:idSupplier/supplier-status-history`} component={SupplierStatusHistory} />

      <ErrorBoundaryRoute path={`${match.path}/:idSupplier/supplier-file`} component={SupplierFile} />

      <ErrorBoundaryRoute path={`${match.path}/:idSupplier/supplier-contact`} component={SupplierContact} />

      <ErrorBoundaryRoute path={`${match.path}/:idSupplier/supplier-table`} component={SupplierTable} />

      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={SupplierUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={SupplierUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={SupplierDetail} />
      <ErrorBoundaryRoute path={match.path} component={Supplier} />
    </Switch>

    <Switch>
      {/* <ErrorBoundaryRoute path={`${match.path}/:idSupplier/supplier-status-history`} component={SupplierStatusHistory} /> */}

      {/* <ErrorBoundaryRoute path={`${match.path}/:idSupplier/supplier-file`} component={SupplierFile} /> */}

      {/* <ErrorBoundaryRoute path={`${match.path}/:idSupplier/supplier-contact`} component={SupplierContact} /> */}

      {/* <ErrorBoundaryRoute path={`${match.path}/:idSupplier/supplier-table`} component={SupplierTable} /> */}
    </Switch>
  </>
);

export default Routes;
