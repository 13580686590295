import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ProfessionalContact from './professional-contact';

import ProfessionalContactDetail from './professional-contact-detail';

import ProfessionalContactUpdate from './professional-contact-update';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={ProfessionalContactUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={ProfessionalContactUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={ProfessionalContactDetail} />
      <ErrorBoundaryRoute path={match.path} component={ProfessionalContact} />
    </Switch>
  </>
);

export default Routes;
