/* eslint complexity: ["error", 500] */
import axios from 'axios';
import { BASE_API_VERSION_PATH } from 'app/config/constants';

import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { getEntity, ICrudGetAllActionQuote, QuoteState, ACTION_TYPES } from '../quote.reducer';
import { IQuote } from 'app/shared/model/quote.model';
import moment from 'moment';
// import {getEntities as getEntityDiet, reset as resetEntityDiet} from '../../quote-diet/quote-diet.reducer';

export const ACTION_TYPES_EXTENDED = {
  EDIT_VALOR_ATUAL: 'quote/EDIT_VALOR_ATUAL',
  GET_ROMANEIO_REPORT: 'quote/GET_ROMANEIO_REPORT',
  GET_ROMANEIO_LIST: 'quote/GET_ROMANEIO_LIST',
};

export const initialStateExtended = {
  romaneioReport: '',
  itensExternalPurchase: [{ value: null, label: 'Seleccione um iten' }],
};

export const SwitchExtended = (state: QuoteState, action): QuoteState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES_EXTENDED.EDIT_VALOR_ATUAL):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ACTION_TYPES_EXTENDED.EDIT_VALOR_ATUAL):
      return {
        ...state,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES_EXTENDED.EDIT_VALOR_ATUAL):
      return {
        ...state,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES_EXTENDED.GET_ROMANEIO_REPORT):
      return {
        ...state,
        loading: false,
        romaneioReport: action.payload.data,
      };
    default:
      return null;
  }
  return null;
};

const apiUrl = BASE_API_VERSION_PATH + 'quotes';

export const alterarQuantidadeAtual: any = (
  quoteId,
  itemTableName,
  itemTableId,
  medicineMaterialId,
  currentQuantity,
  itenSel,
  isExternalStock,
  textBatch
) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES_EXTENDED.EDIT_VALOR_ATUAL,
    payload: axios.patch(apiUrl + '/current-quantity', {
      quoteId,
      itemTableName,
      itemTableId,
      medicineMaterialId,
      currentQuantity,
      itenSel,
      isExternalStock,
      textBatch,
    }),
  });

  await dispatch(getEntity(quoteId));
  return result;
};

export const getEntitiesByRomaneo: ICrudGetAllActionQuote<IQuote> = (
  patientId,
  patientClientId,
  source,
  status,
  fromStart,
  fromEnd,
  toStart,
  toEnd,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const patientRequest = patientId ? `patient.id.in=${patientId}&` : '';
  const patientClientRequest = patientClientId ? `patient.client.id.in=${patientClientId}&` : '';
  const sourceRequest = source ? (Array.isArray(status) ? `source.in=${source.join(',')}&` : `source.equals=${source}&`) : '';
  const statusRequest = status ? (Array.isArray(status) ? `status.in=${status.join(',')}&` : `status.equals=${status}&`) : '';
  let fromStartRequest = '';
  let fromEndRequest = '';
  if (typeof fromStart !== 'undefined' && fromStart && !(typeof fromEnd !== 'undefined' && fromEnd)) {
    fromStartRequest = `from.greaterThan=${moment(fromStart).format('YYYY-MM-DD')}&`;
  } else if (!(typeof fromStart !== 'undefined' && fromStart) && typeof fromEnd !== 'undefined' && fromEnd) {
    fromEndRequest = `from.lessThan=${moment(fromEnd).format('YYYY-MM-DD')}&`;
  } else if (typeof fromStart !== 'undefined' && fromStart && typeof fromEnd !== 'undefined' && fromEnd) {
    fromStartRequest = `from.between=${moment(fromStart).format('YYYY-MM-DD')},${moment(fromEnd).format('YYYY-MM-DD')}&`;
  }
  let toStartRequest = '';
  let toEndRequest = '';
  if (typeof toStart !== 'undefined' && toStart && !(typeof toEnd !== 'undefined' && toEnd)) {
    toStartRequest = `to.greaterThan=${moment(toStart).format('YYYY-MM-DD')}&`;
  } else if (!(typeof toStart !== 'undefined' && toStart) && typeof toEnd !== 'undefined' && toEnd) {
    toEndRequest = `to.lessThan=${moment(toEnd).format('YYYY-MM-DD')}&`;
  } else if (typeof toStart !== 'undefined' && toStart && typeof toEnd !== 'undefined' && toEnd) {
    toStartRequest = `to.between=${moment(toStart).format('YYYY-MM-DD')},${moment(toEnd).format('YYYY-MM-DD')}&`;
  }

  const requestUrl = `${apiUrl}/romaneio${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_QUOTE_LIST,
    payload: axios.get<IQuote>(
      `${requestUrl}${extraFiltersRequest}${patientRequest}${patientClientRequest}${sourceRequest}${statusRequest}${fromStartRequest}${fromEndRequest}${toStartRequest}${toEndRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getSuppliersByProductService: any = (
  quoteId,
  quoteProductServiceId,
  supplierTableProductServiceId,
  suprimentoStatus
) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES_EXTENDED.EDIT_VALOR_ATUAL,
    payload: axios.patch(apiUrl + '/suppliers-product-service', { quoteProductServiceId, supplierTableProductServiceId, suprimentoStatus }),
  });

  await dispatch(getEntity(quoteId));
  return result;
};

export const getRomaneioReport: any = quoteId => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES_EXTENDED.GET_ROMANEIO_REPORT,
    payload: axios.get(apiUrl + '/romaneio-report/' + quoteId),
  });

  await dispatch(getEntity(quoteId));
  return result;
};
