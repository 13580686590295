import React, { useEffect } from 'react';
import Chart from 'react-apexcharts';
import { connect } from 'react-redux';
import { PatientStatus } from 'app/shared/model/enumerations/patient-status.model';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import FiltersModal from './modal/filters-modal';

export interface IPatientsActivesProps extends StateProps, DispatchProps {
  getReport: Function;
}

const PatientsActives = (props: IPatientsActivesProps) => {
  useEffect(() => {
    props.getReport('pacientes-ativos', { status: PatientStatus.ATIVO });
  }, []);

  let total = 0;
  const amounts = {
    BAIXA: 0,
    MEDIA: 0,
    ALTA: 0,
    VENTILACAOMECANICA: 0,
    TELEMONITORAMENTO: 0,
  };

  if (props.report.length > 0) {
    Object.values(props.report)
      .filter(value => value !== undefined)
      .map(value => {
        amounts[Object.values(value)[0]] = Object.values(value)[1];
        total += parseInt(Object.values(value)[1], 10);
      });
  }

  const state = {
    options: {
      chart: {
        id: 'chart-pacientes-ativos',
      },
      xaxis: {
        categories: ['Baixa', 'Media', 'Alta', 'Vent. Mec.', 'Tele'],
      },
      title: {
        text: 'Total (' + total + ')',
        floating: true,
        align: 'center',
        style: {
          color: '#444',
        },
      },
    },
    series: [
      {
        name: 'Pacientes ativos',
        data: [amounts['BAIXA'], amounts['MEDIA'], amounts['ALTA'], amounts['VENTILACAOMECANICA'], amounts['TELEMONITORAMENTO']],
      },
    ],
  };

  return (
    <Panel>
      <PanelHeader>
        <span>Pacientes ativos</span>
        <FiltersModal />
      </PanelHeader>
      <PanelBody>
        <Chart options={state.options} series={state.series} type="bar" width="100%" height={250} />
      </PanelBody>
    </Panel>
  );
  return <></>;
};

const mapStateToProps = storeState => ({
  report:
    storeState.jhiReports.reportsList && storeState.jhiReports.reportsList['pacientes-ativos']
      ? storeState.jhiReports.reportsList['pacientes-ativos']
      : [],
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps)(PatientsActives);
