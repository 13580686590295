/* eslint complexity: ["error", 300] */
import React from 'react';

import CurrencyInput from 'react-currency-input';
import { connect } from 'react-redux';

import Swal from 'sweetalert2';
import { APP_LOCAL_DATETIME_SEM_SEG, APP_LOCAL_TIME_FORMAT } from 'app/config/constants';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);

import axios from 'axios';
import Select from 'react-select';
import { toast } from 'react-toastify';
import SelectAsync from 'react-select/async';
import { Link, RouteComponentProps } from 'react-router-dom';
import InputMask from 'react-input-mask';
import {
  Button,
  Col,
  Row,
  Table,
  Label,
  UncontrolledCollapse,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';
import { setFileData, TextFormat, getSortState, IPaginationBaseState, JhiItemCount } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';
import { AvForm } from 'availity-reactstrap-validation';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';
import GoogleMapComponent from 'app/entities/patient/components/GoogleMapComponent';

import { IRootState } from 'app/shared/reducers';
import {
  updateEntity,
  getUrlBack,
  getPadAttendanceState,
  IPadAttendanceBaseState,
  getEntityFiltersURL,
  reset,
  getEntities,
  IPadAttendanceUpdateState as IBaseUpdateState,
  getEntity,
  deleteEntity,
  createEntity,
  setBlob,
} from '../pad-attendance.reducer';

import { getTotalize, cancelAttendances } from './pad-attendance.reducer';

import { APP_LOCAL_DATE_FORMAT, BASE_API_VERSION_PATH } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';

import { StatePropsExtended, DispatchPropsExtended, IExtendedState, IExtendedProps } from '../extended/pad-attendance';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';
import moment from 'moment';
import { AttendanceStatus } from 'app/shared/model/enumerations/attendance-status.model';
import {
  getFilterFromSelect,
  getFormFromSelect,
  getListAxios,
  getValueRecursive,
  showFieldsSelectAsync,
} from 'app/shared/util/entity-utils';
import ModalFilterExportPadAttendances from 'app/entities/pad-attendance/extended/modal-filter-export-pad-attendance';
import { PadAttendancesPdfReport } from 'app/entities/pad-attendance/report/pad-attendances';
registerLocale('pt', pt);

export interface IPadAttendanceProps
  extends StateProps,
    DispatchProps,
    StatePropsExtended,
    DispatchPropsExtended,
    IExtendedProps,
    RouteComponentProps<{ url: string }> {}

export interface IPadAttendanceState extends IPadAttendanceBaseState, IPaginationBaseState, IBaseUpdateState, IExtendedState {
  showModalForm?: boolean;
  showModalEdit?: number;
  showModalEditView?: boolean;
  showModalReport?: boolean;
  report?: any;
  fromFilter?: any;
  toFilter?: any;
  evolutionHistory?: any;
  photoFilter?: any;
  dropdownButtons: {};
}

export class PadAttendanceListAll extends React.Component<IPadAttendanceProps, IPadAttendanceState> {
  protected myFormRef: any;
  protected myFormRefNew: any;
  protected myFormRefEdit: any;

  periodFileInput: any;

  scheduleFileInput: any;

  pushFileInput: any;

  valueFileInput: any;

  frequencyFileInput: any;

  quantityFileInput: any;

  dateFileInput: any;

  statusFileInput: any;

  personalize = {
    AGUARDANDOCONFIRMACAO: {
      label: 'label label-red',
      color: 'bg-gradient-red',
      icon: 'fa fa-user-check',
    },
    AGUARDANDOATENDIMENTO: {
      label: 'label label-yellow',
      color: 'bg-gradient-yellow',
      icon: 'fa fa-user-clock',
    },
    EMATENDIMENTO: {
      label: 'label label-blue',
      color: 'bg-gradient-blue',
      icon: 'fa fa-user-md',
    },
    FINALIZADO: {
      label: 'label label-green',
      color: 'bg-gradient-green',
      icon: 'fa fa-check-circle',
    },
    CANCELADO: {
      label: 'label label-black',
      color: 'bg-gradient-black',
      icon: 'fa fa-times-circle',
    },
  };

  constructor(props: IPadAttendanceProps) {
    super(props);
    this.state = {
      showModalForm: false,
      showModalEdit: null,
      dropdownButtons: {},
      urlBack: getUrlBack(this.props.location),
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      ...getPadAttendanceState(this.props.location),
      padId: this.props.match.params['idPad'],
      baseFilters: 'pad',
      professionalSelectValue: null,

      padSelectValue: parseInt(this.props.match.params['idPad'], 10),

      quoteRhSelectValue: null,

      periodSelectValue: null,
      scheduleSelectValue: null,
      checkInSelectValue: null,
      checkOutSelectValue: null,
      evolutionSelectValue: null,
      pushSelectValue: null,
      valuePadSelectValue: null,
      frequencySelectValue: null,
      quantitySelectValue: null,
      dateSelectValue: null,
      statusSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getPadAttendanceState(this.props.location),
        padId: this.props.match.params['idPad'],
        baseFilters: 'pad',
      },
      professionalName: null,
      quoteRhId: null,
      isNew: false,
      listCheckedID: [],
    };
  }

  toggle = btn => {
    const dropdownButtons = this.state.dropdownButtons;
    dropdownButtons[btn] = !dropdownButtons[btn];
    this.setState({ dropdownButtons });
  };

  UNSAFE_componentWillMount() {
    this.getTotalize();
    this.getEntities();
  }

  cancelFilters = () => {
    this.setState(
      {
        padId: '',
        period: '',
        schedule: '',
        push: '',
        frequency: '',
        valuePad: '',
        quantity: '',
        dateStart: '',
        dateEnd: '',
        quoteRhId: '',
        professionalId: '',
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getTotalize();
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();
    const {
      padId,
      period,
      schedule,
      push,
      frequency,
      valuePad,
      quantity,
      dateStart,
      dateEnd,
      quoteRhId,
      quoteRhQuoteId,
      quoteRhQuotePatientId,
      professionalId,
      status,
      quoteRhQuotePatientClientId,
      quoteRhSpecialtyCategoryId,
      extraFilters, // // -----
      photoFilter,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;

    this.props.getEntities(
      getFilterFromSelect(padId, 'many-to-one'),
      period,
      schedule,
      push,
      frequency,
      valuePad,
      quantity,
      dateStart ? dateStart : moment(),
      dateEnd ? dateEnd : moment(),
      getFilterFromSelect(quoteRhId, 'many-to-one'),
      getFilterFromSelect(quoteRhQuoteId, 'many-to-one'),
      getFilterFromSelect(quoteRhQuotePatientId, 'many-to-one'),
      getFilterFromSelect(professionalId, 'many-to-one'),
      status,
      getFilterFromSelect(quoteRhQuotePatientClientId, 'many-to-one'),
      getFilterFromSelect(quoteRhSpecialtyCategoryId, 'many-to-one'),
      extraFilters, // // -----
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      'pad.id,quoteRh.specialty.category.name,quoteRh.quote.id,quoteRh.quote.patient.name,' +
        'professional.specialties.category.id,' +
        'status,quoteRh.quote.patient.client.fantasyName,quoteRh.specialty.name,'
    );
  };

  getTotalize = () => {
    const {
      padId,
      period,
      schedule,
      push,
      frequency,
      valuePad,
      quantity,
      dateStart,
      dateEnd,
      quoteRhId,
      quoteRhQuoteId,
      quoteRhQuotePatientId,
      professionalId,
      status,
      quoteRhQuotePatientClientId,
      quoteRhSpecialtyCategoryId,
      extraFilters,
      photoFilter,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;

    this.props.getTotalize(
      getFilterFromSelect(padId, 'many-to-one'),
      period,
      schedule,
      push,
      frequency,
      valuePad,
      quantity,
      dateStart ? dateStart : moment(),
      dateEnd ? dateEnd : moment(),
      getFilterFromSelect(quoteRhId, 'many-to-one'),
      getFilterFromSelect(quoteRhQuoteId, 'many-to-one'),
      getFilterFromSelect(quoteRhQuotePatientId, 'many-to-one'),
      getFilterFromSelect(professionalId, 'many-to-one'),
      status,
      getFilterFromSelect(quoteRhQuotePatientClientId, 'many-to-one'),
      getFilterFromSelect(quoteRhSpecialtyCategoryId, 'many-to-one'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      'pad.id,quoteRh.specialty.category.name,quoteRh.quote.id,quoteRh.quote.patient.name,' +
        'professional.specialties.category.id,' +
        'status,quoteRh.quote.patient.client.fantasyName,quoteRh.specialty.name,'
    );
  };

  updateEntity = entity => {
    const {
      padId,
      period,
      schedule,
      push,
      frequency,
      valuePad,
      quantity,
      dateStart,
      dateEnd,
      quoteRhId,
      professionalId,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;
    this.props.updateEntity(entity, [
      padId,
      period,
      schedule,
      push,
      frequency,
      valuePad,
      quantity,
      dateStart,
      dateEnd,
      quoteRhId && quoteRhId.value ? quoteRhId.value : '',
      professionalId && professionalId.value ? professionalId.value : '',
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
    ]);
  };

  onBlobChange = (isAnImage, name, fileInput) => event => {
    const fileName = event.target.files[0].name;
    setFileData(event, (contentType, data) => this.props.setBlob(name, data, contentType, fileName), isAnImage);
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined);
  };

  saveEntity = (event: any, errors: any, values: any, isNew: any) => {
    if (errors.length === 0) {
      const { padAttendanceEntity } = this.props;
      const entity = {
        ...padAttendanceEntity,
        ...values,

        professional: getFormFromSelect(this.state.professionalSelectValue, 'many-to-one'), // many-to-one - otherSide

        //  pad: getFormFromSelect(this.state.padSelectValue, 'many-to-one'), // many-to-one - otherSide

        //  quoteRh: getFormFromSelect(this.state.quoteRhSelectValue, 'many-to-one'), // many-to-one - otherSide

        period: this.state.periodSelectValue === null ? 'MATUTINO' : this.state.periodSelectValue,
        schedule: this.state.scheduleSelectValue,
        checkIn: this.state.checkInSelectValue,
        checkOut: this.state.checkOutSelectValue,
        evolution: this.state.evolutionSelectValue,
        push: this.state.pushSelectValue,
        value: this.state.valuePadSelectValue,
        frequency: this.state.frequencySelectValue === null ? 'UMXSEMANA' : this.state.frequencySelectValue,
        quantity: this.state.quantitySelectValue,
        date: this.state.dateSelectValue,
        status: this.state.statusSelectValue === null ? 'AGUARDANDOCONFIRMACAO' : this.state.statusSelectValue,
        cancellationReason: this.state.cancellationReasonSelectValue,
      };

      const {
        padId,
        period,
        schedule,
        push,
        frequency,
        valuePad,
        quantity,
        dateStart,
        dateEnd,
        quoteRhId,
        professionalId,
      } = this.state.fieldsBase;

      if (isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              padId,
              period,
              schedule,
              push,
              frequency,
              valuePad,
              quantity,
              dateStart,
              dateEnd,
              quoteRhId && quoteRhId.value ? quoteRhId.value : '',
              professionalId && professionalId.value ? professionalId.value : '',
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        this.props.updateEntity(entity, [
          padId,
          period,
          schedule,
          push,
          frequency,
          valuePad,
          quantity,
          dateStart,
          dateEnd,
          quoteRhId && quoteRhId.value ? quoteRhId.value : '',
          professionalId && professionalId.value ? professionalId.value : '',
        ]);
      }
      this.setState({ showModalForm: false });
      this.setState({ showModalEdit: null });
    } else {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.specialty.' + errors[0]) }));
    }
  };

  confirmDelete = event => {
    const {
      padId,
      period,
      schedule,
      push,
      frequency,
      valuePad,
      quantity,
      dateStart,
      dateEnd,
      quoteRhId,
      professionalId,
    } = this.state.fieldsBase;

    this.props.deleteEntity(this.props.padAttendanceEntity.id, [
      padId,
      period,
      schedule,
      push,
      frequency,
      valuePad,
      quantity,
      dateStart,
      dateEnd,
      quoteRhId && quoteRhId.value ? quoteRhId.value : '',
      professionalId && professionalId.value ? professionalId.value : '',
    ]);

    // this.setState({ showModalDelete: false });
  };

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({ listCheckedID: this.props.padAttendanceList.map(padAttendance => padAttendance.id) });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({ listCheckedID: this.props.padAttendanceList.map(padAttendance => padAttendance.id) });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  removeEntitySelected() {
    MySwal.fire({
      title: <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>,
      html: <Translate contentKey="generadorApp.padAttendance.cancel.question">Are you sure you want to cancel ?</Translate>,
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#e70015',
      cancelButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="ban" />
          &nbsp;<Translate contentKey="generadorApp.padAttendance.confirm.no">Não</Translate>
        </>
      ),
      confirmButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="trash" />
          &nbsp; <Translate contentKey="generadorApp.padAttendance.confirm.yes">Sim</Translate>
        </>
      ),
    }).then(response => {
      if (response.isConfirmed) {
        if (!this.state.isCheckedAll) {
          this.cancelAttendances(this.state.listCheckedID);
        } else {
          this.cancelAttendances('all');
        }
        this.setState({ listCheckedID: [] });
      }
    });
  }

  cancelAttendances = ids => {
    const {
      padId,
      period,
      schedule,
      push,
      frequency,
      valuePad,
      quantity,
      dateStart,
      dateEnd,
      quoteRhId,
      professionalId,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;
    const filters = [
      padId,
      period,
      schedule,
      push,
      frequency,
      valuePad,
      quantity,
      dateStart,
      dateEnd,
      quoteRhId && quoteRhId.value ? quoteRhId.value : '',
      professionalId && professionalId.value ? professionalId.value : '',
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
    ];
    if (ids === 'all') {
      this.props.cancelAttendances(ids, filters);
    } else {
      this.props.cancelAttendances(typeof ids === 'object' ? ids : [ids], filters);
    }
  };

  renderNewModal() {
    return (
      <Modal isOpen={this.state.showModalForm} toggle={() => this.setState({ showModalForm: false })}>
        <ModalHeader toggle={() => this.setState({ showModalForm: false })}>{this.renderHeaderUpdate({}, true)}</ModalHeader>
        <div ref={el => (this.myFormRefNew = el)}>
          <ModalBody>{this.renderBodyUpdate({}, true)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalForm: false })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.padAttendance.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefNew && this.myFormRefNew.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.padAttendance.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    );
  }

  getFullEntityFromState(v) {
    const professionalEntity = this.props.professionals.filter(x =>
      x.padAttendance.map(x1 => x1.id).includes(this.state.professionalSelectValue)
    ); // many-to-one
    const padEntity = this.props.pads.filter(x => x.padAttendance.map(x1 => x1.id).includes(this.state.padSelectValue)); // many-to-one
    const quoteRhEntity = this.props.quoteRhs.filter(x => x.padAttendance.map(x1 => x1.id).includes(this.state.quoteRhSelectValue)); // many-to-one
    return {
      period: this.state.periodSelectValue,
      schedule: this.state.scheduleSelectValue,
      checkIn: this.state.checkInSelectValue,
      checkOut: this.state.checkOutSelectValue,
      evolution: this.state.evolutionSelectValue,
      push: this.state.pushSelectValue,
      value: this.state.valuePadSelectValue,
      frequency: this.state.frequencySelectValue,
      quantity: this.state.quantitySelectValue,
      date: this.state.dateSelectValue,
      status: this.state.statusSelectValue,
      professional: professionalEntity,
      pad: padEntity,
      quoteRh: quoteRhEntity,
    };
  }
  getFullEntitySelectValue(v) {
    let professionalEntity = v.professional; // many-to-one
    if (!professionalEntity) {
      professionalEntity = this.props.professionals.filter(x => x.padAttendance.map(x1 => x1.id).includes(v.id)).pop();
    }
    let padEntity = v.pad; // many-to-one
    if (!padEntity) {
      padEntity = this.props.pads.filter(x => x.padAttendance.map(x1 => x1.id).includes(v.id)).pop();
    }
    let quoteRhEntity = v.quoteRh; // many-to-one
    if (!quoteRhEntity) {
      quoteRhEntity = this.props.quoteRhs.filter(x => x.padAttendance.map(x1 => x1.id).includes(v.id)).pop();
    }

    return {
      periodSelectValue: v.period, // period,
      scheduleSelectValue: v.schedule, // schedule,
      checkInSelectValue: moment(v.checkIn).format('HH:mm'),
      checkOutSelectValue: moment(v.checkOut).format('HH:mm'),
      evolutionSelectValue: v.evolution,
      pushSelectValue: v.push, // push,
      valuePadSelectValue: v.value, // value,
      frequencySelectValue: v.frequency, // frequency,
      quantitySelectValue: v.quantity, // quantity,
      dateSelectValue: v.date ? moment(v.date).toDate() : null, // LocalDate,
      statusSelectValue: v.status, // status,
      professionalSelectValue: professionalEntity ? professionalEntity['id'] : null,
      padSelectValue: padEntity ? padEntity['id'] : null,
      quoteRhSelectValue: quoteRhEntity ? quoteRhEntity['id'] : null,
    };
  }

  openUpdateModal(v, edit = true) {
    this.props.getEntity(
      v.id,
      'createdBy.id,lastModifiedBy.id,professional.id,pad.id,quoteRh.specialty.category.name,quoteRh.quote.patient.name'
    );
    axios.get(BASE_API_VERSION_PATH + 'audit-entities?entityType=PadAttendance&entityId=' + v.id).then(v1 => {
      const evolutionHistory = v1.data
        .filter(v2 => {
          const entityValue = JSON.parse(v2.entityValue);
          const entityKeyDiff = v2.entityKeyDiff ? v2.entityKeyDiff.split(',') : [];
          const isInKeyDiff =
            entityKeyDiff.includes('evolution') || entityKeyDiff.includes('checkIn') || entityKeyDiff.includes('checkOut');
          const hasValues = entityValue.evolution || entityValue.checkIn || entityValue.checkOut;

          return isInKeyDiff && hasValues;
        })
        .map(v2 => ({
          createdById: v2.createdBy.id,
          createdByLogin: v2.createdBy.login,
          createdDate: v2.createdDate,
        }));

      this.setState({ evolutionHistory });
    });
    this.setState({
      showModalEdit: v.id,
      showModalEditView: edit,
      ...this.getFullEntitySelectValue(v),
    });
  }

  renderUpdateModal() {
    const { padAttendanceEntity } = this.props;
    return padAttendanceEntity && padAttendanceEntity.id ? (
      <Modal
        size={'xl'}
        style={{ width: '90%', maxWidth: '90%' }}
        isOpen={this.state.showModalEdit === padAttendanceEntity.id}
        toggle={() => {
          this.setState({ showModalEdit: null });
          this.getEntities();
        }}
      >
        <ModalHeader
          toggle={() => {
            this.setState({ showModalEdit: null });
            this.getEntities();
          }}
        >
          {this.state.showModalEditView ? (
            this.renderHeaderUpdate(padAttendanceEntity, false)
          ) : (
            <h2>
              <Translate contentKey="generadorApp.padAttendance.detail.title">PadAttendance</Translate>
              <small>&nbsp; {padAttendanceEntity['period']}</small>
            </h2>
          )}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md="6">
              <strong>Paciente:</strong>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={'/patient/' + getValueRecursive(padAttendanceEntity, 'quoteRh.quote.patient.id'.split('.'), '')}
              >
                {getValueRecursive(padAttendanceEntity, 'quoteRh.quote.patient.name'.split('.'), '')}
              </a>
              <br />
              <strong>Convênio:</strong> {getValueRecursive(padAttendanceEntity, 'quoteRh.quote.client.name'.split('.'), '')}
              <br />
              <strong>PAD:</strong> {getValueRecursive(padAttendanceEntity, 'quoteRh.quote.id'.split('.'), '')}
              <br />
              <strong>Data:</strong> <TextFormat value={padAttendanceEntity.date} type="date" format={APP_LOCAL_DATE_FORMAT} />
              <br />
              <strong>Período:</strong> {padAttendanceEntity.period}
              <br />
              <strong>Categoria:</strong> {getValueRecursive(padAttendanceEntity, 'quoteRh.specialty.category.name'.split('.'), '')}
              <br />
              <strong>Especialidade:</strong> {getValueRecursive(padAttendanceEntity, 'quoteRh.specialty.name'.split('.'), '')}
              <br />
              <strong>Valor:</strong> R${' '}
              {((padAttendanceEntity.valuePad ? padAttendanceEntity.valuePad : 0) * 1).toFixed(2).replace('.', ',')}
              <br />
              <strong>Profissional:</strong> {getValueRecursive(padAttendanceEntity, 'professional.name'.split('.'), '')}
              <br />
              <strong>Status:</strong>
              <span
                className={
                  this.personalize[padAttendanceEntity.status].label
                    ? this.personalize[padAttendanceEntity.status].label
                    : 'label label-info'
                }
              >
                {translate('generadorApp.AttendanceStatus.' + padAttendanceEntity.status)}
              </span>
              <br />
              <strong>Intercorrência:</strong>
              <br />
              <strong>Evolução:</strong> {padAttendanceEntity.evolution}
              <br />
              <strong>Avaliação:</strong>
              <br />
              <strong style={{ float: 'left' }}>Valor: </strong>
              {this.state.showModalEditView ? (
                <>
                  <CurrencyInput
                    onChange={(maskedvalue, floatvalue, event) => this.setState({ valuePadSelectValue: floatvalue })}
                    decimalSeparator=","
                    thousandSeparator=""
                    prefix="R$ "
                    allowNegative={true}
                    value={this.state.valuePadSelectValue ? this.state.valuePadSelectValue : 0}
                    id="cl-com-item-saleValue"
                    name="saleValue"
                    className={'form-control'}
                    style={{ width: '150px', float: 'left' }}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      axios
                        .put(BASE_API_VERSION_PATH + 'pad-attendances', {
                          ...padAttendanceEntity,
                          valuePad: this.state.valuePadSelectValue ? this.state.valuePadSelectValue : 0,
                        })
                        .then(v => {
                          this.props.getEntity(
                            v.data.id,
                            'createdBy.id,lastModifiedBy.id,professional.id,pad.id,quoteRh.specialty.category.name,quoteRh.quote.patient.name'
                          );

                          axios.get(BASE_API_VERSION_PATH + 'audit-entities?entityType=PadAttendance&entityId=' + v.data.id).then(x1 => {
                            const evolutionHistory = x1.data
                              .filter(x => {
                                const entityValue = JSON.parse(x.entityValue);
                                const entityKeyDiff = x.entityKeyDiff ? x.entityKeyDiff.split(',') : [];
                                const isInKeyDiff =
                                  entityKeyDiff.includes('evolution') ||
                                  entityKeyDiff.includes('checkIn') ||
                                  entityKeyDiff.includes('checkOut');
                                const hasValues = entityValue.evolution || entityValue.checkIn || entityValue.checkOut;

                                return isInKeyDiff && hasValues;
                              })
                              .map(x => ({
                                createdById: x.createdBy.id,
                                createdByLogin: x.createdBy.login,
                                createdDate: x.createdDate,
                              }));

                            this.setState({
                              ...this.getFullEntitySelectValue(v.data),
                              evolutionHistory,
                            });
                          });
                        });
                    }}
                  >
                    Alterar
                  </button>
                </>
              ) : (
                <>R$ {(this.state.valuePadSelectValue ? this.state.valuePadSelectValue : 0).toFixed(2)}</>
              )}
            </Col>
            <Col md="6">
              <h3>Endereço do Paciente</h3>
              <div className="divH div20">{/* */}</div>
              <strong>Endereço: </strong>
              {getValueRecursive(padAttendanceEntity, 'quoteRh.quote.patient.street'.split('.'), '')},
              {getValueRecursive(padAttendanceEntity, 'quoteRh.quote.patient.number'.split('.'), '')}
              <br />
              <strong>Complemento: </strong> {getValueRecursive(padAttendanceEntity, 'quoteRh.quote.patient.complement'.split('.'), '')}
              <br />
              <strong>Bairro: </strong> {getValueRecursive(padAttendanceEntity, 'quoteRh.quote.patient.neighborhood'.split('.'), '')}
              <br />
              <strong>Cidade/UF: </strong>
              {getValueRecursive(padAttendanceEntity, 'quoteRh.quote.patient.city'.split('.'), '')}/
              {getValueRecursive(padAttendanceEntity, 'quoteRh.quote.patient.uf'.split('.'), '')}
              <br />
              <strong>CEP: </strong> {getValueRecursive(padAttendanceEntity, 'quoteRh.quote.patient.zipCode'.split('.'), '')}
              <br />
              {!this.state.showModalEditView ? (
                <Row>
                  <Col md="12" style={{ paddingRight: '30px' }}>
                    <GoogleMapComponent
                      entityBase={getValueRecursive(padAttendanceEntity, 'quoteRh.quote.patient'.split('.'))}
                      baseState={this.state}
                      baseProps={this.props}
                      setState={_state => this.setState(_state)}
                    />
                  </Col>
                </Row>
              ) : (
                <> </>
              )}
            </Col>
          </Row>
          {!this.state.showModalEditView ? (
            <Row>
              <Col md="12">
                {' '}
                <hr />{' '}
              </Col>
              <Col md="6">
                <h3>
                  <FontAwesomeIcon icon="clock" />
                  Chegada
                </h3>
                <b>Chegada: {this.state.checkInSelectValue}</b>
              </Col>
              <Col md="6">
                <h3>
                  <FontAwesomeIcon icon="clock" />
                  Saída
                </h3>
                <b>Saída: {this.state.checkOutSelectValue}</b>
              </Col>
            </Row>
          ) : (
            <> </>
          )}
          <Row>
            {this.state.showModalEditView ? (
              <Col md="12">
                {' '}
                <hr />{' '}
              </Col>
            ) : (
              <></>
            )}
          </Row>
          <div ref={el => (this.myFormRefEdit = el)}>
            {this.state.showModalEditView ? this.renderBodyUpdate(padAttendanceEntity, false) : <></>}
          </div>
          <Row>
            <Col md="12">
              <ModalFooter>
                <Button
                  color="secondary"
                  onClick={() => {
                    this.setState({ showModalEdit: null });
                    this.getEntities();
                  }}
                >
                  Fechar
                </Button>
              </ModalFooter>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    ) : (
      <> </>
    );
  }

  renderHeaderUpdate(padAttendanceEntity, isNew) {
    const { professionals, pads, quoteRhs, loading } = this.props;

    const { push } = padAttendanceEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {isNew ? (
              <Translate contentKey="generadorApp.padAttendance.home.createLabel">Create a PadAttendance</Translate>
            ) : (
              <Translate contentKey="generadorApp.padAttendance.home.editLabel">Edit a PadAttendance</Translate>
            )}
          </span>
        </div>
      </>
    );
  }

  renderBodyUpdate(padAttendanceEntity, isNew) {
    const { professionals, pads, quoteRhs, loading } = this.props;

    const { push } = padAttendanceEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <Row className="justify-content-center">
          <Col md="6">
            <Row>
              <Col md="3">
                <div>
                  <Row>
                    <Col md="12">
                      <Label className="mt-2 label-single-line" id="checkInLabel" for="pad-attendance-checkIn">
                        <Translate contentKey="generadorApp.padAttendance.checkIn">Check in</Translate>
                      </Label>
                    </Col>
                    <Col md="12" className="react-datepicker-only-time">
                      <InputMask
                        onChange={evt => this.setState({ checkInSelectValue: evt.target.value })}
                        value={this.state.checkInSelectValue}
                        type="text"
                        name="checkIn"
                        className={'form-control'}
                        mask="99:99"
                        tag={InputMask}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md="3">
                <div>
                  <Row>
                    <Col md="12">
                      <Label className="mt-2 label-single-line" id="checkOutLabel" for="pad-attendance-checkOut">
                        <Translate contentKey="generadorApp.padAttendance.checkOut">Check out</Translate>
                      </Label>
                    </Col>
                    <Col md="12" className="react-datepicker-only-time">
                      <InputMask
                        onChange={evt => this.setState({ checkOutSelectValue: evt.target.value })}
                        value={this.state.checkOutSelectValue}
                        type="text"
                        name="checkOut"
                        className={'form-control'}
                        mask="99:99"
                        tag={InputMask}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md="3">
                <div>
                  <Row>
                    <Col md="12">
                      <Label className="mt-2 label-single-line" id="checkInLabel" for="pad-attendance-checkIn">
                        {'  '}
                      </Label>
                    </Col>
                    <Col md="12" className="">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          let status = this.state.status;
                          if (this.state.checkOutSelectValue && !this.state.evolutionSelectValue) {
                            MySwal.fire({
                              title: 'Evolução',
                              html: 'Na saida do paciente precisa adicionar a evolução',
                            });
                            return;
                          }
                          if (this.state.checkOutSelectValue && !this.state.evolutionSelectValue) {
                            MySwal.fire({
                              title: 'Evolução',
                              html: 'Na saida do paciente precisa adicionar a evolução',
                            });
                            return;
                          } else if (this.state.checkOutSelectValue) {
                            status = AttendanceStatus.FINALIZADO;
                          } else if (this.state.checkInSelectValue) {
                            status = AttendanceStatus.EMATENDIMENTO;
                          }

                          const checkInData = new Date();
                          checkInData.setHours(this.state.checkInSelectValue.split(':')[0]);
                          checkInData.setMinutes(this.state.checkInSelectValue.split(':')[1]);

                          const checkOutData = new Date();
                          checkOutData.setHours(this.state.checkOutSelectValue.split(':')[0]);
                          checkOutData.setMinutes(this.state.checkOutSelectValue.split(':')[1]);

                          axios
                            .put(BASE_API_VERSION_PATH + 'pad-attendances', {
                              ...padAttendanceEntity,
                              status,
                              checkIn: moment(checkInData).format('YYYY-MM-DD HH:mm:ss'),
                              checkOut: moment(checkOutData).format('YYYY-MM-DD HH:mm:ss'),
                              evolution: this.state.evolutionSelectValue,
                            })
                            .then(v => {
                              this.props.getEntity(
                                v.data.id,
                                'createdBy.id,lastModifiedBy.id,professional.id,pad.id,quoteRh.specialty.category.name,quoteRh.quote.patient.name'
                              );

                              axios
                                .get(BASE_API_VERSION_PATH + 'audit-entities?entityType=PadAttendance&entityId=' + v.data.id)
                                .then(x1 => {
                                  const evolutionHistory = x1.data
                                    .filter(x => {
                                      const entityValue = JSON.parse(x.entityValue);
                                      const entityKeyDiff = x.entityKeyDiff ? x.entityKeyDiff.split(',') : [];
                                      const isInKeyDiff =
                                        entityKeyDiff.includes('evolution') ||
                                        entityKeyDiff.includes('checkIn') ||
                                        entityKeyDiff.includes('checkOut');
                                      const hasValues = entityValue.evolution || entityValue.checkIn || entityValue.checkOut;

                                      return isInKeyDiff && hasValues;
                                    })
                                    .map(x => ({
                                      createdById: x.createdBy.id,
                                      createdByLogin: x.createdBy.login,
                                      createdDate: x.createdDate,
                                    }));

                                  this.setState({
                                    ...this.getFullEntitySelectValue(v.data),
                                    evolutionHistory,
                                  });
                                });
                            });
                        }}
                      >
                        Alterar
                      </button>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md="12">
                <div>
                  <Row>
                    <Col md="12">
                      <Label className="mt-2 label-single-line" id="evolutionLabel" for="pad-attendance-evolution">
                        <Translate contentKey="generadorApp.padAttendance.evolution">Evolution</Translate>
                      </Label>
                    </Col>
                    <Col md="12">
                      <textarea
                        onChange={evt => this.setState({ evolutionSelectValue: evt.target.value })}
                        className={'form-control'}
                        id="pad-attendance-evolution"
                        name="evolution"
                        value={this.state.evolutionSelectValue ? this.state.evolutionSelectValue : ''}
                      />
                    </Col>
                  </Row>
                </div>
                <br />
                <Col
                  style={{
                    maxHeight: '130px',
                    height: '130px',
                    overflow: 'auto',
                  }}
                >
                  {this.state.evolutionHistory ? (
                    <Table
                      id="pad-attendance-table-list"
                      responsive
                      aria-describedby="pad-attendance-heading"
                      className={'table-hover table-striped table-responsive-css'}
                    >
                      <thead className={'thead-light'}>
                        <tr>
                          <th>Atualizado - Data</th>
                          <th>Por</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr></tr>
                        {this.state.evolutionHistory.map((v, i) => (
                          <tr key={i}>
                            <td>
                              <TextFormat value={v.createdDate} type="date" format={APP_LOCAL_DATETIME_SEM_SEG} />
                            </td>
                            <td>{v.createdByLogin}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <> </>
                  )}
                </Col>
              </Col>
            </Row>
          </Col>

          <Col md="6">
            <div>
              {!isNew ? (
                <div>
                  <Row>
                    <Col md="12">
                      <input id="pad-attendance-id" type="hidden" className="form-control" name="id" required readOnly />
                    </Col>
                  </Row>
                </div>
              ) : null}
              <Row>
                {baseFilters !== 'pad' ? (
                  <Col md="12">
                    <div>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" for="pad-attendance-pad">
                            <Translate contentKey="generadorApp.padAttendance.pad">Pad</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <SelectAsync
                            id="pad-attendance-pad"
                            name={'pad'}
                            className={'css-select-control'}
                            data-type-rel="many-to-one-other-side"
                            value={this.state.padSelectValue}
                            onChange={options => this.setState({ padSelectValue: options })}
                            defaultOptions={this.state.padStartSelectOptions ? this.state.padStartSelectOptions : []}
                            loadingMessage={input => translate('selectAsync.loadingMessage')}
                            noOptionsMessage={input =>
                              this.state.padStartSelectOptions === undefined
                                ? translate('selectAsync.loadingMessage')
                                : translate('selectAsync.noOptionsMessage')
                            }
                            onMenuOpen={async () => {
                              if (this.state.padStartSelectOptions === undefined) {
                                const result = await axios.get('/api/pads/?page=0&size=20&sort=id,asc');
                                this.setState({
                                  padStartSelectOptions: result.data
                                    ? result.data.map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
                                    : [],
                                });
                              }
                            }}
                            loadOptions={async (inputValue, callback) => {
                              const result = await axios.get('/api/pads/?page=0&size=20&sort=id,asc&id.contains=' + inputValue);
                              callback(result.data ? result.data.map(p => ({ value: p.id, label: p['id'] ? p.id : '' })) : []);
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                ) : (
                  <input type="hidden" name="pad" value={this.state.fieldsBase[baseFilters + 'Id']} />
                )}
                <Col md="6">
                  <div>
                    <Row>
                      <Col md="12">
                        <Label className="mt-2 label-single-line" id="dateLabel" for="pad-attendance-date">
                          <Translate contentKey="generadorApp.padAttendance.date">Date</Translate>
                        </Label>
                      </Col>
                      <Col md="12">
                        <DatePicker
                          onChange={value => this.setState({ dateSelectValue: value })}
                          id="date"
                          validate={{}}
                          popperPlacement="bottom-start"
                          className="form-control react-datepicker"
                          name="date"
                          dateFormat="dd/MM/yyyy"
                          selected={this.state.dateSelectValue ? moment(this.state.dateSelectValue).toDate() : null}
                          locale="pt"
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md="6">
                  <div>
                    <Row>
                      <Col md="12">
                        <Label className="mt-2 label-single-line" id="periodLabel" for="pad-attendance-period">
                          <Translate contentKey="generadorApp.padAttendance.period">Period</Translate>
                        </Label>
                      </Col>
                      <Col md="12">
                        <Select
                          id="pad-attendance-period"
                          className={'css-select-control'}
                          value={{
                            value: this.state.periodSelectValue,
                            label: translate('generadorApp.PadAttendancePeriod.' + this.state.periodSelectValue),
                          }}
                          options={[
                            { value: 'MATUTINO', label: translate('generadorApp.PadAttendancePeriod.MATUTINO') },
                            { value: 'VESPERTINO', label: translate('generadorApp.PadAttendancePeriod.VESPERTINO') },
                            { value: 'NOTURNO', label: translate('generadorApp.PadAttendancePeriod.NOTURNO') },
                          ]}
                          onChange={(options: any) => this.setState({ periodSelectValue: options['value'] })}
                          name="period"
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="3">
                  <div>
                    <Row>
                      <Col md="12">
                        <Label className="mt-2 label-single-line" id="scheduleLabel" for="pad-attendance-schedule">
                          <Translate contentKey="generadorApp.padAttendance.schedule">Schedule</Translate>
                        </Label>
                      </Col>
                      <Col md="12">
                        <InputMask
                          onChange={evt => this.setState({ scheduleSelectValue: evt.target.value })}
                          value={this.state.scheduleSelectValue}
                          id="pad-attendance-schedule"
                          type="text"
                          name="schedule"
                          className={'form-control'}
                          mask="99:99"
                          tag={InputMask}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md="6">
                  <div>
                    <Row>
                      <Col md="12">
                        <Label className="mt-2 label-single-line" for="pad-attendance-professional">
                          <Translate contentKey="generadorApp.padAttendance.professional">Professional</Translate>
                        </Label>
                      </Col>
                      <Col md="12">
                        <SelectAsync
                          id="pad-attendance-professional"
                          name={'professional'}
                          className={'css-select-control'}
                          data-type-rel="many-to-one-other-side"
                          value={this.state.professionalSelectValue}
                          onChange={options => this.setState({ professionalSelectValue: options })}
                          defaultOptions={this.state.professionalStartSelectOptions ? this.state.professionalStartSelectOptions : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.professionalStartSelectOptions === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.professionalStartSelectOptions === undefined) {
                              const result = await axios.get('/api/professionals/?page=0&size=20&sort=name,asc');
                              this.setState({
                                professionalStartSelectOptions: result.data
                                  ? result.data.map(p => ({ value: p.id, label: p['name'] ? p.name : '' }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await axios.get('/api/professionals/?page=0&size=20&sort=name,asc&name.contains=' + inputValue);
                            callback(result.data ? result.data.map(p => ({ value: p.id, label: p['name'] ? p.name : '' })) : []);
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md="3">
                  <Row>
                    <Col md="12">
                      <Label className="mt-2 label-single-line" id="checkInLabel" for="pad-attendance-checkIn">
                        {'  '}
                      </Label>
                    </Col>
                    <Col md="12" className="">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          axios
                            .put(BASE_API_VERSION_PATH + 'pad-attendances', {
                              ...padAttendanceEntity,
                              professional: getFormFromSelect(this.state.professionalSelectValue, 'many-to-one'),
                              period: this.state.periodSelectValue === null ? 'MATUTINO' : this.state.periodSelectValue,
                              schedule: this.state.scheduleSelectValue,
                              date: this.state.dateSelectValue,
                            })
                            .then(v => {
                              this.props.getEntity(
                                v.data.id,
                                'createdBy.id,lastModifiedBy.id,professional.id,pad.id,quoteRh.specialty.category.name,quoteRh.quote.patient.name'
                              );
                              axios
                                .get(BASE_API_VERSION_PATH + 'audit-entities?entityType=PadAttendance&entityId=' + v.data.id)
                                .then(x1 => {
                                  this.setState({
                                    ...this.getFullEntitySelectValue(v.data),
                                  });
                                });
                            });
                        }}
                      >
                        Alterar
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </>
    );
  }

  renderHeader() {
    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.padAttendance.home.title">Attendance</Translate>
          </span>
          <Button id="togglerFilterPadAttendance" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.padAttendance.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>
          &nbsp;
          <ModalFilterExportPadAttendances setBaseState={v => this.setState(v)} />
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.padAttendance.home.title">Attendance</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    const { professionals, pads, quoteRhs, padAttendanceList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <UncontrolledCollapse toggler="#togglerFilterPadAttendance">
          <CardBody>
            <AvForm ref={el => (this.myFormRef = el)} id="form-filter" onSubmit={this.filterEntity}>
              <div className="row mt-1 ml-3 mr-3">
                <Col md="3">
                  <Row className="mr-1 mt-1">
                    <Label id="dateStart" for="pad-attendance-date_start">
                      <Translate contentKey="generadorApp.padAttendance.date_start">Date (start)</Translate>
                    </Label>
                    <DatePicker
                      onChange={value => this.setState({ dateStart: value })}
                      id="dateStart"
                      className="form-control react-datepicker"
                      name="dateStart"
                      dateFormat="dd/MM/yyyy"
                      selected={this.state.dateStart ? moment(this.state.dateStart).toDate() : null}
                      locale="pt"
                    />
                  </Row>
                </Col>
                <Col md="3">
                  <Row className="mr-1 mt-1">
                    <Label id="dateEnd" for="pad-attendance-date_end">
                      <Translate contentKey="generadorApp.padAttendance.date_end">Date (end)</Translate>
                    </Label>
                    <DatePicker
                      onChange={value => this.setState({ dateEnd: value })}
                      id="dateEnd"
                      className="form-control react-datepicker"
                      name="dateEnd"
                      dateFormat="dd/MM/yyyy"
                      selected={this.state.dateEnd ? moment(this.state.dateEnd).toDate() : null}
                      locale="pt"
                    />
                  </Row>
                </Col>

                <Col md="3">
                  <Row className="mr-1 mt-1">
                    <Label id="dateEnd" for="pad-attendance-date_end">
                      <Translate contentKey="generadorApp.quote.detail.title">quote</Translate>
                    </Label>
                    <SelectAsync
                      id="expected-result-quote"
                      isMulti
                      className={'css-select-control'}
                      name={'quote'}
                      cacheOptions
                      value={this.state.quoteRhQuoteId}
                      onChange={options => this.setState({ quoteRhQuoteId: options })}
                      defaultOptions={this.state.quoteRhQuoteStartFilter ? this.state.quoteRhQuoteStartFilter : []}
                      loadingMessage={input => translate('selectAsync.loadingMessage')}
                      noOptionsMessage={input =>
                        this.state.quoteRhQuoteStartFilter === undefined
                          ? translate('selectAsync.loadingMessage')
                          : translate('selectAsync.noOptionsMessage')
                      }
                      onMenuOpen={async () => {
                        if (this.state.quoteRhQuoteStartFilter === undefined) {
                          const result = await getListAxios('quotes', {}, 0, 100, 'id,asc', 'id');
                          this.setState({
                            quoteRhQuoteStartFilter: result.data
                              ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') }))
                              : [],
                          });
                        }
                      }}
                      loadOptions={async (inputValue, callback) => {
                        const result = await getListAxios('quotes', { 'id.contains': inputValue }, 0, 100, 'id,asc', 'id');
                        callback(result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') })) : []);
                      }}
                    />
                  </Row>
                </Col>

                <Col md="3">
                  <Row className="mr-1 mt-1">
                    <div style={{ width: '100%' }}>
                      <Label for="pad-attendance-quoteRh-quote-patient">
                        <Translate contentKey="generadorApp.padAttendance.quoteRh.quote.patient">Quote Rh</Translate>
                      </Label>
                      <SelectAsync
                        id="pad-attendance-quoteRh-quote-patient"
                        isMulti
                        className={'css-select-control'}
                        name={'quoteRh-quote-patient'}
                        cacheOptions
                        value={this.state.quoteRhQuotePatientId}
                        onChange={options => this.setState({ quoteRhQuotePatientId: options })}
                        defaultOptions={this.state.quoteRhQuotePatientStartFilter ? this.state.quoteRhQuotePatientStartFilter : []}
                        loadingMessage={input => translate('selectAsync.loadingMessage')}
                        noOptionsMessage={input =>
                          this.state.quoteRhQuotePatientStartFilter === undefined
                            ? translate('selectAsync.loadingMessage')
                            : translate('selectAsync.noOptionsMessage')
                        }
                        onMenuOpen={async () => {
                          if (this.state.quoteRhQuotePatientStartFilter === undefined) {
                            const result = await getListAxios('patients', {}, 0, 100, 'name,asc', 'name');
                            this.setState({
                              quoteRhQuotePatientStartFilter: result.data
                                ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                                : [],
                            });
                          }
                        }}
                        loadOptions={async (inputValue, callback) => {
                          const result = await getListAxios('patients', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                          callback(
                            result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []
                          );
                        }}
                      />
                    </div>
                  </Row>
                </Col>

                <Col md="3">
                  <Row className="mr-1 mt-1">
                    <div style={{ width: '100%' }}>
                      <Label for="pad-attendance-professional">
                        <Translate contentKey="generadorApp.padAttendance.professional">Professional</Translate>
                      </Label>
                      <SelectAsync
                        id="pad-attendance-professional"
                        isMulti
                        className={'css-select-control'}
                        name={'professional'}
                        cacheOptions
                        value={this.state.professionalId}
                        onChange={options => this.setState({ professionalId: options })}
                        defaultOptions={this.state.professionalStartFilter ? this.state.professionalStartFilter : []}
                        loadingMessage={input => translate('selectAsync.loadingMessage')}
                        noOptionsMessage={input =>
                          this.state.professionalStartFilter === undefined
                            ? translate('selectAsync.loadingMessage')
                            : translate('selectAsync.noOptionsMessage')
                        }
                        onMenuOpen={async () => {
                          if (this.state.professionalStartFilter === undefined) {
                            const result = await getListAxios('professionals', {}, 0, 100, 'name,asc', 'name');
                            this.setState({
                              professionalStartFilter: result.data
                                ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                                : [],
                            });
                          }
                        }}
                        loadOptions={async (inputValue, callback) => {
                          const result = await getListAxios('professionals', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                          callback(
                            result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []
                          );
                        }}
                      />
                    </div>
                  </Row>
                </Col>

                <Col md="3" className="col-filter-pad-attendance-status">
                  <Row className="mr-1 mt-1">
                    <Label id="statusLabel" for="pad-attendance-status">
                      <Translate contentKey="generadorApp.padAttendance.status">Status</Translate>
                    </Label>
                    <Select
                      id="pad-attendance-status"
                      className={'css-select-control'}
                      value={
                        this.state.status
                          ? { value: this.state.status, label: translate('generadorApp.AttendanceStatus.' + this.state.status) }
                          : { value: '', label: translate('generadorApp.padAttendance.status') }
                      }
                      options={[
                        { value: '', label: translate('generadorApp.padAttendance.status') },
                        { value: 'AGUARDANDOCONFIRMACAO', label: translate('generadorApp.AttendanceStatus.AGUARDANDOCONFIRMACAO') },
                        { value: 'AGUARDANDOATENDIMENTO', label: translate('generadorApp.AttendanceStatus.AGUARDANDOATENDIMENTO') },
                        { value: 'EMATENDIMENTO', label: translate('generadorApp.AttendanceStatus.EMATENDIMENTO') },
                        { value: 'FINALIZADO', label: translate('generadorApp.AttendanceStatus.FINALIZADO') },
                        { value: 'CANCELADO', label: translate('generadorApp.AttendanceStatus.CANCELADO') },
                      ]}
                      onChange={(options: any) => this.setState({ status: options['value'] })}
                      name="status"
                    />
                  </Row>
                </Col>

                <Col md="3">
                  <Row className="mr-1 mt-1">
                    <div style={{ width: '100%' }}>
                      <Label for="pad-attendance-quoteRh-quote-patient-client">
                        <Translate contentKey="generadorApp.client.detail.title">Client</Translate>
                      </Label>
                      <SelectAsync
                        id="pad-attendance-quoteRh-quote-patient-client"
                        isMulti
                        className={'css-select-control'}
                        name={'quoteRh-quote-patient-client'}
                        cacheOptions
                        value={this.state.quoteRhQuotePatientClientId}
                        onChange={options => this.setState({ quoteRhQuotePatientClientId: options })}
                        defaultOptions={
                          this.state.quoteRhQuotePatientClientStartFilter ? this.state.quoteRhQuotePatientClientStartFilter : []
                        }
                        loadingMessage={input => translate('selectAsync.loadingMessage')}
                        noOptionsMessage={input =>
                          this.state.quoteRhQuotePatientClientStartFilter === undefined
                            ? translate('selectAsync.loadingMessage')
                            : translate('selectAsync.noOptionsMessage')
                        }
                        onMenuOpen={async () => {
                          if (this.state.quoteRhQuotePatientClientStartFilter === undefined) {
                            const result = await getListAxios('clients', {}, 0, 100, 'fantasyName,asc', 'fantasyName');
                            this.setState({
                              quoteRhQuotePatientClientStartFilter: result.data
                                ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'fantasyName') }))
                                : [],
                            });
                          }
                        }}
                        loadOptions={async (inputValue, callback) => {
                          const result = await getListAxios(
                            'clients',
                            { 'fantasyName.contains': inputValue },
                            0,
                            100,
                            'fantasyName,asc',
                            'fantasyName'
                          );
                          callback(
                            result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'fantasyName') })) : []
                          );
                        }}
                      />
                    </div>
                  </Row>
                </Col>

                <Col md="3">
                  <Row className="mr-1 mt-1">
                    <div style={{ width: '100%' }}>
                      <Label for="pad-attendance-quoteRh-specialty-category">
                        <Translate contentKey="generadorApp.padAttendance.quoteRh.specialty.category">Quote Rh</Translate>
                      </Label>
                      <SelectAsync
                        id="pad-attendance-quoteRh-specialty-category"
                        isMulti
                        className={'css-select-control'}
                        name={'quoteRh-specialty-category'}
                        cacheOptions
                        value={this.state.quoteRhSpecialtyCategoryId}
                        onChange={options => this.setState({ quoteRhSpecialtyCategoryId: options })}
                        defaultOptions={
                          this.state.quoteRhSpecialtyCategoryStartFilter ? this.state.quoteRhSpecialtyCategoryStartFilter : []
                        }
                        loadingMessage={input => translate('selectAsync.loadingMessage')}
                        noOptionsMessage={input =>
                          this.state.quoteRhSpecialtyCategoryStartFilter === undefined
                            ? translate('selectAsync.loadingMessage')
                            : translate('selectAsync.noOptionsMessage')
                        }
                        onMenuOpen={async () => {
                          if (this.state.quoteRhSpecialtyCategoryStartFilter === undefined) {
                            const result = await getListAxios('categories', {}, 0, 100, 'name,asc', 'name');
                            this.setState({
                              quoteRhSpecialtyCategoryStartFilter: result.data
                                ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                                : [],
                            });
                          }
                        }}
                        loadOptions={async (inputValue, callback) => {
                          const result = await getListAxios('categories', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                          callback(
                            result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []
                          );
                        }}
                      />
                    </div>
                  </Row>
                </Col>

                <Col md="3">
                  <Row className="mr-1 mt-1">
                    <Label id="periodLabel" for="pad-attendance-period" style={{ width: '100%' }}>
                      <Translate contentKey="generadorApp.padAttendance.period">Period</Translate>
                    </Label>
                    <Select
                      id="pad-attendance-period"
                      style={{ width: '100%' }}
                      className={'css-select-control'}
                      value={
                        this.state.period
                          ? { value: this.state.period, label: translate('generadorApp.PadAttendancePeriod.' + this.state.period) }
                          : { value: '', label: translate('generadorApp.padAttendance.period') }
                      }
                      options={[
                        { value: '', label: translate('generadorApp.padAttendance.period') },
                        { value: 'MATUTINO', label: translate('generadorApp.PadAttendancePeriod.MATUTINO') },
                        { value: 'VESPERTINO', label: translate('generadorApp.PadAttendancePeriod.VESPERTINO') },
                        { value: 'NOTURNO', label: translate('generadorApp.PadAttendancePeriod.NOTURNO') },
                      ]}
                      onChange={(options: any) => this.setState({ period: options['value'] })}
                      name="period"
                    />
                  </Row>
                </Col>

                <Col md="3">
                  <Row className="mr-1 mt-1">
                    <Label id="scheduleLabel" for="pad-attendance-schedule">
                      <Translate contentKey="generadorApp.padAttendance.schedule">Schedule</Translate>
                    </Label>

                    <input
                      onChange={evt => this.setState({ scheduleSelectValue: evt.target.value })}
                      type="text"
                      name="schedule"
                      id="pad-attendance-schedule"
                      className={'form-control'}
                      value={this.state.scheduleSelectValue}
                    />
                  </Row>
                </Col>

                <Col md="3">
                  <Row className="mr-1 mt-1">
                    <Label id="photoFilter" for="pad-attendance-photo">
                      Foto
                    </Label>
                    <Select
                      id="pad-attendance-photo"
                      className={'css-select-control'}
                      value={
                        this.state.photoFilter
                          ? { value: this.state.photoFilter, label: this.state.photoFilter }
                          : { value: '', label: 'Foto' }
                      }
                      options={[
                        { value: '', label: 'Foto' },
                        { value: 'SIM', label: 'SIM' },
                        { value: 'NÃO', label: 'NÃO' },
                      ]}
                      onChange={(options: any) => this.setState({ photoFilter: options['value'] })}
                      name="photoFilter"
                    />
                  </Row>
                </Col>
              </div>
              <div className="row mb-2 mr-4 justify-content-end">
                <Button color="primary" type="submit">
                  <i className="fa fa-filter" aria-hidden={'true'}></i>&nbsp;
                  <Translate contentKey="generadorApp.padAttendance.btnFilter">Filter</Translate>
                </Button>
                &nbsp;
                <Button color="secondary" onClick={this.cancelFilters}>
                  <FontAwesomeIcon icon="trash-alt" />
                  &nbsp;
                  <Translate contentKey="generadorApp.padAttendance.btnCleanFilter">Clean Filter</Translate>
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </UncontrolledCollapse>
      </>
    );
  }

  renderTable() {
    const { professionals, pads, quotes, quoteRhs, padAttendanceList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {this.state.listCheckedID.length > 0 ? (
          <div className="clasificar-all" style={{ textAlign: 'center' }}>
            {this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems ? (
              <div className="alert alert-info" role="alert">
                {!this.state.isCheckedAll ? (
                  this.props.totalItems < 20 ? (
                    <>
                      <Translate
                        contentKey="generadorApp.quote.listSelectedButtons.allItensAreSelected"
                        interpolate={{ totalItems: this.props.totalItems }}
                      >
                        Os {this.props.totalItems} Attendance nesta página estão selecionadas.
                      </Translate>
                      &nbsp;
                      <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => this.handleInputAllChange()}>
                        <Translate contentKey="generadorApp.quote.listSelectedButtons.clearSelect">Limpar Seleção</Translate>
                      </span>
                    </>
                  ) : (
                    <>
                      <Translate
                        contentKey="generadorApp.quote.listSelectedButtons.allItensAreSelectedThisPage"
                        interpolate={{ itemsPerPage: this.state.itemsPerPage }}
                      >
                        Todos os {this.state.itemsPerPage} Attendance nesta página estão selecionadas.
                      </Translate>
                      &nbsp;
                      <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => this.handleInputAllChange()}>
                        <Translate
                          contentKey="generadorApp.quote.listSelectedButtons.selectAll"
                          interpolate={{ totalItems: this.props.totalItems }}
                        >
                          Selecionar os {this.props.totalItems} Attendance
                        </Translate>
                      </span>
                    </>
                  )
                ) : (
                  <>
                    <Translate
                      contentKey="generadorApp.quote.listSelectedButtons.allItensAreSelectedAllPages"
                      interpolate={{ totalItems: this.props.totalItems }}
                    >
                      Todas os {this.props.totalItems} Attendance estão selecionadas.
                    </Translate>
                    &nbsp;
                    <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => this.handleInputAllChange()}>
                      <Translate contentKey="generadorApp.quote.listSelectedButtons.clearSelect">Limpar Seleção</Translate>
                    </span>
                  </>
                )}
              </div>
            ) : (
              <></>
            )}
            <div className={'pull-right p-b-15'}>
              <Button className="float-right jh-create-entity" onClick={() => this.removeEntitySelected()} color="primary" size="md">
                <FontAwesomeIcon icon="trash" />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="generadorApp.padAttendance.listSelectedButtons.cancel">Cancelar</Translate>
                </span>
              </Button>
            </div>
          </div>
        ) : (
          <> </>
        )}
        {padAttendanceList && padAttendanceList.length > 0 ? (
          <Table
            id="pad-attendance-table-list"
            responsive
            aria-describedby="pad-attendance-heading"
            className={'table-hover table-striped table-responsive-css'}
          >
            <thead className={'thead-light'}>
              <tr>
                <th className={'align-middle text-center'} style={{ width: '4%' }}>
                  <input
                    type="checkbox"
                    checked={
                      this.state.listCheckedID.length === this.state.itemsPerPage ||
                      this.state.listCheckedID.length === this.props.totalItems
                    }
                    onChange={() => this.handleInputAllThisItens()}
                  />
                </th>
                <th>
                  <Translate contentKey="generadorApp.quote.detail.title">Quote</Translate>
                </th>
                <th>
                  <Translate contentKey="generadorApp.padAttendance.date">Date</Translate>
                </th>
                <th>
                  <Translate contentKey="generadorApp.padAttendance.period">Period</Translate>
                </th>
                <th style={{ width: '170px' }}>
                  <Translate contentKey="generadorApp.patient.detail.title">Patient</Translate>
                </th>
                <th>Complexidade</th>
                <th>
                  <Translate contentKey="generadorApp.professional.detail.title">Professional</Translate>
                </th>
                <th>
                  <Translate contentKey="generadorApp.category.detail.title">Category</Translate>
                </th>
                <th>
                  <Translate contentKey="generadorApp.client.detail.title">Client</Translate>
                </th>
                <th>
                  <Translate contentKey="generadorApp.padAttendance.checkIn">checkIn</Translate>
                </th>
                <th>
                  <Translate contentKey="generadorApp.padAttendance.checkOut">checkOut</Translate>
                </th>
                <th>
                  <Translate contentKey="generadorApp.padAttendance.status">Status</Translate>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr></tr>
              {padAttendanceList
                .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                .map((padAttendance, i) => {
                  const quoteAttendance = quotes
                    .filter(v => padAttendance.quoteRh && v.quoteRh.map(b => b.id).includes(padAttendance.quoteRh.id))
                    .pop();

                  return (
                    <tr key={`entity-${i}`} className={AttendanceStatus.CANCELADO === padAttendance.status ? 'alert alert-danger' : ''}>
                      <td className={'align-middle text-center'}>
                        <input
                          type="checkbox"
                          checked={this.state.listCheckedID.includes(padAttendance.id)}
                          onChange={() => this.handleInputChange(padAttendance.id)}
                        />
                      </td>
                      <td id="quoteRh-cell">
                        <b className="visible-xs"> Quote </b>
                        {getValueRecursive(padAttendance, 'quoteRh.quote.id'.split('.'), '')}
                      </td>
                      <td id="date-cell">
                        <b className="visible-xs"> Date </b>
                        <TextFormat type="date" value={padAttendance.date} format={APP_LOCAL_DATE_FORMAT} />
                      </td>
                      <td id="period-cell">
                        <b className="visible-xs"> Period </b>
                        {padAttendance.period ? (
                          <Translate contentKey={`generadorApp.PadAttendancePeriod.SMALL_${padAttendance.period}`} />
                        ) : (
                          <> </>
                        )}
                      </td>
                      <td id="patient-cell">
                        <b className="visible-xs"> Patient </b>
                        {getValueRecursive(padAttendance, 'quoteRh.quote.patient.name'.split('.'), '')
                          .toLowerCase()
                          .split(' ')
                          .map(v => (v ? v[0].toUpperCase() + v.substring(1) : v))
                          .join(' ')}
                      </td>
                      <td id="patientComplexity-cell">
                        <b className="visible-xs"> Patient </b>
                        {getValueRecursive(padAttendance, 'quoteRh.quote.patient.patientComplexity'.split('.'), '')}
                      </td>
                      <td id="professional-cell">
                        <b className="visible-xs"> Professional </b>
                        {padAttendance ? (
                          <span data-type-rel="2-many-to-one-other-side">
                            {getValueRecursive(padAttendance, 'professional.name'.split('.'), '')
                              .toLowerCase()
                              .split(' ')
                              .map(v => (v ? v[0].toUpperCase() + v.substring(1) : v))
                              .join(' ')}
                          </span>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td id="professionalCategory-cell">
                        <b className="visible-xs"> Categoria </b>
                        {padAttendance ? (
                          <span data-type-rel="2-many-to-one-other-side">
                            {getValueRecursive(padAttendance, 'professional.specialties.category.name'.split('.'), '').split(', ').pop()}
                          </span>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td id="patient-cell">
                        <b className="visible-xs"> client </b>
                        {getValueRecursive(padAttendance, 'quoteRh.quote.patient.client.fantasyName'.split('.'), '')}
                      </td>
                      <td id="checkIn-cell">
                        {padAttendance.checkIn && moment(padAttendance.checkIn) ? (
                          <TextFormat value={padAttendance.checkIn} type="date" format={APP_LOCAL_TIME_FORMAT} />
                        ) : (
                          ''
                        )}
                      </td>
                      <td id="checkOut-cell">
                        {padAttendance.checkOut && moment(padAttendance.checkOut) ? (
                          <TextFormat value={padAttendance.checkOut} type="date" format={APP_LOCAL_TIME_FORMAT} />
                        ) : (
                          ''
                        )}
                      </td>
                      <td id="checkOut-cell">{translate('generadorApp.AttendanceStatus.' + padAttendance.status)}</td>

                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          <Dropdown isOpen={state.dropdownButtons[i]} toggle={() => this.toggle(i)}>
                            <DropdownToggle caret>
                              <Translate contentKey="generadorApp.patient.btnActions">Actions</Translate>
                            </DropdownToggle>
                            <DropdownMenu right>
                              <DropdownItem onClick={() => this.openUpdateModal(padAttendance, false)}>
                                <FontAwesomeIcon icon="eye" />
                                <span className="d-none d-md-inline">Ver</span>
                              </DropdownItem>
                              {padAttendance.status !== AttendanceStatus.CANCELADO ? (
                                <>
                                  <DropdownItem onClick={() => this.openUpdateModal(padAttendance, true)}>
                                    <FontAwesomeIcon icon="pencil-alt" /> Editar
                                  </DropdownItem>
                                  <DropdownItem tag={Link} to={`/pad-attendance/${padAttendance.id}/pad-attendance-file`}>
                                    <FontAwesomeIcon icon="file" /> Arquivos
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => {
                                      MySwal.fire({
                                        title: (
                                          <p>
                                            <Translate contentKey={`generadorApp.cancel_the_service`} />
                                          </p>
                                        ),
                                        html: (
                                          <p>
                                            <Translate contentKey={`generadorApp.want_to_cancel_the_service`} />
                                          </p>
                                        ),
                                        showConfirmButton: true,
                                        showCancelButton: true,
                                      }).then(response => {
                                        if (response.isConfirmed) {
                                          this.updateEntity({ ...padAttendance, status: AttendanceStatus.CANCELADO });
                                        }
                                      });
                                    }}
                                  >
                                    <FontAwesomeIcon icon="ban" /> Cancelar
                                  </DropdownItem>
                                </>
                              ) : (
                                <> </>
                              )}
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.padAttendance.home.notFound">No Attendance found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const { professionals, pads, quoteRhs, padAttendanceList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={padAttendanceList && padAttendanceList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const { professionals, pads, quoteRhs, padAttendanceList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel>
        {this.renderModal()}
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div id="attendance-totais" className="row justify-content-md-center mb-4">
            {Object.keys(this.personalize).map((val, i) => {
              const current = props.totalize.find(row => row.status === val);
              return (
                <div className="col-lg-2 col-sm-4 col-12 text-center" key={'totalize-' + i}>
                  <div className="row main-box-layout img-thumbnail">
                    <div className={'col-lg-12 col-sm-12 col-12 box-icon-section ' + this.personalize[val].color}>
                      <i className={this.personalize[val].icon} aria-hidden="true">
                        {' '}
                      </i>
                    </div>
                    <div className="col-lg-12 col-sm-12 col-12 box-text-section">
                      <p>{translate('generadorApp.AttendanceStatus.' + val)}</p>
                    </div>
                    <div className="label" style={{ padding: 0 }}>
                      <h3>
                        <span className={'badge badge-pill ' + this.personalize[val].color}>
                          {current === undefined ? 0 : current.amount}
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
            {this.renderNewModal()}

            {this.renderUpdateModal()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }

  renderModal() {
    const report: any = this.state.report;
    if (!report || this.state.showModalReport !== true) return <> </>;

    return (
      <Modal size="xl" isOpen toggle={() => this.setState({ showModalReport: false })}>
        <ModalBody id="generadorApp.quote.pdf.generate">
          <PadAttendancesPdfReport data={report.data} from={this.state.fromFilter} to={this.state.toFilter} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => this.setState({ showModalReport: false })}>
            <FontAwesomeIcon icon="ban" />
            &nbsp;
            <Translate contentKey="generadorApp.quote.btnCancel">Close</Translate>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export const mapStateToProps = ({ padAttendance, ...storeState }: IRootState) => ({
  professionals: storeState.professional.entities,
  pads: storeState.pad.entities,
  quotes: storeState.quote.entities,
  quoteRhs: storeState.quoteRh.entities,
  padAttendanceList: padAttendance.entities,
  totalItems: padAttendance.totalItems,
  loading: padAttendance.loading,

  // UPDATE
  padAttendanceEntity: padAttendance.entity,
  totalize: padAttendance.totalize,
});

export const mapDispatchToProps = {
  reset,
  getEntities,

  updateEntity,

  // UPDATE
  getEntity,
  setBlob,
  createEntity,

  // DELETE
  deleteEntity,
  getTotalize,
  cancelAttendances,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PadAttendanceListAll);
