import React, { useState } from 'react';
import { Document, Page, StyleSheet, View, Text, PDFViewer } from '@react-pdf/renderer';
import Patient from '../../../../../../../server/src/domain/patient.entity';
import { DataTableCell, Table, TableBody, TableCell, TableHeader } from '@david.kucsai/react-pdf-table';

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  tableView: {
    border: 0,
    width: '100%',
    flexDirection: 'column',
    paddingVertical: 20,
  },
  tableNumberField: {
    textAlign: 'right',
    border: 0,
  },
  tableField: {
    border: 0,
  },
});

const buildHeader = function (patient: Patient, approvalDate, conta, quoteId) {
  return (
    <>
      <View style={{ width: '100%', flexDirection: 'row', paddingBottom: 7 }} fixed={true}>
        <View style={{ width: '90%' }}>
          <Text style={{ fontSize: 10, paddingVertical: 2 }}>Conta: {conta}</Text>
          <Text style={{ fontSize: 10, paddingVertical: 2 }}>
            Paciente: <Text style={{ fontWeight: 'bold' }}>{patient.name}</Text>
          </Text>
          <Text
            style={{
              fontSize: 10,
              paddingVertical: 2,
            }}
          >
            Endereço: {patient.street + ', ' + patient.number + ', ' + patient.neighborhood}
          </Text>
          <Text
            style={{
              fontSize: 10,
              paddingVertical: 2,
            }}
          >
            Cidade: {patient.city + ', ' + patient.uf + '    CEP: ' + patient.zipCode}
          </Text>
          <Text style={{ fontSize: 10, paddingVertical: 2 }}>
            Telefone:{' '}
            {patient.patientContact ? (patient.patientContact[0].telephonePrimary ? patient.patientContact[0].telephonePrimary : '') : ''}
          </Text>
        </View>

        <View style={{ width: '10%' }}>
          <View style={{ borderTop: 1, borderRight: 1, borderLeft: 1 }}>
            <Text style={{ fontSize: 15, fontWeight: 'bold', textAlign: 'center' }}>Romaneio</Text>
          </View>
          <View style={{ borderTop: 1, borderRight: 1, borderLeft: 1 }}>
            <Text style={{ fontSize: 6, fontWeight: 'bold', paddingLeft: 3 }}>Nº</Text>
            <Text style={{ fontSize: 10, fontWeight: 'bold', textAlign: 'center' }}>{quoteId}</Text>
          </View>
          <View style={{ borderTop: 1, borderRight: 1, borderLeft: 1 }}>
            <Text style={{ fontSize: 6, fontWeight: 'bold', paddingLeft: 3 }}>Data</Text>
            <Text style={{ fontSize: 10, fontWeight: 'bold', textAlign: 'center' }}>{approvalDate}</Text>
          </View>
          <View style={{ borderTop: 1, borderRight: 1, borderLeft: 1, borderBottom: 1 }}>
            <Text
              style={{ fontSize: 10, paddingVertical: 3, textAlign: 'center' }}
              render={({ pageNumber, totalPages }) => `Página: ${pageNumber}`}
              fixed={true}
            />
          </View>
        </View>
      </View>
    </>
  );
};

export const RomaneioPdfReport = (props: any) => {
  const [ready, setReady] = useState(false);

  if (ready === false) {
    setTimeout(() => {
      setReady(true);
    }, 2);
    return <></>;
  }
  const quote = props.data;
  const userLogged = props.userLogged;
  const totalItems = quote.quoteMaterial.length + quote.quoteMedicine.length + quote.quoteDiet.length;
  let totalUnidades = 0;
  const tableData = [];
  let index = -1;

  quote.quoteMaterial.map(val => {
    totalUnidades += parseFloat(val.quantity);
    val.index = index++;
    tableData.push(val);
  });

  quote.quoteMedicine.map(val => {
    totalUnidades += parseFloat(val.quantity);
    val.index = index++;
    tableData.push(val);
  });

  quote.quoteDiet.map(val => {
    totalUnidades += parseFloat(val.quantity);
    val.index = index++;
    tableData.push(val);
  });

  const currentDateObj = new Date();
  const currentDateString =
    currentDateObj.getFullYear() +
    '/' +
    (currentDateObj.getMonth() + 1) +
    '/' +
    currentDateObj.getDate() +
    ' ' +
    currentDateObj.getHours() +
    ':' +
    currentDateObj.getMinutes() +
    ':' +
    currentDateObj.getSeconds();

  return (
    <PDFViewer style={{ width: '100%', minHeight: '400px' }}>
      <Document>
        {
          <Page size="A4" key="" orientation="landscape" style={styles.page}>
            {buildHeader(quote.patient, quote.approvalDate, quote.accountNumber, quote.id)}

            <View style={styles.tableView}>
              <Table data={tableData}>
                <TableHeader fontSize={10} includeLeftBorder={false} includeRightBorder={false}>
                  <TableCell weighting={0.1} style={{ textAlign: 'center', padding: 3, border: 0 }}>
                    <View>
                      <Text> Cód. </Text>
                    </View>
                  </TableCell>
                  <TableCell weighting={0.4} style={{ padding: 3, border: 0 }}>
                    <View>
                      <Text> Descrição do Material </Text>
                    </View>
                  </TableCell>
                  <TableCell weighting={0.1} style={{ textAlign: 'center', padding: 3, border: 0 }}>
                    <View>
                      <Text> Qtdade </Text>
                    </View>
                  </TableCell>
                  <TableCell weighting={0.2} style={{ textAlign: 'center', padding: 3, border: 0 }}>
                    <View>
                      <Text> Unid </Text>
                    </View>
                  </TableCell>
                  <TableCell weighting={0.2} style={{ textAlign: 'center', padding: 3, border: 0 }}>
                    <View>
                      <Text> Marca </Text>
                    </View>
                  </TableCell>
                </TableHeader>
                <TableBody fontSize={9} includeLeftBorder={false} includeRightBorder={false}>
                  <DataTableCell
                    key={'column-0'}
                    weighting={0.1}
                    style={{ textAlign: 'center', border: 0 }}
                    getContent={r => (
                      <View
                        style={{
                          backgroundColor: `${r.index % 2 === 0 ? '#cff6cf' : ''}`,
                          padding: 3,
                          flexGrow: 1,
                        }}
                      >
                        <Text>{r.id}</Text>
                      </View>
                    )}
                  />
                  <DataTableCell
                    key={'column-0'}
                    weighting={0.4}
                    style={{ border: 0 }}
                    getContent={r => (
                      <View
                        style={{
                          backgroundColor: `${r.index % 2 === 0 ? '#cff6cf' : ''}`,
                          padding: 3,
                          flexGrow: 1,
                        }}
                      >
                        <Text>{r.medicineMaterial.name}</Text>
                      </View>
                    )}
                  />
                  <DataTableCell
                    key={'column-0'}
                    weighting={0.1}
                    style={{ textAlign: 'center', border: 0 }}
                    getContent={r => (
                      <View
                        style={{
                          backgroundColor: `${r.index % 2 === 0 ? '#cff6cf' : ''}`,
                          padding: 3,
                          flexGrow: 1,
                        }}
                      >
                        <Text>{r.quantity}</Text>
                      </View>
                    )}
                  />
                  <DataTableCell
                    key={'column-0'}
                    weighting={0.2}
                    style={{ textAlign: 'center', border: 0 }}
                    getContent={r => (
                      <View
                        style={{
                          backgroundColor: `${r.index % 2 === 0 ? '#cff6cf' : ''}`,
                          padding: 3,
                          flexGrow: 1,
                        }}
                      >
                        <Text>{r.medicineMaterial.presentation ? r.medicineMaterial.presentation.name : ''}</Text>
                      </View>
                    )}
                  />
                  <DataTableCell
                    key={'column-0'}
                    weighting={0.2}
                    style={{ textAlign: 'center', border: 0 }}
                    getContent={r => (
                      <View
                        style={{
                          backgroundColor: `${r.index % 2 === 0 ? '#cff6cf' : ''}`,
                          padding: 3,
                          flexGrow: 1,
                        }}
                      >
                        <Text>{r.medicineMaterial.brand ? r.medicineMaterial.brand.name : ''}</Text>
                      </View>
                    )}
                  />
                </TableBody>
              </Table>
            </View>

            <View style={{ marginTop: 10 }}>
              <View style={{ width: '100%', flexDirection: 'row', borderTop: 1, borderRight: 1, borderLeft: 1 }}>
                <View style={{ width: '20%', padding: 3, borderRight: 1 }}>
                  <Text style={{ fontSize: 10, fontWeight: 'bold' }}>Ítens: {totalItems}</Text>
                </View>
                <View style={{ width: '20%', padding: 3, borderRight: 1 }}>
                  <Text style={{ fontSize: 10, fontWeight: 'bold' }}>Unidades: {totalUnidades}</Text>
                </View>
                <View style={{ width: '20%', padding: 3, borderRight: 1 }}>
                  <Text style={{ fontSize: 10, fontWeight: 'bold' }}>Volumes:</Text>
                </View>
                <View style={{ width: '20%', padding: 3, borderRight: 1 }}>
                  <Text style={{ fontSize: 10, fontWeight: 'bold' }}>Peso Liq.:</Text>
                </View>
                <View style={{ width: '20%', padding: 3 }}>
                  <Text style={{ fontSize: 10, fontWeight: 'bold' }}></Text>
                </View>
              </View>
              <View style={{ width: '100%', flexDirection: 'row', minHeight: 50, border: 1, padding: 3 }}>
                <Text style={{ fontSize: 8, paddingVertical: 2 }}>Observações:</Text>
              </View>
              <View
                style={{
                  width: '100%',
                  flexDirection: 'row',
                  minHeight: 40,
                  paddingBottom: 5,
                  borderBottom: 1,
                  borderRight: 1,
                  borderLeft: 1,
                }}
              >
                <View style={{ width: '20%', padding: 3, borderRight: 1 }}>
                  <Text style={{ fontSize: 8, paddingVertical: 2 }}>Solicitante:</Text>
                  <Text style={{ fontSize: 9, paddingVertical: 2, textAlign: 'center' }}>{quote.approvalDate}</Text>
                  <Text style={{ fontSize: 9, paddingVertical: 2, textAlign: 'center' }}>{userLogged.fullname}</Text>
                </View>
                <View style={{ width: '20%', padding: 3, borderRight: 1 }}>
                  <Text style={{ fontSize: 8, fontWeight: 'bold' }}>Separador:</Text>
                </View>
                <View style={{ width: '20%', padding: 3, borderRight: 1 }}>
                  <Text style={{ fontSize: 8, fontWeight: 'bold' }}>Conferente:</Text>
                </View>
                <View style={{ width: '20%', padding: 3, borderRight: 1 }}>
                  <Text style={{ fontSize: 8, fontWeight: 'bold' }}>Transportador:</Text>
                </View>
                <View style={{ width: '20%', padding: 3 }}>
                  <Text style={{ fontSize: 8, fontWeight: 'bold' }}>Recebido por:</Text>
                </View>
              </View>
            </View>
            <Text style={{ fontSize: 7, marginTop: 5 }}>Impressão: {currentDateString.substring(0, 15)}</Text>
          </Page>
        }
      </Document>
    </PDFViewer>
  );
};
