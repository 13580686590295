import React from 'react';

import { connect } from 'react-redux';

import { Moment } from 'moment';
import { Client } from 'app/entities/client/client';

export interface IChartPacientesStatusProps extends StateProps, DispatchProps {
  periodoInicio?: Moment;
  periodoFim?: Moment;
  operadora?: Array<Client>;
  pacientesPorStatus: {
    TotalAtivos: number;
    TotalHospitalizados: number;
    TotalDesospitalizados: number;
    TotalIntercorrencias: number;
  };
}

const ChartPacientesStatus = (props: IChartPacientesStatusProps) => {
  return (
    <div className="card border-0 bg-dark text-white text-truncate mb-3">
      <div className="card-body">
        <div className="mb-3 text-grey">
          <b className="mb-3">Pacientes por Status</b>
        </div>
        <div className="d-flex align-items-center mb-1">
          <h2 className="text-white mb-0">{props.pacientesPorStatus?.TotalAtivos}</h2>
        </div>
        <div className="d-flex mb-2">
          <div className="d-flex align-items-center">
            <i className="fa fa-circle text-teal f-s-8 mr-2"></i>
            Hospitalizado
          </div>
          <div className="d-flex align-items-center ml-auto">
            <div className="width-50 text-right pl-2 f-w-600">{props.pacientesPorStatus?.TotalHospitalizados}</div>
          </div>
        </div>
        <div className="d-flex mb-2">
          <div className="d-flex align-items-center">
            <i className="fa fa-circle text-blue f-s-8 mr-2"></i>
            Desospitalizados
          </div>
          <div className="d-flex align-items-center ml-auto">
            <div className="width-50 text-right pl-2 f-w-600">{props.pacientesPorStatus?.TotalDesospitalizados}</div>
          </div>
        </div>
        <div className="d-flex">
          <div className="d-flex align-items-center">
            <i className="fa fa-circle text-aqua f-s-8 mr-2"></i>
            Intercorrência
          </div>
          <div className="d-flex align-items-center ml-auto">
            <div className="width-50 text-right pl-2 f-w-600">{props.pacientesPorStatus?.TotalIntercorrencias}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = storeState => ({
  report:
    storeState.jhiReports.reportsList && storeState.jhiReports.reportsList['pacientes-hospitalizados']
      ? storeState.jhiReports.reportsList['pacientes-hospitalizados']
      : [],
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps)(ChartPacientesStatus);
