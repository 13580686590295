/* eslint complexity: ["error", 500] */
import axios from 'axios';

import { IPaginationBaseState, IPayloadResult } from 'react-jhipster';

import { IPayload } from 'react-jhipster/src/type/redux-action.type';

import { BASE_API_VERSION_PATH } from 'app/config/constants';

import { cleanEntity, jsonParse } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IPatientContact, defaultValue } from 'app/shared/model/patient-contact.model';

export declare type ICrudPutAction<T> = (data?: T, listFiltersPage?) => IPayload<T> | IPayloadResult<T>;
export declare type ICrudGetAction<T> = (id: string | number, selectFields?: string) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const ACTION_TYPES = {
  FETCH_PATIENTCONTACT_LIST_EXPORT: 'patientContact/FETCH_PATIENTCONTACT_LIST_EXPORT',
  FETCH_PATIENTCONTACT_LIST: 'patientContact/FETCH_PATIENTCONTACT_LIST',
  FETCH_PATIENTCONTACT: 'patientContact/FETCH_PATIENTCONTACT',
  FETCH_PATIENTCONTACT_OFFSET: 'patientContact/FETCH_PATIENTCONTACT_OFFSET',
  CREATE_PATIENTCONTACT: 'patientContact/CREATE_PATIENTCONTACT',
  UPDATE_PATIENTCONTACT: 'patientContact/UPDATE_PATIENTCONTACT',
  CREATE_PATIENTCONTACT_AND_GO_LIST: 'patientContact/CREATE_PATIENTCONTACT_AND_GO_LIST',
  UPDATE_PATIENTCONTACT_AND_GO_LIST: 'patientContact/UPDATE_PATIENTCONTACT_AND_GO_LIST',
  DELETE_PATIENTCONTACT: 'patientContact/DELETE_PATIENTCONTACT',
  RESET: 'patientContact/RESET',
  SHOW_MODAL: 'patientContact/SHOW_MODAL',
};

const initialState = {
  loading: false,
  openModal: {
    list: false,
    view: false,
    detail: false,
  },
  errorMessage: null,
  entities: [] as ReadonlyArray<IPatientContact>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const actionPendind = [];
let actualState = initialState;

export const convertEntityBuffers = entity => ({
  ...entity,
});

export type PatientContactState = Readonly<typeof initialState>;

export interface IPatientContactBaseState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  listCheckedID?: Array<number>;
  isCheckedAll?: boolean;
  urlBack?: string;
  extraFilters?: any;
  baseFilters: any;
  offset: number;
  telephonePrimary?: any;
  telephoneSecondary?: any;
  phonePrimary?: any;
  phoneSecondary?: any;
  email?: any;
  patientId?: any;
  patientStartFilter?: any;
}

export interface IFieldsBase extends IPatientContactBaseState, IPaginationBaseState {}
export interface IPatientContactUpdateState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  urlBack?: string;
  functionEmbebed?: any[];
  fieldsBase: IFieldsBase;
  telephonePrimarySelectValue?: any;
  telephonePrimaryStartSelectOptions?: any;
  telephoneSecondarySelectValue?: any;
  telephoneSecondaryStartSelectOptions?: any;
  phonePrimarySelectValue?: any;
  phonePrimaryStartSelectOptions?: any;
  phoneSecondarySelectValue?: any;
  phoneSecondaryStartSelectOptions?: any;
  emailSelectValue?: any;
  emailStartSelectOptions?: any;

  patientSelectValue?: any;
  patientStartSelectOptions?: any;

  isNew: boolean;
  patientName?: any;

  patientId?: any;
}

// Reducer

export default (state: PatientContactState = initialState, action): PatientContactState => {
  actualState = state;
  if (
    action.type.includes('_PENDING') &&
    !actionPendind.includes(action.type.replace('_PENDING', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_PENDING', ''))
  ) {
    actionPendind.push(action.type.replace('_PENDING', ''));
  }

  if (
    action.type.includes('_FULFILLED') &&
    actionPendind.includes(action.type.replace('_FULFILLED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_FULFILLED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_FULFILLED', '')), 1);
  }

  if (
    action.type.includes('_REJECTED') &&
    actionPendind.includes(action.type.replace('_REJECTED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_REJECTED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_REJECTED', '')), 1);
  }

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PATIENTCONTACT_LIST_EXPORT):
    case REQUEST(ACTION_TYPES.FETCH_PATIENTCONTACT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_PATIENTCONTACT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_PATIENTCONTACT):
    case REQUEST(ACTION_TYPES.UPDATE_PATIENTCONTACT):
    case REQUEST(ACTION_TYPES.CREATE_PATIENTCONTACT_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.UPDATE_PATIENTCONTACT_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.DELETE_PATIENTCONTACT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_PATIENTCONTACT_LIST_EXPORT):
    case FAILURE(ACTION_TYPES.FETCH_PATIENTCONTACT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_PATIENTCONTACT):
    case FAILURE(ACTION_TYPES.CREATE_PATIENTCONTACT):
    case FAILURE(ACTION_TYPES.UPDATE_PATIENTCONTACT):
    case FAILURE(ACTION_TYPES.CREATE_PATIENTCONTACT_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.UPDATE_PATIENTCONTACT_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.DELETE_PATIENTCONTACT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_PATIENTCONTACT_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_PATIENTCONTACT_OFFSET):
      if (action.payload.data && action.payload.data.length > 0) {
        return {
          ...state,
          loading: false,
          entity: convertEntityBuffers(action.payload.data[0]),
        };
      }
      return {
        ...state,
        loading: false,
      };

    case SUCCESS(ACTION_TYPES.FETCH_PATIENTCONTACT):
      return {
        ...state,
        loading: false,
        entity: convertEntityBuffers(action.payload.data),
      };
    case SUCCESS(ACTION_TYPES.CREATE_PATIENTCONTACT):
    case SUCCESS(ACTION_TYPES.UPDATE_PATIENTCONTACT):
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_PATIENTCONTACT_AND_GO_LIST):
    case SUCCESS(ACTION_TYPES.UPDATE_PATIENTCONTACT_AND_GO_LIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_PATIENTCONTACT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };

    case ACTION_TYPES.SHOW_MODAL: {
      const openModal = state.openModal;
      openModal[action.payload.type] = action.payload.show;
      return {
        ...state,
        openModal,
      };
    }
    default:
      return state;
  }
};

const apiUrl = BASE_API_VERSION_PATH + 'patient-contacts';

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

// Actions

// Actions
export type ICrudGetAllActionPatientContact<T> = (
  telephonePrimary?: any,
  telephoneSecondary?: any,
  phonePrimary?: any,
  phoneSecondary?: any,
  email?: any,
  patientId?: any,
  extraFilters?: {},
  page?: number,
  size?: number,
  sort?: string,
  selectFields?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getEntitiesFilter = (filters?, page?, size?, sort?, selectFields = '') => {
  const requestUrl = `${apiUrl}?page=${page >= 0 ? page : 0}&size=${size >= 0 ? size : 20}&sort=${sort ? sort : 'id,asc'}&`;
  const requestFilters = filters.map(v => v.column + '.' + v.operation + '=' + v.value).join('&');
  return {
    type: ACTION_TYPES.FETCH_PATIENTCONTACT_LIST,
    payload: axios.get<IPatientContact>(`${requestUrl}${requestFilters}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getAllEntities = (filters?, sort?, selectFields = '') => {
  if (
    (actualState.totalItems > 0 && actualState.entities.length === actualState.totalItems) ||
    actionPendind.includes(ACTION_TYPES.FETCH_PATIENTCONTACT_LIST)
  ) {
    return {
      type: ACTION_TYPES.FETCH_PATIENTCONTACT_LIST + '_FROM_CACHE',
      payload: new Promise(function (resolve, reject) {
        resolve('done');
      }),
    };
  }

  const idsRequest = filters && typeof filters['ids'] !== 'undefined' && filters['ids'] ? `id.in=${filters['ids'].join(',')}&` : '';
  const telephonePrimaryRequest =
    filters && typeof filters['telephonePrimary'] !== 'undefined' && filters['telephonePrimary']
      ? `telephonePrimary.contains=${filters['telephonePrimary']}&`
      : '';
  const telephoneSecondaryRequest =
    filters && typeof filters['telephoneSecondary'] !== 'undefined' && filters['telephoneSecondary']
      ? `telephoneSecondary.contains=${filters['telephoneSecondary']}&`
      : '';
  const phonePrimaryRequest =
    filters && typeof filters['phonePrimary'] !== 'undefined' && filters['phonePrimary']
      ? `phonePrimary.contains=${filters['phonePrimary']}&`
      : '';
  const phoneSecondaryRequest =
    filters && typeof filters['phoneSecondary'] !== 'undefined' && filters['phoneSecondary']
      ? `phoneSecondary.contains=${filters['phoneSecondary']}&`
      : '';
  const emailRequest = filters && typeof filters['email'] !== 'undefined' && filters['email'] ? `email.contains=${filters['email']}&` : '';
  const patientRequest =
    filters && typeof filters['patientId'] !== 'undefined' && filters['patientId'] ? `patient.id.in=${filters['patientId']}&` : '';

  const requestUrl = `${apiUrl}${`?page=${0}&size=${1000000}&sort=${sort ? sort : 'id,asc'}&`}`;
  return {
    type: ACTION_TYPES.FETCH_PATIENTCONTACT_LIST,
    payload: axios.get<IPatientContact>(
      `${requestUrl}${idsRequest}${telephonePrimaryRequest}${telephoneSecondaryRequest}${phonePrimaryRequest}${phoneSecondaryRequest}${emailRequest}${patientRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntities: ICrudGetAllActionPatientContact<IPatientContact> = (
  telephonePrimary,
  telephoneSecondary,
  phonePrimary,
  phoneSecondary,
  email,
  patientId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const telephonePrimaryRequest = telephonePrimary ? `telephonePrimary.contains=${telephonePrimary}&` : '';
  const telephoneSecondaryRequest = telephoneSecondary ? `telephoneSecondary.contains=${telephoneSecondary}&` : '';
  const phonePrimaryRequest = phonePrimary ? `phonePrimary.contains=${phonePrimary}&` : '';
  const phoneSecondaryRequest = phoneSecondary ? `phoneSecondary.contains=${phoneSecondary}&` : '';
  const emailRequest = email ? `email.contains=${email}&` : '';
  const patientRequest = patientId ? `patient.id.in=${patientId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PATIENTCONTACT_LIST,
    payload: axios.get<IPatientContact>(
      `${requestUrl}${extraFiltersRequest}${telephonePrimaryRequest}${telephoneSecondaryRequest}${phonePrimaryRequest}${phoneSecondaryRequest}${emailRequest}${patientRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntityOffset: any = (
  telephonePrimary,
  telephoneSecondary,
  phonePrimary,
  phoneSecondary,
  email,
  patientId,
  extraFilters,
  offset,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const telephonePrimaryRequest = telephonePrimary ? `telephonePrimary.contains=${telephonePrimary}&` : '';
  const telephoneSecondaryRequest = telephoneSecondary ? `telephoneSecondary.contains=${telephoneSecondary}&` : '';
  const phonePrimaryRequest = phonePrimary ? `phonePrimary.contains=${phonePrimary}&` : '';
  const phoneSecondaryRequest = phoneSecondary ? `phoneSecondary.contains=${phoneSecondary}&` : '';
  const emailRequest = email ? `email.contains=${email}&` : '';
  const patientRequest = patientId ? `patient.id.in=${patientId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${offset}&size=${1}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PATIENTCONTACT_OFFSET,
    payload: axios.get<IPatientContact>(
      `${requestUrl}${extraFiltersRequest}${telephonePrimaryRequest}${telephoneSecondaryRequest}${phonePrimaryRequest}${phoneSecondaryRequest}${emailRequest}${patientRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntity: ICrudGetAction<IPatientContact> = (id, selectFields = '') => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_PATIENTCONTACT,
    payload: axios.get<IPatientContact>(requestUrl, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntitiesExport: ICrudGetAllActionPatientContact<IPatientContact> = (
  telephonePrimary,
  telephoneSecondary,
  phonePrimary,
  phoneSecondary,
  email,
  patientId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const telephonePrimaryRequest = telephonePrimary
    ? Array.isArray(status)
      ? `telephonePrimary.in=${telephonePrimary.join(',')}&`
      : `telephonePrimary.contains=${telephonePrimary}&`
    : '';
  const telephoneSecondaryRequest = telephoneSecondary
    ? Array.isArray(status)
      ? `telephoneSecondary.in=${telephoneSecondary.join(',')}&`
      : `telephoneSecondary.contains=${telephoneSecondary}&`
    : '';
  const phonePrimaryRequest = phonePrimary
    ? Array.isArray(status)
      ? `phonePrimary.in=${phonePrimary.join(',')}&`
      : `phonePrimary.contains=${phonePrimary}&`
    : '';
  const phoneSecondaryRequest = phoneSecondary
    ? Array.isArray(status)
      ? `phoneSecondary.in=${phoneSecondary.join(',')}&`
      : `phoneSecondary.contains=${phoneSecondary}&`
    : '';
  const emailRequest = email ? (Array.isArray(status) ? `email.in=${email.join(',')}&` : `email.contains=${email}&`) : '';
  const patientRequest = patientId ? `patient.id.in=${patientId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PATIENTCONTACT_LIST,
    payload: axios.get<IPatientContact>(
      `${requestUrl}${extraFiltersRequest}${telephonePrimaryRequest}${telephoneSecondaryRequest}${phonePrimaryRequest}${phoneSecondaryRequest}${emailRequest}${patientRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const createEntity: ICrudPutAction<IPatientContact> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.CREATE_PATIENTCONTACT_AND_GO_LIST
        : ACTION_TYPES.CREATE_PATIENTCONTACT,
    payload: axios.post(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const updateEntity: ICrudPutAction<IPatientContact> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.UPDATE_PATIENTCONTACT_AND_GO_LIST
        : ACTION_TYPES.UPDATE_PATIENTCONTACT,
    payload: axios.put(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const deleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_PATIENTCONTACT,
    payload: axios.delete(requestUrl),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const showModal = view => ({
  payload: { type: view, show: true },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const hideModal = view => ({
  payload: { type: view, show: false },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const getUrlBack = (location): string => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  let urlBack = _urlBase.searchParams.get('urlBack') || '';
  urlBack = urlBack.trim();
  urlBack = urlBack.startsWith('/') ? urlBack.slice(1) : urlBack;
  urlBack = urlBack.endsWith('/') ? urlBack.slice(0, -1) : urlBack;
  return urlBack ? '/' + urlBack + '/' : '';
};

export const getPatientContactState = (location): IPatientContactBaseState => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  const extraFilters = jsonParse(_urlBase.searchParams.get('extraFilters') || '{}');
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;

  const telephonePrimary = _urlBase.searchParams.get('telephonePrimary') || '';
  const telephoneSecondary = _urlBase.searchParams.get('telephoneSecondary') || '';
  const phonePrimary = _urlBase.searchParams.get('phonePrimary') || '';
  const phoneSecondary = _urlBase.searchParams.get('phoneSecondary') || '';
  const email = _urlBase.searchParams.get('email') || '';
  const patientId = _urlBase.searchParams.get('patient') || '';
  return {
    baseFilters,
    extraFilters,
    offset,
    telephonePrimary,
    telephoneSecondary,
    phonePrimary,
    phoneSecondary,
    email,
    patientId: patientId ? patientId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
  };
};

export const getEntityFiltersURL = (state, offset = null) => {
  return (
    'baseFilters=' +
    state.baseFilters +
    '&extraFilters=' +
    encodeURI(JSON.stringify(state.extraFilters)) +
    '&page=' +
    state.activePage +
    '&' +
    'size=' +
    state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    state.sort +
    ',' +
    state.order +
    '&' +
    (state.telephonePrimary ? 'telephonePrimary=' + state.telephonePrimary + '&' : '') +
    (state.telephoneSecondary ? 'telephoneSecondary=' + state.telephoneSecondary + '&' : '') +
    (state.phonePrimary ? 'phonePrimary=' + state.phonePrimary + '&' : '') +
    (state.phoneSecondary ? 'phoneSecondary=' + state.phoneSecondary + '&' : '') +
    (state.email ? 'email=' + state.email + '&' : '') +
    (state.patientId ? 'patient=' + state.patientId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    ''
  );
};
