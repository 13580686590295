import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import WhiteLabel from './white-label';

import WhiteLabelDetail from './white-label-detail';

import WhiteLabelUpdate from './white-label-update';

import WhiteLabelDeleteDialog from './white-label-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={WhiteLabelUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={WhiteLabelUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={WhiteLabelDetail} />
      <ErrorBoundaryRoute path={match.path} component={WhiteLabel} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.path}/:id/delete`} component={WhiteLabelDeleteDialog} />
  </>
);

export default Routes;
