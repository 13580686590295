/* eslint-disable no-console */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import axios from 'axios';
import { BASE_API_VERSION_PATH } from 'app/config/constants';

interface IErrorBoundaryProps {
  readonly children: JSX.Element | JSX.Element[];
}

interface IErrorBoundaryState {
  readonly error: any;
  readonly errorInfo: any;
}

class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  readonly state: IErrorBoundaryState = { error: undefined, errorInfo: undefined };

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const { error, errorInfo } = this.state;
    if (errorInfo) {
      const token =
        Math.random().toString(36).substr(2) +
        Math.random().toString(36).substr(2) +
        Math.random().toString(36).substr(2) +
        Math.random().toString(36).substr(2);
      // main.bundle.js//:131375 Uncaught TypeError: Cannot read property
      // const fileErrors = error.stack.split('at');
      // if (fileErrors.length > 1) {
      //   const fileSplit = (fileErrors[1].substring(fileErrors[1].indexOf('http'))).split('.js:');
      // if (fileSplit.length > 1) {
      //   axios.get(fileSplit[0] + '.js').then(v1 => {
      //     const linesErrors = v1.data.split('\n');
      //     const numberStart = (fileSplit[1].split(':')[0]) - 20;
      //     const codeTextLines = linesErrors
      //       .filter((v, i) => numberStart < i && i < numberStart + 40)
      //       .map((v, i) => {
      //         return ((numberStart + 0 + i + ': ') + ((parseInt(i, 10) === 18) ? '--->' : ' ') + v)
      //       })
      //       .join('\n');

      //     axios.post(BASE_API_VERSION_PATH + 'react-errors', {
      //       token,
      //       href: window.location.href,
      //       errorInfo,
      //       error: error.message,
      //       codeTextLines,
      //       stack: error.stack,
      //     });
      //   });
      // } else {
      //     axios.post(BASE_API_VERSION_PATH + 'react-errors', {
      //       token,
      //       href: window.location.href,
      //       errorInfo,
      //       error: error.message,
      //       stack: error.stack,
      //     });
      //   }
      // } else {
      axios.post(BASE_API_VERSION_PATH + 'react-errors', {
        token,
        href: window.location.href,
        errorInfo,
        error: error.message,
        stack: error.stack,
      });
      // }

      const errorDetails = (
        /* process.env.NODE_ENV === 'development' ?  */

        <details className="preserve-space">
          {error && error.toString()}
          <br />
          {errorInfo.componentStack}
        </details>
      ); /* : undefined; */
      return (
        <div className="error-unexpected">
          <div id="notfound">
            <div className="notfound">
              <div className="notfound-500"></div>
              <div className="notfound-500-div1">
                <FontAwesomeIcon className="notfound-500-icon" icon="frown" />
              </div>
              <div className="notfound-500-div2">
                <h1>500</h1>
                <h2> Ops. Parece que está quebrado</h2>
              </div>
              <p>
                {' '}
                Se você continuar a ver este erro, entre em contato com o equipe de desenvolvimento enviando o seguinte codigo:{' '}
                <b>{token}</b>
              </p>
              <a className="btn btn-default" href={window.location.href}>
                Recarregar Pagina
              </a>
              <a className="btn btn-primary" href="/">
                Back to homepage
              </a>
            </div>
          </div>
          {errorDetails}
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
