import React from 'react';

export interface IClComRulerProps {
  baseState?: any;
  baseProps?: any;
  setState?: any;
  entityBase?: any;
}

const ClComRulerComponentCustomize = (props: IClComRulerProps) => {
  return <div className="">{props.entityBase && props.entityBase.levelGroup ? props.entityBase.levelGroup.name : ''}</div>;
};

export default ClComRulerComponentCustomize;
