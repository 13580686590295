/* eslint complexity: ["error", 100] */
import axios from 'axios';
import { ICrudDeleteAction } from 'react-jhipster';

import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IAuditEntity } from 'app/shared/model/audit-entity.model';

import { AuditEntityState } from './audit-entity.reducer';

export const ACTION_TYPES_EXTENDED = {
  AUDITENTITY_DUMMY: 'auditEntity/AUDITENTITY_DUMMY',
};

export const initialStateExtended = {
  dummyReducerState: '',
};

export const SwitchExtended = (state: AuditEntityState, action): AuditEntityState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES_EXTENDED.AUDITENTITY_DUMMY):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ACTION_TYPES_EXTENDED.AUDITENTITY_DUMMY):
      return {
        ...state,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES_EXTENDED.AUDITENTITY_DUMMY):
      return {
        ...state,
        loading: false,
        dummyReducerState: action.payload.data.dummy,
      };
    default:
      return null;
  }
  return null;
};

const apiUrl = 'api/audit-entities';
export const dummyEntity: ICrudDeleteAction<IAuditEntity> = id => async dispatch => {
  const requestUrl = `${apiUrl}/dummy-route`;
  const result = await dispatch({
    type: ACTION_TYPES_EXTENDED.AUDITENTITY_DUMMY,
    payload: axios.delete(requestUrl),
  });
  return result;
};
