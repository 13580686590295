import React from 'react';
import axios from 'axios';
import { AvGroup } from 'availity-reactstrap-validation';
import { Row, Col, Label } from 'reactstrap';
import Select from 'react-select';
import { Translate, translate } from 'app/config/translate-component';
import { BASE_API_VERSION_PATH } from 'app/config/constants';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);

export interface IQuoteProps {
  baseState?: any;
  baseProps?: any;
  setState?: any;
  entityBase?: any;
}

const QuoteComponentCustomize = (props: IQuoteProps) => {
  let listStatus = [];
  if (['APROVADO', 'INATIVOREPROVADOPRECO', 'INATIVOCONTROLEINTERNO'].includes(props.baseState.statusSelectValue)) {
    listStatus = [
      { value: 'APROVADO', label: translate('generadorApp.QuoteStatus.APROVADO') },
      { value: 'INATIVOREPROVADOPRECO', label: translate('generadorApp.QuoteStatus.INATIVOREPROVADOPRECO') },
      { value: 'INATIVOCONTROLEINTERNO', label: translate('generadorApp.QuoteStatus.INATIVOCONTROLEINTERNO') },
    ];
  } else {
    listStatus = [
      { value: 'ORCAMENTOABERTO', label: translate('generadorApp.QuoteStatus.ORCAMENTOABERTO') },
      { value: 'AGUARDANDOPRECIFICACAO', label: translate('generadorApp.QuoteStatus.AGUARDANDOPRECIFICACAO') },
      { value: 'ORCAMENTOPRECIFICADO', label: translate('generadorApp.QuoteStatus.ORCAMENTOPRECIFICADO') },
      { value: 'AGUARDANDOAPROVACAO', label: translate('generadorApp.QuoteStatus.AGUARDANDOAPROVACAO') },
      { value: 'PLANTAOAPROVADO', label: translate('generadorApp.QuoteStatus.PLANTAOAPROVADO') },
    ];
  }

  return (
    <AvGroup>
      <Row>
        <Col md="12">
          <Label className="mt-2 label-single-line" id="statusLabel" for="quote-status">
            <Translate contentKey="generadorApp.quote.status">Status</Translate>
          </Label>
        </Col>
        <Col md="12">
          <Select
            isDisabled={props.baseState.statusSelectValue === 'CANCELADO'}
            id="quote-status"
            className={'css-select-control'}
            value={
              props.baseState.statusSelectValue
                ? {
                    value: props.baseState.statusSelectValue,
                    label: translate('generadorApp.QuoteStatus.' + props.baseState.statusSelectValue),
                  }
                : { value: '', label: translate('generadorApp.quote.status') }
            }
            options={listStatus}
            onChange={(options: any) => {
              MySwal.fire({
                title: <p>Status do Orçamento</p>,
                html: <p>Deseja atualizar o status do orçamento</p>,
                showConfirmButton: true,
                showCancelButton: true,
              }).then(response => {
                if (response.isConfirmed) {
                  axios.put(BASE_API_VERSION_PATH + 'quotes', { id: props.entityBase.id, status: options['value'] });
                  return props.setState({ statusSelectValue: options['value'] });
                }
              });
            }}
            name="status"
          />
        </Col>
      </Row>
    </AvGroup>
  );
};

const mapStateToProps = storeState => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default QuoteComponentCustomize;
