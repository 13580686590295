import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Patient from './patient';

import PatientDetail from './patient-detail';

import PatientUpdate from './extended/patient-update';

import PatientFile from '../patient-file/index';

import PatientStatusHistory from '../patient-status-history/index';

import PatientContact from '../patient-contact/index';

import PatientCid from '../patient-cid/index';

import PatientAllergy from '../patient-allergy/index';

import PatientResponsible from '../patient-responsible/index';

import PatientDoctorPrescription from '../patient-doctor-prescription/index';

import PatientNursingPrescription from '../patient-nursing-prescription/index';

import PatientClinicalIndicator from '../patient-clinical-indicator/index';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute path={`${match.path}/:idPatient/patient-file`} component={PatientFile} />

      <ErrorBoundaryRoute path={`${match.path}/:idPatient/patient-status-history`} component={PatientStatusHistory} />

      <ErrorBoundaryRoute path={`${match.path}/:idPatient/patient-contact`} component={PatientContact} />

      <ErrorBoundaryRoute path={`${match.path}/:idPatient/patient-cid`} component={PatientCid} />

      <ErrorBoundaryRoute path={`${match.path}/:idPatient/patient-allergy`} component={PatientAllergy} />

      <ErrorBoundaryRoute path={`${match.path}/:idPatient/patient-responsible`} component={PatientResponsible} />

      <ErrorBoundaryRoute path={`${match.path}/:idPatient/patient-doctor-prescription`} component={PatientDoctorPrescription} />

      <ErrorBoundaryRoute path={`${match.path}/:idPatient/patient-nursing-prescription`} component={PatientNursingPrescription} />

      <ErrorBoundaryRoute path={`${match.path}/:idPatient/patient-clinical-indicator`} component={PatientClinicalIndicator} />

      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={PatientUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={PatientUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={PatientDetail} />
      <ErrorBoundaryRoute path={match.path} component={Patient} />
    </Switch>

    <Switch>
      {/* <ErrorBoundaryRoute path={`${match.path}/:idPatient/patient-file`} component={PatientFile} /> */}

      {/* <ErrorBoundaryRoute path={`${match.path}/:idPatient/patient-status-history`} component={PatientStatusHistory} /> */}

      {/* <ErrorBoundaryRoute path={`${match.path}/:idPatient/patient-contact`} component={PatientContact} /> */}

      {/* <ErrorBoundaryRoute path={`${match.path}/:idPatient/patient-cid`} component={PatientCid} /> */}

      {/* <ErrorBoundaryRoute path={`${match.path}/:idPatient/patient-allergy`} component={PatientAllergy} /> */}

      {/* <ErrorBoundaryRoute path={`${match.path}/:idPatient/patient-responsible`} component={PatientResponsible} /> */}

      {/* <ErrorBoundaryRoute path={`${match.path}/:idPatient/patient-doctor-prescription`} component={PatientDoctorPrescription} /> */}

      {/* <ErrorBoundaryRoute path={`${match.path}/:idPatient/patient-nursing-prescription`} component={PatientNursingPrescription} /> */}

      {/* <ErrorBoundaryRoute path={`${match.path}/:idPatient/patient-clinical-indicator`} component={PatientClinicalIndicator} /> */}
    </Switch>
  </>
);

export default Routes;
