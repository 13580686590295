/* eslint complexity: ["error", 300] */
import React from 'react';
import { connect } from 'react-redux';

import { Link, RouteComponentProps } from 'react-router-dom';

import 'react-quill/dist/quill.snow.css';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Button, Row, Col, Label } from 'reactstrap';
import { translate } from 'app/config/translate-component';
import { Translate, setFileData } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import {
  IAuditEntityUpdateState,
  getEntity,
  getAuditEntityState,
  updateEntity,
  createEntity,
  setBlob,
  reset,
} from './audit-entity.reducer';

export interface IAuditEntityUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class AuditEntityUpdate extends React.Component<IAuditEntityUpdateProps, IAuditEntityUpdateState> {
  entityIdFileInput: React.RefObject<HTMLInputElement>;

  entityTypeFileInput: React.RefObject<HTMLInputElement>;

  actionFileInput: React.RefObject<HTMLInputElement>;

  entityValueFileInput: React.RefObject<HTMLInputElement>;

  commitVersionFileInput: React.RefObject<HTMLInputElement>;
  constructor(props: Readonly<IAuditEntityUpdateProps>) {
    super(props);

    this.entityValueFileInput = React.createRef();

    this.state = {
      entityIdSelectValue: null,
      entityTypeSelectValue: null,
      actionSelectValue: null,
      entityValueSelectValue: null,
      commitVersionSelectValue: null,
      fieldsBase: {
        ...getAuditEntityState(this.props.location),
      },
      isNew: !this.props.match.params || !this.props.match.params['id'],
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.updateSuccess !== this.props.updateSuccess && prevProps.updateSuccess) {
      this.handleClose();
    }

    if (
      (prevProps.auditEntityEntity && prevProps.auditEntityEntity.id !== this.props.auditEntityEntity.id) ||
      (this.props.auditEntityEntity.entityId && this.state.entityIdSelectValue === null)
    ) {
      this.setState({ entityIdSelectValue: this.props.auditEntityEntity.entityId });
    }
    if (
      (prevProps.auditEntityEntity && prevProps.auditEntityEntity.id !== this.props.auditEntityEntity.id) ||
      (this.props.auditEntityEntity.entityType && this.state.entityTypeSelectValue === null)
    ) {
      this.setState({ entityTypeSelectValue: this.props.auditEntityEntity.entityType });
    }
    if (
      (prevProps.auditEntityEntity && prevProps.auditEntityEntity.id !== this.props.auditEntityEntity.id) ||
      (this.props.auditEntityEntity.action && this.state.actionSelectValue === null)
    ) {
      this.setState({ actionSelectValue: this.props.auditEntityEntity.action });
    }
    if (
      (prevProps.auditEntityEntity && prevProps.auditEntityEntity.id !== this.props.auditEntityEntity.id) ||
      (this.props.auditEntityEntity.entityValue && this.state.entityValueSelectValue === null)
    ) {
      this.setState({ entityValueSelectValue: this.props.auditEntityEntity.entityValue });
    }
    if (
      (prevProps.auditEntityEntity && prevProps.auditEntityEntity.id !== this.props.auditEntityEntity.id) ||
      (this.props.auditEntityEntity.commitVersion && this.state.commitVersionSelectValue === null)
    ) {
      this.setState({ commitVersionSelectValue: this.props.auditEntityEntity.commitVersion });
    }
  }

  UNSAFE_componentWillMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params['id']);
    }
  }

  onBlobChange = (isAnImage, name, fileInput) => event => {
    const fileName = event.target.files[0].name;
    setFileData(event, (contentType, data) => this.props.setBlob(name, data, contentType, fileName), isAnImage);
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined);
  };
  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = '_back=1' + (offset !== null ? '&offset=' + offset : '');
    Object.keys(fieldsBase).map(key => {
      url += '&' + key + '=' + fieldsBase[key];
    });
    return url;
  };
  saveEntity = (event: any, errors: any, values: any) => {
    if (errors.length === 0) {
      const { auditEntityEntity } = this.props;
      const entity = {
        ...auditEntityEntity,
        ...values,

        entityId: this.state.entityIdSelectValue,
        entityType: this.state.entityTypeSelectValue,
        action: this.state.actionSelectValue,
        entityValue: this.state.entityValueSelectValue,
        commitVersion: this.state.commitVersionSelectValue,
      };

      const { entityId, entityType, action, entityValue, commitVersion } = this.state.fieldsBase;

      if (this.state.isNew) {
        this.props.createEntity(entity, [entityId, entityType, action, entityValue, commitVersion]);
      } else {
        this.props.updateEntity(entity, [entityId, entityType, action, entityValue, commitVersion]);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/audit-entity?' + this.getFiltersURL());
  };

  renderHeader() {
    const { auditEntityEntity, loading, updating } = this.props;
    const { isNew } = this.state;

    const { entityValue } = auditEntityEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.auditEntity.home.createLabel">Create a AuditEntity</Translate>
            ) : (
              <Translate contentKey="generadorApp.auditEntity.home.editLabel">Edit a AuditEntity</Translate>
            )}
          </span>

          <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.auditEntity.btnSave">Save</Translate>
          </Button>
          <Button
            tag={Link}
            id="cancel-save"
            to={'/audit-entity?' + this.getFiltersURL()}
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.auditEntity.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={'/audit-entity'}>Audit Entities</Link>
          </li>
          <li className="breadcrumb-item active">Audit Entities edit</li>
        </ol>
      </>
    );
  }
  renderBody() {
    const { auditEntityEntity, loading, updating } = this.props;
    const { isNew } = this.state;

    const { entityValue } = auditEntityEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="audit-entity-id">
                  <Translate contentKey="global.field.id">ID</Translate>
                </Label>
                </Col> */}
                      <Col md="12">
                        <AvInput id="audit-entity-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'entityId' ? (
                    <Col md="12">
                      <AvGroup>
                        <Row>
                          <Col md="3">
                            <Label className="mt-2 label-single-line" id="entityIdLabel" for="audit-entity-entityId">
                              <Translate contentKey="generadorApp.auditEntity.entityId">Entity Id</Translate>
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvField
                              onChange={evt => this.setState({ entityIdSelectValue: evt.target.value })}
                              id="audit-entity-entityId"
                              type="string"
                              className="form-control"
                              name="entityId"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ entityIdSelectValue: evt.target.value })}
                      type="hidden"
                      name="entityId"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'entityType' ? (
                    <Col md="12">
                      <AvGroup>
                        <Row>
                          <Col md="3">
                            <Label className="mt-2 label-single-line" id="entityTypeLabel" for="audit-entity-entityType">
                              <Translate contentKey="generadorApp.auditEntity.entityType">Entity Type</Translate>
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvInput
                              onChange={evt => this.setState({ entityTypeSelectValue: evt.target.value })}
                              validate={{ maxLength: { value: 255, errorMessage: translate('entity.validation.maxlength', { max: 255 }) } }}
                              id="audit-entity-entityType"
                              type="text"
                              name="entityType"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ entityTypeSelectValue: evt.target.value })}
                      type="hidden"
                      name="entityType"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'action' ? (
                    <Col md="12">
                      <AvGroup>
                        <Row>
                          <Col md="3">
                            <Label className="mt-2 label-single-line" id="actionLabel" for="audit-entity-action">
                              <Translate contentKey="generadorApp.auditEntity.action">Action</Translate>
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvInput
                              onChange={evt => this.setState({ actionSelectValue: evt.target.value })}
                              validate={{ maxLength: { value: 255, errorMessage: translate('entity.validation.maxlength', { max: 255 }) } }}
                              id="audit-entity-action"
                              type="text"
                              name="action"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ actionSelectValue: evt.target.value })}
                      type="hidden"
                      name="action"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'entityValue' ? (
                    <Col md="12">
                      <AvGroup>
                        <Row>
                          <Col md="3">
                            <Label className="mt-2 label-single-line" id="entityValueLabel" for="audit-entity-entityValue">
                              <Translate contentKey="generadorApp.auditEntity.entityValue">Entity Value</Translate>
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvInput
                              onChange={evt => this.setState({ entityValueSelectValue: evt.target.value })}
                              validate={{}}
                              id="audit-entity-entityValue"
                              type="textarea"
                              name="entityValue"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ entityValueSelectValue: evt.target.value })}
                      type="hidden"
                      name="entityValue"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'commitVersion' ? (
                    <Col md="12">
                      <AvGroup>
                        <Row>
                          <Col md="3">
                            <Label className="mt-2 label-single-line" id="commitVersionLabel" for="audit-entity-commitVersion">
                              <Translate contentKey="generadorApp.auditEntity.commitVersion">Commit Version</Translate>
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvField
                              onChange={evt => this.setState({ commitVersionSelectValue: evt.target.value })}
                              id="audit-entity-commitVersion"
                              type="string"
                              className="form-control"
                              name="commitVersion"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ commitVersionSelectValue: evt.target.value })}
                      type="hidden"
                      name="commitVersion"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}{' '}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { auditEntityEntity, loading, updating } = this.props;
    const { isNew } = this.state;

    const { entityValue } = auditEntityEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...auditEntityEntity,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel>
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToProps = (storeState: IRootState) => ({
  auditEntityEntity: storeState.auditEntity.entity,
  loading: storeState.auditEntity.loading,
  updating: storeState.auditEntity.updating,
  updateSuccess: storeState.auditEntity.updateSuccess,
});

export const mapDispatchToProps = {
  getEntity,
  updateEntity,
  setBlob,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AuditEntityUpdate);
