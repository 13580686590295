/* eslint complexity: ["error", 500] */
import React from 'react';
import { connect } from 'react-redux';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import {
  PatientNursingPrescriptionUpdate,
  mapStateToProps,
  mapDispatchToProps,
  IPatientNursingPrescriptionUpdateProps,
} from '../patient-nursing-prescription-update';
import { IRootState } from 'app/shared/reducers';

import { Button, Row, Col, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { translate, Translate } from 'app/config/translate-component';
import { getEntity as getPatient, getEntityFiltersURL } from 'app/entities/patient/patient.reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { getReport } from 'app/shared/reports/jhi-reports.reducer';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';

import {
  mapDispatchToPropsExtended as mapDispatchToPropsPatNrPrescProcediment,
  PatNrPrescProcedimentExtended as PatNrPrescProcediment,
} from 'app/entities/pat-nr-presc-procediment/extended/pat-nr-presc-procediment';

import SelectAsync from 'react-select/async';
import moment, { Moment } from 'moment';
import { Status } from 'app/shared/model/enumerations/status.model';
import { NursingReport } from 'app/entities/patient-doctor-prescription/report/nursing';
import Axios from 'axios';
export interface IUpdateExtendedState {
  showModalReport?: boolean;
  dataInicial?: Moment;
  dataFinal?: Moment;
  infos:any
}

export class PatientNursingPrescriptionExtendedUpdate extends PatientNursingPrescriptionUpdate {
  constructor(props: IPatientNursingPrescriptionUpdateProps) {
    super(props);
    this.state = {
      ...this.state,
      dataInicial: moment().startOf('month'),
      dataFinal: moment().endOf('month'),
      infos:null
    };
  }
  renderHeader() {
    const { updating } = this.props;
    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.patientNursingPrescription.home.createLabel">
                Create a PatientNursingPrescription
              </Translate>
            ) : (
              <Translate contentKey="generadorApp.patientNursingPrescription.home.editLabel">Edit a PatientNursingPrescription</Translate>
            )}
          </span>

          <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.patientNursingPrescription.btnSave">Save</Translate>
          </Button>
          <Button
            className={'float-right'}
            color="dark"
            onClick={() => {
              new Promise(resolve => {
                resolve(
                  this.props.getReport('nursing-prescription', {
                    id: this.props.patientNursingPrescriptionEntity.id,
                    // initialDate: "'" + this.state.dataInicial.format('YYYY-MM-DD') + "'",
                    // finalDate: "'" + this.state.dataFinal.format('YYYY-MM-DD') + "'",
                    initialDate: "'" + moment().startOf('month').format('YYYY-MM-DD') + "'",
                    finalDate: "'" + moment().endOf('month').format('YYYY-MM-DD') + "'",
                  })
                );
              }).then(resultEntity => {
                if (resultEntity.value.data.result.length === 0) {
                  toast.error(translate('generadorApp.prescription.emptyProcediment'));
                } else {
                  this.setState({
                    showModalReport: true,
                  });
                }
              });
            }}
          >
            <FontAwesomeIcon icon="file-pdf" />
            &nbsp; Visualizar PDF
          </Button>

          {!this.state.isNew ? (
            <>
              <Button
                color="primary"
                id="next-entity"
                disabled={updating}
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.observations,
                    this.state.fieldsBase.technicianId,
                    this.state.fieldsBase.patientId,
                    this.state.fieldsBase.nrDiagnosticId,
                    this.state.fieldsBase.patNrPrescProcedimentId,
                    this.state.fieldsBase.offset * 1 + 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 + 1 },
                    });
                    this.props.history.push(
                      '/patient-nursing-prescription/' +
                        myresult.value.data[0]['id'] +
                        '/edit?' +
                        this.getFiltersURL(this.state.fieldsBase.offset * 1 + 1)
                    );
                  } else {
                    toast.error(
                      translate('generadorApp.last.item', { fieldName: translate('generadorApp.patientNursingPrescription.detail.title') })
                    );
                  }
                }}
                className="float-right jh-create-entity"
              >
                {'>>'}
              </Button>

              <Button
                color="primary"
                id="prev-entity"
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.observations,
                    this.state.fieldsBase.technicianId,
                    this.state.fieldsBase.patientId,
                    this.state.fieldsBase.nrDiagnosticId,
                    this.state.fieldsBase.patNrPrescProcedimentId,
                    this.state.fieldsBase.offset * 1 - 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 - 1 },
                    });
                    this.props.history.push(
                      '/patient-nursing-prescription/' +
                        myresult.value.data[0]['id'] +
                        '/edit?' +
                        this.getFiltersURL(this.state.fieldsBase.offset * 1 - 1)
                    );
                  } else {
                    toast.error(
                      translate('generadorApp.first.item', { fieldName: translate('generadorApp.patientNursingPrescription.detail.title') })
                    );
                  }
                }}
                disabled={updating}
                className="float-right jh-create-entity"
              >
                {'<<'}
              </Button>
            </>
          ) : (
            <> </>
          )}

          <Button
            tag={Link}
            id="cancel-save"
            to={'/patient?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.patientNursingPrescription.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/patient?' + getEntityFiltersURL(this.state.fieldsBase)}>
              <Translate contentKey="generadorApp.patient.home.title">Patients</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            {this.state.patientSelectValue ? this.state.patientSelectValue.label : this.props.match.params['idPatient']}
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.patientNursingPrescription.detail.title">Patient nursing prescription edit</Translate>
          </li>
        </ol>
      </>
    );
  }

  UNSAFE_componentWillMount() {
    super.UNSAFE_componentWillMount();
    this.props.getPatient(parseInt(this.props.match.params['idPatient'], 10));
  }

  renderBody() {
    const patientAllergy = this.props.patientEntity.patientAllergy && this.props.patientEntity.patientAllergy.map(v => v.id);
    const patientCid = this.props.patientEntity.patientCid && this.props.patientEntity.patientCid.map(v => v.id);

    const { patientNursingPrescriptionEntity, loading } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const fullEntityEmbebed = this.getFullEntityFromState(patientNursingPrescriptionEntity);

    const mapDispatchToPropsPatNrPrescProcedimentList: any = {};
    Object.keys(mapDispatchToPropsPatNrPrescProcediment).map(v => {
      mapDispatchToPropsPatNrPrescProcedimentList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'PatNrPrescProcediment')) {
          this.setState(
            { functionEmbebed: [...this.state.functionEmbebed, v + 'PatNrPrescProcediment'] },
            this.props[v + 'PatNrPrescProcediment']
          );
        }
      };
    });

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="patient-nursing-prescription-id">
                  <Translate contentKey="generadorApp.patientNursingPrescription.field_id">ID</Translate>
                </Label>
                </Col> */}
                      <Col md="12">
                        <AvInput id="patient-nursing-prescription-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  <Col md="4">
                    <AvGroup>
                      <Label className="mt-2" id="technicalManagerNurseLabel" for="patient-doctor-prescription-technicalManagerNurse">
                        <Translate contentKey="generadorApp.patient.detail.title">Edit a PatientNursingPrescription</Translate>
                      </Label>
                      <AvInput
                        value={this.props.patientEntity ? this.props.patientEntity.name : ''}
                        id="patient-doctor-prescription-technicalManagerNurse"
                        type="text"
                        name="technicalManagerNurse"
                        disabled
                      />
                    </AvGroup>
                  </Col>
                  <Col md="4">
                    <AvGroup>
                      <Label className="mt-2" id="technicalManagerNurseLabel" for="patient-doctor-prescription-technicalManagerNurse">
                        <Translate contentKey="generadorApp.client.detail.title">Edit a PatientNursingPrescription</Translate>
                      </Label>
                      <AvInput
                        value={
                          this.props.patientEntity && this.props.patientEntity.client ? this.props.patientEntity.client.fantasyName : ''
                        }
                        id="patient-doctor-prescription-technicalManagerNurse"
                        type="text"
                        name="technicalManagerNurse"
                        disabled
                      />
                    </AvGroup>
                  </Col>
                  <Col md="4">
                    <AvGroup>
                      <Label className="mt-2" id="technicalManagerNurseLabel" for="patient-doctor-prescription-technicalManagerNurse">
                        <Translate contentKey="generadorApp.patient.birthDate">Birth Date</Translate>
                      </Label>
                      <AvInput
                        value={this.props.patientEntity ? moment(this.props.patientEntity.birthDate).format('DD/MM/YYYY') : ''}
                        id="patient-doctor-prescription-technicalManagerNurse"
                        type="text"
                        name="technicalManagerNurse"
                        disabled
                      />
                    </AvGroup>
                  </Col>
                  {baseFilters !== 'observations' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label
                              className="mt-2 label-single-line"
                              id="observationsLabel"
                              for="patient-nursing-prescription-observations"
                            >
                              <Translate contentKey="generadorApp.patientNursingPrescription.observations">Observations</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ observationsSelectValue: evt.target.value })}
                              value={this.state.observationsSelectValue}
                              id="patient-nursing-prescription-observations"
                              type="text"
                              name="observations"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ observationsSelectValue: evt.target.value })}
                      type="hidden"
                      name="observations"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'technician' ? (
                    <Col md="6">
                      <AvGroup>
                        <Label className="mt-2" id="technicalManagerNurseLabel" for="patient-doctor-prescription-technicalManagerNurse">
                          <Translate contentKey="generadorApp.patientDoctorPrescription.technicalManagerNurse">Technical Manager</Translate>
                        </Label>
                        <AvInput
                          value={this.props.userAccount.whiteLabel ? this.props.userAccount.whiteLabel.technicalManagerNurse : ''}
                          id="patient-doctor-prescription-technicalManagerNurse"
                          type="text"
                          name="technicalManagerNurse"
                          disabled
                        />
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput type="hidden" name="technician" value={this.state.fieldsBase[baseFilters + 'Id']} />
                  )}
                  {baseFilters !== 'nrDiagnostic' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="patient-nursing-prescription-nrDiagnostic">
                              <Translate contentKey="generadorApp.patientNursingPrescription.nrDiagnostic">Nr Diagnostic</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              isMulti
                              id="patient-nursing-prescription-nrDiagnostic"
                              name={'nrDiagnostic'}
                              className={'css-select-control'}
                              data-type-rel="many-to-many-owner-side"
                              value={this.state.nrDiagnosticSelectValue}
                              onChange={options => this.setState({ nrDiagnosticSelectValue: options })}
                              defaultOptions={this.state.nrDiagnosticStartSelectOptions ? this.state.nrDiagnosticStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.nrDiagnosticStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.nrDiagnosticStartSelectOptions === undefined) {
                                  const result = await getListAxios('pat-nr-prescription-diagnostics', {}, 0, 100, 'name,asc', 'name');
                                  this.setState({
                                    nrDiagnosticStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'pat-nr-prescription-diagnostics',
                                  { 'name.contains': inputValue },
                                  0,
                                  100,
                                  'name,asc',
                                  'name'
                                );
                                callback(
                                  result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput type="hidden" name="nrDiagnostic" value={this.state.fieldsBase[baseFilters + 'Id']} />
                  )}
                  <Col Col md={12}>
                    <Row className="justify-content-center">
                      <Col md={11}>
                        <Row className="justify-content-center">
                          <Col md={6}>
                            <p>
                              <Translate contentKey="generadorApp.patientAllergy.home.title">patientAllergy</Translate>
                            </p>
                            <ul>
                              {this.props.patientEntity.patientAllergy &&
                                this.props.patientEntity.patientAllergy
                                  .filter(v => patientAllergy && patientAllergy.includes(v.id) && !v.deletedAt)
                                  .map((v1, i1) => <li key={i1}>{v1.name}</li>)}
                            </ul>
                          </Col>
                          <Col md={6}>
                            <p>
                              <Translate contentKey="generadorApp.patientCid.home.title">PatientCid</Translate>
                            </p>
                            <ul>
                              {this.props.patientEntity.patientCid &&
                                this.props.patientEntity.patientCid
                                  .filter(v => patientCid && patientCid.includes(v.id) && !v.deletedAt && v.status === Status.ATIVO)
                                  .map((v1, i1) => (
                                    <li key={i1}>
                                      <span>
                                        {
                                          <>
                                            <b>{v1.cid.code}</b> {v1.cid.name}
                                          </>
                                        }
                                      </span>
                                    </li>
                                  ))}
                            </ul>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  {baseFilters !== 'patNrPrescProcediment' ? (
                    <Col md="12">
                      <div className="entityEmbebedList">
                        <PatNrPrescProcediment
                          entityEmbebedPai={patientNursingPrescriptionEntity}
                          history={this.props.history}
                          location={{
                            pathname: `/patient-nursing-prescription/${this.props.match.params['id']}/pat-nr-presc-procediment/`,
                            search: '',
                            hash: '',
                            state: undefined,
                            key: 'q2tbhd',
                          }}
                          match={{
                            path: `/patient-nursing-prescription/:idPatientNursingPrescription/pat-nr-presc-procediment/:id`,
                            url: `/patient-nursing-prescription/${this.props.match.params['id']}/pat-nr-presc-procediment/`,
                            isExact: true,
                            params: {
                              idPatientNursingPrescription: this.props.match.params['id'],
                              url: `/patient-nursing-prescription/${this.props.match.params['id']}/pat-nr-presc-procediment/`,
                            },
                          }}
                          dataInicial={this.state.dataInicial}
                          dataFinal={this.state.dataFinal}
                          setDataInicial={v => this.setState({ dataInicial: v })}
                          setDataFinal={v => this.setState({ dataFinal: v })}
                          {...this.props.mapStateToPropsPatNrPrescProcediment}
                          {...mapDispatchToPropsPatNrPrescProcedimentList}
                          resetPatientNursingPrescription={this.props.reset}
                          getPatientNursingPrescription={this.props.getEntity}
                          getEntities={this.patNrPrescProcedimentGetEntities}
                          updateEntity={this.patNrPrescProcedimentUpdateEntity}
                          getEntity={this.patNrPrescProcedimentGetEntity}
                          createEntity={this.patNrPrescProcedimentCreateEntity}
                          deleteEntity={this.patNrPrescProcedimentDeleteEntity}
                          reset={this.patNrPrescProcedimentReset}
                          patNrPrescProcedimentList={patientNursingPrescriptionEntity.patNrPrescProcediment}
                          totalItems={this.props.patNrPrescProcedimentListEmbed ? this.props.patNrPrescProcedimentListEmbed.length : 0}
                          loading={this.props.loading}
                          patNrPrescProcedimentEntity={this.props.patNrPrescProcedimentSelected}
                        />
                      </div>
                    </Col>
                  ) : (
                    <AvInput type="hidden" name="patNrPrescProcediment" value={this.state.fieldsBase[baseFilters + 'Id']} />
                  )}{' '}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const {
      patientNursingPrescriptionEntity,
      patNrPrescProcediments,
      patNrPrescriptionDiagnostics,
      patients,
      professionals,
      loading,
      updating,
    } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        {this.renderModal()}
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...patientNursingPrescriptionEntity,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel>
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }

  renderModal() {

    // const numCalls = 5; // Número máximo de chamadas

    // const getObs = () => {
    //   Axios.get(`${"api/patient-doctor-prescriptions"}/${this.props.report[0].cod}`)
    //     .then((res) => {
    //       // const data = res.data; // Armazena o resultado da requisição na variável "data"
    //       // this.setState({ infos: data }); // Atualiza o estado com o resultado da requisição
    //       console.log(res)
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // };
  
    // // Chama a função getObs 5 vezes usando um loop for
    // for (let i = 0; i < numCalls; i++) {
    //   getObs();
    // }

    if (!this.props.report || this.props.report.length === 0 || this.state.showModalReport !== true) return <> </>;
    return (
      <Modal size="xl" isOpen toggle={() => this.setState({ showModalReport: false })}>
        <ModalBody id="generadorApp.quote.delete.question">
          {/* <NursingReport data={this.props.report} dataInicial={this.state.dataInicial.toDate()} dataFinal={this.state.dataFinal.toDate()} /> */}
          <NursingReport
            data={this.props.report}
            dataInicial={moment().startOf('month').toDate()}
            dataFinal={moment().endOf('month').toDate()}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => this.setState({ showModalReport: false })}>
            <FontAwesomeIcon icon="ban" />
            &nbsp;
            <Translate contentKey="generadorApp.quote.btnCancel">Close</Translate>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
    listProcediment: storeState.patDrPrescrProcd.entities,
    patientEntity: storeState.patient.entity,
    userAccount: storeState.authentication.account,
    report:
      storeState.jhiReports.reportsList && storeState.jhiReports.reportsList['nursing-prescription']
        ? storeState.jhiReports.reportsList['nursing-prescription']
        : [],
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
  getPatient,
  getReport,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;
export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(PatientNursingPrescriptionExtendedUpdate);
