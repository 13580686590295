/* eslint complexity: ["error", 500] */
/* eslint no-empty: off */
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

import { Link, RouteComponentProps } from 'react-router-dom';

import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Col, Label } from 'reactstrap';
import { setFileData } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import {
  IClientGlosaUpdateState as IBaseUpdateState,
  getEntity,
  getClientGlosaState,
  getUrlBack,
  updateEntity,
  createEntity,
  getEntityFiltersURL,
  getEntityOffset,
  setBlob,
  reset,
} from './client-glosa.reducer';

import { selectFieldsList } from './extended/extendSelectFields';

import QuoteComponent from './components/QuoteComponent';
import QuoteItems from './components/QuoteItems';

import { StatePropsExtended, DispatchPropsExtended, IUpdateExtendedState } from './extended/client-glosa-update';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

registerLocale('pt', pt);

export interface IClientGlosaUpdateProps
  extends StateProps,
    DispatchProps,
    StatePropsExtended,
    DispatchPropsExtended,
    RouteComponentProps<{ url: string }> {}
export interface IClientGlosaUpdateState extends IBaseUpdateState, IUpdateExtendedState {}

export class ClientGlosaUpdate extends React.Component<IClientGlosaUpdateProps, IClientGlosaUpdateState> {
  typeGlosaFileInput: any;

  statusFileInput: any;

  observationFileInput: any;
  constructor(props: Readonly<IClientGlosaUpdateProps>) {
    super(props);

    this.observationFileInput = React.createRef();

    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      functionEmbebed: [],

      clientGlosaItemSelectValue: null,

      quoteSelectValue: null,

      typeGlosaSelectValue: null,
      statusSelectValue: null,
      observationSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getClientGlosaState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
      quoteId: null,

      isNew: !this.props.match.params || !this.props.match.params['id'],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateSuccess !== this.props.updateSuccess && prevProps.updateSuccess) {
      this.handleClose();
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.clientGlosaEntity && prevProps.clientGlosaEntity.id !== this.props.clientGlosaEntity.id) ||
      (this.props.clientGlosaItems.length > 0 &&
        this.state.clientGlosaItemSelectValue === null &&
        this.props.clientGlosaEntity.clientGlosaItem)
    ) {
      this.setState({
        clientGlosaItemSelectValue:
          this.props.clientGlosaEntity && this.props.clientGlosaEntity.clientGlosaItem
            ? this.props.clientGlosaEntity.clientGlosaItem.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.clientGlosaEntity && prevProps.clientGlosaEntity.id !== this.props.clientGlosaEntity.id) ||
      (this.props.quotes &&
        this.state.quoteSelectValue === null &&
        this.props.clientGlosaEntity.quote &&
        this.props.clientGlosaEntity.quote.id)
    ) {
      const p = this.props.clientGlosaEntity && this.props.clientGlosaEntity.quote ? this.props.clientGlosaEntity.quote : null;
      this.setState({
        quoteSelectValue: p ? { ...p, value: p.id, label: p['id'] ? p.id : '' } : null,
      });
    }

    if (
      (prevProps.clientGlosaEntity && prevProps.clientGlosaEntity.id !== this.props.clientGlosaEntity.id) ||
      (this.props.clientGlosaEntity.typeGlosa &&
        this.state.typeGlosaSelectValue === null &&
        this.props.clientGlosaEntity.typeGlosa !== this.state.typeGlosaSelectValue)
    ) {
      this.setState({ typeGlosaSelectValue: this.props.clientGlosaEntity.typeGlosa ? this.props.clientGlosaEntity.typeGlosa : null });
    }
    if (
      (prevProps.clientGlosaEntity && prevProps.clientGlosaEntity.id !== this.props.clientGlosaEntity.id) ||
      (this.state.statusSelectValue === null && this.props.clientGlosaEntity.status !== this.state.statusSelectValue)
    ) {
      this.setState({ statusSelectValue: this.props.clientGlosaEntity.status ? this.props.clientGlosaEntity.status : 'EMRECURSO' });
    }
    if (
      (prevProps.clientGlosaEntity && prevProps.clientGlosaEntity.id !== this.props.clientGlosaEntity.id) ||
      (this.props.clientGlosaEntity.observation &&
        this.state.observationSelectValue === null &&
        this.props.clientGlosaEntity.observation !== this.state.observationSelectValue)
    ) {
      this.setState({ observationSelectValue: this.props.clientGlosaEntity.observation ? this.props.clientGlosaEntity.observation : null });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  UNSAFE_componentWillMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params['id']);
    }
  }

  onBlobChange = (isAnImage, name, fileInput) => event => {
    const fileName = event.target.files[0].name;
    setFileData(event, (contentType, data) => this.props.setBlob(name, data, contentType, fileName), isAnImage);
    const varAux = {};
    varAux[name + 'SelectValue'] = fileName;
    this.setState(varAux);
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined);
  };
  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = offset !== null ? '&offset=' + offset : '';
    Object.keys(fieldsBase).map(key => {
      url += key !== 'offset' ? '&' + key + '=' + fieldsBase[key] : '';
    });
    return url;
  };
  saveEntity = (event: any, errors: any, values: any) => {
    if (errors.length === 0) {
      const { clientGlosaEntity } = this.props;
      const _entity = {
        ...clientGlosaEntity,
        // ...values,

        typeGlosa: this.state.typeGlosaSelectValue === null ? null : this.state.typeGlosaSelectValue,
        status: this.state.statusSelectValue === null ? null : this.state.statusSelectValue,
        observation: this.state.observationSelectValue,
      };

      const entity = _entity;

      const { typeGlosa, status, extraFilters, activePage, itemsPerPage, sort, order } = this.state.fieldsBase;

      if (this.state.isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              typeGlosa,
              status,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              typeGlosa,
              status,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/client-glosa?' + getEntityFiltersURL(this.state.fieldsBase));
  };

  getFullEntityFromState(v) {
    return {
      id: v.id,
      typeGlosa: this.state.typeGlosaSelectValue,
      status: this.state.statusSelectValue,
      observation: this.state.observationSelectValue,
      clientGlosaItem: this.state.clientGlosaItemSelectValue,
      quote: this.state.quoteSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    const clientGlosaItemEntity = v.clientGlosaItem;

    const quoteEntity = v.quote;

    return {
      typeGlosaSelectValue: v.typeGlosa,
      statusSelectValue: v.status,
      observationSelectValue: v.observation,
      clientGlosaItemSelectValue: clientGlosaItemEntity ? clientGlosaItemEntity['id'] : null,
      quoteSelectValue: quoteEntity ? quoteEntity['id'] : null,
    };
  }

  renderHeader() {
    const { clientGlosaEntity, clientGlosaItems, quotes, loading, updating } = this.props;
    const { isNew } = this.state;

    const { observation } = clientGlosaEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.clientGlosa.home.createLabel">Create a ClientGlosa</Translate>
            ) : (
              <Translate contentKey="generadorApp.clientGlosa.home.editLabel">Edit a ClientGlosa</Translate>
            )}
          </span>

          <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.clientGlosa.btnSave">Save</Translate>
          </Button>

          {!this.state.isNew ? (
            <>
              <Button
                color="primary"
                id="next-entity"
                disabled={updating}
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.typeGlosa,
                    this.state.fieldsBase.status,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 + 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 + 1 },
                    });
                    this.props.history.push(
                      '/client-glosa/' + myresult.value.data[0]['id'] + '/edit?' + this.getFiltersURL(this.state.fieldsBase.offset * 1 + 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.last.item', { fieldName: translate('generadorApp.clientGlosa.detail.title') }));
                  }
                }}
                className="float-right jh-create-entity"
              >
                {'>>'}
              </Button>

              <Button
                color="primary"
                id="prev-entity"
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.typeGlosa,
                    this.state.fieldsBase.status,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 - 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 - 1 },
                    });
                    this.props.history.push(
                      '/client-glosa/' + myresult.value.data[0]['id'] + '/edit?' + this.getFiltersURL(this.state.fieldsBase.offset * 1 - 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.first.item', { fieldName: translate('generadorApp.clientGlosa.detail.title') }));
                  }
                }}
                disabled={updating}
                className="float-right jh-create-entity"
              >
                {'<<'}
              </Button>
            </>
          ) : (
            <> </>
          )}

          <Button
            tag={Link}
            id="cancel-save"
            to={'/client-glosa?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.clientGlosa.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={'/client-glosa'}>
              <Translate contentKey="generadorApp.clientGlosa.home.title">Glosas</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.clientGlosa.detail.title">Glosa edit</Translate>
          </li>
        </ol>
      </>
    );
  }
  renderBody() {
    const { clientGlosaEntity, clientGlosaItems, quotes, loading, updating } = this.props;
    const { isNew } = this.state;

    const { observation } = clientGlosaEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const fullEntityEmbebed = this.getFullEntityFromState(clientGlosaEntity);

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="client-glosa-id">
                  <Translate contentKey="generadorApp.clientGlosa.field_id">ID</Translate>
                </Label>
                </Col> */}
                      <Col md="12">
                        <AvInput id="client-glosa-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'QuoteComponent' ? (
                    <Col md="6">
                      <QuoteComponent
                        entityBase={clientGlosaEntity}
                        baseState={this.state}
                        baseProps={this.props}
                        setState={_state => this.setState(_state)}
                      />
                    </Col>
                  ) : (
                    <> </>
                  )}

                  {baseFilters !== 'status' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="statusLabel" for="client-glosa-status">
                              <Translate contentKey="generadorApp.clientGlosa.status">Status</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="client-glosa-status"
                              className={'css-select-control'}
                              value={
                                this.state.statusSelectValue
                                  ? {
                                      value: this.state.statusSelectValue,
                                      label: translate('generadorApp.GlosaStatus.' + this.state.statusSelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.clientGlosa.status') }
                              }
                              options={[
                                { value: 'EMRECURSO', label: translate('generadorApp.GlosaStatus.EMRECURSO') },
                                { value: 'RECURSADO', label: translate('generadorApp.GlosaStatus.RECURSADO') },
                                { value: 'SEMRECURSO', label: translate('generadorApp.GlosaStatus.SEMRECURSO') },
                                { value: 'EMANALISE', label: translate('generadorApp.GlosaStatus.EMANALISE') },
                              ]}
                              onChange={(options: any) => this.setState({ statusSelectValue: options['value'] })}
                              name="status"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ statusSelectValue: evt.target.value })}
                      type="hidden"
                      name="status"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'observation' ? (
                    <Col md="12">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="observationLabel" for="client-glosa-observation">
                              <Translate contentKey="generadorApp.clientGlosa.observation">Observation</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <AvInput
                              onChange={evt => this.setState({ observationSelectValue: evt.target.value })}
                              validate={{}}
                              id="client-glosa-observation"
                              type="textarea"
                              name="observation"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ observationSelectValue: evt.target.value })}
                      type="hidden"
                      name="observation"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'QuoteItems' ? (
                    <Col md="12">
                      <QuoteItems
                        entityBase={clientGlosaEntity}
                        baseState={this.state}
                        baseProps={this.props}
                        setState={_state => this.setState(_state)}
                      />
                    </Col>
                  ) : (
                    <> </>
                  )}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { clientGlosaEntity, clientGlosaItems, quotes, loading, updating } = this.props;
    const { isNew } = this.state;

    const { observation } = clientGlosaEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...clientGlosaEntity,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel className="client-glosa client-glosa-update">
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToProps = (storeState: IRootState) => {
  return {
    clientGlosaItems: storeState.clientGlosaItem.entities,
    quotes: storeState.quote.entities,
    clientGlosaEntity: storeState.clientGlosa.entity,
    loading: storeState.clientGlosa.loading,
    updating: storeState.clientGlosa.updating,
    updateSuccess: storeState.clientGlosa.updateSuccess,
  };
};

export const mapDispatchToProps = {
  getEntity,
  updateEntity,
  setBlob,
  createEntity,
  getEntityOffset,
  reset,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ClientGlosaUpdate);
