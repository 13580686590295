/* eslint complexity: ["error", 500] */
import React, { useEffect, useState } from 'react';

import axios from 'axios';

import { Link } from 'react-router-dom';

import { Button, Col, Row, Label, UncontrolledCollapse, CardBody } from 'reactstrap';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';
import moment from 'moment';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Select from 'react-select';
import { jsonParse } from 'app/shared/util/entity-utils';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

export const cashFlow = (props: any) => {
  const [typeSelectValue, setTypeSelectValue] = useState('All');
  const [createdById, setCreatedById] = useState(null);
  const [createdDateStart, setCreatedDateStart] = useState(new Date());
  const [createdDateEnd, setCreatedDateEnd] = useState(new Date(new Date().setMonth(new Date().getMonth() + 5)));
  const [extraFilters, setExtraFilters] = useState(null);
  const [cashFlowList, setCashFlowList] = useState([]);

  const getCashFlow = () => {
    let urlCashFlow = '/api/billing-xmls/cash-flow?';
    urlCashFlow += 'start_date=' + (createdDateStart ? createdDateStart : new Date());
    urlCashFlow += '&end_date=' + (createdDateEnd ? createdDateEnd : new Date(new Date().setMonth(new Date().getMonth() + 5)));
    axios.get(urlCashFlow).then(v => setCashFlowList(v && v.data ? v.data : []));
  };

  useEffect(() => {
    getCashFlow();
  }, []);

  const getDaysArray = function (start, end) {
    const arr = [];
    for (const dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt));
    }
    return arr;
  };

  const printBillingXml = cash => {
    const otherData = jsonParse(cash.data);
    return (
      <>
        <div className="timeline-header">
          <span className="username">Conta a receber </span>
        </div>
        <div className="timeline-content">
          <div className="row">
            <div className="col-md-4">
              <strong> Numero: </strong> {otherData ? otherData.number : ''}
            </div>
            <div className="col-md-4">
              <strong> Cliente: </strong> {otherData ? otherData.client : ''}
            </div>
            <div className="col-md-4">
              <strong>Valor total:</strong> R$ {(cash.amount * 1).toFixed(2).replace('.', ',')}
            </div>
          </div>
        </div>
      </>
    );
  };
  const printBuyNfe = cash => {
    const otherData = jsonParse(cash.data);
    return (
      <>
        <div className="timeline-header">
          <span className="username">
            Conta a pagar <small> Matmed </small>{' '}
          </span>
        </div>
        <div className="timeline-content">
          <div className="row">
            <div className="col-md-4">
              <strong> Fornecedor: </strong> {otherData.supplier}
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <strong>Valor: R$ {(cash.amount * 1).toFixed(2).replace('.', ',')}</strong>
            </div>
          </div>
        </div>
      </>
    );
  };
  const printBillToPayXml = cash => {
    const otherData = jsonParse(cash.data);
    return (
      <>
        <div className="timeline-header">
          <span className="username">
            Conta a pagar <small> {otherData.cost_type}</small>
          </span>
        </div>
        <div className="timeline-content">
          <div className="row">
            <div className="col-md-4">
              <strong> Nome: </strong> {otherData.name}
            </div>
            <div className="col-md-4">
              <strong>Tipo:</strong> {cash.type === 'bill_to_pay_variable' ? 'Variável' : 'Fixo'}
            </div>
            <div className="col-md-4">
              <strong>Valor: R$ {(cash.amount * 1).toFixed(2).replace('.', ',')}</strong>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <strong>Descrição:</strong> {otherData.description}
            </div>
          </div>
        </div>
      </>
    );
  };
  const printAttendance = cash => {
    const otherData = jsonParse(cash.data);
    return (
      <>
        <div className="timeline-header">
          <span className="username">
            Conta a pagar <small> Profissional </small>
          </span>
        </div>
        <div className="timeline-content">
          <div className="row">
            <div className="col-md-4">
              <strong> Profissional: </strong> {otherData ? otherData.professional : ''}
            </div>
            <div className="col-md-4">
              <strong> Especialidade: </strong> {otherData ? otherData.specialty : ''}
            </div>
            <div className="col-md-4">
              <strong>Valor: R$ {(cash.amount * 1).toFixed(2).replace('.', ',')}</strong>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderTable = () => {
    const startDay = new Date('2020-07-26');
    const endDay = new Date('2020-12-17');
    const days = getDaysArray(startDay, endDay);
    return (
      <div>
        <h1 className="page-header">
          Periodo: {moment(createdDateStart).format('DD/MM/YYYY')} - {moment(createdDateEnd).format('DD/MM/YYYY')}
        </h1>
        <ul className="timeline">
          {cashFlowList.map((cash, idx) =>
            typeSelectValue === 'All' ||
            (typeSelectValue === 'BillToReceive' && cash.type === 'billing_xml') ||
            (typeSelectValue === 'BillToPay' && cash.type !== 'billing_xml') ? (
              <li key={idx}>
                <div className="timeline-time">
                  <span className="time">{moment(cash.date).format('DD/MM/YYYY')}</span>
                </div>
                <div className="timeline-icon">
                  <Link to="/extra/timeline">&nbsp;</Link>
                </div>
                <div className={'timeline-body ' + (cash.type === 'billing_xml' ? 'bill-to-receive' : 'bill-pay')}>
                  {cash.type === 'billing_xml' ? printBillingXml(cash) : <></>}
                  {cash.type === 'nfe_buys' ? printBuyNfe(cash) : <></>}
                  {cash.type === 'bill_to_pay_fixed' ? printBillToPayXml(cash) : <></>}
                  {cash.type === 'bill_to_pay_variable' ? printBillToPayXml(cash) : <></>}
                  {cash.type === 'pad_attendance' ? printAttendance(cash) : <></>}
                  <div className="row"> </div>
                </div>
              </li>
            ) : (
              <> </>
            )
          )}
        </ul>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.cashFlow.home.title">Billing Xmls</Translate>
          </span>
          <Button id="togglerFilterBillingXml" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.billingXml.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          &nbsp;
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.cashFlow.home.title">Billing Xmls</Translate>
          </li>
        </ol>
      </>
    );
  };

  const cancelFilters = () => {
    setCreatedById(null);
    setCreatedDateStart(null);
    setCreatedDateEnd(null);
    setExtraFilters(null);
    getCashFlow();
  };

  const renderFilter = () => {
    return (
      <>
        <UncontrolledCollapse toggler="#togglerFilterBillingXml">
          <CardBody>
            <div className="row mt-1 ml-3 mr-3">
              <Col md="3">
                <Row>
                  <Col md="12">
                    <Label id="classFixedCostsLabel" for="bill-to-pay-classFixedCosts">
                      <Translate contentKey="generadorApp.CashFlow.classFixedCosts">Classification fixed costs</Translate>
                    </Label>
                  </Col>
                  <Col md="12">
                    <Select
                      id="bill-to-pay-type"
                      className={'css-select-control'}
                      value={
                        typeSelectValue
                          ? {
                              value: typeSelectValue,
                              label: translate('generadorApp.CashFlow.' + typeSelectValue),
                            }
                          : { value: 'All', label: translate('generadorApp.CashFlow.All') }
                      }
                      options={[
                        { value: 'All', label: translate('generadorApp.CashFlow.All') },
                        { value: 'BillToReceive', label: translate('generadorApp.CashFlow.BillToReceive') },
                        { value: 'BillToPay', label: translate('generadorApp.CashFlow.BillToPay') },
                      ]}
                      onChange={(options: any) => setTypeSelectValue(options['value'])}
                      name="type"
                    />
                  </Col>
                </Row>
              </Col>
              <Col md="3">
                <Row className="mr-1 mt-1">
                  <Label id="createdDateStart" for="billing-xml-createdDate_start">
                    <Translate contentKey="generadorApp.billingXml.createdDate_start">Created Date (start)</Translate>
                  </Label>
                  <DatePicker
                    onChange={value => setCreatedDateStart(value)}
                    id="createdDateStart"
                    className="form-control react-datepicker"
                    name="createdDateStart"
                    dateFormat="dd/MM/yyyy"
                    selected={createdDateStart ? moment(createdDateStart).toDate() : null}
                    locale="pt"
                  />
                </Row>
              </Col>
              <Col md="3">
                <Row className="mr-1 mt-1">
                  <Label id="createdDateEnd" for="billing-xml-createdDate_end">
                    <Translate contentKey="generadorApp.billingXml.createdDate_end">Created Date (end)</Translate>
                  </Label>
                  <DatePicker
                    onChange={value => setCreatedDateEnd(value)}
                    id="createdDateEnd"
                    className="form-control react-datepicker"
                    name="createdDateEnd"
                    dateFormat="dd/MM/yyyy"
                    selected={createdDateEnd ? moment(createdDateEnd).toDate() : null}
                    locale="pt"
                  />
                </Row>
              </Col>
            </div>

            <div className="row mb-2 mr-4 justify-content-end">
              <Button color="primary" onClick={getCashFlow}>
                <i className="fa fa-filter" aria-hidden={'true'}></i>&nbsp;
                <Translate contentKey="generadorApp.billingXml.btnFilter">Filter</Translate>
              </Button>
              &nbsp;
              <Button color="secondary" onClick={cancelFilters}>
                <FontAwesomeIcon icon="trash-alt" />
                &nbsp;
                <Translate contentKey="generadorApp.billingXml.btnCleanFilter">Clean Filter</Translate>
              </Button>
            </div>
          </CardBody>
        </UncontrolledCollapse>
      </>
    );
  };

  return (
    <Panel className="billing-xml billing-xml-list-table">
      <PanelHeader>{renderHeader()}</PanelHeader>

      <PanelBody>
        <div className="table-responsive">
          {renderFilter()}

          {renderTable()}
        </div>
      </PanelBody>
      <PanelFooter></PanelFooter>
    </Panel>
  );
};

export default cashFlow;
