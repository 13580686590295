import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import PharmacyStockStatus from './extended/pharmacy-stock-status';

import PharmacyStockStatusDetail from './pharmacy-stock-status-detail';

import PharmacyStockStatusUpdate from './pharmacy-stock-status';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={PharmacyStockStatusUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={PharmacyStockStatusUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={PharmacyStockStatusDetail} />
      <ErrorBoundaryRoute path={match.path} component={PharmacyStockStatus} />
    </Switch>
  </>
);

export default Routes;
