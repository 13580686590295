import { Moment } from 'moment';
import { IUser } from 'app/shared/model/user.model';

export interface IAuditEntity {
  id?: number;
  entityId?: number;
  entityType?: string;
  action?: string;
  entityKeyDiff?: string;
  entityValue?: any;
  commitVersion?: number;
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const defaultValue: Readonly<IAuditEntity> = {};
