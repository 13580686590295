/* eslint complexity: ["error", 500] */
import axios from 'axios';

import { IPaginationBaseState, IPayloadResult } from 'react-jhipster';

import { IPayload } from 'react-jhipster/src/type/redux-action.type';

import { BASE_API_VERSION_PATH } from 'app/config/constants';

import { cleanEntity, jsonParse } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IPatNrPrescProcediment, defaultValue } from 'app/shared/model/pat-nr-presc-procediment.model';

export declare type ICrudPutAction<T> = (data?: T, listFiltersPage?) => IPayload<T> | IPayloadResult<T>;
export declare type ICrudGetAction<T> = (id: string | number, selectFields?: string) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const ACTION_TYPES = {
  FETCH_PATNRPRESCPROCEDIMENT_LIST_EXPORT: 'patNrPrescProcediment/FETCH_PATNRPRESCPROCEDIMENT_LIST_EXPORT',
  FETCH_PATNRPRESCPROCEDIMENT_LIST: 'patNrPrescProcediment/FETCH_PATNRPRESCPROCEDIMENT_LIST',
  FETCH_PATNRPRESCPROCEDIMENT: 'patNrPrescProcediment/FETCH_PATNRPRESCPROCEDIMENT',
  FETCH_PATNRPRESCPROCEDIMENT_OFFSET: 'patNrPrescProcediment/FETCH_PATNRPRESCPROCEDIMENT_OFFSET',
  CREATE_PATNRPRESCPROCEDIMENT: 'patNrPrescProcediment/CREATE_PATNRPRESCPROCEDIMENT',
  UPDATE_PATNRPRESCPROCEDIMENT: 'patNrPrescProcediment/UPDATE_PATNRPRESCPROCEDIMENT',
  CREATE_PATNRPRESCPROCEDIMENT_AND_GO_LIST: 'patNrPrescProcediment/CREATE_PATNRPRESCPROCEDIMENT_AND_GO_LIST',
  UPDATE_PATNRPRESCPROCEDIMENT_AND_GO_LIST: 'patNrPrescProcediment/UPDATE_PATNRPRESCPROCEDIMENT_AND_GO_LIST',
  DELETE_PATNRPRESCPROCEDIMENT: 'patNrPrescProcediment/DELETE_PATNRPRESCPROCEDIMENT',
  RESET: 'patNrPrescProcediment/RESET',
  SHOW_MODAL: 'patNrPrescProcediment/SHOW_MODAL',
};

const initialState = {
  loading: false,
  openModal: {
    list: false,
    view: false,
    detail: false,
  },
  errorMessage: null,
  entities: [] as ReadonlyArray<IPatNrPrescProcediment>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const actionPendind = [];
let actualState = initialState;

export const convertEntityBuffers = entity => ({
  ...entity,
});

export type PatNrPrescProcedimentState = Readonly<typeof initialState>;

export interface IPatNrPrescProcedimentBaseState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  listCheckedID?: Array<number>;
  isCheckedAll?: boolean;
  urlBack?: string;
  extraFilters?: any;
  baseFilters: any;
  offset: number;
  frequency?: any;
  continuous?: any;
  status?: any;
  patientNursingPrescriptionId?: any;
  patientNursingPrescriptionStartFilter?: any;
}

export interface IFieldsBase extends IPatNrPrescProcedimentBaseState, IPaginationBaseState {}
export interface IPatNrPrescProcedimentUpdateState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  urlBack?: string;
  functionEmbebed?: any[];
  fieldsBase: IFieldsBase;
  descriptionSelectValue?: any;
  descriptionStartSelectOptions?: any;
  orderSelectValue?: any;
  orderStartSelectOptions?: any;
  continuousSelectValue?: any;
  continuousStartSelectOptions?: any;
  initialDateSelectValue?: any;
  initialDateStartSelectOptions?: any;
  finalDateSelectValue?: any;
  finalDateStartSelectOptions?: any;
  statusSelectValue?: any;
  statusStartSelectOptions?: any;
  frequencySelectValue?: any;
  frequencyStartSelectOptions?: any;
  periodSelectValue?: any;
  periodStartSelectOptions?: any;
  observationSelectValue?: any;
  observationStartSelectOptions?: any;

  nrStatusHistorySelectValue?: any;
  nrStatusHistoryStartSelectOptions?: any;

  patientNursingPrescriptionSelectValue?: any;
  patientNursingPrescriptionStartSelectOptions?: any;

  isNew: boolean;
  patientNursingPrescriptionId?: any;
}

// Reducer

export default (state: PatNrPrescProcedimentState = initialState, action): PatNrPrescProcedimentState => {
  actualState = state;
  if (
    action.type.includes('_PENDING') &&
    !actionPendind.includes(action.type.replace('_PENDING', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_PENDING', ''))
  ) {
    actionPendind.push(action.type.replace('_PENDING', ''));
  }

  if (
    action.type.includes('_FULFILLED') &&
    actionPendind.includes(action.type.replace('_FULFILLED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_FULFILLED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_FULFILLED', '')), 1);
  }

  if (
    action.type.includes('_REJECTED') &&
    actionPendind.includes(action.type.replace('_REJECTED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_REJECTED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_REJECTED', '')), 1);
  }

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PATNRPRESCPROCEDIMENT_LIST_EXPORT):
    case REQUEST(ACTION_TYPES.FETCH_PATNRPRESCPROCEDIMENT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_PATNRPRESCPROCEDIMENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_PATNRPRESCPROCEDIMENT):
    case REQUEST(ACTION_TYPES.UPDATE_PATNRPRESCPROCEDIMENT):
    case REQUEST(ACTION_TYPES.CREATE_PATNRPRESCPROCEDIMENT_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.UPDATE_PATNRPRESCPROCEDIMENT_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.DELETE_PATNRPRESCPROCEDIMENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_PATNRPRESCPROCEDIMENT_LIST_EXPORT):
    case FAILURE(ACTION_TYPES.FETCH_PATNRPRESCPROCEDIMENT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_PATNRPRESCPROCEDIMENT):
    case FAILURE(ACTION_TYPES.CREATE_PATNRPRESCPROCEDIMENT):
    case FAILURE(ACTION_TYPES.UPDATE_PATNRPRESCPROCEDIMENT):
    case FAILURE(ACTION_TYPES.CREATE_PATNRPRESCPROCEDIMENT_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.UPDATE_PATNRPRESCPROCEDIMENT_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.DELETE_PATNRPRESCPROCEDIMENT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_PATNRPRESCPROCEDIMENT_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_PATNRPRESCPROCEDIMENT_OFFSET):
      if (action.payload.data && action.payload.data.length > 0) {
        return {
          ...state,
          loading: false,
          entity: convertEntityBuffers(action.payload.data[0]),
        };
      }
      return {
        ...state,
        loading: false,
      };

    case SUCCESS(ACTION_TYPES.FETCH_PATNRPRESCPROCEDIMENT):
      return {
        ...state,
        loading: false,
        entity: convertEntityBuffers(action.payload.data),
      };
    case SUCCESS(ACTION_TYPES.CREATE_PATNRPRESCPROCEDIMENT):
    case SUCCESS(ACTION_TYPES.UPDATE_PATNRPRESCPROCEDIMENT):
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_PATNRPRESCPROCEDIMENT_AND_GO_LIST):
    case SUCCESS(ACTION_TYPES.UPDATE_PATNRPRESCPROCEDIMENT_AND_GO_LIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_PATNRPRESCPROCEDIMENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };

    case ACTION_TYPES.SHOW_MODAL: {
      const openModal = state.openModal;
      openModal[action.payload.type] = action.payload.show;
      return {
        ...state,
        openModal,
      };
    }
    default:
      return state;
  }
};

const apiUrl = BASE_API_VERSION_PATH + 'pat-nr-presc-procediments';

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

// Actions

// Actions
export type ICrudGetAllActionPatNrPrescProcediment<T> = (
  frequency?: any,
  continuous?: any,
  status?: any,
  patientNursingPrescriptionId?: any,
  extraFilters?: {},
  page?: number,
  size?: number,
  sort?: string,
  selectFields?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getEntitiesFilter = (filters?, page?, size?, sort?, selectFields = '') => {
  const requestUrl = `${apiUrl}?page=${page >= 0 ? page : 0}&size=${size >= 0 ? size : 20}&sort=${sort ? sort : 'id,asc'}&`;
  const requestFilters = filters.map(v => v.column + '.' + v.operation + '=' + v.value).join('&');
  return {
    type: ACTION_TYPES.FETCH_PATNRPRESCPROCEDIMENT_LIST,
    payload: axios.get<IPatNrPrescProcediment>(`${requestUrl}${requestFilters}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getAllEntities = (filters?, sort?, selectFields = '') => {
  if (
    (actualState.totalItems > 0 && actualState.entities.length === actualState.totalItems) ||
    actionPendind.includes(ACTION_TYPES.FETCH_PATNRPRESCPROCEDIMENT_LIST)
  ) {
    return {
      type: ACTION_TYPES.FETCH_PATNRPRESCPROCEDIMENT_LIST + '_FROM_CACHE',
      payload: new Promise(function (resolve, reject) {
        resolve('done');
      }),
    };
  }

  const idsRequest = filters && typeof filters['ids'] !== 'undefined' && filters['ids'] ? `id.in=${filters['ids'].join(',')}&` : '';
  const frequencyRequest =
    filters && typeof filters['frequency'] !== 'undefined' && filters['frequency'] ? `frequency.contains=${filters['frequency']}&` : '';
  const continuousRequest =
    filters && typeof filters['continuous'] !== 'undefined' && filters['continuous'] ? `continuous.contains=${filters['continuous']}&` : '';
  const statusRequest =
    filters && typeof filters['status'] !== 'undefined' && filters['status'] ? `status.contains=${filters['status']}&` : '';
  const patientNursingPrescriptionRequest =
    filters && typeof filters['patientNursingPrescriptionId'] !== 'undefined' && filters['patientNursingPrescriptionId']
      ? `patientNursingPrescription.id.in=${filters['patientNursingPrescriptionId']}&`
      : '';

  const requestUrl = `${apiUrl}${`?page=${0}&size=${1000000}&sort=${sort ? sort : 'id,asc'}&`}`;
  return {
    type: ACTION_TYPES.FETCH_PATNRPRESCPROCEDIMENT_LIST,
    payload: axios.get<IPatNrPrescProcediment>(
      `${requestUrl}${idsRequest}${frequencyRequest}${continuousRequest}${statusRequest}${patientNursingPrescriptionRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntities: ICrudGetAllActionPatNrPrescProcediment<IPatNrPrescProcediment> = (
  frequency,
  continuous,
  status,
  patientNursingPrescriptionId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const frequencyRequest = frequency
    ? Array.isArray(status)
      ? `frequency.in=${frequency.join(',')}&`
      : `frequency.equals=${frequency}&`
    : '';
  const continuousRequest = continuous !== '' ? `continuous.equals=${continuous}&` : '';
  const statusRequest = status ? (Array.isArray(status) ? `status.in=${status.join(',')}&` : `status.equals=${status}&`) : '';
  const patientNursingPrescriptionRequest = patientNursingPrescriptionId
    ? `patientNursingPrescription.id.in=${patientNursingPrescriptionId}&`
    : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PATNRPRESCPROCEDIMENT_LIST,
    payload: axios.get<IPatNrPrescProcediment>(
      `${requestUrl}${extraFiltersRequest}${frequencyRequest}${continuousRequest}${statusRequest}${patientNursingPrescriptionRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntityOffset: any = (
  frequency,
  continuous,
  status,
  patientNursingPrescriptionId,
  extraFilters,
  offset,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const frequencyRequest = frequency
    ? Array.isArray(status)
      ? `frequency.in=${frequency.join(',')}&`
      : `frequency.equals=${frequency}&`
    : '';
  const continuousRequest = continuous !== '' ? `continuous.equals=${continuous}&` : '';
  const statusRequest = status ? (Array.isArray(status) ? `status.in=${status.join(',')}&` : `status.equals=${status}&`) : '';
  const patientNursingPrescriptionRequest = patientNursingPrescriptionId
    ? `patientNursingPrescription.id.in=${patientNursingPrescriptionId}&`
    : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${offset}&size=${1}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PATNRPRESCPROCEDIMENT_OFFSET,
    payload: axios.get<IPatNrPrescProcediment>(
      `${requestUrl}${extraFiltersRequest}${frequencyRequest}${continuousRequest}${statusRequest}${patientNursingPrescriptionRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntity: ICrudGetAction<IPatNrPrescProcediment> = (id, selectFields = '') => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_PATNRPRESCPROCEDIMENT,
    payload: axios.get<IPatNrPrescProcediment>(requestUrl, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntitiesExport: ICrudGetAllActionPatNrPrescProcediment<IPatNrPrescProcediment> = (
  frequency,
  continuous,
  status,
  patientNursingPrescriptionId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const frequencyRequest = frequency
    ? Array.isArray(status)
      ? `frequency.in=${frequency.join(',')}&`
      : `frequency.equals=${frequency}&`
    : '';
  const continuousRequest = continuous !== '' ? `continuous.equals=${continuous}&` : '';
  const statusRequest = status ? (Array.isArray(status) ? `status.in=${status.join(',')}&` : `status.equals=${status}&`) : '';
  const patientNursingPrescriptionRequest = patientNursingPrescriptionId
    ? `patientNursingPrescription.id.in=${patientNursingPrescriptionId}&`
    : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PATNRPRESCPROCEDIMENT_LIST,
    payload: axios.get<IPatNrPrescProcediment>(
      `${requestUrl}${extraFiltersRequest}${frequencyRequest}${continuousRequest}${statusRequest}${patientNursingPrescriptionRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const createEntity: ICrudPutAction<IPatNrPrescProcediment> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.CREATE_PATNRPRESCPROCEDIMENT_AND_GO_LIST
        : ACTION_TYPES.CREATE_PATNRPRESCPROCEDIMENT,
    payload: axios.post(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const updateEntity: ICrudPutAction<IPatNrPrescProcediment> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.UPDATE_PATNRPRESCPROCEDIMENT_AND_GO_LIST
        : ACTION_TYPES.UPDATE_PATNRPRESCPROCEDIMENT,
    payload: axios.put(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const deleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_PATNRPRESCPROCEDIMENT,
    payload: axios.delete(requestUrl),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const showModal = view => ({
  payload: { type: view, show: true },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const hideModal = view => ({
  payload: { type: view, show: false },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const getUrlBack = (location): string => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  let urlBack = _urlBase.searchParams.get('urlBack') || '';
  urlBack = urlBack.trim();
  urlBack = urlBack.startsWith('/') ? urlBack.slice(1) : urlBack;
  urlBack = urlBack.endsWith('/') ? urlBack.slice(0, -1) : urlBack;
  return urlBack ? '/' + urlBack + '/' : '';
};

export const getPatNrPrescProcedimentState = (location): IPatNrPrescProcedimentBaseState => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  const extraFilters = jsonParse(_urlBase.searchParams.get('extraFilters') || '{}');
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;

  const frequency = _urlBase.searchParams.get('frequency') || '';
  const continuous = _urlBase.searchParams.get('continuous') || '';
  const status = _urlBase.searchParams.get('status') || '';
  const patientNursingPrescriptionId = _urlBase.searchParams.get('patientNursingPrescription') || '';
  return {
    baseFilters,
    extraFilters,
    offset,
    frequency,
    continuous,
    status,
    patientNursingPrescriptionId: patientNursingPrescriptionId
      ? patientNursingPrescriptionId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] }))
      : [],
  };
};

export const getEntityFiltersURL = (state, offset = null) => {
  return (
    'baseFilters=' +
    state.baseFilters +
    '&extraFilters=' +
    encodeURI(JSON.stringify(state.extraFilters)) +
    '&page=' +
    state.activePage +
    '&' +
    'size=' +
    state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    state.sort +
    ',' +
    state.order +
    '&' +
    (state.frequency ? 'frequency=' + state.frequency + '&' : '') +
    (state.continuous ? 'continuous=' + state.continuous + '&' : '') +
    (state.status ? 'status=' + state.status + '&' : '') +
    (state.patientNursingPrescriptionId
      ? 'patientNursingPrescription=' + state.patientNursingPrescriptionId.map(v => v.value + '<->' + v.label).join(',') + '&'
      : '') +
    ''
  );
};
