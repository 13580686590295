import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import NfeFile from './nfe-file';

import NfeFileUpdate from './nfe-file';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={NfeFileUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={NfeFileUpdate} />
      <ErrorBoundaryRoute path={match.path} component={NfeFile} />
    </Switch>
  </>
);

export default Routes;
