/* eslint complexity: ["error", 500] */
/* eslint no-empty: off */
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import SelectAsync from 'react-select/async';

import { Link, RouteComponentProps } from 'react-router-dom';
import InputMask from 'react-input-mask';
import ReactQuill from 'react-quill';

import {
  quillEditorModules,
  quillEditorFormats,
  getFilterFromSelect,
  getFormFromSelect,
  getListAxios,
  showFieldsSelectAsync,
} from 'app/shared/util/entity-utils';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Col, Label } from 'reactstrap';
import { setFileData } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import {
  IProfessionalUpdateState as IBaseUpdateState,
  getEntity,
  getProfessionalState,
  getUrlBack,
  updateEntity,
  createEntity,
  getEntityFiltersURL,
  getEntityOffset,
  setBlob,
  reset,
} from './professional.reducer';

import { viacepRequest } from 'app/shared/util/entity-utils';

import { RenderModalSuperSelect } from 'app/shared/layout/components/RenderModalSuperSelect';

import { StatePropsExtended, DispatchPropsExtended, IUpdateExtendedState } from './extended/professional-update';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';
import moment from 'moment';
registerLocale('pt', pt);

export interface IProfessionalUpdateProps
  extends StateProps,
    DispatchProps,
    StatePropsExtended,
    DispatchPropsExtended,
    RouteComponentProps<{ url: string }> {}
export interface IProfessionalUpdateState extends IBaseUpdateState, IUpdateExtendedState {}

export class ProfessionalUpdate extends React.Component<IProfessionalUpdateProps, IProfessionalUpdateState> {
  nameFileInput: any;

  rgFileInput: any;

  cpfFileInput: any;

  expiredDateFileInput: any;

  experienceTimeFileInput: any;

  companyFileInput: any;

  cnpjFileInput: any;

  observationFileInput: any;

  sourceFileInput: any;

  registerFileInput: any;

  statusFileInput: any;

  zipCodeFileInput: any;

  streetFileInput: any;

  complementFileInput: any;

  numberFileInput: any;

  neighborhoodFileInput: any;

  cityFileInput: any;

  ufFileInput: any;

  sexFileInput: any;

  birthDateFileInput: any;

  bankFileInput: any;

  agencyFileInput: any;

  bankAccountFileInput: any;

  typeFileInput: any;

  professionalComplexityFileInput: any;
  constructor(props: Readonly<IProfessionalUpdateProps>) {
    super(props);

    this.observationFileInput = React.createRef();

    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      functionEmbebed: [],

      userSelectValue: null,

      padAttendanceSelectValue: null,

      professionalContactSelectValue: null,

      professionalStatusHistorySelectValue: null,

      patientNursingPrescriptionSelectValue: null,

      professionalFileSelectValue: null,

      professionalOccupationAreaSelectValue: null,

      pushSelectValue: null,

      professionalDeviceSelectValue: null,

      specialtySelectValue: null,

      professionalContractionTypeSelectValue: null,

      levelGroupSelectValue: null,

      professionalAdviceSelectValue: null,

      categorySelectValue: null,

      patientBlockedSelectValue: null,

      patientPreferredSelectValue: null,

      cepRequestCep: null,
      cepRequestLogradouro: null,

      cepRequestBairro: null,
      cepRequestLocalidade: null,
      cepRequestUf: null,

      nameSelectValue: null,
      rgSelectValue: null,
      cpfSelectValue: null,
      expiredDateSelectValue: null,
      experienceTimeSelectValue: null,
      companySelectValue: null,
      cnpjSelectValue: null,
      observationSelectValue: null,
      sourceSelectValue: null,
      registerSelectValue: null,
      statusSelectValue: null,
      zipCodeSelectValue: null,
      streetSelectValue: null,
      complementSelectValue: null,
      numberSelectValue: null,
      neighborhoodSelectValue: null,
      citySelectValue: null,
      ufSelectValue: null,
      sexSelectValue: null,
      birthDateSelectValue: null,
      bankSelectValue: null,
      agencySelectValue: null,
      bankAccountSelectValue: null,
      typeSelectValue: null,
      professionalComplexitySelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getProfessionalState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
      activeTab: 0,
      idsprofessionalDevice: [],
      idsspecialty: [],
      idspatientBlocked: [],
      idspatientPreferred: [],
      userId: null,
      professionalContractionTypeName: null,
      levelGroupName: null,
      professionalAdviceName: null,
      categoryName: null,

      isNew: !this.props.match.params || !this.props.match.params['id'],
    };
  }
  toggleTab(tab: number) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }
  tabsNext(tab: number, tabAtual?: number) {
    if (typeof tabAtual !== 'undefined' && tabAtual === 0) {
      if (!this.state.nameSelectValue) {
        toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.professional.name') }));
        return false;
      }
    }
    if (typeof tabAtual !== 'undefined' && tabAtual === 1) {
      if (!this.state.cepRequestCep) {
        toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.professional.zipCode') }));
        return false;
      }

      if (!this.state.numberSelectValue) {
        toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.professional.number') }));
        return false;
      }
    }
    if (typeof tabAtual !== 'undefined' && tabAtual === 2) {
    }
    if (typeof tabAtual !== 'undefined' && tabAtual === 3) {
      if (!this.state.agencySelectValue) {
        toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.professional.agency') }));
        return false;
      }

      if (!this.state.bankAccountSelectValue) {
        toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.professional.bankAccount') }));
        return false;
      }
    }
    if (typeof tabAtual !== 'undefined' && tabAtual === 4) {
    }
    this.toggleTab(tab);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateSuccess !== this.props.updateSuccess && prevProps.updateSuccess) {
      this.handleClose();
    }

    // data-type-rel -> tipo-3 one-to-one owner-side
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.users &&
        this.state.userSelectValue === null &&
        this.props.professionalEntity.user &&
        this.props.professionalEntity.user.id)
    ) {
      const p = this.props.professionalEntity && this.props.professionalEntity.user ? this.props.professionalEntity.user : null;
      this.setState({
        userSelectValue: p ? { ...p, value: p.id, label: p['id'] ? p.id : '' } : null,
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.padAttendances.length > 0 && this.state.padAttendanceSelectValue === null && this.props.professionalEntity.padAttendance)
    ) {
      this.setState({
        padAttendanceSelectValue:
          this.props.professionalEntity && this.props.professionalEntity.padAttendance
            ? this.props.professionalEntity.padAttendance.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalContacts.length > 0 &&
        this.state.professionalContactSelectValue === null &&
        this.props.professionalEntity.professionalContact)
    ) {
      this.setState({
        professionalContactSelectValue:
          this.props.professionalEntity && this.props.professionalEntity.professionalContact
            ? this.props.professionalEntity.professionalContact.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalStatusHistories.length > 0 &&
        this.state.professionalStatusHistorySelectValue === null &&
        this.props.professionalEntity.professionalStatusHistory)
    ) {
      this.setState({
        professionalStatusHistorySelectValue:
          this.props.professionalEntity && this.props.professionalEntity.professionalStatusHistory
            ? this.props.professionalEntity.professionalStatusHistory.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.patientNursingPrescriptions.length > 0 &&
        this.state.patientNursingPrescriptionSelectValue === null &&
        this.props.professionalEntity.patientNursingPrescription)
    ) {
      this.setState({
        patientNursingPrescriptionSelectValue:
          this.props.professionalEntity && this.props.professionalEntity.patientNursingPrescription
            ? this.props.professionalEntity.patientNursingPrescription
                .filter(p => p)
                .map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalFiles.length > 0 &&
        this.state.professionalFileSelectValue === null &&
        this.props.professionalEntity.professionalFile)
    ) {
      this.setState({
        professionalFileSelectValue:
          this.props.professionalEntity && this.props.professionalEntity.professionalFile
            ? this.props.professionalEntity.professionalFile.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalOccupationAreas.length > 0 &&
        this.state.professionalOccupationAreaSelectValue === null &&
        this.props.professionalEntity.professionalOccupationArea)
    ) {
      this.setState({
        professionalOccupationAreaSelectValue:
          this.props.professionalEntity && this.props.professionalEntity.professionalOccupationArea
            ? this.props.professionalEntity.professionalOccupationArea
                .filter(p => p)
                .map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.pushes.length > 0 && this.state.pushSelectValue === null && this.props.professionalEntity.push)
    ) {
      this.setState({
        pushSelectValue:
          this.props.professionalEntity && this.props.professionalEntity.push
            ? this.props.professionalEntity.push.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-1 many-to-many owner-side
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalDevices.length > 0 &&
        this.state.professionalDeviceSelectValue === null &&
        this.props.professionalEntity.professionalDevices)
    ) {
      this.setState({
        professionalDeviceSelectValue:
          this.props.professionalEntity && this.props.professionalEntity.professionalDevices
            ? this.props.professionalEntity.professionalDevices.filter(p => p).map(p => ({ value: p.id, label: p['name'] ? p.name : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-1 many-to-many owner-side
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.specialties.length > 0 && this.state.specialtySelectValue === null && this.props.professionalEntity.specialties)
    ) {
      this.setState({
        specialtySelectValue:
          this.props.professionalEntity && this.props.professionalEntity.specialties
            ? this.props.professionalEntity.specialties.filter(p => p).map(p => ({ value: p.id, label: p['name'] ? p.name : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalContractionTypes &&
        this.state.professionalContractionTypeSelectValue === null &&
        this.props.professionalEntity.professionalContractionType &&
        this.props.professionalEntity.professionalContractionType.id)
    ) {
      const p =
        this.props.professionalEntity && this.props.professionalEntity.professionalContractionType
          ? this.props.professionalEntity.professionalContractionType
          : null;
      this.setState({
        professionalContractionTypeSelectValue: p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : null,
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.levelGroups &&
        this.state.levelGroupSelectValue === null &&
        this.props.professionalEntity.levelGroup &&
        this.props.professionalEntity.levelGroup.id)
    ) {
      const p = this.props.professionalEntity && this.props.professionalEntity.levelGroup ? this.props.professionalEntity.levelGroup : null;
      this.setState({
        levelGroupSelectValue: p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : null,
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalAdvices &&
        this.state.professionalAdviceSelectValue === null &&
        this.props.professionalEntity.professionalAdvice &&
        this.props.professionalEntity.professionalAdvice.id)
    ) {
      const p =
        this.props.professionalEntity && this.props.professionalEntity.professionalAdvice
          ? this.props.professionalEntity.professionalAdvice
          : null;
      this.setState({
        professionalAdviceSelectValue: p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : null,
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.categories &&
        this.state.categorySelectValue === null &&
        this.props.professionalEntity.category &&
        this.props.professionalEntity.category.id)
    ) {
      const p = this.props.professionalEntity && this.props.professionalEntity.category ? this.props.professionalEntity.category : null;
      this.setState({
        categorySelectValue: p
          ? {
              ...p,
              value: p.id,
              label: (p['levelGroup'] && p['levelGroup']['name'] ? p.levelGroup.name : '') + ' | ' + (p['name'] ? p.name : ''),
            }
          : null,
      });
    }

    // data-type-rel -> tipo-1 many-to-many other-side
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.patients.length > 0 && this.state.patientBlockedSelectValue === null && this.props.professionalEntity.patientBlockeds)
    ) {
      this.setState({
        patientBlockedSelectValue:
          this.props.professionalEntity && this.props.professionalEntity.patientBlockeds
            ? this.props.professionalEntity.patientBlockeds.filter(p => p).map(p => ({ value: p.id, label: p['name'] ? p.name : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-1 many-to-many other-side
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.patients.length > 0 && this.state.patientPreferredSelectValue === null && this.props.professionalEntity.patientPreferreds)
    ) {
      this.setState({
        patientPreferredSelectValue:
          this.props.professionalEntity && this.props.professionalEntity.patientPreferreds
            ? this.props.professionalEntity.patientPreferreds.filter(p => p).map(p => ({ value: p.id, label: p['name'] ? p.name : '' }))
            : [],
      });
    }

    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalEntity.name &&
        this.state.nameSelectValue === null &&
        this.props.professionalEntity.name !== this.state.nameSelectValue)
    ) {
      this.setState({ nameSelectValue: this.props.professionalEntity.name ? this.props.professionalEntity.name : null });
    }
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalEntity.rg &&
        this.state.rgSelectValue === null &&
        this.props.professionalEntity.rg !== this.state.rgSelectValue)
    ) {
      this.setState({ rgSelectValue: this.props.professionalEntity.rg ? this.props.professionalEntity.rg : null });
    }
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalEntity.cpf &&
        this.state.cpfSelectValue === null &&
        this.props.professionalEntity.cpf !== this.state.cpfSelectValue)
    ) {
      this.setState({ cpfSelectValue: this.props.professionalEntity.cpf ? this.props.professionalEntity.cpf : null });
    }
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalEntity.expiredDate &&
        this.state.expiredDateSelectValue === null &&
        this.props.professionalEntity.expiredDate !== this.state.expiredDateSelectValue)
    ) {
      this.setState({
        expiredDateSelectValue: this.props.professionalEntity.expiredDate ? this.props.professionalEntity.expiredDate : null,
      });
    }
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalEntity.experienceTime &&
        this.state.experienceTimeSelectValue === null &&
        this.props.professionalEntity.experienceTime !== this.state.experienceTimeSelectValue)
    ) {
      this.setState({
        experienceTimeSelectValue: this.props.professionalEntity.experienceTime ? this.props.professionalEntity.experienceTime : null,
      });
    }
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalEntity.company &&
        this.state.companySelectValue === null &&
        this.props.professionalEntity.company !== this.state.companySelectValue)
    ) {
      this.setState({ companySelectValue: this.props.professionalEntity.company ? this.props.professionalEntity.company : null });
    }
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalEntity.cnpj &&
        this.state.cnpjSelectValue === null &&
        this.props.professionalEntity.cnpj !== this.state.cnpjSelectValue)
    ) {
      this.setState({ cnpjSelectValue: this.props.professionalEntity.cnpj ? this.props.professionalEntity.cnpj : null });
    }
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalEntity.observation &&
        this.state.observationSelectValue === null &&
        this.props.professionalEntity.observation !== this.state.observationSelectValue)
    ) {
      this.setState({
        observationSelectValue: this.props.professionalEntity.observation ? this.props.professionalEntity.observation : null,
      });
    }
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalEntity.source &&
        this.state.sourceSelectValue === null &&
        this.props.professionalEntity.source !== this.state.sourceSelectValue)
    ) {
      this.setState({ sourceSelectValue: this.props.professionalEntity.source ? this.props.professionalEntity.source : null });
    }
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalEntity.register &&
        this.state.registerSelectValue === null &&
        this.props.professionalEntity.register !== this.state.registerSelectValue)
    ) {
      this.setState({ registerSelectValue: this.props.professionalEntity.register ? this.props.professionalEntity.register : null });
    }
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalEntity.status &&
        this.state.statusSelectValue === null &&
        this.props.professionalEntity.status !== this.state.statusSelectValue)
    ) {
      this.setState({ statusSelectValue: this.props.professionalEntity.status ? this.props.professionalEntity.status : null });
    }
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalEntity.zipCode && this.state.cepRequestCep === null)
    ) {
      this.setState({ cepRequestCep: this.props.professionalEntity.zipCode });
      // this.setState({ zipCodeSelectValue: this.props.professionalEntity.zipCode })
    }
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalEntity.zipCode &&
        this.state.zipCodeSelectValue === null &&
        this.props.professionalEntity.zipCode !== this.state.zipCodeSelectValue)
    ) {
      this.setState({ zipCodeSelectValue: this.props.professionalEntity.zipCode ? this.props.professionalEntity.zipCode : null });
    }
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalEntity.street && this.state.cepRequestLogradouro === null)
    ) {
      this.setState({ cepRequestLogradouro: this.props.professionalEntity.street });
      // this.setState({ streetSelectValue: this.props.professionalEntity.street })
    }
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalEntity.street &&
        this.state.streetSelectValue === null &&
        this.props.professionalEntity.street !== this.state.streetSelectValue)
    ) {
      this.setState({ streetSelectValue: this.props.professionalEntity.street ? this.props.professionalEntity.street : null });
    }
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalEntity.complement &&
        this.state.complementSelectValue === null &&
        this.props.professionalEntity.complement !== this.state.complementSelectValue)
    ) {
      this.setState({ complementSelectValue: this.props.professionalEntity.complement ? this.props.professionalEntity.complement : null });
    }
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalEntity.number &&
        this.state.numberSelectValue === null &&
        this.props.professionalEntity.number !== this.state.numberSelectValue)
    ) {
      this.setState({ numberSelectValue: this.props.professionalEntity.number ? this.props.professionalEntity.number : null });
    }
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalEntity.neighborhood && this.state.cepRequestBairro === null)
    ) {
      this.setState({ cepRequestBairro: this.props.professionalEntity.neighborhood });
      // this.setState({ neighborhoodSelectValue: this.props.professionalEntity.neighborhood })
    }
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalEntity.neighborhood &&
        this.state.neighborhoodSelectValue === null &&
        this.props.professionalEntity.neighborhood !== this.state.neighborhoodSelectValue)
    ) {
      this.setState({
        neighborhoodSelectValue: this.props.professionalEntity.neighborhood ? this.props.professionalEntity.neighborhood : null,
      });
    }
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalEntity.city && this.state.cepRequestLocalidade === null)
    ) {
      this.setState({ cepRequestLocalidade: this.props.professionalEntity.city });
      // this.setState({ citySelectValue: this.props.professionalEntity.city })
    }
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalEntity.city &&
        this.state.citySelectValue === null &&
        this.props.professionalEntity.city !== this.state.citySelectValue)
    ) {
      this.setState({ citySelectValue: this.props.professionalEntity.city ? this.props.professionalEntity.city : null });
    }
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalEntity.uf && this.state.cepRequestUf === null)
    ) {
      this.setState({ cepRequestUf: this.props.professionalEntity.uf });
      // this.setState({ ufSelectValue: this.props.professionalEntity.uf })
    }
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalEntity.uf &&
        this.state.ufSelectValue === null &&
        this.props.professionalEntity.uf !== this.state.ufSelectValue)
    ) {
      this.setState({ ufSelectValue: this.props.professionalEntity.uf ? this.props.professionalEntity.uf : null });
    }
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalEntity.sex &&
        this.state.sexSelectValue === null &&
        this.props.professionalEntity.sex !== this.state.sexSelectValue)
    ) {
      this.setState({ sexSelectValue: this.props.professionalEntity.sex ? this.props.professionalEntity.sex : null });
    }
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalEntity.birthDate &&
        this.state.birthDateSelectValue === null &&
        this.props.professionalEntity.birthDate !== this.state.birthDateSelectValue)
    ) {
      this.setState({ birthDateSelectValue: this.props.professionalEntity.birthDate ? this.props.professionalEntity.birthDate : null });
    }
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalEntity.bank &&
        this.state.bankSelectValue === null &&
        this.props.professionalEntity.bank !== this.state.bankSelectValue)
    ) {
      this.setState({ bankSelectValue: this.props.professionalEntity.bank ? this.props.professionalEntity.bank : null });
    }
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalEntity.agency &&
        this.state.agencySelectValue === null &&
        this.props.professionalEntity.agency !== this.state.agencySelectValue)
    ) {
      this.setState({ agencySelectValue: this.props.professionalEntity.agency ? this.props.professionalEntity.agency : null });
    }
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalEntity.bankAccount &&
        this.state.bankAccountSelectValue === null &&
        this.props.professionalEntity.bankAccount !== this.state.bankAccountSelectValue)
    ) {
      this.setState({
        bankAccountSelectValue: this.props.professionalEntity.bankAccount ? this.props.professionalEntity.bankAccount : null,
      });
    }
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalEntity.type &&
        this.state.typeSelectValue === null &&
        this.props.professionalEntity.type !== this.state.typeSelectValue)
    ) {
      this.setState({ typeSelectValue: this.props.professionalEntity.type ? this.props.professionalEntity.type : null });
    }
    if (
      (prevProps.professionalEntity && prevProps.professionalEntity.id !== this.props.professionalEntity.id) ||
      (this.props.professionalEntity.professionalComplexity &&
        this.state.professionalComplexitySelectValue === null &&
        this.props.professionalEntity.professionalComplexity !== this.state.professionalComplexitySelectValue)
    ) {
      this.setState({
        professionalComplexitySelectValue: this.props.professionalEntity.professionalComplexity
          ? this.props.professionalEntity.professionalComplexity
          : null,
      });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  UNSAFE_componentWillMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params['id']);
    }
  }

  onBlobChange = (isAnImage, name, fileInput) => event => {
    const fileName = event.target.files[0].name;
    setFileData(event, (contentType, data) => this.props.setBlob(name, data, contentType, fileName), isAnImage);
    const varAux = {};
    varAux[name + 'SelectValue'] = fileName;
    this.setState(varAux);
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined);
  };
  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = offset !== null ? '&offset=' + offset : '';
    Object.keys(fieldsBase).map(key => {
      url += key !== 'offset' ? '&' + key + '=' + fieldsBase[key] : '';
    });
    return url;
  };
  saveEntity = (event: any, errors: any, values: any) => {
    if (!this.state.nameSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.professional.name') }));
      return false;
    }

    if (!this.state.cepRequestCep) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.professional.zipCode') }));
      return false;
    }

    if (!this.state.numberSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.professional.number') }));
      return false;
    }

    if (!this.state.agencySelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.professional.agency') }));
      return false;
    }

    if (!this.state.bankAccountSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.professional.bankAccount') }));
      return false;
    }

    const selectFieldsList = ['name', 'category.name', 'specialties.name', 'register', 'city', 'uf', 'status'];

    if (!this.state.cepRequestCep) {
      errors.push(translate('entity.validation.required'));
    }

    if (errors.length === 0) {
      const { professionalEntity } = this.props;
      const _entity = {
        ...professionalEntity,
        // ...values,

        category: getFormFromSelect(this.state.categorySelectValue, 'many-to-one'), // many-to-one - otherSide

        specialties: getFormFromSelect(this.state.specialtySelectValue, 'many-to-many'), // many-to-many - ownerSide

        professionalDevices: getFormFromSelect(this.state.professionalDeviceSelectValue, 'many-to-many'), // many-to-many - ownerSide

        professionalAdvice: getFormFromSelect(this.state.professionalAdviceSelectValue, 'many-to-one'), // many-to-one - otherSide

        professionalContractionType: getFormFromSelect(this.state.professionalContractionTypeSelectValue, 'many-to-one'), // many-to-one - otherSide

        name: this.state.nameSelectValue,
        rg: this.state.rgSelectValue,
        cpf: this.state.cpfSelectValue,
        expiredDate: this.state.expiredDateSelectValue,
        experienceTime: this.state.experienceTimeSelectValue === null ? null : this.state.experienceTimeSelectValue,
        company: this.state.companySelectValue,
        cnpj: this.state.cnpjSelectValue,
        observation: this.state.observationSelectValue,
        source: this.state.sourceSelectValue,
        register: this.state.registerSelectValue,
        status: this.state.statusSelectValue === null ? 'AGUARDANDOATIVACAO' : this.state.statusSelectValue,
        zipCode: this.state.cepRequestCep,
        street: this.state.cepRequestLogradouro,
        complement: this.state.complementSelectValue,
        number: this.state.numberSelectValue,
        neighborhood: this.state.cepRequestBairro,
        city: this.state.cepRequestLocalidade,
        uf: this.state.cepRequestUf,
        sex: this.state.sexSelectValue === null ? 'MASCULINO' : this.state.sexSelectValue,
        birthDate: this.state.birthDateSelectValue,
        bank: this.state.bankSelectValue === null ? null : this.state.bankSelectValue,
        agency: this.state.agencySelectValue,
        bankAccount: this.state.bankAccountSelectValue,
        type: this.state.typeSelectValue === null ? null : this.state.typeSelectValue,
        professionalComplexity: this.state.professionalComplexitySelectValue === null ? null : this.state.professionalComplexitySelectValue,
      };

      const entity = _entity;

      const {
        specialtyCategoryId,
        specialtyId,
        name,
        status,
        professionalComplexity,
        uf,
        city,
        extraFilters,
        activePage,
        itemsPerPage,
        sort,
        order,
      } = this.state.fieldsBase;

      if (this.state.isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              getFilterFromSelect(specialtyCategoryId, 'many-to-many'),
              getFilterFromSelect(specialtyId, 'many-to-many'),
              name,
              status,
              professionalComplexity,
              uf,
              city,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              getFilterFromSelect(specialtyCategoryId, 'many-to-many'),
              getFilterFromSelect(specialtyId, 'many-to-many'),
              name,
              status,
              professionalComplexity,
              uf,
              city,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/professional?' + getEntityFiltersURL(this.state.fieldsBase));
  };

  getFullEntityFromState(v) {
    return {
      id: v.id,
      name: this.state.nameSelectValue,
      rg: this.state.rgSelectValue,
      cpf: this.state.cpfSelectValue,
      expiredDate: this.state.expiredDateSelectValue,
      experienceTime: this.state.experienceTimeSelectValue,
      company: this.state.companySelectValue,
      cnpj: this.state.cnpjSelectValue,
      observation: this.state.observationSelectValue,
      source: this.state.sourceSelectValue,
      register: this.state.registerSelectValue,
      status: this.state.statusSelectValue,
      zipCode: this.state.zipCodeSelectValue,
      street: this.state.streetSelectValue,
      complement: this.state.complementSelectValue,
      number: this.state.numberSelectValue,
      neighborhood: this.state.neighborhoodSelectValue,
      city: this.state.citySelectValue,
      uf: this.state.ufSelectValue,
      sex: this.state.sexSelectValue,
      birthDate: this.state.birthDateSelectValue,
      bank: this.state.bankSelectValue,
      agency: this.state.agencySelectValue,
      bankAccount: this.state.bankAccountSelectValue,
      type: this.state.typeSelectValue,
      professionalComplexity: this.state.professionalComplexitySelectValue,
      user: this.state.userSelectValue,
      padAttendance: this.state.padAttendanceSelectValue,
      professionalContact: this.state.professionalContactSelectValue,
      professionalStatusHistory: this.state.professionalStatusHistorySelectValue,
      patientNursingPrescription: this.state.patientNursingPrescriptionSelectValue,
      professionalFile: this.state.professionalFileSelectValue,
      professionalOccupationArea: this.state.professionalOccupationAreaSelectValue,
      push: this.state.pushSelectValue,
      professionalDevice: this.state.professionalDeviceSelectValue,
      specialty: this.state.specialtySelectValue,
      professionalContractionType: this.state.professionalContractionTypeSelectValue,
      levelGroup: this.state.levelGroupSelectValue,
      professionalAdvice: this.state.professionalAdviceSelectValue,
      category: this.state.categorySelectValue,
      patientBlocked: this.state.patientBlockedSelectValue,
      patientPreferred: this.state.patientPreferredSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    const userEntity = v.user;

    const padAttendanceEntity = v.padAttendance;

    const professionalContactEntity = v.professionalContact;

    const professionalStatusHistoryEntity = v.professionalStatusHistory;

    const patientNursingPrescriptionEntity = v.patientNursingPrescription;

    const professionalFileEntity = v.professionalFile;

    const professionalOccupationAreaEntity = v.professionalOccupationArea;

    const pushEntity = v.push;

    const professionalDeviceEntity = this.props.professionalDevices.filter(x => x.professionals.map(x1 => x1.id).includes(v.id)).pop();

    const specialtyEntity = this.props.specialties.filter(x => x.professionals.map(x1 => x1.id).includes(v.id)).pop();

    const professionalContractionTypeEntity = v.professionalContractionType;

    const levelGroupEntity = v.levelGroup;

    const professionalAdviceEntity = v.professionalAdvice;

    const categoryEntity = v.category;

    const patientBlockedEntity = this.props.patients.filter(x => x.professionalBlockeds.map(x1 => x1.id).includes(v.id)).pop();

    const patientPreferredEntity = this.props.patients.filter(x => x.professionalPreferreds.map(x1 => x1.id).includes(v.id)).pop();

    return {
      nameSelectValue: v.name,
      rgSelectValue: v.rg,
      cpfSelectValue: v.cpf,
      expiredDateSelectValue: v.expiredDate,
      experienceTimeSelectValue: v.experienceTime,
      companySelectValue: v.company,
      cnpjSelectValue: v.cnpj,
      observationSelectValue: v.observation,
      sourceSelectValue: v.source,
      registerSelectValue: v.register,
      statusSelectValue: v.status,
      zipCodeSelectValue: v.zipCode,
      streetSelectValue: v.street,
      complementSelectValue: v.complement,
      numberSelectValue: v.number,
      neighborhoodSelectValue: v.neighborhood,
      citySelectValue: v.city,
      ufSelectValue: v.uf,
      sexSelectValue: v.sex,
      birthDateSelectValue: v.birthDate,
      bankSelectValue: v.bank,
      agencySelectValue: v.agency,
      bankAccountSelectValue: v.bankAccount,
      typeSelectValue: v.type,
      professionalComplexitySelectValue: v.professionalComplexity,
      userSelectValue: userEntity ? userEntity['id'] : null,
      padAttendanceSelectValue: padAttendanceEntity ? padAttendanceEntity['id'] : null,
      professionalContactSelectValue: professionalContactEntity ? professionalContactEntity['id'] : null,
      professionalStatusHistorySelectValue: professionalStatusHistoryEntity ? professionalStatusHistoryEntity['id'] : null,
      patientNursingPrescriptionSelectValue: patientNursingPrescriptionEntity ? patientNursingPrescriptionEntity['id'] : null,
      professionalFileSelectValue: professionalFileEntity ? professionalFileEntity['id'] : null,
      professionalOccupationAreaSelectValue: professionalOccupationAreaEntity ? professionalOccupationAreaEntity['id'] : null,
      pushSelectValue: pushEntity ? pushEntity['id'] : null,
      professionalDeviceSelectValue: professionalDeviceEntity ? professionalDeviceEntity['id'] : null,
      specialtySelectValue: specialtyEntity ? specialtyEntity['id'] : null,
      professionalContractionTypeSelectValue: professionalContractionTypeEntity ? professionalContractionTypeEntity['id'] : null,
      levelGroupSelectValue: levelGroupEntity ? levelGroupEntity['id'] : null,
      professionalAdviceSelectValue: professionalAdviceEntity ? professionalAdviceEntity['id'] : null,
      categorySelectValue: categoryEntity ? categoryEntity['id'] : null,
      patientBlockedSelectValue: patientBlockedEntity ? patientBlockedEntity['id'] : null,
      patientPreferredSelectValue: patientPreferredEntity ? patientPreferredEntity['id'] : null,
    };
  }

  renderHeader() {
    const {
      professionalEntity,
      users,
      padAttendances,
      professionalContacts,
      professionalStatusHistories,
      patientNursingPrescriptions,
      professionalFiles,
      professionalOccupationAreas,
      pushes,
      professionalDevices,
      specialties,
      professionalContractionTypes,
      levelGroups,
      professionalAdvices,
      categories,
      patients,
      loading,
      updating,
    } = this.props;
    const { isNew } = this.state;

    const { observation } = professionalEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.professional.home.createLabel">Create a Professional</Translate>
            ) : (
              <Translate contentKey="generadorApp.professional.home.editLabel">Edit a Professional</Translate>
            )}
          </span>

          <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.professional.btnSave">Save</Translate>
          </Button>

          {!this.state.isNew ? (
            <>
              <Button
                color="primary"
                id="next-entity"
                disabled={updating}
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.specialtyCategoryId,
                    this.state.fieldsBase.specialtyId,
                    this.state.fieldsBase.name,
                    this.state.fieldsBase.status,
                    this.state.fieldsBase.professionalComplexity,
                    this.state.fieldsBase.uf,
                    this.state.fieldsBase.city,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 + 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 + 1 },
                    });
                    this.props.history.push(
                      '/professional/' + myresult.value.data[0]['id'] + '/edit?' + this.getFiltersURL(this.state.fieldsBase.offset * 1 + 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.last.item', { fieldName: translate('generadorApp.professional.detail.title') }));
                  }
                }}
                className="float-right jh-create-entity"
              >
                {'>>'}
              </Button>

              <Button
                color="primary"
                id="prev-entity"
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.specialtyCategoryId,
                    this.state.fieldsBase.specialtyId,
                    this.state.fieldsBase.name,
                    this.state.fieldsBase.status,
                    this.state.fieldsBase.professionalComplexity,
                    this.state.fieldsBase.uf,
                    this.state.fieldsBase.city,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 - 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 - 1 },
                    });
                    this.props.history.push(
                      '/professional/' + myresult.value.data[0]['id'] + '/edit?' + this.getFiltersURL(this.state.fieldsBase.offset * 1 - 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.first.item', { fieldName: translate('generadorApp.professional.detail.title') }));
                  }
                }}
                disabled={updating}
                className="float-right jh-create-entity"
              >
                {'<<'}
              </Button>
            </>
          ) : (
            <> </>
          )}

          <Button
            tag={Link}
            id="cancel-save"
            to={'/professional?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.professional.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={'/professional'}>
              <Translate contentKey="generadorApp.professional.home.title">Professionals</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.professional.detail.title">Professional edit</Translate>
          </li>
        </ol>

        {!isNew ? (
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === 0 })}
                onClick={() => {
                  this.tabsNext(0);
                }}
              >
                <span className="d-sm-none">
                  <Translate contentKey="generadorApp.professional.PersonalInformation">PersonalInformation</Translate>
                </span>
                <span className="d-sm-block d-none">
                  <Translate contentKey="generadorApp.professional.PersonalInformation">PersonalInformation</Translate>
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === 1 })}
                onClick={() => {
                  this.tabsNext(1);
                }}
              >
                <span className="d-sm-none">
                  <Translate contentKey="generadorApp.professional.InformationResidential">InformationResidential</Translate>
                </span>
                <span className="d-sm-block d-none">
                  <Translate contentKey="generadorApp.professional.InformationResidential">InformationResidential</Translate>
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === 2 })}
                onClick={() => {
                  this.tabsNext(2);
                }}
              >
                <span className="d-sm-none">
                  <Translate contentKey="generadorApp.professional.ProfessionalInformation">ProfessionalInformation</Translate>
                </span>
                <span className="d-sm-block d-none">
                  <Translate contentKey="generadorApp.professional.ProfessionalInformation">ProfessionalInformation</Translate>
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === 3 })}
                onClick={() => {
                  this.tabsNext(3);
                }}
              >
                <span className="d-sm-none">
                  <Translate contentKey="generadorApp.professional.AccountInformation">AccountInformation</Translate>
                </span>
                <span className="d-sm-block d-none">
                  <Translate contentKey="generadorApp.professional.AccountInformation">AccountInformation</Translate>
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === 4 })}
                onClick={() => {
                  this.tabsNext(4);
                }}
              >
                <span className="d-sm-none">
                  <Translate contentKey="generadorApp.professional.OtherInformation">OtherInformation</Translate>
                </span>
                <span className="d-sm-block d-none">
                  <Translate contentKey="generadorApp.professional.OtherInformation">OtherInformation</Translate>
                </span>
              </NavLink>
            </NavItem>
          </Nav>
        ) : null}
      </>
    );
  }
  renderBody() {
    const {
      professionalEntity,
      users,
      padAttendances,
      professionalContacts,
      professionalStatusHistories,
      patientNursingPrescriptions,
      professionalFiles,
      professionalOccupationAreas,
      pushes,
      professionalDevices,
      specialties,
      professionalContractionTypes,
      levelGroups,
      professionalAdvices,
      categories,
      patients,
      loading,
      updating,
    } = this.props;
    const { isNew } = this.state;

    const { observation } = professionalEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const fullEntityEmbebed = this.getFullEntityFromState(professionalEntity);

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="professional-id">
                  <Translate contentKey="generadorApp.professional.field_id">ID</Translate>
                </Label>
                </Col> */}
                      <Col md="12">
                        <AvInput id="professional-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId={0}>
                    {isNew ? (
                      <Row className="justify-content-center mb-3">
                        <Col md="12">
                          <h2 id="generadorApp.professional.home.formTabs_28">
                            <Translate contentKey="generadorApp.professional.formTabs.PersonalInformation">PersonalInformation</Translate>
                          </h2>
                        </Col>
                      </Row>
                    ) : null}

                    <Row>
                      {baseFilters !== 'name' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="nameLabel" for="professional-name">
                                  <Translate contentKey="generadorApp.professional.name">Name</Translate>(*)
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                                  value={this.state.nameSelectValue ? this.state.nameSelectValue : ''}
                                  id="professional-name"
                                  type="text"
                                  name="name"
                                  className={'form-control'}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                          type="hidden"
                          name="name"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'rg' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="rgLabel" for="professional-rg">
                                  <Translate contentKey="generadorApp.professional.rg">RG</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  onChange={evt => this.setState({ rgSelectValue: evt.target.value })}
                                  value={this.state.rgSelectValue ? this.state.rgSelectValue : ''}
                                  id="professional-rg"
                                  type="text"
                                  name="rg"
                                  className={'form-control'}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ rgSelectValue: evt.target.value })}
                          type="hidden"
                          name="rg"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'cpf' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="cpfLabel" for="professional-cpf">
                                  <Translate contentKey="generadorApp.professional.cpf">CPF</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <InputMask
                                  onChange={evt => this.setState({ cpfSelectValue: evt.target.value })}
                                  value={this.state.cpfSelectValue ? this.state.cpfSelectValue : ''}
                                  id="professional-cpf"
                                  type="text"
                                  name="cpf"
                                  className={'form-control'}
                                  mask="999.999.999-99"
                                  tag={InputMask}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ cpfSelectValue: evt.target.value })}
                          type="hidden"
                          name="cpf"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'sex' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="sexLabel" for="professional-sex">
                                  <Translate contentKey="generadorApp.professional.sex">Sex</Translate>(*)
                                </Label>
                              </Col>
                              <Col md="12">
                                <Select
                                  id="professional-sex"
                                  className={'css-select-control'}
                                  value={
                                    this.state.sexSelectValue
                                      ? {
                                          value: this.state.sexSelectValue,
                                          label: translate('generadorApp.Sex.' + this.state.sexSelectValue),
                                        }
                                      : { value: '', label: translate('generadorApp.professional.sex') }
                                  }
                                  options={[
                                    { value: 'MASCULINO', label: translate('generadorApp.Sex.MASCULINO') },
                                    { value: 'FEMININO', label: translate('generadorApp.Sex.FEMININO') },
                                  ]}
                                  onChange={(options: any) => this.setState({ sexSelectValue: options['value'] })}
                                  name="sex"
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ sexSelectValue: evt.target.value })}
                          type="hidden"
                          name="sex"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'birthDate' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="birthDateLabel" for="professional-birthDate">
                                  <Translate contentKey="generadorApp.professional.birthDate">Birth Date</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <DatePicker
                                  autoComplete="off"
                                  onChange={value => this.setState({ birthDateSelectValue: value })}
                                  id="birthDate"
                                  validate={{}}
                                  className="form-control react-datepicker"
                                  name="birthDate"
                                  dateFormat="dd/MM/yyyy"
                                  selected={this.state.birthDateSelectValue ? moment(this.state.birthDateSelectValue).toDate() : null}
                                  locale="pt"
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ birthDateSelectValue: evt.target.value })}
                          type="hidden"
                          name="birthDate"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}
                    </Row>

                    {isNew ? (
                      <Button
                        color="primary"
                        className={'float-right jh-create-entity'}
                        onClick={() => {
                          this.tabsNext(1, 0);
                        }}
                      >
                        <span className="d-sm-none">
                          <Translate contentKey="generadorApp.professional.formTabs.btnInformationResidential">
                            InformationResidential
                          </Translate>
                        </span>
                        <span className="d-sm-block d-none">
                          <Translate contentKey="generadorApp.professional.formTabs.btnInformationResidential">
                            InformationResidential
                          </Translate>
                          &nbsp;
                          <FontAwesomeIcon icon="arrow-right" />
                        </span>
                      </Button>
                    ) : null}
                  </TabPane>
                  <TabPane tabId={1}>
                    {isNew ? (
                      <Row className="justify-content-center mb-3">
                        <Col md="12">
                          <h2 id="generadorApp.professional.home.formTabs_28">
                            <Translate contentKey="generadorApp.professional.formTabs.InformationResidential">
                              InformationResidential
                            </Translate>
                          </h2>
                        </Col>
                      </Row>
                    ) : null}

                    <Row>
                      {baseFilters !== 'zipCode' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label
                                  className={'mt-2' + (this.state.cepRequestCep === '' ? ' text-danger' : '')}
                                  id="zipCodeLabel"
                                  for="professional-zipCode"
                                >
                                  <Translate contentKey="generadorApp.professional.zipCode">Zip Code</Translate>(*)
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  id="professional-zipCode"
                                  type="text"
                                  name="zipCode"
                                  className={
                                    'form-control' +
                                    (this.state.cepRequestCep === '' ? ' is-touched is-pristine av-invalid is-invalid' : '')
                                  }
                                  value={this.state.cepRequestCep}
                                  onBlur={inputVal => {
                                    viacepRequest(inputVal.target.value, (cepRequest: any) => {
                                      this.setState(cepRequest);
                                    });
                                    this.setState({ zipCodeSelectValue: inputVal.target.value });
                                    this.setState({ cepRequestCep: inputVal.target.value ? inputVal.target.value : '' });
                                  }}
                                  onChange={inputVal => {
                                    this.setState({ cepRequestCep: inputVal.target.value });
                                    this.setState({ zipCodeSelectValue: inputVal.target.value });
                                  }}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ zipCodeSelectValue: evt.target.value })}
                          type="hidden"
                          name="zipCode"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'street' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className={'mt-2'} id="streetLabel" for="professional-street">
                                  <Translate contentKey="generadorApp.professional.street">Street</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  id="professional-street"
                                  type="text"
                                  name="street"
                                  className={'form-control'}
                                  value={this.state.cepRequestLogradouro}
                                  onBlur={inputVal =>
                                    this.setState({ cepRequestLogradouro: inputVal.target.value ? inputVal.target.value : '' })
                                  }
                                  onChange={inputVal => {
                                    this.setState({ cepRequestLogradouro: inputVal.target.value });
                                    this.setState({ streetSelectValue: inputVal.target.value });
                                  }}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ streetSelectValue: evt.target.value })}
                          type="hidden"
                          name="street"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'complement' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="complementLabel" for="professional-complement">
                                  <Translate contentKey="generadorApp.professional.complement">Complement</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  onChange={evt => this.setState({ complementSelectValue: evt.target.value })}
                                  value={this.state.complementSelectValue ? this.state.complementSelectValue : ''}
                                  id="professional-complement"
                                  type="text"
                                  name="complement"
                                  className={'form-control'}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ complementSelectValue: evt.target.value })}
                          type="hidden"
                          name="complement"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'number' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="numberLabel" for="professional-number">
                                  <Translate contentKey="generadorApp.professional.number">Number</Translate>(*)
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  onChange={evt => this.setState({ numberSelectValue: evt.target.value })}
                                  value={this.state.numberSelectValue ? this.state.numberSelectValue : ''}
                                  id="professional-number"
                                  type="text"
                                  name="number"
                                  className={'form-control'}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ numberSelectValue: evt.target.value })}
                          type="hidden"
                          name="number"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'neighborhood' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className={'mt-2'} id="neighborhoodLabel" for="professional-neighborhood">
                                  <Translate contentKey="generadorApp.professional.neighborhood">Neighborhood</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  id="professional-neighborhood"
                                  type="text"
                                  name="neighborhood"
                                  className={'form-control'}
                                  value={this.state.cepRequestBairro}
                                  onBlur={inputVal =>
                                    this.setState({ cepRequestBairro: inputVal.target.value ? inputVal.target.value : '' })
                                  }
                                  onChange={inputVal => {
                                    this.setState({ cepRequestBairro: inputVal.target.value });
                                    this.setState({ neighborhoodSelectValue: inputVal.target.value });
                                  }}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ neighborhoodSelectValue: evt.target.value })}
                          type="hidden"
                          name="neighborhood"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'city' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className={'mt-2'} id="cityLabel" for="professional-city">
                                  <Translate contentKey="generadorApp.professional.city">City</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  id="professional-city"
                                  type="text"
                                  name="city"
                                  className={'form-control'}
                                  value={this.state.cepRequestLocalidade}
                                  onBlur={inputVal =>
                                    this.setState({ cepRequestLocalidade: inputVal.target.value ? inputVal.target.value : '' })
                                  }
                                  onChange={inputVal => {
                                    this.setState({ cepRequestLocalidade: inputVal.target.value });
                                    this.setState({ citySelectValue: inputVal.target.value });
                                  }}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ citySelectValue: evt.target.value })}
                          type="hidden"
                          name="city"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'uf' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className={'mt-2'} id="ufLabel" for="professional-uf">
                                  <Translate contentKey="generadorApp.professional.uf">UF</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  id="professional-uf"
                                  type="text"
                                  name="uf"
                                  className={'form-control'}
                                  value={this.state.cepRequestUf}
                                  onBlur={inputVal => this.setState({ cepRequestUf: inputVal.target.value ? inputVal.target.value : '' })}
                                  onChange={inputVal => {
                                    this.setState({ cepRequestUf: inputVal.target.value });
                                    this.setState({ ufSelectValue: inputVal.target.value });
                                  }}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ ufSelectValue: evt.target.value })}
                          type="hidden"
                          name="uf"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}
                    </Row>

                    {isNew ? (
                      <Button
                        color="primary"
                        className={'float-right jh-create-entity'}
                        onClick={() => {
                          this.tabsNext(2, 1);
                        }}
                      >
                        <span className="d-sm-none">
                          <Translate contentKey="generadorApp.professional.formTabs.btnProfessionalInformation">
                            ProfessionalInformation
                          </Translate>
                        </span>
                        <span className="d-sm-block d-none">
                          <Translate contentKey="generadorApp.professional.formTabs.btnProfessionalInformation">
                            ProfessionalInformation
                          </Translate>
                          &nbsp;
                          <FontAwesomeIcon icon="arrow-right" />
                        </span>
                      </Button>
                    ) : null}

                    {isNew ? (
                      <Button
                        color="info"
                        className={'float-right jh-create-entity'}
                        onClick={() => {
                          this.tabsNext(0);
                        }}
                      >
                        <span className="d-sm-none">
                          <Translate contentKey="generadorApp.professional.formTabs.btnPersonalInformation">PersonalInformation</Translate>
                        </span>
                        <span className="d-sm-block d-none">
                          <FontAwesomeIcon icon="arrow-left" />
                          &nbsp;
                          <Translate contentKey="generadorApp.professional.formTabs.btnPersonalInformation">PersonalInformation</Translate>
                        </span>
                      </Button>
                    ) : null}
                  </TabPane>
                  <TabPane tabId={2}>
                    {isNew ? (
                      <Row className="justify-content-center mb-3">
                        <Col md="12">
                          <h2 id="generadorApp.professional.home.formTabs_28">
                            <Translate contentKey="generadorApp.professional.formTabs.ProfessionalInformation">
                              ProfessionalInformation
                            </Translate>
                          </h2>
                        </Col>
                      </Row>
                    ) : null}

                    <Row>
                      {baseFilters !== 'company' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="companyLabel" for="professional-company">
                                  <Translate contentKey="generadorApp.professional.company">Company</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  onChange={evt => this.setState({ companySelectValue: evt.target.value })}
                                  value={this.state.companySelectValue ? this.state.companySelectValue : ''}
                                  id="professional-company"
                                  type="text"
                                  name="company"
                                  className={'form-control'}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ companySelectValue: evt.target.value })}
                          type="hidden"
                          name="company"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}
                      {baseFilters !== 'cnpj' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="cnpjLabel" for="professional-cnpj">
                                  <Translate contentKey="generadorApp.professional.cnpj">CNPJ</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <InputMask
                                  onChange={evt => this.setState({ cnpjSelectValue: evt.target.value })}
                                  value={this.state.cnpjSelectValue ? this.state.cnpjSelectValue : ''}
                                  id="professional-cnpj"
                                  type="text"
                                  name="cnpj"
                                  className={'form-control'}
                                  mask="99.999.999/9999-99"
                                  tag={InputMask}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ cnpjSelectValue: evt.target.value })}
                          type="hidden"
                          name="cnpj"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}
                      {baseFilters !== 'category' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" for="professional-category">
                                  <Translate contentKey="generadorApp.professional.category">Category</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <SelectAsync
                                  id="professional-category"
                                  name={'category'}
                                  className={'css-select-control'}
                                  data-type-rel="many-to-one-other-side"
                                  value={this.state.categorySelectValue ? this.state.categorySelectValue : ''}
                                  onChange={options =>
                                    this.setState({
                                      specialtySelectValue: null,
                                      specialtyStartSelectOptions: undefined,
                                      categorySelectValue: options,
                                    })
                                  }
                                  defaultOptions={this.state.categoryStartSelectOptions ? this.state.categoryStartSelectOptions : []}
                                  loadingMessage={input => translate('selectAsync.loadingMessage')}
                                  noOptionsMessage={input =>
                                    this.state.categoryStartSelectOptions === undefined
                                      ? translate('selectAsync.loadingMessage')
                                      : translate('selectAsync.noOptionsMessage')
                                  }
                                  onMenuOpen={async () => {
                                    if (this.state.categoryStartSelectOptions === undefined) {
                                      const result = await getListAxios(
                                        'categories',
                                        { 'levelGroup.sub.equals': 'ESPECIALIDADE' },
                                        0,
                                        100,
                                        'levelGroup,asc',
                                        'levelGroup.name,name'
                                      );
                                      this.setState({
                                        categoryStartSelectOptions: result.data
                                          ? result.data.map(p => ({
                                              ...p,
                                              value: p.id,
                                              label: showFieldsSelectAsync(p, 'levelGroup.name;name') || '',
                                            }))
                                          : [],
                                      });
                                    }
                                  }}
                                  loadOptions={async (inputValue, callback) => {
                                    const result = await getListAxios(
                                      'categories',
                                      { 'levelGroup.sub.equals': 'ESPECIALIDADE', 'name.contains': inputValue },
                                      0,
                                      100,
                                      'levelGroup,asc',
                                      'levelGroup.name,name'
                                    );
                                    callback(
                                      result.data
                                        ? result.data.map(p => ({
                                            ...p,
                                            value: p.id,
                                            label: showFieldsSelectAsync(p, 'levelGroup.name;name') || '',
                                          }))
                                        : []
                                    );
                                  }}
                                />
                              </Col>
                              <RenderModalSuperSelect
                                baseState={this.state}
                                setState={_state => this.setState(_state)}
                                entity={'category'}
                                listEntity={'categories'}
                                template={'levelGroup.name;name'.split(';')}
                                stateField={'categorySelectValue'}
                                multiple={false}
                                showFields={'levelGroup.name;name'.split(';')}
                                order={'levelGroup,asc'}
                                filtersBase={{ 'levelGroup.sub.equals': 'ESPECIALIDADE' }}
                              />
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          type="hidden"
                          name="category"
                          value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                        />
                      )}
                      {baseFilters !== 'specialty' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" for="professional-specialty">
                                  <Translate contentKey="generadorApp.professional.specialty">Specialty</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <SelectAsync
                                  isMulti
                                  id="professional-specialty"
                                  name={'specialty'}
                                  className={'css-select-control'}
                                  data-type-rel="many-to-many-owner-side"
                                  value={this.state.specialtySelectValue ? this.state.specialtySelectValue : ''}
                                  onChange={options => this.setState({ specialtySelectValue: options })}
                                  defaultOptions={this.state.specialtyStartSelectOptions ? this.state.specialtyStartSelectOptions : []}
                                  loadingMessage={input => translate('selectAsync.loadingMessage')}
                                  noOptionsMessage={input =>
                                    this.state.specialtyStartSelectOptions === undefined
                                      ? translate('selectAsync.loadingMessage')
                                      : translate('selectAsync.noOptionsMessage')
                                  }
                                  onMenuOpen={async () => {
                                    if (this.state.specialtyStartSelectOptions === undefined) {
                                      const result = await getListAxios(
                                        'specialties',
                                        { 'category.id.equals': this.state.categorySelectValue.value },
                                        0,
                                        100,
                                        'name,asc',
                                        'name'
                                      );
                                      this.setState({
                                        specialtyStartSelectOptions: result.data
                                          ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                          : [],
                                      });
                                    }
                                  }}
                                  loadOptions={async (inputValue, callback) => {
                                    const result = await getListAxios(
                                      'specialties',
                                      { 'category.id.equals': this.state.categorySelectValue.value, 'name.contains': inputValue },
                                      0,
                                      100,
                                      'name,asc',
                                      'name'
                                    );
                                    callback(
                                      result.data
                                        ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                        : []
                                    );
                                  }}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          type="hidden"
                          name="specialty"
                          value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                        />
                      )}
                      {baseFilters !== 'expiredDate' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="expiredDateLabel" for="professional-expiredDate">
                                  <Translate contentKey="generadorApp.professional.expiredDate">Expired Date</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <DatePicker
                                  autoComplete="off"
                                  onChange={value => this.setState({ expiredDateSelectValue: value })}
                                  id="expiredDate"
                                  validate={{}}
                                  className="form-control react-datepicker"
                                  name="expiredDate"
                                  dateFormat="dd/MM/yyyy"
                                  selected={this.state.expiredDateSelectValue ? moment(this.state.expiredDateSelectValue).toDate() : null}
                                  locale="pt"
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ expiredDateSelectValue: evt.target.value })}
                          type="hidden"
                          name="expiredDate"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}
                      {baseFilters !== 'experienceTime' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="experienceTimeLabel" for="professional-experienceTime">
                                  <Translate contentKey="generadorApp.professional.experienceTime">Experience Time</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <Select
                                  id="professional-experienceTime"
                                  className={'css-select-control'}
                                  value={
                                    this.state.experienceTimeSelectValue
                                      ? {
                                          value: this.state.experienceTimeSelectValue,
                                          label: translate('generadorApp.ExperienceTime.' + this.state.experienceTimeSelectValue),
                                        }
                                      : { value: '', label: translate('generadorApp.professional.experienceTime') }
                                  }
                                  options={[
                                    { value: 'ZEROADOISANOS', label: translate('generadorApp.ExperienceTime.ZEROADOISANOS') },
                                    { value: 'DOISACINCOANOS', label: translate('generadorApp.ExperienceTime.DOISACINCOANOS') },
                                    { value: 'MAISDECINCOANOS', label: translate('generadorApp.ExperienceTime.MAISDECINCOANOS') },
                                    { value: 'SEMEXPERIENCIA', label: translate('generadorApp.ExperienceTime.SEMEXPERIENCIA') },
                                  ]}
                                  onChange={(options: any) => this.setState({ experienceTimeSelectValue: options['value'] })}
                                  name="experienceTime"
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ experienceTimeSelectValue: evt.target.value })}
                          type="hidden"
                          name="experienceTime"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}
                      {baseFilters !== 'register' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="registerLabel" for="professional-register">
                                  <Translate contentKey="generadorApp.professional.register">Professional register</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  onChange={evt => this.setState({ registerSelectValue: evt.target.value })}
                                  value={this.state.registerSelectValue ? this.state.registerSelectValue : ''}
                                  id="professional-register"
                                  type="text"
                                  name="register"
                                  className={'form-control'}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ registerSelectValue: evt.target.value })}
                          type="hidden"
                          name="register"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}
                      {baseFilters !== 'professionalDevice' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" for="professional-professionalDevice">
                                  <Translate contentKey="generadorApp.professional.professionalDevice">Professional Device</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <SelectAsync
                                  isMulti
                                  id="professional-professionalDevice"
                                  name={'professionalDevice'}
                                  className={'css-select-control'}
                                  data-type-rel="many-to-many-owner-side"
                                  value={this.state.professionalDeviceSelectValue ? this.state.professionalDeviceSelectValue : ''}
                                  onChange={options => this.setState({ professionalDeviceSelectValue: options })}
                                  defaultOptions={
                                    this.state.professionalDeviceStartSelectOptions ? this.state.professionalDeviceStartSelectOptions : []
                                  }
                                  loadingMessage={input => translate('selectAsync.loadingMessage')}
                                  noOptionsMessage={input =>
                                    this.state.professionalDeviceStartSelectOptions === undefined
                                      ? translate('selectAsync.loadingMessage')
                                      : translate('selectAsync.noOptionsMessage')
                                  }
                                  onMenuOpen={async () => {
                                    if (this.state.professionalDeviceStartSelectOptions === undefined) {
                                      const result = await getListAxios('professional-devices', {}, 0, 100, 'name,asc', 'name');
                                      this.setState({
                                        professionalDeviceStartSelectOptions: result.data
                                          ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                          : [],
                                      });
                                    }
                                  }}
                                  loadOptions={async (inputValue, callback) => {
                                    const result = await getListAxios(
                                      'professional-devices',
                                      { 'name.contains': inputValue },
                                      0,
                                      100,
                                      'name,asc',
                                      'name'
                                    );
                                    callback(
                                      result.data
                                        ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                        : []
                                    );
                                  }}
                                />
                              </Col>
                              <RenderModalSuperSelect
                                baseState={this.state}
                                setState={_state => this.setState(_state)}
                                entity={'professionalDevice'}
                                listEntity={'professional-devices'}
                                template={'name'.split(';')}
                                stateField={'professionalDeviceSelectValue'}
                                multiple={true}
                                showFields={'name'.split(';')}
                                order={'name,asc'}
                                filtersBase={{}}
                              />
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          type="hidden"
                          name="professionalDevice"
                          value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                        />
                      )}
                      {baseFilters !== 'professionalComplexity' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label
                                  className="mt-2 label-single-line"
                                  id="professionalComplexityLabel"
                                  for="professional-professionalComplexity"
                                >
                                  <Translate contentKey="generadorApp.professional.professionalComplexity">Patient complexity</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <Select
                                  id="professional-professionalComplexity"
                                  className={'css-select-control'}
                                  value={
                                    this.state.professionalComplexitySelectValue
                                      ? {
                                          value: this.state.professionalComplexitySelectValue,
                                          label: translate(
                                            'generadorApp.ProfessionalComplexity.' + this.state.professionalComplexitySelectValue
                                          ),
                                        }
                                      : { value: '', label: translate('generadorApp.professional.professionalComplexity') }
                                  }
                                  options={[
                                    { value: 'BAIXA', label: translate('generadorApp.ProfessionalComplexity.BAIXA') },
                                    { value: 'MEDIA', label: translate('generadorApp.ProfessionalComplexity.MEDIA') },
                                    { value: 'ALTA', label: translate('generadorApp.ProfessionalComplexity.ALTA') },
                                    {
                                      value: 'VENTILACAOMECANICA',
                                      label: translate('generadorApp.ProfessionalComplexity.VENTILACAOMECANICA'),
                                    },
                                  ]}
                                  onChange={(options: any) => this.setState({ professionalComplexitySelectValue: options['value'] })}
                                  name="professionalComplexity"
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ professionalComplexitySelectValue: evt.target.value })}
                          type="hidden"
                          name="professionalComplexity"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}
                      {baseFilters !== 'professionalAdvice' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" for="professional-professionalAdvice">
                                  <Translate contentKey="generadorApp.professional.professionalAdvice">Professional Advice</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <SelectAsync
                                  id="professional-professionalAdvice"
                                  name={'professionalAdvice'}
                                  className={'css-select-control'}
                                  data-type-rel="many-to-one-other-side"
                                  value={this.state.professionalAdviceSelectValue ? this.state.professionalAdviceSelectValue : ''}
                                  onChange={options => this.setState({ professionalAdviceSelectValue: options })}
                                  defaultOptions={
                                    this.state.professionalAdviceStartSelectOptions ? this.state.professionalAdviceStartSelectOptions : []
                                  }
                                  loadingMessage={input => translate('selectAsync.loadingMessage')}
                                  noOptionsMessage={input =>
                                    this.state.professionalAdviceStartSelectOptions === undefined
                                      ? translate('selectAsync.loadingMessage')
                                      : translate('selectAsync.noOptionsMessage')
                                  }
                                  onMenuOpen={async () => {
                                    if (this.state.professionalAdviceStartSelectOptions === undefined) {
                                      const result = await getListAxios('professional-advices', {}, 0, 100, 'name,asc', 'name');
                                      this.setState({
                                        professionalAdviceStartSelectOptions: result.data
                                          ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                          : [],
                                      });
                                    }
                                  }}
                                  loadOptions={async (inputValue, callback) => {
                                    const result = await getListAxios(
                                      'professional-advices',
                                      { 'name.contains': inputValue },
                                      0,
                                      100,
                                      'name,asc',
                                      'name'
                                    );
                                    callback(
                                      result.data
                                        ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                        : []
                                    );
                                  }}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          type="hidden"
                          name="professionalAdvice"
                          value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                        />
                      )}
                      {baseFilters !== 'professionalContractionType' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" for="professional-professionalContractionType">
                                  <Translate contentKey="generadorApp.professional.professionalContractionType">
                                    Professional Contraction Type
                                  </Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <SelectAsync
                                  id="professional-professionalContractionType"
                                  name={'professionalContractionType'}
                                  className={'css-select-control'}
                                  data-type-rel="many-to-one-other-side"
                                  value={
                                    this.state.professionalContractionTypeSelectValue
                                      ? this.state.professionalContractionTypeSelectValue
                                      : ''
                                  }
                                  onChange={options => this.setState({ professionalContractionTypeSelectValue: options })}
                                  defaultOptions={
                                    this.state.professionalContractionTypeStartSelectOptions
                                      ? this.state.professionalContractionTypeStartSelectOptions
                                      : []
                                  }
                                  loadingMessage={input => translate('selectAsync.loadingMessage')}
                                  noOptionsMessage={input =>
                                    this.state.professionalContractionTypeStartSelectOptions === undefined
                                      ? translate('selectAsync.loadingMessage')
                                      : translate('selectAsync.noOptionsMessage')
                                  }
                                  onMenuOpen={async () => {
                                    if (this.state.professionalContractionTypeStartSelectOptions === undefined) {
                                      const result = await getListAxios('professional-contraction-types', {}, 0, 100, 'name,asc', 'name');
                                      this.setState({
                                        professionalContractionTypeStartSelectOptions: result.data
                                          ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                          : [],
                                      });
                                    }
                                  }}
                                  loadOptions={async (inputValue, callback) => {
                                    const result = await getListAxios(
                                      'professional-contraction-types',
                                      { 'name.contains': inputValue },
                                      0,
                                      100,
                                      'name,asc',
                                      'name'
                                    );
                                    callback(
                                      result.data
                                        ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                        : []
                                    );
                                  }}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          type="hidden"
                          name="professionalContractionType"
                          value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                        />
                      )}{' '}
                    </Row>

                    {isNew ? (
                      <Button
                        color="primary"
                        className={'float-right jh-create-entity'}
                        onClick={() => {
                          this.tabsNext(3, 2);
                        }}
                      >
                        <span className="d-sm-none">
                          <Translate contentKey="generadorApp.professional.formTabs.btnAccountInformation">AccountInformation</Translate>
                        </span>
                        <span className="d-sm-block d-none">
                          <Translate contentKey="generadorApp.professional.formTabs.btnAccountInformation">AccountInformation</Translate>
                          &nbsp;
                          <FontAwesomeIcon icon="arrow-right" />
                        </span>
                      </Button>
                    ) : null}

                    {isNew ? (
                      <Button
                        color="info"
                        className={'float-right jh-create-entity'}
                        onClick={() => {
                          this.tabsNext(1);
                        }}
                      >
                        <span className="d-sm-none">
                          <Translate contentKey="generadorApp.professional.formTabs.btnInformationResidential">
                            InformationResidential
                          </Translate>
                        </span>
                        <span className="d-sm-block d-none">
                          <FontAwesomeIcon icon="arrow-left" />
                          &nbsp;
                          <Translate contentKey="generadorApp.professional.formTabs.btnInformationResidential">
                            InformationResidential
                          </Translate>
                        </span>
                      </Button>
                    ) : null}
                  </TabPane>
                  <TabPane tabId={3}>
                    {isNew ? (
                      <Row className="justify-content-center mb-3">
                        <Col md="12">
                          <h2 id="generadorApp.professional.home.formTabs_28">
                            <Translate contentKey="generadorApp.professional.formTabs.AccountInformation">AccountInformation</Translate>
                          </h2>
                        </Col>
                      </Row>
                    ) : null}

                    <Row>
                      {baseFilters !== 'bank' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="bankLabel" for="professional-bank">
                                  <Translate contentKey="generadorApp.professional.bank">Bank</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <Select
                                  id="professional-bank"
                                  className={'css-select-control'}
                                  value={
                                    this.state.bankSelectValue
                                      ? {
                                          value: this.state.bankSelectValue,
                                          label: translate('generadorApp.BrazilBanks.' + this.state.bankSelectValue),
                                        }
                                      : { value: '', label: translate('generadorApp.professional.bank') }
                                  }
                                  options={[
                                    {
                                      value: 'BANCO_DO_BRASIL_SA_001',
                                      label: translate('generadorApp.BrazilBanks.BANCO_DO_BRASIL_SA_001'),
                                    },
                                    {
                                      value: 'BANCO_DA_AMAZONIA_SA_003',
                                      label: translate('generadorApp.BrazilBanks.BANCO_DA_AMAZONIA_SA_003'),
                                    },
                                    {
                                      value: 'BANCO_DO_NORDESTE_DO_BRASIL_SA_004',
                                      label: translate('generadorApp.BrazilBanks.BANCO_DO_NORDESTE_DO_BRASIL_SA_004'),
                                    },
                                    {
                                      value: 'BANCO_NACIONAL_DE_DESENVOLVIMENTO_ECONOMICO_E_SOCIAL_BNDES_007',
                                      label: translate(
                                        'generadorApp.BrazilBanks.BANCO_NACIONAL_DE_DESENVOLVIMENTO_ECONOMICO_E_SOCIAL_BNDES_007'
                                      ),
                                    },
                                    { value: 'CREDICOAMO_010', label: translate('generadorApp.BrazilBanks.CREDICOAMO_010') },
                                    {
                                      value: 'CSUISSE_HEDGING_GRIFFO_CV_SA_CREDIT_SUISSE__011',
                                      label: translate('generadorApp.BrazilBanks.CSUISSE_HEDGING_GRIFFO_CV_SA_CREDIT_SUISSE__011'),
                                    },
                                    { value: 'BANCO_INBURSA_SA_012', label: translate('generadorApp.BrazilBanks.BANCO_INBURSA_SA_012') },
                                    {
                                      value: 'NATIXIS_BRASIL_SA_BANCO_MULTIPLO_014',
                                      label: translate('generadorApp.BrazilBanks.NATIXIS_BRASIL_SA_BANCO_MULTIPLO_014'),
                                    },
                                    {
                                      value: 'CCM_DESP_TR_NS_SC_E_RS_016',
                                      label: translate('generadorApp.BrazilBanks.CCM_DESP_TR_NS_SC_E_RS_016'),
                                    },
                                    {
                                      value: 'BNY_MELLON_BANCO_SA_017',
                                      label: translate('generadorApp.BrazilBanks.BNY_MELLON_BANCO_SA_017'),
                                    },
                                    { value: 'BANCO_TRICURY_SA_018', label: translate('generadorApp.BrazilBanks.BANCO_TRICURY_SA_018') },
                                    {
                                      value: 'BANESTES_SA_BANCO_DO_ESTADO_DO_ESPIRITO_SANTO_021',
                                      label: translate('generadorApp.BrazilBanks.BANESTES_SA_BANCO_DO_ESTADO_DO_ESPIRITO_SANTO_021'),
                                    },
                                    { value: 'BANCO_BANDEPE_SA_024', label: translate('generadorApp.BrazilBanks.BANCO_BANDEPE_SA_024') },
                                    { value: 'BANCO_ALFA_SA_025', label: translate('generadorApp.BrazilBanks.BANCO_ALFA_SA_025') },
                                    {
                                      value: 'BANCO_ITAU_CONSIGNADO_SA_029',
                                      label: translate('generadorApp.BrazilBanks.BANCO_ITAU_CONSIGNADO_SA_029'),
                                    },
                                    {
                                      value: 'BANCO_SANTANDER_BRASIL_SA_033',
                                      label: translate('generadorApp.BrazilBanks.BANCO_SANTANDER_BRASIL_SA_033'),
                                    },
                                    {
                                      value: 'BANCO_BRADESCO_BBI_SA_036',
                                      label: translate('generadorApp.BrazilBanks.BANCO_BRADESCO_BBI_SA_036'),
                                    },
                                    {
                                      value: 'BANCO_DO_ESTADO_DO_PARA_SA_037',
                                      label: translate('generadorApp.BrazilBanks.BANCO_DO_ESTADO_DO_PARA_SA_037'),
                                    },
                                    { value: 'BANCO_CARGILL_SA_040', label: translate('generadorApp.BrazilBanks.BANCO_CARGILL_SA_040') },
                                    {
                                      value: 'BANCO_DO_ESTADO_DO_RIO_GRANDE_DO_SUL_SA_041',
                                      label: translate('generadorApp.BrazilBanks.BANCO_DO_ESTADO_DO_RIO_GRANDE_DO_SUL_SA_041'),
                                    },
                                    {
                                      value: 'BANCO_DO_ESTADO_DE_SERGIPE_SA_047',
                                      label: translate('generadorApp.BrazilBanks.BANCO_DO_ESTADO_DE_SERGIPE_SA_047'),
                                    },
                                    {
                                      value: 'BANCO_DE_DESENVOLVIMENTO_DO_ESPIRITO_SANTO_SA_051',
                                      label: translate('generadorApp.BrazilBanks.BANCO_DE_DESENVOLVIMENTO_DO_ESPIRITO_SANTO_SA_051'),
                                    },
                                    { value: 'CONFIDENCE_CC_SA_060', label: translate('generadorApp.BrazilBanks.CONFIDENCE_CC_SA_060') },
                                    {
                                      value: 'HIPERCARD_BANCO_MULTIPLO_SA_062',
                                      label: translate('generadorApp.BrazilBanks.HIPERCARD_BANCO_MULTIPLO_SA_062'),
                                    },
                                    {
                                      value: 'BANCO_BRADESCARD_SA_063',
                                      label: translate('generadorApp.BrazilBanks.BANCO_BRADESCARD_SA_063'),
                                    },
                                    {
                                      value: 'GOLDMAN_SACHS_DO_BRASIL_BANCO_MULTIPLO_SA_064',
                                      label: translate('generadorApp.BrazilBanks.GOLDMAN_SACHS_DO_BRASIL_BANCO_MULTIPLO_SA_064'),
                                    },
                                    {
                                      value: 'BANCO_ANDBANK_BRASIL_SA_065',
                                      label: translate('generadorApp.BrazilBanks.BANCO_ANDBANK_BRASIL_SA_065'),
                                    },
                                    {
                                      value: 'BANCO_MORGAN_STANLEY_SA_066',
                                      label: translate('generadorApp.BrazilBanks.BANCO_MORGAN_STANLEY_SA_066'),
                                    },
                                    { value: 'BANCO_CREFISA_SA_069', label: translate('generadorApp.BrazilBanks.BANCO_CREFISA_SA_069') },
                                    {
                                      value: 'BRB_BANCO_DE_BRASILIA_SA_070',
                                      label: translate('generadorApp.BrazilBanks.BRB_BANCO_DE_BRASILIA_SA_070'),
                                    },
                                    { value: 'BANCO_J_SAFRA_SA_074', label: translate('generadorApp.BrazilBanks.BANCO_J_SAFRA_SA_074') },
                                    { value: 'BANCO_ABN_AMRO_SA_075', label: translate('generadorApp.BrazilBanks.BANCO_ABN_AMRO_SA_075') },
                                    { value: 'BANCO_KDB_SA_076', label: translate('generadorApp.BrazilBanks.BANCO_KDB_SA_076') },
                                    { value: 'BANCO_INTER_SA_077', label: translate('generadorApp.BrazilBanks.BANCO_INTER_SA_077') },
                                    {
                                      value: 'HAITONG_BANCO_DE_INVESTIMENTO_DO_BRASIL_SA_078',
                                      label: translate('generadorApp.BrazilBanks.HAITONG_BANCO_DE_INVESTIMENTO_DO_BRASIL_SA_078'),
                                    },
                                    {
                                      value: 'BANCO_ORIGINAL_DO_AGRONEGOCIO_SA_079',
                                      label: translate('generadorApp.BrazilBanks.BANCO_ORIGINAL_DO_AGRONEGOCIO_SA_079'),
                                    },
                                    { value: 'B_T_CC_LTDA_080', label: translate('generadorApp.BrazilBanks.B_T_CC_LTDA_080') },
                                    { value: 'BANCOSEGURO_SA_081', label: translate('generadorApp.BrazilBanks.BANCOSEGURO_SA_081') },
                                    { value: 'BANCO_TOPAZIO_SA_082', label: translate('generadorApp.BrazilBanks.BANCO_TOPAZIO_SA_082') },
                                    {
                                      value: 'BANCO_DA_CHINA_BRASIL_SA_083',
                                      label: translate('generadorApp.BrazilBanks.BANCO_DA_CHINA_BRASIL_SA_083'),
                                    },
                                    {
                                      value:
                                        'UNIPRIME_NORTE_DO_PARANA_COOP_DE_ECONOMIA_ECREDITO_MUTUO_DOS_MEDICOS_PROFISSIONAIS_DAS_CIENCIAS_084',
                                      label: translate(
                                        'generadorApp.BrazilBanks.UNIPRIME_NORTE_DO_PARANA_COOP_DE_ECONOMIA_ECREDITO_MUTUO_DOS_MEDICOS_PROFISSIONAIS_DAS_CIENCIAS_084'
                                      ),
                                    },
                                    {
                                      value: 'COOP_CENTRAL_AILOS_085',
                                      label: translate('generadorApp.BrazilBanks.COOP_CENTRAL_AILOS_085'),
                                    },
                                    { value: 'CCR_REG_MOGIANA_089', label: translate('generadorApp.BrazilBanks.CCR_REG_MOGIANA_089') },
                                    {
                                      value: 'UNICRED_CENTRAL_RS_091',
                                      label: translate('generadorApp.BrazilBanks.UNICRED_CENTRAL_RS_091'),
                                    },
                                    {
                                      value: 'BRICKELL_SA_CREDITO_FINANCIAMENTO_E_INVESTIMENTO_092',
                                      label: translate('generadorApp.BrazilBanks.BRICKELL_SA_CREDITO_FINANCIAMENTO_E_INVESTIMENTO_092'),
                                    },
                                    {
                                      value: 'POLOCRED_SCMEPP_LTDA_093',
                                      label: translate('generadorApp.BrazilBanks.POLOCRED_SCMEPP_LTDA_093'),
                                    },
                                    { value: 'BANCO_FINAXIS_SA_094', label: translate('generadorApp.BrazilBanks.BANCO_FINAXIS_SA_094') },
                                    {
                                      value: 'TRAVELEX_BANCO_DE_CAMBIO_SA_095',
                                      label: translate('generadorApp.BrazilBanks.TRAVELEX_BANCO_DE_CAMBIO_SA_095'),
                                    },
                                    { value: 'BANCO_B3_SA_096', label: translate('generadorApp.BrazilBanks.BANCO_B3_SA_096') },
                                    {
                                      value: 'COOPERATIVA_CENTRAL_DE_CREDITO_NOROESTE_BRASILEIRO_LTDA_097',
                                      label: translate(
                                        'generadorApp.BrazilBanks.COOPERATIVA_CENTRAL_DE_CREDITO_NOROESTE_BRASILEIRO_LTDA_097'
                                      ),
                                    },
                                    { value: 'CREDIALIANCA_CCR_098', label: translate('generadorApp.BrazilBanks.CREDIALIANCA_CCR_098') },
                                    {
                                      value: 'UNIPRIME_CENTRAL_CENTRAL_INTERESTADUAL_DE_COOPERATIVAS_DE_CREDITO_LTDA_099',
                                      label: translate(
                                        'generadorApp.BrazilBanks.UNIPRIME_CENTRAL_CENTRAL_INTERESTADUAL_DE_COOPERATIVAS_DE_CREDITO_LTDA_099'
                                      ),
                                    },
                                    {
                                      value: 'PLANNER_CORRETORA_DE_VALORES_SA_100',
                                      label: translate('generadorApp.BrazilBanks.PLANNER_CORRETORA_DE_VALORES_SA_100'),
                                    },
                                    {
                                      value: 'RENASCENCA_DTVM_LTDA_101',
                                      label: translate('generadorApp.BrazilBanks.RENASCENCA_DTVM_LTDA_101'),
                                    },
                                    {
                                      value: 'XP_INVESTIMENTOS_SA_102',
                                      label: translate('generadorApp.BrazilBanks.XP_INVESTIMENTOS_SA_102'),
                                    },
                                    {
                                      value: 'CAIXA_ECONOMICA_FEDERAL_104',
                                      label: translate('generadorApp.BrazilBanks.CAIXA_ECONOMICA_FEDERAL_104'),
                                    },
                                    {
                                      value: 'LECCA_CREDITO_FINANCIAMENTO_E_INVESTIMENTO_SA_105',
                                      label: translate('generadorApp.BrazilBanks.LECCA_CREDITO_FINANCIAMENTO_E_INVESTIMENTO_SA_105'),
                                    },
                                    {
                                      value: 'BANCO_BOCOM_BBM_SA_107',
                                      label: translate('generadorApp.BrazilBanks.BANCO_BOCOM_BBM_SA_107'),
                                    },
                                    { value: 'PORTOCRED_SA_108', label: translate('generadorApp.BrazilBanks.PORTOCRED_SA_108') },
                                    {
                                      value: 'BANCO_OLIVEIRA_TRUST_DTVM_SA_111',
                                      label: translate('generadorApp.BrazilBanks.BANCO_OLIVEIRA_TRUST_DTVM_SA_111'),
                                    },
                                    { value: 'MAGLIANO_SA_113', label: translate('generadorApp.BrazilBanks.MAGLIANO_SA_113') },
                                    {
                                      value: 'CENTRAL_COOPERATIVA_DE_CREDITO_NO_ESTADO_DO_ESPIRITO_SANTO_114',
                                      label: translate(
                                        'generadorApp.BrazilBanks.CENTRAL_COOPERATIVA_DE_CREDITO_NO_ESTADO_DO_ESPIRITO_SANTO_114'
                                      ),
                                    },
                                    { value: 'ADVANCED_CC_LTDA_117', label: translate('generadorApp.BrazilBanks.ADVANCED_CC_LTDA_117') },
                                    {
                                      value: 'STANDARD_CHARTERED_BANK_BRASIL_SA_BCO_INVEST_118',
                                      label: translate('generadorApp.BrazilBanks.STANDARD_CHARTERED_BANK_BRASIL_SA_BCO_INVEST_118'),
                                    },
                                    {
                                      value: 'BANCO_WESTERN_UNION_DO_BRASIL_SA_119',
                                      label: translate('generadorApp.BrazilBanks.BANCO_WESTERN_UNION_DO_BRASIL_SA_119'),
                                    },
                                    { value: 'BANCO_RODOBENS_SA_120', label: translate('generadorApp.BrazilBanks.BANCO_RODOBENS_SA_120') },
                                    { value: 'BANCO_AGIBANK_SA_121', label: translate('generadorApp.BrazilBanks.BANCO_AGIBANK_SA_121') },
                                    {
                                      value: 'BANCO_BRADESCO_BERJ_SA_122',
                                      label: translate('generadorApp.BrazilBanks.BANCO_BRADESCO_BERJ_SA_122'),
                                    },
                                    {
                                      value: 'BANCO_WOORI_BANK_DO_BRASIL_SA_124',
                                      label: translate('generadorApp.BrazilBanks.BANCO_WOORI_BANK_DO_BRASIL_SA_124'),
                                    },
                                    {
                                      value: 'PLURAL_SA_BANCO_MULTIPLO_125',
                                      label: translate('generadorApp.BrazilBanks.PLURAL_SA_BANCO_MULTIPLO_125'),
                                    },
                                    {
                                      value: 'BR_PARTNERS_BANCO_DE_INVESTIMENTO_SA_126',
                                      label: translate('generadorApp.BrazilBanks.BR_PARTNERS_BANCO_DE_INVESTIMENTO_SA_126'),
                                    },
                                    { value: 'CODEPE_CVC_SA_127', label: translate('generadorApp.BrazilBanks.CODEPE_CVC_SA_127') },
                                    {
                                      value: 'MS_BANK_SA_BANCO_DE_CAMBIO_128',
                                      label: translate('generadorApp.BrazilBanks.MS_BANK_SA_BANCO_DE_CAMBIO_128'),
                                    },
                                    {
                                      value: 'UBS_BRASIL_BANCO_DE_INVESTIMENTO_SA_129',
                                      label: translate('generadorApp.BrazilBanks.UBS_BRASIL_BANCO_DE_INVESTIMENTO_SA_129'),
                                    },
                                    { value: 'CARUANA_SCFI_130', label: translate('generadorApp.BrazilBanks.CARUANA_SCFI_130') },
                                    {
                                      value: 'TULLETT_PREBON_BRASIL_CVC_LTDA_131',
                                      label: translate('generadorApp.BrazilBanks.TULLETT_PREBON_BRASIL_CVC_LTDA_131'),
                                    },
                                    {
                                      value: 'ICBC_DO_BRASIL_BANCO_MULTIPLO_SA_132',
                                      label: translate('generadorApp.BrazilBanks.ICBC_DO_BRASIL_BANCO_MULTIPLO_SA_132'),
                                    },
                                    {
                                      value: 'CRESOL_CONFEDERACAO_133',
                                      label: translate('generadorApp.BrazilBanks.CRESOL_CONFEDERACAO_133'),
                                    },
                                    {
                                      value: 'BGC_LIQUIDEZ_DTVM_LTDA_134',
                                      label: translate('generadorApp.BrazilBanks.BGC_LIQUIDEZ_DTVM_LTDA_134'),
                                    },
                                    {
                                      value: 'UNICRED_COOPERATIVA_136',
                                      label: translate('generadorApp.BrazilBanks.UNICRED_COOPERATIVA_136'),
                                    },
                                    {
                                      value: 'MULTIMONEY_CC_LTDA_137',
                                      label: translate('generadorApp.BrazilBanks.MULTIMONEY_CC_LTDA_137'),
                                    },
                                    { value: 'GET_MONEY_CC_LTDA_138', label: translate('generadorApp.BrazilBanks.GET_MONEY_CC_LTDA_138') },
                                    {
                                      value: 'INTESA_SANPAOLO_BRASIL_SA_BANCO_MULTIPLO_139',
                                      label: translate('generadorApp.BrazilBanks.INTESA_SANPAOLO_BRASIL_SA_BANCO_MULTIPLO_139'),
                                    },
                                    {
                                      value: 'EASYNVEST_TITULO_CV_SA_140',
                                      label: translate('generadorApp.BrazilBanks.EASYNVEST_TITULO_CV_SA_140'),
                                    },
                                    {
                                      value: 'BROKER_BRASIL_CC_LTDA_142',
                                      label: translate('generadorApp.BrazilBanks.BROKER_BRASIL_CC_LTDA_142'),
                                    },
                                    {
                                      value: 'TREVISO_CORRETORA_DE_CAMBIO_SA_143',
                                      label: translate('generadorApp.BrazilBanks.TREVISO_CORRETORA_DE_CAMBIO_SA_143'),
                                    },
                                    {
                                      value: 'BEXS_BANCO_DE_CAMBIO_SA_144',
                                      label: translate('generadorApp.BrazilBanks.BEXS_BANCO_DE_CAMBIO_SA_144'),
                                    },
                                    { value: 'LEVYCAM_CCV_LTDA_145', label: translate('generadorApp.BrazilBanks.LEVYCAM_CCV_LTDA_145') },
                                    {
                                      value: 'GUITTA_CORRETORA_DE_CAMBIO_LTDA_146',
                                      label: translate('generadorApp.BrazilBanks.GUITTA_CORRETORA_DE_CAMBIO_LTDA_146'),
                                    },
                                    { value: 'FACTA_SA_CFI_149', label: translate('generadorApp.BrazilBanks.FACTA_SA_CFI_149') },
                                    {
                                      value: 'ICAP_DO_BRASIL_CTVM_LTDA_157',
                                      label: translate('generadorApp.BrazilBanks.ICAP_DO_BRASIL_CTVM_LTDA_157'),
                                    },
                                    { value: 'CASA_CREDITO_SA_159', label: translate('generadorApp.BrazilBanks.CASA_CREDITO_SA_159') },
                                    {
                                      value: 'COMMERZBANK_BRASIL_SA_BANCO_MULTIPLO_163',
                                      label: translate('generadorApp.BrazilBanks.COMMERZBANK_BRASIL_SA_BANCO_MULTIPLO_163'),
                                    },
                                    {
                                      value: 'BANCO_OLE_BONSUCESSO_CONSIGNADO_SA_169',
                                      label: translate('generadorApp.BrazilBanks.BANCO_OLE_BONSUCESSO_CONSIGNADO_SA_169'),
                                    },
                                    { value: 'ALBATROSS_CCV_SA_172', label: translate('generadorApp.BrazilBanks.ALBATROSS_CCV_SA_172') },
                                    {
                                      value: 'BRL_TRUST_DISTRIBUIDORA_DE_TITULOS_E_VALORES_MOBILIARIOS_SA_173',
                                      label: translate(
                                        'generadorApp.BrazilBanks.BRL_TRUST_DISTRIBUIDORA_DE_TITULOS_E_VALORES_MOBILIARIOS_SA_173'
                                      ),
                                    },
                                    {
                                      value: 'PERNAMBUCANAS_FINANC_SA_174',
                                      label: translate('generadorApp.BrazilBanks.PERNAMBUCANAS_FINANC_SA_174'),
                                    },
                                    {
                                      value: 'GUIDE_INVESTIMENTOS_SA_CORRETORA_DE_VALORES_177',
                                      label: translate('generadorApp.BrazilBanks.GUIDE_INVESTIMENTOS_SA_CORRETORA_DE_VALORES_177'),
                                    },
                                    {
                                      value: 'CM_CAPITAL_MARKETS_CCTVM_LTDA_180',
                                      label: translate('generadorApp.BrazilBanks.CM_CAPITAL_MARKETS_CCTVM_LTDA_180'),
                                    },
                                    {
                                      value: 'DACASA_FINANCEIRA_SA_182',
                                      label: translate('generadorApp.BrazilBanks.DACASA_FINANCEIRA_SA_182'),
                                    },
                                    { value: 'SOCRED_SA_183', label: translate('generadorApp.BrazilBanks.SOCRED_SA_183') },
                                    { value: 'BANCO_ITAU_BBA_SA_184', label: translate('generadorApp.BrazilBanks.BANCO_ITAU_BBA_SA_184') },
                                    {
                                      value: 'ATIVA_INVESTIMENTOS_SA_188',
                                      label: translate('generadorApp.BrazilBanks.ATIVA_INVESTIMENTOS_SA_188'),
                                    },
                                    {
                                      value: 'HS_FINANCEIRA_SA_CREDITO_FINANCIAMENTO_E_INVESTIMENTOS_189',
                                      label: translate(
                                        'generadorApp.BrazilBanks.HS_FINANCEIRA_SA_CREDITO_FINANCIAMENTO_E_INVESTIMENTOS_189'
                                      ),
                                    },
                                    { value: 'SERVICOOP_190', label: translate('generadorApp.BrazilBanks.SERVICOOP_190') },
                                    {
                                      value: 'NOVA_FUTURA_CORRETORA_DE_TITULOS_E_VALORES_MOBILIARIOS_LTDA_191',
                                      label: translate(
                                        'generadorApp.BrazilBanks.NOVA_FUTURA_CORRETORA_DE_TITULOS_E_VALORES_MOBILIARIOS_LTDA_191'
                                      ),
                                    },
                                    {
                                      value: 'PARMETAL_DISTRIBUIDORA_DE_TITULOS_E_VALORES_MOBILIARIOS_LTDA_194',
                                      label: translate(
                                        'generadorApp.BrazilBanks.PARMETAL_DISTRIBUIDORA_DE_TITULOS_E_VALORES_MOBILIARIOS_LTDA_194'
                                      ),
                                    },
                                    {
                                      value: 'BANCO_FAIR_CORRETORA_DE_CAMBIO_SA_196',
                                      label: translate('generadorApp.BrazilBanks.BANCO_FAIR_CORRETORA_DE_CAMBIO_SA_196'),
                                    },
                                    {
                                      value: 'STONE_PAGAMENTOS_SA_197',
                                      label: translate('generadorApp.BrazilBanks.STONE_PAGAMENTOS_SA_197'),
                                    },
                                    {
                                      value: 'BANCO_BRADESCO_CARTOES_SA_204',
                                      label: translate('generadorApp.BrazilBanks.BANCO_BRADESCO_CARTOES_SA_204'),
                                    },
                                    {
                                      value: 'BANCO_BTG_PACTUAL_SA_208',
                                      label: translate('generadorApp.BrazilBanks.BANCO_BTG_PACTUAL_SA_208'),
                                    },
                                    { value: 'BANCO_ORIGINAL_SA_212', label: translate('generadorApp.BrazilBanks.BANCO_ORIGINAL_SA_212') },
                                    { value: 'BANCO_ARBI_SA_213', label: translate('generadorApp.BrazilBanks.BANCO_ARBI_SA_213') },
                                    {
                                      value: 'BANCO_JOHN_DEERE_SA_217',
                                      label: translate('generadorApp.BrazilBanks.BANCO_JOHN_DEERE_SA_217'),
                                    },
                                    { value: 'BANCO_BS2_SA_218', label: translate('generadorApp.BrazilBanks.BANCO_BS2_SA_218') },
                                    {
                                      value: 'BANCO_CREDIT_AGRICOLE_BRASIL_SA_222',
                                      label: translate('generadorApp.BrazilBanks.BANCO_CREDIT_AGRICOLE_BRASIL_SA_222'),
                                    },
                                    { value: 'BANCO_FIBRA_SA_224', label: translate('generadorApp.BrazilBanks.BANCO_FIBRA_SA_224') },
                                    { value: 'BANCO_CIFRA_SA_233', label: translate('generadorApp.BrazilBanks.BANCO_CIFRA_SA_233') },
                                    { value: 'BANCO_BRADESCO_SA_237', label: translate('generadorApp.BrazilBanks.BANCO_BRADESCO_SA_237') },
                                    { value: 'BANCO_CLASSICO_SA_241', label: translate('generadorApp.BrazilBanks.BANCO_CLASSICO_SA_241') },
                                    { value: 'BANCO_MAXIMA_SA_243', label: translate('generadorApp.BrazilBanks.BANCO_MAXIMA_SA_243') },
                                    {
                                      value: 'BANCO_ABC_BRASIL_SA_246',
                                      label: translate('generadorApp.BrazilBanks.BANCO_ABC_BRASIL_SA_246'),
                                    },
                                    {
                                      value: 'BANCO_INVESTCRED_UNIBANCO_SA_249',
                                      label: translate('generadorApp.BrazilBanks.BANCO_INVESTCRED_UNIBANCO_SA_249'),
                                    },
                                    {
                                      value: 'BCV_BANCO_DE_CREDITO_E_VAREJO_SA_250',
                                      label: translate('generadorApp.BrazilBanks.BCV_BANCO_DE_CREDITO_E_VAREJO_SA_250'),
                                    },
                                    {
                                      value: 'BEXS_CORRETORA_DE_CAMBIO_SA_253',
                                      label: translate('generadorApp.BrazilBanks.BEXS_CORRETORA_DE_CAMBIO_SA_253'),
                                    },
                                    { value: 'PARANA_BANCO_SA_254', label: translate('generadorApp.BrazilBanks.PARANA_BANCO_SA_254') },
                                    {
                                      value: 'NU_PAGAMENTOS_SA_NUBANK__260',
                                      label: translate('generadorApp.BrazilBanks.NU_PAGAMENTOS_SA_NUBANK__260'),
                                    },
                                    { value: 'BANCO_FATOR_SA_265', label: translate('generadorApp.BrazilBanks.BANCO_FATOR_SA_265') },
                                    { value: 'BANCO_CEDULA_SA_266', label: translate('generadorApp.BrazilBanks.BANCO_CEDULA_SA_266') },
                                    {
                                      value: 'BARIGUI_COMPANHIA_HIPOTECARIA_268',
                                      label: translate('generadorApp.BrazilBanks.BARIGUI_COMPANHIA_HIPOTECARIA_268'),
                                    },
                                    {
                                      value: 'HSBC_BRASIL_SA_BANCO_DE_INVESTIMENTO_269',
                                      label: translate('generadorApp.BrazilBanks.HSBC_BRASIL_SA_BANCO_DE_INVESTIMENTO_269'),
                                    },
                                    {
                                      value: 'SAGITUR_CORRETORA_DE_CAMBIO_LTDA_270',
                                      label: translate('generadorApp.BrazilBanks.SAGITUR_CORRETORA_DE_CAMBIO_LTDA_270'),
                                    },
                                    {
                                      value: 'IB_CORRETORA_DE_CAMBIO_TITULOS_E_VALORES_MOBILIARIOS_SA_271',
                                      label: translate(
                                        'generadorApp.BrazilBanks.IB_CORRETORA_DE_CAMBIO_TITULOS_E_VALORES_MOBILIARIOS_SA_271'
                                      ),
                                    },
                                    {
                                      value: 'COOPERATIVA_DE_CREDITO_RURAL_DE_SAO_MIGUEL_DO_OESTE_SULCREDI_SAO_MIGUEL_273',
                                      label: translate(
                                        'generadorApp.BrazilBanks.COOPERATIVA_DE_CREDITO_RURAL_DE_SAO_MIGUEL_DO_OESTE_SULCREDI_SAO_MIGUEL_273'
                                      ),
                                    },
                                    {
                                      value: 'SENFF_SA_CREDITO_FINANCIAMENTO_E_INVESTIMENTO_276',
                                      label: translate('generadorApp.BrazilBanks.SENFF_SA_CREDITO_FINANCIAMENTO_E_INVESTIMENTO_276'),
                                    },
                                    {
                                      value: 'GENIAL_INVESTIMENTOS_CORRETORA_DE_VALORES_MOBILIARIOS_SA_278',
                                      label: translate(
                                        'generadorApp.BrazilBanks.GENIAL_INVESTIMENTOS_CORRETORA_DE_VALORES_MOBILIARIOS_SA_278'
                                      ),
                                    },
                                    {
                                      value: 'COOPERATIVA_DE_CREDITO_RURAL_DE_PRIMAVERA_DO_LESTE_279',
                                      label: translate('generadorApp.BrazilBanks.COOPERATIVA_DE_CREDITO_RURAL_DE_PRIMAVERA_DO_LESTE_279'),
                                    },
                                    {
                                      value: 'AVISTA_SA_CREDITO_FINANCIAMENTO_E_INVESTIMENTO_280',
                                      label: translate('generadorApp.BrazilBanks.AVISTA_SA_CREDITO_FINANCIAMENTO_E_INVESTIMENTO_280'),
                                    },
                                    {
                                      value: 'RB_CAPITAL_INVESTIMENTOS_DTVM_LTDA_283',
                                      label: translate('generadorApp.BrazilBanks.RB_CAPITAL_INVESTIMENTOS_DTVM_LTDA_283'),
                                    },
                                    {
                                      value: 'FRENTE_CORRETORA_DE_CAMBIO_LTDA_285',
                                      label: translate('generadorApp.BrazilBanks.FRENTE_CORRETORA_DE_CAMBIO_LTDA_285'),
                                    },
                                    {
                                      value: 'COOPERATIVA_DE_CREDITO_RURAL_DE_OURO_286',
                                      label: translate('generadorApp.BrazilBanks.COOPERATIVA_DE_CREDITO_RURAL_DE_OURO_286'),
                                    },
                                    {
                                      value: 'CAROL_DISTRIBUIDORA_DE_TITULOS_E_VALOR_MOBILIARIOS_LTDA_288',
                                      label: translate(
                                        'generadorApp.BrazilBanks.CAROL_DISTRIBUIDORA_DE_TITULOS_E_VALOR_MOBILIARIOS_LTDA_288'
                                      ),
                                    },
                                    {
                                      value: 'PAGSEGURO_INTERNET_SA_290',
                                      label: translate('generadorApp.BrazilBanks.PAGSEGURO_INTERNET_SA_290'),
                                    },
                                    {
                                      value: 'BS2_DISTRIBUIDORA_DE_TITULOS_E_VALORES_MOBILIARIOS_SA_292',
                                      label: translate(
                                        'generadorApp.BrazilBanks.BS2_DISTRIBUIDORA_DE_TITULOS_E_VALORES_MOBILIARIOS_SA_292'
                                      ),
                                    },
                                    {
                                      value: 'LASTRO_RDV_DISTRIBUIDORA_DE_TITULOS_E_VALORES_MOBILIARIOS_LTDA_293',
                                      label: translate(
                                        'generadorApp.BrazilBanks.LASTRO_RDV_DISTRIBUIDORA_DE_TITULOS_E_VALORES_MOBILIARIOS_LTDA_293'
                                      ),
                                    },
                                    { value: 'VIPS_CC_LTDA_298', label: translate('generadorApp.BrazilBanks.VIPS_CC_LTDA_298') },
                                    {
                                      value: 'SOROCRED_CREDITO_FINANCIAMENTO_E_INVESTIMENTO_SA_299',
                                      label: translate('generadorApp.BrazilBanks.SOROCRED_CREDITO_FINANCIAMENTO_E_INVESTIMENTO_SA_299'),
                                    },
                                    {
                                      value: 'BANCO_DE_LA_NACION_ARGENTINA_300',
                                      label: translate('generadorApp.BrazilBanks.BANCO_DE_LA_NACION_ARGENTINA_300'),
                                    },
                                    {
                                      value: 'BPP_INSTITUICAO_DE_PAGAMENTOS_SA_301',
                                      label: translate('generadorApp.BrazilBanks.BPP_INSTITUICAO_DE_PAGAMENTOS_SA_301'),
                                    },
                                    {
                                      value: 'VORTX_DISTRIBUIDORA_DE_TITULOS_E_VALORES_MOBILIARIOS_LTDA_310',
                                      label: translate(
                                        'generadorApp.BrazilBanks.VORTX_DISTRIBUIDORA_DE_TITULOS_E_VALORES_MOBILIARIOS_LTDA_310'
                                      ),
                                    },
                                    { value: 'BANCO_BMG_SA_318', label: translate('generadorApp.BrazilBanks.BANCO_BMG_SA_318') },
                                    {
                                      value: 'CHINA_CONSTRUCTION_BANK_BRASIL_BANCO_MULTIPLO_SA_320',
                                      label: translate('generadorApp.BrazilBanks.CHINA_CONSTRUCTION_BANK_BRASIL_BANCO_MULTIPLO_SA_320'),
                                    },
                                    {
                                      value: 'MERCADO_PAGO_CONTA_DO_MERCADO_LIVRE_323',
                                      label: translate('generadorApp.BrazilBanks.MERCADO_PAGO_CONTA_DO_MERCADO_LIVRE_323'),
                                    },
                                    {
                                      value: 'PARATI_CREDITO_FINANCIAMENTO_E_INVESTIMENTO_SA_326',
                                      label: translate('generadorApp.BrazilBanks.PARATI_CREDITO_FINANCIAMENTO_E_INVESTIMENTO_SA_326'),
                                    },
                                    { value: 'BANCO_DIGIO_SA_335', label: translate('generadorApp.BrazilBanks.BANCO_DIGIO_SA_335') },
                                    {
                                      value: 'BANCO_C6_SA_C6_BANK_336',
                                      label: translate('generadorApp.BrazilBanks.BANCO_C6_SA_C6_BANK_336'),
                                    },
                                    {
                                      value: 'SUPER_PAGAMENTOS_E_ADMINISTRACAO_DE_MEIOS_ELETRONICOS_SA_340',
                                      label: translate(
                                        'generadorApp.BrazilBanks.SUPER_PAGAMENTOS_E_ADMINISTRACAO_DE_MEIOS_ELETRONICOS_SA_340'
                                      ),
                                    },
                                    { value: 'ITAU_UNIBANCO_SA_341', label: translate('generadorApp.BrazilBanks.ITAU_UNIBANCO_SA_341') },
                                    { value: 'BANCO_XP_SA_348', label: translate('generadorApp.BrazilBanks.BANCO_XP_SA_348') },
                                    {
                                      value: 'BANCO_SOCIETE_GENERALE_BRASIL_SA_366',
                                      label: translate('generadorApp.BrazilBanks.BANCO_SOCIETE_GENERALE_BRASIL_SA_366'),
                                    },
                                    { value: 'BANCO_CARREFOUR_368', label: translate('generadorApp.BrazilBanks.BANCO_CARREFOUR_368') },
                                    {
                                      value: 'BANCO_MIZUHO_DO_BRASIL_SA_370',
                                      label: translate('generadorApp.BrazilBanks.BANCO_MIZUHO_DO_BRASIL_SA_370'),
                                    },
                                    {
                                      value: 'BANCO_J_P_MORGAN_SA_376',
                                      label: translate('generadorApp.BrazilBanks.BANCO_J_P_MORGAN_SA_376'),
                                    },
                                    {
                                      value: 'BANCO_MERCANTIL_DE_INVESTIMENTOS_SA_389',
                                      label: translate('generadorApp.BrazilBanks.BANCO_MERCANTIL_DE_INVESTIMENTOS_SA_389'),
                                    },
                                    {
                                      value: 'BANCO_MERCANTIL_DO_BRASIL_SA_389',
                                      label: translate('generadorApp.BrazilBanks.BANCO_MERCANTIL_DO_BRASIL_SA_389'),
                                    },
                                    {
                                      value: 'BANCO_BRADESCO_FINANCIAMENTOS_SA_394',
                                      label: translate('generadorApp.BrazilBanks.BANCO_BRADESCO_FINANCIAMENTOS_SA_394'),
                                    },
                                    {
                                      value: 'KIRTON_BANK_SA_BANCO_MULTIPLO_399',
                                      label: translate('generadorApp.BrazilBanks.KIRTON_BANK_SA_BANCO_MULTIPLO_399'),
                                    },
                                    { value: 'BANCO_CAPITAL_SA_412', label: translate('generadorApp.BrazilBanks.BANCO_CAPITAL_SA_412') },
                                    { value: 'BANCO_SAFRA_SA_422', label: translate('generadorApp.BrazilBanks.BANCO_SAFRA_SA_422') },
                                    {
                                      value: 'BANCO_MUFG_BRASIL_SA_456',
                                      label: translate('generadorApp.BrazilBanks.BANCO_MUFG_BRASIL_SA_456'),
                                    },
                                    {
                                      value: 'BANCO_SUMITOMO_MITSUI_BRASILEIRO_SA_464',
                                      label: translate('generadorApp.BrazilBanks.BANCO_SUMITOMO_MITSUI_BRASILEIRO_SA_464'),
                                    },
                                    {
                                      value: 'BANCO_CAIXA_GERAL_BRASIL_SA_473',
                                      label: translate('generadorApp.BrazilBanks.BANCO_CAIXA_GERAL_BRASIL_SA_473'),
                                    },
                                    { value: 'CITIBANK_NA_477', label: translate('generadorApp.BrazilBanks.CITIBANK_NA_477') },
                                    { value: 'BANCO_ITAUBANK_SA_479', label: translate('generadorApp.BrazilBanks.BANCO_ITAUBANK_SA_479') },
                                    {
                                      value: 'DEUTSCHE_BANK_SA_BANCO_ALEMAO_487',
                                      label: translate('generadorApp.BrazilBanks.DEUTSCHE_BANK_SA_BANCO_ALEMAO_487'),
                                    },
                                    {
                                      value: 'JPMORGAN_CHASE_BANK_NATIONAL_ASSOCIATION_488',
                                      label: translate('generadorApp.BrazilBanks.JPMORGAN_CHASE_BANK_NATIONAL_ASSOCIATION_488'),
                                    },
                                    { value: 'ING_BANK_NV_492', label: translate('generadorApp.BrazilBanks.ING_BANK_NV_492') },
                                    {
                                      value: 'BANCO_DE_LA_REPUBLICA_ORIENTAL_DEL_URUGUAY_494',
                                      label: translate('generadorApp.BrazilBanks.BANCO_DE_LA_REPUBLICA_ORIENTAL_DEL_URUGUAY_494'),
                                    },
                                    {
                                      value: 'BANCO_DE_LA_PROVINCIA_DE_BUENOS_AIRES_495',
                                      label: translate('generadorApp.BrazilBanks.BANCO_DE_LA_PROVINCIA_DE_BUENOS_AIRES_495'),
                                    },
                                    {
                                      value: 'BANCO_CREDIT_SUISSE_BRASIL_SA_505',
                                      label: translate('generadorApp.BrazilBanks.BANCO_CREDIT_SUISSE_BRASIL_SA_505'),
                                    },
                                    { value: 'SENSO_CCVM_SA_545', label: translate('generadorApp.BrazilBanks.SENSO_CCVM_SA_545') },
                                    {
                                      value: 'BANCO_LUSO_BRASILEIRO_SA_600',
                                      label: translate('generadorApp.BrazilBanks.BANCO_LUSO_BRASILEIRO_SA_600'),
                                    },
                                    {
                                      value: 'BANCO_INDUSTRIAL_DO_BRASIL_SA_604',
                                      label: translate('generadorApp.BrazilBanks.BANCO_INDUSTRIAL_DO_BRASIL_SA_604'),
                                    },
                                    { value: 'BANCO_VR_SA_610', label: translate('generadorApp.BrazilBanks.BANCO_VR_SA_610') },
                                    { value: 'BANCO_PAULISTA_SA_611', label: translate('generadorApp.BrazilBanks.BANCO_PAULISTA_SA_611') },
                                    {
                                      value: 'BANCO_GUANABARA_SA_612',
                                      label: translate('generadorApp.BrazilBanks.BANCO_GUANABARA_SA_612'),
                                    },
                                    { value: 'OMNI_BANCO_SA_613', label: translate('generadorApp.BrazilBanks.OMNI_BANCO_SA_613') },
                                    { value: 'BANCO_PAN_SA_623', label: translate('generadorApp.BrazilBanks.BANCO_PAN_SA_623') },
                                    { value: 'BANCO_FICSA_SA_626', label: translate('generadorApp.BrazilBanks.BANCO_FICSA_SA_626') },
                                    {
                                      value: 'BANCO_SMARTBANK_SA_630',
                                      label: translate('generadorApp.BrazilBanks.BANCO_SMARTBANK_SA_630'),
                                    },
                                    {
                                      value: 'BANCO_RENDIMENTO_SA_633',
                                      label: translate('generadorApp.BrazilBanks.BANCO_RENDIMENTO_SA_633'),
                                    },
                                    {
                                      value: 'BANCO_TRIANGULO_SA_634',
                                      label: translate('generadorApp.BrazilBanks.BANCO_TRIANGULO_SA_634'),
                                    },
                                    { value: 'BANCO_SOFISA_SA_637', label: translate('generadorApp.BrazilBanks.BANCO_SOFISA_SA_637') },
                                    { value: 'BANCO_ALVORADA_SA_641', label: translate('generadorApp.BrazilBanks.BANCO_ALVORADA_SA_641') },
                                    { value: 'BANCO_PINE_SA_643', label: translate('generadorApp.BrazilBanks.BANCO_PINE_SA_643') },
                                    {
                                      value: 'ITAU_UNIBANCO_HOLDING_SA_652',
                                      label: translate('generadorApp.BrazilBanks.ITAU_UNIBANCO_HOLDING_SA_652'),
                                    },
                                    { value: 'BANCO_INDUSVAL_SA_653', label: translate('generadorApp.BrazilBanks.BANCO_INDUSVAL_SA_653') },
                                    { value: 'BANCO_AJRENNER_SA_654', label: translate('generadorApp.BrazilBanks.BANCO_AJRENNER_SA_654') },
                                    {
                                      value: 'BANCO_VOTORANTIM_SA_655',
                                      label: translate('generadorApp.BrazilBanks.BANCO_VOTORANTIM_SA_655'),
                                    },
                                    {
                                      value: 'BANCO_PORTO_REAL_DE_INVESTIMENTOS_SA_658',
                                      label: translate('generadorApp.BrazilBanks.BANCO_PORTO_REAL_DE_INVESTIMENTOS_SA_658'),
                                    },
                                    { value: 'BANCO_DAYCOVAL_SA_707', label: translate('generadorApp.BrazilBanks.BANCO_DAYCOVAL_SA_707') },
                                    {
                                      value: 'BANCO_OURINVEST_SA_712',
                                      label: translate('generadorApp.BrazilBanks.BANCO_OURINVEST_SA_712'),
                                    },
                                    {
                                      value: 'BANCO_MAXINVEST_SA_720',
                                      label: translate('generadorApp.BrazilBanks.BANCO_MAXINVEST_SA_720'),
                                    },
                                    { value: 'BANCO_CETELEM_SA_739', label: translate('generadorApp.BrazilBanks.BANCO_CETELEM_SA_739') },
                                    {
                                      value: 'BANCO_RIBEIRAO_PRETO_SA_741',
                                      label: translate('generadorApp.BrazilBanks.BANCO_RIBEIRAO_PRETO_SA_741'),
                                    },
                                    { value: 'BANCO_SEMEAR_SA_743', label: translate('generadorApp.BrazilBanks.BANCO_SEMEAR_SA_743') },
                                    { value: 'BANCO_CITIBANK_SA_745', label: translate('generadorApp.BrazilBanks.BANCO_CITIBANK_SA_745') },
                                    { value: 'BANCO_MODAL_SA_746', label: translate('generadorApp.BrazilBanks.BANCO_MODAL_SA_746') },
                                    {
                                      value: 'BANCO_RABOBANK_INTERNATIONAL_BRASIL_SA_747',
                                      label: translate('generadorApp.BrazilBanks.BANCO_RABOBANK_INTERNATIONAL_BRASIL_SA_747'),
                                    },
                                    {
                                      value: 'BANCO_COOPERATIVO_SICREDI_SA_748',
                                      label: translate('generadorApp.BrazilBanks.BANCO_COOPERATIVO_SICREDI_SA_748'),
                                    },
                                    {
                                      value: 'SCOTIABANK_BRASIL_SA_BANCO_MULTIPLO_751',
                                      label: translate('generadorApp.BrazilBanks.SCOTIABANK_BRASIL_SA_BANCO_MULTIPLO_751'),
                                    },
                                    {
                                      value: 'BANCO_BNP_PARIBAS_BRASIL_SA_752',
                                      label: translate('generadorApp.BrazilBanks.BANCO_BNP_PARIBAS_BRASIL_SA_752'),
                                    },
                                    {
                                      value: 'NOVO_BANCO_CONTINENTAL_SA_BANCO_MULTIPLO_753',
                                      label: translate('generadorApp.BrazilBanks.NOVO_BANCO_CONTINENTAL_SA_BANCO_MULTIPLO_753'),
                                    },
                                    { value: 'BANCO_SISTEMA_SA_754', label: translate('generadorApp.BrazilBanks.BANCO_SISTEMA_SA_754') },
                                    {
                                      value: 'BANK_OF_AMERICA_MERRILL_LYNCH_BANCO_MULTIPLO_SA_755',
                                      label: translate('generadorApp.BrazilBanks.BANK_OF_AMERICA_MERRILL_LYNCH_BANCO_MULTIPLO_SA_755'),
                                    },
                                    {
                                      value: 'BANCO_COOPERATIVO_DO_BRASIL_SA_BANCOOB_756',
                                      label: translate('generadorApp.BrazilBanks.BANCO_COOPERATIVO_DO_BRASIL_SA_BANCOOB_756'),
                                    },
                                    {
                                      value: 'BANCO_KEB_HANA_DO_BRASIL_SA_757',
                                      label: translate('generadorApp.BrazilBanks.BANCO_KEB_HANA_DO_BRASIL_SA_757'),
                                    },
                                    {
                                      value: 'COOPERATIVA_CENTRAL_DE_CREDITO_URBANO_CECRED_085_X',
                                      label: translate('generadorApp.BrazilBanks.COOPERATIVA_CENTRAL_DE_CREDITO_URBANO_CECRED_085_X'),
                                    },
                                    {
                                      value: 'COOPERATIVA_CENTRAL_DE_ECONOMIA_E_CREDITO_MUTUO_DAS_UNICREDSDE_SANTA_CATARINA_E_PARANA_087_6',
                                      label: translate(
                                        'generadorApp.BrazilBanks.COOPERATIVA_CENTRAL_DE_ECONOMIA_E_CREDITO_MUTUO_DAS_UNICREDSDE_SANTA_CATARINA_E_PARANA_087_6'
                                      ),
                                    },
                                    {
                                      value: 'COOPERATIVA_DE_CREDITO_RURAL_DA_REGIAO_DA_MOGIANA_089_2',
                                      label: translate('generadorApp.BrazilBanks.COOPERATIVA_DE_CREDITO_RURAL_DA_REGIAO_DA_MOGIANA_089_2'),
                                    },
                                    {
                                      value: 'COOPERATIVA_CENTRAL_DE_ECONOMIA_E_CREDITO_MUTUO_SICOOB_UNIMAIS_090_2',
                                      label: translate(
                                        'generadorApp.BrazilBanks.COOPERATIVA_CENTRAL_DE_ECONOMIA_E_CREDITO_MUTUO_SICOOB_UNIMAIS_090_2'
                                      ),
                                    },
                                    {
                                      value: 'UNICRED_CENTRAL_DO_RIO_GRANDE_DO_SUL_091_4',
                                      label: translate('generadorApp.BrazilBanks.UNICRED_CENTRAL_DO_RIO_GRANDE_DO_SUL_091_4'),
                                    },
                                    {
                                      value: 'CREDIALIANCA_COOPERATIVA_DE_CREDITO_RURAL_098_1',
                                      label: translate('generadorApp.BrazilBanks.CREDIALIANCA_COOPERATIVA_DE_CREDITO_RURAL_098_1'),
                                    },
                                    {
                                      value: 'CENTRAL_DAS_COOPERATIVAS_DE_ECONOMIA_E_CREDITO_MUTUO_DOESTADO_DO_ESPIRITO_SANTO_LTDA_114_7',
                                      label: translate(
                                        'generadorApp.BrazilBanks.CENTRAL_DAS_COOPERATIVAS_DE_ECONOMIA_E_CREDITO_MUTUO_DOESTADO_DO_ESPIRITO_SANTO_LTDA_114_7'
                                      ),
                                    },
                                  ]}
                                  onChange={(options: any) => this.setState({ bankSelectValue: options['value'] })}
                                  name="bank"
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ bankSelectValue: evt.target.value })}
                          type="hidden"
                          name="bank"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'agency' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="agencyLabel" for="professional-agency">
                                  <Translate contentKey="generadorApp.professional.agency">Agency</Translate>(*)
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  onChange={evt => this.setState({ agencySelectValue: evt.target.value })}
                                  value={this.state.agencySelectValue ? this.state.agencySelectValue : ''}
                                  id="professional-agency"
                                  type="text"
                                  name="agency"
                                  className={'form-control'}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ agencySelectValue: evt.target.value })}
                          type="hidden"
                          name="agency"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'bankAccount' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="bankAccountLabel" for="professional-bankAccount">
                                  <Translate contentKey="generadorApp.professional.bankAccount">Bank Account</Translate>(*)
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  onChange={evt => this.setState({ bankAccountSelectValue: evt.target.value })}
                                  value={this.state.bankAccountSelectValue ? this.state.bankAccountSelectValue : ''}
                                  id="professional-bankAccount"
                                  type="text"
                                  name="bankAccount"
                                  className={'form-control'}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ bankAccountSelectValue: evt.target.value })}
                          type="hidden"
                          name="bankAccount"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'type' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="typeLabel" for="professional-type">
                                  <Translate contentKey="generadorApp.professional.type">Type</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <Select
                                  id="professional-type"
                                  className={'css-select-control'}
                                  value={
                                    this.state.typeSelectValue
                                      ? {
                                          value: this.state.typeSelectValue,
                                          label: translate('generadorApp.TypeProfessionalBank.' + this.state.typeSelectValue),
                                        }
                                      : { value: '', label: translate('generadorApp.professional.type') }
                                  }
                                  options={[
                                    { value: 'CORRENTE', label: translate('generadorApp.TypeProfessionalBank.CORRENTE') },
                                    { value: 'POUPANCA', label: translate('generadorApp.TypeProfessionalBank.POUPANCA') },
                                  ]}
                                  onChange={(options: any) => this.setState({ typeSelectValue: options['value'] })}
                                  name="type"
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ typeSelectValue: evt.target.value })}
                          type="hidden"
                          name="type"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}
                    </Row>

                    {isNew ? (
                      <Button
                        color="primary"
                        className={'float-right jh-create-entity'}
                        onClick={() => {
                          this.tabsNext(4, 3);
                        }}
                      >
                        <span className="d-sm-none">
                          <Translate contentKey="generadorApp.professional.formTabs.btnOtherInformation">OtherInformation</Translate>
                        </span>
                        <span className="d-sm-block d-none">
                          <Translate contentKey="generadorApp.professional.formTabs.btnOtherInformation">OtherInformation</Translate>
                          &nbsp;
                          <FontAwesomeIcon icon="arrow-right" />
                        </span>
                      </Button>
                    ) : null}

                    {isNew ? (
                      <Button
                        color="info"
                        className={'float-right jh-create-entity'}
                        onClick={() => {
                          this.tabsNext(2);
                        }}
                      >
                        <span className="d-sm-none">
                          <Translate contentKey="generadorApp.professional.formTabs.btnProfessionalInformation">
                            ProfessionalInformation
                          </Translate>
                        </span>
                        <span className="d-sm-block d-none">
                          <FontAwesomeIcon icon="arrow-left" />
                          &nbsp;
                          <Translate contentKey="generadorApp.professional.formTabs.btnProfessionalInformation">
                            ProfessionalInformation
                          </Translate>
                        </span>
                      </Button>
                    ) : null}
                  </TabPane>
                  <TabPane tabId={4}>
                    {isNew ? (
                      <Row className="justify-content-center mb-3">
                        <Col md="12">
                          <h2 id="generadorApp.professional.home.formTabs_28">
                            <Translate contentKey="generadorApp.professional.formTabs.OtherInformation">OtherInformation</Translate>
                          </h2>
                        </Col>
                      </Row>
                    ) : null}

                    <Row>
                      {baseFilters !== 'source' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="sourceLabel" for="professional-source">
                                  <Translate contentKey="generadorApp.professional.source">Registration Origin</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  onChange={evt => this.setState({ sourceSelectValue: evt.target.value })}
                                  value={this.state.sourceSelectValue ? this.state.sourceSelectValue : ''}
                                  id="professional-source"
                                  type="text"
                                  name="source"
                                  className={'form-control'}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ sourceSelectValue: evt.target.value })}
                          type="hidden"
                          name="source"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'observation' ? (
                        <Col md="12">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className={'mt-2'} id="observationLabel" for="professional-observation">
                                  <Translate contentKey="generadorApp.professional.observation">Observation</Translate>
                                </Label>
                              </Col>

                              <Col md="12">
                                <ReactQuill
                                  value={this.state.observationSelectValue ? this.state.observationSelectValue : ''}
                                  onChange={evt => this.setState({ observationSelectValue: evt })}
                                  modules={quillEditorModules}
                                  formats={quillEditorFormats}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ observationSelectValue: evt.target.value })}
                          type="hidden"
                          name="observation"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}
                    </Row>

                    {isNew ? (
                      <Button
                        color="primary"
                        id="save-entity"
                        type="submit"
                        disabled={updating}
                        className="btn btn-primary float-right jh-create-entity"
                      >
                        <FontAwesomeIcon icon="save" />
                        &nbsp;
                        <Translate contentKey="generadorApp.professional.btnSave">Save</Translate>
                      </Button>
                    ) : null}

                    {isNew ? (
                      <Button
                        color="info"
                        className={'float-right jh-create-entity'}
                        onClick={() => {
                          this.tabsNext(3);
                        }}
                      >
                        <span className="d-sm-none">
                          <Translate contentKey="generadorApp.professional.formTabs.btnAccountInformation">AccountInformation</Translate>
                        </span>
                        <span className="d-sm-block d-none">
                          <FontAwesomeIcon icon="arrow-left" />
                          &nbsp;
                          <Translate contentKey="generadorApp.professional.formTabs.btnAccountInformation">AccountInformation</Translate>
                        </span>
                      </Button>
                    ) : null}
                  </TabPane>
                </TabContent>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const {
      professionalEntity,
      users,
      padAttendances,
      professionalContacts,
      professionalStatusHistories,
      patientNursingPrescriptions,
      professionalFiles,
      professionalOccupationAreas,
      pushes,
      professionalDevices,
      specialties,
      professionalContractionTypes,
      levelGroups,
      professionalAdvices,
      categories,
      patients,
      loading,
      updating,
    } = this.props;
    const { isNew } = this.state;

    const { observation } = professionalEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...professionalEntity,
                  activeTab: 0,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel className="professional professional-update">
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToProps = (storeState: IRootState) => {
  return {
    users: storeState.userManagement.users,
    padAttendances: storeState.padAttendance.entities,
    professionalContacts: storeState.professionalContact.entities,
    professionalStatusHistories: storeState.professionalStatusHistory.entities,
    patientNursingPrescriptions: storeState.patientNursingPrescription.entities,
    professionalFiles: storeState.professionalFile.entities,
    professionalOccupationAreas: storeState.professionalOccupationArea.entities,
    pushes: storeState.push.entities,
    professionalDevices: storeState.professionalDevice.entities,
    specialties: storeState.specialty.entities,
    professionalContractionTypes: storeState.professionalContractionType.entities,
    levelGroups: storeState.levelGroup.entities,
    professionalAdvices: storeState.professionalAdvice.entities,
    categories: storeState.category.entities,
    patients: storeState.patient.entities,
    professionalEntity: storeState.professional.entity,
    loading: storeState.professional.loading,
    updating: storeState.professional.updating,
    updateSuccess: storeState.professional.updateSuccess,
  };
};

export const mapDispatchToProps = {
  getEntity,
  updateEntity,
  setBlob,
  createEntity,
  getEntityOffset,
  reset,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProfessionalUpdate);
