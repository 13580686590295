/* eslint complexity: ["error", 500] */
import axios from 'axios';

import { IPaginationBaseState, IPayloadResult } from 'react-jhipster';

import { IPayload } from 'react-jhipster/src/type/redux-action.type';

import { BASE_API_VERSION_PATH } from 'app/config/constants';

import { cleanEntity, jsonParse } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ISupplierTableRh, defaultValue } from 'app/shared/model/supplier-table-rh.model';

export declare type ICrudPutAction<T> = (data?: T, listFiltersPage?) => IPayload<T> | IPayloadResult<T>;
export declare type ICrudGetAction<T> = (id: string | number, selectFields?: string) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const ACTION_TYPES = {
  FETCH_SUPPLIERTABLERH_LIST_EXPORT: 'supplierTableRh/FETCH_SUPPLIERTABLERH_LIST_EXPORT',
  FETCH_SUPPLIERTABLERH_LIST: 'supplierTableRh/FETCH_SUPPLIERTABLERH_LIST',
  FETCH_SUPPLIERTABLERH: 'supplierTableRh/FETCH_SUPPLIERTABLERH',
  FETCH_SUPPLIERTABLERH_OFFSET: 'supplierTableRh/FETCH_SUPPLIERTABLERH_OFFSET',
  CREATE_SUPPLIERTABLERH: 'supplierTableRh/CREATE_SUPPLIERTABLERH',
  UPDATE_SUPPLIERTABLERH: 'supplierTableRh/UPDATE_SUPPLIERTABLERH',
  CREATE_SUPPLIERTABLERH_AND_GO_LIST: 'supplierTableRh/CREATE_SUPPLIERTABLERH_AND_GO_LIST',
  UPDATE_SUPPLIERTABLERH_AND_GO_LIST: 'supplierTableRh/UPDATE_SUPPLIERTABLERH_AND_GO_LIST',
  DELETE_SUPPLIERTABLERH: 'supplierTableRh/DELETE_SUPPLIERTABLERH',
  RESET: 'supplierTableRh/RESET',
  SHOW_MODAL: 'supplierTableRh/SHOW_MODAL',
};

const initialState = {
  loading: false,
  openModal: {
    list: false,
    view: false,
    detail: false,
  },
  errorMessage: null,
  entities: [] as ReadonlyArray<ISupplierTableRh>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const actionPendind = [];
let actualState = initialState;

export const convertEntityBuffers = entity => ({
  ...entity,
});

export type SupplierTableRhState = Readonly<typeof initialState>;

export interface ISupplierTableRhBaseState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  listCheckedID?: Array<number>;
  isCheckedAll?: boolean;
  urlBack?: string;
  extraFilters?: any;
  baseFilters: any;
  offset: number;
  specialtyId?: any;
  specialtyStartFilter?: any;
  name?: any;
  description?: any;
  price?: any;
  supplierTableId?: any;
  supplierTableStartFilter?: any;
}

export interface IFieldsBase extends ISupplierTableRhBaseState, IPaginationBaseState {}
export interface ISupplierTableRhUpdateState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  urlBack?: string;
  functionEmbebed?: any[];
  fieldsBase: IFieldsBase;
  nameSelectValue?: any;
  nameStartSelectOptions?: any;
  descriptionSelectValue?: any;
  descriptionStartSelectOptions?: any;
  priceSelectValue?: any;
  priceStartSelectOptions?: any;

  supplierTableSelectValue?: any;
  supplierTableStartSelectOptions?: any;

  specialtySelectValue?: any;
  specialtyStartSelectOptions?: any;

  isNew: boolean;
  supplierTableName?: any;

  supplierTableId?: any;
  specialtyName?: any;

  specialtyId?: any;
}

// Reducer

export default (state: SupplierTableRhState = initialState, action): SupplierTableRhState => {
  actualState = state;
  if (
    action.type.includes('_PENDING') &&
    !actionPendind.includes(action.type.replace('_PENDING', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_PENDING', ''))
  ) {
    actionPendind.push(action.type.replace('_PENDING', ''));
  }

  if (
    action.type.includes('_FULFILLED') &&
    actionPendind.includes(action.type.replace('_FULFILLED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_FULFILLED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_FULFILLED', '')), 1);
  }

  if (
    action.type.includes('_REJECTED') &&
    actionPendind.includes(action.type.replace('_REJECTED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_REJECTED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_REJECTED', '')), 1);
  }

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_SUPPLIERTABLERH_LIST_EXPORT):
    case REQUEST(ACTION_TYPES.FETCH_SUPPLIERTABLERH_LIST):
    case REQUEST(ACTION_TYPES.FETCH_SUPPLIERTABLERH):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_SUPPLIERTABLERH):
    case REQUEST(ACTION_TYPES.UPDATE_SUPPLIERTABLERH):
    case REQUEST(ACTION_TYPES.CREATE_SUPPLIERTABLERH_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.UPDATE_SUPPLIERTABLERH_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.DELETE_SUPPLIERTABLERH):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_SUPPLIERTABLERH_LIST_EXPORT):
    case FAILURE(ACTION_TYPES.FETCH_SUPPLIERTABLERH_LIST):
    case FAILURE(ACTION_TYPES.FETCH_SUPPLIERTABLERH):
    case FAILURE(ACTION_TYPES.CREATE_SUPPLIERTABLERH):
    case FAILURE(ACTION_TYPES.UPDATE_SUPPLIERTABLERH):
    case FAILURE(ACTION_TYPES.CREATE_SUPPLIERTABLERH_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.UPDATE_SUPPLIERTABLERH_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.DELETE_SUPPLIERTABLERH):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_SUPPLIERTABLERH_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_SUPPLIERTABLERH_OFFSET):
      if (action.payload.data && action.payload.data.length > 0) {
        return {
          ...state,
          loading: false,
          entity: convertEntityBuffers(action.payload.data[0]),
        };
      }
      return {
        ...state,
        loading: false,
      };

    case SUCCESS(ACTION_TYPES.FETCH_SUPPLIERTABLERH):
      return {
        ...state,
        loading: false,
        entity: convertEntityBuffers(action.payload.data),
      };
    case SUCCESS(ACTION_TYPES.CREATE_SUPPLIERTABLERH):
    case SUCCESS(ACTION_TYPES.UPDATE_SUPPLIERTABLERH):
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_SUPPLIERTABLERH_AND_GO_LIST):
    case SUCCESS(ACTION_TYPES.UPDATE_SUPPLIERTABLERH_AND_GO_LIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_SUPPLIERTABLERH):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };

    case ACTION_TYPES.SHOW_MODAL: {
      const openModal = state.openModal;
      openModal[action.payload.type] = action.payload.show;
      return {
        ...state,
        openModal,
      };
    }
    default:
      return state;
  }
};

const apiUrl = BASE_API_VERSION_PATH + 'supplier-table-rhs';

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

// Actions

// Actions
export type ICrudGetAllActionSupplierTableRh<T> = (
  specialtyId?: any,
  name?: any,
  description?: any,
  price?: any,
  supplierTableId?: any,
  extraFilters?: {},
  page?: number,
  size?: number,
  sort?: string,
  selectFields?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getEntitiesFilter = (filters?, page?, size?, sort?, selectFields = '') => {
  const requestUrl = `${apiUrl}?page=${page >= 0 ? page : 0}&size=${size >= 0 ? size : 20}&sort=${sort ? sort : 'id,asc'}&`;
  const requestFilters = filters.map(v => v.column + '.' + v.operation + '=' + v.value).join('&');
  return {
    type: ACTION_TYPES.FETCH_SUPPLIERTABLERH_LIST,
    payload: axios.get<ISupplierTableRh>(`${requestUrl}${requestFilters}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getAllEntities = (filters?, sort?, selectFields = '') => {
  if (
    (actualState.totalItems > 0 && actualState.entities.length === actualState.totalItems) ||
    actionPendind.includes(ACTION_TYPES.FETCH_SUPPLIERTABLERH_LIST)
  ) {
    return {
      type: ACTION_TYPES.FETCH_SUPPLIERTABLERH_LIST + '_FROM_CACHE',
      payload: new Promise(function (resolve, reject) {
        resolve('done');
      }),
    };
  }

  const idsRequest = filters && typeof filters['ids'] !== 'undefined' && filters['ids'] ? `id.in=${filters['ids'].join(',')}&` : '';
  const specialtyRequest =
    filters && typeof filters['specialtyId'] !== 'undefined' && filters['specialtyId'] ? `specialty.id.in=${filters['specialtyId']}&` : '';
  const nameRequest = filters && typeof filters['name'] !== 'undefined' && filters['name'] ? `name.contains=${filters['name']}&` : '';
  const descriptionRequest =
    filters && typeof filters['description'] !== 'undefined' && filters['description']
      ? `description.contains=${filters['description']}&`
      : '';
  const priceRequest = filters && typeof filters['price'] !== 'undefined' && filters['price'] ? `price.contains=${filters['price']}&` : '';
  const supplierTableRequest =
    filters && typeof filters['supplierTableId'] !== 'undefined' && filters['supplierTableId']
      ? `supplierTable.id.in=${filters['supplierTableId']}&`
      : '';

  const requestUrl = `${apiUrl}${`?page=${0}&size=${1000000}&sort=${sort ? sort : 'id,asc'}&`}`;
  return {
    type: ACTION_TYPES.FETCH_SUPPLIERTABLERH_LIST,
    payload: axios.get<ISupplierTableRh>(
      `${requestUrl}${idsRequest}${specialtyRequest}${nameRequest}${descriptionRequest}${priceRequest}${supplierTableRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntities: ICrudGetAllActionSupplierTableRh<ISupplierTableRh> = (
  specialtyId,
  name,
  description,
  price,
  supplierTableId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const specialtyRequest = specialtyId ? `specialty.id.in=${specialtyId}&` : '';
  const nameRequest = name ? `name.contains=${name}&` : '';
  const descriptionRequest = description ? `description.contains=${description}&` : '';
  const priceRequest = price ? `price.contains=${price}&` : '';
  const supplierTableRequest = supplierTableId ? `supplierTable.id.in=${supplierTableId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_SUPPLIERTABLERH_LIST,
    payload: axios.get<ISupplierTableRh>(
      `${requestUrl}${extraFiltersRequest}${specialtyRequest}${nameRequest}${descriptionRequest}${priceRequest}${supplierTableRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntityOffset: any = (
  specialtyId,
  name,
  description,
  price,
  supplierTableId,
  extraFilters,
  offset,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const specialtyRequest = specialtyId ? `specialty.id.in=${specialtyId}&` : '';
  const nameRequest = name ? `name.contains=${name}&` : '';
  const descriptionRequest = description ? `description.contains=${description}&` : '';
  const priceRequest = price ? `price.contains=${price}&` : '';
  const supplierTableRequest = supplierTableId ? `supplierTable.id.in=${supplierTableId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${offset}&size=${1}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_SUPPLIERTABLERH_OFFSET,
    payload: axios.get<ISupplierTableRh>(
      `${requestUrl}${extraFiltersRequest}${specialtyRequest}${nameRequest}${descriptionRequest}${priceRequest}${supplierTableRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntity: ICrudGetAction<ISupplierTableRh> = (id, selectFields = '') => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_SUPPLIERTABLERH,
    payload: axios.get<ISupplierTableRh>(requestUrl, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntitiesExport: ICrudGetAllActionSupplierTableRh<ISupplierTableRh> = (
  specialtyId,
  name,
  description,
  price,
  supplierTableId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const specialtyRequest = specialtyId ? `specialty.id.in=${specialtyId}&` : '';
  const nameRequest = name ? (Array.isArray(status) ? `name.in=${name.join(',')}&` : `name.contains=${name}&`) : '';
  const descriptionRequest = description
    ? Array.isArray(status)
      ? `description.in=${description.join(',')}&`
      : `description.contains=${description}&`
    : '';
  const priceRequest = price ? (Array.isArray(status) ? `price.in=${price.join(',')}&` : `price.contains=${price}&`) : '';
  const supplierTableRequest = supplierTableId ? `supplierTable.id.in=${supplierTableId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_SUPPLIERTABLERH_LIST,
    payload: axios.get<ISupplierTableRh>(
      `${requestUrl}${extraFiltersRequest}${specialtyRequest}${nameRequest}${descriptionRequest}${priceRequest}${supplierTableRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const createEntity: ICrudPutAction<ISupplierTableRh> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.CREATE_SUPPLIERTABLERH_AND_GO_LIST
        : ACTION_TYPES.CREATE_SUPPLIERTABLERH,
    payload: axios.post(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const updateEntity: ICrudPutAction<ISupplierTableRh> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.UPDATE_SUPPLIERTABLERH_AND_GO_LIST
        : ACTION_TYPES.UPDATE_SUPPLIERTABLERH,
    payload: axios.put(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const deleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_SUPPLIERTABLERH,
    payload: axios.delete(requestUrl),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const showModal = view => ({
  payload: { type: view, show: true },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const hideModal = view => ({
  payload: { type: view, show: false },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const getUrlBack = (location): string => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  let urlBack = _urlBase.searchParams.get('urlBack') || '';
  urlBack = urlBack.trim();
  urlBack = urlBack.startsWith('/') ? urlBack.slice(1) : urlBack;
  urlBack = urlBack.endsWith('/') ? urlBack.slice(0, -1) : urlBack;
  return urlBack ? '/' + urlBack + '/' : '';
};

export const getSupplierTableRhState = (location): ISupplierTableRhBaseState => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  const extraFilters = jsonParse(_urlBase.searchParams.get('extraFilters') || '{}');
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;

  const specialtyId = _urlBase.searchParams.get('specialty') || '';
  const name = _urlBase.searchParams.get('name') || '';
  const description = _urlBase.searchParams.get('description') || '';
  const price = _urlBase.searchParams.get('price') || '';
  const supplierTableId = _urlBase.searchParams.get('supplierTable') || '';
  return {
    baseFilters,
    extraFilters,
    offset,
    specialtyId: specialtyId ? specialtyId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
    name,
    description,
    price,
    supplierTableId: supplierTableId ? supplierTableId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
  };
};

export const getEntityFiltersURL = (state, offset = null) => {
  return (
    'baseFilters=' +
    state.baseFilters +
    '&extraFilters=' +
    encodeURI(JSON.stringify(state.extraFilters)) +
    '&page=' +
    state.activePage +
    '&' +
    'size=' +
    state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    state.sort +
    ',' +
    state.order +
    '&' +
    (state.specialtyId ? 'specialty=' + state.specialtyId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.name ? 'name=' + state.name + '&' : '') +
    (state.description ? 'description=' + state.description + '&' : '') +
    (state.price ? 'price=' + state.price + '&' : '') +
    (state.supplierTableId ? 'supplierTable=' + state.supplierTableId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    ''
  );
};
