import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { IQuotePackage } from 'app/shared/model/quote-package.model';
import { IQuote } from 'app/shared/model/quote.model';
import { IClComRuler } from 'app/shared/model/cl-com-ruler.model';
import { IClComItem } from 'app/shared/model/cl-com-item.model';
import { IClient } from 'app/shared/model/client.model';
import { IDose } from 'app/shared/model/dose.model';
import { Status } from 'app/shared/model/enumerations/status.model';
import { ChargeType } from 'app/shared/model/enumerations/charge-type.model';
import { TypeClientCommercialTable } from 'app/shared/model/enumerations/type-client-commercial-table.model';
import { ServiceType } from 'app/shared/model/enumerations/service-type.model';

export interface IClComTable {
  id?: number;
  name?: string;
  status?: Status;
  chargeType?: ChargeType;
  type?: TypeClientCommercialTable;
  valuePackage?: number;
  option?: ServiceType;
  saleValue?: number;
  observation?: any;
  rentalValue?: number;
  tussCode?: string;
  tableCode?: string;
  expenseCode?: string;
  quotePackage?: IQuotePackage[];
  quote?: IQuote[];
  clComRuler?: IClComRuler[];
  clComItem?: IClComItem[];
  client?: IClient;
  dose?: IDose;
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  quotePackage: {
    isMulti: false, // one-to-many - other-side
    entity: 'QuotePackage',
    urlParameters: {
      enpoint: 'quote-packages',
      filters: 'clComTable.id.equals',
    },
  },
  quote: {
    isMulti: false, // one-to-many - other-side
    entity: 'Quote',
    urlParameters: {
      enpoint: 'quotes',
      filters: 'clComTable.id.equals',
    },
  },
  clComRuler: {
    isMulti: false, // one-to-many - other-side
    entity: 'ClComRuler',
    urlParameters: {
      enpoint: 'cl-com-rulers',
      filters: 'clComTable.id.equals',
    },
  },
  clComItem: {
    isMulti: false, // one-to-many - other-side
    entity: 'ClComItem',
    urlParameters: {
      enpoint: 'cl-com-items',
      filters: 'clComTable.id.equals',
    },
  },
  client: {
    isMulti: true, // many-to-one - other-side
    entity: 'Client',
    urlParameters: {
      enpoint: 'clients',
      filters: 'clComTable.id.equals',
    },
  },
  dose: {
    isMulti: true, // many-to-one - other-side
    entity: 'Dose',
    urlParameters: {
      enpoint: 'doses',
      filters: 'clComTable.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'clComTable.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'clComTable.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<IClComTable> = {};
