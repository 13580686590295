/* eslint complexity: ["error", 500] */

import { connect } from 'react-redux';

import { ClComRuler, mapStateToProps, mapDispatchToProps } from '../cl-com-ruler';
import { IRootState } from 'app/shared/reducers';
import { translate } from 'app/config/translate-component';
import { toast } from 'react-toastify';
import { getFilterFromSelect, getFormFromSelect } from 'app/shared/util/entity-utils';

export interface IExtendedState {
  dumystate?: any;
}
export interface IExtendedProps {
  entityEmbebedPai?: any;
}

export class ClComRulerExtended extends ClComRuler {
  saveEntity = (event: any, errors: any, values: any, isNew: any) => {
    const selectFieldsList = ['category.name', 'frequency', 'option', 'clComTable.name'];
    if (errors.length === 0) {
      const { clComRulerEntity } = this.props;
      const _entity = {
        ...clComRulerEntity,
        // ...values,

        clComTable: getFormFromSelect(this.state.clComTableSelectValue, 'many-to-one'), // many-to-one - otherSide

        levelGroup: getFormFromSelect(this.state.levelGroupSelectValue, 'many-to-one'), // many-to-one - otherSide

        category: getFormFromSelect(this.state.categorySelectValue, 'many-to-one'), // many-to-one - otherSide

        productServices: getFormFromSelect(this.state.productServiceSelectValue, 'many-to-many'), // many-to-many - otherSide

        specialties: getFormFromSelect(this.state.specialtySelectValue, 'many-to-many'), // many-to-many - otherSide

        option: this.state.optionSelectValue === null ? null : this.state.optionSelectValue,
        comercialTableType: this.state.comercialTableTypeSelectValue === null ? null : this.state.comercialTableTypeSelectValue,
        saleValue: this.state.saleValueSelectValue,
        observation: this.state.observationSelectValue,
        table: this.state.tableSelectValue === null ? null : this.state.tableSelectValue,
        percent: this.state.percentSelectValue,
        inflator: this.state.inflatorSelectValue,
        deflator: this.state.deflatorSelectValue,
        rentalValue: this.state.rentalValueSelectValue,
        tussCode: this.state.tussCodeSelectValue,
        tissCode: this.state.tissCodeSelectValue,
        tableCode: this.state.tableCodeSelectValue,
        expenseCode: this.state.expenseCodeSelectValue,
        frequency: this.state.frequencySelectValue === null ? null : this.state.frequencySelectValue,
        minValuePackage: this.state.minValuePackageSelectValue,
      };
      const entity = _entity;

      const {
        option,
        comercialTableType,
        saleValue,
        observation,
        table,
        percent,
        inflator,
        deflator,
        rentalValue,
        tussCode,
        tissCode,
        tableCode,
        expenseCode,
        frequency,
        minValuePackage,
        clComTableId,
        levelGroupId,
        categoryId,
        productServiceId,
        specialtyId,
        extraFilters,
        activePage,
        itemsPerPage,
        sort,
        order,
      } = this.state.fieldsBase;

      if (isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              option,
              comercialTableType,
              saleValue,
              observation,
              table,
              percent,
              inflator,
              deflator,
              rentalValue,
              tussCode,
              tissCode,
              tableCode,
              expenseCode,
              frequency,
              minValuePackage,
              getFilterFromSelect(clComTableId, 'many-to-one'),
              getFilterFromSelect(levelGroupId, 'many-to-one'),
              getFilterFromSelect(categoryId, 'many-to-one'),
              getFilterFromSelect(productServiceId, 'many-to-many'),
              getFilterFromSelect(specialtyId, 'many-to-many'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              option,
              comercialTableType,
              saleValue,
              observation,
              table,
              percent,
              inflator,
              deflator,
              rentalValue,
              tussCode,
              tissCode,
              tableCode,
              expenseCode,
              frequency,
              minValuePackage,
              getFilterFromSelect(clComTableId, 'many-to-one'),
              getFilterFromSelect(levelGroupId, 'many-to-one'),
              getFilterFromSelect(categoryId, 'many-to-one'),
              getFilterFromSelect(productServiceId, 'many-to-many'),
              getFilterFromSelect(specialtyId, 'many-to-many'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
      this.setState({ showModalForm: false });
      this.setState({ showModalEdit: null });
    } else {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.specialty.' + errors[0]) }));
    }
  };
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;

export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(ClComRulerExtended);
