/* eslint-disable no-console */
import React, { useState } from 'react';
import axios from 'axios';
import { Button, Table } from 'reactstrap';

import { translate as basetranslate } from 'app/config/translate-component';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { registerLocale } from 'react-datepicker';
import { pt } from 'date-fns/esm/locale';

const MySwal = withReactContent(Swal);
registerLocale('pt', pt);

import { showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import { IClientGlosaItem } from 'app/shared/model/client-glosa-item.model';
import CurrencyInput from 'react-currency-input';
import { GeralSelect } from 'app/shared/model/enumerations/geral-select.model';
import { TypeBilling } from 'app/shared/model/enumerations/type-billing.model';

export interface IClientGlosaProps {
  baseState?: any;
  baseProps?: any;
  setState?: any;
  entityBase?: any;
}

const levelGroupPaths = {
  RECURSOSHUMANOS: 'quoteRh.specialty.category.levelGroup.name',
  MATERIAIS: 'quoteMaterial.medicineMaterial.categories.levelGroup.name',
  MEDICAMENTOS: 'quoteMedicine.medicineMaterial.categories.levelGroup.name',
  DIETAS: 'quoteDiet.medicineMaterial.categories.levelGroup.name',
  PRODUCTOSERVICIO: 'quoteProductService.productService.category.levelGroup.name',
};

const categoryPaths = {
  RECURSOSHUMANOS: 'quoteRh.specialty.category.name',
  MATERIAIS: 'quoteMaterial.medicineMaterial.categories.name',
  MEDICAMENTOS: 'quoteMedicine.medicineMaterial.categories.name',
  DIETAS: 'quoteDiet.medicineMaterial.categories.name',
  PRODUCTOSERVICIO: 'quoteProductService.productService.category.name',
};
const itemPaths = {
  RECURSOSHUMANOS: 'quoteRh.specialty.name',
  MATERIAIS: 'quoteMaterial.medicineMaterial.name',
  MEDICAMENTOS: 'quoteMedicine.medicineMaterial.name',
  DIETAS: 'quoteDiet.medicineMaterial.name',
  PRODUCTOSERVICIO: 'quoteProductService.productService.name',
};
const unitaryAmountPaths = {
  RECURSOSHUMANOS: 'quoteRh.unitaryAmount',
  MATERIAIS: 'quoteMaterial.unitaryAmount',
  MEDICAMENTOS: 'quoteMedicine.unitaryAmount',
  DIETAS: 'quoteDiet.unitaryAmount',
  PRODUCTOSERVICIO: 'quoteProductService.unitaryAmount',
};
const idPaths = {
  RECURSOSHUMANOS: 'quoteRh.id',
  MATERIAIS: 'quoteMaterial.id',
  MEDICAMENTOS: 'quoteMedicine.id',
  DIETAS: 'quoteDiet.id',
  PRODUCTOSERVICIO: 'quoteProductService.id',
};

const ClientGlosaComponentCustomize = (props: IClientGlosaProps) => {
  const [quoteValue, setQuoteValue] = useState(null);
  const [quotePackageList, setQuotePackageList] = useState([]);
  const [quoteRhList, setQuoteRhList] = useState([]);
  const [quoteMaterialList, setQuoteMaterialList] = useState([]);
  const [quoteMedicineList, setQuoteMedicineList] = useState([]);
  const [quoteDietList, setQuoteDietList] = useState([]);
  const [quoteProductServiceList, setQuoteProductServiceList] = useState([]);

  const isNew = props.entityBase.id && true;

  const translate = v => v;
  if (
    props.baseState.quoteSelectValue !== null &&
    props.baseState.quoteSelectValue.id > 0 &&
    props.baseState.quoteSelectValue.id !== quoteValue
  ) {
    setQuoteValue(props.baseState.quoteSelectValue.id);
    axios
      .get('/api/quote-packages?quote.id.equal=' + props.baseState.quoteSelectValue.id, {
        headers: {
          'Select-Fields': 'id',
        },
      })
      .then(v => setQuotePackageList(v && v.data ? v.data : []));
    axios
      .get('/api/quote-rhs?quote.id.equal=' + props.baseState.quoteSelectValue.id, {
        headers: {
          'Select-Fields': 'id,specialty.id,specialty.category.id,specialty.category.levelGroup.id',
        },
      })
      .then(v => setQuoteRhList(v && v.data ? v.data : []));
    axios
      .get('/api/quote-materials?quote.id.equal=' + props.baseState.quoteSelectValue.id, {
        headers: {
          'Select-Fields': 'id,medicineMaterial.id,medicineMaterial.categories.id,medicineMaterial.categories.levelGroup.id',
        },
      })
      .then(v => setQuoteMaterialList(v && v.data ? v.data : []));
    axios
      .get('/api/quote-medicines?quote.id.equal=' + props.baseState.quoteSelectValue.id, {
        headers: {
          'Select-Fields': 'id,medicineMaterial.id,medicineMaterial.categories.id,medicineMaterial.categories.levelGroup.id',
        },
      })
      .then(v => setQuoteMedicineList(v && v.data ? v.data : []));
    axios
      .get('/api/quote-diets?quote.id.equal=' + props.baseState.quoteSelectValue.id, {
        headers: {
          'Select-Fields': 'id,medicineMaterial.id,medicineMaterial.categories.id,medicineMaterial.categories.levelGroup.id',
        },
      })
      .then(v => setQuoteDietList(v && v.data ? v.data : []));
    axios
      .get('/api/quote-product-services?quote.id.equal=' + props.baseState.quoteSelectValue.id, {
        headers: {
          'Select-Fields': 'id,productService.id,productService.category.id,productService.category.levelGroup.id',
        },
      })
      .then(v => setQuoteProductServiceList(v && v.data ? v.data : []));
  }

  const baseClientClosaItems: IClientGlosaItem = {
    clientGlosa: { id: props.entityBase.id },
    justification: '',
    quantityRefused: 0,
    quantityGlossed: 0,
    quoteDiet: null,
    quoteMaterial: null,
    quoteMedicine: null,
    quotePackage: null,
    quoteProductService: null,
    quoteRh: null,
    secOrder: null,
    glosaItemAceita: GeralSelect.NAO,
    typeItemGlosa: TypeBilling.RECURSOSHUMANOS,
  };

  const quotePackageListButtonAction = (id, glosaItemAceita, quantityGlossed, secOrder) => {
    axios
      .post('/api/client-glosa-items', {
        ...baseClientClosaItems,
        typeItemGlosa: 'PACKAGE',
        quotePackage: { id },
        quantityGlossed,
        glosaItemAceita,
        secOrder,
      })
      .then(v => {
        props.baseProps.getEntity(props.entityBase.id);
      });
  };
  const quoteRhListButtonAction = (id, glosaItemAceita, quantityGlossed, secOrder) => {
    axios
      .post('/api/client-glosa-items', {
        ...baseClientClosaItems,
        typeItemGlosa: 'RECURSOSHUMANOS',
        quoteRh: { id },
        quantityGlossed,
        glosaItemAceita,
        secOrder,
      })
      .then(v => {
        props.baseProps.getEntity(props.entityBase.id);
      });
  };
  const quoteMaterialListButtonAction = (id, glosaItemAceita, quantityGlossed, secOrder) => {
    axios
      .post('/api/client-glosa-items', {
        ...baseClientClosaItems,
        typeItemGlosa: 'MATERIAIS',
        quoteMaterial: { id },
        quantityGlossed,
        glosaItemAceita,
        secOrder,
      })
      .then(v => {
        props.baseProps.getEntity(props.entityBase.id);
      });
  };
  const quoteMedicineListButtonAction = (id, glosaItemAceita, quantityGlossed, secOrder) => {
    axios
      .post('/api/client-glosa-items', {
        ...baseClientClosaItems,
        typeItemGlosa: 'MEDICAMENTOS',
        quoteMedicine: { id },
        quantityGlossed,
        glosaItemAceita,
        secOrder,
      })
      .then(v => {
        props.baseProps.getEntity(props.entityBase.id);
      });
  };
  const quoteDietListButtonAction = (id, glosaItemAceita, quantityGlossed, secOrder) => {
    axios
      .post('/api/client-glosa-items', {
        ...baseClientClosaItems,
        typeItemGlosa: 'DIETAS',
        quoteDiet: { id },
        quantityGlossed,
        glosaItemAceita,
        secOrder,
      })
      .then(v => {
        props.baseProps.getEntity(props.entityBase.id);
      });
  };
  const quoteProductServiceListButtonAction = (id, glosaItemAceita, quantityGlossed, secOrder) => {
    axios
      .post('/api/client-glosa-items', {
        ...baseClientClosaItems,
        typeItemGlosa: 'PRODUCTOSERVICIO',
        quoteProductService: { id },
        quantityGlossed,
        glosaItemAceita,
        secOrder,
      })
      .then(v => {
        props.baseProps.getEntity(props.entityBase.id);
      });
  };
  const itemListButtonAction = (entity, glosaItemAceita, quantityRefused, secOrder) => {
    if (glosaItemAceita === 'NAO' && (entity.quantityRefused <= 0 || entity.justification === '')) {
      if (entity.justification === '') {
        MySwal.fire({
          title: basetranslate('generadorApp.validators.required', {
            fieldName: basetranslate('generadorApp.clientGlosaItem.justification'),
          }),
          icon: 'error',
        });
      }
      if (entity.quantityRefused <= 0) {
        MySwal.fire({
          title: basetranslate('generadorApp.validators.required', {
            fieldName: basetranslate('generadorApp.clientGlosaItem.quantityRefused'),
          }),
          icon: 'error',
        });
      }
    } else {
      axios
        .post('/api/client-glosa-items', {
          ...entity,
          clientGlosa: { id: props.entityBase.id },
          id: null,
          quantityRefused,
          glosaItemAceita,
          secOrder,
          justification: '',
        })
        .then(v => {
          props.baseProps.getEntity(props.entityBase.id);
        });
    }
  };

  let secOrderMax = 0;
  const clientGlosaItemList: any = {};
  const clientGlosaItem: IClientGlosaItem[] = props.entityBase && props.entityBase.clientGlosaItem ? props.entityBase.clientGlosaItem : [];
  clientGlosaItem.map(v => {
    if (v.secOrder > secOrderMax) {
      secOrderMax = v.secOrder;
    }
    if (!clientGlosaItemList[v.secOrder]) {
      clientGlosaItemList[v.secOrder] = [];
    }
    clientGlosaItemList[v.secOrder].push(v);
  });

  return (
    <>
      <Table
        id="client-glosa-table-list"
        responsive
        aria-describedby="client-glosa-heading"
        className={'table-hover table-striped mt-4 table-responsive-css'}
      >
        <thead className={'thead-light'}>
          <tr>
            <th>{translate('GRUPO')}</th>
            <th>{translate('CATEGORIA')}</th>
            <th>{translate('ESPECIALIDADE / SERVIÇO / PRODUTO')}</th>
            <th>{translate('QTDE')}</th>
            <th>{translate('VALOR UNIT ')}</th>
            <th>{translate('VALOR TOTAL')}</th>
            {isNew ? <th>{translate('GLOSA ACEITA')}</th> : <> </>}
            <th />
          </tr>
        </thead>
        <tbody>
          <tr></tr>
          {quotePackageList.map((v, idx) => {
            const nextClient = clientGlosaItemList[1]
              ? clientGlosaItemList[1].filter(itm => itm.quotePackage && itm.quotePackage.id === v.id).pop()
              : null;
            return (
              <tr key={idx}>
                <td title="GRUPO">PACOTE</td>
                <td title="CATEGORIA"></td>
                <td title="ESPECIALIDADE / SERVIÇO / PRODUTO"></td>
                <td title="QTDE">{v.billQuantityItem ? v.billQuantityItem : 0}</td>
                <td title="VALOR UNIT ">R$ {(v.unitaryAmount * 1).toFixed(2).replace('.', ',')}</td>
                <td title="VALOR TOTAL ">R$ {(v.finalAmount * 1).toFixed(2).replace('.', ',')}</td>
                {isNew ? (
                  <td title="GLOSA ACEITA">
                    <Button
                      onClick={() => quotePackageListButtonAction(v.id, GeralSelect.SIM, v.billQuantityItem, 1)}
                      color="success"
                      size="sm"
                    >
                      Sim
                    </Button>
                    <Button
                      onClick={() => quotePackageListButtonAction(v.id, GeralSelect.NAO, v.billQuantityItem, 1)}
                      color="danger"
                      size="sm"
                    >
                      Não
                    </Button>
                  </td>
                ) : (
                  <> </>
                )}
              </tr>
            );
          })}
          {quoteRhList.map((v, idx) => {
            const nextClient = clientGlosaItemList[1]
              ? clientGlosaItemList[1].filter(itm => itm.quoteRh && itm.quoteRh.id === v.id).pop()
              : null;
            return (
              <tr key={idx}>
                <td title="GRUPO">{showFieldsSelectAsync(v, 'specialty.category.levelGroup.name')}</td>
                <td title="CATEGORIA">{showFieldsSelectAsync(v, 'specialty.category.name')}</td>
                <td title="ESPECIALIDADE / SERVIÇO / PRODUTO">{showFieldsSelectAsync(v, 'specialty.name')}</td>
                <td title="QTDE">{v.billQuantityItem ? v.billQuantityItem : 0}</td>
                <td title="VALOR UNIT ">R$ {(v.unitaryAmount * 1).toFixed(2).replace('.', ',')}</td>
                <td title="VALOR TOTAL ">R$ {(v.finalAmount * 1).toFixed(2).replace('.', ',')}</td>
                {isNew ? (
                  <td title="GLOSA ACEITA">
                    {!nextClient ? (
                      <>
                        <Button
                          onClick={() => quoteRhListButtonAction(v.id, GeralSelect.SIM, v.billQuantityItem, 1)}
                          color="success"
                          size="sm"
                        >
                          Sim
                        </Button>
                        <Button
                          onClick={() => quoteRhListButtonAction(v.id, GeralSelect.NAO, v.billQuantityItem, 1)}
                          color="danger"
                          size="sm"
                        >
                          Não
                        </Button>
                      </>
                    ) : (
                      <div className={'text-center alert-' + (nextClient.glosaItemAceita === GeralSelect.SIM ? 'success' : 'danger')}>
                        {nextClient.glosaItemAceita}
                      </div>
                    )}
                  </td>
                ) : (
                  <> </>
                )}
              </tr>
            );
          })}
          {quoteMaterialList.map((v, idx) => {
            const nextClient = clientGlosaItemList[1]
              ? clientGlosaItemList[1].filter(itm => itm.quoteMaterial && itm.quoteMaterial.id === v.id).pop()
              : null;
            return (
              <tr key={idx}>
                <td title="GRUPO">{showFieldsSelectAsync(v, 'medicineMaterial.categories.levelGroup.name')}</td>
                <td title="CATEGORIA">{showFieldsSelectAsync(v, 'medicineMaterial.categories.name')}</td>
                <td title="ESPECIALIDADE / SERVIÇO / PRODUTO">{showFieldsSelectAsync(v, 'medicineMaterial.name')}</td>
                <td title="QTDE">{v.billQuantityItem ? v.billQuantityItem : 0}</td>
                <td title="VALOR UNIT ">R$ {(v.unitaryAmount * 1).toFixed(2).replace('.', ',')}</td>
                <td title="VALOR TOTAL ">R$ {(v.finalAmount * 1).toFixed(2).replace('.', ',')}</td>
                {isNew ? (
                  <td title="GLOSA ACEITA">
                    {!nextClient ? (
                      <>
                        <Button
                          onClick={() => quoteMaterialListButtonAction(v.id, GeralSelect.SIM, v.billQuantityItem, 1)}
                          color="success"
                          size="sm"
                        >
                          Sim
                        </Button>
                        <Button
                          onClick={() => quoteMaterialListButtonAction(v.id, GeralSelect.NAO, v.billQuantityItem, 1)}
                          color="danger"
                          size="sm"
                        >
                          Não
                        </Button>
                      </>
                    ) : (
                      <div className={'text-center alert-' + (nextClient.glosaItemAceita === GeralSelect.SIM ? 'success' : 'danger')}>
                        {nextClient.glosaItemAceita}
                      </div>
                    )}
                  </td>
                ) : (
                  <> </>
                )}
              </tr>
            );
          })}
          {quoteMedicineList.map((v, idx) => {
            const nextClient = clientGlosaItemList[1]
              ? clientGlosaItemList[1].filter(itm => itm.quoteMedicine && itm.quoteMedicine.id === v.id).pop()
              : null;
            return (
              <tr key={idx}>
                <td title="GRUPO">{showFieldsSelectAsync(v, 'medicineMaterial.categories.levelGroup.name')}</td>
                <td title="CATEGORIA">{showFieldsSelectAsync(v, 'medicineMaterial.categories.name')}</td>
                <td title="ESPECIALIDADE / SERVIÇO / PRODUTO">{showFieldsSelectAsync(v, 'medicineMaterial.name')}</td>
                <td title="QTDE">{v.billQuantityItem ? v.billQuantityItem : 0}</td>
                <td title="VALOR UNIT ">R$ {(v.unitaryAmount * 1).toFixed(2).replace('.', ',')}</td>
                <td title="VALOR TOTAL ">R$ {(v.finalAmount * 1).toFixed(2).replace('.', ',')}</td>
                {isNew ? (
                  <td title="GLOSA ACEITA">
                    {!nextClient ? (
                      <>
                        <Button
                          onClick={() => quoteMedicineListButtonAction(v.id, GeralSelect.SIM, v.billQuantityItem, 1)}
                          color="success"
                          size="sm"
                        >
                          Sim
                        </Button>
                        <Button
                          onClick={() => quoteMedicineListButtonAction(v.id, GeralSelect.NAO, v.billQuantityItem, 1)}
                          color="danger"
                          size="sm"
                        >
                          Não
                        </Button>
                      </>
                    ) : (
                      <div className={'text-center alert-' + (nextClient.glosaItemAceita === GeralSelect.SIM ? 'success' : 'danger')}>
                        {nextClient.glosaItemAceita}
                      </div>
                    )}
                  </td>
                ) : (
                  <> </>
                )}
              </tr>
            );
          })}
          {quoteDietList.map((v, idx) => {
            const nextClient = clientGlosaItemList[1]
              ? clientGlosaItemList[1].filter(itm => itm.quoteDiet && itm.quoteDiet.id === v.id).pop()
              : null;
            return (
              <tr key={idx}>
                <td title="GRUPO">{showFieldsSelectAsync(v, 'medicineMaterial.categories.levelGroup.name')}</td>
                <td title="CATEGORIA">{showFieldsSelectAsync(v, 'medicineMaterial.categories.name')}</td>
                <td title="ESPECIALIDADE / SERVIÇO / PRODUTO">{showFieldsSelectAsync(v, 'medicineMaterial.name')}</td>
                <td title="QTDE">{v.billQuantityItem ? v.billQuantityItem : 0}</td>
                <td title="VALOR UNIT ">R$ {(v.unitaryAmount * 1).toFixed(2).replace('.', ',')}</td>
                <td title="VALOR TOTAL ">R$ {(v.finalAmount * 1).toFixed(2).replace('.', ',')}</td>
                {isNew ? (
                  <td title="GLOSA ACEITA">
                    {!nextClient ? (
                      <>
                        <Button
                          onClick={() => quoteDietListButtonAction(v.id, GeralSelect.SIM, v.billQuantityItem ? v.billQuantityItem : 0, 1)}
                          color="success"
                          size="sm"
                        >
                          Sim
                        </Button>
                        <Button
                          onClick={() => quoteDietListButtonAction(v.id, GeralSelect.NAO, v.billQuantityItem ? v.billQuantityItem : 0, 1)}
                          color="danger"
                          size="sm"
                        >
                          Não
                        </Button>
                      </>
                    ) : (
                      <div className={'text-center alert-' + (nextClient.glosaItemAceita === GeralSelect.SIM ? 'success' : 'danger')}>
                        {nextClient.glosaItemAceita}
                      </div>
                    )}
                  </td>
                ) : (
                  <> </>
                )}
              </tr>
            );
          })}
          {quoteProductServiceList.map((v, idx) => {
            const nextClient = clientGlosaItemList[1]
              ? clientGlosaItemList[1].filter(itm => itm.quoteProductService && itm.quoteProductService.id === v.id).pop()
              : null;
            return (
              <tr key={idx}>
                <td title="GRUPO">{showFieldsSelectAsync(v, 'productService.category.levelGroup.name')}</td>
                <td title="CATEGORIA">{showFieldsSelectAsync(v, 'productService.category.name')}</td>
                <td title="ESPECIALIDADE / SERVIÇO / PRODUTO">{showFieldsSelectAsync(v, 'productService.name')}</td>
                <td title="QTDE">{v.billQuantityItem ? v.billQuantityItem : '0'}</td>
                <td title="VALOR UNIT ">R$ {(v.unitaryAmount * 1).toFixed(2).replace('.', ',')}</td>
                <td title="VALOR TOTAL ">R$ {(v.finalAmount * 1).toFixed(2).replace('.', ',')}</td>
                {isNew ? (
                  <td title="GLOSA ACEITA">
                    {!nextClient ? (
                      <>
                        <Button
                          onClick={() => quoteProductServiceListButtonAction(v.id, GeralSelect.SIM, v.billQuantityItem, 1)}
                          color="success"
                          size="sm"
                        >
                          Sim
                        </Button>
                        <Button
                          onClick={() => quoteProductServiceListButtonAction(v.id, GeralSelect.NAO, v.billQuantityItem, 1)}
                          color="danger"
                          size="sm"
                        >
                          Não
                        </Button>
                      </>
                    ) : (
                      <div className={'text-center alert-' + (nextClient.glosaItemAceita === GeralSelect.SIM ? 'success' : 'danger')}>
                        {nextClient.glosaItemAceita}
                      </div>
                    )}
                  </td>
                ) : (
                  <> </>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>

      {isNew ? (
        Object.keys(clientGlosaItemList).map((itenKey, keyIdx) =>
          clientGlosaItemList[itenKey].filter(v => v.glosaItemAceita === GeralSelect.NAO).length > 0 ? (
            <>
              <br />
              <br />
              <Table
                id="client-glosa-table-list"
                style={{ minHeight: 100 }}
                responsive
                aria-describedby="client-glosa-heading"
                className={'table-hover table-striped mt-4 table-responsive-css'}
              >
                <thead className={'thead-light'}>
                  <tr>
                    <th>{translate('GRUPO')}</th>
                    <th>{translate('CATEGORIA')}</th>
                    <th>{translate('ESPECIALIDADE / SERVIÇO / PRODUTO')}</th>
                    <th>{translate('QTDE')}</th>
                    <th>{translate('QTDE RECURSADA')}</th>
                    <th>{translate('VALOR UNIT ')}</th>
                    <th>{translate('VALOR TOTAL')}</th>
                    <th colSpan={2}>{translate('JUSTIFICATIVA')}</th>
                    <th>{translate('GLOSA ACEITA')}</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  <tr></tr>
                  {clientGlosaItemList[itenKey]
                    .filter(v => v.glosaItemAceita === GeralSelect.NAO)
                    .map((v, idx) => {
                      const nextClient = clientGlosaItemList[v.secOrder + 1]
                        ? clientGlosaItemList[v.secOrder + 1]
                            .filter(
                              itm =>
                                (itm.quotePackage && v.quotePackage && itm.quotePackage.id === v.quotePackage.id) ||
                                (itm.quoteRh && v.quoteRh && itm.quoteRh.id === v.quoteRh.id) ||
                                (itm.quoteMedicine && v.quoteMedicine && itm.quoteMedicine.id === v.quoteMedicine.id) ||
                                (itm.quoteMaterial && v.quoteMaterial && itm.quoteMaterial.id === v.quoteMaterial.id) ||
                                (itm.quoteDiet && v.quoteDiet && itm.quoteDiet.id === v.quoteDiet.id) ||
                                (itm.quoteProductService &&
                                  v.quoteProductService &&
                                  itm.quoteProductService.id === v.quoteProductService.id)
                            )
                            .pop()
                        : null;
                      return (
                        <tr key={idx}>
                          <td title="GRUPO">{showFieldsSelectAsync(v, levelGroupPaths[v.typeItemGlosa])}</td>
                          <td title="CATEGORIA">{showFieldsSelectAsync(v, categoryPaths[v.typeItemGlosa])}</td>
                          <td title="ESPECIALIDADE / SERVIÇO / PRODUTO">{showFieldsSelectAsync(v, itemPaths[v.typeItemGlosa])}</td>
                          <td title="QTDE">{v.quantityGlossed ? v.quantityGlossed : '0'}</td>
                          <td title="QTDE">
                            <CurrencyInput
                              precision={0}
                              value={v.quantityRefused}
                              className={'form-control'}
                              onBlur={event => {
                                axios
                                  .put('/api/client-glosa-items', {
                                    id: v.id,
                                    quantityRefused: event.target.value,
                                  })
                                  .then(x => {
                                    props.baseProps.getEntity(props.entityBase.id);
                                  });
                              }}
                            />
                          </td>
                          <td title="VALOR UNIT ">
                            R$ {(showFieldsSelectAsync(v, unitaryAmountPaths[v.typeItemGlosa]) * 1).toFixed(2).replace('.', ',')}
                          </td>
                          <td title="VALOR TOTAL ">
                            R${' '}
                            {(showFieldsSelectAsync(v, unitaryAmountPaths[v.typeItemGlosa]) * v.quantityRefused)
                              .toFixed(2)
                              .replace('.', ',')}
                          </td>
                          <td colSpan={2} title="JUSTIFICATIVA">
                            <textarea
                              className={'form-control'}
                              defaultValue={v.justification}
                              onBlur={txt => {
                                axios
                                  .put('/api/client-glosa-items', {
                                    id: v.id,
                                    justification: txt.target.value,
                                  })
                                  .then(x => {
                                    props.baseProps.getEntity(props.entityBase.id);
                                  });
                              }}
                            ></textarea>
                          </td>
                          <td title="GLOSA ACEITA11111">
                            {!nextClient ? (
                              <>
                                <Button
                                  color="success"
                                  size="sm"
                                  onClick={() => itemListButtonAction(v, GeralSelect.SIM, v.quantityRefused, v.secOrder + 1)}
                                >
                                  Sim
                                </Button>
                                <Button
                                  color="danger"
                                  size="sm"
                                  onClick={() => itemListButtonAction(v, GeralSelect.NAO, v.quantityRefused, v.secOrder + 1)}
                                >
                                  Não
                                </Button>
                              </>
                            ) : (
                              <div
                                className={'text-center alert-' + (nextClient.glosaItemAceita === GeralSelect.SIM ? 'success' : 'danger')}
                              >
                                {nextClient.glosaItemAceita}
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </>
          ) : (
            <> </>
          )
        )
      ) : (
        <> </>
      )}
    </>
  );
};

const mapStateToProps = storeState => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default ClientGlosaComponentCustomize;
