import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AvInput } from 'availity-reactstrap-validation';
import { Translate, translate } from 'app/config/translate-component';
import { getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import SelectAsync from 'react-select/async';
import CurrencyInput from 'react-currency-input';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const ModalUpdateRomaneio = props => {
  const { buttonLabel, className } = props;

  const [modal, setModal] = useState(false);
  const [itenSel, setItenSel] = useState(null);
  const [textBatch, setTextBatch] = useState("");
  const [listPharmacyInitial, setListPharmacyInitial] = useState(undefined);
  const [isExternalStock, setIsExternalStock] = useState(false);
  const [quantidade, setQuantidade] = useState(0);
  const [quantidadeInitial, setQuantidadeInitial] = useState(0);
  const MySwal = withReactContent(Swal);

  const toggle = () => setModal(!modal);

  if (quantidade === 0 && props.entity.currentQuantity > 0) {
    setQuantidadeInitial(props.entity.currentQuantity);
    setQuantidade(props.entity.currentQuantity);
    setTextBatch(props.entity.textBatch)
  }


  useEffect(() => {
    if (quantidade === 0 && props.entity.currentQuantity > 0) {
      setQuantidadeInitial(props.entity.currentQuantity);
      setQuantidade(props.entity.currentQuantity);
      setTextBatch(props.entity.textBatch)
    }
  },[])



  return (
    <span>
      <Button onClick={() => toggle()} className="btn btn-sm btn-warning">
        <FontAwesomeIcon icon="pencil-alt" />
      </Button>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Editar iten do romaneio</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={'6'}>
              <Label for="current-quantity">Quantidade Atual</Label>
              <AvInput
                onChange={(batch) => setQuantidade(batch.target.value)}
                type="text"
                value={Math.trunc(quantidade) === 0 ? "0" : Math.trunc(quantidade)}
                id="batch"
                name="current-lote"
                className={'form-control'}
              />
              {/* <CurrencyInput
                onChange={(maskedvalue, floatvalue, event) => setQuantidade(floatvalue)}
                decimalSeparator=","
                thousandSeparator=""
                prefix=""
                precision={0}
                allowNegative={true}
                value={quantidade ? quantidade : 0}
                id="current-quantity"
                name="current-quantity"
                className={'form-control' + (quantidade < quantidadeInitial || quantidade > props.entity.days) ? 'is-invalid' : ''}
              /> */}
              {quantidade < quantidadeInitial || quantidade > props.entity.days ? (
                <div className={'mt-1 font-weight-bold' + (quantidade < quantidadeInitial) ? 'invalid-feedback' : ''}>
                  O valor deve estar entre {quantidadeInitial} e {props.entity.days}
                </div>
              ) : (
                <></>
              )}
            </Col>
            <Col lg={'6'}>
              <Label for="current-batch">Lote</Label>
              <AvInput
                onChange={(batch) => setTextBatch(batch.target.value)}
                type="text"
                value={textBatch ? textBatch : "Não tem Lote"}
                id="batch"
                name="current-lote"
                className={'form-control'}
              />
            </Col>
            <Col lg={'6'}>
              <Label check className={'m-t-30 m-l-20'}>
                <AvInput
                  id="current-quantity"
                  type="checkbox"
                  name="estoque-externo"
                  value={isExternalStock}
                  onChange={v => setIsExternalStock(!isExternalStock)}
                />
                Estoque externo?
              </Label>
            </Col>
          </Row>
          {isExternalStock ? (
            <Row className={'mt-3'}>
              <Col lg={'12'}>
                <Label for="itemId">Itens</Label>
                <SelectAsync
                  id="pharmacy-stock-status-pharmacyStock"
                  name={'pharmacyStock'}
                  className={'css-select-control'}
                  data-type-rel="many-to-one-other-side"
                  value={itenSel}
                  onChange={options => setItenSel(options)}
                  defaultOptions={listPharmacyInitial ? listPharmacyInitial : []}
                  loadingMessage={input => translate('selectAsync.loadingMessage')}
                  noOptionsMessage={input =>
                    listPharmacyInitial === undefined ? translate('selectAsync.loadingMessage') : translate('selectAsync.noOptionsMessage')
                  }
                  onMenuOpen={async () => {
                    if (listPharmacyInitial === undefined) {
                      const result = await getListAxios(
                        'pharmacy-stocks',
                        { 'externalPurchase.equals': 1 },
                        0,
                        100,
                        'id,asc',
                        'id,medicineMaterial.name'
                      );
                      setListPharmacyInitial(
                        result.data
                          ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'medicineMaterial.name') }))
                          : []
                      );
                    }
                  }}
                  loadOptions={async (inputValue, callback) => {
                    const result = await getListAxios(
                      'pharmacy-stocks',
                      {
                        'medicineMaterial.name.contains': inputValue,
                        'externalPurchase.equals': 1,
                      },
                      0,
                      100,
                      'id,asc',
                      'id,medicineMaterial.name'
                    );
                    callback(
                      result.data
                        ? result.data.map(p => ({
                            ...p,
                            value: p.id,
                            label: showFieldsSelectAsync(p, 'medicineMaterial.name'),
                          }))
                        : []
                    );
                  }}
                />
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={quantidade < quantidadeInitial || quantidade > props.entity.days}
            onClick={() => {
              const result = props.alterarQuantidadeAtual1(itenSel, isExternalStock, quantidade, textBatch).then(value => {
                if (value.value.data === false) {
                  MySwal.fire({
                    title: (
                      <p>
                        <Translate contentKey={`generadorApp.romaneio.semEstoque`} />
                      </p>
                    ),
                    html: (
                      <p>
                        <Translate contentKey={`generadorApp.romaneio.semEstoqueDesc`} />
                      </p>
                    ),
                    showConfirmButton: true,
                    showCancelButton: false,
                  });
                }
              });
            }}
            type="submit"
          >
            Salvar
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </span>
  );
};

export default ModalUpdateRomaneio;
