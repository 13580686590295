/* eslint complexity: ["error", 500] */
import axios from 'axios';

import { IPaginationBaseState, IPayloadResult } from 'react-jhipster';

import { IPayload } from 'react-jhipster/src/type/redux-action.type';

import { BASE_API_VERSION_PATH } from 'app/config/constants';

import { cleanEntity, jsonParse } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IDose, defaultValue } from 'app/shared/model/dose.model';

export declare type ICrudPutAction<T> = (data?: T, listFiltersPage?) => IPayload<T> | IPayloadResult<T>;
export declare type ICrudGetAction<T> = (id: string | number, selectFields?: string) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const ACTION_TYPES = {
  FETCH_DOSE_LIST_EXPORT: 'dose/FETCH_DOSE_LIST_EXPORT',
  FETCH_DOSE_LIST: 'dose/FETCH_DOSE_LIST',
  FETCH_DOSE: 'dose/FETCH_DOSE',
  FETCH_DOSE_OFFSET: 'dose/FETCH_DOSE_OFFSET',
  CREATE_DOSE: 'dose/CREATE_DOSE',
  UPDATE_DOSE: 'dose/UPDATE_DOSE',
  CREATE_DOSE_AND_GO_LIST: 'dose/CREATE_DOSE_AND_GO_LIST',
  UPDATE_DOSE_AND_GO_LIST: 'dose/UPDATE_DOSE_AND_GO_LIST',
  DELETE_DOSE: 'dose/DELETE_DOSE',
  RESET: 'dose/RESET',
  SHOW_MODAL: 'dose/SHOW_MODAL',
};

const initialState = {
  loading: false,
  openModal: {
    list: false,
    view: false,
    detail: false,
  },
  errorMessage: null,
  entities: [] as ReadonlyArray<IDose>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const actionPendind = [];
let actualState = initialState;

export const convertEntityBuffers = entity => ({
  ...entity,
});

export type DoseState = Readonly<typeof initialState>;

export interface IDoseBaseState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  listCheckedID?: Array<number>;
  isCheckedAll?: boolean;
  urlBack?: string;
  extraFilters?: any;
  baseFilters: any;
  offset: number;
  name?: any;
  codeANS?: any;
  description?: any;
}

export interface IFieldsBase extends IDoseBaseState, IPaginationBaseState {}
export interface IDoseUpdateState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  urlBack?: string;
  functionEmbebed?: any[];
  fieldsBase: IFieldsBase;
  nameSelectValue?: any;
  nameStartSelectOptions?: any;
  codeANSSelectValue?: any;
  codeANSStartSelectOptions?: any;
  descriptionSelectValue?: any;
  descriptionStartSelectOptions?: any;
  caseDecimalSelectValue?: any;
  caseDecimalStartSelectOptions?: any;

  medicineMaterialSelectValue?: any;
  medicineMaterialStartSelectOptions?: any;

  clComTableSelectValue?: any;
  clComTableStartSelectOptions?: any;

  specialtySelectValue?: any;
  specialtyStartSelectOptions?: any;

  productServiceSelectValue?: any;
  productServiceStartSelectOptions?: any;

  levelGroupSelectValue?: any;
  levelGroupStartSelectOptions?: any;

  isNew: boolean;
  levelGroupName?: any;

  levelGroupId?: any;
}

// Reducer

export default (state: DoseState = initialState, action): DoseState => {
  actualState = state;
  if (
    action.type.includes('_PENDING') &&
    !actionPendind.includes(action.type.replace('_PENDING', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_PENDING', ''))
  ) {
    actionPendind.push(action.type.replace('_PENDING', ''));
  }

  if (
    action.type.includes('_FULFILLED') &&
    actionPendind.includes(action.type.replace('_FULFILLED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_FULFILLED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_FULFILLED', '')), 1);
  }

  if (
    action.type.includes('_REJECTED') &&
    actionPendind.includes(action.type.replace('_REJECTED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_REJECTED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_REJECTED', '')), 1);
  }

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_DOSE_LIST_EXPORT):
    case REQUEST(ACTION_TYPES.FETCH_DOSE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_DOSE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_DOSE):
    case REQUEST(ACTION_TYPES.UPDATE_DOSE):
    case REQUEST(ACTION_TYPES.CREATE_DOSE_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.UPDATE_DOSE_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.DELETE_DOSE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_DOSE_LIST_EXPORT):
    case FAILURE(ACTION_TYPES.FETCH_DOSE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_DOSE):
    case FAILURE(ACTION_TYPES.CREATE_DOSE):
    case FAILURE(ACTION_TYPES.UPDATE_DOSE):
    case FAILURE(ACTION_TYPES.CREATE_DOSE_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.UPDATE_DOSE_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.DELETE_DOSE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_DOSE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_DOSE_OFFSET):
      if (action.payload.data && action.payload.data.length > 0) {
        return {
          ...state,
          loading: false,
          entity: convertEntityBuffers(action.payload.data[0]),
        };
      }
      return {
        ...state,
        loading: false,
      };

    case SUCCESS(ACTION_TYPES.FETCH_DOSE):
      return {
        ...state,
        loading: false,
        entity: convertEntityBuffers(action.payload.data),
      };
    case SUCCESS(ACTION_TYPES.CREATE_DOSE):
    case SUCCESS(ACTION_TYPES.UPDATE_DOSE):
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_DOSE_AND_GO_LIST):
    case SUCCESS(ACTION_TYPES.UPDATE_DOSE_AND_GO_LIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_DOSE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };

    case ACTION_TYPES.SHOW_MODAL: {
      const openModal = state.openModal;
      openModal[action.payload.type] = action.payload.show;
      return {
        ...state,
        openModal,
      };
    }
    default:
      return state;
  }
};

const apiUrl = BASE_API_VERSION_PATH + 'doses';

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

// Actions

// Actions
export type ICrudGetAllActionDose<T> = (
  name?: any,
  codeANS?: any,
  description?: any,
  extraFilters?: {},
  page?: number,
  size?: number,
  sort?: string,
  selectFields?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getEntitiesFilter = (filters?, page?, size?, sort?, selectFields = '') => {
  const requestUrl = `${apiUrl}?page=${page >= 0 ? page : 0}&size=${size >= 0 ? size : 20}&sort=${sort ? sort : 'id,asc'}&`;
  const requestFilters = filters.map(v => v.column + '.' + v.operation + '=' + v.value).join('&');
  return {
    type: ACTION_TYPES.FETCH_DOSE_LIST,
    payload: axios.get<IDose>(`${requestUrl}${requestFilters}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getAllEntities = (filters?, sort?, selectFields = '') => {
  if (
    (actualState.totalItems > 0 && actualState.entities.length === actualState.totalItems) ||
    actionPendind.includes(ACTION_TYPES.FETCH_DOSE_LIST)
  ) {
    return {
      type: ACTION_TYPES.FETCH_DOSE_LIST + '_FROM_CACHE',
      payload: new Promise(function (resolve, reject) {
        resolve('done');
      }),
    };
  }

  const idsRequest = filters && typeof filters['ids'] !== 'undefined' && filters['ids'] ? `id.in=${filters['ids'].join(',')}&` : '';
  const nameRequest = filters && typeof filters['name'] !== 'undefined' && filters['name'] ? `name.contains=${filters['name']}&` : '';
  const codeANSRequest =
    filters && typeof filters['codeANS'] !== 'undefined' && filters['codeANS'] ? `codeANS.contains=${filters['codeANS']}&` : '';
  const descriptionRequest =
    filters && typeof filters['description'] !== 'undefined' && filters['description']
      ? `description.contains=${filters['description']}&`
      : '';

  const requestUrl = `${apiUrl}${`?page=${0}&size=${1000000}&sort=${sort ? sort : 'id,asc'}&`}`;
  return {
    type: ACTION_TYPES.FETCH_DOSE_LIST,
    payload: axios.get<IDose>(`${requestUrl}${idsRequest}${nameRequest}${codeANSRequest}${descriptionRequest}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntities: ICrudGetAllActionDose<IDose> = (
  name,
  codeANS,
  description,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const nameRequest = name ? `name.contains=${name}&` : '';
  const codeANSRequest = codeANS ? `codeANS.contains=${codeANS}&` : '';
  const descriptionRequest = description ? `description.contains=${description}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_DOSE_LIST,
    payload: axios.get<IDose>(`${requestUrl}${extraFiltersRequest}${nameRequest}${codeANSRequest}${descriptionRequest}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntityOffset: any = (name, codeANS, description, extraFilters, offset, sort, selectFields = '') => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const nameRequest = name ? `name.contains=${name}&` : '';
  const codeANSRequest = codeANS ? `codeANS.contains=${codeANS}&` : '';
  const descriptionRequest = description ? `description.contains=${description}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${offset}&size=${1}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_DOSE_OFFSET,
    payload: axios.get<IDose>(`${requestUrl}${extraFiltersRequest}${nameRequest}${codeANSRequest}${descriptionRequest}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntity: ICrudGetAction<IDose> = (id, selectFields = '') => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_DOSE,
    payload: axios.get<IDose>(requestUrl, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntitiesExport: ICrudGetAllActionDose<IDose> = (
  name,
  codeANS,
  description,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const nameRequest = name ? (Array.isArray(status) ? `name.in=${name.join(',')}&` : `name.contains=${name}&`) : '';
  const codeANSRequest = codeANS ? (Array.isArray(status) ? `codeANS.in=${codeANS.join(',')}&` : `codeANS.contains=${codeANS}&`) : '';
  const descriptionRequest = description
    ? Array.isArray(status)
      ? `description.in=${description.join(',')}&`
      : `description.contains=${description}&`
    : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_DOSE_LIST,
    payload: axios.get<IDose>(`${requestUrl}${extraFiltersRequest}${nameRequest}${codeANSRequest}${descriptionRequest}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const createEntity: ICrudPutAction<IDose> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type: listFiltersPage && listFiltersPage['reloadList'] !== false ? ACTION_TYPES.CREATE_DOSE_AND_GO_LIST : ACTION_TYPES.CREATE_DOSE,
    payload: axios.post(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const updateEntity: ICrudPutAction<IDose> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type: listFiltersPage && listFiltersPage['reloadList'] !== false ? ACTION_TYPES.UPDATE_DOSE_AND_GO_LIST : ACTION_TYPES.UPDATE_DOSE,
    payload: axios.put(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const deleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_DOSE,
    payload: axios.delete(requestUrl),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const showModal = view => ({
  payload: { type: view, show: true },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const hideModal = view => ({
  payload: { type: view, show: false },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const getUrlBack = (location): string => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  let urlBack = _urlBase.searchParams.get('urlBack') || '';
  urlBack = urlBack.trim();
  urlBack = urlBack.startsWith('/') ? urlBack.slice(1) : urlBack;
  urlBack = urlBack.endsWith('/') ? urlBack.slice(0, -1) : urlBack;
  return urlBack ? '/' + urlBack + '/' : '';
};

export const getDoseState = (location): IDoseBaseState => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  const extraFilters = jsonParse(_urlBase.searchParams.get('extraFilters') || '{}');
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;

  const name = _urlBase.searchParams.get('name') || '';
  const codeANS = _urlBase.searchParams.get('codeANS') || '';
  const description = _urlBase.searchParams.get('description') || '';
  return {
    baseFilters,
    extraFilters,
    offset,
    name,
    codeANS,
    description,
  };
};

export const getEntityFiltersURL = (state, offset = null) => {
  return (
    'baseFilters=' +
    state.baseFilters +
    '&extraFilters=' +
    encodeURI(JSON.stringify(state.extraFilters)) +
    '&page=' +
    state.activePage +
    '&' +
    'size=' +
    state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    state.sort +
    ',' +
    state.order +
    '&' +
    (state.name ? 'name=' + state.name + '&' : '') +
    (state.codeANS ? 'codeANS=' + state.codeANS + '&' : '') +
    (state.description ? 'description=' + state.description + '&' : '') +
    ''
  );
};
