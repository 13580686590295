/* eslint complexity: ["error", 500] */
import axios from 'axios';

import { IPaginationBaseState, IPayloadResult } from 'react-jhipster';

import { IPayload } from 'react-jhipster/src/type/redux-action.type';

import { BASE_API_VERSION_PATH } from 'app/config/constants';

import { cleanEntity, jsonParse } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IPatientCid, defaultValue } from 'app/shared/model/patient-cid.model';

export declare type ICrudPutAction<T> = (data?: T, listFiltersPage?) => IPayload<T> | IPayloadResult<T>;
export declare type ICrudGetAction<T> = (id: string | number, selectFields?: string) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const ACTION_TYPES = {
  FETCH_PATIENTCID_LIST_EXPORT: 'patientCid/FETCH_PATIENTCID_LIST_EXPORT',
  FETCH_PATIENTCID_LIST: 'patientCid/FETCH_PATIENTCID_LIST',
  FETCH_PATIENTCID: 'patientCid/FETCH_PATIENTCID',
  FETCH_PATIENTCID_OFFSET: 'patientCid/FETCH_PATIENTCID_OFFSET',
  CREATE_PATIENTCID: 'patientCid/CREATE_PATIENTCID',
  UPDATE_PATIENTCID: 'patientCid/UPDATE_PATIENTCID',
  CREATE_PATIENTCID_AND_GO_LIST: 'patientCid/CREATE_PATIENTCID_AND_GO_LIST',
  UPDATE_PATIENTCID_AND_GO_LIST: 'patientCid/UPDATE_PATIENTCID_AND_GO_LIST',
  DELETE_PATIENTCID: 'patientCid/DELETE_PATIENTCID',
  SET_BLOB: 'patientCid/SET_BLOB',
  RESET: 'patientCid/RESET',
  SHOW_MODAL: 'patientCid/SHOW_MODAL',
};

const initialState = {
  loading: false,
  openModal: {
    list: false,
    view: false,
    detail: false,
  },
  errorMessage: null,
  entities: [] as ReadonlyArray<IPatientCid>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const actionPendind = [];
let actualState = initialState;

export const convertEntityBuffers = entity => ({
  ...entity,
  cidComments: entity.cidComments ? Buffer.from(entity.cidComments).toString() : entity.cidComments,
});

export type PatientCidState = Readonly<typeof initialState>;

export interface IPatientCidBaseState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  listCheckedID?: Array<number>;
  isCheckedAll?: boolean;
  urlBack?: string;
  extraFilters?: any;
  baseFilters: any;
  offset: number;
  cidId?: any;
  cidStartFilter?: any;
  cidComments?: any;
  cidPrimary?: any;
  cidComplexity?: any;
  status?: any;
  patientId?: any;
  patientStartFilter?: any;
}

export interface IFieldsBase extends IPatientCidBaseState, IPaginationBaseState {}
export interface IPatientCidUpdateState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  urlBack?: string;
  functionEmbebed?: any[];
  fieldsBase: IFieldsBase;
  cidCommentsSelectValue?: any;
  cidCommentsStartSelectOptions?: any;
  cidPrimarySelectValue?: any;
  cidPrimaryStartSelectOptions?: any;
  cidComplexitySelectValue?: any;
  cidComplexityStartSelectOptions?: any;
  statusSelectValue?: any;
  statusStartSelectOptions?: any;

  cidStatusHistorySelectValue?: any;
  cidStatusHistoryStartSelectOptions?: any;

  patientSelectValue?: any;
  patientStartSelectOptions?: any;

  cidSelectValue?: any;
  cidStartSelectOptions?: any;

  isNew: boolean;
  patientName?: any;

  patientId?: any;
  cidName?: any;

  cidId?: any;
}

// Reducer

export default (state: PatientCidState = initialState, action): PatientCidState => {
  actualState = state;
  if (
    action.type.includes('_PENDING') &&
    !actionPendind.includes(action.type.replace('_PENDING', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_PENDING', ''))
  ) {
    actionPendind.push(action.type.replace('_PENDING', ''));
  }

  if (
    action.type.includes('_FULFILLED') &&
    actionPendind.includes(action.type.replace('_FULFILLED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_FULFILLED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_FULFILLED', '')), 1);
  }

  if (
    action.type.includes('_REJECTED') &&
    actionPendind.includes(action.type.replace('_REJECTED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_REJECTED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_REJECTED', '')), 1);
  }

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PATIENTCID_LIST_EXPORT):
    case REQUEST(ACTION_TYPES.FETCH_PATIENTCID_LIST):
    case REQUEST(ACTION_TYPES.FETCH_PATIENTCID):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_PATIENTCID):
    case REQUEST(ACTION_TYPES.UPDATE_PATIENTCID):
    case REQUEST(ACTION_TYPES.CREATE_PATIENTCID_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.UPDATE_PATIENTCID_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.DELETE_PATIENTCID):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_PATIENTCID_LIST_EXPORT):
    case FAILURE(ACTION_TYPES.FETCH_PATIENTCID_LIST):
    case FAILURE(ACTION_TYPES.FETCH_PATIENTCID):
    case FAILURE(ACTION_TYPES.CREATE_PATIENTCID):
    case FAILURE(ACTION_TYPES.UPDATE_PATIENTCID):
    case FAILURE(ACTION_TYPES.CREATE_PATIENTCID_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.UPDATE_PATIENTCID_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.DELETE_PATIENTCID):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_PATIENTCID_LIST):
      action.payload.data = action.payload.data.map(v => {
        return { ...v, cidComments: v.cidComments ? Buffer.from(v.cidComments).toString() : v.cidComments };
      });

      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_PATIENTCID_OFFSET):
      if (action.payload.data && action.payload.data.length > 0) {
        return {
          ...state,
          loading: false,
          entity: convertEntityBuffers(action.payload.data[0]),
        };
      }
      return {
        ...state,
        loading: false,
      };

    case SUCCESS(ACTION_TYPES.FETCH_PATIENTCID):
      return {
        ...state,
        loading: false,
        entity: convertEntityBuffers(action.payload.data),
      };
    case SUCCESS(ACTION_TYPES.CREATE_PATIENTCID):
    case SUCCESS(ACTION_TYPES.UPDATE_PATIENTCID):
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_PATIENTCID_AND_GO_LIST):
    case SUCCESS(ACTION_TYPES.UPDATE_PATIENTCID_AND_GO_LIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_PATIENTCID):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.SET_BLOB: {
      const { name, data, contentType, fileName } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [name + 'Base64']: data,
          [name + 'ContentType']: contentType,
          [name + 'FileName']: fileName,
        },
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };

    case ACTION_TYPES.SHOW_MODAL: {
      const openModal = state.openModal;
      openModal[action.payload.type] = action.payload.show;
      return {
        ...state,
        openModal,
      };
    }
    default:
      return state;
  }
};

const apiUrl = BASE_API_VERSION_PATH + 'patient-cids';

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

// Actions

// Actions
export type ICrudGetAllActionPatientCid<T> = (
  cidId?: any,
  cidComments?: any,
  cidPrimary?: any,
  cidComplexity?: any,
  status?: any,
  patientId?: any,
  extraFilters?: {},
  page?: number,
  size?: number,
  sort?: string,
  selectFields?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getEntitiesFilter = (filters?, page?, size?, sort?, selectFields = '') => {
  const requestUrl = `${apiUrl}?page=${page >= 0 ? page : 0}&size=${size >= 0 ? size : 20}&sort=${sort ? sort : 'id,asc'}&`;
  const requestFilters = filters.map(v => v.column + '.' + v.operation + '=' + v.value).join('&');
  return {
    type: ACTION_TYPES.FETCH_PATIENTCID_LIST,
    payload: axios.get<IPatientCid>(`${requestUrl}${requestFilters}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getAllEntities = (filters?, sort?, selectFields = '') => {
  if (
    (actualState.totalItems > 0 && actualState.entities.length === actualState.totalItems) ||
    actionPendind.includes(ACTION_TYPES.FETCH_PATIENTCID_LIST)
  ) {
    return {
      type: ACTION_TYPES.FETCH_PATIENTCID_LIST + '_FROM_CACHE',
      payload: new Promise(function (resolve, reject) {
        resolve('done');
      }),
    };
  }

  const idsRequest = filters && typeof filters['ids'] !== 'undefined' && filters['ids'] ? `id.in=${filters['ids'].join(',')}&` : '';
  const cidRequest = filters && typeof filters['cidId'] !== 'undefined' && filters['cidId'] ? `cid.id.in=${filters['cidId']}&` : '';
  const cidCommentsRequest =
    filters && typeof filters['cidComments'] !== 'undefined' && filters['cidComments']
      ? `cidComments.contains=${filters['cidComments']}&`
      : '';
  const cidPrimaryRequest =
    filters && typeof filters['cidPrimary'] !== 'undefined' && filters['cidPrimary'] ? `cidPrimary.contains=${filters['cidPrimary']}&` : '';
  const cidComplexityRequest =
    filters && typeof filters['cidComplexity'] !== 'undefined' && filters['cidComplexity']
      ? `cidComplexity.contains=${filters['cidComplexity']}&`
      : '';
  const statusRequest =
    filters && typeof filters['status'] !== 'undefined' && filters['status'] ? `status.contains=${filters['status']}&` : '';
  const patientRequest =
    filters && typeof filters['patientId'] !== 'undefined' && filters['patientId'] ? `patient.id.in=${filters['patientId']}&` : '';

  const requestUrl = `${apiUrl}${`?page=${0}&size=${1000000}&sort=${sort ? sort : 'id,asc'}&`}`;
  return {
    type: ACTION_TYPES.FETCH_PATIENTCID_LIST,
    payload: axios.get<IPatientCid>(
      `${requestUrl}${idsRequest}${cidRequest}${cidCommentsRequest}${cidPrimaryRequest}${cidComplexityRequest}${statusRequest}${patientRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntities: ICrudGetAllActionPatientCid<IPatientCid> = (
  cidId,
  cidComments,
  cidPrimary,
  cidComplexity,
  status,
  patientId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const cidRequest = cidId ? `cid.id.in=${cidId}&` : '';
  const cidCommentsRequest = cidComments ? `cidComments.contains=${cidComments}&` : '';
  const cidPrimaryRequest = cidPrimary !== '' ? `cidPrimary.equals=${cidPrimary}&` : '';
  const cidComplexityRequest = cidComplexity
    ? Array.isArray(status)
      ? `cidComplexity.in=${cidComplexity.join(',')}&`
      : `cidComplexity.equals=${cidComplexity}&`
    : '';
  const statusRequest = status ? (Array.isArray(status) ? `status.in=${status.join(',')}&` : `status.equals=${status}&`) : '';
  const patientRequest = patientId ? `patient.id.in=${patientId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PATIENTCID_LIST,
    payload: axios.get<IPatientCid>(
      `${requestUrl}${extraFiltersRequest}${cidRequest}${cidCommentsRequest}${cidPrimaryRequest}${cidComplexityRequest}${statusRequest}${patientRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntityOffset: any = (
  cidId,
  cidComments,
  cidPrimary,
  cidComplexity,
  status,
  patientId,
  extraFilters,
  offset,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const cidRequest = cidId ? `cid.id.in=${cidId}&` : '';
  const cidCommentsRequest = cidComments ? `cidComments.contains=${cidComments}&` : '';
  const cidPrimaryRequest = cidPrimary !== '' ? `cidPrimary.equals=${cidPrimary}&` : '';
  const cidComplexityRequest = cidComplexity
    ? Array.isArray(status)
      ? `cidComplexity.in=${cidComplexity.join(',')}&`
      : `cidComplexity.equals=${cidComplexity}&`
    : '';
  const statusRequest = status ? (Array.isArray(status) ? `status.in=${status.join(',')}&` : `status.equals=${status}&`) : '';
  const patientRequest = patientId ? `patient.id.in=${patientId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${offset}&size=${1}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PATIENTCID_OFFSET,
    payload: axios.get<IPatientCid>(
      `${requestUrl}${extraFiltersRequest}${cidRequest}${cidCommentsRequest}${cidPrimaryRequest}${cidComplexityRequest}${statusRequest}${patientRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntity: ICrudGetAction<IPatientCid> = (id, selectFields = '') => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_PATIENTCID,
    payload: axios.get<IPatientCid>(requestUrl, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntitiesExport: ICrudGetAllActionPatientCid<IPatientCid> = (
  cidId,
  cidComments,
  cidPrimary,
  cidComplexity,
  status,
  patientId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const cidRequest = cidId ? `cid.id.in=${cidId}&` : '';
  const cidCommentsRequest = cidComments
    ? Array.isArray(status)
      ? `cidComments.in=${cidComments.join(',')}&`
      : `cidComments.contains=${cidComments}&`
    : '';
  const cidPrimaryRequest = cidPrimary !== '' ? `cidPrimary.equals=${cidPrimary}&` : '';
  const cidComplexityRequest = cidComplexity
    ? Array.isArray(status)
      ? `cidComplexity.in=${cidComplexity.join(',')}&`
      : `cidComplexity.equals=${cidComplexity}&`
    : '';
  const statusRequest = status ? (Array.isArray(status) ? `status.in=${status.join(',')}&` : `status.equals=${status}&`) : '';
  const patientRequest = patientId ? `patient.id.in=${patientId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PATIENTCID_LIST,
    payload: axios.get<IPatientCid>(
      `${requestUrl}${extraFiltersRequest}${cidRequest}${cidCommentsRequest}${cidPrimaryRequest}${cidComplexityRequest}${statusRequest}${patientRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const createEntity: ICrudPutAction<IPatientCid> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.CREATE_PATIENTCID_AND_GO_LIST
        : ACTION_TYPES.CREATE_PATIENTCID,
    payload: axios.post(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const updateEntity: ICrudPutAction<IPatientCid> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.UPDATE_PATIENTCID_AND_GO_LIST
        : ACTION_TYPES.UPDATE_PATIENTCID,
    payload: axios.put(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const deleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_PATIENTCID,
    payload: axios.delete(requestUrl),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const setBlob = (name, data, contentType?, fileName?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType,
    fileName,
  },
});

export const showModal = view => ({
  payload: { type: view, show: true },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const hideModal = view => ({
  payload: { type: view, show: false },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const getUrlBack = (location): string => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  let urlBack = _urlBase.searchParams.get('urlBack') || '';
  urlBack = urlBack.trim();
  urlBack = urlBack.startsWith('/') ? urlBack.slice(1) : urlBack;
  urlBack = urlBack.endsWith('/') ? urlBack.slice(0, -1) : urlBack;
  return urlBack ? '/' + urlBack + '/' : '';
};

export const getPatientCidState = (location): IPatientCidBaseState => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  const extraFilters = jsonParse(_urlBase.searchParams.get('extraFilters') || '{}');
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;

  const cidId = _urlBase.searchParams.get('cid') || '';
  const cidComments = _urlBase.searchParams.get('cidComments') || '';
  const cidPrimary = _urlBase.searchParams.get('cidPrimary') || '';
  const cidComplexity = _urlBase.searchParams.get('cidComplexity') || '';
  const status = _urlBase.searchParams.get('status') || '';
  const patientId = _urlBase.searchParams.get('patient') || '';
  return {
    baseFilters,
    extraFilters,
    offset,
    cidId: cidId ? cidId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
    cidComments,
    cidPrimary,
    cidComplexity,
    status,
    patientId: patientId ? patientId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
  };
};

export const getEntityFiltersURL = (state, offset = null) => {
  return (
    'baseFilters=' +
    state.baseFilters +
    '&extraFilters=' +
    encodeURI(JSON.stringify(state.extraFilters)) +
    '&page=' +
    state.activePage +
    '&' +
    'size=' +
    state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    state.sort +
    ',' +
    state.order +
    '&' +
    (state.cidId ? 'cid=' + state.cidId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.cidComments ? 'cidComments=' + state.cidComments + '&' : '') +
    (state.cidPrimary ? 'cidPrimary=' + state.cidPrimary + '&' : '') +
    (state.cidComplexity ? 'cidComplexity=' + state.cidComplexity + '&' : '') +
    (state.status ? 'status=' + state.status + '&' : '') +
    (state.patientId ? 'patient=' + state.patientId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    ''
  );
};
