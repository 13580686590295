/* eslint complexity: ["error", 500] */
/* eslint no-empty: off */
import React from 'react';
import { connect } from 'react-redux';

import { Link, RouteComponentProps } from 'react-router-dom';

import { getFilterFromSelect } from 'app/shared/util/entity-utils';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Col, Label } from 'reactstrap';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import {
  IProfileUpdateState as IBaseUpdateState,
  getEntity,
  getProfileState,
  getUrlBack,
  updateEntity,
  createEntity,
  getEntityFiltersURL,
  getEntityOffset,
  reset,
  permissionProfileSetEntities,
  permissionProfileGetEntities,
  permissionProfileUpdateEntity,
  permissionProfileReset,
  permissionProfileGetEntity,
  permissionProfileCreateEntity,
  permissionProfileDeleteEntity,
} from './profile.reducer';

import {
  mapStateToPropsExtended as mapStateToPropsPermissionProfile,
  mapDispatchToPropsExtended as mapDispatchToPropsPermissionProfile,
  PermissionProfileExtended as PermissionProfile,
} from 'app/entities/permission-profile/extended/permission-profile';

import { StatePropsExtended, DispatchPropsExtended, IUpdateExtendedState } from './extended/profile-update';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

registerLocale('pt', pt);

export interface IProfileUpdateProps
  extends StateProps,
    DispatchProps,
    StatePropsExtended,
    DispatchPropsExtended,
    RouteComponentProps<{ url: string }> {}
export interface IProfileUpdateState extends IBaseUpdateState, IUpdateExtendedState {}

export class ProfileUpdate extends React.Component<IProfileUpdateProps, IProfileUpdateState> {
  nameFileInput: any;

  statusFileInput: any;
  constructor(props: Readonly<IProfileUpdateProps>) {
    super(props);

    this.permissionProfileUpdateEntity = this.permissionProfileUpdateEntity.bind(this);
    this.permissionProfileGetEntities = this.permissionProfileGetEntities.bind(this);
    this.permissionProfileReset = this.permissionProfileReset.bind(this);
    this.permissionProfileGetEntity = this.permissionProfileGetEntity.bind(this);
    this.permissionProfileCreateEntity = this.permissionProfileCreateEntity.bind(this);
    this.permissionProfileDeleteEntity = this.permissionProfileDeleteEntity.bind(this);

    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      functionEmbebed: [],

      permissionProfileSelectValue: null,

      userSelectValue: null,

      nameSelectValue: null,
      statusSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getProfileState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
      userFullname: null,

      isNew: !this.props.match.params || !this.props.match.params['id'],
    };
  }

  permissionProfileUpdateEntity(...values) {
    values[0]['profile'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['updateEntityPermissionProfile'](...values);
    }
    return this.props.permissionProfileUpdateEntity(...values);
  }
  permissionProfileGetEntities(...values) {
    return this.props.permissionProfileGetEntities(...values);
  }
  permissionProfileReset(...values) {
    return this.props.permissionProfileReset(...values);
  }
  permissionProfileGetEntity(...values) {
    return this.props.permissionProfileGetEntity(...values);
  }
  permissionProfileCreateEntity(...values) {
    values[0]['profile'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['createEntityPermissionProfile'](...values);
    }
    return this.props.permissionProfileCreateEntity(...values);
  }
  permissionProfileDeleteEntity(...values) {
    if (!this.state.isNew) {
      this.props['deleteEntityPermissionProfile'](...values);
    }
    return this.props.permissionProfileDeleteEntity(...values);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateSuccess !== this.props.updateSuccess && prevProps.updateSuccess) {
      this.handleClose();
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.profileEntity && prevProps.profileEntity.id !== this.props.profileEntity.id) ||
      (this.props.permissionProfiles.length > 0 &&
        this.state.permissionProfileSelectValue === null &&
        this.props.profileEntity.permissionProfile)
    ) {
      this.setState({ permissionProfileSelectValue: this.props.profileEntity.permissionProfile });
      this.props.permissionProfileSetEntities(this.props.profileEntity.permissionProfile);
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.profileEntity && prevProps.profileEntity.id !== this.props.profileEntity.id) ||
      (this.props.users && this.state.userSelectValue === null && this.props.profileEntity.user && this.props.profileEntity.user.id)
    ) {
      const p = this.props.profileEntity && this.props.profileEntity.user ? this.props.profileEntity.user : null;
      this.setState({
        userSelectValue: p ? { ...p, value: p.id, label: p['fullname'] ? p.fullname : '' } : null,
      });
    }

    if (
      (prevProps.profileEntity && prevProps.profileEntity.id !== this.props.profileEntity.id) ||
      (this.props.profileEntity.name && this.state.nameSelectValue === null && this.props.profileEntity.name !== this.state.nameSelectValue)
    ) {
      this.setState({ nameSelectValue: this.props.profileEntity.name ? this.props.profileEntity.name : null });
    }
    if (
      (prevProps.profileEntity && prevProps.profileEntity.id !== this.props.profileEntity.id) ||
      (this.state.statusSelectValue === null && this.props.profileEntity.status !== this.state.statusSelectValue)
    ) {
      this.setState({ statusSelectValue: this.props.profileEntity.status ? this.props.profileEntity.status : false });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  UNSAFE_componentWillMount() {
    if (this.state.isNew) {
      this.props.reset();
      this.permissionProfileReset();
    } else {
      this.props.getEntity(this.props.match.params['id']);
    }
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = offset !== null ? '&offset=' + offset : '';
    Object.keys(fieldsBase).map(key => {
      url += key !== 'offset' ? '&' + key + '=' + fieldsBase[key] : '';
    });
    return url;
  };
  saveEntity = (event: any, errors: any, values: any) => {
    const selectFieldsList = ['name', 'status'];

    if (errors.length === 0) {
      const { profileEntity } = this.props;
      const _entity = {
        ...profileEntity,
        // ...values,

        permissionProfile: undefined,

        name: this.state.nameSelectValue,
        status: this.state.statusSelectValue === null ? false : this.state.statusSelectValue,
      };

      const entity = _entity;

      const { name, status, permissionProfileId, userId, extraFilters, activePage, itemsPerPage, sort, order } = this.state.fieldsBase;

      const permissionProfileListAllEmbed = this.props.permissionProfileListAllEmbed;

      if (this.state.isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              name,
              status,
              getFilterFromSelect(permissionProfileId, 'one-to-many'),
              getFilterFromSelect(userId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
          if (idEntity) {
            permissionProfileListAllEmbed.map(v => {
              v['id'] = null;
              v['profile'] = { id: idEntity };
              this.props['createEntityPermissionProfile'](v);
            });
          }
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              name,
              status,
              getFilterFromSelect(permissionProfileId, 'one-to-many'),
              getFilterFromSelect(userId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/profile?' + getEntityFiltersURL(this.state.fieldsBase));
  };

  getFullEntityFromState(v) {
    return {
      id: v.id,
      name: this.state.nameSelectValue,
      status: this.state.statusSelectValue,
      permissionProfile: this.state.permissionProfileSelectValue,
      user: this.state.userSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    const permissionProfileEntity = v.permissionProfile;

    const userEntity = v.user;

    return {
      nameSelectValue: v.name,
      statusSelectValue: v.status,
      permissionProfileSelectValue: permissionProfileEntity ? permissionProfileEntity['id'] : null,
      userSelectValue: userEntity ? userEntity['id'] : null,
    };
  }

  renderHeader() {
    const { profileEntity, permissionProfiles, users, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.profile.home.createLabel">Create a Profile</Translate>
            ) : (
              <Translate contentKey="generadorApp.profile.home.editLabel">Edit a Profile</Translate>
            )}
          </span>

          <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.profile.btnSave">Save</Translate>
          </Button>

          {!this.state.isNew ? (
            <>
              <Button
                color="primary"
                id="next-entity"
                disabled={updating}
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.name,
                    this.state.fieldsBase.status,
                    this.state.fieldsBase.permissionProfileId,
                    this.state.fieldsBase.userId,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 + 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 + 1 },
                    });
                    this.props.history.push(
                      '/profile/' + myresult.value.data[0]['id'] + '/edit?' + this.getFiltersURL(this.state.fieldsBase.offset * 1 + 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.last.item', { fieldName: translate('generadorApp.profile.detail.title') }));
                  }
                }}
                className="float-right jh-create-entity"
              >
                {'>>'}
              </Button>

              <Button
                color="primary"
                id="prev-entity"
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.name,
                    this.state.fieldsBase.status,
                    this.state.fieldsBase.permissionProfileId,
                    this.state.fieldsBase.userId,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 - 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 - 1 },
                    });
                    this.props.history.push(
                      '/profile/' + myresult.value.data[0]['id'] + '/edit?' + this.getFiltersURL(this.state.fieldsBase.offset * 1 - 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.first.item', { fieldName: translate('generadorApp.profile.detail.title') }));
                  }
                }}
                disabled={updating}
                className="float-right jh-create-entity"
              >
                {'<<'}
              </Button>
            </>
          ) : (
            <> </>
          )}

          <Button
            tag={Link}
            id="cancel-save"
            to={'/profile?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.profile.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={'/profile'}>
              <Translate contentKey="generadorApp.profile.home.title">Profiles</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.profile.detail.title">Profile edit</Translate>
          </li>
        </ol>
      </>
    );
  }
  renderBody() {
    const { profileEntity, permissionProfiles, users, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const fullEntityEmbebed = this.getFullEntityFromState(profileEntity);

    const mapDispatchToPropsPermissionProfileList: any = {};
    Object.keys(mapDispatchToPropsPermissionProfile).map(v => {
      mapDispatchToPropsPermissionProfileList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'PermissionProfile')) {
          this.setState({ functionEmbebed: [...this.state.functionEmbebed, v + 'PermissionProfile'] }, this.props[v + 'PermissionProfile']);
        }
      };
    });

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="profile-id">
                  <Translate contentKey="generadorApp.profile.field_id">ID</Translate>
                </Label>
                </Col> */}
                      <Col md="12">
                        <AvInput id="profile-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'name' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="nameLabel" for="profile-name">
                              <Translate contentKey="generadorApp.profile.name">Name</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                              value={this.state.nameSelectValue ? this.state.nameSelectValue : ''}
                              id="profile-name"
                              type="text"
                              name="name"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                      type="hidden"
                      name="name"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'permissionProfile' ? (
                    <Col md="12">
                      <div className="entityEmbebedList">
                        <PermissionProfile
                          entityEmbebedPai={fullEntityEmbebed}
                          history={this.props.history}
                          location={{
                            pathname: `/profile/${this.props.match.params['id']}/permission-profile/`,
                            search: '',
                            hash: '',
                            state: undefined,
                            key: 'q2tbhd',
                          }}
                          match={{
                            path: `/profile/:idProfile/permission-profile/:id`,
                            url: `/profile/${this.props.match.params['id']}/permission-profile/`,
                            isExact: true,
                            params: {
                              idProfile: this.props.match.params['id'],
                              url: `/profile/${this.props.match.params['id']}/permission-profile/`,
                            },
                          }}
                          {...this.props.mapStateToPropsPermissionProfile}
                          {...mapDispatchToPropsPermissionProfileList}
                          getEntityPai={(...v) => this.props.getEntity(...v)}
                          getEntities={this.permissionProfileGetEntities}
                          updateEntity={this.permissionProfileUpdateEntity}
                          getEntity={this.permissionProfileGetEntity}
                          createEntity={this.permissionProfileCreateEntity}
                          deleteEntity={this.permissionProfileDeleteEntity}
                          reset={this.permissionProfileReset}
                          permissionProfileList={this.props.permissionProfileListEmbed}
                          totalItems={this.props.permissionProfileListEmbed ? this.props.permissionProfileListEmbed.length : 0}
                          loading={this.props.loading}
                          permissionProfileEntity={this.props.permissionProfileSelected}
                        />
                      </div>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="permissionProfile"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}{' '}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { profileEntity, permissionProfiles, users, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...profileEntity,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel className="profile profile-update">
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToProps = (storeState: IRootState) => {
  return {
    permissionProfiles: storeState.permissionProfile.entities,
    users: storeState.userManagement.users,
    profileEntity: storeState.profile.entity,
    loading: storeState.profile.loading,
    updating: storeState.profile.updating,
    updateSuccess: storeState.profile.updateSuccess,

    profiles: storeState.profile.entities,
    permissionProfileListAllEmbed: storeState.profile.permissionProfileListAllEmbed,
    permissionProfileListEmbed: storeState.profile.permissionProfileListEmbed,
    permissionProfileSelected: storeState.profile.permissionProfileSelected,
    mapStateToPropsPermissionProfile: mapStateToPropsPermissionProfile(storeState),
  };
};

const mapDispatchToPropsPermissionProfileList = {};
Object.keys(mapDispatchToPropsPermissionProfile).map(v => {
  mapDispatchToPropsPermissionProfileList[v + 'PermissionProfile'] = mapDispatchToPropsPermissionProfile[v];
});

export const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  getEntityOffset,
  reset,

  ...mapDispatchToPropsPermissionProfileList,
  permissionProfileUpdateEntity,
  permissionProfileGetEntities,
  permissionProfileSetEntities,
  permissionProfileReset,
  permissionProfileGetEntity,
  permissionProfileCreateEntity,
  permissionProfileDeleteEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProfileUpdate);
