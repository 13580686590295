/* eslint complexity: ["error", 500] */
import React from 'react';
import axios from 'axios';

import CurrencyInput from 'react-currency-input';
import { connect } from 'react-redux';

import {
  getFilterFromSelect,
  getFormFromSelect,
  getListAxios,
  getValueRecursive,
  showFieldsSelectAsync,
} from 'app/shared/util/entity-utils';

import { toast } from 'react-toastify';
import SelectAsync from 'react-select/async';
import { Link, RouteComponentProps } from 'react-router-dom';

import { Button, Col, Row, Table, Label, UncontrolledCollapse, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { JhiItemCount } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { IRootState } from 'app/shared/reducers';
import {
  updateEntity,
  getUrlBack,
  getSupplierTableMedicineState,
  ISupplierTableMedicineBaseState,
  getEntityFiltersURL,
  getEntities,
  ISupplierTableMedicineUpdateState as IBaseUpdateState,
  reset,
  getEntity,
  deleteEntity,
  createEntity,
} from './supplier-table-medicine.reducer';

import { ITEMS_PER_PAGE, IPaginationBaseState, getSortState } from 'app/shared/util/pagination.constants';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { RenderModalSuperSelect } from 'app/shared/layout/components/RenderModalSuperSelect';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

export interface ISupplierTableMedicineProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
  entityEmbebedPai?: any;
}

export interface ISupplierTableMedicineState extends ISupplierTableMedicineBaseState, IPaginationBaseState, IBaseUpdateState {
  showModalForm?: boolean;
  showModalEdit?: number;
  showModalEditView?: boolean;
}

export class SupplierTableMedicine extends React.Component<ISupplierTableMedicineProps, ISupplierTableMedicineState> {
  protected myFormRef: any;
  protected myFormRefNew: any;
  protected myFormRefEdit: any;

  descriptionFileInput: any;

  priceFileInput: any;

  constructor(props: ISupplierTableMedicineProps) {
    super(props);
    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      listCheckedID: [],
      isCheckedAll: false,
      showModalForm: false,
      showModalEdit: null,
      urlBack: getUrlBack(this.props.location),
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      ...getSupplierTableMedicineState(this.props.location),
      supplierTableId: [
        {
          ...(this.props.entityEmbebedPai ? this.props.entityEmbebedPai : {}),
          value: this.props.match.params['idSupplierTable'],
          label: this.props.match.params['idSupplierTable'],
        },
      ],
      baseFilters: 'supplierTable',
      supplierTableSelectValue: {
        ...(this.props.entityEmbebedPai ? this.props.entityEmbebedPai : {}),
        value: this.props.match.params['idSupplierTable'],
        label: this.props.match.params['idSupplierTable'],
      },

      medicineMaterialSelectValue: null,

      presentationSelectValue: null,

      descriptionSelectValue: null,
      priceSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getSupplierTableMedicineState(this.props.location),
        supplierTableId: [
          {
            ...(this.props.entityEmbebedPai ? this.props.entityEmbebedPai : {}),
            value: this.props.match.params['idSupplierTable'],
            label: this.props.match.params['idSupplierTable'],
          },
        ],
        baseFilters: 'supplierTable',
      },
      supplierTableName: null,
      medicineMaterialName: null,
      presentationName: null,
      isNew: false,
    };

    axios
      .get('/api/supplier-tables/' + this.props.match.params['idSupplierTable'], {
        headers: {
          'Select-Fields': 'name',
        },
      })
      .then(result => {
        this.setState({
          supplierTableSelectValue: {
            ...result['data'],
            value: this.props.match.params['idSupplierTable'],
            label: showFieldsSelectAsync(result['data'], 'name'),
          },
        });
      });
  }

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({ listCheckedID: this.props.supplierTableMedicineList.map(supplierTableMedicine => supplierTableMedicine.id) });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({ listCheckedID: this.props.supplierTableMedicineList.map(supplierTableMedicine => supplierTableMedicine.id) });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  removeEntitySelected() {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  }

  saveEntityMany = () => {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  };

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  cancelFilters = () => {
    this.setState(
      {
        medicineMaterialId: '',
        description: '',
        presentationId: '',
        price: '',
        supplierTableId: '',
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
        activePage: 0,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();

    const selectFieldsList = ['medicineMaterial.name', 'description', 'presentation.name', 'price', 'supplierTable.name'];
    const {
      medicineMaterialId,
      description,
      presentationId,
      price,
      supplierTableId,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;
    return this.props.getEntities(
      getFilterFromSelect(medicineMaterialId, 'many-to-one'),
      description,
      getFilterFromSelect(presentationId, 'many-to-one'),
      price,
      getFilterFromSelect(supplierTableId, 'many-to-one'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(',')
    );
  };

  updateEntity = entity => {
    const selectFieldsList = ['medicineMaterial.name', 'description', 'presentation.name', 'price', 'supplierTable.name'];

    const {
      medicineMaterialId,
      description,
      presentationId,
      price,
      supplierTableId,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;
    this.props.updateEntity(entity, [
      getFilterFromSelect(medicineMaterialId, 'many-to-one'),
      description,
      getFilterFromSelect(presentationId, 'many-to-one'),
      price,
      getFilterFromSelect(supplierTableId, 'many-to-one'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(','),
    ]);
  };

  saveEntity = (event: any, errors: any, values: any, isNew: any) => {
    if (!this.state.descriptionSelectValue) {
      toast.error(
        translate('generadorApp.validators.required', { fieldName: translate('generadorApp.supplierTableMedicine.description') })
      );
      return false;
    }

    const selectFieldsList = ['medicineMaterial.name', 'description', 'presentation.name', 'price', 'supplierTable.name'];

    if (errors.length === 0) {
      const { supplierTableMedicineEntity } = this.props;
      const _entity = {
        ...supplierTableMedicineEntity,
        // ...values,

        medicineMaterial: getFormFromSelect(this.state.medicineMaterialSelectValue, 'many-to-one'), // many-to-one - otherSide

        presentation: getFormFromSelect(this.state.presentationSelectValue, 'many-to-one'), // many-to-one - otherSide

        supplierTable: getFormFromSelect(this.state.supplierTableSelectValue, 'many-to-one'), // many-to-one - otherSide

        description: this.state.descriptionSelectValue,
        price: this.state.priceSelectValue,
      };
      const entity = _entity;

      const {
        medicineMaterialId,
        description,
        presentationId,
        price,
        supplierTableId,
        extraFilters,
        activePage,
        itemsPerPage,
        sort,
        order,
      } = this.state.fieldsBase;

      if (isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              getFilterFromSelect(medicineMaterialId, 'many-to-one'),
              description,
              getFilterFromSelect(presentationId, 'many-to-one'),
              price,
              getFilterFromSelect(supplierTableId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              getFilterFromSelect(medicineMaterialId, 'many-to-one'),
              description,
              getFilterFromSelect(presentationId, 'many-to-one'),
              price,
              getFilterFromSelect(supplierTableId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
      this.setState({ showModalForm: false });
      this.setState({ showModalEdit: null });
    } else {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.specialty.' + errors[0]) }));
    }
  };

  componentDidUpdate(prevProps) {}

  deleteEntity(supplierTableMedicineEntity) {
    MySwal.fire({
      title: <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>,
      html: (
        <Translate contentKey="generadorApp.supplierTableMedicine.delete.question" interpolate={{ id: supplierTableMedicineEntity.id }}>
          Are you sure you want to delete this SupplierTableMedicine?
        </Translate>
      ),
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#e70015',
      cancelButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="ban" />
          &nbsp; <Translate contentKey="generadorApp.supplierTableMedicine.btnCancel">Cancel</Translate>
        </>
      ),
      confirmButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="trash" />
          &nbsp;<Translate contentKey="generadorApp.supplierTableMedicine.btnDelete">Delete</Translate>
        </>
      ),
    }).then(async response => {
      if (response.isConfirmed) {
        await this.props.deleteEntity(supplierTableMedicineEntity.id, { reloadList: false });
        this.getEntities();
        MySwal.fire({
          title: <Translate contentKey="entity.delete.title.confirmed">Confirmed delete operation</Translate>,
          icon: 'success',
        });
      }
    });
  }

  renderNewModal() {
    return (
      <Modal size={'xl'} isOpen={this.state.showModalForm} toggle={() => this.setState({ showModalForm: false })}>
        <ModalHeader toggle={() => this.setState({ showModalForm: false })}>{this.renderHeaderUpdate({}, true)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefNew = el)}
          model={{}}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, true)}
        >
          <ModalBody>{this.renderBodyUpdate({}, true)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalForm: false })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.supplierTableMedicine.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefNew && this.myFormRefNew.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.supplierTableMedicine.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    );
  }

  getFullEntityFromState(v) {
    return {
      id: v.id,
      description: this.state.descriptionSelectValue,
      price: this.state.priceSelectValue,
      supplierTable: this.state.supplierTableSelectValue,
      medicineMaterial: this.state.medicineMaterialSelectValue,
      presentation: this.state.presentationSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    let p: any = null;

    p = v.supplierTable;
    const supplierTableEntity = p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : p;

    p = v.medicineMaterial;
    const medicineMaterialEntity = p
      ? {
          ...p,
          value: p.id,
          label: (p['categories'] && p['categories']['name'] ? p.categories.name : '') + ' | ' + (p['name'] ? p.name : ''),
        }
      : p;

    p = v.presentation;
    const presentationEntity = p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : p;

    return {
      descriptionSelectValue: v.description, // description,
      priceSelectValue: v.price, // price,
      supplierTableSelectValue: supplierTableEntity,
      medicineMaterialSelectValue: medicineMaterialEntity,
      presentationSelectValue: presentationEntity,
    };
  }

  openUpdateModal(v, edit = true) {
    this.props.getEntity(v.id);
    this.setState({
      showModalEdit: v.id,
      showModalEditView: edit,
      ...this.getFullEntitySelectValue(v),
    });
  }

  renderUpdateModal() {
    const { supplierTableMedicineEntity, entityEmbebedPai } = this.props;
    return supplierTableMedicineEntity && supplierTableMedicineEntity.id ? (
      <Modal
        size={'xl'}
        isOpen={this.state.showModalEdit === supplierTableMedicineEntity.id}
        toggle={() => this.setState({ showModalEdit: null })}
      >
        <ModalHeader toggle={() => this.setState({ showModalEdit: null })}>
          {this.renderHeaderUpdate(supplierTableMedicineEntity, false)}
        </ModalHeader>
        <AvForm
          ref={el => (this.myFormRefEdit = el)}
          model={{
            ...supplierTableMedicineEntity,
          }}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, false)}
        >
          <ModalBody>{this.renderBodyUpdate(supplierTableMedicineEntity, false)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalEdit: null })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.supplierTableMedicine.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefEdit && this.myFormRefEdit.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.supplierTableMedicine.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    ) : (
      <> </>
    );
  }

  renderHeaderUpdate(supplierTableMedicineEntity, isNew) {
    const { entityEmbebedPai, supplierTables, medicineMaterials, presentations, loading } = this.props;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {isNew ? (
              <Translate contentKey="generadorApp.supplierTableMedicine.home.createLabel">Create a SupplierTableMedicine</Translate>
            ) : (
              <Translate contentKey="generadorApp.supplierTableMedicine.home.editLabel">Edit a SupplierTableMedicine</Translate>
            )}
          </span>
        </div>
      </>
    );
  }
  renderBodyUpdate(supplierTableMedicineEntity, isNew) {
    const { entityEmbebedPai, supplierTables, medicineMaterials, presentations, loading } = this.props;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
              <Col md="3">
              <Label className="mt-2 label-single-line"for="supplier-table-medicine-id">
                <Translate contentKey="generadorApp.supplierTableMedicine.field_id">ID</Translate>
              </Label>
              </Col> */}
                      <Col md="12">
                        <AvInput id="supplier-table-medicine-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'medicineMaterial' ? (
                    <Col md="12">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="supplier-table-medicine-medicineMaterial">
                              <Translate contentKey="generadorApp.supplierTableMedicine.medicineMaterial">Medicine Material</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="supplier-table-medicine-medicineMaterial"
                              name={'medicineMaterial'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.medicineMaterialSelectValue ? this.state.medicineMaterialSelectValue : ''}
                              onChange={options => this.setState({ medicineMaterialSelectValue: options })}
                              defaultOptions={
                                this.state.medicineMaterialStartSelectOptions ? this.state.medicineMaterialStartSelectOptions : []
                              }
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.medicineMaterialStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.medicineMaterialStartSelectOptions === undefined) {
                                  const result = await getListAxios(
                                    'medicine-materials',
                                    { 'medicineMaterialType.equals': 'MEDICAMENTO' },
                                    0,
                                    100,
                                    'categories,asc',
                                    'categories.name,name'
                                  );
                                  this.setState({
                                    medicineMaterialStartSelectOptions: result.data
                                      ? result.data.map(p => ({
                                          ...p,
                                          value: p.id,
                                          label: showFieldsSelectAsync(p, 'categories.name;name') || '',
                                        }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'medicine-materials',
                                  { 'medicineMaterialType.equals': 'MEDICAMENTO', 'name.contains': inputValue },
                                  0,
                                  100,
                                  'categories,asc',
                                  'categories.name,name'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({
                                        ...p,
                                        value: p.id,
                                        label: showFieldsSelectAsync(p, 'categories.name;name') || '',
                                      }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                          <RenderModalSuperSelect
                            baseState={this.state}
                            setState={_state => this.setState(_state)}
                            entity={'medicineMaterial'}
                            listEntity={'medicine-materials'}
                            template={'categories.name;name'.split(';')}
                            stateField={'medicineMaterialSelectValue'}
                            multiple={false}
                            showFields={'categories.name;name'.split(';')}
                            order={'categories,asc'}
                            filtersBase={{ 'medicineMaterialType.equals': 'MEDICAMENTO' }}
                          />
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="medicineMaterial"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}
                  {baseFilters !== 'presentation' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="supplier-table-medicine-presentation">
                              <Translate contentKey="generadorApp.supplierTableMedicine.presentation">Presentation</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="supplier-table-medicine-presentation"
                              name={'presentation'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.presentationSelectValue ? this.state.presentationSelectValue : ''}
                              onChange={options => this.setState({ presentationSelectValue: options })}
                              defaultOptions={this.state.presentationStartSelectOptions ? this.state.presentationStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.presentationStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.presentationStartSelectOptions === undefined) {
                                  const result = await getListAxios('presentations', {}, 0, 100, 'name,asc', 'name');
                                  this.setState({
                                    presentationStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'presentations',
                                  { 'name.contains': inputValue },
                                  0,
                                  100,
                                  'name,asc',
                                  'name'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="presentation"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}
                  {baseFilters !== 'price' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="priceLabel" for="supplier-table-medicine-price">
                              <Translate contentKey="generadorApp.supplierTableMedicine.price">Price</Translate>
                            </Label>
                          </Col>

                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ priceSelectValue: floatvalue })}
                              decimalSeparator=","
                              precision={2}
                              thousandSeparator=""
                              prefix="R$ "
                              allowNegative={true}
                              value={this.state.priceSelectValue ? this.state.priceSelectValue : 0}
                              id="supplier-table-medicine-price"
                              name="price"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ priceSelectValue: evt.target.value })}
                      type="hidden"
                      name="price"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'description' ? (
                    <Col md="12">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="descriptionLabel" for="supplier-table-medicine-description">
                              <Translate contentKey="generadorApp.supplierTableMedicine.description">Description</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ descriptionSelectValue: evt.target.value })}
                              value={this.state.descriptionSelectValue ? this.state.descriptionSelectValue : ''}
                              id="supplier-table-medicine-description"
                              type="text"
                              name="description"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ descriptionSelectValue: evt.target.value })}
                      type="hidden"
                      name="description"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'supplierTable' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="supplier-table-medicine-supplierTable">
                              <Translate contentKey="generadorApp.supplierTableMedicine.supplierTable">Supplier Table</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="supplier-table-medicine-supplierTable"
                              name={'supplierTable'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.supplierTableSelectValue ? this.state.supplierTableSelectValue : ''}
                              onChange={options => this.setState({ supplierTableSelectValue: options })}
                              defaultOptions={this.state.supplierTableStartSelectOptions ? this.state.supplierTableStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.supplierTableStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.supplierTableStartSelectOptions === undefined) {
                                  const result = await getListAxios('supplier-tables', {}, 0, 100, 'name,asc', 'name');
                                  this.setState({
                                    supplierTableStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'supplier-tables',
                                  { 'name.contains': inputValue },
                                  0,
                                  100,
                                  'name,asc',
                                  'name'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="supplierTable"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}{' '}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  renderHeader() {
    const { supplierTables, medicineMaterials, presentations, supplierTableMedicineList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.supplierTableMedicine.home.title">Medicines</Translate>
          </span>
          <Button id="togglerFilterSupplierTableMedicine" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.supplierTableMedicine.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          &nbsp;
          <Button
            onClick={() =>
              this.setState({
                showModalForm: true,
                descriptionSelectValue: null,
                priceSelectValue: null,

                medicineMaterialStartSelectOptions: undefined,
                medicineMaterialSelectValue: null,

                presentationStartSelectOptions: undefined,
                presentationSelectValue: null,
                isNew: true,
              })
            }
            color="primary"
            className="btn btn-primary float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="generadorApp.supplierTableMedicine.btnNewItem">Create new</Translate>
          </Button>{' '}
          &nbsp;
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/supplierTable'}>
              <Translate contentKey="generadorApp.supplierTable.home.title">SupplierTables</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/supplierTable/' + this.props.match.params['idSupplierTable']}>
              {this.state.supplierTableSelectValue ? this.state.supplierTableSelectValue.label : this.props.match.params['idSupplierTable']}
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.supplierTableMedicine.home.title">Medicines</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    const { supplierTables, medicineMaterials, presentations, supplierTableMedicineList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <UncontrolledCollapse toggler="#togglerFilterSupplierTableMedicine">
          <CardBody>
            <AvForm ref={el => (this.myFormRef = el)} id="form-filter" onSubmit={this.filterEntity}>
              <div className="row mt-1 ml-3 mr-3">
                {this.state.baseFilters !== 'medicineMaterial' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="supplier-table-medicine-medicineMaterial">
                          <Translate contentKey="generadorApp.supplierTableMedicine.medicineMaterial">Medicine Material</Translate>
                        </Label>
                        <SelectAsync
                          id="supplier-table-medicine-medicineMaterial"
                          isMulti
                          className={'css-select-control'}
                          name={'medicineMaterial'}
                          cacheOptions
                          value={this.state.medicineMaterialId}
                          onChange={options => this.setState({ medicineMaterialId: options })}
                          defaultOptions={this.state.medicineMaterialStartFilter ? this.state.medicineMaterialStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.medicineMaterialStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.medicineMaterialStartFilter === undefined) {
                              const result = await getListAxios('medicine-materials', {}, 0, 100, 'name,asc', 'name');
                              this.setState({
                                medicineMaterialStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios(
                              'medicine-materials',
                              { 'name.contains': inputValue },
                              0,
                              100,
                              'name,asc',
                              'name'
                            );
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'description' ? (
                  <Col md="3" className="col-filter-supplier-table-medicine-description">
                    <Row className="mr-1 mt-1">
                      <Label id="descriptionLabel" for="supplier-table-medicine-description">
                        <Translate contentKey="generadorApp.supplierTableMedicine.description">Description</Translate>
                      </Label>

                      <AvInput type="text" name="description" id="supplier-table-medicine-description" value={this.state.description} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'presentation' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="supplier-table-medicine-presentation">
                          <Translate contentKey="generadorApp.supplierTableMedicine.presentation">Presentation</Translate>
                        </Label>
                        <SelectAsync
                          id="supplier-table-medicine-presentation"
                          isMulti
                          className={'css-select-control'}
                          name={'presentation'}
                          cacheOptions
                          value={this.state.presentationId}
                          onChange={options => this.setState({ presentationId: options })}
                          defaultOptions={this.state.presentationStartFilter ? this.state.presentationStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.presentationStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.presentationStartFilter === undefined) {
                              const result = await getListAxios('presentations', {}, 0, 100, 'name,asc', 'name');
                              this.setState({
                                presentationStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios('presentations', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'price' ? (
                  <Col md="3" className="col-filter-supplier-table-medicine-price">
                    <Row className="mr-1 mt-1">
                      <Label id="priceLabel" for="supplier-table-medicine-price">
                        <Translate contentKey="generadorApp.supplierTableMedicine.price">Price</Translate>
                      </Label>

                      <AvInput type="text" name="price" id="supplier-table-medicine-price" value={this.state.price} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'supplierTable' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="supplier-table-medicine-supplierTable">
                          <Translate contentKey="generadorApp.supplierTableMedicine.supplierTable">Supplier Table</Translate>
                        </Label>
                        <SelectAsync
                          id="supplier-table-medicine-supplierTable"
                          isMulti
                          className={'css-select-control'}
                          name={'supplierTable'}
                          cacheOptions
                          value={this.state.supplierTableId}
                          onChange={options => this.setState({ supplierTableId: options })}
                          defaultOptions={this.state.supplierTableStartFilter ? this.state.supplierTableStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.supplierTableStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.supplierTableStartFilter === undefined) {
                              const result = await getListAxios('supplier-tables', {}, 0, 100, 'name,asc', 'name');
                              this.setState({
                                supplierTableStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios(
                              'supplier-tables',
                              { 'name.contains': inputValue },
                              0,
                              100,
                              'name,asc',
                              'name'
                            );
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}
              </div>

              <div className="row mb-2 mr-4 justify-content-end">
                <Button color="primary" type="submit">
                  <i className="fa fa-filter" aria-hidden={'true'}></i>&nbsp;
                  <Translate contentKey="generadorApp.supplierTableMedicine.btnFilter">Filter</Translate>
                </Button>
                &nbsp;
                <Button color="secondary" onClick={this.cancelFilters}>
                  <FontAwesomeIcon icon="trash-alt" />
                  &nbsp;
                  <Translate contentKey="generadorApp.supplierTableMedicine.btnCleanFilter">Clean Filter</Translate>
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </UncontrolledCollapse>
      </>
    );
  }

  renderTable() {
    const { supplierTables, medicineMaterials, presentations, supplierTableMedicineList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {supplierTableMedicineList &&
        supplierTableMedicineList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null).length > 0 ? (
          <>
            <Table
              id="supplier-table-medicine-table-list"
              responsive
              aria-describedby="supplier-table-medicine-heading"
              className={'table-hover table-striped table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.supplierTableMedicine.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'medicineMaterial' ? (
                    <th>
                      <Translate contentKey="generadorApp.supplierTableMedicine.medicineMaterial">Medicine Material</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'description' ? (
                    <th className="hand" onClick={sortFunction('description')}>
                      <Translate contentKey="generadorApp.supplierTableMedicine.description">Description</Translate>
                      <FontAwesomeIcon icon={state.sort === 'description' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'presentation' ? (
                    <th>
                      <Translate contentKey="generadorApp.supplierTableMedicine.presentation">Presentation</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'price' ? (
                    <th className="hand" onClick={sortFunction('price')}>
                      <Translate contentKey="generadorApp.supplierTableMedicine.price">Price</Translate>
                      <FontAwesomeIcon icon={state.sort === 'price' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'supplierTable' ? (
                    <th>
                      <Translate contentKey="generadorApp.supplierTableMedicine.supplierTable">Supplier Table</Translate>
                    </th>
                  ) : null}

                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {supplierTableMedicineList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((supplierTableMedicine, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.supplierTableMedicine.field_id">ID</Translate>
                        </b>
                        {supplierTableMedicine.id}
                      </td>

                      {state.baseFilters !== 'medicineMaterial' ? (
                        <td id="medicineMaterial-cell">
                          <b className="visible-xs"> Medicine Material </b>
                          {supplierTableMedicine ? (
                            <span data-type-rel="2-many-to-one-other-side">
                              {getValueRecursive(supplierTableMedicine, 'medicineMaterial.name'.split('.'), '')}
                            </span>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'description' ? (
                        <td id="description-cell">
                          <b className="visible-xs"> Description </b>

                          {supplierTableMedicine.description}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'presentation' ? (
                        <td id="presentation-cell">
                          <b className="visible-xs"> Presentation </b>
                          {supplierTableMedicine ? (
                            <span data-type-rel="2-many-to-one-other-side">
                              {getValueRecursive(supplierTableMedicine, 'presentation.name'.split('.'), '')}
                            </span>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'price' ? (
                        <td id="price-cell">
                          <b className="visible-xs"> Price </b>
                          R$ {(supplierTableMedicine.price * 1).toFixed(2).replace('.', ',')}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'supplierTable' ? (
                        <td id="supplierTable-cell">
                          <b className="visible-xs"> Supplier Table </b>
                          {supplierTableMedicine ? (
                            <span data-type-rel="2-many-to-one-other-side">
                              {getValueRecursive(supplierTableMedicine, 'supplierTable.name'.split('.'), '')}
                            </span>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : null}

                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          {hasAnyAuthority(props.userAccount, ['supplier'], 'edit') ? (
                            <Button onClick={() => this.openUpdateModal(supplierTableMedicine)} color="primary" size="sm">
                              <FontAwesomeIcon icon="pencil-alt" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                          {hasAnyAuthority(props.userAccount, ['supplier'], 'edit') ? (
                            <Button onClick={() => this.deleteEntity(supplierTableMedicine)} color="danger" size="sm">
                              <FontAwesomeIcon icon="trash" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.supplierTableMedicine.home.notFound">No Medicines found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const { supplierTables, medicineMaterials, presentations, supplierTableMedicineList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={supplierTableMedicineList && supplierTableMedicineList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const { supplierTables, medicineMaterials, presentations, supplierTableMedicineList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel className="supplier-table-medicine supplier-table-medicine-all-in-one">
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
            {this.renderNewModal()}
            {this.renderUpdateModal()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToProps = ({ supplierTableMedicine, ...storeState }: IRootState) => ({
  supplierTables: storeState.supplierTable.entities,
  medicineMaterials: storeState.medicineMaterial.entities,
  presentations: storeState.presentation.entities,
  supplierTableMedicineList: supplierTableMedicine.entities,
  totalItems: supplierTableMedicine.totalItems,
  userAccount: storeState.authentication.account,
  loading: supplierTableMedicine.loading,

  // UPDATE
  supplierTableMedicineEntity: supplierTableMedicine.entity,
});

export const mapDispatchToProps = {
  getEntities,

  updateEntity,
  reset,

  // UPDATE
  getEntity,
  createEntity,

  // DELETE
  deleteEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SupplierTableMedicine);
