import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { ICategoryStatusHistory } from 'app/shared/model/category-status-history.model';
import { ISpecialty } from 'app/shared/model/specialty.model';
import { IClComRuler } from 'app/shared/model/cl-com-ruler.model';
import { IClComItem } from 'app/shared/model/cl-com-item.model';
import { IProductService } from 'app/shared/model/product-service.model';
import { IPush } from 'app/shared/model/push.model';
import { IProfessional } from 'app/shared/model/professional.model';
import { ILevelGroup } from 'app/shared/model/level-group.model';
import { IMedicineMaterial } from 'app/shared/model/medicine-material.model';
import { IKit } from 'app/shared/model/kit.model';
import { Status } from 'app/shared/model/enumerations/status.model';

export interface ICategory {
  id?: number;
  name?: string;
  status?: Status;
  contract?: any;
  categoryStatusHistory?: ICategoryStatusHistory[];
  specialty?: ISpecialty[];
  clComRuler?: IClComRuler[];
  clComItem?: IClComItem[];
  productService?: IProductService[];
  push?: IPush[];
  professional?: IProfessional[];
  levelGroup?: ILevelGroup;
  medicineMaterials?: IMedicineMaterial[];
  kits?: IKit[];
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  categoryStatusHistory: {
    isMulti: false, // one-to-many - other-side
    entity: 'CategoryStatusHistory',
    urlParameters: {
      enpoint: 'category-status-histories',
      filters: 'category.id.equals',
    },
  },
  specialty: {
    isMulti: false, // one-to-many - other-side
    entity: 'Specialty',
    urlParameters: {
      enpoint: 'specialties',
      filters: 'category.id.equals',
    },
  },
  clComRuler: {
    isMulti: false, // one-to-many - other-side
    entity: 'ClComRuler',
    urlParameters: {
      enpoint: 'cl-com-rulers',
      filters: 'category.id.equals',
    },
  },
  clComItem: {
    isMulti: false, // one-to-many - other-side
    entity: 'ClComItem',
    urlParameters: {
      enpoint: 'cl-com-items',
      filters: 'category.id.equals',
    },
  },
  productService: {
    isMulti: false, // one-to-many - other-side
    entity: 'ProductService',
    urlParameters: {
      enpoint: 'product-services',
      filters: 'category.id.equals',
    },
  },
  push: {
    isMulti: false, // one-to-many - other-side
    entity: 'Push',
    urlParameters: {
      enpoint: 'pushes',
      filters: 'category.id.equals',
    },
  },
  professional: {
    isMulti: false, // one-to-many - other-side
    entity: 'Professional',
    urlParameters: {
      enpoint: 'professionals',
      filters: 'category.id.equals',
    },
  },
  levelGroup: {
    isMulti: true, // many-to-one - other-side
    entity: 'LevelGroup',
    urlParameters: {
      enpoint: 'level-groups',
      filters: 'category.id.equals',
    },
  },
  medicineMaterial: {
    isMulti: true, // many-to-many - other-side
    entity: 'MedicineMaterial',
    urlParameters: {
      enpoint: 'medicine-materials',
      filters: 'categories.id.equals',
    },
  },
  kit: {
    isMulti: true, // many-to-many - other-side
    entity: 'Kit',
    urlParameters: {
      enpoint: 'kits',
      filters: 'categories.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'category.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'category.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<ICategory> = {};
