import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { IMedicineMaterialStatus } from 'app/shared/model/medicine-material-status.model';
import { IKitDiet } from 'app/shared/model/kit-diet.model';
import { IKitMaterial } from 'app/shared/model/kit-material.model';
import { IKitMedicine } from 'app/shared/model/kit-medicine.model';
import { IQuoteDiet } from 'app/shared/model/quote-diet.model';
import { IQuoteMedicine } from 'app/shared/model/quote-medicine.model';
import { IQuoteMaterial } from 'app/shared/model/quote-material.model';
import { IClComItem } from 'app/shared/model/cl-com-item.model';
import { ISupplierTableDiet } from 'app/shared/model/supplier-table-diet.model';
import { ISupplierTableMaterial } from 'app/shared/model/supplier-table-material.model';
import { ISupplierTableMedicine } from 'app/shared/model/supplier-table-medicine.model';
import { IInventoryItem } from 'app/shared/model/inventory-item.model';
import { IPharmacyStock } from 'app/shared/model/pharmacy-stock.model';
import { IPatientAllergy } from 'app/shared/model/patient-allergy.model';
import { IPatDrPrescrProcd } from 'app/shared/model/pat-dr-prescr-procd.model';
import { ICategory } from 'app/shared/model/category.model';
import { ISimpro } from 'app/shared/model/simpro.model';
import { IBrasindice } from 'app/shared/model/brasindice.model';
import { IBrand } from 'app/shared/model/brand.model';
import { IPresentation } from 'app/shared/model/presentation.model';
import { IDose } from 'app/shared/model/dose.model';
import { ILevelGroup } from 'app/shared/model/level-group.model';
import { EnumTablePriceType } from 'app/shared/model/enumerations/enum-table-price-type.model';
import { Status } from 'app/shared/model/enumerations/status.model';
import { Ordinance } from 'app/shared/model/enumerations/ordinance.model';
import { MedicineMaterialType } from 'app/shared/model/enumerations/medicine-material-type.model';

export interface IMedicineMaterial {
  id?: number;
  table?: EnumTablePriceType;
  name?: string;
  minimumAmount?: number;
  expenseCode?: string;
  activePrinciple?: string;
  status?: Status;
  ordinance344?: Ordinance;
  medicineMaterialType?: MedicineMaterialType;
  inventoryControl?: boolean;
  fractioned?: boolean;
  specialControl?: boolean;
  thermolabile?: boolean;
  comments?: any;
  laboratory?: string;
  medicineMaterialStatus?: IMedicineMaterialStatus[];
  kitDiet?: IKitDiet[];
  kitMaterial?: IKitMaterial[];
  kitMedicine?: IKitMedicine[];
  quoteDiet?: IQuoteDiet[];
  quoteMedicine?: IQuoteMedicine[];
  quoteMaterial?: IQuoteMaterial[];
  clComItem?: IClComItem[];
  supplierTableDiet?: ISupplierTableDiet[];
  supplierTableMaterial?: ISupplierTableMaterial[];
  supplierTableMedicine?: ISupplierTableMedicine[];
  inventoryItem?: IInventoryItem[];
  pharmacyStock?: IPharmacyStock[];
  patientAllergy?: IPatientAllergy[];
  patDrPrescrProcd?: IPatDrPrescrProcd[];
  categories?: ICategory[];
  simpro?: ISimpro;
  brasindice?: IBrasindice;
  brand?: IBrand;
  presentation?: IPresentation;
  dose?: IDose;
  levelGroup?: ILevelGroup;
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  medicineMaterialStatus: {
    isMulti: false, // one-to-many - other-side
    entity: 'MedicineMaterialStatus',
    urlParameters: {
      enpoint: 'medicine-material-statuses',
      filters: 'medicineMaterial.id.equals',
    },
  },
  kitDiet: {
    isMulti: false, // one-to-many - other-side
    entity: 'KitDiet',
    urlParameters: {
      enpoint: 'kit-diets',
      filters: 'medicineMaterial.id.equals',
    },
  },
  kitMaterial: {
    isMulti: false, // one-to-many - other-side
    entity: 'KitMaterial',
    urlParameters: {
      enpoint: 'kit-materials',
      filters: 'medicineMaterial.id.equals',
    },
  },
  kitMedicine: {
    isMulti: false, // one-to-many - other-side
    entity: 'KitMedicine',
    urlParameters: {
      enpoint: 'kit-medicines',
      filters: 'medicineMaterial.id.equals',
    },
  },
  quoteDiet: {
    isMulti: false, // one-to-many - other-side
    entity: 'QuoteDiet',
    urlParameters: {
      enpoint: 'quote-diets',
      filters: 'medicineMaterial.id.equals',
    },
  },
  quoteMedicine: {
    isMulti: false, // one-to-many - other-side
    entity: 'QuoteMedicine',
    urlParameters: {
      enpoint: 'quote-medicines',
      filters: 'medicineMaterial.id.equals',
    },
  },
  quoteMaterial: {
    isMulti: false, // one-to-many - other-side
    entity: 'QuoteMaterial',
    urlParameters: {
      enpoint: 'quote-materials',
      filters: 'medicineMaterial.id.equals',
    },
  },
  clComItem: {
    isMulti: false, // one-to-many - other-side
    entity: 'ClComItem',
    urlParameters: {
      enpoint: 'cl-com-items',
      filters: 'materialMedicine.id.equals',
    },
  },
  supplierTableDiet: {
    isMulti: false, // one-to-many - other-side
    entity: 'SupplierTableDiet',
    urlParameters: {
      enpoint: 'supplier-table-diets',
      filters: 'medicineMaterial.id.equals',
    },
  },
  supplierTableMaterial: {
    isMulti: false, // one-to-many - other-side
    entity: 'SupplierTableMaterial',
    urlParameters: {
      enpoint: 'supplier-table-materials',
      filters: 'medicineMaterial.id.equals',
    },
  },
  supplierTableMedicine: {
    isMulti: false, // one-to-many - other-side
    entity: 'SupplierTableMedicine',
    urlParameters: {
      enpoint: 'supplier-table-medicines',
      filters: 'medicineMaterial.id.equals',
    },
  },
  inventoryItem: {
    isMulti: false, // one-to-many - other-side
    entity: 'InventoryItem',
    urlParameters: {
      enpoint: 'inventory-items',
      filters: 'medicineMaterial.id.equals',
    },
  },
  pharmacyStock: {
    isMulti: false, // one-to-many - other-side
    entity: 'PharmacyStock',
    urlParameters: {
      enpoint: 'pharmacy-stocks',
      filters: 'medicineMaterial.id.equals',
    },
  },
  patientAllergy: {
    isMulti: false, // one-to-many - other-side
    entity: 'PatientAllergy',
    urlParameters: {
      enpoint: 'patient-allergies',
      filters: 'medicineMaterial.id.equals',
    },
  },
  patDrPrescrProcd: {
    isMulti: false, // one-to-many - other-side
    entity: 'PatDrPrescrProcd',
    urlParameters: {
      enpoint: 'pat-dr-prescr-procds',
      filters: 'medicineMaterial.id.equals',
    },
  },
  category: {
    isMulti: true, // many-to-many - owner-side
    entity: 'Category',
    urlParameters: {
      enpoint: 'categories',
      filters: 'medicineMaterials.id.equals',
    },
  },
  simpro: {
    isMulti: true, // many-to-one - other-side
    entity: 'Simpro',
    urlParameters: {
      enpoint: 'simpros',
      filters: 'medicineMaterial.id.equals',
    },
  },
  brasindice: {
    isMulti: true, // many-to-one - other-side
    entity: 'Brasindice',
    urlParameters: {
      enpoint: 'brasindices',
      filters: 'medicineMaterial.id.equals',
    },
  },
  brand: {
    isMulti: true, // many-to-one - other-side
    entity: 'Brand',
    urlParameters: {
      enpoint: 'brands',
      filters: 'medicineMaterial.id.equals',
    },
  },
  presentation: {
    isMulti: true, // many-to-one - other-side
    entity: 'Presentation',
    urlParameters: {
      enpoint: 'presentations',
      filters: 'medicineMaterial.id.equals',
    },
  },
  dose: {
    isMulti: true, // many-to-one - other-side
    entity: 'Dose',
    urlParameters: {
      enpoint: 'doses',
      filters: 'medicineMaterial.id.equals',
    },
  },
  levelGroup: {
    isMulti: true, // many-to-one - other-side
    entity: 'LevelGroup',
    urlParameters: {
      enpoint: 'level-groups',
      filters: 'medicineMaterial.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'medicineMaterial.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'medicineMaterial.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<IMedicineMaterial> = {
  inventoryControl: false,
  fractioned: false,
  specialControl: false,
  thermolabile: false,
};
