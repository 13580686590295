/* eslint complexity: ["error", 500] */
/* eslint no-empty: off */
import React from 'react';
import { connect } from 'react-redux';

import SelectAsync from 'react-select/async';
import axios from 'axios';
import { Link, RouteComponentProps } from 'react-router-dom';

import { getFilterFromSelect, getFormFromSelect, getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Col, Label } from 'reactstrap';
import { setFileData } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import {
  IProfessionalFileUpdateState as IBaseUpdateState,
  getEntity,
  getProfessionalFileState,
  getUrlBack,
  updateEntity,
  createEntity,
  getEntityFiltersURL,
  getEntityOffset,
  setBlob,
  reset,
} from './professional-file.reducer';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

registerLocale('pt', pt);

export interface IProfessionalFileUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}
export type IProfessionalFileUpdateState = IBaseUpdateState;

export class ProfessionalFileUpdate extends React.Component<IProfessionalFileUpdateProps, IProfessionalFileUpdateState> {
  nameFileInput: any;

  filenameFileInput: any;
  constructor(props: Readonly<IProfessionalFileUpdateProps>) {
    super(props);

    this.filenameFileInput = React.createRef();

    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      functionEmbebed: [],

      professionalSelectValue: { value: this.props.match.params['idProfessional'], label: this.props.match.params['idProfessional'] },

      nameSelectValue: null,
      filenameSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getProfessionalFileState(this.props.location),
        urlBack: getUrlBack(this.props.location),
        professionalId: [{ value: this.props.match.params['idProfessional'], label: this.props.match.params['idProfessional'] }],
        baseFilters: 'professional',
      },
      professionalName: null,

      isNew: !this.props.match.params || !this.props.match.params['id'],
    };

    axios
      .get('/api/professionals/' + this.props.match.params['idProfessional'], {
        headers: {
          'Select-Fields': 'name',
        },
      })
      .then(result => {
        this.setState({
          professionalSelectValue: {
            ...result['data'],
            value: this.props.match.params['idProfessional'],
            label: showFieldsSelectAsync(result['data'], 'name'),
          },
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateSuccess !== this.props.updateSuccess && prevProps.updateSuccess) {
      this.handleClose();
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.professionalFileEntity && prevProps.professionalFileEntity.id !== this.props.professionalFileEntity.id) ||
      (this.props.professionals &&
        this.state.professionalSelectValue === null &&
        this.props.professionalFileEntity.professional &&
        this.props.professionalFileEntity.professional.id)
    ) {
      const p =
        this.props.professionalFileEntity && this.props.professionalFileEntity.professional
          ? this.props.professionalFileEntity.professional
          : null;
      this.setState({
        professionalSelectValue: p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : null,
      });
    }

    if (
      (prevProps.professionalFileEntity && prevProps.professionalFileEntity.id !== this.props.professionalFileEntity.id) ||
      (this.props.professionalFileEntity.name &&
        this.state.nameSelectValue === null &&
        this.props.professionalFileEntity.name !== this.state.nameSelectValue)
    ) {
      this.setState({ nameSelectValue: this.props.professionalFileEntity.name ? this.props.professionalFileEntity.name : null });
    }
    if (
      (prevProps.professionalFileEntity && prevProps.professionalFileEntity.id !== this.props.professionalFileEntity.id) ||
      (this.props.professionalFileEntity.filename &&
        this.state.filenameSelectValue === null &&
        this.props.professionalFileEntity.filename !== this.state.filenameSelectValue)
    ) {
      this.setState({
        filenameSelectValue: this.props.professionalFileEntity.filename ? this.props.professionalFileEntity.filename : null,
      });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  UNSAFE_componentWillMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params['id']);
    }
  }

  onBlobChange = (isAnImage, name, fileInput) => event => {
    const fileName = event.target.files[0].name;
    setFileData(event, (contentType, data) => this.props.setBlob(name, data, contentType, fileName), isAnImage);
    const varAux = {};
    varAux[name + 'SelectValue'] = fileName;
    this.setState(varAux);
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined);
  };
  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = offset !== null ? '&offset=' + offset : '';
    Object.keys(fieldsBase).map(key => {
      url += key !== 'offset' ? '&' + key + '=' + fieldsBase[key] : '';
    });
    return url;
  };
  saveEntity = (event: any, errors: any, values: any) => {
    if (!this.state.filenameSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.professionalFile.filename') }));
      return false;
    }

    const selectFieldsList = ['name', 'filename', 'professional.name'];

    if (errors.length === 0) {
      const { professionalFileEntity } = this.props;
      const _entity = {
        ...professionalFileEntity,
        // ...values,

        professional: getFormFromSelect(this.state.professionalSelectValue, 'many-to-one'), // many-to-one - otherSide

        name: this.state.nameSelectValue,
        filename: this.state.filenameSelectValue,
      };

      const entity = _entity;

      const { name, filename, professionalId, extraFilters, activePage, itemsPerPage, sort, order } = this.state.fieldsBase;

      if (this.state.isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              name,
              filename,
              getFilterFromSelect(professionalId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              name,
              filename,
              getFilterFromSelect(professionalId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
    }
  };

  handleClose = () => {
    this.props.history.push(
      '/professional/' + this.props.match.params['idProfessional'] + '/professional-file?' + getEntityFiltersURL(this.state.fieldsBase)
    );
  };

  getFullEntityFromState(v) {
    return {
      id: v.id,
      name: this.state.nameSelectValue,
      filename: this.state.filenameSelectValue,
      professional: this.state.professionalSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    const professionalEntity = v.professional;

    return {
      nameSelectValue: v.name,
      filenameSelectValue: v.filename,
      professionalSelectValue: professionalEntity ? professionalEntity['id'] : null,
    };
  }

  renderHeader() {
    const { professionalFileEntity, professionals, loading, updating } = this.props;
    const { isNew } = this.state;

    const { filename, filenameContentType, filenameBase64 } = professionalFileEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.professionalFile.home.createLabel">Create a ProfessionalFile</Translate>
            ) : (
              <Translate contentKey="generadorApp.professionalFile.home.editLabel">Edit a ProfessionalFile</Translate>
            )}
          </span>

          <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.professionalFile.btnSave">Save</Translate>
          </Button>

          {!this.state.isNew ? (
            <>
              <Button
                color="primary"
                id="next-entity"
                disabled={updating}
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.name,
                    this.state.fieldsBase.filename,
                    this.state.fieldsBase.professionalId,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 + 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 + 1 },
                    });
                    this.props.history.push(
                      '/professional-file/' +
                        myresult.value.data[0]['id'] +
                        '/edit?' +
                        this.getFiltersURL(this.state.fieldsBase.offset * 1 + 1)
                    );
                  } else {
                    toast.error(
                      translate('generadorApp.last.item', { fieldName: translate('generadorApp.professionalFile.detail.title') })
                    );
                  }
                }}
                className="float-right jh-create-entity"
              >
                {'>>'}
              </Button>

              <Button
                color="primary"
                id="prev-entity"
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.name,
                    this.state.fieldsBase.filename,
                    this.state.fieldsBase.professionalId,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 - 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 - 1 },
                    });
                    this.props.history.push(
                      '/professional-file/' +
                        myresult.value.data[0]['id'] +
                        '/edit?' +
                        this.getFiltersURL(this.state.fieldsBase.offset * 1 - 1)
                    );
                  } else {
                    toast.error(
                      translate('generadorApp.first.item', { fieldName: translate('generadorApp.professionalFile.detail.title') })
                    );
                  }
                }}
                disabled={updating}
                className="float-right jh-create-entity"
              >
                {'<<'}
              </Button>
            </>
          ) : (
            <> </>
          )}

          <Button
            tag={Link}
            id="cancel-save"
            to={
              '/professional/' +
              this.props.match.params['idProfessional'] +
              '/professional-file?' +
              getEntityFiltersURL(this.state.fieldsBase)
            }
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.professionalFile.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/professional'}>
              <Translate contentKey="generadorApp.professional.home.title">Professionals</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/professional/' + this.props.match.params['idProfessional']}>
              {this.state.professionalSelectValue ? this.state.professionalSelectValue.label : this.props.match.params['idProfessional']}
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/professional/' + this.props.match.params['idProfessional'] + '/professional-file'}>
              <Translate contentKey="generadorApp.professionalFile.home.title">Professional Files</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.professionalFile.detail.title">Professional File edit</Translate>
          </li>
        </ol>
      </>
    );
  }
  renderBody() {
    const { professionalFileEntity, professionals, loading, updating } = this.props;
    const { isNew } = this.state;

    const { filename, filenameContentType, filenameBase64 } = professionalFileEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const fullEntityEmbebed = this.getFullEntityFromState(professionalFileEntity);

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="professional-file-id">
                  <Translate contentKey="generadorApp.professionalFile.field_id">ID</Translate>
                </Label>
                </Col> */}
                      <Col md="12">
                        <AvInput id="professional-file-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'name' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="nameLabel" for="professional-file-name">
                              <Translate contentKey="generadorApp.professionalFile.name">Name</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                              value={this.state.nameSelectValue ? this.state.nameSelectValue : ''}
                              id="professional-file-name"
                              type="text"
                              name="name"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                      type="hidden"
                      name="name"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'filename' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="6">
                            <AvGroup>
                              <Row>
                                <Col md="12">
                                  <Label className="mt-2 label-single-line" id="filenameLabel" for="filename">
                                    <Translate contentKey="generadorApp.professionalFile.filename">Filename</Translate>(*)
                                  </Label>
                                </Col>
                                <Col md="12">
                                  {filename || filenameBase64 ? (
                                    <div>
                                      <Row>
                                        <Col md="11"></Col>
                                        <Col md="1">
                                          <Button color="danger" onClick={this.clearBlob('filename')}>
                                            <FontAwesomeIcon icon="times-circle" />
                                          </Button>
                                        </Col>
                                      </Row>
                                      <a rel="noopener noreferrer" target={'_blank'} href={`${filename}`}>
                                        <img
                                          src={`${filenameBase64 ? 'data:' + filenameContentType + ';base64,' + filenameBase64 : filename}`}
                                          style={{ maxHeight: '100px' }}
                                        />
                                      </a>
                                      <br />
                                    </div>
                                  ) : null}

                                  <input
                                    className="file_input"
                                    id="file_filename"
                                    type="file"
                                    ref={el => (this.filenameFileInput = el)}
                                    onChange={this.onBlobChange(false, 'filename', this.filenameFileInput)}
                                    accept="*"
                                  />

                                  <label htmlFor="file_filename" className="file_btn_open">
                                    <FontAwesomeIcon icon="upload" />
                                    <Translate contentKey="generadorApp.professionalFile.upload_filename">Upload Filename </Translate>
                                  </label>

                                  <AvInput
                                    onChange={evt => this.setState({ filenameSelectValue: evt.target.value })}
                                    type="hidden"
                                    name="filename"
                                    value={filename}
                                  />
                                </Col>
                              </Row>
                            </AvGroup>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ filenameSelectValue: evt.target.value })}
                      type="hidden"
                      name="filename"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'professional' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="professional-file-professional">
                              <Translate contentKey="generadorApp.professionalFile.professional">Professional</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="professional-file-professional"
                              name={'professional'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.professionalSelectValue ? this.state.professionalSelectValue : ''}
                              onChange={options => this.setState({ professionalSelectValue: options })}
                              defaultOptions={this.state.professionalStartSelectOptions ? this.state.professionalStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.professionalStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.professionalStartSelectOptions === undefined) {
                                  const result = await getListAxios('professionals', {}, 0, 100, 'name,asc', 'name');
                                  this.setState({
                                    professionalStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'professionals',
                                  { 'name.contains': inputValue },
                                  0,
                                  100,
                                  'name,asc',
                                  'name'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="professional"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}{' '}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { professionalFileEntity, professionals, loading, updating } = this.props;
    const { isNew } = this.state;

    const { filename, filenameContentType, filenameBase64 } = professionalFileEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...professionalFileEntity,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel className="professional-file professional-file-update">
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToProps = (storeState: IRootState) => {
  return {
    professionals: storeState.professional.entities,
    professionalFileEntity: storeState.professionalFile.entity,
    loading: storeState.professionalFile.loading,
    updating: storeState.professionalFile.updating,
    updateSuccess: storeState.professionalFile.updateSuccess,
  };
};

export const mapDispatchToProps = {
  getEntity,
  updateEntity,
  setBlob,
  createEntity,
  getEntityOffset,
  reset,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProfessionalFileUpdate);
