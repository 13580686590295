export const selectFieldsList = [
  'patient.client.fantasyName',
  'patient.name',
  'from',
  'to',
  'source',
  'status',
  'finalAmount',
  'billFinalAmount',
];
