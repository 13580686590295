import React, { useState } from 'react';
import axios from 'axios';
import { ISupplierGlosaItem } from 'app/shared/model/supplier-glosa-item.model';
import { TypeBilling } from 'app/shared/model/enumerations/type-billing.model';
import { GeralSelect } from 'app/shared/model/enumerations/geral-select.model';

import { Button, Table } from 'reactstrap';

import { showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import CurrencyInput from 'react-currency-input';

import { translate as basetranslate } from 'app/config/translate-component';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { registerLocale } from 'react-datepicker';
import { pt } from 'date-fns/esm/locale';

const MySwal = withReactContent(Swal);
registerLocale('pt', pt);

export interface ISupplierGlosaProps {
  baseState?: any;
  baseProps?: any;
  setState?: any;
  entityBase?: any;
}

const levelGroupPaths = {
  RECURSOSHUMANOS: 'quoteRh.specialty.category.levelGroup.name',
  MATERIAIS: 'quoteMaterial.medicineMaterial.categories.levelGroup.name',
  MEDICAMENTOS: 'quoteMedicine.medicineMaterial.categories.levelGroup.name',
  DIETAS: 'quoteDiet.medicineMaterial.categories.levelGroup.name',
  PRODUCTOSERVICIO: 'quoteProductService.productService.category.levelGroup.name',
};

const categoryPaths = {
  RECURSOSHUMANOS: 'quoteRh.specialty.category.name',
  MATERIAIS: 'quoteMaterial.medicineMaterial.categories.name',
  MEDICAMENTOS: 'quoteMedicine.medicineMaterial.categories.name',
  DIETAS: 'quoteDiet.medicineMaterial.categories.name',
  PRODUCTOSERVICIO: 'quoteProductService.productService.category.name',
};
const itemPaths = {
  RECURSOSHUMANOS: 'quoteRh.specialty.name',
  MATERIAIS: 'quoteMaterial.medicineMaterial.name',
  MEDICAMENTOS: 'quoteMedicine.medicineMaterial.name',
  DIETAS: 'quoteDiet.medicineMaterial.name',
  PRODUCTOSERVICIO: 'quoteProductService.productService.name',
};
const unitaryAmountPaths = {
  RECURSOSHUMANOS: 'quoteRh.unitaryAmount',
  MATERIAIS: 'quoteMaterial.unitaryAmount',
  MEDICAMENTOS: 'quoteMedicine.unitaryAmount',
  DIETAS: 'quoteDiet.unitaryAmount',
  PRODUCTOSERVICIO: 'quoteProductService.unitaryAmount',
};
const idPaths = {
  RECURSOSHUMANOS: 'quoteRh.id',
  MATERIAIS: 'quoteMaterial.id',
  MEDICAMENTOS: 'quoteMedicine.id',
  DIETAS: 'quoteDiet.id',
  PRODUCTOSERVICIO: 'quoteProductService.id',
};

const SupplierGlosaComponentCustomize = (props: ISupplierGlosaProps) => {
  const [quoteSupplierIds, setQuoteSupplierIds] = useState(null);
  const [quoteProductServiceList, setQuoteProductServiceList] = useState([]);

  const isNew = props.entityBase.id && true;

  if (
    props.baseState.quoteSelectValue === null ||
    props.baseState.quoteSelectValue.id <= 0 ||
    props.baseState.supplierSelectValue === null ||
    props.baseState.supplierSelectValue.id <= 0
  ) {
    return <></>;
  }

  const translate = v => v;
  if (props.baseState.quoteSelectValue.id + '-' + props.baseState.supplierSelectValue.id !== quoteSupplierIds) {
    setQuoteSupplierIds(props.baseState.quoteSelectValue.id + '-' + props.baseState.supplierSelectValue.id);
    axios
      .get('/api/quote-product-services?quote.id.equal=' + props.baseState.quoteSelectValue.id, {
        headers: {
          'Select-Fields':
            'id,comercialTable.supplierTable.supplier.id,productService.id,productService.category.id,productService.category.levelGroup.id',
        },
      })
      .then(v =>
        setQuoteProductServiceList(
          v && v.data
            ? v.data.filter(x =>
                x.comercialTable && x.comercialTable.supplierTable
                  ? x.comercialTable.supplierTable.supplier.id === props.baseState.supplierSelectValue.id
                  : false
              )
            : []
        )
      );
  }

  const baseSupplierClosaItems: ISupplierGlosaItem = {
    supplierGlosa: { id: props.entityBase.id },
    justification: '',
    quantityRefused: 0,
    quantityGlossed: 0,
    quoteProductService: null,
    secOrder: null,
    glosaItemAceita: GeralSelect.NAO,
    typeItemGlosa: TypeBilling.PRODUCTOSERVICIO,
  };

  const quoteProductServiceListButtonAction = (id, glosaItemAceita, quantityGlossed, secOrder) => {
    axios
      .post('/api/supplier-glosa-items', {
        ...baseSupplierClosaItems,
        typeItemGlosa: TypeBilling.PRODUCTOSERVICIO,
        quoteProductService: { id },
        quantityGlossed,
        glosaItemAceita,
        secOrder,
      })
      .then(v => {
        props.baseProps.getEntity(props.entityBase.id);
      });
  };
  const itemListButtonAction = (entity, glosaItemAceita, quantityRefused, secOrder) => {
    if (glosaItemAceita === 'NAO' && (entity.quantityRefused <= 0 || entity.justification === '')) {
      if (entity.justification === '') {
        MySwal.fire({
          title: basetranslate('generadorApp.validators.required', {
            fieldName: basetranslate('generadorApp.clientGlosaItem.justification'),
          }),
          icon: 'error',
        });
      }
      if (quantityRefused <= 0) {
        MySwal.fire({
          title: basetranslate('generadorApp.validators.required', {
            fieldName: basetranslate('generadorApp.clientGlosaItem.quantityRefused'),
          }),
          icon: 'error',
        });
      }
    } else {
      axios
        .post('/api/supplier-glosa-items', {
          ...entity,
          supplierGlosa: { id: props.entityBase.id },
          id: null,
          quantityRefused,
          glosaItemAceita,
          secOrder,
          justification: '',
        })
        .then(v => {
          props.baseProps.getEntity(props.entityBase.id);
        });
    }
  };

  let secOrderMax = 0;
  const supplierGlosaItemList: any = {};
  const supplierGlosaItem: ISupplierGlosaItem[] =
    props.entityBase && props.entityBase.supplierGlosaItem ? props.entityBase.supplierGlosaItem : [];
  supplierGlosaItem.map(v => {
    if (v.secOrder > secOrderMax) {
      secOrderMax = v.secOrder;
    }
    if (!supplierGlosaItemList[v.secOrder]) {
      supplierGlosaItemList[v.secOrder] = [];
    }
    supplierGlosaItemList[v.secOrder].push(v);
  });

  return (
    <>
      <Table
        id="supplier-glosa-table-list"
        responsive
        aria-describedby="supplier-glosa-heading"
        className={'table-hover table-striped mt-4 table-responsive-css'}
      >
        <thead className={'thead-light'}>
          <tr>
            <th>{translate('GRUPO')}</th>
            <th>{translate('CATEGORIA')}</th>
            <th>{translate('ESPECIALIDADE / SERVIÇO / PRODUTO')}</th>
            <th>{translate('QTDE')}</th>
            <th>{translate('VALOR UNIT ')}</th>
            <th>{translate('VALOR TOTAL')}</th>
            {isNew ? <th>{translate('GLOSA ACEITA')}</th> : <> </>}
            <th />
          </tr>
        </thead>
        <tbody>
          <tr></tr>
          {quoteProductServiceList.map((v, idx) => {
            const nextSupplier = supplierGlosaItemList[1]
              ? supplierGlosaItemList[1].filter(itm => itm.quoteProductService && itm.quoteProductService.id === v.id).pop()
              : null;
            return (
              <tr key={idx}>
                <td title="GRUPO">{showFieldsSelectAsync(v, 'productService.category.levelGroup.name')}</td>
                <td title="CATEGORIA">{showFieldsSelectAsync(v, 'productService.category.name')}</td>
                <td title="ESPECIALIDADE / SERVIÇO / PRODUTO">{showFieldsSelectAsync(v, 'productService.name')}</td>
                <td title="QTDE">{v.billQuantityItem ? v.billQuantityItem : 0}</td>
                <td title="VALOR UNIT ">R$ {(v.unitaryAmount * 1).toFixed(2).replace('.', ',')}</td>
                <td title="VALOR TOTAL ">R$ {(v.finalAmount * 1).toFixed(2).replace('.', ',')}</td>
                {isNew ? (
                  <td title="GLOSA ACEITA">
                    {!nextSupplier ? (
                      <>
                        <Button
                          onClick={() => quoteProductServiceListButtonAction(v.id, GeralSelect.SIM, v.billQuantityItem, 1)}
                          color="success"
                          size="sm"
                        >
                          Sim
                        </Button>
                        <Button
                          onClick={() => quoteProductServiceListButtonAction(v.id, GeralSelect.NAO, v.billQuantityItem, 1)}
                          color="danger"
                          size="sm"
                        >
                          Não
                        </Button>
                      </>
                    ) : (
                      <div className={'text-center alert-' + (nextSupplier.glosaItemAceita === GeralSelect.SIM ? 'success' : 'danger')}>
                        {nextSupplier.glosaItemAceita}
                      </div>
                    )}
                  </td>
                ) : (
                  <> </>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>

      {isNew ? (
        Object.keys(supplierGlosaItemList).map((itenKey, keyIdx) =>
          supplierGlosaItemList[itenKey].filter(v => v.glosaItemAceita === GeralSelect.NAO).length > 0 ? (
            <>
              <br />
              <br />
              <Table
                id="supplier-glosa-table-list"
                style={{ minHeight: 100 }}
                responsive
                aria-describedby="supplier-glosa-heading"
                className={'table-hover table-striped mt-4 table-responsive-css'}
              >
                <thead className={'thead-light'}>
                  <tr>
                    <th>{translate('GRUPO')}</th>
                    <th>{translate('CATEGORIA')}</th>
                    <th>{translate('ESPECIALIDADE / SERVIÇO / PRODUTO')}</th>
                    <th>{translate('QTDE')}</th>
                    <th>{translate('QTDE RECURSADA')}</th>
                    <th>{translate('VALOR UNIT ')}</th>
                    <th>{translate('VALOR TOTAL')}</th>
                    <th colSpan={2}>{translate('JUSTIFICATIVA')}</th>
                    <th>{translate('GLOSA ACEITA')}</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  <tr></tr>
                  {supplierGlosaItemList[itenKey]
                    .filter(v => v.glosaItemAceita === GeralSelect.NAO)
                    .map((v, idx) => {
                      const nextSupplier = supplierGlosaItemList[v.secOrder + 1]
                        ? supplierGlosaItemList[v.secOrder + 1]
                            .filter(
                              itm =>
                                (itm.quotePackage && v.quotePackage && itm.quotePackage.id === v.quotePackage.id) ||
                                (itm.quoteRh && v.quoteRh && itm.quoteRh.id === v.quoteRh.id) ||
                                (itm.quoteMedicine && v.quoteMedicine && itm.quoteMedicine.id === v.quoteMedicine.id) ||
                                (itm.quoteMaterial && v.quoteMaterial && itm.quoteMaterial.id === v.quoteMaterial.id) ||
                                (itm.quoteDiet && v.quoteDiet && itm.quoteDiet.id === v.quoteDiet.id) ||
                                (itm.quoteProductService &&
                                  v.quoteProductService &&
                                  itm.quoteProductService.id === v.quoteProductService.id)
                            )
                            .pop()
                        : null;
                      return (
                        <tr key={idx}>
                          <td title="GRUPO">{showFieldsSelectAsync(v, levelGroupPaths[v.typeItemGlosa])}</td>
                          <td title="CATEGORIA">{showFieldsSelectAsync(v, categoryPaths[v.typeItemGlosa])}</td>
                          <td title="ESPECIALIDADE / SERVIÇO / PRODUTO">{showFieldsSelectAsync(v, itemPaths[v.typeItemGlosa])}</td>
                          <td title="QTDE">{v.quantityGlossed}</td>
                          <td title="QTDE">
                            <CurrencyInput
                              precision={0}
                              value={v.quantityRefused}
                              className={'form-control'}
                              onBlur={event => {
                                axios
                                  .put('/api/supplier-glosa-items', {
                                    id: v.id,
                                    quantityRefused: event.target.value,
                                  })
                                  .then(x => {
                                    props.baseProps.getEntity(props.entityBase.id);
                                  });
                              }}
                            />
                          </td>
                          <td title="VALOR UNIT ">
                            R$ {(showFieldsSelectAsync(v, unitaryAmountPaths[v.typeItemGlosa]) * 1).toFixed(2).replace('.', ',')}
                          </td>
                          <td title="VALOR TOTAL ">
                            R${' '}
                            {(showFieldsSelectAsync(v, unitaryAmountPaths[v.typeItemGlosa]) * v.quantityRefused)
                              .toFixed(2)
                              .replace('.', ',')}
                          </td>
                          <td colSpan={2} title="JUSTIFICATIVA">
                            <textarea
                              className={'form-control'}
                              defaultValue={v.justification}
                              onBlur={txt => {
                                axios
                                  .put('/api/supplier-glosa-items', {
                                    id: v.id,
                                    justification: txt.target.value,
                                  })
                                  .then(x => {
                                    props.baseProps.getEntity(props.entityBase.id);
                                  });
                              }}
                            ></textarea>
                          </td>
                          <td title="GLOSA ACEITA">
                            {!nextSupplier ? (
                              <>
                                <Button
                                  color="success"
                                  size="sm"
                                  onClick={() => itemListButtonAction(v, GeralSelect.SIM, v.quantityRefused, v.secOrder + 1)}
                                >
                                  Sim
                                </Button>
                                <Button
                                  color="danger"
                                  size="sm"
                                  onClick={() => itemListButtonAction(v, GeralSelect.NAO, v.quantityRefused, v.secOrder + 1)}
                                >
                                  Não
                                </Button>
                              </>
                            ) : (
                              <div
                                className={'text-center alert-' + (nextSupplier.glosaItemAceita === GeralSelect.SIM ? 'success' : 'danger')}
                              >
                                {nextSupplier.glosaItemAceita}
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </>
          ) : (
            <> </>
          )
        )
      ) : (
        <> </>
      )}
    </>
  );
};

const mapStateToProps = storeState => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default SupplierGlosaComponentCustomize;
