import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { IKitDiet } from 'app/shared/model/kit-diet.model';
import { IKitMaterial } from 'app/shared/model/kit-material.model';
import { IKitMedicine } from 'app/shared/model/kit-medicine.model';
import { IKitProductService } from 'app/shared/model/kit-product-service.model';
import { IKitRhs } from 'app/shared/model/kit-rhs.model';
import { IKitStatusHistory } from 'app/shared/model/kit-status-history.model';
import { ICategory } from 'app/shared/model/category.model';
import { Status } from 'app/shared/model/enumerations/status.model';

export interface IKit {
  id?: number;
  name?: string;
  status?: Status;
  kitDiet?: IKitDiet[];
  kitMaterial?: IKitMaterial[];
  kitMedicine?: IKitMedicine[];
  kitProductService?: IKitProductService[];
  kitRhs?: IKitRhs[];
  kitStatusHistory?: IKitStatusHistory[];
  categories?: ICategory[];
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  kitDiet: {
    isMulti: false, // one-to-many - other-side
    entity: 'KitDiet',
    urlParameters: {
      enpoint: 'kit-diets',
      filters: 'kit.id.equals',
    },
  },
  kitMaterial: {
    isMulti: false, // one-to-many - other-side
    entity: 'KitMaterial',
    urlParameters: {
      enpoint: 'kit-materials',
      filters: 'kit.id.equals',
    },
  },
  kitMedicine: {
    isMulti: false, // one-to-many - other-side
    entity: 'KitMedicine',
    urlParameters: {
      enpoint: 'kit-medicines',
      filters: 'kit.id.equals',
    },
  },
  kitProductService: {
    isMulti: false, // one-to-many - other-side
    entity: 'KitProductService',
    urlParameters: {
      enpoint: 'kit-product-services',
      filters: 'kit.id.equals',
    },
  },
  kitRhs: {
    isMulti: false, // one-to-many - other-side
    entity: 'KitRhs',
    urlParameters: {
      enpoint: 'kit-rhs',
      filters: 'kit.id.equals',
    },
  },
  kitStatusHistory: {
    isMulti: false, // one-to-many - other-side
    entity: 'KitStatusHistory',
    urlParameters: {
      enpoint: 'kit-status-histories',
      filters: 'kit.id.equals',
    },
  },
  category: {
    isMulti: true, // many-to-many - owner-side
    entity: 'Category',
    urlParameters: {
      enpoint: 'categories',
      filters: 'kits.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'kit.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'kit.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<IKit> = {};
