/* eslint complexity: ["error", 500] */
import axios from 'axios';

import { IPaginationBaseState, IPayloadResult } from 'react-jhipster';

import { IPayload } from 'react-jhipster/src/type/redux-action.type';

import { BASE_API_VERSION_PATH } from 'app/config/constants';

import { cleanEntity, jsonParse } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IPermissionUser, defaultValue } from 'app/shared/model/permission-user.model';

export declare type ICrudPutAction<T> = (data?: T, listFiltersPage?) => IPayload<T> | IPayloadResult<T>;
export declare type ICrudGetAction<T> = (id: string | number, selectFields?: string) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const ACTION_TYPES = {
  FETCH_PERMISSIONUSER_LIST_EXPORT: 'permissionUser/FETCH_PERMISSIONUSER_LIST_EXPORT',
  FETCH_PERMISSIONUSER_LIST: 'permissionUser/FETCH_PERMISSIONUSER_LIST',
  FETCH_PERMISSIONUSER: 'permissionUser/FETCH_PERMISSIONUSER',
  FETCH_PERMISSIONUSER_OFFSET: 'permissionUser/FETCH_PERMISSIONUSER_OFFSET',
  CREATE_PERMISSIONUSER: 'permissionUser/CREATE_PERMISSIONUSER',
  UPDATE_PERMISSIONUSER: 'permissionUser/UPDATE_PERMISSIONUSER',
  CREATE_PERMISSIONUSER_AND_GO_LIST: 'permissionUser/CREATE_PERMISSIONUSER_AND_GO_LIST',
  UPDATE_PERMISSIONUSER_AND_GO_LIST: 'permissionUser/UPDATE_PERMISSIONUSER_AND_GO_LIST',
  DELETE_PERMISSIONUSER: 'permissionUser/DELETE_PERMISSIONUSER',
  RESET: 'permissionUser/RESET',
  SHOW_MODAL: 'permissionUser/SHOW_MODAL',
};

const initialState = {
  loading: false,
  openModal: {
    list: false,
    view: false,
    detail: false,
  },
  errorMessage: null,
  entities: [] as ReadonlyArray<IPermissionUser>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const actionPendind = [];
let actualState = initialState;

export const convertEntityBuffers = entity => ({
  ...entity,
});

export type PermissionUserState = Readonly<typeof initialState>;

export interface IPermissionUserBaseState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  listCheckedID?: Array<number>;
  isCheckedAll?: boolean;
  urlBack?: string;
  extraFilters?: any;
  baseFilters: any;
  offset: number;
  permissionId?: any;
  permissionStartFilter?: any;
  userId?: any;
  userStartFilter?: any;
}

export interface IFieldsBase extends IPermissionUserBaseState, IPaginationBaseState {}
export interface IPermissionUserUpdateState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  urlBack?: string;
  functionEmbebed?: any[];
  fieldsBase: IFieldsBase;
  viewSelectValue?: any;
  viewStartSelectOptions?: any;
  resgisterSelectValue?: any;
  resgisterStartSelectOptions?: any;
  editSelectValue?: any;
  editStartSelectOptions?: any;
  canDeleteSelectValue?: any;
  canDeleteStartSelectOptions?: any;
  manualRemovalSelectValue?: any;
  manualRemovalStartSelectOptions?: any;
  editLocationSelectValue?: any;
  editLocationStartSelectOptions?: any;
  reportSelectValue?: any;
  reportStartSelectOptions?: any;
  activateProfessionalSelectValue?: any;
  activateProfessionalStartSelectOptions?: any;
  deletePhotoSelectValue?: any;
  deletePhotoStartSelectOptions?: any;
  financialValueSelectValue?: any;
  financialValueStartSelectOptions?: any;
  valueAuthorizationSelectValue?: any;
  valueAuthorizationStartSelectOptions?: any;
  confirmPaymentSelectValue?: any;
  confirmPaymentStartSelectOptions?: any;
  evolutionSelectValue?: any;
  evolutionStartSelectOptions?: any;
  professionalNameSelectValue?: any;
  professionalNameStartSelectOptions?: any;
  professionalRegisterSelectValue?: any;
  professionalRegisterStartSelectOptions?: any;

  userSelectValue?: any;
  userStartSelectOptions?: any;

  permissionSelectValue?: any;
  permissionStartSelectOptions?: any;

  isNew: boolean;
  userFullname?: any;

  userId?: any;
  permissionName?: any;

  permissionId?: any;
}

// Reducer

export default (state: PermissionUserState = initialState, action): PermissionUserState => {
  actualState = state;
  if (
    action.type.includes('_PENDING') &&
    !actionPendind.includes(action.type.replace('_PENDING', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_PENDING', ''))
  ) {
    actionPendind.push(action.type.replace('_PENDING', ''));
  }

  if (
    action.type.includes('_FULFILLED') &&
    actionPendind.includes(action.type.replace('_FULFILLED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_FULFILLED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_FULFILLED', '')), 1);
  }

  if (
    action.type.includes('_REJECTED') &&
    actionPendind.includes(action.type.replace('_REJECTED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_REJECTED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_REJECTED', '')), 1);
  }

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PERMISSIONUSER_LIST_EXPORT):
    case REQUEST(ACTION_TYPES.FETCH_PERMISSIONUSER_LIST):
    case REQUEST(ACTION_TYPES.FETCH_PERMISSIONUSER):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_PERMISSIONUSER):
    case REQUEST(ACTION_TYPES.UPDATE_PERMISSIONUSER):
    case REQUEST(ACTION_TYPES.CREATE_PERMISSIONUSER_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.UPDATE_PERMISSIONUSER_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.DELETE_PERMISSIONUSER):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_PERMISSIONUSER_LIST_EXPORT):
    case FAILURE(ACTION_TYPES.FETCH_PERMISSIONUSER_LIST):
    case FAILURE(ACTION_TYPES.FETCH_PERMISSIONUSER):
    case FAILURE(ACTION_TYPES.CREATE_PERMISSIONUSER):
    case FAILURE(ACTION_TYPES.UPDATE_PERMISSIONUSER):
    case FAILURE(ACTION_TYPES.CREATE_PERMISSIONUSER_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.UPDATE_PERMISSIONUSER_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.DELETE_PERMISSIONUSER):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_PERMISSIONUSER_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_PERMISSIONUSER_OFFSET):
      if (action.payload.data && action.payload.data.length > 0) {
        return {
          ...state,
          loading: false,
          entity: convertEntityBuffers(action.payload.data[0]),
        };
      }
      return {
        ...state,
        loading: false,
      };

    case SUCCESS(ACTION_TYPES.FETCH_PERMISSIONUSER):
      return {
        ...state,
        loading: false,
        entity: convertEntityBuffers(action.payload.data),
      };
    case SUCCESS(ACTION_TYPES.CREATE_PERMISSIONUSER):
    case SUCCESS(ACTION_TYPES.UPDATE_PERMISSIONUSER):
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_PERMISSIONUSER_AND_GO_LIST):
    case SUCCESS(ACTION_TYPES.UPDATE_PERMISSIONUSER_AND_GO_LIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_PERMISSIONUSER):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };

    case ACTION_TYPES.SHOW_MODAL: {
      const openModal = state.openModal;
      openModal[action.payload.type] = action.payload.show;
      return {
        ...state,
        openModal,
      };
    }
    default:
      return state;
  }
};

const apiUrl = BASE_API_VERSION_PATH + 'permission-users';

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

// Actions

// Actions
export type ICrudGetAllActionPermissionUser<T> = (
  permissionId?: any,
  userId?: any,
  extraFilters?: {},
  page?: number,
  size?: number,
  sort?: string,
  selectFields?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getEntitiesFilter = (filters?, page?, size?, sort?, selectFields = '') => {
  const requestUrl = `${apiUrl}?page=${page >= 0 ? page : 0}&size=${size >= 0 ? size : 20}&sort=${sort ? sort : 'id,asc'}&`;
  const requestFilters = filters.map(v => v.column + '.' + v.operation + '=' + v.value).join('&');
  return {
    type: ACTION_TYPES.FETCH_PERMISSIONUSER_LIST,
    payload: axios.get<IPermissionUser>(`${requestUrl}${requestFilters}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getAllEntities = (filters?, sort?, selectFields = '') => {
  if (
    (actualState.totalItems > 0 && actualState.entities.length === actualState.totalItems) ||
    actionPendind.includes(ACTION_TYPES.FETCH_PERMISSIONUSER_LIST)
  ) {
    return {
      type: ACTION_TYPES.FETCH_PERMISSIONUSER_LIST + '_FROM_CACHE',
      payload: new Promise(function (resolve, reject) {
        resolve('done');
      }),
    };
  }

  const idsRequest = filters && typeof filters['ids'] !== 'undefined' && filters['ids'] ? `id.in=${filters['ids'].join(',')}&` : '';
  const permissionRequest =
    filters && typeof filters['permissionId'] !== 'undefined' && filters['permissionId']
      ? `permission.id.in=${filters['permissionId']}&`
      : '';
  const userRequest = filters && typeof filters['userId'] !== 'undefined' && filters['userId'] ? `user.id.in=${filters['userId']}&` : '';

  const requestUrl = `${apiUrl}${`?page=${0}&size=${1000000}&sort=${sort ? sort : 'id,asc'}&`}`;
  return {
    type: ACTION_TYPES.FETCH_PERMISSIONUSER_LIST,
    payload: axios.get<IPermissionUser>(`${requestUrl}${idsRequest}${permissionRequest}${userRequest}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntities: ICrudGetAllActionPermissionUser<IPermissionUser> = (
  permissionId,
  userId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const permissionRequest = permissionId ? `permission.id.in=${permissionId}&` : '';
  const userRequest = userId ? `user.id.in=${userId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PERMISSIONUSER_LIST,
    payload: axios.get<IPermissionUser>(`${requestUrl}${extraFiltersRequest}${permissionRequest}${userRequest}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntityOffset: any = (permissionId, userId, extraFilters, offset, sort, selectFields = '') => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const permissionRequest = permissionId ? `permission.id.in=${permissionId}&` : '';
  const userRequest = userId ? `user.id.in=${userId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${offset}&size=${1}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PERMISSIONUSER_OFFSET,
    payload: axios.get<IPermissionUser>(`${requestUrl}${extraFiltersRequest}${permissionRequest}${userRequest}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntity: ICrudGetAction<IPermissionUser> = (id, selectFields = '') => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_PERMISSIONUSER,
    payload: axios.get<IPermissionUser>(requestUrl, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntitiesExport: ICrudGetAllActionPermissionUser<IPermissionUser> = (
  permissionId,
  userId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const permissionRequest = permissionId ? `permission.id.in=${permissionId}&` : '';
  const userRequest = userId ? `user.id.in=${userId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PERMISSIONUSER_LIST,
    payload: axios.get<IPermissionUser>(`${requestUrl}${extraFiltersRequest}${permissionRequest}${userRequest}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const createEntity: ICrudPutAction<IPermissionUser> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.CREATE_PERMISSIONUSER_AND_GO_LIST
        : ACTION_TYPES.CREATE_PERMISSIONUSER,
    payload: axios.post(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const updateEntity: ICrudPutAction<IPermissionUser> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.UPDATE_PERMISSIONUSER_AND_GO_LIST
        : ACTION_TYPES.UPDATE_PERMISSIONUSER,
    payload: axios.put(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const deleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_PERMISSIONUSER,
    payload: axios.delete(requestUrl),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const showModal = view => ({
  payload: { type: view, show: true },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const hideModal = view => ({
  payload: { type: view, show: false },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const getUrlBack = (location): string => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  let urlBack = _urlBase.searchParams.get('urlBack') || '';
  urlBack = urlBack.trim();
  urlBack = urlBack.startsWith('/') ? urlBack.slice(1) : urlBack;
  urlBack = urlBack.endsWith('/') ? urlBack.slice(0, -1) : urlBack;
  return urlBack ? '/' + urlBack + '/' : '';
};

export const getPermissionUserState = (location): IPermissionUserBaseState => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  const extraFilters = jsonParse(_urlBase.searchParams.get('extraFilters') || '{}');
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;

  const permissionId = _urlBase.searchParams.get('permission') || '';
  const userId = _urlBase.searchParams.get('user') || '';
  return {
    baseFilters,
    extraFilters,
    offset,
    permissionId: permissionId ? permissionId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
    userId: userId ? userId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
  };
};

export const getEntityFiltersURL = (state, offset = null) => {
  return (
    'baseFilters=' +
    state.baseFilters +
    '&extraFilters=' +
    encodeURI(JSON.stringify(state.extraFilters)) +
    '&page=' +
    state.activePage +
    '&' +
    'size=' +
    state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    state.sort +
    ',' +
    state.order +
    '&' +
    (state.permissionId ? 'permission=' + state.permissionId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.userId ? 'user=' + state.userId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    ''
  );
};
