import React from 'react';

export interface ISpecialtyProps {
  baseState?: any;
  baseProps?: any;
  setState?: any;
  entityBase?: any;
}

const SpecialtyComponentCustomize = (props: ISpecialtyProps) => {
  return <></>;
};

const mapStateToProps = storeState => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default SpecialtyComponentCustomize;
