/* eslint complexity: ["error", 500] */
import axios from 'axios';

import moment from 'moment';
import { IPaginationBaseState, IPayloadResult } from 'react-jhipster';

import { IPayload } from 'react-jhipster/src/type/redux-action.type';

import { BASE_API_VERSION_PATH } from 'app/config/constants';

import { cleanEntity, jsonParse } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IQuotePackage, defaultValue } from 'app/shared/model/quote-package.model';

export declare type ICrudPutAction<T> = (data?: T, listFiltersPage?) => IPayload<T> | IPayloadResult<T>;
export declare type ICrudGetAction<T> = (id: string | number, selectFields?: string) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const ACTION_TYPES = {
  FETCH_QUOTEPACKAGE_LIST_EXPORT: 'quotePackage/FETCH_QUOTEPACKAGE_LIST_EXPORT',
  FETCH_QUOTEPACKAGE_LIST: 'quotePackage/FETCH_QUOTEPACKAGE_LIST',
  FETCH_QUOTEPACKAGE: 'quotePackage/FETCH_QUOTEPACKAGE',
  FETCH_QUOTEPACKAGE_OFFSET: 'quotePackage/FETCH_QUOTEPACKAGE_OFFSET',
  CREATE_QUOTEPACKAGE: 'quotePackage/CREATE_QUOTEPACKAGE',
  UPDATE_QUOTEPACKAGE: 'quotePackage/UPDATE_QUOTEPACKAGE',
  CREATE_QUOTEPACKAGE_AND_GO_LIST: 'quotePackage/CREATE_QUOTEPACKAGE_AND_GO_LIST',
  UPDATE_QUOTEPACKAGE_AND_GO_LIST: 'quotePackage/UPDATE_QUOTEPACKAGE_AND_GO_LIST',
  DELETE_QUOTEPACKAGE: 'quotePackage/DELETE_QUOTEPACKAGE',
  RESET: 'quotePackage/RESET',
  SHOW_MODAL: 'quotePackage/SHOW_MODAL',
};

const initialState = {
  loading: false,
  openModal: {
    list: false,
    view: false,
    detail: false,
  },
  errorMessage: null,
  entities: [] as ReadonlyArray<IQuotePackage>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const actionPendind = [];
let actualState = initialState;

export const convertEntityBuffers = entity => ({
  ...entity,
});

export type QuotePackageState = Readonly<typeof initialState>;

export interface IQuotePackageBaseState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  listCheckedID?: Array<number>;
  isCheckedAll?: boolean;
  urlBack?: string;
  extraFilters?: any;
  baseFilters: any;
  offset: number;
  startDateStart?: any;
  startDateEnd?: any;
  finishDateStart?: any;
  finishDateEnd?: any;
  days?: any;
  quantity?: any;
  unitaryAmount?: any;
  grossAmount?: any;
  typeDiscount?: any;
  discountPercent?: any;
  discount?: any;
  finalAmount?: any;
  frequency?: any;
  additive?: any;
  billQuantityItem?: any;
  billGrossAmount?: any;
  billFinalAmount?: any;
  clientGlosaItemId?: any;
  clientGlosaItemStartFilter?: any;
  quoteId?: any;
  quoteStartFilter?: any;
  clComTableId?: any;
  clComTableStartFilter?: any;
}

export interface IFieldsBase extends IQuotePackageBaseState, IPaginationBaseState {}
export interface IQuotePackageUpdateState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  urlBack?: string;
  functionEmbebed?: any[];
  fieldsBase: IFieldsBase;
  startDateSelectValue?: any;
  startDateStartSelectOptions?: any;
  finishDateSelectValue?: any;
  finishDateStartSelectOptions?: any;
  daysSelectValue?: any;
  daysStartSelectOptions?: any;
  quantitySelectValue?: any;
  quantityStartSelectOptions?: any;
  unitaryAmountSelectValue?: any;
  unitaryAmountStartSelectOptions?: any;
  grossAmountSelectValue?: any;
  grossAmountStartSelectOptions?: any;
  typeDiscountSelectValue?: any;
  typeDiscountStartSelectOptions?: any;
  discountPercentSelectValue?: any;
  discountPercentStartSelectOptions?: any;
  discountSelectValue?: any;
  discountStartSelectOptions?: any;
  finalAmountSelectValue?: any;
  finalAmountStartSelectOptions?: any;
  frequencySelectValue?: any;
  frequencyStartSelectOptions?: any;
  additiveSelectValue?: any;
  additiveStartSelectOptions?: any;
  billQuantityItemSelectValue?: any;
  billQuantityItemStartSelectOptions?: any;
  billGrossAmountSelectValue?: any;
  billGrossAmountStartSelectOptions?: any;
  billFinalAmountSelectValue?: any;
  billFinalAmountStartSelectOptions?: any;

  clientGlosaItemSelectValue?: any;
  clientGlosaItemStartSelectOptions?: any;

  quoteSelectValue?: any;
  quoteStartSelectOptions?: any;

  clComTableSelectValue?: any;
  clComTableStartSelectOptions?: any;

  isNew: boolean;
  quoteId?: any;
  clComTableName?: any;

  clComTableId?: any;
}

// Reducer

export default (state: QuotePackageState = initialState, action): QuotePackageState => {
  actualState = state;
  if (
    action.type.includes('_PENDING') &&
    !actionPendind.includes(action.type.replace('_PENDING', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_PENDING', ''))
  ) {
    actionPendind.push(action.type.replace('_PENDING', ''));
  }

  if (
    action.type.includes('_FULFILLED') &&
    actionPendind.includes(action.type.replace('_FULFILLED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_FULFILLED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_FULFILLED', '')), 1);
  }

  if (
    action.type.includes('_REJECTED') &&
    actionPendind.includes(action.type.replace('_REJECTED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_REJECTED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_REJECTED', '')), 1);
  }

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_QUOTEPACKAGE_LIST_EXPORT):
    case REQUEST(ACTION_TYPES.FETCH_QUOTEPACKAGE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_QUOTEPACKAGE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_QUOTEPACKAGE):
    case REQUEST(ACTION_TYPES.UPDATE_QUOTEPACKAGE):
    case REQUEST(ACTION_TYPES.CREATE_QUOTEPACKAGE_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.UPDATE_QUOTEPACKAGE_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.DELETE_QUOTEPACKAGE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_QUOTEPACKAGE_LIST_EXPORT):
    case FAILURE(ACTION_TYPES.FETCH_QUOTEPACKAGE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_QUOTEPACKAGE):
    case FAILURE(ACTION_TYPES.CREATE_QUOTEPACKAGE):
    case FAILURE(ACTION_TYPES.UPDATE_QUOTEPACKAGE):
    case FAILURE(ACTION_TYPES.CREATE_QUOTEPACKAGE_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.UPDATE_QUOTEPACKAGE_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.DELETE_QUOTEPACKAGE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_QUOTEPACKAGE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_QUOTEPACKAGE_OFFSET):
      if (action.payload.data && action.payload.data.length > 0) {
        return {
          ...state,
          loading: false,
          entity: convertEntityBuffers(action.payload.data[0]),
        };
      }
      return {
        ...state,
        loading: false,
      };

    case SUCCESS(ACTION_TYPES.FETCH_QUOTEPACKAGE):
      return {
        ...state,
        loading: false,
        entity: convertEntityBuffers(action.payload.data),
      };
    case SUCCESS(ACTION_TYPES.CREATE_QUOTEPACKAGE):
    case SUCCESS(ACTION_TYPES.UPDATE_QUOTEPACKAGE):
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_QUOTEPACKAGE_AND_GO_LIST):
    case SUCCESS(ACTION_TYPES.UPDATE_QUOTEPACKAGE_AND_GO_LIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_QUOTEPACKAGE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };

    case ACTION_TYPES.SHOW_MODAL: {
      const openModal = state.openModal;
      openModal[action.payload.type] = action.payload.show;
      return {
        ...state,
        openModal,
      };
    }
    default:
      return state;
  }
};

const apiUrl = BASE_API_VERSION_PATH + 'quote-packages';

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

// Actions

// Actions
export type ICrudGetAllActionQuotePackage<T> = (
  startDateStart?: any,
  startDateEnd?: any,
  finishDateStart?: any,
  finishDateEnd?: any,
  days?: any,
  quantity?: any,
  unitaryAmount?: any,
  grossAmount?: any,
  typeDiscount?: any,
  discountPercent?: any,
  discount?: any,
  finalAmount?: any,
  frequency?: any,
  additive?: any,
  billQuantityItem?: any,
  billGrossAmount?: any,
  billFinalAmount?: any,
  clientGlosaItemId?: any,
  quoteId?: any,
  clComTableId?: any,
  extraFilters?: {},
  page?: number,
  size?: number,
  sort?: string,
  selectFields?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getEntitiesFilter = (filters?, page?, size?, sort?, selectFields = '') => {
  const requestUrl = `${apiUrl}?page=${page >= 0 ? page : 0}&size=${size >= 0 ? size : 20}&sort=${sort ? sort : 'id,asc'}&`;
  const requestFilters = filters.map(v => v.column + '.' + v.operation + '=' + v.value).join('&');
  return {
    type: ACTION_TYPES.FETCH_QUOTEPACKAGE_LIST,
    payload: axios.get<IQuotePackage>(`${requestUrl}${requestFilters}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getAllEntities = (filters?, sort?, selectFields = '') => {
  if (
    (actualState.totalItems > 0 && actualState.entities.length === actualState.totalItems) ||
    actionPendind.includes(ACTION_TYPES.FETCH_QUOTEPACKAGE_LIST)
  ) {
    return {
      type: ACTION_TYPES.FETCH_QUOTEPACKAGE_LIST + '_FROM_CACHE',
      payload: new Promise(function (resolve, reject) {
        resolve('done');
      }),
    };
  }

  const idsRequest = filters && typeof filters['ids'] !== 'undefined' && filters['ids'] ? `id.in=${filters['ids'].join(',')}&` : '';
  let startDateStartRequest = '';
  let startDateEndRequest = '';
  if (
    filters &&
    typeof filters['startDateStart'] !== 'undefined' &&
    filters['startDateStart'] &&
    !(typeof filters['startDateEnd'] !== 'undefined' && filters['startDateEnd'])
  ) {
    startDateStartRequest = `startDate.greaterThan=${moment(filters['startDateStart']).format('YYYY-MM-DD')}&`;
  } else if (
    filters &&
    !(typeof filters['startDateStart'] !== 'undefined' && filters['startDateStart']) &&
    typeof filters['startDateEnd'] !== 'undefined' &&
    filters['startDateEnd']
  ) {
    startDateEndRequest = `startDate.lessThan=${moment(filters['startDateEnd']).format('YYYY-MM-DD')}&`;
  } else if (
    filters &&
    typeof filters['startDateStart'] !== 'undefined' &&
    filters['startDateStart'] &&
    typeof filters['startDateEnd'] !== 'undefined' &&
    filters['startDateEnd']
  ) {
    startDateStartRequest = `startDate.between=${moment(filters['startDateStart']).format('YYYY-MM-DD')},${moment(
      filters['startDateEnd']
    ).format('YYYY-MM-DD')}&`;
  }
  let finishDateStartRequest = '';
  let finishDateEndRequest = '';
  if (
    filters &&
    typeof filters['finishDateStart'] !== 'undefined' &&
    filters['finishDateStart'] &&
    !(typeof filters['finishDateEnd'] !== 'undefined' && filters['finishDateEnd'])
  ) {
    finishDateStartRequest = `finishDate.greaterThan=${moment(filters['finishDateStart']).format('YYYY-MM-DD')}&`;
  } else if (
    filters &&
    !(typeof filters['finishDateStart'] !== 'undefined' && filters['finishDateStart']) &&
    typeof filters['finishDateEnd'] !== 'undefined' &&
    filters['finishDateEnd']
  ) {
    finishDateEndRequest = `finishDate.lessThan=${moment(filters['finishDateEnd']).format('YYYY-MM-DD')}&`;
  } else if (
    filters &&
    typeof filters['finishDateStart'] !== 'undefined' &&
    filters['finishDateStart'] &&
    typeof filters['finishDateEnd'] !== 'undefined' &&
    filters['finishDateEnd']
  ) {
    finishDateStartRequest = `finishDate.between=${moment(filters['finishDateStart']).format('YYYY-MM-DD')},${moment(
      filters['finishDateEnd']
    ).format('YYYY-MM-DD')}&`;
  }
  const daysRequest = filters && typeof filters['days'] !== 'undefined' && filters['days'] ? `days.equals=${filters['days']}&` : '';
  const quantityRequest =
    filters && typeof filters['quantity'] !== 'undefined' && filters['quantity'] ? `quantity.equals=${filters['quantity']}&` : '';
  const unitaryAmountRequest =
    filters && typeof filters['unitaryAmount'] !== 'undefined' && filters['unitaryAmount']
      ? `unitaryAmount.contains=${filters['unitaryAmount']}&`
      : '';
  const grossAmountRequest =
    filters && typeof filters['grossAmount'] !== 'undefined' && filters['grossAmount']
      ? `grossAmount.contains=${filters['grossAmount']}&`
      : '';
  const typeDiscountRequest =
    filters && typeof filters['typeDiscount'] !== 'undefined' && filters['typeDiscount']
      ? `typeDiscount.contains=${filters['typeDiscount']}&`
      : '';
  const discountPercentRequest =
    filters && typeof filters['discountPercent'] !== 'undefined' && filters['discountPercent']
      ? `discountPercent.equals=${filters['discountPercent']}&`
      : '';
  const discountRequest =
    filters && typeof filters['discount'] !== 'undefined' && filters['discount'] ? `discount.contains=${filters['discount']}&` : '';
  const finalAmountRequest =
    filters && typeof filters['finalAmount'] !== 'undefined' && filters['finalAmount']
      ? `finalAmount.contains=${filters['finalAmount']}&`
      : '';
  const frequencyRequest =
    filters && typeof filters['frequency'] !== 'undefined' && filters['frequency'] ? `frequency.contains=${filters['frequency']}&` : '';
  const additiveRequest =
    filters && typeof filters['additive'] !== 'undefined' && filters['additive'] ? `additive.contains=${filters['additive']}&` : '';
  const billQuantityItemRequest =
    filters && typeof filters['billQuantityItem'] !== 'undefined' && filters['billQuantityItem']
      ? `billQuantityItem.equals=${filters['billQuantityItem']}&`
      : '';
  const billGrossAmountRequest =
    filters && typeof filters['billGrossAmount'] !== 'undefined' && filters['billGrossAmount']
      ? `billGrossAmount.contains=${filters['billGrossAmount']}&`
      : '';
  const billFinalAmountRequest =
    filters && typeof filters['billFinalAmount'] !== 'undefined' && filters['billFinalAmount']
      ? `billFinalAmount.contains=${filters['billFinalAmount']}&`
      : '';
  const clientGlosaItemRequest =
    filters && typeof filters['clientGlosaItemId'] !== 'undefined' && filters['clientGlosaItemId']
      ? `clientGlosaItem.id.in=${filters['clientGlosaItemId']}&&`
      : '';
  const quoteRequest =
    filters && typeof filters['quoteId'] !== 'undefined' && filters['quoteId'] ? `quote.id.in=${filters['quoteId']}&&` : '';
  const clComTableRequest =
    filters && typeof filters['clComTableId'] !== 'undefined' && filters['clComTableId']
      ? `clComTable.id.in=${filters['clComTableId']}&&`
      : '';

  const requestUrl = `${apiUrl}${`?page=${0}&size=${1000000}&sort=${sort ? sort : 'id,asc'}&`}`;
  return {
    type: ACTION_TYPES.FETCH_QUOTEPACKAGE_LIST,
    payload: axios.get<IQuotePackage>(
      `${requestUrl}${idsRequest}${startDateStartRequest}${startDateEndRequest}${finishDateStartRequest}${finishDateEndRequest}${daysRequest}${quantityRequest}${unitaryAmountRequest}${grossAmountRequest}${typeDiscountRequest}${discountPercentRequest}${discountRequest}${finalAmountRequest}${frequencyRequest}${additiveRequest}${billQuantityItemRequest}${billGrossAmountRequest}${billFinalAmountRequest}${clientGlosaItemRequest}${quoteRequest}${clComTableRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntities: ICrudGetAllActionQuotePackage<IQuotePackage> = (
  startDateStart,
  startDateEnd,
  finishDateStart,
  finishDateEnd,
  days,
  quantity,
  unitaryAmount,
  grossAmount,
  typeDiscount,
  discountPercent,
  discount,
  finalAmount,
  frequency,
  additive,
  billQuantityItem,
  billGrossAmount,
  billFinalAmount,
  clientGlosaItemId,
  quoteId,
  clComTableId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  let startDateStartRequest = '';
  let startDateEndRequest = '';
  if (typeof startDateStart !== 'undefined' && startDateStart && !(typeof startDateEnd !== 'undefined' && startDateEnd)) {
    startDateStartRequest = `startDate.greaterThan=${moment(startDateStart).format('YYYY-MM-DD')}&`;
  } else if (!(typeof startDateStart !== 'undefined' && startDateStart) && typeof startDateEnd !== 'undefined' && startDateEnd) {
    startDateEndRequest = `startDate.lessThan=${moment(startDateEnd).format('YYYY-MM-DD')}&`;
  } else if (typeof startDateStart !== 'undefined' && startDateStart && typeof startDateEnd !== 'undefined' && startDateEnd) {
    startDateStartRequest = `startDate.between=${moment(startDateStart).format('YYYY-MM-DD')},${moment(startDateEnd).format(
      'YYYY-MM-DD'
    )}&`;
  }
  let finishDateStartRequest = '';
  let finishDateEndRequest = '';
  if (typeof finishDateStart !== 'undefined' && finishDateStart && !(typeof finishDateEnd !== 'undefined' && finishDateEnd)) {
    finishDateStartRequest = `finishDate.greaterThan=${moment(finishDateStart).format('YYYY-MM-DD')}&`;
  } else if (!(typeof finishDateStart !== 'undefined' && finishDateStart) && typeof finishDateEnd !== 'undefined' && finishDateEnd) {
    finishDateEndRequest = `finishDate.lessThan=${moment(finishDateEnd).format('YYYY-MM-DD')}&`;
  } else if (typeof finishDateStart !== 'undefined' && finishDateStart && typeof finishDateEnd !== 'undefined' && finishDateEnd) {
    finishDateStartRequest = `finishDate.between=${moment(finishDateStart).format('YYYY-MM-DD')},${moment(finishDateEnd).format(
      'YYYY-MM-DD'
    )}&`;
  }
  const daysRequest = days ? (Array.isArray(status) ? `days.in=${days.join(',')}&` : `days.equals=${days}&`) : '';
  const quantityRequest = quantity ? (Array.isArray(status) ? `quantity.in=${quantity.join(',')}&` : `quantity.equals=${quantity}&`) : '';
  const unitaryAmountRequest = unitaryAmount
    ? Array.isArray(status)
      ? `unitaryAmount.in=${unitaryAmount.join(',')}&`
      : `unitaryAmount.contains=${unitaryAmount}&`
    : '';
  const grossAmountRequest = grossAmount
    ? Array.isArray(status)
      ? `grossAmount.in=${grossAmount.join(',')}&`
      : `grossAmount.contains=${grossAmount}&`
    : '';
  const typeDiscountRequest = typeDiscount
    ? Array.isArray(status)
      ? `typeDiscount.in=${typeDiscount.join(',')}&`
      : `typeDiscount.equals=${typeDiscount}&`
    : '';
  const discountPercentRequest = discountPercent
    ? Array.isArray(status)
      ? `discountPercent.in=${discountPercent.join(',')}&`
      : `discountPercent.equals=${discountPercent}&`
    : '';
  const discountRequest = discount ? (Array.isArray(status) ? `discount.in=${discount.join(',')}&` : `discount.contains=${discount}&`) : '';
  const finalAmountRequest = finalAmount
    ? Array.isArray(status)
      ? `finalAmount.in=${finalAmount.join(',')}&`
      : `finalAmount.contains=${finalAmount}&`
    : '';
  const frequencyRequest = frequency
    ? Array.isArray(status)
      ? `frequency.in=${frequency.join(',')}&`
      : `frequency.equals=${frequency}&`
    : '';
  const additiveRequest = additive !== '' ? `additive.equals=${additive}&` : '';
  const billQuantityItemRequest = billQuantityItem
    ? Array.isArray(status)
      ? `billQuantityItem.in=${billQuantityItem.join(',')}&`
      : `billQuantityItem.equals=${billQuantityItem}&`
    : '';
  const billGrossAmountRequest = billGrossAmount
    ? Array.isArray(status)
      ? `billGrossAmount.in=${billGrossAmount.join(',')}&`
      : `billGrossAmount.contains=${billGrossAmount}&`
    : '';
  const billFinalAmountRequest = billFinalAmount
    ? Array.isArray(status)
      ? `billFinalAmount.in=${billFinalAmount.join(',')}&`
      : `billFinalAmount.contains=${billFinalAmount}&`
    : '';
  const clientGlosaItemRequest = clientGlosaItemId ? `clientGlosaItem.id.in=${clientGlosaItemId}&` : '';
  const quoteRequest = quoteId ? `quote.id.in=${quoteId}&` : '';
  const clComTableRequest = clComTableId ? `clComTable.id.in=${clComTableId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_QUOTEPACKAGE_LIST,
    payload: axios.get<IQuotePackage>(
      `${requestUrl}${extraFiltersRequest}${startDateStartRequest}${startDateEndRequest}${finishDateStartRequest}${finishDateEndRequest}${daysRequest}${quantityRequest}${unitaryAmountRequest}${grossAmountRequest}${typeDiscountRequest}${discountPercentRequest}${discountRequest}${finalAmountRequest}${frequencyRequest}${additiveRequest}${billQuantityItemRequest}${billGrossAmountRequest}${billFinalAmountRequest}${clientGlosaItemRequest}${quoteRequest}${clComTableRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntityOffset: any = (
  startDateStart,
  startDateEnd,
  finishDateStart,
  finishDateEnd,
  days,
  quantity,
  unitaryAmount,
  grossAmount,
  typeDiscount,
  discountPercent,
  discount,
  finalAmount,
  frequency,
  additive,
  billQuantityItem,
  billGrossAmount,
  billFinalAmount,
  clientGlosaItemId,
  quoteId,
  clComTableId,
  extraFilters,
  offset,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  let startDateStartRequest = '';
  let startDateEndRequest = '';
  if (typeof startDateStart !== 'undefined' && startDateStart && !(typeof startDateEnd !== 'undefined' && startDateEnd)) {
    startDateStartRequest = `startDate.greaterThan=${moment(startDateStart).format('YYYY-MM-DD')}&`;
  } else if (!(typeof startDateStart !== 'undefined' && startDateStart) && typeof startDateEnd !== 'undefined' && startDateEnd) {
    startDateEndRequest = `startDate.lessThan=${moment(startDateEnd).format('YYYY-MM-DD')}&`;
  } else if (typeof startDateStart !== 'undefined' && startDateStart && typeof startDateEnd !== 'undefined' && startDateEnd) {
    startDateStartRequest = `startDate.between=${moment(startDateStart).format('YYYY-MM-DD')},${moment(startDateEnd).format(
      'YYYY-MM-DD'
    )}&`;
  }
  let finishDateStartRequest = '';
  let finishDateEndRequest = '';
  if (typeof finishDateStart !== 'undefined' && finishDateStart && !(typeof finishDateEnd !== 'undefined' && finishDateEnd)) {
    finishDateStartRequest = `finishDate.greaterThan=${moment(finishDateStart).format('YYYY-MM-DD')}&`;
  } else if (!(typeof finishDateStart !== 'undefined' && finishDateStart) && typeof finishDateEnd !== 'undefined' && finishDateEnd) {
    finishDateEndRequest = `finishDate.lessThan=${moment(finishDateEnd).format('YYYY-MM-DD')}&`;
  } else if (typeof finishDateStart !== 'undefined' && finishDateStart && typeof finishDateEnd !== 'undefined' && finishDateEnd) {
    finishDateStartRequest = `finishDate.between=${moment(finishDateStart).format('YYYY-MM-DD')},${moment(finishDateEnd).format(
      'YYYY-MM-DD'
    )}&`;
  }
  const daysRequest = days ? (Array.isArray(status) ? `days.in=${days.join(',')}&` : `days.equals=${days}&`) : '';
  const quantityRequest = quantity ? (Array.isArray(status) ? `quantity.in=${quantity.join(',')}&` : `quantity.equals=${quantity}&`) : '';
  const unitaryAmountRequest = unitaryAmount
    ? Array.isArray(status)
      ? `unitaryAmount.in=${unitaryAmount.join(',')}&`
      : `unitaryAmount.contains=${unitaryAmount}&`
    : '';
  const grossAmountRequest = grossAmount
    ? Array.isArray(status)
      ? `grossAmount.in=${grossAmount.join(',')}&`
      : `grossAmount.contains=${grossAmount}&`
    : '';
  const typeDiscountRequest = typeDiscount
    ? Array.isArray(status)
      ? `typeDiscount.in=${typeDiscount.join(',')}&`
      : `typeDiscount.equals=${typeDiscount}&`
    : '';
  const discountPercentRequest = discountPercent
    ? Array.isArray(status)
      ? `discountPercent.in=${discountPercent.join(',')}&`
      : `discountPercent.equals=${discountPercent}&`
    : '';
  const discountRequest = discount ? (Array.isArray(status) ? `discount.in=${discount.join(',')}&` : `discount.contains=${discount}&`) : '';
  const finalAmountRequest = finalAmount
    ? Array.isArray(status)
      ? `finalAmount.in=${finalAmount.join(',')}&`
      : `finalAmount.contains=${finalAmount}&`
    : '';
  const frequencyRequest = frequency
    ? Array.isArray(status)
      ? `frequency.in=${frequency.join(',')}&`
      : `frequency.equals=${frequency}&`
    : '';
  const additiveRequest = additive !== '' ? `additive.equals=${additive}&` : '';
  const billQuantityItemRequest = billQuantityItem
    ? Array.isArray(status)
      ? `billQuantityItem.in=${billQuantityItem.join(',')}&`
      : `billQuantityItem.equals=${billQuantityItem}&`
    : '';
  const billGrossAmountRequest = billGrossAmount
    ? Array.isArray(status)
      ? `billGrossAmount.in=${billGrossAmount.join(',')}&`
      : `billGrossAmount.contains=${billGrossAmount}&`
    : '';
  const billFinalAmountRequest = billFinalAmount
    ? Array.isArray(status)
      ? `billFinalAmount.in=${billFinalAmount.join(',')}&`
      : `billFinalAmount.contains=${billFinalAmount}&`
    : '';
  const clientGlosaItemRequest = clientGlosaItemId ? `clientGlosaItem.id.in=${clientGlosaItemId}&` : '';
  const quoteRequest = quoteId ? `quote.id.in=${quoteId}&` : '';
  const clComTableRequest = clComTableId ? `clComTable.id.in=${clComTableId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${offset}&size=${1}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_QUOTEPACKAGE_OFFSET,
    payload: axios.get<IQuotePackage>(
      `${requestUrl}${extraFiltersRequest}${startDateStartRequest}${startDateEndRequest}${finishDateStartRequest}${finishDateEndRequest}${daysRequest}${quantityRequest}${unitaryAmountRequest}${grossAmountRequest}${typeDiscountRequest}${discountPercentRequest}${discountRequest}${finalAmountRequest}${frequencyRequest}${additiveRequest}${billQuantityItemRequest}${billGrossAmountRequest}${billFinalAmountRequest}${clientGlosaItemRequest}${quoteRequest}${clComTableRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntity: ICrudGetAction<IQuotePackage> = (id, selectFields = '') => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_QUOTEPACKAGE,
    payload: axios.get<IQuotePackage>(requestUrl, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntitiesExport: ICrudGetAllActionQuotePackage<IQuotePackage> = (
  startDateStart,
  startDateEnd,
  finishDateStart,
  finishDateEnd,
  days,
  quantity,
  unitaryAmount,
  grossAmount,
  typeDiscount,
  discountPercent,
  discount,
  finalAmount,
  frequency,
  additive,
  billQuantityItem,
  billGrossAmount,
  billFinalAmount,
  clientGlosaItemId,
  quoteId,
  clComTableId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  let startDateStartRequest = '';
  let startDateEndRequest = '';
  if (typeof startDateStart !== 'undefined' && startDateStart && !(typeof startDateEnd !== 'undefined' && startDateEnd)) {
    startDateStartRequest = `startDate.greaterThan=${moment(startDateStart).format('YYYY-MM-DD')}&`;
  } else if (!(typeof startDateStart !== 'undefined' && startDateStart) && typeof startDateEnd !== 'undefined' && startDateEnd) {
    startDateEndRequest = `startDate.lessThan=${moment(startDateEnd).format('YYYY-MM-DD')}&`;
  } else if (typeof startDateStart !== 'undefined' && startDateStart && typeof startDateEnd !== 'undefined' && startDateEnd) {
    startDateStartRequest = `startDate.between=${moment(startDateStart).format('YYYY-MM-DD')},${moment(startDateEnd).format(
      'YYYY-MM-DD'
    )}&`;
  }
  let finishDateStartRequest = '';
  let finishDateEndRequest = '';
  if (typeof finishDateStart !== 'undefined' && finishDateStart && !(typeof finishDateEnd !== 'undefined' && finishDateEnd)) {
    finishDateStartRequest = `finishDate.greaterThan=${moment(finishDateStart).format('YYYY-MM-DD')}&`;
  } else if (!(typeof finishDateStart !== 'undefined' && finishDateStart) && typeof finishDateEnd !== 'undefined' && finishDateEnd) {
    finishDateEndRequest = `finishDate.lessThan=${moment(finishDateEnd).format('YYYY-MM-DD')}&`;
  } else if (typeof finishDateStart !== 'undefined' && finishDateStart && typeof finishDateEnd !== 'undefined' && finishDateEnd) {
    finishDateStartRequest = `finishDate.between=${moment(finishDateStart).format('YYYY-MM-DD')},${moment(finishDateEnd).format(
      'YYYY-MM-DD'
    )}&`;
  }
  const daysRequest = days ? (Array.isArray(status) ? `days.in=${days.join(',')}&` : `days.equals=${days}&`) : '';
  const quantityRequest = quantity ? (Array.isArray(status) ? `quantity.in=${quantity.join(',')}&` : `quantity.equals=${quantity}&`) : '';
  const unitaryAmountRequest = unitaryAmount
    ? Array.isArray(status)
      ? `unitaryAmount.in=${unitaryAmount.join(',')}&`
      : `unitaryAmount.contains=${unitaryAmount}&`
    : '';
  const grossAmountRequest = grossAmount
    ? Array.isArray(status)
      ? `grossAmount.in=${grossAmount.join(',')}&`
      : `grossAmount.contains=${grossAmount}&`
    : '';
  const typeDiscountRequest = typeDiscount
    ? Array.isArray(status)
      ? `typeDiscount.in=${typeDiscount.join(',')}&`
      : `typeDiscount.equals=${typeDiscount}&`
    : '';
  const discountPercentRequest = discountPercent
    ? Array.isArray(status)
      ? `discountPercent.in=${discountPercent.join(',')}&`
      : `discountPercent.equals=${discountPercent}&`
    : '';
  const discountRequest = discount ? (Array.isArray(status) ? `discount.in=${discount.join(',')}&` : `discount.contains=${discount}&`) : '';
  const finalAmountRequest = finalAmount
    ? Array.isArray(status)
      ? `finalAmount.in=${finalAmount.join(',')}&`
      : `finalAmount.contains=${finalAmount}&`
    : '';
  const frequencyRequest = frequency
    ? Array.isArray(status)
      ? `frequency.in=${frequency.join(',')}&`
      : `frequency.equals=${frequency}&`
    : '';
  const additiveRequest = additive !== '' ? `additive.equals=${additive}&` : '';
  const billQuantityItemRequest = billQuantityItem
    ? Array.isArray(status)
      ? `billQuantityItem.in=${billQuantityItem.join(',')}&`
      : `billQuantityItem.equals=${billQuantityItem}&`
    : '';
  const billGrossAmountRequest = billGrossAmount
    ? Array.isArray(status)
      ? `billGrossAmount.in=${billGrossAmount.join(',')}&`
      : `billGrossAmount.contains=${billGrossAmount}&`
    : '';
  const billFinalAmountRequest = billFinalAmount
    ? Array.isArray(status)
      ? `billFinalAmount.in=${billFinalAmount.join(',')}&`
      : `billFinalAmount.contains=${billFinalAmount}&`
    : '';
  const clientGlosaItemRequest = clientGlosaItemId ? `clientGlosaItem.id.in=${clientGlosaItemId}&` : '';
  const quoteRequest = quoteId ? `quote.id.in=${quoteId}&` : '';
  const clComTableRequest = clComTableId ? `clComTable.id.in=${clComTableId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_QUOTEPACKAGE_LIST,
    payload: axios.get<IQuotePackage>(
      `${requestUrl}${extraFiltersRequest}${startDateStartRequest}${startDateEndRequest}${finishDateStartRequest}${finishDateEndRequest}${daysRequest}${quantityRequest}${unitaryAmountRequest}${grossAmountRequest}${typeDiscountRequest}${discountPercentRequest}${discountRequest}${finalAmountRequest}${frequencyRequest}${additiveRequest}${billQuantityItemRequest}${billGrossAmountRequest}${billFinalAmountRequest}${clientGlosaItemRequest}${quoteRequest}${clComTableRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const createEntity: ICrudPutAction<IQuotePackage> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.CREATE_QUOTEPACKAGE_AND_GO_LIST
        : ACTION_TYPES.CREATE_QUOTEPACKAGE,
    payload: axios.post(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const updateEntity: ICrudPutAction<IQuotePackage> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.UPDATE_QUOTEPACKAGE_AND_GO_LIST
        : ACTION_TYPES.UPDATE_QUOTEPACKAGE,
    payload: axios.put(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const deleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_QUOTEPACKAGE,
    payload: axios.delete(requestUrl),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const showModal = view => ({
  payload: { type: view, show: true },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const hideModal = view => ({
  payload: { type: view, show: false },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const getUrlBack = (location): string => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  let urlBack = _urlBase.searchParams.get('urlBack') || '';
  urlBack = urlBack.trim();
  urlBack = urlBack.startsWith('/') ? urlBack.slice(1) : urlBack;
  urlBack = urlBack.endsWith('/') ? urlBack.slice(0, -1) : urlBack;
  return urlBack ? '/' + urlBack + '/' : '';
};

export const getQuotePackageState = (location): IQuotePackageBaseState => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  const extraFilters = jsonParse(_urlBase.searchParams.get('extraFilters') || '{}');
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;

  const startDateStart = _urlBase.searchParams.get('startDate_start') || '';
  const startDateEnd = _urlBase.searchParams.get('startDate_end') || '';
  const finishDateStart = _urlBase.searchParams.get('finishDate_start') || '';
  const finishDateEnd = _urlBase.searchParams.get('finishDate_end') || '';
  const days = _urlBase.searchParams.get('days') || '';
  const quantity = _urlBase.searchParams.get('quantity') || '';
  const unitaryAmount = _urlBase.searchParams.get('unitaryAmount') || '';
  const grossAmount = _urlBase.searchParams.get('grossAmount') || '';
  const typeDiscount = _urlBase.searchParams.get('typeDiscount') || '';
  const discountPercent = _urlBase.searchParams.get('discountPercent') || '';
  const discount = _urlBase.searchParams.get('discount') || '';
  const finalAmount = _urlBase.searchParams.get('finalAmount') || '';
  const frequency = _urlBase.searchParams.get('frequency') || '';
  const additive = _urlBase.searchParams.get('additive') || '';
  const billQuantityItem = _urlBase.searchParams.get('billQuantityItem') || '';
  const billGrossAmount = _urlBase.searchParams.get('billGrossAmount') || '';
  const billFinalAmount = _urlBase.searchParams.get('billFinalAmount') || '';
  const clientGlosaItemId = _urlBase.searchParams.get('clientGlosaItem') || '';
  const quoteId = _urlBase.searchParams.get('quote') || '';
  const clComTableId = _urlBase.searchParams.get('clComTable') || '';
  return {
    baseFilters,
    extraFilters,
    offset,
    startDateStart,
    startDateEnd,
    finishDateStart,
    finishDateEnd,
    days,
    quantity,
    unitaryAmount,
    grossAmount,
    typeDiscount,
    discountPercent,
    discount,
    finalAmount,
    frequency,
    additive,
    billQuantityItem,
    billGrossAmount,
    billFinalAmount,
    clientGlosaItemId: clientGlosaItemId
      ? clientGlosaItemId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] }))
      : [],
    quoteId: quoteId ? quoteId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
    clComTableId: clComTableId ? clComTableId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
  };
};

export const getEntityFiltersURL = (state, offset = null) => {
  return (
    'baseFilters=' +
    state.baseFilters +
    '&extraFilters=' +
    encodeURI(JSON.stringify(state.extraFilters)) +
    '&page=' +
    state.activePage +
    '&' +
    'size=' +
    state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    state.sort +
    ',' +
    state.order +
    '&' +
    (state.startDate ? 'startDate=' + state.startDate + '&' : '') +
    (state.finishDate ? 'finishDate=' + state.finishDate + '&' : '') +
    (state.days ? 'days=' + state.days + '&' : '') +
    (state.quantity ? 'quantity=' + state.quantity + '&' : '') +
    (state.unitaryAmount ? 'unitaryAmount=' + state.unitaryAmount + '&' : '') +
    (state.grossAmount ? 'grossAmount=' + state.grossAmount + '&' : '') +
    (state.typeDiscount ? 'typeDiscount=' + state.typeDiscount + '&' : '') +
    (state.discountPercent ? 'discountPercent=' + state.discountPercent + '&' : '') +
    (state.discount ? 'discount=' + state.discount + '&' : '') +
    (state.finalAmount ? 'finalAmount=' + state.finalAmount + '&' : '') +
    (state.frequency ? 'frequency=' + state.frequency + '&' : '') +
    (state.additive ? 'additive=' + state.additive + '&' : '') +
    (state.billQuantityItem ? 'billQuantityItem=' + state.billQuantityItem + '&' : '') +
    (state.billGrossAmount ? 'billGrossAmount=' + state.billGrossAmount + '&' : '') +
    (state.billFinalAmount ? 'billFinalAmount=' + state.billFinalAmount + '&' : '') +
    (state.clientGlosaItemId ? 'clientGlosaItem=' + state.clientGlosaItemId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.quoteId ? 'quote=' + state.quoteId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.clComTableId ? 'clComTable=' + state.clComTableId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    ''
  );
};
