import React from 'react';

import { connect } from 'react-redux';
import { Moment } from 'moment';
import { Client } from 'app/entities/client/client';

export interface IChartTotalSuprimentosromaneioPadProps extends StateProps, DispatchProps {
  periodoInicio?: Moment;
  periodoFim?: Moment;
  operadora?: Array<Client>;
  totalSuprimentosRomaneioPad: {
    TotalSuprimentosComStatusEntregue: number;
    TotalSuprimentosComStatusPendente: number;
    TotalRomaneiosComStatusEntregue: number;
    TotalRomaneiosComStatusPendente: number;
    TotalPadsCompletos: number;
    TotalPadsIncompletos: number;
  };
}

const ChartTotalSuprimentosromaneioPad = (props: IChartTotalSuprimentosromaneioPadProps) => {
  const suprimentosEntregue = props.totalSuprimentosRomaneioPad?.TotalSuprimentosComStatusEntregue;
  const suprimentosPendente = props.totalSuprimentosRomaneioPad?.TotalSuprimentosComStatusPendente;
  const romaneiosEntregue = props.totalSuprimentosRomaneioPad?.TotalRomaneiosComStatusEntregue;
  const romaneiosPendente = props.totalSuprimentosRomaneioPad?.TotalRomaneiosComStatusPendente;
  const padsCompletos = props.totalSuprimentosRomaneioPad?.TotalPadsCompletos;
  const padsIncompletos = props.totalSuprimentosRomaneioPad?.TotalPadsIncompletos;

  return (
    <div className="card border-0 bg-dark text-white mb-3 overflow-hidden">
      <div className="card-body">
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="row">
              <div className="col-xl-4 col-lg-12">
                <div className="mb-3 text-grey">
                  <b>Suprimentos</b>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12">
                <div className="row text-truncate">
                  <div className="col-6">
                    <div className="f-s-12 text-grey">Entregue</div>
                    <div className="f-s-18 m-b-5 f-w-600 p-b-1">{props.totalSuprimentosRomaneioPad?.TotalSuprimentosComStatusEntregue}</div>
                    <div className="progress progress-xs rounded-lg bg-dark-darker m-b-5">
                      <div
                        className="progress-bar progress-bar-striped rounded-right bg-teal"
                        style={{ width: (suprimentosEntregue * 100) / (suprimentosEntregue + suprimentosPendente) + '%' }}
                      ></div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="f-s-12 text-grey">Pendente</div>
                    <div className="f-s-18 m-b-5 f-w-600 p-b-1">{suprimentosPendente}</div>
                    <div className="progress progress-xs rounded-lg bg-dark-darker m-b-5">
                      <div
                        className="progress-bar progress-bar-striped rounded-right"
                        style={{ width: (suprimentosPendente * 100) / (suprimentosEntregue + suprimentosPendente) + '%' }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="bg-white-transparent-2" />
            <div className="row">
              <div className="col-xl-4 col-lg-12">
                <div className="mb-3 text-grey">
                  <b>Romaneio</b>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12">
                <div className="row text-truncate">
                  <div className="col-6">
                    <div className="f-s-12 text-grey">Entregue</div>
                    <div className="f-s-18 m-b-5 f-w-600 p-b-1">{romaneiosEntregue}</div>
                    <div className="progress progress-xs rounded-lg bg-dark-darker m-b-5">
                      <div
                        className="progress-bar progress-bar-striped rounded-right bg-teal"
                        style={{ width: (romaneiosEntregue * 100) / (romaneiosEntregue + romaneiosPendente) + '%' }}
                      ></div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="f-s-12 text-grey">Pendente</div>
                    <div className="f-s-18 m-b-5 f-w-600 p-b-1">{romaneiosPendente}</div>
                    <div className="progress progress-xs rounded-lg bg-dark-darker m-b-5">
                      <div
                        className="progress-bar progress-bar-striped rounded-right"
                        style={{ width: (romaneiosPendente * 100) / (romaneiosEntregue + romaneiosPendente) + '%' }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="bg-white-transparent-2" />
            <div className="row">
              <div className="col-xl-4 col-lg-12">
                <div className="mb-3 text-grey">
                  <b>PADs</b>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12">
                <div className="row text-truncate">
                  <div className="col-6">
                    <div className="f-s-12 text-grey">Completos</div>
                    <div className="f-s-18 m-b-5 f-w-600 p-b-1">{padsCompletos}</div>
                    <div className="progress progress-xs rounded-lg bg-dark-darker m-b-5">
                      <div
                        className="progress-bar progress-bar-striped rounded-right bg-teal"
                        style={{ width: (padsCompletos * 100) / (padsCompletos + padsIncompletos) + '%' }}
                      ></div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="f-s-12 text-grey">Incompletos</div>
                    <div className="f-s-18 m-b-5 f-w-600 p-b-1">{padsIncompletos}</div>
                    <div className="progress progress-xs rounded-lg bg-dark-darker m-b-5">
                      <div
                        className="progress-bar progress-bar-striped rounded-right"
                        style={{ width: (padsIncompletos * 100) / (padsCompletos + padsIncompletos) + '%' }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = storeState => ({
  report:
    storeState.jhiReports.reportsList && storeState.jhiReports.reportsList['pacientes-hospitalizados']
      ? storeState.jhiReports.reportsList['pacientes-hospitalizados']
      : [],
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps)(ChartTotalSuprimentosromaneioPad);
