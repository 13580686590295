import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';
import moment from 'moment';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity, ISupplierBaseState, getUrlBack, getSupplierState, getEntityFiltersURL } from './supplier.reducer';

import { APP_DATE_FORMAT } from 'app/config/constants';

import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

export interface ISupplierState {
  fieldsBase: ISupplierBaseState;
  activeTab: number;
}

export interface ISupplierDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class SupplierDetail extends React.Component<ISupplierDetailProps, ISupplierState> {
  constructor(props: Readonly<ISupplierDetailProps>) {
    super(props);
    this.state = {
      ...this.state,
      activeTab: 0,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getSupplierState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
    };
  }

  toggleTab(tab: number) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = '_back=1' + (offset !== null ? '&offset=' + offset : '');
    Object.keys(fieldsBase).map(key => {
      url += '&' + key + '=' + fieldsBase[key];
    });
    return url;
  };

  UNSAFE_componentWillMount() {
    this.props.getEntity(this.props.match.params['id']);
  }

  renderHeader() {
    const { supplierEntity } = this.props;
    const state = this.state;
    return (
      <>
        <div id="page-heading">
          {' '}
          <h2>
            <Translate contentKey="generadorApp.supplier.detail.title">Supplier</Translate>
            <small>&nbsp; {supplierEntity['fantasyName']}</small>
          </h2>
        </div>

        <div className="pull-right">
          <Button tag={Link} to={'/supplier?' + getEntityFiltersURL(this.state.fieldsBase)} replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.supplier.btnBack">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/supplier/${supplierEntity.id}/edit?+${getEntityFiltersURL(this.state.fieldsBase)}`}
            replace
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.supplier.btnEdit">Edit</Translate>
            </span>
          </Button>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>

          <li className="breadcrumb-item active">
            <Link to={'/supplier'}>Suppliers</Link>
          </li>
          <li className="breadcrumb-item active">Suppliers details</li>
        </ol>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === 0 })}
              onClick={() => {
                this.toggleTab(0);
              }}
            >
              <span className="d-sm-none">
                <Translate contentKey="generadorApp.supplier.viewTabs.CompanyInformation">CompanyInformation</Translate>
              </span>
              <span className="d-sm-block d-none">
                <Translate contentKey="generadorApp.supplier.viewTabs.CompanyInformation">CompanyInformation</Translate>
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === 1 })}
              onClick={() => {
                this.toggleTab(1);
              }}
            >
              <span className="d-sm-none">
                <Translate contentKey="generadorApp.supplier.viewTabs.CommercialInformation">CommercialInformation</Translate>
              </span>
              <span className="d-sm-block d-none">
                <Translate contentKey="generadorApp.supplier.viewTabs.CommercialInformation">CommercialInformation</Translate>
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === 2 })}
              onClick={() => {
                this.toggleTab(2);
              }}
            >
              <span className="d-sm-none">
                <Translate contentKey="generadorApp.supplier.viewTabs.SupplierContacts">SupplierContacts</Translate>
              </span>
              <span className="d-sm-block d-none">
                <Translate contentKey="generadorApp.supplier.viewTabs.SupplierContacts">SupplierContacts</Translate>
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === 3 })}
              onClick={() => {
                this.toggleTab(3);
              }}
            >
              <span className="d-sm-none">
                <Translate contentKey="generadorApp.supplier.viewTabs.OtherInformation">OtherInformation</Translate>
              </span>
              <span className="d-sm-block d-none">
                <Translate contentKey="generadorApp.supplier.viewTabs.OtherInformation">OtherInformation</Translate>
              </span>
            </NavLink>
          </NavItem>
        </Nav>
      </>
    );
  }

  renderBody() {
    const { supplierEntity } = this.props;
    const state = this.state;
    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            <Row className="jh-entity-details">
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId={0}>
                  <Row>
                    <Col className="view-detail-row supplier-groups-detail" md="6">
                      <Row>
                        <Col className="view-detail-label-top supplier-groups-detail-label" md="12">
                          <dt>
                            <Translate contentKey="generadorApp.supplier.groups">Groups</Translate>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top supplier-groups-detail-value" md="12">
                          <dd>
                            {supplierEntity.groups
                              ? supplierEntity.groups.map((val, i) => (
                                  <span key={val.id}>
                                    <a>{val.name}</a>
                                    {i === supplierEntity.groups.length - 1 ? '' : ', '}
                                  </span>
                                ))
                              : null}
                          </dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row supplier-socialReason-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top supplier-socialReason-detail-label" md="12">
                          <dt>
                            <span id="socialReason">
                              <Translate contentKey="generadorApp.supplier.socialReason">Social Reason</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top supplier-socialReason-detail-value" md="12">
                          <dd>{supplierEntity.socialReason}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row supplier-fantasyName-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top supplier-fantasyName-detail-label" md="12">
                          <dt>
                            <span id="fantasyName">
                              <Translate contentKey="generadorApp.supplier.fantasyName">Fantasy Name</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top supplier-fantasyName-detail-value" md="12">
                          <dd>{supplierEntity.fantasyName}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row supplier-cnpj-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top supplier-cnpj-detail-label" md="12">
                          <dt>
                            <span id="cnpj">
                              <Translate contentKey="generadorApp.supplier.cnpj">Cnpj</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top supplier-cnpj-detail-value" md="12">
                          <dd>{supplierEntity.cnpj}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row supplier-ie-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top supplier-ie-detail-label" md="12">
                          <dt>
                            <span id="ie">
                              <Translate contentKey="generadorApp.supplier.ie">Ie</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top supplier-ie-detail-value" md="12">
                          <dd>{supplierEntity.ie}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row supplier-groups-detail" md="6">
                      <Row>
                        <Col className="view-detail-label-top supplier-groups-detail-label" md="12">
                          <dt>
                            <Translate contentKey="generadorApp.supplier.groups">Groups</Translate>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top supplier-groups-detail-value" md="12">
                          <dd>
                            {supplierEntity.groups
                              ? supplierEntity.groups.map((val, i) => (
                                  <span key={val.id}>
                                    <a>{val.name}</a>
                                    {i === supplierEntity.groups.length - 1 ? '' : ', '}
                                  </span>
                                ))
                              : null}
                          </dd>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={1}>
                  <Row>
                    <Col className="view-detail-row supplier-zipCode-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top supplier-zipCode-detail-label" md="12">
                          <dt>
                            <span id="zipCode">
                              <Translate contentKey="generadorApp.supplier.zipCode">Zip Code</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top supplier-zipCode-detail-value" md="12">
                          <dd>{supplierEntity.zipCode}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row supplier-street-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top supplier-street-detail-label" md="12">
                          <dt>
                            <span id="street">
                              <Translate contentKey="generadorApp.supplier.street">Street</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top supplier-street-detail-value" md="12">
                          <dd>{supplierEntity.street}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row supplier-complement-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top supplier-complement-detail-label" md="12">
                          <dt>
                            <span id="complement">
                              <Translate contentKey="generadorApp.supplier.complement">Complement</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top supplier-complement-detail-value" md="12">
                          <dd>{supplierEntity.complement}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row supplier-number-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top supplier-number-detail-label" md="12">
                          <dt>
                            <span id="number">
                              <Translate contentKey="generadorApp.supplier.number">Number</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top supplier-number-detail-value" md="12">
                          <dd>{supplierEntity.number}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row supplier-neighborhood-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top supplier-neighborhood-detail-label" md="12">
                          <dt>
                            <span id="neighborhood">
                              <Translate contentKey="generadorApp.supplier.neighborhood">Neighborhood</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top supplier-neighborhood-detail-value" md="12">
                          <dd>{supplierEntity.neighborhood}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row supplier-city-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top supplier-city-detail-label" md="12">
                          <dt>
                            <span id="city">
                              <Translate contentKey="generadorApp.supplier.city">City</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top supplier-city-detail-value" md="12">
                          <dd>{supplierEntity.city}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row supplier-uf-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top supplier-uf-detail-label" md="12">
                          <dt>
                            <span id="uf">
                              <Translate contentKey="generadorApp.supplier.uf">UF</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top supplier-uf-detail-value" md="12">
                          <dd>{supplierEntity.uf}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row supplier-reference-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top supplier-reference-detail-label" md="12">
                          <dt>
                            <span id="reference">
                              <Translate contentKey="generadorApp.supplier.reference">Reference</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top supplier-reference-detail-value" md="12">
                          <dd>{supplierEntity.reference}</dd>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={2}>
                  <Row>
                    <Col className="view-detail-row supplier-supplierContact-detail" md="12">
                      <Row>
                        <Col className="view-detail-label-top supplier-supplierContact-detail-label" md="12">
                          <dt>
                            <Translate contentKey="generadorApp.supplier.supplierContact">Supplier Contact</Translate>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top supplier-supplierContact-detail-value" md="12">
                          <dd>{supplierEntity.supplierContact ? supplierEntity.supplierContact.id : ''}</dd>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={3}>
                  <Row>
                    <Col className="view-detail-row supplier-contractSignatureDate-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top supplier-contractSignatureDate-detail-label" md="12">
                          <dt>
                            <span id="contractSignatureDate">
                              <Translate contentKey="generadorApp.supplier.contractSignatureDate">Signature Date</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top supplier-contractSignatureDate-detail-value" md="12">
                          <dd>
                            {supplierEntity.contractSignatureDate
                              ? moment(supplierEntity.contractSignatureDate).format(APP_DATE_FORMAT)
                              : ''}
                          </dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row supplier-expectedEmissionNfDay-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top supplier-expectedEmissionNfDay-detail-label" md="12">
                          <dt>
                            <span id="expectedEmissionNfDay">
                              <Translate contentKey="generadorApp.supplier.expectedEmissionNfDay">NF Expected issue day</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top supplier-expectedEmissionNfDay-detail-value" md="12">
                          <dd>{supplierEntity.expectedEmissionNfDay}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row supplier-expectedReceiptDate-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top supplier-expectedReceiptDate-detail-label" md="12">
                          <dt>
                            <span id="expectedReceiptDate">
                              <Translate contentKey="generadorApp.supplier.expectedReceiptDate">Expected payment date</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top supplier-expectedReceiptDate-detail-value" md="12">
                          <dd>{supplierEntity.expectedReceiptDate}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row supplier-observation-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top supplier-observation-detail-label" md="12">
                          <dt>
                            <span id="observation">
                              <Translate contentKey="generadorApp.supplier.observation">Observation</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top supplier-observation-detail-value" md="12">
                          <dd>{supplierEntity.observation}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row supplier-billingRules-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top supplier-billingRules-detail-label" md="12">
                          <dt>
                            <span id="billingRules">
                              <Translate contentKey="generadorApp.supplier.billingRules">Billing Rules</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top supplier-billingRules-detail-value" md="12">
                          <dd>{supplierEntity.billingRules}</dd>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="view-detail-row supplier-status-detail" md="3">
                      <Row>
                        <Col className="view-detail-label-top supplier-status-detail-label" md="12">
                          <dt>
                            <span id="status">
                              <Translate contentKey="generadorApp.supplier.status">Status</Translate>
                            </span>
                          </dt>
                        </Col>
                        <Col className="view-detail-value-top supplier-status-detail-value" md="12">
                          <dd>{translate('generadorApp.ExtraStatus.' + (supplierEntity.status ? supplierEntity.status : 'ATIVO'))}</dd>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { supplierEntity } = this.props;
    const state = this.state;
    return (
      <div>
        <Panel className="supplier supplier-detail">
          <PanelHeader>{this.renderHeader()}</PanelHeader>

          <PanelBody>{this.renderBody()}</PanelBody>
        </Panel>
      </div>
    );
  }
}

export const mapStateToProps = ({ supplier, ...storeState }: IRootState) => ({
  supplierEntity: supplier.entity,
});

export const mapDispatchToProps = {
  getEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SupplierDetail);
