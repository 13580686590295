/* eslint complexity: ["error", 500] */
import axios from 'axios';

import moment from 'moment';
import { IPaginationBaseState, IPayloadResult } from 'react-jhipster';

import { IPayload } from 'react-jhipster/src/type/redux-action.type';

import { BASE_API_VERSION_PATH } from 'app/config/constants';

import { cleanEntity, jsonParse } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IPad, defaultValue } from 'app/shared/model/pad.model';

import { IPadCidPta, defaultValue as defaultValuePadCidPta } from 'app/shared/model/pad-cid-pta.model';

export declare type ICrudPutAction<T> = (data?: T, listFiltersPage?) => IPayload<T> | IPayloadResult<T>;
export declare type ICrudGetAction<T> = (id: string | number, selectFields?: string) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const ACTION_TYPES = {
  FETCH_PAD_LIST_EXPORT: 'pad/FETCH_PAD_LIST_EXPORT',
  FETCH_PAD_LIST: 'pad/FETCH_PAD_LIST',
  FETCH_PAD: 'pad/FETCH_PAD',
  FETCH_PAD_OFFSET: 'pad/FETCH_PAD_OFFSET',
  CREATE_PAD: 'pad/CREATE_PAD',
  UPDATE_PAD: 'pad/UPDATE_PAD',
  CREATE_PAD_AND_GO_LIST: 'pad/CREATE_PAD_AND_GO_LIST',
  UPDATE_PAD_AND_GO_LIST: 'pad/UPDATE_PAD_AND_GO_LIST',
  DELETE_PAD: 'pad/DELETE_PAD',
  RESET: 'pad/RESET',
  SHOW_MODAL: 'pad/SHOW_MODAL',
};

const initialState = {
  loading: false,
  openModal: {
    list: false,
    view: false,
    detail: false,
  },
  errorMessage: null,
  entities: [] as ReadonlyArray<IPad>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,

  padCidPtaListEmbed: [] as ReadonlyArray<IPadCidPta> | any,
  padCidPtaListAllEmbed: [] as ReadonlyArray<IPadCidPta> | any,
  padCidPtaSelected: defaultValuePadCidPta,
};

const actionPendind = [];
let actualState = initialState;

export const convertEntityBuffers = entity => ({
  ...entity,
});

export type PadState = Readonly<typeof initialState>;

export interface IPadBaseState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  listCheckedID?: Array<number>;
  isCheckedAll?: boolean;
  urlBack?: string;
  extraFilters?: any;
  baseFilters: any;
  offset: number;
  id?: any;
  initDateStart?: any;
  initDateEnd?: any;
  finalDateStart?: any;
  finalDateEnd?: any;
  status?: any;
  patientId?: any;
  patientStartFilter?: any;
  quoteId?: any;
  quoteStartFilter?: any;
  padCidPtaId?: any;
  padCidPtaStartFilter?: any;
}

export interface IFieldsBase extends IPadBaseState, IPaginationBaseState {}
export interface IPadUpdateState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  urlBack?: string;
  functionEmbebed?: any[];
  fieldsBase: IFieldsBase;
  initDateSelectValue?: any;
  initDateStartSelectOptions?: any;
  finalDateSelectValue?: any;
  finalDateStartSelectOptions?: any;
  statusSelectValue?: any;
  statusStartSelectOptions?: any;

  padCidPtaSelectValue?: any;
  padCidPtaStartSelectOptions?: any;

  padAttendanceSelectValue?: any;
  padAttendanceStartSelectOptions?: any;

  patientSelectValue?: any;
  patientStartSelectOptions?: any;

  quoteSelectValue?: any;
  quoteStartSelectOptions?: any;

  isNew: boolean;
  patientName?: any;

  patientId?: any;
  quoteId?: any;
}

// Reducer

export default (state: PadState = initialState, action): PadState => {
  actualState = state;
  if (
    action.type.includes('_PENDING') &&
    !actionPendind.includes(action.type.replace('_PENDING', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_PENDING', ''))
  ) {
    actionPendind.push(action.type.replace('_PENDING', ''));
  }

  if (
    action.type.includes('_FULFILLED') &&
    actionPendind.includes(action.type.replace('_FULFILLED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_FULFILLED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_FULFILLED', '')), 1);
  }

  if (
    action.type.includes('_REJECTED') &&
    actionPendind.includes(action.type.replace('_REJECTED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_REJECTED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_REJECTED', '')), 1);
  }

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PAD_LIST_EXPORT):
    case REQUEST(ACTION_TYPES.FETCH_PAD_LIST):
    case REQUEST(ACTION_TYPES.FETCH_PAD):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_PAD):
    case REQUEST(ACTION_TYPES.UPDATE_PAD):
    case REQUEST(ACTION_TYPES.CREATE_PAD_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.UPDATE_PAD_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.DELETE_PAD):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_PAD_LIST_EXPORT):
    case FAILURE(ACTION_TYPES.FETCH_PAD_LIST):
    case FAILURE(ACTION_TYPES.FETCH_PAD):
    case FAILURE(ACTION_TYPES.CREATE_PAD):
    case FAILURE(ACTION_TYPES.UPDATE_PAD):
    case FAILURE(ACTION_TYPES.CREATE_PAD_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.UPDATE_PAD_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.DELETE_PAD):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_PAD_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_PAD_OFFSET):
      if (action.payload.data && action.payload.data.length > 0) {
        return {
          ...state,
          loading: false,
          entity: convertEntityBuffers(action.payload.data[0]),
        };
      }
      return {
        ...state,
        loading: false,
      };

    case SUCCESS(ACTION_TYPES.FETCH_PAD):
      return {
        ...state,
        loading: false,
        entity: convertEntityBuffers(action.payload.data),
      };
    case SUCCESS(ACTION_TYPES.CREATE_PAD):
    case SUCCESS(ACTION_TYPES.UPDATE_PAD):
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_PAD_AND_GO_LIST):
    case SUCCESS(ACTION_TYPES.UPDATE_PAD_AND_GO_LIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_PAD):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
        padCidPtaListEmbed: [],
        padCidPtaListAllEmbed: [],
        padCidPtaSelected: state.padCidPtaSelected,
      };

    case 'pad/PADCIDPTA_GET_ENTITIES':
      return {
        ...state,
        padCidPtaListEmbed: state.padCidPtaListAllEmbed,
      };
    case 'pad/PADCIDPTA_CREATE_ENTITY':
      return {
        ...state,
        padCidPtaListAllEmbed: [...(state.padCidPtaListAllEmbed ? state.padCidPtaListAllEmbed : []), action.payload],
      };
    case 'pad/PADCIDPTA_SET_ENTITIES':
      return {
        ...state,
        padCidPtaListAllEmbed: action.payload,
      };
    case 'pad/PADCIDPTA_UPDATE_ENTITY': {
      const padCidPtaListAllEmbed = [...state.padCidPtaListAllEmbed];
      state.padCidPtaListAllEmbed.map((v, i: any) => {
        if (v.id === action.payload.id) {
          padCidPtaListAllEmbed[i] = action.payload;
        }
      });
      return {
        ...state,
        padCidPtaListAllEmbed,
        padCidPtaSelected: {},
      };
    }
    case 'pad/PADCIDPTA_RESET':
      return {
        ...state,
        padCidPtaSelected: {},
        padCidPtaListEmbed: [],
      };
    case 'pad/PADCIDPTA_GET_ENTITY':
      if (!!state.padCidPtaListAllEmbed && state.padCidPtaListAllEmbed.constructor === Array) {
        return {
          ...state,
          padCidPtaSelected: state.padCidPtaListAllEmbed.filter(v => v.id === action.payload).pop(),
        };
      }
      return {
        ...state,
        padCidPtaSelected: state.padCidPtaListAllEmbed,
      };
    case 'pad/PADCIDPTA_DELETE_ENTITY':
      return {
        ...state,
        padCidPtaListAllEmbed: state.padCidPtaListAllEmbed.filter(v => v.id !== action.payload),
      };

    case ACTION_TYPES.SHOW_MODAL: {
      const openModal = state.openModal;
      openModal[action.payload.type] = action.payload.show;
      return {
        ...state,
        openModal,
      };
    }
    default:
      return state;
  }
};

const apiUrl = BASE_API_VERSION_PATH + 'pads';

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

// Actions

// Actions
export type ICrudGetAllActionPad<T> = (
  id?: any,
  initDateStart?: any,
  initDateEnd?: any,
  finalDateStart?: any,
  finalDateEnd?: any,
  status?: any,
  patientId?: any,
  quoteId?: any,
  padCidPtaId?: any,
  extraFilters?: {},
  page?: number,
  size?: number,
  sort?: string,
  selectFields?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getEntitiesFilter = (filters?, page?, size?, sort?, selectFields = '') => {
  const requestUrl = `${apiUrl}?page=${page >= 0 ? page : 0}&size=${size >= 0 ? size : 20}&sort=${sort ? sort : 'id,asc'}&`;
  const requestFilters = filters.map(v => v.column + '.' + v.operation + '=' + v.value).join('&');
  return {
    type: ACTION_TYPES.FETCH_PAD_LIST,
    payload: axios.get<IPad>(`${requestUrl}${requestFilters}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getAllEntities = (filters?, sort?, selectFields = '') => {
  if (
    (actualState.totalItems > 0 && actualState.entities.length === actualState.totalItems) ||
    actionPendind.includes(ACTION_TYPES.FETCH_PAD_LIST)
  ) {
    return {
      type: ACTION_TYPES.FETCH_PAD_LIST + '_FROM_CACHE',
      payload: new Promise(function (resolve, reject) {
        resolve('done');
      }),
    };
  }

  const idsRequest = filters && typeof filters['ids'] !== 'undefined' && filters['ids'] ? `id.in=${filters['ids'].join(',')}&` : '';
  const idRequest = filters && typeof filters['id'] !== 'undefined' && filters['id'] ? `id.equals=${filters['id']}&` : '';
  let initDateStartRequest = '';
  let initDateEndRequest = '';
  if (
    filters &&
    typeof filters['initDateStart'] !== 'undefined' &&
    filters['initDateStart'] &&
    !(typeof filters['initDateEnd'] !== 'undefined' && filters['initDateEnd'])
  ) {
    initDateStartRequest = `initDate.greaterThan=${moment(filters['initDateStart']).format('YYYY-MM-DD')}&`;
  } else if (
    filters &&
    !(typeof filters['initDateStart'] !== 'undefined' && filters['initDateStart']) &&
    typeof filters['initDateEnd'] !== 'undefined' &&
    filters['initDateEnd']
  ) {
    initDateEndRequest = `initDate.lessThan=${moment(filters['initDateEnd']).format('YYYY-MM-DD')}&`;
  } else if (
    filters &&
    typeof filters['initDateStart'] !== 'undefined' &&
    filters['initDateStart'] &&
    typeof filters['initDateEnd'] !== 'undefined' &&
    filters['initDateEnd']
  ) {
    initDateStartRequest = `initDate.between=${moment(filters['initDateStart']).format('YYYY-MM-DD')},${moment(
      filters['initDateEnd']
    ).format('YYYY-MM-DD')}&`;
  }
  let finalDateStartRequest = '';
  let finalDateEndRequest = '';
  if (
    filters &&
    typeof filters['finalDateStart'] !== 'undefined' &&
    filters['finalDateStart'] &&
    !(typeof filters['finalDateEnd'] !== 'undefined' && filters['finalDateEnd'])
  ) {
    finalDateStartRequest = `finalDate.greaterThan=${moment(filters['finalDateStart']).format('YYYY-MM-DD')}&`;
  } else if (
    filters &&
    !(typeof filters['finalDateStart'] !== 'undefined' && filters['finalDateStart']) &&
    typeof filters['finalDateEnd'] !== 'undefined' &&
    filters['finalDateEnd']
  ) {
    finalDateEndRequest = `finalDate.lessThan=${moment(filters['finalDateEnd']).format('YYYY-MM-DD')}&`;
  } else if (
    filters &&
    typeof filters['finalDateStart'] !== 'undefined' &&
    filters['finalDateStart'] &&
    typeof filters['finalDateEnd'] !== 'undefined' &&
    filters['finalDateEnd']
  ) {
    finalDateStartRequest = `finalDate.between=${moment(filters['finalDateStart']).format('YYYY-MM-DD')},${moment(
      filters['finalDateEnd']
    ).format('YYYY-MM-DD')}&`;
  }
  const statusRequest =
    filters && typeof filters['status'] !== 'undefined' && filters['status'] ? `status.contains=${filters['status']}&` : '';
  const patientRequest =
    filters && typeof filters['patientId'] !== 'undefined' && filters['patientId'] ? `patient.id.in=${filters['patientId']}&` : '';
  const quoteRequest =
    filters && typeof filters['quoteId'] !== 'undefined' && filters['quoteId'] ? `quote.id.in=${filters['quoteId']}&` : '';
  const padCidPtaRequest =
    filters && typeof filters['padCidPtaId'] !== 'undefined' && filters['padCidPtaId'] ? `padCidPta.id.in=${filters['padCidPtaId']}&` : '';

  const requestUrl = `${apiUrl}${`?page=${0}&size=${1000000}&sort=${sort ? sort : 'id,asc'}&`}`;
  return {
    type: ACTION_TYPES.FETCH_PAD_LIST,
    payload: axios.get<IPad>(
      `${requestUrl}${idsRequest}${idRequest}${initDateStartRequest}${initDateEndRequest}${finalDateStartRequest}${finalDateEndRequest}${statusRequest}${patientRequest}${quoteRequest}${padCidPtaRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntities: ICrudGetAllActionPad<IPad> = (
  id,
  initDateStart,
  initDateEnd,
  finalDateStart,
  finalDateEnd,
  status,
  patientId,
  quoteId,
  padCidPtaId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const idRequest = id ? `id.equals=${id}&` : '';
  let initDateStartRequest = '';
  let initDateEndRequest = '';
  if (typeof initDateStart !== 'undefined' && initDateStart && !(typeof initDateEnd !== 'undefined' && initDateEnd)) {
    initDateStartRequest = `initDate.greaterThan=${moment(initDateStart).format('YYYY-MM-DD')}&`;
  } else if (!(typeof initDateStart !== 'undefined' && initDateStart) && typeof initDateEnd !== 'undefined' && initDateEnd) {
    initDateEndRequest = `initDate.lessThan=${moment(initDateEnd).format('YYYY-MM-DD')}&`;
  } else if (typeof initDateStart !== 'undefined' && initDateStart && typeof initDateEnd !== 'undefined' && initDateEnd) {
    initDateStartRequest = `initDate.between=${moment(initDateStart).format('YYYY-MM-DD')},${moment(initDateEnd).format('YYYY-MM-DD')}&`;
  }
  let finalDateStartRequest = '';
  let finalDateEndRequest = '';
  if (typeof finalDateStart !== 'undefined' && finalDateStart && !(typeof finalDateEnd !== 'undefined' && finalDateEnd)) {
    finalDateStartRequest = `finalDate.greaterThan=${moment(finalDateStart).format('YYYY-MM-DD')}&`;
  } else if (!(typeof finalDateStart !== 'undefined' && finalDateStart) && typeof finalDateEnd !== 'undefined' && finalDateEnd) {
    finalDateEndRequest = `finalDate.lessThan=${moment(finalDateEnd).format('YYYY-MM-DD')}&`;
  } else if (typeof finalDateStart !== 'undefined' && finalDateStart && typeof finalDateEnd !== 'undefined' && finalDateEnd) {
    finalDateStartRequest = `finalDate.between=${moment(finalDateStart).format('YYYY-MM-DD')},${moment(finalDateEnd).format(
      'YYYY-MM-DD'
    )}&`;
  }
  const statusRequest = status ? (Array.isArray(status) ? `status.in=${status.join(',')}&` : `status.equals=${status}&`) : '';
  const patientRequest = patientId ? `patient.id.in=${patientId}&` : '';
  const quoteRequest = quoteId ? `quote.id.in=${quoteId}&` : '';
  const padCidPtaRequest = padCidPtaId ? `padCidPta.id.in=${padCidPtaId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PAD_LIST,
    payload: axios.get<IPad>(
      `${requestUrl}${extraFiltersRequest}${idRequest}${initDateStartRequest}${initDateEndRequest}${finalDateStartRequest}${finalDateEndRequest}${statusRequest}${patientRequest}${quoteRequest}${padCidPtaRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntityOffset: any = (
  id,
  initDateStart,
  initDateEnd,
  finalDateStart,
  finalDateEnd,
  status,
  patientId,
  quoteId,
  padCidPtaId,
  extraFilters,
  offset,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const idRequest = id ? `id.equals=${id}&` : '';
  let initDateStartRequest = '';
  let initDateEndRequest = '';
  if (typeof initDateStart !== 'undefined' && initDateStart && !(typeof initDateEnd !== 'undefined' && initDateEnd)) {
    initDateStartRequest = `initDate.greaterThan=${moment(initDateStart).format('YYYY-MM-DD')}&`;
  } else if (!(typeof initDateStart !== 'undefined' && initDateStart) && typeof initDateEnd !== 'undefined' && initDateEnd) {
    initDateEndRequest = `initDate.lessThan=${moment(initDateEnd).format('YYYY-MM-DD')}&`;
  } else if (typeof initDateStart !== 'undefined' && initDateStart && typeof initDateEnd !== 'undefined' && initDateEnd) {
    initDateStartRequest = `initDate.between=${moment(initDateStart).format('YYYY-MM-DD')},${moment(initDateEnd).format('YYYY-MM-DD')}&`;
  }
  let finalDateStartRequest = '';
  let finalDateEndRequest = '';
  if (typeof finalDateStart !== 'undefined' && finalDateStart && !(typeof finalDateEnd !== 'undefined' && finalDateEnd)) {
    finalDateStartRequest = `finalDate.greaterThan=${moment(finalDateStart).format('YYYY-MM-DD')}&`;
  } else if (!(typeof finalDateStart !== 'undefined' && finalDateStart) && typeof finalDateEnd !== 'undefined' && finalDateEnd) {
    finalDateEndRequest = `finalDate.lessThan=${moment(finalDateEnd).format('YYYY-MM-DD')}&`;
  } else if (typeof finalDateStart !== 'undefined' && finalDateStart && typeof finalDateEnd !== 'undefined' && finalDateEnd) {
    finalDateStartRequest = `finalDate.between=${moment(finalDateStart).format('YYYY-MM-DD')},${moment(finalDateEnd).format(
      'YYYY-MM-DD'
    )}&`;
  }
  const statusRequest = status ? (Array.isArray(status) ? `status.in=${status.join(',')}&` : `status.equals=${status}&`) : '';
  const patientRequest = patientId ? `patient.id.in=${patientId}&` : '';
  const quoteRequest = quoteId ? `quote.id.in=${quoteId}&` : '';
  const padCidPtaRequest = padCidPtaId ? `padCidPta.id.in=${padCidPtaId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${offset}&size=${1}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PAD_OFFSET,
    payload: axios.get<IPad>(
      `${requestUrl}${extraFiltersRequest}${idRequest}${initDateStartRequest}${initDateEndRequest}${finalDateStartRequest}${finalDateEndRequest}${statusRequest}${patientRequest}${quoteRequest}${padCidPtaRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntity: ICrudGetAction<IPad> = (id, selectFields = '') => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_PAD,
    payload: axios.get<IPad>(requestUrl, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntitiesExport: ICrudGetAllActionPad<IPad> = (
  id,
  initDateStart,
  initDateEnd,
  finalDateStart,
  finalDateEnd,
  status,
  patientId,
  quoteId,
  padCidPtaId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const idRequest = id ? (Array.isArray(status) ? `id.in=${id.join(',')}&` : `id.equals=${id}&`) : '';
  let initDateStartRequest = '';
  let initDateEndRequest = '';
  if (typeof initDateStart !== 'undefined' && initDateStart && !(typeof initDateEnd !== 'undefined' && initDateEnd)) {
    initDateStartRequest = `initDate.greaterThan=${moment(initDateStart).format('YYYY-MM-DD')}&`;
  } else if (!(typeof initDateStart !== 'undefined' && initDateStart) && typeof initDateEnd !== 'undefined' && initDateEnd) {
    initDateEndRequest = `initDate.lessThan=${moment(initDateEnd).format('YYYY-MM-DD')}&`;
  } else if (typeof initDateStart !== 'undefined' && initDateStart && typeof initDateEnd !== 'undefined' && initDateEnd) {
    initDateStartRequest = `initDate.between=${moment(initDateStart).format('YYYY-MM-DD')},${moment(initDateEnd).format('YYYY-MM-DD')}&`;
  }
  let finalDateStartRequest = '';
  let finalDateEndRequest = '';
  if (typeof finalDateStart !== 'undefined' && finalDateStart && !(typeof finalDateEnd !== 'undefined' && finalDateEnd)) {
    finalDateStartRequest = `finalDate.greaterThan=${moment(finalDateStart).format('YYYY-MM-DD')}&`;
  } else if (!(typeof finalDateStart !== 'undefined' && finalDateStart) && typeof finalDateEnd !== 'undefined' && finalDateEnd) {
    finalDateEndRequest = `finalDate.lessThan=${moment(finalDateEnd).format('YYYY-MM-DD')}&`;
  } else if (typeof finalDateStart !== 'undefined' && finalDateStart && typeof finalDateEnd !== 'undefined' && finalDateEnd) {
    finalDateStartRequest = `finalDate.between=${moment(finalDateStart).format('YYYY-MM-DD')},${moment(finalDateEnd).format(
      'YYYY-MM-DD'
    )}&`;
  }
  const statusRequest = status ? (Array.isArray(status) ? `status.in=${status.join(',')}&` : `status.equals=${status}&`) : '';
  const patientRequest = patientId ? `patient.id.in=${patientId}&` : '';
  const quoteRequest = quoteId ? `quote.id.in=${quoteId}&` : '';
  const padCidPtaRequest = padCidPtaId ? `padCidPta.id.in=${padCidPtaId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PAD_LIST,
    payload: axios.get<IPad>(
      `${requestUrl}${extraFiltersRequest}${idRequest}${initDateStartRequest}${initDateEndRequest}${finalDateStartRequest}${finalDateEndRequest}${statusRequest}${patientRequest}${quoteRequest}${padCidPtaRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const createEntity: ICrudPutAction<IPad> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = {
    ...entity,
    initDate: entity.initDate ? moment(entity.initDate).format('YYYY-MM-DD') : null,
    finalDate: entity.finalDate ? moment(entity.finalDate).format('YYYY-MM-DD') : null,
  };
  const result = await dispatch({
    type: listFiltersPage && listFiltersPage['reloadList'] !== false ? ACTION_TYPES.CREATE_PAD_AND_GO_LIST : ACTION_TYPES.CREATE_PAD,
    payload: axios.post(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const updateEntity: ICrudPutAction<IPad> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = {
    ...entity,
    initDate: entity.initDate ? moment(entity.initDate).format('YYYY-MM-DD') : null,
    finalDate: entity.finalDate ? moment(entity.finalDate).format('YYYY-MM-DD') : null,
  };
  const result = await dispatch({
    type: listFiltersPage && listFiltersPage['reloadList'] !== false ? ACTION_TYPES.UPDATE_PAD_AND_GO_LIST : ACTION_TYPES.UPDATE_PAD,
    payload: axios.put(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const deleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_PAD,
    payload: axios.delete(requestUrl),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const showModal = view => ({
  payload: { type: view, show: true },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const hideModal = view => ({
  payload: { type: view, show: false },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const getUrlBack = (location): string => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  let urlBack = _urlBase.searchParams.get('urlBack') || '';
  urlBack = urlBack.trim();
  urlBack = urlBack.startsWith('/') ? urlBack.slice(1) : urlBack;
  urlBack = urlBack.endsWith('/') ? urlBack.slice(0, -1) : urlBack;
  return urlBack ? '/' + urlBack + '/' : '';
};

export const getPadState = (location): IPadBaseState => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  const extraFilters = jsonParse(_urlBase.searchParams.get('extraFilters') || '{}');
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;

  const id = _urlBase.searchParams.get('id') || '';
  const initDateStart = _urlBase.searchParams.get('initDateStart') || '';
  const initDateEnd = _urlBase.searchParams.get('initDateEnd') || '';
  const finalDateStart = _urlBase.searchParams.get('finalDateStart') || '';
  const finalDateEnd = _urlBase.searchParams.get('finalDateEnd') || '';
  const status = _urlBase.searchParams.get('status') || '';
  const patientId = _urlBase.searchParams.get('patient') || '';
  const quoteId = _urlBase.searchParams.get('quote') || '';
  const padCidPtaId = _urlBase.searchParams.get('padCidPta') || '';
  return {
    baseFilters,
    extraFilters,
    offset,
    id,
    initDateStart,
    initDateEnd,
    finalDateStart,
    finalDateEnd,
    status,
    patientId: patientId ? patientId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
    quoteId: quoteId ? quoteId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
    padCidPtaId: padCidPtaId ? padCidPtaId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
  };
};

export const getEntityFiltersURL = (state, offset = null) => {
  return (
    'baseFilters=' +
    state.baseFilters +
    '&extraFilters=' +
    encodeURI(JSON.stringify(state.extraFilters)) +
    '&page=' +
    state.activePage +
    '&' +
    'size=' +
    state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    state.sort +
    ',' +
    state.order +
    '&' +
    (state.id ? 'id=' + state.id + '&' : '') +
    (state.initDate ? 'initDate=' + state.initDate + '&' : '') +
    (state.finalDate ? 'finalDate=' + state.finalDate + '&' : '') +
    (state.status ? 'status=' + state.status + '&' : '') +
    (state.patientId ? 'patient=' + state.patientId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.quoteId ? 'quote=' + state.quoteId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.padCidPtaId ? 'padCidPta=' + state.padCidPtaId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    ''
  );
};

export const padCidPtaReset: any = values => ({
  payload: values,
  type: 'pad/PADCIDPTA_RESET',
});

export const padCidPtaGetEntities: any = values => ({
  payload: values,
  type: 'pad/PADCIDPTA_GET_ENTITIES',
});

export const padCidPtaSetEntities: any = values => async dispatch => {
  const result = await dispatch({
    payload: values,
    type: 'pad/PADCIDPTA_SET_ENTITIES',
  });
  dispatch(padCidPtaGetEntities());
  return result;
};

export const padCidPtaCreateEntity: ICrudPutAction<IPadCidPta> = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: { id: (new Date().getTime() * Math.random()).toString(16).split('.').join('-'), ...entity },
    type: 'pad/PADCIDPTA_CREATE_ENTITY',
  });

  if (entity.pad === 'is_new') {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(padCidPtaReset());
      dispatch(padCidPtaGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};

export const padCidPtaUpdateEntity: ICrudPutAction<IPadCidPta> = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: entity,
    type: 'pad/PADCIDPTA_UPDATE_ENTITY',
  });
  if (entity.pad === 'is_new') {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(padCidPtaReset());
      dispatch(padCidPtaGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};
export const padCidPtaGetEntity: any = values => ({
  payload: values,
  type: 'pad/PADCIDPTA_GET_ENTITY',
});

export const padCidPtaDeleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: id,
    type: 'pad/PADCIDPTA_DELETE_ENTITY',
  });
  if (isNaN(id)) {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(padCidPtaReset());
      dispatch(padCidPtaGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};
