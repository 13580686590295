/* eslint complexity: ["error", 500] */
import React from 'react';
import axios from 'axios';

import { connect } from 'react-redux';

import Select from 'react-select';
import { toast } from 'react-toastify';

import { Link, RouteComponentProps } from 'react-router-dom';

import { Button, Col, Row, Table, Label, UncontrolledCollapse, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { JhiItemCount } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { IRootState } from 'app/shared/reducers';
import {
  updateEntity,
  getUrlBack,
  getConfigTagState,
  IConfigTagBaseState,
  getEntityFiltersURL,
  getEntities,
  IConfigTagUpdateState as IBaseUpdateState,
  reset,
  getEntity,
  deleteEntity,
  createEntity,
} from './config-tag.reducer';

import { BASE_API_VERSION_PATH } from 'app/config/constants';
import { ITEMS_PER_PAGE, IPaginationBaseState, getSortState } from 'app/shared/util/pagination.constants';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

export interface IConfigTagProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
  entityEmbebedPai?: any;
}

export interface IConfigTagState extends IConfigTagBaseState, IPaginationBaseState, IBaseUpdateState {
  showModalForm?: boolean;
  showModalEdit?: number;
  showModalEditView?: boolean;
}

export class ConfigTag extends React.Component<IConfigTagProps, IConfigTagState> {
  protected myFormRef: any;
  protected myFormRefNew: any;
  protected myFormRefEdit: any;

  nameFileInput: any;

  isMedicalRecordFileInput: any;

  activeFileInput: any;

  constructor(props: IConfigTagProps) {
    super(props);
    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      listCheckedID: [],
      isCheckedAll: false,
      showModalForm: false,
      showModalEdit: null,
      urlBack: getUrlBack(this.props.location),
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      ...getConfigTagState(this.props.location),
      subTagSelectValue: null,

      nursingDiarySelectValue: null,

      medicalRecordSelectValue: null,

      nameSelectValue: null,
      isMedicalRecordSelectValue: null,
      activeSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getConfigTagState(this.props.location),
      },
      idsnursingDiary: [],
      idsmedicalRecord: [],
      isNew: false,
    };
  }

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({ listCheckedID: this.props.configTagList.map(configTag => configTag.id) });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({ listCheckedID: this.props.configTagList.map(configTag => configTag.id) });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  removeEntitySelected() {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  }

  saveEntityMany = () => {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  };

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  cancelFilters = () => {
    this.setState(
      {
        name: '',
        isMedicalRecord: '',
        active: '',
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
        activePage: 0,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();

    const selectFieldsList = ['name', 'isMedicalRecord', 'active'];
    const { name, isMedicalRecord, active, extraFilters, activePage, itemsPerPage, sort, order } = this.state;
    return this.props.getEntities(
      name,
      isMedicalRecord,
      active,
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(',')
    );
  };

  updateEntity = entity => {
    const selectFieldsList = ['name', 'isMedicalRecord', 'active'];

    const { name, isMedicalRecord, active, extraFilters, activePage, itemsPerPage, sort, order } = this.state;
    this.props.updateEntity(entity, [
      name,
      isMedicalRecord,
      active,
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(','),
    ]);
  };

  saveEntity = (event: any, errors: any, values: any, isNew: any) => {
    if (!this.state.nameSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.configTag.name') }));
      return false;
    }

    const selectFieldsList = ['name', 'isMedicalRecord', 'active'];

    if (errors.length === 0) {
      const { configTagEntity } = this.props;
      const _entity = {
        ...configTagEntity,
        // ...values,

        name: this.state.nameSelectValue,
        isMedicalRecord: this.state.isMedicalRecordSelectValue === null ? false : this.state.isMedicalRecordSelectValue,
        active: this.state.activeSelectValue === null ? false : this.state.activeSelectValue,
      };
      const entity = _entity;

      const { name, isMedicalRecord, active, extraFilters, activePage, itemsPerPage, sort, order } = this.state.fieldsBase;

      if (isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              name,
              isMedicalRecord,
              active,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              name,
              isMedicalRecord,
              active,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
      this.setState({ showModalForm: false });
      this.setState({ showModalEdit: null });
    } else {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.specialty.' + errors[0]) }));
    }
  };

  componentDidUpdate(prevProps) {}

  deleteEntity(configTagEntity) {
    MySwal.fire({
      title: <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>,
      html: (
        <Translate contentKey="generadorApp.configTag.delete.question" interpolate={{ id: configTagEntity.id }}>
          Are you sure you want to delete this ConfigTag?
        </Translate>
      ),
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#e70015',
      cancelButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="ban" />
          &nbsp; <Translate contentKey="generadorApp.configTag.btnCancel">Cancel</Translate>
        </>
      ),
      confirmButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="trash" />
          &nbsp;<Translate contentKey="generadorApp.configTag.btnDelete">Delete</Translate>
        </>
      ),
    }).then(async response => {
      if (response.isConfirmed) {
        await this.props.deleteEntity(configTagEntity.id, { reloadList: false });
        this.getEntities();
        MySwal.fire({
          title: <Translate contentKey="entity.delete.title.confirmed">Confirmed delete operation</Translate>,
          icon: 'success',
        });
      }
    });
  }

  renderNewModal() {
    return (
      <Modal size={'xl'} isOpen={this.state.showModalForm} toggle={() => this.setState({ showModalForm: false })}>
        <ModalHeader toggle={() => this.setState({ showModalForm: false })}>{this.renderHeaderUpdate({}, true)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefNew = el)}
          model={{}}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, true)}
        >
          <ModalBody>{this.renderBodyUpdate({}, true)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalForm: false })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.configTag.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefNew && this.myFormRefNew.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.configTag.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    );
  }

  getFullEntityFromState(v) {
    return {
      id: v.id,
      name: this.state.nameSelectValue,
      isMedicalRecord: this.state.isMedicalRecordSelectValue,
      active: this.state.activeSelectValue,
      subTag: this.state.subTagSelectValue,
      nursingDiary: this.state.nursingDiarySelectValue,
      medicalRecord: this.state.medicalRecordSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    const p: any = null;

    const subTagEntity = v.subTag ? v.subTag.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' })) : v.subTag;

    const nursingDiaryEntity = v.nursingDiaries
      ? v.nursingDiaries.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' }))
      : v.nursingDiaries;

    const medicalRecordEntity = v.medicalRecords
      ? v.medicalRecords.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' }))
      : v.medicalRecords;

    return {
      nameSelectValue: v.name, // name,
      isMedicalRecordSelectValue: v.isMedicalRecord, // isMedicalRecord,
      activeSelectValue: v.active, // active,
      subTagSelectValue: subTagEntity,
      nursingDiarySelectValue: nursingDiaryEntity,
      medicalRecordSelectValue: medicalRecordEntity,
    };
  }

  openUpdateModal(v, edit = true) {
    this.props.getEntity(v.id);
    this.setState({
      showModalEdit: v.id,
      showModalEditView: edit,
      ...this.getFullEntitySelectValue(v),
    });
  }

  renderUpdateModal() {
    const { configTagEntity, entityEmbebedPai } = this.props;
    return configTagEntity && configTagEntity.id ? (
      <Modal size={'xl'} isOpen={this.state.showModalEdit === configTagEntity.id} toggle={() => this.setState({ showModalEdit: null })}>
        <ModalHeader toggle={() => this.setState({ showModalEdit: null })}>{this.renderHeaderUpdate(configTagEntity, false)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefEdit = el)}
          model={{
            ...configTagEntity,
          }}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, false)}
        >
          <ModalBody>{this.renderBodyUpdate(configTagEntity, false)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalEdit: null })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.configTag.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefEdit && this.myFormRefEdit.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.configTag.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    ) : (
      <> </>
    );
  }

  renderHeaderUpdate(configTagEntity, isNew) {
    const { entityEmbebedPai, subTags, nursingDiaries, medicalRecords, loading } = this.props;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {isNew ? (
              <Translate contentKey="generadorApp.configTag.home.createLabel">Create a ConfigTag</Translate>
            ) : (
              <Translate contentKey="generadorApp.configTag.home.editLabel">Edit a ConfigTag</Translate>
            )}
          </span>
        </div>
      </>
    );
  }
  renderBodyUpdate(configTagEntity, isNew) {
    const { entityEmbebedPai, subTags, nursingDiaries, medicalRecords, loading } = this.props;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
              <Col md="3">
              <Label className="mt-2 label-single-line"for="config-tag-id">
                <Translate contentKey="generadorApp.configTag.field_id">ID</Translate>
              </Label>
              </Col> */}
                      <Col md="12">
                        <AvInput id="config-tag-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'name' ? (
                    <Col md="4">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="nameLabel" for="config-tag-name">
                              <Translate contentKey="generadorApp.configTag.name">Name</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                              value={this.state.nameSelectValue ? this.state.nameSelectValue : ''}
                              id="config-tag-name"
                              type="text"
                              name="name"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                      type="hidden"
                      name="name"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'isMedicalRecord' ? (
                    <Col md="4">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="isMedicalRecordLabel" for="config-tag-isMedicalRecord">
                              <Translate contentKey="generadorApp.configTag.isMedicalRecord">Medical Record</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'isMedicalRecord_checkbox'}
                                id={'isMedicalRecord_checkbox'}
                                checked={this.state.isMedicalRecordSelectValue}
                                onChange={evt => this.setState({ isMedicalRecordSelectValue: !this.state.isMedicalRecordSelectValue })}
                              />
                              <label htmlFor={'isMedicalRecord_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ isMedicalRecordSelectValue: evt.target.value })}
                      type="hidden"
                      name="isMedicalRecord"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'active' ? (
                    <Col md="4">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="activeLabel" for="config-tag-active">
                              <Translate contentKey="generadorApp.configTag.active">Active</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'active_checkbox'}
                                id={'active_checkbox'}
                                checked={this.state.activeSelectValue}
                                onChange={evt => this.setState({ activeSelectValue: !this.state.activeSelectValue })}
                              />
                              <label htmlFor={'active_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ activeSelectValue: evt.target.value })}
                      type="hidden"
                      name="active"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  renderHeader() {
    const { subTags, nursingDiaries, medicalRecords, configTagList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.configTag.home.title">Tags</Translate>
          </span>
          <Button id="togglerFilterConfigTag" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.configTag.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          &nbsp;
          {hasAnyAuthority(props.userAccount, ['config-tag'], 'resgister') ? (
            <Button
              onClick={() =>
                this.setState({
                  showModalForm: true,
                  nameSelectValue: null,
                  isMedicalRecordSelectValue: null,
                  activeSelectValue: null,

                  subTagStartSelectOptions: undefined,
                  subTagSelectValue: null,

                  nursingDiaryStartSelectOptions: undefined,
                  nursingDiarySelectValue: null,

                  medicalRecordStartSelectOptions: undefined,
                  medicalRecordSelectValue: null,
                  isNew: true,
                })
              }
              color="primary"
              className="btn btn-primary float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="plus" />
              &nbsp;
              <Translate contentKey="generadorApp.configTag.btnNewItem">Create new</Translate>
            </Button>
          ) : (
            <></>
          )}{' '}
          &nbsp;
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.configTag.home.title">Tags</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    const { subTags, nursingDiaries, medicalRecords, configTagList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <UncontrolledCollapse toggler="#togglerFilterConfigTag">
          <CardBody>
            <AvForm ref={el => (this.myFormRef = el)} id="form-filter" onSubmit={this.filterEntity}>
              <div className="row mt-1 ml-3 mr-3">
                {this.state.baseFilters !== 'name' ? (
                  <Col md="3" className="col-filter-config-tag-name">
                    <Row className="mr-1 mt-1">
                      <Label id="nameLabel" for="config-tag-name">
                        <Translate contentKey="generadorApp.configTag.name">Name</Translate>
                      </Label>

                      <AvInput type="text" name="name" id="config-tag-name" value={this.state.name} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'isMedicalRecord' ? (
                  <Col md="3" className="col-filter-config-tag-isMedicalRecord">
                    <Row className="mr-1 mt-1">
                      <Label id="isMedicalRecordLabel" for="config-tag-isMedicalRecord">
                        <Translate contentKey="generadorApp.configTag.isMedicalRecord">Medical Record</Translate>
                      </Label>
                      <Select
                        id="config-tag-isMedicalRecord"
                        className={'css-select-control'}
                        value={
                          this.state.isMedicalRecord === 0
                            ? { value: 0, label: translate('generadorApp.configTag.isMedicalRecord_filter_without') }
                            : this.state.isMedicalRecord === 1
                            ? { value: 1, label: translate('generadorApp.configTag.isMedicalRecord_filter_with') }
                            : { value: '', label: translate('generadorApp.configTag.isMedicalRecord_filter_all') }
                        }
                        options={[
                          { value: '', label: translate('generadorApp.configTag.isMedicalRecord_filter_all') },
                          { value: 1, label: translate('generadorApp.configTag.isMedicalRecord_filter_with') },
                          { value: 0, label: translate('generadorApp.configTag.isMedicalRecord_filter_without') },
                        ]}
                        onChange={(options: any) => this.setState({ isMedicalRecord: options['value'] })}
                        name="isMedicalRecord"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'active' ? (
                  <Col md="3" className="col-filter-config-tag-active">
                    <Row className="mr-1 mt-1">
                      <Label id="activeLabel" for="config-tag-active">
                        <Translate contentKey="generadorApp.configTag.active">Active</Translate>
                      </Label>
                      <Select
                        id="config-tag-active"
                        className={'css-select-control'}
                        value={
                          this.state.active === 0
                            ? { value: 0, label: translate('generadorApp.configTag.active_filter_without') }
                            : this.state.active === 1
                            ? { value: 1, label: translate('generadorApp.configTag.active_filter_with') }
                            : { value: '', label: translate('generadorApp.configTag.active_filter_all') }
                        }
                        options={[
                          { value: '', label: translate('generadorApp.configTag.active_filter_all') },
                          { value: 1, label: translate('generadorApp.configTag.active_filter_with') },
                          { value: 0, label: translate('generadorApp.configTag.active_filter_without') },
                        ]}
                        onChange={(options: any) => this.setState({ active: options['value'] })}
                        name="active"
                      />
                    </Row>
                  </Col>
                ) : null}
              </div>

              <div className="row mb-2 mr-4 justify-content-end">
                <Button color="primary" type="submit">
                  <i className="fa fa-filter" aria-hidden={'true'}></i>&nbsp;
                  <Translate contentKey="generadorApp.configTag.btnFilter">Filter</Translate>
                </Button>
                &nbsp;
                <Button color="secondary" onClick={this.cancelFilters}>
                  <FontAwesomeIcon icon="trash-alt" />
                  &nbsp;
                  <Translate contentKey="generadorApp.configTag.btnCleanFilter">Clean Filter</Translate>
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </UncontrolledCollapse>
      </>
    );
  }

  renderTable() {
    const { subTags, nursingDiaries, medicalRecords, configTagList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {configTagList && configTagList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null).length > 0 ? (
          <>
            <Table
              id="config-tag-table-list"
              responsive
              aria-describedby="config-tag-heading"
              className={'table-hover table-striped table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.configTag.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'name' ? (
                    <th className="hand" onClick={sortFunction('name')}>
                      <Translate contentKey="generadorApp.configTag.name">Name</Translate>
                      <FontAwesomeIcon icon={state.sort === 'name' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'isMedicalRecord' ? (
                    <th className="hand" onClick={sortFunction('isMedicalRecord')}>
                      <Translate contentKey="generadorApp.configTag.isMedicalRecord">Medical Record</Translate>
                      <FontAwesomeIcon
                        icon={state.sort === 'isMedicalRecord' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'}
                      />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'active' ? (
                    <th className="hand" onClick={sortFunction('active')}>
                      <Translate contentKey="generadorApp.configTag.active">Active</Translate>
                      <FontAwesomeIcon icon={state.sort === 'active' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}

                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {configTagList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((configTag, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.configTag.field_id">ID</Translate>
                        </b>
                        {configTag.id}
                      </td>

                      {state.baseFilters !== 'name' ? (
                        <td id="name-cell">
                          <b className="visible-xs"> Name </b>

                          {configTag.name}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'isMedicalRecord' ? (
                        <td id="isMedicalRecord-cell">
                          <b className="visible-xs"> Medical Record </b>
                          <div className="switcher switcher-success">
                            <input
                              type="checkbox"
                              name={'isMedicalRecord_' + configTag.id}
                              id={'isMedicalRecord_' + configTag.id}
                              defaultChecked={configTag.isMedicalRecord}
                              onClick={(evt: any) =>
                                axios
                                  .put(BASE_API_VERSION_PATH + 'config-tags', { ...configTag, isMedicalRecord: evt.target.checked })
                                  .then(v => {
                                    this.getEntities();
                                    MySwal.fire({ title: 'Atualizado com sucesso', icon: 'success' });
                                  })
                              }
                            />
                            <label htmlFor={'isMedicalRecord_' + configTag.id} />
                          </div>
                        </td>
                      ) : null}

                      {state.baseFilters !== 'active' ? (
                        <td id="active-cell">
                          <b className="visible-xs"> Active </b>
                          <div className="switcher switcher-success">
                            <input
                              type="checkbox"
                              name={'active_' + configTag.id}
                              id={'active_' + configTag.id}
                              defaultChecked={configTag.active}
                              onClick={(evt: any) =>
                                axios.put(BASE_API_VERSION_PATH + 'config-tags', { ...configTag, active: evt.target.checked }).then(v => {
                                  this.getEntities();
                                  MySwal.fire({ title: 'Atualizado com sucesso', icon: 'success' });
                                })
                              }
                            />
                            <label htmlFor={'active_' + configTag.id} />
                          </div>
                        </td>
                      ) : null}

                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          {hasAnyAuthority(props.userAccount, ['config-tag'], 'edit') ? (
                            <Button onClick={() => this.openUpdateModal(configTag)} color="primary" size="sm">
                              <FontAwesomeIcon icon="pencil-alt" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                          {hasAnyAuthority(props.userAccount, ['config-tag'], 'canDelete') ? (
                            <Button onClick={() => this.deleteEntity(configTag)} color="danger" size="sm">
                              <FontAwesomeIcon icon="trash" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.configTag.home.notFound">No Tags found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const { subTags, nursingDiaries, medicalRecords, configTagList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={configTagList && configTagList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const { subTags, nursingDiaries, medicalRecords, configTagList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel className="config-tag config-tag-all-in-one">
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
            {this.renderNewModal()}
            {this.renderUpdateModal()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToProps = ({ configTag, ...storeState }: IRootState) => ({
  subTags: storeState.subTag.entities,
  nursingDiaries: storeState.nursingDiary.entities,
  medicalRecords: storeState.medicalRecord.entities,
  configTagList: configTag.entities,
  totalItems: configTag.totalItems,
  userAccount: storeState.authentication.account,
  loading: configTag.loading,

  // UPDATE
  configTagEntity: configTag.entity,
});

export const mapDispatchToProps = {
  getEntities,

  updateEntity,
  reset,

  // UPDATE
  getEntity,
  createEntity,

  // DELETE
  deleteEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ConfigTag);
