import brasindiceExtended, { BrasindiceStateExtended } from 'app/entities/brasindice/extended/brasindice.reducer';
import simproExtended, { SimproStateExtended } from 'app/entities/simpro/extended/simpro.reducer';

export interface IRootStateExtended {
  readonly brasindice: BrasindiceStateExtended;
  readonly simpro: SimproStateExtended;
}

export default {
  brasindiceExtended,
  simproExtended,
};
