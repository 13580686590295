const config = {
  VERSION: process.env.VERSION,
};

export default config;

export const SERVER_API_URL = process.env.SERVER_API_URL;
export const BASE_API_VERSION_PATH = 'api/';

export const AUTHORITIES = {
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
  COLLECTOR: 'ROLE_COLLECTOR',
};

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};

export const currency = {
  REAIS: 'R$',
  CRUZEIRO: 'Cr$',
};

export const estados = {
  Todos: -1,
  SC: 1,
  SP: 2,
};

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_TIME_FORMAT = 'HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_TIMESTAMP_FORMAT_Z = 'YYYY-MM-DDTHH:mm:ss.Z';
export const APP_LOCAL_TIME_FORMAT = 'HH:mm';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATE_FORMAT_SERVER = 'YYYY-MM-DD';
export const APP_LOCAL_DATETIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';
export const APP_LOCAL_DATETIME_SEM_SEG = 'DD/MM/YYYY HH:mm';
export const APP_LOCAL_DATETIME_FORMAT_Z = 'YYYY-MM-DDTHH:mm Z';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';
