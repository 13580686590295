/* eslint-disable no-console */
/* eslint complexity: ["error", 300] */
import React from 'react';
import { connect } from 'react-redux';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import SelectAsync from 'react-select/async';
import { Table, Button, Col, Row, Label } from 'reactstrap';
import { AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Translate, translate } from 'app/config/translate-component';
import { PadCidPta, mapStateToProps, mapDispatchToProps } from '../pad-cid-pta';
import { IRootState } from 'app/shared/reducers';
import { IActivity } from 'app/shared/model/activity.model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { getListAxios } from 'app/shared/util/entity-utils';

export interface IExtendedState {
  cidSelectValue?: any;
  cidStartSelectOptions?: any;
}
export interface IExtendedProps {
  entityEmbebedPai?: any;
}

export class PadCidPtaExtended extends PadCidPta {
  renderHeader() {
    return <> {super.renderHeader()} </>;
  }

  renderFilter() {
    return <> {super.renderFilter()} </>;
  }

  renderTable() {
    const { pads, patientCids, cidPtas, padCidPtaList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {padCidPtaList && padCidPtaList.length > 0 ? (
          <Table
            id="pad-cid-pta-table-list"
            responsive
            aria-describedby="pad-cid-pta-heading"
            className={'table-hover table-striped table-responsive-css'}
          >
            <thead className={'thead-light'}>
              <tr>
                <th className="hand" onClick={sortFunction('id')}>
                  <Translate contentKey="generadorApp.padCidPta.field_id">ID</Translate>
                  <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                </th>
                <th>
                  <Translate contentKey="generadorApp.padCidPta.pad">Pta</Translate>
                </th>
                <th>
                  <Translate contentKey="generadorApp.padCidPta.cidPta">Cid</Translate>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr></tr>
              {padCidPtaList
                .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                .map((padCidPta, i) => (
                  <tr key={`entity-${i}`}>
                    <td>
                      <b className="visible-xs">
                        <Translate contentKey="generadorApp.padCidPta.field_id">ID</Translate>
                      </b>
                      <Button tag={Link} to={`${match.url}/${padCidPta.id}`} color="link" size="sm">
                        {padCidPta.id}
                      </Button>
                    </td>

                    <td id="pad-cell">
                      <b className="visible-xs"> PTA </b>
                      {padCidPta && padCidPta.cidPta ? padCidPta.cidPta.name : ''}
                    </td>
                    <td id="cidPta-cell">
                      <b className="visible-xs"> Cid Pta </b>
                      {padCidPta && padCidPta.cidPta && padCidPta.cidPta.cid ? padCidPta.cidPta.cid.name : ''}
                    </td>

                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">
                        <Button onClick={() => this.deleteEntity(padCidPta)} color="danger" size="sm">
                          <FontAwesomeIcon icon="trash" />
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.padCidPta.home.notFound">No Include PTA found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  UNSAFE_componentWillMount() {
    super.UNSAFE_componentWillMount();
  }

  renderFooter() {
    return <> {super.renderFooter()} </>;
  }

  renderBodyUpdate(padCidPtaEntity, isNew) {
    const { pads, patientCids, cidPtas, loading } = this.props;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const padBaseFilterActivity: ReadonlyArray<IActivity> = padCidPtaEntity.cidPta ? padCidPtaEntity.cidPta : [];
    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      <Col md="12">
                        <AvInput id="pad-cid-pta-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  <Col md="6">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" for="pad-cid-pta-cid">
                            <Translate contentKey="generadorApp.cid.home.title">Cid</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <SelectAsync
                            id="pad-cid-pta-cid"
                            name={'cidPta'}
                            className={'css-select-control'}
                            data-type-rel="many-to-one-other-side"
                            value={this.state.cidSelectValue}
                            onChange={options =>
                              this.setState({ cidSelectValue: options, cidPtaStartSelectOptions: undefined, cidPtaSelectValue: undefined })
                            }
                            defaultOptions={this.state.cidStartSelectOptions ? this.state.cidStartSelectOptions : []}
                            loadingMessage={input => translate('selectAsync.loadingMessage')}
                            noOptionsMessage={input =>
                              this.state.cidStartSelectOptions === undefined
                                ? translate('selectAsync.loadingMessage')
                                : translate('selectAsync.noOptionsMessage')
                            }
                            onMenuOpen={async () => {
                              if (this.state.cidStartSelectOptions === undefined) {
                                const result = await getListAxios(
                                  'cids',
                                  {
                                    'patientCid.patient.equals': this.props.entityEmbebedPai ? this.props.entityEmbebedPai.patient.id : '',
                                  },
                                  0,
                                  100,
                                  'code,asc',
                                  'code,name'
                                );
                                this.setState({
                                  cidStartSelectOptions: result.data
                                    ? result.data.map(p => ({
                                        ...p,
                                        value: p.id,
                                        label: (p['code'] ? p.code : '') + ' | ' + (p['name'] ? p.name : ''),
                                      }))
                                    : [],
                                });
                              }
                            }}
                            loadOptions={async (inputValue, callback) => {
                              const result = await getListAxios('cids', { 'name.contains': inputValue }, 0, 100, 'code,asc', 'code,name');
                              callback(
                                result.data
                                  ? result.data.map(p => ({
                                      ...p,
                                      value: p.id,
                                      label: (p['code'] ? p.code : '') + ' | ' + (p['name'] ? p.name : ''),
                                    }))
                                  : []
                              );
                            }}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>

                  <Col md="6">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" for="pad-cid-pta-cidPta">
                            <Translate contentKey="generadorApp.padCidPta.cidPta">Cid Pta</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <SelectAsync
                            id="pad-cid-pta-cidPta"
                            name={'cidPta'}
                            className={'css-select-control'}
                            data-type-rel="many-to-one-other-side"
                            value={this.state.cidPtaSelectValue}
                            onChange={options => this.setState({ cidPtaSelectValue: options })}
                            defaultOptions={this.state.cidPtaStartSelectOptions ? this.state.cidPtaStartSelectOptions : []}
                            loadingMessage={input => translate('selectAsync.loadingMessage')}
                            noOptionsMessage={input =>
                              this.state.cidPtaStartSelectOptions === undefined
                                ? translate('selectAsync.loadingMessage')
                                : translate('selectAsync.noOptionsMessage')
                            }
                            onMenuOpen={async () => {
                              if (this.state.cidPtaStartSelectOptions === undefined) {
                                const result = await getListAxios(
                                  'cid-ptas',
                                  { 'cid.equals': this.state.cidSelectValue ? this.state.cidSelectValue.id : '' },
                                  0,
                                  100,
                                  'name,asc',
                                  'name'
                                );
                                this.setState({
                                  cidPtaStartSelectOptions: result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: p['name'] ? p.name : '' }))
                                    : [],
                                });
                              }
                            }}
                            loadOptions={async (inputValue, callback) => {
                              const result = await getListAxios('cid-ptas', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                              callback(result.data ? result.data.map(p => ({ ...p, value: p.id, label: p['name'] ? p.name : '' })) : []);
                            }}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>

                  <Col md="12">
                    <AvGroup>
                      <Row>
                        {padBaseFilterActivity
                          .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                          .map((cidPta, i) => (
                            <Col key={`entity-${i}`} md="12">
                              <h3
                                dangerouslySetInnerHTML={{
                                  __html: cidPta.name
                                    ? Buffer.from(cidPta.name)
                                        .toString()
                                        .replace(/(<([^>]+)>)/gi, '')
                                        .substring(0, 150)
                                    : null,
                                }}
                              />
                            </Col>
                          ))}
                      </Row>
                    </AvGroup>
                  </Col>
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { padCidPtaList, match, totalItems, pads } = this.props;
    let padBaseFilter = null;
    if (pads) {
      pads.map(p => {
        if (p.id === parseInt(this.state.padId, 10)) padBaseFilter = p;
      });
    }

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel>
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
            {this.renderNewModal()}
            {this.renderUpdateModal()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
    patientCids: storeState.patientCid.entities,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;

export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(PadCidPtaExtended);
