/* eslint complexity: ["error", 500] */
import axios from 'axios';

import moment from 'moment';
import { IPaginationBaseState, IPayloadResult } from 'react-jhipster';

import { IPayload } from 'react-jhipster/src/type/redux-action.type';

import { BASE_API_VERSION_PATH } from 'app/config/constants';

import { cleanEntity, jsonParse } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IPadAttendance, defaultValue } from 'app/shared/model/pad-attendance.model';

import { SwitchExtended, initialStateExtended } from './extended/pad-attendance.reducer';

export declare type ICrudPutAction<T> = (data?: T, listFiltersPage?) => IPayload<T> | IPayloadResult<T>;
export declare type ICrudGetAction<T> = (id: string | number, selectFields?: string) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const ACTION_TYPES = {
  FETCH_PADATTENDANCE_LIST_EXPORT: 'padAttendance/FETCH_PADATTENDANCE_LIST_EXPORT',
  FETCH_PADATTENDANCE_LIST: 'padAttendance/FETCH_PADATTENDANCE_LIST',
  FETCH_PADATTENDANCE: 'padAttendance/FETCH_PADATTENDANCE',
  FETCH_PADATTENDANCE_OFFSET: 'padAttendance/FETCH_PADATTENDANCE_OFFSET',
  CREATE_PADATTENDANCE: 'padAttendance/CREATE_PADATTENDANCE',
  UPDATE_PADATTENDANCE: 'padAttendance/UPDATE_PADATTENDANCE',
  CREATE_PADATTENDANCE_AND_GO_LIST: 'padAttendance/CREATE_PADATTENDANCE_AND_GO_LIST',
  UPDATE_PADATTENDANCE_AND_GO_LIST: 'padAttendance/UPDATE_PADATTENDANCE_AND_GO_LIST',
  DELETE_PADATTENDANCE: 'padAttendance/DELETE_PADATTENDANCE',
  SET_BLOB: 'padAttendance/SET_BLOB',
  RESET: 'padAttendance/RESET',
  SHOW_MODAL: 'padAttendance/SHOW_MODAL',
};

const initialState = {
  loading: false,
  openModal: {
    list: false,
    view: false,
    detail: false,
  },
  errorMessage: null,
  entities: [] as ReadonlyArray<IPadAttendance>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,

  ...initialStateExtended,
};

const actionPendind = [];
let actualState = initialState;

export const convertEntityBuffers = entity => ({
  ...entity,
  push: entity.push ? Buffer.from(entity.push).toString() : entity.push,
  evolution: entity.evolution ? Buffer.from(entity.evolution).toString() : entity.evolution,
});

export type PadAttendanceState = Readonly<typeof initialState>;

export interface IPadAttendanceBaseState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  listCheckedID?: Array<number>;
  isCheckedAll?: boolean;
  urlBack?: string;
  extraFilters?: any;
  baseFilters: any;
  offset: number;
  padId?: any;
  padStartFilter?: any;
  period?: any;
  schedule?: any;
  push?: any;
  frequency?: any;
  valuePad?: any;
  quantity?: any;
  dateStart?: any;
  dateEnd?: any;
  quoteRhId?: any;
  quoteRhStartFilter?: any;
  quoteRhQuoteId?: any;
  quoteRhQuoteStartFilter?: any;
  quoteRhQuotePatientId?: any;
  quoteRhQuotePatientStartFilter?: any;
  professionalId?: any;
  professionalStartFilter?: any;
  status?: any;
  quoteRhQuotePatientClientId?: any;
  quoteRhQuotePatientClientStartFilter?: any;
  quoteRhSpecialtyCategoryId?: any;
  quoteRhSpecialtyCategoryStartFilter?: any;
}

export interface IFieldsBase extends IPadAttendanceBaseState, IPaginationBaseState {}
export interface IPadAttendanceUpdateState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  urlBack?: string;
  functionEmbebed?: any[];
  fieldsBase: IFieldsBase;
  periodSelectValue?: any;
  periodStartSelectOptions?: any;
  scheduleSelectValue?: any;
  scheduleStartSelectOptions?: any;
  pushSelectValue?: any;
  pushStartSelectOptions?: any;
  evolutionSelectValue?: any;
  evolutionStartSelectOptions?: any;
  valuePadSelectValue?: any;
  valuePadStartSelectOptions?: any;
  frequencySelectValue?: any;
  frequencyStartSelectOptions?: any;
  quantitySelectValue?: any;
  quantityStartSelectOptions?: any;
  dateSelectValue?: any;
  dateStartSelectOptions?: any;
  statusSelectValue?: any;
  statusStartSelectOptions?: any;
  cancellationReasonSelectValue?: any;
  cancellationReasonStartSelectOptions?: any;
  checkInSelectValue?: any;
  checkInStartSelectOptions?: any;
  checkOutSelectValue?: any;
  checkOutStartSelectOptions?: any;
  latSelectValue?: any;
  latStartSelectOptions?: any;
  lngSelectValue?: any;
  lngStartSelectOptions?: any;

  padAttendanceFileSelectValue?: any;
  padAttendanceFileStartSelectOptions?: any;

  professionalSelectValue?: any;
  professionalStartSelectOptions?: any;

  padSelectValue?: any;
  padStartSelectOptions?: any;

  quoteRhSelectValue?: any;
  quoteRhStartSelectOptions?: any;

  isNew: boolean;
  professionalName?: any;

  professionalId?: any;
  padId?: any;
  quoteRhId?: any;
}

// Reducer

export default (state: PadAttendanceState = initialState, action): PadAttendanceState => {
  actualState = state;
  if (
    action.type.includes('_PENDING') &&
    !actionPendind.includes(action.type.replace('_PENDING', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_PENDING', ''))
  ) {
    actionPendind.push(action.type.replace('_PENDING', ''));
  }

  if (
    action.type.includes('_FULFILLED') &&
    actionPendind.includes(action.type.replace('_FULFILLED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_FULFILLED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_FULFILLED', '')), 1);
  }

  if (
    action.type.includes('_REJECTED') &&
    actionPendind.includes(action.type.replace('_REJECTED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_REJECTED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_REJECTED', '')), 1);
  }

  const switchExtended = SwitchExtended(state, action);
  if (switchExtended !== null) return switchExtended;

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PADATTENDANCE_LIST_EXPORT):
    case REQUEST(ACTION_TYPES.FETCH_PADATTENDANCE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_PADATTENDANCE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_PADATTENDANCE):
    case REQUEST(ACTION_TYPES.UPDATE_PADATTENDANCE):
    case REQUEST(ACTION_TYPES.CREATE_PADATTENDANCE_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.UPDATE_PADATTENDANCE_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.DELETE_PADATTENDANCE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_PADATTENDANCE_LIST_EXPORT):
    case FAILURE(ACTION_TYPES.FETCH_PADATTENDANCE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_PADATTENDANCE):
    case FAILURE(ACTION_TYPES.CREATE_PADATTENDANCE):
    case FAILURE(ACTION_TYPES.UPDATE_PADATTENDANCE):
    case FAILURE(ACTION_TYPES.CREATE_PADATTENDANCE_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.UPDATE_PADATTENDANCE_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.DELETE_PADATTENDANCE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_PADATTENDANCE_LIST):
      action.payload.data = action.payload.data.map(v => {
        return { ...v, push: v.push ? Buffer.from(v.push).toString() : v.push };
      });
      action.payload.data = action.payload.data.map(v => {
        return { ...v, evolution: v.evolution ? Buffer.from(v.evolution).toString() : v.evolution };
      });

      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_PADATTENDANCE_OFFSET):
      if (action.payload.data && action.payload.data.length > 0) {
        return {
          ...state,
          loading: false,
          entity: convertEntityBuffers(action.payload.data[0]),
        };
      }
      return {
        ...state,
        loading: false,
      };

    case SUCCESS(ACTION_TYPES.FETCH_PADATTENDANCE):
      return {
        ...state,
        loading: false,
        entity: convertEntityBuffers(action.payload.data),
      };
    case SUCCESS(ACTION_TYPES.CREATE_PADATTENDANCE):
    case SUCCESS(ACTION_TYPES.UPDATE_PADATTENDANCE):
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_PADATTENDANCE_AND_GO_LIST):
    case SUCCESS(ACTION_TYPES.UPDATE_PADATTENDANCE_AND_GO_LIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_PADATTENDANCE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.SET_BLOB: {
      const { name, data, contentType, fileName } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [name + 'Base64']: data,
          [name + 'ContentType']: contentType,
          [name + 'FileName']: fileName,
        },
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };

    case ACTION_TYPES.SHOW_MODAL: {
      const openModal = state.openModal;
      openModal[action.payload.type] = action.payload.show;
      return {
        ...state,
        openModal,
      };
    }
    default:
      return state;
  }
};

const apiUrl = BASE_API_VERSION_PATH + 'pad-attendances';

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

// Actions

// Actions
export type ICrudGetAllActionPadAttendance<T> = (
  padId?: any,
  period?: any,
  schedule?: any,
  push?: any,
  frequency?: any,
  valuePad?: any,
  quantity?: any,
  dateStart?: any,
  dateEnd?: any,
  quoteRhId?: any,
  quoteRhQuoteId?: any,
  quoteRhQuotePatientId?: any,
  professionalId?: any,
  status?: any,
  quoteRhQuotePatientClientId?: any,
  quoteRhSpecialtyCategoryId?: any,
  extraFilters?: {},
  page?: number,
  size?: number,
  sort?: string,
  selectFields?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getEntitiesFilter = (filters?, page?, size?, sort?, selectFields = '') => {
  const requestUrl = `${apiUrl}?page=${page >= 0 ? page : 0}&size=${size >= 0 ? size : 20}&sort=${sort ? sort : 'id,asc'}&`;
  const requestFilters = filters.map(v => v.column + '.' + v.operation + '=' + v.value).join('&');
  return {
    type: ACTION_TYPES.FETCH_PADATTENDANCE_LIST,
    payload: axios.get<IPadAttendance>(`${requestUrl}${requestFilters}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getAllEntities = (filters?, sort?, selectFields = '') => {
  if (
    (actualState.totalItems > 0 && actualState.entities.length === actualState.totalItems) ||
    actionPendind.includes(ACTION_TYPES.FETCH_PADATTENDANCE_LIST)
  ) {
    return {
      type: ACTION_TYPES.FETCH_PADATTENDANCE_LIST + '_FROM_CACHE',
      payload: new Promise(function (resolve, reject) {
        resolve('done');
      }),
    };
  }

  const idsRequest = filters && typeof filters['ids'] !== 'undefined' && filters['ids'] ? `id.in=${filters['ids'].join(',')}&` : '';
  const padRequest = filters && typeof filters['padId'] !== 'undefined' && filters['padId'] ? `pad.id.in=${filters['padId']}&` : '';
  const periodRequest =
    filters && typeof filters['period'] !== 'undefined' && filters['period'] ? `period.contains=${filters['period']}&` : '';
  const scheduleRequest =
    filters && typeof filters['schedule'] !== 'undefined' && filters['schedule'] ? `schedule.contains=${filters['schedule']}&` : '';
  const pushRequest = filters && typeof filters['push'] !== 'undefined' && filters['push'] ? `push.contains=${filters['push']}&` : '';
  const frequencyRequest =
    filters && typeof filters['frequency'] !== 'undefined' && filters['frequency'] ? `frequency.contains=${filters['frequency']}&` : '';
  const valuePadRequest =
    filters && typeof filters['valuePad'] !== 'undefined' && filters['valuePad'] ? `valuePad.contains=${filters['valuePad']}&` : '';
  const quantityRequest =
    filters && typeof filters['quantity'] !== 'undefined' && filters['quantity'] ? `quantity.equals=${filters['quantity']}&` : '';
  let dateStartRequest = '';
  let dateEndRequest = '';
  if (
    filters &&
    typeof filters['dateStart'] !== 'undefined' &&
    filters['dateStart'] &&
    !(typeof filters['dateEnd'] !== 'undefined' && filters['dateEnd'])
  ) {
    dateStartRequest = `date.greaterThan=${moment(filters['dateStart']).format('YYYY-MM-DD')}&`;
  } else if (
    filters &&
    !(typeof filters['dateStart'] !== 'undefined' && filters['dateStart']) &&
    typeof filters['dateEnd'] !== 'undefined' &&
    filters['dateEnd']
  ) {
    dateEndRequest = `date.lessThan=${moment(filters['dateEnd']).format('YYYY-MM-DD')}&`;
  } else if (
    filters &&
    typeof filters['dateStart'] !== 'undefined' &&
    filters['dateStart'] &&
    typeof filters['dateEnd'] !== 'undefined' &&
    filters['dateEnd']
  ) {
    dateStartRequest = `date.between=${moment(filters['dateStart']).format('YYYY-MM-DD')},${moment(filters['dateEnd']).format(
      'YYYY-MM-DD'
    )}&`;
  }
  const quoteRhRequest =
    filters && typeof filters['quoteRhId'] !== 'undefined' && filters['quoteRhId'] ? `quoteRh.id.in=${filters['quoteRhId']}&` : '';
  const quoteRhQuoteRequest =
    filters && typeof filters['quoteRhQuoteId'] !== 'undefined' && filters['quoteRhQuoteId']
      ? `quoteRh.quote.id.in=${filters['quoteRhQuoteId']}&`
      : '';
  const quoteRhQuotePatientRequest =
    filters && typeof filters['quoteRhQuotePatientId'] !== 'undefined' && filters['quoteRhQuotePatientId']
      ? `quoteRh.quote.patient.id.in=${filters['quoteRhQuotePatientId']}&`
      : '';
  const professionalRequest =
    filters && typeof filters['professionalId'] !== 'undefined' && filters['professionalId']
      ? `professional.id.in=${filters['professionalId']}&`
      : '';
  const statusRequest =
    filters && typeof filters['status'] !== 'undefined' && filters['status'] ? `status.contains=${filters['status']}&` : '';
  const quoteRhQuotePatientClientRequest =
    filters && typeof filters['quoteRhQuotePatientClientId'] !== 'undefined' && filters['quoteRhQuotePatientClientId']
      ? `quoteRh.quote.patient.client.id.in=${filters['quoteRhQuotePatientClientId']}&`
      : '';
  const quoteRhSpecialtyCategoryRequest =
    filters && typeof filters['quoteRhSpecialtyCategoryId'] !== 'undefined' && filters['quoteRhSpecialtyCategoryId']
      ? `quoteRh.specialty.category.id.in=${filters['quoteRhSpecialtyCategoryId']}&`
      : '';

  const requestUrl = `${apiUrl}${`?page=${0}&size=${1000000}&sort=${sort ? sort : 'id,asc'}&`}`;
  return {
    type: ACTION_TYPES.FETCH_PADATTENDANCE_LIST,
    payload: axios.get<IPadAttendance>(
      `${requestUrl}${idsRequest}${padRequest}${periodRequest}${scheduleRequest}${pushRequest}${frequencyRequest}${valuePadRequest}${quantityRequest}${dateStartRequest}${dateEndRequest}${quoteRhRequest}${quoteRhQuoteRequest}${quoteRhQuotePatientRequest}${professionalRequest}${statusRequest}${quoteRhQuotePatientClientRequest}${quoteRhSpecialtyCategoryRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntities: ICrudGetAllActionPadAttendance<IPadAttendance> = (
  padId,
  period,
  schedule,
  push,
  frequency,
  valuePad,
  quantity,
  dateStart,
  dateEnd,
  quoteRhId,
  quoteRhQuoteId,
  quoteRhQuotePatientId,
  professionalId,
  status,
  quoteRhQuotePatientClientId,
  quoteRhSpecialtyCategoryId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const padRequest = padId ? `pad.id.in=${padId}&` : '';
  const periodRequest = period ? (Array.isArray(status) ? `period.in=${period.join(',')}&` : `period.equals=${period}&`) : '';
  const scheduleRequest = schedule ? `schedule.contains=${schedule}&` : '';
  const pushRequest = push ? `push.contains=${push}&` : '';
  const frequencyRequest = frequency
    ? Array.isArray(status)
      ? `frequency.in=${frequency.join(',')}&`
      : `frequency.equals=${frequency}&`
    : '';
  const valuePadRequest = valuePad ? `valuePad.contains=${valuePad}&` : '';
  const quantityRequest = quantity ? `quantity.equals=${quantity}&` : '';
  let dateStartRequest = '';
  let dateEndRequest = '';
  if (typeof dateStart !== 'undefined' && dateStart && !(typeof dateEnd !== 'undefined' && dateEnd)) {
    dateStartRequest = `date.greaterThan=${moment(dateStart).format('YYYY-MM-DD')}&`;
  } else if (!(typeof dateStart !== 'undefined' && dateStart) && typeof dateEnd !== 'undefined' && dateEnd) {
    dateEndRequest = `date.lessThan=${moment(dateEnd).format('YYYY-MM-DD')}&`;
  } else if (typeof dateStart !== 'undefined' && dateStart && typeof dateEnd !== 'undefined' && dateEnd) {
    dateStartRequest = `date.between=${moment(dateStart).format('YYYY-MM-DD')},${moment(dateEnd).format('YYYY-MM-DD')}&`;
  }
  const quoteRhRequest = quoteRhId ? `quoteRh.id.in=${quoteRhId}&` : '';
  const quoteRhQuoteRequest = quoteRhQuoteId ? `quoteRh.quote.id.in=${quoteRhQuoteId}&` : '';
  const quoteRhQuotePatientRequest = quoteRhQuotePatientId ? `quoteRh.quote.patient.id.in=${quoteRhQuotePatientId}&` : '';
  const professionalRequest = professionalId ? `professional.id.in=${professionalId}&` : '';
  const statusRequest = status ? (Array.isArray(status) ? `status.in=${status.join(',')}&` : `status.equals=${status}&`) : '';
  const quoteRhQuotePatientClientRequest = quoteRhQuotePatientClientId
    ? `quoteRh.quote.patient.client.id.in=${quoteRhQuotePatientClientId}&`
    : '';
  const quoteRhSpecialtyCategoryRequest = quoteRhSpecialtyCategoryId
    ? `quoteRh.specialty.category.id.in=${quoteRhSpecialtyCategoryId}&`
    : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PADATTENDANCE_LIST,
    payload: axios.get<IPadAttendance>(
      `${requestUrl}${extraFiltersRequest}${padRequest}${periodRequest}${scheduleRequest}${pushRequest}${frequencyRequest}${valuePadRequest}${quantityRequest}${dateStartRequest}${dateEndRequest}${quoteRhRequest}${quoteRhQuoteRequest}${quoteRhQuotePatientRequest}${professionalRequest}${statusRequest}${quoteRhQuotePatientClientRequest}${quoteRhSpecialtyCategoryRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntityOffset: any = (
  padId,
  period,
  schedule,
  push,
  frequency,
  valuePad,
  quantity,
  dateStart,
  dateEnd,
  quoteRhId,
  quoteRhQuoteId,
  quoteRhQuotePatientId,
  professionalId,
  status,
  quoteRhQuotePatientClientId,
  quoteRhSpecialtyCategoryId,
  extraFilters,
  offset,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const padRequest = padId ? `pad.id.in=${padId}&` : '';
  const periodRequest = period ? (Array.isArray(status) ? `period.in=${period.join(',')}&` : `period.equals=${period}&`) : '';
  const scheduleRequest = schedule ? `schedule.contains=${schedule}&` : '';
  const pushRequest = push ? `push.contains=${push}&` : '';
  const frequencyRequest = frequency
    ? Array.isArray(status)
      ? `frequency.in=${frequency.join(',')}&`
      : `frequency.equals=${frequency}&`
    : '';
  const valuePadRequest = valuePad ? `valuePad.contains=${valuePad}&` : '';
  const quantityRequest = quantity ? `quantity.equals=${quantity}&` : '';
  let dateStartRequest = '';
  let dateEndRequest = '';
  if (typeof dateStart !== 'undefined' && dateStart && !(typeof dateEnd !== 'undefined' && dateEnd)) {
    dateStartRequest = `date.greaterThan=${moment(dateStart).format('YYYY-MM-DD')}&`;
  } else if (!(typeof dateStart !== 'undefined' && dateStart) && typeof dateEnd !== 'undefined' && dateEnd) {
    dateEndRequest = `date.lessThan=${moment(dateEnd).format('YYYY-MM-DD')}&`;
  } else if (typeof dateStart !== 'undefined' && dateStart && typeof dateEnd !== 'undefined' && dateEnd) {
    dateStartRequest = `date.between=${moment(dateStart).format('YYYY-MM-DD')},${moment(dateEnd).format('YYYY-MM-DD')}&`;
  }
  const quoteRhRequest = quoteRhId ? `quoteRh.id.in=${quoteRhId}&` : '';
  const quoteRhQuoteRequest = quoteRhQuoteId ? `quoteRh.quote.id.in=${quoteRhQuoteId}&` : '';
  const quoteRhQuotePatientRequest = quoteRhQuotePatientId ? `quoteRh.quote.patient.id.in=${quoteRhQuotePatientId}&` : '';
  const professionalRequest = professionalId ? `professional.id.in=${professionalId}&` : '';
  const statusRequest = status ? (Array.isArray(status) ? `status.in=${status.join(',')}&` : `status.equals=${status}&`) : '';
  const quoteRhQuotePatientClientRequest = quoteRhQuotePatientClientId
    ? `quoteRh.quote.patient.client.id.in=${quoteRhQuotePatientClientId}&`
    : '';
  const quoteRhSpecialtyCategoryRequest = quoteRhSpecialtyCategoryId
    ? `quoteRh.specialty.category.id.in=${quoteRhSpecialtyCategoryId}&`
    : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${offset}&size=${1}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PADATTENDANCE_OFFSET,
    payload: axios.get<IPadAttendance>(
      `${requestUrl}${extraFiltersRequest}${padRequest}${periodRequest}${scheduleRequest}${pushRequest}${frequencyRequest}${valuePadRequest}${quantityRequest}${dateStartRequest}${dateEndRequest}${quoteRhRequest}${quoteRhQuoteRequest}${quoteRhQuotePatientRequest}${professionalRequest}${statusRequest}${quoteRhQuotePatientClientRequest}${quoteRhSpecialtyCategoryRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntity: ICrudGetAction<IPadAttendance> = (id, selectFields = '') => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_PADATTENDANCE,
    payload: axios.get<IPadAttendance>(requestUrl, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntitiesExport: ICrudGetAllActionPadAttendance<IPadAttendance> = (
  padId,
  period,
  schedule,
  push,
  frequency,
  valuePad,
  quantity,
  dateStart,
  dateEnd,
  quoteRhId,
  quoteRhQuoteId,
  quoteRhQuotePatientId,
  professionalId,
  status,
  quoteRhQuotePatientClientId,
  quoteRhSpecialtyCategoryId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const padRequest = padId ? `pad.id.in=${padId}&` : '';
  const periodRequest = period ? (Array.isArray(status) ? `period.in=${period.join(',')}&` : `period.equals=${period}&`) : '';
  const scheduleRequest = schedule ? (Array.isArray(status) ? `schedule.in=${schedule.join(',')}&` : `schedule.contains=${schedule}&`) : '';
  const pushRequest = push ? (Array.isArray(status) ? `push.in=${push.join(',')}&` : `push.contains=${push}&`) : '';
  const frequencyRequest = frequency
    ? Array.isArray(status)
      ? `frequency.in=${frequency.join(',')}&`
      : `frequency.equals=${frequency}&`
    : '';
  const valuePadRequest = valuePad ? (Array.isArray(status) ? `valuePad.in=${valuePad.join(',')}&` : `valuePad.contains=${valuePad}&`) : '';
  const quantityRequest = quantity ? (Array.isArray(status) ? `quantity.in=${quantity.join(',')}&` : `quantity.equals=${quantity}&`) : '';
  let dateStartRequest = '';
  let dateEndRequest = '';
  if (typeof dateStart !== 'undefined' && dateStart && !(typeof dateEnd !== 'undefined' && dateEnd)) {
    dateStartRequest = `date.greaterThan=${moment(dateStart).format('YYYY-MM-DD')}&`;
  } else if (!(typeof dateStart !== 'undefined' && dateStart) && typeof dateEnd !== 'undefined' && dateEnd) {
    dateEndRequest = `date.lessThan=${moment(dateEnd).format('YYYY-MM-DD')}&`;
  } else if (typeof dateStart !== 'undefined' && dateStart && typeof dateEnd !== 'undefined' && dateEnd) {
    dateStartRequest = `date.between=${moment(dateStart).format('YYYY-MM-DD')},${moment(dateEnd).format('YYYY-MM-DD')}&`;
  }
  const quoteRhRequest = quoteRhId ? `quoteRh.id.in=${quoteRhId}&` : '';
  const quoteRhQuoteRequest = quoteRhQuoteId ? `quoteRh.quote.id.in=${quoteRhQuoteId}&` : '';
  const quoteRhQuotePatientRequest = quoteRhQuotePatientId ? `quoteRh.quote.patient.id.in=${quoteRhQuotePatientId}&` : '';
  const professionalRequest = professionalId ? `professional.id.in=${professionalId}&` : '';
  const statusRequest = status ? (Array.isArray(status) ? `status.in=${status.join(',')}&` : `status.equals=${status}&`) : '';
  const quoteRhQuotePatientClientRequest = quoteRhQuotePatientClientId
    ? `quoteRh.quote.patient.client.id.in=${quoteRhQuotePatientClientId}&`
    : '';
  const quoteRhSpecialtyCategoryRequest = quoteRhSpecialtyCategoryId
    ? `quoteRh.specialty.category.id.in=${quoteRhSpecialtyCategoryId}&`
    : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PADATTENDANCE_LIST,
    payload: axios.get<IPadAttendance>(
      `${requestUrl}${extraFiltersRequest}${padRequest}${periodRequest}${scheduleRequest}${pushRequest}${frequencyRequest}${valuePadRequest}${quantityRequest}${dateStartRequest}${dateEndRequest}${quoteRhRequest}${quoteRhQuoteRequest}${quoteRhQuotePatientRequest}${professionalRequest}${statusRequest}${quoteRhQuotePatientClientRequest}${quoteRhSpecialtyCategoryRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const createEntity: ICrudPutAction<IPadAttendance> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity, date: entity.date ? moment(entity.date).format('YYYY-MM-DD') : null };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.CREATE_PADATTENDANCE_AND_GO_LIST
        : ACTION_TYPES.CREATE_PADATTENDANCE,
    payload: axios.post(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const updateEntity: ICrudPutAction<IPadAttendance> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity, date: entity.date ? moment(entity.date).format('YYYY-MM-DD') : null };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.UPDATE_PADATTENDANCE_AND_GO_LIST
        : ACTION_TYPES.UPDATE_PADATTENDANCE,
    payload: axios.put(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const deleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_PADATTENDANCE,
    payload: axios.delete(requestUrl),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const setBlob = (name, data, contentType?, fileName?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType,
    fileName,
  },
});

export const showModal = view => ({
  payload: { type: view, show: true },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const hideModal = view => ({
  payload: { type: view, show: false },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const getUrlBack = (location): string => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  let urlBack = _urlBase.searchParams.get('urlBack') || '';
  urlBack = urlBack.trim();
  urlBack = urlBack.startsWith('/') ? urlBack.slice(1) : urlBack;
  urlBack = urlBack.endsWith('/') ? urlBack.slice(0, -1) : urlBack;
  return urlBack ? '/' + urlBack + '/' : '';
};

export const getPadAttendanceState = (location): IPadAttendanceBaseState => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  const extraFilters = jsonParse(_urlBase.searchParams.get('extraFilters') || '{}');
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;

  const padId = _urlBase.searchParams.get('pad') || '';
  const period = _urlBase.searchParams.get('period') || '';
  const schedule = _urlBase.searchParams.get('schedule') || '';
  const push = _urlBase.searchParams.get('push') || '';
  const frequency = _urlBase.searchParams.get('frequency') || '';
  const valuePad = _urlBase.searchParams.get('valuePad') || '';
  const quantity = _urlBase.searchParams.get('quantity') || '';
  const dateStart = _urlBase.searchParams.get('dateStart') || '';
  const dateEnd = _urlBase.searchParams.get('dateEnd') || '';
  const quoteRhId = _urlBase.searchParams.get('quoteRh') || '';
  const quoteRhQuoteId = _urlBase.searchParams.get('quoteRh.quote') || '';
  const quoteRhQuotePatientId = _urlBase.searchParams.get('quoteRh.quote.patient') || '';
  const professionalId = _urlBase.searchParams.get('professional') || '';
  const status = _urlBase.searchParams.get('status') || '';
  const quoteRhQuotePatientClientId = _urlBase.searchParams.get('quoteRh.quote.patient.client') || '';
  const quoteRhSpecialtyCategoryId = _urlBase.searchParams.get('quoteRh.specialty.category') || '';
  return {
    baseFilters,
    extraFilters,
    offset,
    padId: padId ? padId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
    period,
    schedule,
    push,
    frequency,
    valuePad,
    quantity,
    dateStart,
    dateEnd,
    quoteRhId: quoteRhId ? quoteRhId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
    quoteRhQuoteId: quoteRhQuoteId ? quoteRhQuoteId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
    quoteRhQuotePatientId: quoteRhQuotePatientId
      ? quoteRhQuotePatientId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] }))
      : [],
    professionalId: professionalId ? professionalId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
    status,
    quoteRhQuotePatientClientId: quoteRhQuotePatientClientId
      ? quoteRhQuotePatientClientId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] }))
      : [],
    quoteRhSpecialtyCategoryId: quoteRhSpecialtyCategoryId
      ? quoteRhSpecialtyCategoryId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] }))
      : [],
  };
};

export const getEntityFiltersURL = (state, offset = null) => {
  return (
    'baseFilters=' +
    state.baseFilters +
    '&extraFilters=' +
    encodeURI(JSON.stringify(state.extraFilters)) +
    '&page=' +
    state.activePage +
    '&' +
    'size=' +
    state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    state.sort +
    ',' +
    state.order +
    '&' +
    (state.padId ? 'pad=' + state.padId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.period ? 'period=' + state.period + '&' : '') +
    (state.schedule ? 'schedule=' + state.schedule + '&' : '') +
    (state.push ? 'push=' + state.push + '&' : '') +
    (state.frequency ? 'frequency=' + state.frequency + '&' : '') +
    (state.valuePad ? 'valuePad=' + state.valuePad + '&' : '') +
    (state.quantity ? 'quantity=' + state.quantity + '&' : '') +
    (state.date ? 'date=' + state.date + '&' : '') +
    (state.quoteRhId ? 'quoteRh=' + state.quoteRhId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.quoteRhQuoteId ? 'quoteRh.quote.id=' + state.quoteRhQuoteId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.quoteRhQuotePatientId
      ? 'quoteRh.quote.patient.name=' + state.quoteRhQuotePatientId.map(v => v.value + '<->' + v.label).join(',') + '&'
      : '') +
    (state.professionalId ? 'professional=' + state.professionalId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.status ? 'status=' + state.status + '&' : '') +
    (state.quoteRhQuotePatientClientId
      ? 'quoteRh.quote.patient.client.fantasyName=' + state.quoteRhQuotePatientClientId.map(v => v.value + '<->' + v.label).join(',') + '&'
      : '') +
    (state.quoteRhSpecialtyCategoryId
      ? 'quoteRh.specialty.category.name=' + state.quoteRhSpecialtyCategoryId.map(v => v.value + '<->' + v.label).join(',') + '&'
      : '') +
    ''
  );
};
