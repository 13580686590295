import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { ISupplierGlosa } from 'app/shared/model/supplier-glosa.model';
import { ISupplierContact } from 'app/shared/model/supplier-contact.model';
import { ISupplierStatusHistory } from 'app/shared/model/supplier-status-history.model';
import { ISupplierFile } from 'app/shared/model/supplier-file.model';
import { INfe } from 'app/shared/model/nfe.model';
import { ISupplierTable } from 'app/shared/model/supplier-table.model';
import { IPharmacyStock } from 'app/shared/model/pharmacy-stock.model';
import { ISupply } from 'app/shared/model/supply.model';
import { IPatientSupplier } from 'app/shared/model/patient-supplier.model';
import { ILevelGroup } from 'app/shared/model/level-group.model';
import { IPatient } from 'app/shared/model/patient.model';
import { ExtraStatus } from 'app/shared/model/enumerations/extra-status.model';

export interface ISupplier {
  id?: number;
  socialReason?: string;
  fantasyName?: string;
  cnpj?: string;
  ie?: string;
  contractSignatureDate?: Moment;
  expectedEmissionNfDay?: number;
  expectedReceiptDate?: number;
  observation?: any;
  status?: ExtraStatus;
  billingRules?: string;
  zipCode?: string;
  street?: string;
  complement?: string;
  number?: string;
  neighborhood?: string;
  city?: string;
  uf?: string;
  reference?: string;
  user?: IUser;
  supplierGlosa?: ISupplierGlosa[];
  supplierContact?: ISupplierContact[];
  supplierStatusHistory?: ISupplierStatusHistory[];
  supplierFile?: ISupplierFile[];
  nfe?: INfe[];
  supplierTable?: ISupplierTable[];
  pharmacyStock?: IPharmacyStock[];
  supply?: ISupply[];
  patientSuppliers?: IPatientSupplier[];
  groups?: ILevelGroup[];
  patients?: IPatient[];
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  user: {
    isMulti: false, // one-to-one - owner-side
    entity: 'User',
    urlParameters: {
      enpoint: 'users',
      filters: 'supplier.id.equals',
    },
  },
  supplierGlosa: {
    isMulti: false, // one-to-many - other-side
    entity: 'SupplierGlosa',
    urlParameters: {
      enpoint: 'supplier-glosas',
      filters: 'supplier.id.equals',
    },
  },
  supplierContact: {
    isMulti: false, // one-to-many - other-side
    entity: 'SupplierContact',
    urlParameters: {
      enpoint: 'supplier-contacts',
      filters: 'supplier.id.equals',
    },
  },
  supplierStatusHistory: {
    isMulti: false, // one-to-many - other-side
    entity: 'SupplierStatusHistory',
    urlParameters: {
      enpoint: 'supplier-status-histories',
      filters: 'supplier.id.equals',
    },
  },
  supplierFile: {
    isMulti: false, // one-to-many - other-side
    entity: 'SupplierFile',
    urlParameters: {
      enpoint: 'supplier-files',
      filters: 'supplier.id.equals',
    },
  },
  nfe: {
    isMulti: false, // one-to-many - other-side
    entity: 'Nfe',
    urlParameters: {
      enpoint: 'nfes',
      filters: 'supplier.id.equals',
    },
  },
  supplierTable: {
    isMulti: false, // one-to-many - other-side
    entity: 'SupplierTable',
    urlParameters: {
      enpoint: 'supplier-tables',
      filters: 'supplier.id.equals',
    },
  },
  pharmacyStock: {
    isMulti: false, // one-to-many - other-side
    entity: 'PharmacyStock',
    urlParameters: {
      enpoint: 'pharmacy-stocks',
      filters: 'supplier.id.equals',
    },
  },
  supply: {
    isMulti: false, // one-to-many - other-side
    entity: 'Supply',
    urlParameters: {
      enpoint: 'supplies',
      filters: 'supplier.id.equals',
    },
  },
  patientSupplier: {
    isMulti: true, // many-to-many - owner-side
    entity: 'PatientSupplier',
    urlParameters: {
      enpoint: 'patient-suppliers',
      filters: 'suppliers.id.equals',
    },
  },
  levelGroup: {
    isMulti: true, // many-to-many - owner-side
    entity: 'LevelGroup',
    urlParameters: {
      enpoint: 'level-groups',
      filters: 'suppliers.id.equals',
    },
  },
  patient: {
    isMulti: true, // many-to-many - other-side
    entity: 'Patient',
    urlParameters: {
      enpoint: 'patients',
      filters: 'suppliers.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'supplier.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'supplier.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<ISupplier> = {};
