/* eslint complexity: ["error", 500] */
import React from 'react';

import { connect } from 'react-redux';
import ReactQuill from 'react-quill';
import {
  quillEditorModules,
  quillEditorFormats,
  getFilterFromSelect,
  getFormFromSelect,
  getListAxios,
  getValueRecursive,
  showFieldsSelectAsync,
} from 'app/shared/util/entity-utils';
import Select from 'react-select';
import { toast } from 'react-toastify';
import SelectAsync from 'react-select/async';
import { Link, RouteComponentProps } from 'react-router-dom';

import { Button, Col, Row, Table, Label, UncontrolledCollapse, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { setFileData, JhiItemCount } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { IRootState } from 'app/shared/reducers';
import {
  updateEntity,
  getUrlBack,
  getCategoryState,
  ICategoryBaseState,
  getEntityFiltersURL,
  getEntities,
  ICategoryUpdateState as IBaseUpdateState,
  reset,
  getEntity,
  deleteEntity,
  createEntity,
  setBlob,
} from './category.reducer';

import { APP_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE, IPaginationBaseState, getSortState } from 'app/shared/util/pagination.constants';

import { StatePropsExtended, DispatchPropsExtended, IExtendedState, IExtendedProps } from './extended/category';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';
import moment from 'moment';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

export interface ICategoryProps
  extends StateProps,
  DispatchProps,
  StatePropsExtended,
  DispatchPropsExtended,
  IExtendedProps,
  RouteComponentProps<{ url: string }> {
  entityEmbebedPai?: any;
}

export interface ICategoryState extends ICategoryBaseState, IPaginationBaseState, IBaseUpdateState, IExtendedState {
  showModalForm?: boolean;
  showModalEdit?: number;
  showModalEditView?: boolean;
}

export class Category extends React.Component<ICategoryProps, ICategoryState> {
  protected myFormRef: any;
  protected myFormRefNew: any;
  protected myFormRefEdit: any;

  nameFileInput: any;

  statusFileInput: any;

  contractFileInput: any;

  constructor(props: ICategoryProps) {
    super(props);
    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      listCheckedID: [],
      isCheckedAll: false,
      showModalForm: false,
      showModalEdit: null,
      urlBack: getUrlBack(this.props.location),
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      ...getCategoryState(this.props.location),
      categoryStatusHistorySelectValue: null,

      specialtySelectValue: null,

      clComRulerSelectValue: null,

      clComItemSelectValue: null,

      productServiceSelectValue: null,

      pushSelectValue: null,

      professionalSelectValue: null,

      levelGroupSelectValue: null,

      medicineMaterialSelectValue: null,

      kitSelectValue: null,

      nameSelectValue: null,
      statusSelectValue: null,
      contractSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getCategoryState(this.props.location),
      },
      idsmedicineMaterial: [],
      idskit: [],
      levelGroupName: null,
      isNew: false,
    };
  }

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({ listCheckedID: this.props.categoryList.map(category => category.id) });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({ listCheckedID: this.props.categoryList.map(category => category.id) });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  removeEntitySelected() {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  }

  saveEntityMany = () => {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  };

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  cancelFilters = () => {
    this.setState(
      {
        name: '',
        status: '',
        levelGroupId: '',
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
        activePage: 0,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };



  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();

    const selectFieldsList = ['name', 'levelGroup.name', 'createdDate', 'lastModifiedDate', 'status'];
    const { name, status, levelGroupId, extraFilters, activePage, itemsPerPage, sort, order } = this.state;
    return this.props.getEntities(
      name,
      status,
      getFilterFromSelect(levelGroupId, 'many-to-one'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(',')
    );
  };

  updateEntity = entity => {
    const selectFieldsList = ['name', 'levelGroup.name', 'createdDate', 'lastModifiedDate', 'status'];

    const { name, status, levelGroupId, extraFilters, activePage, itemsPerPage, sort, order } = this.state;
    this.props.updateEntity(entity, [
      name,
      status,
      getFilterFromSelect(levelGroupId, 'many-to-one'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(','),
    ]);
  };

  onBlobChange = (isAnImage, name, fileInput) => event => {
    const fileName = event.target.files[0].name;
    setFileData(event, (contentType, data) => this.props.setBlob(name, data, contentType, fileName), isAnImage);
    const varAux = {};
    varAux[name + 'SelectValue'] = fileName;
    this.setState(varAux);
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined);
  };

  saveEntity = (event: any, errors: any, values: any, isNew: any) => {
    if (!this.state.nameSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.category.name') }));
      return false;
    }

    const selectFieldsList = ['name', 'levelGroup.name', 'createdDate', 'lastModifiedDate', 'status'];

    if (errors.length === 0) {
      const { categoryEntity } = this.props;
      const _entity = {
        ...categoryEntity,
        // ...values,

        levelGroup: getFormFromSelect(this.state.levelGroupSelectValue, 'many-to-one'), // many-to-one - otherSide

        name: this.state.nameSelectValue,
        status: this.state.statusSelectValue === null ? 'INATIVADO' : this.state.statusSelectValue,
        contract: this.state.contractSelectValue,
      };
      const entity = _entity;

      const { name, status, levelGroupId, extraFilters, activePage, itemsPerPage, sort, order } = this.state.fieldsBase;

      if (isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              name,
              status,
              getFilterFromSelect(levelGroupId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              name,
              status,
              getFilterFromSelect(levelGroupId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
      this.setState({ showModalForm: false });
      this.setState({ showModalEdit: null });
    } else {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.specialty.' + errors[0]) }));
    }
  };

  componentDidUpdate(prevProps) { }

  deleteEntity(categoryEntity) {
    MySwal.fire({
      title: <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>,
      html: (
        <Translate contentKey="generadorApp.category.delete.question" interpolate={{ id: categoryEntity.id }}>
          Are you sure you want to delete this Category?
        </Translate>
      ),
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#e70015',
      cancelButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="ban" />
          &nbsp; <Translate contentKey="generadorApp.category.btnCancel">Cancel</Translate>
        </>
      ),
      confirmButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="trash" />
          &nbsp;<Translate contentKey="generadorApp.category.btnDelete">Delete</Translate>
        </>
      ),
    }).then(async response => {
      if (response.isConfirmed) {
        await this.props.deleteEntity(categoryEntity.id, { reloadList: false });
        this.getEntities();
        MySwal.fire({
          title: <Translate contentKey="entity.delete.title.confirmed">Confirmed delete operation</Translate>,
          icon: 'success',
        });
      }
    });
  }



  renderNewModal() {
    function checkValue(info:any) {
      if(info === null){
        return ''
      }else if(info === undefined) {
        return ''
      } else {
        return info
      }
    }


    return (
      <Modal size={'xl'} isOpen={this.state.showModalForm} toggle={() => this.setState({ showModalForm: false })}>
        <ModalHeader toggle={() => this.setState({ showModalForm: false })}>{this.renderHeaderUpdate({}, true)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefNew = el)}
          model={{}}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, true)}
        >
          <ModalBody>{this.renderBodyUpdate({}, true)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalForm: false })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.category.btnCancel">Cancel</Translate>
            </Button>
            {checkValue(this.state.levelGroupSelectValue) === '' || checkValue(this.state.levelGroupSelectValue) === '' ?
              <Button
                color="#c4c4c4"
                id="save-entity"
                onClick={() => toast.error("Preencha os campos obrigatórios.")}
                className="float-right jh-create-entity"
              >
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="generadorApp.category.btnSave">Save</Translate>
              </Button> :
              <Button
                color="primary"
                id="save-entity"
                onClick={() => this.myFormRefNew && this.myFormRefNew.submit()}
                className="float-right jh-create-entity"
              >
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="generadorApp.category.btnSave">Save</Translate>
              </Button>


            }

          </ModalFooter>
        </AvForm>
      </Modal>
    );
  }

  getFullEntityFromState(v) {
    return {
      id: v.id,
      name: this.state.nameSelectValue,
      status: this.state.statusSelectValue,
      contract: this.state.contractSelectValue,
      categoryStatusHistory: this.state.categoryStatusHistorySelectValue,
      specialty: this.state.specialtySelectValue,
      clComRuler: this.state.clComRulerSelectValue,
      clComItem: this.state.clComItemSelectValue,
      productService: this.state.productServiceSelectValue,
      push: this.state.pushSelectValue,
      professional: this.state.professionalSelectValue,
      levelGroup: this.state.levelGroupSelectValue,
      medicineMaterial: this.state.medicineMaterialSelectValue,
      kit: this.state.kitSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    let p: any = null;

    const categoryStatusHistoryEntity = v.categoryStatusHistory
      ? v.categoryStatusHistory.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' }))
      : v.categoryStatusHistory;

    const specialtyEntity = v.specialty
      ? v.specialty.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' }))
      : v.specialty;

    const clComRulerEntity = v.clComRuler
      ? v.clComRuler.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' }))
      : v.clComRuler;

    const clComItemEntity = v.clComItem
      ? v.clComItem.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' }))
      : v.clComItem;

    const productServiceEntity = v.productService
      ? v.productService.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' }))
      : v.productService;

    const pushEntity = v.push ? v.push.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' })) : v.push;

    const professionalEntity = v.professional
      ? v.professional.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' }))
      : v.professional;

    p = v.levelGroup;
    const levelGroupEntity = p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : p;

    const medicineMaterialEntity = v.medicineMaterials
      ? v.medicineMaterials.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['name'] ? p1.name : '' }))
      : v.medicineMaterials;

    const kitEntity = v.kits ? v.kits.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['name'] ? p1.name : '' })) : v.kits;

    return {
      nameSelectValue: v.name, // name,
      statusSelectValue: v.status, // status,
      contractSelectValue: v.contract, // contract,
      categoryStatusHistorySelectValue: categoryStatusHistoryEntity,
      specialtySelectValue: specialtyEntity,
      clComRulerSelectValue: clComRulerEntity,
      clComItemSelectValue: clComItemEntity,
      productServiceSelectValue: productServiceEntity,
      pushSelectValue: pushEntity,
      professionalSelectValue: professionalEntity,
      levelGroupSelectValue: levelGroupEntity,
      medicineMaterialSelectValue: medicineMaterialEntity,
      kitSelectValue: kitEntity,
    };
  }

  openUpdateModal(v, edit = true) {
    this.props.getEntity(v.id);
    this.setState({
      showModalEdit: v.id,
      showModalEditView: edit,
      ...this.getFullEntitySelectValue(v),
    });
  }

  renderUpdateModal() {
    const { categoryEntity, entityEmbebedPai } = this.props;
    return categoryEntity && categoryEntity.id ? (
      <Modal size={'xl'} isOpen={this.state.showModalEdit === categoryEntity.id} toggle={() => this.setState({ showModalEdit: null })}>
        <ModalHeader toggle={() => this.setState({ showModalEdit: null })}>{this.renderHeaderUpdate(categoryEntity, false)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefEdit = el)}
          model={{
            ...categoryEntity,
          }}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, false)}
        >
          <ModalBody>{this.renderBodyUpdate(categoryEntity, false)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalEdit: null })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.category.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefEdit && this.myFormRefEdit.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.category.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    ) : (
      <> </>
    );
  }

  renderHeaderUpdate(categoryEntity, isNew) {
    const {
      entityEmbebedPai,
      categoryStatusHistories,
      specialties,
      clComRulers,
      clComItems,
      productServices,
      pushes,
      professionals,
      levelGroups,
      medicineMaterials,
      kits,
      loading,
    } = this.props;

    const { contract } = categoryEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {isNew ? (
              <Translate contentKey="generadorApp.category.home.createLabel">Create a Category</Translate>
            ) : (
              <Translate contentKey="generadorApp.category.home.editLabel">Edit a Category</Translate>
            )}
          </span>
        </div>
      </>
    );
  }
  renderBodyUpdate(categoryEntity, isNew) {
    const {
      entityEmbebedPai,
      categoryStatusHistories,
      specialties,
      clComRulers,
      clComItems,
      productServices,
      pushes,
      professionals,
      levelGroups,
      medicineMaterials,
      kits,
      loading,
    } = this.props;

    const { contract } = categoryEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
              <Col md="3">
              <Label className="mt-2 label-single-line"for="category-id">
                <Translate contentKey="generadorApp.category.field_id">ID</Translate>
              </Label>
              </Col> */}
                      <Col md="12">
                        <AvInput id="category-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'levelGroup' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="category-levelGroup">
                              <Translate contentKey="generadorApp.category.levelGroup">Level Group</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="category-levelGroup"
                              name={'levelGroup'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.levelGroupSelectValue ? this.state.levelGroupSelectValue : ''}
                              onChange={options => this.setState({ levelGroupSelectValue: options })}
                              defaultOptions={this.state.levelGroupStartSelectOptions ? this.state.levelGroupStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.levelGroupStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.levelGroupStartSelectOptions === undefined) {
                                  const result = await getListAxios('level-groups', {}, 0, 100, 'name,asc', 'name');
                                  this.setState({
                                    levelGroupStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'level-groups',
                                  { 'name.contains': inputValue },
                                  0,
                                  100,
                                  'name,asc',
                                  'name'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                        {this.state.levelGroupSelectValue === null ? <text style={{ color: 'red' }}>Campo obrigatório</text> : null}
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="levelGroup"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}

                  {baseFilters !== 'name' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="nameLabel" for="category-name">
                              <Translate contentKey="generadorApp.category.name">Name</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                              value={this.state.nameSelectValue ? this.state.nameSelectValue : ''}
                              id="category-name"
                              type="text"
                              name="name"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                        {this.state.nameSelectValue === null ? <text style={{ color: "red" }}>Campo obrigatório</text> : null}
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                      type="hidden"
                      name="name"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {this.state.levelGroupSelectValue &&
                    this.state.levelGroupSelectValue.sub === 'ESPECIALIDADE' &&
                    baseFilters !== 'contract' ? (
                    <Col md="12">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="contractLabel" for="category-contract">
                              <Translate contentKey="generadorApp.category.contract">Contract</Translate>
                            </Label>
                          </Col>

                          <Col md="12">
                            <ReactQuill
                              value={this.state.contractSelectValue ? this.state.contractSelectValue : ''}
                              onChange={evt => this.setState({ contractSelectValue: evt })}
                              modules={quillEditorModules}
                              formats={quillEditorFormats}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ contractSelectValue: evt.target.value })}
                      type="hidden"
                      name="contract"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  renderHeader() {
    const {
      categoryStatusHistories,
      specialties,
      clComRulers,
      clComItems,
      productServices,
      pushes,
      professionals,
      levelGroups,
      medicineMaterials,
      kits,
      categoryList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.category.home.title">Categories</Translate>
          </span>
          <Button id="togglerFilterCategory" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.category.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          &nbsp;
          {hasAnyAuthority(props.userAccount, ['category'], 'resgister') ? (
            <Button
              onClick={() =>
                this.setState({
                  showModalForm: true,
                  nameSelectValue: null,
                  statusSelectValue: 'ATIVO',
                  contractSelectValue: null,

                  categoryStatusHistoryStartSelectOptions: undefined,
                  categoryStatusHistorySelectValue: null,

                  specialtyStartSelectOptions: undefined,
                  specialtySelectValue: null,

                  clComRulerStartSelectOptions: undefined,
                  clComRulerSelectValue: null,

                  clComItemStartSelectOptions: undefined,
                  clComItemSelectValue: null,

                  productServiceStartSelectOptions: undefined,
                  productServiceSelectValue: null,

                  pushStartSelectOptions: undefined,
                  pushSelectValue: null,

                  professionalStartSelectOptions: undefined,
                  professionalSelectValue: null,

                  levelGroupStartSelectOptions: undefined,
                  levelGroupSelectValue: null,

                  medicineMaterialStartSelectOptions: undefined,
                  medicineMaterialSelectValue: null,

                  kitStartSelectOptions: undefined,
                  kitSelectValue: null,
                  isNew: true,
                })
              }
              color="primary"
              className="btn btn-primary float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="plus" />
              &nbsp;
              <Translate contentKey="generadorApp.category.btnNewItem">Create new</Translate>
            </Button>
          ) : (
            <></>
          )}{' '}
          &nbsp;
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.category.home.title">Categories</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    const {
      categoryStatusHistories,
      specialties,
      clComRulers,
      clComItems,
      productServices,
      pushes,
      professionals,
      levelGroups,
      medicineMaterials,
      kits,
      categoryList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <UncontrolledCollapse toggler="#togglerFilterCategory">
          <CardBody>
            <AvForm ref={el => (this.myFormRef = el)} id="form-filter" onSubmit={this.filterEntity}>
              <div className="row mt-1 ml-3 mr-3">
                {this.state.baseFilters !== 'name' ? (
                  <Col md="3" className="col-filter-category-name">
                    <Row className="mr-1 mt-1">
                      <Label id="nameLabel" for="category-name">
                        <Translate contentKey="generadorApp.category.name">Name</Translate>
                      </Label>

                      <AvInput type="text" name="name" id="category-name" value={this.state.name} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'status' ? (
                  <Col md="3" className="col-filter-category-status">
                    <Row className="mr-1 mt-1">
                      <Label id="statusLabel" for="category-status">
                        <Translate contentKey="generadorApp.category.status">Status</Translate>
                      </Label>
                      <Select
                        id="category-status"
                        className={'css-select-control'}
                        value={
                          this.state.status
                            ? { value: this.state.status, label: translate('generadorApp.Status.' + this.state.status) }
                            : { value: '', label: translate('generadorApp.category.status') }
                        }
                        options={[
                          { value: '', label: translate('generadorApp.category.status') },
                          { value: 'INATIVADO', label: translate('generadorApp.Status.INATIVADO') },
                          { value: 'ATIVO', label: translate('generadorApp.Status.ATIVO') },
                        ]}
                        onChange={(options: any) => this.setState({ status: options['value'] })}
                        name="status"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'levelGroup' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="category-levelGroup">
                          <Translate contentKey="generadorApp.category.levelGroup">Level Group</Translate>
                        </Label>
                        <SelectAsync
                          id="category-levelGroup"
                          isMulti
                          className={'css-select-control'}
                          name={'levelGroup'}
                          cacheOptions
                          value={this.state.levelGroupId}
                          onChange={options => this.setState({ levelGroupId: options })}
                          defaultOptions={this.state.levelGroupStartFilter ? this.state.levelGroupStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.levelGroupStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.levelGroupStartFilter === undefined) {
                              const result = await getListAxios('level-groups', {}, 0, 100, 'name,asc', 'name');
                              this.setState({
                                levelGroupStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios('level-groups', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}
              </div>

              <div className="row mb-2 mr-4 justify-content-end">
                <Button color="primary" type="submit">
                  <i className="fa fa-filter" aria-hidden={'true'}></i>&nbsp;
                  <Translate contentKey="generadorApp.category.btnFilter">Filter</Translate>
                </Button>
                &nbsp;
                <Button color="secondary" onClick={this.cancelFilters}>
                  <FontAwesomeIcon icon="trash-alt" />
                  &nbsp;
                  <Translate contentKey="generadorApp.category.btnCleanFilter">Clean Filter</Translate>
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </UncontrolledCollapse>
      </>
    );
  }

  renderTable() {
    const {
      categoryStatusHistories,
      specialties,
      clComRulers,
      clComItems,
      productServices,
      pushes,
      professionals,
      levelGroups,
      medicineMaterials,
      kits,
      categoryList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {categoryList && categoryList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null).length > 0 ? (
          <>
            <Table
              id="category-table-list"
              responsive
              aria-describedby="category-heading"
              className={'table-hover table-striped table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.category.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'name' ? (
                    <th className="hand" onClick={sortFunction('name')}>
                      <Translate contentKey="generadorApp.category.name">Name</Translate>
                      <FontAwesomeIcon icon={state.sort === 'name' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'levelGroup' ? (
                    <th>
                      <Translate contentKey="generadorApp.category.levelGroup">Level Group</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'createdDate' ? (
                    <th className="hand" onClick={sortFunction('createdDate')}>
                      <Translate contentKey="generadorApp.category.createdDate">Created Date</Translate>
                      <FontAwesomeIcon icon={state.sort === 'createdDate' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'lastModifiedDate' ? (
                    <th className="hand" onClick={sortFunction('lastModifiedDate')}>
                      <Translate contentKey="generadorApp.category.lastModifiedDate">Last Modified Date</Translate>
                      <FontAwesomeIcon
                        icon={state.sort === 'lastModifiedDate' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'}
                      />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'status' ? (
                    <th className="hand" onClick={sortFunction('status')}>
                      <Translate contentKey="generadorApp.category.status">Status</Translate>
                      <FontAwesomeIcon icon={state.sort === 'status' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}

                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {categoryList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((category, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.category.field_id">ID</Translate>
                        </b>
                        {category.id}
                      </td>

                      {state.baseFilters !== 'name' ? (
                        <td id="name-cell">
                          <b className="visible-xs"> Name </b>

                          {category.name}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'levelGroup' ? (
                        <td id="levelGroup-cell">
                          <b className="visible-xs"> Level Group </b>
                          {category ? (
                            <span data-type-rel="2-many-to-one-other-side">
                              {getValueRecursive(category, 'levelGroup.name'.split('.'), '')}
                            </span>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'createdDate' ? (
                        <td id="createdDate-cell">
                          <b className="visible-xs"> Created Date </b>
                          {category.createdDate ? moment(category.createdDate).format(APP_DATE_FORMAT) : ''}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'lastModifiedDate' ? (
                        <td id="lastModifiedDate-cell">
                          <b className="visible-xs"> Last Modified Date </b>
                          {category.lastModifiedDate ? moment(category.lastModifiedDate).format(APP_DATE_FORMAT) : ''}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'status' ? (
                        <td id="status-cell">
                          <b className="visible-xs"> Status </b>
                          {category.status ? <Translate contentKey={`generadorApp.Status.${category.status}`} /> : <> </>}
                        </td>
                      ) : null}

                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          {hasAnyAuthority(props.userAccount, ['category'], 'edit') ? (
                            <Button onClick={() => this.openUpdateModal(category)} color="primary" size="sm">
                              <FontAwesomeIcon icon="pencil-alt" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                          {hasAnyAuthority(props.userAccount, ['category'], 'canDelete') ? (
                            <Button onClick={() => this.deleteEntity(category)} color="danger" size="sm">
                              <FontAwesomeIcon icon="trash" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                          {hasAnyAuthority(props.userAccount, ['category'], 'edit') ? (
                            <Button tag={Link} to={`/category/${category.id}/category-status-history`} color="info" size="sm">
                              <FontAwesomeIcon icon="check-square" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                          <Button tag={Link} to={`/category/${category.id}/audit-entity`} color="info" size="sm">
                            <FontAwesomeIcon icon="history" />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.category.home.notFound">No Categories found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const {
      categoryStatusHistories,
      specialties,
      clComRulers,
      clComItems,
      productServices,
      pushes,
      professionals,
      levelGroups,
      medicineMaterials,
      kits,
      categoryList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={categoryList && categoryList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const {
      categoryStatusHistories,
      specialties,
      clComRulers,
      clComItems,
      productServices,
      pushes,
      professionals,
      levelGroups,
      medicineMaterials,
      kits,
      categoryList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel className="category category-all-in-one">
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
            {this.renderNewModal()}
            {this.renderUpdateModal()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToProps = ({ category, ...storeState }: IRootState) => ({
  categoryStatusHistories: storeState.categoryStatusHistory.entities,
  specialties: storeState.specialty.entities,
  clComRulers: storeState.clComRuler.entities,
  clComItems: storeState.clComItem.entities,
  productServices: storeState.productService.entities,
  pushes: storeState.push.entities,
  professionals: storeState.professional.entities,
  levelGroups: storeState.levelGroup.entities,
  medicineMaterials: storeState.medicineMaterial.entities,
  kits: storeState.kit.entities,
  categoryList: category.entities,
  totalItems: category.totalItems,
  userAccount: storeState.authentication.account,
  loading: category.loading,

  // UPDATE
  categoryEntity: category.entity,
});

export const mapDispatchToProps = {
  getEntities,

  updateEntity,
  reset,

  // UPDATE
  getEntity,
  setBlob,
  createEntity,

  // DELETE
  deleteEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Category);
