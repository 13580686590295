import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ClientGlosa from './client-glosa';

import ClientGlosaDetail from './client-glosa-detail';

import ClientGlosaUpdate from './extended/client-glosa-update';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={ClientGlosaUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={ClientGlosaUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={ClientGlosaDetail} />
      <ErrorBoundaryRoute path={match.path} component={ClientGlosa} />
    </Switch>
  </>
);

export default Routes;
