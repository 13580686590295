/* eslint complexity: ["error", 500] */
import React from 'react';
import axios from 'axios';

import { getFilterFromSelect, getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import { connect } from 'react-redux';

import SelectAsync from 'react-select/async';
import { Link, RouteComponentProps } from 'react-router-dom';

import { Button, Col, Row, Table, Label, UncontrolledCollapse, CardBody } from 'reactstrap';
import { AvForm, div } from 'availity-reactstrap-validation';
import { JhiItemCount } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { IRootState } from 'app/shared/reducers';
import {
  updateEntity,
  deleteEntity,
  getUrlBack,
  getPermissionUserState,
  IPermissionUserBaseState,
  getEntityFiltersURL,
  getEntities,
  reset,
} from './permission-user.reducer';

import { BASE_API_VERSION_PATH } from 'app/config/constants';
import { ITEMS_PER_PAGE, IPaginationBaseState, getSortState } from 'app/shared/util/pagination.constants';

import { StatePropsExtended, DispatchPropsExtended, IExtendedState, IExtendedProps } from './extended/permission-user';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

export interface IPermissionUserProps
  extends StateProps,
    DispatchProps,
    StatePropsExtended,
    DispatchPropsExtended,
    IExtendedProps,
    RouteComponentProps<{ url: string }> {}
export interface IPermissionUserStateAux extends IPermissionUserBaseState, IExtendedState {}

export interface IPermissionUserState extends IPermissionUserStateAux, IPaginationBaseState {}

export class PermissionUser extends React.Component<IPermissionUserProps, IPermissionUserState> {
  protected myFormRef: any;

  constructor(props: IPermissionUserProps) {
    super(props);
    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      listCheckedID: [],
      isCheckedAll: false,
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      urlBack: getUrlBack(this.props.location),
      ...getPermissionUserState(this.props.location),
    };
  }

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({ listCheckedID: this.props.permissionUserList.map(permissionUser => permissionUser.id) });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({ listCheckedID: this.props.permissionUserList.map(permissionUser => permissionUser.id) });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  removeEntitySelected() {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  }

  saveEntityMany = () => {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  };

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  deleteEntity(permissionUserEntity) {
    MySwal.fire({
      title: <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>,
      html: (
        <Translate contentKey="generadorApp.permissionUser.delete.question" interpolate={{ id: permissionUserEntity.id }}>
          Are you sure you want to delete this PermissionUser?
        </Translate>
      ),
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#e70015',
      cancelButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="ban" />
          &nbsp; <Translate contentKey="generadorApp.permissionUser.btnCancel">Cancel</Translate>
        </>
      ),
      confirmButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="trash" />
          &nbsp;<Translate contentKey="generadorApp.permissionUser.btnDelete">Delete</Translate>
        </>
      ),
    }).then(async response => {
      if (response.isConfirmed) {
        await this.props.deleteEntity(permissionUserEntity.id, { reloadList: false });
        this.getEntities();
        MySwal.fire({
          title: <Translate contentKey="entity.delete.title.confirmed">Confirmed delete operation</Translate>,
          icon: 'success',
        });
      }
    });
  }

  cancelFilters = () => {
    this.setState(
      {
        permissionId: '',
        userId: '',
        extraFilters: {},
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
        activePage: 0,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();

    const selectFieldsList = [
      'permission.name',
      'user.fullname',
      'view',
      'resgister',
      'edit',
      'canDelete',
      'manualRemoval',
      'editLocation',
      'report',
      'activateProfessional',
      'deletePhoto',
      'financialValue',
      'valueAuthorization',
      'confirmPayment',
      'evolution',
      'professionalName',
      'professionalRegister',
    ];

    const { permissionId, userId, extraFilters, activePage, itemsPerPage, sort, order } = this.state;
    return this.props.getEntities(
      getFilterFromSelect(permissionId, 'many-to-one'),
      getFilterFromSelect(userId, 'many-to-one'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(',')
    );
  };

  updateEntity = entity => {
    const selectFieldsList = [
      'permission.name',
      'user.fullname',
      'view',
      'resgister',
      'edit',
      'canDelete',
      'manualRemoval',
      'editLocation',
      'report',
      'activateProfessional',
      'deletePhoto',
      'financialValue',
      'valueAuthorization',
      'confirmPayment',
      'evolution',
      'professionalName',
      'professionalRegister',
    ];

    const { permissionId, userId, extraFilters, activePage, itemsPerPage, sort, order } = this.state;
    this.props.updateEntity(entity, [
      getFilterFromSelect(permissionId, 'many-to-one'),
      getFilterFromSelect(userId, 'many-to-one'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(','),
    ]);
  };

  renderHeader() {
    const { users, permissions, permissionUserList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.permissionUser.home.title">Permission Users</Translate>
          </span>
          <Button id="togglerFilterPermissionUser" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.permissionUser.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          &nbsp;
          <Link
            to={`${match.url}/new?${getEntityFiltersURL(state)}`}
            className="btn btn-primary float-right jh-create-entity"
            id="jh-create-entity"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="generadorApp.permissionUser.btnNewItem">Create new</Translate>
          </Link>{' '}
          &nbsp;
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.permissionUser.home.title">Permission Users</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    const { users, permissions, permissionUserList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <UncontrolledCollapse toggler="#togglerFilterPermissionUser">
          <CardBody>
            <AvForm ref={el => (this.myFormRef = el)} id="form-filter" onSubmit={this.filterEntity}>
              <div className="row mt-1 ml-3 mr-3">
                {this.state.baseFilters !== 'permission' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="permission-user-permission">
                          <Translate contentKey="generadorApp.permissionUser.permission">Permission</Translate>
                        </Label>
                        <SelectAsync
                          id="permission-user-permission"
                          isMulti
                          className={'css-select-control'}
                          name={'permission'}
                          cacheOptions
                          value={this.state.permissionId}
                          onChange={options => this.setState({ permissionId: options })}
                          defaultOptions={this.state.permissionStartFilter ? this.state.permissionStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.permissionStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.permissionStartFilter === undefined) {
                              const result = await getListAxios('permissions', {}, 0, 100, 'name,asc', 'name');
                              this.setState({
                                permissionStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios('permissions', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'user' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="permission-user-user">
                          <Translate contentKey="generadorApp.permissionUser.user">User</Translate>
                        </Label>
                        <SelectAsync
                          id="permission-user-user"
                          isMulti
                          className={'css-select-control'}
                          name={'user'}
                          cacheOptions
                          value={this.state.userId}
                          onChange={options => this.setState({ userId: options })}
                          defaultOptions={this.state.userStartFilter ? this.state.userStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.userStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.userStartFilter === undefined) {
                              const result = await getListAxios('users', {}, 0, 100, 'fullname,asc', 'fullname');
                              this.setState({
                                userStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'fullname') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios(
                              'users',
                              { 'fullname.contains': inputValue },
                              0,
                              100,
                              'fullname,asc',
                              'fullname'
                            );
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'fullname') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}
              </div>

              <div className="row mb-2 mr-4 justify-content-end">
                <Button color="primary" type="submit">
                  <i className="fa fa-filter" aria-hidden={'true'}></i>&nbsp;
                  <Translate contentKey="generadorApp.permissionUser.btnFilter">Filter</Translate>
                </Button>
                &nbsp;
                <Button color="secondary" onClick={this.cancelFilters}>
                  <FontAwesomeIcon icon="trash-alt" />
                  &nbsp;
                  <Translate contentKey="generadorApp.permissionUser.btnCleanFilter">Clean Filter</Translate>
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </UncontrolledCollapse>
      </>
    );
  }

  renderTable() {
    const { users, permissions, permissionUserList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {permissionUserList && permissionUserList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null).length > 0 ? (
          <>
            <Table
              id="permission-user-table-list"
              responsive
              aria-describedby="permission-user-heading"
              className={'table-hover table-striped mt-4 table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.permissionUser.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'permission' ? (
                    <th>
                      <Translate contentKey="generadorApp.permissionUser.permission">Permission</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'user' ? (
                    <th>
                      <Translate contentKey="generadorApp.permissionUser.user">User</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'view' ? (
                    <th className="hand" onClick={sortFunction('view')}>
                      <Translate contentKey="generadorApp.permissionUser.view">View</Translate>
                      <FontAwesomeIcon icon={state.sort === 'view' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'resgister' ? (
                    <th className="hand" onClick={sortFunction('resgister')}>
                      <Translate contentKey="generadorApp.permissionUser.resgister">Register</Translate>
                      <FontAwesomeIcon icon={state.sort === 'resgister' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'edit' ? (
                    <th className="hand" onClick={sortFunction('edit')}>
                      <Translate contentKey="generadorApp.permissionUser.edit">Edit</Translate>
                      <FontAwesomeIcon icon={state.sort === 'edit' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'canDelete' ? (
                    <th className="hand" onClick={sortFunction('canDelete')}>
                      <Translate contentKey="generadorApp.permissionUser.canDelete">Delete</Translate>
                      <FontAwesomeIcon icon={state.sort === 'canDelete' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'manualRemoval' ? (
                    <th className="hand" onClick={sortFunction('manualRemoval')}>
                      <Translate contentKey="generadorApp.permissionUser.manualRemoval">Manual removal</Translate>
                      <FontAwesomeIcon icon={state.sort === 'manualRemoval' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'editLocation' ? (
                    <th className="hand" onClick={sortFunction('editLocation')}>
                      <Translate contentKey="generadorApp.permissionUser.editLocation">editLocation</Translate>
                      <FontAwesomeIcon icon={state.sort === 'editLocation' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'report' ? (
                    <th className="hand" onClick={sortFunction('report')}>
                      <Translate contentKey="generadorApp.permissionUser.report">Report</Translate>
                      <FontAwesomeIcon icon={state.sort === 'report' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'activateProfessional' ? (
                    <th className="hand" onClick={sortFunction('activateProfessional')}>
                      <Translate contentKey="generadorApp.permissionUser.activateProfessional">Activate professional</Translate>
                      <FontAwesomeIcon
                        icon={state.sort === 'activateProfessional' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'}
                      />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'deletePhoto' ? (
                    <th className="hand" onClick={sortFunction('deletePhoto')}>
                      <Translate contentKey="generadorApp.permissionUser.deletePhoto">Delete photo</Translate>
                      <FontAwesomeIcon icon={state.sort === 'deletePhoto' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'financialValue' ? (
                    <th className="hand" onClick={sortFunction('financialValue')}>
                      <Translate contentKey="generadorApp.permissionUser.financialValue">Values</Translate>
                      <FontAwesomeIcon
                        icon={state.sort === 'financialValue' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'}
                      />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'valueAuthorization' ? (
                    <th className="hand" onClick={sortFunction('valueAuthorization')}>
                      <Translate contentKey="generadorApp.permissionUser.valueAuthorization">Value authorization</Translate>
                      <FontAwesomeIcon
                        icon={state.sort === 'valueAuthorization' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'}
                      />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'confirmPayment' ? (
                    <th className="hand" onClick={sortFunction('confirmPayment')}>
                      <Translate contentKey="generadorApp.permissionUser.confirmPayment">confirmPayment</Translate>
                      <FontAwesomeIcon
                        icon={state.sort === 'confirmPayment' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'}
                      />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'evolution' ? (
                    <th className="hand" onClick={sortFunction('evolution')}>
                      <Translate contentKey="generadorApp.permissionUser.evolution">Evolution</Translate>
                      <FontAwesomeIcon icon={state.sort === 'evolution' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'professionalName' ? (
                    <th className="hand" onClick={sortFunction('professionalName')}>
                      <Translate contentKey="generadorApp.permissionUser.professionalName">Professional name</Translate>
                      <FontAwesomeIcon
                        icon={state.sort === 'professionalName' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'}
                      />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'professionalRegister' ? (
                    <th className="hand" onClick={sortFunction('professionalRegister')}>
                      <Translate contentKey="generadorApp.permissionUser.professionalRegister">Professional register</Translate>
                      <FontAwesomeIcon
                        icon={state.sort === 'professionalRegister' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'}
                      />
                    </th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {permissionUserList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((permissionUser, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.permissionUser.field_id">ID</Translate>
                        </b>
                        {permissionUser.id}
                      </td>

                      {state.baseFilters !== 'permission' ? (
                        <td id="permission-cell">
                          <b className="visible-xs"> Permission </b>
                          {showFieldsSelectAsync(permissionUser, 'permission.name', null).map((v, ikey) => (
                            <span key={ikey}> {v} </span>
                          ))}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'user' ? (
                        <td id="user-cell">
                          <b className="visible-xs"> User </b>
                          {permissionUser.user ? permissionUser.user.fullname : ''}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'view' ? (
                        <td id="view-cell">
                          <b className="visible-xs"> View </b>
                          <div className="switcher switcher-success">
                            <input
                              type="checkbox"
                              name={'view_' + permissionUser.id}
                              id={'view_' + permissionUser.id}
                              defaultChecked={permissionUser.view}
                              onClick={(evt: any) =>
                                axios
                                  .put(BASE_API_VERSION_PATH + 'permission-users', { ...permissionUser, view: evt.target.checked })
                                  .then(v => {
                                    this.getEntities();
                                    MySwal.fire({ title: 'Atualizado com sucesso', icon: 'success' });
                                  })
                              }
                            />
                            <label htmlFor={'view_' + permissionUser.id} />
                          </div>
                        </td>
                      ) : null}

                      {state.baseFilters !== 'resgister' ? (
                        <td id="resgister-cell">
                          <b className="visible-xs"> Register </b>
                          <div className="switcher switcher-success">
                            <input
                              type="checkbox"
                              name={'resgister_' + permissionUser.id}
                              id={'resgister_' + permissionUser.id}
                              defaultChecked={permissionUser.resgister}
                              onClick={(evt: any) =>
                                axios
                                  .put(BASE_API_VERSION_PATH + 'permission-users', { ...permissionUser, resgister: evt.target.checked })
                                  .then(v => {
                                    this.getEntities();
                                    MySwal.fire({ title: 'Atualizado com sucesso', icon: 'success' });
                                  })
                              }
                            />
                            <label htmlFor={'resgister_' + permissionUser.id} />
                          </div>
                        </td>
                      ) : null}

                      {state.baseFilters !== 'edit' ? (
                        <td id="edit-cell">
                          <b className="visible-xs"> Edit </b>
                          <div className="switcher switcher-success">
                            <input
                              type="checkbox"
                              name={'edit_' + permissionUser.id}
                              id={'edit_' + permissionUser.id}
                              defaultChecked={permissionUser.edit}
                              onClick={(evt: any) =>
                                axios
                                  .put(BASE_API_VERSION_PATH + 'permission-users', { ...permissionUser, edit: evt.target.checked })
                                  .then(v => {
                                    this.getEntities();
                                    MySwal.fire({ title: 'Atualizado com sucesso', icon: 'success' });
                                  })
                              }
                            />
                            <label htmlFor={'edit_' + permissionUser.id} />
                          </div>
                        </td>
                      ) : null}

                      {state.baseFilters !== 'canDelete' ? (
                        <td id="canDelete-cell">
                          <b className="visible-xs"> Delete </b>
                          <div className="switcher switcher-success">
                            <input
                              type="checkbox"
                              name={'canDelete_' + permissionUser.id}
                              id={'canDelete_' + permissionUser.id}
                              defaultChecked={permissionUser.canDelete}
                              onClick={(evt: any) =>
                                axios
                                  .put(BASE_API_VERSION_PATH + 'permission-users', { ...permissionUser, canDelete: evt.target.checked })
                                  .then(v => {
                                    this.getEntities();
                                    MySwal.fire({ title: 'Atualizado com sucesso', icon: 'success' });
                                  })
                              }
                            />
                            <label htmlFor={'canDelete_' + permissionUser.id} />
                          </div>
                        </td>
                      ) : null}

                      {state.baseFilters !== 'manualRemoval' ? (
                        <td id="manualRemoval-cell">
                          <b className="visible-xs"> Manual removal </b>
                          <div className="switcher switcher-success">
                            <input
                              type="checkbox"
                              name={'manualRemoval_' + permissionUser.id}
                              id={'manualRemoval_' + permissionUser.id}
                              defaultChecked={permissionUser.manualRemoval}
                              onClick={(evt: any) =>
                                axios
                                  .put(BASE_API_VERSION_PATH + 'permission-users', { ...permissionUser, manualRemoval: evt.target.checked })
                                  .then(v => {
                                    this.getEntities();
                                    MySwal.fire({ title: 'Atualizado com sucesso', icon: 'success' });
                                  })
                              }
                            />
                            <label htmlFor={'manualRemoval_' + permissionUser.id} />
                          </div>
                        </td>
                      ) : null}

                      {state.baseFilters !== 'editLocation' ? (
                        <td id="editLocation-cell">
                          <b className="visible-xs"> editLocation </b>
                          <div className="switcher switcher-success">
                            <input
                              type="checkbox"
                              name={'editLocation_' + permissionUser.id}
                              id={'editLocation_' + permissionUser.id}
                              defaultChecked={permissionUser.editLocation}
                              onClick={(evt: any) =>
                                axios
                                  .put(BASE_API_VERSION_PATH + 'permission-users', { ...permissionUser, editLocation: evt.target.checked })
                                  .then(v => {
                                    this.getEntities();
                                    MySwal.fire({ title: 'Atualizado com sucesso', icon: 'success' });
                                  })
                              }
                            />
                            <label htmlFor={'editLocation_' + permissionUser.id} />
                          </div>
                        </td>
                      ) : null}

                      {state.baseFilters !== 'report' ? (
                        <td id="report-cell">
                          <b className="visible-xs"> Report </b>
                          <div className="switcher switcher-success">
                            <input
                              type="checkbox"
                              name={'report_' + permissionUser.id}
                              id={'report_' + permissionUser.id}
                              defaultChecked={permissionUser.report}
                              onClick={(evt: any) =>
                                axios
                                  .put(BASE_API_VERSION_PATH + 'permission-users', { ...permissionUser, report: evt.target.checked })
                                  .then(v => {
                                    this.getEntities();
                                    MySwal.fire({ title: 'Atualizado com sucesso', icon: 'success' });
                                  })
                              }
                            />
                            <label htmlFor={'report_' + permissionUser.id} />
                          </div>
                        </td>
                      ) : null}

                      {state.baseFilters !== 'activateProfessional' ? (
                        <td id="activateProfessional-cell">
                          <b className="visible-xs"> Activate professional </b>
                          <div className="switcher switcher-success">
                            <input
                              type="checkbox"
                              name={'activateProfessional_' + permissionUser.id}
                              id={'activateProfessional_' + permissionUser.id}
                              defaultChecked={permissionUser.activateProfessional}
                              onClick={(evt: any) =>
                                axios
                                  .put(BASE_API_VERSION_PATH + 'permission-users', {
                                    ...permissionUser,
                                    activateProfessional: evt.target.checked,
                                  })
                                  .then(v => {
                                    this.getEntities();
                                    MySwal.fire({ title: 'Atualizado com sucesso', icon: 'success' });
                                  })
                              }
                            />
                            <label htmlFor={'activateProfessional_' + permissionUser.id} />
                          </div>
                        </td>
                      ) : null}

                      {state.baseFilters !== 'deletePhoto' ? (
                        <td id="deletePhoto-cell">
                          <b className="visible-xs"> Delete photo </b>
                          <div className="switcher switcher-success">
                            <input
                              type="checkbox"
                              name={'deletePhoto_' + permissionUser.id}
                              id={'deletePhoto_' + permissionUser.id}
                              defaultChecked={permissionUser.deletePhoto}
                              onClick={(evt: any) =>
                                axios
                                  .put(BASE_API_VERSION_PATH + 'permission-users', { ...permissionUser, deletePhoto: evt.target.checked })
                                  .then(v => {
                                    this.getEntities();
                                    MySwal.fire({ title: 'Atualizado com sucesso', icon: 'success' });
                                  })
                              }
                            />
                            <label htmlFor={'deletePhoto_' + permissionUser.id} />
                          </div>
                        </td>
                      ) : null}

                      {state.baseFilters !== 'financialValue' ? (
                        <td id="financialValue-cell">
                          <b className="visible-xs"> Values </b>
                          <div className="switcher switcher-success">
                            <input
                              type="checkbox"
                              name={'financialValue_' + permissionUser.id}
                              id={'financialValue_' + permissionUser.id}
                              defaultChecked={permissionUser.financialValue}
                              onClick={(evt: any) =>
                                axios
                                  .put(BASE_API_VERSION_PATH + 'permission-users', {
                                    ...permissionUser,
                                    financialValue: evt.target.checked,
                                  })
                                  .then(v => {
                                    this.getEntities();
                                    MySwal.fire({ title: 'Atualizado com sucesso', icon: 'success' });
                                  })
                              }
                            />
                            <label htmlFor={'financialValue_' + permissionUser.id} />
                          </div>
                        </td>
                      ) : null}

                      {state.baseFilters !== 'valueAuthorization' ? (
                        <td id="valueAuthorization-cell">
                          <b className="visible-xs"> Value authorization </b>
                          <div className="switcher switcher-success">
                            <input
                              type="checkbox"
                              name={'valueAuthorization_' + permissionUser.id}
                              id={'valueAuthorization_' + permissionUser.id}
                              defaultChecked={permissionUser.valueAuthorization}
                              onClick={(evt: any) =>
                                axios
                                  .put(BASE_API_VERSION_PATH + 'permission-users', {
                                    ...permissionUser,
                                    valueAuthorization: evt.target.checked,
                                  })
                                  .then(v => {
                                    this.getEntities();
                                    MySwal.fire({ title: 'Atualizado com sucesso', icon: 'success' });
                                  })
                              }
                            />
                            <label htmlFor={'valueAuthorization_' + permissionUser.id} />
                          </div>
                        </td>
                      ) : null}

                      {state.baseFilters !== 'confirmPayment' ? (
                        <td id="confirmPayment-cell">
                          <b className="visible-xs"> confirmPayment </b>
                          <div className="switcher switcher-success">
                            <input
                              type="checkbox"
                              name={'confirmPayment_' + permissionUser.id}
                              id={'confirmPayment_' + permissionUser.id}
                              defaultChecked={permissionUser.confirmPayment}
                              onClick={(evt: any) =>
                                axios
                                  .put(BASE_API_VERSION_PATH + 'permission-users', {
                                    ...permissionUser,
                                    confirmPayment: evt.target.checked,
                                  })
                                  .then(v => {
                                    this.getEntities();
                                    MySwal.fire({ title: 'Atualizado com sucesso', icon: 'success' });
                                  })
                              }
                            />
                            <label htmlFor={'confirmPayment_' + permissionUser.id} />
                          </div>
                        </td>
                      ) : null}

                      {state.baseFilters !== 'evolution' ? (
                        <td id="evolution-cell">
                          <b className="visible-xs"> Evolution </b>
                          <div className="switcher switcher-success">
                            <input
                              type="checkbox"
                              name={'evolution_' + permissionUser.id}
                              id={'evolution_' + permissionUser.id}
                              defaultChecked={permissionUser.evolution}
                              onClick={(evt: any) =>
                                axios
                                  .put(BASE_API_VERSION_PATH + 'permission-users', { ...permissionUser, evolution: evt.target.checked })
                                  .then(v => {
                                    this.getEntities();
                                    MySwal.fire({ title: 'Atualizado com sucesso', icon: 'success' });
                                  })
                              }
                            />
                            <label htmlFor={'evolution_' + permissionUser.id} />
                          </div>
                        </td>
                      ) : null}

                      {state.baseFilters !== 'professionalName' ? (
                        <td id="professionalName-cell">
                          <b className="visible-xs"> Professional name </b>
                          <div className="switcher switcher-success">
                            <input
                              type="checkbox"
                              name={'professionalName_' + permissionUser.id}
                              id={'professionalName_' + permissionUser.id}
                              defaultChecked={permissionUser.professionalName}
                              onClick={(evt: any) =>
                                axios
                                  .put(BASE_API_VERSION_PATH + 'permission-users', {
                                    ...permissionUser,
                                    professionalName: evt.target.checked,
                                  })
                                  .then(v => {
                                    this.getEntities();
                                    MySwal.fire({ title: 'Atualizado com sucesso', icon: 'success' });
                                  })
                              }
                            />
                            <label htmlFor={'professionalName_' + permissionUser.id} />
                          </div>
                        </td>
                      ) : null}

                      {state.baseFilters !== 'professionalRegister' ? (
                        <td id="professionalRegister-cell">
                          <b className="visible-xs"> Professional register </b>
                          <div className="switcher switcher-success">
                            <input
                              type="checkbox"
                              name={'professionalRegister_' + permissionUser.id}
                              id={'professionalRegister_' + permissionUser.id}
                              defaultChecked={permissionUser.professionalRegister}
                              onClick={(evt: any) =>
                                axios
                                  .put(BASE_API_VERSION_PATH + 'permission-users', {
                                    ...permissionUser,
                                    professionalRegister: evt.target.checked,
                                  })
                                  .then(v => {
                                    this.getEntities();
                                    MySwal.fire({ title: 'Atualizado com sucesso', icon: 'success' });
                                  })
                              }
                            />
                            <label htmlFor={'professionalRegister_' + permissionUser.id} />
                          </div>
                        </td>
                      ) : null}
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.permissionUser.home.notFound">No Permission Users found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const { users, permissions, permissionUserList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={permissionUserList && permissionUserList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const { users, permissions, permissionUserList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel className="permission-user permission-user-list-table">
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToProps = ({ ...storeState }: IRootState) => {
  return {
    users: storeState.userManagement.users,
    permissions: storeState.permission.entities,
    permissionUserList: storeState.permissionUser.entities,
    totalItems: storeState.permissionUser.totalItems,
    userAccount: storeState.authentication.account,
    loading: storeState.permissionUser.loading,
  };
};

export const mapDispatchToProps = {
  getEntities,

  deleteEntity,
  updateEntity,
  reset,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PermissionUser);
