const functionAfterFormMount = (baseProps, baseState, createEntity) => {
  if (createEntity) {
    const categories = ['AMBULANCIA', 'EQUIPAMENTOS_RESPIRATORIOS', 'EQUIPAMENTOS_MOBILIARIOS', 'EQUIPAMENTOS_OXIGENIOTERAPIA', 'EXAMES'];
    categories.map(category => {
      baseProps.patientSupplierCreateEntity(
        {
          patient: 'is_new',
          category,
          suppliers: [],
        },
        []
      );
    });
  }
  return;
};

export default functionAfterFormMount;
