/* eslint complexity: ["error", 500] */
/* eslint no-empty: off */
import React from 'react';
import { connect } from 'react-redux';

import SelectAsync from 'react-select/async';

import { Link, RouteComponentProps } from 'react-router-dom';

import { getFilterFromSelect, getFormFromSelect, getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Col, Label } from 'reactstrap';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import {
  INotificationUserUpdateState as IBaseUpdateState,
  getEntity,
  getNotificationUserState,
  getUrlBack,
  updateEntity,
  createEntity,
  getEntityFiltersURL,
  getEntityOffset,
  reset,
} from './notification-user.reducer';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

registerLocale('pt', pt);

export interface INotificationUserUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}
export type INotificationUserUpdateState = IBaseUpdateState;

export class NotificationUserUpdate extends React.Component<INotificationUserUpdateProps, INotificationUserUpdateState> {
  visualizedFileInput: any;
  constructor(props: Readonly<INotificationUserUpdateProps>) {
    super(props);

    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      functionEmbebed: [],

      userSelectValue: null,

      notificationSelectValue: null,

      visualizedSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getNotificationUserState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
      userId: null,
      notificationId: null,

      isNew: !this.props.match.params || !this.props.match.params['id'],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateSuccess !== this.props.updateSuccess && prevProps.updateSuccess) {
      this.handleClose();
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.notificationUserEntity && prevProps.notificationUserEntity.id !== this.props.notificationUserEntity.id) ||
      (this.props.users &&
        this.state.userSelectValue === null &&
        this.props.notificationUserEntity.user &&
        this.props.notificationUserEntity.user.id)
    ) {
      const p = this.props.notificationUserEntity && this.props.notificationUserEntity.user ? this.props.notificationUserEntity.user : null;
      this.setState({
        userSelectValue: p ? { ...p, value: p.id, label: p['id'] ? p.id : '' } : null,
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.notificationUserEntity && prevProps.notificationUserEntity.id !== this.props.notificationUserEntity.id) ||
      (this.props.notifications &&
        this.state.notificationSelectValue === null &&
        this.props.notificationUserEntity.notification &&
        this.props.notificationUserEntity.notification.id)
    ) {
      const p =
        this.props.notificationUserEntity && this.props.notificationUserEntity.notification
          ? this.props.notificationUserEntity.notification
          : null;
      this.setState({
        notificationSelectValue: p ? { ...p, value: p.id, label: p['id'] ? p.id : '' } : null,
      });
    }

    if (
      (prevProps.notificationUserEntity && prevProps.notificationUserEntity.id !== this.props.notificationUserEntity.id) ||
      (this.state.visualizedSelectValue === null && this.props.notificationUserEntity.visualized !== this.state.visualizedSelectValue)
    ) {
      this.setState({
        visualizedSelectValue: this.props.notificationUserEntity.visualized ? this.props.notificationUserEntity.visualized : false,
      });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  UNSAFE_componentWillMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params['id']);
    }
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = offset !== null ? '&offset=' + offset : '';
    Object.keys(fieldsBase).map(key => {
      url += key !== 'offset' ? '&' + key + '=' + fieldsBase[key] : '';
    });
    return url;
  };
  saveEntity = (event: any, errors: any, values: any) => {
    const selectFieldsList = ['visualized', 'user.id', 'notification.id'];

    if (errors.length === 0) {
      const { notificationUserEntity } = this.props;
      const _entity = {
        ...notificationUserEntity,
        // ...values,

        user: getFormFromSelect(this.state.userSelectValue, 'many-to-one'), // many-to-one - otherSide

        notification: getFormFromSelect(this.state.notificationSelectValue, 'many-to-one'), // many-to-one - otherSide

        visualized: this.state.visualizedSelectValue === null ? false : this.state.visualizedSelectValue,
      };

      const entity = _entity;

      const { visualized, userId, notificationId, extraFilters, activePage, itemsPerPage, sort, order } = this.state.fieldsBase;

      if (this.state.isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              visualized,
              getFilterFromSelect(userId, 'many-to-one'),
              getFilterFromSelect(notificationId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              visualized,
              getFilterFromSelect(userId, 'many-to-one'),
              getFilterFromSelect(notificationId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/notification-user?' + getEntityFiltersURL(this.state.fieldsBase));
  };

  getFullEntityFromState(v) {
    return {
      id: v.id,
      visualized: this.state.visualizedSelectValue,
      user: this.state.userSelectValue,
      notification: this.state.notificationSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    const userEntity = v.user;

    const notificationEntity = v.notification;

    return {
      visualizedSelectValue: v.visualized,
      userSelectValue: userEntity ? userEntity['id'] : null,
      notificationSelectValue: notificationEntity ? notificationEntity['id'] : null,
    };
  }

  renderHeader() {
    const { notificationUserEntity, users, notifications, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.notificationUser.home.createLabel">Create a NotificationUser</Translate>
            ) : (
              <Translate contentKey="generadorApp.notificationUser.home.editLabel">Edit a NotificationUser</Translate>
            )}
          </span>

          <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.notificationUser.btnSave">Save</Translate>
          </Button>

          {!this.state.isNew ? (
            <>
              <Button
                color="primary"
                id="next-entity"
                disabled={updating}
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.visualized,
                    this.state.fieldsBase.userId,
                    this.state.fieldsBase.notificationId,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 + 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 + 1 },
                    });
                    this.props.history.push(
                      '/notification-user/' +
                        myresult.value.data[0]['id'] +
                        '/edit?' +
                        this.getFiltersURL(this.state.fieldsBase.offset * 1 + 1)
                    );
                  } else {
                    toast.error(
                      translate('generadorApp.last.item', { fieldName: translate('generadorApp.notificationUser.detail.title') })
                    );
                  }
                }}
                className="float-right jh-create-entity"
              >
                {'>>'}
              </Button>

              <Button
                color="primary"
                id="prev-entity"
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.visualized,
                    this.state.fieldsBase.userId,
                    this.state.fieldsBase.notificationId,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 - 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 - 1 },
                    });
                    this.props.history.push(
                      '/notification-user/' +
                        myresult.value.data[0]['id'] +
                        '/edit?' +
                        this.getFiltersURL(this.state.fieldsBase.offset * 1 - 1)
                    );
                  } else {
                    toast.error(
                      translate('generadorApp.first.item', { fieldName: translate('generadorApp.notificationUser.detail.title') })
                    );
                  }
                }}
                disabled={updating}
                className="float-right jh-create-entity"
              >
                {'<<'}
              </Button>
            </>
          ) : (
            <> </>
          )}

          <Button
            tag={Link}
            id="cancel-save"
            to={'/notification-user?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.notificationUser.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={'/notification-user'}>
              <Translate contentKey="generadorApp.notificationUser.home.title">Notification Users</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.notificationUser.detail.title">Notification User edit</Translate>
          </li>
        </ol>
      </>
    );
  }
  renderBody() {
    const { notificationUserEntity, users, notifications, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const fullEntityEmbebed = this.getFullEntityFromState(notificationUserEntity);

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="notification-user-id">
                  <Translate contentKey="generadorApp.notificationUser.field_id">ID</Translate>
                </Label>
                </Col> */}
                      <Col md="12">
                        <AvInput id="notification-user-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'visualized' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="visualizedLabel" for="notification-user-visualized">
                              <Translate contentKey="generadorApp.notificationUser.visualized">Visualized</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'visualized_checkbox'}
                                id={'visualized_checkbox'}
                                checked={this.state.visualizedSelectValue}
                                onChange={evt => this.setState({ visualizedSelectValue: !this.state.visualizedSelectValue })}
                              />
                              <label htmlFor={'visualized_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ visualizedSelectValue: evt.target.value })}
                      type="hidden"
                      name="visualized"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'user' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="notification-user-user">
                              <Translate contentKey="generadorApp.notificationUser.user">User</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="notification-user-user"
                              name={'user'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.userSelectValue ? this.state.userSelectValue : ''}
                              onChange={options => this.setState({ userSelectValue: options })}
                              defaultOptions={this.state.userStartSelectOptions ? this.state.userStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.userStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.userStartSelectOptions === undefined) {
                                  const result = await getListAxios('users', {}, 0, 100, 'id,asc', 'id');
                                  this.setState({
                                    userStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios('users', { 'id.contains': inputValue }, 0, 100, 'id,asc', 'id');
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="user"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}
                  {baseFilters !== 'notification' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="notification-user-notification">
                              <Translate contentKey="generadorApp.notificationUser.notification">Notification</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="notification-user-notification"
                              name={'notification'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.notificationSelectValue ? this.state.notificationSelectValue : ''}
                              onChange={options => this.setState({ notificationSelectValue: options })}
                              defaultOptions={this.state.notificationStartSelectOptions ? this.state.notificationStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.notificationStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.notificationStartSelectOptions === undefined) {
                                  const result = await getListAxios('notifications', {}, 0, 100, 'id,asc', 'id');
                                  this.setState({
                                    notificationStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios('notifications', { 'id.contains': inputValue }, 0, 100, 'id,asc', 'id');
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="notification"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}{' '}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { notificationUserEntity, users, notifications, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...notificationUserEntity,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel className="notification-user notification-user-update">
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToProps = (storeState: IRootState) => {
  return {
    users: storeState.userManagement.users,
    notifications: storeState.notification.entities,
    notificationUserEntity: storeState.notificationUser.entity,
    loading: storeState.notificationUser.loading,
    updating: storeState.notificationUser.updating,
    updateSuccess: storeState.notificationUser.updateSuccess,
  };
};

export const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  getEntityOffset,
  reset,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(NotificationUserUpdate);
