/* eslint complexity: ["error", 500] */
import React from 'react';
import axios from 'axios';
import { convertDateTimeToServer } from 'app/shared/util/date-utils';
import CurrencyInput from 'react-currency-input';
import { connect } from 'react-redux';

import {
  getFilterFromSelect,
  getFormFromSelect,
  getListAxios,
  getValueRecursive,
  showFieldsSelectAsync,
} from 'app/shared/util/entity-utils';
import Select from 'react-select';
import { toast } from 'react-toastify';
import SelectAsync from 'react-select/async';
import { Link, RouteComponentProps } from 'react-router-dom';

import { Button, Col, Row, Table, Label, UncontrolledCollapse, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { JhiItemCount } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { IRootState } from 'app/shared/reducers';
import {
  updateEntity,
  getUrlBack,
  getQuoteProductServiceState,
  IQuoteProductServiceBaseState,
  getEntityFiltersURL,
  getEntities,
  IQuoteProductServiceUpdateState as IBaseUpdateState,
  reset,
  getEntity,
  deleteEntity,
  createEntity,
} from './quote-product-service.reducer';

import { ITEMS_PER_PAGE, IPaginationBaseState, getSortState } from 'app/shared/util/pagination.constants';

import { StatePropsExtended, DispatchPropsExtended, IExtendedState, IExtendedProps } from './extended/quote-product-service';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';
import moment from 'moment';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { RenderModalSuperSelect } from 'app/shared/layout/components/RenderModalSuperSelect';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

export interface IQuoteProductServiceProps
  extends StateProps,
    DispatchProps,
    StatePropsExtended,
    DispatchPropsExtended,
    IExtendedProps,
    RouteComponentProps<{ url: string }> {
  entityEmbebedPai?: any;
}

export interface IQuoteProductServiceState extends IQuoteProductServiceBaseState, IPaginationBaseState, IBaseUpdateState, IExtendedState {
  showModalForm?: boolean;
  showModalEdit?: number;
  showModalEditView?: boolean;
}

export class QuoteProductService extends React.Component<IQuoteProductServiceProps, IQuoteProductServiceState> {
  protected myFormRef: any;
  protected myFormRefNew: any;
  protected myFormRefEdit: any;

  startDateFileInput: any;

  finishDateFileInput: any;

  daysFileInput: any;

  quantityFileInput: any;

  currentQuantityFileInput: any;

  unitaryAmountFileInput: any;

  grossAmountFileInput: any;

  typeDiscountFileInput: any;

  discountPercentFileInput: any;

  discountFileInput: any;

  finalAmountFileInput: any;

  frequencyFileInput: any;

  additiveFileInput: any;

  billQuantityItemFileInput: any;

  billGrossAmountFileInput: any;

  billFinalAmountFileInput: any;

  suprimentoStatusFileInput: any;

  constructor(props: IQuoteProductServiceProps) {
    super(props);
    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      listCheckedID: [],
      isCheckedAll: false,
      showModalForm: false,
      showModalEdit: null,
      urlBack: getUrlBack(this.props.location),
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      ...getQuoteProductServiceState(this.props.location),
      quoteId: [
        {
          ...(this.props.entityEmbebedPai ? this.props.entityEmbebedPai : {}),
          value: this.props.match.params['idQuote'],
          label: this.props.match.params['idQuote'],
        },
      ],
      baseFilters: 'quote',
      clientGlosaItemSelectValue: null,

      supplierGlosaItemSelectValue: null,

      quoteSelectValue: {
        ...(this.props.entityEmbebedPai ? this.props.entityEmbebedPai : {}),
        value: this.props.match.params['idQuote'],
        label: this.props.match.params['idQuote'],
      },

      productServiceSelectValue: null,

      comercialTableSelectValue: null,

      startDateSelectValue: null,
      finishDateSelectValue: null,
      daysSelectValue: null,
      quantitySelectValue: null,
      currentQuantitySelectValue: null,
      unitaryAmountSelectValue: null,
      grossAmountSelectValue: null,
      typeDiscountSelectValue: null,
      discountPercentSelectValue: null,
      discountSelectValue: null,
      finalAmountSelectValue: null,
      frequencySelectValue: null,
      additiveSelectValue: null,
      billQuantityItemSelectValue: null,
      billGrossAmountSelectValue: null,
      billFinalAmountSelectValue: null,
      suprimentoStatusSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getQuoteProductServiceState(this.props.location),
        quoteId: [
          {
            ...(this.props.entityEmbebedPai ? this.props.entityEmbebedPai : {}),
            value: this.props.match.params['idQuote'],
            label: this.props.match.params['idQuote'],
          },
        ],
        baseFilters: 'quote',
      },
      productServiceName: null,
      comercialTableId: null,
      isNew: false,
    };

    axios
      .get('/api/quotes/' + this.props.match.params['idQuote'], {
        headers: {
          'Select-Fields': 'id',
        },
      })
      .then(result => {
        this.setState({
          quoteSelectValue: {
            ...result['data'],
            value: this.props.match.params['idQuote'],
            label: showFieldsSelectAsync(result['data'], 'id'),
          },
        });
      });
  }

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({ listCheckedID: this.props.quoteProductServiceList.map(quoteProductService => quoteProductService.id) });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({ listCheckedID: this.props.quoteProductServiceList.map(quoteProductService => quoteProductService.id) });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  removeEntitySelected() {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  }

  saveEntityMany = () => {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  };

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  cancelFilters = () => {
    this.setState(
      {
        startDateStart: '',
        startDateEnd: '',
        finishDateStart: '',
        finishDateEnd: '',
        typeDiscount: '',
        days: '',
        quantity: '',
        currentQuantity: '',
        unitaryAmount: '',
        grossAmount: '',
        discountPercent: '',
        discount: '',
        finalAmount: '',
        frequency: '',
        quoteId: '',
        productServiceId: '',
        additive: '',
        billQuantityItem: '',
        billFinalAmount: '',
        suprimentoStatus: '',
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
        activePage: 0,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();

    const selectFieldsList = [
      'productService.name',
      'frequency',
      'typeDiscount',
      'quote.id',
      'billQuantityItem',
      'billFinalAmount',
      'currentQuantity',
    ];
    const {
      startDateStart,
      startDateEnd,
      finishDateStart,
      finishDateEnd,
      typeDiscount,
      days,
      quantity,
      currentQuantity,
      unitaryAmount,
      grossAmount,
      discountPercent,
      discount,
      finalAmount,
      frequency,
      quoteId,
      productServiceId,
      additive,
      billQuantityItem,
      billFinalAmount,
      suprimentoStatus,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;
    return this.props.getEntities(
      startDateStart,
      startDateEnd,
      finishDateStart,
      finishDateEnd,
      typeDiscount,
      days,
      quantity,
      currentQuantity,
      unitaryAmount,
      grossAmount,
      discountPercent,
      discount,
      finalAmount,
      frequency,
      getFilterFromSelect(quoteId, 'many-to-one'),
      getFilterFromSelect(productServiceId, 'many-to-one'),
      additive,
      billQuantityItem,
      billFinalAmount,
      suprimentoStatus,
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(',')
    );
  };

  updateEntity = entity => {
    const selectFieldsList = [
      'productService.name',
      'frequency',
      'typeDiscount',
      'quote.id',
      'billQuantityItem',
      'billFinalAmount',
      'currentQuantity',
    ];

    const {
      startDateStart,
      startDateEnd,
      finishDateStart,
      finishDateEnd,
      typeDiscount,
      days,
      quantity,
      currentQuantity,
      unitaryAmount,
      grossAmount,
      discountPercent,
      discount,
      finalAmount,
      frequency,
      quoteId,
      productServiceId,
      additive,
      billQuantityItem,
      billFinalAmount,
      suprimentoStatus,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;
    this.props.updateEntity(entity, [
      startDateStart,
      startDateEnd,
      finishDateStart,
      finishDateEnd,
      typeDiscount,
      days,
      quantity,
      currentQuantity,
      unitaryAmount,
      grossAmount,
      discountPercent,
      discount,
      finalAmount,
      frequency,
      getFilterFromSelect(quoteId, 'many-to-one'),
      getFilterFromSelect(productServiceId, 'many-to-one'),
      additive,
      billQuantityItem,
      billFinalAmount,
      suprimentoStatus,
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(','),
    ]);
  };

  saveEntity = (event: any, errors: any, values: any, isNew: any) => {
    const selectFieldsList = [
      'productService.name',
      'frequency',
      'typeDiscount',
      'quote.id',
      'billQuantityItem',
      'billFinalAmount',
      'currentQuantity',
    ];

    values.startDate = convertDateTimeToServer(values.startDate);
    values.finishDate = convertDateTimeToServer(values.finishDate);

    if (errors.length === 0) {
      const { quoteProductServiceEntity } = this.props;
      const _entity = {
        ...quoteProductServiceEntity,
        // ...values,

        quote: getFormFromSelect(this.state.quoteSelectValue, 'many-to-one'), // many-to-one - otherSide

        productService: getFormFromSelect(this.state.productServiceSelectValue, 'many-to-one'), // many-to-one - otherSide

        startDate: this.state.startDateSelectValue,
        finishDate: this.state.finishDateSelectValue,
        days: this.state.daysSelectValue,
        quantity: this.state.quantitySelectValue,
        currentQuantity: this.state.currentQuantitySelectValue,
        unitaryAmount: this.state.unitaryAmountSelectValue,
        grossAmount: this.state.grossAmountSelectValue,
        typeDiscount: this.state.typeDiscountSelectValue === null ? null : this.state.typeDiscountSelectValue,
        discountPercent: this.state.discountPercentSelectValue,
        discount: this.state.discountSelectValue,
        finalAmount: this.state.finalAmountSelectValue,
        frequency: this.state.frequencySelectValue === null ? null : this.state.frequencySelectValue,
        additive: this.state.additiveSelectValue === null ? false : this.state.additiveSelectValue,
        billQuantityItem: this.state.billQuantityItemSelectValue,
        billGrossAmount: this.state.billGrossAmountSelectValue,
        billFinalAmount: this.state.billFinalAmountSelectValue,
        suprimentoStatus: this.state.suprimentoStatusSelectValue === null ? null : this.state.suprimentoStatusSelectValue,
      };
      const entity = _entity;

      const {
        startDateStart,
        startDateEnd,
        finishDateStart,
        finishDateEnd,
        typeDiscount,
        days,
        quantity,
        currentQuantity,
        unitaryAmount,
        grossAmount,
        discountPercent,
        discount,
        finalAmount,
        frequency,
        quoteId,
        productServiceId,
        additive,
        billQuantityItem,
        billFinalAmount,
        suprimentoStatus,
        extraFilters,
        activePage,
        itemsPerPage,
        sort,
        order,
      } = this.state.fieldsBase;

      if (isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              startDateStart,
              startDateEnd,
              finishDateStart,
              finishDateEnd,
              typeDiscount,
              days,
              quantity,
              currentQuantity,
              unitaryAmount,
              grossAmount,
              discountPercent,
              discount,
              finalAmount,
              frequency,
              getFilterFromSelect(quoteId, 'many-to-one'),
              getFilterFromSelect(productServiceId, 'many-to-one'),
              additive,
              billQuantityItem,
              billFinalAmount,
              suprimentoStatus,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              startDateStart,
              startDateEnd,
              finishDateStart,
              finishDateEnd,
              typeDiscount,
              days,
              quantity,
              currentQuantity,
              unitaryAmount,
              grossAmount,
              discountPercent,
              discount,
              finalAmount,
              frequency,
              getFilterFromSelect(quoteId, 'many-to-one'),
              getFilterFromSelect(productServiceId, 'many-to-one'),
              additive,
              billQuantityItem,
              billFinalAmount,
              suprimentoStatus,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
      this.setState({ showModalForm: false });
      this.setState({ showModalEdit: null });
    } else {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.specialty.' + errors[0]) }));
    }
  };

  componentDidUpdate(prevProps) {}

  deleteEntity(quoteProductServiceEntity) {
    MySwal.fire({
      title: <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>,
      html: (
        <Translate contentKey="generadorApp.quoteProductService.delete.question" interpolate={{ id: quoteProductServiceEntity.id }}>
          Are you sure you want to delete this QuoteProductService?
        </Translate>
      ),
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#e70015',
      cancelButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="ban" />
          &nbsp; <Translate contentKey="generadorApp.quoteProductService.btnCancel">Cancel</Translate>
        </>
      ),
      confirmButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="trash" />
          &nbsp;<Translate contentKey="generadorApp.quoteProductService.btnDelete">Delete</Translate>
        </>
      ),
    }).then(async response => {
      if (response.isConfirmed) {
        await this.props.deleteEntity(quoteProductServiceEntity.id, { reloadList: false });
        this.getEntities();
        MySwal.fire({
          title: <Translate contentKey="entity.delete.title.confirmed">Confirmed delete operation</Translate>,
          icon: 'success',
        });
      }
    });
  }

  renderNewModal() {
    return (
      <Modal size={'xl'} isOpen={this.state.showModalForm} toggle={() => this.setState({ showModalForm: false })}>
        <ModalHeader toggle={() => this.setState({ showModalForm: false })}>{this.renderHeaderUpdate({}, true)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefNew = el)}
          model={{}}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, true)}
        >
          <ModalBody>{this.renderBodyUpdate({}, true)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalForm: false })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.quoteProductService.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefNew && this.myFormRefNew.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.quoteProductService.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    );
  }

  getFullEntityFromState(v) {
    return {
      id: v.id,
      startDate: this.state.startDateSelectValue,
      finishDate: this.state.finishDateSelectValue,
      days: this.state.daysSelectValue,
      quantity: this.state.quantitySelectValue,
      currentQuantity: this.state.currentQuantitySelectValue,
      unitaryAmount: this.state.unitaryAmountSelectValue,
      grossAmount: this.state.grossAmountSelectValue,
      typeDiscount: this.state.typeDiscountSelectValue,
      discountPercent: this.state.discountPercentSelectValue,
      discount: this.state.discountSelectValue,
      finalAmount: this.state.finalAmountSelectValue,
      frequency: this.state.frequencySelectValue,
      additive: this.state.additiveSelectValue,
      billQuantityItem: this.state.billQuantityItemSelectValue,
      billGrossAmount: this.state.billGrossAmountSelectValue,
      billFinalAmount: this.state.billFinalAmountSelectValue,
      suprimentoStatus: this.state.suprimentoStatusSelectValue,
      clientGlosaItem: this.state.clientGlosaItemSelectValue,
      supplierGlosaItem: this.state.supplierGlosaItemSelectValue,
      quote: this.state.quoteSelectValue,
      productService: this.state.productServiceSelectValue,
      comercialTable: this.state.comercialTableSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    let p: any = null;

    const clientGlosaItemEntity = v.clientGlosaItem
      ? v.clientGlosaItem.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' }))
      : v.clientGlosaItem;

    const supplierGlosaItemEntity = v.supplierGlosaItem
      ? v.supplierGlosaItem.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' }))
      : v.supplierGlosaItem;

    p = v.quote;
    const quoteEntity = p ? { ...p, value: p.id, label: p['id'] ? p.id : '' } : p;

    p = v.productService;
    const productServiceEntity = p
      ? { ...p, value: p.id, label: (p['category'] && p['category']['name'] ? p.category.name : '') + ' | ' + (p['name'] ? p.name : '') }
      : p;

    p = v.comercialTable;
    const comercialTableEntity = p ? { ...p, value: p.id, label: p['id'] ? p.id : '' } : p;

    return {
      startDateSelectValue: v.startDate ? moment(v.startDate).toDate() : null, // Instant,
      finishDateSelectValue: v.finishDate ? moment(v.finishDate).toDate() : null, // Instant,
      daysSelectValue: v.days, // days,
      quantitySelectValue: v.quantity, // quantity,
      currentQuantitySelectValue: v.currentQuantity, // currentQuantity,
      unitaryAmountSelectValue: v.unitaryAmount, // unitaryAmount,
      grossAmountSelectValue: v.grossAmount, // grossAmount,
      typeDiscountSelectValue: v.typeDiscount, // typeDiscount,
      discountPercentSelectValue: v.discountPercent, // discountPercent,
      discountSelectValue: v.discount, // discount,
      finalAmountSelectValue: v.finalAmount, // finalAmount,
      frequencySelectValue: v.frequency, // frequency,
      additiveSelectValue: v.additive, // additive,
      billQuantityItemSelectValue: v.billQuantityItem, // billQuantityItem,
      billGrossAmountSelectValue: v.billGrossAmount, // billGrossAmount,
      billFinalAmountSelectValue: v.billFinalAmount, // billFinalAmount,
      suprimentoStatusSelectValue: v.suprimentoStatus, // suprimentoStatus,
      clientGlosaItemSelectValue: clientGlosaItemEntity,
      supplierGlosaItemSelectValue: supplierGlosaItemEntity,
      quoteSelectValue: quoteEntity,
      productServiceSelectValue: productServiceEntity,
      comercialTableSelectValue: comercialTableEntity,
    };
  }

  openUpdateModal(v, edit = true) {
    this.props.getEntity(v.id);
    this.setState({
      showModalEdit: v.id,
      showModalEditView: edit,
      ...this.getFullEntitySelectValue(v),
    });
  }

  renderUpdateModal() {
    const { quoteProductServiceEntity, entityEmbebedPai } = this.props;
    return quoteProductServiceEntity && quoteProductServiceEntity.id ? (
      <Modal
        size={'xl'}
        isOpen={this.state.showModalEdit === quoteProductServiceEntity.id}
        toggle={() => this.setState({ showModalEdit: null })}
      >
        <ModalHeader toggle={() => this.setState({ showModalEdit: null })}>
          {this.renderHeaderUpdate(quoteProductServiceEntity, false)}
        </ModalHeader>
        <AvForm
          ref={el => (this.myFormRefEdit = el)}
          model={{
            ...quoteProductServiceEntity,
          }}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, false)}
        >
          <ModalBody>{this.renderBodyUpdate(quoteProductServiceEntity, false)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalEdit: null })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.quoteProductService.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefEdit && this.myFormRefEdit.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.quoteProductService.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    ) : (
      <> </>
    );
  }

  renderHeaderUpdate(quoteProductServiceEntity, isNew) {
    const {
      entityEmbebedPai,
      clientGlosaItems,
      supplierGlosaItems,
      quotes,
      productServices,
      supplierTableProductServices,
      loading,
    } = this.props;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {isNew ? (
              <Translate contentKey="generadorApp.quoteProductService.home.createLabel">Create a QuoteProductService</Translate>
            ) : (
              <Translate contentKey="generadorApp.quoteProductService.home.editLabel">Edit a QuoteProductService</Translate>
            )}
          </span>
        </div>
      </>
    );
  }
  renderBodyUpdate(quoteProductServiceEntity, isNew) {
    const {
      entityEmbebedPai,
      clientGlosaItems,
      supplierGlosaItems,
      quotes,
      productServices,
      supplierTableProductServices,
      loading,
    } = this.props;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
              <Col md="3">
              <Label className="mt-2 label-single-line"for="quote-product-service-id">
                <Translate contentKey="generadorApp.quoteProductService.field_id">ID</Translate>
              </Label>
              </Col> */}
                      <Col md="12">
                        <AvInput id="quote-product-service-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'startDate' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="startDateLabel" for="quote-product-service-startDate">
                              <Translate contentKey="generadorApp.quoteProductService.startDate">Start Date</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <DatePicker
                              autoComplete="off"
                              onChange={value => this.setState({ startDateSelectValue: value })}
                              id="startDate"
                              validate={{}}
                              className="form-control react-datepicker"
                              name="startDate"
                              dateFormat="dd/MM/yyyy"
                              selected={this.state.startDateSelectValue ? moment(this.state.startDateSelectValue).toDate() : null}
                              locale="pt"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ startDateSelectValue: evt.target.value })}
                      type="hidden"
                      name="startDate"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'finishDate' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="finishDateLabel" for="quote-product-service-finishDate">
                              <Translate contentKey="generadorApp.quoteProductService.finishDate">Finish Date</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <DatePicker
                              autoComplete="off"
                              onChange={value => this.setState({ finishDateSelectValue: value })}
                              id="finishDate"
                              validate={{}}
                              className="form-control react-datepicker"
                              name="finishDate"
                              dateFormat="dd/MM/yyyy"
                              selected={this.state.finishDateSelectValue ? moment(this.state.finishDateSelectValue).toDate() : null}
                              locale="pt"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ finishDateSelectValue: evt.target.value })}
                      type="hidden"
                      name="finishDate"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'typeDiscount' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="typeDiscountLabel" for="quote-product-service-typeDiscount">
                              <Translate contentKey="generadorApp.quoteProductService.typeDiscount">Type Discount (%)</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="quote-product-service-typeDiscount"
                              className={'css-select-control'}
                              value={
                                this.state.typeDiscountSelectValue
                                  ? {
                                      value: this.state.typeDiscountSelectValue,
                                      label: translate('generadorApp.QuoteDiscountType.' + this.state.typeDiscountSelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.quoteProductService.typeDiscount') }
                              }
                              options={[
                                { value: 'VALOR_UNITARIO', label: translate('generadorApp.QuoteDiscountType.VALOR_UNITARIO') },
                                { value: 'PORCENTO', label: translate('generadorApp.QuoteDiscountType.PORCENTO') },
                              ]}
                              onChange={(options: any) => this.setState({ typeDiscountSelectValue: options['value'] })}
                              name="typeDiscount"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ typeDiscountSelectValue: evt.target.value })}
                      type="hidden"
                      name="typeDiscount"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'days' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="daysLabel" for="quote-product-service-days">
                              <Translate contentKey="generadorApp.quoteProductService.days">Days</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ daysSelectValue: floatvalue })}
                              decimalSeparator=""
                              thousandSeparator=""
                              precision={0}
                              prefix=""
                              allowNegative={true}
                              value={this.state.daysSelectValue ? this.state.daysSelectValue : 0}
                              id="quote-product-service-days"
                              name="days"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ daysSelectValue: evt.target.value })}
                      type="hidden"
                      name="days"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'quantity' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="quantityLabel" for="quote-product-service-quantity">
                              <Translate contentKey="generadorApp.quoteProductService.quantity">Quantity</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ quantitySelectValue: floatvalue })}
                              decimalSeparator=""
                              thousandSeparator=""
                              precision={0}
                              prefix=""
                              allowNegative={true}
                              value={this.state.quantitySelectValue ? this.state.quantitySelectValue : 0}
                              id="quote-product-service-quantity"
                              name="quantity"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ quantitySelectValue: evt.target.value })}
                      type="hidden"
                      name="quantity"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'currentQuantity' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="currentQuantityLabel" for="quote-product-service-currentQuantity">
                              <Translate contentKey="generadorApp.quoteProductService.currentQuantity">Current quantity</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ currentQuantitySelectValue: floatvalue })}
                              decimalSeparator=","
                              thousandSeparator=""
                              precision={2}
                              prefix=""
                              allowNegative={true}
                              value={this.state.currentQuantitySelectValue ? this.state.currentQuantitySelectValue : 0}
                              id="quote-product-service-currentQuantity"
                              name="currentQuantity"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ currentQuantitySelectValue: evt.target.value })}
                      type="hidden"
                      name="currentQuantity"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'unitaryAmount' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="unitaryAmountLabel" for="quote-product-service-unitaryAmount">
                              <Translate contentKey="generadorApp.quoteProductService.unitaryAmount">Unitary Amount</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ unitaryAmountSelectValue: floatvalue })}
                              decimalSeparator=","
                              thousandSeparator=""
                              precision={2}
                              prefix=""
                              allowNegative={true}
                              value={this.state.unitaryAmountSelectValue ? this.state.unitaryAmountSelectValue : 0}
                              id="quote-product-service-unitaryAmount"
                              name="unitaryAmount"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ unitaryAmountSelectValue: evt.target.value })}
                      type="hidden"
                      name="unitaryAmount"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'grossAmount' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="grossAmountLabel" for="quote-product-service-grossAmount">
                              <Translate contentKey="generadorApp.quoteProductService.grossAmount">Gross Amount</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ grossAmountSelectValue: floatvalue })}
                              decimalSeparator=","
                              thousandSeparator=""
                              precision={2}
                              prefix=""
                              allowNegative={true}
                              value={this.state.grossAmountSelectValue ? this.state.grossAmountSelectValue : 0}
                              id="quote-product-service-grossAmount"
                              name="grossAmount"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ grossAmountSelectValue: evt.target.value })}
                      type="hidden"
                      name="grossAmount"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'discountPercent' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="discountPercentLabel" for="quote-product-service-discountPercent">
                              <Translate contentKey="generadorApp.quoteProductService.discountPercent">Discount (%)</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ discountPercentSelectValue: floatvalue })}
                              decimalSeparator=""
                              thousandSeparator=""
                              precision={0}
                              prefix=""
                              allowNegative={true}
                              value={this.state.discountPercentSelectValue ? this.state.discountPercentSelectValue : 0}
                              id="quote-product-service-discountPercent"
                              name="discountPercent"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ discountPercentSelectValue: evt.target.value })}
                      type="hidden"
                      name="discountPercent"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'discount' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="discountLabel" for="quote-product-service-discount">
                              <Translate contentKey="generadorApp.quoteProductService.discount">Discount</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ discountSelectValue: floatvalue })}
                              decimalSeparator=","
                              thousandSeparator=""
                              precision={2}
                              prefix=""
                              allowNegative={true}
                              value={this.state.discountSelectValue ? this.state.discountSelectValue : 0}
                              id="quote-product-service-discount"
                              name="discount"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ discountSelectValue: evt.target.value })}
                      type="hidden"
                      name="discount"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'finalAmount' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="finalAmountLabel" for="quote-product-service-finalAmount">
                              <Translate contentKey="generadorApp.quoteProductService.finalAmount">Final Amount</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ finalAmountSelectValue: floatvalue })}
                              decimalSeparator=","
                              thousandSeparator=""
                              precision={2}
                              prefix=""
                              allowNegative={true}
                              value={this.state.finalAmountSelectValue ? this.state.finalAmountSelectValue : 0}
                              id="quote-product-service-finalAmount"
                              name="finalAmount"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ finalAmountSelectValue: evt.target.value })}
                      type="hidden"
                      name="finalAmount"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'frequency' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="frequencyLabel" for="quote-product-service-frequency">
                              <Translate contentKey="generadorApp.quoteProductService.frequency">Frequency</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="quote-product-service-frequency"
                              className={'css-select-control'}
                              value={
                                this.state.frequencySelectValue
                                  ? {
                                      value: this.state.frequencySelectValue,
                                      label: translate('generadorApp.QuoteProductServiceFrequency.' + this.state.frequencySelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.quoteProductService.frequency') }
                              }
                              options={[
                                { value: 'LOCACAODIARIA', label: translate('generadorApp.QuoteProductServiceFrequency.LOCACAODIARIA') },
                                { value: 'VENDA', label: translate('generadorApp.QuoteProductServiceFrequency.VENDA') },
                              ]}
                              onChange={(options: any) => this.setState({ frequencySelectValue: options['value'] })}
                              name="frequency"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ frequencySelectValue: evt.target.value })}
                      type="hidden"
                      name="frequency"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'quote' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="quote-product-service-quote">
                              <Translate contentKey="generadorApp.quoteProductService.quote">Quote</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="quote-product-service-quote"
                              name={'quote'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.quoteSelectValue ? this.state.quoteSelectValue : ''}
                              onChange={options => this.setState({ quoteSelectValue: options })}
                              defaultOptions={this.state.quoteStartSelectOptions ? this.state.quoteStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.quoteStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.quoteStartSelectOptions === undefined) {
                                  const result = await getListAxios('quotes', {}, 0, 100, 'id,asc', 'id');
                                  this.setState({
                                    quoteStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios('quotes', { 'id.contains': inputValue }, 0, 100, 'id,asc', 'id');
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="quote"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}

                  {baseFilters !== 'productService' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="quote-product-service-productService">
                              <Translate contentKey="generadorApp.quoteProductService.productService">Product Service</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="quote-product-service-productService"
                              name={'productService'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.productServiceSelectValue ? this.state.productServiceSelectValue : ''}
                              onChange={options => this.setState({ productServiceSelectValue: options })}
                              defaultOptions={
                                this.state.productServiceStartSelectOptions ? this.state.productServiceStartSelectOptions : []
                              }
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.productServiceStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.productServiceStartSelectOptions === undefined) {
                                  const result = await getListAxios(
                                    'product-services',
                                    { 'levelGroup.sub.equals': 'PRODUTOSESERVICOS' },
                                    0,
                                    100,
                                    'category,asc',
                                    'category.name,name'
                                  );
                                  this.setState({
                                    productServiceStartSelectOptions: result.data
                                      ? result.data.map(p => ({
                                          ...p,
                                          value: p.id,
                                          label: showFieldsSelectAsync(p, 'category.name;name') || '',
                                        }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'product-services',
                                  { 'levelGroup.sub.equals': 'PRODUTOSESERVICOS', 'name.contains': inputValue },
                                  0,
                                  100,
                                  'category,asc',
                                  'category.name,name'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({
                                        ...p,
                                        value: p.id,
                                        label: showFieldsSelectAsync(p, 'category.name;name') || '',
                                      }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                          <RenderModalSuperSelect
                            baseState={this.state}
                            setState={_state => this.setState(_state)}
                            entity={'productService'}
                            listEntity={'product-services'}
                            template={'category.name;name'.split(';')}
                            stateField={'productServiceSelectValue'}
                            multiple={false}
                            showFields={'category.name;name'.split(';')}
                            order={'category,asc'}
                            filtersBase={{ 'levelGroup.sub.equals': 'PRODUTOSESERVICOS' }}
                          />
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="productService"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}

                  {baseFilters !== 'additive' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="additiveLabel" for="quote-product-service-additive">
                              <Translate contentKey="generadorApp.quoteProductService.additive">Additive</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'additive_checkbox'}
                                id={'additive_checkbox'}
                                checked={this.state.additiveSelectValue}
                                onChange={evt => this.setState({ additiveSelectValue: !this.state.additiveSelectValue })}
                              />
                              <label htmlFor={'additive_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ additiveSelectValue: evt.target.value })}
                      type="hidden"
                      name="additive"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'billQuantityItem' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label
                              className="mt-2 label-single-line"
                              id="billQuantityItemLabel"
                              for="quote-product-service-billQuantityItem"
                            >
                              <Translate contentKey="generadorApp.quoteProductService.billQuantityItem">Quantity</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ billQuantityItemSelectValue: floatvalue })}
                              decimalSeparator=""
                              thousandSeparator=""
                              precision={0}
                              prefix=""
                              allowNegative={true}
                              value={this.state.billQuantityItemSelectValue ? this.state.billQuantityItemSelectValue : 0}
                              id="quote-product-service-billQuantityItem"
                              name="billQuantityItem"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ billQuantityItemSelectValue: evt.target.value })}
                      type="hidden"
                      name="billQuantityItem"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'billFinalAmount' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="billFinalAmountLabel" for="quote-product-service-billFinalAmount">
                              <Translate contentKey="generadorApp.quoteProductService.billFinalAmount">Final Amount</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ billFinalAmountSelectValue: floatvalue })}
                              decimalSeparator=","
                              thousandSeparator=""
                              precision={2}
                              prefix=""
                              allowNegative={true}
                              value={this.state.billFinalAmountSelectValue ? this.state.billFinalAmountSelectValue : 0}
                              id="quote-product-service-billFinalAmount"
                              name="billFinalAmount"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ billFinalAmountSelectValue: evt.target.value })}
                      type="hidden"
                      name="billFinalAmount"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'suprimentoStatus' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label
                              className="mt-2 label-single-line"
                              id="suprimentoStatusLabel"
                              for="quote-product-service-suprimentoStatus"
                            >
                              <Translate contentKey="generadorApp.quoteProductService.suprimentoStatus">Status</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="quote-product-service-suprimentoStatus"
                              className={'css-select-control'}
                              value={
                                this.state.suprimentoStatusSelectValue
                                  ? {
                                      value: this.state.suprimentoStatusSelectValue,
                                      label: translate('generadorApp.SuprimentoStatus.' + this.state.suprimentoStatusSelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.quoteProductService.suprimentoStatus') }
                              }
                              options={[
                                { value: 'COMPLETO', label: translate('generadorApp.SuprimentoStatus.COMPLETO') },
                                { value: 'INCOMPLETO', label: translate('generadorApp.SuprimentoStatus.INCOMPLETO') },
                              ]}
                              onChange={(options: any) => this.setState({ suprimentoStatusSelectValue: options['value'] })}
                              name="suprimentoStatus"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ suprimentoStatusSelectValue: evt.target.value })}
                      type="hidden"
                      name="suprimentoStatus"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  renderHeader() {
    const {
      clientGlosaItems,
      supplierGlosaItems,
      quotes,
      productServices,
      supplierTableProductServices,
      quoteProductServiceList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.quoteProductService.home.title">Product Services</Translate>
          </span>
          <Button id="togglerFilterQuoteProductService" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.quoteProductService.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          &nbsp;
          <Button
            onClick={() =>
              this.setState({
                showModalForm: true,
                startDateSelectValue: null,
                finishDateSelectValue: null,
                daysSelectValue: null,
                quantitySelectValue: null,
                currentQuantitySelectValue: null,
                unitaryAmountSelectValue: null,
                grossAmountSelectValue: null,
                typeDiscountSelectValue: null,
                discountPercentSelectValue: null,
                discountSelectValue: null,
                finalAmountSelectValue: null,
                frequencySelectValue: null,
                additiveSelectValue: null,
                billQuantityItemSelectValue: null,
                billGrossAmountSelectValue: null,
                billFinalAmountSelectValue: null,
                suprimentoStatusSelectValue: null,

                clientGlosaItemStartSelectOptions: undefined,
                clientGlosaItemSelectValue: null,

                supplierGlosaItemStartSelectOptions: undefined,
                supplierGlosaItemSelectValue: null,

                productServiceStartSelectOptions: undefined,
                productServiceSelectValue: null,

                comercialTableStartSelectOptions: undefined,
                comercialTableSelectValue: null,
                isNew: true,
              })
            }
            color="primary"
            className="btn btn-primary float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="generadorApp.quoteProductService.btnNewItem">Create new</Translate>
          </Button>{' '}
          &nbsp;
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/quote'}>
              <Translate contentKey="generadorApp.quote.home.title">Quotes</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/quote/' + this.props.match.params['idQuote']}>
              {this.state.quoteSelectValue ? this.state.quoteSelectValue.label : this.props.match.params['idQuote']}
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.quoteProductService.home.title">Product Services</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    const {
      clientGlosaItems,
      supplierGlosaItems,
      quotes,
      productServices,
      supplierTableProductServices,
      quoteProductServiceList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <UncontrolledCollapse toggler="#togglerFilterQuoteProductService">
          <CardBody>
            <AvForm ref={el => (this.myFormRef = el)} id="form-filter" onSubmit={this.filterEntity}>
              <div className="row mt-1 ml-3 mr-3">
                {this.state.baseFilters !== 'startDate' ? (
                  <Col md="3" className="col-filter-quote-product-service-startDate">
                    <Row className="mr-1 mt-1">
                      <Label id="startDateStart" for="quote-product-service-startDate_start">
                        <Translate contentKey="generadorApp.quoteProductService.startDate_start">Start Date (start)</Translate>
                      </Label>
                      <DatePicker
                        onChange={value => this.setState({ startDateStart: value })}
                        id="startDateStart"
                        className="form-control react-datepicker"
                        name="startDateStart"
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.startDateStart ? moment(this.state.startDateStart).toDate() : null}
                        locale="pt"
                      />
                    </Row>
                  </Col>
                ) : null}
                {this.state.baseFilters !== 'startDate' ? (
                  <Col md="3" className="col-filter-quote-product-service-startDate-final">
                    <Row className="mr-1 mt-1">
                      <Label id="startDateEnd" for="quote-product-service-startDate_end">
                        <Translate contentKey="generadorApp.quoteProductService.startDate_end">Start Date (end)</Translate>
                      </Label>
                      <DatePicker
                        onChange={value => this.setState({ startDateEnd: value })}
                        id="startDateEnd"
                        className="form-control react-datepicker"
                        name="startDateEnd"
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.startDateEnd ? moment(this.state.startDateEnd).toDate() : null}
                        locale="pt"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'finishDate' ? (
                  <Col md="3" className="col-filter-quote-product-service-finishDate">
                    <Row className="mr-1 mt-1">
                      <Label id="finishDateStart" for="quote-product-service-finishDate_start">
                        <Translate contentKey="generadorApp.quoteProductService.finishDate_start">Finish Date (start)</Translate>
                      </Label>
                      <DatePicker
                        onChange={value => this.setState({ finishDateStart: value })}
                        id="finishDateStart"
                        className="form-control react-datepicker"
                        name="finishDateStart"
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.finishDateStart ? moment(this.state.finishDateStart).toDate() : null}
                        locale="pt"
                      />
                    </Row>
                  </Col>
                ) : null}
                {this.state.baseFilters !== 'finishDate' ? (
                  <Col md="3" className="col-filter-quote-product-service-finishDate-final">
                    <Row className="mr-1 mt-1">
                      <Label id="finishDateEnd" for="quote-product-service-finishDate_end">
                        <Translate contentKey="generadorApp.quoteProductService.finishDate_end">Finish Date (end)</Translate>
                      </Label>
                      <DatePicker
                        onChange={value => this.setState({ finishDateEnd: value })}
                        id="finishDateEnd"
                        className="form-control react-datepicker"
                        name="finishDateEnd"
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.finishDateEnd ? moment(this.state.finishDateEnd).toDate() : null}
                        locale="pt"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'typeDiscount' ? (
                  <Col md="3" className="col-filter-quote-product-service-typeDiscount">
                    <Row className="mr-1 mt-1">
                      <Label id="typeDiscountLabel" for="quote-product-service-typeDiscount">
                        <Translate contentKey="generadorApp.quoteProductService.typeDiscount">Type Discount (%)</Translate>
                      </Label>
                      <Select
                        id="quote-product-service-typeDiscount"
                        className={'css-select-control'}
                        value={
                          this.state.typeDiscount
                            ? {
                                value: this.state.typeDiscount,
                                label: translate('generadorApp.QuoteDiscountType.' + this.state.typeDiscount),
                              }
                            : { value: '', label: translate('generadorApp.quoteProductService.typeDiscount') }
                        }
                        options={[
                          { value: '', label: translate('generadorApp.quoteProductService.typeDiscount') },
                          { value: 'VALOR_UNITARIO', label: translate('generadorApp.QuoteDiscountType.VALOR_UNITARIO') },
                          { value: 'PORCENTO', label: translate('generadorApp.QuoteDiscountType.PORCENTO') },
                        ]}
                        onChange={(options: any) => this.setState({ typeDiscount: options['value'] })}
                        name="typeDiscount"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'days' ? (
                  <Col md="3" className="col-filter-quote-product-service-days">
                    <Row className="mr-1 mt-1">
                      <Label id="daysLabel" for="quote-product-service-days">
                        <Translate contentKey="generadorApp.quoteProductService.days">Days</Translate>
                      </Label>
                      <AvInput type="number" name="days" id="quote-product-service-days" value={this.state.days} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'quantity' ? (
                  <Col md="3" className="col-filter-quote-product-service-quantity">
                    <Row className="mr-1 mt-1">
                      <Label id="quantityLabel" for="quote-product-service-quantity">
                        <Translate contentKey="generadorApp.quoteProductService.quantity">Quantity</Translate>
                      </Label>
                      <AvInput type="number" name="quantity" id="quote-product-service-quantity" value={this.state.quantity} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'currentQuantity' ? (
                  <Col md="3" className="col-filter-quote-product-service-currentQuantity">
                    <Row className="mr-1 mt-1">
                      <Label id="currentQuantityLabel" for="quote-product-service-currentQuantity">
                        <Translate contentKey="generadorApp.quoteProductService.currentQuantity">Current quantity</Translate>
                      </Label>

                      <AvInput
                        type="text"
                        name="currentQuantity"
                        id="quote-product-service-currentQuantity"
                        value={this.state.currentQuantity}
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'unitaryAmount' ? (
                  <Col md="3" className="col-filter-quote-product-service-unitaryAmount">
                    <Row className="mr-1 mt-1">
                      <Label id="unitaryAmountLabel" for="quote-product-service-unitaryAmount">
                        <Translate contentKey="generadorApp.quoteProductService.unitaryAmount">Unitary Amount</Translate>
                      </Label>

                      <AvInput type="text" name="unitaryAmount" id="quote-product-service-unitaryAmount" value={this.state.unitaryAmount} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'grossAmount' ? (
                  <Col md="3" className="col-filter-quote-product-service-grossAmount">
                    <Row className="mr-1 mt-1">
                      <Label id="grossAmountLabel" for="quote-product-service-grossAmount">
                        <Translate contentKey="generadorApp.quoteProductService.grossAmount">Gross Amount</Translate>
                      </Label>

                      <AvInput type="text" name="grossAmount" id="quote-product-service-grossAmount" value={this.state.grossAmount} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'discountPercent' ? (
                  <Col md="3" className="col-filter-quote-product-service-discountPercent">
                    <Row className="mr-1 mt-1">
                      <Label id="discountPercentLabel" for="quote-product-service-discountPercent">
                        <Translate contentKey="generadorApp.quoteProductService.discountPercent">Discount (%)</Translate>
                      </Label>
                      <AvInput
                        type="number"
                        name="discountPercent"
                        id="quote-product-service-discountPercent"
                        value={this.state.discountPercent}
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'discount' ? (
                  <Col md="3" className="col-filter-quote-product-service-discount">
                    <Row className="mr-1 mt-1">
                      <Label id="discountLabel" for="quote-product-service-discount">
                        <Translate contentKey="generadorApp.quoteProductService.discount">Discount</Translate>
                      </Label>

                      <AvInput type="text" name="discount" id="quote-product-service-discount" value={this.state.discount} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'finalAmount' ? (
                  <Col md="3" className="col-filter-quote-product-service-finalAmount">
                    <Row className="mr-1 mt-1">
                      <Label id="finalAmountLabel" for="quote-product-service-finalAmount">
                        <Translate contentKey="generadorApp.quoteProductService.finalAmount">Final Amount</Translate>
                      </Label>

                      <AvInput type="text" name="finalAmount" id="quote-product-service-finalAmount" value={this.state.finalAmount} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'frequency' ? (
                  <Col md="3" className="col-filter-quote-product-service-frequency">
                    <Row className="mr-1 mt-1">
                      <Label id="frequencyLabel" for="quote-product-service-frequency">
                        <Translate contentKey="generadorApp.quoteProductService.frequency">Frequency</Translate>
                      </Label>
                      <Select
                        id="quote-product-service-frequency"
                        className={'css-select-control'}
                        value={
                          this.state.frequency
                            ? {
                                value: this.state.frequency,
                                label: translate('generadorApp.QuoteProductServiceFrequency.' + this.state.frequency),
                              }
                            : { value: '', label: translate('generadorApp.quoteProductService.frequency') }
                        }
                        options={[
                          { value: '', label: translate('generadorApp.quoteProductService.frequency') },
                          { value: 'LOCACAODIARIA', label: translate('generadorApp.QuoteProductServiceFrequency.LOCACAODIARIA') },
                          { value: 'VENDA', label: translate('generadorApp.QuoteProductServiceFrequency.VENDA') },
                        ]}
                        onChange={(options: any) => this.setState({ frequency: options['value'] })}
                        name="frequency"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'quote' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="quote-product-service-quote">
                          <Translate contentKey="generadorApp.quoteProductService.quote">Quote</Translate>
                        </Label>
                        <SelectAsync
                          id="quote-product-service-quote"
                          isMulti
                          className={'css-select-control'}
                          name={'quote'}
                          cacheOptions
                          value={this.state.quoteId}
                          onChange={options => this.setState({ quoteId: options })}
                          defaultOptions={this.state.quoteStartFilter ? this.state.quoteStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.quoteStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.quoteStartFilter === undefined) {
                              const result = await getListAxios('quotes', {}, 0, 100, 'id,asc', 'id');
                              this.setState({
                                quoteStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios('quotes', { 'id.contains': inputValue }, 0, 100, 'id,asc', 'id');
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'productService' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="quote-product-service-productService">
                          <Translate contentKey="generadorApp.quoteProductService.productService">Product Service</Translate>
                        </Label>
                        <SelectAsync
                          id="quote-product-service-productService"
                          isMulti
                          className={'css-select-control'}
                          name={'productService'}
                          cacheOptions
                          value={this.state.productServiceId}
                          onChange={options => this.setState({ productServiceId: options })}
                          defaultOptions={this.state.productServiceStartFilter ? this.state.productServiceStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.productServiceStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.productServiceStartFilter === undefined) {
                              const result = await getListAxios('product-services', {}, 0, 100, 'name,asc', 'name');
                              this.setState({
                                productServiceStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios(
                              'product-services',
                              { 'name.contains': inputValue },
                              0,
                              100,
                              'name,asc',
                              'name'
                            );
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'additive' ? (
                  <Col md="3" className="col-filter-quote-product-service-additive">
                    <Row className="mr-1 mt-1">
                      <Label id="additiveLabel" for="quote-product-service-additive">
                        <Translate contentKey="generadorApp.quoteProductService.additive">Additive</Translate>
                      </Label>
                      <Select
                        id="quote-product-service-additive"
                        className={'css-select-control'}
                        value={
                          this.state.additive === 0
                            ? { value: 0, label: translate('generadorApp.quoteProductService.additive_filter_without') }
                            : this.state.additive === 1
                            ? { value: 1, label: translate('generadorApp.quoteProductService.additive_filter_with') }
                            : { value: '', label: translate('generadorApp.quoteProductService.additive_filter_all') }
                        }
                        options={[
                          { value: '', label: translate('generadorApp.quoteProductService.additive_filter_all') },
                          { value: 1, label: translate('generadorApp.quoteProductService.additive_filter_with') },
                          { value: 0, label: translate('generadorApp.quoteProductService.additive_filter_without') },
                        ]}
                        onChange={(options: any) => this.setState({ additive: options['value'] })}
                        name="additive"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'billQuantityItem' ? (
                  <Col md="3" className="col-filter-quote-product-service-billQuantityItem">
                    <Row className="mr-1 mt-1">
                      <Label id="billQuantityItemLabel" for="quote-product-service-billQuantityItem">
                        <Translate contentKey="generadorApp.quoteProductService.billQuantityItem">Quantity</Translate>
                      </Label>
                      <AvInput
                        type="number"
                        name="billQuantityItem"
                        id="quote-product-service-billQuantityItem"
                        value={this.state.billQuantityItem}
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'billFinalAmount' ? (
                  <Col md="3" className="col-filter-quote-product-service-billFinalAmount">
                    <Row className="mr-1 mt-1">
                      <Label id="billFinalAmountLabel" for="quote-product-service-billFinalAmount">
                        <Translate contentKey="generadorApp.quoteProductService.billFinalAmount">Final Amount</Translate>
                      </Label>

                      <AvInput
                        type="text"
                        name="billFinalAmount"
                        id="quote-product-service-billFinalAmount"
                        value={this.state.billFinalAmount}
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'suprimentoStatus' ? (
                  <Col md="3" className="col-filter-quote-product-service-suprimentoStatus">
                    <Row className="mr-1 mt-1">
                      <Label id="suprimentoStatusLabel" for="quote-product-service-suprimentoStatus">
                        <Translate contentKey="generadorApp.quoteProductService.suprimentoStatus">Status</Translate>
                      </Label>
                      <Select
                        id="quote-product-service-suprimentoStatus"
                        className={'css-select-control'}
                        value={
                          this.state.suprimentoStatus
                            ? {
                                value: this.state.suprimentoStatus,
                                label: translate('generadorApp.SuprimentoStatus.' + this.state.suprimentoStatus),
                              }
                            : { value: '', label: translate('generadorApp.quoteProductService.suprimentoStatus') }
                        }
                        options={[
                          { value: '', label: translate('generadorApp.quoteProductService.suprimentoStatus') },
                          { value: 'COMPLETO', label: translate('generadorApp.SuprimentoStatus.COMPLETO') },
                          { value: 'INCOMPLETO', label: translate('generadorApp.SuprimentoStatus.INCOMPLETO') },
                        ]}
                        onChange={(options: any) => this.setState({ suprimentoStatus: options['value'] })}
                        name="suprimentoStatus"
                      />
                    </Row>
                  </Col>
                ) : null}
              </div>

              <div className="row mb-2 mr-4 justify-content-end">
                <Button color="primary" type="submit">
                  <i className="fa fa-filter" aria-hidden={'true'}></i>&nbsp;
                  <Translate contentKey="generadorApp.quoteProductService.btnFilter">Filter</Translate>
                </Button>
                &nbsp;
                <Button color="secondary" onClick={this.cancelFilters}>
                  <FontAwesomeIcon icon="trash-alt" />
                  &nbsp;
                  <Translate contentKey="generadorApp.quoteProductService.btnCleanFilter">Clean Filter</Translate>
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </UncontrolledCollapse>
      </>
    );
  }

  renderTable() {
    const {
      clientGlosaItems,
      supplierGlosaItems,
      quotes,
      productServices,
      supplierTableProductServices,
      quoteProductServiceList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {quoteProductServiceList &&
        quoteProductServiceList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null).length > 0 ? (
          <>
            <Table
              id="quote-product-service-table-list"
              responsive
              aria-describedby="quote-product-service-heading"
              className={'table-hover table-striped table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.quoteProductService.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'productService' ? (
                    <th>
                      <Translate contentKey="generadorApp.quoteProductService.productService">Product Service</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'frequency' ? (
                    <th className="hand" onClick={sortFunction('frequency')}>
                      <Translate contentKey="generadorApp.quoteProductService.frequency">Frequency</Translate>
                      <FontAwesomeIcon icon={state.sort === 'frequency' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'typeDiscount' ? (
                    <th className="hand" onClick={sortFunction('typeDiscount')}>
                      <Translate contentKey="generadorApp.quoteProductService.typeDiscount">Type Discount (%)</Translate>
                      <FontAwesomeIcon icon={state.sort === 'typeDiscount' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'quote' ? (
                    <th>
                      <Translate contentKey="generadorApp.quoteProductService.quote">Quote</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'billQuantityItem' ? (
                    <th className="hand" onClick={sortFunction('billQuantityItem')}>
                      <Translate contentKey="generadorApp.quoteProductService.billQuantityItem">Quantity</Translate>
                      <FontAwesomeIcon
                        icon={state.sort === 'billQuantityItem' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'}
                      />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'billFinalAmount' ? (
                    <th className="hand" onClick={sortFunction('billFinalAmount')}>
                      <Translate contentKey="generadorApp.quoteProductService.billFinalAmount">Final Amount</Translate>
                      <FontAwesomeIcon
                        icon={state.sort === 'billFinalAmount' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'}
                      />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'currentQuantity' ? (
                    <th className="hand" onClick={sortFunction('currentQuantity')}>
                      <Translate contentKey="generadorApp.quoteProductService.currentQuantity">Current quantity</Translate>
                      <FontAwesomeIcon
                        icon={state.sort === 'currentQuantity' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'}
                      />
                    </th>
                  ) : null}

                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {quoteProductServiceList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((quoteProductService, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.quoteProductService.field_id">ID</Translate>
                        </b>
                        {quoteProductService.id}
                      </td>

                      {state.baseFilters !== 'productService' ? (
                        <td id="productService-cell">
                          <b className="visible-xs"> Product Service </b>
                          {quoteProductService ? (
                            <span data-type-rel="2-many-to-one-other-side">
                              {getValueRecursive(quoteProductService, 'productService.name'.split('.'), '')}
                            </span>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'frequency' ? (
                        <td id="frequency-cell">
                          <b className="visible-xs"> Frequency </b>
                          {quoteProductService.frequency ? (
                            <Translate contentKey={`generadorApp.QuoteProductServiceFrequency.${quoteProductService.frequency}`} />
                          ) : (
                            <> </>
                          )}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'typeDiscount' ? (
                        <td id="typeDiscount-cell">
                          <b className="visible-xs"> Type Discount (%) </b>
                          {quoteProductService.typeDiscount ? (
                            <Translate contentKey={`generadorApp.QuoteDiscountType.${quoteProductService.typeDiscount}`} />
                          ) : (
                            <> </>
                          )}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'quote' ? (
                        <td id="quote-cell">
                          <b className="visible-xs"> Quote </b>
                          {quoteProductService ? (
                            <span data-type-rel="2-many-to-one-other-side">
                              {getValueRecursive(quoteProductService, 'quote.id'.split('.'), '')}
                            </span>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'billQuantityItem' ? (
                        <td id="billQuantityItem-cell">
                          <b className="visible-xs"> Quantity </b>

                          {quoteProductService.billQuantityItem}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'billFinalAmount' ? (
                        <td id="billFinalAmount-cell">
                          <b className="visible-xs"> Final Amount </b>

                          {quoteProductService.billFinalAmount}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'currentQuantity' ? (
                        <td id="currentQuantity-cell">
                          <b className="visible-xs"> Current quantity </b>

                          {quoteProductService.currentQuantity}
                        </td>
                      ) : null}

                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          {hasAnyAuthority(props.userAccount, ['quote'], 'edit') ? (
                            <Button onClick={() => this.openUpdateModal(quoteProductService)} color="primary" size="sm">
                              <FontAwesomeIcon icon="pencil-alt" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                          {hasAnyAuthority(props.userAccount, ['quote'], 'edit') ? (
                            <Button onClick={() => this.deleteEntity(quoteProductService)} color="danger" size="sm">
                              <FontAwesomeIcon icon="trash" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.quoteProductService.home.notFound">No Product Services found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const {
      clientGlosaItems,
      supplierGlosaItems,
      quotes,
      productServices,
      supplierTableProductServices,
      quoteProductServiceList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={quoteProductServiceList && quoteProductServiceList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const {
      clientGlosaItems,
      supplierGlosaItems,
      quotes,
      productServices,
      supplierTableProductServices,
      quoteProductServiceList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel className="quote-product-service quote-product-service-all-in-one">
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
            {this.renderNewModal()}
            {this.renderUpdateModal()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToProps = ({ quoteProductService, ...storeState }: IRootState) => ({
  clientGlosaItems: storeState.clientGlosaItem.entities,
  supplierGlosaItems: storeState.supplierGlosaItem.entities,
  quotes: storeState.quote.entities,
  productServices: storeState.productService.entities,
  supplierTableProductServices: storeState.supplierTableProductService.entities,
  quoteProductServiceList: quoteProductService.entities,
  totalItems: quoteProductService.totalItems,
  userAccount: storeState.authentication.account,
  loading: quoteProductService.loading,

  // UPDATE
  quoteProductServiceEntity: quoteProductService.entity,
});

export const mapDispatchToProps = {
  getEntities,

  updateEntity,
  reset,

  // UPDATE
  getEntity,
  createEntity,

  // DELETE
  deleteEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(QuoteProductService);
