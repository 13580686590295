/* eslint complexity: ["error", 500] */
import axios from 'axios';

import { IPaginationBaseState, IPayloadResult } from 'react-jhipster';

import { IPayload } from 'react-jhipster/src/type/redux-action.type';

import { BASE_API_VERSION_PATH } from 'app/config/constants';

import { cleanEntity, jsonParse } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ISupplierGlosa, defaultValue } from 'app/shared/model/supplier-glosa.model';

export declare type ICrudPutAction<T> = (data?: T, listFiltersPage?) => IPayload<T> | IPayloadResult<T>;
export declare type ICrudGetAction<T> = (id: string | number, selectFields?: string) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const ACTION_TYPES = {
  FETCH_SUPPLIERGLOSA_LIST_EXPORT: 'supplierGlosa/FETCH_SUPPLIERGLOSA_LIST_EXPORT',
  FETCH_SUPPLIERGLOSA_LIST: 'supplierGlosa/FETCH_SUPPLIERGLOSA_LIST',
  FETCH_SUPPLIERGLOSA: 'supplierGlosa/FETCH_SUPPLIERGLOSA',
  FETCH_SUPPLIERGLOSA_OFFSET: 'supplierGlosa/FETCH_SUPPLIERGLOSA_OFFSET',
  CREATE_SUPPLIERGLOSA: 'supplierGlosa/CREATE_SUPPLIERGLOSA',
  UPDATE_SUPPLIERGLOSA: 'supplierGlosa/UPDATE_SUPPLIERGLOSA',
  CREATE_SUPPLIERGLOSA_AND_GO_LIST: 'supplierGlosa/CREATE_SUPPLIERGLOSA_AND_GO_LIST',
  UPDATE_SUPPLIERGLOSA_AND_GO_LIST: 'supplierGlosa/UPDATE_SUPPLIERGLOSA_AND_GO_LIST',
  DELETE_SUPPLIERGLOSA: 'supplierGlosa/DELETE_SUPPLIERGLOSA',
  SET_BLOB: 'supplierGlosa/SET_BLOB',
  RESET: 'supplierGlosa/RESET',
  SHOW_MODAL: 'supplierGlosa/SHOW_MODAL',
};

const initialState = {
  loading: false,
  openModal: {
    list: false,
    view: false,
    detail: false,
  },
  errorMessage: null,
  entities: [] as ReadonlyArray<ISupplierGlosa>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const actionPendind = [];
let actualState = initialState;

export const convertEntityBuffers = entity => ({
  ...entity,
  observation: entity.observation ? Buffer.from(entity.observation).toString() : entity.observation,
});

export type SupplierGlosaState = Readonly<typeof initialState>;

export interface ISupplierGlosaBaseState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  listCheckedID?: Array<number>;
  isCheckedAll?: boolean;
  urlBack?: string;
  extraFilters?: any;
  baseFilters: any;
  offset: number;
  typeGlosa?: any;
  status?: any;
}

export interface IFieldsBase extends ISupplierGlosaBaseState, IPaginationBaseState {}
export interface ISupplierGlosaUpdateState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  urlBack?: string;
  functionEmbebed?: any[];
  fieldsBase: IFieldsBase;
  typeGlosaSelectValue?: any;
  typeGlosaStartSelectOptions?: any;
  statusSelectValue?: any;
  statusStartSelectOptions?: any;
  observationSelectValue?: any;
  observationStartSelectOptions?: any;

  supplierGlosaItemSelectValue?: any;
  supplierGlosaItemStartSelectOptions?: any;

  quoteSelectValue?: any;
  quoteStartSelectOptions?: any;

  supplierSelectValue?: any;
  supplierStartSelectOptions?: any;

  isNew: boolean;
  quoteId?: any;
  supplierId?: any;
}

// Reducer

export default (state: SupplierGlosaState = initialState, action): SupplierGlosaState => {
  actualState = state;
  if (
    action.type.includes('_PENDING') &&
    !actionPendind.includes(action.type.replace('_PENDING', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_PENDING', ''))
  ) {
    actionPendind.push(action.type.replace('_PENDING', ''));
  }

  if (
    action.type.includes('_FULFILLED') &&
    actionPendind.includes(action.type.replace('_FULFILLED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_FULFILLED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_FULFILLED', '')), 1);
  }

  if (
    action.type.includes('_REJECTED') &&
    actionPendind.includes(action.type.replace('_REJECTED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_REJECTED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_REJECTED', '')), 1);
  }

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_SUPPLIERGLOSA_LIST_EXPORT):
    case REQUEST(ACTION_TYPES.FETCH_SUPPLIERGLOSA_LIST):
    case REQUEST(ACTION_TYPES.FETCH_SUPPLIERGLOSA):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_SUPPLIERGLOSA):
    case REQUEST(ACTION_TYPES.UPDATE_SUPPLIERGLOSA):
    case REQUEST(ACTION_TYPES.CREATE_SUPPLIERGLOSA_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.UPDATE_SUPPLIERGLOSA_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.DELETE_SUPPLIERGLOSA):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_SUPPLIERGLOSA_LIST_EXPORT):
    case FAILURE(ACTION_TYPES.FETCH_SUPPLIERGLOSA_LIST):
    case FAILURE(ACTION_TYPES.FETCH_SUPPLIERGLOSA):
    case FAILURE(ACTION_TYPES.CREATE_SUPPLIERGLOSA):
    case FAILURE(ACTION_TYPES.UPDATE_SUPPLIERGLOSA):
    case FAILURE(ACTION_TYPES.CREATE_SUPPLIERGLOSA_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.UPDATE_SUPPLIERGLOSA_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.DELETE_SUPPLIERGLOSA):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_SUPPLIERGLOSA_LIST):
      action.payload.data = action.payload.data.map(v => {
        return { ...v, observation: v.observation ? Buffer.from(v.observation).toString() : v.observation };
      });

      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_SUPPLIERGLOSA_OFFSET):
      if (action.payload.data && action.payload.data.length > 0) {
        return {
          ...state,
          loading: false,
          entity: convertEntityBuffers(action.payload.data[0]),
        };
      }
      return {
        ...state,
        loading: false,
      };

    case SUCCESS(ACTION_TYPES.FETCH_SUPPLIERGLOSA):
      return {
        ...state,
        loading: false,
        entity: convertEntityBuffers(action.payload.data),
      };
    case SUCCESS(ACTION_TYPES.CREATE_SUPPLIERGLOSA):
    case SUCCESS(ACTION_TYPES.UPDATE_SUPPLIERGLOSA):
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_SUPPLIERGLOSA_AND_GO_LIST):
    case SUCCESS(ACTION_TYPES.UPDATE_SUPPLIERGLOSA_AND_GO_LIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_SUPPLIERGLOSA):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.SET_BLOB: {
      const { name, data, contentType, fileName } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [name + 'Base64']: data,
          [name + 'ContentType']: contentType,
          [name + 'FileName']: fileName,
        },
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };

    case ACTION_TYPES.SHOW_MODAL: {
      const openModal = state.openModal;
      openModal[action.payload.type] = action.payload.show;
      return {
        ...state,
        openModal,
      };
    }
    default:
      return state;
  }
};

const apiUrl = BASE_API_VERSION_PATH + 'supplier-glosas';

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

// Actions

// Actions
export type ICrudGetAllActionSupplierGlosa<T> = (
  typeGlosa?: any,
  status?: any,
  extraFilters?: {},
  page?: number,
  size?: number,
  sort?: string,
  selectFields?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getEntitiesFilter = (filters?, page?, size?, sort?, selectFields = '') => {
  const requestUrl = `${apiUrl}?page=${page >= 0 ? page : 0}&size=${size >= 0 ? size : 20}&sort=${sort ? sort : 'id,asc'}&`;
  const requestFilters = filters.map(v => v.column + '.' + v.operation + '=' + v.value).join('&');
  return {
    type: ACTION_TYPES.FETCH_SUPPLIERGLOSA_LIST,
    payload: axios.get<ISupplierGlosa>(`${requestUrl}${requestFilters}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getAllEntities = (filters?, sort?, selectFields = '') => {
  if (
    (actualState.totalItems > 0 && actualState.entities.length === actualState.totalItems) ||
    actionPendind.includes(ACTION_TYPES.FETCH_SUPPLIERGLOSA_LIST)
  ) {
    return {
      type: ACTION_TYPES.FETCH_SUPPLIERGLOSA_LIST + '_FROM_CACHE',
      payload: new Promise(function (resolve, reject) {
        resolve('done');
      }),
    };
  }

  const idsRequest = filters && typeof filters['ids'] !== 'undefined' && filters['ids'] ? `id.in=${filters['ids'].join(',')}&` : '';
  const typeGlosaRequest =
    filters && typeof filters['typeGlosa'] !== 'undefined' && filters['typeGlosa'] ? `typeGlosa.contains=${filters['typeGlosa']}&` : '';
  const statusRequest =
    filters && typeof filters['status'] !== 'undefined' && filters['status'] ? `status.contains=${filters['status']}&` : '';

  const requestUrl = `${apiUrl}${`?page=${0}&size=${1000000}&sort=${sort ? sort : 'id,asc'}&`}`;
  return {
    type: ACTION_TYPES.FETCH_SUPPLIERGLOSA_LIST,
    payload: axios.get<ISupplierGlosa>(`${requestUrl}${idsRequest}${typeGlosaRequest}${statusRequest}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntities: ICrudGetAllActionSupplierGlosa<ISupplierGlosa> = (
  typeGlosa,
  status,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const typeGlosaRequest = typeGlosa
    ? Array.isArray(status)
      ? `typeGlosa.in=${typeGlosa.join(',')}&`
      : `typeGlosa.equals=${typeGlosa}&`
    : '';
  const statusRequest = status ? (Array.isArray(status) ? `status.in=${status.join(',')}&` : `status.equals=${status}&`) : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_SUPPLIERGLOSA_LIST,
    payload: axios.get<ISupplierGlosa>(`${requestUrl}${extraFiltersRequest}${typeGlosaRequest}${statusRequest}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntityOffset: any = (typeGlosa, status, extraFilters, offset, sort, selectFields = '') => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const typeGlosaRequest = typeGlosa
    ? Array.isArray(status)
      ? `typeGlosa.in=${typeGlosa.join(',')}&`
      : `typeGlosa.equals=${typeGlosa}&`
    : '';
  const statusRequest = status ? (Array.isArray(status) ? `status.in=${status.join(',')}&` : `status.equals=${status}&`) : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${offset}&size=${1}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_SUPPLIERGLOSA_OFFSET,
    payload: axios.get<ISupplierGlosa>(`${requestUrl}${extraFiltersRequest}${typeGlosaRequest}${statusRequest}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntity: ICrudGetAction<ISupplierGlosa> = (id, selectFields = '') => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_SUPPLIERGLOSA,
    payload: axios.get<ISupplierGlosa>(requestUrl, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntitiesExport: ICrudGetAllActionSupplierGlosa<ISupplierGlosa> = (
  typeGlosa,
  status,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const typeGlosaRequest = typeGlosa
    ? Array.isArray(status)
      ? `typeGlosa.in=${typeGlosa.join(',')}&`
      : `typeGlosa.equals=${typeGlosa}&`
    : '';
  const statusRequest = status ? (Array.isArray(status) ? `status.in=${status.join(',')}&` : `status.equals=${status}&`) : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_SUPPLIERGLOSA_LIST,
    payload: axios.get<ISupplierGlosa>(`${requestUrl}${extraFiltersRequest}${typeGlosaRequest}${statusRequest}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const createEntity: ICrudPutAction<ISupplierGlosa> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.CREATE_SUPPLIERGLOSA_AND_GO_LIST
        : ACTION_TYPES.CREATE_SUPPLIERGLOSA,
    payload: axios.post(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const updateEntity: ICrudPutAction<ISupplierGlosa> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.UPDATE_SUPPLIERGLOSA_AND_GO_LIST
        : ACTION_TYPES.UPDATE_SUPPLIERGLOSA,
    payload: axios.put(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const deleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_SUPPLIERGLOSA,
    payload: axios.delete(requestUrl),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const setBlob = (name, data, contentType?, fileName?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType,
    fileName,
  },
});

export const showModal = view => ({
  payload: { type: view, show: true },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const hideModal = view => ({
  payload: { type: view, show: false },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const getUrlBack = (location): string => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  let urlBack = _urlBase.searchParams.get('urlBack') || '';
  urlBack = urlBack.trim();
  urlBack = urlBack.startsWith('/') ? urlBack.slice(1) : urlBack;
  urlBack = urlBack.endsWith('/') ? urlBack.slice(0, -1) : urlBack;
  return urlBack ? '/' + urlBack + '/' : '';
};

export const getSupplierGlosaState = (location): ISupplierGlosaBaseState => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  const extraFilters = jsonParse(_urlBase.searchParams.get('extraFilters') || '{}');
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;

  const typeGlosa = _urlBase.searchParams.get('typeGlosa') || '';
  const status = _urlBase.searchParams.get('status') || '';
  return {
    baseFilters,
    extraFilters,
    offset,
    typeGlosa,
    status,
  };
};

export const getEntityFiltersURL = (state, offset = null) => {
  return (
    'baseFilters=' +
    state.baseFilters +
    '&extraFilters=' +
    encodeURI(JSON.stringify(state.extraFilters)) +
    '&page=' +
    state.activePage +
    '&' +
    'size=' +
    state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    state.sort +
    ',' +
    state.order +
    '&' +
    (state.typeGlosa ? 'typeGlosa=' + state.typeGlosa + '&' : '') +
    (state.status ? 'status=' + state.status + '&' : '') +
    ''
  );
};
