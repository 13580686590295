/* eslint complexity: ["error", 500] */
import axios from 'axios';

import { IPaginationBaseState, IPayloadResult } from 'react-jhipster';

import { IPayload } from 'react-jhipster/src/type/redux-action.type';

import { BASE_API_VERSION_PATH } from 'app/config/constants';

import { cleanEntity, jsonParse } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ICompany, defaultValue } from 'app/shared/model/company.model';

export declare type ICrudPutAction<T> = (data?: T, listFiltersPage?) => IPayload<T> | IPayloadResult<T>;
export declare type ICrudGetAction<T> = (id: string | number, selectFields?: string) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const ACTION_TYPES = {
  FETCH_COMPANY_LIST_EXPORT: 'company/FETCH_COMPANY_LIST_EXPORT',
  FETCH_COMPANY_LIST: 'company/FETCH_COMPANY_LIST',
  FETCH_COMPANY: 'company/FETCH_COMPANY',
  FETCH_COMPANY_OFFSET: 'company/FETCH_COMPANY_OFFSET',
  CREATE_COMPANY: 'company/CREATE_COMPANY',
  UPDATE_COMPANY: 'company/UPDATE_COMPANY',
  CREATE_COMPANY_AND_GO_LIST: 'company/CREATE_COMPANY_AND_GO_LIST',
  UPDATE_COMPANY_AND_GO_LIST: 'company/UPDATE_COMPANY_AND_GO_LIST',
  DELETE_COMPANY: 'company/DELETE_COMPANY',
  RESET: 'company/RESET',
  SHOW_MODAL: 'company/SHOW_MODAL',
};

const initialState = {
  loading: false,
  openModal: {
    list: false,
    view: false,
    detail: false,
  },
  errorMessage: null,
  entities: [] as ReadonlyArray<ICompany>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const actionPendind = [];
let actualState = initialState;

export const convertEntityBuffers = entity => ({
  ...entity,
});

export type CompanyState = Readonly<typeof initialState>;

export interface ICompanyBaseState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  listCheckedID?: Array<number>;
  isCheckedAll?: boolean;
  urlBack?: string;
  extraFilters?: any;
  baseFilters: any;
  offset: number;
  socialReason?: any;
  fantasyName?: any;
  cnpj?: any;
  zipCode?: any;
}

export interface IFieldsBase extends ICompanyBaseState, IPaginationBaseState {}
export interface ICompanyUpdateState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  urlBack?: string;
  functionEmbebed?: any[];
  fieldsBase: IFieldsBase;
  socialReasonSelectValue?: any;
  socialReasonStartSelectOptions?: any;
  fantasyNameSelectValue?: any;
  fantasyNameStartSelectOptions?: any;
  cnpjSelectValue?: any;
  cnpjStartSelectOptions?: any;
  zipCodeSelectValue?: any;
  zipCodeStartSelectOptions?: any;
  streetSelectValue?: any;
  streetStartSelectOptions?: any;
  complementSelectValue?: any;
  complementStartSelectOptions?: any;
  numberSelectValue?: any;
  numberStartSelectOptions?: any;
  neighborhoodSelectValue?: any;
  neighborhoodStartSelectOptions?: any;
  citySelectValue?: any;
  cityStartSelectOptions?: any;
  ufSelectValue?: any;
  ufStartSelectOptions?: any;
  regANSSelectValue?: any;
  regANSStartSelectOptions?: any;
  regCNESSelectValue?: any;
  regCNESStartSelectOptions?: any;
  technicalManagerSelectValue?: any;
  technicalManagerStartSelectOptions?: any;
  technicalManagerNurseSelectValue?: any;
  technicalManagerNurseStartSelectOptions?: any;
  inscriptionSelectValue?: any;
  inscriptionStartSelectOptions?: any;
  councilCodeSelectValue?: any;
  councilCodeStartSelectOptions?: any;
  ufCodeSelectValue?: any;
  ufCodeStartSelectOptions?: any;
  cboCodeSelectValue?: any;
  cboCodeStartSelectOptions?: any;
  cepRequestBairro?: any;
  cepRequestCep?: any;
  cepRequestComplemento?: any;
  cepRequestGia?: any;
  cepRequestIbge?: any;
  cepRequestLocalidade?: any;
  cepRequestLogradouro?: any;
  cepRequestUf?: any;
  cepRequestUnidade?: any;

  isNew: boolean;
}

// Reducer

export default (state: CompanyState = initialState, action): CompanyState => {
  actualState = state;
  if (
    action.type.includes('_PENDING') &&
    !actionPendind.includes(action.type.replace('_PENDING', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_PENDING', ''))
  ) {
    actionPendind.push(action.type.replace('_PENDING', ''));
  }

  if (
    action.type.includes('_FULFILLED') &&
    actionPendind.includes(action.type.replace('_FULFILLED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_FULFILLED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_FULFILLED', '')), 1);
  }

  if (
    action.type.includes('_REJECTED') &&
    actionPendind.includes(action.type.replace('_REJECTED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_REJECTED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_REJECTED', '')), 1);
  }

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_COMPANY_LIST_EXPORT):
    case REQUEST(ACTION_TYPES.FETCH_COMPANY_LIST):
    case REQUEST(ACTION_TYPES.FETCH_COMPANY):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_COMPANY):
    case REQUEST(ACTION_TYPES.UPDATE_COMPANY):
    case REQUEST(ACTION_TYPES.CREATE_COMPANY_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.UPDATE_COMPANY_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.DELETE_COMPANY):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_COMPANY_LIST_EXPORT):
    case FAILURE(ACTION_TYPES.FETCH_COMPANY_LIST):
    case FAILURE(ACTION_TYPES.FETCH_COMPANY):
    case FAILURE(ACTION_TYPES.CREATE_COMPANY):
    case FAILURE(ACTION_TYPES.UPDATE_COMPANY):
    case FAILURE(ACTION_TYPES.CREATE_COMPANY_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.UPDATE_COMPANY_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.DELETE_COMPANY):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_COMPANY_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_COMPANY_OFFSET):
      if (action.payload.data && action.payload.data.length > 0) {
        return {
          ...state,
          loading: false,
          entity: convertEntityBuffers(action.payload.data[0]),
        };
      }
      return {
        ...state,
        loading: false,
      };

    case SUCCESS(ACTION_TYPES.FETCH_COMPANY):
      return {
        ...state,
        loading: false,
        entity: convertEntityBuffers(action.payload.data),
      };
    case SUCCESS(ACTION_TYPES.CREATE_COMPANY):
    case SUCCESS(ACTION_TYPES.UPDATE_COMPANY):
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_COMPANY_AND_GO_LIST):
    case SUCCESS(ACTION_TYPES.UPDATE_COMPANY_AND_GO_LIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_COMPANY):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };

    case ACTION_TYPES.SHOW_MODAL: {
      const openModal = state.openModal;
      openModal[action.payload.type] = action.payload.show;
      return {
        ...state,
        openModal,
      };
    }
    default:
      return state;
  }
};

const apiUrl = BASE_API_VERSION_PATH + 'companies';

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

// Actions

// Actions
export type ICrudGetAllActionCompany<T> = (
  socialReason?: any,
  fantasyName?: any,
  cnpj?: any,
  zipCode?: any,
  extraFilters?: {},
  page?: number,
  size?: number,
  sort?: string,
  selectFields?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getEntitiesFilter = (filters?, page?, size?, sort?, selectFields = '') => {
  const requestUrl = `${apiUrl}?page=${page >= 0 ? page : 0}&size=${size >= 0 ? size : 20}&sort=${sort ? sort : 'id,asc'}&`;
  const requestFilters = filters.map(v => v.column + '.' + v.operation + '=' + v.value).join('&');
  return {
    type: ACTION_TYPES.FETCH_COMPANY_LIST,
    payload: axios.get<ICompany>(`${requestUrl}${requestFilters}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getAllEntities = (filters?, sort?, selectFields = '') => {
  if (
    (actualState.totalItems > 0 && actualState.entities.length === actualState.totalItems) ||
    actionPendind.includes(ACTION_TYPES.FETCH_COMPANY_LIST)
  ) {
    return {
      type: ACTION_TYPES.FETCH_COMPANY_LIST + '_FROM_CACHE',
      payload: new Promise(function (resolve, reject) {
        resolve('done');
      }),
    };
  }

  const idsRequest = filters && typeof filters['ids'] !== 'undefined' && filters['ids'] ? `id.in=${filters['ids'].join(',')}&` : '';
  const socialReasonRequest =
    filters && typeof filters['socialReason'] !== 'undefined' && filters['socialReason']
      ? `socialReason.contains=${filters['socialReason']}&`
      : '';
  const fantasyNameRequest =
    filters && typeof filters['fantasyName'] !== 'undefined' && filters['fantasyName']
      ? `fantasyName.contains=${filters['fantasyName']}&`
      : '';
  const cnpjRequest = filters && typeof filters['cnpj'] !== 'undefined' && filters['cnpj'] ? `cnpj.contains=${filters['cnpj']}&` : '';
  const zipCodeRequest =
    filters && typeof filters['zipCode'] !== 'undefined' && filters['zipCode'] ? `zipCode.contains=${filters['zipCode']}&` : '';

  const requestUrl = `${apiUrl}${`?page=${0}&size=${1000000}&sort=${sort ? sort : 'id,asc'}&`}`;
  return {
    type: ACTION_TYPES.FETCH_COMPANY_LIST,
    payload: axios.get<ICompany>(`${requestUrl}${idsRequest}${socialReasonRequest}${fantasyNameRequest}${cnpjRequest}${zipCodeRequest}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntities: ICrudGetAllActionCompany<ICompany> = (
  socialReason,
  fantasyName,
  cnpj,
  zipCode,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const socialReasonRequest = socialReason ? `socialReason.contains=${socialReason}&` : '';
  const fantasyNameRequest = fantasyName ? `fantasyName.contains=${fantasyName}&` : '';
  const cnpjRequest = cnpj ? `cnpj.contains=${cnpj}&` : '';
  const zipCodeRequest = zipCode ? `zipCode.contains=${zipCode}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_COMPANY_LIST,
    payload: axios.get<ICompany>(
      `${requestUrl}${extraFiltersRequest}${socialReasonRequest}${fantasyNameRequest}${cnpjRequest}${zipCodeRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntityOffset: any = (socialReason, fantasyName, cnpj, zipCode, extraFilters, offset, sort, selectFields = '') => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const socialReasonRequest = socialReason ? `socialReason.contains=${socialReason}&` : '';
  const fantasyNameRequest = fantasyName ? `fantasyName.contains=${fantasyName}&` : '';
  const cnpjRequest = cnpj ? `cnpj.contains=${cnpj}&` : '';
  const zipCodeRequest = zipCode ? `zipCode.contains=${zipCode}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${offset}&size=${1}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_COMPANY_OFFSET,
    payload: axios.get<ICompany>(
      `${requestUrl}${extraFiltersRequest}${socialReasonRequest}${fantasyNameRequest}${cnpjRequest}${zipCodeRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntity: ICrudGetAction<ICompany> = (id, selectFields = '') => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_COMPANY,
    payload: axios.get<ICompany>(requestUrl, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntitiesExport: ICrudGetAllActionCompany<ICompany> = (
  socialReason,
  fantasyName,
  cnpj,
  zipCode,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const socialReasonRequest = socialReason
    ? Array.isArray(status)
      ? `socialReason.in=${socialReason.join(',')}&`
      : `socialReason.contains=${socialReason}&`
    : '';
  const fantasyNameRequest = fantasyName
    ? Array.isArray(status)
      ? `fantasyName.in=${fantasyName.join(',')}&`
      : `fantasyName.contains=${fantasyName}&`
    : '';
  const cnpjRequest = cnpj ? (Array.isArray(status) ? `cnpj.in=${cnpj.join(',')}&` : `cnpj.contains=${cnpj}&`) : '';
  const zipCodeRequest = zipCode ? (Array.isArray(status) ? `zipCode.in=${zipCode.join(',')}&` : `zipCode.contains=${zipCode}&`) : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_COMPANY_LIST,
    payload: axios.get<ICompany>(
      `${requestUrl}${extraFiltersRequest}${socialReasonRequest}${fantasyNameRequest}${cnpjRequest}${zipCodeRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const createEntity: ICrudPutAction<ICompany> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false ? ACTION_TYPES.CREATE_COMPANY_AND_GO_LIST : ACTION_TYPES.CREATE_COMPANY,
    payload: axios.post(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const updateEntity: ICrudPutAction<ICompany> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false ? ACTION_TYPES.UPDATE_COMPANY_AND_GO_LIST : ACTION_TYPES.UPDATE_COMPANY,
    payload: axios.put(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const deleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_COMPANY,
    payload: axios.delete(requestUrl),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const showModal = view => ({
  payload: { type: view, show: true },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const hideModal = view => ({
  payload: { type: view, show: false },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const getUrlBack = (location): string => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  let urlBack = _urlBase.searchParams.get('urlBack') || '';
  urlBack = urlBack.trim();
  urlBack = urlBack.startsWith('/') ? urlBack.slice(1) : urlBack;
  urlBack = urlBack.endsWith('/') ? urlBack.slice(0, -1) : urlBack;
  return urlBack ? '/' + urlBack + '/' : '';
};

export const getCompanyState = (location): ICompanyBaseState => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  const extraFilters = jsonParse(_urlBase.searchParams.get('extraFilters') || '{}');
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;

  const socialReason = _urlBase.searchParams.get('socialReason') || '';
  const fantasyName = _urlBase.searchParams.get('fantasyName') || '';
  const cnpj = _urlBase.searchParams.get('cnpj') || '';
  const zipCode = _urlBase.searchParams.get('zipCode') || '';
  return {
    baseFilters,
    extraFilters,
    offset,
    socialReason,
    fantasyName,
    cnpj,
    zipCode,
  };
};

export const getEntityFiltersURL = (state, offset = null) => {
  return (
    'baseFilters=' +
    state.baseFilters +
    '&extraFilters=' +
    encodeURI(JSON.stringify(state.extraFilters)) +
    '&page=' +
    state.activePage +
    '&' +
    'size=' +
    state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    state.sort +
    ',' +
    state.order +
    '&' +
    (state.socialReason ? 'socialReason=' + state.socialReason + '&' : '') +
    (state.fantasyName ? 'fantasyName=' + state.fantasyName + '&' : '') +
    (state.cnpj ? 'cnpj=' + state.cnpj + '&' : '') +
    (state.zipCode ? 'zipCode=' + state.zipCode + '&' : '') +
    ''
  );
};
