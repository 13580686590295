/* eslint-disable no-console */
/* eslint complexity: ["error", 300] */

import { connect } from 'react-redux';

import { getFilterFromSelect, getFormFromSelect } from 'app/shared/util/entity-utils';

import { translate } from 'app/config/translate-component';

import { ClComItem, mapStateToProps, mapDispatchToProps } from '../cl-com-item';
import { IRootState } from 'app/shared/reducers';

import { toast } from 'react-toastify';

export interface IExtendedState {
  dumystate?: any;
}

export interface IExtendedProps {
  entityEmbebedPai?: any;
}

export class ClComItemExtended extends ClComItem {
  saveEntity = (event: any, errors: any, values: any, isNew: any) => {
    const selectFieldsList = [
      'materialMedicine.medicineMaterialType',
      'materialMedicine.categories.name',
      'materialMedicine.name',
      'saleValue',
      'clComTable.name',
    ];

    if (errors.length === 0) {
      const { clComItemEntity } = this.props;
      const _entity = {
        ...clComItemEntity,
        // ...values,

        clComTable: getFormFromSelect(this.state.clComTableSelectValue, 'many-to-one'), // many-to-one - otherSide

        levelGroup: getFormFromSelect(this.state.levelGroupSelectValue, 'many-to-one'), // many-to-one - otherSide

        category: getFormFromSelect(this.state.categorySelectValue, 'many-to-one'), // many-to-one - otherSide

        materialMedicine: getFormFromSelect(this.state.materialMedicineSelectValue, 'many-to-one'), // many-to-one - otherSide

        comercialTableType: this.state.comercialTableTypeSelectValue === null ? null : this.state.comercialTableTypeSelectValue,
        table: this.state.tableSelectValue === null ? null : this.state.tableSelectValue,
        tussCode: this.state.tussCodeSelectValue,
        tissCode: this.state.tissCodeSelectValue,
        tableCode: this.state.tableCodeSelectValue,
        expenseCode: this.state.expenseCodeSelectValue,
        saleValue: this.state.saleValueSelectValue,
        percent: this.state.percentSelectValue,
        inflator: this.state.inflatorSelectValue,
        deflator: this.state.deflatorSelectValue,
        rentalValue: this.state.rentalValueSelectValue,
        observation: this.state.observationSelectValue,
      };
      const entity = _entity;

      const {
        comercialTableType,
        table,
        tussCode,
        tissCode,
        tableCode,
        expenseCode,
        saleValue,
        percent,
        inflator,
        deflator,
        rentalValue,
        observation,
        clComTableId,
        levelGroupId,
        categoryId,
        materialMedicineId,
        extraFilters,
        activePage,
        itemsPerPage,
        sort,
        order,
      } = this.state.fieldsBase;

      if (isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              comercialTableType,
              table,
              tussCode,
              tissCode,
              tableCode,
              expenseCode,
              saleValue,
              percent,
              inflator,
              deflator,
              rentalValue,
              observation,
              getFilterFromSelect(clComTableId, 'many-to-one'),
              getFilterFromSelect(levelGroupId, 'many-to-one'),
              getFilterFromSelect(categoryId, 'many-to-one'),
              getFilterFromSelect(materialMedicineId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              comercialTableType,
              table,
              tussCode,
              tissCode,
              tableCode,
              expenseCode,
              saleValue,
              percent,
              inflator,
              deflator,
              rentalValue,
              observation,
              getFilterFromSelect(clComTableId, 'many-to-one'),
              getFilterFromSelect(levelGroupId, 'many-to-one'),
              getFilterFromSelect(categoryId, 'many-to-one'),
              getFilterFromSelect(materialMedicineId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
      this.setState({ showModalForm: false });
      this.setState({ showModalEdit: null });
    } else {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.specialty.' + errors[0]) }));
    }
  };
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;

export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(ClComItemExtended);
