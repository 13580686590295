import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { Translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity, IAuditEntityBaseState, getAuditEntityState } from './audit-entity.reducer';

export interface IAuditEntityState {
  fieldsBase: IAuditEntityBaseState;
}

export interface IAuditEntityDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class AuditEntityDetail extends React.Component<IAuditEntityDetailProps, IAuditEntityState> {
  constructor(props: Readonly<IAuditEntityDetailProps>) {
    super(props);
    this.state = {
      ...this.state,
      fieldsBase: {
        ...getAuditEntityState(this.props.location),
      },
    };
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = '_back=1' + (offset !== null ? '&offset=' + offset : '');
    Object.keys(fieldsBase).map(key => {
      url += '&' + key + '=' + fieldsBase[key];
    });
    return url;
  };

  UNSAFE_componentWillMount() {
    this.props.getEntity(this.props.match.params['id']);
  }

  renderHeader() {
    const { auditEntityEntity } = this.props;
    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">Audit Entities</span>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={'/audit-entity'}>Audit Entities</Link>
          </li>
          <li className="breadcrumb-item active">Audit Entities details</li>
        </ol>
      </>
    );
  }

  renderBody() {
    const { auditEntityEntity } = this.props;
    return (
      <>
        <Row>
          <Col md="8">
            <h2>
              <Translate contentKey="generadorApp.auditEntity.detail.title">AuditEntity</Translate>[<b>{auditEntityEntity.id}</b>]
            </h2>
            <Row className="jh-entity-details">
              <Col md="12">
                <Row>
                  <Col md="3">
                    <dt>
                      <span id="entityId">
                        <Translate contentKey="generadorApp.auditEntity.entityId">Entity Id</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col md="9">
                    <dd>{auditEntityEntity.entityId}</dd>
                  </Col>
                </Row>
              </Col>

              <Col md="12">
                <Row>
                  <Col md="3">
                    <dt>
                      <span id="entityType">
                        <Translate contentKey="generadorApp.auditEntity.entityType">Entity Type</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col md="9">
                    <dd>{auditEntityEntity.entityType}</dd>
                  </Col>
                </Row>
              </Col>

              <Col md="12">
                <Row>
                  <Col md="3">
                    <dt>
                      <span id="action">
                        <Translate contentKey="generadorApp.auditEntity.action">Action</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col md="9">
                    <dd>{auditEntityEntity.action}</dd>
                  </Col>
                </Row>
              </Col>

              <Col md="12">
                <Row>
                  <Col md="3">
                    <dt>
                      <span id="entityValue">
                        <Translate contentKey="generadorApp.auditEntity.entityValue">Entity Value</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col md="9">
                    <dd>{auditEntityEntity.entityValue}</dd>
                  </Col>
                </Row>
              </Col>

              <Col md="12">
                <Row>
                  <Col md="3">
                    <dt>
                      <span id="commitVersion">
                        <Translate contentKey="generadorApp.auditEntity.commitVersion">Commit Version</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col md="9">
                    <dd>{auditEntityEntity.commitVersion}</dd>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Button tag={Link} to={'/audit-entity?' + this.getFiltersURL()} replace color="info">
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="generadorApp.auditEntity.btnBack">Back</Translate>
              </span>
            </Button>
            &nbsp;
            <Button tag={Link} to={`/audit-entity/${auditEntityEntity.id}/edit?+${this.getFiltersURL()}`} replace color="primary">
              <FontAwesomeIcon icon="pencil-alt" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="generadorApp.auditEntity.btnEdit">Edit</Translate>
              </span>
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { auditEntityEntity } = this.props;
    return (
      <div>
        <Panel>
          <PanelHeader>{this.renderHeader()}</PanelHeader>
          <PanelBody>{this.renderBody()}</PanelBody>
        </Panel>
      </div>
    );
  }
}

export const mapStateToProps = ({ auditEntity, ...storeState }: IRootState) => ({
  auditEntityEntity: auditEntity.entity,
});

export const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AuditEntityDetail);
