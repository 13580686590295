/* eslint complexity: ["error", 500] */
import React from 'react';
import { connect } from 'react-redux';

import { PatientDoctorPrescription, mapStateToProps, mapDispatchToProps } from '../patient-doctor-prescription';
import { createEntity, getEntity, getEntityFiltersURL } from '../patient-doctor-prescription.reducer';
import { IRootState } from 'app/shared/reducers';

export interface IExtendedState {
  dumystate?: any;
}
export interface IExtendedProps {
  entityEmbebedPai?: any;
}

export class PatientDoctorPrescriptionExtended extends PatientDoctorPrescription {
  UNSAFE_componentWillMount() {
    const idPatient = this.props.match.params['idPatient'];
    new Promise(resolve => {
      resolve(this.props.getEntity(idPatient));
    }).then(resultEntity => {
      if (resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']) {
        this.props.history.push(`/patient/${idPatient}/patient-doctor-prescription/${idPatient}/edit?${getEntityFiltersURL(this.state)}`);
      } else {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(
              {
                id: idPatient,
                patient: { id: idPatient },
              },
              { reloadList: false }
            )
          );
        }).then(v => {
          this.props.history.push(`/patient/${idPatient}/patient-doctor-prescription/${idPatient}/edit?${getEntityFiltersURL(this.state)}`);
        });
      }
    });
  }




  renderHeader() {
    return <> {super.renderHeader()} </>;
  }

  renderFilter() {
    return <> {super.renderFilter()} </>;
  }

  renderTable() {
    return <> {super.renderTable()} </>;
  }

  renderFooter() {
    return <> {super.renderFooter()} </>;
  }

  render() {
    return <> </>;
  }
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
  createEntity,
  getEntity,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;

export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(PatientDoctorPrescriptionExtended);
