/* eslint no-console: off */
import pick from 'lodash/pick';
import axios from 'axios';

export const getFilterFromSelect = (filterValue, type?) => {
  if (!filterValue) {
    return '';
  } else if (!!filterValue && filterValue.constructor === Object) {
    return filterValue.value;
  } else if (!!filterValue && filterValue.constructor === Array) {
    return filterValue.map(v => v.value).join(',');
  }
  return '';
};

export const getValueRecursive = (entity, fieldPath, returnOnNull = null, returnJoin = ', ') => {
  const field = fieldPath.slice(0, 1).pop();

  if (!entity) return returnOnNull;
  if (!fieldPath) return returnOnNull;
  if (fieldPath.length === 0) return returnOnNull;

  if (Array.isArray(entity)) {
    if (fieldPath.length === 1) {
      const _result = entity.map(enti => enti[field]).join(returnJoin);
      return _result;
    }

    const _result1 = entity
      .map(enti => {
        return getValueRecursive(enti[field], fieldPath.slice(1), returnOnNull, returnJoin);
      })
      .join(', ');
    // console.info(_result1);
    return _result1;
  } else {
    if (fieldPath.length === 1) {
      const result = entity && entity[field] ? entity[field] : returnOnNull;
      // console.info(result);
      return result;
    }
    return entity[field] ? getValueRecursive(entity[field], fieldPath.slice(1), returnOnNull, returnJoin) : returnOnNull;
  }
};

export const jsonParse = (raw, returnObject = true) => {
  try {
    return JSON.parse(raw);
  } catch (err) {
    if (returnObject) {
      return {};
    }
    return [];
  }
};

export const getFormFromSelect = (filterValue, type) => {
  if (!!filterValue && filterValue.constructor === Object) {
    return { ...filterValue, id: filterValue.value };
  } else if (!!filterValue && filterValue.constructor === Array) {
    return filterValue.map(v => ({ ...v, id: v.value }));
  }
  return ['many-to-many', 'one-to-many'].includes(type) ? [] : null;
};

export const getListAxios: any = async (enpoint, filters, page, size, sort, selectFields = '') => {
  filters['page'] = page;
  filters['size'] = size;
  filters['sort'] = sort;
  const filtersQuery = Object.keys(filters).reduce(function (str, key, i) {
    const delimiter = i === 0 ? '?' : '&';
    key = encodeURIComponent(key);
    const val = encodeURIComponent(filters[key]);
    return [str, delimiter, key, '=', val].join('');
  }, '');
  const result = await axios.get('/api/' + enpoint + filtersQuery, {
    headers: {
      'Select-Fields': selectFields,
    },
  });
  return result ? result : [];
};
export const showFieldsSelectAsync = (entity, fields, returnJoin: any = ', ') => {
  if (returnJoin !== false && returnJoin !== null) {
    return fields
      .split(';')
      .map(field => {
        const _result = getValueRecursive(entity, field.split('.'), null);
        return _result;
      })
      .join(returnJoin);
  } else if (returnJoin === null) {
    const _arrayResult = fields.split(';').map(field => {
      const _result = getValueRecursive(entity, field.split('.'), null);
      return _result;
    });
    if (Array.isArray(_arrayResult)) {
      return _arrayResult && _arrayResult.length > 0 && _arrayResult[0] ? (_arrayResult[0] + '').split(', ') : [];
    }
    return _arrayResult ? [_arrayResult] : [];
  }
  return fields
    .split(';')
    .map(field => {
      const _result = getValueRecursive(entity, field.split('.'), null);
      return _result;
    })
    .pop();
};

export const sleepFunction = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const checkAnyValueFields = (entity, fields, value, equals = true) => {
  const values = showFieldsSelectAsync(entity, fields, '*+*+*');
  if (values) {
    return equals === values.split('*+*+*').includes(value + '');
  }
  return false;
};

/**
 * Removes fields with an 'id' field that equals ''.
 * This function was created to prevent entities to be sent to
 * the server with relationship fields with empty an empty id and thus
 * resulting in a 500.
 *
 * @param entity Object to clean.
 */
export const cleanEntity = entity => {
  const keysToKeep = Object.keys(entity).filter(k => !(entity[k] instanceof Object) || (entity[k]['id'] !== '' && entity[k]['id'] !== -1));

  return pick(entity, keysToKeep);
};
/**
 * Removes fields with an 'id' field that equals ''.
 * This function was created to prevent entities to be sent to
 * the server with relationship fields with empty an empty id and thus
 * resulting in a 500.
 *
 * @param entity Object to clean.
 */
export const viacepRequest = (cpf, fn) => {
  fetch('https://viacep.com.br/ws/' + cpf + '/json/')
    .then(res => res.json())
    .then(
      result => {
        fn({
          cepRequestBairro: result.bairro ? result.bairro : '',
          cepRequestCep: result.cep ? result.cep : '',
          cepRequestComplemento: result.complemento ? result.complemento : '',
          cepRequestGia: result.gia ? result.gia : '',
          cepRequestIbge: result.ibge ? result.ibge : '',
          cepRequestLocalidade: result.localidade ? result.localidade : '',
          cepRequestLogradouro: result.logradouro ? result.logradouro : '',
          cepRequestUf: result.uf ? result.uf : '',
          cepRequestUnidade: result.unidade ? result.unidade : '',
        });
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      error => {
        //  this.setState({
        //    isLoaded: true,
        //    error
        //  });
      }
    );
};

/**
 * Simply map a list of element to a list a object with the element as id.
 *
 * @param idList Elements to map.
 * @returns The list of objects with mapped ids.
 */
export const mapIdList = (idList: ReadonlyArray<any>) =>
  idList.filter((entityId: any) => entityId !== '').map((entityId: any) => ({ id: entityId }));

export const quillEditorModules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'image', 'video'],
    ['clean'],
  ],
  clipboard: {
    matchVisual: false,
  },
};
export const quillEditorFormats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
];

export const prettifyXml = sourceXml => {
  const xmlDoc = new DOMParser().parseFromString(sourceXml, 'application/xml');
  const xsltDoc = new DOMParser().parseFromString(
    [
      // describes how we want to modify the XML - indent everything
      '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
      '  <xsl:strip-space elements="*"/>',
      '  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
      '    <xsl:value-of select="normalize-space(.)"/>',
      '  </xsl:template>',
      '  <xsl:template match="node()|@*">',
      '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
      '  </xsl:template>',
      '  <xsl:output indent="yes"/>',
      '</xsl:stylesheet>',
    ].join('\n'),
    'application/xml'
  );

  const xsltProcessor = new XSLTProcessor();
  xsltProcessor.importStylesheet(xsltDoc);
  const resultDoc = xsltProcessor.transformToDocument(xmlDoc);
  const resultXml = new XMLSerializer().serializeToString(resultDoc);
  return resultXml;
};
