/* eslint complexity: ["error", 500] */
import React from 'react';

import { getFilterFromSelect, getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import { connect } from 'react-redux';
import Select from 'react-select';
import SelectAsync from 'react-select/async';
import { Link, RouteComponentProps } from 'react-router-dom';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import {
  Button,
  Col,
  Row,
  Table,
  Label,
  UncontrolledCollapse,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { AvForm, div, AvInput } from 'availity-reactstrap-validation';
import { JhiItemCount } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { IRootState } from 'app/shared/reducers';
import {
  updateEntity,
  deleteEntity,
  getUrlBack,
  getSupplierState,
  ISupplierBaseState,
  getEntityFiltersURL,
  getEntities,
  reset,
} from './supplier.reducer';

import { ITEMS_PER_PAGE, IPaginationBaseState, getSortState } from 'app/shared/util/pagination.constants';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

export interface ISupplierProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}
export type ISupplierStateAux = ISupplierBaseState;

export interface ISupplierState extends ISupplierStateAux, IPaginationBaseState {
  dropdownButtons: {};
}

export class Supplier extends React.Component<ISupplierProps, ISupplierState> {
  protected myFormRef: any;

  constructor(props: ISupplierProps) {
    super(props);
    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      listCheckedID: [],
      isCheckedAll: false,
      dropdownButtons: {},
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      urlBack: getUrlBack(this.props.location),
      ...getSupplierState(this.props.location),
    };
  }

  toggle = btn => {
    const dropdownButtons = this.state.dropdownButtons;
    dropdownButtons[btn] = !dropdownButtons[btn];
    this.setState({ dropdownButtons });
  };

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({ listCheckedID: this.props.supplierList.map(supplier => supplier.id) });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({ listCheckedID: this.props.supplierList.map(supplier => supplier.id) });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  removeEntitySelected() {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  }

  saveEntityMany = () => {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  };

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  deleteEntity(supplierEntity) {
    MySwal.fire({
      title: <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>,
      html: (
        <Translate contentKey="generadorApp.supplier.delete.question" interpolate={{ id: supplierEntity.id }}>
          Are you sure you want to delete this Supplier?
        </Translate>
      ),
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#e70015',
      cancelButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="ban" />
          &nbsp; <Translate contentKey="generadorApp.supplier.btnCancel">Cancel</Translate>
        </>
      ),
      confirmButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="trash" />
          &nbsp;<Translate contentKey="generadorApp.supplier.btnDelete">Delete</Translate>
        </>
      ),
    }).then(async response => {
      if (response.isConfirmed) {
        await this.props.deleteEntity(supplierEntity.id, { reloadList: false });
        this.getEntities();
        MySwal.fire({
          title: <Translate contentKey="entity.delete.title.confirmed">Confirmed delete operation</Translate>,
          icon: 'success',
        });
      }
    });
  }

  cancelFilters = () => {
    this.setState(
      {
        groupsId: '',
        socialReason: '',
        fantasyName: '',
        cnpj: '',
        status: '',
        zipCode: '',
        city: '',
        extraFilters: {},
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
        activePage: 0,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();

    const selectFieldsList = ['fantasyName', 'groups.name', 'city', 'status'];

    const {
      groupsId,
      socialReason,
      fantasyName,
      cnpj,
      status,
      zipCode,
      city,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;
    return this.props.getEntities(
      getFilterFromSelect(groupsId, 'many-to-many'),
      socialReason,
      fantasyName,
      cnpj,
      status,
      zipCode,
      city,
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(',')
    );
  };

  updateEntity = entity => {
    const selectFieldsList = ['fantasyName', 'groups.name', 'city', 'status'];

    const {
      groupsId,
      socialReason,
      fantasyName,
      cnpj,
      status,
      zipCode,
      city,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;
    this.props.updateEntity(entity, [
      getFilterFromSelect(groupsId, 'many-to-many'),
      socialReason,
      fantasyName,
      cnpj,
      status,
      zipCode,
      city,
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(','),
    ]);
  };

  renderHeader() {
    const {
      users,
      supplierGlosas,
      supplierContacts,
      supplierStatusHistories,
      supplierFiles,
      nfes,
      supplierTables,
      pharmacyStocks,
      supplies,
      patientSuppliers,
      levelGroups,
      patients,
      supplierList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.supplier.home.title">Suppliers</Translate>
          </span>
          <Button id="togglerFilterSupplier" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.supplier.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          &nbsp;
          {hasAnyAuthority(props.userAccount, ['supplier'], 'resgister') ? (
            <Link
              to={`${match.url}/new?${getEntityFiltersURL(state)}`}
              className="btn btn-primary float-right jh-create-entity"
              id="jh-create-entity"
            >
              <FontAwesomeIcon icon="plus" />
              &nbsp;
              <Translate contentKey="generadorApp.supplier.btnNewItem">Create new</Translate>
            </Link>
          ) : (
            <></>
          )}{' '}
          &nbsp;
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.supplier.home.title">Suppliers</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    const {
      users,
      supplierGlosas,
      supplierContacts,
      supplierStatusHistories,
      supplierFiles,
      nfes,
      supplierTables,
      pharmacyStocks,
      supplies,
      patientSuppliers,
      levelGroups,
      patients,
      supplierList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <UncontrolledCollapse toggler="#togglerFilterSupplier">
          <CardBody>
            <AvForm ref={el => (this.myFormRef = el)} id="form-filter" onSubmit={this.filterEntity}>
              <div className="row mt-1 ml-3 mr-3">
                {this.state.baseFilters !== 'groups' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="supplier-groups">
                          <Translate contentKey="generadorApp.supplier.groups">Groups</Translate>
                        </Label>
                        <SelectAsync
                          id="supplier-groups"
                          isMulti
                          className={'css-select-control'}
                          name={'groups'}
                          cacheOptions
                          value={this.state.groupsId}
                          onChange={options => this.setState({ groupsId: options })}
                          defaultOptions={this.state.groupsStartFilter ? this.state.groupsStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.groupsStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.groupsStartFilter === undefined) {
                              const result = await getListAxios('level-groups', {}, 0, 100, 'name,asc', 'name');
                              this.setState({
                                groupsStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios('level-groups', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'socialReason' ? (
                  <Col md="3" className="col-filter-supplier-socialReason">
                    <Row className="mr-1 mt-1">
                      <Label id="socialReasonLabel" for="supplier-socialReason">
                        <Translate contentKey="generadorApp.supplier.socialReason">Social Reason</Translate>
                      </Label>

                      <AvInput type="text" name="socialReason" id="supplier-socialReason" value={this.state.socialReason} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'fantasyName' ? (
                  <Col md="3" className="col-filter-supplier-fantasyName">
                    <Row className="mr-1 mt-1">
                      <Label id="fantasyNameLabel" for="supplier-fantasyName">
                        <Translate contentKey="generadorApp.supplier.fantasyName">Fantasy Name</Translate>
                      </Label>

                      <AvInput type="text" name="fantasyName" id="supplier-fantasyName" value={this.state.fantasyName} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'cnpj' ? (
                  <Col md="3" className="col-filter-supplier-cnpj">
                    <Row className="mr-1 mt-1">
                      <Label id="cnpjLabel" for="supplier-cnpj">
                        <Translate contentKey="generadorApp.supplier.cnpj">Cnpj</Translate>
                      </Label>

                      <AvInput type="text" name="cnpj" id="supplier-cnpj" value={this.state.cnpj} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'status' ? (
                  <Col md="3" className="col-filter-supplier-status">
                    <Row className="mr-1 mt-1">
                      <Label id="statusLabel" for="supplier-status">
                        <Translate contentKey="generadorApp.supplier.status">Status</Translate>
                      </Label>
                      <Select
                        id="supplier-status"
                        className={'css-select-control'}
                        value={
                          this.state.status
                            ? { value: this.state.status, label: translate('generadorApp.ExtraStatus.' + this.state.status) }
                            : { value: '', label: translate('generadorApp.supplier.status') }
                        }
                        options={[
                          { value: '', label: translate('generadorApp.supplier.status') },
                          { value: 'ATIVO', label: translate('generadorApp.ExtraStatus.ATIVO') },
                          { value: 'INATIVADO', label: translate('generadorApp.ExtraStatus.INATIVADO') },
                          { value: 'AGUARDANDO', label: translate('generadorApp.ExtraStatus.AGUARDANDO') },
                        ]}
                        onChange={(options: any) => this.setState({ status: options['value'] })}
                        name="status"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'zipCode' ? (
                  <Col md="3" className="col-filter-supplier-zipCode">
                    <Row className="mr-1 mt-1">
                      <Label id="zipCodeLabel" for="supplier-zipCode">
                        <Translate contentKey="generadorApp.supplier.zipCode">Zip Code</Translate>
                      </Label>

                      <AvInput type="text" name="zipCode" id="supplier-zipCode" value={this.state.zipCode} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'city' ? (
                  <Col md="3" className="col-filter-supplier-city">
                    <Row className="mr-1 mt-1">
                      <Label id="cityLabel" for="supplier-city">
                        <Translate contentKey="generadorApp.supplier.city">City</Translate>
                      </Label>

                      <AvInput type="text" name="city" id="supplier-city" value={this.state.city} />
                    </Row>
                  </Col>
                ) : null}
              </div>

              <div className="row mb-2 mr-4 justify-content-end">
                <Button color="primary" type="submit">
                  <i className="fa fa-filter" aria-hidden={'true'}></i>&nbsp;
                  <Translate contentKey="generadorApp.supplier.btnFilter">Filter</Translate>
                </Button>
                &nbsp;
                <Button color="secondary" onClick={this.cancelFilters}>
                  <FontAwesomeIcon icon="trash-alt" />
                  &nbsp;
                  <Translate contentKey="generadorApp.supplier.btnCleanFilter">Clean Filter</Translate>
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </UncontrolledCollapse>
      </>
    );
  }

  renderTable() {
    const {
      users,
      supplierGlosas,
      supplierContacts,
      supplierStatusHistories,
      supplierFiles,
      nfes,
      supplierTables,
      pharmacyStocks,
      supplies,
      patientSuppliers,
      levelGroups,
      patients,
      supplierList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {supplierList && supplierList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null).length > 0 ? (
          <>
            <Table
              id="supplier-table-list"
              responsive
              aria-describedby="supplier-heading"
              className={'table-hover table-striped mt-4 table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.supplier.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'fantasyName' ? (
                    <th className="hand" onClick={sortFunction('fantasyName')}>
                      <Translate contentKey="generadorApp.supplier.fantasyName">Fantasy Name</Translate>
                      <FontAwesomeIcon icon={state.sort === 'fantasyName' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'groups' ? (
                    <th>
                      <Translate contentKey="generadorApp.supplier.groups">Groups</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'city' ? (
                    <th className="hand" onClick={sortFunction('city')}>
                      <Translate contentKey="generadorApp.supplier.city">City</Translate>
                      <FontAwesomeIcon icon={state.sort === 'city' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'status' ? (
                    <th className="hand" onClick={sortFunction('status')}>
                      <Translate contentKey="generadorApp.supplier.status">Status</Translate>
                      <FontAwesomeIcon icon={state.sort === 'status' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}

                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {supplierList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((supplier, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.supplier.field_id">ID</Translate>
                        </b>
                        {supplier.id}
                      </td>

                      {state.baseFilters !== 'fantasyName' ? (
                        <td id="fantasyName-cell">
                          <b className="visible-xs"> Fantasy Name </b>

                          {supplier.fantasyName}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'groups' ? (
                        <td id="groups-cell">
                          <b className="visible-xs"> Groups </b>
                          {showFieldsSelectAsync(supplier, 'groups.name', null).map((v, ikey) => (
                            <span key={ikey}> {v} </span>
                          ))}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'city' ? (
                        <td id="city-cell">
                          <b className="visible-xs"> City </b>

                          {supplier.city}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'status' ? (
                        <td id="status-cell">
                          <b className="visible-xs"> Status </b>
                          {supplier.status ? <Translate contentKey={`generadorApp.ExtraStatus.${supplier.status}`} /> : <> </>}
                        </td>
                      ) : null}

                      <td className="text-right">
                        <Dropdown isOpen={state.dropdownButtons[i]} toggle={() => this.toggle(i)}>
                          <DropdownToggle caret>
                            <Translate contentKey="generadorApp.supplier.btnActions">Actions</Translate>
                          </DropdownToggle>
                          <DropdownMenu right>
                            <DropdownItem tag={Link} to={`${match.url}/${supplier.id}`} color="info" size="sm">
                              <FontAwesomeIcon icon="eye" />

                              <span className="d-none d-md-inline">
                                <Translate contentKey="generadorApp.supplier.listButtons.view">View</Translate>
                              </span>
                            </DropdownItem>
                            <DropdownItem
                              tag={Link}
                              to={`${match.url}/${supplier.id}/edit?${getEntityFiltersURL(state)}`}
                              color="info"
                              size="sm"
                            >
                              <FontAwesomeIcon icon="pencil-alt" />

                              <span className="d-none d-md-inline">
                                <Translate contentKey="generadorApp.supplier.listButtons.edit">Edit</Translate>
                              </span>
                            </DropdownItem>
                            <DropdownItem onClick={() => this.deleteEntity(supplier)} color="danger" size="sm">
                              <FontAwesomeIcon icon="trash" />

                              <span className="d-none d-md-inline">
                                <Translate contentKey="generadorApp.supplier.listButtons.delete">Delete</Translate>
                              </span>
                            </DropdownItem>
                            <DropdownItem tag={Link} to={`/supplier/${supplier.id}/supplier-status-history`} color="info" size="sm">
                              <FontAwesomeIcon icon="check-square" />

                              <span className="d-none d-md-inline">
                                <Translate contentKey="generadorApp.supplier.listButtons.status">Status</Translate>
                              </span>
                            </DropdownItem>
                            <DropdownItem tag={Link} to={`/supplier/${supplier.id}/supplier-file`} color="info" size="sm">
                              <FontAwesomeIcon icon="file" />

                              <span className="d-none d-md-inline">
                                <Translate contentKey="generadorApp.supplier.listButtons.file">File</Translate>
                              </span>
                            </DropdownItem>
                            <DropdownItem tag={Link} to={`/supplier/${supplier.id}/supplier-contact`} color="info" size="sm">
                              <FontAwesomeIcon icon="table" />

                              <span className="d-none d-md-inline">
                                <Translate contentKey="generadorApp.supplier.listButtons.contact">Contacts</Translate>
                              </span>
                            </DropdownItem>
                            <DropdownItem tag={Link} to={`/supplier/${supplier.id}/supplier-table`} color="info" size="sm">
                              <FontAwesomeIcon icon="table" />

                              <span className="d-none d-md-inline">
                                <Translate contentKey="generadorApp.supplier.listButtons.table">Table</Translate>
                              </span>
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.supplier.home.notFound">No Suppliers found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const {
      users,
      supplierGlosas,
      supplierContacts,
      supplierStatusHistories,
      supplierFiles,
      nfes,
      supplierTables,
      pharmacyStocks,
      supplies,
      patientSuppliers,
      levelGroups,
      patients,
      supplierList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={supplierList && supplierList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const {
      users,
      supplierGlosas,
      supplierContacts,
      supplierStatusHistories,
      supplierFiles,
      nfes,
      supplierTables,
      pharmacyStocks,
      supplies,
      patientSuppliers,
      levelGroups,
      patients,
      supplierList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel className="supplier supplier-list-table">
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToProps = ({ ...storeState }: IRootState) => {
  return {
    users: storeState.userManagement.users,
    supplierGlosas: storeState.supplierGlosa.entities,
    supplierContacts: storeState.supplierContact.entities,
    supplierStatusHistories: storeState.supplierStatusHistory.entities,
    supplierFiles: storeState.supplierFile.entities,
    nfes: storeState.nfe.entities,
    supplierTables: storeState.supplierTable.entities,
    pharmacyStocks: storeState.pharmacyStock.entities,
    supplies: storeState.supply.entities,
    patientSuppliers: storeState.patientSupplier.entities,
    levelGroups: storeState.levelGroup.entities,
    patients: storeState.patient.entities,
    supplierList: storeState.supplier.entities,
    totalItems: storeState.supplier.totalItems,
    userAccount: storeState.authentication.account,
    loading: storeState.supplier.loading,
  };
};

export const mapDispatchToProps = {
  getEntities,

  deleteEntity,
  updateEntity,
  reset,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Supplier);
