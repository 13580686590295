/* eslint complexity: ["error", 500] */
/* eslint no-empty: off */
import React from 'react';
import { connect } from 'react-redux';

import { Link, RouteComponentProps } from 'react-router-dom';

import CurrencyInput from 'react-currency-input';

import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Col, Label } from 'reactstrap';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import {
  ISimproUpdateState as IBaseUpdateState,
  getEntity,
  getSimproState,
  getUrlBack,
  updateEntity,
  createEntity,
  getEntityFiltersURL,
  getEntityOffset,
  reset,
} from './simpro.reducer';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

registerLocale('pt', pt);

export interface ISimproUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}
export type ISimproUpdateState = IBaseUpdateState;

export class SimproUpdate extends React.Component<ISimproUpdateProps, ISimproUpdateState> {
  cdUsuarioFileInput: any;

  cdFracaoFileInput: any;

  descricaoFileInput: any;

  vigenciaFileInput: any;

  identifFileInput: any;

  pcEmFabFileInput: any;

  pcEmVenFileInput: any;

  pcEmUsuFileInput: any;

  pcFrFabFileInput: any;

  pcFrVenFileInput: any;

  pcFrUsuFileInput: any;

  tpEmbalFileInput: any;

  tpFracaoFileInput: any;

  qtdeEmbalFileInput: any;

  qtdeFracFileInput: any;

  percLucrFileInput: any;

  tipAltFileInput: any;

  fabricaFileInput: any;

  cdSimproFileInput: any;

  cdMercadoFileInput: any;

  percDescFileInput: any;

  ipiProdutoFileInput: any;

  registroAnvisaFileInput: any;

  validadeAnvisaFileInput: any;

  cdBarraFileInput: any;

  listaFileInput: any;

  hospitalarFileInput: any;

  fracionarFileInput: any;

  cdTussFileInput: any;

  cdClassifFileInput: any;

  cdRefProFileInput: any;

  genericoFileInput: any;

  statusFileInput: any;
  constructor(props: Readonly<ISimproUpdateProps>) {
    super(props);

    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      functionEmbebed: [],

      medicineMaterialSelectValue: null,

      cdUsuarioSelectValue: null,
      cdFracaoSelectValue: null,
      descricaoSelectValue: null,
      vigenciaSelectValue: null,
      identifSelectValue: null,
      pcEmFabSelectValue: null,
      pcEmVenSelectValue: null,
      pcEmUsuSelectValue: null,
      pcFrFabSelectValue: null,
      pcFrVenSelectValue: null,
      pcFrUsuSelectValue: null,
      tpEmbalSelectValue: null,
      tpFracaoSelectValue: null,
      qtdeEmbalSelectValue: null,
      qtdeFracSelectValue: null,
      percLucrSelectValue: null,
      tipAltSelectValue: null,
      fabricaSelectValue: null,
      cdSimproSelectValue: null,
      cdMercadoSelectValue: null,
      percDescSelectValue: null,
      ipiProdutoSelectValue: null,
      registroAnvisaSelectValue: null,
      validadeAnvisaSelectValue: null,
      cdBarraSelectValue: null,
      listaSelectValue: null,
      hospitalarSelectValue: null,
      fracionarSelectValue: null,
      cdTussSelectValue: null,
      cdClassifSelectValue: null,
      cdRefProSelectValue: null,
      genericoSelectValue: null,
      statusSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getSimproState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },

      isNew: !this.props.match.params || !this.props.match.params['id'],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateSuccess !== this.props.updateSuccess && prevProps.updateSuccess) {
      this.handleClose();
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.simproEntity && prevProps.simproEntity.id !== this.props.simproEntity.id) ||
      (this.props.medicineMaterials.length > 0 &&
        this.state.medicineMaterialSelectValue === null &&
        this.props.simproEntity.medicineMaterial)
    ) {
      this.setState({
        medicineMaterialSelectValue:
          this.props.simproEntity && this.props.simproEntity.medicineMaterial
            ? this.props.simproEntity.medicineMaterial.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    if (
      (prevProps.simproEntity && prevProps.simproEntity.id !== this.props.simproEntity.id) ||
      (this.props.simproEntity.cdUsuario &&
        this.state.cdUsuarioSelectValue === null &&
        this.props.simproEntity.cdUsuario !== this.state.cdUsuarioSelectValue)
    ) {
      this.setState({ cdUsuarioSelectValue: this.props.simproEntity.cdUsuario ? this.props.simproEntity.cdUsuario : null });
    }
    if (
      (prevProps.simproEntity && prevProps.simproEntity.id !== this.props.simproEntity.id) ||
      (this.props.simproEntity.cdFracao &&
        this.state.cdFracaoSelectValue === null &&
        this.props.simproEntity.cdFracao !== this.state.cdFracaoSelectValue)
    ) {
      this.setState({ cdFracaoSelectValue: this.props.simproEntity.cdFracao ? this.props.simproEntity.cdFracao : null });
    }
    if (
      (prevProps.simproEntity && prevProps.simproEntity.id !== this.props.simproEntity.id) ||
      (this.props.simproEntity.descricao &&
        this.state.descricaoSelectValue === null &&
        this.props.simproEntity.descricao !== this.state.descricaoSelectValue)
    ) {
      this.setState({ descricaoSelectValue: this.props.simproEntity.descricao ? this.props.simproEntity.descricao : null });
    }
    if (
      (prevProps.simproEntity && prevProps.simproEntity.id !== this.props.simproEntity.id) ||
      (this.props.simproEntity.vigencia &&
        this.state.vigenciaSelectValue === null &&
        this.props.simproEntity.vigencia !== this.state.vigenciaSelectValue)
    ) {
      this.setState({ vigenciaSelectValue: this.props.simproEntity.vigencia ? this.props.simproEntity.vigencia : null });
    }
    if (
      (prevProps.simproEntity && prevProps.simproEntity.id !== this.props.simproEntity.id) ||
      (this.props.simproEntity.identif &&
        this.state.identifSelectValue === null &&
        this.props.simproEntity.identif !== this.state.identifSelectValue)
    ) {
      this.setState({ identifSelectValue: this.props.simproEntity.identif ? this.props.simproEntity.identif : null });
    }
    if (
      (prevProps.simproEntity && prevProps.simproEntity.id !== this.props.simproEntity.id) ||
      (this.props.simproEntity.pcEmFab &&
        this.state.pcEmFabSelectValue === null &&
        this.props.simproEntity.pcEmFab !== this.state.pcEmFabSelectValue)
    ) {
      this.setState({ pcEmFabSelectValue: this.props.simproEntity.pcEmFab ? this.props.simproEntity.pcEmFab : null });
    }
    if (
      (prevProps.simproEntity && prevProps.simproEntity.id !== this.props.simproEntity.id) ||
      (this.props.simproEntity.pcEmVen &&
        this.state.pcEmVenSelectValue === null &&
        this.props.simproEntity.pcEmVen !== this.state.pcEmVenSelectValue)
    ) {
      this.setState({ pcEmVenSelectValue: this.props.simproEntity.pcEmVen ? this.props.simproEntity.pcEmVen : null });
    }
    if (
      (prevProps.simproEntity && prevProps.simproEntity.id !== this.props.simproEntity.id) ||
      (this.props.simproEntity.pcEmUsu &&
        this.state.pcEmUsuSelectValue === null &&
        this.props.simproEntity.pcEmUsu !== this.state.pcEmUsuSelectValue)
    ) {
      this.setState({ pcEmUsuSelectValue: this.props.simproEntity.pcEmUsu ? this.props.simproEntity.pcEmUsu : null });
    }
    if (
      (prevProps.simproEntity && prevProps.simproEntity.id !== this.props.simproEntity.id) ||
      (this.props.simproEntity.pcFrFab &&
        this.state.pcFrFabSelectValue === null &&
        this.props.simproEntity.pcFrFab !== this.state.pcFrFabSelectValue)
    ) {
      this.setState({ pcFrFabSelectValue: this.props.simproEntity.pcFrFab ? this.props.simproEntity.pcFrFab : null });
    }
    if (
      (prevProps.simproEntity && prevProps.simproEntity.id !== this.props.simproEntity.id) ||
      (this.props.simproEntity.pcFrVen &&
        this.state.pcFrVenSelectValue === null &&
        this.props.simproEntity.pcFrVen !== this.state.pcFrVenSelectValue)
    ) {
      this.setState({ pcFrVenSelectValue: this.props.simproEntity.pcFrVen ? this.props.simproEntity.pcFrVen : null });
    }
    if (
      (prevProps.simproEntity && prevProps.simproEntity.id !== this.props.simproEntity.id) ||
      (this.props.simproEntity.pcFrUsu &&
        this.state.pcFrUsuSelectValue === null &&
        this.props.simproEntity.pcFrUsu !== this.state.pcFrUsuSelectValue)
    ) {
      this.setState({ pcFrUsuSelectValue: this.props.simproEntity.pcFrUsu ? this.props.simproEntity.pcFrUsu : null });
    }
    if (
      (prevProps.simproEntity && prevProps.simproEntity.id !== this.props.simproEntity.id) ||
      (this.props.simproEntity.tpEmbal &&
        this.state.tpEmbalSelectValue === null &&
        this.props.simproEntity.tpEmbal !== this.state.tpEmbalSelectValue)
    ) {
      this.setState({ tpEmbalSelectValue: this.props.simproEntity.tpEmbal ? this.props.simproEntity.tpEmbal : null });
    }
    if (
      (prevProps.simproEntity && prevProps.simproEntity.id !== this.props.simproEntity.id) ||
      (this.props.simproEntity.tpFracao &&
        this.state.tpFracaoSelectValue === null &&
        this.props.simproEntity.tpFracao !== this.state.tpFracaoSelectValue)
    ) {
      this.setState({ tpFracaoSelectValue: this.props.simproEntity.tpFracao ? this.props.simproEntity.tpFracao : null });
    }
    if (
      (prevProps.simproEntity && prevProps.simproEntity.id !== this.props.simproEntity.id) ||
      (this.props.simproEntity.qtdeEmbal &&
        this.state.qtdeEmbalSelectValue === null &&
        this.props.simproEntity.qtdeEmbal !== this.state.qtdeEmbalSelectValue)
    ) {
      this.setState({ qtdeEmbalSelectValue: this.props.simproEntity.qtdeEmbal ? this.props.simproEntity.qtdeEmbal : null });
    }
    if (
      (prevProps.simproEntity && prevProps.simproEntity.id !== this.props.simproEntity.id) ||
      (this.props.simproEntity.qtdeFrac &&
        this.state.qtdeFracSelectValue === null &&
        this.props.simproEntity.qtdeFrac !== this.state.qtdeFracSelectValue)
    ) {
      this.setState({ qtdeFracSelectValue: this.props.simproEntity.qtdeFrac ? this.props.simproEntity.qtdeFrac : null });
    }
    if (
      (prevProps.simproEntity && prevProps.simproEntity.id !== this.props.simproEntity.id) ||
      (this.props.simproEntity.percLucr &&
        this.state.percLucrSelectValue === null &&
        this.props.simproEntity.percLucr !== this.state.percLucrSelectValue)
    ) {
      this.setState({ percLucrSelectValue: this.props.simproEntity.percLucr ? this.props.simproEntity.percLucr : null });
    }
    if (
      (prevProps.simproEntity && prevProps.simproEntity.id !== this.props.simproEntity.id) ||
      (this.props.simproEntity.tipAlt &&
        this.state.tipAltSelectValue === null &&
        this.props.simproEntity.tipAlt !== this.state.tipAltSelectValue)
    ) {
      this.setState({ tipAltSelectValue: this.props.simproEntity.tipAlt ? this.props.simproEntity.tipAlt : null });
    }
    if (
      (prevProps.simproEntity && prevProps.simproEntity.id !== this.props.simproEntity.id) ||
      (this.props.simproEntity.fabrica &&
        this.state.fabricaSelectValue === null &&
        this.props.simproEntity.fabrica !== this.state.fabricaSelectValue)
    ) {
      this.setState({ fabricaSelectValue: this.props.simproEntity.fabrica ? this.props.simproEntity.fabrica : null });
    }
    if (
      (prevProps.simproEntity && prevProps.simproEntity.id !== this.props.simproEntity.id) ||
      (this.props.simproEntity.cdSimpro &&
        this.state.cdSimproSelectValue === null &&
        this.props.simproEntity.cdSimpro !== this.state.cdSimproSelectValue)
    ) {
      this.setState({ cdSimproSelectValue: this.props.simproEntity.cdSimpro ? this.props.simproEntity.cdSimpro : null });
    }
    if (
      (prevProps.simproEntity && prevProps.simproEntity.id !== this.props.simproEntity.id) ||
      (this.props.simproEntity.cdMercado &&
        this.state.cdMercadoSelectValue === null &&
        this.props.simproEntity.cdMercado !== this.state.cdMercadoSelectValue)
    ) {
      this.setState({ cdMercadoSelectValue: this.props.simproEntity.cdMercado ? this.props.simproEntity.cdMercado : null });
    }
    if (
      (prevProps.simproEntity && prevProps.simproEntity.id !== this.props.simproEntity.id) ||
      (this.props.simproEntity.percDesc &&
        this.state.percDescSelectValue === null &&
        this.props.simproEntity.percDesc !== this.state.percDescSelectValue)
    ) {
      this.setState({ percDescSelectValue: this.props.simproEntity.percDesc ? this.props.simproEntity.percDesc : null });
    }
    if (
      (prevProps.simproEntity && prevProps.simproEntity.id !== this.props.simproEntity.id) ||
      (this.props.simproEntity.ipiProduto &&
        this.state.ipiProdutoSelectValue === null &&
        this.props.simproEntity.ipiProduto !== this.state.ipiProdutoSelectValue)
    ) {
      this.setState({ ipiProdutoSelectValue: this.props.simproEntity.ipiProduto ? this.props.simproEntity.ipiProduto : null });
    }
    if (
      (prevProps.simproEntity && prevProps.simproEntity.id !== this.props.simproEntity.id) ||
      (this.props.simproEntity.registroAnvisa &&
        this.state.registroAnvisaSelectValue === null &&
        this.props.simproEntity.registroAnvisa !== this.state.registroAnvisaSelectValue)
    ) {
      this.setState({ registroAnvisaSelectValue: this.props.simproEntity.registroAnvisa ? this.props.simproEntity.registroAnvisa : null });
    }
    if (
      (prevProps.simproEntity && prevProps.simproEntity.id !== this.props.simproEntity.id) ||
      (this.props.simproEntity.validadeAnvisa &&
        this.state.validadeAnvisaSelectValue === null &&
        this.props.simproEntity.validadeAnvisa !== this.state.validadeAnvisaSelectValue)
    ) {
      this.setState({ validadeAnvisaSelectValue: this.props.simproEntity.validadeAnvisa ? this.props.simproEntity.validadeAnvisa : null });
    }
    if (
      (prevProps.simproEntity && prevProps.simproEntity.id !== this.props.simproEntity.id) ||
      (this.props.simproEntity.cdBarra &&
        this.state.cdBarraSelectValue === null &&
        this.props.simproEntity.cdBarra !== this.state.cdBarraSelectValue)
    ) {
      this.setState({ cdBarraSelectValue: this.props.simproEntity.cdBarra ? this.props.simproEntity.cdBarra : null });
    }
    if (
      (prevProps.simproEntity && prevProps.simproEntity.id !== this.props.simproEntity.id) ||
      (this.props.simproEntity.lista &&
        this.state.listaSelectValue === null &&
        this.props.simproEntity.lista !== this.state.listaSelectValue)
    ) {
      this.setState({ listaSelectValue: this.props.simproEntity.lista ? this.props.simproEntity.lista : null });
    }
    if (
      (prevProps.simproEntity && prevProps.simproEntity.id !== this.props.simproEntity.id) ||
      (this.props.simproEntity.hospitalar &&
        this.state.hospitalarSelectValue === null &&
        this.props.simproEntity.hospitalar !== this.state.hospitalarSelectValue)
    ) {
      this.setState({ hospitalarSelectValue: this.props.simproEntity.hospitalar ? this.props.simproEntity.hospitalar : null });
    }
    if (
      (prevProps.simproEntity && prevProps.simproEntity.id !== this.props.simproEntity.id) ||
      (this.props.simproEntity.fracionar &&
        this.state.fracionarSelectValue === null &&
        this.props.simproEntity.fracionar !== this.state.fracionarSelectValue)
    ) {
      this.setState({ fracionarSelectValue: this.props.simproEntity.fracionar ? this.props.simproEntity.fracionar : null });
    }
    if (
      (prevProps.simproEntity && prevProps.simproEntity.id !== this.props.simproEntity.id) ||
      (this.props.simproEntity.cdTuss &&
        this.state.cdTussSelectValue === null &&
        this.props.simproEntity.cdTuss !== this.state.cdTussSelectValue)
    ) {
      this.setState({ cdTussSelectValue: this.props.simproEntity.cdTuss ? this.props.simproEntity.cdTuss : null });
    }
    if (
      (prevProps.simproEntity && prevProps.simproEntity.id !== this.props.simproEntity.id) ||
      (this.props.simproEntity.cdClassif &&
        this.state.cdClassifSelectValue === null &&
        this.props.simproEntity.cdClassif !== this.state.cdClassifSelectValue)
    ) {
      this.setState({ cdClassifSelectValue: this.props.simproEntity.cdClassif ? this.props.simproEntity.cdClassif : null });
    }
    if (
      (prevProps.simproEntity && prevProps.simproEntity.id !== this.props.simproEntity.id) ||
      (this.props.simproEntity.cdRefPro &&
        this.state.cdRefProSelectValue === null &&
        this.props.simproEntity.cdRefPro !== this.state.cdRefProSelectValue)
    ) {
      this.setState({ cdRefProSelectValue: this.props.simproEntity.cdRefPro ? this.props.simproEntity.cdRefPro : null });
    }
    if (
      (prevProps.simproEntity && prevProps.simproEntity.id !== this.props.simproEntity.id) ||
      (this.props.simproEntity.generico &&
        this.state.genericoSelectValue === null &&
        this.props.simproEntity.generico !== this.state.genericoSelectValue)
    ) {
      this.setState({ genericoSelectValue: this.props.simproEntity.generico ? this.props.simproEntity.generico : null });
    }
    if (
      (prevProps.simproEntity && prevProps.simproEntity.id !== this.props.simproEntity.id) ||
      (this.state.statusSelectValue === null && this.props.simproEntity.status !== this.state.statusSelectValue)
    ) {
      this.setState({ statusSelectValue: this.props.simproEntity.status ? this.props.simproEntity.status : false });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  UNSAFE_componentWillMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params['id']);
    }
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = offset !== null ? '&offset=' + offset : '';
    Object.keys(fieldsBase).map(key => {
      url += key !== 'offset' ? '&' + key + '=' + fieldsBase[key] : '';
    });
    return url;
  };
  saveEntity = (event: any, errors: any, values: any) => {
    const selectFieldsList = ['fabrica', 'descricao', 'pcEmFab', 'pcFrFab', 'pcEmVen', 'pcFrVen'];

    if (errors.length === 0) {
      const { simproEntity } = this.props;
      const _entity = {
        ...simproEntity,
        // ...values,

        cdUsuario: this.state.cdUsuarioSelectValue,
        cdFracao: this.state.cdFracaoSelectValue,
        descricao: this.state.descricaoSelectValue,
        vigencia: this.state.vigenciaSelectValue,
        identif: this.state.identifSelectValue,
        pcEmFab: this.state.pcEmFabSelectValue,
        pcEmVen: this.state.pcEmVenSelectValue,
        pcEmUsu: this.state.pcEmUsuSelectValue,
        pcFrFab: this.state.pcFrFabSelectValue,
        pcFrVen: this.state.pcFrVenSelectValue,
        pcFrUsu: this.state.pcFrUsuSelectValue,
        tpEmbal: this.state.tpEmbalSelectValue,
        tpFracao: this.state.tpFracaoSelectValue,
        qtdeEmbal: this.state.qtdeEmbalSelectValue,
        qtdeFrac: this.state.qtdeFracSelectValue,
        percLucr: this.state.percLucrSelectValue,
        tipAlt: this.state.tipAltSelectValue,
        fabrica: this.state.fabricaSelectValue,
        cdSimpro: this.state.cdSimproSelectValue,
        cdMercado: this.state.cdMercadoSelectValue,
        percDesc: this.state.percDescSelectValue,
        ipiProduto: this.state.ipiProdutoSelectValue,
        registroAnvisa: this.state.registroAnvisaSelectValue,
        validadeAnvisa: this.state.validadeAnvisaSelectValue,
        cdBarra: this.state.cdBarraSelectValue,
        lista: this.state.listaSelectValue,
        hospitalar: this.state.hospitalarSelectValue,
        fracionar: this.state.fracionarSelectValue,
        cdTuss: this.state.cdTussSelectValue,
        cdClassif: this.state.cdClassifSelectValue,
        cdRefPro: this.state.cdRefProSelectValue,
        generico: this.state.genericoSelectValue,
        status: this.state.statusSelectValue === null ? false : this.state.statusSelectValue,
      };

      const entity = _entity;

      const {
        cdUsuario,
        descricao,
        vigencia,
        identif,
        status,
        extraFilters,
        activePage,
        itemsPerPage,
        sort,
        order,
      } = this.state.fieldsBase;

      if (this.state.isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              cdUsuario,
              descricao,
              vigencia,
              identif,
              status,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              cdUsuario,
              descricao,
              vigencia,
              identif,
              status,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/simpro?' + getEntityFiltersURL(this.state.fieldsBase));
  };

  getFullEntityFromState(v) {
    return {
      id: v.id,
      cdUsuario: this.state.cdUsuarioSelectValue,
      cdFracao: this.state.cdFracaoSelectValue,
      descricao: this.state.descricaoSelectValue,
      vigencia: this.state.vigenciaSelectValue,
      identif: this.state.identifSelectValue,
      pcEmFab: this.state.pcEmFabSelectValue,
      pcEmVen: this.state.pcEmVenSelectValue,
      pcEmUsu: this.state.pcEmUsuSelectValue,
      pcFrFab: this.state.pcFrFabSelectValue,
      pcFrVen: this.state.pcFrVenSelectValue,
      pcFrUsu: this.state.pcFrUsuSelectValue,
      tpEmbal: this.state.tpEmbalSelectValue,
      tpFracao: this.state.tpFracaoSelectValue,
      qtdeEmbal: this.state.qtdeEmbalSelectValue,
      qtdeFrac: this.state.qtdeFracSelectValue,
      percLucr: this.state.percLucrSelectValue,
      tipAlt: this.state.tipAltSelectValue,
      fabrica: this.state.fabricaSelectValue,
      cdSimpro: this.state.cdSimproSelectValue,
      cdMercado: this.state.cdMercadoSelectValue,
      percDesc: this.state.percDescSelectValue,
      ipiProduto: this.state.ipiProdutoSelectValue,
      registroAnvisa: this.state.registroAnvisaSelectValue,
      validadeAnvisa: this.state.validadeAnvisaSelectValue,
      cdBarra: this.state.cdBarraSelectValue,
      lista: this.state.listaSelectValue,
      hospitalar: this.state.hospitalarSelectValue,
      fracionar: this.state.fracionarSelectValue,
      cdTuss: this.state.cdTussSelectValue,
      cdClassif: this.state.cdClassifSelectValue,
      cdRefPro: this.state.cdRefProSelectValue,
      generico: this.state.genericoSelectValue,
      status: this.state.statusSelectValue,
      medicineMaterial: this.state.medicineMaterialSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    const medicineMaterialEntity = v.medicineMaterial;

    return {
      cdUsuarioSelectValue: v.cdUsuario,
      cdFracaoSelectValue: v.cdFracao,
      descricaoSelectValue: v.descricao,
      vigenciaSelectValue: v.vigencia,
      identifSelectValue: v.identif,
      pcEmFabSelectValue: v.pcEmFab,
      pcEmVenSelectValue: v.pcEmVen,
      pcEmUsuSelectValue: v.pcEmUsu,
      pcFrFabSelectValue: v.pcFrFab,
      pcFrVenSelectValue: v.pcFrVen,
      pcFrUsuSelectValue: v.pcFrUsu,
      tpEmbalSelectValue: v.tpEmbal,
      tpFracaoSelectValue: v.tpFracao,
      qtdeEmbalSelectValue: v.qtdeEmbal,
      qtdeFracSelectValue: v.qtdeFrac,
      percLucrSelectValue: v.percLucr,
      tipAltSelectValue: v.tipAlt,
      fabricaSelectValue: v.fabrica,
      cdSimproSelectValue: v.cdSimpro,
      cdMercadoSelectValue: v.cdMercado,
      percDescSelectValue: v.percDesc,
      ipiProdutoSelectValue: v.ipiProduto,
      registroAnvisaSelectValue: v.registroAnvisa,
      validadeAnvisaSelectValue: v.validadeAnvisa,
      cdBarraSelectValue: v.cdBarra,
      listaSelectValue: v.lista,
      hospitalarSelectValue: v.hospitalar,
      fracionarSelectValue: v.fracionar,
      cdTussSelectValue: v.cdTuss,
      cdClassifSelectValue: v.cdClassif,
      cdRefProSelectValue: v.cdRefPro,
      genericoSelectValue: v.generico,
      statusSelectValue: v.status,
      medicineMaterialSelectValue: medicineMaterialEntity ? medicineMaterialEntity['id'] : null,
    };
  }

  renderHeader() {
    const { simproEntity, medicineMaterials, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.simpro.home.createLabel">Create a Simpro</Translate>
            ) : (
              <Translate contentKey="generadorApp.simpro.home.editLabel">Edit a Simpro</Translate>
            )}
          </span>

          <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.simpro.btnSave">Save</Translate>
          </Button>

          {!this.state.isNew ? (
            <>
              <Button
                color="primary"
                id="next-entity"
                disabled={updating}
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.cdUsuario,
                    this.state.fieldsBase.descricao,
                    this.state.fieldsBase.vigencia,
                    this.state.fieldsBase.identif,
                    this.state.fieldsBase.status,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 + 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 + 1 },
                    });
                    this.props.history.push(
                      '/simpro/' + myresult.value.data[0]['id'] + '/edit?' + this.getFiltersURL(this.state.fieldsBase.offset * 1 + 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.last.item', { fieldName: translate('generadorApp.simpro.detail.title') }));
                  }
                }}
                className="float-right jh-create-entity"
              >
                {'>>'}
              </Button>

              <Button
                color="primary"
                id="prev-entity"
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.cdUsuario,
                    this.state.fieldsBase.descricao,
                    this.state.fieldsBase.vigencia,
                    this.state.fieldsBase.identif,
                    this.state.fieldsBase.status,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 - 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 - 1 },
                    });
                    this.props.history.push(
                      '/simpro/' + myresult.value.data[0]['id'] + '/edit?' + this.getFiltersURL(this.state.fieldsBase.offset * 1 - 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.first.item', { fieldName: translate('generadorApp.simpro.detail.title') }));
                  }
                }}
                disabled={updating}
                className="float-right jh-create-entity"
              >
                {'<<'}
              </Button>
            </>
          ) : (
            <> </>
          )}

          <Button
            tag={Link}
            id="cancel-save"
            to={'/simpro?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.simpro.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={'/simpro'}>
              <Translate contentKey="generadorApp.simpro.home.title">Simpro table</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.simpro.detail.title">Simpro table edit</Translate>
          </li>
        </ol>
      </>
    );
  }
  renderBody() {
    const { simproEntity, medicineMaterials, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const fullEntityEmbebed = this.getFullEntityFromState(simproEntity);

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="simpro-id">
                  <Translate contentKey="generadorApp.simpro.field_id">ID</Translate>
                </Label>
                </Col> */}
                      <Col md="12">
                        <AvInput id="simpro-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'cdUsuario' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="cdUsuarioLabel" for="simpro-cdUsuario">
                              <Translate contentKey="generadorApp.simpro.cdUsuario">Cd Usuario</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ cdUsuarioSelectValue: evt.target.value })}
                              value={this.state.cdUsuarioSelectValue ? this.state.cdUsuarioSelectValue : ''}
                              id="simpro-cdUsuario"
                              type="text"
                              name="cdUsuario"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ cdUsuarioSelectValue: evt.target.value })}
                      type="hidden"
                      name="cdUsuario"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'cdFracao' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="cdFracaoLabel" for="simpro-cdFracao">
                              <Translate contentKey="generadorApp.simpro.cdFracao">Cd Fracao</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ cdFracaoSelectValue: evt.target.value })}
                              value={this.state.cdFracaoSelectValue ? this.state.cdFracaoSelectValue : ''}
                              id="simpro-cdFracao"
                              type="text"
                              name="cdFracao"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ cdFracaoSelectValue: evt.target.value })}
                      type="hidden"
                      name="cdFracao"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'descricao' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="descricaoLabel" for="simpro-descricao">
                              <Translate contentKey="generadorApp.simpro.descricao">Descricao</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ descricaoSelectValue: evt.target.value })}
                              value={this.state.descricaoSelectValue ? this.state.descricaoSelectValue : ''}
                              id="simpro-descricao"
                              type="text"
                              name="descricao"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ descricaoSelectValue: evt.target.value })}
                      type="hidden"
                      name="descricao"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'vigencia' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="vigenciaLabel" for="simpro-vigencia">
                              <Translate contentKey="generadorApp.simpro.vigencia">Vigencia</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ vigenciaSelectValue: evt.target.value })}
                              value={this.state.vigenciaSelectValue ? this.state.vigenciaSelectValue : ''}
                              id="simpro-vigencia"
                              type="text"
                              name="vigencia"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ vigenciaSelectValue: evt.target.value })}
                      type="hidden"
                      name="vigencia"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'identif' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="identifLabel" for="simpro-identif">
                              <Translate contentKey="generadorApp.simpro.identif">Identif</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ identifSelectValue: evt.target.value })}
                              value={this.state.identifSelectValue ? this.state.identifSelectValue : ''}
                              id="simpro-identif"
                              type="text"
                              name="identif"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ identifSelectValue: evt.target.value })}
                      type="hidden"
                      name="identif"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'tpEmbal' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="tpEmbalLabel" for="simpro-tpEmbal">
                              <Translate contentKey="generadorApp.simpro.tpEmbal">Tp Embal</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ tpEmbalSelectValue: evt.target.value })}
                              value={this.state.tpEmbalSelectValue ? this.state.tpEmbalSelectValue : ''}
                              id="simpro-tpEmbal"
                              type="text"
                              name="tpEmbal"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ tpEmbalSelectValue: evt.target.value })}
                      type="hidden"
                      name="tpEmbal"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'pcEmFab' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="pcEmFabLabel" for="simpro-pcEmFab">
                              <Translate contentKey="generadorApp.simpro.pcEmFab">Pc Em Fab</Translate>
                            </Label>
                          </Col>

                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ pcEmFabSelectValue: floatvalue })}
                              decimalSeparator=","
                              precision={2}
                              thousandSeparator=""
                              prefix="R$ "
                              allowNegative={true}
                              value={this.state.pcEmFabSelectValue ? this.state.pcEmFabSelectValue : 0}
                              id="simpro-pcEmFab"
                              name="pcEmFab"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ pcEmFabSelectValue: evt.target.value })}
                      type="hidden"
                      name="pcEmFab"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'pcFrFab' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="pcFrFabLabel" for="simpro-pcFrFab">
                              <Translate contentKey="generadorApp.simpro.pcFrFab">Pc Fr Fab</Translate>
                            </Label>
                          </Col>

                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ pcFrFabSelectValue: floatvalue })}
                              decimalSeparator=","
                              precision={3}
                              thousandSeparator=""
                              prefix="R$ "
                              allowNegative={true}
                              value={this.state.pcFrFabSelectValue ? this.state.pcFrFabSelectValue : 0}
                              id="simpro-pcFrFab"
                              name="pcFrFab"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ pcFrFabSelectValue: evt.target.value })}
                      type="hidden"
                      name="pcFrFab"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'pcEmVen' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="pcEmVenLabel" for="simpro-pcEmVen">
                              <Translate contentKey="generadorApp.simpro.pcEmVen">Pc Em Ven</Translate>
                            </Label>
                          </Col>

                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ pcEmVenSelectValue: floatvalue })}
                              decimalSeparator=","
                              precision={2}
                              thousandSeparator=""
                              prefix="R$ "
                              allowNegative={true}
                              value={this.state.pcEmVenSelectValue ? this.state.pcEmVenSelectValue : 0}
                              id="simpro-pcEmVen"
                              name="pcEmVen"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ pcEmVenSelectValue: evt.target.value })}
                      type="hidden"
                      name="pcEmVen"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'pcFrVen' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="pcFrVenLabel" for="simpro-pcFrVen">
                              <Translate contentKey="generadorApp.simpro.pcFrVen">Pc Fr Ven</Translate>
                            </Label>
                          </Col>

                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ pcFrVenSelectValue: floatvalue })}
                              decimalSeparator=","
                              precision={3}
                              thousandSeparator=""
                              prefix="R$ "
                              allowNegative={true}
                              value={this.state.pcFrVenSelectValue ? this.state.pcFrVenSelectValue : 0}
                              id="simpro-pcFrVen"
                              name="pcFrVen"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ pcFrVenSelectValue: evt.target.value })}
                      type="hidden"
                      name="pcFrVen"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'pcEmUsu' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="pcEmUsuLabel" for="simpro-pcEmUsu">
                              <Translate contentKey="generadorApp.simpro.pcEmUsu">Pc Em Usu</Translate>
                            </Label>
                          </Col>

                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ pcEmUsuSelectValue: floatvalue })}
                              decimalSeparator=","
                              precision={2}
                              thousandSeparator=""
                              prefix="R$ "
                              allowNegative={true}
                              value={this.state.pcEmUsuSelectValue ? this.state.pcEmUsuSelectValue : 0}
                              id="simpro-pcEmUsu"
                              name="pcEmUsu"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ pcEmUsuSelectValue: evt.target.value })}
                      type="hidden"
                      name="pcEmUsu"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'pcFrUsu' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="pcFrUsuLabel" for="simpro-pcFrUsu">
                              <Translate contentKey="generadorApp.simpro.pcFrUsu">Pc Fr Usu</Translate>
                            </Label>
                          </Col>

                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ pcFrUsuSelectValue: floatvalue })}
                              decimalSeparator=","
                              precision={3}
                              thousandSeparator=""
                              prefix="R$ "
                              allowNegative={true}
                              value={this.state.pcFrUsuSelectValue ? this.state.pcFrUsuSelectValue : 0}
                              id="simpro-pcFrUsu"
                              name="pcFrUsu"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ pcFrUsuSelectValue: evt.target.value })}
                      type="hidden"
                      name="pcFrUsu"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'tpFracao' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="tpFracaoLabel" for="simpro-tpFracao">
                              <Translate contentKey="generadorApp.simpro.tpFracao">Tp Fracao</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ tpFracaoSelectValue: evt.target.value })}
                              value={this.state.tpFracaoSelectValue ? this.state.tpFracaoSelectValue : ''}
                              id="simpro-tpFracao"
                              type="text"
                              name="tpFracao"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ tpFracaoSelectValue: evt.target.value })}
                      type="hidden"
                      name="tpFracao"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'qtdeEmbal' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="qtdeEmbalLabel" for="simpro-qtdeEmbal">
                              <Translate contentKey="generadorApp.simpro.qtdeEmbal">Qtde Embal</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ qtdeEmbalSelectValue: floatvalue })}
                              decimalSeparator=","
                              thousandSeparator=""
                              precision={2}
                              prefix=""
                              allowNegative={true}
                              value={this.state.qtdeEmbalSelectValue ? this.state.qtdeEmbalSelectValue : 0}
                              id="simpro-qtdeEmbal"
                              name="qtdeEmbal"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ qtdeEmbalSelectValue: evt.target.value })}
                      type="hidden"
                      name="qtdeEmbal"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'qtdeFrac' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="qtdeFracLabel" for="simpro-qtdeFrac">
                              <Translate contentKey="generadorApp.simpro.qtdeFrac">Qtde Frac</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ qtdeFracSelectValue: floatvalue })}
                              decimalSeparator=","
                              thousandSeparator=""
                              precision={2}
                              prefix=""
                              allowNegative={true}
                              value={this.state.qtdeFracSelectValue ? this.state.qtdeFracSelectValue : 0}
                              id="simpro-qtdeFrac"
                              name="qtdeFrac"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ qtdeFracSelectValue: evt.target.value })}
                      type="hidden"
                      name="qtdeFrac"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'percLucr' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="percLucrLabel" for="simpro-percLucr">
                              <Translate contentKey="generadorApp.simpro.percLucr">Perc Lucr</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ percLucrSelectValue: floatvalue })}
                              decimalSeparator=","
                              thousandSeparator=""
                              precision={2}
                              prefix=""
                              allowNegative={true}
                              value={this.state.percLucrSelectValue ? this.state.percLucrSelectValue : 0}
                              id="simpro-percLucr"
                              name="percLucr"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ percLucrSelectValue: evt.target.value })}
                      type="hidden"
                      name="percLucr"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'tipAlt' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="tipAltLabel" for="simpro-tipAlt">
                              <Translate contentKey="generadorApp.simpro.tipAlt">Tip Alt</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ tipAltSelectValue: evt.target.value })}
                              value={this.state.tipAltSelectValue ? this.state.tipAltSelectValue : ''}
                              id="simpro-tipAlt"
                              type="text"
                              name="tipAlt"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ tipAltSelectValue: evt.target.value })}
                      type="hidden"
                      name="tipAlt"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'fabrica' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="fabricaLabel" for="simpro-fabrica">
                              <Translate contentKey="generadorApp.simpro.fabrica">Fabrica</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ fabricaSelectValue: evt.target.value })}
                              value={this.state.fabricaSelectValue ? this.state.fabricaSelectValue : ''}
                              id="simpro-fabrica"
                              type="text"
                              name="fabrica"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ fabricaSelectValue: evt.target.value })}
                      type="hidden"
                      name="fabrica"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'cdSimpro' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="cdSimproLabel" for="simpro-cdSimpro">
                              <Translate contentKey="generadorApp.simpro.cdSimpro">Cd Simpro</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ cdSimproSelectValue: evt.target.value })}
                              value={this.state.cdSimproSelectValue ? this.state.cdSimproSelectValue : ''}
                              id="simpro-cdSimpro"
                              type="text"
                              name="cdSimpro"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ cdSimproSelectValue: evt.target.value })}
                      type="hidden"
                      name="cdSimpro"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'cdMercado' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="cdMercadoLabel" for="simpro-cdMercado">
                              <Translate contentKey="generadorApp.simpro.cdMercado">Cd Mercado</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ cdMercadoSelectValue: evt.target.value })}
                              value={this.state.cdMercadoSelectValue ? this.state.cdMercadoSelectValue : ''}
                              id="simpro-cdMercado"
                              type="text"
                              name="cdMercado"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ cdMercadoSelectValue: evt.target.value })}
                      type="hidden"
                      name="cdMercado"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'percDesc' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="percDescLabel" for="simpro-percDesc">
                              <Translate contentKey="generadorApp.simpro.percDesc">Perc Desc</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ percDescSelectValue: floatvalue })}
                              decimalSeparator=","
                              thousandSeparator=""
                              precision={2}
                              prefix=""
                              allowNegative={true}
                              value={this.state.percDescSelectValue ? this.state.percDescSelectValue : 0}
                              id="simpro-percDesc"
                              name="percDesc"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ percDescSelectValue: evt.target.value })}
                      type="hidden"
                      name="percDesc"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'ipiProduto' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="ipiProdutoLabel" for="simpro-ipiProduto">
                              <Translate contentKey="generadorApp.simpro.ipiProduto">Ipi Produto</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ ipiProdutoSelectValue: floatvalue })}
                              decimalSeparator=","
                              thousandSeparator=""
                              precision={2}
                              prefix=""
                              allowNegative={true}
                              value={this.state.ipiProdutoSelectValue ? this.state.ipiProdutoSelectValue : 0}
                              id="simpro-ipiProduto"
                              name="ipiProduto"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ ipiProdutoSelectValue: evt.target.value })}
                      type="hidden"
                      name="ipiProduto"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'registroAnvisa' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="registroAnvisaLabel" for="simpro-registroAnvisa">
                              <Translate contentKey="generadorApp.simpro.registroAnvisa">Registro Anvisa</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ registroAnvisaSelectValue: evt.target.value })}
                              value={this.state.registroAnvisaSelectValue ? this.state.registroAnvisaSelectValue : ''}
                              id="simpro-registroAnvisa"
                              type="text"
                              name="registroAnvisa"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ registroAnvisaSelectValue: evt.target.value })}
                      type="hidden"
                      name="registroAnvisa"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'validadeAnvisa' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="validadeAnvisaLabel" for="simpro-validadeAnvisa">
                              <Translate contentKey="generadorApp.simpro.validadeAnvisa">Validade Anvisa</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ validadeAnvisaSelectValue: evt.target.value })}
                              value={this.state.validadeAnvisaSelectValue ? this.state.validadeAnvisaSelectValue : ''}
                              id="simpro-validadeAnvisa"
                              type="text"
                              name="validadeAnvisa"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ validadeAnvisaSelectValue: evt.target.value })}
                      type="hidden"
                      name="validadeAnvisa"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'cdBarra' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="cdBarraLabel" for="simpro-cdBarra">
                              <Translate contentKey="generadorApp.simpro.cdBarra">Cd Barra</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ cdBarraSelectValue: evt.target.value })}
                              value={this.state.cdBarraSelectValue ? this.state.cdBarraSelectValue : ''}
                              id="simpro-cdBarra"
                              type="text"
                              name="cdBarra"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ cdBarraSelectValue: evt.target.value })}
                      type="hidden"
                      name="cdBarra"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'lista' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="listaLabel" for="simpro-lista">
                              <Translate contentKey="generadorApp.simpro.lista">Lista</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ listaSelectValue: evt.target.value })}
                              value={this.state.listaSelectValue ? this.state.listaSelectValue : ''}
                              id="simpro-lista"
                              type="text"
                              name="lista"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ listaSelectValue: evt.target.value })}
                      type="hidden"
                      name="lista"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'hospitalar' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="hospitalarLabel" for="simpro-hospitalar">
                              <Translate contentKey="generadorApp.simpro.hospitalar">Hospitalar</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ hospitalarSelectValue: evt.target.value })}
                              value={this.state.hospitalarSelectValue ? this.state.hospitalarSelectValue : ''}
                              id="simpro-hospitalar"
                              type="text"
                              name="hospitalar"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ hospitalarSelectValue: evt.target.value })}
                      type="hidden"
                      name="hospitalar"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'fracionar' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="fracionarLabel" for="simpro-fracionar">
                              <Translate contentKey="generadorApp.simpro.fracionar">Fracionar</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ fracionarSelectValue: evt.target.value })}
                              value={this.state.fracionarSelectValue ? this.state.fracionarSelectValue : ''}
                              id="simpro-fracionar"
                              type="text"
                              name="fracionar"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ fracionarSelectValue: evt.target.value })}
                      type="hidden"
                      name="fracionar"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'cdTuss' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="cdTussLabel" for="simpro-cdTuss">
                              <Translate contentKey="generadorApp.simpro.cdTuss">Cd Tuss</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ cdTussSelectValue: evt.target.value })}
                              value={this.state.cdTussSelectValue ? this.state.cdTussSelectValue : ''}
                              id="simpro-cdTuss"
                              type="text"
                              name="cdTuss"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ cdTussSelectValue: evt.target.value })}
                      type="hidden"
                      name="cdTuss"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'cdClassif' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="cdClassifLabel" for="simpro-cdClassif">
                              <Translate contentKey="generadorApp.simpro.cdClassif">Cd Classif</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ cdClassifSelectValue: evt.target.value })}
                              value={this.state.cdClassifSelectValue ? this.state.cdClassifSelectValue : ''}
                              id="simpro-cdClassif"
                              type="text"
                              name="cdClassif"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ cdClassifSelectValue: evt.target.value })}
                      type="hidden"
                      name="cdClassif"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'cdRefPro' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="cdRefProLabel" for="simpro-cdRefPro">
                              <Translate contentKey="generadorApp.simpro.cdRefPro">Cd Ref Pro</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ cdRefProSelectValue: evt.target.value })}
                              value={this.state.cdRefProSelectValue ? this.state.cdRefProSelectValue : ''}
                              id="simpro-cdRefPro"
                              type="text"
                              name="cdRefPro"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ cdRefProSelectValue: evt.target.value })}
                      type="hidden"
                      name="cdRefPro"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'generico' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="genericoLabel" for="simpro-generico">
                              <Translate contentKey="generadorApp.simpro.generico">Generico</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ genericoSelectValue: evt.target.value })}
                              value={this.state.genericoSelectValue ? this.state.genericoSelectValue : ''}
                              id="simpro-generico"
                              type="text"
                              name="generico"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ genericoSelectValue: evt.target.value })}
                      type="hidden"
                      name="generico"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'status' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="statusLabel" for="simpro-status">
                              <Translate contentKey="generadorApp.simpro.status">Status</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'status_checkbox'}
                                id={'status_checkbox'}
                                checked={this.state.statusSelectValue}
                                onChange={evt => this.setState({ statusSelectValue: !this.state.statusSelectValue })}
                              />
                              <label htmlFor={'status_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ statusSelectValue: evt.target.value })}
                      type="hidden"
                      name="status"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { simproEntity, medicineMaterials, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...simproEntity,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel className="simpro simpro-update">
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToProps = (storeState: IRootState) => {
  return {
    medicineMaterials: storeState.medicineMaterial.entities,
    simproEntity: storeState.simpro.entity,
    loading: storeState.simpro.loading,
    updating: storeState.simpro.updating,
    updateSuccess: storeState.simpro.updateSuccess,
  };
};

export const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  getEntityOffset,
  reset,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SimproUpdate);
