/* eslint complexity: ["error", 200] */

import React from 'react';
import { Switch, RouteComponentProps } from 'react-router-dom';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import PageNotFound from 'app/shared/error/page-not-found';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import AuditEntity from './audit-entity';
import WhiteLabel from '../shared/white-label';
import PageForbidden from 'app/shared/error/page-not-have-permisstion';

import Company from './company';
import Brand from './brand';
import Brasindice from './brasindice';
import Category from './category';
import Cid from './cid';
import ClComRuler from './cl-com-ruler';
import ClComItem from './cl-com-item';
import BillingXml from './billing-xml';
import BillToPay from './bill-to-pay';
import VariableCost from './variable-cost';
import FixedCost from './fixed-cost';
import ClComTable from './cl-com-table';
import ClientContact from './client-contact';
import ClientFile from './client-file';
import MedicalRecordFile from './medical-record-file';
import UserFile from './user-file';
import Client from './client';
import Dose from './dose';
import Specialty from './specialty';
import ExpectedResult from './expected-result';
import LevelGroup from './level-group';
import KitDiet from './kit-diet';
import KitMaterial from './kit-material';
import KitMedicine from './kit-medicine';
import KitProductService from './kit-product-service';
import KitRhs from './kit-rhs';
import Kit from './kit';
import MedicineMaterial from './medicine-material';
import MedicalRecord from './medical-record';
import Nfe from './nfe';
import NfeFile from './nfe-file';
import NotificationUser from './notification-user';
import Notification from './notification';
import NursingDiary from './nursing-diary';
import SubTag from './sub-tag';
import ConfigTag from './config-tag';
import ClinicalIndicator from './clinical-indicator';
import PatientClinicalIndicator from './patient-clinical-indicator';
import NursingPrescription from './nursing-prescription';
import PatientContact from './patient-contact';
import ProfessionalDevice from './professional-device';
import PatientDevice from './patient-device';
import PatNrPrescriptionDiagnostic from './pat-nr-prescription-diagnostic';
import PatientDoctorPrescription from './patient-doctor-prescription';
import DrStatusHistory from './dr-status-history';
import NrStatusHistory from './nr-status-history';
import PatDrPrescrProcd from './pat-dr-prescr-procd';
import PatientFile from './patient-file';
import PatientNursingPrescription from './patient-nursing-prescription';
import PatNrPrescProcediment from './pat-nr-presc-procediment';
import PatientResponsible from './patient-responsible';
import PatientStatusHistory from './patient-status-history';
import ClientStatusHistory from './client-status-history';
import LevelGroupStatusHistory from './level-group-status-history';
import CategoryStatusHistory from './category-status-history';
import SpecialtyStatusHistory from './specialty-status-history';
import ProductServiceStatusHistory from './product-service-status-history';
import KitStatusHistory from './kit-status-history';
import MedicineMaterialStatus from './medicine-material-status';
import ProfessionalStatusHistory from './professional-status-history';
import SupplierStatusHistory from './supplier-status-history';
import Patient from './patient';
import PatientCid from './patient-cid';
import CidStatusHistory from './cid-status-history';
import PatientAllergy from './patient-allergy';
import Permission from './permission';
import PermissionUser from './permission-user';
import PermissionProfile from './permission-profile';
import Profile from './profile';
import PharmacyInventory from './pharmacy-inventory';
import InventoryItem from './inventory-item';
import PharmacyStockDevolution from './pharmacy-stock-devolution';
import PharmacyStock from './pharmacy-stock';
import PharmacyStockStatus from './pharmacy-stock-status';
import Presentation from './presentation';
import ProductService from './product-service';
import ProfessionalAdvice from './professional-advice';
import ProfessionalContact from './professional-contact';
import ProfessionalContractionType from './professional-contraction-type';
import ProfessionalFile from './professional-file';
import ProfessionalOccupationArea from './professional-occupation-area';
import Professional from './professional';
import QuoteDiet from './quote-diet';
import QuoteFile from './quote-file';
import QuoteMaterial from './quote-material';
import QuoteMedicine from './quote-medicine';
import QuoteProductService from './quote-product-service';
import QuoteRh from './quote-rh';
import QuotePackage from './quote-package';
import Quote from './quote';
import Segment from './segment';
import Simpro from './simpro';
import SupplierTable from './supplier-table';
import SupplierTableDiet from './supplier-table-diet';
import SupplierTableMaterial from './supplier-table-material';
import SupplierTableMedicine from './supplier-table-medicine';
import SupplierTableProductService from './supplier-table-product-service';
import SupplierTableRh from './supplier-table-rh';
import SupplierContact from './supplier-contact';
import SupplierFile from './supplier-file';
import Supplier from './supplier';
import Pad from './pad';
import CidPta from './cid-pta';
import PadCidPta from './pad-cid-pta';
import Activity from './activity';
import PadAttendance from './pad-attendance';
import PadAttendanceFile from './pad-attendance-file';
import Push from './push';
import Supply from './supply';
import ClientGlosa from './client-glosa';
import ClientGlosaItem from './client-glosa-item';
import SupplierGlosa from './supplier-glosa';
import SupplierGlosaItem from './supplier-glosa-item';
import PatientSupplier from './patient-supplier';
import PharmacyStockQuote from './pharmacy-stock-quote';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export interface IEntityProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}
const component = [
  { name: 'audit-entity', component: AuditEntity },
  { name: 'white-label', component: WhiteLabel },
  { name: 'company', component: Company },
  { name: 'brand', component: Brand },
  { name: 'brasindice', component: Brasindice },
  { name: 'category', component: Category },
  { name: 'cid', component: Cid },
  { name: 'cl-com-ruler', component: ClComRuler },
  { name: 'cl-com-item', component: ClComItem },
  { name: 'billing-xml', component: BillingXml },
  { name: 'bill-to-pay', component: BillToPay },
  { name: 'variable-cost', component: VariableCost },
  { name: 'fixed-cost', component: FixedCost },
  { name: 'cl-com-table', component: ClComTable },
  { name: 'client-contact', component: ClientContact },
  { name: 'client-file', component: ClientFile },
  { name: 'medical-record-file', component: MedicalRecordFile },
  { name: 'user-file', component: UserFile },
  { name: 'client', component: Client },
  { name: 'dose', component: Dose },
  { name: 'specialty', component: Specialty },
  { name: 'expected-result', component: ExpectedResult },
  { name: 'level-group', component: LevelGroup },
  { name: 'kit-diet', component: KitDiet },
  { name: 'kit-material', component: KitMaterial },
  { name: 'kit-medicine', component: KitMedicine },
  { name: 'kit-product-service', component: KitProductService },
  { name: 'kit-rhs', component: KitRhs },
  { name: 'kit', component: Kit },
  { name: 'medicine-material', component: MedicineMaterial },
  { name: 'medical-record', component: MedicalRecord },
  { name: 'nfe', component: Nfe },
  { name: 'nfe-file', component: NfeFile },
  { name: 'notification-user', component: NotificationUser },
  { name: 'notification', component: Notification },
  { name: 'nursing-diary', component: NursingDiary },
  { name: 'sub-tag', component: SubTag },
  { name: 'config-tag', component: ConfigTag },
  { name: 'clinical-indicator', component: ClinicalIndicator },
  { name: 'patient-clinical-indicator', component: PatientClinicalIndicator },
  { name: 'nursing-prescription', component: NursingPrescription },
  { name: 'patient-contact', component: PatientContact },
  { name: 'professional-device', component: ProfessionalDevice },
  { name: 'patient-device', component: PatientDevice },
  { name: 'pat-nr-prescription-diagnostic', component: PatNrPrescriptionDiagnostic },
  { name: 'patient-doctor-prescription', component: PatientDoctorPrescription },
  { name: 'dr-status-history', component: DrStatusHistory },
  { name: 'nr-status-history', component: NrStatusHistory },
  { name: 'pat-dr-prescr-procd', component: PatDrPrescrProcd },
  { name: 'patient-file', component: PatientFile },
  { name: 'patient-nursing-prescription', component: PatientNursingPrescription },
  { name: 'pat-nr-presc-procediment', component: PatNrPrescProcediment },
  { name: 'patient-responsible', component: PatientResponsible },
  { name: 'patient-status-history', component: PatientStatusHistory },
  { name: 'client-status-history', component: ClientStatusHistory },
  { name: 'level-group-status-history', component: LevelGroupStatusHistory },
  { name: 'category-status-history', component: CategoryStatusHistory },
  { name: 'specialty-status-history', component: SpecialtyStatusHistory },
  { name: 'product-service-status-history', component: ProductServiceStatusHistory },
  { name: 'kit-status-history', component: KitStatusHistory },
  { name: 'medicine-material-status', component: MedicineMaterialStatus },
  { name: 'professional-status-history', component: ProfessionalStatusHistory },
  { name: 'supplier-status-history', component: SupplierStatusHistory },
  { name: 'patient', component: Patient },
  { name: 'patient-cid', component: PatientCid },
  { name: 'cid-status-history', component: CidStatusHistory },
  { name: 'patient-allergy', component: PatientAllergy },
  { name: 'permission', component: Permission },
  { name: 'permission-user', component: PermissionUser },
  { name: 'permission-profile', component: PermissionProfile },
  { name: 'profile', component: Profile },
  { name: 'pharmacy-inventory', component: PharmacyInventory },
  { name: 'inventory-item', component: InventoryItem },
  { name: 'pharmacy-stock-devolution', component: PharmacyStockDevolution },
  { name: 'pharmacy-stock', component: PharmacyStock },
  { name: 'pharmacy-stock-status', component: PharmacyStockStatus },
  { name: 'presentation', component: Presentation },
  { name: 'product-service', component: ProductService },
  { name: 'professional-advice', component: ProfessionalAdvice },
  { name: 'professional-contact', component: ProfessionalContact },
  { name: 'professional-contraction-type', component: ProfessionalContractionType },
  { name: 'professional-file', component: ProfessionalFile },
  { name: 'professional-occupation-area', component: ProfessionalOccupationArea },
  { name: 'professional', component: Professional },
  { name: 'quote-diet', component: QuoteDiet },
  { name: 'quote-file', component: QuoteFile },
  { name: 'quote-material', component: QuoteMaterial },
  { name: 'quote-medicine', component: QuoteMedicine },
  { name: 'quote-product-service', component: QuoteProductService },
  { name: 'quote-rh', component: QuoteRh },
  { name: 'quote-package', component: QuotePackage },
  { name: 'quote', component: Quote },
  { name: 'segment', component: Segment },
  { name: 'simpro', component: Simpro },
  { name: 'supplier-table', component: SupplierTable },
  { name: 'supplier-table-diet', component: SupplierTableDiet },
  { name: 'supplier-table-material', component: SupplierTableMaterial },
  { name: 'supplier-table-medicine', component: SupplierTableMedicine },
  { name: 'supplier-table-product-service', component: SupplierTableProductService },
  { name: 'supplier-table-rh', component: SupplierTableRh },
  { name: 'supplier-contact', component: SupplierContact },
  { name: 'supplier-file', component: SupplierFile },
  { name: 'supplier', component: Supplier },
  { name: 'pad', component: Pad },
  { name: 'cid-pta', component: CidPta },
  { name: 'pad-cid-pta', component: PadCidPta },
  { name: 'activity', component: Activity },
  { name: 'pad-attendance', component: PadAttendance },
  { name: 'pad-attendance-file', component: PadAttendanceFile },
  { name: 'push', component: Push },
  { name: 'supply', component: Supply },
  { name: 'client-glosa', component: ClientGlosa },
  { name: 'client-glosa-item', component: ClientGlosaItem },
  { name: 'supplier-glosa', component: SupplierGlosa },
  { name: 'supplier-glosa-item', component: SupplierGlosaItem },
  { name: 'patient-supplier', component: PatientSupplier },
  { name: 'pharmacy-stock-quote', component: PharmacyStockQuote },
  { name: 'dose', component: Dose },
  { name: 'quote', component: Quote },
  { name: 'cl-com-table', component: ClComTable },
  /* jhipster-needle-add-route-path - JHipster will add routes here */
];
const Routes = (props: IEntityProps) => (
  <div>
    <Switch>
      {component.map((v, i) =>
        hasAnyAuthority(props.userAccount, [v.name]) ? (
          <ErrorBoundaryRoute key={i} path={`${props.match.url + v.name}`} component={v.component} />
        ) : (
          <ErrorBoundaryRoute key={i} path={`${props.match.url + v.name}`} component={PageForbidden} />
        )
      )}
      <ErrorBoundaryRoute component={PageNotFound} />
    </Switch>
  </div>
);

const mapStateToProps = ({ authentication }: IRootState) => ({
  userAccount: authentication.account,
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
