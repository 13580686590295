import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import PadCidPta from './extended/pad-cid-pta';

import PadCidPtaDetail from './pad-cid-pta-detail';

import PadCidPtaUpdate from './pad-cid-pta';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={PadCidPtaUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={PadCidPtaUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={PadCidPtaDetail} />
      <ErrorBoundaryRoute path={match.path} component={PadCidPta} />
    </Switch>
  </>
);

export default Routes;
