/* eslint complexity: ["error", 500] */
import React from 'react';

import { getFilterFromSelect, getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import { connect } from 'react-redux';
import Select from 'react-select';
import SelectAsync from 'react-select/async';
import { Link, RouteComponentProps } from 'react-router-dom';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import {
  Button,
  Col,
  Row,
  Table,
  Label,
  UncontrolledCollapse,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { AvForm, div, AvInput } from 'availity-reactstrap-validation';
import { JhiItemCount } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import ZipCodeInitial from './components/ZipCodeInitial';

import { IRootState } from 'app/shared/reducers';
import {
  updateEntity,
  deleteEntity,
  getUrlBack,
  getProfessionalState,
  IProfessionalBaseState,
  getEntityFiltersURL,
  getEntities,
  reset,
} from './professional.reducer';

import { ITEMS_PER_PAGE, IPaginationBaseState, getSortState } from 'app/shared/util/pagination.constants';

import { StatePropsExtended, DispatchPropsExtended, IExtendedState, IExtendedProps } from './extended/professional';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

export interface IProfessionalProps
  extends StateProps,
    DispatchProps,
    StatePropsExtended,
    DispatchPropsExtended,
    IExtendedProps,
    RouteComponentProps<{ url: string }> {}
export interface IProfessionalStateAux extends IProfessionalBaseState, IExtendedState {}

export interface IProfessionalState extends IProfessionalStateAux, IPaginationBaseState {
  dropdownButtons: {};
}

export class Professional extends React.Component<IProfessionalProps, IProfessionalState> {
  protected myFormRef: any;

  constructor(props: IProfessionalProps) {
    super(props);
    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      listCheckedID: [],
      isCheckedAll: false,
      dropdownButtons: {},
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      urlBack: getUrlBack(this.props.location),
      ...getProfessionalState(this.props.location),
    };
  }

  toggle = btn => {
    const dropdownButtons = this.state.dropdownButtons;
    dropdownButtons[btn] = !dropdownButtons[btn];
    this.setState({ dropdownButtons });
  };

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({ listCheckedID: this.props.professionalList.map(professional => professional.id) });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({ listCheckedID: this.props.professionalList.map(professional => professional.id) });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  removeEntitySelected() {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  }

  saveEntityMany = () => {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  };

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  deleteEntity(professionalEntity) {
    MySwal.fire({
      title: <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>,
      html: (
        <Translate contentKey="generadorApp.professional.delete.question" interpolate={{ id: professionalEntity.id }}>
          Are you sure you want to delete this Professional?
        </Translate>
      ),
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#e70015',
      cancelButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="ban" />
          &nbsp; <Translate contentKey="generadorApp.professional.btnCancel">Cancel</Translate>
        </>
      ),
      confirmButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="trash" />
          &nbsp;<Translate contentKey="generadorApp.professional.btnDelete">Delete</Translate>
        </>
      ),
    }).then(async response => {
      if (response.isConfirmed) {
        await this.props.deleteEntity(professionalEntity.id, { reloadList: false });
        this.getEntities();
        MySwal.fire({
          title: <Translate contentKey="entity.delete.title.confirmed">Confirmed delete operation</Translate>,
          icon: 'success',
        });
      }
    });
  }

  cancelFilters = () => {
    this.setState(
      {
        specialtyCategoryId: '',
        specialtyId: '',
        name: '',
        status: '',
        professionalComplexity: '',
        uf: '',
        city: '',
        extraFilters: {},
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
        activePage: 0,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();

    const selectFieldsList = ['name', 'category.name', 'specialties.name', 'register', 'city', 'uf', 'status'];

    const {
      specialtyCategoryId,
      specialtyId,
      name,
      status,
      professionalComplexity,
      uf,
      city,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;
    return this.props.getEntities(
      getFilterFromSelect(specialtyCategoryId, 'many-to-many'),
      getFilterFromSelect(specialtyId, 'many-to-many'),
      name,
      status,
      professionalComplexity,
      uf,
      city,
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(',')
    );
  };

  updateEntity = entity => {
    const selectFieldsList = ['name', 'category.name', 'specialties.name', 'register', 'city', 'uf', 'status'];

    const {
      specialtyCategoryId,
      specialtyId,
      name,
      status,
      professionalComplexity,
      uf,
      city,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;
    this.props.updateEntity(entity, [
      getFilterFromSelect(specialtyCategoryId, 'many-to-many'),
      getFilterFromSelect(specialtyId, 'many-to-many'),
      name,
      status,
      professionalComplexity,
      uf,
      city,
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(','),
    ]);
  };

  renderHeader() {
    const {
      users,
      padAttendances,
      professionalContacts,
      professionalStatusHistories,
      patientNursingPrescriptions,
      professionalFiles,
      professionalOccupationAreas,
      pushes,
      professionalDevices,
      specialties,
      professionalContractionTypes,
      levelGroups,
      professionalAdvices,
      categories,
      patients,
      professionalList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.professional.home.title">Professionals</Translate>
          </span>
          <Button id="togglerFilterProfessional" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.professional.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          &nbsp;
          {hasAnyAuthority(props.userAccount, ['professional'], 'resgister') ? (
            <Link
              to={`${match.url}/new?${getEntityFiltersURL(state)}`}
              className="btn btn-primary float-right jh-create-entity"
              id="jh-create-entity"
            >
              <FontAwesomeIcon icon="plus" />
              &nbsp;
              <Translate contentKey="generadorApp.professional.btnNewItem">Create new</Translate>
            </Link>
          ) : (
            <></>
          )}{' '}
          &nbsp;
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.professional.home.title">Professionals</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    const {
      users,
      padAttendances,
      professionalContacts,
      professionalStatusHistories,
      patientNursingPrescriptions,
      professionalFiles,
      professionalOccupationAreas,
      pushes,
      professionalDevices,
      specialties,
      professionalContractionTypes,
      levelGroups,
      professionalAdvices,
      categories,
      patients,
      professionalList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <UncontrolledCollapse toggler="#togglerFilterProfessional">
          <CardBody>
            <AvForm ref={el => (this.myFormRef = el)} id="form-filter" onSubmit={this.filterEntity}>
              <div className="row mt-1 ml-3 mr-3">
                {this.state.baseFilters !== 'specialty.category' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="professional-specialty-category">
                          <Translate contentKey="generadorApp.professional.specialty.category">Specialty</Translate>
                        </Label>
                        <SelectAsync
                          id="professional-specialty-category"
                          isMulti
                          className={'css-select-control'}
                          name={'specialty-category'}
                          cacheOptions
                          value={this.state.specialtyCategoryId}
                          onChange={options => this.setState({ specialtyCategoryId: options })}
                          defaultOptions={this.state.specialtyCategoryStartFilter ? this.state.specialtyCategoryStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.specialtyCategoryStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.specialtyCategoryStartFilter === undefined) {
                              const result = await getListAxios(
                                'categories',
                                { 'levelGroup.sub.equals': 'ESPECIALIDADE' },
                                0,
                                100,
                                'name,asc',
                                'name'
                              );
                              this.setState({
                                specialtyCategoryStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios(
                              'categories',
                              { 'levelGroup.sub.equals': 'ESPECIALIDADE', 'name.contains': inputValue },
                              0,
                              100,
                              'name,asc',
                              'name'
                            );
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'specialty' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="professional-specialty">
                          <Translate contentKey="generadorApp.professional.specialty">Specialty</Translate>
                        </Label>
                        <SelectAsync
                          id="professional-specialty"
                          isMulti
                          className={'css-select-control'}
                          name={'specialty'}
                          cacheOptions
                          value={this.state.specialtyId}
                          onChange={options => this.setState({ specialtyId: options })}
                          defaultOptions={this.state.specialtyStartFilter ? this.state.specialtyStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.specialtyStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.specialtyStartFilter === undefined) {
                              const result = await getListAxios(
                                'specialties',
                                { 'levelGroup.sub.equals': 'ESPECIALIDADE' },
                                0,
                                100,
                                'name,asc',
                                'name'
                              );
                              this.setState({
                                specialtyStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios(
                              'specialties',
                              { 'levelGroup.sub.equals': 'ESPECIALIDADE', 'name.contains': inputValue },
                              0,
                              100,
                              'name,asc',
                              'name'
                            );
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'name' ? (
                  <Col md="3" className="col-filter-professional-name">
                    <Row className="mr-1 mt-1">
                      <Label id="nameLabel" for="professional-name">
                        <Translate contentKey="generadorApp.professional.name">Name</Translate>
                      </Label>

                      <AvInput type="text" name="name" id="professional-name" value={this.state.name} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'status' ? (
                  <Col md="3" className="col-filter-professional-status">
                    <Row className="mr-1 mt-1">
                      <Label id="statusLabel" for="professional-status">
                        <Translate contentKey="generadorApp.professional.status">Status</Translate>
                      </Label>
                      <Select
                        id="professional-status"
                        className={'css-select-control'}
                        value={
                          this.state.status
                            ? { value: this.state.status, label: translate('generadorApp.StatusProfessional.' + this.state.status) }
                            : { value: '', label: translate('generadorApp.professional.status') }
                        }
                        options={[
                          { value: '', label: translate('generadorApp.professional.status') },
                          { value: 'AGUARDANDOATIVACAO', label: translate('generadorApp.StatusProfessional.AGUARDANDOATIVACAO') },
                          { value: 'ATIVO', label: translate('generadorApp.StatusProfessional.ATIVO') },
                          { value: 'INATIVADO', label: translate('generadorApp.StatusProfessional.INATIVADO') },
                        ]}
                        onChange={(options: any) => this.setState({ status: options['value'] })}
                        name="status"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'professionalComplexity' ? (
                  <Col md="3" className="col-filter-professional-professionalComplexity">
                    <Row className="mr-1 mt-1">
                      <Label id="professionalComplexityLabel" for="professional-professionalComplexity">
                        <Translate contentKey="generadorApp.professional.professionalComplexity">Patient complexity</Translate>
                      </Label>
                      <Select
                        id="professional-professionalComplexity"
                        className={'css-select-control'}
                        value={
                          this.state.professionalComplexity
                            ? {
                                value: this.state.professionalComplexity,
                                label: translate('generadorApp.ProfessionalComplexity.' + this.state.professionalComplexity),
                              }
                            : { value: '', label: translate('generadorApp.professional.professionalComplexity') }
                        }
                        options={[
                          { value: '', label: translate('generadorApp.professional.professionalComplexity') },
                          { value: 'BAIXA', label: translate('generadorApp.ProfessionalComplexity.BAIXA') },
                          { value: 'MEDIA', label: translate('generadorApp.ProfessionalComplexity.MEDIA') },
                          { value: 'ALTA', label: translate('generadorApp.ProfessionalComplexity.ALTA') },
                          { value: 'VENTILACAOMECANICA', label: translate('generadorApp.ProfessionalComplexity.VENTILACAOMECANICA') },
                        ]}
                        onChange={(options: any) => this.setState({ professionalComplexity: options['value'] })}
                        name="professionalComplexity"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'uf' ? (
                  <Col md="2" className="col-filter-professional-uf">
                    <Row className="mr-1 mt-1">
                      <Label id="ufLabel" for="professional-uf">
                        <Translate contentKey="generadorApp.professional.uf">UF</Translate>
                      </Label>

                      <AvInput type="text" name="uf" id="professional-uf" value={this.state.uf} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'city' ? (
                  <Col md="3" className="col-filter-professional-city">
                    <Row className="mr-1 mt-1">
                      <Label id="cityLabel" for="professional-city">
                        <Translate contentKey="generadorApp.professional.city">City</Translate>
                      </Label>

                      <AvInput type="text" name="city" id="professional-city" value={this.state.city} />
                    </Row>
                  </Col>
                ) : null}
                {this.state.baseFilters !== 'ZipCodeInitial' ? (
                  <Col md="4">
                    <ZipCodeInitial
                      entityBase={this.state}
                      baseState={this.state}
                      baseProps={this.props}
                      setState={_state => this.setState(_state)}
                    />
                  </Col>
                ) : (
                  <> </>
                )}
              </div>

              <div className="row mb-2 mr-4 justify-content-end">
                <Button color="primary" type="submit">
                  <i className="fa fa-filter" aria-hidden={'true'}></i>&nbsp;
                  <Translate contentKey="generadorApp.professional.btnFilter">Filter</Translate>
                </Button>
                &nbsp;
                <Button color="secondary" onClick={this.cancelFilters}>
                  <FontAwesomeIcon icon="trash-alt" />
                  &nbsp;
                  <Translate contentKey="generadorApp.professional.btnCleanFilter">Clean Filter</Translate>
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </UncontrolledCollapse>
      </>
    );
  }

  renderTable() {
    const {
      users,
      padAttendances,
      professionalContacts,
      professionalStatusHistories,
      patientNursingPrescriptions,
      professionalFiles,
      professionalOccupationAreas,
      pushes,
      professionalDevices,
      specialties,
      professionalContractionTypes,
      levelGroups,
      professionalAdvices,
      categories,
      patients,
      professionalList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {professionalList && professionalList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null).length > 0 ? (
          <>
            <Table
              id="professional-table-list"
              responsive
              aria-describedby="professional-heading"
              className={'table-hover table-striped mt-4 table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.professional.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'name' ? (
                    <th className="hand" onClick={sortFunction('name')}>
                      <Translate contentKey="generadorApp.professional.name">Name</Translate>
                      <FontAwesomeIcon icon={state.sort === 'name' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'category.name' ? (
                    <th>
                      <Translate contentKey="generadorApp.professional.category">Category</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'specialty' ? (
                    <th>
                      <Translate contentKey="generadorApp.professional.specialty">Specialty</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'register' ? (
                    <th className="hand" onClick={sortFunction('register')}>
                      <Translate contentKey="generadorApp.professional.register">Professional register</Translate>
                      <FontAwesomeIcon icon={state.sort === 'register' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'city' ? (
                    <th className="hand" onClick={sortFunction('city')}>
                      <Translate contentKey="generadorApp.professional.city">City</Translate>
                      <FontAwesomeIcon icon={state.sort === 'city' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'uf' ? (
                    <th className="hand" onClick={sortFunction('uf')}>
                      <Translate contentKey="generadorApp.professional.uf">UF</Translate>
                      <FontAwesomeIcon icon={state.sort === 'uf' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'status' ? (
                    <th className="hand" onClick={sortFunction('status')}>
                      <Translate contentKey="generadorApp.professional.status">Status</Translate>
                      <FontAwesomeIcon icon={state.sort === 'status' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}

                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {professionalList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((professional, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.professional.field_id">ID</Translate>
                        </b>
                        {professional.id}
                      </td>

                      {state.baseFilters !== 'name' ? (
                        <td id="name-cell">
                          <b className="visible-xs"> Name </b>

                          {professional.name}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'category' ? (
                        <td id="category-cell">
                          <b className="visible-xs"> Category </b>
                          {showFieldsSelectAsync(professional, 'category.name', null).map((v, ikey) => (
                            <span key={ikey}> {v} </span>
                          ))}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'specialty' ? (
                        <td id="specialty-cell">
                          <b className="visible-xs"> Specialty </b>
                          {showFieldsSelectAsync(professional, 'specialties.name', null).map((v, ikey) => (
                            <span key={ikey}> {v} </span>
                          ))}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'register' ? (
                        <td id="register-cell">
                          <b className="visible-xs"> Professional register </b>

                          {professional.register}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'city' ? (
                        <td id="city-cell">
                          <b className="visible-xs"> City </b>

                          {professional.city}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'uf' ? (
                        <td id="uf-cell">
                          <b className="visible-xs"> UF </b>

                          {professional.uf}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'status' ? (
                        <td id="status-cell">
                          <b className="visible-xs"> Status </b>
                          {professional.status ? (
                            <Translate contentKey={`generadorApp.StatusProfessional.${professional.status}`} />
                          ) : (
                            <> </>
                          )}
                        </td>
                      ) : null}

                      <td className="text-right">
                        <Dropdown isOpen={state.dropdownButtons[i]} toggle={() => this.toggle(i)}>
                          <DropdownToggle caret>
                            <Translate contentKey="generadorApp.professional.btnActions">Actions</Translate>
                          </DropdownToggle>
                          <DropdownMenu right>
                            {hasAnyAuthority(props.userAccount, ['professional'], 'view') ? (
                              <DropdownItem tag={Link} to={`${match.url}/${professional.id}`} color="info" size="sm">
                                <FontAwesomeIcon icon="eye" />

                                <span className="d-none d-md-inline">
                                  <Translate contentKey="generadorApp.professional.listButtons.view">View</Translate>
                                </span>
                              </DropdownItem>
                            ) : (
                              <></>
                            )}{' '}
                            {hasAnyAuthority(props.userAccount, ['professional'], 'edit') ? (
                              <DropdownItem
                                tag={Link}
                                to={`${match.url}/${professional.id}/edit?${getEntityFiltersURL(state)}`}
                                color="info"
                                size="sm"
                              >
                                <FontAwesomeIcon icon="pencil-alt" />

                                <span className="d-none d-md-inline">
                                  <Translate contentKey="generadorApp.professional.listButtons.edit">Edit</Translate>
                                </span>
                              </DropdownItem>
                            ) : (
                              <></>
                            )}{' '}
                            {hasAnyAuthority(props.userAccount, ['professional'], 'canDelete') ? (
                              <DropdownItem onClick={() => this.deleteEntity(professional)} color="danger" size="sm">
                                <FontAwesomeIcon icon="trash" />

                                <span className="d-none d-md-inline">
                                  <Translate contentKey="generadorApp.professional.listButtons.delete">Delete</Translate>
                                </span>
                              </DropdownItem>
                            ) : (
                              <></>
                            )}{' '}
                            {hasAnyAuthority(props.userAccount, ['professional'], 'edit') ? (
                              <DropdownItem tag={Link} to={`/professional/${professional.id}/professional-file`} color="info" size="sm">
                                <FontAwesomeIcon icon="file" />

                                <span className="d-none d-md-inline">
                                  <Translate contentKey="generadorApp.professional.listButtons.file">file</Translate>
                                </span>
                              </DropdownItem>
                            ) : (
                              <></>
                            )}{' '}
                            {hasAnyAuthority(props.userAccount, ['professional'], 'edit') ? (
                              <DropdownItem
                                tag={Link}
                                to={`/professional/${professional.id}/professional-status-history`}
                                color="info"
                                size="sm"
                              >
                                <FontAwesomeIcon icon="check-square" />

                                <span className="d-none d-md-inline">
                                  <Translate contentKey="generadorApp.professional.listButtons.Status">Status</Translate>
                                </span>
                              </DropdownItem>
                            ) : (
                              <></>
                            )}{' '}
                            {hasAnyAuthority(props.userAccount, ['professional'], 'edit') ? (
                              <DropdownItem tag={Link} to={`/professional/${professional.id}/professional-contact`} color="info" size="sm">
                                <FontAwesomeIcon icon="user" />

                                <span className="d-none d-md-inline">
                                  <Translate contentKey="generadorApp.professional.listButtons.contact">contact</Translate>
                                </span>
                              </DropdownItem>
                            ) : (
                              <></>
                            )}{' '}
                            {hasAnyAuthority(props.userAccount, ['professional'], 'edit') ? (
                              <DropdownItem
                                tag={Link}
                                to={`/professional/${professional.id}/professional-occupation-area`}
                                color="info"
                                size="sm"
                              >
                                <FontAwesomeIcon icon="briefcase" />

                                <span className="d-none d-md-inline">
                                  <Translate contentKey="generadorApp.professional.listButtons.occupation">occupation</Translate>
                                </span>
                              </DropdownItem>
                            ) : (
                              <></>
                            )}{' '}
                            {hasAnyAuthority(props.userAccount, ['professional'], 'edit') ? (
                              <DropdownItem
                                tag={Link}
                                to={`/attendance-list?professional=${professional.id}<->${professional.name}`}
                                color="info"
                                size="sm"
                              >
                                <FontAwesomeIcon icon="briefcase" />

                                <span className="d-none d-md-inline">
                                  <Translate contentKey="generadorApp.professional.listButtons.attendance">Attendance</Translate>
                                </span>
                              </DropdownItem>
                            ) : (
                              <></>
                            )}{' '}
                          </DropdownMenu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.professional.home.notFound">No Professionals found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const {
      users,
      padAttendances,
      professionalContacts,
      professionalStatusHistories,
      patientNursingPrescriptions,
      professionalFiles,
      professionalOccupationAreas,
      pushes,
      professionalDevices,
      specialties,
      professionalContractionTypes,
      levelGroups,
      professionalAdvices,
      categories,
      patients,
      professionalList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={professionalList && professionalList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const {
      users,
      padAttendances,
      professionalContacts,
      professionalStatusHistories,
      patientNursingPrescriptions,
      professionalFiles,
      professionalOccupationAreas,
      pushes,
      professionalDevices,
      specialties,
      professionalContractionTypes,
      levelGroups,
      professionalAdvices,
      categories,
      patients,
      professionalList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel className="professional professional-list-table">
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToProps = ({ ...storeState }: IRootState) => {
  return {
    users: storeState.userManagement.users,
    padAttendances: storeState.padAttendance.entities,
    professionalContacts: storeState.professionalContact.entities,
    professionalStatusHistories: storeState.professionalStatusHistory.entities,
    patientNursingPrescriptions: storeState.patientNursingPrescription.entities,
    professionalFiles: storeState.professionalFile.entities,
    professionalOccupationAreas: storeState.professionalOccupationArea.entities,
    pushes: storeState.push.entities,
    professionalDevices: storeState.professionalDevice.entities,
    specialties: storeState.specialty.entities,
    professionalContractionTypes: storeState.professionalContractionType.entities,
    levelGroups: storeState.levelGroup.entities,
    professionalAdvices: storeState.professionalAdvice.entities,
    categories: storeState.category.entities,
    patients: storeState.patient.entities,
    professionalList: storeState.professional.entities,
    totalItems: storeState.professional.totalItems,
    userAccount: storeState.authentication.account,
    loading: storeState.professional.loading,
  };
};

export const mapDispatchToProps = {
  getEntities,

  deleteEntity,
  updateEntity,
  reset,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Professional);
