/* eslint complexity: ["error", 300] */
import React from 'react';
import { connect } from 'react-redux';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { DrStatusHistory, mapStateToProps, mapDispatchToProps } from '../dr-status-history';
import { IRootState } from 'app/shared/reducers';

export interface IExtendedState {
  dumystate?: any;
}
export interface IExtendedProps {
  entityEmbebedPai?: any;
}

export class DrStatusHistoryExtended extends DrStatusHistory {
  renderHeader() {
    return <> {super.renderHeader()} </>;
  }

  renderFilter() {
    return <> {super.renderFilter()} </>;
  }

  renderTable() {
    return <> {super.renderTable()} </>;
  }

  renderFooter() {
    return <> {super.renderFooter()} </>;
  }

  render() {
    const { drStatusHistoryList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel>
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">{this.renderTable()}</div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;

export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(DrStatusHistoryExtended);
