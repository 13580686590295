import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { IClientGlosa } from 'app/shared/model/client-glosa.model';
import { IQuoteMedicine } from 'app/shared/model/quote-medicine.model';
import { IQuoteDiet } from 'app/shared/model/quote-diet.model';
import { IQuoteMaterial } from 'app/shared/model/quote-material.model';
import { IQuoteProductService } from 'app/shared/model/quote-product-service.model';
import { IQuoteRh } from 'app/shared/model/quote-rh.model';
import { IQuotePackage } from 'app/shared/model/quote-package.model';
import { TypeBilling } from 'app/shared/model/enumerations/type-billing.model';
import { GeralSelect } from 'app/shared/model/enumerations/geral-select.model';

export interface IClientGlosaItem {
  id?: number;
  secOrder?: number;
  typeItemGlosa?: TypeBilling;
  quantityGlossed?: number;
  quantityRefused?: number;
  justification?: string;
  glosaItemAceita?: GeralSelect;
  clientGlosa?: IClientGlosa;
  quoteMedicine?: IQuoteMedicine;
  quoteDiet?: IQuoteDiet;
  quoteMaterial?: IQuoteMaterial;
  quoteProductService?: IQuoteProductService;
  quoteRh?: IQuoteRh;
  quotePackage?: IQuotePackage;
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  clientGlosa: {
    isMulti: true, // many-to-one - other-side
    entity: 'ClientGlosa',
    urlParameters: {
      enpoint: 'client-glosas',
      filters: 'clientGlosaItem.id.equals',
    },
  },
  quoteMedicine: {
    isMulti: true, // many-to-one - other-side
    entity: 'QuoteMedicine',
    urlParameters: {
      enpoint: 'quote-medicines',
      filters: 'clientGlosaItem.id.equals',
    },
  },
  quoteDiet: {
    isMulti: true, // many-to-one - other-side
    entity: 'QuoteDiet',
    urlParameters: {
      enpoint: 'quote-diets',
      filters: 'clientGlosaItem.id.equals',
    },
  },
  quoteMaterial: {
    isMulti: true, // many-to-one - other-side
    entity: 'QuoteMaterial',
    urlParameters: {
      enpoint: 'quote-materials',
      filters: 'clientGlosaItem.id.equals',
    },
  },
  quoteProductService: {
    isMulti: true, // many-to-one - other-side
    entity: 'QuoteProductService',
    urlParameters: {
      enpoint: 'quote-product-services',
      filters: 'clientGlosaItem.id.equals',
    },
  },
  quoteRh: {
    isMulti: true, // many-to-one - other-side
    entity: 'QuoteRh',
    urlParameters: {
      enpoint: 'quote-rhs',
      filters: 'clientGlosaItem.id.equals',
    },
  },
  quotePackage: {
    isMulti: true, // many-to-one - other-side
    entity: 'QuotePackage',
    urlParameters: {
      enpoint: 'quote-packages',
      filters: 'clientGlosaItem.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'clientGlosaItem.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'clientGlosaItem.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<IClientGlosaItem> = {};
