import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import MedicineMaterialStatus from './extended/medicine-material-status';

import MedicineMaterialStatusDetail from './medicine-material-status-detail';

import MedicineMaterialStatusUpdate from './medicine-material-status';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={MedicineMaterialStatusUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={MedicineMaterialStatusUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={MedicineMaterialStatusDetail} />
      <ErrorBoundaryRoute path={match.path} component={MedicineMaterialStatus} />
    </Switch>
  </>
);

export default Routes;
