import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';

import { NavDropdown } from './menu-components';
import { EntitiesMenu } from './entities';

const accountMenuItemsAuthenticated = (
  <>
    <EntitiesMenu />
    <MenuItem icon="wrench" to="/account/settings">
      Conta
    </MenuItem>
    <MenuItem icon="lock" to="/account/password">
      Senha
    </MenuItem>
    <MenuItem icon="sign-out-alt" to="/logout">
      Sair
    </MenuItem>
  </>
);

const accountMenuItems = (
  <MenuItem id="login-item" icon="sign-in-alt" to="/login">
    Entrar
  </MenuItem>
);

export const AccountMenu = ({ isAuthenticated = false }) => (
  <NavDropdown icon="user" name="Conta">
    {isAuthenticated ? accountMenuItemsAuthenticated : accountMenuItems}
  </NavDropdown>
);

export default AccountMenu;
