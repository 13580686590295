/* eslint complexity: ["error", 500] */
/* eslint no-empty: off */
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import SelectAsync from 'react-select/async';
import axios from 'axios';
import { Link, RouteComponentProps } from 'react-router-dom';

import {
  getFilterFromSelect,
  getFormFromSelect,
  getListAxios,
  showFieldsSelectAsync,
  checkAnyValueFields,
} from 'app/shared/util/entity-utils';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Col, Label } from 'reactstrap';
import { setFileData } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import {
  ISupplierTableUpdateState as IBaseUpdateState,
  getEntity,
  getSupplierTableState,
  getUrlBack,
  updateEntity,
  createEntity,
  getEntityFiltersURL,
  getEntityOffset,
  setBlob,
  reset,
  supplierTableProductServiceSetEntities,
  supplierTableProductServiceGetEntities,
  supplierTableProductServiceUpdateEntity,
  supplierTableProductServiceReset,
  supplierTableProductServiceGetEntity,
  supplierTableProductServiceCreateEntity,
  supplierTableProductServiceDeleteEntity,
} from './supplier-table.reducer';

import {
  mapStateToProps as mapStateToPropsSupplierTableProductService,
  mapDispatchToProps as mapDispatchToPropsSupplierTableProductService,
  SupplierTableProductService,
} from 'app/entities/supplier-table-product-service/supplier-table-product-service';

import { StatePropsExtended, DispatchPropsExtended, IUpdateExtendedState } from './extended/supplier-table-update';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

registerLocale('pt', pt);

export interface ISupplierTableUpdateProps
  extends StateProps,
    DispatchProps,
    StatePropsExtended,
    DispatchPropsExtended,
    RouteComponentProps<{ url: string }> {}
export interface ISupplierTableUpdateState extends IBaseUpdateState, IUpdateExtendedState {}

export class SupplierTableUpdate extends React.Component<ISupplierTableUpdateProps, ISupplierTableUpdateState> {
  nameFileInput: any;

  statusFileInput: any;

  observationFileInput: any;
  constructor(props: Readonly<ISupplierTableUpdateProps>) {
    super(props);

    this.supplierTableProductServiceUpdateEntity = this.supplierTableProductServiceUpdateEntity.bind(this);
    this.supplierTableProductServiceGetEntities = this.supplierTableProductServiceGetEntities.bind(this);
    this.supplierTableProductServiceReset = this.supplierTableProductServiceReset.bind(this);
    this.supplierTableProductServiceGetEntity = this.supplierTableProductServiceGetEntity.bind(this);
    this.supplierTableProductServiceCreateEntity = this.supplierTableProductServiceCreateEntity.bind(this);
    this.supplierTableProductServiceDeleteEntity = this.supplierTableProductServiceDeleteEntity.bind(this);

    this.observationFileInput = React.createRef();

    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      functionEmbebed: [],

      supplierTableDietSelectValue: null,

      supplierTableMaterialSelectValue: null,

      supplierTableMedicineSelectValue: null,

      supplierTableProductServiceSelectValue: null,

      supplierTableRhSelectValue: null,

      supplierSelectValue: { value: this.props.match.params['idSupplier'], label: this.props.match.params['idSupplier'] },

      nameSelectValue: null,
      statusSelectValue: null,
      observationSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getSupplierTableState(this.props.location),
        urlBack: getUrlBack(this.props.location),
        supplierId: [{ value: this.props.match.params['idSupplier'], label: this.props.match.params['idSupplier'] }],
        baseFilters: 'supplier',
      },
      supplierFantasyName: null,

      isNew: !this.props.match.params || !this.props.match.params['id'],
    };

    axios
      .get('/api/suppliers/' + this.props.match.params['idSupplier'], {
        headers: {
          'Select-Fields': 'fantasyName',
        },
      })
      .then(result => {
        this.setState({
          supplierSelectValue: {
            ...result['data'],
            value: this.props.match.params['idSupplier'],
            label: showFieldsSelectAsync(result['data'], 'fantasyName'),
          },
        });
      });
  }

  supplierTableProductServiceUpdateEntity(...values) {
    values[0]['supplierTable'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['updateEntitySupplierTableProductService'](...values);
    }
    return this.props.supplierTableProductServiceUpdateEntity(...values);
  }
  supplierTableProductServiceGetEntities(...values) {
    return this.props.supplierTableProductServiceGetEntities(...values);
  }
  supplierTableProductServiceReset(...values) {
    return this.props.supplierTableProductServiceReset(...values);
  }
  supplierTableProductServiceGetEntity(...values) {
    return this.props.supplierTableProductServiceGetEntity(...values);
  }
  supplierTableProductServiceCreateEntity(...values) {
    values[0]['supplierTable'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['createEntitySupplierTableProductService'](...values);
    }
    return this.props.supplierTableProductServiceCreateEntity(...values);
  }
  supplierTableProductServiceDeleteEntity(...values) {
    if (!this.state.isNew) {
      this.props['deleteEntitySupplierTableProductService'](...values);
    }
    return this.props.supplierTableProductServiceDeleteEntity(...values);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateSuccess !== this.props.updateSuccess && prevProps.updateSuccess) {
      this.handleClose();
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.supplierTableEntity && prevProps.supplierTableEntity.id !== this.props.supplierTableEntity.id) ||
      (this.props.supplierTableDiets.length > 0 &&
        this.state.supplierTableDietSelectValue === null &&
        this.props.supplierTableEntity.supplierTableDiet)
    ) {
      this.setState({
        supplierTableDietSelectValue:
          this.props.supplierTableEntity && this.props.supplierTableEntity.supplierTableDiet
            ? this.props.supplierTableEntity.supplierTableDiet.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.supplierTableEntity && prevProps.supplierTableEntity.id !== this.props.supplierTableEntity.id) ||
      (this.props.supplierTableMaterials.length > 0 &&
        this.state.supplierTableMaterialSelectValue === null &&
        this.props.supplierTableEntity.supplierTableMaterial)
    ) {
      this.setState({
        supplierTableMaterialSelectValue:
          this.props.supplierTableEntity && this.props.supplierTableEntity.supplierTableMaterial
            ? this.props.supplierTableEntity.supplierTableMaterial.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.supplierTableEntity && prevProps.supplierTableEntity.id !== this.props.supplierTableEntity.id) ||
      (this.props.supplierTableMedicines.length > 0 &&
        this.state.supplierTableMedicineSelectValue === null &&
        this.props.supplierTableEntity.supplierTableMedicine)
    ) {
      this.setState({
        supplierTableMedicineSelectValue:
          this.props.supplierTableEntity && this.props.supplierTableEntity.supplierTableMedicine
            ? this.props.supplierTableEntity.supplierTableMedicine.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.supplierTableEntity && prevProps.supplierTableEntity.id !== this.props.supplierTableEntity.id) ||
      (this.props.supplierTableProductServices.length > 0 &&
        this.state.supplierTableProductServiceSelectValue === null &&
        this.props.supplierTableEntity.supplierTableProductService)
    ) {
      this.setState({ supplierTableProductServiceSelectValue: this.props.supplierTableEntity.supplierTableProductService });
      this.props.supplierTableProductServiceSetEntities(this.props.supplierTableEntity.supplierTableProductService);
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.supplierTableEntity && prevProps.supplierTableEntity.id !== this.props.supplierTableEntity.id) ||
      (this.props.supplierTableRhs.length > 0 &&
        this.state.supplierTableRhSelectValue === null &&
        this.props.supplierTableEntity.supplierTableRh)
    ) {
      this.setState({
        supplierTableRhSelectValue:
          this.props.supplierTableEntity && this.props.supplierTableEntity.supplierTableRh
            ? this.props.supplierTableEntity.supplierTableRh.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.supplierTableEntity && prevProps.supplierTableEntity.id !== this.props.supplierTableEntity.id) ||
      (this.props.suppliers &&
        this.state.supplierSelectValue === null &&
        this.props.supplierTableEntity.supplier &&
        this.props.supplierTableEntity.supplier.id)
    ) {
      const p = this.props.supplierTableEntity && this.props.supplierTableEntity.supplier ? this.props.supplierTableEntity.supplier : null;
      this.setState({
        supplierSelectValue: p ? { ...p, value: p.id, label: p['fantasyName'] ? p.fantasyName : '' } : null,
      });
    }

    if (
      (prevProps.supplierTableEntity && prevProps.supplierTableEntity.id !== this.props.supplierTableEntity.id) ||
      (this.props.supplierTableEntity.name &&
        this.state.nameSelectValue === null &&
        this.props.supplierTableEntity.name !== this.state.nameSelectValue)
    ) {
      this.setState({ nameSelectValue: this.props.supplierTableEntity.name ? this.props.supplierTableEntity.name : null });
    }
    if (
      (prevProps.supplierTableEntity && prevProps.supplierTableEntity.id !== this.props.supplierTableEntity.id) ||
      (this.state.statusSelectValue === null && this.props.supplierTableEntity.status !== this.state.statusSelectValue)
    ) {
      this.setState({ statusSelectValue: this.props.supplierTableEntity.status ? this.props.supplierTableEntity.status : 'ATIVO' });
    }
    if (
      (prevProps.supplierTableEntity && prevProps.supplierTableEntity.id !== this.props.supplierTableEntity.id) ||
      (this.props.supplierTableEntity.observation &&
        this.state.observationSelectValue === null &&
        this.props.supplierTableEntity.observation !== this.state.observationSelectValue)
    ) {
      this.setState({
        observationSelectValue: this.props.supplierTableEntity.observation ? this.props.supplierTableEntity.observation : null,
      });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  UNSAFE_componentWillMount() {
    if (this.state.isNew) {
      this.props.reset();
      this.supplierTableProductServiceReset();
    } else {
      this.props.getEntity(this.props.match.params['id']);
    }
  }

  onBlobChange = (isAnImage, name, fileInput) => event => {
    const fileName = event.target.files[0].name;
    setFileData(event, (contentType, data) => this.props.setBlob(name, data, contentType, fileName), isAnImage);
    const varAux = {};
    varAux[name + 'SelectValue'] = fileName;
    this.setState(varAux);
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined);
  };
  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = offset !== null ? '&offset=' + offset : '';
    Object.keys(fieldsBase).map(key => {
      url += key !== 'offset' ? '&' + key + '=' + fieldsBase[key] : '';
    });
    return url;
  };
  saveEntity = (event: any, errors: any, values: any) => {
    const selectFieldsList = ['name', 'status', 'observation', 'supplier.fantasyName', 'createdDate', 'lastModifiedDate'];

    if (errors.length === 0) {
      const { supplierTableEntity } = this.props;
      const _entity = {
        ...supplierTableEntity,
        // ...values,

        supplier: getFormFromSelect(this.state.supplierSelectValue, 'many-to-one'), // many-to-one - otherSide

        supplierTableProductService: undefined,

        name: this.state.nameSelectValue,
        status: this.state.statusSelectValue === null ? null : this.state.statusSelectValue,
        observation: this.state.observationSelectValue,
      };

      const entity = _entity;

      const { name, status, observation, supplierId, extraFilters, activePage, itemsPerPage, sort, order } = this.state.fieldsBase;

      const supplierTableProductServiceListAllEmbed = this.props.supplierTableProductServiceListAllEmbed;

      if (this.state.isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              name,
              status,
              observation,
              getFilterFromSelect(supplierId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
          if (idEntity) {
            supplierTableProductServiceListAllEmbed.map(v => {
              v['id'] = null;
              v['supplierTable'] = { id: idEntity };
              this.props['createEntitySupplierTableProductService'](v);
            });
          }
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              name,
              status,
              observation,
              getFilterFromSelect(supplierId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
    }
  };

  handleClose = () => {
    this.props.history.push(
      '/supplier/' + this.props.match.params['idSupplier'] + '/supplier-table?' + getEntityFiltersURL(this.state.fieldsBase)
    );
  };

  getFullEntityFromState(v) {
    return {
      id: v.id,
      name: this.state.nameSelectValue,
      status: this.state.statusSelectValue,
      observation: this.state.observationSelectValue,
      supplierTableDiet: this.state.supplierTableDietSelectValue,
      supplierTableMaterial: this.state.supplierTableMaterialSelectValue,
      supplierTableMedicine: this.state.supplierTableMedicineSelectValue,
      supplierTableProductService: this.state.supplierTableProductServiceSelectValue,
      supplierTableRh: this.state.supplierTableRhSelectValue,
      supplier: this.state.supplierSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    const supplierTableDietEntity = v.supplierTableDiet;

    const supplierTableMaterialEntity = v.supplierTableMaterial;

    const supplierTableMedicineEntity = v.supplierTableMedicine;

    const supplierTableProductServiceEntity = v.supplierTableProductService;

    const supplierTableRhEntity = v.supplierTableRh;

    const supplierEntity = v.supplier;

    return {
      nameSelectValue: v.name,
      statusSelectValue: v.status,
      observationSelectValue: v.observation,
      supplierTableDietSelectValue: supplierTableDietEntity ? supplierTableDietEntity['id'] : null,
      supplierTableMaterialSelectValue: supplierTableMaterialEntity ? supplierTableMaterialEntity['id'] : null,
      supplierTableMedicineSelectValue: supplierTableMedicineEntity ? supplierTableMedicineEntity['id'] : null,
      supplierTableProductServiceSelectValue: supplierTableProductServiceEntity ? supplierTableProductServiceEntity['id'] : null,
      supplierTableRhSelectValue: supplierTableRhEntity ? supplierTableRhEntity['id'] : null,
      supplierSelectValue: supplierEntity ? supplierEntity['id'] : null,
    };
  }

  renderHeader() {
    const {
      supplierTableEntity,
      supplierTableDiets,
      supplierTableMaterials,
      supplierTableMedicines,
      supplierTableProductServices,
      supplierTableRhs,
      suppliers,
      loading,
      updating,
    } = this.props;
    const { isNew } = this.state;

    const { observation } = supplierTableEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.supplierTable.home.createLabel">Create a SupplierTable</Translate>
            ) : (
              <Translate contentKey="generadorApp.supplierTable.home.editLabel">Edit a SupplierTable</Translate>
            )}
          </span>

          <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.supplierTable.btnSave">Save</Translate>
          </Button>

          {!this.state.isNew ? (
            <>
              <Button
                color="primary"
                id="next-entity"
                disabled={updating}
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.name,
                    this.state.fieldsBase.status,
                    this.state.fieldsBase.observation,
                    this.state.fieldsBase.supplierId,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 + 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 + 1 },
                    });
                    this.props.history.push(
                      '/supplier-table/' +
                        myresult.value.data[0]['id'] +
                        '/edit?' +
                        this.getFiltersURL(this.state.fieldsBase.offset * 1 + 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.last.item', { fieldName: translate('generadorApp.supplierTable.detail.title') }));
                  }
                }}
                className="float-right jh-create-entity"
              >
                {'>>'}
              </Button>

              <Button
                color="primary"
                id="prev-entity"
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.name,
                    this.state.fieldsBase.status,
                    this.state.fieldsBase.observation,
                    this.state.fieldsBase.supplierId,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 - 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 - 1 },
                    });
                    this.props.history.push(
                      '/supplier-table/' +
                        myresult.value.data[0]['id'] +
                        '/edit?' +
                        this.getFiltersURL(this.state.fieldsBase.offset * 1 - 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.first.item', { fieldName: translate('generadorApp.supplierTable.detail.title') }));
                  }
                }}
                disabled={updating}
                className="float-right jh-create-entity"
              >
                {'<<'}
              </Button>
            </>
          ) : (
            <> </>
          )}

          <Button
            tag={Link}
            id="cancel-save"
            to={'/supplier/' + this.props.match.params['idSupplier'] + '/supplier-table?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.supplierTable.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/supplier'}>
              <Translate contentKey="generadorApp.supplier.home.title">Suppliers</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/supplier/' + this.props.match.params['idSupplier']}>
              {this.state.supplierSelectValue ? this.state.supplierSelectValue.label : this.props.match.params['idSupplier']}
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/supplier/' + this.props.match.params['idSupplier'] + '/supplier-table'}>
              <Translate contentKey="generadorApp.supplierTable.home.title">Supplier Commercial Tables</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.supplierTable.detail.title">Supplier Commercial Table edit</Translate>
          </li>
        </ol>
      </>
    );
  }
  renderBody() {
    const {
      supplierTableEntity,
      supplierTableDiets,
      supplierTableMaterials,
      supplierTableMedicines,
      supplierTableProductServices,
      supplierTableRhs,
      suppliers,
      loading,
      updating,
    } = this.props;
    const { isNew } = this.state;

    const { observation } = supplierTableEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const fullEntityEmbebed = this.getFullEntityFromState(supplierTableEntity);

    const mapDispatchToPropsSupplierTableProductServiceList: any = {};
    Object.keys(mapDispatchToPropsSupplierTableProductService).map(v => {
      mapDispatchToPropsSupplierTableProductServiceList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'SupplierTableProductService')) {
          this.setState(
            { functionEmbebed: [...this.state.functionEmbebed, v + 'SupplierTableProductService'] },
            this.props[v + 'SupplierTableProductService']
          );
        }
      };
    });

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="supplier-table-id">
                  <Translate contentKey="generadorApp.supplierTable.field_id">ID</Translate>
                </Label>
                </Col> */}
                      <Col md="12">
                        <AvInput id="supplier-table-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'name' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="nameLabel" for="supplier-table-name">
                              <Translate contentKey="generadorApp.supplierTable.name">Name</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                              value={this.state.nameSelectValue ? this.state.nameSelectValue : ''}
                              id="supplier-table-name"
                              type="text"
                              name="name"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                      type="hidden"
                      name="name"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'status' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="statusLabel" for="supplier-table-status">
                              <Translate contentKey="generadorApp.supplierTable.status">Status</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="supplier-table-status"
                              className={'css-select-control'}
                              value={
                                this.state.statusSelectValue
                                  ? {
                                      value: this.state.statusSelectValue,
                                      label: translate('generadorApp.Status.' + this.state.statusSelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.supplierTable.status') }
                              }
                              options={[
                                { value: 'INATIVADO', label: translate('generadorApp.Status.INATIVADO') },
                                { value: 'ATIVO', label: translate('generadorApp.Status.ATIVO') },
                              ]}
                              onChange={(options: any) => this.setState({ statusSelectValue: options['value'] })}
                              name="status"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ statusSelectValue: evt.target.value })}
                      type="hidden"
                      name="status"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'observation' ? (
                    <Col md="12">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="observationLabel" for="supplier-table-observation">
                              <Translate contentKey="generadorApp.supplierTable.observation">Observation</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <AvInput
                              onChange={evt => this.setState({ observationSelectValue: evt.target.value })}
                              validate={{}}
                              id="supplier-table-observation"
                              type="textarea"
                              name="observation"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ observationSelectValue: evt.target.value })}
                      type="hidden"
                      name="observation"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'supplier' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="supplier-table-supplier">
                              <Translate contentKey="generadorApp.supplierTable.supplier">Supplier</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="supplier-table-supplier"
                              name={'supplier'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.supplierSelectValue ? this.state.supplierSelectValue : ''}
                              onChange={options => this.setState({ supplierSelectValue: options })}
                              defaultOptions={this.state.supplierStartSelectOptions ? this.state.supplierStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.supplierStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.supplierStartSelectOptions === undefined) {
                                  const result = await getListAxios('suppliers', {}, 0, 100, 'fantasyName,asc', 'fantasyName');
                                  this.setState({
                                    supplierStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'fantasyName') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'suppliers',
                                  { 'fantasyName.contains': inputValue },
                                  0,
                                  100,
                                  'fantasyName,asc',
                                  'fantasyName'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'fantasyName') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="supplier"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}
                  {checkAnyValueFields(this.state, 'supplierSelectValue.groups.sub', 'PRODUTOSESERVICOS', true) &&
                  baseFilters !== 'supplierTableProductService' ? (
                    <Col md="12">
                      <div className="entityEmbebedList">
                        <SupplierTableProductService
                          entityEmbebedPai={fullEntityEmbebed}
                          history={this.props.history}
                          location={{
                            pathname: `/supplier-table/${this.props.match.params['id']}/supplier-table-product-service/`,
                            search: '',
                            hash: '',
                            state: undefined,
                            key: 'q2tbhd',
                          }}
                          match={{
                            path: `/supplier-table/:idSupplierTable/supplier-table-product-service/:id`,
                            url: `/supplier-table/${this.props.match.params['id']}/supplier-table-product-service/`,
                            isExact: true,
                            params: {
                              idSupplierTable: this.props.match.params['id'],
                              url: `/supplier-table/${this.props.match.params['id']}/supplier-table-product-service/`,
                            },
                          }}
                          {...this.props.mapStateToPropsSupplierTableProductService}
                          {...mapDispatchToPropsSupplierTableProductServiceList}
                          getEntityPai={(...v) => this.props.getEntity(...v)}
                          getEntities={this.supplierTableProductServiceGetEntities}
                          updateEntity={this.supplierTableProductServiceUpdateEntity}
                          getEntity={this.supplierTableProductServiceGetEntity}
                          createEntity={this.supplierTableProductServiceCreateEntity}
                          deleteEntity={this.supplierTableProductServiceDeleteEntity}
                          reset={this.supplierTableProductServiceReset}
                          supplierTableProductServiceList={this.props.supplierTableProductServiceListEmbed}
                          totalItems={
                            this.props.supplierTableProductServiceListEmbed ? this.props.supplierTableProductServiceListEmbed.length : 0
                          }
                          loading={this.props.loading}
                          supplierTableProductServiceEntity={this.props.supplierTableProductServiceSelected}
                        />
                      </div>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="supplierTableProductService"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}{' '}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const {
      supplierTableEntity,
      supplierTableDiets,
      supplierTableMaterials,
      supplierTableMedicines,
      supplierTableProductServices,
      supplierTableRhs,
      suppliers,
      loading,
      updating,
    } = this.props;
    const { isNew } = this.state;

    const { observation } = supplierTableEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...supplierTableEntity,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel className="supplier-table supplier-table-update">
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToProps = (storeState: IRootState) => {
  return {
    supplierTableDiets: storeState.supplierTableDiet.entities,
    supplierTableMaterials: storeState.supplierTableMaterial.entities,
    supplierTableMedicines: storeState.supplierTableMedicine.entities,
    supplierTableProductServices: storeState.supplierTableProductService.entities,
    supplierTableRhs: storeState.supplierTableRh.entities,
    suppliers: storeState.supplier.entities,
    supplierTableEntity: storeState.supplierTable.entity,
    loading: storeState.supplierTable.loading,
    updating: storeState.supplierTable.updating,
    updateSuccess: storeState.supplierTable.updateSuccess,

    supplierTables: storeState.supplierTable.entities,
    supplierTableProductServiceListAllEmbed: storeState.supplierTable.supplierTableProductServiceListAllEmbed,
    supplierTableProductServiceListEmbed: storeState.supplierTable.supplierTableProductServiceListEmbed,
    supplierTableProductServiceSelected: storeState.supplierTable.supplierTableProductServiceSelected,
    mapStateToPropsSupplierTableProductService: mapStateToPropsSupplierTableProductService(storeState),
  };
};

const mapDispatchToPropsSupplierTableProductServiceList = {};
Object.keys(mapDispatchToPropsSupplierTableProductService).map(v => {
  mapDispatchToPropsSupplierTableProductServiceList[v + 'SupplierTableProductService'] = mapDispatchToPropsSupplierTableProductService[v];
});

export const mapDispatchToProps = {
  getEntity,
  updateEntity,
  setBlob,
  createEntity,
  getEntityOffset,
  reset,

  ...mapDispatchToPropsSupplierTableProductServiceList,
  supplierTableProductServiceUpdateEntity,
  supplierTableProductServiceGetEntities,
  supplierTableProductServiceSetEntities,
  supplierTableProductServiceReset,
  supplierTableProductServiceGetEntity,
  supplierTableProductServiceCreateEntity,
  supplierTableProductServiceDeleteEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SupplierTableUpdate);
