import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Table, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { translate, Translate } from 'app/config/translate-component';
import { getListAxios, getValueRecursive } from 'app/shared/util/entity-utils';

export const RenderModalSuperSelect = ({
  baseState,
  setState,
  entity,
  listEntity,
  template,
  stateField,
  multiple,
  showFields,
  order = 'id,asc',
  filtersBase = {},
}) => {
  const [totalCount, setTotalCount] = useState(0);
  const [listCheckedModalSuperEntities, setListCheckedModalSuperEntities] = useState([]);
  const [listCheckedModalSuperSelectID, setListCheckedModalSuperSelectID] = useState([]);
  const [listCheckedModalSuperSelectFilters, setListCheckedModalSuperSelectFilters] = useState({});
  const [modalSuperSelect, setModalSuperSelect] = useState('');

  const handleInputModalSuperSelectChange = (selected, _multiple) => {
    let _listCheckedModalSuperSelectID: any = _multiple ? [...listCheckedModalSuperSelectID] : [];
    if (_listCheckedModalSuperSelectID.map(v => v['value'] + '').includes(selected.value + '')) {
      _listCheckedModalSuperSelectID = _listCheckedModalSuperSelectID.filter(v => v['value'] + '' !== selected.value + '');
    } else {
      _listCheckedModalSuperSelectID.push(selected);
    }
    setListCheckedModalSuperSelectID(_multiple ? _listCheckedModalSuperSelectID : _listCheckedModalSuperSelectID[0]);
  };

  const handleInputAllModalSuperSelectThisItens = (evt, listChecked, label) => {
    if (evt.target.checked) {
      setListCheckedModalSuperSelectID(listChecked.map(v => ({ ...v, value: v.id, label: v[label] })));
    } else {
      setListCheckedModalSuperSelectID([]);
    }
  };

  return (
    <>
      <Button
        color="secondary"
        className="super-select-button"
        onClick={async () => {
          const axiosListEntity = await getListAxios(listEntity, filtersBase, 0, 150, order ? order : 'id,asc', template.join(','));
          // console.info(axiosListEntity);
          if (baseState[stateField]) {
            setListCheckedModalSuperSelectID(baseState[stateField]);
            setListCheckedModalSuperSelectFilters({});
            setListCheckedModalSuperEntities(axiosListEntity && axiosListEntity['data'] ? axiosListEntity['data'] : []);
            setModalSuperSelect(entity);
          } else {
            setListCheckedModalSuperSelectFilters({});
            setListCheckedModalSuperEntities(axiosListEntity && axiosListEntity['data'] ? axiosListEntity['data'] : []);
            setModalSuperSelect(entity);
          }
        }}
      >
        <FontAwesomeIcon icon="filter" />
        &nbsp;
      </Button>
      {modalSuperSelect === entity ? (
        <Modal
          size={template.length > 3 ? 'xl' : 'lg'}
          isOpen={true}
          toggle={() => {
            setListCheckedModalSuperSelectID([]);
            setListCheckedModalSuperSelectFilters({});
            setModalSuperSelect('');
          }}
          className={'super-select-' + entity}
        >
          <ModalHeader
            toggle={() => {
              setListCheckedModalSuperSelectID([]);
              setListCheckedModalSuperSelectFilters({});
              setModalSuperSelect('');
            }}
          >
            <Translate contentKey={'generadorApp.' + entity + '-modal-super-select-title'}> Select Entity</Translate>
          </ModalHeader>
          <ModalBody>
            <div style={{ height: '350px', overflow: 'auto' }}>
              <Table id={'filter-table-list-' + entity} responsive className={'table-hover table-striped mt-4 table-responsive-css'}>
                <thead>
                  <tr>
                    <th className={'align-middle text-center'} style={{ width: '4%' }}>
                      <input
                        type={multiple ? 'checkbox' : 'hidden'}
                        onChange={evt => handleInputAllModalSuperSelectThisItens(evt, listCheckedModalSuperEntities, showFields)}
                      />
                    </th>
                    {Object.keys(template).map((v, i) => (
                      <th key={'list-' + entity + '-' + i}>
                        {}
                        <input
                          className={'form-control'}
                          name={'list-' + entity + '-' + i}
                          type="text"
                          style={v === 'id' ? { width: '60px' } : {}}
                          onChange={async evt => {
                            const _listCheckedModalSuperSelectFilters = listCheckedModalSuperSelectFilters;
                            _listCheckedModalSuperSelectFilters[i] = evt.target.value;

                            const _filters = { ...filtersBase };
                            Object.keys(_listCheckedModalSuperSelectFilters).map((v2, k) => {
                              if (_listCheckedModalSuperSelectFilters[v2])
                                _filters[template[v2] + '.contains'] = _listCheckedModalSuperSelectFilters[v2];
                            });

                            const axiosListEntity = await getListAxios(
                              listEntity,
                              _filters,
                              0,
                              150,
                              order ? order : 'id,asc',
                              template.join(',')
                            );
                            setListCheckedModalSuperSelectFilters(_listCheckedModalSuperSelectFilters);
                            setListCheckedModalSuperEntities(axiosListEntity && axiosListEntity['data'] ? axiosListEntity['data'] : []);
                          }}
                          value={listCheckedModalSuperSelectFilters[i] ? listCheckedModalSuperSelectFilters[i] : ''}
                          placeholder={translate(
                            template[v] === 'id' ? 'generadorApp.' + entity + '.field_id' : 'generadorApp.' + entity + '.' + template[v]
                          )}
                        />
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {listCheckedModalSuperEntities &&
                    listCheckedModalSuperEntities.map((ent, i) => (
                      <tr key={i}>
                        <td className={'align-middle text-center'}>
                          <input
                            type={multiple ? 'checkbox' : 'radio'}
                            checked={
                              multiple
                                ? listCheckedModalSuperSelectID.map(v => v['value'] + '').includes(ent['id'] + '')
                                : listCheckedModalSuperSelectID['value'] + '' === ent['id'] + ''
                            }
                            onChange={() =>
                              handleInputModalSuperSelectChange(
                                {
                                  ...ent,
                                  value: ent['id'],
                                  label: showFields
                                    .map(field => getValueRecursive(ent, field.split('.')))
                                    .filter(field => field)
                                    .join(' | '),
                                },
                                multiple
                              )
                            }
                          />
                        </td>
                        {template &&
                          template.map((field, j) => {
                            if (!field.includes('.')) {
                              return <td key={'list-' + entity + '-' + field}>{ent[field]}</td>;
                            } else {
                              let valAux = ent;
                              field.split('.').map(subField => {
                                if (Array.isArray(valAux)) {
                                  valAux = valAux
                                    .map(arrField => (arrField[subField] ? arrField[subField] : false))
                                    .filter(filt => filt !== false)
                                    .join(' | ');
                                } else {
                                  valAux = valAux[subField] ? valAux[subField] : '';
                                }
                              });
                              return <td key={'list-' + entity + '-' + field}>{valAux}</td>;
                            }
                          })}
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => {
                setListCheckedModalSuperSelectID([]);
                setListCheckedModalSuperSelectFilters({});
                setModalSuperSelect('');
              }}
            >
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey={'generadorApp.' + entity + '.btnCancel'}>Cancel</Translate>
            </Button>
            <Button
              id={'jhi-confirm-delete-' + entity}
              color="primary"
              onClick={() => {
                setListCheckedModalSuperSelectID([]);
                setListCheckedModalSuperSelectFilters({});
                setModalSuperSelect('');

                const newState = {};
                newState[stateField] = listCheckedModalSuperSelectID;
                setState(newState);
              }}
            >
              <FontAwesomeIcon icon="filter" />
              &nbsp;
              <Translate contentKey={'generadorApp.' + entity + '.btnFilter'}>Filter</Translate>
            </Button>
          </ModalFooter>
        </Modal>
      ) : (
        <> </>
      )}
    </>
  );
};
