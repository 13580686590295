import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import NursingDiary from './nursing-diary';

import NursingDiaryDetail from './nursing-diary-detail';

import NursingDiaryUpdate from './nursing-diary-update';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={NursingDiaryUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={NursingDiaryUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={NursingDiaryDetail} />
      <ErrorBoundaryRoute path={match.path} component={NursingDiary} />
    </Switch>
  </>
);

export default Routes;
