/* eslint complexity: ["error", 500] */
import axios from 'axios';

import { IPaginationBaseState, IPayloadResult } from 'react-jhipster';

import { IPayload } from 'react-jhipster/src/type/redux-action.type';

import { BASE_API_VERSION_PATH } from 'app/config/constants';

import { cleanEntity, jsonParse } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IMedicineMaterial, defaultValue } from 'app/shared/model/medicine-material.model';

export declare type ICrudPutAction<T> = (data?: T, listFiltersPage?) => IPayload<T> | IPayloadResult<T>;
export declare type ICrudGetAction<T> = (id: string | number, selectFields?: string) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const ACTION_TYPES = {
  FETCH_MEDICINEMATERIAL_LIST_EXPORT: 'medicineMaterial/FETCH_MEDICINEMATERIAL_LIST_EXPORT',
  FETCH_MEDICINEMATERIAL_LIST: 'medicineMaterial/FETCH_MEDICINEMATERIAL_LIST',
  FETCH_MEDICINEMATERIAL: 'medicineMaterial/FETCH_MEDICINEMATERIAL',
  FETCH_MEDICINEMATERIAL_OFFSET: 'medicineMaterial/FETCH_MEDICINEMATERIAL_OFFSET',
  CREATE_MEDICINEMATERIAL: 'medicineMaterial/CREATE_MEDICINEMATERIAL',
  UPDATE_MEDICINEMATERIAL: 'medicineMaterial/UPDATE_MEDICINEMATERIAL',
  CREATE_MEDICINEMATERIAL_AND_GO_LIST: 'medicineMaterial/CREATE_MEDICINEMATERIAL_AND_GO_LIST',
  UPDATE_MEDICINEMATERIAL_AND_GO_LIST: 'medicineMaterial/UPDATE_MEDICINEMATERIAL_AND_GO_LIST',
  DELETE_MEDICINEMATERIAL: 'medicineMaterial/DELETE_MEDICINEMATERIAL',
  SET_BLOB: 'medicineMaterial/SET_BLOB',
  RESET: 'medicineMaterial/RESET',
  SHOW_MODAL: 'medicineMaterial/SHOW_MODAL',
};

const initialState = {
  loading: false,
  openModal: {
    list: false,
    view: false,
    detail: false,
  },
  errorMessage: null,
  entities: [] as ReadonlyArray<IMedicineMaterial>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const actionPendind = [];
let actualState = initialState;

export const convertEntityBuffers = entity => ({
  ...entity,
  comments: entity.comments ? Buffer.from(entity.comments).toString() : entity.comments,
});

export type MedicineMaterialState = Readonly<typeof initialState>;

export interface IMedicineMaterialBaseState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  listCheckedID?: Array<number>;
  isCheckedAll?: boolean;
  urlBack?: string;
  extraFilters?: any;
  baseFilters: any;
  offset: number;
  medicineMaterialType?: any;
  name?: any;
  categoryId?: any;
  categoryStartFilter?: any;
  brandId?: any;
  brandStartFilter?: any;
  status?: any;
}

export interface IFieldsBase extends IMedicineMaterialBaseState, IPaginationBaseState {}
export interface IMedicineMaterialUpdateState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  urlBack?: string;
  functionEmbebed?: any[];
  fieldsBase: IFieldsBase;
  tableSelectValue?: any;
  tableStartSelectOptions?: any;
  nameSelectValue?: any;
  nameStartSelectOptions?: any;
  minimumAmountSelectValue?: any;
  minimumAmountStartSelectOptions?: any;
  expenseCodeSelectValue?: any;
  expenseCodeStartSelectOptions?: any;
  activePrincipleSelectValue?: any;
  dosagemSelectValue?: any;
  activePrincipleStartSelectOptions?: any;
  statusSelectValue?: any;
  statusStartSelectOptions?: any;
  ordinance344SelectValue?: any;
  pharmagroupSelectValue?: any;
  ordinance344StartSelectOptions?: any;
  medicineMaterialTypeSelectValue?: any;
  medicineMaterialTypeStartSelectOptions?: any;
  inventoryControlSelectValue?: any;
  inventoryControlStartSelectOptions?: any;
  fractionedSelectValue?: any;
  fractionedStartSelectOptions?: any;
  specialControlSelectValue?: any;
  medicineControlSelectValue?: any;
  specialControlStartSelectOptions?: any;
  thermolabileSelectValue?: any;
  thermolabileStartSelectOptions?: any;
  commentsSelectValue?: any;
  commentsStartSelectOptions?: any;
  laboratorySelectValue?: any;
  laboratoryStartSelectOptions?: any;

  medicineMaterialStatusSelectValue?: any;
  medicineMaterialStatusStartSelectOptions?: any;

  kitDietSelectValue?: any;
  kitDietStartSelectOptions?: any;

  kitMaterialSelectValue?: any;
  kitMaterialStartSelectOptions?: any;

  kitMedicineSelectValue?: any;
  kitMedicineStartSelectOptions?: any;

  quoteDietSelectValue?: any;
  quoteDietStartSelectOptions?: any;

  quoteMedicineSelectValue?: any;
  quoteMedicineStartSelectOptions?: any;

  quoteMaterialSelectValue?: any;
  quoteMaterialStartSelectOptions?: any;

  clComItemSelectValue?: any;
  clComItemStartSelectOptions?: any;

  supplierTableDietSelectValue?: any;
  supplierTableDietStartSelectOptions?: any;

  supplierTableMaterialSelectValue?: any;
  supplierTableMaterialStartSelectOptions?: any;

  supplierTableMedicineSelectValue?: any;
  supplierTableMedicineStartSelectOptions?: any;

  inventoryItemSelectValue?: any;
  inventoryItemStartSelectOptions?: any;

  pharmacyStockSelectValue?: any;
  pharmacyStockStartSelectOptions?: any;

  patientAllergySelectValue?: any;
  patientAllergyStartSelectOptions?: any;

  patDrPrescrProcdSelectValue?: any;
  patDrPrescrProcdStartSelectOptions?: any;

  categorySelectValue?: any;
  categoryStartSelectOptions?: any;

  simproSelectValue?: any;
  simproStartSelectOptions?: any;

  brasindiceSelectValue?: any;
  brasindiceStartSelectOptions?: any;

  brandSelectValue?: any;
  brandStartSelectOptions?: any;

  presentationSelectValue?: any;
  presentationStartSelectOptions?: any;

  doseSelectValue?: any;
  doseStartSelectOptions?: any;

  levelGroupSelectValue?: any;
  levelGroupStartSelectOptions?: any;

  isNew: boolean;
  idscategory: any[];
  simproDescricao?: any;

  simproId?: any;
  brasindiceName?: any;

  brasindiceId?: any;
  brandName?: any;

  brandId?: any;
  presentationName?: any;

  presentationId?: any;
  doseName?: any;

  doseId?: any;
  levelGroupName?: any;

  levelGroupId?: any;
}

// Reducer

export default (state: MedicineMaterialState = initialState, action): MedicineMaterialState => {
  actualState = state;
  if (
    action.type.includes('_PENDING') &&
    !actionPendind.includes(action.type.replace('_PENDING', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_PENDING', ''))
  ) {
    actionPendind.push(action.type.replace('_PENDING', ''));
  }

  if (
    action.type.includes('_FULFILLED') &&
    actionPendind.includes(action.type.replace('_FULFILLED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_FULFILLED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_FULFILLED', '')), 1);
  }

  if (
    action.type.includes('_REJECTED') &&
    actionPendind.includes(action.type.replace('_REJECTED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_REJECTED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_REJECTED', '')), 1);
  }

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_MEDICINEMATERIAL_LIST_EXPORT):
    case REQUEST(ACTION_TYPES.FETCH_MEDICINEMATERIAL_LIST):
    case REQUEST(ACTION_TYPES.FETCH_MEDICINEMATERIAL):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_MEDICINEMATERIAL):
    case REQUEST(ACTION_TYPES.UPDATE_MEDICINEMATERIAL):
    case REQUEST(ACTION_TYPES.CREATE_MEDICINEMATERIAL_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.UPDATE_MEDICINEMATERIAL_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.DELETE_MEDICINEMATERIAL):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_MEDICINEMATERIAL_LIST_EXPORT):
    case FAILURE(ACTION_TYPES.FETCH_MEDICINEMATERIAL_LIST):
    case FAILURE(ACTION_TYPES.FETCH_MEDICINEMATERIAL):
    case FAILURE(ACTION_TYPES.CREATE_MEDICINEMATERIAL):
    case FAILURE(ACTION_TYPES.UPDATE_MEDICINEMATERIAL):
    case FAILURE(ACTION_TYPES.CREATE_MEDICINEMATERIAL_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.UPDATE_MEDICINEMATERIAL_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.DELETE_MEDICINEMATERIAL):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_MEDICINEMATERIAL_LIST):
      action.payload.data = action.payload.data.map(v => {
        return { ...v, comments: v.comments ? Buffer.from(v.comments).toString() : v.comments };
      });

      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_MEDICINEMATERIAL_OFFSET):
      if (action.payload.data && action.payload.data.length > 0) {
        return {
          ...state,
          loading: false,
          entity: convertEntityBuffers(action.payload.data[0]),
        };
      }
      return {
        ...state,
        loading: false,
      };

    case SUCCESS(ACTION_TYPES.FETCH_MEDICINEMATERIAL):
      return {
        ...state,
        loading: false,
        entity: convertEntityBuffers(action.payload.data),
      };
    case SUCCESS(ACTION_TYPES.CREATE_MEDICINEMATERIAL):
    case SUCCESS(ACTION_TYPES.UPDATE_MEDICINEMATERIAL):
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_MEDICINEMATERIAL_AND_GO_LIST):
    case SUCCESS(ACTION_TYPES.UPDATE_MEDICINEMATERIAL_AND_GO_LIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_MEDICINEMATERIAL):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.SET_BLOB: {
      const { name, data, contentType, fileName } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [name + 'Base64']: data,
          [name + 'ContentType']: contentType,
          [name + 'FileName']: fileName,
        },
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };

    case ACTION_TYPES.SHOW_MODAL: {
      const openModal = state.openModal;
      openModal[action.payload.type] = action.payload.show;
      return {
        ...state,
        openModal,
      };
    }
    default:
      return state;
  }
};

const apiUrl = BASE_API_VERSION_PATH + 'medicine-materials';

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

// Actions

// Actions
export type ICrudGetAllActionMedicineMaterial<T> = (
  medicineMaterialType?: any,
  name?: any,
  categoryId?: any,
  brandId?: any,
  status?: any,
  extraFilters?: {},
  page?: number,
  size?: number,
  sort?: string,
  selectFields?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getEntitiesFilter = (filters?, page?, size?, sort?, selectFields = '') => {
  const requestUrl = `${apiUrl}?page=${page >= 0 ? page : 0}&size=${size >= 0 ? size : 20}&sort=${sort ? sort : 'id,asc'}&`;
  const requestFilters = filters.map(v => v.column + '.' + v.operation + '=' + v.value).join('&');
  return {
    type: ACTION_TYPES.FETCH_MEDICINEMATERIAL_LIST,
    payload: axios.get<IMedicineMaterial>(`${requestUrl}${requestFilters}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getAllEntities = (filters?, sort?, selectFields = '') => {
  if (
    (actualState.totalItems > 0 && actualState.entities.length === actualState.totalItems) ||
    actionPendind.includes(ACTION_TYPES.FETCH_MEDICINEMATERIAL_LIST)
  ) {
    return {
      type: ACTION_TYPES.FETCH_MEDICINEMATERIAL_LIST + '_FROM_CACHE',
      payload: new Promise(function (resolve, reject) {
        resolve('done');
      }),
    };
  }

  const idsRequest = filters && typeof filters['ids'] !== 'undefined' && filters['ids'] ? `id.in=${filters['ids'].join(',')}&` : '';
  const medicineMaterialTypeRequest =
    filters && typeof filters['medicineMaterialType'] !== 'undefined' && filters['medicineMaterialType']
      ? `medicineMaterialType.contains=${filters['medicineMaterialType']}&`
      : '';
  const nameRequest = filters && typeof filters['name'] !== 'undefined' && filters['name'] ? `name.contains=${filters['name']}&` : '';
  const categoryRequest =
    filters && typeof filters['categoryId'] !== 'undefined' && filters['categoryId'] ? `categories.id.in=${filters['categoryId']}&` : '';
  const brandRequest =
    filters && typeof filters['brandId'] !== 'undefined' && filters['brandId'] ? `brand.id.in=${filters['brandId']}&` : '';
  const statusRequest =
    filters && typeof filters['status'] !== 'undefined' && filters['status'] ? `status.contains=${filters['status']}&` : '';

  const requestUrl = `${apiUrl}${`?page=${0}&size=${1000000}&sort=${sort ? sort : 'id,asc'}&`}`;
  return {
    type: ACTION_TYPES.FETCH_MEDICINEMATERIAL_LIST,
    payload: axios.get<IMedicineMaterial>(
      `${requestUrl}${idsRequest}${medicineMaterialTypeRequest}${nameRequest}${categoryRequest}${brandRequest}${statusRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntities: ICrudGetAllActionMedicineMaterial<IMedicineMaterial> = (
  medicineMaterialType,
  name,
  categoryId,
  brandId,
  status,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const medicineMaterialTypeRequest = medicineMaterialType
    ? Array.isArray(status)
      ? `medicineMaterialType.in=${medicineMaterialType.join(',')}&`
      : `medicineMaterialType.equals=${medicineMaterialType}&`
    : '';
  const nameRequest = name ? `name.contains=${name}&` : '';
  const categoryRequest = categoryId ? `categories.id.in=${categoryId}&` : '';
  const brandRequest = brandId ? `brand.id.in=${brandId}&` : '';
  const statusRequest = status ? (Array.isArray(status) ? `status.in=${status.join(',')}&` : `status.equals=${status}&`) : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_MEDICINEMATERIAL_LIST,
    payload: axios.get<IMedicineMaterial>(
      `${requestUrl}${extraFiltersRequest}${medicineMaterialTypeRequest}${nameRequest}${categoryRequest}${brandRequest}${statusRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntityOffset: any = (
  medicineMaterialType,
  name,
  categoryId,
  brandId,
  status,
  extraFilters,
  offset,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const medicineMaterialTypeRequest = medicineMaterialType
    ? Array.isArray(status)
      ? `medicineMaterialType.in=${medicineMaterialType.join(',')}&`
      : `medicineMaterialType.equals=${medicineMaterialType}&`
    : '';
  const nameRequest = name ? `name.contains=${name}&` : '';
  const categoryRequest = categoryId ? `categories.id.in=${categoryId}&` : '';
  const brandRequest = brandId ? `brand.id.in=${brandId}&` : '';
  const statusRequest = status ? (Array.isArray(status) ? `status.in=${status.join(',')}&` : `status.equals=${status}&`) : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${offset}&size=${1}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_MEDICINEMATERIAL_OFFSET,
    payload: axios.get<IMedicineMaterial>(
      `${requestUrl}${extraFiltersRequest}${medicineMaterialTypeRequest}${nameRequest}${categoryRequest}${brandRequest}${statusRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntity: ICrudGetAction<IMedicineMaterial> = (id, selectFields = '') => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_MEDICINEMATERIAL,
    payload: axios.get<IMedicineMaterial>(requestUrl, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntitiesExport: ICrudGetAllActionMedicineMaterial<IMedicineMaterial> = (
  medicineMaterialType,
  name,
  categoryId,
  brandId,
  status,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const medicineMaterialTypeRequest = medicineMaterialType
    ? Array.isArray(status)
      ? `medicineMaterialType.in=${medicineMaterialType.join(',')}&`
      : `medicineMaterialType.equals=${medicineMaterialType}&`
    : '';
  const nameRequest = name ? (Array.isArray(status) ? `name.in=${name.join(',')}&` : `name.contains=${name}&`) : '';
  const categoryRequest = categoryId ? `categories.id.in=${categoryId}&` : '';
  const brandRequest = brandId ? `brand.id.in=${brandId}&` : '';
  const statusRequest = status ? (Array.isArray(status) ? `status.in=${status.join(',')}&` : `status.equals=${status}&`) : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_MEDICINEMATERIAL_LIST,
    payload: axios.get<IMedicineMaterial>(
      `${requestUrl}${extraFiltersRequest}${medicineMaterialTypeRequest}${nameRequest}${categoryRequest}${brandRequest}${statusRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const createEntity: ICrudPutAction<IMedicineMaterial> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.CREATE_MEDICINEMATERIAL_AND_GO_LIST
        : ACTION_TYPES.CREATE_MEDICINEMATERIAL,
    payload: axios.post(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const updateEntity: ICrudPutAction<IMedicineMaterial> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.UPDATE_MEDICINEMATERIAL_AND_GO_LIST
        : ACTION_TYPES.UPDATE_MEDICINEMATERIAL,
    payload: axios.put(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const deleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_MEDICINEMATERIAL,
    payload: axios.delete(requestUrl),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const setBlob = (name, data, contentType?, fileName?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType,
    fileName,
  },
});

export const showModal = view => ({
  payload: { type: view, show: true },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const hideModal = view => ({
  payload: { type: view, show: false },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const getUrlBack = (location): string => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  let urlBack = _urlBase.searchParams.get('urlBack') || '';
  urlBack = urlBack.trim();
  urlBack = urlBack.startsWith('/') ? urlBack.slice(1) : urlBack;
  urlBack = urlBack.endsWith('/') ? urlBack.slice(0, -1) : urlBack;
  return urlBack ? '/' + urlBack + '/' : '';
};

export const getMedicineMaterialState = (location): IMedicineMaterialBaseState => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  const extraFilters = jsonParse(_urlBase.searchParams.get('extraFilters') || '{}');
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;

  const medicineMaterialType = _urlBase.searchParams.get('medicineMaterialType') || '';
  const name = _urlBase.searchParams.get('name') || '';
  const categoryId = _urlBase.searchParams.get('category') || '';
  const brandId = _urlBase.searchParams.get('brand') || '';
  const status = _urlBase.searchParams.get('status') || '';
  return {
    baseFilters,
    extraFilters,
    offset,
    medicineMaterialType,
    name,
    categoryId: categoryId ? categoryId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
    brandId: brandId ? brandId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
    status,
  };
};

export const getEntityFiltersURL = (state, offset = null) => {
  return (
    'baseFilters=' +
    state.baseFilters +
    '&extraFilters=' +
    encodeURI(JSON.stringify(state.extraFilters)) +
    '&page=' +
    state.activePage +
    '&' +
    'size=' +
    state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    state.sort +
    ',' +
    state.order +
    '&' +
    (state.medicineMaterialType ? 'medicineMaterialType=' + state.medicineMaterialType + '&' : '') +
    (state.name ? 'name=' + state.name + '&' : '') +
    (state.categoryId ? 'category=' + state.categoryId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.brandId ? 'brand=' + state.brandId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.status ? 'status=' + state.status + '&' : '') +
    ''
  );
};
