/* eslint complexity: ["error", 500] */
import React from 'react';

import CurrencyInput from 'react-currency-input';
import { connect } from 'react-redux';

import {
  getFilterFromSelect,
  getFormFromSelect,
  getListAxios,
  getValueRecursive,
  showFieldsSelectAsync,
} from 'app/shared/util/entity-utils';

import { toast } from 'react-toastify';
import SelectAsync from 'react-select/async';
import { Link, RouteComponentProps } from 'react-router-dom';

import { Button, Col, Row, Table, Label, UncontrolledCollapse, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { JhiItemCount } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import BreackLine from './components/BreackLine';

import { IRootState } from 'app/shared/reducers';
import {
  updateEntity,
  getUrlBack,
  getSpecialtyState,
  ISpecialtyBaseState,
  getEntityFiltersURL,
  getEntities,
  ISpecialtyUpdateState as IBaseUpdateState,
  reset,
  getEntity,
  deleteEntity,
  createEntity,
} from './specialty.reducer';

import { APP_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE, IPaginationBaseState, getSortState } from 'app/shared/util/pagination.constants';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';
import moment from 'moment';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { RenderModalSuperSelect } from 'app/shared/layout/components/RenderModalSuperSelect';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

export interface ISpecialtyProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
  entityEmbebedPai?: any;
}

export interface ISpecialtyState extends ISpecialtyBaseState, IPaginationBaseState, IBaseUpdateState {
  showModalForm?: boolean;
  showModalEdit?: number;
  showModalEditView?: boolean;
}

export class Specialty extends React.Component<ISpecialtyProps, ISpecialtyState> {
  protected myFormRef: any;
  protected myFormRefNew: any;
  protected myFormRefEdit: any;

  nameFileInput: any;

  costFileInput: any;

  statusFileInput: any;

  tussCodeFileInput: any;

  tissCodeFileInput: any;

  tableCodeFileInput: any;

  expenseCodeFileInput: any;

  constructor(props: ISpecialtyProps) {
    super(props);
    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      listCheckedID: [],
      isCheckedAll: false,
      showModalForm: false,
      showModalEdit: null,
      urlBack: getUrlBack(this.props.location),
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      ...getSpecialtyState(this.props.location),
      userSelectValue: null,

      kitRhsSelectValue: null,

      quoteRhSelectValue: null,

      specialtyStatusHistorySelectValue: null,

      supplierTableRhSelectValue: null,

      medicalRecordSelectValue: null,

      pushSelectValue: null,

      clComRulerSelectValue: null,

      categorySelectValue: null,

      doseSelectValue: null,

      professionalSelectValue: null,

      nameSelectValue: null,
      costSelectValue: null,
      statusSelectValue: null,
      tussCodeSelectValue: null,
      tissCodeSelectValue: null,
      tableCodeSelectValue: null,
      expenseCodeSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getSpecialtyState(this.props.location),
      },
      idsclComRuler: [],
      idsprofessional: [],
      userId: null,
      categoryName: null,
      doseName: null,
      isNew: false,
    };
  }

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({ listCheckedID: this.props.specialtyList.map(specialty => specialty.id) });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({ listCheckedID: this.props.specialtyList.map(specialty => specialty.id) });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  removeEntitySelected() {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  }

  saveEntityMany = () => {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  };

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  cancelFilters = () => {
    this.setState(
      {
        categoryId: '',
        name: '',
        cost: '',
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
        activePage: 0,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();

    const selectFieldsList = ['name', 'category.name', 'createdDate', 'lastModifiedDate', 'status'];
    const { categoryId, name, cost, extraFilters, activePage, itemsPerPage, sort, order } = this.state;
    return this.props.getEntities(
      getFilterFromSelect(categoryId, 'many-to-one'),
      name,
      cost,
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(',')
    );
  };

  updateEntity = entity => {
    const selectFieldsList = ['name', 'category.name', 'createdDate', 'lastModifiedDate', 'status'];

    const { categoryId, name, cost, extraFilters, activePage, itemsPerPage, sort, order } = this.state;
    this.props.updateEntity(entity, [
      getFilterFromSelect(categoryId, 'many-to-one'),
      name,
      cost,
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(','),
    ]);
  };

  saveEntity = (event: any, errors: any, values: any, isNew: any) => {
    if (!this.state.nameSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.specialty.name') }));
      return false;
    }

    if (!this.state.costSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.specialty.cost') }));
      return false;
    }

    const selectFieldsList = ['name', 'category.name', 'createdDate', 'lastModifiedDate', 'status'];

    if (errors.length === 0) {
      const { specialtyEntity } = this.props;
      const _entity = {
        ...specialtyEntity,
        // ...values,

        category: getFormFromSelect(this.state.categorySelectValue, 'many-to-one'), // many-to-one - otherSide

        dose: getFormFromSelect(this.state.doseSelectValue, 'many-to-one'), // many-to-one - otherSide

        name: this.state.nameSelectValue,
        cost: this.state.costSelectValue,
        status: this.state.statusSelectValue === null ? 'INATIVADO' : this.state.statusSelectValue,
        tussCode: this.state.tussCodeSelectValue,
        tissCode: this.state.tissCodeSelectValue,
        tableCode: this.state.tableCodeSelectValue,
        expenseCode: this.state.expenseCodeSelectValue,
      };
      const entity = _entity;

      const { categoryId, name, cost, extraFilters, activePage, itemsPerPage, sort, order } = this.state.fieldsBase;

      if (isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              getFilterFromSelect(categoryId, 'many-to-one'),
              name,
              cost,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              getFilterFromSelect(categoryId, 'many-to-one'),
              name,
              cost,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
      this.setState({ showModalForm: false });
      this.setState({ showModalEdit: null });
    } else {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.specialty.' + errors[0]) }));
    }
  };

  componentDidUpdate(prevProps) { }

  deleteEntity(specialtyEntity) {
    MySwal.fire({
      title: <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>,
      html: (
        <Translate contentKey="generadorApp.specialty.delete.question" interpolate={{ id: specialtyEntity.id }}>
          Are you sure you want to delete this Specialty?
        </Translate>
      ),
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#e70015',
      cancelButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="ban" />
          &nbsp; <Translate contentKey="generadorApp.specialty.btnCancel">Cancel</Translate>
        </>
      ),
      confirmButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="trash" />
          &nbsp;<Translate contentKey="generadorApp.specialty.btnDelete">Delete</Translate>
        </>
      ),
    }).then(async response => {
      if (response.isConfirmed) {
        await this.props.deleteEntity(specialtyEntity.id, { reloadList: false });
        this.getEntities();
        MySwal.fire({
          title: <Translate contentKey="entity.delete.title.confirmed">Confirmed delete operation</Translate>,
          icon: 'success',
        });
      }
    });
  }

  // categorySelectValue
  // nameSelectValue
  // doseSelectValue
  // costSelectValue

  renderNewModal() {

    function checkValue(info: any) {
      if (info === null) {
        return ''
      } else if (info === undefined) {
        return ''
      } else {
        return info
      }
    }


    return (
      <Modal size={'xl'} isOpen={this.state.showModalForm} toggle={() => this.setState({ showModalForm: false })}>
        <ModalHeader toggle={() => this.setState({ showModalForm: false })}>{this.renderHeaderUpdate({}, true)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefNew = el)}
          model={{}}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, true)}
        >
          <ModalBody>{this.renderBodyUpdate({}, true)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalForm: false })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.specialty.btnCancel">Cancel</Translate>
            </Button>
            {checkValue(this.state.categorySelectValue) === ''
              || checkValue(this.state.nameSelectValue) === ''
              || checkValue(this.state.doseSelectValue) === ''
              || checkValue(this.state.costSelectValue) === '' ?
              <Button
                color="#c4c4c4"
                id="save-entity"
                onClick={() => toast.error("Preencha os campos obrigatórios.")}
                className="float-right jh-create-entity"
              >
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="generadorApp.specialty.btnSave">Save</Translate>
              </Button> :
              <Button
                color="primary"
                id="save-entity"
                onClick={() => this.myFormRefNew && this.myFormRefNew.submit()}
                className="float-right jh-create-entity"
              >
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="generadorApp.specialty.btnSave">Save</Translate>
              </Button>
            }
          </ModalFooter>
        </AvForm>
      </Modal>
    );
  }

  getFullEntityFromState(v) {
    return {
      id: v.id,
      name: this.state.nameSelectValue,
      cost: this.state.costSelectValue,
      status: this.state.statusSelectValue,
      tussCode: this.state.tussCodeSelectValue,
      tissCode: this.state.tissCodeSelectValue,
      tableCode: this.state.tableCodeSelectValue,
      expenseCode: this.state.expenseCodeSelectValue,
      user: this.state.userSelectValue,
      kitRhs: this.state.kitRhsSelectValue,
      quoteRh: this.state.quoteRhSelectValue,
      specialtyStatusHistory: this.state.specialtyStatusHistorySelectValue,
      supplierTableRh: this.state.supplierTableRhSelectValue,
      medicalRecord: this.state.medicalRecordSelectValue,
      push: this.state.pushSelectValue,
      clComRuler: this.state.clComRulerSelectValue,
      category: this.state.categorySelectValue,
      dose: this.state.doseSelectValue,
      professional: this.state.professionalSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    let p: any = null;

    p = v.user;
    const userEntity = p ? { ...p, value: p.id, label: p['id'] ? p.id : '' } : p;

    const kitRhsEntity = v.kitRhs ? v.kitRhs.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' })) : v.kitRhs;

    const quoteRhEntity = v.quoteRh ? v.quoteRh.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' })) : v.quoteRh;

    const specialtyStatusHistoryEntity = v.specialtyStatusHistory
      ? v.specialtyStatusHistory.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' }))
      : v.specialtyStatusHistory;

    const supplierTableRhEntity = v.supplierTableRh
      ? v.supplierTableRh.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' }))
      : v.supplierTableRh;

    const medicalRecordEntity = v.medicalRecord
      ? v.medicalRecord.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' }))
      : v.medicalRecord;

    const pushEntity = v.push ? v.push.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' })) : v.push;

    const clComRulerEntity = v.clComRulers
      ? v.clComRulers.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' }))
      : v.clComRulers;

    p = v.category;
    const categoryEntity = p
      ? {
        ...p,
        value: p.id,
        label: (p['levelGroup'] && p['levelGroup']['name'] ? p.levelGroup.name : '') + ' | ' + (p['name'] ? p.name : ''),
      }
      : p;

    p = v.dose;
    const doseEntity = p ? { ...p, value: p.id, label: (p['name'] ? p.name : '') + ' | ' + (p['codeANS'] ? p.codeANS : '') } : p;

    const professionalEntity = v.professionals
      ? v.professionals.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' }))
      : v.professionals;

    return {
      nameSelectValue: v.name, // name,
      costSelectValue: v.cost, // cost,
      statusSelectValue: v.status, // status,
      tussCodeSelectValue: v.tussCode, // tussCode,
      tissCodeSelectValue: v.tissCode, // tissCode,
      tableCodeSelectValue: v.tableCode, // tableCode,
      expenseCodeSelectValue: v.expenseCode, // expenseCode,
      userSelectValue: userEntity,
      kitRhsSelectValue: kitRhsEntity,
      quoteRhSelectValue: quoteRhEntity,
      specialtyStatusHistorySelectValue: specialtyStatusHistoryEntity,
      supplierTableRhSelectValue: supplierTableRhEntity,
      medicalRecordSelectValue: medicalRecordEntity,
      pushSelectValue: pushEntity,
      clComRulerSelectValue: clComRulerEntity,
      categorySelectValue: categoryEntity,
      doseSelectValue: doseEntity,
      professionalSelectValue: professionalEntity,
    };
  }

  async openUpdateModal(v, edit = true) {
    // Update 17-09-2021
    const response: any = await this.props.getEntity(v.id);
    this.setState({
      showModalEdit: v.id,
      showModalEditView: edit,
      ...this.getFullEntitySelectValue(response.value?.data ? response.value?.data : v),
    });
  }

  renderUpdateModal() {
    const { specialtyEntity, entityEmbebedPai } = this.props;
    return specialtyEntity && specialtyEntity.id ? (
      <Modal size={'xl'} isOpen={this.state.showModalEdit === specialtyEntity.id} toggle={() => this.setState({ showModalEdit: null })}>
        <ModalHeader toggle={() => this.setState({ showModalEdit: null })}>{this.renderHeaderUpdate(specialtyEntity, false)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefEdit = el)}
          model={{
            ...specialtyEntity,
          }}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, false)}
        >
          <ModalBody>{this.renderBodyUpdate(specialtyEntity, false)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalEdit: null })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.specialty.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefEdit && this.myFormRefEdit.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.specialty.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    ) : (
      <> </>
    );
  }

  renderHeaderUpdate(specialtyEntity, isNew) {
    const {
      entityEmbebedPai,
      users,
      kitRhs,
      quoteRhs,
      specialtyStatusHistories,
      supplierTableRhs,
      medicalRecords,
      pushes,
      clComRulers,
      categories,
      doses,
      professionals,
      loading,
    } = this.props;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {isNew ? (
              <Translate contentKey="generadorApp.specialty.home.createLabel">Create a Specialty</Translate>
            ) : (
              <Translate contentKey="generadorApp.specialty.home.editLabel">Edit a Specialty</Translate>
            )}
          </span>
        </div>
      </>
    );
  }
  renderBodyUpdate(specialtyEntity, isNew) {
    const {
      entityEmbebedPai,
      users,
      kitRhs,
      quoteRhs,
      specialtyStatusHistories,
      supplierTableRhs,
      medicalRecords,
      pushes,
      clComRulers,
      categories,
      doses,
      professionals,
      loading,
    } = this.props;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    function checkValue(info: any) {
      if (info === null) {
        return ''
      } else if (info === undefined) {
        return ''
      } else {
        return info
      }
    }



    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
              <Col md="3">
              <Label className="mt-2 label-single-line"for="specialty-id">
                <Translate contentKey="generadorApp.specialty.field_id">ID</Translate>
              </Label>
              </Col> */}
                      <Col md="12">
                        <AvInput id="specialty-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'category' ? (
                    <Col md="5">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="specialty-category">
                              <Translate contentKey="generadorApp.specialty.category">Category</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="specialty-category"
                              name={'category'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.categorySelectValue ? this.state.categorySelectValue : ''}
                              onChange={options => this.setState({ categorySelectValue: options })}
                              defaultOptions={this.state.categoryStartSelectOptions ? this.state.categoryStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.categoryStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.categoryStartSelectOptions === undefined) {
                                  const result = await getListAxios(
                                    'categories',
                                    { 'levelGroup.sub.in': 'ESPECIALIDADE' },
                                    0,
                                    100,
                                    'levelGroup,asc',
                                    'levelGroup.name,name'
                                  );
                                  this.setState({
                                    categoryStartSelectOptions: result.data
                                      ? result.data.map(p => ({
                                        ...p,
                                        value: p.id,
                                        label: showFieldsSelectAsync(p, 'levelGroup.name;name') || '',
                                      }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'categories',
                                  { 'levelGroup.sub.in': 'ESPECIALIDADE', 'name.contains': inputValue },
                                  0,
                                  100,
                                  'levelGroup,asc',
                                  'levelGroup.name,name'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({
                                      ...p,
                                      value: p.id,
                                      label: showFieldsSelectAsync(p, 'levelGroup.name;name') || '',
                                    }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                          <RenderModalSuperSelect
                            baseState={this.state}
                            setState={_state => this.setState(_state)}
                            entity={'category'}
                            listEntity={'categories'}
                            template={'levelGroup.name;name'.split(';')}
                            stateField={'categorySelectValue'}
                            multiple={false}
                            showFields={'levelGroup.name;name'.split(';')}
                            order={'levelGroup,asc'}
                            filtersBase={{ 'levelGroup.sub.in': 'ESPECIALIDADE' }}
                          />
                        </Row>
                        {checkValue(this.state.categorySelectValue) === '' ? <text style={{ color: 'red' }}>Campo obrigatório</text> : null}
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="category"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}

                  {baseFilters !== 'name' ? (
                    <Col md="5">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="nameLabel" for="specialty-name">
                              <Translate contentKey="generadorApp.specialty.name">Name</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                              value={this.state.nameSelectValue ? this.state.nameSelectValue : ''}
                              id="specialty-name"
                              type="text"
                              name="name"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                        {checkValue(this.state.nameSelectValue) === '' ? <text style={{ color: 'red' }}>Campo obrigatório</text> : null}
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                      type="hidden"
                      name="name"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'dose' ? (
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="specialty-dose">
                              <Translate contentKey="generadorApp.specialty.dose">Dose</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="specialty-dose"
                              name={'dose'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.doseSelectValue ? this.state.doseSelectValue : ''}
                              onChange={options => this.setState({ doseSelectValue: options })}
                              defaultOptions={this.state.doseStartSelectOptions ? this.state.doseStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.doseStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.doseStartSelectOptions === undefined) {
                                  const result = await getListAxios('doses', {}, 0, 100, 'name,asc', 'name,codeANS');
                                  this.setState({
                                    doseStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name;codeANS') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'doses',
                                  { 'codeANS.contains': inputValue },
                                  0,
                                  100,
                                  'name,asc',
                                  'name,codeANS'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name;codeANS') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                        {checkValue(this.state.doseSelectValue) === '' ? <text style={{ color: 'red' }}>Campo obrigatório</text> : null}
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="dose"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}

                  {baseFilters !== 'cost' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="costLabel" for="specialty-cost">
                              <Translate contentKey="generadorApp.specialty.cost">Cost</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ costSelectValue: floatvalue })}
                              decimalSeparator=","
                              thousandSeparator=""
                              precision={2}
                              prefix=""
                              allowNegative={true}
                              value={this.state.costSelectValue ? this.state.costSelectValue : 0}
                              id="specialty-cost"
                              name="cost"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                        {checkValue(this.state.costSelectValue) === '' ? <text style={{ color: 'red' }}>Campo obrigatório</text> : null}
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ costSelectValue: evt.target.value })}
                      type="hidden"
                      name="cost"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'BreackLine' ? (
                    <Col md="12">
                      <BreackLine
                        entityBase={specialtyEntity}
                        baseState={this.state}
                        baseProps={this.props}
                        setState={_state => this.setState(_state)}
                      />
                    </Col>
                  ) : (
                    <> </>
                  )}

                  {baseFilters !== 'tussCode' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="tussCodeLabel" for="specialty-tussCode">
                              <Translate contentKey="generadorApp.specialty.tussCode">Tuss Code</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ tussCodeSelectValue: evt.target.value })}
                              value={this.state.tussCodeSelectValue ? this.state.tussCodeSelectValue : ''}
                              id="specialty-tussCode"
                              type="text"
                              name="tussCode"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ tussCodeSelectValue: evt.target.value })}
                      type="hidden"
                      name="tussCode"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'tissCode' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="tissCodeLabel" for="specialty-tissCode">
                              <Translate contentKey="generadorApp.specialty.tissCode">TISS Code</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ tissCodeSelectValue: evt.target.value })}
                              value={this.state.tissCodeSelectValue ? this.state.tissCodeSelectValue : ''}
                              id="specialty-tissCode"
                              type="text"
                              name="tissCode"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ tissCodeSelectValue: evt.target.value })}
                      type="hidden"
                      name="tissCode"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'tableCode' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="tableCodeLabel" for="specialty-tableCode">
                              <Translate contentKey="generadorApp.specialty.tableCode">Table TUSS Code</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ tableCodeSelectValue: evt.target.value })}
                              value={this.state.tableCodeSelectValue ? this.state.tableCodeSelectValue : ''}
                              id="specialty-tableCode"
                              type="text"
                              name="tableCode"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ tableCodeSelectValue: evt.target.value })}
                      type="hidden"
                      name="tableCode"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'expenseCode' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="expenseCodeLabel" for="specialty-expenseCode">
                              <Translate contentKey="generadorApp.specialty.expenseCode">TISS Expense Code</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ expenseCodeSelectValue: evt.target.value })}
                              value={this.state.expenseCodeSelectValue ? this.state.expenseCodeSelectValue : ''}
                              id="specialty-expenseCode"
                              type="text"
                              name="expenseCode"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ expenseCodeSelectValue: evt.target.value })}
                      type="hidden"
                      name="expenseCode"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  renderHeader() {
    const {
      users,
      kitRhs,
      quoteRhs,
      specialtyStatusHistories,
      supplierTableRhs,
      medicalRecords,
      pushes,
      clComRulers,
      categories,
      doses,
      professionals,
      specialtyList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.specialty.home.title">Specialties</Translate>
          </span>
          <Button id="togglerFilterSpecialty" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.specialty.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          &nbsp;
          <Button
            onClick={() =>
              this.setState({
                showModalForm: true,
                nameSelectValue: null,
                costSelectValue: null,
                statusSelectValue: 'ATIVO',
                tussCodeSelectValue: null,
                tissCodeSelectValue: null,
                tableCodeSelectValue: null,
                expenseCodeSelectValue: null,

                userStartSelectOptions: undefined,
                userSelectValue: null,

                kitRhsStartSelectOptions: undefined,
                kitRhsSelectValue: null,

                quoteRhStartSelectOptions: undefined,
                quoteRhSelectValue: null,

                specialtyStatusHistoryStartSelectOptions: undefined,
                specialtyStatusHistorySelectValue: null,

                supplierTableRhStartSelectOptions: undefined,
                supplierTableRhSelectValue: null,

                medicalRecordStartSelectOptions: undefined,
                medicalRecordSelectValue: null,

                pushStartSelectOptions: undefined,
                pushSelectValue: null,

                clComRulerStartSelectOptions: undefined,
                clComRulerSelectValue: null,

                categoryStartSelectOptions: undefined,
                categorySelectValue: null,

                doseStartSelectOptions: undefined,
                doseSelectValue: null,

                professionalStartSelectOptions: undefined,
                professionalSelectValue: null,
                isNew: true,
              })
            }
            color="primary"
            className="btn btn-primary float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="generadorApp.specialty.btnNewItem">Create new</Translate>
          </Button>{' '}
          &nbsp;
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.specialty.home.title">Specialties</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    const {
      users,
      kitRhs,
      quoteRhs,
      specialtyStatusHistories,
      supplierTableRhs,
      medicalRecords,
      pushes,
      clComRulers,
      categories,
      doses,
      professionals,
      specialtyList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <UncontrolledCollapse toggler="#togglerFilterSpecialty">
          <CardBody>
            <AvForm ref={el => (this.myFormRef = el)} id="form-filter" onSubmit={this.filterEntity}>
              <div className="row mt-1 ml-3 mr-3">
                {this.state.baseFilters !== 'category' ? (
                  <Col md="6">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="specialty-category">
                          <Translate contentKey="generadorApp.specialty.category">Category</Translate>
                        </Label>
                        <SelectAsync
                          id="specialty-category"
                          isMulti
                          className={'css-select-control'}
                          name={'category'}
                          cacheOptions
                          value={this.state.categoryId}
                          onChange={options => this.setState({ categoryId: options })}
                          defaultOptions={this.state.categoryStartFilter ? this.state.categoryStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.categoryStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.categoryStartFilter === undefined) {
                              const result = await getListAxios(
                                'categories',
                                { 'levelGroup.sub.in': 'ESPECIALIDADE' },
                                0,
                                100,
                                'name,asc',
                                'name'
                              );
                              this.setState({
                                categoryStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios(
                              'categories',
                              { 'levelGroup.sub.in': 'ESPECIALIDADE', 'name.contains': inputValue },
                              0,
                              100,
                              'name,asc',
                              'name'
                            );
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []
                            );
                          }}
                        />
                        <RenderModalSuperSelect
                          baseState={this.state}
                          setState={_state => this.setState(_state)}
                          entity={'category'}
                          listEntity={'categories'}
                          template={'levelGroup.name;name'.split(';')}
                          stateField={'categoryId'}
                          multiple={true}
                          showFields={'name'.split(';')}
                          order={'name,asc'}
                          filtersBase={{ 'levelGroup.sub.in': 'ESPECIALIDADE' }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'name' ? (
                  <Col md="3" className="col-filter-specialty-name">
                    <Row className="mr-1 mt-1">
                      <Label id="nameLabel" for="specialty-name">
                        <Translate contentKey="generadorApp.specialty.name">Name</Translate>
                      </Label>

                      <AvInput type="text" name="name" id="specialty-name" value={this.state.name} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'cost' ? (
                  <Col md="3" className="col-filter-specialty-cost">
                    <Row className="mr-1 mt-1">
                      <Label id="costLabel" for="specialty-cost">
                        <Translate contentKey="generadorApp.specialty.cost">Cost</Translate>
                      </Label>

                      <AvInput type="text" name="cost" id="specialty-cost" value={this.state.cost} />
                    </Row>
                  </Col>
                ) : null}
              </div>

              <div className="row mb-2 mr-4 justify-content-end">
                <Button color="primary" type="submit">
                  <i className="fa fa-filter" aria-hidden={'true'}></i>&nbsp;
                  <Translate contentKey="generadorApp.specialty.btnFilter">Filter</Translate>
                </Button>
                &nbsp;
                <Button color="secondary" onClick={this.cancelFilters}>
                  <FontAwesomeIcon icon="trash-alt" />
                  &nbsp;
                  <Translate contentKey="generadorApp.specialty.btnCleanFilter">Clean Filter</Translate>
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </UncontrolledCollapse>
      </>
    );
  }

  renderTable() {
    const {
      users,
      kitRhs,
      quoteRhs,
      specialtyStatusHistories,
      supplierTableRhs,
      medicalRecords,
      pushes,
      clComRulers,
      categories,
      doses,
      professionals,
      specialtyList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {specialtyList && specialtyList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null).length > 0 ? (
          <>
            <Table
              id="specialty-table-list"
              responsive
              aria-describedby="specialty-heading"
              className={'table-hover table-striped table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.specialty.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'name' ? (
                    <th className="hand" onClick={sortFunction('name')}>
                      <Translate contentKey="generadorApp.specialty.name">Name</Translate>
                      <FontAwesomeIcon icon={state.sort === 'name' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'category' ? (
                    <th>
                      <Translate contentKey="generadorApp.specialty.category">Category</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'createdDate' ? (
                    <th className="hand" onClick={sortFunction('createdDate')}>
                      <Translate contentKey="generadorApp.specialty.createdDate">Created Date</Translate>
                      <FontAwesomeIcon icon={state.sort === 'createdDate' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'lastModifiedDate' ? (
                    <th className="hand" onClick={sortFunction('lastModifiedDate')}>
                      <Translate contentKey="generadorApp.specialty.lastModifiedDate">Last Modified Date</Translate>
                      <FontAwesomeIcon
                        icon={state.sort === 'lastModifiedDate' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'}
                      />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'status' ? (
                    <th className="hand" onClick={sortFunction('status')}>
                      <Translate contentKey="generadorApp.specialty.status">Status</Translate>
                      <FontAwesomeIcon icon={state.sort === 'status' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}

                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {specialtyList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((specialty, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.specialty.field_id">ID</Translate>
                        </b>
                        {specialty.id}
                      </td>

                      {state.baseFilters !== 'name' ? (
                        <td id="name-cell">
                          <b className="visible-xs"> Name </b>

                          {specialty.name}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'category' ? (
                        <td id="category-cell">
                          <b className="visible-xs"> Category </b>
                          {specialty ? (
                            <span data-type-rel="2-many-to-one-other-side">
                              {getValueRecursive(specialty, 'category.name'.split('.'), '')}
                            </span>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'createdDate' ? (
                        <td id="createdDate-cell">
                          <b className="visible-xs"> Created Date </b>
                          {specialty.createdDate ? moment(specialty.createdDate).format(APP_DATE_FORMAT) : ''}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'lastModifiedDate' ? (
                        <td id="lastModifiedDate-cell">
                          <b className="visible-xs"> Last Modified Date </b>
                          {specialty.lastModifiedDate ? moment(specialty.lastModifiedDate).format(APP_DATE_FORMAT) : ''}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'status' ? (
                        <td id="status-cell">
                          <b className="visible-xs"> Status </b>
                          {specialty.status ? <Translate contentKey={`generadorApp.Status.${specialty.status}`} /> : <> </>}
                        </td>
                      ) : null}

                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          {hasAnyAuthority(props.userAccount, ['specialty'], 'edit') ? (
                            <Button onClick={() => this.openUpdateModal(specialty)} color="primary" size="sm">
                              <FontAwesomeIcon icon="pencil-alt" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                          {hasAnyAuthority(props.userAccount, ['specialty'], 'canDelete') ? (
                            <Button onClick={() => this.deleteEntity(specialty)} color="danger" size="sm">
                              <FontAwesomeIcon icon="trash" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                          <Button tag={Link} to={`/specialty/${specialty.id}/specialty-status-history`} color="info" size="sm">
                            <FontAwesomeIcon icon="check-square" />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.specialty.home.notFound">No Specialties found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const {
      users,
      kitRhs,
      quoteRhs,
      specialtyStatusHistories,
      supplierTableRhs,
      medicalRecords,
      pushes,
      clComRulers,
      categories,
      doses,
      professionals,
      specialtyList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={specialtyList && specialtyList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const {
      users,
      kitRhs,
      quoteRhs,
      specialtyStatusHistories,
      supplierTableRhs,
      medicalRecords,
      pushes,
      clComRulers,
      categories,
      doses,
      professionals,
      specialtyList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel className="specialty specialty-all-in-one">
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
            {this.renderNewModal()}
            {this.renderUpdateModal()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToProps = ({ specialty, ...storeState }: IRootState) => ({
  users: storeState.userManagement.users,
  kitRhs: storeState.kitRhs.entities,
  quoteRhs: storeState.quoteRh.entities,
  specialtyStatusHistories: storeState.specialtyStatusHistory.entities,
  supplierTableRhs: storeState.supplierTableRh.entities,
  medicalRecords: storeState.medicalRecord.entities,
  pushes: storeState.push.entities,
  clComRulers: storeState.clComRuler.entities,
  categories: storeState.category.entities,
  doses: storeState.dose.entities,
  professionals: storeState.professional.entities,
  specialtyList: specialty.entities,
  totalItems: specialty.totalItems,
  userAccount: storeState.authentication.account,
  loading: specialty.loading,

  // UPDATE
  specialtyEntity: specialty.entity,
});

export const mapDispatchToProps = {
  getEntities,

  updateEntity,
  reset,

  // UPDATE
  getEntity,
  createEntity,

  // DELETE
  deleteEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Specialty);
