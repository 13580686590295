import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import SpecialtyStatusHistory from './extended/specialty-status-history';

import SpecialtyStatusHistoryUpdate from './specialty-status-history';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={SpecialtyStatusHistoryUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={SpecialtyStatusHistoryUpdate} />
      <ErrorBoundaryRoute path={match.path} component={SpecialtyStatusHistory} />
    </Switch>
  </>
);

export default Routes;
