import React, { useEffect, useRef, useState } from "react";
import { Body, ButtonPagination, ButtonSearch, Card, InputReport, MenuPagination, Modal, OptionsReportName, OptionsTextDiv, OptionsTextName, SelectOption, SelectReport, SelectReportName, SelectStatus, TableCard, TableColumn, TableRow, TittleRow } from "./styled";
import Select from 'react-select';
import Axios from "axios";
import { saveAs } from 'file-saver';
import XLSX from 'xlsx';

const Reports = () => {

  const [page, setPage] = useState(0);
  const [data, setData] = useState<any>([]);
  const [dataAllUser, setDataAllUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalName, setOpenModalName] = useState(false);
  const [name, setName] = useState(null);
  const [selectPat, setSelectPat] = useState(null);
  const [selectOptions, setSelectOptions] = useState(null);
  const [dateA, setDateA] = useState(null);
  const [dateB, setDateB] = useState(null);
  const modalRef = useRef(null);


  function formatDate(dateValue) {
    const parts = dateValue.split('/');
    const day = parts[0];
    const month = parts[1];
    const year = parts[2];
    const formattedDate = `${year}/${month}/${day}`;
    return formattedDate;
  }


  const formatValue = (value) => {
    const sanitizedValue = value.replace(/[^\d]/g, '');
    let formattedValue = '';

    if (sanitizedValue.length >= 1) {
      formattedValue += sanitizedValue.slice(0, 2);
    }

    if (sanitizedValue.length >= 3) {
      formattedValue += '/' + sanitizedValue.slice(2, 4);
    }

    if (sanitizedValue.length >= 5) {
      formattedValue += '/' + sanitizedValue.slice(4, 8);
    }

    return formattedValue;
  };


  const handleInputChange = (event) => {
    const { value } = event.target;
    const formattedValue = formatValue(value);
    setDateA(formattedValue);
  };

  const handleInputChangeB = (event) => {
    const { value } = event.target;
    const formattedValue = formatValue(value);
    setDateB(formattedValue);
  };

  const getInfos = () => {
    setLoading(true)
    Axios
      .get(`/api/quotes?page=${page}&size=10&sort=id,desc&${selectPat === null ? "" : `patient.id.in=${selectPat?.id}&`}${selectOptions === null ? "" : `status.equals=${selectOptions}&`}${dateA != null && dateB === null ? `from.greaterThan=${formatDate(dateA)}&&` : ""}${dateA != null && dateB != null ? `from.between=${formatDate(dateA)},${formatDate(dateB)}&&` : ""}`)
      .then((res) => {
        setData(res.data)
        console.log(res.data)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  const getAllUsers = () => {
    Axios.get(`/api/patients?page=0&size=100&sort=name%2Casc`)
      .then((res) => {
        setDataAllUser(res.data)
        console.log(res.data)
      })
      .catch((err) => {
        console.log(err)
      })

  }
  const getNameUsers = () => {
    Axios.get(`/api/patients?name.contains=${name}&page=0&size=100&sort=name%2Casc`)
      .then((res) => {
        setDataAllUser(res.data)

      })
      .catch((err) => {
        console.log(err)
      })

  }

  const getInfosId = (id) => {
    Axios.get(`/api/quotes/${id}`)
      .then((res) => {
        console.log(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }


  const handleDownload = () => {
    // Criar uma matriz com os dados da tabela
    const tableData = data?.map((item) => [
      item?.id,
      item?.patient?.name,
      item?.patient?.client?.fantasyName,
      item?.billFinalAmount,
      item?.patient?.status
    ]);

    // Criar uma nova planilha do Excel
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(tableData);

    // Adicionar a planilha ao arquivo
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Dados');

    // Converter o arquivo para um objeto binário
    const excelBuffer = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });

    // Criar um Blob a partir do objeto binário
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Salvar o arquivo usando o FileSaver.js
    saveAs(blob, 'dados.xlsx');
  };


  useEffect(() => {
    getInfos()
    getAllUsers()
  }, [page])

  useEffect(() => {
    getNameUsers()

    if (name === '') {
      setName("")
      setSelectPat(null)
      getNameUsers()
      setDateA(null)
      setDateB(null)
    }

  }, [name, dateA, dateB, selectPat])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setOpenModalName(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const handleSelect = (itens) => {
    setSelectPat(itens)
    setName(itens?.name)
  }

  const generateXML = (data) => {
    const xmlItems = data.map((item) => {
      return `
        <Item>
          <ID>${item.id}</ID>
          <Nome>${item.patient?.name}</Nome>
          <NomeFantasia>${item.patient?.client?.fantasyName}</NomeFantasia>
          <Valor>${item.billFinalAmount}</Valor>
          <Status>${item.patient?.status}</Status>
        </Item>
      `;
    });

    const xmlContent = `
      <Data>
        ${xmlItems.join('\n')}
      </Data>
    `;

    return `<?xml version="1.0" encoding="UTF-8"?>
      ${xmlContent}
    `;
  };


  return (
    <>
      <Body>

        <Card>
          <div>
            <text style={{ width: 100, marginRight: 5 }}>Relatorios: </text>
            <SelectReport>
              <SelectOption>Orçamento</SelectOption>
            </SelectReport>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ marginTop: 20, display: "flex", flexDirection: "column" }}>
              <div>
                <text style={{ width: 100, marginRight: 5 }}>Paciente: </text>
                <SelectReportName value={name} onChange={(e) => setName(e.target.value)} onClick={() => setOpenModalName(true)}></SelectReportName>
              </div>
              {openModalName ?
                <OptionsReportName ref={modalRef}>
                  {dataAllUser?.map((itens) => {
                    return (
                      <OptionsTextDiv>
                        <OptionsTextName onClick={() => handleSelect(itens)}>{itens?.name}</OptionsTextName>
                      </OptionsTextDiv>
                    )
                  })}
                </OptionsReportName>
                : null
              }

            </div>
            <div style={{ marginTop: 20, marginLeft: 20 }}>
              <text style={{ width: 100 }}>Status:</text>
              <SelectStatus value={selectOptions} onChange={(e) => setSelectOptions(e.target.value)}>
                <SelectOption value={""}>Selecione</SelectOption>
                <SelectOption value={"ORCAMENTOABERTO"}>Orçamento aberto</SelectOption>
                <SelectOption value={"AGUARDANDOPRECIFICACAO"}>Aguardando precificação</SelectOption>
                <SelectOption value={"ORCAMENTOPRECIFICADO"}>Orçamento precificado</SelectOption>
                <SelectOption value={"AGUARDANDOAPROVACAO"}>Aguardando aprovação</SelectOption>
                <SelectOption value={"PLANTAOAPROVADO"}>Plantão aprovado</SelectOption>
                <SelectOption value={"APROVADO"}>Aprovado</SelectOption>
                <SelectOption value={"INATIVOREPROVADOPRECO"}>Preço reprovado inativo</SelectOption>
                <SelectOption value={"INATIVOCONTROLEINTERNO"}>Controle interno inativo</SelectOption>
                <SelectOption value={"CANCELADO"}>Cancelado</SelectOption>
              </SelectStatus>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ marginTop: 20 }}>
              <text style={{ marginRight: 5 }}>Data inicial: </text>
              <InputReport
                type="text"
                value={dateA}
                onChange={handleInputChange}
                placeholder="dd/mm/aaaa"
              ></InputReport>
            </div>
            <div style={{ marginTop: 20, marginLeft: 20 }}>
              <text>Data final: </text>
              <InputReport
                type="text"
                value={dateB}
                onChange={handleInputChangeB}
                placeholder="dd/mm/aaaa"

              ></InputReport>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
            <ButtonSearch onClick={() => getInfos()}>Buscar</ButtonSearch>
            <ButtonSearch style={{ marginLeft: 20 }} onClick={() => setOpenModal(true)}>Abrir XML</ButtonSearch>
          </div>
        </Card>
        <TableCard>
          {loading ?
            <div style={{ position: "absolute" }}>
              <div className="loading-spinner"></div>
            </div>

            : null}
          {data?.map((itens, index) => {

            const isEvenIndex = index % 2 === 0;

            return (
              <TableRow style={{ backgroundColor: isEvenIndex ? "#f2f3f4" : "#ffffff" }}>
                <TableColumn>
                  <TittleRow>ID: </TittleRow>
                  <TittleRow>{itens?.id}</TittleRow>
                </TableColumn>
                <TableColumn>
                  <TittleRow>Nome: </TittleRow>
                  <TittleRow>{itens?.patient?.name}</TittleRow>
                </TableColumn>
                <TableColumn>
                  <TittleRow>Nome Fantasia: </TittleRow>
                  <TittleRow>{itens?.patient?.client?.fantasyName}</TittleRow>
                </TableColumn>
                <TableColumn>
                  <TittleRow>Valor: </TittleRow>
                  <TittleRow>{itens?.billFinalAmount}</TittleRow>
                </TableColumn>
                <TableColumn>
                  <TittleRow>Status: </TittleRow>
                  <TittleRow>{itens?.patient?.status}</TittleRow>
                </TableColumn>
              </TableRow>
            )
          })}
          <MenuPagination>
            <ButtonPagination onClick={() => setPage(page === 0 ? 0 : page - 1)} style={{ marginRight: 10 }}>Voltar</ButtonPagination>
            <TittleRow style={{ color: "black" }}> {page} </TittleRow>
            {data?.length < 10 ? null : <ButtonPagination onClick={() => setPage(page + 1)} style={{ marginLeft: 10 }}>Próxima</ButtonPagination>}
          </MenuPagination>
        </TableCard>
        {openModal ?
          <Modal>
            <TableCard>
              {data?.map((itens, index) => {
                const isEvenIndex = index % 2 === 0;
                return (
                  <>
                    {
                      itens?.quoteRh?.map((itensChild) => {
                        return (
                          <TableRow style={{ backgroundColor: isEvenIndex ? "#f2f3f4" : "#ffffff" }}>
                            <TableColumn>
                              <TittleRow>ID: </TittleRow>
                              <TittleRow>{itensChild?.id}</TittleRow>
                            </TableColumn>
                            <TableColumn>
                              <TittleRow>Tipo: </TittleRow>
                              <TittleRow>{itensChild?.typeDiscount}</TittleRow>
                            </TableColumn>
                            <TableColumn>
                              <TittleRow>Frequencia: </TittleRow>
                              <TittleRow>{itensChild?.frequency}</TittleRow>
                            </TableColumn>
                            <TableColumn>
                              <TittleRow>Quantidade: </TittleRow>
                              <TittleRow>{itensChild?.quantity}</TittleRow>
                            </TableColumn>
                            <TableColumn>
                              <TittleRow>Valor unitário: </TittleRow>
                              <TittleRow>{itensChild?.unitaryAmountCost}</TittleRow>
                            </TableColumn>
                          </TableRow>
                        )
                      })
                    }
                  </>
                )
              })}
            </TableCard>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: 20}}>
              <ButtonSearch onClick={handleDownload}>Baixar XLSX</ButtonSearch>
              <ButtonSearch style={{ marginLeft: 20}} onClick={() => setOpenModal(false)}>Fechar</ButtonSearch>
            </div>
          </Modal>
          :
          null
        }

      </Body>
    </>
  )
}

export default Reports;