import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { IClientGlosaItem } from 'app/shared/model/client-glosa-item.model';
import { ISupplierGlosaItem } from 'app/shared/model/supplier-glosa-item.model';
import { IQuote } from 'app/shared/model/quote.model';
import { IProductService } from 'app/shared/model/product-service.model';
import { ISupplierTableProductService } from 'app/shared/model/supplier-table-product-service.model';
import { QuoteDiscountType } from 'app/shared/model/enumerations/quote-discount-type.model';
import { QuoteProductServiceFrequency } from 'app/shared/model/enumerations/quote-product-service-frequency.model';
import { SuprimentoStatus } from 'app/shared/model/enumerations/suprimento-status.model';

export interface IQuoteProductService {
  id?: number;
  startDate?: Moment;
  finishDate?: Moment;
  days?: number;
  quantity?: number;
  currentQuantity?: number;
  unitaryAmount?: number;
  grossAmount?: number;
  typeDiscount?: QuoteDiscountType;
  discountPercent?: number;
  discount?: number;
  finalAmount?: number;
  frequency?: QuoteProductServiceFrequency;
  additive?: boolean;
  billQuantityItem?: number;
  billGrossAmount?: number;
  billFinalAmount?: number;
  suprimentoStatus?: SuprimentoStatus;
  clientGlosaItem?: IClientGlosaItem[];
  supplierGlosaItem?: ISupplierGlosaItem[];
  quote?: IQuote;
  productService?: IProductService;
  comercialTable?: ISupplierTableProductService;
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  clientGlosaItem: {
    isMulti: false, // one-to-many - other-side
    entity: 'ClientGlosaItem',
    urlParameters: {
      enpoint: 'client-glosa-items',
      filters: 'quoteProductService.id.equals',
    },
  },
  supplierGlosaItem: {
    isMulti: false, // one-to-many - other-side
    entity: 'SupplierGlosaItem',
    urlParameters: {
      enpoint: 'supplier-glosa-items',
      filters: 'quoteProductService.id.equals',
    },
  },
  quote: {
    isMulti: true, // many-to-one - other-side
    entity: 'Quote',
    urlParameters: {
      enpoint: 'quotes',
      filters: 'quoteProductService.id.equals',
    },
  },
  productService: {
    isMulti: true, // many-to-one - other-side
    entity: 'ProductService',
    urlParameters: {
      enpoint: 'product-services',
      filters: 'quoteProductService.id.equals',
    },
  },
  supplierTableProductService: {
    isMulti: true, // many-to-one - other-side
    entity: 'SupplierTableProductService',
    urlParameters: {
      enpoint: 'supplier-table-product-services',
      filters: 'quoteProductService.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'quoteProductService.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'quoteProductService.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<IQuoteProductService> = {
  additive: false,
};
