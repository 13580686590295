/* eslint complexity: ["error", 500] */
import React from 'react';

import { connect } from 'react-redux';

import { toast } from 'react-toastify';

import { Link, RouteComponentProps } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { Button, Col, Row, Table, Label, UncontrolledCollapse, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { getSortState, IPaginationBaseState, JhiItemCount, setFileData } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { IRootState } from 'app/shared/reducers';
import {
  updateEntity,
  getUrlBack,
  getWhiteLabelState,
  IWhiteLabelBaseState,
  getEntityFiltersURL,
  getEntities,
  IWhiteLabelUpdateState as IBaseUpdateState,
  reset,
  getEntity,
  deleteEntity,
  createEntity,
  setBlob,
} from './white-label.reducer';

import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';

import { viacepRequest } from 'app/shared/util/entity-utils';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

export interface IWhiteLabelProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export interface IWhiteLabelState extends IWhiteLabelBaseState, IPaginationBaseState, IBaseUpdateState {
  showModalForm?: boolean;
  showModalEdit?: number;
  showModalEditView?: boolean;
}

export class WhiteLabel extends React.Component<IWhiteLabelProps, IWhiteLabelState> {
  protected myFormRef: any;
  protected myFormRefNew: any;
  protected myFormRefEdit: any;

  socialReasonFileInput: any;

  fantasyNameFileInput: any;

  logoFileInput: any;

  cnpjFileInput: any;

  zipCodeFileInput: any;

  streetFileInput: any;

  complementFileInput: any;

  numberFileInput: any;

  neighborhoodFileInput: any;

  cityFileInput: any;

  ufFileInput: any;

  regANSFileInput: any;

  regCNESFileInput: any;

  technicalManagerFileInput: any;

  technicalManagerNurseFileInput: any;

  inscriptionFileInput: any;

  councilCodeFileInput: any;

  ufCodeFileInput: any;

  cboCodeFileInput: any;

  constructor(props: IWhiteLabelProps) {
    super(props);
    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      listCheckedID: [],
      isCheckedAll: false,
      showModalForm: false,
      showModalEdit: null,
      urlBack: getUrlBack(this.props.location),
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      ...getWhiteLabelState(this.props.location),

      cepRequestCep: null,
      cepRequestLogradouro: null,

      cepRequestBairro: null,
      cepRequestLocalidade: null,
      cepRequestUf: null,

      logoSelectValue: null,
      socialReasonSelectValue: null,
      fantasyNameSelectValue: null,
      cnpjSelectValue: null,
      zipCodeSelectValue: null,
      streetSelectValue: null,
      complementSelectValue: null,
      numberSelectValue: null,
      neighborhoodSelectValue: null,
      citySelectValue: null,
      ufSelectValue: null,
      regANSSelectValue: null,
      regCNESSelectValue: null,
      technicalManagerSelectValue: null,
      technicalManagerNurseSelectValue: null,
      inscriptionSelectValue: null,
      councilCodeSelectValue: null,
      ufCodeSelectValue: null,
      cboCodeSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getWhiteLabelState(this.props.location),
      },
      isNew: false,
    };
  }

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({ listCheckedID: this.props.whiteLabelList.map(whiteLabel => whiteLabel.id) });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({ listCheckedID: this.props.whiteLabelList.map(whiteLabel => whiteLabel.id) });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  removeEntitySelected() {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  }

  saveEntityMany = () => {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  };

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  cancelFilters = () => {
    this.setState(
      {
        socialReason: '',
        fantasyName: '',
        cnpj: '',
        zipCode: '',
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();
    const { socialReason, fantasyName, cnpj, zipCode, activePage, itemsPerPage, sort, order } = this.state;
    this.props.getEntities(socialReason, fantasyName, cnpj, zipCode, activePage - 1, itemsPerPage, `${sort},${order}`);
  };

  updateEntity = entity => {
    const { socialReason, fantasyName, cnpj, zipCode, activePage, itemsPerPage, sort, order } = this.state;
    this.props.updateEntity(entity, [socialReason, fantasyName, cnpj, zipCode, activePage - 1, itemsPerPage, `${sort},${order}`]);
  };

  onBlobChange = (isAnImage, name, fileInput) => event => {
    const fileName = event.target.files[0].name;
    setFileData(event, (contentType, data) => this.props.setBlob(name, data, contentType, fileName), isAnImage);
    const varAux = {};
    varAux[name + 'SelectValue'] = fileName;
    this.setState(varAux);
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined);
  };

  saveEntity = (event: any, errors: any, values: any, isNew: any) => {
    if (!this.state.logoSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.whiteLabel.logo') }));
      return false;
    }

    if (!this.state.cepRequestCep) {
      errors.push('cepRequestCep');
    }

    if (errors.length === 0) {
      const { whiteLabelEntity } = this.props;
      const _entity = {
        ...whiteLabelEntity,
        ...values,

        logo: this.state.logoSelectValue,
        socialReason: this.state.socialReasonSelectValue,
        fantasyName: this.state.fantasyNameSelectValue,
        cnpj: this.state.cnpjSelectValue,
        zipCode: this.state.cepRequestCep,
        street: this.state.cepRequestLogradouro,
        complement: this.state.complementSelectValue,
        number: this.state.numberSelectValue,
        neighborhood: this.state.cepRequestBairro,
        city: this.state.cepRequestLocalidade,
        uf: this.state.cepRequestUf,
        regANS: this.state.regANSSelectValue,
        regCNES: this.state.regCNESSelectValue,
        technicalManager: this.state.technicalManagerSelectValue,
        technicalManagerNurse: this.state.technicalManagerNurseSelectValue,
        inscription: this.state.inscriptionSelectValue,
        councilCode: this.state.councilCodeSelectValue,
        ufCode: this.state.ufCodeSelectValue,
        cboCode: this.state.cboCodeSelectValue,
      };
      const entity = _entity;

      const { socialReason, fantasyName, cnpj, zipCode } = this.state.fieldsBase;

      if (isNew) {
        new Promise(resolve => {
          resolve(this.props.createEntity(entity, [socialReason, fantasyName, cnpj, zipCode]));
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(this.props.updateEntity(entity, [socialReason, fantasyName, cnpj, zipCode]));
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
      this.setState({ showModalForm: false });
      this.setState({ showModalEdit: null });
    } else {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.whitelabel.' + errors[0]) }));
    }
  };

  deleteEntity(whiteLabelEntity) {
    MySwal.fire({
      title: <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>,
      html: (
        <Translate contentKey="generadorApp.whiteLabel.delete.question" interpolate={{ id: whiteLabelEntity.id }}>
          Are you sure you want to delete this WhiteLabel?
        </Translate>
      ),
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#e70015',
      cancelButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="ban" />
          &nbsp; <Translate contentKey="generadorApp.whiteLabel.btnCancel">Cancel</Translate>
        </>
      ),
      confirmButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="trash" />
          &nbsp;<Translate contentKey="generadorApp.whiteLabel.btnDelete">Delete</Translate>
        </>
      ),
    }).then(async response => {
      if (response.isConfirmed) {
        await this.props.deleteEntity(whiteLabelEntity.id, { reloadList: false });
        this.getEntities();
        MySwal.fire({
          title: <Translate contentKey="entity.delete.title.confirmed">Confirmed delete operation</Translate>,
          icon: 'success',
        });
      }
    });
  }

  renderNewModal() {
    return (
      <Modal size={'lg'} isOpen={this.state.showModalForm} toggle={() => this.setState({ showModalForm: false })}>
        <ModalHeader toggle={() => this.setState({ showModalForm: false })}>{this.renderHeaderUpdate({}, true)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefNew = el)}
          model={{}}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, true)}
        >
          <ModalBody>{this.renderBodyUpdate({}, true)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalForm: false })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.whiteLabel.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefNew && this.myFormRefNew.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.whiteLabel.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    );
  }

  getFullEntityFromState(v) {
    return {
      logo: this.state.logoSelectValue,
      socialReason: this.state.socialReasonSelectValue,
      fantasyName: this.state.fantasyNameSelectValue,
      cnpj: this.state.cnpjSelectValue,
      zipCode: this.state.zipCodeSelectValue,
      street: this.state.streetSelectValue,
      complement: this.state.complementSelectValue,
      number: this.state.numberSelectValue,
      neighborhood: this.state.neighborhoodSelectValue,
      city: this.state.citySelectValue,
      uf: this.state.ufSelectValue,
      regANS: this.state.regANSSelectValue,
      regCNES: this.state.regCNESSelectValue,
      technicalManager: this.state.technicalManagerSelectValue,
      technicalManagerNurse: this.state.technicalManagerSelectValue,
      inscription: this.state.inscriptionSelectValue,
      councilCode: this.state.councilCodeSelectValue,
      ufCode: this.state.ufCodeSelectValue,
      cboCode: this.state.cboCodeSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    const p: any = null;

    return {
      logoSelectValue: v.logo, // logo,
      socialReasonSelectValue: v.socialReason, // socialReason,
      fantasyNameSelectValue: v.fantasyName, // fantasyName,
      cnpjSelectValue: v.cnpj, // cnpj,
      zipCodeSelectValue: v.zipCode, // zipCode,
      streetSelectValue: v.street, // street,
      complementSelectValue: v.complement, // complement,
      numberSelectValue: v.number, // number,
      neighborhoodSelectValue: v.neighborhood, // neighborhood,
      citySelectValue: v.city, // city,
      ufSelectValue: v.uf, // uf,
      regANSSelectValue: v.regANS, // regANS,
      regCNESSelectValue: v.regCNES, // regCNES,
      technicalManagerSelectValue: v.technicalManager, // technicalManager,
      technicalManagerNurseSelectValue: v.technicalManagerNurse, // technicalManagerNurse,
      inscriptionSelectValue: v.inscription, // inscription,
      councilCodeSelectValue: v.councilCode, // councilCode,
      ufCodeSelectValue: v.ufCode, // ufCode,
      cboCodeSelectValue: v.cboCode, // cboCode,
    };
  }

  openUpdateModal(v, edit = true) {
    this.props.getEntity(v.id);
    this.setState({
      showModalEdit: v.id,
      showModalEditView: edit,
      ...this.getFullEntitySelectValue(v),
    });
  }

  renderUpdateModal() {
    const { whiteLabelEntity } = this.props;
    return whiteLabelEntity && whiteLabelEntity.id ? (
      <Modal size={'lg'} isOpen={this.state.showModalEdit === whiteLabelEntity.id} toggle={() => this.setState({ showModalEdit: null })}>
        <ModalHeader toggle={() => this.setState({ showModalEdit: null })}>{this.renderHeaderUpdate(whiteLabelEntity, false)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefEdit = el)}
          model={{
            ...whiteLabelEntity,
          }}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, false)}
        >
          <ModalBody>{this.renderBodyUpdate(whiteLabelEntity, false)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalEdit: null })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.whiteLabel.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefEdit && this.myFormRefEdit.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.whiteLabel.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    ) : (
      <> </>
    );
  }

  renderHeaderUpdate(whiteLabelEntity, isNew) {
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {isNew ? (
              <Translate contentKey="generadorApp.whiteLabel.home.createLabel">Create a WhiteLabel</Translate>
            ) : (
              <Translate contentKey="generadorApp.whiteLabel.home.editLabel">Edit a WhiteLabel</Translate>
            )}
          </span>
        </div>
      </>
    );
  }
  renderBodyUpdate(whiteLabelEntity, isNew) {
    const { loading } = this.props;

    const { logo, logoContentType, logoBase64 } = whiteLabelEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
              <Col md="3">
              <Label className="mt-2 label-single-line"for="whiteLabel-id">
                <Translate contentKey="generadorApp.whiteLabel.field_id">ID</Translate>
              </Label>
              </Col> */}
                      <Col md="12">
                        <AvInput id="whiteLabel-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'logo' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="6">
                            <AvGroup>
                              <Row>
                                <Col md="12">
                                  <Label className="mt-2 label-single-line" id="logoLabel" for="logo">
                                    <Translate contentKey="generadorApp.whiteLabel.logo">Filename</Translate>(*)
                                  </Label>
                                </Col>
                                <Col md="12">
                                  {logo || logoBase64 ? (
                                    <div>
                                      <Row>
                                        <Col md="11"></Col>
                                        <Col md="1">
                                          <Button color="danger" onClick={this.clearBlob('logo')}>
                                            <FontAwesomeIcon icon="times-circle" />
                                          </Button>
                                        </Col>
                                      </Row>
                                      <a rel="noopener noreferrer" target={'_blank'} href={`${logo}`}>
                                        <img
                                          src={`${logoBase64 ? 'data:' + logoContentType + ';base64,' + logoBase64 : logo}`}
                                          style={{ maxHeight: '100px' }}
                                        />
                                      </a>
                                      <br />
                                    </div>
                                  ) : null}

                                  <input
                                    className="file_input"
                                    id="file_logo"
                                    type="file"
                                    ref={el => (this.logoFileInput = el)}
                                    onChange={this.onBlobChange(false, 'logo', this.logoFileInput)}
                                    accept="*"
                                  />

                                  <label htmlFor="file_logo" className="file_btn_open">
                                    <FontAwesomeIcon icon="upload" />
                                    <Translate contentKey="generadorApp.whiteLabel.upload_logo">Upload Filename </Translate>
                                  </label>

                                  <AvInput
                                    onChange={evt => this.setState({ logoSelectValue: evt.target.value })}
                                    type="hidden"
                                    name="logo"
                                    value={logo}
                                  />
                                </Col>
                              </Row>
                            </AvGroup>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ logoSelectValue: evt.target.value })}
                      type="hidden"
                      name="logo"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'socialReason' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="socialReasonLabel" for="whiteLabel-socialReason">
                              <Translate contentKey="generadorApp.whiteLabel.socialReason">Social Reason</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <AvInput
                              onChange={evt => this.setState({ socialReasonSelectValue: evt.target.value })}
                              id="whiteLabel-socialReason"
                              type="text"
                              name="socialReason"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ socialReasonSelectValue: evt.target.value })}
                      type="hidden"
                      name="socialReason"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'fantasyName' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="fantasyNameLabel" for="whiteLabel-fantasyName">
                              <Translate contentKey="generadorApp.whiteLabel.fantasyName">Fantasy Name</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <AvInput
                              onChange={evt => this.setState({ fantasyNameSelectValue: evt.target.value })}
                              id="whiteLabel-fantasyName"
                              type="text"
                              name="fantasyName"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ fantasyNameSelectValue: evt.target.value })}
                      type="hidden"
                      name="fantasyName"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'cnpj' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="cnpjLabel" for="whiteLabel-cnpj">
                              <Translate contentKey="generadorApp.whiteLabel.cnpj">Cnpj</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <AvInput
                              onChange={evt => this.setState({ cnpjSelectValue: evt.target.value })}
                              id="whiteLabel-cnpj"
                              type="text"
                              name="cnpj"
                              className={'form-control'}
                              mask="99.999.999/9999-99"
                              tag={InputMask}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ cnpjSelectValue: evt.target.value })}
                      type="hidden"
                      name="cnpj"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'zipCode' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label
                              className={'mt-2' + (this.state.cepRequestCep === '' ? ' text-danger' : '')}
                              id="zipCodeLabel"
                              for="whiteLabel-zipCode"
                            >
                              <Translate contentKey="generadorApp.whiteLabel.zipCode">Zip Code</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              id="whiteLabel-zipCode"
                              type="text"
                              name="zipCode"
                              className={
                                'form-control' + (this.state.cepRequestCep === '' ? ' is-touched is-pristine av-invalid is-invalid' : '')
                              }
                              value={this.state.cepRequestCep ? this.state.cepRequestCep : this.state.zipCodeSelectValue}
                              onBlur={inputVal => {
                                viacepRequest(inputVal.target.value, (cepRequest: any) => {
                                  this.setState(cepRequest);
                                });
                                this.setState({ zipCodeSelectValue: inputVal.target.value });
                                this.setState({ cepRequestCep: inputVal.target.value ? inputVal.target.value : '' });
                              }}
                              onChange={inputVal => {
                                this.setState({ cepRequestCep: inputVal.target.value });
                                this.setState({ zipCodeSelectValue: inputVal.target.value });
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ zipCodeSelectValue: evt.target.value })}
                      type="hidden"
                      name="zipCode"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'street' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="streetLabel" for="whiteLabel-street">
                              <Translate contentKey="generadorApp.whiteLabel.street">Street</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              id="whiteLabel-street"
                              type="text"
                              name="street"
                              className={'form-control'}
                              value={this.state.cepRequestLogradouro ? this.state.cepRequestLogradouro : this.state.streetSelectValue}
                              onBlur={inputVal =>
                                this.setState({ cepRequestLogradouro: inputVal.target.value ? inputVal.target.value : '' })
                              }
                              onChange={inputVal => {
                                this.setState({ cepRequestLogradouro: inputVal.target.value });
                                this.setState({ streetSelectValue: inputVal.target.value });
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ streetSelectValue: evt.target.value })}
                      type="hidden"
                      name="street"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'complement' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="complementLabel" for="whiteLabel-complement">
                              <Translate contentKey="generadorApp.whiteLabel.complement">Complement</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <AvInput
                              onChange={evt => this.setState({ complementSelectValue: evt.target.value })}
                              validate={{ maxLength: { value: 200, errorMessage: translate('entity.validation.maxlength', { max: 200 }) } }}
                              id="whiteLabel-complement"
                              type="text"
                              name="complement"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ complementSelectValue: evt.target.value })}
                      type="hidden"
                      name="complement"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'number' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="numberLabel" for="whiteLabel-number">
                              <Translate contentKey="generadorApp.whiteLabel.number">Number</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <AvInput
                              onChange={evt => this.setState({ numberSelectValue: evt.target.value })}
                              id="whiteLabel-number"
                              type="text"
                              name="number"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ numberSelectValue: evt.target.value })}
                      type="hidden"
                      name="number"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'neighborhood' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="neighborhoodLabel" for="whiteLabel-neighborhood">
                              <Translate contentKey="generadorApp.whiteLabel.neighborhood">Neighborhood</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              id="whiteLabel-neighborhood"
                              type="text"
                              name="neighborhood"
                              className={'form-control'}
                              value={this.state.cepRequestBairro ? this.state.cepRequestBairro : this.state.neighborhoodSelectValue}
                              onBlur={inputVal => this.setState({ cepRequestBairro: inputVal.target.value ? inputVal.target.value : '' })}
                              onChange={inputVal => {
                                this.setState({ cepRequestBairro: inputVal.target.value });
                                this.setState({ neighborhoodSelectValue: inputVal.target.value });
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ neighborhoodSelectValue: evt.target.value })}
                      type="hidden"
                      name="neighborhood"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'city' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="cityLabel" for="whiteLabel-city">
                              <Translate contentKey="generadorApp.whiteLabel.city">City</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              id="whiteLabel-city"
                              type="text"
                              name="city"
                              className={'form-control'}
                              value={this.state.cepRequestLocalidade ? this.state.cepRequestLocalidade : this.state.citySelectValue}
                              onBlur={inputVal =>
                                this.setState({ cepRequestLocalidade: inputVal.target.value ? inputVal.target.value : '' })
                              }
                              onChange={inputVal => {
                                this.setState({ cepRequestLocalidade: inputVal.target.value });
                                this.setState({ citySelectValue: inputVal.target.value });
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ citySelectValue: evt.target.value })}
                      type="hidden"
                      name="city"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'uf' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="ufLabel" for="whiteLabel-uf">
                              <Translate contentKey="generadorApp.whiteLabel.uf">Uf</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              id="whiteLabel-uf"
                              type="text"
                              name="uf"
                              className={'form-control'}
                              value={this.state.cepRequestUf ? this.state.cepRequestUf : this.state.ufSelectValue}
                              onBlur={inputVal => this.setState({ cepRequestUf: inputVal.target.value ? inputVal.target.value : '' })}
                              onChange={inputVal => {
                                this.setState({ cepRequestUf: inputVal.target.value });
                                this.setState({ ufSelectValue: inputVal.target.value });
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ ufSelectValue: evt.target.value })}
                      type="hidden"
                      name="uf"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'regANS' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="regANSLabel" for="whiteLabel-regANS">
                              <Translate contentKey="generadorApp.whiteLabel.regANS">Reg. ANS</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <AvInput
                              onChange={evt => this.setState({ regANSSelectValue: evt.target.value })}
                              validate={{ maxLength: { value: 250, errorMessage: translate('entity.validation.maxlength', { max: 250 }) } }}
                              id="whiteLabel-regANS"
                              type="text"
                              name="regANS"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ regANSSelectValue: evt.target.value })}
                      type="hidden"
                      name="regANS"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'regCNES' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="regCNESLabel" for="whiteLabel-regCNES">
                              <Translate contentKey="generadorApp.whiteLabel.regCNES">Reg. CNES</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <AvInput
                              onChange={evt => this.setState({ regCNESSelectValue: evt.target.value })}
                              validate={{ maxLength: { value: 250, errorMessage: translate('entity.validation.maxlength', { max: 250 }) } }}
                              id="whiteLabel-regCNES"
                              type="text"
                              name="regCNES"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ regCNESSelectValue: evt.target.value })}
                      type="hidden"
                      name="regCNES"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'technicalManager' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="technicalManagerLabel" for="whiteLabel-technicalManager">
                              <Translate contentKey="generadorApp.whiteLabel.technicalManager">Technical manager</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <AvInput
                              onChange={evt => this.setState({ technicalManagerSelectValue: evt.target.value })}
                              validate={{ maxLength: { value: 250, errorMessage: translate('entity.validation.maxlength', { max: 250 }) } }}
                              id="whiteLabel-technicalManager"
                              type="text"
                              name="technicalManager"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ technicalManagerSelectValue: evt.target.value })}
                      type="hidden"
                      name="technicalManager"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'technicalManagerNurse' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label
                              className="mt-2 label-single-line"
                              id="technicalManagerNurseLabel"
                              for="whiteLabel-technicalManagerNurse"
                            >
                              <Translate contentKey="generadorApp.patientDoctorPrescription.technicalManagerNurse">
                                Technical nurse manager
                              </Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ technicalManagerNurseSelectValue: evt.target.value })}
                              value={this.state.technicalManagerNurseSelectValue ? this.state.technicalManagerNurseSelectValue : ''}
                              id="whiteLabel-technicalManagerNurse"
                              type="text"
                              name="technicalManagerNurse"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ technicalManagerNurseSelectValue: evt.target.value })}
                      type="hidden"
                      name="technicalManagerNurse"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'inscription' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="inscriptionLabel" for="whiteLabel-inscription">
                              <Translate contentKey="generadorApp.whiteLabel.inscription">Inscription</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <AvInput
                              onChange={evt => this.setState({ inscriptionSelectValue: evt.target.value })}
                              validate={{ maxLength: { value: 250, errorMessage: translate('entity.validation.maxlength', { max: 250 }) } }}
                              id="whiteLabel-inscription"
                              type="text"
                              name="inscription"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ inscriptionSelectValue: evt.target.value })}
                      type="hidden"
                      name="inscription"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'councilCode' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="councilCodeLabel" for="whiteLabel-councilCode">
                              <Translate contentKey="generadorApp.whiteLabel.councilCode">Council Code</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <AvInput
                              onChange={evt => this.setState({ councilCodeSelectValue: evt.target.value })}
                              validate={{ maxLength: { value: 250, errorMessage: translate('entity.validation.maxlength', { max: 250 }) } }}
                              id="whiteLabel-councilCode"
                              type="text"
                              name="councilCode"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ councilCodeSelectValue: evt.target.value })}
                      type="hidden"
                      name="councilCode"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'ufCode' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="ufCodeLabel" for="whiteLabel-ufCode">
                              <Translate contentKey="generadorApp.whiteLabel.ufCode">UF Code</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <AvInput
                              onChange={evt => this.setState({ ufCodeSelectValue: evt.target.value })}
                              validate={{ maxLength: { value: 250, errorMessage: translate('entity.validation.maxlength', { max: 250 }) } }}
                              id="whiteLabel-ufCode"
                              type="text"
                              name="ufCode"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ ufCodeSelectValue: evt.target.value })}
                      type="hidden"
                      name="ufCode"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'cboCode' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="cboCodeLabel" for="whiteLabel-cboCode">
                              <Translate contentKey="generadorApp.whiteLabel.cboCode">CBO Code</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <AvInput
                              onChange={evt => this.setState({ cboCodeSelectValue: evt.target.value })}
                              validate={{ maxLength: { value: 250, errorMessage: translate('entity.validation.maxlength', { max: 250 }) } }}
                              id="whiteLabel-cboCode"
                              type="text"
                              name="cboCode"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ cboCodeSelectValue: evt.target.value })}
                      type="hidden"
                      name="cboCode"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}{' '}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  renderHeader() {
    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.whiteLabel.home.title">Companies</Translate>
          </span>
          <Button id="togglerFilterWhiteLabel" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.whiteLabel.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          &nbsp;
          <Button
            onClick={() =>
              this.setState({
                showModalForm: true,
                logoSelectValue: null,
                socialReasonSelectValue: null,
                fantasyNameSelectValue: null,
                cnpjSelectValue: null,
                zipCodeSelectValue: null,
                streetSelectValue: null,
                complementSelectValue: null,
                numberSelectValue: null,
                neighborhoodSelectValue: null,
                citySelectValue: null,
                ufSelectValue: null,
                regANSSelectValue: null,
                regCNESSelectValue: null,
                technicalManagerSelectValue: null,
                technicalManagerNurseSelectValue: null,
                inscriptionSelectValue: null,
                councilCodeSelectValue: null,
                ufCodeSelectValue: null,
                cboCodeSelectValue: null,

                isNew: true,
              })
            }
            color="primary"
            className="btn btn-primary float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="generadorApp.whiteLabel.btnNewItem">Create new</Translate>
          </Button>{' '}
          &nbsp;
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.whiteLabel.home.title">Companies</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <UncontrolledCollapse toggler="#togglerFilterWhiteLabel">
          <CardBody>
            <AvForm ref={el => (this.myFormRef = el)} id="form-filter" onSubmit={this.filterEntity}>
              <div className="row mt-1 ml-3 mr-3">
                {this.state.baseFilters !== 'socialReason' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <Label id="socialReasonLabel" for="whiteLabel-socialReason">
                        <Translate contentKey="generadorApp.whiteLabel.socialReason">Social Reason</Translate>
                      </Label>

                      <AvInput type="text" name="socialReason" id="whiteLabel-socialReason" value={this.state.socialReason} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'fantasyName' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <Label id="fantasyNameLabel" for="whiteLabel-fantasyName">
                        <Translate contentKey="generadorApp.whiteLabel.fantasyName">Fantasy Name</Translate>
                      </Label>

                      <AvInput type="text" name="fantasyName" id="whiteLabel-fantasyName" value={this.state.fantasyName} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'cnpj' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <Label id="cnpjLabel" for="whiteLabel-cnpj">
                        <Translate contentKey="generadorApp.whiteLabel.cnpj">Cnpj</Translate>
                      </Label>

                      <AvInput type="text" name="cnpj" id="whiteLabel-cnpj" value={this.state.cnpj} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'zipCode' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <Label id="zipCodeLabel" for="whiteLabel-zipCode">
                        <Translate contentKey="generadorApp.whiteLabel.zipCode">Zip Code</Translate>
                      </Label>

                      <AvInput type="text" name="zipCode" id="whiteLabel-zipCode" value={this.state.zipCode} />
                    </Row>
                  </Col>
                ) : null}
              </div>

              <div className="row mb-2 mr-4 justify-content-end">
                <Button color="primary" type="submit">
                  <i className="fa fa-filter" aria-hidden={'true'}></i>&nbsp;
                  <Translate contentKey="generadorApp.whiteLabel.btnFilter">Filter</Translate>
                </Button>
                &nbsp;
                <Button color="secondary" onClick={this.cancelFilters}>
                  <FontAwesomeIcon icon="trash-alt" />
                  &nbsp;
                  <Translate contentKey="generadorApp.whiteLabel.btnCleanFilter">Clean Filter</Translate>
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </UncontrolledCollapse>
      </>
    );
  }

  renderTable() {
    const { whiteLabelList, match } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {whiteLabelList && whiteLabelList.length > 0 ? (
          <>
            <Table
              id="whiteLabel-table-list"
              responsive
              aria-describedby="whiteLabel-heading"
              className={'table-hover table-striped table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.whiteLabel.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'logo' ? (
                    <th className="hand" onClick={sortFunction('logo')}>
                      <Translate contentKey="generadorApp.whiteLabel.logo">Filename</Translate>
                      <FontAwesomeIcon icon={state.sort === 'logo' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'socialReason' ? (
                    <th className="hand" onClick={sortFunction('socialReason')}>
                      <Translate contentKey="generadorApp.whiteLabel.socialReason">Social Reason</Translate>
                      <FontAwesomeIcon icon={state.sort === 'socialReason' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'fantasyName' ? (
                    <th className="hand" onClick={sortFunction('fantasyName')}>
                      <Translate contentKey="generadorApp.whiteLabel.fantasyName">Fantasy Name</Translate>
                      <FontAwesomeIcon icon={state.sort === 'fantasyName' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'cnpj' ? (
                    <th className="hand" onClick={sortFunction('cnpj')}>
                      <Translate contentKey="generadorApp.whiteLabel.cnpj">Cnpj</Translate>
                      <FontAwesomeIcon icon={state.sort === 'cnpj' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'zipCode' ? (
                    <th className="hand" onClick={sortFunction('zipCode')}>
                      <Translate contentKey="generadorApp.whiteLabel.zipCode">Zip Code</Translate>
                      <FontAwesomeIcon icon={state.sort === 'zipCode' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}

                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {whiteLabelList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((whiteLabel, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.whiteLabel.field_id">ID</Translate>
                        </b>
                        <Button tag={Link} to={`${match.url}/${whiteLabel.id}`} color="link" size="sm">
                          {whiteLabel.id}
                        </Button>
                      </td>
                      {state.baseFilters !== 'logo' ? (
                        <td id="logo-cell">
                          <b className="visible-xs"> Filename </b>
                          {whiteLabel.logo ? (
                            <div>
                              <a rel="noopener noreferrer" target={'_blank'} href={`${whiteLabel.logo}`}>
                                {whiteLabel.logoContentType && whiteLabel.logoContentType.includes('image/') ? (
                                  <img src={`${whiteLabel.logo}`} style={{ maxHeight: '30px' }} />
                                ) : (
                                  <Translate contentKey="generadorApp.whiteLabel.btnOpen">Open</Translate>
                                )}
                              </a>
                            </div>
                          ) : null}
                        </td>
                      ) : null}
                      {state.baseFilters !== 'socialReason' ? (
                        <td id="socialReason-cell">
                          <b className="visible-xs"> Social Reason </b>

                          {whiteLabel.socialReason}
                        </td>
                      ) : null}
                      {state.baseFilters !== 'fantasyName' ? (
                        <td id="fantasyName-cell">
                          <b className="visible-xs"> Fantasy Name </b>

                          {whiteLabel.fantasyName}
                        </td>
                      ) : null}
                      {state.baseFilters !== 'cnpj' ? (
                        <td id="cnpj-cell">
                          <b className="visible-xs"> Cnpj </b>

                          {whiteLabel.cnpj}
                        </td>
                      ) : null}
                      {state.baseFilters !== 'zipCode' ? (
                        <td id="zipCode-cell">
                          <b className="visible-xs"> Zip Code </b>

                          {whiteLabel.zipCode}
                        </td>
                      ) : null}

                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          <Button onClick={() => this.openUpdateModal(whiteLabel)} color="primary" size="sm">
                            <FontAwesomeIcon icon="pencil-alt" />
                          </Button>
                          <Button onClick={() => this.deleteEntity(whiteLabel)} color="danger" size="sm">
                            <FontAwesomeIcon icon="trash" />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.whiteLabel.home.notFound">No Companies found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const { whiteLabelList, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={whiteLabelList && whiteLabelList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel className="whiteLabel whiteLabel-all-in-one">
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
            {this.renderNewModal()}
            {this.renderUpdateModal()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToProps = ({ whiteLabel, ...storeState }: IRootState) => ({
  whiteLabelList: whiteLabel.entities,
  totalItems: whiteLabel.totalItems,
  loading: whiteLabel.loading,

  // UPDATE
  whiteLabelEntity: whiteLabel.entity,
});

export const mapDispatchToProps = {
  getEntities,

  updateEntity,
  reset,

  // UPDATE
  getEntity,
  setBlob,
  createEntity,

  // DELETE
  deleteEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(WhiteLabel);
