import React from 'react';
// import { AvGroup } from 'availity-reactstrap-validation';
import { Row, Col, Label } from 'reactstrap';
import { Translate, translate } from 'app/config/translate-component';
import SelectAsync from 'react-select/async';
import { getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';

export interface IClientGlosaProps {
  baseState?: any;
  baseProps?: any;
  setState?: any;
  entityBase?: any;
}

const ClientGlosaComponentCustomize = (props: IClientGlosaProps) => {
  const isNew = props.entityBase.id && true;
  return (
    <Row>
      <Col md="12">
        <Label className="mt-2 label-single-line" for="client-glosa-quote">
          <Translate contentKey="generadorApp.quote.detail.title">Quote</Translate>
        </Label>
      </Col>
      <Col md="12">
        <SelectAsync
          isDisabled={isNew}
          id="client-glosa-quote"
          name={'quote'}
          className={'css-select-control'}
          data-type-rel="many-to-one-other-side"
          value={props.baseState.quoteSelectValue ? props.baseState.quoteSelectValue : {}}
          onChange={options => props.setState({ quoteSelectValue: options })}
          defaultOptions={props.baseState.quoteStartSelectOptions ? props.baseState.quoteStartSelectOptions : []}
          loadingMessage={input => translate('selectAsync.loadingMessage')}
          noOptionsMessage={input =>
            props.baseState.quoteStartSelectOptions === undefined
              ? translate('selectAsync.loadingMessage')
              : translate('selectAsync.noOptionsMessage')
          }
          onMenuOpen={async () => {
            if (props.baseState.quoteStartSelectOptions === undefined) {
              const result = await getListAxios(
                'quotes',
                {
                  'status.in': 'APROVADO,INATIVOREPROVADOPRECO,INATIVOCONTROLEINTERNO',
                  'accountNumber.greaterThan': 0,
                },
                0,
                100,
                'accountNumber,asc',
                'accountNumber'
              );
              props.setState({
                quoteStartSelectOptions: result.data
                  ? result.data.map(p => ({
                      ...p,
                      value: p.id,
                      label: showFieldsSelectAsync(p, 'type') || '',
                    }))
                  : [],
              });
            }
          }}
          loadOptions={async (inputValue, callback) => {
            const result = await getListAxios(
              'quotes',
              {
                'status.in': 'APROVADO,INATIVOREPROVADOPRECO,INATIVOCONTROLEINTERNO',
                'accountNumber.greaterThan': 0,
                'accountNumber.contains': inputValue,
              },
              0,
              100,
              'accountNumber,asc',
              'accountNumber'
            );
            callback(
              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'accountNumber') || '' })) : []
            );
          }}
        />
      </Col>
    </Row>
  );
};

const mapStateToProps = storeState => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default ClientGlosaComponentCustomize;
