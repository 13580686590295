import React, { useEffect } from 'react';
import Chart from 'react-apexcharts';
import { connect } from 'react-redux';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import FiltersModal from './modal/filters-modal';

export interface IAtendimentosCategoriaComplexidadeProps extends StateProps, DispatchProps {
  getReport: Function;
}

const AtendimentosCategoriaComplexidade = (props: IAtendimentosCategoriaComplexidadeProps) => {
  useEffect(() => {
    props.getReport('atendimentos-categoria-complexidades');
  }, []);

  const data = [];
  let total = 0;
  const series = [];
  let result = { 'Sem Categorias': 0 };
  if (props.report.length > 0) {
    result = props.report.reduce(function (r, a: any) {
      r[a.name] = r[a.name] || [];
      r[a.name].push(a);
      return r;
    }, Object.create(null));

    Object.values(result).map((values: any, index) => {
      const complexidades = {
        BAIXA: 0,
        MEDIA: 0,
        ALTA: 0,
        VENTILACAOMECANICA: 0,
        TELEMONITORAMENTO: 0,
      };
      values.map((value: any, name) => {
        complexidades[value.patient_complexity] += parseInt(value.amount, 10);
        total += parseInt(value.amount, 10);
      });
      data.push(complexidades);
    });
  }

  const baixa = data.map(a => a.BAIXA);
  const media = data.map(a => a.MEDIA);
  const alta = data.map(a => a.ALTA);
  const ventMec = data.map(a => a.VENTILACAOMECANICA);
  const tele = data.map(a => a.TELEMONITORAMENTO);
  series.push({ name: 'Baixa', data: baixa });
  series.push({ name: 'Media', data: media });
  series.push({ name: 'Alta', data: alta });
  series.push({ name: 'Ventilação mecánica', data: ventMec });
  series.push({ name: 'Telemonitoramento', data: tele });

  const state = {
    options: {
      chart: {
        id: 'chart-atendimentos-categoria-complexidades',
        stacked: true,
      },
      xaxis: {
        categories: Object.keys(result),
      },
      title: {
        text: 'Total (' + total + ')',
        floating: true,
        align: 'center',
        style: {
          color: '#444',
        },
      },
    },
    series,
  };

  return (
    <Panel>
      <PanelHeader>
        <span>Número de atendimentos por categorías e complexidades</span>
        <FiltersModal />
      </PanelHeader>
      <PanelBody>
        <Chart options={state.options} series={state.series} type="bar" width="100%" height={250} />
      </PanelBody>
    </Panel>
  );
};

const mapStateToProps = storeState => ({
  report:
    storeState.jhiReports.reportsList && storeState.jhiReports.reportsList['atendimentos-categoria-complexidades']
      ? storeState.jhiReports.reportsList['atendimentos-categoria-complexidades']
      : [],
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps)(AtendimentosCategoriaComplexidade);
