/* eslint complexity: ["error", 300] */
import React from 'react';
import { connect } from 'react-redux';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { Button, Col, Row, Table, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import SelectAsync from 'react-select/async';
import { Translate, translate } from 'app/config/translate-component';
import { PatientCid, mapStateToProps, mapDispatchToProps } from '../patient-cid';
import { IRootState } from 'app/shared/reducers';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextFormat } from 'react-jhipster';
import { createEntity as createCidStatus } from 'app/entities/cid-status-history/cid-status-history.reducer';
import { getEntity as getOnePatientCid, updateEntity as updatePatientCid } from '../patient-cid.reducer';

import { APP_DATE_FORMAT } from 'app/config/constants';
import Select from 'react-select';
import { Status } from 'app/shared/model/enumerations/status.model';
import { IPatientCid } from 'app/shared/model/patient-cid.model';
import { getListAxios } from 'app/shared/util/entity-utils';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export interface IExtendedState {
  showModalCidStatus?: boolean;
  reasonCidStatusSelectValue?: any;
  statusCidStatusSelectValue?: any;
  patientCidEntity?: IPatientCid;
}
export interface IExtendedProps {
  entityEmbebedPai?: any;
}

export class PatientCidExtended extends PatientCid {
  renderHeader() {
    return <> {super.renderHeader()} </>;
  }

  renderFilter() {
    return <> {super.renderFilter()} </>;
  }

  renderTable() {
    const { patients, cids, patientCidList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {patientCidList && patientCidList.length > 0 ? (
          <Table
            id="patient-cid-table-list"
            responsive
            aria-describedby="patient-cid-heading"
            className={'table-hover table-striped table-responsive-css'}
          >
            <thead className={'thead-light'}>
              <tr>
                <th className="hand" onClick={sortFunction('id')}>
                  <Translate contentKey="generadorApp.patientCid.field_id">ID</Translate>
                </th>
                {state.baseFilters !== 'cid' ? (
                  <th>
                    <Translate contentKey="generadorApp.cid.code">Cid Code</Translate>
                  </th>
                ) : null}
                {state.baseFilters !== 'cidName' ? (
                  <th>
                    <Translate contentKey="generadorApp.cid.name">Name</Translate>
                  </th>
                ) : null}
                {state.baseFilters !== 'cidComplexity' ? (
                  <th className="hand" onClick={sortFunction('cidComplexity')}>
                    <Translate contentKey="generadorApp.patientCid.cidComplexity">Complexity</Translate>
                    <FontAwesomeIcon icon={state.sort === 'cidComplexity' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                ) : null}
                {state.baseFilters !== 'cidPrimary' ? (
                  <th>
                    <Translate contentKey="generadorApp.patientCid.cidPrimary">Primary</Translate>
                  </th>
                ) : null}
                {state.baseFilters !== 'status' ? (
                  <th>
                    <Translate contentKey="generadorApp.patientCid.status">Status</Translate>
                  </th>
                ) : null}

                <th />
              </tr>
            </thead>
            <tbody>
              <tr></tr>
              {patientCidList
                .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                .map((patientCid, i) => (
                  <tr key={`entity-${i}`}>
                    <td>
                      <b className="visible-xs">
                        <Translate contentKey="generadorApp.patientCid.field_id">ID</Translate>
                      </b>
                      <Button tag={Link} to={`${match.url}/${patientCid.id}`} color="link" size="sm">
                        {patientCid.id}
                      </Button>
                    </td>

                    {state.baseFilters !== 'cid' ? (
                      <td id="cid-cell">
                        <b className="visible-xs"> Code </b>
                        {patientCid && patientCid.cid && patientCid.cid['code']
                          ? patientCid.cid['code']
                          : cids.filter(v => v.patientCid.map(b => b.id).includes(patientCid.id)).map((v, k) => v.code)}
                      </td>
                    ) : null}
                    {state.baseFilters !== 'cidComplexity' ? (
                      <td id="cidComplexity-cell">
                        <b className="visible-xs"> Code </b>
                        {patientCid && patientCid.cid && patientCid.cid['name']
                          ? patientCid.cid['name']
                          : cids.filter(v => v.patientCid.map(b => b.id).includes(patientCid.id)).map((v, k) => v.name)}
                      </td>
                    ) : null}
                    {state.baseFilters !== 'cidComplexity' ? (
                      <td id="cidComplexity-cell">
                        <b className="visible-xs"> Complexity </b>
                        {patientCid.cidComplexity ? (
                          <Translate contentKey={`generadorApp.CidComplexity.${patientCid.cidComplexity}`} />
                        ) : (
                          <> </>
                        )}
                      </td>
                    ) : null}
                    {state.baseFilters !== 'cidPrimary' ? (
                      <td id="cidPrimary-cell">
                        <b className="visible-xs"> Primary </b>

                        <div className="switcher switcher-success">
                          <input
                            type="checkbox"
                            name={'cidPrimary_' + patientCid.id}
                            id={'cidPrimary_' + patientCid.id}
                            checked={patientCid.cidPrimary}
                            onClick={() => props.updateEntity({ ...patientCid, cidPrimary: !patientCid.cidPrimary }, { reloadList: false })}
                          />
                          <label htmlFor={'cidPrimary_' + patientCid.id} />
                        </div>
                      </td>
                    ) : null}
                    <td>
                      {patientCid.status === Status.ATIVO ? (
                        <div style={{ padding: 0, margin: 0, textAlign: 'center' }} className="alert alert-success">
                          <Translate contentKey={'generadorApp.Status.' + Status.ATIVO} />
                        </div>
                      ) : (
                        <div style={{ padding: 0, margin: 0, textAlign: 'center' }} className="alert alert-danger">
                          <Translate contentKey={'generadorApp.Status.' + Status.INATIVADO} />
                        </div>
                      )}
                    </td>
                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">
                        {hasAnyAuthority(props.userAccount, ['patient-cid'], 'edit') ? (
                          <Button
                            onClick={() => {
                              this.setState({
                                patientCidEntity: patientCid,
                                showModalCidStatus: true,
                                reasonCidStatusSelectValue: '',
                                statusCidStatusSelectValue: '',
                              });
                            }}
                            color="info"
                            size="sm"
                          >
                            <FontAwesomeIcon icon="check-square" />
                          </Button>
                        ) : (
                          <></>
                        )}{' '}
                        {hasAnyAuthority(props.userAccount, ['patient-cid'], 'edit') ? (
                          <Button onClick={() => this.openUpdateModal(patientCid)} color="primary" size="sm">
                            <FontAwesomeIcon icon="pencil-alt" />
                          </Button>
                        ) : (
                          <></>
                        )}{' '}
                        {hasAnyAuthority(props.userAccount, ['patient-cid'], 'canDelete') ? (
                          <Button onClick={() => this.deleteEntity(patientCid)} color="danger" size="sm">
                            <FontAwesomeIcon icon="trash" />
                          </Button>
                        ) : (
                          <></>
                        )}{' '}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.patientCid.home.notFound">No Patient Cids found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderNewModal() {
    return (
      <div>
        <Modal size={'lg'} isOpen={this.state.showModalForm} toggle={() => this.setState({ showModalForm: false })}>
          <ModalHeader toggle={() => this.setState({ showModalForm: false })}>{this.renderHeaderUpdate({}, true)}</ModalHeader>
          <AvForm
            ref={el => (this.myFormRefNew = el)}
            model={{}}
            onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, true)}
          >
            <ModalBody>{this.renderBodyUpdate({}, true)}</ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => this.setState({ showModalForm: false })}>
                <FontAwesomeIcon icon="ban" />
                &nbsp;
                <Translate contentKey="generadorApp.patientCid.btnCancel">Cancel</Translate>
              </Button>
              <Button
                color="primary"
                id="save-entity"
                onClick={() => this.myFormRefNew && this.myFormRefNew.submit()}
                className="float-right jh-create-entity"
              >
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="generadorApp.patientCid.btnSave">Save</Translate>
              </Button>
            </ModalFooter>
          </AvForm>
        </Modal>
        {this.state.patientCidEntity ? (
          <Modal size={'lg'} isOpen={this.state.showModalCidStatus} toggle={() => this.setState({ showModalCidStatus: false })}>
            <ModalHeader toggle={() => this.setState({ showModalCidStatus: false })}>
              <Translate contentKey="generadorApp.cidStatusHistory.home.title">Status Histories</Translate>
            </ModalHeader>
            <ModalBody id="generadorApp.cidStatusHistory.question">
              {this.renderBodyProcedimentStatus()}
              <br />
              <br />
              {this.renderTableProcedimentStatus()}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => this.setState({ showModalCidStatus: false })}>
                <FontAwesomeIcon icon="ban" />
                &nbsp;
                <Translate contentKey="generadorApp.cidStatusHistory.btnCancel">Cancel</Translate>
              </Button>
            </ModalFooter>
          </Modal>
        ) : (
          <> </>
        )}
      </div>
    );
  }

  renderBodyProcedimentStatus() {
    const { loading } = this.props;

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                <Row>
                  <Col md="12">
                    <Row>
                      <Col md="12">
                        <Label className="mt-2 label-single-line" id="statusLabel" for="nr-status-history-status">
                          <Translate contentKey="generadorApp.cidStatusHistory.status">Status</Translate>(*)
                        </Label>
                      </Col>
                      <Col md="12">
                        <Select
                          id="nr-status-history-status"
                          className={'css-select-control'}
                          value={{
                            value: this.state.statusCidStatusSelectValue,
                            label: translate('generadorApp.Status.' + this.state.statusCidStatusSelectValue),
                          }}
                          options={[
                            { value: 'ATIVO', label: translate('generadorApp.Status.ATIVO') },
                            { value: 'INATIVADO', label: translate('generadorApp.Status.INATIVADO') },
                          ]}
                          onChange={(options: any) => this.setState({ statusCidStatusSelectValue: options['value'] })}
                          name="status"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md="12">
                    <Row>
                      <Col md="12">
                        <Label className="mt-2 label-single-line" id="observationLabel" for="nr-status-history-reason">
                          <Translate contentKey="generadorApp.cidStatusHistory.reason">reason</Translate>
                        </Label>
                      </Col>
                      <Col md="12">
                        <input
                          onChange={evt => this.setState({ reasonCidStatusSelectValue: evt.target.value })}
                          value={this.state.reasonCidStatusSelectValue}
                          id="nr-status-history-reason"
                          type="text"
                          name="reason"
                          className={'form-control'}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col md="12">
                    <br />
                    <Button
                      className="pull-right"
                      color="primary"
                      onClick={() => {
                        new Promise(resolve => {
                          resolve(
                            this.props.createCidStatus(
                              {
                                reason: this.state.reasonCidStatusSelectValue,
                                status: this.state.statusCidStatusSelectValue,
                                patientCid: { ...this.state.patientCidEntity },
                              },
                              { reloadList: false }
                            )
                          );
                        }).then(resultEntity => {
                          new Promise(resolve => {
                            resolve(
                              this.props.updateEntity(
                                {
                                  id: this.state.patientCidEntity.id,
                                  status: this.state.statusCidStatusSelectValue,
                                },
                                { reloadList: false }
                              )
                            );
                          }).then(resultEntity1 => {
                            this.getEntities();
                            this.setState({
                              reasonCidStatusSelectValue: '',
                              statusCidStatusSelectValue: '',
                              showModalCidStatus: false,
                            });
                          });
                        });
                      }}
                    >
                      <FontAwesomeIcon icon="save" />
                      &nbsp;
                      <Translate contentKey="generadorApp.cidStatusHistory.btnSave">btnSave</Translate>
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  renderTableProcedimentStatus() {
    const nrStatusHistoryList = this.state.patientCidEntity.cidStatusHistory;

    return (
      <>
        {nrStatusHistoryList && nrStatusHistoryList.length > 0 ? (
          <Table
            id="nr-status-history-table-list"
            responsive
            aria-describedby="nr-status-history-heading"
            className={'table-hover table-striped table-responsive-css'}
          >
            <thead className={'thead-light'}>
              <tr>
                <th>
                  <Translate contentKey="generadorApp.cidStatusHistory.status">Status</Translate>
                </th>
                <th>
                  <Translate contentKey="generadorApp.cidStatusHistory.reason">Reason</Translate>
                </th>
                <th>
                  <Translate contentKey="generadorApp.cidStatusHistory.createdDate">Created Date</Translate>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr></tr>
              {nrStatusHistoryList
                .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                .map((cidStatusHistory, i) => (
                  <tr key={`entity-${i}`}>
                    <td id="status-cell">
                      {cidStatusHistory.status ? <Translate contentKey={`generadorApp.Status.${cidStatusHistory.status}`} /> : <> </>}
                    </td>
                    <td id="observation-cell">{cidStatusHistory.reason}</td>

                    <td id="createdDate-cell">
                      <b className="visible-xs"> Created Date </b>
                      <TextFormat type="date" value={cidStatusHistory.createdDate} format={APP_DATE_FORMAT} />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.cidStatusHistory.home.notFound">No Status Histories found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  openUpdateModal(v, edit = true) {
    this.props.getEntity(v.id);
    this.setState({
      showModalEdit: v.id,
      showModalEditView: edit,
      ...this.getFullEntitySelectValue(v),
    });
  }

  renderUpdateModal() {
    const { patientCidEntity } = this.props;
    return patientCidEntity && patientCidEntity.id ? (
      <Modal size={'lg'} isOpen={this.state.showModalEdit === patientCidEntity.id} toggle={() => this.setState({ showModalEdit: null })}>
        <ModalHeader toggle={() => this.setState({ showModalEdit: null })}>{this.renderHeaderUpdate(patientCidEntity, false)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefEdit = el)}
          model={{
            ...patientCidEntity,
          }}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, false)}
        >
          <ModalBody>{this.renderBodyUpdate(patientCidEntity, false)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalEdit: null })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.patientCid.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefEdit && this.myFormRefEdit.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.patientCid.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    ) : (
      <> </>
    );
  }
  renderStatusHistoryModal() {
    const { patientCidEntity } = this.props;
    return patientCidEntity && patientCidEntity.id ? (
      <Modal size={'lg'} isOpen={this.state.showModalEdit === patientCidEntity.id} toggle={() => this.setState({ showModalEdit: null })}>
        <ModalHeader toggle={() => this.setState({ showModalEdit: null })}>{this.renderHeaderUpdate(patientCidEntity, false)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefEdit = el)}
          model={{
            ...patientCidEntity,
          }}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, false)}
        >
          <ModalBody>{this.renderBodyUpdate(patientCidEntity, false)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalEdit: null })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.patientCid.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefEdit && this.myFormRefEdit.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.patientCid.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    ) : (
      <> </>
    );
  }

  renderHeaderUpdate(patientCidEntity, isNew) {
    const { patients, cids, loading } = this.props;

    const { cidComments } = patientCidEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {isNew ? (
              <Translate contentKey="generadorApp.patientCid.home.createLabel">Create a PatientCid</Translate>
            ) : (
              <Translate contentKey="generadorApp.patientCid.home.editLabel">Edit a PatientCid</Translate>
            )}
          </span>
        </div>
      </>
    );
  }
  renderBodyUpdate(patientCidEntity, isNew) {
    return super.renderBodyUpdate(patientCidEntity, isNew);
    const { patients, cids, loading } = this.props;

    const { cidComments } = patientCidEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      <Col md="12">
                        <AvInput id="patient-cid-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'cid' ? (
                    <Col md="5">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="patient-cid-cid">
                              <Translate contentKey="generadorApp.patientCid.cid">Cid</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="patient-cid-cid"
                              name={'cid'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.cidSelectValue}
                              onChange={options => this.setState({ cidSelectValue: options })}
                              defaultOptions={this.state.cidStartSelectOptions ? this.state.cidStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.cidStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.cidStartSelectOptions === undefined) {
                                  const result = await getListAxios('cids', {}, 0, 100, 'code,asc', 'code');
                                  this.setState({
                                    cidStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: p['code'] ? p.code : '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios('cids', { 'code.contains': inputValue }, 0, 100, 'code,asc', 'code');
                                callback(result.data ? result.data.map(p => ({ ...p, value: p.id, label: p['code'] ? p.code : '' })) : []);
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput type="hidden" name="cid" value={this.state.fieldsBase[baseFilters + 'Id']} />
                  )}
                  {baseFilters !== 'cidComplexity' ? (
                    <Col md="5">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="cidComplexityLabel" for="patient-cid-cidComplexity">
                              <Translate contentKey="generadorApp.patientCid.cidComplexity">Complexity</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="patient-cid-cidComplexity"
                              className={'css-select-control'}
                              value={{
                                value: this.state.cidComplexitySelectValue,
                                label: translate('generadorApp.CidComplexity.' + this.state.cidComplexitySelectValue),
                              }}
                              options={[
                                { value: 'BAIXA', label: translate('generadorApp.CidComplexity.BAIXA') },
                                { value: 'MEDIA', label: translate('generadorApp.CidComplexity.MEDIA') },
                                { value: 'ALTA', label: translate('generadorApp.CidComplexity.ALTA') },
                              ]}
                              onChange={(options: any) => this.setState({ cidComplexitySelectValue: options['value'] })}
                              name="cidComplexity"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ cidComplexitySelectValue: evt.target.value })}
                      type="hidden"
                      name="cidComplexity"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'cidPrimary' ? (
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="cidPrimaryLabel" for="patient-cid-cidPrimary">
                              <Translate contentKey="generadorApp.patientCid.cidPrimary">Primary</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'cidPrimary_checkbox'}
                                id={'cidPrimary_checkbox'}
                                checked={this.state.cidPrimarySelectValue}
                                onChange={evt => this.setState({ cidPrimarySelectValue: !this.state.cidPrimarySelectValue })}
                              />
                              <label htmlFor={'cidPrimary_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ cidPrimarySelectValue: evt.target.value })}
                      type="hidden"
                      name="cidPrimary"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'cidComments' ? (
                    <Col md="12">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="cidCommentsLabel" for="patient-cid-cidComments">
                              <Translate contentKey="generadorApp.patientCid.cidComments">Comments</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <AvInput
                              onChange={evt => this.setState({ cidCommentsSelectValue: evt.target.value })}
                              validate={{}}
                              id="patient-cid-cidComments"
                              type="textarea"
                              name="cidComments"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ cidCommentsSelectValue: evt.target.value })}
                      type="hidden"
                      name="cidComments"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'patient' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="patient-cid-patient">
                              <Translate contentKey="generadorApp.patientCid.patient">Patient</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="patient-cid-patient"
                              name={'patient'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.patientSelectValue}
                              onChange={options => this.setState({ patientSelectValue: options })}
                              defaultOptions={this.state.patientStartSelectOptions ? this.state.patientStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.patientStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.patientStartSelectOptions === undefined) {
                                  const result = await getListAxios('patients', {}, 0, 100, 'name,asc', 'name');
                                  this.setState({
                                    patientStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: p['name'] ? p.name : '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios('patients', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                                callback(result.data ? result.data.map(p => ({ ...p, value: p.id, label: p['name'] ? p.name : '' })) : []);
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput type="hidden" name="patient" value={this.state.fieldsBase[baseFilters + 'Id']} />
                  )}{' '}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  renderFooter() {
    return <> {super.renderFooter()} </>;
  }

  render() {
    const { patientCidList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel>
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}
            {this.renderTable()}
            {this.renderNewModal()}

            {this.renderUpdateModal()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
  getOnePatientCid,
  createCidStatus,
  updatePatientCid,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;

export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(PatientCidExtended);
