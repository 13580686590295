/* eslint-disable no-console */
/* eslint complexity: ["error", 300] */
import React from 'react';
import { connect } from 'react-redux';
import { MedicineMaterialUpdate, mapStateToProps, mapDispatchToProps, IMedicineMaterialUpdateProps } from '../medicine-material-update';
import { IRootState } from 'app/shared/reducers';

import Select from 'react-select';
import SelectAsync from 'react-select/async';
import CurrencyInput from 'react-currency-input';

import ReactQuill from 'react-quill';
import { quillEditorModules, quillEditorFormats, getListAxios } from 'app/shared/util/entity-utils';
import 'react-quill/dist/quill.snow.css';

import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Col, Label } from 'reactstrap';
import { Translate, translate } from 'app/config/translate-component';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MedicineMaterialType } from 'app/shared/model/enumerations/medicine-material-type.model';
import { RenderModalSuperSelect } from 'app/shared/layout/components/RenderModalSuperSelect';
import { getEntityFiltersURL } from '../medicine-material.reducer';

export interface IUpdateExtendedState {
  pmcFracAmountSelectValue?: any;
  pfFracAmountSelectValue?: any;
  pmcAmountSelectValue?: any;
  pfAmountSelectValue?: any;
  unitaryAmountSelectValue?: any;
  saleValueSelectValue?: any;
  tussCodeSelectValue?: any;
  tissCodeSelectValue?: any;
  tableCodeSelectValue?: any;
  expenseCodeSelectValue?: any;
  SelectValuetable?: any;
  urlLevelGroupId?: any;
  urlLevelGroupName?: any;
  priceFrac?: any;
  priceSel?: any;
}

export class MedicineMaterialExtendedUpdate extends MedicineMaterialUpdate {
  constructor(props: Readonly<IMedicineMaterialUpdateProps>) {
    super(props);

    const url = new URL(`http://localhost${this.props.location.search}`);
    this.state = {
      ...this.state,

      urlLevelGroupId: url.searchParams.get('urlLevelGroupId') || '',
      urlLevelGroupName: url.searchParams.get('levelGroupName') || '',
    };
  }
  renderHeader() {
    const { medicineMaterialEntity, updating } = this.props;
    const { isNew } = this.state;

    const { comments } = medicineMaterialEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <>Criar {this.state.urlLevelGroupName}</>
            ) : (
              <>Editar {medicineMaterialEntity && medicineMaterialEntity.levelGroup ? medicineMaterialEntity.levelGroup.name : ''}</>
            )}
          </span>

          <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.medicineMaterial.btnSave">Save</Translate>
          </Button>
          <Button
            tag={Link}
            id="cancel-save"
            to={'/medicine-material?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.medicineMaterial.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={'/medicine-material'}>
              <Translate contentKey="generadorApp.medicineMaterial.home.title">Medicine/Material/Diet</Translate>
            </Link>
          </li>
          {this.state.urlLevelGroupName ? <li className="breadcrumb-item active">{this.state.urlLevelGroupName}</li> : <> </>}
          {this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.name ? (
            <li className="breadcrumb-item active">{this.props.medicineMaterialEntity.name}</li>
          ) : (
            <> </>
          )}

          {this.state.isNew ? (
            <li className="breadcrumb-item active"> Criar {this.state.urlLevelGroupName}</li>
          ) : (
            <li className="breadcrumb-item active">
              {' '}
              Editar {medicineMaterialEntity && medicineMaterialEntity.levelGroup ? medicineMaterialEntity.levelGroup.name : ''}
            </li>
          )}
        </ol>
      </>
    );
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.updateSuccess !== this.props.updateSuccess && prevProps.updateSuccess) {
  //     this.handleClose();
  //   }

  //   // data-type-rel -> tipo-2 one-to-many other-side
  //   if (
  //     (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
  //     (this.props.medicineMaterialStatuses.length > 0 &&
  //       this.state.medicineMaterialStatusSelectValue === null &&
  //       this.props.medicineMaterialEntity.medicineMaterialStatus)
  //   ) {
  //     this.setState({
  //       medicineMaterialStatusSelectValue: this.props.medicineMaterialStatuses
  //         ? this.props.medicineMaterialStatuses
  //           .map(f =>
  //             this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.medicineMaterialStatus
  //               ? this.props.medicineMaterialEntity.medicineMaterialStatus.map(p => (p !== null ? p.id : '')).includes(f.id)
  //                 ? f.id
  //                 : null
  //               : []
  //           )
  //           .filter(v => v && true)
  //           .join(',')
  //         : null,
  //     });
  //   }

  //   // data-type-rel -> tipo-2 one-to-many other-side
  //   if (
  //     (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
  //     (this.props.kitDiets.length > 0 && this.state.kitDietSelectValue === null && this.props.medicineMaterialEntity.kitDiet)
  //   ) {
  //     this.setState({
  //       kitDietSelectValue: this.props.kitDiets
  //         ? this.props.kitDiets
  //           .map(f =>
  //             this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.kitDiet
  //               ? this.props.medicineMaterialEntity.kitDiet.map(p => (p !== null ? p.id : '')).includes(f.id)
  //                 ? f.id
  //                 : null
  //               : []
  //           )
  //           .filter(v => v && true)
  //           .join(',')
  //         : null,
  //     });
  //   }

  //   // data-type-rel -> tipo-2 one-to-many other-side
  //   if (
  //     (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
  //     (this.props.kitMaterials.length > 0 && this.state.kitMaterialSelectValue === null && this.props.medicineMaterialEntity.kitMaterial)
  //   ) {
  //     this.setState({
  //       kitMaterialSelectValue: this.props.kitMaterials
  //         ? this.props.kitMaterials
  //           .map(f =>
  //             this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.kitMaterial
  //               ? this.props.medicineMaterialEntity.kitMaterial.map(p => (p !== null ? p.id : '')).includes(f.id)
  //                 ? f.id
  //                 : null
  //               : []
  //           )
  //           .filter(v => v && true)
  //           .join(',')
  //         : null,
  //     });
  //   }

  //   // data-type-rel -> tipo-2 one-to-many other-side
  //   if (
  //     (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
  //     (this.props.kitMedicines.length > 0 && this.state.kitMedicineSelectValue === null && this.props.medicineMaterialEntity.kitMedicine)
  //   ) {
  //     this.setState({
  //       kitMedicineSelectValue: this.props.kitMedicines
  //         ? this.props.kitMedicines
  //           .map(f =>
  //             this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.kitMedicine
  //               ? this.props.medicineMaterialEntity.kitMedicine.map(p => (p !== null ? p.id : '')).includes(f.id)
  //                 ? f.id
  //                 : null
  //               : []
  //           )
  //           .filter(v => v && true)
  //           .join(',')
  //         : null,
  //     });
  //   }

  //   // data-type-rel -> tipo-2 one-to-many other-side
  //   if (
  //     (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
  //     (this.props.quoteDiets.length > 0 && this.state.quoteDietSelectValue === null && this.props.medicineMaterialEntity.quoteDiet)
  //   ) {
  //     this.setState({
  //       quoteDietSelectValue: this.props.quoteDiets
  //         ? this.props.quoteDiets
  //           .map(f =>
  //             this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.quoteDiet
  //               ? this.props.medicineMaterialEntity.quoteDiet.map(p => (p !== null ? p.id : '')).includes(f.id)
  //                 ? f.id
  //                 : null
  //               : []
  //           )
  //           .filter(v => v && true)
  //           .join(',')
  //         : null,
  //     });
  //   }

  //   // data-type-rel -> tipo-2 one-to-many other-side
  //   if (
  //     (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
  //     (this.props.quoteMedicines.length > 0 &&
  //       this.state.quoteMedicineSelectValue === null &&
  //       this.props.medicineMaterialEntity.quoteMedicine)
  //   ) {
  //     this.setState({
  //       quoteMedicineSelectValue: this.props.quoteMedicines
  //         ? this.props.quoteMedicines
  //           .map(f =>
  //             this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.quoteMedicine
  //               ? this.props.medicineMaterialEntity.quoteMedicine.map(p => (p !== null ? p.id : '')).includes(f.id)
  //                 ? f.id
  //                 : null
  //               : []
  //           )
  //           .filter(v => v && true)
  //           .join(',')
  //         : null,
  //     });
  //   }

  //   // data-type-rel -> tipo-2 one-to-many other-side
  //   if (
  //     (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
  //     (this.props.quoteMaterials.length > 0 &&
  //       this.state.quoteMaterialSelectValue === null &&
  //       this.props.medicineMaterialEntity.quoteMaterial)
  //   ) {
  //     this.setState({
  //       quoteMaterialSelectValue: this.props.quoteMaterials
  //         ? this.props.quoteMaterials
  //           .map(f =>
  //             this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.quoteMaterial
  //               ? this.props.medicineMaterialEntity.quoteMaterial.map(p => (p !== null ? p.id : '')).includes(f.id)
  //                 ? f.id
  //                 : null
  //               : []
  //           )
  //           .filter(v => v && true)
  //           .join(',')
  //         : null,
  //     });
  //   }

  //   // data-type-rel -> tipo-2 one-to-many other-side
  //   if (
  //     (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
  //     (this.props.clComItems.length > 0 && this.state.clComItemSelectValue === null && this.props.medicineMaterialEntity.clComItem)
  //   ) {
  //     this.setState({
  //       clComItemSelectValue: this.props.clComItems
  //         ? this.props.clComItems
  //           .map(f =>
  //             this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.clComItem
  //               ? this.props.medicineMaterialEntity.clComItem.map(p => (p !== null ? p.id : '')).includes(f.id)
  //                 ? f.id
  //                 : null
  //               : []
  //           )
  //           .filter(v => v && true)
  //           .join(',')
  //         : null,
  //     });
  //   }

  //   // data-type-rel -> tipo-2 one-to-many other-side
  //   if (
  //     (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
  //     (this.props.supplierTableDiets.length > 0 &&
  //       this.state.supplierTableDietSelectValue === null &&
  //       this.props.medicineMaterialEntity.supplierTableDiet)
  //   ) {
  //     this.setState({
  //       supplierTableDietSelectValue: this.props.supplierTableDiets
  //         ? this.props.supplierTableDiets
  //           .map(f =>
  //             this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.supplierTableDiet
  //               ? this.props.medicineMaterialEntity.supplierTableDiet.map(p => (p !== null ? p.id : '')).includes(f.id)
  //                 ? f.id
  //                 : null
  //               : []
  //           )
  //           .filter(v => v && true)
  //           .join(',')
  //         : null,
  //     });
  //   }

  //   // data-type-rel -> tipo-2 one-to-many other-side
  //   if (
  //     (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
  //     (this.props.supplierTableMaterials.length > 0 &&
  //       this.state.supplierTableMaterialSelectValue === null &&
  //       this.props.medicineMaterialEntity.supplierTableMaterial)
  //   ) {
  //     this.setState({
  //       supplierTableMaterialSelectValue: this.props.supplierTableMaterials
  //         ? this.props.supplierTableMaterials
  //           .map(f =>
  //             this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.supplierTableMaterial
  //               ? this.props.medicineMaterialEntity.supplierTableMaterial.map(p => (p !== null ? p.id : '')).includes(f.id)
  //                 ? f.id
  //                 : null
  //               : []
  //           )
  //           .filter(v => v && true)
  //           .join(',')
  //         : null,
  //     });
  //   }

  //   // data-type-rel -> tipo-2 one-to-many other-side
  //   if (
  //     (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
  //     (this.props.supplierTableMedicines.length > 0 &&
  //       this.state.supplierTableMedicineSelectValue === null &&
  //       this.props.medicineMaterialEntity.supplierTableMedicine)
  //   ) {
  //     this.setState({
  //       supplierTableMedicineSelectValue: this.props.supplierTableMedicines
  //         ? this.props.supplierTableMedicines
  //           .map(f =>
  //             this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.supplierTableMedicine
  //               ? this.props.medicineMaterialEntity.supplierTableMedicine.map(p => (p !== null ? p.id : '')).includes(f.id)
  //                 ? f.id
  //                 : null
  //               : []
  //           )
  //           .filter(v => v && true)
  //           .join(',')
  //         : null,
  //     });
  //   }

  //   // data-type-rel -> tipo-2 one-to-many other-side
  //   if (
  //     (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
  //     (this.props.pharmacyStocks.length > 0 &&
  //       this.state.pharmacyStockSelectValue === null &&
  //       this.props.medicineMaterialEntity.pharmacyStock)
  //   ) {
  //     this.setState({
  //       pharmacyStockSelectValue: this.props.pharmacyStocks
  //         ? this.props.pharmacyStocks
  //           .map(f =>
  //             this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.pharmacyStock
  //               ? this.props.medicineMaterialEntity.pharmacyStock.map(p => (p !== null ? p.id : '')).includes(f.id)
  //                 ? f.id
  //                 : null
  //               : []
  //           )
  //           .filter(v => v && true)
  //           .join(',')
  //         : null,
  //     });
  //   }
  //   // data-type-rel -> tipo-2 one-to-many other-side
  //   if (
  //     (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
  //     (this.props.patDrPrescrProcds.length > 0 &&
  //       this.state.patDrPrescrProcdSelectValue === null &&
  //       this.props.medicineMaterialEntity.patDrPrescrProcd)
  //   ) {
  //     this.setState({
  //       patDrPrescrProcdSelectValue: this.props.patDrPrescrProcds
  //         ? this.props.patDrPrescrProcds
  //           .map(f =>
  //             this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.patDrPrescrProcd
  //               ? this.props.medicineMaterialEntity.patDrPrescrProcd.map(p => (p !== null ? p.id : '')).includes(f.id)
  //                 ? f.id
  //                 : null
  //               : []
  //           )
  //           .filter(v => v && true)
  //           .join(',')
  //         : null,
  //     });
  //   }

  //   // data-type-rel -> tipo-1 many-to-many owner-side
  //   if (
  //     (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
  //     (this.props.categories.length > 0 && this.state.categorySelectValue === null && this.props.medicineMaterialEntity.categories)
  //   ) {
  //     this.setState({
  //       categorySelectValue:
  //         this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.categories
  //           ? this.props.medicineMaterialEntity.categories
  //             .filter(p => p)
  //             .map(p => ({
  //               ...p,
  //               value: p.id,
  //               label: (p['levelGroup'] && p['levelGroup']['name'] ? p.levelGroup.name : '') + ' | ' + (p['name'] ? p.name : ''),
  //             }))
  //           : [],
  //     });
  //   }

  //   // data-type-rel -> tipo-4 many-to-one other-side
  //   if (
  //     (this.state.tableSelectValue === 'SIMPRO' &&
  //       prevProps.medicineMaterialEntity &&
  //       prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
  //     (this.props.simpros.length > 0 &&
  //       this.state.simproSelectValue === null &&
  //       this.props.medicineMaterialEntity.simpro &&
  //       this.props.medicineMaterialEntity.simpro.id)
  //   ) {
  //     const options: any = this.props.medicineMaterialEntity.simpro;
  //     if (options) {
  //       this.setState({
  //         saleValueSelectValue: options['price'],
  //         tussCodeSelectValue: options['cdTuss'],
  //         tissCodeSelectValue: options['cdSimpro'],
  //         tableCodeSelectValue: options['tableCode'],
  //         expenseCodeSelectValue: options['expenseCode'],
  //         pmcAmountSelectValue: options['pcEmVen'],
  //         pfAmountSelectValue: options['pcEmFab'],
  //         pmcFracAmountSelectValue: options['pcFrVen'],
  //         pfFracAmountSelectValue: options['pcFrFab'],
  //         laboratorySelectValue: options['fabrica'],
  //         unitaryAmountSelectValue: this.state.fractionedSelectValue ? options['pcEmUsu'] : options['price'],
  //         priceFrac: options['pcEmUsu'],
  //         priceSel: options['price'],
  //         simproSelectValue: {
  //           ...options,
  //           value: options['id'],
  //           label: options['descricao'],
  //         },
  //       });
  //     }
  //   }

  //   // data-type-rel -> tipo-4 many-to-one other-side
  //   if (
  //     (this.state.tableSelectValue === 'BRASINDICE' &&
  //       prevProps.medicineMaterialEntity &&
  //       prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
  //     (this.props.brasindices.length > 0 &&
  //       this.state.brasindiceSelectValue === null &&
  //       this.props.medicineMaterialEntity.brasindice &&
  //       this.props.medicineMaterialEntity.brasindice.id)
  //   ) {
  //     const options: any = this.props.medicineMaterialEntity.brasindice;
  //     if (options) {
  //       this.setState({
  //         saleValueSelectValue: options['price'],
  //         tussCodeSelectValue: options['codeTuss'],
  //         tissCodeSelectValue: options['codeTiss'],
  //         tableCodeSelectValue: options['tableCode'],
  //         expenseCodeSelectValue: options['expenseCode'],
  //         pmcAmountSelectValue: options['pcEmVen'],
  //         pfAmountSelectValue: options['pcEmFab'],
  //         pmcFracAmountSelectValue: options['pcFrVen'],
  //         pfFracAmountSelectValue: options['pcFrFab'],
  //         laboratorySelectValue: options['codeLab'],
  //         unitaryAmountSelectValue: this.state.fractionedSelectValue ? options['valueFrac'] : options['price'],
  //         priceFrac: options['valueFrac'],
  //         priceSel: options['price'],
  //         simproSelectValue: {
  //           ...options,
  //           value: options['id'],
  //           label: options['name'],
  //         },
  //       });
  //     }
  //   }

  //   // data-type-rel -> tipo-4 many-to-one other-side
  //   if (
  //     (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
  //     (this.props.brands &&
  //       this.state.brandSelectValue === null &&
  //       this.props.medicineMaterialEntity.brand &&
  //       this.props.medicineMaterialEntity.brand.id)
  //   ) {
  //     const p =
  //       this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.brand ? this.props.medicineMaterialEntity.brand : null;
  //     this.setState({
  //       brandSelectValue: p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : null,
  //     });
  //   }

  //   // data-type-rel -> tipo-4 many-to-one other-side
  //   if (
  //     (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
  //     (this.props.presentations &&
  //       this.state.presentationSelectValue === null &&
  //       this.props.medicineMaterialEntity.presentation &&
  //       this.props.medicineMaterialEntity.presentation.id)
  //   ) {
  //     const p =
  //       this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.presentation
  //         ? this.props.medicineMaterialEntity.presentation
  //         : null;
  //     this.setState({
  //       presentationSelectValue: p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : null,
  //     });
  //   }

  //   // data-type-rel -> tipo-4 many-to-one other-side
  //   if (
  //     (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
  //     (this.props.doses &&
  //       this.state.doseSelectValue === null &&
  //       this.props.medicineMaterialEntity.dose &&
  //       this.props.medicineMaterialEntity.dose.id)
  //   ) {
  //     this.setState({
  //       doseSelectValue:
  //         this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.dose ? this.props.medicineMaterialEntity.dose.id : null,
  //     });
  //   }

  //   // data-type-rel -> tipo-4 many-to-one other-side
  //   if (
  //     (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
  //     (this.props.levelGroups &&
  //       this.state.levelGroupSelectValue === null &&
  //       this.props.medicineMaterialEntity.levelGroup &&
  //       this.props.medicineMaterialEntity.levelGroup.id)
  //   ) {
  //     this.setState({
  //       levelGroupSelectValue:
  //         this.props.medicineMaterialEntity && this.props.medicineMaterialEntity.levelGroup
  //           ? this.props.medicineMaterialEntity.levelGroup.id
  //           : null,
  //     });
  //   }

  //   if (
  //     (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
  //     (this.props.medicineMaterialEntity.table && this.state.tableSelectValue === null)
  //   ) {
  //     if (this.props.medicineMaterialEntity.table === 'SIMPRO' && this.props.medicineMaterialEntity.simpro) {
  //       const options: any = this.props.medicineMaterialEntity.simpro;
  //       if (options) {
  //         this.setState({
  //           tableSelectValue: this.props.medicineMaterialEntity.table,
  //           saleValueSelectValue: options['price'],
  //           tussCodeSelectValue: options['cdTuss'],
  //           tissCodeSelectValue: options['cdSimpro'],
  //           tableCodeSelectValue: options['tableCode'],
  //           expenseCodeSelectValue: options['expenseCode'],
  //           pmcAmountSelectValue: options['pcEmVen'],
  //           pfAmountSelectValue: options['pcEmFab'],
  //           pmcFracAmountSelectValue: options['pcFrVen'],
  //           pfFracAmountSelectValue: options['pcFrFab'],
  //           laboratorySelectValue: options['fabrica'],
  //           unitaryAmountSelectValue: this.state.fractionedSelectValue ? options['pcEmUsu'] : options['price'],
  //           priceFrac: options['pcEmUsu'],
  //           priceSel: options['price'],
  //           simproSelectValue: {
  //             ...options,
  //             value: options['id'],
  //             label: options['descricao'],
  //           },
  //         });
  //       }
  //     } else if (this.props.medicineMaterialEntity.table === 'BRASINDICE' && this.props.medicineMaterialEntity.brasindice) {
  //       const options: any = this.props.medicineMaterialEntity.brasindice;
  //       if (options) {
  //         this.setState({
  //           tableSelectValue: this.props.medicineMaterialEntity.table,
  //           saleValueSelectValue: options['price'],
  //           tussCodeSelectValue: options['codeTuss'],
  //           tissCodeSelectValue: options['codeTiss'],
  //           tableCodeSelectValue: options['tableCode'],
  //           expenseCodeSelectValue: options['expenseCode'],
  //           pmcAmountSelectValue: options['pcEmVen'],
  //           pfAmountSelectValue: options['pcEmFab'],
  //           pmcFracAmountSelectValue: options['pcFrVen'],
  //           pfFracAmountSelectValue: options['pcFrFab'],
  //           laboratorySelectValue: options['codeLab'],
  //           unitaryAmountSelectValue: this.state.fractionedSelectValue ? options['valueFrac'] : options['price'],
  //           priceFrac: options['valueFrac'],
  //           priceSel: options['price'],
  //           brasindiceSelectValue: {
  //             ...options,
  //             value: options['id'],
  //             label: options['name'],
  //           },
  //         });
  //       }
  //     } else {
  //       this.setState({ tableSelectValue: this.props.medicineMaterialEntity.table });
  //     }
  //   }
  //   if (
  //     (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
  //     (this.props.medicineMaterialEntity.name && this.state.nameSelectValue === null)
  //   ) {
  //     this.setState({ nameSelectValue: this.props.medicineMaterialEntity.name });
  //   }
  //   if (
  //     (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
  //     (this.props.medicineMaterialEntity.minimumAmount && this.state.minimumAmountSelectValue === null)
  //   ) {
  //     this.setState({ minimumAmountSelectValue: this.props.medicineMaterialEntity.minimumAmount });
  //   }
  //   if (
  //     (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
  //     (this.props.medicineMaterialEntity.expenseCode && this.state.expenseCodeSelectValue === null)
  //   ) {
  //     this.setState({ expenseCodeSelectValue: this.props.medicineMaterialEntity.expenseCode });
  //   }
  //   if (
  //     (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
  //     (this.props.medicineMaterialEntity.activePrinciple && this.state.activePrincipleSelectValue === null)
  //   ) {
  //     this.setState({ activePrincipleSelectValue: this.props.medicineMaterialEntity.activePrinciple });
  //   }
  //   if (
  //     (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
  //     (this.props.medicineMaterialEntity.status && this.state.statusSelectValue === null)
  //   ) {
  //     this.setState({ statusSelectValue: this.props.medicineMaterialEntity.status });
  //   }
  //   if (
  //     (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
  //     (this.props.medicineMaterialEntity.ordinance344 && this.state.ordinance344SelectValue === null)
  //   ) {
  //     this.setState({ ordinance344SelectValue: this.props.medicineMaterialEntity.ordinance344 });
  //   }
  //   if (
  //     (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
  //     (this.props.medicineMaterialEntity.inventoryControl && this.state.inventoryControlSelectValue === null)
  //   ) {
  //     this.setState({ inventoryControlSelectValue: this.props.medicineMaterialEntity.inventoryControl });
  //   }
  //   if (
  //     (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
  //     (this.props.medicineMaterialEntity.fractioned && this.state.fractionedSelectValue === null)
  //   ) {
  //     this.setState({ fractionedSelectValue: this.props.medicineMaterialEntity.fractioned });
  //   }
  //   if (
  //     (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
  //     (this.props.medicineMaterialEntity.specialControl && this.state.specialControlSelectValue === null)
  //   ) {
  //     this.setState({ specialControlSelectValue: this.props.medicineMaterialEntity.specialControl });
  //   }
  //   if (
  //     (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
  //     (this.props.medicineMaterialEntity.thermolabile && this.state.thermolabileSelectValue === null)
  //   ) {
  //     this.setState({ thermolabileSelectValue: this.props.medicineMaterialEntity.thermolabile });
  //   }
  //   if (
  //     (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
  //     (this.props.medicineMaterialEntity.comments && this.state.commentsSelectValue === null)
  //   ) {
  //     this.setState({ commentsSelectValue: this.props.medicineMaterialEntity.comments });
  //   }
  //   if (
  //     (prevProps.medicineMaterialEntity && prevProps.medicineMaterialEntity.id !== this.props.medicineMaterialEntity.id) ||
  //     (this.props.medicineMaterialEntity.laboratory && this.state.laboratorySelectValue === null)
  //   ) {
  //     this.setState({ laboratorySelectValue: this.props.medicineMaterialEntity.laboratory });
  //   }

  //   if (
  //     this.state.isNew &&
  //     this.props.levelGroups &&
  //     this.props.levelGroups.length > 0 &&
  //     this.state.urlLevelGroupName &&
  //     !this.state.levelGroupSelectValue
  //   ) {
  //     this.setState({
  //       levelGroupSelectValue: this.props.levelGroups
  //         .filter(p => p.name === this.state.urlLevelGroupName)
  //         .map(p => p.id)
  //         .pop(),
  //     });
  //   }

  //   console.info(this.state.urlLevelGroupName);
  //   if (!this.state.isNew && this.state.medicineMaterialTypeSelectValue && !this.state.urlLevelGroupName) {
  //     this.setState({
  //       urlLevelGroupName: this.state.medicineMaterialTypeSelectValue,
  //     });
  //   }
  // }

  renderBody() {
    const { medicineMaterialEntity, loading } = this.props;
    const { isNew } = this.state;

    const { comments } = medicineMaterialEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    if (this.state.categorySelectValue && this.state.categorySelectValue.length > 0) {
      const groupAux = this.state.categorySelectValue.slice(0, 1).pop().levelGroup;
      if (
        groupAux &&
        groupAux.name &&
        ['medicamentos', 'medicamento'].includes(groupAux.name.toLowerCase()) &&
        this.state.medicineMaterialTypeSelectValue !== MedicineMaterialType.MEDICAMENTO
      ) {
        this.setState({ medicineMaterialTypeSelectValue: MedicineMaterialType.MEDICAMENTO });
      } else if (
        groupAux &&
        groupAux.name &&
        ['materiais', 'material'].includes(groupAux.name.toLowerCase()) &&
        this.state.medicineMaterialTypeSelectValue !== MedicineMaterialType.MATERIAL
      ) {
        this.setState({ medicineMaterialTypeSelectValue: MedicineMaterialType.MATERIAL });
      } else if (
        groupAux &&
        groupAux.name &&
        ['dietas', 'dieta'].includes(groupAux.name.toLowerCase()) &&
        this.state.medicineMaterialTypeSelectValue !== MedicineMaterialType.DIETA
      ) {
        this.setState({ medicineMaterialTypeSelectValue: MedicineMaterialType.DIETA });
      }
    }
    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      <Col md="12">
                        <AvInput id="medicine-material-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'category' ? (
                    <Col md="12">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="medicine-material-category">
                              <Translate contentKey="generadorApp.medicineMaterial.category">Category</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              isMulti
                              id="medicine-material-category"
                              name={'category'}
                              className={'css-select-control'}
                              data-type-rel="many-to-many-owner-side"
                              value={this.state.categorySelectValue}
                              onChange={options => this.setState({ categorySelectValue: options })}
                              defaultOptions={this.state.categoryStartSelectOptions ? this.state.categoryStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.categoryStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.categoryStartSelectOptions === undefined) {
                                  const result = await getListAxios(
                                    'categories',
                                    this.state.urlLevelGroupName
                                      ? { 'levelGroup.name.equals': this.state.urlLevelGroupName }
                                      : { 'levelGroup.sub.contains': 'MATERIALMEDICAMENTOEDIETA' },
                                    0,
                                    100,
                                    'levelGroup.id,asc',
                                    'levelGroup.id'
                                  );
                                  this.setState({
                                    categoryStartSelectOptions: result.data
                                      ? result.data.map(p => ({
                                        ...p,
                                        value: p.id,
                                        label:
                                          (p['levelGroup'] && p['levelGroup']['name'] ? p.levelGroup.name : '') +
                                          ' | ' +
                                          (p['name'] ? p.name : ''),
                                      }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'categories',
                                  this.state.urlLevelGroupName
                                    ? { 'levelGroup.name.equals': this.state.urlLevelGroupName, 'name.contains': inputValue }
                                    : { 'levelGroup.sub.contains': 'MATERIALMEDICAMENTOEDIETA', 'name.contains': inputValue },
                                  0,
                                  100,
                                  'levelGroup.id,asc',
                                  'levelGroup.id'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({
                                      ...p,
                                      value: p.id,
                                      label:
                                        (p['levelGroup'] && p['levelGroup']['name'] ? p.levelGroup.name : '') +
                                        ' | ' +
                                        (p['name'] ? p.name : ''),
                                    }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                          <RenderModalSuperSelect
                            baseState={this.state}
                            setState={_state => this.setState(_state)}
                            entity={'category'}
                            listEntity={'categories'}
                            template={'levelGroup.name;name'.split(';')}
                            stateField={'categorySelectValue'}
                            multiple={false}
                            showFields={'levelGroup.name;name'.split(';')}
                            order={'levelGroup,asc'}
                            filtersBase={
                              this.state.urlLevelGroupName
                                ? { 'levelGroup.name.equals': this.state.urlLevelGroupName }
                                : { 'levelGroup.sub.contains': 'MATERIALMEDICAMENTOEDIETA' }
                            }
                          />
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput type="hidden" name="category" value={this.state.fieldsBase[baseFilters + 'Id']} />
                  )}
                  {baseFilters !== 'table' ? (
                    <Col md="5">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="tableLabel" for="medicine-material-table">
                              <Translate contentKey="generadorApp.medicineMaterial.table">Price Table</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="medicine-material-table"
                              className={'css-select-control'}
                              value={{
                                value: this.state.tableSelectValue,
                                label: translate('generadorApp.EnumTablePriceType.' + this.state.tableSelectValue),
                              }}
                              options={[
                                { value: 'BRASINDICE', label: translate('generadorApp.EnumTablePriceType.BRASINDICE') },
                                { value: 'SIMPRO', label: translate('generadorApp.EnumTablePriceType.SIMPRO') },
                              ]}
                              onChange={(options: any) => this.setState({ tableSelectValue: options['value'] })}
                              name="table"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ tableSelectValue: evt.target.value })}
                      type="hidden"
                      name="table"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  <Col md="7">
                    {this.state.tableSelectValue === 'BRASINDICE' && baseFilters !== 'brasindice' ? (
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="medicine-material-brasindice">
                              <Translate contentKey="generadorApp.medicineMaterial.brasindice">Brasindice</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="medicine-material-brasindice"
                              name={'brasindice'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.brasindiceSelectValue}
                              defaultOptions={this.state.brasindiceStartSelectOptions ? this.state.brasindiceStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.brasindiceStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.brasindiceStartSelectOptions === undefined) {
                                  const result = await getListAxios('brasindices', {}, 0, 100, 'name,asc', 'name');
                                  this.setState({
                                    brasindiceStartSelectOptions: result.data
                                      ? result.data.map(p => ({
                                        ...p,
                                        value: p.id,
                                        label: (p['nameLab'] ? p.nameLab : '') + ' | ' + (p['name'] ? p.name : ''),
                                      }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'brasindices',
                                  { 'name.contains': inputValue },
                                  0,
                                  100,
                                  'name,asc',
                                  'nameLab,name'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({
                                      ...p,
                                      value: p.id,
                                      label: (p['nameLab'] ? p.nameLab : '') + ' | ' + (p['name'] ? p.name : ''),
                                    }))
                                    : []
                                );
                              }}
                              onChange={options =>
                                this.setState({
                                  brasindiceSelectValue: options,

                                  saleValueSelectValue: options['price'],
                                  tussCodeSelectValue: options['codeTuss'],
                                  tissCodeSelectValue: options['codeTiss'],
                                  tableCodeSelectValue: options['tableCode'] ? options['tableCode'] : '',
                                  expenseCodeSelectValue: options['expenseCode'],

                                  pmcAmountSelectValue: options['pcEmVen'],
                                  pfAmountSelectValue: options['pcEmFab'],
                                  pmcFracAmountSelectValue: options['pcFrVen'],
                                  pfFracAmountSelectValue: options['pcFrFab'],

                                  // nameSelectValue: options['name'],
                                  laboratorySelectValue: options['codeLab'],
                                  unitaryAmountSelectValue: this.state.fractionedSelectValue ? options['valueFrac'] : options['price'],
                                  priceFrac: options['valueFrac'],
                                  priceSel: options['price'],
                                })
                              }
                            />
                          </Col>
                          <RenderModalSuperSelect
                            baseState={this.state}
                            setState={options =>
                              this.setState({
                                brasindiceSelectValue: options.brasindiceSelectValue,

                                saleValueSelectValue: options.brasindiceSelectValue['price'],
                                tussCodeSelectValue: options.brasindiceSelectValue['codeTuss'],
                                tissCodeSelectValue: options.brasindiceSelectValue['codeTiss'],
                                tableCodeSelectValue: options.brasindiceSelectValue['tableCode']
                                  ? options.brasindiceSelectValue['tableCode']
                                  : '',
                                expenseCodeSelectValue: options.brasindiceSelectValue['expenseCode'],

                                pmcAmountSelectValue: options.brasindiceSelectValue['pcEmVen'],
                                pmcFracAmountSelectValue: options.brasindiceSelectValue['pcFrVen'],
                                pfAmountSelectValue: options.brasindiceSelectValue['pcEmFab'],
                                pfFracAmountSelectValue: options.brasindiceSelectValue['pcFrFab'],

                                // nameSelectValue: options.brasindiceSelectValue['name'],
                                laboratorySelectValue: options.brasindiceSelectValue['codeLab'],
                                unitaryAmountSelectValue: this.state.fractionedSelectValue
                                  ? options.brasindiceSelectValue['valueFrac']
                                  : options.brasindiceSelectValue['price'],
                                priceFrac: options.brasindiceSelectValue['valueFrac'],
                                priceSel: options.brasindiceSelectValue['price'],
                              })
                            }
                            entity={'brasindice'}
                            listEntity={'brasindices'}
                            template={'codeTuss;codeTiss;name;nameLab;nameApre;pcEmFab;pcFrFab;pcEmVen;pcFrVen'.split(';')}
                            stateField={'brasindiceSelectValue'}
                            multiple={false}
                            showFields={'nameLab;name'.split(';')}
                            order={'name,asc'}
                            filtersBase={{}}
                          />
                        </Row>
                      </AvGroup>
                    ) : (
                      <AvInput type="hidden" name="brasindice" value={this.state.fieldsBase[baseFilters + 'Id']} />
                    )}
                    {this.state.tableSelectValue === 'SIMPRO' && baseFilters !== 'simpro' ? (
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="medicine-material-simpro">
                              <Translate contentKey="generadorApp.medicineMaterial.simpro">Simpro</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="medicine-material-simpro"
                              name={'simpro'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.simproSelectValue}
                              defaultOptions={this.state.simproStartSelectOptions ? this.state.simproStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.simproStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.simproStartSelectOptions === undefined) {
                                  const result = await getListAxios('simpros', {}, 0, 100, 'descricao,asc', 'descricao,fabrica');
                                  this.setState({
                                    simproStartSelectOptions: result.data
                                      ? result.data.map(p => ({
                                        ...p,
                                        value: p.id,
                                        label: (p['fabrica'] ? p.fabrica : '') + ' | ' + (p['descricao'] ? p.descricao : ''),
                                      }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'simpros',
                                  { 'descricao.contains': inputValue },
                                  0,
                                  100,
                                  'descricao,asc',
                                  'descricao,fabrica'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({
                                      ...p,
                                      value: p.id,
                                      label: (p['fabrica'] ? p.fabrica : '') + ' | ' + (p['descricao'] ? p.descricao : ''),
                                    }))
                                    : []
                                );
                              }}
                              onChange={options =>
                                this.setState({
                                  simproSelectValue: options,
                                  saleValueSelectValue: options['pcFrVen'], // price
                                  tussCodeSelectValue: options['cdTuss'],
                                  tissCodeSelectValue: options['cdSimpro'],
                                  tableCodeSelectValue: options['tableCode'],
                                  expenseCodeSelectValue: options['expenseCode'],

                                  pmcAmountSelectValue: options['pcEmVen'],
                                  pfAmountSelectValue: options['pcEmFab'],
                                  pmcFracAmountSelectValue: options['pcFrVen'],
                                  pfFracAmountSelectValue: options['pcFrFab'],

                                  // nameSelectValue: options['descricao'],
                                  laboratorySelectValue: options['fabrica'],
                                  unitaryAmountSelectValue: this.state.fractionedSelectValue ? options['pcEmUsu'] : options['price'],
                                  priceFrac: options['pcEmUsu'],
                                  priceSel: options['price'],
                                })
                              }
                            />
                          </Col>
                          <RenderModalSuperSelect
                            baseState={this.state}
                            setState={options =>
                              this.setState({
                                simproSelectValue: options.simproSelectValue,
                                saleValueSelectValue: options.simproSelectValue['pcFrVen'], // price
                                tussCodeSelectValue: options.simproSelectValue['cdTuss'],
                                tissCodeSelectValue: options.simproSelectValue['cdSimpro'],
                                tableCodeSelectValue: options.simproSelectValue['tableCode'],
                                expenseCodeSelectValue: options.simproSelectValue['expenseCode'],

                                pmcAmountSelectValue: options.simproSelectValue['pcEmVen'],
                                pfAmountSelectValue: options.simproSelectValue['pcEmFab'],
                                pmcFracAmountSelectValue: options.simproSelectValue['pcFrVen'],
                                pfFracAmountSelectValue: options.simproSelectValue['pcFrFab'],

                                // nameSelectValue: options.simproSelectValue['descricao'],
                                laboratorySelectValue: options.simproSelectValue['fabrica'],
                                unitaryAmountSelectValue: this.state.fractionedSelectValue
                                  ? options.simproSelectValue['pcEmUsu']
                                  : options.simproSelectValue['price'],
                                priceFrac: options.simproSelectValue['pcEmUsu'],
                                priceSel: options.simproSelectValue['price'],
                              })
                            }
                            entity={'simpro'}
                            listEntity={'simpros'}
                            template={'cdTuss;cdSimpro;descricao;fabrica;qtdeEmbal;pcEmFab;pcFrFab;pcEmVen;pcFrVen'.split(';')}
                            stateField={'simproSelectValue'}
                            multiple={false}
                            showFields={'fabrica;descricao'.split(';')}
                            order={'descricao,asc'}
                            filtersBase={{}}
                          />
                        </Row>
                      </AvGroup>
                    ) : (
                      <AvInput type="hidden" name="simpro" value={this.state.fieldsBase[baseFilters + 'Id']} />
                    )}
                  </Col>
                  {baseFilters !== 'name' ? (
                    <Col md="7">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="nameLabel" for="medicine-material-name">
                              <Translate contentKey="generadorApp.medicineMaterial.name">Name</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <AvInput
                              onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                              validate={{
                                required: { value: true, errorMessage: translate('entity.validation.required') },
                                maxLength: { value: 255, errorMessage: translate('entity.validation.maxlength', { max: 255 }) },
                              }}
                              id="medicine-material-name"
                              type="text"
                              name="name_1"
                              value={this.state.nameSelectValue}
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                      type="hidden"
                      name="name"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters === 'unitaryAmount' ? (
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="unitaryAmountLabel" for="quote-rh-unitaryAmount">
                              <Translate contentKey="generadorApp.quoteRh.unitaryAmount">Unitary Amount</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              disabled={true}
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ unitaryAmountSelectValue: floatvalue })}
                              decimalSeparator=","
                              thousandSeparator=""
                              prefix="R$ "
                              allowNegative={true}
                              value={this.state.unitaryAmountSelectValue}
                              id="quote-rh-unitaryAmount"
                              name="unitaryAmount"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ unitaryAmountSelectValue: evt.target.value })}
                      type="hidden"
                      name="unitaryAmount"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'minimumAmount' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="minimumAmountLabel" for="medicine-material-minimumAmount">
                              <Translate contentKey="generadorApp.medicineMaterial.minimumAmount">Minimum Amount</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <AvField
                              onChange={evt => this.setState({ minimumAmountSelectValue: evt.target.value })}
                              id="medicine-material-minimumAmount"
                              type="number"
                              className="form-control"
                              name="minimumAmount"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ minimumAmountSelectValue: evt.target.value })}
                      type="hidden"
                      name="minimumAmount"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'expenseCode' ? (
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="expenseCodeLabel" for="medicine-material-expenseCode">
                              <Translate contentKey="generadorApp.clComTable.expenseCode">expenseCode</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <AvField
                              onChange={evt => this.setState({ expenseCodeSelectValue: evt.target.value })}
                              id="medicine-material-expenseCode"
                              type="text"
                              className="form-control"
                              name="expenseCode"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ expenseCodeSelectValue: evt.target.value })}
                      type="hidden"
                      name="expenseCode"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  <Row>
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2"> Código TUSS </Label>
                          </Col>
                          <Col md="12">
                            <input className="form-control" disabled={true} name={'CodigoTUSS'} value={this.state.tussCodeSelectValue} />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2"> Código TISS </Label>
                          </Col>
                          <Col md="12">
                            <input className="form-control" disabled={true} name={'CodigoTISS'} value={this.state.tissCodeSelectValue} />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2"> Valor PMC </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              disabled={true}
                              decimalSeparator=","
                              thousandSeparator=""
                              prefix="R$ "
                              allowNegative={true}
                              value={this.state.pmcAmountSelectValue}
                              id="quote-rh-pmcAmount"
                              name="pmcAmount"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2"> Valor PMC (Frac) </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              disabled={true}
                              decimalSeparator=","
                              thousandSeparator=""
                              prefix="R$ "
                              allowNegative={true}
                              value={this.state.pmcFracAmountSelectValue}
                              id="quote-rh-pmcFracAmount"
                              name="pmcFracAmount"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2"> Valor PF </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              disabled={true}
                              decimalSeparator=","
                              thousandSeparator=""
                              prefix="R$ "
                              allowNegative={true}
                              value={this.state.pfAmountSelectValue}
                              id="quote-rh-pfAmount"
                              name="pfAmount"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2"> Valor PF (Frac) </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              disabled={true}
                              decimalSeparator=","
                              thousandSeparator=""
                              prefix="R$ "
                              allowNegative={true}
                              value={this.state.pfFracAmountSelectValue}
                              id="quote-rh-pfFracAmount"
                              name="pfFracAmount"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  </Row>
                  {['Medicamentos', 'Medicamento', 'MEDICAMENTOS', 'MEDICAMENTO'].includes(this.state.urlLevelGroupName) ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="activePrincipleLabel" for="medicine-material-activePrinciple">
                              <Translate contentKey="generadorApp.medicineMaterial.activePrinciple">Active Principle</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <AvInput
                              onChange={evt => this.setState({ activePrincipleSelectValue: evt.target.value })}
                              validate={{ maxLength: { value: 255, errorMessage: translate('entity.validation.maxlength', { max: 255 }) } }}
                              id="medicine-material-activePrinciple"
                              type="text"
                              name="activePrinciple"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ activePrincipleSelectValue: evt.target.value })}
                      type="hidden"
                      name="activePrinciple"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {['Medicamentos', 'Medicamento', 'MEDICAMENTOS', 'MEDICAMENTO'].includes(this.state.urlLevelGroupName) ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="medicine-material-dose">
                              <Translate contentKey="generadorApp.medicineMaterial.dose">Dose</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="medicine-material-dose"
                              name={'dose'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.doseSelectValue}
                              onChange={options => this.setState({ doseSelectValue: options })}
                              defaultOptions={this.state.doseStartSelectOptions ? this.state.doseStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.doseStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.doseStartSelectOptions === undefined) {
                                  const result = await getListAxios('doses', {}, 0, 100, 'name,asc', 'name');
                                  this.setState({
                                    doseStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: p['name'] ? p.name : '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios('doses', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                                callback(result.data ? result.data.map(p => ({ ...p, value: p.id, label: p['name'] ? p.name : '' })) : []);
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput type="hidden" name="dose" value={this.state.fieldsBase[baseFilters + 'Id']} />
                  )}
                  {baseFilters !== 'presentation' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="medicine-material-presentation">
                              <Translate contentKey="generadorApp.medicineMaterial.presentation">Presentation</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="medicine-material-presentation"
                              name={'presentation'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.presentationSelectValue}
                              onChange={options => this.setState({ presentationSelectValue: options })}
                              defaultOptions={this.state.presentationStartSelectOptions ? this.state.presentationStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.presentationStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.presentationStartSelectOptions === undefined) {
                                  const result = await getListAxios('presentations', {}, 0, 100, 'name,asc', 'name');
                                  this.setState({
                                    presentationStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: p['name'] ? p.name : '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'presentations',
                                  { 'name.contains': inputValue },
                                  0,
                                  100,
                                  'name,asc',
                                  'name'
                                );
                                callback(result.data ? result.data.map(p => ({ ...p, value: p.id, label: p['name'] ? p.name : '' })) : []);
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput type="hidden" name="presentation" value={this.state.fieldsBase[baseFilters + 'Id']} />
                  )}
                  {baseFilters !== 'brand' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="medicine-material-brand">
                              <Translate contentKey="generadorApp.medicineMaterial.brand">Brand</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="medicine-material-brand"
                              name={'brand'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.brandSelectValue}
                              onChange={options => this.setState({ brandSelectValue: options })}
                              defaultOptions={this.state.brandStartSelectOptions ? this.state.brandStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.brandStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.brandStartSelectOptions === undefined) {
                                  const result = await getListAxios('brands', {}, 0, 100, 'name,asc', 'name');
                                  this.setState({
                                    brandStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: p['name'] ? p.name : '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios('brands', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                                callback(result.data ? result.data.map(p => ({ ...p, value: p.id, label: p['name'] ? p.name : '' })) : []);
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput type="hidden" name="brand" value={this.state.fieldsBase[baseFilters + 'Id']} />
                  )}
                  {baseFilters !== 'inventoryControl' ? (
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="inventoryControlLabel" for="medicine-material-inventoryControl">
                              <Translate contentKey="generadorApp.medicineMaterial.inventoryControl">Inventory Control</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'inventoryControl_checkbox'}
                                id={'inventoryControl_checkbox'}
                                checked={this.state.inventoryControlSelectValue}
                                onChange={evt => this.setState({ inventoryControlSelectValue: !this.state.inventoryControlSelectValue })}
                              />
                              <label htmlFor={'inventoryControl_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ inventoryControlSelectValue: evt.target.value })}
                      type="hidden"
                      name="inventoryControl"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'fractioned' ? (
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="fractionedLabel" for="medicine-material-fractioned">
                              <Translate contentKey="generadorApp.medicineMaterial.fractioned">Fractioned</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'fractioned_checkbox'}
                                id={'fractioned_checkbox'}
                                checked={this.state.fractionedSelectValue}
                                onChange={evt => {
                                  console.info(evt.target.checked);
                                  this.setState({
                                    fractionedSelectValue: evt.target.checked,
                                    unitaryAmountSelectValue: !evt.target.checked ? this.state.priceFrac : this.state.priceSel,
                                  });
                                }}
                              />
                              <label htmlFor={'fractioned_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ fractionedSelectValue: evt.target.value })}
                      type="hidden"
                      name="fractioned"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'status' ? (
                    <Col md="4">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="statusLabel" for="medicine-material-status">
                              <Translate contentKey="generadorApp.medicineMaterial.status">Status</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="medicine-material-status"
                              className={'css-select-control'}
                              value={{
                                value: this.state.statusSelectValue,
                                label: translate('generadorApp.Status.' + this.state.statusSelectValue),
                              }}
                              options={[
                                { value: 'ATIVO', label: translate('generadorApp.Status.ATIVO') },
                                { value: 'INATIVADO', label: translate('generadorApp.Status.INATIVADO') },
                              ]}
                              onChange={(options: any) => this.setState({ statusSelectValue: options['value'] })}
                              name="status"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ statusSelectValue: evt.target.value })}
                      type="hidden"
                      name="status"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {['Medicamentos', 'Medicamento', 'MEDICAMENTOS', 'MEDICAMENTO'].includes(this.state.urlLevelGroupName) ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="thermolabileLabel" for="medicine-material-thermolabile">
                              <Translate contentKey="generadorApp.medicineMaterial.thermolabile">Thermolabile</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'thermolabile_checkbox'}
                                id={'thermolabile_checkbox'}
                                checked={this.state.thermolabileSelectValue}
                                onChange={evt => this.setState({ thermolabileSelectValue: !this.state.thermolabileSelectValue })}
                              />
                              <label htmlFor={'thermolabile_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ thermolabileSelectValue: evt.target.value })}
                      type="hidden"
                      name="thermolabile"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {['Medicamentos', 'Medicamento', 'MEDICAMENTOS', 'MEDICAMENTO'].includes(this.state.urlLevelGroupName) ? (
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="specialControlLabel" for="medicine-material-specialControl">
                              <Translate contentKey="generadorApp.medicineMaterial.specialControl">Special Control</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'specialControl_checkbox'}
                                id={'specialControl_checkbox'}
                                checked={this.state.specialControlSelectValue}
                                onChange={evt => this.setState({ specialControlSelectValue: !this.state.specialControlSelectValue })}
                              />
                              <label htmlFor={'specialControl_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ specialControlSelectValue: evt.target.value })}
                      type="hidden"
                      name="specialControl"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {['Medicamentos', 'Medicamento', 'MEDICAMENTOS', 'MEDICAMENTO'].includes(this.state.urlLevelGroupName) ? (
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="specialControlLabel" for="medicine-material-controlled">
                              <text >Medicamento Controlado</text>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'controlled'}
                                id={'controlled'}
                                checked={this.state.medicineControlSelectValue}
                                onChange={evt => this.setState({ medicineControlSelectValue: !this.state.medicineControlSelectValue })}
                              />
                              <label htmlFor={'controlled'} />
                            </div>
                          </Col>
                        </Row>
                        <Row>

                          {this.state.medicineControlSelectValue ?
                            <>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="ordinance344Label" for="medicine-material-pharmagroup">
                                  <text>Grupos farmacológicos</text>
                                </Label>
                              </Col>
                              <Col md="12">
                                <Select
                                  id="medicine-material-pharmagroup"
                                  className={'css-select-control'}
                                  value={{
                                    value: this.state.ordinance344SelectValue,
                                    label: translate('generadorApp.Ordinance.' + this.state.ordinance344SelectValue),
                                  }}
                                  options={[
                                    { value: "Analgésicos", label: "Analgésicos" },
                                    { value: "Anti-inflamatórios", label: "Anti-inflamatórios" },
                                    { value: "Antidepressivos", label: "Antidepressivos" },
                                    { value: "Antidiabéticos", label: "Antidiabéticos" },
                                    { value: "Anticoagulantes", label: "Anticoagulantes" },
                                    { value: "Anticonvulsivantes", label: "Anticonvulsivantes" },
                                    { value: "Antimicrobianos", label: "Antimicrobianos" },
                                    { value: "Antifúngicos", label: "Antifúngicos" },
                                    { value: "Anti-hipertensivos", label: "Anti-hipertensivos" },
                                    { value: "Antineoplásicos (quimioterápicos)", label: "Antineoplásicos (quimioterápicos)" },
                                    { value: "Antiácidos", label: "Antiácidos" },
                                    { value: "Antiespasmódicos", label: "Antiespasmódicos" },
                                    { value: "Broncodilatadores", label: "Broncodilatadores" },
                                    { value: "Diuréticos", label: "Diuréticos" },
                                  ]}
                                  onChange={(options: any) => this.setState({ ordinance344SelectValue: options['value'] })}
                                  name="ordinance344"
                                />           
                                </Col>
                            </>
                            : null

                          }


                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ medicineControlSelectValue: evt.target.value })}
                      type="hidden"
                      name="controlled"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {['Medicamentos', 'Medicamento', 'MEDICAMENTOS', 'MEDICAMENTO'].includes(this.state.urlLevelGroupName) ? (
                    <Col md="4">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="ordinance344Label" for="medicine-material-ordinance344">
                              <Translate contentKey="generadorApp.medicineMaterial.ordinance344">Ordinance 344</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="medicine-material-ordinance344"
                              className={'css-select-control'}
                              value={{
                                value: this.state.ordinance344SelectValue,
                                label: translate('generadorApp.Ordinance.' + this.state.ordinance344SelectValue),
                              }}
                              options={[
                                { value: 'A1', label: translate('generadorApp.Ordinance.A1') },
                                { value: 'A2', label: translate('generadorApp.Ordinance.A2') },
                                { value: 'A3', label: translate('generadorApp.Ordinance.A3') },
                                { value: 'C1', label: translate('generadorApp.Ordinance.C1') },
                                { value: 'B1', label: translate('generadorApp.Ordinance.B1') },
                                { value: 'B2', label: translate('generadorApp.Ordinance.B2') },
                              ]}
                              onChange={(options: any) => this.setState({ ordinance344SelectValue: options['value'] })}
                              name="ordinance344"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="ordinance344Label" for="medicine-material-ordinance344">
                              <text>Dosagem</text>
                            </Label>
                          </Col>
                          <Col md="12">
                            <AvInput
                              onChange={evt => this.setState({ dosagemSelectValue: evt.target.value })}
                              validate={{ maxLength: { value: 255, errorMessage: translate('entity.validation.maxlength', { max: 255 }) } }}
                              id="medicine-material-dosage"
                              type="text"
                              name="dosage"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ ordinance344SelectValue: evt.target.value })}
                      type="hidden"
                      name="ordinance344"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'comments' ? (
                    <Col md="12">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="commentsLabel" for="medicine-material-comments">
                              <Translate contentKey="generadorApp.medicineMaterial.comments">Comments</Translate>
                            </Label>
                          </Col>

                          <Col md="12">
                            <ReactQuill
                              value={this.state.commentsSelectValue}
                              onChange={evt => this.setState({ commentsSelectValue: evt })}
                              modules={quillEditorModules}
                              formats={quillEditorFormats}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ commentsSelectValue: evt.target.value })}
                      type="hidden"
                      name="comments"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}{' '}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { medicineMaterialEntity } = this.props;
    const { isNew } = this.state;

    const { comments } = medicineMaterialEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                ...medicineMaterialEntity,
              }
          }
          onSubmit={this.saveEntity}
        >
          <Panel>
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
    listProcediment: storeState.patDrPrescrProcd.entities,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;
export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(MedicineMaterialExtendedUpdate);
