/* eslint complexity: ["error", 500] */
/* eslint no-empty: off */
import React from 'react';
import { connect } from 'react-redux';

import SelectAsync from 'react-select/async';

import { Link, RouteComponentProps } from 'react-router-dom';

import { getFilterFromSelect, getFormFromSelect, getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Col, Label } from 'reactstrap';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import {
  IPermissionUserUpdateState as IBaseUpdateState,
  getEntity,
  getPermissionUserState,
  getUrlBack,
  updateEntity,
  createEntity,
  getEntityFiltersURL,
  getEntityOffset,
  reset,
} from './permission-user.reducer';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

registerLocale('pt', pt);

export interface IPermissionUserUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}
export type IPermissionUserUpdateState = IBaseUpdateState;

export class PermissionUserUpdate extends React.Component<IPermissionUserUpdateProps, IPermissionUserUpdateState> {
  viewFileInput: any;

  resgisterFileInput: any;

  editFileInput: any;

  canDeleteFileInput: any;

  manualRemovalFileInput: any;

  editLocationFileInput: any;

  reportFileInput: any;

  activateProfessionalFileInput: any;

  deletePhotoFileInput: any;

  financialValueFileInput: any;

  valueAuthorizationFileInput: any;

  confirmPaymentFileInput: any;

  evolutionFileInput: any;

  professionalNameFileInput: any;

  professionalRegisterFileInput: any;
  constructor(props: Readonly<IPermissionUserUpdateProps>) {
    super(props);

    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      functionEmbebed: [],

      userSelectValue: null,

      permissionSelectValue: null,

      viewSelectValue: null,
      resgisterSelectValue: null,
      editSelectValue: null,
      canDeleteSelectValue: null,
      manualRemovalSelectValue: null,
      editLocationSelectValue: null,
      reportSelectValue: null,
      activateProfessionalSelectValue: null,
      deletePhotoSelectValue: null,
      financialValueSelectValue: null,
      valueAuthorizationSelectValue: null,
      confirmPaymentSelectValue: null,
      evolutionSelectValue: null,
      professionalNameSelectValue: null,
      professionalRegisterSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getPermissionUserState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
      userFullname: null,
      permissionName: null,

      isNew: !this.props.match.params || !this.props.match.params['id'],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateSuccess !== this.props.updateSuccess && prevProps.updateSuccess) {
      this.handleClose();
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.permissionUserEntity && prevProps.permissionUserEntity.id !== this.props.permissionUserEntity.id) ||
      (this.props.users &&
        this.state.userSelectValue === null &&
        this.props.permissionUserEntity.user &&
        this.props.permissionUserEntity.user.id)
    ) {
      const p = this.props.permissionUserEntity && this.props.permissionUserEntity.user ? this.props.permissionUserEntity.user : null;
      this.setState({
        userSelectValue: p ? { ...p, value: p.id, label: p['fullname'] ? p.fullname : '' } : null,
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.permissionUserEntity && prevProps.permissionUserEntity.id !== this.props.permissionUserEntity.id) ||
      (this.props.permissions &&
        this.state.permissionSelectValue === null &&
        this.props.permissionUserEntity.permission &&
        this.props.permissionUserEntity.permission.id)
    ) {
      const p =
        this.props.permissionUserEntity && this.props.permissionUserEntity.permission ? this.props.permissionUserEntity.permission : null;
      this.setState({
        permissionSelectValue: p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : null,
      });
    }

    if (
      (prevProps.permissionUserEntity && prevProps.permissionUserEntity.id !== this.props.permissionUserEntity.id) ||
      (this.state.viewSelectValue === null && this.props.permissionUserEntity.view !== this.state.viewSelectValue)
    ) {
      this.setState({ viewSelectValue: this.props.permissionUserEntity.view ? this.props.permissionUserEntity.view : false });
    }
    if (
      (prevProps.permissionUserEntity && prevProps.permissionUserEntity.id !== this.props.permissionUserEntity.id) ||
      (this.state.resgisterSelectValue === null && this.props.permissionUserEntity.resgister !== this.state.resgisterSelectValue)
    ) {
      this.setState({
        resgisterSelectValue: this.props.permissionUserEntity.resgister ? this.props.permissionUserEntity.resgister : false,
      });
    }
    if (
      (prevProps.permissionUserEntity && prevProps.permissionUserEntity.id !== this.props.permissionUserEntity.id) ||
      (this.state.editSelectValue === null && this.props.permissionUserEntity.edit !== this.state.editSelectValue)
    ) {
      this.setState({ editSelectValue: this.props.permissionUserEntity.edit ? this.props.permissionUserEntity.edit : false });
    }
    if (
      (prevProps.permissionUserEntity && prevProps.permissionUserEntity.id !== this.props.permissionUserEntity.id) ||
      (this.state.canDeleteSelectValue === null && this.props.permissionUserEntity.canDelete !== this.state.canDeleteSelectValue)
    ) {
      this.setState({
        canDeleteSelectValue: this.props.permissionUserEntity.canDelete ? this.props.permissionUserEntity.canDelete : false,
      });
    }
    if (
      (prevProps.permissionUserEntity && prevProps.permissionUserEntity.id !== this.props.permissionUserEntity.id) ||
      (this.state.manualRemovalSelectValue === null &&
        this.props.permissionUserEntity.manualRemoval !== this.state.manualRemovalSelectValue)
    ) {
      this.setState({
        manualRemovalSelectValue: this.props.permissionUserEntity.manualRemoval ? this.props.permissionUserEntity.manualRemoval : false,
      });
    }
    if (
      (prevProps.permissionUserEntity && prevProps.permissionUserEntity.id !== this.props.permissionUserEntity.id) ||
      (this.state.editLocationSelectValue === null && this.props.permissionUserEntity.editLocation !== this.state.editLocationSelectValue)
    ) {
      this.setState({
        editLocationSelectValue: this.props.permissionUserEntity.editLocation ? this.props.permissionUserEntity.editLocation : false,
      });
    }
    if (
      (prevProps.permissionUserEntity && prevProps.permissionUserEntity.id !== this.props.permissionUserEntity.id) ||
      (this.state.reportSelectValue === null && this.props.permissionUserEntity.report !== this.state.reportSelectValue)
    ) {
      this.setState({ reportSelectValue: this.props.permissionUserEntity.report ? this.props.permissionUserEntity.report : false });
    }
    if (
      (prevProps.permissionUserEntity && prevProps.permissionUserEntity.id !== this.props.permissionUserEntity.id) ||
      (this.state.activateProfessionalSelectValue === null &&
        this.props.permissionUserEntity.activateProfessional !== this.state.activateProfessionalSelectValue)
    ) {
      this.setState({
        activateProfessionalSelectValue: this.props.permissionUserEntity.activateProfessional
          ? this.props.permissionUserEntity.activateProfessional
          : false,
      });
    }
    if (
      (prevProps.permissionUserEntity && prevProps.permissionUserEntity.id !== this.props.permissionUserEntity.id) ||
      (this.state.deletePhotoSelectValue === null && this.props.permissionUserEntity.deletePhoto !== this.state.deletePhotoSelectValue)
    ) {
      this.setState({
        deletePhotoSelectValue: this.props.permissionUserEntity.deletePhoto ? this.props.permissionUserEntity.deletePhoto : false,
      });
    }
    if (
      (prevProps.permissionUserEntity && prevProps.permissionUserEntity.id !== this.props.permissionUserEntity.id) ||
      (this.state.financialValueSelectValue === null &&
        this.props.permissionUserEntity.financialValue !== this.state.financialValueSelectValue)
    ) {
      this.setState({
        financialValueSelectValue: this.props.permissionUserEntity.financialValue ? this.props.permissionUserEntity.financialValue : false,
      });
    }
    if (
      (prevProps.permissionUserEntity && prevProps.permissionUserEntity.id !== this.props.permissionUserEntity.id) ||
      (this.state.valueAuthorizationSelectValue === null &&
        this.props.permissionUserEntity.valueAuthorization !== this.state.valueAuthorizationSelectValue)
    ) {
      this.setState({
        valueAuthorizationSelectValue: this.props.permissionUserEntity.valueAuthorization
          ? this.props.permissionUserEntity.valueAuthorization
          : false,
      });
    }
    if (
      (prevProps.permissionUserEntity && prevProps.permissionUserEntity.id !== this.props.permissionUserEntity.id) ||
      (this.state.confirmPaymentSelectValue === null &&
        this.props.permissionUserEntity.confirmPayment !== this.state.confirmPaymentSelectValue)
    ) {
      this.setState({
        confirmPaymentSelectValue: this.props.permissionUserEntity.confirmPayment ? this.props.permissionUserEntity.confirmPayment : false,
      });
    }
    if (
      (prevProps.permissionUserEntity && prevProps.permissionUserEntity.id !== this.props.permissionUserEntity.id) ||
      (this.state.evolutionSelectValue === null && this.props.permissionUserEntity.evolution !== this.state.evolutionSelectValue)
    ) {
      this.setState({
        evolutionSelectValue: this.props.permissionUserEntity.evolution ? this.props.permissionUserEntity.evolution : false,
      });
    }
    if (
      (prevProps.permissionUserEntity && prevProps.permissionUserEntity.id !== this.props.permissionUserEntity.id) ||
      (this.state.professionalNameSelectValue === null &&
        this.props.permissionUserEntity.professionalName !== this.state.professionalNameSelectValue)
    ) {
      this.setState({
        professionalNameSelectValue: this.props.permissionUserEntity.professionalName
          ? this.props.permissionUserEntity.professionalName
          : false,
      });
    }
    if (
      (prevProps.permissionUserEntity && prevProps.permissionUserEntity.id !== this.props.permissionUserEntity.id) ||
      (this.state.professionalRegisterSelectValue === null &&
        this.props.permissionUserEntity.professionalRegister !== this.state.professionalRegisterSelectValue)
    ) {
      this.setState({
        professionalRegisterSelectValue: this.props.permissionUserEntity.professionalRegister
          ? this.props.permissionUserEntity.professionalRegister
          : false,
      });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  UNSAFE_componentWillMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params['id']);
    }
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = offset !== null ? '&offset=' + offset : '';
    Object.keys(fieldsBase).map(key => {
      url += key !== 'offset' ? '&' + key + '=' + fieldsBase[key] : '';
    });
    return url;
  };
  saveEntity = (event: any, errors: any, values: any) => {
    const selectFieldsList = [
      'permission.name',
      'user.fullname',
      'view',
      'resgister',
      'edit',
      'canDelete',
      'manualRemoval',
      'editLocation',
      'report',
      'activateProfessional',
      'deletePhoto',
      'financialValue',
      'valueAuthorization',
      'confirmPayment',
      'evolution',
      'professionalName',
      'professionalRegister',
    ];

    if (errors.length === 0) {
      const { permissionUserEntity } = this.props;
      const _entity = {
        ...permissionUserEntity,
        // ...values,

        permission: getFormFromSelect(this.state.permissionSelectValue, 'many-to-one'), // many-to-one - otherSide

        user: getFormFromSelect(this.state.userSelectValue, 'many-to-one'), // many-to-one - otherSide

        view: this.state.viewSelectValue === null ? false : this.state.viewSelectValue,
        resgister: this.state.resgisterSelectValue === null ? false : this.state.resgisterSelectValue,
        edit: this.state.editSelectValue === null ? false : this.state.editSelectValue,
        canDelete: this.state.canDeleteSelectValue === null ? false : this.state.canDeleteSelectValue,
        manualRemoval: this.state.manualRemovalSelectValue === null ? false : this.state.manualRemovalSelectValue,
        editLocation: this.state.editLocationSelectValue === null ? false : this.state.editLocationSelectValue,
        report: this.state.reportSelectValue === null ? false : this.state.reportSelectValue,
        activateProfessional: this.state.activateProfessionalSelectValue === null ? false : this.state.activateProfessionalSelectValue,
        deletePhoto: this.state.deletePhotoSelectValue === null ? false : this.state.deletePhotoSelectValue,
        financialValue: this.state.financialValueSelectValue === null ? false : this.state.financialValueSelectValue,
        valueAuthorization: this.state.valueAuthorizationSelectValue === null ? false : this.state.valueAuthorizationSelectValue,
        confirmPayment: this.state.confirmPaymentSelectValue === null ? false : this.state.confirmPaymentSelectValue,
        evolution: this.state.evolutionSelectValue === null ? false : this.state.evolutionSelectValue,
        professionalName: this.state.professionalNameSelectValue === null ? false : this.state.professionalNameSelectValue,
        professionalRegister: this.state.professionalRegisterSelectValue === null ? false : this.state.professionalRegisterSelectValue,
      };

      const entity = _entity;

      const { permissionId, userId, extraFilters, activePage, itemsPerPage, sort, order } = this.state.fieldsBase;

      if (this.state.isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              getFilterFromSelect(permissionId, 'many-to-one'),
              getFilterFromSelect(userId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              getFilterFromSelect(permissionId, 'many-to-one'),
              getFilterFromSelect(userId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/permission-user?' + getEntityFiltersURL(this.state.fieldsBase));
  };

  getFullEntityFromState(v) {
    return {
      id: v.id,
      view: this.state.viewSelectValue,
      resgister: this.state.resgisterSelectValue,
      edit: this.state.editSelectValue,
      canDelete: this.state.canDeleteSelectValue,
      manualRemoval: this.state.manualRemovalSelectValue,
      editLocation: this.state.editLocationSelectValue,
      report: this.state.reportSelectValue,
      activateProfessional: this.state.activateProfessionalSelectValue,
      deletePhoto: this.state.deletePhotoSelectValue,
      financialValue: this.state.financialValueSelectValue,
      valueAuthorization: this.state.valueAuthorizationSelectValue,
      confirmPayment: this.state.confirmPaymentSelectValue,
      evolution: this.state.evolutionSelectValue,
      professionalName: this.state.professionalNameSelectValue,
      professionalRegister: this.state.professionalRegisterSelectValue,
      user: this.state.userSelectValue,
      permission: this.state.permissionSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    const userEntity = v.user;

    const permissionEntity = v.permission;

    return {
      viewSelectValue: v.view,
      resgisterSelectValue: v.resgister,
      editSelectValue: v.edit,
      canDeleteSelectValue: v.canDelete,
      manualRemovalSelectValue: v.manualRemoval,
      editLocationSelectValue: v.editLocation,
      reportSelectValue: v.report,
      activateProfessionalSelectValue: v.activateProfessional,
      deletePhotoSelectValue: v.deletePhoto,
      financialValueSelectValue: v.financialValue,
      valueAuthorizationSelectValue: v.valueAuthorization,
      confirmPaymentSelectValue: v.confirmPayment,
      evolutionSelectValue: v.evolution,
      professionalNameSelectValue: v.professionalName,
      professionalRegisterSelectValue: v.professionalRegister,
      userSelectValue: userEntity ? userEntity['id'] : null,
      permissionSelectValue: permissionEntity ? permissionEntity['id'] : null,
    };
  }

  renderHeader() {
    const { permissionUserEntity, users, permissions, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.permissionUser.home.createLabel">Create a PermissionUser</Translate>
            ) : (
              <Translate contentKey="generadorApp.permissionUser.home.editLabel">Edit a PermissionUser</Translate>
            )}
          </span>

          <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.permissionUser.btnSave">Save</Translate>
          </Button>

          {!this.state.isNew ? (
            <>
              <Button
                color="primary"
                id="next-entity"
                disabled={updating}
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.permissionId,
                    this.state.fieldsBase.userId,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 + 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 + 1 },
                    });
                    this.props.history.push(
                      '/permission-user/' +
                        myresult.value.data[0]['id'] +
                        '/edit?' +
                        this.getFiltersURL(this.state.fieldsBase.offset * 1 + 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.last.item', { fieldName: translate('generadorApp.permissionUser.detail.title') }));
                  }
                }}
                className="float-right jh-create-entity"
              >
                {'>>'}
              </Button>

              <Button
                color="primary"
                id="prev-entity"
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.permissionId,
                    this.state.fieldsBase.userId,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 - 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 - 1 },
                    });
                    this.props.history.push(
                      '/permission-user/' +
                        myresult.value.data[0]['id'] +
                        '/edit?' +
                        this.getFiltersURL(this.state.fieldsBase.offset * 1 - 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.first.item', { fieldName: translate('generadorApp.permissionUser.detail.title') }));
                  }
                }}
                disabled={updating}
                className="float-right jh-create-entity"
              >
                {'<<'}
              </Button>
            </>
          ) : (
            <> </>
          )}

          <Button
            tag={Link}
            id="cancel-save"
            to={'/permission-user?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.permissionUser.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={'/permission-user'}>
              <Translate contentKey="generadorApp.permissionUser.home.title">Permission Users</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.permissionUser.detail.title">Permission User edit</Translate>
          </li>
        </ol>
      </>
    );
  }
  renderBody() {
    const { permissionUserEntity, users, permissions, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const fullEntityEmbebed = this.getFullEntityFromState(permissionUserEntity);

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="permission-user-id">
                  <Translate contentKey="generadorApp.permissionUser.field_id">ID</Translate>
                </Label>
                </Col> */}
                      <Col md="12">
                        <AvInput id="permission-user-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'permission' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="permission-user-permission">
                              <Translate contentKey="generadorApp.permissionUser.permission">Permission</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="permission-user-permission"
                              name={'permission'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.permissionSelectValue ? this.state.permissionSelectValue : ''}
                              onChange={options => this.setState({ permissionSelectValue: options })}
                              defaultOptions={this.state.permissionStartSelectOptions ? this.state.permissionStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.permissionStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.permissionStartSelectOptions === undefined) {
                                  const result = await getListAxios('permissions', {}, 0, 100, 'name,asc', 'name');
                                  this.setState({
                                    permissionStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'permissions',
                                  { 'name.contains': inputValue },
                                  0,
                                  100,
                                  'name,asc',
                                  'name'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="permission"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}

                  {baseFilters !== 'user' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="permission-user-user">
                              <Translate contentKey="generadorApp.permissionUser.user">User</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="permission-user-user"
                              name={'user'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.userSelectValue ? this.state.userSelectValue : ''}
                              onChange={options => this.setState({ userSelectValue: options })}
                              defaultOptions={this.state.userStartSelectOptions ? this.state.userStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.userStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.userStartSelectOptions === undefined) {
                                  const result = await getListAxios('users', {}, 0, 100, 'fullname,asc', 'fullname');
                                  this.setState({
                                    userStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'fullname') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'users',
                                  { 'fullname.contains': inputValue },
                                  0,
                                  100,
                                  'fullname,asc',
                                  'fullname'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'fullname') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="user"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}

                  {baseFilters !== 'view' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="viewLabel" for="permission-user-view">
                              <Translate contentKey="generadorApp.permissionUser.view">View</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'view_checkbox'}
                                id={'view_checkbox'}
                                checked={this.state.viewSelectValue}
                                onChange={evt => this.setState({ viewSelectValue: !this.state.viewSelectValue })}
                              />
                              <label htmlFor={'view_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ viewSelectValue: evt.target.value })}
                      type="hidden"
                      name="view"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'resgister' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="resgisterLabel" for="permission-user-resgister">
                              <Translate contentKey="generadorApp.permissionUser.resgister">Register</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'resgister_checkbox'}
                                id={'resgister_checkbox'}
                                checked={this.state.resgisterSelectValue}
                                onChange={evt => this.setState({ resgisterSelectValue: !this.state.resgisterSelectValue })}
                              />
                              <label htmlFor={'resgister_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ resgisterSelectValue: evt.target.value })}
                      type="hidden"
                      name="resgister"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'edit' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="editLabel" for="permission-user-edit">
                              <Translate contentKey="generadorApp.permissionUser.edit">Edit</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'edit_checkbox'}
                                id={'edit_checkbox'}
                                checked={this.state.editSelectValue}
                                onChange={evt => this.setState({ editSelectValue: !this.state.editSelectValue })}
                              />
                              <label htmlFor={'edit_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ editSelectValue: evt.target.value })}
                      type="hidden"
                      name="edit"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'canDelete' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="canDeleteLabel" for="permission-user-canDelete">
                              <Translate contentKey="generadorApp.permissionUser.canDelete">Delete</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'canDelete_checkbox'}
                                id={'canDelete_checkbox'}
                                checked={this.state.canDeleteSelectValue}
                                onChange={evt => this.setState({ canDeleteSelectValue: !this.state.canDeleteSelectValue })}
                              />
                              <label htmlFor={'canDelete_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ canDeleteSelectValue: evt.target.value })}
                      type="hidden"
                      name="canDelete"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'manualRemoval' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="manualRemovalLabel" for="permission-user-manualRemoval">
                              <Translate contentKey="generadorApp.permissionUser.manualRemoval">Manual removal</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'manualRemoval_checkbox'}
                                id={'manualRemoval_checkbox'}
                                checked={this.state.manualRemovalSelectValue}
                                onChange={evt => this.setState({ manualRemovalSelectValue: !this.state.manualRemovalSelectValue })}
                              />
                              <label htmlFor={'manualRemoval_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ manualRemovalSelectValue: evt.target.value })}
                      type="hidden"
                      name="manualRemoval"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'editLocation' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="editLocationLabel" for="permission-user-editLocation">
                              <Translate contentKey="generadorApp.permissionUser.editLocation">editLocation</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'editLocation_checkbox'}
                                id={'editLocation_checkbox'}
                                checked={this.state.editLocationSelectValue}
                                onChange={evt => this.setState({ editLocationSelectValue: !this.state.editLocationSelectValue })}
                              />
                              <label htmlFor={'editLocation_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ editLocationSelectValue: evt.target.value })}
                      type="hidden"
                      name="editLocation"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'report' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="reportLabel" for="permission-user-report">
                              <Translate contentKey="generadorApp.permissionUser.report">Report</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'report_checkbox'}
                                id={'report_checkbox'}
                                checked={this.state.reportSelectValue}
                                onChange={evt => this.setState({ reportSelectValue: !this.state.reportSelectValue })}
                              />
                              <label htmlFor={'report_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ reportSelectValue: evt.target.value })}
                      type="hidden"
                      name="report"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'activateProfessional' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label
                              className="mt-2 label-single-line"
                              id="activateProfessionalLabel"
                              for="permission-user-activateProfessional"
                            >
                              <Translate contentKey="generadorApp.permissionUser.activateProfessional">Activate professional</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'activateProfessional_checkbox'}
                                id={'activateProfessional_checkbox'}
                                checked={this.state.activateProfessionalSelectValue}
                                onChange={evt =>
                                  this.setState({ activateProfessionalSelectValue: !this.state.activateProfessionalSelectValue })
                                }
                              />
                              <label htmlFor={'activateProfessional_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ activateProfessionalSelectValue: evt.target.value })}
                      type="hidden"
                      name="activateProfessional"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'deletePhoto' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="deletePhotoLabel" for="permission-user-deletePhoto">
                              <Translate contentKey="generadorApp.permissionUser.deletePhoto">Delete photo</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'deletePhoto_checkbox'}
                                id={'deletePhoto_checkbox'}
                                checked={this.state.deletePhotoSelectValue}
                                onChange={evt => this.setState({ deletePhotoSelectValue: !this.state.deletePhotoSelectValue })}
                              />
                              <label htmlFor={'deletePhoto_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ deletePhotoSelectValue: evt.target.value })}
                      type="hidden"
                      name="deletePhoto"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'financialValue' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="financialValueLabel" for="permission-user-financialValue">
                              <Translate contentKey="generadorApp.permissionUser.financialValue">Values</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'financialValue_checkbox'}
                                id={'financialValue_checkbox'}
                                checked={this.state.financialValueSelectValue}
                                onChange={evt => this.setState({ financialValueSelectValue: !this.state.financialValueSelectValue })}
                              />
                              <label htmlFor={'financialValue_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ financialValueSelectValue: evt.target.value })}
                      type="hidden"
                      name="financialValue"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'valueAuthorization' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="valueAuthorizationLabel" for="permission-user-valueAuthorization">
                              <Translate contentKey="generadorApp.permissionUser.valueAuthorization">Value authorization</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'valueAuthorization_checkbox'}
                                id={'valueAuthorization_checkbox'}
                                checked={this.state.valueAuthorizationSelectValue}
                                onChange={evt =>
                                  this.setState({ valueAuthorizationSelectValue: !this.state.valueAuthorizationSelectValue })
                                }
                              />
                              <label htmlFor={'valueAuthorization_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ valueAuthorizationSelectValue: evt.target.value })}
                      type="hidden"
                      name="valueAuthorization"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'confirmPayment' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="confirmPaymentLabel" for="permission-user-confirmPayment">
                              <Translate contentKey="generadorApp.permissionUser.confirmPayment">confirmPayment</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'confirmPayment_checkbox'}
                                id={'confirmPayment_checkbox'}
                                checked={this.state.confirmPaymentSelectValue}
                                onChange={evt => this.setState({ confirmPaymentSelectValue: !this.state.confirmPaymentSelectValue })}
                              />
                              <label htmlFor={'confirmPayment_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ confirmPaymentSelectValue: evt.target.value })}
                      type="hidden"
                      name="confirmPayment"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'evolution' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="evolutionLabel" for="permission-user-evolution">
                              <Translate contentKey="generadorApp.permissionUser.evolution">Evolution</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'evolution_checkbox'}
                                id={'evolution_checkbox'}
                                checked={this.state.evolutionSelectValue}
                                onChange={evt => this.setState({ evolutionSelectValue: !this.state.evolutionSelectValue })}
                              />
                              <label htmlFor={'evolution_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ evolutionSelectValue: evt.target.value })}
                      type="hidden"
                      name="evolution"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'professionalName' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="professionalNameLabel" for="permission-user-professionalName">
                              <Translate contentKey="generadorApp.permissionUser.professionalName">Professional name</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'professionalName_checkbox'}
                                id={'professionalName_checkbox'}
                                checked={this.state.professionalNameSelectValue}
                                onChange={evt => this.setState({ professionalNameSelectValue: !this.state.professionalNameSelectValue })}
                              />
                              <label htmlFor={'professionalName_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ professionalNameSelectValue: evt.target.value })}
                      type="hidden"
                      name="professionalName"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'professionalRegister' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label
                              className="mt-2 label-single-line"
                              id="professionalRegisterLabel"
                              for="permission-user-professionalRegister"
                            >
                              <Translate contentKey="generadorApp.permissionUser.professionalRegister">Professional register</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'professionalRegister_checkbox'}
                                id={'professionalRegister_checkbox'}
                                checked={this.state.professionalRegisterSelectValue}
                                onChange={evt =>
                                  this.setState({ professionalRegisterSelectValue: !this.state.professionalRegisterSelectValue })
                                }
                              />
                              <label htmlFor={'professionalRegister_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ professionalRegisterSelectValue: evt.target.value })}
                      type="hidden"
                      name="professionalRegister"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { permissionUserEntity, users, permissions, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...permissionUserEntity,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel className="permission-user permission-user-update">
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToProps = (storeState: IRootState) => {
  return {
    users: storeState.userManagement.users,
    permissions: storeState.permission.entities,
    permissionUserEntity: storeState.permissionUser.entity,
    loading: storeState.permissionUser.loading,
    updating: storeState.permissionUser.updating,
    updateSuccess: storeState.permissionUser.updateSuccess,
  };
};

export const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  getEntityOffset,
  reset,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PermissionUserUpdate);
