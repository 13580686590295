import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate } from 'app/config/translate-component';
import { getListAxios } from 'app/shared/util/entity-utils';
import DatePicker from 'react-datepicker';
import { useJsonToCsv } from 'react-json-csv';

import moment from 'moment';
import { APP_LOCAL_DATE_FORMAT_SERVER } from 'app/config/constants';

const ModalFilterExportPharmacyStock = props => {
  const { buttonLabel, className } = props;
  const { saveAsCsv } = useJsonToCsv();

  const [modal, setModal] = useState(false);
  const [from, setFrom] = useState(moment().subtract(30, 'days').toDate());
  const [to, setTo] = useState(moment().toDate());

  const toggle = () => setModal(!modal);

  return (
    <span>
      <Button onClick={() => toggle()} className="btn float-right" size="md" color="dark">
        <FontAwesomeIcon icon="file" />
        <span className="d-none d-md-inline">
          <Translate contentKey="generadorApp.quote.listSelectedButtons.report-controlled">Report (Controlados)</Translate>
        </span>
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <Translate contentKey="generadorApp.bills.report.filtros">Filtros</Translate>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md="4">
              <Label id="fromStart" for="quote-from_start">
                <Translate contentKey="generadorApp.quote.from_start">From (start)</Translate>
              </Label>
              <DatePicker
                onChange={value => setFrom(value)}
                id="fromStart"
                className="form-control react-datepicker"
                name="fromStart"
                dateFormat="dd/MM/yyyy"
                selected={from}
                locale="pt"
              />
            </Col>
            <Col md="4">
              <Label id="toEnd" for="quote-to_end">
                <Translate contentKey="generadorApp.quote.to_end">To (end)</Translate>
              </Label>
              <DatePicker
                onChange={value => setTo(value)}
                id="toEnd"
                className="form-control react-datepicker"
                name="toEnd"
                dateFormat="dd/MM/yyyy"
                selected={to}
                locale="pt"
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={false}
            onClick={async () => {
              const filters = {};
              if (from) {
                filters['from'] = moment(from).format(APP_LOCAL_DATE_FORMAT_SERVER);
              }
              if (to) {
                filters['to'] = moment(to).format(APP_LOCAL_DATE_FORMAT_SERVER);
              }
              const result = await getListAxios('pharmacy-stocks/special-control', filters, 0, 999999999999, '', '');
              // eslint-disable-next-line no-console
              console.info(result);
              const filename = 'reporte-farmacia-controlados',
                data = [],
                separator = ';',
                fields = {
                  paciente: 'Paciente',
                  produto: 'Produto',
                  lote: 'Lote',
                  validade: 'Validade',
                  quantidade: 'Quantidade',
                };
              if (result.data) {
                result.data.map(row => {
                  data.push({
                    paciente: row.patient ? row.patient : '',
                    produto: row.product ? row.product : '',
                    lote: row.lot ? row.lot : '',
                    validade: row.validate ? row.validate : '',
                    quantidade: row.quantity ? row.quantity : '',
                  });
                });
                saveAsCsv({ data, fields, filename, separator });
              }
            }}
            type="submit"
          >
            Aceitar
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </span>
  );
};

export default ModalFilterExportPharmacyStock;
