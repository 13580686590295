import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import { Translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity, ISimproBaseState, getUrlBack, getSimproState, getEntityFiltersURL } from './simpro.reducer';

export interface ISimproState {
  fieldsBase: ISimproBaseState;
}

export interface ISimproDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class SimproDetail extends React.Component<ISimproDetailProps, ISimproState> {
  constructor(props: Readonly<ISimproDetailProps>) {
    super(props);
    this.state = {
      ...this.state,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getSimproState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
    };
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = '_back=1' + (offset !== null ? '&offset=' + offset : '');
    Object.keys(fieldsBase).map(key => {
      url += '&' + key + '=' + fieldsBase[key];
    });
    return url;
  };

  UNSAFE_componentWillMount() {
    this.props.getEntity(this.props.match.params['id']);
  }

  renderHeader() {
    const { simproEntity } = this.props;
    const state = this.state;
    return (
      <>
        <div id="page-heading">
          {' '}
          <h2>
            <Translate contentKey="generadorApp.simpro.detail.title">Simpro</Translate>
            <small>&nbsp; {simproEntity['descricao']}</small>
          </h2>
        </div>

        <div className="pull-right">
          <Button tag={Link} to={'/simpro?' + getEntityFiltersURL(this.state.fieldsBase)} replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.simpro.btnBack">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/simpro/${simproEntity.id}/edit?+${getEntityFiltersURL(this.state.fieldsBase)}`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.simpro.btnEdit">Edit</Translate>
            </span>
          </Button>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>

          <li className="breadcrumb-item active">
            <Link to={'/simpro'}>Simpro table</Link>
          </li>
          <li className="breadcrumb-item active">Simpro table details</li>
        </ol>
      </>
    );
  }

  renderBody() {
    const { simproEntity } = this.props;
    const state = this.state;
    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            <Row className="jh-entity-details">
              <Col className="view-detail-row simpro-cdUsuario-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left simpro-cdUsuario-detail-label" md="3">
                    <dt>
                      <span id="cdUsuario">
                        <Translate contentKey="generadorApp.simpro.cdUsuario">Cd Usuario</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left simpro-cdUsuario-detail-value" md="9">
                    <dd>{simproEntity.cdUsuario}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row simpro-cdFracao-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left simpro-cdFracao-detail-label" md="3">
                    <dt>
                      <span id="cdFracao">
                        <Translate contentKey="generadorApp.simpro.cdFracao">Cd Fracao</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left simpro-cdFracao-detail-value" md="9">
                    <dd>{simproEntity.cdFracao}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row simpro-descricao-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left simpro-descricao-detail-label" md="3">
                    <dt>
                      <span id="descricao">
                        <Translate contentKey="generadorApp.simpro.descricao">Descricao</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left simpro-descricao-detail-value" md="9">
                    <dd>{simproEntity.descricao}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row simpro-vigencia-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left simpro-vigencia-detail-label" md="3">
                    <dt>
                      <span id="vigencia">
                        <Translate contentKey="generadorApp.simpro.vigencia">Vigencia</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left simpro-vigencia-detail-value" md="9">
                    <dd>{simproEntity.vigencia}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row simpro-identif-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left simpro-identif-detail-label" md="3">
                    <dt>
                      <span id="identif">
                        <Translate contentKey="generadorApp.simpro.identif">Identif</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left simpro-identif-detail-value" md="9">
                    <dd>{simproEntity.identif}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row simpro-tpEmbal-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left simpro-tpEmbal-detail-label" md="3">
                    <dt>
                      <span id="tpEmbal">
                        <Translate contentKey="generadorApp.simpro.tpEmbal">Tp Embal</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left simpro-tpEmbal-detail-value" md="9">
                    <dd>{simproEntity.tpEmbal}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row simpro-pcEmFab-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left simpro-pcEmFab-detail-label" md="3">
                    <dt>
                      <span id="pcEmFab">
                        <Translate contentKey="generadorApp.simpro.pcEmFab">Pc Em Fab</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left simpro-pcEmFab-detail-value" md="9">
                    <dd>R$ {(simproEntity.pcEmFab * 1).toFixed(2).replace('.', ',')}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row simpro-pcFrFab-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left simpro-pcFrFab-detail-label" md="3">
                    <dt>
                      <span id="pcFrFab">
                        <Translate contentKey="generadorApp.simpro.pcFrFab">Pc Fr Fab</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left simpro-pcFrFab-detail-value" md="9">
                    <dd>R$ {(simproEntity.pcFrFab * 1).toFixed(3).replace('.', ',')}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row simpro-pcEmVen-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left simpro-pcEmVen-detail-label" md="3">
                    <dt>
                      <span id="pcEmVen">
                        <Translate contentKey="generadorApp.simpro.pcEmVen">Pc Em Ven</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left simpro-pcEmVen-detail-value" md="9">
                    <dd>R$ {(simproEntity.pcEmVen * 1).toFixed(2).replace('.', ',')}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row simpro-pcFrVen-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left simpro-pcFrVen-detail-label" md="3">
                    <dt>
                      <span id="pcFrVen">
                        <Translate contentKey="generadorApp.simpro.pcFrVen">Pc Fr Ven</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left simpro-pcFrVen-detail-value" md="9">
                    <dd>R$ {(simproEntity.pcFrVen * 1).toFixed(3).replace('.', ',')}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row simpro-pcEmUsu-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left simpro-pcEmUsu-detail-label" md="3">
                    <dt>
                      <span id="pcEmUsu">
                        <Translate contentKey="generadorApp.simpro.pcEmUsu">Pc Em Usu</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left simpro-pcEmUsu-detail-value" md="9">
                    <dd>R$ {(simproEntity.pcEmUsu * 1).toFixed(2).replace('.', ',')}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row simpro-pcFrUsu-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left simpro-pcFrUsu-detail-label" md="3">
                    <dt>
                      <span id="pcFrUsu">
                        <Translate contentKey="generadorApp.simpro.pcFrUsu">Pc Fr Usu</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left simpro-pcFrUsu-detail-value" md="9">
                    <dd>R$ {(simproEntity.pcFrUsu * 1).toFixed(3).replace('.', ',')}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row simpro-tpFracao-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left simpro-tpFracao-detail-label" md="3">
                    <dt>
                      <span id="tpFracao">
                        <Translate contentKey="generadorApp.simpro.tpFracao">Tp Fracao</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left simpro-tpFracao-detail-value" md="9">
                    <dd>{simproEntity.tpFracao}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row simpro-qtdeEmbal-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left simpro-qtdeEmbal-detail-label" md="3">
                    <dt>
                      <span id="qtdeEmbal">
                        <Translate contentKey="generadorApp.simpro.qtdeEmbal">Qtde Embal</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left simpro-qtdeEmbal-detail-value" md="9">
                    <dd>{simproEntity.qtdeEmbal}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row simpro-qtdeFrac-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left simpro-qtdeFrac-detail-label" md="3">
                    <dt>
                      <span id="qtdeFrac">
                        <Translate contentKey="generadorApp.simpro.qtdeFrac">Qtde Frac</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left simpro-qtdeFrac-detail-value" md="9">
                    <dd>{simproEntity.qtdeFrac}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row simpro-percLucr-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left simpro-percLucr-detail-label" md="3">
                    <dt>
                      <span id="percLucr">
                        <Translate contentKey="generadorApp.simpro.percLucr">Perc Lucr</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left simpro-percLucr-detail-value" md="9">
                    <dd>{simproEntity.percLucr}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row simpro-tipAlt-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left simpro-tipAlt-detail-label" md="3">
                    <dt>
                      <span id="tipAlt">
                        <Translate contentKey="generadorApp.simpro.tipAlt">Tip Alt</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left simpro-tipAlt-detail-value" md="9">
                    <dd>{simproEntity.tipAlt}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row simpro-fabrica-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left simpro-fabrica-detail-label" md="3">
                    <dt>
                      <span id="fabrica">
                        <Translate contentKey="generadorApp.simpro.fabrica">Fabrica</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left simpro-fabrica-detail-value" md="9">
                    <dd>{simproEntity.fabrica}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row simpro-cdSimpro-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left simpro-cdSimpro-detail-label" md="3">
                    <dt>
                      <span id="cdSimpro">
                        <Translate contentKey="generadorApp.simpro.cdSimpro">Cd Simpro</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left simpro-cdSimpro-detail-value" md="9">
                    <dd>{simproEntity.cdSimpro}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row simpro-cdMercado-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left simpro-cdMercado-detail-label" md="3">
                    <dt>
                      <span id="cdMercado">
                        <Translate contentKey="generadorApp.simpro.cdMercado">Cd Mercado</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left simpro-cdMercado-detail-value" md="9">
                    <dd>{simproEntity.cdMercado}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row simpro-percDesc-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left simpro-percDesc-detail-label" md="3">
                    <dt>
                      <span id="percDesc">
                        <Translate contentKey="generadorApp.simpro.percDesc">Perc Desc</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left simpro-percDesc-detail-value" md="9">
                    <dd>{simproEntity.percDesc}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row simpro-ipiProduto-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left simpro-ipiProduto-detail-label" md="3">
                    <dt>
                      <span id="ipiProduto">
                        <Translate contentKey="generadorApp.simpro.ipiProduto">Ipi Produto</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left simpro-ipiProduto-detail-value" md="9">
                    <dd>{simproEntity.ipiProduto}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row simpro-registroAnvisa-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left simpro-registroAnvisa-detail-label" md="3">
                    <dt>
                      <span id="registroAnvisa">
                        <Translate contentKey="generadorApp.simpro.registroAnvisa">Registro Anvisa</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left simpro-registroAnvisa-detail-value" md="9">
                    <dd>{simproEntity.registroAnvisa}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row simpro-validadeAnvisa-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left simpro-validadeAnvisa-detail-label" md="3">
                    <dt>
                      <span id="validadeAnvisa">
                        <Translate contentKey="generadorApp.simpro.validadeAnvisa">Validade Anvisa</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left simpro-validadeAnvisa-detail-value" md="9">
                    <dd>{simproEntity.validadeAnvisa}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row simpro-cdBarra-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left simpro-cdBarra-detail-label" md="3">
                    <dt>
                      <span id="cdBarra">
                        <Translate contentKey="generadorApp.simpro.cdBarra">Cd Barra</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left simpro-cdBarra-detail-value" md="9">
                    <dd>{simproEntity.cdBarra}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row simpro-lista-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left simpro-lista-detail-label" md="3">
                    <dt>
                      <span id="lista">
                        <Translate contentKey="generadorApp.simpro.lista">Lista</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left simpro-lista-detail-value" md="9">
                    <dd>{simproEntity.lista}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row simpro-hospitalar-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left simpro-hospitalar-detail-label" md="3">
                    <dt>
                      <span id="hospitalar">
                        <Translate contentKey="generadorApp.simpro.hospitalar">Hospitalar</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left simpro-hospitalar-detail-value" md="9">
                    <dd>{simproEntity.hospitalar}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row simpro-fracionar-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left simpro-fracionar-detail-label" md="3">
                    <dt>
                      <span id="fracionar">
                        <Translate contentKey="generadorApp.simpro.fracionar">Fracionar</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left simpro-fracionar-detail-value" md="9">
                    <dd>{simproEntity.fracionar}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row simpro-cdTuss-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left simpro-cdTuss-detail-label" md="3">
                    <dt>
                      <span id="cdTuss">
                        <Translate contentKey="generadorApp.simpro.cdTuss">Cd Tuss</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left simpro-cdTuss-detail-value" md="9">
                    <dd>{simproEntity.cdTuss}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row simpro-cdClassif-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left simpro-cdClassif-detail-label" md="3">
                    <dt>
                      <span id="cdClassif">
                        <Translate contentKey="generadorApp.simpro.cdClassif">Cd Classif</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left simpro-cdClassif-detail-value" md="9">
                    <dd>{simproEntity.cdClassif}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row simpro-cdRefPro-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left simpro-cdRefPro-detail-label" md="3">
                    <dt>
                      <span id="cdRefPro">
                        <Translate contentKey="generadorApp.simpro.cdRefPro">Cd Ref Pro</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left simpro-cdRefPro-detail-value" md="9">
                    <dd>{simproEntity.cdRefPro}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row simpro-generico-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left simpro-generico-detail-label" md="3">
                    <dt>
                      <span id="generico">
                        <Translate contentKey="generadorApp.simpro.generico">Generico</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left simpro-generico-detail-value" md="9">
                    <dd>{simproEntity.generico}</dd>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { simproEntity } = this.props;
    const state = this.state;
    return (
      <div>
        <Panel className="simpro simpro-detail">
          <PanelHeader>{this.renderHeader()}</PanelHeader>

          <PanelBody>{this.renderBody()}</PanelBody>
        </Panel>
      </div>
    );
  }
}

export const mapStateToProps = ({ simpro, ...storeState }: IRootState) => ({
  simproEntity: simpro.entity,
});

export const mapDispatchToProps = {
  getEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SimproDetail);
