import React from 'react';
// import { AvGroup } from 'availity-reactstrap-validation';
import { Row, Col, Label } from 'reactstrap';
import { Translate, translate } from 'app/config/translate-component';
import SelectAsync from 'react-select/async';
import { getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';

export interface ISupplierGlosaProps {
  baseState?: any;
  baseProps?: any;
  setState?: any;
  entityBase?: any;
}

const SupplierGlosaComponentCustomize = (props: ISupplierGlosaProps) => {
  const isNew = props.entityBase.id && true;
  return (
    <Row>
      <Col md="12">
        <Label className="mt-2 label-single-line" for="client-glosa-supplier">
          <Translate contentKey="generadorApp.supplier.detail.title">Supplier</Translate>
        </Label>
      </Col>
      <Col md="12">
        <SelectAsync
          isDisabled={isNew}
          id="client-glosa-supplier"
          name={'supplier'}
          className={'css-select-control'}
          data-type-rel="many-to-one-other-side"
          value={props.baseState.supplierSelectValue ? props.baseState.supplierSelectValue : {}}
          onChange={options => props.setState({ supplierSelectValue: options })}
          defaultOptions={props.baseState.supplierStartSelectOptions ? props.baseState.supplierStartSelectOptions : []}
          loadingMessage={input => translate('selectAsync.loadingMessage')}
          noOptionsMessage={input =>
            props.baseState.supplierStartSelectOptions === undefined
              ? translate('selectAsync.loadingMessage')
              : translate('selectAsync.noOptionsMessage')
          }
          onMenuOpen={async () => {
            if (props.baseState.supplierStartSelectOptions === undefined) {
              const result = await getListAxios(
                'suppliers',
                {
                  'supplierTable.id': 'true',
                },
                0,
                100,
                'fantasyName,asc',
                'fantasyName,supplierTable.supplierTableProductService.quoteProductService.quote.id'
              );
              props.setState({
                supplierStartSelectOptions: result.data
                  ? result.data.map(p => {
                      return {
                        ...p,
                        value: p.id,
                        label: showFieldsSelectAsync(p, 'fantasyName') || '',
                      };
                    })
                  : [],
              });
            }
          }}
          loadOptions={async (inputValue, callback) => {
            const result = await getListAxios(
              'suppliers',
              {
                'supplierTable.supplierTableProductService.quoteProductService.quote.in': '1',
                'fantasyName.contains': inputValue,
              },
              0,
              100,
              'fantasyName,asc',
              'fantasyName'
            );
            callback(
              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'fantasyName') || '' })) : []
            );
          }}
        />
      </Col>
    </Row>
  );
};

const mapStateToProps = storeState => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default SupplierGlosaComponentCustomize;
