import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import { Translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity, IBrasindiceBaseState, getUrlBack, getBrasindiceState, getEntityFiltersURL } from './brasindice.reducer';

export interface IBrasindiceState {
  fieldsBase: IBrasindiceBaseState;
  dataArray: any;
}

export interface IBrasindiceDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class BrasindiceDetail extends React.Component<IBrasindiceDetailProps, IBrasindiceState> {
  constructor(props: Readonly<IBrasindiceDetailProps>) {
    super(props);
    this.state = {
      ...this.state,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getBrasindiceState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
    };
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = '_back=1' + (offset !== null ? '&offset=' + offset : '');
    Object.keys(fieldsBase).map(key => {
      url += '&' + key + '=' + fieldsBase[key];
    });
    return url;
  };

  UNSAFE_componentWillMount() {
    this.props.getEntity(this.props.match.params['id']);
  }

  renderHeader() {
    const { brasindiceEntity } = this.props;
    const state = this.state;
    return (
      <>
        <div id="page-heading">
          {' '}
          <h2>
            <Translate contentKey="generadorApp.brasindice.detail.title">Brasindice</Translate>
            <small>&nbsp; {brasindiceEntity['name']}</small>
          </h2>
        </div>

        <div className="pull-right">
          <Button tag={Link} to={'/brasindice?' + getEntityFiltersURL(this.state.fieldsBase)} replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.brasindice.btnBack">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/brasindice/${brasindiceEntity.id}/edit?+${getEntityFiltersURL(this.state.fieldsBase)}`}
            replace
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.brasindice.btnEdit">Edit</Translate>
            </span>
          </Button>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>

          <li className="breadcrumb-item active">
            <Link to={'/brasindice'}>Brasindices</Link>
          </li>
          <li className="breadcrumb-item active">Brasindices details</li>
        </ol>
      </>
    );
  }

  renderBody() {
    const { brasindiceEntity } = this.props;
    const state = this.state;
    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            <Row className="jh-entity-details">
              <Col className="view-detail-row brasindice-codeLab-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left brasindice-codeLab-detail-label" md="3">
                    <dt>
                      <span id="codeLab">
                        <Translate contentKey="generadorApp.brasindice.codeLab">Code Lab</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left brasindice-codeLab-detail-value" md="9">
                    <dd>{brasindiceEntity.codeLab}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row brasindice-nameLab-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left brasindice-nameLab-detail-label" md="3">
                    <dt>
                      <span id="nameLab">
                        <Translate contentKey="generadorApp.brasindice.nameLab">Name Lab</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left brasindice-nameLab-detail-value" md="9">
                    <dd>{brasindiceEntity.nameLab}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row brasindice-code-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left brasindice-code-detail-label" md="3">
                    <dt>
                      <span id="code">
                        <Translate contentKey="generadorApp.brasindice.code">Code</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left brasindice-code-detail-value" md="9">
                    <dd>{brasindiceEntity.code}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row brasindice-name-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left brasindice-name-detail-label" md="3">
                    <dt>
                      <span id="name">
                        <Translate contentKey="generadorApp.brasindice.name">Name</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left brasindice-name-detail-value" md="9">
                    <dd>{brasindiceEntity.name}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row brasindice-codeApre-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left brasindice-codeApre-detail-label" md="3">
                    <dt>
                      <span id="codeApre">
                        <Translate contentKey="generadorApp.brasindice.codeApre">Code Apre</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left brasindice-codeApre-detail-value" md="9">
                    <dd>{brasindiceEntity.codeApre}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row brasindice-nameApre-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left brasindice-nameApre-detail-label" md="3">
                    <dt>
                      <span id="nameApre">
                        <Translate contentKey="generadorApp.brasindice.nameApre">Name Apre</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left brasindice-nameApre-detail-value" md="9">
                    <dd>{brasindiceEntity.nameApre}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row brasindice-price-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left brasindice-price-detail-label" md="3">
                    <dt>
                      <span id="price">
                        <Translate contentKey="generadorApp.brasindice.price">Price</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left brasindice-price-detail-value" md="9">
                    <dd>R$ {(brasindiceEntity.price * 1).toFixed(2).replace('.', ',')}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row brasindice-quantFrac-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left brasindice-quantFrac-detail-label" md="3">
                    <dt>
                      <span id="quantFrac">
                        <Translate contentKey="generadorApp.brasindice.quantFrac">Quant Frac</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left brasindice-quantFrac-detail-value" md="9">
                    <dd>{brasindiceEntity.quantFrac}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row brasindice-priceType-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left brasindice-priceType-detail-label" md="3">
                    <dt>
                      <span id="priceType">
                        <Translate contentKey="generadorApp.brasindice.priceType">Price Type</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left brasindice-priceType-detail-value" md="9">
                    <dd>{brasindiceEntity.priceType}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row brasindice-valueFrac-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left brasindice-valueFrac-detail-label" md="3">
                    <dt>
                      <span id="valueFrac">
                        <Translate contentKey="generadorApp.brasindice.valueFrac">Value Frac</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left brasindice-valueFrac-detail-value" md="9">
                    <dd>{brasindiceEntity.valueFrac}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row brasindice-ipi-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left brasindice-ipi-detail-label" md="3">
                    <dt>
                      <span id="ipi">
                        <Translate contentKey="generadorApp.brasindice.ipi">Ipi</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left brasindice-ipi-detail-value" md="9">
                    <dd>{brasindiceEntity.ipi}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row brasindice-flagPort-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left brasindice-flagPort-detail-label" md="3">
                    <dt>
                      <span id="flagPort">
                        <Translate contentKey="generadorApp.brasindice.flagPort">FLAG_PORT_PIS/COFINS</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left brasindice-flagPort-detail-value" md="9">
                    <dd>{brasindiceEntity.flagPort}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row brasindice-codeEan-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left brasindice-codeEan-detail-label" md="3">
                    <dt>
                      <span id="codeEan">
                        <Translate contentKey="generadorApp.brasindice.codeEan">Code Ean</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left brasindice-codeEan-detail-value" md="9">
                    <dd>{brasindiceEntity.codeEan}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row brasindice-codeTiss-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left brasindice-codeTiss-detail-label" md="3">
                    <dt>
                      <span id="codeTiss">
                        <Translate contentKey="generadorApp.brasindice.codeTiss">Code Tiss</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left brasindice-codeTiss-detail-value" md="9">
                    <dd>{brasindiceEntity.codeTiss}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row brasindice-flagGen-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left brasindice-flagGen-detail-label" md="3">
                    <dt>
                      <span id="flagGen">
                        <Translate contentKey="generadorApp.brasindice.flagGen">Flag Gen</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left brasindice-flagGen-detail-value" md="9">
                    <dd>{brasindiceEntity.flagGen}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row brasindice-codeTuss-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left brasindice-codeTuss-detail-label" md="3">
                    <dt>
                      <span id="codeTuss">
                        <Translate contentKey="generadorApp.brasindice.codeTuss">Code Tuss</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left brasindice-codeTuss-detail-value" md="9">
                    <dd>{brasindiceEntity.codeTuss}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row brasindice-codeHier-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left brasindice-codeHier-detail-label" md="3">
                    <dt>
                      <span id="codeHier">
                        <Translate contentKey="generadorApp.brasindice.codeHier">Code Hier</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left brasindice-codeHier-detail-value" md="9">
                    <dd>{brasindiceEntity.codeHier}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row brasindice-brasindiceType-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left brasindice-brasindiceType-detail-label" md="3">
                    <dt>
                      <span id="brasindiceType">
                        <Translate contentKey="generadorApp.brasindice.brasindiceType">Brasindice Type</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left brasindice-brasindiceType-detail-value" md="9">
                    <dd>{brasindiceEntity.brasindiceType}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row brasindice-pcEmFab-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left brasindice-pcEmFab-detail-label" md="3">
                    <dt>
                      <span id="pcEmFab">
                        <Translate contentKey="generadorApp.brasindice.pcEmFab">Pc Em Fab</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left brasindice-pcEmFab-detail-value" md="9">
                    <dd>R$ {(brasindiceEntity.pcEmFab * 1).toFixed(2).replace('.', ',')}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row brasindice-pcEmVen-detail" md="6">
                <Row>
                  <Col className="view-detail-label-left brasindice-pcEmVen-detail-label" md="3">
                    <dt>
                      <span id="pcEmVen">
                        <Translate contentKey="generadorApp.brasindice.pcEmVen">Pc Em Ven</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left brasindice-pcEmVen-detail-value" md="9">
                    <dd>R$ {(brasindiceEntity.pcEmVen * 1).toFixed(2).replace('.', ',')}</dd>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { brasindiceEntity } = this.props;
    const state = this.state;
    return (
      <div>
        <Panel className="brasindice brasindice-detail">
          <PanelHeader>{this.renderHeader()}</PanelHeader>

          <PanelBody>{this.renderBody()}</PanelBody>
        </Panel>
      </div>
    );
  }
}

export const mapStateToProps = ({ brasindice, ...storeState }: IRootState) => ({
  brasindiceEntity: brasindice.entity,
});

export const mapDispatchToProps = {
  getEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(BrasindiceDetail);
