/* eslint-disable no-console */
/* eslint complexity: ["error", 300] */
import React from 'react';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextFormat } from 'react-jhipster';
import { APP_LOCAL_DATE_FORMAT, BASE_API_VERSION_PATH } from 'app/config/constants';
import { getEntityFiltersURL, getEntitiesFilter } from '../quote.reducer';
import CurrencyInput from 'react-currency-input';

import { Quote, mapStateToProps, mapDispatchToProps } from '../quote';
import { IRootState } from 'app/shared/reducers';
import { getReport } from 'app/shared/reports/jhi-reports.reducer';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SelectAsync from 'react-select/async';
import axios from 'axios';
import moment from 'moment';
import { getListAxios, prettifyXml, showFieldsSelectAsync } from 'app/shared/util/entity-utils';

import { Button, Col, Row, Table, Label, UncontrolledCollapse, CardBody, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, div } from 'availity-reactstrap-validation';
import { RenderModalSuperSelect } from 'app/shared/layout/components/RenderModalSuperSelect';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { BillingStatus } from 'app/shared/model/enumerations/billing-status.model';
import { ContasPdfReport } from 'app/entities/quote/report/contas';
import ModalFilterExportContas from './modal-filter-export-contas';
const MySwal = withReactContent(Swal);

export interface IExtendedState {
  showClearFilter?: boolean;
  idQuoteFilter?: any;
  showModalReport?: boolean;
}
export interface IExtendedProps {
  entityEmbebedPai?: any;
}

export class Bill extends Quote {
  constructor(props: any) {
    super(props);
    this.state = {
      ...this.state,
      itemsPerPage: 200,
      sort: 'accountNumber',
      order: 'desc',
    };
  }

  downloadTxtFile = (name, xml) => {
    const element = document.createElement('a');
    const file = new Blob([xml], { type: 'text/plain;charset=utf-8' });
    element.href = URL.createObjectURL(file);
    element.download = name + '.xml';
    document.body.appendChild(element);
    element.click();
  };

  renderHeader() {
    const state = this.state;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.bill.home.title">Quotes</Translate>
          </span>
          <Button id="togglerFilterQuote" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.bill.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          <div className="float-right">
            <ModalFilterExportContas setBaseState={v => this.setState(v)} />
          </div>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.bill.home.title">Quotes</Translate>
          </li>
        </ol>
      </>
    );
  }

  UNSAFE_componentWillMount() {
    super.UNSAFE_componentWillMount();
  }

  renderFilter() {
    const { patients } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <UncontrolledCollapse toggler="#togglerFilterQuote">
          <CardBody>
            <AvForm ref={el => (this.myFormRef = el)} id="form-filter" onSubmit={this.filterEntity}>
              <div className="row mt-1 ml-3 mr-3">
                <Col md="2">
                  <Row className="mr-1 mt-1">
                    <Label id="fromStart" for="quote-from_start">
                      <Translate contentKey="generadorApp.quote.detail.title">Quote</Translate>
                    </Label>
                    <CurrencyInput
                      precision={0}
                      prefix={
                        !this.state.idQuoteFilter || this.state.idQuoteFilter > 999
                          ? ''
                          : this.state.idQuoteFilter > 99
                          ? '0'
                          : this.state.idQuoteFilter > 9
                          ? '00'
                          : '000'
                      }
                      allowEmpty={true}
                      onChange={(maskedvalue, floatvalue, event) => this.setState({ idQuoteFilter: floatvalue })}
                      decimalSeparator=""
                      thousandSeparator=""
                      value={this.state.idQuoteFilter ? this.state.idQuoteFilter : ''}
                      id="quote-rh-idQuoteFilter"
                      name="idQuoteFilter"
                      className={'form-control'}
                    />
                  </Row>
                </Col>
                <Col md="2">
                  <Row className="mr-1 mt-1">
                    <Label id="fromStart" for="quote-from_start">
                      <Translate contentKey="generadorApp.quote.from_start">From (start)</Translate>
                    </Label>
                    <DatePicker
                      onChange={value => this.setState({ fromStart: value })}
                      id="fromStart"
                      className="form-control react-datepicker"
                      name="fromStart"
                      dateFormat="dd/MM/yyyy"
                      selected={this.state.fromStart ? moment(this.state.fromStart).toDate() : null}
                      locale="pt"
                    />
                  </Row>
                </Col>
                <Col md="2">
                  <Row className="mr-1 mt-1">
                    <Label id="toEnd" for="quote-to_end">
                      <Translate contentKey="generadorApp.quote.to_end">To (end)</Translate>
                    </Label>
                    <DatePicker
                      onChange={value => this.setState({ toEnd: value })}
                      id="toEnd"
                      className="form-control react-datepicker"
                      name="toEnd"
                      dateFormat="dd/MM/yyyy"
                      selected={this.state.toEnd ? moment(this.state.toEnd).toDate() : null}
                      locale="pt"
                    />
                  </Row>
                </Col>

                <Col md="3">
                  <Row className="mr-1 mt-1">
                    <div style={{ width: '100%' }}>
                      <Label for="quote-client">
                        <Translate contentKey="generadorApp.quote.client">Client</Translate>
                      </Label>
                      <SelectAsync
                        id="quote-client"
                        isMulti
                        className={'css-select-control'}
                        name={'client'}
                        cacheOptions
                        value={this.state.patientClientId}
                        onChange={options => this.setState({ patientClientId: options })}
                        defaultOptions={this.state.patientClientStartFilter ? this.state.patientClientStartFilter : []}
                        loadingMessage={input => translate('selectAsync.loadingMessage')}
                        noOptionsMessage={input =>
                          this.state.patientClientStartFilter === undefined
                            ? translate('selectAsync.loadingMessage')
                            : translate('selectAsync.noOptionsMessage')
                        }
                        onMenuOpen={async () => {
                          if (this.state.patientClientStartFilter === undefined) {
                            const result = await getListAxios('clients', {}, 0, 100, 'fantasyName,asc', 'fantasyName');
                            this.setState({
                              patientClientStartFilter: result.data
                                ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'fantasyName') }))
                                : [],
                            });
                          }
                        }}
                        loadOptions={async (inputValue, callback) => {
                          const result = await getListAxios(
                            'clients',
                            { 'fantasyName.contains': inputValue },
                            0,
                            100,
                            'fantasyName,asc',
                            'fantasyName'
                          );
                          callback(
                            result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'fantasyName') })) : []
                          );
                        }}
                      />
                      <RenderModalSuperSelect
                        baseState={this.state}
                        setState={_state => this.setState(_state)}
                        entity={'client'}
                        listEntity={'clients'}
                        template={'fantasyName;cnpj;patient.name;createdBy.email'.split(';')}
                        stateField={'patientClientId'}
                        multiple={true}
                        showFields={'fantasyName'.split(';')}
                        order={'fantasyName,asc'}
                        filtersBase={{}}
                      />
                    </div>
                  </Row>
                </Col>
                <Col md="3">
                  <Row className="mr-1 mt-1">
                    <div style={{ width: '100%' }}>
                      <Label for="quote-patient">
                        <Translate contentKey="generadorApp.quote.patient">Patient</Translate>
                      </Label>
                      <SelectAsync
                        id="quote-patient"
                        isMulti
                        className={'css-select-control'}
                        name={'patient'}
                        cacheOptions
                        value={this.state.patientId}
                        onChange={options => this.setState({ patientId: options })}
                        defaultOptions={this.state.patientStartFilter ? this.state.patientStartFilter : []}
                        loadingMessage={input => translate('selectAsync.loadingMessage')}
                        noOptionsMessage={input =>
                          this.state.patientStartFilter === undefined
                            ? translate('selectAsync.loadingMessage')
                            : translate('selectAsync.noOptionsMessage')
                        }
                        onMenuOpen={async () => {
                          if (this.state.patientStartFilter === undefined) {
                            const filtersAux = {};
                            const result = await getListAxios('patients', filtersAux, 0, 20, 'name,asc', 'name');
                            this.setState({
                              patientStartFilter: result.data
                                ? result.data.map(p => ({ value: p.id, label: p['name'] ? p.name : '' }))
                                : [],
                            });
                          }
                        }}
                        loadOptions={async (inputValue, callback) => {
                          const loadOptionsFiltersAux = {};
                          loadOptionsFiltersAux['name.contains'] = inputValue;
                          const result = await getListAxios('patients', loadOptionsFiltersAux, 0, 20, 'name,asc', 'name');
                          callback(result.data ? result.data.map(p => ({ value: p.id, label: p['name'] ? p.name : '' })) : []);
                        }}
                      />
                      <RenderModalSuperSelect
                        baseState={this.state}
                        setState={_state => this.setState(_state)}
                        entity={'patient'}
                        listEntity={'patients'}
                        template={'name;client.fantasyName;zipCode'.split(';')}
                        stateField={'patientId'}
                        multiple={true}
                        showFields={'fantasyName;cnpj;patient.name;createdBy.email'.split(';')}
                        order={'fantasyName,asc'}
                        filtersBase={{}}
                      />
                    </div>
                  </Row>
                </Col>
              </div>

              <div className="row mb-2 mr-4 justify-content-end">
                <Button color="primary" type="submit">
                  <i className="fa fa-filter" aria-hidden={'true'}></i>&nbsp;
                  <Translate contentKey="generadorApp.quote.btnFilter">Filter</Translate>
                </Button>
                &nbsp;
                <Button color="secondary" onClick={this.cancelFilters}>
                  <FontAwesomeIcon icon="trash-alt" />
                  &nbsp;
                  <Translate contentKey="generadorApp.quote.btnCleanFilter">Clean Filter</Translate>
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </UncontrolledCollapse>
      </>
    );
  }

  getEntities = () => {
    this.props.reset();
    const {
      idQuoteFilter,
      patientClientId,
      patientId,
      source,
      fromStart,
      fromEnd,
      toStart,
      toEnd,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;

    const filtersList = [
      {
        column: 'status',
        operation: 'in',
        value: ['APROVADO', 'PRORROGADO', 'INATIVOREPROVADOPRECO', 'INATIVOCONTROLEINTERNO'].join(','),
      },
    ];
    if (idQuoteFilter) filtersList.push({ column: 'id', operation: 'equals', value: idQuoteFilter });
    if (patientId !== undefined && patientId)
      filtersList.push({ column: 'patient', operation: 'in', value: patientId.map(v => v.value).join(',') });
    if (patientClientId !== undefined && patientClientId)
      filtersList.push({ column: 'patient.client.id', operation: 'in', value: patientClientId.map(v => v.value).join(',') });
    if (source) filtersList.push({ column: 'source', operation: 'contains', value: source });
    if (fromStart) filtersList.push({ column: 'from', operation: 'greaterOrEqualThan', value: moment(fromStart).format('YYYY-MM-DD') });
    if (fromEnd) filtersList.push({ column: 'from', operation: 'lessOrEqualThan', value: moment(fromEnd).format('YYYY-MM-DD') });
    if (toStart) filtersList.push({ column: 'to', operation: 'greaterOrEqualThan', value: moment(toStart).format('YYYY-MM-DD') });
    if (toEnd) filtersList.push({ column: 'to', operation: 'lessOrEqualThan', value: moment(toEnd).format('YYYY-MM-DD') });
    this.props.getEntitiesFilter(filtersList, activePage - 1, itemsPerPage, `accountNumber,desc`);
  };

  renderTable() {
    const { quoteList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    const nQuoteList = quoteList;

    return (
      <>
        {nQuoteList && nQuoteList.length > 0 ? (
          <>
            {this.state.listCheckedID.length > 0 ? (
              <div className="clasificar-all" style={{ textAlign: 'center' }}>
                {this.state.listCheckedID.length === this.state.itemsPerPage ||
                this.state.listCheckedID.length === this.props.totalItems ? (
                  <div className="alert alert-warning pull-left" style={{ color: 'black', padding: '13px 100px' }}>
                    {!this.state.isCheckedAll ? (
                      this.props.totalItems < 20 ? (
                        <>
                          <Translate
                            contentKey="generadorApp.quote.listSelectedButtons.allItensAreSelected"
                            interpolate={{ totalItems: this.props.totalItems }}
                          >
                            Os {this.props.totalItems} Quotes nesta página estão selecionadas.
                          </Translate>
                          &nbsp;
                          <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => this.handleInputAllChange()}>
                            <Translate contentKey="generadorApp.quote.listSelectedButtons.clearSelect">Limpar Seleção</Translate>
                          </span>
                        </>
                      ) : (
                        <>
                          <Translate
                            contentKey="generadorApp.quote.listSelectedButtons.allItensAreSelectedThisPage"
                            interpolate={{ itemsPerPage: this.state.itemsPerPage }}
                          >
                            Todos os {this.state.itemsPerPage} Quotes nesta página estão selecionadas.
                          </Translate>
                          &nbsp;
                          <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => this.handleInputAllChange()}>
                            <Translate
                              contentKey="generadorApp.quote.listSelectedButtons.selectAll"
                              interpolate={{ totalItems: this.props.totalItems }}
                            >
                              Selecionar os {this.props.totalItems} Quotes
                            </Translate>
                          </span>
                        </>
                      )
                    ) : (
                      <>
                        <Translate
                          contentKey="generadorApp.quote.listSelectedButtons.allItensAreSelectedAllPages"
                          interpolate={{ totalItems: this.props.totalItems }}
                        >
                          Todas os {this.props.totalItems} Quotes estão selecionadas.
                        </Translate>
                        &nbsp;
                        <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => this.handleInputAllChange()}>
                          <Translate contentKey="generadorApp.quote.listSelectedButtons.clearSelect">Limpar Seleção</Translate>
                        </span>
                      </>
                    )}
                  </div>
                ) : (
                  <></>
                )}
                <div className={'pull-right'}>
                  <Button
                    color="danger"
                    className="float-right jh-create-entity"
                    onClick={v => {
                      const listUnfinishAccount = nQuoteList
                        .filter(x => this.state.listCheckedID.includes(x.id) && !x.patient.healthPlanEnrollment)
                        .map(x => ({ accountNumber: x.accountNumber, healthPlanEnrollment: x.patient.healthPlanEnrollment }));
                      if (listUnfinishAccount.length > 0) {
                        MySwal.fire({
                          title: (
                            <p>
                              {' '}
                              {listUnfinishAccount.length === 1 ? 'A conta' : 'As contas'}{' '}
                              {listUnfinishAccount
                                .map(x => (x.accountNumber + '').padStart(4, '0'))
                                .join(', ')
                                .replace(/,(?=[^,]*$)/, ' e ')}{' '}
                              o paciente esta sem Matrícula do Plano de Saúde{' '}
                            </p>
                          ),
                        });
                      } else {
                        axios
                          .post(BASE_API_VERSION_PATH + 'quotes/generate-xml', {
                            isGuiaResumoInternacao: true,
                            createFile: false,
                            quoteIds: this.state.listCheckedID,
                          })
                          .then(data => {
                            if (data.data && data.data.xml) {
                              MySwal.fire({
                                title: <p>Guia internação, Deseja baixar o arquivo?</p>,
                                html: (
                                  <pre style={{ textAlign: 'left', height: '500px' }}>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          '<b>&lt;?xml version="1.0" encoding="ISO-8859-1"?&gt;</b> \n' +
                                          prettifyXml(data.data.xml)
                                            .split('<')
                                            .join('bb_bb&lt;')
                                            .split('>')
                                            .join('&gt;ff_ff')
                                            .split('bb_bb')
                                            .join('<b>')
                                            .split('ff_ff')
                                            .join('</b>'),
                                      }}
                                    />
                                  </pre>
                                ),
                                showConfirmButton: true,
                                width: '90%',
                                showCancelButton: true,
                                confirmButtonText:
                                  '<i class="fa fa-file-code"></i> ' + translate('generadorApp.bill.listTopButtons.printXmlInternacao'),
                                confirmButtonAriaLabel: 'Thumbs up, great!',
                                cancelButtonText: '<i class="fa fa-ban"></i> Fechar',
                                cancelButtonAriaLabel: 'Thumbs down',
                              }).then(response => {
                                if (response.isConfirmed) {
                                  axios
                                    .post(BASE_API_VERSION_PATH + 'quotes/generate-xml', {
                                      isGuiaResumoInternacao: true,
                                      createFile: true,
                                      quoteIds: this.state.listCheckedID,
                                    })
                                    .then(data1 => {
                                      this.downloadTxtFile(data1.data.name, data1.data.xml);
                                      this.getEntities();
                                    });
                                }
                              });
                            }
                          });
                      }
                      return;
                    }}
                  >
                    <FontAwesomeIcon icon="file-code" />
                    <Translate contentKey="generadorApp.bill.listTopButtons.printXmlInternacao">Guia internação</Translate>
                  </Button>
                  <Button
                    color="danger"
                    className="float-right jh-create-entity"
                    onClick={v => {
                      const listUnfinishAccount = nQuoteList
                        .filter(x => this.state.listCheckedID.includes(x.id) && !x.patient.healthPlanEnrollment)
                        .map(x => ({ accountNumber: x.accountNumber, healthPlanEnrollment: x.patient.healthPlanEnrollment }));
                      if (listUnfinishAccount.length > 0) {
                        MySwal.fire({
                          title: (
                            <p>
                              {' '}
                              {listUnfinishAccount.length === 1 ? 'A conta' : 'As contas'}{' '}
                              {listUnfinishAccount
                                .map(x => (x.accountNumber + '').padStart(4, '0'))
                                .join(', ')
                                .replace(/,(?=[^,]*$)/, ' e ')}{' '}
                              o paciente esta sem Matrícula do Plano de Saúde{' '}
                            </p>
                          ),
                        });
                      } else {
                        axios
                          .post(BASE_API_VERSION_PATH + 'quotes/generate-xml', {
                            isGuiaResumoInternacao: false,
                            createFile: false,
                            quoteIds: this.state.listCheckedID,
                          })
                          .then(data => {
                            if (data.data && data.data.xml) {
                              MySwal.fire({
                                title: <p>Guia SP/SADT, Deseja baixar o arquivo ?</p>,
                                html: (
                                  <pre style={{ textAlign: 'left', height: '500px' }}>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          '<b>&lt;?xml version="1.0" encoding="ISO-8859-1"?&gt;</b> \n' +
                                          prettifyXml(data.data.xml)
                                            .split('<')
                                            .join('bb_bb&lt;')
                                            .split('>')
                                            .join('&gt;ff_ff')
                                            .split('bb_bb')
                                            .join('<b>')
                                            .split('ff_ff')
                                            .join('</b>'),
                                      }}
                                    />
                                  </pre>
                                ),
                                showConfirmButton: true,
                                width: '90%',
                                showCancelButton: true,
                                confirmButtonText:
                                  '<i class="fa fa-file-code"></i> ' + translate('generadorApp.bill.listTopButtons.printXmlSPSADT'),
                                confirmButtonAriaLabel: 'Thumbs up, great!',
                                cancelButtonText: '<i class="fa fa-ban"></i> Fechar',
                                cancelButtonAriaLabel: 'Thumbs down',
                              }).then(response => {
                                if (response.isConfirmed) {
                                  axios
                                    .post(BASE_API_VERSION_PATH + 'quotes/generate-xml', {
                                      isGuiaResumoInternacao: false,
                                      createFile: true,
                                      quoteIds: this.state.listCheckedID,
                                    })
                                    .then(data1 => {
                                      this.downloadTxtFile(data1.data.name, data1.data.xml);
                                      this.getEntities();
                                    });
                                }
                              });
                            }
                          });
                      }
                    }}
                  >
                    <FontAwesomeIcon icon="file-code" />
                    <Translate contentKey="generadorApp.bill.listTopButtons.printXmlSPSADT">Guia SP/SADT</Translate>
                  </Button>
                </div>
              </div>
            ) : (
              <> </>
            )}
            <Table
              id="quote-table-list"
              responsive
              aria-describedby="quote-heading"
              className={'table-hover table-striped mt-4 table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className={'align-middle text-center'} style={{ width: '4%' }}>
                    {/*             <input
                      type="checkbox"
                      checked={
                        this.state.listCheckedID.length === this.state.itemsPerPage ||
                        this.state.listCheckedID.length === this.props.totalItems
                      }
                      onChange={() => this.handleInputAllThisItens()}
                    /> */}
                  </th>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.quote.accountNumber">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'patient' ? (
                    <th>
                      <Translate contentKey="generadorApp.bill.patient">Patient</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'client' ? (
                    <th>
                      <Translate contentKey="generadorApp.bill.client">Client</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'from' ? (
                    <th className="hand" onClick={sortFunction('from')}>
                      <Translate contentKey="generadorApp.bill.from">From</Translate>
                      <FontAwesomeIcon icon={state.sort === 'from' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'to' ? (
                    <th className="hand" onClick={sortFunction('to')}>
                      <Translate contentKey="generadorApp.bill.to">To</Translate>
                      <FontAwesomeIcon icon={state.sort === 'to' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}

                  <th className="hand">
                    <Translate contentKey="generadorApp.quote.billingStatus">billingStatus</Translate>
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {nQuoteList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((quote, i) => (
                    <tr key={`entity-${i}`} className={[BillingStatus.CANCELADA].includes(quote.billingStatus) ? 'alert alert-danger' : ''}>
                      <td className={'align-middle text-center'}>
                        {![BillingStatus.CANCELADA].includes(quote.billingStatus) ? (
                          <input
                            type="checkbox"
                            checked={this.state.listCheckedID.includes(quote.id)}
                            onChange={() => this.handleInputChange(quote.id)}
                          />
                        ) : (
                          <> </>
                        )}
                      </td>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.quote.accountNumber">ID</Translate>
                        </b>
                        {quote.accountNumber ? (
                          <Button tag={Link} to={`${match.url}/${quote.id}?${getEntityFiltersURL(state)}`} color="link" size="sm">
                            {(quote.accountNumber + '').padStart(4, '0')}
                          </Button>
                        ) : (
                          <> </>
                        )}
                      </td>

                      <td id="patient-cell">
                        <b className="visible-xs"> Patient </b>
                        {quote && quote.patient ? quote.patient.name : ''}
                      </td>

                      <td id="client-cell">
                        <b className="visible-xs"> Client </b>
                        {quote && quote.patient && quote.patient.client ? quote.patient.client.fantasyName : ''}
                      </td>

                      <td id="from-cell">
                        <b className="visible-xs"> From </b>
                        {quote.from ? (
                          <TextFormat type="date" value={moment(quote.from).toDate()} format={APP_LOCAL_DATE_FORMAT} />
                        ) : (
                          <> </>
                        )}
                      </td>
                      <td id="to-cell">
                        <b className="visible-xs"> To </b>
                        {quote.to ? <TextFormat type="date" value={moment(quote.to).toDate()} format={APP_LOCAL_DATE_FORMAT} /> : <> </>}
                      </td>
                      <td id="from-cell">
                        {translate('generadorApp.BillingStatus.' + (quote.billingStatus ? quote.billingStatus : 'ABERTA'))}
                      </td>
                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          <Button tag={Link} to={`${match.url}/${quote.id}?${getEntityFiltersURL(state)}`} color="primary" size="sm">
                            <FontAwesomeIcon icon={'eye'} />
                          </Button>
                          <Button tag={Link} to={`/quote/${quote.id}/quote-file`} color="grey" size="sm">
                            <FontAwesomeIcon icon="file" />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.bill.home.notFound">No Quotes found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  render() {
    return (
      <>
        {' '}
        {this.renderModal()} {super.render()}{' '}
      </>
    );
  }

  renderFooter() {
    return <> {super.renderFooter()} </>;
  }

  renderModal() {
    const report: any = this.state.report;
    if (!report || this.state.showModalReport !== true) return <> </>;

    return (
      <Modal size="xl" isOpen toggle={() => this.setState({ showModalReport: false })}>
        <ModalBody id="generadorApp.quote.pdf.generate">
          <ContasPdfReport data={report.data} from={this.state.fromStart} to={this.state.toEnd} provider={this.state.provider} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => this.setState({ showModalReport: false })}>
            <FontAwesomeIcon icon="ban" />
            &nbsp;
            <Translate contentKey="generadorApp.quote.btnCancel">Close</Translate>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
    report:
      storeState.jhiReports.reportsList && storeState.jhiReports.reportsList['quotes'] ? storeState.jhiReports.reportsList['quotes'] : [],
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
  getReport,
  getEntitiesFilter,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;

export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(Bill);
