/* eslint complexity: ["error", 500] */
/* eslint no-empty: off */
import React from 'react';
import { connect } from 'react-redux';

import { Link, RouteComponentProps } from 'react-router-dom';

import { getFilterFromSelect, getFormFromSelect, showFieldsSelectAsync, checkAnyValueFields } from 'app/shared/util/entity-utils';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Col, Label } from 'reactstrap';
import { setFileData } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import {
  IQuoteUpdateState as IBaseUpdateState,
  getEntity,
  getQuoteState,
  getUrlBack,
  updateEntity,
  createEntity,
  getEntityFiltersURL,
  getEntityOffset,
  setBlob,
  reset,
  quotePackageSetEntities,
  quotePackageGetEntities,
  quotePackageUpdateEntity,
  quotePackageReset,
  quotePackageGetEntity,
  quotePackageCreateEntity,
  quotePackageDeleteEntity,
  quoteRhSetEntities,
  quoteRhGetEntities,
  quoteRhUpdateEntity,
  quoteRhReset,
  quoteRhGetEntity,
  quoteRhCreateEntity,
  quoteRhDeleteEntity,
  quoteMedicineSetEntities,
  quoteMedicineGetEntities,
  quoteMedicineUpdateEntity,
  quoteMedicineReset,
  quoteMedicineGetEntity,
  quoteMedicineCreateEntity,
  quoteMedicineDeleteEntity,
  quoteMaterialSetEntities,
  quoteMaterialGetEntities,
  quoteMaterialUpdateEntity,
  quoteMaterialReset,
  quoteMaterialGetEntity,
  quoteMaterialCreateEntity,
  quoteMaterialDeleteEntity,
  quoteDietSetEntities,
  quoteDietGetEntities,
  quoteDietUpdateEntity,
  quoteDietReset,
  quoteDietGetEntity,
  quoteDietCreateEntity,
  quoteDietDeleteEntity,
  quoteProductServiceSetEntities,
  quoteProductServiceGetEntities,
  quoteProductServiceUpdateEntity,
  quoteProductServiceReset,
  quoteProductServiceGetEntity,
  quoteProductServiceCreateEntity,
  quoteProductServiceDeleteEntity,
} from './quote.reducer';

import { selectFieldsList } from './extended/extendSelectFields';

import SourceComponentTable from './components/SourceComponentTable';
import StatusComponentTable from './components/StatusComponentTable';
import FromComponentTable from './components/FromComponentTable';
import ToComponentTable from './components/ToComponentTable';
import LevelGroupOpenTable from './components/LevelGroupOpenTable';

import {
  mapStateToPropsExtended as mapStateToPropsQuotePackage,
  mapDispatchToPropsExtended as mapDispatchToPropsQuotePackage,
  QuotePackageExtended as QuotePackage,
} from 'app/entities/quote-package/extended/quote-package';

import {
  mapStateToPropsExtended as mapStateToPropsQuoteRh,
  mapDispatchToPropsExtended as mapDispatchToPropsQuoteRh,
  QuoteRhExtended as QuoteRh,
} from 'app/entities/quote-rh/extended/quote-rh';

import {
  mapStateToPropsExtended as mapStateToPropsQuoteMedicine,
  mapDispatchToPropsExtended as mapDispatchToPropsQuoteMedicine,
  QuoteMedicineExtended as QuoteMedicine,
} from 'app/entities/quote-medicine/extended/quote-medicine';

import {
  mapStateToPropsExtended as mapStateToPropsQuoteMaterial,
  mapDispatchToPropsExtended as mapDispatchToPropsQuoteMaterial,
  QuoteMaterialExtended as QuoteMaterial,
} from 'app/entities/quote-material/extended/quote-material';

import {
  mapStateToPropsExtended as mapStateToPropsQuoteDiet,
  mapDispatchToPropsExtended as mapDispatchToPropsQuoteDiet,
  QuoteDietExtended as QuoteDiet,
} from 'app/entities/quote-diet/extended/quote-diet';

import {
  mapStateToPropsExtended as mapStateToPropsQuoteProductService,
  mapDispatchToPropsExtended as mapDispatchToPropsQuoteProductService,
  QuoteProductServiceExtended as QuoteProductService,
} from 'app/entities/quote-product-service/extended/quote-product-service';

import { StatePropsExtended, DispatchPropsExtended, IUpdateExtendedState } from './extended/quote-update';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';
import moment from 'moment';
registerLocale('pt', pt);

export interface IQuoteUpdateProps
  extends StateProps,
    DispatchProps,
    StatePropsExtended,
    DispatchPropsExtended,
    RouteComponentProps<{ url: string }> {}
export interface IQuoteUpdateState extends IBaseUpdateState, IUpdateExtendedState {}

export class QuoteUpdate extends React.Component<IQuoteUpdateProps, IQuoteUpdateState> {
  sourceFileInput: any;

  statusFileInput: any;

  approvalDateFileInput: any;

  fromFileInput: any;

  toFileInput: any;

  accountNumberFileInput: any;

  finalAmountFileInput: any;

  billFinalAmountFileInput: any;

  typeFileInput: any;

  billingStatusFileInput: any;

  billingPasswordNumberFileInput: any;

  billingPasswordIssueFileInput: any;

  billingPasswordExpiryFileInput: any;

  billingPasswordIssuerFileInput: any;

  autorizationDataFileInput: any;

  billingHospitalizationRecordFileInput: any;

  billingHospitalizationGuideFileInput: any;

  billingGuideSPSADTFileInput: any;

  billingObservationFileInput: any;
  constructor(props: Readonly<IQuoteUpdateProps>) {
    super(props);

    this.quotePackageUpdateEntity = this.quotePackageUpdateEntity.bind(this);
    this.quotePackageGetEntities = this.quotePackageGetEntities.bind(this);
    this.quotePackageReset = this.quotePackageReset.bind(this);
    this.quotePackageGetEntity = this.quotePackageGetEntity.bind(this);
    this.quotePackageCreateEntity = this.quotePackageCreateEntity.bind(this);
    this.quotePackageDeleteEntity = this.quotePackageDeleteEntity.bind(this);
    this.quoteRhUpdateEntity = this.quoteRhUpdateEntity.bind(this);
    this.quoteRhGetEntities = this.quoteRhGetEntities.bind(this);
    this.quoteRhReset = this.quoteRhReset.bind(this);
    this.quoteRhGetEntity = this.quoteRhGetEntity.bind(this);
    this.quoteRhCreateEntity = this.quoteRhCreateEntity.bind(this);
    this.quoteRhDeleteEntity = this.quoteRhDeleteEntity.bind(this);
    this.quoteMedicineUpdateEntity = this.quoteMedicineUpdateEntity.bind(this);
    this.quoteMedicineGetEntities = this.quoteMedicineGetEntities.bind(this);
    this.quoteMedicineReset = this.quoteMedicineReset.bind(this);
    this.quoteMedicineGetEntity = this.quoteMedicineGetEntity.bind(this);
    this.quoteMedicineCreateEntity = this.quoteMedicineCreateEntity.bind(this);
    this.quoteMedicineDeleteEntity = this.quoteMedicineDeleteEntity.bind(this);
    this.quoteMaterialUpdateEntity = this.quoteMaterialUpdateEntity.bind(this);
    this.quoteMaterialGetEntities = this.quoteMaterialGetEntities.bind(this);
    this.quoteMaterialReset = this.quoteMaterialReset.bind(this);
    this.quoteMaterialGetEntity = this.quoteMaterialGetEntity.bind(this);
    this.quoteMaterialCreateEntity = this.quoteMaterialCreateEntity.bind(this);
    this.quoteMaterialDeleteEntity = this.quoteMaterialDeleteEntity.bind(this);
    this.quoteDietUpdateEntity = this.quoteDietUpdateEntity.bind(this);
    this.quoteDietGetEntities = this.quoteDietGetEntities.bind(this);
    this.quoteDietReset = this.quoteDietReset.bind(this);
    this.quoteDietGetEntity = this.quoteDietGetEntity.bind(this);
    this.quoteDietCreateEntity = this.quoteDietCreateEntity.bind(this);
    this.quoteDietDeleteEntity = this.quoteDietDeleteEntity.bind(this);
    this.quoteProductServiceUpdateEntity = this.quoteProductServiceUpdateEntity.bind(this);
    this.quoteProductServiceGetEntities = this.quoteProductServiceGetEntities.bind(this);
    this.quoteProductServiceReset = this.quoteProductServiceReset.bind(this);
    this.quoteProductServiceGetEntity = this.quoteProductServiceGetEntity.bind(this);
    this.quoteProductServiceCreateEntity = this.quoteProductServiceCreateEntity.bind(this);
    this.quoteProductServiceDeleteEntity = this.quoteProductServiceDeleteEntity.bind(this);

    this.billingObservationFileInput = React.createRef();

    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      functionEmbebed: [],

      clientGlosaSelectValue: null,

      supplierGlosaSelectValue: null,

      quoteDietSelectValue: null,

      quoteMedicineSelectValue: null,

      quoteMaterialSelectValue: null,

      quoteProductServiceSelectValue: null,

      quotePackageSelectValue: null,

      quoteRhSelectValue: null,

      quoteFileSelectValue: null,

      expectedResultSelectValue: null,

      padSelectValue: null,

      supplySelectValue: null,

      patientSelectValue: null,

      billingXmlSelectValue: null,

      clComTableSelectValue: null,

      sourceSelectValue: null,
      statusSelectValue: null,
      approvalDateSelectValue: null,
      fromSelectValue: null,
      toSelectValue: null,
      accountNumberSelectValue: null,
      finalAmountSelectValue: null,
      billFinalAmountSelectValue: null,
      typeSelectValue: null,
      billingStatusSelectValue: null,
      billingPasswordNumberSelectValue: null,
      billingPasswordIssueSelectValue: null,
      billingPasswordExpirySelectValue: null,
      billingPasswordIssuerSelectValue: null,
      autorizationDataSelectValue: null,
      billingHospitalizationRecordSelectValue: null,
      billingHospitalizationGuideSelectValue: null,
      billingGuideSPSADTSelectValue: null,
      billingObservationSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getQuoteState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
      patientName: null,
      billingXmlId: null,
      clComTableName: null,

      isNew: !this.props.match.params || !this.props.match.params['id'],
    };
  }

  quotePackageUpdateEntity(...values) {
    values[0]['quote'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['updateEntityQuotePackage'](...values);
    }
    return this.props.quotePackageUpdateEntity(...values);
  }
  quotePackageGetEntities(...values) {
    return this.props.quotePackageGetEntities(...values);
  }
  quotePackageReset(...values) {
    return this.props.quotePackageReset(...values);
  }
  quotePackageGetEntity(...values) {
    return this.props.quotePackageGetEntity(...values);
  }
  quotePackageCreateEntity(...values) {
    values[0]['quote'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['createEntityQuotePackage'](...values);
    }
    return this.props.quotePackageCreateEntity(...values);
  }
  quotePackageDeleteEntity(...values) {
    if (!this.state.isNew) {
      this.props['deleteEntityQuotePackage'](...values);
    }
    return this.props.quotePackageDeleteEntity(...values);
  }
  quoteRhUpdateEntity(...values) {
    values[0]['quote'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['updateEntityQuoteRh'](...values);
    }
    return this.props.quoteRhUpdateEntity(...values);
  }
  quoteRhGetEntities(...values) {
    return this.props.quoteRhGetEntities(...values);
  }
  quoteRhReset(...values) {
    return this.props.quoteRhReset(...values);
  }
  quoteRhGetEntity(...values) {
    return this.props.quoteRhGetEntity(...values);
  }
  quoteRhCreateEntity(...values) {
    values[0]['quote'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['createEntityQuoteRh'](...values);
    }
    return this.props.quoteRhCreateEntity(...values);
  }
  quoteRhDeleteEntity(...values) {
    if (!this.state.isNew) {
      this.props['deleteEntityQuoteRh'](...values);
    }
    return this.props.quoteRhDeleteEntity(...values);
  }
  quoteMedicineUpdateEntity(...values) {
    values[0]['quote'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['updateEntityQuoteMedicine'](...values);
    }
    return this.props.quoteMedicineUpdateEntity(...values);
  }
  quoteMedicineGetEntities(...values) {
    return this.props.quoteMedicineGetEntities(...values);
  }
  quoteMedicineReset(...values) {
    return this.props.quoteMedicineReset(...values);
  }
  quoteMedicineGetEntity(...values) {
    return this.props.quoteMedicineGetEntity(...values);
  }
  quoteMedicineCreateEntity(...values) {
    values[0]['quote'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['createEntityQuoteMedicine'](...values);
    }
    return this.props.quoteMedicineCreateEntity(...values);
  }
  quoteMedicineDeleteEntity(...values) {
    if (!this.state.isNew) {
      this.props['deleteEntityQuoteMedicine'](...values);
    }
    return this.props.quoteMedicineDeleteEntity(...values);
  }
  quoteMaterialUpdateEntity(...values) {
    values[0]['quote'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['updateEntityQuoteMaterial'](...values);
    }
    return this.props.quoteMaterialUpdateEntity(...values);
  }
  quoteMaterialGetEntities(...values) {
    return this.props.quoteMaterialGetEntities(...values);
  }
  quoteMaterialReset(...values) {
    return this.props.quoteMaterialReset(...values);
  }
  quoteMaterialGetEntity(...values) {
    return this.props.quoteMaterialGetEntity(...values);
  }
  quoteMaterialCreateEntity(...values) {
    values[0]['quote'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['createEntityQuoteMaterial'](...values);
    }
    return this.props.quoteMaterialCreateEntity(...values);
  }
  quoteMaterialDeleteEntity(...values) {
    if (!this.state.isNew) {
      this.props['deleteEntityQuoteMaterial'](...values);
    }
    return this.props.quoteMaterialDeleteEntity(...values);
  }
  quoteDietUpdateEntity(...values) {
    values[0]['quote'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['updateEntityQuoteDiet'](...values);
    }
    return this.props.quoteDietUpdateEntity(...values);
  }
  quoteDietGetEntities(...values) {
    return this.props.quoteDietGetEntities(...values);
  }
  quoteDietReset(...values) {
    return this.props.quoteDietReset(...values);
  }
  quoteDietGetEntity(...values) {
    return this.props.quoteDietGetEntity(...values);
  }
  quoteDietCreateEntity(...values) {
    values[0]['quote'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['createEntityQuoteDiet'](...values);
    }
    return this.props.quoteDietCreateEntity(...values);
  }
  quoteDietDeleteEntity(...values) {
    if (!this.state.isNew) {
      this.props['deleteEntityQuoteDiet'](...values);
    }
    return this.props.quoteDietDeleteEntity(...values);
  }
  quoteProductServiceUpdateEntity(...values) {
    values[0]['quote'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['updateEntityQuoteProductService'](...values);
    }
    return this.props.quoteProductServiceUpdateEntity(...values);
  }
  quoteProductServiceGetEntities(...values) {
    return this.props.quoteProductServiceGetEntities(...values);
  }
  quoteProductServiceReset(...values) {
    return this.props.quoteProductServiceReset(...values);
  }
  quoteProductServiceGetEntity(...values) {
    return this.props.quoteProductServiceGetEntity(...values);
  }
  quoteProductServiceCreateEntity(...values) {
    values[0]['quote'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['createEntityQuoteProductService'](...values);
    }
    return this.props.quoteProductServiceCreateEntity(...values);
  }
  quoteProductServiceDeleteEntity(...values) {
    if (!this.state.isNew) {
      this.props['deleteEntityQuoteProductService'](...values);
    }
    return this.props.quoteProductServiceDeleteEntity(...values);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateSuccess !== this.props.updateSuccess && prevProps.updateSuccess) {
      this.handleClose();
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.clientGlosas.length > 0 && this.state.clientGlosaSelectValue === null && this.props.quoteEntity.clientGlosa)
    ) {
      this.setState({
        clientGlosaSelectValue:
          this.props.quoteEntity && this.props.quoteEntity.clientGlosa
            ? this.props.quoteEntity.clientGlosa.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.supplierGlosas.length > 0 && this.state.supplierGlosaSelectValue === null && this.props.quoteEntity.supplierGlosa)
    ) {
      this.setState({
        supplierGlosaSelectValue:
          this.props.quoteEntity && this.props.quoteEntity.supplierGlosa
            ? this.props.quoteEntity.supplierGlosa.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteDiets.length > 0 && this.state.quoteDietSelectValue === null && this.props.quoteEntity.quoteDiet)
    ) {
      this.setState({ quoteDietSelectValue: this.props.quoteEntity.quoteDiet });
      this.props.quoteDietSetEntities(this.props.quoteEntity.quoteDiet);
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteMedicines.length > 0 && this.state.quoteMedicineSelectValue === null && this.props.quoteEntity.quoteMedicine)
    ) {
      this.setState({ quoteMedicineSelectValue: this.props.quoteEntity.quoteMedicine });
      this.props.quoteMedicineSetEntities(this.props.quoteEntity.quoteMedicine);
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteMaterials.length > 0 && this.state.quoteMaterialSelectValue === null && this.props.quoteEntity.quoteMaterial)
    ) {
      this.setState({ quoteMaterialSelectValue: this.props.quoteEntity.quoteMaterial });
      this.props.quoteMaterialSetEntities(this.props.quoteEntity.quoteMaterial);
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteProductServices.length > 0 &&
        this.state.quoteProductServiceSelectValue === null &&
        this.props.quoteEntity.quoteProductService)
    ) {
      this.setState({ quoteProductServiceSelectValue: this.props.quoteEntity.quoteProductService });
      this.props.quoteProductServiceSetEntities(this.props.quoteEntity.quoteProductService);
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quotePackages.length > 0 && this.state.quotePackageSelectValue === null && this.props.quoteEntity.quotePackage)
    ) {
      this.setState({ quotePackageSelectValue: this.props.quoteEntity.quotePackage });
      this.props.quotePackageSetEntities(this.props.quoteEntity.quotePackage);
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteRhs.length > 0 && this.state.quoteRhSelectValue === null && this.props.quoteEntity.quoteRh)
    ) {
      this.setState({ quoteRhSelectValue: this.props.quoteEntity.quoteRh });
      this.props.quoteRhSetEntities(this.props.quoteEntity.quoteRh);
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteFiles.length > 0 && this.state.quoteFileSelectValue === null && this.props.quoteEntity.quoteFile)
    ) {
      this.setState({
        quoteFileSelectValue:
          this.props.quoteEntity && this.props.quoteEntity.quoteFile
            ? this.props.quoteEntity.quoteFile.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.expectedResults.length > 0 && this.state.expectedResultSelectValue === null && this.props.quoteEntity.expectedResult)
    ) {
      this.setState({
        expectedResultSelectValue:
          this.props.quoteEntity && this.props.quoteEntity.expectedResult
            ? this.props.quoteEntity.expectedResult.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.pads.length > 0 && this.state.padSelectValue === null && this.props.quoteEntity.pad)
    ) {
      this.setState({
        padSelectValue:
          this.props.quoteEntity && this.props.quoteEntity.pad
            ? this.props.quoteEntity.pad.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.supplies.length > 0 && this.state.supplySelectValue === null && this.props.quoteEntity.supply)
    ) {
      this.setState({
        supplySelectValue:
          this.props.quoteEntity && this.props.quoteEntity.supply
            ? this.props.quoteEntity.supply.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.patients && this.state.patientSelectValue === null && this.props.quoteEntity.patient && this.props.quoteEntity.patient.id)
    ) {
      const p = this.props.quoteEntity && this.props.quoteEntity.patient ? this.props.quoteEntity.patient : null;
      this.setState({
        patientSelectValue: p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : null,
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.billingXmls &&
        this.state.billingXmlSelectValue === null &&
        this.props.quoteEntity.billingXml &&
        this.props.quoteEntity.billingXml.id)
    ) {
      const p = this.props.quoteEntity && this.props.quoteEntity.billingXml ? this.props.quoteEntity.billingXml : null;
      this.setState({
        billingXmlSelectValue: p ? { ...p, value: p.id, label: p['id'] ? p.id : '' } : null,
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.clComTables &&
        this.state.clComTableSelectValue === null &&
        this.props.quoteEntity.clComTable &&
        this.props.quoteEntity.clComTable.id)
    ) {
      const p = this.props.quoteEntity && this.props.quoteEntity.clComTable ? this.props.quoteEntity.clComTable : null;
      this.setState({
        clComTableSelectValue: p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : null,
      });
    }

    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.source &&
        this.state.sourceSelectValue === null &&
        this.props.quoteEntity.source !== this.state.sourceSelectValue)
    ) {
      this.setState({ sourceSelectValue: this.props.quoteEntity.source ? this.props.quoteEntity.source : null });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.status &&
        this.state.statusSelectValue === null &&
        this.props.quoteEntity.status !== this.state.statusSelectValue)
    ) {
      this.setState({ statusSelectValue: this.props.quoteEntity.status ? this.props.quoteEntity.status : null });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.approvalDate &&
        this.state.approvalDateSelectValue === null &&
        this.props.quoteEntity.approvalDate !== this.state.approvalDateSelectValue)
    ) {
      this.setState({ approvalDateSelectValue: this.props.quoteEntity.approvalDate ? this.props.quoteEntity.approvalDate : null });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.from && this.state.fromSelectValue === null && this.props.quoteEntity.from !== this.state.fromSelectValue)
    ) {
      this.setState({ fromSelectValue: this.props.quoteEntity.from ? this.props.quoteEntity.from : null });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.to && this.state.toSelectValue === null && this.props.quoteEntity.to !== this.state.toSelectValue)
    ) {
      this.setState({ toSelectValue: this.props.quoteEntity.to ? this.props.quoteEntity.to : null });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.accountNumber &&
        this.state.accountNumberSelectValue === null &&
        this.props.quoteEntity.accountNumber !== this.state.accountNumberSelectValue)
    ) {
      this.setState({ accountNumberSelectValue: this.props.quoteEntity.accountNumber ? this.props.quoteEntity.accountNumber : null });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.finalAmount &&
        this.state.finalAmountSelectValue === null &&
        this.props.quoteEntity.finalAmount !== this.state.finalAmountSelectValue)
    ) {
      this.setState({ finalAmountSelectValue: this.props.quoteEntity.finalAmount ? this.props.quoteEntity.finalAmount : null });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.billFinalAmount &&
        this.state.billFinalAmountSelectValue === null &&
        this.props.quoteEntity.billFinalAmount !== this.state.billFinalAmountSelectValue)
    ) {
      this.setState({ billFinalAmountSelectValue: this.props.quoteEntity.billFinalAmount ? this.props.quoteEntity.billFinalAmount : null });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.type && this.state.typeSelectValue === null && this.props.quoteEntity.type !== this.state.typeSelectValue)
    ) {
      this.setState({ typeSelectValue: this.props.quoteEntity.type ? this.props.quoteEntity.type : null });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.billingStatus &&
        this.state.billingStatusSelectValue === null &&
        this.props.quoteEntity.billingStatus !== this.state.billingStatusSelectValue)
    ) {
      this.setState({ billingStatusSelectValue: this.props.quoteEntity.billingStatus ? this.props.quoteEntity.billingStatus : null });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.billingPasswordNumber &&
        this.state.billingPasswordNumberSelectValue === null &&
        this.props.quoteEntity.billingPasswordNumber !== this.state.billingPasswordNumberSelectValue)
    ) {
      this.setState({
        billingPasswordNumberSelectValue: this.props.quoteEntity.billingPasswordNumber
          ? this.props.quoteEntity.billingPasswordNumber
          : null,
      });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.billingPasswordIssue &&
        this.state.billingPasswordIssueSelectValue === null &&
        this.props.quoteEntity.billingPasswordIssue !== this.state.billingPasswordIssueSelectValue)
    ) {
      this.setState({
        billingPasswordIssueSelectValue: this.props.quoteEntity.billingPasswordIssue ? this.props.quoteEntity.billingPasswordIssue : null,
      });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.billingPasswordExpiry &&
        this.state.billingPasswordExpirySelectValue === null &&
        this.props.quoteEntity.billingPasswordExpiry !== this.state.billingPasswordExpirySelectValue)
    ) {
      this.setState({
        billingPasswordExpirySelectValue: this.props.quoteEntity.billingPasswordExpiry
          ? this.props.quoteEntity.billingPasswordExpiry
          : null,
      });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.billingPasswordIssuer &&
        this.state.billingPasswordIssuerSelectValue === null &&
        this.props.quoteEntity.billingPasswordIssuer !== this.state.billingPasswordIssuerSelectValue)
    ) {
      this.setState({
        billingPasswordIssuerSelectValue: this.props.quoteEntity.billingPasswordIssuer
          ? this.props.quoteEntity.billingPasswordIssuer
          : null,
      });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.autorizationData &&
        this.state.autorizationDataSelectValue === null &&
        this.props.quoteEntity.autorizationData !== this.state.autorizationDataSelectValue)
    ) {
      this.setState({
        autorizationDataSelectValue: this.props.quoteEntity.autorizationData ? this.props.quoteEntity.autorizationData : null,
      });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.billingHospitalizationRecord &&
        this.state.billingHospitalizationRecordSelectValue === null &&
        this.props.quoteEntity.billingHospitalizationRecord !== this.state.billingHospitalizationRecordSelectValue)
    ) {
      this.setState({
        billingHospitalizationRecordSelectValue: this.props.quoteEntity.billingHospitalizationRecord
          ? this.props.quoteEntity.billingHospitalizationRecord
          : null,
      });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.billingHospitalizationGuide &&
        this.state.billingHospitalizationGuideSelectValue === null &&
        this.props.quoteEntity.billingHospitalizationGuide !== this.state.billingHospitalizationGuideSelectValue)
    ) {
      this.setState({
        billingHospitalizationGuideSelectValue: this.props.quoteEntity.billingHospitalizationGuide
          ? this.props.quoteEntity.billingHospitalizationGuide
          : null,
      });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.billingGuideSPSADT &&
        this.state.billingGuideSPSADTSelectValue === null &&
        this.props.quoteEntity.billingGuideSPSADT !== this.state.billingGuideSPSADTSelectValue)
    ) {
      this.setState({
        billingGuideSPSADTSelectValue: this.props.quoteEntity.billingGuideSPSADT ? this.props.quoteEntity.billingGuideSPSADT : null,
      });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.billingObservation &&
        this.state.billingObservationSelectValue === null &&
        this.props.quoteEntity.billingObservation !== this.state.billingObservationSelectValue)
    ) {
      this.setState({
        billingObservationSelectValue: this.props.quoteEntity.billingObservation ? this.props.quoteEntity.billingObservation : null,
      });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  UNSAFE_componentWillMount() {
    if (this.state.isNew) {
      this.props.reset();
      this.quotePackageReset();
      this.quoteRhReset();
      this.quoteMedicineReset();
      this.quoteMaterialReset();
      this.quoteDietReset();
      this.quoteProductServiceReset();
    } else {
      this.props.getEntity(this.props.match.params['id']);
    }
  }

  onBlobChange = (isAnImage, name, fileInput) => event => {
    const fileName = event.target.files[0].name;
    setFileData(event, (contentType, data) => this.props.setBlob(name, data, contentType, fileName), isAnImage);
    const varAux = {};
    varAux[name + 'SelectValue'] = fileName;
    this.setState(varAux);
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined);
  };
  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = offset !== null ? '&offset=' + offset : '';
    Object.keys(fieldsBase).map(key => {
      url += key !== 'offset' ? '&' + key + '=' + fieldsBase[key] : '';
    });
    return url;
  };
  saveEntity = (event: any, errors: any, values: any) => {
    if (errors.length === 0) {
      const { quoteEntity } = this.props;
      const _entity = {
        ...quoteEntity,
        // ...values,

        patient: getFormFromSelect(this.state.patientSelectValue, 'many-to-one'), // many-to-one - otherSide

        quotePackage: undefined,

        quoteRh: undefined,

        quoteMedicine: undefined,

        quoteMaterial: undefined,

        quoteDiet: undefined,

        quoteProductService: undefined,

        source: this.state.sourceSelectValue === null ? null : this.state.sourceSelectValue,
        status: this.state.statusSelectValue === null ? null : this.state.statusSelectValue,
        approvalDate: this.state.approvalDateSelectValue,
        from: this.state.fromSelectValue,
        to: this.state.toSelectValue,
        accountNumber: this.state.accountNumberSelectValue,
        finalAmount: this.state.finalAmountSelectValue,
        billFinalAmount: this.state.billFinalAmountSelectValue,
        type: this.state.typeSelectValue === null ? null : this.state.typeSelectValue,
        billingStatus: this.state.billingStatusSelectValue === null ? null : this.state.billingStatusSelectValue,
        billingPasswordNumber: this.state.billingPasswordNumberSelectValue,
        billingPasswordIssue: this.state.billingPasswordIssueSelectValue,
        billingPasswordExpiry: this.state.billingPasswordExpirySelectValue,
        billingPasswordIssuer: this.state.billingPasswordIssuerSelectValue,
        autorizationData: this.state.autorizationDataSelectValue,
        billingHospitalizationRecord: this.state.billingHospitalizationRecordSelectValue,
        billingHospitalizationGuide: this.state.billingHospitalizationGuideSelectValue,
        billingGuideSPSADT: this.state.billingGuideSPSADTSelectValue,
        billingObservation: this.state.billingObservationSelectValue,
      };

      const entity = _entity;

      const {
        patientId,
        patientClientId,
        source,
        status,
        fromStart,
        fromEnd,
        toStart,
        toEnd,
        extraFilters,
        activePage,
        itemsPerPage,
        sort,
        order,
      } = this.state.fieldsBase;

      const quotePackageListAllEmbed = this.props.quotePackageListAllEmbed;
      const quoteRhListAllEmbed = this.props.quoteRhListAllEmbed;
      const quoteMedicineListAllEmbed = this.props.quoteMedicineListAllEmbed;
      const quoteMaterialListAllEmbed = this.props.quoteMaterialListAllEmbed;
      const quoteDietListAllEmbed = this.props.quoteDietListAllEmbed;
      const quoteProductServiceListAllEmbed = this.props.quoteProductServiceListAllEmbed;

      if (this.state.isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              getFilterFromSelect(patientId, 'many-to-one'),
              getFilterFromSelect(patientClientId, 'many-to-one'),
              source,
              status,
              fromStart,
              fromEnd,
              toStart,
              toEnd,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
          if (idEntity) {
            quotePackageListAllEmbed.map(v => {
              v['id'] = null;
              v['quote'] = { id: idEntity };
              this.props['createEntityQuotePackage'](v);
            });
          }
          if (idEntity) {
            quoteRhListAllEmbed.map(v => {
              v['id'] = null;
              v['quote'] = { id: idEntity };
              this.props['createEntityQuoteRh'](v);
            });
          }
          if (idEntity) {
            quoteMedicineListAllEmbed.map(v => {
              v['id'] = null;
              v['quote'] = { id: idEntity };
              this.props['createEntityQuoteMedicine'](v);
            });
          }
          if (idEntity) {
            quoteMaterialListAllEmbed.map(v => {
              v['id'] = null;
              v['quote'] = { id: idEntity };
              this.props['createEntityQuoteMaterial'](v);
            });
          }
          if (idEntity) {
            quoteDietListAllEmbed.map(v => {
              v['id'] = null;
              v['quote'] = { id: idEntity };
              this.props['createEntityQuoteDiet'](v);
            });
          }
          if (idEntity) {
            quoteProductServiceListAllEmbed.map(v => {
              v['id'] = null;
              v['quote'] = { id: idEntity };
              this.props['createEntityQuoteProductService'](v);
            });
          }
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              getFilterFromSelect(patientId, 'many-to-one'),
              getFilterFromSelect(patientClientId, 'many-to-one'),
              source,
              status,
              fromStart,
              fromEnd,
              toStart,
              toEnd,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/quote?' + getEntityFiltersURL(this.state.fieldsBase));
  };

  getFullEntityFromState(v) {
    return {
      id: v.id,
      source: this.state.sourceSelectValue,
      status: this.state.statusSelectValue,
      approvalDate: this.state.approvalDateSelectValue,
      from: this.state.fromSelectValue,
      to: this.state.toSelectValue,
      accountNumber: this.state.accountNumberSelectValue,
      finalAmount: this.state.finalAmountSelectValue,
      billFinalAmount: this.state.billFinalAmountSelectValue,
      type: this.state.typeSelectValue,
      billingStatus: this.state.billingStatusSelectValue,
      billingPasswordNumber: this.state.billingPasswordNumberSelectValue,
      billingPasswordIssue: this.state.billingPasswordIssueSelectValue,
      billingPasswordExpiry: this.state.billingPasswordExpirySelectValue,
      billingPasswordIssuer: this.state.billingPasswordIssuerSelectValue,
      autorizationData: this.state.autorizationDataSelectValue,
      billingHospitalizationRecord: this.state.billingHospitalizationRecordSelectValue,
      billingHospitalizationGuide: this.state.billingHospitalizationGuideSelectValue,
      billingGuideSPSADT: this.state.billingGuideSPSADTSelectValue,
      billingObservation: this.state.billingObservationSelectValue,
      clientGlosa: this.state.clientGlosaSelectValue,
      supplierGlosa: this.state.supplierGlosaSelectValue,
      quoteDiet: this.state.quoteDietSelectValue,
      quoteMedicine: this.state.quoteMedicineSelectValue,
      quoteMaterial: this.state.quoteMaterialSelectValue,
      quoteProductService: this.state.quoteProductServiceSelectValue,
      quotePackage: this.state.quotePackageSelectValue,
      quoteRh: this.state.quoteRhSelectValue,
      quoteFile: this.state.quoteFileSelectValue,
      expectedResult: this.state.expectedResultSelectValue,
      pad: this.state.padSelectValue,
      supply: this.state.supplySelectValue,
      patient: this.state.patientSelectValue,
      billingXml: this.state.billingXmlSelectValue,
      clComTable: this.state.clComTableSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    const clientGlosaEntity = v.clientGlosa;

    const supplierGlosaEntity = v.supplierGlosa;

    const quoteDietEntity = v.quoteDiet;

    const quoteMedicineEntity = v.quoteMedicine;

    const quoteMaterialEntity = v.quoteMaterial;

    const quoteProductServiceEntity = v.quoteProductService;

    const quotePackageEntity = v.quotePackage;

    const quoteRhEntity = v.quoteRh;

    const quoteFileEntity = v.quoteFile;

    const expectedResultEntity = v.expectedResult;

    const padEntity = v.pad;

    const supplyEntity = v.supply;

    const patientEntity = v.patient;

    const billingXmlEntity = v.billingXml;

    const clComTableEntity = v.clComTable;

    return {
      sourceSelectValue: v.source,
      statusSelectValue: v.status,
      approvalDateSelectValue: v.approvalDate,
      fromSelectValue: v.from,
      toSelectValue: v.to,
      accountNumberSelectValue: v.accountNumber,
      finalAmountSelectValue: v.finalAmount,
      billFinalAmountSelectValue: v.billFinalAmount,
      typeSelectValue: v.type,
      billingStatusSelectValue: v.billingStatus,
      billingPasswordNumberSelectValue: v.billingPasswordNumber,
      billingPasswordIssueSelectValue: v.billingPasswordIssue,
      billingPasswordExpirySelectValue: v.billingPasswordExpiry,
      billingPasswordIssuerSelectValue: v.billingPasswordIssuer,
      autorizationDataSelectValue: v.autorizationData,
      billingHospitalizationRecordSelectValue: v.billingHospitalizationRecord,
      billingHospitalizationGuideSelectValue: v.billingHospitalizationGuide,
      billingGuideSPSADTSelectValue: v.billingGuideSPSADT,
      billingObservationSelectValue: v.billingObservation,
      clientGlosaSelectValue: clientGlosaEntity ? clientGlosaEntity['id'] : null,
      supplierGlosaSelectValue: supplierGlosaEntity ? supplierGlosaEntity['id'] : null,
      quoteDietSelectValue: quoteDietEntity ? quoteDietEntity['id'] : null,
      quoteMedicineSelectValue: quoteMedicineEntity ? quoteMedicineEntity['id'] : null,
      quoteMaterialSelectValue: quoteMaterialEntity ? quoteMaterialEntity['id'] : null,
      quoteProductServiceSelectValue: quoteProductServiceEntity ? quoteProductServiceEntity['id'] : null,
      quotePackageSelectValue: quotePackageEntity ? quotePackageEntity['id'] : null,
      quoteRhSelectValue: quoteRhEntity ? quoteRhEntity['id'] : null,
      quoteFileSelectValue: quoteFileEntity ? quoteFileEntity['id'] : null,
      expectedResultSelectValue: expectedResultEntity ? expectedResultEntity['id'] : null,
      padSelectValue: padEntity ? padEntity['id'] : null,
      supplySelectValue: supplyEntity ? supplyEntity['id'] : null,
      patientSelectValue: patientEntity ? patientEntity['id'] : null,
      billingXmlSelectValue: billingXmlEntity ? billingXmlEntity['id'] : null,
      clComTableSelectValue: clComTableEntity ? clComTableEntity['id'] : null,
    };
  }

  renderHeader() {
    const {
      quoteEntity,
      clientGlosas,
      supplierGlosas,
      quoteDiets,
      quoteMedicines,
      quoteMaterials,
      quoteProductServices,
      quotePackages,
      quoteRhs,
      quoteFiles,
      expectedResults,
      pads,
      supplies,
      patients,
      billingXmls,
      clComTables,
      loading,
      updating,
    } = this.props;
    const { isNew } = this.state;

    const { billingObservation } = quoteEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.quote.home.createLabel">Create a Quote</Translate>
            ) : (
              <Translate contentKey="generadorApp.quote.home.editLabel">Edit a Quote</Translate>
            )}
          </span>

          <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.quote.btnSave">Save</Translate>
          </Button>

          {!this.state.isNew ? (
            <>
              <Button
                color="primary"
                id="next-entity"
                disabled={updating}
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.patientId,
                    this.state.fieldsBase.patientClientId,
                    this.state.fieldsBase.source,
                    this.state.fieldsBase.status,
                    this.state.fieldsBase.fromStart,
                    this.state.fieldsBase.fromEnd,
                    this.state.fieldsBase.toStart,
                    this.state.fieldsBase.toEnd,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 + 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 + 1 },
                    });
                    this.props.history.push(
                      '/quote/' + myresult.value.data[0]['id'] + '/edit?' + this.getFiltersURL(this.state.fieldsBase.offset * 1 + 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.last.item', { fieldName: translate('generadorApp.quote.detail.title') }));
                  }
                }}
                className="float-right jh-create-entity"
              >
                {'>>'}
              </Button>

              <Button
                color="primary"
                id="prev-entity"
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.patientId,
                    this.state.fieldsBase.patientClientId,
                    this.state.fieldsBase.source,
                    this.state.fieldsBase.status,
                    this.state.fieldsBase.fromStart,
                    this.state.fieldsBase.fromEnd,
                    this.state.fieldsBase.toStart,
                    this.state.fieldsBase.toEnd,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 - 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 - 1 },
                    });
                    this.props.history.push(
                      '/quote/' + myresult.value.data[0]['id'] + '/edit?' + this.getFiltersURL(this.state.fieldsBase.offset * 1 - 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.first.item', { fieldName: translate('generadorApp.quote.detail.title') }));
                  }
                }}
                disabled={updating}
                className="float-right jh-create-entity"
              >
                {'<<'}
              </Button>
            </>
          ) : (
            <> </>
          )}

          <Button
            tag={Link}
            id="cancel-save"
            to={'/quote?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.quote.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={'/quote'}>
              <Translate contentKey="generadorApp.quote.home.title">Quotes</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.quote.detail.title">Quote edit</Translate>
          </li>
        </ol>
      </>
    );
  }
  renderBody() {
    const {
      quoteEntity,
      clientGlosas,
      supplierGlosas,
      quoteDiets,
      quoteMedicines,
      quoteMaterials,
      quoteProductServices,
      quotePackages,
      quoteRhs,
      quoteFiles,
      expectedResults,
      pads,
      supplies,
      patients,
      billingXmls,
      clComTables,
      loading,
      updating,
    } = this.props;
    const { isNew } = this.state;

    const { billingObservation } = quoteEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const fullEntityEmbebed = this.getFullEntityFromState(quoteEntity);

    const mapDispatchToPropsQuotePackageList: any = {};
    Object.keys(mapDispatchToPropsQuotePackage).map(v => {
      mapDispatchToPropsQuotePackageList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'QuotePackage')) {
          this.setState({ functionEmbebed: [...this.state.functionEmbebed, v + 'QuotePackage'] }, this.props[v + 'QuotePackage']);
        }
      };
    });
    const mapDispatchToPropsQuoteRhList: any = {};
    Object.keys(mapDispatchToPropsQuoteRh).map(v => {
      mapDispatchToPropsQuoteRhList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'QuoteRh')) {
          this.setState({ functionEmbebed: [...this.state.functionEmbebed, v + 'QuoteRh'] }, this.props[v + 'QuoteRh']);
        }
      };
    });
    const mapDispatchToPropsQuoteMedicineList: any = {};
    Object.keys(mapDispatchToPropsQuoteMedicine).map(v => {
      mapDispatchToPropsQuoteMedicineList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'QuoteMedicine')) {
          this.setState({ functionEmbebed: [...this.state.functionEmbebed, v + 'QuoteMedicine'] }, this.props[v + 'QuoteMedicine']);
        }
      };
    });
    const mapDispatchToPropsQuoteMaterialList: any = {};
    Object.keys(mapDispatchToPropsQuoteMaterial).map(v => {
      mapDispatchToPropsQuoteMaterialList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'QuoteMaterial')) {
          this.setState({ functionEmbebed: [...this.state.functionEmbebed, v + 'QuoteMaterial'] }, this.props[v + 'QuoteMaterial']);
        }
      };
    });
    const mapDispatchToPropsQuoteDietList: any = {};
    Object.keys(mapDispatchToPropsQuoteDiet).map(v => {
      mapDispatchToPropsQuoteDietList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'QuoteDiet')) {
          this.setState({ functionEmbebed: [...this.state.functionEmbebed, v + 'QuoteDiet'] }, this.props[v + 'QuoteDiet']);
        }
      };
    });
    const mapDispatchToPropsQuoteProductServiceList: any = {};
    Object.keys(mapDispatchToPropsQuoteProductService).map(v => {
      mapDispatchToPropsQuoteProductServiceList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'QuoteProductService')) {
          this.setState(
            { functionEmbebed: [...this.state.functionEmbebed, v + 'QuoteProductService'] },
            this.props[v + 'QuoteProductService']
          );
        }
      };
    });

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="quote-id">
                  <Translate contentKey="generadorApp.quote.field_id">ID</Translate>
                </Label>
                </Col> */}
                      <Col md="12">
                        <AvInput id="quote-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'id' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="idLabel" for="quote-id">
                              <Translate contentKey="generadorApp.quote.id">Id</Translate>
                            </Label>
                          </Col>

                          <Col md="12">
                            <AvInput
                              disabled={true}
                              value={quoteEntity.id ? quoteEntity.id : ''}
                              id="quote-id"
                              type="text"
                              name="id"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <> </>
                  )}
                  {baseFilters !== 'createdDate' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="createdDateLabel" for="quote-createdDate">
                              <Translate contentKey="generadorApp.quote.createdDate">Created Date</Translate>
                            </Label>
                          </Col>

                          <Col md="12">
                            <DatePicker
                              id="createdDate"
                              disabled={true}
                              className="form-control react-datepicker"
                              name="createdDate"
                              dateFormat="dd/MM/yyyy"
                              selected={quoteEntity.createdDate ? moment(quoteEntity.createdDate).toDate() : ''}
                              locale="pt"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <> </>
                  )}
                  {baseFilters !== 'approvalDate' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="approvalDateLabel" for="quote-approvalDate">
                              <Translate contentKey="generadorApp.quote.approvalDate">Approval Date</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <AvInput
                              disabled={true}
                              value={this.state.approvalDateSelectValue ? this.state.approvalDateSelectValue : ''}
                              id="quote-approvalDate"
                              type="text"
                              name="approvalDate"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ approvalDateSelectValue: evt.target.value })}
                      type="hidden"
                      name="approvalDate"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'SourceComponentTable' ? (
                    <Col md="3">
                      <SourceComponentTable
                        entityBase={quoteEntity}
                        baseState={this.state}
                        baseProps={this.props}
                        setState={_state => this.setState(_state)}
                      />
                    </Col>
                  ) : (
                    <> </>
                  )}
                  {baseFilters !== 'StatusComponentTable' ? (
                    <Col md="4">
                      <StatusComponentTable
                        entityBase={quoteEntity}
                        baseState={this.state}
                        baseProps={this.props}
                        setState={_state => this.setState(_state)}
                      />
                    </Col>
                  ) : (
                    <> </>
                  )}
                  {baseFilters !== 'FromComponentTable' ? (
                    <Col md="3">
                      <FromComponentTable
                        entityBase={quoteEntity}
                        baseState={this.state}
                        baseProps={this.props}
                        setState={_state => this.setState(_state)}
                      />
                    </Col>
                  ) : (
                    <> </>
                  )}
                  {baseFilters !== 'ToComponentTable' ? (
                    <Col md="3">
                      <ToComponentTable
                        entityBase={quoteEntity}
                        baseState={this.state}
                        baseProps={this.props}
                        setState={_state => this.setState(_state)}
                      />
                    </Col>
                  ) : (
                    <> </>
                  )}
                  {baseFilters !== 'patient' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="quote-patient">
                              <Translate contentKey="generadorApp.quote.patient">Patient</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <AvInput
                              disabled={true}
                              value={showFieldsSelectAsync(quoteEntity, 'patient.name') || ''}
                              id="quote-patient"
                              type="text"
                              name="patient"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="patient"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}
                  {baseFilters !== 'patient.client' ? (
                    <Col md="4">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="quote-patient.client">
                              <Translate contentKey="generadorApp.quote.patient.client">Patient</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <AvInput
                              disabled={true}
                              value={showFieldsSelectAsync(quoteEntity, 'patient.client.fantasyName') || ''}
                              id="quote-patient.client"
                              type="text"
                              name="patient.client"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="patient.client"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}
                  {baseFilters !== 'type' ? (
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="typeLabel" for="quote-type">
                              <Translate contentKey="generadorApp.quote.type">Type</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <AvInput
                              disabled={true}
                              value={this.state.typeSelectValue ? this.state.typeSelectValue : ''}
                              id="quote-type"
                              type="text"
                              name="type"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ typeSelectValue: evt.target.value })}
                      type="hidden"
                      name="type"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'LevelGroupOpenTable' ? (
                    <Col md="3">
                      {/* <LevelGroupOpenTable
                        entityBase={quoteEntity}
                        baseState={this.state}
                        baseProps={this.props}
                        setState={_state => this.setState(_state)}
                      /> */}
                    </Col>
                  ) : (
                    <> </>
                  )}
                  {checkAnyValueFields(this.state, 'typeSelectValue', 'PACOTE', true) && baseFilters !== 'quotePackage' ? (
                    <Col md="12">
                      <div className="entityEmbebedList">
                        <QuotePackage
                          entityEmbebedPai={fullEntityEmbebed}
                          history={this.props.history}
                          location={{
                            pathname: `/quote/${this.props.match.params['id']}/quote-package/`,
                            search: '',
                            hash: '',
                            state: undefined,
                            key: 'q2tbhd',
                          }}
                          match={{
                            path: `/quote/:idQuote/quote-package/:id`,
                            url: `/quote/${this.props.match.params['id']}/quote-package/`,
                            isExact: true,
                            params: {
                              idQuote: this.props.match.params['id'],
                              url: `/quote/${this.props.match.params['id']}/quote-package/`,
                            },
                          }}
                          {...this.props.mapStateToPropsQuotePackage}
                          {...mapDispatchToPropsQuotePackageList}
                          getEntityPai={(...v) => this.props.getEntity(...v)}
                          getEntities={this.quotePackageGetEntities}
                          updateEntity={this.quotePackageUpdateEntity}
                          getEntity={this.quotePackageGetEntity}
                          createEntity={this.quotePackageCreateEntity}
                          deleteEntity={this.quotePackageDeleteEntity}
                          reset={this.quotePackageReset}
                          quotePackageList={this.props.quotePackageListEmbed}
                          totalItems={this.props.quotePackageListEmbed ? this.props.quotePackageListEmbed.length : 0}
                          loading={this.props.loading}
                          quotePackageEntity={this.props.quotePackageSelected}
                        />
                      </div>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="quotePackage"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}
                  {baseFilters !== 'quoteRh' ? (
                    <Col md="12">
                      <div className="entityEmbebedList">
                        <QuoteRh
                          entityEmbebedPai={fullEntityEmbebed}
                          history={this.props.history}
                          location={{
                            pathname: `/quote/${this.props.match.params['id']}/quote-rh/`,
                            search: '',
                            hash: '',
                            state: undefined,
                            key: 'q2tbhd',
                          }}
                          match={{
                            path: `/quote/:idQuote/quote-rh/:id`,
                            url: `/quote/${this.props.match.params['id']}/quote-rh/`,
                            isExact: true,
                            params: { idQuote: this.props.match.params['id'], url: `/quote/${this.props.match.params['id']}/quote-rh/` },
                          }}
                          {...this.props.mapStateToPropsQuoteRh}
                          {...mapDispatchToPropsQuoteRhList}
                          getEntityPai={(...v) => this.props.getEntity(...v)}
                          getEntities={this.quoteRhGetEntities}
                          updateEntity={this.quoteRhUpdateEntity}
                          getEntity={this.quoteRhGetEntity}
                          createEntity={this.quoteRhCreateEntity}
                          deleteEntity={this.quoteRhDeleteEntity}
                          reset={this.quoteRhReset}
                          quoteRhList={this.props.quoteRhListEmbed}
                          totalItems={this.props.quoteRhListEmbed ? this.props.quoteRhListEmbed.length : 0}
                          loading={this.props.loading}
                          quoteRhEntity={this.props.quoteRhSelected}
                        />
                      </div>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="quoteRh"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}
                  {baseFilters !== 'quoteMedicine' ? (
                    <Col md="12">
                      <div className="entityEmbebedList">
                        <QuoteMedicine
                          entityEmbebedPai={fullEntityEmbebed}
                          history={this.props.history}
                          location={{
                            pathname: `/quote/${this.props.match.params['id']}/quote-medicine/`,
                            search: '',
                            hash: '',
                            state: undefined,
                            key: 'q2tbhd',
                          }}
                          match={{
                            path: `/quote/:idQuote/quote-medicine/:id`,
                            url: `/quote/${this.props.match.params['id']}/quote-medicine/`,
                            isExact: true,
                            params: {
                              idQuote: this.props.match.params['id'],
                              url: `/quote/${this.props.match.params['id']}/quote-medicine/`,
                            },
                          }}
                          {...this.props.mapStateToPropsQuoteMedicine}
                          {...mapDispatchToPropsQuoteMedicineList}
                          getEntityPai={(...v) => this.props.getEntity(...v)}
                          getEntities={this.quoteMedicineGetEntities}
                          updateEntity={this.quoteMedicineUpdateEntity}
                          getEntity={this.quoteMedicineGetEntity}
                          createEntity={this.quoteMedicineCreateEntity}
                          deleteEntity={this.quoteMedicineDeleteEntity}
                          reset={this.quoteMedicineReset}
                          quoteMedicineList={this.props.quoteMedicineListEmbed}
                          totalItems={this.props.quoteMedicineListEmbed ? this.props.quoteMedicineListEmbed.length : 0}
                          loading={this.props.loading}
                          quoteMedicineEntity={this.props.quoteMedicineSelected}
                        />
                      </div>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="quoteMedicine"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}
                  {baseFilters !== 'quoteMaterial' ? (
                    <Col md="12">
                      <div className="entityEmbebedList">
                        <QuoteMaterial
                          entityEmbebedPai={fullEntityEmbebed}
                          history={this.props.history}
                          location={{
                            pathname: `/quote/${this.props.match.params['id']}/quote-material/`,
                            search: '',
                            hash: '',
                            state: undefined,
                            key: 'q2tbhd',
                          }}
                          match={{
                            path: `/quote/:idQuote/quote-material/:id`,
                            url: `/quote/${this.props.match.params['id']}/quote-material/`,
                            isExact: true,
                            params: {
                              idQuote: this.props.match.params['id'],
                              url: `/quote/${this.props.match.params['id']}/quote-material/`,
                            },
                          }}
                          {...this.props.mapStateToPropsQuoteMaterial}
                          {...mapDispatchToPropsQuoteMaterialList}
                          getEntityPai={(...v) => this.props.getEntity(...v)}
                          getEntities={this.quoteMaterialGetEntities}
                          updateEntity={this.quoteMaterialUpdateEntity}
                          getEntity={this.quoteMaterialGetEntity}
                          createEntity={this.quoteMaterialCreateEntity}
                          deleteEntity={this.quoteMaterialDeleteEntity}
                          reset={this.quoteMaterialReset}
                          quoteMaterialList={this.props.quoteMaterialListEmbed}
                          totalItems={this.props.quoteMaterialListEmbed ? this.props.quoteMaterialListEmbed.length : 0}
                          loading={this.props.loading}
                          quoteMaterialEntity={this.props.quoteMaterialSelected}
                        />
                      </div>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="quoteMaterial"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}
                  {baseFilters !== 'quoteDiet' ? (
                    <Col md="12">
                      <div className="entityEmbebedList">
                        <QuoteDiet
                          entityEmbebedPai={fullEntityEmbebed}
                          history={this.props.history}
                          location={{
                            pathname: `/quote/${this.props.match.params['id']}/quote-diet/`,
                            search: '',
                            hash: '',
                            state: undefined,
                            key: 'q2tbhd',
                          }}
                          match={{
                            path: `/quote/:idQuote/quote-diet/:id`,
                            url: `/quote/${this.props.match.params['id']}/quote-diet/`,
                            isExact: true,
                            params: { idQuote: this.props.match.params['id'], url: `/quote/${this.props.match.params['id']}/quote-diet/` },
                          }}
                          {...this.props.mapStateToPropsQuoteDiet}
                          {...mapDispatchToPropsQuoteDietList}
                          getEntityPai={(...v) => this.props.getEntity(...v)}
                          getEntities={this.quoteDietGetEntities}
                          updateEntity={this.quoteDietUpdateEntity}
                          getEntity={this.quoteDietGetEntity}
                          createEntity={this.quoteDietCreateEntity}
                          deleteEntity={this.quoteDietDeleteEntity}
                          reset={this.quoteDietReset}
                          quoteDietList={this.props.quoteDietListEmbed}
                          totalItems={this.props.quoteDietListEmbed ? this.props.quoteDietListEmbed.length : 0}
                          loading={this.props.loading}
                          quoteDietEntity={this.props.quoteDietSelected}
                        />
                      </div>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="quoteDiet"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}
                  {baseFilters !== 'quoteProductService' ? (
                    <Col md="12">
                      <div className="entityEmbebedList">
                        <QuoteProductService
                          entityEmbebedPai={fullEntityEmbebed}
                          history={this.props.history}
                          location={{
                            pathname: `/quote/${this.props.match.params['id']}/quote-product-service/`,
                            search: '',
                            hash: '',
                            state: undefined,
                            key: 'q2tbhd',
                          }}
                          match={{
                            path: `/quote/:idQuote/quote-product-service/:id`,
                            url: `/quote/${this.props.match.params['id']}/quote-product-service/`,
                            isExact: true,
                            params: {
                              idQuote: this.props.match.params['id'],
                              url: `/quote/${this.props.match.params['id']}/quote-product-service/`,
                            },
                          }}
                          {...this.props.mapStateToPropsQuoteProductService}
                          {...mapDispatchToPropsQuoteProductServiceList}
                          getEntityPai={(...v) => this.props.getEntity(...v)}
                          getEntities={this.quoteProductServiceGetEntities}
                          updateEntity={this.quoteProductServiceUpdateEntity}
                          getEntity={this.quoteProductServiceGetEntity}
                          createEntity={this.quoteProductServiceCreateEntity}
                          deleteEntity={this.quoteProductServiceDeleteEntity}
                          reset={this.quoteProductServiceReset}
                          quoteProductServiceList={this.props.quoteProductServiceListEmbed}
                          totalItems={this.props.quoteProductServiceListEmbed ? this.props.quoteProductServiceListEmbed.length : 0}
                          loading={this.props.loading}
                          quoteProductServiceEntity={this.props.quoteProductServiceSelected}
                        />
                      </div>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="quoteProductService"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}{' '}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const {
      quoteEntity,
      clientGlosas,
      supplierGlosas,
      quoteDiets,
      quoteMedicines,
      quoteMaterials,
      quoteProductServices,
      quotePackages,
      quoteRhs,
      quoteFiles,
      expectedResults,
      pads,
      supplies,
      patients,
      billingXmls,
      clComTables,
      loading,
      updating,
    } = this.props;
    const { isNew } = this.state;

    const { billingObservation } = quoteEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...quoteEntity,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel className="quote quote-update">
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToProps = (storeState: IRootState) => {
  return {
    clientGlosas: storeState.clientGlosa.entities,
    supplierGlosas: storeState.supplierGlosa.entities,
    quoteDiets: storeState.quoteDiet.entities,
    quoteMedicines: storeState.quoteMedicine.entities,
    quoteMaterials: storeState.quoteMaterial.entities,
    quoteProductServices: storeState.quoteProductService.entities,
    quotePackages: storeState.quotePackage.entities,
    quoteRhs: storeState.quoteRh.entities,
    quoteFiles: storeState.quoteFile.entities,
    expectedResults: storeState.expectedResult.entities,
    pads: storeState.pad.entities,
    supplies: storeState.supply.entities,
    patients: storeState.patient.entities,
    billingXmls: storeState.billingXml.entities,
    clComTables: storeState.clComTable.entities,
    quoteEntity: storeState.quote.entity,
    loading: storeState.quote.loading,
    updating: storeState.quote.updating,
    updateSuccess: storeState.quote.updateSuccess,

    quotes: storeState.quote.entities,
    quotePackageListAllEmbed: storeState.quote.quotePackageListAllEmbed,
    quotePackageListEmbed: storeState.quote.quotePackageListEmbed,
    quotePackageSelected: storeState.quote.quotePackageSelected,
    mapStateToPropsQuotePackage: mapStateToPropsQuotePackage(storeState),

    quoteRhListAllEmbed: storeState.quote.quoteRhListAllEmbed,
    quoteRhListEmbed: storeState.quote.quoteRhListEmbed,
    quoteRhSelected: storeState.quote.quoteRhSelected,
    mapStateToPropsQuoteRh: mapStateToPropsQuoteRh(storeState),

    quoteMedicineListAllEmbed: storeState.quote.quoteMedicineListAllEmbed,
    quoteMedicineListEmbed: storeState.quote.quoteMedicineListEmbed,
    quoteMedicineSelected: storeState.quote.quoteMedicineSelected,
    mapStateToPropsQuoteMedicine: mapStateToPropsQuoteMedicine(storeState),

    quoteMaterialListAllEmbed: storeState.quote.quoteMaterialListAllEmbed,
    quoteMaterialListEmbed: storeState.quote.quoteMaterialListEmbed,
    quoteMaterialSelected: storeState.quote.quoteMaterialSelected,
    mapStateToPropsQuoteMaterial: mapStateToPropsQuoteMaterial(storeState),

    quoteDietListAllEmbed: storeState.quote.quoteDietListAllEmbed,
    quoteDietListEmbed: storeState.quote.quoteDietListEmbed,
    quoteDietSelected: storeState.quote.quoteDietSelected,
    mapStateToPropsQuoteDiet: mapStateToPropsQuoteDiet(storeState),

    quoteProductServiceListAllEmbed: storeState.quote.quoteProductServiceListAllEmbed,
    quoteProductServiceListEmbed: storeState.quote.quoteProductServiceListEmbed,
    quoteProductServiceSelected: storeState.quote.quoteProductServiceSelected,
    mapStateToPropsQuoteProductService: mapStateToPropsQuoteProductService(storeState),
  };
};

const mapDispatchToPropsQuotePackageList = {};
Object.keys(mapDispatchToPropsQuotePackage).map(v => {
  mapDispatchToPropsQuotePackageList[v + 'QuotePackage'] = mapDispatchToPropsQuotePackage[v];
});
const mapDispatchToPropsQuoteRhList = {};
Object.keys(mapDispatchToPropsQuoteRh).map(v => {
  mapDispatchToPropsQuoteRhList[v + 'QuoteRh'] = mapDispatchToPropsQuoteRh[v];
});
const mapDispatchToPropsQuoteMedicineList = {};
Object.keys(mapDispatchToPropsQuoteMedicine).map(v => {
  mapDispatchToPropsQuoteMedicineList[v + 'QuoteMedicine'] = mapDispatchToPropsQuoteMedicine[v];
});
const mapDispatchToPropsQuoteMaterialList = {};
Object.keys(mapDispatchToPropsQuoteMaterial).map(v => {
  mapDispatchToPropsQuoteMaterialList[v + 'QuoteMaterial'] = mapDispatchToPropsQuoteMaterial[v];
});
const mapDispatchToPropsQuoteDietList = {};
Object.keys(mapDispatchToPropsQuoteDiet).map(v => {
  mapDispatchToPropsQuoteDietList[v + 'QuoteDiet'] = mapDispatchToPropsQuoteDiet[v];
});
const mapDispatchToPropsQuoteProductServiceList = {};
Object.keys(mapDispatchToPropsQuoteProductService).map(v => {
  mapDispatchToPropsQuoteProductServiceList[v + 'QuoteProductService'] = mapDispatchToPropsQuoteProductService[v];
});

export const mapDispatchToProps = {
  getEntity,
  updateEntity,
  setBlob,
  createEntity,
  getEntityOffset,
  reset,

  ...mapDispatchToPropsQuotePackageList,
  quotePackageUpdateEntity,
  quotePackageGetEntities,
  quotePackageSetEntities,
  quotePackageReset,
  quotePackageGetEntity,
  quotePackageCreateEntity,
  quotePackageDeleteEntity,
  ...mapDispatchToPropsQuoteRhList,
  quoteRhUpdateEntity,
  quoteRhGetEntities,
  quoteRhSetEntities,
  quoteRhReset,
  quoteRhGetEntity,
  quoteRhCreateEntity,
  quoteRhDeleteEntity,
  ...mapDispatchToPropsQuoteMedicineList,
  quoteMedicineUpdateEntity,
  quoteMedicineGetEntities,
  quoteMedicineSetEntities,
  quoteMedicineReset,
  quoteMedicineGetEntity,
  quoteMedicineCreateEntity,
  quoteMedicineDeleteEntity,
  ...mapDispatchToPropsQuoteMaterialList,
  quoteMaterialUpdateEntity,
  quoteMaterialGetEntities,
  quoteMaterialSetEntities,
  quoteMaterialReset,
  quoteMaterialGetEntity,
  quoteMaterialCreateEntity,
  quoteMaterialDeleteEntity,
  ...mapDispatchToPropsQuoteDietList,
  quoteDietUpdateEntity,
  quoteDietGetEntities,
  quoteDietSetEntities,
  quoteDietReset,
  quoteDietGetEntity,
  quoteDietCreateEntity,
  quoteDietDeleteEntity,
  ...mapDispatchToPropsQuoteProductServiceList,
  quoteProductServiceUpdateEntity,
  quoteProductServiceGetEntities,
  quoteProductServiceSetEntities,
  quoteProductServiceReset,
  quoteProductServiceGetEntity,
  quoteProductServiceCreateEntity,
  quoteProductServiceDeleteEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(QuoteUpdate);
