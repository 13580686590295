import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { IClientGlosaItem } from 'app/shared/model/client-glosa-item.model';
import { IQuote } from 'app/shared/model/quote.model';
import { TypeGlosa } from 'app/shared/model/enumerations/type-glosa.model';
import { GlosaStatus } from 'app/shared/model/enumerations/glosa-status.model';

export interface IClientGlosa {
  id?: number;
  typeGlosa?: TypeGlosa;
  status?: GlosaStatus;
  observation?: any;
  clientGlosaItem?: IClientGlosaItem[];
  quote?: IQuote;
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  clientGlosaItem: {
    isMulti: false, // one-to-many - other-side
    entity: 'ClientGlosaItem',
    urlParameters: {
      enpoint: 'client-glosa-items',
      filters: 'clientGlosa.id.equals',
    },
  },
  quote: {
    isMulti: true, // many-to-one - other-side
    entity: 'Quote',
    urlParameters: {
      enpoint: 'quotes',
      filters: 'clientGlosa.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'clientGlosa.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'clientGlosa.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<IClientGlosa> = {};
