import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { IActivity } from 'app/shared/model/activity.model';
import { IPadCidPta } from 'app/shared/model/pad-cid-pta.model';
import { ICid } from 'app/shared/model/cid.model';

export interface ICidPta {
  id?: number;
  name?: string;
  expectedResult?: any;
  activity?: IActivity[];
  padCidPta?: IPadCidPta[];
  cid?: ICid;
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  activity: {
    isMulti: false, // one-to-many - other-side
    entity: 'Activity',
    urlParameters: {
      enpoint: 'activities',
      filters: 'cidPta.id.equals',
    },
  },
  padCidPta: {
    isMulti: false, // one-to-many - other-side
    entity: 'PadCidPta',
    urlParameters: {
      enpoint: 'pad-cid-ptas',
      filters: 'cidPta.id.equals',
    },
  },
  cid: {
    isMulti: true, // many-to-one - other-side
    entity: 'Cid',
    urlParameters: {
      enpoint: 'cids',
      filters: 'cidPta.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'cidPta.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'cidPta.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<ICidPta> = {};
