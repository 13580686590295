/* eslint complexity: ["error", 500] */
import React from 'react';

import { getFilterFromSelect, getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import { connect } from 'react-redux';
import Select from 'react-select';
import SelectAsync from 'react-select/async';
import { Link, RouteComponentProps } from 'react-router-dom';

import { Button, Col, Row, Table, Label, UncontrolledCollapse, CardBody } from 'reactstrap';
import { AvForm, div } from 'availity-reactstrap-validation';
import { JhiItemCount } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { IRootState } from 'app/shared/reducers';
import {
  updateEntity,
  deleteEntity,
  getUrlBack,
  getQuoteState,
  IQuoteBaseState,
  getEntityFiltersURL,
  getEntities,
  reset,
} from './quote.reducer';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE, IPaginationBaseState, getSortState } from 'app/shared/util/pagination.constants';

import { StatePropsExtended, DispatchPropsExtended, IExtendedState, IExtendedProps } from './extended/quote';

import { selectFieldsList } from './extended/extendSelectFields';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';
import moment from 'moment';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { RenderModalSuperSelect } from 'app/shared/layout/components/RenderModalSuperSelect';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

export interface IQuoteProps
  extends StateProps,
    DispatchProps,
    StatePropsExtended,
    DispatchPropsExtended,
    IExtendedProps,
    RouteComponentProps<{ url: string }> {}
export interface IQuoteStateAux extends IQuoteBaseState, IExtendedState {}

export interface IQuoteState extends IQuoteStateAux, IPaginationBaseState {}

export class Quote extends React.Component<IQuoteProps, IQuoteState> {
  protected myFormRef: any;

  constructor(props: IQuoteProps) {
    super(props);
    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      listCheckedID: [],
      isCheckedAll: false,
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      urlBack: getUrlBack(this.props.location),
      ...getQuoteState(this.props.location),
    };
  }

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({ listCheckedID: this.props.quoteList.map(quote => quote.id) });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({ listCheckedID: this.props.quoteList.map(quote => quote.id) });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  removeEntitySelected() {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  }

  saveEntityMany = () => {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  };

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  deleteEntity(quoteEntity) {
    MySwal.fire({
      title: <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>,
      html: (
        <Translate contentKey="generadorApp.quote.delete.question" interpolate={{ id: quoteEntity.id }}>
          Are you sure you want to delete this Quote?
        </Translate>
      ),
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#e70015',
      cancelButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="ban" />
          &nbsp; <Translate contentKey="generadorApp.quote.btnCancel">Cancel</Translate>
        </>
      ),
      confirmButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="trash" />
          &nbsp;<Translate contentKey="generadorApp.quote.btnDelete">Delete</Translate>
        </>
      ),
    }).then(async response => {
      if (response.isConfirmed) {
        await this.props.deleteEntity(quoteEntity.id, { reloadList: false });
        this.getEntities();
        MySwal.fire({
          title: <Translate contentKey="entity.delete.title.confirmed">Confirmed delete operation</Translate>,
          icon: 'success',
        });
      }
    });
  }

  cancelFilters = () => {
    this.setState(
      {
        patientId: '',
        patientClientId: '',
        source: '',
        status: '',
        fromStart: '',
        fromEnd: '',
        toStart: '',
        toEnd: '',
        extraFilters: {},
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
        activePage: 0,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();

    const {
      patientId,
      patientClientId,
      source,
      status,
      fromStart,
      fromEnd,
      toStart,
      toEnd,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;
    return this.props.getEntities(
      getFilterFromSelect(patientId, 'many-to-one'),
      getFilterFromSelect(patientClientId, 'many-to-one'),
      source,
      status,
      fromStart,
      fromEnd,
      toStart,
      toEnd,
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(',')
    );
  };

  updateEntity = entity => {
    const {
      patientId,
      patientClientId,
      source,
      status,
      fromStart,
      fromEnd,
      toStart,
      toEnd,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;
    this.props.updateEntity(entity, [
      getFilterFromSelect(patientId, 'many-to-one'),
      getFilterFromSelect(patientClientId, 'many-to-one'),
      source,
      status,
      fromStart,
      fromEnd,
      toStart,
      toEnd,
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(','),
    ]);
  };

  
  renderHeader() {
    const {
      clientGlosas,
      supplierGlosas,
      quoteDiets,
      quoteMedicines,
      quoteMaterials,
      quoteProductServices,
      quotePackages,
      quoteRhs,
      quoteFiles,
      expectedResults,
      pads,
      supplies,
      patients,
      billingXmls,
      clComTables,
      quoteList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.quote.home.title">Quotes</Translate>
          </span>
          <Button id="togglerFilterQuote" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.quote.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          &nbsp;
          <Button
            className="float-right jh-create-entity"
            tag={Link}
            to={`/quote/new?${getEntityFiltersURL(state)}`}
            color="primary"
            size="md"
          >
            <FontAwesomeIcon icon="plus" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.quote.listTopButtons.new">NewQuote</Translate>
            </span>
          </Button>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.quote.home.title">Quotes</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    const {
      clientGlosas,
      supplierGlosas,
      quoteDiets,
      quoteMedicines,
      quoteMaterials,
      quoteProductServices,
      quotePackages,
      quoteRhs,
      quoteFiles,
      expectedResults,
      pads,
      supplies,
      patients,
      billingXmls,
      clComTables,
      quoteList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <UncontrolledCollapse toggler="#togglerFilterQuote">
          <CardBody>
            <AvForm ref={el => (this.myFormRef = el)} id="form-filter" onSubmit={this.filterEntity}>
              <div className="row mt-1 ml-3 mr-3">
                {this.state.baseFilters !== 'patient' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="quote-patient">
                          <Translate contentKey="generadorApp.quote.patient">Patient</Translate>
                        </Label>
                        <SelectAsync
                          id="quote-patient"
                          isMulti
                          className={'css-select-control'}
                          name={'patient'}
                          cacheOptions
                          value={this.state.patientId}
                          onChange={options => this.setState({ patientId: options })}
                          defaultOptions={this.state.patientStartFilter ? this.state.patientStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.patientStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.patientStartFilter === undefined) {
                              const result = await getListAxios('patients', {}, 0, 100, 'name,asc', 'name');
                              this.setState({
                                patientStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios('patients', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []
                            );
                          }}
                        />
                        <RenderModalSuperSelect
                          baseState={this.state}
                          setState={_state => this.setState(_state)}
                          entity={'patient'}
                          listEntity={'patients'}
                          template={'fantasyName;cnpj;patient.name;createdBy.email'.split(';')}
                          stateField={'patientId'}
                          multiple={true}
                          showFields={'name'.split(';')}
                          order={'name,asc'}
                          filtersBase={{}}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'patient.client' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="quote-patient-client">
                          <Translate contentKey="generadorApp.quote.patient.client">Patient</Translate>
                        </Label>
                        <SelectAsync
                          id="quote-patient-client"
                          isMulti
                          className={'css-select-control'}
                          name={'patient-client'}
                          cacheOptions
                          value={this.state.patientClientId}
                          onChange={options => this.setState({ patientClientId: options })}
                          defaultOptions={this.state.patientClientStartFilter ? this.state.patientClientStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.patientClientStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.patientClientStartFilter === undefined) {
                              const result = await getListAxios('clients', {}, 0, 100, 'fantasyName,asc', 'fantasyName');
                              this.setState({
                                patientClientStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'fantasyName') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios(
                              'clients',
                              { 'fantasyName.contains': inputValue },
                              0,
                              100,
                              'fantasyName,asc',
                              'fantasyName'
                            );
                            callback(
                              result.data
                                ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'fantasyName') }))
                                : []
                            );
                          }}
                        />
                        <RenderModalSuperSelect
                          baseState={this.state}
                          setState={_state => this.setState(_state)}
                          entity={'patientClient'}
                          listEntity={'clients'}
                          template={'name;client.fantasyName;zipCode'.split(';')}
                          stateField={'patientClientId'}
                          multiple={true}
                          showFields={'fantasyName'.split(';')}
                          order={'fantasyName,asc'}
                          filtersBase={{}}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'source' ? (
                  <Col md="3" className="col-filter-quote-source">
                    <Row className="mr-1 mt-1">
                      <Label id="sourceLabel" for="quote-source">
                        <Translate contentKey="generadorApp.quote.source">Source</Translate>
                      </Label>
                      <Select
                        id="quote-source"
                        className={'css-select-control'}
                        value={
                          this.state.source
                            ? { value: this.state.source, label: translate('generadorApp.QuoteSource.' + this.state.source) }
                            : { value: '', label: translate('generadorApp.quote.source') }
                        }
                        options={[
                          { value: '', label: translate('generadorApp.quote.source') },
                          { value: 'AVALIACAO', label: translate('generadorApp.QuoteSource.AVALIACAO') },
                          { value: 'IMPLANTACAO', label: translate('generadorApp.QuoteSource.IMPLANTACAO') },
                          { value: 'CONTROLEDECUSTO', label: translate('generadorApp.QuoteSource.CONTROLEDECUSTO') },
                          { value: 'ADITIVO', label: translate('generadorApp.QuoteSource.ADITIVO') },
                          { value: 'PRORROGADO', label: translate('generadorApp.QuoteSource.PRORROGADO') },
                        ]}
                        onChange={(options: any) => this.setState({ source: options['value'] })}
                        name="source"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'status' ? (
                  <Col md="3" className="col-filter-quote-status">
                    <Row className="mr-1 mt-1">
                      <Label id="statusLabel" for="quote-status">
                        <Translate contentKey="generadorApp.quote.status">Status</Translate>
                      </Label>
                      <Select
                        id="quote-status"
                        className={'css-select-control'}
                        value={
                          this.state.status
                            ? { value: this.state.status, label: translate('generadorApp.QuoteStatus.' + this.state.status) }
                            : { value: '', label: translate('generadorApp.quote.status') }
                        }
                        options={[
                          { value: '', label: translate('generadorApp.quote.status') },
                          { value: 'ORCAMENTOABERTO', label: translate('generadorApp.QuoteStatus.ORCAMENTOABERTO') },
                          { value: 'AGUARDANDOPRECIFICACAO', label: translate('generadorApp.QuoteStatus.AGUARDANDOPRECIFICACAO') },
                          { value: 'ORCAMENTOPRECIFICADO', label: translate('generadorApp.QuoteStatus.ORCAMENTOPRECIFICADO') },
                          { value: 'AGUARDANDOAPROVACAO', label: translate('generadorApp.QuoteStatus.AGUARDANDOAPROVACAO') },
                          { value: 'PLANTAOAPROVADO', label: translate('generadorApp.QuoteStatus.PLANTAOAPROVADO') },
                          { value: 'APROVADO', label: translate('generadorApp.QuoteStatus.APROVADO') },
                          { value: 'INATIVOREPROVADOPRECO', label: translate('generadorApp.QuoteStatus.INATIVOREPROVADOPRECO') },
                          { value: 'INATIVOCONTROLEINTERNO', label: translate('generadorApp.QuoteStatus.INATIVOCONTROLEINTERNO') },
                          { value: 'CANCELADO', label: translate('generadorApp.QuoteStatus.CANCELADO') },
                        ]}
                        onChange={(options: any) => this.setState({ status: options['value'] })}
                        name="status"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'from' ? (
                  <Col md="3" className="col-filter-quote-from">
                    <Row className="mr-1 mt-1">
                      <Label id="fromStart" for="quote-from_start">
                        <Translate contentKey="generadorApp.quote.from_start">From (start)</Translate>
                      </Label>
                      <DatePicker
                        onChange={value => this.setState({ fromStart: value })}
                        id="fromStart"
                        className="form-control react-datepicker"
                        name="fromStart"
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.fromStart ? moment(this.state.fromStart).toDate() : null}
                        locale="pt"
                      />
                    </Row>
                  </Col>
                ) : null}
                {this.state.baseFilters !== 'from' ? (
                  <Col md="3" className="col-filter-quote-from-final">
                    <Row className="mr-1 mt-1">
                      <Label id="fromEnd" for="quote-from_end">
                        <Translate contentKey="generadorApp.quote.from_end">From (end)</Translate>
                      </Label>
                      <DatePicker
                        onChange={value => this.setState({ fromEnd: value })}
                        id="fromEnd"
                        className="form-control react-datepicker"
                        name="fromEnd"
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.fromEnd ? moment(this.state.fromEnd).toDate() : null}
                        locale="pt"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'to' ? (
                  <Col md="3" className="col-filter-quote-to">
                    <Row className="mr-1 mt-1">
                      <Label id="toStart" for="quote-to_start">
                        <Translate contentKey="generadorApp.quote.to_start">To (start)</Translate>
                      </Label>
                      <DatePicker
                        onChange={value => this.setState({ toStart: value })}
                        id="toStart"
                        className="form-control react-datepicker"
                        name="toStart"
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.toStart ? moment(this.state.toStart).toDate() : null}
                        locale="pt"
                      />
                    </Row>
                  </Col>
                ) : null}
                {this.state.baseFilters !== 'to' ? (
                  <Col md="3" className="col-filter-quote-to-final">
                    <Row className="mr-1 mt-1">
                      <Label id="toEnd" for="quote-to_end">
                        <Translate contentKey="generadorApp.quote.to_end">To (end)</Translate>
                      </Label>
                      <DatePicker
                        onChange={value => this.setState({ toEnd: value })}
                        id="toEnd"
                        className="form-control react-datepicker"
                        name="toEnd"
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.toEnd ? moment(this.state.toEnd).toDate() : null}
                        locale="pt"
                      />
                    </Row>
                  </Col>
                ) : null}
              </div>

              <div className="row mb-2 mr-4 justify-content-end">
                <Button color="primary" type="submit">
                  <i className="fa fa-filter" aria-hidden={'true'}></i>&nbsp;
                  <Translate contentKey="generadorApp.quote.btnFilter">Filter</Translate>
                </Button>
                &nbsp;
                <Button color="secondary" onClick={this.cancelFilters}>
                  <FontAwesomeIcon icon="trash-alt" />
                  &nbsp;
                  <Translate contentKey="generadorApp.quote.btnCleanFilter">Clean Filter</Translate>
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </UncontrolledCollapse>
      </>
    );
  }

  renderTable() {
    const {
      clientGlosas,
      supplierGlosas,
      quoteDiets,
      quoteMedicines,
      quoteMaterials,
      quoteProductServices,
      quotePackages,
      quoteRhs,
      quoteFiles,
      expectedResults,
      pads,
      supplies,
      patients,
      billingXmls,
      clComTables,
      quoteList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {quoteList && quoteList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null).length > 0 ? (
          <>
            {this.state.listCheckedID.length > 0 ? (
              <div className="clasificar-all" style={{ textAlign: 'center' }}>
                {this.state.listCheckedID.length === this.state.itemsPerPage ||
                this.state.listCheckedID.length === this.props.totalItems ? (
                  <div className="alert alert-warning pull-left" style={{ color: 'black', padding: '13px 100px' }}>
                    {!this.state.isCheckedAll ? (
                      this.props.totalItems < 20 ? (
                        <>
                          <Translate
                            contentKey="generadorApp.quote.listSelectedButtons.allItensAreSelected"
                            interpolate={{ totalItems: this.props.totalItems }}
                          >
                            Os {this.props.totalItems} Quotes nesta página estão selecionadas.
                          </Translate>
                          &nbsp;
                          <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => this.handleInputAllChange()}>
                            <Translate contentKey="generadorApp.quote.listSelectedButtons.clearSelect">Limpar Seleção</Translate>
                          </span>
                        </>
                      ) : (
                        <>
                          <Translate
                            contentKey="generadorApp.quote.listSelectedButtons.allItensAreSelectedThisPage"
                            interpolate={{ itemsPerPage: this.state.itemsPerPage }}
                          >
                            Todos os {this.state.itemsPerPage} Quotes nesta página estão selecionadas.
                          </Translate>
                          &nbsp;
                          <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => this.handleInputAllChange()}>
                            <Translate
                              contentKey="generadorApp.quote.listSelectedButtons.selectAll"
                              interpolate={{ totalItems: this.props.totalItems }}
                            >
                              Selecionar os {this.props.totalItems} Quotes
                            </Translate>
                          </span>
                        </>
                      )
                    ) : (
                      <>
                        <Translate
                          contentKey="generadorApp.quote.listSelectedButtons.allItensAreSelectedAllPages"
                          interpolate={{ totalItems: this.props.totalItems }}
                        >
                          Todas os {this.props.totalItems} Quotes estão selecionadas.
                        </Translate>
                        &nbsp;
                        <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => this.handleInputAllChange()}>
                          <Translate contentKey="generadorApp.quote.listSelectedButtons.clearSelect">Limpar Seleção</Translate>
                        </span>
                      </>
                    )}
                  </div>
                ) : (
                  <></>
                )}
                <div className={'pull-right'}>
                  <Button className="float-right jh-create-entity" onClick={() => this.removeEntitySelected()} color="primary" size="md">
                    <FontAwesomeIcon icon="plus" />{' '}
                    <span className="d-none d-md-inline">
                      <Translate contentKey="generadorApp.quote.listSelectedButtons.extend">Extension</Translate>
                    </span>
                  </Button>
                  <Button className="float-right jh-create-entity" onClick={() => this.removeEntitySelected()} color="purple" size="md">
                    <FontAwesomeIcon icon="print" />{' '}
                    <span className="d-none d-md-inline">
                      <Translate contentKey="generadorApp.quote.listSelectedButtons.print">Print</Translate>
                    </span>
                  </Button>
                  <Button className="float-right jh-create-entity" onClick={() => this.removeEntitySelected()} color="grey" size="md">
                    <FontAwesomeIcon icon="file" />{' '}
                    <span className="d-none d-md-inline">
                      <Translate contentKey="generadorApp.quote.listSelectedButtons.pdf">SavePDF</Translate>
                    </span>
                  </Button>
                </div>
              </div>
            ) : (
              <> </>
            )}
            <Table
              id="quote-table-list"
              responsive
              aria-describedby="quote-heading"
              className={'table-hover table-striped mt-4 table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className={'align-middle text-center'} style={{ width: '4%' }}>
                    <input
                      type="checkbox"
                      checked={
                        this.state.listCheckedID.length === this.state.itemsPerPage ||
                        this.state.listCheckedID.length === this.props.totalItems
                      }
                      onChange={() => this.handleInputAllThisItens()}
                    />
                  </th>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.quote.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'patient.client.fantasyName' ? (
                    <th>
                      <Translate contentKey="generadorApp.quote.patient.client.fantasyName">Patient</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'patient' ? (
                    <th>
                      <Translate contentKey="generadorApp.quote.patient">Patient</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'from' ? (
                    <th className="hand" onClick={sortFunction('from')}>
                      <Translate contentKey="generadorApp.quote.from">From</Translate>
                      <FontAwesomeIcon icon={state.sort === 'from' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'to' ? (
                    <th className="hand" onClick={sortFunction('to')}>
                      <Translate contentKey="generadorApp.quote.to">To</Translate>
                      <FontAwesomeIcon icon={state.sort === 'to' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'source' ? (
                    <th className="hand" onClick={sortFunction('source')}>
                      <Translate contentKey="generadorApp.quote.source">Source</Translate>
                      <FontAwesomeIcon icon={state.sort === 'source' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'status' ? (
                    <th className="hand" onClick={sortFunction('status')}>
                      <Translate contentKey="generadorApp.quote.status">Status</Translate>
                      <FontAwesomeIcon icon={state.sort === 'status' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'finalAmount' ? (
                    <th className="hand" onClick={sortFunction('finalAmount')}>
                      <Translate contentKey="generadorApp.quote.finalAmount">Final Amount</Translate>
                      <FontAwesomeIcon icon={state.sort === 'finalAmount' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'billFinalAmount' ? (
                    <th className="hand" onClick={sortFunction('billFinalAmount')}>
                      <Translate contentKey="generadorApp.quote.billFinalAmount">Bill final amount</Translate>
                      <FontAwesomeIcon
                        icon={state.sort === 'billFinalAmount' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'}
                      />
                    </th>
                  ) : null}

                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {quoteList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((quote, i) => (
                    <tr key={`entity-${i}`}>
                      <td className={'align-middle text-center'}>
                        <input
                          type="checkbox"
                          checked={this.state.listCheckedID.includes(quote.id)}
                          onChange={() => this.handleInputChange(quote.id)}
                        />
                      </td>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.quote.field_id">ID</Translate>
                        </b>
                        {quote.id}
                      </td>

                      {state.baseFilters !== 'patient.client' ? (
                        <td id="patient-client-cell">
                          <b className="visible-xs"> Patient </b>
                          {showFieldsSelectAsync(quote, 'patient.client.fantasyName', null).map((v, ikey) => (
                            <span key={ikey}> {v} </span>
                          ))}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'patient' ? (
                        <td id="patient-cell">
                          <b className="visible-xs"> Patient </b>
                          {showFieldsSelectAsync(quote, 'patient.name', null).map((v, ikey) => (
                            <span key={ikey}> {v} </span>
                          ))}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'from' ? (
                        <td id="from-cell">
                          <b className="visible-xs"> From </b>
                          {quote.from ? moment(quote.from).format(APP_LOCAL_DATE_FORMAT) : ''}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'to' ? (
                        <td id="to-cell">
                          <b className="visible-xs"> To </b>
                          {quote.to ? moment(quote.to).format(APP_LOCAL_DATE_FORMAT) : ''}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'source' ? (
                        <td id="source-cell">
                          <b className="visible-xs"> Source </b>
                          {quote.source ? <Translate contentKey={`generadorApp.QuoteSource.${quote.source}`} /> : <> </>}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'status' ? (
                        <td id="status-cell">
                          <b className="visible-xs"> Status </b>
                          {quote.status ? <Translate contentKey={`generadorApp.QuoteStatus.${quote.status}`} /> : <> </>}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'finalAmount' ? (
                        <td id="finalAmount-cell">
                          <b className="visible-xs"> Final Amount </b>
                          R$ {(quote.finalAmount * 1).toFixed(2).replace('.', ',')}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'billFinalAmount' ? (
                        <td id="billFinalAmount-cell">
                          <b className="visible-xs"> Bill final amount </b>

                          {quote.billFinalAmount}
                        </td>
                      ) : null}

                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          <Button tag={Link} to={`/quote/${quote.id}/quote-file`} color="grey" size="sm">
                            <FontAwesomeIcon icon="file" />
                          </Button>
                          <Button
                            tag={Link}
                            to={`${match.url}/${quote.id}/edit?${getEntityFiltersURL(
                              state,
                              this.state.itemsPerPage * (this.state.activePage - 1) + i
                            )}`}
                            color="primary"
                            size="sm"
                          >
                            <FontAwesomeIcon icon="eye" />
                          </Button>
                          <Button onClick={() => this.deleteEntity(quote)} color="danger" size="sm">
                            <FontAwesomeIcon icon="ban" />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.quote.home.notFound">No Quotes found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const {
      clientGlosas,
      supplierGlosas,
      quoteDiets,
      quoteMedicines,
      quoteMaterials,
      quoteProductServices,
      quotePackages,
      quoteRhs,
      quoteFiles,
      expectedResults,
      pads,
      supplies,
      patients,
      billingXmls,
      clComTables,
      quoteList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={quoteList && quoteList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const {
      clientGlosas,
      supplierGlosas,
      quoteDiets,
      quoteMedicines,
      quoteMaterials,
      quoteProductServices,
      quotePackages,
      quoteRhs,
      quoteFiles,
      expectedResults,
      pads,
      supplies,
      patients,
      billingXmls,
      clComTables,
      quoteList,
      match,
      totalItems,
    } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel className="quote quote-list-table">
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToProps = ({ ...storeState }: IRootState) => {
  return {
    clientGlosas: storeState.clientGlosa.entities,
    supplierGlosas: storeState.supplierGlosa.entities,
    quoteDiets: storeState.quoteDiet.entities,
    quoteMedicines: storeState.quoteMedicine.entities,
    quoteMaterials: storeState.quoteMaterial.entities,
    quoteProductServices: storeState.quoteProductService.entities,
    quotePackages: storeState.quotePackage.entities,
    quoteRhs: storeState.quoteRh.entities,
    quoteFiles: storeState.quoteFile.entities,
    expectedResults: storeState.expectedResult.entities,
    pads: storeState.pad.entities,
    supplies: storeState.supply.entities,
    patients: storeState.patient.entities,
    billingXmls: storeState.billingXml.entities,
    clComTables: storeState.clComTable.entities,
    quoteList: storeState.quote.entities,
    totalItems: storeState.quote.totalItems,
    userAccount: storeState.authentication.account,
    loading: storeState.quote.loading,
  };
};

export const mapDispatchToProps = {
  getEntities,

  deleteEntity,
  updateEntity,
  reset,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Quote);
