/* eslint complexity: ["error", 500] */
import React from 'react';

import { getFilterFromSelect, getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import { connect } from 'react-redux';
import Select from 'react-select';
import SelectAsync from 'react-select/async';
import { Link, RouteComponentProps } from 'react-router-dom';

import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { Button, Col, Row, Table, Label, UncontrolledCollapse, CardBody } from 'reactstrap';
import { AvForm, div } from 'availity-reactstrap-validation';
import { JhiItemCount } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { IRootState } from 'app/shared/reducers';
import {
  updateEntity,
  deleteEntity,
  getUrlBack,
  getPharmacyInventoryState,
  IPharmacyInventoryBaseState,
  getEntityFiltersURL,
  getEntities,
  reset,
} from './pharmacy-inventory.reducer';

import { APP_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE, IPaginationBaseState, getSortState } from 'app/shared/util/pagination.constants';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';
import moment from 'moment';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

export interface IPharmacyInventoryProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}
export type IPharmacyInventoryStateAux = IPharmacyInventoryBaseState;

export interface IPharmacyInventoryState extends IPharmacyInventoryStateAux, IPaginationBaseState {}

export class PharmacyInventory extends React.Component<IPharmacyInventoryProps, IPharmacyInventoryState> {
  protected myFormRef: any;

  constructor(props: IPharmacyInventoryProps) {
    super(props);
    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      listCheckedID: [],
      isCheckedAll: false,
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      urlBack: getUrlBack(this.props.location),
      ...getPharmacyInventoryState(this.props.location),
    };
  }

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({ listCheckedID: this.props.pharmacyInventoryList.map(pharmacyInventory => pharmacyInventory.id) });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({ listCheckedID: this.props.pharmacyInventoryList.map(pharmacyInventory => pharmacyInventory.id) });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  removeEntitySelected() {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  }

  saveEntityMany = () => {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  };

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  deleteEntity(pharmacyInventoryEntity) {
    MySwal.fire({
      title: <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>,
      html: (
        <Translate contentKey="generadorApp.pharmacyInventory.delete.question" interpolate={{ id: pharmacyInventoryEntity.id }}>
          Are you sure you want to delete this PharmacyInventory?
        </Translate>
      ),
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#e70015',
      cancelButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="ban" />
          &nbsp; <Translate contentKey="generadorApp.pharmacyInventory.btnCancel">Cancel</Translate>
        </>
      ),
      confirmButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="trash" />
          &nbsp;<Translate contentKey="generadorApp.pharmacyInventory.btnDelete">Delete</Translate>
        </>
      ),
    }).then(async response => {
      if (response.isConfirmed) {
        await this.props.deleteEntity(pharmacyInventoryEntity.id, { reloadList: false });
        this.getEntities();
        MySwal.fire({
          title: <Translate contentKey="entity.delete.title.confirmed">Confirmed delete operation</Translate>,
          icon: 'success',
        });
      }
    });
  }

  cancelFilters = () => {
    this.setState(
      {
        createdDateStart: '',
        createdDateEnd: '',
        createdById: '',
        levelGroup: '',
        extraFilters: {},
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
        activePage: 0,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();

    const selectFieldsList = ['createdDate', 'levelGroup', 'createdBy.login'];

    const { createdDateStart, createdDateEnd, createdById, levelGroup, extraFilters, activePage, itemsPerPage, sort, order } = this.state;
    return this.props.getEntities(
      createdDateStart,
      createdDateEnd,
      getFilterFromSelect(createdById, 'many-to-one'),
      levelGroup,
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(',')
    );
  };

  updateEntity = entity => {
    const selectFieldsList = ['createdDate', 'levelGroup', 'createdBy.login'];

    const { createdDateStart, createdDateEnd, createdById, levelGroup, extraFilters, activePage, itemsPerPage, sort, order } = this.state;
    this.props.updateEntity(entity, [
      createdDateStart,
      createdDateEnd,
      getFilterFromSelect(createdById, 'many-to-one'),
      levelGroup,
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(','),
    ]);
  };

  renderHeader() {
    const { inventoryItems, users, pharmacyInventoryList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.pharmacyInventory.home.title">Pharmacy Inventories</Translate>
          </span>
          <Button id="togglerFilterPharmacyInventory" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.pharmacyInventory.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          &nbsp;
          {hasAnyAuthority(props.userAccount, ['pharmacy-inventory'], 'resgister') ? (
            <Link
              to={`${match.url}/new?${getEntityFiltersURL(state)}`}
              className="btn btn-primary float-right jh-create-entity"
              id="jh-create-entity"
            >
              <FontAwesomeIcon icon="plus" />
              &nbsp;
              <Translate contentKey="generadorApp.pharmacyInventory.btnNewItem">Create new</Translate>
            </Link>
          ) : (
            <></>
          )}{' '}
          &nbsp;
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.pharmacyInventory.home.title">Pharmacy Inventories</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    const { inventoryItems, users, pharmacyInventoryList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <UncontrolledCollapse toggler="#togglerFilterPharmacyInventory">
          <CardBody>
            <AvForm ref={el => (this.myFormRef = el)} id="form-filter" onSubmit={this.filterEntity}>
              <div className="row mt-1 ml-3 mr-3">
                {this.state.baseFilters !== 'createdDate' ? (
                  <Col md="4" className="col-filter-pharmacy-inventory-createdDate">
                    <Row className="mr-1 mt-1">
                      <Label id="createdDateStart" for="pharmacy-inventory-createdDate_start">
                        <Translate contentKey="generadorApp.pharmacyInventory.createdDate_start">Created Date (start)</Translate>
                      </Label>
                      <DatePicker
                        onChange={value => this.setState({ createdDateStart: value })}
                        id="createdDateStart"
                        className="form-control react-datepicker"
                        name="createdDateStart"
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.createdDateStart ? moment(this.state.createdDateStart).toDate() : null}
                        locale="pt"
                      />
                    </Row>
                  </Col>
                ) : null}
                {this.state.baseFilters !== 'createdDate' ? (
                  <Col md="4" className="col-filter-pharmacy-inventory-createdDate-final">
                    <Row className="mr-1 mt-1">
                      <Label id="createdDateEnd" for="pharmacy-inventory-createdDate_end">
                        <Translate contentKey="generadorApp.pharmacyInventory.createdDate_end">Created Date (end)</Translate>
                      </Label>
                      <DatePicker
                        onChange={value => this.setState({ createdDateEnd: value })}
                        id="createdDateEnd"
                        className="form-control react-datepicker"
                        name="createdDateEnd"
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.createdDateEnd ? moment(this.state.createdDateEnd).toDate() : null}
                        locale="pt"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'createdBy' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="pharmacy-inventory-createdBy">
                          <Translate contentKey="generadorApp.pharmacyInventory.createdBy">Created By</Translate>
                        </Label>
                        <SelectAsync
                          id="pharmacy-inventory-createdBy"
                          isMulti
                          className={'css-select-control'}
                          name={'createdBy'}
                          cacheOptions
                          value={this.state.createdById}
                          onChange={options => this.setState({ createdById: options })}
                          defaultOptions={this.state.createdByStartFilter ? this.state.createdByStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.createdByStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.createdByStartFilter === undefined) {
                              const result = await getListAxios('users', {}, 0, 100, 'login,asc', 'login');
                              this.setState({
                                createdByStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'login') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios('users', { 'login.contains': inputValue }, 0, 100, 'login,asc', 'login');
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'login') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'levelGroup' ? (
                  <Col md="4" className="col-filter-pharmacy-inventory-levelGroup">
                    <Row className="mr-1 mt-1">
                      <Label id="levelGroupLabel" for="pharmacy-inventory-levelGroup">
                        <Translate contentKey="generadorApp.pharmacyInventory.levelGroup">Group</Translate>
                      </Label>
                      <Select
                        id="pharmacy-inventory-levelGroup"
                        className={'css-select-control'}
                        value={
                          this.state.levelGroup
                            ? {
                                value: this.state.levelGroup,
                                label: translate('generadorApp.MedicineMaterialType.' + this.state.levelGroup),
                              }
                            : { value: '', label: translate('generadorApp.pharmacyInventory.levelGroup') }
                        }
                        options={[
                          { value: '', label: translate('generadorApp.pharmacyInventory.levelGroup') },
                          { value: 'MATERIAL', label: translate('generadorApp.MedicineMaterialType.MATERIAL') },
                          { value: 'MEDICAMENTO', label: translate('generadorApp.MedicineMaterialType.MEDICAMENTO') },
                          { value: 'DIETA', label: translate('generadorApp.MedicineMaterialType.DIETA') },
                        ]}
                        onChange={(options: any) => this.setState({ levelGroup: options['value'] })}
                        name="levelGroup"
                      />
                    </Row>
                  </Col>
                ) : null}
              </div>

              <div className="row mb-2 mr-4 justify-content-end">
                <Button color="primary" type="submit">
                  <i className="fa fa-filter" aria-hidden={'true'}></i>&nbsp;
                  <Translate contentKey="generadorApp.pharmacyInventory.btnFilter">Filter</Translate>
                </Button>
                &nbsp;
                <Button color="secondary" onClick={this.cancelFilters}>
                  <FontAwesomeIcon icon="trash-alt" />
                  &nbsp;
                  <Translate contentKey="generadorApp.pharmacyInventory.btnCleanFilter">Clean Filter</Translate>
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </UncontrolledCollapse>
      </>
    );
  }

  renderTable() {
    const { inventoryItems, users, pharmacyInventoryList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {pharmacyInventoryList &&
        pharmacyInventoryList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null).length > 0 ? (
          <>
            <Table
              id="pharmacy-inventory-table-list"
              responsive
              aria-describedby="pharmacy-inventory-heading"
              className={'table-hover table-striped mt-4 table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.pharmacyInventory.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'createdDate' ? (
                    <th className="hand" onClick={sortFunction('createdDate')}>
                      <Translate contentKey="generadorApp.pharmacyInventory.createdDate">Created Date</Translate>
                      <FontAwesomeIcon icon={state.sort === 'createdDate' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'levelGroup' ? (
                    <th className="hand" onClick={sortFunction('levelGroup')}>
                      <Translate contentKey="generadorApp.pharmacyInventory.levelGroup">Group</Translate>
                      <FontAwesomeIcon icon={state.sort === 'levelGroup' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'createdBy' ? (
                    <th>
                      <Translate contentKey="generadorApp.pharmacyInventory.createdBy">Created By</Translate>
                    </th>
                  ) : null}

                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {pharmacyInventoryList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((pharmacyInventory, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.pharmacyInventory.field_id">ID</Translate>
                        </b>
                        {pharmacyInventory.id}
                      </td>

                      {state.baseFilters !== 'createdDate' ? (
                        <td id="createdDate-cell">
                          <b className="visible-xs"> Created Date </b>
                          {pharmacyInventory.createdDate ? moment(pharmacyInventory.createdDate).format(APP_DATE_FORMAT) : ''}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'levelGroup' ? (
                        <td id="levelGroup-cell">
                          <b className="visible-xs"> Group </b>
                          {pharmacyInventory.levelGroup ? (
                            <Translate contentKey={`generadorApp.MedicineMaterialType.${pharmacyInventory.levelGroup}`} />
                          ) : (
                            <> </>
                          )}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'createdBy' ? (
                        <td id="createdBy-cell">
                          <b className="visible-xs"> Created By </b>
                          {pharmacyInventory.createdBy ? pharmacyInventory.createdBy.login : ''}
                        </td>
                      ) : null}

                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          {hasAnyAuthority(props.userAccount, ['pharmacy-inventory'], 'edit') ? (
                            <Button
                              tag={Link}
                              to={`${match.url}/${pharmacyInventory.id}/edit?${getEntityFiltersURL(
                                state,
                                this.state.itemsPerPage * (this.state.activePage - 1) + i
                              )}`}
                              color="primary"
                              size="sm"
                            >
                              <FontAwesomeIcon icon="pencil-alt" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.pharmacyInventory.home.notFound">No Pharmacy Inventories found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const { inventoryItems, users, pharmacyInventoryList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={pharmacyInventoryList && pharmacyInventoryList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const { inventoryItems, users, pharmacyInventoryList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel className="pharmacy-inventory pharmacy-inventory-list-table">
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToProps = ({ ...storeState }: IRootState) => {
  return {
    inventoryItems: storeState.inventoryItem.entities,
    users: storeState.userManagement.users,
    pharmacyInventoryList: storeState.pharmacyInventory.entities,
    totalItems: storeState.pharmacyInventory.totalItems,
    userAccount: storeState.authentication.account,
    loading: storeState.pharmacyInventory.loading,
  };
};

export const mapDispatchToProps = {
  getEntities,

  deleteEntity,
  updateEntity,
  reset,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyInventory);
