/* eslint complexity: ["error", 500] */
import axios from 'axios';

import moment from 'moment';
import { IPaginationBaseState, IPayloadResult } from 'react-jhipster';

import { IPayload } from 'react-jhipster/src/type/redux-action.type';

import { BASE_API_VERSION_PATH } from 'app/config/constants';

import { cleanEntity, jsonParse } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IPatient, defaultValue } from 'app/shared/model/patient.model';

import { IPatientSupplier, defaultValue as defaultValuePatientSupplier } from 'app/shared/model/patient-supplier.model';
import { IPatientCid, defaultValue as defaultValuePatientCid } from 'app/shared/model/patient-cid.model';
import { IPatientAllergy, defaultValue as defaultValuePatientAllergy } from 'app/shared/model/patient-allergy.model';
import { IPatientContact, defaultValue as defaultValuePatientContact } from 'app/shared/model/patient-contact.model';
import { IPatientResponsible, defaultValue as defaultValuePatientResponsible } from 'app/shared/model/patient-responsible.model';

export declare type ICrudPutAction<T> = (data?: T, listFiltersPage?) => IPayload<T> | IPayloadResult<T>;
export declare type ICrudGetAction<T> = (id: string | number, selectFields?: string) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const ACTION_TYPES = {
  FETCH_PATIENT_LIST_EXPORT: 'patient/FETCH_PATIENT_LIST_EXPORT',
  FETCH_PATIENT_LIST: 'patient/FETCH_PATIENT_LIST',
  FETCH_PATIENT: 'patient/FETCH_PATIENT',
  FETCH_PATIENT_OFFSET: 'patient/FETCH_PATIENT_OFFSET',
  CREATE_PATIENT: 'patient/CREATE_PATIENT',
  UPDATE_PATIENT: 'patient/UPDATE_PATIENT',
  CREATE_PATIENT_AND_GO_LIST: 'patient/CREATE_PATIENT_AND_GO_LIST',
  UPDATE_PATIENT_AND_GO_LIST: 'patient/UPDATE_PATIENT_AND_GO_LIST',
  DELETE_PATIENT: 'patient/DELETE_PATIENT',
  SET_BLOB: 'patient/SET_BLOB',
  RESET: 'patient/RESET',
  SHOW_MODAL: 'patient/SHOW_MODAL',
};

const initialState = {
  loading: false,
  openModal: {
    list: false,
    view: false,
    detail: false,
  },
  errorMessage: null,
  entities: [] as ReadonlyArray<IPatient>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,

  patientSupplierListEmbed: [] as ReadonlyArray<IPatientSupplier> | any,
  patientSupplierListAllEmbed: [] as ReadonlyArray<IPatientSupplier> | any,
  patientSupplierSelected: defaultValuePatientSupplier,
  patientCidListEmbed: [] as ReadonlyArray<IPatientCid> | any,
  patientCidListAllEmbed: [] as ReadonlyArray<IPatientCid> | any,
  patientCidSelected: defaultValuePatientCid,
  patientAllergyListEmbed: [] as ReadonlyArray<IPatientAllergy> | any,
  patientAllergyListAllEmbed: [] as ReadonlyArray<IPatientAllergy> | any,
  patientAllergySelected: defaultValuePatientAllergy,
  patientContactListEmbed: [] as ReadonlyArray<IPatientContact> | any,
  patientContactListAllEmbed: [] as ReadonlyArray<IPatientContact> | any,
  patientContactSelected: defaultValuePatientContact,
  patientResponsibleListEmbed: [] as ReadonlyArray<IPatientResponsible> | any,
  patientResponsibleListAllEmbed: [] as ReadonlyArray<IPatientResponsible> | any,
  patientResponsibleSelected: defaultValuePatientResponsible,
};

const actionPendind = [];
let actualState = initialState;

export const convertEntityBuffers = entity => ({
  ...entity,
  observations: entity.observations ? Buffer.from(entity.observations).toString() : entity.observations,
});

export type PatientState = Readonly<typeof initialState>;

export interface IPatientBaseState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  listCheckedID?: Array<number>;
  isCheckedAll?: boolean;
  urlBack?: string;
  extraFilters?: any;
  baseFilters: any;
  offset: number;
  name?: any;
  zipCode?: any;
  neighborhood?: any;
  city?: any;
  uf?: any;
  cpf?: any;
  clientId?: any;
  clientStartFilter?: any;
  register?: any;
  status?: any;
}

export interface IFieldsBase extends IPatientBaseState, IPaginationBaseState {}
export interface IPatientUpdateState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  urlBack?: string;
  functionEmbebed?: any[];
  fieldsBase: IFieldsBase;
  nameSelectValue?: any;
  nameStartSelectOptions?: any;
  cpfSelectValue?: any;
  cpfStartSelectOptions?: any;
  rgSelectValue?: any;
  rgStartSelectOptions?: any;
  sexSelectValue?: any;
  sexStartSelectOptions?: any;
  birthDateSelectValue?: any;
  birthDateStartSelectOptions?: any;
  weightSelectValue?: any;
  weightStartSelectOptions?: any;
  heightSelectValue?: any;
  heightStartSelectOptions?: any;
  healthPlanEnrollmentSelectValue?: any;
  healthPlanEnrollmentStartSelectOptions?: any;
  liminarSelectValue?: any;
  liminarStartSelectOptions?: any;
  observationsSelectValue?: any;
  observationsStartSelectOptions?: any;
  informationProfessionalSelectValue?: any;
  informationProfessionalStartSelectOptions?: any;
  registerSelectValue?: any;
  registerStartSelectOptions?: any;
  zipCodeSelectValue?: any;
  zipCodeStartSelectOptions?: any;
  hospitalReferenceSelectValue?: any;
  hospitalReferenceStartSelectOptions?: any;
  streetSelectValue?: any;
  streetStartSelectOptions?: any;
  complementSelectValue?: any;
  complementStartSelectOptions?: any;
  numberSelectValue?: any;
  numberStartSelectOptions?: any;
  neighborhoodSelectValue?: any;
  neighborhoodStartSelectOptions?: any;
  citySelectValue?: any;
  cityStartSelectOptions?: any;
  ufSelectValue?: any;
  ufStartSelectOptions?: any;
  referenceSelectValue?: any;
  referenceStartSelectOptions?: any;
  latSelectValue?: any;
  latStartSelectOptions?: any;
  lngSelectValue?: any;
  lngStartSelectOptions?: any;
  statusSelectValue?: any;
  statusStartSelectOptions?: any;
  adIdSelectValue?: any;
  adIdStartSelectOptions?: any;
  neadSelectValue?: any;
  neadStartSelectOptions?: any;
  patientComplexitySelectValue?: any;
  patientComplexityStartSelectOptions?: any;
  obeseSelectValue?: any;
  obeseStartSelectOptions?: any;
  cepRequestBairro?: any;
  cepRequestCep?: any;
  cepRequestComplemento?: any;
  cepRequestGia?: any;
  cepRequestIbge?: any;
  cepRequestLocalidade?: any;
  cepRequestLogradouro?: any;
  cepRequestUf?: any;
  cepRequestUnidade?: any;

  patientDoctorPrescriptionSelectValue?: any;
  patientDoctorPrescriptionStartSelectOptions?: any;

  patientNursingPrescriptionSelectValue?: any;
  patientNursingPrescriptionStartSelectOptions?: any;

  patientStatusHistorySelectValue?: any;
  patientStatusHistoryStartSelectOptions?: any;

  patientContactSelectValue?: any;
  patientContactStartSelectOptions?: any;

  patientClinicalIndicatorSelectValue?: any;
  patientClinicalIndicatorStartSelectOptions?: any;

  patientCidSelectValue?: any;
  patientCidStartSelectOptions?: any;

  patientAllergySelectValue?: any;
  patientAllergyStartSelectOptions?: any;

  patientSupplierSelectValue?: any;
  patientSupplierStartSelectOptions?: any;

  quoteSelectValue?: any;
  quoteStartSelectOptions?: any;

  medicalRecordSelectValue?: any;
  medicalRecordStartSelectOptions?: any;

  nursingDiarySelectValue?: any;
  nursingDiaryStartSelectOptions?: any;

  nursingPrescriptionSelectValue?: any;
  nursingPrescriptionStartSelectOptions?: any;

  patientFileSelectValue?: any;
  patientFileStartSelectOptions?: any;

  patientResponsibleSelectValue?: any;
  patientResponsibleStartSelectOptions?: any;

  padSelectValue?: any;
  padStartSelectOptions?: any;

  pushSelectValue?: any;
  pushStartSelectOptions?: any;

  patientDeviceSelectValue?: any;
  patientDeviceStartSelectOptions?: any;

  professionalBlockedSelectValue?: any;
  professionalBlockedStartSelectOptions?: any;

  professionalPreferredSelectValue?: any;
  professionalPreferredStartSelectOptions?: any;

  supplierSelectValue?: any;
  supplierStartSelectOptions?: any;

  clientSelectValue?: any;
  clientStartSelectOptions?: any;

  activeTab: number;
  isNew: boolean;
  idspatientDevice: any[];
  idsprofessionalBlocked: any[];
  idsprofessionalPreferred: any[];
  idssupplier: any[];
  patientDoctorPrescriptionObservation?: any;

  patientDoctorPrescriptionId?: any;
  patientNursingPrescriptionObservations?: any;

  patientNursingPrescriptionId?: any;
  clientFantasyName?: any;

  clientId?: any;
}

// Reducer

export default (state: PatientState = initialState, action): PatientState => {
  actualState = state;
  if (
    action.type.includes('_PENDING') &&
    !actionPendind.includes(action.type.replace('_PENDING', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_PENDING', ''))
  ) {
    actionPendind.push(action.type.replace('_PENDING', ''));
  }

  if (
    action.type.includes('_FULFILLED') &&
    actionPendind.includes(action.type.replace('_FULFILLED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_FULFILLED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_FULFILLED', '')), 1);
  }

  if (
    action.type.includes('_REJECTED') &&
    actionPendind.includes(action.type.replace('_REJECTED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_REJECTED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_REJECTED', '')), 1);
  }

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PATIENT_LIST_EXPORT):
    case REQUEST(ACTION_TYPES.FETCH_PATIENT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_PATIENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_PATIENT):
    case REQUEST(ACTION_TYPES.UPDATE_PATIENT):
    case REQUEST(ACTION_TYPES.CREATE_PATIENT_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.UPDATE_PATIENT_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.DELETE_PATIENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_PATIENT_LIST_EXPORT):
    case FAILURE(ACTION_TYPES.FETCH_PATIENT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_PATIENT):
    case FAILURE(ACTION_TYPES.CREATE_PATIENT):
    case FAILURE(ACTION_TYPES.UPDATE_PATIENT):
    case FAILURE(ACTION_TYPES.CREATE_PATIENT_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.UPDATE_PATIENT_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.DELETE_PATIENT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_PATIENT_LIST):
      action.payload.data = action.payload.data.map(v => {
        return { ...v, observations: v.observations ? Buffer.from(v.observations).toString() : v.observations };
      });

      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_PATIENT_OFFSET):
      if (action.payload.data && action.payload.data.length > 0) {
        return {
          ...state,
          loading: false,
          entity: convertEntityBuffers(action.payload.data[0]),
        };
      }
      return {
        ...state,
        loading: false,
      };

    case SUCCESS(ACTION_TYPES.FETCH_PATIENT):
      return {
        ...state,
        loading: false,
        entity: convertEntityBuffers(action.payload.data),
      };
    case SUCCESS(ACTION_TYPES.CREATE_PATIENT):
    case SUCCESS(ACTION_TYPES.UPDATE_PATIENT):
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_PATIENT_AND_GO_LIST):
    case SUCCESS(ACTION_TYPES.UPDATE_PATIENT_AND_GO_LIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_PATIENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.SET_BLOB: {
      const { name, data, contentType, fileName } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [name + 'Base64']: data,
          [name + 'ContentType']: contentType,
          [name + 'FileName']: fileName,
        },
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
        patientSupplierListEmbed: [],
        patientSupplierListAllEmbed: [],
        patientSupplierSelected: state.patientSupplierSelected,
        patientCidListEmbed: [],
        patientCidListAllEmbed: [],
        patientCidSelected: state.patientCidSelected,
        patientAllergyListEmbed: [],
        patientAllergyListAllEmbed: [],
        patientAllergySelected: state.patientAllergySelected,
        patientContactListEmbed: [],
        patientContactListAllEmbed: [],
        patientContactSelected: state.patientContactSelected,
        patientResponsibleListEmbed: [],
        patientResponsibleListAllEmbed: [],
        patientResponsibleSelected: state.patientResponsibleSelected,
      };

    case 'patient/PATIENTSUPPLIER_GET_ENTITIES':
      return {
        ...state,
        patientSupplierListEmbed: state.patientSupplierListAllEmbed,
      };
    case 'patient/PATIENTSUPPLIER_CREATE_ENTITY':
      return {
        ...state,
        patientSupplierListAllEmbed: [...(state.patientSupplierListAllEmbed ? state.patientSupplierListAllEmbed : []), action.payload],
      };
    case 'patient/PATIENTSUPPLIER_SET_ENTITIES':
      return {
        ...state,
        patientSupplierListAllEmbed: action.payload,
      };
    case 'patient/PATIENTSUPPLIER_UPDATE_ENTITY': {
      const patientSupplierListAllEmbed = [...state.patientSupplierListAllEmbed];
      state.patientSupplierListAllEmbed.map((v, i: any) => {
        if (v.id === action.payload.id) {
          patientSupplierListAllEmbed[i] = action.payload;
        }
      });
      return {
        ...state,
        patientSupplierListAllEmbed,
        patientSupplierSelected: {},
      };
    }
    case 'patient/PATIENTSUPPLIER_RESET':
      return {
        ...state,
        patientSupplierSelected: {},
        patientSupplierListEmbed: [],
      };
    case 'patient/PATIENTSUPPLIER_GET_ENTITY':
      if (!!state.patientSupplierListAllEmbed && state.patientSupplierListAllEmbed.constructor === Array) {
        return {
          ...state,
          patientSupplierSelected: state.patientSupplierListAllEmbed.filter(v => v.id === action.payload).pop(),
        };
      }
      return {
        ...state,
        patientSupplierSelected: state.patientSupplierListAllEmbed,
      };
    case 'patient/PATIENTSUPPLIER_DELETE_ENTITY':
      return {
        ...state,
        patientSupplierListAllEmbed: state.patientSupplierListAllEmbed.filter(v => v.id !== action.payload),
      };

    case 'patient/PATIENTCID_GET_ENTITIES':
      return {
        ...state,
        patientCidListEmbed: state.patientCidListAllEmbed,
      };
    case 'patient/PATIENTCID_CREATE_ENTITY':
      return {
        ...state,
        patientCidListAllEmbed: [...(state.patientCidListAllEmbed ? state.patientCidListAllEmbed : []), action.payload],
      };
    case 'patient/PATIENTCID_SET_ENTITIES':
      return {
        ...state,
        patientCidListAllEmbed: action.payload,
      };
    case 'patient/PATIENTCID_UPDATE_ENTITY': {
      const patientCidListAllEmbed = [...state.patientCidListAllEmbed];
      state.patientCidListAllEmbed.map((v, i: any) => {
        if (v.id === action.payload.id) {
          patientCidListAllEmbed[i] = action.payload;
        }
      });
      return {
        ...state,
        patientCidListAllEmbed,
        patientCidSelected: {},
      };
    }
    case 'patient/PATIENTCID_RESET':
      return {
        ...state,
        patientCidSelected: {},
        patientCidListEmbed: [],
      };
    case 'patient/PATIENTCID_GET_ENTITY':
      if (!!state.patientCidListAllEmbed && state.patientCidListAllEmbed.constructor === Array) {
        return {
          ...state,
          patientCidSelected: state.patientCidListAllEmbed.filter(v => v.id === action.payload).pop(),
        };
      }
      return {
        ...state,
        patientCidSelected: state.patientCidListAllEmbed,
      };
    case 'patient/PATIENTCID_DELETE_ENTITY':
      return {
        ...state,
        patientCidListAllEmbed: state.patientCidListAllEmbed.filter(v => v.id !== action.payload),
      };

    case 'patient/PATIENTALLERGY_GET_ENTITIES':
      return {
        ...state,
        patientAllergyListEmbed: state.patientAllergyListAllEmbed,
      };
    case 'patient/PATIENTALLERGY_CREATE_ENTITY':
      return {
        ...state,
        patientAllergyListAllEmbed: [...(state.patientAllergyListAllEmbed ? state.patientAllergyListAllEmbed : []), action.payload],
      };
    case 'patient/PATIENTALLERGY_SET_ENTITIES':
      return {
        ...state,
        patientAllergyListAllEmbed: action.payload,
      };
    case 'patient/PATIENTALLERGY_UPDATE_ENTITY': {
      const patientAllergyListAllEmbed = [...state.patientAllergyListAllEmbed];
      state.patientAllergyListAllEmbed.map((v, i: any) => {
        if (v.id === action.payload.id) {
          patientAllergyListAllEmbed[i] = action.payload;
        }
      });
      return {
        ...state,
        patientAllergyListAllEmbed,
        patientAllergySelected: {},
      };
    }
    case 'patient/PATIENTALLERGY_RESET':
      return {
        ...state,
        patientAllergySelected: {},
        patientAllergyListEmbed: [],
      };
    case 'patient/PATIENTALLERGY_GET_ENTITY':
      if (!!state.patientAllergyListAllEmbed && state.patientAllergyListAllEmbed.constructor === Array) {
        return {
          ...state,
          patientAllergySelected: state.patientAllergyListAllEmbed.filter(v => v.id === action.payload).pop(),
        };
      }
      return {
        ...state,
        patientAllergySelected: state.patientAllergyListAllEmbed,
      };
    case 'patient/PATIENTALLERGY_DELETE_ENTITY':
      return {
        ...state,
        patientAllergyListAllEmbed: state.patientAllergyListAllEmbed.filter(v => v.id !== action.payload),
      };

    case 'patient/PATIENTCONTACT_GET_ENTITIES':
      return {
        ...state,
        patientContactListEmbed: state.patientContactListAllEmbed,
      };
    case 'patient/PATIENTCONTACT_CREATE_ENTITY':
      return {
        ...state,
        patientContactListAllEmbed: [...(state.patientContactListAllEmbed ? state.patientContactListAllEmbed : []), action.payload],
      };
    case 'patient/PATIENTCONTACT_SET_ENTITIES':
      return {
        ...state,
        patientContactListAllEmbed: action.payload,
      };
    case 'patient/PATIENTCONTACT_UPDATE_ENTITY': {
      const patientContactListAllEmbed = [...state.patientContactListAllEmbed];
      state.patientContactListAllEmbed.map((v, i: any) => {
        if (v.id === action.payload.id) {
          patientContactListAllEmbed[i] = action.payload;
        }
      });
      return {
        ...state,
        patientContactListAllEmbed,
        patientContactSelected: {},
      };
    }
    case 'patient/PATIENTCONTACT_RESET':
      return {
        ...state,
        patientContactSelected: {},
        patientContactListEmbed: [],
      };
    case 'patient/PATIENTCONTACT_GET_ENTITY':
      if (!!state.patientContactListAllEmbed && state.patientContactListAllEmbed.constructor === Array) {
        return {
          ...state,
          patientContactSelected: state.patientContactListAllEmbed.filter(v => v.id === action.payload).pop(),
        };
      }
      return {
        ...state,
        patientContactSelected: state.patientContactListAllEmbed,
      };
    case 'patient/PATIENTCONTACT_DELETE_ENTITY':
      return {
        ...state,
        patientContactListAllEmbed: state.patientContactListAllEmbed.filter(v => v.id !== action.payload),
      };

    case 'patient/PATIENTRESPONSIBLE_GET_ENTITIES':
      return {
        ...state,
        patientResponsibleListEmbed: state.patientResponsibleListAllEmbed,
      };
    case 'patient/PATIENTRESPONSIBLE_CREATE_ENTITY':
      return {
        ...state,
        patientResponsibleListAllEmbed: [
          ...(state.patientResponsibleListAllEmbed ? state.patientResponsibleListAllEmbed : []),
          action.payload,
        ],
      };
    case 'patient/PATIENTRESPONSIBLE_SET_ENTITIES':
      return {
        ...state,
        patientResponsibleListAllEmbed: action.payload,
      };
    case 'patient/PATIENTRESPONSIBLE_UPDATE_ENTITY': {
      const patientResponsibleListAllEmbed = [...state.patientResponsibleListAllEmbed];
      state.patientResponsibleListAllEmbed.map((v, i: any) => {
        if (v.id === action.payload.id) {
          patientResponsibleListAllEmbed[i] = action.payload;
        }
      });
      return {
        ...state,
        patientResponsibleListAllEmbed,
        patientResponsibleSelected: {},
      };
    }
    case 'patient/PATIENTRESPONSIBLE_RESET':
      return {
        ...state,
        patientResponsibleSelected: {},
        patientResponsibleListEmbed: [],
      };
    case 'patient/PATIENTRESPONSIBLE_GET_ENTITY':
      if (!!state.patientResponsibleListAllEmbed && state.patientResponsibleListAllEmbed.constructor === Array) {
        return {
          ...state,
          patientResponsibleSelected: state.patientResponsibleListAllEmbed.filter(v => v.id === action.payload).pop(),
        };
      }
      return {
        ...state,
        patientResponsibleSelected: state.patientResponsibleListAllEmbed,
      };
    case 'patient/PATIENTRESPONSIBLE_DELETE_ENTITY':
      return {
        ...state,
        patientResponsibleListAllEmbed: state.patientResponsibleListAllEmbed.filter(v => v.id !== action.payload),
      };

    case ACTION_TYPES.SHOW_MODAL: {
      const openModal = state.openModal;
      openModal[action.payload.type] = action.payload.show;
      return {
        ...state,
        openModal,
      };
    }
    default:
      return state;
  }
};

const apiUrl = BASE_API_VERSION_PATH + 'patients';

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

// Actions

// Actions
export type ICrudGetAllActionPatient<T> = (
  name?: any,
  zipCode?: any,
  neighborhood?: any,
  city?: any,
  uf?: any,
  cpf?: any,
  clientId?: any,
  register?: any,
  status?: any,
  extraFilters?: {},
  page?: number,
  size?: number,
  sort?: string,
  selectFields?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getEntitiesFilter = (filters?, page?, size?, sort?, selectFields = '') => {
  const requestUrl = `${apiUrl}?page=${page >= 0 ? page : 0}&size=${size >= 0 ? size : 20}&sort=${sort ? sort : 'id,asc'}&`;
  const requestFilters = filters.map(v => v.column + '.' + v.operation + '=' + v.value).join('&');
  return {
    type: ACTION_TYPES.FETCH_PATIENT_LIST,
    payload: axios.get<IPatient>(`${requestUrl}${requestFilters}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getAllEntities = (filters?, sort?, selectFields = '') => {
  if (
    (actualState.totalItems > 0 && actualState.entities.length === actualState.totalItems) ||
    actionPendind.includes(ACTION_TYPES.FETCH_PATIENT_LIST)
  ) {
    return {
      type: ACTION_TYPES.FETCH_PATIENT_LIST + '_FROM_CACHE',
      payload: new Promise(function (resolve, reject) {
        resolve('done');
      }),
    };
  }

  const idsRequest = filters && typeof filters['ids'] !== 'undefined' && filters['ids'] ? `id.in=${filters['ids'].join(',')}&` : '';
  const nameRequest = filters && typeof filters['name'] !== 'undefined' && filters['name'] ? `name.contains=${filters['name']}&` : '';
  const zipCodeRequest =
    filters && typeof filters['zipCode'] !== 'undefined' && filters['zipCode'] ? `zipCode.contains=${filters['zipCode']}&` : '';
  const neighborhoodRequest =
    filters && typeof filters['neighborhood'] !== 'undefined' && filters['neighborhood']
      ? `neighborhood.contains=${filters['neighborhood']}&`
      : '';
  const cityRequest = filters && typeof filters['city'] !== 'undefined' && filters['city'] ? `city.contains=${filters['city']}&` : '';
  const ufRequest = filters && typeof filters['uf'] !== 'undefined' && filters['uf'] ? `uf.contains=${filters['uf']}&` : '';
  const cpfRequest = filters && typeof filters['cpf'] !== 'undefined' && filters['cpf'] ? `cpf.contains=${filters['cpf']}&` : '';
  const clientRequest =
    filters && typeof filters['clientId'] !== 'undefined' && filters['clientId'] ? `client.id.in=${filters['clientId']}&` : '';
  const registerRequest =
    filters && typeof filters['register'] !== 'undefined' && filters['register'] ? `register.contains=${filters['register']}&` : '';
  const statusRequest =
    filters && typeof filters['status'] !== 'undefined' && filters['status'] ? `status.contains=${filters['status']}&` : '';

  const requestUrl = `${apiUrl}${`?page=${0}&size=${1000000}&sort=${sort ? sort : 'id,asc'}&`}`;
  return {
    type: ACTION_TYPES.FETCH_PATIENT_LIST,
    payload: axios.get<IPatient>(
      `${requestUrl}${idsRequest}${nameRequest}${zipCodeRequest}${neighborhoodRequest}${cityRequest}${ufRequest}${cpfRequest}${clientRequest}${registerRequest}${statusRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntities: ICrudGetAllActionPatient<IPatient> = (
  name,
  zipCode,
  neighborhood,
  city,
  uf,
  cpf,
  clientId,
  register,
  status,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const nameRequest = name ? `name.contains=${name}&` : '';
  const zipCodeRequest = zipCode ? `zipCode.contains=${zipCode}&` : '';
  const neighborhoodRequest = neighborhood ? `neighborhood.contains=${neighborhood}&` : '';
  const cityRequest = city ? `city.contains=${city}&` : '';
  const ufRequest = uf ? `uf.contains=${uf}&` : '';
  const cpfRequest = cpf ? `cpf.contains=${cpf}&` : '';
  const clientRequest = clientId ? `client.id.in=${clientId}&` : '';
  const registerRequest = register !== '' ? `register.equals=${register}&` : '';
  const statusRequest = status ? (Array.isArray(status) ? `status.in=${status.join(',')}&` : `status.equals=${status}&`) : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PATIENT_LIST,
    payload: axios.get<IPatient>(
      `${requestUrl}${extraFiltersRequest}${nameRequest}${zipCodeRequest}${neighborhoodRequest}${cityRequest}${ufRequest}${cpfRequest}${clientRequest}${registerRequest}${statusRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntityOffset: any = (
  name,
  zipCode,
  neighborhood,
  city,
  uf,
  cpf,
  clientId,
  register,
  status,
  extraFilters,
  offset,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const nameRequest = name ? `name.contains=${name}&` : '';
  const zipCodeRequest = zipCode ? `zipCode.contains=${zipCode}&` : '';
  const neighborhoodRequest = neighborhood ? `neighborhood.contains=${neighborhood}&` : '';
  const cityRequest = city ? `city.contains=${city}&` : '';
  const ufRequest = uf ? `uf.contains=${uf}&` : '';
  const cpfRequest = cpf ? `cpf.contains=${cpf}&` : '';
  const clientRequest = clientId ? `client.id.in=${clientId}&` : '';
  const registerRequest = register !== '' ? `register.equals=${register}&` : '';
  const statusRequest = status ? (Array.isArray(status) ? `status.in=${status.join(',')}&` : `status.equals=${status}&`) : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${offset}&size=${1}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PATIENT_OFFSET,
    payload: axios.get<IPatient>(
      `${requestUrl}${extraFiltersRequest}${nameRequest}${zipCodeRequest}${neighborhoodRequest}${cityRequest}${ufRequest}${cpfRequest}${clientRequest}${registerRequest}${statusRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntity: ICrudGetAction<IPatient> = (id, selectFields = '') => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_PATIENT,
    payload: axios.get<IPatient>(requestUrl, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntitiesExport: ICrudGetAllActionPatient<IPatient> = (
  name,
  zipCode,
  neighborhood,
  city,
  uf,
  cpf,
  clientId,
  register,
  status,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const nameRequest = name ? (Array.isArray(status) ? `name.in=${name.join(',')}&` : `name.contains=${name}&`) : '';
  const zipCodeRequest = zipCode ? (Array.isArray(status) ? `zipCode.in=${zipCode.join(',')}&` : `zipCode.contains=${zipCode}&`) : '';
  const neighborhoodRequest = neighborhood
    ? Array.isArray(status)
      ? `neighborhood.in=${neighborhood.join(',')}&`
      : `neighborhood.contains=${neighborhood}&`
    : '';
  const cityRequest = city ? (Array.isArray(status) ? `city.in=${city.join(',')}&` : `city.contains=${city}&`) : '';
  const ufRequest = uf ? (Array.isArray(status) ? `uf.in=${uf.join(',')}&` : `uf.contains=${uf}&`) : '';
  const cpfRequest = cpf ? (Array.isArray(status) ? `cpf.in=${cpf.join(',')}&` : `cpf.contains=${cpf}&`) : '';
  const clientRequest = clientId ? `client.id.in=${clientId}&` : '';
  const registerRequest = register !== '' ? `register.equals=${register}&` : '';
  const statusRequest = status ? (Array.isArray(status) ? `status.in=${status.join(',')}&` : `status.equals=${status}&`) : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PATIENT_LIST,
    payload: axios.get<IPatient>(
      `${requestUrl}${extraFiltersRequest}${nameRequest}${zipCodeRequest}${neighborhoodRequest}${cityRequest}${ufRequest}${cpfRequest}${clientRequest}${registerRequest}${statusRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const createEntity: ICrudPutAction<IPatient> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity, birthDate: entity.birthDate ? moment(entity.birthDate).format('YYYY-MM-DD') : null };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false ? ACTION_TYPES.CREATE_PATIENT_AND_GO_LIST : ACTION_TYPES.CREATE_PATIENT,
    payload: axios.post(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const updateEntity: ICrudPutAction<IPatient> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity, birthDate: entity.birthDate ? moment(entity.birthDate).format('YYYY-MM-DD') : null };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false ? ACTION_TYPES.UPDATE_PATIENT_AND_GO_LIST : ACTION_TYPES.UPDATE_PATIENT,
    payload: axios.put(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const deleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_PATIENT,
    payload: axios.delete(requestUrl),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const setBlob = (name, data, contentType?, fileName?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType,
    fileName,
  },
});

export const showModal = view => ({
  payload: { type: view, show: true },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const hideModal = view => ({
  payload: { type: view, show: false },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const getUrlBack = (location): string => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  let urlBack = _urlBase.searchParams.get('urlBack') || '';
  urlBack = urlBack.trim();
  urlBack = urlBack.startsWith('/') ? urlBack.slice(1) : urlBack;
  urlBack = urlBack.endsWith('/') ? urlBack.slice(0, -1) : urlBack;
  return urlBack ? '/' + urlBack + '/' : '';
};

export const getPatientState = (location): IPatientBaseState => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  const extraFilters = jsonParse(_urlBase.searchParams.get('extraFilters') || '{}');
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;

  const name = _urlBase.searchParams.get('name') || '';
  const zipCode = _urlBase.searchParams.get('zipCode') || '';
  const neighborhood = _urlBase.searchParams.get('neighborhood') || '';
  const city = _urlBase.searchParams.get('city') || '';
  const uf = _urlBase.searchParams.get('uf') || '';
  const cpf = _urlBase.searchParams.get('cpf') || '';
  const clientId = _urlBase.searchParams.get('client') || '';
  const register = _urlBase.searchParams.get('register') || '';
  const status = _urlBase.searchParams.get('status') || '';
  return {
    baseFilters,
    extraFilters,
    offset,
    name,
    zipCode,
    neighborhood,
    city,
    uf,
    cpf,
    clientId: clientId ? clientId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
    register,
    status,
  };
};

export const getEntityFiltersURL = (state, offset = null) => {
  return (
    'baseFilters=' +
    state.baseFilters +
    '&extraFilters=' +
    encodeURI(JSON.stringify(state.extraFilters)) +
    '&page=' +
    state.activePage +
    '&' +
    'size=' +
    state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    state.sort +
    ',' +
    state.order +
    '&' +
    (state.name ? 'name=' + state.name + '&' : '') +
    (state.zipCode ? 'zipCode=' + state.zipCode + '&' : '') +
    (state.neighborhood ? 'neighborhood=' + state.neighborhood + '&' : '') +
    (state.city ? 'city=' + state.city + '&' : '') +
    (state.uf ? 'uf=' + state.uf + '&' : '') +
    (state.cpf ? 'cpf=' + state.cpf + '&' : '') +
    (state.clientId ? 'client=' + state.clientId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.register ? 'register=' + state.register + '&' : '') +
    (state.status ? 'status=' + state.status + '&' : '') +
    ''
  );
};

export const patientSupplierReset: any = values => ({
  payload: values,
  type: 'patient/PATIENTSUPPLIER_RESET',
});

export const patientSupplierGetEntities: any = values => ({
  payload: values,
  type: 'patient/PATIENTSUPPLIER_GET_ENTITIES',
});

export const patientSupplierSetEntities: any = values => async dispatch => {
  const result = await dispatch({
    payload: values,
    type: 'patient/PATIENTSUPPLIER_SET_ENTITIES',
  });
  dispatch(patientSupplierGetEntities());
  return result;
};

export const patientSupplierCreateEntity: ICrudPutAction<IPatientSupplier> = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: { id: (new Date().getTime() * Math.random()).toString(16).split('.').join('-'), ...entity },
    type: 'patient/PATIENTSUPPLIER_CREATE_ENTITY',
  });

  if (entity.patient === 'is_new') {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(patientSupplierReset());
      dispatch(patientSupplierGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};

export const patientSupplierUpdateEntity: ICrudPutAction<IPatientSupplier> = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: entity,
    type: 'patient/PATIENTSUPPLIER_UPDATE_ENTITY',
  });
  if (entity.patient === 'is_new') {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(patientSupplierReset());
      dispatch(patientSupplierGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};
export const patientSupplierGetEntity: any = values => ({
  payload: values,
  type: 'patient/PATIENTSUPPLIER_GET_ENTITY',
});

export const patientSupplierDeleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: id,
    type: 'patient/PATIENTSUPPLIER_DELETE_ENTITY',
  });
  if (isNaN(id)) {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(patientSupplierReset());
      dispatch(patientSupplierGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};

export const patientCidReset: any = values => ({
  payload: values,
  type: 'patient/PATIENTCID_RESET',
});

export const patientCidGetEntities: any = values => ({
  payload: values,
  type: 'patient/PATIENTCID_GET_ENTITIES',
});

export const patientCidSetEntities: any = values => async dispatch => {
  const result = await dispatch({
    payload: values,
    type: 'patient/PATIENTCID_SET_ENTITIES',
  });
  dispatch(patientCidGetEntities());
  return result;
};

export const patientCidCreateEntity: ICrudPutAction<IPatientCid> = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: { id: (new Date().getTime() * Math.random()).toString(16).split('.').join('-'), ...entity },
    type: 'patient/PATIENTCID_CREATE_ENTITY',
  });

  if (entity.patient === 'is_new') {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(patientCidReset());
      dispatch(patientCidGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};

export const patientCidUpdateEntity: ICrudPutAction<IPatientCid> = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: entity,
    type: 'patient/PATIENTCID_UPDATE_ENTITY',
  });
  if (entity.patient === 'is_new') {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(patientCidReset());
      dispatch(patientCidGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};
export const patientCidGetEntity: any = values => ({
  payload: values,
  type: 'patient/PATIENTCID_GET_ENTITY',
});

export const patientCidDeleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: id,
    type: 'patient/PATIENTCID_DELETE_ENTITY',
  });
  if (isNaN(id)) {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(patientCidReset());
      dispatch(patientCidGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};

export const patientAllergyReset: any = values => ({
  payload: values,
  type: 'patient/PATIENTALLERGY_RESET',
});

export const patientAllergyGetEntities: any = values => ({
  payload: values,
  type: 'patient/PATIENTALLERGY_GET_ENTITIES',
});

export const patientAllergySetEntities: any = values => async dispatch => {
  const result = await dispatch({
    payload: values,
    type: 'patient/PATIENTALLERGY_SET_ENTITIES',
  });
  dispatch(patientAllergyGetEntities());
  return result;
};

export const patientAllergyCreateEntity: ICrudPutAction<IPatientAllergy> = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: { id: (new Date().getTime() * Math.random()).toString(16).split('.').join('-'), ...entity },
    type: 'patient/PATIENTALLERGY_CREATE_ENTITY',
  });

  if (entity.patient === 'is_new') {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(patientAllergyReset());
      dispatch(patientAllergyGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};

export const patientAllergyUpdateEntity: ICrudPutAction<IPatientAllergy> = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: entity,
    type: 'patient/PATIENTALLERGY_UPDATE_ENTITY',
  });
  if (entity.patient === 'is_new') {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(patientAllergyReset());
      dispatch(patientAllergyGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};
export const patientAllergyGetEntity: any = values => ({
  payload: values,
  type: 'patient/PATIENTALLERGY_GET_ENTITY',
});

export const patientAllergyDeleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: id,
    type: 'patient/PATIENTALLERGY_DELETE_ENTITY',
  });
  if (isNaN(id)) {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(patientAllergyReset());
      dispatch(patientAllergyGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};

export const patientContactReset: any = values => ({
  payload: values,
  type: 'patient/PATIENTCONTACT_RESET',
});

export const patientContactGetEntities: any = values => ({
  payload: values,
  type: 'patient/PATIENTCONTACT_GET_ENTITIES',
});

export const patientContactSetEntities: any = values => async dispatch => {
  const result = await dispatch({
    payload: values,
    type: 'patient/PATIENTCONTACT_SET_ENTITIES',
  });
  dispatch(patientContactGetEntities());
  return result;
};

export const patientContactCreateEntity: ICrudPutAction<IPatientContact> = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: { id: (new Date().getTime() * Math.random()).toString(16).split('.').join('-'), ...entity },
    type: 'patient/PATIENTCONTACT_CREATE_ENTITY',
  });

  if (entity.patient === 'is_new') {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(patientContactReset());
      dispatch(patientContactGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};

export const patientContactUpdateEntity: ICrudPutAction<IPatientContact> = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: entity,
    type: 'patient/PATIENTCONTACT_UPDATE_ENTITY',
  });
  if (entity.patient === 'is_new') {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(patientContactReset());
      dispatch(patientContactGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};
export const patientContactGetEntity: any = values => ({
  payload: values,
  type: 'patient/PATIENTCONTACT_GET_ENTITY',
});

export const patientContactDeleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: id,
    type: 'patient/PATIENTCONTACT_DELETE_ENTITY',
  });
  if (isNaN(id)) {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(patientContactReset());
      dispatch(patientContactGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};

export const patientResponsibleReset: any = values => ({
  payload: values,
  type: 'patient/PATIENTRESPONSIBLE_RESET',
});

export const patientResponsibleGetEntities: any = values => ({
  payload: values,
  type: 'patient/PATIENTRESPONSIBLE_GET_ENTITIES',
});

export const patientResponsibleSetEntities: any = values => async dispatch => {
  const result = await dispatch({
    payload: values,
    type: 'patient/PATIENTRESPONSIBLE_SET_ENTITIES',
  });
  dispatch(patientResponsibleGetEntities());
  return result;
};

export const patientResponsibleCreateEntity: ICrudPutAction<IPatientResponsible> = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: { id: (new Date().getTime() * Math.random()).toString(16).split('.').join('-'), ...entity },
    type: 'patient/PATIENTRESPONSIBLE_CREATE_ENTITY',
  });

  if (entity.patient === 'is_new') {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(patientResponsibleReset());
      dispatch(patientResponsibleGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};

export const patientResponsibleUpdateEntity: ICrudPutAction<IPatientResponsible> = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: entity,
    type: 'patient/PATIENTRESPONSIBLE_UPDATE_ENTITY',
  });
  if (entity.patient === 'is_new') {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(patientResponsibleReset());
      dispatch(patientResponsibleGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};
export const patientResponsibleGetEntity: any = values => ({
  payload: values,
  type: 'patient/PATIENTRESPONSIBLE_GET_ENTITY',
});

export const patientResponsibleDeleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: id,
    type: 'patient/PATIENTRESPONSIBLE_DELETE_ENTITY',
  });
  if (isNaN(id)) {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(patientResponsibleReset());
      dispatch(patientResponsibleGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};
