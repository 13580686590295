import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity, IPatientSupplierBaseState, getUrlBack, getPatientSupplierState, getEntityFiltersURL } from './patient-supplier.reducer';

export interface IPatientSupplierState {
  fieldsBase: IPatientSupplierBaseState;
}

export interface IPatientSupplierDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class PatientSupplierDetail extends React.Component<IPatientSupplierDetailProps, IPatientSupplierState> {
  constructor(props: Readonly<IPatientSupplierDetailProps>) {
    super(props);
    this.state = {
      ...this.state,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getPatientSupplierState(this.props.location),
        urlBack: getUrlBack(this.props.location),
        patientId: [{ value: this.props.match.params['idPatient'], label: this.props.match.params['idPatient'] }],
        baseFilters: 'patient',
      },
    };
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = '_back=1' + (offset !== null ? '&offset=' + offset : '');
    Object.keys(fieldsBase).map(key => {
      url += '&' + key + '=' + fieldsBase[key];
    });
    return url;
  };

  UNSAFE_componentWillMount() {
    this.props.getEntity(this.props.match.params['id']);
  }

  renderHeader() {
    const { patientSupplierEntity } = this.props;
    const state = this.state;
    return (
      <>
        <div id="page-heading">
          {' '}
          <h2>
            <Translate contentKey="generadorApp.patientSupplier.detail.title">PatientSupplier</Translate>
            <b>{patientSupplierEntity.id}</b>
          </h2>
        </div>

        <div className="pull-right">
          <Button
            tag={Link}
            to={'/patient/' + this.props.match.params['idPatient'] + '/patient-supplier?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
          >
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.patientSupplier.btnBack">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/patient/${this.props.match.params['idPatient']}/patient-supplier/${patientSupplierEntity.id}/edit?+${getEntityFiltersURL(
              this.state.fieldsBase
            )}`}
            replace
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.patientSupplier.btnEdit">Edit</Translate>
            </span>
          </Button>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>

          <li className="breadcrumb-item">
            <Link to={'/patient'}>Patients</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/patient/' + this.props.match.params['idPatient']}>{this.props.match.params['idPatient']}</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/patient/' + (this.state.fieldsBase.patientId ? this.state.fieldsBase.patientId.label : '') + '/patient-supplier'}>
              Supplies
            </Link>
          </li>
          <li className="breadcrumb-item active">Supplies details</li>
        </ol>
      </>
    );
  }

  renderBody() {
    const { patientSupplierEntity } = this.props;
    const state = this.state;
    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            <Row className="jh-entity-details">
              <Col className="view-detail-row patient-supplier-category-detail" md="6">
                <Row>
                  <Col className="view-detail-label-top patient-supplier-category-detail-label" md="12">
                    <dt>
                      <span id="category">
                        <Translate contentKey="generadorApp.patientSupplier.category">Category</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top patient-supplier-category-detail-value" md="12">
                    <dd>
                      {translate(
                        'generadorApp.PatientSupplierCategory.' +
                          (patientSupplierEntity.category ? patientSupplierEntity.category : 'AMBULANCIA')
                      )}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row patient-supplier-suppliers-detail" md="6">
                <Row>
                  <Col className="view-detail-label-top patient-supplier-suppliers-detail-label" md="12">
                    <dt>
                      <Translate contentKey="generadorApp.patientSupplier.suppliers">Suppliers</Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top patient-supplier-suppliers-detail-value" md="12">
                    <dd>
                      {patientSupplierEntity.suppliers
                        ? patientSupplierEntity.suppliers.map((val, i) => (
                            <span key={val.id}>
                              <a>{val.fantasyName}</a>
                              {i === patientSupplierEntity.suppliers.length - 1 ? '' : ', '}
                            </span>
                          ))
                        : null}
                    </dd>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { patientSupplierEntity } = this.props;
    const state = this.state;
    return (
      <div>
        <Panel className="patient-supplier patient-supplier-detail">
          <PanelHeader>{this.renderHeader()}</PanelHeader>

          <PanelBody>{this.renderBody()}</PanelBody>
        </Panel>
      </div>
    );
  }
}

export const mapStateToProps = ({ patientSupplier, ...storeState }: IRootState) => ({
  patientSupplierEntity: patientSupplier.entity,
});

export const mapDispatchToProps = {
  getEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PatientSupplierDetail);
