import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { IVariableCost } from 'app/shared/model/variable-cost.model';
import { IFixedCost } from 'app/shared/model/fixed-cost.model';
import { BillsPayType } from 'app/shared/model/enumerations/bills-pay-type.model';

export interface IBillToPay {
  id?: number;
  type?: BillsPayType;
  name?: string;
  description?: string;
  billValue?: number;
  paymentDay?: number;
  paymentDate?: Moment;
  variableCost?: IVariableCost;
  fixedCost?: IFixedCost;
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  variableCost: {
    isMulti: true, // many-to-one - other-side
    entity: 'VariableCost',
    urlParameters: {
      enpoint: 'variable-costs',
      filters: 'billToPay.id.equals',
    },
  },
  fixedCost: {
    isMulti: true, // many-to-one - other-side
    entity: 'FixedCost',
    urlParameters: {
      enpoint: 'fixed-costs',
      filters: 'billToPay.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'billToPay.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'billToPay.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<IBillToPay> = {};
