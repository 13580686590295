import './header.scss';

import React, { useState } from 'react';
import { Storage } from 'react-jhipster';
import { Nav, Collapse } from 'reactstrap';

import LoadingBar from 'react-redux-loading-bar';

import { EntitiesMenu } from '../menus/entities';

import { LocaleMenu } from 'app/shared/layout/menus';
import { Account, Logout, SwaggerMenu } from './header-components';
import { connect } from 'react-redux';
import { toggleSidebarMobileOpen } from 'app/shared/reducers/sidebar';
import { IRootState } from 'app/shared/reducers';

export interface IMyHeaderProps {
  pageSidebarMinified: boolean;
  pageSidebarTransparent: boolean;
  isAuthenticated: boolean;
  isAdmin: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isSwaggerEnabled: boolean;
  currentLocale: string;
  onLocaleChange: Function;
  toggleSidebarMinify: Function;
  toggleMobileSidebar: Function;
}

export interface IHeaderProps extends StateProps, DispatchProps, IMyHeaderProps {}

const Header = (props: any) => {
  const [menuOpen] = useState(true);

  const handleLocaleChange = event => {
    const langKey = event.target.value;
    Storage.session.set('locale', langKey);
    props.onLocaleChange(langKey);
  };

  /* jhipster-needle-add-element-to-menu - JHipster will add new menu items here */
  return (
    <div id="app-header">
      <LoadingBar className="loading-bar" />
      <div id="header" className="header navbar-inverse" style={{ boxShadow: '0 0 16px rgba(0,0,0,.15)' }}>
        <div
          className="btn sidebar-minify-btn"
          onClick={() => {
            props.toggleSidebarMinify(!props.pageSidebarMinified);
          }}
        >
          <i className="fa fa-bars"></i>
        </div>
        <div className={'navbar-header'} style={{ marginLeft: 10, color: '#fff', fontSize: '18px' }}>
          <div className={'navbar-nav navbar-left'}>{props.account.login === 'admin' ? 'Admin' : props.whiteLabel.fantasyName}</div>
        </div>
        <div className={'navbar-header'}>
          <div className={'navbar-nav navbar-right d-block d-md-none'}>
            <Collapse isOpen={menuOpen} navbar>
              <Nav id="header-tabs" className="ml-auto navbar-nav navbar-right pull-right" navbar>
                {props.isAuthenticated && props.isAdmin && props.isSwaggerEnabled && <SwaggerMenu />}
                {props.isAdmin && <EntitiesMenu />}
                {props.isAuthenticated && (
                  <LocaleMenu currentLocale={props.currentLocale ? props.currentLocale : 'pt_br'} onClick={handleLocaleChange} />
                )}
                {props.isAuthenticated && <Account />}
                {props.isAuthenticated && <Logout />}

                <button type="button" className="navbar-toggle" onClick={props.toggleSidebarMobileOpen}>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
              </Nav>
            </Collapse>
          </div>
        </div>
        <div className={'navbar-nav navbar-right d-none d-md-block'}>
          <Nav id="header-tabs" style={{ height: 40 }} className="ml-auto navbar-nav navbar-right pull-right mr-4" navbar>
            {props.isAuthenticated && props.isAdmin && props.isSwaggerEnabled && <SwaggerMenu />}
            {props.isAdmin && <EntitiesMenu />}
            {props.isAuthenticated && (
              <LocaleMenu currentLocale={props.currentLocale ? props.currentLocale : 'pt_br'} onClick={handleLocaleChange} />
            )}
            {props.isAuthenticated && <Account />}
            {props.isAuthenticated && <Logout />}
          </Nav>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
  account: authentication.account,
  whiteLabel: authentication.account.whiteLabel,
});

const mapDispatchToProps = { toggleSidebarMobileOpen };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Header);
