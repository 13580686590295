import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import PharmacyInventory from './pharmacy-inventory';

import PharmacyInventoryDetail from './pharmacy-inventory-detail';

import PharmacyInventoryUpdate from './pharmacy-inventory-update';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={PharmacyInventoryUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={PharmacyInventoryUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={PharmacyInventoryDetail} />
      <ErrorBoundaryRoute path={match.path} component={PharmacyInventory} />
    </Switch>
  </>
);

export default Routes;
