/* eslint complexity: ["error", 500] */
import React from 'react';

import { connect } from 'react-redux';

import { toast } from 'react-toastify';

import { Link, RouteComponentProps } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { Button, Col, Row, Table, Label, UncontrolledCollapse, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { JhiItemCount } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { IRootState } from 'app/shared/reducers';
import {
  updateEntity,
  getUrlBack,
  getCompanyState,
  ICompanyBaseState,
  getEntityFiltersURL,
  getEntities,
  ICompanyUpdateState as IBaseUpdateState,
  reset,
  getEntity,
  deleteEntity,
  createEntity,
} from './company.reducer';

import { ITEMS_PER_PAGE, IPaginationBaseState, getSortState } from 'app/shared/util/pagination.constants';

import { viacepRequest } from 'app/shared/util/entity-utils';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

export interface ICompanyProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
  entityEmbebedPai?: any;
}

export interface ICompanyState extends ICompanyBaseState, IPaginationBaseState, IBaseUpdateState {
  showModalForm?: boolean;
  showModalEdit?: number;
  showModalEditView?: boolean;
}

export class Company extends React.Component<ICompanyProps, ICompanyState> {
  protected myFormRef: any;
  protected myFormRefNew: any;
  protected myFormRefEdit: any;

  socialReasonFileInput: any;

  fantasyNameFileInput: any;

  cnpjFileInput: any;

  zipCodeFileInput: any;

  streetFileInput: any;

  complementFileInput: any;

  numberFileInput: any;

  neighborhoodFileInput: any;

  cityFileInput: any;

  ufFileInput: any;

  regANSFileInput: any;

  regCNESFileInput: any;

  technicalManagerFileInput: any;

  technicalManagerNurseFileInput: any;

  inscriptionFileInput: any;

  councilCodeFileInput: any;

  ufCodeFileInput: any;

  cboCodeFileInput: any;

  constructor(props: ICompanyProps) {
    super(props);
    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      listCheckedID: [],
      isCheckedAll: false,
      showModalForm: false,
      showModalEdit: null,
      urlBack: getUrlBack(this.props.location),
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      ...getCompanyState(this.props.location),
      cepRequestCep: null,
      cepRequestLogradouro: null,

      cepRequestBairro: null,
      cepRequestLocalidade: null,
      cepRequestUf: null,

      socialReasonSelectValue: null,
      fantasyNameSelectValue: null,
      cnpjSelectValue: null,
      zipCodeSelectValue: null,
      streetSelectValue: null,
      complementSelectValue: null,
      numberSelectValue: null,
      neighborhoodSelectValue: null,
      citySelectValue: null,
      ufSelectValue: null,
      regANSSelectValue: null,
      regCNESSelectValue: null,
      technicalManagerSelectValue: null,
      technicalManagerNurseSelectValue: null,
      inscriptionSelectValue: null,
      councilCodeSelectValue: null,
      ufCodeSelectValue: null,
      cboCodeSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getCompanyState(this.props.location),
      },
      isNew: false,
    };
  }

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({ listCheckedID: this.props.companyList.map(company => company.id) });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({ listCheckedID: this.props.companyList.map(company => company.id) });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  removeEntitySelected() {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  }

  saveEntityMany = () => {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  };

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  cancelFilters = () => {
    this.setState(
      {
        socialReason: '',
        fantasyName: '',
        cnpj: '',
        zipCode: '',
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
        activePage: 0,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();

    const selectFieldsList = ['socialReason', 'fantasyName', 'cnpj', 'zipCode'];
    const { socialReason, fantasyName, cnpj, zipCode, extraFilters, activePage, itemsPerPage, sort, order } = this.state;
    return this.props.getEntities(
      socialReason,
      fantasyName,
      cnpj,
      zipCode,
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(',')
    );
  };

  updateEntity = entity => {
    const selectFieldsList = ['socialReason', 'fantasyName', 'cnpj', 'zipCode'];

    const { socialReason, fantasyName, cnpj, zipCode, extraFilters, activePage, itemsPerPage, sort, order } = this.state;
    this.props.updateEntity(entity, [
      socialReason,
      fantasyName,
      cnpj,
      zipCode,
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(','),
    ]);
  };

  saveEntity = (event: any, errors: any, values: any, isNew: any) => {
    if (!this.state.socialReasonSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.company.socialReason') }));
      return false;
    }

    if (!this.state.fantasyNameSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.company.fantasyName') }));
      return false;
    }

    if (!this.state.cnpjSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.company.cnpj') }));
      return false;
    }

    if (!this.state.cepRequestCep) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.company.zipCode') }));
      return false;
    }

    if (!this.state.numberSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.company.number') }));
      return false;
    }

    const selectFieldsList = ['socialReason', 'fantasyName', 'cnpj', 'zipCode'];

    if (!this.state.cepRequestCep) {
      errors.push(translate('entity.validation.required'));
    }

    if (errors.length === 0) {
      const { companyEntity } = this.props;
      const _entity = {
        ...companyEntity,
        // ...values,

        socialReason: this.state.socialReasonSelectValue,
        fantasyName: this.state.fantasyNameSelectValue,
        cnpj: this.state.cnpjSelectValue,
        zipCode: this.state.cepRequestCep,
        street: this.state.cepRequestLogradouro,
        complement: this.state.complementSelectValue,
        number: this.state.numberSelectValue,
        neighborhood: this.state.cepRequestBairro,
        city: this.state.cepRequestLocalidade,
        uf: this.state.cepRequestUf,
        regANS: this.state.regANSSelectValue,
        regCNES: this.state.regCNESSelectValue,
        technicalManager: this.state.technicalManagerSelectValue,
        technicalManagerNurse: this.state.technicalManagerNurseSelectValue,
        inscription: this.state.inscriptionSelectValue,
        councilCode: this.state.councilCodeSelectValue,
        ufCode: this.state.ufCodeSelectValue,
        cboCode: this.state.cboCodeSelectValue,
      };
      const entity = _entity;

      const { socialReason, fantasyName, cnpj, zipCode, extraFilters, activePage, itemsPerPage, sort, order } = this.state.fieldsBase;

      if (isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              socialReason,
              fantasyName,
              cnpj,
              zipCode,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              socialReason,
              fantasyName,
              cnpj,
              zipCode,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
      this.setState({ showModalForm: false });
      this.setState({ showModalEdit: null });
    } else {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.specialty.' + errors[0]) }));
    }
  };

  componentDidUpdate(prevProps) {}

  deleteEntity(companyEntity) {
    MySwal.fire({
      title: <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>,
      html: (
        <Translate contentKey="generadorApp.company.delete.question" interpolate={{ id: companyEntity.id }}>
          Are you sure you want to delete this Company?
        </Translate>
      ),
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#e70015',
      cancelButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="ban" />
          &nbsp; <Translate contentKey="generadorApp.company.btnCancel">Cancel</Translate>
        </>
      ),
      confirmButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="trash" />
          &nbsp;<Translate contentKey="generadorApp.company.btnDelete">Delete</Translate>
        </>
      ),
    }).then(async response => {
      if (response.isConfirmed) {
        await this.props.deleteEntity(companyEntity.id, { reloadList: false });
        this.getEntities();
        MySwal.fire({
          title: <Translate contentKey="entity.delete.title.confirmed">Confirmed delete operation</Translate>,
          icon: 'success',
        });
      }
    });
  }

  renderNewModal() {
    return (
      <Modal size={'xl'} isOpen={this.state.showModalForm} toggle={() => this.setState({ showModalForm: false })}>
        <ModalHeader toggle={() => this.setState({ showModalForm: false })}>{this.renderHeaderUpdate({}, true)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefNew = el)}
          model={{}}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, true)}
        >
          <ModalBody>{this.renderBodyUpdate({}, true)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalForm: false })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.company.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefNew && this.myFormRefNew.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.company.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    );
  }

  getFullEntityFromState(v) {
    return {
      id: v.id,
      socialReason: this.state.socialReasonSelectValue,
      fantasyName: this.state.fantasyNameSelectValue,
      cnpj: this.state.cnpjSelectValue,
      zipCode: this.state.zipCodeSelectValue,
      street: this.state.streetSelectValue,
      complement: this.state.complementSelectValue,
      number: this.state.numberSelectValue,
      neighborhood: this.state.neighborhoodSelectValue,
      city: this.state.citySelectValue,
      uf: this.state.ufSelectValue,
      regANS: this.state.regANSSelectValue,
      regCNES: this.state.regCNESSelectValue,
      technicalManager: this.state.technicalManagerSelectValue,
      technicalManagerNurse: this.state.technicalManagerNurseSelectValue,
      inscription: this.state.inscriptionSelectValue,
      councilCode: this.state.councilCodeSelectValue,
      ufCode: this.state.ufCodeSelectValue,
      cboCode: this.state.cboCodeSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    const p: any = null;

    return {
      socialReasonSelectValue: v.socialReason, // socialReason,
      fantasyNameSelectValue: v.fantasyName, // fantasyName,
      cnpjSelectValue: v.cnpj, // cnpj,
      zipCodeSelectValue: v.zipCode, // zipCode,
      streetSelectValue: v.street, // street,
      complementSelectValue: v.complement, // complement,
      numberSelectValue: v.number, // number,
      neighborhoodSelectValue: v.neighborhood, // neighborhood,
      citySelectValue: v.city, // city,
      ufSelectValue: v.uf, // uf,
      regANSSelectValue: v.regANS, // regANS,
      regCNESSelectValue: v.regCNES, // regCNES,
      technicalManagerSelectValue: v.technicalManager, // technicalManager,
      technicalManagerNurseSelectValue: v.technicalManagerNurse, // technicalManagerNurse,
      inscriptionSelectValue: v.inscription, // inscription,
      councilCodeSelectValue: v.councilCode, // councilCode,
      ufCodeSelectValue: v.ufCode, // ufCode,
      cboCodeSelectValue: v.cboCode, // cboCode,
    };
  }

  openUpdateModal(v, edit = true) {
    this.props.getEntity(v.id);
    this.setState({
      showModalEdit: v.id,
      showModalEditView: edit,
      ...this.getFullEntitySelectValue(v),
    });
  }

  renderUpdateModal() {
    const { companyEntity, entityEmbebedPai } = this.props;
    return companyEntity && companyEntity.id ? (
      <Modal size={'xl'} isOpen={this.state.showModalEdit === companyEntity.id} toggle={() => this.setState({ showModalEdit: null })}>
        <ModalHeader toggle={() => this.setState({ showModalEdit: null })}>{this.renderHeaderUpdate(companyEntity, false)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefEdit = el)}
          model={{
            ...companyEntity,
          }}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, false)}
        >
          <ModalBody>{this.renderBodyUpdate(companyEntity, false)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalEdit: null })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.company.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefEdit && this.myFormRefEdit.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.company.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    ) : (
      <> </>
    );
  }

  renderHeaderUpdate(companyEntity, isNew) {
    const { entityEmbebedPai, loading } = this.props;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {isNew ? (
              <Translate contentKey="generadorApp.company.home.createLabel">Create a Company</Translate>
            ) : (
              <Translate contentKey="generadorApp.company.home.editLabel">Edit a Company</Translate>
            )}
          </span>
        </div>
      </>
    );
  }
  renderBodyUpdate(companyEntity, isNew) {
    const { entityEmbebedPai, loading } = this.props;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
              <Col md="3">
              <Label className="mt-2 label-single-line"for="company-id">
                <Translate contentKey="generadorApp.company.field_id">ID</Translate>
              </Label>
              </Col> */}
                      <Col md="12">
                        <AvInput id="company-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'socialReason' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="socialReasonLabel" for="company-socialReason">
                              <Translate contentKey="generadorApp.company.socialReason">Social Reason</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ socialReasonSelectValue: evt.target.value })}
                              value={this.state.socialReasonSelectValue ? this.state.socialReasonSelectValue : ''}
                              id="company-socialReason"
                              type="text"
                              name="socialReason"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ socialReasonSelectValue: evt.target.value })}
                      type="hidden"
                      name="socialReason"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'fantasyName' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="fantasyNameLabel" for="company-fantasyName">
                              <Translate contentKey="generadorApp.company.fantasyName">Fantasy Name</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ fantasyNameSelectValue: evt.target.value })}
                              value={this.state.fantasyNameSelectValue ? this.state.fantasyNameSelectValue : ''}
                              id="company-fantasyName"
                              type="text"
                              name="fantasyName"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ fantasyNameSelectValue: evt.target.value })}
                      type="hidden"
                      name="fantasyName"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'cnpj' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="cnpjLabel" for="company-cnpj">
                              <Translate contentKey="generadorApp.company.cnpj">Cnpj</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <InputMask
                              onChange={evt => this.setState({ cnpjSelectValue: evt.target.value })}
                              value={this.state.cnpjSelectValue ? this.state.cnpjSelectValue : ''}
                              id="company-cnpj"
                              type="text"
                              name="cnpj"
                              className={'form-control'}
                              mask="99.999.999/9999-99"
                              tag={InputMask}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ cnpjSelectValue: evt.target.value })}
                      type="hidden"
                      name="cnpj"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'zipCode' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label
                              className={'mt-2' + (this.state.cepRequestCep === '' ? ' text-danger' : '')}
                              id="zipCodeLabel"
                              for="company-zipCode"
                            >
                              <Translate contentKey="generadorApp.company.zipCode">Zip Code</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              id="company-zipCode"
                              type="text"
                              name="zipCode"
                              className={
                                'form-control' + (this.state.cepRequestCep === '' ? ' is-touched is-pristine av-invalid is-invalid' : '')
                              }
                              value={this.state.cepRequestCep}
                              onBlur={inputVal => {
                                viacepRequest(inputVal.target.value, (cepRequest: any) => {
                                  this.setState(cepRequest);
                                });
                                this.setState({ zipCodeSelectValue: inputVal.target.value });
                                this.setState({ cepRequestCep: inputVal.target.value ? inputVal.target.value : '' });
                              }}
                              onChange={inputVal => {
                                this.setState({ cepRequestCep: inputVal.target.value });
                                this.setState({ zipCodeSelectValue: inputVal.target.value });
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ zipCodeSelectValue: evt.target.value })}
                      type="hidden"
                      name="zipCode"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'street' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="streetLabel" for="company-street">
                              <Translate contentKey="generadorApp.company.street">Street</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              id="company-street"
                              type="text"
                              name="street"
                              className={'form-control'}
                              value={this.state.cepRequestLogradouro}
                              onBlur={inputVal =>
                                this.setState({ cepRequestLogradouro: inputVal.target.value ? inputVal.target.value : '' })
                              }
                              onChange={inputVal => {
                                this.setState({ cepRequestLogradouro: inputVal.target.value });
                                this.setState({ streetSelectValue: inputVal.target.value });
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ streetSelectValue: evt.target.value })}
                      type="hidden"
                      name="street"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'complement' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="complementLabel" for="company-complement">
                              <Translate contentKey="generadorApp.company.complement">Complement</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ complementSelectValue: evt.target.value })}
                              value={this.state.complementSelectValue ? this.state.complementSelectValue : ''}
                              id="company-complement"
                              type="text"
                              name="complement"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ complementSelectValue: evt.target.value })}
                      type="hidden"
                      name="complement"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'number' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="numberLabel" for="company-number">
                              <Translate contentKey="generadorApp.company.number">Number</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ numberSelectValue: evt.target.value })}
                              value={this.state.numberSelectValue ? this.state.numberSelectValue : ''}
                              id="company-number"
                              type="text"
                              name="number"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ numberSelectValue: evt.target.value })}
                      type="hidden"
                      name="number"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'neighborhood' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="neighborhoodLabel" for="company-neighborhood">
                              <Translate contentKey="generadorApp.company.neighborhood">Neighborhood</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              id="company-neighborhood"
                              type="text"
                              name="neighborhood"
                              className={'form-control'}
                              value={this.state.cepRequestBairro}
                              onBlur={inputVal => this.setState({ cepRequestBairro: inputVal.target.value ? inputVal.target.value : '' })}
                              onChange={inputVal => {
                                this.setState({ cepRequestBairro: inputVal.target.value });
                                this.setState({ neighborhoodSelectValue: inputVal.target.value });
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ neighborhoodSelectValue: evt.target.value })}
                      type="hidden"
                      name="neighborhood"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'city' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="cityLabel" for="company-city">
                              <Translate contentKey="generadorApp.company.city">City</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              id="company-city"
                              type="text"
                              name="city"
                              className={'form-control'}
                              value={this.state.cepRequestLocalidade}
                              onBlur={inputVal =>
                                this.setState({ cepRequestLocalidade: inputVal.target.value ? inputVal.target.value : '' })
                              }
                              onChange={inputVal => {
                                this.setState({ cepRequestLocalidade: inputVal.target.value });
                                this.setState({ citySelectValue: inputVal.target.value });
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ citySelectValue: evt.target.value })}
                      type="hidden"
                      name="city"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'uf' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="ufLabel" for="company-uf">
                              <Translate contentKey="generadorApp.company.uf">Uf</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              id="company-uf"
                              type="text"
                              name="uf"
                              className={'form-control'}
                              value={this.state.cepRequestUf}
                              onBlur={inputVal => this.setState({ cepRequestUf: inputVal.target.value ? inputVal.target.value : '' })}
                              onChange={inputVal => {
                                this.setState({ cepRequestUf: inputVal.target.value });
                                this.setState({ ufSelectValue: inputVal.target.value });
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ ufSelectValue: evt.target.value })}
                      type="hidden"
                      name="uf"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'regANS' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="regANSLabel" for="company-regANS">
                              <Translate contentKey="generadorApp.company.regANS">Reg. ANS</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ regANSSelectValue: evt.target.value })}
                              value={this.state.regANSSelectValue ? this.state.regANSSelectValue : ''}
                              id="company-regANS"
                              type="text"
                              name="regANS"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ regANSSelectValue: evt.target.value })}
                      type="hidden"
                      name="regANS"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'regCNES' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="regCNESLabel" for="company-regCNES">
                              <Translate contentKey="generadorApp.company.regCNES">Reg. CNES</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ regCNESSelectValue: evt.target.value })}
                              value={this.state.regCNESSelectValue ? this.state.regCNESSelectValue : ''}
                              id="company-regCNES"
                              type="text"
                              name="regCNES"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ regCNESSelectValue: evt.target.value })}
                      type="hidden"
                      name="regCNES"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'technicalManager' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="technicalManagerLabel" for="company-technicalManager">
                              <Translate contentKey="generadorApp.company.technicalManager">Technical manager</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ technicalManagerSelectValue: evt.target.value })}
                              value={this.state.technicalManagerSelectValue ? this.state.technicalManagerSelectValue : ''}
                              id="company-technicalManager"
                              type="text"
                              name="technicalManager"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ technicalManagerSelectValue: evt.target.value })}
                      type="hidden"
                      name="technicalManager"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'technicalManagerNurse' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="technicalManagerNurseLabel" for="company-technicalManagerNurse">
                              <Translate contentKey="generadorApp.company.technicalManagerNurse">Technical nurse manager</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ technicalManagerNurseSelectValue: evt.target.value })}
                              value={this.state.technicalManagerNurseSelectValue ? this.state.technicalManagerNurseSelectValue : ''}
                              id="company-technicalManagerNurse"
                              type="text"
                              name="technicalManagerNurse"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ technicalManagerNurseSelectValue: evt.target.value })}
                      type="hidden"
                      name="technicalManagerNurse"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'inscription' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="inscriptionLabel" for="company-inscription">
                              <Translate contentKey="generadorApp.company.inscription">Inscription</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ inscriptionSelectValue: evt.target.value })}
                              value={this.state.inscriptionSelectValue ? this.state.inscriptionSelectValue : ''}
                              id="company-inscription"
                              type="text"
                              name="inscription"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ inscriptionSelectValue: evt.target.value })}
                      type="hidden"
                      name="inscription"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'councilCode' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="councilCodeLabel" for="company-councilCode">
                              <Translate contentKey="generadorApp.company.councilCode">Council Code</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ councilCodeSelectValue: evt.target.value })}
                              value={this.state.councilCodeSelectValue ? this.state.councilCodeSelectValue : ''}
                              id="company-councilCode"
                              type="text"
                              name="councilCode"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ councilCodeSelectValue: evt.target.value })}
                      type="hidden"
                      name="councilCode"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'ufCode' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="ufCodeLabel" for="company-ufCode">
                              <Translate contentKey="generadorApp.company.ufCode">UF Code</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ ufCodeSelectValue: evt.target.value })}
                              value={this.state.ufCodeSelectValue ? this.state.ufCodeSelectValue : ''}
                              id="company-ufCode"
                              type="text"
                              name="ufCode"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ ufCodeSelectValue: evt.target.value })}
                      type="hidden"
                      name="ufCode"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'cboCode' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="cboCodeLabel" for="company-cboCode">
                              <Translate contentKey="generadorApp.company.cboCode">CBO Code</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ cboCodeSelectValue: evt.target.value })}
                              value={this.state.cboCodeSelectValue ? this.state.cboCodeSelectValue : ''}
                              id="company-cboCode"
                              type="text"
                              name="cboCode"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ cboCodeSelectValue: evt.target.value })}
                      type="hidden"
                      name="cboCode"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  renderHeader() {
    const { companyList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.company.home.title">Companies</Translate>
          </span>
          <Button id="togglerFilterCompany" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.company.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          &nbsp;
          {hasAnyAuthority(props.userAccount, ['company'], 'resgister') ? (
            <Button
              onClick={() =>
                this.setState({
                  showModalForm: true,
                  socialReasonSelectValue: null,
                  fantasyNameSelectValue: null,
                  cnpjSelectValue: null,
                  zipCodeSelectValue: null,
                  streetSelectValue: null,
                  complementSelectValue: null,
                  numberSelectValue: null,
                  neighborhoodSelectValue: null,
                  citySelectValue: null,
                  ufSelectValue: null,
                  regANSSelectValue: null,
                  regCNESSelectValue: null,
                  technicalManagerSelectValue: null,
                  technicalManagerNurseSelectValue: null,
                  inscriptionSelectValue: null,
                  councilCodeSelectValue: null,
                  ufCodeSelectValue: null,
                  cboCodeSelectValue: null,
                  isNew: true,
                })
              }
              color="primary"
              className="btn btn-primary float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="plus" />
              &nbsp;
              <Translate contentKey="generadorApp.company.btnNewItem">Create new</Translate>
            </Button>
          ) : (
            <></>
          )}{' '}
          &nbsp;
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.company.home.title">Companies</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    const { companyList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <UncontrolledCollapse toggler="#togglerFilterCompany">
          <CardBody>
            <AvForm ref={el => (this.myFormRef = el)} id="form-filter" onSubmit={this.filterEntity}>
              <div className="row mt-1 ml-3 mr-3">
                {this.state.baseFilters !== 'socialReason' ? (
                  <Col md="3" className="col-filter-company-socialReason">
                    <Row className="mr-1 mt-1">
                      <Label id="socialReasonLabel" for="company-socialReason">
                        <Translate contentKey="generadorApp.company.socialReason">Social Reason</Translate>
                      </Label>

                      <AvInput type="text" name="socialReason" id="company-socialReason" value={this.state.socialReason} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'fantasyName' ? (
                  <Col md="3" className="col-filter-company-fantasyName">
                    <Row className="mr-1 mt-1">
                      <Label id="fantasyNameLabel" for="company-fantasyName">
                        <Translate contentKey="generadorApp.company.fantasyName">Fantasy Name</Translate>
                      </Label>

                      <AvInput type="text" name="fantasyName" id="company-fantasyName" value={this.state.fantasyName} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'cnpj' ? (
                  <Col md="3" className="col-filter-company-cnpj">
                    <Row className="mr-1 mt-1">
                      <Label id="cnpjLabel" for="company-cnpj">
                        <Translate contentKey="generadorApp.company.cnpj">Cnpj</Translate>
                      </Label>

                      <AvInput type="text" name="cnpj" id="company-cnpj" value={this.state.cnpj} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'zipCode' ? (
                  <Col md="3" className="col-filter-company-zipCode">
                    <Row className="mr-1 mt-1">
                      <Label id="zipCodeLabel" for="company-zipCode">
                        <Translate contentKey="generadorApp.company.zipCode">Zip Code</Translate>
                      </Label>

                      <AvInput type="text" name="zipCode" id="company-zipCode" value={this.state.zipCode} />
                    </Row>
                  </Col>
                ) : null}
              </div>

              <div className="row mb-2 mr-4 justify-content-end">
                <Button color="primary" type="submit">
                  <i className="fa fa-filter" aria-hidden={'true'}></i>&nbsp;
                  <Translate contentKey="generadorApp.company.btnFilter">Filter</Translate>
                </Button>
                &nbsp;
                <Button color="secondary" onClick={this.cancelFilters}>
                  <FontAwesomeIcon icon="trash-alt" />
                  &nbsp;
                  <Translate contentKey="generadorApp.company.btnCleanFilter">Clean Filter</Translate>
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </UncontrolledCollapse>
      </>
    );
  }

  renderTable() {
    const { companyList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {companyList && companyList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null).length > 0 ? (
          <>
            <Table
              id="company-table-list"
              responsive
              aria-describedby="company-heading"
              className={'table-hover table-striped table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.company.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'socialReason' ? (
                    <th className="hand" onClick={sortFunction('socialReason')}>
                      <Translate contentKey="generadorApp.company.socialReason">Social Reason</Translate>
                      <FontAwesomeIcon icon={state.sort === 'socialReason' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'fantasyName' ? (
                    <th className="hand" onClick={sortFunction('fantasyName')}>
                      <Translate contentKey="generadorApp.company.fantasyName">Fantasy Name</Translate>
                      <FontAwesomeIcon icon={state.sort === 'fantasyName' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'cnpj' ? (
                    <th className="hand" onClick={sortFunction('cnpj')}>
                      <Translate contentKey="generadorApp.company.cnpj">Cnpj</Translate>
                      <FontAwesomeIcon icon={state.sort === 'cnpj' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'zipCode' ? (
                    <th className="hand" onClick={sortFunction('zipCode')}>
                      <Translate contentKey="generadorApp.company.zipCode">Zip Code</Translate>
                      <FontAwesomeIcon icon={state.sort === 'zipCode' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}

                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {companyList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((company, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.company.field_id">ID</Translate>
                        </b>
                        {company.id}
                      </td>

                      {state.baseFilters !== 'socialReason' ? (
                        <td id="socialReason-cell">
                          <b className="visible-xs"> Social Reason </b>

                          {company.socialReason}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'fantasyName' ? (
                        <td id="fantasyName-cell">
                          <b className="visible-xs"> Fantasy Name </b>

                          {company.fantasyName}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'cnpj' ? (
                        <td id="cnpj-cell">
                          <b className="visible-xs"> Cnpj </b>

                          {company.cnpj}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'zipCode' ? (
                        <td id="zipCode-cell">
                          <b className="visible-xs"> Zip Code </b>

                          {company.zipCode}
                        </td>
                      ) : null}

                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          {hasAnyAuthority(props.userAccount, ['company'], 'edit') ? (
                            <Button onClick={() => this.openUpdateModal(company)} color="primary" size="sm">
                              <FontAwesomeIcon icon="pencil-alt" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                          {hasAnyAuthority(props.userAccount, ['company'], 'canDelete') ? (
                            <Button onClick={() => this.deleteEntity(company)} color="danger" size="sm">
                              <FontAwesomeIcon icon="trash" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.company.home.notFound">No Companies found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const { companyList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={companyList && companyList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const { companyList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel className="company company-all-in-one">
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
            {this.renderNewModal()}
            {this.renderUpdateModal()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToProps = ({ company, ...storeState }: IRootState) => ({
  companyList: company.entities,
  totalItems: company.totalItems,
  userAccount: storeState.authentication.account,
  loading: company.loading,

  // UPDATE
  companyEntity: company.entity,
});

export const mapDispatchToProps = {
  getEntities,

  updateEntity,
  reset,

  // UPDATE
  getEntity,
  createEntity,

  // DELETE
  deleteEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Company);
