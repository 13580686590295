/* eslint complexity: ["error", 500] */
/* eslint no-empty: off */
import React from 'react';
import { connect } from 'react-redux';

import SelectAsync from 'react-select/async';

import { Link, RouteComponentProps } from 'react-router-dom';

import { getFilterFromSelect, getFormFromSelect, getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Col, Label } from 'reactstrap';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import {
  INursingDiaryUpdateState as IBaseUpdateState,
  getEntity,
  getNursingDiaryState,
  getUrlBack,
  updateEntity,
  createEntity,
  getEntityFiltersURL,
  getEntityOffset,
  reset,
} from './nursing-diary.reducer';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

registerLocale('pt', pt);

export interface INursingDiaryUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}
export type INursingDiaryUpdateState = IBaseUpdateState;

export class NursingDiaryUpdate extends React.Component<INursingDiaryUpdateProps, INursingDiaryUpdateState> {
  diaryFileInput: any;
  constructor(props: Readonly<INursingDiaryUpdateProps>) {
    super(props);

    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      functionEmbebed: [],

      userSelectValue: null,

      configTagSelectValue: null,

      patientSelectValue: null,

      diarySelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getNursingDiaryState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
      idsconfigTag: [],
      userId: null,
      patientName: null,

      isNew: !this.props.match.params || !this.props.match.params['id'],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateSuccess !== this.props.updateSuccess && prevProps.updateSuccess) {
      this.handleClose();
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.nursingDiaryEntity && prevProps.nursingDiaryEntity.id !== this.props.nursingDiaryEntity.id) ||
      (this.props.users &&
        this.state.userSelectValue === null &&
        this.props.nursingDiaryEntity.user &&
        this.props.nursingDiaryEntity.user.id)
    ) {
      const p = this.props.nursingDiaryEntity && this.props.nursingDiaryEntity.user ? this.props.nursingDiaryEntity.user : null;
      this.setState({
        userSelectValue: p ? { ...p, value: p.id, label: p['id'] ? p.id : '' } : null,
      });
    }

    // data-type-rel -> tipo-1 many-to-many owner-side
    if (
      (prevProps.nursingDiaryEntity && prevProps.nursingDiaryEntity.id !== this.props.nursingDiaryEntity.id) ||
      (this.props.configTags.length > 0 && this.state.configTagSelectValue === null && this.props.nursingDiaryEntity.configTags)
    ) {
      this.setState({
        configTagSelectValue:
          this.props.nursingDiaryEntity && this.props.nursingDiaryEntity.configTags
            ? this.props.nursingDiaryEntity.configTags.filter(p => p).map(p => ({ value: p.id, label: p['name'] ? p.name : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.nursingDiaryEntity && prevProps.nursingDiaryEntity.id !== this.props.nursingDiaryEntity.id) ||
      (this.props.patients &&
        this.state.patientSelectValue === null &&
        this.props.nursingDiaryEntity.patient &&
        this.props.nursingDiaryEntity.patient.id)
    ) {
      const p = this.props.nursingDiaryEntity && this.props.nursingDiaryEntity.patient ? this.props.nursingDiaryEntity.patient : null;
      this.setState({
        patientSelectValue: p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : null,
      });
    }

    if (
      (prevProps.nursingDiaryEntity && prevProps.nursingDiaryEntity.id !== this.props.nursingDiaryEntity.id) ||
      (this.props.nursingDiaryEntity.diary &&
        this.state.diarySelectValue === null &&
        this.props.nursingDiaryEntity.diary !== this.state.diarySelectValue)
    ) {
      this.setState({ diarySelectValue: this.props.nursingDiaryEntity.diary ? this.props.nursingDiaryEntity.diary : null });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  UNSAFE_componentWillMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params['id']);
    }
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = offset !== null ? '&offset=' + offset : '';
    Object.keys(fieldsBase).map(key => {
      url += key !== 'offset' ? '&' + key + '=' + fieldsBase[key] : '';
    });
    return url;
  };
  saveEntity = (event: any, errors: any, values: any) => {
    if (!this.state.diarySelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.nursingDiary.diary') }));
      return false;
    }

    const selectFieldsList = ['diary', 'patient.name', 'createdBy.login', 'createdDate'];

    if (errors.length === 0) {
      const { nursingDiaryEntity } = this.props;
      const _entity = {
        ...nursingDiaryEntity,
        // ...values,

        patient: getFormFromSelect(this.state.patientSelectValue, 'many-to-one'), // many-to-one - otherSide

        configTags: getFormFromSelect(this.state.configTagSelectValue, 'many-to-many'), // many-to-many - ownerSide

        diary: this.state.diarySelectValue,
      };

      const entity = _entity;

      const { patientId, createdById, diary, configTagId, extraFilters, activePage, itemsPerPage, sort, order } = this.state.fieldsBase;

      if (this.state.isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              getFilterFromSelect(patientId, 'many-to-one'),
              getFilterFromSelect(createdById, 'many-to-one'),
              diary,
              getFilterFromSelect(configTagId, 'many-to-many'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              getFilterFromSelect(patientId, 'many-to-one'),
              getFilterFromSelect(createdById, 'many-to-one'),
              diary,
              getFilterFromSelect(configTagId, 'many-to-many'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/nursing-diary?' + getEntityFiltersURL(this.state.fieldsBase));
  };

  getFullEntityFromState(v) {
    return {
      id: v.id,
      diary: this.state.diarySelectValue,
      user: this.state.userSelectValue,
      configTag: this.state.configTagSelectValue,
      patient: this.state.patientSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    const userEntity = v.user;

    const configTagEntity = this.props.configTags.filter(x => x.nursingDiaries.map(x1 => x1.id).includes(v.id)).pop();

    const patientEntity = v.patient;

    return {
      diarySelectValue: v.diary,
      userSelectValue: userEntity ? userEntity['id'] : null,
      configTagSelectValue: configTagEntity ? configTagEntity['id'] : null,
      patientSelectValue: patientEntity ? patientEntity['id'] : null,
    };
  }

  renderHeader() {
    const { nursingDiaryEntity, users, configTags, patients, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.nursingDiary.home.createLabel">Create a NursingDiary</Translate>
            ) : (
              <Translate contentKey="generadorApp.nursingDiary.home.editLabel">Edit a NursingDiary</Translate>
            )}
          </span>

          <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.nursingDiary.btnSave">Save</Translate>
          </Button>

          {!this.state.isNew ? (
            <>
              <Button
                color="primary"
                id="next-entity"
                disabled={updating}
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.patientId,
                    this.state.fieldsBase.createdById,
                    this.state.fieldsBase.diary,
                    this.state.fieldsBase.configTagId,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 + 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 + 1 },
                    });
                    this.props.history.push(
                      '/nursing-diary/' + myresult.value.data[0]['id'] + '/edit?' + this.getFiltersURL(this.state.fieldsBase.offset * 1 + 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.last.item', { fieldName: translate('generadorApp.nursingDiary.detail.title') }));
                  }
                }}
                className="float-right jh-create-entity"
              >
                {'>>'}
              </Button>

              <Button
                color="primary"
                id="prev-entity"
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.patientId,
                    this.state.fieldsBase.createdById,
                    this.state.fieldsBase.diary,
                    this.state.fieldsBase.configTagId,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 - 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 - 1 },
                    });
                    this.props.history.push(
                      '/nursing-diary/' + myresult.value.data[0]['id'] + '/edit?' + this.getFiltersURL(this.state.fieldsBase.offset * 1 - 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.first.item', { fieldName: translate('generadorApp.nursingDiary.detail.title') }));
                  }
                }}
                disabled={updating}
                className="float-right jh-create-entity"
              >
                {'<<'}
              </Button>
            </>
          ) : (
            <> </>
          )}

          <Button
            tag={Link}
            id="cancel-save"
            to={'/nursing-diary?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.nursingDiary.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={'/nursing-diary'}>
              <Translate contentKey="generadorApp.nursingDiary.home.title">Nursing Diaries</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.nursingDiary.detail.title">Nursing Diary edit</Translate>
          </li>
        </ol>
      </>
    );
  }
  renderBody() {
    const { nursingDiaryEntity, users, configTags, patients, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const fullEntityEmbebed = this.getFullEntityFromState(nursingDiaryEntity);

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="nursing-diary-id">
                  <Translate contentKey="generadorApp.nursingDiary.field_id">ID</Translate>
                </Label>
                </Col> */}
                      <Col md="12">
                        <AvInput id="nursing-diary-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'patient' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="nursing-diary-patient">
                              <Translate contentKey="generadorApp.nursingDiary.patient">Patient</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="nursing-diary-patient"
                              name={'patient'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.patientSelectValue ? this.state.patientSelectValue : ''}
                              onChange={options => this.setState({ patientSelectValue: options })}
                              defaultOptions={this.state.patientStartSelectOptions ? this.state.patientStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.patientStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.patientStartSelectOptions === undefined) {
                                  const result = await getListAxios('patients', {}, 0, 100, 'name,asc', 'name');
                                  this.setState({
                                    patientStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios('patients', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="patient"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}
                  {baseFilters !== 'diary' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="diaryLabel" for="nursing-diary-diary">
                              <Translate contentKey="generadorApp.nursingDiary.diary">Diary</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ diarySelectValue: evt.target.value })}
                              value={this.state.diarySelectValue ? this.state.diarySelectValue : ''}
                              id="nursing-diary-diary"
                              type="text"
                              name="diary"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ diarySelectValue: evt.target.value })}
                      type="hidden"
                      name="diary"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'configTag' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="nursing-diary-configTag">
                              <Translate contentKey="generadorApp.nursingDiary.configTag">Config Tag</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              isMulti
                              id="nursing-diary-configTag"
                              name={'configTag'}
                              className={'css-select-control'}
                              data-type-rel="many-to-many-owner-side"
                              value={this.state.configTagSelectValue ? this.state.configTagSelectValue : ''}
                              onChange={options => this.setState({ configTagSelectValue: options })}
                              defaultOptions={this.state.configTagStartSelectOptions ? this.state.configTagStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.configTagStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.configTagStartSelectOptions === undefined) {
                                  const result = await getListAxios(
                                    'config-tags',
                                    { 'isMedicalRecord.equals': false },
                                    0,
                                    100,
                                    'name,asc',
                                    'name'
                                  );
                                  this.setState({
                                    configTagStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'config-tags',
                                  { 'isMedicalRecord.equals': false, 'name.contains': inputValue },
                                  0,
                                  100,
                                  'name,asc',
                                  'name'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="configTag"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}{' '}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { nursingDiaryEntity, users, configTags, patients, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...nursingDiaryEntity,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel className="nursing-diary nursing-diary-update">
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToProps = (storeState: IRootState) => {
  return {
    users: storeState.userManagement.users,
    configTags: storeState.configTag.entities,
    patients: storeState.patient.entities,
    nursingDiaryEntity: storeState.nursingDiary.entity,
    loading: storeState.nursingDiary.loading,
    updating: storeState.nursingDiary.updating,
    updateSuccess: storeState.nursingDiary.updateSuccess,
  };
};

export const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  getEntityOffset,
  reset,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(NursingDiaryUpdate);
