export const selectFieldsList = [
  'padAttendance.id',
  'padAttendance.quoteRh.id',
  'quote.id',
  'patient.client.fantasyName',
  'patient.name',
  'initDate',
  'finalDate',
  'quote.quoteRh.specialty.category.name',
  'quote.quoteRh.days',
];
