import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';
import moment from 'moment';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity, IMedicalRecordBaseState, getUrlBack, getMedicalRecordState, getEntityFiltersURL } from './medical-record.reducer';

import { APP_DATE_FORMAT } from 'app/config/constants';

export interface IMedicalRecordState {
  fieldsBase: IMedicalRecordBaseState;
}

export interface IMedicalRecordDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class MedicalRecordDetail extends React.Component<IMedicalRecordDetailProps, IMedicalRecordState> {
  constructor(props: Readonly<IMedicalRecordDetailProps>) {
    super(props);
    this.state = {
      ...this.state,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getMedicalRecordState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
    };
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = '_back=1' + (offset !== null ? '&offset=' + offset : '');
    Object.keys(fieldsBase).map(key => {
      url += '&' + key + '=' + fieldsBase[key];
    });
    return url;
  };

  UNSAFE_componentWillMount() {
    this.props.getEntity(this.props.match.params['id']);
  }

  renderHeader() {
    const { medicalRecordEntity } = this.props;
    const state = this.state;
    return (
      <>
        <div id="page-heading">
          {' '}
          <h2>
            <Translate contentKey="generadorApp.medicalRecord.detail.title">MedicalRecord</Translate>
            <small>&nbsp; {medicalRecordEntity['passageReason']}</small>
          </h2>
        </div>

        <div className="pull-right">
          <Button tag={Link} to={'/medical-record?' + getEntityFiltersURL(this.state.fieldsBase)} replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.medicalRecord.btnBack">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/medical-record/${medicalRecordEntity.id}/edit?+${getEntityFiltersURL(this.state.fieldsBase)}`}
            replace
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.medicalRecord.btnEdit">Edit</Translate>
            </span>
          </Button>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>

          <li className="breadcrumb-item active">
            <Link to={'/medical-record'}>Medical Records</Link>
          </li>
          <li className="breadcrumb-item active">Medical Records details</li>
        </ol>
      </>
    );
  }

  renderBody() {
    const { medicalRecordEntity } = this.props;
    const state = this.state;
    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            <Row className="jh-entity-details">
              <Col className="view-detail-row medical-record-patient-detail" md="6">
                <Row>
                  <Col className="view-detail-label-top medical-record-patient-detail-label" md="12">
                    <dt>
                      <Translate contentKey="generadorApp.medicalRecord.patient">Patient</Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top medical-record-patient-detail-value" md="12">
                    <dd>{medicalRecordEntity.patient ? medicalRecordEntity.patient.id : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row medical-record-date-detail" md="6">
                <Row>
                  <Col className="view-detail-label-top medical-record-date-detail-label" md="12">
                    <dt>
                      <span id="date">
                        <Translate contentKey="generadorApp.medicalRecord.date">Date of occurrence</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top medical-record-date-detail-value" md="12">
                    <dd>{medicalRecordEntity.date ? moment(medicalRecordEntity.date).format(APP_DATE_FORMAT) : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row medical-record-record-detail" md="6">
                <Row>
                  <Col className="view-detail-label-top medical-record-record-detail-label" md="12">
                    <dt>
                      <span id="record">
                        <Translate contentKey="generadorApp.medicalRecord.record">Medical record</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top medical-record-record-detail-value" md="12">
                    <dd>
                      {medicalRecordEntity.record
                        ? translate('generadorApp.medicalRecord.record.true')
                        : translate('generadorApp.medicalRecord.record.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row medical-record-configTag-detail" md="6">
                <Row>
                  <Col className="view-detail-label-top medical-record-configTag-detail-label" md="12">
                    <dt>
                      <Translate contentKey="generadorApp.medicalRecord.configTag">Config Tag</Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top medical-record-configTag-detail-value" md="12">
                    <dd>
                      {medicalRecordEntity.configTags
                        ? medicalRecordEntity.configTags.map((val, i) => (
                            <span key={val.id}>
                              <a>{val.name}</a>
                              {i === medicalRecordEntity.configTags.length - 1 ? '' : ', '}
                            </span>
                          ))
                        : null}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row medical-record-clinicalIndicator-detail" md="6">
                <Row>
                  <Col className="view-detail-label-top medical-record-clinicalIndicator-detail-label" md="12">
                    <dt>
                      <Translate contentKey="generadorApp.medicalRecord.clinicalIndicator">Clinical Indicator</Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top medical-record-clinicalIndicator-detail-value" md="12">
                    <dd>
                      {medicalRecordEntity.clinicalIndicators
                        ? medicalRecordEntity.clinicalIndicators.map((val, i) => (
                            <span key={val.id}>
                              <a>{val.name}</a>
                              {i === medicalRecordEntity.clinicalIndicators.length - 1 ? '' : ', '}
                            </span>
                          ))
                        : null}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row medical-record-passageReason-detail" md="6">
                <Row>
                  <Col className="view-detail-label-top medical-record-passageReason-detail-label" md="12">
                    <dt>
                      <span id="passageReason">
                        <Translate contentKey="generadorApp.medicalRecord.passageReason">Passage Reason</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top medical-record-passageReason-detail-value" md="12">
                    <dd>{medicalRecordEntity.passageReason}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row medical-record-fall-detail" md="6">
                <Row>
                  <Col className="view-detail-label-top medical-record-fall-detail-label" md="12">
                    <dt>
                      <span id="fall">
                        <Translate contentKey="generadorApp.medicalRecord.fall">Fall</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top medical-record-fall-detail-value" md="12">
                    <dd>{medicalRecordEntity.fall}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row medical-record-clinicalInstability-detail" md="6">
                <Row>
                  <Col className="view-detail-label-top medical-record-clinicalInstability-detail-label" md="12">
                    <dt>
                      <span id="clinicalInstability">
                        <Translate contentKey="generadorApp.medicalRecord.clinicalInstability">Clinical Instability</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top medical-record-clinicalInstability-detail-value" md="12">
                    <dd>{medicalRecordEntity.clinicalInstability}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row medical-record-exam-detail" md="6">
                <Row>
                  <Col className="view-detail-label-top medical-record-exam-detail-label" md="12">
                    <dt>
                      <span id="exam">
                        <Translate contentKey="generadorApp.medicalRecord.exam">Exam</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top medical-record-exam-detail-value" md="12">
                    <dd>{translate('generadorApp.Exam.' + (medicalRecordEntity.exam ? medicalRecordEntity.exam : 'CLINICO'))}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row medical-record-examType-detail" md="6">
                <Row>
                  <Col className="view-detail-label-top medical-record-examType-detail-label" md="12">
                    <dt>
                      <span id="examType">
                        <Translate contentKey="generadorApp.medicalRecord.examType">Exam Type</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top medical-record-examType-detail-value" md="12">
                    <dd>{translate('generadorApp.ExamType.' + (medicalRecordEntity.examType ? medicalRecordEntity.examType : 'RAIOX'))}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row medical-record-hospitalizationDate-detail" md="6">
                <Row>
                  <Col className="view-detail-label-top medical-record-hospitalizationDate-detail-label" md="12">
                    <dt>
                      <span id="hospitalizationDate">
                        <Translate contentKey="generadorApp.medicalRecord.hospitalizationDate">Hospitalization Date</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top medical-record-hospitalizationDate-detail-value" md="12">
                    <dd>
                      {medicalRecordEntity.hospitalizationDate
                        ? moment(medicalRecordEntity.hospitalizationDate).format(APP_DATE_FORMAT)
                        : ''}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row medical-record-dischargeDate-detail" md="6">
                <Row>
                  <Col className="view-detail-label-top medical-record-dischargeDate-detail-label" md="12">
                    <dt>
                      <span id="dischargeDate">
                        <Translate contentKey="generadorApp.medicalRecord.dischargeDate">Discharge Date</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top medical-record-dischargeDate-detail-value" md="12">
                    <dd>{medicalRecordEntity.dischargeDate ? moment(medicalRecordEntity.dischargeDate).format(APP_DATE_FORMAT) : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row medical-record-hospitalizationReason-detail" md="6">
                <Row>
                  <Col className="view-detail-label-top medical-record-hospitalizationReason-detail-label" md="12">
                    <dt>
                      <span id="hospitalizationReason">
                        <Translate contentKey="generadorApp.medicalRecord.hospitalizationReason">Hospitalization Reason</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top medical-record-hospitalizationReason-detail-value" md="12">
                    <dd>{medicalRecordEntity.hospitalizationReason}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row medical-record-occurrence-detail" md="6">
                <Row>
                  <Col className="view-detail-label-top medical-record-occurrence-detail-label" md="12">
                    <dt>
                      <span id="occurrence">
                        <Translate contentKey="generadorApp.medicalRecord.occurrence">Occurrence</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top medical-record-occurrence-detail-value" md="12">
                    <dd>
                      {translate(
                        'generadorApp.Ocurrence.' + (medicalRecordEntity.occurrence ? medicalRecordEntity.occurrence : 'SONDANASOENTERAL')
                      )}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row medical-record-manifestation-detail" md="6">
                <Row>
                  <Col className="view-detail-label-top medical-record-manifestation-detail-label" md="12">
                    <dt>
                      <span id="manifestation">
                        <Translate contentKey="generadorApp.medicalRecord.manifestation">Manifestation</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top medical-record-manifestation-detail-value" md="12">
                    <dd>
                      {translate(
                        'generadorApp.Manifestation.' + (medicalRecordEntity.manifestation ? medicalRecordEntity.manifestation : 'ELOGIO')
                      )}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row medical-record-compliment-detail" md="6">
                <Row>
                  <Col className="view-detail-label-top medical-record-compliment-detail-label" md="12">
                    <dt>
                      <span id="compliment">
                        <Translate contentKey="generadorApp.medicalRecord.compliment">Compliment</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top medical-record-compliment-detail-value" md="12">
                    <dd>{medicalRecordEntity.compliment}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row medical-record-complaint-detail" md="6">
                <Row>
                  <Col className="view-detail-label-top medical-record-complaint-detail-label" md="12">
                    <dt>
                      <span id="complaint">
                        <Translate contentKey="generadorApp.medicalRecord.complaint">Complaint</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top medical-record-complaint-detail-value" md="12">
                    <dd>{medicalRecordEntity.complaint}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row medical-record-suggestion-detail" md="6">
                <Row>
                  <Col className="view-detail-label-top medical-record-suggestion-detail-label" md="12">
                    <dt>
                      <span id="suggestion">
                        <Translate contentKey="generadorApp.medicalRecord.suggestion">Suggestion</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top medical-record-suggestion-detail-value" md="12">
                    <dd>{medicalRecordEntity.suggestion}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row medical-record-additionalInformation-detail" md="6">
                <Row>
                  <Col className="view-detail-label-top medical-record-additionalInformation-detail-label" md="12">
                    <dt>
                      <span id="additionalInformation">
                        <Translate contentKey="generadorApp.medicalRecord.additionalInformation">Additional Information</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top medical-record-additionalInformation-detail-value" md="12">
                    <dd>{medicalRecordEntity.additionalInformation}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row medical-record-type-detail" md="6">
                <Row>
                  <Col className="view-detail-label-top medical-record-type-detail-label" md="12">
                    <dt>
                      <span id="type">
                        <Translate contentKey="generadorApp.medicalRecord.type">Type</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top medical-record-type-detail-value" md="12">
                    <dd>{medicalRecordEntity.type}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row medical-record-reason-detail" md="6">
                <Row>
                  <Col className="view-detail-label-top medical-record-reason-detail-label" md="12">
                    <dt>
                      <span id="reason">
                        <Translate contentKey="generadorApp.medicalRecord.reason">Reason</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top medical-record-reason-detail-value" md="12">
                    <dd>{medicalRecordEntity.reason}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row medical-record-conduct-detail" md="6">
                <Row>
                  <Col className="view-detail-label-top medical-record-conduct-detail-label" md="12">
                    <dt>
                      <span id="conduct">
                        <Translate contentKey="generadorApp.medicalRecord.conduct">Conduct</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top medical-record-conduct-detail-value" md="12">
                    <dd>{medicalRecordEntity.conduct}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row medical-record-highlighted-detail" md="6">
                <Row>
                  <Col className="view-detail-label-top medical-record-highlighted-detail-label" md="12">
                    <dt>
                      <span id="highlighted">
                        <Translate contentKey="generadorApp.medicalRecord.highlighted">Highlighted</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top medical-record-highlighted-detail-value" md="12">
                    <dd>
                      {medicalRecordEntity.highlighted
                        ? translate('generadorApp.medicalRecord.highlighted.true')
                        : translate('generadorApp.medicalRecord.highlighted.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { medicalRecordEntity } = this.props;
    const state = this.state;
    return (
      <div>
        <Panel className="medical-record medical-record-detail">
          <PanelHeader>{this.renderHeader()}</PanelHeader>

          <PanelBody>{this.renderBody()}</PanelBody>
        </Panel>
      </div>
    );
  }
}

export const mapStateToProps = ({ medicalRecord, ...storeState }: IRootState) => ({
  medicalRecordEntity: medicalRecord.entity,
});

export const mapDispatchToProps = {
  getEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MedicalRecordDetail);
