/* eslint complexity: ["error", 500] */
import axios from 'axios';

import { IPaginationBaseState, IPayloadResult } from 'react-jhipster';

import { IPayload } from 'react-jhipster/src/type/redux-action.type';

import { BASE_API_VERSION_PATH } from 'app/config/constants';

import { cleanEntity, jsonParse } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IKitMedicine, defaultValue } from 'app/shared/model/kit-medicine.model';

export declare type ICrudPutAction<T> = (data?: T, listFiltersPage?) => IPayload<T> | IPayloadResult<T>;
export declare type ICrudGetAction<T> = (id: string | number, selectFields?: string) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const ACTION_TYPES = {
  FETCH_KITMEDICINE_LIST_EXPORT: 'kitMedicine/FETCH_KITMEDICINE_LIST_EXPORT',
  FETCH_KITMEDICINE_LIST: 'kitMedicine/FETCH_KITMEDICINE_LIST',
  FETCH_KITMEDICINE: 'kitMedicine/FETCH_KITMEDICINE',
  FETCH_KITMEDICINE_OFFSET: 'kitMedicine/FETCH_KITMEDICINE_OFFSET',
  CREATE_KITMEDICINE: 'kitMedicine/CREATE_KITMEDICINE',
  UPDATE_KITMEDICINE: 'kitMedicine/UPDATE_KITMEDICINE',
  CREATE_KITMEDICINE_AND_GO_LIST: 'kitMedicine/CREATE_KITMEDICINE_AND_GO_LIST',
  UPDATE_KITMEDICINE_AND_GO_LIST: 'kitMedicine/UPDATE_KITMEDICINE_AND_GO_LIST',
  DELETE_KITMEDICINE: 'kitMedicine/DELETE_KITMEDICINE',
  RESET: 'kitMedicine/RESET',
  SHOW_MODAL: 'kitMedicine/SHOW_MODAL',
};

const initialState = {
  loading: false,
  openModal: {
    list: false,
    view: false,
    detail: false,
  },
  errorMessage: null,
  entities: [] as ReadonlyArray<IKitMedicine>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const actionPendind = [];
let actualState = initialState;

export const convertEntityBuffers = entity => ({
  ...entity,
});

export type KitMedicineState = Readonly<typeof initialState>;

export interface IKitMedicineBaseState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  listCheckedID?: Array<number>;
  isCheckedAll?: boolean;
  urlBack?: string;
  extraFilters?: any;
  baseFilters: any;
  offset: number;
  medicineMaterialId?: any;
  medicineMaterialStartFilter?: any;
  quantity?: any;
  kitId?: any;
  kitStartFilter?: any;
}

export interface IFieldsBase extends IKitMedicineBaseState, IPaginationBaseState {}
export interface IKitMedicineUpdateState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  urlBack?: string;
  functionEmbebed?: any[];
  fieldsBase: IFieldsBase;
  quantitySelectValue?: any;
  quantityStartSelectOptions?: any;

  kitSelectValue?: any;
  kitStartSelectOptions?: any;

  medicineMaterialSelectValue?: any;
  medicineMaterialStartSelectOptions?: any;

  isNew: boolean;
  kitName?: any;

  kitId?: any;
  medicineMaterialName?: any;

  medicineMaterialId?: any;
}

// Reducer

export default (state: KitMedicineState = initialState, action): KitMedicineState => {
  actualState = state;
  if (
    action.type.includes('_PENDING') &&
    !actionPendind.includes(action.type.replace('_PENDING', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_PENDING', ''))
  ) {
    actionPendind.push(action.type.replace('_PENDING', ''));
  }

  if (
    action.type.includes('_FULFILLED') &&
    actionPendind.includes(action.type.replace('_FULFILLED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_FULFILLED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_FULFILLED', '')), 1);
  }

  if (
    action.type.includes('_REJECTED') &&
    actionPendind.includes(action.type.replace('_REJECTED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_REJECTED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_REJECTED', '')), 1);
  }

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_KITMEDICINE_LIST_EXPORT):
    case REQUEST(ACTION_TYPES.FETCH_KITMEDICINE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_KITMEDICINE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_KITMEDICINE):
    case REQUEST(ACTION_TYPES.UPDATE_KITMEDICINE):
    case REQUEST(ACTION_TYPES.CREATE_KITMEDICINE_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.UPDATE_KITMEDICINE_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.DELETE_KITMEDICINE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_KITMEDICINE_LIST_EXPORT):
    case FAILURE(ACTION_TYPES.FETCH_KITMEDICINE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_KITMEDICINE):
    case FAILURE(ACTION_TYPES.CREATE_KITMEDICINE):
    case FAILURE(ACTION_TYPES.UPDATE_KITMEDICINE):
    case FAILURE(ACTION_TYPES.CREATE_KITMEDICINE_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.UPDATE_KITMEDICINE_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.DELETE_KITMEDICINE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_KITMEDICINE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_KITMEDICINE_OFFSET):
      if (action.payload.data && action.payload.data.length > 0) {
        return {
          ...state,
          loading: false,
          entity: convertEntityBuffers(action.payload.data[0]),
        };
      }
      return {
        ...state,
        loading: false,
      };

    case SUCCESS(ACTION_TYPES.FETCH_KITMEDICINE):
      return {
        ...state,
        loading: false,
        entity: convertEntityBuffers(action.payload.data),
      };
    case SUCCESS(ACTION_TYPES.CREATE_KITMEDICINE):
    case SUCCESS(ACTION_TYPES.UPDATE_KITMEDICINE):
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_KITMEDICINE_AND_GO_LIST):
    case SUCCESS(ACTION_TYPES.UPDATE_KITMEDICINE_AND_GO_LIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_KITMEDICINE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };

    case ACTION_TYPES.SHOW_MODAL: {
      const openModal = state.openModal;
      openModal[action.payload.type] = action.payload.show;
      return {
        ...state,
        openModal,
      };
    }
    default:
      return state;
  }
};

const apiUrl = BASE_API_VERSION_PATH + 'kit-medicines';

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

// Actions

// Actions
export type ICrudGetAllActionKitMedicine<T> = (
  medicineMaterialId?: any,
  quantity?: any,
  kitId?: any,
  extraFilters?: {},
  page?: number,
  size?: number,
  sort?: string,
  selectFields?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getEntitiesFilter = (filters?, page?, size?, sort?, selectFields = '') => {
  const requestUrl = `${apiUrl}?page=${page >= 0 ? page : 0}&size=${size >= 0 ? size : 20}&sort=${sort ? sort : 'id,asc'}&`;
  const requestFilters = filters.map(v => v.column + '.' + v.operation + '=' + v.value).join('&');
  return {
    type: ACTION_TYPES.FETCH_KITMEDICINE_LIST,
    payload: axios.get<IKitMedicine>(`${requestUrl}${requestFilters}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getAllEntities = (filters?, sort?, selectFields = '') => {
  if (
    (actualState.totalItems > 0 && actualState.entities.length === actualState.totalItems) ||
    actionPendind.includes(ACTION_TYPES.FETCH_KITMEDICINE_LIST)
  ) {
    return {
      type: ACTION_TYPES.FETCH_KITMEDICINE_LIST + '_FROM_CACHE',
      payload: new Promise(function (resolve, reject) {
        resolve('done');
      }),
    };
  }

  const idsRequest = filters && typeof filters['ids'] !== 'undefined' && filters['ids'] ? `id.in=${filters['ids'].join(',')}&` : '';
  const medicineMaterialRequest =
    filters && typeof filters['medicineMaterialId'] !== 'undefined' && filters['medicineMaterialId']
      ? `medicineMaterial.id.in=${filters['medicineMaterialId']}&`
      : '';
  const quantityRequest =
    filters && typeof filters['quantity'] !== 'undefined' && filters['quantity'] ? `quantity.equals=${filters['quantity']}&` : '';
  const kitRequest = filters && typeof filters['kitId'] !== 'undefined' && filters['kitId'] ? `kit.id.in=${filters['kitId']}&` : '';

  const requestUrl = `${apiUrl}${`?page=${0}&size=${1000000}&sort=${sort ? sort : 'id,asc'}&`}`;
  return {
    type: ACTION_TYPES.FETCH_KITMEDICINE_LIST,
    payload: axios.get<IKitMedicine>(`${requestUrl}${idsRequest}${medicineMaterialRequest}${quantityRequest}${kitRequest}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntities: ICrudGetAllActionKitMedicine<IKitMedicine> = (
  medicineMaterialId,
  quantity,
  kitId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const medicineMaterialRequest = medicineMaterialId ? `medicineMaterial.id.in=${medicineMaterialId}&` : '';
  const quantityRequest = quantity ? `quantity.equals=${quantity}&` : '';
  const kitRequest = kitId ? `kit.id.in=${kitId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_KITMEDICINE_LIST,
    payload: axios.get<IKitMedicine>(`${requestUrl}${extraFiltersRequest}${medicineMaterialRequest}${quantityRequest}${kitRequest}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntityOffset: any = (medicineMaterialId, quantity, kitId, extraFilters, offset, sort, selectFields = '') => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const medicineMaterialRequest = medicineMaterialId ? `medicineMaterial.id.in=${medicineMaterialId}&` : '';
  const quantityRequest = quantity ? `quantity.equals=${quantity}&` : '';
  const kitRequest = kitId ? `kit.id.in=${kitId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${offset}&size=${1}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_KITMEDICINE_OFFSET,
    payload: axios.get<IKitMedicine>(`${requestUrl}${extraFiltersRequest}${medicineMaterialRequest}${quantityRequest}${kitRequest}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntity: ICrudGetAction<IKitMedicine> = (id, selectFields = '') => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_KITMEDICINE,
    payload: axios.get<IKitMedicine>(requestUrl, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntitiesExport: ICrudGetAllActionKitMedicine<IKitMedicine> = (
  medicineMaterialId,
  quantity,
  kitId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const medicineMaterialRequest = medicineMaterialId ? `medicineMaterial.id.in=${medicineMaterialId}&` : '';
  const quantityRequest = quantity ? (Array.isArray(status) ? `quantity.in=${quantity.join(',')}&` : `quantity.equals=${quantity}&`) : '';
  const kitRequest = kitId ? `kit.id.in=${kitId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_KITMEDICINE_LIST,
    payload: axios.get<IKitMedicine>(`${requestUrl}${extraFiltersRequest}${medicineMaterialRequest}${quantityRequest}${kitRequest}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const createEntity: ICrudPutAction<IKitMedicine> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.CREATE_KITMEDICINE_AND_GO_LIST
        : ACTION_TYPES.CREATE_KITMEDICINE,
    payload: axios.post(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const updateEntity: ICrudPutAction<IKitMedicine> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.UPDATE_KITMEDICINE_AND_GO_LIST
        : ACTION_TYPES.UPDATE_KITMEDICINE,
    payload: axios.put(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const deleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_KITMEDICINE,
    payload: axios.delete(requestUrl),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const showModal = view => ({
  payload: { type: view, show: true },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const hideModal = view => ({
  payload: { type: view, show: false },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const getUrlBack = (location): string => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  let urlBack = _urlBase.searchParams.get('urlBack') || '';
  urlBack = urlBack.trim();
  urlBack = urlBack.startsWith('/') ? urlBack.slice(1) : urlBack;
  urlBack = urlBack.endsWith('/') ? urlBack.slice(0, -1) : urlBack;
  return urlBack ? '/' + urlBack + '/' : '';
};

export const getKitMedicineState = (location): IKitMedicineBaseState => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  const extraFilters = jsonParse(_urlBase.searchParams.get('extraFilters') || '{}');
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;

  const medicineMaterialId = _urlBase.searchParams.get('medicineMaterial') || '';
  const quantity = _urlBase.searchParams.get('quantity') || '';
  const kitId = _urlBase.searchParams.get('kit') || '';
  return {
    baseFilters,
    extraFilters,
    offset,
    medicineMaterialId: medicineMaterialId
      ? medicineMaterialId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] }))
      : [],
    quantity,
    kitId: kitId ? kitId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
  };
};

export const getEntityFiltersURL = (state, offset = null) => {
  return (
    'baseFilters=' +
    state.baseFilters +
    '&extraFilters=' +
    encodeURI(JSON.stringify(state.extraFilters)) +
    '&page=' +
    state.activePage +
    '&' +
    'size=' +
    state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    state.sort +
    ',' +
    state.order +
    '&' +
    (state.medicineMaterialId ? 'medicineMaterial=' + state.medicineMaterialId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.quantity ? 'quantity=' + state.quantity + '&' : '') +
    (state.kitId ? 'kit=' + state.kitId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    ''
  );
};
