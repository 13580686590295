/* eslint complexity: ["error", 500] */
import axios from 'axios';

import { IPaginationBaseState, IPayloadResult } from 'react-jhipster';

import { IPayload } from 'react-jhipster/src/type/redux-action.type';

import { BASE_API_VERSION_PATH } from 'app/config/constants';

import { cleanEntity, jsonParse } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IPatientResponsible, defaultValue } from 'app/shared/model/patient-responsible.model';

export declare type ICrudPutAction<T> = (data?: T, listFiltersPage?) => IPayload<T> | IPayloadResult<T>;
export declare type ICrudGetAction<T> = (id: string | number, selectFields?: string) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const ACTION_TYPES = {
  FETCH_PATIENTRESPONSIBLE_LIST_EXPORT: 'patientResponsible/FETCH_PATIENTRESPONSIBLE_LIST_EXPORT',
  FETCH_PATIENTRESPONSIBLE_LIST: 'patientResponsible/FETCH_PATIENTRESPONSIBLE_LIST',
  FETCH_PATIENTRESPONSIBLE: 'patientResponsible/FETCH_PATIENTRESPONSIBLE',
  FETCH_PATIENTRESPONSIBLE_OFFSET: 'patientResponsible/FETCH_PATIENTRESPONSIBLE_OFFSET',
  CREATE_PATIENTRESPONSIBLE: 'patientResponsible/CREATE_PATIENTRESPONSIBLE',
  UPDATE_PATIENTRESPONSIBLE: 'patientResponsible/UPDATE_PATIENTRESPONSIBLE',
  CREATE_PATIENTRESPONSIBLE_AND_GO_LIST: 'patientResponsible/CREATE_PATIENTRESPONSIBLE_AND_GO_LIST',
  UPDATE_PATIENTRESPONSIBLE_AND_GO_LIST: 'patientResponsible/UPDATE_PATIENTRESPONSIBLE_AND_GO_LIST',
  DELETE_PATIENTRESPONSIBLE: 'patientResponsible/DELETE_PATIENTRESPONSIBLE',
  RESET: 'patientResponsible/RESET',
  SHOW_MODAL: 'patientResponsible/SHOW_MODAL',
};

const initialState = {
  loading: false,
  openModal: {
    list: false,
    view: false,
    detail: false,
  },
  errorMessage: null,
  entities: [] as ReadonlyArray<IPatientResponsible>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const actionPendind = [];
let actualState = initialState;

export const convertEntityBuffers = entity => ({
  ...entity,
});

export type PatientResponsibleState = Readonly<typeof initialState>;

export interface IPatientResponsibleBaseState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  listCheckedID?: Array<number>;
  isCheckedAll?: boolean;
  urlBack?: string;
  extraFilters?: any;
  baseFilters: any;
  offset: number;
  name?: any;
  kinshipDegree?: any;
  contactTelephonePrincipal?: any;
  comments?: any;
  accessApp?: any;
  zipCode?: any;
  city?: any;
  patientId?: any;
  patientStartFilter?: any;
}

export interface IFieldsBase extends IPatientResponsibleBaseState, IPaginationBaseState {}
export interface IPatientResponsibleUpdateState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  urlBack?: string;
  functionEmbebed?: any[];
  fieldsBase: IFieldsBase;
  nameSelectValue?: any;
  nameStartSelectOptions?: any;
  kinshipDegreeSelectValue?: any;
  kinshipDegreeStartSelectOptions?: any;
  contactTelephonePrincipalSelectValue?: any;
  contactTelephonePrincipalStartSelectOptions?: any;
  contactTelephoneSecondarySelectValue?: any;
  contactTelephoneSecondaryStartSelectOptions?: any;
  commentsSelectValue?: any;
  commentsStartSelectOptions?: any;
  emailSelectValue?: any;
  emailStartSelectOptions?: any;
  accessAppSelectValue?: any;
  accessAppStartSelectOptions?: any;
  zipCodeSelectValue?: any;
  zipCodeStartSelectOptions?: any;
  streetSelectValue?: any;
  streetStartSelectOptions?: any;
  complementSelectValue?: any;
  complementStartSelectOptions?: any;
  numberSelectValue?: any;
  numberStartSelectOptions?: any;
  neighborhoodSelectValue?: any;
  neighborhoodStartSelectOptions?: any;
  citySelectValue?: any;
  cityStartSelectOptions?: any;
  ufSelectValue?: any;
  ufStartSelectOptions?: any;
  cepRequestBairro?: any;
  cepRequestCep?: any;
  cepRequestComplemento?: any;
  cepRequestGia?: any;
  cepRequestIbge?: any;
  cepRequestLocalidade?: any;
  cepRequestLogradouro?: any;
  cepRequestUf?: any;
  cepRequestUnidade?: any;

  patientSelectValue?: any;
  patientStartSelectOptions?: any;

  isNew: boolean;
  patientId?: any;
}

// Reducer

export default (state: PatientResponsibleState = initialState, action): PatientResponsibleState => {
  actualState = state;
  if (
    action.type.includes('_PENDING') &&
    !actionPendind.includes(action.type.replace('_PENDING', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_PENDING', ''))
  ) {
    actionPendind.push(action.type.replace('_PENDING', ''));
  }

  if (
    action.type.includes('_FULFILLED') &&
    actionPendind.includes(action.type.replace('_FULFILLED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_FULFILLED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_FULFILLED', '')), 1);
  }

  if (
    action.type.includes('_REJECTED') &&
    actionPendind.includes(action.type.replace('_REJECTED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_REJECTED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_REJECTED', '')), 1);
  }

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PATIENTRESPONSIBLE_LIST_EXPORT):
    case REQUEST(ACTION_TYPES.FETCH_PATIENTRESPONSIBLE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_PATIENTRESPONSIBLE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_PATIENTRESPONSIBLE):
    case REQUEST(ACTION_TYPES.UPDATE_PATIENTRESPONSIBLE):
    case REQUEST(ACTION_TYPES.CREATE_PATIENTRESPONSIBLE_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.UPDATE_PATIENTRESPONSIBLE_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.DELETE_PATIENTRESPONSIBLE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_PATIENTRESPONSIBLE_LIST_EXPORT):
    case FAILURE(ACTION_TYPES.FETCH_PATIENTRESPONSIBLE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_PATIENTRESPONSIBLE):
    case FAILURE(ACTION_TYPES.CREATE_PATIENTRESPONSIBLE):
    case FAILURE(ACTION_TYPES.UPDATE_PATIENTRESPONSIBLE):
    case FAILURE(ACTION_TYPES.CREATE_PATIENTRESPONSIBLE_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.UPDATE_PATIENTRESPONSIBLE_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.DELETE_PATIENTRESPONSIBLE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_PATIENTRESPONSIBLE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_PATIENTRESPONSIBLE_OFFSET):
      if (action.payload.data && action.payload.data.length > 0) {
        return {
          ...state,
          loading: false,
          entity: convertEntityBuffers(action.payload.data[0]),
        };
      }
      return {
        ...state,
        loading: false,
      };

    case SUCCESS(ACTION_TYPES.FETCH_PATIENTRESPONSIBLE):
      return {
        ...state,
        loading: false,
        entity: convertEntityBuffers(action.payload.data),
      };
    case SUCCESS(ACTION_TYPES.CREATE_PATIENTRESPONSIBLE):
    case SUCCESS(ACTION_TYPES.UPDATE_PATIENTRESPONSIBLE):
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_PATIENTRESPONSIBLE_AND_GO_LIST):
    case SUCCESS(ACTION_TYPES.UPDATE_PATIENTRESPONSIBLE_AND_GO_LIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_PATIENTRESPONSIBLE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };

    case ACTION_TYPES.SHOW_MODAL: {
      const openModal = state.openModal;
      openModal[action.payload.type] = action.payload.show;
      return {
        ...state,
        openModal,
      };
    }
    default:
      return state;
  }
};

const apiUrl = BASE_API_VERSION_PATH + 'patient-responsibles';

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

// Actions

// Actions
export type ICrudGetAllActionPatientResponsible<T> = (
  name?: any,
  kinshipDegree?: any,
  contactTelephonePrincipal?: any,
  comments?: any,
  accessApp?: any,
  zipCode?: any,
  city?: any,
  patientId?: any,
  extraFilters?: {},
  page?: number,
  size?: number,
  sort?: string,
  selectFields?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getEntitiesFilter = (filters?, page?, size?, sort?, selectFields = '') => {
  const requestUrl = `${apiUrl}?page=${page >= 0 ? page : 0}&size=${size >= 0 ? size : 20}&sort=${sort ? sort : 'id,asc'}&`;
  const requestFilters = filters.map(v => v.column + '.' + v.operation + '=' + v.value).join('&');
  return {
    type: ACTION_TYPES.FETCH_PATIENTRESPONSIBLE_LIST,
    payload: axios.get<IPatientResponsible>(`${requestUrl}${requestFilters}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getAllEntities = (filters?, sort?, selectFields = '') => {
  if (
    (actualState.totalItems > 0 && actualState.entities.length === actualState.totalItems) ||
    actionPendind.includes(ACTION_TYPES.FETCH_PATIENTRESPONSIBLE_LIST)
  ) {
    return {
      type: ACTION_TYPES.FETCH_PATIENTRESPONSIBLE_LIST + '_FROM_CACHE',
      payload: new Promise(function (resolve, reject) {
        resolve('done');
      }),
    };
  }

  const idsRequest = filters && typeof filters['ids'] !== 'undefined' && filters['ids'] ? `id.in=${filters['ids'].join(',')}&` : '';
  const nameRequest = filters && typeof filters['name'] !== 'undefined' && filters['name'] ? `name.contains=${filters['name']}&` : '';
  const kinshipDegreeRequest =
    filters && typeof filters['kinshipDegree'] !== 'undefined' && filters['kinshipDegree']
      ? `kinshipDegree.contains=${filters['kinshipDegree']}&`
      : '';
  const contactTelephonePrincipalRequest =
    filters && typeof filters['contactTelephonePrincipal'] !== 'undefined' && filters['contactTelephonePrincipal']
      ? `contactTelephonePrincipal.contains=${filters['contactTelephonePrincipal']}&`
      : '';
  const commentsRequest =
    filters && typeof filters['comments'] !== 'undefined' && filters['comments'] ? `comments.contains=${filters['comments']}&` : '';
  const accessAppRequest =
    filters && typeof filters['accessApp'] !== 'undefined' && filters['accessApp'] ? `accessApp.contains=${filters['accessApp']}&` : '';
  const zipCodeRequest =
    filters && typeof filters['zipCode'] !== 'undefined' && filters['zipCode'] ? `zipCode.contains=${filters['zipCode']}&` : '';
  const cityRequest = filters && typeof filters['city'] !== 'undefined' && filters['city'] ? `city.contains=${filters['city']}&` : '';
  const patientRequest =
    filters && typeof filters['patientId'] !== 'undefined' && filters['patientId'] ? `patient.id.in=${filters['patientId']}&` : '';

  const requestUrl = `${apiUrl}${`?page=${0}&size=${1000000}&sort=${sort ? sort : 'id,asc'}&`}`;
  return {
    type: ACTION_TYPES.FETCH_PATIENTRESPONSIBLE_LIST,
    payload: axios.get<IPatientResponsible>(
      `${requestUrl}${idsRequest}${nameRequest}${kinshipDegreeRequest}${contactTelephonePrincipalRequest}${commentsRequest}${accessAppRequest}${zipCodeRequest}${cityRequest}${patientRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntities: ICrudGetAllActionPatientResponsible<IPatientResponsible> = (
  name,
  kinshipDegree,
  contactTelephonePrincipal,
  comments,
  accessApp,
  zipCode,
  city,
  patientId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const nameRequest = name ? `name.contains=${name}&` : '';
  const kinshipDegreeRequest = kinshipDegree ? `kinshipDegree.contains=${kinshipDegree}&` : '';
  const contactTelephonePrincipalRequest = contactTelephonePrincipal
    ? `contactTelephonePrincipal.contains=${contactTelephonePrincipal}&`
    : '';
  const commentsRequest = comments ? `comments.contains=${comments}&` : '';
  const accessAppRequest = accessApp
    ? Array.isArray(status)
      ? `accessApp.in=${accessApp.join(',')}&`
      : `accessApp.equals=${accessApp}&`
    : '';
  const zipCodeRequest = zipCode ? `zipCode.contains=${zipCode}&` : '';
  const cityRequest = city ? `city.contains=${city}&` : '';
  const patientRequest = patientId ? `patient.id.in=${patientId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PATIENTRESPONSIBLE_LIST,
    payload: axios.get<IPatientResponsible>(
      `${requestUrl}${extraFiltersRequest}${nameRequest}${kinshipDegreeRequest}${contactTelephonePrincipalRequest}${commentsRequest}${accessAppRequest}${zipCodeRequest}${cityRequest}${patientRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntityOffset: any = (
  name,
  kinshipDegree,
  contactTelephonePrincipal,
  comments,
  accessApp,
  zipCode,
  city,
  patientId,
  extraFilters,
  offset,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const nameRequest = name ? `name.contains=${name}&` : '';
  const kinshipDegreeRequest = kinshipDegree ? `kinshipDegree.contains=${kinshipDegree}&` : '';
  const contactTelephonePrincipalRequest = contactTelephonePrincipal
    ? `contactTelephonePrincipal.contains=${contactTelephonePrincipal}&`
    : '';
  const commentsRequest = comments ? `comments.contains=${comments}&` : '';
  const accessAppRequest = accessApp
    ? Array.isArray(status)
      ? `accessApp.in=${accessApp.join(',')}&`
      : `accessApp.equals=${accessApp}&`
    : '';
  const zipCodeRequest = zipCode ? `zipCode.contains=${zipCode}&` : '';
  const cityRequest = city ? `city.contains=${city}&` : '';
  const patientRequest = patientId ? `patient.id.in=${patientId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${offset}&size=${1}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PATIENTRESPONSIBLE_OFFSET,
    payload: axios.get<IPatientResponsible>(
      `${requestUrl}${extraFiltersRequest}${nameRequest}${kinshipDegreeRequest}${contactTelephonePrincipalRequest}${commentsRequest}${accessAppRequest}${zipCodeRequest}${cityRequest}${patientRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntity: ICrudGetAction<IPatientResponsible> = (id, selectFields = '') => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_PATIENTRESPONSIBLE,
    payload: axios.get<IPatientResponsible>(requestUrl, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntitiesExport: ICrudGetAllActionPatientResponsible<IPatientResponsible> = (
  name,
  kinshipDegree,
  contactTelephonePrincipal,
  comments,
  accessApp,
  zipCode,
  city,
  patientId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const nameRequest = name ? (Array.isArray(status) ? `name.in=${name.join(',')}&` : `name.contains=${name}&`) : '';
  const kinshipDegreeRequest = kinshipDegree
    ? Array.isArray(status)
      ? `kinshipDegree.in=${kinshipDegree.join(',')}&`
      : `kinshipDegree.contains=${kinshipDegree}&`
    : '';
  const contactTelephonePrincipalRequest = contactTelephonePrincipal
    ? Array.isArray(status)
      ? `contactTelephonePrincipal.in=${contactTelephonePrincipal.join(',')}&`
      : `contactTelephonePrincipal.contains=${contactTelephonePrincipal}&`
    : '';
  const commentsRequest = comments ? (Array.isArray(status) ? `comments.in=${comments.join(',')}&` : `comments.contains=${comments}&`) : '';
  const accessAppRequest = accessApp
    ? Array.isArray(status)
      ? `accessApp.in=${accessApp.join(',')}&`
      : `accessApp.equals=${accessApp}&`
    : '';
  const zipCodeRequest = zipCode ? (Array.isArray(status) ? `zipCode.in=${zipCode.join(',')}&` : `zipCode.contains=${zipCode}&`) : '';
  const cityRequest = city ? (Array.isArray(status) ? `city.in=${city.join(',')}&` : `city.contains=${city}&`) : '';
  const patientRequest = patientId ? `patient.id.in=${patientId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PATIENTRESPONSIBLE_LIST,
    payload: axios.get<IPatientResponsible>(
      `${requestUrl}${extraFiltersRequest}${nameRequest}${kinshipDegreeRequest}${contactTelephonePrincipalRequest}${commentsRequest}${accessAppRequest}${zipCodeRequest}${cityRequest}${patientRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const createEntity: ICrudPutAction<IPatientResponsible> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.CREATE_PATIENTRESPONSIBLE_AND_GO_LIST
        : ACTION_TYPES.CREATE_PATIENTRESPONSIBLE,
    payload: axios.post(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const updateEntity: ICrudPutAction<IPatientResponsible> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.UPDATE_PATIENTRESPONSIBLE_AND_GO_LIST
        : ACTION_TYPES.UPDATE_PATIENTRESPONSIBLE,
    payload: axios.put(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const deleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_PATIENTRESPONSIBLE,
    payload: axios.delete(requestUrl),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const showModal = view => ({
  payload: { type: view, show: true },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const hideModal = view => ({
  payload: { type: view, show: false },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const getUrlBack = (location): string => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  let urlBack = _urlBase.searchParams.get('urlBack') || '';
  urlBack = urlBack.trim();
  urlBack = urlBack.startsWith('/') ? urlBack.slice(1) : urlBack;
  urlBack = urlBack.endsWith('/') ? urlBack.slice(0, -1) : urlBack;
  return urlBack ? '/' + urlBack + '/' : '';
};

export const getPatientResponsibleState = (location): IPatientResponsibleBaseState => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  const extraFilters = jsonParse(_urlBase.searchParams.get('extraFilters') || '{}');
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;

  const name = _urlBase.searchParams.get('name') || '';
  const kinshipDegree = _urlBase.searchParams.get('kinshipDegree') || '';
  const contactTelephonePrincipal = _urlBase.searchParams.get('contactTelephonePrincipal') || '';
  const comments = _urlBase.searchParams.get('comments') || '';
  const accessApp = _urlBase.searchParams.get('accessApp') || '';
  const zipCode = _urlBase.searchParams.get('zipCode') || '';
  const city = _urlBase.searchParams.get('city') || '';
  const patientId = _urlBase.searchParams.get('patient') || '';
  return {
    baseFilters,
    extraFilters,
    offset,
    name,
    kinshipDegree,
    contactTelephonePrincipal,
    comments,
    accessApp,
    zipCode,
    city,
    patientId: patientId ? patientId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
  };
};

export const getEntityFiltersURL = (state, offset = null) => {
  return (
    'baseFilters=' +
    state.baseFilters +
    '&extraFilters=' +
    encodeURI(JSON.stringify(state.extraFilters)) +
    '&page=' +
    state.activePage +
    '&' +
    'size=' +
    state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    state.sort +
    ',' +
    state.order +
    '&' +
    (state.name ? 'name=' + state.name + '&' : '') +
    (state.kinshipDegree ? 'kinshipDegree=' + state.kinshipDegree + '&' : '') +
    (state.contactTelephonePrincipal ? 'contactTelephonePrincipal=' + state.contactTelephonePrincipal + '&' : '') +
    (state.comments ? 'comments=' + state.comments + '&' : '') +
    (state.accessApp ? 'accessApp=' + state.accessApp + '&' : '') +
    (state.zipCode ? 'zipCode=' + state.zipCode + '&' : '') +
    (state.city ? 'city=' + state.city + '&' : '') +
    (state.patientId ? 'patient=' + state.patientId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    ''
  );
};
