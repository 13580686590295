/* eslint complexity: ["error", 500] */
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { PharmacyStock, mapStateToProps, mapDispatchToProps } from '../pharmacy-stock';
import { IRootState } from 'app/shared/reducers';
import { Translate } from 'app/config/translate-component';
import { Button, Col, Row, Table, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { getEntityFiltersURL } from 'app/entities/pharmacy-stock/pharmacy-stock.reducer';
import { getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import moment from 'moment';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

import { useJsonToCsv } from 'react-json-csv';

import ModalFilterExportPharmacyStock from 'app/entities/pharmacy-stock/extended/modal-filter-export-pharmacy-stock';

export interface IExtendedState {
  showModalReport?: boolean;
}
export interface IExtendedProps {
  entityEmbebedPai?: any;
}




export class PharmacyStockExtended extends PharmacyStock {

  renderHeader() {
    const { saveAsCsv } = useJsonToCsv();

    const { pharmacyStockStatuses, medicineMaterials, suppliers, nfes, pharmacyStockList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;


    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.pharmacyStock.home.title">Pharmacy Stocks</Translate>
          </span>
          <Button id="togglerFilterPharmacyStock" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.pharmacyStock.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          &nbsp;
          <Button
            className="float-right jh-create-entity"
            tag={Link}
            to={`/pharmacy-stock/new?${getEntityFiltersURL(state)}`}
            color="info"
            size="md"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.pharmacyStock.listTopButtons.new">NewProduto</Translate>
            </span>
          </Button>
          <ModalFilterExportPharmacyStock setBaseState={v => this.setState(v)} />
          <Button
            size="md"
            color="dark"
            className="float-right jh-create-entity "
            onClick={async () => {
              const result = await getListAxios(
                'pharmacy-stocks',
                {},
                0,
                999999999999,
                'createdDate,desc',
                'medicineMaterial.name,supplier.fantasyName,medicineMaterial.brand.name'
              );

              const filename = 'pharmacy-stock-report',
                separator = ';',
                fields = {
                  nome: 'Nome',
                  validade: 'Validade',
                  quantidadeAtual: 'Quantidade atual',
                  marca: 'Marca',
                  grupo: 'Grupo',
                },
                data = [];

              if (result.data) {
                result.data.map(row => {
                  data.push({
                    nome: row.medicineMaterial ? row.medicineMaterial.name : '',
                    validade: row.validate ? row.validate : '',
                    quantidadeAtual: row.quantity - row.amountExtract,
                    marca: row.medicineMaterial && row.medicineMaterial.brand ? row.medicineMaterial.brand.name : '',
                    grupo: row.medicineMaterial ? row.medicineMaterial.medicineMaterialType : '',
                  });
                });
                saveAsCsv({ data, fields, filename, separator });
              }
            }}
          >
            <FontAwesomeIcon icon="file" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.quote.listSelectedButtons.report">Report</Translate>
            </span>
          </Button>
          <Button
            className="float-right jh-create-entity"
            tag={Link}
            to={`/pharmacy-stock-devolution/new?${getEntityFiltersURL(state)}`}
            color="info"
            size="md"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.pharmacyStock.listTopButtons.newDevolution">NewDevolution</Translate>
            </span>
          </Button>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.pharmacyStock.home.title">Pharmacy Stocks</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    return <> {super.renderFilter()} </>;
  }

  renderTable() {
    const { pharmacyStockStatuses, medicineMaterials, suppliers, nfes, pharmacyStockList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;
    let array = [];

    return (
      <>
        {pharmacyStockList && pharmacyStockList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null).length > 0 ? (
          <>
            <Table
              id="pharmacy-stock-table-list"
              responsive
              aria-describedby="pharmacy-stock-heading"
              className={'table-hover table-striped mt-4 table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.pharmacyStock.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'medicineMaterial' ? (
                    <th>
                      <Translate contentKey="generadorApp.pharmacyStock.medicineMaterial">Medicine Material</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'quantity' ? (
                    <th className="hand" onClick={sortFunction('quantity')}>
                      <Translate contentKey="generadorApp.pharmacyStock.quantity">Quantity</Translate>
                      <FontAwesomeIcon icon={state.sort === 'quantity' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'validate' ? (
                    <th className="hand" onClick={sortFunction('validate')}>
                      <Translate contentKey="generadorApp.pharmacyStock.validate">Validate</Translate>
                      <FontAwesomeIcon icon={state.sort === 'validate' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'lot' ? (
                    <th className="hand" onClick={sortFunction('lot')}>
                      <Translate contentKey="generadorApp.pharmacyStock.lot">Lot</Translate>
                      <FontAwesomeIcon icon={state.sort === 'lot' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'supplier' ? (
                    <th>
                      <Translate contentKey="generadorApp.pharmacyStock.supplier">Supplier</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'princePaid' ? (
                    <th className="hand" onClick={sortFunction('princePaid')}>
                      <Translate contentKey="generadorApp.pharmacyStock.princePaid">Prince Paid</Translate>
                      <FontAwesomeIcon icon={state.sort === 'princePaid' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'stockedLocation' ? (
                    <th className="hand" onClick={sortFunction('stockedLocation')}>
                      <Translate contentKey="generadorApp.pharmacyStock.stockedLocation">Stocked Location</Translate>
                      <FontAwesomeIcon
                        icon={state.sort === 'stockedLocation' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'}
                      />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'status' ? (
                    <th className="hand" onClick={sortFunction('status')}>
                      <Translate contentKey="generadorApp.pharmacyStock.status">Status</Translate>
                      <FontAwesomeIcon icon={state.sort === 'status' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}

                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {pharmacyStockList?.map((itens: any) => {
                  if (array?.find(element => element?.medicineMaterial?.name === itens?.medicineMaterial?.name)) {

                  } else {
                    array.push(itens)
                  }
                })
                }
                {array
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((pharmacyStock, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.pharmacyStock.field_id">ID</Translate>
                        </b>
                        {pharmacyStock.id}
                      </td>

                      {state.baseFilters !== 'medicineMaterial' ? (
                        <td id="medicineMaterial-cell">
                          <b className="visible-xs"> Medicine Material </b>
                          {showFieldsSelectAsync(pharmacyStock, 'medicineMaterial.name', null).map((v, ikey) => (
                            <span key={ikey}> {v} </span>
                          ))}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'quantity' ? (
                        <td id="quantity-cell">
                          <b className="visible-xs"> Quantity </b>

                          {pharmacyStock.amountExtract + ' / ' + pharmacyStock.quantity}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'validate' ? (
                        <td id="validate-cell">
                          <b className="visible-xs"> Validate </b>
                          {pharmacyStock.validate ? moment(pharmacyStock.validate).format(APP_LOCAL_DATE_FORMAT) : ''}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'lot' ? (
                        <td id="lot-cell">
                          <b className="visible-xs"> Lot </b>

                          {pharmacyStock.lot}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'supplier' ? (
                        <td id="supplier-cell">
                          <b className="visible-xs"> Supplier </b>
                          {showFieldsSelectAsync(pharmacyStock, 'supplier.fantasyName', null).map((v, ikey) => (
                            <span key={ikey}> {v} </span>
                          ))}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'princePaid' ? (
                        <td id="princePaid-cell">
                          <b className="visible-xs"> Prince Paid </b>
                          R$ {(pharmacyStock.princePaid * 1).toFixed(2).replace('.', ',')}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'stockedLocation' ? (
                        <td id="stockedLocation-cell">
                          <b className="visible-xs"> Stocked Location </b>

                          {pharmacyStock.stockedLocation}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'status' ? (
                        <td id="status-cell">
                          <b className="visible-xs"> Status </b>
                          {pharmacyStock.status ? (
                            <Translate contentKey={`generadorApp.StatusPhamacyStock.${pharmacyStock.status}`} />
                          ) : (
                            <> </>
                          )}
                        </td>
                      ) : null}

                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          {hasAnyAuthority(props.userAccount, ['pharmacy-stock'], 'view') ? (
                            <Button tag={Link} to={`${match.url}/${pharmacyStock.id}`} color="info" size="sm">
                              <FontAwesomeIcon icon="eye" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                          {hasAnyAuthority(props.userAccount, ['pharmacy-stock'], 'edit') ? (
                            <Button
                              tag={Link}
                              to={`${match.url}/${pharmacyStock.id}/edit?${getEntityFiltersURL(
                                state,
                                this.state.itemsPerPage * (this.state.activePage - 1) + i
                              )}`}
                              color="primary"
                              size="sm"
                            >
                              <FontAwesomeIcon icon="pencil-alt" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                          {hasAnyAuthority(props.userAccount, ['pharmacy-stock'], 'canDelete') ? (
                            <Button onClick={() => this.deleteEntity(pharmacyStock)} color="danger" size="sm">
                              <FontAwesomeIcon icon="trash" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                          <Button tag={Link} to={`/pharmacy-stock/${pharmacyStock.id}/pharmacy-stock-status`} color="info" size="sm">
                            <FontAwesomeIcon icon="check-square" />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.pharmacyStock.home.notFound">No Pharmacy Stocks found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    return <> {super.renderFooter()} </>;
  }

  render() {
    return <> {super.render()} </>;
  }
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;

export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(PharmacyStockExtended);
function handleModal() {
  throw new Error('Function not implemented.');
}

