import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import QuoteDiet from './extended/quote-diet';

import QuoteDietUpdate from './quote-diet';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={QuoteDietUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={QuoteDietUpdate} />
      <ErrorBoundaryRoute path={match.path} component={QuoteDiet} />
    </Switch>
  </>
);

export default Routes;
