import { Translate } from 'app/config/translate-component';
import { showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import React from 'react';

export interface IPadProps {
  baseState?: any;
  baseProps?: any;
  setState?: any;
  entityBase?: any;
}

const PadComponentCustomize = (props: IPadProps) => {
  return (
    <>
      <b className="visible-xs"> Quote </b>
      {showFieldsSelectAsync(props.entityBase, 'quote.quoteRh.frequency', null).map((v, ikey) => (
        <div key={ikey}>
          {' '}
          <Translate contentKey={'generadorApp.QuoteFrequency.' + v} />{' '}
        </div>
      ))}
    </>
  );
};

const mapStateToProps = storeState => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default PadComponentCustomize;
