import React from 'react';

import { connect } from 'react-redux';
import { Moment } from 'moment';
import { Client } from 'app/entities/client/client';

export interface IChartImplantacoesProps extends StateProps, DispatchProps {
  periodoInicio?: Moment;
  periodoFim?: Moment;
  operadora?: Array<Client>;
  implantacoes: {
    TotalImplantacoes: number;
    TotalAguardandoPrecificacao: number;
    TotalAberto: number;
    TotalPrecificados: number;
    TotalAprovados: number;
  };
}

const ChartImplantacoes = (props: IChartImplantacoesProps) => {
  return (
    <div className="card border-0 bg-dark text-white mb-3 overflow-hidden">
      <div className="card-body">
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="mb-3 text-grey">
              <b>Total implantações</b>
            </div>
            <div className="d-flex mb-1">
              <h2 className="mb-0">{props.implantacoes?.TotalImplantacoes}</h2>
            </div>
            <hr className="bg-white-transparent-2" />
            <div className="mb-3 text-grey">
              <b>Total aguardando</b>
            </div>
            <div className="row text-truncate">
              <div className="col-6">
                <div className="f-s-12 text-grey">Aberto</div>
                <div className="f-s-18 m-b-5 f-w-600 p-b-1">{props.implantacoes?.TotalAberto}</div>
                <div className="progress progress-xs rounded-lg bg-dark-darker m-b-5">
                  <div
                    className="progress-bar progress-bar-striped rounded-right bg-teal"
                    style={{
                      width: (props.implantacoes?.TotalAberto * 100) / props.implantacoes?.TotalImplantacoes + '%',
                    }}
                  ></div>
                </div>
              </div>
              <div className="col-6">
                <div className="f-s-12 text-grey">Aguardando Precificação</div>
                <div className="f-s-18 m-b-5 f-w-600 p-b-1">{props.implantacoes?.TotalAguardandoPrecificacao}</div>
                <div className="progress progress-xs rounded-lg bg-dark-darker m-b-5">
                  <div
                    className="progress-bar progress-bar-striped rounded-right bg-teal"
                    style={{
                      width: (props.implantacoes?.TotalAguardandoPrecificacao * 100) / props.implantacoes?.TotalImplantacoes + '%',
                    }}
                  ></div>
                </div>
              </div>
              <div className="col-6">
                <div className="f-s-12 text-grey">Precificados</div>
                <div className="f-s-18 m-b-5 f-w-600 p-b-1">{props.implantacoes?.TotalPrecificados}</div>
                <div className="progress progress-xs rounded-lg bg-dark-darker m-b-5">
                  <div
                    className="progress-bar progress-bar-striped rounded-right"
                    style={{
                      width: (props.implantacoes?.TotalPrecificados * 100) / props.implantacoes?.TotalImplantacoes + '%',
                    }}
                  ></div>
                </div>
              </div>
              <div className="col-6">
                <div className="f-s-12 text-grey">Aprovados</div>
                <div className="f-s-18 m-b-5 f-w-600 p-b-1">{props.implantacoes?.TotalAprovados}</div>
                <div className="progress progress-xs rounded-lg bg-dark-darker m-b-5">
                  <div
                    className="progress-bar progress-bar-striped rounded-right"
                    style={{
                      width: (props.implantacoes?.TotalAprovados * 100) / props.implantacoes?.TotalImplantacoes + '%',
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = storeState => ({
  report:
    storeState.jhiReports.reportsList && storeState.jhiReports.reportsList['pacientes-hospitalizados']
      ? storeState.jhiReports.reportsList['pacientes-hospitalizados']
      : [],
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps)(ChartImplantacoes);
