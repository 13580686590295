import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';
import moment from 'moment';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity, IQuoteBaseState, getUrlBack, getQuoteState, getEntityFiltersURL } from './quote.reducer';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IQuoteState {
  fieldsBase: IQuoteBaseState;
}

export interface IQuoteDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class QuoteDetail extends React.Component<IQuoteDetailProps, IQuoteState> {
  constructor(props: Readonly<IQuoteDetailProps>) {
    super(props);
    this.state = {
      ...this.state,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getQuoteState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
    };
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = '_back=1' + (offset !== null ? '&offset=' + offset : '');
    Object.keys(fieldsBase).map(key => {
      url += '&' + key + '=' + fieldsBase[key];
    });
    return url;
  };

  UNSAFE_componentWillMount() {
    this.props.getEntity(this.props.match.params['id']);
  }

  renderHeader() {
    const { quoteEntity } = this.props;
    const state = this.state;
    return (
      <>
        <div id="page-heading">
          {' '}
          <h2>
            <Translate contentKey="generadorApp.quote.detail.title">Quote</Translate>
            <small>&nbsp; {quoteEntity['id']}</small>
          </h2>
        </div>

        <div className="pull-right">
          <Button tag={Link} to={'/quote?' + getEntityFiltersURL(this.state.fieldsBase)} replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.quote.btnBack">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/quote/${quoteEntity.id}/edit?+${getEntityFiltersURL(this.state.fieldsBase)}`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.quote.btnEdit">Edit</Translate>
            </span>
          </Button>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>

          <li className="breadcrumb-item active">
            <Link to={'/quote'}>Quotes</Link>
          </li>
          <li className="breadcrumb-item active">Quotes details</li>
        </ol>
      </>
    );
  }

  renderBody() {
    const { quoteEntity } = this.props;
    const state = this.state;
    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            <Row className="jh-entity-details">
              <Col className="view-detail-row quote-approvalDate-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top quote-approvalDate-detail-label" md="12">
                    <dt>
                      <span id="approvalDate">
                        <Translate contentKey="generadorApp.quote.approvalDate">Approval Date</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top quote-approvalDate-detail-value" md="12">
                    <dd>{quoteEntity.approvalDate ? moment(quoteEntity.approvalDate).format(APP_LOCAL_DATE_FORMAT) : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row quote-source-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top quote-source-detail-label" md="12">
                    <dt>
                      <span id="source">
                        <Translate contentKey="generadorApp.quote.source">Source</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top quote-source-detail-value" md="12">
                    <dd>{translate('generadorApp.QuoteSource.' + (quoteEntity.source ? quoteEntity.source : 'AVALIACAO'))}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row quote-status-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top quote-status-detail-label" md="12">
                    <dt>
                      <span id="status">
                        <Translate contentKey="generadorApp.quote.status">Status</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top quote-status-detail-value" md="12">
                    <dd>{translate('generadorApp.QuoteStatus.' + (quoteEntity.status ? quoteEntity.status : 'ORCAMENTOABERTO'))}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row quote-from-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top quote-from-detail-label" md="12">
                    <dt>
                      <span id="from">
                        <Translate contentKey="generadorApp.quote.from">From</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top quote-from-detail-value" md="12">
                    <dd>{quoteEntity.from ? moment(quoteEntity.from).format(APP_LOCAL_DATE_FORMAT) : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row quote-to-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top quote-to-detail-label" md="12">
                    <dt>
                      <span id="to">
                        <Translate contentKey="generadorApp.quote.to">To</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top quote-to-detail-value" md="12">
                    <dd>{quoteEntity.to ? moment(quoteEntity.to).format(APP_LOCAL_DATE_FORMAT) : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row quote-patient-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top quote-patient-detail-label" md="12">
                    <dt>
                      <Translate contentKey="generadorApp.quote.patient">Patient</Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top quote-patient-detail-value" md="12">
                    <dd>{quoteEntity.patient ? quoteEntity.patient.id : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row quote-quoteProductService-detail" md="12">
                <Row>
                  <Col className="view-detail-label-top quote-quoteProductService-detail-label" md="12">
                    <dt>
                      <Translate contentKey="generadorApp.quote.quoteProductService">Quote Product Service</Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top quote-quoteProductService-detail-value" md="12">
                    <dd>{quoteEntity.quoteProductService ? quoteEntity.quoteProductService.id : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row quote-quoteRh-detail" md="12">
                <Row>
                  <Col className="view-detail-label-top quote-quoteRh-detail-label" md="12">
                    <dt>
                      <Translate contentKey="generadorApp.quote.quoteRh">Quote Rh</Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top quote-quoteRh-detail-value" md="12">
                    <dd>{quoteEntity.quoteRh ? quoteEntity.quoteRh.id : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row quote-quoteMedicine-detail" md="12">
                <Row>
                  <Col className="view-detail-label-top quote-quoteMedicine-detail-label" md="12">
                    <dt>
                      <Translate contentKey="generadorApp.quote.quoteMedicine">Quote Medicine</Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top quote-quoteMedicine-detail-value" md="12">
                    <dd>{quoteEntity.quoteMedicine ? quoteEntity.quoteMedicine.id : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row quote-quoteMaterial-detail" md="12">
                <Row>
                  <Col className="view-detail-label-top quote-quoteMaterial-detail-label" md="12">
                    <dt>
                      <Translate contentKey="generadorApp.quote.quoteMaterial">Quote Material</Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top quote-quoteMaterial-detail-value" md="12">
                    <dd>{quoteEntity.quoteMaterial ? quoteEntity.quoteMaterial.id : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row quote-quoteDiet-detail" md="12">
                <Row>
                  <Col className="view-detail-label-top quote-quoteDiet-detail-label" md="12">
                    <dt>
                      <Translate contentKey="generadorApp.quote.quoteDiet">Quote Diet</Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top quote-quoteDiet-detail-value" md="12">
                    <dd>{quoteEntity.quoteDiet ? quoteEntity.quoteDiet.id : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row quote-finalAmount-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top quote-finalAmount-detail-label" md="12">
                    <dt>
                      <span id="finalAmount">
                        <Translate contentKey="generadorApp.quote.finalAmount">Final Amount</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top quote-finalAmount-detail-value" md="12">
                    <dd>{quoteEntity.finalAmount}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row quote-billFinalAmount-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top quote-billFinalAmount-detail-label" md="12">
                    <dt>
                      <span id="billFinalAmount">
                        <Translate contentKey="generadorApp.quote.billFinalAmount">Bill final amount</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top quote-billFinalAmount-detail-value" md="12">
                    <dd>{quoteEntity.billFinalAmount}</dd>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { quoteEntity } = this.props;
    const state = this.state;
    return (
      <div>
        <Panel className="quote quote-detail">
          <PanelHeader>{this.renderHeader()}</PanelHeader>

          <PanelBody>{this.renderBody()}</PanelBody>
        </Panel>
      </div>
    );
  }
}

export const mapStateToProps = ({ quote, ...storeState }: IRootState) => ({
  quoteEntity: quote.entity,
});

export const mapDispatchToProps = {
  getEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(QuoteDetail);
