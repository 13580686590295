/* eslint-disable no-console */
/* eslint complexity: ["error", 300] */
import React from 'react';
import { connect } from 'react-redux';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);

import { getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import SelectAsync from 'react-select/async';

import { Link } from 'react-router-dom';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Label, Button, Modal, ModalBody, ModalHeader, ModalFooter, Table, Row, Col } from 'reactstrap';

import { TextFormat } from 'react-jhipster';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { getEntityFiltersURL, getEntitiesFilter, updateEntity, createEntity } from '../quote.reducer';

import { Quote, mapStateToProps, mapDispatchToProps } from '../quote';
import { IRootState } from 'app/shared/reducers';
import { OrcamentoPdfReport } from 'app/entities/quote/report/orcamento';
import { callFunction, getReport } from 'app/shared/reports/jhi-reports.reducer';
import { toast } from 'react-toastify';
import moment from 'moment';
import { QuoteStatus } from 'app/shared/model/enumerations/quote-status.model';
import { getEntity } from '../quote.reducer';

import Select from 'react-select';
import { QuoteSource } from 'app/shared/model/enumerations/quote-source.model';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import Axios from 'axios';

export interface IExtendedState {
  showModalNewQuote?: boolean;
  disableButton?: boolean;
  showModalReport?: boolean;
  showClearFilter?: boolean;
  idQuoteFilter?: any;
  kitSelectValue?: any;
  kitStartSelectOptions?: any;
  clientSelectValue?: any;
  clientStartSelectOptions?: any;
  patientSelectValue?: any;
  patientStartSelectOptions?: any;
  typeSelectValue?: any;
  report?: any;
  provider?: string;
}
export interface IExtendedProps {
  entityEmbebedPai?: any;
}

export class QuoteExtended extends Quote {
  constructor(props: any) {
    super(props);
    this.state = {
      ...this.state,
      sort: 'id',
      order: 'desc',
      arrayAxioTuss: [],
      disableButton: false,
    };
  }

  renderHeader() {
    const { userAccount }: any = this.props;
    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.quote.home.title">Quotes</Translate>
          </span>
          <Button id="togglerFilterQuote" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.quote.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          &nbsp;
          {hasAnyAuthority(userAccount, ['quote'], 'resgister') ? (
            <Button
              className="float-right jh-create-entity"
              onClick={() => {
                this.setState({ showModalNewQuote: true });
              }}
              color="primary"
              size="md"
            >
              <FontAwesomeIcon icon="plus" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="generadorApp.quote.listTopButtons.new">NewQuote</Translate>
              </span>
            </Button>
          ) : (
            <></>
          )}{' '}
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.quote.home.title">Quotes</Translate>
          </li>
        </ol>
      </>
    );
  }

  UNSAFE_componentWillMount() {
    super.UNSAFE_componentWillMount();
  }

  renderFilter() {
    return <> {super.renderFilter()} </>;
  }

  renderTable() {
    const { quoteList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    const nQuoteList = quoteList;
    return (
      <>
        {nQuoteList && nQuoteList.filter(v => v.deletedAt === null).length > 0 ? (
          <>
            {this.state.listCheckedID.length > 0 ? (
              <div className="clasificar-all" style={{ textAlign: 'center' }}>
                {this.state.listCheckedID.length === this.state.itemsPerPage ||
                this.state.listCheckedID.length === this.props.totalItems ? (
                  <div className="alert alert-warning pull-left" style={{ color: 'black', padding: '13px 100px' }}>
                    {!this.state.isCheckedAll ? (
                      this.props.totalItems < 20 ? (
                        <>
                          <Translate
                            contentKey="generadorApp.quote.listSelectedButtons.allItensAreSelected"
                            interpolate={{ totalItems: this.props.totalItems }}
                          >
                            Os {this.props.totalItems} Quotes nesta página estão selecionadas.
                          </Translate>
                          &nbsp;
                          <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => this.handleInputAllChange()}>
                            <Translate contentKey="generadorApp.quote.listSelectedButtons.clearSelect">Limpar Seleção</Translate>
                          </span>
                        </>
                      ) : (
                        <>
                          <Translate
                            contentKey="generadorApp.quote.listSelectedButtons.allItensAreSelectedThisPage"
                            interpolate={{ itemsPerPage: this.state.itemsPerPage }}
                          >
                            Todos os {this.state.itemsPerPage} Quotes nesta página estão selecionadas.
                          </Translate>
                          &nbsp;
                          <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => this.handleInputAllChange()}>
                            <Translate
                              contentKey="generadorApp.quote.listSelectedButtons.selectAll"
                              interpolate={{ totalItems: this.props.totalItems }}
                            >
                              Selecionar os {this.props.totalItems} Quotes
                            </Translate>
                          </span>
                        </>
                      )
                    ) : (
                      <>
                        <Translate
                          contentKey="generadorApp.quote.listSelectedButtons.allItensAreSelectedAllPages"
                          interpolate={{ totalItems: this.props.totalItems }}
                        >
                          Todas os {this.props.totalItems} Quotes estão selecionadas.
                        </Translate>
                        &nbsp;
                        <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => this.handleInputAllChange()}>
                          <Translate contentKey="generadorApp.quote.listSelectedButtons.clearSelect">Limpar Seleção</Translate>
                        </span>
                      </>
                    )}
                  </div>
                ) : (
                  <></>
                )}

                <div className={'pull-right'}>
                  <Button
                    className="float-right jh-create-entity"
                    onClick={() => {
                      callFunction('fnDuplicateQuoteByListId', { listaId: this.state.listCheckedID.join(',') }).then(v => {
                        this.setState({ listCheckedID: [], isCheckedAll: false, showClearFilter: true });
                        toast.success(translate('generadorApp.quote.listSelectedButtons.extend.success'));
                      });
                      this.getEntities();
                    }}
                    color="primary"
                    size="md"
                  >
                    <FontAwesomeIcon icon="plus" />{' '}
                    <span className="d-none d-md-inline">
                      <Translate contentKey="generadorApp.quote.listSelectedButtons.extend">Extension</Translate>
                    </span>
                  </Button>
                  <Button
                    className="float-right jh-create-entity"
                    onClick={() => {
                      this.props.getReport('quotes', { quoteIds: this.state.listCheckedID.join(',') });
                      this.setState({ showModalReport: true });
                    }}
                    color="grey"
                    size="md"
                  >
                    <FontAwesomeIcon icon="file" />{' '}
                    <span className="d-none d-md-inline">
                      <Translate contentKey="generadorApp.quote.listSelectedButtons.report">Report</Translate>
                    </span>
                  </Button>
                </div>
              </div>
            ) : (
              <> </>
            )}
            {this.state.showClearFilter === true ? (
              <>
                <Row>
                  <Col md={12}>
                    <Translate
                      contentKey="generadorApp.quote.listSelectedButtons.allItensAreSelected"
                      interpolate={{ totalItems: this.props.totalItems }}
                    >
                      Listar todos os Quotes
                    </Translate>
                    &nbsp;
                    <span
                      style={{ color: 'blue', cursor: 'pointer' }}
                      onClick={() => {
                        this.setState({ showClearFilter: false });
                        this.cancelFilters();
                      }}
                    >
                      <Translate contentKey="generadorApp.quote.listSelectedButtons.clearSelect">Limpar Seleção</Translate>
                    </span>
                  </Col>
                </Row>
              </>
            ) : (
              <> </>
            )}
            <Table
              id="quote-table-list"
              responsive
              aria-describedby="quote-heading"
              className={'table-hover table-striped mt-4 table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className={'align-middle text-center'} style={{ width: '4%' }}>
                    <input
                      type="checkbox"
                      checked={
                        this.state.listCheckedID.length === this.state.itemsPerPage ||
                        this.state.listCheckedID.length === this.props.totalItems
                      }
                      onChange={() => this.handleInputAllThisItens()}
                    />
                  </th>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.quote.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'client' ? (
                    <th>
                      <Translate contentKey="generadorApp.quote.client">Client</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'patient' ? (
                    <th>
                      <Translate contentKey="generadorApp.quote.patient">Patient</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'from' ? (
                    <th className="hand" onClick={sortFunction('from')}>
                      <Translate contentKey="generadorApp.quote.from">From</Translate>
                      <FontAwesomeIcon icon={state.sort === 'from' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'to' ? (
                    <th className="hand" onClick={sortFunction('to')}>
                      <Translate contentKey="generadorApp.quote.to">To</Translate>
                      <FontAwesomeIcon icon={state.sort === 'to' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'source' ? (
                    <th className="hand" onClick={sortFunction('source')}>
                      <Translate contentKey="generadorApp.quote.source">Source</Translate>
                      <FontAwesomeIcon icon={state.sort === 'source' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'status' ? (
                    <th className="hand" onClick={sortFunction('status')}>
                      <Translate contentKey="generadorApp.quote.status">Status</Translate>
                      <FontAwesomeIcon icon={state.sort === 'status' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {nQuoteList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((quote, i) => (
                    <tr key={`entity-${i}`} className={quote.status === QuoteStatus.CANCELADO ? 'alert alert-danger' : ''}>
                      <td className={'align-middle text-center'}>
                        <input
                          type="checkbox"
                          checked={this.state.listCheckedID.includes(quote.id)}
                          onChange={() => this.handleInputChange(quote.id)}
                        />
                      </td>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.quote.field_id">ID</Translate>
                        </b>
                        {hasAnyAuthority(props.userAccount, ['quote'], 'edit') ? (
                          <Button
                            tag={Link}
                            to={`${match.url}/${quote.id}/edit?${getEntityFiltersURL(
                              state,
                              this.state.itemsPerPage * (this.state.activePage - 1) + i
                            )}`}
                            color="link"
                            size="sm"
                          >
                            {(quote.id + '').padStart(4, '0')}
                          </Button>
                        ) : (
                          (quote.id + '').padStart(4, '0')
                        )}
                      </td>

                      {state.baseFilters !== 'client' ? (
                        <td id="client-cell">
                          <b className="visible-xs"> Client </b>
                          {quote.patient && quote.patient.client ? quote.patient.client.fantasyName : ''}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'patient' ? (
                        <td id="patient-cell">
                          <b className="visible-xs"> Patient </b>
                          {quote.patient ? quote.patient.name : ''}
                        </td>
                      ) : null}
                      {state.baseFilters !== 'from' ? (
                        <td id="from-cell">
                          <b className="visible-xs"> From </b>
                          {quote.from ? moment(quote.from).format(APP_LOCAL_DATE_FORMAT) : ''}
                        </td>
                      ) : null}
                      {state.baseFilters !== 'to' ? (
                        <td id="to-cell">
                          <b className="visible-xs"> To </b>
                          {quote.to ? <TextFormat type="date" value={moment(quote.to).toDate()} format={APP_LOCAL_DATE_FORMAT} /> : <> </>}
                        </td>
                      ) : null}
                      {state.baseFilters !== 'source' ? (
                        <td id="source-cell">
                          <b className="visible-xs"> Source </b>
                          {quote.source ? <Translate contentKey={`generadorApp.QuoteSource.${quote.source}`} /> : <> </>}
                        </td>
                      ) : null}
                      {state.baseFilters !== 'status' ? (
                        <td id="status-cell">
                          <b className="visible-xs"> Status </b>
                          {quote.status ? <Translate contentKey={`generadorApp.QuoteStatus.${quote.status}`} /> : <> </>}
                        </td>
                      ) : null}

                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          <Button tag={Link} to={`/quote/${quote.id}/quote-file`} color="grey" size="sm">
                            <FontAwesomeIcon icon="file" />
                          </Button>
                          {hasAnyAuthority(props.userAccount, ['quote'], 'edit') ? (
                            <Button
                              tag={Link}
                              to={`${match.url}/${quote.id}/edit?${getEntityFiltersURL(
                                state,
                                this.state.itemsPerPage * (this.state.activePage - 1) + i
                              )}`}
                              color="primary"
                              size="sm"
                            >
                              <FontAwesomeIcon
                                icon={
                                  [
                                    QuoteStatus.APROVADO,
                                    QuoteStatus.INATIVOREPROVADOPRECO,
                                    QuoteStatus.INATIVOCONTROLEINTERNO,
                                    QuoteStatus.CANCELADO,
                                  ].includes(quote.status)
                                    ? 'eye'
                                    : 'pencil-alt'
                                }
                              />
                            </Button>
                          ) : (
                            <></>
                          )}
                          {hasAnyAuthority(props.userAccount, ['quote'], 'canDelete') && quote.status !== QuoteStatus.CANCELADO ? (
                            <Button
                              onClick={() => {
                                MySwal.fire({
                                  title: (
                                    <p>
                                      <Translate contentKey={`generadorApp.cancel_quote`} />
                                    </p>
                                  ),
                                  html: (
                                    <p>
                                      <Translate contentKey={`generadorApp.want_to_cancel_quote`} />
                                    </p>
                                  ),
                                  showConfirmButton: true,
                                  showCancelButton: true,
                                }).then(response => {
                                  if (response.isConfirmed) {
                                    this.updateEntity({ ...quote, status: QuoteStatus.CANCELADO });
                                  }
                                });
                              }}
                              color="danger"
                              size="sm"
                            >
                              <FontAwesomeIcon icon="ban" />
                            </Button>
                          ) : (
                            <span style={{ paddingLeft: '33px' }}> </span>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.quote.home.notFound">No Quotes found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    return <> {super.renderFooter()} </>;
  }

  render() {
    return (
      <>
        {' '}
        {this.renderModal()} {this.renderModalNewQuote()} {super.render()}{' '}
      </>
    );
  }

  renderModalNewQuote() {
    if (this.state.showModalNewQuote !== true) return <> </>;

    return (
      <Modal size="md" isOpen toggle={() => this.setState({ showModalNewQuote: false })}>
        <ModalHeader toggle={() => this.setState({ showModalNewQuote: false })}>
          <Translate contentKey="generadorApp.quote.createQuoteTitle">createQuoteTitle</Translate>
        </ModalHeader>
        <ModalBody id="generadorApp.quote.pdf.generate">
          <Row>
            <Col md="12">
              <Row>
                <Col md="4">
                  <Label className="mt-2 label-single-line" id="typeLabel" for="quote-type">
                    <Translate contentKey="generadorApp.quote.type">Type</Translate>
                  </Label>
                </Col>
                <Col md="8">
                  <Select
                    id="quote-type"
                    className={'css-select-control'}
                    value={
                      this.state.typeSelectValue
                        ? {
                            value: this.state.typeSelectValue,
                            label: translate('generadorApp.TypeClientCommercialTable.' + this.state.typeSelectValue),
                          }
                        : { value: 'TABELAABERTA', label: translate('generadorApp.TypeClientCommercialTable.TABELAABERTA') }
                    }
                    options={[
                      { value: 'TABELAABERTA', label: translate('generadorApp.TypeClientCommercialTable.TABELAABERTA') },
                      { value: 'PACOTE', label: translate('generadorApp.TypeClientCommercialTable.PACOTE') },
                    ]}
                    onChange={(options: any) => this.setState({ typeSelectValue: options['value'] })}
                    name="type"
                  />
                </Col>
              </Row>
            </Col>{' '}
            <Col md="12">
              <Row>
                <Col md="4">
                  <Label className="mt-2 label-single-line" for="quote-patient">
                    <Translate contentKey="generadorApp.quote.patient">Patient</Translate>
                  </Label>
                </Col>
                <Col md="8">
                  <SelectAsync
                    id="quote-patient"
                    name={'patient'}
                    className={'css-select-control'}
                    data-type-rel="many-to-one-other-side"
                    value={this.state.patientSelectValue}
                    onChange={options =>
                      this.setState({
                        patientSelectValue: options,
                        clientSelectValue: {
                          ...options.client,
                          value: options.client ? options.client.id : '',
                          label: options.client ? options.client.fantasyName : '',
                        },
                      })
                    }
                    defaultOptions={this.state.patientStartSelectOptions ? this.state.patientStartSelectOptions : []}
                    loadingMessage={input => translate('selectAsync.loadingMessage')}
                    noOptionsMessage={input =>
                      this.state.patientStartSelectOptions === undefined
                        ? translate('selectAsync.loadingMessage')
                        : translate('selectAsync.noOptionsMessage')
                    }
                    onMenuOpen={async () => {
                      if (this.state.patientStartSelectOptions === undefined) {
                        const result = await getListAxios('patients', {}, 0, 100, 'name,asc', 'name,client.id');
                        this.setState({
                          patientStartSelectOptions: result.data
                            ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                            : [],
                        });
                      }
                    }}
                    loadOptions={async (inputValue, callback) => {
                      const result = await getListAxios(
                        'patients',
                        {
                          'name.contains': inputValue,
                        },
                        0,
                        100,
                        'name,asc',
                        'name,client.id'
                      );
                      callback(result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []);
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col md="12">
              <Row>
                <Col md="4">
                  <Label className="mt-2 label-single-line" for="quote-client">
                    <Translate contentKey="generadorApp.quote.client">Client</Translate>
                  </Label>
                </Col>
                <Col md="8">
                  <SelectAsync
                    id="quote-client"
                    name={'client'}
                    isDisabled={true}
                    className={'css-select-control'}
                    data-type-rel="many-to-one-other-side"
                    value={this.state.clientSelectValue}
                    onChange={options =>
                      this.setState({
                        clientSelectValue: options,
                        patientSelectValue: null,
                        patientStartSelectOptions: undefined,
                      })
                    }
                    defaultOptions={this.state.clientStartSelectOptions ? this.state.clientStartSelectOptions : []}
                    loadingMessage={input => translate('selectAsync.loadingMessage')}
                    noOptionsMessage={input =>
                      this.state.clientStartSelectOptions === undefined
                        ? translate('selectAsync.loadingMessage')
                        : translate('selectAsync.noOptionsMessage')
                    }
                    onMenuOpen={async () => {
                      if (this.state.clientStartSelectOptions === undefined) {
                        const result = await getListAxios('clients', {}, 0, 100, 'fantasyName,asc', 'fantasyName');
                        this.setState({
                          clientStartSelectOptions: result.data
                            ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'fantasyName') }))
                            : [],
                        });
                      }
                    }}
                    loadOptions={async (inputValue, callback) => {
                      const result = await getListAxios(
                        'clients',
                        { 'fantasyName.contains': inputValue },
                        0,
                        100,
                        'fantasyName,asc',
                        'fantasyName'
                      );
                      callback(
                        result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'fantasyName') })) : []
                      );
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col md="12">
              <Row>
                <Col md="4">
                  <Label className="mt-2 label-single-line" for="quote-kitSelectValue">
                    <Translate contentKey="generadorApp.quote.createQuoteSelectKit">kitSelectValue</Translate>
                  </Label>
                </Col>
                <Col md="8">
                  <SelectAsync
                    id="kit-medicine-kit"
                    name={'kit'}
                    className={'css-select-control'}
                    data-type-rel="many-to-one-other-side"
                    value={this.state.kitSelectValue}
                    onChange={options => this.setState({ kitSelectValue: options })}
                    defaultOptions={this.state.kitStartSelectOptions ? this.state.kitStartSelectOptions : []}
                    loadingMessage={input => translate('selectAsync.loadingMessage')}
                    noOptionsMessage={input =>
                      this.state.kitStartSelectOptions === undefined
                        ? translate('selectAsync.loadingMessage')
                        : translate('selectAsync.noOptionsMessage')
                    }
                    onMenuOpen={async () => {
                      if (this.state.kitStartSelectOptions === undefined) {
                        const result = await getListAxios('kits', {}, 0, 100, 'name,asc', 'name');
                        this.setState({
                          kitStartSelectOptions: result.data
                            ? [
                                { value: -1, label: translate('generadorApp.quote.createQuoteBlank') },
                                ...result.data.map(p => ({ ...p, value: p.id, label: p['name'] ? p.name : '' })),
                              ]
                            : [],
                        });
                      }
                    }}
                    loadOptions={async (inputValue, callback) => {
                      const result = await getListAxios('kits', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                      callback(
                        result.data
                          ? [
                              { value: -1, label: translate('generadorApp.quote.createQuoteBlank') },
                              ...result.data.map(p => ({ ...p, value: p.id, label: p['name'] ? p.name : '' })),
                            ]
                          : []
                      );
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <br />
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={this.state.disableButton}
            onClick={async () => {
              this.setState({disableButton: true})
              if (this.state.kitSelectValue && this.state.kitSelectValue.value > 0) {
                const replayFuction = await callFunction('fnCreatQuoteByKitId', {
                  kitId: this.state.kitSelectValue.value,
                  patientId: this.state.patientSelectValue.value,
                  typeQuote: this.state.typeSelectValue ? this.state.typeSelectValue : 'TABELAABERTA',
                  lastModifiedBy: this.props.user.id,
                });
                const newQuote = Object.values(replayFuction['data']['result'][0]).pop();
                this.props.history.push('/quote/' + newQuote + '/edit?' + getEntityFiltersURL(this.state));
              } else {
                new Promise(resolve => {
                  resolve(
                    this.props.createEntity(
                      {
                        source: QuoteSource.AVALIACAO,
                        status: QuoteStatus.ORCAMENTOABERTO,
                        patient: this.state.patientSelectValue,
                        type: this.state.typeSelectValue ? this.state.typeSelectValue : 'TABELAABERTA',
                        from: moment().startOf('month'),
                        to: moment().endOf('month'),
                        finalAmount: 0,
                        billFinalAmount: 0,
                      },
                      { reloadList: false }
                    )
                  );
                }).then(resultEntity => {
                  const idEntity =
                    resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
                      ? resultEntity['value']['data']['id']
                      : -1;
                  new Promise(resolve => {
                    resolve(this.props.getEntity(idEntity));
                  }).then(v => {
                    this.props.history.push('/quote/' + idEntity + '/edit?' + getEntityFiltersURL(this.state));
                  });
                });
              }
            }}
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="generadorApp.quote.listTopButtons.new">NewQuote</Translate>
          </Button>
          <Button color="secondary" onClick={() => this.setState({ showModalNewQuote: false })}>
            <FontAwesomeIcon icon="ban" />
            &nbsp;
            <Translate contentKey="generadorApp.quote.btnCancel">Close</Translate>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  renderModal() {
    const report: any = this.props.report;
    const props = this.props;
    let resultsSelect:any = [];
    let resultsSelectPack:any = [];
    if (!report || this.state.showModalReport !== true) return <> </>;


    const getDados = async (id) => {
      let newArray = [];
      try {
        const res = await Axios.get(`/api/quotes/${id}`);
        let results = res.data?.patient?.client?.clComTable[0].clComRuler;
        results?.forEach((item) => {
          if (!newArray.includes(item)) {
            newArray.push(item);
          }
        });
        this.setState(() => ({
          resultsSelect: [...newArray],
        }));
      } catch (err) {
        console.log(err);
      }
    };



    const result = report.reduce(function (r, a: any) {
      r[a.quote_id] = r[a.quote_id] || [];
      r[a.quote_id].push(a);
      return r;
    }, Object.create(null));

    const resultadoSelect = this.state.resultsSelect;
    let resultadoSelectPack = this.state.resultsSelectPack;

    const getDadosAll = async (id) => {
      try {
        const res = await Axios.get(`/api/quotes/${id}`);
        let results = [{
          id: res?.data?.id,
          quotePackage: res?.data?.quotePackage
        }]
        console.log(res)
        this.setState(() => ({
          resultsSelectPack: [...results]
        }));
      } catch (err) {
        console.log(err);
      }
    };


    Object.values(result).forEach((itens:any) => {
      getDados(itens[0]?.quote_id)
      getDadosAll(itens[0]?.quote_id)
    });
    
    return (
      <Modal size="xl" isOpen toggle={() => this.setState({ showModalReport: false })}>
        <ModalBody id="generadorApp.quote.pdf.generate">
          <OrcamentoPdfReport data={Object.values(result)} resultsSelect={resultadoSelect} resultsSelectPack={resultadoSelectPack} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => this.setState({ showModalReport: false })}>
            <FontAwesomeIcon icon="ban" />
            &nbsp;
            <Translate contentKey="generadorApp.quote.btnCancel">Close</Translate>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
    kitsList: storeState.kit.entities,
    user: storeState.authentication.account,
    report:
      storeState.jhiReports.reportsList && storeState.jhiReports.reportsList['quotes'] ? storeState.jhiReports.reportsList['quotes'] : [],
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
  getReport,
  getEntitiesFilter,
  updateEntity,
  createEntity,
  getEntity,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;

export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(QuoteExtended);
function data() {
  throw new Error('Function not implemented.');
}

