import React from 'react';

export interface IClComRulerProps {
  baseState?: any;
  baseProps?: any;
  setState?: any;
  entityBase?: any;
}

const ClComRulerComponentCustomize = (props: IClComRulerProps) => {
  return <></>;
};

const mapStateToProps = storeState => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default ClComRulerComponentCustomize;
