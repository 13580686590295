import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { IProfile } from 'app/shared/model/profile.model';
import { IPermission } from 'app/shared/model/permission.model';

export interface IPermissionProfile {
  id?: number;
  view?: boolean;
  resgister?: boolean;
  edit?: boolean;
  canDelete?: boolean;
  manualRemoval?: boolean;
  editLocation?: boolean;
  report?: boolean;
  activateProfessional?: boolean;
  deletePhoto?: boolean;
  financialValue?: boolean;
  valueAuthorization?: boolean;
  confirmPayment?: boolean;
  evolution?: boolean;
  professionalName?: boolean;
  professionalRegister?: boolean;
  profile?: IProfile;
  permission?: IPermission;
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  profile: {
    isMulti: true, // many-to-one - other-side
    entity: 'Profile',
    urlParameters: {
      enpoint: 'profiles',
      filters: 'permissionProfile.id.equals',
    },
  },
  permission: {
    isMulti: true, // many-to-one - other-side
    entity: 'Permission',
    urlParameters: {
      enpoint: 'permissions',
      filters: 'permissionProfile.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'permissionProfile.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'permissionProfile.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<IPermissionProfile> = {
  view: false,
  resgister: false,
  edit: false,
  canDelete: false,
  manualRemoval: false,
  editLocation: false,
  report: false,
  activateProfessional: false,
  deletePhoto: false,
  financialValue: false,
  valueAuthorization: false,
  confirmPayment: false,
  evolution: false,
  professionalName: false,
  professionalRegister: false,
};
