import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { IKitRhs } from 'app/shared/model/kit-rhs.model';
import { IQuoteRh } from 'app/shared/model/quote-rh.model';
import { ISpecialtyStatusHistory } from 'app/shared/model/specialty-status-history.model';
import { ISupplierTableRh } from 'app/shared/model/supplier-table-rh.model';
import { IMedicalRecord } from 'app/shared/model/medical-record.model';
import { IPush } from 'app/shared/model/push.model';
import { IClComRuler } from 'app/shared/model/cl-com-ruler.model';
import { ICategory } from 'app/shared/model/category.model';
import { IDose } from 'app/shared/model/dose.model';
import { IProfessional } from 'app/shared/model/professional.model';
import { Status } from 'app/shared/model/enumerations/status.model';

export interface ISpecialty {
  id?: number;
  name?: string;
  cost?: number;
  status?: Status;
  tussCode?: string;
  tissCode?: string;
  tableCode?: string;
  expenseCode?: string;
  user?: IUser;
  kitRhs?: IKitRhs[];
  quoteRh?: IQuoteRh[];
  specialtyStatusHistory?: ISpecialtyStatusHistory[];
  supplierTableRh?: ISupplierTableRh[];
  medicalRecord?: IMedicalRecord[];
  push?: IPush[];
  clComRulers?: IClComRuler[];
  category?: ICategory;
  dose?: IDose;
  professionals?: IProfessional[];
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  user: {
    isMulti: false, // one-to-one - owner-side
    entity: 'User',
    urlParameters: {
      enpoint: 'users',
      filters: 'specialty.id.equals',
    },
  },
  kitRhs: {
    isMulti: false, // one-to-many - other-side
    entity: 'KitRhs',
    urlParameters: {
      enpoint: 'kit-rhs',
      filters: 'specialty.id.equals',
    },
  },
  quoteRh: {
    isMulti: false, // one-to-many - other-side
    entity: 'QuoteRh',
    urlParameters: {
      enpoint: 'quote-rhs',
      filters: 'specialty.id.equals',
    },
  },
  specialtyStatusHistory: {
    isMulti: false, // one-to-many - other-side
    entity: 'SpecialtyStatusHistory',
    urlParameters: {
      enpoint: 'specialty-status-histories',
      filters: 'specialty.id.equals',
    },
  },
  supplierTableRh: {
    isMulti: false, // one-to-many - other-side
    entity: 'SupplierTableRh',
    urlParameters: {
      enpoint: 'supplier-table-rhs',
      filters: 'specialty.id.equals',
    },
  },
  medicalRecord: {
    isMulti: false, // one-to-many - other-side
    entity: 'MedicalRecord',
    urlParameters: {
      enpoint: 'medical-records',
      filters: 'specialty.id.equals',
    },
  },
  push: {
    isMulti: false, // one-to-many - other-side
    entity: 'Push',
    urlParameters: {
      enpoint: 'pushes',
      filters: 'specialty.id.equals',
    },
  },
  clComRuler: {
    isMulti: true, // many-to-many - owner-side
    entity: 'ClComRuler',
    urlParameters: {
      enpoint: 'cl-com-rulers',
      filters: 'specialties.id.equals',
    },
  },
  category: {
    isMulti: true, // many-to-one - other-side
    entity: 'Category',
    urlParameters: {
      enpoint: 'categories',
      filters: 'specialty.id.equals',
    },
  },
  dose: {
    isMulti: true, // many-to-one - other-side
    entity: 'Dose',
    urlParameters: {
      enpoint: 'doses',
      filters: 'specialty.id.equals',
    },
  },
  professional: {
    isMulti: true, // many-to-many - other-side
    entity: 'Professional',
    urlParameters: {
      enpoint: 'professionals',
      filters: 'specialties.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'specialty.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'specialty.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<ISpecialty> = {};
