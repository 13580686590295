import React from 'react';
import { connect } from 'react-redux';
import { AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Row, Col, Label } from 'reactstrap';

export interface IPatientProps extends StateProps, DispatchProps {
  baseState?: any;
  entityBase?: any;
  setState?: any;
  baseProps?: any;
}

const patientComponentCustomize = (props: IPatientProps) => {
  return (
    <AvGroup>
      <Row>
        <Col md="12">
          <Label className="mt-2 label-single-line" id="heightLabel" for="patient-height">
            <span>IMC</span>
          </Label>
        </Col>
        <Col md="12">
          <AvInput
            disabled={true}
            name="_patient-imc"
            id="patient-imc"
            type="text"
            value={
              props.baseState.weightSelectValue &&
              props.baseState.heightSelectValue &&
              !isNaN(props.baseState.weightSelectValue) &&
              !isNaN(props.baseState.heightSelectValue)
                ? (
                    (props.baseState.weightSelectValue / (props.baseState.heightSelectValue * props.baseState.heightSelectValue)).toFixed(
                      2
                    ) + ''
                  )
                    .split('.')
                    .join(',')
                : ''
            }
            className={'form-control'}
          />
        </Col>
      </Row>
    </AvGroup>
  );
};

const mapStateToProps = storeState => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(patientComponentCustomize);
