import React from 'react';
import { AvGroup } from 'availity-reactstrap-validation';
import { Row, Col, Label } from 'reactstrap';
import { Translate } from 'app/config/translate-component';
import InputMask from 'react-input-mask';

export interface IProfessionalProps {
  baseState?: any;
  baseProps?: any;
  setState?: any;
  entityBase?: any;
}

const ProfessionalComponentCustomize = (props: IProfessionalProps) => {
  const extraFiltersGreater = props.baseState.extraFilters['professionalOccupationArea.initial_zipcode.greaterOrEqualThan']
    ? props.baseState.extraFilters['professionalOccupationArea.initial_zipcode.greaterOrEqualThan']
    : '';
  const extraFiltersLess = props.baseState.extraFilters['professionalOccupationArea.final_zipcode.lessOrEqualThan']
    ? props.baseState.extraFilters['professionalOccupationArea.final_zipcode.lessOrEqualThan']
    : '';
  return (
    <AvGroup>
      <Row>
        <Col md="12">
          <Label className="mt-2 label-single-line" id="scheduleLabel" for="pad-attendance-schedule">
            <Translate contentKey="generadorApp.professional.zipCode">zipCode</Translate>
          </Label>
        </Col>
        <Col md="12">
          <InputMask
            onChange={evt =>
              props.setState({
                extraFilters: {
                  ...props.baseState.extraFilters,
                  'professionalOccupationArea.initial_zipcode.greaterOrEqualThan': evt.target.value,
                },
              })
            }
            style={{ width: '50%', float: 'left' }}
            value={extraFiltersGreater}
            id="pad-attendance-schedule"
            type="text"
            name="schedule"
            className={'form-control'}
          />
          <InputMask
            onChange={evt =>
              props.setState({
                extraFilters: {
                  ...props.baseState.extraFilters,
                  'professionalOccupationArea.final_zipcode.lessOrEqualThan': evt.target.value,
                },
              })
            }
            value={extraFiltersLess}
            style={{ width: '50%', float: 'left' }}
            id="pad-attendance-schedule"
            type="text"
            name="schedule"
            className={'form-control'}
          />
        </Col>
      </Row>
    </AvGroup>
  );
};

const mapStateToProps = storeState => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default ProfessionalComponentCustomize;
