import React from 'react';

import { connect } from 'react-redux';
import { Moment } from 'moment';
import { Client } from 'app/entities/client/client';
import { translate } from 'app/config/translate-component';

export interface IChartOrcamentosPorStatusProps extends StateProps, DispatchProps {
  periodoInicio?: Moment;
  periodoFim?: Moment;
  operadora?: Array<Client>;
  orcamentosPorStatus: {
    OrcamentosPorStatus: Array<{ Status: string; Total: number }>;
  };
}

const ChartOrcamentosPorStatus = (props: IChartOrcamentosPorStatusProps) => {
  return (
    <div className="card border-0 bg-dark text-white mb-3">
      <div className="card-body">
        <div className="mb-3 text-grey">
          <b>Valor total dos orçamentos por status</b>
        </div>

        <div style={{ maxHeight: '305px', overflow: 'auto' }}>
          {props.orcamentosPorStatus && props.orcamentosPorStatus?.OrcamentosPorStatus ? (
            props.orcamentosPorStatus.OrcamentosPorStatus.map((v, i) => (
              <div key={i} className="d-flex align-items-center m-b-15">
                <div className="text-truncate">
                  <div>{translate('generadorApp.QuoteStatus.' + v.Status)}</div>
                </div>
                <div className="ml-auto text-center">
                  <div className="f-s-13">R$ {(v.Total * 1).toFixed(2).replace('.', ',')}</div>
                </div>
              </div>
            ))
          ) : (
            <> </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = storeState => ({
  report:
    storeState.jhiReports.reportsList && storeState.jhiReports.reportsList['pacientes-hospitalizados']
      ? storeState.jhiReports.reportsList['pacientes-hospitalizados']
      : [],
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps)(ChartOrcamentosPorStatus);
