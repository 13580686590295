/* eslint complexity: ["error", 500] */
import React from 'react';

import { getFilterFromSelect, getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import { connect } from 'react-redux';

import SelectAsync from 'react-select/async';
import { Link, RouteComponentProps } from 'react-router-dom';

import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { Button, Col, Row, Table, Label, UncontrolledCollapse, CardBody } from 'reactstrap';
import { AvForm, div } from 'availity-reactstrap-validation';
import { JhiItemCount } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { IRootState } from 'app/shared/reducers';
import {
  updateEntity,
  deleteEntity,
  getUrlBack,
  getMedicalRecordState,
  IMedicalRecordBaseState,
  getEntityFiltersURL,
  getEntities,
  reset,
} from './medical-record.reducer';

import { APP_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE, IPaginationBaseState, getSortState } from 'app/shared/util/pagination.constants';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';
import moment from 'moment';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

export interface IMedicalRecordProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}
export type IMedicalRecordStateAux = IMedicalRecordBaseState;

export interface IMedicalRecordState extends IMedicalRecordStateAux, IPaginationBaseState {}

export class MedicalRecord extends React.Component<IMedicalRecordProps, IMedicalRecordState> {
  protected myFormRef: any;

  constructor(props: IMedicalRecordProps) {
    super(props);
    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      listCheckedID: [],
      isCheckedAll: false,
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      urlBack: getUrlBack(this.props.location),
      ...getMedicalRecordState(this.props.location),
    };
  }

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({ listCheckedID: this.props.medicalRecordList.map(medicalRecord => medicalRecord.id) });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({ listCheckedID: this.props.medicalRecordList.map(medicalRecord => medicalRecord.id) });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  removeEntitySelected() {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  }

  saveEntityMany = () => {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  };

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  deleteEntity(medicalRecordEntity) {
    MySwal.fire({
      title: <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>,
      html: (
        <Translate contentKey="generadorApp.medicalRecord.delete.question" interpolate={{ id: medicalRecordEntity.id }}>
          Are you sure you want to delete this MedicalRecord?
        </Translate>
      ),
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#e70015',
      cancelButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="ban" />
          &nbsp; <Translate contentKey="generadorApp.medicalRecord.btnCancel">Cancel</Translate>
        </>
      ),
      confirmButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="trash" />
          &nbsp;<Translate contentKey="generadorApp.medicalRecord.btnDelete">Delete</Translate>
        </>
      ),
    }).then(async response => {
      if (response.isConfirmed) {
        await this.props.deleteEntity(medicalRecordEntity.id, { reloadList: false });
        this.getEntities();
        MySwal.fire({
          title: <Translate contentKey="entity.delete.title.confirmed">Confirmed delete operation</Translate>,
          icon: 'success',
        });
      }
    });
  }

  cancelFilters = () => {
    this.setState(
      {
        patientId: '',
        specialtyId: '',
        extraFilters: {},
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
        activePage: 0,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();

    const selectFieldsList = [
      'passageReason',
      'fall',
      'clinicalInstability',
      'exam',
      'examType',
      'hospitalizationDate',
      'dischargeDate',
      'hospitalizationReason',
      'occurrence',
      'manifestation',
      'compliment',
      'complaint',
      'suggestion',
      'additionalInformation',
      'type',
      'date',
      'reason',
      'conduct',
      'patient.id',
      'specialty.name',
    ];

    const { patientId, specialtyId, extraFilters, activePage, itemsPerPage, sort, order } = this.state;
    return this.props.getEntities(
      getFilterFromSelect(patientId, 'many-to-one'),
      getFilterFromSelect(specialtyId, 'many-to-one'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(',')
    );
  };

  updateEntity = entity => {
    const selectFieldsList = [
      'passageReason',
      'fall',
      'clinicalInstability',
      'exam',
      'examType',
      'hospitalizationDate',
      'dischargeDate',
      'hospitalizationReason',
      'occurrence',
      'manifestation',
      'compliment',
      'complaint',
      'suggestion',
      'additionalInformation',
      'type',
      'date',
      'reason',
      'conduct',
      'patient.id',
      'specialty.name',
    ];

    const { patientId, specialtyId, extraFilters, activePage, itemsPerPage, sort, order } = this.state;
    this.props.updateEntity(entity, [
      getFilterFromSelect(patientId, 'many-to-one'),
      getFilterFromSelect(specialtyId, 'many-to-one'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(','),
    ]);
  };

  renderHeader() {
    const { medicalRecordFiles, configTags, clinicalIndicators, patients, specialties, medicalRecordList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.medicalRecord.home.title">Medical Records</Translate>
          </span>
          <Button id="togglerFilterMedicalRecord" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.medicalRecord.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          &nbsp;
          {hasAnyAuthority(props.userAccount, ['medical-record'], 'resgister') ? (
            <Link
              to={`${match.url}/new?${getEntityFiltersURL(state)}`}
              className="btn btn-primary float-right jh-create-entity"
              id="jh-create-entity"
            >
              <FontAwesomeIcon icon="plus" />
              &nbsp;
              <Translate contentKey="generadorApp.medicalRecord.btnNewItem">Create new</Translate>
            </Link>
          ) : (
            <></>
          )}{' '}
          &nbsp;
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.medicalRecord.home.title">Medical Records</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    const { medicalRecordFiles, configTags, clinicalIndicators, patients, specialties, medicalRecordList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <UncontrolledCollapse toggler="#togglerFilterMedicalRecord">
          <CardBody>
            <AvForm ref={el => (this.myFormRef = el)} id="form-filter" onSubmit={this.filterEntity}>
              <div className="row mt-1 ml-3 mr-3">
                {this.state.baseFilters !== 'patient' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="medical-record-patient">
                          <Translate contentKey="generadorApp.medicalRecord.patient">Patient</Translate>
                        </Label>
                        <SelectAsync
                          id="medical-record-patient"
                          isMulti
                          className={'css-select-control'}
                          name={'patient'}
                          cacheOptions
                          value={this.state.patientId}
                          onChange={options => this.setState({ patientId: options })}
                          defaultOptions={this.state.patientStartFilter ? this.state.patientStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.patientStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.patientStartFilter === undefined) {
                              const result = await getListAxios('patients', {}, 0, 100, 'id,asc', 'id');
                              this.setState({
                                patientStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios('patients', { 'id.contains': inputValue }, 0, 100, 'id,asc', 'id');
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'specialty' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="medical-record-specialty">
                          <Translate contentKey="generadorApp.medicalRecord.specialty">Specialty</Translate>
                        </Label>
                        <SelectAsync
                          id="medical-record-specialty"
                          isMulti
                          className={'css-select-control'}
                          name={'specialty'}
                          cacheOptions
                          value={this.state.specialtyId}
                          onChange={options => this.setState({ specialtyId: options })}
                          defaultOptions={this.state.specialtyStartFilter ? this.state.specialtyStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.specialtyStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.specialtyStartFilter === undefined) {
                              const result = await getListAxios('specialties', {}, 0, 100, 'name,asc', 'name');
                              this.setState({
                                specialtyStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios('specialties', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}
              </div>

              <div className="row mb-2 mr-4 justify-content-end">
                <Button color="primary" type="submit">
                  <i className="fa fa-filter" aria-hidden={'true'}></i>&nbsp;
                  <Translate contentKey="generadorApp.medicalRecord.btnFilter">Filter</Translate>
                </Button>
                &nbsp;
                <Button color="secondary" onClick={this.cancelFilters}>
                  <FontAwesomeIcon icon="trash-alt" />
                  &nbsp;
                  <Translate contentKey="generadorApp.medicalRecord.btnCleanFilter">Clean Filter</Translate>
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </UncontrolledCollapse>
      </>
    );
  }

  renderTable() {
    const { medicalRecordFiles, configTags, clinicalIndicators, patients, specialties, medicalRecordList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {medicalRecordList && medicalRecordList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null).length > 0 ? (
          <>
            <Table
              id="medical-record-table-list"
              responsive
              aria-describedby="medical-record-heading"
              className={'table-hover table-striped mt-4 table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.medicalRecord.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'passageReason' ? (
                    <th className="hand" onClick={sortFunction('passageReason')}>
                      <Translate contentKey="generadorApp.medicalRecord.passageReason">Passage Reason</Translate>
                      <FontAwesomeIcon icon={state.sort === 'passageReason' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'fall' ? (
                    <th className="hand" onClick={sortFunction('fall')}>
                      <Translate contentKey="generadorApp.medicalRecord.fall">Fall</Translate>
                      <FontAwesomeIcon icon={state.sort === 'fall' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'clinicalInstability' ? (
                    <th className="hand" onClick={sortFunction('clinicalInstability')}>
                      <Translate contentKey="generadorApp.medicalRecord.clinicalInstability">Clinical Instability</Translate>
                      <FontAwesomeIcon
                        icon={state.sort === 'clinicalInstability' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'}
                      />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'exam' ? (
                    <th className="hand" onClick={sortFunction('exam')}>
                      <Translate contentKey="generadorApp.medicalRecord.exam">Exam</Translate>
                      <FontAwesomeIcon icon={state.sort === 'exam' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'examType' ? (
                    <th className="hand" onClick={sortFunction('examType')}>
                      <Translate contentKey="generadorApp.medicalRecord.examType">Exam Type</Translate>
                      <FontAwesomeIcon icon={state.sort === 'examType' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'hospitalizationDate' ? (
                    <th className="hand" onClick={sortFunction('hospitalizationDate')}>
                      <Translate contentKey="generadorApp.medicalRecord.hospitalizationDate">Hospitalization Date</Translate>
                      <FontAwesomeIcon
                        icon={state.sort === 'hospitalizationDate' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'}
                      />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'dischargeDate' ? (
                    <th className="hand" onClick={sortFunction('dischargeDate')}>
                      <Translate contentKey="generadorApp.medicalRecord.dischargeDate">Discharge Date</Translate>
                      <FontAwesomeIcon icon={state.sort === 'dischargeDate' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'hospitalizationReason' ? (
                    <th className="hand" onClick={sortFunction('hospitalizationReason')}>
                      <Translate contentKey="generadorApp.medicalRecord.hospitalizationReason">Hospitalization Reason</Translate>
                      <FontAwesomeIcon
                        icon={state.sort === 'hospitalizationReason' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'}
                      />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'occurrence' ? (
                    <th className="hand" onClick={sortFunction('occurrence')}>
                      <Translate contentKey="generadorApp.medicalRecord.occurrence">Occurrence</Translate>
                      <FontAwesomeIcon icon={state.sort === 'occurrence' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'manifestation' ? (
                    <th className="hand" onClick={sortFunction('manifestation')}>
                      <Translate contentKey="generadorApp.medicalRecord.manifestation">Manifestation</Translate>
                      <FontAwesomeIcon icon={state.sort === 'manifestation' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'compliment' ? (
                    <th className="hand" onClick={sortFunction('compliment')}>
                      <Translate contentKey="generadorApp.medicalRecord.compliment">Compliment</Translate>
                      <FontAwesomeIcon icon={state.sort === 'compliment' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'complaint' ? (
                    <th className="hand" onClick={sortFunction('complaint')}>
                      <Translate contentKey="generadorApp.medicalRecord.complaint">Complaint</Translate>
                      <FontAwesomeIcon icon={state.sort === 'complaint' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'suggestion' ? (
                    <th className="hand" onClick={sortFunction('suggestion')}>
                      <Translate contentKey="generadorApp.medicalRecord.suggestion">Suggestion</Translate>
                      <FontAwesomeIcon icon={state.sort === 'suggestion' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'additionalInformation' ? (
                    <th className="hand" onClick={sortFunction('additionalInformation')}>
                      <Translate contentKey="generadorApp.medicalRecord.additionalInformation">Additional Information</Translate>
                      <FontAwesomeIcon
                        icon={state.sort === 'additionalInformation' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'}
                      />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'type' ? (
                    <th className="hand" onClick={sortFunction('type')}>
                      <Translate contentKey="generadorApp.medicalRecord.type">Type</Translate>
                      <FontAwesomeIcon icon={state.sort === 'type' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'date' ? (
                    <th className="hand" onClick={sortFunction('date')}>
                      <Translate contentKey="generadorApp.medicalRecord.date">Date of occurrence</Translate>
                      <FontAwesomeIcon icon={state.sort === 'date' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'reason' ? (
                    <th className="hand" onClick={sortFunction('reason')}>
                      <Translate contentKey="generadorApp.medicalRecord.reason">Reason</Translate>
                      <FontAwesomeIcon icon={state.sort === 'reason' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'conduct' ? (
                    <th className="hand" onClick={sortFunction('conduct')}>
                      <Translate contentKey="generadorApp.medicalRecord.conduct">Conduct</Translate>
                      <FontAwesomeIcon icon={state.sort === 'conduct' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'patient' ? (
                    <th>
                      <Translate contentKey="generadorApp.medicalRecord.patient">Patient</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'specialty' ? (
                    <th>
                      <Translate contentKey="generadorApp.medicalRecord.specialty">Specialty</Translate>
                    </th>
                  ) : null}

                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {medicalRecordList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((medicalRecord, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.medicalRecord.field_id">ID</Translate>
                        </b>
                        {medicalRecord.id}
                      </td>

                      {state.baseFilters !== 'passageReason' ? (
                        <td id="passageReason-cell">
                          <b className="visible-xs"> Passage Reason </b>

                          {medicalRecord.passageReason}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'fall' ? (
                        <td id="fall-cell">
                          <b className="visible-xs"> Fall </b>

                          {medicalRecord.fall}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'clinicalInstability' ? (
                        <td id="clinicalInstability-cell">
                          <b className="visible-xs"> Clinical Instability </b>

                          {medicalRecord.clinicalInstability}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'exam' ? (
                        <td id="exam-cell">
                          <b className="visible-xs"> Exam </b>
                          {medicalRecord.exam ? <Translate contentKey={`generadorApp.Exam.${medicalRecord.exam}`} /> : <> </>}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'examType' ? (
                        <td id="examType-cell">
                          <b className="visible-xs"> Exam Type </b>
                          {medicalRecord.examType ? <Translate contentKey={`generadorApp.ExamType.${medicalRecord.examType}`} /> : <> </>}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'hospitalizationDate' ? (
                        <td id="hospitalizationDate-cell">
                          <b className="visible-xs"> Hospitalization Date </b>
                          {medicalRecord.hospitalizationDate ? moment(medicalRecord.hospitalizationDate).format(APP_DATE_FORMAT) : ''}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'dischargeDate' ? (
                        <td id="dischargeDate-cell">
                          <b className="visible-xs"> Discharge Date </b>
                          {medicalRecord.dischargeDate ? moment(medicalRecord.dischargeDate).format(APP_DATE_FORMAT) : ''}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'hospitalizationReason' ? (
                        <td id="hospitalizationReason-cell">
                          <b className="visible-xs"> Hospitalization Reason </b>

                          {medicalRecord.hospitalizationReason}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'occurrence' ? (
                        <td id="occurrence-cell">
                          <b className="visible-xs"> Occurrence </b>
                          {medicalRecord.occurrence ? (
                            <Translate contentKey={`generadorApp.Ocurrence.${medicalRecord.occurrence}`} />
                          ) : (
                            <> </>
                          )}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'manifestation' ? (
                        <td id="manifestation-cell">
                          <b className="visible-xs"> Manifestation </b>
                          {medicalRecord.manifestation ? (
                            <Translate contentKey={`generadorApp.Manifestation.${medicalRecord.manifestation}`} />
                          ) : (
                            <> </>
                          )}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'compliment' ? (
                        <td id="compliment-cell">
                          <b className="visible-xs"> Compliment </b>

                          {medicalRecord.compliment}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'complaint' ? (
                        <td id="complaint-cell">
                          <b className="visible-xs"> Complaint </b>

                          {medicalRecord.complaint}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'suggestion' ? (
                        <td id="suggestion-cell">
                          <b className="visible-xs"> Suggestion </b>

                          {medicalRecord.suggestion}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'additionalInformation' ? (
                        <td id="additionalInformation-cell">
                          <b className="visible-xs"> Additional Information </b>

                          {medicalRecord.additionalInformation}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'type' ? (
                        <td id="type-cell">
                          <b className="visible-xs"> Type </b>

                          {medicalRecord.type}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'date' ? (
                        <td id="date-cell">
                          <b className="visible-xs"> Date of occurrence </b>
                          {medicalRecord.date ? moment(medicalRecord.date).format(APP_DATE_FORMAT) : ''}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'reason' ? (
                        <td id="reason-cell">
                          <b className="visible-xs"> Reason </b>

                          {medicalRecord.reason}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'conduct' ? (
                        <td id="conduct-cell">
                          <b className="visible-xs"> Conduct </b>

                          {medicalRecord.conduct}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'patient' ? (
                        <td id="patient-cell">
                          <b className="visible-xs"> Patient </b>
                          {showFieldsSelectAsync(medicalRecord, 'patient.id', null).map((v, ikey) => (
                            <span key={ikey}> {v} </span>
                          ))}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'specialty' ? (
                        <td id="specialty-cell">
                          <b className="visible-xs"> Specialty </b>
                          {showFieldsSelectAsync(medicalRecord, 'specialty.name', null).map((v, ikey) => (
                            <span key={ikey}> {v} </span>
                          ))}
                        </td>
                      ) : null}

                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          {hasAnyAuthority(props.userAccount, ['medical-record'], 'view') ? (
                            <Button tag={Link} to={`${match.url}/${medicalRecord.id}`} color="info" size="sm">
                              <FontAwesomeIcon icon="eye" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                          {hasAnyAuthority(props.userAccount, ['medical-record'], 'edit') ? (
                            <Button
                              tag={Link}
                              to={`${match.url}/${medicalRecord.id}/edit?${getEntityFiltersURL(
                                state,
                                this.state.itemsPerPage * (this.state.activePage - 1) + i
                              )}`}
                              color="primary"
                              size="sm"
                            >
                              <FontAwesomeIcon icon="pencil-alt" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                          {hasAnyAuthority(props.userAccount, ['medical-record'], 'canDelete') ? (
                            <Button onClick={() => this.deleteEntity(medicalRecord)} color="danger" size="sm">
                              <FontAwesomeIcon icon="trash" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                          <Button tag={Link} to={`/medical-record/${medicalRecord.id}/medical-record-file`} color="info" size="sm">
                            <FontAwesomeIcon icon="file" />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.medicalRecord.home.notFound">No Medical Records found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const { medicalRecordFiles, configTags, clinicalIndicators, patients, specialties, medicalRecordList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={medicalRecordList && medicalRecordList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const { medicalRecordFiles, configTags, clinicalIndicators, patients, specialties, medicalRecordList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel className="medical-record medical-record-list-table">
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToProps = ({ ...storeState }: IRootState) => {
  return {
    medicalRecordFiles: storeState.medicalRecordFile.entities,
    configTags: storeState.configTag.entities,
    clinicalIndicators: storeState.clinicalIndicator.entities,
    patients: storeState.patient.entities,
    specialties: storeState.specialty.entities,
    medicalRecordList: storeState.medicalRecord.entities,
    totalItems: storeState.medicalRecord.totalItems,
    userAccount: storeState.authentication.account,
    loading: storeState.medicalRecord.loading,
  };
};

export const mapDispatchToProps = {
  getEntities,

  deleteEntity,
  updateEntity,
  reset,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MedicalRecord);
