import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { IMedicineMaterial } from 'app/shared/model/medicine-material.model';

export interface ISimpro {
  id?: number;
  cdUsuario?: string;
  cdFracao?: string;
  descricao?: string;
  vigencia?: string;
  identif?: string;
  pcEmFab?: number;
  pcEmVen?: number;
  pcEmUsu?: number;
  pcFrFab?: number;
  pcFrVen?: number;
  pcFrUsu?: number;
  tpEmbal?: string;
  tpFracao?: string;
  qtdeEmbal?: number;
  qtdeFrac?: number;
  percLucr?: number;
  tipAlt?: string;
  fabrica?: string;
  cdSimpro?: string;
  cdMercado?: string;
  percDesc?: number;
  ipiProduto?: number;
  registroAnvisa?: string;
  validadeAnvisa?: string;
  cdBarra?: string;
  lista?: string;
  hospitalar?: string;
  fracionar?: string;
  cdTuss?: string;
  cdClassif?: string;
  cdRefPro?: string;
  generico?: string;
  status?: boolean;
  medicineMaterial?: IMedicineMaterial[];
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  medicineMaterial: {
    isMulti: false, // one-to-many - other-side
    entity: 'MedicineMaterial',
    urlParameters: {
      enpoint: 'medicine-materials',
      filters: 'simpro.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'simpro.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'simpro.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<ISimpro> = {
  status: false,
};
