import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';
import moment from 'moment';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import {
  getEntity,
  IMedicineMaterialStatusBaseState,
  getUrlBack,
  getMedicineMaterialStatusState,
  getEntityFiltersURL,
} from './medicine-material-status.reducer';

import { APP_DATE_FORMAT } from 'app/config/constants';

export interface IMedicineMaterialStatusState {
  fieldsBase: IMedicineMaterialStatusBaseState;
}

export interface IMedicineMaterialStatusDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class MedicineMaterialStatusDetail extends React.Component<IMedicineMaterialStatusDetailProps, IMedicineMaterialStatusState> {
  constructor(props: Readonly<IMedicineMaterialStatusDetailProps>) {
    super(props);
    this.state = {
      ...this.state,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getMedicineMaterialStatusState(this.props.location),
        urlBack: getUrlBack(this.props.location),
        medicineMaterialId: [
          { value: this.props.match.params['idMedicineMaterial'], label: this.props.match.params['idMedicineMaterial'] },
        ],
        baseFilters: 'medicineMaterial',
      },
    };
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = '_back=1' + (offset !== null ? '&offset=' + offset : '');
    Object.keys(fieldsBase).map(key => {
      url += '&' + key + '=' + fieldsBase[key];
    });
    return url;
  };

  UNSAFE_componentWillMount() {
    this.props.getEntity(this.props.match.params['id']);
  }

  renderHeader() {
    const { medicineMaterialStatusEntity } = this.props;
    const state = this.state;
    return (
      <>
        <div id="page-heading">
          {' '}
          <h2>
            <Translate contentKey="generadorApp.medicineMaterialStatus.detail.title">MedicineMaterialStatus</Translate>
            <small>&nbsp; {medicineMaterialStatusEntity['status']}</small>
          </h2>
        </div>

        <div className="pull-right">
          <Button
            tag={Link}
            to={
              '/medicineMaterial/' +
              this.props.match.params['idMedicineMaterial'] +
              '/medicine-material-status?' +
              getEntityFiltersURL(this.state.fieldsBase)
            }
            replace
            color="info"
          >
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.medicineMaterialStatus.btnBack">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/medicineMaterial/${this.props.match.params['idMedicineMaterial']}/medicine-material-status/${
              medicineMaterialStatusEntity.id
            }/edit?+${getEntityFiltersURL(this.state.fieldsBase)}`}
            replace
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.medicineMaterialStatus.btnEdit">Edit</Translate>
            </span>
          </Button>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>

          <li className="breadcrumb-item">
            <Link to={'/medicineMaterial'}>Medicine Materials</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/medicineMaterial/' + this.props.match.params['idMedicineMaterial']}>
              {this.props.match.params['idMedicineMaterial']}
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link
              to={
                '/medicineMaterial/' +
                (this.state.fieldsBase.medicineMaterialId ? this.state.fieldsBase.medicineMaterialId.label : '') +
                '/medicine-material-status'
              }
            >
              Kit status Histories
            </Link>
          </li>
          <li className="breadcrumb-item active">Kit status Histories details</li>
        </ol>
      </>
    );
  }

  renderBody() {
    const { medicineMaterialStatusEntity } = this.props;
    const state = this.state;
    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            <Row className="jh-entity-details">
              <Col className="view-detail-row medicine-material-status-status-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top medicine-material-status-status-detail-label" md="12">
                    <dt>
                      <span id="status">
                        <Translate contentKey="generadorApp.medicineMaterialStatus.status">Status</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top medicine-material-status-status-detail-value" md="12">
                    <dd>
                      {translate(
                        'generadorApp.Status.' + (medicineMaterialStatusEntity.status ? medicineMaterialStatusEntity.status : 'INATIVADO')
                      )}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row medicine-material-status-observation-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top medicine-material-status-observation-detail-label" md="12">
                    <dt>
                      <span id="observation">
                        <Translate contentKey="generadorApp.medicineMaterialStatus.observation">Observation</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top medicine-material-status-observation-detail-value" md="12">
                    <dd>{medicineMaterialStatusEntity.observation}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row medicine-material-status-createdBy-detail" md="12">
                <Row>
                  <Col className="view-detail-label-left medicine-material-status-createdBy-detail-label" md="3">
                    <dt>
                      <Translate contentKey="generadorApp.medicineMaterialStatus.createdBy">Created By</Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left medicine-material-status-createdBy-detail-value" md="9">
                    <dd>{medicineMaterialStatusEntity.createdBy ? medicineMaterialStatusEntity.createdBy.id : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row medicine-material-status-createdDate-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top medicine-material-status-createdDate-detail-label" md="12">
                    <dt>
                      <span id="createdDate">
                        <Translate contentKey="generadorApp.medicineMaterialStatus.createdDate">Created Date</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top medicine-material-status-createdDate-detail-value" md="12">
                    <dd>
                      {medicineMaterialStatusEntity.createdDate
                        ? moment(medicineMaterialStatusEntity.createdDate).format(APP_DATE_FORMAT)
                        : ''}
                    </dd>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { medicineMaterialStatusEntity } = this.props;
    const state = this.state;
    return (
      <div>
        <Panel className="medicine-material-status medicine-material-status-detail">
          <PanelHeader>{this.renderHeader()}</PanelHeader>

          <PanelBody>{this.renderBody()}</PanelBody>
        </Panel>
      </div>
    );
  }
}

export const mapStateToProps = ({ medicineMaterialStatus, ...storeState }: IRootState) => ({
  medicineMaterialStatusEntity: medicineMaterialStatus.entity,
});

export const mapDispatchToProps = {
  getEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MedicineMaterialStatusDetail);
