import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import MedicalRecord from './medical-record';

import MedicalRecordDetail from './medical-record-detail';

import MedicalRecordUpdate from './extended/medical-record-update';

import MedicalRecordFile from '../medical-record-file/index';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute path={`${match.path}/:idMedicalRecord/medical-record-file`} component={MedicalRecordFile} />

      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={MedicalRecordUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={MedicalRecordUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={MedicalRecordDetail} />
      <ErrorBoundaryRoute path={match.path} component={MedicalRecord} />
    </Switch>

    <Switch>
      {/* <ErrorBoundaryRoute path={`${match.path}/:idMedicalRecord/medical-record-file`} component={MedicalRecordFile} /> */}
    </Switch>
  </>
);

export default Routes;
