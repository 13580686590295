/* eslint-disable no-console */
/* eslint complexity: ["error", 300] */
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { QuoteUpdate, mapStateToProps, mapDispatchToProps } from '../quote-update';
import { IRootState } from 'app/shared/reducers';

import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Row, Col, Label, Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate } from 'app/config/translate-component';

import { callFunction } from 'app/shared/reports/jhi-reports.reducer';
import { checkAnyValueFields, getFilterFromSelect, getFormFromSelect, showFieldsSelectAsync } from 'app/shared/util/entity-utils';

import LevelGroupOpenTable from '../components/LevelGroupOpenTable';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';
import moment from 'moment';
registerLocale('pt', pt);

import { getEntityFiltersURL, updateStateManual } from 'app/entities/quote/quote.reducer';
import { createEntity as createPad, getEntity as getPad } from 'app/entities/pad/pad.reducer';
import { createEntity as createPharmacyStock, getEntity as getPharmacyStock } from 'app/entities/pharmacy-stock/pharmacy-stock.reducer';

import {
  mapDispatchToPropsExtended as mapDispatchToPropsQuoteProductService,
  QuoteProductServiceExtended as QuoteProductService,
} from 'app/entities/quote-product-service/extended/quote-product-service';

import {
  mapDispatchToPropsExtended as mapDispatchToPropsQuoteRh,
  QuoteRhExtended as QuoteRh,
} from 'app/entities/quote-rh/extended/quote-rh';

import {
  mapDispatchToPropsExtended as mapDispatchToPropsQuoteMedicine,
  QuoteMedicineExtended as QuoteMedicine,
} from 'app/entities/quote-medicine/extended/quote-medicine';

import {
  mapDispatchToPropsExtended as mapDispatchToPropsQuoteMaterial,
  QuoteMaterialExtended as QuoteMaterial,
} from 'app/entities/quote-material/extended/quote-material';

import {
  mapDispatchToPropsExtended as mapDispatchToPropsQuotePackage,
  QuotePackageExtended as QuotePackage,
} from 'app/entities/quote-package/extended/quote-package';

import {
  mapDispatchToPropsExtended as mapDispatchToPropsQuoteDiet,
  QuoteDietExtended as QuoteDiet,
} from 'app/entities/quote-diet/extended/quote-diet';
import { IClient } from 'app/shared/model/client.model';
import { convertDateTimeToServer } from 'app/shared/util/date-utils';
import { IPatient } from 'app/shared/model/patient.model';
import { IClComTable } from 'app/shared/model/cl-com-table.model';
import { Sub } from 'app/shared/model/enumerations/sub.model';
import Select from 'react-select';
import { translate } from 'react-jhipster';

import { ContaConsumoPdfReport } from 'app/entities/quote/report/conta-consumo';
import { GuiaInternacaoPdfReport } from 'app/entities/quote/report/guia-internacao';
import { GuiaServicoPdfReport } from 'app/entities/quote/report/guia-servico';

export interface IUpdateExtendedState {
  createPadAfterSubmit?: boolean;
  showModalContaConsumoReport?: boolean;
  showModalGuiaIntegracaoReport?: boolean;
  showModalGuiaServicoReport?: boolean;
}
const selectFieldsPai =
  'patient,patient.client.id,clComTable.id,quoteMaterial.medicineMaterial.brasindice.id,quoteMaterial.medicineMaterial.simpro.id,quoteMaterial.medicineMaterial.presentation.id,quoteMedicine.medicineMaterial.brasindice.id,quoteMedicine.medicineMaterial.simpro.id,quoteMedicine.medicineMaterial.presentation.id,quoteDiet.medicineMaterial.simpro.id,quoteDiet.medicineMaterial.brasindice.id,quoteDiet.medicineMaterial.presentation.id,quoteProductService.productService.id,quoteProductService.productService.category.levelGroup.id,quoteRh.specialty.category,quoteRh.specialty.category.id,quotePackage,quotePackage.clComTable,quotePackage.clComTable.id';
export class BillUpdate extends QuoteUpdate {
  UNSAFE_componentWillMount() {
    if (this.state.isNew) {
      this.props.reset();
      this.quotePackageReset();
      this.quoteRhReset();
      this.quoteMedicineReset();
      this.quoteMaterialReset();
      this.quoteDietReset();
      this.quoteProductServiceReset();
    } else {
      this.props.getEntity(this.props.match.params['id'], selectFieldsPai);
    }
  }

  saveEntityOld(event: any, errors: any, values: any, createPadAfter: boolean) {
    values.approvalDate = convertDateTimeToServer(values.approvalDate);
    values.from = convertDateTimeToServer(values.from);
    values.to = convertDateTimeToServer(values.to);

    if (errors.length === 0) {
      const { quoteEntity } = this.props;
      const entity = {
        ...quoteEntity,
        ...values,

        quoteDiet: undefined,

        quoteMedicine: undefined,

        quoteMaterial: undefined,

        quoteProductService: undefined,

        quoteRh: undefined,

        quoteFile: getFormFromSelect(this.state.quoteFileSelectValue, 'one-to-many'), // one-to-many - otherSide

        expectedResult: getFormFromSelect(this.state.expectedResultSelectValue, 'one-to-many'), // one-to-many - otherSide

        pad: getFormFromSelect(this.state.padSelectValue, 'one-to-many'), // one-to-many - otherSide

        supply: getFormFromSelect(this.state.supplySelectValue, 'one-to-many'), // one-to-many - otherSide

        patient: getFormFromSelect(this.state.patientSelectValue, 'many-to-one'), // many-to-one - otherSide

        clComTable: getFormFromSelect(this.state.clComTableSelectValue, 'many-to-one'), // many-to-one - otherSide

        source: this.state.sourceSelectValue === null ? 'AVALIACAO' : this.state.sourceSelectValue,
        status: this.state.statusSelectValue === null ? 'ORCAMENTOABERTO' : this.state.statusSelectValue,
        approvalDate: this.state.approvalDateSelectValue,
        from: this.state.fromSelectValue,
        to: this.state.toSelectValue,
        accountNumber: this.state.accountNumberSelectValue,
        finalAmount: this.state.finalAmountSelectValue,
        billFinalAmount: this.state.billFinalAmountSelectValue,
      };

      const { patientId, patientClientId, source, status, fromStart, fromEnd, toStart, toEnd } = this.state.fieldsBase;

      const quoteProductServiceListAllEmbed = this.props.quoteProductServiceListAllEmbed;
      const quoteRhListAllEmbed = this.props.quoteRhListAllEmbed;
      const quoteMedicineListAllEmbed = this.props.quoteMedicineListAllEmbed;
      const quoteMaterialListAllEmbed = this.props.quoteMaterialListAllEmbed;
      const quoteDietListAllEmbed = this.props.quoteDietListAllEmbed;

      if (this.state.isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(
              entity,
              !createPadAfter
                ? [
                    getFilterFromSelect(patientId, 'many-to-one'),
                    getFilterFromSelect(patientClientId, 'many-to-one'),
                    source,
                    status,
                    fromStart,
                    fromEnd,
                    toStart,
                    toEnd,
                  ]
                : { reloadList: false }
            )
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
          if (idEntity) {
            quoteProductServiceListAllEmbed &&
              quoteProductServiceListAllEmbed.map(v => {
                v['id'] = null;
                v['quote'] = { id: idEntity };
                this.props['createEntityQuoteProductService'](v);
              });
          }
          // createPad
          if (idEntity) {
            quoteRhListAllEmbed &&
              quoteRhListAllEmbed.map(v => {
                v['id'] = null;
                v['quote'] = { id: idEntity };

                this.props['createEntityQuoteRh'](v);
              });
          }
          if (idEntity) {
            quoteMedicineListAllEmbed.map(v => {
              v['id'] = null;
              v['quote'] = { id: idEntity };
              this.props['createEntityQuoteMedicine'](v);
            });
          }
          if (idEntity) {
            quoteMaterialListAllEmbed.map(v => {
              v['id'] = null;
              v['quote'] = { id: idEntity };
              this.props['createEntityQuoteMaterial'](v);
            });
          }
          if (idEntity) {
            quoteDietListAllEmbed.map(v => {
              v['id'] = null;
              v['quote'] = { id: idEntity };
              this.props['createEntityQuoteDiet'](v);
            });
          }

          if (createPadAfter && idEntity) {
            callFunction('fnCreateStockFromQuote', { parameter: idEntity });
          }
          if (quoteRhListAllEmbed.length > 0 && createPadAfter && idEntity) {
            new Promise(resolve => {
              resolve(
                this.props.createPad(
                  {
                    finalDate: this.state.toSelectValue,
                    initDate: this.state.fromSelectValue,
                    patient: getFormFromSelect(this.state.patientSelectValue, 'many-to-one'),
                    quote: { id: idEntity },
                  },
                  { reloadList: false }
                )
              );
            }).then(resultEntityPad => {
              const idPad =
                resultEntityPad && resultEntityPad['value'] && resultEntityPad['value']['data'] && resultEntityPad['value']['data']['id']
                  ? resultEntityPad['value']['data']['id']
                  : -1;
              new Promise(resolve => {
                resolve(this.props.getPad(idPad));
              }).then(resultPad => {
                //  this.props.history.push('/pad/' + idPad + '/edit');
              });
            });
          }
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(
              entity,
              !createPadAfter ? [patientId, patientClientId, source, status, fromStart, fromEnd, toStart, toEnd] : { reloadList: false }
            )
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;

          if (createPadAfter && idEntity) {
            callFunction('fnCreateStockFromQuote', { parameter: idEntity });
          }
          if (quoteRhListAllEmbed.length > 0 && createPadAfter && idEntity) {
            new Promise(resolve => {
              resolve(
                this.props.createPad(
                  {
                    finalDate: this.state.toSelectValue,
                    initDate: this.state.fromSelectValue,
                    patient: getFormFromSelect(this.state.patientSelectValue, 'many-to-one'),
                    quote: { id: idEntity },
                  },
                  { reloadList: false }
                )
              );
            }).then(resultEntityPad => {
              const idPad =
                resultEntityPad && resultEntityPad['value'] && resultEntityPad['value']['data'] && resultEntityPad['value']['data']['id']
                  ? resultEntityPad['value']['data']['id']
                  : -1;
              new Promise(resolve => {
                resolve(this.props.getPad(idPad));
              }).then(resultPad => {
                //     this.props.history.push('/pad/' + idPad + '/edit');
              });
            });
          }
        });
      }
    }
  }

  quotePackageUpdateEntity(...values) {
    const _aux = this.props.quotePackageListEmbed.map(v => (v.id === values[0].id ? { ...v, ...values[0] } : v));
    this.props.updateStateManual('quotePackageListEmbed', _aux);

    return super.quotePackageUpdateEntity(...values);
  }

  quoteRhUpdateEntity(...values) {
    const _aux = this.props.quoteRhListEmbed.map(v => (v.id === values[0].id ? { ...v, ...values[0] } : v));
    this.props.updateStateManual('quoteRhListEmbed', _aux);

    return super.quoteRhUpdateEntity(...values);
  }

  quoteMedicineUpdateEntity(...values) {
    const _aux = this.props.quoteMedicineListEmbed.map(v => (v.id === values[0].id ? { ...v, ...values[0] } : v));
    this.props.updateStateManual('quoteMedicineListEmbed', _aux);

    return super.quoteMedicineUpdateEntity(...values);
  }

  quoteMaterialUpdateEntity(...values) {
    const _aux = this.props.quoteMaterialListEmbed.map(v => (v.id === values[0].id ? { ...v, ...values[0] } : v));
    this.props.updateStateManual('quoteMaterialListEmbed', _aux);

    return super.quoteMaterialUpdateEntity(...values);
  }

  quoteDietUpdateEntity(...values) {
    const _aux = this.props.quoteDietListEmbed.map(v => (v.id === values[0].id ? { ...v, ...values[0] } : v));
    this.props.updateStateManual('quoteDietListEmbed', _aux);

    return super.quoteDietUpdateEntity(...values);
  }

  quoteProductServiceUpdateEntity(...values) {
    const _aux = this.props.quoteProductServiceListEmbed.map(v => (v.id === values[0].id ? { ...v, ...values[0] } : v));
    this.props.updateStateManual('quoteProductServiceListEmbed', _aux);

    return super.quoteProductServiceUpdateEntity(...values);
  }

  calcTotalAmount() {
    let total = 0;
    this.props.quotePackageListEmbed &&
      this.props.quotePackageListEmbed
        .filter(v => v.deletedAt === null)
        .map(v => {
          total += parseFloat((v.billFinalAmount ? v.billFinalAmount : 0) + '');
        });
    this.props.quoteRhListEmbed &&
      this.props.quoteRhListEmbed
        .filter(v => v.deletedAt === null)
        .map(v => {
          total += parseFloat((v.billFinalAmount ? v.billFinalAmount : 0) + '');
        });
    this.props.quoteMedicineListEmbed &&
      this.props.quoteMedicineListEmbed
        .filter(v => v.deletedAt === null)
        .map(v => {
          total += parseFloat((v.billFinalAmount ? v.billFinalAmount : 0) + '');
        });
    this.props.quoteMaterialListEmbed &&
      this.props.quoteMaterialListEmbed
        .filter(v => v.deletedAt === null)
        .map(v => {
          total += parseFloat((v.billFinalAmount ? v.billFinalAmount : 0) + '');
        });
    this.props.quoteDietListEmbed &&
      this.props.quoteDietListEmbed
        .filter(v => v.deletedAt === null)
        .map(v => {
          total += parseFloat((v.billFinalAmount ? v.billFinalAmount : 0) + '');
        });
    this.props.quoteProductServiceListEmbed &&
      this.props.quoteProductServiceListEmbed
        .filter(v => v.deletedAt === null)
        .map(v => {
          total += parseFloat((v.billFinalAmount ? v.billFinalAmount : 0) + '');
        });
    if (total !== this.state.billFinalAmountSelectValue && !this.props.loading && !this.props.updating) {
      this.setState({ billFinalAmountSelectValue: total });
      if (!this.state.isNew && !this.props.updating) {
        axios.put('/api/quotes', {
          id: this.props.match.params['id'] * 1,
          billFinalAmount: total,
        });
      }
    }

    return total;
  }

  componentDidUpdate(prevProps) {
    this.calcTotalAmount();

    if (prevProps.updateSuccess !== this.props.updateSuccess && prevProps.updateSuccess) {
      this.handleClose();
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    // if (
    //   (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
    //   (this.props.quoteDiets.length > 0 && this.state.quoteDietSelectValue === null && this.props.quoteEntity.quoteDiet)
    // ) {
    //   this.setState({ quoteDietSelectValue: this.props.quoteEntity.quoteDiet });
    //   this.props.quoteDietSetEntities(this.props.quoteEntity.quoteDiet);
    // }

    // // data-type-rel -> tipo-2 one-to-many other-side
    // if (
    //   (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
    //   (this.props.quoteMedicines.length > 0 && this.state.quoteMedicineSelectValue === null && this.props.quoteEntity.quoteMedicine)
    // ) {
    //   this.setState({ quoteMedicineSelectValue: this.props.quoteEntity.quoteMedicine });
    //   this.props.quoteMedicineSetEntities(this.props.quoteEntity.quoteMedicine);
    // }

    // // data-type-rel -> tipo-2 one-to-many other-side
    // if (
    //   (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
    //   (this.props.quoteMaterials.length > 0 && this.state.quoteMaterialSelectValue === null && this.props.quoteEntity.quoteMaterial)
    // ) {
    //   this.setState({ quoteMaterialSelectValue: this.props.quoteEntity.quoteMaterial });
    //   this.props.quoteMaterialSetEntities(this.props.quoteEntity.quoteMaterial);
    // }

    // // data-type-rel -> tipo-2 one-to-many other-side
    // if (
    //   (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
    //   (this.props.quoteProductServices.length > 0 &&
    //     this.state.quoteProductServiceSelectValue === null &&
    //     this.props.quoteEntity.quoteProductService)
    // ) {
    //   this.setState({ quoteProductServiceSelectValue: this.props.quoteEntity.quoteProductService });
    //   this.props.quoteProductServiceSetEntities(this.props.quoteEntity.quoteProductService);
    // }

    // // data-type-rel -> tipo-2 one-to-many other-side
    // if (
    //   (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
    //   (this.props.quotePackages.length > 0 && this.state.quotePackageSelectValue === null && this.props.quoteEntity.quotePackage)
    // ) {
    //   this.setState({ quotePackageSelectValue: this.props.quoteEntity.quotePackage });
    //   this.props.quotePackageSetEntities(this.props.quoteEntity.quotePackage);
    // }

    // // data-type-rel -> tipo-2 one-to-many other-side
    // if (
    //   (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
    //   (this.props.quoteRhs.length > 0 && this.state.quoteRhSelectValue === null && this.props.quoteEntity.quoteRh)
    // ) {
    //   this.setState({ quoteRhSelectValue: this.props.quoteEntity.quoteRh });
    //   this.props.quoteRhSetEntities(this.props.quoteEntity.quoteRh);
    // }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteFiles.length > 0 && this.state.quoteFileSelectValue === null && this.props.quoteEntity.quoteFile)
    ) {
      this.setState({
        quoteFileSelectValue:
          this.props.quoteEntity && this.props.quoteEntity.quoteFile
            ? this.props.quoteEntity.quoteFile.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.expectedResults.length > 0 && this.state.expectedResultSelectValue === null && this.props.quoteEntity.expectedResult)
    ) {
      this.setState({
        expectedResultSelectValue:
          this.props.quoteEntity && this.props.quoteEntity.expectedResult
            ? this.props.quoteEntity.expectedResult.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.pads.length > 0 && this.state.padSelectValue === null && this.props.quoteEntity.pad)
    ) {
      this.setState({
        padSelectValue:
          this.props.quoteEntity && this.props.quoteEntity.pad
            ? this.props.quoteEntity.pad.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.supplies.length > 0 && this.state.supplySelectValue === null && this.props.quoteEntity.supply)
    ) {
      this.setState({
        supplySelectValue:
          this.props.quoteEntity && this.props.quoteEntity.supply
            ? this.props.quoteEntity.supply.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.patients && this.state.patientSelectValue === null && this.props.quoteEntity.patient && this.props.quoteEntity.patient.id)
    ) {
      const p = this.props.quoteEntity && this.props.quoteEntity.patient ? this.props.quoteEntity.patient : null;
      this.setState({
        patientSelectValue: p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : null,
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.clComTables &&
        this.state.clComTableSelectValue === null &&
        this.props.quoteEntity.clComTable &&
        this.props.quoteEntity.clComTable.id)
    ) {
      const p = this.props.quoteEntity && this.props.quoteEntity.clComTable ? this.props.quoteEntity.clComTable : null;
      this.setState({
        clComTableSelectValue: p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : null,
      });
    }

    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.source &&
        this.state.sourceSelectValue === null &&
        this.props.quoteEntity.source !== this.state.sourceSelectValue)
    ) {
      this.setState({ sourceSelectValue: this.props.quoteEntity.source ? this.props.quoteEntity.source : null });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.status &&
        this.state.statusSelectValue === null &&
        this.props.quoteEntity.status !== this.state.statusSelectValue)
    ) {
      this.setState({ statusSelectValue: this.props.quoteEntity.status ? this.props.quoteEntity.status : null });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.approvalDate &&
        this.state.approvalDateSelectValue === null &&
        this.props.quoteEntity.approvalDate !== this.state.approvalDateSelectValue)
    ) {
      this.setState({ approvalDateSelectValue: this.props.quoteEntity.approvalDate ? this.props.quoteEntity.approvalDate : null });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.from && this.state.fromSelectValue === null && this.props.quoteEntity.from !== this.state.fromSelectValue)
    ) {
      this.setState({ fromSelectValue: this.props.quoteEntity.from ? this.props.quoteEntity.from : null });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.to && this.state.toSelectValue === null && this.props.quoteEntity.to !== this.state.toSelectValue)
    ) {
      this.setState({ toSelectValue: this.props.quoteEntity.to ? this.props.quoteEntity.to : null });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.accountNumber &&
        this.state.accountNumberSelectValue === null &&
        this.props.quoteEntity.accountNumber !== this.state.accountNumberSelectValue)
    ) {
      this.setState({ accountNumberSelectValue: this.props.quoteEntity.accountNumber ? this.props.quoteEntity.accountNumber : null });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.finalAmount &&
        this.state.finalAmountSelectValue === null &&
        this.props.quoteEntity.finalAmount !== this.state.finalAmountSelectValue)
    ) {
      this.setState({ finalAmountSelectValue: this.props.quoteEntity.finalAmount ? this.props.quoteEntity.finalAmount : null });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.billFinalAmount &&
        this.state.billFinalAmountSelectValue === null &&
        this.props.quoteEntity.billFinalAmount !== this.state.billFinalAmountSelectValue)
    ) {
      this.setState({ billFinalAmountSelectValue: this.props.quoteEntity.billFinalAmount ? this.props.quoteEntity.billFinalAmount : null });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.type && this.state.typeSelectValue === null && this.props.quoteEntity.type !== this.state.typeSelectValue)
    ) {
      this.setState({ typeSelectValue: this.props.quoteEntity.type ? this.props.quoteEntity.type : null });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.billingStatus &&
        this.state.billingStatusSelectValue === null &&
        this.props.quoteEntity.billingStatus !== this.state.billingStatusSelectValue)
    ) {
      this.setState({ billingStatusSelectValue: this.props.quoteEntity.billingStatus ? this.props.quoteEntity.billingStatus : null });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.billingPasswordNumber &&
        this.state.billingPasswordNumberSelectValue === null &&
        this.props.quoteEntity.billingPasswordNumber !== this.state.billingPasswordNumberSelectValue)
    ) {
      this.setState({
        billingPasswordNumberSelectValue: this.props.quoteEntity.billingPasswordNumber
          ? this.props.quoteEntity.billingPasswordNumber
          : null,
      });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.billingPasswordIssue &&
        this.state.billingPasswordIssueSelectValue === null &&
        this.props.quoteEntity.billingPasswordIssue !== this.state.billingPasswordIssueSelectValue)
    ) {
      this.setState({
        billingPasswordIssueSelectValue: this.props.quoteEntity.billingPasswordIssue ? this.props.quoteEntity.billingPasswordIssue : null,
      });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.billingPasswordExpiry &&
        this.state.billingPasswordExpirySelectValue === null &&
        this.props.quoteEntity.billingPasswordExpiry !== this.state.billingPasswordExpirySelectValue)
    ) {
      this.setState({
        billingPasswordExpirySelectValue: this.props.quoteEntity.billingPasswordExpiry
          ? this.props.quoteEntity.billingPasswordExpiry
          : null,
      });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.billingPasswordIssuer &&
        this.state.billingPasswordIssuerSelectValue === null &&
        this.props.quoteEntity.billingPasswordIssuer !== this.state.billingPasswordIssuerSelectValue)
    ) {
      this.setState({
        billingPasswordIssuerSelectValue: this.props.quoteEntity.billingPasswordIssuer
          ? this.props.quoteEntity.billingPasswordIssuer
          : null,
      });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.autorizationData &&
        this.state.autorizationDataSelectValue === null &&
        this.props.quoteEntity.autorizationData !== this.state.autorizationDataSelectValue)
    ) {
      this.setState({
        autorizationDataSelectValue: this.props.quoteEntity.autorizationData ? this.props.quoteEntity.autorizationData : null,
      });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.billingHospitalizationRecord &&
        this.state.billingHospitalizationRecordSelectValue === null &&
        this.props.quoteEntity.billingHospitalizationRecord !== this.state.billingHospitalizationRecordSelectValue)
    ) {
      this.setState({
        billingHospitalizationRecordSelectValue: this.props.quoteEntity.billingHospitalizationRecord
          ? this.props.quoteEntity.billingHospitalizationRecord
          : null,
      });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.billingHospitalizationGuide &&
        this.state.billingHospitalizationGuideSelectValue === null &&
        this.props.quoteEntity.billingHospitalizationGuide !== this.state.billingHospitalizationGuideSelectValue)
    ) {
      this.setState({
        billingHospitalizationGuideSelectValue: this.props.quoteEntity.billingHospitalizationGuide
          ? this.props.quoteEntity.billingHospitalizationGuide
          : null,
      });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.billingGuideSPSADT &&
        this.state.billingGuideSPSADTSelectValue === null &&
        this.props.quoteEntity.billingGuideSPSADT !== this.state.billingGuideSPSADTSelectValue)
    ) {
      this.setState({
        billingGuideSPSADTSelectValue: this.props.quoteEntity.billingGuideSPSADT ? this.props.quoteEntity.billingGuideSPSADT : null,
      });
    }
    if (
      (prevProps.quoteEntity && prevProps.quoteEntity.id !== this.props.quoteEntity.id) ||
      (this.props.quoteEntity.billingObservation &&
        this.state.billingObservationSelectValue === null &&
        this.props.quoteEntity.billingObservation !== this.state.billingObservationSelectValue)
    ) {
      this.setState({
        billingObservationSelectValue: this.props.quoteEntity.billingObservation ? this.props.quoteEntity.billingObservation : null,
      });
    }
  }

  handleClose = () => {
    // if (!this.state.createPadAfterSubmit) {
    this.props.history.push('/bills?' + getEntityFiltersURL(this.state.fieldsBase));
    //  }
  };

  renderHeader() {
    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            <Translate contentKey="generadorApp.bill.home.editLabel">Create a Bill</Translate>
          </span>
          <Button color="primary" id="save-entity" type="submit" disabled={this.props.updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.quote.btnSave">Save</Translate>
          </Button>
          <Button
            className={'float-right'}
            color="dark"
            onClick={() => {
              this.setState({
                showModalContaConsumoReport: true,
              });
            }}
          >
            <FontAwesomeIcon icon="file-pdf" />
            &nbsp; Visualizar PDF
          </Button>
          <Button
            className={'float-right'}
            color="dark"
            onClick={() => {
              this.setState({
                showModalGuiaIntegracaoReport: true,
              });
            }}
          >
            <FontAwesomeIcon icon="file-pdf" />
            &nbsp; Guia de Internação
          </Button>
          <Button
            className={'float-right'}
            color="dark"
            onClick={() => {
              this.setState({
                showModalGuiaServicoReport: true,
              });
            }}
          >
            <FontAwesomeIcon icon="file-pdf" />
            &nbsp; Guia de Serviço
          </Button>
          <Link to={'/bills?' + getEntityFiltersURL(this.state.fieldsBase)} className="float-right jh-create-entity btn btn-info">
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.bill.btnBack">Back</Translate>
            </span>
          </Link>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={'/bills'}>
              <Translate contentKey="generadorApp.bill.home.title">Bills</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.bill.detail.title">Bill edit</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderBody() {
    const { quoteEntity, patients, clComTables, loading, updating } = this.props;
    const { isNew } = this.state;

    const quoteEntityReadOnly = ['APROVADO', 'PRORROGADO', 'INATIVOREPROVADOPRECO', 'INATIVOCONTROLEINTERNO'].includes(quoteEntity.status);

    const fullEntityEmbebed = this.getFullEntityFromState(quoteEntity);

    const mapDispatchToPropsQuoteProductServiceList: any = {};
    Object.keys(mapDispatchToPropsQuoteProductService).map(v => {
      mapDispatchToPropsQuoteProductServiceList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'QuoteProductService')) {
          this.setState(
            { functionEmbebed: [...this.state.functionEmbebed, v + 'QuoteProductService'] },
            this.props[v + 'QuoteProductService']
          );
        }
      };
    });
    const mapDispatchToPropsQuoteRhList: any = {};
    Object.keys(mapDispatchToPropsQuoteRh).map(v => {
      mapDispatchToPropsQuoteRhList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'QuoteRh')) {
          this.setState({ functionEmbebed: [...this.state.functionEmbebed, v + 'QuoteRh'] }, this.props[v + 'QuoteRh']);
        }
      };
    });
    const mapDispatchToPropsQuotePackageList: any = {};
    Object.keys(mapDispatchToPropsQuotePackage).map(v => {
      mapDispatchToPropsQuotePackageList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'QuotePackage')) {
          this.setState({ functionEmbebed: [...this.state.functionEmbebed, v + 'QuotePackage'] }, this.props[v + 'QuotePackage']);
        }
      };
    });
    const mapDispatchToPropsQuoteMedicineList: any = {};
    Object.keys(mapDispatchToPropsQuoteMedicine).map(v => {
      mapDispatchToPropsQuoteMedicineList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'QuoteMedicine')) {
          this.setState({ functionEmbebed: [...this.state.functionEmbebed, v + 'QuoteMedicine'] }, this.props[v + 'QuoteMedicine']);
        }
      };
    });
    const mapDispatchToPropsQuoteMaterialList: any = {};
    Object.keys(mapDispatchToPropsQuoteMaterial).map(v => {
      mapDispatchToPropsQuoteMaterialList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'QuoteMaterial')) {
          this.setState({ functionEmbebed: [...this.state.functionEmbebed, v + 'QuoteMaterial'] }, this.props[v + 'QuoteMaterial']);
        }
      };
    });
    const mapDispatchToPropsQuoteDietList: any = {};
    Object.keys(mapDispatchToPropsQuoteDiet).map(v => {
      mapDispatchToPropsQuoteDietList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'QuoteDiet')) {
          this.setState({ functionEmbebed: [...this.state.functionEmbebed, v + 'QuoteDiet'] }, this.props[v + 'QuoteDiet']);
        }
      };
    });

    const quoteEntitySelectValue = {
      ...this.props.quoteEntity,
      source: this.state.sourceSelectValue,
      status: this.state.statusSelectValue,
      approvalDate: this.state.approvalDateSelectValue,
      from: this.state.fromSelectValue,
      to: this.state.toSelectValue,
    };

    const patientSelect: IPatient = this.state.patientSelectValue;
    const patientSelectClient: IClient = patientSelect ? patientSelect.client : null;
    const patientSelectClientTabelas: IClComTable[] =
      patientSelectClient && patientSelectClient.clComTable ? patientSelectClient.clComTable.filter(v => v.deletedAt === null) : [];
    const patientSelectTabela: IClComTable = patientSelectClientTabelas.length > 0 ? patientSelectClientTabelas[0] : null;

    const patientSelectSegment = patientSelectClient && patientSelectClient.segment ? patientSelectClient.segment : null;

    const clComTableRulers = {};
    if (patientSelectTabela) {
      this.props.listRulersList.map(v => {
        if (v.clComTable && v.clComTable.id === patientSelectTabela.id) {
          const levelGroupName = [Sub.ESPECIALIDADE, Sub.MATERIALMEDICAMENTOEDIETA].includes(v.levelGroup && v.levelGroup.sub)
            ? v.levelGroup.name
            : 'Outros';
          clComTableRulers[levelGroupName] = clComTableRulers[levelGroupName] ? clComTableRulers[levelGroupName] : [];
          clComTableRulers[levelGroupName].push(v);
        }
      });
    }

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? <AvInput id="quote-id" type="hidden" className="form-control" name="id" required readOnly /> : <></>}
                <Row>
                  <Col md="3">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" for="quote-id">
                            <Translate contentKey="generadorApp.quote.accountNumber">ID</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <input
                            value={quoteEntity && quoteEntity['accountNumber'] ? quoteEntity.accountNumber + '' : 'XXXX'}
                            className="form-control"
                            type="text"
                            disabled={true}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md="3">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" for="quote-id">
                            <Translate contentKey="generadorApp.quote.id">ID</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <input
                            value={quoteEntity && quoteEntity['id'] ? quoteEntity.id + '' : 'XXXX'}
                            className="form-control"
                            type="text"
                            disabled={true}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md="2">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" id="createdDateLabel" for="quote-createdDate">
                            <Translate contentKey="generadorApp.bill.createdDate">Created Date</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <input
                            value={
                              quoteEntity ? moment(quoteEntity.createdDate).format('DD/MM/YYYY') : moment(new Date()).format('DD/MM/YYYY')
                            }
                            className="form-control"
                            type="text"
                            disabled={true}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md="2">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" id="toLabel" for="quote-to">
                            <Translate contentKey="generadorApp.bill.to">Created Date</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <input
                            value={quoteEntity ? moment(quoteEntity.to).format('DD/MM/YYYY') : moment(new Date()).format('DD/MM/YYYY')}
                            className="form-control"
                            type="text"
                            disabled={true}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md="2">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" id="fromLabel" for="quote-from">
                            <Translate contentKey="generadorApp.bill.from">Created Date</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <input
                            value={quoteEntity ? moment(quoteEntity.from).format('DD/MM/YYYY') : moment(new Date()).format('DD/MM/YYYY')}
                            className="form-control"
                            type="text"
                            disabled={true}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md="3">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" for="quote-patient">
                            <Translate contentKey="generadorApp.quote.patient">Patient</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <AvInput
                            disabled={true}
                            value={showFieldsSelectAsync(quoteEntity, 'patient.name')}
                            id="quote-patient"
                            type="text"
                            name="patient"
                            className={'form-control'}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md="4">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" for="quote-patient.client">
                            <Translate contentKey="generadorApp.quote.patient.client">Patient</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <AvInput
                            disabled={true}
                            value={showFieldsSelectAsync(quoteEntity, 'patient.client.fantasyName')}
                            id="quote-patient.client"
                            type="text"
                            name="patient.client"
                            className={'form-control'}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md="2">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className={'mt-2'} id="typeLabel" for="quote-type">
                            <Translate contentKey="generadorApp.quote.type">Type</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <AvInput
                            disabled={true}
                            value={
                              this.state.typeSelectValue
                                ? translate('generadorApp.TypeClientCommercialTable.' + this.state.typeSelectValue)
                                : ''
                            }
                            id="quote-type"
                            type="text"
                            name="type"
                            className={'form-control'}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md="3">
                    <LevelGroupOpenTable
                      entityBase={quoteEntity}
                      baseState={this.state}
                      baseProps={this.props}
                      setState={_state => this.setState(_state)}
                    />
                  </Col>

                  <Col md="12">
                    <br />
                    <br />
                    <h1>
                      <Translate contentKey="generadorApp.quote.detail.title">quote</Translate>
                    </h1>
                  </Col>

                  <Col md="3">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" id="billingStatusLabel" for="quote-billingStatus">
                            <Translate contentKey="generadorApp.quote.billingStatus">billingStatus</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <Select
                            id="quote-billingStatus"
                            className={'css-select-control'}
                            value={{
                              value: this.state.billingStatusSelectValue ? this.state.billingStatusSelectValue : 'ABERTA',
                              label: translate(
                                'generadorApp.BillingStatus.' +
                                  (this.state.billingStatusSelectValue ? this.state.billingStatusSelectValue : 'ABERTA')
                              ),
                            }}
                            options={[
                              { value: 'ABERTA', label: translate('generadorApp.BillingStatus.ABERTA') },
                              { value: 'FECHADA', label: translate('generadorApp.BillingStatus.FECHADA') },
                              { value: 'FATURADA', label: translate('generadorApp.BillingStatus.FATURADA') },
                              { value: 'CANCELADA', label: translate('generadorApp.BillingStatus.CANCELADA') },
                            ]}
                            onChange={(options: any) => this.setState({ billingStatusSelectValue: options['value'] })}
                            name="billingStatus"
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>

                  <Col md="3">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className={'mt-2'} id="billingPasswordNumberLabel" for="quote-billingPasswordNumber">
                            <Translate contentKey="generadorApp.quote.billingPasswordNumber">billingPasswordNumber</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <input
                            onChange={(options: any) => this.setState({ billingPasswordNumberSelectValue: options.target.value })}
                            value={this.state.billingPasswordNumberSelectValue}
                            id="quote-billingPasswordNumber"
                            type="text"
                            name="billingPasswordNumber"
                            className={'form-control'}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>

                  <Col md="3">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className={'mt-2'} id="billingPasswordIssueLabel" for="quote-billingPasswordIssue">
                            <Translate contentKey="generadorApp.quote.billingPasswordIssue">BillingPasswordIssue</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <input
                            onChange={(options: any) => this.setState({ billingPasswordIssueSelectValue: options.target.value })}
                            value={this.state.billingPasswordIssueSelectValue}
                            id="quote-billingPasswordIssue"
                            type="text"
                            name="billingPasswordIssue"
                            className={'form-control'}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>

                  <Col md="3">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className={'mt-2'} id="billingPasswordExpiryLabel" for="quote-billingPasswordExpiry">
                            <Translate contentKey="generadorApp.quote.billingPasswordExpiry">billingPasswordExpiry</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <DatePicker
                            autoComplete="off"
                            onChange={value => this.setState({ billingPasswordExpirySelectValue: moment(value).format('YYYY-MM-DD') })}
                            id="contractSignatureDate"
                            validate={{}}
                            className="form-control react-datepicker"
                            name="contractSignatureDate"
                            dateFormat="dd/MM/yyyy"
                            selected={
                              this.state.billingPasswordExpirySelectValue &&
                              moment(this.state.billingPasswordExpirySelectValue, 'YYYY-MM-DD', true).isValid()
                                ? moment(this.state.billingPasswordExpirySelectValue, 'YYYY-MM-DD').toDate()
                                : new Date()
                            }
                            locale="pt"
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>

                  <Col md="2">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className={'mt-2'} id="billingPasswordIssuerLabel" for="quote-billingPasswordIssuer">
                            <Translate contentKey="generadorApp.quote.billingPasswordIssuer">billingPasswordIssuer</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <DatePicker
                            autoComplete="off"
                            onChange={value => this.setState({ billingPasswordIssuerSelectValue: moment(value).format('YYYY-MM-DD') })}
                            id="contractSignatureDate"
                            validate={{}}
                            className="form-control react-datepicker"
                            name="contractSignatureDate"
                            dateFormat="dd/MM/yyyy"
                            selected={
                              this.state.billingPasswordIssuerSelectValue &&
                              moment(this.state.billingPasswordIssuerSelectValue, 'YYYY-MM-DD', true).isValid()
                                ? moment(this.state.billingPasswordIssuerSelectValue, 'YYYY-MM-DD').toDate()
                                : new Date()
                            }
                            locale="pt"
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>

                  <Col md="2">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className={'mt-2'} id="autorizationDataLabel" for="quote-autorizationData">
                            <Translate contentKey="generadorApp.quote.autorizationData">autorizationData</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <DatePicker
                            autoComplete="off"
                            onChange={value => this.setState({ autorizationDataSelectValue: moment(value).format('YYYY-MM-DD') })}
                            id="contractSignatureDate"
                            validate={{}}
                            className="form-control react-datepicker"
                            name="contractSignatureDate"
                            dateFormat="dd/MM/yyyy"
                            selected={
                              this.state.autorizationDataSelectValue &&
                              moment(this.state.autorizationDataSelectValue, 'YYYY-MM-DD', true).isValid()
                                ? moment(this.state.autorizationDataSelectValue, 'YYYY-MM-DD').toDate()
                                : ''
                            }
                            locale="pt"
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>

                  <Col md="2">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className={'mt-2'} id="billingHospitalizationRecordLabel" for="quote-billingHospitalizationRecord">
                            <Translate contentKey="generadorApp.quote.billingHospitalizationRecord">billingHospitalizationRecord</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <input
                            onChange={(options: any) => this.setState({ billingHospitalizationRecordSelectValue: options.target.value })}
                            value={this.state.billingHospitalizationRecordSelectValue}
                            id="quote-billingHospitalizationRecord"
                            type="text"
                            name="billingHospitalizationRecord"
                            className={'form-control'}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>

                  <Col md="3">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className={'mt-2'} id="billingHospitalizationGuideLabel" for="quote-billingHospitalizationGuide">
                            <Translate contentKey="generadorApp.quote.billingHospitalizationGuide">billingHospitalizationGuide</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <input
                            onChange={(options: any) => this.setState({ billingHospitalizationGuideSelectValue: options.target.value })}
                            value={this.state.billingHospitalizationGuideSelectValue}
                            id="quote-billingHospitalizationGuide"
                            type="text"
                            name="billingHospitalizationGuide"
                            className={'form-control'}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>

                  <Col md="3">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className={'mt-2'} id="billingGuideSPSADTLabel" for="quote-billingGuideSPSADT">
                            <Translate contentKey="generadorApp.quote.billingGuideSPSADT">billingGuideSPSADT</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <input
                            onChange={(options: any) => this.setState({ billingGuideSPSADTSelectValue: options.target.value })}
                            value={this.state.billingGuideSPSADTSelectValue}
                            id="quote-billingGuideSPSADT"
                            type="billingGuideSPSADT"
                            name="type"
                            className={'form-control'}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>

                  <Col md="12">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className={'mt-2'} id="billingObservationLabel" for="quote-billingObservation">
                            <Translate contentKey="generadorApp.quote.billingObservation">billingObservation</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <textarea
                            onChange={(options: any) => this.setState({ billingObservationSelectValue: options.target.value })}
                            value={this.state.billingObservationSelectValue}
                            id="quote-billingObservation"
                            name="billingObservation"
                            className={'form-control'}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>

                  {quoteEntitySelectValue['to'] && quoteEntitySelectValue['from'] && patientSelectClient && patientSelectTabela ? (
                    <>
                      {checkAnyValueFields(this.state, 'typeSelectValue', 'PACOTE', true) ? (
                        <Col md="12">
                          <div className="entityEmbebedList">
                            <QuotePackage
                              entityEmbebedPai={fullEntityEmbebed}
                              history={this.props.history}
                              location={{
                                pathname: `/quote/${this.props.match.params['id']}/quote-package/`,
                                search: '',
                                hash: '',
                                state: undefined,
                                key: 'q2tbhd',
                              }}
                              match={{
                                path: `/quote/:idQuote/quote-package/:id`,
                                url: `/quote/${this.props.match.params['id']}/quote-package/`,
                                isExact: true,
                                params: {
                                  idQuote: this.props.match.params['id'],
                                  url: `/quote/${this.props.match.params['id']}/quote-package/`,
                                },
                              }}
                              {...this.props.mapStateToPropsQuotePackage}
                              {...mapDispatchToPropsQuotePackageList}
                              getEntities={this.quotePackageGetEntities}
                              updateEntity={this.quotePackageUpdateEntity}
                              getEntity={this.quotePackageGetEntity}
                              createEntity={this.quotePackageCreateEntity}
                              deleteEntity={this.quotePackageDeleteEntity}
                              reset={this.quotePackageReset}
                              quotePackageList={this.props.quotePackageListEmbed}
                              totalItems={this.props.quotePackageListEmbed ? this.props.quotePackageListEmbed.length : 0}
                              loading={this.props.loading}
                              quotePackageEntity={this.props.quotePackageSelected}
                            />
                          </div>
                        </Col>
                      ) : (
                        <> </>
                      )}
                      <Col md="12">
                        <div className="entityEmbebedList">
                          <QuoteRh
                            entityEmbebedPai={fullEntityEmbebed}
                            typeView={'Bill'}
                            clComTableRulers={clComTableRulers}
                            clComTable={patientSelectTabela}
                            quote={quoteEntitySelectValue}
                            history={this.props.history}
                            location={{
                              pathname: `/quote/${this.props.match.params['id']}/quote-rh/`,
                              search: '',
                              hash: '',
                              state: undefined,
                              key: 'q2tbhd',
                            }}
                            match={{
                              path: `/quote/:idQuote/quote-rh/:id`,
                              url: `/quote/${this.props.match.params['id']}/quote-rh/`,
                              isExact: true,
                              params: {
                                idQuote: this.props.match.params['id'],
                                url: `/quote/${this.props.match.params['id']}/quote-rh/`,
                              },
                            }}
                            {...this.props.mapStateToPropsQuoteRh}
                            {...mapDispatchToPropsQuoteRhList}
                            getEntities={this.quoteRhGetEntities}
                            updateEntity={this.quoteRhUpdateEntity}
                            getEntity={this.quoteRhGetEntity}
                            createEntity={this.quoteRhCreateEntity}
                            deleteEntity={this.quoteRhDeleteEntity}
                            reset={this.quoteRhReset}
                            quoteRhList={this.props.quoteRhListEmbed}
                            totalItems={this.props.quoteRhListEmbed ? this.props.quoteRhListEmbed.length : 0}
                            loading={this.props.loading}
                            quoteRhEntity={this.props.quoteRhSelected}
                          />
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="entityEmbebedList">
                          <QuoteMedicine
                            entityEmbebedPai={fullEntityEmbebed}
                            typeView={'Bill'}
                            clComTableRulers={clComTableRulers}
                            clComTable={patientSelectTabela}
                            quote={quoteEntitySelectValue}
                            history={this.props.history}
                            location={{
                              pathname: `/quote/${this.props.match.params['id']}/quote-medicine/`,
                              search: '',
                              hash: '',
                              state: undefined,
                              key: 'q2tbhd',
                            }}
                            match={{
                              path: `/quote/:idQuote/quote-medicine/:id`,
                              url: `/quote/${this.props.match.params['id']}/quote-medicine/`,
                              isExact: true,
                              params: {
                                idQuote: this.props.match.params['id'],
                                url: `/quote/${this.props.match.params['id']}/quote-medicine/`,
                              },
                            }}
                            {...this.props.mapStateToPropsQuoteMedicine}
                            {...mapDispatchToPropsQuoteMedicineList}
                            getEntities={this.quoteMedicineGetEntities}
                            updateEntity={this.quoteMedicineUpdateEntity}
                            getEntity={this.quoteMedicineGetEntity}
                            createEntity={this.quoteMedicineCreateEntity}
                            deleteEntity={this.quoteMedicineDeleteEntity}
                            reset={this.quoteMedicineReset}
                            quoteMedicineList={this.props.quoteMedicineListEmbed}
                            totalItems={this.props.quoteMedicineListEmbed ? this.props.quoteMedicineListEmbed.length : 0}
                            loading={this.props.loading}
                            quoteMedicineEntity={this.props.quoteMedicineSelected}
                          />
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="entityEmbebedList">
                          <QuoteMaterial
                            entityEmbebedPai={fullEntityEmbebed}
                            typeView={'Bill'}
                            clComTableRulers={clComTableRulers}
                            clComTable={patientSelectTabela}
                            quote={quoteEntitySelectValue}
                            history={this.props.history}
                            location={{
                              pathname: `/quote/${this.props.match.params['id']}/quote-material/`,
                              search: '',
                              hash: '',
                              state: undefined,
                              key: 'q2tbhd',
                            }}
                            match={{
                              path: `/quote/:idQuote/quote-material/:id`,
                              url: `/quote/${this.props.match.params['id']}/quote-material/`,
                              isExact: true,
                              params: {
                                idQuote: this.props.match.params['id'],
                                url: `/quote/${this.props.match.params['id']}/quote-material/`,
                              },
                            }}
                            {...this.props.mapStateToPropsQuoteMaterial}
                            {...mapDispatchToPropsQuoteMaterialList}
                            getEntities={this.quoteMaterialGetEntities}
                            updateEntity={this.quoteMaterialUpdateEntity}
                            getEntity={this.quoteMaterialGetEntity}
                            createEntity={this.quoteMaterialCreateEntity}
                            deleteEntity={this.quoteMaterialDeleteEntity}
                            reset={this.quoteMaterialReset}
                            quoteMaterialList={this.props.quoteMaterialListEmbed}
                            totalItems={this.props.quoteMaterialListEmbed ? this.props.quoteMaterialListEmbed.length : 0}
                            loading={this.props.loading}
                            quoteMaterialEntity={this.props.quoteMaterialSelected}
                          />
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="entityEmbebedList">
                          <QuoteDiet
                            entityEmbebedPai={fullEntityEmbebed}
                            typeView={'Bill'}
                            clComTableRulers={clComTableRulers}
                            clComTable={patientSelectTabela}
                            quote={quoteEntitySelectValue}
                            history={this.props.history}
                            location={{
                              pathname: `/quote/${this.props.match.params['id']}/quote-diet/`,
                              search: '',
                              hash: '',
                              state: undefined,
                              key: 'q2tbhd',
                            }}
                            match={{
                              path: `/quote/:idQuote/quote-diet/:id`,
                              url: `/quote/${this.props.match.params['id']}/quote-diet/`,
                              isExact: true,
                              params: {
                                idQuote: this.props.match.params['id'],
                                url: `/quote/${this.props.match.params['id']}/quote-diet/`,
                              },
                            }}
                            {...this.props.mapStateToPropsQuoteDiet}
                            {...mapDispatchToPropsQuoteDietList}
                            getEntities={this.quoteDietGetEntities}
                            updateEntity={this.quoteDietUpdateEntity}
                            getEntity={this.quoteDietGetEntity}
                            createEntity={this.quoteDietCreateEntity}
                            deleteEntity={this.quoteDietDeleteEntity}
                            reset={this.quoteDietReset}
                            quoteDietList={this.props.quoteDietListEmbed}
                            totalItems={this.props.quoteDietListEmbed ? this.props.quoteDietListEmbed.length : 0}
                            loading={this.props.loading}
                            quoteDietEntity={this.props.quoteDietSelected}
                          />
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="entityEmbebedList">
                          <QuoteProductService
                            entityEmbebedPai={fullEntityEmbebed}
                            typeView={'Bill'}
                            clComTableRulers={clComTableRulers}
                            clComTable={patientSelectTabela}
                            quote={quoteEntitySelectValue}
                            history={this.props.history}
                            location={{
                              pathname: `/quote/${this.props.match.params['id']}/quote-product-service/`,
                              search: '',
                              hash: '',
                              state: undefined,
                              key: 'q2tbhd',
                            }}
                            match={{
                              path: `/quote/:idQuote/quote-product-service/:id`,
                              url: `/quote/${this.props.match.params['id']}/quote-product-service/`,
                              isExact: true,
                              params: {
                                idQuote: this.props.match.params['id'],
                                url: `/quote/${this.props.match.params['id']}/quote-product-service/`,
                              },
                            }}
                            {...this.props.mapStateToPropsQuoteProductService}
                            {...mapDispatchToPropsQuoteProductServiceList}
                            getEntities={this.quoteProductServiceGetEntities}
                            updateEntity={this.quoteProductServiceUpdateEntity}
                            getEntity={this.quoteProductServiceGetEntity}
                            createEntity={this.quoteProductServiceCreateEntity}
                            deleteEntity={this.quoteProductServiceDeleteEntity}
                            reset={this.quoteProductServiceReset}
                            quoteProductServiceList={this.props.quoteProductServiceListEmbed}
                            totalItems={this.props.quoteProductServiceListEmbed ? this.props.quoteProductServiceListEmbed.length : 0}
                            loading={this.props.loading}
                            quoteProductServiceEntity={this.props.quoteProductServiceSelected}
                          />
                        </div>
                      </Col>
                    </>
                  ) : (
                    <Col md="12">
                      {patientSelectClient ? (
                        <div className="alert alert-warning">
                          <Translate contentKey="generadorApp.bill.patientSelectClientNotTable">patientSelectClientNotTable</Translate>
                        </div>
                      ) : quoteEntitySelectValue['to'] && quoteEntitySelectValue['from'] ? (
                        <div className="alert alert-warning">
                          {' '}
                          <Translate contentKey="generadorApp.bill.patientSelectNotClient">patientSelectNotClient</Translate>{' '}
                        </div>
                      ) : (
                        <div className="alert alert-warning">
                          {' '}
                          <Translate contentKey="generadorApp.bill.dataNotselected">dataNotselected</Translate>{' '}
                        </div>
                      )}
                    </Col>
                  )}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const {
      quoteEntity,
      quoteDiets,
      quoteMedicines,
      quoteMaterials,
      quoteProductServices,
      quoteRhs,
      quoteFiles,
      expectedResults,
      patients,
      loading,
      updating,
    } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        {this.renderContaConsumoModal()}
        {this.renderGuiaInternacaoModal()}
        {this.renderGuiaServicoModal()}
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...quoteEntity,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel>
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>
              {this.renderBody()}
              <Row className="justify-content-center">
                <Col md="11">
                  <br />
                  <h2 className="pull-right">
                    <Translate contentKey="generadorApp.quoteRh.finalAmount">finalAmount</Translate>: R${' '}
                    {(this.state.billFinalAmountSelectValue * 1).toFixed(2).replace('.', ',')}
                  </h2>
                </Col>
              </Row>
            </PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }

  renderContaConsumoModal() {
    // const report: any = this.state.report;
    // if (!report || this.state.showModalReport !== true) return <> </>;
    if (this.state.showModalContaConsumoReport !== true) return <> </>;
    return (
      <Modal size="xl" isOpen toggle={() => this.setState({ showModalContaConsumoReport: false })}>
        <ModalBody id="generadorApp.quote.pdf.generate">
          <ContaConsumoPdfReport data={this.props.quoteEntity} responsavel={this.props.userLogged.whiteLabel.technicalManager} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => this.setState({ showModalContaConsumoReport: false })}>
            <FontAwesomeIcon icon="ban" />
            &nbsp;
            <Translate contentKey="generadorApp.quote.btnCancel">Close</Translate>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  renderGuiaInternacaoModal() {
    // const report: any = this.state.report;
    // if (!report || this.state.showModalReport !== true) return <> </>;
    if (this.state.showModalGuiaIntegracaoReport !== true) return <> </>;
    return (
      <Modal size="xl" isOpen toggle={() => this.setState({ showModalGuiaIntegracaoReport: false })}>
        <ModalBody id="generadorApp.quote.pdf.generate">
          <GuiaInternacaoPdfReport data={this.props.quoteEntity} userLogged={this.props.userLogged} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => this.setState({ showModalGuiaIntegracaoReport: false })}>
            <FontAwesomeIcon icon="ban" />
            &nbsp;
            <Translate contentKey="generadorApp.quote.btnCancel">Close</Translate>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  renderGuiaServicoModal() {
    // const report: any = this.state.report;
    // if (!report || this.state.showModalReport !== true) return <> </>;
    if (this.state.showModalGuiaServicoReport !== true) return <> </>;
    return (
      <Modal size="xl" isOpen toggle={() => this.setState({ showModalGuiaServicoReport: false })}>
        <ModalBody id="generadorApp.quote.pdf.generate">
          <GuiaServicoPdfReport data={this.props.quoteEntity} userLogged={this.props.userLogged} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => this.setState({ showModalGuiaServicoReport: false })}>
            <FontAwesomeIcon icon="ban" />
            &nbsp;
            <Translate contentKey="generadorApp.quote.btnCancel">Close</Translate>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
    userLogged: storeState.authentication.account,
    listProcediment: storeState.patDrPrescrProcd.entities,
    listRulersList: storeState.clComRuler.entities,
    listItemsList: storeState.clComItem.entities,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
  createPad,
  getPad,
  updateStateManual,
  createPharmacyStock,
  getPharmacyStock,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;
export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(BillUpdate);
