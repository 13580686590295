import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ProfessionalDevice from './professional-device';

import ProfessionalDeviceDetail from './professional-device-detail';

import ProfessionalDeviceUpdate from './professional-device';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={ProfessionalDeviceUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={ProfessionalDeviceUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={ProfessionalDeviceDetail} />
      <ErrorBoundaryRoute path={match.path} component={ProfessionalDevice} />
    </Switch>
  </>
);

export default Routes;
