import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import SubTag from './sub-tag';

import SubTagDetail from './sub-tag-detail';

import SubTagUpdate from './sub-tag';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={SubTagUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={SubTagUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={SubTagDetail} />
      <ErrorBoundaryRoute path={match.path} component={SubTag} />
    </Switch>
  </>
);

export default Routes;
