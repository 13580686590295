/* eslint complexity: ["error", 500] */
import axios from 'axios';

import { IPaginationBaseState, IPayloadResult } from 'react-jhipster';

import { IPayload } from 'react-jhipster/src/type/redux-action.type';

import { BASE_API_VERSION_PATH } from 'app/config/constants';

import { cleanEntity, jsonParse } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ISupplierTable, defaultValue } from 'app/shared/model/supplier-table.model';

import {
  ISupplierTableProductService,
  defaultValue as defaultValueSupplierTableProductService,
} from 'app/shared/model/supplier-table-product-service.model';

export declare type ICrudPutAction<T> = (data?: T, listFiltersPage?) => IPayload<T> | IPayloadResult<T>;
export declare type ICrudGetAction<T> = (id: string | number, selectFields?: string) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const ACTION_TYPES = {
  FETCH_SUPPLIERTABLE_LIST_EXPORT: 'supplierTable/FETCH_SUPPLIERTABLE_LIST_EXPORT',
  FETCH_SUPPLIERTABLE_LIST: 'supplierTable/FETCH_SUPPLIERTABLE_LIST',
  FETCH_SUPPLIERTABLE: 'supplierTable/FETCH_SUPPLIERTABLE',
  FETCH_SUPPLIERTABLE_OFFSET: 'supplierTable/FETCH_SUPPLIERTABLE_OFFSET',
  CREATE_SUPPLIERTABLE: 'supplierTable/CREATE_SUPPLIERTABLE',
  UPDATE_SUPPLIERTABLE: 'supplierTable/UPDATE_SUPPLIERTABLE',
  CREATE_SUPPLIERTABLE_AND_GO_LIST: 'supplierTable/CREATE_SUPPLIERTABLE_AND_GO_LIST',
  UPDATE_SUPPLIERTABLE_AND_GO_LIST: 'supplierTable/UPDATE_SUPPLIERTABLE_AND_GO_LIST',
  DELETE_SUPPLIERTABLE: 'supplierTable/DELETE_SUPPLIERTABLE',
  SET_BLOB: 'supplierTable/SET_BLOB',
  RESET: 'supplierTable/RESET',
  SHOW_MODAL: 'supplierTable/SHOW_MODAL',
};

const initialState = {
  loading: false,
  openModal: {
    list: false,
    view: false,
    detail: false,
  },
  errorMessage: null,
  entities: [] as ReadonlyArray<ISupplierTable>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,

  supplierTableProductServiceListEmbed: [] as ReadonlyArray<ISupplierTableProductService> | any,
  supplierTableProductServiceListAllEmbed: [] as ReadonlyArray<ISupplierTableProductService> | any,
  supplierTableProductServiceSelected: defaultValueSupplierTableProductService,
};

const actionPendind = [];
let actualState = initialState;

export const convertEntityBuffers = entity => ({
  ...entity,
  observation: entity.observation ? Buffer.from(entity.observation).toString() : entity.observation,
});

export type SupplierTableState = Readonly<typeof initialState>;

export interface ISupplierTableBaseState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  listCheckedID?: Array<number>;
  isCheckedAll?: boolean;
  urlBack?: string;
  extraFilters?: any;
  baseFilters: any;
  offset: number;
  name?: any;
  status?: any;
  observation?: any;
  supplierId?: any;
  supplierStartFilter?: any;
}

export interface IFieldsBase extends ISupplierTableBaseState, IPaginationBaseState {}
export interface ISupplierTableUpdateState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  urlBack?: string;
  functionEmbebed?: any[];
  fieldsBase: IFieldsBase;
  nameSelectValue?: any;
  nameStartSelectOptions?: any;
  statusSelectValue?: any;
  statusStartSelectOptions?: any;
  observationSelectValue?: any;
  observationStartSelectOptions?: any;

  supplierTableDietSelectValue?: any;
  supplierTableDietStartSelectOptions?: any;

  supplierTableMaterialSelectValue?: any;
  supplierTableMaterialStartSelectOptions?: any;

  supplierTableMedicineSelectValue?: any;
  supplierTableMedicineStartSelectOptions?: any;

  supplierTableProductServiceSelectValue?: any;
  supplierTableProductServiceStartSelectOptions?: any;

  supplierTableRhSelectValue?: any;
  supplierTableRhStartSelectOptions?: any;

  supplierSelectValue?: any;
  supplierStartSelectOptions?: any;

  isNew: boolean;
  supplierFantasyName?: any;

  supplierId?: any;
}

// Reducer

export default (state: SupplierTableState = initialState, action): SupplierTableState => {
  actualState = state;
  if (
    action.type.includes('_PENDING') &&
    !actionPendind.includes(action.type.replace('_PENDING', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_PENDING', ''))
  ) {
    actionPendind.push(action.type.replace('_PENDING', ''));
  }

  if (
    action.type.includes('_FULFILLED') &&
    actionPendind.includes(action.type.replace('_FULFILLED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_FULFILLED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_FULFILLED', '')), 1);
  }

  if (
    action.type.includes('_REJECTED') &&
    actionPendind.includes(action.type.replace('_REJECTED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_REJECTED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_REJECTED', '')), 1);
  }

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_SUPPLIERTABLE_LIST_EXPORT):
    case REQUEST(ACTION_TYPES.FETCH_SUPPLIERTABLE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_SUPPLIERTABLE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_SUPPLIERTABLE):
    case REQUEST(ACTION_TYPES.UPDATE_SUPPLIERTABLE):
    case REQUEST(ACTION_TYPES.CREATE_SUPPLIERTABLE_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.UPDATE_SUPPLIERTABLE_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.DELETE_SUPPLIERTABLE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_SUPPLIERTABLE_LIST_EXPORT):
    case FAILURE(ACTION_TYPES.FETCH_SUPPLIERTABLE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_SUPPLIERTABLE):
    case FAILURE(ACTION_TYPES.CREATE_SUPPLIERTABLE):
    case FAILURE(ACTION_TYPES.UPDATE_SUPPLIERTABLE):
    case FAILURE(ACTION_TYPES.CREATE_SUPPLIERTABLE_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.UPDATE_SUPPLIERTABLE_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.DELETE_SUPPLIERTABLE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_SUPPLIERTABLE_LIST):
      action.payload.data = action.payload.data.map(v => {
        return { ...v, observation: v.observation ? Buffer.from(v.observation).toString() : v.observation };
      });

      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_SUPPLIERTABLE_OFFSET):
      if (action.payload.data && action.payload.data.length > 0) {
        return {
          ...state,
          loading: false,
          entity: convertEntityBuffers(action.payload.data[0]),
        };
      }
      return {
        ...state,
        loading: false,
      };

    case SUCCESS(ACTION_TYPES.FETCH_SUPPLIERTABLE):
      return {
        ...state,
        loading: false,
        entity: convertEntityBuffers(action.payload.data),
      };
    case SUCCESS(ACTION_TYPES.CREATE_SUPPLIERTABLE):
    case SUCCESS(ACTION_TYPES.UPDATE_SUPPLIERTABLE):
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_SUPPLIERTABLE_AND_GO_LIST):
    case SUCCESS(ACTION_TYPES.UPDATE_SUPPLIERTABLE_AND_GO_LIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_SUPPLIERTABLE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.SET_BLOB: {
      const { name, data, contentType, fileName } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [name + 'Base64']: data,
          [name + 'ContentType']: contentType,
          [name + 'FileName']: fileName,
        },
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
        supplierTableProductServiceListEmbed: [],
        supplierTableProductServiceListAllEmbed: [],
        supplierTableProductServiceSelected: state.supplierTableProductServiceSelected,
      };

    case 'supplierTable/SUPPLIERTABLEPRODUCTSERVICE_GET_ENTITIES':
      return {
        ...state,
        supplierTableProductServiceListEmbed: state.supplierTableProductServiceListAllEmbed,
      };
    case 'supplierTable/SUPPLIERTABLEPRODUCTSERVICE_CREATE_ENTITY':
      return {
        ...state,
        supplierTableProductServiceListAllEmbed: [
          ...(state.supplierTableProductServiceListAllEmbed ? state.supplierTableProductServiceListAllEmbed : []),
          action.payload,
        ],
      };
    case 'supplierTable/SUPPLIERTABLEPRODUCTSERVICE_SET_ENTITIES':
      return {
        ...state,
        supplierTableProductServiceListAllEmbed: action.payload,
      };
    case 'supplierTable/SUPPLIERTABLEPRODUCTSERVICE_UPDATE_ENTITY': {
      const supplierTableProductServiceListAllEmbed = [...state.supplierTableProductServiceListAllEmbed];
      state.supplierTableProductServiceListAllEmbed.map((v, i: any) => {
        if (v.id === action.payload.id) {
          supplierTableProductServiceListAllEmbed[i] = action.payload;
        }
      });
      return {
        ...state,
        supplierTableProductServiceListAllEmbed,
        supplierTableProductServiceSelected: {},
      };
    }
    case 'supplierTable/SUPPLIERTABLEPRODUCTSERVICE_RESET':
      return {
        ...state,
        supplierTableProductServiceSelected: {},
        supplierTableProductServiceListEmbed: [],
      };
    case 'supplierTable/SUPPLIERTABLEPRODUCTSERVICE_GET_ENTITY':
      if (!!state.supplierTableProductServiceListAllEmbed && state.supplierTableProductServiceListAllEmbed.constructor === Array) {
        return {
          ...state,
          supplierTableProductServiceSelected: state.supplierTableProductServiceListAllEmbed.filter(v => v.id === action.payload).pop(),
        };
      }
      return {
        ...state,
        supplierTableProductServiceSelected: state.supplierTableProductServiceListAllEmbed,
      };
    case 'supplierTable/SUPPLIERTABLEPRODUCTSERVICE_DELETE_ENTITY':
      return {
        ...state,
        supplierTableProductServiceListAllEmbed: state.supplierTableProductServiceListAllEmbed.filter(v => v.id !== action.payload),
      };

    case ACTION_TYPES.SHOW_MODAL: {
      const openModal = state.openModal;
      openModal[action.payload.type] = action.payload.show;
      return {
        ...state,
        openModal,
      };
    }
    default:
      return state;
  }
};

const apiUrl = BASE_API_VERSION_PATH + 'supplier-tables';

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

// Actions

// Actions
export type ICrudGetAllActionSupplierTable<T> = (
  name?: any,
  status?: any,
  observation?: any,
  supplierId?: any,
  extraFilters?: {},
  page?: number,
  size?: number,
  sort?: string,
  selectFields?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getEntitiesFilter = (filters?, page?, size?, sort?, selectFields = '') => {
  const requestUrl = `${apiUrl}?page=${page >= 0 ? page : 0}&size=${size >= 0 ? size : 20}&sort=${sort ? sort : 'id,asc'}&`;
  const requestFilters = filters.map(v => v.column + '.' + v.operation + '=' + v.value).join('&');
  return {
    type: ACTION_TYPES.FETCH_SUPPLIERTABLE_LIST,
    payload: axios.get<ISupplierTable>(`${requestUrl}${requestFilters}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getAllEntities = (filters?, sort?, selectFields = '') => {
  if (
    (actualState.totalItems > 0 && actualState.entities.length === actualState.totalItems) ||
    actionPendind.includes(ACTION_TYPES.FETCH_SUPPLIERTABLE_LIST)
  ) {
    return {
      type: ACTION_TYPES.FETCH_SUPPLIERTABLE_LIST + '_FROM_CACHE',
      payload: new Promise(function (resolve, reject) {
        resolve('done');
      }),
    };
  }

  const idsRequest = filters && typeof filters['ids'] !== 'undefined' && filters['ids'] ? `id.in=${filters['ids'].join(',')}&` : '';
  const nameRequest = filters && typeof filters['name'] !== 'undefined' && filters['name'] ? `name.contains=${filters['name']}&` : '';
  const statusRequest =
    filters && typeof filters['status'] !== 'undefined' && filters['status'] ? `status.contains=${filters['status']}&` : '';
  const observationRequest =
    filters && typeof filters['observation'] !== 'undefined' && filters['observation']
      ? `observation.contains=${filters['observation']}&`
      : '';
  const supplierRequest =
    filters && typeof filters['supplierId'] !== 'undefined' && filters['supplierId'] ? `supplier.id.in=${filters['supplierId']}&` : '';

  const requestUrl = `${apiUrl}${`?page=${0}&size=${1000000}&sort=${sort ? sort : 'id,asc'}&`}`;
  return {
    type: ACTION_TYPES.FETCH_SUPPLIERTABLE_LIST,
    payload: axios.get<ISupplierTable>(`${requestUrl}${idsRequest}${nameRequest}${statusRequest}${observationRequest}${supplierRequest}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntities: ICrudGetAllActionSupplierTable<ISupplierTable> = (
  name,
  status,
  observation,
  supplierId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const nameRequest = name ? `name.contains=${name}&` : '';
  const statusRequest = status ? (Array.isArray(status) ? `status.in=${status.join(',')}&` : `status.equals=${status}&`) : '';
  const observationRequest = observation ? `observation.contains=${observation}&` : '';
  const supplierRequest = supplierId ? `supplier.id.in=${supplierId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_SUPPLIERTABLE_LIST,
    payload: axios.get<ISupplierTable>(
      `${requestUrl}${extraFiltersRequest}${nameRequest}${statusRequest}${observationRequest}${supplierRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntityOffset: any = (name, status, observation, supplierId, extraFilters, offset, sort, selectFields = '') => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const nameRequest = name ? `name.contains=${name}&` : '';
  const statusRequest = status ? (Array.isArray(status) ? `status.in=${status.join(',')}&` : `status.equals=${status}&`) : '';
  const observationRequest = observation ? `observation.contains=${observation}&` : '';
  const supplierRequest = supplierId ? `supplier.id.in=${supplierId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${offset}&size=${1}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_SUPPLIERTABLE_OFFSET,
    payload: axios.get<ISupplierTable>(
      `${requestUrl}${extraFiltersRequest}${nameRequest}${statusRequest}${observationRequest}${supplierRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntity: ICrudGetAction<ISupplierTable> = (id, selectFields = '') => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_SUPPLIERTABLE,
    payload: axios.get<ISupplierTable>(requestUrl, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntitiesExport: ICrudGetAllActionSupplierTable<ISupplierTable> = (
  name,
  status,
  observation,
  supplierId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const nameRequest = name ? (Array.isArray(status) ? `name.in=${name.join(',')}&` : `name.contains=${name}&`) : '';
  const statusRequest = status ? (Array.isArray(status) ? `status.in=${status.join(',')}&` : `status.equals=${status}&`) : '';
  const observationRequest = observation
    ? Array.isArray(status)
      ? `observation.in=${observation.join(',')}&`
      : `observation.contains=${observation}&`
    : '';
  const supplierRequest = supplierId ? `supplier.id.in=${supplierId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_SUPPLIERTABLE_LIST,
    payload: axios.get<ISupplierTable>(
      `${requestUrl}${extraFiltersRequest}${nameRequest}${statusRequest}${observationRequest}${supplierRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const createEntity: ICrudPutAction<ISupplierTable> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.CREATE_SUPPLIERTABLE_AND_GO_LIST
        : ACTION_TYPES.CREATE_SUPPLIERTABLE,
    payload: axios.post(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const updateEntity: ICrudPutAction<ISupplierTable> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.UPDATE_SUPPLIERTABLE_AND_GO_LIST
        : ACTION_TYPES.UPDATE_SUPPLIERTABLE,
    payload: axios.put(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const deleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_SUPPLIERTABLE,
    payload: axios.delete(requestUrl),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const setBlob = (name, data, contentType?, fileName?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType,
    fileName,
  },
});

export const showModal = view => ({
  payload: { type: view, show: true },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const hideModal = view => ({
  payload: { type: view, show: false },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const getUrlBack = (location): string => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  let urlBack = _urlBase.searchParams.get('urlBack') || '';
  urlBack = urlBack.trim();
  urlBack = urlBack.startsWith('/') ? urlBack.slice(1) : urlBack;
  urlBack = urlBack.endsWith('/') ? urlBack.slice(0, -1) : urlBack;
  return urlBack ? '/' + urlBack + '/' : '';
};

export const getSupplierTableState = (location): ISupplierTableBaseState => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  const extraFilters = jsonParse(_urlBase.searchParams.get('extraFilters') || '{}');
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;

  const name = _urlBase.searchParams.get('name') || '';
  const status = _urlBase.searchParams.get('status') || '';
  const observation = _urlBase.searchParams.get('observation') || '';
  const supplierId = _urlBase.searchParams.get('supplier') || '';
  return {
    baseFilters,
    extraFilters,
    offset,
    name,
    status,
    observation,
    supplierId: supplierId ? supplierId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
  };
};

export const getEntityFiltersURL = (state, offset = null) => {
  return (
    'baseFilters=' +
    state.baseFilters +
    '&extraFilters=' +
    encodeURI(JSON.stringify(state.extraFilters)) +
    '&page=' +
    state.activePage +
    '&' +
    'size=' +
    state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    state.sort +
    ',' +
    state.order +
    '&' +
    (state.name ? 'name=' + state.name + '&' : '') +
    (state.status ? 'status=' + state.status + '&' : '') +
    (state.observation ? 'observation=' + state.observation + '&' : '') +
    (state.supplierId ? 'supplier=' + state.supplierId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    ''
  );
};

export const supplierTableProductServiceReset: any = values => ({
  payload: values,
  type: 'supplierTable/SUPPLIERTABLEPRODUCTSERVICE_RESET',
});

export const supplierTableProductServiceGetEntities: any = values => ({
  payload: values,
  type: 'supplierTable/SUPPLIERTABLEPRODUCTSERVICE_GET_ENTITIES',
});

export const supplierTableProductServiceSetEntities: any = values => async dispatch => {
  const result = await dispatch({
    payload: values,
    type: 'supplierTable/SUPPLIERTABLEPRODUCTSERVICE_SET_ENTITIES',
  });
  dispatch(supplierTableProductServiceGetEntities());
  return result;
};

export const supplierTableProductServiceCreateEntity: ICrudPutAction<ISupplierTableProductService> = (
  entity,
  listFiltersPage
) => async dispatch => {
  const result = await dispatch({
    payload: { id: (new Date().getTime() * Math.random()).toString(16).split('.').join('-'), ...entity },
    type: 'supplierTable/SUPPLIERTABLEPRODUCTSERVICE_CREATE_ENTITY',
  });

  if (entity.supplierTable === 'is_new') {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(supplierTableProductServiceReset());
      dispatch(supplierTableProductServiceGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};

export const supplierTableProductServiceUpdateEntity: ICrudPutAction<ISupplierTableProductService> = (
  entity,
  listFiltersPage
) => async dispatch => {
  const result = await dispatch({
    payload: entity,
    type: 'supplierTable/SUPPLIERTABLEPRODUCTSERVICE_UPDATE_ENTITY',
  });
  if (entity.supplierTable === 'is_new') {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(supplierTableProductServiceReset());
      dispatch(supplierTableProductServiceGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};
export const supplierTableProductServiceGetEntity: any = values => ({
  payload: values,
  type: 'supplierTable/SUPPLIERTABLEPRODUCTSERVICE_GET_ENTITY',
});

export const supplierTableProductServiceDeleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: id,
    type: 'supplierTable/SUPPLIERTABLEPRODUCTSERVICE_DELETE_ENTITY',
  });
  if (isNaN(id)) {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(supplierTableProductServiceReset());
      dispatch(supplierTableProductServiceGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};
