/* eslint complexity: ["error", 500] */
import React from 'react';
import { connect } from 'react-redux';

import { ClComTable, mapStateToProps, mapDispatchToProps } from '../cl-com-table';
import { IRootState } from 'app/shared/reducers';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);

import Select from 'react-select';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Label, Button, Modal, ModalBody, ModalHeader, ModalFooter, Row, Col } from 'reactstrap';

import { getEntityFiltersURL, getEntity, createEntity } from '../cl-com-table.reducer';

export interface IExtendedState {
  showModalForm?: any;
  nameSelectValue?: any;
  statusSelectValue?: any;
  typeSelectValue?: any;
}
export interface IExtendedProps {
  entityEmbebedPai?: any;
}

export class ClComTableExtended extends ClComTable {
  renderHeader() {
    return <> {super.renderHeader()} </>;
  }

  renderFilter() {
    return <> {super.renderFilter()} </>;
  }

  renderTable() {
    return <> {super.renderTable()} </>;
  }

  renderFooter() {
    return <> {super.renderFooter()} </>;
  }

  render() {
    return (
      <>
        {' '}
        {this.renderModalNewQuote()} {super.render()}{' '}
      </>
    );
  }

  renderModalNewQuote() {
    if (this.state.showModalForm !== true) return <> </>;

    return (
      <Modal size="md" isOpen toggle={() => this.setState({ showModalForm: false })}>
        <ModalHeader toggle={() => this.setState({ showModalForm: false })}>
          <Translate contentKey="generadorApp.quote.createTableTitle">createQuoteTitle</Translate>
        </ModalHeader>
        <ModalBody id="generadorApp.quote.pdf.generate">
          <Row>
            <Col md="12">
              <Row>
                <Col md="12">
                  <Label className="mt-2 label-single-line" id="nameLabel" for="cl-com-table-name">
                    <Translate contentKey="generadorApp.clComTable.name">Name</Translate>
                  </Label>
                </Col>
                <Col md="12">
                  <input
                    onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                    value={this.state.nameSelectValue ? this.state.nameSelectValue : ''}
                    id="cl-com-table-name"
                    type="text"
                    name="name"
                    className={'form-control'}
                  />
                </Col>
              </Row>
            </Col>
            <Col md="12">
              <Row>
                <Col md="12">
                  <Label className="mt-2 label-single-line" id="statusLabel" for="cl-com-table-status">
                    <Translate contentKey="generadorApp.clComTable.status">Status</Translate>
                  </Label>
                </Col>
                <Col md="12">
                  <Select
                    id="cl-com-table-status"
                    className={'css-select-control'}
                    value={
                      this.state.statusSelectValue
                        ? {
                            value: this.state.statusSelectValue,
                            label: translate('generadorApp.Status.' + this.state.statusSelectValue),
                          }
                        : { value: '', label: translate('generadorApp.clComTable.status') }
                    }
                    options={[
                      { value: 'INATIVADO', label: translate('generadorApp.Status.INATIVADO') },
                      { value: 'ATIVO', label: translate('generadorApp.Status.ATIVO') },
                    ]}
                    onChange={(options: any) => this.setState({ statusSelectValue: options['value'] })}
                    name="status"
                  />
                </Col>
              </Row>
            </Col>
            <Col md="12">
              <Row>
                <Col md="12">
                  <Label className="mt-2 label-single-line" id="typeLabel" for="cl-com-table-type">
                    <Translate contentKey="generadorApp.clComTable.type">Type</Translate>
                  </Label>
                </Col>
                <Col md="12">
                  <Select
                    id="cl-com-table-type"
                    className={'css-select-control'}
                    value={
                      this.state.typeSelectValue
                        ? {
                            value: this.state.typeSelectValue,
                            label: translate('generadorApp.TypeClientCommercialTable.' + this.state.typeSelectValue),
                          }
                        : { value: '', label: translate('generadorApp.clComTable.type') }
                    }
                    options={[
                      { value: 'TABELAABERTA', label: translate('generadorApp.TypeClientCommercialTable.TABELAABERTA') },
                      { value: 'PACOTE', label: translate('generadorApp.TypeClientCommercialTable.PACOTE') },
                    ]}
                    onChange={(options: any) => this.setState({ typeSelectValue: options['value'] })}
                    name="type"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              new Promise(resolve => {
                resolve(
                  this.props.createEntity(
                    {
                      client: { id: this.props.match.params['idClient'] },
                      name: this.state.nameSelectValue,
                      status: this.state.statusSelectValue,
                      type: this.state.typeSelectValue ? this.state.typeSelectValue : 'TABELAABERTA',
                    },
                    { reloadList: false }
                  )
                );
              }).then(resultEntity => {
                const idEntity =
                  resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
                    ? resultEntity['value']['data']['id']
                    : -1;
                new Promise(resolve => {
                  resolve(this.props.getEntity(idEntity));
                }).then(v => {
                  this.props.history.push(
                    '/client/' +
                      this.props.match.params['idClient'] +
                      '/cl-com-table/' +
                      idEntity +
                      '/edit?' +
                      getEntityFiltersURL(this.state)
                  );
                });
              });
            }}
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="generadorApp.clComTable.listTopButtons.new">new</Translate>
          </Button>
          <Button color="secondary" onClick={() => this.setState({ showModalForm: false })}>
            <FontAwesomeIcon icon="ban" />
            &nbsp;
            <Translate contentKey="generadorApp.quote.btnCancel">Close</Translate>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
  createEntity,
  getEntity,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;

export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(ClComTableExtended);
