import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import { Translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity, ICidPtaBaseState, getUrlBack, getCidPtaState, getEntityFiltersURL } from './cid-pta.reducer';

export interface ICidPtaState {
  fieldsBase: ICidPtaBaseState;
}

export interface ICidPtaDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class CidPtaDetail extends React.Component<ICidPtaDetailProps, ICidPtaState> {
  constructor(props: Readonly<ICidPtaDetailProps>) {
    super(props);
    this.state = {
      ...this.state,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getCidPtaState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
    };
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = '_back=1' + (offset !== null ? '&offset=' + offset : '');
    Object.keys(fieldsBase).map(key => {
      url += '&' + key + '=' + fieldsBase[key];
    });
    return url;
  };

  UNSAFE_componentWillMount() {
    this.props.getEntity(this.props.match.params['id']);
  }

  renderHeader() {
    const { cidPtaEntity } = this.props;
    const state = this.state;
    return (
      <>
        <div id="page-heading">
          {' '}
          <h2>
            <Translate contentKey="generadorApp.cidPta.detail.title">CidPta</Translate>
            <small>&nbsp; {cidPtaEntity['name']}</small>
          </h2>
        </div>

        <div className="pull-right">
          <Button tag={Link} to={'/cid-pta?' + getEntityFiltersURL(this.state.fieldsBase)} replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.cidPta.btnBack">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/cid-pta/${cidPtaEntity.id}/edit?+${getEntityFiltersURL(this.state.fieldsBase)}`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.cidPta.btnEdit">Edit</Translate>
            </span>
          </Button>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>

          <li className="breadcrumb-item active">
            <Link to={'/cid-pta'}>CID x PTA</Link>
          </li>
          <li className="breadcrumb-item active">CID x PTA details</li>
        </ol>
      </>
    );
  }

  renderBody() {
    const { cidPtaEntity } = this.props;
    const state = this.state;
    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            <Row className="jh-entity-details">
              <Col className="view-detail-row cid-pta-name-detail" md="6">
                <Row>
                  <Col className="view-detail-label-top cid-pta-name-detail-label" md="12">
                    <dt>
                      <span id="name">
                        <Translate contentKey="generadorApp.cidPta.name">Name</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top cid-pta-name-detail-value" md="12">
                    <dd>{cidPtaEntity.name}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row cid-pta-cid-detail" md="6">
                <Row>
                  <Col className="view-detail-label-top cid-pta-cid-detail-label" md="12">
                    <dt>
                      <Translate contentKey="generadorApp.cidPta.cid">Cid</Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top cid-pta-cid-detail-value" md="12">
                    <dd>{cidPtaEntity.cid ? cidPtaEntity.cid.id : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row cid-pta-expectedResult-detail" md="12">
                <Row>
                  <Col className="view-detail-label-top cid-pta-expectedResult-detail-label" md="12">
                    <dt>
                      <span id="expectedResult">
                        <Translate contentKey="generadorApp.cidPta.expectedResult">Expected Result</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top cid-pta-expectedResult-detail-value" md="12">
                    <dd>{cidPtaEntity.expectedResult}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row cid-pta-activity-detail" md="12">
                <Row>
                  <Col className="view-detail-label-top cid-pta-activity-detail-label" md="12">
                    <dt>
                      <Translate contentKey="generadorApp.cidPta.activity">Activity</Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top cid-pta-activity-detail-value" md="12">
                    <dd>{cidPtaEntity.activity ? cidPtaEntity.activity.id : ''}</dd>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { cidPtaEntity } = this.props;
    const state = this.state;
    return (
      <div>
        <Panel className="cid-pta cid-pta-detail">
          <PanelHeader>{this.renderHeader()}</PanelHeader>

          <PanelBody>{this.renderBody()}</PanelBody>
        </Panel>
      </div>
    );
  }
}

export const mapStateToProps = ({ cidPta, ...storeState }: IRootState) => ({
  cidPtaEntity: cidPta.entity,
});

export const mapDispatchToProps = {
  getEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CidPtaDetail);
