import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { IClComTable } from 'app/shared/model/cl-com-table.model';
import { ILevelGroup } from 'app/shared/model/level-group.model';
import { ICategory } from 'app/shared/model/category.model';
import { IProductService } from 'app/shared/model/product-service.model';
import { ISpecialty } from 'app/shared/model/specialty.model';
import { ServiceType } from 'app/shared/model/enumerations/service-type.model';
import { ComercialTableType } from 'app/shared/model/enumerations/comercial-table-type.model';
import { EnumTable } from 'app/shared/model/enumerations/enum-table.model';
import { QuoteFrequency } from 'app/shared/model/enumerations/quote-frequency.model';

export interface IClComRuler {
  id?: number;
  option?: ServiceType;
  comercialTableType?: ComercialTableType;
  saleValue?: number;
  observation?: any;
  table?: EnumTable;
  percent?: number;
  inflator?: number;
  deflator?: number;
  rentalValue?: number;
  tussCode?: string;
  tissCode?: string;
  tableCode?: string;
  expenseCode?: string;
  frequency?: QuoteFrequency;
  minValuePackage?: number;
  clComTable?: IClComTable;
  levelGroup?: ILevelGroup;
  category?: ICategory;
  productServices?: IProductService[];
  specialties?: ISpecialty[];
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  clComTable: {
    isMulti: true, // many-to-one - other-side
    entity: 'ClComTable',
    urlParameters: {
      enpoint: 'cl-com-tables',
      filters: 'clComRuler.id.equals',
    },
  },
  levelGroup: {
    isMulti: true, // many-to-one - other-side
    entity: 'LevelGroup',
    urlParameters: {
      enpoint: 'level-groups',
      filters: 'clComRuler.id.equals',
    },
  },
  category: {
    isMulti: true, // many-to-one - other-side
    entity: 'Category',
    urlParameters: {
      enpoint: 'categories',
      filters: 'clComRuler.id.equals',
    },
  },
  productService: {
    isMulti: true, // many-to-many - other-side
    entity: 'ProductService',
    urlParameters: {
      enpoint: 'product-services',
      filters: 'clComRulers.id.equals',
    },
  },
  specialty: {
    isMulti: true, // many-to-many - other-side
    entity: 'Specialty',
    urlParameters: {
      enpoint: 'specialties',
      filters: 'clComRulers.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'clComRuler.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'clComRuler.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<IClComRuler> = {};
