import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { IClientGlosaItem } from 'app/shared/model/client-glosa-item.model';
import { IQuote } from 'app/shared/model/quote.model';
import { IMedicineMaterial } from 'app/shared/model/medicine-material.model';
import { Via } from 'app/shared/model/enumerations/via.model';
import { QuoteDiscountType } from 'app/shared/model/enumerations/quote-discount-type.model';
import { QuoteFrequency } from 'app/shared/model/enumerations/quote-frequency.model';
import { RomaneioStatus } from 'app/shared/model/enumerations/romaneio-status.model';

export interface IQuoteMaterial {
  id?: number;
  unitPerDay?: number;
  via?: Via;
  startDate?: Moment;
  finishDate?: Moment;
  days?: number;
  quantity?: number;
  currentQuantity?: number;
  unitaryAmount?: number;
  grossAmount?: number;
  typeDiscount?: QuoteDiscountType;
  discountPercent?: number;
  discount?: number;
  finalAmount?: number;
  frequency?: QuoteFrequency;
  romaneioStatus?: RomaneioStatus;
  additive?: boolean;
  billQuantityItem?: number;
  billGrossAmount?: number;
  billFinalAmount?: number;
  clientGlosaItem?: IClientGlosaItem[];
  quote?: IQuote;
  medicineMaterial?: IMedicineMaterial;
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  clientGlosaItem: {
    isMulti: false, // one-to-many - other-side
    entity: 'ClientGlosaItem',
    urlParameters: {
      enpoint: 'client-glosa-items',
      filters: 'quoteMaterial.id.equals',
    },
  },
  quote: {
    isMulti: true, // many-to-one - other-side
    entity: 'Quote',
    urlParameters: {
      enpoint: 'quotes',
      filters: 'quoteMaterial.id.equals',
    },
  },
  medicineMaterial: {
    isMulti: true, // many-to-one - other-side
    entity: 'MedicineMaterial',
    urlParameters: {
      enpoint: 'medicine-materials',
      filters: 'quoteMaterial.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'quoteMaterial.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'quoteMaterial.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<IQuoteMaterial> = {
  additive: false,
};
