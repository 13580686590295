/* eslint complexity: ["error", 500] */
import axios from 'axios';

import { IPaginationBaseState, IPayloadResult } from 'react-jhipster';

import { IPayload } from 'react-jhipster/src/type/redux-action.type';

import { BASE_API_VERSION_PATH } from 'app/config/constants';

import { cleanEntity, jsonParse } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ISupplierGlosaItem, defaultValue } from 'app/shared/model/supplier-glosa-item.model';

export declare type ICrudPutAction<T> = (data?: T, listFiltersPage?) => IPayload<T> | IPayloadResult<T>;
export declare type ICrudGetAction<T> = (id: string | number, selectFields?: string) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const ACTION_TYPES = {
  FETCH_SUPPLIERGLOSAITEM_LIST_EXPORT: 'supplierGlosaItem/FETCH_SUPPLIERGLOSAITEM_LIST_EXPORT',
  FETCH_SUPPLIERGLOSAITEM_LIST: 'supplierGlosaItem/FETCH_SUPPLIERGLOSAITEM_LIST',
  FETCH_SUPPLIERGLOSAITEM: 'supplierGlosaItem/FETCH_SUPPLIERGLOSAITEM',
  FETCH_SUPPLIERGLOSAITEM_OFFSET: 'supplierGlosaItem/FETCH_SUPPLIERGLOSAITEM_OFFSET',
  CREATE_SUPPLIERGLOSAITEM: 'supplierGlosaItem/CREATE_SUPPLIERGLOSAITEM',
  UPDATE_SUPPLIERGLOSAITEM: 'supplierGlosaItem/UPDATE_SUPPLIERGLOSAITEM',
  CREATE_SUPPLIERGLOSAITEM_AND_GO_LIST: 'supplierGlosaItem/CREATE_SUPPLIERGLOSAITEM_AND_GO_LIST',
  UPDATE_SUPPLIERGLOSAITEM_AND_GO_LIST: 'supplierGlosaItem/UPDATE_SUPPLIERGLOSAITEM_AND_GO_LIST',
  DELETE_SUPPLIERGLOSAITEM: 'supplierGlosaItem/DELETE_SUPPLIERGLOSAITEM',
  RESET: 'supplierGlosaItem/RESET',
  SHOW_MODAL: 'supplierGlosaItem/SHOW_MODAL',
};

const initialState = {
  loading: false,
  openModal: {
    list: false,
    view: false,
    detail: false,
  },
  errorMessage: null,
  entities: [] as ReadonlyArray<ISupplierGlosaItem>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const actionPendind = [];
let actualState = initialState;

export const convertEntityBuffers = entity => ({
  ...entity,
});

export type SupplierGlosaItemState = Readonly<typeof initialState>;

export interface ISupplierGlosaItemBaseState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  listCheckedID?: Array<number>;
  isCheckedAll?: boolean;
  urlBack?: string;
  extraFilters?: any;
  baseFilters: any;
  offset: number;
  secOrder?: any;
  typeItemGlosa?: any;
  quantityGlossed?: any;
  quantityRefused?: any;
  justification?: any;
  glosaItemAceita?: any;
  supplierGlosaId?: any;
  supplierGlosaStartFilter?: any;
  quoteProductServiceId?: any;
  quoteProductServiceStartFilter?: any;
}

export interface IFieldsBase extends ISupplierGlosaItemBaseState, IPaginationBaseState {}
export interface ISupplierGlosaItemUpdateState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  urlBack?: string;
  functionEmbebed?: any[];
  fieldsBase: IFieldsBase;
  secOrderSelectValue?: any;
  secOrderStartSelectOptions?: any;
  typeItemGlosaSelectValue?: any;
  typeItemGlosaStartSelectOptions?: any;
  quantityGlossedSelectValue?: any;
  quantityGlossedStartSelectOptions?: any;
  quantityRefusedSelectValue?: any;
  quantityRefusedStartSelectOptions?: any;
  justificationSelectValue?: any;
  justificationStartSelectOptions?: any;
  glosaItemAceitaSelectValue?: any;
  glosaItemAceitaStartSelectOptions?: any;

  supplierGlosaSelectValue?: any;
  supplierGlosaStartSelectOptions?: any;

  quoteProductServiceSelectValue?: any;
  quoteProductServiceStartSelectOptions?: any;

  isNew: boolean;
  supplierGlosaId?: any;
  quoteProductServiceId?: any;
}

// Reducer

export default (state: SupplierGlosaItemState = initialState, action): SupplierGlosaItemState => {
  actualState = state;
  if (
    action.type.includes('_PENDING') &&
    !actionPendind.includes(action.type.replace('_PENDING', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_PENDING', ''))
  ) {
    actionPendind.push(action.type.replace('_PENDING', ''));
  }

  if (
    action.type.includes('_FULFILLED') &&
    actionPendind.includes(action.type.replace('_FULFILLED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_FULFILLED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_FULFILLED', '')), 1);
  }

  if (
    action.type.includes('_REJECTED') &&
    actionPendind.includes(action.type.replace('_REJECTED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_REJECTED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_REJECTED', '')), 1);
  }

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_SUPPLIERGLOSAITEM_LIST_EXPORT):
    case REQUEST(ACTION_TYPES.FETCH_SUPPLIERGLOSAITEM_LIST):
    case REQUEST(ACTION_TYPES.FETCH_SUPPLIERGLOSAITEM):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_SUPPLIERGLOSAITEM):
    case REQUEST(ACTION_TYPES.UPDATE_SUPPLIERGLOSAITEM):
    case REQUEST(ACTION_TYPES.CREATE_SUPPLIERGLOSAITEM_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.UPDATE_SUPPLIERGLOSAITEM_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.DELETE_SUPPLIERGLOSAITEM):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_SUPPLIERGLOSAITEM_LIST_EXPORT):
    case FAILURE(ACTION_TYPES.FETCH_SUPPLIERGLOSAITEM_LIST):
    case FAILURE(ACTION_TYPES.FETCH_SUPPLIERGLOSAITEM):
    case FAILURE(ACTION_TYPES.CREATE_SUPPLIERGLOSAITEM):
    case FAILURE(ACTION_TYPES.UPDATE_SUPPLIERGLOSAITEM):
    case FAILURE(ACTION_TYPES.CREATE_SUPPLIERGLOSAITEM_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.UPDATE_SUPPLIERGLOSAITEM_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.DELETE_SUPPLIERGLOSAITEM):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_SUPPLIERGLOSAITEM_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_SUPPLIERGLOSAITEM_OFFSET):
      if (action.payload.data && action.payload.data.length > 0) {
        return {
          ...state,
          loading: false,
          entity: convertEntityBuffers(action.payload.data[0]),
        };
      }
      return {
        ...state,
        loading: false,
      };

    case SUCCESS(ACTION_TYPES.FETCH_SUPPLIERGLOSAITEM):
      return {
        ...state,
        loading: false,
        entity: convertEntityBuffers(action.payload.data),
      };
    case SUCCESS(ACTION_TYPES.CREATE_SUPPLIERGLOSAITEM):
    case SUCCESS(ACTION_TYPES.UPDATE_SUPPLIERGLOSAITEM):
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_SUPPLIERGLOSAITEM_AND_GO_LIST):
    case SUCCESS(ACTION_TYPES.UPDATE_SUPPLIERGLOSAITEM_AND_GO_LIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_SUPPLIERGLOSAITEM):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };

    case ACTION_TYPES.SHOW_MODAL: {
      const openModal = state.openModal;
      openModal[action.payload.type] = action.payload.show;
      return {
        ...state,
        openModal,
      };
    }
    default:
      return state;
  }
};

const apiUrl = BASE_API_VERSION_PATH + 'supplier-glosa-items';

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

// Actions

// Actions
export type ICrudGetAllActionSupplierGlosaItem<T> = (
  secOrder?: any,
  typeItemGlosa?: any,
  quantityGlossed?: any,
  quantityRefused?: any,
  justification?: any,
  glosaItemAceita?: any,
  supplierGlosaId?: any,
  quoteProductServiceId?: any,
  extraFilters?: {},
  page?: number,
  size?: number,
  sort?: string,
  selectFields?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getEntitiesFilter = (filters?, page?, size?, sort?, selectFields = '') => {
  const requestUrl = `${apiUrl}?page=${page >= 0 ? page : 0}&size=${size >= 0 ? size : 20}&sort=${sort ? sort : 'id,asc'}&`;
  const requestFilters = filters.map(v => v.column + '.' + v.operation + '=' + v.value).join('&');
  return {
    type: ACTION_TYPES.FETCH_SUPPLIERGLOSAITEM_LIST,
    payload: axios.get<ISupplierGlosaItem>(`${requestUrl}${requestFilters}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getAllEntities = (filters?, sort?, selectFields = '') => {
  if (
    (actualState.totalItems > 0 && actualState.entities.length === actualState.totalItems) ||
    actionPendind.includes(ACTION_TYPES.FETCH_SUPPLIERGLOSAITEM_LIST)
  ) {
    return {
      type: ACTION_TYPES.FETCH_SUPPLIERGLOSAITEM_LIST + '_FROM_CACHE',
      payload: new Promise(function (resolve, reject) {
        resolve('done');
      }),
    };
  }

  const idsRequest = filters && typeof filters['ids'] !== 'undefined' && filters['ids'] ? `id.in=${filters['ids'].join(',')}&` : '';
  const secOrderRequest =
    filters && typeof filters['secOrder'] !== 'undefined' && filters['secOrder'] ? `secOrder.equals=${filters['secOrder']}&` : '';
  const typeItemGlosaRequest =
    filters && typeof filters['typeItemGlosa'] !== 'undefined' && filters['typeItemGlosa']
      ? `typeItemGlosa.contains=${filters['typeItemGlosa']}&`
      : '';
  const quantityGlossedRequest =
    filters && typeof filters['quantityGlossed'] !== 'undefined' && filters['quantityGlossed']
      ? `quantityGlossed.equals=${filters['quantityGlossed']}&`
      : '';
  const quantityRefusedRequest =
    filters && typeof filters['quantityRefused'] !== 'undefined' && filters['quantityRefused']
      ? `quantityRefused.equals=${filters['quantityRefused']}&`
      : '';
  const justificationRequest =
    filters && typeof filters['justification'] !== 'undefined' && filters['justification']
      ? `justification.contains=${filters['justification']}&`
      : '';
  const glosaItemAceitaRequest =
    filters && typeof filters['glosaItemAceita'] !== 'undefined' && filters['glosaItemAceita']
      ? `glosaItemAceita.contains=${filters['glosaItemAceita']}&`
      : '';
  const supplierGlosaRequest =
    filters && typeof filters['supplierGlosaId'] !== 'undefined' && filters['supplierGlosaId']
      ? `supplierGlosa.id.in=${filters['supplierGlosaId']}&&`
      : '';
  const quoteProductServiceRequest =
    filters && typeof filters['quoteProductServiceId'] !== 'undefined' && filters['quoteProductServiceId']
      ? `quoteProductService.id.in=${filters['quoteProductServiceId']}&&`
      : '';

  const requestUrl = `${apiUrl}${`?page=${0}&size=${1000000}&sort=${sort ? sort : 'id,asc'}&`}`;
  return {
    type: ACTION_TYPES.FETCH_SUPPLIERGLOSAITEM_LIST,
    payload: axios.get<ISupplierGlosaItem>(
      `${requestUrl}${idsRequest}${secOrderRequest}${typeItemGlosaRequest}${quantityGlossedRequest}${quantityRefusedRequest}${justificationRequest}${glosaItemAceitaRequest}${supplierGlosaRequest}${quoteProductServiceRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntities: ICrudGetAllActionSupplierGlosaItem<ISupplierGlosaItem> = (
  secOrder,
  typeItemGlosa,
  quantityGlossed,
  quantityRefused,
  justification,
  glosaItemAceita,
  supplierGlosaId,
  quoteProductServiceId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const secOrderRequest = secOrder ? (Array.isArray(status) ? `secOrder.in=${secOrder.join(',')}&` : `secOrder.equals=${secOrder}&`) : '';
  const typeItemGlosaRequest = typeItemGlosa
    ? Array.isArray(status)
      ? `typeItemGlosa.in=${typeItemGlosa.join(',')}&`
      : `typeItemGlosa.equals=${typeItemGlosa}&`
    : '';
  const quantityGlossedRequest = quantityGlossed
    ? Array.isArray(status)
      ? `quantityGlossed.in=${quantityGlossed.join(',')}&`
      : `quantityGlossed.equals=${quantityGlossed}&`
    : '';
  const quantityRefusedRequest = quantityRefused
    ? Array.isArray(status)
      ? `quantityRefused.in=${quantityRefused.join(',')}&`
      : `quantityRefused.equals=${quantityRefused}&`
    : '';
  const justificationRequest = justification
    ? Array.isArray(status)
      ? `justification.in=${justification.join(',')}&`
      : `justification.contains=${justification}&`
    : '';
  const glosaItemAceitaRequest = glosaItemAceita
    ? Array.isArray(status)
      ? `glosaItemAceita.in=${glosaItemAceita.join(',')}&`
      : `glosaItemAceita.equals=${glosaItemAceita}&`
    : '';
  const supplierGlosaRequest = supplierGlosaId ? `supplierGlosa.id.in=${supplierGlosaId}&` : '';
  const quoteProductServiceRequest = quoteProductServiceId ? `quoteProductService.id.in=${quoteProductServiceId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_SUPPLIERGLOSAITEM_LIST,
    payload: axios.get<ISupplierGlosaItem>(
      `${requestUrl}${extraFiltersRequest}${secOrderRequest}${typeItemGlosaRequest}${quantityGlossedRequest}${quantityRefusedRequest}${justificationRequest}${glosaItemAceitaRequest}${supplierGlosaRequest}${quoteProductServiceRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntityOffset: any = (
  secOrder,
  typeItemGlosa,
  quantityGlossed,
  quantityRefused,
  justification,
  glosaItemAceita,
  supplierGlosaId,
  quoteProductServiceId,
  extraFilters,
  offset,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const secOrderRequest = secOrder ? (Array.isArray(status) ? `secOrder.in=${secOrder.join(',')}&` : `secOrder.equals=${secOrder}&`) : '';
  const typeItemGlosaRequest = typeItemGlosa
    ? Array.isArray(status)
      ? `typeItemGlosa.in=${typeItemGlosa.join(',')}&`
      : `typeItemGlosa.equals=${typeItemGlosa}&`
    : '';
  const quantityGlossedRequest = quantityGlossed
    ? Array.isArray(status)
      ? `quantityGlossed.in=${quantityGlossed.join(',')}&`
      : `quantityGlossed.equals=${quantityGlossed}&`
    : '';
  const quantityRefusedRequest = quantityRefused
    ? Array.isArray(status)
      ? `quantityRefused.in=${quantityRefused.join(',')}&`
      : `quantityRefused.equals=${quantityRefused}&`
    : '';
  const justificationRequest = justification
    ? Array.isArray(status)
      ? `justification.in=${justification.join(',')}&`
      : `justification.contains=${justification}&`
    : '';
  const glosaItemAceitaRequest = glosaItemAceita
    ? Array.isArray(status)
      ? `glosaItemAceita.in=${glosaItemAceita.join(',')}&`
      : `glosaItemAceita.equals=${glosaItemAceita}&`
    : '';
  const supplierGlosaRequest = supplierGlosaId ? `supplierGlosa.id.in=${supplierGlosaId}&` : '';
  const quoteProductServiceRequest = quoteProductServiceId ? `quoteProductService.id.in=${quoteProductServiceId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${offset}&size=${1}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_SUPPLIERGLOSAITEM_OFFSET,
    payload: axios.get<ISupplierGlosaItem>(
      `${requestUrl}${extraFiltersRequest}${secOrderRequest}${typeItemGlosaRequest}${quantityGlossedRequest}${quantityRefusedRequest}${justificationRequest}${glosaItemAceitaRequest}${supplierGlosaRequest}${quoteProductServiceRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntity: ICrudGetAction<ISupplierGlosaItem> = (id, selectFields = '') => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_SUPPLIERGLOSAITEM,
    payload: axios.get<ISupplierGlosaItem>(requestUrl, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntitiesExport: ICrudGetAllActionSupplierGlosaItem<ISupplierGlosaItem> = (
  secOrder,
  typeItemGlosa,
  quantityGlossed,
  quantityRefused,
  justification,
  glosaItemAceita,
  supplierGlosaId,
  quoteProductServiceId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const secOrderRequest = secOrder ? (Array.isArray(status) ? `secOrder.in=${secOrder.join(',')}&` : `secOrder.equals=${secOrder}&`) : '';
  const typeItemGlosaRequest = typeItemGlosa
    ? Array.isArray(status)
      ? `typeItemGlosa.in=${typeItemGlosa.join(',')}&`
      : `typeItemGlosa.equals=${typeItemGlosa}&`
    : '';
  const quantityGlossedRequest = quantityGlossed
    ? Array.isArray(status)
      ? `quantityGlossed.in=${quantityGlossed.join(',')}&`
      : `quantityGlossed.equals=${quantityGlossed}&`
    : '';
  const quantityRefusedRequest = quantityRefused
    ? Array.isArray(status)
      ? `quantityRefused.in=${quantityRefused.join(',')}&`
      : `quantityRefused.equals=${quantityRefused}&`
    : '';
  const justificationRequest = justification
    ? Array.isArray(status)
      ? `justification.in=${justification.join(',')}&`
      : `justification.contains=${justification}&`
    : '';
  const glosaItemAceitaRequest = glosaItemAceita
    ? Array.isArray(status)
      ? `glosaItemAceita.in=${glosaItemAceita.join(',')}&`
      : `glosaItemAceita.equals=${glosaItemAceita}&`
    : '';
  const supplierGlosaRequest = supplierGlosaId ? `supplierGlosa.id.in=${supplierGlosaId}&` : '';
  const quoteProductServiceRequest = quoteProductServiceId ? `quoteProductService.id.in=${quoteProductServiceId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_SUPPLIERGLOSAITEM_LIST,
    payload: axios.get<ISupplierGlosaItem>(
      `${requestUrl}${extraFiltersRequest}${secOrderRequest}${typeItemGlosaRequest}${quantityGlossedRequest}${quantityRefusedRequest}${justificationRequest}${glosaItemAceitaRequest}${supplierGlosaRequest}${quoteProductServiceRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const createEntity: ICrudPutAction<ISupplierGlosaItem> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.CREATE_SUPPLIERGLOSAITEM_AND_GO_LIST
        : ACTION_TYPES.CREATE_SUPPLIERGLOSAITEM,
    payload: axios.post(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const updateEntity: ICrudPutAction<ISupplierGlosaItem> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.UPDATE_SUPPLIERGLOSAITEM_AND_GO_LIST
        : ACTION_TYPES.UPDATE_SUPPLIERGLOSAITEM,
    payload: axios.put(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const deleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_SUPPLIERGLOSAITEM,
    payload: axios.delete(requestUrl),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const showModal = view => ({
  payload: { type: view, show: true },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const hideModal = view => ({
  payload: { type: view, show: false },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const getUrlBack = (location): string => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  let urlBack = _urlBase.searchParams.get('urlBack') || '';
  urlBack = urlBack.trim();
  urlBack = urlBack.startsWith('/') ? urlBack.slice(1) : urlBack;
  urlBack = urlBack.endsWith('/') ? urlBack.slice(0, -1) : urlBack;
  return urlBack ? '/' + urlBack + '/' : '';
};

export const getSupplierGlosaItemState = (location): ISupplierGlosaItemBaseState => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  const extraFilters = jsonParse(_urlBase.searchParams.get('extraFilters') || '{}');
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;

  const secOrder = _urlBase.searchParams.get('secOrder') || '';
  const typeItemGlosa = _urlBase.searchParams.get('typeItemGlosa') || '';
  const quantityGlossed = _urlBase.searchParams.get('quantityGlossed') || '';
  const quantityRefused = _urlBase.searchParams.get('quantityRefused') || '';
  const justification = _urlBase.searchParams.get('justification') || '';
  const glosaItemAceita = _urlBase.searchParams.get('glosaItemAceita') || '';
  const supplierGlosaId = _urlBase.searchParams.get('supplierGlosa') || '';
  const quoteProductServiceId = _urlBase.searchParams.get('quoteProductService') || '';
  return {
    baseFilters,
    extraFilters,
    offset,
    secOrder,
    typeItemGlosa,
    quantityGlossed,
    quantityRefused,
    justification,
    glosaItemAceita,
    supplierGlosaId: supplierGlosaId ? supplierGlosaId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
    quoteProductServiceId: quoteProductServiceId
      ? quoteProductServiceId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] }))
      : [],
  };
};

export const getEntityFiltersURL = (state, offset = null) => {
  return (
    'baseFilters=' +
    state.baseFilters +
    '&extraFilters=' +
    encodeURI(JSON.stringify(state.extraFilters)) +
    '&page=' +
    state.activePage +
    '&' +
    'size=' +
    state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    state.sort +
    ',' +
    state.order +
    '&' +
    (state.secOrder ? 'secOrder=' + state.secOrder + '&' : '') +
    (state.typeItemGlosa ? 'typeItemGlosa=' + state.typeItemGlosa + '&' : '') +
    (state.quantityGlossed ? 'quantityGlossed=' + state.quantityGlossed + '&' : '') +
    (state.quantityRefused ? 'quantityRefused=' + state.quantityRefused + '&' : '') +
    (state.justification ? 'justification=' + state.justification + '&' : '') +
    (state.glosaItemAceita ? 'glosaItemAceita=' + state.glosaItemAceita + '&' : '') +
    (state.supplierGlosaId ? 'supplierGlosa=' + state.supplierGlosaId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.quoteProductServiceId
      ? 'quoteProductService=' + state.quoteProductServiceId.map(v => v.value + '<->' + v.label).join(',') + '&'
      : '') +
    ''
  );
};
