import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AvForm, AvGroup } from 'availity-reactstrap-validation';
import Select from 'react-select';

const FiltersModal = props => {
  const { buttonLabel, className } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <span>
      <Button onClick={() => toggle()} className="btn btn-xs pull-right" color="primary">
        <FontAwesomeIcon icon="filter" /> Filtrar
      </Button>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} className={'modal-header-dark'}>
          Filtrar informações
        </ModalHeader>
        <AvForm className="" model={''}>
          <ModalBody>
            <AvGroup>
              <Label for="operadora">Operadoras</Label>
              <Select
                id={'operadoras-select'}
                name={'operadoras'}
                isMulti
                // value={userRoles.map(role => ({value: role, label: role }))}
                // options={roles.map(role => ({value: role, label:  role}))}
                // onChange={(values) => setUserRoles(values ? values.map(v => v.value) : [])}
                placeholder="Selecione as operadoras"
              />
            </AvGroup>
            <AvGroup>
              <Label for="operadora">Complexidades</Label>
              <Select
                id={'complexidade-select'}
                name={'operadora'}
                isMulti
                // value={userRoles.map(role => ({value: role, label: role }))}
                // options={roles.map(role => ({value: role, label:  role}))}
                // onChange={(values) => setUserRoles(values ? values.map(v => v.value) : [])}
                placeholder="Selecione as complexidades"
              />
            </AvGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggle} type="submit">
              Filtrar
            </Button>{' '}
            <Button color="dark" onClick={toggle}>
              Cancelar
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    </span>
  );
};

export default FiltersModal;
