/* eslint no-console: off */
import React from 'react';
import { AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Row, Col, Label } from 'reactstrap';
import { Translate } from 'app/config/translate-component';
import { showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import { IQuote } from 'app/shared/model/quote.model';

export interface IQuoteProps {
  baseState?: any;
  baseProps?: any;
  setState?: any;
  entityBase?: Readonly<IQuote>;
}

const QuoteComponentCustomize = (props: IQuoteProps) => {
  const clComTables = showFieldsSelectAsync(props.entityBase, 'patient.client.clComTable', false);
  const tablesData = clComTables
    ? clComTables.filter(v => v.deletedAt === null && v.type === 'TABELAABERTA' && v.status === 'ATIVO').pop()
    : null;

  return (
    <AvGroup>
      <Row>
        <Col md="12">
          <Label className="mt-2 label-single-line" for="quote-patient">
            <Translate contentKey="generadorApp.TypeClientCommercialTable.TABELAABERTA">TABELAABERTA</Translate>
          </Label>
        </Col>
        <Col md="12">
          <AvInput
            disabled={true}
            value={showFieldsSelectAsync(tablesData, 'name')}
            id="quote-patient1"
            type="text"
            name="patient1"
            className={'form-control'}
          />
        </Col>
      </Row>
    </AvGroup>
  );
};

export default QuoteComponentCustomize;
