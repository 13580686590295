import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { IClientGlosa } from 'app/shared/model/client-glosa.model';
import { ISupplierGlosa } from 'app/shared/model/supplier-glosa.model';
import { IQuoteDiet } from 'app/shared/model/quote-diet.model';
import { IQuoteMedicine } from 'app/shared/model/quote-medicine.model';
import { IQuoteMaterial } from 'app/shared/model/quote-material.model';
import { IQuoteProductService } from 'app/shared/model/quote-product-service.model';
import { IQuotePackage } from 'app/shared/model/quote-package.model';
import { IQuoteRh } from 'app/shared/model/quote-rh.model';
import { IQuoteFile } from 'app/shared/model/quote-file.model';
import { IExpectedResult } from 'app/shared/model/expected-result.model';
import { IPad } from 'app/shared/model/pad.model';
import { ISupply } from 'app/shared/model/supply.model';
import { IPatient } from 'app/shared/model/patient.model';
import { IBillingXml } from 'app/shared/model/billing-xml.model';
import { IClComTable } from 'app/shared/model/cl-com-table.model';
import { QuoteSource } from 'app/shared/model/enumerations/quote-source.model';
import { QuoteStatus } from 'app/shared/model/enumerations/quote-status.model';
import { TypeClientCommercialTable } from 'app/shared/model/enumerations/type-client-commercial-table.model';
import { BillingStatus } from 'app/shared/model/enumerations/billing-status.model';

export interface IQuote {
  id?: number;
  source?: QuoteSource;
  status?: QuoteStatus;
  approvalDate?: Moment;
  from?: Moment;
  to?: Moment;
  accountNumber?: number;
  finalAmount?: number;
  billFinalAmount?: number;
  type?: TypeClientCommercialTable;
  billingStatus?: BillingStatus;
  billingPasswordNumber?: string;
  billingPasswordIssue?: string;
  billingPasswordExpiry?: string;
  billingPasswordIssuer?: string;
  autorizationData?: string;
  billingHospitalizationRecord?: string;
  billingHospitalizationGuide?: string;
  billingGuideSPSADT?: string;
  billingObservation?: any;
  clientGlosa?: IClientGlosa[];
  supplierGlosa?: ISupplierGlosa[];
  quoteDiet?: IQuoteDiet[];
  quoteMedicine?: IQuoteMedicine[];
  quoteMaterial?: IQuoteMaterial[];
  quoteProductService?: IQuoteProductService[];
  quotePackage?: IQuotePackage[];
  quoteRh?: IQuoteRh[];
  quoteFile?: IQuoteFile[];
  expectedResult?: IExpectedResult[];
  pad?: IPad[];
  supply?: ISupply[];
  patient?: IPatient;
  billingXml?: IBillingXml;
  clComTable?: IClComTable;
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  clientGlosa: {
    isMulti: false, // one-to-many - other-side
    entity: 'ClientGlosa',
    urlParameters: {
      enpoint: 'client-glosas',
      filters: 'quote.id.equals',
    },
  },
  supplierGlosa: {
    isMulti: false, // one-to-many - other-side
    entity: 'SupplierGlosa',
    urlParameters: {
      enpoint: 'supplier-glosas',
      filters: 'quote.id.equals',
    },
  },
  quoteDiet: {
    isMulti: false, // one-to-many - other-side
    entity: 'QuoteDiet',
    urlParameters: {
      enpoint: 'quote-diets',
      filters: 'quote.id.equals',
    },
  },
  quoteMedicine: {
    isMulti: false, // one-to-many - other-side
    entity: 'QuoteMedicine',
    urlParameters: {
      enpoint: 'quote-medicines',
      filters: 'quote.id.equals',
    },
  },
  quoteMaterial: {
    isMulti: false, // one-to-many - other-side
    entity: 'QuoteMaterial',
    urlParameters: {
      enpoint: 'quote-materials',
      filters: 'quote.id.equals',
    },
  },
  quoteProductService: {
    isMulti: false, // one-to-many - other-side
    entity: 'QuoteProductService',
    urlParameters: {
      enpoint: 'quote-product-services',
      filters: 'quote.id.equals',
    },
  },
  quotePackage: {
    isMulti: false, // one-to-many - other-side
    entity: 'QuotePackage',
    urlParameters: {
      enpoint: 'quote-packages',
      filters: 'quote.id.equals',
    },
  },
  quoteRh: {
    isMulti: false, // one-to-many - other-side
    entity: 'QuoteRh',
    urlParameters: {
      enpoint: 'quote-rhs',
      filters: 'quote.id.equals',
    },
  },
  quoteFile: {
    isMulti: false, // one-to-many - other-side
    entity: 'QuoteFile',
    urlParameters: {
      enpoint: 'quote-files',
      filters: 'quote.id.equals',
    },
  },
  expectedResult: {
    isMulti: false, // one-to-many - other-side
    entity: 'ExpectedResult',
    urlParameters: {
      enpoint: 'expected-results',
      filters: 'quote.id.equals',
    },
  },
  pad: {
    isMulti: false, // one-to-many - other-side
    entity: 'Pad',
    urlParameters: {
      enpoint: 'pads',
      filters: 'quote.id.equals',
    },
  },
  supply: {
    isMulti: false, // one-to-many - other-side
    entity: 'Supply',
    urlParameters: {
      enpoint: 'supplies',
      filters: 'quote.id.equals',
    },
  },
  patient: {
    isMulti: true, // many-to-one - other-side
    entity: 'Patient',
    urlParameters: {
      enpoint: 'patients',
      filters: 'quote.id.equals',
    },
  },
  billingXml: {
    isMulti: true, // many-to-one - other-side
    entity: 'BillingXml',
    urlParameters: {
      enpoint: 'billing-xmls',
      filters: 'quote.id.equals',
    },
  },
  clComTable: {
    isMulti: true, // many-to-one - other-side
    entity: 'ClComTable',
    urlParameters: {
      enpoint: 'cl-com-tables',
      filters: 'quote.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'quote.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'quote.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<IQuote> = {};
