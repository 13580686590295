/* eslint complexity: ["error", 100] */
import axios from 'axios';
import { ICrudGetAction, ICrudDeleteAction, IPayloadResult } from 'react-jhipster';

import { IPayload } from 'react-jhipster/src/type/redux-action.type';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IAuditEntity, defaultValue } from 'app/shared/model/audit-entity.model';
import { SwitchExtended, initialStateExtended } from './audit-entity-extended.reducer';

export declare type ICrudPutAction<T> = (data?: T, listFiltersPage?) => IPayload<T> | IPayloadResult<T>;
export const ACTION_TYPES = {
  FETCH_AUDITENTITY_LIST_EXPORT: 'auditEntity/FETCH_AUDITENTITY_LIST_EXPORT',
  FETCH_AUDITENTITY_LIST: 'auditEntity/FETCH_AUDITENTITY_LIST',
  FETCH_AUDITENTITY: 'auditEntity/FETCH_AUDITENTITY',
  CREATE_AUDITENTITY: 'auditEntity/CREATE_AUDITENTITY',
  UPDATE_AUDITENTITY: 'auditEntity/UPDATE_AUDITENTITY',
  DELETE_AUDITENTITY: 'auditEntity/DELETE_AUDITENTITY',
  SET_BLOB: 'auditEntity/SET_BLOB',
  RESET: 'auditEntity/RESET',
  SHOW_MODAL: 'auditEntity/SHOW_MODAL',
};

const initialState = {
  loading: false,
  openModal: {
    list: false,
    view: false,
    detail: false,
  },
  errorMessage: null,
  entities: [] as ReadonlyArray<IAuditEntity>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  ...initialStateExtended,
};

export type AuditEntityState = Readonly<typeof initialState>;

export interface IAuditEntityBaseState {
  baseFilters: any;
  entityId: any;
  entityType: any;
  action: any;
  entityValue: any;
  commitVersion: any;
}

export interface IAuditEntityUpdateState {
  fieldsBase: IAuditEntityBaseState;
  entityIdSelectValue?: any;
  entityTypeSelectValue?: any;
  actionSelectValue?: any;
  entityValueSelectValue?: any;
  commitVersionSelectValue?: any;

  isNew: boolean;
}

// Reducer

export default (state: AuditEntityState = initialState, action): AuditEntityState => {
  const switchExtended = SwitchExtended(state, action);
  if (switchExtended !== null) return switchExtended;

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_AUDITENTITY_LIST_EXPORT):
    case REQUEST(ACTION_TYPES.FETCH_AUDITENTITY_LIST):
    case REQUEST(ACTION_TYPES.FETCH_AUDITENTITY):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_AUDITENTITY):
    case REQUEST(ACTION_TYPES.UPDATE_AUDITENTITY):
    case REQUEST(ACTION_TYPES.DELETE_AUDITENTITY):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_AUDITENTITY_LIST_EXPORT):
    case FAILURE(ACTION_TYPES.FETCH_AUDITENTITY_LIST):
    case FAILURE(ACTION_TYPES.FETCH_AUDITENTITY):
    case FAILURE(ACTION_TYPES.CREATE_AUDITENTITY):
    case FAILURE(ACTION_TYPES.UPDATE_AUDITENTITY):
    case FAILURE(ACTION_TYPES.DELETE_AUDITENTITY):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_AUDITENTITY_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_AUDITENTITY):
      action.payload.data.entityValue = action.payload.data.entityValue
        ? Buffer.from(action.payload.data.entityValue).toString()
        : action.payload.data.entityValue;
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_AUDITENTITY):
    case SUCCESS(ACTION_TYPES.UPDATE_AUDITENTITY):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_AUDITENTITY):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.SET_BLOB: {
      const { name, data, contentType, fileName } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [name + 'Base64']: data,
          [name + 'ContentType']: contentType,
          [name + 'FileName']: fileName,
        },
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    case ACTION_TYPES.SHOW_MODAL: {
      const openModal = state.openModal;
      openModal[action.payload.type] = action.payload.show;
      return {
        ...state,
        openModal,
      };
    }
    default:
      return state;
  }
};

const apiUrl = 'api/audit-entities';

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

// Actions

// Actions
export type ICrudGetAllActionAuditEntity<T> = (
  entityId?: any,
  entityType?: any,
  action?: any,
  entityValue?: any,
  entityKeyDiff?: any,
  commitVersion?: any,
  page?: number,
  size?: number,
  sort?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getAllEntities = (filters?, sort?) => {
  const entityIdRequest =
    filters && typeof filters['entityId'] !== 'undefined' && filters['entityId'] ? `entityId.contains=${filters['entityId']}&` : '';
  const entityTypeRequest =
    filters && typeof filters['entityType'] !== 'undefined' && filters['entityType'] ? `entityType.contains=${filters['entityType']}&` : '';
  const actionRequest =
    filters && typeof filters['action'] !== 'undefined' && filters['action'] ? `action.contains=${filters['action']}&` : '';
  const entityValueRequest =
    filters && typeof filters['entityValue'] !== 'undefined' && filters['entityValue']
      ? `entityValue.contains=${filters['entityValue']}&`
      : '';
  const commitVersionRequest =
    filters && typeof filters['commitVersion'] !== 'undefined' && filters['commitVersion']
      ? `commitVersion.contains=${filters['commitVersion']}&`
      : '';

  const requestUrl = `${apiUrl}${`?page=${0}&size=${1000000}&sort=${sort ? sort : 'id,asc'}&`}`;
  return {
    type: ACTION_TYPES.FETCH_AUDITENTITY_LIST,
    payload: axios.get<IAuditEntity>(
      `${requestUrl}${entityIdRequest}${entityTypeRequest}${actionRequest}${entityValueRequest}${commitVersionRequest}`
    ),
  };
};

export const getEntities: ICrudGetAllActionAuditEntity<IAuditEntity> = (
  entityId,
  entityType,
  action,
  entityValue,
  commitVersion,
  entityKeyDiff,
  page,
  size,
  sort
) => {
  const entityIdRequest = entityId ? `entityId.equals=${entityId}&` : '';
  const entityTypeRequest = entityType ? `entityType.equals=${entityType}&` : '';
  const actionRequest = action ? `action.equals=${action}&` : '';
  const entityValueRequest = entityValue ? `entityValue.contains=${entityValue}&` : '';
  const commitVersionRequest = commitVersion ? `commitVersion.contains=${commitVersion}&` : '';
  const entityKeyDiffRequest = entityKeyDiff ? `entityKeyDiff.contains=${entityKeyDiff}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_AUDITENTITY_LIST,
    payload: axios.get<IAuditEntity>(
      `${requestUrl}${entityIdRequest}${entityTypeRequest}${actionRequest}${entityValueRequest}${commitVersionRequest}${entityKeyDiffRequest}`
    ),
  };
};
export const getEntity: ICrudGetAction<IAuditEntity> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_AUDITENTITY,
    payload: axios.get<IAuditEntity>(requestUrl),
  };
};

export const getEntitiesExport: ICrudGetAllActionAuditEntity<IAuditEntity> = (
  entityId,
  entityType,
  action,
  entityValue,
  commitVersion,
  page,
  size,
  sort
) => {
  const entityIdRequest = entityId ? `entityId.contains=${entityId}&` : '';
  const entityTypeRequest = entityType ? `entityType.contains=${entityType}&` : '';
  const actionRequest = action ? `action.contains=${action}&` : '';
  const entityValueRequest = entityValue ? `entityValue.contains=${entityValue}&` : '';
  const commitVersionRequest = commitVersion ? `commitVersion.contains=${commitVersion}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_AUDITENTITY_LIST,
    payload: axios.get<IAuditEntity>(
      `${requestUrl}${entityIdRequest}${entityTypeRequest}${actionRequest}${entityValueRequest}${commitVersionRequest}`
    ),
  };
};

export const createEntity: ICrudPutAction<IAuditEntity> = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_AUDITENTITY,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  if (listFiltersPage !== false && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const updateEntity: ICrudPutAction<IAuditEntity> = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_AUDITENTITY,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  if (listFiltersPage !== false && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const deleteEntity: ICrudDeleteAction<IAuditEntity> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_AUDITENTITY,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const setBlob = (name, data, contentType?, fileName?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType,
    fileName,
  },
});

export const showModal = view => ({
  payload: { type: view, show: true },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const hideModal = view => ({
  payload: { type: view, show: false },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const getAuditEntityState = (location): IAuditEntityBaseState => {
  const url = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  const baseFilters = url.searchParams.get('baseFilters') || '';

  const entityId = url.searchParams.get('entityId') || '';
  const entityType = url.searchParams.get('entityType') || '';
  const action = url.searchParams.get('action') || '';
  const entityValue = url.searchParams.get('entityValue') || '';
  const commitVersion = url.searchParams.get('commitVersion') || '';
  return {
    baseFilters,
    entityId,
    entityType,
    action,
    entityValue,
    commitVersion,
  };
};

export const getAuditEntityFiltersURL = (state, offset = null) => {
  return (
    'baseFilters=' +
    state.baseFilters +
    '&page=' +
    state.activePage +
    '&' +
    'size=' +
    state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    state.sort +
    ',' +
    state.order +
    '&' +
    'entityId=' +
    state.entityId +
    '&' +
    'entityType=' +
    state.entityType +
    '&' +
    'action=' +
    state.action +
    '&' +
    'entityValue=' +
    state.entityValue +
    '&' +
    'commitVersion=' +
    state.commitVersion +
    '&' +
    ''
  );
};
