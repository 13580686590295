/* eslint complexity: ["error", 500] */
/* eslint-disable no-console */

import React from 'react';
import axios from 'axios';

import { convertDateTimeToServer } from 'app/shared/util/date-utils';
import { getFormFromSelect, sleepFunction } from 'app/shared/util/entity-utils';
import { toast } from 'react-toastify';
import FrequencyDescriptionTable from '../components/FrequencyDescriptionTable';
import { connect } from 'react-redux';

import { TextFormat } from 'react-jhipster';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, Label, Table } from 'reactstrap';

import { Translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import {
  getEntity as getPatientDoctorPrescription,
  reset as resetPatientDoctorPrescription,
} from 'app/entities/patient-doctor-prescription/patient-doctor-prescription.reducer';

import {
  getEntities as getProcediments,
  updateEntity as updateProcediment,
} from 'app/entities/pat-dr-prescr-procd/pat-dr-prescr-procd.reducer.ts';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

import { PatDrPrescrProcd, mapStateToProps, mapDispatchToProps } from '../pat-dr-prescr-procd';
import { IRootState } from 'app/shared/reducers';
import Select from 'react-select';
import { translate } from 'react-jhipster';
import { APP_DATE_FORMAT, BASE_API_VERSION_PATH } from 'app/config/constants';
import moment from 'moment';
import { getValueRecursive } from 'app/shared/util/entity-utils';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { Status } from 'app/shared/model/enumerations/status.model';
import { convertDateFromServer } from 'app/shared/util/date-utils';

export interface IExtendedState {
  showModalStatus?: any;
  observationProcedimentStatusSelectValue?: any;
  statusProcedimentStatusSelectValue?: any;
}
export interface IExtendedProps {
  entityEmbebedPai?: any;
  setDataInicial?: Function;
  setDataFinal?: Function;
  dataInicial?: any;
  dataFinal?: any;
  updating?: any;
}

export class PatDrPrescrProcdExtended extends PatDrPrescrProcd {
  renderHeader() {
    return <> {super.renderHeader()} </>;
  }

  saveEntity = (event: any, errors: any, values: any, isNew: any) => {
    const selectFieldsList = ['order', 'medicineMaterial.name', 'frequency', 'observations', 'status', 'patientDoctorPrescription.id'];

    values.initialDate = convertDateTimeToServer(values.initialDate);
    values.finalDate = convertDateTimeToServer(values.finalDate);

    if (errors.length === 0) {
      const { patDrPrescrProcdEntity } = this.props;
      const _entity = {
        ...patDrPrescrProcdEntity,
        // ...values,

        medicineMaterial: getFormFromSelect(this.state.medicineMaterialSelectValue, 'many-to-one'), // many-to-one - otherSide

        patientDoctorPrescription: getFormFromSelect(this.state.patientDoctorPrescriptionSelectValue, 'many-to-one'), // many-to-one - otherSide

        order: this.state.orderSelectValue,
        initialHour: this.state.initialHourSelectValue,
        frequency: this.state.frequencySelectValue === null ? null : this.state.frequencySelectValue,
        frequencyDescription: this.state.frequencyDescriptionSelectValue,
        initialDate: this.state.initialDateSelectValue,
        finalDate: this.state.finalDateSelectValue,
        continuous: this.state.continuousSelectValue === null ? false : this.state.continuousSelectValue,
        dose: this.state.doseSelectValue,
        via: this.state.viaSelectValue === null ? null : this.state.viaSelectValue,
        observations: this.state.observationsSelectValue,
        status: this.state.statusSelectValue === null ? 'INATIVADO' : this.state.statusSelectValue,
        otherMedicine: this.state.otherMedicineSelectValue === null ? false : this.state.otherMedicineSelectValue,
        medicineName: this.state.medicineNameSelectValue,
      };
      const entity = _entity;

      const {
        createdDateStart,
        createdDateEnd,
        lastModifiedDateStart,
        lastModifiedDateEnd,
        status,
        patientDoctorPrescriptionId,
        medicineMaterialId,
        extraFilters,
        activePage,
        itemsPerPage,
        sort,
        order,
      } = this.state.fieldsBase;

      console.info(this.props.updating);
      if (isNew) {
        new Promise(resolve => {
          resolve(this.props.createEntity(entity, { reloadList: false }));
        }).then(async resultEntity => {
          await sleepFunction(3);
          this.props.getPatientDoctorPrescription(this.props.match.params['idPatientDoctorPrescription']);
        });
      } else {
        new Promise(resolve => {
          resolve(this.props.updateEntity(entity, { reloadList: false }));
        }).then(async resultEntity => {
          console.info(this.props.updating);
          await sleepFunction(3000);
          console.info(this.props.updating);
          this.props.getPatientDoctorPrescription(this.props.match.params['idPatientDoctorPrescription']);
        });
      }
      this.setState({ showModalForm: false });
      this.setState({ showModalEdit: null });
    } else {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.specialty.' + errors[0]) }));
    }
  };

  renderFilter() {
    return (
      <>
        <Col md="12">
          <Row>
            {this.state.baseFilters !== 'createdDate' ? (
              <Col md="3">
                <Row className="mr-1 mt-1">
                  <Label id="createdDateStart" for="pat-dr-prescr-procd-createdDate_start">
                    <Translate contentKey="generadorApp.patDrPrescrProcd.createdDate_start">Created Date (start)</Translate>
                  </Label>
                  <DatePicker
                    onChange={value => this.props.setDataInicial(moment(value))}
                    id="createdDateStart"
                    className="form-control react-datepicker"
                    name="createdDateStart"
                    dateFormat="dd/MM/yyyy"
                    maxDate={this.props.dataFinal ? moment(this.props.dataFinal).toDate() : null}
                    selected={this.props.dataInicial ? moment(this.props.dataInicial).toDate() : null}
                    locale="pt"
                  />
                </Row>
              </Col>
            ) : null}
            {this.state.baseFilters !== 'createdDate' ? (
              <Col md="3">
                <Row className="mr-1 mt-1">
                  <Label id="createdDateEnd" for="pat-dr-prescr-procd-createdDate_end">
                    <Translate contentKey="generadorApp.patDrPrescrProcd.createdDate_end">Created Date (end)</Translate>
                  </Label>
                  <DatePicker
                    onChange={value => this.props.setDataFinal(moment(value))}
                    id="createdDateEnd"
                    className="form-control react-datepicker"
                    name="createdDateEnd"
                    dateFormat="dd/MM/yyyy"
                    minDate={this.props.dataInicial ? moment(this.props.dataInicial).toDate() : null}
                    selected={this.props.dataFinal ? moment(this.props.dataFinal).toDate() : null}
                    locale="pt"
                  />
                </Row>
              </Col>
            ) : null}
          </Row>
        </Col>
        <br />
      </>
    );
  }

  renderTable() {
    const { drStatusHistories, patientDoctorPrescriptions, medicineMaterials, patDrPrescrProcdList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
      {}
        {patDrPrescrProcdList &&
        patDrPrescrProcdList.filter(
          v =>
            (typeof v.deletedAt === 'undefined' || v.deletedAt === null)
            // moment(v.initialDate).toDate() >= moment(this.props.dataInicial).toDate() &&
            // (moment(v.finalDate).toDate() <= moment(this.props.dataFinal).toDate() || v.continuous)
        ).length > 0 ? (
          <>
            <Table
              id="pat-dr-prescr-procd-table-list"
              responsive
              aria-describedby="pat-dr-prescr-procd-heading"
              className={'table-hover table-striped table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand">
                    <Translate contentKey="generadorApp.patDrPrescrProcd.field_id">ID</Translate>
                  </th>
                  {state.baseFilters !== 'order' ? (
                    <th className="hand">
                      <Translate contentKey="generadorApp.patDrPrescrProcd.order">Order</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'medicineMaterial' ? (
                    <th>
                      <Translate contentKey="generadorApp.patDrPrescrProcd.medicineMaterial">Medicine Material</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'frequency' ? (
                    <th className="hand">
                      <Translate contentKey="generadorApp.patDrPrescrProcd.frequency">Frequency</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'FrequencyDescriptionTable' ? (
                    <th>
                      <Translate contentKey="generadorApp.patDrPrescrProcd.FrequencyDescriptionTable">FrequencyDescriptionTable</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'initial_date' ? (
                    <th className="hand">
                      <Translate contentKey="generadorApp.patDrPrescrProcd.initialDate">Initial date</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'final_date' ? (
                    <th className="hand">
                      <Translate contentKey="generadorApp.patDrPrescrProcd.finalDate">Final date</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'continuous' ? (
                    <th className="hand">
                      <Translate contentKey="generadorApp.patDrPrescrProcd.continuous">Continuous</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'status' ? (
                    <th className="hand">
                      <Translate contentKey="generadorApp.patDrPrescrProcd.status">Status</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'patientDoctorPrescription' ? (
                    <th>
                      <Translate contentKey="generadorApp.patDrPrescrProcd.patientDoctorPrescription">
                        Patient Doctor Prescription
                      </Translate>
                    </th>
                  ) : null}

                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {patDrPrescrProcdList
                  .filter(
                    v =>
                      (typeof v.deletedAt === 'undefined' || v.deletedAt === null) 
                      // moment(v.initialDate).toDate() >= moment(this.props.dataInicial).toDate() &&
                      // (moment(v.finalDate).toDate() <= moment(this.props.dataFinal).toDate() 
                      // || v.continuous)
                  )
                  .sort((a, b) => a.order - b.order)
                  .sort((a, b) => (b.status === Status.ATIVO ? 1 : 0) - (a.status === Status.ATIVO ? 1 : 0))
                  .map((patDrPrescrProcd, i) => (    
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.patDrPrescrProcd.field_id">ID</Translate>
                        </b>
                        {patDrPrescrProcd.id}
                      </td>

                      {state.baseFilters !== 'order' ? (
                        <td id="order-cell">
                          <b className="visible-xs"> Order </b>

                          {patDrPrescrProcd.order ? patDrPrescrProcd.order : 0}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'medicineMaterial' ? (
                        <td id="medicineMaterial-cell">
                          <b className="visible-xs"> Medicine Material </b>
                          {patDrPrescrProcd ? (
                            <span data-type-rel="2-many-to-one-other-side">
                              {getValueRecursive(patDrPrescrProcd, 'medicineMaterial.name'.split('.'), '')}
                            </span>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'frequency' ? (
                        <td id="frequency-cell">
                          <b className="visible-xs"> Frequency </b>
                          {patDrPrescrProcd.frequency ? (
                            <Translate contentKey={`generadorApp.QuoteFrequency.${patDrPrescrProcd.frequency}`} />
                          ) : (
                            <> </>
                          )}
                        </td>
                      ) : null}
                      {state.baseFilters !== 'FrequencyDescriptionTable' ? (
                        <td>
                          {' '}
                          <FrequencyDescriptionTable
                            baseState={this.state}
                            baseProps={this.props}
                            setState={st => this.setState(st)}
                            entityBase={patDrPrescrProcd}
                          />
                        </td>
                      ) : (
                        <> </>
                      )}

                      {state.baseFilters !== 'initial_date' ? (
                        <td id="initialDate-cell">
                          <b className="visible-xs"> Initial date </b>

                          {convertDateFromServer(patDrPrescrProcd.initialDate)}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'final_date' ? (
                        <td id="finalDate-cell">
                          <b className="visible-xs"> Final date </b>

                          {convertDateFromServer(patDrPrescrProcd.finalDate)}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'continuous' ? (
                        <td id="continuous-cell" className={'text-center'}>
                          <b className="visible-xs"> Continuous </b>

                          <b> {patDrPrescrProcd.continuous ? String.fromCharCode(10003) : ''}</b>
                        </td>
                      ) : null}

                      {state.baseFilters !== 'status' ? (
                        <td id="status-cell">
                          <b className="visible-xs"> Status </b>
                          {patDrPrescrProcd.status ? <Translate contentKey={`generadorApp.Status.${patDrPrescrProcd.status}`} /> : <> </>}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'patientDoctorPrescription' ? (
                        <td id="patientDoctorPrescription-cell">
                          <b className="visible-xs"> Patient Doctor Prescription </b>
                          {patDrPrescrProcd ? (
                            <span data-type-rel="2-many-to-one-other-side">
                              {getValueRecursive(patDrPrescrProcd, 'patientDoctorPrescription.id'.split('.'), '')}
                            </span>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : null}

                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          {hasAnyAuthority(props.userAccount, ['pat-dr-prescr-procd'], 'edit') ? (
                            <Button onClick={() => this.openUpdateModal(patDrPrescrProcd)} color="primary" size="sm">
                              <FontAwesomeIcon icon="pencil-alt" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                          {hasAnyAuthority(props.userAccount, ['pat-dr-prescr-procd'], 'canDelete') ? (
                            <Button onClick={() => this.deleteEntity(patDrPrescrProcd)} color="danger" size="sm">
                              <FontAwesomeIcon icon="trash" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                          <Button
                            onClick={() =>
                              this.setState({
                                statusProcedimentStatusSelectValue: patDrPrescrProcd.status,
                                showModalStatus: patDrPrescrProcd,
                              })
                            }
                            color="info"
                            size="sm"
                          >
                            <FontAwesomeIcon icon="check-square" />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  )
                  )}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.patDrPrescrProcd.home.notFound">No Pat Dr Prescr Procds found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    return <> {super.renderFooter()} </>;
  }

  renderStatusModal() {
    const entityProcediment = this.state.showModalStatus;
    const { loading } = this.props;
    const drStatusHistoryList = entityProcediment
      ? entityProcediment.drStatusHistory
          .filter(v => v.deletedAt === null)
          .sort((a, b) => {
            return moment(b.createdDate).unix() - moment(a.createdDate).unix();
          })
      : [];

    return (
      <>
        <Modal isOpen={this.state.showModalStatus !== undefined} size={'lg'} toggle={() => this.setState({ showModalStatus: undefined })}>
          <ModalHeader toggle={() => this.setState({ showModalStatus: undefined })}>
            <Translate contentKey="generadorApp.patDrPrescrProcd.home.editLabel">Edit a PatientDoctorPrescription</Translate>
          </ModalHeader>
          <ModalBody id="generadorApp.patDrPrescrProcd.delete.question">
            <Row className="justify-content-center">
              <Col md="11">
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <div>
                    <Row>
                      <Col md="12">
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="statusLabel" for="nr-status-history-status">
                              <Translate contentKey="generadorApp.drStatusHistory.status">Status</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="nr-status-history-status"
                              className={'css-select-control'}
                              value={{
                                value: this.state.statusProcedimentStatusSelectValue,
                                label: translate('generadorApp.Status.' + this.state.statusProcedimentStatusSelectValue),
                              }}
                              options={[
                                { value: 'ATIVO', label: translate('generadorApp.Status.ATIVO') },
                                { value: 'INATIVADO', label: translate('generadorApp.Status.INATIVADO') },
                              ]}
                              onChange={(options: any) => this.setState({ statusProcedimentStatusSelectValue: options['value'] })}
                              name="status"
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col md="12">
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="observationLabel" for="nr-status-history-observation">
                              <Translate contentKey="generadorApp.drStatusHistory.observation">Observation</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ observationProcedimentStatusSelectValue: evt.target.value })}
                              value={this.state.observationProcedimentStatusSelectValue}
                              id="nr-status-history-observation"
                              type="text"
                              name="observation"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col md="12">
                        <br />
                        <Button
                          className="pull-right"
                          color="primary"
                          onClick={async () => {
                            const result = await axios.post(BASE_API_VERSION_PATH + 'dr-status-histories', {
                              observation: this.state.observationProcedimentStatusSelectValue,
                              status: this.state.statusProcedimentStatusSelectValue,
                              patDrPrescrProcd: { id: entityProcediment.id, status: this.state.statusProcedimentStatusSelectValue },
                            });
                            const result1 = await axios.put(BASE_API_VERSION_PATH + 'pat-dr-prescr-procds', {
                              id: entityProcediment.id,
                              status: this.state.statusProcedimentStatusSelectValue,
                            });
                            const result2 = await axios.put(BASE_API_VERSION_PATH + 'patient-doctor-prescriptions', {
                              id: this.props.match.params['idPatientDoctorPrescription'] * 1,
                            });
                            this.props.resetPatientDoctorPrescription();
                            this.props.getPatientDoctorPrescription(this.props.match.params['idPatientDoctorPrescription']);
                            this.setState({
                              showModalStatus: undefined,
                              observationProcedimentStatusSelectValue: '',
                              statusProcedimentStatusSelectValue: '',
                            });
                          }}
                        >
                          <FontAwesomeIcon icon="save" />
                          &nbsp;
                          <Translate contentKey="generadorApp.patDrPrescrProcd.btnSave">btnSave</Translate>
                        </Button>
                      </Col>
                    </Row>
                    <br />
                    <br />
                    <>
                      {drStatusHistoryList && drStatusHistoryList.length > 0 ? (
                        <Table
                          id="nr-status-history-table-list"
                          responsive
                          aria-describedby="nr-status-history-heading"
                          className={'table-hover table-striped table-responsive-css'}
                        >
                          <thead className={'thead-light'}>
                            <tr>
                              <th>
                                <Translate contentKey="generadorApp.drStatusHistory.status">Status</Translate>
                              </th>
                              <th>
                                <Translate contentKey="generadorApp.drStatusHistory.observation">Observation</Translate>
                              </th>
                              <th>
                                <Translate contentKey="generadorApp.drStatusHistory.createdDate">Created Date</Translate>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr></tr>
                            {drStatusHistoryList
                              .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                              .map((drStatusHistory, i) => (
                                <tr key={`entity-${i}`}>
                                  <td id="status-cell">
                                    <b className="visible-xs"> Status </b>
                                    {drStatusHistory.status ? (
                                      <Translate contentKey={`generadorApp.Status.${drStatusHistory.status}`} />
                                    ) : (
                                      <> </>
                                    )}
                                  </td>
                                  <td id="observation-cell">
                                    <b className="visible-xs"> Observation </b>

                                    {drStatusHistory.observation}
                                  </td>

                                  <td id="createdDate-cell">
                                    <b className="visible-xs"> Created Date </b>
                                    <TextFormat type="date" value={drStatusHistory.createdDate} format={APP_DATE_FORMAT} />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      ) : !this.props.loading ? (
                        <div className="alert alert-warning">
                          <Translate contentKey="generadorApp.drStatusHistory.home.notFound">No Status Histories found</Translate>
                        </div>
                      ) : (
                        <div />
                      )}
                    </>
                  </div>
                )}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
      </>
    );
  }

  render() {
    return (
      <>
        {' '}
        {this.renderStatusModal()}
        {super.render()}{' '}
      </>
    );
  }
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
  getPatientDoctorPrescription,
  updateProcediment,
  getProcediments,
  resetPatientDoctorPrescription,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;

export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(PatDrPrescrProcdExtended);
