/* eslint no-console: off */
import React from 'react';
import { Translate, translate } from 'app/config/translate-component';
import SelectAsync from 'react-select/async';
import { AvGroup } from 'availity-reactstrap-validation';

export interface IClComRulerProps {
  baseState?: any;
  baseProps?: any;
  entityBase?: any;
  setState?: any;
}
import { Col, Row, Label } from 'reactstrap';
import { getListAxios } from 'app/shared/util/entity-utils';
import { RenderModalSuperSelect } from 'app/shared/layout/components/RenderModalSuperSelect';
const ClComRulerComponentCustomize = (props: IClComRulerProps) => {
  const { clComRulerList, clComRulerEntity } = props.baseProps;

  if (props.baseState.productServiceSelectValue && props.baseState.productServiceSelectValue.length > 0) {
    const productService = props.baseState.productServiceSelectValue[0];
    const newState = {};
    if (productService.tissCode && !props.baseState.tissCodeSelectValue) {
      newState['tissCodeSelectValue'] = productService.tissCode;
    }
    if (productService.tussCode && !props.baseState.tussCodeSelectValue) {
      newState['tussCodeSelectValue'] = productService.tussCode;
    }
    if (productService.tableCode && !props.baseState.tableCodeSelectValue) {
      newState['tableCodeSelectValue'] = productService.tableCode;
    }
    if (productService.expenseCode && !props.baseState.expenseCodeSelectValue) {
      newState['expenseCodeSelectValue'] = productService.expenseCode;
    }

    if (Object.keys(newState).length > 0) {
      props.setState(newState);
    }
  }

  if (props.baseState.specialtySelectValue && props.baseState.specialtySelectValue.length > 0) {
    const specialty = props.baseState.specialtySelectValue[0];
    const newState = {};
    if (specialty.tissCode && !props.baseState.tissCodeSelectValue) {
      newState['tissCodeSelectValue'] = specialty.tissCode;
    }
    if (specialty.tussCode && !props.baseState.tussCodeSelectValue) {
      newState['tussCodeSelectValue'] = specialty.tussCode;
    }
    if (specialty.tableCode && !props.baseState.tableCodeSelectValue) {
      newState['tableCodeSelectValue'] = specialty.tableCode;
    }
    if (specialty.expenseCode && !props.baseState.expenseCodeSelectValue) {
      newState['expenseCodeSelectValue'] = specialty.expenseCode;
    }

    if (Object.keys(newState).length > 0) {
      props.setState(newState);
    }
  }

  let medicineMaterialInRulersIds = [];
  if (clComRulerList && clComRulerList.length > 0) {
    medicineMaterialInRulersIds = clComRulerList
      .filter(
        v =>
          v.category &&
          v.category.levelGroup &&
          v.category.levelGroup.sub === 'MATERIALMEDICAMENTOEDIETA' &&
          (!clComRulerEntity || !clComRulerEntity.category || v.category.id !== clComRulerEntity.category.id)
      )
      .map(v => v.category.id);
  }
  return (
    <Row>
      <Col md="12">
        <AvGroup>
          <Row>
            <Col md="12">
              <Label className="mt-2 label-single-line" for="cl-com-ruler-category">
                <Translate contentKey="generadorApp.clComRuler.category">Category</Translate>
              </Label>
            </Col>
            <Col md="12">
              <SelectAsync
                id="cl-com-ruler-category"
                name={'category'}
                className={'css-select-control'}
                data-type-rel="many-to-one-other-side"
                value={props.baseState.categorySelectValue}
                onChange={options =>
                  props.setState({
                    categorySelectValue: options,
                    specialtyStartSelectOptions: undefined,
                    productServiceStartSelectOptions: undefined,
                  })
                }
                defaultOptions={props.baseState.categoryStartSelectOptions ? props.baseState.categoryStartSelectOptions : []}
                loadingMessage={input => translate('selectAsync.loadingMessage')}
                noOptionsMessage={input =>
                  props.baseState.categoryStartSelectOptions === undefined
                    ? translate('selectAsync.loadingMessage')
                    : translate('selectAsync.noOptionsMessage')
                }
                onMenuOpen={async () => {
                  if (props.baseState.categoryStartSelectOptions === undefined) {
                    const result = await getListAxios(
                      'categories',
                      {
                        'levelGroup.id.in': props.baseState.levelGroupSelectValue ? props.baseState.levelGroupSelectValue.id : '',
                        'id.notIn': medicineMaterialInRulersIds,
                      },
                      0,
                      100,
                      'levelGroup,asc',
                      'name,levelGroup.id'
                    );
                    props.setState({
                      categoryStartSelectOptions: result.data
                        ? result.data.map(p => ({
                            ...p,
                            value: p.id,
                            label: p['name'] ? p.name : '',
                          }))
                        : [],
                    });
                  }
                }}
                loadOptions={async (inputValue, callback) => {
                  const result = await getListAxios(
                    'categories',
                    {
                      'levelGroup.id.in': props.baseState.levelGroupSelectValue ? props.baseState.levelGroupSelectValue.id : '',
                      'id.notIn': medicineMaterialInRulersIds,
                      'name.contains': inputValue,
                    },
                    0,
                    100,
                    'levelGroup,asc',
                    'name,levelGroup.id'
                  );
                  callback(
                    result.data
                      ? result.data.map(p => ({
                          ...p,
                          value: p.id,
                          label: p['name'] ? p.name : '',
                        }))
                      : []
                  );
                }}
              />
            </Col>
            <RenderModalSuperSelect
              baseState={props.baseState}
              setState={props.setState}
              entity={'category'}
              listEntity={'categories'}
              template={'levelGroup.name;name'.split(';')}
              stateField={'categorySelectValue'}
              multiple={false}
              showFields={'levelGroup.name;name'.split(';')}
              order={'levelGroup,asc'}
              filtersBase={{
                'levelGroup.id.in': props.baseState.levelGroupSelectValue ? props.baseState.levelGroupSelectValue.id : '',
                'id.notIn': medicineMaterialInRulersIds,
              }}
            />
          </Row>
        </AvGroup>
      </Col>
    </Row>
  );
};

const mapStateToProps = storeState => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default ClComRulerComponentCustomize;
