import React from 'react';
import { connect } from 'react-redux';
import { AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Row, Col, Label } from 'reactstrap';
import { Translate } from 'app/config/translate-component';

export interface IPatientProps extends StateProps, DispatchProps {
  setState?: any;
  entityBase?: any;
  baseProps?: any;
  baseState: any;
}

const patientComponentCustomize = (props: IPatientProps) => {
  return (
    <AvGroup>
      <Row>
        <Col md="12">
          <Label className="mt-2 label-single-line" id="birthDateLabel" for="patient-birthDate">
            <Translate contentKey="generadorApp.patient.age">Age</Translate>
          </Label>
        </Col>
        <Col md="12">
          <AvInput
            disabled={true}
            name="_patient-age"
            id="patient-age"
            type="text"
            value={
              props.baseState.birthDateSelectValue
                ? Math.abs(new Date(Date.now() - new Date(props.baseState.birthDateSelectValue).getTime()).getUTCFullYear() - 1970)
                : ''
            }
            className={'form-control'}
          />
        </Col>
      </Row>
    </AvGroup>
  );
};

const mapStateToProps = storeState => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(patientComponentCustomize);
