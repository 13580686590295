import React, { useState } from 'react';
import { Document, Page, StyleSheet, View, Text, PDFViewer } from '@react-pdf/renderer';
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table';
import { convertDateFromServer } from 'app/shared/util/date-utils';

// Create styles
const styles = StyleSheet.create({
  page: {
    paddingTop: 25,
    paddingBottom: 20,
    paddingHorizontal: 20,
  },
  page2: {
    paddingTop: 25,
    paddingBottom: 60,
    paddingHorizontal: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  tableView: {
    border: 0,
    width: '100%',
    flexDirection: 'column',
    paddingVertical: 10,
  },
  tableSummaryView: {
    border: 0,
    width: '100%',
    flexDirection: 'column',
  },
  tableHeaderNumberField: {
    textAlign: 'right',
    border: 0,
    fontSize: 6,
    padding: 1,
  },
  tableHeaderField: {
    border: 0,
    fontSize: 6,
    padding: 1,
  },
  tableNumberField: {
    textAlign: 'right',
    border: 0,
  },
  tableField: {
    border: 0,
  },
});

export const GuiaInternacaoPdfReport = (props: any) => {
  const [ready, setReady] = useState(false);
  const quote = props.data;
  const patient = quote.patient;
  const client = patient.client;
  const userLogged = props.userLogged;

  let totalDiarias = 0.0;
  let totalMateriais = 0.0;
  let totalMedicamentos = 0.0;
  let totalTaxasAlugueis = 0.0;
  let totalGeral = 0.0;
  let totalDiariasDespensas = 0.0;
  let totalGeralDespensas = 0.0;

  const rhPacoteTable = [];
  quote.quoteRh.map(quoteRh => {
    if (quoteRh.billFinalAmount > 0) {
      rhPacoteTable.push({
        dataFim: convertDateFromServer(quote.to),
        tableCode: quoteRh.specialty.tableCode, // 37
        tussCode: quoteRh.specialty.tussCode, // 38
        descricao: quoteRh.specialty.name,
        quantidade: quoteRh.billQuantityItem,
        redAcresc: '1,00',
        unitario: quoteRh.unitaryAmount,
        total: (quoteRh.billFinalAmount * 1).toFixed(2).replace('.', ','),
      });
      if (quoteRh.specialty.expenseCode === '05') {
        totalDiarias += quoteRh.billFinalAmount * 1;
      }
      totalGeral += quoteRh.billFinalAmount * 1;
    }
  });

  quote.quotePackage.map(quotePackage => {
    if (quotePackage.billFinalAmount > 0) {
      rhPacoteTable.push({
        dataFim: convertDateFromServer(quote.to),
        tableCode: quotePackage.clComTable?.tableCode, // 37
        tussCode: quotePackage.clComTable?.tussCode, // 38
        descricao: quotePackage.clComTable?.name,
        quantidade: quotePackage.days,
        redAcresc: '1,00',
        unitario: quotePackage.grossAmount,
        total: (quotePackage.finalAmount * 1).toFixed(2).replace('.', ','),
      });
      if (quotePackage.clComTable?.expenseCode === '05') {
        totalDiarias += quotePackage.finalAmount * 1;
      }
      totalGeral += quotePackage.billFinalAmount * 1;
    }
  });

  const despensasTable = [];
  quote.quoteMaterial.map(quoteMaterial => {
    if (quoteMaterial.billFinalAmount > 0) {
      let codItem = '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0';
      if (quoteMaterial.medicineMaterial.table === 'BRASINDICE') {
        if (quoteMaterial.medicineMaterial.brasindice?.codeTuss) {
          codItem = quoteMaterial.medicineMaterial.brasindice.codeTuss;
        }
      } else if (quoteMaterial.medicineMaterial.simpro?.codeTuss) {
        codItem = quoteMaterial.medicineMaterial.simpro.codeTuss;
      }
      despensasTable.push({
        codDesp: '03',
        dataFinal: convertDateFromServer(quote.to),
        tabela: '20',
        codItem,
        quantidade: quoteMaterial.billQuantityItem,
        unidadeMedida: 36,
        redAcresc: '1,00',
        unitario: (quoteMaterial.billFinalAmount / quoteMaterial.billQuantityItem).toFixed(2).replace('.', ','),
        total: (quoteMaterial.billFinalAmount * 1).toFixed(2).replace('.', ','),
        desc: quoteMaterial.medicineMaterial.name,
      });
      totalMateriais += quoteMaterial.billFinalAmount * 1;
      totalGeral += quoteMaterial.billFinalAmount * 1;
      totalGeralDespensas += quoteMaterial.billFinalAmount * 1;
      totalDiariasDespensas += quoteMaterial.billFinalAmount * 1;
    }
  });

  quote.quoteMedicine.map(quoteMedicine => {
    if (quoteMedicine.billFinalAmount > 0) {
      let codItem = '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0';
      if (quoteMedicine.medicineMaterial.table === 'BRASINDICE') {
        if (quoteMedicine.medicineMaterial.brasindice?.codeTuss) {
          codItem = quoteMedicine.medicineMaterial.brasindice.codeTuss;
        }
      } else if (quoteMedicine.medicineMaterial.simpro?.codeTuss) {
        codItem = quoteMedicine.medicineMaterial.simpro.codeTuss;
      }
      despensasTable.push({
        codDesp: '02',
        dataFinal: convertDateFromServer(quote.to),
        tabela: '19',
        codItem,
        quantidade: quoteMedicine.billQuantityItem,
        unidadeMedida: 36,
        redAcresc: '1,00',
        unitario: (quoteMedicine.billFinalAmount / quoteMedicine.billQuantityItem).toFixed(2).replace('.', ','),
        total: (quoteMedicine.billFinalAmount * 1).toFixed(2).replace('.', ','),
        desc: quoteMedicine.medicineMaterial.name,
      });
      totalMedicamentos += quoteMedicine.billFinalAmount * 1;
      totalGeral += quoteMedicine.billFinalAmount * 1;
      totalGeralDespensas += quoteMedicine.billFinalAmount * 1;
    }
  });

  quote.quoteDiet.map(quoteDiet => {
    if (quoteDiet.billFinalAmount > 0) {
      let codItem = '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0';
      if (quoteDiet.medicineMaterial.table === 'BRASINDICE') {
        if (quoteDiet.medicineMaterial.brasindice?.codeTuss) {
          codItem = quoteDiet.medicineMaterial.brasindice.codeTuss;
        }
      } else if (quoteDiet.medicineMaterial.simpro?.codeTuss) {
        codItem = quoteDiet.medicineMaterial.simpro.codeTuss;
      }
      despensasTable.push({
        codDesp: '02',
        dataFinal: convertDateFromServer(quote.to),
        tabela: '20',
        codItem,
        quantidade: quoteDiet.medicineMaterial.billQuantityItem,
        unidadeMedida: 36,
        redAcresc: '1,00',
        unitario: (quoteDiet.billFinalAmount / quoteDiet.billQuantityItem).toFixed(2).replace('.', ','),
        total: (quoteDiet.billFinalAmount * 1).toFixed(2).replace('.', ','),
        desc: quoteDiet.medicineMaterial.name,
      });
      totalGeral += quoteDiet.billFinalAmount * 1;
      totalGeralDespensas += quoteDiet.billFinalAmount * 1;
    }
  });

  quote.quoteProductService.map(quoteProductService => {
    if (quoteProductService.billFinalAmount > 0) {
      despensasTable.push({
        codDesp: quoteProductService.productService?.expenseCode
          ? quoteProductService.productService?.expenseCode
          : '\u00A0\u00A0\u00A0\u00A0',
        dataFinal: convertDateFromServer(quote.to),
        tabela: quoteProductService.productService?.tableCode,
        codItem: quoteProductService.productService.tussCode
          ? quoteProductService.productService.tussCode
          : '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
        quantidade: quoteProductService.days,
        unidadeMedida: 36,
        redAcresc: '1,00',
        unitario: (quoteProductService.billFinalAmount / quoteProductService.billQuantityItem).toFixed(2).replace('.', ','),
        total: (quoteProductService.billFinalAmount * 1).toFixed(2).replace('.', ','),
        desc: quoteProductService.productService.name,
      });
      if (quoteProductService.productService?.expenseCode === '05') {
        totalDiarias += quoteProductService.billFinalAmount * 1;
      }
      if (quoteProductService.frequency === 'LOCACAODIARIA') {
        totalTaxasAlugueis += quoteProductService.billFinalAmount * 1;
      }
      if (quoteProductService.productService?.expenseCode === '05') {
        totalDiariasDespensas += quoteProductService.billFinalAmount * 1;
      }
      totalGeral += quoteProductService.billFinalAmount * 1;
      totalGeralDespensas += quoteProductService.billFinalAmount * 1;
    }
  });

  if (ready === false) {
    setTimeout(() => {
      setReady(true);
    }, 2);
    return <></>;
  }

  return (
    <PDFViewer style={{ width: '100%', minHeight: '400px' }}>
      <Document>
        <Page size="A4" key="" orientation="landscape" style={styles.page}>
          <View style={{ width: '100%', flexDirection: 'row', marginBottom: 10 }}>
            <Text style={{ fontSize: 11, width: '40%' }}>{client.fantasyName}</Text>
            <Text style={{ fontSize: 11, width: '30%' }}>GUIA DE RESUMO DE INTERNAÇÃO</Text>
            <Text style={{ fontSize: 10, width: '30%', textAlign: 'right' }}>2 - Nº da Guia do Prestador: {quote.accountNumber}</Text>
          </View>

          <View style={{ width: '100%', flexDirection: 'row', marginBottom: '3px' }}>
            <View style={{ width: '12%', minHeight: 20, border: 1, padding: 1 }}>
              <Text style={{ fontSize: 6, paddingVertical: 1 }}>1 - Registro ANS:</Text>
              <Text style={{ fontSize: 10, marginTop: 3, textAlign: 'center' }}>{client.codAns}</Text>
            </View>
            <View style={{ width: '20%', minHeight: 20, border: 1, padding: 1, marginLeft: 4 }}>
              <Text style={{ fontSize: 6 }}>3 - Nº da Guia Principal</Text>
            </View>
            <View style={{ width: '10.5%', minHeight: 20, border: 1, padding: 1, marginLeft: 4 }}>
              <Text style={{ fontSize: 6 }}>4 - Data da Autorização</Text>
              <Text style={{ fontSize: 10, marginTop: 3, marginLeft: 10, textAlign: 'left' }}>{convertDateFromServer(quote.from)}</Text>
            </View>
            <View style={{ width: '20%', minHeight: 20, border: 1, padding: 1, marginLeft: 4 }}>
              <Text style={{ fontSize: 6 }}>5 - Senha</Text>
              <Text style={{ fontSize: 10, marginTop: 3, textAlign: 'center' }}>{quote.billingPasswordNumber}</Text>
            </View>
            <View style={{ width: '15%', minHeight: 20, border: 1, padding: 1, marginLeft: 4 }}>
              <Text style={{ fontSize: 6 }}>6 - Data Validade da Senha</Text>
              <Text style={{ fontSize: 10, marginTop: 3, marginLeft: 10, textAlign: 'left' }}>{quote.billingPasswordExpiry}</Text>
            </View>
            <View style={{ width: '20%', minHeight: 20, border: 1, padding: 1, marginLeft: 4 }}>
              <Text style={{ fontSize: 6 }}>7 - Nº da Guia atribuído pela Operadora</Text>
              <Text style={{ fontSize: 10, marginTop: 3, marginLeft: 10, textAlign: 'left' }}>{quote.accountNumber}</Text>
            </View>
          </View>

          <View style={{ width: '100%', minHeight: 10, marginBottom: '3px', backgroundColor: '#aaaaaa' }}>
            <Text style={{ fontSize: 8, marginLeft: '3px' }}>Dados do Beneficiário</Text>
          </View>

          <View style={{ width: '100%', flexDirection: 'row', marginBottom: '3px' }}>
            <View style={{ width: '15%', minHeight: 20, border: 1, padding: 1 }}>
              <Text style={{ fontSize: 6 }}>8 - Nº da Carteira</Text>
              <Text style={{ fontSize: 10, marginTop: 3, marginLeft: 10, textAlign: 'left' }}>{patient.healthPlanEnrollment}</Text>
            </View>
            <View style={{ width: '15%', minHeight: 20, border: 1, padding: 1, marginLeft: 4 }}>
              <Text style={{ fontSize: 6 }}>9 - Validade da Carteira</Text>
            </View>
            <View style={{ width: '33%', minHeight: 20, border: 1, padding: 1, marginLeft: 4 }}>
              <Text style={{ fontSize: 6 }}>10 - Nome</Text>
              <Text style={{ fontSize: 10, marginTop: 3, marginLeft: 10, textAlign: 'left' }}>{patient.name}</Text>
            </View>
            <View style={{ width: '20%', minHeight: 20, border: 1, padding: 1, marginLeft: 4 }}>
              <Text style={{ fontSize: 6 }}>11 - Cartão Nacional Saúde</Text>
            </View>
            <View style={{ width: '15%', minHeight: 20, border: 1, padding: 1, marginLeft: 4 }}>
              <Text style={{ fontSize: 6 }}>12 - Atendimento a RN</Text>
              <Text style={{ fontSize: 10, marginTop: 3, textAlign: 'center' }}>N</Text>
            </View>
          </View>

          <View style={{ width: '100%', minHeight: 10, marginBottom: '3px', backgroundColor: '#aaaaaa' }}>
            <Text style={{ fontSize: 8, marginLeft: '3px' }}>Dados do Solicitante</Text>
          </View>

          <View style={{ width: '100%', flexDirection: 'row', marginBottom: '3px' }}>
            <View style={{ width: '20%', minHeight: 20, border: 1, padding: 1 }}>
              <Text style={{ fontSize: 6 }}>13 - Código na Operadora</Text>
              <Text style={{ fontSize: 10, marginTop: 3, marginLeft: 10, textAlign: 'left' }}>{client.codEmpNoProvedor}</Text>
            </View>
            <View style={{ width: '58%', minHeight: 20, border: 1, padding: 1, marginLeft: 8 }}>
              <Text style={{ fontSize: 6 }}>14 - Nome do Contratado</Text>
              <Text style={{ fontSize: 10, marginTop: 3, marginLeft: 10, textAlign: 'left' }}>{userLogged.whiteLabel.fantasyName}</Text>
            </View>
            <View style={{ width: '20%', minHeight: 20, border: 1, padding: 1, marginLeft: 8 }}>
              <Text style={{ fontSize: 6 }}>15 - Código CNES</Text>
              <Text style={{ fontSize: 10, marginTop: 3, textAlign: 'center' }}>{userLogged.whiteLabel.regCNES}</Text>
            </View>
          </View>

          <View style={{ width: '100%', flexDirection: 'row', marginBottom: '3px' }}>
            <View style={{ width: '12%', minHeight: 20, border: 1, padding: 1 }}>
              <Text style={{ fontSize: 6 }}>16 - Caráter do Atendimento</Text>
              <Text style={{ fontSize: 10, marginTop: 3, textAlign: 'center' }}>1</Text>
            </View>
            <View style={{ width: '12%', minHeight: 20, border: 1, padding: 1, marginLeft: 4 }}>
              <Text style={{ fontSize: 6 }}>17 - Tipo de Faturamento</Text>
              <Text style={{ fontSize: 10, marginTop: 3, textAlign: 'center' }}>4</Text>
            </View>
            <View style={{ width: '12.5%', minHeight: 20, border: 1, padding: 1, marginLeft: 4 }}>
              <Text style={{ fontSize: 6 }}>18 - Data Início Faturamento</Text>
              <Text style={{ fontSize: 10, marginTop: 3, marginLeft: 10, textAlign: 'left' }}>{convertDateFromServer(quote.from)}</Text>
            </View>
            <View style={{ width: '12%', minHeight: 20, border: 1, padding: 1, marginLeft: 4 }}>
              <Text style={{ fontSize: 6 }}>19 - Hora Início Faturamento</Text>
              <Text style={{ fontSize: 10, marginTop: 3, textAlign: 'center' }}>00:01</Text>
            </View>
            <View style={{ width: '12%', minHeight: 20, border: 1, padding: 1, marginLeft: 4 }}>
              <Text style={{ fontSize: 6 }}>20 - Data Fim Faturamento</Text>
              <Text style={{ fontSize: 10, marginTop: 3, marginLeft: 10, textAlign: 'left' }}>{convertDateFromServer(quote.to)}</Text>
            </View>
            <View style={{ width: '12%', minHeight: 20, border: 1, padding: 1, marginLeft: 4 }}>
              <Text style={{ fontSize: 6 }}>21 - Hora Fim Faturamento</Text>
              <Text style={{ fontSize: 10, marginTop: 3, textAlign: 'center' }}>23:59</Text>
            </View>
            <View style={{ width: '12%', minHeight: 20, border: 1, padding: 1, marginLeft: 4 }}>
              <Text style={{ fontSize: 6 }}>22 - Tipo de Internação</Text>
              <Text style={{ fontSize: 10, marginTop: 3, textAlign: 'center' }}>1</Text>
            </View>
            <View style={{ width: '12%', minHeight: 20, border: 1, padding: 1, marginLeft: 4 }}>
              <Text style={{ fontSize: 6 }}>23 - Regime de Internação</Text>
              <Text style={{ fontSize: 10, marginTop: 3, textAlign: 'center' }}>3</Text>
            </View>
          </View>

          <View style={{ width: '100%', flexDirection: 'row', marginBottom: '3px' }}>
            <View
              style={{
                width: '9.5%',
                flexDirection: 'row',
                minHeight: 20,
                border: 1,
                padding: 1,
                backgroundColor: '#aaaaaa',
              }}
            >
              <Text style={{ fontSize: 6 }}>24 - CID 10 Principal</Text>
            </View>
            <View
              style={{
                width: '9.5%',
                flexDirection: 'row',
                minHeight: 20,
                border: 1,
                padding: 1,
                marginLeft: 4,
                backgroundColor: '#aaaaaa',
              }}
            >
              <Text style={{ fontSize: 6 }}>25 - CID 10 (2)</Text>
            </View>
            <View
              style={{
                width: '9.5%',
                flexDirection: 'row',
                minHeight: 20,
                border: 1,
                padding: 1,
                marginLeft: 4,
                backgroundColor: '#aaaaaa',
              }}
            >
              <Text style={{ fontSize: 6 }}>26 - CID 10 (3) </Text>
            </View>
            <View
              style={{
                width: '9.5%',
                flexDirection: 'row',
                minHeight: 20,
                border: 1,
                padding: 1,
                marginLeft: 4,
                backgroundColor: '#aaaaaa',
              }}
            >
              <Text style={{ fontSize: 6 }}>27 - CID 10 (4)</Text>
            </View>
            <View style={{ width: '9.5%', minHeight: 20, border: 1, padding: 1, marginLeft: 4 }}>
              <Text style={{ fontSize: 6 }}>28 - Ind.Acidente</Text>
              <Text style={{ fontSize: 10, marginTop: 3, textAlign: 'center' }}>9</Text>
            </View>
            <View style={{ width: '9.5%', minHeight: 20, border: 1, padding: 1, marginLeft: 4 }}>
              <Text style={{ fontSize: 6 }}>29-Motivo Encerramento da Internação</Text>
              <Text style={{ fontSize: 10, marginRight: 5, textAlign: 'right' }}>21</Text>
            </View>
            <View style={{ width: '9.5%', minHeight: 20, border: 1, padding: 1, marginLeft: 4 }}>
              <Text style={{ fontSize: 6 }}>30 - Nº Decl.Nascido Vivo</Text>
            </View>
            <View
              style={{
                width: '9.5%',
                flexDirection: 'row',
                minHeight: 20,
                border: 1,
                padding: 1,
                marginLeft: 4,
                backgroundColor: '#aaaaaa',
              }}
            >
              <Text style={{ fontSize: 6 }}>31 - CID 10 Óbito</Text>
            </View>
            <View style={{ width: '9.5%', minHeight: 20, border: 1, padding: 1, marginLeft: 4 }}>
              <Text style={{ fontSize: 6 }}>32 - Nº Declaração de Óbito</Text>
            </View>
            <View style={{ width: '10%', minHeight: 20, border: 1, padding: 1, marginLeft: 4 }}>
              <Text style={{ fontSize: 6 }}>33 - Ind. D.O. de RN</Text>
            </View>
          </View>

          <View style={{ width: '100%', minHeight: 10, marginBottom: '3px', backgroundColor: '#aaaaaa' }}>
            <Text style={{ fontSize: 8, marginLeft: '3px' }}>Procedimentos e Exames Realizados</Text>
          </View>

          <Table data={rhPacoteTable}>
            <TableHeader fontSize={10} includeLeftBorder={false} includeRightBorder={false}>
              <TableCell weighting={0.07} includeLeftBorder={false} includeRightBorder={false} style={styles.tableHeaderField}>
                34-Data
              </TableCell>
              <TableCell weighting={0.06} includeLeftBorder={false} includeRightBorder={false} style={styles.tableHeaderField}>
                35-Hora Inicial
              </TableCell>
              <TableCell weighting={0.06} includeLeftBorder={false} includeRightBorder={false} style={styles.tableHeaderField}>
                36-Hora Final
              </TableCell>
              <TableCell weighting={0.05} includeLeftBorder={false} includeRightBorder={false} style={styles.tableHeaderNumberField}>
                37-Tabela
              </TableCell>
              <TableCell weighting={0.1} includeLeftBorder={false} includeRightBorder={false} style={styles.tableHeaderField}>
                38-Cód.Procedmento
              </TableCell>
              <TableCell weighting={0.28} includeLeftBorder={false} includeRightBorder={false} style={styles.tableHeaderField}>
                39-Descrição
              </TableCell>
              <TableCell weighting={0.04} includeLeftBorder={false} includeRightBorder={false} style={styles.tableHeaderNumberField}>
                40-Qtde
              </TableCell>
              <TableCell weighting={0.05} includeLeftBorder={false} includeRightBorder={false} style={styles.tableHeaderNumberField}>
                41-Via
              </TableCell>
              <TableCell weighting={0.05} includeLeftBorder={false} includeRightBorder={false} style={styles.tableHeaderNumberField}>
                42-Téc
              </TableCell>
              <TableCell weighting={0.07} includeLeftBorder={false} includeRightBorder={false} style={styles.tableHeaderNumberField}>
                43-Red./Acresc.
              </TableCell>
              <TableCell weighting={0.06} includeLeftBorder={false} includeRightBorder={false} style={styles.tableHeaderNumberField}>
                44-Vlr.Uitário
              </TableCell>
              <TableCell weighting={0.08} includeLeftBorder={false} includeRightBorder={false} style={styles.tableHeaderNumberField}>
                45-Vlr.Total R$
              </TableCell>
            </TableHeader>
            <TableBody fontSize={9} includeLeftBorder={false} includeRightBorder={false}>
              <DataTableCell
                style={styles.tableField}
                weighting={0.07}
                includeLeftBorder={false}
                includeRightBorder={false}
                getContent={v => (
                  <View
                    style={{
                      padding: 1,
                      flexGrow: 1,
                    }}
                  >
                    <Text>{v.dataFim}</Text>
                  </View>
                )}
              />
              <DataTableCell
                style={styles.tableField}
                weighting={0.06}
                includeLeftBorder={false}
                includeRightBorder={false}
                getContent={v => (
                  <View
                    style={{
                      padding: 1,
                      flexGrow: 1,
                    }}
                  >
                    <Text> </Text>
                  </View>
                )}
              />
              <DataTableCell
                style={styles.tableField}
                weighting={0.06}
                includeLeftBorder={false}
                includeRightBorder={false}
                getContent={v => (
                  <View
                    style={{
                      padding: 1,
                      flexGrow: 1,
                    }}
                  >
                    <Text> </Text>
                  </View>
                )}
              />
              <DataTableCell
                style={styles.tableNumberField}
                weighting={0.05}
                includeLeftBorder={false}
                includeRightBorder={false}
                getContent={v => (
                  <View
                    style={{
                      padding: 1,
                      flexGrow: 1,
                    }}
                  >
                    <Text>{v.tussCode}</Text>
                  </View>
                )}
              />
              <DataTableCell
                style={styles.tableField}
                weighting={0.1}
                includeLeftBorder={false}
                includeRightBorder={false}
                getContent={v => (
                  <View
                    style={{
                      paddingVertical: 1,
                      paddingLeft: 4,
                      flexGrow: 1,
                    }}
                  >
                    <Text>{v.tableCode}</Text>
                  </View>
                )}
              />
              <DataTableCell
                style={styles.tableField}
                weighting={0.28}
                includeLeftBorder={false}
                includeRightBorder={false}
                getContent={v => (
                  <View
                    style={{
                      padding: 1,
                      flexGrow: 1,
                    }}
                  >
                    <Text>{v.descricao}</Text>
                  </View>
                )}
              />
              <DataTableCell
                style={styles.tableNumberField}
                weighting={0.04}
                includeLeftBorder={false}
                includeRightBorder={false}
                getContent={v => (
                  <View
                    style={{
                      padding: 1,
                      flexGrow: 1,
                    }}
                  >
                    <Text>{v.quantidade}</Text>
                  </View>
                )}
              />
              <DataTableCell
                style={styles.tableNumberField}
                weighting={0.05}
                includeLeftBorder={false}
                includeRightBorder={false}
                getContent={v => (
                  <View
                    style={{
                      padding: 1,
                      flexGrow: 1,
                    }}
                  >
                    <Text> </Text>
                  </View>
                )}
              />
              <DataTableCell
                style={styles.tableNumberField}
                weighting={0.05}
                includeLeftBorder={false}
                includeRightBorder={false}
                getContent={v => (
                  <View
                    style={{
                      padding: 1,
                      flexGrow: 1,
                    }}
                  >
                    <Text> </Text>
                  </View>
                )}
              />
              <DataTableCell
                style={styles.tableNumberField}
                weighting={0.07}
                includeLeftBorder={false}
                includeRightBorder={false}
                getContent={v => (
                  <View
                    style={{
                      padding: 1,
                      flexGrow: 1,
                    }}
                  >
                    <Text>{v.redAcresc}</Text>
                  </View>
                )}
              />
              <DataTableCell
                style={styles.tableNumberField}
                weighting={0.06}
                includeLeftBorder={false}
                includeRightBorder={false}
                getContent={v => (
                  <View
                    style={{
                      padding: 1,
                      flexGrow: 1,
                    }}
                  >
                    <Text>{v.unitario}</Text>
                  </View>
                )}
              />
              <DataTableCell
                style={styles.tableNumberField}
                weighting={0.08}
                includeLeftBorder={false}
                includeRightBorder={false}
                getContent={v => (
                  <View
                    style={{
                      padding: 1,
                      flexGrow: 1,
                    }}
                  >
                    <Text>{v.total}</Text>
                  </View>
                )}
              />
            </TableBody>
          </Table>

          {/* Footer */}
          <View style={{ width: '100%', bottom: 30, position: 'absolute', left: 20, right: 20 }}>
            <View style={{ minHeight: 10, marginBottom: '3px', backgroundColor: '#aaaaaa' }}>
              <Text style={{ fontSize: 8, marginLeft: '3px' }}>Identificação da Equipe</Text>
            </View>

            <View style={{ width: '100%', flexDirection: 'row' }}>
              <View style={{ width: '8%', padding: 1 }}>
                <Text style={{ fontSize: 6 }}>46-Seq.Ref.</Text>
              </View>
              <View style={{ width: '8%', padding: 1, marginLeft: 4 }}>
                <Text style={{ fontSize: 6 }}>47-Grau Part.</Text>
              </View>
              <View style={{ width: '15%', padding: 1, marginLeft: 4 }}>
                <Text style={{ fontSize: 6 }}>48-Cód.na Operadora/CPF</Text>
              </View>
              <View style={{ width: '30%', padding: 1, marginLeft: 4 }}>
                <Text style={{ fontSize: 6 }}>49-Nome do Profissional</Text>
              </View>
              <View style={{ width: '10%', padding: 1, marginLeft: 4 }}>
                <Text style={{ fontSize: 6 }}>50-Conselho Profissional</Text>
              </View>
              <View style={{ width: '10%', padding: 1, marginLeft: 4 }}>
                <Text style={{ fontSize: 6 }}>51-Nº no Conselho</Text>
              </View>
              <View style={{ width: '5%', padding: 1, marginLeft: 4 }}>
                <Text style={{ fontSize: 6 }}>52-UF</Text>
              </View>
              <View style={{ width: '10%', padding: 1, marginLeft: 4 }}>
                <Text style={{ fontSize: 6 }}>53-Cód. CBO</Text>
              </View>
            </View>

            <View style={{ width: '100%', flexDirection: 'row' }}>
              <View
                style={{
                  width: '8%',
                  minHeight: 20,
                  borderBottom: 1,
                  borderColor: '#404040',
                  padding: 1,
                }}
              ></View>
              <View
                style={{
                  width: '8%',
                  minHeight: 20,
                  borderBottom: 1,
                  borderColor: '#404040',
                  padding: 1,
                  marginLeft: 4,
                }}
              ></View>
              <View
                style={{
                  width: '15%',
                  minHeight: 20,
                  borderBottom: 1,
                  borderColor: '#404040',
                  padding: 1,
                  marginLeft: 4,
                }}
              ></View>
              <View
                style={{
                  width: '30%',
                  flexDirection: 'row',
                  minHeight: 20,
                  borderBottom: 1,
                  borderColor: '#404040',
                  padding: 1,
                  marginLeft: 4,
                }}
              ></View>
              <View
                style={{
                  width: '10%',
                  minHeight: 20,
                  borderBottom: 1,
                  borderColor: '#404040',
                  padding: 1,
                  marginLeft: 4,
                }}
              ></View>
              <View
                style={{
                  width: '10%',
                  flexDirection: 'row',
                  minHeight: 20,
                  borderBottom: 1,
                  borderColor: '#404040',
                  padding: 1,
                  marginLeft: 4,
                }}
              ></View>
              <View
                style={{
                  width: '5%',
                  minHeight: 20,
                  borderBottom: 1,
                  borderColor: '#404040',
                  padding: 1,
                  marginLeft: 4,
                }}
              ></View>
              <View
                style={{
                  width: '10%',
                  minHeight: 20,
                  borderBottom: 1,
                  borderColor: '#404040',
                  padding: 1,
                  marginLeft: 4,
                }}
              ></View>
            </View>

            <View style={{ width: '100%', flexDirection: 'row', marginBottom: '5px' }}>
              <View
                style={{
                  width: '8%',
                  minHeight: 20,
                  borderBottom: 1,
                  borderColor: '#404040',
                  padding: 1,
                }}
              ></View>
              <View
                style={{
                  width: '8%',
                  minHeight: 20,
                  borderBottom: 1,
                  borderColor: '#404040',
                  padding: 1,
                  marginLeft: 4,
                }}
              ></View>
              <View
                style={{
                  width: '15%',
                  minHeight: 20,
                  borderBottom: 1,
                  borderColor: '#404040',
                  padding: 1,
                  marginLeft: 4,
                }}
              ></View>
              <View
                style={{
                  width: '30%',
                  minHeight: 20,
                  borderBottom: 1,
                  borderColor: '#404040',
                  padding: 1,
                  marginLeft: 4,
                }}
              ></View>
              <View
                style={{
                  width: '10%',
                  minHeight: 20,
                  borderBottom: 1,
                  borderColor: '#404040',
                  padding: 1,
                  marginLeft: 4,
                }}
              ></View>
              <View
                style={{
                  width: '10%',
                  minHeight: 20,
                  borderBottom: 1,
                  borderColor: '#404040',
                  padding: 1,
                  marginLeft: 4,
                }}
              ></View>
              <View
                style={{
                  width: '5%',
                  minHeight: 20,
                  borderBottom: 1,
                  borderColor: '#404040',
                  padding: 1,
                  marginLeft: 4,
                }}
              ></View>
              <View
                style={{
                  width: '10%',
                  minHeight: 20,
                  borderBottom: 1,
                  borderColor: '#404040',
                  padding: 1,
                  marginLeft: 4,
                }}
              ></View>
            </View>

            <View style={{ width: '100%', flexDirection: 'row', marginBottom: '3px' }}>
              <View style={{ width: '12%', padding: 1, border: 1, minHeight: 20 }}>
                <Text style={{ fontSize: 6 }}>54-Total de Procedimentos R$</Text>
                <Text style={{ fontSize: 10, marginTop: 3, marginRight: 5, textAlign: 'right' }}>{quote.billFinalAmount}</Text>
              </View>
              <View style={{ width: '12%', padding: 1, border: 1, minHeight: 20, marginLeft: 4 }}>
                <Text style={{ fontSize: 6 }}>55-Total de Diárias R$</Text>
                <Text style={{ fontSize: 10, marginTop: 3, marginRight: 5, textAlign: 'right' }}>{totalDiarias}</Text>
              </View>
              <View style={{ width: '12%', padding: 1, border: 1, minHeight: 20, marginLeft: 4 }}>
                <Text style={{ fontSize: 6 }}>56-Total de Taxas e Aluguéis R$</Text>
                <Text style={{ fontSize: 10, marginTop: 3, marginRight: 5, textAlign: 'right' }}>{totalTaxasAlugueis.toFixed(2)}</Text>
              </View>
              <View style={{ width: '12%', padding: 1, border: 1, minHeight: 20, marginLeft: 4 }}>
                <Text style={{ fontSize: 6 }}>57-Total de Materiais R$</Text>
                <Text style={{ fontSize: 10, marginTop: 3, marginRight: 5, textAlign: 'right' }}>{totalMateriais}</Text>
              </View>
              <View style={{ width: '12%', padding: 1, border: 1, minHeight: 20, marginLeft: 4 }}>
                <Text style={{ fontSize: 6 }}>58-Total de OPME R$</Text>
                <Text style={{ fontSize: 10, marginTop: 3, marginRight: 5, textAlign: 'right' }}>0</Text>
              </View>
              <View style={{ width: '12%', padding: 1, border: 1, minHeight: 20, marginLeft: 4 }}>
                <Text style={{ fontSize: 6 }}>59-Total de Medicamentos R$</Text>
                <Text style={{ fontSize: 10, marginTop: 3, marginRight: 5, textAlign: 'right' }}>{totalMedicamentos}</Text>
              </View>
              <View style={{ width: '12%', padding: 1, border: 1, minHeight: 20, marginLeft: 4 }}>
                <Text style={{ fontSize: 6 }}>60-Total de Gases Medicinais R$</Text>
                <Text style={{ fontSize: 10, marginTop: 3, marginRight: 5, textAlign: 'right' }}>0</Text>
              </View>
              <View style={{ width: '12%', padding: 1, border: 1, minHeight: 20, marginLeft: 4 }}>
                <Text style={{ fontSize: 6 }}>61-Total Geral R$</Text>
                <Text style={{ fontSize: 10, marginTop: 3, marginRight: 5, textAlign: 'right' }}>{totalGeral}</Text>
              </View>
            </View>

            <View style={{ width: '100%', flexDirection: 'row', marginBottom: '3px' }}>
              <View style={{ width: '18.5%', flexDirection: 'row', padding: 1, border: 1, minHeight: 20 }}>
                <Text style={{ fontSize: 6 }}>62-Data da Assinatura do Contratado</Text>
              </View>
              <View style={{ width: '40%', flexDirection: 'row', padding: 1, border: 1, minHeight: 20, marginLeft: 4 }}>
                <Text style={{ fontSize: 6 }}>63-Assinatura do Contratado</Text>
              </View>
              <View style={{ width: '40%', flexDirection: 'row', padding: 1, border: 1, minHeight: 20, marginLeft: 4 }}>
                <Text style={{ fontSize: 6 }}>64-Assinatura do(s) Auditor(es) da Operadora</Text>
              </View>
            </View>

            <View style={{ width: '100%', minHeight: 45, marginBottom: '3px', border: 1, backgroundColor: '#aaaaaa' }}>
              <Text style={{ fontSize: 8, marginLeft: '3px' }}>65-Observações / Justificativa</Text>
            </View>
          </View>
        </Page>

        <Page size="A4" orientation="landscape" style={styles.page2}>
          <View style={{ width: '100%', flexDirection: 'row', marginBottom: '3px' }}>
            <View style={{ width: '13%', minHeight: 20, marginBottom: '3px', border: 1 }}>
              <Text style={{ fontSize: 6, marginLeft: '3px' }}>1 - Registro ANS</Text>
              <Text style={{ fontSize: 10, marginTop: 3, textAlign: 'center' }}>{client.codAns}</Text>
            </View>
            <View style={{ width: '13%', minHeight: 20, marginBottom: '3px', border: 1, marginLeft: 4 }}>
              <Text style={{ fontSize: 6, marginLeft: '3px' }}>2 - Nº da Guia de Referencia</Text>
              <Text style={{ fontSize: 10, marginTop: 3, textAlign: 'center' }}>{quote.accountNumber}</Text>
            </View>
            <Text style={{ fontSize: 11, width: '53%', textAlign: 'center' }}>ANEXO DE OUTRAS DESPESAS </Text>
            <Text style={{ fontSize: 11, width: '20%', textAlign: 'right' }}>{client.fantasyName}</Text>
          </View>

          <View style={{ width: '100%', minHeight: 10, marginBottom: '3px', backgroundColor: '#aaaaaa' }}>
            <Text style={{ fontSize: 8, marginLeft: '3px' }}>Dados do Contratado Executante</Text>
          </View>

          <View style={{ width: '100%', flexDirection: 'row', marginBottom: '3px' }}>
            <View style={{ width: '20%', padding: 1, border: 1, minHeight: 20 }}>
              <Text style={{ fontSize: 6 }}>3 - Código na Operadora</Text>
              <Text style={{ fontSize: 10, marginTop: 3, marginLeft: 10, textAlign: 'left' }}>{client.codEmpNoProvedor}</Text>
            </View>
            <View style={{ width: '64%', padding: 1, border: 1, minHeight: 20, marginLeft: 4 }}>
              <Text style={{ fontSize: 6 }}>4 - Nome do Contratado</Text>
              <Text style={{ fontSize: 10, marginTop: 3, marginLeft: 10, textAlign: 'left' }}>{userLogged.whiteLabel.fantasyName}</Text>
            </View>
            <View style={{ width: '15%', padding: 1, border: 1, minHeight: 20, marginLeft: 4 }}>
              <Text style={{ fontSize: 6 }}>5 - Código CNES</Text>
              <Text style={{ fontSize: 10, marginTop: 3, textAlign: 'center' }}>{userLogged.whiteLabel.regCNES}</Text>
            </View>
          </View>

          <View style={{ width: '100%', minHeight: 10, marginBottom: '3px', backgroundColor: '#aaaaaa' }}>
            <Text style={{ fontSize: 8, marginLeft: '3px' }}>Despesas Realizadas</Text>
          </View>

          <Table data={despensasTable}>
            <TableHeader fontSize={10} includeLeftBorder={false} includeRightBorder={false}>
              <TableCell weighting={0.15} includeLeftBorder={false} includeRightBorder={false} style={styles.tableHeaderField}>
                <Text>6-cd 7-Data</Text>
                <Text>17-Registro ANVISA do Material</Text>
              </TableCell>
              <TableCell weighting={0.06} includeLeftBorder={false} includeRightBorder={false} style={styles.tableHeaderField}>
                7-Hora Inicial
              </TableCell>
              <TableCell weighting={0.06} includeLeftBorder={false} includeRightBorder={false} style={styles.tableHeaderField}>
                8-Hora Final
              </TableCell>
              <TableCell weighting={0.06} includeLeftBorder={false} includeRightBorder={false} style={styles.tableHeaderField}>
                10-Tabela
              </TableCell>
              <TableCell weighting={0.31} includeLeftBorder={false} includeRightBorder={false} style={styles.tableHeaderField}>
                <Text>11-Código do Ítem&nbsp;&nbsp;&nbsp;&nbsp;20-Descrição</Text>
                <Text>18-Referencia do Material no Fabricante</Text>
              </TableCell>
              <TableCell weighting={0.05} includeLeftBorder={false} includeRightBorder={false} style={styles.tableHeaderNumberField}>
                12-Qtde
              </TableCell>
              <TableCell weighting={0.06} includeLeftBorder={false} includeRightBorder={false} style={styles.tableHeaderNumberField}>
                13-Unidade de Medida
              </TableCell>
              <TableCell weighting={0.15} includeLeftBorder={false} includeRightBorder={false} style={styles.tableHeaderField}>
                <Text>
                  &nbsp;&nbsp;&nbsp;&nbsp;14-Red./Acresc&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;15-Vlr.Unitário
                </Text>
                <Text>&nbsp;&nbsp;&nbsp;&nbsp;19-Nº Autorização de Funcionamento</Text>
              </TableCell>
              <TableCell weighting={0.1} includeLeftBorder={false} includeRightBorder={false} style={styles.tableHeaderNumberField}>
                16-Vlr.Total R$
              </TableCell>
            </TableHeader>
            <TableBody fontSize={9} includeLeftBorder={false} includeRightBorder={false} includeBottomBorder={true}>
              <DataTableCell
                style={styles.tableField}
                weighting={0.15}
                includeLeftBorder={false}
                includeRightBorder={false}
                getContent={v => (
                  <View
                    style={{
                      padding: 1,
                      flexGrow: 1,
                    }}
                  >
                    <Text>
                      {v.codDesp}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {v.dataFinal}
                    </Text>
                  </View>
                )}
              />
              <DataTableCell
                style={styles.tableField}
                weighting={0.06}
                includeLeftBorder={false}
                includeRightBorder={false}
                getContent={v => (
                  <View
                    style={{
                      padding: 1,
                      flexGrow: 1,
                    }}
                  >
                    <Text> </Text>
                  </View>
                )}
              />
              <DataTableCell
                style={styles.tableField}
                weighting={0.06}
                includeLeftBorder={false}
                includeRightBorder={false}
                getContent={v => (
                  <View
                    style={{
                      padding: 1,
                      flexGrow: 1,
                    }}
                  >
                    <Text> </Text>
                  </View>
                )}
              />
              <DataTableCell
                style={styles.tableField}
                weighting={0.06}
                includeLeftBorder={false}
                includeRightBorder={false}
                getContent={v => (
                  <View
                    style={{
                      padding: 1,
                      flexGrow: 1,
                    }}
                  >
                    <Text>{v.tabela}</Text>
                  </View>
                )}
              />
              <DataTableCell
                style={styles.tableField}
                weighting={0.31}
                includeLeftBorder={false}
                includeRightBorder={false}
                getContent={v => (
                  <View
                    style={{
                      padding: 1,
                      flexGrow: 1,
                    }}
                  >
                    <Text>
                      {v.codItem}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{v.desc}
                    </Text>
                  </View>
                )}
              />
              <DataTableCell
                style={styles.tableNumberField}
                weighting={0.05}
                includeLeftBorder={false}
                includeRightBorder={false}
                getContent={v => (
                  <View
                    style={{
                      padding: 1,
                      flexGrow: 1,
                    }}
                  >
                    <Text>{v.quantidade}</Text>
                  </View>
                )}
              />
              <DataTableCell
                style={styles.tableNumberField}
                weighting={0.06}
                includeLeftBorder={false}
                includeRightBorder={false}
                getContent={v => (
                  <View
                    style={{
                      padding: 1,
                      flexGrow: 1,
                    }}
                  >
                    <Text>{v.unidadeMedida}</Text>
                  </View>
                )}
              />
              <DataTableCell
                style={styles.tableField}
                weighting={0.15}
                includeLeftBorder={false}
                includeRightBorder={false}
                getContent={v => (
                  <View
                    style={{
                      padding: 1,
                      flexGrow: 1,
                    }}
                  >
                    <Text>
                      &nbsp;&nbsp;&nbsp;{v.redAcresc}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{v.unitario}
                    </Text>
                  </View>
                )}
              />
              <DataTableCell
                style={styles.tableNumberField}
                weighting={0.1}
                includeLeftBorder={false}
                includeRightBorder={false}
                getContent={v => (
                  <View
                    style={{
                      padding: 1,
                      flexGrow: 1,
                    }}
                  >
                    <Text>{v.total}</Text>
                  </View>
                )}
              />
            </TableBody>
          </Table>

          {/* Footer */}
          {/* <View style={{ width: '100%', bottom: 30, position: 'absolute', left: 20, right: 20 }}>*/}

          <View
            style={{
              width: '100%',
              bottom: 25,
              flexDirection: 'row',
              position: 'absolute',
              left: 20,
              right: 20,
              borderTop: 1,
              borderBottom: 1,
              maxHeight: 30,
            }}
            wrap={false}
          >
            <View style={{ width: '13.5%', padding: 1, border: 1, minHeight: 20 }}>
              <Text style={{ fontSize: 6 }}>21-Total de Gases Medicinais R$</Text>
              <Text style={{ fontSize: 10, marginTop: 3, marginRight: 5, textAlign: 'right' }}>0</Text>
            </View>
            <View style={{ width: '13.5%', padding: 1, border: 1, minHeight: 20, marginLeft: 4 }}>
              <Text style={{ fontSize: 6 }}>22-Total de Medicamentos R$</Text>
              <Text style={{ fontSize: 10, marginTop: 3, marginRight: 5, textAlign: 'right' }}>{totalMedicamentos}</Text>
            </View>
            <View style={{ width: '14%', padding: 1, border: 1, minHeight: 20, marginLeft: 4 }}>
              <Text style={{ fontSize: 6 }}>23-Total de Materiais R$</Text>
              <Text style={{ fontSize: 10, marginTop: 3, marginRight: 5, textAlign: 'right' }}>{totalMateriais}</Text>
            </View>
            <View style={{ width: '14%', padding: 1, border: 1, minHeight: 20, marginLeft: 4 }}>
              <Text style={{ fontSize: 6 }}>24-Total de OPME R$</Text>
              <Text style={{ fontSize: 10, marginTop: 3, marginRight: 5, textAlign: 'right' }}>0</Text>
            </View>
            <View style={{ width: '14%', padding: 1, border: 1, minHeight: 20, marginLeft: 4 }}>
              <Text style={{ fontSize: 6 }}>25-Total de Taxas e Aluguéis R$</Text>
              <Text style={{ fontSize: 10, marginTop: 3, marginRight: 5, textAlign: 'right' }}>{totalTaxasAlugueis.toFixed(2)}</Text>
            </View>
            <View style={{ width: '14%', padding: 1, border: 1, minHeight: 20, marginLeft: 4 }}>
              <Text style={{ fontSize: 6 }}>26-Total de Diárias R$</Text>
              <Text style={{ fontSize: 10, marginTop: 3, marginRight: 5, textAlign: 'right' }}>{totalDiariasDespensas}</Text>
            </View>
            <View style={{ width: '14%', padding: 1, border: 1, minHeight: 20, marginLeft: 4 }}>
              <Text style={{ fontSize: 6 }}>27-Total Geral R$</Text>
              <Text style={{ fontSize: 10, marginTop: 3, marginRight: 5, textAlign: 'right' }}>{totalGeralDespensas}</Text>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};
