import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import MedicalRecordFile from './medical-record-file';

import MedicalRecordFileUpdate from './medical-record-file';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={MedicalRecordFileUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={MedicalRecordFileUpdate} />
      <ErrorBoundaryRoute path={match.path} component={MedicalRecordFile} />
    </Switch>
  </>
);

export default Routes;
