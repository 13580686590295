import React from 'react';
import { Route } from 'react-router-dom';
import SidebarNavList from 'app/shared/layout/sidebar/sidebar-nav-list';
import menus from 'app/shared/layout/sidebar/menu';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export interface ISidebarNavState {
  active: number;
  clicked: number;
}

export interface ISidebarNavProps {
  userAccount: any;
}

class SidebarNav extends React.Component<ISidebarNavProps, ISidebarNavState> {
  constructor(props) {
    super(props);
    this.state = {
      active: -1,
      clicked: -1,
    };
  }

  handleExpand(e, i, match) {
    e.preventDefault();

    if (this.state.clicked === -1 && match) {
      this.setState({
        active: -1,
        clicked: 1,
      });
    } else {
      this.setState({
        active: this.state.active === i ? -1 : i,
        clicked: 1,
      });
    }
  }

  render() {
    return (
      <ul className="nav">
        {/* <li className="nav-header">Navigation</li> */}
        {menus.map((menu, i) => (
          <Route path={menu.path} key={i}>
            {({ match }) => {
              return (
                <SidebarNavList
                  show={menu.role && menu.role.length > 0 ? hasAnyAuthority(this.props.userAccount, menu.role) : true}
                  data={menu}
                  key={i}
                  expand={e => this.handleExpand(e, i, match)}
                  active={i === this.state.active}
                  clicked={this.state.clicked}
                />
              );
            }}
            
          </Route>
        ))}
      </ul>
    );
  }
}

export default SidebarNav;
