import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { Translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity, IWhiteLabelBaseState, getWhiteLabelState } from './white-label.reducer';

export interface IWhiteLabelState {
  fieldsBase: IWhiteLabelBaseState;
}

export interface IWhiteLabelDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class WhiteLabelDetail extends React.Component<IWhiteLabelDetailProps, IWhiteLabelState> {
  constructor(props: Readonly<IWhiteLabelDetailProps>) {
    super(props);
    this.state = {
      ...this.state,
      fieldsBase: {
        ...getWhiteLabelState(this.props.location),
      },
    };
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = '_back=1' + (offset !== null ? '&offset=' + offset : '');
    Object.keys(fieldsBase).map(key => {
      url += '&' + key + '=' + fieldsBase[key];
    });
    return url;
  };

  UNSAFE_componentWillMount() {
    this.props.getEntity(this.props.match.params['id']);
  }

  renderHeader() {
    const { whiteLabelEntity } = this.props;
    const state = this.state;
    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">White Labels</span>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={'/white-label'}>White Labels</Link>
          </li>
          <li className="breadcrumb-item active">White Labels details</li>
        </ol>
      </>
    );
  }

  renderBody() {
    const { whiteLabelEntity } = this.props;
    const state = this.state;
    return (
      <>
        <Row>
          <Col md="8">
            <h2>
              <Translate contentKey="generadorApp.whiteLabel.detail.title">WhiteLabel</Translate>[<b>{whiteLabelEntity.id}</b>]
            </h2>
            <Row className="jh-entity-details">
              <Col className="view-detail-row company-logo-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top company-logo-detail-label" md="12">
                    <dt>
                      <span id="logo">
                        <Translate contentKey="generadorApp.company.logo">Filename</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top company-logo-detail-value" md="12">
                    <dd>
                      {whiteLabelEntity.logo ? (
                        <div>
                          <a rel="noopener noreferrer" target={'_blank'} href={`${whiteLabelEntity.logo}`}>
                            <img src={`${whiteLabelEntity.logo}`} style={{ maxHeight: '30px' }} />
                          </a>
                          <a rel="noopener noreferrer" target={'_blank'} href={`${whiteLabelEntity.logo}`}>
                            {whiteLabelEntity.logoContentType && whiteLabelEntity.logoContentType.includes('image/') ? (
                              <img src={`${whiteLabelEntity.logo}`} style={{ maxHeight: '30px' }} />
                            ) : (
                              <Translate contentKey="generadorApp.company.btnOpen">Open</Translate>
                            )}
                          </a>
                        </div>
                      ) : null}
                    </dd>
                  </Col>
                </Row>
              </Col>
              <Col md="12">
                <Row>
                  <Col md="3">
                    <dt>
                      <span id="name">
                        <Translate contentKey="generadorApp.whiteLabel.name">Name</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col md="9">
                    <dd>{whiteLabelEntity.name}</dd>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Button tag={Link} to={'/white-label?' + this.getFiltersURL()} replace color="info">
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="generadorApp.whiteLabel.btnBack">Back</Translate>
              </span>
            </Button>
            &nbsp;
            <Button tag={Link} to={`/white-label/${whiteLabelEntity.id}/edit?+${this.getFiltersURL()}`} replace color="primary">
              <FontAwesomeIcon icon="pencil-alt" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="generadorApp.whiteLabel.btnEdit">Edit</Translate>
              </span>
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { whiteLabelEntity } = this.props;
    const state = this.state;
    return (
      <div>
        <Panel>
          <PanelHeader>{this.renderHeader()}</PanelHeader>
          <PanelBody>{this.renderBody()}</PanelBody>
        </Panel>
      </div>
    );
  }
}

export const mapStateToProps = ({ whiteLabel, ...storeState }: IRootState) => ({
  whiteLabelEntity: whiteLabel.entity,
});

export const mapDispatchToProps = {
  getEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(WhiteLabelDetail);
