/* eslint complexity: ["error", 500] */
/* eslint no-empty: off */
import React from 'react';
import { connect } from 'react-redux';

import SelectAsync from 'react-select/async';

import { Link, RouteComponentProps } from 'react-router-dom';

import CurrencyInput from 'react-currency-input';
import { getFilterFromSelect, getFormFromSelect, getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Col, Label } from 'reactstrap';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import {
  INfeUpdateState as IBaseUpdateState,
  getEntity,
  getNfeState,
  getUrlBack,
  updateEntity,
  createEntity,
  getEntityFiltersURL,
  getEntityOffset,
  reset,
} from './nfe.reducer';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';
import moment from 'moment';
registerLocale('pt', pt);

export interface INfeUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}
export type INfeUpdateState = IBaseUpdateState;

export class NfeUpdate extends React.Component<INfeUpdateProps, INfeUpdateState> {
  numberFileInput: any;

  acessKeyFileInput: any;

  valueNfeFileInput: any;

  buyDateFileInput: any;

  paymentDateFileInput: any;
  constructor(props: Readonly<INfeUpdateProps>) {
    super(props);

    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      functionEmbebed: [],

      nfeFileSelectValue: null,

      pharmacyStockSelectValue: null,

      supplierSelectValue: null,

      numberSelectValue: null,
      acessKeySelectValue: null,
      valueNfeSelectValue: null,
      buyDateSelectValue: null,
      paymentDateSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getNfeState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
      supplierFantasyName: null,

      isNew: !this.props.match.params || !this.props.match.params['id'],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateSuccess !== this.props.updateSuccess && prevProps.updateSuccess) {
      this.handleClose();
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.nfeEntity && prevProps.nfeEntity.id !== this.props.nfeEntity.id) ||
      (this.props.nfeFiles.length > 0 && this.state.nfeFileSelectValue === null && this.props.nfeEntity.nfeFile)
    ) {
      this.setState({
        nfeFileSelectValue:
          this.props.nfeEntity && this.props.nfeEntity.nfeFile
            ? this.props.nfeEntity.nfeFile.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.nfeEntity && prevProps.nfeEntity.id !== this.props.nfeEntity.id) ||
      (this.props.pharmacyStocks.length > 0 && this.state.pharmacyStockSelectValue === null && this.props.nfeEntity.pharmacyStock)
    ) {
      this.setState({
        pharmacyStockSelectValue:
          this.props.nfeEntity && this.props.nfeEntity.pharmacyStock
            ? this.props.nfeEntity.pharmacyStock.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.nfeEntity && prevProps.nfeEntity.id !== this.props.nfeEntity.id) ||
      (this.props.suppliers && this.state.supplierSelectValue === null && this.props.nfeEntity.supplier && this.props.nfeEntity.supplier.id)
    ) {
      const p = this.props.nfeEntity && this.props.nfeEntity.supplier ? this.props.nfeEntity.supplier : null;
      this.setState({
        supplierSelectValue: p ? { ...p, value: p.id, label: p['fantasyName'] ? p.fantasyName : '' } : null,
      });
    }

    if (
      (prevProps.nfeEntity && prevProps.nfeEntity.id !== this.props.nfeEntity.id) ||
      (this.props.nfeEntity.number && this.state.numberSelectValue === null && this.props.nfeEntity.number !== this.state.numberSelectValue)
    ) {
      this.setState({ numberSelectValue: this.props.nfeEntity.number ? this.props.nfeEntity.number : null });
    }
    if (
      (prevProps.nfeEntity && prevProps.nfeEntity.id !== this.props.nfeEntity.id) ||
      (this.props.nfeEntity.acessKey &&
        this.state.acessKeySelectValue === null &&
        this.props.nfeEntity.acessKey !== this.state.acessKeySelectValue)
    ) {
      this.setState({ acessKeySelectValue: this.props.nfeEntity.acessKey ? this.props.nfeEntity.acessKey : null });
    }
    if (
      (prevProps.nfeEntity && prevProps.nfeEntity.id !== this.props.nfeEntity.id) ||
      (this.props.nfeEntity.valueNfe &&
        this.state.valueNfeSelectValue === null &&
        this.props.nfeEntity.valueNfe !== this.state.valueNfeSelectValue)
    ) {
      this.setState({ valueNfeSelectValue: this.props.nfeEntity.valueNfe ? this.props.nfeEntity.valueNfe : null });
    }
    if (
      (prevProps.nfeEntity && prevProps.nfeEntity.id !== this.props.nfeEntity.id) ||
      (this.props.nfeEntity.buyDate &&
        this.state.buyDateSelectValue === null &&
        this.props.nfeEntity.buyDate !== this.state.buyDateSelectValue)
    ) {
      this.setState({ buyDateSelectValue: this.props.nfeEntity.buyDate ? this.props.nfeEntity.buyDate : null });
    }
    if (
      (prevProps.nfeEntity && prevProps.nfeEntity.id !== this.props.nfeEntity.id) ||
      (this.props.nfeEntity.paymentDate &&
        this.state.paymentDateSelectValue === null &&
        this.props.nfeEntity.paymentDate !== this.state.paymentDateSelectValue)
    ) {
      this.setState({ paymentDateSelectValue: this.props.nfeEntity.paymentDate ? this.props.nfeEntity.paymentDate : null });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  UNSAFE_componentWillMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params['id']);
    }
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = offset !== null ? '&offset=' + offset : '';
    Object.keys(fieldsBase).map(key => {
      url += key !== 'offset' ? '&' + key + '=' + fieldsBase[key] : '';
    });
    return url;
  };
  saveEntity = (event: any, errors: any, values: any) => {
    if (!this.state.buyDateSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.nfe.buyDate') }));
      return false;
    }

    const selectFieldsList = ['supplier.fantasyName', 'number', 'valueNfe', 'buyDate', 'createdBy.login'];

    if (errors.length === 0) {
      const { nfeEntity } = this.props;
      const _entity = {
        ...nfeEntity,
        // ...values,

        supplier: getFormFromSelect(this.state.supplierSelectValue, 'many-to-one'), // many-to-one - otherSide

        number: this.state.numberSelectValue,
        acessKey: this.state.acessKeySelectValue,
        valueNfe: this.state.valueNfeSelectValue,
        buyDate: this.state.buyDateSelectValue,
        paymentDate: this.state.paymentDateSelectValue,
      };

      const entity = _entity;

      const {
        supplierId,
        number,
        valueNfe,
        buyDateStart,
        buyDateEnd,
        extraFilters,
        activePage,
        itemsPerPage,
        sort,
        order,
      } = this.state.fieldsBase;

      if (this.state.isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              getFilterFromSelect(supplierId, 'many-to-one'),
              number,
              valueNfe,
              buyDateStart,
              buyDateEnd,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              getFilterFromSelect(supplierId, 'many-to-one'),
              number,
              valueNfe,
              buyDateStart,
              buyDateEnd,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/nfe?' + getEntityFiltersURL(this.state.fieldsBase));
  };

  getFullEntityFromState(v) {
    return {
      id: v.id,
      number: this.state.numberSelectValue,
      acessKey: this.state.acessKeySelectValue,
      valueNfe: this.state.valueNfeSelectValue,
      buyDate: this.state.buyDateSelectValue,
      paymentDate: this.state.paymentDateSelectValue,
      nfeFile: this.state.nfeFileSelectValue,
      pharmacyStock: this.state.pharmacyStockSelectValue,
      supplier: this.state.supplierSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    const nfeFileEntity = v.nfeFile;

    const pharmacyStockEntity = v.pharmacyStock;

    const supplierEntity = v.supplier;

    return {
      numberSelectValue: v.number,
      acessKeySelectValue: v.acessKey,
      valueNfeSelectValue: v.valueNfe,
      buyDateSelectValue: v.buyDate,
      paymentDateSelectValue: v.paymentDate,
      nfeFileSelectValue: nfeFileEntity ? nfeFileEntity['id'] : null,
      pharmacyStockSelectValue: pharmacyStockEntity ? pharmacyStockEntity['id'] : null,
      supplierSelectValue: supplierEntity ? supplierEntity['id'] : null,
    };
  }

  renderHeader() {
    const { nfeEntity, nfeFiles, pharmacyStocks, suppliers, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.nfe.home.createLabel">Create a Nfe</Translate>
            ) : (
              <Translate contentKey="generadorApp.nfe.home.editLabel">Edit a Nfe</Translate>
            )}
          </span>

          <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.nfe.btnSave">Save</Translate>
          </Button>

          {!this.state.isNew ? (
            <>
              <Button
                color="primary"
                id="next-entity"
                disabled={updating}
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.supplierId,
                    this.state.fieldsBase.number,
                    this.state.fieldsBase.valueNfe,
                    this.state.fieldsBase.buyDateStart,
                    this.state.fieldsBase.buyDateEnd,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 + 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 + 1 },
                    });
                    this.props.history.push(
                      '/nfe/' + myresult.value.data[0]['id'] + '/edit?' + this.getFiltersURL(this.state.fieldsBase.offset * 1 + 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.last.item', { fieldName: translate('generadorApp.nfe.detail.title') }));
                  }
                }}
                className="float-right jh-create-entity"
              >
                {'>>'}
              </Button>

              <Button
                color="primary"
                id="prev-entity"
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.supplierId,
                    this.state.fieldsBase.number,
                    this.state.fieldsBase.valueNfe,
                    this.state.fieldsBase.buyDateStart,
                    this.state.fieldsBase.buyDateEnd,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 - 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 - 1 },
                    });
                    this.props.history.push(
                      '/nfe/' + myresult.value.data[0]['id'] + '/edit?' + this.getFiltersURL(this.state.fieldsBase.offset * 1 - 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.first.item', { fieldName: translate('generadorApp.nfe.detail.title') }));
                  }
                }}
                disabled={updating}
                className="float-right jh-create-entity"
              >
                {'<<'}
              </Button>
            </>
          ) : (
            <> </>
          )}

          <Button
            tag={Link}
            id="cancel-save"
            to={'/nfe?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.nfe.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={'/nfe'}>
              <Translate contentKey="generadorApp.nfe.home.title">Buys</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.nfe.detail.title">Buy edit</Translate>
          </li>
        </ol>
      </>
    );
  }
  renderBody() {
    const { nfeEntity, nfeFiles, pharmacyStocks, suppliers, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const fullEntityEmbebed = this.getFullEntityFromState(nfeEntity);

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="nfe-id">
                  <Translate contentKey="generadorApp.nfe.field_id">ID</Translate>
                </Label>
                </Col> */}
                      <Col md="12">
                        <AvInput id="nfe-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'supplier' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="nfe-supplier">
                              <Translate contentKey="generadorApp.nfe.supplier">Supplier</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="nfe-supplier"
                              name={'supplier'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.supplierSelectValue ? this.state.supplierSelectValue : ''}
                              onChange={options => this.setState({ supplierSelectValue: options })}
                              defaultOptions={this.state.supplierStartSelectOptions ? this.state.supplierStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.supplierStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.supplierStartSelectOptions === undefined) {
                                  const result = await getListAxios('suppliers', {}, 0, 100, 'fantasyName,asc', 'fantasyName');
                                  this.setState({
                                    supplierStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'fantasyName') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'suppliers',
                                  { 'fantasyName.contains': inputValue },
                                  0,
                                  100,
                                  'fantasyName,asc',
                                  'fantasyName'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'fantasyName') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="supplier"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}

                  {baseFilters !== 'number' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="numberLabel" for="nfe-number">
                              <Translate contentKey="generadorApp.nfe.number">NF Number</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ numberSelectValue: evt.target.value })}
                              value={this.state.numberSelectValue ? this.state.numberSelectValue : ''}
                              id="nfe-number"
                              type="text"
                              name="number"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ numberSelectValue: evt.target.value })}
                      type="hidden"
                      name="number"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'acessKey' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="acessKeyLabel" for="nfe-acessKey">
                              <Translate contentKey="generadorApp.nfe.acessKey">Acess Key</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ acessKeySelectValue: evt.target.value })}
                              value={this.state.acessKeySelectValue ? this.state.acessKeySelectValue : ''}
                              id="nfe-acessKey"
                              type="text"
                              name="acessKey"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ acessKeySelectValue: evt.target.value })}
                      type="hidden"
                      name="acessKey"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'valueNfe' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="valueNfeLabel" for="nfe-valueNfe">
                              <Translate contentKey="generadorApp.nfe.valueNfe">Value</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ valueNfeSelectValue: floatvalue })}
                              decimalSeparator=","
                              thousandSeparator=""
                              precision={2}
                              prefix=""
                              allowNegative={true}
                              value={this.state.valueNfeSelectValue ? this.state.valueNfeSelectValue : 0}
                              id="nfe-valueNfe"
                              name="valueNfe"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ valueNfeSelectValue: evt.target.value })}
                      type="hidden"
                      name="valueNfe"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'buyDate' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="buyDateLabel" for="nfe-buyDate">
                              <Translate contentKey="generadorApp.nfe.buyDate">Buy Date</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <DatePicker
                              autoComplete="off"
                              onChange={value => this.setState({ buyDateSelectValue: value })}
                              id="buyDate"
                              validate={{ required: { value: true, errorMessage: translate('entity.validation.required') } }}
                              className="form-control react-datepicker"
                              name="buyDate"
                              dateFormat="dd/MM/yyyy"
                              selected={this.state.buyDateSelectValue ? moment(this.state.buyDateSelectValue).toDate() : null}
                              locale="pt"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ buyDateSelectValue: evt.target.value })}
                      type="hidden"
                      name="buyDate"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'paymentDate' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="paymentDateLabel" for="nfe-paymentDate">
                              <Translate contentKey="generadorApp.nfe.paymentDate">Nfe date</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <DatePicker
                              autoComplete="off"
                              onChange={value => this.setState({ paymentDateSelectValue: value })}
                              id="paymentDate"
                              validate={{}}
                              className="form-control react-datepicker"
                              name="paymentDate"
                              dateFormat="dd/MM/yyyy"
                              selected={this.state.paymentDateSelectValue ? moment(this.state.paymentDateSelectValue).toDate() : null}
                              locale="pt"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ paymentDateSelectValue: evt.target.value })}
                      type="hidden"
                      name="paymentDate"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { nfeEntity, nfeFiles, pharmacyStocks, suppliers, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...nfeEntity,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel className="nfe nfe-update">
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToProps = (storeState: IRootState) => {
  return {
    nfeFiles: storeState.nfeFile.entities,
    pharmacyStocks: storeState.pharmacyStock.entities,
    suppliers: storeState.supplier.entities,
    nfeEntity: storeState.nfe.entity,
    loading: storeState.nfe.loading,
    updating: storeState.nfe.updating,
    updateSuccess: storeState.nfe.updateSuccess,
  };
};

export const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  getEntityOffset,
  reset,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(NfeUpdate);
