/* eslint complexity: ["error", 500] */
import React from 'react';
import { connect } from 'react-redux';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { Button, Table } from 'reactstrap';
import { Translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEntityFiltersURL } from '../profile.reducer';

import { Profile, mapStateToProps, mapDispatchToProps } from '../profile';
import { IRootState } from 'app/shared/reducers';

export interface IExtendedState {
  dumystate?: any;
}
export interface IExtendedProps {
  entityEmbebedPai?: any;
}

export class ProfileExtended extends Profile {
  renderTable() {
    const { permissionProfiles, users, profileList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {profileList && profileList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null).length > 0 ? (
          <>
            <Table
              id="profile-table-list"
              responsive
              aria-describedby="profile-heading"
              className={'table-hover table-striped mt-4 table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.profile.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'name' ? (
                    <th className="hand" onClick={sortFunction('name')}>
                      <Translate contentKey="generadorApp.profile.name">Name</Translate>
                      <FontAwesomeIcon icon={state.sort === 'name' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'status' ? (
                    <th className="hand" onClick={sortFunction('status')}>
                      <Translate contentKey="generadorApp.profile.status">Status</Translate>
                      <FontAwesomeIcon icon={state.sort === 'status' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}

                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {profileList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((profile, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.profile.field_id">ID</Translate>
                        </b>
                        {profile.id}
                      </td>

                      {state.baseFilters !== 'name' ? (
                        <td id="name-cell">
                          <b className="visible-xs"> Name </b>

                          {profile.name}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'status' ? (
                        <td id="status-cell">
                          <b className="visible-xs"> Status </b>

                          <div className="switcher switcher-success">
                            <input
                              type="checkbox"
                              name={'status_' + profile.id}
                              id={'status_' + profile.id}
                              defaultChecked={profile.status}
                              onClick={() => props.updateEntity({ ...profile, status: !profile.status }, { reloadList: false })}
                            />
                            <label htmlFor={'status_' + profile.id} />
                          </div>
                        </td>
                      ) : null}

                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          {hasAnyAuthority(props.userAccount, ['profile'], 'edit') ? (
                            <a
                              className="btn btn-primary btn-sm"
                              href={`${match.url}/${profile.id}/edit?${getEntityFiltersURL(
                                state,
                                this.state.itemsPerPage * (this.state.activePage - 1) + i
                              )}`}
                            >
                              <FontAwesomeIcon icon="pencil-alt" />
                            </a>
                          ) : (
                            <></>
                          )}{' '}
                          {hasAnyAuthority(props.userAccount, ['profile'], 'canDelete') ? (
                            <Button onClick={() => this.deleteEntity(profile)} color="danger" size="sm">
                              <FontAwesomeIcon icon="trash" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.profile.home.notFound">No Profiles found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;

export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(ProfileExtended);
