/* eslint complexity: ["error", 100] */
import axios from 'axios';

import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { getEntities, PadAttendanceState, reset } from '../pad-attendance.reducer';
import { BASE_API_VERSION_PATH } from 'app/config/constants';
import moment from 'moment';

export const ACTION_TYPES_EXTENDED = {
  PADATTENDANCE_TOTALIZE: 'padAttendance/PADATTENDANCE_TOTALIZE',
  PADATTENDANCE_CANCEL: 'padAttendance/PADATTENDANCE_CANCEL',
};

export const initialStateExtended = {
  totalize: [],
};

export const SwitchExtended = (state: PadAttendanceState, action): PadAttendanceState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES_EXTENDED.PADATTENDANCE_TOTALIZE):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ACTION_TYPES_EXTENDED.PADATTENDANCE_TOTALIZE):
      return {
        ...state,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES_EXTENDED.PADATTENDANCE_TOTALIZE):
      return {
        ...state,
        loading: false,
        totalize: action.payload.data,
      };
    default:
      return null;
  }
  return null;
};

const apiUrl = BASE_API_VERSION_PATH + 'pad-attendances';
export const getTotalize: any = (
  padId,
  period,
  schedule,
  push,
  frequency,
  valuePad,
  quantity,
  dateStart,
  dateEnd,
  quoteRhId,
  quoteRhQuoteId,
  quoteRhQuotePatientId,
  professionalId,
  status,
  quoteRhQuotePatientClientId,
  quoteRhSpecialtyCategoryId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const padRequest = padId ? `pad.id.in=${padId}&` : '';
  const periodRequest = period ? (Array.isArray(status) ? `period.in=${period.join(',')}&` : `period.equals=${period}&`) : '';
  const scheduleRequest = schedule ? `schedule.contains=${schedule}&` : '';
  const pushRequest = push ? `push.contains=${push}&` : '';
  const frequencyRequest = frequency
    ? Array.isArray(status)
      ? `frequency.in=${frequency.join(',')}&`
      : `frequency.equals=${frequency}&`
    : '';
  const valuePadRequest = valuePad ? `valuePad.contains=${valuePad}&` : '';
  const quantityRequest = quantity ? `quantity.equals=${quantity}&` : '';
  let dateStartRequest = '';
  let dateEndRequest = '';
  if (typeof dateStart !== 'undefined' && dateStart && !(typeof dateEnd !== 'undefined' && dateEnd)) {
    dateStartRequest = `date.greaterThan=${moment(dateStart).format('YYYY-MM-DD')}&`;
  } else if (!(typeof dateStart !== 'undefined' && dateStart) && typeof dateEnd !== 'undefined' && dateEnd) {
    dateEndRequest = `date.lessThan=${moment(dateEnd).format('YYYY-MM-DD')}&`;
  } else if (typeof dateStart !== 'undefined' && dateStart && typeof dateEnd !== 'undefined' && dateEnd) {
    dateStartRequest = `date.between=${moment(dateStart).format('YYYY-MM-DD')},${moment(dateEnd).format('YYYY-MM-DD')}&`;
  }
  const quoteRhRequest = quoteRhId ? `quoteRh.id.in=${quoteRhId}&` : '';
  const quoteRhQuoteRequest = quoteRhQuoteId ? `quoteRh.quote.id.in=${quoteRhQuoteId}&` : '';
  const quoteRhQuotePatientRequest = quoteRhQuotePatientId ? `quoteRh.quote.patient.id.in=${quoteRhQuotePatientId}&` : '';
  const professionalRequest = professionalId ? `professional.id.in=${professionalId}&` : '';
  const statusRequest = status ? (Array.isArray(status) ? `status.in=${status.join(',')}&` : `status.equals=${status}&`) : '';
  const quoteRhQuotePatientClientRequest = quoteRhQuotePatientClientId
    ? `quoteRh.quote.patient.client.id.in=${quoteRhQuotePatientClientId}&`
    : '';
  const quoteRhSpecialtyCategoryRequest = quoteRhSpecialtyCategoryId
    ? `quoteRh.specialty.category.id.in=${quoteRhSpecialtyCategoryId}&`
    : '';

  const requestUrl = `${apiUrl + `/totalize?`}`;
  return {
    type: ACTION_TYPES_EXTENDED.PADATTENDANCE_TOTALIZE,
    payload: axios.get<any>(
      `${requestUrl}${extraFiltersRequest}${padRequest}${periodRequest}${scheduleRequest}${pushRequest}${frequencyRequest}${valuePadRequest}${quantityRequest}${dateStartRequest}${dateEndRequest}${quoteRhRequest}${quoteRhQuoteRequest}${quoteRhQuotePatientRequest}${professionalRequest}${statusRequest}${quoteRhQuotePatientClientRequest}${quoteRhSpecialtyCategoryRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const cancelAttendances: any = (listCheckedID, listFiltersPage) => async dispatch => {
  const requestUrl = `${apiUrl + `/cancel`}`;
  const result = await dispatch({
    type: ACTION_TYPES_EXTENDED.PADATTENDANCE_CANCEL,
    payload: axios.patch<any>(`${requestUrl}`, { listCheckedID }),
  });
  if (listFiltersPage !== false && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};
