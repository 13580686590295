/* eslint complexity: ["error", 500] */
/* eslint-disable no-console */

import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

import { ProfessionalOccupationArea, mapStateToProps, mapDispatchToProps } from '../professional-occupation-area';
import { IRootState } from 'app/shared/reducers';
import SelectAsync from 'react-select/async';

import { Col, Row, Label } from 'reactstrap';
import { AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Translate, translate } from 'app/config/translate-component';

import { getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';

export interface IExtendedState {
  ufApiSelectValue?: any;
  ufApiStartSelectOptions?: any;
  cityApiSelectValue?: any;
  cityApiStartSelectOptions?: any;
}
export interface IExtendedProps {
  entityEmbebedPai?: any;
}

export class ProfessionalOccupationAreaExtended extends ProfessionalOccupationArea {
  renderBodyUpdate(isNew) {
    const { loading } = this.props;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      <Col md="12">
                        <AvInput id="professional-occupation-area-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'initialZipcode' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label
                              className="mt-2 label-single-line"
                              id="initialZipcodeLabel"
                              for="professional-occupation-area-initialZipcode"
                            >
                              <Translate contentKey="generadorApp.professionalOccupationArea.initialZipcode">Initial Zipcode</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ initialZipcodeSelectValue: evt.target.value })}
                              value={this.state.initialZipcodeSelectValue ? this.state.initialZipcodeSelectValue : ''}
                              id="professional-occupation-area-initialZipcode"
                              type="text"
                              name="initialZipcode"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ initialZipcodeSelectValue: evt.target.value })}
                      type="hidden"
                      name="initialZipcode"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'finalZipcode' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label
                              className="mt-2 label-single-line"
                              id="finalZipcodeLabel"
                              for="professional-occupation-area-finalZipcode"
                            >
                              <Translate contentKey="generadorApp.professionalOccupationArea.finalZipcode">Final Zipcode</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ finalZipcodeSelectValue: evt.target.value })}
                              value={this.state.finalZipcodeSelectValue ? this.state.finalZipcodeSelectValue : ''}
                              id="professional-occupation-area-finalZipcode"
                              type="text"
                              name="finalZipcode"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ finalZipcodeSelectValue: evt.target.value })}
                      type="hidden"
                      name="finalZipcode"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'uf' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="ufLabel" for="professional-occupation-area-select-uf">
                              <Translate contentKey="generadorApp.professionalOccupationArea.uf">Uf</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="professional-occupation-area-select-uf"
                              name={'professional'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={{
                                value: this.state.ufApiSelectValue ? this.state.ufApiSelectValue.value : '',
                                label: this.state.ufSelectValue,
                              }}
                              onChange={async options => {
                                const resultCity = await axios.get(
                                  'https://servicodados.ibge.gov.br/api/v1/localidades/estados/' + options['value'] + '/municipios'
                                );

                                this.setState({
                                  ufApiSelectValue: options,
                                  cityApiSelectValue: null,
                                  ufSelectValue: options['label'],
                                  cityApiStartSelectOptions: resultCity.data
                                    ? resultCity.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'nome') || '' }))
                                    : [],
                                });
                              }}
                              defaultOptions={this.state.ufApiStartSelectOptions ? this.state.ufApiStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.ufApiStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.ufApiStartSelectOptions === undefined) {
                                  const result = await axios.get(
                                    'https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome'
                                  );
                                  console.info(result);
                                  this.setState({
                                    ufApiStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'nome') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios('professionals', { 'id.contains': inputValue }, 0, 100, 'id,asc', 'id');
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'nome') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ ufSelectValue: evt.target.value })}
                      type="hidden"
                      name="uf"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'city' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="cityLabel" for="professional-occupation-area-city">
                              <Translate contentKey="generadorApp.professionalOccupationArea.city">City</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="professional-occupation-area-city"
                              name={'professional'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={{
                                value: this.state.cityApiSelectValue ? this.state.cityApiSelectValue.value : '',
                                label: this.state.citySelectValue,
                              }}
                              onChange={options => {
                                this.setState({
                                  cityApiSelectValue: options,
                                  citySelectValue: options['label'],
                                });
                              }}
                              defaultOptions={this.state.cityApiStartSelectOptions ? this.state.cityApiStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.cityApiStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.cityApiStartSelectOptions === undefined) {
                                  const result = await axios.get(
                                    'https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome'
                                  );
                                  this.setState({
                                    cityApiStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'nome') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios('professionals', { 'id.contains': inputValue }, 0, 100, 'id,asc', 'id');
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'nome') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ citySelectValue: evt.target.value })}
                      type="hidden"
                      name="city"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'professional' ? (
                    <Col md="12">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="professional-occupation-area-professional">
                              <Translate contentKey="generadorApp.professionalOccupationArea.professional">Professional</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="professional-occupation-area-professional"
                              name={'professional'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.professionalSelectValue ? this.state.professionalSelectValue : {}}
                              onChange={options => this.setState({ professionalSelectValue: options })}
                              defaultOptions={this.state.professionalStartSelectOptions ? this.state.professionalStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.professionalStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.professionalStartSelectOptions === undefined) {
                                  const result = await getListAxios('professionals', {}, 0, 100, 'id,asc', 'id');
                                  this.setState({
                                    professionalStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios('professionals', { 'id.contains': inputValue }, 0, 100, 'id,asc', 'id');
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="professional"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}{' '}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;

export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(ProfessionalOccupationAreaExtended);
