/* eslint complexity: ["error", 500] */
import React from 'react';

import CurrencyInput from 'react-currency-input';
import { connect } from 'react-redux';

import { toast } from 'react-toastify';

import { Link, RouteComponentProps } from 'react-router-dom';

import { Button, Col, Row, Table, Label, UncontrolledCollapse, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { JhiItemCount } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { IRootState } from 'app/shared/reducers';
import {
  updateEntity,
  getUrlBack,
  getDoseState,
  IDoseBaseState,
  getEntityFiltersURL,
  getEntities,
  IDoseUpdateState as IBaseUpdateState,
  reset,
  getEntity,
  deleteEntity,
  createEntity,
} from './dose.reducer';

import { ITEMS_PER_PAGE, IPaginationBaseState, getSortState } from 'app/shared/util/pagination.constants';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

export interface IDoseProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
  entityEmbebedPai?: any;
}

export interface IDoseState extends IDoseBaseState, IPaginationBaseState, IBaseUpdateState {
  showModalForm?: boolean;
  showModalEdit?: number;
  showModalEditView?: boolean;
}

export class Dose extends React.Component<IDoseProps, IDoseState> {
  protected myFormRef: any;
  protected myFormRefNew: any;
  protected myFormRefEdit: any;

  nameFileInput: any;

  codeANSFileInput: any;

  descriptionFileInput: any;

  caseDecimalFileInput: any;

  constructor(props: IDoseProps) {
    super(props);
    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      listCheckedID: [],
      isCheckedAll: false,
      showModalForm: false,
      showModalEdit: null,
      urlBack: getUrlBack(this.props.location),
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      ...getDoseState(this.props.location),
      medicineMaterialSelectValue: null,

      clComTableSelectValue: null,

      specialtySelectValue: null,

      productServiceSelectValue: null,

      levelGroupSelectValue: null,

      nameSelectValue: null,
      codeANSSelectValue: null,
      descriptionSelectValue: null,
      caseDecimalSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getDoseState(this.props.location),
      },
      levelGroupName: null,
      isNew: false,
    };
  }

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({ listCheckedID: this.props.doseList.map(dose => dose.id) });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({ listCheckedID: this.props.doseList.map(dose => dose.id) });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  removeEntitySelected() {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  }

  saveEntityMany = () => {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  };

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  cancelFilters = () => {
    this.setState(
      {
        name: '',
        codeANS: '',
        description: '',
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
        activePage: 0,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();

    const selectFieldsList = ['name', 'codeANS', 'description', 'caseDecimal'];
    const { name, codeANS, description, extraFilters, activePage, itemsPerPage, sort, order } = this.state;
    return this.props.getEntities(
      name,
      codeANS,
      description,
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(',')
    );
  };

  updateEntity = entity => {
    const selectFieldsList = ['name', 'codeANS', 'description', 'caseDecimal'];

    const { name, codeANS, description, extraFilters, activePage, itemsPerPage, sort, order } = this.state;
    this.props.updateEntity(entity, [
      name,
      codeANS,
      description,
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(','),
    ]);
  };

  saveEntity = (event: any, errors: any, values: any, isNew: any) => {
    if (!this.state.nameSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.dose.name') }));
      return false;
    }

    if (!this.state.codeANSSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.dose.codeANS') }));
      return false;
    }

    if (!this.state.caseDecimalSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.dose.caseDecimal') }));
      return false;
    }

    if (!this.state.descriptionSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.dose.description') }));
      return false;
    }

    const selectFieldsList = ['name', 'codeANS', 'description', 'caseDecimal'];

    if (errors.length === 0) {
      const { doseEntity } = this.props;
      const _entity = {
        ...doseEntity,
        // ...values,

        name: this.state.nameSelectValue,
        codeANS: this.state.codeANSSelectValue,
        description: this.state.descriptionSelectValue,
        caseDecimal: this.state.caseDecimalSelectValue,
      };
      const entity = _entity;

      const { name, codeANS, description, extraFilters, activePage, itemsPerPage, sort, order } = this.state.fieldsBase;

      if (isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              name,
              codeANS,
              description,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              name,
              codeANS,
              description,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
      this.setState({ showModalForm: false });
      this.setState({ showModalEdit: null });
    } else {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.specialty.' + errors[0]) }));
    }
  };

  componentDidUpdate(prevProps) {}

  deleteEntity(doseEntity) {
    MySwal.fire({
      title: <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>,
      html: (
        <Translate contentKey="generadorApp.dose.delete.question" interpolate={{ id: doseEntity.id }}>
          Are you sure you want to delete this Dose?
        </Translate>
      ),
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#e70015',
      cancelButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="ban" />
          &nbsp; <Translate contentKey="generadorApp.dose.btnCancel">Cancel</Translate>
        </>
      ),
      confirmButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="trash" />
          &nbsp;<Translate contentKey="generadorApp.dose.btnDelete">Delete</Translate>
        </>
      ),
    }).then(async response => {
      if (response.isConfirmed) {
        await this.props.deleteEntity(doseEntity.id, { reloadList: false });
        this.getEntities();
        MySwal.fire({
          title: <Translate contentKey="entity.delete.title.confirmed">Confirmed delete operation</Translate>,
          icon: 'success',
        });
      }
    });
  }

  renderNewModal() {
    return (
      <Modal size={'xl'} isOpen={this.state.showModalForm} toggle={() => this.setState({ showModalForm: false })}>
        <ModalHeader toggle={() => this.setState({ showModalForm: false })}>{this.renderHeaderUpdate({}, true)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefNew = el)}
          model={{}}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, true)}
        >
          <ModalBody>{this.renderBodyUpdate({}, true)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalForm: false })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.dose.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefNew && this.myFormRefNew.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.dose.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    );
  }

  getFullEntityFromState(v) {
    return {
      id: v.id,
      name: this.state.nameSelectValue,
      codeANS: this.state.codeANSSelectValue,
      description: this.state.descriptionSelectValue,
      caseDecimal: this.state.caseDecimalSelectValue,
      medicineMaterial: this.state.medicineMaterialSelectValue,
      clComTable: this.state.clComTableSelectValue,
      specialty: this.state.specialtySelectValue,
      productService: this.state.productServiceSelectValue,
      levelGroup: this.state.levelGroupSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    let p: any = null;

    const medicineMaterialEntity = v.medicineMaterial
      ? v.medicineMaterial.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' }))
      : v.medicineMaterial;

    const clComTableEntity = v.clComTable
      ? v.clComTable.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' }))
      : v.clComTable;

    const specialtyEntity = v.specialty
      ? v.specialty.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' }))
      : v.specialty;

    const productServiceEntity = v.productService
      ? v.productService.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' }))
      : v.productService;

    p = v.levelGroup;
    const levelGroupEntity = p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : p;

    return {
      nameSelectValue: v.name, // name,
      codeANSSelectValue: v.codeANS, // codeANS,
      descriptionSelectValue: v.description, // description,
      caseDecimalSelectValue: v.caseDecimal, // caseDecimal,
      medicineMaterialSelectValue: medicineMaterialEntity,
      clComTableSelectValue: clComTableEntity,
      specialtySelectValue: specialtyEntity,
      productServiceSelectValue: productServiceEntity,
      levelGroupSelectValue: levelGroupEntity,
    };
  }

  openUpdateModal(v, edit = true) {
    this.props.getEntity(v.id);
    this.setState({
      showModalEdit: v.id,
      showModalEditView: edit,
      ...this.getFullEntitySelectValue(v),
    });
  }

  renderUpdateModal() {
    const { doseEntity, entityEmbebedPai } = this.props;
    return doseEntity && doseEntity.id ? (
      <Modal size={'xl'} isOpen={this.state.showModalEdit === doseEntity.id} toggle={() => this.setState({ showModalEdit: null })}>
        <ModalHeader toggle={() => this.setState({ showModalEdit: null })}>{this.renderHeaderUpdate(doseEntity, false)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefEdit = el)}
          model={{
            ...doseEntity,
          }}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, false)}
        >
          <ModalBody>{this.renderBodyUpdate(doseEntity, false)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalEdit: null })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.dose.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefEdit && this.myFormRefEdit.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.dose.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    ) : (
      <> </>
    );
  }

  renderHeaderUpdate(doseEntity, isNew) {
    const { entityEmbebedPai, medicineMaterials, clComTables, specialties, productServices, levelGroups, loading } = this.props;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {isNew ? (
              <Translate contentKey="generadorApp.dose.home.createLabel">Create a Dose</Translate>
            ) : (
              <Translate contentKey="generadorApp.dose.home.editLabel">Edit a Dose</Translate>
            )}
          </span>
        </div>
      </>
    );
  }
  renderBodyUpdate(doseEntity, isNew) {
    const { entityEmbebedPai, medicineMaterials, clComTables, specialties, productServices, levelGroups, loading } = this.props;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
              <Col md="3">
              <Label className="mt-2 label-single-line"for="dose-id">
                <Translate contentKey="generadorApp.dose.field_id">ID</Translate>
              </Label>
              </Col> */}
                      <Col md="12">
                        <AvInput id="dose-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'name' ? (
                    <Col md="4">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="nameLabel" for="dose-name">
                              <Translate contentKey="generadorApp.dose.name">Name</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                              value={this.state.nameSelectValue ? this.state.nameSelectValue : ''}
                              id="dose-name"
                              type="text"
                              name="name"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                      type="hidden"
                      name="name"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'codeANS' ? (
                    <Col md="4">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="codeANSLabel" for="dose-codeANS">
                              <Translate contentKey="generadorApp.dose.codeANS">Código ANS</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ codeANSSelectValue: evt.target.value })}
                              value={this.state.codeANSSelectValue ? this.state.codeANSSelectValue : ''}
                              id="dose-codeANS"
                              type="text"
                              name="codeANS"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ codeANSSelectValue: evt.target.value })}
                      type="hidden"
                      name="codeANS"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'caseDecimal' ? (
                    <Col md="4">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="caseDecimalLabel" for="dose-caseDecimal">
                              <Translate contentKey="generadorApp.dose.caseDecimal">Decimais</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ caseDecimalSelectValue: floatvalue })}
                              decimalSeparator=""
                              thousandSeparator=""
                              precision={0}
                              prefix=""
                              allowNegative={true}
                              value={this.state.caseDecimalSelectValue ? this.state.caseDecimalSelectValue : 0}
                              id="dose-caseDecimal"
                              name="caseDecimal"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ caseDecimalSelectValue: evt.target.value })}
                      type="hidden"
                      name="caseDecimal"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'description' ? (
                    <Col md="12">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="descriptionLabel" for="dose-description">
                              <Translate contentKey="generadorApp.dose.description">Descrição Detalhada</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ descriptionSelectValue: evt.target.value })}
                              value={this.state.descriptionSelectValue ? this.state.descriptionSelectValue : ''}
                              id="dose-description"
                              type="text"
                              name="description"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ descriptionSelectValue: evt.target.value })}
                      type="hidden"
                      name="description"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  renderHeader() {
    const { medicineMaterials, clComTables, specialties, productServices, levelGroups, doseList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.dose.home.title">Dose</Translate>
          </span>
          <Button id="togglerFilterDose" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.dose.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          &nbsp;
          <Button
            onClick={() =>
              this.setState({
                showModalForm: true,
                nameSelectValue: null,
                codeANSSelectValue: null,
                descriptionSelectValue: null,
                caseDecimalSelectValue: null,

                medicineMaterialStartSelectOptions: undefined,
                medicineMaterialSelectValue: null,

                clComTableStartSelectOptions: undefined,
                clComTableSelectValue: null,

                specialtyStartSelectOptions: undefined,
                specialtySelectValue: null,

                productServiceStartSelectOptions: undefined,
                productServiceSelectValue: null,

                levelGroupStartSelectOptions: undefined,
                levelGroupSelectValue: null,
                isNew: true,
              })
            }
            color="primary"
            className="btn btn-primary float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="generadorApp.dose.btnNewItem">Create new</Translate>
          </Button>{' '}
          &nbsp;
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.dose.home.title">Dose</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    const { medicineMaterials, clComTables, specialties, productServices, levelGroups, doseList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <UncontrolledCollapse toggler="#togglerFilterDose">
          <CardBody>
            <AvForm ref={el => (this.myFormRef = el)} id="form-filter" onSubmit={this.filterEntity}>
              <div className="row mt-1 ml-3 mr-3">
                {this.state.baseFilters !== 'name' ? (
                  <Col md="3" className="col-filter-dose-name">
                    <Row className="mr-1 mt-1">
                      <Label id="nameLabel" for="dose-name">
                        <Translate contentKey="generadorApp.dose.name">Name</Translate>
                      </Label>

                      <AvInput type="text" name="name" id="dose-name" value={this.state.name} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'codeANS' ? (
                  <Col md="3" className="col-filter-dose-codeANS">
                    <Row className="mr-1 mt-1">
                      <Label id="codeANSLabel" for="dose-codeANS">
                        <Translate contentKey="generadorApp.dose.codeANS">Código ANS</Translate>
                      </Label>

                      <AvInput type="text" name="codeANS" id="dose-codeANS" value={this.state.codeANS} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'description' ? (
                  <Col md="3" className="col-filter-dose-description">
                    <Row className="mr-1 mt-1">
                      <Label id="descriptionLabel" for="dose-description">
                        <Translate contentKey="generadorApp.dose.description">Descrição Detalhada</Translate>
                      </Label>

                      <AvInput type="text" name="description" id="dose-description" value={this.state.description} />
                    </Row>
                  </Col>
                ) : null}
              </div>

              <div className="row mb-2 mr-4 justify-content-end">
                <Button color="primary" type="submit">
                  <i className="fa fa-filter" aria-hidden={'true'}></i>&nbsp;
                  <Translate contentKey="generadorApp.dose.btnFilter">Filter</Translate>
                </Button>
                &nbsp;
                <Button color="secondary" onClick={this.cancelFilters}>
                  <FontAwesomeIcon icon="trash-alt" />
                  &nbsp;
                  <Translate contentKey="generadorApp.dose.btnCleanFilter">Clean Filter</Translate>
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </UncontrolledCollapse>
      </>
    );
  }

  renderTable() {
    const { medicineMaterials, clComTables, specialties, productServices, levelGroups, doseList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {doseList && doseList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null).length > 0 ? (
          <>
            <Table
              id="dose-table-list"
              responsive
              aria-describedby="dose-heading"
              className={'table-hover table-striped table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.dose.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'name' ? (
                    <th className="hand" onClick={sortFunction('name')}>
                      <Translate contentKey="generadorApp.dose.name">Name</Translate>
                      <FontAwesomeIcon icon={state.sort === 'name' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'codeANS' ? (
                    <th className="hand" onClick={sortFunction('codeANS')}>
                      <Translate contentKey="generadorApp.dose.codeANS">Código ANS</Translate>
                      <FontAwesomeIcon icon={state.sort === 'codeANS' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'description' ? (
                    <th className="hand" onClick={sortFunction('description')}>
                      <Translate contentKey="generadorApp.dose.description">Descrição Detalhada</Translate>
                      <FontAwesomeIcon icon={state.sort === 'description' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'caseDecimal' ? (
                    <th className="hand" onClick={sortFunction('caseDecimal')}>
                      <Translate contentKey="generadorApp.dose.caseDecimal">Decimais</Translate>
                      <FontAwesomeIcon icon={state.sort === 'caseDecimal' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}

                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {doseList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((dose, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.dose.field_id">ID</Translate>
                        </b>
                        {dose.id}
                      </td>

                      {state.baseFilters !== 'name' ? (
                        <td id="name-cell">
                          <b className="visible-xs"> Name </b>

                          {dose.name}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'codeANS' ? (
                        <td id="codeANS-cell">
                          <b className="visible-xs"> Código ANS </b>

                          {dose.codeANS}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'description' ? (
                        <td id="description-cell">
                          <b className="visible-xs"> Descrição Detalhada </b>

                          {dose.description}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'caseDecimal' ? (
                        <td id="caseDecimal-cell">
                          <b className="visible-xs"> Decimais </b>

                          {dose.caseDecimal}
                        </td>
                      ) : null}

                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          {hasAnyAuthority(props.userAccount, ['dose'], 'edit') ? (
                            <Button onClick={() => this.openUpdateModal(dose)} color="primary" size="sm">
                              <FontAwesomeIcon icon="pencil-alt" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                          {hasAnyAuthority(props.userAccount, ['dose'], 'canDelete') ? (
                            <Button onClick={() => this.deleteEntity(dose)} color="danger" size="sm">
                              <FontAwesomeIcon icon="trash" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.dose.home.notFound">No Dose found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const { medicineMaterials, clComTables, specialties, productServices, levelGroups, doseList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={doseList && doseList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const { medicineMaterials, clComTables, specialties, productServices, levelGroups, doseList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel className="dose dose-all-in-one">
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
            {this.renderNewModal()}
            {this.renderUpdateModal()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToProps = ({ dose, ...storeState }: IRootState) => ({
  medicineMaterials: storeState.medicineMaterial.entities,
  clComTables: storeState.clComTable.entities,
  specialties: storeState.specialty.entities,
  productServices: storeState.productService.entities,
  levelGroups: storeState.levelGroup.entities,
  doseList: dose.entities,
  totalItems: dose.totalItems,
  userAccount: storeState.authentication.account,
  loading: dose.loading,

  // UPDATE
  doseEntity: dose.entity,
});

export const mapDispatchToProps = {
  getEntities,

  updateEntity,
  reset,

  // UPDATE
  getEntity,
  createEntity,

  // DELETE
  deleteEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Dose);
