/* eslint complexity: ["error", 300] */
/* eslint no-empty: off */
import React from 'react';
import { connect } from 'react-redux';

import { Link, RouteComponentProps } from 'react-router-dom';

import 'react-quill/dist/quill.snow.css';

import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Col, Label } from 'reactstrap';

import { Translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import {
  IWhiteLabelUpdateState as IBaseUpdateState,
  getEntity,
  getWhiteLabelState,
  updateEntity,
  createEntity,
  reset,
} from './white-label.reducer';

export interface IWhiteLabelUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}
export type IWhiteLabelUpdateState = IBaseUpdateState;

export class WhiteLabelUpdate extends React.Component<IWhiteLabelUpdateProps, IWhiteLabelUpdateState> {
  nameFileInput: React.RefObject<HTMLInputElement>;
  constructor(props: Readonly<IWhiteLabelUpdateProps>) {
    super(props);

    this.state = {
      nameSelectValue: null,
      fieldsBase: {
        ...getWhiteLabelState(this.props.location),
      },

      isNew: !this.props.match.params || !this.props.match.params['id'],
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.updateSuccess !== this.props.updateSuccess && prevProps.updateSuccess) {
      this.handleClose();
    }

    if (
      (prevProps.whiteLabelEntity && prevProps.whiteLabelEntity.id !== this.props.whiteLabelEntity.id) ||
      (this.props.whiteLabelEntity.name && this.state.nameSelectValue === null)
    ) {
      this.setState({ nameSelectValue: this.props.whiteLabelEntity.name });
    }
  }

  UNSAFE_componentWillMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params['id']);
    }
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = '_back=1' + (offset !== null ? '&offset=' + offset : '');
    Object.keys(fieldsBase).map(key => {
      url += '&' + key + '=' + fieldsBase[key];
    });
    return url;
  };
  saveEntity = (event: any, errors: any, values: any) => {
    if (errors.length === 0) {
      const { whiteLabelEntity } = this.props;
      const entity = {
        ...whiteLabelEntity,
        ...values,

        name: this.state.nameSelectValue,
      };

      const { name } = this.state.fieldsBase;

      if (this.state.isNew) {
        this.props.createEntity(entity, [name]);
      } else {
        this.props.updateEntity(entity, [name]);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/white-label?' + this.getFiltersURL());
  };

  renderHeader() {
    const { whiteLabelEntity, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.whiteLabel.home.createLabel">Create a WhiteLabel</Translate>
            ) : (
              <Translate contentKey="generadorApp.whiteLabel.home.editLabel">Edit a WhiteLabel</Translate>
            )}
          </span>

          <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.whiteLabel.btnSave">Save</Translate>
          </Button>
          <Button
            tag={Link}
            id="cancel-save"
            to={'/white-label?' + this.getFiltersURL()}
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.whiteLabel.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={'/white-label'}>White Labels</Link>
          </li>
          <li className="breadcrumb-item active">White Labels edit</li>
        </ol>
      </>
    );
  }
  renderBody() {
    const { whiteLabelEntity, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="white-label-id">
                  <Translate contentKey="global.field.id">ID</Translate>
                </Label>
                </Col> */}
                      <Col md="12">
                        <AvInput id="white-label-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'name' ? (
                    <Col md="12">
                      <AvGroup>
                        <Row>
                          <Col md="3">
                            <Label className="mt-2 label-single-line" id="nameLabel" for="white-label-name">
                              <Translate contentKey="generadorApp.whiteLabel.name">Name</Translate>
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvInput
                              onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                              validate={{}}
                              id="white-label-name"
                              type="text"
                              name="name"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                      type="hidden"
                      name="name"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}{' '}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { whiteLabelEntity, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...whiteLabelEntity,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel>
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToProps = (storeState: IRootState) => ({
  whiteLabelEntity: storeState.whiteLabel.entity,
  loading: storeState.whiteLabel.loading,
  updating: storeState.whiteLabel.updating,
  updateSuccess: storeState.whiteLabel.updateSuccess,
});

export const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(WhiteLabelUpdate);
