/* eslint complexity: ["error", 500] */
import axios from 'axios';

import moment from 'moment';
import { IPaginationBaseState, IPayloadResult } from 'react-jhipster';

import { IPayload } from 'react-jhipster/src/type/redux-action.type';

import { BASE_API_VERSION_PATH } from 'app/config/constants';

import { cleanEntity, jsonParse } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { INfe, defaultValue } from 'app/shared/model/nfe.model';

export declare type ICrudPutAction<T> = (data?: T, listFiltersPage?) => IPayload<T> | IPayloadResult<T>;
export declare type ICrudGetAction<T> = (id: string | number, selectFields?: string) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const ACTION_TYPES = {
  FETCH_NFE_LIST_EXPORT: 'nfe/FETCH_NFE_LIST_EXPORT',
  FETCH_NFE_LIST: 'nfe/FETCH_NFE_LIST',
  FETCH_NFE: 'nfe/FETCH_NFE',
  FETCH_NFE_OFFSET: 'nfe/FETCH_NFE_OFFSET',
  CREATE_NFE: 'nfe/CREATE_NFE',
  UPDATE_NFE: 'nfe/UPDATE_NFE',
  CREATE_NFE_AND_GO_LIST: 'nfe/CREATE_NFE_AND_GO_LIST',
  UPDATE_NFE_AND_GO_LIST: 'nfe/UPDATE_NFE_AND_GO_LIST',
  DELETE_NFE: 'nfe/DELETE_NFE',
  RESET: 'nfe/RESET',
  SHOW_MODAL: 'nfe/SHOW_MODAL',
};

const initialState = {
  loading: false,
  openModal: {
    list: false,
    view: false,
    detail: false,
  },
  errorMessage: null,
  entities: [] as ReadonlyArray<INfe>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const actionPendind = [];
let actualState = initialState;

export const convertEntityBuffers = entity => ({
  ...entity,
});

export type NfeState = Readonly<typeof initialState>;

export interface INfeBaseState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  listCheckedID?: Array<number>;
  isCheckedAll?: boolean;
  urlBack?: string;
  extraFilters?: any;
  baseFilters: any;
  offset: number;
  supplierId?: any;
  supplierStartFilter?: any;
  number?: any;
  valueNfe?: any;
  buyDateStart?: any;
  buyDateEnd?: any;
}

export interface IFieldsBase extends INfeBaseState, IPaginationBaseState {}
export interface INfeUpdateState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  urlBack?: string;
  functionEmbebed?: any[];
  fieldsBase: IFieldsBase;
  numberSelectValue?: any;
  numberStartSelectOptions?: any;
  acessKeySelectValue?: any;
  acessKeyStartSelectOptions?: any;
  valueNfeSelectValue?: any;
  valueNfeStartSelectOptions?: any;
  buyDateSelectValue?: any;
  buyDateStartSelectOptions?: any;
  paymentDateSelectValue?: any;
  paymentDateStartSelectOptions?: any;

  nfeFileSelectValue?: any;
  nfeFileStartSelectOptions?: any;

  pharmacyStockSelectValue?: any;
  pharmacyStockStartSelectOptions?: any;

  supplierSelectValue?: any;
  supplierStartSelectOptions?: any;

  isNew: boolean;
  supplierFantasyName?: any;

  supplierId?: any;
}

// Reducer

export default (state: NfeState = initialState, action): NfeState => {
  actualState = state;
  if (
    action.type.includes('_PENDING') &&
    !actionPendind.includes(action.type.replace('_PENDING', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_PENDING', ''))
  ) {
    actionPendind.push(action.type.replace('_PENDING', ''));
  }

  if (
    action.type.includes('_FULFILLED') &&
    actionPendind.includes(action.type.replace('_FULFILLED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_FULFILLED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_FULFILLED', '')), 1);
  }

  if (
    action.type.includes('_REJECTED') &&
    actionPendind.includes(action.type.replace('_REJECTED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_REJECTED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_REJECTED', '')), 1);
  }

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_NFE_LIST_EXPORT):
    case REQUEST(ACTION_TYPES.FETCH_NFE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_NFE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_NFE):
    case REQUEST(ACTION_TYPES.UPDATE_NFE):
    case REQUEST(ACTION_TYPES.CREATE_NFE_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.UPDATE_NFE_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.DELETE_NFE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_NFE_LIST_EXPORT):
    case FAILURE(ACTION_TYPES.FETCH_NFE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_NFE):
    case FAILURE(ACTION_TYPES.CREATE_NFE):
    case FAILURE(ACTION_TYPES.UPDATE_NFE):
    case FAILURE(ACTION_TYPES.CREATE_NFE_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.UPDATE_NFE_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.DELETE_NFE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_NFE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_NFE_OFFSET):
      if (action.payload.data && action.payload.data.length > 0) {
        return {
          ...state,
          loading: false,
          entity: convertEntityBuffers(action.payload.data[0]),
        };
      }
      return {
        ...state,
        loading: false,
      };

    case SUCCESS(ACTION_TYPES.FETCH_NFE):
      return {
        ...state,
        loading: false,
        entity: convertEntityBuffers(action.payload.data),
      };
    case SUCCESS(ACTION_TYPES.CREATE_NFE):
    case SUCCESS(ACTION_TYPES.UPDATE_NFE):
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_NFE_AND_GO_LIST):
    case SUCCESS(ACTION_TYPES.UPDATE_NFE_AND_GO_LIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_NFE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };

    case ACTION_TYPES.SHOW_MODAL: {
      const openModal = state.openModal;
      openModal[action.payload.type] = action.payload.show;
      return {
        ...state,
        openModal,
      };
    }
    default:
      return state;
  }
};

const apiUrl = BASE_API_VERSION_PATH + 'nfes';

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

// Actions

// Actions
export type ICrudGetAllActionNfe<T> = (
  supplierId?: any,
  number?: any,
  valueNfe?: any,
  buyDateStart?: any,
  buyDateEnd?: any,
  extraFilters?: {},
  page?: number,
  size?: number,
  sort?: string,
  selectFields?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getEntitiesFilter = (filters?, page?, size?, sort?, selectFields = '') => {
  const requestUrl = `${apiUrl}?page=${page >= 0 ? page : 0}&size=${size >= 0 ? size : 20}&sort=${sort ? sort : 'id,asc'}&`;
  const requestFilters = filters.map(v => v.column + '.' + v.operation + '=' + v.value).join('&');
  return {
    type: ACTION_TYPES.FETCH_NFE_LIST,
    payload: axios.get<INfe>(`${requestUrl}${requestFilters}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getAllEntities = (filters?, sort?, selectFields = '') => {
  if (
    (actualState.totalItems > 0 && actualState.entities.length === actualState.totalItems) ||
    actionPendind.includes(ACTION_TYPES.FETCH_NFE_LIST)
  ) {
    return {
      type: ACTION_TYPES.FETCH_NFE_LIST + '_FROM_CACHE',
      payload: new Promise(function (resolve, reject) {
        resolve('done');
      }),
    };
  }

  const idsRequest = filters && typeof filters['ids'] !== 'undefined' && filters['ids'] ? `id.in=${filters['ids'].join(',')}&` : '';
  const supplierRequest =
    filters && typeof filters['supplierId'] !== 'undefined' && filters['supplierId'] ? `supplier.id.in=${filters['supplierId']}&` : '';
  const numberRequest =
    filters && typeof filters['number'] !== 'undefined' && filters['number'] ? `number.contains=${filters['number']}&` : '';
  const valueNfeRequest =
    filters && typeof filters['valueNfe'] !== 'undefined' && filters['valueNfe'] ? `valueNfe.contains=${filters['valueNfe']}&` : '';
  let buyDateStartRequest = '';
  let buyDateEndRequest = '';
  if (
    filters &&
    typeof filters['buyDateStart'] !== 'undefined' &&
    filters['buyDateStart'] &&
    !(typeof filters['buyDateEnd'] !== 'undefined' && filters['buyDateEnd'])
  ) {
    buyDateStartRequest = `buyDate.greaterThan=${moment(filters['buyDateStart']).format('YYYY-MM-DD')}&`;
  } else if (
    filters &&
    !(typeof filters['buyDateStart'] !== 'undefined' && filters['buyDateStart']) &&
    typeof filters['buyDateEnd'] !== 'undefined' &&
    filters['buyDateEnd']
  ) {
    buyDateEndRequest = `buyDate.lessThan=${moment(filters['buyDateEnd']).format('YYYY-MM-DD')}&`;
  } else if (
    filters &&
    typeof filters['buyDateStart'] !== 'undefined' &&
    filters['buyDateStart'] &&
    typeof filters['buyDateEnd'] !== 'undefined' &&
    filters['buyDateEnd']
  ) {
    buyDateStartRequest = `buyDate.between=${moment(filters['buyDateStart']).format('YYYY-MM-DD')},${moment(filters['buyDateEnd']).format(
      'YYYY-MM-DD'
    )}&`;
  }

  const requestUrl = `${apiUrl}${`?page=${0}&size=${1000000}&sort=${sort ? sort : 'id,asc'}&`}`;
  return {
    type: ACTION_TYPES.FETCH_NFE_LIST,
    payload: axios.get<INfe>(
      `${requestUrl}${idsRequest}${supplierRequest}${numberRequest}${valueNfeRequest}${buyDateStartRequest}${buyDateEndRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntities: ICrudGetAllActionNfe<INfe> = (
  supplierId,
  number,
  valueNfe,
  buyDateStart,
  buyDateEnd,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const supplierRequest = supplierId ? `supplier.id.in=${supplierId}&` : '';
  const numberRequest = number ? `number.contains=${number}&` : '';
  const valueNfeRequest = valueNfe ? `valueNfe.contains=${valueNfe}&` : '';
  let buyDateStartRequest = '';
  let buyDateEndRequest = '';
  if (typeof buyDateStart !== 'undefined' && buyDateStart && !(typeof buyDateEnd !== 'undefined' && buyDateEnd)) {
    buyDateStartRequest = `buyDate.greaterThan=${moment(buyDateStart).format('YYYY-MM-DD')}&`;
  } else if (!(typeof buyDateStart !== 'undefined' && buyDateStart) && typeof buyDateEnd !== 'undefined' && buyDateEnd) {
    buyDateEndRequest = `buyDate.lessThan=${moment(buyDateEnd).format('YYYY-MM-DD')}&`;
  } else if (typeof buyDateStart !== 'undefined' && buyDateStart && typeof buyDateEnd !== 'undefined' && buyDateEnd) {
    buyDateStartRequest = `buyDate.between=${moment(buyDateStart).format('YYYY-MM-DD')},${moment(buyDateEnd).format('YYYY-MM-DD')}&`;
  }

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_NFE_LIST,
    payload: axios.get<INfe>(
      `${requestUrl}${extraFiltersRequest}${supplierRequest}${numberRequest}${valueNfeRequest}${buyDateStartRequest}${buyDateEndRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntityOffset: any = (
  supplierId,
  number,
  valueNfe,
  buyDateStart,
  buyDateEnd,
  extraFilters,
  offset,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const supplierRequest = supplierId ? `supplier.id.in=${supplierId}&` : '';
  const numberRequest = number ? `number.contains=${number}&` : '';
  const valueNfeRequest = valueNfe ? `valueNfe.contains=${valueNfe}&` : '';
  let buyDateStartRequest = '';
  let buyDateEndRequest = '';
  if (typeof buyDateStart !== 'undefined' && buyDateStart && !(typeof buyDateEnd !== 'undefined' && buyDateEnd)) {
    buyDateStartRequest = `buyDate.greaterThan=${moment(buyDateStart).format('YYYY-MM-DD')}&`;
  } else if (!(typeof buyDateStart !== 'undefined' && buyDateStart) && typeof buyDateEnd !== 'undefined' && buyDateEnd) {
    buyDateEndRequest = `buyDate.lessThan=${moment(buyDateEnd).format('YYYY-MM-DD')}&`;
  } else if (typeof buyDateStart !== 'undefined' && buyDateStart && typeof buyDateEnd !== 'undefined' && buyDateEnd) {
    buyDateStartRequest = `buyDate.between=${moment(buyDateStart).format('YYYY-MM-DD')},${moment(buyDateEnd).format('YYYY-MM-DD')}&`;
  }

  const requestUrl = `${apiUrl}${sort ? `?page=${offset}&size=${1}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_NFE_OFFSET,
    payload: axios.get<INfe>(
      `${requestUrl}${extraFiltersRequest}${supplierRequest}${numberRequest}${valueNfeRequest}${buyDateStartRequest}${buyDateEndRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntity: ICrudGetAction<INfe> = (id, selectFields = '') => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_NFE,
    payload: axios.get<INfe>(requestUrl, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntitiesExport: ICrudGetAllActionNfe<INfe> = (
  supplierId,
  number,
  valueNfe,
  buyDateStart,
  buyDateEnd,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const supplierRequest = supplierId ? `supplier.id.in=${supplierId}&` : '';
  const numberRequest = number ? (Array.isArray(status) ? `number.in=${number.join(',')}&` : `number.contains=${number}&`) : '';
  const valueNfeRequest = valueNfe ? (Array.isArray(status) ? `valueNfe.in=${valueNfe.join(',')}&` : `valueNfe.contains=${valueNfe}&`) : '';
  let buyDateStartRequest = '';
  let buyDateEndRequest = '';
  if (typeof buyDateStart !== 'undefined' && buyDateStart && !(typeof buyDateEnd !== 'undefined' && buyDateEnd)) {
    buyDateStartRequest = `buyDate.greaterThan=${moment(buyDateStart).format('YYYY-MM-DD')}&`;
  } else if (!(typeof buyDateStart !== 'undefined' && buyDateStart) && typeof buyDateEnd !== 'undefined' && buyDateEnd) {
    buyDateEndRequest = `buyDate.lessThan=${moment(buyDateEnd).format('YYYY-MM-DD')}&`;
  } else if (typeof buyDateStart !== 'undefined' && buyDateStart && typeof buyDateEnd !== 'undefined' && buyDateEnd) {
    buyDateStartRequest = `buyDate.between=${moment(buyDateStart).format('YYYY-MM-DD')},${moment(buyDateEnd).format('YYYY-MM-DD')}&`;
  }

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_NFE_LIST,
    payload: axios.get<INfe>(
      `${requestUrl}${extraFiltersRequest}${supplierRequest}${numberRequest}${valueNfeRequest}${buyDateStartRequest}${buyDateEndRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const createEntity: ICrudPutAction<INfe> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = {
    ...entity,
    buyDate: entity.buyDate ? moment(entity.buyDate).format('YYYY-MM-DD') : null,
    paymentDate: entity.paymentDate ? moment(entity.paymentDate).format('YYYY-MM-DD') : null,
  };
  const result = await dispatch({
    type: listFiltersPage && listFiltersPage['reloadList'] !== false ? ACTION_TYPES.CREATE_NFE_AND_GO_LIST : ACTION_TYPES.CREATE_NFE,
    payload: axios.post(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const updateEntity: ICrudPutAction<INfe> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = {
    ...entity,
    buyDate: entity.buyDate ? moment(entity.buyDate).format('YYYY-MM-DD') : null,
    paymentDate: entity.paymentDate ? moment(entity.paymentDate).format('YYYY-MM-DD') : null,
  };
  const result = await dispatch({
    type: listFiltersPage && listFiltersPage['reloadList'] !== false ? ACTION_TYPES.UPDATE_NFE_AND_GO_LIST : ACTION_TYPES.UPDATE_NFE,
    payload: axios.put(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const deleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_NFE,
    payload: axios.delete(requestUrl),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const showModal = view => ({
  payload: { type: view, show: true },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const hideModal = view => ({
  payload: { type: view, show: false },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const getUrlBack = (location): string => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  let urlBack = _urlBase.searchParams.get('urlBack') || '';
  urlBack = urlBack.trim();
  urlBack = urlBack.startsWith('/') ? urlBack.slice(1) : urlBack;
  urlBack = urlBack.endsWith('/') ? urlBack.slice(0, -1) : urlBack;
  return urlBack ? '/' + urlBack + '/' : '';
};

export const getNfeState = (location): INfeBaseState => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  const extraFilters = jsonParse(_urlBase.searchParams.get('extraFilters') || '{}');
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;

  const supplierId = _urlBase.searchParams.get('supplier') || '';
  const number = _urlBase.searchParams.get('number') || '';
  const valueNfe = _urlBase.searchParams.get('valueNfe') || '';
  const buyDateStart = _urlBase.searchParams.get('buyDateStart') || '';
  const buyDateEnd = _urlBase.searchParams.get('buyDateEnd') || '';
  return {
    baseFilters,
    extraFilters,
    offset,
    supplierId: supplierId ? supplierId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
    number,
    valueNfe,
    buyDateStart,
    buyDateEnd,
  };
};

export const getEntityFiltersURL = (state, offset = null) => {
  return (
    'baseFilters=' +
    state.baseFilters +
    '&extraFilters=' +
    encodeURI(JSON.stringify(state.extraFilters)) +
    '&page=' +
    state.activePage +
    '&' +
    'size=' +
    state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    state.sort +
    ',' +
    state.order +
    '&' +
    (state.supplierId ? 'supplier=' + state.supplierId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.number ? 'number=' + state.number + '&' : '') +
    (state.valueNfe ? 'valueNfe=' + state.valueNfe + '&' : '') +
    (state.buyDate ? 'buyDate=' + state.buyDate + '&' : '') +
    ''
  );
};
