import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { IMedicalRecord } from 'app/shared/model/medical-record.model';

export interface IMedicalRecordFile {
  id?: number;
  name?: string;
  filenameContentType?: string;
  filenameBase64?: string;
  filename?: any;
  medicalRecord?: IMedicalRecord;
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  medicalRecord: {
    isMulti: true, // many-to-one - other-side
    entity: 'MedicalRecord',
    urlParameters: {
      enpoint: 'medical-records',
      filters: 'medicalRecordFile.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'medicalRecordFile.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'medicalRecordFile.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<IMedicalRecordFile> = {};
