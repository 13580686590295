/* eslint complexity: ["error", 500] */
import React from 'react';

import { connect } from 'react-redux';
import Select from 'react-select';

import { Link, RouteComponentProps } from 'react-router-dom';

import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { Button, Col, Row, Table, Label, UncontrolledCollapse, CardBody } from 'reactstrap';
import { AvForm, div, AvInput } from 'availity-reactstrap-validation';
import { JhiItemCount } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { IRootState } from 'app/shared/reducers';
import {
  updateEntity,
  deleteEntity,
  getUrlBack,
  getSimproState,
  ISimproBaseState,
  getEntityFiltersURL,
  getEntities,
  reset,
} from './simpro.reducer';

import { ITEMS_PER_PAGE, IPaginationBaseState, getSortState } from 'app/shared/util/pagination.constants';

import { StatePropsExtended, DispatchPropsExtended, IExtendedState, IExtendedProps } from './extended/simpro';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import moment from 'moment';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

export interface ISimproProps
  extends StateProps,
    DispatchProps,
    StatePropsExtended,
    DispatchPropsExtended,
    IExtendedProps,
    RouteComponentProps<{ url: string }> {}
export interface ISimproStateAux extends ISimproBaseState, IExtendedState {}

export interface ISimproState extends ISimproStateAux, IPaginationBaseState {}

export class Simpro extends React.Component<ISimproProps, ISimproState> {
  protected myFormRef: any;

  constructor(props: ISimproProps) {
    super(props);
    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      listCheckedID: [],
      isCheckedAll: false,
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      urlBack: getUrlBack(this.props.location),
      ...getSimproState(this.props.location),
    };
  }

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({ listCheckedID: this.props.simproList.map(simpro => simpro.id) });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({ listCheckedID: this.props.simproList.map(simpro => simpro.id) });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  removeEntitySelected() {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  }

  saveEntityMany = () => {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  };

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  deleteEntity(simproEntity) {
    MySwal.fire({
      title: <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>,
      html: (
        <Translate contentKey="generadorApp.simpro.delete.question" interpolate={{ id: simproEntity.id }}>
          Are you sure you want to delete this Simpro?
        </Translate>
      ),
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#e70015',
      cancelButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="ban" />
          &nbsp; <Translate contentKey="generadorApp.simpro.btnCancel">Cancel</Translate>
        </>
      ),
      confirmButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="trash" />
          &nbsp;<Translate contentKey="generadorApp.simpro.btnDelete">Delete</Translate>
        </>
      ),
    }).then(async response => {
      if (response.isConfirmed) {
        await this.props.deleteEntity(simproEntity.id, { reloadList: false });
        this.getEntities();
        MySwal.fire({
          title: <Translate contentKey="entity.delete.title.confirmed">Confirmed delete operation</Translate>,
          icon: 'success',
        });
      }
    });
  }

  cancelFilters = () => {
    this.setState(
      {
        cdUsuario: '',
        descricao: '',
        vigencia: '',
        identif: '',
        status: '',
        extraFilters: {},
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
        activePage: 0,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();

    const selectFieldsList = ['fabrica', 'descricao', 'pcEmFab', 'pcFrFab', 'pcEmVen', 'pcFrVen'];

    const { cdUsuario, descricao, vigencia, identif, status, extraFilters, activePage, itemsPerPage, sort, order } = this.state;
    return this.props.getEntities(
      cdUsuario,
      descricao,
      vigencia,
      identif,
      status,
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(',')
    );
  };

  updateEntity = entity => {
    const selectFieldsList = ['fabrica', 'descricao', 'pcEmFab', 'pcFrFab', 'pcEmVen', 'pcFrVen'];

    const { cdUsuario, descricao, vigencia, identif, status, extraFilters, activePage, itemsPerPage, sort, order } = this.state;
    this.props.updateEntity(entity, [
      cdUsuario,
      descricao,
      vigencia,
      identif,
      status,
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(','),
    ]);
  };

  renderHeader() {
    const { medicineMaterials, simproList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.simpro.home.title">Simpro table</Translate>
          </span>
          <Button id="togglerFilterSimpro" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.simpro.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          &nbsp;
          <Link
            to={`${match.url}/new?${getEntityFiltersURL(state)}`}
            className="btn btn-primary float-right jh-create-entity"
            id="jh-create-entity"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="generadorApp.simpro.btnNewItem">Create new</Translate>
          </Link>{' '}
          &nbsp;
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.simpro.home.title">Simpro table</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    const { medicineMaterials, simproList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <UncontrolledCollapse toggler="#togglerFilterSimpro">
          <CardBody>
            <AvForm ref={el => (this.myFormRef = el)} id="form-filter" onSubmit={this.filterEntity}>
              <div className="row mt-1 ml-3 mr-3">
                {this.state.baseFilters !== 'cdUsuario' ? (
                  <Col md="3" className="col-filter-simpro-cdUsuario">
                    <Row className="mr-1 mt-1">
                      <Label id="cdUsuarioLabel" for="simpro-cdUsuario">
                        <Translate contentKey="generadorApp.simpro.cdUsuario">Cd Usuario</Translate>
                      </Label>

                      <AvInput type="text" name="cdUsuario" id="simpro-cdUsuario" value={this.state.cdUsuario} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'descricao' ? (
                  <Col md="3" className="col-filter-simpro-descricao">
                    <Row className="mr-1 mt-1">
                      <Label id="descricaoLabel" for="simpro-descricao">
                        <Translate contentKey="generadorApp.simpro.descricao">Descricao</Translate>
                      </Label>

                      <AvInput type="text" name="descricao" id="simpro-descricao" value={this.state.descricao} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'vigencia' ? (
                  <Col md="2" className="col-filter-simpro-vigencia">
                    <Row className="mr-1 mt-1">
                      <Label id="vigenciaLabel" for="simpro-vigencia">
                        <Translate contentKey="generadorApp.simpro.vigencia">Vigencia</Translate>
                      </Label>

                      <AvInput type="text" name="vigencia" id="simpro-vigencia" value={this.state.vigencia} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'identif' ? (
                  <Col md="2" className="col-filter-simpro-identif">
                    <Row className="mr-1 mt-1">
                      <Label id="identifLabel" for="simpro-identif">
                        <Translate contentKey="generadorApp.simpro.identif">Identif</Translate>
                      </Label>

                      <AvInput type="text" name="identif" id="simpro-identif" value={this.state.identif} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'status' ? (
                  <Col md="2" className="col-filter-simpro-status">
                    <Row className="mr-1 mt-1">
                      <Label id="statusLabel" for="simpro-status">
                        <Translate contentKey="generadorApp.simpro.status">Status</Translate>
                      </Label>
                      <Select
                        id="simpro-status"
                        className={'css-select-control'}
                        value={
                          this.state.status === 0
                            ? { value: 0, label: translate('generadorApp.simpro.status_filter_without') }
                            : this.state.status === 1
                            ? { value: 1, label: translate('generadorApp.simpro.status_filter_with') }
                            : { value: '', label: translate('generadorApp.simpro.status_filter_all') }
                        }
                        options={[
                          { value: '', label: translate('generadorApp.simpro.status_filter_all') },
                          { value: 1, label: translate('generadorApp.simpro.status_filter_with') },
                          { value: 0, label: translate('generadorApp.simpro.status_filter_without') },
                        ]}
                        onChange={(options: any) => this.setState({ status: options['value'] })}
                        name="status"
                      />
                    </Row>
                  </Col>
                ) : null}
              </div>

              <div className="row mb-2 mr-4 justify-content-end">
                <Button color="primary" type="submit">
                  <i className="fa fa-filter" aria-hidden={'true'}></i>&nbsp;
                  <Translate contentKey="generadorApp.simpro.btnFilter">Filter</Translate>
                </Button>
                &nbsp;
                <Button color="secondary" onClick={this.cancelFilters}>
                  <FontAwesomeIcon icon="trash-alt" />
                  &nbsp;
                  <Translate contentKey="generadorApp.simpro.btnCleanFilter">Clean Filter</Translate>
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </UncontrolledCollapse>
      </>
    );
  }

  renderTable() {
    const { medicineMaterials, simproList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {simproList && simproList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null).length > 0 ? (
          <>
            <Table
              id="simpro-table-list"
              responsive
              aria-describedby="simpro-heading"
              className={'table-hover table-striped mt-4 table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.simpro.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'fabrica' ? (
                    <th className="hand" onClick={sortFunction('fabrica')}>
                      <Translate contentKey="generadorApp.simpro.fabrica">Fabrica</Translate>
                      <FontAwesomeIcon icon={state.sort === 'fabrica' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'descricao' ? (
                    <th className="hand" onClick={sortFunction('descricao')}>
                      <Translate contentKey="generadorApp.simpro.descricao">Descricao</Translate>
                      <FontAwesomeIcon icon={state.sort === 'descricao' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'pcEmFab' ? (
                    <th className="hand" onClick={sortFunction('pcEmFab')}>
                      <Translate contentKey="generadorApp.simpro.pcEmFab">Pc Em Fab</Translate>
                      <FontAwesomeIcon icon={state.sort === 'pcEmFab' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'pcFrFab' ? (
                    <th className="hand" onClick={sortFunction('pcFrFab')}>
                      <Translate contentKey="generadorApp.simpro.pcFrFab">Pc Fr Fab</Translate>
                      <FontAwesomeIcon icon={state.sort === 'pcFrFab' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'pcEmVen' ? (
                    <th className="hand" onClick={sortFunction('pcEmVen')}>
                      <Translate contentKey="generadorApp.simpro.pcEmVen">Pc Em Ven</Translate>
                      <FontAwesomeIcon icon={state.sort === 'pcEmVen' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'pcFrVen' ? (
                    <th className="hand" onClick={sortFunction('pcFrVen')}>
                      <Translate contentKey="generadorApp.simpro.pcFrVen">Pc Fr Ven</Translate>
                      <FontAwesomeIcon icon={state.sort === 'pcFrVen' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}

                  {state.baseFilters !== 'lastModifiedDate' ? (
                    <th className="hand" onClick={sortFunction('lastModifiedDate')}>
                      <Translate contentKey="generadorApp.simpro.lastModifiedDate" />
                      <FontAwesomeIcon
                        icon={state.sort === 'lastModifiedDate' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'}
                      />
                    </th>
                  ) : null}
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {simproList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((simpro, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.simpro.field_id">ID</Translate>
                        </b>
                        {simpro.id}
                      </td>

                      {state.baseFilters !== 'fabrica' ? (
                        <td id="fabrica-cell">
                          <b className="visible-xs"> Fabrica </b>

                          {simpro.fabrica}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'descricao' ? (
                        <td id="descricao-cell">
                          <b className="visible-xs"> Descricao </b>

                          {simpro.descricao}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'pcEmFab' ? (
                        <td id="pcEmFab-cell">
                          <b className="visible-xs"> Pc Em Fab </b>
                          R$ {(simpro.pcEmFab * 1).toFixed(2).replace('.', ',')}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'pcFrFab' ? (
                        <td id="pcFrFab-cell">
                          <b className="visible-xs"> Pc Fr Fab </b>
                          R$ {(simpro.pcFrFab * 1).toFixed(3).replace('.', ',')}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'pcEmVen' ? (
                        <td id="pcEmVen-cell">
                          <b className="visible-xs"> Pc Em Ven </b>
                          R$ {(simpro.pcEmVen * 1).toFixed(2).replace('.', ',')}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'pcFrVen' ? (
                        <td id="pcFrVen-cell">
                          <b className="visible-xs"> Pc Fr Ven </b>
                          R$ {(simpro.pcFrVen * 1).toFixed(3).replace('.', ',')}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'lastModifiedDate' ? (
                        <td id="lastModifiedDate-cell">
                          {simpro.lastModifiedDate ? moment(simpro.lastModifiedDate).format(APP_LOCAL_DATE_FORMAT) : ''}
                        </td>
                      ) : null}
                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          {hasAnyAuthority(props.userAccount, ['simpro'], 'view') ? (
                            <Button tag={Link} to={`${match.url}/${simpro.id}`} color="info" size="sm">
                              <FontAwesomeIcon icon="eye" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.simpro.home.notFound">No Simpro table found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const { medicineMaterials, simproList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={simproList && simproList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const { medicineMaterials, simproList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel className="simpro simpro-list-table">
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToProps = ({ ...storeState }: IRootState) => {
  return {
    medicineMaterials: storeState.medicineMaterial.entities,
    simproList: storeState.simpro.entities,
    totalItems: storeState.simpro.totalItems,
    userAccount: storeState.authentication.account,
    loading: storeState.simpro.loading,
  };
};

export const mapDispatchToProps = {
  getEntities,

  deleteEntity,
  updateEntity,
  reset,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Simpro);
