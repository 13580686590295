/* eslint complexity: ["error", 100] */
import axios from 'axios';
import { ICrudDeleteAction, IPayloadResult } from 'react-jhipster';

import { IPayload } from 'react-jhipster/src/type/redux-action.type';

import { BASE_API_VERSION_PATH } from 'app/config/constants';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IJhiReports, defaultValue } from 'app/shared/model/jhi-reports.model';

export declare type ICrudPutAction<T> = (data?: T, listFiltersPage?) => IPayload<T> | IPayloadResult<T>;
export const ACTION_TYPES = {
  FETCH_JHIREPORTS_LIST_EXPORT: 'jhiReports/FETCH_JHIREPORTS_LIST_EXPORT',
  FETCH_JHIREPORTS_LIST: 'jhiReports/FETCH_JHIREPORTS_LIST',
  FETCH_JHIREPORTS: 'jhiReports/FETCH_JHIREPORTS',
  CREATE_JHIREPORTS: 'jhiReports/CREATE_JHIREPORTS',
  UPDATE_JHIREPORTS: 'jhiReports/UPDATE_JHIREPORTS',
  DELETE_JHIREPORTS: 'jhiReports/DELETE_JHIREPORTS',
  SET_BLOB: 'jhiReports/SET_BLOB',
  RESET: 'jhiReports/RESET',
  SHOW_MODAL: 'jhiReports/SHOW_MODAL',
};

interface IJhiReportsList {
  [index: string]: IJhiReports;
}

const initialState = {
  loading: false,
  openModal: {
    list: false,
    view: false,
    detail: false,
  },
  errorMessage: null,
  reportsList: {} as IJhiReportsList,
  entities: [] as ReadonlyArray<IJhiReports>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type JhiReportsState = Readonly<typeof initialState>;

export interface IJhiReportsBaseState {
  baseFilters: any;
  name: any;
  querySql: any;
  queryParams: any;
  queryResult: any;
}

export interface IJhiReportsUpdateState {
  fieldsBase: IJhiReportsBaseState;
  nameSelectValue?: any;
  querySqlSelectValue?: any;
  queryParamsSelectValue?: any;
  queryResultSelectValue?: any;

  isNew: boolean;
}

// Reducer

export default (state: JhiReportsState = initialState, action): JhiReportsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_JHIREPORTS_LIST_EXPORT):
    case REQUEST(ACTION_TYPES.FETCH_JHIREPORTS_LIST):
    case REQUEST(ACTION_TYPES.FETCH_JHIREPORTS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_JHIREPORTS):
    case REQUEST(ACTION_TYPES.UPDATE_JHIREPORTS):
    case REQUEST(ACTION_TYPES.DELETE_JHIREPORTS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_JHIREPORTS_LIST_EXPORT):
    case FAILURE(ACTION_TYPES.FETCH_JHIREPORTS_LIST):
    case FAILURE(ACTION_TYPES.FETCH_JHIREPORTS):
    case FAILURE(ACTION_TYPES.CREATE_JHIREPORTS):
    case FAILURE(ACTION_TYPES.UPDATE_JHIREPORTS):
    case FAILURE(ACTION_TYPES.DELETE_JHIREPORTS):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_JHIREPORTS_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_JHIREPORTS):
      action.payload.data.querySql = action.payload.data.querySql
        ? Buffer.from(action.payload.data.querySql).toString()
        : action.payload.data.querySql;
      action.payload.data.queryParams = action.payload.data.queryParams
        ? Buffer.from(action.payload.data.queryParams).toString()
        : action.payload.data.queryParams;
      action.payload.data.queryResult = action.payload.data.queryResult
        ? Buffer.from(action.payload.data.queryResult).toString()
        : action.payload.data.queryResult;

      // eslint-disable-next-line no-case-declarations
      const reportsList = { ...state.reportsList };
      if (action.payload.data && action.payload.data.name) {
        reportsList[action.payload.data.name] = action.payload.data.result;
      }
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
        reportsList,
      };
    case SUCCESS(ACTION_TYPES.CREATE_JHIREPORTS):
    case SUCCESS(ACTION_TYPES.UPDATE_JHIREPORTS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_JHIREPORTS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.SET_BLOB: {
      const { name, data, contentType, fileName } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [name + 'Base64']: data,
          [name + 'ContentType']: contentType,
          [name + 'FileName']: fileName,
        },
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    case ACTION_TYPES.SHOW_MODAL: {
      const openModal = state.openModal;
      openModal[action.payload.type] = action.payload.show;
      return {
        ...state,
        openModal,
      };
    }
    default:
      return state;
  }
};

const apiUrl = BASE_API_VERSION_PATH + 'jhi-reports';

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

// Actions

// Actions
export type ICrudGetAllActionJhiReports<T> = (
  name?: any,
  querySql?: any,
  queryParams?: any,
  queryResult?: any,
  page?: number,
  size?: number,
  sort?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getAllEntities = (filters?, sort?) => {
  const nameRequest = filters && typeof filters['name'] !== 'undefined' && filters['name'] ? `name.contains=${filters['name']}&` : '';
  const querySqlRequest =
    filters && typeof filters['querySql'] !== 'undefined' && filters['querySql'] ? `querySql.contains=${filters['querySql']}&` : '';
  const queryParamsRequest =
    filters && typeof filters['queryParams'] !== 'undefined' && filters['queryParams']
      ? `queryParams.contains=${filters['queryParams']}&`
      : '';
  const queryResultRequest =
    filters && typeof filters['queryResult'] !== 'undefined' && filters['queryResult']
      ? `queryResult.contains=${filters['queryResult']}&`
      : '';

  const requestUrl = `${apiUrl}${`?page=${0}&size=${1000000}&sort=${sort ? sort : 'id,asc'}&`}`;
  return {
    type: ACTION_TYPES.FETCH_JHIREPORTS_LIST,
    payload: axios.get<IJhiReports>(`${requestUrl}${nameRequest}${querySqlRequest}${queryParamsRequest}${queryResultRequest}`),
  };
};

export const getEntities: ICrudGetAllActionJhiReports<IJhiReports> = (name, querySql, queryParams, queryResult, page, size, sort) => {
  const nameRequest = name ? `name.contains=${name}&` : '';
  const querySqlRequest = querySql ? `querySql.contains=${querySql}&` : '';
  const queryParamsRequest = queryParams ? `queryParams.contains=${queryParams}&` : '';
  const queryResultRequest = queryResult ? `queryResult.contains=${queryResult}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_JHIREPORTS_LIST,
    payload: axios.get<IJhiReports>(`${requestUrl}${nameRequest}${querySqlRequest}${queryParamsRequest}${queryResultRequest}`),
  };
};
export const getReport = (id, parameters) => {
  const requestUrl = `${apiUrl}/${id}`;
  const url = [];
  for (const key in parameters) {
    if (Object.prototype.hasOwnProperty.call(parameters, key)) {
      url.push(key + '=' + encodeURIComponent(parameters[key]));
    }
  }
  return {
    type: ACTION_TYPES.FETCH_JHIREPORTS,
    payload: axios.get(requestUrl + '?' + url.join('&')),
  };
};

export const callFunction = async (name, parameters) => {
  const requestUrl = `${apiUrl}/function/${name}`;
  const url = [];
  for (const key in parameters) {
    if (Object.prototype.hasOwnProperty.call(parameters, key)) {
      url.push(key + '=' + encodeURIComponent(parameters[key]));
    }
  }

  const result = await axios.get(requestUrl + '?' + url.join('&'));
  return result;
};

export const getEntitiesExport: ICrudGetAllActionJhiReports<IJhiReports> = (name, querySql, queryParams, queryResult, page, size, sort) => {
  const nameRequest = name ? `name.contains=${name}&` : '';
  const querySqlRequest = querySql ? `querySql.contains=${querySql}&` : '';
  const queryParamsRequest = queryParams ? `queryParams.contains=${queryParams}&` : '';
  const queryResultRequest = queryResult ? `queryResult.contains=${queryResult}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_JHIREPORTS_LIST,
    payload: axios.get<IJhiReports>(`${requestUrl}${nameRequest}${querySqlRequest}${queryParamsRequest}${queryResultRequest}`),
  };
};

export const createEntity: ICrudPutAction<IJhiReports> = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_JHIREPORTS,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  if (listFiltersPage !== false && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const updateEntity: ICrudPutAction<IJhiReports> = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_JHIREPORTS,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  if (listFiltersPage !== false && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const deleteEntity: ICrudDeleteAction<IJhiReports> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_JHIREPORTS,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const setBlob = (name, data, contentType?, fileName?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType,
    fileName,
  },
});

export const showModal = view => ({
  payload: { type: view, show: true },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const hideModal = view => ({
  payload: { type: view, show: false },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const getJhiReportsState = (location): IJhiReportsBaseState => {
  const url = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  const baseFilters = url.searchParams.get('baseFilters') || '';

  const name = url.searchParams.get('name') || '';
  const querySql = url.searchParams.get('querySql') || '';
  const queryParams = url.searchParams.get('queryParams') || '';
  const queryResult = url.searchParams.get('queryResult') || '';
  return {
    baseFilters,
    name,
    querySql,
    queryParams,
    queryResult,
  };
};

export const getEntityFiltersURL = (state, offset = null) => {
  return (
    'baseFilters=' +
    state.baseFilters +
    '&page=' +
    state.activePage +
    '&' +
    'size=' +
    state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    state.sort +
    ',' +
    state.order +
    '&' +
    'name=' +
    state.name +
    '&' +
    'querySql=' +
    state.querySql +
    '&' +
    'queryParams=' +
    state.queryParams +
    '&' +
    'queryResult=' +
    state.queryResult +
    '&' +
    ''
  );
};
