import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import { Translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity, ICompanyBaseState, getUrlBack, getCompanyState, getEntityFiltersURL } from './company.reducer';

export interface ICompanyState {
  fieldsBase: ICompanyBaseState;
}

export interface ICompanyDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class CompanyDetail extends React.Component<ICompanyDetailProps, ICompanyState> {
  constructor(props: Readonly<ICompanyDetailProps>) {
    super(props);
    this.state = {
      ...this.state,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getCompanyState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
    };
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = '_back=1' + (offset !== null ? '&offset=' + offset : '');
    Object.keys(fieldsBase).map(key => {
      url += '&' + key + '=' + fieldsBase[key];
    });
    return url;
  };

  UNSAFE_componentWillMount() {
    this.props.getEntity(this.props.match.params['id']);
  }

  renderHeader() {
    const { companyEntity } = this.props;
    const state = this.state;
    return (
      <>
        <div id="page-heading">
          {' '}
          <h2>
            <Translate contentKey="generadorApp.company.detail.title">Company</Translate>
            <b>{companyEntity.id}</b>
          </h2>
        </div>

        <div className="pull-right">
          <Button tag={Link} to={'/company?' + getEntityFiltersURL(this.state.fieldsBase)} replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.company.btnBack">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/company/${companyEntity.id}/edit?+${getEntityFiltersURL(this.state.fieldsBase)}`}
            replace
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.company.btnEdit">Edit</Translate>
            </span>
          </Button>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>

          <li className="breadcrumb-item active">
            <Link to={'/company'}>Companies</Link>
          </li>
          <li className="breadcrumb-item active">Companies details</li>
        </ol>
      </>
    );
  }

  renderBody() {
    const { companyEntity } = this.props;
    const state = this.state;
    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            <Row className="jh-entity-details">
              <Col className="view-detail-row company-socialReason-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top company-socialReason-detail-label" md="12">
                    <dt>
                      <span id="socialReason">
                        <Translate contentKey="generadorApp.company.socialReason">Social Reason</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top company-socialReason-detail-value" md="12">
                    <dd>{companyEntity.socialReason}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row company-fantasyName-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top company-fantasyName-detail-label" md="12">
                    <dt>
                      <span id="fantasyName">
                        <Translate contentKey="generadorApp.company.fantasyName">Fantasy Name</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top company-fantasyName-detail-value" md="12">
                    <dd>{companyEntity.fantasyName}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row company-cnpj-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top company-cnpj-detail-label" md="12">
                    <dt>
                      <span id="cnpj">
                        <Translate contentKey="generadorApp.company.cnpj">Cnpj</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top company-cnpj-detail-value" md="12">
                    <dd>{companyEntity.cnpj}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row company-zipCode-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top company-zipCode-detail-label" md="12">
                    <dt>
                      <span id="zipCode">
                        <Translate contentKey="generadorApp.company.zipCode">Zip Code</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top company-zipCode-detail-value" md="12">
                    <dd>{companyEntity.zipCode}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row company-street-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top company-street-detail-label" md="12">
                    <dt>
                      <span id="street">
                        <Translate contentKey="generadorApp.company.street">Street</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top company-street-detail-value" md="12">
                    <dd>{companyEntity.street}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row company-complement-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top company-complement-detail-label" md="12">
                    <dt>
                      <span id="complement">
                        <Translate contentKey="generadorApp.company.complement">Complement</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top company-complement-detail-value" md="12">
                    <dd>{companyEntity.complement}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row company-number-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top company-number-detail-label" md="12">
                    <dt>
                      <span id="number">
                        <Translate contentKey="generadorApp.company.number">Number</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top company-number-detail-value" md="12">
                    <dd>{companyEntity.number}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row company-neighborhood-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top company-neighborhood-detail-label" md="12">
                    <dt>
                      <span id="neighborhood">
                        <Translate contentKey="generadorApp.company.neighborhood">Neighborhood</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top company-neighborhood-detail-value" md="12">
                    <dd>{companyEntity.neighborhood}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row company-city-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top company-city-detail-label" md="12">
                    <dt>
                      <span id="city">
                        <Translate contentKey="generadorApp.company.city">City</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top company-city-detail-value" md="12">
                    <dd>{companyEntity.city}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row company-uf-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top company-uf-detail-label" md="12">
                    <dt>
                      <span id="uf">
                        <Translate contentKey="generadorApp.company.uf">Uf</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top company-uf-detail-value" md="12">
                    <dd>{companyEntity.uf}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row company-regANS-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top company-regANS-detail-label" md="12">
                    <dt>
                      <span id="regANS">
                        <Translate contentKey="generadorApp.company.regANS">Reg. ANS</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top company-regANS-detail-value" md="12">
                    <dd>{companyEntity.regANS}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row company-regCNES-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top company-regCNES-detail-label" md="12">
                    <dt>
                      <span id="regCNES">
                        <Translate contentKey="generadorApp.company.regCNES">Reg. CNES</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top company-regCNES-detail-value" md="12">
                    <dd>{companyEntity.regCNES}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row company-technicalManager-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top company-technicalManager-detail-label" md="12">
                    <dt>
                      <span id="technicalManager">
                        <Translate contentKey="generadorApp.company.technicalManager">Technical manager</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top company-technicalManager-detail-value" md="12">
                    <dd>{companyEntity.technicalManager}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row company-technicalManagerNurse-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top company-technicalManagerNurse-detail-label" md="12">
                    <dt>
                      <span id="technicalManagerNurse">
                        <Translate contentKey="generadorApp.company.technicalManagerNurse">Technical nurse manager</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top company-technicalManagerNurse-detail-value" md="12">
                    <dd>{companyEntity.technicalManagerNurse}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row company-inscription-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top company-inscription-detail-label" md="12">
                    <dt>
                      <span id="inscription">
                        <Translate contentKey="generadorApp.company.inscription">Inscription</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top company-inscription-detail-value" md="12">
                    <dd>{companyEntity.inscription}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row company-councilCode-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top company-councilCode-detail-label" md="12">
                    <dt>
                      <span id="councilCode">
                        <Translate contentKey="generadorApp.company.councilCode">Council Code</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top company-councilCode-detail-value" md="12">
                    <dd>{companyEntity.councilCode}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row company-ufCode-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top company-ufCode-detail-label" md="12">
                    <dt>
                      <span id="ufCode">
                        <Translate contentKey="generadorApp.company.ufCode">UF Code</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top company-ufCode-detail-value" md="12">
                    <dd>{companyEntity.ufCode}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row company-cboCode-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top company-cboCode-detail-label" md="12">
                    <dt>
                      <span id="cboCode">
                        <Translate contentKey="generadorApp.company.cboCode">CBO Code</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top company-cboCode-detail-value" md="12">
                    <dd>{companyEntity.cboCode}</dd>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { companyEntity } = this.props;
    const state = this.state;
    return (
      <div>
        <Panel className="company company-detail">
          <PanelHeader>{this.renderHeader()}</PanelHeader>

          <PanelBody>{this.renderBody()}</PanelBody>
        </Panel>
      </div>
    );
  }
}

export const mapStateToProps = ({ company, ...storeState }: IRootState) => ({
  companyEntity: company.entity,
});

export const mapDispatchToProps = {
  getEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetail);
