/* eslint complexity: ["error", 500] */
import React from 'react';

import { connect } from 'react-redux';

import {
  getFilterFromSelect,
  getFormFromSelect,
  getListAxios,
  getValueRecursive,
  showFieldsSelectAsync,
  checkAnyValueFields,
} from 'app/shared/util/entity-utils';
import Select from 'react-select';
import { toast } from 'react-toastify';
import SelectAsync from 'react-select/async';
import { Link, RouteComponentProps } from 'react-router-dom';

import { Button, Col, Row, Table, Label, UncontrolledCollapse, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { setFileData, JhiItemCount } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { IRootState } from 'app/shared/reducers';
import {
  updateEntity,
  getUrlBack,
  getPushState,
  IPushBaseState,
  getEntityFiltersURL,
  getEntities,
  IPushUpdateState as IBaseUpdateState,
  reset,
  getEntity,
  deleteEntity,
  createEntity,
  setBlob,
} from './push.reducer';

import { ITEMS_PER_PAGE, IPaginationBaseState, getSortState } from 'app/shared/util/pagination.constants';

import { StatePropsExtended, DispatchPropsExtended, IExtendedState, IExtendedProps } from './extended/push';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

export interface IPushProps
  extends StateProps,
    DispatchProps,
    StatePropsExtended,
    DispatchPropsExtended,
    IExtendedProps,
    RouteComponentProps<{ url: string }> {
  entityEmbebedPai?: any;
}

export interface IPushState extends IPushBaseState, IPaginationBaseState, IBaseUpdateState, IExtendedState {
  showModalForm?: boolean;
  showModalEdit?: number;
  showModalEditView?: boolean;
}

export class Push extends React.Component<IPushProps, IPushState> {
  protected myFormRef: any;
  protected myFormRefNew: any;
  protected myFormRefEdit: any;

  typeFileInput: any;

  messageFileInput: any;

  constructor(props: IPushProps) {
    super(props);
    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      listCheckedID: [],
      isCheckedAll: false,
      showModalForm: false,
      showModalEdit: null,
      urlBack: getUrlBack(this.props.location),
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      ...getPushState(this.props.location),
      clientSelectValue: null,

      patientSelectValue: null,

      professionalSelectValue: null,

      categorySelectValue: null,

      specialtySelectValue: null,

      typeSelectValue: null,
      messageSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getPushState(this.props.location),
      },
      clientFantasyName: null,
      patientName: null,
      professionalName: null,
      categoryName: null,
      specialtyName: null,
      isNew: false,
    };
  }

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({ listCheckedID: this.props.pushList.map(push => push.id) });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({ listCheckedID: this.props.pushList.map(push => push.id) });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  removeEntitySelected() {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  }

  saveEntityMany = () => {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  };

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  cancelFilters = () => {
    this.setState(
      {
        type: '',
        message: '',
        patientId: '',
        clientId: '',
        professionalId: '',
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
        activePage: 0,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();

    const selectFieldsList = ['patient.name', 'client.fantasyName', 'type', 'message'];
    const { type, message, patientId, clientId, professionalId, extraFilters, activePage, itemsPerPage, sort, order } = this.state;
    return this.props.getEntities(
      type,
      message,
      getFilterFromSelect(patientId, 'many-to-one'),
      getFilterFromSelect(clientId, 'many-to-one'),
      getFilterFromSelect(professionalId, 'many-to-one'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(',')
    );
  };

  updateEntity = entity => {
    const selectFieldsList = ['patient.name', 'client.fantasyName', 'type', 'message'];

    const { type, message, patientId, clientId, professionalId, extraFilters, activePage, itemsPerPage, sort, order } = this.state;
    this.props.updateEntity(entity, [
      type,
      message,
      getFilterFromSelect(patientId, 'many-to-one'),
      getFilterFromSelect(clientId, 'many-to-one'),
      getFilterFromSelect(professionalId, 'many-to-one'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(','),
    ]);
  };

  onBlobChange = (isAnImage, name, fileInput) => event => {
    const fileName = event.target.files[0].name;
    setFileData(event, (contentType, data) => this.props.setBlob(name, data, contentType, fileName), isAnImage);
    const varAux = {};
    varAux[name + 'SelectValue'] = fileName;
    this.setState(varAux);
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined);
  };

  saveEntity = (event: any, errors: any, values: any, isNew: any) => {
    const selectFieldsList = ['patient.name', 'client.fantasyName', 'type', 'message'];

    if (errors.length === 0) {
      const { pushEntity } = this.props;
      const _entity = {
        ...pushEntity,
        // ...values,

        client: getFormFromSelect(this.state.clientSelectValue, 'many-to-one'), // many-to-one - otherSide

        patient: getFormFromSelect(this.state.patientSelectValue, 'many-to-one'), // many-to-one - otherSide

        category: getFormFromSelect(this.state.categorySelectValue, 'many-to-one'), // many-to-one - otherSide

        specialty: getFormFromSelect(this.state.specialtySelectValue, 'many-to-one'), // many-to-one - otherSide

        professional: getFormFromSelect(this.state.professionalSelectValue, 'many-to-one'), // many-to-one - otherSide

        type: this.state.typeSelectValue === null ? null : this.state.typeSelectValue,
        message: this.state.messageSelectValue,
      };
      const entity = _entity;

      const {
        type,
        message,
        patientId,
        clientId,
        professionalId,
        extraFilters,
        activePage,
        itemsPerPage,
        sort,
        order,
      } = this.state.fieldsBase;

      if (isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              type,
              message,
              getFilterFromSelect(patientId, 'many-to-one'),
              getFilterFromSelect(clientId, 'many-to-one'),
              getFilterFromSelect(professionalId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              type,
              message,
              getFilterFromSelect(patientId, 'many-to-one'),
              getFilterFromSelect(clientId, 'many-to-one'),
              getFilterFromSelect(professionalId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
      this.setState({ showModalForm: false });
      this.setState({ showModalEdit: null });
    } else {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.specialty.' + errors[0]) }));
    }
  };

  componentDidUpdate(prevProps) {}

  deleteEntity(pushEntity) {
    MySwal.fire({
      title: <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>,
      html: (
        <Translate contentKey="generadorApp.push.delete.question" interpolate={{ id: pushEntity.id }}>
          Are you sure you want to delete this Push?
        </Translate>
      ),
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#e70015',
      cancelButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="ban" />
          &nbsp; <Translate contentKey="generadorApp.push.btnCancel">Cancel</Translate>
        </>
      ),
      confirmButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="trash" />
          &nbsp;<Translate contentKey="generadorApp.push.btnDelete">Delete</Translate>
        </>
      ),
    }).then(async response => {
      if (response.isConfirmed) {
        await this.props.deleteEntity(pushEntity.id, { reloadList: false });
        this.getEntities();
        MySwal.fire({
          title: <Translate contentKey="entity.delete.title.confirmed">Confirmed delete operation</Translate>,
          icon: 'success',
        });
      }
    });
  }

  renderNewModal() {
    return (
      <Modal size={'xl'} isOpen={this.state.showModalForm} toggle={() => this.setState({ showModalForm: false })}>
        <ModalHeader toggle={() => this.setState({ showModalForm: false })}>{this.renderHeaderUpdate({}, true)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefNew = el)}
          model={{}}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, true)}
        >
          <ModalBody>{this.renderBodyUpdate({}, true)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalForm: false })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.push.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefNew && this.myFormRefNew.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.push.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    );
  }

  getFullEntityFromState(v) {
    return {
      id: v.id,
      type: this.state.typeSelectValue,
      message: this.state.messageSelectValue,
      client: this.state.clientSelectValue,
      patient: this.state.patientSelectValue,
      professional: this.state.professionalSelectValue,
      category: this.state.categorySelectValue,
      specialty: this.state.specialtySelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    let p: any = null;

    p = v.client;
    const clientEntity = p ? { ...p, value: p.id, label: p['fantasyName'] ? p.fantasyName : '' } : p;

    p = v.patient;
    const patientEntity = p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : p;

    p = v.professional;
    const professionalEntity = p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : p;

    p = v.category;
    const categoryEntity = p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : p;

    p = v.specialty;
    const specialtyEntity = p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : p;

    return {
      typeSelectValue: v.type, // type,
      messageSelectValue: v.message, // message,
      clientSelectValue: clientEntity,
      patientSelectValue: patientEntity,
      professionalSelectValue: professionalEntity,
      categorySelectValue: categoryEntity,
      specialtySelectValue: specialtyEntity,
    };
  }

  openUpdateModal(v, edit = true) {
    this.props.getEntity(v.id);
    this.setState({
      showModalEdit: v.id,
      showModalEditView: edit,
      ...this.getFullEntitySelectValue(v),
    });
  }

  renderUpdateModal() {
    const { pushEntity, entityEmbebedPai } = this.props;
    return pushEntity && pushEntity.id ? (
      <Modal size={'xl'} isOpen={this.state.showModalEdit === pushEntity.id} toggle={() => this.setState({ showModalEdit: null })}>
        <ModalHeader toggle={() => this.setState({ showModalEdit: null })}>{this.renderHeaderUpdate(pushEntity, false)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefEdit = el)}
          model={{
            ...pushEntity,
          }}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, false)}
        >
          <ModalBody>{this.renderBodyUpdate(pushEntity, false)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalEdit: null })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.push.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefEdit && this.myFormRefEdit.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.push.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    ) : (
      <> </>
    );
  }

  renderHeaderUpdate(pushEntity, isNew) {
    const { entityEmbebedPai, clients, patients, professionals, categories, specialties, loading } = this.props;

    const { message } = pushEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {isNew ? (
              <Translate contentKey="generadorApp.push.home.createLabel">Create a Push</Translate>
            ) : (
              <Translate contentKey="generadorApp.push.home.editLabel">Edit a Push</Translate>
            )}
          </span>
        </div>
      </>
    );
  }
  renderBodyUpdate(pushEntity, isNew) {
    const { entityEmbebedPai, clients, patients, professionals, categories, specialties, loading } = this.props;

    const { message } = pushEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
              <Col md="3">
              <Label className="mt-2 label-single-line"for="push-id">
                <Translate contentKey="generadorApp.push.field_id">ID</Translate>
              </Label>
              </Col> */}
                      <Col md="12">
                        <AvInput id="push-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'type' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="typeLabel" for="push-type">
                              <Translate contentKey="generadorApp.push.type">Type</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="push-type"
                              className={'css-select-control'}
                              value={
                                this.state.typeSelectValue
                                  ? {
                                      value: this.state.typeSelectValue,
                                      label: translate('generadorApp.TypePush.' + this.state.typeSelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.push.type') }
                              }
                              options={[
                                { value: 'PACIENTE', label: translate('generadorApp.TypePush.PACIENTE') },
                                { value: 'PROFISSIONAL', label: translate('generadorApp.TypePush.PROFISSIONAL') },
                              ]}
                              onChange={(options: any) => this.setState({ typeSelectValue: options['value'] })}
                              name="type"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ typeSelectValue: evt.target.value })}
                      type="hidden"
                      name="type"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {checkAnyValueFields(this.state, 'typeSelectValue', 'PACIENTE', true) && baseFilters !== 'client' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="push-client">
                              <Translate contentKey="generadorApp.push.client">Client</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="push-client"
                              name={'client'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.clientSelectValue ? this.state.clientSelectValue : ''}
                              onChange={options => this.setState({ clientSelectValue: options })}
                              defaultOptions={this.state.clientStartSelectOptions ? this.state.clientStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.clientStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.clientStartSelectOptions === undefined) {
                                  const result = await getListAxios('clients', {}, 0, 100, 'fantasyName,asc', 'fantasyName');
                                  this.setState({
                                    clientStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'fantasyName') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'clients',
                                  { 'fantasyName.contains': inputValue },
                                  0,
                                  100,
                                  'fantasyName,asc',
                                  'fantasyName'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'fantasyName') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="client"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}

                  {checkAnyValueFields(this.state, 'typeSelectValue', 'PACIENTE', true) && baseFilters !== 'patient' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="push-patient">
                              <Translate contentKey="generadorApp.push.patient">Patient</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="push-patient"
                              name={'patient'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.patientSelectValue ? this.state.patientSelectValue : ''}
                              onChange={options => this.setState({ patientSelectValue: options })}
                              defaultOptions={this.state.patientStartSelectOptions ? this.state.patientStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.patientStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.patientStartSelectOptions === undefined) {
                                  const result = await getListAxios('patients', {}, 0, 100, 'name,asc', 'name');
                                  this.setState({
                                    patientStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios('patients', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="patient"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}

                  {checkAnyValueFields(this.state, 'typeSelectValue', 'PROFISSIONAL', true) && baseFilters !== 'category' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="push-category">
                              <Translate contentKey="generadorApp.push.category">Category</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="push-category"
                              name={'category'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.categorySelectValue ? this.state.categorySelectValue : ''}
                              onChange={options => this.setState({ categorySelectValue: options })}
                              defaultOptions={this.state.categoryStartSelectOptions ? this.state.categoryStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.categoryStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.categoryStartSelectOptions === undefined) {
                                  const result = await getListAxios(
                                    'categories',
                                    { 'levelGroup.sub.equals': 'ESPECIALIDADE' },
                                    0,
                                    100,
                                    'name,asc',
                                    'name'
                                  );
                                  this.setState({
                                    categoryStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'categories',
                                  { 'levelGroup.sub.equals': 'ESPECIALIDADE', 'name.contains': inputValue },
                                  0,
                                  100,
                                  'name,asc',
                                  'name'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="category"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}

                  {checkAnyValueFields(this.state, 'typeSelectValue', 'PROFISSIONAL', true) && baseFilters !== 'specialty' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="push-specialty">
                              <Translate contentKey="generadorApp.push.specialty">Specialty</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="push-specialty"
                              name={'specialty'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.specialtySelectValue ? this.state.specialtySelectValue : ''}
                              onChange={options => this.setState({ specialtySelectValue: options })}
                              defaultOptions={this.state.specialtyStartSelectOptions ? this.state.specialtyStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.specialtyStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.specialtyStartSelectOptions === undefined) {
                                  const result = await getListAxios('specialties', {}, 0, 100, 'name,asc', 'name');
                                  this.setState({
                                    specialtyStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'specialties',
                                  { 'name.contains': inputValue },
                                  0,
                                  100,
                                  'name,asc',
                                  'name'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="specialty"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}

                  {checkAnyValueFields(this.state, 'typeSelectValue', 'PROFISSIONAL', true) && baseFilters !== 'professional' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="push-professional">
                              <Translate contentKey="generadorApp.push.professional">Professional</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="push-professional"
                              name={'professional'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.professionalSelectValue ? this.state.professionalSelectValue : ''}
                              onChange={options => this.setState({ professionalSelectValue: options })}
                              defaultOptions={this.state.professionalStartSelectOptions ? this.state.professionalStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.professionalStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.professionalStartSelectOptions === undefined) {
                                  const result = await getListAxios('professionals', {}, 0, 100, 'name,asc', 'name');
                                  this.setState({
                                    professionalStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'professionals',
                                  { 'name.contains': inputValue },
                                  0,
                                  100,
                                  'name,asc',
                                  'name'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="professional"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}

                  {baseFilters !== 'message' ? (
                    <Col md="12">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="messageLabel" for="push-message">
                              <Translate contentKey="generadorApp.push.message">Message</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <AvInput
                              onChange={evt => this.setState({ messageSelectValue: evt.target.value })}
                              validate={{}}
                              id="push-message"
                              type="textarea"
                              name="message"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ messageSelectValue: evt.target.value })}
                      type="hidden"
                      name="message"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  renderHeader() {
    const { clients, patients, professionals, categories, specialties, pushList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.push.home.title">Push</Translate>
          </span>
          <Button id="togglerFilterPush" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.push.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          &nbsp;
          {hasAnyAuthority(props.userAccount, ['push'], 'resgister') ? (
            <Button
              onClick={() =>
                this.setState({
                  showModalForm: true,
                  typeSelectValue: null,
                  messageSelectValue: null,

                  clientStartSelectOptions: undefined,
                  clientSelectValue: null,

                  patientStartSelectOptions: undefined,
                  patientSelectValue: null,

                  professionalStartSelectOptions: undefined,
                  professionalSelectValue: null,

                  categoryStartSelectOptions: undefined,
                  categorySelectValue: null,

                  specialtyStartSelectOptions: undefined,
                  specialtySelectValue: null,
                  isNew: true,
                })
              }
              color="primary"
              className="btn btn-primary float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="plus" />
              &nbsp;
              <Translate contentKey="generadorApp.push.btnNewItem">Create new</Translate>
            </Button>
          ) : (
            <></>
          )}{' '}
          &nbsp;
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.push.home.title">Push</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    const { clients, patients, professionals, categories, specialties, pushList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <UncontrolledCollapse toggler="#togglerFilterPush">
          <CardBody>
            <AvForm ref={el => (this.myFormRef = el)} id="form-filter" onSubmit={this.filterEntity}>
              <div className="row mt-1 ml-3 mr-3">
                {this.state.baseFilters !== 'type' ? (
                  <Col md="3" className="col-filter-push-type">
                    <Row className="mr-1 mt-1">
                      <Label id="typeLabel" for="push-type">
                        <Translate contentKey="generadorApp.push.type">Type</Translate>
                      </Label>
                      <Select
                        id="push-type"
                        className={'css-select-control'}
                        value={
                          this.state.type
                            ? { value: this.state.type, label: translate('generadorApp.TypePush.' + this.state.type) }
                            : { value: '', label: translate('generadorApp.push.type') }
                        }
                        options={[
                          { value: '', label: translate('generadorApp.push.type') },
                          { value: 'PACIENTE', label: translate('generadorApp.TypePush.PACIENTE') },
                          { value: 'PROFISSIONAL', label: translate('generadorApp.TypePush.PROFISSIONAL') },
                        ]}
                        onChange={(options: any) => this.setState({ type: options['value'] })}
                        name="type"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'message' ? (
                  <Col md="3" className="col-filter-push-message">
                    <Row className="mr-1 mt-1">
                      <Label id="messageLabel" for="push-message">
                        <Translate contentKey="generadorApp.push.message">Message</Translate>
                      </Label>
                      <AvInput id="push-message" type="textarea" name="message" />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'patient' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="push-patient">
                          <Translate contentKey="generadorApp.push.patient">Patient</Translate>
                        </Label>
                        <SelectAsync
                          id="push-patient"
                          isMulti
                          className={'css-select-control'}
                          name={'patient'}
                          cacheOptions
                          value={this.state.patientId}
                          onChange={options => this.setState({ patientId: options })}
                          defaultOptions={this.state.patientStartFilter ? this.state.patientStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.patientStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.patientStartFilter === undefined) {
                              const result = await getListAxios('patients', {}, 0, 100, 'name,asc', 'name');
                              this.setState({
                                patientStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios('patients', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'client' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="push-client">
                          <Translate contentKey="generadorApp.push.client">Client</Translate>
                        </Label>
                        <SelectAsync
                          id="push-client"
                          isMulti
                          className={'css-select-control'}
                          name={'client'}
                          cacheOptions
                          value={this.state.clientId}
                          onChange={options => this.setState({ clientId: options })}
                          defaultOptions={this.state.clientStartFilter ? this.state.clientStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.clientStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.clientStartFilter === undefined) {
                              const result = await getListAxios('clients', {}, 0, 100, 'fantasyName,asc', 'fantasyName');
                              this.setState({
                                clientStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'fantasyName') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios(
                              'clients',
                              { 'fantasyName.contains': inputValue },
                              0,
                              100,
                              'fantasyName,asc',
                              'fantasyName'
                            );
                            callback(
                              result.data
                                ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'fantasyName') }))
                                : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'professional' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="push-professional">
                          <Translate contentKey="generadorApp.push.professional">Professional</Translate>
                        </Label>
                        <SelectAsync
                          id="push-professional"
                          isMulti
                          className={'css-select-control'}
                          name={'professional'}
                          cacheOptions
                          value={this.state.professionalId}
                          onChange={options => this.setState({ professionalId: options })}
                          defaultOptions={this.state.professionalStartFilter ? this.state.professionalStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.professionalStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.professionalStartFilter === undefined) {
                              const result = await getListAxios('professionals', {}, 0, 100, 'name,asc', 'name');
                              this.setState({
                                professionalStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios('professionals', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}
              </div>

              <div className="row mb-2 mr-4 justify-content-end">
                <Button color="primary" type="submit">
                  <i className="fa fa-filter" aria-hidden={'true'}></i>&nbsp;
                  <Translate contentKey="generadorApp.push.btnFilter">Filter</Translate>
                </Button>
                &nbsp;
                <Button color="secondary" onClick={this.cancelFilters}>
                  <FontAwesomeIcon icon="trash-alt" />
                  &nbsp;
                  <Translate contentKey="generadorApp.push.btnCleanFilter">Clean Filter</Translate>
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </UncontrolledCollapse>
      </>
    );
  }

  renderTable() {
    const { clients, patients, professionals, categories, specialties, pushList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {pushList && pushList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null).length > 0 ? (
          <>
            <Table
              id="push-table-list"
              responsive
              aria-describedby="push-heading"
              className={'table-hover table-striped table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.push.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'patient' ? (
                    <th>
                      <Translate contentKey="generadorApp.push.patient">Patient</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'client' ? (
                    <th>
                      <Translate contentKey="generadorApp.push.client">Client</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'type' ? (
                    <th className="hand" onClick={sortFunction('type')}>
                      <Translate contentKey="generadorApp.push.type">Type</Translate>
                      <FontAwesomeIcon icon={state.sort === 'type' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'message' ? (
                    <th className="hand" onClick={sortFunction('message')}>
                      <Translate contentKey="generadorApp.push.message">Message</Translate>
                      <FontAwesomeIcon icon={state.sort === 'message' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}

                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {pushList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((push, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.push.field_id">ID</Translate>
                        </b>
                        {push.id}
                      </td>

                      {state.baseFilters !== 'patient' ? (
                        <td id="patient-cell">
                          <b className="visible-xs"> Patient </b>
                          {push ? (
                            <span data-type-rel="2-many-to-one-other-side">{getValueRecursive(push, 'patient.name'.split('.'), '')}</span>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'client' ? (
                        <td id="client-cell">
                          <b className="visible-xs"> Client </b>
                          {push ? (
                            <span data-type-rel="2-many-to-one-other-side">
                              {getValueRecursive(push, 'client.fantasyName'.split('.'), '')}
                            </span>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'type' ? (
                        <td id="type-cell">
                          <b className="visible-xs"> Type </b>
                          {push.type ? <Translate contentKey={`generadorApp.TypePush.${push.type}`} /> : <> </>}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'message' ? (
                        <td id="message-cell">
                          <b className="visible-xs"> Message </b>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: push.message
                                ? Buffer.from(push.message)
                                    .toString()
                                    .replace(/(<([^>]+)>)/gi, '')
                                    .substring(0, 150)
                                : null,
                            }}
                          />
                        </td>
                      ) : null}

                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          {hasAnyAuthority(props.userAccount, ['push'], 'edit') ? (
                            <Button onClick={() => this.openUpdateModal(push)} color="primary" size="sm">
                              <FontAwesomeIcon icon="pencil-alt" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                          {hasAnyAuthority(props.userAccount, ['push'], 'canDelete') ? (
                            <Button onClick={() => this.deleteEntity(push)} color="danger" size="sm">
                              <FontAwesomeIcon icon="trash" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.push.home.notFound">No Push found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const { clients, patients, professionals, categories, specialties, pushList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={pushList && pushList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const { clients, patients, professionals, categories, specialties, pushList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel className="push push-all-in-one">
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
            {this.renderNewModal()}
            {this.renderUpdateModal()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToProps = ({ push, ...storeState }: IRootState) => ({
  clients: storeState.client.entities,
  patients: storeState.patient.entities,
  professionals: storeState.professional.entities,
  categories: storeState.category.entities,
  specialties: storeState.specialty.entities,
  pushList: push.entities,
  totalItems: push.totalItems,
  userAccount: storeState.authentication.account,
  loading: push.loading,

  // UPDATE
  pushEntity: push.entity,
});

export const mapDispatchToProps = {
  getEntities,

  updateEntity,
  reset,

  // UPDATE
  getEntity,
  setBlob,
  createEntity,

  // DELETE
  deleteEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Push);
