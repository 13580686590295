import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { INrStatusHistory } from 'app/shared/model/nr-status-history.model';
import { IPatientNursingPrescription } from 'app/shared/model/patient-nursing-prescription.model';
import { Status } from 'app/shared/model/enumerations/status.model';
import { QuoteFrequency } from 'app/shared/model/enumerations/quote-frequency.model';
import { Periodo } from 'app/shared/model/enumerations/periodo.model';

export interface IPatNrPrescProcediment {
  id?: number;
  description?: string;
  order?: number;
  continuous?: boolean;
  initialDate?: Moment;
  finalDate?: Moment;
  status?: Status;
  frequency?: QuoteFrequency;
  period?: Periodo;
  observation?: string;
  nrStatusHistory?: INrStatusHistory[];
  patientNursingPrescription?: IPatientNursingPrescription;
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  nrStatusHistory: {
    isMulti: false, // one-to-many - other-side
    entity: 'NrStatusHistory',
    urlParameters: {
      enpoint: 'nr-status-histories',
      filters: 'patNrPrescProcediment.id.equals',
    },
  },
  patientNursingPrescription: {
    isMulti: true, // many-to-one - other-side
    entity: 'PatientNursingPrescription',
    urlParameters: {
      enpoint: 'patient-nursing-prescriptions',
      filters: 'patNrPrescProcediment.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'patNrPrescProcediment.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'patNrPrescProcediment.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<IPatNrPrescProcediment> = {
  continuous: false,
};
