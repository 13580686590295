import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';
import moment from 'moment';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity, IPharmacyStockBaseState, getUrlBack, getPharmacyStockState, getEntityFiltersURL } from './pharmacy-stock.reducer';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IPharmacyStockState {
  fieldsBase: IPharmacyStockBaseState;
}

export interface IPharmacyStockDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class PharmacyStockDetail extends React.Component<IPharmacyStockDetailProps, IPharmacyStockState> {
  constructor(props: Readonly<IPharmacyStockDetailProps>) {
    super(props);
    this.state = {
      ...this.state,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getPharmacyStockState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
    };
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = '_back=1' + (offset !== null ? '&offset=' + offset : '');
    Object.keys(fieldsBase).map(key => {
      url += '&' + key + '=' + fieldsBase[key];
    });
    return url;
  };

  UNSAFE_componentWillMount() {
    this.props.getEntity(this.props.match.params['id']);
  }

  renderHeader() {
    const { pharmacyStockEntity } = this.props;
    const state = this.state;
    return (
      <>
        <div id="page-heading">
          {' '}
          <h2>
            <Translate contentKey="generadorApp.pharmacyStock.detail.title">PharmacyStock</Translate>
            <small>&nbsp; {pharmacyStockEntity['lot']}</small>
          </h2>
        </div>

        <div className="pull-right">
          <Button tag={Link} to={'/pharmacy-stock?' + getEntityFiltersURL(this.state.fieldsBase)} replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.pharmacyStock.btnBack">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/pharmacy-stock/${pharmacyStockEntity.id}/edit?+${getEntityFiltersURL(this.state.fieldsBase)}`}
            replace
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.pharmacyStock.btnEdit">Edit</Translate>
            </span>
          </Button>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>

          <li className="breadcrumb-item active">
            <Link to={'/pharmacy-stock'}>Pharmacy Stocks</Link>
          </li>
          <li className="breadcrumb-item active">Pharmacy Stocks details</li>
        </ol>
      </>
    );
  }

  renderBody() {
    const { pharmacyStockEntity } = this.props;
    const state = this.state;
    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            <Row className="jh-entity-details">
              <Col className="view-detail-row pharmacy-stock-medicineMaterial-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top pharmacy-stock-medicineMaterial-detail-label" md="12">
                    <dt>
                      <Translate contentKey="generadorApp.pharmacyStock.medicineMaterial">Medicine Material</Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pharmacy-stock-medicineMaterial-detail-value" md="12">
                    <dd>{pharmacyStockEntity.medicineMaterial ? pharmacyStockEntity.medicineMaterial.id : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pharmacy-stock-supplier-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top pharmacy-stock-supplier-detail-label" md="12">
                    <dt>
                      <Translate contentKey="generadorApp.pharmacyStock.supplier">Supplier</Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pharmacy-stock-supplier-detail-value" md="12">
                    <dd>{pharmacyStockEntity.supplier ? pharmacyStockEntity.supplier.id : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pharmacy-stock-externalPurchase-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top pharmacy-stock-externalPurchase-detail-label" md="12">
                    <dt>
                      <span id="externalPurchase">
                        <Translate contentKey="generadorApp.pharmacyStock.externalPurchase">External purchase</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pharmacy-stock-externalPurchase-detail-value" md="12">
                    <dd>
                      {pharmacyStockEntity.externalPurchase
                        ? translate('generadorApp.pharmacyStock.externalPurchase.true')
                        : translate('generadorApp.pharmacyStock.externalPurchase.false')}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pharmacy-stock-observation-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top pharmacy-stock-observation-detail-label" md="12">
                    <dt>
                      <span id="observation">
                        <Translate contentKey="generadorApp.pharmacyStock.observation">Observation</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pharmacy-stock-observation-detail-value" md="12">
                    <dd>{pharmacyStockEntity.observation}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pharmacy-stock-lot-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top pharmacy-stock-lot-detail-label" md="12">
                    <dt>
                      <span id="lot">
                        <Translate contentKey="generadorApp.pharmacyStock.lot">Lot</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pharmacy-stock-lot-detail-value" md="12">
                    <dd>{pharmacyStockEntity.lot}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pharmacy-stock-validate-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top pharmacy-stock-validate-detail-label" md="12">
                    <dt>
                      <span id="validate">
                        <Translate contentKey="generadorApp.pharmacyStock.validate">Validate</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pharmacy-stock-validate-detail-value" md="12">
                    <dd>{pharmacyStockEntity.validate ? moment(pharmacyStockEntity.validate).format(APP_LOCAL_DATE_FORMAT) : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pharmacy-stock-notificationDate-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top pharmacy-stock-notificationDate-detail-label" md="12">
                    <dt>
                      <span id="notificationDate">
                        <Translate contentKey="generadorApp.pharmacyStock.notificationDate">Date for notification of validity</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pharmacy-stock-notificationDate-detail-value" md="12">
                    <dd>
                      {pharmacyStockEntity.notificationDate ? moment(pharmacyStockEntity.notificationDate).format(APP_DATE_FORMAT) : ''}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pharmacy-stock-stockedLocation-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top pharmacy-stock-stockedLocation-detail-label" md="12">
                    <dt>
                      <span id="stockedLocation">
                        <Translate contentKey="generadorApp.pharmacyStock.stockedLocation">Stocked Location</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pharmacy-stock-stockedLocation-detail-value" md="12">
                    <dd>{pharmacyStockEntity.stockedLocation}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pharmacy-stock-princePaid-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top pharmacy-stock-princePaid-detail-label" md="12">
                    <dt>
                      <span id="princePaid">
                        <Translate contentKey="generadorApp.pharmacyStock.princePaid">Prince Paid</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pharmacy-stock-princePaid-detail-value" md="12">
                    <dd>{pharmacyStockEntity.princePaid}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pharmacy-stock-nfe-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top pharmacy-stock-nfe-detail-label" md="12">
                    <dt>
                      <Translate contentKey="generadorApp.pharmacyStock.nfe">Nfe</Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pharmacy-stock-nfe-detail-value" md="12">
                    <dd>{pharmacyStockEntity.nfe ? pharmacyStockEntity.nfe.id : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pharmacy-stock-quantity-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top pharmacy-stock-quantity-detail-label" md="12">
                    <dt>
                      <span id="quantity">
                        <Translate contentKey="generadorApp.pharmacyStock.quantity">Quantity</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pharmacy-stock-quantity-detail-value" md="12">
                    <dd>{pharmacyStockEntity.quantity}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row pharmacy-stock-status-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top pharmacy-stock-status-detail-label" md="12">
                    <dt>
                      <span id="status">
                        <Translate contentKey="generadorApp.pharmacyStock.status">Status</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top pharmacy-stock-status-detail-value" md="12">
                    <dd>
                      {translate(
                        'generadorApp.StatusPhamacyStock.' + (pharmacyStockEntity.status ? pharmacyStockEntity.status : 'PROXIMODAVALIDADE')
                      )}
                    </dd>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { pharmacyStockEntity } = this.props;
    const state = this.state;
    return (
      <div>
        <Panel className="pharmacy-stock pharmacy-stock-detail">
          <PanelHeader>{this.renderHeader()}</PanelHeader>

          <PanelBody>{this.renderBody()}</PanelBody>
        </Panel>
      </div>
    );
  }
}

export const mapStateToProps = ({ pharmacyStock, ...storeState }: IRootState) => ({
  pharmacyStockEntity: pharmacyStock.entity,
});

export const mapDispatchToProps = {
  getEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyStockDetail);
