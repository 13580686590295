import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Specialty from './specialty';

import SpecialtyUpdate from './specialty';

import SpecialtyStatusHistory from '../specialty-status-history/index';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute path={`${match.path}/:idSpecialty/specialty-status-history`} component={SpecialtyStatusHistory} />

      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={SpecialtyUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={SpecialtyUpdate} />
      <ErrorBoundaryRoute path={match.path} component={Specialty} />
    </Switch>

    <Switch>
      {/* <ErrorBoundaryRoute path={`${match.path}/:idSpecialty/specialty-status-history`} component={SpecialtyStatusHistory} /> */}
    </Switch>
  </>
);

export default Routes;
