/* eslint-disable no-console */
/* eslint complexity: ["error", 300] */
import React from 'react';
import { connect } from 'react-redux';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';
import SelectAsync from 'react-select/async';

import { APP_LOCAL_DATE_FORMAT, BASE_API_VERSION_PATH } from 'app/config/constants';

import Select from 'react-select';
import InputMask from 'react-input-mask';
import CurrencyInput from 'react-currency-input';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Col,
  Row,
  // Table,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { PadAttendance, mapStateToProps, mapDispatchToProps } from '../pad-attendance';
import { IRootState } from 'app/shared/reducers';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);

import { Link } from 'react-router-dom';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';
import axios from 'axios';

import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import { IPad } from 'app/shared/model/pad.model';
import { IQuoteRh } from 'app/shared/model/quote-rh.model';

import moment from 'moment';
import { Box } from './Box';
import { Dustbin } from './Dustbin';
import { toast } from 'react-toastify';
import { getFormFromSelect, getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';

const ptBR = {
  ...pt,
  options: {
    firstWeekContainsDate: 4,
    weekStartsOn: 0,
  },
};
const selectFieldsList = [
  'quoteRh.id',
  'quoteRh.quote.patient.name',
  'quoteRh.specialty.name',
  'quoteRh.specialty.category.name',
  'pad.id',
  'period',
  'professional.name',
  'checkIn',
  'checkOut',
];

registerLocale('ptBR', ptBR);

export interface IExtendedState {
  padAttendanceListAll?: any;
  padBaseFilter?: any;
  categoryStartSelectOptions?: any;
  categorySelectValue?: any;
  specialtyStartSelectOptions?: any;
  specialtySelectValue?: any;
  professionalSameZipCodeSelectValue?: any;
  professionalSameZipCodeStartSelectOptions?: any;
  professionalAllZipCodeSelectValue?: any;
  professionalAllZipCodeStartSelectOptions?: any;
  professionalSuggestedSelectValue?: any;
  professionalSuggestedStartSelectOptions?: any;
  defaultMin?: any;
  defaultMax?: any;
  draggedEvent?: any;
  events?: any;
  displayDragItemInCell?: any;
  quantitySelectValueMax?: any;
}
export interface IExtendedProps {
  entityEmbebedPai?: any;
}

export class PadAttendanceExtended extends PadAttendance {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      displayDragItemInCell: true,
      itemsPerPage: 1000,
    };

    this.moveEvent = this.moveEvent.bind(this);
    this.newEvent = this.newEvent.bind(this);
  }

  async UNSAFE_componentWillMount() {
    super.UNSAFE_componentWillMount();
    const padId = this.props.match.params['idPad'];
    await axios
      .get('/api/pads/' + padId, {
        headers: {
          'Select-Fields':
            'patient.client.id,patient.zipCode,patient.professionalBlockeds.id,patient.professionalPreferreds.id,quote.quoteRh.id,quote.quoteRh.specialty.category.id',
        },
      })
      .then(padBaseFilterData =>
        this.setState({
          padBaseFilter: padBaseFilterData ? padBaseFilterData['data'] : {},
        })
      );
    await axios
      .get('/api/pad-attendances?page=0&size=1000&sort=id,asc&pad.id.in=' + padId, {
        headers: {
          'Select-Fields':
            'quoteRh.specialty.category.name,quoteRh.specialty.name,quoteRh.quote.patient.name,professional.name,date,period,pad.id',
        },
      })
      .then(padAttendanceData =>
        this.setState({
          padAttendanceListAll: padAttendanceData ? padAttendanceData['data'] : {},
        })
      );
  }

  renderHeader() {
    const { professionals, pads, quoteRhs, padAttendanceList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    const padBaseFilter: IPad = this.state.padBaseFilter;
    const specialtiesInfo =
      padBaseFilter && padBaseFilter.quote && padBaseFilter.quote.quoteRh
        ? padBaseFilter.quote.quoteRh
            .map((v, i) => ({
              count: v.days,
              attendanceCount: padAttendanceList.filter(v1 => v1.quoteRh && v1.quoteRh.id === v.id).length,
            }))
            .filter(v => v.count > v.attendanceCount)
        : [];

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.padAttendance.home.title">Attendance</Translate>
          </span>
          <Button
            onClick={() => {
              const padId = this.props.match.params['idPad'];
              const padAttendanceListAll = this.state.padAttendanceListAll;
              axios
                .post(
                  '/api/pad-attendances/update-all',
                  { padId, padAttendanceListAll },
                  {
                    headers: {
                      'Select-Fields':
                        'patient.client.id,patient.zipCode,patient.professionalBlockeds.id,patient.professionalPreferreds.id,quote.quoteRh.id,quote.quoteRh.specialty.category.id',
                    },
                  }
                )
                .then(padBaseFilterData =>
                  MySwal.fire({
                    html: <Translate contentKey="generadorApp.PadAtendance.AcceptComponent">ObservationComponent</Translate>,
                  })
                );
            }}
            color="primary"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.auditEntity.btnSave">Save</Translate>
          </Button>
          {hasAnyAuthority(props.userAccount, ['pad'], 'resgister') && specialtiesInfo.length >= 1 ? (
            <Button
              onClick={() =>
                this.setState({
                  showModalForm: true,
                  periodSelectValue: null,
                  scheduleSelectValue: null,
                  pushSelectValue: null,
                  valuePadSelectValue: null,
                  frequencySelectValue: null,
                  quantitySelectValue: null,
                  dateSelectValue: null,
                  statusSelectValue: null,
                  cancellationReasonSelectValue: null,
                  checkInSelectValue: null,
                  checkOutSelectValue: null,
                  latSelectValue: null,
                  lngSelectValue: null,

                  professionalStartSelectOptions: undefined,
                  professionalSelectValue: null,

                  quoteRhStartSelectOptions: undefined,
                  quoteRhSelectValue: null,
                  isNew: true,
                })
              }
              color="primary"
              className="btn btn-primary float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="plus" />
              &nbsp;
              <Translate contentKey="generadorApp.padAttendance.btnNewItem">Create new</Translate>
            </Button>
          ) : (
            <></>
          )}{' '}
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/pad'}>
              <Translate contentKey="generadorApp.pad.home.title">Pads</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            {this.state.padSelectValue ? this.state.padSelectValue.label : this.props.match.params['idPad']}
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.padAttendance.home.title">Attendance</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    return <> {super.renderFilter()} </>;
  }

  renderDayContents = (day, date, padAttendanceList) => {
    const { professionals, pads, quoteRhs } = this.props;
    let padBaseFilter: IPad = null;
    if (pads) {
      pads.map(p => {
        if (p.id === parseInt(this.state.padId, 10)) padBaseFilter = p;
      });
    }

    return (
      <Dustbin
        padAttendanceList={padAttendanceList.filter(v =>
          v.date && moment(v.date).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD') ? true : false
        )}
        dia={moment(date)}
        updateOrders={console.info}
        removeItem={console.info}
        fieldsBase={this.state.fieldsBase}
        padBaseFilter={padBaseFilter}
        updateEntity={attendanceUpdated => {
          this.setState({
            padAttendanceListAll: [...this.state.padAttendanceListAll.filter(obj => obj.id !== attendanceUpdated.id), attendanceUpdated],
          });
        }}
        professionals={professionals}
        pads={pads}
        quoteRhs={quoteRhs}
      />
    );
  };

  renderTable() {
    const { pads } = this.props;
    const padAttendanceList = this.state.padAttendanceListAll ? this.state.padAttendanceListAll : [];

    const padAttendanceListInPlanning = padAttendanceList.filter(
      v => (typeof v.deletedAt === 'undefined' || v.deletedAt === null) && v.date === null
    );
    const padAttendanceListPlanned = padAttendanceList.filter(
      v => (typeof v.deletedAt === 'undefined' || v.deletedAt === null) && v.date !== null
    );
    const padBaseFilter: IPad = this.state.padBaseFilter;

    if (!padBaseFilter) return <> </>;

    return (
      <>
        <div>
          <div className="box-geral box-table5">
            <strong>
              <Translate contentKey="generadorApp.quote.patient">Paciente</Translate>:
            </strong>{' '}
            {showFieldsSelectAsync(padBaseFilter, 'patient.name', null)} <br />
            <strong>
              <Translate contentKey="generadorApp.quote.client">Operadora</Translate>:
            </strong>{' '}
            {showFieldsSelectAsync(padBaseFilter, 'patient.client.fantasyName', null)}
            <br />
            <strong>
              <Translate contentKey="generadorApp.quote.period">Data</Translate>:
            </strong>{' '}
            {moment(showFieldsSelectAsync(padBaseFilter, 'quote.from', null)).format(APP_LOCAL_DATE_FORMAT)} -{' '}
            {moment(showFieldsSelectAsync(padBaseFilter, 'quote.to', null)).format(APP_LOCAL_DATE_FORMAT)}
            <br />
            <strong>
              <Translate contentKey="generadorApp.quote.field_id">Quote</Translate>:
            </strong>{' '}
            {showFieldsSelectAsync(padBaseFilter, 'quote.id', null)}
            <br />
            <div className="divH div10"></div>
            <table className="table table-striped">
              <thead>
                <tr className="trcenter">
                  <th>
                    <Translate contentKey="generadorApp.pad.initDate" />
                  </th>
                  <th>
                    <Translate contentKey="generadorApp.pad.finalDate" />
                  </th>
                  <th>
                    <Translate contentKey="generadorApp.pad.quote.quoteRh.specialty.name" />
                  </th>
                  <th>
                    <Translate contentKey="generadorApp.quoteRh.frequency" />
                  </th>
                  <th>
                    <Translate contentKey="generadorApp.quoteRh.days" />
                  </th>
                  <th>
                    <Translate contentKey="generadorApp.pad.AttendancePlanned" />
                  </th>
                  <th>
                    <Translate contentKey="generadorApp.pad.ItemStatus" />
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {padBaseFilter && padBaseFilter.quote && padBaseFilter.quote.quoteRh ? (
                  padBaseFilter.quote.quoteRh.map((v, i) => {
                    const attendancePlanned = padAttendanceListPlanned.filter(v1 => v1.quoteRh && v1.quoteRh.id === v.id).length;
                    return (
                      <tr key={i}>
                        <td className="tdcenter">{moment(v.startDate).format(APP_LOCAL_DATE_FORMAT)}</td>
                        <td className="tdcenter">{moment(v.finishDate).format(APP_LOCAL_DATE_FORMAT)}</td>
                        <td> {v.specialty ? v.specialty.name : ''} </td>
                        <td> {v.frequency ? translate('generadorApp.QuoteFrequency.' + v.frequency) : ''}</td>
                        <td> {v.days} </td>
                        <td className="tdcenter">{attendancePlanned}</td>
                        <td className="tdcenter">
                          {attendancePlanned >= v.days ? (
                            <div style={{ padding: 0, margin: 0, textAlign: 'center' }} className="alert alert-success">
                              &nbsp;Completo&nbsp;
                            </div>
                          ) : (
                            <div style={{ padding: 0, margin: 0, textAlign: 'center' }} className="alert alert-danger">
                              &nbsp;Incompleto&nbsp;
                            </div>
                          )}
                        </td>
                        <td></td>
                      </tr>
                    );
                  })
                ) : (
                  <> </>
                )}
              </tbody>
            </table>
            <div className="divH div10"></div>
          </div>
        </div>

        {!this.props.loadingPadAttendance && !this.props.updatingPadAttendance ? (
          <div>
            <div className="pad-attendance-extended">
              <DndProvider backend={HTML5Backend}>
                <Row>
                  {padAttendanceListInPlanning.length > 0 ? (
                    <Col md={4}>
                      {padAttendanceListInPlanning.map((padAttendance, i) => {
                        return (
                          <Box
                            key={i}
                            md={4}
                            inCalendar={false}
                            name={'nnonoo'}
                            cor={'#f00'}
                            padAttendance={padAttendance}
                            onDrag={dia => {
                              if (
                                moment(padAttendance.quoteRh.startDate).format('YYYY-MM-DD') <= moment(dia).format('YYYY-MM-DD') &&
                                moment(padAttendance.quoteRh.finishDate).format('YYYY-MM-DD') >= moment(dia).format('YYYY-MM-DD')
                              ) {
                                this.setState({
                                  padAttendanceListAll: [
                                    ...this.state.padAttendanceListAll.filter(obj => obj.id !== padAttendance.id),
                                    { ...padAttendance, date: dia.format('YYYY-MM-DD') },
                                  ],
                                });
                              } else {
                                MySwal.fire({
                                  html: <Translate contentKey="generadorApp.PadAttendance.OutOfDate">ObservationComponent</Translate>,
                                });
                              }
                            }}
                            quoteRh={
                              padAttendance && padAttendance.quoteRh && padAttendance.quoteRh['id'] ? padAttendance.quoteRh['id'] : ''
                            }
                            specialty={
                              padAttendance && padAttendance.quoteRh && padAttendance.quoteRh['specialty']
                                ? padAttendance.quoteRh['specialty']['name']
                                : ''
                            }
                            specialtyCategory={
                              padAttendance &&
                              padAttendance.quoteRh &&
                              padAttendance.quoteRh['specialty'] &&
                              padAttendance.quoteRh['specialty']['category']
                                ? padAttendance.quoteRh['specialty']['category']['name']
                                : ''
                            }
                            patient={
                              padAttendance && padAttendance.quoteRh && padAttendance.quoteRh.quote && padAttendance.quoteRh.quote.patient
                                ? padAttendance.quoteRh.quote.patient.name
                                : ''
                            }
                            period={padAttendance.period ? translate(`generadorApp.PadAttendancePeriod.SMALL_${padAttendance.period}`) : ''}
                            schedule={padAttendance.schedule}
                            frequency={padAttendance.frequency ? translate(`generadorApp.QuoteFrequency.${padAttendance.frequency}`) : ''}
                            quantity={padAttendance.quantity}
                            pad={
                              padAttendance && padAttendance.pad && padAttendance.pad['id']
                                ? padAttendance.pad['id']
                                : pads
                                    .filter(v => v.padAttendance && v.padAttendance.map(b => b.id).includes(padAttendance.id))
                                    .map((v, k) => v.id)
                            }
                            professional={
                              padAttendance && padAttendance.professional && padAttendance.professional['name']
                                ? padAttendance.professional['name']
                                : ''
                            }
                          />
                        );
                      })}
                    </Col>
                  ) : (
                    <> </>
                  )}
                  {console.info(padAttendanceListInPlanning)}
                  <Col md={padAttendanceListInPlanning.length > 0 ? 8 : 12}>
                    <DatePicker
                      inline
                      style={{}}
                      dateFormat="dd/MM/yyyy"
                      locale="ptBR"
                      minDate={moment(showFieldsSelectAsync(padBaseFilter, 'quote.from', false)).toDate()}
                      maxDate={moment(showFieldsSelectAsync(padBaseFilter, 'quote.to', false)).toDate()}
                      renderDayContents={(day, date) => this.renderDayContents(day, date, padAttendanceListPlanned)}
                    />
                  </Col>
                </Row>
              </DndProvider>
            </div>
          </div>
        ) : (
          <> </>
        )}
      </>
    );
  }

  handleDragStart = event => {
    this.setState({ draggedEvent: event });
  };

  dragFromOutsideItem = () => {
    return this.state.draggedEvent;
  };

  onDropFromOutside = ({ start, end, allDay }) => {
    const { draggedEvent } = this.state;

    const event = {
      id: draggedEvent.id,
      title: draggedEvent.title,
      start,
      end,
      allDay,
    };

    this.setState({ draggedEvent: null });
    this.moveEvent({ event, start, end, isAllDay: allDay });
  };

  moveEvent = ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {
    const { events } = this.state;

    let allDay = event.allDay;

    if (!event.allDay && droppedOnAllDaySlot) {
      allDay = true;
    } else if (event.allDay && !droppedOnAllDaySlot) {
      allDay = false;
    }

    const nextEvents = events.map(existingEvent => {
      return existingEvent.id === event.id ? { ...existingEvent, start, end } : existingEvent;
    });

    this.setState({
      events: nextEvents,
    });
  };

  resizeEvent = ({ event, start, end }) => {
    const { events } = this.state;

    const nextEvents = events.map(existingEvent => {
      return existingEvent.id === event.id ? { ...existingEvent, start, end } : existingEvent;
    });

    this.setState({
      events: nextEvents,
    });
  };

  newEvent(event) {
    const idList = this.state.events.map(a => a.id);
    const newId = Math.max(...idList) + 1;
    const hour = {
      id: newId,
      title: 'New Event',
      allDay: event.slots.length === 1,
      start: event.start,
      end: event.end,
    };
    this.setState({
      events: this.state.events.concat([hour]),
    });
  }

  renderFooter() {
    return <> </>;
  }

  saveEntity = (event: any, errors: any, values: any, isNew: any) => {
    if (!this.state.quoteRhSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.quoteRh.specialty') }));
      return false;
    }

    if (!this.state.professionalSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.padAttendance.professional') }));
      return false;
    }

    if (errors.length === 0) {
      const { padAttendanceEntity } = this.props;
      const entity = {
        ...padAttendanceEntity,
        ...values,

        professional: getFormFromSelect(this.state.professionalSelectValue, 'many-to-one'), // many-to-one - otherSide

        pad: getFormFromSelect(this.state.padSelectValue, 'many-to-one'), // many-to-one - otherSide

        quoteRh: { id: this.state.quoteRhSelectValue.id }, // many-to-one - otherSide

        period: this.state.periodSelectValue === null ? 'MATUTINO' : this.state.periodSelectValue,
        schedule: this.state.scheduleSelectValue,
        push: this.state.pushSelectValue,
        value: this.state.valuePadSelectValue,
        frequency: this.state.frequencySelectValue === null ? 'UMXSEMANA' : this.state.frequencySelectValue,
        quantity: this.state.quantitySelectValue,
        date: this.state.dateSelectValue,
        status: this.state.statusSelectValue === null ? 'AGUARDANDOCONFIRMACAO' : this.state.statusSelectValue,
        cancellationReason: this.state.cancellationReasonSelectValue,
      };

      const {
        padId,
        period,
        schedule,
        push,
        frequency,
        valuePad,
        quantity,
        dateStart,
        dateEnd,
        quoteRhId,
        professionalId,
      } = this.state.fieldsBase;

      if (isNew) {
        const entityList = [];
        for (let i = 0; i < entity.quantity; i++) {
          entityList.push({ ...entity, quantity: 1 });
        }

        new Promise(resolve => {
          // entity;
          resolve(axios.post(BASE_API_VERSION_PATH + 'pad-attendances/save-many', entityList));
        }).then(resultEntity => {
          new Promise(resolve => {
            resolve(this.getEntities());
          }).then(resultEntities => {
            this.setState({ padAttendanceListAll: resultEntities['value']['data'] });
          });
        });
      } else {
        this.props.updateEntity(entity, [
          padId,
          period,
          schedule,
          push,
          frequency,
          valuePad,
          quantity,
          dateStart,
          dateEnd,
          quoteRhId && quoteRhId.value ? quoteRhId.value : '',
          professionalId && professionalId.value ? professionalId.value : '',
          0,
          1000,
          'id,asc',
          selectFieldsList.join(','),
        ]);
      }
      this.setState({ showModalForm: false });
      this.setState({ showModalEdit: null });
    } else {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.specialty.' + errors[0]) }));
    }
  };

  renderUpdateModal() {
    const { padAttendanceEntity } = this.props;

    return padAttendanceEntity && padAttendanceEntity.id ? (
      <Modal size={'lg'} isOpen={this.state.showModalEdit === padAttendanceEntity.id} toggle={() => this.setState({ showModalEdit: null })}>
        <ModalHeader toggle={() => this.setState({ showModalEdit: null })}>
          {this.renderHeaderUpdate(padAttendanceEntity, false)}
        </ModalHeader>
        <AvForm
          ref={el => (this.myFormRefEdit = el)}
          model={{
            ...padAttendanceEntity,
          }}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, false)}
        >
          <ModalBody>{this.renderBodyUpdate(padAttendanceEntity, false)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalEdit: null })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.padAttendance.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefEdit && this.myFormRefEdit.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.padAttendance.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    ) : (
      <> </>
    );
  }

  renderBodyUpdate(padAttendanceEntity, isNew) {
    const loading = this.props.loading;
    const padAttendanceList = this.state.padAttendanceListAll ? this.state.padAttendanceListAll : [];

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const padBaseFilter: IPad = this.state.padBaseFilter;
    if (!padBaseFilter) return <> </>;

    const padQuoteRhs: IQuoteRh[] = [];

    const padAttendanceListPlanned = padAttendanceList.filter(
      v => (typeof v.deletedAt === 'undefined' || v.deletedAt === null) && v.date !== null
    );

    const specialtiesInfo =
      padBaseFilter && padBaseFilter.quote && padBaseFilter.quote.quoteRh
        ? padBaseFilter.quote.quoteRh
            .map((v, i) => ({
              id: v.specialty.id,
              name: v.specialty.name,
              cost: v.specialty.cost,
              categoryId: v.specialty.category.id,
              categoryName: v.specialty.category.name,
              count: v.days,
              planned: padAttendanceListPlanned.filter(v1 => v1.quoteRh && v1.quoteRh.id === v.id).length,
              attendanceCount: padAttendanceList.filter(v1 => v1.quoteRh && v1.quoteRh.id === v.id).length,
            }))
            .filter(v => v.count > v.planned)
        : [];

    const padCategoriesIds = specialtiesInfo.map(v => v.categoryId);
    const padSpecialiesIds = specialtiesInfo.map(v => v.id);

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      <Col md="12">
                        <AvInput id="pad-attendance-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  <Col md="12">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <h4>
                            <strong>
                              {' '}
                              <Translate contentKey="generadorApp.padAttendance.patientZipCode">patientZipCode</Translate>{' '}
                            </strong>{' '}
                            : {padBaseFilter && padBaseFilter.patient ? padBaseFilter.patient.zipCode : ''}
                          </h4>
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md="6">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" id="periodLabel" for="pad-attendance-period">
                            <Translate contentKey="generadorApp.padAttendance.period">Period</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <Select
                            id="pad-attendance-period"
                            className={'css-select-control'}
                            value={{
                              value: this.state.periodSelectValue ? this.state.periodSelectValue : '',
                              label: translate('generadorApp.PadAttendancePeriod.' + this.state.periodSelectValue),
                            }}
                            options={[
                              { value: null, label: translate('generadorApp.PadAttendancePeriod.null') },
                              { value: 'MATUTINO', label: translate('generadorApp.PadAttendancePeriod.MATUTINO') },
                              { value: 'VESPERTINO', label: translate('generadorApp.PadAttendancePeriod.VESPERTINO') },
                              { value: 'NOTURNO', label: translate('generadorApp.PadAttendancePeriod.NOTURNO') },
                            ]}
                            onChange={(options: any) => this.setState({ periodSelectValue: options['value'] })}
                            name="period"
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>

                  <Col md="6">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" id="scheduleLabel" for="pad-attendance-schedule">
                            <Translate contentKey="generadorApp.padAttendance.schedule">Schedule</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <AvInput
                            disabled={this.state.periodSelectValue !== null}
                            onChange={evt => this.setState({ scheduleSelectValue: evt.target.value })}
                            validate={{}}
                            id="pad-attendance-schedule"
                            type="text"
                            name="schedule"
                            className={'form-control'}
                            mask="99:99"
                            tag={InputMask}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>

                  <Col md="6">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" for="pad-attendance-category">
                            <Translate contentKey="generadorApp.specialty.category">Category</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <SelectAsync
                            id="pad-attendance-category"
                            name={'specialty'}
                            className={'css-select-control'}
                            data-type-rel="1-many-to-one-other-side"
                            value={this.state.categorySelectValue ? this.state.categorySelectValue : ''}
                            onChange={options =>
                              this.setState({
                                categorySelectValue: options,
                                specialtyStartSelectOptions: undefined,
                                specialtySelectValue: null,
                                professionalSelectValue: null,
                                professionalSameZipCodeSelectValue: null,
                                professionalAllZipCodeSelectValue: null,
                                professionalSuggestedSelectValue: null,
                                professionalSameZipCodeStartSelectOptions: undefined,
                                professionalAllZipCodeStartSelectOptions: undefined,
                                professionalSuggestedStartSelectOptions: undefined,
                              })
                            }
                            defaultOptions={this.state.categoryStartSelectOptions ? this.state.categoryStartSelectOptions : []}
                            loadingMessage={input => translate('selectAsync.loadingMessage')}
                            noOptionsMessage={input =>
                              this.state.categoryStartSelectOptions === undefined
                                ? translate('selectAsync.loadingMessage')
                                : translate('selectAsync.noOptionsMessage')
                            }
                            onMenuOpen={async () => {
                              if (this.state.categoryStartSelectOptions === undefined) {
                                const result = await getListAxios(
                                  'categories',
                                  { 'id.in': padCategoriesIds.join(',') },
                                  0,
                                  100,
                                  'name,asc',
                                  'name'
                                );
                                this.setState({
                                  categoryStartSelectOptions: result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: p['name'] ? p.name : '' }))
                                    : [],
                                });
                              }
                            }}
                            loadOptions={async (inputValue, callback) => {
                              const result = await getListAxios(
                                'categories',
                                { 'name.contains': inputValue, 'id.in': padCategoriesIds.join(',') },
                                0,
                                100,
                                'name,asc',
                                'name'
                              );
                              callback(result.data ? result.data.map(p => ({ ...p, value: p.id, label: p['name'] ? p.name : '' })) : []);
                            }}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md="6">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" for="quote-rh-specialty">
                            <Translate contentKey="generadorApp.quoteRh.specialty">Specialty</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <SelectAsync
                            id="supplier-table-rh-specialty"
                            name={'specialty'}
                            className={'css-select-control'}
                            data-type-rel="many-to-one-other-side"
                            value={this.state.specialtySelectValue ? this.state.specialtySelectValue : ''}
                            onChange={options => {
                              const quoteRhSpecialty = options['quoteRh']
                                .filter(v => {
                                  return v.quote && v.quote.id === padBaseFilter.quote.id;
                                })
                                .pop();
                              this.setState({
                                specialtySelectValue: options,
                                defaultMin:
                                  quoteRhSpecialty && quoteRhSpecialty['startDate'] ? moment(quoteRhSpecialty['startDate']).toDate() : null,
                                defaultMax:
                                  quoteRhSpecialty && quoteRhSpecialty['finishDate']
                                    ? moment(quoteRhSpecialty['finishDate']).toDate()
                                    : null,
                                frequencySelectValue: quoteRhSpecialty ? quoteRhSpecialty['frequency'] : null,
                                quoteRhSelectValue: quoteRhSpecialty ? quoteRhSpecialty : null,
                                valuePadSelectValue: specialtiesInfo
                                  .filter(v => v.id === options.id)
                                  .map(v => v.cost)
                                  .pop(),
                                quantitySelectValue: specialtiesInfo
                                  .filter(v => v.id === options.id)
                                  .map(v => v.count - v.attendanceCount)
                                  .pop(),
                                quantitySelectValueMax: specialtiesInfo
                                  .filter(v => v.id === options.id)
                                  .map(v => v.count - v.attendanceCount)
                                  .pop(),
                                professionalSelectValue: null,
                                professionalSameZipCodeSelectValue: null,
                                professionalAllZipCodeSelectValue: null,
                                professionalSuggestedSelectValue: null,
                                professionalSameZipCodeStartSelectOptions: undefined,
                                professionalAllZipCodeStartSelectOptions: undefined,
                                professionalSuggestedStartSelectOptions: undefined,
                              });
                            }}
                            defaultOptions={this.state.specialtyStartSelectOptions ? this.state.specialtyStartSelectOptions : []}
                            loadingMessage={input => translate('selectAsync.loadingMessage')}
                            noOptionsMessage={input =>
                              this.state.specialtyStartSelectOptions === undefined
                                ? translate('selectAsync.loadingMessage')
                                : translate('selectAsync.noOptionsMessage')
                            }
                            onMenuOpen={async () => {
                              if (this.state.specialtyStartSelectOptions === undefined) {
                                const result = await getListAxios(
                                  'specialties',
                                  { 'id.in': padSpecialiesIds.join(','), 'category.id.in': this.state.categorySelectValue.value },
                                  0,
                                  100,
                                  'name,asc',
                                  'name,quoteRh.id,quoteRh.quote.id'
                                );
                                this.setState({
                                  specialtyStartSelectOptions: result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: p['name'] ? p.name : '' }))
                                    : [],
                                });
                              }
                            }}
                            loadOptions={async (inputValue, callback) => {
                              const result = await getListAxios(
                                'specialties',
                                {
                                  'id.in': padSpecialiesIds.join(','),
                                  'category.id.in': this.state.categorySelectValue.value,
                                  'name.contains': inputValue,
                                },
                                0,
                                100,
                                'name,asc',
                                'name,quoteRh.id,quoteRh.quote.id'
                              );
                              callback(result.data ? result.data.map(p => ({ ...p, value: p.id, label: p['name'] ? p.name : '' })) : []);
                            }}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>

                  <Col md="12">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" for="pad-attendance-professionalSuggested">
                            <Translate contentKey="generadorApp.padAttendance.professionalSuggested">Profissionais Sugeridos</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <SelectAsync
                            id="pad-attendance-professionalSuggested"
                            name={'professionalSuggested'}
                            className={'css-select-control'}
                            data-type-rel="7-many-to-many-owner-side"
                            value={this.state.professionalSuggestedSelectValue ? this.state.professionalSuggestedSelectValue : ''}
                            onChange={options =>
                              this.setState({
                                professionalSelectValue: options,
                                professionalSameZipCodeSelectValue: null,
                                professionalAllZipCodeSelectValue: null,
                                professionalSuggestedSelectValue: options,
                              })
                            }
                            defaultOptions={
                              this.state.professionalSuggestedStartSelectOptions ? this.state.professionalSuggestedStartSelectOptions : []
                            }
                            loadingMessage={input => translate('selectAsync.loadingMessage')}
                            noOptionsMessage={input =>
                              this.state.professionalSuggestedStartSelectOptions === undefined
                                ? translate('selectAsync.loadingMessage')
                                : translate('selectAsync.noOptionsMessage')
                            }
                            onMenuOpen={async () => {
                              if (this.state.professionalSuggestedStartSelectOptions === undefined) {
                                let result = { data: [] };
                                if (padBaseFilter.patient.professionalPreferreds.length > 0) {
                                  result = await getListAxios(
                                    'professionals',
                                    this.state.specialtySelectValue
                                      ? {
                                          'id.in': padBaseFilter.patient.professionalPreferreds.map(v => v.id).join(','),
                                          'specialties.id.in': this.state.specialtySelectValue.value,
                                        }
                                      : {},
                                    0,
                                    100,
                                    'name,asc',
                                    'name'
                                  );
                                }
                                this.setState({
                                  professionalSuggestedStartSelectOptions: result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: p['name'] ? p.name : '' }))
                                    : [],
                                });
                              }
                            }}
                            loadOptions={async (inputValue, callback) => {
                              const result = await getListAxios(
                                'professionals',
                                {
                                  'id.in': padBaseFilter.patient.professionalPreferreds.map(v => v.id).join(','),
                                  'specialties.id.in': this.state.specialtySelectValue.value,
                                  'name.contains': inputValue,
                                },
                                0,
                                100,
                                'name,asc',
                                'name'
                              );
                              callback(result.data ? result.data.map(p => ({ ...p, value: p.id, label: p['name'] ? p.name : '' })) : []);
                            }}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>

                  <Col md="12">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" for="pad-attendance-professionalSameZipCode">
                            <Translate contentKey="generadorApp.padAttendance.professionalSameZipCode">Profissionais mesmo CEP</Translate>
                          </Label>
                        </Col>

                        <Col md="12">
                          <SelectAsync
                            id="pad-attendance-professionalSameZipCode"
                            name={'professionalSameZipCode'}
                            className={'css-select-control'}
                            data-type-rel="7-many-to-many-owner-side"
                            value={this.state.professionalSameZipCodeSelectValue ? this.state.professionalSameZipCodeSelectValue : ''}
                            onChange={options =>
                              this.setState({
                                professionalSelectValue: options,
                                professionalSameZipCodeSelectValue: options,
                                professionalAllZipCodeSelectValue: null,
                                professionalSuggestedSelectValue: null,
                              })
                            }
                            defaultOptions={
                              this.state.professionalSameZipCodeStartSelectOptions
                                ? this.state.professionalSameZipCodeStartSelectOptions
                                : []
                            }
                            loadingMessage={input => translate('selectAsync.loadingMessage')}
                            noOptionsMessage={input =>
                              this.state.professionalSameZipCodeStartSelectOptions === undefined
                                ? translate('selectAsync.loadingMessage')
                                : translate('selectAsync.noOptionsMessage')
                            }
                            onMenuOpen={async () => {
                              if (this.state.professionalSameZipCodeStartSelectOptions === undefined) {
                                const result = await getListAxios(
                                  'professionals',
                                  {
                                    'id.notIn': padBaseFilter.patient.professionalBlockeds.map(v => v.id).join(','),
                                    'specialties.id.in': this.state.specialtySelectValue.value,
                                    'professionalOccupationArea.initialZipcode.lessOrEqualThan': padBaseFilter.patient.zipCode.substring(
                                      0,
                                      3
                                    ),
                                    'professionalOccupationArea.finalZipcode.greaterOrEqualThan': padBaseFilter.patient.zipCode.substring(
                                      0,
                                      3
                                    ),
                                  },
                                  0,
                                  100,
                                  'name,asc',
                                  'name'
                                );
                                this.setState({
                                  professionalSameZipCodeStartSelectOptions: result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: p['name'] ? p.name : '' }))
                                    : [],
                                });
                              }
                            }}
                            loadOptions={async (inputValue, callback) => {
                              const result = await getListAxios(
                                'professionals',
                                {
                                  'id.notIn': padBaseFilter.patient.professionalBlockeds.map(v => v.id).join(','),
                                  'specialties.id.in': this.state.specialtySelectValue.value,
                                  'professionalOccupationArea.initialZipcode.lessOrEqualThan': padBaseFilter.patient.zipCode.substring(
                                    0,
                                    3
                                  ),
                                  'professionalOccupationArea.finalZipcode.greaterOrEqualThan': padBaseFilter.patient.zipCode.substring(
                                    0,
                                    3
                                  ),
                                  'name.contains': inputValue,
                                },
                                0,
                                100,
                                'name,asc',
                                'name'
                              );
                              callback(result.data ? result.data.map(p => ({ ...p, value: p.id, label: p['name'] ? p.name : '' })) : []);
                            }}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>

                  <Col md="12">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" for="pad-attendance-professionalAllZipCode">
                            <Translate contentKey="generadorApp.padAttendance.professionalAllZipCode">Profissionais todos CEP</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <SelectAsync
                            id="pad-attendance-professionalAllZipCode"
                            name={'professionalAllZipCode'}
                            className={'css-select-control'}
                            data-type-rel="7-many-to-many-owner-side"
                            value={this.state.professionalAllZipCodeSelectValue ? this.state.professionalAllZipCodeSelectValue : ''}
                            onChange={options =>
                              this.setState({
                                professionalSelectValue: options,
                                professionalSameZipCodeSelectValue: null,
                                professionalAllZipCodeSelectValue: options,
                                professionalSuggestedSelectValue: null,
                              })
                            }
                            defaultOptions={
                              this.state.professionalAllZipCodeStartSelectOptions ? this.state.professionalAllZipCodeStartSelectOptions : []
                            }
                            loadingMessage={input => translate('selectAsync.loadingMessage')}
                            noOptionsMessage={input =>
                              this.state.professionalAllZipCodeStartSelectOptions === undefined
                                ? translate('selectAsync.loadingMessage')
                                : translate('selectAsync.noOptionsMessage')
                            }
                            onMenuOpen={async () => {
                              if (this.state.professionalAllZipCodeStartSelectOptions === undefined) {
                                let result = { data: [] };
                                result = await getListAxios(
                                  'professionals',
                                  {
                                    'id.notIn': padBaseFilter.patient.professionalBlockeds.map(v => v.id).join(','),
                                    'specialties.id.in': this.state.specialtySelectValue.value,
                                  },
                                  0,
                                  100,
                                  'name,asc',
                                  'name'
                                );
                                this.setState({
                                  professionalAllZipCodeStartSelectOptions: result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: p['name'] ? p.name : '' }))
                                    : [],
                                });
                              }
                            }}
                            loadOptions={async (inputValue, callback) => {
                              const result = await getListAxios(
                                'professionals',
                                {
                                  'id.notIn': padBaseFilter.patient.professionalBlockeds.map(v => v.id).join(','),
                                  'specialties.id.in': this.state.specialtySelectValue.value,
                                  'name.contains': inputValue,
                                },
                                0,
                                100,
                                'name,asc',
                                'name'
                              );
                              callback(result.data ? result.data.map(p => ({ ...p, value: p.id, label: p['name'] ? p.name : '' })) : []);
                            }}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>

                  {this.state.baseFilters !== 'frequency' ? (
                    <Col md="4">
                      <Row className="mr-1 mt-1">
                        <Col md="12">
                          <Label id="frequencyLabel" for="pad-attendance-frequency">
                            <Translate contentKey="generadorApp.padAttendance.frequency">Frequency</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <Select
                            isDisabled={true}
                            id="pad-attendance-frequency"
                            className={'css-select-control'}
                            value={
                              this.state.frequencySelectValue
                                ? {
                                    value: this.state.frequency,
                                    label: translate('generadorApp.QuoteFrequency.' + this.state.frequencySelectValue),
                                  }
                                : { value: '', label: translate('generadorApp.padAttendance.frequency') }
                            }
                            options={[
                              { value: '', label: translate('generadorApp.padAttendance.frequency') },
                              { value: 'UMXSEMANA', label: translate('generadorApp.QuoteFrequency.UMXSEMANA') },
                              { value: 'DOISXSEMANA', label: translate('generadorApp.QuoteFrequency.DOISXSEMANA') },
                              { value: 'TRESXSEMANA', label: translate('generadorApp.QuoteFrequency.TRESXSEMANA') },
                              { value: 'QUATROXSEMANA', label: translate('generadorApp.QuoteFrequency.QUATROXSEMANA') },
                              { value: 'CINCOXSEMANA', label: translate('generadorApp.QuoteFrequency.CINCOXSEMANA') },
                              { value: 'SEISXSEMANA', label: translate('generadorApp.QuoteFrequency.SEISXSEMANA') },
                              { value: 'UMXDIA', label: translate('generadorApp.QuoteFrequency.UMXDIA') },
                              { value: 'DOISXDIA', label: translate('generadorApp.QuoteFrequency.DOISXDIA') },
                              { value: 'TRESXDIA', label: translate('generadorApp.QuoteFrequency.TRESXDIA') },
                              { value: 'QUATROXDIA', label: translate('generadorApp.QuoteFrequency.QUATROXDIA') },
                              { value: 'UMXMES', label: translate('generadorApp.QuoteFrequency.UMXMES') },
                              { value: 'DOISXMES', label: translate('generadorApp.QuoteFrequency.DOISXMES') },
                              { value: 'TRESXMES', label: translate('generadorApp.QuoteFrequency.TRESXMES') },
                              { value: 'QUATROXMES', label: translate('generadorApp.QuoteFrequency.QUATROXMES') },
                              { value: 'CADA48HORAS', label: translate('generadorApp.QuoteFrequency.CADA48HORAS') },
                              { value: 'CADA72HORAS', label: translate('generadorApp.QuoteFrequency.CADA72HORAS') },
                              { value: 'PONTUAL', label: translate('generadorApp.QuoteFrequency.PONTUAL') },
                            ]}
                            onChange={(options: any) => this.setState({ frequencySelectValue: options['value'] })}
                            name="frequency"
                          />
                        </Col>
                      </Row>
                    </Col>
                  ) : null}

                  {this.state.baseFilters !== 'quantity' ? (
                    <Col md="4">
                      <Row className="mr-1 mt-1">
                        <Label id="quantityLabel" for="pad-attendance-quantity">
                          <Translate contentKey="generadorApp.padAttendance.quantity">Quantity</Translate>
                        </Label>
                        <AvInput
                          onChange={evt => this.setState({ quantitySelectValue: evt.target.value })}
                          type="number"
                          max={this.state.quantitySelectValueMax}
                          name="quantity"
                          id="pad-attendance-quantity"
                          value={this.state.quantitySelectValue ? this.state.quantitySelectValue : ''}
                        />
                      </Row>
                    </Col>
                  ) : null}

                  {this.state.baseFilters !== 'value' ? (
                    <Col md="4">
                      <Row className="mr-1 mt-1">
                        <Col md="12">
                          <Label id="valueLabel" for="pad-attendance-value">
                            <Translate contentKey="generadorApp.padAttendance.value">Value</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <CurrencyInput
                            onChange={(maskedvalue, floatvalue, event) => this.setState({ valuePadSelectValue: floatvalue })}
                            decimalSeparator=","
                            thousandSeparator=""
                            prefix="R$ "
                            allowNegative={true}
                            value={this.state.valuePadSelectValue ? this.state.valuePadSelectValue : 0}
                            id="cl-com-item-saleValue"
                            name="saleValue"
                            className={'form-control'}
                          />
                        </Col>
                      </Row>
                    </Col>
                  ) : null}

                  {baseFilters !== 'push' ? (
                    <Col md="12">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="pushLabel" for="pad-attendance-push">
                              <Translate contentKey="generadorApp.padAttendance.push">Push</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <AvInput
                              onChange={evt => this.setState({ pushSelectValue: evt.target.value })}
                              validate={{}}
                              id="pad-attendance-push"
                              type="textarea"
                              name="push"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ pushSelectValue: evt.target.value })}
                      type="hidden"
                      name="push"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel>
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
            {this.renderNewModal()}
            {this.renderUpdateModal()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
    categories: storeState.category.entities,
    specialties: storeState.specialty.entities,
    loadingPadAttendance: storeState.padAttendance.loading,
    updatingPadAttendance: storeState.padAttendance.updating,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;

export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(PadAttendanceExtended);
