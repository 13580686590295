import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { IPharmacyStockStatus } from 'app/shared/model/pharmacy-stock-status.model';
import { IMedicineMaterial } from 'app/shared/model/medicine-material.model';
import { ISupplier } from 'app/shared/model/supplier.model';
import { INfe } from 'app/shared/model/nfe.model';
import { StatusPhamacyStock } from 'app/shared/model/enumerations/status-phamacy-stock.model';
import { ActionType } from 'app/shared/model/enumerations/action-type.model';

export interface IPharmacyStock {
  id?: number;
  lot?: string;
  validate?: Moment;
  notificationDate?: Moment;
  stockedLocation?: string;
  princePaid?: number;
  quantity?: number;
  amountExtract?: number;
  status?: StatusPhamacyStock;
  externalPurchase?: boolean;
  observation?: string;
  actionType?: ActionType;
  pharmacyStockStatus?: IPharmacyStockStatus[];
  medicineMaterial?: IMedicineMaterial;
  supplier?: ISupplier;
  nfe?: INfe;
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  pharmacyStockStatus: {
    isMulti: false, // one-to-many - other-side
    entity: 'PharmacyStockStatus',
    urlParameters: {
      enpoint: 'pharmacy-stock-statuses',
      filters: 'pharmacyStock.id.equals',
    },
  },
  medicineMaterial: {
    isMulti: true, // many-to-one - other-side
    entity: 'MedicineMaterial',
    urlParameters: {
      enpoint: 'medicine-materials',
      filters: 'pharmacyStock.id.equals',
    },
  },
  supplier: {
    isMulti: true, // many-to-one - other-side
    entity: 'Supplier',
    urlParameters: {
      enpoint: 'suppliers',
      filters: 'pharmacyStock.id.equals',
    },
  },
  nfe: {
    isMulti: true, // many-to-one - other-side
    entity: 'Nfe',
    urlParameters: {
      enpoint: 'nfes',
      filters: 'pharmacyStock.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'pharmacyStock.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'pharmacyStock.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<IPharmacyStock> = {
  externalPurchase: false,
};
