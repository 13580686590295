import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Client from './client';

import ClientDetail from './client-detail';

import ClientUpdate from './client-update';

import ClientStatusHistory from '../client-status-history/index';

import ClientFile from '../client-file/index';

import ClientContact from '../client-contact/index';

import ClComTable from '../cl-com-table/index';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute path={`${match.path}/:idClient/client-status-history`} component={ClientStatusHistory} />

      <ErrorBoundaryRoute path={`${match.path}/:idClient/client-file`} component={ClientFile} />

      <ErrorBoundaryRoute path={`${match.path}/:idClient/client-contact`} component={ClientContact} />

      <ErrorBoundaryRoute path={`${match.path}/:idClient/cl-com-table`} component={ClComTable} />

      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={ClientUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={ClientUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={ClientDetail} />
      <ErrorBoundaryRoute path={match.path} component={Client} />
    </Switch>

    <Switch>
      {/* <ErrorBoundaryRoute path={`${match.path}/:idClient/client-status-history`} component={ClientStatusHistory} /> */}

      {/* <ErrorBoundaryRoute path={`${match.path}/:idClient/client-file`} component={ClientFile} /> */}

      {/* <ErrorBoundaryRoute path={`${match.path}/:idClient/client-contact`} component={ClientContact} /> */}

      {/* <ErrorBoundaryRoute path={`${match.path}/:idClient/cl-com-table`} component={ClComTable} /> */}
    </Switch>
  </>
);

export default Routes;
