/* eslint-disable no-console */
/* eslint complexity: ["error", 300] */
import React from 'react';
import { connect } from 'react-redux';
import CurrencyInput from 'react-currency-input';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';
import { QuoteMaterial, mapStateToProps, mapDispatchToProps } from '../quote-material';
import { IRootState } from 'app/shared/reducers';
import { ClComTable } from 'app/entities/cl-com-table/cl-com-table';

import { getFormFromSelect, getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import SelectAsync from 'react-select/async';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Translate, translate } from 'app/config/translate-component';
import Select from 'react-select';
import { Button, Col, Row, Label, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';

import { TextFormat } from 'react-jhipster';
import { QuoteDiscountType } from 'app/shared/model/enumerations/quote-discount-type.model';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';
import moment from 'moment';

import { IMedicineMaterial } from 'app/shared/model/medicine-material.model';
import { IQuoteMaterial } from 'app/shared/model/quote-material.model';
import { IQuote } from 'app/shared/model/quote.model';
import { toast } from 'react-toastify';
import { convertDateTimeToServer } from 'app/shared/util/date-utils';
import { callFunction } from 'app/shared/reports/jhi-reports.reducer';
import { RomaneioStatus } from 'app/shared/model/enumerations/romaneio-status.model';

import { IClComTable } from 'app/shared/model/cl-com-table.model';
import { ICategory } from 'app/shared/model/category.model';
import { TypeClientCommercialTable } from 'app/shared/model/enumerations/type-client-commercial-table.model';
import { IClComRuler } from 'app/shared/model/cl-com-ruler.model';
import { ComercialTableType } from 'app/shared/model/enumerations/comercial-table-type.model';
import { EnumTablePriceType } from 'app/shared/model/enumerations/enum-table-price-type.model';
import { IClComItem } from 'app/shared/model/cl-com-item.model';
registerLocale('pt', pt);

export interface IExtendedState {
  unitaryAmountEnabled?: boolean;
  billListFinalAmountSelectValue?: number[];
  billListGrossAmountSelectValue?: number[];
  billListQuantityItemSelectValue?: number[];
  clComTableSelectValue: IClComTable;
  clComTableStartSelectOptions: IClComTable[];
  categorySelectValue: ICategory;
  categoryStartSelectOptions: ICategory[];
}
export interface IExtendedProps {
  clComTable?: ClComTable;
  clComTableRulers?: any;
  clComTableItens?: any;
  quote?: IQuote;
  typeView?: string;
}

export class QuoteMaterialExtended extends QuoteMaterial {
  constructor(props: any) {
    super(props);
    this.state = {
      ...this.state,
      billListFinalAmountSelectValue: [],
      billListGrossAmountSelectValue: [],
      billListQuantityItemSelectValue: [],
    };
    this.fnPushPharmacyStock = this.fnPushPharmacyStock.bind(this);
  }
  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  renderHeader() {
    const { entityEmbebedPai } = this.props;
    return entityEmbebedPai &&
      ['APROVADO', 'PRORROGADO', 'INATIVOREPROVADOPRECO', 'INATIVOCONTROLEINTERNO'].includes(entityEmbebedPai.status) ? (
      <div id="page-heading">
        <span className="page-header">
          <Translate contentKey="generadorApp.quoteMaterial.home.title">quoteMaterial</Translate>
        </span>
        {!['Romaneio', 'Bill'].includes(this.props.typeView) ? (
          <Button
            onClick={() =>
              this.setState({
                showModalForm: true,
                unitPerDaySelectValue: null,
                viaSelectValue: null,
                startDateSelectValue: null,
                finishDateSelectValue: null,
                daysSelectValue: null,
                quantitySelectValue: 1,
                unitaryAmountSelectValue: null,
                grossAmountSelectValue: null,
                typeDiscountSelectValue: null,
                discountPercentSelectValue: null,
                discountSelectValue: null,
                finalAmountSelectValue: null,
                frequencySelectValue: null,
                romaneioStatusSelectValue: null,
                additiveSelectValue: null,
                billQuantityItemSelectValue: null,
                billGrossAmountSelectValue: null,
                billFinalAmountSelectValue: null,

                medicineMaterialStartSelectOptions: undefined,
                medicineMaterialSelectValue: null,
                isNew: true,
              })
            }
            color="primary"
            className="btn btn-primary float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="generadorApp.quote.btnAddAdditive">Add Additive</Translate>
          </Button>
        ) : (
          <> </>
        )}
      </div>
    ) : (
      <> {super.renderHeader()} </>
    );
  }

  renderFilter() {
    return <> </>;
  }

  renderFooter() {
    return <> {super.renderFooter()} </>;
  }

  fnPushPharmacyStock(resultEntity) {
    const entityDB: IQuoteMaterial = resultEntity && resultEntity['payload'] && resultEntity['payload'] ? resultEntity['payload'] : null;
    if (this.props.user && entityDB.additive && entityDB['medicineMaterial']) {
      callFunction('fnPushPharmacyStock', {
        lastModifiedBy: this.props.user.id,
        medicineMaterialId: entityDB['medicineMaterial']['id'],
        whiteLabelId: this.props.user['whiteLabel'] ? entityDB['whiteLabel'].id : 1,
        quantity: entityDB.quantity,
      });
    }
  }

  saveEntity = (event: any, errors: any, values: any, isNew: any) => {
    values.startDate = convertDateTimeToServer(values.startDate);
    values.finishDate = convertDateTimeToServer(values.finishDate);

    if (errors.length === 0) {
      const { quoteMaterialEntity } = this.props;
      const entity = {
        ...quoteMaterialEntity,
        ...values,

        quote: getFormFromSelect(this.state.quoteSelectValue, 'many-to-one'), // many-to-one - otherSide

        medicineMaterial: getFormFromSelect(this.state.medicineMaterialSelectValue), // many-to-one - otherSide

        unitPerDay: this.state.unitPerDaySelectValue,
        via: this.state.viaSelectValue === null ? 'INJECAO' : this.state.viaSelectValue,
        startDate: this.state.startDateSelectValue,
        finishDate: this.state.finishDateSelectValue,
        days: this.state.daysSelectValue,
        quantity: this.state.quantitySelectValue,
        unitaryAmount: this.state.unitaryAmountSelectValue,
        grossAmount: this.state.grossAmountSelectValue,
        typeDiscount: this.state.typeDiscountSelectValue === null ? 'VALOR_UNITARIO' : this.state.typeDiscountSelectValue,
        discountPercent: this.state.discountPercentSelectValue,
        discount: this.state.discountSelectValue,
        finalAmount: this.state.finalAmountSelectValue,
        frequency: this.state.frequencySelectValue === null ? 'UMXSEMANA' : this.state.frequencySelectValue,
        romaneioStatus: this.state.romaneioStatusSelectValue === null ? 'ENTREGUE' : this.state.romaneioStatusSelectValue,
        additive: this.state.additiveSelectValue === null ? false : this.state.additiveSelectValue,
        billQuantityItem: this.state.billQuantityItemSelectValue ? this.state.billQuantityItemSelectValue : this.state.daysSelectValue,
        billGrossAmount: this.state.billGrossAmountSelectValue ? this.state.billGrossAmountSelectValue : this.state.grossAmountSelectValue,
        billFinalAmount: this.state.billFinalAmountSelectValue ? this.state.billFinalAmountSelectValue : this.state.finalAmountSelectValue,
      };

      const {
        unitPerDay,
        via,
        startDateStart,
        startDateEnd,
        finishDateStart,
        finishDateEnd,
        days,
        quantity,
        unitaryAmount,
        grossAmount,
        discountPercent,
        discount,
        finalAmount,
        frequency,
        quoteId,
        typeDiscount,
        medicineMaterialId,
        billQuantityItem,
      } = this.state.fieldsBase;

      if (isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              unitPerDay,
              via,
              startDateStart,
              startDateEnd,
              finishDateStart,
              finishDateEnd,
              days,
              quantity,
              unitaryAmount,
              grossAmount,
              discountPercent,
              discount,
              finalAmount,
              frequency,
              quoteId && quoteId.value ? quoteId.value : '',
              typeDiscount,
              medicineMaterialId && medicineMaterialId.value ? medicineMaterialId.value : '',
              billQuantityItem,
            ])
          );
        }).then(this.fnPushPharmacyStock);
      } else {
        this.props.updateEntity(entity, [
          unitPerDay,
          via,
          startDateStart,
          startDateEnd,
          finishDateStart,
          finishDateEnd,
          days,
          quantity,
          unitaryAmount,
          grossAmount,
          discountPercent,
          discount,
          finalAmount,
          frequency,
          quoteId && quoteId.value ? quoteId.value : '',
          typeDiscount,
          medicineMaterialId && medicineMaterialId.value ? medicineMaterialId.value : '',
          billQuantityItem,
        ]);
      }
      this.setState({ showModalForm: false });
      this.setState({ showModalEdit: null });
    } else {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.specialty.' + errors[0]) }));
    }
  };

  renderNewModal() {
    const { entityEmbebedPai } = this.props;

    let additive = false;
    if (entityEmbebedPai) {
      additive = ['APROVADO', 'PRORROGADO', 'INATIVOREPROVADOPRECO', 'INATIVOCONTROLEINTERNO'].includes(entityEmbebedPai.status);
      if (additive !== this.state.additiveSelectValue) {
        this.setState({ additiveSelectValue: additive });
      }
    }
    return (
      <Modal size={'xl'} isOpen={this.state.showModalForm} toggle={() => this.setState({ showModalForm: false })}>
        <ModalHeader toggle={() => this.setState({ showModalForm: false })}>{this.renderHeaderUpdate({}, true)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefNew = el)}
          model={{}}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, { ...values, additive }, true)}
        >
          <ModalBody>{this.renderBodyUpdate({}, true)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalForm: false })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.quoteMaterial.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefNew && this.myFormRefNew.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.quoteMaterial.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    );
  }

  renderUpdateModal() {
    const { quoteMaterialEntity } = this.props;
    return quoteMaterialEntity && quoteMaterialEntity.id ? (
      <Modal size={'xl'} isOpen={this.state.showModalEdit === quoteMaterialEntity.id} toggle={() => this.setState({ showModalEdit: null })}>
        <ModalHeader toggle={() => this.setState({ showModalEdit: null })}>
          {this.renderHeaderUpdate(quoteMaterialEntity, false)}
        </ModalHeader>
        <AvForm
          ref={el => (this.myFormRefEdit = el)}
          model={{
            ...quoteMaterialEntity,
          }}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, false)}
        >
          <ModalBody>{this.renderBodyUpdate(quoteMaterialEntity, false)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalEdit: null })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.quoteMaterial.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefEdit && this.myFormRefEdit.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.quoteMaterial.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    ) : (
      <> </>
    );
  }

  async openUpdateModal(v: IQuoteMaterial, edit?) {
    this.props.getEntity(v.id);

    let categorySelectValue = null;
    if (v.medicineMaterial && v.medicineMaterial.id) {
      const category = await getListAxios(
        'categories',
        { 'medicineMaterials.id.equals': v.medicineMaterial.id },
        0,
        100,
        'name,asc',
        'name'
      );

      if (category['data'] && category['data'].length > 0) {
        const _categ = category['data'][0];
        categorySelectValue = { ..._categ, value: _categ.id, label: _categ.name };
      }
    }

    this.setState({
      showModalEdit: v.id,
      showModalEditView: edit,
      categorySelectValue,
      ...this.getFullEntitySelectValue(v),
    });
  }

  renderTable() {
    const { quoteMaterialList, entityEmbebedPai } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    const quoteMaterialListActives = quoteMaterialList
      ? quoteMaterialList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
      : [];

    const listQuoteItemWithValues =
      entityEmbebedPai && entityEmbebedPai.quoteMaterial
        ? entityEmbebedPai.quoteMaterial
            .filter(quoteMaterial => !['Bill'].includes(this.props.typeView) || quoteMaterial.billFinalAmount > 0)
            .map(v => v.id)
        : quoteMaterialListActives;

    return (
      <>
        {quoteMaterialListActives && quoteMaterialListActives.length > 0 && listQuoteItemWithValues.length > 0 ? (
          <Table
            id="quote-rh-table-list"
            responsive
            aria-describedby="quote-rh-heading"
            className={'table-hover table-striped table-responsive-css'}
          >
            <thead className={'thead-light'}>
              <tr>
                <th>
                  <Translate contentKey="generadorApp.quoteMaterial.productService">ID</Translate>
                </th>
                <th>
                  <Translate contentKey="generadorApp.quoteMaterial.home.title">Medicine Material</Translate>
                </th>
                {state.baseFilters !== 'frequency' ? (
                  <th>
                    <Translate contentKey="generadorApp.quoteMaterial.frequency">Frequency</Translate>
                  </th>
                ) : null}
                <th>
                  <Translate contentKey="generadorApp.quote.date">Date</Translate>
                </th>
                {state.baseFilters !== 'days' ? (
                  <th>
                    <Translate contentKey="generadorApp.quoteMaterial.days">Days</Translate>
                  </th>
                ) : null}
                {!['Bill'].includes(this.props.typeView) ? (
                  <th>
                    <Translate contentKey="generadorApp.quoteMaterial.quantity">Quantity</Translate>
                  </th>
                ) : null}
                {['Bill'].includes(this.props.typeView) ? (
                  <th>
                    <Translate contentKey="generadorApp.quoteMaterial.billQuantityItem">billQuantityItem</Translate>
                  </th>
                ) : null}
                {!['Romaneio'].includes(this.props.typeView) ? (
                  <th className="hand" onClick={sortFunction('unitaryAmount')}>
                    <Translate contentKey="generadorApp.quoteMaterial.unitaryAmount">Unitary Amount</Translate>
                  </th>
                ) : null}
                {!['Romaneio'].includes(this.props.typeView) ? (
                  <th className="hand" onClick={sortFunction('grossAmount')}>
                    <Translate contentKey="generadorApp.quoteMaterial.grossAmount">Gross Amount</Translate>
                  </th>
                ) : null}
                {!['Romaneio'].includes(this.props.typeView) ? (
                  <th className="hand" onClick={sortFunction('discount')}>
                    <Translate contentKey="generadorApp.quoteMaterial.discount">Discount</Translate>
                  </th>
                ) : null}
                {!['Bill'].includes(this.props.typeView) ? (
                  <th>
                    <Translate contentKey="generadorApp.quoteMaterial.finalAmount">finalAmount</Translate>
                  </th>
                ) : null}
                {['Bill'].includes(this.props.typeView) ? (
                  <th>
                    <Translate contentKey="generadorApp.quoteMaterial.billFinalAmount">billFinalAmount</Translate>
                  </th>
                ) : null}
                {['Romaneio'].includes(this.props.typeView) ? (
                  <th>
                    <Translate contentKey="generadorApp.RomaneioStatus.ENTREGUE">ENTREGUE</Translate>
                  </th>
                ) : (
                  <> </>
                )}
                <th />
              </tr>
            </thead>
            <tbody>
              <tr></tr>
              {quoteMaterialListActives
                .filter(quoteRh => !['Bill'].includes(this.props.typeView) || listQuoteItemWithValues.includes(quoteRh.id))
                .map((quoteMaterial, i) => (
                  <tr key={`entity-${i}`} className={quoteMaterial.additive ? 'alert alert-warning' : ''}>
                    <td />
                    <td id="medicineMaterials-cell">
                      <b className="visible-xs"> medicineMaterials </b>
                      {quoteMaterial && quoteMaterial.medicineMaterial && quoteMaterial.medicineMaterial['name']
                        ? quoteMaterial.medicineMaterial['name']
                        : ''}
                    </td>
                    {state.baseFilters !== 'frequency' ? (
                      <td id="frequency-cell">
                        <b className="visible-xs"> Frequency </b>
                        {quoteMaterial.frequency ? (
                          <Translate contentKey={`generadorApp.QuoteFrequency.${quoteMaterial.frequency}`} />
                        ) : (
                          <> </>
                        )}
                      </td>
                    ) : null}
                    <td id="startDate-cell">
                      <b className="visible-xs"> Date </b>
                      <TextFormat type="date" value={quoteMaterial.startDate} format={APP_LOCAL_DATE_FORMAT} /> -{' '}
                      <TextFormat type="date" value={quoteMaterial.finishDate} format={APP_LOCAL_DATE_FORMAT} />
                    </td>
                    {state.baseFilters !== 'days' ? (
                      <td id="days-cell">
                        <b className="visible-xs"> Days </b>
                        {quoteMaterial.days}
                      </td>
                    ) : null}
                    {!['Bill'].includes(this.props.typeView) ? (
                      <td id="quantity-cell">
                        <b className="visible-xs"> Quantity </b>
                        {quoteMaterial.quantity}
                      </td>
                    ) : null}
                    {['Bill'].includes(this.props.typeView) ? (
                      <td id="billQuantityItem-cell">
                        <CurrencyInput
                          precision={0}
                          style={{ width: '100px' }}
                          onChange={(maskedvalue, floatvalue, event) => {
                            const grossAmount = quoteMaterial.unitaryAmount * floatvalue;

                            const finalAmount =
                              quoteMaterial.typeDiscount !== 'PORCENTO'
                                ? grossAmount - quoteMaterial.discount
                                : grossAmount - (grossAmount * quoteMaterial.discountPercent) / 100;

                            const billListQuantityItemSelectValue = this.state.billListQuantityItemSelectValue;
                            const billListFinalAmountSelectValue = this.state.billListFinalAmountSelectValue;
                            const billListGrossAmountSelectValue = this.state.billListGrossAmountSelectValue;

                            billListQuantityItemSelectValue[quoteMaterial.id] = floatvalue;
                            billListFinalAmountSelectValue[quoteMaterial.id] = finalAmount;
                            billListGrossAmountSelectValue[quoteMaterial.id] = grossAmount;

                            this.setState({
                              billListQuantityItemSelectValue,
                              billListFinalAmountSelectValue,
                              billListGrossAmountSelectValue,
                            });
                            this.props.updateEntity(
                              {
                                id: quoteMaterial.id,
                                billQuantityItem: floatvalue,
                                billFinalAmount: finalAmount,
                                billGrossAmount: grossAmount,
                              },
                              { reloadList: false }
                            );
                          }}
                          allowNegative={false}
                          value={
                            typeof this.state.billListQuantityItemSelectValue === 'undefined' ||
                            typeof this.state.billListQuantityItemSelectValue[quoteMaterial.id] === 'undefined'
                              ? quoteMaterial.billQuantityItem
                              : this.state.billListQuantityItemSelectValue[quoteMaterial.id]
                          }
                          id="quote-rh-days"
                          name="days"
                          className={'form-control'}
                        />
                      </td>
                    ) : null}
                    {!['Romaneio'].includes(this.props.typeView) ? (
                      <td id="unitaryAmount-cell">
                        <b className="visible-xs"> Unitary Amount </b>
                        R$ {(quoteMaterial.unitaryAmount * 1).toFixed(2).replace('.', ',')}
                      </td>
                    ) : null}
                    {!['Romaneio', 'Bill'].includes(this.props.typeView) ? (
                      <td id="grossAmount-cell">
                        <b className="visible-xs"> Gross Amount </b>
                        R$ {(quoteMaterial.grossAmount * 1).toFixed(2).replace('.', ',')}
                      </td>
                    ) : null}
                    {['Bill'].includes(this.props.typeView) ? (
                      <td id="grossAmount-cell">
                        <b className="visible-xs"> Gross Amount </b>
                        R${' '}
                        {(
                          (typeof this.state.billListGrossAmountSelectValue === 'undefined' ||
                          !this.state.billListGrossAmountSelectValue[quoteMaterial.id]
                            ? quoteMaterial.billGrossAmount
                            : this.state.billListGrossAmountSelectValue[quoteMaterial.id]) * 1
                        )
                          .toFixed(2)
                          .replace('.', ',')}
                      </td>
                    ) : null}
                    {!['Romaneio'].includes(this.props.typeView) ? (
                      <td id="discount-cell">
                        <b className="visible-xs"> Discount </b>
                        {quoteMaterial.typeDiscount !== QuoteDiscountType.PORCENTO
                          ? 'R$ ' + (quoteMaterial.discount * 1).toFixed(2).replace('.', ',')
                          : (quoteMaterial.discountPercent * 1).toFixed(2).replace('.', ',') + ' %'}
                      </td>
                    ) : null}
                    {!['Bill'].includes(this.props.typeView) ? (
                      <td id="finalAmount-cell">
                        <b className="visible-xs"> Final Amount </b>
                        R$ {(quoteMaterial.finalAmount * 1).toFixed(2).replace('.', ',')}
                      </td>
                    ) : null}
                    {['Bill'].includes(this.props.typeView) ? (
                      <td id="billFinalAmount-cell">
                        R${' '}
                        {(
                          (typeof this.state.billListFinalAmountSelectValue === 'undefined' ||
                          !this.state.billListFinalAmountSelectValue[quoteMaterial.id]
                            ? quoteMaterial.billFinalAmount
                            : this.state.billListFinalAmountSelectValue[quoteMaterial.id]) * 1
                        )
                          .toFixed(2)
                          .replace('.', ',')}
                      </td>
                    ) : null}

                    {['Romaneio'].includes(this.props.typeView) ? (
                      <td id="finalAmount-cell">
                        <div className="switcher switcher-success">
                          <input
                            type="checkbox"
                            name={'register_quoteMaterial_' + quoteMaterial.id}
                            id={'register_quoteMaterial_' + quoteMaterial.id}
                            defaultChecked={quoteMaterial.romaneioStatus === RomaneioStatus.ENTREGUE}
                            onClick={() =>
                              this.props.updateEntity(
                                {
                                  ...quoteMaterial,
                                  romaneioStatus:
                                    quoteMaterial.romaneioStatus === RomaneioStatus.PENDENTE
                                      ? RomaneioStatus.ENTREGUE
                                      : RomaneioStatus.PENDENTE,
                                },
                                { reloadList: false }
                              )
                            }
                          />
                          <label htmlFor={'register_quoteMaterial_' + quoteMaterial.id} />
                        </div>
                      </td>
                    ) : (
                      <> </>
                    )}

                    <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          <Button onClick={() => this.openUpdateModal(quoteMaterial)} color="primary" size="sm">
                            <FontAwesomeIcon icon="pencil-alt" />
                          </Button>
                          <Button onClick={() => this.deleteEntity(quoteMaterial)} color="danger" size="sm">
                            <FontAwesomeIcon icon="trash" />
                          </Button>
                        </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.quoteMaterial.home.notFound">No Human Resources found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  calcValue(item: IMedicineMaterial, openTable: IClComTable) {

    const _item: IClComItem = openTable
    ? openTable.clComItem.filter((v: IClComItem) => v.deletedAt === null && v.materialMedicine && item.id === v.materialMedicine.id).pop()
    : null;

  if (_item && _item.id) return _item.saleValue;

  const _ruler: IClComRuler = openTable
    ? openTable.clComRuler.filter((v: IClComRuler) => v.deletedAt === null && ['MATERIAIS', 'MATERIAL'].includes(v.levelGroup.name)).pop()
    : null;

  if (!_ruler) return 0;

  let pfValue = 0;
  let pmcValue = 0;
  if (item.fractioned) {
    if (item.table === EnumTablePriceType.BRASINDICE) {
      pfValue = item.brasindice ? item.brasindice.pcFrFab : 0;
      pmcValue = item.brasindice ? item.brasindice.pcFrVen : 0;
    } else {
      pfValue = item.simpro ? item.simpro.pcFrFab : 0;
      pmcValue = item.simpro ? item.simpro.pcFrVen : 0;
    }
  } else {
    if (item.table === EnumTablePriceType.BRASINDICE) {
      pfValue = item.brasindice ? item.brasindice.pcEmFab : 0;
      pmcValue = item.brasindice ? item.brasindice.pcEmVen : 0;
    } else {
      pfValue = item.simpro ? item.simpro.pcEmFab : 0;
      pmcValue = item.simpro ? item.simpro.pcEmVen : 0;
    }
  }
  return _ruler.comercialTableType === ComercialTableType.PF
    ? pfValue * 1 + pfValue * (_ruler.inflator / 100)
    : pmcValue * 1 - pmcValue * (_ruler.deflator / 100);
}

  quoteFrequency(frequency, daysCount) {
    switch (frequency) {
      case 'UMXSEMANA':
        return Math.floor(daysCount / 7) * 1;
      case 'DOISXSEMANA':
        return Math.floor(daysCount / 7) * 2;
      case 'TRESXSEMANA':
        return Math.floor(daysCount / 7) * 3;
      case 'QUATROXSEMANA':
        return Math.floor(daysCount / 7) * 4;
      case 'CINCOXSEMANA':
        return Math.floor(daysCount / 7) * 5;
      case 'SEISXSEMANA':
        return Math.floor(daysCount / 7) * 6;
      case 'UMXDIA':
        return daysCount * 1;
      case 'DOISXDIA':
        return daysCount * 2;
      case 'TRESXDIA':
        return daysCount * 3;
      case 'QUATROXDIA':
        return daysCount * 4;
      case 'UMXMES':
        return Math.floor(daysCount / 30) * 1;
      case 'DOISXMES':
        return Math.floor(daysCount / 30) * 2;
      case 'TRESXMES':
        return Math.floor(daysCount / 30) * 3;
      case 'QUATROXMES':
        return Math.floor(daysCount / 30) * 4;
      case 'CADA48HORAS':
        return Math.floor(daysCount / 2);
      case 'CADA72HORAS':
        return Math.floor(daysCount / 3);
      case 'PONTUAL':
        return 1;
      default:
        return 0;
    }
  }



  renderBodyUpdate(quoteMaterialEntity, isNew) {
    const { loading, entityEmbebedPai } = this.props;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;




    const clComTables = showFieldsSelectAsync(entityEmbebedPai, 'patient.client.clComTable', false);
    const openTable = clComTables
      ? clComTables.filter(v => v.deletedAt === null && v.type === 'TABELAABERTA' && v.status === 'ATIVO').pop()
      : null;

    const defaultTo = this.props.entityEmbebedPai['to'] ? moment(this.props.entityEmbebedPai['to']).toDate() : new Date();
    const defaultFrom = this.props.entityEmbebedPai['from'] ? moment(this.props.entityEmbebedPai['from']).toDate() : new Date();

    const daysCount = Math.round(
      ((this.state.finishDateSelectValue ? moment(this.state.finishDateSelectValue).toDate().getTime() : defaultTo.getTime()) -
        (this.state.startDateSelectValue ? moment(this.state.startDateSelectValue).toDate().getTime() : defaultFrom.getTime())) /
        (1000 * 3600 * 24) +
        1
    );

    if (this.state.startDateSelectValue === null) {
      this.setState({
        startDateSelectValue: defaultFrom,
      });
    }
    if (this.state.finishDateSelectValue === null) {
      this.setState({
        finishDateSelectValue: defaultTo,
      });
    }


    const verifiRule = (valor:any) => {
      let valorRetorno = valor
      if(this.state.clComTableSelectValue != null) {
        
      if(this.state.clComTableSelectValue?.clComItem?.length != 0){
        this.state.clComTableSelectValue?.clComRuler?.map((itens:any) => {
            if(itens?.levelGroup?.name === "MEDICAMENTOS" && itens?.levelGroup?.status === "ATIVO"){
              valorRetorno = valor > parseFloat(itens?.minValuePackage) ? valor : 0;
            } else{
              valorRetorno = valor
            }
  
        })
      } else {
        entityEmbebedPai?.patient?.client?.clComTable.map((itens) => {
          if(itens?.id === this.state.clComTableSelectValue?.id){
            if(itens?.clComItem.find(element => element?.materialMedicine?.name === this.state.medicineMaterialSelectValue?.name)){
                  valorRetorno = parseFloat(itens?.clComItem[0]?.saleValue)
            } else {
              valorRetorno = valor
            }
          }else {
              valorRetorno = valor
          }
  
        })
      }
    }else {
      valorRetorno = valor
    }
  
  
  
      return valorRetorno
    }
  

    const packageTable = this.state.clComTableSelectValue;
    const packageRuler =
      packageTable &&
      packageTable.clComRuler &&
      this.state.medicineMaterialSelectValue &&
      this.state.medicineMaterialSelectValue.medicineMaterialType
        ? packageTable.clComRuler
            .filter(v => {
              return ['MATERIAIS', 'MATERIAL'].includes(v.levelGroup.name);
            })
            .map(v => ({ incluseValues: this.quoteFrequency(v.frequency, daysCount), ...v }))
            .sort((a, b) => a.incluseValues - b.incluseValues)
            .pop()
        : null;

    const grossAmount = this.state.unitaryAmountSelectValue * (this.state.quantitySelectValue ? this.state.quantitySelectValue : 0);

    const finalAmount =
      this.state.typeDiscountSelectValue !== 'PORCENTO'
        ? this.state.grossAmountSelectValue - (this.state.discountSelectValue ? this.state.discountSelectValue : 0)
        : this.state.grossAmountSelectValue -
          (this.state.grossAmountSelectValue * (this.state.discountPercentSelectValue ? this.state.discountPercentSelectValue : 0)) / 100;

    if (grossAmount && this.state.grossAmountSelectValue !== grossAmount) {
      this.setState({
        grossAmountSelectValue: grossAmount,
      });
    }
    if (this.state.finalAmountSelectValue !== finalAmount) {
      this.setState({
        finalAmountSelectValue: finalAmount,
      });
    }
    if (entityEmbebedPai && entityEmbebedPai.type !== TypeClientCommercialTable.PACOTE && this.state.clComTableSelectValue !== null) {
      this.setState({
        clComTableSelectValue: null,
        clComTableStartSelectOptions: undefined,
      });
    }

    let cleanValue = typeof this.state.medicineMaterialSelectValue === 'string'
  ? this.state.medicineMaterialSelectValue.replace('(NaN)', '')
  : this.state.medicineMaterialSelectValue;
    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      <Col md="12">
                        <AvInput id="quote-material-medicine-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {entityEmbebedPai.type === 'PACOTE' ? (
                    <Col md="4">
                      <AvGroup>

                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="frequencyLabel" for="quote-material-medicine-frequency">
                              <Translate contentKey="generadorApp.quoteItem.clComTable">clComTable</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="quote-material-medicine-clComTable"
                              name={'clComTable'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.clComTableSelectValue}
                              onChange={(options: any) => {
                                this.setState({
                                  categorySelectValue: null,
                                  categoryStartSelectOptions: undefined,
                                  medicineMaterialStartSelectOptions: undefined,
                                  clComTableSelectValue: options,
                                });
                              }}
                              defaultOptions={this.state.clComTableStartSelectOptions ? this.state.clComTableStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.clComTableStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                const idsPackage = entityEmbebedPai.quotePackage
                                  ? entityEmbebedPai.quotePackage
                                      .filter(v => v.deletedAt === null && v.clComTable)
                                      .map(v => v.clComTable.id)
                                  : null;

                                let result = { data: null };
                                if (idsPackage && idsPackage.length > 0) {
                                  result = await getListAxios(
                                    'cl-com-tables',
                                    {
                                      'id.in': idsPackage && idsPackage.length > 0 ? idsPackage : ['not-list'],
                                    },
                                    0,
                                    100,
                                    'name,asc',
                                    ''
                                  );
                                }

                                let _listData = [];
                                if (openTable) {
                                  _listData = [{ ...openTable, value: openTable.id, label: '(Tabela) ' + openTable.name }];
                                }
                                if (result.data) {
                                  _listData = [..._listData, ...result.data.map(p => ({ ...p, value: p.id, label: '(Pacote) ' + p.name }))];
                                }

                                this.setState({
                                  clComTableStartSelectOptions: _listData,
                                });
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const idsPackage = entityEmbebedPai.quotePackage
                                  ? entityEmbebedPai.quotePackage
                                      .filter(v => v.deletedAt === null && v.clComTable)
                                      .map(v => v.clComTable.id)
                                  : null;

                                let result = { data: null };
                                if (idsPackage && idsPackage.length > 0) {
                                  result = await getListAxios(
                                    'cl-com-tables',
                                    {
                                      'name.contains': inputValue,
                                      'id.in': idsPackage && idsPackage.length > 0 ? idsPackage : ['not-list'],
                                    },
                                    0,
                                    100,
                                    'name,asc',
                                    ''
                                  );
                                }

                                let _listData = [];
                                if (openTable) {
                                  _listData = [{ ...openTable, value: openTable.id, label: '(Tabela) ' + openTable.name }];
                                }
                                if (result.data) {
                                  _listData = [..._listData, ...result.data.map(p => ({ ...p, value: p.id, label: '(Pacote) ' + p.name }))];
                                }

                                this.setState({
                                  clComTableStartSelectOptions: _listData,
                                });

                                callback(_listData);
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <> </>
                  )}
                  <Col md={entityEmbebedPai.type === 'PACOTE' ? '8' : '12'}>
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" for="quote-material-medicine-medicineMaterial">
                            <Translate contentKey="generadorApp.quoteMaterial.Material">MedicineMaterial</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <SelectAsync
                            id="quote-material-medicine-medicineMaterial"
                            name={'medicineMaterial'}
                            className={'css-select-control'}
                            data-type-rel="many-to-one-other-side"
                            value={this.state.medicineMaterialSelectValue}
                            onChange={(options: IMedicineMaterial) => {
                              const unitaryAmount = this.calcValue(options, openTable);
                              this.setState({
                                unitaryAmountEnabled: unitaryAmount <= 0,
                                unitaryAmountSelectValue: verifiRule(options?.simpro?.pcEmFab),
                                medicineMaterialSelectValue: options,
                              });
                            }}
                            defaultOptions={
                              this.state.medicineMaterialStartSelectOptions ? this.state.medicineMaterialStartSelectOptions : []
                            }
                            loadingMessage={input => translate('selectAsync.loadingMessage')}
                            noOptionsMessage={input =>
                              this.state.medicineMaterialStartSelectOptions === undefined
                                ? translate('selectAsync.loadingMessage')
                                : translate('selectAsync.noOptionsMessage')
                            }
                            onMenuOpen={async () => {
                              if (this.state.medicineMaterialStartSelectOptions === undefined) {
                                const result = await getListAxios(
                                  'medicine-materials',
                                  { 'medicineMaterialType.equals': 'MATERIAL' },
                                  0,
                                  100,
                                  'name,asc',
                                  'name,brasindice.id,simpro.id'
                                );
                                this.setState({
                                  medicineMaterialStartSelectOptions: result.data
                                    ? result.data.map(p => {
                                        const amount = (this.calcValue(p, openTable) * 1).toFixed(2).replace('.', ',');
                                        return { ...p, value: p.id, label: p.name + (amount !== '0,00' ? ' (' + amount + ') ' : '') };
                                      })
                                    : [],
                                });
                              }
                            }}
                            loadOptions={async (inputValue, callback) => {
                              const result = await getListAxios(
                                'medicine-materials',
                                { 'name.contains': inputValue, 'medicineMaterialType.equals': 'MATERIAL' },
                                0,
                                100,
                                'name,asc',
                                'name,brasindice.id,simpro.id'
                              );
                              callback(
                                result.data.map(p => {
                                  const amount = (this.calcValue(p, openTable) * 1).toFixed(2).replace('.', ',');
                                  return { ...p, value: p.id, label: p.name + (amount !== '0,00' ? ' (' + amount + ') ' : '') };
                                })
                              );
                            }}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>

                  {baseFilters !== 'startDate' ? (
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="startDateLabel" for="quote-material-medicine-startDate">
                              <Translate contentKey="generadorApp.quoteMaterial.startDate">Start Date</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <DatePicker
                              onChange={value => this.setState({ startDateSelectValue: value })}
                              id="startDate"
                              validate={{}}
                              className="form-control react-datepicker"
                              name="startDate"
                              dateFormat="dd/MM/yyyy"
                              minDate={defaultFrom}
                              maxDate={defaultTo}
                              selected={this.state.startDateSelectValue ? moment(this.state.startDateSelectValue).toDate() : defaultFrom}
                              locale="pt"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ startDateSelectValue: evt.target.value })}
                      type="hidden"
                      name="startDate"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'finishDate' ? (
                    <Col md="2">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="finishDateLabel" for="quote-material-medicine-finishDate">
                              <Translate contentKey="generadorApp.quoteDiet.finishDate">Finish Date</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <DatePicker
                              onChange={value => this.setState({ finishDateSelectValue: value })}
                              id="finishDate"
                              validate={{}}
                              className="form-control react-datepicker"
                              name="finishDate"
                              dateFormat="dd/MM/yyyy"
                              minDate={defaultFrom}
                              maxDate={defaultTo}
                              selected={this.state.finishDateSelectValue ? moment(this.state.finishDateSelectValue).toDate() : defaultTo}
                              locale="pt"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ finishDateSelectValue: evt.target.value })}
                      type="hidden"
                      name="finishDate"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {baseFilters !== 'days' ? (
                    <Col md="1">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="daysLabel" for="quote-material-medicine-days">
                              <Translate contentKey="generadorApp.quoteMedicineMaterial.daysCount">daysCount</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <AvField
                              disabled={true}
                              value={daysCount}
                              id="quote-material-medicine-days"
                              type="number"
                              className="form-control"
                              name="daysCount"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ daysSelectValue: evt.target.value })}
                      type="hidden"
                      name="daysCount"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  <Col md="3">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" id="frequencyLabel" for="quote-material-medicine-frequency">
                            <Translate contentKey="generadorApp.quoteDiet.frequency">Frequency</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <Select
                            id="quote-material-medicine-frequency"
                            className={'css-select-control'}
                            value={{
                              value: this.state.frequencySelectValue ? this.state.frequencySelectValue : '',
                              label: translate(
                                'generadorApp.QuoteFrequency.' +
                                  (this.state.frequencySelectValue ? this.state.frequencySelectValue : 'null')
                              ),
                            }}
                            options={[
                              { value: 'UMXSEMANA', label: translate('generadorApp.QuoteFrequency.UMXSEMANA') },
                              { value: 'DOISXSEMANA', label: translate('generadorApp.QuoteFrequency.DOISXSEMANA') },
                              { value: 'TRESXSEMANA', label: translate('generadorApp.QuoteFrequency.TRESXSEMANA') },
                              { value: 'QUATROXSEMANA', label: translate('generadorApp.QuoteFrequency.QUATROXSEMANA') },
                              { value: 'CINCOXSEMANA', label: translate('generadorApp.QuoteFrequency.CINCOXSEMANA') },
                              { value: 'SEISXSEMANA', label: translate('generadorApp.QuoteFrequency.SEISXSEMANA') },
                              { value: 'UMXDIA', label: translate('generadorApp.QuoteFrequency.UMXDIA') },
                              { value: 'DOISXDIA', label: translate('generadorApp.QuoteFrequency.DOISXDIA') },
                              { value: 'TRESXDIA', label: translate('generadorApp.QuoteFrequency.TRESXDIA') },
                              { value: 'QUATROXDIA', label: translate('generadorApp.QuoteFrequency.QUATROXDIA') },
                              { value: 'UMXMES', label: translate('generadorApp.QuoteFrequency.UMXMES') },
                              { value: 'DOISXMES', label: translate('generadorApp.QuoteFrequency.DOISXMES') },
                              { value: 'TRESXMES', label: translate('generadorApp.QuoteFrequency.TRESXMES') },
                              { value: 'QUATROXMES', label: translate('generadorApp.QuoteFrequency.QUATROXMES') },
                              { value: 'CADA48HORAS', label: translate('generadorApp.QuoteFrequency.CADA48HORAS') },
                              { value: 'CADA72HORAS', label: translate('generadorApp.QuoteFrequency.CADA72HORAS') },
                              { value: 'PONTUAL', label: translate('generadorApp.QuoteFrequency.PONTUAL') },
                            ]}
                            onChange={(options: any) => {
                              const daysSelectValue = this.quoteFrequency(options['value'], daysCount);
                              return this.setState({
                                frequencySelectValue: options['value'],
                                daysSelectValue,
                                quantitySelectValue:
                                  !packageRuler || this.state.unitaryAmountSelectValue >= packageRuler.minValuePackage
                                    ? daysSelectValue
                                    : 0,
                              });
                            }}
                            name="frequency"
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md="2">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" id="daysLabel" for="quote-material-medicine-days">
                            <Translate contentKey="generadorApp.quoteDiet.days">Days</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <CurrencyInput
                            precision={0}
                            onChange={(maskedvalue, floatvalue, event) => {
                              return this.setState({
                                quantitySelectValue:
                                  this.state.unitaryAmountSelectValue >= (packageRuler && packageRuler.minValuePackage ? floatvalue : 0),
                                daysSelectValue: floatvalue,
                              });
                            }}
                            allowNegative={false}
                            value={this.state.daysSelectValue ? this.state.daysSelectValue : '0'}
                            id="quote-rh-days"
                            name="days"
                            className={'form-control'}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md="2">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" id="unitaryAmountLabel" for="quote-material-medicine-unitaryAmount">
                            <Translate contentKey="generadorApp.quoteMaterial.unitaryAmount">Unitary Amount</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <CurrencyInput
                            disabled={!this.state.unitaryAmountEnabled}
                            onChange={(maskedvalue, floatvalue, event) => this.setState({ unitaryAmountSelectValue: floatvalue })}
                            decimalSeparator=","
                            thousandSeparator=""
                            prefix="R$ "
                            allowNegative={true}
                            value={this.state.unitaryAmountSelectValue}
                            id="quote-material-medicine-unitaryAmount"
                            name="unitaryAmount"
                            className={'form-control'}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <Col md="2">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" id="quantityLabel" for="quote-material-medicine-quantity">
                            <Translate contentKey="generadorApp.quoteMaterial.quantity">Quantity</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <AvField
                            onChange={evt => this.setState({ quantitySelectValue: evt.target.value })}
                            id="quote-material-medicine-quantity"
                            value={this.state.quantitySelectValue ? this.state.quantitySelectValue : '0'}
                            type="number"
                            className="form-control"
                            name="quantity"
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>

                  <Col md="2">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" id="discountLabel" for="quote-material-medicine-grossAmount">
                            <Translate contentKey="generadorApp.quoteMaterial.grossAmount">Gross Amount</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <CurrencyInput
                            disabled={true}
                            onChange={(maskedvalue, floatvalue, event) => this.setState({ grossAmountSelectValue: floatvalue })}
                            decimalSeparator=","
                            thousandSeparator=""
                            prefix="R$ "
                            allowNegative={true}
                            value={this.state.grossAmountSelectValue}
                            id="quote-material-medicine-grossAmount"
                            name="grossAmount"
                            className={'form-control'}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>

                  <Col md="3">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" id="frequencyLabel" for="quote-material-medicine-frequency">
                            <Translate contentKey="generadorApp.quote.typeDiscount">Type Discount</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <Select
                            id="quote-material-medicine-frequency"
                            className={'css-select-control'}
                            value={
                              this.state.typeDiscountSelectValue
                                ? {
                                    value: this.state.typeDiscountSelectValue ? this.state.typeDiscountSelectValue : '',
                                    label: translate('generadorApp.quote.' + this.state.typeDiscountSelectValue),
                                  }
                                : { value: 'VALOR_UNITARIO', label: translate('generadorApp.quote.VALOR_UNITARIO') }
                            }
                            options={[
                              { value: 'VALOR_UNITARIO', label: translate('generadorApp.quote.VALOR_UNITARIO') },
                              { value: 'PORCENTO', label: translate('generadorApp.quote.PORCENTO') },
                            ]}
                            onChange={(options: any) => this.setState({ typeDiscountSelectValue: options['value'] })}
                            name="frequency"
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>

                  <Col md="2">
                    <AvGroup>
                      {this.state.typeDiscountSelectValue === 'PORCENTO' ? (
                        <Row>
                          <Col md="12">
                            <Label
                              className="mt-2 label-single-line"
                              id="discountPercentLabel"
                              for="quote-material-medicine-discountPercent"
                            >
                              <Translate contentKey="generadorApp.quoteMaterial.discountPercent">Discount (%)</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ discountPercentSelectValue: floatvalue })}
                              decimalSeparator=","
                              thousandSeparator=""
                              suffix=" %"
                              allowNegative={true}
                              value={this.state.discountPercentSelectValue ? this.state.discountPercentSelectValue : 0}
                              id="quote-material-medicine-discountPercent"
                              name="discountPercent"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      ) : (
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="discountLabel" for="quote-material-medicine-discount">
                              <Translate contentKey="generadorApp.quoteDiet.discount">discount</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ discountSelectValue: floatvalue })}
                              decimalSeparator=","
                              thousandSeparator=""
                              prefix="R$ "
                              allowNegative={true}
                              value={this.state.discountSelectValue ? this.state.discountSelectValue : 0}
                              id="quote-material-medicine-discount"
                              name="discount"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      )}
                    </AvGroup>
                  </Col>
                  <Col md="3">
                    <AvGroup>
                      <Row>
                        <Col md="12">
                          <Label className="mt-2 label-single-line" id="finalAmountLabel" for="quote-material-medicine-finalAmount">
                            <Translate contentKey="generadorApp.quoteMaterial.finalAmount">Final Amount</Translate>
                          </Label>
                        </Col>
                        <Col md="12">
                          <CurrencyInput
                            disabled={true}
                            onChange={(maskedvalue, floatvalue, event) => this.setState({ finalAmountSelectValue: floatvalue })}
                            allowNegative={true}
                            decimalSeparator=","
                            thousandSeparator=""
                            prefix="R$ "
                            value={this.state.finalAmountSelectValue}
                            id="quote-material-medicine-finalAmount"
                            name="finalAmount"
                            className={'form-control'}
                          />
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>
                  <AvInput type="hidden" name="quote" value={this.state.fieldsBase[baseFilters + 'Id']} />
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    return (
      <Panel>
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
            {this.renderNewModal()}

            {this.renderUpdateModal()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
    user: storeState.authentication.account,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;

export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(QuoteMaterialExtended);
