/* eslint complexity: ["error", 300] */
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Col, Label } from 'reactstrap';
import { ProfileUpdate, mapStateToProps, mapDispatchToProps } from '../profile-update';
import { getAllEntities as getPermissions } from 'app/entities/permission/permission.reducer';
import { createEntity as createEntityPermissions } from 'app/entities/permission-profile/permission-profile.reducer';
import { IRootState } from 'app/shared/reducers';

import { getEntityFiltersURL } from '../profile.reducer';
import {
  permissionProfileSelectAll,
  permissionProfileUnselectAll,
} from 'app/modules/administration/user-management/user-management.reducer';
import { Translate } from 'app/config/translate-component';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  mapDispatchToPropsExtended as mapDispatchToPropsPermissionProfile,
  PermissionProfileExtended as PermissionProfile,
} from 'app/entities/permission-profile/extended/permission-profile';
import { getFilterFromSelect } from 'app/shared/util/entity-utils';
import { BASE_API_VERSION_PATH } from 'app/config/constants';

export interface IUpdateExtendedState {
  permissionBooleanList?: any;
}

export class ProfileExtendedUpdate extends ProfileUpdate {
  renderHeader() {
    const { profileEntity, permissionProfiles, users, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.profile.home.createLabel">Create a Profile</Translate>
            ) : (
              <Translate contentKey="generadorApp.profile.home.editLabel">Edit a Profile</Translate>
            )}
          </span>
          <Button
            color="primary"
            className="float-right jh-create-entity"
            disabled={updating}
            onClick={() => {
              new Promise(resolve => {
                resolve(
                  axios.post(BASE_API_VERSION_PATH + 'users/save-profile-permission', {
                    id: profileEntity.id,
                    name: this.state.nameSelectValue,
                    permission: this.state.permissionBooleanList ? Object.values(this.state.permissionBooleanList) : [],
                  })
                );
              }).then(resultEntity => {
                this.props.history.goBack();
              });
            }}
          >
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.profile.btnSave">Save</Translate>
          </Button>

          <Button
            tag={Link}
            id="cancel-save"
            to={'/profile?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.profile.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={'/profile'}>
              <Translate contentKey="generadorApp.profile.home.title">Profiles</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.profile.detail.title">Profile edit</Translate>
          </li>
        </ol>
      </>
    );
  }

  saveEntity = (event: any, errors: any, values: any) => {
    const selectFieldsList = ['name', 'status'];

    const permissionProfile = this.props.permissions.map((_permissionProfile, i) => {
      return _permissionProfile.id;
    });

    if (errors.length === 0) {
      const { profileEntity } = this.props;
      const _entity = {
        ...profileEntity,
        // ...values,

        permissionProfile: this.state.isNew ? permissionProfile : undefined,

        name: this.state.nameSelectValue,
        status: this.state.statusSelectValue === null ? false : this.state.statusSelectValue,
      };

      const entity: any = _entity;

      const { name, status, permissionProfileId, userId, extraFilters, activePage, itemsPerPage, sort, order } = this.state.fieldsBase;

      const permissionProfileListAllEmbed = this.props.permissionProfileListAllEmbed;

      if (this.state.isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              name,
              status,
              getFilterFromSelect(permissionProfileId, 'one-to-many'),
              getFilterFromSelect(userId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              name,
              status,
              getFilterFromSelect(permissionProfileId, 'one-to-many'),
              getFilterFromSelect(userId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
    }
  };

  UNSAFE_componentWillMount() {
    super.UNSAFE_componentWillMount();
    this.props.getPermissions();
  }

  renderBody() {
    const { profileEntity, permissionProfiles, users, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const fullEntityEmbebed = this.getFullEntityFromState(profileEntity);

    const mapDispatchToPropsPermissionProfileList: any = {};
    Object.keys(mapDispatchToPropsPermissionProfile).map(v => {
      mapDispatchToPropsPermissionProfileList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'PermissionProfile')) {
          this.setState({ functionEmbebed: [...this.state.functionEmbebed, v + 'PermissionProfile'] }, this.props[v + 'PermissionProfile']);
        }
      };
    });

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="profile-id">
                  <Translate contentKey="generadorApp.profile.field_id">ID</Translate>
                </Label>
                </Col> */}
                      <Col md="12">
                        <AvInput id="profile-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'name' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="nameLabel" for="profile-name">
                              <Translate contentKey="generadorApp.profile.name">Name</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <AvInput
                              onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                              validate={{}}
                              id="profile-name"
                              type="text"
                              name="name"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                      type="hidden"
                      name="name"
                      value={this.state.fieldsBase[baseFilters]}
                    />
                  )}
                  {!isNew ? (
                    <Col md="12">
                      <div className="entityEmbebedList">
                        <PermissionProfile
                          entityEmbebedPai={fullEntityEmbebed}
                          history={this.props.history}
                          location={{
                            pathname: `/profile/${this.props.match.params['id']}/permission-profile/`,
                            search: '',
                            hash: '',
                            state: undefined,
                            key: 'q2tbhd',
                          }}
                          match={{
                            path: `/profile/:idProfile/permission-profile/:id`,
                            url: `/profile/${this.props.match.params['id']}/permission-profile/`,
                            isExact: true,
                            params: {
                              idProfile: this.props.match.params['id'],
                              url: `/profile/${this.props.match.params['id']}/permission-profile/`,
                            },
                          }}
                          {...this.props.mapStateToPropsPermissionProfile}
                          {...mapDispatchToPropsPermissionProfileList}
                          permissionProfileSelectAll={this.props.permissionProfileSelectAll}
                          permissionProfileUnselectAll={this.props.permissionProfileUnselectAll}
                          getEntities={this.permissionProfileGetEntities}
                          getEntityPai={(...v) => this.props.getEntity(...v)}
                          updateEntity={this.permissionProfileUpdateEntity}
                          getEntity={this.permissionProfileGetEntity}
                          createEntity={this.permissionProfileCreateEntity}
                          deleteEntity={this.permissionProfileDeleteEntity}
                          reset={this.permissionProfileReset}
                          permissionBooleanList={this.state.permissionBooleanList}
                          setPermissionBooleanList={permissionBooleanList => this.setState({ permissionBooleanList })}
                          permissionProfileList={this.props.permissionProfileListEmbed}
                          totalItems={this.props.permissionProfileListEmbed ? this.props.permissionProfileListEmbed.length : 0}
                          loading={this.props.loading}
                          permissionProfileEntity={this.props.permissionProfileSelected}
                        />
                      </div>
                    </Col>
                  ) : (
                    ''
                  )}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { profileEntity, permissionProfiles, users, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...profileEntity,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel>
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
    permissions: storeState.permission.entities,
    listProcediment: storeState.patDrPrescrProcd.entities,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
  getPermissions,
  createEntityPermissions,
  permissionProfileSelectAll,
  permissionProfileUnselectAll,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;
export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(ProfileExtendedUpdate);
