import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import PatientResponsible from './patient-responsible';

import PatientResponsibleDetail from './patient-responsible-detail';

import PatientResponsibleUpdate from './patient-responsible';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={PatientResponsibleUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={PatientResponsibleUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={PatientResponsibleDetail} />
      <ErrorBoundaryRoute path={match.path} component={PatientResponsible} />
    </Switch>
  </>
);

export default Routes;
