import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity, IClComTableBaseState, getUrlBack, getClComTableState, getEntityFiltersURL } from './cl-com-table.reducer';

export interface IClComTableState {
  fieldsBase: IClComTableBaseState;
}

export interface IClComTableDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class ClComTableDetail extends React.Component<IClComTableDetailProps, IClComTableState> {
  constructor(props: Readonly<IClComTableDetailProps>) {
    super(props);
    this.state = {
      ...this.state,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getClComTableState(this.props.location),
        urlBack: getUrlBack(this.props.location),
        clientId: [{ value: this.props.match.params['idClient'], label: this.props.match.params['idClient'] }],
        baseFilters: 'client',
      },
    };
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = '_back=1' + (offset !== null ? '&offset=' + offset : '');
    Object.keys(fieldsBase).map(key => {
      url += '&' + key + '=' + fieldsBase[key];
    });
    return url;
  };

  UNSAFE_componentWillMount() {
    this.props.getEntity(this.props.match.params['id']);
  }

  renderHeader() {
    const { clComTableEntity } = this.props;
    const state = this.state;
    return (
      <>
        <div id="page-heading">
          {' '}
          <h2>
            <Translate contentKey="generadorApp.clComTable.detail.title">ClComTable</Translate>
            <small>&nbsp; {clComTableEntity['name']}</small>
          </h2>
        </div>

        <div className="pull-right">
          <Button
            tag={Link}
            to={'/client/' + this.props.match.params['idClient'] + '/cl-com-table?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
          >
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.clComTable.btnBack">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/client/${this.props.match.params['idClient']}/cl-com-table/${clComTableEntity.id}/edit?+${getEntityFiltersURL(
              this.state.fieldsBase
            )}`}
            replace
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.clComTable.btnEdit">Edit</Translate>
            </span>
          </Button>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>

          <li className="breadcrumb-item">
            <Link to={'/client'}>Clients</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/client/' + this.props.match.params['idClient']}>{this.props.match.params['idClient']}</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/client/' + (this.state.fieldsBase.clientId ? this.state.fieldsBase.clientId.label : '') + '/cl-com-table'}>
              Commercial tables
            </Link>
          </li>
          <li className="breadcrumb-item active">Commercial tables details</li>
        </ol>
      </>
    );
  }

  renderBody() {
    const { clComTableEntity } = this.props;
    const state = this.state;
    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            <Row className="jh-entity-details">
              <Col className="view-detail-row cl-com-table-name-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top cl-com-table-name-detail-label" md="12">
                    <dt>
                      <span id="name">
                        <Translate contentKey="generadorApp.clComTable.name">Name</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top cl-com-table-name-detail-value" md="12">
                    <dd>{clComTableEntity.name}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row cl-com-table-type-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top cl-com-table-type-detail-label" md="12">
                    <dt>
                      <span id="type">
                        <Translate contentKey="generadorApp.clComTable.type">Type</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top cl-com-table-type-detail-value" md="12">
                    <dd>
                      {translate(
                        'generadorApp.TypeClientCommercialTable.' + (clComTableEntity.type ? clComTableEntity.type : 'TABELAABERTA')
                      )}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row cl-com-table-status-detail" md="2">
                <Row>
                  <Col className="view-detail-label-top cl-com-table-status-detail-label" md="12">
                    <dt>
                      <span id="status">
                        <Translate contentKey="generadorApp.clComTable.status">Status</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top cl-com-table-status-detail-value" md="12">
                    <dd>{translate('generadorApp.Status.' + (clComTableEntity.status ? clComTableEntity.status : 'INATIVADO'))}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row cl-com-table-clComRuler-detail" md="12">
                <Row>
                  <Col className="view-detail-label-top cl-com-table-clComRuler-detail-label" md="12">
                    <dt>
                      <Translate contentKey="generadorApp.clComTable.clComRuler">Cl Com Ruler</Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top cl-com-table-clComRuler-detail-value" md="12">
                    <dd>{clComTableEntity.clComRuler ? clComTableEntity.clComRuler.id : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row cl-com-table-clComItem-detail" md="12">
                <Row>
                  <Col className="view-detail-label-top cl-com-table-clComItem-detail-label" md="12">
                    <dt>
                      <Translate contentKey="generadorApp.clComTable.clComItem">Cl Com Item</Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top cl-com-table-clComItem-detail-value" md="12">
                    <dd>{clComTableEntity.clComItem ? clComTableEntity.clComItem.id : ''}</dd>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { clComTableEntity } = this.props;
    const state = this.state;
    return (
      <div>
        <Panel className="cl-com-table cl-com-table-detail">
          <PanelHeader>{this.renderHeader()}</PanelHeader>

          <PanelBody>{this.renderBody()}</PanelBody>
        </Panel>
      </div>
    );
  }
}

export const mapStateToProps = ({ clComTable, ...storeState }: IRootState) => ({
  clComTableEntity: clComTable.entity,
});

export const mapDispatchToProps = {
  getEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ClComTableDetail);
