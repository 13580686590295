import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { IPatDrPrescrProcd } from 'app/shared/model/pat-dr-prescr-procd.model';
import { IPatient } from 'app/shared/model/patient.model';

export interface IPatientDoctorPrescription {
  id?: number;
  observation?: string;
  patDrPrescrProcd?: IPatDrPrescrProcd[];
  patient?: IPatient;
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  patDrPrescrProcd: {
    isMulti: false, // one-to-many - other-side
    entity: 'PatDrPrescrProcd',
    urlParameters: {
      enpoint: 'pat-dr-prescr-procds',
      filters: 'patientDoctorPrescription.id.equals',
    },
  },
  patient: {
    isMulti: false, // one-to-one - other-side
    entity: 'Patient',
    urlParameters: {
      enpoint: 'patients',
      filters: 'patientDoctorPrescription.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'patientDoctorPrescription.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'patientDoctorPrescription.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<IPatientDoctorPrescription> = {};
