/* eslint-disable no-console */
/* eslint complexity: ["error", 300] */
import React from 'react';
import { connect } from 'react-redux';

import { Pad, mapStateToProps, mapDispatchToProps } from '../pad';

import { IRootState } from 'app/shared/reducers';
import { Translate } from 'app/config/translate-component';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import ModalFilterExportPads from 'app/entities/pad/extended/modal-filter-export-pads';
import { PadsPdfReport } from 'app/entities/pad/report/pads';

export interface IExtendedState {
  showModalReport?: boolean;
  report?: any;
  fromFilter?: any;
  toFilter?: any;
}
export interface IExtendedProps {
  entityEmbebedPai?: any;
}

export class PadExtended extends Pad {
  renderHeader() {
    const { padCidPtas, padAttendances, patients, quotes, padList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.pad.home.title">PAD</Translate>
          </span>
          <Button id="togglerFilterPad" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.pad.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          &nbsp;
          <ModalFilterExportPads setBaseState={v => this.setState(v)} />
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.pad.home.title">PAD</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    return <> {super.renderFilter()} </>;
  }

  UNSAFE_componentWillMount() {
    super.UNSAFE_componentWillMount();
  }

  renderTable() {
    return <> {super.renderTable()} </>;
  }

  renderFooter() {
    return <> {super.renderFooter()} </>;
  }

  render() {
    return (
      <>
        {this.renderModal()}
        {super.render()}
      </>
    );
  }

  renderModal() {
    const report: any = this.state.report;
    if (!report || this.state.showModalReport !== true) return <> </>;

    return (
      <Modal size="xl" isOpen toggle={() => this.setState({ showModalReport: false })}>
        <ModalBody id="generadorApp.quote.pdf.generate">
          <PadsPdfReport data={report.data} from={this.state.fromFilter} to={this.state.toFilter} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => this.setState({ showModalReport: false })}>
            <FontAwesomeIcon icon="ban" />
            &nbsp;
            <Translate contentKey="generadorApp.quote.btnCancel">Close</Translate>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
    quoteRhs: storeState.quoteRh.entities,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;

export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(PadExtended);
