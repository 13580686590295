/* eslint complexity: ["error", 500] */
import axios from 'axios';

import { IPaginationBaseState, IPayloadResult } from 'react-jhipster';

import { IPayload } from 'react-jhipster/src/type/redux-action.type';

import { BASE_API_VERSION_PATH } from 'app/config/constants';

import { cleanEntity, jsonParse } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IClComTable, defaultValue } from 'app/shared/model/cl-com-table.model';

import { IClComRuler, defaultValue as defaultValueClComRuler } from 'app/shared/model/cl-com-ruler.model';
import { IClComItem, defaultValue as defaultValueClComItem } from 'app/shared/model/cl-com-item.model';

export declare type ICrudPutAction<T> = (data?: T, listFiltersPage?) => IPayload<T> | IPayloadResult<T>;
export declare type ICrudGetAction<T> = (id: string | number, selectFields?: string) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const ACTION_TYPES = {
  FETCH_CLCOMTABLE_LIST_EXPORT: 'clComTable/FETCH_CLCOMTABLE_LIST_EXPORT',
  FETCH_CLCOMTABLE_LIST: 'clComTable/FETCH_CLCOMTABLE_LIST',
  FETCH_CLCOMTABLE: 'clComTable/FETCH_CLCOMTABLE',
  FETCH_CLCOMTABLE_OFFSET: 'clComTable/FETCH_CLCOMTABLE_OFFSET',
  CREATE_CLCOMTABLE: 'clComTable/CREATE_CLCOMTABLE',
  UPDATE_CLCOMTABLE: 'clComTable/UPDATE_CLCOMTABLE',
  CREATE_CLCOMTABLE_AND_GO_LIST: 'clComTable/CREATE_CLCOMTABLE_AND_GO_LIST',
  UPDATE_CLCOMTABLE_AND_GO_LIST: 'clComTable/UPDATE_CLCOMTABLE_AND_GO_LIST',
  DELETE_CLCOMTABLE: 'clComTable/DELETE_CLCOMTABLE',
  SET_BLOB: 'clComTable/SET_BLOB',
  RESET: 'clComTable/RESET',
  SHOW_MODAL: 'clComTable/SHOW_MODAL',
};

const initialState = {
  loading: false,
  openModal: {
    list: false,
    view: false,
    detail: false,
  },
  errorMessage: null,
  entities: [] as ReadonlyArray<IClComTable>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,

  clComRulerListEmbed: [] as ReadonlyArray<IClComRuler> | any,
  clComRulerListAllEmbed: [] as ReadonlyArray<IClComRuler> | any,
  clComRulerSelected: defaultValueClComRuler,
  clComItemListEmbed: [] as ReadonlyArray<IClComItem> | any,
  clComItemListAllEmbed: [] as ReadonlyArray<IClComItem> | any,
  clComItemSelected: defaultValueClComItem,
};

const actionPendind = [];
let actualState = initialState;

export const convertEntityBuffers = entity => ({
  ...entity,
  observation: entity.observation ? Buffer.from(entity.observation).toString() : entity.observation,
});

export type ClComTableState = Readonly<typeof initialState>;

export interface IClComTableBaseState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  listCheckedID?: Array<number>;
  isCheckedAll?: boolean;
  urlBack?: string;
  extraFilters?: any;
  baseFilters: any;
  offset: number;
  name?: any;
  status?: any;
  type?: any;
  valuePackage?: any;
  option?: any;
  saleValue?: any;
  observation?: any;
  rentalValue?: any;
  clientId?: any;
  clientStartFilter?: any;
  clComRulerId?: any;
  clComRulerStartFilter?: any;
  clComItemId?: any;
  clComItemStartFilter?: any;
}

export interface IFieldsBase extends IClComTableBaseState, IPaginationBaseState {}
export interface IClComTableUpdateState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  urlBack?: string;
  functionEmbebed?: any[];
  fieldsBase: IFieldsBase;
  nameSelectValue?: any;
  nameStartSelectOptions?: any;
  statusSelectValue?: any;
  statusStartSelectOptions?: any;
  chargeTypeSelectValue?: any;
  chargeTypeStartSelectOptions?: any;
  typeSelectValue?: any;
  typeStartSelectOptions?: any;
  valuePackageSelectValue?: any;
  valuePackageStartSelectOptions?: any;
  optionSelectValue?: any;
  optionStartSelectOptions?: any;
  saleValueSelectValue?: any;
  saleValueStartSelectOptions?: any;
  observationSelectValue?: any;
  observationStartSelectOptions?: any;
  rentalValueSelectValue?: any;
  rentalValueStartSelectOptions?: any;
  tussCodeSelectValue?: any;
  tussCodeStartSelectOptions?: any;
  tableCodeSelectValue?: any;
  tableCodeStartSelectOptions?: any;
  expenseCodeSelectValue?: any;
  expenseCodeStartSelectOptions?: any;

  quotePackageSelectValue?: any;
  quotePackageStartSelectOptions?: any;

  quoteSelectValue?: any;
  quoteStartSelectOptions?: any;

  clComRulerSelectValue?: any;
  clComRulerStartSelectOptions?: any;

  clComItemSelectValue?: any;
  clComItemStartSelectOptions?: any;

  clientSelectValue?: any;
  clientStartSelectOptions?: any;

  doseSelectValue?: any;
  doseStartSelectOptions?: any;

  isNew: boolean;
  clientFantasyName?: any;

  clientId?: any;
  doseName?: any;

  doseId?: any;
}

// Reducer

export default (state: ClComTableState = initialState, action): ClComTableState => {
  actualState = state;
  if (
    action.type.includes('_PENDING') &&
    !actionPendind.includes(action.type.replace('_PENDING', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_PENDING', ''))
  ) {
    actionPendind.push(action.type.replace('_PENDING', ''));
  }

  if (
    action.type.includes('_FULFILLED') &&
    actionPendind.includes(action.type.replace('_FULFILLED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_FULFILLED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_FULFILLED', '')), 1);
  }

  if (
    action.type.includes('_REJECTED') &&
    actionPendind.includes(action.type.replace('_REJECTED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_REJECTED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_REJECTED', '')), 1);
  }

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_CLCOMTABLE_LIST_EXPORT):
    case REQUEST(ACTION_TYPES.FETCH_CLCOMTABLE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_CLCOMTABLE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_CLCOMTABLE):
    case REQUEST(ACTION_TYPES.UPDATE_CLCOMTABLE):
    case REQUEST(ACTION_TYPES.CREATE_CLCOMTABLE_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.UPDATE_CLCOMTABLE_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.DELETE_CLCOMTABLE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_CLCOMTABLE_LIST_EXPORT):
    case FAILURE(ACTION_TYPES.FETCH_CLCOMTABLE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_CLCOMTABLE):
    case FAILURE(ACTION_TYPES.CREATE_CLCOMTABLE):
    case FAILURE(ACTION_TYPES.UPDATE_CLCOMTABLE):
    case FAILURE(ACTION_TYPES.CREATE_CLCOMTABLE_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.UPDATE_CLCOMTABLE_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.DELETE_CLCOMTABLE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_CLCOMTABLE_LIST):
      action.payload.data = action.payload.data.map(v => {
        return { ...v, observation: v.observation ? Buffer.from(v.observation).toString() : v.observation };
      });

      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_CLCOMTABLE_OFFSET):
      if (action.payload.data && action.payload.data.length > 0) {
        return {
          ...state,
          loading: false,
          entity: convertEntityBuffers(action.payload.data[0]),
        };
      }
      return {
        ...state,
        loading: false,
      };

    case SUCCESS(ACTION_TYPES.FETCH_CLCOMTABLE):
      return {
        ...state,
        loading: false,
        entity: convertEntityBuffers(action.payload.data),
      };
    case SUCCESS(ACTION_TYPES.CREATE_CLCOMTABLE):
    case SUCCESS(ACTION_TYPES.UPDATE_CLCOMTABLE):
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_CLCOMTABLE_AND_GO_LIST):
    case SUCCESS(ACTION_TYPES.UPDATE_CLCOMTABLE_AND_GO_LIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_CLCOMTABLE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.SET_BLOB: {
      const { name, data, contentType, fileName } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [name + 'Base64']: data,
          [name + 'ContentType']: contentType,
          [name + 'FileName']: fileName,
        },
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
        clComRulerListEmbed: [],
        clComRulerListAllEmbed: [],
        clComRulerSelected: state.clComRulerSelected,
        clComItemListEmbed: [],
        clComItemListAllEmbed: [],
        clComItemSelected: state.clComItemSelected,
      };

    case 'clComTable/CLCOMRULER_GET_ENTITIES':
      return {
        ...state,
        clComRulerListEmbed: state.clComRulerListAllEmbed,
      };
    case 'clComTable/CLCOMRULER_CREATE_ENTITY':
      return {
        ...state,
        clComRulerListAllEmbed: [...(state.clComRulerListAllEmbed ? state.clComRulerListAllEmbed : []), action.payload],
      };
    case 'clComTable/CLCOMRULER_SET_ENTITIES':
      return {
        ...state,
        clComRulerListAllEmbed: action.payload,
      };
    case 'clComTable/CLCOMRULER_UPDATE_ENTITY': {
      const clComRulerListAllEmbed = [...state.clComRulerListAllEmbed];
      state.clComRulerListAllEmbed.map((v, i: any) => {
        if (v.id === action.payload.id) {
          clComRulerListAllEmbed[i] = action.payload;
        }
      });
      return {
        ...state,
        clComRulerListAllEmbed,
        clComRulerSelected: {},
      };
    }
    case 'clComTable/CLCOMRULER_RESET':
      return {
        ...state,
        clComRulerSelected: {},
        clComRulerListEmbed: [],
      };
    case 'clComTable/CLCOMRULER_GET_ENTITY':
      if (!!state.clComRulerListAllEmbed && state.clComRulerListAllEmbed.constructor === Array) {
        return {
          ...state,
          clComRulerSelected: state.clComRulerListAllEmbed.filter(v => v.id === action.payload).pop(),
        };
      }
      return {
        ...state,
        clComRulerSelected: state.clComRulerListAllEmbed,
      };
    case 'clComTable/CLCOMRULER_DELETE_ENTITY':
      return {
        ...state,
        clComRulerListAllEmbed: state.clComRulerListAllEmbed.filter(v => v.id !== action.payload),
      };

    case 'clComTable/CLCOMITEM_GET_ENTITIES':
      return {
        ...state,
        clComItemListEmbed: state.clComItemListAllEmbed,
      };
    case 'clComTable/CLCOMITEM_CREATE_ENTITY':
      return {
        ...state,
        clComItemListAllEmbed: [...(state.clComItemListAllEmbed ? state.clComItemListAllEmbed : []), action.payload],
      };
    case 'clComTable/CLCOMITEM_SET_ENTITIES':
      return {
        ...state,
        clComItemListAllEmbed: action.payload,
      };
    case 'clComTable/CLCOMITEM_UPDATE_ENTITY': {
      const clComItemListAllEmbed = [...state.clComItemListAllEmbed];
      state.clComItemListAllEmbed.map((v, i: any) => {
        if (v.id === action.payload.id) {
          clComItemListAllEmbed[i] = action.payload;
        }
      });
      return {
        ...state,
        clComItemListAllEmbed,
        clComItemSelected: {},
      };
    }
    case 'clComTable/CLCOMITEM_RESET':
      return {
        ...state,
        clComItemSelected: {},
        clComItemListEmbed: [],
      };
    case 'clComTable/CLCOMITEM_GET_ENTITY':
      if (!!state.clComItemListAllEmbed && state.clComItemListAllEmbed.constructor === Array) {
        return {
          ...state,
          clComItemSelected: state.clComItemListAllEmbed.filter(v => v.id === action.payload).pop(),
        };
      }
      return {
        ...state,
        clComItemSelected: state.clComItemListAllEmbed,
      };
    case 'clComTable/CLCOMITEM_DELETE_ENTITY':
      return {
        ...state,
        clComItemListAllEmbed: state.clComItemListAllEmbed.filter(v => v.id !== action.payload),
      };

    case ACTION_TYPES.SHOW_MODAL: {
      const openModal = state.openModal;
      openModal[action.payload.type] = action.payload.show;
      return {
        ...state,
        openModal,
      };
    }
    default:
      return state;
  }
};

const apiUrl = BASE_API_VERSION_PATH + 'cl-com-tables';

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

// Actions

// Actions
export type ICrudGetAllActionClComTable<T> = (
  name?: any,
  status?: any,
  type?: any,
  valuePackage?: any,
  option?: any,
  saleValue?: any,
  observation?: any,
  rentalValue?: any,
  clientId?: any,
  clComRulerId?: any,
  clComItemId?: any,
  extraFilters?: {},
  page?: number,
  size?: number,
  sort?: string,
  selectFields?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getEntitiesFilter = (filters?, page?, size?, sort?, selectFields = '') => {
  const requestUrl = `${apiUrl}?page=${page >= 0 ? page : 0}&size=${size >= 0 ? size : 20}&sort=${sort ? sort : 'id,asc'}&`;
  const requestFilters = filters.map(v => v.column + '.' + v.operation + '=' + v.value).join('&');
  return {
    type: ACTION_TYPES.FETCH_CLCOMTABLE_LIST,
    payload: axios.get<IClComTable>(`${requestUrl}${requestFilters}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getAllEntities = (filters?, sort?, selectFields = '') => {
  if (
    (actualState.totalItems > 0 && actualState.entities.length === actualState.totalItems) ||
    actionPendind.includes(ACTION_TYPES.FETCH_CLCOMTABLE_LIST)
  ) {
    return {
      type: ACTION_TYPES.FETCH_CLCOMTABLE_LIST + '_FROM_CACHE',
      payload: new Promise(function (resolve, reject) {
        resolve('done');
      }),
    };
  }

  const idsRequest = filters && typeof filters['ids'] !== 'undefined' && filters['ids'] ? `id.in=${filters['ids'].join(',')}&` : '';
  const nameRequest = filters && typeof filters['name'] !== 'undefined' && filters['name'] ? `name.contains=${filters['name']}&` : '';
  const statusRequest =
    filters && typeof filters['status'] !== 'undefined' && filters['status'] ? `status.contains=${filters['status']}&` : '';
  const typeRequest = filters && typeof filters['type'] !== 'undefined' && filters['type'] ? `type.contains=${filters['type']}&` : '';
  const valuePackageRequest =
    filters && typeof filters['valuePackage'] !== 'undefined' && filters['valuePackage']
      ? `valuePackage.contains=${filters['valuePackage']}&`
      : '';
  const optionRequest =
    filters && typeof filters['option'] !== 'undefined' && filters['option'] ? `option.contains=${filters['option']}&` : '';
  const saleValueRequest =
    filters && typeof filters['saleValue'] !== 'undefined' && filters['saleValue'] ? `saleValue.contains=${filters['saleValue']}&` : '';
  const observationRequest =
    filters && typeof filters['observation'] !== 'undefined' && filters['observation']
      ? `observation.contains=${filters['observation']}&`
      : '';
  const rentalValueRequest =
    filters && typeof filters['rentalValue'] !== 'undefined' && filters['rentalValue']
      ? `rentalValue.contains=${filters['rentalValue']}&`
      : '';
  const clientRequest =
    filters && typeof filters['clientId'] !== 'undefined' && filters['clientId'] ? `client.id.in=${filters['clientId']}&` : '';
  const clComRulerRequest =
    filters && typeof filters['clComRulerId'] !== 'undefined' && filters['clComRulerId']
      ? `clComRuler.id.in=${filters['clComRulerId']}&`
      : '';
  const clComItemRequest =
    filters && typeof filters['clComItemId'] !== 'undefined' && filters['clComItemId'] ? `clComItem.id.in=${filters['clComItemId']}&` : '';

  const requestUrl = `${apiUrl}${`?page=${0}&size=${1000000}&sort=${sort ? sort : 'id,asc'}&`}`;
  return {
    type: ACTION_TYPES.FETCH_CLCOMTABLE_LIST,
    payload: axios.get<IClComTable>(
      `${requestUrl}${idsRequest}${nameRequest}${statusRequest}${typeRequest}${valuePackageRequest}${optionRequest}${saleValueRequest}${observationRequest}${rentalValueRequest}${clientRequest}${clComRulerRequest}${clComItemRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntities: ICrudGetAllActionClComTable<IClComTable> = (
  name,
  status,
  type,
  valuePackage,
  option,
  saleValue,
  observation,
  rentalValue,
  clientId,
  clComRulerId,
  clComItemId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const nameRequest = name ? `name.contains=${name}&` : '';
  const statusRequest = status ? (Array.isArray(status) ? `status.in=${status.join(',')}&` : `status.equals=${status}&`) : '';
  const typeRequest = type ? (Array.isArray(status) ? `type.in=${type.join(',')}&` : `type.equals=${type}&`) : '';
  const valuePackageRequest = valuePackage ? `valuePackage.contains=${valuePackage}&` : '';
  const optionRequest = option ? (Array.isArray(status) ? `option.in=${option.join(',')}&` : `option.equals=${option}&`) : '';
  const saleValueRequest = saleValue ? `saleValue.contains=${saleValue}&` : '';
  const observationRequest = observation ? `observation.contains=${observation}&` : '';
  const rentalValueRequest = rentalValue ? `rentalValue.contains=${rentalValue}&` : '';
  const clientRequest = clientId ? `client.id.in=${clientId}&` : '';
  const clComRulerRequest = clComRulerId ? `clComRuler.id.in=${clComRulerId}&` : '';
  const clComItemRequest = clComItemId ? `clComItem.id.in=${clComItemId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_CLCOMTABLE_LIST,
    payload: axios.get<IClComTable>(
      `${requestUrl}${extraFiltersRequest}${nameRequest}${statusRequest}${typeRequest}${valuePackageRequest}${optionRequest}${saleValueRequest}${observationRequest}${rentalValueRequest}${clientRequest}${clComRulerRequest}${clComItemRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntityOffset: any = (
  name,
  status,
  type,
  valuePackage,
  option,
  saleValue,
  observation,
  rentalValue,
  clientId,
  clComRulerId,
  clComItemId,
  extraFilters,
  offset,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const nameRequest = name ? `name.contains=${name}&` : '';
  const statusRequest = status ? (Array.isArray(status) ? `status.in=${status.join(',')}&` : `status.equals=${status}&`) : '';
  const typeRequest = type ? (Array.isArray(status) ? `type.in=${type.join(',')}&` : `type.equals=${type}&`) : '';
  const valuePackageRequest = valuePackage ? `valuePackage.contains=${valuePackage}&` : '';
  const optionRequest = option ? (Array.isArray(status) ? `option.in=${option.join(',')}&` : `option.equals=${option}&`) : '';
  const saleValueRequest = saleValue ? `saleValue.contains=${saleValue}&` : '';
  const observationRequest = observation ? `observation.contains=${observation}&` : '';
  const rentalValueRequest = rentalValue ? `rentalValue.contains=${rentalValue}&` : '';
  const clientRequest = clientId ? `client.id.in=${clientId}&` : '';
  const clComRulerRequest = clComRulerId ? `clComRuler.id.in=${clComRulerId}&` : '';
  const clComItemRequest = clComItemId ? `clComItem.id.in=${clComItemId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${offset}&size=${1}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_CLCOMTABLE_OFFSET,
    payload: axios.get<IClComTable>(
      `${requestUrl}${extraFiltersRequest}${nameRequest}${statusRequest}${typeRequest}${valuePackageRequest}${optionRequest}${saleValueRequest}${observationRequest}${rentalValueRequest}${clientRequest}${clComRulerRequest}${clComItemRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntity: ICrudGetAction<IClComTable> = (id, selectFields = '') => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_CLCOMTABLE,
    payload: axios.get<IClComTable>(requestUrl, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntitiesExport: ICrudGetAllActionClComTable<IClComTable> = (
  name,
  status,
  type,
  valuePackage,
  option,
  saleValue,
  observation,
  rentalValue,
  clientId,
  clComRulerId,
  clComItemId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const nameRequest = name ? (Array.isArray(status) ? `name.in=${name.join(',')}&` : `name.contains=${name}&`) : '';
  const statusRequest = status ? (Array.isArray(status) ? `status.in=${status.join(',')}&` : `status.equals=${status}&`) : '';
  const typeRequest = type ? (Array.isArray(status) ? `type.in=${type.join(',')}&` : `type.equals=${type}&`) : '';
  const valuePackageRequest = valuePackage
    ? Array.isArray(status)
      ? `valuePackage.in=${valuePackage.join(',')}&`
      : `valuePackage.contains=${valuePackage}&`
    : '';
  const optionRequest = option ? (Array.isArray(status) ? `option.in=${option.join(',')}&` : `option.equals=${option}&`) : '';
  const saleValueRequest = saleValue
    ? Array.isArray(status)
      ? `saleValue.in=${saleValue.join(',')}&`
      : `saleValue.contains=${saleValue}&`
    : '';
  const observationRequest = observation
    ? Array.isArray(status)
      ? `observation.in=${observation.join(',')}&`
      : `observation.contains=${observation}&`
    : '';
  const rentalValueRequest = rentalValue
    ? Array.isArray(status)
      ? `rentalValue.in=${rentalValue.join(',')}&`
      : `rentalValue.contains=${rentalValue}&`
    : '';
  const clientRequest = clientId ? `client.id.in=${clientId}&` : '';
  const clComRulerRequest = clComRulerId ? `clComRuler.id.in=${clComRulerId}&` : '';
  const clComItemRequest = clComItemId ? `clComItem.id.in=${clComItemId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_CLCOMTABLE_LIST,
    payload: axios.get<IClComTable>(
      `${requestUrl}${extraFiltersRequest}${nameRequest}${statusRequest}${typeRequest}${valuePackageRequest}${optionRequest}${saleValueRequest}${observationRequest}${rentalValueRequest}${clientRequest}${clComRulerRequest}${clComItemRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const createEntity: ICrudPutAction<IClComTable> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.CREATE_CLCOMTABLE_AND_GO_LIST
        : ACTION_TYPES.CREATE_CLCOMTABLE,
    payload: axios.post(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const updateEntity: ICrudPutAction<IClComTable> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.UPDATE_CLCOMTABLE_AND_GO_LIST
        : ACTION_TYPES.UPDATE_CLCOMTABLE,
    payload: axios.put(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const deleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_CLCOMTABLE,
    payload: axios.delete(requestUrl),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const setBlob = (name, data, contentType?, fileName?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType,
    fileName,
  },
});

export const showModal = view => ({
  payload: { type: view, show: true },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const hideModal = view => ({
  payload: { type: view, show: false },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const getUrlBack = (location): string => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  let urlBack = _urlBase.searchParams.get('urlBack') || '';
  urlBack = urlBack.trim();
  urlBack = urlBack.startsWith('/') ? urlBack.slice(1) : urlBack;
  urlBack = urlBack.endsWith('/') ? urlBack.slice(0, -1) : urlBack;
  return urlBack ? '/' + urlBack + '/' : '';
};

export const getClComTableState = (location): IClComTableBaseState => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  const extraFilters = jsonParse(_urlBase.searchParams.get('extraFilters') || '{}');
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;

  const name = _urlBase.searchParams.get('name') || '';
  const status = _urlBase.searchParams.get('status') || '';
  const type = _urlBase.searchParams.get('type') || '';
  const valuePackage = _urlBase.searchParams.get('valuePackage') || '';
  const option = _urlBase.searchParams.get('option') || '';
  const saleValue = _urlBase.searchParams.get('saleValue') || '';
  const observation = _urlBase.searchParams.get('observation') || '';
  const rentalValue = _urlBase.searchParams.get('rentalValue') || '';
  const clientId = _urlBase.searchParams.get('client') || '';
  const clComRulerId = _urlBase.searchParams.get('clComRuler') || '';
  const clComItemId = _urlBase.searchParams.get('clComItem') || '';
  return {
    baseFilters,
    extraFilters,
    offset,
    name,
    status,
    type,
    valuePackage,
    option,
    saleValue,
    observation,
    rentalValue,
    clientId: clientId ? clientId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
    clComRulerId: clComRulerId ? clComRulerId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
    clComItemId: clComItemId ? clComItemId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
  };
};

export const getEntityFiltersURL = (state, offset = null) => {
  return (
    'baseFilters=' +
    state.baseFilters +
    '&extraFilters=' +
    encodeURI(JSON.stringify(state.extraFilters)) +
    '&page=' +
    state.activePage +
    '&' +
    'size=' +
    state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    state.sort +
    ',' +
    state.order +
    '&' +
    (state.name ? 'name=' + state.name + '&' : '') +
    (state.status ? 'status=' + state.status + '&' : '') +
    (state.type ? 'type=' + state.type + '&' : '') +
    (state.valuePackage ? 'valuePackage=' + state.valuePackage + '&' : '') +
    (state.option ? 'option=' + state.option + '&' : '') +
    (state.saleValue ? 'saleValue=' + state.saleValue + '&' : '') +
    (state.observation ? 'observation=' + state.observation + '&' : '') +
    (state.rentalValue ? 'rentalValue=' + state.rentalValue + '&' : '') +
    (state.clientId ? 'client=' + state.clientId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.clComRulerId ? 'clComRuler=' + state.clComRulerId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.clComItemId ? 'clComItem=' + state.clComItemId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    ''
  );
};

export const clComRulerReset: any = values => ({
  payload: values,
  type: 'clComTable/CLCOMRULER_RESET',
});

export const clComRulerGetEntities: any = values => ({
  payload: values,
  type: 'clComTable/CLCOMRULER_GET_ENTITIES',
});

export const clComRulerSetEntities: any = values => async dispatch => {
  const result = await dispatch({
    payload: values,
    type: 'clComTable/CLCOMRULER_SET_ENTITIES',
  });
  dispatch(clComRulerGetEntities());
  return result;
};

export const clComRulerCreateEntity: ICrudPutAction<IClComRuler> = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: { id: (new Date().getTime() * Math.random()).toString(16).split('.').join('-'), ...entity },
    type: 'clComTable/CLCOMRULER_CREATE_ENTITY',
  });

  if (entity.clComTable === 'is_new') {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(clComRulerReset());
      dispatch(clComRulerGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};

export const clComRulerUpdateEntity: ICrudPutAction<IClComRuler> = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: entity,
    type: 'clComTable/CLCOMRULER_UPDATE_ENTITY',
  });
  if (entity.clComTable === 'is_new') {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(clComRulerReset());
      dispatch(clComRulerGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};
export const clComRulerGetEntity: any = values => ({
  payload: values,
  type: 'clComTable/CLCOMRULER_GET_ENTITY',
});

export const clComRulerDeleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: id,
    type: 'clComTable/CLCOMRULER_DELETE_ENTITY',
  });
  if (isNaN(id)) {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(clComRulerReset());
      dispatch(clComRulerGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};

export const clComItemReset: any = values => ({
  payload: values,
  type: 'clComTable/CLCOMITEM_RESET',
});

export const clComItemGetEntities: any = values => ({
  payload: values,
  type: 'clComTable/CLCOMITEM_GET_ENTITIES',
});

export const clComItemSetEntities: any = values => async dispatch => {
  const result = await dispatch({
    payload: values,
    type: 'clComTable/CLCOMITEM_SET_ENTITIES',
  });
  dispatch(clComItemGetEntities());
  return result;
};

export const clComItemCreateEntity: ICrudPutAction<IClComItem> = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: { id: (new Date().getTime() * Math.random()).toString(16).split('.').join('-'), ...entity },
    type: 'clComTable/CLCOMITEM_CREATE_ENTITY',
  });

  if (entity.clComTable === 'is_new') {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(clComItemReset());
      dispatch(clComItemGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};

export const clComItemUpdateEntity: ICrudPutAction<IClComItem> = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: entity,
    type: 'clComTable/CLCOMITEM_UPDATE_ENTITY',
  });
  if (entity.clComTable === 'is_new') {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(clComItemReset());
      dispatch(clComItemGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};
export const clComItemGetEntity: any = values => ({
  payload: values,
  type: 'clComTable/CLCOMITEM_GET_ENTITY',
});

export const clComItemDeleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    payload: id,
    type: 'clComTable/CLCOMITEM_DELETE_ENTITY',
  });
  if (isNaN(id)) {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      await dispatch(clComItemReset());
      dispatch(clComItemGetEntities(...listFiltersPage));
    }
  } else {
    if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
      const idEntityPai = actualState.entity.id;
      await dispatch(reset());
      await dispatch(getEntity(idEntityPai));
    }
  }
  return result;
};
