import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { IPatientCid } from 'app/shared/model/patient-cid.model';
import { Status } from 'app/shared/model/enumerations/status.model';

export interface ICidStatusHistory {
  id?: number;
  status?: Status;
  reason?: string;
  patientCid?: IPatientCid;
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  patientCid: {
    isMulti: true, // many-to-one - other-side
    entity: 'PatientCid',
    urlParameters: {
      enpoint: 'patient-cids',
      filters: 'cidStatusHistory.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'cidStatusHistory.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'cidStatusHistory.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<ICidStatusHistory> = {};
