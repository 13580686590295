import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import PermissionProfile from './extended/permission-profile';

import PermissionProfileDetail from './permission-profile-detail';

import PermissionProfileUpdate from './permission-profile';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={PermissionProfileUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={PermissionProfileUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={PermissionProfileDetail} />
      <ErrorBoundaryRoute path={match.path} component={PermissionProfile} />
    </Switch>
  </>
);

export default Routes;
