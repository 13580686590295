import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';
import moment from 'moment';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity, ISupplierTableBaseState, getUrlBack, getSupplierTableState, getEntityFiltersURL } from './supplier-table.reducer';

import { APP_DATE_FORMAT } from 'app/config/constants';

export interface ISupplierTableState {
  fieldsBase: ISupplierTableBaseState;
}

export interface ISupplierTableDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class SupplierTableDetail extends React.Component<ISupplierTableDetailProps, ISupplierTableState> {
  constructor(props: Readonly<ISupplierTableDetailProps>) {
    super(props);
    this.state = {
      ...this.state,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getSupplierTableState(this.props.location),
        urlBack: getUrlBack(this.props.location),
        supplierId: [{ value: this.props.match.params['idSupplier'], label: this.props.match.params['idSupplier'] }],
        baseFilters: 'supplier',
      },
    };
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = '_back=1' + (offset !== null ? '&offset=' + offset : '');
    Object.keys(fieldsBase).map(key => {
      url += '&' + key + '=' + fieldsBase[key];
    });
    return url;
  };

  UNSAFE_componentWillMount() {
    this.props.getEntity(this.props.match.params['id']);
  }

  renderHeader() {
    const { supplierTableEntity } = this.props;
    const state = this.state;
    return (
      <>
        <div id="page-heading">
          {' '}
          <h2>
            <Translate contentKey="generadorApp.supplierTable.detail.title">SupplierTable</Translate>
            <small>&nbsp; {supplierTableEntity['name']}</small>
          </h2>
        </div>

        <div className="pull-right">
          <Button
            tag={Link}
            to={'/supplier/' + this.props.match.params['idSupplier'] + '/supplier-table?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
          >
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.supplierTable.btnBack">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/supplier/${this.props.match.params['idSupplier']}/supplier-table/${supplierTableEntity.id}/edit?+${getEntityFiltersURL(
              this.state.fieldsBase
            )}`}
            replace
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.supplierTable.btnEdit">Edit</Translate>
            </span>
          </Button>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>

          <li className="breadcrumb-item">
            <Link to={'/supplier'}>Suppliers</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/supplier/' + this.props.match.params['idSupplier']}>{this.props.match.params['idSupplier']}</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/supplier/' + (this.state.fieldsBase.supplierId ? this.state.fieldsBase.supplierId.label : '') + '/supplier-table'}>
              Supplier Commercial Tables
            </Link>
          </li>
          <li className="breadcrumb-item active">Supplier Commercial Tables details</li>
        </ol>
      </>
    );
  }

  renderBody() {
    const { supplierTableEntity } = this.props;
    const state = this.state;
    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            <Row className="jh-entity-details">
              <Col className="view-detail-row supplier-table-name-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top supplier-table-name-detail-label" md="12">
                    <dt>
                      <span id="name">
                        <Translate contentKey="generadorApp.supplierTable.name">Name</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top supplier-table-name-detail-value" md="12">
                    <dd>{supplierTableEntity.name}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row supplier-table-status-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top supplier-table-status-detail-label" md="12">
                    <dt>
                      <span id="status">
                        <Translate contentKey="generadorApp.supplierTable.status">Status</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top supplier-table-status-detail-value" md="12">
                    <dd>{translate('generadorApp.Status.' + (supplierTableEntity.status ? supplierTableEntity.status : 'INATIVADO'))}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row supplier-table-observation-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top supplier-table-observation-detail-label" md="12">
                    <dt>
                      <span id="observation">
                        <Translate contentKey="generadorApp.supplierTable.observation">Observation</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top supplier-table-observation-detail-value" md="12">
                    <dd>{supplierTableEntity.observation}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row supplier-table-createdDate-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top supplier-table-createdDate-detail-label" md="12">
                    <dt>
                      <span id="createdDate">
                        <Translate contentKey="generadorApp.supplierTable.createdDate">Created Date</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top supplier-table-createdDate-detail-value" md="12">
                    <dd>{supplierTableEntity.createdDate ? moment(supplierTableEntity.createdDate).format(APP_DATE_FORMAT) : ''}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row supplier-table-lastModifiedDate-detail" md="12">
                <Row>
                  <Col className="view-detail-label-left supplier-table-lastModifiedDate-detail-label" md="3">
                    <dt>
                      <span id="lastModifiedDate">
                        <Translate contentKey="generadorApp.supplierTable.lastModifiedDate">Last Modified Date</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left supplier-table-lastModifiedDate-detail-value" md="9">
                    <dd>
                      {supplierTableEntity.lastModifiedDate ? moment(supplierTableEntity.lastModifiedDate).format(APP_DATE_FORMAT) : ''}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row supplier-table-supplierTableProductService-detail" md="12">
                <Row>
                  <Col className="view-detail-label-top supplier-table-supplierTableProductService-detail-label" md="12">
                    <dt>
                      <Translate contentKey="generadorApp.supplierTable.supplierTableProductService">
                        Supplier Table Product Service
                      </Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top supplier-table-supplierTableProductService-detail-value" md="12">
                    <dd>{supplierTableEntity.supplierTableProductService ? supplierTableEntity.supplierTableProductService.id : ''}</dd>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { supplierTableEntity } = this.props;
    const state = this.state;
    return (
      <div>
        <Panel className="supplier-table supplier-table-detail">
          <PanelHeader>{this.renderHeader()}</PanelHeader>

          <PanelBody>{this.renderBody()}</PanelBody>
        </Panel>
      </div>
    );
  }
}

export const mapStateToProps = ({ supplierTable, ...storeState }: IRootState) => ({
  supplierTableEntity: supplierTable.entity,
});

export const mapDispatchToProps = {
  getEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SupplierTableDetail);
