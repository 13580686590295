/* eslint complexity: ["error", 500] */
/* eslint no-empty: off */
import React from 'react';
import { connect } from 'react-redux';

import SelectAsync from 'react-select/async';
import axios from 'axios';
import { Link, RouteComponentProps } from 'react-router-dom';

import { getFilterFromSelect, getFormFromSelect, getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Col, Label } from 'reactstrap';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import {
  IPatientDoctorPrescriptionUpdateState as IBaseUpdateState,
  getEntity,
  getPatientDoctorPrescriptionState,
  getUrlBack,
  updateEntity,
  createEntity,
  getEntityFiltersURL,
  getEntityOffset,
  reset,
  patDrPrescrProcdSetEntities,
  patDrPrescrProcdGetEntities,
  patDrPrescrProcdUpdateEntity,
  patDrPrescrProcdReset,
  patDrPrescrProcdGetEntity,
  patDrPrescrProcdCreateEntity,
  patDrPrescrProcdDeleteEntity,
} from './patient-doctor-prescription.reducer';

import {
  mapStateToPropsExtended as mapStateToPropsPatDrPrescrProcd,
  mapDispatchToPropsExtended as mapDispatchToPropsPatDrPrescrProcd,
  PatDrPrescrProcdExtended as PatDrPrescrProcd,
} from 'app/entities/pat-dr-prescr-procd/extended/pat-dr-prescr-procd';

import { StatePropsExtended, DispatchPropsExtended, IUpdateExtendedState } from './extended/patient-doctor-prescription-update';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

registerLocale('pt', pt);

export interface IPatientDoctorPrescriptionUpdateProps
  extends StateProps,
    DispatchProps,
    StatePropsExtended,
    DispatchPropsExtended,
    RouteComponentProps<{ url: string }> {}
export interface IPatientDoctorPrescriptionUpdateState extends IBaseUpdateState, IUpdateExtendedState {}

export class PatientDoctorPrescriptionUpdate extends React.Component<
  IPatientDoctorPrescriptionUpdateProps,
  IPatientDoctorPrescriptionUpdateState
> {
  observationFileInput: any;
  constructor(props: Readonly<IPatientDoctorPrescriptionUpdateProps>) {
    super(props);

    this.patDrPrescrProcdUpdateEntity = this.patDrPrescrProcdUpdateEntity.bind(this);
    this.patDrPrescrProcdGetEntities = this.patDrPrescrProcdGetEntities.bind(this);
    this.patDrPrescrProcdReset = this.patDrPrescrProcdReset.bind(this);
    this.patDrPrescrProcdGetEntity = this.patDrPrescrProcdGetEntity.bind(this);
    this.patDrPrescrProcdCreateEntity = this.patDrPrescrProcdCreateEntity.bind(this);
    this.patDrPrescrProcdDeleteEntity = this.patDrPrescrProcdDeleteEntity.bind(this);

    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      functionEmbebed: [],

      patDrPrescrProcdSelectValue: null,

      patientSelectValue: { value: this.props.match.params['idPatient'], label: this.props.match.params['idPatient'] },

      observationSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getPatientDoctorPrescriptionState(this.props.location),
        urlBack: getUrlBack(this.props.location),
        patientId: [{ value: this.props.match.params['idPatient'], label: this.props.match.params['idPatient'] }],
        baseFilters: 'patient',
      },
      patient: null,

      isNew: false,
    };

    axios
      .get('/api/patients/' + this.props.match.params['idPatient'], {
        headers: {
          'Select-Fields': 'name',
        },
      })
      .then(result => {
        this.setState({
          patientSelectValue: {
            ...result['data'],
            value: this.props.match.params['idPatient'],
            label: showFieldsSelectAsync(result['data'], 'name'),
          },
        });
      });
  }

  patDrPrescrProcdUpdateEntity(...values) {
    values[0]['patientDoctorPrescription'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['updateEntityPatDrPrescrProcd'](...values);
    }
    return this.props.patDrPrescrProcdUpdateEntity(...values);
  }
  patDrPrescrProcdGetEntities(...values) {
    return this.props.patDrPrescrProcdGetEntities(...values);
  }
  patDrPrescrProcdReset(...values) {
    return this.props.patDrPrescrProcdReset(...values);
  }
  patDrPrescrProcdGetEntity(...values) {
    return this.props.patDrPrescrProcdGetEntity(...values);
  }
  patDrPrescrProcdCreateEntity(...values) {
    values[0]['patientDoctorPrescription'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['createEntityPatDrPrescrProcd'](...values);
    }
    return this.props.patDrPrescrProcdCreateEntity(...values);
  }
  patDrPrescrProcdDeleteEntity(...values) {
    if (!this.state.isNew) {
      this.props['deleteEntityPatDrPrescrProcd'](...values);
    }
    return this.props.patDrPrescrProcdDeleteEntity(...values);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateSuccess !== this.props.updateSuccess && prevProps.updateSuccess) {
      this.handleClose();
    }

    if (
      prevProps.patientDoctorPrescriptionEntity &&
      prevProps.patientDoctorPrescriptionEntity.id !== this.props.patientDoctorPrescriptionEntity.id &&
      this.props.patientDoctorPrescriptionEntity.id > 0
    ) {
      if (this.props.patientDoctorPrescriptionEntity.id > 0) {
        this.setState({ isNew: false });
      } else {
        this.setState({ isNew: true });
      }
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.patientDoctorPrescriptionEntity &&
        prevProps.patientDoctorPrescriptionEntity.id !== this.props.patientDoctorPrescriptionEntity.id) ||
      (this.props.patDrPrescrProcds.length > 0 &&
        this.state.patDrPrescrProcdSelectValue === null &&
        this.props.patientDoctorPrescriptionEntity.patDrPrescrProcd)
    ) {
      this.setState({ patDrPrescrProcdSelectValue: this.props.patientDoctorPrescriptionEntity.patDrPrescrProcd });
      this.props.patDrPrescrProcdSetEntities(this.props.patientDoctorPrescriptionEntity.patDrPrescrProcd);
    }

    // data-type-rel -> tipo-4 one-to-one other-side
    if (
      (prevProps.patientDoctorPrescriptionEntity &&
        prevProps.patientDoctorPrescriptionEntity.id !== this.props.patientDoctorPrescriptionEntity.id) ||
      (this.props.patients &&
        this.state.patientSelectValue === null &&
        this.props.patientDoctorPrescriptionEntity.patient &&
        this.props.patientDoctorPrescriptionEntity.patient.id)
    ) {
      const p =
        this.props.patientDoctorPrescriptionEntity && this.props.patientDoctorPrescriptionEntity.patient
          ? this.props.patientDoctorPrescriptionEntity.patient
          : null;
      this.setState({
        patientSelectValue: p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : null,
      });
    }

    if (
      (prevProps.patientDoctorPrescriptionEntity &&
        prevProps.patientDoctorPrescriptionEntity.id !== this.props.patientDoctorPrescriptionEntity.id) ||
      (this.props.patientDoctorPrescriptionEntity.observation &&
        this.state.observationSelectValue === null &&
        this.props.patientDoctorPrescriptionEntity.observation !== this.state.observationSelectValue)
    ) {
      this.setState({
        observationSelectValue: this.props.patientDoctorPrescriptionEntity.observation
          ? this.props.patientDoctorPrescriptionEntity.observation
          : null,
      });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  UNSAFE_componentWillMount() {
    this.props.getEntity(parseInt(this.props.match.params['idPatient'], 10));
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = offset !== null ? '&offset=' + offset : '';
    Object.keys(fieldsBase).map(key => {
      url += key !== 'offset' ? '&' + key + '=' + fieldsBase[key] : '';
    });
    return url;
  };
  saveEntity = (event: any, errors: any, values: any) => {
    const selectFieldsList = ['observation', 'patient.'];

    errors = errors.filter(v => v !== 'id');

    if (errors.length === 0) {
      const { patientDoctorPrescriptionEntity } = this.props;
      const _entity = {
        ...patientDoctorPrescriptionEntity,
        // ...values,
        id: parseInt(this.props.match.params['idPatient'], 10),
        patient: getFormFromSelect(this.state.patientSelectValue, 'many-to-one'), // many-to-one - otherSide

        patDrPrescrProcd: undefined,

        observation: this.state.observationSelectValue,
      };

      const entity = _entity;

      const { observation, patientId, extraFilters, activePage, itemsPerPage, sort, order } = this.state.fieldsBase;

      const patDrPrescrProcdListAllEmbed = this.props.patDrPrescrProcdListAllEmbed;

      if (this.state.isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              observation,
              getFilterFromSelect(patientId, 'one-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
          if (idEntity) {
            patDrPrescrProcdListAllEmbed.map(v => {
              v['id'] = null;
              v['patientDoctorPrescription'] = { id: idEntity };
              this.props['createEntityPatDrPrescrProcd'](v);
            });
          }
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              observation,
              getFilterFromSelect(patientId, 'one-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
    }
  };

  handleClose = () => {
    this.props.history.push(
      '/patient/' + this.props.match.params['idPatient'] + '/patient-doctor-prescription?' + getEntityFiltersURL(this.state.fieldsBase)
    );
  };

  getFullEntityFromState(v) {
    return {
      id: v.id,
      observation: this.state.observationSelectValue,
      patDrPrescrProcd: this.state.patDrPrescrProcdSelectValue,
      patient: this.state.patientSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    const patDrPrescrProcdEntity = v.patDrPrescrProcd;

    const patientEntity = v.patient;

    return {
      observationSelectValue: v.observation,
      patDrPrescrProcdSelectValue: patDrPrescrProcdEntity ? patDrPrescrProcdEntity['id'] : null,
      patientSelectValue: patientEntity ? patientEntity['id'] : null,
    };
  }

  renderHeader() {
    const { patientDoctorPrescriptionEntity, patDrPrescrProcds, patients, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.patientDoctorPrescription.home.createLabel">Create a PatientDoctorPrescription</Translate>
            ) : (
              <Translate contentKey="generadorApp.patientDoctorPrescription.home.editLabel">Edit a PatientDoctorPrescription</Translate>
            )}
          </span>

          <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.patientDoctorPrescription.btnSave">Save</Translate>
          </Button>

          {!this.state.isNew ? (
            <>
              <Button
                color="primary"
                id="next-entity"
                disabled={updating}
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.observation,
                    this.state.fieldsBase.patientId,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 + 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 + 1 },
                    });
                    this.props.history.push(
                      '/patient-doctor-prescription/' +
                        myresult.value.data[0]['id'] +
                        '/edit?' +
                        this.getFiltersURL(this.state.fieldsBase.offset * 1 + 1)
                    );
                  } else {
                    toast.error(
                      translate('generadorApp.last.item', { fieldName: translate('generadorApp.patientDoctorPrescription.detail.title') })
                    );
                  }
                }}
                className="float-right jh-create-entity"
              >
                {'>>'}
              </Button>

              <Button
                color="primary"
                id="prev-entity"
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.observation,
                    this.state.fieldsBase.patientId,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 - 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 - 1 },
                    });
                    this.props.history.push(
                      '/patient-doctor-prescription/' +
                        myresult.value.data[0]['id'] +
                        '/edit?' +
                        this.getFiltersURL(this.state.fieldsBase.offset * 1 - 1)
                    );
                  } else {
                    toast.error(
                      translate('generadorApp.first.item', { fieldName: translate('generadorApp.patientDoctorPrescription.detail.title') })
                    );
                  }
                }}
                disabled={updating}
                className="float-right jh-create-entity"
              >
                {'<<'}
              </Button>
            </>
          ) : (
            <> </>
          )}

          <Button
            tag={Link}
            id="cancel-save"
            to={
              '/patient/' +
              this.props.match.params['idPatient'] +
              '/patient-doctor-prescription?' +
              getEntityFiltersURL(this.state.fieldsBase)
            }
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.patientDoctorPrescription.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/patient'}>
              <Translate contentKey="generadorApp.patient.home.title">Patients</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/patient/' + this.props.match.params['idPatient']}>
              {this.state.patientSelectValue ? this.state.patientSelectValue.label : this.props.match.params['idPatient']}
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/patient/' + this.props.match.params['idPatient'] + '/patient-doctor-prescription'}>
              <Translate contentKey="generadorApp.patientDoctorPrescription.home.title">Patient doctor prescriptions</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.patientDoctorPrescription.detail.title">Patient doctor prescription edit</Translate>
          </li>
        </ol>
      </>
    );
  }
  renderBody() {
    const { patientDoctorPrescriptionEntity, patDrPrescrProcds, patients, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const fullEntityEmbebed = this.getFullEntityFromState(patientDoctorPrescriptionEntity);

    const mapDispatchToPropsPatDrPrescrProcdList: any = {};
    Object.keys(mapDispatchToPropsPatDrPrescrProcd).map(v => {
      mapDispatchToPropsPatDrPrescrProcdList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'PatDrPrescrProcd')) {
          this.setState({ functionEmbebed: [...this.state.functionEmbebed, v + 'PatDrPrescrProcd'] }, this.props[v + 'PatDrPrescrProcd']);
        }
      };
    });

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="patient-doctor-prescription-id">
                  <Translate contentKey="generadorApp.patientDoctorPrescription.field_id">ID</Translate>
                </Label>
                </Col> */}
                      <Col md="12">
                        <AvInput id="patient-doctor-prescription-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'observation' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="observationLabel" for="patient-doctor-prescription-observation">
                              <Translate contentKey="generadorApp.patientDoctorPrescription.observation">Observations</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ observationSelectValue: evt.target.value })}
                              value={this.state.observationSelectValue ? this.state.observationSelectValue : ''}
                              id="patient-doctor-prescription-observation"
                              type="text"
                              name="observation"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ observationSelectValue: evt.target.value })}
                      type="hidden"
                      name="observation"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'patient' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="patient-doctor-prescription-patient">
                              <Translate contentKey="generadorApp.patientDoctorPrescription.patient">Patient</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="patient-doctor-prescription-patient"
                              name={'patient'}
                              className={'css-select-control'}
                              data-type-rel="one-to-one-other-side"
                              value={this.state.patientSelectValue ? this.state.patientSelectValue : ''}
                              onChange={options => this.setState({ patientSelectValue: options })}
                              defaultOptions={this.state.patientStartSelectOptions ? this.state.patientStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.patientStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.patientStartSelectOptions === undefined) {
                                  const result = await getListAxios('patients', {}, 0, 100, 'name,asc', 'name');
                                  this.setState({
                                    patientStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios('patients', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="patient"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}
                  {baseFilters !== 'patDrPrescrProcd' ? (
                    <Col md="12">
                      <div className="entityEmbebedList">
                        <PatDrPrescrProcd
                          entityEmbebedPai={fullEntityEmbebed}
                          history={this.props.history}
                          location={{
                            pathname: `/patient-doctor-prescription/${this.props.match.params['id']}/pat-dr-prescr-procd/`,
                            search: '',
                            hash: '',
                            state: undefined,
                            key: 'q2tbhd',
                          }}
                          match={{
                            path: `/patient-doctor-prescription/:idPatientDoctorPrescription/pat-dr-prescr-procd/:id`,
                            url: `/patient-doctor-prescription/${this.props.match.params['id']}/pat-dr-prescr-procd/`,
                            isExact: true,
                            params: {
                              idPatientDoctorPrescription: this.props.match.params['id'],
                              url: `/patient-doctor-prescription/${this.props.match.params['id']}/pat-dr-prescr-procd/`,
                            },
                          }}
                          {...this.props.mapStateToPropsPatDrPrescrProcd}
                          {...mapDispatchToPropsPatDrPrescrProcdList}
                          getEntityPai={(...v) => this.props.getEntity(...v)}
                          getEntities={this.patDrPrescrProcdGetEntities}
                          updateEntity={this.patDrPrescrProcdUpdateEntity}
                          getEntity={this.patDrPrescrProcdGetEntity}
                          createEntity={this.patDrPrescrProcdCreateEntity}
                          deleteEntity={this.patDrPrescrProcdDeleteEntity}
                          reset={this.patDrPrescrProcdReset}
                          patDrPrescrProcdList={this.props.patDrPrescrProcdListEmbed}
                          totalItems={this.props.patDrPrescrProcdListEmbed ? this.props.patDrPrescrProcdListEmbed.length : 0}
                          loading={this.props.loading}
                          patDrPrescrProcdEntity={this.props.patDrPrescrProcdSelected}
                        />
                      </div>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="patDrPrescrProcd"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}{' '}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { patientDoctorPrescriptionEntity, patDrPrescrProcds, patients, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...patientDoctorPrescriptionEntity,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel className="patient-doctor-prescription patient-doctor-prescription-update">
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToProps = (storeState: IRootState) => {
  return {
    patDrPrescrProcds: storeState.patDrPrescrProcd.entities,
    patients: storeState.patient.entities,
    patientDoctorPrescriptionEntity: storeState.patientDoctorPrescription.entity,
    loading: storeState.patientDoctorPrescription.loading,
    updating: storeState.patientDoctorPrescription.updating,
    updateSuccess: storeState.patientDoctorPrescription.updateSuccess,

    patientDoctorPrescriptions: storeState.patientDoctorPrescription.entities,
    patDrPrescrProcdListAllEmbed: storeState.patientDoctorPrescription.patDrPrescrProcdListAllEmbed,
    patDrPrescrProcdListEmbed: storeState.patientDoctorPrescription.patDrPrescrProcdListEmbed,
    patDrPrescrProcdSelected: storeState.patientDoctorPrescription.patDrPrescrProcdSelected,
    mapStateToPropsPatDrPrescrProcd: mapStateToPropsPatDrPrescrProcd(storeState),
  };
};

const mapDispatchToPropsPatDrPrescrProcdList = {};
Object.keys(mapDispatchToPropsPatDrPrescrProcd).map(v => {
  mapDispatchToPropsPatDrPrescrProcdList[v + 'PatDrPrescrProcd'] = mapDispatchToPropsPatDrPrescrProcd[v];
});

export const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  getEntityOffset,
  reset,

  ...mapDispatchToPropsPatDrPrescrProcdList,
  patDrPrescrProcdUpdateEntity,
  patDrPrescrProcdGetEntities,
  patDrPrescrProcdSetEntities,
  patDrPrescrProcdReset,
  patDrPrescrProcdGetEntity,
  patDrPrescrProcdCreateEntity,
  patDrPrescrProcdDeleteEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PatientDoctorPrescriptionUpdate);
