/* eslint complexity: ["error", 500] */
import React from 'react';

import { getFilterFromSelect, getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import { connect } from 'react-redux';

import SelectAsync from 'react-select/async';
import { Link, RouteComponentProps } from 'react-router-dom';

import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { Button, Col, Row, Table, Label, UncontrolledCollapse, CardBody } from 'reactstrap';
import { AvForm, div, AvInput } from 'availity-reactstrap-validation';
import { JhiItemCount } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { IRootState } from 'app/shared/reducers';
import { updateEntity, deleteEntity, getUrlBack, getNfeState, INfeBaseState, getEntityFiltersURL, getEntities, reset } from './nfe.reducer';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE, IPaginationBaseState, getSortState } from 'app/shared/util/pagination.constants';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';
import moment from 'moment';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

export interface INfeProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}
export type INfeStateAux = INfeBaseState;

export interface INfeState extends INfeStateAux, IPaginationBaseState {}

export class Nfe extends React.Component<INfeProps, INfeState> {
  protected myFormRef: any;

  constructor(props: INfeProps) {
    super(props);
    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      listCheckedID: [],
      isCheckedAll: false,
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      urlBack: getUrlBack(this.props.location),
      ...getNfeState(this.props.location),
    };
  }

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({ listCheckedID: this.props.nfeList.map(nfe => nfe.id) });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({ listCheckedID: this.props.nfeList.map(nfe => nfe.id) });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  removeEntitySelected() {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  }

  saveEntityMany = () => {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  };

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  deleteEntity(nfeEntity) {
    MySwal.fire({
      title: <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>,
      html: (
        <Translate contentKey="generadorApp.nfe.delete.question" interpolate={{ id: nfeEntity.id }}>
          Are you sure you want to delete this Nfe?
        </Translate>
      ),
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#e70015',
      cancelButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="ban" />
          &nbsp; <Translate contentKey="generadorApp.nfe.btnCancel">Cancel</Translate>
        </>
      ),
      confirmButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="trash" />
          &nbsp;<Translate contentKey="generadorApp.nfe.btnDelete">Delete</Translate>
        </>
      ),
    }).then(async response => {
      if (response.isConfirmed) {
        await this.props.deleteEntity(nfeEntity.id, { reloadList: false });
        this.getEntities();
        MySwal.fire({
          title: <Translate contentKey="entity.delete.title.confirmed">Confirmed delete operation</Translate>,
          icon: 'success',
        });
      }
    });
  }

  cancelFilters = () => {
    this.setState(
      {
        supplierId: '',
        number: '',
        valueNfe: '',
        buyDateStart: '',
        buyDateEnd: '',
        extraFilters: {},
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
        activePage: 0,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();

    const selectFieldsList = ['supplier.fantasyName', 'number', 'valueNfe', 'buyDate', 'createdBy.login'];

    const { supplierId, number, valueNfe, buyDateStart, buyDateEnd, extraFilters, activePage, itemsPerPage, sort, order } = this.state;
    return this.props.getEntities(
      getFilterFromSelect(supplierId, 'many-to-one'),
      number,
      valueNfe,
      buyDateStart,
      buyDateEnd,
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(',')
    );
  };

  updateEntity = entity => {
    const selectFieldsList = ['supplier.fantasyName', 'number', 'valueNfe', 'buyDate', 'createdBy.login'];

    const { supplierId, number, valueNfe, buyDateStart, buyDateEnd, extraFilters, activePage, itemsPerPage, sort, order } = this.state;
    this.props.updateEntity(entity, [
      getFilterFromSelect(supplierId, 'many-to-one'),
      number,
      valueNfe,
      buyDateStart,
      buyDateEnd,
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(','),
    ]);
  };

  renderHeader() {
    const { nfeFiles, pharmacyStocks, suppliers, nfeList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.nfe.home.title">Buys</Translate>
          </span>
          <Button id="togglerFilterNfe" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.nfe.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          &nbsp;
          {hasAnyAuthority(props.userAccount, ['nfe'], 'resgister') ? (
            <Link
              to={`${match.url}/new?${getEntityFiltersURL(state)}`}
              className="btn btn-primary float-right jh-create-entity"
              id="jh-create-entity"
            >
              <FontAwesomeIcon icon="plus" />
              &nbsp;
              <Translate contentKey="generadorApp.nfe.btnNewItem">Add buy</Translate>
            </Link>
          ) : (
            <></>
          )}{' '}
          &nbsp;
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.nfe.home.title">Buys</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    const { nfeFiles, pharmacyStocks, suppliers, nfeList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <UncontrolledCollapse toggler="#togglerFilterNfe">
          <CardBody>
            <AvForm ref={el => (this.myFormRef = el)} id="form-filter" onSubmit={this.filterEntity}>
              <div className="row mt-1 ml-3 mr-3">
                {this.state.baseFilters !== 'supplier' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="nfe-supplier">
                          <Translate contentKey="generadorApp.nfe.supplier">Supplier</Translate>
                        </Label>
                        <SelectAsync
                          id="nfe-supplier"
                          isMulti
                          className={'css-select-control'}
                          name={'supplier'}
                          cacheOptions
                          value={this.state.supplierId}
                          onChange={options => this.setState({ supplierId: options })}
                          defaultOptions={this.state.supplierStartFilter ? this.state.supplierStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.supplierStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.supplierStartFilter === undefined) {
                              const result = await getListAxios('suppliers', {}, 0, 100, 'fantasyName,asc', 'fantasyName');
                              this.setState({
                                supplierStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'fantasyName') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios(
                              'suppliers',
                              { 'fantasyName.contains': inputValue },
                              0,
                              100,
                              'fantasyName,asc',
                              'fantasyName'
                            );
                            callback(
                              result.data
                                ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'fantasyName') }))
                                : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'number' ? (
                  <Col md="3" className="col-filter-nfe-number">
                    <Row className="mr-1 mt-1">
                      <Label id="numberLabel" for="nfe-number">
                        <Translate contentKey="generadorApp.nfe.number">NF Number</Translate>
                      </Label>

                      <AvInput type="text" name="number" id="nfe-number" value={this.state.number} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'valueNfe' ? (
                  <Col md="2" className="col-filter-nfe-valueNfe">
                    <Row className="mr-1 mt-1">
                      <Label id="valueNfeLabel" for="nfe-valueNfe">
                        <Translate contentKey="generadorApp.nfe.valueNfe">Value</Translate>
                      </Label>

                      <AvInput type="text" name="valueNfe" id="nfe-valueNfe" value={this.state.valueNfe} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'buyDate' ? (
                  <Col md="2" className="col-filter-nfe-buyDate">
                    <Row className="mr-1 mt-1">
                      <Label id="buyDateStart" for="nfe-buyDate_start">
                        <Translate contentKey="generadorApp.nfe.buyDate_start">Buy Date (start)</Translate>
                      </Label>
                      <DatePicker
                        onChange={value => this.setState({ buyDateStart: value })}
                        id="buyDateStart"
                        className="form-control react-datepicker"
                        name="buyDateStart"
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.buyDateStart ? moment(this.state.buyDateStart).toDate() : null}
                        locale="pt"
                      />
                    </Row>
                  </Col>
                ) : null}
                {this.state.baseFilters !== 'buyDate' ? (
                  <Col md="2" className="col-filter-nfe-buyDate-final">
                    <Row className="mr-1 mt-1">
                      <Label id="buyDateEnd" for="nfe-buyDate_end">
                        <Translate contentKey="generadorApp.nfe.buyDate_end">Buy Date (end)</Translate>
                      </Label>
                      <DatePicker
                        onChange={value => this.setState({ buyDateEnd: value })}
                        id="buyDateEnd"
                        className="form-control react-datepicker"
                        name="buyDateEnd"
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.buyDateEnd ? moment(this.state.buyDateEnd).toDate() : null}
                        locale="pt"
                      />
                    </Row>
                  </Col>
                ) : null}
              </div>

              <div className="row mb-2 mr-4 justify-content-end">
                <Button color="primary" type="submit">
                  <i className="fa fa-filter" aria-hidden={'true'}></i>&nbsp;
                  <Translate contentKey="generadorApp.nfe.btnFilter">Filter</Translate>
                </Button>
                &nbsp;
                <Button color="secondary" onClick={this.cancelFilters}>
                  <FontAwesomeIcon icon="trash-alt" />
                  &nbsp;
                  <Translate contentKey="generadorApp.nfe.btnCleanFilter">Clean Filter</Translate>
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </UncontrolledCollapse>
      </>
    );
  }

  renderTable() {
    const { nfeFiles, pharmacyStocks, suppliers, nfeList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {nfeList && nfeList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null).length > 0 ? (
          <>
            <Table
              id="nfe-table-list"
              responsive
              aria-describedby="nfe-heading"
              className={'table-hover table-striped mt-4 table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.nfe.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'supplier' ? (
                    <th>
                      <Translate contentKey="generadorApp.nfe.supplier">Supplier</Translate>
                    </th>
                  ) : null}
                  {state.baseFilters !== 'number' ? (
                    <th className="hand" onClick={sortFunction('number')}>
                      <Translate contentKey="generadorApp.nfe.number">NF Number</Translate>
                      <FontAwesomeIcon icon={state.sort === 'number' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'valueNfe' ? (
                    <th className="hand" onClick={sortFunction('valueNfe')}>
                      <Translate contentKey="generadorApp.nfe.valueNfe">Value</Translate>
                      <FontAwesomeIcon icon={state.sort === 'valueNfe' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'buyDate' ? (
                    <th className="hand" onClick={sortFunction('buyDate')}>
                      <Translate contentKey="generadorApp.nfe.buyDate">Buy Date</Translate>
                      <FontAwesomeIcon icon={state.sort === 'buyDate' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'createdBy' ? (
                    <th>
                      <Translate contentKey="generadorApp.nfe.createdBy">Created By</Translate>
                    </th>
                  ) : null}

                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {nfeList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((nfe, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.nfe.field_id">ID</Translate>
                        </b>
                        {nfe.id}
                      </td>

                      {state.baseFilters !== 'supplier' ? (
                        <td id="supplier-cell">
                          <b className="visible-xs"> Supplier </b>
                          {showFieldsSelectAsync(nfe, 'supplier.fantasyName', null).map((v, ikey) => (
                            <span key={ikey}> {v} </span>
                          ))}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'number' ? (
                        <td id="number-cell">
                          <b className="visible-xs"> NF Number </b>

                          {nfe.number}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'valueNfe' ? (
                        <td id="valueNfe-cell">
                          <b className="visible-xs"> Value </b>

                          {nfe.valueNfe}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'buyDate' ? (
                        <td id="buyDate-cell">
                          <b className="visible-xs"> Buy Date </b>
                          {nfe.buyDate ? moment(nfe.buyDate).format(APP_LOCAL_DATE_FORMAT) : ''}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'createdBy' ? (
                        <td id="createdBy-cell">
                          <b className="visible-xs"> Created By </b>
                          {nfe.createdBy ? nfe.createdBy.login : ''}
                        </td>
                      ) : null}

                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          {hasAnyAuthority(props.userAccount, ['nfe'], 'view') ? (
                            <Button tag={Link} to={`${match.url}/${nfe.id}`} color="info" size="sm">
                              <FontAwesomeIcon icon="eye" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                          {hasAnyAuthority(props.userAccount, ['nfe'], 'edit') ? (
                            <Button
                              tag={Link}
                              to={`${match.url}/${nfe.id}/edit?${getEntityFiltersURL(
                                state,
                                this.state.itemsPerPage * (this.state.activePage - 1) + i
                              )}`}
                              color="primary"
                              size="sm"
                            >
                              <FontAwesomeIcon icon="pencil-alt" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                          {hasAnyAuthority(props.userAccount, ['nfe'], 'canDelete') ? (
                            <Button onClick={() => this.deleteEntity(nfe)} color="danger" size="sm">
                              <FontAwesomeIcon icon="trash" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                          <Button tag={Link} to={`/nfe/${nfe.id}/nfe-file`} color="info" size="sm">
                            <FontAwesomeIcon icon="file" />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.nfe.home.notFound">No Buys found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const { nfeFiles, pharmacyStocks, suppliers, nfeList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={nfeList && nfeList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const { nfeFiles, pharmacyStocks, suppliers, nfeList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel className="nfe nfe-list-table">
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToProps = ({ ...storeState }: IRootState) => {
  return {
    nfeFiles: storeState.nfeFile.entities,
    pharmacyStocks: storeState.pharmacyStock.entities,
    suppliers: storeState.supplier.entities,
    nfeList: storeState.nfe.entities,
    totalItems: storeState.nfe.totalItems,
    userAccount: storeState.authentication.account,
    loading: storeState.nfe.loading,
  };
};

export const mapDispatchToProps = {
  getEntities,

  deleteEntity,
  updateEntity,
  reset,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Nfe);
