import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ProfessionalAdvice from './professional-advice';

import ProfessionalAdviceDetail from './professional-advice-detail';

import ProfessionalAdviceUpdate from './professional-advice';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={ProfessionalAdviceUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={ProfessionalAdviceUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={ProfessionalAdviceDetail} />
      <ErrorBoundaryRoute path={match.path} component={ProfessionalAdvice} />
    </Switch>
  </>
);

export default Routes;
