const functionAfterSubmit = (entity, resultEntity, createEntity) => {
  if (createEntity) {
    // const categories = ['AMBULANCIA', 'EQUIPAMENTOS_RESPIRATORIOS', 'EQUIPAMENTOS_MOBILIARIOS', 'EQUIPAMENTOS_OXIGENIOTERAPIA', 'EXAMES'];
    // const idEntity = resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id'];
    // categories.map(category => {
    //   axios.post(BASE_API_VERSION_PATH + 'patient-suppliers', {
    //     category,
    //     patient: { id: idEntity },
    //     suppliers: [],
    //   });
    // });

    return entity;
  }

  return entity;
};

export default functionAfterSubmit;
