import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import {
  getEntity,
  IPatientResponsibleBaseState,
  getUrlBack,
  getPatientResponsibleState,
  getEntityFiltersURL,
} from './patient-responsible.reducer';

export interface IPatientResponsibleState {
  fieldsBase: IPatientResponsibleBaseState;
}

export interface IPatientResponsibleDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class PatientResponsibleDetail extends React.Component<IPatientResponsibleDetailProps, IPatientResponsibleState> {
  constructor(props: Readonly<IPatientResponsibleDetailProps>) {
    super(props);
    this.state = {
      ...this.state,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getPatientResponsibleState(this.props.location),
        urlBack: getUrlBack(this.props.location),
        patientId: [{ value: this.props.match.params['idPatient'], label: this.props.match.params['idPatient'] }],
        baseFilters: 'patient',
      },
    };
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = '_back=1' + (offset !== null ? '&offset=' + offset : '');
    Object.keys(fieldsBase).map(key => {
      url += '&' + key + '=' + fieldsBase[key];
    });
    return url;
  };

  UNSAFE_componentWillMount() {
    this.props.getEntity(this.props.match.params['id']);
  }

  renderHeader() {
    const { patientResponsibleEntity } = this.props;
    const state = this.state;
    return (
      <>
        <div id="page-heading">
          {' '}
          <h2>
            <Translate contentKey="generadorApp.patientResponsible.detail.title">PatientResponsible</Translate>
            <small>&nbsp; {patientResponsibleEntity['name']}</small>
          </h2>
        </div>

        <div className="pull-right">
          <Button
            tag={Link}
            to={'/patient/' + this.props.match.params['idPatient'] + '/patient-responsible?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
          >
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.patientResponsible.btnBack">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/patient/${this.props.match.params['idPatient']}/patient-responsible/${
              patientResponsibleEntity.id
            }/edit?+${getEntityFiltersURL(this.state.fieldsBase)}`}
            replace
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.patientResponsible.btnEdit">Edit</Translate>
            </span>
          </Button>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>

          <li className="breadcrumb-item">
            <Link to={'/patient'}>Patients</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/patient/' + this.props.match.params['idPatient']}>{this.props.match.params['idPatient']}</Link>
          </li>
          <li className="breadcrumb-item">
            <Link
              to={'/patient/' + (this.state.fieldsBase.patientId ? this.state.fieldsBase.patientId.label : '') + '/patient-responsible'}
            >
              Patient Responsibles
            </Link>
          </li>
          <li className="breadcrumb-item active">Patient Responsibles details</li>
        </ol>
      </>
    );
  }

  renderBody() {
    const { patientResponsibleEntity } = this.props;
    const state = this.state;
    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            <Row className="jh-entity-details">
              <Col className="view-detail-row patient-responsible-name-detail" md="12">
                <Row>
                  <Col className="view-detail-label-left patient-responsible-name-detail-label" md="3">
                    <dt>
                      <span id="name">
                        <Translate contentKey="generadorApp.patientResponsible.name">Name</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left patient-responsible-name-detail-value" md="9">
                    <dd>{patientResponsibleEntity.name}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row patient-responsible-kinshipDegree-detail" md="12">
                <Row>
                  <Col className="view-detail-label-left patient-responsible-kinshipDegree-detail-label" md="3">
                    <dt>
                      <span id="kinshipDegree">
                        <Translate contentKey="generadorApp.patientResponsible.kinshipDegree">Kinship Degree</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left patient-responsible-kinshipDegree-detail-value" md="9">
                    <dd>{patientResponsibleEntity.kinshipDegree}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row patient-responsible-contactTelephonePrincipal-detail" md="12">
                <Row>
                  <Col className="view-detail-label-left patient-responsible-contactTelephonePrincipal-detail-label" md="3">
                    <dt>
                      <span id="contactTelephonePrincipal">
                        <Translate contentKey="generadorApp.patientResponsible.contactTelephonePrincipal">
                          Contact Telephone Principal
                        </Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left patient-responsible-contactTelephonePrincipal-detail-value" md="9">
                    <dd>{patientResponsibleEntity.contactTelephonePrincipal}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row patient-responsible-contactTelephoneSecondary-detail" md="12">
                <Row>
                  <Col className="view-detail-label-left patient-responsible-contactTelephoneSecondary-detail-label" md="3">
                    <dt>
                      <span id="contactTelephoneSecondary">
                        <Translate contentKey="generadorApp.patientResponsible.contactTelephoneSecondary">
                          Contact Telephone Secondary
                        </Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left patient-responsible-contactTelephoneSecondary-detail-value" md="9">
                    <dd>{patientResponsibleEntity.contactTelephoneSecondary}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row patient-responsible-comments-detail" md="12">
                <Row>
                  <Col className="view-detail-label-left patient-responsible-comments-detail-label" md="3">
                    <dt>
                      <span id="comments">
                        <Translate contentKey="generadorApp.patientResponsible.comments">Comments</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left patient-responsible-comments-detail-value" md="9">
                    <dd>{patientResponsibleEntity.comments}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row patient-responsible-email-detail" md="12">
                <Row>
                  <Col className="view-detail-label-left patient-responsible-email-detail-label" md="3">
                    <dt>
                      <span id="email">
                        <Translate contentKey="generadorApp.patientResponsible.email">Email</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left patient-responsible-email-detail-value" md="9">
                    <dd>{patientResponsibleEntity.email}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row patient-responsible-accessApp-detail" md="12">
                <Row>
                  <Col className="view-detail-label-left patient-responsible-accessApp-detail-label" md="3">
                    <dt>
                      <span id="accessApp">
                        <Translate contentKey="generadorApp.patientResponsible.accessApp">Access App</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left patient-responsible-accessApp-detail-value" md="9">
                    <dd>
                      {translate(
                        'generadorApp.GeralSelect.' + (patientResponsibleEntity.accessApp ? patientResponsibleEntity.accessApp : 'SIM')
                      )}
                    </dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row patient-responsible-zipCode-detail" md="12">
                <Row>
                  <Col className="view-detail-label-left patient-responsible-zipCode-detail-label" md="3">
                    <dt>
                      <span id="zipCode">
                        <Translate contentKey="generadorApp.patientResponsible.zipCode">Zip Code</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left patient-responsible-zipCode-detail-value" md="9">
                    <dd>{patientResponsibleEntity.zipCode}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row patient-responsible-street-detail" md="12">
                <Row>
                  <Col className="view-detail-label-left patient-responsible-street-detail-label" md="3">
                    <dt>
                      <span id="street">
                        <Translate contentKey="generadorApp.patientResponsible.street">Street</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left patient-responsible-street-detail-value" md="9">
                    <dd>{patientResponsibleEntity.street}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row patient-responsible-complement-detail" md="12">
                <Row>
                  <Col className="view-detail-label-left patient-responsible-complement-detail-label" md="3">
                    <dt>
                      <span id="complement">
                        <Translate contentKey="generadorApp.patientResponsible.complement">Complement</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left patient-responsible-complement-detail-value" md="9">
                    <dd>{patientResponsibleEntity.complement}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row patient-responsible-number-detail" md="12">
                <Row>
                  <Col className="view-detail-label-left patient-responsible-number-detail-label" md="3">
                    <dt>
                      <span id="number">
                        <Translate contentKey="generadorApp.patientResponsible.number">Number</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left patient-responsible-number-detail-value" md="9">
                    <dd>{patientResponsibleEntity.number}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row patient-responsible-neighborhood-detail" md="12">
                <Row>
                  <Col className="view-detail-label-left patient-responsible-neighborhood-detail-label" md="3">
                    <dt>
                      <span id="neighborhood">
                        <Translate contentKey="generadorApp.patientResponsible.neighborhood">Neighborhood</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left patient-responsible-neighborhood-detail-value" md="9">
                    <dd>{patientResponsibleEntity.neighborhood}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row patient-responsible-city-detail" md="12">
                <Row>
                  <Col className="view-detail-label-left patient-responsible-city-detail-label" md="3">
                    <dt>
                      <span id="city">
                        <Translate contentKey="generadorApp.patientResponsible.city">City</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left patient-responsible-city-detail-value" md="9">
                    <dd>{patientResponsibleEntity.city}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row patient-responsible-uf-detail" md="12">
                <Row>
                  <Col className="view-detail-label-left patient-responsible-uf-detail-label" md="3">
                    <dt>
                      <span id="uf">
                        <Translate contentKey="generadorApp.patientResponsible.uf">Uf</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left patient-responsible-uf-detail-value" md="9">
                    <dd>{patientResponsibleEntity.uf}</dd>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { patientResponsibleEntity } = this.props;
    const state = this.state;
    return (
      <div>
        <Panel className="patient-responsible patient-responsible-detail">
          <PanelHeader>{this.renderHeader()}</PanelHeader>

          <PanelBody>{this.renderBody()}</PanelBody>
        </Panel>
      </div>
    );
  }
}

export const mapStateToProps = ({ patientResponsible, ...storeState }: IRootState) => ({
  patientResponsibleEntity: patientResponsible.entity,
});

export const mapDispatchToProps = {
  getEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PatientResponsibleDetail);
