import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { ICidStatusHistory } from 'app/shared/model/cid-status-history.model';
import { IPatient } from 'app/shared/model/patient.model';
import { ICid } from 'app/shared/model/cid.model';
import { CidComplexity } from 'app/shared/model/enumerations/cid-complexity.model';
import { Status } from 'app/shared/model/enumerations/status.model';

export interface IPatientCid {
  id?: number;
  cidComments?: any;
  cidPrimary?: boolean;
  cidComplexity?: CidComplexity;
  status?: Status;
  cidStatusHistory?: ICidStatusHistory[];
  patient?: IPatient;
  cid?: ICid;
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  cidStatusHistory: {
    isMulti: false, // one-to-many - other-side
    entity: 'CidStatusHistory',
    urlParameters: {
      enpoint: 'cid-status-histories',
      filters: 'patientCid.id.equals',
    },
  },
  patient: {
    isMulti: true, // many-to-one - other-side
    entity: 'Patient',
    urlParameters: {
      enpoint: 'patients',
      filters: 'patientCid.id.equals',
    },
  },
  cid: {
    isMulti: true, // many-to-one - other-side
    entity: 'Cid',
    urlParameters: {
      enpoint: 'cids',
      filters: 'patientCid.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'patientCid.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'patientCid.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<IPatientCid> = {
  cidPrimary: false,
};
