/* eslint complexity: ["error", 500] */
import React from 'react';
import axios from 'axios';

import { connect } from 'react-redux';

import {
  getFilterFromSelect,
  getFormFromSelect,
  getListAxios,
  getValueRecursive,
  showFieldsSelectAsync,
} from 'app/shared/util/entity-utils';
import Select from 'react-select';
import { toast } from 'react-toastify';
import SelectAsync from 'react-select/async';
import { Link, RouteComponentProps } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { Button, Col, Row, Table, Label, UncontrolledCollapse, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { JhiItemCount } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { IRootState } from 'app/shared/reducers';
import {
  updateEntity,
  getUrlBack,
  getPatientResponsibleState,
  IPatientResponsibleBaseState,
  getEntityFiltersURL,
  getEntities,
  IPatientResponsibleUpdateState as IBaseUpdateState,
  reset,
  getEntity,
  deleteEntity,
  createEntity,
} from './patient-responsible.reducer';

import { ITEMS_PER_PAGE, IPaginationBaseState, getSortState } from 'app/shared/util/pagination.constants';

import { viacepRequest } from 'app/shared/util/entity-utils';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

export interface IPatientResponsibleProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
  entityEmbebedPai?: any;
}

export interface IPatientResponsibleState extends IPatientResponsibleBaseState, IPaginationBaseState, IBaseUpdateState {
  showModalForm?: boolean;
  showModalEdit?: number;
  showModalEditView?: boolean;
}

export class PatientResponsible extends React.Component<IPatientResponsibleProps, IPatientResponsibleState> {
  protected myFormRef: any;
  protected myFormRefNew: any;
  protected myFormRefEdit: any;

  nameFileInput: any;

  kinshipDegreeFileInput: any;

  contactTelephonePrincipalFileInput: any;

  contactTelephoneSecondaryFileInput: any;

  commentsFileInput: any;

  emailFileInput: any;

  accessAppFileInput: any;

  zipCodeFileInput: any;

  streetFileInput: any;

  complementFileInput: any;

  numberFileInput: any;

  neighborhoodFileInput: any;

  cityFileInput: any;

  ufFileInput: any;

  constructor(props: IPatientResponsibleProps) {
    super(props);
    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      listCheckedID: [],
      isCheckedAll: false,
      showModalForm: false,
      showModalEdit: null,
      urlBack: getUrlBack(this.props.location),
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      ...getPatientResponsibleState(this.props.location),
      patientId: [
        {
          ...(this.props.entityEmbebedPai ? this.props.entityEmbebedPai : {}),
          value: this.props.match.params['idPatient'],
          label: this.props.match.params['idPatient'],
        },
      ],
      baseFilters: 'patient',
      patientSelectValue: {
        ...(this.props.entityEmbebedPai ? this.props.entityEmbebedPai : {}),
        value: this.props.match.params['idPatient'],
        label: this.props.match.params['idPatient'],
      },

      cepRequestCep: null,
      cepRequestLogradouro: null,

      cepRequestBairro: null,
      cepRequestLocalidade: null,
      cepRequestUf: null,
      nameSelectValue: null,
      kinshipDegreeSelectValue: null,
      contactTelephonePrincipalSelectValue: null,
      contactTelephoneSecondarySelectValue: null,
      commentsSelectValue: null,
      emailSelectValue: null,
      accessAppSelectValue: null,
      zipCodeSelectValue: null,
      streetSelectValue: null,
      complementSelectValue: null,
      numberSelectValue: null,
      neighborhoodSelectValue: null,
      citySelectValue: null,
      ufSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getPatientResponsibleState(this.props.location),
        patientId: [
          {
            ...(this.props.entityEmbebedPai ? this.props.entityEmbebedPai : {}),
            value: this.props.match.params['idPatient'],
            label: this.props.match.params['idPatient'],
          },
        ],
        baseFilters: 'patient',
      },
      isNew: false,
    };

    axios
      .get('/api/patients/' + this.props.match.params['idPatient'], {
        headers: {
          'Select-Fields': 'id',
        },
      })
      .then(result => {
        this.setState({
          patientSelectValue: {
            ...result['data'],
            value: this.props.match.params['idPatient'],
            label: showFieldsSelectAsync(result['data'], 'id'),
          },
        });
      });
  }

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({ listCheckedID: this.props.patientResponsibleList.map(patientResponsible => patientResponsible.id) });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({ listCheckedID: this.props.patientResponsibleList.map(patientResponsible => patientResponsible.id) });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  removeEntitySelected() {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  }

  saveEntityMany = () => {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  };

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  cancelFilters = () => {
    this.setState(
      {
        name: '',
        kinshipDegree: '',
        contactTelephonePrincipal: '',
        comments: '',
        accessApp: '',
        zipCode: '',
        city: '',
        patientId: '',
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
        activePage: 0,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();

    const selectFieldsList = ['name', 'kinshipDegree', 'contactTelephonePrincipal', 'comments', 'accessApp', 'patient.id'];
    const {
      name,
      kinshipDegree,
      contactTelephonePrincipal,
      comments,
      accessApp,
      zipCode,
      city,
      patientId,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;
    return this.props.getEntities(
      name,
      kinshipDegree,
      contactTelephonePrincipal,
      comments,
      accessApp,
      zipCode,
      city,
      getFilterFromSelect(patientId, 'many-to-one'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(',')
    );
  };

  updateEntity = entity => {
    const selectFieldsList = ['name', 'kinshipDegree', 'contactTelephonePrincipal', 'comments', 'accessApp', 'patient.id'];

    const {
      name,
      kinshipDegree,
      contactTelephonePrincipal,
      comments,
      accessApp,
      zipCode,
      city,
      patientId,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = this.state;
    this.props.updateEntity(entity, [
      name,
      kinshipDegree,
      contactTelephonePrincipal,
      comments,
      accessApp,
      zipCode,
      city,
      getFilterFromSelect(patientId, 'many-to-one'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(','),
    ]);
  };

  saveEntity = (event: any, errors: any, values: any, isNew: any) => {
    if (!this.state.nameSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.patientResponsible.name') }));
      return false;
    }

    if (!this.state.kinshipDegreeSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.patientResponsible.kinshipDegree') }));
      return false;
    }

    if (!this.state.cepRequestCep) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.patientResponsible.zipCode') }));
      return false;
    }

    if (!this.state.numberSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.patientResponsible.number') }));
      return false;
    }

    const selectFieldsList = ['name', 'kinshipDegree', 'contactTelephonePrincipal', 'comments', 'accessApp', 'patient.id'];

    if (!this.state.cepRequestCep) {
      errors.push(translate('entity.validation.required'));
    }

    if (errors.length === 0) {
      const { patientResponsibleEntity } = this.props;
      const _entity = {
        ...patientResponsibleEntity,
        // ...values,

        patient: getFormFromSelect(this.state.patientSelectValue, 'many-to-one'), // many-to-one - otherSide

        name: this.state.nameSelectValue,
        kinshipDegree: this.state.kinshipDegreeSelectValue,
        contactTelephonePrincipal: this.state.contactTelephonePrincipalSelectValue,
        contactTelephoneSecondary: this.state.contactTelephoneSecondarySelectValue,
        comments: this.state.commentsSelectValue,
        email: this.state.emailSelectValue,
        accessApp: this.state.accessAppSelectValue === null ? null : this.state.accessAppSelectValue,
        zipCode: this.state.cepRequestCep,
        street: this.state.cepRequestLogradouro,
        complement: this.state.complementSelectValue,
        number: this.state.numberSelectValue,
        neighborhood: this.state.cepRequestBairro,
        city: this.state.cepRequestLocalidade,
        uf: this.state.cepRequestUf,
      };
      const entity = _entity;

      const {
        name,
        kinshipDegree,
        contactTelephonePrincipal,
        comments,
        accessApp,
        zipCode,
        city,
        patientId,
        extraFilters,
        activePage,
        itemsPerPage,
        sort,
        order,
      } = this.state.fieldsBase;

      if (isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              name,
              kinshipDegree,
              contactTelephonePrincipal,
              comments,
              accessApp,
              zipCode,
              city,
              getFilterFromSelect(patientId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              name,
              kinshipDegree,
              contactTelephonePrincipal,
              comments,
              accessApp,
              zipCode,
              city,
              getFilterFromSelect(patientId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
      this.setState({ showModalForm: false });
      this.setState({ showModalEdit: null });
    } else {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.specialty.' + errors[0]) }));
    }
  };

  componentDidUpdate(prevProps) {}

  deleteEntity(patientResponsibleEntity) {
    MySwal.fire({
      title: <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>,
      html: (
        <Translate contentKey="generadorApp.patientResponsible.delete.question" interpolate={{ id: patientResponsibleEntity.id }}>
          Are you sure you want to delete this PatientResponsible?
        </Translate>
      ),
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#e70015',
      cancelButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="ban" />
          &nbsp; <Translate contentKey="generadorApp.patientResponsible.btnCancel">Cancel</Translate>
        </>
      ),
      confirmButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="trash" />
          &nbsp;<Translate contentKey="generadorApp.patientResponsible.btnDelete">Delete</Translate>
        </>
      ),
    }).then(async response => {
      if (response.isConfirmed) {
        await this.props.deleteEntity(patientResponsibleEntity.id, { reloadList: false });
        this.getEntities();
        MySwal.fire({
          title: <Translate contentKey="entity.delete.title.confirmed">Confirmed delete operation</Translate>,
          icon: 'success',
        });
      }
    });
  }

  renderNewModal() {
    return (
      <Modal size={'xl'} isOpen={this.state.showModalForm} toggle={() => this.setState({ showModalForm: false })}>
        <ModalHeader toggle={() => this.setState({ showModalForm: false })}>{this.renderHeaderUpdate({}, true)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefNew = el)}
          model={{}}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, true)}
        >
          <ModalBody>{this.renderBodyUpdate({}, true)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalForm: false })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.patientResponsible.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefNew && this.myFormRefNew.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.patientResponsible.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    );
  }

  getFullEntityFromState(v) {
    return {
      id: v.id,
      name: this.state.nameSelectValue,
      kinshipDegree: this.state.kinshipDegreeSelectValue,
      contactTelephonePrincipal: this.state.contactTelephonePrincipalSelectValue,
      contactTelephoneSecondary: this.state.contactTelephoneSecondarySelectValue,
      comments: this.state.commentsSelectValue,
      email: this.state.emailSelectValue,
      accessApp: this.state.accessAppSelectValue,
      zipCode: this.state.zipCodeSelectValue,
      street: this.state.streetSelectValue,
      complement: this.state.complementSelectValue,
      number: this.state.numberSelectValue,
      neighborhood: this.state.neighborhoodSelectValue,
      city: this.state.citySelectValue,
      uf: this.state.ufSelectValue,
      patient: this.state.patientSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    let p: any = null;

    p = v.patient;
    const patientEntity = p ? { ...p, value: p.id, label: p['id'] ? p.id : '' } : p;

    return {
      nameSelectValue: v.name, // name,
      kinshipDegreeSelectValue: v.kinshipDegree, // kinshipDegree,
      contactTelephonePrincipalSelectValue: v.contactTelephonePrincipal, // contactTelephonePrincipal,
      contactTelephoneSecondarySelectValue: v.contactTelephoneSecondary, // contactTelephoneSecondary,
      commentsSelectValue: v.comments, // comments,
      emailSelectValue: v.email, // email,
      accessAppSelectValue: v.accessApp, // accessApp,
      zipCodeSelectValue: v.zipCode, // zipCode,
      streetSelectValue: v.street, // street,
      complementSelectValue: v.complement, // complement,
      numberSelectValue: v.number, // number,
      neighborhoodSelectValue: v.neighborhood, // neighborhood,
      citySelectValue: v.city, // city,
      ufSelectValue: v.uf, // uf,
      patientSelectValue: patientEntity,
    };
  }

  openUpdateModal(v, edit = true) {
    this.props.getEntity(v.id);
    this.setState({
      showModalEdit: v.id,
      showModalEditView: edit,
      ...this.getFullEntitySelectValue(v),
    });
  }

  renderUpdateModal() {
    const { patientResponsibleEntity, entityEmbebedPai } = this.props;
    return patientResponsibleEntity && patientResponsibleEntity.id ? (
      <Modal
        size={'xl'}
        isOpen={this.state.showModalEdit === patientResponsibleEntity.id}
        toggle={() => this.setState({ showModalEdit: null })}
      >
        <ModalHeader toggle={() => this.setState({ showModalEdit: null })}>
          {this.renderHeaderUpdate(patientResponsibleEntity, false)}
        </ModalHeader>
        <AvForm
          ref={el => (this.myFormRefEdit = el)}
          model={{
            ...patientResponsibleEntity,
          }}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, false)}
        >
          <ModalBody>{this.renderBodyUpdate(patientResponsibleEntity, false)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalEdit: null })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.patientResponsible.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefEdit && this.myFormRefEdit.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.patientResponsible.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    ) : (
      <> </>
    );
  }

  renderHeaderUpdate(patientResponsibleEntity, isNew) {
    const { entityEmbebedPai, patients, loading } = this.props;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {isNew ? (
              <Translate contentKey="generadorApp.patientResponsible.home.createLabel">Create a PatientResponsible</Translate>
            ) : (
              <Translate contentKey="generadorApp.patientResponsible.home.editLabel">Edit a PatientResponsible</Translate>
            )}
          </span>
        </div>
      </>
    );
  }
  renderBodyUpdate(patientResponsibleEntity, isNew) {
    const { entityEmbebedPai, patients, loading } = this.props;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
              <Col md="3">
              <Label className="mt-2 label-single-line"for="patient-responsible-id">
                <Translate contentKey="generadorApp.patientResponsible.field_id">ID</Translate>
              </Label>
              </Col> */}
                      <Col md="12">
                        <AvInput id="patient-responsible-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'name' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="nameLabel" for="patient-responsible-name">
                              <Translate contentKey="generadorApp.patientResponsible.name">Name</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                              value={this.state.nameSelectValue ? this.state.nameSelectValue : ''}
                              id="patient-responsible-name"
                              type="text"
                              name="name"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                      type="hidden"
                      name="name"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'kinshipDegree' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="kinshipDegreeLabel" for="patient-responsible-kinshipDegree">
                              <Translate contentKey="generadorApp.patientResponsible.kinshipDegree">Kinship Degree</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ kinshipDegreeSelectValue: evt.target.value })}
                              value={this.state.kinshipDegreeSelectValue ? this.state.kinshipDegreeSelectValue : ''}
                              id="patient-responsible-kinshipDegree"
                              type="text"
                              name="kinshipDegree"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ kinshipDegreeSelectValue: evt.target.value })}
                      type="hidden"
                      name="kinshipDegree"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'contactTelephonePrincipal' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label
                              className="mt-2 label-single-line"
                              id="contactTelephonePrincipalLabel"
                              for="patient-responsible-contactTelephonePrincipal"
                            >
                              <Translate contentKey="generadorApp.patientResponsible.contactTelephonePrincipal">
                                Contact Telephone Principal
                              </Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <InputMask
                              onChange={evt => this.setState({ contactTelephonePrincipalSelectValue: evt.target.value })}
                              value={this.state.contactTelephonePrincipalSelectValue ? this.state.contactTelephonePrincipalSelectValue : ''}
                              id="patient-responsible-contactTelephonePrincipal"
                              type="text"
                              name="contactTelephonePrincipal"
                              className={'form-control'}
                              mask="99 99999-9999"
                              tag={InputMask}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ contactTelephonePrincipalSelectValue: evt.target.value })}
                      type="hidden"
                      name="contactTelephonePrincipal"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'contactTelephoneSecondary' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label
                              className="mt-2 label-single-line"
                              id="contactTelephoneSecondaryLabel"
                              for="patient-responsible-contactTelephoneSecondary"
                            >
                              <Translate contentKey="generadorApp.patientResponsible.contactTelephoneSecondary">
                                Contact Telephone Secondary
                              </Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <InputMask
                              onChange={evt => this.setState({ contactTelephoneSecondarySelectValue: evt.target.value })}
                              value={this.state.contactTelephoneSecondarySelectValue ? this.state.contactTelephoneSecondarySelectValue : ''}
                              id="patient-responsible-contactTelephoneSecondary"
                              type="text"
                              name="contactTelephoneSecondary"
                              className={'form-control'}
                              mask="99 99999-9999"
                              tag={InputMask}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ contactTelephoneSecondarySelectValue: evt.target.value })}
                      type="hidden"
                      name="contactTelephoneSecondary"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'email' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="emailLabel" for="patient-responsible-email">
                              <Translate contentKey="generadorApp.patientResponsible.email">Email</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ emailSelectValue: evt.target.value })}
                              value={this.state.emailSelectValue ? this.state.emailSelectValue : ''}
                              id="patient-responsible-email"
                              type="text"
                              name="email"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ emailSelectValue: evt.target.value })}
                      type="hidden"
                      name="email"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'accessApp' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="accessAppLabel" for="patient-responsible-accessApp">
                              <Translate contentKey="generadorApp.patientResponsible.accessApp">Access App</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="patient-responsible-accessApp"
                              className={'css-select-control'}
                              value={
                                this.state.accessAppSelectValue
                                  ? {
                                      value: this.state.accessAppSelectValue,
                                      label: translate('generadorApp.GeralSelect.' + this.state.accessAppSelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.patientResponsible.accessApp') }
                              }
                              options={[
                                { value: 'SIM', label: translate('generadorApp.GeralSelect.SIM') },
                                { value: 'NAO', label: translate('generadorApp.GeralSelect.NAO') },
                              ]}
                              onChange={(options: any) => this.setState({ accessAppSelectValue: options['value'] })}
                              name="accessApp"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ accessAppSelectValue: evt.target.value })}
                      type="hidden"
                      name="accessApp"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'zipCode' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label
                              className={'mt-2' + (this.state.cepRequestCep === '' ? ' text-danger' : '')}
                              id="zipCodeLabel"
                              for="patient-responsible-zipCode"
                            >
                              <Translate contentKey="generadorApp.patientResponsible.zipCode">Zip Code</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              id="patient-responsible-zipCode"
                              type="text"
                              name="zipCode"
                              className={
                                'form-control' + (this.state.cepRequestCep === '' ? ' is-touched is-pristine av-invalid is-invalid' : '')
                              }
                              value={this.state.cepRequestCep}
                              onBlur={inputVal => {
                                viacepRequest(inputVal.target.value, (cepRequest: any) => {
                                  this.setState(cepRequest);
                                });
                                this.setState({ zipCodeSelectValue: inputVal.target.value });
                                this.setState({ cepRequestCep: inputVal.target.value ? inputVal.target.value : '' });
                              }}
                              onChange={inputVal => {
                                this.setState({ cepRequestCep: inputVal.target.value });
                                this.setState({ zipCodeSelectValue: inputVal.target.value });
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ zipCodeSelectValue: evt.target.value })}
                      type="hidden"
                      name="zipCode"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'street' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="streetLabel" for="patient-responsible-street">
                              <Translate contentKey="generadorApp.patientResponsible.street">Street</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              id="patient-responsible-street"
                              type="text"
                              name="street"
                              className={'form-control'}
                              value={this.state.cepRequestLogradouro}
                              onBlur={inputVal =>
                                this.setState({ cepRequestLogradouro: inputVal.target.value ? inputVal.target.value : '' })
                              }
                              onChange={inputVal => {
                                this.setState({ cepRequestLogradouro: inputVal.target.value });
                                this.setState({ streetSelectValue: inputVal.target.value });
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ streetSelectValue: evt.target.value })}
                      type="hidden"
                      name="street"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'complement' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="complementLabel" for="patient-responsible-complement">
                              <Translate contentKey="generadorApp.patientResponsible.complement">Complement</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ complementSelectValue: evt.target.value })}
                              value={this.state.complementSelectValue ? this.state.complementSelectValue : ''}
                              id="patient-responsible-complement"
                              type="text"
                              name="complement"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ complementSelectValue: evt.target.value })}
                      type="hidden"
                      name="complement"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'number' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="numberLabel" for="patient-responsible-number">
                              <Translate contentKey="generadorApp.patientResponsible.number">Number</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ numberSelectValue: evt.target.value })}
                              value={this.state.numberSelectValue ? this.state.numberSelectValue : ''}
                              id="patient-responsible-number"
                              type="text"
                              name="number"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ numberSelectValue: evt.target.value })}
                      type="hidden"
                      name="number"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'neighborhood' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="neighborhoodLabel" for="patient-responsible-neighborhood">
                              <Translate contentKey="generadorApp.patientResponsible.neighborhood">Neighborhood</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              id="patient-responsible-neighborhood"
                              type="text"
                              name="neighborhood"
                              className={'form-control'}
                              value={this.state.cepRequestBairro}
                              onBlur={inputVal => this.setState({ cepRequestBairro: inputVal.target.value ? inputVal.target.value : '' })}
                              onChange={inputVal => {
                                this.setState({ cepRequestBairro: inputVal.target.value });
                                this.setState({ neighborhoodSelectValue: inputVal.target.value });
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ neighborhoodSelectValue: evt.target.value })}
                      type="hidden"
                      name="neighborhood"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'city' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="cityLabel" for="patient-responsible-city">
                              <Translate contentKey="generadorApp.patientResponsible.city">City</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              id="patient-responsible-city"
                              type="text"
                              name="city"
                              className={'form-control'}
                              value={this.state.cepRequestLocalidade}
                              onBlur={inputVal =>
                                this.setState({ cepRequestLocalidade: inputVal.target.value ? inputVal.target.value : '' })
                              }
                              onChange={inputVal => {
                                this.setState({ cepRequestLocalidade: inputVal.target.value });
                                this.setState({ citySelectValue: inputVal.target.value });
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ citySelectValue: evt.target.value })}
                      type="hidden"
                      name="city"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'uf' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="ufLabel" for="patient-responsible-uf">
                              <Translate contentKey="generadorApp.patientResponsible.uf">Uf</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              id="patient-responsible-uf"
                              type="text"
                              name="uf"
                              className={'form-control'}
                              value={this.state.cepRequestUf}
                              onBlur={inputVal => this.setState({ cepRequestUf: inputVal.target.value ? inputVal.target.value : '' })}
                              onChange={inputVal => {
                                this.setState({ cepRequestUf: inputVal.target.value });
                                this.setState({ ufSelectValue: inputVal.target.value });
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ ufSelectValue: evt.target.value })}
                      type="hidden"
                      name="uf"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'patient' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="patient-responsible-patient">
                              <Translate contentKey="generadorApp.patientResponsible.patient">Patient</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="patient-responsible-patient"
                              name={'patient'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.patientSelectValue ? this.state.patientSelectValue : ''}
                              onChange={options => this.setState({ patientSelectValue: options })}
                              defaultOptions={this.state.patientStartSelectOptions ? this.state.patientStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.patientStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.patientStartSelectOptions === undefined) {
                                  const result = await getListAxios('patients', {}, 0, 100, 'id,asc', 'id');
                                  this.setState({
                                    patientStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios('patients', { 'id.contains': inputValue }, 0, 100, 'id,asc', 'id');
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="patient"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}

                  {baseFilters !== 'comments' ? (
                    <Col md="12">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="commentsLabel" for="patient-responsible-comments">
                              <Translate contentKey="generadorApp.patientResponsible.comments">Comments</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ commentsSelectValue: evt.target.value })}
                              value={this.state.commentsSelectValue ? this.state.commentsSelectValue : ''}
                              id="patient-responsible-comments"
                              type="text"
                              name="comments"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ commentsSelectValue: evt.target.value })}
                      type="hidden"
                      name="comments"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  renderHeader() {
    const { patients, patientResponsibleList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.patientResponsible.home.title">Patient Responsibles</Translate>
          </span>
          <Button id="togglerFilterPatientResponsible" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.patientResponsible.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          &nbsp;
          <Button
            onClick={() =>
              this.setState({
                showModalForm: true,
                nameSelectValue: null,
                kinshipDegreeSelectValue: null,
                contactTelephonePrincipalSelectValue: null,
                contactTelephoneSecondarySelectValue: null,
                commentsSelectValue: null,
                emailSelectValue: null,
                accessAppSelectValue: 'NAO',
                zipCodeSelectValue: null,
                streetSelectValue: null,
                complementSelectValue: null,
                numberSelectValue: null,
                neighborhoodSelectValue: null,
                citySelectValue: null,
                ufSelectValue: null,

                isNew: true,
              })
            }
            color="primary"
            className="btn btn-primary float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="generadorApp.patientResponsible.btnNewItem">Create new</Translate>
          </Button>{' '}
          &nbsp;
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/patient'}>
              <Translate contentKey="generadorApp.patient.home.title">Patients</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/patient/' + this.props.match.params['idPatient']}>
              {this.state.patientSelectValue ? this.state.patientSelectValue.label : this.props.match.params['idPatient']}
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.patientResponsible.home.title">Patient Responsibles</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    const { patients, patientResponsibleList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <UncontrolledCollapse toggler="#togglerFilterPatientResponsible">
          <CardBody>
            <AvForm ref={el => (this.myFormRef = el)} id="form-filter" onSubmit={this.filterEntity}>
              <div className="row mt-1 ml-3 mr-3">
                {this.state.baseFilters !== 'name' ? (
                  <Col md="3" className="col-filter-patient-responsible-name">
                    <Row className="mr-1 mt-1">
                      <Label id="nameLabel" for="patient-responsible-name">
                        <Translate contentKey="generadorApp.patientResponsible.name">Name</Translate>
                      </Label>

                      <AvInput type="text" name="name" id="patient-responsible-name" value={this.state.name} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'kinshipDegree' ? (
                  <Col md="3" className="col-filter-patient-responsible-kinshipDegree">
                    <Row className="mr-1 mt-1">
                      <Label id="kinshipDegreeLabel" for="patient-responsible-kinshipDegree">
                        <Translate contentKey="generadorApp.patientResponsible.kinshipDegree">Kinship Degree</Translate>
                      </Label>

                      <AvInput type="text" name="kinshipDegree" id="patient-responsible-kinshipDegree" value={this.state.kinshipDegree} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'contactTelephonePrincipal' ? (
                  <Col md="3" className="col-filter-patient-responsible-contactTelephonePrincipal">
                    <Row className="mr-1 mt-1">
                      <Label id="contactTelephonePrincipalLabel" for="patient-responsible-contactTelephonePrincipal">
                        <Translate contentKey="generadorApp.patientResponsible.contactTelephonePrincipal">
                          Contact Telephone Principal
                        </Translate>
                      </Label>

                      <AvInput
                        type="text"
                        name="contactTelephonePrincipal"
                        id="patient-responsible-contactTelephonePrincipal"
                        value={this.state.contactTelephonePrincipal}
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'comments' ? (
                  <Col md="3" className="col-filter-patient-responsible-comments">
                    <Row className="mr-1 mt-1">
                      <Label id="commentsLabel" for="patient-responsible-comments">
                        <Translate contentKey="generadorApp.patientResponsible.comments">Comments</Translate>
                      </Label>

                      <AvInput type="text" name="comments" id="patient-responsible-comments" value={this.state.comments} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'accessApp' ? (
                  <Col md="3" className="col-filter-patient-responsible-accessApp">
                    <Row className="mr-1 mt-1">
                      <Label id="accessAppLabel" for="patient-responsible-accessApp">
                        <Translate contentKey="generadorApp.patientResponsible.accessApp">Access App</Translate>
                      </Label>
                      <Select
                        id="patient-responsible-accessApp"
                        className={'css-select-control'}
                        value={
                          this.state.accessApp
                            ? { value: this.state.accessApp, label: translate('generadorApp.GeralSelect.' + this.state.accessApp) }
                            : { value: '', label: translate('generadorApp.patientResponsible.accessApp') }
                        }
                        options={[
                          { value: '', label: translate('generadorApp.patientResponsible.accessApp') },
                          { value: 'SIM', label: translate('generadorApp.GeralSelect.SIM') },
                          { value: 'NAO', label: translate('generadorApp.GeralSelect.NAO') },
                        ]}
                        onChange={(options: any) => this.setState({ accessApp: options['value'] })}
                        name="accessApp"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'zipCode' ? (
                  <Col md="3" className="col-filter-patient-responsible-zipCode">
                    <Row className="mr-1 mt-1">
                      <Label id="zipCodeLabel" for="patient-responsible-zipCode">
                        <Translate contentKey="generadorApp.patientResponsible.zipCode">Zip Code</Translate>
                      </Label>

                      <AvInput type="text" name="zipCode" id="patient-responsible-zipCode" value={this.state.zipCode} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'city' ? (
                  <Col md="3" className="col-filter-patient-responsible-city">
                    <Row className="mr-1 mt-1">
                      <Label id="cityLabel" for="patient-responsible-city">
                        <Translate contentKey="generadorApp.patientResponsible.city">City</Translate>
                      </Label>

                      <AvInput type="text" name="city" id="patient-responsible-city" value={this.state.city} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'patient' ? (
                  <Col md="3">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="patient-responsible-patient">
                          <Translate contentKey="generadorApp.patientResponsible.patient">Patient</Translate>
                        </Label>
                        <SelectAsync
                          id="patient-responsible-patient"
                          isMulti
                          className={'css-select-control'}
                          name={'patient'}
                          cacheOptions
                          value={this.state.patientId}
                          onChange={options => this.setState({ patientId: options })}
                          defaultOptions={this.state.patientStartFilter ? this.state.patientStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.patientStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.patientStartFilter === undefined) {
                              const result = await getListAxios('patients', {}, 0, 100, 'id,asc', 'id');
                              this.setState({
                                patientStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios('patients', { 'id.contains': inputValue }, 0, 100, 'id,asc', 'id');
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}
              </div>

              <div className="row mb-2 mr-4 justify-content-end">
                <Button color="primary" type="submit">
                  <i className="fa fa-filter" aria-hidden={'true'}></i>&nbsp;
                  <Translate contentKey="generadorApp.patientResponsible.btnFilter">Filter</Translate>
                </Button>
                &nbsp;
                <Button color="secondary" onClick={this.cancelFilters}>
                  <FontAwesomeIcon icon="trash-alt" />
                  &nbsp;
                  <Translate contentKey="generadorApp.patientResponsible.btnCleanFilter">Clean Filter</Translate>
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </UncontrolledCollapse>
      </>
    );
  }

  renderTable() {
    const { patients, patientResponsibleList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {patientResponsibleList &&
        patientResponsibleList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null).length > 0 ? (
          <>
            <Table
              id="patient-responsible-table-list"
              responsive
              aria-describedby="patient-responsible-heading"
              className={'table-hover table-striped table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.patientResponsible.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'name' ? (
                    <th className="hand" onClick={sortFunction('name')}>
                      <Translate contentKey="generadorApp.patientResponsible.name">Name</Translate>
                      <FontAwesomeIcon icon={state.sort === 'name' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'kinshipDegree' ? (
                    <th className="hand" onClick={sortFunction('kinshipDegree')}>
                      <Translate contentKey="generadorApp.patientResponsible.kinshipDegree">Kinship Degree</Translate>
                      <FontAwesomeIcon icon={state.sort === 'kinshipDegree' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'contactTelephonePrincipal' ? (
                    <th className="hand" onClick={sortFunction('contactTelephonePrincipal')}>
                      <Translate contentKey="generadorApp.patientResponsible.contactTelephonePrincipal">
                        Contact Telephone Principal
                      </Translate>
                      <FontAwesomeIcon
                        icon={state.sort === 'contactTelephonePrincipal' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'}
                      />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'comments' ? (
                    <th className="hand" onClick={sortFunction('comments')}>
                      <Translate contentKey="generadorApp.patientResponsible.comments">Comments</Translate>
                      <FontAwesomeIcon icon={state.sort === 'comments' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'accessApp' ? (
                    <th className="hand" onClick={sortFunction('accessApp')}>
                      <Translate contentKey="generadorApp.patientResponsible.accessApp">Access App</Translate>
                      <FontAwesomeIcon icon={state.sort === 'accessApp' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'patient' ? (
                    <th>
                      <Translate contentKey="generadorApp.patientResponsible.patient">Patient</Translate>
                    </th>
                  ) : null}

                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {patientResponsibleList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((patientResponsible, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.patientResponsible.field_id">ID</Translate>
                        </b>
                        {patientResponsible.id}
                      </td>

                      {state.baseFilters !== 'name' ? (
                        <td id="name-cell">
                          <b className="visible-xs"> Name </b>

                          {patientResponsible.name}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'kinshipDegree' ? (
                        <td id="kinshipDegree-cell">
                          <b className="visible-xs"> Kinship Degree </b>

                          {patientResponsible.kinshipDegree}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'contactTelephonePrincipal' ? (
                        <td id="contactTelephonePrincipal-cell">
                          <b className="visible-xs"> Contact Telephone Principal </b>

                          {patientResponsible.contactTelephonePrincipal}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'comments' ? (
                        <td id="comments-cell">
                          <b className="visible-xs"> Comments </b>

                          {patientResponsible.comments}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'accessApp' ? (
                        <td id="accessApp-cell">
                          <b className="visible-xs"> Access App </b>
                          {patientResponsible.accessApp ? (
                            <Translate contentKey={`generadorApp.GeralSelect.${patientResponsible.accessApp}`} />
                          ) : (
                            <> </>
                          )}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'patient' ? (
                        <td id="patient-cell">
                          <b className="visible-xs"> Patient </b>
                          {patientResponsible ? (
                            <span data-type-rel="2-many-to-one-other-side">
                              {getValueRecursive(patientResponsible, 'patient.id'.split('.'), '')}
                            </span>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : null}

                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          {hasAnyAuthority(props.userAccount, ['patient-responsible'], 'edit') ? (
                            <Button onClick={() => this.openUpdateModal(patientResponsible)} color="primary" size="sm">
                              <FontAwesomeIcon icon="pencil-alt" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                          {hasAnyAuthority(props.userAccount, ['patient-responsible'], 'canDelete') ? (
                            <Button onClick={() => this.deleteEntity(patientResponsible)} color="danger" size="sm">
                              <FontAwesomeIcon icon="trash" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.patientResponsible.home.notFound">No Patient Responsibles found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const { patients, patientResponsibleList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={patientResponsibleList && patientResponsibleList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const { patients, patientResponsibleList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel className="patient-responsible patient-responsible-all-in-one">
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
            {this.renderNewModal()}
            {this.renderUpdateModal()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToProps = ({ patientResponsible, ...storeState }: IRootState) => ({
  patients: storeState.patient.entities,
  patientResponsibleList: patientResponsible.entities,
  totalItems: patientResponsible.totalItems,
  userAccount: storeState.authentication.account,
  loading: patientResponsible.loading,

  // UPDATE
  patientResponsibleEntity: patientResponsible.entity,
});

export const mapDispatchToProps = {
  getEntities,

  updateEntity,
  reset,

  // UPDATE
  getEntity,
  createEntity,

  // DELETE
  deleteEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PatientResponsible);
