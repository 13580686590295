import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Brand from './brand';

import BrandDetail from './brand-detail';

import BrandUpdate from './brand';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={BrandUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={BrandUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={BrandDetail} />
      <ErrorBoundaryRoute path={match.path} component={Brand} />
    </Switch>
  </>
);

export default Routes;
