import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ClientFile from './client-file';

import ClientFileUpdate from './client-file';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={ClientFileUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={ClientFileUpdate} />
      <ErrorBoundaryRoute path={match.path} component={ClientFile} />
    </Switch>
  </>
);

export default Routes;
