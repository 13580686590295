/* eslint complexity: ["error", 500] */
import axios from 'axios';

import { IPaginationBaseState, IPayloadResult } from 'react-jhipster';

import { IPayload } from 'react-jhipster/src/type/redux-action.type';

import { BASE_API_VERSION_PATH } from 'app/config/constants';

import { cleanEntity, jsonParse } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IPermissionProfile, defaultValue } from 'app/shared/model/permission-profile.model';

export declare type ICrudPutAction<T> = (data?: T, listFiltersPage?) => IPayload<T> | IPayloadResult<T>;
export declare type ICrudGetAction<T> = (id: string | number, selectFields?: string) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const ACTION_TYPES = {
  FETCH_PERMISSIONPROFILE_LIST_EXPORT: 'permissionProfile/FETCH_PERMISSIONPROFILE_LIST_EXPORT',
  FETCH_PERMISSIONPROFILE_LIST: 'permissionProfile/FETCH_PERMISSIONPROFILE_LIST',
  FETCH_PERMISSIONPROFILE: 'permissionProfile/FETCH_PERMISSIONPROFILE',
  FETCH_PERMISSIONPROFILE_OFFSET: 'permissionProfile/FETCH_PERMISSIONPROFILE_OFFSET',
  CREATE_PERMISSIONPROFILE: 'permissionProfile/CREATE_PERMISSIONPROFILE',
  UPDATE_PERMISSIONPROFILE: 'permissionProfile/UPDATE_PERMISSIONPROFILE',
  CREATE_PERMISSIONPROFILE_AND_GO_LIST: 'permissionProfile/CREATE_PERMISSIONPROFILE_AND_GO_LIST',
  UPDATE_PERMISSIONPROFILE_AND_GO_LIST: 'permissionProfile/UPDATE_PERMISSIONPROFILE_AND_GO_LIST',
  DELETE_PERMISSIONPROFILE: 'permissionProfile/DELETE_PERMISSIONPROFILE',
  RESET: 'permissionProfile/RESET',
  SHOW_MODAL: 'permissionProfile/SHOW_MODAL',
};

const initialState = {
  loading: false,
  openModal: {
    list: false,
    view: false,
    detail: false,
  },
  errorMessage: null,
  entities: [] as ReadonlyArray<IPermissionProfile>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const actionPendind = [];
let actualState = initialState;

export const convertEntityBuffers = entity => ({
  ...entity,
});

export type PermissionProfileState = Readonly<typeof initialState>;

export interface IPermissionProfileBaseState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  listCheckedID?: Array<number>;
  isCheckedAll?: boolean;
  urlBack?: string;
  extraFilters?: any;
  baseFilters: any;
  offset: number;
  view?: any;
  resgister?: any;
  edit?: any;
  canDelete?: any;
  manualRemoval?: any;
  editLocation?: any;
  report?: any;
  activateProfessional?: any;
  deletePhoto?: any;
  financialValue?: any;
  valueAuthorization?: any;
  confirmPayment?: any;
  evolution?: any;
  professionalName?: any;
  professionalRegister?: any;
  profileId?: any;
  profileStartFilter?: any;
  permissionId?: any;
  permissionStartFilter?: any;
}

export interface IFieldsBase extends IPermissionProfileBaseState, IPaginationBaseState {}
export interface IPermissionProfileUpdateState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  urlBack?: string;
  functionEmbebed?: any[];
  fieldsBase: IFieldsBase;
  viewSelectValue?: any;
  viewStartSelectOptions?: any;
  resgisterSelectValue?: any;
  resgisterStartSelectOptions?: any;
  editSelectValue?: any;
  editStartSelectOptions?: any;
  canDeleteSelectValue?: any;
  canDeleteStartSelectOptions?: any;
  manualRemovalSelectValue?: any;
  manualRemovalStartSelectOptions?: any;
  editLocationSelectValue?: any;
  editLocationStartSelectOptions?: any;
  reportSelectValue?: any;
  reportStartSelectOptions?: any;
  activateProfessionalSelectValue?: any;
  activateProfessionalStartSelectOptions?: any;
  deletePhotoSelectValue?: any;
  deletePhotoStartSelectOptions?: any;
  financialValueSelectValue?: any;
  financialValueStartSelectOptions?: any;
  valueAuthorizationSelectValue?: any;
  valueAuthorizationStartSelectOptions?: any;
  confirmPaymentSelectValue?: any;
  confirmPaymentStartSelectOptions?: any;
  evolutionSelectValue?: any;
  evolutionStartSelectOptions?: any;
  professionalNameSelectValue?: any;
  professionalNameStartSelectOptions?: any;
  professionalRegisterSelectValue?: any;
  professionalRegisterStartSelectOptions?: any;

  profileSelectValue?: any;
  profileStartSelectOptions?: any;

  permissionSelectValue?: any;
  permissionStartSelectOptions?: any;

  isNew: boolean;
  profileName?: any;

  profileId?: any;
  permissionName?: any;

  permissionId?: any;
}

// Reducer

export default (state: PermissionProfileState = initialState, action): PermissionProfileState => {
  actualState = state;
  if (
    action.type.includes('_PENDING') &&
    !actionPendind.includes(action.type.replace('_PENDING', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_PENDING', ''))
  ) {
    actionPendind.push(action.type.replace('_PENDING', ''));
  }

  if (
    action.type.includes('_FULFILLED') &&
    actionPendind.includes(action.type.replace('_FULFILLED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_FULFILLED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_FULFILLED', '')), 1);
  }

  if (
    action.type.includes('_REJECTED') &&
    actionPendind.includes(action.type.replace('_REJECTED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_REJECTED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_REJECTED', '')), 1);
  }

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PERMISSIONPROFILE_LIST_EXPORT):
    case REQUEST(ACTION_TYPES.FETCH_PERMISSIONPROFILE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_PERMISSIONPROFILE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_PERMISSIONPROFILE):
    case REQUEST(ACTION_TYPES.UPDATE_PERMISSIONPROFILE):
    case REQUEST(ACTION_TYPES.CREATE_PERMISSIONPROFILE_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.UPDATE_PERMISSIONPROFILE_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.DELETE_PERMISSIONPROFILE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_PERMISSIONPROFILE_LIST_EXPORT):
    case FAILURE(ACTION_TYPES.FETCH_PERMISSIONPROFILE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_PERMISSIONPROFILE):
    case FAILURE(ACTION_TYPES.CREATE_PERMISSIONPROFILE):
    case FAILURE(ACTION_TYPES.UPDATE_PERMISSIONPROFILE):
    case FAILURE(ACTION_TYPES.CREATE_PERMISSIONPROFILE_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.UPDATE_PERMISSIONPROFILE_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.DELETE_PERMISSIONPROFILE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_PERMISSIONPROFILE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_PERMISSIONPROFILE_OFFSET):
      if (action.payload.data && action.payload.data.length > 0) {
        return {
          ...state,
          loading: false,
          entity: convertEntityBuffers(action.payload.data[0]),
        };
      }
      return {
        ...state,
        loading: false,
      };

    case SUCCESS(ACTION_TYPES.FETCH_PERMISSIONPROFILE):
      return {
        ...state,
        loading: false,
        entity: convertEntityBuffers(action.payload.data),
      };
    case SUCCESS(ACTION_TYPES.CREATE_PERMISSIONPROFILE):
    case SUCCESS(ACTION_TYPES.UPDATE_PERMISSIONPROFILE):
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_PERMISSIONPROFILE_AND_GO_LIST):
    case SUCCESS(ACTION_TYPES.UPDATE_PERMISSIONPROFILE_AND_GO_LIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_PERMISSIONPROFILE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };

    case ACTION_TYPES.SHOW_MODAL: {
      const openModal = state.openModal;
      openModal[action.payload.type] = action.payload.show;
      return {
        ...state,
        openModal,
      };
    }
    default:
      return state;
  }
};

const apiUrl = BASE_API_VERSION_PATH + 'permission-profiles';

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

// Actions

// Actions
export type ICrudGetAllActionPermissionProfile<T> = (
  view?: any,
  resgister?: any,
  edit?: any,
  canDelete?: any,
  manualRemoval?: any,
  editLocation?: any,
  report?: any,
  activateProfessional?: any,
  deletePhoto?: any,
  financialValue?: any,
  valueAuthorization?: any,
  confirmPayment?: any,
  evolution?: any,
  professionalName?: any,
  professionalRegister?: any,
  profileId?: any,
  permissionId?: any,
  extraFilters?: {},
  page?: number,
  size?: number,
  sort?: string,
  selectFields?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getEntitiesFilter = (filters?, page?, size?, sort?, selectFields = '') => {
  const requestUrl = `${apiUrl}?page=${page >= 0 ? page : 0}&size=${size >= 0 ? size : 20}&sort=${sort ? sort : 'id,asc'}&`;
  const requestFilters = filters.map(v => v.column + '.' + v.operation + '=' + v.value).join('&');
  return {
    type: ACTION_TYPES.FETCH_PERMISSIONPROFILE_LIST,
    payload: axios.get<IPermissionProfile>(`${requestUrl}${requestFilters}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getAllEntities = (filters?, sort?, selectFields = '') => {
  if (
    (actualState.totalItems > 0 && actualState.entities.length === actualState.totalItems) ||
    actionPendind.includes(ACTION_TYPES.FETCH_PERMISSIONPROFILE_LIST)
  ) {
    return {
      type: ACTION_TYPES.FETCH_PERMISSIONPROFILE_LIST + '_FROM_CACHE',
      payload: new Promise(function (resolve, reject) {
        resolve('done');
      }),
    };
  }

  const idsRequest = filters && typeof filters['ids'] !== 'undefined' && filters['ids'] ? `id.in=${filters['ids'].join(',')}&` : '';
  const viewRequest = filters && typeof filters['view'] !== 'undefined' && filters['view'] ? `view.contains=${filters['view']}&` : '';
  const resgisterRequest =
    filters && typeof filters['resgister'] !== 'undefined' && filters['resgister'] ? `resgister.contains=${filters['resgister']}&` : '';
  const editRequest = filters && typeof filters['edit'] !== 'undefined' && filters['edit'] ? `edit.contains=${filters['edit']}&` : '';
  const canDeleteRequest =
    filters && typeof filters['canDelete'] !== 'undefined' && filters['canDelete'] ? `canDelete.contains=${filters['canDelete']}&` : '';
  const manualRemovalRequest =
    filters && typeof filters['manualRemoval'] !== 'undefined' && filters['manualRemoval']
      ? `manualRemoval.contains=${filters['manualRemoval']}&`
      : '';
  const editLocationRequest =
    filters && typeof filters['editLocation'] !== 'undefined' && filters['editLocation']
      ? `editLocation.contains=${filters['editLocation']}&`
      : '';
  const reportRequest =
    filters && typeof filters['report'] !== 'undefined' && filters['report'] ? `report.contains=${filters['report']}&` : '';
  const activateProfessionalRequest =
    filters && typeof filters['activateProfessional'] !== 'undefined' && filters['activateProfessional']
      ? `activateProfessional.contains=${filters['activateProfessional']}&`
      : '';
  const deletePhotoRequest =
    filters && typeof filters['deletePhoto'] !== 'undefined' && filters['deletePhoto']
      ? `deletePhoto.contains=${filters['deletePhoto']}&`
      : '';
  const financialValueRequest =
    filters && typeof filters['financialValue'] !== 'undefined' && filters['financialValue']
      ? `financialValue.contains=${filters['financialValue']}&`
      : '';
  const valueAuthorizationRequest =
    filters && typeof filters['valueAuthorization'] !== 'undefined' && filters['valueAuthorization']
      ? `valueAuthorization.contains=${filters['valueAuthorization']}&`
      : '';
  const confirmPaymentRequest =
    filters && typeof filters['confirmPayment'] !== 'undefined' && filters['confirmPayment']
      ? `confirmPayment.contains=${filters['confirmPayment']}&`
      : '';
  const evolutionRequest =
    filters && typeof filters['evolution'] !== 'undefined' && filters['evolution'] ? `evolution.contains=${filters['evolution']}&` : '';
  const professionalNameRequest =
    filters && typeof filters['professionalName'] !== 'undefined' && filters['professionalName']
      ? `professionalName.contains=${filters['professionalName']}&`
      : '';
  const professionalRegisterRequest =
    filters && typeof filters['professionalRegister'] !== 'undefined' && filters['professionalRegister']
      ? `professionalRegister.contains=${filters['professionalRegister']}&`
      : '';
  const profileRequest =
    filters && typeof filters['profileId'] !== 'undefined' && filters['profileId'] ? `profile.id.in=${filters['profileId']}&&` : '';
  const permissionRequest =
    filters && typeof filters['permissionId'] !== 'undefined' && filters['permissionId']
      ? `permission.id.in=${filters['permissionId']}&&`
      : '';

  const requestUrl = `${apiUrl}${`?page=${0}&size=${1000000}&sort=${sort ? sort : 'id,asc'}&`}`;
  return {
    type: ACTION_TYPES.FETCH_PERMISSIONPROFILE_LIST,
    payload: axios.get<IPermissionProfile>(
      `${requestUrl}${idsRequest}${viewRequest}${resgisterRequest}${editRequest}${canDeleteRequest}${manualRemovalRequest}${editLocationRequest}${reportRequest}${activateProfessionalRequest}${deletePhotoRequest}${financialValueRequest}${valueAuthorizationRequest}${confirmPaymentRequest}${evolutionRequest}${professionalNameRequest}${professionalRegisterRequest}${profileRequest}${permissionRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntities: ICrudGetAllActionPermissionProfile<IPermissionProfile> = (
  view,
  resgister,
  edit,
  canDelete,
  manualRemoval,
  editLocation,
  report,
  activateProfessional,
  deletePhoto,
  financialValue,
  valueAuthorization,
  confirmPayment,
  evolution,
  professionalName,
  professionalRegister,
  profileId,
  permissionId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const viewRequest = view !== '' ? `view.equals=${view}&` : '';
  const resgisterRequest = resgister !== '' ? `resgister.equals=${resgister}&` : '';
  const editRequest = edit !== '' ? `edit.equals=${edit}&` : '';
  const canDeleteRequest = canDelete !== '' ? `canDelete.equals=${canDelete}&` : '';
  const manualRemovalRequest = manualRemoval !== '' ? `manualRemoval.equals=${manualRemoval}&` : '';
  const editLocationRequest = editLocation !== '' ? `editLocation.equals=${editLocation}&` : '';
  const reportRequest = report !== '' ? `report.equals=${report}&` : '';
  const activateProfessionalRequest = activateProfessional !== '' ? `activateProfessional.equals=${activateProfessional}&` : '';
  const deletePhotoRequest = deletePhoto !== '' ? `deletePhoto.equals=${deletePhoto}&` : '';
  const financialValueRequest = financialValue !== '' ? `financialValue.equals=${financialValue}&` : '';
  const valueAuthorizationRequest = valueAuthorization !== '' ? `valueAuthorization.equals=${valueAuthorization}&` : '';
  const confirmPaymentRequest = confirmPayment !== '' ? `confirmPayment.equals=${confirmPayment}&` : '';
  const evolutionRequest = evolution !== '' ? `evolution.equals=${evolution}&` : '';
  const professionalNameRequest = professionalName !== '' ? `professionalName.equals=${professionalName}&` : '';
  const professionalRegisterRequest = professionalRegister !== '' ? `professionalRegister.equals=${professionalRegister}&` : '';
  const profileRequest = profileId ? `profile.id.in=${profileId}&` : '';
  const permissionRequest = permissionId ? `permission.id.in=${permissionId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PERMISSIONPROFILE_LIST,
    payload: axios.get<IPermissionProfile>(
      `${requestUrl}${extraFiltersRequest}${viewRequest}${resgisterRequest}${editRequest}${canDeleteRequest}${manualRemovalRequest}${editLocationRequest}${reportRequest}${activateProfessionalRequest}${deletePhotoRequest}${financialValueRequest}${valueAuthorizationRequest}${confirmPaymentRequest}${evolutionRequest}${professionalNameRequest}${professionalRegisterRequest}${profileRequest}${permissionRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntityOffset: any = (
  view,
  resgister,
  edit,
  canDelete,
  manualRemoval,
  editLocation,
  report,
  activateProfessional,
  deletePhoto,
  financialValue,
  valueAuthorization,
  confirmPayment,
  evolution,
  professionalName,
  professionalRegister,
  profileId,
  permissionId,
  extraFilters,
  offset,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const viewRequest = view !== '' ? `view.equals=${view}&` : '';
  const resgisterRequest = resgister !== '' ? `resgister.equals=${resgister}&` : '';
  const editRequest = edit !== '' ? `edit.equals=${edit}&` : '';
  const canDeleteRequest = canDelete !== '' ? `canDelete.equals=${canDelete}&` : '';
  const manualRemovalRequest = manualRemoval !== '' ? `manualRemoval.equals=${manualRemoval}&` : '';
  const editLocationRequest = editLocation !== '' ? `editLocation.equals=${editLocation}&` : '';
  const reportRequest = report !== '' ? `report.equals=${report}&` : '';
  const activateProfessionalRequest = activateProfessional !== '' ? `activateProfessional.equals=${activateProfessional}&` : '';
  const deletePhotoRequest = deletePhoto !== '' ? `deletePhoto.equals=${deletePhoto}&` : '';
  const financialValueRequest = financialValue !== '' ? `financialValue.equals=${financialValue}&` : '';
  const valueAuthorizationRequest = valueAuthorization !== '' ? `valueAuthorization.equals=${valueAuthorization}&` : '';
  const confirmPaymentRequest = confirmPayment !== '' ? `confirmPayment.equals=${confirmPayment}&` : '';
  const evolutionRequest = evolution !== '' ? `evolution.equals=${evolution}&` : '';
  const professionalNameRequest = professionalName !== '' ? `professionalName.equals=${professionalName}&` : '';
  const professionalRegisterRequest = professionalRegister !== '' ? `professionalRegister.equals=${professionalRegister}&` : '';
  const profileRequest = profileId ? `profile.id.in=${profileId}&` : '';
  const permissionRequest = permissionId ? `permission.id.in=${permissionId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${offset}&size=${1}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PERMISSIONPROFILE_OFFSET,
    payload: axios.get<IPermissionProfile>(
      `${requestUrl}${extraFiltersRequest}${viewRequest}${resgisterRequest}${editRequest}${canDeleteRequest}${manualRemovalRequest}${editLocationRequest}${reportRequest}${activateProfessionalRequest}${deletePhotoRequest}${financialValueRequest}${valueAuthorizationRequest}${confirmPaymentRequest}${evolutionRequest}${professionalNameRequest}${professionalRegisterRequest}${profileRequest}${permissionRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntity: ICrudGetAction<IPermissionProfile> = (id, selectFields = '') => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_PERMISSIONPROFILE,
    payload: axios.get<IPermissionProfile>(requestUrl, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntitiesExport: ICrudGetAllActionPermissionProfile<IPermissionProfile> = (
  view,
  resgister,
  edit,
  canDelete,
  manualRemoval,
  editLocation,
  report,
  activateProfessional,
  deletePhoto,
  financialValue,
  valueAuthorization,
  confirmPayment,
  evolution,
  professionalName,
  professionalRegister,
  profileId,
  permissionId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const viewRequest = view !== '' ? `view.equals=${view}&` : '';
  const resgisterRequest = resgister !== '' ? `resgister.equals=${resgister}&` : '';
  const editRequest = edit !== '' ? `edit.equals=${edit}&` : '';
  const canDeleteRequest = canDelete !== '' ? `canDelete.equals=${canDelete}&` : '';
  const manualRemovalRequest = manualRemoval !== '' ? `manualRemoval.equals=${manualRemoval}&` : '';
  const editLocationRequest = editLocation !== '' ? `editLocation.equals=${editLocation}&` : '';
  const reportRequest = report !== '' ? `report.equals=${report}&` : '';
  const activateProfessionalRequest = activateProfessional !== '' ? `activateProfessional.equals=${activateProfessional}&` : '';
  const deletePhotoRequest = deletePhoto !== '' ? `deletePhoto.equals=${deletePhoto}&` : '';
  const financialValueRequest = financialValue !== '' ? `financialValue.equals=${financialValue}&` : '';
  const valueAuthorizationRequest = valueAuthorization !== '' ? `valueAuthorization.equals=${valueAuthorization}&` : '';
  const confirmPaymentRequest = confirmPayment !== '' ? `confirmPayment.equals=${confirmPayment}&` : '';
  const evolutionRequest = evolution !== '' ? `evolution.equals=${evolution}&` : '';
  const professionalNameRequest = professionalName !== '' ? `professionalName.equals=${professionalName}&` : '';
  const professionalRegisterRequest = professionalRegister !== '' ? `professionalRegister.equals=${professionalRegister}&` : '';
  const profileRequest = profileId ? `profile.id.in=${profileId}&` : '';
  const permissionRequest = permissionId ? `permission.id.in=${permissionId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PERMISSIONPROFILE_LIST,
    payload: axios.get<IPermissionProfile>(
      `${requestUrl}${extraFiltersRequest}${viewRequest}${resgisterRequest}${editRequest}${canDeleteRequest}${manualRemovalRequest}${editLocationRequest}${reportRequest}${activateProfessionalRequest}${deletePhotoRequest}${financialValueRequest}${valueAuthorizationRequest}${confirmPaymentRequest}${evolutionRequest}${professionalNameRequest}${professionalRegisterRequest}${profileRequest}${permissionRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const createEntity: ICrudPutAction<IPermissionProfile> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.CREATE_PERMISSIONPROFILE_AND_GO_LIST
        : ACTION_TYPES.CREATE_PERMISSIONPROFILE,
    payload: axios.post(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const updateEntity: ICrudPutAction<IPermissionProfile> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.UPDATE_PERMISSIONPROFILE_AND_GO_LIST
        : ACTION_TYPES.UPDATE_PERMISSIONPROFILE,
    payload: axios.put(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const deleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_PERMISSIONPROFILE,
    payload: axios.delete(requestUrl),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const showModal = view => ({
  payload: { type: view, show: true },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const hideModal = view => ({
  payload: { type: view, show: false },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const getUrlBack = (location): string => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  let urlBack = _urlBase.searchParams.get('urlBack') || '';
  urlBack = urlBack.trim();
  urlBack = urlBack.startsWith('/') ? urlBack.slice(1) : urlBack;
  urlBack = urlBack.endsWith('/') ? urlBack.slice(0, -1) : urlBack;
  return urlBack ? '/' + urlBack + '/' : '';
};

export const getPermissionProfileState = (location): IPermissionProfileBaseState => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  const extraFilters = jsonParse(_urlBase.searchParams.get('extraFilters') || '{}');
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;

  const view = _urlBase.searchParams.get('view') || '';
  const resgister = _urlBase.searchParams.get('resgister') || '';
  const edit = _urlBase.searchParams.get('edit') || '';
  const canDelete = _urlBase.searchParams.get('canDelete') || '';
  const manualRemoval = _urlBase.searchParams.get('manualRemoval') || '';
  const editLocation = _urlBase.searchParams.get('editLocation') || '';
  const report = _urlBase.searchParams.get('report') || '';
  const activateProfessional = _urlBase.searchParams.get('activateProfessional') || '';
  const deletePhoto = _urlBase.searchParams.get('deletePhoto') || '';
  const financialValue = _urlBase.searchParams.get('financialValue') || '';
  const valueAuthorization = _urlBase.searchParams.get('valueAuthorization') || '';
  const confirmPayment = _urlBase.searchParams.get('confirmPayment') || '';
  const evolution = _urlBase.searchParams.get('evolution') || '';
  const professionalName = _urlBase.searchParams.get('professionalName') || '';
  const professionalRegister = _urlBase.searchParams.get('professionalRegister') || '';
  const profileId = _urlBase.searchParams.get('profile') || '';
  const permissionId = _urlBase.searchParams.get('permission') || '';
  return {
    baseFilters,
    extraFilters,
    offset,
    view,
    resgister,
    edit,
    canDelete,
    manualRemoval,
    editLocation,
    report,
    activateProfessional,
    deletePhoto,
    financialValue,
    valueAuthorization,
    confirmPayment,
    evolution,
    professionalName,
    professionalRegister,
    profileId: profileId ? profileId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
    permissionId: permissionId ? permissionId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
  };
};

export const getEntityFiltersURL = (state, offset = null) => {
  return (
    'baseFilters=' +
    state.baseFilters +
    '&extraFilters=' +
    encodeURI(JSON.stringify(state.extraFilters)) +
    '&page=' +
    state.activePage +
    '&' +
    'size=' +
    state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    state.sort +
    ',' +
    state.order +
    '&' +
    (state.view ? 'view=' + state.view + '&' : '') +
    (state.resgister ? 'resgister=' + state.resgister + '&' : '') +
    (state.edit ? 'edit=' + state.edit + '&' : '') +
    (state.canDelete ? 'canDelete=' + state.canDelete + '&' : '') +
    (state.manualRemoval ? 'manualRemoval=' + state.manualRemoval + '&' : '') +
    (state.editLocation ? 'editLocation=' + state.editLocation + '&' : '') +
    (state.report ? 'report=' + state.report + '&' : '') +
    (state.activateProfessional ? 'activateProfessional=' + state.activateProfessional + '&' : '') +
    (state.deletePhoto ? 'deletePhoto=' + state.deletePhoto + '&' : '') +
    (state.financialValue ? 'financialValue=' + state.financialValue + '&' : '') +
    (state.valueAuthorization ? 'valueAuthorization=' + state.valueAuthorization + '&' : '') +
    (state.confirmPayment ? 'confirmPayment=' + state.confirmPayment + '&' : '') +
    (state.evolution ? 'evolution=' + state.evolution + '&' : '') +
    (state.professionalName ? 'professionalName=' + state.professionalName + '&' : '') +
    (state.professionalRegister ? 'professionalRegister=' + state.professionalRegister + '&' : '') +
    (state.profileId ? 'profile=' + state.profileId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.permissionId ? 'permission=' + state.permissionId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    ''
  );
};
