import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import SupplierTable from './supplier-table';

import SupplierTableDetail from './supplier-table-detail';

import SupplierTableUpdate from './extended/supplier-table-update';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={SupplierTableUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={SupplierTableUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={SupplierTableDetail} />
      <ErrorBoundaryRoute path={match.path} component={SupplierTable} />
    </Switch>
  </>
);

export default Routes;
