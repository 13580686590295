/* eslint complexity: ["error", 500] */
/* eslint no-empty: off */
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import SelectAsync from 'react-select/async';

import { Link, RouteComponentProps } from 'react-router-dom';

import { getFilterFromSelect, getFormFromSelect, getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Col, Label } from 'reactstrap';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import {
  IMedicalRecordUpdateState as IBaseUpdateState,
  getEntity,
  getMedicalRecordState,
  getUrlBack,
  updateEntity,
  createEntity,
  getEntityFiltersURL,
  getEntityOffset,
  reset,
} from './medical-record.reducer';

import { convertDateTimeToServer } from 'app/shared/util/date-utils';

import { StatePropsExtended, DispatchPropsExtended, IUpdateExtendedState } from './extended/medical-record-update';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';
import moment from 'moment';
registerLocale('pt', pt);

export interface IMedicalRecordUpdateProps
  extends StateProps,
    DispatchProps,
    StatePropsExtended,
    DispatchPropsExtended,
    RouteComponentProps<{ url: string }> {}
export interface IMedicalRecordUpdateState extends IBaseUpdateState, IUpdateExtendedState {}

export class MedicalRecordUpdate extends React.Component<IMedicalRecordUpdateProps, IMedicalRecordUpdateState> {
  passageReasonFileInput: any;

  fallFileInput: any;

  clinicalInstabilityFileInput: any;

  examFileInput: any;

  examTypeFileInput: any;

  hospitalizationDateFileInput: any;

  dischargeDateFileInput: any;

  hospitalizationReasonFileInput: any;

  occurrenceFileInput: any;

  manifestationFileInput: any;

  complimentFileInput: any;

  complaintFileInput: any;

  suggestionFileInput: any;

  additionalInformationFileInput: any;

  typeFileInput: any;

  dateFileInput: any;

  reasonFileInput: any;

  conductFileInput: any;

  recordFileInput: any;

  highlightedFileInput: any;
  constructor(props: Readonly<IMedicalRecordUpdateProps>) {
    super(props);

    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      functionEmbebed: [],

      medicalRecordFileSelectValue: null,

      configTagSelectValue: null,

      clinicalIndicatorSelectValue: null,

      patientSelectValue: null,

      specialtySelectValue: null,

      passageReasonSelectValue: null,
      fallSelectValue: null,
      clinicalInstabilitySelectValue: null,
      examSelectValue: null,
      examTypeSelectValue: null,
      hospitalizationDateSelectValue: null,
      dischargeDateSelectValue: null,
      hospitalizationReasonSelectValue: null,
      occurrenceSelectValue: null,
      manifestationSelectValue: null,
      complimentSelectValue: null,
      complaintSelectValue: null,
      suggestionSelectValue: null,
      additionalInformationSelectValue: null,
      typeSelectValue: null,
      dateSelectValue: null,
      reasonSelectValue: null,
      conductSelectValue: null,
      recordSelectValue: null,
      highlightedSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getMedicalRecordState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
      idsconfigTag: [],
      idsclinicalIndicator: [],
      patientId: null,
      specialtyName: null,

      isNew: !this.props.match.params || !this.props.match.params['id'],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateSuccess !== this.props.updateSuccess && prevProps.updateSuccess) {
      this.handleClose();
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.medicalRecordEntity && prevProps.medicalRecordEntity.id !== this.props.medicalRecordEntity.id) ||
      (this.props.medicalRecordFiles.length > 0 &&
        this.state.medicalRecordFileSelectValue === null &&
        this.props.medicalRecordEntity.medicalRecordFile)
    ) {
      this.setState({
        medicalRecordFileSelectValue:
          this.props.medicalRecordEntity && this.props.medicalRecordEntity.medicalRecordFile
            ? this.props.medicalRecordEntity.medicalRecordFile.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-1 many-to-many owner-side
    if (
      (prevProps.medicalRecordEntity && prevProps.medicalRecordEntity.id !== this.props.medicalRecordEntity.id) ||
      (this.props.configTags.length > 0 && this.state.configTagSelectValue === null && this.props.medicalRecordEntity.configTags)
    ) {
      this.setState({
        configTagSelectValue:
          this.props.medicalRecordEntity && this.props.medicalRecordEntity.configTags
            ? this.props.medicalRecordEntity.configTags.filter(p => p).map(p => ({ value: p.id, label: p['name'] ? p.name : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-1 many-to-many owner-side
    if (
      (prevProps.medicalRecordEntity && prevProps.medicalRecordEntity.id !== this.props.medicalRecordEntity.id) ||
      (this.props.clinicalIndicators.length > 0 &&
        this.state.clinicalIndicatorSelectValue === null &&
        this.props.medicalRecordEntity.clinicalIndicators)
    ) {
      this.setState({
        clinicalIndicatorSelectValue:
          this.props.medicalRecordEntity && this.props.medicalRecordEntity.clinicalIndicators
            ? this.props.medicalRecordEntity.clinicalIndicators.filter(p => p).map(p => ({ value: p.id, label: p['name'] ? p.name : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.medicalRecordEntity && prevProps.medicalRecordEntity.id !== this.props.medicalRecordEntity.id) ||
      (this.props.patients &&
        this.state.patientSelectValue === null &&
        this.props.medicalRecordEntity.patient &&
        this.props.medicalRecordEntity.patient.id)
    ) {
      const p = this.props.medicalRecordEntity && this.props.medicalRecordEntity.patient ? this.props.medicalRecordEntity.patient : null;
      this.setState({
        patientSelectValue: p ? { ...p, value: p.id, label: p['id'] ? p.id : '' } : null,
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.medicalRecordEntity && prevProps.medicalRecordEntity.id !== this.props.medicalRecordEntity.id) ||
      (this.props.specialties &&
        this.state.specialtySelectValue === null &&
        this.props.medicalRecordEntity.specialty &&
        this.props.medicalRecordEntity.specialty.id)
    ) {
      const p =
        this.props.medicalRecordEntity && this.props.medicalRecordEntity.specialty ? this.props.medicalRecordEntity.specialty : null;
      this.setState({
        specialtySelectValue: p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : null,
      });
    }

    if (
      (prevProps.medicalRecordEntity && prevProps.medicalRecordEntity.id !== this.props.medicalRecordEntity.id) ||
      (this.props.medicalRecordEntity.passageReason &&
        this.state.passageReasonSelectValue === null &&
        this.props.medicalRecordEntity.passageReason !== this.state.passageReasonSelectValue)
    ) {
      this.setState({
        passageReasonSelectValue: this.props.medicalRecordEntity.passageReason ? this.props.medicalRecordEntity.passageReason : null,
      });
    }
    if (
      (prevProps.medicalRecordEntity && prevProps.medicalRecordEntity.id !== this.props.medicalRecordEntity.id) ||
      (this.props.medicalRecordEntity.fall &&
        this.state.fallSelectValue === null &&
        this.props.medicalRecordEntity.fall !== this.state.fallSelectValue)
    ) {
      this.setState({ fallSelectValue: this.props.medicalRecordEntity.fall ? this.props.medicalRecordEntity.fall : null });
    }
    if (
      (prevProps.medicalRecordEntity && prevProps.medicalRecordEntity.id !== this.props.medicalRecordEntity.id) ||
      (this.props.medicalRecordEntity.clinicalInstability &&
        this.state.clinicalInstabilitySelectValue === null &&
        this.props.medicalRecordEntity.clinicalInstability !== this.state.clinicalInstabilitySelectValue)
    ) {
      this.setState({
        clinicalInstabilitySelectValue: this.props.medicalRecordEntity.clinicalInstability
          ? this.props.medicalRecordEntity.clinicalInstability
          : null,
      });
    }
    if (
      (prevProps.medicalRecordEntity && prevProps.medicalRecordEntity.id !== this.props.medicalRecordEntity.id) ||
      (this.props.medicalRecordEntity.exam &&
        this.state.examSelectValue === null &&
        this.props.medicalRecordEntity.exam !== this.state.examSelectValue)
    ) {
      this.setState({ examSelectValue: this.props.medicalRecordEntity.exam ? this.props.medicalRecordEntity.exam : null });
    }
    if (
      (prevProps.medicalRecordEntity && prevProps.medicalRecordEntity.id !== this.props.medicalRecordEntity.id) ||
      (this.props.medicalRecordEntity.examType &&
        this.state.examTypeSelectValue === null &&
        this.props.medicalRecordEntity.examType !== this.state.examTypeSelectValue)
    ) {
      this.setState({ examTypeSelectValue: this.props.medicalRecordEntity.examType ? this.props.medicalRecordEntity.examType : null });
    }
    if (
      (prevProps.medicalRecordEntity && prevProps.medicalRecordEntity.id !== this.props.medicalRecordEntity.id) ||
      (this.props.medicalRecordEntity.hospitalizationDate &&
        this.state.hospitalizationDateSelectValue === null &&
        this.props.medicalRecordEntity.hospitalizationDate !== this.state.hospitalizationDateSelectValue)
    ) {
      this.setState({
        hospitalizationDateSelectValue: this.props.medicalRecordEntity.hospitalizationDate
          ? this.props.medicalRecordEntity.hospitalizationDate
          : null,
      });
    }
    if (
      (prevProps.medicalRecordEntity && prevProps.medicalRecordEntity.id !== this.props.medicalRecordEntity.id) ||
      (this.props.medicalRecordEntity.dischargeDate &&
        this.state.dischargeDateSelectValue === null &&
        this.props.medicalRecordEntity.dischargeDate !== this.state.dischargeDateSelectValue)
    ) {
      this.setState({
        dischargeDateSelectValue: this.props.medicalRecordEntity.dischargeDate ? this.props.medicalRecordEntity.dischargeDate : null,
      });
    }
    if (
      (prevProps.medicalRecordEntity && prevProps.medicalRecordEntity.id !== this.props.medicalRecordEntity.id) ||
      (this.props.medicalRecordEntity.hospitalizationReason &&
        this.state.hospitalizationReasonSelectValue === null &&
        this.props.medicalRecordEntity.hospitalizationReason !== this.state.hospitalizationReasonSelectValue)
    ) {
      this.setState({
        hospitalizationReasonSelectValue: this.props.medicalRecordEntity.hospitalizationReason
          ? this.props.medicalRecordEntity.hospitalizationReason
          : null,
      });
    }
    if (
      (prevProps.medicalRecordEntity && prevProps.medicalRecordEntity.id !== this.props.medicalRecordEntity.id) ||
      (this.props.medicalRecordEntity.occurrence &&
        this.state.occurrenceSelectValue === null &&
        this.props.medicalRecordEntity.occurrence !== this.state.occurrenceSelectValue)
    ) {
      this.setState({
        occurrenceSelectValue: this.props.medicalRecordEntity.occurrence ? this.props.medicalRecordEntity.occurrence : null,
      });
    }
    if (
      (prevProps.medicalRecordEntity && prevProps.medicalRecordEntity.id !== this.props.medicalRecordEntity.id) ||
      (this.props.medicalRecordEntity.manifestation &&
        this.state.manifestationSelectValue === null &&
        this.props.medicalRecordEntity.manifestation !== this.state.manifestationSelectValue)
    ) {
      this.setState({
        manifestationSelectValue: this.props.medicalRecordEntity.manifestation ? this.props.medicalRecordEntity.manifestation : null,
      });
    }
    if (
      (prevProps.medicalRecordEntity && prevProps.medicalRecordEntity.id !== this.props.medicalRecordEntity.id) ||
      (this.props.medicalRecordEntity.compliment &&
        this.state.complimentSelectValue === null &&
        this.props.medicalRecordEntity.compliment !== this.state.complimentSelectValue)
    ) {
      this.setState({
        complimentSelectValue: this.props.medicalRecordEntity.compliment ? this.props.medicalRecordEntity.compliment : null,
      });
    }
    if (
      (prevProps.medicalRecordEntity && prevProps.medicalRecordEntity.id !== this.props.medicalRecordEntity.id) ||
      (this.props.medicalRecordEntity.complaint &&
        this.state.complaintSelectValue === null &&
        this.props.medicalRecordEntity.complaint !== this.state.complaintSelectValue)
    ) {
      this.setState({ complaintSelectValue: this.props.medicalRecordEntity.complaint ? this.props.medicalRecordEntity.complaint : null });
    }
    if (
      (prevProps.medicalRecordEntity && prevProps.medicalRecordEntity.id !== this.props.medicalRecordEntity.id) ||
      (this.props.medicalRecordEntity.suggestion &&
        this.state.suggestionSelectValue === null &&
        this.props.medicalRecordEntity.suggestion !== this.state.suggestionSelectValue)
    ) {
      this.setState({
        suggestionSelectValue: this.props.medicalRecordEntity.suggestion ? this.props.medicalRecordEntity.suggestion : null,
      });
    }
    if (
      (prevProps.medicalRecordEntity && prevProps.medicalRecordEntity.id !== this.props.medicalRecordEntity.id) ||
      (this.props.medicalRecordEntity.additionalInformation &&
        this.state.additionalInformationSelectValue === null &&
        this.props.medicalRecordEntity.additionalInformation !== this.state.additionalInformationSelectValue)
    ) {
      this.setState({
        additionalInformationSelectValue: this.props.medicalRecordEntity.additionalInformation
          ? this.props.medicalRecordEntity.additionalInformation
          : null,
      });
    }
    if (
      (prevProps.medicalRecordEntity && prevProps.medicalRecordEntity.id !== this.props.medicalRecordEntity.id) ||
      (this.props.medicalRecordEntity.type &&
        this.state.typeSelectValue === null &&
        this.props.medicalRecordEntity.type !== this.state.typeSelectValue)
    ) {
      this.setState({ typeSelectValue: this.props.medicalRecordEntity.type ? this.props.medicalRecordEntity.type : null });
    }
    if (
      (prevProps.medicalRecordEntity && prevProps.medicalRecordEntity.id !== this.props.medicalRecordEntity.id) ||
      (this.props.medicalRecordEntity.date &&
        this.state.dateSelectValue === null &&
        this.props.medicalRecordEntity.date !== this.state.dateSelectValue)
    ) {
      this.setState({ dateSelectValue: this.props.medicalRecordEntity.date ? this.props.medicalRecordEntity.date : null });
    }
    if (
      (prevProps.medicalRecordEntity && prevProps.medicalRecordEntity.id !== this.props.medicalRecordEntity.id) ||
      (this.props.medicalRecordEntity.reason &&
        this.state.reasonSelectValue === null &&
        this.props.medicalRecordEntity.reason !== this.state.reasonSelectValue)
    ) {
      this.setState({ reasonSelectValue: this.props.medicalRecordEntity.reason ? this.props.medicalRecordEntity.reason : null });
    }
    if (
      (prevProps.medicalRecordEntity && prevProps.medicalRecordEntity.id !== this.props.medicalRecordEntity.id) ||
      (this.props.medicalRecordEntity.conduct &&
        this.state.conductSelectValue === null &&
        this.props.medicalRecordEntity.conduct !== this.state.conductSelectValue)
    ) {
      this.setState({ conductSelectValue: this.props.medicalRecordEntity.conduct ? this.props.medicalRecordEntity.conduct : null });
    }
    if (
      (prevProps.medicalRecordEntity && prevProps.medicalRecordEntity.id !== this.props.medicalRecordEntity.id) ||
      (this.state.recordSelectValue === null && this.props.medicalRecordEntity.record !== this.state.recordSelectValue)
    ) {
      this.setState({ recordSelectValue: this.props.medicalRecordEntity.record ? this.props.medicalRecordEntity.record : false });
    }
    if (
      (prevProps.medicalRecordEntity && prevProps.medicalRecordEntity.id !== this.props.medicalRecordEntity.id) ||
      (this.state.highlightedSelectValue === null && this.props.medicalRecordEntity.highlighted !== this.state.highlightedSelectValue)
    ) {
      this.setState({
        highlightedSelectValue: this.props.medicalRecordEntity.highlighted ? this.props.medicalRecordEntity.highlighted : false,
      });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  UNSAFE_componentWillMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params['id']);
    }
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = offset !== null ? '&offset=' + offset : '';
    Object.keys(fieldsBase).map(key => {
      url += key !== 'offset' ? '&' + key + '=' + fieldsBase[key] : '';
    });
    return url;
  };
  saveEntity = (event: any, errors: any, values: any) => {
    if (!this.state.typeSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.medicalRecord.type') }));
      return false;
    }

    const selectFieldsList = [
      'passageReason',
      'fall',
      'clinicalInstability',
      'exam',
      'examType',
      'hospitalizationDate',
      'dischargeDate',
      'hospitalizationReason',
      'occurrence',
      'manifestation',
      'compliment',
      'complaint',
      'suggestion',
      'additionalInformation',
      'type',
      'date',
      'reason',
      'conduct',
      'patient.id',
      'specialty.name',
    ];

    values.hospitalizationDate = convertDateTimeToServer(values.hospitalizationDate);
    values.dischargeDate = convertDateTimeToServer(values.dischargeDate);
    values.date = convertDateTimeToServer(values.date);

    if (errors.length === 0) {
      const { medicalRecordEntity } = this.props;
      const _entity = {
        ...medicalRecordEntity,
        // ...values,

        patient: getFormFromSelect(this.state.patientSelectValue, 'many-to-one'), // many-to-one - otherSide

        configTags: getFormFromSelect(this.state.configTagSelectValue, 'many-to-many'), // many-to-many - ownerSide

        clinicalIndicators: getFormFromSelect(this.state.clinicalIndicatorSelectValue, 'many-to-many'), // many-to-many - ownerSide

        passageReason: this.state.passageReasonSelectValue,
        fall: this.state.fallSelectValue,
        clinicalInstability: this.state.clinicalInstabilitySelectValue,
        exam: this.state.examSelectValue === null ? null : this.state.examSelectValue,
        examType: this.state.examTypeSelectValue === null ? null : this.state.examTypeSelectValue,
        hospitalizationDate: this.state.hospitalizationDateSelectValue,
        dischargeDate: this.state.dischargeDateSelectValue,
        hospitalizationReason: this.state.hospitalizationReasonSelectValue,
        occurrence: this.state.occurrenceSelectValue === null ? null : this.state.occurrenceSelectValue,
        manifestation: this.state.manifestationSelectValue === null ? null : this.state.manifestationSelectValue,
        compliment: this.state.complimentSelectValue,
        complaint: this.state.complaintSelectValue,
        suggestion: this.state.suggestionSelectValue,
        additionalInformation: this.state.additionalInformationSelectValue,
        type: this.state.typeSelectValue,
        date: this.state.dateSelectValue,
        reason: this.state.reasonSelectValue,
        conduct: this.state.conductSelectValue,
        record: this.state.recordSelectValue === null ? false : this.state.recordSelectValue,
        highlighted: this.state.highlightedSelectValue === null ? false : this.state.highlightedSelectValue,
      };

      const entity = _entity;

      const { patientId, specialtyId, extraFilters, activePage, itemsPerPage, sort, order } = this.state.fieldsBase;

      if (this.state.isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              getFilterFromSelect(patientId, 'many-to-one'),
              getFilterFromSelect(specialtyId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              getFilterFromSelect(patientId, 'many-to-one'),
              getFilterFromSelect(specialtyId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/medical-record?' + getEntityFiltersURL(this.state.fieldsBase));
  };

  getFullEntityFromState(v) {
    return {
      id: v.id,
      passageReason: this.state.passageReasonSelectValue,
      fall: this.state.fallSelectValue,
      clinicalInstability: this.state.clinicalInstabilitySelectValue,
      exam: this.state.examSelectValue,
      examType: this.state.examTypeSelectValue,
      hospitalizationDate: this.state.hospitalizationDateSelectValue,
      dischargeDate: this.state.dischargeDateSelectValue,
      hospitalizationReason: this.state.hospitalizationReasonSelectValue,
      occurrence: this.state.occurrenceSelectValue,
      manifestation: this.state.manifestationSelectValue,
      compliment: this.state.complimentSelectValue,
      complaint: this.state.complaintSelectValue,
      suggestion: this.state.suggestionSelectValue,
      additionalInformation: this.state.additionalInformationSelectValue,
      type: this.state.typeSelectValue,
      date: this.state.dateSelectValue,
      reason: this.state.reasonSelectValue,
      conduct: this.state.conductSelectValue,
      record: this.state.recordSelectValue,
      highlighted: this.state.highlightedSelectValue,
      medicalRecordFile: this.state.medicalRecordFileSelectValue,
      configTag: this.state.configTagSelectValue,
      clinicalIndicator: this.state.clinicalIndicatorSelectValue,
      patient: this.state.patientSelectValue,
      specialty: this.state.specialtySelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    const medicalRecordFileEntity = v.medicalRecordFile;

    const configTagEntity = this.props.configTags.filter(x => x.medicalRecords.map(x1 => x1.id).includes(v.id)).pop();

    const clinicalIndicatorEntity = this.props.clinicalIndicators.filter(x => x.medicalRecords.map(x1 => x1.id).includes(v.id)).pop();

    const patientEntity = v.patient;

    const specialtyEntity = v.specialty;

    return {
      passageReasonSelectValue: v.passageReason,
      fallSelectValue: v.fall,
      clinicalInstabilitySelectValue: v.clinicalInstability,
      examSelectValue: v.exam,
      examTypeSelectValue: v.examType,
      hospitalizationDateSelectValue: v.hospitalizationDate,
      dischargeDateSelectValue: v.dischargeDate,
      hospitalizationReasonSelectValue: v.hospitalizationReason,
      occurrenceSelectValue: v.occurrence,
      manifestationSelectValue: v.manifestation,
      complimentSelectValue: v.compliment,
      complaintSelectValue: v.complaint,
      suggestionSelectValue: v.suggestion,
      additionalInformationSelectValue: v.additionalInformation,
      typeSelectValue: v.type,
      dateSelectValue: v.date,
      reasonSelectValue: v.reason,
      conductSelectValue: v.conduct,
      recordSelectValue: v.record,
      highlightedSelectValue: v.highlighted,
      medicalRecordFileSelectValue: medicalRecordFileEntity ? medicalRecordFileEntity['id'] : null,
      configTagSelectValue: configTagEntity ? configTagEntity['id'] : null,
      clinicalIndicatorSelectValue: clinicalIndicatorEntity ? clinicalIndicatorEntity['id'] : null,
      patientSelectValue: patientEntity ? patientEntity['id'] : null,
      specialtySelectValue: specialtyEntity ? specialtyEntity['id'] : null,
    };
  }

  renderHeader() {
    const {
      medicalRecordEntity,
      medicalRecordFiles,
      configTags,
      clinicalIndicators,
      patients,
      specialties,
      loading,
      updating,
    } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.medicalRecord.home.createLabel">Create a MedicalRecord</Translate>
            ) : (
              <Translate contentKey="generadorApp.medicalRecord.home.editLabel">Edit a MedicalRecord</Translate>
            )}
          </span>

          <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.medicalRecord.btnSave">Save</Translate>
          </Button>

          {!this.state.isNew ? (
            <>
              <Button
                color="primary"
                id="next-entity"
                disabled={updating}
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.patientId,
                    this.state.fieldsBase.specialtyId,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 + 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 + 1 },
                    });
                    this.props.history.push(
                      '/medical-record/' +
                        myresult.value.data[0]['id'] +
                        '/edit?' +
                        this.getFiltersURL(this.state.fieldsBase.offset * 1 + 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.last.item', { fieldName: translate('generadorApp.medicalRecord.detail.title') }));
                  }
                }}
                className="float-right jh-create-entity"
              >
                {'>>'}
              </Button>

              <Button
                color="primary"
                id="prev-entity"
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.patientId,
                    this.state.fieldsBase.specialtyId,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 - 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 - 1 },
                    });
                    this.props.history.push(
                      '/medical-record/' +
                        myresult.value.data[0]['id'] +
                        '/edit?' +
                        this.getFiltersURL(this.state.fieldsBase.offset * 1 - 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.first.item', { fieldName: translate('generadorApp.medicalRecord.detail.title') }));
                  }
                }}
                disabled={updating}
                className="float-right jh-create-entity"
              >
                {'<<'}
              </Button>
            </>
          ) : (
            <> </>
          )}

          <Button
            tag={Link}
            id="cancel-save"
            to={'/medical-record?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.medicalRecord.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={'/medical-record'}>
              <Translate contentKey="generadorApp.medicalRecord.home.title">Medical Records</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.medicalRecord.detail.title">Medical Record edit</Translate>
          </li>
        </ol>
      </>
    );
  }
  renderBody() {
    const {
      medicalRecordEntity,
      medicalRecordFiles,
      configTags,
      clinicalIndicators,
      patients,
      specialties,
      loading,
      updating,
    } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const fullEntityEmbebed = this.getFullEntityFromState(medicalRecordEntity);

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="medical-record-id">
                  <Translate contentKey="generadorApp.medicalRecord.field_id">ID</Translate>
                </Label>
                </Col> */}
                      <Col md="12">
                        <AvInput id="medical-record-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'patient' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="medical-record-patient">
                              <Translate contentKey="generadorApp.medicalRecord.patient">Patient</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="medical-record-patient"
                              name={'patient'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.patientSelectValue ? this.state.patientSelectValue : ''}
                              onChange={options => this.setState({ patientSelectValue: options })}
                              defaultOptions={this.state.patientStartSelectOptions ? this.state.patientStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.patientStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.patientStartSelectOptions === undefined) {
                                  const result = await getListAxios('patients', {}, 0, 100, 'id,asc', 'id');
                                  this.setState({
                                    patientStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios('patients', { 'id.contains': inputValue }, 0, 100, 'id,asc', 'id');
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'id') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="patient"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}

                  {baseFilters !== 'date' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="dateLabel" for="medical-record-date">
                              <Translate contentKey="generadorApp.medicalRecord.date">Date of occurrence</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <DatePicker
                              autoComplete="off"
                              onChange={value => this.setState({ dateSelectValue: value })}
                              id="date"
                              validate={{}}
                              className="form-control react-datepicker"
                              name="date"
                              dateFormat="dd/MM/yyyy"
                              selected={this.state.dateSelectValue ? moment(this.state.dateSelectValue).toDate() : null}
                              locale="pt"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ dateSelectValue: evt.target.value })}
                      type="hidden"
                      name="date"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'record' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="recordLabel" for="medical-record-record">
                              <Translate contentKey="generadorApp.medicalRecord.record">Medical record</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'record_checkbox'}
                                id={'record_checkbox'}
                                checked={this.state.recordSelectValue}
                                onChange={evt => this.setState({ recordSelectValue: !this.state.recordSelectValue })}
                              />
                              <label htmlFor={'record_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ recordSelectValue: evt.target.value })}
                      type="hidden"
                      name="record"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'configTag' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="medical-record-configTag">
                              <Translate contentKey="generadorApp.medicalRecord.configTag">Config Tag</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              isMulti
                              id="medical-record-configTag"
                              name={'configTag'}
                              className={'css-select-control'}
                              data-type-rel="many-to-many-owner-side"
                              value={this.state.configTagSelectValue ? this.state.configTagSelectValue : ''}
                              onChange={options => this.setState({ configTagSelectValue: options })}
                              defaultOptions={this.state.configTagStartSelectOptions ? this.state.configTagStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.configTagStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.configTagStartSelectOptions === undefined) {
                                  const result = await getListAxios(
                                    'config-tags',
                                    { 'isMedicalRecord.equals': true },
                                    0,
                                    100,
                                    'name,asc',
                                    'name'
                                  );
                                  this.setState({
                                    configTagStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'config-tags',
                                  { 'isMedicalRecord.equals': true, 'name.contains': inputValue },
                                  0,
                                  100,
                                  'name,asc',
                                  'name'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="configTag"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}

                  {baseFilters !== 'clinicalIndicator' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="medical-record-clinicalIndicator">
                              <Translate contentKey="generadorApp.medicalRecord.clinicalIndicator">Clinical Indicator</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              isMulti
                              id="medical-record-clinicalIndicator"
                              name={'clinicalIndicator'}
                              className={'css-select-control'}
                              data-type-rel="many-to-many-owner-side"
                              value={this.state.clinicalIndicatorSelectValue ? this.state.clinicalIndicatorSelectValue : ''}
                              onChange={options => this.setState({ clinicalIndicatorSelectValue: options })}
                              defaultOptions={
                                this.state.clinicalIndicatorStartSelectOptions ? this.state.clinicalIndicatorStartSelectOptions : []
                              }
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.clinicalIndicatorStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.clinicalIndicatorStartSelectOptions === undefined) {
                                  const result = await getListAxios('clinical-indicators', {}, 0, 100, 'name,asc', 'name');
                                  this.setState({
                                    clinicalIndicatorStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'clinical-indicators',
                                  { 'name.contains': inputValue },
                                  0,
                                  100,
                                  'name,asc',
                                  'name'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="clinicalIndicator"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}

                  {baseFilters !== 'passageReason' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="passageReasonLabel" for="medical-record-passageReason">
                              <Translate contentKey="generadorApp.medicalRecord.passageReason">Passage Reason</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ passageReasonSelectValue: evt.target.value })}
                              value={this.state.passageReasonSelectValue ? this.state.passageReasonSelectValue : ''}
                              id="medical-record-passageReason"
                              type="text"
                              name="passageReason"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ passageReasonSelectValue: evt.target.value })}
                      type="hidden"
                      name="passageReason"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'fall' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="fallLabel" for="medical-record-fall">
                              <Translate contentKey="generadorApp.medicalRecord.fall">Fall</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ fallSelectValue: evt.target.value })}
                              value={this.state.fallSelectValue ? this.state.fallSelectValue : ''}
                              id="medical-record-fall"
                              type="text"
                              name="fall"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ fallSelectValue: evt.target.value })}
                      type="hidden"
                      name="fall"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'clinicalInstability' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label
                              className="mt-2 label-single-line"
                              id="clinicalInstabilityLabel"
                              for="medical-record-clinicalInstability"
                            >
                              <Translate contentKey="generadorApp.medicalRecord.clinicalInstability">Clinical Instability</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ clinicalInstabilitySelectValue: evt.target.value })}
                              value={this.state.clinicalInstabilitySelectValue ? this.state.clinicalInstabilitySelectValue : ''}
                              id="medical-record-clinicalInstability"
                              type="text"
                              name="clinicalInstability"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ clinicalInstabilitySelectValue: evt.target.value })}
                      type="hidden"
                      name="clinicalInstability"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'exam' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="examLabel" for="medical-record-exam">
                              <Translate contentKey="generadorApp.medicalRecord.exam">Exam</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="medical-record-exam"
                              className={'css-select-control'}
                              value={
                                this.state.examSelectValue
                                  ? {
                                      value: this.state.examSelectValue,
                                      label: translate('generadorApp.Exam.' + this.state.examSelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.medicalRecord.exam') }
                              }
                              options={[
                                { value: 'CLINICO', label: translate('generadorApp.Exam.CLINICO') },
                                { value: 'IMAGEM', label: translate('generadorApp.Exam.IMAGEM') },
                              ]}
                              onChange={(options: any) => this.setState({ examSelectValue: options['value'] })}
                              name="exam"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ examSelectValue: evt.target.value })}
                      type="hidden"
                      name="exam"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'examType' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="examTypeLabel" for="medical-record-examType">
                              <Translate contentKey="generadorApp.medicalRecord.examType">Exam Type</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="medical-record-examType"
                              className={'css-select-control'}
                              value={
                                this.state.examTypeSelectValue
                                  ? {
                                      value: this.state.examTypeSelectValue,
                                      label: translate('generadorApp.ExamType.' + this.state.examTypeSelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.medicalRecord.examType') }
                              }
                              options={[
                                { value: 'RAIOX', label: translate('generadorApp.ExamType.RAIOX') },
                                { value: 'ULTRASSONOGRAFIA', label: translate('generadorApp.ExamType.ULTRASSONOGRAFIA') },
                                { value: 'TOMOGRAFIACOMPUTADORIZADA', label: translate('generadorApp.ExamType.TOMOGRAFIACOMPUTADORIZADA') },
                                { value: 'RESSONANCIAMAGNETICA', label: translate('generadorApp.ExamType.RESSONANCIAMAGNETICA') },
                                { value: 'ENDOSCOPIA', label: translate('generadorApp.ExamType.ENDOSCOPIA') },
                                { value: 'COLONOSCOPIA', label: translate('generadorApp.ExamType.COLONOSCOPIA') },
                                { value: 'SANGUE', label: translate('generadorApp.ExamType.SANGUE') },
                                { value: 'URINA', label: translate('generadorApp.ExamType.URINA') },
                              ]}
                              onChange={(options: any) => this.setState({ examTypeSelectValue: options['value'] })}
                              name="examType"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ examTypeSelectValue: evt.target.value })}
                      type="hidden"
                      name="examType"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'hospitalizationDate' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label
                              className="mt-2 label-single-line"
                              id="hospitalizationDateLabel"
                              for="medical-record-hospitalizationDate"
                            >
                              <Translate contentKey="generadorApp.medicalRecord.hospitalizationDate">Hospitalization Date</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <DatePicker
                              autoComplete="off"
                              onChange={value => this.setState({ hospitalizationDateSelectValue: value })}
                              id="hospitalizationDate"
                              validate={{}}
                              className="form-control react-datepicker"
                              name="hospitalizationDate"
                              dateFormat="dd/MM/yyyy"
                              selected={
                                this.state.hospitalizationDateSelectValue
                                  ? moment(this.state.hospitalizationDateSelectValue).toDate()
                                  : null
                              }
                              locale="pt"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ hospitalizationDateSelectValue: evt.target.value })}
                      type="hidden"
                      name="hospitalizationDate"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'dischargeDate' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="dischargeDateLabel" for="medical-record-dischargeDate">
                              <Translate contentKey="generadorApp.medicalRecord.dischargeDate">Discharge Date</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <DatePicker
                              autoComplete="off"
                              onChange={value => this.setState({ dischargeDateSelectValue: value })}
                              id="dischargeDate"
                              validate={{}}
                              className="form-control react-datepicker"
                              name="dischargeDate"
                              dateFormat="dd/MM/yyyy"
                              selected={this.state.dischargeDateSelectValue ? moment(this.state.dischargeDateSelectValue).toDate() : null}
                              locale="pt"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ dischargeDateSelectValue: evt.target.value })}
                      type="hidden"
                      name="dischargeDate"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'hospitalizationReason' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label
                              className="mt-2 label-single-line"
                              id="hospitalizationReasonLabel"
                              for="medical-record-hospitalizationReason"
                            >
                              <Translate contentKey="generadorApp.medicalRecord.hospitalizationReason">Hospitalization Reason</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ hospitalizationReasonSelectValue: evt.target.value })}
                              value={this.state.hospitalizationReasonSelectValue ? this.state.hospitalizationReasonSelectValue : ''}
                              id="medical-record-hospitalizationReason"
                              type="text"
                              name="hospitalizationReason"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ hospitalizationReasonSelectValue: evt.target.value })}
                      type="hidden"
                      name="hospitalizationReason"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'occurrence' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="occurrenceLabel" for="medical-record-occurrence">
                              <Translate contentKey="generadorApp.medicalRecord.occurrence">Occurrence</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="medical-record-occurrence"
                              className={'css-select-control'}
                              value={
                                this.state.occurrenceSelectValue
                                  ? {
                                      value: this.state.occurrenceSelectValue,
                                      label: translate('generadorApp.Ocurrence.' + this.state.occurrenceSelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.medicalRecord.occurrence') }
                              }
                              options={[
                                { value: 'SONDANASOENTERAL', label: translate('generadorApp.Ocurrence.SONDANASOENTERAL') },
                                { value: 'TRAQUEOSTOMIA', label: translate('generadorApp.Ocurrence.TRAQUEOSTOMIA') },
                                { value: 'GASTROSTOMIA', label: translate('generadorApp.Ocurrence.GASTROSTOMIA') },
                                { value: 'SONDAVESICALDEDEMORA', label: translate('generadorApp.Ocurrence.SONDAVESICALDEDEMORA') },
                                { value: 'SONDAVESICALDEALIVIO', label: translate('generadorApp.Ocurrence.SONDAVESICALDEALIVIO') },
                                { value: 'PICC', label: translate('generadorApp.Ocurrence.PICC') },
                                {
                                  value: 'INTERVENCAOCOMANTIBIOTICOTERAPIA',
                                  label: translate('generadorApp.Ocurrence.INTERVENCAOCOMANTIBIOTICOTERAPIA'),
                                },
                                {
                                  value: 'INTERVENCAOCOMOUTROSMEDICAMENTOS',
                                  label: translate('generadorApp.Ocurrence.INTERVENCAOCOMOUTROSMEDICAMENTOS'),
                                },
                                { value: 'APH', label: translate('generadorApp.Ocurrence.APH') },
                                { value: 'RAIOX', label: translate('generadorApp.Ocurrence.RAIOX') },
                                {
                                  value: 'COLETADEEXAMESLABORATORIAISEMDOMICILIO',
                                  label: translate('generadorApp.Ocurrence.COLETADEEXAMESLABORATORIAISEMDOMICILIO'),
                                },
                                { value: 'OUTROSEXAMESEMDOMICILIO', label: translate('generadorApp.Ocurrence.OUTROSEXAMESEMDOMICILIO') },
                              ]}
                              onChange={(options: any) => this.setState({ occurrenceSelectValue: options['value'] })}
                              name="occurrence"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ occurrenceSelectValue: evt.target.value })}
                      type="hidden"
                      name="occurrence"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'manifestation' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="manifestationLabel" for="medical-record-manifestation">
                              <Translate contentKey="generadorApp.medicalRecord.manifestation">Manifestation</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="medical-record-manifestation"
                              className={'css-select-control'}
                              value={
                                this.state.manifestationSelectValue
                                  ? {
                                      value: this.state.manifestationSelectValue,
                                      label: translate('generadorApp.Manifestation.' + this.state.manifestationSelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.medicalRecord.manifestation') }
                              }
                              options={[
                                { value: 'ELOGIO', label: translate('generadorApp.Manifestation.ELOGIO') },
                                { value: 'RECLAMACAO', label: translate('generadorApp.Manifestation.RECLAMACAO') },
                              ]}
                              onChange={(options: any) => this.setState({ manifestationSelectValue: options['value'] })}
                              name="manifestation"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ manifestationSelectValue: evt.target.value })}
                      type="hidden"
                      name="manifestation"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'compliment' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="complimentLabel" for="medical-record-compliment">
                              <Translate contentKey="generadorApp.medicalRecord.compliment">Compliment</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ complimentSelectValue: evt.target.value })}
                              value={this.state.complimentSelectValue ? this.state.complimentSelectValue : ''}
                              id="medical-record-compliment"
                              type="text"
                              name="compliment"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ complimentSelectValue: evt.target.value })}
                      type="hidden"
                      name="compliment"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'complaint' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="complaintLabel" for="medical-record-complaint">
                              <Translate contentKey="generadorApp.medicalRecord.complaint">Complaint</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ complaintSelectValue: evt.target.value })}
                              value={this.state.complaintSelectValue ? this.state.complaintSelectValue : ''}
                              id="medical-record-complaint"
                              type="text"
                              name="complaint"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ complaintSelectValue: evt.target.value })}
                      type="hidden"
                      name="complaint"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'suggestion' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="suggestionLabel" for="medical-record-suggestion">
                              <Translate contentKey="generadorApp.medicalRecord.suggestion">Suggestion</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ suggestionSelectValue: evt.target.value })}
                              value={this.state.suggestionSelectValue ? this.state.suggestionSelectValue : ''}
                              id="medical-record-suggestion"
                              type="text"
                              name="suggestion"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ suggestionSelectValue: evt.target.value })}
                      type="hidden"
                      name="suggestion"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'additionalInformation' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label
                              className="mt-2 label-single-line"
                              id="additionalInformationLabel"
                              for="medical-record-additionalInformation"
                            >
                              <Translate contentKey="generadorApp.medicalRecord.additionalInformation">Additional Information</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ additionalInformationSelectValue: evt.target.value })}
                              value={this.state.additionalInformationSelectValue ? this.state.additionalInformationSelectValue : ''}
                              id="medical-record-additionalInformation"
                              type="text"
                              name="additionalInformation"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ additionalInformationSelectValue: evt.target.value })}
                      type="hidden"
                      name="additionalInformation"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'type' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="typeLabel" for="medical-record-type">
                              <Translate contentKey="generadorApp.medicalRecord.type">Type</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ typeSelectValue: evt.target.value })}
                              value={this.state.typeSelectValue ? this.state.typeSelectValue : ''}
                              id="medical-record-type"
                              type="text"
                              name="type"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ typeSelectValue: evt.target.value })}
                      type="hidden"
                      name="type"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'reason' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="reasonLabel" for="medical-record-reason">
                              <Translate contentKey="generadorApp.medicalRecord.reason">Reason</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ reasonSelectValue: evt.target.value })}
                              value={this.state.reasonSelectValue ? this.state.reasonSelectValue : ''}
                              id="medical-record-reason"
                              type="text"
                              name="reason"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ reasonSelectValue: evt.target.value })}
                      type="hidden"
                      name="reason"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'conduct' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="conductLabel" for="medical-record-conduct">
                              <Translate contentKey="generadorApp.medicalRecord.conduct">Conduct</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ conductSelectValue: evt.target.value })}
                              value={this.state.conductSelectValue ? this.state.conductSelectValue : ''}
                              id="medical-record-conduct"
                              type="text"
                              name="conduct"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ conductSelectValue: evt.target.value })}
                      type="hidden"
                      name="conduct"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'highlighted' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="highlightedLabel" for="medical-record-highlighted">
                              <Translate contentKey="generadorApp.medicalRecord.highlighted">Highlighted</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'highlighted_checkbox'}
                                id={'highlighted_checkbox'}
                                checked={this.state.highlightedSelectValue}
                                onChange={evt => this.setState({ highlightedSelectValue: !this.state.highlightedSelectValue })}
                              />
                              <label htmlFor={'highlighted_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ highlightedSelectValue: evt.target.value })}
                      type="hidden"
                      name="highlighted"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const {
      medicalRecordEntity,
      medicalRecordFiles,
      configTags,
      clinicalIndicators,
      patients,
      specialties,
      loading,
      updating,
    } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...medicalRecordEntity,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel className="medical-record medical-record-update">
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToProps = (storeState: IRootState) => {
  return {
    medicalRecordFiles: storeState.medicalRecordFile.entities,
    configTags: storeState.configTag.entities,
    clinicalIndicators: storeState.clinicalIndicator.entities,
    patients: storeState.patient.entities,
    specialties: storeState.specialty.entities,
    medicalRecordEntity: storeState.medicalRecord.entity,
    loading: storeState.medicalRecord.loading,
    updating: storeState.medicalRecord.updating,
    updateSuccess: storeState.medicalRecord.updateSuccess,
  };
};

export const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  getEntityOffset,
  reset,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MedicalRecordUpdate);
