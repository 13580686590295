/* eslint complexity: ["error", 500] */
/* eslint no-empty: off */
import React from 'react';
import { connect } from 'react-redux';

import SelectAsync from 'react-select/async';

import { Link, RouteComponentProps } from 'react-router-dom';

import ReactQuill from 'react-quill';

import {
  quillEditorModules,
  quillEditorFormats,
  getFilterFromSelect,
  getFormFromSelect,
  getListAxios,
  showFieldsSelectAsync,
} from 'app/shared/util/entity-utils';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Col, Label } from 'reactstrap';
import { setFileData } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import {
  ICidPtaUpdateState as IBaseUpdateState,
  getEntity,
  getCidPtaState,
  getUrlBack,
  updateEntity,
  createEntity,
  getEntityFiltersURL,
  getEntityOffset,
  setBlob,
  reset,
  activitySetEntities,
  activityGetEntities,
  activityUpdateEntity,
  activityReset,
  activityGetEntity,
  activityCreateEntity,
  activityDeleteEntity,
} from './cid-pta.reducer';

import { RenderModalSuperSelect } from 'app/shared/layout/components/RenderModalSuperSelect';

import {
  mapStateToProps as mapStateToPropsActivity,
  mapDispatchToProps as mapDispatchToPropsActivity,
  Activity,
} from 'app/entities/activity/activity';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

registerLocale('pt', pt);

export interface ICidPtaUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}
export type ICidPtaUpdateState = IBaseUpdateState;

export class CidPtaUpdate extends React.Component<ICidPtaUpdateProps, ICidPtaUpdateState> {
  nameFileInput: any;

  expectedResultFileInput: any;
  constructor(props: Readonly<ICidPtaUpdateProps>) {
    super(props);

    this.activityUpdateEntity = this.activityUpdateEntity.bind(this);
    this.activityGetEntities = this.activityGetEntities.bind(this);
    this.activityReset = this.activityReset.bind(this);
    this.activityGetEntity = this.activityGetEntity.bind(this);
    this.activityCreateEntity = this.activityCreateEntity.bind(this);
    this.activityDeleteEntity = this.activityDeleteEntity.bind(this);

    this.expectedResultFileInput = React.createRef();

    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      functionEmbebed: [],

      activitySelectValue: null,

      padCidPtaSelectValue: null,

      cidSelectValue: null,

      nameSelectValue: null,
      expectedResultSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getCidPtaState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
      cidName: null,

      isNew: !this.props.match.params || !this.props.match.params['id'],
    };
  }

  activityUpdateEntity(...values) {
    values[0]['cidPta'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['updateEntityActivity'](...values);
    }
    return this.props.activityUpdateEntity(...values);
  }
  activityGetEntities(...values) {
    return this.props.activityGetEntities(...values);
  }
  activityReset(...values) {
    return this.props.activityReset(...values);
  }
  activityGetEntity(...values) {
    return this.props.activityGetEntity(...values);
  }
  activityCreateEntity(...values) {
    values[0]['cidPta'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['createEntityActivity'](...values);
    }
    return this.props.activityCreateEntity(...values);
  }
  activityDeleteEntity(...values) {
    if (!this.state.isNew) {
      this.props['deleteEntityActivity'](...values);
    }
    return this.props.activityDeleteEntity(...values);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateSuccess !== this.props.updateSuccess && prevProps.updateSuccess) {
      this.handleClose();
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.cidPtaEntity && prevProps.cidPtaEntity.id !== this.props.cidPtaEntity.id) ||
      (this.props.activities.length > 0 && this.state.activitySelectValue === null && this.props.cidPtaEntity.activity)
    ) {
      this.setState({ activitySelectValue: this.props.cidPtaEntity.activity });
      this.props.activitySetEntities(this.props.cidPtaEntity.activity);
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.cidPtaEntity && prevProps.cidPtaEntity.id !== this.props.cidPtaEntity.id) ||
      (this.props.padCidPtas.length > 0 && this.state.padCidPtaSelectValue === null && this.props.cidPtaEntity.padCidPta)
    ) {
      this.setState({
        padCidPtaSelectValue:
          this.props.cidPtaEntity && this.props.cidPtaEntity.padCidPta
            ? this.props.cidPtaEntity.padCidPta.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.cidPtaEntity && prevProps.cidPtaEntity.id !== this.props.cidPtaEntity.id) ||
      (this.props.cids && this.state.cidSelectValue === null && this.props.cidPtaEntity.cid && this.props.cidPtaEntity.cid.id)
    ) {
      const p = this.props.cidPtaEntity && this.props.cidPtaEntity.cid ? this.props.cidPtaEntity.cid : null;
      this.setState({
        cidSelectValue: p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : null,
      });
    }

    if (
      (prevProps.cidPtaEntity && prevProps.cidPtaEntity.id !== this.props.cidPtaEntity.id) ||
      (this.props.cidPtaEntity.name && this.state.nameSelectValue === null && this.props.cidPtaEntity.name !== this.state.nameSelectValue)
    ) {
      this.setState({ nameSelectValue: this.props.cidPtaEntity.name ? this.props.cidPtaEntity.name : null });
    }
    if (
      (prevProps.cidPtaEntity && prevProps.cidPtaEntity.id !== this.props.cidPtaEntity.id) ||
      (this.props.cidPtaEntity.expectedResult &&
        this.state.expectedResultSelectValue === null &&
        this.props.cidPtaEntity.expectedResult !== this.state.expectedResultSelectValue)
    ) {
      this.setState({ expectedResultSelectValue: this.props.cidPtaEntity.expectedResult ? this.props.cidPtaEntity.expectedResult : null });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  UNSAFE_componentWillMount() {
    if (this.state.isNew) {
      this.props.reset();
      this.activityReset();
    } else {
      this.props.getEntity(this.props.match.params['id']);
    }
  }

  onBlobChange = (isAnImage, name, fileInput) => event => {
    const fileName = event.target.files[0].name;
    setFileData(event, (contentType, data) => this.props.setBlob(name, data, contentType, fileName), isAnImage);
    const varAux = {};
    varAux[name + 'SelectValue'] = fileName;
    this.setState(varAux);
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined);
  };
  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = offset !== null ? '&offset=' + offset : '';
    Object.keys(fieldsBase).map(key => {
      url += key !== 'offset' ? '&' + key + '=' + fieldsBase[key] : '';
    });
    return url;
  };
  saveEntity = (event: any, errors: any, values: any) => {
    const selectFieldsList = ['name', 'cid.name', 'expectedResult', 'activity.'];

    if (errors.length === 0) {
      const { cidPtaEntity } = this.props;
      const _entity = {
        ...cidPtaEntity,
        // ...values,

        cid: getFormFromSelect(this.state.cidSelectValue, 'many-to-one'), // many-to-one - otherSide

        activity: undefined,

        name: this.state.nameSelectValue,
        expectedResult: this.state.expectedResultSelectValue,
      };

      const entity = _entity;

      const { cidId, name, activityId, extraFilters, activePage, itemsPerPage, sort, order } = this.state.fieldsBase;

      const activityListAllEmbed = this.props.activityListAllEmbed;

      if (this.state.isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              getFilterFromSelect(cidId, 'many-to-one'),
              name,
              getFilterFromSelect(activityId, 'one-to-many'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
          if (idEntity) {
            activityListAllEmbed.map(v => {
              v['id'] = null;
              v['cidPta'] = { id: idEntity };
              this.props['createEntityActivity'](v);
            });
          }
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              getFilterFromSelect(cidId, 'many-to-one'),
              name,
              getFilterFromSelect(activityId, 'one-to-many'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/cid-pta?' + getEntityFiltersURL(this.state.fieldsBase));
  };

  getFullEntityFromState(v) {
    return {
      id: v.id,
      name: this.state.nameSelectValue,
      expectedResult: this.state.expectedResultSelectValue,
      activity: this.state.activitySelectValue,
      padCidPta: this.state.padCidPtaSelectValue,
      cid: this.state.cidSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    const activityEntity = v.activity;

    const padCidPtaEntity = v.padCidPta;

    const cidEntity = v.cid;

    return {
      nameSelectValue: v.name,
      expectedResultSelectValue: v.expectedResult,
      activitySelectValue: activityEntity ? activityEntity['id'] : null,
      padCidPtaSelectValue: padCidPtaEntity ? padCidPtaEntity['id'] : null,
      cidSelectValue: cidEntity ? cidEntity['id'] : null,
    };
  }

  renderHeader() {
    const { cidPtaEntity, activities, padCidPtas, cids, loading, updating } = this.props;
    const { isNew } = this.state;

    const { expectedResult } = cidPtaEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.cidPta.home.createLabel">Create a CidPta</Translate>
            ) : (
              <Translate contentKey="generadorApp.cidPta.home.editLabel">Edit a CidPta</Translate>
            )}
          </span>

          <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.cidPta.btnSave">Save</Translate>
          </Button>

          {!this.state.isNew ? (
            <>
              <Button
                color="primary"
                id="next-entity"
                disabled={updating}
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.cidId,
                    this.state.fieldsBase.name,
                    this.state.fieldsBase.activityId,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 + 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 + 1 },
                    });
                    this.props.history.push(
                      '/cid-pta/' + myresult.value.data[0]['id'] + '/edit?' + this.getFiltersURL(this.state.fieldsBase.offset * 1 + 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.last.item', { fieldName: translate('generadorApp.cidPta.detail.title') }));
                  }
                }}
                className="float-right jh-create-entity"
              >
                {'>>'}
              </Button>

              <Button
                color="primary"
                id="prev-entity"
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.cidId,
                    this.state.fieldsBase.name,
                    this.state.fieldsBase.activityId,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 - 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 - 1 },
                    });
                    this.props.history.push(
                      '/cid-pta/' + myresult.value.data[0]['id'] + '/edit?' + this.getFiltersURL(this.state.fieldsBase.offset * 1 - 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.first.item', { fieldName: translate('generadorApp.cidPta.detail.title') }));
                  }
                }}
                disabled={updating}
                className="float-right jh-create-entity"
              >
                {'<<'}
              </Button>
            </>
          ) : (
            <> </>
          )}

          <Button
            tag={Link}
            id="cancel-save"
            to={'/cid-pta?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.cidPta.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={'/cid-pta'}>
              <Translate contentKey="generadorApp.cidPta.home.title">CID x PTA</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.cidPta.detail.title">CID x PTA edit</Translate>
          </li>
        </ol>
      </>
    );
  }
  renderBody() {
    const { cidPtaEntity, activities, padCidPtas, cids, loading, updating } = this.props;
    const { isNew } = this.state;

    const { expectedResult } = cidPtaEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const fullEntityEmbebed = this.getFullEntityFromState(cidPtaEntity);

    const mapDispatchToPropsActivityList: any = {};
    Object.keys(mapDispatchToPropsActivity).map(v => {
      mapDispatchToPropsActivityList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'Activity')) {
          this.setState({ functionEmbebed: [...this.state.functionEmbebed, v + 'Activity'] }, this.props[v + 'Activity']);
        }
      };
    });

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="cid-pta-id">
                  <Translate contentKey="generadorApp.cidPta.field_id">ID</Translate>
                </Label>
                </Col> */}
                      <Col md="12">
                        <AvInput id="cid-pta-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'name' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="nameLabel" for="cid-pta-name">
                              <Translate contentKey="generadorApp.cidPta.name">Name</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                              value={this.state.nameSelectValue ? this.state.nameSelectValue : ''}
                              id="cid-pta-name"
                              type="text"
                              name="name"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                      type="hidden"
                      name="name"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'cid' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="cid-pta-cid">
                              <Translate contentKey="generadorApp.cidPta.cid">Cid</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="cid-pta-cid"
                              name={'cid'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.cidSelectValue ? this.state.cidSelectValue : ''}
                              onChange={options => this.setState({ cidSelectValue: options })}
                              defaultOptions={this.state.cidStartSelectOptions ? this.state.cidStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.cidStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.cidStartSelectOptions === undefined) {
                                  const result = await getListAxios('cids', {}, 0, 100, 'name,asc', 'name');
                                  this.setState({
                                    cidStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios('cids', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                          <RenderModalSuperSelect
                            baseState={this.state}
                            setState={_state => this.setState(_state)}
                            entity={'cid'}
                            listEntity={'cids'}
                            template={'code;name'.split(';')}
                            stateField={'cidSelectValue'}
                            multiple={false}
                            showFields={'name'.split(';')}
                            order={'name,asc'}
                            filtersBase={{}}
                          />
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="cid"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}
                  {baseFilters !== 'expectedResult' ? (
                    <Col md="12">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="expectedResultLabel" for="cid-pta-expectedResult">
                              <Translate contentKey="generadorApp.cidPta.expectedResult">Expected Result</Translate>
                            </Label>
                          </Col>

                          <Col md="12">
                            <ReactQuill
                              value={this.state.expectedResultSelectValue ? this.state.expectedResultSelectValue : ''}
                              onChange={evt => this.setState({ expectedResultSelectValue: evt })}
                              modules={quillEditorModules}
                              formats={quillEditorFormats}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ expectedResultSelectValue: evt.target.value })}
                      type="hidden"
                      name="expectedResult"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'activity' ? (
                    <Col md="12">
                      <div className="entityEmbebedList">
                        <Activity
                          entityEmbebedPai={fullEntityEmbebed}
                          history={this.props.history}
                          location={{
                            pathname: `/cid-pta/${this.props.match.params['id']}/activity/`,
                            search: '',
                            hash: '',
                            state: undefined,
                            key: 'q2tbhd',
                          }}
                          match={{
                            path: `/cid-pta/:idCidPta/activity/:id`,
                            url: `/cid-pta/${this.props.match.params['id']}/activity/`,
                            isExact: true,
                            params: { idCidPta: this.props.match.params['id'], url: `/cid-pta/${this.props.match.params['id']}/activity/` },
                          }}
                          {...this.props.mapStateToPropsActivity}
                          {...mapDispatchToPropsActivityList}
                          getEntityPai={(...v) => this.props.getEntity(...v)}
                          getEntities={this.activityGetEntities}
                          updateEntity={this.activityUpdateEntity}
                          getEntity={this.activityGetEntity}
                          createEntity={this.activityCreateEntity}
                          deleteEntity={this.activityDeleteEntity}
                          reset={this.activityReset}
                          activityList={this.props.activityListEmbed}
                          totalItems={this.props.activityListEmbed ? this.props.activityListEmbed.length : 0}
                          loading={this.props.loading}
                          activityEntity={this.props.activitySelected}
                        />
                      </div>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="activity"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}{' '}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { cidPtaEntity, activities, padCidPtas, cids, loading, updating } = this.props;
    const { isNew } = this.state;

    const { expectedResult } = cidPtaEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...cidPtaEntity,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel className="cid-pta cid-pta-update">
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToProps = (storeState: IRootState) => {
  return {
    activities: storeState.activity.entities,
    padCidPtas: storeState.padCidPta.entities,
    cids: storeState.cid.entities,
    cidPtaEntity: storeState.cidPta.entity,
    loading: storeState.cidPta.loading,
    updating: storeState.cidPta.updating,
    updateSuccess: storeState.cidPta.updateSuccess,

    cidPtas: storeState.cidPta.entities,
    activityListAllEmbed: storeState.cidPta.activityListAllEmbed,
    activityListEmbed: storeState.cidPta.activityListEmbed,
    activitySelected: storeState.cidPta.activitySelected,
    mapStateToPropsActivity: mapStateToPropsActivity(storeState),
  };
};

const mapDispatchToPropsActivityList = {};
Object.keys(mapDispatchToPropsActivity).map(v => {
  mapDispatchToPropsActivityList[v + 'Activity'] = mapDispatchToPropsActivity[v];
});

export const mapDispatchToProps = {
  getEntity,
  updateEntity,
  setBlob,
  createEntity,
  getEntityOffset,
  reset,

  ...mapDispatchToPropsActivityList,
  activityUpdateEntity,
  activityGetEntities,
  activitySetEntities,
  activityReset,
  activityGetEntity,
  activityCreateEntity,
  activityDeleteEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CidPtaUpdate);
