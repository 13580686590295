import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Simpro from './extended/simpro';

import SimproDetail from './simpro-detail';

import SimproUpdate from './simpro-update';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={SimproUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={SimproUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={SimproDetail} />
      <ErrorBoundaryRoute path={match.path} component={Simpro} />
    </Switch>
  </>
);

export default Routes;
