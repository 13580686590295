/* eslint complexity: ["error", 500] */
import axios from 'axios';

import { IPaginationBaseState, IPayloadResult } from 'react-jhipster';

import { IPayload } from 'react-jhipster/src/type/redux-action.type';

import { BASE_API_VERSION_PATH } from 'app/config/constants';

import { cleanEntity, jsonParse } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IProfessionalContact, defaultValue } from 'app/shared/model/professional-contact.model';

export declare type ICrudPutAction<T> = (data?: T, listFiltersPage?) => IPayload<T> | IPayloadResult<T>;
export declare type ICrudGetAction<T> = (id: string | number, selectFields?: string) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const ACTION_TYPES = {
  FETCH_PROFESSIONALCONTACT_LIST_EXPORT: 'professionalContact/FETCH_PROFESSIONALCONTACT_LIST_EXPORT',
  FETCH_PROFESSIONALCONTACT_LIST: 'professionalContact/FETCH_PROFESSIONALCONTACT_LIST',
  FETCH_PROFESSIONALCONTACT: 'professionalContact/FETCH_PROFESSIONALCONTACT',
  FETCH_PROFESSIONALCONTACT_OFFSET: 'professionalContact/FETCH_PROFESSIONALCONTACT_OFFSET',
  CREATE_PROFESSIONALCONTACT: 'professionalContact/CREATE_PROFESSIONALCONTACT',
  UPDATE_PROFESSIONALCONTACT: 'professionalContact/UPDATE_PROFESSIONALCONTACT',
  CREATE_PROFESSIONALCONTACT_AND_GO_LIST: 'professionalContact/CREATE_PROFESSIONALCONTACT_AND_GO_LIST',
  UPDATE_PROFESSIONALCONTACT_AND_GO_LIST: 'professionalContact/UPDATE_PROFESSIONALCONTACT_AND_GO_LIST',
  DELETE_PROFESSIONALCONTACT: 'professionalContact/DELETE_PROFESSIONALCONTACT',
  RESET: 'professionalContact/RESET',
  SHOW_MODAL: 'professionalContact/SHOW_MODAL',
};

const initialState = {
  loading: false,
  openModal: {
    list: false,
    view: false,
    detail: false,
  },
  errorMessage: null,
  entities: [] as ReadonlyArray<IProfessionalContact>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const actionPendind = [];
let actualState = initialState;

export const convertEntityBuffers = entity => ({
  ...entity,
});

export type ProfessionalContactState = Readonly<typeof initialState>;

export interface IProfessionalContactBaseState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  listCheckedID?: Array<number>;
  isCheckedAll?: boolean;
  urlBack?: string;
  extraFilters?: any;
  baseFilters: any;
  offset: number;
  telephonePrimary?: any;
  phonePrimary?: any;
  email?: any;
  professionalId?: any;
  professionalStartFilter?: any;
}

export interface IFieldsBase extends IProfessionalContactBaseState, IPaginationBaseState {}
export interface IProfessionalContactUpdateState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  urlBack?: string;
  functionEmbebed?: any[];
  fieldsBase: IFieldsBase;
  telephonePrimarySelectValue?: any;
  telephonePrimaryStartSelectOptions?: any;
  phonePrimarySelectValue?: any;
  phonePrimaryStartSelectOptions?: any;
  emailSelectValue?: any;
  emailStartSelectOptions?: any;

  professionalSelectValue?: any;
  professionalStartSelectOptions?: any;

  isNew: boolean;
  professionalName?: any;

  professionalId?: any;
}

// Reducer

export default (state: ProfessionalContactState = initialState, action): ProfessionalContactState => {
  actualState = state;
  if (
    action.type.includes('_PENDING') &&
    !actionPendind.includes(action.type.replace('_PENDING', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_PENDING', ''))
  ) {
    actionPendind.push(action.type.replace('_PENDING', ''));
  }

  if (
    action.type.includes('_FULFILLED') &&
    actionPendind.includes(action.type.replace('_FULFILLED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_FULFILLED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_FULFILLED', '')), 1);
  }

  if (
    action.type.includes('_REJECTED') &&
    actionPendind.includes(action.type.replace('_REJECTED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_REJECTED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_REJECTED', '')), 1);
  }

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PROFESSIONALCONTACT_LIST_EXPORT):
    case REQUEST(ACTION_TYPES.FETCH_PROFESSIONALCONTACT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_PROFESSIONALCONTACT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_PROFESSIONALCONTACT):
    case REQUEST(ACTION_TYPES.UPDATE_PROFESSIONALCONTACT):
    case REQUEST(ACTION_TYPES.CREATE_PROFESSIONALCONTACT_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.UPDATE_PROFESSIONALCONTACT_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.DELETE_PROFESSIONALCONTACT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_PROFESSIONALCONTACT_LIST_EXPORT):
    case FAILURE(ACTION_TYPES.FETCH_PROFESSIONALCONTACT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_PROFESSIONALCONTACT):
    case FAILURE(ACTION_TYPES.CREATE_PROFESSIONALCONTACT):
    case FAILURE(ACTION_TYPES.UPDATE_PROFESSIONALCONTACT):
    case FAILURE(ACTION_TYPES.CREATE_PROFESSIONALCONTACT_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.UPDATE_PROFESSIONALCONTACT_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.DELETE_PROFESSIONALCONTACT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_PROFESSIONALCONTACT_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_PROFESSIONALCONTACT_OFFSET):
      if (action.payload.data && action.payload.data.length > 0) {
        return {
          ...state,
          loading: false,
          entity: convertEntityBuffers(action.payload.data[0]),
        };
      }
      return {
        ...state,
        loading: false,
      };

    case SUCCESS(ACTION_TYPES.FETCH_PROFESSIONALCONTACT):
      return {
        ...state,
        loading: false,
        entity: convertEntityBuffers(action.payload.data),
      };
    case SUCCESS(ACTION_TYPES.CREATE_PROFESSIONALCONTACT):
    case SUCCESS(ACTION_TYPES.UPDATE_PROFESSIONALCONTACT):
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_PROFESSIONALCONTACT_AND_GO_LIST):
    case SUCCESS(ACTION_TYPES.UPDATE_PROFESSIONALCONTACT_AND_GO_LIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_PROFESSIONALCONTACT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };

    case ACTION_TYPES.SHOW_MODAL: {
      const openModal = state.openModal;
      openModal[action.payload.type] = action.payload.show;
      return {
        ...state,
        openModal,
      };
    }
    default:
      return state;
  }
};

const apiUrl = BASE_API_VERSION_PATH + 'professional-contacts';

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

// Actions

// Actions
export type ICrudGetAllActionProfessionalContact<T> = (
  telephonePrimary?: any,
  phonePrimary?: any,
  email?: any,
  professionalId?: any,
  extraFilters?: {},
  page?: number,
  size?: number,
  sort?: string,
  selectFields?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getEntitiesFilter = (filters?, page?, size?, sort?, selectFields = '') => {
  const requestUrl = `${apiUrl}?page=${page >= 0 ? page : 0}&size=${size >= 0 ? size : 20}&sort=${sort ? sort : 'id,asc'}&`;
  const requestFilters = filters.map(v => v.column + '.' + v.operation + '=' + v.value).join('&');
  return {
    type: ACTION_TYPES.FETCH_PROFESSIONALCONTACT_LIST,
    payload: axios.get<IProfessionalContact>(`${requestUrl}${requestFilters}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getAllEntities = (filters?, sort?, selectFields = '') => {
  if (
    (actualState.totalItems > 0 && actualState.entities.length === actualState.totalItems) ||
    actionPendind.includes(ACTION_TYPES.FETCH_PROFESSIONALCONTACT_LIST)
  ) {
    return {
      type: ACTION_TYPES.FETCH_PROFESSIONALCONTACT_LIST + '_FROM_CACHE',
      payload: new Promise(function (resolve, reject) {
        resolve('done');
      }),
    };
  }

  const idsRequest = filters && typeof filters['ids'] !== 'undefined' && filters['ids'] ? `id.in=${filters['ids'].join(',')}&` : '';
  const telephonePrimaryRequest =
    filters && typeof filters['telephonePrimary'] !== 'undefined' && filters['telephonePrimary']
      ? `telephonePrimary.contains=${filters['telephonePrimary']}&`
      : '';
  const phonePrimaryRequest =
    filters && typeof filters['phonePrimary'] !== 'undefined' && filters['phonePrimary']
      ? `phonePrimary.contains=${filters['phonePrimary']}&`
      : '';
  const emailRequest = filters && typeof filters['email'] !== 'undefined' && filters['email'] ? `email.contains=${filters['email']}&` : '';
  const professionalRequest =
    filters && typeof filters['professionalId'] !== 'undefined' && filters['professionalId']
      ? `professional.id.in=${filters['professionalId']}&`
      : '';

  const requestUrl = `${apiUrl}${`?page=${0}&size=${1000000}&sort=${sort ? sort : 'id,asc'}&`}`;
  return {
    type: ACTION_TYPES.FETCH_PROFESSIONALCONTACT_LIST,
    payload: axios.get<IProfessionalContact>(
      `${requestUrl}${idsRequest}${telephonePrimaryRequest}${phonePrimaryRequest}${emailRequest}${professionalRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntities: ICrudGetAllActionProfessionalContact<IProfessionalContact> = (
  telephonePrimary,
  phonePrimary,
  email,
  professionalId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const telephonePrimaryRequest = telephonePrimary ? `telephonePrimary.contains=${telephonePrimary}&` : '';
  const phonePrimaryRequest = phonePrimary ? `phonePrimary.contains=${phonePrimary}&` : '';
  const emailRequest = email ? `email.contains=${email}&` : '';
  const professionalRequest = professionalId ? `professional.id.in=${professionalId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PROFESSIONALCONTACT_LIST,
    payload: axios.get<IProfessionalContact>(
      `${requestUrl}${extraFiltersRequest}${telephonePrimaryRequest}${phonePrimaryRequest}${emailRequest}${professionalRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntityOffset: any = (
  telephonePrimary,
  phonePrimary,
  email,
  professionalId,
  extraFilters,
  offset,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const telephonePrimaryRequest = telephonePrimary ? `telephonePrimary.contains=${telephonePrimary}&` : '';
  const phonePrimaryRequest = phonePrimary ? `phonePrimary.contains=${phonePrimary}&` : '';
  const emailRequest = email ? `email.contains=${email}&` : '';
  const professionalRequest = professionalId ? `professional.id.in=${professionalId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${offset}&size=${1}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PROFESSIONALCONTACT_OFFSET,
    payload: axios.get<IProfessionalContact>(
      `${requestUrl}${extraFiltersRequest}${telephonePrimaryRequest}${phonePrimaryRequest}${emailRequest}${professionalRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntity: ICrudGetAction<IProfessionalContact> = (id, selectFields = '') => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_PROFESSIONALCONTACT,
    payload: axios.get<IProfessionalContact>(requestUrl, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntitiesExport: ICrudGetAllActionProfessionalContact<IProfessionalContact> = (
  telephonePrimary,
  phonePrimary,
  email,
  professionalId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const telephonePrimaryRequest = telephonePrimary
    ? Array.isArray(status)
      ? `telephonePrimary.in=${telephonePrimary.join(',')}&`
      : `telephonePrimary.contains=${telephonePrimary}&`
    : '';
  const phonePrimaryRequest = phonePrimary
    ? Array.isArray(status)
      ? `phonePrimary.in=${phonePrimary.join(',')}&`
      : `phonePrimary.contains=${phonePrimary}&`
    : '';
  const emailRequest = email ? (Array.isArray(status) ? `email.in=${email.join(',')}&` : `email.contains=${email}&`) : '';
  const professionalRequest = professionalId ? `professional.id.in=${professionalId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_PROFESSIONALCONTACT_LIST,
    payload: axios.get<IProfessionalContact>(
      `${requestUrl}${extraFiltersRequest}${telephonePrimaryRequest}${phonePrimaryRequest}${emailRequest}${professionalRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const createEntity: ICrudPutAction<IProfessionalContact> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.CREATE_PROFESSIONALCONTACT_AND_GO_LIST
        : ACTION_TYPES.CREATE_PROFESSIONALCONTACT,
    payload: axios.post(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const updateEntity: ICrudPutAction<IProfessionalContact> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.UPDATE_PROFESSIONALCONTACT_AND_GO_LIST
        : ACTION_TYPES.UPDATE_PROFESSIONALCONTACT,
    payload: axios.put(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const deleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_PROFESSIONALCONTACT,
    payload: axios.delete(requestUrl),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const showModal = view => ({
  payload: { type: view, show: true },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const hideModal = view => ({
  payload: { type: view, show: false },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const getUrlBack = (location): string => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  let urlBack = _urlBase.searchParams.get('urlBack') || '';
  urlBack = urlBack.trim();
  urlBack = urlBack.startsWith('/') ? urlBack.slice(1) : urlBack;
  urlBack = urlBack.endsWith('/') ? urlBack.slice(0, -1) : urlBack;
  return urlBack ? '/' + urlBack + '/' : '';
};

export const getProfessionalContactState = (location): IProfessionalContactBaseState => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  const extraFilters = jsonParse(_urlBase.searchParams.get('extraFilters') || '{}');
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;

  const telephonePrimary = _urlBase.searchParams.get('telephonePrimary') || '';
  const phonePrimary = _urlBase.searchParams.get('phonePrimary') || '';
  const email = _urlBase.searchParams.get('email') || '';
  const professionalId = _urlBase.searchParams.get('professional') || '';
  return {
    baseFilters,
    extraFilters,
    offset,
    telephonePrimary,
    phonePrimary,
    email,
    professionalId: professionalId ? professionalId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
  };
};

export const getEntityFiltersURL = (state, offset = null) => {
  return (
    'baseFilters=' +
    state.baseFilters +
    '&extraFilters=' +
    encodeURI(JSON.stringify(state.extraFilters)) +
    '&page=' +
    state.activePage +
    '&' +
    'size=' +
    state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    state.sort +
    ',' +
    state.order +
    '&' +
    (state.telephonePrimary ? 'telephonePrimary=' + state.telephonePrimary + '&' : '') +
    (state.phonePrimary ? 'phonePrimary=' + state.phonePrimary + '&' : '') +
    (state.email ? 'email=' + state.email + '&' : '') +
    (state.professionalId ? 'professional=' + state.professionalId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    ''
  );
};
