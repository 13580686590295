/* eslint complexity: ["error", 500] */
/* eslint no-empty: off */
import React from 'react';
import { connect } from 'react-redux';

import SelectAsync from 'react-select/async';
import axios from 'axios';
import { Link, RouteComponentProps } from 'react-router-dom';
import InputMask from 'react-input-mask';

import { getFilterFromSelect, getFormFromSelect, getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Col, Label } from 'reactstrap';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import {
  IProfessionalContactUpdateState as IBaseUpdateState,
  getEntity,
  getProfessionalContactState,
  getUrlBack,
  updateEntity,
  createEntity,
  getEntityFiltersURL,
  getEntityOffset,
  reset,
} from './professional-contact.reducer';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

registerLocale('pt', pt);

export interface IProfessionalContactUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}
export type IProfessionalContactUpdateState = IBaseUpdateState;

export class ProfessionalContactUpdate extends React.Component<IProfessionalContactUpdateProps, IProfessionalContactUpdateState> {
  telephonePrimaryFileInput: any;

  phonePrimaryFileInput: any;

  emailFileInput: any;
  constructor(props: Readonly<IProfessionalContactUpdateProps>) {
    super(props);

    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      functionEmbebed: [],

      professionalSelectValue: { value: this.props.match.params['idProfessional'], label: this.props.match.params['idProfessional'] },

      telephonePrimarySelectValue: null,
      phonePrimarySelectValue: null,
      emailSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getProfessionalContactState(this.props.location),
        urlBack: getUrlBack(this.props.location),
        professionalId: [{ value: this.props.match.params['idProfessional'], label: this.props.match.params['idProfessional'] }],
        baseFilters: 'professional',
      },
      professionalName: null,

      isNew: !this.props.match.params || !this.props.match.params['id'],
    };

    axios
      .get('/api/professionals/' + this.props.match.params['idProfessional'], {
        headers: {
          'Select-Fields': 'name',
        },
      })
      .then(result => {
        this.setState({
          professionalSelectValue: {
            ...result['data'],
            value: this.props.match.params['idProfessional'],
            label: showFieldsSelectAsync(result['data'], 'name'),
          },
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateSuccess !== this.props.updateSuccess && prevProps.updateSuccess) {
      this.handleClose();
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.professionalContactEntity && prevProps.professionalContactEntity.id !== this.props.professionalContactEntity.id) ||
      (this.props.professionals &&
        this.state.professionalSelectValue === null &&
        this.props.professionalContactEntity.professional &&
        this.props.professionalContactEntity.professional.id)
    ) {
      const p =
        this.props.professionalContactEntity && this.props.professionalContactEntity.professional
          ? this.props.professionalContactEntity.professional
          : null;
      this.setState({
        professionalSelectValue: p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : null,
      });
    }

    if (
      (prevProps.professionalContactEntity && prevProps.professionalContactEntity.id !== this.props.professionalContactEntity.id) ||
      (this.props.professionalContactEntity.telephonePrimary &&
        this.state.telephonePrimarySelectValue === null &&
        this.props.professionalContactEntity.telephonePrimary !== this.state.telephonePrimarySelectValue)
    ) {
      this.setState({
        telephonePrimarySelectValue: this.props.professionalContactEntity.telephonePrimary
          ? this.props.professionalContactEntity.telephonePrimary
          : null,
      });
    }
    if (
      (prevProps.professionalContactEntity && prevProps.professionalContactEntity.id !== this.props.professionalContactEntity.id) ||
      (this.props.professionalContactEntity.phonePrimary &&
        this.state.phonePrimarySelectValue === null &&
        this.props.professionalContactEntity.phonePrimary !== this.state.phonePrimarySelectValue)
    ) {
      this.setState({
        phonePrimarySelectValue: this.props.professionalContactEntity.phonePrimary
          ? this.props.professionalContactEntity.phonePrimary
          : null,
      });
    }
    if (
      (prevProps.professionalContactEntity && prevProps.professionalContactEntity.id !== this.props.professionalContactEntity.id) ||
      (this.props.professionalContactEntity.email &&
        this.state.emailSelectValue === null &&
        this.props.professionalContactEntity.email !== this.state.emailSelectValue)
    ) {
      this.setState({ emailSelectValue: this.props.professionalContactEntity.email ? this.props.professionalContactEntity.email : null });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  UNSAFE_componentWillMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params['id']);
    }
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = offset !== null ? '&offset=' + offset : '';
    Object.keys(fieldsBase).map(key => {
      url += key !== 'offset' ? '&' + key + '=' + fieldsBase[key] : '';
    });
    return url;
  };
  saveEntity = (event: any, errors: any, values: any) => {
    if (!this.state.telephonePrimarySelectValue) {
      toast.error(
        translate('generadorApp.validators.required', { fieldName: translate('generadorApp.professionalContact.telephonePrimary') })
      );
      return false;
    }

    const selectFieldsList = ['telephonePrimary', 'phonePrimary', 'email', 'professional.name'];

    if (errors.length === 0) {
      const { professionalContactEntity } = this.props;
      const _entity = {
        ...professionalContactEntity,
        // ...values,

        professional: getFormFromSelect(this.state.professionalSelectValue, 'many-to-one'), // many-to-one - otherSide

        telephonePrimary: this.state.telephonePrimarySelectValue,
        phonePrimary: this.state.phonePrimarySelectValue,
        email: this.state.emailSelectValue,
      };

      const entity = _entity;

      const {
        telephonePrimary,
        phonePrimary,
        email,
        professionalId,
        extraFilters,
        activePage,
        itemsPerPage,
        sort,
        order,
      } = this.state.fieldsBase;

      if (this.state.isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              telephonePrimary,
              phonePrimary,
              email,
              getFilterFromSelect(professionalId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              telephonePrimary,
              phonePrimary,
              email,
              getFilterFromSelect(professionalId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
    }
  };

  handleClose = () => {
    this.props.history.push(
      '/professional/' + this.props.match.params['idProfessional'] + '/professional-contact?' + getEntityFiltersURL(this.state.fieldsBase)
    );
  };

  getFullEntityFromState(v) {
    return {
      id: v.id,
      telephonePrimary: this.state.telephonePrimarySelectValue,
      phonePrimary: this.state.phonePrimarySelectValue,
      email: this.state.emailSelectValue,
      professional: this.state.professionalSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    const professionalEntity = v.professional;

    return {
      telephonePrimarySelectValue: v.telephonePrimary,
      phonePrimarySelectValue: v.phonePrimary,
      emailSelectValue: v.email,
      professionalSelectValue: professionalEntity ? professionalEntity['id'] : null,
    };
  }

  renderHeader() {
    const { professionalContactEntity, professionals, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.professionalContact.home.createLabel">Create a ProfessionalContact</Translate>
            ) : (
              <Translate contentKey="generadorApp.professionalContact.home.editLabel">Edit a ProfessionalContact</Translate>
            )}
          </span>

          <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.professionalContact.btnSave">Save</Translate>
          </Button>

          {!this.state.isNew ? (
            <>
              <Button
                color="primary"
                id="next-entity"
                disabled={updating}
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.telephonePrimary,
                    this.state.fieldsBase.phonePrimary,
                    this.state.fieldsBase.email,
                    this.state.fieldsBase.professionalId,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 + 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 + 1 },
                    });
                    this.props.history.push(
                      '/professional-contact/' +
                        myresult.value.data[0]['id'] +
                        '/edit?' +
                        this.getFiltersURL(this.state.fieldsBase.offset * 1 + 1)
                    );
                  } else {
                    toast.error(
                      translate('generadorApp.last.item', { fieldName: translate('generadorApp.professionalContact.detail.title') })
                    );
                  }
                }}
                className="float-right jh-create-entity"
              >
                {'>>'}
              </Button>

              <Button
                color="primary"
                id="prev-entity"
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.telephonePrimary,
                    this.state.fieldsBase.phonePrimary,
                    this.state.fieldsBase.email,
                    this.state.fieldsBase.professionalId,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 - 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 - 1 },
                    });
                    this.props.history.push(
                      '/professional-contact/' +
                        myresult.value.data[0]['id'] +
                        '/edit?' +
                        this.getFiltersURL(this.state.fieldsBase.offset * 1 - 1)
                    );
                  } else {
                    toast.error(
                      translate('generadorApp.first.item', { fieldName: translate('generadorApp.professionalContact.detail.title') })
                    );
                  }
                }}
                disabled={updating}
                className="float-right jh-create-entity"
              >
                {'<<'}
              </Button>
            </>
          ) : (
            <> </>
          )}

          <Button
            tag={Link}
            id="cancel-save"
            to={
              '/professional/' +
              this.props.match.params['idProfessional'] +
              '/professional-contact?' +
              getEntityFiltersURL(this.state.fieldsBase)
            }
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.professionalContact.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/professional'}>
              <Translate contentKey="generadorApp.professional.home.title">Professionals</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/professional/' + this.props.match.params['idProfessional']}>
              {this.state.professionalSelectValue ? this.state.professionalSelectValue.label : this.props.match.params['idProfessional']}
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/professional/' + this.props.match.params['idProfessional'] + '/professional-contact'}>
              <Translate contentKey="generadorApp.professionalContact.home.title">Professional Contacts</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.professionalContact.detail.title">Professional Contact edit</Translate>
          </li>
        </ol>
      </>
    );
  }
  renderBody() {
    const { professionalContactEntity, professionals, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const fullEntityEmbebed = this.getFullEntityFromState(professionalContactEntity);

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="professional-contact-id">
                  <Translate contentKey="generadorApp.professionalContact.field_id">ID</Translate>
                </Label>
                </Col> */}
                      <Col md="12">
                        <AvInput id="professional-contact-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'telephonePrimary' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label
                              className="mt-2 label-single-line"
                              id="telephonePrimaryLabel"
                              for="professional-contact-telephonePrimary"
                            >
                              <Translate contentKey="generadorApp.professionalContact.telephonePrimary">Telephone Primary</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <InputMask
                              onChange={evt => this.setState({ telephonePrimarySelectValue: evt.target.value })}
                              value={this.state.telephonePrimarySelectValue ? this.state.telephonePrimarySelectValue : ''}
                              id="professional-contact-telephonePrimary"
                              type="text"
                              name="telephonePrimary"
                              className={'form-control'}
                              mask="99 99999-9999"
                              tag={InputMask}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ telephonePrimarySelectValue: evt.target.value })}
                      type="hidden"
                      name="telephonePrimary"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'phonePrimary' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="phonePrimaryLabel" for="professional-contact-phonePrimary">
                              <Translate contentKey="generadorApp.professionalContact.phonePrimary">Phone Primary</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <InputMask
                              onChange={evt => this.setState({ phonePrimarySelectValue: evt.target.value })}
                              value={this.state.phonePrimarySelectValue ? this.state.phonePrimarySelectValue : ''}
                              id="professional-contact-phonePrimary"
                              type="text"
                              name="phonePrimary"
                              className={'form-control'}
                              mask="99 99999-9999"
                              tag={InputMask}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ phonePrimarySelectValue: evt.target.value })}
                      type="hidden"
                      name="phonePrimary"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'email' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="emailLabel" for="professional-contact-email">
                              <Translate contentKey="generadorApp.professionalContact.email">Email</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ emailSelectValue: evt.target.value })}
                              value={this.state.emailSelectValue ? this.state.emailSelectValue : ''}
                              id="professional-contact-email"
                              type="text"
                              name="email"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ emailSelectValue: evt.target.value })}
                      type="hidden"
                      name="email"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                  {baseFilters !== 'professional' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="professional-contact-professional">
                              <Translate contentKey="generadorApp.professionalContact.professional">Professional</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="professional-contact-professional"
                              name={'professional'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.professionalSelectValue ? this.state.professionalSelectValue : ''}
                              onChange={options => this.setState({ professionalSelectValue: options })}
                              defaultOptions={this.state.professionalStartSelectOptions ? this.state.professionalStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.professionalStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.professionalStartSelectOptions === undefined) {
                                  const result = await getListAxios('professionals', {}, 0, 100, 'name,asc', 'name');
                                  this.setState({
                                    professionalStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'professionals',
                                  { 'name.contains': inputValue },
                                  0,
                                  100,
                                  'name,asc',
                                  'name'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="professional"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}{' '}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { professionalContactEntity, professionals, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...professionalContactEntity,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel className="professional-contact professional-contact-update">
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToProps = (storeState: IRootState) => {
  return {
    professionals: storeState.professional.entities,
    professionalContactEntity: storeState.professionalContact.entity,
    loading: storeState.professionalContact.loading,
    updating: storeState.professionalContact.updating,
    updateSuccess: storeState.professionalContact.updateSuccess,
  };
};

export const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  getEntityOffset,
  reset,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProfessionalContactUpdate);
