import React from 'react';

import { connect } from 'react-redux';
import { Moment } from 'moment';
import { Client } from 'app/entities/client/client';

export interface IChartAtendimentosCategoriasProps extends StateProps, DispatchProps {
  periodoInicio?: Moment;
  periodoFim?: Moment;
  operadora?: Array<Client>;
  atendimentosPorCategorias: {
    QuantidadeTotalAtendimentos: number;
    QuantidadeTotalAtendimentosPorCategorias: Array<{ Categoria: string; Total: number }>;
  };
}

const ChartAtendimentosCategorias = (props: IChartAtendimentosCategoriasProps) => {
  return (
    <div className="card border-0 bg-dark text-white mb-3 overflow-hidden">
      <div className="card-body">
        <div className="row">
          <div className="col-xl-7 col-lg-8">
            <div className="mb-3 text-grey">
              <b>Atendimentos por categorias</b>
            </div>
          </div>
        </div>
      </div>
      <div className="widget-list widget-list-rounded inverse-mode">
        <span className="widget-list-item rounded-0">
          <div className="widget-list-content">
            <b className="text-nowrap text-grey">Total</b>
          </div>
          <b className="widget-list-action text-nowrap text-grey">{props.atendimentosPorCategorias?.QuantidadeTotalAtendimentos}</b>
        </span>
        <div style={{ height: '220px', overflow: 'auto' }}>
          {props.atendimentosPorCategorias && props.atendimentosPorCategorias.QuantidadeTotalAtendimentosPorCategorias ? (
            props.atendimentosPorCategorias.QuantidadeTotalAtendimentosPorCategorias.map((v, i) => (
              <span key={i} className="widget-list-item rounded-0 p-t-3">
                <div className="widget-list-content">
                  <div className="widget-list-title">{v.Categoria}</div>
                </div>
                <div className="widget-list-action text-nowrap text-grey">{v.Total}</div>
              </span>
            ))
          ) : (
            <> </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = storeState => ({
  report:
    storeState.jhiReports.reportsList && storeState.jhiReports.reportsList['pacientes-hospitalizados']
      ? storeState.jhiReports.reportsList['pacientes-hospitalizados']
      : [],
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps)(ChartAtendimentosCategorias);
