import React from 'react';
import { Translate, translate } from 'app/config/translate-component';
import { getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';

import { Col, Label, Row } from 'reactstrap';
import SelectAsync from 'react-select/async';
import { AvGroup } from 'availity-reactstrap-validation';

export interface IQuotePackageProps {
  baseState?: any;
  baseProps?: any;
  setState?: any;
  entityBase?: any;
}

const QuotePackageComponentCustomize = (props: IQuotePackageProps) => {
  return (
    <Row>
      <Col md="12">
        <AvGroup>
          <Row>
            <Col md="12">
              <Label className="mt-2 label-single-line" for="quote-package-clComTable">
                <Translate contentKey="generadorApp.quotePackage.clComTable">Cl Com Table</Translate>
              </Label>
            </Col>
            <Col md="12">
              <SelectAsync
                id="quote-package-clComTable"
                name={'clComTable'}
                className={'css-select-control'}
                data-type-rel="many-to-one-other-side"
                value={props.baseState.clComTableSelectValue} 
                onChange={options => {
                  props.setState({
                    clComTableSelectValue: options,
                    grossAmountSelectValue: options['valuePackage'],
                    finalAmountSelectValue:
                      options['valuePackage'] * (props.baseState.daysSelectValue > 0 ? props.baseState.daysSelectValue : 0),
                  });
                }}
                defaultOptions={props?.baseState?.clComTableStartSelectOptions ? props?.baseState?.clComTableStartSelectOptions : []}
                loadingMessage={input => translate('selectAsync.loadingMessage')}
                noOptionsMessage={input =>
                  props.baseState.clComTableStartSelectOptions === undefined
                    ? translate('selectAsync.loadingMessage')
                    : translate('selectAsync.noOptionsMessage')
                }
                onMenuOpen={async () => {
                  if (props.baseState.clComTableStartSelectOptions === undefined) {
                    const result = await getListAxios(
                      'cl-com-tables',
                      { 'type.equals': 'PACOTE', 'client.id.equals': props?.baseProps?.entityEmbebedPai?.patient?.client?.id },
                      0,
                      100,
                      'name,asc',
                      'name'
                    );
                    props.setState({
                      clComTableStartSelectOptions: result.data
                        ? result?.data?.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                        : [],
                    });
                  }
                }}
                loadOptions={async (inputValue, callback) => {
                  const result = await getListAxios(
                    'cl-com-tables',
                    {
                      'type.equals': 'PACOTE',
                      'client.id.equals': props?.baseProps?.entityEmbebedPai?.patient?.client?.id,
                      'name.contains': inputValue,
                    },
                    0,
                    100,
                    'name,asc',
                    'name'
                  );
                  callback(result?.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []);
                }}
              />
            </Col>
          </Row>
        </AvGroup>
      </Col>
    </Row>
  );
};

const mapStateToProps = storeState => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default QuotePackageComponentCustomize;
