/* eslint complexity: ["error", 300] */
import React from 'react';
import { connect } from 'react-redux';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { CategoryStatusHistory, mapStateToProps, mapDispatchToProps, ICategoryStatusHistoryProps } from '../category-status-history';
import { IRootState } from 'app/shared/reducers';
import { updateEntity as updateEntityCategory } from '../../category/category.reducer';
import { getFormFromSelect } from 'app/shared/util/entity-utils';

export interface IExtendedState {
  dumystate?: any;
}

export interface IExtendedProps {
  entityEmbebedPai?: any;
}

export class CategoryStatusHistoryExtended extends CategoryStatusHistory {
  constructor(props: ICategoryStatusHistoryProps) {
    super(props);
    this.state = {
      ...this.state,
      sort: 'id',
      order: 'DESC',
      activePage: 0,
      itemsPerPage: 1000,
    };
  }
  renderHeader() {
    return <> {super.renderHeader()} </>;
  }

  renderFilter() {
    return <> {super.renderFilter()} </>;
  }

  renderTable() {
    return <> {super.renderTable()} </>;
  }

  renderFooter() {
    return <> {super.renderFooter()} </>;
  }

  saveEntity = (event: any, errors: any, values: any, isNew: any) => {
    if (errors.length === 0) {
      const { categoryStatusHistoryEntity } = this.props;
      const entity = {
        ...categoryStatusHistoryEntity,
        ...values,

        category: getFormFromSelect(this.state.categorySelectValue, 'many-to-one'), // many-to-one - otherSide

        status: this.state.statusSelectValue === null ? 'ATIVO' : this.state.statusSelectValue,
        observation: this.state.observationSelectValue,
      };

      const { status, observation, categoryId } = this.state.fieldsBase;
      const entityIdBase = parseInt(categoryId && categoryId.length > 0 && categoryId[0].value ? categoryId[0].value : '', 10);

      if (isNew) {
        this.props.updateEntityCategory(
          {
            id: entityIdBase,
            status: this.state.statusSelectValue === null ? false : this.state.statusSelectValue,
          },
          { reloadList: false }
        );
        new Promise(resolve => {
          resolve(this.props.createEntity(entity, [status, observation, entityIdBase, 0, 1000, 'id,DESC']));
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        this.props.updateEntity(entity, [status, observation, entityIdBase, 0, 1000, 'id,DESC']);
      }
    }
    this.setState({ showModalForm: false });
    this.setState({ showModalEdit: null });
  };

  render() {
    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel>
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderTable()}
            {this.renderNewModal()}
            {this.renderUpdateModal()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
  updateEntityCategory,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;

export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(CategoryStatusHistoryExtended);
