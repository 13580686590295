import React from 'react';
import axios from 'axios';
import { AvGroup } from 'availity-reactstrap-validation';
import { Row, Col, Label } from 'reactstrap';

import { Translate } from 'app/config/translate-component';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BASE_API_VERSION_PATH } from 'app/config/constants';
import moment from 'moment';

export interface IQuoteProps {
  baseState?: any;
  baseProps?: any;
  setState?: any;
  entityBase?: any;
}

const QuoteComponentCustomize = (props: IQuoteProps) => {
  return (
    <AvGroup>
      <Row>
        <Col md="12">
          <Label className="mt-2 label-single-line" id="toLabel" for="quote-to">
            <Translate contentKey="generadorApp.quote.to">To</Translate>
          </Label>
        </Col>
        <Col md="12">
          <DatePicker
            autoComplete="off"
            onChange={value => {
              axios.put(BASE_API_VERSION_PATH + 'quotes', { id: props.entityBase.id, to: moment(value).format('YYYY-MM-DD') });
              return props.setState({ toSelectValue: value });
            }}
            id="to"
            validate={{}}
            className="form-control react-datepicker"
            name="to"
            dateFormat="dd/MM/yyyy"
            selected={props.baseState.toSelectValue ? moment(props.baseState.toSelectValue).toDate() : null}
            locale="pt"
          />
        </Col>
      </Row>
    </AvGroup>
  );
};

const mapStateToProps = storeState => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default QuoteComponentCustomize;
