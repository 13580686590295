import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import { Translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity, IFixedCostBaseState, getUrlBack, getFixedCostState, getEntityFiltersURL } from './fixed-cost.reducer';

export interface IFixedCostState {
  fieldsBase: IFixedCostBaseState;
}

export interface IFixedCostDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class FixedCostDetail extends React.Component<IFixedCostDetailProps, IFixedCostState> {
  constructor(props: Readonly<IFixedCostDetailProps>) {
    super(props);
    this.state = {
      ...this.state,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getFixedCostState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
    };
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = '_back=1' + (offset !== null ? '&offset=' + offset : '');
    Object.keys(fieldsBase).map(key => {
      url += '&' + key + '=' + fieldsBase[key];
    });
    return url;
  };

  UNSAFE_componentWillMount() {
    this.props.getEntity(this.props.match.params['id']);
  }

  renderHeader() {
    const { fixedCostEntity } = this.props;
    const state = this.state;
    return (
      <>
        <div id="page-heading">
          {' '}
          <h2>
            <Translate contentKey="generadorApp.fixedCost.detail.title">FixedCost</Translate>
            <small>&nbsp; {fixedCostEntity['createdBy'] ? fixedCostEntity['createdBy']['login'] : ''}</small>
          </h2>
        </div>

        <div className="pull-right">
          <Button tag={Link} to={'/fixed-cost?' + getEntityFiltersURL(this.state.fieldsBase)} replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.fixedCost.btnBack">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/fixed-cost/${fixedCostEntity.id}/edit?+${getEntityFiltersURL(this.state.fieldsBase)}`}
            replace
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.fixedCost.btnEdit">Edit</Translate>
            </span>
          </Button>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>

          <li className="breadcrumb-item active">
            <Link to={'/fixed-cost'}>Fixed costs</Link>
          </li>
          <li className="breadcrumb-item active">Fixed costs details</li>
        </ol>
      </>
    );
  }

  renderBody() {
    const { fixedCostEntity } = this.props;
    const state = this.state;
    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            <Row className="jh-entity-details">
              <Col className="view-detail-row fixed-cost-name-detail" md="12">
                <Row>
                  <Col className="view-detail-label-left fixed-cost-name-detail-label" md="3">
                    <dt>
                      <span id="name">
                        <Translate contentKey="generadorApp.fixedCost.name">Name</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left fixed-cost-name-detail-value" md="9">
                    <dd>{fixedCostEntity.name}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row fixed-cost-description-detail" md="12">
                <Row>
                  <Col className="view-detail-label-left fixed-cost-description-detail-label" md="3">
                    <dt>
                      <span id="description">
                        <Translate contentKey="generadorApp.fixedCost.description">Description</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left fixed-cost-description-detail-value" md="9">
                    <dd>{fixedCostEntity.description}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row fixed-cost-billToPay-detail" md="12">
                <Row>
                  <Col className="view-detail-label-left fixed-cost-billToPay-detail-label" md="3">
                    <dt>
                      <Translate contentKey="generadorApp.fixedCost.billToPay">Bill To Pay</Translate>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-left fixed-cost-billToPay-detail-value" md="9">
                    <dd>{fixedCostEntity.billToPay ? fixedCostEntity.billToPay.id : ''}</dd>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { fixedCostEntity } = this.props;
    const state = this.state;
    return (
      <div>
        <Panel className="fixed-cost fixed-cost-detail">
          <PanelHeader>{this.renderHeader()}</PanelHeader>

          <PanelBody>{this.renderBody()}</PanelBody>
        </Panel>
      </div>
    );
  }
}

export const mapStateToProps = ({ fixedCost, ...storeState }: IRootState) => ({
  fixedCostEntity: fixedCost.entity,
});

export const mapDispatchToProps = {
  getEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(FixedCostDetail);
