/* import { AUTHORITIES } from 'app/config/constants'; */

const Menu = [
  // { path: '/', icon: 'fa fa-ths', title: 'global.menu.home', role: [] },
  {
    path: '/groups-levels',
    icon: 'fa fa-list',
    title: 'generadorApp.levelGroup.home.title',
    role: ['level-group', 'category', 'specialty', 'product-service', 'medicine-material', 'kit'],
    children: [
      { path: '/level-group', title: 'generadorApp.levelGroup.home.title', role: ['level-group'] },
      { path: '/category', title: 'generadorApp.category.home.title', role: ['category'] },
      // { path: '/category-contract', icon: 'fa fa-dashboard fa-fw', title: 'generadorApp.categoryContract.home.title', role: [] },
      { path: '/specialty', title: 'generadorApp.specialty.home.title', role: ['specialty'] },
      { path: '/product-service', title: 'generadorApp.productService.home.title', role: ['product-service'] },
      { path: '/medicine-material', title: 'generadorApp.medicineMaterial.home.title', role: ['medicine-material'] },
      { path: '/kit', title: 'generadorApp.kit.home.title', role: ['kit'] },
    ],
  },
  {
    path: '/registration',
    icon: 'fa fa-book',
    title: 'global.menu.registrations',
    role: ['user-management', 'profile', 'patient', 'professional', 'client', 'supplier'],
    children: [
      { path: '/admin/user-management', title: 'global.menu.admin.userManagement', role: ['user-management'] },
      { path: '/profile', title: 'generadorApp.profile.home.title', role: ['profile'] },
      { path: '/patient', title: 'generadorApp.patient.home.title', role: ['patient'] },
      { path: '/professional', title: 'generadorApp.professional.home.title', role: ['professional'] },
      { path: '/client', title: 'generadorApp.client.home.title', role: ['client'] },
      { path: '/supplier', title: 'generadorApp.supplier.home.title', role: ['supplier'] },
    ],
  },
  {
    path: '/configuration',
    icon: 'fa fa-cogs',
    title: 'global.menu.admin.configuration',
    role: [
      'brand',
      'presentation',
      'dose',
      'brasindice',
      'simpro',
      'cid',
      'white-label',
      'segment',
      'patient-device',
      'pat-nr-prescription-diagnostic',
      'professional-device',
      'professional-advice',
      'professional-contraction-type',
      'config-tag',
      'sub-tag',
    ],
    children: [
      { path: '/brand', title: 'generadorApp.brand.home.title', role: ['brand'] },
      { path: '/presentation', title: 'generadorApp.presentation.home.title', role: ['presentation'] },
      { path: '/dose', title: 'generadorApp.dose.home.title', role: ['dose'] },
      {
        path: '/professional-data',
        title: 'global.menu.professionalData',
        role: ['professional-device', 'professional-advice', 'professional-contraction-type'],
        children: [
          { path: '/professional-device', title: 'generadorApp.professionalDevice.home.title', role: ['professional-device'] },
          { path: '/professional-advice', title: 'generadorApp.professionalAdvice.home.title', role: ['professional-advice'] },
          {
            path: '/professional-contraction-type',
            title: 'generadorApp.professionalContractionType.home.title',
            role: ['professional-contraction-type'],
          },
          // { path: '/sup-com-professional', title: 'generadorApp.supComProfessional.home.title', role: ['sup-com-professional'] },
        ],
      },
      {
        path: '/patient-data',
        title: 'global.menu.patientData',
        role: ['patient-device', 'pat-nr-prescription-diagnostic'],
        children: [
          { path: '/patient-device', title: 'generadorApp.patientDevice.home.title', role: ['patient-device'] },
          // {
          //   path: '/pat-nr-prescription-diagnostic',
          //   title: 'generadorApp.PatNrPrescriptionDiagnostic.home.title',
          //   role: ['pat-nr-prescription-diagnostic'],
          // },
        ],
      },
      { path: '/segment', title: 'generadorApp.segment.home.title', role: ['segment'] },

      // Brasindice e Simpro
      { path: '/brasindice', title: 'generadorApp.brasindice.home.title', role: ['brasindice'] },
      { path: '/simpro', title: 'generadorApp.simpro.home.title', role: ['simpro'] },

      { path: '/cid', title: 'generadorApp.cid.home.title', role: ['cid'] },

      { path: '/white-label', title: 'generadorApp.company.home.title', role: ['white-label'] },

      // Tag
      {
        path: '/tag',
        title: 'generadorApp.tags.home.title',
        role: ['config-tag', 'sub-tag'],
        children: [
          { path: '/config-tag', title: 'generadorApp.configTag.home.title', role: ['config-tag'] },
          { path: '/sub-tag', title: 'generadorApp.subTag.home.title', role: ['sub-tag'] },
        ],
      },
      { path: '/variable-cost', title: 'generadorApp.variableCost.home.title', role: ['variable-cost'] },
      { path: '/fixed-cost', title: 'generadorApp.fixedCost.home.title', role: ['fixed-cost'] },
    ],
  },
  // {
  //   path: '/attendance-list',
  //   icon: 'fa fa-user-md',
  //   title: 'generadorApp.padAttendance.home.title',
  //   role: ['attendance-list'],
  // },
  {
    path: '/nursing-diary',
    icon: 'fa fa-user-md',
    title: 'generadorApp.occurrence.home.title',
    role: [''],
    children: [
      { path: '/nursing-diary', title: 'generadorApp.nursingDiary.home.title', role: ['nursing-diary'] },
      { path: '/medical-record', title: 'generadorApp.medicalRecord.home.title', role: ['medical-record'] },
    ],
  },
  {
    path: '/cid-pta',
    icon: 'fa fa-stethoscope',
    title: 'global.menu.cidpta',
    role: ['cid-pta', 'clinical-indicator', 'activity'],
    children: [
      { path: '/cid-pta', title: 'generadorApp.cidPta.home.title', role: ['cid-pta'] },
      { path: '/clinical-indicator', title: 'generadorApp.clinicalIndicator.home.title', role: ['clinical-indicator'] },
      { path: '/activity', title: 'generadorApp.activity.home.title', role: ['activity'] },
    ],
  },

  {
    path: '/pad',
    icon: 'fa fa-user-md',
    title: 'generadorApp.pad.home.title',
    role: ['pad'],
  },
  {
    path: '/romaneio',
    icon: 'fa fa-user-md',
    title: 'generadorApp.romaneio.home.title',
    role: ['romaneio'],
  },
  {
    path: '/push',
    icon: 'fa fa-bell',
    title: 'global.menu.push',
    role: ['push'],
  },
  {
    path: '/suprimento',
    icon: 'fa fa-user-md',
    title: 'generadorApp.supply.home.title',
    role: ['supply'],
  },
  {
    path: '/pharmacy',
    icon: 'fa fa-medkit',
    title: 'global.menu.pharmacy',
    role: ['nfe', 'pharmacy-stock', 'pharmacy-inventory', 'pharmacy-inventory-loss', 'pharmacy-stock-devolution'],
    children: [
      { path: '/nfe', title: 'generadorApp.nfe.home.title', role: ['nfe'] },
      { path: '/pharmacy-stock', title: 'generadorApp.pharmacyStock.home.title', role: ['pharmacy-stock'] },
      { path: '/pharmacy-inventory', title: 'generadorApp.pharmacyInventories.home.title', role: ['pharmacy-inventory'] },
      { path: '/pharmacy-deposit', title: 'generadorApp.pharmacyInventories.home.titleDeposit', role: ['pharmacy-deposit'] },
    ],
  },
  {
    path: '/quotes',
    icon: 'fa fa-user-md',
    title: 'generadorApp.quotes.home.title',
    role: ['quote'],
    children: [{ path: '/quote', title: 'generadorApp.quote.home.title', role: ['quote'] }],
  },
  {
    path: '/reports',
    icon: 'fa fa-book',
    title: 'generadorApp.quotes.home.report',
    role: ['reports'],
  },
  {
    path: '/bills',
    icon: 'fa fa-user-md',
    title: 'global.menu.billing',
    role: ['bills'],
  },
  {
    path: '/audit',
    icon: 'fa fa-user-md',
    title: 'global.menu.audit',
    role: ['audit'],
    children: [
      { path: '/client-glosa', title: 'generadorApp.clientGlosa.home.title', role: [] },
      { path: '/supplier-glosa', title: 'generadorApp.supplierGlosa.home.title', role: [] },
    ],
  },
  {
    path: '/audit',
    icon: 'fa fa-user-md',
    title: 'global.menu.financier',
    role: ['audit'],
    children: [
      { path: '/cash-flow', title: 'generadorApp.cashFlow.home.title', role: [] },
      { path: '/billing-xml', title: 'generadorApp.billingXml.home.title', role: [] },
      { path: '/bill-to-pay', title: 'generadorApp.billToPay.home.title', role: [] },
    ],
  },
];

export default Menu;
