/* eslint complexity: ["error", 500] */
/* eslint no-empty: off */
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import SelectAsync from 'react-select/async';

import { Link, RouteComponentProps } from 'react-router-dom';

import { getFilterFromSelect, getFormFromSelect, getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Col, Label } from 'reactstrap';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import {
  INursingPrescriptionUpdateState as IBaseUpdateState,
  getEntity,
  getNursingPrescriptionState,
  getUrlBack,
  updateEntity,
  createEntity,
  getEntityFiltersURL,
  getEntityOffset,
  reset,
} from './nursing-prescription.reducer';

import { convertDateTimeToServer } from 'app/shared/util/date-utils';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';
import moment from 'moment';
registerLocale('pt', pt);

export interface INursingPrescriptionUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}
export type INursingPrescriptionUpdateState = IBaseUpdateState;

export class NursingPrescriptionUpdate extends React.Component<INursingPrescriptionUpdateProps, INursingPrescriptionUpdateState> {
  descriptionFileInput: any;

  startFileInput: any;

  startTimeFileInput: any;

  frequencyFileInput: any;
  constructor(props: Readonly<INursingPrescriptionUpdateProps>) {
    super(props);

    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      functionEmbebed: [],

      patientSelectValue: null,

      descriptionSelectValue: null,
      startSelectValue: null,
      startTimeSelectValue: null,
      frequencySelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getNursingPrescriptionState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
      patientName: null,

      isNew: !this.props.match.params || !this.props.match.params['id'],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateSuccess !== this.props.updateSuccess && prevProps.updateSuccess) {
      this.handleClose();
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.nursingPrescriptionEntity && prevProps.nursingPrescriptionEntity.id !== this.props.nursingPrescriptionEntity.id) ||
      (this.props.patients &&
        this.state.patientSelectValue === null &&
        this.props.nursingPrescriptionEntity.patient &&
        this.props.nursingPrescriptionEntity.patient.id)
    ) {
      const p =
        this.props.nursingPrescriptionEntity && this.props.nursingPrescriptionEntity.patient
          ? this.props.nursingPrescriptionEntity.patient
          : null;
      this.setState({
        patientSelectValue: p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : null,
      });
    }

    if (
      (prevProps.nursingPrescriptionEntity && prevProps.nursingPrescriptionEntity.id !== this.props.nursingPrescriptionEntity.id) ||
      (this.props.nursingPrescriptionEntity.description &&
        this.state.descriptionSelectValue === null &&
        this.props.nursingPrescriptionEntity.description !== this.state.descriptionSelectValue)
    ) {
      this.setState({
        descriptionSelectValue: this.props.nursingPrescriptionEntity.description ? this.props.nursingPrescriptionEntity.description : null,
      });
    }
    if (
      (prevProps.nursingPrescriptionEntity && prevProps.nursingPrescriptionEntity.id !== this.props.nursingPrescriptionEntity.id) ||
      (this.props.nursingPrescriptionEntity.start &&
        this.state.startSelectValue === null &&
        this.props.nursingPrescriptionEntity.start !== this.state.startSelectValue)
    ) {
      this.setState({ startSelectValue: this.props.nursingPrescriptionEntity.start ? this.props.nursingPrescriptionEntity.start : null });
    }
    if (
      (prevProps.nursingPrescriptionEntity && prevProps.nursingPrescriptionEntity.id !== this.props.nursingPrescriptionEntity.id) ||
      (this.props.nursingPrescriptionEntity.startTime &&
        this.state.startTimeSelectValue === null &&
        this.props.nursingPrescriptionEntity.startTime !== this.state.startTimeSelectValue)
    ) {
      this.setState({
        startTimeSelectValue: this.props.nursingPrescriptionEntity.startTime ? this.props.nursingPrescriptionEntity.startTime : null,
      });
    }
    if (
      (prevProps.nursingPrescriptionEntity && prevProps.nursingPrescriptionEntity.id !== this.props.nursingPrescriptionEntity.id) ||
      (this.props.nursingPrescriptionEntity.frequency &&
        this.state.frequencySelectValue === null &&
        this.props.nursingPrescriptionEntity.frequency !== this.state.frequencySelectValue)
    ) {
      this.setState({
        frequencySelectValue: this.props.nursingPrescriptionEntity.frequency ? this.props.nursingPrescriptionEntity.frequency : null,
      });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  UNSAFE_componentWillMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params['id']);
    }
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = offset !== null ? '&offset=' + offset : '';
    Object.keys(fieldsBase).map(key => {
      url += key !== 'offset' ? '&' + key + '=' + fieldsBase[key] : '';
    });
    return url;
  };
  saveEntity = (event: any, errors: any, values: any) => {
    const selectFieldsList = ['description', 'start', 'startTime', 'patient.name', 'frequency'];

    values.start = convertDateTimeToServer(values.start);

    if (errors.length === 0) {
      const { nursingPrescriptionEntity } = this.props;
      const _entity = {
        ...nursingPrescriptionEntity,
        // ...values,

        patient: getFormFromSelect(this.state.patientSelectValue, 'many-to-one'), // many-to-one - otherSide

        description: this.state.descriptionSelectValue,
        start: this.state.startSelectValue,
        startTime: this.state.startTimeSelectValue,
        frequency: this.state.frequencySelectValue === null ? null : this.state.frequencySelectValue,
      };

      const entity = _entity;

      const {
        description,
        startStart,
        startEnd,
        startTime,
        patientId,
        frequency,
        extraFilters,
        activePage,
        itemsPerPage,
        sort,
        order,
      } = this.state.fieldsBase;

      if (this.state.isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              description,
              startStart,
              startEnd,
              startTime,
              getFilterFromSelect(patientId, 'many-to-one'),
              frequency,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              description,
              startStart,
              startEnd,
              startTime,
              getFilterFromSelect(patientId, 'many-to-one'),
              frequency,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/nursing-prescription?' + getEntityFiltersURL(this.state.fieldsBase));
  };

  getFullEntityFromState(v) {
    return {
      id: v.id,
      description: this.state.descriptionSelectValue,
      start: this.state.startSelectValue,
      startTime: this.state.startTimeSelectValue,
      frequency: this.state.frequencySelectValue,
      patient: this.state.patientSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    const patientEntity = v.patient;

    return {
      descriptionSelectValue: v.description,
      startSelectValue: v.start,
      startTimeSelectValue: v.startTime,
      frequencySelectValue: v.frequency,
      patientSelectValue: patientEntity ? patientEntity['id'] : null,
    };
  }

  renderHeader() {
    const { nursingPrescriptionEntity, patients, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.nursingPrescription.home.createLabel">Create a NursingPrescription</Translate>
            ) : (
              <Translate contentKey="generadorApp.nursingPrescription.home.editLabel">Edit a NursingPrescription</Translate>
            )}
          </span>

          <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.nursingPrescription.btnSave">Save</Translate>
          </Button>

          {!this.state.isNew ? (
            <>
              <Button
                color="primary"
                id="next-entity"
                disabled={updating}
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.description,
                    this.state.fieldsBase.startStart,
                    this.state.fieldsBase.startEnd,
                    this.state.fieldsBase.startTime,
                    this.state.fieldsBase.patientId,
                    this.state.fieldsBase.frequency,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 + 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 + 1 },
                    });
                    this.props.history.push(
                      '/nursing-prescription/' +
                        myresult.value.data[0]['id'] +
                        '/edit?' +
                        this.getFiltersURL(this.state.fieldsBase.offset * 1 + 1)
                    );
                  } else {
                    toast.error(
                      translate('generadorApp.last.item', { fieldName: translate('generadorApp.nursingPrescription.detail.title') })
                    );
                  }
                }}
                className="float-right jh-create-entity"
              >
                {'>>'}
              </Button>

              <Button
                color="primary"
                id="prev-entity"
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.description,
                    this.state.fieldsBase.startStart,
                    this.state.fieldsBase.startEnd,
                    this.state.fieldsBase.startTime,
                    this.state.fieldsBase.patientId,
                    this.state.fieldsBase.frequency,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 - 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 - 1 },
                    });
                    this.props.history.push(
                      '/nursing-prescription/' +
                        myresult.value.data[0]['id'] +
                        '/edit?' +
                        this.getFiltersURL(this.state.fieldsBase.offset * 1 - 1)
                    );
                  } else {
                    toast.error(
                      translate('generadorApp.first.item', { fieldName: translate('generadorApp.nursingPrescription.detail.title') })
                    );
                  }
                }}
                disabled={updating}
                className="float-right jh-create-entity"
              >
                {'<<'}
              </Button>
            </>
          ) : (
            <> </>
          )}

          <Button
            tag={Link}
            id="cancel-save"
            to={'/nursing-prescription?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.nursingPrescription.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={'/nursing-prescription'}>
              <Translate contentKey="generadorApp.nursingPrescription.home.title">Nursing Prescriptions</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.nursingPrescription.detail.title">Nursing Prescription edit</Translate>
          </li>
        </ol>
      </>
    );
  }
  renderBody() {
    const { nursingPrescriptionEntity, patients, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const fullEntityEmbebed = this.getFullEntityFromState(nursingPrescriptionEntity);

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="nursing-prescription-id">
                  <Translate contentKey="generadorApp.nursingPrescription.field_id">ID</Translate>
                </Label>
                </Col> */}
                      <Col md="12">
                        <AvInput id="nursing-prescription-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'description' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="descriptionLabel" for="nursing-prescription-description">
                              <Translate contentKey="generadorApp.nursingPrescription.description">Description</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ descriptionSelectValue: evt.target.value })}
                              value={this.state.descriptionSelectValue ? this.state.descriptionSelectValue : ''}
                              id="nursing-prescription-description"
                              type="text"
                              name="description"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ descriptionSelectValue: evt.target.value })}
                      type="hidden"
                      name="description"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'start' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="startLabel" for="nursing-prescription-start">
                              <Translate contentKey="generadorApp.nursingPrescription.start">Start</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <DatePicker
                              autoComplete="off"
                              onChange={value => this.setState({ startSelectValue: value })}
                              id="start"
                              validate={{}}
                              className="form-control react-datepicker"
                              name="start"
                              dateFormat="dd/MM/yyyy"
                              selected={this.state.startSelectValue ? moment(this.state.startSelectValue).toDate() : null}
                              locale="pt"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ startSelectValue: evt.target.value })}
                      type="hidden"
                      name="start"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'startTime' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="startTimeLabel" for="nursing-prescription-startTime">
                              <Translate contentKey="generadorApp.nursingPrescription.startTime">Start Time</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ startTimeSelectValue: evt.target.value })}
                              value={this.state.startTimeSelectValue ? this.state.startTimeSelectValue : ''}
                              id="nursing-prescription-startTime"
                              type="text"
                              name="startTime"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ startTimeSelectValue: evt.target.value })}
                      type="hidden"
                      name="startTime"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'patient' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="nursing-prescription-patient">
                              <Translate contentKey="generadorApp.nursingPrescription.patient">Patient</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="nursing-prescription-patient"
                              name={'patient'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.patientSelectValue ? this.state.patientSelectValue : ''}
                              onChange={options => this.setState({ patientSelectValue: options })}
                              defaultOptions={this.state.patientStartSelectOptions ? this.state.patientStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.patientStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.patientStartSelectOptions === undefined) {
                                  const result = await getListAxios('patients', {}, 0, 100, 'name,asc', 'name');
                                  this.setState({
                                    patientStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios('patients', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="patient"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}

                  {baseFilters !== 'frequency' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="frequencyLabel" for="nursing-prescription-frequency">
                              <Translate contentKey="generadorApp.nursingPrescription.frequency">Frequency</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="nursing-prescription-frequency"
                              className={'css-select-control'}
                              value={
                                this.state.frequencySelectValue
                                  ? {
                                      value: this.state.frequencySelectValue,
                                      label: translate('generadorApp.QuoteFrequency.' + this.state.frequencySelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.nursingPrescription.frequency') }
                              }
                              options={[
                                { value: 'UMXSEMANA', label: translate('generadorApp.QuoteFrequency.UMXSEMANA') },
                                { value: 'DOISXSEMANA', label: translate('generadorApp.QuoteFrequency.DOISXSEMANA') },
                                { value: 'TRESXSEMANA', label: translate('generadorApp.QuoteFrequency.TRESXSEMANA') },
                                { value: 'QUATROXSEMANA', label: translate('generadorApp.QuoteFrequency.QUATROXSEMANA') },
                                { value: 'CINCOXSEMANA', label: translate('generadorApp.QuoteFrequency.CINCOXSEMANA') },
                                { value: 'SEISXSEMANA', label: translate('generadorApp.QuoteFrequency.SEISXSEMANA') },
                                { value: 'UMXDIA', label: translate('generadorApp.QuoteFrequency.UMXDIA') },
                                { value: 'DOISXDIA', label: translate('generadorApp.QuoteFrequency.DOISXDIA') },
                                { value: 'TRESXDIA', label: translate('generadorApp.QuoteFrequency.TRESXDIA') },
                                { value: 'QUATROXDIA', label: translate('generadorApp.QuoteFrequency.QUATROXDIA') },
                                { value: 'CINCOXDIA', label: translate('generadorApp.QuoteFrequency.CINCOXDIA') },
                                { value: 'SEISXDIA', label: translate('generadorApp.QuoteFrequency.SEISXDIA') },
                                { value: 'UMXMES', label: translate('generadorApp.QuoteFrequency.UMXMES') },
                                { value: 'DOISXMES', label: translate('generadorApp.QuoteFrequency.DOISXMES') },
                                { value: 'TRESXMES', label: translate('generadorApp.QuoteFrequency.TRESXMES') },
                                { value: 'QUATROXMES', label: translate('generadorApp.QuoteFrequency.QUATROXMES') },
                                { value: 'CADA48HORAS', label: translate('generadorApp.QuoteFrequency.CADA48HORAS') },
                                { value: 'CADA72HORAS', label: translate('generadorApp.QuoteFrequency.CADA72HORAS') },
                                { value: 'PONTUAL', label: translate('generadorApp.QuoteFrequency.PONTUAL') },
                              ]}
                              onChange={(options: any) => this.setState({ frequencySelectValue: options['value'] })}
                              name="frequency"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ frequencySelectValue: evt.target.value })}
                      type="hidden"
                      name="frequency"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { nursingPrescriptionEntity, patients, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...nursingPrescriptionEntity,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel className="nursing-prescription nursing-prescription-update">
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToProps = (storeState: IRootState) => {
  return {
    patients: storeState.patient.entities,
    nursingPrescriptionEntity: storeState.nursingPrescription.entity,
    loading: storeState.nursingPrescription.loading,
    updating: storeState.nursingPrescription.updating,
    updateSuccess: storeState.nursingPrescription.updateSuccess,
  };
};

export const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  getEntityOffset,
  reset,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(NursingPrescriptionUpdate);
