/* eslint-disable no-console */
/* eslint complexity: ["error", 300] */
import React from 'react';
import { connect } from 'react-redux';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { Button, Col, Row, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';

import { setFileData } from 'react-jhipster';
import { Simpro, mapStateToProps, mapDispatchToProps } from '../simpro';
import { importFile } from '../extended/simpro.reducer';
import { IRootState } from 'app/shared/reducers';
import { Translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { setBlob } from '../../user-file/user-file.reducer';
import Axios from 'axios';

export interface IExtendedState {
  openModalImportFile?: boolean;
  nameSelectValue?: any;
  filenameSelectValue?: any;
  openModalLoading?: boolean;
  openModalLoadingModal?: boolean;
}
export interface IExtendedProps {
  entityEmbebedPai?: any;
}

export class SimproExtended extends Simpro {
  private myFormImportFile: any;

  nameFileInput: React.RefObject<HTMLInputElement>;

  filenameFileInput: any;

  constructor(props: any) {
    super(props);
    this.renderBodyUpdate = this.renderBodyUpdate.bind(this);
    this.state = {
      ...this.state, // Mantém os outros estados já definidos
      dataArray: [] // Tipando o dataArray como any[]
    };
  }

  setBlob(name, data, contentType?, fileName?) {
    this.setState({ nameSelectValue: name });
    this.setState({ filenameSelectValue: data });
  }

  onBlobChange = (isAnImage, name, fileInput) => event => {
    this.setState({ openModalLoadingModal: true })
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = async () => {
      const txtContent = reader.result;
      const lines = txtContent.split('\r\n');
  
      for (let i = 0; i < lines.length; i++) {
        const line = lines[i];
        const values = line.split('|');
  
        const obj = {
          cdUsuario: values[0]?.trim(),
          cdFracao: values[1]?.trim(),
          descricao: values[2]?.trim(),
          vigencia: values[3]?.trim(),
          identif: values[4]?.trim(),
          pcEmFab: values[5]?.trim(),
          pcEmVen: values[6]?.trim(),
          pcEmUsu: values[7]?.trim(),
          pcFrFab: values[8]?.trim(),
          pcFrVen: values[9]?.trim(),
          pcFrUsu: values[10]?.trim(),
          tpEmbal: values[11]?.trim(),
          tpFracao: values[12]?.trim(),
          qtdeEmbal: values[13]?.trim(),
        };
        this.setState(prevState => ({
          dataArray: [...prevState.dataArray, obj]
        }));

        if (i === lines.length - 1) {
          // Ação a ser executada quando todos os arquivos forem processados
          this.setState({ openModalLoadingModal: false })
          console.log("Todos os arquivos foram processados.");
        }
      }
    };
    reader.readAsText(file);
  };


  
  save = async () => {
    this.setState({ openModalImportFile: false })
    this.setState({ openModalLoading: true })
    try {
      const response = await Axios.post(
        (process.env.NODE_HTS_SYS_URL + 'api/simpros/import-file').replace("undefined", ""),
        {
          brasindices: this.state.dataArray, // Enviar a array para o endpoint
        }
      );  
      if(response.data.status === "terminou"){
        this.setState({ openModalLoading: false })
      } // Exibir a resposta do servidor
    } catch (error) {
      console.error('Erro ao enviar dados:');
      console.error(error);
    }
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined);
  };

  renderHeader() {
    const { medicineMaterials, simproList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.simpro.home.title">Simpros</Translate>
          </span>
          <Button id="togglerFilterSimpro" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.simpro.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          &nbsp;
          <Button
            onClick={() => this.setState({ openModalImportFile: true })}
            color="primary"
            className="float-right jh-create-entity"
            id="jh-create-entity"
          >
            <FontAwesomeIcon icon="upload" />
            &nbsp;
            <Translate contentKey="generadorApp.simpro.btnImportFile">Import File</Translate>
          </Button>{' '}
          &nbsp;
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.simpro.home.title">Simpros</Translate>
          </li>
        </ol>
      </>
    );
  }

  saveEntity = (event: any, errors: any, values: any, isNew: any) => {
    if (errors.length === 0) {
      const entity = {
        brasindiceType: this.state.nameSelectValue,
        filenameBase64: this.state.filenameSelectValue,
      };

      new Promise(resolve => {
        resolve(this.props.importFile(entity, ['', '', '']));
      }).then(resultEntity => {
        const idEntity =
          resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
            ? resultEntity['value']['data']['id']
            : -1;
      });
    }
    this.setState({ openModalImportFile: false });
  };

  renderImportFileModal() {
    return (
      <Modal
        size={'lg'}
        isOpen={this.state.openModalImportFile}
        toggle={() => this.setState({ openModalImportFile: this.state.openModalImportFile })}
      >
        <ModalHeader toggle={() => this.setState({ openModalImportFile: false })}>
          <Translate contentKey="generadorApp.simpro.btnImportFile">Import File</Translate>
        </ModalHeader>
        <AvForm
          ref={el => (this.myFormImportFile = el)}
          model={{}}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, false)}
        >
          <ModalBody>{this.renderBodyUpdate({}, false)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ openModalImportFile: null })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.userFile.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.save()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.userFile.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
        {this.state.openModalLoadingModal === true ?
          <div style={{ position: "absolute", height: "100vh", width: "100%", backgroundColor: "transparent", zIndex: 10, display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div style={{ height: 300, width: 500, backgroundColor: "white", marginRight: "20%", borderRadius: 10, position: "relative", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
              <text style={{ fontSize: 15 }}>Aguarde em quanto estamos processando sua tabela</text>
              <text style={{ marginBottom: 20, fontSize: 15 }}>Isso pode demorar um pouco...</text>
              <div className="loading-spinner"></div>
            </div>
          </div> : null
        }
      </Modal>
    );
  }

  renderBodyUpdate(userFileEntity, isNew) {
    const { loading } = this.props;

    const { filename, filenameContentType, filenameBase64 } = userFileEntity;

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                <Row>
                  <Col md="6">
                    <AvGroup>
                      <Row>
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="filenameLabel" for="filename">
                                  <Translate contentKey="generadorApp.userFile.filename">Filename</Translate>(*)
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  id="file_filename"
                                  type="file"
                                  ref={el => (this.filenameFileInput = el)}
                                  onChange={this.onBlobChange(false, 'filename', this.filenameFileInput)}
                                  accept="*"
                                />
                                <label htmlFor="file_filename" className="file_btn_open">
                                  <FontAwesomeIcon icon="upload" />
                                  <Translate contentKey="generadorApp.simpro.btnImportFile">Upload Filename </Translate>
                                </label>
                                <AvInput
                                  onChange={evt => this.setState({ filenameSelectValue: evt.target.value })}
                                  type="hidden"
                                  name="filename"
                                  value={filename}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      </Row>
                    </AvGroup>
                  </Col>{' '}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  renderFilter() {
    return <> {super.renderFilter()} </>;
  }

  renderTable() {
    return <> {super.renderTable()} </>;
  }

  renderFooter() {
    return <> {super.renderFooter()} </>;
  }

  render() {
    return (
      <Panel>
            {this.state.openModalLoading === true ?
          <div style={{ position: "absolute", height: "100vh", width: "100%", backgroundColor: "transparent", zIndex: 10, display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div style={{ height: 300, width: 500, backgroundColor: "white", marginRight: "20%", borderRadius: 10, position: "relative", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
              <text style={{ fontSize: 15 }}>Aguarde em quanto estamos salvando sua tabela</text>
              <text style={{ marginBottom: 20, fontSize: 15 }}>Isso pode demorar um pouco...</text>
              <div className="loading-spinner"></div>
            </div>
          </div> : null
        }
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
            {this.renderImportFileModal()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
  setBlob,
  importFile,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;

export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(SimproExtended);
