/* eslint complexity: ["error", 500] */
/* eslint no-empty: off */
import React from 'react';
import { connect } from 'react-redux';

import { Link, RouteComponentProps } from 'react-router-dom';

import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Col, Label } from 'reactstrap';

import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import {
  IPermissionUpdateState as IBaseUpdateState,
  getEntity,
  getPermissionState,
  getUrlBack,
  updateEntity,
  createEntity,
  getEntityFiltersURL,
  getEntityOffset,
  reset,
} from './permission.reducer';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

registerLocale('pt', pt);

export interface IPermissionUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}
export type IPermissionUpdateState = IBaseUpdateState;

export class PermissionUpdate extends React.Component<IPermissionUpdateProps, IPermissionUpdateState> {
  slugFileInput: any;

  nameFileInput: any;

  viewFileInput: any;

  resgisterFileInput: any;

  editFileInput: any;

  canDeleteFileInput: any;

  manualRemovalFileInput: any;

  editLocationFileInput: any;

  reportFileInput: any;

  activateProfessionalFileInput: any;

  deletePhotoFileInput: any;

  financialValueFileInput: any;

  valueAuthorizationFileInput: any;

  confirmPaymentFileInput: any;

  evolutionFileInput: any;

  professionalNameFileInput: any;

  professionalRegisterFileInput: any;

  contactFileInput: any;

  statusFileInput: any;

  fileFileInput: any;

  comercialTableFileInput: any;

  drPrescriptionFileInput: any;

  nrPrescriptionFileInput: any;

  clinicalIndicatorsFileInput: any;

  importTableFileInput: any;
  constructor(props: Readonly<IPermissionUpdateProps>) {
    super(props);

    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      functionEmbebed: [],

      permissionProfileSelectValue: null,

      permissionUserSelectValue: null,

      slugSelectValue: null,
      nameSelectValue: null,
      viewSelectValue: null,
      resgisterSelectValue: null,
      editSelectValue: null,
      canDeleteSelectValue: null,
      manualRemovalSelectValue: null,
      editLocationSelectValue: null,
      reportSelectValue: null,
      activateProfessionalSelectValue: null,
      deletePhotoSelectValue: null,
      financialValueSelectValue: null,
      valueAuthorizationSelectValue: null,
      confirmPaymentSelectValue: null,
      evolutionSelectValue: null,
      professionalNameSelectValue: null,
      professionalRegisterSelectValue: null,
      contactSelectValue: null,
      statusSelectValue: null,
      fileSelectValue: null,
      comercialTableSelectValue: null,
      drPrescriptionSelectValue: null,
      nrPrescriptionSelectValue: null,
      clinicalIndicatorsSelectValue: null,
      importTableSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getPermissionState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },

      isNew: !this.props.match.params || !this.props.match.params['id'],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateSuccess !== this.props.updateSuccess && prevProps.updateSuccess) {
      this.handleClose();
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.permissionEntity && prevProps.permissionEntity.id !== this.props.permissionEntity.id) ||
      (this.props.permissionProfiles.length > 0 &&
        this.state.permissionProfileSelectValue === null &&
        this.props.permissionEntity.permissionProfile)
    ) {
      this.setState({
        permissionProfileSelectValue:
          this.props.permissionEntity && this.props.permissionEntity.permissionProfile
            ? this.props.permissionEntity.permissionProfile.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.permissionEntity && prevProps.permissionEntity.id !== this.props.permissionEntity.id) ||
      (this.props.permissionUsers.length > 0 && this.state.permissionUserSelectValue === null && this.props.permissionEntity.permissionUser)
    ) {
      this.setState({
        permissionUserSelectValue:
          this.props.permissionEntity && this.props.permissionEntity.permissionUser
            ? this.props.permissionEntity.permissionUser.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    if (
      (prevProps.permissionEntity && prevProps.permissionEntity.id !== this.props.permissionEntity.id) ||
      (this.props.permissionEntity.slug &&
        this.state.slugSelectValue === null &&
        this.props.permissionEntity.slug !== this.state.slugSelectValue)
    ) {
      this.setState({ slugSelectValue: this.props.permissionEntity.slug ? this.props.permissionEntity.slug : null });
    }
    if (
      (prevProps.permissionEntity && prevProps.permissionEntity.id !== this.props.permissionEntity.id) ||
      (this.props.permissionEntity.name &&
        this.state.nameSelectValue === null &&
        this.props.permissionEntity.name !== this.state.nameSelectValue)
    ) {
      this.setState({ nameSelectValue: this.props.permissionEntity.name ? this.props.permissionEntity.name : null });
    }
    if (
      (prevProps.permissionEntity && prevProps.permissionEntity.id !== this.props.permissionEntity.id) ||
      (this.state.viewSelectValue === null && this.props.permissionEntity.view !== this.state.viewSelectValue)
    ) {
      this.setState({ viewSelectValue: this.props.permissionEntity.view ? this.props.permissionEntity.view : false });
    }
    if (
      (prevProps.permissionEntity && prevProps.permissionEntity.id !== this.props.permissionEntity.id) ||
      (this.state.resgisterSelectValue === null && this.props.permissionEntity.resgister !== this.state.resgisterSelectValue)
    ) {
      this.setState({ resgisterSelectValue: this.props.permissionEntity.resgister ? this.props.permissionEntity.resgister : false });
    }
    if (
      (prevProps.permissionEntity && prevProps.permissionEntity.id !== this.props.permissionEntity.id) ||
      (this.state.editSelectValue === null && this.props.permissionEntity.edit !== this.state.editSelectValue)
    ) {
      this.setState({ editSelectValue: this.props.permissionEntity.edit ? this.props.permissionEntity.edit : false });
    }
    if (
      (prevProps.permissionEntity && prevProps.permissionEntity.id !== this.props.permissionEntity.id) ||
      (this.state.canDeleteSelectValue === null && this.props.permissionEntity.canDelete !== this.state.canDeleteSelectValue)
    ) {
      this.setState({ canDeleteSelectValue: this.props.permissionEntity.canDelete ? this.props.permissionEntity.canDelete : false });
    }
    if (
      (prevProps.permissionEntity && prevProps.permissionEntity.id !== this.props.permissionEntity.id) ||
      (this.state.manualRemovalSelectValue === null && this.props.permissionEntity.manualRemoval !== this.state.manualRemovalSelectValue)
    ) {
      this.setState({
        manualRemovalSelectValue: this.props.permissionEntity.manualRemoval ? this.props.permissionEntity.manualRemoval : false,
      });
    }
    if (
      (prevProps.permissionEntity && prevProps.permissionEntity.id !== this.props.permissionEntity.id) ||
      (this.state.editLocationSelectValue === null && this.props.permissionEntity.editLocation !== this.state.editLocationSelectValue)
    ) {
      this.setState({
        editLocationSelectValue: this.props.permissionEntity.editLocation ? this.props.permissionEntity.editLocation : false,
      });
    }
    if (
      (prevProps.permissionEntity && prevProps.permissionEntity.id !== this.props.permissionEntity.id) ||
      (this.state.reportSelectValue === null && this.props.permissionEntity.report !== this.state.reportSelectValue)
    ) {
      this.setState({ reportSelectValue: this.props.permissionEntity.report ? this.props.permissionEntity.report : false });
    }
    if (
      (prevProps.permissionEntity && prevProps.permissionEntity.id !== this.props.permissionEntity.id) ||
      (this.state.activateProfessionalSelectValue === null &&
        this.props.permissionEntity.activateProfessional !== this.state.activateProfessionalSelectValue)
    ) {
      this.setState({
        activateProfessionalSelectValue: this.props.permissionEntity.activateProfessional
          ? this.props.permissionEntity.activateProfessional
          : false,
      });
    }
    if (
      (prevProps.permissionEntity && prevProps.permissionEntity.id !== this.props.permissionEntity.id) ||
      (this.state.deletePhotoSelectValue === null && this.props.permissionEntity.deletePhoto !== this.state.deletePhotoSelectValue)
    ) {
      this.setState({ deletePhotoSelectValue: this.props.permissionEntity.deletePhoto ? this.props.permissionEntity.deletePhoto : false });
    }
    if (
      (prevProps.permissionEntity && prevProps.permissionEntity.id !== this.props.permissionEntity.id) ||
      (this.state.financialValueSelectValue === null && this.props.permissionEntity.financialValue !== this.state.financialValueSelectValue)
    ) {
      this.setState({
        financialValueSelectValue: this.props.permissionEntity.financialValue ? this.props.permissionEntity.financialValue : false,
      });
    }
    if (
      (prevProps.permissionEntity && prevProps.permissionEntity.id !== this.props.permissionEntity.id) ||
      (this.state.valueAuthorizationSelectValue === null &&
        this.props.permissionEntity.valueAuthorization !== this.state.valueAuthorizationSelectValue)
    ) {
      this.setState({
        valueAuthorizationSelectValue: this.props.permissionEntity.valueAuthorization
          ? this.props.permissionEntity.valueAuthorization
          : false,
      });
    }
    if (
      (prevProps.permissionEntity && prevProps.permissionEntity.id !== this.props.permissionEntity.id) ||
      (this.state.confirmPaymentSelectValue === null && this.props.permissionEntity.confirmPayment !== this.state.confirmPaymentSelectValue)
    ) {
      this.setState({
        confirmPaymentSelectValue: this.props.permissionEntity.confirmPayment ? this.props.permissionEntity.confirmPayment : false,
      });
    }
    if (
      (prevProps.permissionEntity && prevProps.permissionEntity.id !== this.props.permissionEntity.id) ||
      (this.state.evolutionSelectValue === null && this.props.permissionEntity.evolution !== this.state.evolutionSelectValue)
    ) {
      this.setState({ evolutionSelectValue: this.props.permissionEntity.evolution ? this.props.permissionEntity.evolution : false });
    }
    if (
      (prevProps.permissionEntity && prevProps.permissionEntity.id !== this.props.permissionEntity.id) ||
      (this.state.professionalNameSelectValue === null &&
        this.props.permissionEntity.professionalName !== this.state.professionalNameSelectValue)
    ) {
      this.setState({
        professionalNameSelectValue: this.props.permissionEntity.professionalName ? this.props.permissionEntity.professionalName : false,
      });
    }
    if (
      (prevProps.permissionEntity && prevProps.permissionEntity.id !== this.props.permissionEntity.id) ||
      (this.state.professionalRegisterSelectValue === null &&
        this.props.permissionEntity.professionalRegister !== this.state.professionalRegisterSelectValue)
    ) {
      this.setState({
        professionalRegisterSelectValue: this.props.permissionEntity.professionalRegister
          ? this.props.permissionEntity.professionalRegister
          : false,
      });
    }
    if (
      (prevProps.permissionEntity && prevProps.permissionEntity.id !== this.props.permissionEntity.id) ||
      (this.state.contactSelectValue === null && this.props.permissionEntity.contact !== this.state.contactSelectValue)
    ) {
      this.setState({ contactSelectValue: this.props.permissionEntity.contact ? this.props.permissionEntity.contact : false });
    }
    if (
      (prevProps.permissionEntity && prevProps.permissionEntity.id !== this.props.permissionEntity.id) ||
      (this.state.statusSelectValue === null && this.props.permissionEntity.status !== this.state.statusSelectValue)
    ) {
      this.setState({ statusSelectValue: this.props.permissionEntity.status ? this.props.permissionEntity.status : false });
    }
    if (
      (prevProps.permissionEntity && prevProps.permissionEntity.id !== this.props.permissionEntity.id) ||
      (this.state.fileSelectValue === null && this.props.permissionEntity.file !== this.state.fileSelectValue)
    ) {
      this.setState({ fileSelectValue: this.props.permissionEntity.file ? this.props.permissionEntity.file : false });
    }
    if (
      (prevProps.permissionEntity && prevProps.permissionEntity.id !== this.props.permissionEntity.id) ||
      (this.state.comercialTableSelectValue === null && this.props.permissionEntity.comercialTable !== this.state.comercialTableSelectValue)
    ) {
      this.setState({
        comercialTableSelectValue: this.props.permissionEntity.comercialTable ? this.props.permissionEntity.comercialTable : false,
      });
    }
    if (
      (prevProps.permissionEntity && prevProps.permissionEntity.id !== this.props.permissionEntity.id) ||
      (this.state.drPrescriptionSelectValue === null && this.props.permissionEntity.drPrescription !== this.state.drPrescriptionSelectValue)
    ) {
      this.setState({
        drPrescriptionSelectValue: this.props.permissionEntity.drPrescription ? this.props.permissionEntity.drPrescription : false,
      });
    }
    if (
      (prevProps.permissionEntity && prevProps.permissionEntity.id !== this.props.permissionEntity.id) ||
      (this.state.nrPrescriptionSelectValue === null && this.props.permissionEntity.nrPrescription !== this.state.nrPrescriptionSelectValue)
    ) {
      this.setState({
        nrPrescriptionSelectValue: this.props.permissionEntity.nrPrescription ? this.props.permissionEntity.nrPrescription : false,
      });
    }
    if (
      (prevProps.permissionEntity && prevProps.permissionEntity.id !== this.props.permissionEntity.id) ||
      (this.state.clinicalIndicatorsSelectValue === null &&
        this.props.permissionEntity.clinicalIndicators !== this.state.clinicalIndicatorsSelectValue)
    ) {
      this.setState({
        clinicalIndicatorsSelectValue: this.props.permissionEntity.clinicalIndicators
          ? this.props.permissionEntity.clinicalIndicators
          : false,
      });
    }
    if (
      (prevProps.permissionEntity && prevProps.permissionEntity.id !== this.props.permissionEntity.id) ||
      (this.state.importTableSelectValue === null && this.props.permissionEntity.importTable !== this.state.importTableSelectValue)
    ) {
      this.setState({ importTableSelectValue: this.props.permissionEntity.importTable ? this.props.permissionEntity.importTable : false });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  UNSAFE_componentWillMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params['id']);
    }
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = offset !== null ? '&offset=' + offset : '';
    Object.keys(fieldsBase).map(key => {
      url += key !== 'offset' ? '&' + key + '=' + fieldsBase[key] : '';
    });
    return url;
  };
  saveEntity = (event: any, errors: any, values: any) => {
    if (!this.state.slugSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.permission.slug') }));
      return false;
    }

    if (!this.state.nameSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.permission.name') }));
      return false;
    }

    const selectFieldsList = ['slug', 'name'];

    if (errors.length === 0) {
      const { permissionEntity } = this.props;
      const _entity = {
        ...permissionEntity,
        // ...values,

        slug: this.state.slugSelectValue,
        name: this.state.nameSelectValue,
        view: this.state.viewSelectValue === null ? false : this.state.viewSelectValue,
        resgister: this.state.resgisterSelectValue === null ? false : this.state.resgisterSelectValue,
        edit: this.state.editSelectValue === null ? false : this.state.editSelectValue,
        canDelete: this.state.canDeleteSelectValue === null ? false : this.state.canDeleteSelectValue,
        manualRemoval: this.state.manualRemovalSelectValue === null ? false : this.state.manualRemovalSelectValue,
        editLocation: this.state.editLocationSelectValue === null ? false : this.state.editLocationSelectValue,
        report: this.state.reportSelectValue === null ? false : this.state.reportSelectValue,
        activateProfessional: this.state.activateProfessionalSelectValue === null ? false : this.state.activateProfessionalSelectValue,
        deletePhoto: this.state.deletePhotoSelectValue === null ? false : this.state.deletePhotoSelectValue,
        financialValue: this.state.financialValueSelectValue === null ? false : this.state.financialValueSelectValue,
        valueAuthorization: this.state.valueAuthorizationSelectValue === null ? false : this.state.valueAuthorizationSelectValue,
        confirmPayment: this.state.confirmPaymentSelectValue === null ? false : this.state.confirmPaymentSelectValue,
        evolution: this.state.evolutionSelectValue === null ? false : this.state.evolutionSelectValue,
        professionalName: this.state.professionalNameSelectValue === null ? false : this.state.professionalNameSelectValue,
        professionalRegister: this.state.professionalRegisterSelectValue === null ? false : this.state.professionalRegisterSelectValue,
        contact: this.state.contactSelectValue === null ? false : this.state.contactSelectValue,
        status: this.state.statusSelectValue === null ? false : this.state.statusSelectValue,
        file: this.state.fileSelectValue === null ? false : this.state.fileSelectValue,
        comercialTable: this.state.comercialTableSelectValue === null ? false : this.state.comercialTableSelectValue,
        drPrescription: this.state.drPrescriptionSelectValue === null ? false : this.state.drPrescriptionSelectValue,
        nrPrescription: this.state.nrPrescriptionSelectValue === null ? false : this.state.nrPrescriptionSelectValue,
        clinicalIndicators: this.state.clinicalIndicatorsSelectValue === null ? false : this.state.clinicalIndicatorsSelectValue,
        importTable: this.state.importTableSelectValue === null ? false : this.state.importTableSelectValue,
      };

      const entity = _entity;

      const { slug, name, extraFilters, activePage, itemsPerPage, sort, order } = this.state.fieldsBase;

      if (this.state.isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              slug,
              name,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              slug,
              name,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/permission?' + getEntityFiltersURL(this.state.fieldsBase));
  };

  getFullEntityFromState(v) {
    return {
      id: v.id,
      slug: this.state.slugSelectValue,
      name: this.state.nameSelectValue,
      view: this.state.viewSelectValue,
      resgister: this.state.resgisterSelectValue,
      edit: this.state.editSelectValue,
      canDelete: this.state.canDeleteSelectValue,
      manualRemoval: this.state.manualRemovalSelectValue,
      editLocation: this.state.editLocationSelectValue,
      report: this.state.reportSelectValue,
      activateProfessional: this.state.activateProfessionalSelectValue,
      deletePhoto: this.state.deletePhotoSelectValue,
      financialValue: this.state.financialValueSelectValue,
      valueAuthorization: this.state.valueAuthorizationSelectValue,
      confirmPayment: this.state.confirmPaymentSelectValue,
      evolution: this.state.evolutionSelectValue,
      professionalName: this.state.professionalNameSelectValue,
      professionalRegister: this.state.professionalRegisterSelectValue,
      contact: this.state.contactSelectValue,
      status: this.state.statusSelectValue,
      file: this.state.fileSelectValue,
      comercialTable: this.state.comercialTableSelectValue,
      drPrescription: this.state.drPrescriptionSelectValue,
      nrPrescription: this.state.nrPrescriptionSelectValue,
      clinicalIndicators: this.state.clinicalIndicatorsSelectValue,
      importTable: this.state.importTableSelectValue,
      permissionProfile: this.state.permissionProfileSelectValue,
      permissionUser: this.state.permissionUserSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    const permissionProfileEntity = v.permissionProfile;

    const permissionUserEntity = v.permissionUser;

    return {
      slugSelectValue: v.slug,
      nameSelectValue: v.name,
      viewSelectValue: v.view,
      resgisterSelectValue: v.resgister,
      editSelectValue: v.edit,
      canDeleteSelectValue: v.canDelete,
      manualRemovalSelectValue: v.manualRemoval,
      editLocationSelectValue: v.editLocation,
      reportSelectValue: v.report,
      activateProfessionalSelectValue: v.activateProfessional,
      deletePhotoSelectValue: v.deletePhoto,
      financialValueSelectValue: v.financialValue,
      valueAuthorizationSelectValue: v.valueAuthorization,
      confirmPaymentSelectValue: v.confirmPayment,
      evolutionSelectValue: v.evolution,
      professionalNameSelectValue: v.professionalName,
      professionalRegisterSelectValue: v.professionalRegister,
      contactSelectValue: v.contact,
      statusSelectValue: v.status,
      fileSelectValue: v.file,
      comercialTableSelectValue: v.comercialTable,
      drPrescriptionSelectValue: v.drPrescription,
      nrPrescriptionSelectValue: v.nrPrescription,
      clinicalIndicatorsSelectValue: v.clinicalIndicators,
      importTableSelectValue: v.importTable,
      permissionProfileSelectValue: permissionProfileEntity ? permissionProfileEntity['id'] : null,
      permissionUserSelectValue: permissionUserEntity ? permissionUserEntity['id'] : null,
    };
  }

  renderHeader() {
    const { permissionEntity, permissionProfiles, permissionUsers, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.permission.home.createLabel">Create a Permission</Translate>
            ) : (
              <Translate contentKey="generadorApp.permission.home.editLabel">Edit a Permission</Translate>
            )}
          </span>

          <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.permission.btnSave">Save</Translate>
          </Button>

          {!this.state.isNew ? (
            <>
              <Button
                color="primary"
                id="next-entity"
                disabled={updating}
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.slug,
                    this.state.fieldsBase.name,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 + 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 + 1 },
                    });
                    this.props.history.push(
                      '/permission/' + myresult.value.data[0]['id'] + '/edit?' + this.getFiltersURL(this.state.fieldsBase.offset * 1 + 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.last.item', { fieldName: translate('generadorApp.permission.detail.title') }));
                  }
                }}
                className="float-right jh-create-entity"
              >
                {'>>'}
              </Button>

              <Button
                color="primary"
                id="prev-entity"
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.slug,
                    this.state.fieldsBase.name,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 - 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 - 1 },
                    });
                    this.props.history.push(
                      '/permission/' + myresult.value.data[0]['id'] + '/edit?' + this.getFiltersURL(this.state.fieldsBase.offset * 1 - 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.first.item', { fieldName: translate('generadorApp.permission.detail.title') }));
                  }
                }}
                disabled={updating}
                className="float-right jh-create-entity"
              >
                {'<<'}
              </Button>
            </>
          ) : (
            <> </>
          )}

          <Button
            tag={Link}
            id="cancel-save"
            to={'/permission?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.permission.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={'/permission'}>
              <Translate contentKey="generadorApp.permission.home.title">Permissions</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.permission.detail.title">Permission edit</Translate>
          </li>
        </ol>
      </>
    );
  }
  renderBody() {
    const { permissionEntity, permissionProfiles, permissionUsers, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const fullEntityEmbebed = this.getFullEntityFromState(permissionEntity);

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="permission-id">
                  <Translate contentKey="generadorApp.permission.field_id">ID</Translate>
                </Label>
                </Col> */}
                      <Col md="12">
                        <AvInput id="permission-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'slug' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="slugLabel" for="permission-slug">
                              <Translate contentKey="generadorApp.permission.slug">Slug</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ slugSelectValue: evt.target.value })}
                              value={this.state.slugSelectValue ? this.state.slugSelectValue : ''}
                              id="permission-slug"
                              type="text"
                              name="slug"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ slugSelectValue: evt.target.value })}
                      type="hidden"
                      name="slug"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'name' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="nameLabel" for="permission-name">
                              <Translate contentKey="generadorApp.permission.name">Name</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                              value={this.state.nameSelectValue ? this.state.nameSelectValue : ''}
                              id="permission-name"
                              type="text"
                              name="name"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                      type="hidden"
                      name="name"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'view' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="viewLabel" for="permission-view">
                              <Translate contentKey="generadorApp.permission.view">View</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'view_checkbox'}
                                id={'view_checkbox'}
                                checked={this.state.viewSelectValue}
                                onChange={evt => this.setState({ viewSelectValue: !this.state.viewSelectValue })}
                              />
                              <label htmlFor={'view_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ viewSelectValue: evt.target.value })}
                      type="hidden"
                      name="view"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'resgister' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="resgisterLabel" for="permission-resgister">
                              <Translate contentKey="generadorApp.permission.resgister">Register</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'resgister_checkbox'}
                                id={'resgister_checkbox'}
                                checked={this.state.resgisterSelectValue}
                                onChange={evt => this.setState({ resgisterSelectValue: !this.state.resgisterSelectValue })}
                              />
                              <label htmlFor={'resgister_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ resgisterSelectValue: evt.target.value })}
                      type="hidden"
                      name="resgister"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'edit' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="editLabel" for="permission-edit">
                              <Translate contentKey="generadorApp.permission.edit">Edit</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'edit_checkbox'}
                                id={'edit_checkbox'}
                                checked={this.state.editSelectValue}
                                onChange={evt => this.setState({ editSelectValue: !this.state.editSelectValue })}
                              />
                              <label htmlFor={'edit_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ editSelectValue: evt.target.value })}
                      type="hidden"
                      name="edit"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'canDelete' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="canDeleteLabel" for="permission-canDelete">
                              <Translate contentKey="generadorApp.permission.canDelete">Delete</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'canDelete_checkbox'}
                                id={'canDelete_checkbox'}
                                checked={this.state.canDeleteSelectValue}
                                onChange={evt => this.setState({ canDeleteSelectValue: !this.state.canDeleteSelectValue })}
                              />
                              <label htmlFor={'canDelete_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ canDeleteSelectValue: evt.target.value })}
                      type="hidden"
                      name="canDelete"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'manualRemoval' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="manualRemovalLabel" for="permission-manualRemoval">
                              <Translate contentKey="generadorApp.permission.manualRemoval">Manual removal</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'manualRemoval_checkbox'}
                                id={'manualRemoval_checkbox'}
                                checked={this.state.manualRemovalSelectValue}
                                onChange={evt => this.setState({ manualRemovalSelectValue: !this.state.manualRemovalSelectValue })}
                              />
                              <label htmlFor={'manualRemoval_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ manualRemovalSelectValue: evt.target.value })}
                      type="hidden"
                      name="manualRemoval"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'editLocation' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="editLocationLabel" for="permission-editLocation">
                              <Translate contentKey="generadorApp.permission.editLocation">editLocation</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'editLocation_checkbox'}
                                id={'editLocation_checkbox'}
                                checked={this.state.editLocationSelectValue}
                                onChange={evt => this.setState({ editLocationSelectValue: !this.state.editLocationSelectValue })}
                              />
                              <label htmlFor={'editLocation_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ editLocationSelectValue: evt.target.value })}
                      type="hidden"
                      name="editLocation"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'report' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="reportLabel" for="permission-report">
                              <Translate contentKey="generadorApp.permission.report">Report</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'report_checkbox'}
                                id={'report_checkbox'}
                                checked={this.state.reportSelectValue}
                                onChange={evt => this.setState({ reportSelectValue: !this.state.reportSelectValue })}
                              />
                              <label htmlFor={'report_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ reportSelectValue: evt.target.value })}
                      type="hidden"
                      name="report"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'activateProfessional' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="activateProfessionalLabel" for="permission-activateProfessional">
                              <Translate contentKey="generadorApp.permission.activateProfessional">Activate professional</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'activateProfessional_checkbox'}
                                id={'activateProfessional_checkbox'}
                                checked={this.state.activateProfessionalSelectValue}
                                onChange={evt =>
                                  this.setState({ activateProfessionalSelectValue: !this.state.activateProfessionalSelectValue })
                                }
                              />
                              <label htmlFor={'activateProfessional_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ activateProfessionalSelectValue: evt.target.value })}
                      type="hidden"
                      name="activateProfessional"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'deletePhoto' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="deletePhotoLabel" for="permission-deletePhoto">
                              <Translate contentKey="generadorApp.permission.deletePhoto">Delete photo</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'deletePhoto_checkbox'}
                                id={'deletePhoto_checkbox'}
                                checked={this.state.deletePhotoSelectValue}
                                onChange={evt => this.setState({ deletePhotoSelectValue: !this.state.deletePhotoSelectValue })}
                              />
                              <label htmlFor={'deletePhoto_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ deletePhotoSelectValue: evt.target.value })}
                      type="hidden"
                      name="deletePhoto"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'financialValue' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="financialValueLabel" for="permission-financialValue">
                              <Translate contentKey="generadorApp.permission.financialValue">Values</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'financialValue_checkbox'}
                                id={'financialValue_checkbox'}
                                checked={this.state.financialValueSelectValue}
                                onChange={evt => this.setState({ financialValueSelectValue: !this.state.financialValueSelectValue })}
                              />
                              <label htmlFor={'financialValue_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ financialValueSelectValue: evt.target.value })}
                      type="hidden"
                      name="financialValue"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'valueAuthorization' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="valueAuthorizationLabel" for="permission-valueAuthorization">
                              <Translate contentKey="generadorApp.permission.valueAuthorization">Value authorization</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'valueAuthorization_checkbox'}
                                id={'valueAuthorization_checkbox'}
                                checked={this.state.valueAuthorizationSelectValue}
                                onChange={evt =>
                                  this.setState({ valueAuthorizationSelectValue: !this.state.valueAuthorizationSelectValue })
                                }
                              />
                              <label htmlFor={'valueAuthorization_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ valueAuthorizationSelectValue: evt.target.value })}
                      type="hidden"
                      name="valueAuthorization"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'confirmPayment' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="confirmPaymentLabel" for="permission-confirmPayment">
                              <Translate contentKey="generadorApp.permission.confirmPayment">confirmPayment</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'confirmPayment_checkbox'}
                                id={'confirmPayment_checkbox'}
                                checked={this.state.confirmPaymentSelectValue}
                                onChange={evt => this.setState({ confirmPaymentSelectValue: !this.state.confirmPaymentSelectValue })}
                              />
                              <label htmlFor={'confirmPayment_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ confirmPaymentSelectValue: evt.target.value })}
                      type="hidden"
                      name="confirmPayment"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'evolution' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="evolutionLabel" for="permission-evolution">
                              <Translate contentKey="generadorApp.permission.evolution">Evolution</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'evolution_checkbox'}
                                id={'evolution_checkbox'}
                                checked={this.state.evolutionSelectValue}
                                onChange={evt => this.setState({ evolutionSelectValue: !this.state.evolutionSelectValue })}
                              />
                              <label htmlFor={'evolution_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ evolutionSelectValue: evt.target.value })}
                      type="hidden"
                      name="evolution"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'professionalName' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="professionalNameLabel" for="permission-professionalName">
                              <Translate contentKey="generadorApp.permission.professionalName">Professional name</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'professionalName_checkbox'}
                                id={'professionalName_checkbox'}
                                checked={this.state.professionalNameSelectValue}
                                onChange={evt => this.setState({ professionalNameSelectValue: !this.state.professionalNameSelectValue })}
                              />
                              <label htmlFor={'professionalName_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ professionalNameSelectValue: evt.target.value })}
                      type="hidden"
                      name="professionalName"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'professionalRegister' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="professionalRegisterLabel" for="permission-professionalRegister">
                              <Translate contentKey="generadorApp.permission.professionalRegister">Professional register</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <div className="switcher switcher-success">
                              <input
                                type="checkbox"
                                name={'professionalRegister_checkbox'}
                                id={'professionalRegister_checkbox'}
                                checked={this.state.professionalRegisterSelectValue}
                                onChange={evt =>
                                  this.setState({ professionalRegisterSelectValue: !this.state.professionalRegisterSelectValue })
                                }
                              />
                              <label htmlFor={'professionalRegister_checkbox'} />
                            </div>
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ professionalRegisterSelectValue: evt.target.value })}
                      type="hidden"
                      name="professionalRegister"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { permissionEntity, permissionProfiles, permissionUsers, loading, updating } = this.props;
    const { isNew } = this.state;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...permissionEntity,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel className="permission permission-update">
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToProps = (storeState: IRootState) => {
  return {
    permissionProfiles: storeState.permissionProfile.entities,
    permissionUsers: storeState.permissionUser.entities,
    permissionEntity: storeState.permission.entity,
    loading: storeState.permission.loading,
    updating: storeState.permission.updating,
    updateSuccess: storeState.permission.updateSuccess,
  };
};

export const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  getEntityOffset,
  reset,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PermissionUpdate);
