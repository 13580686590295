/* eslint-disable no-console */
import React from 'react';
import { useDrag, DragSourceMonitor } from 'react-dnd';

const style: React.CSSProperties = {
  border: '1px dashed gray',
  fontSize: '13px',
  padding: '0.5rem 1rem',
  marginRight: '0',
  marginBottom: '0',
  cursor: 'move',
  float: 'left',
  width: '100%',
};
const styleInCalendar: React.CSSProperties = {
  border: '1px dashed gray',
  marginRight: '0',
  marginBottom: '0',
  cursor: 'move',
  float: 'left',
  width: '100%',
  fontSize: '10px',
  overflow: 'hidden',
  padding: '2px',
};

export const Box: any = ({ name, ...propsN }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { type: 'box' },

    end: (item: any, monitor: DragSourceMonitor) => (monitor.getDropResult() ? propsN.onDrag(monitor.getDropResult().dia) : ''),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  return !propsN.inCalendar ? (
    <div ref={drag} style={style} className={'back-' + propsN.cor}>
      {propsN.period} -{' '}
      <b>
        {propsN.specialtyCategory} {propsN.specialty}
      </b>{' '}
      <br /> Pat: <b>{propsN.patient}</b> <br /> Prof: <b>{propsN.professional}</b>
    </div>
  ) : (
    <div ref={drag} style={styleInCalendar} className={'back-' + propsN.cor}>
      {propsN.professional}
    </div>
  );
};
