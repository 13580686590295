/* eslint complexity: ["error", 500] */
import axios from 'axios';

import { IPaginationBaseState, IPayloadResult } from 'react-jhipster';

import { IPayload } from 'react-jhipster/src/type/redux-action.type';

import { BASE_API_VERSION_PATH } from 'app/config/constants';

import { cleanEntity, jsonParse } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IClientGlosaItem, defaultValue } from 'app/shared/model/client-glosa-item.model';

export declare type ICrudPutAction<T> = (data?: T, listFiltersPage?) => IPayload<T> | IPayloadResult<T>;
export declare type ICrudGetAction<T> = (id: string | number, selectFields?: string) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const ACTION_TYPES = {
  FETCH_CLIENTGLOSAITEM_LIST_EXPORT: 'clientGlosaItem/FETCH_CLIENTGLOSAITEM_LIST_EXPORT',
  FETCH_CLIENTGLOSAITEM_LIST: 'clientGlosaItem/FETCH_CLIENTGLOSAITEM_LIST',
  FETCH_CLIENTGLOSAITEM: 'clientGlosaItem/FETCH_CLIENTGLOSAITEM',
  FETCH_CLIENTGLOSAITEM_OFFSET: 'clientGlosaItem/FETCH_CLIENTGLOSAITEM_OFFSET',
  CREATE_CLIENTGLOSAITEM: 'clientGlosaItem/CREATE_CLIENTGLOSAITEM',
  UPDATE_CLIENTGLOSAITEM: 'clientGlosaItem/UPDATE_CLIENTGLOSAITEM',
  CREATE_CLIENTGLOSAITEM_AND_GO_LIST: 'clientGlosaItem/CREATE_CLIENTGLOSAITEM_AND_GO_LIST',
  UPDATE_CLIENTGLOSAITEM_AND_GO_LIST: 'clientGlosaItem/UPDATE_CLIENTGLOSAITEM_AND_GO_LIST',
  DELETE_CLIENTGLOSAITEM: 'clientGlosaItem/DELETE_CLIENTGLOSAITEM',
  RESET: 'clientGlosaItem/RESET',
  SHOW_MODAL: 'clientGlosaItem/SHOW_MODAL',
};

const initialState = {
  loading: false,
  openModal: {
    list: false,
    view: false,
    detail: false,
  },
  errorMessage: null,
  entities: [] as ReadonlyArray<IClientGlosaItem>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const actionPendind = [];
let actualState = initialState;

export const convertEntityBuffers = entity => ({
  ...entity,
});

export type ClientGlosaItemState = Readonly<typeof initialState>;

export interface IClientGlosaItemBaseState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  listCheckedID?: Array<number>;
  isCheckedAll?: boolean;
  urlBack?: string;
  extraFilters?: any;
  baseFilters: any;
  offset: number;
  secOrder?: any;
  typeItemGlosa?: any;
  quantityGlossed?: any;
  quantityRefused?: any;
  justification?: any;
  glosaItemAceita?: any;
  clientGlosaId?: any;
  clientGlosaStartFilter?: any;
  quoteMedicineId?: any;
  quoteMedicineStartFilter?: any;
  quoteDietId?: any;
  quoteDietStartFilter?: any;
  quoteMaterialId?: any;
  quoteMaterialStartFilter?: any;
  quoteProductServiceId?: any;
  quoteProductServiceStartFilter?: any;
  quoteRhId?: any;
  quoteRhStartFilter?: any;
  quotePackageId?: any;
  quotePackageStartFilter?: any;
}

export interface IFieldsBase extends IClientGlosaItemBaseState, IPaginationBaseState {}
export interface IClientGlosaItemUpdateState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  urlBack?: string;
  functionEmbebed?: any[];
  fieldsBase: IFieldsBase;
  secOrderSelectValue?: any;
  secOrderStartSelectOptions?: any;
  typeItemGlosaSelectValue?: any;
  typeItemGlosaStartSelectOptions?: any;
  quantityGlossedSelectValue?: any;
  quantityGlossedStartSelectOptions?: any;
  quantityRefusedSelectValue?: any;
  quantityRefusedStartSelectOptions?: any;
  justificationSelectValue?: any;
  justificationStartSelectOptions?: any;
  glosaItemAceitaSelectValue?: any;
  glosaItemAceitaStartSelectOptions?: any;

  clientGlosaSelectValue?: any;
  clientGlosaStartSelectOptions?: any;

  quoteMedicineSelectValue?: any;
  quoteMedicineStartSelectOptions?: any;

  quoteDietSelectValue?: any;
  quoteDietStartSelectOptions?: any;

  quoteMaterialSelectValue?: any;
  quoteMaterialStartSelectOptions?: any;

  quoteProductServiceSelectValue?: any;
  quoteProductServiceStartSelectOptions?: any;

  quoteRhSelectValue?: any;
  quoteRhStartSelectOptions?: any;

  quotePackageSelectValue?: any;
  quotePackageStartSelectOptions?: any;

  isNew: boolean;
  clientGlosaId?: any;
  quoteMedicineId?: any;
  quoteDietId?: any;
  quoteMaterialId?: any;
  quoteProductServiceId?: any;
  quoteRhId?: any;
  quotePackageId?: any;
}

// Reducer

export default (state: ClientGlosaItemState = initialState, action): ClientGlosaItemState => {
  actualState = state;
  if (
    action.type.includes('_PENDING') &&
    !actionPendind.includes(action.type.replace('_PENDING', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_PENDING', ''))
  ) {
    actionPendind.push(action.type.replace('_PENDING', ''));
  }

  if (
    action.type.includes('_FULFILLED') &&
    actionPendind.includes(action.type.replace('_FULFILLED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_FULFILLED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_FULFILLED', '')), 1);
  }

  if (
    action.type.includes('_REJECTED') &&
    actionPendind.includes(action.type.replace('_REJECTED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_REJECTED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_REJECTED', '')), 1);
  }

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_CLIENTGLOSAITEM_LIST_EXPORT):
    case REQUEST(ACTION_TYPES.FETCH_CLIENTGLOSAITEM_LIST):
    case REQUEST(ACTION_TYPES.FETCH_CLIENTGLOSAITEM):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_CLIENTGLOSAITEM):
    case REQUEST(ACTION_TYPES.UPDATE_CLIENTGLOSAITEM):
    case REQUEST(ACTION_TYPES.CREATE_CLIENTGLOSAITEM_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.UPDATE_CLIENTGLOSAITEM_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.DELETE_CLIENTGLOSAITEM):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_CLIENTGLOSAITEM_LIST_EXPORT):
    case FAILURE(ACTION_TYPES.FETCH_CLIENTGLOSAITEM_LIST):
    case FAILURE(ACTION_TYPES.FETCH_CLIENTGLOSAITEM):
    case FAILURE(ACTION_TYPES.CREATE_CLIENTGLOSAITEM):
    case FAILURE(ACTION_TYPES.UPDATE_CLIENTGLOSAITEM):
    case FAILURE(ACTION_TYPES.CREATE_CLIENTGLOSAITEM_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.UPDATE_CLIENTGLOSAITEM_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.DELETE_CLIENTGLOSAITEM):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_CLIENTGLOSAITEM_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_CLIENTGLOSAITEM_OFFSET):
      if (action.payload.data && action.payload.data.length > 0) {
        return {
          ...state,
          loading: false,
          entity: convertEntityBuffers(action.payload.data[0]),
        };
      }
      return {
        ...state,
        loading: false,
      };

    case SUCCESS(ACTION_TYPES.FETCH_CLIENTGLOSAITEM):
      return {
        ...state,
        loading: false,
        entity: convertEntityBuffers(action.payload.data),
      };
    case SUCCESS(ACTION_TYPES.CREATE_CLIENTGLOSAITEM):
    case SUCCESS(ACTION_TYPES.UPDATE_CLIENTGLOSAITEM):
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_CLIENTGLOSAITEM_AND_GO_LIST):
    case SUCCESS(ACTION_TYPES.UPDATE_CLIENTGLOSAITEM_AND_GO_LIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_CLIENTGLOSAITEM):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };

    case ACTION_TYPES.SHOW_MODAL: {
      const openModal = state.openModal;
      openModal[action.payload.type] = action.payload.show;
      return {
        ...state,
        openModal,
      };
    }
    default:
      return state;
  }
};

const apiUrl = BASE_API_VERSION_PATH + 'client-glosa-items';

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

// Actions

// Actions
export type ICrudGetAllActionClientGlosaItem<T> = (
  secOrder?: any,
  typeItemGlosa?: any,
  quantityGlossed?: any,
  quantityRefused?: any,
  justification?: any,
  glosaItemAceita?: any,
  clientGlosaId?: any,
  quoteMedicineId?: any,
  quoteDietId?: any,
  quoteMaterialId?: any,
  quoteProductServiceId?: any,
  quoteRhId?: any,
  quotePackageId?: any,
  extraFilters?: {},
  page?: number,
  size?: number,
  sort?: string,
  selectFields?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getEntitiesFilter = (filters?, page?, size?, sort?, selectFields = '') => {
  const requestUrl = `${apiUrl}?page=${page >= 0 ? page : 0}&size=${size >= 0 ? size : 20}&sort=${sort ? sort : 'id,asc'}&`;
  const requestFilters = filters.map(v => v.column + '.' + v.operation + '=' + v.value).join('&');
  return {
    type: ACTION_TYPES.FETCH_CLIENTGLOSAITEM_LIST,
    payload: axios.get<IClientGlosaItem>(`${requestUrl}${requestFilters}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getAllEntities = (filters?, sort?, selectFields = '') => {
  if (
    (actualState.totalItems > 0 && actualState.entities.length === actualState.totalItems) ||
    actionPendind.includes(ACTION_TYPES.FETCH_CLIENTGLOSAITEM_LIST)
  ) {
    return {
      type: ACTION_TYPES.FETCH_CLIENTGLOSAITEM_LIST + '_FROM_CACHE',
      payload: new Promise(function (resolve, reject) {
        resolve('done');
      }),
    };
  }

  const idsRequest = filters && typeof filters['ids'] !== 'undefined' && filters['ids'] ? `id.in=${filters['ids'].join(',')}&` : '';
  const secOrderRequest =
    filters && typeof filters['secOrder'] !== 'undefined' && filters['secOrder'] ? `secOrder.equals=${filters['secOrder']}&` : '';
  const typeItemGlosaRequest =
    filters && typeof filters['typeItemGlosa'] !== 'undefined' && filters['typeItemGlosa']
      ? `typeItemGlosa.contains=${filters['typeItemGlosa']}&`
      : '';
  const quantityGlossedRequest =
    filters && typeof filters['quantityGlossed'] !== 'undefined' && filters['quantityGlossed']
      ? `quantityGlossed.equals=${filters['quantityGlossed']}&`
      : '';
  const quantityRefusedRequest =
    filters && typeof filters['quantityRefused'] !== 'undefined' && filters['quantityRefused']
      ? `quantityRefused.equals=${filters['quantityRefused']}&`
      : '';
  const justificationRequest =
    filters && typeof filters['justification'] !== 'undefined' && filters['justification']
      ? `justification.contains=${filters['justification']}&`
      : '';
  const glosaItemAceitaRequest =
    filters && typeof filters['glosaItemAceita'] !== 'undefined' && filters['glosaItemAceita']
      ? `glosaItemAceita.contains=${filters['glosaItemAceita']}&`
      : '';
  const clientGlosaRequest =
    filters && typeof filters['clientGlosaId'] !== 'undefined' && filters['clientGlosaId']
      ? `clientGlosa.id.in=${filters['clientGlosaId']}&&`
      : '';
  const quoteMedicineRequest =
    filters && typeof filters['quoteMedicineId'] !== 'undefined' && filters['quoteMedicineId']
      ? `quoteMedicine.id.in=${filters['quoteMedicineId']}&&`
      : '';
  const quoteDietRequest =
    filters && typeof filters['quoteDietId'] !== 'undefined' && filters['quoteDietId'] ? `quoteDiet.id.in=${filters['quoteDietId']}&&` : '';
  const quoteMaterialRequest =
    filters && typeof filters['quoteMaterialId'] !== 'undefined' && filters['quoteMaterialId']
      ? `quoteMaterial.id.in=${filters['quoteMaterialId']}&&`
      : '';
  const quoteProductServiceRequest =
    filters && typeof filters['quoteProductServiceId'] !== 'undefined' && filters['quoteProductServiceId']
      ? `quoteProductService.id.in=${filters['quoteProductServiceId']}&&`
      : '';
  const quoteRhRequest =
    filters && typeof filters['quoteRhId'] !== 'undefined' && filters['quoteRhId'] ? `quoteRh.id.in=${filters['quoteRhId']}&&` : '';
  const quotePackageRequest =
    filters && typeof filters['quotePackageId'] !== 'undefined' && filters['quotePackageId']
      ? `quotePackage.id.in=${filters['quotePackageId']}&&`
      : '';

  const requestUrl = `${apiUrl}${`?page=${0}&size=${1000000}&sort=${sort ? sort : 'id,asc'}&`}`;
  return {
    type: ACTION_TYPES.FETCH_CLIENTGLOSAITEM_LIST,
    payload: axios.get<IClientGlosaItem>(
      `${requestUrl}${idsRequest}${secOrderRequest}${typeItemGlosaRequest}${quantityGlossedRequest}${quantityRefusedRequest}${justificationRequest}${glosaItemAceitaRequest}${clientGlosaRequest}${quoteMedicineRequest}${quoteDietRequest}${quoteMaterialRequest}${quoteProductServiceRequest}${quoteRhRequest}${quotePackageRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntities: ICrudGetAllActionClientGlosaItem<IClientGlosaItem> = (
  secOrder,
  typeItemGlosa,
  quantityGlossed,
  quantityRefused,
  justification,
  glosaItemAceita,
  clientGlosaId,
  quoteMedicineId,
  quoteDietId,
  quoteMaterialId,
  quoteProductServiceId,
  quoteRhId,
  quotePackageId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const secOrderRequest = secOrder ? (Array.isArray(status) ? `secOrder.in=${secOrder.join(',')}&` : `secOrder.equals=${secOrder}&`) : '';
  const typeItemGlosaRequest = typeItemGlosa
    ? Array.isArray(status)
      ? `typeItemGlosa.in=${typeItemGlosa.join(',')}&`
      : `typeItemGlosa.equals=${typeItemGlosa}&`
    : '';
  const quantityGlossedRequest = quantityGlossed
    ? Array.isArray(status)
      ? `quantityGlossed.in=${quantityGlossed.join(',')}&`
      : `quantityGlossed.equals=${quantityGlossed}&`
    : '';
  const quantityRefusedRequest = quantityRefused
    ? Array.isArray(status)
      ? `quantityRefused.in=${quantityRefused.join(',')}&`
      : `quantityRefused.equals=${quantityRefused}&`
    : '';
  const justificationRequest = justification
    ? Array.isArray(status)
      ? `justification.in=${justification.join(',')}&`
      : `justification.contains=${justification}&`
    : '';
  const glosaItemAceitaRequest = glosaItemAceita
    ? Array.isArray(status)
      ? `glosaItemAceita.in=${glosaItemAceita.join(',')}&`
      : `glosaItemAceita.equals=${glosaItemAceita}&`
    : '';
  const clientGlosaRequest = clientGlosaId ? `clientGlosa.id.in=${clientGlosaId}&` : '';
  const quoteMedicineRequest = quoteMedicineId ? `quoteMedicine.id.in=${quoteMedicineId}&` : '';
  const quoteDietRequest = quoteDietId ? `quoteDiet.id.in=${quoteDietId}&` : '';
  const quoteMaterialRequest = quoteMaterialId ? `quoteMaterial.id.in=${quoteMaterialId}&` : '';
  const quoteProductServiceRequest = quoteProductServiceId ? `quoteProductService.id.in=${quoteProductServiceId}&` : '';
  const quoteRhRequest = quoteRhId ? `quoteRh.id.in=${quoteRhId}&` : '';
  const quotePackageRequest = quotePackageId ? `quotePackage.id.in=${quotePackageId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_CLIENTGLOSAITEM_LIST,
    payload: axios.get<IClientGlosaItem>(
      `${requestUrl}${extraFiltersRequest}${secOrderRequest}${typeItemGlosaRequest}${quantityGlossedRequest}${quantityRefusedRequest}${justificationRequest}${glosaItemAceitaRequest}${clientGlosaRequest}${quoteMedicineRequest}${quoteDietRequest}${quoteMaterialRequest}${quoteProductServiceRequest}${quoteRhRequest}${quotePackageRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntityOffset: any = (
  secOrder,
  typeItemGlosa,
  quantityGlossed,
  quantityRefused,
  justification,
  glosaItemAceita,
  clientGlosaId,
  quoteMedicineId,
  quoteDietId,
  quoteMaterialId,
  quoteProductServiceId,
  quoteRhId,
  quotePackageId,
  extraFilters,
  offset,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const secOrderRequest = secOrder ? (Array.isArray(status) ? `secOrder.in=${secOrder.join(',')}&` : `secOrder.equals=${secOrder}&`) : '';
  const typeItemGlosaRequest = typeItemGlosa
    ? Array.isArray(status)
      ? `typeItemGlosa.in=${typeItemGlosa.join(',')}&`
      : `typeItemGlosa.equals=${typeItemGlosa}&`
    : '';
  const quantityGlossedRequest = quantityGlossed
    ? Array.isArray(status)
      ? `quantityGlossed.in=${quantityGlossed.join(',')}&`
      : `quantityGlossed.equals=${quantityGlossed}&`
    : '';
  const quantityRefusedRequest = quantityRefused
    ? Array.isArray(status)
      ? `quantityRefused.in=${quantityRefused.join(',')}&`
      : `quantityRefused.equals=${quantityRefused}&`
    : '';
  const justificationRequest = justification
    ? Array.isArray(status)
      ? `justification.in=${justification.join(',')}&`
      : `justification.contains=${justification}&`
    : '';
  const glosaItemAceitaRequest = glosaItemAceita
    ? Array.isArray(status)
      ? `glosaItemAceita.in=${glosaItemAceita.join(',')}&`
      : `glosaItemAceita.equals=${glosaItemAceita}&`
    : '';
  const clientGlosaRequest = clientGlosaId ? `clientGlosa.id.in=${clientGlosaId}&` : '';
  const quoteMedicineRequest = quoteMedicineId ? `quoteMedicine.id.in=${quoteMedicineId}&` : '';
  const quoteDietRequest = quoteDietId ? `quoteDiet.id.in=${quoteDietId}&` : '';
  const quoteMaterialRequest = quoteMaterialId ? `quoteMaterial.id.in=${quoteMaterialId}&` : '';
  const quoteProductServiceRequest = quoteProductServiceId ? `quoteProductService.id.in=${quoteProductServiceId}&` : '';
  const quoteRhRequest = quoteRhId ? `quoteRh.id.in=${quoteRhId}&` : '';
  const quotePackageRequest = quotePackageId ? `quotePackage.id.in=${quotePackageId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${offset}&size=${1}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_CLIENTGLOSAITEM_OFFSET,
    payload: axios.get<IClientGlosaItem>(
      `${requestUrl}${extraFiltersRequest}${secOrderRequest}${typeItemGlosaRequest}${quantityGlossedRequest}${quantityRefusedRequest}${justificationRequest}${glosaItemAceitaRequest}${clientGlosaRequest}${quoteMedicineRequest}${quoteDietRequest}${quoteMaterialRequest}${quoteProductServiceRequest}${quoteRhRequest}${quotePackageRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntity: ICrudGetAction<IClientGlosaItem> = (id, selectFields = '') => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_CLIENTGLOSAITEM,
    payload: axios.get<IClientGlosaItem>(requestUrl, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntitiesExport: ICrudGetAllActionClientGlosaItem<IClientGlosaItem> = (
  secOrder,
  typeItemGlosa,
  quantityGlossed,
  quantityRefused,
  justification,
  glosaItemAceita,
  clientGlosaId,
  quoteMedicineId,
  quoteDietId,
  quoteMaterialId,
  quoteProductServiceId,
  quoteRhId,
  quotePackageId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const secOrderRequest = secOrder ? (Array.isArray(status) ? `secOrder.in=${secOrder.join(',')}&` : `secOrder.equals=${secOrder}&`) : '';
  const typeItemGlosaRequest = typeItemGlosa
    ? Array.isArray(status)
      ? `typeItemGlosa.in=${typeItemGlosa.join(',')}&`
      : `typeItemGlosa.equals=${typeItemGlosa}&`
    : '';
  const quantityGlossedRequest = quantityGlossed
    ? Array.isArray(status)
      ? `quantityGlossed.in=${quantityGlossed.join(',')}&`
      : `quantityGlossed.equals=${quantityGlossed}&`
    : '';
  const quantityRefusedRequest = quantityRefused
    ? Array.isArray(status)
      ? `quantityRefused.in=${quantityRefused.join(',')}&`
      : `quantityRefused.equals=${quantityRefused}&`
    : '';
  const justificationRequest = justification
    ? Array.isArray(status)
      ? `justification.in=${justification.join(',')}&`
      : `justification.contains=${justification}&`
    : '';
  const glosaItemAceitaRequest = glosaItemAceita
    ? Array.isArray(status)
      ? `glosaItemAceita.in=${glosaItemAceita.join(',')}&`
      : `glosaItemAceita.equals=${glosaItemAceita}&`
    : '';
  const clientGlosaRequest = clientGlosaId ? `clientGlosa.id.in=${clientGlosaId}&` : '';
  const quoteMedicineRequest = quoteMedicineId ? `quoteMedicine.id.in=${quoteMedicineId}&` : '';
  const quoteDietRequest = quoteDietId ? `quoteDiet.id.in=${quoteDietId}&` : '';
  const quoteMaterialRequest = quoteMaterialId ? `quoteMaterial.id.in=${quoteMaterialId}&` : '';
  const quoteProductServiceRequest = quoteProductServiceId ? `quoteProductService.id.in=${quoteProductServiceId}&` : '';
  const quoteRhRequest = quoteRhId ? `quoteRh.id.in=${quoteRhId}&` : '';
  const quotePackageRequest = quotePackageId ? `quotePackage.id.in=${quotePackageId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_CLIENTGLOSAITEM_LIST,
    payload: axios.get<IClientGlosaItem>(
      `${requestUrl}${extraFiltersRequest}${secOrderRequest}${typeItemGlosaRequest}${quantityGlossedRequest}${quantityRefusedRequest}${justificationRequest}${glosaItemAceitaRequest}${clientGlosaRequest}${quoteMedicineRequest}${quoteDietRequest}${quoteMaterialRequest}${quoteProductServiceRequest}${quoteRhRequest}${quotePackageRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const createEntity: ICrudPutAction<IClientGlosaItem> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.CREATE_CLIENTGLOSAITEM_AND_GO_LIST
        : ACTION_TYPES.CREATE_CLIENTGLOSAITEM,
    payload: axios.post(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const updateEntity: ICrudPutAction<IClientGlosaItem> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.UPDATE_CLIENTGLOSAITEM_AND_GO_LIST
        : ACTION_TYPES.UPDATE_CLIENTGLOSAITEM,
    payload: axios.put(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const deleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_CLIENTGLOSAITEM,
    payload: axios.delete(requestUrl),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const showModal = view => ({
  payload: { type: view, show: true },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const hideModal = view => ({
  payload: { type: view, show: false },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const getUrlBack = (location): string => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  let urlBack = _urlBase.searchParams.get('urlBack') || '';
  urlBack = urlBack.trim();
  urlBack = urlBack.startsWith('/') ? urlBack.slice(1) : urlBack;
  urlBack = urlBack.endsWith('/') ? urlBack.slice(0, -1) : urlBack;
  return urlBack ? '/' + urlBack + '/' : '';
};

export const getClientGlosaItemState = (location): IClientGlosaItemBaseState => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  const extraFilters = jsonParse(_urlBase.searchParams.get('extraFilters') || '{}');
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;

  const secOrder = _urlBase.searchParams.get('secOrder') || '';
  const typeItemGlosa = _urlBase.searchParams.get('typeItemGlosa') || '';
  const quantityGlossed = _urlBase.searchParams.get('quantityGlossed') || '';
  const quantityRefused = _urlBase.searchParams.get('quantityRefused') || '';
  const justification = _urlBase.searchParams.get('justification') || '';
  const glosaItemAceita = _urlBase.searchParams.get('glosaItemAceita') || '';
  const clientGlosaId = _urlBase.searchParams.get('clientGlosa') || '';
  const quoteMedicineId = _urlBase.searchParams.get('quoteMedicine') || '';
  const quoteDietId = _urlBase.searchParams.get('quoteDiet') || '';
  const quoteMaterialId = _urlBase.searchParams.get('quoteMaterial') || '';
  const quoteProductServiceId = _urlBase.searchParams.get('quoteProductService') || '';
  const quoteRhId = _urlBase.searchParams.get('quoteRh') || '';
  const quotePackageId = _urlBase.searchParams.get('quotePackage') || '';
  return {
    baseFilters,
    extraFilters,
    offset,
    secOrder,
    typeItemGlosa,
    quantityGlossed,
    quantityRefused,
    justification,
    glosaItemAceita,
    clientGlosaId: clientGlosaId ? clientGlosaId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
    quoteMedicineId: quoteMedicineId ? quoteMedicineId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
    quoteDietId: quoteDietId ? quoteDietId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
    quoteMaterialId: quoteMaterialId ? quoteMaterialId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
    quoteProductServiceId: quoteProductServiceId
      ? quoteProductServiceId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] }))
      : [],
    quoteRhId: quoteRhId ? quoteRhId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
    quotePackageId: quotePackageId ? quotePackageId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
  };
};

export const getEntityFiltersURL = (state, offset = null) => {
  return (
    'baseFilters=' +
    state.baseFilters +
    '&extraFilters=' +
    encodeURI(JSON.stringify(state.extraFilters)) +
    '&page=' +
    state.activePage +
    '&' +
    'size=' +
    state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    state.sort +
    ',' +
    state.order +
    '&' +
    (state.secOrder ? 'secOrder=' + state.secOrder + '&' : '') +
    (state.typeItemGlosa ? 'typeItemGlosa=' + state.typeItemGlosa + '&' : '') +
    (state.quantityGlossed ? 'quantityGlossed=' + state.quantityGlossed + '&' : '') +
    (state.quantityRefused ? 'quantityRefused=' + state.quantityRefused + '&' : '') +
    (state.justification ? 'justification=' + state.justification + '&' : '') +
    (state.glosaItemAceita ? 'glosaItemAceita=' + state.glosaItemAceita + '&' : '') +
    (state.clientGlosaId ? 'clientGlosa=' + state.clientGlosaId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.quoteMedicineId ? 'quoteMedicine=' + state.quoteMedicineId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.quoteDietId ? 'quoteDiet=' + state.quoteDietId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.quoteMaterialId ? 'quoteMaterial=' + state.quoteMaterialId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.quoteProductServiceId
      ? 'quoteProductService=' + state.quoteProductServiceId.map(v => v.value + '<->' + v.label).join(',') + '&'
      : '') +
    (state.quoteRhId ? 'quoteRh=' + state.quoteRhId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.quotePackageId ? 'quotePackage=' + state.quotePackageId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    ''
  );
};
