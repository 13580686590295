import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import { Translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import {
  getEntity,
  IProfessionalContactBaseState,
  getUrlBack,
  getProfessionalContactState,
  getEntityFiltersURL,
} from './professional-contact.reducer';

export interface IProfessionalContactState {
  fieldsBase: IProfessionalContactBaseState;
}

export interface IProfessionalContactDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class ProfessionalContactDetail extends React.Component<IProfessionalContactDetailProps, IProfessionalContactState> {
  constructor(props: Readonly<IProfessionalContactDetailProps>) {
    super(props);
    this.state = {
      ...this.state,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getProfessionalContactState(this.props.location),
        urlBack: getUrlBack(this.props.location),
        professionalId: [{ value: this.props.match.params['idProfessional'], label: this.props.match.params['idProfessional'] }],
        baseFilters: 'professional',
      },
    };
  }

  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = '_back=1' + (offset !== null ? '&offset=' + offset : '');
    Object.keys(fieldsBase).map(key => {
      url += '&' + key + '=' + fieldsBase[key];
    });
    return url;
  };

  UNSAFE_componentWillMount() {
    this.props.getEntity(this.props.match.params['id']);
  }

  renderHeader() {
    const { professionalContactEntity } = this.props;
    const state = this.state;
    return (
      <>
        <div id="page-heading">
          {' '}
          <h2>
            <Translate contentKey="generadorApp.professionalContact.detail.title">ProfessionalContact</Translate>
            <small>&nbsp; {professionalContactEntity['email']}</small>
          </h2>
        </div>

        <div className="pull-right">
          <Button
            tag={Link}
            to={
              '/professional/' +
              this.props.match.params['idProfessional'] +
              '/professional-contact?' +
              getEntityFiltersURL(this.state.fieldsBase)
            }
            replace
            color="info"
          >
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.professionalContact.btnBack">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/professional/${this.props.match.params['idProfessional']}/professional-contact/${
              professionalContactEntity.id
            }/edit?+${getEntityFiltersURL(this.state.fieldsBase)}`}
            replace
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.professionalContact.btnEdit">Edit</Translate>
            </span>
          </Button>
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>

          <li className="breadcrumb-item">
            <Link to={'/professional'}>Professionals</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/professional/' + this.props.match.params['idProfessional']}>{this.props.match.params['idProfessional']}</Link>
          </li>
          <li className="breadcrumb-item">
            <Link
              to={
                '/professional/' +
                (this.state.fieldsBase.professionalId ? this.state.fieldsBase.professionalId.label : '') +
                '/professional-contact'
              }
            >
              Professional Contacts
            </Link>
          </li>
          <li className="breadcrumb-item active">Professional Contacts details</li>
        </ol>
      </>
    );
  }

  renderBody() {
    const { professionalContactEntity } = this.props;
    const state = this.state;
    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            <Row className="jh-entity-details">
              <Col className="view-detail-row professional-contact-telephonePrimary-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top professional-contact-telephonePrimary-detail-label" md="12">
                    <dt>
                      <span id="telephonePrimary">
                        <Translate contentKey="generadorApp.professionalContact.telephonePrimary">Telephone Primary</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top professional-contact-telephonePrimary-detail-value" md="12">
                    <dd>{professionalContactEntity.telephonePrimary}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row professional-contact-phonePrimary-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top professional-contact-phonePrimary-detail-label" md="12">
                    <dt>
                      <span id="phonePrimary">
                        <Translate contentKey="generadorApp.professionalContact.phonePrimary">Phone Primary</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top professional-contact-phonePrimary-detail-value" md="12">
                    <dd>{professionalContactEntity.phonePrimary}</dd>
                  </Col>
                </Row>
              </Col>

              <Col className="view-detail-row professional-contact-email-detail" md="3">
                <Row>
                  <Col className="view-detail-label-top professional-contact-email-detail-label" md="12">
                    <dt>
                      <span id="email">
                        <Translate contentKey="generadorApp.professionalContact.email">Email</Translate>
                      </span>
                    </dt>
                  </Col>
                  <Col className="view-detail-value-top professional-contact-email-detail-value" md="12">
                    <dd>{professionalContactEntity.email}</dd>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { professionalContactEntity } = this.props;
    const state = this.state;
    return (
      <div>
        <Panel className="professional-contact professional-contact-detail">
          <PanelHeader>{this.renderHeader()}</PanelHeader>

          <PanelBody>{this.renderBody()}</PanelBody>
        </Panel>
      </div>
    );
  }
}

export const mapStateToProps = ({ professionalContact, ...storeState }: IRootState) => ({
  professionalContactEntity: professionalContact.entity,
});

export const mapDispatchToProps = {
  getEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProfessionalContactDetail);
