/* eslint complexity: ["error", 300] */

import { connect } from 'react-redux';

import { PatientUpdate, mapStateToProps, mapDispatchToProps } from '../patient-update';
import { IRootState } from 'app/shared/reducers';

import 'react-quill/dist/quill.snow.css';

import { translate } from 'app/config/translate-component';

import 'react-quill/dist/quill.snow.css';

import { createEntity as createCidStatus } from 'app/entities/cid-status-history/cid-status-history.reducer';
import { getEntity as getOnePatientCid, updateEntity as updatePatientCid } from 'app/entities/patient-cid/patient-cid.reducer';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

import axios from 'axios';
import { selectFieldsList } from './extendSelectFields';
import { getFilterFromSelect, getFormFromSelect } from 'app/shared/util/entity-utils';
import { toast } from 'react-toastify';
import functionBeforeSubmit from '../components/functionBeforeSubmit';
import functionAfterSubmit from '../components/functionAfterSubmit';
import { BASE_API_VERSION_PATH } from 'app/config/constants';

registerLocale('pt', pt);

export interface IUpdateExtendedState {
  dumystate?: any;
}

export class PatientExtendedUpdate extends PatientUpdate {
  saveEntity = (event: any, errors: any, values: any) => {
    // console.info("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
    if (!this.state.nameSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.patient.name') }));
      return false;
    }

    if (!this.state.cepRequestCep) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.patient.zipCode') }));
      return false;
    }

    if (!this.state.numberSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.patient.number') }));
      return false;
    }

    if (!this.state.cepRequestCep) {
      errors.push(translate('entity.validation.required'));
    }

    if (errors.length === 0) {
      const { patientEntity } = this.props;
      const _entity = {
        ...patientEntity,
        // ...values,

        client: getFormFromSelect(this.state.clientSelectValue, 'many-to-one'), // many-to-one - otherSide

        patientDevices: getFormFromSelect(this.state.patientDeviceSelectValue, 'many-to-many'), // many-to-many - ownerSide

        professionalBlockeds: getFormFromSelect(this.state.professionalBlockedSelectValue, 'many-to-many'), // many-to-many - ownerSide

        professionalPreferreds: getFormFromSelect(this.state.professionalPreferredSelectValue, 'many-to-many'), // many-to-many - ownerSide

        patientSupplier: undefined,

        patientCid: undefined,

        patientAllergy: undefined,

        patientContact: undefined,

        patientResponsible: undefined,

        name: this.state.nameSelectValue,
        cpf: this.state.cpfSelectValue,
        rg: this.state.rgSelectValue,
        sex: this.state.sexSelectValue === null ? 'MASCULINO' : this.state.sexSelectValue,
        birthDate: this.state.birthDateSelectValue,
        weight: this.state.weightSelectValue,
        height: this.state.heightSelectValue,
        healthPlanEnrollment: this.state.healthPlanEnrollmentSelectValue,
        liminar: this.state.liminarSelectValue === null ? null : this.state.liminarSelectValue,
        observations: this.state.observationsSelectValue,
        informationProfessional: this.state.informationProfessionalSelectValue,
        register: this.state.registerSelectValue === null ? false : this.state.registerSelectValue,
        zipCode: this.state.cepRequestCep,
        hospitalReference: this.state.hospitalReferenceSelectValue,
        street: this.state.cepRequestLogradouro,
        complement: this.state.complementSelectValue,
        number: this.state.numberSelectValue,
        neighborhood: this.state.cepRequestBairro,
        city: this.state.cepRequestLocalidade,
        uf: this.state.cepRequestUf,
        reference: this.state.referenceSelectValue,
        lat: this.state.latSelectValue,
        lng: this.state.lngSelectValue,
        status: this.state.statusSelectValue === null ? 'ATIVO' : this.state.statusSelectValue,
        adId: this.state.adIdSelectValue === null ? null : this.state.adIdSelectValue,
        nead: this.state.neadSelectValue,
        patientComplexity: this.state.patientComplexitySelectValue === null ? null : this.state.patientComplexitySelectValue,
        obese: this.state.obeseSelectValue === null ? null : this.state.obeseSelectValue,
      };

      const entity = functionBeforeSubmit(_entity, this.props, this.state);

      const {
        name,
        zipCode,
        neighborhood,
        city,
        uf,
        cpf,
        clientId,
        register,
        status,
        extraFilters,
        activePage,
        itemsPerPage,
        sort,
        order,
      } = this.state.fieldsBase;

      const patientSupplierListAllEmbed = this.props.patientSupplierListAllEmbed;
      const patientCidListAllEmbed = this.props.patientCidListAllEmbed;
      const patientAllergyListAllEmbed = this.props.patientAllergyListAllEmbed;
      const patientContactListAllEmbed = this.props.patientContactListAllEmbed;
      const patientResponsibleListAllEmbed = this.props.patientResponsibleListAllEmbed;

      if (this.state.isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              name,
              zipCode,
              neighborhood,
              city,
              uf,
              cpf,
              getFilterFromSelect(clientId, 'many-to-one'),
              register,
              status,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;

          axios.post(BASE_API_VERSION_PATH + 'patient-status-histories', {
            observation: 'Cadastro do Paciente',
            patient: { id: idEntity },
            status: 'ATIVO',
          });

          if (idEntity) {
            patientSupplierListAllEmbed.map(v => {
              v['id'] = null;
              v['patient'] = { id: idEntity };
              this.props['createEntityPatientSupplier'](v);
            });
          }
          if (idEntity) {
            patientCidListAllEmbed.map(v => {
              v['id'] = null;
              v['patient'] = { id: idEntity };
              this.props['createEntityPatientCid'](v);
            });
          }
          if (idEntity) {
            patientAllergyListAllEmbed.map(v => {
              v['id'] = null;
              v['patient'] = { id: idEntity };
              this.props['createEntityPatientAllergy'](v);
            });
          }
          if (idEntity) {
            patientContactListAllEmbed.map(v => {
              v['id'] = null;
              v['patient'] = { id: idEntity };
              this.props['createEntityPatientContact'](v);
            });
          }
          if (idEntity) {
            patientResponsibleListAllEmbed.map(v => {
              v['id'] = null;
              v['patient'] = { id: idEntity };
              this.props['createEntityPatientResponsible'](v);
            });
          }

          functionAfterSubmit(entity, resultEntity, 'createEntity');
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              name,
              zipCode,
              neighborhood,
              city,
              uf,
              cpf,
              getFilterFromSelect(clientId, 'many-to-one'),
              register,
              status,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
          functionAfterSubmit(entity, resultEntity, 'createEntity');
        });
      }
    }
  };
}

export const mapStateToPropsExtended = (storeState: IRootState) => {
  const listBase = mapStateToProps(storeState);
  return {
    ...listBase,
    listProcediment: storeState.patDrPrescrProcd.entities,
  };
};

export const mapDispatchToPropsExtended = {
  ...mapDispatchToProps,
  getOnePatientCid,
  createCidStatus,
  updatePatientCid,
};
export type StatePropsExtended = ReturnType<typeof mapStateToPropsExtended>;
export type DispatchPropsExtended = typeof mapDispatchToPropsExtended;
export default connect(mapStateToPropsExtended, mapDispatchToPropsExtended)(PatientExtendedUpdate);
