import { Translate, translate } from 'app/config/translate-component';
import { getListAxios, showFieldsSelectAsync } from 'app/shared/util/entity-utils';
import React from 'react';
import { Label, Row } from 'reactstrap';
import SelectAsync from 'react-select/async';
import { IKitRhsState } from '../kit-rhs';

export interface IKitRhsProps {
  baseState?: IKitRhsState;
  baseProps?: any;
  setState?: any;
  entityBase?: any;
}

const KitRhsComponentCustomize = (props: IKitRhsProps) => {
  const auxCategory = props.baseState.specialtySelectValue ? props.baseState.specialtySelectValue.category : null;
  return (
    <Row className="mr-1 mt-1">
      <div style={{ width: '100%' }}>
        <Label for="medicine-material-category">
          <Translate contentKey="generadorApp.medicineMaterial.category">Category</Translate>
        </Label>
        <SelectAsync
          id="push-category"
          name={'category'}
          className={'css-select-control'}
          data-type-rel="many-to-one-other-side"
          value={
            props.baseState.categorySelectValue
              ? props.baseState.categorySelectValue
              : auxCategory
              ? {
                  ...auxCategory,
                  value: auxCategory.id,
                  label: auxCategory.name,
                }
              : ''
          }
          onChange={options => props.setState({ categorySelectValue: options, specialtyStartSelectOptions: undefined })}
          defaultOptions={props.baseState.categoryStartSelectOptions ? props.baseState.categoryStartSelectOptions : []}
          loadingMessage={input => translate('selectAsync.loadingMessage')}
          noOptionsMessage={input =>
            props.baseState.categoryStartSelectOptions === undefined
              ? translate('selectAsync.loadingMessage')
              : translate('selectAsync.noOptionsMessage')
          }
          onMenuOpen={async () => {
            if (props.baseState.categoryStartSelectOptions === undefined) {
              const result = await getListAxios('categories', { 'levelGroup.sub.equals': 'ESPECIALIDADE' }, 0, 100, 'name,asc', 'name');
              props.setState({
                categoryStartSelectOptions: result.data
                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                  : [],
              });
            }
          }}
          loadOptions={async (inputValue, callback) => {
            const result = await getListAxios(
              'categories',
              { 'levelGroup.sub.equals': 'ESPECIALIDADE', 'name.contains': inputValue },
              0,
              100,
              'name,asc',
              'name'
            );
            callback(result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' })) : []);
          }}
        />
      </div>
    </Row>
  );
};

const mapStateToProps = storeState => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default KitRhsComponentCustomize;
