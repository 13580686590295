import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { IPermissionProfile } from 'app/shared/model/permission-profile.model';
import { IPermissionUser } from 'app/shared/model/permission-user.model';

export interface IPermission {
  id?: number;
  slug?: string;
  name?: string;
  view?: boolean;
  resgister?: boolean;
  edit?: boolean;
  canDelete?: boolean;
  manualRemoval?: boolean;
  editLocation?: boolean;
  report?: boolean;
  activateProfessional?: boolean;
  deletePhoto?: boolean;
  financialValue?: boolean;
  valueAuthorization?: boolean;
  confirmPayment?: boolean;
  evolution?: boolean;
  professionalName?: boolean;
  professionalRegister?: boolean;
  contact?: boolean;
  status?: boolean;
  file?: boolean;
  comercialTable?: boolean;
  drPrescription?: boolean;
  nrPrescription?: boolean;
  clinicalIndicators?: boolean;
  importTable?: boolean;
  permissionProfile?: IPermissionProfile[];
  permissionUser?: IPermissionUser[];
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  permissionProfile: {
    isMulti: false, // one-to-many - other-side
    entity: 'PermissionProfile',
    urlParameters: {
      enpoint: 'permission-profiles',
      filters: 'permission.id.equals',
    },
  },
  permissionUser: {
    isMulti: false, // one-to-many - other-side
    entity: 'PermissionUser',
    urlParameters: {
      enpoint: 'permission-users',
      filters: 'permission.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'permission.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'permission.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<IPermission> = {
  view: false,
  resgister: false,
  edit: false,
  canDelete: false,
  manualRemoval: false,
  editLocation: false,
  report: false,
  activateProfessional: false,
  deletePhoto: false,
  financialValue: false,
  valueAuthorization: false,
  confirmPayment: false,
  evolution: false,
  professionalName: false,
  professionalRegister: false,
  contact: false,
  status: false,
  file: false,
  comercialTable: false,
  drPrescription: false,
  nrPrescription: false,
  clinicalIndicators: false,
  importTable: false,
};
