import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import AuditEntity from './audit-entity';

import AuditEntityDetail from './audit-entity-detail';

import AuditEntityUpdate from './audit-entity-update';

import AuditEntityDeleteDialog from './audit-entity-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.path}/new`} component={AuditEntityUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id/edit`} component={AuditEntityUpdate} />
      <ErrorBoundaryRoute exact path={`${match.path}/:id`} component={AuditEntityDetail} />
      <ErrorBoundaryRoute path={match.path} component={AuditEntity} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.path}/:id/delete`} component={AuditEntityDeleteDialog} />
  </>
);

export default Routes;
