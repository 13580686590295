import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { IPatient } from 'app/shared/model/patient.model';
import { GeralSelect } from 'app/shared/model/enumerations/geral-select.model';

export interface IPatientResponsible {
  id?: number;
  name?: string;
  kinshipDegree?: string;
  contactTelephonePrincipal?: string;
  contactTelephoneSecondary?: string;
  comments?: string;
  email?: string;
  accessApp?: GeralSelect;
  zipCode?: string;
  street?: string;
  complement?: string;
  number?: string;
  neighborhood?: string;
  city?: string;
  uf?: string;
  patient?: IPatient;
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  patient: {
    isMulti: true, // many-to-one - other-side
    entity: 'Patient',
    urlParameters: {
      enpoint: 'patients',
      filters: 'patientResponsible.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'patientResponsible.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'patientResponsible.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<IPatientResponsible> = {};
