/* eslint complexity: ["error", 100] */
import axios from 'axios';
import { IPayloadResult } from 'react-jhipster';

import { IPayload } from 'react-jhipster/src/type/redux-action.type';

import { BASE_API_VERSION_PATH } from 'app/config/constants';

import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { getEntities } from '../brasindice.reducer';

export declare type ICrudPutAction<T> = (data?: T, listFiltersPage?) => IPayload<T> | IPayloadResult<T>;
export const ACTION_TYPES = {
  CREATE_IMPORTFILE: 'brasindice/CREATE_IMPORTFILE',
};

const initialState = {
  loading: false,
};

export type BrasindiceStateExtended = Readonly<typeof initialState>;

export default (state: BrasindiceStateExtended = initialState, action): BrasindiceStateExtended => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.CREATE_IMPORTFILE):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.CREATE_IMPORTFILE):
      return {
        ...state,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.CREATE_IMPORTFILE):
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

const apiUrl = BASE_API_VERSION_PATH + 'brasindices';

export const importFile: any = (entity, listFiltersPage) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_IMPORTFILE,
    payload: axios.post(apiUrl + '/import-file', entity),
  });
  if (listFiltersPage !== false && listFiltersPage['reloadList'] !== false) {
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};
