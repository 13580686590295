import { Moment } from 'moment';
import { getListAxios } from '../util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { ILevelGroupStatusHistory } from 'app/shared/model/level-group-status-history.model';
import { ICategory } from 'app/shared/model/category.model';
import { IClComRuler } from 'app/shared/model/cl-com-ruler.model';
import { IClComItem } from 'app/shared/model/cl-com-item.model';
import { IProfessional } from 'app/shared/model/professional.model';
import { IDose } from 'app/shared/model/dose.model';
import { IBrand } from 'app/shared/model/brand.model';
import { IMedicineMaterial } from 'app/shared/model/medicine-material.model';
import { IPresentation } from 'app/shared/model/presentation.model';
import { ISupplier } from 'app/shared/model/supplier.model';
import { ChargeType } from 'app/shared/model/enumerations/charge-type.model';
import { Sub } from 'app/shared/model/enumerations/sub.model';
import { Status } from 'app/shared/model/enumerations/status.model';

export interface ILevelGroup {
  id?: number;
  name?: string;
  chargeType?: ChargeType;
  despesaNumber?: string;
  sub?: Sub;
  status?: Status;
  levelGroupStatusHistory?: ILevelGroupStatusHistory[];
  category?: ICategory[];
  clComRuler?: IClComRuler[];
  clComItem?: IClComItem[];
  professional?: IProfessional[];
  dose?: IDose[];
  brand?: IBrand[];
  medicineMaterial?: IMedicineMaterial[];
  presentation?: IPresentation[];
  suppliers?: ISupplier[];
  createdBy?: IUser;
  createdDate?: Moment;
  lastModifiedBy?: IUser;
  lastModifiedDate?: Moment;
  deletedAt?: Moment;
}

export const entityGetUrl = {
  levelGroupStatusHistory: {
    isMulti: false, // one-to-many - other-side
    entity: 'LevelGroupStatusHistory',
    urlParameters: {
      enpoint: 'level-group-status-histories',
      filters: 'levelGroup.id.equals',
    },
  },
  category: {
    isMulti: false, // one-to-many - other-side
    entity: 'Category',
    urlParameters: {
      enpoint: 'categories',
      filters: 'levelGroup.id.equals',
    },
  },
  clComRuler: {
    isMulti: false, // one-to-many - other-side
    entity: 'ClComRuler',
    urlParameters: {
      enpoint: 'cl-com-rulers',
      filters: 'levelGroup.id.equals',
    },
  },
  clComItem: {
    isMulti: false, // one-to-many - other-side
    entity: 'ClComItem',
    urlParameters: {
      enpoint: 'cl-com-items',
      filters: 'levelGroup.id.equals',
    },
  },
  professional: {
    isMulti: false, // one-to-many - other-side
    entity: 'Professional',
    urlParameters: {
      enpoint: 'professionals',
      filters: 'levelGroup.id.equals',
    },
  },
  dose: {
    isMulti: false, // one-to-many - other-side
    entity: 'Dose',
    urlParameters: {
      enpoint: 'doses',
      filters: 'levelGroup.id.equals',
    },
  },
  brand: {
    isMulti: false, // one-to-many - other-side
    entity: 'Brand',
    urlParameters: {
      enpoint: 'brands',
      filters: 'levelGroup.id.equals',
    },
  },
  medicineMaterial: {
    isMulti: false, // one-to-many - other-side
    entity: 'MedicineMaterial',
    urlParameters: {
      enpoint: 'medicine-materials',
      filters: 'levelGroup.id.equals',
    },
  },
  presentation: {
    isMulti: false, // one-to-many - other-side
    entity: 'Presentation',
    urlParameters: {
      enpoint: 'presentations',
      filters: 'levelGroup.id.equals',
    },
  },
  supplier: {
    isMulti: true, // many-to-many - other-side
    entity: 'Supplier',
    urlParameters: {
      enpoint: 'suppliers',
      filters: 'groups.id.equals',
    },
  },

  createdBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'levelGroup.id.equals' } },
  lastModifiedBy: { isMulti: false, entity: 'User', urlParameters: { enpoint: 'users', filters: 'levelGroup.id.equals' } },
};

export const getEntitiesAixos = async (entity, id) => {
  const entities = entityGetUrl[entity];
  const filters = {};
  filters[entities.urlParameters.enpoint] = id;
  const result = await getListAxios(entities.urlParameters.enpoint, filters, 0, 100, 'id,asc');
  if (result && result['data']) {
    if (entities.isMulti) {
      return result['data'] ? result['data'] : [];
    }
    return result['data'] ? result['data'].pop() : null;
  }
};

export const defaultValue: Readonly<ILevelGroup> = {};
