/* eslint no-console: off */
import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, RouteProps, Link } from 'react-router-dom';
import { Translate } from 'app/config/translate-component';
import { IRootState } from 'app/shared/reducers';
import ErrorBoundary from 'app/shared/error/error-boundary';

interface IOwnProps extends RouteProps {
  hasAnyAuthorities?: string[];
}

export interface IPrivateRouteProps extends IOwnProps, StateProps {}

export const PrivateRouteComponent = ({
  component: Component,
  isAuthenticated,
  sessionHasBeenFetched,
  isAuthorized,
  ...rest
}: IPrivateRouteProps) => {
  const checkAuthorities = props =>
    isAuthorized ? (
      <ErrorBoundary>
        <Component {...props} />
      </ErrorBoundary>
    ) : (
      <div className="insufficient-authority">
        <div className="error">
          <div className="error-code m-b-10">403</div>
          <div className="error-content">
            <div className="error-message">
              <Translate contentKey="error.http.403">You are not authorized to access this page.</Translate>
            </div>
            <div className="error-desc m-b-30">
              <Translate contentKey="error.http-desc.403">You are not authorized to access this page.</Translate>
            </div>
            <div>
              <Link to="/" className="btn btn-success p-l-20 p-r-20">
                <Translate contentKey="error.goHome">Exit</Translate>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );

  const renderRedirect = props => {
    if (!sessionHasBeenFetched) {
      return <div />;
    } else {
      return isAuthenticated ? (
        checkAuthorities(props)
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            search: props.location.search,
            state: { from: props.location },
          }}
        />
      );
    }
  };

  if (!Component) throw new Error(`A component needs to be specified for private route for path ${(rest as any).path}`);
  return <Route {...rest} render={renderRedirect} />;
};

export const hasAnyAuthority = (account, hasAnyAuthorities: string[], type = '') => {
  if (account.authorities.includes('ROLE_ADMIN')) return true;
  const permissions = account
    ? Object.keys(account.permissions).map(key => {
        if (type !== '') {
          return account.permissions[key][type] === true ? key : false;
        }
        return Object.values(account.permissions[key]).filter(isAuth => isAuth === true).length > 0 ? key : false;
      })
    : [];

  if (hasAnyAuthorities.length === 0) return true;
  if (!permissions || permissions.length === 0) return false;
  if (type === 'resgister') {
    console.info({ permissions, hasAnyAuthorities, type });
  }
  return hasAnyAuthorities.some(auth => permissions.includes(auth));
};

export const hasAnyAuthorityNew = (account, hasAnyAuthorities: string[], type = '') => {
  if (account.authorities.includes('ROLE_ADMIN')) return true;

  const permissions = account
    ? Object.keys(account.permissions).map(key => {
        if (type !== '') {
          return account.permissions[key][type] === true ? key : false;
        }
        return Object.values(account.permissions[key]).filter(isAuth => isAuth === true).length > 0 ? key : false;
      })
    : [];

  if (hasAnyAuthorities.length === 0) return true;
  if (!permissions || permissions.length === 0) return false;

  return hasAnyAuthorities.some(auth => permissions.includes(auth));
};

const mapStateToProps = ({ authentication: { isAuthenticated, account, sessionHasBeenFetched } }: IRootState) =>
  // { hasAnyAuthorities = [] }: IOwnProps
  ({
    isAuthenticated: true,
    // isAuthorized: hasAnyAuthority(account, hasAnyAuthorities),
    isAuthorized: true,
    sessionHasBeenFetched: true,
  });

type StateProps = ReturnType<typeof mapStateToProps>;

/**
 * A route wrapped in an authentication check so that routing happens only when you are authenticated.
 * Accepts same props as React router Route.
 * The route also checks for authorization if hasAnyAuthorities is specified.
 */
export const PrivateRoute = connect<StateProps, undefined, IOwnProps>(mapStateToProps, null, null, { pure: false })(PrivateRouteComponent);

export default PrivateRoute;
