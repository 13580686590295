/* eslint complexity: ["error", 500] */
/* eslint no-empty: off */
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import SelectAsync from 'react-select/async';

import { Link, RouteComponentProps } from 'react-router-dom';
import InputMask from 'react-input-mask';
import ReactQuill from 'react-quill';
import CurrencyInput from 'react-currency-input';
import {
  quillEditorModules,
  quillEditorFormats,
  getFilterFromSelect,
  getFormFromSelect,
  getListAxios,
  showFieldsSelectAsync,
} from 'app/shared/util/entity-utils';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { Panel, PanelHeader, PanelBody } from 'app/shared/layout/panel/panel';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Col, Label } from 'reactstrap';
import { setFileData } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

import { ITEMS_PER_PAGE, getSortState } from 'app/shared/util/pagination.constants';

import {
  IPatientUpdateState as IBaseUpdateState,
  getEntity,
  getPatientState,
  getUrlBack,
  updateEntity,
  createEntity,
  getEntityFiltersURL,
  getEntityOffset,
  setBlob,
  reset,
  patientSupplierSetEntities,
  patientSupplierGetEntities,
  patientSupplierUpdateEntity,
  patientSupplierReset,
  patientSupplierGetEntity,
  patientSupplierCreateEntity,
  patientSupplierDeleteEntity,
  patientCidSetEntities,
  patientCidGetEntities,
  patientCidUpdateEntity,
  patientCidReset,
  patientCidGetEntity,
  patientCidCreateEntity,
  patientCidDeleteEntity,
  patientAllergySetEntities,
  patientAllergyGetEntities,
  patientAllergyUpdateEntity,
  patientAllergyReset,
  patientAllergyGetEntity,
  patientAllergyCreateEntity,
  patientAllergyDeleteEntity,
  patientContactSetEntities,
  patientContactGetEntities,
  patientContactUpdateEntity,
  patientContactReset,
  patientContactGetEntity,
  patientContactCreateEntity,
  patientContactDeleteEntity,
  patientResponsibleSetEntities,
  patientResponsibleGetEntities,
  patientResponsibleUpdateEntity,
  patientResponsibleReset,
  patientResponsibleGetEntity,
  patientResponsibleCreateEntity,
  patientResponsibleDeleteEntity,
} from './patient.reducer';

import { viacepRequest } from 'app/shared/util/entity-utils';

import { selectFieldsList } from './extended/extendSelectFields';
import functionAfterFormMount from './components/functionAfterFormMount';
import functionAfterFormUpdate from './components/functionAfterFormUpdate';

import functionBeforeSubmit from './components/functionBeforeSubmit';
import functionAfterSubmit from './components/functionAfterSubmit';

import AgeComponent from './components/AgeComponent';
import ImcComponent from './components/ImcComponent';

import {
  mapStateToProps as mapStateToPropsPatientSupplier,
  mapDispatchToProps as mapDispatchToPropsPatientSupplier,
  PatientSupplier,
} from 'app/entities/patient-supplier/patient-supplier';

import {
  mapStateToPropsExtended as mapStateToPropsPatientCid,
  mapDispatchToPropsExtended as mapDispatchToPropsPatientCid,
  PatientCidExtended as PatientCid,
} from 'app/entities/patient-cid/extended/patient-cid';

import {
  mapStateToPropsExtended as mapStateToPropsPatientAllergy,
  mapDispatchToPropsExtended as mapDispatchToPropsPatientAllergy,
  PatientAllergyExtended as PatientAllergy,
} from 'app/entities/patient-allergy/extended/patient-allergy';

import {
  mapStateToProps as mapStateToPropsPatientContact,
  mapDispatchToProps as mapDispatchToPropsPatientContact,
  PatientContact,
} from 'app/entities/patient-contact/patient-contact';

import {
  mapStateToProps as mapStateToPropsPatientResponsible,
  mapDispatchToProps as mapDispatchToPropsPatientResponsible,
  PatientResponsible,
} from 'app/entities/patient-responsible/patient-responsible';

import { StatePropsExtended, DispatchPropsExtended, IUpdateExtendedState } from './extended/patient-update';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';
import moment from 'moment';
registerLocale('pt', pt);

export interface IPatientUpdateProps
  extends StateProps,
    DispatchProps,
    StatePropsExtended,
    DispatchPropsExtended,
    RouteComponentProps<{ url: string }> {}
export interface IPatientUpdateState extends IBaseUpdateState, IUpdateExtendedState {}

export class PatientUpdate extends React.Component<IPatientUpdateProps, IPatientUpdateState> {
  nameFileInput: any;

  cpfFileInput: any;

  rgFileInput: any;

  sexFileInput: any;

  birthDateFileInput: any;

  weightFileInput: any;

  heightFileInput: any;

  healthPlanEnrollmentFileInput: any;

  liminarFileInput: any;

  observationsFileInput: any;

  informationProfessionalFileInput: any;

  registerFileInput: any;

  zipCodeFileInput: any;

  hospitalReferenceFileInput: any;

  streetFileInput: any;

  complementFileInput: any;

  numberFileInput: any;

  neighborhoodFileInput: any;

  cityFileInput: any;

  ufFileInput: any;

  referenceFileInput: any;

  latFileInput: any;

  lngFileInput: any;

  statusFileInput: any;

  adIdFileInput: any;

  neadFileInput: any;

  patientComplexityFileInput: any;

  obeseFileInput: any;
  constructor(props: Readonly<IPatientUpdateProps>) {
    super(props);

    this.patientSupplierUpdateEntity = this.patientSupplierUpdateEntity.bind(this);
    this.patientSupplierGetEntities = this.patientSupplierGetEntities.bind(this);
    this.patientSupplierReset = this.patientSupplierReset.bind(this);
    this.patientSupplierGetEntity = this.patientSupplierGetEntity.bind(this);
    this.patientSupplierCreateEntity = this.patientSupplierCreateEntity.bind(this);
    this.patientSupplierDeleteEntity = this.patientSupplierDeleteEntity.bind(this);
    this.patientCidUpdateEntity = this.patientCidUpdateEntity.bind(this);
    this.patientCidGetEntities = this.patientCidGetEntities.bind(this);
    this.patientCidReset = this.patientCidReset.bind(this);
    this.patientCidGetEntity = this.patientCidGetEntity.bind(this);
    this.patientCidCreateEntity = this.patientCidCreateEntity.bind(this);
    this.patientCidDeleteEntity = this.patientCidDeleteEntity.bind(this);
    this.patientAllergyUpdateEntity = this.patientAllergyUpdateEntity.bind(this);
    this.patientAllergyGetEntities = this.patientAllergyGetEntities.bind(this);
    this.patientAllergyReset = this.patientAllergyReset.bind(this);
    this.patientAllergyGetEntity = this.patientAllergyGetEntity.bind(this);
    this.patientAllergyCreateEntity = this.patientAllergyCreateEntity.bind(this);
    this.patientAllergyDeleteEntity = this.patientAllergyDeleteEntity.bind(this);
    this.patientContactUpdateEntity = this.patientContactUpdateEntity.bind(this);
    this.patientContactGetEntities = this.patientContactGetEntities.bind(this);
    this.patientContactReset = this.patientContactReset.bind(this);
    this.patientContactGetEntity = this.patientContactGetEntity.bind(this);
    this.patientContactCreateEntity = this.patientContactCreateEntity.bind(this);
    this.patientContactDeleteEntity = this.patientContactDeleteEntity.bind(this);
    this.patientResponsibleUpdateEntity = this.patientResponsibleUpdateEntity.bind(this);
    this.patientResponsibleGetEntities = this.patientResponsibleGetEntities.bind(this);
    this.patientResponsibleReset = this.patientResponsibleReset.bind(this);
    this.patientResponsibleGetEntity = this.patientResponsibleGetEntity.bind(this);
    this.patientResponsibleCreateEntity = this.patientResponsibleCreateEntity.bind(this);
    this.patientResponsibleDeleteEntity = this.patientResponsibleDeleteEntity.bind(this);

    this.observationsFileInput = React.createRef();

    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      functionEmbebed: [],

      patientDoctorPrescriptionSelectValue: null,

      patientNursingPrescriptionSelectValue: null,

      patientStatusHistorySelectValue: null,

      patientContactSelectValue: null,

      patientClinicalIndicatorSelectValue: null,

      patientCidSelectValue: null,

      patientAllergySelectValue: null,

      patientSupplierSelectValue: null,

      quoteSelectValue: null,

      medicalRecordSelectValue: null,

      nursingDiarySelectValue: null,

      nursingPrescriptionSelectValue: null,

      patientFileSelectValue: null,

      patientResponsibleSelectValue: null,

      padSelectValue: null,

      pushSelectValue: null,

      patientDeviceSelectValue: null,

      professionalBlockedSelectValue: null,

      professionalPreferredSelectValue: null,

      supplierSelectValue: null,

      clientSelectValue: null,

      cepRequestCep: null,

      cepRequestLogradouro: null,

      cepRequestBairro: null,
      cepRequestLocalidade: null,
      cepRequestUf: null,

      nameSelectValue: null,
      cpfSelectValue: null,
      rgSelectValue: null,
      sexSelectValue: null,
      birthDateSelectValue: null,
      weightSelectValue: null,
      heightSelectValue: null,
      healthPlanEnrollmentSelectValue: null,
      liminarSelectValue: null,
      observationsSelectValue: null,
      informationProfessionalSelectValue: null,
      registerSelectValue: null,
      zipCodeSelectValue: null,
      hospitalReferenceSelectValue: null,
      streetSelectValue: null,
      complementSelectValue: null,
      numberSelectValue: null,
      neighborhoodSelectValue: null,
      citySelectValue: null,
      ufSelectValue: null,
      referenceSelectValue: null,
      latSelectValue: null,
      lngSelectValue: null,
      statusSelectValue: null,
      adIdSelectValue: null,
      neadSelectValue: null,
      patientComplexitySelectValue: null,
      obeseSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getPatientState(this.props.location),
        urlBack: getUrlBack(this.props.location),
      },
      activeTab: 0,
      idspatientDevice: [],
      idsprofessionalBlocked: [],
      idsprofessionalPreferred: [],
      idssupplier: [],
      patientDoctorPrescriptionObservation: null,
      patientNursingPrescriptionObservations: null,
      clientFantasyName: null,

      isNew: !this.props.match.params || !this.props.match.params['id'],
    };
  }
  toggleTab(tab: number) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }
  tabsNext(tab: number, tabAtual?: number) {
    if (typeof tabAtual !== 'undefined' && tabAtual === 0) {
      if (!this.state.nameSelectValue) {
        toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.patient.name') }));
        return false;
      }
    }
    if (typeof tabAtual !== 'undefined' && tabAtual === 1) {
      if (!this.state.cepRequestCep) {
        toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.patient.zipCode') }));
        return false;
      }

      if (!this.state.numberSelectValue) {
        toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.patient.number') }));
        return false;
      }
    }
    if (typeof tabAtual !== 'undefined' && tabAtual === 2) {
    }
    if (typeof tabAtual !== 'undefined' && tabAtual === 3) {
    }
    if (typeof tabAtual !== 'undefined' && tabAtual === 4) {
    }
    if (typeof tabAtual !== 'undefined' && tabAtual === 5) {
    }
    this.toggleTab(tab);
  }

  patientSupplierUpdateEntity(...values) {
    values[0]['patient'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['updateEntityPatientSupplier'](...values);
    }
    return this.props.patientSupplierUpdateEntity(...values);
  }
  patientSupplierGetEntities(...values) {
    return this.props.patientSupplierGetEntities(...values);
  }
  patientSupplierReset(...values) {
    return this.props.patientSupplierReset(...values);
  }
  patientSupplierGetEntity(...values) {
    return this.props.patientSupplierGetEntity(...values);
  }
  patientSupplierCreateEntity(...values) {
    values[0]['patient'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['createEntityPatientSupplier'](...values);
    }
    return this.props.patientSupplierCreateEntity(...values);
  }
  patientSupplierDeleteEntity(...values) {
    if (!this.state.isNew) {
      this.props['deleteEntityPatientSupplier'](...values);
    }
    return this.props.patientSupplierDeleteEntity(...values);
  }
  patientCidUpdateEntity(...values) {
    values[0]['patient'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['updateEntityPatientCid'](...values);
    }
    return this.props.patientCidUpdateEntity(...values);
  }
  patientCidGetEntities(...values) {
    return this.props.patientCidGetEntities(...values);
  }
  patientCidReset(...values) {
    return this.props.patientCidReset(...values);
  }
  patientCidGetEntity(...values) {
    return this.props.patientCidGetEntity(...values);
  }
  patientCidCreateEntity(...values) {
    values[0]['patient'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['createEntityPatientCid'](...values);
    }
    return this.props.patientCidCreateEntity(...values);
  }
  patientCidDeleteEntity(...values) {
    if (!this.state.isNew) {
      this.props['deleteEntityPatientCid'](...values);
    }
    return this.props.patientCidDeleteEntity(...values);
  }
  patientAllergyUpdateEntity(...values) {
    values[0]['patient'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['updateEntityPatientAllergy'](...values);
    }
    return this.props.patientAllergyUpdateEntity(...values);
  }
  patientAllergyGetEntities(...values) {
    return this.props.patientAllergyGetEntities(...values);
  }
  patientAllergyReset(...values) {
    return this.props.patientAllergyReset(...values);
  }
  patientAllergyGetEntity(...values) {
    return this.props.patientAllergyGetEntity(...values);
  }
  patientAllergyCreateEntity(...values) {
    values[0]['patient'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['createEntityPatientAllergy'](...values);
    }
    return this.props.patientAllergyCreateEntity(...values);
  }
  patientAllergyDeleteEntity(...values) {
    if (!this.state.isNew) {
      this.props['deleteEntityPatientAllergy'](...values);
    }
    return this.props.patientAllergyDeleteEntity(...values);
  }
  patientContactUpdateEntity(...values) {
    values[0]['patient'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['updateEntityPatientContact'](...values);
    }
    return this.props.patientContactUpdateEntity(...values);
  }
  patientContactGetEntities(...values) {
    return this.props.patientContactGetEntities(...values);
  }
  patientContactReset(...values) {
    return this.props.patientContactReset(...values);
  }
  patientContactGetEntity(...values) {
    return this.props.patientContactGetEntity(...values);
  }
  patientContactCreateEntity(...values) {
    values[0]['patient'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['createEntityPatientContact'](...values);
    }
    return this.props.patientContactCreateEntity(...values);
  }
  patientContactDeleteEntity(...values) {
    if (!this.state.isNew) {
      this.props['deleteEntityPatientContact'](...values);
    }
    return this.props.patientContactDeleteEntity(...values);
  }
  patientResponsibleUpdateEntity(...values) {
    values[0]['patient'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['updateEntityPatientResponsible'](...values);
    }
    return this.props.patientResponsibleUpdateEntity(...values);
  }
  patientResponsibleGetEntities(...values) {
    return this.props.patientResponsibleGetEntities(...values);
  }
  patientResponsibleReset(...values) {
    return this.props.patientResponsibleReset(...values);
  }
  patientResponsibleGetEntity(...values) {
    return this.props.patientResponsibleGetEntity(...values);
  }
  patientResponsibleCreateEntity(...values) {
    values[0]['patient'] = !this.state.isNew ? { id: parseInt(this.props.match.params['id'], 10) } : 'is_new';
    if (!this.state.isNew) {
      this.props['createEntityPatientResponsible'](...values);
    }
    return this.props.patientResponsibleCreateEntity(...values);
  }
  patientResponsibleDeleteEntity(...values) {
    if (!this.state.isNew) {
      this.props['deleteEntityPatientResponsible'](...values);
    }
    return this.props.patientResponsibleDeleteEntity(...values);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateSuccess !== this.props.updateSuccess && prevProps.updateSuccess) {
      this.handleClose();
    }

    // data-type-rel -> tipo-3 one-to-one owner-side
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientDoctorPrescriptions &&
        this.state.patientDoctorPrescriptionSelectValue === null &&
        this.props.patientEntity.patientDoctorPrescription &&
        this.props.patientEntity.patientDoctorPrescription.id)
    ) {
      const p =
        this.props.patientEntity && this.props.patientEntity.patientDoctorPrescription
          ? this.props.patientEntity.patientDoctorPrescription
          : null;
      this.setState({
        patientDoctorPrescriptionSelectValue: p ? { ...p, value: p.id, label: p['observation'] ? p.observation : '' } : null,
      });
    }

    // data-type-rel -> tipo-3 one-to-one owner-side
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientNursingPrescriptions &&
        this.state.patientNursingPrescriptionSelectValue === null &&
        this.props.patientEntity.patientNursingPrescription &&
        this.props.patientEntity.patientNursingPrescription.id)
    ) {
      const p =
        this.props.patientEntity && this.props.patientEntity.patientNursingPrescription
          ? this.props.patientEntity.patientNursingPrescription
          : null;
      this.setState({
        patientNursingPrescriptionSelectValue: p ? { ...p, value: p.id, label: p['observations'] ? p.observations : '' } : null,
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientStatusHistories.length > 0 &&
        this.state.patientStatusHistorySelectValue === null &&
        this.props.patientEntity.patientStatusHistory)
    ) {
      this.setState({
        patientStatusHistorySelectValue:
          this.props.patientEntity && this.props.patientEntity.patientStatusHistory
            ? this.props.patientEntity.patientStatusHistory.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientContacts.length > 0 && this.state.patientContactSelectValue === null && this.props.patientEntity.patientContact)
    ) {
      this.setState({ patientContactSelectValue: this.props.patientEntity.patientContact });
      this.props.patientContactSetEntities(this.props.patientEntity.patientContact);
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientClinicalIndicators.length > 0 &&
        this.state.patientClinicalIndicatorSelectValue === null &&
        this.props.patientEntity.patientClinicalIndicator)
    ) {
      this.setState({
        patientClinicalIndicatorSelectValue:
          this.props.patientEntity && this.props.patientEntity.patientClinicalIndicator
            ? this.props.patientEntity.patientClinicalIndicator.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientCids.length > 0 && this.state.patientCidSelectValue === null && this.props.patientEntity.patientCid)
    ) {
      this.setState({ patientCidSelectValue: this.props.patientEntity.patientCid });
      this.props.patientCidSetEntities(this.props.patientEntity.patientCid);
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientAllergies.length > 0 && this.state.patientAllergySelectValue === null && this.props.patientEntity.patientAllergy)
    ) {
      this.setState({ patientAllergySelectValue: this.props.patientEntity.patientAllergy });
      this.props.patientAllergySetEntities(this.props.patientEntity.patientAllergy);
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientSuppliers.length > 0 && this.state.patientSupplierSelectValue === null && this.props.patientEntity.patientSupplier)
    ) {
      this.setState({ patientSupplierSelectValue: this.props.patientEntity.patientSupplier });
      this.props.patientSupplierSetEntities(this.props.patientEntity.patientSupplier);
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.quotes.length > 0 && this.state.quoteSelectValue === null && this.props.patientEntity.quote)
    ) {
      this.setState({
        quoteSelectValue:
          this.props.patientEntity && this.props.patientEntity.quote
            ? this.props.patientEntity.quote.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.medicalRecords.length > 0 && this.state.medicalRecordSelectValue === null && this.props.patientEntity.medicalRecord)
    ) {
      this.setState({
        medicalRecordSelectValue:
          this.props.patientEntity && this.props.patientEntity.medicalRecord
            ? this.props.patientEntity.medicalRecord.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.nursingDiaries.length > 0 && this.state.nursingDiarySelectValue === null && this.props.patientEntity.nursingDiary)
    ) {
      this.setState({
        nursingDiarySelectValue:
          this.props.patientEntity && this.props.patientEntity.nursingDiary
            ? this.props.patientEntity.nursingDiary.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.nursingPrescriptions.length > 0 &&
        this.state.nursingPrescriptionSelectValue === null &&
        this.props.patientEntity.nursingPrescription)
    ) {
      this.setState({
        nursingPrescriptionSelectValue:
          this.props.patientEntity && this.props.patientEntity.nursingPrescription
            ? this.props.patientEntity.nursingPrescription.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientFiles.length > 0 && this.state.patientFileSelectValue === null && this.props.patientEntity.patientFile)
    ) {
      this.setState({
        patientFileSelectValue:
          this.props.patientEntity && this.props.patientEntity.patientFile
            ? this.props.patientEntity.patientFile.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientResponsibles.length > 0 &&
        this.state.patientResponsibleSelectValue === null &&
        this.props.patientEntity.patientResponsible)
    ) {
      this.setState({ patientResponsibleSelectValue: this.props.patientEntity.patientResponsible });
      this.props.patientResponsibleSetEntities(this.props.patientEntity.patientResponsible);
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.pads.length > 0 && this.state.padSelectValue === null && this.props.patientEntity.pad)
    ) {
      this.setState({
        padSelectValue:
          this.props.patientEntity && this.props.patientEntity.pad
            ? this.props.patientEntity.pad.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-2 one-to-many other-side
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.pushes.length > 0 && this.state.pushSelectValue === null && this.props.patientEntity.push)
    ) {
      this.setState({
        pushSelectValue:
          this.props.patientEntity && this.props.patientEntity.push
            ? this.props.patientEntity.push.filter(p => p).map(p => ({ value: p.id, label: p['id'] ? p.id : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-1 many-to-many owner-side
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientDevices.length > 0 && this.state.patientDeviceSelectValue === null && this.props.patientEntity.patientDevices)
    ) {
      this.setState({
        patientDeviceSelectValue:
          this.props.patientEntity && this.props.patientEntity.patientDevices
            ? this.props.patientEntity.patientDevices.filter(p => p).map(p => ({ value: p.id, label: p['name'] ? p.name : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-1 many-to-many owner-side
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.professionals.length > 0 &&
        this.state.professionalBlockedSelectValue === null &&
        this.props.patientEntity.professionalBlockeds)
    ) {
      this.setState({
        professionalBlockedSelectValue:
          this.props.patientEntity && this.props.patientEntity.professionalBlockeds
            ? this.props.patientEntity.professionalBlockeds.filter(p => p).map(p => ({ value: p.id, label: p['name'] ? p.name : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-1 many-to-many owner-side
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.professionals.length > 0 &&
        this.state.professionalPreferredSelectValue === null &&
        this.props.patientEntity.professionalPreferreds)
    ) {
      this.setState({
        professionalPreferredSelectValue:
          this.props.patientEntity && this.props.patientEntity.professionalPreferreds
            ? this.props.patientEntity.professionalPreferreds.filter(p => p).map(p => ({ value: p.id, label: p['name'] ? p.name : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-1 many-to-many owner-side
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.suppliers.length > 0 && this.state.supplierSelectValue === null && this.props.patientEntity.suppliers)
    ) {
      this.setState({
        supplierSelectValue:
          this.props.patientEntity && this.props.patientEntity.suppliers
            ? this.props.patientEntity.suppliers.filter(p => p).map(p => ({ value: p.id, label: p['fantasyName'] ? p.fantasyName : '' }))
            : [],
      });
    }

    // data-type-rel -> tipo-4 many-to-one other-side
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.clients && this.state.clientSelectValue === null && this.props.patientEntity.client && this.props.patientEntity.client.id)
    ) {
      const p = this.props.patientEntity && this.props.patientEntity.client ? this.props.patientEntity.client : null;
      this.setState({
        clientSelectValue: p ? { ...p, value: p.id, label: p['fantasyName'] ? p.fantasyName : '' } : null,
      });
    }

    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientEntity.name && this.state.nameSelectValue === null && this.props.patientEntity.name !== this.state.nameSelectValue)
    ) {
      this.setState({ nameSelectValue: this.props.patientEntity.name ? this.props.patientEntity.name : null });
    }
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientEntity.cpf && this.state.cpfSelectValue === null && this.props.patientEntity.cpf !== this.state.cpfSelectValue)
    ) {
      this.setState({ cpfSelectValue: this.props.patientEntity.cpf ? this.props.patientEntity.cpf : null });
    }
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientEntity.rg && this.state.rgSelectValue === null && this.props.patientEntity.rg !== this.state.rgSelectValue)
    ) {
      this.setState({ rgSelectValue: this.props.patientEntity.rg ? this.props.patientEntity.rg : null });
    }
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientEntity.sex && this.state.sexSelectValue === null && this.props.patientEntity.sex !== this.state.sexSelectValue)
    ) {
      this.setState({ sexSelectValue: this.props.patientEntity.sex ? this.props.patientEntity.sex : null });
    }
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientEntity.birthDate &&
        this.state.birthDateSelectValue === null &&
        this.props.patientEntity.birthDate !== this.state.birthDateSelectValue)
    ) {
      this.setState({ birthDateSelectValue: this.props.patientEntity.birthDate ? this.props.patientEntity.birthDate : null });
    }
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientEntity.weight &&
        this.state.weightSelectValue === null &&
        this.props.patientEntity.weight !== this.state.weightSelectValue)
    ) {
      this.setState({ weightSelectValue: this.props.patientEntity.weight ? this.props.patientEntity.weight : null });
    }
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientEntity.height &&
        this.state.heightSelectValue === null &&
        this.props.patientEntity.height !== this.state.heightSelectValue)
    ) {
      this.setState({ heightSelectValue: this.props.patientEntity.height ? this.props.patientEntity.height : null });
    }
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientEntity.healthPlanEnrollment &&
        this.state.healthPlanEnrollmentSelectValue === null &&
        this.props.patientEntity.healthPlanEnrollment !== this.state.healthPlanEnrollmentSelectValue)
    ) {
      this.setState({
        healthPlanEnrollmentSelectValue: this.props.patientEntity.healthPlanEnrollment
          ? this.props.patientEntity.healthPlanEnrollment
          : null,
      });
    }
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientEntity.liminar &&
        this.state.liminarSelectValue === null &&
        this.props.patientEntity.liminar !== this.state.liminarSelectValue)
    ) {
      this.setState({ liminarSelectValue: this.props.patientEntity.liminar ? this.props.patientEntity.liminar : null });
    }
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientEntity.observations &&
        this.state.observationsSelectValue === null &&
        this.props.patientEntity.observations !== this.state.observationsSelectValue)
    ) {
      this.setState({ observationsSelectValue: this.props.patientEntity.observations ? this.props.patientEntity.observations : null });
    }
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientEntity.informationProfessional &&
        this.state.informationProfessionalSelectValue === null &&
        this.props.patientEntity.informationProfessional !== this.state.informationProfessionalSelectValue)
    ) {
      this.setState({
        informationProfessionalSelectValue: this.props.patientEntity.informationProfessional
          ? this.props.patientEntity.informationProfessional
          : null,
      });
    }
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.state.registerSelectValue === null && this.props.patientEntity.register !== this.state.registerSelectValue)
    ) {
      this.setState({ registerSelectValue: this.props.patientEntity.register ? this.props.patientEntity.register : false });
    }
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientEntity.zipCode && this.state.cepRequestCep === null)
    ) {
      this.setState({ cepRequestCep: this.props.patientEntity.zipCode });
      // this.setState({ zipCodeSelectValue: this.props.patientEntity.zipCode })
    }
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientEntity.zipCode &&
        this.state.zipCodeSelectValue === null &&
        this.props.patientEntity.zipCode !== this.state.zipCodeSelectValue)
    ) {
      this.setState({ zipCodeSelectValue: this.props.patientEntity.zipCode ? this.props.patientEntity.zipCode : null });
    }
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientEntity.hospitalReference &&
        this.state.hospitalReferenceSelectValue === null &&
        this.props.patientEntity.hospitalReference !== this.state.hospitalReferenceSelectValue)
    ) {
      this.setState({
        hospitalReferenceSelectValue: this.props.patientEntity.hospitalReference ? this.props.patientEntity.hospitalReference : null,
      });
    }
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientEntity.street && this.state.cepRequestLogradouro === null)
    ) {
      this.setState({ cepRequestLogradouro: this.props.patientEntity.street });
      // this.setState({ streetSelectValue: this.props.patientEntity.street })
    }
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientEntity.street &&
        this.state.streetSelectValue === null &&
        this.props.patientEntity.street !== this.state.streetSelectValue)
    ) {
      this.setState({ streetSelectValue: this.props.patientEntity.street ? this.props.patientEntity.street : null });
    }
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientEntity.complement &&
        this.state.complementSelectValue === null &&
        this.props.patientEntity.complement !== this.state.complementSelectValue)
    ) {
      this.setState({ complementSelectValue: this.props.patientEntity.complement ? this.props.patientEntity.complement : null });
    }
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientEntity.number &&
        this.state.numberSelectValue === null &&
        this.props.patientEntity.number !== this.state.numberSelectValue)
    ) {
      this.setState({ numberSelectValue: this.props.patientEntity.number ? this.props.patientEntity.number : null });
    }
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientEntity.neighborhood && this.state.cepRequestBairro === null)
    ) {
      this.setState({ cepRequestBairro: this.props.patientEntity.neighborhood });
      // this.setState({ neighborhoodSelectValue: this.props.patientEntity.neighborhood })
    }
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientEntity.neighborhood &&
        this.state.neighborhoodSelectValue === null &&
        this.props.patientEntity.neighborhood !== this.state.neighborhoodSelectValue)
    ) {
      this.setState({ neighborhoodSelectValue: this.props.patientEntity.neighborhood ? this.props.patientEntity.neighborhood : null });
    }
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientEntity.city && this.state.cepRequestLocalidade === null)
    ) {
      this.setState({ cepRequestLocalidade: this.props.patientEntity.city });
      // this.setState({ citySelectValue: this.props.patientEntity.city })
    }
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientEntity.city && this.state.citySelectValue === null && this.props.patientEntity.city !== this.state.citySelectValue)
    ) {
      this.setState({ citySelectValue: this.props.patientEntity.city ? this.props.patientEntity.city : null });
    }
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientEntity.uf && this.state.cepRequestUf === null)
    ) {
      this.setState({ cepRequestUf: this.props.patientEntity.uf });
      // this.setState({ ufSelectValue: this.props.patientEntity.uf })
    }
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientEntity.uf && this.state.ufSelectValue === null && this.props.patientEntity.uf !== this.state.ufSelectValue)
    ) {
      this.setState({ ufSelectValue: this.props.patientEntity.uf ? this.props.patientEntity.uf : null });
    }
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientEntity.reference &&
        this.state.referenceSelectValue === null &&
        this.props.patientEntity.reference !== this.state.referenceSelectValue)
    ) {
      this.setState({ referenceSelectValue: this.props.patientEntity.reference ? this.props.patientEntity.reference : null });
    }
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientEntity.lat && this.state.latSelectValue === null && this.props.patientEntity.lat !== this.state.latSelectValue)
    ) {
      this.setState({ latSelectValue: this.props.patientEntity.lat ? this.props.patientEntity.lat : null });
    }
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientEntity.lng && this.state.lngSelectValue === null && this.props.patientEntity.lng !== this.state.lngSelectValue)
    ) {
      this.setState({ lngSelectValue: this.props.patientEntity.lng ? this.props.patientEntity.lng : null });
    }
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientEntity.status &&
        this.state.statusSelectValue === null &&
        this.props.patientEntity.status !== this.state.statusSelectValue)
    ) {
      this.setState({ statusSelectValue: this.props.patientEntity.status ? this.props.patientEntity.status : null });
    }
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientEntity.adId && this.state.adIdSelectValue === null && this.props.patientEntity.adId !== this.state.adIdSelectValue)
    ) {
      this.setState({ adIdSelectValue: this.props.patientEntity.adId ? this.props.patientEntity.adId : null });
    }
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientEntity.nead && this.state.neadSelectValue === null && this.props.patientEntity.nead !== this.state.neadSelectValue)
    ) {
      this.setState({ neadSelectValue: this.props.patientEntity.nead ? this.props.patientEntity.nead : null });
    }
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientEntity.patientComplexity &&
        this.state.patientComplexitySelectValue === null &&
        this.props.patientEntity.patientComplexity !== this.state.patientComplexitySelectValue)
    ) {
      this.setState({
        patientComplexitySelectValue: this.props.patientEntity.patientComplexity ? this.props.patientEntity.patientComplexity : null,
      });
    }
    if (
      (prevProps.patientEntity && prevProps.patientEntity.id !== this.props.patientEntity.id) ||
      (this.props.patientEntity.obese &&
        this.state.obeseSelectValue === null &&
        this.props.patientEntity.obese !== this.state.obeseSelectValue)
    ) {
      this.setState({ obeseSelectValue: this.props.patientEntity.obese ? this.props.patientEntity.obese : null });
    }

    const entity = functionAfterFormUpdate(this.props, this.state, this.state.isNew);
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  UNSAFE_componentWillMount() {
    if (this.state.isNew) {
      this.props.reset();
      this.patientSupplierReset();
      this.patientCidReset();
      this.patientAllergyReset();
      this.patientContactReset();
      this.patientResponsibleReset();
    } else {
      this.props.getEntity(this.props.match.params['id']);
    }

    const entity = functionAfterFormMount(this.props, this.state, this.state.isNew);
  }

  onBlobChange = (isAnImage, name, fileInput) => event => {
    const fileName = event.target.files[0].name;
    setFileData(event, (contentType, data) => this.props.setBlob(name, data, contentType, fileName), isAnImage);
    const varAux = {};
    varAux[name + 'SelectValue'] = fileName;
    this.setState(varAux);
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined);
  };
  getFiltersURL = (offset = null) => {
    const fieldsBase = this.state.fieldsBase;
    let url = offset !== null ? '&offset=' + offset : '';
    Object.keys(fieldsBase).map(key => {
      url += key !== 'offset' ? '&' + key + '=' + fieldsBase[key] : '';
    });
    return url;
  };
  saveEntity = (event: any, errors: any, values: any) => {
    if (!this.state.nameSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.patient.name') }));
      return false;
    }

    if (!this.state.cepRequestCep) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.patient.zipCode') }));
      return false;
    }

    if (!this.state.numberSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.patient.number') }));
      return false;
    }

    if (!this.state.cepRequestCep) {
      errors.push(translate('entity.validation.required'));
    }

    if (errors.length === 0) {
      const { patientEntity } = this.props;
      const _entity = {
        ...patientEntity,
        // ...values,

        client: getFormFromSelect(this.state.clientSelectValue, 'many-to-one'), // many-to-one - otherSide

        patientDevices: getFormFromSelect(this.state.patientDeviceSelectValue, 'many-to-many'), // many-to-many - ownerSide

        professionalBlockeds: getFormFromSelect(this.state.professionalBlockedSelectValue, 'many-to-many'), // many-to-many - ownerSide

        professionalPreferreds: getFormFromSelect(this.state.professionalPreferredSelectValue, 'many-to-many'), // many-to-many - ownerSide

        patientSupplier: undefined,

        patientCid: undefined,

        patientAllergy: undefined,

        patientContact: undefined,

        patientResponsible: undefined,

        name: this.state.nameSelectValue,
        cpf: this.state.cpfSelectValue,
        rg: this.state.rgSelectValue,
        sex: this.state.sexSelectValue === null ? 'MASCULINO' : this.state.sexSelectValue,
        birthDate: this.state.birthDateSelectValue,
        weight: this.state.weightSelectValue,
        height: this.state.heightSelectValue,
        healthPlanEnrollment: this.state.healthPlanEnrollmentSelectValue,
        liminar: this.state.liminarSelectValue === null ? null : this.state.liminarSelectValue,
        observations: this.state.observationsSelectValue,
        informationProfessional: this.state.informationProfessionalSelectValue,
        register: this.state.registerSelectValue === null ? false : this.state.registerSelectValue,
        zipCode: this.state.cepRequestCep,
        hospitalReference: this.state.hospitalReferenceSelectValue,
        street: this.state.cepRequestLogradouro,
        complement: this.state.complementSelectValue,
        number: this.state.numberSelectValue,
        neighborhood: this.state.cepRequestBairro,
        city: this.state.cepRequestLocalidade,
        uf: this.state.cepRequestUf,
        reference: this.state.referenceSelectValue,
        lat: this.state.latSelectValue,
        lng: this.state.lngSelectValue,
        status: this.state.statusSelectValue === null ? 'ATIVO' : this.state.statusSelectValue,
        adId: this.state.adIdSelectValue === null ? null : this.state.adIdSelectValue,
        nead: this.state.neadSelectValue,
        patientComplexity: this.state.patientComplexitySelectValue === null ? null : this.state.patientComplexitySelectValue,
        obese: this.state.obeseSelectValue === null ? null : this.state.obeseSelectValue,
      };

      const entity = functionBeforeSubmit(_entity, this.props, this.state);

      const {
        name,
        zipCode,
        neighborhood,
        city,
        uf,
        cpf,
        clientId,
        register,
        status,
        extraFilters,
        activePage,
        itemsPerPage,
        sort,
        order,
      } = this.state.fieldsBase;

      const patientSupplierListAllEmbed = this.props.patientSupplierListAllEmbed;
      const patientCidListAllEmbed = this.props.patientCidListAllEmbed;
      const patientAllergyListAllEmbed = this.props.patientAllergyListAllEmbed;
      const patientContactListAllEmbed = this.props.patientContactListAllEmbed;
      const patientResponsibleListAllEmbed = this.props.patientResponsibleListAllEmbed;

      if (this.state.isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              name,
              zipCode,
              neighborhood,
              city,
              uf,
              cpf,
              getFilterFromSelect(clientId, 'many-to-one'),
              register,
              status,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
          if (idEntity) {
            patientSupplierListAllEmbed.map(v => {
              v['id'] = null;
              v['patient'] = { id: idEntity };
              this.props['createEntityPatientSupplier'](v);
            });
          }
          if (idEntity) {
            patientCidListAllEmbed.map(v => {
              v['id'] = null;
              v['patient'] = { id: idEntity };
              this.props['createEntityPatientCid'](v);
            });
          }
          if (idEntity) {
            patientAllergyListAllEmbed.map(v => {
              v['id'] = null;
              v['patient'] = { id: idEntity };
              this.props['createEntityPatientAllergy'](v);
            });
          }
          if (idEntity) {
            patientContactListAllEmbed.map(v => {
              v['id'] = null;
              v['patient'] = { id: idEntity };
              this.props['createEntityPatientContact'](v);
            });
          }
          if (idEntity) {
            patientResponsibleListAllEmbed.map(v => {
              v['id'] = null;
              v['patient'] = { id: idEntity };
              this.props['createEntityPatientResponsible'](v);
            });
          }

          functionAfterSubmit(entity, resultEntity, 'createEntity');
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              name,
              zipCode,
              neighborhood,
              city,
              uf,
              cpf,
              getFilterFromSelect(clientId, 'many-to-one'),
              register,
              status,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
          functionAfterSubmit(entity, resultEntity, 'createEntity');
        });
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/patient?' + getEntityFiltersURL(this.state.fieldsBase));
  };

  getFullEntityFromState(v) {
    return {
      id: v.id,
      name: this.state.nameSelectValue,
      cpf: this.state.cpfSelectValue,
      rg: this.state.rgSelectValue,
      sex: this.state.sexSelectValue,
      birthDate: this.state.birthDateSelectValue,
      weight: this.state.weightSelectValue,
      height: this.state.heightSelectValue,
      healthPlanEnrollment: this.state.healthPlanEnrollmentSelectValue,
      liminar: this.state.liminarSelectValue,
      observations: this.state.observationsSelectValue,
      informationProfessional: this.state.informationProfessionalSelectValue,
      register: this.state.registerSelectValue,
      zipCode: this.state.zipCodeSelectValue,
      hospitalReference: this.state.hospitalReferenceSelectValue,
      street: this.state.streetSelectValue,
      complement: this.state.complementSelectValue,
      number: this.state.numberSelectValue,
      neighborhood: this.state.neighborhoodSelectValue,
      city: this.state.citySelectValue,
      uf: this.state.ufSelectValue,
      reference: this.state.referenceSelectValue,
      lat: this.state.latSelectValue,
      lng: this.state.lngSelectValue,
      status: this.state.statusSelectValue,
      adId: this.state.adIdSelectValue,
      nead: this.state.neadSelectValue,
      patientComplexity: this.state.patientComplexitySelectValue,
      obese: this.state.obeseSelectValue,
      patientDoctorPrescription: this.state.patientDoctorPrescriptionSelectValue,
      patientNursingPrescription: this.state.patientNursingPrescriptionSelectValue,
      patientStatusHistory: this.state.patientStatusHistorySelectValue,
      patientContact: this.state.patientContactSelectValue,
      patientClinicalIndicator: this.state.patientClinicalIndicatorSelectValue,
      patientCid: this.state.patientCidSelectValue,
      patientAllergy: this.state.patientAllergySelectValue,
      patientSupplier: this.state.patientSupplierSelectValue,
      quote: this.state.quoteSelectValue,
      medicalRecord: this.state.medicalRecordSelectValue,
      nursingDiary: this.state.nursingDiarySelectValue,
      nursingPrescription: this.state.nursingPrescriptionSelectValue,
      patientFile: this.state.patientFileSelectValue,
      patientResponsible: this.state.patientResponsibleSelectValue,
      pad: this.state.padSelectValue,
      push: this.state.pushSelectValue,
      patientDevice: this.state.patientDeviceSelectValue,
      professionalBlocked: this.state.professionalBlockedSelectValue,
      professionalPreferred: this.state.professionalPreferredSelectValue,
      supplier: this.state.supplierSelectValue,
      client: this.state.clientSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    const patientDoctorPrescriptionEntity = v.patientDoctorPrescription;

    const patientNursingPrescriptionEntity = v.patientNursingPrescription;

    const patientStatusHistoryEntity = v.patientStatusHistory;

    const patientContactEntity = v.patientContact;

    const patientClinicalIndicatorEntity = v.patientClinicalIndicator;

    const patientCidEntity = v.patientCid;

    const patientAllergyEntity = v.patientAllergy;

    const patientSupplierEntity = v.patientSupplier;

    const quoteEntity = v.quote;

    const medicalRecordEntity = v.medicalRecord;

    const nursingDiaryEntity = v.nursingDiary;

    const nursingPrescriptionEntity = v.nursingPrescription;

    const patientFileEntity = v.patientFile;

    const patientResponsibleEntity = v.patientResponsible;

    const padEntity = v.pad;

    const pushEntity = v.push;

    const patientDeviceEntity = this.props.patientDevices.filter(x => x.patients.map(x1 => x1.id).includes(v.id)).pop();

    const professionalBlockedEntity = this.props.professionals.filter(x => x.patientBlockeds.map(x1 => x1.id).includes(v.id)).pop();

    const professionalPreferredEntity = this.props.professionals.filter(x => x.patientPreferreds.map(x1 => x1.id).includes(v.id)).pop();

    const supplierEntity = this.props.suppliers.filter(x => x.patients.map(x1 => x1.id).includes(v.id)).pop();

    const clientEntity = v.client;

    return {
      nameSelectValue: v.name,
      cpfSelectValue: v.cpf,
      rgSelectValue: v.rg,
      sexSelectValue: v.sex,
      birthDateSelectValue: v.birthDate,
      weightSelectValue: v.weight,
      heightSelectValue: v.height,
      healthPlanEnrollmentSelectValue: v.healthPlanEnrollment,
      liminarSelectValue: v.liminar,
      observationsSelectValue: v.observations,
      informationProfessionalSelectValue: v.informationProfessional,
      registerSelectValue: v.register,
      zipCodeSelectValue: v.zipCode,
      hospitalReferenceSelectValue: v.hospitalReference,
      streetSelectValue: v.street,
      complementSelectValue: v.complement,
      numberSelectValue: v.number,
      neighborhoodSelectValue: v.neighborhood,
      citySelectValue: v.city,
      ufSelectValue: v.uf,
      referenceSelectValue: v.reference,
      latSelectValue: v.lat,
      lngSelectValue: v.lng,
      statusSelectValue: v.status,
      adIdSelectValue: v.adId,
      neadSelectValue: v.nead,
      patientComplexitySelectValue: v.patientComplexity,
      obeseSelectValue: v.obese,
      patientDoctorPrescriptionSelectValue: patientDoctorPrescriptionEntity ? patientDoctorPrescriptionEntity['id'] : null,
      patientNursingPrescriptionSelectValue: patientNursingPrescriptionEntity ? patientNursingPrescriptionEntity['id'] : null,
      patientStatusHistorySelectValue: patientStatusHistoryEntity ? patientStatusHistoryEntity['id'] : null,
      patientContactSelectValue: patientContactEntity ? patientContactEntity['id'] : null,
      patientClinicalIndicatorSelectValue: patientClinicalIndicatorEntity ? patientClinicalIndicatorEntity['id'] : null,
      patientCidSelectValue: patientCidEntity ? patientCidEntity['id'] : null,
      patientAllergySelectValue: patientAllergyEntity ? patientAllergyEntity['id'] : null,
      patientSupplierSelectValue: patientSupplierEntity ? patientSupplierEntity['id'] : null,
      quoteSelectValue: quoteEntity ? quoteEntity['id'] : null,
      medicalRecordSelectValue: medicalRecordEntity ? medicalRecordEntity['id'] : null,
      nursingDiarySelectValue: nursingDiaryEntity ? nursingDiaryEntity['id'] : null,
      nursingPrescriptionSelectValue: nursingPrescriptionEntity ? nursingPrescriptionEntity['id'] : null,
      patientFileSelectValue: patientFileEntity ? patientFileEntity['id'] : null,
      patientResponsibleSelectValue: patientResponsibleEntity ? patientResponsibleEntity['id'] : null,
      padSelectValue: padEntity ? padEntity['id'] : null,
      pushSelectValue: pushEntity ? pushEntity['id'] : null,
      patientDeviceSelectValue: patientDeviceEntity ? patientDeviceEntity['id'] : null,
      professionalBlockedSelectValue: professionalBlockedEntity ? professionalBlockedEntity['id'] : null,
      professionalPreferredSelectValue: professionalPreferredEntity ? professionalPreferredEntity['id'] : null,
      supplierSelectValue: supplierEntity ? supplierEntity['id'] : null,
      clientSelectValue: clientEntity ? clientEntity['id'] : null,
    };
  }

  renderHeader() {
    const {
      patientEntity,
      patientDoctorPrescriptions,
      patientNursingPrescriptions,
      patientStatusHistories,
      patientContacts,
      patientClinicalIndicators,
      patientCids,
      patientAllergies,
      patientSuppliers,
      quotes,
      medicalRecords,
      nursingDiaries,
      nursingPrescriptions,
      patientFiles,
      patientResponsibles,
      pads,
      pushes,
      patientDevices,
      professionals,
      suppliers,
      clients,
      loading,
      updating,
    } = this.props;
    const { isNew } = this.state;

    const { observations } = patientEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {this.state.isNew ? (
              <Translate contentKey="generadorApp.patient.home.createLabel">Create a Patient</Translate>
            ) : (
              <Translate contentKey="generadorApp.patient.home.editLabel">Edit a Patient</Translate>
            )}
          </span>

          <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right jh-create-entity">
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="generadorApp.patient.btnSave">Save</Translate>
          </Button>

          {!this.state.isNew ? (
            <>
              <Button
                color="primary"
                id="next-entity"
                disabled={updating}
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.name,
                    this.state.fieldsBase.zipCode,
                    this.state.fieldsBase.neighborhood,
                    this.state.fieldsBase.city,
                    this.state.fieldsBase.uf,
                    this.state.fieldsBase.cpf,
                    this.state.fieldsBase.clientId,
                    this.state.fieldsBase.register,
                    this.state.fieldsBase.status,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 + 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 + 1 },
                    });
                    this.props.history.push(
                      '/patient/' + myresult.value.data[0]['id'] + '/edit?' + this.getFiltersURL(this.state.fieldsBase.offset * 1 + 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.last.item', { fieldName: translate('generadorApp.patient.detail.title') }));
                  }
                }}
                className="float-right jh-create-entity"
              >
                {'>>'}
              </Button>

              <Button
                color="primary"
                id="prev-entity"
                onClick={async () => {
                  const myresult = await this.props.getEntityOffset(
                    this.state.fieldsBase.name,
                    this.state.fieldsBase.zipCode,
                    this.state.fieldsBase.neighborhood,
                    this.state.fieldsBase.city,
                    this.state.fieldsBase.uf,
                    this.state.fieldsBase.cpf,
                    this.state.fieldsBase.clientId,
                    this.state.fieldsBase.register,
                    this.state.fieldsBase.status,
                    this.state.fieldsBase.extraFilters,
                    this.state.fieldsBase.offset * 1 - 1,
                    this.state.fieldsBase.sort + ',' + this.state.fieldsBase.order
                  );
                  if (myresult.value.data && myresult.value.data.length > 0) {
                    this.setState({
                      fieldsBase: { ...this.state.fieldsBase, offset: this.state.fieldsBase.offset * 1 - 1 },
                    });
                    this.props.history.push(
                      '/patient/' + myresult.value.data[0]['id'] + '/edit?' + this.getFiltersURL(this.state.fieldsBase.offset * 1 - 1)
                    );
                  } else {
                    toast.error(translate('generadorApp.first.item', { fieldName: translate('generadorApp.patient.detail.title') }));
                  }
                }}
                disabled={updating}
                className="float-right jh-create-entity"
              >
                {'<<'}
              </Button>
            </>
          ) : (
            <> </>
          )}

          <Button
            tag={Link}
            id="cancel-save"
            to={'/patient?' + getEntityFiltersURL(this.state.fieldsBase)}
            replace
            color="info"
            className="float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="generadorApp.patient.btnBack">Back</Translate>
            </span>
          </Button>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={'/patient'}>
              <Translate contentKey="generadorApp.patient.home.title">Patients</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.patient.detail.title">Patient edit</Translate>
          </li>
        </ol>

        {!isNew ? (
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === 0 })}
                onClick={() => {
                  this.tabsNext(0);
                }}
              >
                <span className="d-sm-none">
                  <Translate contentKey="generadorApp.patient.PersonalInformation">PersonalInformation</Translate>
                </span>
                <span className="d-sm-block d-none">
                  <Translate contentKey="generadorApp.patient.PersonalInformation">PersonalInformation</Translate>
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === 1 })}
                onClick={() => {
                  this.tabsNext(1);
                }}
              >
                <span className="d-sm-none">
                  <Translate contentKey="generadorApp.patient.InformationResidential">InformationResidential</Translate>
                </span>
                <span className="d-sm-block d-none">
                  <Translate contentKey="generadorApp.patient.InformationResidential">InformationResidential</Translate>
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === 2 })}
                onClick={() => {
                  this.tabsNext(2);
                }}
              >
                <span className="d-sm-none">
                  <Translate contentKey="generadorApp.patient.PatientContacts">PatientContacts</Translate>
                </span>
                <span className="d-sm-block d-none">
                  <Translate contentKey="generadorApp.patient.PatientContacts">PatientContacts</Translate>
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === 3 })}
                onClick={() => {
                  this.tabsNext(3);
                }}
              >
                <span className="d-sm-none">
                  <Translate contentKey="generadorApp.patient.PatientResponsible">PatientResponsible</Translate>
                </span>
                <span className="d-sm-block d-none">
                  <Translate contentKey="generadorApp.patient.PatientResponsible">PatientResponsible</Translate>
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === 4 })}
                onClick={() => {
                  this.tabsNext(4);
                }}
              >
                <span className="d-sm-none">
                  <Translate contentKey="generadorApp.patient.PatientSupplier">PatientSupplier</Translate>
                </span>
                <span className="d-sm-block d-none">
                  <Translate contentKey="generadorApp.patient.PatientSupplier">PatientSupplier</Translate>
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === 5 })}
                onClick={() => {
                  this.tabsNext(5);
                }}
              >
                <span className="d-sm-none">
                  <Translate contentKey="generadorApp.patient.PatientInfomation">PatientInfomation</Translate>
                </span>
                <span className="d-sm-block d-none">
                  <Translate contentKey="generadorApp.patient.PatientInfomation">PatientInfomation</Translate>
                </span>
              </NavLink>
            </NavItem>
          </Nav>
        ) : null}
      </>
    );
  }
  renderBody() {
    const {
      patientEntity,
      patientDoctorPrescriptions,
      patientNursingPrescriptions,
      patientStatusHistories,
      patientContacts,
      patientClinicalIndicators,
      patientCids,
      patientAllergies,
      patientSuppliers,
      quotes,
      medicalRecords,
      nursingDiaries,
      nursingPrescriptions,
      patientFiles,
      patientResponsibles,
      pads,
      pushes,
      patientDevices,
      professionals,
      suppliers,
      clients,
      loading,
      updating,
    } = this.props;
    const { isNew } = this.state;

    const { observations } = patientEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    const fullEntityEmbebed = this.getFullEntityFromState(patientEntity);

    const mapDispatchToPropsPatientSupplierList: any = {};
    Object.keys(mapDispatchToPropsPatientSupplier).map(v => {
      mapDispatchToPropsPatientSupplierList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'PatientSupplier')) {
          this.setState({ functionEmbebed: [...this.state.functionEmbebed, v + 'PatientSupplier'] }, this.props[v + 'PatientSupplier']);
        }
      };
    });
    const mapDispatchToPropsPatientCidList: any = {};
    Object.keys(mapDispatchToPropsPatientCid).map(v => {
      mapDispatchToPropsPatientCidList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'PatientCid')) {
          this.setState({ functionEmbebed: [...this.state.functionEmbebed, v + 'PatientCid'] }, this.props[v + 'PatientCid']);
        }
      };
    });
    const mapDispatchToPropsPatientAllergyList: any = {};
    Object.keys(mapDispatchToPropsPatientAllergy).map(v => {
      mapDispatchToPropsPatientAllergyList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'PatientAllergy')) {
          this.setState({ functionEmbebed: [...this.state.functionEmbebed, v + 'PatientAllergy'] }, this.props[v + 'PatientAllergy']);
        }
      };
    });
    const mapDispatchToPropsPatientContactList: any = {};
    Object.keys(mapDispatchToPropsPatientContact).map(v => {
      mapDispatchToPropsPatientContactList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'PatientContact')) {
          this.setState({ functionEmbebed: [...this.state.functionEmbebed, v + 'PatientContact'] }, this.props[v + 'PatientContact']);
        }
      };
    });
    const mapDispatchToPropsPatientResponsibleList: any = {};
    Object.keys(mapDispatchToPropsPatientResponsible).map(v => {
      mapDispatchToPropsPatientResponsibleList[v] = () => {
        if (!this.state.functionEmbebed.includes(v + 'PatientResponsible')) {
          this.setState(
            { functionEmbebed: [...this.state.functionEmbebed, v + 'PatientResponsible'] },
            this.props[v + 'PatientResponsible']
          );
        }
      };
    });

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
                <Col md="3">
                <Label className="mt-2 label-single-line"for="patient-id">
                  <Translate contentKey="generadorApp.patient.field_id">ID</Translate>
                </Label>
                </Col> */}
                      <Col md="12">
                        <AvInput id="patient-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId={0}>
                    {isNew ? (
                      <Row className="justify-content-center mb-3">
                        <Col md="12">
                          <h2 id="generadorApp.patient.home.formTabs_34">
                            <Translate contentKey="generadorApp.patient.formTabs.PersonalInformation">PersonalInformation</Translate>
                          </h2>
                        </Col>
                      </Row>
                    ) : null}

                    <Row>
                      {baseFilters !== 'name' ? (
                        <Col md="5">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="nameLabel" for="patient-name">
                                  <Translate contentKey="generadorApp.patient.name">Name</Translate>(*)
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                                  value={this.state.nameSelectValue ? this.state.nameSelectValue : ''}
                                  id="patient-name"
                                  type="text"
                                  name="name"
                                  className={'form-control'}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                          type="hidden"
                          name="name"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'cpf' ? (
                        <Col md="4">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="cpfLabel" for="patient-cpf">
                                  <Translate contentKey="generadorApp.patient.cpf">Cpf</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <InputMask
                                  onChange={evt => this.setState({ cpfSelectValue: evt.target.value })}
                                  value={this.state.cpfSelectValue ? this.state.cpfSelectValue : ''}
                                  id="patient-cpf"
                                  type="text"
                                  name="cpf"
                                  className={'form-control'}
                                  mask="999.999.999-99"
                                  tag={InputMask}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ cpfSelectValue: evt.target.value })}
                          type="hidden"
                          name="cpf"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'rg' ? (
                        <Col md="3">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="rgLabel" for="patient-rg">
                                  <Translate contentKey="generadorApp.patient.rg">Rg</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  onChange={evt => this.setState({ rgSelectValue: evt.target.value })}
                                  value={this.state.rgSelectValue ? this.state.rgSelectValue : ''}
                                  id="patient-rg"
                                  type="text"
                                  name="rg"
                                  className={'form-control'}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ rgSelectValue: evt.target.value })}
                          type="hidden"
                          name="rg"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'sex' ? (
                        <Col md="4">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="sexLabel" for="patient-sex">
                                  <Translate contentKey="generadorApp.patient.sex">Sex</Translate>(*)
                                </Label>
                              </Col>
                              <Col md="12">
                                <Select
                                  id="patient-sex"
                                  className={'css-select-control'}
                                  value={
                                    this.state.sexSelectValue
                                      ? {
                                          value: this.state.sexSelectValue,
                                          label: translate('generadorApp.Sex.' + this.state.sexSelectValue),
                                        }
                                      : { value: '', label: translate('generadorApp.patient.sex') }
                                  }
                                  options={[
                                    { value: 'MASCULINO', label: translate('generadorApp.Sex.MASCULINO') },
                                    { value: 'FEMININO', label: translate('generadorApp.Sex.FEMININO') },
                                  ]}
                                  onChange={(options: any) => this.setState({ sexSelectValue: options['value'] })}
                                  name="sex"
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ sexSelectValue: evt.target.value })}
                          type="hidden"
                          name="sex"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'birthDate' ? (
                        <Col md="4">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="birthDateLabel" for="patient-birthDate">
                                  <Translate contentKey="generadorApp.patient.birthDate">Birth Date</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <DatePicker
                                  autoComplete="off"
                                  onChange={value => this.setState({ birthDateSelectValue: value })}
                                  id="birthDate"
                                  validate={{}}
                                  className="form-control react-datepicker"
                                  name="birthDate"
                                  dateFormat="dd/MM/yyyy"
                                  selected={this.state.birthDateSelectValue ? moment(this.state.birthDateSelectValue).toDate() : null}
                                  locale="pt"
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ birthDateSelectValue: evt.target.value })}
                          type="hidden"
                          name="birthDate"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}
                      {baseFilters !== 'AgeComponent' ? (
                        <Col md="4">
                          <AgeComponent
                            entityBase={patientEntity}
                            baseState={this.state}
                            baseProps={this.props}
                            setState={_state => this.setState(_state)}
                          />
                        </Col>
                      ) : (
                        <> </>
                      )}

                      {baseFilters !== 'weight' ? (
                        <Col md="4">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="weightLabel" for="patient-weight">
                                  <Translate contentKey="generadorApp.patient.weight">Weight</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <CurrencyInput
                                  onChange={(maskedvalue, floatvalue, event) => this.setState({ weightSelectValue: floatvalue })}
                                  decimalSeparator=","
                                  thousandSeparator=""
                                  precision={2}
                                  prefix=""
                                  allowNegative={true}
                                  value={this.state.weightSelectValue ? this.state.weightSelectValue : 0}
                                  id="patient-weight"
                                  name="weight"
                                  className={'form-control'}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ weightSelectValue: evt.target.value })}
                          type="hidden"
                          name="weight"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'height' ? (
                        <Col md="4">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="heightLabel" for="patient-height">
                                  <Translate contentKey="generadorApp.patient.height">Height</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <CurrencyInput
                                  onChange={(maskedvalue, floatvalue, event) => this.setState({ heightSelectValue: floatvalue })}
                                  decimalSeparator=","
                                  thousandSeparator=""
                                  precision={2}
                                  prefix=""
                                  allowNegative={true}
                                  value={this.state.heightSelectValue ? this.state.heightSelectValue : 0}
                                  id="patient-height"
                                  name="height"
                                  className={'form-control'}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ heightSelectValue: evt.target.value })}
                          type="hidden"
                          name="height"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}
                      {baseFilters !== 'ImcComponent' ? (
                        <Col md="4">
                          <ImcComponent
                            entityBase={patientEntity}
                            baseState={this.state}
                            baseProps={this.props}
                            setState={_state => this.setState(_state)}
                          />
                        </Col>
                      ) : (
                        <> </>
                      )}
                    </Row>

                    {isNew ? (
                      <Button
                        color="primary"
                        className={'float-right jh-create-entity'}
                        onClick={() => {
                          this.tabsNext(1, 0);
                        }}
                      >
                        <span className="d-sm-none">
                          <Translate contentKey="generadorApp.patient.formTabs.btnInformationResidential">InformationResidential</Translate>
                        </span>
                        <span className="d-sm-block d-none">
                          <Translate contentKey="generadorApp.patient.formTabs.btnInformationResidential">InformationResidential</Translate>
                          &nbsp;
                          <FontAwesomeIcon icon="arrow-right" />
                        </span>
                      </Button>
                    ) : null}
                  </TabPane>
                  <TabPane tabId={1}>
                    {isNew ? (
                      <Row className="justify-content-center mb-3">
                        <Col md="12">
                          <h2 id="generadorApp.patient.home.formTabs_34">
                            <Translate contentKey="generadorApp.patient.formTabs.InformationResidential">InformationResidential</Translate>
                          </h2>
                        </Col>
                      </Row>
                    ) : null}

                    <Row>
                      {baseFilters !== 'zipCode' ? (
                        <Col md="4">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label
                                  className={'mt-2' + (this.state.cepRequestCep === '' ? ' text-danger' : '')}
                                  id="zipCodeLabel"
                                  for="patient-zipCode"
                                >
                                  <Translate contentKey="generadorApp.patient.zipCode">Zip Code</Translate>(*)
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  id="patient-zipCode"
                                  type="text"
                                  name="zipCode"
                                  className={
                                    'form-control' +
                                    (this.state.cepRequestCep === '' ? ' is-touched is-pristine av-invalid is-invalid' : '')
                                  }
                                  value={this.state.cepRequestCep}
                                  onBlur={inputVal => {
                                    viacepRequest(inputVal.target.value, (cepRequest: any) => {
                                      this.setState(cepRequest);
                                    });
                                    this.setState({ zipCodeSelectValue: inputVal.target.value });
                                    this.setState({ cepRequestCep: inputVal.target.value ? inputVal.target.value : '' });
                                  }}
                                  onChange={inputVal => {
                                    this.setState({ cepRequestCep: inputVal.target.value });
                                    this.setState({ zipCodeSelectValue: inputVal.target.value });
                                  }}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ zipCodeSelectValue: evt.target.value })}
                          type="hidden"
                          name="zipCode"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'street' ? (
                        <Col md="8">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className={'mt-2'} id="streetLabel" for="patient-street">
                                  <Translate contentKey="generadorApp.patient.street">Street</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  id="patient-street"
                                  type="text"
                                  name="street"
                                  className={'form-control'}
                                  value={this.state.cepRequestLogradouro}
                                  onBlur={inputVal =>
                                    this.setState({ cepRequestLogradouro: inputVal.target.value ? inputVal.target.value : '' })
                                  }
                                  onChange={inputVal => {
                                    this.setState({ cepRequestLogradouro: inputVal.target.value });
                                    this.setState({ streetSelectValue: inputVal.target.value });
                                  }}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ streetSelectValue: evt.target.value })}
                          type="hidden"
                          name="street"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'number' ? (
                        <Col md="4">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="numberLabel" for="patient-number">
                                  <Translate contentKey="generadorApp.patient.number">Number</Translate>(*)
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  onChange={evt => this.setState({ numberSelectValue: evt.target.value })}
                                  value={this.state.numberSelectValue ? this.state.numberSelectValue : ''}
                                  id="patient-number"
                                  type="text"
                                  name="number"
                                  className={'form-control'}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ numberSelectValue: evt.target.value })}
                          type="hidden"
                          name="number"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'neighborhood' ? (
                        <Col md="8">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className={'mt-2'} id="neighborhoodLabel" for="patient-neighborhood">
                                  <Translate contentKey="generadorApp.patient.neighborhood">Neighborhood</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  id="patient-neighborhood"
                                  type="text"
                                  name="neighborhood"
                                  className={'form-control'}
                                  value={this.state.cepRequestBairro}
                                  onBlur={inputVal =>
                                    this.setState({ cepRequestBairro: inputVal.target.value ? inputVal.target.value : '' })
                                  }
                                  onChange={inputVal => {
                                    this.setState({ cepRequestBairro: inputVal.target.value });
                                    this.setState({ neighborhoodSelectValue: inputVal.target.value });
                                  }}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ neighborhoodSelectValue: evt.target.value })}
                          type="hidden"
                          name="neighborhood"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'city' ? (
                        <Col md="3">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className={'mt-2'} id="cityLabel" for="patient-city">
                                  <Translate contentKey="generadorApp.patient.city">City</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  id="patient-city"
                                  type="text"
                                  name="city"
                                  className={'form-control'}
                                  value={this.state.cepRequestLocalidade}
                                  onBlur={inputVal =>
                                    this.setState({ cepRequestLocalidade: inputVal.target.value ? inputVal.target.value : '' })
                                  }
                                  onChange={inputVal => {
                                    this.setState({ cepRequestLocalidade: inputVal.target.value });
                                    this.setState({ citySelectValue: inputVal.target.value });
                                  }}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ citySelectValue: evt.target.value })}
                          type="hidden"
                          name="city"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'uf' ? (
                        <Col md="3">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className={'mt-2'} id="ufLabel" for="patient-uf">
                                  <Translate contentKey="generadorApp.patient.uf">Uf</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  id="patient-uf"
                                  type="text"
                                  name="uf"
                                  className={'form-control'}
                                  value={this.state.cepRequestUf}
                                  onBlur={inputVal => this.setState({ cepRequestUf: inputVal.target.value ? inputVal.target.value : '' })}
                                  onChange={inputVal => {
                                    this.setState({ cepRequestUf: inputVal.target.value });
                                    this.setState({ ufSelectValue: inputVal.target.value });
                                  }}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ ufSelectValue: evt.target.value })}
                          type="hidden"
                          name="uf"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'complement' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="complementLabel" for="patient-complement">
                                  <Translate contentKey="generadorApp.patient.complement">Complement</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  onChange={evt => this.setState({ complementSelectValue: evt.target.value })}
                                  value={this.state.complementSelectValue ? this.state.complementSelectValue : ''}
                                  id="patient-complement"
                                  type="text"
                                  name="complement"
                                  className={'form-control'}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ complementSelectValue: evt.target.value })}
                          type="hidden"
                          name="complement"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}

                      {baseFilters !== 'reference' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="referenceLabel" for="patient-reference">
                                  <Translate contentKey="generadorApp.patient.reference">Reference</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  onChange={evt => this.setState({ referenceSelectValue: evt.target.value })}
                                  value={this.state.referenceSelectValue ? this.state.referenceSelectValue : ''}
                                  id="patient-reference"
                                  type="text"
                                  name="reference"
                                  className={'form-control'}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ referenceSelectValue: evt.target.value })}
                          type="hidden"
                          name="reference"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}
                    </Row>

                    {isNew ? (
                      <Button
                        color="primary"
                        className={'float-right jh-create-entity'}
                        onClick={() => {
                          this.tabsNext(2, 1);
                        }}
                      >
                        <span className="d-sm-none">
                          <Translate contentKey="generadorApp.patient.formTabs.btnPatientContacts">PatientContacts</Translate>
                        </span>
                        <span className="d-sm-block d-none">
                          <Translate contentKey="generadorApp.patient.formTabs.btnPatientContacts">PatientContacts</Translate>
                          &nbsp;
                          <FontAwesomeIcon icon="arrow-right" />
                        </span>
                      </Button>
                    ) : null}

                    {isNew ? (
                      <Button
                        color="info"
                        className={'float-right jh-create-entity'}
                        onClick={() => {
                          this.tabsNext(0);
                        }}
                      >
                        <span className="d-sm-none">
                          <Translate contentKey="generadorApp.patient.formTabs.btnPersonalInformation">PersonalInformation</Translate>
                        </span>
                        <span className="d-sm-block d-none">
                          <FontAwesomeIcon icon="arrow-left" />
                          &nbsp;
                          <Translate contentKey="generadorApp.patient.formTabs.btnPersonalInformation">PersonalInformation</Translate>
                        </span>
                      </Button>
                    ) : null}
                  </TabPane>
                  <TabPane tabId={2}>
                    {isNew ? (
                      <Row className="justify-content-center mb-3">
                        <Col md="12">
                          <h2 id="generadorApp.patient.home.formTabs_34">
                            <Translate contentKey="generadorApp.patient.formTabs.PatientContacts">PatientContacts</Translate>
                          </h2>
                        </Col>
                      </Row>
                    ) : null}

                    <Row>
                      {baseFilters !== 'patientContact' ? (
                        <Col md="12">
                          <div className="entityEmbebedList">
                            <PatientContact
                              entityEmbebedPai={fullEntityEmbebed}
                              history={this.props.history}
                              location={{
                                pathname: `/patient/${this.props.match.params['id']}/patient-contact/`,
                                search: '',
                                hash: '',
                                state: undefined,
                                key: 'q2tbhd',
                              }}
                              match={{
                                path: `/patient/:idPatient/patient-contact/:id`,
                                url: `/patient/${this.props.match.params['id']}/patient-contact/`,
                                isExact: true,
                                params: {
                                  idPatient: this.props.match.params['id'],
                                  url: `/patient/${this.props.match.params['id']}/patient-contact/`,
                                },
                              }}
                              {...this.props.mapStateToPropsPatientContact}
                              {...mapDispatchToPropsPatientContactList}
                              getEntityPai={(...v) => this.props.getEntity(...v)}
                              getEntities={this.patientContactGetEntities}
                              updateEntity={this.patientContactUpdateEntity}
                              getEntity={this.patientContactGetEntity}
                              createEntity={this.patientContactCreateEntity}
                              deleteEntity={this.patientContactDeleteEntity}
                              reset={this.patientContactReset}
                              patientContactList={this.props.patientContactListEmbed}
                              totalItems={this.props.patientContactListEmbed ? this.props.patientContactListEmbed.length : 0}
                              loading={this.props.loading}
                              patientContactEntity={this.props.patientContactSelected}
                            />
                          </div>
                        </Col>
                      ) : (
                        <AvInput
                          type="hidden"
                          name="patientContact"
                          value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                        />
                      )}{' '}
                    </Row>

                    {isNew ? (
                      <Button
                        color="primary"
                        className={'float-right jh-create-entity'}
                        onClick={() => {
                          this.tabsNext(3, 2);
                        }}
                      >
                        <span className="d-sm-none">
                          <Translate contentKey="generadorApp.patient.formTabs.btnPatientResponsible">PatientResponsible</Translate>
                        </span>
                        <span className="d-sm-block d-none">
                          <Translate contentKey="generadorApp.patient.formTabs.btnPatientResponsible">PatientResponsible</Translate>
                          &nbsp;
                          <FontAwesomeIcon icon="arrow-right" />
                        </span>
                      </Button>
                    ) : null}

                    {isNew ? (
                      <Button
                        color="info"
                        className={'float-right jh-create-entity'}
                        onClick={() => {
                          this.tabsNext(1);
                        }}
                      >
                        <span className="d-sm-none">
                          <Translate contentKey="generadorApp.patient.formTabs.btnInformationResidential">InformationResidential</Translate>
                        </span>
                        <span className="d-sm-block d-none">
                          <FontAwesomeIcon icon="arrow-left" />
                          &nbsp;
                          <Translate contentKey="generadorApp.patient.formTabs.btnInformationResidential">InformationResidential</Translate>
                        </span>
                      </Button>
                    ) : null}
                  </TabPane>
                  <TabPane tabId={3}>
                    {isNew ? (
                      <Row className="justify-content-center mb-3">
                        <Col md="12">
                          <h2 id="generadorApp.patient.home.formTabs_34">
                            <Translate contentKey="generadorApp.patient.formTabs.PatientResponsible">PatientResponsible</Translate>
                          </h2>
                        </Col>
                      </Row>
                    ) : null}

                    <Row>
                      {baseFilters !== 'patientResponsible' ? (
                        <Col md="12">
                          <div className="entityEmbebedList">
                            <PatientResponsible
                              entityEmbebedPai={fullEntityEmbebed}
                              history={this.props.history}
                              location={{
                                pathname: `/patient/${this.props.match.params['id']}/patient-responsible/`,
                                search: '',
                                hash: '',
                                state: undefined,
                                key: 'q2tbhd',
                              }}
                              match={{
                                path: `/patient/:idPatient/patient-responsible/:id`,
                                url: `/patient/${this.props.match.params['id']}/patient-responsible/`,
                                isExact: true,
                                params: {
                                  idPatient: this.props.match.params['id'],
                                  url: `/patient/${this.props.match.params['id']}/patient-responsible/`,
                                },
                              }}
                              {...this.props.mapStateToPropsPatientResponsible}
                              {...mapDispatchToPropsPatientResponsibleList}
                              getEntityPai={(...v) => this.props.getEntity(...v)}
                              getEntities={this.patientResponsibleGetEntities}
                              updateEntity={this.patientResponsibleUpdateEntity}
                              getEntity={this.patientResponsibleGetEntity}
                              createEntity={this.patientResponsibleCreateEntity}
                              deleteEntity={this.patientResponsibleDeleteEntity}
                              reset={this.patientResponsibleReset}
                              patientResponsibleList={this.props.patientResponsibleListEmbed}
                              totalItems={this.props.patientResponsibleListEmbed ? this.props.patientResponsibleListEmbed.length : 0}
                              loading={this.props.loading}
                              patientResponsibleEntity={this.props.patientResponsibleSelected}
                            />
                          </div>
                        </Col>
                      ) : (
                        <AvInput
                          type="hidden"
                          name="patientResponsible"
                          value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                        />
                      )}{' '}
                    </Row>

                    {isNew ? (
                      <Button
                        color="primary"
                        className={'float-right jh-create-entity'}
                        onClick={() => {
                          this.tabsNext(4, 3);
                        }}
                      >
                        <span className="d-sm-none">
                          <Translate contentKey="generadorApp.patient.formTabs.btnPatientSupplier">PatientSupplier</Translate>
                        </span>
                        <span className="d-sm-block d-none">
                          <Translate contentKey="generadorApp.patient.formTabs.btnPatientSupplier">PatientSupplier</Translate>
                          &nbsp;
                          <FontAwesomeIcon icon="arrow-right" />
                        </span>
                      </Button>
                    ) : null}

                    {isNew ? (
                      <Button
                        color="info"
                        className={'float-right jh-create-entity'}
                        onClick={() => {
                          this.tabsNext(2);
                        }}
                      >
                        <span className="d-sm-none">
                          <Translate contentKey="generadorApp.patient.formTabs.btnPatientContacts">PatientContacts</Translate>
                        </span>
                        <span className="d-sm-block d-none">
                          <FontAwesomeIcon icon="arrow-left" />
                          &nbsp;
                          <Translate contentKey="generadorApp.patient.formTabs.btnPatientContacts">PatientContacts</Translate>
                        </span>
                      </Button>
                    ) : null}
                  </TabPane>
                  <TabPane tabId={4}>
                    {isNew ? (
                      <Row className="justify-content-center mb-3">
                        <Col md="12">
                          <h2 id="generadorApp.patient.home.formTabs_34">
                            <Translate contentKey="generadorApp.patient.formTabs.PatientSupplier">PatientSupplier</Translate>
                          </h2>
                        </Col>
                      </Row>
                    ) : null}

                    <Row>
                      {baseFilters !== 'patientSupplier' ? (
                        <Col md="12">
                          <div className="entityEmbebedList">
                            <PatientSupplier
                              entityEmbebedPai={fullEntityEmbebed}
                              history={this.props.history}
                              location={{
                                pathname: `/patient/${this.props.match.params['id']}/patient-supplier/`,
                                search: '',
                                hash: '',
                                state: undefined,
                                key: 'q2tbhd',
                              }}
                              match={{
                                path: `/patient/:idPatient/patient-supplier/:id`,
                                url: `/patient/${this.props.match.params['id']}/patient-supplier/`,
                                isExact: true,
                                params: {
                                  idPatient: this.props.match.params['id'],
                                  url: `/patient/${this.props.match.params['id']}/patient-supplier/`,
                                },
                              }}
                              {...this.props.mapStateToPropsPatientSupplier}
                              {...mapDispatchToPropsPatientSupplierList}
                              getEntityPai={(...v) => this.props.getEntity(...v)}
                              getEntities={this.patientSupplierGetEntities}
                              updateEntity={this.patientSupplierUpdateEntity}
                              getEntity={this.patientSupplierGetEntity}
                              createEntity={this.patientSupplierCreateEntity}
                              deleteEntity={this.patientSupplierDeleteEntity}
                              reset={this.patientSupplierReset}
                              patientSupplierList={this.props.patientSupplierListEmbed}
                              totalItems={this.props.patientSupplierListEmbed ? this.props.patientSupplierListEmbed.length : 0}
                              loading={this.props.loading}
                              patientSupplierEntity={this.props.patientSupplierSelected}
                            />
                          </div>
                        </Col>
                      ) : (
                        <AvInput
                          type="hidden"
                          name="patientSupplier"
                          value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                        />
                      )}{' '}
                    </Row>

                    {isNew ? (
                      <Button
                        color="primary"
                        className={'float-right jh-create-entity'}
                        onClick={() => {
                          this.tabsNext(5, 4);
                        }}
                      >
                        <span className="d-sm-none">
                          <Translate contentKey="generadorApp.patient.formTabs.btnPatientInfomation">PatientInfomation</Translate>
                        </span>
                        <span className="d-sm-block d-none">
                          <Translate contentKey="generadorApp.patient.formTabs.btnPatientInfomation">PatientInfomation</Translate>
                          &nbsp;
                          <FontAwesomeIcon icon="arrow-right" />
                        </span>
                      </Button>
                    ) : null}

                    {isNew ? (
                      <Button
                        color="info"
                        className={'float-right jh-create-entity'}
                        onClick={() => {
                          this.tabsNext(3);
                        }}
                      >
                        <span className="d-sm-none">
                          <Translate contentKey="generadorApp.patient.formTabs.btnPatientResponsible">PatientResponsible</Translate>
                        </span>
                        <span className="d-sm-block d-none">
                          <FontAwesomeIcon icon="arrow-left" />
                          &nbsp;
                          <Translate contentKey="generadorApp.patient.formTabs.btnPatientResponsible">PatientResponsible</Translate>
                        </span>
                      </Button>
                    ) : null}
                  </TabPane>
                  <TabPane tabId={5}>
                    {isNew ? (
                      <Row className="justify-content-center mb-3">
                        <Col md="12">
                          <h2 id="generadorApp.patient.home.formTabs_34">
                            <Translate contentKey="generadorApp.patient.formTabs.PatientInfomation">PatientInfomation</Translate>
                          </h2>
                        </Col>
                      </Row>
                    ) : null}

                    <Row>
                      {baseFilters !== 'client' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" for="patient-client">
                                  <Translate contentKey="generadorApp.patient.client">Client</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <SelectAsync
                                  id="patient-client"
                                  name={'client'}
                                  className={'css-select-control'}
                                  data-type-rel="many-to-one-other-side"
                                  value={this.state.clientSelectValue ? this.state.clientSelectValue : ''}
                                  onChange={options => this.setState({ clientSelectValue: options })}
                                  defaultOptions={this.state.clientStartSelectOptions ? this.state.clientStartSelectOptions : []}
                                  loadingMessage={input => translate('selectAsync.loadingMessage')}
                                  noOptionsMessage={input =>
                                    this.state.clientStartSelectOptions === undefined
                                      ? translate('selectAsync.loadingMessage')
                                      : translate('selectAsync.noOptionsMessage')
                                  }
                                  onMenuOpen={async () => {
                                    if (this.state.clientStartSelectOptions === undefined) {
                                      const result = await getListAxios('clients', {}, 0, 100, 'fantasyName,asc', 'fantasyName');
                                      this.setState({
                                        clientStartSelectOptions: result.data
                                          ? result.data.map(p => ({
                                              ...p,
                                              value: p.id,
                                              label: showFieldsSelectAsync(p, 'fantasyName') || '',
                                            }))
                                          : [],
                                      });
                                    }
                                  }}
                                  loadOptions={async (inputValue, callback) => {
                                    const result = await getListAxios(
                                      'clients',
                                      { 'fantasyName.contains': inputValue },
                                      0,
                                      100,
                                      'fantasyName,asc',
                                      'fantasyName'
                                    );
                                    callback(
                                      result.data
                                        ? result.data.map(p => ({
                                            ...p,
                                            value: p.id,
                                            label: showFieldsSelectAsync(p, 'fantasyName') || '',
                                          }))
                                        : []
                                    );
                                  }}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          type="hidden"
                          name="client"
                          value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                        />
                      )}
                      {baseFilters !== 'healthPlanEnrollment' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="healthPlanEnrollmentLabel" for="patient-healthPlanEnrollment">
                                  <Translate contentKey="generadorApp.patient.healthPlanEnrollment">Health Plan Enrollment</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  onChange={evt => this.setState({ healthPlanEnrollmentSelectValue: evt.target.value })}
                                  value={this.state.healthPlanEnrollmentSelectValue ? this.state.healthPlanEnrollmentSelectValue : ''}
                                  id="patient-healthPlanEnrollment"
                                  type="text"
                                  name="healthPlanEnrollment"
                                  className={'form-control'}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ healthPlanEnrollmentSelectValue: evt.target.value })}
                          type="hidden"
                          name="healthPlanEnrollment"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}
                      {baseFilters !== 'liminar' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="liminarLabel" for="patient-liminar">
                                  <Translate contentKey="generadorApp.patient.liminar">Liminar</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <Select
                                  id="patient-liminar"
                                  className={'css-select-control'}
                                  value={
                                    this.state.liminarSelectValue
                                      ? {
                                          value: this.state.liminarSelectValue,
                                          label: translate('generadorApp.GeralSelect.' + this.state.liminarSelectValue),
                                        }
                                      : { value: '', label: translate('generadorApp.patient.liminar') }
                                  }
                                  options={[
                                    { value: 'SIM', label: translate('generadorApp.GeralSelect.SIM') },
                                    { value: 'NAO', label: translate('generadorApp.GeralSelect.NAO') },
                                  ]}
                                  onChange={(options: any) => this.setState({ liminarSelectValue: options['value'] })}
                                  name="liminar"
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ liminarSelectValue: evt.target.value })}
                          type="hidden"
                          name="liminar"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}
                      {baseFilters !== 'patientComplexity' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="patientComplexityLabel" for="patient-patientComplexity">
                                  <Translate contentKey="generadorApp.patient.patientComplexity">Patient complexity</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <Select
                                  id="patient-patientComplexity"
                                  className={'css-select-control'}
                                  value={
                                    this.state.patientComplexitySelectValue
                                      ? {
                                          value: this.state.patientComplexitySelectValue,
                                          label: translate('generadorApp.PatientComplexity.' + this.state.patientComplexitySelectValue),
                                        }
                                      : { value: '', label: translate('generadorApp.patient.patientComplexity') }
                                  }
                                  options={[
                                    { value: 'BAIXA', label: translate('generadorApp.PatientComplexity.BAIXA') },
                                    { value: 'MEDIA', label: translate('generadorApp.PatientComplexity.MEDIA') },
                                    { value: 'ALTA', label: translate('generadorApp.PatientComplexity.ALTA') },
                                    { value: 'VENTILACAOMECANICA', label: translate('generadorApp.PatientComplexity.VENTILACAOMECANICA') },
                                  ]}
                                  onChange={(options: any) => this.setState({ patientComplexitySelectValue: options['value'] })}
                                  name="patientComplexity"
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ patientComplexitySelectValue: evt.target.value })}
                          type="hidden"
                          name="patientComplexity"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}
                      {baseFilters !== 'patientDevice' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" for="patient-patientDevice">
                                  <Translate contentKey="generadorApp.patient.patientDevice">Patient Device</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <SelectAsync
                                  isMulti
                                  id="patient-patientDevice"
                                  name={'patientDevice'}
                                  className={'css-select-control'}
                                  data-type-rel="many-to-many-owner-side"
                                  value={this.state.patientDeviceSelectValue ? this.state.patientDeviceSelectValue : ''}
                                  onChange={options => this.setState({ patientDeviceSelectValue: options })}
                                  defaultOptions={
                                    this.state.patientDeviceStartSelectOptions ? this.state.patientDeviceStartSelectOptions : []
                                  }
                                  loadingMessage={input => translate('selectAsync.loadingMessage')}
                                  noOptionsMessage={input =>
                                    this.state.patientDeviceStartSelectOptions === undefined
                                      ? translate('selectAsync.loadingMessage')
                                      : translate('selectAsync.noOptionsMessage')
                                  }
                                  onMenuOpen={async () => {
                                    if (this.state.patientDeviceStartSelectOptions === undefined) {
                                      const result = await getListAxios('patient-devices', {}, 0, 100, 'name,asc', 'name');
                                      this.setState({
                                        patientDeviceStartSelectOptions: result.data
                                          ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                          : [],
                                      });
                                    }
                                  }}
                                  loadOptions={async (inputValue, callback) => {
                                    const result = await getListAxios(
                                      'patient-devices',
                                      { 'name.contains': inputValue },
                                      0,
                                      100,
                                      'name,asc',
                                      'name'
                                    );
                                    callback(
                                      result.data
                                        ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                        : []
                                    );
                                  }}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          type="hidden"
                          name="patientDevice"
                          value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                        />
                      )}
                      {baseFilters !== 'adId' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="adIdLabel" for="patient-adId">
                                  <Translate contentKey="generadorApp.patient.adId">AD/ID</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <Select
                                  id="patient-adId"
                                  className={'css-select-control'}
                                  value={
                                    this.state.adIdSelectValue
                                      ? {
                                          value: this.state.adIdSelectValue,
                                          label: translate('generadorApp.ADID.' + this.state.adIdSelectValue),
                                        }
                                      : { value: '', label: translate('generadorApp.patient.adId') }
                                  }
                                  options={[
                                    { value: 'AD', label: translate('generadorApp.ADID.AD') },
                                    { value: 'ID', label: translate('generadorApp.ADID.ID') },
                                  ]}
                                  onChange={(options: any) => this.setState({ adIdSelectValue: options['value'] })}
                                  name="adId"
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ adIdSelectValue: evt.target.value })}
                          type="hidden"
                          name="adId"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}
                      {baseFilters !== 'obese' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="obeseLabel" for="patient-obese">
                                  <Translate contentKey="generadorApp.patient.obese">Obese</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <Select
                                  id="patient-obese"
                                  className={'css-select-control'}
                                  value={
                                    this.state.obeseSelectValue
                                      ? {
                                          value: this.state.obeseSelectValue,
                                          label: translate('generadorApp.GeralSelect.' + this.state.obeseSelectValue),
                                        }
                                      : { value: '', label: translate('generadorApp.patient.obese') }
                                  }
                                  options={[
                                    { value: 'SIM', label: translate('generadorApp.GeralSelect.SIM') },
                                    { value: 'NAO', label: translate('generadorApp.GeralSelect.NAO') },
                                  ]}
                                  onChange={(options: any) => this.setState({ obeseSelectValue: options['value'] })}
                                  name="obese"
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ obeseSelectValue: evt.target.value })}
                          type="hidden"
                          name="obese"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}
                      {baseFilters !== 'nead' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="neadLabel" for="patient-nead">
                                  <Translate contentKey="generadorApp.patient.nead">NEAD</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <CurrencyInput
                                  onChange={(maskedvalue, floatvalue, event) => this.setState({ neadSelectValue: floatvalue })}
                                  decimalSeparator=""
                                  thousandSeparator=""
                                  precision={0}
                                  prefix=""
                                  allowNegative={true}
                                  value={this.state.neadSelectValue ? this.state.neadSelectValue : 0}
                                  id="patient-nead"
                                  name="nead"
                                  className={'form-control'}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ neadSelectValue: evt.target.value })}
                          type="hidden"
                          name="nead"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}
                      {baseFilters !== 'hospitalReference' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" id="hospitalReferenceLabel" for="patient-hospitalReference">
                                  <Translate contentKey="generadorApp.patient.hospitalReference">Hospital Reference</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  onChange={evt => this.setState({ hospitalReferenceSelectValue: evt.target.value })}
                                  value={this.state.hospitalReferenceSelectValue ? this.state.hospitalReferenceSelectValue : ''}
                                  id="patient-hospitalReference"
                                  type="text"
                                  name="hospitalReference"
                                  className={'form-control'}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ hospitalReferenceSelectValue: evt.target.value })}
                          type="hidden"
                          name="hospitalReference"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}
                      {baseFilters !== 'observations' ? (
                        <Col md="12">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className={'mt-2'} id="observationsLabel" for="patient-observations">
                                  <Translate contentKey="generadorApp.patient.observations">Observations</Translate>
                                </Label>
                              </Col>

                              <Col md="12">
                                <ReactQuill
                                  value={this.state.observationsSelectValue ? this.state.observationsSelectValue : ''}
                                  onChange={evt => this.setState({ observationsSelectValue: evt })}
                                  modules={quillEditorModules}
                                  formats={quillEditorFormats}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ observationsSelectValue: evt.target.value })}
                          type="hidden"
                          name="observations"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}
                      {baseFilters !== 'informationProfessional' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label
                                  className="mt-2 label-single-line"
                                  id="informationProfessionalLabel"
                                  for="patient-informationProfessional"
                                >
                                  <Translate contentKey="generadorApp.patient.informationProfessional">Information Professional</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <input
                                  onChange={evt => this.setState({ informationProfessionalSelectValue: evt.target.value })}
                                  value={this.state.informationProfessionalSelectValue ? this.state.informationProfessionalSelectValue : ''}
                                  id="patient-informationProfessional"
                                  type="text"
                                  name="informationProfessional"
                                  className={'form-control'}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          onChange={evt => this.setState({ informationProfessionalSelectValue: evt.target.value })}
                          type="hidden"
                          name="informationProfessional"
                          value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                        />
                      )}
                      {baseFilters !== 'professionalBlocked' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" for="patient-professionalBlocked">
                                  <Translate contentKey="generadorApp.patient.professionalBlocked">Professional Blocked</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <SelectAsync
                                  isMulti
                                  id="patient-professionalBlocked"
                                  name={'professionalBlocked'}
                                  className={'css-select-control'}
                                  data-type-rel="many-to-many-owner-side"
                                  value={this.state.professionalBlockedSelectValue ? this.state.professionalBlockedSelectValue : ''}
                                  onChange={options => this.setState({ professionalBlockedSelectValue: options })}
                                  defaultOptions={
                                    this.state.professionalBlockedStartSelectOptions ? this.state.professionalBlockedStartSelectOptions : []
                                  }
                                  loadingMessage={input => translate('selectAsync.loadingMessage')}
                                  noOptionsMessage={input =>
                                    this.state.professionalBlockedStartSelectOptions === undefined
                                      ? translate('selectAsync.loadingMessage')
                                      : translate('selectAsync.noOptionsMessage')
                                  }
                                  onMenuOpen={async () => {
                                    if (this.state.professionalBlockedStartSelectOptions === undefined) {
                                      const result = await getListAxios('professionals', {}, 0, 100, 'name,asc', 'name');
                                      this.setState({
                                        professionalBlockedStartSelectOptions: result.data
                                          ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                          : [],
                                      });
                                    }
                                  }}
                                  loadOptions={async (inputValue, callback) => {
                                    const result = await getListAxios(
                                      'professionals',
                                      { 'name.contains': inputValue },
                                      0,
                                      100,
                                      'name,asc',
                                      'name'
                                    );
                                    callback(
                                      result.data
                                        ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                        : []
                                    );
                                  }}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          type="hidden"
                          name="professionalBlocked"
                          value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                        />
                      )}
                      {baseFilters !== 'professionalPreferred' ? (
                        <Col md="6">
                          <AvGroup>
                            <Row>
                              <Col md="12">
                                <Label className="mt-2 label-single-line" for="patient-professionalPreferred">
                                  <Translate contentKey="generadorApp.patient.professionalPreferred">Professional Preferred</Translate>
                                </Label>
                              </Col>
                              <Col md="12">
                                <SelectAsync
                                  isMulti
                                  id="patient-professionalPreferred"
                                  name={'professionalPreferred'}
                                  className={'css-select-control'}
                                  data-type-rel="many-to-many-owner-side"
                                  value={this.state.professionalPreferredSelectValue ? this.state.professionalPreferredSelectValue : ''}
                                  onChange={options => this.setState({ professionalPreferredSelectValue: options })}
                                  defaultOptions={
                                    this.state.professionalPreferredStartSelectOptions
                                      ? this.state.professionalPreferredStartSelectOptions
                                      : []
                                  }
                                  loadingMessage={input => translate('selectAsync.loadingMessage')}
                                  noOptionsMessage={input =>
                                    this.state.professionalPreferredStartSelectOptions === undefined
                                      ? translate('selectAsync.loadingMessage')
                                      : translate('selectAsync.noOptionsMessage')
                                  }
                                  onMenuOpen={async () => {
                                    if (this.state.professionalPreferredStartSelectOptions === undefined) {
                                      const result = await getListAxios('professionals', {}, 0, 100, 'name,asc', 'name');
                                      this.setState({
                                        professionalPreferredStartSelectOptions: result.data
                                          ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                          : [],
                                      });
                                    }
                                  }}
                                  loadOptions={async (inputValue, callback) => {
                                    const result = await getListAxios(
                                      'professionals',
                                      { 'name.contains': inputValue },
                                      0,
                                      100,
                                      'name,asc',
                                      'name'
                                    );
                                    callback(
                                      result.data
                                        ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                        : []
                                    );
                                  }}
                                />
                              </Col>
                            </Row>
                          </AvGroup>
                        </Col>
                      ) : (
                        <AvInput
                          type="hidden"
                          name="professionalPreferred"
                          value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                        />
                      )}
                      {baseFilters !== 'patientCid' ? (
                        <Col md="12">
                          <div className="entityEmbebedList">
                            <PatientCid
                              entityEmbebedPai={fullEntityEmbebed}
                              history={this.props.history}
                              location={{
                                pathname: `/patient/${this.props.match.params['id']}/patient-cid/`,
                                search: '',
                                hash: '',
                                state: undefined,
                                key: 'q2tbhd',
                              }}
                              match={{
                                path: `/patient/:idPatient/patient-cid/:id`,
                                url: `/patient/${this.props.match.params['id']}/patient-cid/`,
                                isExact: true,
                                params: {
                                  idPatient: this.props.match.params['id'],
                                  url: `/patient/${this.props.match.params['id']}/patient-cid/`,
                                },
                              }}
                              {...this.props.mapStateToPropsPatientCid}
                              {...mapDispatchToPropsPatientCidList}
                              getEntityPai={(...v) => this.props.getEntity(...v)}
                              getEntities={this.patientCidGetEntities}
                              updateEntity={this.patientCidUpdateEntity}
                              getEntity={this.patientCidGetEntity}
                              createEntity={this.patientCidCreateEntity}
                              deleteEntity={this.patientCidDeleteEntity}
                              reset={this.patientCidReset}
                              patientCidList={this.props.patientCidListEmbed}
                              totalItems={this.props.patientCidListEmbed ? this.props.patientCidListEmbed.length : 0}
                              loading={this.props.loading}
                              patientCidEntity={this.props.patientCidSelected}
                            />
                          </div>
                        </Col>
                      ) : (
                        <AvInput
                          type="hidden"
                          name="patientCid"
                          value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                        />
                      )}
                      {baseFilters !== 'patientAllergy' ? (
                        <Col md="12">
                          <div className="entityEmbebedList">
                            <PatientAllergy
                              entityEmbebedPai={fullEntityEmbebed}
                              history={this.props.history}
                              location={{
                                pathname: `/patient/${this.props.match.params['id']}/patient-allergy/`,
                                search: '',
                                hash: '',
                                state: undefined,
                                key: 'q2tbhd',
                              }}
                              match={{
                                path: `/patient/:idPatient/patient-allergy/:id`,
                                url: `/patient/${this.props.match.params['id']}/patient-allergy/`,
                                isExact: true,
                                params: {
                                  idPatient: this.props.match.params['id'],
                                  url: `/patient/${this.props.match.params['id']}/patient-allergy/`,
                                },
                              }}
                              {...this.props.mapStateToPropsPatientAllergy}
                              {...mapDispatchToPropsPatientAllergyList}
                              getEntityPai={(...v) => this.props.getEntity(...v)}
                              getEntities={this.patientAllergyGetEntities}
                              updateEntity={this.patientAllergyUpdateEntity}
                              getEntity={this.patientAllergyGetEntity}
                              createEntity={this.patientAllergyCreateEntity}
                              deleteEntity={this.patientAllergyDeleteEntity}
                              reset={this.patientAllergyReset}
                              patientAllergyList={this.props.patientAllergyListEmbed}
                              totalItems={this.props.patientAllergyListEmbed ? this.props.patientAllergyListEmbed.length : 0}
                              loading={this.props.loading}
                              patientAllergyEntity={this.props.patientAllergySelected}
                            />
                          </div>
                        </Col>
                      ) : (
                        <AvInput
                          type="hidden"
                          name="patientAllergy"
                          value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                        />
                      )}{' '}
                    </Row>

                    {isNew ? (
                      <Button
                        color="primary"
                        id="save-entity"
                        type="submit"
                        disabled={updating}
                        className="btn btn-primary float-right jh-create-entity"
                      >
                        <FontAwesomeIcon icon="save" />
                        &nbsp;
                        <Translate contentKey="generadorApp.patient.btnSave">Save</Translate>
                      </Button>
                    ) : null}

                    {isNew ? (
                      <Button
                        color="info"
                        className={'float-right jh-create-entity'}
                        onClick={() => {
                          this.tabsNext(4);
                        }}
                      >
                        <span className="d-sm-none">
                          <Translate contentKey="generadorApp.patient.formTabs.btnPatientSupplier">PatientSupplier</Translate>
                        </span>
                        <span className="d-sm-block d-none">
                          <FontAwesomeIcon icon="arrow-left" />
                          &nbsp;
                          <Translate contentKey="generadorApp.patient.formTabs.btnPatientSupplier">PatientSupplier</Translate>
                        </span>
                      </Button>
                    ) : null}
                  </TabPane>
                </TabContent>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const {
      patientEntity,
      patientDoctorPrescriptions,
      patientNursingPrescriptions,
      patientStatusHistories,
      patientContacts,
      patientClinicalIndicators,
      patientCids,
      patientAllergies,
      patientSuppliers,
      quotes,
      medicalRecords,
      nursingDiaries,
      nursingPrescriptions,
      patientFiles,
      patientResponsibles,
      pads,
      pushes,
      patientDevices,
      professionals,
      suppliers,
      clients,
      loading,
      updating,
    } = this.props;
    const { isNew } = this.state;

    const { observations } = patientEntity;
    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <div>
        <AvForm
          model={
            isNew
              ? {}
              : {
                  ...patientEntity,
                  activeTab: 0,
                }
          }
          onSubmit={this.saveEntity}
        >
          <Panel className="patient patient-update">
            <PanelHeader>{this.renderHeader()}</PanelHeader>
            <PanelBody>{this.renderBody()}</PanelBody>
          </Panel>
        </AvForm>
      </div>
    );
  }
}

export const mapStateToProps = (storeState: IRootState) => {
  return {
    patientDoctorPrescriptions: storeState.patientDoctorPrescription.entities,
    patientNursingPrescriptions: storeState.patientNursingPrescription.entities,
    patientStatusHistories: storeState.patientStatusHistory.entities,
    patientContacts: storeState.patientContact.entities,
    patientClinicalIndicators: storeState.patientClinicalIndicator.entities,
    patientCids: storeState.patientCid.entities,
    patientAllergies: storeState.patientAllergy.entities,
    patientSuppliers: storeState.patientSupplier.entities,
    quotes: storeState.quote.entities,
    medicalRecords: storeState.medicalRecord.entities,
    nursingDiaries: storeState.nursingDiary.entities,
    nursingPrescriptions: storeState.nursingPrescription.entities,
    patientFiles: storeState.patientFile.entities,
    patientResponsibles: storeState.patientResponsible.entities,
    pads: storeState.pad.entities,
    pushes: storeState.push.entities,
    patientDevices: storeState.patientDevice.entities,
    professionals: storeState.professional.entities,
    suppliers: storeState.supplier.entities,
    clients: storeState.client.entities,
    patientEntity: storeState.patient.entity,
    loading: storeState.patient.loading,
    updating: storeState.patient.updating,
    updateSuccess: storeState.patient.updateSuccess,

    patients: storeState.patient.entities,
    patientSupplierListAllEmbed: storeState.patient.patientSupplierListAllEmbed,
    patientSupplierListEmbed: storeState.patient.patientSupplierListEmbed,
    patientSupplierSelected: storeState.patient.patientSupplierSelected,
    mapStateToPropsPatientSupplier: mapStateToPropsPatientSupplier(storeState),

    patientCidListAllEmbed: storeState.patient.patientCidListAllEmbed,
    patientCidListEmbed: storeState.patient.patientCidListEmbed,
    patientCidSelected: storeState.patient.patientCidSelected,
    mapStateToPropsPatientCid: mapStateToPropsPatientCid(storeState),

    patientAllergyListAllEmbed: storeState.patient.patientAllergyListAllEmbed,
    patientAllergyListEmbed: storeState.patient.patientAllergyListEmbed,
    patientAllergySelected: storeState.patient.patientAllergySelected,
    mapStateToPropsPatientAllergy: mapStateToPropsPatientAllergy(storeState),

    patientContactListAllEmbed: storeState.patient.patientContactListAllEmbed,
    patientContactListEmbed: storeState.patient.patientContactListEmbed,
    patientContactSelected: storeState.patient.patientContactSelected,
    mapStateToPropsPatientContact: mapStateToPropsPatientContact(storeState),

    patientResponsibleListAllEmbed: storeState.patient.patientResponsibleListAllEmbed,
    patientResponsibleListEmbed: storeState.patient.patientResponsibleListEmbed,
    patientResponsibleSelected: storeState.patient.patientResponsibleSelected,
    mapStateToPropsPatientResponsible: mapStateToPropsPatientResponsible(storeState),
  };
};

const mapDispatchToPropsPatientSupplierList = {};
Object.keys(mapDispatchToPropsPatientSupplier).map(v => {
  mapDispatchToPropsPatientSupplierList[v + 'PatientSupplier'] = mapDispatchToPropsPatientSupplier[v];
});
const mapDispatchToPropsPatientCidList = {};
Object.keys(mapDispatchToPropsPatientCid).map(v => {
  mapDispatchToPropsPatientCidList[v + 'PatientCid'] = mapDispatchToPropsPatientCid[v];
});
const mapDispatchToPropsPatientAllergyList = {};
Object.keys(mapDispatchToPropsPatientAllergy).map(v => {
  mapDispatchToPropsPatientAllergyList[v + 'PatientAllergy'] = mapDispatchToPropsPatientAllergy[v];
});
const mapDispatchToPropsPatientContactList = {};
Object.keys(mapDispatchToPropsPatientContact).map(v => {
  mapDispatchToPropsPatientContactList[v + 'PatientContact'] = mapDispatchToPropsPatientContact[v];
});
const mapDispatchToPropsPatientResponsibleList = {};
Object.keys(mapDispatchToPropsPatientResponsible).map(v => {
  mapDispatchToPropsPatientResponsibleList[v + 'PatientResponsible'] = mapDispatchToPropsPatientResponsible[v];
});

export const mapDispatchToProps = {
  getEntity,
  updateEntity,
  setBlob,
  createEntity,
  getEntityOffset,
  reset,

  ...mapDispatchToPropsPatientSupplierList,
  patientSupplierUpdateEntity,
  patientSupplierGetEntities,
  patientSupplierSetEntities,
  patientSupplierReset,
  patientSupplierGetEntity,
  patientSupplierCreateEntity,
  patientSupplierDeleteEntity,
  ...mapDispatchToPropsPatientCidList,
  patientCidUpdateEntity,
  patientCidGetEntities,
  patientCidSetEntities,
  patientCidReset,
  patientCidGetEntity,
  patientCidCreateEntity,
  patientCidDeleteEntity,
  ...mapDispatchToPropsPatientAllergyList,
  patientAllergyUpdateEntity,
  patientAllergyGetEntities,
  patientAllergySetEntities,
  patientAllergyReset,
  patientAllergyGetEntity,
  patientAllergyCreateEntity,
  patientAllergyDeleteEntity,
  ...mapDispatchToPropsPatientContactList,
  patientContactUpdateEntity,
  patientContactGetEntities,
  patientContactSetEntities,
  patientContactReset,
  patientContactGetEntity,
  patientContactCreateEntity,
  patientContactDeleteEntity,
  ...mapDispatchToPropsPatientResponsibleList,
  patientResponsibleUpdateEntity,
  patientResponsibleGetEntities,
  patientResponsibleSetEntities,
  patientResponsibleReset,
  patientResponsibleGetEntity,
  patientResponsibleCreateEntity,
  patientResponsibleDeleteEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PatientUpdate);
