/* eslint complexity: ["error", 500] */
import React from 'react';

import { convertDateTimeToServer } from 'app/shared/util/date-utils';
import CurrencyInput from 'react-currency-input';
import { connect } from 'react-redux';

import {
  getFilterFromSelect,
  getFormFromSelect,
  getListAxios,
  showFieldsSelectAsync,
  checkAnyValueFields,
} from 'app/shared/util/entity-utils';
import Select from 'react-select';
import { toast } from 'react-toastify';
import SelectAsync from 'react-select/async';
import { Link, RouteComponentProps } from 'react-router-dom';

import { Button, Col, Row, Table, Label, UncontrolledCollapse, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { JhiItemCount } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { IRootState } from 'app/shared/reducers';
import {
  updateEntity,
  getUrlBack,
  getBillToPayState,
  IBillToPayBaseState,
  getEntityFiltersURL,
  getEntities,
  IBillToPayUpdateState as IBaseUpdateState,
  reset,
  getEntity,
  deleteEntity,
  createEntity,
} from './bill-to-pay.reducer';

import { ITEMS_PER_PAGE, IPaginationBaseState, getSortState } from 'app/shared/util/pagination.constants';

import { selectFieldsList } from './extended/extendSelectFields';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';
import moment from 'moment';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

export interface IBillToPayProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
  entityEmbebedPai?: any;
}

export interface IBillToPayState extends IBillToPayBaseState, IPaginationBaseState, IBaseUpdateState {
  showModalForm?: boolean;
  showModalEdit?: number;
  showModalEditView?: boolean;
}

export class BillToPay extends React.Component<IBillToPayProps, IBillToPayState> {
  protected myFormRef: any;
  protected myFormRefNew: any;
  protected myFormRefEdit: any;

  typeFileInput: any;

  nameFileInput: any;

  descriptionFileInput: any;

  billValueFileInput: any;

  paymentDayFileInput: any;

  paymentDateFileInput: any;

  constructor(props: IBillToPayProps) {
    super(props);
    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      listCheckedID: [],
      isCheckedAll: false,
      showModalForm: false,
      showModalEdit: null,
      urlBack: getUrlBack(this.props.location),
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      ...getBillToPayState(this.props.location),
      variableCostSelectValue: null,

      fixedCostSelectValue: null,

      typeSelectValue: null,
      nameSelectValue: null,
      descriptionSelectValue: null,
      billValueSelectValue: null,
      paymentDaySelectValue: null,
      paymentDateSelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getBillToPayState(this.props.location),
      },
      variableCostName: null,
      fixedCostName: null,
      isNew: false,
    };
  }

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({ listCheckedID: this.props.billToPayList.map(billToPay => billToPay.id) });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({ listCheckedID: this.props.billToPayList.map(billToPay => billToPay.id) });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  removeEntitySelected() {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  }

  saveEntityMany = () => {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  };

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  cancelFilters = () => {
    this.setState(
      {
        name: '',
        type: '',
        billValue: '',
        fixedCostId: '',
        variableCostId: '',
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
        activePage: 0,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();
    const { name, type, billValue, fixedCostId, variableCostId, extraFilters, activePage, itemsPerPage, sort, order } = this.state;
    return this.props.getEntities(
      name,
      type,
      billValue,
      getFilterFromSelect(fixedCostId, 'many-to-one'),
      getFilterFromSelect(variableCostId, 'many-to-one'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(',')
    );
  };

  updateEntity = entity => {
    const { name, type, billValue, fixedCostId, variableCostId, extraFilters, activePage, itemsPerPage, sort, order } = this.state;
    this.props.updateEntity(entity, [
      name,
      type,
      billValue,
      getFilterFromSelect(fixedCostId, 'many-to-one'),
      getFilterFromSelect(variableCostId, 'many-to-one'),
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(','),
    ]);
  };

  saveEntity = (event: any, errors: any, values: any, isNew: any) => {
    values.paymentDate = convertDateTimeToServer(values.paymentDate);

    if (errors.length === 0) {
      const { billToPayEntity } = this.props;
      const _entity = {
        ...billToPayEntity,
        // ...values,

        fixedCost: getFormFromSelect(this.state.fixedCostSelectValue, 'many-to-one'), // many-to-one - otherSide

        variableCost: getFormFromSelect(this.state.variableCostSelectValue, 'many-to-one'), // many-to-one - otherSide

        type: this.state.typeSelectValue === null ? null : this.state.typeSelectValue,
        name: this.state.nameSelectValue,
        description: this.state.descriptionSelectValue,
        billValue: this.state.billValueSelectValue,
        paymentDay: this.state.paymentDaySelectValue,
        paymentDate: this.state.paymentDateSelectValue,
      };
      const entity = _entity;

      const {
        name,
        type,
        billValue,
        fixedCostId,
        variableCostId,
        extraFilters,
        activePage,
        itemsPerPage,
        sort,
        order,
      } = this.state.fieldsBase;

      if (isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              name,
              type,
              billValue,
              getFilterFromSelect(fixedCostId, 'many-to-one'),
              getFilterFromSelect(variableCostId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              name,
              type,
              billValue,
              getFilterFromSelect(fixedCostId, 'many-to-one'),
              getFilterFromSelect(variableCostId, 'many-to-one'),
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
      this.setState({ showModalForm: false });
      this.setState({ showModalEdit: null });
    } else {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.specialty.' + errors[0]) }));
    }
  };

  componentDidUpdate(prevProps) {}

  deleteEntity(billToPayEntity) {
    MySwal.fire({
      title: <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>,
      html: (
        <Translate contentKey="generadorApp.billToPay.delete.question" interpolate={{ id: billToPayEntity.id }}>
          Are you sure you want to delete this BillToPay?
        </Translate>
      ),
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#e70015',
      cancelButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="ban" />
          &nbsp; <Translate contentKey="generadorApp.billToPay.btnCancel">Cancel</Translate>
        </>
      ),
      confirmButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="trash" />
          &nbsp;<Translate contentKey="generadorApp.billToPay.btnDelete">Delete</Translate>
        </>
      ),
    }).then(async response => {
      if (response.isConfirmed) {
        await this.props.deleteEntity(billToPayEntity.id, { reloadList: false });
        this.getEntities();
        MySwal.fire({
          title: <Translate contentKey="entity.delete.title.confirmed">Confirmed delete operation</Translate>,
          icon: 'success',
        });
      }
    });
  }

  renderNewModal() {
    return (
      <Modal size={'xl'} isOpen={this.state.showModalForm} toggle={() => this.setState({ showModalForm: false })}>
        <ModalHeader toggle={() => this.setState({ showModalForm: false })}>{this.renderHeaderUpdate({}, true)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefNew = el)}
          model={{}}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, true)}
        >
          <ModalBody>{this.renderBodyUpdate({}, true)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalForm: false })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.billToPay.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefNew && this.myFormRefNew.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.billToPay.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    );
  }

  getFullEntityFromState(v) {
    return {
      id: v.id,
      type: this.state.typeSelectValue,
      name: this.state.nameSelectValue,
      description: this.state.descriptionSelectValue,
      billValue: this.state.billValueSelectValue,
      paymentDay: this.state.paymentDaySelectValue,
      paymentDate: this.state.paymentDateSelectValue,
      variableCost: this.state.variableCostSelectValue,
      fixedCost: this.state.fixedCostSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    let p: any = null;

    p = v.variableCost;
    const variableCostEntity = p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : p;

    p = v.fixedCost;
    const fixedCostEntity = p ? { ...p, value: p.id, label: p['name'] ? p.name : '' } : p;

    return {
      typeSelectValue: v.type, // type,
      nameSelectValue: v.name, // name,
      descriptionSelectValue: v.description, // description,
      billValueSelectValue: v.billValue, // billValue,
      paymentDaySelectValue: v.paymentDay, // paymentDay,
      paymentDateSelectValue: v.paymentDate ? moment(v.paymentDate).toDate() : null, // Instant,
      variableCostSelectValue: variableCostEntity,
      fixedCostSelectValue: fixedCostEntity,
    };
  }

  openUpdateModal(v, edit = true) {
    this.props.getEntity(v.id);
    this.setState({
      showModalEdit: v.id,
      showModalEditView: edit,
      ...this.getFullEntitySelectValue(v),
    });
  }

  renderUpdateModal() {
    const { billToPayEntity, entityEmbebedPai } = this.props;
    return billToPayEntity && billToPayEntity.id ? (
      <Modal size={'xl'} isOpen={this.state.showModalEdit === billToPayEntity.id} toggle={() => this.setState({ showModalEdit: null })}>
        <ModalHeader toggle={() => this.setState({ showModalEdit: null })}>{this.renderHeaderUpdate(billToPayEntity, false)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefEdit = el)}
          model={{
            ...billToPayEntity,
          }}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, false)}
        >
          <ModalBody>{this.renderBodyUpdate(billToPayEntity, false)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalEdit: null })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.billToPay.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefEdit && this.myFormRefEdit.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.billToPay.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    ) : (
      <> </>
    );
  }

  renderHeaderUpdate(billToPayEntity, isNew) {
    const { entityEmbebedPai, variableCosts, fixedCosts, loading } = this.props;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {isNew ? (
              <Translate contentKey="generadorApp.billToPay.home.createLabel">Create a BillToPay</Translate>
            ) : (
              <Translate contentKey="generadorApp.billToPay.home.editLabel">Edit a BillToPay</Translate>
            )}
          </span>
        </div>
      </>
    );
  }
  renderBodyUpdate(billToPayEntity, isNew) {
    const { entityEmbebedPai, variableCosts, fixedCosts, loading } = this.props;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
              <Col md="3">
              <Label className="mt-2 label-single-line"for="bill-to-pay-id">
                <Translate contentKey="generadorApp.billToPay.field_id">ID</Translate>
              </Label>
              </Col> */}
                      <Col md="12">
                        <AvInput id="bill-to-pay-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'type' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="typeLabel" for="bill-to-pay-type">
                              <Translate contentKey="generadorApp.billToPay.type">Type</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="bill-to-pay-type"
                              className={'css-select-control'}
                              value={
                                this.state.typeSelectValue
                                  ? {
                                      value: this.state.typeSelectValue,
                                      label: translate('generadorApp.BillsPayType.' + this.state.typeSelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.billToPay.type') }
                              }
                              options={[
                                { value: 'FIXO', label: translate('generadorApp.BillsPayType.FIXO') },
                                { value: 'VARIAVEL', label: translate('generadorApp.BillsPayType.VARIAVEL') },
                              ]}
                              onChange={(options: any) => this.setState({ typeSelectValue: options['value'] })}
                              name="type"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ typeSelectValue: evt.target.value })}
                      type="hidden"
                      name="type"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'name' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="nameLabel" for="bill-to-pay-name">
                              <Translate contentKey="generadorApp.billToPay.name">Name</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                              value={this.state.nameSelectValue ? this.state.nameSelectValue : ''}
                              id="bill-to-pay-name"
                              type="text"
                              name="name"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                      type="hidden"
                      name="name"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'description' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="descriptionLabel" for="bill-to-pay-description">
                              <Translate contentKey="generadorApp.billToPay.description">Description</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ descriptionSelectValue: evt.target.value })}
                              value={this.state.descriptionSelectValue ? this.state.descriptionSelectValue : ''}
                              id="bill-to-pay-description"
                              type="text"
                              name="description"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ descriptionSelectValue: evt.target.value })}
                      type="hidden"
                      name="description"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'billValue' ? (
                    <Col md="3">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className={'mt-2'} id="billValueLabel" for="bill-to-pay-billValue">
                              <Translate contentKey="generadorApp.billToPay.billValue">Value</Translate>
                            </Label>
                          </Col>

                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ billValueSelectValue: floatvalue })}
                              decimalSeparator=","
                              precision={2}
                              thousandSeparator=""
                              prefix="R$ "
                              allowNegative={true}
                              value={this.state.billValueSelectValue ? this.state.billValueSelectValue : 0}
                              id="bill-to-pay-billValue"
                              name="billValue"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ billValueSelectValue: evt.target.value })}
                      type="hidden"
                      name="billValue"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {checkAnyValueFields(this.state, 'typeSelectValue', 'FIXO', true) && baseFilters !== 'fixedCost' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="bill-to-pay-fixedCost">
                              <Translate contentKey="generadorApp.billToPay.fixedCost">Fixed Cost</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="bill-to-pay-fixedCost"
                              name={'fixedCost'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.fixedCostSelectValue ? this.state.fixedCostSelectValue : ''}
                              onChange={options => this.setState({ fixedCostSelectValue: options })}
                              defaultOptions={this.state.fixedCostStartSelectOptions ? this.state.fixedCostStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.fixedCostStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.fixedCostStartSelectOptions === undefined) {
                                  const result = await getListAxios('fixed-costs', {}, 0, 100, 'name,asc', 'name');
                                  this.setState({
                                    fixedCostStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'fixed-costs',
                                  { 'name.contains': inputValue },
                                  0,
                                  100,
                                  'name,asc',
                                  'name'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="fixedCost"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}

                  {checkAnyValueFields(this.state, 'typeSelectValue', 'VARIAVEL', true) && baseFilters !== 'variableCost' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" for="bill-to-pay-variableCost">
                              <Translate contentKey="generadorApp.billToPay.variableCost">Variable Cost</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <SelectAsync
                              id="bill-to-pay-variableCost"
                              name={'variableCost'}
                              className={'css-select-control'}
                              data-type-rel="many-to-one-other-side"
                              value={this.state.variableCostSelectValue ? this.state.variableCostSelectValue : ''}
                              onChange={options => this.setState({ variableCostSelectValue: options })}
                              defaultOptions={this.state.variableCostStartSelectOptions ? this.state.variableCostStartSelectOptions : []}
                              loadingMessage={input => translate('selectAsync.loadingMessage')}
                              noOptionsMessage={input =>
                                this.state.variableCostStartSelectOptions === undefined
                                  ? translate('selectAsync.loadingMessage')
                                  : translate('selectAsync.noOptionsMessage')
                              }
                              onMenuOpen={async () => {
                                if (this.state.variableCostStartSelectOptions === undefined) {
                                  const result = await getListAxios('variable-costs', {}, 0, 100, 'name,asc', 'name');
                                  this.setState({
                                    variableCostStartSelectOptions: result.data
                                      ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                      : [],
                                  });
                                }
                              }}
                              loadOptions={async (inputValue, callback) => {
                                const result = await getListAxios(
                                  'variable-costs',
                                  { 'name.contains': inputValue },
                                  0,
                                  100,
                                  'name,asc',
                                  'name'
                                );
                                callback(
                                  result.data
                                    ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') || '' }))
                                    : []
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      type="hidden"
                      name="variableCost"
                      value={this.state.fieldsBase[baseFilters + 'Id'] ? this.state.fieldsBase[baseFilters + 'Id'] : ''}
                    />
                  )}

                  {this.state.typeSelectValue === 'FIXO' && baseFilters !== 'paymentDay' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="paymentDayLabel" for="bill-to-pay-paymentDay">
                              <Translate contentKey="generadorApp.billToPay.paymentDay">Payment day</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <CurrencyInput
                              onChange={(maskedvalue, floatvalue, event) => this.setState({ paymentDaySelectValue: floatvalue })}
                              decimalSeparator=""
                              thousandSeparator=""
                              precision={0}
                              prefix=""
                              allowNegative={true}
                              value={this.state.paymentDaySelectValue ? this.state.paymentDaySelectValue : 0}
                              id="bill-to-pay-paymentDay"
                              name="paymentDay"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ paymentDaySelectValue: evt.target.value })}
                      type="hidden"
                      name="paymentDay"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {this.state.typeSelectValue === 'VARIAVEL' && baseFilters !== 'paymentDate' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="paymentDateLabel" for="bill-to-pay-paymentDate">
                              <Translate contentKey="generadorApp.billToPay.paymentDate">Payment date</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <DatePicker
                              autoComplete="off"
                              onChange={value => this.setState({ paymentDateSelectValue: value })}
                              id="paymentDate"
                              validate={{}}
                              className="form-control react-datepicker"
                              name="paymentDate"
                              dateFormat="dd/MM/yyyy"
                              selected={this.state.paymentDateSelectValue ? moment(this.state.paymentDateSelectValue).toDate() : null}
                              locale="pt"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ paymentDateSelectValue: evt.target.value })}
                      type="hidden"
                      name="paymentDate"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  renderHeader() {
    const { variableCosts, fixedCosts, billToPayList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.billToPay.home.title">Billing Xmls</Translate>
          </span>
          <Button id="togglerFilterBillToPay" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.billToPay.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          &nbsp;
          <Button
            onClick={() =>
              this.setState({
                showModalForm: true,
                typeSelectValue: null,
                nameSelectValue: null,
                descriptionSelectValue: null,
                billValueSelectValue: null,
                paymentDaySelectValue: null,
                paymentDateSelectValue: null,

                variableCostStartSelectOptions: undefined,
                variableCostSelectValue: null,

                fixedCostStartSelectOptions: undefined,
                fixedCostSelectValue: null,
                isNew: true,
              })
            }
            color="primary"
            className="btn btn-primary float-right jh-create-entity"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="generadorApp.billToPay.btnNewItem">Create new</Translate>
          </Button>{' '}
          &nbsp;
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.billToPay.home.title">Billing Xmls</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    const { variableCosts, fixedCosts, billToPayList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <UncontrolledCollapse toggler="#togglerFilterBillToPay">
          <CardBody>
            <AvForm ref={el => (this.myFormRef = el)} id="form-filter" onSubmit={this.filterEntity}>
              <div className="row mt-1 ml-3 mr-3">
                {this.state.baseFilters !== 'name' ? (
                  <Col md="4" className="col-filter-bill-to-pay-name">
                    <Row className="mr-1 mt-1">
                      <Label id="nameLabel" for="bill-to-pay-name">
                        <Translate contentKey="generadorApp.billToPay.name">Name</Translate>
                      </Label>

                      <AvInput type="text" name="name" id="bill-to-pay-name" value={this.state.name} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'type' ? (
                  <Col md="4" className="col-filter-bill-to-pay-type">
                    <Row className="mr-1 mt-1">
                      <Label id="typeLabel" for="bill-to-pay-type">
                        <Translate contentKey="generadorApp.billToPay.type">Type</Translate>
                      </Label>
                      <Select
                        id="bill-to-pay-type"
                        className={'css-select-control'}
                        value={
                          this.state.type
                            ? { value: this.state.type, label: translate('generadorApp.BillsPayType.' + this.state.type) }
                            : { value: '', label: translate('generadorApp.billToPay.type') }
                        }
                        options={[
                          { value: '', label: translate('generadorApp.billToPay.type') },
                          { value: 'FIXO', label: translate('generadorApp.BillsPayType.FIXO') },
                          { value: 'VARIAVEL', label: translate('generadorApp.BillsPayType.VARIAVEL') },
                        ]}
                        onChange={(options: any) => this.setState({ type: options['value'] })}
                        name="type"
                      />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'billValue' ? (
                  <Col md="4" className="col-filter-bill-to-pay-billValue">
                    <Row className="mr-1 mt-1">
                      <Label id="billValueLabel" for="bill-to-pay-billValue">
                        <Translate contentKey="generadorApp.billToPay.billValue">Value</Translate>
                      </Label>

                      <AvInput type="text" name="billValue" id="bill-to-pay-billValue" value={this.state.billValue} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'fixedCost' ? (
                  <Col md="6">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="bill-to-pay-fixedCost">
                          <Translate contentKey="generadorApp.billToPay.fixedCost">Fixed Cost</Translate>
                        </Label>
                        <SelectAsync
                          id="bill-to-pay-fixedCost"
                          isMulti
                          className={'css-select-control'}
                          name={'fixedCost'}
                          cacheOptions
                          value={this.state.fixedCostId}
                          onChange={options => this.setState({ fixedCostId: options })}
                          defaultOptions={this.state.fixedCostStartFilter ? this.state.fixedCostStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.fixedCostStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.fixedCostStartFilter === undefined) {
                              const result = await getListAxios('fixed-costs', {}, 0, 100, 'name,asc', 'name');
                              this.setState({
                                fixedCostStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios('fixed-costs', { 'name.contains': inputValue }, 0, 100, 'name,asc', 'name');
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'variableCost' ? (
                  <Col md="6">
                    <Row className="mr-1 mt-1">
                      <div style={{ width: '100%' }}>
                        <Label for="bill-to-pay-variableCost">
                          <Translate contentKey="generadorApp.billToPay.variableCost">Variable Cost</Translate>
                        </Label>
                        <SelectAsync
                          id="bill-to-pay-variableCost"
                          isMulti
                          className={'css-select-control'}
                          name={'variableCost'}
                          cacheOptions
                          value={this.state.variableCostId}
                          onChange={options => this.setState({ variableCostId: options })}
                          defaultOptions={this.state.variableCostStartFilter ? this.state.variableCostStartFilter : []}
                          loadingMessage={input => translate('selectAsync.loadingMessage')}
                          noOptionsMessage={input =>
                            this.state.variableCostStartFilter === undefined
                              ? translate('selectAsync.loadingMessage')
                              : translate('selectAsync.noOptionsMessage')
                          }
                          onMenuOpen={async () => {
                            if (this.state.variableCostStartFilter === undefined) {
                              const result = await getListAxios('variable-costs', {}, 0, 100, 'name,asc', 'name');
                              this.setState({
                                variableCostStartFilter: result.data
                                  ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={async (inputValue, callback) => {
                            const result = await getListAxios(
                              'variable-costs',
                              { 'name.contains': inputValue },
                              0,
                              100,
                              'name,asc',
                              'name'
                            );
                            callback(
                              result.data ? result.data.map(p => ({ ...p, value: p.id, label: showFieldsSelectAsync(p, 'name') })) : []
                            );
                          }}
                        />
                      </div>
                    </Row>
                  </Col>
                ) : null}
              </div>

              <div className="row mb-2 mr-4 justify-content-end">
                <Button color="primary" type="submit">
                  <i className="fa fa-filter" aria-hidden={'true'}></i>&nbsp;
                  <Translate contentKey="generadorApp.billToPay.btnFilter">Filter</Translate>
                </Button>
                &nbsp;
                <Button color="secondary" onClick={this.cancelFilters}>
                  <FontAwesomeIcon icon="trash-alt" />
                  &nbsp;
                  <Translate contentKey="generadorApp.billToPay.btnCleanFilter">Clean Filter</Translate>
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </UncontrolledCollapse>
      </>
    );
  }

  renderTable() {
    const { variableCosts, fixedCosts, billToPayList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {billToPayList && billToPayList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null).length > 0 ? (
          <>
            <Table
              id="bill-to-pay-table-list"
              responsive
              aria-describedby="bill-to-pay-heading"
              className={'table-hover table-striped table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.billToPay.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'type' ? (
                    <th className="hand" onClick={sortFunction('type')}>
                      <Translate contentKey="generadorApp.billToPay.type">Type</Translate>
                      <FontAwesomeIcon icon={state.sort === 'type' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'name' ? (
                    <th className="hand" onClick={sortFunction('name')}>
                      <Translate contentKey="generadorApp.billToPay.name">Name</Translate>
                      <FontAwesomeIcon icon={state.sort === 'name' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'description' ? (
                    <th className="hand" onClick={sortFunction('description')}>
                      <Translate contentKey="generadorApp.billToPay.description">Description</Translate>
                      <FontAwesomeIcon icon={state.sort === 'description' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'billValue' ? (
                    <th className="hand" onClick={sortFunction('billValue')}>
                      <Translate contentKey="generadorApp.billToPay.billValue">Value</Translate>
                      <FontAwesomeIcon icon={state.sort === 'billValue' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}

                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {billToPayList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((billToPay, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.billToPay.field_id">ID</Translate>
                        </b>
                        {billToPay.id}
                      </td>

                      {state.baseFilters !== 'type' ? (
                        <td id="type-cell">
                          <b className="visible-xs"> Type </b>
                          {billToPay.type ? <Translate contentKey={`generadorApp.BillsPayType.${billToPay.type}`} /> : <> </>}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'name' ? (
                        <td id="name-cell">
                          <b className="visible-xs"> Name </b>

                          {billToPay.name}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'description' ? (
                        <td id="description-cell">
                          <b className="visible-xs"> Description </b>

                          {billToPay.description}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'billValue' ? (
                        <td id="billValue-cell">
                          <b className="visible-xs"> Value </b>
                          R$ {(billToPay.billValue * 1).toFixed(2).replace('.', ',')}
                        </td>
                      ) : null}

                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          <Button onClick={() => this.openUpdateModal(billToPay)} color="primary" size="sm">
                            <FontAwesomeIcon icon="pencil-alt" />
                          </Button>
                          <Button onClick={() => this.deleteEntity(billToPay)} color="danger" size="sm">
                            <FontAwesomeIcon icon="trash" />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.billToPay.home.notFound">No Billing Xmls found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const { variableCosts, fixedCosts, billToPayList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={billToPayList && billToPayList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const { variableCosts, fixedCosts, billToPayList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel className="bill-to-pay bill-to-pay-all-in-one">
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
            {this.renderNewModal()}
            {this.renderUpdateModal()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToProps = ({ billToPay, ...storeState }: IRootState) => ({
  variableCosts: storeState.variableCost.entities,
  fixedCosts: storeState.fixedCost.entities,
  billToPayList: billToPay.entities,
  totalItems: billToPay.totalItems,
  userAccount: storeState.authentication.account,
  loading: billToPay.loading,

  // UPDATE
  billToPayEntity: billToPay.entity,
});

export const mapDispatchToProps = {
  getEntities,

  updateEntity,
  reset,

  // UPDATE
  getEntity,
  createEntity,

  // DELETE
  deleteEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(BillToPay);
