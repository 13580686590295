/* eslint complexity: ["error", 500] */
import React, { useState } from 'react';
import { Document, Page, StyleSheet, View, Text, PDFViewer } from '@react-pdf/renderer';
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table';
import { convertDateFromServer, convertDateToServer } from 'app/shared/util/date-utils';
import moment from 'moment';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  tableView: {
    border: 0,
    width: '100%',
    flexDirection: 'column',
    paddingVertical: 20,
  },
  tableNumberField: {
    textAlign: 'right',
    border: 0,
  },
  tableField: {
    textAlign: 'left',
  },
});

const buildHeader = function (patientName, observacoes, dataInicial, dataFinal, diagnostics, allergies) {
  return (
    <>
      <View style={{ width: '100%', flexDirection: 'row', paddingBottom: 5 }} fixed={true}>
        <View style={{ width: '75%' }}>
          <Text style={{ fontSize: 12, paddingVertical: 2 }}>Paciente: {patientName}</Text>
          <View style={{ width: '100%', flexDirection: 'row' }}>
            <View style={{ width: '10%' }}>
              <Text style={{ fontSize: 9, textAlign: 'right', padding: 2 }}>Diagnóstico:&nbsp;</Text>
            </View>
            <View style={{ width: '90%' }}>
              <Text style={{ fontSize: 9, textAlign: 'left', padding: 2 }}>
                {diagnostics ? (
                  diagnostics.map((diagnostic, index) => {
                    return index < diagnostics.length - 1 ? diagnostic + ' | ' : diagnostic;
                  })
                ) : (
                  <Text> </Text>
                )}
              </Text>
            </View>
          </View>

          <View style={{ width: '100%', flexDirection: 'row' }}>
            <View style={{ width: '10%' }}>
              <Text style={{ fontSize: 9, textAlign: 'right', padding: 2, color: 'blue' }}>Alergias:&nbsp;</Text>
            </View>
            <View style={{ width: '90%' }}>
              <Text style={{ fontSize: 9, textAlign: 'left', padding: 2, color: 'black' }}>
                {allergies ? (
                  allergies.map((allergy, index) => {
                    return index < allergies.length - 1 ? allergy + ' | ' : allergy;
                  })
                ) : (
                  <Text> </Text>
                )}
              </Text>
            </View>
          </View>

          {/* <Text style={{ fontSize: 9, textAlign: 'left', paddingVertical: 2, color: 'blue' }}>Observações: {observacoes}</Text> */}
        </View>
        <View style={{ width: '25%', textAlign: 'right' }}>
          <Text style={{ fontSize: 12, fontWeight: 'bold' }}>
            Período: {convertDateFromServer(dataInicial)} - {convertDateFromServer(dataFinal)}
          </Text>
          <Text style={{ fontSize: 10, paddingVertical: 3 }}>Área: Médica</Text>
          <Text
            style={{ fontSize: 10, paddingVertical: 3 }}
            render={({ pageNumber, totalPages }) => `Pág: ${pageNumber} / ${totalPages}`}
            fixed={true}
          />
        </View>
      </View>
    </>
  );
};

const getDailyTable = function (dataInicial: Date, dataFinal: Date, tableData: any, patientName, observacoes, diagnostics, allergies) {
  let diff = dataFinal.getTime() - dataInicial.getTime();
  diff = diff / (1000 * 3600 * 24);
  const weekDays = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];

  const dates = [];
  for (let i = 0; i < diff; i++) {
    const date = new Date();
    date.setDate(dataInicial.getDate() + i);
    dates.push(convertDateFromServer(date));
  }

  return (
    <>
      {[...Array(Math.ceil(diff / 7))].fill('a').map((a, index) => {
        return (
          <Page size="A4" orientation="landscape" style={styles.page} key={'page' + index}>
            {buildHeader(patientName, observacoes, dataInicial, dataFinal, diagnostics, allergies)}
            <View style={styles.tableView}>
              <Table data={tableData}>
                <TableHeader fontSize={9}>
                  <TableCell weighting={0.05} style={{ textAlign: 'center' }}>
                    <View style={{ padding: 5 }}>
                      <Text style={{ fontWeight: 'bold', padding: 5 }}>#</Text>
                    </View>
                  </TableCell>
                  <TableCell weighting={0.45} style={{ textAlign: 'left' }}>
                    <View style={{ padding: 5 }}>
                      <Text style={{ fontWeight: 'bold', padding: 5 }}>Descrição do Procedimento</Text>
                    </View>
                  </TableCell>
                  {weekDays.map((weekDay, i) => {
                    const remainder = (dataInicial.getDay() + 1 + i) % 7;
                    return (
                      <TableCell
                        key={i}
                        weighting={0.1}
                        style={{
                          textAlign: 'center',
                          fontWeight: 'bold',
                        }}
                      >
                        <View style={{ padding: 5 }}>
                          <Text>
                            {dates[index * 7 + i] !== undefined
                              ? dates[index * 7 + i] + ' ' + weekDays[remainder === 0 ? 6 : remainder - 1]
                              : ''}
                          </Text>
                        </View>
                      </TableCell>
                    );
                  })}
                </TableHeader>
                <TableBody fontSize={9}>
                  <DataTableCell
                    key={'column-0'}
                    weighting={0.05}
                    style={{ textAlign: 'center' }}
                    getContent={r => (
                      <View style={{ backgroundColor: `${r.index % 2 === 0 ? '#cff6cf' : ''}`, padding: 5, flexGrow: 1 }}>
                        <Text>{r.index}</Text>
                      </View>
                    )}
                  />
                  <DataTableCell
                    key={'column-1'}
                    weighting={0.45}
                    style={{ textAlign: 'left' }}
                    getContent={r => (
                      <View style={{ backgroundColor: `${r.index % 2 === 0 ? '#cff6cf' : ''}`, padding: 5, flexGrow: 1 }}>
                        <Text>{r.descricaoProcedimento}</Text>
                      </View>
                    )}
                  />
                  {weekDays.map((weekDay, i) => {
                    return (
                      <DataTableCell
                        key={'column-1'}
                        weighting={0.1}
                        style={{ textAlign: 'center' }}
                        getContent={r => {
                          const dataInicialObj = convertDateToServer(r.dataInicial);
                          const dataFinalObj = convertDateToServer(r.dataFinal);
                          const dataColumna = moment(dates[index * 7 + i], APP_LOCAL_DATE_FORMAT).toDate();
                          if (dataInicialObj <= dataColumna && (r.continuo || dataColumna <= dataFinalObj)) {
                            switch (r.frequencyGroup) {
                              case 'DAILY': {
                                switch (r.frequency) {
                                  case 'PONTUAL': {
                                    if (dataInicialObj.getDate() === dataColumna.getDate()) {
                                      return (
                                        <View style={{ backgroundColor: `${r.index % 2 === 0 ? '#cff6cf' : ''}`, padding: 5, flexGrow: 1 }}>
                                          <Text style={{ fontSize: 8 }}>{r.periodo}</Text>
                                        </View>
                                      );
                                    } else {
                                      return (
                                        <View style={{ backgroundColor: `${r.index % 2 === 0 ? '#cff6cf' : ''}`, padding: 5, flexGrow: 1 }}>
                                          <Text style={{ fontSize: 8 }}> </Text>
                                        </View>
                                      );
                                    }
                                  }
                                  case 'CADA48HORAS': {
                                    let diff48horas = dataColumna.getTime() - dataInicialObj.getTime();
                                    diff48horas = diff48horas / (1000 * 3600 * 24);
                                    if (diff48horas % 2 === 0) {
                                      return (
                                        <View style={{ backgroundColor: `${r.index % 2 === 0 ? '#cff6cf' : ''}`, padding: 5, flexGrow: 1 }}>
                                          <Text style={{ fontSize: 8 }}>{r.periodo}</Text>
                                        </View>
                                      );
                                    } else {
                                      return (
                                        <View style={{ backgroundColor: `${r.index % 2 === 0 ? '#cff6cf' : ''}`, padding: 5, flexGrow: 1 }}>
                                          <Text style={{ fontSize: 8 }}> </Text>
                                        </View>
                                      );
                                    }
                                  }
                                  case 'CADA72HORAS': {
                                    let diff72horas = dataColumna.getTime() - dataInicialObj.getTime();
                                    diff72horas = diff72horas / (1000 * 3600 * 24);
                                    if (diff72horas % 3 === 0) {
                                      return (
                                        <View style={{ backgroundColor: `${r.index % 2 === 0 ? '#cff6cf' : ''}`, padding: 5, flexGrow: 1 }}>
                                          <Text style={{ fontSize: 8 }}>{r.periodo}</Text>
                                        </View>
                                      );
                                    } else {
                                      return (
                                        <View style={{ backgroundColor: `${r.index % 2 === 0 ? '#cff6cf' : ''}`, padding: 5, flexGrow: 1 }}>
                                          <Text style={{ fontSize: 8 }}> </Text>
                                        </View>
                                      );
                                    }
                                  }
                                  default: {
                                    return (
                                      <View style={{ backgroundColor: `${r.index % 2 === 0 ? '#cff6cf' : ''}`, padding: 5, flexGrow: 1 }}>
                                        <Text>{r.periodo}</Text>
                                      </View>
                                    );
                                  }
                                }
                              }
                              case 'WEEKLY': {
                                switch (r.frequency) {
                                  case 'UMXSEMANA': {
                                    if (dataColumna.getDay() === dataInicialObj.getDay()) {
                                      return (
                                        <View style={{ backgroundColor: `${r.index % 2 === 0 ? '#cff6cf' : ''}`, padding: 5, flexGrow: 1 }}>
                                          <Text>{r.periodo}</Text>
                                        </View>
                                      );
                                    }
                                    return (
                                      <View style={{ backgroundColor: `${r.index % 2 === 0 ? '#cff6cf' : ''}`, padding: 5, flexGrow: 1 }}>
                                        <Text> </Text>
                                      </View>
                                    );
                                  }
                                  case 'DOISXSEMANA': {
                                    const remainder1 = dataInicialObj.getDate() % 7;
                                    const remainder2 = (dataInicialObj.getDate() + 4) % 7;
                                    if (dataColumna.getDate() % 7 === remainder1 || dataColumna.getDate() % 7 === remainder2) {
                                      return (
                                        <View style={{ backgroundColor: `${r.index % 2 === 0 ? '#cff6cf' : ''}`, padding: 5, flexGrow: 1 }}>
                                          <Text>{r.periodo}</Text>
                                        </View>
                                      );
                                    }
                                    return (
                                      <View style={{ backgroundColor: `${r.index % 2 === 0 ? '#cff6cf' : ''}`, padding: 5, flexGrow: 1 }}>
                                        <Text> </Text>
                                      </View>
                                    );
                                  }
                                  case 'TRESXSEMANA': {
                                    const remainder1 = dataInicialObj.getDate() % 7;
                                    const remainder2 = (dataInicialObj.getDate() + 2) % 7;
                                    const remainder3 = (dataInicialObj.getDate() + 4) % 7;
                                    if (
                                      dataColumna.getDate() % 7 === remainder1 ||
                                      dataColumna.getDate() % 7 === remainder2 ||
                                      dataColumna.getDate() % 7 === remainder3
                                    ) {
                                      return (
                                        <View style={{ backgroundColor: `${r.index % 2 === 0 ? '#cff6cf' : ''}`, padding: 5, flexGrow: 1 }}>
                                          <Text>{r.periodo}</Text>
                                        </View>
                                      );
                                    }
                                    return (
                                      <View style={{ backgroundColor: `${r.index % 2 === 0 ? '#cff6cf' : ''}`, padding: 5, flexGrow: 1 }}>
                                        <Text> </Text>
                                      </View>
                                    );
                                  }
                                  case 'QUATROXSEMANA': {
                                    const remainder1 = dataInicialObj.getDate() % 7;
                                    const remainder2 = (dataInicialObj.getDate() + 2) % 7;
                                    const remainder3 = (dataInicialObj.getDate() + 4) % 7;
                                    const remainder4 = (dataInicialObj.getDate() + 6) % 7;
                                    if (
                                      dataColumna.getDate() % 7 === remainder1 ||
                                      dataColumna.getDate() % 7 === remainder2 ||
                                      dataColumna.getDate() % 7 === remainder3 ||
                                      dataColumna.getDate() % 7 === remainder4
                                    ) {
                                      return (
                                        <View style={{ backgroundColor: `${r.index % 2 === 0 ? '#cff6cf' : ''}`, padding: 5, flexGrow: 1 }}>
                                          <Text>{r.periodo}</Text>
                                        </View>
                                      );
                                    }
                                    return (
                                      <View style={{ backgroundColor: `${r.index % 2 === 0 ? '#cff6cf' : ''}`, padding: 5, flexGrow: 1 }}>
                                        <Text> </Text>
                                      </View>
                                    );
                                  }
                                  case 'CINCOXSEMANA': {
                                    const remainder1 = dataInicialObj.getDate() % 7;
                                    const remainder2 = (dataInicialObj.getDate() + 2) % 7;
                                    const remainder3 = (dataInicialObj.getDate() + 3) % 7;
                                    const remainder4 = (dataInicialObj.getDate() + 4) % 7;
                                    const remainder5 = (dataInicialObj.getDate() + 6) % 7;
                                    if (
                                      dataColumna.getDate() % 7 === remainder1 ||
                                      dataColumna.getDate() % 7 === remainder2 ||
                                      dataColumna.getDate() % 7 === remainder3 ||
                                      dataColumna.getDate() % 7 === remainder4 ||
                                      dataColumna.getDate() % 7 === remainder5
                                    ) {
                                      return (
                                        <View style={{ backgroundColor: `${r.index % 2 === 0 ? '#cff6cf' : ''}`, padding: 5, flexGrow: 1 }}>
                                          <Text>{r.periodo}</Text>
                                        </View>
                                      );
                                    }
                                    return (
                                      <View style={{ backgroundColor: `${r.index % 2 === 0 ? '#cff6cf' : ''}`, padding: 5, flexGrow: 1 }}>
                                        <Text> </Text>
                                      </View>
                                    );
                                  }
                                  default: {
                                    return (
                                      <View style={{ backgroundColor: `${r.index % 2 === 0 ? '#cff6cf' : ''}`, padding: 5, flexGrow: 1 }}>
                                        <Text> DEFAULT</Text>
                                      </View>
                                    );
                                  }
                                }
                              }
                              case 'MONTHLY': {
                                switch (r.frequency) {
                                  case 'UMXMES': {
                                    if (dataColumna.getDate() === dataInicialObj.getDate()) {
                                      return (
                                        <View style={{ backgroundColor: `${r.index % 2 === 0 ? '#cff6cf' : ''}`, padding: 5, flexGrow: 1 }}>
                                          <Text>{r.periodo}</Text>
                                        </View>
                                      );
                                    }
                                    return (
                                      <View style={{ backgroundColor: `${r.index % 2 === 0 ? '#cff6cf' : ''}`, padding: 5, flexGrow: 1 }}>
                                        <Text> </Text>
                                      </View>
                                    );
                                  }
                                  case 'DOISXMES': {
                                    let diff2xmes = dataColumna.getTime() - dataInicialObj.getTime();
                                    diff2xmes = diff2xmes / (1000 * 3600 * 24);
                                    //  Dia inicial e a cada 15 dias
                                    if (dataColumna.getDate() === dataInicialObj.getDate() || diff2xmes === 15) {
                                      return (
                                        <View style={{ backgroundColor: `${r.index % 2 === 0 ? '#cff6cf' : ''}`, padding: 5, flexGrow: 1 }}>
                                          <Text>{r.periodo}</Text>
                                        </View>
                                      );
                                    }
                                    return (
                                      <View style={{ backgroundColor: `${r.index % 2 === 0 ? '#cff6cf' : ''}`, padding: 5, flexGrow: 1 }}>
                                        <Text> </Text>
                                      </View>
                                    );
                                  }
                                  case 'TRESXMES': {
                                    let diff2xmes = dataColumna.getTime() - dataInicialObj.getTime();
                                    diff2xmes = diff2xmes / (1000 * 3600 * 24);
                                    //  Dia inicial e a cada 10 dias
                                    if (dataColumna.getDate() === dataInicialObj.getDate() || diff2xmes === 10 || diff2xmes === 20) {
                                      return (
                                        <View style={{ backgroundColor: `${r.index % 2 === 0 ? '#cff6cf' : ''}`, padding: 5, flexGrow: 1 }}>
                                          <Text>{r.periodo}</Text>
                                        </View>
                                      );
                                    }
                                    return (
                                      <View style={{ backgroundColor: `${r.index % 2 === 0 ? '#cff6cf' : ''}`, padding: 5, flexGrow: 1 }}>
                                        <Text> </Text>
                                      </View>
                                    );
                                  }
                                  case 'QUATROXMES': {
                                    let diff2xmes = dataColumna.getTime() - dataInicialObj.getTime();
                                    diff2xmes = diff2xmes / (1000 * 3600 * 24);
                                    //  Dia inicial e a cada 7 dias
                                    if (
                                      dataColumna.getDate() === dataInicialObj.getDate() ||
                                      diff2xmes === 7 ||
                                      diff2xmes === 14 ||
                                      diff2xmes === 21 ||
                                      diff2xmes === 28
                                    ) {
                                      return (
                                        <View style={{ backgroundColor: `${r.index % 2 === 0 ? '#cff6cf' : ''}`, padding: 5, flexGrow: 1 }}>
                                          <Text>{r.periodo}</Text>
                                        </View>
                                      );
                                    }
                                    return (
                                      <View style={{ backgroundColor: `${r.index % 2 === 0 ? '#cff6cf' : ''}`, padding: 5, flexGrow: 1 }}>
                                        <Text> </Text>
                                      </View>
                                    );
                                  }
                                  default: {
                                    return (
                                      <View style={{ backgroundColor: `${r.index % 2 === 0 ? '#cff6cf' : ''}`, padding: 5, flexGrow: 1 }}>
                                        <Text> </Text>
                                      </View>
                                    );
                                  }
                                }
                              }
                              default: {
                                return (
                                  <View style={{ backgroundColor: `${r.index % 2 === 0 ? '#cff6cf' : ''}`, padding: 5, flexGrow: 1 }}>
                                    <Text> </Text>
                                  </View>
                                );
                              }
                            }
                          } else {
                            return (
                              <View style={{ backgroundColor: `${r.index % 2 === 0 ? '#cff6cf' : ''}`, padding: 5, flexGrow: 1 }}>
                                <Text> </Text>
                              </View>
                            );
                          }
                        }}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </View>

            <View style={{ textAlign: 'center', fontSize: 10, fontWeight: 'bold', paddingTop: 25 }}>
              <Text>__________________________________________</Text>
              <Text style={{ fontWeight: 'bold', paddingTop: 3 }}>{tableData[0].responsavel}</Text>
            </View>

            <View style={{ marginTop: 20, backgroundColor: '#cff6cf' }}>
              <Table>
                <TableHeader fontSize={8}>
                  {[...Array(7)].fill('a').map((elem, i) => {
                    const remainder = (dataInicial.getDay() + i) % 7;
                    return (
                      <TableCell
                        key={i}
                        weighting={0.2}
                        style={{
                          textAlign: 'center',
                          padding: 3,
                          fontWeight: 'bold',
                        }}
                      >
                        <View style={{ minHeight: 50 }}>
                          <Text>Visto e Carimbo Prof. Diurno</Text>
                          <Text style={{ fontWeight: 'bold' }}>
                            {dates[index * 7 + i] + ' ' + weekDays[remainder === 0 ? 6 : remainder - 1]}
                          </Text>
                        </View>
                      </TableCell>
                    );
                  })}
                </TableHeader>
              </Table>
            </View>

            <View style={{ paddingVertical: 5 }}>
              <Table>
                <TableHeader fontSize={8}>
                  {[...Array(7)].fill('a').map((elem, i) => {
                    const remainder = (dataInicial.getDay() + i) % 7;
                    return (
                      <TableCell
                        key={i}
                        weighting={0.2}
                        style={{
                          textAlign: 'center',
                          padding: 3,
                          fontWeight: 'bold',
                        }}
                      >
                        <View style={{ minHeight: 50 }}>
                          <Text>Visto e Carimbo Prof. Noturno</Text>
                          <Text style={{ fontWeight: 'bold' }}>
                            {dates[index * 7 + i] + ' ' + weekDays[remainder === 0 ? 6 : remainder - 1]}
                          </Text>
                        </View>
                      </TableCell>
                    );
                  })}
                </TableHeader>
              </Table>
            </View>
          </Page>
        );
      })}
    </>
  );
};

const getTable = function (dataInicial, dataFinal, tableData: any, patientName, observacoes, diagnostics, allergies) {
  return getDailyTable(dataInicial, dataFinal, tableData, patientName, observacoes, diagnostics, allergies);
};

export const NursingReport = (props: any) => {
  const [ready, setReady] = useState(false);
  const tableData = [];
  props.data.map((row, i) => {
    tableData.push({
      index: i + 1,
      descricaoProcedimento: row.descricao_procedimento,
      frequency: row.frequency,
      frequencyGroup: row.frequency_group,
      quantidadeDias: row.quantidade_dias,
      dataInicial: row.data_inicial,
      dataFinal: row.data_final,
      responsavel: row.responsavel,
      continuo: row.continuo,
      periodo: row.periodo,
    });
  });

  const allergies = props.data.length > 0 && props.data[0].allergies ? props.data[0].allergies.split('|') : [];
  const diagnostics = props.data.length > 0 && props.data[0].diagnostics ? props.data[0].diagnostics.split('|') : [];
  const prescriptionNo = props.data.length > 0 && props.data[0].cod ? props.data[0].cod : '';
  const patientName = props.data.length > 0 && props.data[0].patient_name ? props.data[0].patient_name : '';
  const observacoes = props.data.length > 0 && props.data[0].observacoes ? props.data[0].observacoes : '';
  const infos = props?.infos?.patDrPrescrProcd;

  if (ready === false) {
    setTimeout(() => {
      setReady(true);
    }, 2);
    return <></>;
  }

  return (
    <PDFViewer style={{ width: '100%', minHeight: '400px' }}>
      <Document>
        {getTable(props.dataInicial, props.dataFinal, Object.values(tableData), patientName, observacoes, diagnostics, allergies)}
      </Document>
    </PDFViewer>
  );
};
