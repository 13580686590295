/* eslint complexity: ["error", 500] */
import axios from 'axios';

import { IPaginationBaseState, IPayloadResult } from 'react-jhipster';

import { IPayload } from 'react-jhipster/src/type/redux-action.type';

import { BASE_API_VERSION_PATH } from 'app/config/constants';

import { cleanEntity, jsonParse } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ISupplierTableMaterial, defaultValue } from 'app/shared/model/supplier-table-material.model';

export declare type ICrudPutAction<T> = (data?: T, listFiltersPage?) => IPayload<T> | IPayloadResult<T>;
export declare type ICrudGetAction<T> = (id: string | number, selectFields?: string) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const ACTION_TYPES = {
  FETCH_SUPPLIERTABLEMATERIAL_LIST_EXPORT: 'supplierTableMaterial/FETCH_SUPPLIERTABLEMATERIAL_LIST_EXPORT',
  FETCH_SUPPLIERTABLEMATERIAL_LIST: 'supplierTableMaterial/FETCH_SUPPLIERTABLEMATERIAL_LIST',
  FETCH_SUPPLIERTABLEMATERIAL: 'supplierTableMaterial/FETCH_SUPPLIERTABLEMATERIAL',
  FETCH_SUPPLIERTABLEMATERIAL_OFFSET: 'supplierTableMaterial/FETCH_SUPPLIERTABLEMATERIAL_OFFSET',
  CREATE_SUPPLIERTABLEMATERIAL: 'supplierTableMaterial/CREATE_SUPPLIERTABLEMATERIAL',
  UPDATE_SUPPLIERTABLEMATERIAL: 'supplierTableMaterial/UPDATE_SUPPLIERTABLEMATERIAL',
  CREATE_SUPPLIERTABLEMATERIAL_AND_GO_LIST: 'supplierTableMaterial/CREATE_SUPPLIERTABLEMATERIAL_AND_GO_LIST',
  UPDATE_SUPPLIERTABLEMATERIAL_AND_GO_LIST: 'supplierTableMaterial/UPDATE_SUPPLIERTABLEMATERIAL_AND_GO_LIST',
  DELETE_SUPPLIERTABLEMATERIAL: 'supplierTableMaterial/DELETE_SUPPLIERTABLEMATERIAL',
  RESET: 'supplierTableMaterial/RESET',
  SHOW_MODAL: 'supplierTableMaterial/SHOW_MODAL',
};

const initialState = {
  loading: false,
  openModal: {
    list: false,
    view: false,
    detail: false,
  },
  errorMessage: null,
  entities: [] as ReadonlyArray<ISupplierTableMaterial>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const actionPendind = [];
let actualState = initialState;

export const convertEntityBuffers = entity => ({
  ...entity,
});

export type SupplierTableMaterialState = Readonly<typeof initialState>;

export interface ISupplierTableMaterialBaseState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  listCheckedID?: Array<number>;
  isCheckedAll?: boolean;
  urlBack?: string;
  extraFilters?: any;
  baseFilters: any;
  offset: number;
  medicineMaterialId?: any;
  medicineMaterialStartFilter?: any;
  description?: any;
  presentationId?: any;
  presentationStartFilter?: any;
  price?: any;
  supplierTableId?: any;
  supplierTableStartFilter?: any;
}

export interface IFieldsBase extends ISupplierTableMaterialBaseState, IPaginationBaseState {}
export interface ISupplierTableMaterialUpdateState {
  listCheckedModalSuperSelectID?: Array<number>;
  listCheckedModalSuperSelectFilters?: any;
  listCheckedModalSuperEntities?: any;
  modalSuperSelect?: string;
  urlBack?: string;
  functionEmbebed?: any[];
  fieldsBase: IFieldsBase;
  descriptionSelectValue?: any;
  descriptionStartSelectOptions?: any;
  priceSelectValue?: any;
  priceStartSelectOptions?: any;

  supplierTableSelectValue?: any;
  supplierTableStartSelectOptions?: any;

  medicineMaterialSelectValue?: any;
  medicineMaterialStartSelectOptions?: any;

  presentationSelectValue?: any;
  presentationStartSelectOptions?: any;

  isNew: boolean;
  supplierTableName?: any;

  supplierTableId?: any;
  medicineMaterialName?: any;

  medicineMaterialId?: any;
  presentationName?: any;

  presentationId?: any;
}

// Reducer

export default (state: SupplierTableMaterialState = initialState, action): SupplierTableMaterialState => {
  actualState = state;
  if (
    action.type.includes('_PENDING') &&
    !actionPendind.includes(action.type.replace('_PENDING', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_PENDING', ''))
  ) {
    actionPendind.push(action.type.replace('_PENDING', ''));
  }

  if (
    action.type.includes('_FULFILLED') &&
    actionPendind.includes(action.type.replace('_FULFILLED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_FULFILLED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_FULFILLED', '')), 1);
  }

  if (
    action.type.includes('_REJECTED') &&
    actionPendind.includes(action.type.replace('_REJECTED', '')) &&
    Object.values(ACTION_TYPES).includes(action.type.replace('_REJECTED', ''))
  ) {
    actionPendind.splice(actionPendind.indexOf(action.type.replace('_REJECTED', '')), 1);
  }

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_SUPPLIERTABLEMATERIAL_LIST_EXPORT):
    case REQUEST(ACTION_TYPES.FETCH_SUPPLIERTABLEMATERIAL_LIST):
    case REQUEST(ACTION_TYPES.FETCH_SUPPLIERTABLEMATERIAL):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_SUPPLIERTABLEMATERIAL):
    case REQUEST(ACTION_TYPES.UPDATE_SUPPLIERTABLEMATERIAL):
    case REQUEST(ACTION_TYPES.CREATE_SUPPLIERTABLEMATERIAL_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.UPDATE_SUPPLIERTABLEMATERIAL_AND_GO_LIST):
    case REQUEST(ACTION_TYPES.DELETE_SUPPLIERTABLEMATERIAL):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_SUPPLIERTABLEMATERIAL_LIST_EXPORT):
    case FAILURE(ACTION_TYPES.FETCH_SUPPLIERTABLEMATERIAL_LIST):
    case FAILURE(ACTION_TYPES.FETCH_SUPPLIERTABLEMATERIAL):
    case FAILURE(ACTION_TYPES.CREATE_SUPPLIERTABLEMATERIAL):
    case FAILURE(ACTION_TYPES.UPDATE_SUPPLIERTABLEMATERIAL):
    case FAILURE(ACTION_TYPES.CREATE_SUPPLIERTABLEMATERIAL_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.UPDATE_SUPPLIERTABLEMATERIAL_AND_GO_LIST):
    case FAILURE(ACTION_TYPES.DELETE_SUPPLIERTABLEMATERIAL):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_SUPPLIERTABLEMATERIAL_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_SUPPLIERTABLEMATERIAL_OFFSET):
      if (action.payload.data && action.payload.data.length > 0) {
        return {
          ...state,
          loading: false,
          entity: convertEntityBuffers(action.payload.data[0]),
        };
      }
      return {
        ...state,
        loading: false,
      };

    case SUCCESS(ACTION_TYPES.FETCH_SUPPLIERTABLEMATERIAL):
      return {
        ...state,
        loading: false,
        entity: convertEntityBuffers(action.payload.data),
      };
    case SUCCESS(ACTION_TYPES.CREATE_SUPPLIERTABLEMATERIAL):
    case SUCCESS(ACTION_TYPES.UPDATE_SUPPLIERTABLEMATERIAL):
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_SUPPLIERTABLEMATERIAL_AND_GO_LIST):
    case SUCCESS(ACTION_TYPES.UPDATE_SUPPLIERTABLEMATERIAL_AND_GO_LIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_SUPPLIERTABLEMATERIAL):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };

    case ACTION_TYPES.SHOW_MODAL: {
      const openModal = state.openModal;
      openModal[action.payload.type] = action.payload.show;
      return {
        ...state,
        openModal,
      };
    }
    default:
      return state;
  }
};

const apiUrl = BASE_API_VERSION_PATH + 'supplier-table-materials';

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

// Actions

// Actions
export type ICrudGetAllActionSupplierTableMaterial<T> = (
  medicineMaterialId?: any,
  description?: any,
  presentationId?: any,
  price?: any,
  supplierTableId?: any,
  extraFilters?: {},
  page?: number,
  size?: number,
  sort?: string,
  selectFields?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getEntitiesFilter = (filters?, page?, size?, sort?, selectFields = '') => {
  const requestUrl = `${apiUrl}?page=${page >= 0 ? page : 0}&size=${size >= 0 ? size : 20}&sort=${sort ? sort : 'id,asc'}&`;
  const requestFilters = filters.map(v => v.column + '.' + v.operation + '=' + v.value).join('&');
  return {
    type: ACTION_TYPES.FETCH_SUPPLIERTABLEMATERIAL_LIST,
    payload: axios.get<ISupplierTableMaterial>(`${requestUrl}${requestFilters}`, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getAllEntities = (filters?, sort?, selectFields = '') => {
  if (
    (actualState.totalItems > 0 && actualState.entities.length === actualState.totalItems) ||
    actionPendind.includes(ACTION_TYPES.FETCH_SUPPLIERTABLEMATERIAL_LIST)
  ) {
    return {
      type: ACTION_TYPES.FETCH_SUPPLIERTABLEMATERIAL_LIST + '_FROM_CACHE',
      payload: new Promise(function (resolve, reject) {
        resolve('done');
      }),
    };
  }

  const idsRequest = filters && typeof filters['ids'] !== 'undefined' && filters['ids'] ? `id.in=${filters['ids'].join(',')}&` : '';
  const medicineMaterialRequest =
    filters && typeof filters['medicineMaterialId'] !== 'undefined' && filters['medicineMaterialId']
      ? `medicineMaterial.id.in=${filters['medicineMaterialId']}&`
      : '';
  const descriptionRequest =
    filters && typeof filters['description'] !== 'undefined' && filters['description']
      ? `description.contains=${filters['description']}&`
      : '';
  const presentationRequest =
    filters && typeof filters['presentationId'] !== 'undefined' && filters['presentationId']
      ? `presentation.id.in=${filters['presentationId']}&`
      : '';
  const priceRequest = filters && typeof filters['price'] !== 'undefined' && filters['price'] ? `price.contains=${filters['price']}&` : '';
  const supplierTableRequest =
    filters && typeof filters['supplierTableId'] !== 'undefined' && filters['supplierTableId']
      ? `supplierTable.id.in=${filters['supplierTableId']}&`
      : '';

  const requestUrl = `${apiUrl}${`?page=${0}&size=${1000000}&sort=${sort ? sort : 'id,asc'}&`}`;
  return {
    type: ACTION_TYPES.FETCH_SUPPLIERTABLEMATERIAL_LIST,
    payload: axios.get<ISupplierTableMaterial>(
      `${requestUrl}${idsRequest}${medicineMaterialRequest}${descriptionRequest}${presentationRequest}${priceRequest}${supplierTableRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntities: ICrudGetAllActionSupplierTableMaterial<ISupplierTableMaterial> = (
  medicineMaterialId,
  description,
  presentationId,
  price,
  supplierTableId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const medicineMaterialRequest = medicineMaterialId ? `medicineMaterial.id.in=${medicineMaterialId}&` : '';
  const descriptionRequest = description ? `description.contains=${description}&` : '';
  const presentationRequest = presentationId ? `presentation.id.in=${presentationId}&` : '';
  const priceRequest = price ? `price.contains=${price}&` : '';
  const supplierTableRequest = supplierTableId ? `supplierTable.id.in=${supplierTableId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_SUPPLIERTABLEMATERIAL_LIST,
    payload: axios.get<ISupplierTableMaterial>(
      `${requestUrl}${extraFiltersRequest}${medicineMaterialRequest}${descriptionRequest}${presentationRequest}${priceRequest}${supplierTableRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntityOffset: any = (
  medicineMaterialId,
  description,
  presentationId,
  price,
  supplierTableId,
  extraFilters,
  offset,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const medicineMaterialRequest = medicineMaterialId ? `medicineMaterial.id.in=${medicineMaterialId}&` : '';
  const descriptionRequest = description ? `description.contains=${description}&` : '';
  const presentationRequest = presentationId ? `presentation.id.in=${presentationId}&` : '';
  const priceRequest = price ? `price.contains=${price}&` : '';
  const supplierTableRequest = supplierTableId ? `supplierTable.id.in=${supplierTableId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${offset}&size=${1}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_SUPPLIERTABLEMATERIAL_OFFSET,
    payload: axios.get<ISupplierTableMaterial>(
      `${requestUrl}${extraFiltersRequest}${medicineMaterialRequest}${descriptionRequest}${presentationRequest}${priceRequest}${supplierTableRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const getEntity: ICrudGetAction<ISupplierTableMaterial> = (id, selectFields = '') => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_SUPPLIERTABLEMATERIAL,
    payload: axios.get<ISupplierTableMaterial>(requestUrl, {
      headers: {
        'Select-Fields': selectFields,
      },
    }),
  };
};

export const getEntitiesExport: ICrudGetAllActionSupplierTableMaterial<ISupplierTableMaterial> = (
  medicineMaterialId,
  description,
  presentationId,
  price,
  supplierTableId,
  extraFilters,
  page,
  size,
  sort,
  selectFields = ''
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map(v => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const medicineMaterialRequest = medicineMaterialId ? `medicineMaterial.id.in=${medicineMaterialId}&` : '';
  const descriptionRequest = description
    ? Array.isArray(status)
      ? `description.in=${description.join(',')}&`
      : `description.contains=${description}&`
    : '';
  const presentationRequest = presentationId ? `presentation.id.in=${presentationId}&` : '';
  const priceRequest = price ? (Array.isArray(status) ? `price.in=${price.join(',')}&` : `price.contains=${price}&`) : '';
  const supplierTableRequest = supplierTableId ? `supplierTable.id.in=${supplierTableId}&` : '';

  const requestUrl = `${apiUrl}${sort ? `?page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'}`;
  return {
    type: ACTION_TYPES.FETCH_SUPPLIERTABLEMATERIAL_LIST,
    payload: axios.get<ISupplierTableMaterial>(
      `${requestUrl}${extraFiltersRequest}${medicineMaterialRequest}${descriptionRequest}${presentationRequest}${priceRequest}${supplierTableRequest}`,
      {
        headers: {
          'Select-Fields': selectFields,
        },
      }
    ),
  };
};

export const createEntity: ICrudPutAction<ISupplierTableMaterial> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.CREATE_SUPPLIERTABLEMATERIAL_AND_GO_LIST
        : ACTION_TYPES.CREATE_SUPPLIERTABLEMATERIAL,
    payload: axios.post(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const updateEntity: ICrudPutAction<ISupplierTableMaterial> = (entity, listFiltersPage) => async dispatch => {
  const nEntity = { ...entity };
  const result = await dispatch({
    type:
      listFiltersPage && listFiltersPage['reloadList'] !== false
        ? ACTION_TYPES.UPDATE_SUPPLIERTABLEMATERIAL_AND_GO_LIST
        : ACTION_TYPES.UPDATE_SUPPLIERTABLEMATERIAL,
    payload: axios.put(apiUrl, cleanEntity(nEntity)),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const deleteEntity: any = (id, listFiltersPage) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_SUPPLIERTABLEMATERIAL,
    payload: axios.delete(requestUrl),
  });
  if (listFiltersPage && listFiltersPage['reloadList'] !== false) {
    await dispatch(reset());
    dispatch(getEntities(...listFiltersPage));
  }
  return result;
};

export const showModal = view => ({
  payload: { type: view, show: true },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const hideModal = view => ({
  payload: { type: view, show: false },
  type: ACTION_TYPES.SHOW_MODAL,
});

export const getUrlBack = (location): string => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  let urlBack = _urlBase.searchParams.get('urlBack') || '';
  urlBack = urlBack.trim();
  urlBack = urlBack.startsWith('/') ? urlBack.slice(1) : urlBack;
  urlBack = urlBack.endsWith('/') ? urlBack.slice(0, -1) : urlBack;
  return urlBack ? '/' + urlBack + '/' : '';
};

export const getSupplierTableMaterialState = (location): ISupplierTableMaterialBaseState => {
  const _urlBase = new URL(`http://localhost${location.search}`); // using a dummy url for parsing
  const extraFilters = jsonParse(_urlBase.searchParams.get('extraFilters') || '{}');
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;

  const medicineMaterialId = _urlBase.searchParams.get('medicineMaterial') || '';
  const description = _urlBase.searchParams.get('description') || '';
  const presentationId = _urlBase.searchParams.get('presentation') || '';
  const price = _urlBase.searchParams.get('price') || '';
  const supplierTableId = _urlBase.searchParams.get('supplierTable') || '';
  return {
    baseFilters,
    extraFilters,
    offset,
    medicineMaterialId: medicineMaterialId
      ? medicineMaterialId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] }))
      : [],
    description,
    presentationId: presentationId ? presentationId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
    price,
    supplierTableId: supplierTableId ? supplierTableId.split(',').map(v => ({ value: v.split('<->')[0], label: v.split('<->')[1] })) : [],
  };
};

export const getEntityFiltersURL = (state, offset = null) => {
  return (
    'baseFilters=' +
    state.baseFilters +
    '&extraFilters=' +
    encodeURI(JSON.stringify(state.extraFilters)) +
    '&page=' +
    state.activePage +
    '&' +
    'size=' +
    state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    state.sort +
    ',' +
    state.order +
    '&' +
    (state.medicineMaterialId ? 'medicineMaterial=' + state.medicineMaterialId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.description ? 'description=' + state.description + '&' : '') +
    (state.presentationId ? 'presentation=' + state.presentationId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    (state.price ? 'price=' + state.price + '&' : '') +
    (state.supplierTableId ? 'supplierTable=' + state.supplierTableId.map(v => v.value + '<->' + v.label).join(',') + '&' : '') +
    ''
  );
};
