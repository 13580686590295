/* eslint complexity: ["error", 500] */
import React from 'react';

import { connect } from 'react-redux';

import Select from 'react-select';
import { toast } from 'react-toastify';

import { Link, RouteComponentProps } from 'react-router-dom';

import { Button, Col, Row, Table, Label, UncontrolledCollapse, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { JhiItemCount } from 'react-jhipster';
import { Translate, translate } from 'app/config/translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

import { Panel, PanelHeader, PanelBody, PanelFooter } from 'app/shared/layout/panel/panel';

import { IRootState } from 'app/shared/reducers';
import {
  updateEntity,
  getUrlBack,
  getClinicalIndicatorState,
  IClinicalIndicatorBaseState,
  getEntityFiltersURL,
  getEntities,
  IClinicalIndicatorUpdateState as IBaseUpdateState,
  reset,
  getEntity,
  deleteEntity,
  createEntity,
} from './clinical-indicator.reducer';

import { ITEMS_PER_PAGE, IPaginationBaseState, getSortState } from 'app/shared/util/pagination.constants';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/esm/locale';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

registerLocale('pt', pt);
const MySwal = withReactContent(Swal);

export interface IClinicalIndicatorProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
  entityEmbebedPai?: any;
}

export interface IClinicalIndicatorState extends IClinicalIndicatorBaseState, IPaginationBaseState, IBaseUpdateState {
  showModalForm?: boolean;
  showModalEdit?: number;
  showModalEditView?: boolean;
}

export class ClinicalIndicator extends React.Component<IClinicalIndicatorProps, IClinicalIndicatorState> {
  protected myFormRef: any;
  protected myFormRefNew: any;
  protected myFormRefEdit: any;

  nameFileInput: any;

  unityFileInput: any;

  constructor(props: IClinicalIndicatorProps) {
    super(props);
    this.state = {
      listCheckedModalSuperSelectID: [],
      listCheckedModalSuperSelectFilters: {},
      modalSuperSelect: '',
      listCheckedID: [],
      isCheckedAll: false,
      showModalForm: false,
      showModalEdit: null,
      urlBack: getUrlBack(this.props.location),
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      ...getClinicalIndicatorState(this.props.location),
      patientClinicalIndicatorSelectValue: null,

      medicalRecordSelectValue: null,

      nameSelectValue: null,
      unitySelectValue: null,
      fieldsBase: {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        ...getClinicalIndicatorState(this.props.location),
      },
      idsmedicalRecord: [],
      isNew: false,
    };
  }

  handleInputChange(id) {
    const listCheckedID = this.state.listCheckedID;
    if (listCheckedID.includes(id)) {
      this.removeAllArrayItem(listCheckedID, id);
      this.setState({ isCheckedAll: false });
    } else {
      listCheckedID.push(id);
    }
    this.setState({ listCheckedID });
  }

  handleInputAllChange() {
    this.setState({ isCheckedAll: !this.state.isCheckedAll });
    this.setState({ listCheckedID: this.props.clinicalIndicatorList.map(clinicalIndicator => clinicalIndicator.id) });
  }
  handleInputAllThisItens() {
    if (this.state.listCheckedID.length === this.state.itemsPerPage || this.state.listCheckedID.length === this.props.totalItems) {
      this.setState({ listCheckedID: [] });
      this.setState({ isCheckedAll: false });
    } else {
      this.setState({ listCheckedID: this.props.clinicalIndicatorList.map(clinicalIndicator => clinicalIndicator.id) });
    }
  }

  removeAllArrayItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  removeEntitySelected() {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  }

  saveEntityMany = () => {
    if (!this.state.isCheckedAll) {
      // this.saveEntityFild(this.state.listCheckedID, interesse, formSemInteresse);
    } else {
      // const {estado, activePage, itemsPerPage, sort, order, activeTab, comarca, advogados, pesquisa, numeroProcesso, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal} = this.state;
      // this.props.updateInteresseAllEntity(estado, activePage - 1, itemsPerPage, `${sort},${order}`, activeTab, comarca, numeroProcesso, advogados,  pesquisa, assunto, distribuicaoInicial, distribuicaoFinal, valorInicial, valorFinal, moeda, cnpj, valorAtualizadoInicial, valorAtualizadoFinal, vara, movimentacao, incluirMovimentacoesAll, movimentacaoInicial, movimentacaoFinal, interesse, formSemInteresse);
    }
    this.setState({ listCheckedID: [] });
  };

  UNSAFE_componentWillMount() {
    this.getEntities();
  }

  cancelFilters = () => {
    this.setState(
      {
        name: '',
        unity: '',
      },
      () => this.sortEntities()
    );
  };

  filterEntity = (event, errors, values) => {
    this.setState(
      {
        ...this.state,
        ...values,
        activePage: 0,
      },
      () => this.sortEntities()
    );
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop,
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(this.props.location.pathname + '?' + getEntityFiltersURL(this.state));
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    this.props.reset();

    const selectFieldsList = ['name', 'unity'];
    const { name, unity, extraFilters, activePage, itemsPerPage, sort, order } = this.state;
    return this.props.getEntities(name, unity, extraFilters, activePage - 1, itemsPerPage, `${sort},${order}`, selectFieldsList.join(','));
  };

  updateEntity = entity => {
    const selectFieldsList = ['name', 'unity'];

    const { name, unity, extraFilters, activePage, itemsPerPage, sort, order } = this.state;
    this.props.updateEntity(entity, [
      name,
      unity,
      extraFilters,
      activePage - 1,
      itemsPerPage,
      `${sort},${order}`,
      selectFieldsList.join(','),
    ]);
  };

  saveEntity = (event: any, errors: any, values: any, isNew: any) => {
    if (!this.state.nameSelectValue) {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.clinicalIndicator.name') }));
      return false;
    }

    const selectFieldsList = ['name', 'unity'];

    if (errors.length === 0) {
      const { clinicalIndicatorEntity } = this.props;
      const _entity = {
        ...clinicalIndicatorEntity,
        // ...values,

        name: this.state.nameSelectValue,
        unity: this.state.unitySelectValue === null ? null : this.state.unitySelectValue,
      };
      const entity = _entity;

      const { name, unity, extraFilters, activePage, itemsPerPage, sort, order } = this.state.fieldsBase;

      if (isNew) {
        new Promise(resolve => {
          resolve(
            this.props.createEntity(entity, [
              name,
              unity,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      } else {
        new Promise(resolve => {
          resolve(
            this.props.updateEntity(entity, [
              name,
              unity,
              extraFilters,
              activePage - 1,
              itemsPerPage,
              `${sort},${order}`,
              selectFieldsList.join(','),
            ])
          );
        }).then(resultEntity => {
          const idEntity =
            resultEntity && resultEntity['value'] && resultEntity['value']['data'] && resultEntity['value']['data']['id']
              ? resultEntity['value']['data']['id']
              : -1;
        });
      }
      this.setState({ showModalForm: false });
      this.setState({ showModalEdit: null });
    } else {
      toast.error(translate('generadorApp.validators.required', { fieldName: translate('generadorApp.specialty.' + errors[0]) }));
    }
  };

  componentDidUpdate(prevProps) {}

  deleteEntity(clinicalIndicatorEntity) {
    MySwal.fire({
      title: <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>,
      html: (
        <Translate contentKey="generadorApp.clinicalIndicator.delete.question" interpolate={{ id: clinicalIndicatorEntity.id }}>
          Are you sure you want to delete this ClinicalIndicator?
        </Translate>
      ),
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#e70015',
      cancelButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="ban" />
          &nbsp; <Translate contentKey="generadorApp.clinicalIndicator.btnCancel">Cancel</Translate>
        </>
      ),
      confirmButtonText: (
        <>
          {' '}
          <FontAwesomeIcon icon="trash" />
          &nbsp;<Translate contentKey="generadorApp.clinicalIndicator.btnDelete">Delete</Translate>
        </>
      ),
    }).then(async response => {
      if (response.isConfirmed) {
        await this.props.deleteEntity(clinicalIndicatorEntity.id, { reloadList: false });
        this.getEntities();
        MySwal.fire({
          title: <Translate contentKey="entity.delete.title.confirmed">Confirmed delete operation</Translate>,
          icon: 'success',
        });
      }
    });
  }

  renderNewModal() {
    return (
      <Modal size={'xl'} isOpen={this.state.showModalForm} toggle={() => this.setState({ showModalForm: false })}>
        <ModalHeader toggle={() => this.setState({ showModalForm: false })}>{this.renderHeaderUpdate({}, true)}</ModalHeader>
        <AvForm
          ref={el => (this.myFormRefNew = el)}
          model={{}}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, true)}
        >
          <ModalBody>{this.renderBodyUpdate({}, true)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalForm: false })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.clinicalIndicator.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefNew && this.myFormRefNew.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.clinicalIndicator.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    );
  }

  getFullEntityFromState(v) {
    return {
      id: v.id,
      name: this.state.nameSelectValue,
      unity: this.state.unitySelectValue,
      patientClinicalIndicator: this.state.patientClinicalIndicatorSelectValue,
      medicalRecord: this.state.medicalRecordSelectValue,
    };
  }
  getFullEntitySelectValue(v) {
    const p: any = null;

    const patientClinicalIndicatorEntity = v.patientClinicalIndicator
      ? v.patientClinicalIndicator.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' }))
      : v.patientClinicalIndicator;

    const medicalRecordEntity = v.medicalRecords
      ? v.medicalRecords.filter(p1 => p1).map(p1 => ({ value: p1.id, label: p1['id'] ? p1.id : '' }))
      : v.medicalRecords;

    return {
      nameSelectValue: v.name, // name,
      unitySelectValue: v.unity, // unity,
      patientClinicalIndicatorSelectValue: patientClinicalIndicatorEntity,
      medicalRecordSelectValue: medicalRecordEntity,
    };
  }

  openUpdateModal(v, edit = true) {
    this.props.getEntity(v.id);
    this.setState({
      showModalEdit: v.id,
      showModalEditView: edit,
      ...this.getFullEntitySelectValue(v),
    });
  }

  renderUpdateModal() {
    const { clinicalIndicatorEntity, entityEmbebedPai } = this.props;
    return clinicalIndicatorEntity && clinicalIndicatorEntity.id ? (
      <Modal
        size={'xl'}
        isOpen={this.state.showModalEdit === clinicalIndicatorEntity.id}
        toggle={() => this.setState({ showModalEdit: null })}
      >
        <ModalHeader toggle={() => this.setState({ showModalEdit: null })}>
          {this.renderHeaderUpdate(clinicalIndicatorEntity, false)}
        </ModalHeader>
        <AvForm
          ref={el => (this.myFormRefEdit = el)}
          model={{
            ...clinicalIndicatorEntity,
          }}
          onSubmit={(event, errors, values) => this.saveEntity(event, errors, values, false)}
        >
          <ModalBody>{this.renderBodyUpdate(clinicalIndicatorEntity, false)}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.setState({ showModalEdit: null })}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="generadorApp.clinicalIndicator.btnCancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              onClick={() => this.myFormRefEdit && this.myFormRefEdit.submit()}
              className="float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="generadorApp.clinicalIndicator.btnSave">Save</Translate>
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    ) : (
      <> </>
    );
  }

  renderHeaderUpdate(clinicalIndicatorEntity, isNew) {
    const { entityEmbebedPai, patientClinicalIndicators, medicalRecords, loading } = this.props;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <div id="page-heading">
          <span className="page-header ml-3">
            {isNew ? (
              <Translate contentKey="generadorApp.clinicalIndicator.home.createLabel">Create a ClinicalIndicator</Translate>
            ) : (
              <Translate contentKey="generadorApp.clinicalIndicator.home.editLabel">Edit a ClinicalIndicator</Translate>
            )}
          </span>
        </div>
      </>
    );
  }
  renderBodyUpdate(clinicalIndicatorEntity, isNew) {
    const { entityEmbebedPai, patientClinicalIndicators, medicalRecords, loading } = this.props;

    const baseFilters = this.state.fieldsBase && this.state.fieldsBase['baseFilters'] ? this.state.fieldsBase['baseFilters'] : null;

    return (
      <>
        <Row className="justify-content-center">
          <Col md="11">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {!isNew ? (
                  <AvGroup>
                    <Row>
                      {/*
              <Col md="3">
              <Label className="mt-2 label-single-line"for="clinical-indicator-id">
                <Translate contentKey="generadorApp.clinicalIndicator.field_id">ID</Translate>
              </Label>
              </Col> */}
                      <Col md="12">
                        <AvInput id="clinical-indicator-id" type="hidden" className="form-control" name="id" required readOnly />
                      </Col>
                    </Row>
                  </AvGroup>
                ) : null}
                <Row>
                  {baseFilters !== 'name' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="nameLabel" for="clinical-indicator-name">
                              <Translate contentKey="generadorApp.clinicalIndicator.name">Name</Translate>(*)
                            </Label>
                          </Col>
                          <Col md="12">
                            <input
                              onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                              value={this.state.nameSelectValue ? this.state.nameSelectValue : ''}
                              id="clinical-indicator-name"
                              type="text"
                              name="name"
                              className={'form-control'}
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ nameSelectValue: evt.target.value })}
                      type="hidden"
                      name="name"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}

                  {baseFilters !== 'unity' ? (
                    <Col md="6">
                      <AvGroup>
                        <Row>
                          <Col md="12">
                            <Label className="mt-2 label-single-line" id="unityLabel" for="clinical-indicator-unity">
                              <Translate contentKey="generadorApp.clinicalIndicator.unity">Name</Translate>
                            </Label>
                          </Col>
                          <Col md="12">
                            <Select
                              id="clinical-indicator-unity"
                              className={'css-select-control'}
                              value={
                                this.state.unitySelectValue
                                  ? {
                                      value: this.state.unitySelectValue,
                                      label: translate('generadorApp.MeasurementUnit.' + this.state.unitySelectValue),
                                    }
                                  : { value: '', label: translate('generadorApp.clinicalIndicator.unity') }
                              }
                              options={[
                                { value: 'PORCENTO', label: translate('generadorApp.MeasurementUnit.PORCENTO') },
                                { value: 'BPM', label: translate('generadorApp.MeasurementUnit.BPM') },
                                { value: 'MGDL', label: translate('generadorApp.MeasurementUnit.MGDL') },
                                { value: 'MMHG', label: translate('generadorApp.MeasurementUnit.MMHG') },
                                { value: 'MPM', label: translate('generadorApp.MeasurementUnit.MPM') },
                                { value: 'GRAUSCELCIUS', label: translate('generadorApp.MeasurementUnit.GRAUSCELCIUS') },
                              ]}
                              onChange={(options: any) => this.setState({ unitySelectValue: options['value'] })}
                              name="unity"
                            />
                          </Col>
                        </Row>
                      </AvGroup>
                    </Col>
                  ) : (
                    <AvInput
                      onChange={evt => this.setState({ unitySelectValue: evt.target.value })}
                      type="hidden"
                      name="unity"
                      value={this.state.fieldsBase[baseFilters] ? this.state.fieldsBase[baseFilters] : ''}
                    />
                  )}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }

  renderHeader() {
    const { patientClinicalIndicators, medicalRecords, clinicalIndicatorList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div id="page-heading">
          <span className="page-header">
            <Translate contentKey="generadorApp.clinicalIndicator.home.title">Clinical indicators</Translate>
          </span>
          <Button id="togglerFilterClinicalIndicator" color="primary" className="float-right jh-create-entity">
            <Translate contentKey="generadorApp.clinicalIndicator.home.btn_filter_open">Filters</Translate>
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </Button>{' '}
          &nbsp;
          {hasAnyAuthority(props.userAccount, ['clinical-indicator'], 'resgister') ? (
            <Button
              onClick={() =>
                this.setState({
                  showModalForm: true,
                  nameSelectValue: null,
                  unitySelectValue: null,

                  patientClinicalIndicatorStartSelectOptions: undefined,
                  patientClinicalIndicatorSelectValue: null,

                  medicalRecordStartSelectOptions: undefined,
                  medicalRecordSelectValue: null,
                  isNew: true,
                })
              }
              color="primary"
              className="btn btn-primary float-right jh-create-entity"
            >
              <FontAwesomeIcon icon="plus" />
              &nbsp;
              <Translate contentKey="generadorApp.clinicalIndicator.btnNewItem">Create new</Translate>
            </Button>
          ) : (
            <></>
          )}{' '}
          &nbsp;
        </div>

        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <Translate contentKey="global.menu.home">Home</Translate>
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Translate contentKey="generadorApp.clinicalIndicator.home.title">Clinical indicators</Translate>
          </li>
        </ol>
      </>
    );
  }

  renderFilter() {
    const { patientClinicalIndicators, medicalRecords, clinicalIndicatorList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <UncontrolledCollapse toggler="#togglerFilterClinicalIndicator">
          <CardBody>
            <AvForm ref={el => (this.myFormRef = el)} id="form-filter" onSubmit={this.filterEntity}>
              <div className="row mt-1 ml-3 mr-3">
                {this.state.baseFilters !== 'name' ? (
                  <Col md="3" className="col-filter-clinical-indicator-name">
                    <Row className="mr-1 mt-1">
                      <Label id="nameLabel" for="clinical-indicator-name">
                        <Translate contentKey="generadorApp.clinicalIndicator.name">Name</Translate>
                      </Label>

                      <AvInput type="text" name="name" id="clinical-indicator-name" value={this.state.name} />
                    </Row>
                  </Col>
                ) : null}

                {this.state.baseFilters !== 'unity' ? (
                  <Col md="3" className="col-filter-clinical-indicator-unity">
                    <Row className="mr-1 mt-1">
                      <Label id="unityLabel" for="clinical-indicator-unity">
                        <Translate contentKey="generadorApp.clinicalIndicator.unity">Name</Translate>
                      </Label>
                      <Select
                        id="clinical-indicator-unity"
                        className={'css-select-control'}
                        value={
                          this.state.unity
                            ? { value: this.state.unity, label: translate('generadorApp.MeasurementUnit.' + this.state.unity) }
                            : { value: '', label: translate('generadorApp.clinicalIndicator.unity') }
                        }
                        options={[
                          { value: '', label: translate('generadorApp.clinicalIndicator.unity') },
                          { value: 'PORCENTO', label: translate('generadorApp.MeasurementUnit.PORCENTO') },
                          { value: 'BPM', label: translate('generadorApp.MeasurementUnit.BPM') },
                          { value: 'MGDL', label: translate('generadorApp.MeasurementUnit.MGDL') },
                          { value: 'MMHG', label: translate('generadorApp.MeasurementUnit.MMHG') },
                          { value: 'MPM', label: translate('generadorApp.MeasurementUnit.MPM') },
                          { value: 'GRAUSCELCIUS', label: translate('generadorApp.MeasurementUnit.GRAUSCELCIUS') },
                        ]}
                        onChange={(options: any) => this.setState({ unity: options['value'] })}
                        name="unity"
                      />
                    </Row>
                  </Col>
                ) : null}
              </div>

              <div className="row mb-2 mr-4 justify-content-end">
                <Button color="primary" type="submit">
                  <i className="fa fa-filter" aria-hidden={'true'}></i>&nbsp;
                  <Translate contentKey="generadorApp.clinicalIndicator.btnFilter">Filter</Translate>
                </Button>
                &nbsp;
                <Button color="secondary" onClick={this.cancelFilters}>
                  <FontAwesomeIcon icon="trash-alt" />
                  &nbsp;
                  <Translate contentKey="generadorApp.clinicalIndicator.btnCleanFilter">Clean Filter</Translate>
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </UncontrolledCollapse>
      </>
    );
  }

  renderTable() {
    const { patientClinicalIndicators, medicalRecords, clinicalIndicatorList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        {clinicalIndicatorList &&
        clinicalIndicatorList.filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null).length > 0 ? (
          <>
            <Table
              id="clinical-indicator-table-list"
              responsive
              aria-describedby="clinical-indicator-heading"
              className={'table-hover table-striped table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="hand" onClick={sortFunction('id')}>
                    <Translate contentKey="generadorApp.clinicalIndicator.field_id">ID</Translate>
                    <FontAwesomeIcon icon={state.sort === 'id' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                  </th>
                  {state.baseFilters !== 'name' ? (
                    <th className="hand" onClick={sortFunction('name')}>
                      <Translate contentKey="generadorApp.clinicalIndicator.name">Name</Translate>
                      <FontAwesomeIcon icon={state.sort === 'name' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}
                  {state.baseFilters !== 'unity' ? (
                    <th className="hand" onClick={sortFunction('unity')}>
                      <Translate contentKey="generadorApp.clinicalIndicator.unity">Name</Translate>
                      <FontAwesomeIcon icon={state.sort === 'unity' ? (state.order === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                    </th>
                  ) : null}

                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {clinicalIndicatorList
                  .filter(v => typeof v.deletedAt === 'undefined' || v.deletedAt === null)
                  .map((clinicalIndicator, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <b className="visible-xs">
                          <Translate contentKey="generadorApp.clinicalIndicator.field_id">ID</Translate>
                        </b>
                        {clinicalIndicator.id}
                      </td>

                      {state.baseFilters !== 'name' ? (
                        <td id="name-cell">
                          <b className="visible-xs"> Name </b>

                          {clinicalIndicator.name}
                        </td>
                      ) : null}

                      {state.baseFilters !== 'unity' ? (
                        <td id="unity-cell">
                          <b className="visible-xs"> Name </b>
                          {clinicalIndicator.unity ? (
                            <Translate contentKey={`generadorApp.MeasurementUnit.${clinicalIndicator.unity}`} />
                          ) : (
                            <> </>
                          )}
                        </td>
                      ) : null}

                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          {hasAnyAuthority(props.userAccount, ['clinical-indicator'], 'edit') ? (
                            <Button onClick={() => this.openUpdateModal(clinicalIndicator)} color="primary" size="sm">
                              <FontAwesomeIcon icon="pencil-alt" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                          {hasAnyAuthority(props.userAccount, ['clinical-indicator'], 'canDelete') ? (
                            <Button onClick={() => this.deleteEntity(clinicalIndicator)} color="danger" size="sm">
                              <FontAwesomeIcon icon="trash" />
                            </Button>
                          ) : (
                            <></>
                          )}{' '}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : !this.props.loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="generadorApp.clinicalIndicator.home.notFound">No Clinical indicators found</Translate>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  renderFooter() {
    const { patientClinicalIndicators, medicalRecords, clinicalIndicatorList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <>
        <div className={clinicalIndicatorList && clinicalIndicatorList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              breakClassName={'page-item'}
              pageClassName={'page-item'}
              nextLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              breakLinkClassName={'page-link'}
              pageLinkClassName={'page-link'}
              pageCount={this.props.totalItems / this.state.itemsPerPage}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={v => {
                if (v.selected >= 0) this.handlePagination(v.selected + 1);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </Row>
        </div>
      </>
    );
  }

  render() {
    const { patientClinicalIndicators, medicalRecords, clinicalIndicatorList, match, totalItems } = this.props;

    const props = this.props;
    const state = this.state;
    const sortFunction = this.sort;

    return (
      <Panel className="clinical-indicator clinical-indicator-all-in-one">
        <PanelHeader>{this.renderHeader()}</PanelHeader>

        <PanelBody>
          <div className="table-responsive">
            {this.renderFilter()}

            {this.renderTable()}
            {this.renderNewModal()}
            {this.renderUpdateModal()}
          </div>
        </PanelBody>
        <PanelFooter>{this.renderFooter()}</PanelFooter>
      </Panel>
    );
  }
}

export const mapStateToProps = ({ clinicalIndicator, ...storeState }: IRootState) => ({
  patientClinicalIndicators: storeState.patientClinicalIndicator.entities,
  medicalRecords: storeState.medicalRecord.entities,
  clinicalIndicatorList: clinicalIndicator.entities,
  totalItems: clinicalIndicator.totalItems,
  userAccount: storeState.authentication.account,
  loading: clinicalIndicator.loading,

  // UPDATE
  clinicalIndicatorEntity: clinicalIndicator.entity,
});

export const mapDispatchToProps = {
  getEntities,

  updateEntity,
  reset,

  // UPDATE
  getEntity,
  createEntity,

  // DELETE
  deleteEntity,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ClinicalIndicator);
